import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';
import { useMeContext } from '~/modules/me/useMeContext';

export const GET_PROGRAMS_QUERY = gql`
  query GetProgramsList($programFilter: ProgramFilter2, $pageSize: Int) {
    programs: programs2(
      page: 1
      pageSize: $pageSize
      programFilter: $programFilter
    ) {
      totalItems
      items {
        id
        displayText
      }
    }
  }
`;

export const GET_ACTIVE_PROJECT_PROGRAMS_QUERY = gql`
  query getPageOfProgramsAvailableForFilteringProjects(
    $pageSize: Int!
    $programFilter: ProgramFilter2
  ) {
    pageOfProgramsAvailableForFilteringProjects(
      page: 1
      pageSize: $pageSize
      programFilter: $programFilter
    ) {
      id
      displayText
    }
  }
`;

const mappedPrograms = programs =>
  (programs || []).map(program => ({
    ...program,
    value: program.id,
    label: program.displayText,
    key: program.displayText
  }));

const getFilter = searchTerm => {
  return { text: searchTerm.length > 0 ? searchTerm : null };
};

export const useSearchablePrograms = searchTerm => {
  const { permissionsMap } = useMeContext();

  const canViewProgram = Boolean(
    permissionsMap['urn:replicon:program-action:view-program']
  );

  const query = !canViewProgram
    ? GET_ACTIVE_PROJECT_PROGRAMS_QUERY
    : GET_PROGRAMS_QUERY;

  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageSize: PAGE_SIZE + 1,
      programFilter: { isActive: true, ...getFilter(searchTerm) }
    }
  });

  const _programs =
    (!canViewProgram
      ? data?.pageOfProgramsAvailableForFilteringProjects
      : data?.programs?.items) || [];

  const programs = (mappedPrograms(_programs) || []).slice(0, PAGE_SIZE);

  return {
    programs,
    loading,
    hasMore: _programs.length > PAGE_SIZE
  };
};

export const searchPrograms = ({
  apolloClient
}) => setHasMore => async searchTerm => {
  const result = await apolloClient.query({
    query: GET_PROGRAMS_QUERY,
    variables: { pageSize: PAGE_SIZE, programFilter: getFilter(searchTerm) }
  });

  const programs = mappedPrograms(result?.data?.programs?.items);

  setHasMore(result?.data?.programs?.totalItems > programs.length);

  return programs;
};

const useSearchableProgramsWithHasMore = () => {
  const apolloClient = useApolloClient();

  return {
    searchPrograms: searchPrograms({
      apolloClient
    })
  };
};

export default useSearchableProgramsWithHasMore;
