import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import billingTransactionRefetchQueries from '~/modules/common/components/RefetchQueries/BillingTransactionRefetchQueries';
import {
  billTaxLineItemsQuery,
  PAGE_SIZE as TAX_LINE_ITEMS_PAGE_SIZE
} from '~/modules/billing-invoicing/common/components/TaxLineItems/hooks/useTaxLineItems';
import { billQuery } from '~/modules/billing-invoicing/bill/hooks/useBillDetails';
import {
  billQuery as lineItemQuery,
  PAGE_SIZE
} from '~/modules/billing-invoicing/bill/hooks/useLineItems';
import { filterSummarizeColumnOptions } from '~/modules/billing-invoicing/common/util';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const OPEN_BILL = gql`
  mutation openBill($input: OpenBillInput!) {
    openBill(openBillInput: $input) {
      success
    }
  }
`;

export const useOpenBill = ({ billId, summarizeColumnOptions }) => {
  const [openBill] = useMutation(OPEN_BILL);
  const filteredSummarizeColumnOptions = filterSummarizeColumnOptions(
    summarizeColumnOptions
  );

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });
  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  const refetchQueries = isPsaFpGraphqlOptimizationEnabled
    ? [
        ...billingTransactionRefetchQueries,
        'getAvailableToBillTotals',
        'getBillingTransactionSummary'
      ]
    : [
        ...billingTransactionRefetchQueries,
        'getAvailableToBillTotals',
        'getDraftTotals',
        'getOverdueBillSummary',
        'getOutstandingBillSummary',
        'getIssuedBillSummary'
      ];

  if (billId) {
    refetchQueries.push({
      query: billQuery,
      variables: {
        id: billId,
        isPsaPswatBillingDraftSubStatusEnabled
      }
    });
    refetchQueries.push({
      query: lineItemQuery,
      variables: {
        page: 1,
        pageSize: PAGE_SIZE,
        id: billId,
        filter: { isAdhoc: false },
        summarizeColumnOptions: filteredSummarizeColumnOptions
      }
    });
    refetchQueries.push({
      query: lineItemQuery,
      variables: {
        page: 1,
        pageSize: PAGE_SIZE,
        id: billId,
        filter: { isAdhoc: true },
        summarizeColumnOptions: filteredSummarizeColumnOptions
      }
    });
    refetchQueries.push({
      query: billTaxLineItemsQuery,
      variables: {
        page: 1,
        pageSize: TAX_LINE_ITEMS_PAGE_SIZE,
        id: billId,
        filter: { isTaxItem: true }
      }
    });
  }

  return {
    openBill: async () => {
      await openBill({
        variables: {
          input: {
            billId,
            summarizeColumnOptions
          }
        },
        refetchQueries,
        awaitRefetchQueries: true
      });
    }
  };
};

export default useOpenBill;
