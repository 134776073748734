import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  CircularProgress,
  makeStyles,
  TableCell
} from '@material-ui/core';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';
import { getCreateDefaultEntry } from '../hooks/utils';
import RateListRowRender from './RateListRowRender';
import { useTableStyles } from './hooks';

const useStyles = makeStyles(theme => ({
  spinner: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4.5)
  },
  progress: {
    position: 'absolute',
    left: '45%',
    top: '40%'
  }
}));

const useCalculatedInlineCellStyles = ({ columns }) =>
  useMemo(() => {
    const columnKeys = Object.keys(columns);

    const stylesFromColumn = column => ({
      width: column.width ? column.width : undefined,
      textAlign: column.align ? column.align : undefined
    });

    return columnKeys.reduce(
      (stylesObj, key) => ({
        ...stylesObj,
        [key]: stylesFromColumn(columns[key])
      }),
      {}
    );
  }, [columns]);

const RateListDataTable = ({
  columns,
  records,
  hasMore,
  loadMore,
  openDeleteDialog,
  onDeleteGroup,
  loadingMore,
  addNewEntry,
  setAddNewEntry,
  onAddNewEntry,
  projectCurrency,
  projectDimensions,
  readOnly
}) => {
  const styles = useCalculatedInlineCellStyles({ columns });

  const classes = useTableStyles({
    readOnly
  });
  const spinnerClasses = useStyles();

  const [focusOnLastRow, setFocusOnLastRow] = useState(false);

  useEffect(() => {
    if (addNewEntry) {
      onAddNewEntry(
        ...getCreateDefaultEntry(projectDimensions, projectCurrency),
        0,
        records.length
      );
      setAddNewEntry(false);
      setFocusOnLastRow(true);
    }
  }, [
    addNewEntry,
    onAddNewEntry,
    projectCurrency,
    projectDimensions,
    records.length,
    records.projectDimensions,
    setAddNewEntry
  ]);

  const onWayPoint = useCallback(() => {
    loadMore();
  }, [loadMore]);

  return (
    <div className={classes.root}>
      <Table className={classes.tableRoot}>
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableHeaderRow}>
            {Object.keys(columns).map((field, index) => (
              <TableCell
                key={field}
                className={classNames(
                  classes.tableHeaderCell,
                  columns[field].className
                )}
                style={styles[field]}
                tabIndex={0}
              >
                {columns[field].value}
              </TableCell>
            ))}
            <TableCell className={classes.tableHeaderCell} />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {records.map((record, index) => (
            <RateListRowRender
              key={record.id}
              record={record}
              nextRecord={records[index + 1]}
              previousRecord={records[index - 1]}
              classes={classes}
              columnStyles={styles}
              columns={columns}
              styles={styles}
              rowIndex={index + 1}
              openDeleteDialog={openDeleteDialog}
              onDeleteGroup={onDeleteGroup}
              focusOnLastRow={focusOnLastRow && records.length - 1 === index}
              setFocusOnLastRow={setFocusOnLastRow}
              readOnly={readOnly}
            />
          ))}
          {readOnly && loadingMore && (
            <>
              {Array.from({ length: 3 }, (_, index) => index).map(
                (record, index) => (
                  <RateListRowRender
                    key={record.id}
                    record={records[records.length - 1]}
                    classes={classes}
                    columnStyles={styles}
                    columns={columns}
                    styles={styles}
                    rowIndex={records.length + index}
                    readOnly={readOnly}
                    loadingMore={loadingMore}
                  />
                )
              )}
            </>
          )}
        </TableBody>
      </Table>
      {!readOnly && loadingMore ? (
        <div className={spinnerClasses.progress}>
          <CircularProgress
            size={45}
            className={spinnerClasses.spinner}
            color="primary"
          />
        </div>
      ) : null}
      {hasMore && loadMore && (
        <Waypoint
          bottomOffset="-20%"
          onEnter={onWayPoint}
          key={(records || []).length}
        />
      )}
    </div>
  );
};

RateListDataTable.propTypes = {
  records: PropTypes.any,
  columns: PropTypes.object,
  openDeleteDialog: PropTypes.func,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  loadingMore: PropTypes.bool,
  onAddNewEntry: PropTypes.func,
  addNewEntry: PropTypes.bool,
  setAddNewEntry: PropTypes.func,
  projectCurrency: PropTypes.object,
  projectDimensions: PropTypes.array,
  readOnly: PropTypes.bool
};

export default RateListDataTable;
