import NotAuthorizedPage from './NotAuthorizedPage';

const notAuthorizedRoute = ({ intl }) => ({
  title: () => intl.formatMessage({ id: 'routes.notAuthorized' }),
  hasPermission: true,
  component: NotAuthorizedPage,
  path: '/not-authorized',
  hidden: true
});

export default notAuthorizedRoute;
