import {
  mdiGoogleDrive,
  mdiGoogleSpreadsheet,
  mdiGoogleMaps,
  mdiAtlassian,
  mdiDropbox,
  mdiOnenote,
  mdiLinkedinBox,
  mdiGithubCircle,
  mdiFileLinkOutline
} from '@mdi/js';

export const supportedURLIcons = [
  {
    name: 'Google Sheets',
    pattern: /https:\/\/docs\.google\.com\/spreadsheets/,
    path: mdiGoogleSpreadsheet
  },
  {
    name: 'Google Drive',
    pattern: /https:\/\/docs\.google\.com/,
    path: mdiGoogleDrive
  },
  {
    name: 'Google Maps',
    pattern: /https:\/\/[a-z]+\.google\.com\/maps/,
    path: mdiGoogleMaps
  },
  {
    name: 'Dropbox',
    pattern: /https:\/\/[a-z]*\.dropbox\./,
    path: mdiDropbox
  },
  {
    name: 'Atlassian',
    pattern: /https:\/\/[a-z]*\.atlassian\.net/,
    path: mdiAtlassian
  },
  {
    name: 'One Note',
    pattern: /https:\/\/onedrive\.live\./,
    path: mdiOnenote
  },
  {
    name: 'LinkedIn',
    pattern: /https:\/\/[a-z]*\.linkedin\./,
    path: mdiLinkedinBox
  },
  {
    name: 'Github',
    pattern: /https:\/\/[a-z]*.github\./,
    path: mdiGithubCircle
  },
  {
    name: 'Default',
    pattern: /.*/,
    path: mdiFileLinkOutline
  }
];
