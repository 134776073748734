import TextFormatter from './TextFormatter';
import BooleanFormatter from './BooleanFormatter';
import PercentageFormatter from './PercentageFormatter';
import NumericFormatter from './NumericFormatter';

const typeFormatters = {
  text: TextFormatter,
  numeric: NumericFormatter,
  boolean: BooleanFormatter,
  percentage: PercentageFormatter
};

export const formatters = ({ taxRateHeaders }) => {
  return taxRateHeaders.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.keyUri]: typeFormatters[current.type]
    }),
    {}
  );
};
