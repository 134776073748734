import { gql } from 'graphql-tag';
import { SpecificResourceRequestFragmentDoc } from '~/types';

export const resourceRequestUnsubmittedSubscription = gql`
  subscription ResourceRequestUnsubmitted {
    resourceRequestUnsubmitted {
      ...SpecificResourceRequest
    }
  }
  ${SpecificResourceRequestFragmentDoc}
`;
