import React from 'react';
import { PropTypes } from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';
import Drawer, { DrawerHeader, DrawerContent } from '../Drawer';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2)
  }
}));

const LoadingDrawer = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Drawer>
      <DrawerHeader onClose={onClose}>
        <Skeleton variant="rect" height={32} />
      </DrawerHeader>
      <DrawerContent>
        <div className={classes.container}>
          <Skeleton variant="rect" height={48} />
        </div>
        <div className={classes.container}>
          <Skeleton variant="rect" height={96} />
        </div>
        <div className={classes.container}>
          <Skeleton variant="rect" height={192} />
        </div>
      </DrawerContent>
    </Drawer>
  );
};

LoadingDrawer.propTypes = {
  onClose: PropTypes.func
};

export default LoadingDrawer;
