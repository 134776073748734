import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.table.border}`,
    width: '100%',
    zIndex: '1000',
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1, 2, 1, 2)
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  buttonSpacing: {
    marginLeft: theme.spacing(1)
  }
}));

export default useStyles;
