import { useCallback } from 'react';

export default ({ uploadedFileData, setUploadedFileData }) => {
  const onRemoveItem = useCallback(
    key => {
      const newFiles = [...uploadedFileData];

      newFiles.splice(key, 1);
      setUploadedFileData(newFiles);
    },
    [setUploadedFileData, uploadedFileData]
  );

  return onRemoveItem;
};
