import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PageOutOfBoundsMessage from '~/modules/common/components/ListTable/PageOutOfBoundsMessage';

const useStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.caption.fontSize
    }
  }
}));

const useTableRowStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  }
}));

const usePageOutOfBoundsMessageStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    position: 'sticky',
    left: theme.spacing(0),
    borderBottom: 'none',
    padding: theme.spacing(2, 0.5)
  }
}));

const NoRecordsRow = ({
  columns,
  currentPage,
  navigatePage,
  enableRowSelection
}) => {
  const classes = useStyles();
  const colSpan = Object.keys(columns).length + (enableRowSelection ? 1 : 0);
  const tableRowClasses = useTableRowStyles();
  const pageOutOfBoundsMessageClasses = usePageOutOfBoundsMessageStyles();

  return (
    <TableBody role="presentation">
      <TableRow classes={tableRowClasses}>
        <TableCell className={classes.cell} colSpan={colSpan}>
          <PageOutOfBoundsMessage
            classes={pageOutOfBoundsMessageClasses}
            currentPage={currentPage}
            setCurrentPage={navigatePage}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

NoRecordsRow.propTypes = {
  columns: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  navigatePage: PropTypes.func.isRequired,
  enableRowSelection: PropTypes.bool.isRequired
};

export default NoRecordsRow;
