import { gql } from 'graphql-tag';
import { projectAdditionalInfoFragment } from './projectAdditionalInfoFragment';

export const UPDATE_PROJECT_ADDITIONAL_INFO_MUTATION = gql`
  mutation UpdateProjectAdditionalInfo($projectInput: ProjectInput!) {
    updateProject2(projectInput: $projectInput) {
      ...ProjectAdditionalInfo
    }
  }
  ${projectAdditionalInfoFragment}
`;
