/* eslint-disable react/jsx-max-depth */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, useMediaQuery } from '@material-ui/core';

import { useIntl } from 'react-intl';
import CustomLineItemTab from './CustomLineItemTab';
import AddLineItemsDialogTitle from './AddLineItemsDialogTitle';
import AddLineItemsTab from './AddLineItemsTab';
import { LineItemsSearchContextProvider } from './AddLineItemsDialogContext';

const paperProps = formatMessage => ({
  'aria-label': formatMessage({
    id: 'addLineItemDialog.dialogBox'
  })
});

const transitionProps = { role: 'presentation' };

export const AddLineItemsDialog = ({
  client,
  endDate,
  startDate,
  open,
  onClose,
  billId,
  setLineItemAdding,
  billCurrency,
  availableToBillType,
  createBill = false,
  projectName,
  projectUri,
  summarizeColumnOptions,
  billingBatchState,
  setBillingBatchState,
  setSummarizeColumOptions,
  refetchAvailableToBillRows,
  refetchAvailableToBillTotal,
  refetchFilteredTotals,
  billDetails,
  ...billInfoProps
}) => {
  const [currentTab, setCurrentTab] = useState('addLineItems');
  const { formatMessage } = useIntl();

  return (
    <Dialog
      TransitionProps={transitionProps}
      PaperProps={paperProps(formatMessage)}
      disableBackdropClick
      maxWidth="md"
      fullWidth
      fullScreen={useMediaQuery(theme => theme.breakpoints.down('sm'), {
        noSsr: true
      })}
      open={open}
      onClose={onClose}
      role="main"
    >
      <AddLineItemsDialogTitle
        createBill={createBill}
        client={client}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      {currentTab === 'addCustomLineItems' && !createBill ? (
        <CustomLineItemTab
          values={billInfoProps.values}
          client={client}
          billCurrency={billCurrency}
          onClose={onClose}
          setLineItemAdding={setLineItemAdding}
          billDetails={billDetails}
        />
      ) : (
        <LineItemsSearchContextProvider
          availableToBillEndDate={endDate}
          availableToBillStartDate={startDate}
          availableToBillItemType={availableToBillType}
          projectName={projectName}
          projectUri={projectUri}
        >
          <AddLineItemsTab
            createBill={createBill}
            onClose={onClose}
            client={client}
            billId={billId}
            billCurrency={billCurrency}
            setLineItemAdding={setLineItemAdding}
            projectName={projectName}
            projectUri={projectUri}
            summarizeColumnOptions={summarizeColumnOptions}
            setSummarizeColumOptions={setSummarizeColumOptions}
            setBillingBatchState={setBillingBatchState}
            billingBatchState={billingBatchState}
            refetchAvailableToBillRows={refetchAvailableToBillRows}
            refetchAvailableToBillTotal={refetchAvailableToBillTotal}
            refetchFilteredTotals={refetchFilteredTotals}
            startDate={startDate}
            endDate={endDate}
            billDetails={billDetails}
          />
        </LineItemsSearchContextProvider>
      )}
    </Dialog>
  );
};

AddLineItemsDialog.propTypes = {
  client: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  billId: PropTypes.string,
  setLineItemAdding: PropTypes.func,
  billCurrency: PropTypes.object,
  availableToBillType: PropTypes.any,
  createBill: PropTypes.bool,
  endDate: PropTypes.any,
  startDate: PropTypes.any,
  projectName: PropTypes.any,
  projectUri: PropTypes.any,
  summarizeColumnOptions: PropTypes.array,
  billingBatchState: PropTypes.bool,
  setBillingBatchState: PropTypes.func,
  setSummarizeColumOptions: PropTypes.func,
  refetchAvailableToBillRows: PropTypes.func,
  refetchAvailableToBillTotal: PropTypes.func,
  refetchFilteredTotals: PropTypes.func,
  billDetails: PropTypes.object.isRequired
};

export default AddLineItemsDialog;
