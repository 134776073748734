import { gql } from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';

export const GET_KEY_VALUE_SETTINGS = gql`
  query Eager_keyValueSettings($key: String!) {
    keyValueSettings(key: $key)
  }
`;

export const PUT_KEY_VALUE_SETTINGS = gql`
  mutation putKeyValueSettings($input: PutKeyValueSettingsInput) {
    putKeyValueSettings2(input: $input)
  }
`;

export const useGetKeyValueSettings = (key, skip = false) => {
  const { loading, error, data } = useQuery(GET_KEY_VALUE_SETTINGS, {
    variables: {
      key
    },
    skip
  });

  return {
    isLoading: loading,
    keyValueSettings: data && data.keyValueSettings,
    error
  };
};

export const useKeyValueSettingsMutation = updateCache =>
  useMutation(PUT_KEY_VALUE_SETTINGS, {
    awaitRefetchQueries: true,
    update: updateCache
  });

export const updateCache = key => (proxy, { data }) => {
  if (data) {
    proxy.writeQuery({
      query: GET_KEY_VALUE_SETTINGS,
      variables: { key },
      data: {
        keyValueSettings: data.putKeyValueSettings2
      }
    });
  }
};
