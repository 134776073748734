import React, { useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

export const BooleanEditor = ({
  column: { id: columnId, setFieldValue, label, isMobile },
  field,
  record,
  index
}) => {
  const handleChange = useCallback(
    event => {
      setFieldValue(`scripts[${index}].${columnId}`, event.target.checked);
    },
    [setFieldValue, index, columnId]
  );

  const switchControl = useMemo(
    () => <Checkbox checked={record[field]} onChange={handleChange} />,
    [record, field, handleChange]
  );

  return (
    <FormControlLabel
      control={switchControl}
      label={label}
      labelPlacement={isMobile ? 'end' : 'top'}
    />
  );
};
BooleanEditor.propTypes = {
  column: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  index: PropTypes.number,
  field: PropTypes.string.isRequired
};

export default BooleanEditor;
