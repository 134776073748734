import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { billQuery } from '~/modules/billing-invoicing/bill/hooks/useBillDetails';
import {
  billAssociatedCreditMemoQuery,
  PAGE_SIZE
} from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/useAssociatedCreditMemos';
import billingTransactionRefetchQueries from '~/modules/common/components/RefetchQueries/BillingTransactionRefetchQueries';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const PUT_CREDIT_MEMOS_FOR_BILL = gql`
  mutation putCreditMemosForBill($input: CreditMemosForBillInput!) {
    putCreditMemosForBill2(input: $input) {
      billUri
    }
  }
`;

export const usePutCreditMemosForBill = () => {
  const [putCreditMemosForBill] = useMutation(PUT_CREDIT_MEMOS_FOR_BILL);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });
  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : [
        'getDraftTotals',
        'getOverdueBillSummary',
        'getOutstandingBillSummary',
        'getIssuedBillSummary',
        'getUnallocatedBillSummary'
      ];

  return {
    putCreditMemosForBill: CreditMemos =>
      putCreditMemosForBill({
        variables: {
          input: CreditMemos
        },
        refetchQueries: [
          ...billingTransactionRefetchQueries,
          'availableCreditMemosForBill',
          ...queries,
          {
            query: billQuery,
            variables: {
              id: CreditMemos.billId,
              isPsaPswatBillingDraftSubStatusEnabled
            }
          },
          {
            query: billAssociatedCreditMemoQuery,
            variables: {
              page: 1,
              pageSize: PAGE_SIZE,
              id: CreditMemos.billId
            }
          }
        ],
        awaitRefetchQueries: true
      })
  };
};
