import { makeStyles } from '@material-ui/core';
import React from 'react';
import MuiDrawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import AddResourceRequestDrawerDetails from './AddResourceRequestDrawerDetails';

const useStyles = makeStyles(theme => ({
  paper: {
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

export const AddResourceRequestDrawer = ({
  project,
  onClose,
  open,
  isMobile = false
}) => {
  const classes = useStyles();

  return (
    <MuiDrawer anchor="right" open={open} classes={classes}>
      <AddResourceRequestDrawerDetails
        project={project}
        onClose={onClose}
        isMobile={isMobile}
      />
    </MuiDrawer>
  );
};

AddResourceRequestDrawer.propTypes = {
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isMobile: PropTypes.bool
};

export default AddResourceRequestDrawer;
