import { useQuery } from '@apollo/client';
import get from 'lodash.get';

import { ResourceRequestAccessLevel } from '~/types';
import { getResourcingPlansQuery as GET_RESOURCING_PLANS_QUERY } from '~/modules/resourcing/graphql';
import useLoadMoreResourcingPlans from './useLoadMoreResourcingPlans';

const useResourcingPlans = ({
  resourcePlanGroup,
  planFilter,
  requestAccessLevel = ResourceRequestAccessLevel.All
}) => {
  const { data, loading, fetchMore, networkStatus } = useQuery(
    GET_RESOURCING_PLANS_QUERY,
    {
      variables: {
        paginationToken: null,
        requestAccessLevel,
        filter: planFilter,
        groupBy: resourcePlanGroup
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const getResourcePlansForCurrentTenant =
    get(data, 'getResourcePlansForCurrentTenant') || {};

  const { plans = [], paginationToken } = getResourcePlansForCurrentTenant;

  const { loadMore } = useLoadMoreResourcingPlans({
    fetchMore,
    hasMore: Boolean(paginationToken),
    paginationToken,
    planFilter,
    networkStatus,
    requestAccessLevel
  });

  return {
    plans,
    paginationToken,
    loading,
    loadMorePlans: loadMore,
    hasMorePlans: Boolean(paginationToken)
  };
};

export default useResourcingPlans;
