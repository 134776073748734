import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { SearchableRoleDropDown } from '~/modules/resourcing/common/components';
import FullWidthPopper from './FullWidthPopper';

const useStyles = makeStyles(theme => ({
  row: { padding: theme.spacing(2, 1, 0, 1), margin: theme.spacing(1) }
}));

export const RoleDropdownRow = ({
  label,
  value,
  onChange,
  isLoading,
  classes: classesOveride,
  placeholder
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOveride });

  return (
    <div className={classes.row}>
      {isLoading && <Skeleton height={36} width={360} />}
      {!isLoading && (
        <SearchableRoleDropDown
          dataQeId="RoleDropDown"
          value={value}
          placeholder={placeholder}
          ariaLabel={formatMessage({
            id: 'resourceRequestTitle.anyRole'
          })}
          label={label}
          classes={classes.inputClasses}
          variant="standard"
          onChange={onChange}
          canUserEditSkillAssignment
          multiline
          customPopperComponent={FullWidthPopper}
        />
      )}
    </div>
  );
};
RoleDropdownRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
  placeholder: PropTypes.string
};

export default RoleDropdownRow;
