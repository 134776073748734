import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import ImportExportMenuItems from './ImportExportMenuItems';
import ActionPropTypes from './ActionPropTypes';
import ExportDialog from './ExportDialog';
import { ExportStatus } from './hooks/useExportTable';

const useMenuStyles = makeStyles(theme => ({
  paper: {
    minWidth: '215px'
  }
}));

const importMenuRole = {
  role: 'main'
};

const useListSubHeaderStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const ImportExportMenu = ({
  menuId,
  isOpen,
  onClose,
  actions,
  anchorEl,
  billingFilter,
  dateRange,
  periodResolution,
  filename
}) => {
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const menuClasses = useMenuStyles();
  const listSubHeaderClasses = useListSubHeaderStyles();

  const [exportInProgress, setExportInProgress] = useState(false);
  const [exportFail, setExportFail] = useState(false);
  const [tableKeyToExport, setTableKeyToExport] = useState('');
  const [exportFilter, setExportFilter] = useState(null);
  const [activeColumns, setActiveColumns] = useState([]);
  const [activeColumnNameOverrides, setActiveColumnNameOverrides] = useState(
    []
  );

  const handleExportClose = useCallback(() => {
    setTableKeyToExport('');
    setExportFilter(null);
    setActiveColumns([]);
    setActiveColumnNameOverrides([]);
    setExportFail(false);
    setExportInProgress(false);
  }, []);

  const handleExportFail = useCallback(() => {
    setExportFail(true);
  }, []);

  const handleExportStart = useCallback(
    (tableKey, filter, activeColumnsToSet, activeColumnNameOverridesToSet) => {
      setExportInProgress(true);
      setTableKeyToExport(tableKey);
      setExportFilter(filter || {});
      setActiveColumns(activeColumnsToSet);
      setActiveColumnNameOverrides(activeColumnNameOverridesToSet);
    },
    []
  );

  return (
    <>
      {exportInProgress && (
        <ExportStatus
          onExportComplete={handleExportClose}
          onExportFail={handleExportFail}
          tableKey={tableKeyToExport}
          exportFilter={exportFilter}
          activeColumns={activeColumns}
          activeColumnNameOverrides={activeColumnNameOverrides}
          dateRange={dateRange}
          billingFilter={billingFilter}
          periodResolution={periodResolution}
          filename={filename}
        >
          <ExportDialog
            isOpen={exportInProgress}
            handleClose={handleExportClose}
            exportFail={exportFail}
          />
        </ExportStatus>
      )}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        onClick={onClose}
        classes={menuClasses}
        MenuListProps={importMenuRole}
        variant="menu"
      >
        {isPsaPrpAccessibilityIssuesEnabled
          ? actions.map((action, index) => [
              action.label && (
                <ListSubheader
                  classes={index > 0 ? listSubHeaderClasses : null}
                >
                  <FormattedMessage id={`${action.label}`} />
                </ListSubheader>
              ),
              <ImportExportMenuItems
                key={`${action.label}_parent`}
                actionItems={action.actionItems}
                customActionItems={action.customActionItems}
                handleExportStart={handleExportStart}
                index={index}
              />
            ])
          : actions.map((action, index) => (
              <div key={`${action.label}_parent`} role="presentation">
                {action.label && (
                  <ListSubheader
                    classes={index > 0 ? listSubHeaderClasses : null}
                  >
                    <FormattedMessage id={`${action.label}`} />
                  </ListSubheader>
                )}
                <div role="menu">
                  <ImportExportMenuItems
                    actionItems={action.actionItems}
                    customActionItems={action.customActionItems}
                    handleExportStart={handleExportStart}
                  />
                </div>
              </div>
            ))}
      </Menu>
    </>
  );
};

ImportExportMenu.propTypes = {
  anchorEl: PropTypes.object,
  menuId: PropTypes.string.isRequired,
  dateRange: PropTypes.object,
  periodResolution: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  billingFilter: PropTypes.object,
  actions: ActionPropTypes.actions.isRequired,
  filename: PropTypes.string
};

export default ImportExportMenu;
