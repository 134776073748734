import usePutCertificate from '~/modules/resourcing/common/components/Certificates/hooks/usePutCertificate';
import useValidationSchema from '~/modules/resourcing/common/components/Certificates/hooks/useValidationSchema';
import { useDeleteCertificate } from './useDeleteCertificate';

export const STRING_MAX_LENGTH = 255;

export const onSubmit = async ({
  values,
  onCancel,
  putCertificate,
  setSubmitError,
  setIsSubmitting
}) => {
  setIsSubmitting(true);

  const { error } = await putCertificate(values);

  setSubmitError(error);
  setIsSubmitting(false);
  if (!error) {
    setSubmitError(null);
    onCancel();
  }
};

export const onReset = ({ onCancel, setSubmitError }) => {
  setSubmitError(null);
  onCancel();
};

export const useFormState = ({
  userUri,
  onCancel,
  certificate,
  setSubmitError,
  setIsSubmitting
}) => {
  const { deleteCertificate } = useDeleteCertificate({
    userCertificateUri: certificate.uri
  });

  const { onPut } = usePutCertificate({
    userUri,
    onCancel,
    setSubmitError,
    setIsSubmitting
  });
  const validationSchema = useValidationSchema();

  return {
    onAdd: onPut,
    onSubmit: onPut,
    deleteCertificate,
    initialValues: {
      ...certificate,
      doesNotExpire: certificate ? !certificate.expires : false
    },
    validationSchema,
    enableReinitialize: true
  };
};

export default useFormState;
