import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { BillingIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  titleIcon: {
    margin: theme.spacing(-2, 1, -2, 0),
    color: theme.palette.text.secondary
  }
}));

const BillingCardEditableTitle = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <BillingIcon className={classes.titleIcon} />
      <FormattedMessage id="billingCard.cardTitle" />
    </div>
  );
};

BillingCardEditableTitle.propTypes = {};

export default BillingCardEditableTitle;
