import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Percentage, User } from '~/modules/common/components';
import IconLinkButton from '~/modules/resourcing/common/components/IconLinkButton';
import useStyles from '../ResourceAllocationEditableUserItem/useStyles';

const useStylesOverride = makeStyles(theme => ({
  resource: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    width: 130,
    textAlign: 'left'
  },
  atDivider: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0.5, 0, 0.5)
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginTop: theme.spacing(0.5)
  },
  name: {
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 130
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    margin: [['-0.28em', '0.28em', '-0.28em', 0]],
    width: '1.95em',
    height: '1.95em',
    flexGrow: 0,
    fontSize: theme.typography.caption.fontSize,
    alignSelf: 'center',
    flexShrink: 0
  }
}));

export const ResourceAllocationReadonlyUserItem = ({
  classes: classesOverride,
  resourceAllocationUser,
  resourceAllocationLoad,
  isAdjustedLoading
}) => {
  const { formatMessage } = useIntl();

  const classes = useStyles({ classes: classesOverride });
  const userClasses = useStylesOverride();
  const history = useHistory();
  const cssClasses = useMemo(
    () => ({
      avatar: userClasses.avatar,
      name: userClasses.name
    }),
    [userClasses.avatar, userClasses.name]
  );

  const isViewUserEnabled =
    resourceAllocationUser.permittedActionUris &&
    resourceAllocationUser.permittedActionUris.includes(
      'urn:replicon:user-action:view-user'
    );

  const iconLinkButtonClasses = useMemo(
    () => ({ openButton: classes.openButton }),
    [classes.openButton]
  );

  const toLink = `#/resource/${resourceAllocationUser.slug}`;
  const openDrawer = useCallback(() => history.push(toLink), [history, toLink]);

  return (
    <div
      className={classes.root}
      onClick={openDrawer}
      onKeyDown={openDrawer}
      role="button"
      tabIndex="0"
    >
      {resourceAllocationUser && (
        <div className={userClasses.resource}>
          <User user={resourceAllocationUser} classes={cssClasses} />
        </div>
      )}
      <span className={userClasses.atDivider}>@</span>
      <Tooltip
        title={
          <>
            <FormattedMessage id="resourceLoadingField.loading" />:{' '}
            {resourceAllocationLoad}%
          </>
        }
      >
        <>
          <div className={userClasses.loadingContainer}>
            <Percentage
              value={resourceAllocationLoad}
              precision={0}
              className={classes.loading}
            />
          </div>
          {isAdjustedLoading && <span> * </span>}
        </>
      </Tooltip>
      <div>
        {isViewUserEnabled && (
          <IconLinkButton
            classes={iconLinkButtonClasses}
            toLink={toLink}
            ariaLabel={formatMessage({
              id: 'resourceRequestItem.showMoreDetails'
            })}
          />
        )}
      </div>
    </div>
  );
};

ResourceAllocationReadonlyUserItem.propTypes = {
  classes: PropTypes.object,
  resourceAllocationUser: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    permittedActionUris: PropTypes.array,
    slug: PropTypes.string
  }),
  resourceAllocationLoad: PropTypes.number,
  isAdjustedLoading: PropTypes.bool
};

export default ResourceAllocationReadonlyUserItem;
