import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import EntityName from '~/modules/common/components/entities/common/EntityName';
import EntityAvatar from '../Avatars/EntityAvatar';
import GroupFullPath from './GroupFullPath';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    verticalAlign: 'top',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'flex-start',
    color: theme.palette.text.primary,
    width: '100%'
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'start',
    fontSize: theme.typography.body2.fontSize,
    width: '1.5em',
    height: '1.5em',
    margin: [[0, '0.375em', 0, '0.125em']],
    color: theme.palette.text.secondary
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
  avatarIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5)
  },
  fullPathContainer: {
    width: '100%',
    overflow: 'hidden'
  }
}));

export const GroupName = ({
  Icon,
  value,
  groupSettings,
  variant = 'icon',
  showFullPath,
  disableIcon
}) => {
  const classes = useStyles();

  if (!groupSettings.isEnabled) return null;

  const icon = (
    <Icon
      className={classNames({
        [classes.icon]: variant === 'icon',
        [classes.avatarIcon]: variant === 'avatar'
      })}
    />
  );

  const groupIcon =
    variant === 'icon' ? (
      icon
    ) : (
      <EntityAvatar className={classes.avatar} size="xsmall">
        {icon}
      </EntityAvatar>
    );

  const name = <EntityName name={value.displayText} />;

  const text =
    showFullPath && value.fullPath && value.fullPath.length > 0 ? (
      <div className={classes.fullPathContainer}>
        <GroupFullPath fullPath={value.fullPath} />
        {name}
      </div>
    ) : (
      name
    );

  return (
    <span className={classes.root}>
      {!disableIcon && groupIcon}
      {text}
    </span>
  );
};

GroupName.propTypes = {
  value: PropTypes.object.isRequired,
  Icon: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['icon', 'avatar']),
  groupSettings: PropTypes.object,
  showFullPath: PropTypes.bool,
  disableIcon: PropTypes.bool
};

export default GroupName;
