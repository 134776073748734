import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { usePaginatedOverEstimatedTasksProjectsQuery } from '../hooks/usePaginatedOverEstimatedTasksProjectsQuery';
import { ProjectRowLoading } from '../../common';
import ProjectRow from './ProjectRow';

const useStyles = makeStyles(theme => ({
  showMoreButton: {
    backgroundColor: theme.palette.grey[100],
    textTransform: 'none',
    width: '100%'
  }
}));

export const ProjectsExpansionPanelDetails = ({ overEstimatedTaskType }) => {
  const classes = useStyles();
  const [page, setPage] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const {
    loading,
    overEstimatedProjects = [],
    loadMore
  } = usePaginatedOverEstimatedTasksProjectsQuery({
    page,
    setPage,
    hasMore,
    setHasMore,
    overEstimatedTaskType
  });

  return (
    <>
      {overEstimatedProjects.map(overEstimatedProject => (
        <ProjectRow
          overEstimatedProject={overEstimatedProject}
          key={overEstimatedProject.id}
        />
      ))}
      {loading && <ProjectRowLoading />}
      {hasMore && !loading && (
        <Button
          className={classes.showMoreButton}
          color="primary"
          onClick={loadMore}
        >
          <FormattedMessage id="projectOverview.showMore" />
        </Button>
      )}
    </>
  );
};

ProjectsExpansionPanelDetails.propTypes = {
  overEstimatedTaskType: PropTypes.string
};

export default ProjectsExpansionPanelDetails;
