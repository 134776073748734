import classNames from 'classnames';
import { Card, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';

const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  },
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5, 2, 1)
  },
  content: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  },
  action: {
    margin: theme.spacing(0, 0, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3)
  }
}));

const useCardStyles = makeStyles(() => ({
  root: {}
}));

export const ResourcingCard = ({
  children,
  title,
  className,
  avatar,
  action,
  classes: classesOverride
}) => {
  const cardClasses = useCardStyles();

  const classes = useStyles({ classes: classesOverride });

  return (
    <ErrorBoundary>
      <Card
        classes={useMemo(
          () => ({ root: classNames(className, cardClasses.root) }),
          [cardClasses.root, className]
        )}
        elevation={1}
      >
        {(title || avatar) && (
          <CardHeader
            classes={classes}
            title={title}
            avatar={avatar}
            action={action}
          />
        )}
        {children}
      </Card>
    </ErrorBoundary>
  );
};

ResourcingCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  avatar: PropTypes.node,
  action: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object
};

export default ResourcingCard;
