import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ResourceUserRequestedCostAllocationTooltip from '../ResourceUserRequestedCostAllocationTooltip';

const ResourceRequestUserAllocationPeriodOverlayTooltip = ({
  showTooltip,
  totalHours,
  totalCost,
  startDate,
  endDate,
  percentage,
  resourceRequest,
  hideCost,
  children
}) => {
  const title = useMemo(() => {
    return showTooltip ? (
      <ResourceUserRequestedCostAllocationTooltip
        totalAllocatedHours={totalHours}
        loading={percentage}
        totalCost={totalCost}
        startDate={startDate}
        endDate={endDate}
        resourceRequest={resourceRequest}
        hideCost={hideCost}
      />
    ) : null;
  }, [
    endDate,
    percentage,
    resourceRequest,
    showTooltip,
    startDate,
    totalCost,
    totalHours,
    hideCost
  ]);

  return showTooltip ? (
    <Tooltip title={title}>{children}</Tooltip>
  ) : (
    <>{children}</>
  );
};

ResourceRequestUserAllocationPeriodOverlayTooltip.propTypes = {
  showTooltip: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  totalHours: PropTypes.number,
  percentage: PropTypes.number,
  totalCost: PropTypes.object,
  resourceRequest: PropTypes.object,
  children: PropTypes.node,
  hideCost: PropTypes.bool
};

export default ResourceRequestUserAllocationPeriodOverlayTooltip;
