import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ADDRESS_TYPE } from '~/modules/common/enums';
import { NoValue } from '~/modules/common/components';
import { CustomChip } from '~/modules/projects/project/common/components/ContactInfo';
import AddressFieldReadOnly from './AddressFieldReadOnly';

const AddressTypeReadOnly = ({ record, field }) => {
  const intl = useIntl();
  const value = intl.formatMessage({
    id: `projectBasicInfoCard.addressInfo.addressTypeOptions.${
      ADDRESS_TYPE[record[field]]
    }`
  });

  return (
    <AddressFieldReadOnly record={record} field={field}>
      {record[field] !== ADDRESS_TYPE.OTHERS ? (
        <CustomChip label={value} />
      ) : (
        value || <NoValue />
      )}
    </AddressFieldReadOnly>
  );
};

AddressTypeReadOnly.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string
};

export default AddressTypeReadOnly;
