import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { BillStatus } from '~/types';
import { draftQuery } from './totalsQuery';
import {
  mapToServiceFilter,
  getConsolidatedTypeFilter
} from './useOutstandingBillTabSummary';

const summaryType = ['BILL'];

export const getDraftQueryParameters = ({
  billingTransactionFilter,
  contextFilter,
  isPsaFpGraphqlOptimizationEnabled
}) => {
  const consolidatedTypeFilter =
    billingTransactionFilter.transactionTypes &&
    billingTransactionFilter.transactionTypes.length > 0
      ? getConsolidatedTypeFilter(
          billingTransactionFilter.transactionTypes,
          summaryType
        )
      : summaryType;

  return {
    variables: {
      filter: mapToServiceFilter({
        contextFilter,
        billingTransactionFilter,
        consolidatedTypeFilter,
        billStatus: [BillStatus.Draft]
      })
    },
    skip:
      isPsaFpGraphqlOptimizationEnabled || !consolidatedTypeFilter.length > 0,
    fetchPolicy: 'network-only'
  };
};

export const useDraftTabSummary = (billingTransactionFilter, contextFilter) => {
  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });
  const param = getDraftQueryParameters({
    billingTransactionFilter,
    contextFilter,
    isPsaFpGraphqlOptimizationEnabled
  });

  const { data, loading } = useQuery(draftQuery, param);

  const draftSummary = get(data, 'getBillingTransactionTotals', {});

  return { loading, draftSummary };
};

export default useDraftTabSummary;
