import React from 'react';
import PropTypes from 'prop-types';
import AbbreviatedDecimal from '../AbbreviatedDecimal';

export const AbbreviatedMoneyValue = ({ money, className, dataQeId }) => (
  <AbbreviatedDecimal
    value={money.amount}
    className={className}
    prefix={`${money.currency.symbol} `}
    dataQeId={dataQeId}
  />
);

AbbreviatedMoneyValue.propTypes = {
  className: PropTypes.string,
  money: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.shape({
      symbol: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  dataQeId: PropTypes.string
};

export default AbbreviatedMoneyValue;
