import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import {
  dayWidth,
  monthWidth,
  quarterWidth,
  weekWidth,
  yearWidth
} from '~/modules/common/charts/timeline/calculations';

const useStyles = makeStyles(theme => ({
  timeBlock: {
    height: theme.spacing(3),
    padding: 0,
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(3)}px`,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    display: 'inline-block'
  },
  year: {
    width: yearWidth
  },
  quarter: {
    width: quarterWidth,
    padding: theme.spacing(0.5, 0),
    lineHeight: `${theme.spacing(2)}px`
  },
  month: {
    width: monthWidth
  },
  week: {
    width: weekWidth
  },
  day: {
    width: dayWidth,
    padding: theme.spacing(0.5, 0),
    lineHeight: `${theme.spacing(2)}px`
  }
}));

const QuickAllocationProjectChartHeaderTimeItems = ({ scale, chartDates }) => {
  const classes = useStyles();

  return chartDates.map(({ start }) => (
    <div
      key={start}
      className={classNames(classes.timeBlock, {
        [classes.year]: scale === PERIOD_SCALE_ENUM.YEARS,
        [classes.quarter]: scale === PERIOD_SCALE_ENUM.QUARTERS,
        [classes.month]: scale === PERIOD_SCALE_ENUM.MONTHS,
        [classes.week]: scale === PERIOD_SCALE_ENUM.WEEKS,
        [classes.day]: scale === PERIOD_SCALE_ENUM.DAYS
      })}
    ></div>
  ));
};

QuickAllocationProjectChartHeaderTimeItems.propTypes = {
  chartDates: PropTypes.array.isRequired,
  scale: PropTypes.string.isRequired
};

export default QuickAllocationProjectChartHeaderTimeItems;
