import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { ResourceRequestsToRescheduleByStatus } from '~/types';

const useStyles = makeStyles(theme => ({
  collapse: {
    margin: theme.spacing(0, 0, 0, 3.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  }
}));

export const ResourceRequestsToRescheduleDropDown = ({
  value,
  onChange,
  resourceRequestsStatusCountSummary,
  isRescheduleResourceRequestsChecked
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { completed, proposed } = resourceRequestsStatusCountSummary;

  const isCompletedOrProposedRequestsPresent = completed > 0 || proposed > 0;

  const options = useMemo(
    () =>
      [
        proposed > 0
          ? {
              id:
                ResourceRequestsToRescheduleByStatus.WithoutAcceptedResourcesRequests,
              displayText: intl.formatMessage({
                id:
                  'rescheduleProject.rescheduleWithoutAcceptedResourceRequests'
              })
            }
          : undefined,
        {
          id:
            ResourceRequestsToRescheduleByStatus.WithoutProposedOrAcceptedResourcesRequests,
          displayText: intl.formatMessage({
            id:
              'rescheduleProject.rescheduleWithoutProposedOrAcceptedResourceRequests'
          })
        },
        completed > 0
          ? {
              id:
                ResourceRequestsToRescheduleByStatus.ReleaseAndRescheduleAllRequests,
              displayText: intl.formatMessage({
                id: 'rescheduleProject.releaseAndRescheduleAllRequests'
              })
            }
          : undefined
      ].filter(x => x),
    [intl, proposed, completed]
  );

  const selectedValue = useMemo(() => options.find(x => x.id === value), [
    options,
    value
  ]);

  const collapseClasses = useMemo(() => ({ container: classes.collapse }), [
    classes.collapse
  ]);

  return isCompletedOrProposedRequestsPresent ? (
    <Collapse
      in={isRescheduleResourceRequestsChecked}
      timeout="auto"
      classes={collapseClasses}
      unmountOnExit
    >
      <SimpleDropdown
        label={intl.formatMessage({
          id: `rescheduleProject.resourceRequestsToReschedule`
        })}
        variant="filled"
        margin="dense"
        options={options}
        onChange={onChange}
        value={selectedValue}
        noneOption={false}
        disableSort
        required
      />
    </Collapse>
  ) : null;
};

ResourceRequestsToRescheduleDropDown.propTypes = {
  value: PropTypes.oneOf([
    ResourceRequestsToRescheduleByStatus.ReleaseAndRescheduleAllRequests,
    ResourceRequestsToRescheduleByStatus.WithoutAcceptedResourcesRequests,
    ResourceRequestsToRescheduleByStatus.WithoutProposedOrAcceptedResourcesRequests
  ]),
  onChange: PropTypes.func.isRequired,
  resourceRequestsStatusCountSummary: PropTypes.object,
  isRescheduleResourceRequestsChecked: PropTypes.bool
};

export default ResourceRequestsToRescheduleDropDown;
