import { useCallback } from 'react';
import { UNMANAGED_URI, MANAGED_URI } from '~/modules/projects/projectTypes';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';

export const updateQueryOnFetchMoreResult = ({ isManaged }) => (
  prev = { overEstimatedTasksProjects: { overEstimatedProjects: [] } },
  {
    fetchMoreResult = {
      overEstimatedTasksProjects: { overEstimatedProjects: [] }
    }
  }
) => {
  if (!fetchMoreResult) return prev;

  const prevProjects =
    prev.overEstimatedTasksProjects.overEstimatedProjects || [];
  const moreProjects =
    fetchMoreResult.overEstimatedTasksProjects.overEstimatedProjects || [];

  const currentQueryProjectManagementType = isManaged
    ? MANAGED_URI
    : UNMANAGED_URI;

  const shouldAppendResult = moreProjects
    ? prevProjects.some(
        p =>
          p.project.projectManagementType === currentQueryProjectManagementType
      )
    : false;

  if (!shouldAppendResult) return { overEstimatedTasksProjects: [] };

  return {
    overEstimatedTasksProjects: {
      overEstimatedProjects: [...prevProjects, ...moreProjects],
      __typename: 'Project',
      totalProjects: prev.overEstimatedTasksProjects.totalProjects || 0
    }
  };
};

export const useLoadMoreOverEstimatedTasksProjects = ({
  isManaged,
  searchCriteria,
  fetchMore,
  page,
  pageSize,
  setPage,
  hasMore,
  overEstimatedTaskType,
  quickFilter
}) => {
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter
  });

  const loadMore = useCallback(async () => {
    if (!hasMore) return;
    try {
      await fetchMore({
        variables: {
          page: page + 1,
          pageSize,
          projectFilter: { ...projectFilter, isManaged },
          overEstimatedTaskType
        },
        updateQuery: updateQueryOnFetchMoreResult({
          isManaged
        }),
        fetchPolicy: 'network-only'
      });

      setPage(page + 1);
      // eslint-disable-next-line no-empty
    } catch {}
  }, [
    hasMore,
    fetchMore,
    page,
    pageSize,
    projectFilter,
    isManaged,
    overEstimatedTaskType,
    setPage
  ]);

  return {
    loadMore
  };
};
