import { DateTime } from 'luxon';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { dateToMidnightUTCString } from '../dates/convert';

export const myApprovalsSummaryQuery = gql`
  query Eager_GetMyApprovalsSummary($effectiveDate: DateTime) {
    me {
      id
      approvalsSummary: approvalsSummary2(effectiveDate: $effectiveDate) {
        awaitingTimesheets
        awaitingTimeOffs
        awaitingExpenses
        awaitingSellBacks
      }
    }
  }
`;

const useMyApprovalsSummaryQuery = () => {
  const effectiveDate = dateToMidnightUTCString(DateTime.local());
  const { data, loading } = useQuery(myApprovalsSummaryQuery, {
    variables: {
      effectiveDate
    },
    fetchPolicy: 'network-only'
  });

  const approvalsSummary = get(data, 'me.approvalsSummary', null);

  return {
    loading,
    approvalsSummary
  };
};

export default useMyApprovalsSummaryQuery;
