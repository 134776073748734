import { DateTime } from 'luxon';
import {
  getRangeEndDateByScale,
  getPeriodDateRangeByScale,
  getNextPeriodStartDateByScale
} from './periodDates';

export const getChartDates = ({ me, chartStartDate, chartEndDate, scale }) => {
  let start = DateTime.fromISO(chartStartDate, { setZone: true }).toUTC();
  const end = DateTime.fromISO(chartEndDate, { setZone: true }).toUTC();
  const rangeEndDate = getRangeEndDateByScale({ me, end, scale });

  const chartDates = [];

  while (start <= rangeEndDate) {
    chartDates.push(getPeriodDateRangeByScale({ me, start, scale }));
    start = getNextPeriodStartDateByScale({ me, start, scale });
  }

  return {
    chartDates
  };
};
