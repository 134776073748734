import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const projectsStatusCountSummaryQuery = gql`
  query Eager_GetProjectsStatusCountSummary(
    $isManaged: Boolean
    $projectFilter: ProjectFilter2
  ) {
    projectsStatusCountSummary(
      isManaged: $isManaged
      projectFilter: $projectFilter
    ) {
      initiate
      planning
      execution
      closed
      archived
      total
    }
  }
`;

export const useProjectsStatusCountSummaryQuery = ({
  skip,
  isManaged,
  projectFilter
}) => {
  const { data, loading, refetch } = useQuery(projectsStatusCountSummaryQuery, {
    variables: {
      isManaged,
      projectFilter
    },
    skip,
    fetchPolicy: 'cache-and-network'
  });

  const projectsStatusCountSummary = get(
    data,
    'projectsStatusCountSummary',
    {}
  );

  return {
    refetch,
    loading,
    projectsStatusCountSummary
  };
};

export default useProjectsStatusCountSummaryQuery;
