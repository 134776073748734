import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_USER_ROLES_QUERY = gql`
  query GetResourceUserRoles(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
  ) {
    resourceUsers2(page: $page, pagesize: $pagesize, filter: $filter) {
      id
      uri
      displayText
      roles {
        projectRole {
          id: uri
          displayText
        }
        isPrimary
      }
    }
  }
`;

export const useResourceUserRoles = ({ variables, skip }) => {
  const { loading, error, data } = useQuery(RESOURCE_USER_ROLES_QUERY, {
    variables,
    skip,
    fetchPolicy: 'cache-and-network'
  });

  return {
    loading,
    resourceUserRoles: data?.resourceUsers2[0]?.roles || [],
    error
  };
};

export default useResourceUserRoles;
