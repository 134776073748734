import React from 'react';
import PropTypes from 'prop-types';

const FooterWrapper = ({ children }) => {
  return <footer>{children}</footer>;
};

FooterWrapper.propTypes = {
  children: PropTypes.node
};

export default FooterWrapper;
