/* eslint-disable react/jsx-max-depth */
import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components/NoDataItem';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useMeContext } from '~/modules/me/useMeContext';
import GroupedColumnarTable from '~/modules/time-expense/components/GroupedColumnarTable';
import { withSettings } from '~/modules/time-expense/enhancers/useSettings';
import { useTimeAndExpenseData } from '~/modules/time-expense/enhancers/useTimeAndExpense';
import { useTimeAndExpensePaged } from '~/modules/time-expense/enhancers/useTimeAndExpensePaged';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  page: {
    display: 'inline-flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper
  },
  settings: {
    padding: theme.spacing(1, 0, 1, 2),
    backgroundColor: theme.palette.background.paper,
    display: 'inline-flex',
    justifyContent: 'space-between',
    position: 'sticky',
    left: 0,
    zIndex: 1000
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  noData: {
    margin: theme.spacing(3)
  }
}));

export const TimeAndExpenseDetails = ({
  intl,
  billingFilter,
  settings,
  onUpdateSettings
}) => {
  const { permissionsMap } = useMeContext();
  const canViewExpenseData = Boolean(
    permissionsMap['urn:replicon:combined-actuals-action:view-expense']
  );
  const noDataMessageId = canViewExpenseData
    ? 'timeAndExpenseTab.noDataForPeriod'
    : 'timeAndExpenseTab.noTimeDataForPeriod';
  const classes = useStyles();

  const {
    error,
    timeAndExpense,
    isLoading,
    fetchMore,
    timeAndExpenseInput
  } = useTimeAndExpenseData({
    billingFilter,
    settings
  });

  const { loadMore, onUpdate } = useTimeAndExpensePaged({
    fetchMore,
    onUpdate: onUpdateSettings,
    timeAndExpenseInput
  });

  const hasData = useMemo(
    () =>
      timeAndExpense?.rowTotals?.rows &&
      timeAndExpense.rowTotals.rows.length > 0,
    [timeAndExpense]
  );

  return (
    <>
      <div className={classes.settings}>
        <StickyHeader>
          <Toolbar
            intl={intl}
            settings={settings}
            onUpdate={onUpdate}
            billingFilter={billingFilter}
            disabled={isLoading}
            dataQeId="TimeAndExpense"
          />
        </StickyHeader>
      </div>
      <div className={classes.page}>
        <div className={classes.content}>
          {!error && !isLoading && (
            <GroupedColumnarTable
              intl={intl}
              settings={settings}
              records={timeAndExpense.records}
              hasMore={timeAndExpense.hasMore}
              loadMore={loadMore}
              isLoading={isLoading}
            />
          )}
          {!error && !isLoading && !hasData && (
            <div className={classes.noData}>
              <NoDataItem dataQeId="TimeAndExpense">
                <FormattedMessage id={noDataMessageId} />
              </NoDataItem>
            </div>
          )}
          {error && (
            <div className={classes.noData}>
              <NoDataItem>
                <FormattedMessage id="timeAndExpenseTab.error" />
              </NoDataItem>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

TimeAndExpenseDetails.propTypes = {
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object,
  billingFilter: PropTypes.object,
  onUpdateSettings: PropTypes.func
};

export default withSettings(TimeAndExpenseDetails);
