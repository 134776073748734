import React from 'react';
import PropTypes from 'prop-types';
import { ProjectRequestStatusBadge } from '../../ProjectRequestStatusBadge';

const StatusColumn = ({ record }) => {
  const { status } = record;

  return <ProjectRequestStatusBadge status={status} />;
};

StatusColumn.propTypes = {
  record: PropTypes.object.isRequired
};

export default StatusColumn;
