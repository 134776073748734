import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment
} from '~/modules/resourcing/common/fragments';
import { ResourceCostModeType } from '~/types';

export const ALLOCATIONS_QUERY = gql`
  query BulkGetAllocations(
    $filter: ResourceAllocationFilter
    $isResourceRoleCostModeEnabled: Boolean!
  ) {
    resourceAllocations(filter: $filter) {
      resourceAllocations {
        ...SpecificResourceAllocation
        role {
          uri
          id
          displayText
          currentRate @include(if: $isResourceRoleCostModeEnabled) {
            amount
            currency {
              id
              displayText
            }
          }
        }
        user {
          ...SpecificResourceAllocationUser
        }
      }
      nextPageCursor
    }
  }
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
`;

const useProjectResourceAllocationsByIds = ({
  allocationIds,
  resourceCostMode,
  skip = false
}) => {
  const { loading, data } = useQuery(ALLOCATIONS_QUERY, {
    variables: {
      filter: {
        resourceAllocationIds: allocationIds
      },
      isResourceRoleCostModeEnabled:
        ResourceCostModeType.Rolecost === resourceCostMode
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip
  });

  return {
    loadingAllocations: loading,
    resourceAllocations: data?.resourceAllocations?.resourceAllocations || []
  };
};

export default useProjectResourceAllocationsByIds;
