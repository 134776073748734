import React from 'react';
import PropTypes from 'prop-types';
import { useDialogState, useHasFeatureFlag } from '~/modules/common/hooks';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';

import ProjectStatusFilterButton from './ProjectStatusFilterButton';
import ProjectStatusFilterDialog from './ProjectStatusFilterDialog';
import useProjectStatusFilterState from './useProjectStatusFilterState';

export const ProjectStatusFilter = ({
  projectStatusFilterOption,
  setProjectStatusFilterOption
}) => {
  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const {
    open: isDialogOpen,
    openDialog: onDialogOpen,
    closeDialog: onDialogClose
  } = useDialogState();

  const {
    values,
    setFieldValue,
    submitForm,
    errors
  } = useProjectStatusFilterState({
    projectStatusFilterOption,
    onDialogClose,
    setProjectStatusFilterOption
  });

  return (
    <>
      <ProjectStatusFilterButton
        projectStatusFilterOption={projectStatusFilterOption}
        onClick={onDialogOpen}
        customStatusMapping={customStatusMapping}
        isProjectCustomStatusNamesLoading={isProjectCustomStatusNamesLoading}
      />
      {isDialogOpen && (
        <ProjectStatusFilterDialog
          onDialogClose={onDialogClose}
          isDialogOpen={isDialogOpen}
          values={values}
          projectStatusFilterOption={projectStatusFilterOption}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
          errors={errors}
          customStatusMapping={customStatusMapping}
          isProjectCustomStatusNamesLoading={isProjectCustomStatusNamesLoading}
        />
      )}
    </>
  );
};

ProjectStatusFilter.propTypes = {
  projectStatusFilterOption: PropTypes.object.isRequired,
  setProjectStatusFilterOption: PropTypes.func.isRequired
};

export default ProjectStatusFilter;
