import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { SearchBox } from '~/modules/common/components';
import {
  InvestmentScoreMetricFacet,
  RiskScoreMetricFacet,
  ValueScoreMetricFacet,
  TotalScoreFacet,
  ProjectRequestPortfolioFacet
} from '../../../common/components/SearchBox/Facets';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 2)
  }
}));

export const ProjectRequestSearchBox = ({
  hidePortfolioFacet,
  searchCriteria,
  setSearchCriteria
}) => {
  const classes = useStyles();

  const facets = useMemo(() => {
    return [
      ...(!hidePortfolioFacet ? [ProjectRequestPortfolioFacet] : []),
      TotalScoreFacet,
      ValueScoreMetricFacet,
      InvestmentScoreMetricFacet,
      RiskScoreMetricFacet
    ];
  }, [hidePortfolioFacet]);

  return (
    <div className={classes.root}>
      {setSearchCriteria ? (
        <SearchBox
          dataQeId="ProjectRequest-SearchBar"
          facets={facets}
          searchCriteria={searchCriteria}
          setSearchCriteria={setSearchCriteria}
        />
      ) : null}
    </div>
  );
};

ProjectRequestSearchBox.propTypes = {
  hidePortfolioFacet: PropTypes.bool,
  searchCriteria: PropTypes.object,
  setSearchCriteria: PropTypes.func
};

export default ProjectRequestSearchBox;
