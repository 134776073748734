import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  KPIIcon,
  ProgressIcon,
  ProfitIcon
} from '~/modules/common/components/Icons';
import {
  ChartCard,
  ProgressSummaryChartHelp
} from '~/modules/common/charts/dashboard';
import ProjectRiskSummary from '~/modules/project-risk-summary';
import { ProjectOverviewChart, ProjectProgressChart } from '../charts';
import { BudgetSummaryChart } from '../charts/BudgetChart';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  otherCards: {
    padding: theme.spacing(0, 1, 1, 1)
  },
  hoursChardCard: {
    padding: theme.spacing(1, 1, 0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 0, 1)
    }
  },
  item: {
    padding: theme.spacing(1)
  },
  overviewItem: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

const useChartCardStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const projectHoursSummaryTitleId = 'projectHoursSummary';
const progressChartTitle = <FormattedMessage id="projectPage.progress" />;
const progressChartAction = projectSummaryChartProps => (
  <ProgressSummaryChartHelp
    projectSummaryChartProps={projectSummaryChartProps}
  />
);

const icons = {
  progress: <ProgressIcon />,
  overview: <KPIIcon />,
  profit: <ProfitIcon />
};

export const ProjectInfoMDDownView = ({
  visibleCards,
  projectDetails,
  canViewBudgetChart,
  overViewChartDataProps,
  progressChartDataProps,
  canViewBillingData
}) => {
  const classes = useStyles();
  const chartClasses = useChartCardStyles();
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();

  const overviewChartTitle = useMemo(
    () => (
      <span id={projectHoursSummaryTitleId}>
        <FormattedMessage
          id={
            !canViewBillingData
              ? 'projectPage.projectCost'
              : isRolledUpTaskEstimateCalculationMethodEnabled
              ? 'projectPage.profitability'
              : 'projectPage.overview'
          }
        />{' '}
      </span>
    ),
    [canViewBillingData, isRolledUpTaskEstimateCalculationMethodEnabled]
  );

  const projectSummaryChartProps = useMemo(
    () => ({
      showCostContent: progressChartDataProps.showCostContent,
      projectProgressCardSettingsLoading:
        progressChartDataProps.projectProgressCardSettingsLoading,
      projectProgressView: progressChartDataProps.projectProgressView,
      saveProjectProgressCardSetting:
        progressChartDataProps.saveProjectProgressCardSetting
    }),
    [
      progressChartDataProps.showCostContent,
      progressChartDataProps.projectProgressCardSettingsLoading,
      progressChartDataProps.projectProgressView,
      progressChartDataProps.saveProjectProgressCardSetting
    ]
  );

  return (
    <Hidden lgUp>
      <Grid item xs={12} lg={8} className={classes.hoursChardCard}>
        {isRolledUpTaskEstimateCalculationMethodEnabled && (
          <Grid item xs={12} sm={12} lg={12}>
            <ChartCard
              chartTitle={progressChartTitle}
              classes={chartClasses}
              chartAvatar={icons.progress}
              chartDataProps={progressChartDataProps}
              ChartComponent={ProjectProgressChart}
              showEntireProjectScaleOption
              chartAction={progressChartAction(projectSummaryChartProps)}
            />
          </Grid>
        )}
        <ProjectRiskSummary
          projectUri={projectDetails.projectId}
          projectEndDate={projectDetails.endDate}
          classes={chartClasses}
        />
        <Grid item xs={12} sm={12} lg={12} className={classes.overviewItem}>
          <ChartCard
            chartTitle={overviewChartTitle}
            classes={chartClasses}
            chartAvatar={
              canViewBillingData &&
              isRolledUpTaskEstimateCalculationMethodEnabled
                ? icons.profit
                : icons.progress
            }
            chartDataProps={overViewChartDataProps}
            ChartComponent={ProjectOverviewChart}
            showPeriodFilter
            showScaleSelector
            showEntireProjectScaleOption
          />
        </Grid>
        {canViewBudgetChart && (
          <BudgetSummaryChart projectDetails={projectDetails} />
        )}
      </Grid>
      {visibleCards.length && (
        <>
          <Grid item xs={12} sm={6} className={classes.otherCards}>
            {visibleCards.filter((_, index) => index % 2 === 0)}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.otherCards}>
            {visibleCards.filter((_, index) => index % 2 === 1)}
          </Grid>
        </>
      )}
    </Hidden>
  );
};

ProjectInfoMDDownView.propTypes = {
  visibleCards: PropTypes.array.isRequired,
  projectDetails: PropTypes.object,
  canViewBudgetChart: PropTypes.bool,
  overViewChartDataProps: PropTypes.object,
  progressChartDataProps: PropTypes.object,
  canViewBillingData: PropTypes.bool
};

export default ProjectInfoMDDownView;
