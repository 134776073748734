import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  count: {
    fontWeight: theme.typography.fontWeightBold,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(5),
    marginRight: theme.spacing(2),
    maxWidth: theme.spacing(5),
    overflow: 'hidden'
  },
  title: {
    flexGrow: 1
  }
}));

export const PanelTitle = ({ classes: classesOverride, count, title }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      <Typography
        className={classes.count}
        data-qe-id={title?.props?.highlightMsgId || null}
        component="span"
        align="right"
        variant="body1"
      >
        {count}
      </Typography>
      <Typography className={classes.title} component="span" variant="body1">
        {title}
      </Typography>
    </div>
  );
};

PanelTitle.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  count: PropTypes.number
};

export default PanelTitle;
