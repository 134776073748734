import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { v4 } from 'uuid';
import { LoadingButton } from '~/modules/common/components';
import { CONTACT_TYPE } from '~/modules/common/enums';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  button: {
    '&.MuiGrid-item': {
      padding: theme.spacing(1, 0)
    }
  },
  addContact: {
    padding: theme.spacing(0.5, 1.5)
  }
}));

export const ContactInfoActionComponent = ({
  isReadOnly,
  isSubmitting,
  onClose,
  setShouldScrollToBottom
}) => {
  const classes = useStyles();
  const {
    values: { contacts },
    isValid,
    resetForm,
    setFieldValue,
    handleSubmit
  } = useFormikContext();

  const isMobile = useIsBreakpointDown('sm');

  const onAddContact = useCallback(() => {
    setFieldValue('contacts', [
      ...(contacts || []),
      {
        id: v4(),
        name: '',
        email: '',
        phoneNumber: '',
        faxNumber: '',
        website: '',
        contactType: CONTACT_TYPE.OTHERS
      }
    ]);
    isMobile && setShouldScrollToBottom(true);
  }, [contacts, isMobile, setFieldValue, setShouldScrollToBottom]);

  const onCancel = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item className={classes.button}>
        {!isReadOnly && (
          <Button
            color="primary"
            onClick={onAddContact}
            disabled={contacts?.length >= 5 || isSubmitting}
            className={classes.addContact}
            data-qe-id="AddContact"
          >
            <FormattedMessage id="projectBasicInfoCard.contactInfo.actions.addContact" />
          </Button>
        )}
      </Grid>
      <Grid item className={classes.button}>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              onClick={onCancel}
              data-qe-id="Cancel"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id={isReadOnly ? 'button.close' : 'button.cancel'}
              />
            </Button>
          </Grid>
          {!isReadOnly && (
            <Grid item>
              <LoadingButton
                color="primary"
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="button.save" />
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ContactInfoActionComponent.propTypes = {
  isReadOnly: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  setShouldScrollToBottom: PropTypes.func
};

export default ContactInfoActionComponent;
