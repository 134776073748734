import { abbreviatedDecimalValue } from '~/modules/common/charts/dashboard';

const getBarProps = ({ theme, highestValue, barColor, ...rest }) => {
  return {
    ...rest,
    style: {
      data: {
        fill: barColor
      },
      labels: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 15,
        fontWeight: 500,
        fill: props => {
          if (highestValue) {
            const unitBarHeight = props.height / highestValue;
            const barHeight = Math.abs(props.datum.y) * unitBarHeight;

            // Prevents label overlap with axis
            if (barHeight <= props.dx + 70) {
              return 'none';
            }
          }

          return theme.palette.getContrastText(theme.palette.success.main);
        }
      }
    }
  };
};

export default (intl, theme, highestValue, currencySymbol) => ({
  totalForecasted: getBarProps({
    theme,
    barColor: theme.palette.grey[300],
    barWidth: 60,
    cornerRadius: { top: 5, bottom: 5 },
    highestValue
  }),
  forecasted: getBarProps({
    theme,
    barColor: '#C6ECFC',
    barWidth: 60,
    cornerRadius: { top: 5, bottom: 5 },
    highestValue
  }),
  recognized: getBarProps({
    theme,
    barColor: '#2FC9CB',
    barWidth: 40,
    cornerRadius: 0,
    highestValue
  }),
  group: {
    height: 60,
    padding: 0,
    offset: 0
  },
  axis: {
    padding: {
      left: 8 + currencySymbol.length * 4,
      right:
        8 +
        currencySymbol.length * 4 +
        abbreviatedDecimalValue(intl, Math.abs(highestValue)).length * 4
    },
    style: {
      axis: { stroke: 'none' },
      tickLabels: {
        fontSize: 15,
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily
      }
    },
    height: theme.spacing(3),
    offsetY: 30
  }
});
