import { makeStyles, Chip } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ResourceRequestStatus } from '~/types';
import { getStatusBasedClassName } from '~/modules/resourcing/common/enums';
import { getStatusBasedMessageKey } from '~/modules/resourcing/common/components/ResourceRequestStatusIndicator';
import { getResourceRequestStatusStyles } from '~/modules/resourcing/common/util/status-color-util';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    ...theme.typography.caption,
    height: theme.spacing(3),
    borderRadius: theme.spacing(2)
  },
  label: {
    padding: theme.spacing(0, 1.5)
  },
  ...getResourceRequestStatusStyles(theme)
}));

export const ResourceRequestStatusChip = ({
  qeIdTag,
  status = ResourceRequestStatus.Draft,
  classes: classesOverride
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride });

  const cssClasses = useMemo(
    () => ({
      label: classes.label,
      root: classNames(
        classes.root,
        classes[getStatusBasedClassName(status, 'root')]
      )
    }),
    [classes, status]
  );

  return (
    <Chip
      data-qe-id={
        qeIdTag
          ? `ResourceRequestStatusChip_${qeIdTag}`
          : 'ResourceRequestStatusChip'
      }
      label={intl.formatMessage({
        id: getStatusBasedMessageKey(status)
      })}
      size="medium"
      classes={cssClasses}
    />
  );
};
ResourceRequestStatusChip.propTypes = {
  status: PropTypes.string,
  classes: PropTypes.object,
  qeIdTag: PropTypes.string
};
export default ResourceRequestStatusChip;
