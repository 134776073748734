import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';

export default ({ scale, rowData: { series = {}, summary = {} } = {} }) => {
  const isAmountSeries = series && series.currency;

  let seriesDataPoints = [];

  if (series) {
    if (Array.isArray(series)) seriesDataPoints = series;
    const { dataPoints } = series;

    if (Array.isArray(dataPoints)) seriesDataPoints = dataPoints;
  }

  const dataPointMap = seriesDataPoints.reduce(
    (retVal, dataPoint) => ({
      ...retVal,
      [`${scale}-${mapRepliconDateToUtcObject(
        dataPoint.period.periodStart
      ).toISODate()}`]: dataPoint[isAmountSeries ? 'amount' : 'hours']
    }),
    {}
  );

  return {
    seriesDataMap: {
      ...dataPointMap,
      total: summary
        ? isAmountSeries
          ? summary.amount && summary.amount.amount
          : summary.hours
        : undefined,
      currencySymbol: isAmountSeries ? series.currency.displayText : undefined
    }
  };
};
