import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PeriodSelector, PeriodDropdown } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    padding: theme.spacing(1.5, 2, 1, 0),
    top: theme.spacing(6) + 1,
    left: 0,
    zIndex: 1
  },
  grow: {
    flexGrow: 1
  },
  date: {
    fontSize: theme.typography.body2.fontSize
  },
  periodDropdown: {
    height: theme.spacing(4)
  }
}));

export const ProjectTimelineToolbar = ({
  onPreviousClick,
  onNextClick,
  dateRange,
  scale,
  onScaleChange
}) => {
  const classes = useStyles();
  const periodSelectorClasses = useMemo(
    () => ({
      date: classes.date
    }),
    [classes.date]
  );
  const periodDropdownClasses = useMemo(
    () => ({
      formControl: classes.periodDropdown
    }),
    [classes.periodDropdown]
  );

  return (
    <Paper className={classes.root} elevation={0} square>
      <Grid container alignItems="center">
        <Grid className={classes.grow} item>
          <PeriodSelector
            classes={periodSelectorClasses}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            dateRange={dateRange}
          />
        </Grid>
        <Grid item>
          <PeriodDropdown
            classes={periodDropdownClasses}
            value={scale}
            onChange={onScaleChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

ProjectTimelineToolbar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  dateRange: PropTypes.object,
  scale: PropTypes.string,
  onScaleChange: PropTypes.func
};

export default ProjectTimelineToolbar;
