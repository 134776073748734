import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import DateField from '~/modules/common/components/DateField';

const useStyles = makeStyles(theme => ({
  dateFieldContainer: {
    flexGrow: 1,
    '& input': {
      cursor: 'pointer'
    }
  }
}));

const DateRangeFields = ({
  className,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-evenly"
      className={className}
      spacing={2}
    >
      <Grid item className={classes.dateFieldContainer}>
        <DateField
          variant="filled"
          data-qe-id="AddProjectStartDate"
          label={intl.formatMessage({ id: 'addProject.startDate' })}
          ariaLabel={intl.formatMessage({ id: 'addProject.startDate' })}
          value={startDate}
          onChange={onStartDateChange}
          editable
        />
      </Grid>
      <Grid item className={classes.dateFieldContainer}>
        <DateField
          variant="filled"
          data-qe-id="AddProjectEndDate"
          label={intl.formatMessage({ id: 'addProject.endDate' })}
          ariaLabel={intl.formatMessage({ id: 'addProject.endDate' })}
          value={endDate}
          onChange={onEndDateChange}
          editable
        />
      </Grid>
    </Grid>
  );
};

DateRangeFields.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired
};

export default DateRangeFields;
