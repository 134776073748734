import React, { memo, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { TableHead } from '@material-ui/core';
import ListTableVariantContext from '../../../ListTableVariantContext';

const TableHeader = ({ children, ...rest }) => {
  const variant = useContext(ListTableVariantContext);

  const Component = variant === 'table' ? TableHead : 'div';

  return <Component {...rest}>{children}</Component>;
};

TableHeader.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any
};

export default memo(TableHeader);
