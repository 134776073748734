import { useState, useRef, useCallback } from 'react';

const useScrollToBottom = () => {
  const containerRef = useRef(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const scrollToBottom = useCallback(() => {
    if (shouldScrollToBottom && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  return {
    containerRef,
    shouldScrollToBottom,
    setShouldScrollToBottom,
    scrollToBottom
  };
};

export default useScrollToBottom;
