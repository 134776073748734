import { makeStyles } from '@material-ui/core/styles';
import {
  yearWidth,
  quarterWidth,
  monthWidth,
  weekWidth,
  dayWidth,
  totalWidth
} from '../../calculations';

export const yearGradient = (
  backgroundColor,
  monthDividerColor,
  dividerColor
) => `repeating-linear-gradient(
  90deg,
  ${backgroundColor},

  ${backgroundColor} ${yearWidth / 12 - 1}px,
  ${monthDividerColor} ${yearWidth / 12}px,
  ${backgroundColor} ${yearWidth / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 2) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 2) / 12}px,
  ${backgroundColor} ${(yearWidth * 2) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 3) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 3) / 12}px,
  ${backgroundColor} ${(yearWidth * 3) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 4) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 4) / 12}px,
  ${backgroundColor} ${(yearWidth * 4) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 5) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 5) / 12}px,
  ${backgroundColor} ${(yearWidth * 5) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 6) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 6) / 12}px,
  ${backgroundColor} ${(yearWidth * 6) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 7) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 7) / 12}px,
  ${backgroundColor} ${(yearWidth * 7) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 8) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 8) / 12}px,
  ${backgroundColor} ${(yearWidth * 8) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 9) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 9) / 12}px,
  ${backgroundColor} ${(yearWidth * 9) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 10) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 10) / 12}px,
  ${backgroundColor} ${(yearWidth * 10) / 12 + 1}px,

  ${backgroundColor} ${(yearWidth * 11) / 12 - 1}px,
  ${monthDividerColor} ${(yearWidth * 11) / 12}px,
  ${backgroundColor} ${(yearWidth * 11) / 12 + 1}px,

  ${backgroundColor} ${yearWidth - 2}px,
  ${dividerColor} ${yearWidth - 1}px,
  ${dividerColor} ${yearWidth}px,

  ${monthDividerColor} ${quarterWidth - 1 / 3}px)`;

export const quarterGradient = (
  backgroundColor,
  monthDividerColor,
  dividerColor
) => `repeating-linear-gradient(
  90deg,
  ${backgroundColor},
  ${backgroundColor} ${quarterWidth / 3 - 1}px,
  ${monthDividerColor} ${quarterWidth / 3}px,
  ${backgroundColor} ${quarterWidth / 3 + 1}px,
  ${backgroundColor} ${(quarterWidth * 2) / 3 - 1}px,
  ${monthDividerColor} ${(quarterWidth * 2) / 3}px,
  ${backgroundColor} ${(quarterWidth * 2) / 3 + 1}px,
  ${backgroundColor} ${quarterWidth - 2}px,
  ${dividerColor} ${quarterWidth - 1}px,
  ${dividerColor} ${quarterWidth}px,

  ${monthDividerColor} ${quarterWidth - 1 / 3}px)`;

export const monthGradient = (
  backgroundColor,
  dividerColor
) => `repeating-linear-gradient(
        90deg,
        ${backgroundColor},
        ${backgroundColor} ${monthWidth - 1}px,
        ${dividerColor} ${monthWidth - 1}px,
        ${dividerColor} ${monthWidth}px)`;

export const weekGradient = (
  backgroundColor,
  dayOffColor,
  dividerColor
) => `repeating-linear-gradient(
        90deg,
        ${dividerColor},
        ${dividerColor} 0.5px,
        ${dayOffColor} 0.5px,
        ${dayOffColor} ${weekWidth / 7}px,
        ${backgroundColor} ${weekWidth / 7}px,
        ${backgroundColor} ${(weekWidth * 6) / 7}px,
        ${dayOffColor} ${(weekWidth * 6) / 7}px,
        ${dayOffColor} ${weekWidth - 0.5}px,
        ${dividerColor} ${weekWidth - 0.5}px,
        ${dividerColor} ${weekWidth}px)`;

export const dayGradient = (
  backgroundColor,
  dayOffColor,
  dayDividerColor,
  weekDividerColor
) => `repeating-linear-gradient(
        90deg,
        ${backgroundColor},
        ${backgroundColor} ${dayWidth - 1}px,
        ${dayDividerColor} ${dayWidth - 1}px,

        ${dayDividerColor} ${dayWidth}px,
        ${backgroundColor} ${dayWidth}px,
        ${backgroundColor} ${dayWidth * 2 - 1}px,
        ${dayDividerColor} ${dayWidth * 2 - 1}px,

        ${dayDividerColor} ${dayWidth * 2}px,
        ${backgroundColor} ${dayWidth * 2}px,
        ${backgroundColor} ${dayWidth * 3 - 1}px,
        ${dayDividerColor} ${dayWidth * 3 - 1}px,

        ${dayDividerColor} ${dayWidth * 3}px,
        ${backgroundColor} ${dayWidth * 3}px,
        ${backgroundColor} ${dayWidth * 4 - 1}px,
        ${dayDividerColor} ${dayWidth * 4 - 1}px,

        ${dayDividerColor} ${dayWidth * 4}px,
        ${backgroundColor} ${dayWidth * 4}px,
        ${backgroundColor} ${dayWidth * 5 - 1}px,
        ${dayDividerColor} ${dayWidth * 5 - 1}px,

        ${dayDividerColor} ${dayWidth * 5}px,
        ${dayOffColor} ${dayWidth * 5}px,
        ${dayOffColor} ${dayWidth * 6 - 1}px,
        ${weekDividerColor} ${dayWidth * 6 - 1}px,

        ${weekDividerColor} ${dayWidth * 6}px,
        ${dayOffColor} ${dayWidth * 6}px,
        ${dayOffColor} ${dayWidth * 7 - 1}px,
        ${dayDividerColor} ${dayWidth * 7 - 1}px,
        ${dayDividerColor} ${dayWidth * 7}px)`;

export default makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },

  itemContainer: {
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    lineHeight: `${theme.spacing(3)}px`,
    position: 'sticky',
    left: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  timeContainer: {
    flexGrow: 0,
    flexShrink: 0,
    position: 'relative',
    minHeight: theme.spacing(5),
    overflow: 'hidden'
  },
  timeContainerYear: {
    background: yearGradient(
      theme.palette.background.paper,
      theme.palette.grey[100],
      theme.palette.grey[200]
    )
  },
  timeContainerQuarter: {
    background: quarterGradient(
      theme.palette.background.paper,
      theme.palette.grey[100],
      theme.palette.grey[200]
    )
  },
  timeContainerMonth: {
    background: monthGradient(
      theme.palette.background.paper,
      theme.palette.grey[200]
    )
  },
  timeContainerFixedRange: {
    overflow: 'hidden'
  },
  timeContainerWeek: {
    background: weekGradient(
      theme.palette.background.paper,
      theme.palette.grey[50],
      theme.palette.grey[200]
    )
  },
  timeContainerDay: {
    background: dayGradient(
      theme.palette.background.paper,
      theme.palette.grey[50],
      theme.palette.grey[200],
      theme.palette.grey[300]
    )
  },

  timeContainerDay1: {
    backgroundPositionX: 0
  },
  timeContainerDay2: {
    backgroundPositionX: -dayWidth
  },
  timeContainerDay3: {
    backgroundPositionX: -dayWidth * 2
  },
  timeContainerDay4: {
    backgroundPositionX: -dayWidth * 3
  },
  timeContainerDay5: {
    backgroundPositionX: -dayWidth * 4
  },
  timeContainerDay6: {
    backgroundPositionX: -dayWidth * 5
  },
  timeContainerDay7: {
    backgroundPositionX: -dayWidth * 6
  },
  totalContainer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: totalWidth,
    width: totalWidth,
    padding: theme.spacing(0, 1, 0, 0),
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.divider}`,
    lineHeight: `${theme.spacing(3)}px`,
    position: 'sticky',
    right: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}));
