import React from 'react';
import { Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export const FormattedAmountHeader = ({
  classes,
  title,
  displaySubTitle,
  subTitle,
  subTitleTooltip
}) => (
  <>
    <div>{title}</div>
    {displaySubTitle &&
      (subTitleTooltip ? (
        <Tooltip title={subTitleTooltip}>
          <div className={classes.subTitle}>{subTitle}</div>
        </Tooltip>
      ) : (
        <div className={classes.subTitle}>{subTitle}</div>
      ))}
  </>
);
FormattedAmountHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  displaySubTitle: PropTypes.bool,
  subTitle: PropTypes.object,
  subTitleTooltip: PropTypes.object
};
