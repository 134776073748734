import React from 'react';
import { useIntl } from 'react-intl';
import { SimpleDropdown } from '~/modules/common/components';
import { getProjectTypes } from '~/modules/projects/projectTypes';

export default props => {
  const { formatMessage } = useIntl();
  const options = getProjectTypes(formatMessage);

  return (
    <SimpleDropdown
      {...props}
      options={options}
      label={formatMessage({ id: 'projectTypes.title' })}
    />
  );
};
