import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BULK_UNASSIGN_RESOURCE_POOLS_TO_USER = gql`
  mutation bulkAssignResourcePoolsToUser(
    $input: BulkUpdateAvailableResourcePoolsForUserInput!
  ) {
    bulkUnassignResourcePoolsToUser(input: $input)
  }
`;

export const BULK_ASSIGN_RESOURCE_POOLS_TO_USER = gql`
  mutation bulkAssignResourcePoolsToUser(
    $input: BulkUpdateAvailableResourcePoolsForUserInput!
  ) {
    bulkAssignResourcePoolsToUser(input: $input)
  }
`;

export const useBulkUnassignResourcePoolsToUser = () => {
  const [bulkUnassignResourcePoolsToUser] = useMutation(
    BULK_UNASSIGN_RESOURCE_POOLS_TO_USER
  );

  return {
    bulkUnassignResourcePoolsToUser: ({ userUri, resourcePoolUris }) =>
      bulkUnassignResourcePoolsToUser({
        variables: {
          input: {
            userUri,
            resourcePoolUris
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'getCountOfAssignedResourcePoolsForUser',
          'getPageOfAssignedResourcePoolsForUser',
          'getPageOfResourcePoolsAvailableForUser'
        ]
      })
  };
};

export const useBulkAssignResourcePoolsToUser = () => {
  const [bulkAssignResourcePoolsToUser] = useMutation(
    BULK_ASSIGN_RESOURCE_POOLS_TO_USER
  );

  return {
    bulkAssignResourcePoolsToUser: ({ userUri, resourcePoolUris }) =>
      bulkAssignResourcePoolsToUser({
        variables: {
          input: {
            userUri,
            resourcePoolUris
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'getCountOfAssignedResourcePoolsForUser',
          'getPageOfAssignedResourcePoolsForUser',
          'getPageOfResourcePoolsAvailableForUser'
        ]
      })
  };
};

export default {
  useBulkAssignResourcePoolsToUser,
  useBulkUnassignResourcePoolsToUser
};
