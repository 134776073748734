import { useSearchableEmployeeTypes } from '~/modules/common/enhancers';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { employeeTypeTag } from './tag';
import EmployeeTypeFacetDetails from './EmployeeTypeFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableEmployeeTypes);

export default {
  tag: employeeTypeTag,
  makeCriteriaProvider,
  DetailsControl: EmployeeTypeFacetDetails
};
