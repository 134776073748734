import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  FormControl,
  MenuItem,
  OutlinedInput
} from '@material-ui/core';
import { ResourcePlanGroupBy } from '~/types';

const useFormStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 0, 0, 2)
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0
  },
  select: {
    fontSize: theme.typography.body2.fontSize,
    minHeight: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1, 3, 1, 1.5),
    color: theme.palette.text.secondary
  }
}));

export const getRenderedValues = selectedValue => ({
  groupType:
    selectedValue === ResourcePlanGroupBy.Project ? (
      <FormattedMessage id="resourcePlanGroupDropdown.project" />
    ) : (
      <FormattedMessage id="resourcePlanGroupDropdown.role" />
    )
});

const outlinedInput = <OutlinedInput labelWidth={0} />;

export const ResourcePlanGroupDropdown = ({
  classes: classesOverrides,
  value,
  onChange,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const formClasses = useFormStyles();
  const intl = useIntl();

  const handleChange = useCallback(
    event => {
      event.target.value && onChange(event.target.value);
    },
    [onChange]
  );

  const renderValue = useCallback(selectedValue => {
    const groupValues = getRenderedValues(selectedValue);

    return (
      <FormattedMessage
        id="resourcePlanGroupDropdown.groupBy"
        values={groupValues}
      />
    );
  }, []);

  return (
    <FormControl className={formClasses.formControl}>
      <Select
        value={value}
        renderValue={renderValue}
        input={outlinedInput}
        onChange={handleChange}
        classes={classes}
        MenuProps={useMemo(
          () => ({
            MenuListProps: {
              dense: true,
              'aria-label': intl.formatMessage({
                id: 'resourcePlanGroupDropdown.groupBySelect'
              })
            }
          }),
          [intl]
        )}
        {...rest}
      >
        <MenuItem value={ResourcePlanGroupBy.Project}>
          <FormattedMessage id="resourcePlanGroupDropdown.project" />
        </MenuItem>
        <MenuItem value={ResourcePlanGroupBy.Role}>
          <FormattedMessage id="resourcePlanGroupDropdown.role" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

ResourcePlanGroupDropdown.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ResourcePlanGroupDropdown;
