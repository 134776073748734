import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Table, TableBody } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';
import NoDataItem from '~/modules/common/components/NoDataItem';
import UserDropdown from '~/modules/common/components/UserDropdown';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { PreferredResourceTableRow } from './components';
import { usePreferredResourceHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  noPreferredResource: { paddingTop: theme.spacing(2) },
  error: { color: theme.palette.error.main },
  table: { marginTop: theme.spacing(1) }
}));

const useSkeletonStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(1)
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));

const getOptionLabel = option => option.displayText || '';

export const UserProfileSkeleton = () => {
  const classes = useSkeletonStyles();

  return (
    <Grid container direction="row" className={classes.spacing}>
      <Grid item>
        <Skeleton variant="circle" height={40} width={40} />
      </Grid>
      <Grid item className={classes.grid}>
        <Skeleton variant="rect" height={15} width={580} />
        <Skeleton variant="rect" height={15} width={500} />
      </Grid>
    </Grid>
  );
};

export const PreferredResourcesInfo = ({
  preferredResources,
  setFieldValue,
  isRequired,
  role,
  roleRate,
  skills,
  resourceCostMode,
  todayForUser
}) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const { formatMessage } = useIntl();
  const removeIconAriaLabel = formatMessage({ id: 'button.delete' });
  const [selectedOptions, setSelectedOptions] = useState(preferredResources);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(null);

  const { onResourceSelected, onRemove } = usePreferredResourceHandlers({
    setSelectedOptions,
    setFieldValue,
    setLoading,
    preferredResources,
    role,
    roleRate,
    skills,
    apolloClient,
    resourceCostMode,
    todayForUser,
    setKey
  });

  const getOptionDisabled = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ||
      selectedOptions.some(v => v.id === option.id),
    [selectedOptions]
  );

  return (
    <>
      <UserDropdown
        key={key}
        onChange={onResourceSelected}
        label={formatMessage({
          id: 'resourceRequestDrawerDetails.addPreferredResource'
        })}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        disableClearable
        userAccessRoleUri={USER_ACCESS_ROLE.PROJECT_RESOURCE}
      />
      {loading ? (
        <UserProfileSkeleton />
      ) : preferredResources.length ? (
        <Table size="small" className={classes.table}>
          <TableBody>
            {preferredResources.map(resource => (
              <PreferredResourceTableRow
                key={resource.id}
                resource={resource}
                onRemove={onRemove}
                removeIconAriaLabel={removeIconAriaLabel}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.noPreferredResource}>
          <NoDataItem>
            <span
              className={classNames({
                [classes.error]: isRequired
              })}
            >
              <FormattedMessage id="resourceRequestDrawerDetails.noPreferredResources" />
            </span>
          </NoDataItem>
        </div>
      )}
    </>
  );
};

PreferredResourcesInfo.propTypes = {
  preferredResources: PropTypes.array,
  setFieldValue: PropTypes.func,
  isRequired: PropTypes.bool,
  role: PropTypes.object,
  roleRate: PropTypes.number,
  skills: PropTypes.array,
  resourceCostMode: PropTypes.string,
  todayForUser: PropTypes.object
};

export default PreferredResourcesInfo;
