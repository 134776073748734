import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const UPDATE_REFERENCE_NUMBER_SETTING = gql`
  mutation updateReferenceNumberSetting(
    $transactionType: BillingTransactionType!
    $referenceNumberSetting: ReferenceNumberSettingInput!
  ) {
    updateTransactionReferenceNumberSetting(
      transactionType: $transactionType
      referenceNumberSetting: $referenceNumberSetting
    )
  }
`;

export const useUpdateReferenceNumberSetting = transactionType => {
  const [updateReferenceNumberSetting] = useMutation(
    UPDATE_REFERENCE_NUMBER_SETTING
  );

  return {
    updateReferenceNumberSetting: referenceNumberSetting => {
      return updateReferenceNumberSetting({
        variables: {
          transactionType,
          referenceNumberSetting
        },
        refetchQueries: [
          'getReferenceNumberSetting',
          'getNextReferenceNumberSetting'
        ]
      });
    }
  };
};
export default useUpdateReferenceNumberSetting;
