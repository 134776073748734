import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  FormControl,
  MenuItem,
  OutlinedInput
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { OVERVIEW_UNITS_ENUM } from '~/modules/resource-management/common/enums';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1, 0, 0, 2)
  },
  root: {
    margin: 0
  },
  select: {
    fontSize: theme.typography.body2.fontSize,
    minHeight: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1, 3, 1, 1.5),
    color: theme.palette.text.secondary
  }
}));

const outlinedInput = <OutlinedInput labelWidth={0} />;
const menuProps = {
  MenuListProps: {
    dense: true,
    'aria-label': <FormattedMessage id="lineItemsTable.units" />
  }
};

const unitOptions = {
  hours: {
    value: OVERVIEW_UNITS_ENUM.HOURS,
    label: 'resourcingOverviewChart.hours',
    dataQeId: 'hoursOption',
    visible: true
  },
  percentage: {
    value: OVERVIEW_UNITS_ENUM.PERCENTAGE,
    label: 'resourcingOverviewChart.percentage',
    dataQeId: 'percentageOption',
    visible: true
  },
  fte: {
    value: OVERVIEW_UNITS_ENUM.FTE,
    label: 'resourcingOverviewChart.resourcesFTE',
    dataQeId: 'resourcesOption',
    visible: true
  }
};

export const ResourcingOverviewUnitDropDown = ({
  classes: classesOverride,
  value,
  onChange,
  showPercentage = true,
  showFTE = true,
  showHours = true
}) => {
  const classes = useStyles({ classes: classesOverride });
  const handleChange = useCallback(
    event => {
      event.target.value && onChange(event.target.value);
    },
    [onChange]
  );

  const options = [
    ...(showHours ? [unitOptions.hours] : []),
    ...(showPercentage ? [unitOptions.percentage] : []),
    ...(showFTE ? [unitOptions.fte] : [])
  ];

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        input={outlinedInput}
        data-qe-id="OverviewUnitDropdown"
        onChange={handleChange}
        classes={useMemo(
          () => ({ root: classes.root, select: classes.select }),
          [classes.root, classes.select]
        )}
        MenuProps={menuProps}
      >
        {options.map(({ value: menuValue, label, dataQeId }) => (
          <MenuItem key={label} value={menuValue} data-qe-id={dataQeId}>
            <FormattedMessage id={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ResourcingOverviewUnitDropDown.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showPercentage: PropTypes.bool,
  showFTE: PropTypes.bool,
  showHours: PropTypes.bool
};

export default ResourcingOverviewUnitDropDown;
