import React from 'react';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ProjectRequestStatusType } from '~/types';
import { ProjectRequestStatusBadgeWithProgress } from './ProjectRequestStatusBadgeWithProgress';

const projectRequestLabel = {
  NEW: 'projectRequestStatus.statusLabel.new',
  IN_REVIEW: 'projectRequestStatus.statusLabel.inReview',
  APPROVED: 'projectRequestStatus.statusLabel.approved',
  REJECTED: 'projectRequestStatus.statusLabel.rejected'
};

export const getStatusStyles = statusType => ({
  backgroundColor: theme.palette.projectRequestStatus[statusType],
  color:
    statusType === ProjectRequestStatusType.Rejected
      ? theme.palette.common.white
      : theme.palette.common.black
});

export const getStatusLabel = statusType => projectRequestLabel[statusType];

const chipLabel = statusType => (
  <FormattedMessage id={getStatusLabel(statusType)} />
);

export const ProjectRequestStatusBadge = ({
  status,
  projectRequestGates = []
}) => {
  const statusType = status?.type;

  return (
    <>
      {statusType === ProjectRequestStatusType.InReview &&
      projectRequestGates.length ? (
        <ProjectRequestStatusBadgeWithProgress
          projectRequestGates={projectRequestGates}
        />
      ) : (
        Boolean(statusType) && (
          <Chip
            style={getStatusStyles(statusType)}
            label={chipLabel(statusType)}
            data-qe-id="ProjectRequestStatus"
          />
        )
      )}
    </>
  );
};

ProjectRequestStatusBadge.propTypes = {
  status: PropTypes.object,
  projectRequestGates: PropTypes.array
};

export default ProjectRequestStatusBadge;
