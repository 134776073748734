import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { PROJECT_STATUS } from '~/modules/common/enums';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';
import { ChartLegends, PillChart } from '~/modules/common/charts/dashboard';
import useProjectStatusChartData from './useProjectStatusChartData';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2)
  },
  chartLegends: {
    paddingRight: theme.spacing(3)
  },
  title: {
    ...theme.typography.h6,
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.secondary
  }
}));

export const ProjectStatusChart = ({
  classes,
  overEstimated,
  onTrack,
  total
}) => {
  const cls = useStyles({ classes });
  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });
  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const { data, legends } = useProjectStatusChartData({
    overEstimated,
    onTrack
  });

  const totalProjectsValues = useMemo(
    () => ({
      count: total
    }),
    [total]
  );

  return (
    <div className={cls.root}>
      <Typography className={cls.title}>
        {isCustomStatusNameEnabled ? (
          <FormattedMessage
            id="clientsOverview.projects.executionCustomStatus"
            values={{
              count: total,
              status: customStatusMapping[PROJECT_STATUS.EXECUTION]
            }}
          />
        ) : (
          <FormattedMessage
            id="clientsOverview.projects.projectsInExecution"
            values={totalProjectsValues}
          />
        )}
      </Typography>
      <ChartLegends className={cls.chartLegends} legends={legends} />
      <PillChart segments={data} />
    </div>
  );
};

ProjectStatusChart.propTypes = {
  classes: PropTypes.object,
  overEstimated: PropTypes.number,
  onTrack: PropTypes.number,
  total: PropTypes.number
};

export default ProjectStatusChart;
