import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Chip, TextField, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { User } from '~/modules/common/components';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';

const useStyles = makeStyles(theme => ({
  hiddenLabel: {
    '&>div': {
      paddingTop: '0px!important',
      paddingLeft: '0px!important'
    }
  },
  hiddenLabelInput: {
    '&>input.MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
      padding: '10px 12px 11px !important'
    }
  },
  chip: {
    backgroundColor: theme.palette.grey[400]
  },
  avatar: {
    color: 'inherit'
  },
  popper: ({ current }) => ({
    [theme.breakpoints.up('sm')]: theme.direction === 'rtl' && {
      position: 'absolute',
      left: current?.offsetLeft ? current?.offsetLeft - theme.spacing(82) : 0,
      top: current?.offsetTop
    }
  })
}));

const getOptionLabelInternal = option => option.displayText;

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : (
    <User user={option} disablePadding />
  );

const getOptionDisabledInternal = option =>
  !option.id || option.id === MORE_AVAILABLE_OPTION_ID;

export const SearchableUserDropDown = ({
  value,
  onChange,
  handleInputChange,
  loading,
  options,
  multiple,
  label,
  ariaLabel = '',
  hasError,
  helperText,
  noOptionsText,
  autoFocus,
  optionTypeText,
  hasMore,
  getOptionDisabled,
  getOptionLabel,
  disableClearable,
  ...otherProps
}) => {
  const ref = useRef();
  const classes = useStyles({ current: ref?.current });

  const renderInput = useCallback(
    ({ InputProps, className, inputProps, ...rest }) => {
      const acInputProps = {
        ...InputProps,
        className: classNames(InputProps.className, {
          [classes.hiddenLabelInput]: !label
        }),
        inputProps: {
          ...inputProps,
          'aria-label': ariaLabel
        }
      };

      return (
        <TextField
          {...rest}
          className={classNames(className, {
            [classes.hiddenLabel]: !label
          })}
          label={label}
          InputProps={acInputProps}
          hiddenLabel={!label}
          autoFocus={autoFocus}
        />
      );
    },
    [label, classes.hiddenLabel, ariaLabel, classes.hiddenLabelInput, autoFocus]
  );

  const chipClasses = useMemo(() => ({ root: classes.chip }), [classes.chip]);

  const renderAvatar = useCallback(
    option => <UserAvatar className={classes.avatar} user={option} />,
    [classes.avatar]
  );

  const renderTags = useCallback(
    (values, getTagProps) =>
      values.map((option, index) => (
        <Chip
          classes={chipClasses}
          key={option.id}
          size="small"
          avatar={renderAvatar(option)}
          label={option.displayText}
          {...getTagProps({ index })}
        />
      )),
    [renderAvatar, chipClasses]
  );

  return (
    <>
      <SimpleAutocomplete
        ref={ref}
        loading={loading}
        options={options}
        size="small"
        noOptionsText={noOptionsText}
        fullWidth
        value={value}
        multiple={multiple}
        getOptionSelected={getOptionSelected}
        onChange={onChange}
        onInputChange={handleInputChange}
        getOptionLabel={getOptionLabel || getOptionLabelInternal}
        renderInput={renderInput}
        renderOption={renderOption}
        renderTags={renderTags}
        classes={{ popper: classes.popper }}
        getOptionDisabled={getOptionDisabled || getOptionDisabledInternal}
        optionTypeText={optionTypeText}
        hasMore={hasMore}
        disableClearable={disableClearable}
        {...otherProps}
      />
      {helperText && (
        <FormHelperText error={hasError}>{helperText}</FormHelperText>
      )}
    </>
  );
};

SearchableUserDropDown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func,
  multiple: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  hasError: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  ariaLabel: PropTypes.string,
  noOptionsText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  autoFocus: PropTypes.bool,
  hasMore: PropTypes.bool,
  optionTypeText: PropTypes.string,
  getOptionDisabled: PropTypes.func,
  getOptionLabel: PropTypes.func,
  disableClearable: PropTypes.bool
};

export default SearchableUserDropDown;
