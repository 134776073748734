import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDecimal } from '~/modules/common/components/Decimal';

export const FormattedMoneyValue = ({
  money,
  className,
  precision,
  numberFormat
}) => {
  const prefix = money.currency.symbol || money.currency.displayText;

  return (
    <FormattedDecimal
      value={money.amount}
      className={className}
      prefix={`${prefix} `}
      precision={precision}
      numberFormat={numberFormat}
    />
  );
};

FormattedMoneyValue.propTypes = {
  className: PropTypes.string,
  money: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.object.isRequired
  }).isRequired,
  precision: PropTypes.number,
  numberFormat: PropTypes.object
};

export default FormattedMoneyValue;
