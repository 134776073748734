import { useQuery } from '@apollo/client';
import { useState, useCallback, useMemo } from 'react';
import { gql } from 'graphql-tag';

export const RESOURCE_REQUESTS_SUMMARY_QUERY = gql`
  query resourceRequests(
    $cursor: String
    $projectUri: String
    $userUri: String
  ) {
    resourceRequests(
      limit: 10
      cursor: $cursor
      projectUri: $projectUri
      filter: { users: [$userUri] }
      requestStatusList: [COMPLETE]
      sort: { field: roleName, direction: ASC }
    ) {
      resourceRequests {
        id
        load
        quantity
        startDate
        endDate
        totalHours
        role {
          displayText
        }
      }
      nextPageCursor
    }
  }
`;
const orderNullFirst = (a, b) => (!b.role ? 1 : !a.role ? -1 : 0);

const useResourceRequestsSummary = ({ projectUri, userUri }) => {
  const [loadingMore, setLoadingMore] = useState(false);

  const variables = useMemo(
    () => ({
      projectUri,
      userUri,
      cursor: null
    }),
    [projectUri, userUri]
  );

  const { loading, error, data, fetchMore } = useQuery(
    RESOURCE_REQUESTS_SUMMARY_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables,
      errorPolicy: 'all'
    }
  );

  const resourceRequests = data?.resourceRequests?.resourceRequests || [];

  const nextPageCursor = data?.resourceRequests?.nextPageCursor;

  const hasMoreRows = Boolean(nextPageCursor);

  const loadMoreRows = useCallback(async () => {
    if (loading || loadingMore || !hasMoreRows) return;

    setLoadingMore(true);
    try {
      await fetchMore({
        query: RESOURCE_REQUESTS_SUMMARY_QUERY,
        variables: {
          ...variables,
          cursor: nextPageCursor
        }
      });
    } finally {
      setLoadingMore(false);
    }
  }, [loading, loadingMore, hasMoreRows, fetchMore, variables, nextPageCursor]);

  return {
    loadingRows: loading,
    loadingMoreRows: loadingMore,
    nextPageCursor,
    resourceRequests: [...resourceRequests].sort(orderNullFirst),
    error,
    loadMoreRows,
    hasMoreRows
  };
};

export default useResourceRequestsSummary;
