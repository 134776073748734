import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SubstituteUserIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 6.5 2 C 5.262500000000003 2 4.25 3.012499999999997 4.25 4.25 C 4.25 5.4875 5.262500000000003 6.5 6.5 6.5 C 7.737499999999998 6.5 8.750000000000002 5.4875 8.75 4.25 C 8.75 3.012499999999997 7.737499999999998 1.999999999999999 6.5 2 z M 13 3 L 13 8.65625 L 15.12109375 6.5859375 C 16.30495891944357 7.76675107376866 17.02632203810228 9.332822781903806 17.154296875 11 L 19.154296875 11 L 19.15234375 10.998046875 C 19.1383437500001 10.75504687500007 19.1150312499999 10.51434375000008 19.08203125 10.27734375 C 18.81514272606355 8.34153633292442 17.9196102720259 6.547040266021384 16.533203125 5.169921875 L 18.65625 3 L 13 3 z M 6.5 7.625 C 5.386250000000002 7.625 4.329296875000003 7.861093750000002 3.373046875 8.27734375 C 2.540546875 8.637343750000003 1.999999999999999 9.448124999999999 2 10.359375 L 2 11 L 11 11 L 11 10.359375 C 11 9.448124999999999 10.459453125 8.637343750000003 9.626953125 8.27734375 C 8.670703125000001 7.861093750000002 7.613750000000003 7.625 6.5 7.625 z M 4.845703125 13 L 4.84765625 13.001953125 C 4.8616562499999 13.24495312499993 4.8849687500001 13.48565624999992 4.91796875 13.72265625 C 5.18485727393645 15.65846366707558 6.080389727974101 17.45295973397861 7.466796875 18.830078125 L 5.34375 21 L 11 21 L 11 15.34375 L 8.87890625 17.4140625 C 7.695041080556431 16.23324892623134 6.97367796189772 14.66717721809619 6.845703125 13 L 4.845703125 13 z M 17.5 13 C 16.2625 13 15.25 14.0125 15.25 15.25 C 15.25 16.4875 16.2625 17.5 17.5 17.5 C 18.7375 17.5 19.75 16.4875 19.75 15.25 C 19.75 14.0125 18.7375 13 17.5 13 z M 17.5 18.625 C 16.38625 18.625 15.329296875 18.86109375000001 14.373046875 19.27734375 C 13.540546875 19.63734375000001 13 20.448125 13 21.359375 L 13 22 L 22 22 L 22 21.359375 C 22 20.448125 21.45945312499999 19.63734375 20.626953125 19.27734375 C 19.670703125 18.86109375000001 18.61375 18.625 17.5 18.625 z " />
    </>
  </SvgIcon>
));

export default SubstituteUserIcon;
