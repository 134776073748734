import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AccessDeniedIcon = React.forwardRef((props, ref) => (
  <SvgIcon viewBox="0 0 380 300" {...props} ref={ref}>
    <>
      <path
        fillOpacity="0.08"
        strokeWidth="1.002"
        d="M70.577 9.766c155.71-38.167 145.92 43.94 234.66 101.38 88.747 57.443 81.173 67.024 63.905 101.27s-179.77 124.65-271.18 70.901C6.56 229.571-54.168 72.687 70.571 9.757z"
      ></path>
      <circle cx="185" cy="166" r="92" fill="#fff" strokeWidth="1.006"></circle>
      <path
        fillOpacity="0.6"
        strokeWidth="0.996"
        d="M198.68 133h-27.354l-19.323 19.323v27.354L171.326 199h27.354l19.323-19.323v-27.354zm-13.677 52.434a4.766 4.766 0 114.767-4.766 4.758 4.758 0 01-4.767 4.766zm3.666-15.768h-7.333v-21.999h7.333z"
      ></path>
    </>
  </SvgIcon>
));

export default AccessDeniedIcon;
