import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ClientDropdown } from '../../project/common/components';
import useClientDropdownOptionsForProject from './useClientDropdownOptionsForProject';
import useMyClientDropDownOptionsForProject from './useMyClientDropDownOptionsForProject';

export const ClientDropdownForProject = ({
  value,
  onChange,
  shouldFetchOnlyMyClients = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const { options, loading } = useClientDropdownOptionsForProject(
    shouldFetchOnlyMyClients
  );

  const {
    hasMore,
    options: myClientOptions,
    loading: myClientOptionsLoading
  } = useMyClientDropDownOptionsForProject(!shouldFetchOnlyMyClients);

  return (
    <ClientDropdown
      hasMore={hasMore}
      options={shouldFetchOnlyMyClients ? myClientOptions : options}
      isLoading={shouldFetchOnlyMyClients ? myClientOptionsLoading : loading}
      value={value}
      onChange={onChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.clients'
      })}
      {...rest}
    />
  );
};

ClientDropdownForProject.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  shouldFetchOnlyMyClients: PropTypes.bool
};

export default ClientDropdownForProject;
