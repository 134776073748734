import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { PROJECT_STATUS_FILTER } from '../enum';
import ProjectStatusFilterDialogContent from './ProjectStatusFilterDialogContent';
import useFormOnChange from './useFormOnChange';

const ProjectStatusFilterDialog = ({
  onDialogClose,
  isDialogOpen,
  values,
  setFieldValue,
  submitForm,
  projectStatusFilterOption,
  errors,
  customStatusMapping,
  isProjectCustomStatusNamesLoading
}) => {
  const { statuses, option } = values;
  const { formatMessage } = useIntl();

  const { onStatusChange, onOptionChange, onCancel } = useFormOnChange({
    statuses,
    setFieldValue,
    projectStatusFilterOption,
    onDialogClose
  });

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onCancel}
      data-qe-id="ProjectStatusFilterDialog"
    >
      <DialogTitle id="project-filter-dialog-title">
        {formatMessage({
          id: 'projectStatusFilterDialog.dialogTitle'
        })}
      </DialogTitle>
      <ProjectStatusFilterDialogContent
        values={values}
        onStatusChange={onStatusChange}
        onOptionChange={onOptionChange}
        errors={errors}
        customStatusMapping={customStatusMapping}
        isProjectCustomStatusNamesLoading={isProjectCustomStatusNamesLoading}
      />
      <DialogActions>
        <Button data-qe-id="CancelDialogButton" onClick={onCancel}>
          {formatMessage({ id: 'projectStatusFilterDialog.cancel' })}
        </Button>
        <Button
          data-qe-id="ConfirmDialogButton"
          color="primary"
          onClick={submitForm}
          autoFocus
          disabled={
            option === PROJECT_STATUS_FILTER.CUSTOM_STATUS &&
            statuses.length === 0
          }
        >
          {formatMessage({
            id: 'projectStatusFilterDialog.saveSettings'
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProjectStatusFilterDialog.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  projectStatusFilterOption: PropTypes.object.isRequired,
  errors: PropTypes.object,
  customStatusMapping: PropTypes.object,
  isProjectCustomStatusNamesLoading: PropTypes.bool
};

export default ProjectStatusFilterDialog;
