import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const BULK_PUT_PROJECT_STATUS_MUTATION = gql`
  mutation BulkPutProjectStatus(
    $projectIds: [String!]!
    $projectWorkflowState: String!
  ) {
    bulkPutProjectStage(
      projectIds: $projectIds
      stageId: $projectWorkflowState
    ) {
      projectIds
    }
  }
`;

export const useBulkPutProjectStatus = ({
  projectIds,
  projectWorkflowState,
  setBulkUpdateProjectState
}) => {
  const [bulkPutProjectStatusChange] = useMutation(
    BULK_PUT_PROJECT_STATUS_MUTATION
  );

  return useCallback(async () => {
    setBulkUpdateProjectState({
      inProgress: true,
      complete: false
    });
    await bulkPutProjectStatusChange({
      variables: {
        projectIds,
        projectWorkflowState
      }
    });
    setBulkUpdateProjectState({
      inProgress: false,
      complete: true
    });
  }, [
    setBulkUpdateProjectState,
    bulkPutProjectStatusChange,
    projectIds,
    projectWorkflowState
  ]);
};

export default useBulkPutProjectStatus;
