import React from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import Score from './Score';
import { scores } from './scores';

export const ScoreDropDown = ({
  value,
  onChange,
  fullWidth = true,
  variant = 'filled',
  labelClassName,
  ...rest
}) => (
  <TextField
    select
    fullWidth={fullWidth}
    value={value}
    onChange={onChange}
    variant={variant}
    InputLabelProps={{
      classes: {
        root: labelClassName
      }
    }}
    {...rest}
  >
    {scores.map(score => (
      <MenuItem key={score} value={score}>
        <Score score={score} />
      </MenuItem>
    ))}
  </TextField>
);

ScoreDropDown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  labelClassName: PropTypes.string
};

export default ScoreDropDown;
