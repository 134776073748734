import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  roleNameContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  roleName: {
    marginRight: theme.spacing(1),
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  icon: {
    marginRight: 0
  },
  field: {
    padding: 0
  },
  user: {
    paddingTop: 0,
    paddingBottom: 0
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  select: {},
  createOptionIcon: {
    minWidth: 42
  },
  control: {},
  name: {}
}));
