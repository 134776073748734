import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

export const projectProgramsQuery = gql`
  query getProjectPrograms($page: Int!, $pageSize: Int!, $searchTerm: String) {
    projectPrograms(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      totalItems
      items {
        id
        displayText
      }
    }
  }
`;

const searchableProjectPrograms = ({
  apolloClient
}) => setHasMore => async searchTerm => {
  const result = await apolloClient.query({
    query: projectProgramsQuery,
    variables: {
      page: 1,
      pageSize: 50,
      searchTerm
    }
  });

  const programs = result.data.projectPrograms.items.map(program => ({
    ...program,
    value: program.id,
    label: program.displayText,
    key: program.displayText
  }));

  setHasMore(result.data.projectPrograms.totalItems > programs.length);

  return programs;
};

export const useSearchableProjectPrograms = () => {
  const apolloClient = useApolloClient();

  return {
    searchPrograms: searchableProjectPrograms({
      apolloClient
    })
  };
};

export default useSearchableProjectPrograms;
