import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useLocations from '~/modules/common/enhancers/useLocations';
import { useStyledGroupLabels } from './hooks';
import GroupDropdown from './GroupDropdown';

export const LocationsDropdown = ({
  classes,
  groupSettings,
  value = '',
  policy,
  onChange,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const { location: locationLabel } = useStyledGroupLabels();
  const { locations, loading, hasMore } = useLocations({ searchTerm, policy });

  const noGroupsText = groupSettings.singularName
    ? formatMessage(
        { id: 'groupsDropdown.noGroup' },
        { group: groupSettings.singularName }
      )
    : formatMessage({ id: 'locationsDropdown.noLocation' });

  return (
    <GroupDropdown
      hasMore={hasMore}
      classes={classes}
      options={locations}
      label={locationLabel}
      value={value}
      loading={loading}
      setSearchTerm={setSearchTerm}
      noOptionsText={noGroupsText}
      onChange={onChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.location'
      })}
      {...rest}
    />
  );
};

LocationsDropdown.propTypes = {
  classes: PropTypes.object,
  groupSettings: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
  policy: PropTypes.string
};

export default LocationsDropdown;
