import { useMemo } from 'react';
import { userTag } from '~/modules/common/components/SearchBox/Facets/UserFacet/tag';
import { projectTag } from '~/modules/common/components/SearchBox/Facets/ProjectFacet/tag';
import { dateTag } from '~/modules/common/components/SearchBox/Facets/DateRangeFacet/tag';
import { billingItemTypeTag } from '~/modules/common/components/SearchBox/Facets/BillingItemTypeFacet/tag';

const hasTag = (criterions, tag) =>
  !criterions || !criterions[tag] || criterions[tag].length <= 0;

export const useFilterBasedOnSearchCriteria = ({
  searchCriteria: { keywords, criterions }
}) =>
  useMemo(() => {
    if (
      (!keywords || keywords.length <= 0) &&
      hasTag(criterions, userTag) &&
      hasTag(criterions, projectTag) &&
      hasTag(criterions, dateTag) &&
      hasTag(criterions, billingItemTypeTag)
    ) {
      return { billingItemSearch: {} };
    }

    const billingItemSearch = {
      text: keywords.join(' ') || null,
      projectUris:
        criterions[projectTag] && criterions[projectTag].length > 0
          ? criterions[projectTag].map(({ value }) => value)
          : null,
      userUris:
        criterions[userTag] && criterions[userTag].length > 0
          ? criterions[userTag].map(({ value }) => value)
          : null,
      dateRange:
        criterions[dateTag] &&
        criterions[dateTag].length > 0 &&
        (criterions[dateTag][0].range.startDate ||
          criterions[dateTag][0].range.endDate)
          ? {
              startDate: criterions[dateTag][0].range.startDate
                ? criterions[dateTag][0].range.startDate.toISODate()
                : null,
              endDate: criterions[dateTag][0].range.endDate
                ? criterions[dateTag][0].range.endDate.toISODate()
                : null
            }
          : null,
      billingItemTypes:
        criterions[billingItemTypeTag] &&
        criterions[billingItemTypeTag].length > 0
          ? criterions[billingItemTypeTag].map(({ value }) => value)
          : null
    };

    return { billingItemSearch };
  }, [criterions, keywords]);

export default useFilterBasedOnSearchCriteria;
