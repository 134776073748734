import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import QuickFilterSummaryTabs from '~/modules/common/components/QuickFilterSummaryTabs';
import { useMeContext } from '~/modules/me/useMeContext';
import { getDueDates } from '~/modules/billing-invoicing/common/util';
import { MobileQuickFilterSummaryTabs } from '~/modules/common/components/QuickFilterSummaryTabs/MobileQuickFilterSummaryTabs';
import { useBillingTransactionFilterMemo } from '../BillingTransactionTab/hooks/useBillingTransactionFilterMemo';
import { useOverdueBillTabSummary } from './hooks';
import { getStatusCountSummaryBasedProps } from './useAccountReceivableStatusCountSummary';
import { getQuickFilterSummaryTabs } from './accountReceivableGetQuickFilterSummaryTabs';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0,
    right: 0,
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2)
  }
}));

export const AccountReceivableQuickFilterSummary = ({
  projectUri,
  clientUri,
  programId,
  isMobile,
  searchCriteria,
  summaryFilterState
}) => {
  const me = useMeContext();

  const dueDate = getDueDates(me);

  const classes = useStyles();
  const contextFilter = {
    projectUri,
    clientUri,
    programUri: programId
  };

  const { billingTransactionFilter } = useBillingTransactionFilterMemo({
    searchCriteria
  });

  const {
    loading: isAllSummaryTabLoading,
    overdueSummary: allSummary
  } = useOverdueBillTabSummary(billingTransactionFilter, contextFilter);

  const {
    loading: isThirtyDaysSummaryTabLoading,
    overdueSummary: thirtyDaysDueDateSummary
  } = useOverdueBillTabSummary(
    billingTransactionFilter,
    contextFilter,
    dueDate.thirtyDays
  );

  const {
    loading: isSixtyDaysSummaryTabLoading,
    overdueSummary: sixtyDaysdueDateSummary
  } = useOverdueBillTabSummary(
    billingTransactionFilter,
    contextFilter,
    dueDate.sixtyDays
  );

  const {
    loading: isNinetyDaysSummaryTabLoading,
    overdueSummary: ninetyDaysdueDateSummary
  } = useOverdueBillTabSummary(
    billingTransactionFilter,
    contextFilter,
    dueDate.ninetyDays
  );

  const {
    loading: isOneTwentyDaysSummaryTabLoading,
    overdueSummary: oneTwentyDaysdueDateSummary
  } = useOverdueBillTabSummary(
    billingTransactionFilter,
    contextFilter,
    dueDate.oneTwentyDays
  );

  const {
    loading: isGreaterThanOneTwentyDaysSummaryTabLoading,
    overdueSummary: greaterThanOneTwentyDaysdueDateSummary
  } = useOverdueBillTabSummary(
    billingTransactionFilter,
    contextFilter,
    dueDate.greaterThanOneTwentyDays
  );

  const isLoading =
    isAllSummaryTabLoading ||
    isThirtyDaysSummaryTabLoading ||
    isSixtyDaysSummaryTabLoading ||
    isNinetyDaysSummaryTabLoading ||
    isOneTwentyDaysSummaryTabLoading ||
    isGreaterThanOneTwentyDaysSummaryTabLoading;

  const { statusOptions, allTotal } = getStatusCountSummaryBasedProps({
    allSummary,
    thirtyDaysDueDateSummary,
    sixtyDaysdueDateSummary,
    ninetyDaysdueDateSummary,
    oneTwentyDaysdueDateSummary,
    greaterThanOneTwentyDaysdueDateSummary
  });

  const { allItems, transactionPhases } = getQuickFilterSummaryTabs({
    statusOptions,
    allTotal
  });

  return isMobile ? (
    <MobileQuickFilterSummaryTabs
      allItems={allItems}
      summaryItems={transactionPhases}
      summaryFilterState={summaryFilterState}
      isValueLoading={isLoading}
      showSingleAllItem
    />
  ) : (
    <div className={classes.root}>
      <QuickFilterSummaryTabs
        allItems={allItems}
        summaryItems={transactionPhases}
        summaryFilterState={summaryFilterState}
        isValueLoading={isLoading}
      />
    </div>
  );
};

AccountReceivableQuickFilterSummary.propTypes = {
  projectUri: PropTypes.string,
  clientUri: PropTypes.string,
  programId: PropTypes.string,
  isMobile: PropTypes.bool,
  searchCriteria: PropTypes.object,
  summaryFilterState: PropTypes.object
};

export default AccountReceivableQuickFilterSummary;
