import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import {
  ReleaseResourceRequestDialogTitle,
  ReleaseResourceRequestDialogActions,
  DirectReleaseResourceDialogContent,
  ReleaseResourceRequestDialogContent
} from './components';
import useReleaseResourceState from './hooks/useReleaseResourceState';
import { useActionStyles } from './useStyles';

const useContentStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

export const ReleaseResourceDialogContent = ({
  onCancelClick,
  canEditTask,
  onReleaseSuccess,
  onReleaseResourceAllocation,
  onReleaseResourceAllocationsForRequest,
  onResourceRequestSubmit,
  resourceRequest,
  allocation,
  projectUri
}) => {
  const contentClasses = useContentStyles();
  const actionClasses = useActionStyles();

  const {
    isPsaRmpTaskAllocation1Enabled,
    releaseResourceValues: {
      allocations,
      releaseDate,
      requestStartDate,
      requestEndDate,
      role,
      comment,
      releaseType,
      releaseConfirmed
    },
    onCommentsChange,
    onReleaseConfirmChange,
    onReleaseDateChange,
    onReleaseTypeChange,
    onRowSelectionChange,
    onSelectAllClick,
    onReleaseClick,
    releaseTaskOwners,
    selectedUsersForReleasing,
    isLoadingImpactedTasksSummary,
    usersAssignedImpactedTaskSummary,
    impactedTasks,
    isReleaseLoading
  } = useReleaseResourceState({
    resourceRequest,
    allocation,
    projectUri,
    onCancelClick,
    onReleaseSuccess,
    onReleaseResourceAllocation,
    onReleaseResourceAllocationsForRequest,
    onResourceRequestSubmit
  });

  const noneSelected =
    (resourceRequest && allocations.filter(a => a.isSelected).length === 0) ||
    false;

  return (
    <>
      <DialogTitle id="release-resource-dialog-title">
        {resourceRequest ? (
          <ReleaseResourceRequestDialogTitle
            role={role}
            onClose={onCancelClick}
            headerId="releaseResourceRequestDialog.releaseResourcesTitle"
          />
        ) : (
          <ReleaseResourceRequestDialogTitle
            role={role}
            onClose={onCancelClick}
            headerId="releaseResourceRequestDialog.releaseResourceTitle"
          />
        )}
      </DialogTitle>
      <DialogContent
        id="release-resource-dialog-content"
        classes={contentClasses}
      >
        {resourceRequest && (
          <ReleaseResourceRequestDialogContent
            allocations={allocations}
            onSelectAllClick={onSelectAllClick}
            onRowSelectionChange={onRowSelectionChange}
          />
        )}
        {!resourceRequest && (
          <DirectReleaseResourceDialogContent allocation={allocation} />
        )}
      </DialogContent>
      <DialogActions classes={actionClasses}>
        <ReleaseResourceRequestDialogActions
          onCancelClick={onCancelClick}
          onReleaseClick={onReleaseClick}
          noneSelected={noneSelected}
          releaseType={releaseType}
          releaseDate={releaseDate}
          requestStartDate={requestStartDate}
          requestEndDate={requestEndDate}
          onCommentsChange={onCommentsChange}
          releaseConfirmed={releaseConfirmed}
          isReleaseLoading={isReleaseLoading}
          onReleaseDateChange={onReleaseDateChange}
          onReleaseTypeChange={onReleaseTypeChange}
          onReleaseConfirmChange={onReleaseConfirmChange}
          comment={comment}
          impactedTasks={impactedTasks}
          usersAssignedImpactedTaskSummary={usersAssignedImpactedTaskSummary}
          isLoadingImpactedTasksSummary={isLoadingImpactedTasksSummary}
          selectedUsersForReleasing={selectedUsersForReleasing}
          canEditTask={canEditTask}
          allocations={allocations}
          releaseTaskOwners={releaseTaskOwners}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      </DialogActions>
    </>
  );
};

ReleaseResourceDialogContent.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  canEditTask: PropTypes.bool.isRequired,
  resourceRequest: PropTypes.object,
  projectUri: PropTypes.string,
  allocation: PropTypes.object,
  onReleaseSuccess: PropTypes.func,
  onResourceRequestSubmit: PropTypes.func,
  onReleaseResourceAllocation: PropTypes.func,
  onReleaseResourceAllocationsForRequest: PropTypes.func
};

export default ReleaseResourceDialogContent;
