import React from 'react';
import PropTypes from 'prop-types';
import { deepPure } from '~/util';
import { useChartDisplayPeriods } from '~/modules/resourcing/common/chart/hooks';
import {
  useHolidaySummaryPeriods,
  useTimeoffSummaryPeriods
} from '~/modules/resourcing/common/hooks';
import TimeOffSummaryBlocks from '../TimeOffSummaryBlock/TimeOffSummaryBlocks';
import HolidaySummaryBlocks from '../HolidaySummaryBlock/HolidaySummaryBlocks';
import { ResourceAllocationTimelineBlocks } from './ResourceAllocationTimelineBlocks';

export const ResourceRequestUserAllocationBlock2 = ({
  allocation,
  chartDisplayDateRange,
  handleAllocationPeriodClick,
  isEditable,
  onAllocationChange,
  resourceRequest,
  scale
}) => {
  const timeoffSummaryPeriods = useTimeoffSummaryPeriods({
    resource: allocation?.user
  });

  const holidaySummaryPeriods = useHolidaySummaryPeriods({
    resource: allocation?.user
  });

  const timeOffHolidaySummaryBlocksComponent = (
    <>
      <TimeOffSummaryBlocks
        scale={scale}
        chartStartDate={chartDisplayDateRange.startDate}
        timeoffSummaryPeriods={timeoffSummaryPeriods}
        chartDisplayDateRange={chartDisplayDateRange}
        timeOffs={allocation?.user.timeoffs}
        showTooltip
        clickable
      />
      <HolidaySummaryBlocks
        scale={scale}
        chartStartDate={chartDisplayDateRange.startDate}
        holidaySummaryPeriods={holidaySummaryPeriods}
        chartDisplayDateRange={chartDisplayDateRange}
        holidays={allocation?.user.holidays}
        showTooltip
        clickable
      />
    </>
  );

  const chartDisplayPeriods = useChartDisplayPeriods({
    chartDisplayDateRange,
    scale
  });

  return (
    <ResourceAllocationTimelineBlocks
      allocation={allocation}
      chartDisplayDateRange={chartDisplayDateRange}
      chartDisplayPeriods={chartDisplayPeriods}
      handleAllocationPeriodClick={handleAllocationPeriodClick}
      onAllocationChange={onAllocationChange}
      isEditable={isEditable}
      scale={scale}
      timeOffHolidaySummaryBlocksComponent={
        timeOffHolidaySummaryBlocksComponent
      }
      resourceRequest={resourceRequest}
    />
  );
};

ResourceRequestUserAllocationBlock2.propTypes = {
  scale: PropTypes.string.isRequired,
  allocation: PropTypes.object,
  handleAllocationPeriodClick: PropTypes.func,
  chartDisplayDateRange: PropTypes.object,
  isEditable: PropTypes.bool,
  onAllocationChange: PropTypes.func.isRequired,
  resourceRequest: PropTypes.object
};

export default deepPure(ResourceRequestUserAllocationBlock2);
