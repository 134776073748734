import { useMemo, useCallback, useState } from 'react';
import { getExpenseCodeIds, getUpdatedParameters } from './utils';

export const useExpenseScriptParamsCardHooks = ({
  scripts,
  expenseCodes,
  setFieldValue,
  editable,
  parameters,
  readOnlyParameters,
  monthlyExpenses,
  projectExpenseCodes,
  allowBillable,
  viewSummary
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const updatedParameters = getUpdatedParameters(
    editable,
    parameters,
    readOnlyParameters,
    viewSummary
  );

  const availableExpenseCodes = useMemo(() => {
    const excludeExpenseCodeIds = getExpenseCodeIds(monthlyExpenses);

    return (expenseCodes || []).filter(
      code => !excludeExpenseCodeIds.includes(code.id)
    );
  }, [expenseCodes, monthlyExpenses]);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onAddExpenseTypeClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onAddRowHandler = useCallback(
    expenseCode => {
      setFieldValue('projectExpenseCodes', [
        ...projectExpenseCodes,
        expenseCode
      ]);

      setFieldValue('monthlyExpenses', [...monthlyExpenses, { expenseCode }]);
    },
    [setFieldValue, projectExpenseCodes, monthlyExpenses]
  );

  const onDeleteRowHandler = useCallback(
    record => {
      setFieldValue(
        'scripts',
        scripts.filter(value => value.scriptId !== record.scriptId)
      );

      setFieldValue(
        'projectExpenseCodes',
        projectExpenseCodes.filter(code => code.id !== record.expenseCode.id)
      );

      setFieldValue(
        'monthlyExpenses',
        monthlyExpenses.filter(
          value => value.expenseCode.id !== record.expenseCode.id
        )
      );
    },
    [setFieldValue, projectExpenseCodes, monthlyExpenses, scripts]
  );

  return {
    availableExpenseCodes,
    onAddExpenseTypeClick,
    anchorEl,
    onMenuClose,
    onAddRowHandler,
    updatedParameters,
    onDeleteRowHandler
  };
};
