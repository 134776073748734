export const INITIAL_STATE = {
  prevFilter: {},
  prevIsResourceActualModeEnabled: false
};

export const filterChangeReducer = (state, action = {}) => {
  switch (action.type) {
    case 'FILTER_CHANGE':
      return {
        prevFilter: action.prevFilter,
        prevIsResourceActualModeEnabled: action.prevIsResourceActualModeEnabled
      };
    default:
      return state;
  }
};
