import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Grid, List, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  outline: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: alpha(theme.palette.common.black, 0.23),
    border: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5)
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}));

export const RoleScheduleReadOnlyFormAdditionalRow = ({
  classes: classesOverride,
  additionalRoles,
  dataQeId
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles(classesOverride);
  const additionalRolesList = useMemo(
    () =>
      additionalRoles.length > 0 && (
        <div className={classes}>
          <Typography variant="body2" color="textSecondary">
            {formatMessage({ id: 'rolesForm.additionalRoles' })}
          </Typography>
          <List className={classes.list}>
            {additionalRoles.map(additionalRole => (
              <div
                className={classes.outline}
                key={additionalRole.role.displayText}
              >
                <span className={classes.item}>
                  {additionalRole.role.displayText}
                </span>
              </div>
            ))}
          </List>
        </div>
      ),
    [additionalRoles, classes, formatMessage]
  );

  return (
    <Grid data-qe-id={dataQeId} item xs={12}>
      {additionalRolesList}
    </Grid>
  );
};

RoleScheduleReadOnlyFormAdditionalRow.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  additionalRoles: PropTypes.array
};

export default RoleScheduleReadOnlyFormAdditionalRow;
