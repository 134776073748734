import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '~/modules/common/components';

const resourceKeys = {
  header: 'billingSettingOverrideDialog.title',
  description: 'billingSettingOverrideDialog.description',
  confirmationMessage: 'billingSettingOverrideDialog.confirmationMessage',
  cancel: 'billingSettingOverrideDialog.cancel',
  overrideChanges: 'billingSettingOverrideDialog.overrideChanges'
};

export const BillingSettingsOverrideConfirmationDialog = ({
  open,
  onClose,
  handleSubmit
}) => {
  const handleOverrideChanges = useCallback(() => {
    handleSubmit();
    onClose();
  }, [handleSubmit, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="entity-confirmation-dialog-title"
      aria-describedby="entity-confirmation-dialog-description"
    >
      <DialogTitle id="entity-confirmation-dialog-title">
        <FormattedMessage id={resourceKeys.header} />
      </DialogTitle>
      <DialogContent id="entity-confirmation-dialog-description">
        <DialogContentText>
          <FormattedMessage id={resourceKeys.description} />
        </DialogContentText>
        <DialogContentText>
          <FormattedMessage id={resourceKeys.confirmationMessage} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id={resourceKeys.cancel} />
        </Button>
        <LoadingButton
          onClick={handleOverrideChanges}
          color="secondary"
          autoFocus
          isLoading={false}
        >
          <FormattedMessage id={resourceKeys.overrideChanges} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

BillingSettingsOverrideConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default BillingSettingsOverrideConfirmationDialog;
