import { useMemo } from 'react';
import { useResourceRequestsStatusSummaryCounts } from './hooks';

export default () => {
  const {
    loading,
    resourceRequestsStatusCountSummary
  } = useResourceRequestsStatusSummaryCounts();

  return useMemo(() => {
    if (!loading) {
      const {
        submitted = 0,
        proposed = 0,
        allocationRejected = 0
      } = resourceRequestsStatusCountSummary;

      const pending = submitted + allocationRejected;

      return {
        loading,
        reminders: [
          pending && {
            count: pending,
            boldMessageId: 'resourcingOverviewReminders.pending',
            messageId: 'resourcingOverviewReminders.pendingResourceRequests',
            planStatus: 'pending',
            subCount: allocationRejected,
            subTextMessageId: 'resourcingOverviewReminders.resourcesRejected'
          },
          proposed && {
            count: proposed,
            boldMessageId: 'resourcingOverviewReminders.proposed',
            messageId:
              'resourcingOverviewReminders.proposedResourcesNotAccepted',
            planStatus: 'proposed'
          }
        ].filter(r => r)
      };
    }

    return {
      loading
    };
  }, [resourceRequestsStatusCountSummary, loading]);
};
