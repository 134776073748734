const getSortDirection = direction =>
  direction ? direction.toLowerCase() : 'asc';

export const getColumnSortProps = ({
  isMultiSortEnabled = false,
  sortField,
  direction,
  customFieldUri,
  currentColumn,
  sortArray
}) => {
  if (isMultiSortEnabled) {
    const { field, direction: dir, customFieldUri: customField } =
      sortArray.find(
        x =>
          x.field === currentColumn.column ||
          x.field === currentColumn.sortField
      ) ?? {};

    const isColumnSorted = !field
      ? false
      : (field === currentColumn.sortField || field === currentColumn.column) &&
        (field !== 'customField' || customField === currentColumn.column);

    return {
      isSorted: isColumnSorted,
      sortDirection: getSortDirection(dir)
    };
  }

  const isSorted =
    sortField &&
    (sortField === currentColumn.sortField ||
      sortField === currentColumn.column) &&
    (sortField !== 'customField' || customFieldUri === currentColumn.column);

  return {
    isSorted,
    sortDirection: getSortDirection(direction)
  };
};
