import PropTypes from 'prop-types';
import React, { useState, useCallback, memo } from 'react';
import { ProjectContextProvider } from '~/modules/resourcing/common/contexts';
import { usePageTitle } from '~/modules/common/title';
import { SortDirection } from '~/types';
import { ResourceRequestActionMessageSnackBar } from '~/modules/common/components';
import { useProjectResourcingPlanSearch } from '../hooks';
import MobileResourcingPlanPage from '../MobileResourcingPlanPage';
import ResourceRequestToolbarContextProvider from '../ResourceRequestToolbarContextProvider';

export const MobileProjectResourcingPlan = memo(
  ({ editable, project, width, setSearchCriteria, searchCriteria }) => {
    const [sort, setSort] = useState();

    const onSortChange = useCallback(
      field =>
        setSort({
          field,
          direction:
            sort && sort.field === field && sort.direction === SortDirection.Asc
              ? SortDirection.Desc
              : SortDirection.Asc
        }),
      [sort, setSort]
    );

    usePageTitle([
      { messageId: 'routes.projects' },
      { title: project.displayText },
      { messageId: 'projectPageTabs.resourcing' }
    ]);

    const filter = useProjectResourcingPlanSearch({ searchCriteria });

    return (
      <ProjectContextProvider project={project}>
        <ResourceRequestToolbarContextProvider
          project={project}
          width={width}
          setSearchCriteria={setSearchCriteria}
          searchCriteria={searchCriteria}
          filter={filter}
          sort={sort}
          onSortChange={onSortChange}
        >
          <MobileResourcingPlanPage isEditSwitchEnabled={editable} />
        </ResourceRequestToolbarContextProvider>
        <ResourceRequestActionMessageSnackBar />
      </ProjectContextProvider>
    );
  }
);

MobileProjectResourcingPlan.propTypes = {
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  width: PropTypes.number,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object
};

export default MobileProjectResourcingPlan;
