import React, { memo, useContext } from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { TableCell } from '@material-ui/core';

import ListTableVariantContext from '../../../ListTableVariantContext';

const useStyles = makeStyles(() => ({
  tableCellFixed: {
    position: 'sticky',
    left: '0px',
    top: '0px',
    zIndex: 1
  },
  tableCellLeftOfFixed: {
    // paddingLeft: '324px'
  }
}));

const TableHeaderCell = ({
  classes: classesOverride,
  className,
  fixed,
  leftOfFixed,
  children,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const variant = useContext(ListTableVariantContext);

  const Component = variant === 'table' ? TableCell : 'div';

  return (
    <Component
      className={classnames(
        fixed ? classes.tableCellFixed : undefined,
        leftOfFixed ? classes.tableCellLeftOfFixed : undefined,
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

TableHeaderCell.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  leftOfFixed: PropTypes.bool,
  children: PropTypes.any
};

export default memo(TableHeaderCell);
