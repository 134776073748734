import classNames from 'classnames';
import { flatMap } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useIsRTL } from '~/modules/common/hooks/useIsRTL';
import { useColumns } from './useColumns';

const useHeaderStyles = makeStyles(theme => ({
  bottomHeader: {
    top: `${theme.spacing(19) + 1}px`,
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(13)
    }
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.table.border}`
  },
  rtlCell: {
    textAlign: 'right',
    flip: false
  },
  topLeftHeader: {}
}));

const isFirst = idx => idx === 0;
const isLast = (idx, arr) => idx === arr.length - 1;

const mapGroupToTopColumns = (classes, columnClasses, isRTL) => group => {
  if (group.title) {
    return [
      {
        column: group.id,
        value: group.title,
        colSpan: group.columns.length,
        align: 'center',
        className: classNames(classes.rightBorder, classes.bottomBorder)
      }
    ];
  }

  return group.columns.map((column, idx) => ({
    column: column.field,
    align: isRTL ? 'left' : column.align,
    rowSpan: 2,
    fixed: column.fixed,
    className: classNames(columnClasses[column.field], {
      [classes.rtlCell]: isRTL,
      [classes.rightBorder]: isLast(idx, group.columns),
      [classes.topLeftHeader]: isFirst(idx)
    }),
    sortable: column.sortable,
    sortField: column.sortField,
    includesNavigationComponent: column.includesNavigationComponent
  }));
};

const mapGroupToBottomColumns = (classes, columnClasses, isRTL) => group => {
  if (!group.title) {
    return [];
  }

  return group.columns.map((column, idx) => ({
    column: column.field,
    align: isRTL ? 'left' : column.align,
    className: classNames(classes.bottomHeader, columnClasses[column.field], {
      [classes.rtlCell]: isRTL,
      [classes.rightBorder]: isLast(idx, group.columns)
    }),
    sortable: column.sortable,
    sortField: column.sortField
  }));
};

const calculateHeaders = ({ columnGroups, classes, columnClasses, isRTL }) => {
  const topColumns = flatMap(
    columnGroups,
    mapGroupToTopColumns(classes, columnClasses, isRTL)
  );
  const bottomColumns = flatMap(
    columnGroups,
    mapGroupToBottomColumns(classes, columnClasses, isRTL)
  );
  const headers = [
    {
      id: 'top',
      columns: topColumns
    },
    {
      id: 'bottom',
      columns: bottomColumns
    }
  ];

  return headers;
};

const calculateColumns = ({ columnGroups, classes, columnClasses, isRTL }) => {
  const columns = flatMap(columnGroups, group =>
    group.columns.map((column, idx) => {
      const { field, ...rest } = column;

      return {
        id: field,
        visible: true,
        className: classNames(columnClasses[field], {
          [classes.rtlCell]: isRTL,
          [classes.rightBorder]: isLast(idx, group.columns)
        }),
        ...rest
      };
    })
  );

  const columnObject = columns.reduce(
    (obj, col) => ({ ...obj, [col.id]: col }),
    {}
  );

  return columnObject;
};

const calculateFooters = ({ columnGroups, classes, isRTL }) => {
  const footers = flatMap(columnGroups, (group, idx) =>
    group.footers.map((footer, colIdx) => {
      const { field, requiredColumn: _, ...rest } = footer;

      return {
        id: field || `footer${idx}`,
        visible: true,
        className: classNames({
          [classes.rtlCell]: isRTL,
          [classes.rightBorder]: isLast(colIdx, group.footers)
        }),
        colSpan: footer.fill ? group.columns.length - colIdx : undefined,
        ...rest
      };
    })
  );

  return footers.reduce((obj, foot) => ({ ...obj, [foot.id]: foot }), {});
};

export const computeTableColumns = ({
  columnSpec,
  renders,
  defaultRender
}) => ({ enabledFields, columnClasses = {}, headerClasses = {} }) => {
  const classes = useHeaderStyles({ classes: headerClasses });
  const enabledFieldsSet = new Set(enabledFields);
  const isRTL = useIsRTL();

  const includedGroups = columnSpec
    .map(group => ({
      ...group,
      columns: group.columns.filter(column =>
        enabledFieldsSet.has(column.field)
      ),
      footers: (group.footers || []).filter(footer =>
        footer.requiredColumn
          ? enabledFieldsSet.has(footer.requiredColumn)
          : true
      )
    }))
    .filter(group => group.columns.length > 0);

  const headers = calculateHeaders({
    columnGroups: includedGroups,
    columnClasses,
    classes,
    isRTL
  });
  const columns = calculateColumns({
    columnGroups: includedGroups,
    columnClasses,
    classes,
    isRTL
  });
  const footers = calculateFooters({
    columnGroups: includedGroups,
    classes,
    isRTL
  });

  return {
    headers,
    columns: useColumns({
      columns,
      renders,
      defaultRender
    }),
    footers: useColumns({
      columns: footers,
      renders,
      defaultRender
    })
  };
};
