import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles, alpha } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Decimal from '~/modules/common/components/Decimal';
import RoleName from './RoleName';

const styles = theme => ({
  root: {},
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.54)}`
  },
  tooltipRoleList: {
    margin: theme.spacing(0.25, 0, 0, 0),
    listStyle: 'none',
    padding: 0
  },
  tooltipRoleItem: {
    margin: 0,
    padding: 0
  },
  role: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  roles: {
    fontSize: theme.typography.caption.fontSize
  },
  noValue: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  additional: {
    marginLeft: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize
  }
});

export const mapProjectRole = r => ({
  ...r,
  projectRole: { ...r.projectRole, displayText: r.projectRole.name }
});

export const ResourceRoles = ({ roles, classes }) => {
  const primaryRole = useMemo(() => {
    const r = roles.find(s => s.isPrimary);

    return r && mapProjectRole(r);
  }, [roles]);

  const secondaryRoles = roles.filter(s => !s.isPrimary).map(mapProjectRole);

  return (
    <span className={classes.root}>
      {primaryRole ? (
        <RoleName
          role={primaryRole.projectRole}
          className={classes.role}
          showTooltip={false}
        />
      ) : (
        <span className={classes.noValue}>
          <FormattedMessage id="resourceRoles.noRole" />
        </span>
      )}
      {secondaryRoles.length > 0 ? (
        <Tooltip
          title={
            <>
              <div className={classes.tooltipTitle}>
                <FormattedMessage id="resourceRoles.additionalRoles" />
              </div>
              <ul className={classes.tooltipRoleList}>
                {secondaryRoles.map(role => (
                  <li
                    key={role.projectRole.uri}
                    className={classes.tooltipRoleItem}
                  >
                    <RoleName
                      showTooltip={false}
                      role={role.projectRole}
                      className={classes.roles}
                    />
                  </li>
                ))}
              </ul>
            </>
          }
        >
          <Decimal
            value={secondaryRoles.length}
            precision={0}
            prefix="+"
            className={classes.additional}
          />
        </Tooltip>
      ) : null}
    </span>
  );
};

ResourceRoles.propTypes = {
  classes: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired
};

export default withStyles(styles)(ResourceRoles);
