import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useClientRate from '~/modules/rateCard/hooks/useClientRate';
import { useStyles } from '~/modules/projects/project/BillPlanV2/BillPlanPreRequisites/hooks';
import { useHasPermission } from '~/modules/common/permissions';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import CopyFromClient from './CopyFromClient';
import AddRateType from './AddRateType';
import AddDynamicRateCard from './AddDynamicRateCard';
import AddMilestoneTaskLink from './AddMilestoneTaskLink';

const formatMessage = (classes, id) => {
  return (
    <Typography className={classes.alert} component="div">
      <FormattedMessage className={classes.textControl} id={id} />
    </Typography>
  );
};

export const BillPlanError = ({
  projectDetails,
  title,
  milestoneTaskAvailable,
  rateAvailable,
  setMilestoneTaskCreated,
  setTaskAddedCount,
  clientUri,
  setRatesModifier,
  ratesCount,
  rates,
  hasViewRatePermission,
  hasViewEditRatePermission
}) => {
  const isPsaFpAdvancedRateCardEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpAdvancedRateCardEnabled'
  });
  const classes = useStyles();
  const { defaultRates } = useClientRate(clientUri);
  const rateTypeKeys = ['userRates', 'roleRates', 'projectRate'];
  const hasDefaultRates = useMemo(
    () =>
      rateTypeKeys.some(
        x => defaultRates && defaultRates[x] && defaultRates[x].length
      ),
    [rateTypeKeys, defaultRates]
  );

  const canEditTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-tasks'
  });

  return (
    <>
      {rateAvailable && !ratesCount && (
        <>
          <Grid container spacing={0} data-qe-id="RateCardRequired">
            <div className={classes.root}>
              <Alert
                severity="error"
                className={classes.alert}
                data-qe-id="ErrorMessage"
              >
                <strong>{title}</strong>
                {!isPsaFpAdvancedRateCardEnabled &&
                  hasViewEditRatePermission &&
                  clientUri &&
                  hasDefaultRates &&
                  hasViewRatePermission && (
                    <>
                      <CopyFromClient
                        clientUri={clientUri}
                        projectDetails={projectDetails}
                        setRatesModifier={setRatesModifier}
                      />
                      <FormattedMessage id="billPlanPreRequisites.or" />
                    </>
                  )}
                {hasViewEditRatePermission &&
                  hasViewRatePermission &&
                  (isPsaFpAdvancedRateCardEnabled ? (
                    <AddDynamicRateCard
                      projectDetails={projectDetails}
                      setRatesModifier={setRatesModifier}
                    />
                  ) : (
                    <AddRateType
                      projectDetails={projectDetails}
                      rates={rates}
                      clientUri={clientUri}
                      setRatesModifier={setRatesModifier}
                    />
                  ))}
                {!hasViewEditRatePermission &&
                  formatMessage(
                    { classes },
                    'billPlanPreRequisites.noViewEditRateCardPermission'
                  )}
                {!hasViewRatePermission && [
                  formatMessage(
                    { classes },
                    'billPlanPreRequisites.billingRateRequiredMessage'
                  ),
                  formatMessage(
                    { classes },
                    'billPlanPreRequisites.noEditRatePermission'
                  )
                ]}
              </Alert>
            </div>
          </Grid>
        </>
      )}
      {milestoneTaskAvailable && (
        <Grid container spacing={0}>
          <div className={classes.root}>
            <Alert severity="error" className={classes.alert}>
              <strong>{title}</strong>
              {canEditTasks && (
                <AddMilestoneTaskLink
                  projectDetails={projectDetails}
                  setMilestoneTaskCreated={setMilestoneTaskCreated}
                  setTaskAddedCount={setTaskAddedCount}
                />
              )}
            </Alert>
          </div>
        </Grid>
      )}
    </>
  );
};

BillPlanError.propTypes = {
  projectDetails: PropTypes.object,
  title: PropTypes.string,
  clientUri: PropTypes.string,
  milestoneTaskAvailable: PropTypes.bool,
  rateAvailable: PropTypes.bool,
  setMilestoneTaskCreated: PropTypes.func,
  setTaskAddedCount: PropTypes.func,
  setRatesModifier: PropTypes.func,
  ratesCount: PropTypes.number,
  rates: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasViewEditRatePermission: PropTypes.bool,
  hasViewRatePermission: PropTypes.bool
};

export default BillPlanError;
