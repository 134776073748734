import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AsOfDate } from './AsOfDate';

const useStyles = makeStyles(theme => ({
  text: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
}));

export const TextFormatter = ({ record, field }) => {
  const classes = useStyles();
  const { value, asOfDate } = record[field];

  return (
    <>
      <Typography className={classes.text}>{value}</Typography>
      <AsOfDate date={asOfDate} />
    </>
  );
};

TextFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default TextFormatter;
