import { useState, useCallback } from 'react';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useTaxProfiles } from '~/modules/clients/client/ClientInfo/dialogs/billing/hooks';

export const useTaxProfileOptions = () => {
  const isPsaPrpBillingDefaultForProjectEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpBillingDefaultForProjectEnabled'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const { loading, taxProfiles } = useTaxProfiles({
    searchTerm,
    skip: !isPsaPrpBillingDefaultForProjectEnabled
  });

  return {
    loading,
    taxProfiles,
    handleInputChange
  };
};

export default useTaxProfileOptions;
