import { makeStyles, alpha } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ChartTotalItems } from '~/modules/common/charts/timeline/components';
import { useMeContext } from '~/modules/me';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { FormattedHoursAndCost } from '~/modules/resourcing/common/components';
import {
  ResourceChartTotalPopper,
  usePopperHandlers
} from '~/modules/resourcing/components/ResourceChartTotalPopper';
import { ResourceCostModeType } from '~/types';

const useTotalStyles = makeStyles(theme => ({
  hours: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  },
  cost: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  currency: {
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    height: ({ allocationRoleCount }) =>
      theme.spacing(allocationRoleCount * 3 + (allocationRoleCount + 1)),
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.1)
    }
  },
  rowContainer: {
    padding: theme.spacing(0, 0.5, 0, 0)
  }
}));

export const formatTotalCost = ({
  totalHours,
  costRate,
  contextCurrency,
  contextExchangeRates
}) => [
  costRate
    ? {
        amount:
          totalHours *
          costRate *
          (Object.values(contextExchangeRates || {}).find(
            r => r?.currencyId === contextCurrency?.id
          )?.rate || 1),
        currency: {
          symbol: contextCurrency?.displayText
        },
        currencySymbol: contextCurrency?.displayText
      }
    : { amount: null }
];

export const UserRoleRowTotal = ({
  allocationsForUserRole,
  canViewCostData,
  projectBudgetCostCurrency
}) => {
  const { isResourceActualModeEnabled } = useResourceRequestToolbarContext();
  const { resourceCostMode } = useMeContext();

  const totalClasses = useTotalStyles();
  const classes = useStyles({
    allocationRoleCount:
      (allocationsForUserRole?.length || 0) +
      (isResourceActualModeEnabled ? 1 : 0)
  });

  const {
    anchorEl,
    onMenuClose,
    onMenuClick,
    handleKeyDown
  } = usePopperHandlers();

  const totalHours = (allocationsForUserRole || []).reduce(
    (acc, { totalHours: allocationTotalHours }) =>
      acc + (allocationTotalHours || 0),
    0
  );

  const isUserCostModeEnabled =
    ResourceCostModeType.Usercost === resourceCostMode;
  const costs = formatTotalCost({
    totalHours,
    costRate: isUserCostModeEnabled
      ? allocationsForUserRole?.[0]?.user?.costRate
      : allocationsForUserRole?.[0]?.user?.primaryRoleCostRate,
    contextCurrency:
      projectBudgetCostCurrency ||
      (isUserCostModeEnabled
        ? allocationsForUserRole?.[0]?.user?.costCurrency
        : allocationsForUserRole?.[0]?.user?.primaryRoleCostCurrency),
    contextExchangeRates: allocationsForUserRole?.[0]?.exchangeRateValues
  });

  const total = useMemo(
    () => (
      <FormattedHoursAndCost
        classes={totalClasses}
        hours={totalHours}
        costs={costs}
        hoursSuffix="h"
        hideCosts={!canViewCostData}
        showAbbreviatedValue={false}
      />
    ),
    [totalClasses, totalHours, costs, canViewCostData]
  );

  const popperContent = useMemo(
    () => (
      <ResourceChartTotalPopper
        id="resource-allocation-popper"
        anchorEl={anchorEl}
        onClose={onMenuClose}
        allocatedHours={totalHours}
        allocatedCost={costs[0]}
        viewCosts={canViewCostData}
        viewAllocation
        viewDifference={false}
      />
    ),
    [anchorEl, onMenuClose, totalHours, costs, canViewCostData]
  );

  return (
    <ChartTotalItems
      classes={classes}
      allocated={total}
      showCost={canViewCostData}
      popperContent={popperContent}
      handleClick={onMenuClick}
      handleKeyDown={handleKeyDown}
      onClickAway={onMenuClose}
    />
  );
};

UserRoleRowTotal.propTypes = {
  allocationsForUserRole: PropTypes.array,
  canViewCostData: PropTypes.bool,
  projectBudgetCostCurrency: PropTypes.object
};
