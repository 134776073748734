import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import React from 'react';
import get from 'lodash.get';
import FormFieldShimmer from '~/modules/common/components/FormFieldShimmer';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';

export const TASK_DROPDOWN_QUERY = gql`
  query TaskDropdown($projectId: String, $isMilestone: Boolean) {
    project(projectId: $projectId) {
      id
      tasks(isMilestone: $isMilestone, hierarchyListDataOptionUris: []) {
        id
        name
        displayText
      }
    }
  }
`;

const withMilestoneTasks = BaseComponent => props => {
  const { projectId, isMilestone, ...rest } = props;

  const { loading, data, error } = useQuery(TASK_DROPDOWN_QUERY, {
    variables: { projectId, isMilestone },
    fetchPolicy: 'network-only'
  });

  if (loading) return <FormFieldShimmer />;
  if (error) return <RedirectToUnhandledError />;

  const tasks = get(data, 'project.tasks') || [];

  return <BaseComponent {...rest} tasks={tasks} />;
};

export default withMilestoneTasks;
