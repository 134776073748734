import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ResourceRequestsIcon } from '~/modules/common/components';
import { ResourceRequestIcon } from '~/modules/common/components/Icons';
import { ResourceRequestTitle } from '~/modules/resourcing/common/components';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary
  },
  heading: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    }
  }
}));

const iconStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'flex-start',
    color: theme.palette.text.secondary,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: `${theme.spacing(4)}px`,
    margin: theme.spacing(1, 2, 0, 0),
    opacity: 0.54
  }
}));

const combinedRequestIconStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'flex-start',
    color: theme.palette.text.primary,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: `${theme.spacing(4)}px`,
    margin: theme.spacing(1, 2, 0, 0),
    opacity: 0.54
  }
}));

export const ResourceRequestDrawerTitle = ({ resourceRequest, project }) => {
  const classes = useStyles();
  const iconClasses = iconStyles();
  const combinedRequestIconClasses = combinedRequestIconStyles();

  return (
    <div className={classes.heading}>
      {resourceRequest?.mergedResourceRequests?.length > 1 ? (
        <ResourceRequestsIcon classes={combinedRequestIconClasses} />
      ) : (
        <ResourceRequestIcon classes={iconClasses} />
      )}
      <Typography className={classes.title} variant="h6" color="inherit">
        <ResourceRequestTitle
          resourceRequest={resourceRequest}
          project={project}
        />
      </Typography>
    </div>
  );
};

ResourceRequestDrawerTitle.propTypes = {
  resourceRequest: PropTypes.object,
  project: PropTypes.object
};

export default ResourceRequestDrawerTitle;
