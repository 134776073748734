import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useDialogState, useMenuState } from '~/modules/common/hooks';
import Decimal from '~/modules/common/components/Decimal';
import ChildInfoDialog from './ChildInfoDialog/ChildInfoDialog';

const useStyles = makeStyles({
  hoursCell: {
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: 'right',
    justifySelf: 'end'
  },
  fact: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: 'inherit'
  },
  na: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  },
  icon: {
    cursor: 'pointer',
    marginBottom: theme.spacing(-0.5) - 1,
    marginLeft: theme.spacing(1),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.75)
  }
});

const HoursCell = ({
  childTasks = [],
  classes: classesOverride,
  dataQeId,
  hasMore,
  loadMore,
  loadingMore,
  hours,
  selectedColumns,
  showValue,
  tooltipComponent,
  hideTooltip
}) => {
  const { open, closeDialog, openDialog } = useDialogState();

  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();
  const classes = useStyles({ classes: classesOverride });

  const handleInfoClick = useCallback(
    event => {
      onMenuClick(event);
      openDialog();
    },
    [onMenuClick, openDialog]
  );

  const handleClosePopover = useCallback(() => {
    onMenuClose();
    closeDialog();
  }, [onMenuClose, closeDialog]);

  return (
    <div className={classes.hoursCell} role="cell">
      {showValue && (
        <>
          {hours === null ? (
            <Typography className={classes.na} data-qe-id={`${dataQeId}_Hours`}>
              <FormattedMessage id="taskDrawer.na" />
            </Typography>
          ) : (
            <Decimal
              dataQeId={`${dataQeId}_Hours`}
              className={classes.fact}
              value={hours}
              fixedDecimalScale
            />
          )}
          {!hideTooltip && !tooltipComponent && childTasks.length !== 0 && (
            <>
              <InfoSharpIcon
                className={classes.icon}
                onClick={handleInfoClick}
              />
              <ChildInfoDialog
                childTasks={childTasks}
                selectedColumns={selectedColumns}
                open={open}
                onClose={handleClosePopover}
                anchorEl={anchorEl}
                hasMore={hasMore}
                loadMore={loadMore}
                loadingMore={loadingMore}
              />
            </>
          )}
          {!hideTooltip && tooltipComponent}
        </>
      )}
    </div>
  );
};

HoursCell.propTypes = {
  childTasks: PropTypes.array,
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  hours: PropTypes.number,
  selectedColumns: PropTypes.array,
  showValue: PropTypes.bool,
  tooltipComponent: PropTypes.node,
  hideTooltip: PropTypes.bool
};

export default HoursCell;
