import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { mapToServiceRequest } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';

export const COMPLETE_AND_MERGE_RESOURCE_REQUEST = gql`
  mutation markResourceRequestAsCompleteAndMerge(
    $input: MarkResourceRequestAsCompleteAndMergeInput!
  ) {
    markResourceRequestAsCompleteAndMerge(input: $input) {
      resourceRequests {
        ...SpecificResourceRequestWithAllocation
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
`;

const useMarkResourceRequestAsCompleteAndMerge = ({ resourceRequest }) => {
  const [markResourceRequestAsCompleteAndMerge, { loading }] = useMutation(
    COMPLETE_AND_MERGE_RESOURCE_REQUEST
  );

  const onMarkResourceRequestAsCompleteAndMerge = useCallback(() => {
    markResourceRequestAsCompleteAndMerge(
      mapToServiceRequest({
        request: resourceRequest,
        responseType: 'markResourceRequestAsCompleteAndMerge',
        responseTypeName: 'MarkResourceRequestAsCompleteAndMergeResult',
        requestStatus: ResourceRequestStatus.MergedArchived
      })
    );
  }, [markResourceRequestAsCompleteAndMerge, resourceRequest]);

  return {
    onMarkResourceRequestAsCompleteAndMerge,
    isLoading: loading
  };
};

export default useMarkResourceRequestAsCompleteAndMerge;
