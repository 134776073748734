import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ColumnGroupOption from './ColumnGroupOption';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0
  }
}));

export const ColumnGroup = ({
  title,
  columns,
  selectedColumns,
  onColumnClick,
  noOfGroups
}) => {
  const classes = useStyles();
  const { xs, sm, md } = useMemo(
    () =>
      noOfGroups <= 2
        ? { xs: 6, sm: 6, md: 6 }
        : noOfGroups === 3
        ? { xs: 6, sm: 6, md: 4 }
        : { xs: 6, sm: 4, md: 3 },
    [noOfGroups]
  );

  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <FormControl component="fieldset" className={classes.root}>
        {title && <FormLabel component="legend">{title}</FormLabel>}
        <FormGroup>
          {columns
            .filter(c => !c.hideInSettingsDialog)
            .map(column => (
              <ColumnGroupOption
                key={column.field}
                columnId={column.field}
                label={column.value}
                secondaryLabel={column.secondaryValue}
                disabled={column.required}
                checked={selectedColumns.includes(column.field)}
                onClick={onColumnClick}
              />
            ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

ColumnGroup.propTypes = {
  noOfGroups: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  onColumnClick: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      secondaryValue: PropTypes.any,
      hideInSettingsDialog: PropTypes.bool
    })
  )
};

export default ColumnGroup;
