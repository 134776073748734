import { gql } from 'graphql-tag';

export const projectClientsFragment = gql`
  fragment ProjectClients on Project {
    id
    clients {
      client {
        displayText
        id
      }
    }
    clientRepresentative {
      displayText
      id
    }
  }
`;
