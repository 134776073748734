import React from 'react';
import PropTypes from 'prop-types';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import {
  HolidaySummaryBlocks,
  TimeOffSummaryBlocks
} from '~/modules/resourcing/common/components';
import { useQuickAllocationContext } from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import QuickAllocationUserTimeoffSummaryBlock from '../QuickAllocationUserTimeoffSummaryBlock';
import QuickAllocationUserPeriodSummaryBlock from './QuickAllocationUserPeriodSummaryBlock';

const mapToEntryDates = entries =>
  entries.map(entry => ({
    ...entry,
    startDate: mapRepliconDateToUtcObject(entry.dateRange.startDate),
    endDate: mapRepliconDateToUtcObject(entry.dateRange.endDate)
  }));

const QuickAllocationUserPeriodSummaryBlocks = ({
  resourceAvailabilitySummarySeries,
  timeoffSummaryPeriods,
  holidaySummaryPeriods,
  timeOffs,
  holidays,
  resourceId,
  showTimeOffRow,
  showActuals
}) => {
  const {
    chartStartDate,
    scale,
    dateRange: chartDisplayDateRange
  } = useQuickAllocationContext();

  const timeOffPeriods = mapToEntryDates(timeOffs || []);
  const holidayPeriods = (holidays || []).map(h => ({
    ...h,
    entryDate: mapRepliconDateToUtcObject(h.date)
  }));

  return (
    <>
      {(resourceAvailabilitySummarySeries || []).map(entry => {
        const startDate = mapIsoStringtoUtcObject(entry.dateRange.startDate);
        const endDate = mapIsoStringtoUtcObject(entry.dateRange.endDate);

        const holidayEntries = holidayPeriods.filter(
          c => c.entryDate <= endDate && c.entryDate >= startDate
        );

        const timeoffEntries = timeOffPeriods.filter(
          c => c.startDate <= endDate && c.endDate >= startDate
        );

        return (
          <div key={entry.dateRange.startDate}>
            <QuickAllocationUserPeriodSummaryBlock
              key={entry.dateRange.startDate}
              startDate={startDate}
              endDate={endDate}
              allocatedDuration={entry.allocatedDuration}
              holidayDuration={entry.holidayDuration}
              scheduledDuration={entry.scheduledDuration}
              timeOffDuration={entry.timeOffDuration}
              timeOffs={timeoffEntries}
              holidays={holidayEntries}
              resourceId={resourceId}
            />
            {showTimeOffRow && (
              <QuickAllocationUserTimeoffSummaryBlock
                key={`${entry.dateRange.startDate}_timeoff`}
                startDate={startDate}
                endDate={endDate}
                holidayDuration={entry.holidayDuration}
                holidays={holidayEntries}
                timeOffDuration={entry.timeOffDuration}
                timeOffs={timeoffEntries}
                scheduledDuration={entry.scheduledDuration}
                showActuals={showActuals}
              />
            )}
          </div>
        );
      })}
      <HolidaySummaryBlocks
        scale={scale}
        chartStartDate={chartStartDate}
        holidaySummaryPeriods={holidaySummaryPeriods}
        holidays={holidays}
        chartDisplayDateRange={chartDisplayDateRange}
        showTooltip
        clickable
      />
      <TimeOffSummaryBlocks
        scale={scale}
        chartStartDate={chartStartDate}
        timeoffSummaryPeriods={timeoffSummaryPeriods}
        timeOffs={timeOffs}
        chartDisplayDateRange={chartDisplayDateRange}
        showTooltip
        clickable
      />
    </>
  );
};

QuickAllocationUserPeriodSummaryBlocks.propTypes = {
  resourceAvailabilitySummarySeries: PropTypes.array,
  timeoffSummaryPeriods: PropTypes.array,
  holidaySummaryPeriods: PropTypes.array,
  timeOffs: PropTypes.array,
  holidays: PropTypes.array,
  resourceId: PropTypes.string,
  showTimeOffRow: PropTypes.bool,
  showActuals: PropTypes.bool
};

export default QuickAllocationUserPeriodSummaryBlocks;
