import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  column: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
}));

export const PeriodFormatter = ({ field, record }) => {
  const classes = useStyles();
  const textValue = record[field] ? record[field].dateRangeValue : null;

  return (
    <Typography className={classes.column} variant="body2">
      {textValue}
    </Typography>
  );
};

PeriodFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default PeriodFormatter;
