import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import {
  itemWidth,
  actionsWidth
} from '~/modules/common/charts/timeline/calculations';
import { AddResourceIcon } from '~/modules/common/components';
import { useHasPermission } from '~/modules/common/permissions';
import { useAllocateResourcePopupHandler } from '~/modules/resourcing/components/AllocateResourcePopup/hooks/useAllocateResourcePopupHandler';
import AllocateResourcePopup from '~/modules/resourcing/components/AllocateResourcePopup/AllocateResourcePopup';
import {
  usePopperHandlers,
  ResourceChartTotalPopper
} from '~/modules/resourcing/components/ResourceChartTotalPopper';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { useResourceRequestToolbarContext } from '../../../hooks';
import ResourceRequestChartFooterRow from '../ResourceRequestChartFooterRow';
import TotalsFooterPopoverCell from '../TotalsFooterPopoverCell/TotalsFooterPopoverCell';

export const useActionStyles = makeStyles(theme => ({
  actionButton: {
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    margin: theme.spacing(0, 2, 0, 0)
  }
}));

const leftComponentWidth = actionsWidth + itemWidth * 2;
const actionButtonIcon = <AddResourceIcon />;

export const ResourceAllocationChartFooter = ({
  handleDirectResourceAllocationSubmit,
  isEditSwitchEnabled,
  allocationsTotals,
  loading,
  createAllocationExistingUserLoading,
  allocateResourcePopupAnchorEl,
  allocateResourcePopupMenuClose,
  allocateResourcePopupClick,
  setExistingResourceAllocation,
  existingResourceAllocation
}) => {
  const actionClasses = useActionStyles();
  const { project } = useProjectContext();
  const [totalScheduledHours, setTotalScheduledHours] = useState(0);
  const { scale, chartDates } = useResourceRequestToolbarContext();

  const isEditAllowed =
    useHasPermission({
      actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
    }) && isEditSwitchEnabled;

  const canViewCostData = project.permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  const {
    anchorEl: totalPopupAnchorEl,
    onMenuClose: totalPopupOnMenuClose,
    onMenuClick: totalPopupOnMenuClick,
    handleKeyDown
  } = usePopperHandlers();

  const {
    initialValues,
    handleAdd,
    validationSchema
  } = useAllocateResourcePopupHandler({
    handleAddAllocation: handleDirectResourceAllocationSubmit,
    project,
    scale,
    setExistingResourceAllocation
  });

  const leftComponent = useMemo(
    () =>
      isEditAllowed ? (
        <Button
          className={actionClasses.actionButton}
          size="small"
          color="primary"
          variant="text"
          onClick={allocateResourcePopupClick}
          startIcon={actionButtonIcon}
        >
          <FormattedMessage id="resourceRequestButtons.allocateResource" />
        </Button>
      ) : null,
    [isEditAllowed, actionClasses.actionButton, allocateResourcePopupClick]
  );

  const showAllocationTotals = Boolean(
    allocationsTotals.totalHours || allocationsTotals.totalCost?.amount
  );

  return (
    <>
      <ResourceRequestChartFooterRow
        scale={scale}
        scaleItemCount={chartDates.length}
        totalLabel="resourceRequestChartFooter.totalAllocated"
        leftComponent={leftComponent}
        leftComponentWidth={leftComponentWidth}
      >
        {showAllocationTotals && (
          <TotalsFooterPopoverCell
            allocationsTotals={allocationsTotals}
            handleClick={totalPopupOnMenuClick}
            handleKeyDown={handleKeyDown}
            onClickAway={totalPopupOnMenuClose}
            canViewCostData={canViewCostData}
            loading={loading}
          >
            <ResourceChartTotalPopper
              id="resource-allocation-total-popper"
              anchorEl={totalPopupAnchorEl}
              onClose={totalPopupOnMenuClose}
              allocatedHours={allocationsTotals.totalHours}
              allocatedCost={allocationsTotals.totalCost}
              viewCosts={canViewCostData}
              viewAllocation
              viewDifference={false}
            />
          </TotalsFooterPopoverCell>
        )}
      </ResourceRequestChartFooterRow>
      {allocateResourcePopupAnchorEl && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleAdd}
          validate={validationSchema}
        >
          <AllocateResourcePopup
            anchorEl={allocateResourcePopupAnchorEl}
            onClose={allocateResourcePopupMenuClose}
            handleAdd={handleAdd}
            totalScheduledHours={totalScheduledHours}
            setTotalScheduledHours={setTotalScheduledHours}
            showAddProject={false}
            showAddUser
            isSaving={createAllocationExistingUserLoading}
            showLoading
            setExistingResourceAllocation={setExistingResourceAllocation}
            existingResourceAllocation={existingResourceAllocation}
          />
        </Formik>
      )}
    </>
  );
};

ResourceAllocationChartFooter.propTypes = {
  handleDirectResourceAllocationSubmit: PropTypes.func.isRequired,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  allocationsTotals: PropTypes.object,
  loading: PropTypes.bool,
  createAllocationExistingUserLoading: PropTypes.bool,
  allocateResourcePopupAnchorEl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node
  ]),
  allocateResourcePopupMenuClose: PropTypes.func.isRequired,
  allocateResourcePopupClick: PropTypes.func.isRequired,
  setExistingResourceAllocation: PropTypes.func,
  existingResourceAllocation: PropTypes.object
};

export default ResourceAllocationChartFooter;
