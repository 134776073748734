import { mergeWith } from 'lodash';

export const styles = ({ theme, overrideChartStyles }) =>
  mergeWith(
    {
      chartTitle: {
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
        fontSize: 29,
        fill: theme.palette.text.primary
      },

      chartValue: {
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: theme.typography.fontFamily,
        fontSize: 18,
        fill: theme.palette.text.primary
      },
      circleWithNoData: {
        data: {
          strokeWidth: 2,
          stroke: theme.palette.grey[400],
          cursor: 'pointer'
        }
      },
      circleWithData: {
        data: {
          cursor: 'pointer'
        }
      }
    },
    overrideChartStyles
  );
