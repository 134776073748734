import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { User } from '~/modules/common/components';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import {
  useEligiblePortfolioManagers,
  useIsPortfolioManagerEditable
} from './hooks';

const renderTags = values =>
  values.map(option => (
    <User user={option} disablePadding key={option.displayText} />
  ));

const renderOption = option => <User user={option} disablePadding />;

export const PortfolioManagerDropdown = ({
  value,
  onChange,
  isNew,
  isSubPortfolio
}) => {
  const { formatMessage } = useIntl();
  const { portfolioManagers } = useEligiblePortfolioManagers();
  const isPortfolioManagerEditable = useIsPortfolioManagerEditable({
    isNew,
    isSubPortfolio
  });

  const handleOnChange = useCallback(
    managers =>
      onChange(
        managers && managers.length ? managers[managers.length - 1] : null
      ),
    [onChange]
  );

  const selectedValue = useMemo(() => (value ? [value] : []), [value]);

  return (
    <SimpleAutocomplete
      inputLabel={formatMessage({ id: 'portfolio.portfolioManager' })}
      options={portfolioManagers}
      renderTags={renderTags}
      value={selectedValue}
      disabled={!isPortfolioManagerEditable}
      onChange={handleOnChange}
      multiple
      fullWidth
      noOptionsText={formatMessage({ id: 'portfolio.noPortfolioManager' })}
      optionPropText="displayText"
      renderOption={renderOption}
    />
  );
};

PortfolioManagerDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
  isSubPortfolio: PropTypes.bool
};

export default PortfolioManagerDropdown;
