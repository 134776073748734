import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  headerRow: {},
  title: {
    minWidth: '165px'
  },
  headerCell: {
    verticalAlign: 'bottom',
    padding: '4px 8px 4px 8px'
  }
});

export default withStyles(styles);
