import { useState } from 'react';
import { isNullOrUndefined } from '~/modules/common/util';
import { useUpdateTaskResourceAllocations } from './useUpdateTaskResourceAllocations';
import { useRemoveTaskResourceAllocations } from './useRemoveTaskResourceAllocations';

export const useChangeHandlers = ({ taskId, dirty, setFieldValue }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const updateTaskResourceAllocations = useUpdateTaskResourceAllocations(
    taskId
  );
  const removeTaskResourceAllocations = useRemoveTaskResourceAllocations(
    taskId
  );

  const onResourceHoursBlur = async (e, allocation) => {
    if (!dirty) return;
    setIsUpdating(true);
    if (isNullOrUndefined(allocation.allocatedHours)) {
      await removeTaskResourceAllocations({
        resourceAllocations: [{ resourceId: allocation.resource.id }]
      });
    }

    await updateTaskResourceAllocations([
      {
        resourceId: allocation.resource.id,
        allocatedHours: allocation.allocatedHours
      }
    ]);
    setIsUpdating(false);
  };

  const onResourceHoursChange = (e, index) => {
    const { value } = e.target;

    setFieldValue(`resourceAllocations[${index}].allocatedHours`, value);
  };

  const onResourceAllocationAdd = async resourceAllocation => {
    setIsUpdating(true);
    await updateTaskResourceAllocations([
      {
        resourceId: resourceAllocation.resource.id,
        allocatedHours: resourceAllocation.allocatedHours
      }
    ]);
    setIsUpdating(false);
  };

  const onResourceAllocationRemove = async id => {
    setIsUpdating(true);
    await removeTaskResourceAllocations({
      resourceAllocations: [{ resourceId: id }],
      refetchQueries: [
        'getTaskResourceAllocations',
        'getFilteredUserIdsAssignedToTask',
        'getTaskResourceAllocationSummary'
      ]
    });
    setIsUpdating(false);
  };

  return {
    isUpdating,
    onResourceHoursBlur,
    onResourceHoursChange,
    onResourceAllocationAdd,
    onResourceAllocationRemove
  };
};

export default useChangeHandlers;
