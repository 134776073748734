import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';

export const GET_REVENUE_RECOGNITION_POLICY = gql`
  query revenueRecognitionPolicies {
    revenueContractClauses {
      id
      displayText
    }
  }
`;

const filterAndMapRevenuePolicies = (revenueContractClauses, searchTerm) =>
  (revenueContractClauses || [])
    .filter(
      policy =>
        policy &&
        policy.displayText &&
        policy.displayText
          .toLocaleLowerCase()
          .includes((searchTerm || '').toLocaleLowerCase())
    )
    .map(policy => ({
      ...policy,
      value: policy.id,
      label: policy.displayText,
      key: policy.displayText
    }));

export const useRevenueRecognitionPolicy = ({
  searchTerm,
  includeNoneOption = false,
  noneOptionText = ''
}) => {
  const { data, loading } = useQuery(GET_REVENUE_RECOGNITION_POLICY, {
    fetchPolicy: 'cache-and-network',
    context: {
      debounceKey: 'revenue-recognition-policy-option',
      debounceTimeout: 250
    }
  });
  const revenueContractClauses = get(data, 'revenueContractClauses', []);

  const revenuePolicies = filterAndMapRevenuePolicies(
    revenueContractClauses,
    searchTerm
  );

  return {
    loading,
    revenuePolicies:
      revenuePolicies.length && includeNoneOption
        ? prependNoneItem(noneOptionText, revenuePolicies)
        : revenuePolicies
  };
};

export const searchableRevenuePolicies = ({
  client,
  intl
}) => async searchTerm => {
  const result = await client.query({
    query: GET_REVENUE_RECOGNITION_POLICY
  });
  const revenueContractClauses = get(result, 'data.revenueContractClauses', []);

  return prependNoneItem(
    intl.formatMessage({
      id: 'revenueRecognitionPolicyFacet.noRevenueRecognitionPolicy'
    }),
    filterAndMapRevenuePolicies(revenueContractClauses, searchTerm)
  );
};

export const useSearchableProjectOptions = () => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  return {
    fetchOptions: searchableRevenuePolicies({
      intl,
      client: apolloClient
    })
  };
};
