/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, makeStyles } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import { useSkillLevels } from '~/modules/common/hooks';
import ErrorDrawer from '~/modules/common/components/TaskDrawer/ErrorDrawer';
import LoadingDrawer from '~/modules/common/components/TaskDrawer/LoadingDrawer';
import { useResourceRequestDetails } from '~/modules/resourcing/common/hooks';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from '~/modules/common/components/Drawer';
import {
  ResourceRequestDrawerAllocationDetails,
  ResourceRequestDrawerReadonlyDetails,
  ResourceRequestDrawerTitle
} from '~/modules/resourcing/common/components/ResourceRequestDrawer';
import ResourceRequestDrawerActions from './ResourceRequestDrawerActions';

const useStyles = makeStyles(theme => ({
  paper: {
    width: theme.spacing(87.5)
  }
}));

export const useHeaderStyles = makeStyles(theme => ({
  headerArea: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0, 1, 2)
  },
  grow: {
    flexGrow: 0
  }
}));

const useContentStyles = makeStyles(theme => ({
  contentArea: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0)
  }
}));

export const useFooterStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.drawer
  }
}));

export const ResourceRequestDrawer = ({ resourceRequestId, onClose, open }) => {
  const headerClasses = useHeaderStyles();
  const classes = useStyles();
  const footerClasses = useFooterStyles();
  const contentClasses = useContentStyles();

  const {
    loading: isResourceRequestDetailsLoading,
    projectRequestDetails: { resourceRequest, resourceAllocations, project },
    error: errorData
  } = useResourceRequestDetails(resourceRequestId);

  const { maxSkillLevel, isSkillLoading } = useSkillLevels();

  const isLoading = isSkillLoading || isResourceRequestDetailsLoading;
  const isError = errorData && errorData.error;

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onEscapeKeyDown={onClose}
      onBackdropClick={onClose}
      classes={classes}
    >
      {Boolean(isLoading) && <LoadingDrawer onClose={onClose} />}
      {Boolean(isError) && <ErrorDrawer data={errorData} onClose={onClose} />}
      {Boolean(!isError && !isLoading) && (
        <>
          <DrawerHeader classes={headerClasses} onClose={onClose}>
            <ResourceRequestDrawerTitle
              resourceRequest={resourceRequest}
              project={project}
            />
          </DrawerHeader>
          <Divider />
          <DrawerContent className={contentClasses.contentArea}>
            <ResourceRequestDrawerReadonlyDetails
              resourceAllocations={resourceAllocations}
              resourceRequest={resourceRequest}
              drawerOnClose={onClose}
              maxSkillLevel={maxSkillLevel}
              project={project}
            />
            <ResourceRequestDrawerAllocationDetails
              project={project}
              resourceRequest={resourceRequest}
              resourceAllocations={resourceAllocations}
              showRequestAllocations
              showRequestAllocationTotals
            />
            <DrawerFooter classes={footerClasses}>
              <ResourceRequestDrawerActions onClose={onClose} />
            </DrawerFooter>
          </DrawerContent>
        </>
      )}
    </MuiDrawer>
  );
};

ResourceRequestDrawer.propTypes = {
  resourceRequestId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default ResourceRequestDrawer;
