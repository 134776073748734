import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useEffect } from 'react';
import { BACKGROUND_JOB_STATUS } from '../enums';

export const BATCH_STATUS_QUERY = gql`
  query getBackgroundJob($jobId: String!) {
    getBackgroundJob(jobId: $jobId) {
      status
      result
    }
  }
`;

export const useBackgroundJobStatus = ({ jobId }) => {
  const {
    stopPolling,
    data: { getBackgroundJob = {} } = {},
    client
  } = useQuery(BATCH_STATUS_QUERY, {
    variables: {
      jobId
    },
    fetchPolicy: 'network-only',
    pollInterval: 500
  });

  const { status, result } = getBackgroundJob;

  const hasProcessed =
    status === BACKGROUND_JOB_STATUS.SUCCEEDED ||
    status === BACKGROUND_JOB_STATUS.CANCELLED ||
    status === BACKGROUND_JOB_STATUS.FAILED;

  useEffect(() => {
    if (!hasProcessed) return;
    stopPolling();
  }, [status, hasProcessed, stopPolling]);

  return { client, status, result, hasProcessed };
};
export default useBackgroundJobStatus;
