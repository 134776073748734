import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({}));

export const useTableStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiFormControl-fullWidth': {
      width: theme.spacing(26)
    }
  },
  tableRoot: ({ readOnly }) => ({
    borderCollapse: 'separate',
    '& thead tr th:nth-last-child(3)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    '& thead tr th:nth-last-child(2)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    '& tbody tr:last-child td': {
      borderBottom: 'none !important'
    },
    '& tbody tr td:nth-last-child(4)': {
      verticalAlign: readOnly ? 'none' : 'top',
      borderLeft: readOnly ? 'none' : `1px solid ${theme.palette.grey[300]}`
    },
    '& tbody tr td:nth-last-child(3)': {
      borderLeft: readOnly ? `1px solid ${theme.palette.grey[300]}` : 'none'
    }
  }),
  groupLastRowCell: {
    borderBottom: `1px solid ${theme.palette.grey[300]} !important`
  },
  tableHeaderCell: ({ readOnly }) => ({
    borderTop: 'none',
    display: 'table-cell',
    height: theme.spacing(4),
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: readOnly ? theme.spacing(0.5, 1) : theme.spacing(0.5, 3, 0.5, 3),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    verticalAlign: 'middle',
    position: 'sticky',
    zIndex: 2,
    backgroundColor: 'rgb(255, 255, 255)',
    top: readOnly ? '0px' : '46px',
    fontWeight: 500,
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  }),
  tableCell: ({ readOnly }) => ({
    display: 'table-cell',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: readOnly ? theme.spacing(0, 1) : theme.spacing(0.5, 3, 0.5, 3),
    verticalAlign: readOnly ? 'middle' : 'top',
    backgroundColor: 'inherit',
    borderBottom: 'none'
  }),
  deleteGroupCell: {
    display: 'table-cell',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: 0,
    verticalAlign: 'top',
    backgroundColor: 'inherit'
  },
  tableFooterCell: {},
  tableRow: {}
}));

export const useColumnStyles = makeStyles(theme => ({
  dimensionCell: ({ readOnly }) => ({
    minWidth: theme.spacing(25),
    ...(readOnly ? { maxWidth: theme.spacing(37.5) } : {})
  }),
  lastDimensionCell: ({ readOnly }) => ({
    minWidth: theme.spacing(25),
    ...(readOnly ? { maxWidth: theme.spacing(37.5) } : {})
  }),
  addRemoveEntry: {
    display: 'table-cell',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 0, 0, 0),
    verticalAlign: 'top',
    backgroundColor: 'rgb(255, 255, 255)',
    borderBottom: 'none'
  },
  amount: ({ readOnly }) => ({
    display: 'table-cell',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: readOnly ? theme.spacing(1.5, 0.5) : theme.spacing(0.5, 3, 0.5, 3),
    verticalAlign: readOnly ? 'none' : 'top',
    backgroundColor: 'rgb(255, 255, 255)',
    borderBottom: 'none',
    textAlign: 'right !important'
  })
}));
