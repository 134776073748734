import { useFormikContext } from 'formik';
import { PROJECT_REQUEST_PERMISSION } from '~/modules/common/enums';

export const useProjectRequestObjectPermissions = () => {
  const {
    values: { permittedActionUris }
  } = useFormikContext();

  return {
    canEditProjectRequest: permittedActionUris.includes(
      PROJECT_REQUEST_PERMISSION.EDIT_PROJECT_REQUEST
    ),
    canEditPlanningData: permittedActionUris.includes(
      PROJECT_REQUEST_PERMISSION.EDIT_PLANNING_DATA
    ),
    canViewPlanningData: permittedActionUris.includes(
      PROJECT_REQUEST_PERMISSION.VIEW_PLANNING_DATA
    )
  };
};
export default useProjectRequestObjectPermissions;
