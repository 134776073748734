import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';
import { PROJECT_STATUS } from '~/modules/common/enums';

export const ALLOCATED_PROJECTS_QUERY = gql`
  query Eager_GetMyAllocatedProjects(
    $page: Int
    $pageSize: Int
    $effectiveDate: Date
    $allocationStatus: [ResourceAllocationStatus!]
    $filter: ProjectAllocationFilter
  ) {
    me {
      id
      projectAllocations(
        page: $page
        pageSize: $pageSize
        effectiveDate: $effectiveDate
        allocationStatus: $allocationStatus
        filter: $filter
      ) {
        id
        role {
          id
          displayText
        }
        project {
          id
          displayText
        }
        startDate
        endDate
        load
        totalHoursWorked
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
      }
    }
  }
`;

export const useLoadMoreProjectsCallback = ({
  page = 1,
  initialHasMore = true,
  effectiveDate,
  allocationStatus,
  pageSize,
  fetchMore
}) => {
  const [hasMore, setHasMore] = useState(initialHasMore);
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    featureFlags: { isPsaPrpTaskBeatUpdates2Enabled }
  } = useMeContext();

  useEffect(() => {
    setHasMore(initialHasMore); // AssignedProjectsCard is getting rendered multiple times. This is required to capture the final state after all the renders are completed
  }, [initialHasMore]);

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }
    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          page: page + 1,
          pageSize,
          effectiveDate,
          allocationStatus,
          ...(isPsaPrpTaskBeatUpdates2Enabled && {
            filter: {
              projectWorkflowStage: [
                PROJECT_STATUS.INITIATE.toUpperCase(),
                PROJECT_STATUS.PLANNING.toUpperCase(),
                PROJECT_STATUS.EXECUTION.toUpperCase()
              ]
            }
          })
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            setHasMore(hasMore);

            return prev;
          }
          setHasMore(fetchMoreResult.me.projectAllocations.length === pageSize);

          return {
            me: {
              ...prev.me,
              projectAllocations: [
                ...prev.me.projectAllocations,
                ...fetchMoreResult.me.projectAllocations
              ]
            }
          };
        }
      });
    } finally {
      setLoadingMore(false);
    }
  }, [
    allocationStatus,
    effectiveDate,
    fetchMore,
    hasMore,
    isPsaPrpTaskBeatUpdates2Enabled,
    page,
    pageSize
  ]);

  return {
    loadingMore,
    loadMore,
    hasMore,
    page
  };
};

export const useMyAllocatedProjectsQuery = (options = {}) => {
  const { variables = {} } = options;
  const {
    featureFlags: { isPsaPrpTaskBeatUpdates2Enabled }
  } = useMeContext();
  const queryOptions = {
    variables: {
      ...variables,
      ...(isPsaPrpTaskBeatUpdates2Enabled && {
        filter: {
          projectWorkflowStage: [
            PROJECT_STATUS.INITIATE.toUpperCase(),
            PROJECT_STATUS.PLANNING.toUpperCase(),
            PROJECT_STATUS.EXECUTION.toUpperCase()
          ]
        }
      })
    },
    fetchPolicy: 'cache-and-network'
  };

  return useQuery(ALLOCATED_PROJECTS_QUERY, queryOptions);
};
