import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const ADD_SKILL = gql`
  mutation AddSkill($addSkillsInput: AddSkillsInput!) {
    addSkill: addSkill2(addSkillsInput: $addSkillsInput) {
      id
      uri
      displayText
      description
      category {
        id
        uri
        displayText
      }
    }
  }
`;

export const useAddSkillMutation = () => useMutation(ADD_SKILL, {});

export default useAddSkillMutation;
