import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(1)
  },
  chart: { backgroundColor: '#FFFFFF', border: '40px solid #E5E5E5' }
}));

export const DonutChartLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton width={40} height={300} variant="rect" />
      <Skeleton
        width={300}
        height={300}
        variant="circle"
        classes={useMemo(() => ({ root: classes.chart }), [classes.chart])}
      />
    </div>
  );
};

export default DonutChartLoading;
