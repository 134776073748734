import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';

export const useVariationMessage = ({
  intl,
  variationStatus,
  isPSAPRPTaskEstimateCalculationMethodEnabled
}) => {
  switch (variationStatus) {
    case ESTIMATED_HOURS_VARIATION_STATUS.OVER:
      return intl.formatMessage({
        id: `projectTasksPage.${
          isPSAPRPTaskEstimateCalculationMethodEnabled
            ? 'aboveHours'
            : 'exceededHours'
        }`
      });
    case ESTIMATED_HOURS_VARIATION_STATUS.UNDER:
      return intl.formatMessage({
        id: 'projectTasksPage.belowHours'
      });
    case ESTIMATED_HOURS_VARIATION_STATUS.MATCHING:
      return intl.formatMessage({
        id: 'projectTasksPage.hoursMatched'
      });
    default:
      return null;
  }
};

export default useVariationMessage;
