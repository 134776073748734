import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

export const useStyles = makeStyles({
  container: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    left: ({ sizes, startDate, endDate }) =>
      `${!startDate && endDate ? sizes.left - 132 : sizes.left}px`,
    top: ({ sizes, taskHeight }) => `${sizes.top + taskHeight + 17}px`
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  hoursContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  hours: ({ variationStatus }) => ({
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    fontWeight: variationStatus ? '600' : 400,
    pointerEvents: 'none'
  }),
  divider: ({ isRTL }) => ({
    color: theme.palette.taskRollUp.text.subHeading,
    marginRight: theme.spacing(0.5),
    marginLeft: isRTL ? theme.spacing(0.5) : 0
  }),
  timeValue: {
    fontWeight: 600
  },
  subTimeValue: {
    fontWeight: 500
  },
  noBorderRightRadius: ({ isCostVariationPillBoxVisible }) => ({
    ...(isCostVariationPillBoxVisible && {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    })
  })
});

export const useTaskBarTextStyles = makeStyles({
  container: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(2.25)
  },
  textContainer: {
    height: theme.spacing(2.25),
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.25)
  },
  hours: {
    fontSize: theme.typography.caption.fontSize,
    marginRight: theme.spacing(0.5),
    fontWeight: 600,
    pointerEvents: 'none'
  },
  hoursWithoutVariationStatus: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 400
  },
  divider: {
    color: theme.palette.taskRollUp.text.subHeading,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  },
  dividerWithoutRTL: {
    marginLeft: 0
  },
  timeValue: {
    fontWeight: 600,
    fontSize: theme.typography.caption.fontSize
  },
  subTimeValue: {
    fontWeight: 500,
    fontSize: theme.typography.caption.fontSize
  },
  noBorderRightRadius: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
});

export default useStyles;
