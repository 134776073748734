import React from 'react';
import User from '~/modules/common/components/User';
import { projectManagerTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import ProjectManagerFacetDetails from './ProjectManagerFacetDetails';

export default {
  makeCriteriaProvider,
  tag: projectManagerTag,
  DetailsControl: ProjectManagerFacetDetails,
  // eslint-disable-next-line react/prop-types
  SuggestionItem: ({ option }) => <User user={option} />
};
