import { useIntl } from 'react-intl';

export const useMenuProps = ({ messageId }) => {
  const intl = useIntl();

  return {
    MenuListProps: {
      'aria-label': intl.formatMessage({ id: messageId })
    }
  };
};
