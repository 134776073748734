import React from 'react';
import { PropTypes } from 'prop-types';
import {
  RefundIcon,
  BillIcon,
  CreditMemoIcon,
  PaymentIcon
} from '~/modules/common/components/Icons';

const TypeIcons = {
  BILL: <BillIcon />,
  PAYMENT: <PaymentIcon />,
  CREDITMEMO: <CreditMemoIcon />,
  REFUND: <RefundIcon />
};

const TypeIcon = ({ id }) => TypeIcons[id] || null;

TypeIcon.propTypes = {
  id: PropTypes.string.isRequired
};
export default TypeIcon;
