import React from 'react';
import { withFormik } from 'formik';
import { array, object, number } from 'yup';
import { orderBy, isEmpty, pickBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me';
import { customValidtorOnKey, duplicateValidator } from '../validator';

export const AMOUNT_MAX = 999999999999;

export const initialState = ({
  me,
  scriptDetails: {
    id,
    parameters,
    displayText,
    description,
    estimatedAmount,
    contractAmount,
    scripts,
    isAddMode = false,
    footerProps
  },
  scriptType
}) => {
  const values = pickBy(
    {
      id: id || undefined,
      description: description || undefined,
      scriptType: scriptType || undefined,
      parameters: orderBy(parameters || [], 'sortIndex', ['asc']),
      scripts: (scripts || []).filter(script => script.id || script.scriptId),
      displayText: displayText || undefined,
      estimatedAmount: estimatedAmount || undefined,
      hasHeaderComponent: contractAmount ? true : undefined,
      headerComponentProps: contractAmount ? { contractAmount } : undefined,
      footerProps: footerProps || undefined,
      isAddMode: isAddMode || undefined,
      featureFlags: me?.featureFlags
    },
    x => x !== undefined
  );

  return values;
};

const getCustomError = value => {
  const errorDetails =
    value && typeof value === 'object' ? value.errorDetails : null;

  return errorDetails;
};

const getDuplicateError = ({ scripts, value, key }) => {
  const validator = duplicateValidator[key];

  if (validator) {
    const fieldValues = scripts.map(x => x[key]);

    return validator(fieldValues, value, key);
  }

  return false;
};

const validateFields = ({ data, intl }) => {
  const errorMessage = {};

  const { parameters, scripts, featureFlags } = data;
  const requiredParams = parameters
    .filter(param => param.required)
    .map(p => {
      errorMessage[p.id] = p.displayText;

      return p.id;
    });
  const errors = {};

  const filteredScripts = scripts.filter(x => x);

  filteredScripts.forEach((value, index) => {
    const keys = Object.keys(value);

    keys.forEach(key => {
      if (
        requiredParams.includes(key) &&
        (typeof value[key] === 'object'
          ? isEmpty(value[key])
          : value[key] === null || value[key] === undefined)
      ) {
        errors[key] = {
          ...errors[key],
          [index]: intl.formatMessage(
            {
              id: 'scriptParamEditor.validations.required'
            },
            { field: errorMessage[key] }
          )
        };
      }
      const customError = getCustomError(value[key]);

      if (customError) {
        errors[key] = {
          ...errors[key],
          [index]: customError
        };
      }

      const getCustomValidation = customValidtorOnKey[key];

      if (getCustomValidation) {
        const msg = getCustomValidation(value, intl, featureFlags);

        if (msg)
          errors[key] = {
            ...errors[key],
            [index]: msg
          };
      }

      const isDuplicate = getDuplicateError({
        scripts: filteredScripts,
        value,
        key
      });

      if (isDuplicate) {
        errors[key] = {
          [index]: intl.formatMessage(
            {
              id: 'scriptParamEditor.validations.duplicate'
            },
            { field: errorMessage[key] }
          ),
          ...errors[key]
        };
      }
    });
  });

  return errors;
};

export const validationSchema = ({ intl }) =>
  object().shape({
    headerComponentProps: object().shape({
      contractAmount: object().shape({
        amount: number().max(
          AMOUNT_MAX,
          intl.formatMessage(
            {
              id: 'scriptParamEditor.validations.maxAmount'
            },
            { maxAmount: AMOUNT_MAX }
          )
        )
      })
    }),

    parameters: array().test('requiredTest', function validate() {
      const fieldErrors = validateFields({
        data: this.parent,
        intl
      });

      return Object.keys(fieldErrors).length
        ? this.createError({
            path: this.path,
            message: {
              ...fieldErrors
            }
          })
        : {};
    })
  });

export const onSubmit = async (values, { props: { onSave } }) => onSave(values);

const withFormState = BaseComponent => props => {
  const me = useMeContext();
  const intl = useIntl();

  return <BaseComponent me={me} intl={intl} {...props} />;
};

export default BaseComponent =>
  withFormState(
    withFormik({
      mapPropsToValues: initialState,
      handleSubmit: onSubmit,
      validationSchema
    })(BaseComponent)
  );
