const dialogContents = {
  PROJECT: {
    object: 'bulkRemoveDialog.project',
    objects: 'bulkRemoveDialog.projects',
    remove: {
      title: 'bulkRemoveDialog.removeProjects.title',
      message: 'bulkRemoveDialog.removeProjects.message'
    },
    archive: {
      title: 'bulkRemoveDialog.archiveProjects.title',
      message: 'bulkRemoveDialog.archiveProjects.message'
    },
    removeAndArchive: {
      title: 'bulkRemoveDialog.removeAndArchiveProjects.title',
      message: 'bulkRemoveDialog.removeAndArchiveProjects.message'
    }
  }
};

export default dialogContents;
