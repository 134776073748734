import { PropTypes } from 'prop-types';
import React from 'react';
import useRoles from '~/modules/common/hooks/useRoles';
import GenericRateCard from '~/modules/rateCard';
import { useMeContext } from '~/modules/me';
import { useClientRate } from '~/modules/rateCard/hooks/useClientRate';
import { useAllProjectRates } from './enhancers/useAllProjectRates';
import { usePutProjectRatesHandler } from './enhancers/usePutProjectRatesHandler';

const RateCard = props => {
  const { projectUri, clientUri } = props;
  const me = useMeContext();
  const { defaultOptions: roles, isLoading: isRolesLoading } = useRoles({
    fetchPolicy: 'no-cache',
    includeNoneOption: false,
    includeBillingScheduleEntries: true
  });

  const { isProjectRatesLoading, ...projectRatesProps } = useAllProjectRates({
    projectId: projectUri
  });

  const { defaultRates } = useClientRate(clientUri);

  const { putProjectRates } = usePutProjectRatesHandler();

  const isLoading = isRolesLoading || isProjectRatesLoading;

  return (
    <GenericRateCard
      me={me}
      roles={roles}
      isLoading={isLoading}
      {...projectRatesProps}
      {...props}
      defaultRates={defaultRates}
      parentUri={projectUri}
      putRatesHandler={putProjectRates}
    />
  );
};

RateCard.propTypes = {
  projectUri: PropTypes.string,
  clientUri: PropTypes.string
};

export default RateCard;
