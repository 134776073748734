import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0
  }
}));

export const TextLoading = ({ width = '30%' }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Skeleton width={width} height={30} />
    </div>
  );
};

TextLoading.propTypes = {
  width: PropTypes.string
};

export default TextLoading;
