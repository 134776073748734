import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { ErrorSharp as ErrorIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  errrorIcon: {
    fontSize: theme.typography.h6.fontSize,
    marginRight: theme.spacing(0.5),
    color: theme.palette.error.dark
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'normal',
    fontSize: theme.typography.body1.fontSize
  },
  errorText: {
    color: theme.palette.error.dark
  }
}));

export const MilestoneBillPlanTitle = ({
  classes: classesOverride,
  displayText,
  errorCode
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      {Boolean(errorCode) && <ErrorIcon className={classes.errrorIcon} />}
      <Typography
        className={classNames(classes.title, {
          [classes.errorText]: Boolean(errorCode)
        })}
      >
        {displayText ||
          (errorCode === 'REMOVED_MILESTONE' ? (
            <FormattedMessage id="billPlan.taskRemoved" />
          ) : (
            <NoValue />
          ))}
      </Typography>
    </div>
  );
};

MilestoneBillPlanTitle.propTypes = {
  classes: PropTypes.object,
  displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorCode: PropTypes.string
};

export default MilestoneBillPlanTitle;
