import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';
import useStyles from './useStyles';

export const ResourcingLegend = memo(
  ({ classes: classesOverrides, className, label, color }) => {
    const classes = useStyles({ classes: classesOverrides });

    return (
      <>
        <span
          className={classNames(classes.rootLegend, classes[className])}
          style={{ backgroundColor: color }}
        />
        <span className={classes.legendText}>{label}</span>
      </>
    );
  }
);

ResourcingLegend.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string
};

export default ResourcingLegend;
