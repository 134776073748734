import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useOnListItemClick = project => {
  const history = useHistory();

  return useCallback(() => {
    history.push({
      pathname: `/projects/${project.slug}`,
      state: {
        goBackButtonPath: project.useHistoryForRedirect
          ? history.location.pathname
          : null
      }
    });
  }, [history, project.slug, project.useHistoryForRedirect]);
};
