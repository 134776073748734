import React from 'react';
import { PropTypes } from 'prop-types';
import ImportExportMenuItem from './ImportExportMenuItem';

export const ImportExportMenuItems = ({
  actionItems = [],
  customActionItems = [],
  handleExportStart,
  index
}) => (
  <>
    {(customActionItems || []).map(actionItem => actionItem.menuItem)}
    {actionItems.map(actionItem => {
      const tempActionItem = actionItem;
      const {
        tableKey,
        filter,
        activeColumns,
        activeColumnNameOverrides
      } = tempActionItem;

      if ('tableKey' in tempActionItem) {
        tempActionItem.onClick = () => {
          handleExportStart(
            tableKey,
            filter,
            activeColumns,
            activeColumnNameOverrides
          );
        };
      }

      return (
        <ImportExportMenuItem
          key={`${tableKey}_ImportExportMenuItem`}
          actionItem={tempActionItem}
          index={index}
        />
      );
    })}
  </>
);

ImportExportMenuItems.propTypes = {
  handleExportStart: PropTypes.func.isRequired,
  actionItems: PropTypes.array,
  customActionItems: PropTypes.array,
  index: PropTypes.number
};

export default ImportExportMenuItems;
