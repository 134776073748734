import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MoneyValue, NoValue } from '~/modules/common/components';

const useStyles = makeStyles(() => ({
  list: { padding: 0 }
}));

export const MultiCurrencyMoney = ({ field, record }) => {
  const moneyList = record[field];

  const classes = useStyles();

  return moneyList && moneyList.length ? (
    <ul className={classes.list}>
      {moneyList.map(money => (
        <li key={`${money.currency.symbol}-${money.amount}`}>
          <MoneyValue money={money} />
        </li>
      ))}
    </ul>
  ) : (
    <NoValue />
  );
};

MultiCurrencyMoney.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default MultiCurrencyMoney;
