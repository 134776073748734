import { array, number, object, string, mixed, bool } from 'yup';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import { useMeContext } from '~/modules/me/useMeContext';
import { useUpdateProjectBillingSetting } from './useUpdateProjectBillingSetting';

const getInvoiceCurrency = invoiceCurrency => {
  return invoiceCurrency
    ? {
        __typename: 'Currency',
        id: invoiceCurrency.id,
        symbol: invoiceCurrency.symbol
      }
    : null;
};

const getFilteredBillLineItemOptions = (
  billLineItemsBy,
  isPsaFPOvertimeBillingEnabled
) =>
  billLineItemsBy?.filter(columnOption => {
    if (columnOption === BILLING_COLUMN_TYPE.EXPENSE_CODE) {
      return true;
    }
    if (
      columnOption === BILLING_COLUMN_TYPE.PAYCODE ||
      columnOption === BILLING_COLUMN_TYPE.ROLE
    ) {
      return isPsaFPOvertimeBillingEnabled;
    }

    return true;
  });
const mapClientBillingSettings = (
  clientDefaultData,
  projectBillSettings,
  isPsaFPOvertimeBillingEnabled,
  baseCurrency
) => {
  const {
    billingSettings: {
      billLineItemsBy: clientsDefaultBillLineItemsBy,
      description: clientsDefaultDescription,
      internalNotes: clientsDefaultInternalNotes,
      showComments: clientsDefaultShowComments
    } = {},
    defaultInvoicePaymentTerm: clientDefaultPaymentTerm,
    invoiceCurrency: clientsDefaultInvoiceCurrency,
    invoiceTemplate: clientsDefaultInvoiceTemplate,
    taxProfile: clientsDefaultTaxProfile
  } = clientDefaultData || {};
  const filteredBillLineItemOptions = getFilteredBillLineItemOptions(
    clientsDefaultBillLineItemsBy,
    isPsaFPOvertimeBillingEnabled
  );

  return {
    defaultInvoicePaymentTerm: clientDefaultPaymentTerm || 30,
    billLineItemsBy: filteredBillLineItemOptions || [
      BILLING_COLUMN_TYPE.PROJECT
    ],
    invoiceCurrency:
      getInvoiceCurrency(clientsDefaultInvoiceCurrency) ||
      getInvoiceCurrency(baseCurrency),
    taxProfile: clientsDefaultTaxProfile,
    invoiceTemplate: clientsDefaultInvoiceTemplate || null,
    description: clientsDefaultDescription || '',
    internalNotes: clientsDefaultInternalNotes || '',
    showComments: clientsDefaultShowComments || false,
    poNumber: projectBillSettings?.poNumber || ''
  };
};

const mapProjectBillingSettings = (
  projectBillSettings,
  isPsaFPOvertimeBillingEnabled,
  baseCurrency
) => {
  const {
    billLineItemsBy,
    description,
    internalNotes,
    showComments,
    poNumber,
    defaultInvoicePaymentTerm,
    invoiceCurrency,
    invoiceTemplate,
    taxProfile
  } = projectBillSettings || {};
  const filteredBillLineItemOptions = getFilteredBillLineItemOptions(
    billLineItemsBy,
    isPsaFPOvertimeBillingEnabled
  );

  return {
    defaultInvoicePaymentTerm: defaultInvoicePaymentTerm || 30,
    billLineItemsBy: filteredBillLineItemOptions || [
      BILLING_COLUMN_TYPE.PROJECT
    ],
    invoiceCurrency:
      getInvoiceCurrency(invoiceCurrency) || getInvoiceCurrency(baseCurrency),
    taxProfile,
    invoiceTemplate: invoiceTemplate || null,
    description: description || '',
    internalNotes: internalNotes || '',
    showComments: showComments || false,
    poNumber: poNumber || ''
  };
};

export const initialState = ({
  id,
  projectBillSettings,
  isPsaFPOvertimeBillingEnabled,
  clientDefaultData,
  showConfirmationDialogBeforeSave,
  baseCurrency
}) => {
  const effectiveSettings = projectBillSettings?.billLineItemsBy
    ? mapProjectBillingSettings(
        projectBillSettings,
        isPsaFPOvertimeBillingEnabled,
        baseCurrency
      )
    : mapClientBillingSettings(
        clientDefaultData,
        projectBillSettings,
        isPsaFPOvertimeBillingEnabled,
        baseCurrency
      );

  return {
    key: v4(),
    id,
    ...effectiveSettings,
    context: 'project',
    showConfirmationDialogBeforeSave
  };
};

export const buildValidationSchema = ({ intl }) =>
  object().shape({
    id: string(),
    invoiceCurrency: string().nullable(),
    invoiceTemplate: string().nullable(),
    defaultInvoicePaymentTerm: number()
      .min(0)
      .max(25000)
      .nullable(),
    taxProfile: string().nullable(),
    billLineItemsBy: array().of(
      mixed().oneOf(Object.keys(BILLING_COLUMN_TYPE))
    ),
    description: string()
      .max(
        255,
        intl.formatMessage({
          id: 'billDetails.validations.descriptionExceedsMaxLength'
        })
      )
      .nullable(),
    internalNotes: string().nullable(),
    showComments: bool()
  });

const useFormState = ({
  id,
  projectBillSettings,
  clientDefaultData,
  showConfirmationDialogBeforeSave
}) => {
  const intl = useIntl();
  const { baseCurrency } = useMeContext();
  const isPsaFPOvertimeBillingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFPOvertimeBillingEnabled'
  });
  const initialValues = initialState({
    id,
    projectBillSettings,
    isPsaFPOvertimeBillingEnabled,
    clientDefaultData,
    showConfirmationDialogBeforeSave,
    baseCurrency
  });

  const validationSchema = useMemo(() => buildValidationSchema({ intl }), [
    intl
  ]);

  const { onSubmit } = useUpdateProjectBillingSetting({
    initialValues,
    projectBillSettings
  });

  return {
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true
  };
};

export default useFormState;
