import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  groupContainer: {
    marginBottom: theme.spacing(1),
    marginRight: 0
  },
  resourceAllocationContainer: {
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
    marginRight: 0,
    marginBottom: theme.spacing(0.5)
  },
  resourceAllocationItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[900]
  },
  noResourceAllocationItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[400],
    fontStyle: 'italic'
  },
  spacing: {
    position: 'absolute',
    left: theme.spacing(18)
  },
  groupItem: {
    display: 'flex'
  },
  resourceAvatar: {
    flexGrow: 0,
    flexShrink: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.typography.caption.fontSize,
    border: `2px dashed ${theme.palette.grey[400]}`,
    borderColor: theme.palette.grey[400],
    background: theme.palette.background.default,
    margin: theme.spacing(1, 0.5)
  },
  resquestAvatar: {
    flexGrow: 0,
    flexShrink: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.typography.caption.fontSize,
    border: `2px dashed ${theme.palette.grey[400]}`,
    borderColor: theme.palette.grey[400],
    background: theme.palette.background.default,
    color: theme.palette.grey[400],
    margin: theme.spacing(1, 0.5)
  }
}));

export const topContainerStyles = makeStyles(theme => ({
  container: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(-1, -1, 0, -1)
  }
}));
export const bottomContainerStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    margin: theme.spacing(0, -1, -1, -1)
  }
}));
export const spacingItemStyles = makeStyles(theme => ({
  item: {
    position: 'absolute',
    left: theme.spacing(18)
  }
}));
export const roleItemStyles = makeStyles(theme => ({
  item: {
    display: 'inline-flex',
    maxWidth: '75%'
  }
}));
export const statusItemStyles = makeStyles(theme => ({
  item: {
    margin: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1)
    },
    alignItems: 'center'
  }
}));

export default useStyles;
