import { useEffect } from 'react';

import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import { ProjectManagementType } from '~/types';

import { useProjectsFilteredByResourceRequestStatuQuery } from './useProjectsFilteredByResourceRequestStatuQuery';
import { useLoadMoreProjectsFilteredByResourceRequestStatus } from './useLoadMoreProjectsFilteredByResourceRequestStatus';

export const DEFAULT_PAGE_SIZE = 10;

export const usePaginatedProjectsFilteredByResourceRequestStatusQuery = ({
  hasMore,
  setHasMore,
  resourceRequestStatus
}) => {
  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();

  const isManaged = projectType === ProjectManagementType.Managed;

  useEffect(() => {
    setHasMore(true);
  }, [isManaged, searchCriteria, setHasMore]);

  const {
    loading,
    resourceRequestsProjects,
    paginationToken,
    fetchMore
  } = useProjectsFilteredByResourceRequestStatuQuery({
    pageSize: DEFAULT_PAGE_SIZE,
    isManaged,
    searchCriteria,
    paginationToken: null,
    resourceRequestStatus,
    quickFilter: summaryFilterState.quickFilter
  });

  useEffect(() => {
    setHasMore(Boolean(paginationToken));
  }, [paginationToken, setHasMore]);

  const { loadMore } = useLoadMoreProjectsFilteredByResourceRequestStatus({
    hasMore,
    pageSize: DEFAULT_PAGE_SIZE,
    isManaged,
    searchCriteria,
    fetchMore,
    setHasMore,
    paginationToken,
    resourceRequestStatus,
    quickFilter: summaryFilterState.quickFilter
  });

  return {
    loading,
    resourceRequestsProjects,
    paginationToken,
    loadMore
  };
};

export default usePaginatedProjectsFilteredByResourceRequestStatusQuery;
