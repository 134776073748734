import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECTS_QUERY = gql`
  query GetOverEstimatedTasksProjects(
    $page: Int!
    $pageSize: Int!
    $projectFilter: ProjectFilter2
    $overEstimatedTaskType: OverEstimatedTaskType
  ) {
    overEstimatedTasksProjects(
      page: $page
      pageSize: $pageSize
      projectFilter: $projectFilter
      overEstimatedTaskType: $overEstimatedTaskType
    ) {
      overEstimatedProjects {
        id
        project {
          id
          displayText
          startDate: startDate2
          endDate: endDate2
          slug
          projectManagementType
        }
        taskCounts
      }
      totalProjects
    }
  }
`;

export const useGetOverEstimatedTasksProjectsQuery = options =>
  useQuery(GET_PROJECTS_QUERY, options);
