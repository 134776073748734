import React from 'react';
import classNames from 'classnames';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles(() => ({
  avatar: {},
  name: {},
  label: {
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const LabelledIcon = ({
  classes: classesOverride,
  icon,
  label,
  className,
  disablePadding,
  dataQeId,
  showTooltip,
  tabIndex
}) => {
  const classes = useStyles({ classes: classesOverride });
  const labelText = (
    <Typography
      data-qe-id={dataQeId}
      variant="caption"
      color="inherit"
      noWrap
      className={classNames(classes.label, className)}
      tabIndex={tabIndex}
    >
      {label}
    </Typography>
  );
  const text = showTooltip ? (
    <Tooltip title={label}>{labelText}</Tooltip>
  ) : (
    labelText
  );

  return (
    <div
      className={classNames(
        classes.container,
        className,
        disablePadding && classes.noPadding
      )}
    >
      {icon}
      {text}
    </div>
  );
};

LabelledIcon.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  dataQeId: PropTypes.string,
  disablePadding: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tabIndex: PropTypes.number
};

export default LabelledIcon;
