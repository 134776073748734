import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import useProjectDropdownOptions from './useProjectDropdownOptions';

const resourceKey = {
  project: <FormattedMessage id="customLineItem.project" />
};

const getOptionLabel = option => option.displayText;

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const SearchableProjectDropdown = ({ value, onChange, clientId }) => {
  const intl = useIntl();
  const noneLabel = intl.formatMessage({ id: 'simpleDropdown.none' });
  const { options, loading } = useProjectDropdownOptions(clientId, noneLabel);
  const renderInput = useCallback(
    params => <TextField {...params} label={resourceKey.project} />,
    []
  );

  return (
    <Autocomplete
      loading={loading}
      options={options}
      fullWidth
      forcePopupIcon
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      getOptionSelected={getOptionSelected}
    />
  );
};

SearchableProjectDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  clientId: PropTypes.string
};

export default SearchableProjectDropdown;
