import { useMeContext } from '~/modules/me';
import columnGroups, {
  defaultColumns,
  defaultColumnNames
} from './projectColumns';

export const useEnabledProjectColumns = ({
  hideProgram = false,
  hideClient = false,
  tagFieldData = [],
  includeGroupsInDefaultColumnNames = true,
  projectListPageType
} = {}) => {
  const me = useMeContext();

  const options = {
    hideProgram,
    hideClient,
    includeGroupsInDefaultColumnNames,
    projectListPageType
  };

  const groups = columnGroups(me, tagFieldData, {
    projectListPageType,
    hideProgram,
    hideClient
  });

  return {
    enabledProjectColumns: defaultColumns(me, options),
    enabledProjectColumnGroups: groups,
    enabledProjectColumnNames: defaultColumnNames(me, options),
    enabledProjectColumnOrder: groups
      .map(({ columns }) => columns.map(({ field }) => field))
      .flat()
  };
};

export default useEnabledProjectColumns;
