import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import {
  buildUpdatedScheduleRules,
  getTotalHours
} from '~/modules/resourcing/common/util';

import { invokeUpdateResourceRequest } from '../hooks/useUpdateResourceRequestHandlers';

export const patchResourceRequest = ({
  updateResourceRequest,
  request,
  openRemoveRequestDialog,
  project: { defaultScheduleRule },
  apolloClient,
  filter,
  isResourceActualModeEnabled
}) => {
  const overrideSchedule = {
    ...defaultScheduleRule,
    do: {
      ...defaultScheduleRule.do,
      load: request.load || 100
    }
  };

  if (request.scheduleRules.length === 0) {
    openRemoveRequestDialog();

    return;
  }
  const scheduleRules = buildUpdatedScheduleRules({
    start: mapIsoStringtoUtcObject(request.startDate),
    end: mapIsoStringtoUtcObject(request.endDate),
    scheduleRules: request.scheduleRules,
    defaultScheduleRule: overrideSchedule
  });

  const [
    {
      dateRange: { startDate: scheduleStartDate }
    }
  ] = scheduleRules;

  const totalHours = getTotalHours({
    scheduleRules,
    quantity: request.quantity || 1,
    defaultScheduleRule: overrideSchedule
  });

  const actualScheduleTotalHours = getTotalHours({
    scheduleRules: [
      {
        dateRange: {
          startDate: scheduleStartDate,
          endDate: scheduleRules[scheduleRules.length - 1].dateRange.endDate
        },
        do: defaultScheduleRule.do
      }
    ],
    quantity: request.quantity || 1,
    defaultScheduleRule
  });

  invokeUpdateResourceRequest({
    updateResourceRequest,
    apolloClient,
    filter,
    isResourceActualModeEnabled
  })({
    ...request,
    scheduleRules,
    totalHours,
    load: (totalHours * 100) / actualScheduleTotalHours || 100
  });
};
