export const AssignmentDialogColumns = {
  DisplayText: 'displayText',
  Availability: 'availability',
  SmartMatchIncludingCost: 'smartMatchIncludingCost',
  SmartMatchExcludingCost: 'smartMatchExcludingCost',
  CostRate: 'costRate',
  CurrentCostCenter: 'currentCostCenter',
  CurrentServiceCenter: 'currentServiceCenter',
  CurrentDepartment: 'currentDepartment',
  CurrentEmployeeType: 'currentEmployeeType',
  CurrentDivision: 'currentDivision',
  CurrentLocation: 'currentLocation',
  ResourcePools: 'resourcePools',
  SupervisorAssignment: 'supervisorAssignment',
  StartDate: 'startDate',
  EndDate: 'endDate',
  Roles: 'roles',
  EmployeeId: 'employeeId',
  Tags: 'tags',
  CustomFields: 'customFields'
};

export const TABLE_KEY = 'psa-resource-assignment-dialog';

export const COLUMN_KEY = 'column';
