import { makeStyles, Grid, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { AddCircleSharp, RemoveCircleSharp } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash.get';
import { toRepliconDate } from '~/modules/common/dates/convert';
import {
  DateField,
  ScreenReadableIconWrapper,
  Money2
} from '~/modules/common/components';
import { useScheduleEntryChangeHandler } from '../hooks';

export const useStyles = makeStyles(theme => ({
  btn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  container: {
    marginBottom: theme.spacing(1)
  },
  item: {
    padding: ({ isMobile }) =>
      isMobile ? theme.spacing(0, 0.5) : theme.spacing(0, 1)
  },
  dateItem: {
    padding: ({ isMobile }) =>
      isMobile ? theme.spacing(0, 0.5) : theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center'
  }
}));

export const ScheduleEntryRow = ({
  scheduleEntry,
  onRemoveEntryRow,
  onAddEntryRow,
  setFieldValue,
  billingRateIndex,
  scheduleEntryIndex,
  errors,
  isMobile
}) => {
  const intl = useIntl();
  const classes = useStyles({ isMobile });
  const {
    effectiveDate,
    rate: { amount, currency }
  } = scheduleEntry;
  const onRemoveEntryClick = useCallback(
    () => onRemoveEntryRow(scheduleEntry.id),
    [onRemoveEntryRow, scheduleEntry.id]
  );
  const onAddEntryClick = useCallback(() => onAddEntryRow(), [onAddEntryRow]);
  const {
    onEffectiveDateChange,
    onAmountChange,
    onCurrencyChange
  } = useScheduleEntryChangeHandler({
    setFieldValue,
    billingRateIndex,
    scheduleEntryIndex
  });

  const { error, helperText } = useMemo(() => {
    const requiredError = get(
      errors,
      `billingRates.billingScheduleEntryEffectiveDate.required[${scheduleEntry.id}]`,
      null
    );
    const duplicateError = get(
      errors,
      `billingRates.billingScheduleEntryEffectiveDate.duplicate[${scheduleEntry.id}]`,
      null
    );

    return {
      error: Boolean(requiredError || duplicateError),
      helperText: requiredError || duplicateError
    };
  }, [errors, scheduleEntry.id]);

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={5} className={classes.dateItem}>
        {effectiveDate ? (
          <DateField
            editable
            clearable={false}
            label={intl.formatMessage({
              id: 'billingRateCard.columns.effectiveDate'
            })}
            value={toRepliconDate(effectiveDate)}
            onChange={onEffectiveDateChange}
            error={error}
            helperText={helperText}
            ariaLabel={intl.formatMessage({
              id: 'billingRateCard.columns.effectiveDate'
            })}
          />
        ) : (
          <Typography variant="body2" className={classes.initialRate}>
            <FormattedMessage id="billingRateCard.table.initialRate" />
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <Money2
          amount={amount}
          label={intl.formatMessage({
            id: 'billingRateCard.columns.rate'
          })}
          currency={currency}
          onAmountChange={onAmountChange}
          onCurrencyChange={onCurrencyChange}
          ariaLabel={intl.formatMessage({
            id: 'billingRateCard.columns.rate'
          })}
        />
      </Grid>
      <Grid item xs={1}>
        {effectiveDate ? (
          <ScreenReadableIconWrapper
            role="img"
            iconAriaLabel="billingRateCard.removeRateButton"
            buttonAriaLabel="billingRateCard.removeRateIcon"
            onClick={onRemoveEntryClick}
          >
            <RemoveCircleSharp />
          </ScreenReadableIconWrapper>
        ) : (
          <ScreenReadableIconWrapper
            role="img"
            iconAriaLabel="billingRateCard.addRateButton"
            buttonAriaLabel="billingRateCard.addRateIcon"
            onClick={onAddEntryClick}
            color="primary"
          >
            <AddCircleSharp />
          </ScreenReadableIconWrapper>
        )}
      </Grid>
    </Grid>
  );
};

ScheduleEntryRow.propTypes = {
  scheduleEntry: PropTypes.object,
  onRemoveEntryRow: PropTypes.func,
  onAddEntryRow: PropTypes.func,
  setFieldValue: PropTypes.func,
  billingRateIndex: PropTypes.number,
  scheduleEntryIndex: PropTypes.number,
  errors: PropTypes.object,
  isMobile: PropTypes.bool
};

export default ScheduleEntryRow;
