import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  rescheduleButton: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(-0.3125),
    textTransform: 'none'
  },
  rescheduleButtonOveride: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(-0.3125),
    textTransform: 'none',
    paddingLeft: theme.spacing(1.2)
  }
}));

const RescheduleButton = ({
  onClick,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const buttonClasses = classNames({
    [classes.rescheduleButtonOveride]: isRolledUpTaskEstimateCalculationMethodEnabled,
    [classes.rescheduleButton]: !isRolledUpTaskEstimateCalculationMethodEnabled
  });

  return (
    <Button color="primary" onClick={onClick} className={buttonClasses}>
      {intl.formatMessage({ id: 'taskDrawer.rescheduleButton' })}
    </Button>
  );
};

RescheduleButton.propTypes = {
  onClick: PropTypes.func,
  isRolledUpTaskEstimateCalculationMethodEnabled: PropTypes.bool
};

export default RescheduleButton;
