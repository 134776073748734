import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Decimal, NoValue } from '~/modules/common/components';
import { RequestAllocationTotals } from '~/modules/projects/resourcing-plan/components';
import useMeContext from '~/modules/me/useMeContext';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import useStyles, { requestAllocationTotalsStyles } from './useStyles';

export const ResourceRequestCardTotal = ({
  label,
  resourceRequestTotal,
  resourceAllocatedTotal,
  showResourceRequestedTotals = true,
  showAllocationTotals,
  resourceAllocatedPrefix,
  resourceRequestPrefix,
  deltaTotal
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const numberSettings = getReactNumberFormatFromMe(me);
  const cssClasses = requestAllocationTotalsStyles();

  return (
    <div className={classes.root}>
      <RequestAllocationTotals
        classes={cssClasses}
        label={label}
        showAllocationTotals={showAllocationTotals}
        resourceAllocatedTotal={resourceAllocatedTotal}
        resourceAllocatedPrefix={resourceAllocatedPrefix}
        deltaTotal={deltaTotal}
        deltaPrefix={resourceRequestPrefix}
        fixedDecimalScale={false}
        precision={numberSettings.decimalScale || 2}
        showDiff
      />
      {!showAllocationTotals && showResourceRequestedTotals && (
        <Decimal
          value={resourceRequestTotal}
          prefix={resourceRequestPrefix && `${resourceRequestPrefix} `}
          className={classnames({
            [classes.primaryValue]: !showAllocationTotals,
            [classes.secondaryValue]: showAllocationTotals
          })}
          fixedDecimalScale={false}
          precision={numberSettings.decimalScale || 2}
        />
      )}
      {!showAllocationTotals && !showResourceRequestedTotals && (
        <NoValue
          className={classnames({
            [classes.primaryValue]: !showAllocationTotals,
            [classes.secondaryValue]: showAllocationTotals
          })}
        />
      )}
    </div>
  );
};

ResourceRequestCardTotal.propTypes = {
  label: PropTypes.string,
  resourceRequestTotal: PropTypes.number,
  resourceAllocatedTotal: PropTypes.number,
  showResourceRequestedTotals: PropTypes.bool,
  showAllocationTotals: PropTypes.bool,
  resourceRequestPrefix: PropTypes.string,
  resourceAllocatedPrefix: PropTypes.string,
  deltaTotal: PropTypes.number
};

export default ResourceRequestCardTotal;
