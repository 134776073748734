import React, { useState, useMemo } from 'react';
import { scoredSuggestions } from '../../../scoredSuggestions';
import { useSearchableTags } from './useSearchableTags';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const mapOptions = tags => tags;

export const matchOption = tags => async (_, term) => {
  if (!term) {
    return null;
  }

  const searchTerm = term.split('+')[0];
  const values = await tags(cleanSearchTerm(searchTerm));

  const option = values.find(t => t.label === term);

  if (option) {
    return {
      ...option,
      key: option.key || option.displayText || option.name
    };
  }

  return null;
};
const withCriteriaProvider = (tag, context) => BaseComponent => ({
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const { fetchOptions } = useSearchableTags();

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption(fetchOptions),
      tags: fetchOptions,
      selected,
      setSelected,
      suggestions: scoredSuggestions(fetchOptions)
    }),
    [selected, fetchOptions]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
