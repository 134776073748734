import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Grid } from '@material-ui/core';

import Delta from '../Delta';
import ReadOnlyHours from './ReadOnlyHours';

const styles = theme => ({
  hoursWithDelta: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1',
    justifyContent: 'flex-end'
  },
  dateWithDeltaContainer: {
    padding: '0.2rem 0.3rem'
  },
  deltaLabel: {
    fontSize: theme.typography.caption.fontSize
  },
  dataCell: {},
  paddedCell: {},
  strikeThrough: {},
  overriddenCell: {}
});
const useStyles = makeStyles(styles);

export const HoursWithDelta = ({
  delta,
  classes: classesOverride,
  precision,
  estimatedCompletionHours,
  overrideValue,
  showOverride = typeof overrideValue === 'number' &&
    overrideValue !== estimatedCompletionHours,
  dataQeId,
  reverseColors
}) => {
  const classes = useStyles({ classes: classesOverride });
  const deltaStyleOverride = useMemo(
    () => ({
      dateWithDeltaContainer: classes.dateWithDeltaContainer,
      deltaLabel: classes.deltaLabel
    }),
    [classes.dateWithDeltaContainer, classes.deltaLabel]
  );

  return (
    <div className={classes.hoursWithDelta}>
      <div className={classes.item}>
        {Boolean(delta) && (
          <Delta
            classes={deltaStyleOverride}
            delta={delta}
            precision={precision}
            showAbbreviatedValue
            reverseColors={reverseColors}
          />
        )}
      </div>
      <Grid container direction="column">
        <Grid item>
          <ReadOnlyHours
            className={classNames(
              classes.dataCell,
              showOverride && classes.overriddenCell,
              showOverride && classes.strikeThrough,
              classes.paddedCell
            )}
            value={estimatedCompletionHours}
            precision={precision}
            dataQeId={dataQeId}
          />
        </Grid>
        {showOverride && (
          <Grid item>
            <ReadOnlyHours
              className={classNames(
                classes.dataCell,
                classes.overriddenCell,
                classes.paddedCell
              )}
              value={overrideValue}
              precision={precision}
              dataQeId={`${dataQeId}_override`}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

HoursWithDelta.propTypes = {
  delta: PropTypes.number,
  classes: PropTypes.object.isRequired,
  precision: PropTypes.number,
  estimatedCompletionHours: PropTypes.number.isRequired,
  overrideValue: PropTypes.number,
  showOverride: PropTypes.bool,
  dataQeId: PropTypes.string,
  reverseColors: PropTypes.bool
};
export default memo(HoursWithDelta);
