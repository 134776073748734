import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { User, ButtonBaseHover } from '~/modules/common/components';
import { OpenInDrawerIcon } from '~/modules/common/components/Icons';
import ResourceScore from '~/modules/resourcing/common/components/ResourceScore';
import { useResourceRequestUserMatchScore } from '~/modules/resourcing/hooks';
import { isNullOrUndefined } from '~/modules/common/util';
import { PreferredResourceIconTooltip } from '~/modules/resourcing/common/components';

const useStyles = makeStyles(theme => ({
  userButton: {
    link: {
      height: theme.spacing(2.25),
      verticalAlign: 'bottom',
      textAlign: 'left',
      display: 'inline-flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',

      cursor: 'pointer',
      textDecoration: 'none',
      borderRadius: theme.shape.borderRadius,
      '&:hover $drawerIcon': {
        opacity: 1
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '100%'
      }
    }
  },
  label: {
    color: theme.palette.text.secondary
  },
  drawerIcon: {
    margin: theme.spacing(-0.5, 0, -0.5, 1),
    color: theme.palette.text.secondary,
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    [theme.breakpoints.down('xs')]: {
      opacity: 0.3
    }
  },
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  smartMatchScoreIcon: {
    marginTop: 0
  },
  smartMatchScore: {
    marginTop: theme.spacing(0.125)
  }
}));

const useUserStyles = makeStyles(() => ({
  container: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const usePreferredResourceIconStyles = makeStyles(theme => ({
  preferredResourceIcon: {
    margin: theme.spacing(0, 0, 0, 0.5)
  }
}));

const RequestAllocationUserScoreItem = ({
  classes: classesOverrides,
  resourceRequestId,
  user,
  preferredResources,
  isCombinedResourceRequest
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const history = useHistory();
  const {
    resourceRequestUsermatch: resourceScore
  } = useResourceRequestUserMatchScore({
    resourceRequestId,
    userId: user.uri
  });

  const userClasses = useUserStyles();
  const preferredResourceIconClasses = usePreferredResourceIconStyles();

  const onUserClick = useCallback(
    () => history.push(`#/resource/${user.slug}`),
    [history, user.slug]
  );

  const intl = useIntl();
  const hasResourceScore = !isNullOrUndefined(resourceScore?.score);

  const isPreferredResource = Boolean(
    !isCombinedResourceRequest &&
      preferredResources?.find(
        preferredResource => preferredResource.id === user.uri
      )
  );

  return (
    <>
      <ButtonBaseHover onClick={onUserClick} className={classes.userButton}>
        <User user={user} classes={userClasses} />
        {isPreferredResource && (
          <PreferredResourceIconTooltip
            classes={preferredResourceIconClasses}
          />
        )}
        <OpenInDrawerIcon className={classes.drawerIcon} mirror="rtl" />
      </ButtonBaseHover>

      {!isCombinedResourceRequest && resourceScore && (
        <div
          className={classNames(classes.root, {
            [classes.smartMatchScore]: hasResourceScore,
            [classes.smartMatchScoreIcon]: !hasResourceScore
          })}
        >
          <span className={classes.label}>
            {intl.formatMessage({
              id: 'resourceRequestDrawerDetails.smartMatch'
            })}
          </span>
          <ResourceScore
            resourceScore={resourceScore}
            label={intl.formatMessage({
              id: 'resourceRequestDrawerDetails.smartMatch'
            })}
          />
        </div>
      )}
    </>
  );
};

RequestAllocationUserScoreItem.propTypes = {
  classes: PropTypes.object,
  resourceRequestId: PropTypes.string.isRequired,
  user: PropTypes.object,
  preferredResources: PropTypes.array,
  isCombinedResourceRequest: PropTypes.bool
};

export default RequestAllocationUserScoreItem;
