import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import { AsOfCloseDateTitle } from '~/modules/projects/project/RevenueRecognition/common';
import { KeyValuesRow } from './common';

const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(1)
  }
}));

const RevenueKeyValues = ({
  asOfCloseDate,
  forecastedAmount,
  recognizedAmount
}) => {
  const classes = useStyles();
  const { varianceAmount, varianceColor } = useMemo(() => {
    if (!(forecastedAmount && recognizedAmount)) return {};
    const amount = recognizedAmount.amount - forecastedAmount.amount;

    return {
      varianceAmount: {
        amount,
        currency: forecastedAmount.currency
      },
      varianceColor: amount > 0 ? '#26D293' : amount < 0 ? '#D92411' : 'unset'
    };
  }, [forecastedAmount, recognizedAmount]);

  return (
    <>
      <Typography variant="subtitle2">
        <AsOfCloseDateTitle date={asOfCloseDate} />
      </Typography>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} className={classes.item}>
          <KeyValuesRow
            bulletColor="#2FC9CB"
            title="recognizedRevenue"
            amount={recognizedAmount}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <KeyValuesRow
            bulletColor="#C6ECFC"
            title="revenueForecast"
            amount={forecastedAmount}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <KeyValuesRow
            bulletColor=""
            title="revenueVariance"
            amount={varianceAmount}
            amountColor={varianceColor}
          />
        </Grid>
      </Grid>
    </>
  );
};

RevenueKeyValues.propTypes = {
  asOfCloseDate: PropTypes.object.isRequired,
  forecastedAmount: PropTypes.object,
  recognizedAmount: PropTypes.object
};
export default RevenueKeyValues;
