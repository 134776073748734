import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const PROJECT_DETAILS_QUERY = gql`
  query GetProjectDetails($projectId: String!) {
    project(projectId: $projectId) {
      id
      displayText
      startDate: startDate2
      endDate: endDate2
      defaultScheduleRule {
        dateRange {
          startDate
          endDate
        }
        do
      }
    }
  }
`;

export const useProjectDateRangeDetails = ({ projectId, skip }) => {
  const { loading, data } = useQuery(PROJECT_DETAILS_QUERY, {
    variables: {
      projectId
    },
    skip
  });

  return {
    loading,
    project: data?.project || {}
  };
};

export const useProjectDetails = () => {
  const client = useApolloClient();

  const fetchProjectDetails = useCallback(
    projectId =>
      client.query({
        query: PROJECT_DETAILS_QUERY,
        variables: {
          projectId
        },
        fetchPolicy: 'cache-first'
      }),
    [client]
  );

  return {
    fetchProjectDetails
  };
};

export default useProjectDetails;
