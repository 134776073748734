import React from 'react';
import { Chip } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ProjectRequestStatusType, ProjectRequestGateValue } from '~/types';

export const getStatusStyles = (totalGatesCount, checkedGatesCount) => {
  const progressPercentage = checkedGatesCount / totalGatesCount;
  const width = `${progressPercentage * 100}%`;

  return {
    backgroundImage: `linear-gradient(to right, ${
      theme.palette.projectRequestStatus[ProjectRequestStatusType.InReview]
    } ${width},  ${lighten(
      theme.palette.projectRequestStatus[ProjectRequestStatusType.InReview],
      0.2
    )} 0  )`
  };
};

export const ProjectRequestStatusBadgeWithProgress = ({
  projectRequestGates
}) => {
  const intl = useIntl();
  const totalGatesCount = projectRequestGates.length;
  const checkedGatesCount = projectRequestGates.filter(
    gate => gate.value === ProjectRequestGateValue.Met
  ).length;

  return (
    <Chip
      style={getStatusStyles(totalGatesCount, checkedGatesCount)}
      label={intl.formatMessage(
        { id: 'projectRequestStatus.statusLabel.inReviewWithProgress' },
        { totalGatesCount, checkedGatesCount }
      )}
      data-qe-id="ProjectRequestStatus"
    />
  );
};

ProjectRequestStatusBadgeWithProgress.propTypes = {
  projectRequestGates: PropTypes.array
};

export default ProjectRequestStatusBadgeWithProgress;
