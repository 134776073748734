export const TASKBEAT_TABLE_COLUMN_TYPE = {
  TASK_NAME: 'TASK_NAME',
  ENTRY_DATE: 'ENTRY_DATE',
  USER_NAME: 'USER_NAME',
  PREVIOUS_WORK: 'PREVIOUS_WORK',
  HOURS_WORKED: 'HOURS_WORKED',
  WORK_REMAINING: 'WORK_REMAINING',
  ESTIMATED_HOURS_AT_COMPLETION: 'ESTIMATED_HOURS_AT_COMPLETION',
  ESTIMATED_COMPLETION_DATE: 'ESTIMATED_COMPLETION_DATE',
  COMMENT: 'COMMENT'
};
