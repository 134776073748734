import React from 'react';
import { Button, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { PROJECT_STATUS_FILTER } from '../enum';
import useStyles from './useStyles';

const projectFilterSettingsResources = {
  [PROJECT_STATUS_FILTER.ALL_PROJECTS]: 'projectStatusFilterButton.allProjects',
  [PROJECT_STATUS_FILTER.UNARCHIVED_PROJECTS]:
    'projectStatusFilterButton.unarchivedProjects'
};

export const getProjectStatusText = ({
  projectStatusFilterOption: { option, statuses },
  formatMessage,
  customStatusMapping,
  isCustomStatusNameEnabled
}) => {
  if (option === PROJECT_STATUS_FILTER.CUSTOM_STATUS) {
    return statuses
      .map(s =>
        isCustomStatusNameEnabled && customStatusMapping[s]
          ? customStatusMapping[s]
          : formatMessage({ id: `projectStatus.${s.toLowerCase()}` })
      )
      .join(', ');
  }

  return formatMessage({
    id: projectFilterSettingsResources[option]
  });
};

export const ProjectStatusFilterButton = ({
  projectStatusFilterOption,
  onClick,
  customStatusMapping,
  isProjectCustomStatusNamesLoading
}) => {
  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  return (
    <Button
      className={classes.root}
      variant="outlined"
      onClick={onClick}
      data-qe-id="ProjectStatusFilterButton"
    >
      <div className={classes.button}>
        <div className={classes.label}>
          <div className={classes.showProjects}>
            <FormattedMessage id="projectStatusFilterButton.showProjects" />
          </div>
          <Typography variant="body2" color="textPrimary">
            {getProjectStatusText({
              projectStatusFilterOption,
              formatMessage,
              customStatusMapping,
              isCustomStatusNameEnabled
            })}
          </Typography>
        </div>
        <ArrowDropDownIcon />
      </div>
    </Button>
  );
};

ProjectStatusFilterButton.propTypes = {
  projectStatusFilterOption: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  customStatusMapping: PropTypes.object,
  isProjectCustomStatusNamesLoading: PropTypes.bool
};

export default ProjectStatusFilterButton;
