import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { EXPENSE_CARD_SETTINGS } from './useExpenseCardSettings';

export const PUT_EXPENSE_CARD_SETTINGS = gql`
  mutation putExpenseCardSettings(
    $allowBillable: Boolean!
    $showActuals: Boolean!
    $projectId: String!
  ) {
    putExpenseCardSettings(
      allowBillable: $allowBillable
      showActuals: $showActuals
      projectId: $projectId
    ) {
      allowBillable
      showActuals
    }
  }
`;

export const updateCache = projectId => (proxy, { data }) => {
  if (data.putExpenseCardSettings) {
    proxy.writeQuery({
      query: EXPENSE_CARD_SETTINGS,
      variables: { projectId },
      data: {
        expenseCardSettings: {
          __typeName: 'ExpenseCardSettings',
          allowBillable: data.putExpenseCardSettings.allowBillable,
          showActuals: data.putExpenseCardSettings.showActuals
        }
      }
    });
  }
};

export const usePutExpenseCardSettings = ({ projectId }) => {
  const [putExpenseCardSettings] = useMutation(PUT_EXPENSE_CARD_SETTINGS);

  return {
    putExpenseCardSettings: async ({ allowBillable, showActuals }) => {
      await putExpenseCardSettings({
        variables: {
          projectId,
          allowBillable: Boolean(allowBillable),
          showActuals: Boolean(showActuals)
        },
        update: updateCache(projectId)
      });
    }
  };
};
export default usePutExpenseCardSettings;
