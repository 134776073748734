import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { CLIENT_ID_QUERY_BY_SLUG } from '~/modules/clients/client/graphql';

export const ADD_CLIENT_MUTATION = gql`
  mutation AddClient($client: AddClientInput!) {
    addClient2(client: $client) {
      id
      slug
      name
      isActive
      displayText
      clientManager {
        id
        displayText
      }
    }
  }
`;

export const updateCache = (proxy, { data }) => {
  if (data) {
    const { addClient2 } = data;

    proxy.writeQuery({
      query: CLIENT_ID_QUERY_BY_SLUG,
      variables: { slug: addClient2.slug },
      data: {
        client: {
          ...addClient2
        }
      }
    });
  }
};

export const useAddClientMutation = () =>
  useMutation(ADD_CLIENT_MUTATION, {
    update: updateCache,
    refetchQueries: ['clients'],
    awaitRefetchQueries: true
  });
