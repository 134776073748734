import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me/useMeContext';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PAGE_SIZE } from '~/modules/common/const';

export const GET_CLIENT_QUERY = gql`
  query clients($page: Int!, $pageSize: Int!, $clientFilter: ClientFilter2) {
    clients(page: $page, pageSize: $pageSize, clientFilter: $clientFilter) {
      totalItems
      items {
        id
        displayText
        isActive
      }
    }
  }
`;

export const GET_PROJECT_CLIENTS_QUERY = gql`
  query getPageOfClientsAvailableForFilteringProjects(
    $pageSize: Int!
    $clientFilter: ClientFilter
  ) {
    pageOfClientsAvailableForFilteringProjects(
      page: 1
      pageSize: $pageSize
      clientFilter: $clientFilter
    ) {
      id
      displayText
    }
  }
`;

const mappedClients = clients =>
  (clients || []).map(client => ({
    ...client,
    value: client.id,
    label: client.displayText,
    key: client.displayText
  }));

const getFilter = searchTerm =>
  searchTerm.length > 0 ? { text: searchTerm } : null;

export const useSearchableClients = ({
  searchTerm,
  includeNoneOption = false,
  noneOptionText = ''
}) => {
  const { permissionsMap } = useMeContext();

  const canViewClient = Boolean(
    permissionsMap['urn:replicon:client-action:view-client']
  );

  const query = !canViewClient ? GET_PROJECT_CLIENTS_QUERY : GET_CLIENT_QUERY;

  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pageSize: PAGE_SIZE + 1,
      clientFilter: { isActive: true, ...getFilter(searchTerm) }
    }
  });
  const _clients =
    (!canViewClient
      ? data?.pageOfClientsAvailableForFilteringProjects
      : data?.clients?.items) || [];

  const prependNoneOption = includeNoneOption && _clients.length;

  const clients = !loading
    ? prependNoneOption
      ? prependNoneItem(noneOptionText, mappedClients(_clients))
      : mappedClients(_clients)
    : [];

  return {
    data: clients.slice(0, PAGE_SIZE),
    loading,
    hasMore: _clients.length > PAGE_SIZE
  };
};

export const searchClients = ({
  apolloClient
}) => setHasMore => async searchTerm => {
  const result = await apolloClient.query({
    query: GET_CLIENT_QUERY,
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
      clientFilter: { isActive: true, ...getFilter(searchTerm) }
    }
  });

  const clients = mappedClients(result?.data?.clients?.items);

  setHasMore(result?.data?.clients?.totalItems > clients.length);

  return clients;
};

const useSearchableClientsWithHasMore = () => {
  const apolloClient = useApolloClient();

  return {
    searchClients: searchClients({
      apolloClient
    })
  };
};

export default useSearchableClientsWithHasMore;
