import { hasPermission as hasPermissionProvider } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me';
import { PROJECT_PERMISSION, PROJECT_DAL } from '~/modules/common/enums';

export const usePortfolioProjectsPermissions = ({
  portfolioManager,
  ancestors
} = {}) => {
  const { id, permissionsMap } = useMeContext();

  const hasPermission = hasPermissionProvider(permissionsMap);

  const isManager =
    portfolioManager?.id === id ||
    (ancestors || []).some(x => x.portfolioManager?.id === id);

  return {
    canViewPortfolioProjects:
      (isManager &&
        hasPermission({
          actionUri: PROJECT_PERMISSION.VIEW_PROJECT,
          dataAccessLevelUri: PROJECT_DAL.PORTFOLIO_MANAGER
        })) ||
      hasPermission({
        actionUri: PROJECT_PERMISSION.VIEW_PROJECT,
        dataAccessLevelUri: PROJECT_DAL.ALL
      }),
    canEditPortfolioProjects:
      (isManager &&
        hasPermission({
          actionUri: PROJECT_PERMISSION.EDIT_PROJECT,
          dataAccessLevelUri: PROJECT_DAL.PORTFOLIO_MANAGER
        })) ||
      hasPermission({
        actionUri: PROJECT_PERMISSION.EDIT_PROJECT,
        dataAccessLevelUri: PROJECT_DAL.ALL
      })
  };
};
