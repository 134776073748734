import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const REMOVE_TASK_RESOURCE_ALLOCATIONS = gql`
  mutation removeTaskResourceAllocations(
    $input: RemoveTaskResourceAllocationsInput
  ) {
    removeTaskResourceAllocations(input: $input)
  }
`;

export const useRemoveTaskResourceAllocations = taskId => {
  const [removeTaskResourceAllocations] = useMutation(
    REMOVE_TASK_RESOURCE_ALLOCATIONS
  );

  return useCallback(
    async ({ resourceAllocations, refetchQueries }) => {
      await removeTaskResourceAllocations({
        variables: {
          input: {
            taskId,
            resourceAllocations
          }
        },
        refetchQueries,
        awaitRefetchQueries: true
      });
    },
    [taskId, removeTaskResourceAllocations]
  );
};

export default useRemoveTaskResourceAllocations;
