import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftSharp';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  previousButton: {
    margin: theme.spacing(-1, 0, -1, -1.5),
    paddingRight: `${theme.spacing(0.25)}px`,
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  nextButton: {
    margin: theme.spacing(-1, 0.5, -1, 0),
    paddingLeft: `${theme.spacing(0.25)}px`,
    borderTopLeftRadius: '25%',
    borderBottomLeftRadius: '25%',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}));

export const ChevronDateButtons = ({
  onPreviousClick,
  onNextClick,
  disableLeftButton = false,
  disableRightButton = false,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const intl = useIntl();

  return (
    <>
      <IconButton
        data-qe-id="PreviousPeriodArrow"
        className={classes.previousButton}
        aria-label={intl.formatMessage({ id: 'dateButton.previous' })}
        onClick={onPreviousClick}
        disabled={disableLeftButton}
      >
        <ChevronLeftIcon mirror="rtl" />
      </IconButton>
      <IconButton
        data-qe-id="NextPeriodArrow"
        className={classes.nextButton}
        aria-label={intl.formatMessage({ id: 'dateButton.next' })}
        onClick={onNextClick}
        disabled={disableRightButton}
      >
        <ChevronRightIcon mirror="rtl" />
      </IconButton>
    </>
  );
};

ChevronDateButtons.propTypes = {
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  disableLeftButton: PropTypes.bool,
  disableRightButton: PropTypes.bool,
  classes: PropTypes.object
};

export default ChevronDateButtons;
