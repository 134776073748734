import { useCallback } from 'react';

export const useShowExpandedTasks = ({ expandedTasks }) => {
  return useCallback(
    gantt => {
      gantt.eachTask(task => {
        if (!expandedTasks.tasks.includes(task.id)) {
          // eslint-disable-next-line no-param-reassign
          task.$open = false;
        }
      });
    },
    [expandedTasks]
  );
};

export default useShowExpandedTasks;
