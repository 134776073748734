import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const AVAILABLETOBILL_QUERY = gql`
  query getAvailableToBillTotals($filter: AvailableToBillFilter2) {
    getAvailableToBillTotals: getAvailableToBillTotals2(filter: $filter) {
      amount {
        amount
        currency {
          symbol
        }
      }
      amountInBaseCurrency {
        amount
        currency {
          symbol
        }
      }
    }
  }
`;

export const useAvailableToBillTotals = ({
  projectUri,
  clientUri,
  programUri
} = {}) => {
  const filter = {};

  if (projectUri) filter.projectUris = [projectUri];
  if (programUri) filter.programUris = [programUri];
  if (!programUri && !projectUri && clientUri) filter.clientUris = [clientUri];
  const { refetch: refetchAvailableToBillTotals } = useQuery(
    AVAILABLETOBILL_QUERY,
    {
      variables: {
        filter
      },
      fetchPolicy: 'network-only'
    }
  );

  return {
    refetchAvailableToBillTotals
  };
};

export default useAvailableToBillTotals;
