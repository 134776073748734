import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { OpenInDrawerIcon } from '~/modules/common/components/Icons';
import TaskTitle from './TaskTitle';

const useStyles = makeStyles(theme => ({
  drawerIcon: {
    color: theme.palette.text.secondary
  },
  link: {
    position: 'relative',
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    overflow: 'auto',
    '& > svg': {
      visibility: 'hidden'
    },
    '&:hover > svg': {
      visibility: 'visible'
    }
  },
  titleContainer: { flexGrow: 1, overflow: 'hidden' },
  title: {}
}));

export const TaskLink = ({
  classes: classesOverride,
  task,
  showNonLinkMode = false,
  disableIcon,
  className
}) => {
  const { search } = useLocation();
  const classes = useStyles({ classes: classesOverride });

  return showNonLinkMode ? (
    <div data-qe-id={task.name} className={classes.titleContainer}>
      <TaskTitle task={task} className={classes.title} />
    </div>
  ) : (
    <Link
      data-qe-id={task.name}
      to={`${search}#/task/${task.id}`}
      className={classNames(classes.link, className)}
    >
      <div className={classes.titleContainer}>
        <TaskTitle task={task} className={classes.title} />
      </div>
      {!disableIcon && <OpenInDrawerIcon />}
    </Link>
  );
};

TaskLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.array.isRequired
  }).isRequired,
  showNonLinkMode: PropTypes.bool,
  disableIcon: PropTypes.bool
};

export default TaskLink;
