import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4, 0, 3),
    borderBottom: `1px solid ${theme.palette.table.border}`
  }
}));

export const MobileListItemRowLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Skeleton width="90%" height={20} />
      <Skeleton width="70%" height={20} />
    </div>
  );
};

export default MobileListItemRowLoading;
