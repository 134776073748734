import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  EmailSharp as EmailIcon,
  CallSharp as PhoneIcon
} from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { CustomChip } from '~/modules/projects/project/common/components/ContactInfo';
import { CONTACT_TYPE } from '~/modules/common/enums';
import {
  ContactIcon,
  FaxIcon,
  WebsiteIcon
} from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(0.5)
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'hidden'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    verticalAlign: 'middle',
    '& svg': {
      fontSize: theme.typography.h6.fontSize
    }
  },
  link: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const icons = {
  name: <ContactIcon width={18} />,
  email: <EmailIcon />,
  phone: <PhoneIcon />,
  fax: <FaxIcon />,
  website: <WebsiteIcon />
};

export const ContactLine = ({ icon, label, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftSide}>
        <div className={classes.nameContainer}>
          <span className={classes.icon}>{icon}</span>
          <Typography variant="body2" noWrap className={classes.name}>
            {children}
          </Typography>
        </div>
      </div>
      {label && <CustomChip label={label} />}
    </div>
  );
};

ContactLine.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

const Contact = ({ item }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { name, email, faxNumber, phoneNumber, website, contactType } = item;

  return (
    <div className={classes.container}>
      {name && (
        <ContactLine
          label={
            contactType !== CONTACT_TYPE.OTHERS &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE[contactType]}`
            })
          }
          icon={icons.name}
        >
          {name}
        </ContactLine>
      )}
      {email && (
        <ContactLine
          label={
            !name &&
            contactType !== CONTACT_TYPE.OTHERS &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE[contactType]}`
            })
          }
          icon={icons.email}
        >
          <span className={classes.link}>
            <Link href={`mailto:${email}`} target="_blank">
              {email}
            </Link>
          </span>
        </ContactLine>
      )}
      {phoneNumber && (
        <ContactLine
          label={
            !name &&
            !email &&
            contactType !== CONTACT_TYPE.OTHERS &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE[contactType]}`
            })
          }
          icon={icons.phone}
        >
          {phoneNumber}
        </ContactLine>
      )}
      {faxNumber && (
        <ContactLine
          label={
            !name &&
            !email &&
            !phoneNumber &&
            contactType !== CONTACT_TYPE.OTHERS &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE[contactType]}`
            })
          }
          icon={icons.fax}
        >
          {faxNumber}
        </ContactLine>
      )}
      {website && (
        <ContactLine
          label={
            !name &&
            !email &&
            !phoneNumber &&
            !faxNumber &&
            contactType !== CONTACT_TYPE.OTHERS &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE[contactType]}`
            })
          }
          icon={icons.website}
        >
          <span className={classes.link}>
            <Link
              href={`${
                website && website.startsWith('http') ? '' : '//'
              }${website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {website}
            </Link>
          </span>
        </ContactLine>
      )}
    </div>
  );
};

Contact.propTypes = {
  item: PropTypes.object
};

export default Contact;
