import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { convertDurationToHours } from '~/modules/common/util';
import { TimelineBlock } from '~/modules/resourcing/common/chart/components';
import { useQuickAllocationContext } from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import {
  getRoundedHours,
  getDayViewRoundedPercentage
} from '~/modules/resourcing/common/util/scheduleUtil';
import ResourceUserPeriodSummaryTooltip from '~/modules/resourcing/common/components/ResourceUserPeriodSummaryTooltip/ResourceUserPeriodSummaryTooltip';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import useStyles from './useStyles';

const QuickAllocationUserTimeoffSummaryBlock = ({
  startDate,
  endDate,
  holidayDuration,
  holidays,
  timeOffDuration,
  timeOffs,
  scheduledDuration,
  showActuals
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { chartStartDate, scale } = useQuickAllocationContext();

  const handleTooltipClose = useCallback(() => setOpen(false), [setOpen]);
  const handleTooltipClick = useCallback(() => setOpen(!open), [open, setOpen]);

  const scheduledHours = convertDurationToHours(scheduledDuration);
  const totalTimeOffHours =
    convertDurationToHours(holidayDuration) +
    convertDurationToHours(timeOffDuration);

  const totalHours = getRoundedHours({
    hours: totalTimeOffHours,
    scale
  });

  const loading = (totalTimeOffHours * 100) / scheduledHours;
  const percentage = getDayViewRoundedPercentage({
    percentage: loading,
    scale
  });

  const dynamicStyle = useMemo(() => {
    const { dynamicPosition } = getDynamicPositionAttributes({
      chartStartDate,
      start: startDate,
      end: endDate,
      scale,
      isPsaRmpUserSchedulePerfFixEnabled: true
    });

    return {
      left: dynamicPosition.left,
      width: dynamicPosition.width,
      borderRight: loading > 0 ? `1px solid ${theme.palette.grey[500]}` : `none`
    };
  }, [chartStartDate, startDate, endDate, scale, loading]);

  return (
    <>
      {totalHours > 0 && (
        <ResourceUserPeriodSummaryTooltip
          startDate={startDate}
          endDate={endDate}
          scheduledHours={scheduledHours}
          holidays={holidays}
          timeOffs={timeOffs}
          isAllocationsLazyLoaded={false}
          totalAllocatedHours={totalHours}
          resourceAllocations={[]}
          open={open}
          onClose={handleTooltipClose}
        >
          <div
            className={classNames(classes.root, {
              [classes.actualPlacement]: showActuals
            })}
            style={dynamicStyle}
          >
            <TimelineBlock
              hours={totalHours}
              percentage={percentage}
              showPercentage
            />
            <button
              type="button"
              aria-label={formatMessage({ id: 'button.showMore' })}
              onClick={handleTooltipClick}
              className={classes.transparentButton}
              onMouseLeave={handleTooltipClose}
            />
          </div>
        </ResourceUserPeriodSummaryTooltip>
      )}
    </>
  );
};

QuickAllocationUserTimeoffSummaryBlock.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  holidayDuration: PropTypes.object.isRequired,
  holidays: PropTypes.array,
  timeOffDuration: PropTypes.object.isRequired,
  timeOffs: PropTypes.array,
  scheduledDuration: PropTypes.object.isRequired,
  showActuals: PropTypes.bool
};

export default QuickAllocationUserTimeoffSummaryBlock;
