import React from 'react';
import { PropTypes } from 'prop-types';
import { TextField } from '@material-ui/core';

export const FormTextField = ({
  value,
  editable = true,
  fullWidth = true,
  disableUnderline = false,
  ariaLabel,
  inputClassName,
  startAdornment,
  endAdornment,
  ...rest
}) => (
  <TextField
    InputProps={{
      className: inputClassName,
      disableUnderline,
      inputProps: {
        'aria-label': ariaLabel
      },
      startAdornment,
      endAdornment
    }}
    value={value || ''}
    fullWidth={fullWidth}
    disabled={!editable}
    {...rest}
  />
);

FormTextField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  disableUnderline: PropTypes.bool
};

export default FormTextField;
