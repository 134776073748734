import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TaskIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 21,2.999999999999998 H 14.82 C 14.4,1.839999999999998 13.3,1 12,1 c -1.3,0 -2.399999999999999,0.839999999999998 -2.82,1.999999999999998 H 3 V 21 h 18 z m -9,0 c 0.55,0 1,0.449999999999999 1,1 0,0.550000000000001 -0.45,1.000000000000001 -1,1.000000000000001 -0.55000000000001,0 -1,-0.45 -1,-1.000000000000001 0,-0.550000000000001 0.44999999999999,-1 1,-1 z M 14,17 H 7.000000000000005 V 15 H 14 Z m 3,-4 H 7 V 11 H 17 Z M 17,9 H 7 V 7 h 10 z" />
    </>
  </SvgIcon>
));

export default TaskIcon;
