import {
  PROJECT_DAL,
  PROJECT_PERMISSION,
  USER_ACCESS_ROLE
} from '~/modules/common/enums';
import {
  useHasPermission,
  useHasUserAccessRole
} from '~/modules/common/permissions';

export const useProjectManagerCanEditOnlyMyProjects = () => {
  const isProjectManager = useHasUserAccessRole({
    roleUri: USER_ACCESS_ROLE.PROJECT_MANAGER
  });

  const canEditProject = useHasPermission({
    actionUri: PROJECT_PERMISSION.EDIT_PROJECT
  });

  const canEditAllProject = useHasPermission({
    actionUri: PROJECT_PERMISSION.EDIT_PROJECT,
    dataAccessLevelUri: PROJECT_DAL.PROJECT_ADMIN
  });

  return isProjectManager && canEditProject && !canEditAllProject;
};
