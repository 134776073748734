import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { BillStatus } from '~/types';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const ISSUE_BILL = gql`
  mutation issueBill($input: IssueBillInput!) {
    issueBill(issueBillInput: $input) {
      success
    }
  }
`;

export const useIssueBill = ({
  transactionId,
  updateDetails = false,
  refreshTransactionList = true,
  record
}) => {
  const [issueBill] = useMutation(ISSUE_BILL);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const refetchQueries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : [
        'getDraftTotals',
        'getOverdueBillSummary',
        'getOutstandingBillSummary',
        'getIssuedBillSummary'
      ];

  if (refreshTransactionList)
    refetchQueries.push('getPageOfBillingTransactions');

  if (updateDetails)
    refetchQueries.push(
      'getBillDetails',
      'getBillLineItems',
      'getBillTaxLineItems'
    );

  return {
    issueBill: async syncBill => {
      await issueBill({
        variables: {
          input: {
            invoiceId: transactionId,
            syncBill
          }
        },
        skip: record.billStatus === BillStatus.Billed,
        refetchQueries,
        awaitRefetchQueries: true
      });
    }
  };
};

export default useIssueBill;
