import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from '@material-ui/core/Input';

import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core';
import { useContextualFacets } from '../enhancers';
import FacetDetailField from '../FacetDetailField';
import SearchButton from './SearchButton';
import { useGenericSearchInputHandler } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 3, 3, 3),
    overflow: 'auto',
    maxHeight: '70vh'
  },
  facetContainer: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  genericInput: {
    fontSize: theme.typography.caption.fontSize,
    flexGrow: 1,
    flexShrink: 1
  },
  buttonRow: {
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}));

export const SearchFacets = ({
  className,
  facets,
  qeIdTag,
  hideDetailsMenu,
  hideSearch = false,
  setSearchCriteria,
  searchCriteria,
  searchFacetClasses
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    genericSearchValue,
    handleGenericSearchValueChange
  } = useGenericSearchInputHandler({ searchCriteria });

  return (
    <>
      <div className={classNames(classes.root, className)}>
        {!hideSearch && (
          <FacetDetailField
            label={intl.formatMessage({ id: 'search.hasWords' })}
            className={classes.facetContainer}
            classes={searchFacetClasses}
          >
            <Input
              data-qe-id={`${qeIdTag}_HasWords`}
              inputProps={{
                'aria-label': intl.formatMessage({ id: 'search.hasWords' })
              }}
              fullWidth
              className={classes.genericInput}
              value={genericSearchValue}
              onChange={handleGenericSearchValueChange}
            />
          </FacetDetailField>
        )}

        {useContextualFacets(facets).map(
          ({
            tag,
            shortTag,
            options,
            selected,
            setSelected,
            DetailsControl,
            context,
            facetDetailsContainerStylesOverride
          }) => (
            <div
              data-qe-id={`${qeIdTag}_${shortTag}`}
              key={tag}
              className={classes.facetContainer}
              style={facetDetailsContainerStylesOverride}
            >
              <DetailsControl
                options={options}
                selected={selected}
                setSelected={setSelected}
                context={context}
                searchFacetClasses={searchFacetClasses}
              />
            </div>
          )
        )}
      </div>
      <div className={classes.buttonRow}>
        <SearchButton
          qeIdTag={qeIdTag}
          genericSearchValue={genericSearchValue}
          facets={facets}
          setSearchCriteria={setSearchCriteria}
          hideDetailsMenu={hideDetailsMenu}
        />
      </div>
    </>
  );
};

SearchFacets.propTypes = {
  searchFacetClasses: PropTypes.object,
  className: PropTypes.string,
  qeIdTag: PropTypes.string,
  facets: PropTypes.array.isRequired,
  searchCriteria: PropTypes.object,
  hideDetailsMenu: PropTypes.func,
  hideSearch: PropTypes.bool,
  setSearchCriteria: PropTypes.func.isRequired
};

export default SearchFacets;
