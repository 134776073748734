import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import {
  DescriptionSharp,
  FolderSharp,
  ArrowDropUpSharp,
  ArrowDropDownSharp
} from '@material-ui/icons';
import { useMeContext } from '~/modules/me/useMeContext';
import { TaskIcon, MilestoneIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  container: ({ isProject, hasChildren }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: isProject || hasChildren ? 0 : theme.spacing(0.5)
  }),
  taskName: ({
    isPSATaskEstimateRollUpMobileViewEnabled,
    hasChildren,
    isProject
  }) => ({
    fontSize: isProject ? theme.spacing(2.75) : theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    ...(isPSATaskEstimateRollUpMobileViewEnabled && {
      fontWeight:
        isProject || hasChildren ? theme.typography.fontWeightBold : 460,
      overflowWrap: 'break-word',
      overflow: 'hidden'
    })
  }),
  titleIcon: ({ isProject }) => ({
    marginRight: theme.spacing(1.25),
    fill: theme.palette.text.secondary,
    ...(isProject && { fontSize: theme.spacing(3) })
  }),
  milestoneIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(0, 1, 0, 0),
    verticalAlign: 'middle'
  }
}));

const TaskCardTitle = ({
  isMilestone,
  displayText,
  hasChildren = false,
  isExpanded = false,
  isProject = false,
  onExpandCollapse
}) => {
  const {
    featureFlags: { isPSATaskEstimateRollUpMobileViewEnabled }
  } = useMeContext();
  const classes = useStyles({
    isPSATaskEstimateRollUpMobileViewEnabled,
    hasChildren,
    isProject
  });

  return isPSATaskEstimateRollUpMobileViewEnabled ? (
    <div className={classes.container}>
      {hasChildren &&
        (isExpanded ? (
          <ArrowDropUpSharp onClick={onExpandCollapse} />
        ) : (
          <ArrowDropDownSharp onClick={onExpandCollapse} />
        ))}
      {isProject ? (
        <FolderSharp className={classes.titleIcon} />
      ) : isMilestone ? (
        <MilestoneIcon className={classes.milestoneIcon} />
      ) : hasChildren ? (
        <TaskIcon className={classes.titleIcon} />
      ) : (
        <DescriptionSharp className={classes.titleIcon} />
      )}
      <Typography className={classes.taskName} variant="h6">
        {displayText}
      </Typography>
    </div>
  ) : (
    <Typography className={classes.taskName} variant="h6">
      {isMilestone && <MilestoneIcon className={classes.milestoneIcon} />}
      {displayText}
    </Typography>
  );
};

TaskCardTitle.propTypes = {
  isMilestone: PropTypes.bool.isRequired,
  displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  hasChildren: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isProject: PropTypes.bool,
  onExpandCollapse: PropTypes.func
};

export default TaskCardTitle;
