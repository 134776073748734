import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';

import Avatar from '@material-ui/core/Avatar';

import { ENTITY_AVATAR_SIZE } from './enums';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: blueGrey[800],
    position: 'unset'
  },
  colorDefault: {},
  img: {},
  sizeXXSmall: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: theme.typography.caption.fontSize
  },
  sizeXSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.typography.caption.fontSize
  },
  sizeSmall: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.typography.caption.fontSize
  },
  sizeSelect: {
    margin: [[-5, 0]],
    width: theme.spacing(4) - 3,
    height: theme.spacing(4) - 3,
    fontSize: theme.typography.caption.fontSize
  },
  sizeMedium: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  sizeLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  }
}));

const {
  XXSMALL,
  DEFAULT,
  XSMALL,
  SMALL,
  SELECT,
  MEDIUM,
  LARGE
} = ENTITY_AVATAR_SIZE;

export const EntityAvatar = forwardRef(
  ({ className, size, children, classes: classesOverride, ...props }, ref) => {
    const classes = useStyles({ classes: classesOverride });
    const {
      root,
      sizeXXSmall,
      sizeXSmall,
      sizeSmall,
      sizeSelect,
      sizeMedium,
      sizeLarge
    } = classes;
    const avatarClasses = useMemo(
      () => ({
        root: classnames(
          root,
          {
            [sizeXXSmall]: size === XXSMALL,
            [sizeXSmall]: size === XSMALL,
            [sizeSmall]: size === SMALL,
            [sizeSelect]: size === SELECT,
            [sizeMedium]: size === MEDIUM,
            [sizeLarge]: size === LARGE
          },
          className
        )
      }),
      [
        root,
        className,
        size,
        sizeLarge,
        sizeMedium,
        sizeSelect,
        sizeSmall,
        sizeXSmall,
        sizeXXSmall
      ]
    );

    return (
      <Avatar {...props} ref={ref} classes={avatarClasses}>
        {children}
      </Avatar>
    );
  }
);

EntityAvatar.muiName = 'Avatar';

EntityAvatar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  size: PropTypes.oneOf([
    XXSMALL,
    XSMALL,
    SMALL,
    SELECT,
    DEFAULT,
    MEDIUM,
    LARGE
  ]),
  alt: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.node
};

EntityAvatar.defaultProps = {
  size: 'default'
};

export default EntityAvatar;
