import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { projectWorkflowFragment } from '~/modules/projects/fragments/projectWorkflow.fragment';
import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';

export const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($id: String!) {
    deleteProject2(id: $id) {
      id
      status
    }
  }
`;

export const updateCache = projectId => (proxy, { data }) => {
  if (data && data.deleteProject2) {
    const {
      deleteProject2: { status }
    } = data;

    const fragmentId = `ProjectWorkflowState:${projectId}`;

    const workflow = proxy.readFragment({
      id: fragmentId,
      fragment: projectWorkflowFragment
    });

    if (!workflow) return;

    proxy.writeFragment({
      id: fragmentId,
      fragment: projectWorkflowFragment,
      data: {
        ...workflow,
        ...(status === 'DISABLED'
          ? {
              id: projectId,
              uri: PROJECT_EXECUTION_PHASE.CLOSEOUT,
              displayText: 'Archived'
            }
          : {})
      }
    });
  }
};

export const useDeleteProject = ({ objectId: projectId }) => {
  const [deleteProject] = useMutation(DELETE_PROJECT_MUTATION, {
    update: updateCache(projectId)
  });

  return deleteProject;
};
