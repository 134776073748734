import React, { Children } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import TaskCardTitle from '../TaskCardTitle';
import { ResponsiveTableRow } from '../ResponsiveTable';
import ResponsiveTableHeaderColumn from '../ResponsiveTable/ResponsiveTableHeaderColumn';
import withStyles from './withStyles';

const minWidthStyle = { minWidth: '165px' };

export const TasksHeader = ({
  classes,
  title,
  children,
  onChange,
  searchQeId,
  className,
  isMDView = false
}) => (
  <ResponsiveTableRow className={className}>
    {title && (
      <ResponsiveTableHeaderColumn
        style={minWidthStyle}
        flex="grow"
        size="lg"
        className={classNames(classes.headerCell, classes.title)}
      >
        <TaskCardTitle
          title={title}
          onChange={onChange}
          searchQeId={searchQeId}
        />
      </ResponsiveTableHeaderColumn>
    )}
    {!isMDView &&
      Children.map(children, child => {
        if (!child) {
          return child;
        }

        const { headerstyle } = child.props;

        return (
          <ResponsiveTableHeaderColumn
            className={classNames(classes.headerCell, className, headerstyle)}
            {...child.props}
          >
            {child}
          </ResponsiveTableHeaderColumn>
        );
      })}
  </ResponsiveTableRow>
);

TasksHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  searchQeId: PropTypes.string,
  className: PropTypes.string,
  isMDView: PropTypes.bool
};

export default withStyles(TasksHeader);
