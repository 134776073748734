import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 1
  },
  text: props => ({
    color: props.paletteClass.dark,
    '&:hover': {
      backgroundColor: alpha(
        props.paletteClass.main,
        theme.palette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }),
  outlined: props => ({
    color: props.paletteClass.dark,
    border: `1px solid ${alpha(props.paletteClass.dark, 0.5)}`,
    '&:hover': {
      border: `1px solid ${props.paletteClass.dark}`,
      backgroundColor: alpha(
        props.paletteClass.main,
        theme.palette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }),
  contained: props => ({
    color: theme.palette.getContrastText(props.paletteClass.main),
    backgroundColor: props.paletteClass.main,
    '&:hover': {
      backgroundColor: darken(
        props.paletteClass.main,
        theme.palette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: props.paletteClass.main
      }
    }
  })
}));

const RequestActionButton = ({
  className,
  classes: classesOverrides,
  label,
  paletteClass,
  secondary,
  ...otherProps
}) => {
  const theme = useTheme();
  const classes = useStyles({
    classes: classesOverrides,
    paletteClass: paletteClass || theme.palette.resourceRequest.draft
  });

  const filteredProps = Object.keys(otherProps).reduce((acc, key) => {
    if (key !== 'buttonComponent') {
      acc[key] = otherProps[key];
    }

    return acc;
  }, {});

  return (
    <Button
      {...filteredProps}
      secondary={secondary || undefined}
      classes={classes}
      className={className}
    >
      {label}
    </Button>
  );
};

RequestActionButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  paletteClass: PropTypes.object,
  secondary: PropTypes.bool
};

export default RequestActionButton;
