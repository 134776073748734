import React from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { hasValue } from '~/modules/customFields/utils';
import { useGetEnabledCustomFieldsQuery } from '~/modules/customFields/graphql';
import { CollapsibleDetailsPanel } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import SupervisorsInfoReadOnly from './SupervisorsInfo/SupervisorsInfoReadOnly';
import InfoSection from './InfoSection';
import BasicInformation from './BasicInformation';
import GroupsInformation from './GroupsInformation';
import AdditionalInfoSection from './AdditionalInfo';
import { ResourceTagsCard } from './tags';
import ResourcePoolInformation from './ResourcePoolInformation';

const useStyles = makeStyles(theme => ({
  userInfoCard: {
    padding: theme.spacing(4, 0, 0, 0),
    borderRadius: [[0, 0, theme.shape.borderRadius, 0]],
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}));

export const ResourceReadOnlyCard = ({ ...rest }) => {
  const isMobile = useIsBreakpointDown('sm');

  return isMobile ? (
    <CollapsibleDetailsPanel>
      <InfoCard {...rest} />
    </CollapsibleDetailsPanel>
  ) : (
    <InfoCard {...rest} />
  );
};

const supervisorTitle = (
  <FormattedMessage id="resourceBasicInfoCard.supervisor" />
);

export const InfoCard = ({
  editable,
  userDetails,
  permittedActions,
  isResourceDetailsLoading
}) => {
  const classes = useStyles();
  const { data: customFieldsData } = useGetEnabledCustomFieldsQuery({
    variables: { groupName: 'User' }
  });

  const customFieldDefinitions =
    get(customFieldsData, 'enabledCustomFields') || [];

  const customFieldValues = get(userDetails, 'customFieldValues');

  const hasCustomFieldDefinitions = customFieldDefinitions.length > 0;
  const hasCustomFieldValues =
    customFieldValues &&
    customFieldValues.length > 0 &&
    customFieldValues.some(hasValue);

  const showAdditionalInfo =
    (permittedActions.includes('urn:replicon:user-action:view-custom-fields') &&
      editable &&
      hasCustomFieldDefinitions) ||
    hasCustomFieldValues;

  const showResourcePoolsInfo = permittedActions.includes(
    'urn:replicon:user-action:view-resource-pool'
  );

  if (isResourceDetailsLoading) return <CardLoading showHeader={false} />;

  const supervisorSection = (
    <InfoSection editable={false} title={supervisorTitle}>
      <SupervisorsInfoReadOnly userDetails={userDetails} />
    </InfoSection>
  );

  return (
    <Card className={classes.userInfoCard}>
      <CardContent>
        <BasicInformation
          userDetails={userDetails}
          permittedActions={permittedActions}
        />
        {permittedActions.includes(
          'urn:replicon:user-action:view-supervisor'
        ) && supervisorSection}
        <GroupsInformation
          permittedActions={permittedActions}
          userDetails={userDetails}
        />
        {showResourcePoolsInfo && (
          <ResourcePoolInformation
            editable={
              permittedActions.includes(
                'urn:replicon:user-action:edit-resource-pool'
              ) && editable
            }
            userDetails={userDetails}
          />
        )}
        {showAdditionalInfo && (
          <AdditionalInfoSection
            editable={
              permittedActions.includes(
                'urn:replicon:user-action:edit-custom-fields'
              ) && editable
            }
            details={userDetails}
            customFieldDefinitions={customFieldDefinitions}
          />
        )}
        {permittedActions.includes(
          'urn:replicon:user-action:view-custom-fields'
        ) && (
          <ResourceTagsCard
            editable={
              permittedActions.includes(
                'urn:replicon:user-action:edit-custom-fields'
              ) && editable
            }
            userDetails={userDetails}
          />
        )}
      </CardContent>
    </Card>
  );
};

InfoCard.propTypes = {
  userDetails: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  isResourceDetailsLoading: PropTypes.bool.isRequired,
  permittedActions: PropTypes.array.isRequired
};

export default ResourceReadOnlyCard;
