import { gql } from 'graphql-tag';

export const projectBasicInfoFragment = gql`
  fragment ProjectBasicInfo on Project {
    id
    name
    code
    startDate: startDate2
    endDate: endDate2
    description
    program {
      id
      slug
      displayText
    }
    projectManagerReference {
      displayText
      id
    }
    coManagers {
      displayText
      id
    }
    timeAndExpenseEntryType {
      id
      displayText
    }
    portfolio {
      id
      displayText
    }
    isTimeEntryAllowed
    isProjectLeaderApprovalRequired
    projectManagementType
    slackChannel
    defaultScheduleRule {
      dateRange {
        startDate
        endDate
      }
      do
    }
  }
`;
