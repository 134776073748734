import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    maxWidth: 250
  }
}));

export const ProgramColumn = ({ record: { program = null } }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      {program === null ? <NoValue /> : program.displayText}
    </Typography>
  );
};

ProgramColumn.propTypes = {
  record: PropTypes.object
};

export default ProgramColumn;
