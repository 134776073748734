import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ErrorOutlined from '@material-ui/icons/ErrorOutlineSharp';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    background: '#fbe9e7'
  },
  icon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: theme.palette.error.main
  },
  message: {
    fontWeight: 500,
    paddingLeft: theme.spacing(2)
  }
}));

export const ErrorMessage = ({ errorKey }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ErrorOutlined className={classes.icon} />
      <Typography variant="body1" className={classes.message}>
        <FormattedMessage id={errorKey} />
      </Typography>
    </div>
  );
};

ErrorMessage.propTypes = {
  errorKey: PropTypes.string.isRequired
};
