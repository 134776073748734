/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable max-nested-callbacks */
import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { v4 as uuid } from 'uuid';
import { useTheme } from '@material-ui/core/styles';

const BubbleChart = ({ data, width = 300, onClick }) => {
  const d3Container = useRef(null);
  const theme = useTheme();

  const onBubbleItemClick = useCallback(
    ({ data: bubbleItemData }) => {
      onClick(bubbleItemData);
    },
    [onClick]
  );

  useEffect(() => {
    if (data && d3Container.current) {
      const format = d3.format(',d');
      const svgCanvas = d3.select(d3Container.current);
      const pack = d3
        .pack()
        .size([width - 2, width - 2])
        .padding(5);

      const root = d3.hierarchy({ children: data }).sum(d => d.value);
      const rootNodes = pack(root).leaves();

      const svg = svgCanvas
        .attr('viewBox', [0, 0, width, width])
        .attr('font-size', theme.typography.caption.fontSize)
        .attr('font-family', theme.typography.fontFamily)
        .attr('text-anchor', 'middle');

      svg.selectAll('g').remove();
      const leaf = svg
        .selectAll('g')
        .data(rootNodes)
        .join('g')
        .attr('transform', d => `translate(${d.x + 1},${d.y + 1})`);

      leaf
        .append('circle')
        .attr('id', d => (d.leafUid = uuid()))
        .style('cursor', 'pointer')
        .attr('r', d => d.r)
        .attr('fill-opacity', 0.7)
        .attr('fill', d => d.data.color)
        .on('click', onBubbleItemClick);

      leaf
        .append('clipPath')
        .attr('id', d => (d.clipUid = uuid()))
        .append('use')
        .attr('xlink:href', d => `#${d.leafUid}`);

      leaf
        .append('text')
        .attr('clip-path', d => `url(#${d.clipUid})`)
        .style('cursor', 'pointer')
        .on('click', onBubbleItemClick)
        .selectAll('tspan')
        .data(d =>
          [d.value.toFixed(0)].concat(d.data.label.split(/(?=[A-Z][a-z])|\s+/g))
        )
        .enter()
        .append('tspan')
        .attr('x', 0)
        .attr('y', (d, i, nodes) => `${i - nodes.length / 2 + 0.8}em`)
        .attr('style', (d, i) => (i === 0 ? 'font-weight:bold;' : ''))
        .text(d => d);

      leaf.append('title').text(d => `${format(d.value)}\n${d.data.label}`);
    }
  }, [
    width,
    data,
    onBubbleItemClick,
    theme.typography.caption.fontSize,
    theme.typography.fontFamily
  ]);

  return <svg ref={d3Container} />;
};

BubbleChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    })
  ),
  width: PropTypes.number,
  onClick: PropTypes.func
};

export default BubbleChart;
