import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import projectRatesFragment from './projectRates.fragment';

export const PUT_PROJECT_RATES = gql`
  mutation putProjectRates($projectId: String!, $rates: [ProjectRateInput2!]) {
    putProjectRates: putProjectRates3(projectId: $projectId, rates: $rates) {
      project {
        id
        ...ProjectRates
      }
    }
  }
  ${projectRatesFragment}
`;

export const usePutProjectRatesHandler = () => {
  const [putProjectRates] = useMutation(PUT_PROJECT_RATES);

  return {
    putProjectRates: async ({ parentId, rates }) =>
      putProjectRates({
        variables: {
          projectId: parentId,
          rates
        },
        refetchQueries: ['getAllProjectRates', 'getEffectiveRates'],
        awaitRefetchQueries: true
      })
  };
};
