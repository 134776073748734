import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const AddSkillDialogFormList = ({ children }) => (
  <Grid container spacing={1}>
    {React.Children.map(children, c => (
      <Grid item xs={12}>
        {c}
      </Grid>
    ))}
  </Grid>
);

AddSkillDialogFormList.propTypes = {
  children: PropTypes.node
};

export default AddSkillDialogFormList;
