import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';

const useContactHandlers = ({ openRemoveDialog, closeRemoveDialog }) => {
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const {
    initialValues,
    values: { contacts },
    setFieldValue
  } = useFormikContext();

  const onContactChange = useCallback(
    ({ index, field, value }) => {
      setFieldValue(`contacts[${index}].${field}`, value);
    },
    [setFieldValue]
  );

  const onRemoveContact = useCallback(
    r => {
      const isExistingEntry = initialValues.contacts.some(
        contact => contact.id === r.id
      );

      if (isExistingEntry) {
        setSelectedRecordId(r.id);
        openRemoveDialog();
      } else {
        setFieldValue(
          'contacts',
          contacts.filter(contact => contact.id !== r.id)
        );
      }
    },
    [contacts, initialValues.contacts, openRemoveDialog, setFieldValue]
  );

  const onConfirmRemoveContact = useCallback(() => {
    setFieldValue(
      'contacts',
      contacts.filter(contact => contact.id !== selectedRecordId)
    );
    closeRemoveDialog();
  }, [contacts, selectedRecordId, setFieldValue, closeRemoveDialog]);

  const onCloseRemoveDialog = useCallback(
    (_, reason) => {
      if (reason && reason === 'backdropClick') return;
      closeRemoveDialog();
    },
    [closeRemoveDialog]
  );

  return {
    onContactChange,
    onRemoveContact,
    onConfirmRemoveContact,
    onCloseRemoveDialog
  };
};

export default useContactHandlers;
