import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  taskVariationlabel: {
    fontSize: theme.spacing(1.75)
  },
  boldItem: {
    fontWeight: 600
  }
}));

const VariationFormattedDate = ({ days = null }) => {
  const classes = useStyles();

  if (days === null) {
    return (
      <div className={classes.taskVariationlabel}>
        <FormattedMessage id="taskDrawer.na" />
      </div>
    );
  }

  const variationLabel = days > 0 ? 'late' : days < 0 ? 'early' : 'onTime';
  const values =
    days > 0 || days < 0
      ? {
          days: <span className={classes.boldItem}>{`${Math.abs(days)} `}</span>
        }
      : null;

  return (
    <div className={classes.taskVariationlabel}>
      <FormattedMessage id={`taskDrawer.${variationLabel}`} values={values} />
    </div>
  );
};

VariationFormattedDate.propTypes = {
  days: PropTypes.number
};

export default VariationFormattedDate;
