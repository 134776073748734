import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  toRepliconDate,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import { getWorkDaysBetweenDates } from '~/modules/common/dates/compare';
import { OVERVIEW_UNITS_ENUM } from '~/modules/resource-management/common/enums';
import { Decimal } from '~/modules/common/components';
import OverAllocatedIndicator from '../../../OverAllocatedIndicator';
import AvailabilityTooltip from './AvailabilityTooltip';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  overAllocatedMark: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}));

const durationToHours = duration =>
  duration.hours + duration.minutes / 60 + duration.seconds / 3600;

export const GradientField = ({ period, overViewUnit }) => {
  const classes = useStyles();

  const holidayHours = durationToHours(period.holidayDuration);

  const workDaysDuration =
    getWorkDaysBetweenDates({
      startDateInRepliconFormat: toRepliconDate(
        mapIsoStringtoUtcObject(period.dateRange.startDate)
      ),
      endDateInRepliconFormat: toRepliconDate(
        mapIsoStringtoUtcObject(period.dateRange.endDate)
      )
    }) * 8;

  const scheduledHours = durationToHours(period.scheduledDuration);
  const overAllocatedHours = durationToHours(period.overAllocatedDuration);
  const allocatedHours =
    durationToHours(period.allocatedDuration) - overAllocatedHours;
  const timeOffHours = durationToHours(period.timeOffDuration);

  const availableHours = durationToHours(period.availableDuration);

  const availabilityPercentage =
    scheduledHours === 0 ? 0 : (availableHours / scheduledHours) * 100;

  const allocatedPercentage =
    scheduledHours === 0 ? 0 : (allocatedHours / scheduledHours) * 100;

  const availableFte =
    workDaysDuration === 0 ? 0 : availableHours / workDaysDuration;

  const resourceCountFTE =
    workDaysDuration === 0 ? 0 : scheduledHours / workDaysDuration;

  return (
    <AvailabilityTooltip
      resourceCount={resourceCountFTE}
      availableFte={availableFte}
      availableHours={availableHours}
      availabilityPercentage={availabilityPercentage}
      allocatedFte={allocatedHours / workDaysDuration}
      allocatedHours={allocatedHours}
      allocatedPercentage={allocatedPercentage}
      overAllocatedFte={overAllocatedHours / workDaysDuration}
      overAllocatedHours={overAllocatedHours}
      overAllocatedPercentage={
        scheduledHours === 0 ? 0 : (overAllocatedHours / scheduledHours) * 100
      }
      timeOffHours={timeOffHours}
      timeOffFte={workDaysDuration === 0 ? 0 : timeOffHours / workDaysDuration}
      timeOffPercentage={
        scheduledHours === 0 ? 0 : (timeOffHours / scheduledHours) * 100
      }
      holidayHours={holidayHours}
      holidayFte={workDaysDuration === 0 ? 0 : holidayHours / workDaysDuration}
      holidayPercentage={
        scheduledHours === 0 ? 0 : (holidayHours / scheduledHours) * 100
      }
      scheduledHours={scheduledHours}
    >
      <div
        className={classes.root}
        style={{
          backgroundColor: `rgba(38, 210, 147, ${allocatedPercentage / 100})`
        }}
      >
        {overViewUnit === OVERVIEW_UNITS_ENUM.HOURS && (
          <Decimal
            value={availableHours}
            suffix=" h"
            enableFixedFormatDecimalScale
            fixedDecimalScale={false}
          />
        )}
        {overViewUnit === OVERVIEW_UNITS_ENUM.PERCENTAGE && (
          <Decimal
            value={availabilityPercentage}
            suffix="%"
            fixedDecimalScale={false}
          />
        )}
        {overViewUnit === OVERVIEW_UNITS_ENUM.FTE && (
          <Decimal value={availableFte} fixedDecimalScale={false} />
        )}
        {overAllocatedHours > 0 && (
          <OverAllocatedIndicator className={classes.overAllocatedMark} />
        )}
      </div>
    </AvailabilityTooltip>
  );
};

GradientField.propTypes = {
  period: PropTypes.object.isRequired,
  overViewUnit: PropTypes.oneOf([
    OVERVIEW_UNITS_ENUM.HOURS,
    OVERVIEW_UNITS_ENUM.PERCENTAGE,
    OVERVIEW_UNITS_ENUM.FTE
  ]).isRequired
};

export default GradientField;
