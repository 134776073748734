import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/AddSharp';
import RemoveIcon from '@material-ui/icons/Remove';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Decimal } from '../Decimal/Decimal';
import { useStyles } from './useStyles';
import getNumberSuffixFormat from './getNumberSuffixFormat';

export const FormattedNumber = ({
  classes: classesOverrides,
  value,
  prefix,
  suffix,
  isHour,
  isHigher,
  showAddIcon,
  fixedDecimalScale,
  formattedMessageKey = null
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const Icon = showAddIcon ? (
    <AddIcon className={classes.icon} />
  ) : (
    <RemoveIcon className={classes.icon} />
  );

  const diffClass = isHigher ? classes.diffPositive : classes.diffNegative;
  const hourClass = isHour ? classes.hourFont : null;

  const formattedDecimalValue = useMemo(
    () => (
      <Decimal
        prefix={prefix}
        value={Math.abs(value)}
        suffix={suffix}
        fixedDecimalScale={fixedDecimalScale}
      />
    ),
    [value, suffix, prefix, fixedDecimalScale]
  );

  const formattedDecimalValueMemo = useMemo(
    () => ({
      value: formattedDecimalValue
    }),
    [formattedDecimalValue]
  );

  return (
    <div className={classNames(classes.root, diffClass, hourClass)}>
      {Icon}
      {formattedMessageKey ? (
        <FormattedMessage
          id={formattedMessageKey}
          values={formattedDecimalValueMemo}
        />
      ) : (
        formattedDecimalValue
      )}
    </div>
  );
};

FormattedNumber.propTypes = {
  classes: PropTypes.object,
  showAddIcon: PropTypes.bool,
  value: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  isHour: PropTypes.bool,
  isHigher: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  formattedMessageKey: PropTypes.string
};

const SummaryFormattedNumber = ({
  classes,
  value,
  prefix,
  isHour,
  isHigher,
  showAddIcon,
  formattedMessageKey = null
}) => {
  const { formattedValue, suffix } = getNumberSuffixFormat(value);

  return (
    <FormattedNumber
      classes={classes}
      value={Number(formattedValue)}
      isHigher={isHigher}
      prefix={prefix}
      suffix={suffix}
      isHour={isHour}
      showAddIcon={showAddIcon}
      fixedDecimalScale={false}
      formattedMessageKey={formattedMessageKey}
    />
  );
};

SummaryFormattedNumber.propTypes = {
  classes: PropTypes.object,
  showAddIcon: PropTypes.bool,
  value: PropTypes.number,
  prefix: PropTypes.string,
  isHour: PropTypes.bool,
  isHigher: PropTypes.bool,
  formattedMessageKey: PropTypes.string
};

export default SummaryFormattedNumber;
