import { SELECTED_STATUS } from '~/modules/billing-invoicing/common/components/BillingTransactionTab/enums';
import { BillStatus } from '~/types';

const getAllSelectedStatus = (total, selected) =>
  selected === 0
    ? SELECTED_STATUS.NONE
    : total === selected
    ? SELECTED_STATUS.INDIVIDUALLY_ALL
    : SELECTED_STATUS.PARTIAL;

export const useBillingTransactionItemSelector = ({
  transactionItems,
  includedTransactionItems,
  setIncludedTransactionItems,
  setAllSelectedStatus,
  allSelectedStatus,
  excludedTransactionItems,
  setExcludedTransactionItems
}) => {
  const onRowSelect = (record, event) => {
    const { checked } = event.target;

    if (allSelectedStatus === SELECTED_STATUS.ALL) {
      const updatedExcluded = checked
        ? excludedTransactionItems.filter(x => x.id !== record.id)
        : [...excludedTransactionItems, record];
      const updatedSelected = transactionItems.filter(
        x => !updatedExcluded.includes(x)
      );

      if (checked) {
        setExcludedTransactionItems(updatedExcluded);
        setIncludedTransactionItems(updatedSelected);
      } else {
        setExcludedTransactionItems(updatedExcluded);
        setIncludedTransactionItems(updatedSelected);
      }
    } else {
      const updatedSelectedTransactionItems = checked
        ? [...includedTransactionItems, record]
        : includedTransactionItems.filter(x => x.id !== record.id);
      const status = getAllSelectedStatus(
        transactionItems.length,
        updatedSelectedTransactionItems.length
      );

      setAllSelectedStatus(status);
      setIncludedTransactionItems(updatedSelectedTransactionItems);
    }
  };

  const onSelectAll = currentSelectionStatus => event => {
    const { checked } = event.target;

    if (currentSelectionStatus !== SELECTED_STATUS.NONE) {
      onReset();

      return;
    }

    setAllSelectedStatus(checked ? SELECTED_STATUS.ALL : SELECTED_STATUS.NONE);
    setExcludedTransactionItems(
      transactionItems.filter(t => t.billStatus === BillStatus.Void)
    );
    setIncludedTransactionItems(checked ? transactionItems : []);
  };

  const onReset = () => {
    setAllSelectedStatus(SELECTED_STATUS.NONE);
    setIncludedTransactionItems([]);
    setExcludedTransactionItems([]);
  };

  return {
    onRowSelect,
    onSelectAll,
    onReset
  };
};

export default useBillingTransactionItemSelector;
