import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useGroupItems from './useGroupItems';

const useStyles = makeStyles(theme => ({
  groupContainer: {
    margin: theme.spacing(0, 1, -2.5, 0)
  },
  groupItem: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  itemsRowContainer: {
    paddingBottom: theme.spacing(2.5)
  }
}));

export const GroupItems = ({
  classes: classesOverride,
  itemsMap = {},
  splitSize = 2
}) => {
  const classes = useStyles({ classes: classesOverride });

  const items = useGroupItems(itemsMap);

  const splitgroupElements = items
    .map((e, i) => i % splitSize === 0 && items.slice(i, i + splitSize))
    .filter(e => e);

  const groupItems = splitgroupElements.map(
    (groups, i) =>
      groups && (
        <Grid
          container
          className={classes.itemsRowContainer}
          key={`Group${i / groups.length}`}
          spacing={1}
        >
          {groups.map(({ key, element }) => (
            <Grid
              item
              xs={groups.length % splitSize === 0 ? 6 : 12}
              className={classes.groupItem}
              key={`Group-${key}`}
            >
              {element}
            </Grid>
          ))}
        </Grid>
      )
  );

  if (!groupItems || groupItems.length === 0) {
    return null;
  }

  return (
    <Grid container item className={classes.groupContainer} spacing={1}>
      {groupItems}
    </Grid>
  );
};

const GroupItemPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    element: PropTypes.node.isRequired,
    key: PropTypes.string.isRequired
  })
);

GroupItems.propTypes = {
  classes: PropTypes.object,
  itemsMap: PropTypes.objectOf(
    PropTypes.shape({
      costCenter: GroupItemPropTypes,
      division: GroupItemPropTypes,
      location: GroupItemPropTypes,
      department: GroupItemPropTypes,
      employeeType: GroupItemPropTypes,
      serviceCenter: GroupItemPropTypes
    })
  ),
  splitSize: PropTypes.number
};

export default GroupItems;
