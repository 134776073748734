import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'flex',
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightRegular
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
    minHeight: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    color: theme.palette.text.secondary
  },
  showProjects: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  root: {
    marginLeft: `${theme.spacing(2)}px`
  }
}));

export default useStyles;
