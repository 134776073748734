import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { POLICYTYPE } from '~/modules/common/enums';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PAGE_SIZE } from '~/modules/common/const';

export const COSTCENTER_QUERY = gql`
  query getCostCenters($searchTerm: String, $policy: String, $pageSize: Int) {
    costCenters(
      searchTerm: $searchTerm
      page: 1
      pageSize: $pageSize
      policy: $policy
    ) {
      id
      displayText
      hierarchyLevel
    }
  }
`;
const mappedCostCenters = data =>
  data?.costCenters?.map(costCenter => ({
    ...costCenter,
    value: costCenter.id,
    label: costCenter.displayText,
    key: costCenter.displayText
  }));

const useCostCenters = ({
  searchTerm,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  includeNoneOption = false,
  noneOptionText = ''
}) => {
  const { data, loading, refetch } = useQuery(COSTCENTER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchTerm,
      pageSize: PAGE_SIZE + 1,
      policy
    },
    context: {
      debounceKey: 'cost-center-search',
      debounceTimeout: 250
    }
  });

  const costCenters = !loading ? mappedCostCenters(data) : [];

  return {
    costCenters: (costCenters.length && includeNoneOption
      ? prependNoneItem(noneOptionText, costCenters)
      : costCenters
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    loading,
    refetch,
    hasMore: costCenters.length > PAGE_SIZE
  };
};

export const fetchCostCenters = ({
  apolloClient,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  intl
}) => async searchTerm => {
  const { data } = await apolloClient.query({
    query: COSTCENTER_QUERY,
    variables: { searchTerm, pageSize: PAGE_SIZE, policy }
  });

  return prependNoneItem(
    intl.formatMessage({ id: 'resourceBasicInfoCard.noCostCenter' }),
    mappedCostCenters(data)
  );
};

export const useSearchableCostCenters = ({ policy } = {}) => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const fetchOptions = fetchCostCenters({
    apolloClient,
    policy,
    intl
  });

  return { fetchOptions };
};

export default useCostCenters;
