import PropTypes from 'prop-types';
import React from 'react';
import {
  getTotalHoursForDateRangeFromScheduleRules,
  roundToDecimals
} from '~/modules/resourcing/common/util/scheduleUtil';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import { useResourceAllocationSeriesData } from '~/modules/resourcing/common/hooks';
import { useUserAllocationsSummaryContext } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/components/ResourceAllocationChartRow/UserAllocationsSummaryContext';
import { getAllocationPeriodEditorDateRangeFromAllocation } from '../ResourceAllocationPeriodEditPopover/components/ResourceAllocationEditor/getAllocationPeriodEditorDateRange';
import TaskAllocationPeriodEditPopover from '../TaskAllocationPeriodEditPopover';
import { useOnChangeHandlers } from './useOnChangeHandlers';

const TaskAllocationTimelineEditorOverlay = ({
  allocationPeriodEditTarget,
  handleAllocationPeriodClose,
  onChange,
  setNextPeriod,
  setPreviousPeriod,
  taskResourceUserAllocation,
  userId
}) => {
  const {
    resourceAllocationScheduleRules,
    loadingResourceAllocation,
    userTaskAllocationsSummaryScheduleRules,
    userTaskAllocationsSummaryLoading
  } = useUserAllocationsSummaryContext();
  const {
    user: { scheduleDurationByPeriodMap }
  } = taskResourceUserAllocation;

  const {
    anchorEl,
    startDate,
    endDate,
    allocatedHours = 0,
    hasAllocationPartialEnd,
    hasAllocationPartialStart
  } = allocationPeriodEditTarget;

  const scheduledHours =
    scheduleDurationByPeriodMap[startDate.toISO()]?.hours || 0;

  const {
    periodStartDate = startDate,
    periodEndDate = endDate
  } = taskResourceUserAllocation?.scheduleRules
    ? getAllocationPeriodEditorDateRangeFromAllocation({
        endDate,
        scheduleRules: taskResourceUserAllocation.scheduleRules,
        startDate,
        isPartial: hasAllocationPartialEnd || hasAllocationPartialStart
      })
    : {};

  const totalTaskAllocatedHours = userTaskAllocationsSummaryScheduleRules
    ? getTotalHoursForDateRangeFromScheduleRules({
        start: periodStartDate,
        end: periodEndDate,
        scheduleRules: userTaskAllocationsSummaryScheduleRules
      })
    : 0;

  const resourceAllocatedHours = resourceAllocationScheduleRules
    ? getTotalHoursForDateRangeFromScheduleRules({
        start: periodStartDate,
        end: periodEndDate,
        scheduleRules: resourceAllocationScheduleRules
      })
    : 0;

  const otherTaskAllocationHours = totalTaskAllocatedHours - allocatedHours;

  const allocatedHoursRoundedValue = roundToDecimals(allocatedHours);

  const availableHours = roundToDecimals(
    resourceAllocatedHours - otherTaskAllocationHours
  );

  const {
    resourceAllocationSeriesData,
    loading: scheduleLoading
  } = useResourceAllocationSeriesData({
    periodResolution: mapScaleToPeriodResolution(PERIOD_SCALE_ENUM.DAYS),
    dateRange: {
      startDate: periodStartDate,
      endDate: periodEndDate
    },
    filter: {
      userIds: [userId]
    },
    includeToBeHiredAndRequested: false,
    fetchPolicy: 'no-cache'
  });

  const { onAllocationChange } = useOnChangeHandlers({
    userTaskAllocationsSummaryScheduleRules,
    resourceAllocationScheduleRules,
    taskResourceUserAllocation,
    periodStartDate,
    periodEndDate,
    availableHours,
    onChange,
    effectiveUserScheduleSeriesData: (resourceAllocationSeriesData || []).map(
      seriesData => ({
        hours: Math.max(
          seriesData.totalSchedule - seriesData.timeoff - seriesData.holiday,
          0
        ),
        date: seriesData.dateRange.startDate
      })
    )
  });

  return (
    <TaskAllocationPeriodEditPopover
      anchorEl={anchorEl}
      periodDetails={allocationPeriodEditTarget}
      allocatedHours={allocatedHoursRoundedValue}
      handleAllocationPeriodClose={handleAllocationPeriodClose}
      setNextPeriod={setNextPeriod}
      availableHours={availableHours}
      onAllocationChange={onAllocationChange}
      setPreviousPeriod={setPreviousPeriod}
      scheduledHours={scheduledHours}
      taskResourceUserAllocation={taskResourceUserAllocation}
      isAvailabilityLoading={
        userTaskAllocationsSummaryLoading ||
        loadingResourceAllocation ||
        scheduleLoading
      }
    />
  );
};

TaskAllocationTimelineEditorOverlay.propTypes = {
  allocationPeriodEditTarget: PropTypes.object,
  handleAllocationPeriodClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  setNextPeriod: PropTypes.func,
  setPreviousPeriod: PropTypes.func,
  taskResourceUserAllocation: PropTypes.object,
  userId: PropTypes.string
};

export default TaskAllocationTimelineEditorOverlay;
