import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, Input, IconButton, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'luxon';
import ClearIcon from '@material-ui/icons/ClearSharp';
import TodayIcon from '@material-ui/icons/TodaySharp';
import ChartDateRangePicker from '~/modules/common/charts/dashboard/ChartDateRangePicker';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import FacetDetailField from '~/modules/common/components/SearchBox/FacetDetailField';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { isEnterKeyPress } from '~/modules/common/util';
import {
  useMonthRangeFacet,
  resolveDateTime,
  isFirstDateGreater
} from './hooks';

const label = <FormattedMessage id="monthRangeFacetDetails.title" />;

const useStyles = makeStyles(theme => ({
  root: {
    padding: 'unset',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  todayButton: {
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(-1.5),
    marginLeft: theme.spacing(-2.5)
  },
  divider: {
    padding: theme.spacing(0, 1)
  },
  container: {
    flexGrow: 1
  }
}));

export const MonthRangeFacetDetails = ({ selected, setSelected }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { onMonthChange } = useMonthRangeFacet({
    setSelected
  });
  const [anchorEl1, setAnchorEl1] = useState(null);
  const startMonthHandleClick = useCallback(
    event => {
      setAnchorEl1(event.currentTarget);
    },
    [setAnchorEl1]
  );
  const startMonthHandleClose = useCallback(() => setAnchorEl1(null), [
    setAnchorEl1
  ]);

  const [anchorEl2, setAnchorEl2] = useState(null);
  const endMonthHandleClick = useCallback(
    event => {
      setAnchorEl2(event.currentTarget);
    },
    [setAnchorEl2]
  );
  const endMonthHandleClose = useCallback(() => setAnchorEl2(null), [
    setAnchorEl2
  ]);

  const onStartMonthChange = useCallback(
    newStartMonth => {
      const { startDate } = newStartMonth;
      const endDate = selected.length ? selected[0].dateRange.endDate : null;
      const isStartDateGreater = isFirstDateGreater(startDate, endDate);

      onMonthChange({
        startDate,
        endDate: isStartDateGreater ? startDate : endDate
      });
    },
    [onMonthChange, selected]
  );

  const onEndMonthChange = useCallback(
    newEndMonth => {
      const { endDate } = newEndMonth;
      const startDate = selected.length
        ? selected[0].dateRange.startDate
        : null;
      const isStartDateGreater = isFirstDateGreater(startDate, endDate);

      onMonthChange({
        startDate: isStartDateGreater ? endDate : startDate,
        endDate
      });
    },
    [onMonthChange, selected]
  );

  const handleStartMonthChange = useCallback(
    value => {
      onStartMonthChange(value);
      startMonthHandleClose();
    },
    [startMonthHandleClose, onStartMonthChange]
  );

  const handleStartMonthClear = useCallback(() => {
    onStartMonthChange({});
  }, [onStartMonthChange]);

  const handleEndMonthChange = useCallback(
    value => {
      onEndMonthChange(value);
      endMonthHandleClose();
    },
    [endMonthHandleClose, onEndMonthChange]
  );

  const handleEndMonthClear = useCallback(() => {
    onEndMonthChange({});
  }, [onEndMonthChange]);

  const onTodayClick = useCallback(() => {
    const firstDayOfThisMonth = DateTime.local().startOf('month');
    const lastDayOfThisMonth = DateTime.local().endOf('month');

    onMonthChange({
      startDate: firstDayOfThisMonth,
      endDate: lastDayOfThisMonth
    });
  }, [onMonthChange]);

  const values = useMemo(() => {
    const hasStartMonthValue =
      selected && selected.length > 0 && selected[0].dateRange.startDate;
    const hasEndMonthValue =
      selected && selected.length > 0 && selected[0].dateRange.endDate;
    const startMonth = { startDate: DateTime.utc() };
    const endMonth = { startDate: DateTime.utc() };

    if (hasStartMonthValue) {
      startMonth.startDate = mapIsoStringtoUtcObject(
        selected[0].dateRange.startDate
      );
    }
    if (hasEndMonthValue) {
      endMonth.startDate = mapIsoStringtoUtcObject(
        selected[0].dateRange.endDate
      );
    }

    return {
      hasStartMonthValue,
      hasEndMonthValue,
      startMonth,
      endMonth,
      startMonthValue: hasStartMonthValue
        ? resolveDateTime(startMonth.startDate)
        : '',
      endMonthValue: hasEndMonthValue ? resolveDateTime(endMonth.startDate) : ''
    };
  }, [selected]);

  const startMonthInputProps = useMemo(
    () => ({
      onClick: startMonthHandleClick,
      'aria-label': formatMessage({ id: 'monthRangeFacetDetails.startMonth' })
    }),
    [startMonthHandleClick, formatMessage]
  );

  const endMonthInputProps = useMemo(
    () => ({
      onClick: endMonthHandleClick,
      'aria-label': formatMessage({ id: 'monthRangeFacetDetails.endMonth' })
    }),
    [endMonthHandleClick, formatMessage]
  );

  const startMonthEndAdornment = useMemo(
    () => (
      <IconButton
        classes={classes}
        aria-label={intl.formatMessage({
          id: 'monthRangeFacetDetails.clearMonthAriaLabel'
        })}
        role="button"
        onClick={handleStartMonthClear}
      >
        <ClearIcon />
      </IconButton>
    ),
    [classes, handleStartMonthClear, intl]
  );

  const endMonthEndAdornment = useMemo(
    () => (
      <IconButton
        classes={classes}
        aria-label={intl.formatMessage({
          id: 'monthRangeFacetDetails.clearMonthAriaLabel'
        })}
        role="button"
        onClick={handleEndMonthClear}
      >
        <ClearIcon />
      </IconButton>
    ),
    [classes, handleEndMonthClear, intl]
  );

  const handleStartMonthKeyDown = useCallback(
    event => {
      isEnterKeyPress(event) && startMonthHandleClick(event);
    },
    [startMonthHandleClick]
  );

  const handleEndMonthKeyDown = useCallback(
    event => {
      isEnterKeyPress(event) && endMonthHandleClick(event);
    },
    [endMonthHandleClick]
  );

  return (
    <FacetDetailField label={label}>
      <Grid container className={classes.container}>
        <Grid item xs="5">
          <FormControl fullWidth>
            <Input
              id="start-month"
              value={values.startMonthValue}
              endAdornment={
                values.hasStartMonthValue ? startMonthEndAdornment : null
              }
              inputProps={startMonthInputProps}
              fullWidth
              readOnly
              onKeyDown={handleStartMonthKeyDown}
            />
          </FormControl>
          <ChartDateRangePicker
            menuId="start-month-select-menu"
            anchorEl={anchorEl1}
            scale={PERIOD_SCALE_ENUM.MONTHS}
            dateRange={values.startMonth}
            onClose={startMonthHandleClose}
            onChange={handleStartMonthChange}
          />
        </Grid>
        <Grid item xs="1">
          <span className={classes.divider}>&mdash;</span>
        </Grid>
        <Grid item xs="5">
          <FormControl fullWidth>
            <Input
              id="end-month"
              value={values.endMonthValue}
              endAdornment={
                values.hasEndMonthValue ? endMonthEndAdornment : null
              }
              inputProps={endMonthInputProps}
              fullWidth
              readOnly
              onKeyDown={handleEndMonthKeyDown}
            />
          </FormControl>
          <ChartDateRangePicker
            menuId="end-month-select-menu"
            anchorEl={anchorEl2}
            scale={PERIOD_SCALE_ENUM.MONTHS}
            dateRange={values.endMonth}
            onClose={endMonthHandleClose}
            onChange={handleEndMonthChange}
          />
        </Grid>
        <Grid item xs="1">
          <IconButton
            className={classes.todayButton}
            onClick={onTodayClick}
            aria-label={intl.formatMessage({
              id: 'calendar.today'
            })}
            role="button"
          >
            <span
              role="img"
              aria-label={intl.formatMessage({
                id: 'calendar.todayIcon'
              })}
            >
              <TodayIcon />
            </span>
          </IconButton>
        </Grid>
      </Grid>
    </FacetDetailField>
  );
};

MonthRangeFacetDetails.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default MonthRangeFacetDetails;
