import { DateTime } from 'luxon';
import { useCallback } from 'react';

export const useFormOnChangeHandlers = ({ setFieldValue, allocations }) => {
  const onSelectAllClick = useCallback(
    event =>
      setFieldValue(
        'allocations',
        allocations.map(allocation => ({
          ...allocation,
          isSelected: event.target && event.target.checked
        }))
      ),
    [allocations, setFieldValue]
  );

  const onReleaseConfirmChange = useCallback(
    event =>
      setFieldValue('releaseConfirmed', event.target && event.target.checked),
    [setFieldValue]
  );

  const onRowSelectionChange = useCallback(
    (allocationId, event) =>
      setFieldValue(
        'allocations',
        allocations.map(allocation => {
          if (allocationId === allocation.id) {
            return {
              ...allocation,
              isSelected: event.target && event.target.checked
            };
          }

          return allocation;
        })
      ),
    [allocations, setFieldValue]
  );

  const onReleaseTypeChange = useCallback(
    event => setFieldValue('releaseType', event.target && event.target.value),
    [setFieldValue]
  );

  const onCommentsChange = useCallback(
    event =>
      setFieldValue('comment', (event.target && event.target.value) || ''),
    [setFieldValue]
  );

  const onReleaseDateChange = useCallback(
    date =>
      setFieldValue(
        'releaseDate',
        DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0)
      ),
    [setFieldValue]
  );

  return {
    onSelectAllClick,
    onReleaseConfirmChange,
    onReleaseTypeChange,
    onCommentsChange,
    onRowSelectionChange,
    onReleaseDateChange
  };
};

export default useFormOnChangeHandlers;
