import React from 'react';
import { PropTypes } from 'prop-types';

export const ReadOnly = ({ children }) => <>{children}</>;

ReadOnly.propTypes = {
  children: PropTypes.node
};

ReadOnly.displayName = 'ReadOnly';

export default ReadOnly;
