import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import { ProjectTypeToggle } from '~/modules/common/components/ProjectTypeToggle';
import ProjectSearchBox from '../ProjectSearchBox';

const useStyles = makeStyles(theme => ({
  searchBox: {
    flexGrow: 1
  }
}));

export const Toolbar = ({ isMobile }) => {
  const classes = useStyles();
  const {
    projectType,
    searchCriteria,
    setSearchCriteria,
    onProjectTypeClick
  } = useProjectsContext();

  const searchBoxClasses = useMemo(
    () => ({
      root: classes.searchBox
    }),
    [classes.searchBox]
  );

  return (
    <>
      {!isMobile && setSearchCriteria && (
        <ProjectSearchBox classes={searchBoxClasses} />
      )}
      <span>
        <ProjectTypeToggle
          projectType={projectType}
          searchCriteria={searchCriteria}
          onProjectTypeClick={onProjectTypeClick}
        />
      </span>
    </>
  );
};

Toolbar.propTypes = {
  isMobile: PropTypes.bool
};

export default Toolbar;
