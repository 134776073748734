import PropTypes from 'prop-types';

const tag = PropTypes.shape({
  id: PropTypes.string.isRequired,
  tag: PropTypes.shape({
    name: PropTypes.string
  }),
  value: PropTypes.shape({
    name: PropTypes.string
  })
});

const tags = PropTypes.arrayOf(tag);

export default {
  tag,
  tags
};
