import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { useMeContext } from '~/modules/me/useMeContext';
import { hasPermission } from '~/modules/common/permissions';
import useStyles from './useStyles';

export const AssignResourceButton = ({ qeIdTag, quantity, onClick }) => {
  const classes = useStyles();
  const { permissionsMap } = useMeContext();

  const isViewUserResourceManagerEnabled = hasPermission(permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-user',
    dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
  });

  const isViewUserResourcePoolManagerEnabled = hasPermission(permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-user',
    dataAccessLevelUri:
      'urn:replicon:user-data-access-level:resource-pool-manager'
  });

  const isViewUserEnabled =
    isViewUserResourceManagerEnabled || isViewUserResourcePoolManagerEnabled;

  const isEditAssignmentEnabled = hasPermission(permissionsMap)({
    actionUri:
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
  });

  return isViewUserEnabled && isEditAssignmentEnabled ? (
    <ButtonBase
      data-qe-id={`${qeIdTag}_AssignResource_Button`}
      onClick={onClick}
      className={classes.assignResourceButton}
    >
      <FormattedMessage id="resourceAllocationItem.allocateResource" />
      &nbsp;{quantity > 1 ? `× ${quantity}` : ''}
    </ButtonBase>
  ) : (
    <div className={classes.container}>
      <Typography variant="caption" className={classes.noResource}>
        <FormattedMessage
          id={
            quantity > 1
              ? 'resourceAllocationItem.noAllocateResources'
              : 'resourceAllocationItem.noAllocateResource'
          }
        />
      </Typography>
    </div>
  );
};

AssignResourceButton.propTypes = {
  qeIdTag: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AssignResourceButton;
