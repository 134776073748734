import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { gql } from 'graphql-tag';
import clientRatesFragment from '~/modules/common/enhancers/clientRates.fragment';
import { mapToDefaultRates } from '~/modules/rateCard/enhancers';

export const GET_CLIENT_RATES = gql`
  query GetClientRates($clientUri: String!) {
    client(uri: $clientUri) {
      id
      ...ClientRates
    }
  }
  ${clientRatesFragment}
`;

export const useClientRate = clientUri => {
  const { data } = useQuery(GET_CLIENT_RATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      clientUri
    },
    skip: !clientUri
  });
  const clientRate = get(data, 'client', {});

  return mapToDefaultRates(clientRate);
};

export default useClientRate;
