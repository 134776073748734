const ariaLabelIds = {
  USER: 'advancedRateCard.resource',
  PROJECT_ROLE: 'advancedRateCard.role',
  PAY_CODE: 'advancedRateCard.payCode'
};

const deletedRatesLabelIds = {
  USER: 'advancedRateCard.resourceDeleted',
  PROJECT_ROLE: 'advancedRateCard.roleDeleted',
  PAY_CODE: 'advancedRateCard.payCodeDeleted'
};

export const buildColumns = ({
  projectDimensions,
  formOnChange: {
    onAmountChange,
    onCurrencyChange,
    onEffectiveDateChange,
    onDimensionValueChange,
    onAddEntry,
    onRemoveEntry,
    onAddNewEntry
  },
  defaultRate,
  classes,
  errors,
  usersResponse,
  rolesResponse,
  payCodeResponse,
  onInputChange,
  readOnly
}) => {
  const dynamicColumns = projectDimensions.reduce(
    (retVal, curr, index) => ({
      ...retVal,
      [curr.id]: {
        id: curr.id,
        value: curr.displayText,
        isDimensionColumns: true,
        displayText: curr.displayText,
        defaultRate,
        visible: true,
        onAddNewEntry,
        dimensionIndex: index,
        className:
          index === projectDimensions.length - 1
            ? classes.lastDimensionCell
            : classes.dimensionCell,
        align: 'left',
        onChange: onDimensionValueChange,
        projectDimensions,
        objectType: curr.objectType,
        errors,
        usersResponse,
        rolesResponse,
        payCodeResponse,
        onInputChange,
        ariaLabelId: ariaLabelIds[curr.objectType],
        deletedRateLabelId: deletedRatesLabelIds[curr.objectType]
      }
    }),
    {}
  );

  return {
    ...dynamicColumns,
    default: {
      id: 'defaultDimension',
      value: '',
      visible: projectDimensions.length === 0,
      align: 'left'
    },
    effectiveDate: {
      id: 'effectiveDate',
      onEffectiveDateChange,
      value: 'Effective Date',
      visible: true,
      align: 'left',
      errors
    },
    amount: {
      id: 'amount',
      onAmountChange,
      onCurrencyChange,
      value: 'Billing Rate',
      visible: true,
      align: readOnly ? 'left' : 'right',
      className: classes.amount
    },
    addRemoveEntry: {
      id: 'addRemoveEntry',
      onAddEntry,
      onRemoveEntry,
      value: '',
      visible: !readOnly,
      className: classes.addRemoveEntry
    }
  };
};
