import React from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import isempty from 'lodash.isempty';
import { FormControl, FormHelperText } from '@material-ui/core';
import SearchableRoleDropDown from '~/modules/resourcing/common/components/SearchableRoleDropDown';

export const AdditionalRoleDropdownComponent = ({
  field = {},
  form: { touched = {}, errors = {} } = {},
  onChange,
  values,
  label,
  permittedActions,
  disabledValues
}) => {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  const canUserEditSkillAssignment = permittedActions.includes(
    'urn:replicon:user-action:edit-skill-assignments'
  );

  const selectedValues =
    values.filter(val => val.role !== null).length > 0
      ? values.map(
          val =>
            val && {
              id: val.role.id,
              displayText: val.role.displayText
            }
        )
      : [];

  return (
    <>
      <SearchableRoleDropDown
        label={label}
        value={selectedValues}
        disabledValues={disabledValues}
        multiple
        onChange={onChange}
        showRate
        canUserEditSkillAssignment={canUserEditSkillAssignment}
      />
      {!isempty(error) && touch && (
        <FormControl error>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      )}
    </>
  );
};

AdditionalRoleDropdownComponent.propTypes = {
  form: PropTypes.object,
  values: PropTypes.array,
  field: PropTypes.object,
  label: PropTypes.string,
  disabledValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  permittedActions: PropTypes.array
};

export default AdditionalRoleDropdownComponent;
