import { useCallback } from 'react';

export const useFormOnChange = ({ setFieldValue }) => {
  const onOptionChange = useCallback(
    event =>
      setFieldValue('autoGeneratedNumber', event.target.value === 'true'),
    [setFieldValue]
  );
  const onPrefixChange = useCallback(
    event => setFieldValue('prefix', event.target.value),
    [setFieldValue]
  );
  const onNextNumberChange = useCallback(
    event =>
      setFieldValue('nextNumber', event.target.value.replace(/[^0-9]/g, '')),
    [setFieldValue]
  );
  const onEditAutoGeneratedNumberChange = useCallback(
    event => setFieldValue('editAutoGeneratedNumber', event.target.checked),
    [setFieldValue]
  );

  return {
    onOptionChange,
    onPrefixChange,
    onNextNumberChange,
    onEditAutoGeneratedNumberChange
  };
};
