import { PropTypes } from 'prop-types';
import React from 'react';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { ExpenseEntryType } from '~/types';
import { useMonthColumnTotals } from '../hooks';
import { mergeScriptAndMonthlyExpense } from '../hooks/utils';
import { useColumnVisibilityProp } from './useColumnVisibilityProp';
import { useListColumns } from './columns';
import { useListFooters } from './footers';
import {
  useCardStyles,
  useListTableThemeProviderStyles,
  useColumnStyles
} from './styles';

const alignDeleteRow = 'center';

export const ExpenseBillPlanTable = ({
  editable,
  parameters,
  scripts,
  context,
  monthlyExpenses,
  allowBillable,
  showActuals,
  totalEstimatedAmount,
  customEditors,
  customFormatters,
  setFieldValue,
  onDeleteRow,
  errors,
  errorKey,
  canDeleteRow = true,
  isMobile,
  chartDates,
  projectId,
  projectCurrency,
  projectExpenseCodes,
  viewSummary,
  loading,
  canViewBillingData,
  ...rest
}) => {
  const {
    isLabelRowColumnVisible,
    isBillableTypeRowColumnVisible
  } = useColumnVisibilityProp({
    viewSummary,
    editable
  });

  const billableColumns = monthlyExpenses.filter(
    expense =>
      expense.expenseCode.expenseEntryType === ExpenseEntryType.Billable ||
      expense.expenseCode.expenseEntryType ===
        ExpenseEntryType.BillableAndNonBillable
  );
  const providerClasses = useListTableThemeProviderStyles({
    allowBillable,
    editable,
    viewSummary,
    isLabelRowColumnVisible,
    isBillableTypeRowColumnVisible,
    billableColumns
  });
  const columnClasses = useColumnStyles();
  const cardClasses = useCardStyles();
  const columns = useListColumns({
    parameters,
    setFieldValue,
    customEditors,
    customFormatters,
    editable,
    context,
    onDeleteRow,
    classes: columnClasses,
    errors,
    errorKey,
    canDeleteRow,
    isMobile,
    alignDeleteRow,
    chartDates,
    allowBillable,
    showActuals,
    projectCurrency,
    viewSummary,
    canViewBillingData,
    billableColumns,
    ...rest
  });

  const footers = useListFooters({
    parameters,
    classes: columnClasses,
    isMobile,
    chartDates,
    context,
    allowBillable,
    editable,
    showActuals,
    projectCurrency,
    viewSummary,
    canViewBillingData,
    billableColumns,
    ...rest
  });

  const totals = useMonthColumnTotals({
    columns,
    showActuals,
    monthlyExpenses,
    allowBillable,
    projectCurrency
  });

  const records = mergeScriptAndMonthlyExpense(
    scripts,
    monthlyExpenses,
    allowBillable,
    canViewBillingData
  );

  return records.length ? (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTable
        variant="table"
        classes={cardClasses}
        columns={columns}
        records={records}
        footers={editable || viewSummary ? footers : undefined}
        totals={editable || viewSummary ? totals : undefined}
        isLoading={loading}
      />
    </ListTableThemeProvider>
  ) : null;
};

ExpenseBillPlanTable.propTypes = {
  editable: PropTypes.bool,
  scripts: PropTypes.array.isRequired,
  monthlyExpenses: PropTypes.array.isRequired,
  allowBillable: PropTypes.bool,
  showActuals: PropTypes.bool,
  setFieldValue: PropTypes.func,
  onDeleteRow: PropTypes.func,
  parameters: PropTypes.array.isRequired,
  customEditors: PropTypes.object,
  customFormatters: PropTypes.object,
  errors: PropTypes.object,
  errorKey: PropTypes.string,
  totalEstimatedAmount: PropTypes.object,
  footers: PropTypes.object,
  canDeleteRow: PropTypes.bool,
  chartDates: PropTypes.array,
  isMobile: PropTypes.bool,
  records: PropTypes.array,
  context: PropTypes.string,
  totals: PropTypes.any,
  projectId: PropTypes.string,
  projectCurrency: PropTypes.object,
  projectExpenseCodes: PropTypes.array,
  viewSummary: PropTypes.bool,
  loading: PropTypes.bool,
  canViewBillingData: PropTypes.bool
};

export default ExpenseBillPlanTable;
