import React from 'react';
import PropTypes from 'prop-types';

export const FocusableDiv = ({ className, ariaLabel, role, children }) => (
  <div className={className} role={role} aria-label={ariaLabel} tabIndex={0}>
    {children}
  </div>
);

FocusableDiv.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.node
};

export default FocusableDiv;
