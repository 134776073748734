import sortBy from 'lodash/sortBy';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_SKILL_CATEGORIES_QUERY = gql`
  query GetSkillCategories {
    skillCategories {
      id
      displayText
    }
  }
`;

const useSkillCategories = () => {
  const { data, loading } = useQuery(GET_SKILL_CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const skillCategories = (data && data.skillCategories) || [];

  return {
    loading,
    skillCategories
  };
};

export const addLocalCategoryToCache = ({ client, category }) => {
  const cache = client.readQuery({
    query: GET_SKILL_CATEGORIES_QUERY
  });

  const { skillCategories } = cache;

  const updatedCategories = [
    ...skillCategories,
    {
      __typename: 'Category',
      ...category
    }
  ];

  client.writeQuery({
    query: GET_SKILL_CATEGORIES_QUERY,
    data: {
      skillCategories: sortBy(updatedCategories, c => c.displayText)
    }
  });
};

export default useSkillCategories;
