import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FaxIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 9 3 L 9 7 L 7 7 L 7 19 L 11 19 L 11 20 L 5 20 L 5 18 L 6 18 L 6 7 L 6 5 L 2 5 L 2 18 L 3 18 L 3 22 L 13 22 L 13 19 L 22 19 L 22 7 L 20 7 L 20 3 L 9 3 z M 11 5 L 18 5 L 18 8 L 11 8 L 11 5 z M 10 11 L 11 11 L 11 12 L 10 12 L 10 11 z M 12 11 L 13 11 L 13 12 L 12 12 L 12 11 z M 14 11 L 15 11 L 15 12 L 14 12 L 14 11 z M 18.5 11 A 1.5 1 0 0 1 20 12 A 1.5 1 0 0 1 18.5 13 A 1.5 1 0 0 1 17 12 A 1.5 1 0 0 1 18.5 11 z M 10 13 L 11 13 L 11 14 L 10 14 L 10 13 z M 12 13 L 13 13 L 13 14 L 12 14 L 12 13 z M 14 13 L 15 13 L 15 14 L 14 14 L 14 13 z M 10 15 L 11 15 L 11 16 L 10 16 L 10 15 z M 12 15 L 13 15 L 13 16 L 12 16 L 12 15 z M 14 15 L 15 15 L 15 16 L 14 16 L 14 15 z " />
    </>
  </SvgIcon>
));

export default FaxIcon;
