import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';

export const OUTSTANDING_BILLS_QUERY = gql`
  query getPageOfBillingTransactions(
    $page: Int!
    $pageSize: Int!
    $filter: BillingTransactionFilter
  ) {
    getPageOfBillingTransactions(
      page: $page
      pageSize: $pageSize
      filter: $filter
    ) {
      id
      displayText
      dueDate {
        dateValue
        textValue
      }
      balanceAmount {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

const mapToOutstandingBills = ({
  bills = [],
  me: { baseCurrency },
  values: { currency = null } = {}
}) =>
  bills.map(bill => ({
    ...bill,
    allocatedBillAmount: {
      amount: 0,
      currency: bill.balanceAmount.currency
    },
    amount: {
      amount: 0,
      currency: currency || baseCurrency
    }
  }));

export const useOutstandingBills = ({
  me,
  values,
  client: { id: contextClientId } = {},
  values: { client: { id: clientId } = {} } = {},
  creditMemo: { client: { id: creditMemoClientId } = {} } = {},
  billPayment: { client: { id: paymentClientId } = {} } = {},
  showAllOutstandingBills,
  billId
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, loading, fetchMore, variables } = useQuery(
    OUTSTANDING_BILLS_QUERY,
    {
      variables: {
        page: 1,
        pageSize: 20,
        filter: showAllOutstandingBills
          ? {
              clientUris: [
                contextClientId ||
                  clientId ||
                  creditMemoClientId ||
                  paymentClientId
              ],
              transactionTypes: ['BILL'],
              balanceStatusList: ['UNALLOCATED', 'PARTIALLY_ALLOCATED']
            }
          : { billingTransactionUris: [billId] }
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network'
    }
  );

  const getPageOfBillingTransactions = get(
    data,
    'getPageOfBillingTransactions',
    []
  );

  const hasMoreRows =
    getPageOfBillingTransactions &&
    getPageOfBillingTransactions.length !== 0 &&
    getPageOfBillingTransactions.length % variables.pageSize === 0;

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows) {
      return;
    }

    setIsLoadingMore(true);

    try {
      await fetchMore({
        query: OUTSTANDING_BILLS_QUERY,
        variables: {
          page: getPageOfBillingTransactions.length / variables.pageSize + 1,
          filter: variables.filter,
          pageSize: variables.pageSize
        },
        updateQuery: (
          previousResult,
          { fetchMoreResult: { getPageOfBillingTransactions: nextResult } }
        ) => ({
          getPageOfBillingTransactions: [
            ...previousResult.getPageOfBillingTransactions,
            ...nextResult
          ]
        })
      });
    } finally {
      setIsLoadingMore(false);
    }
  }, [fetchMore, getPageOfBillingTransactions.length, hasMoreRows, variables]);

  return {
    loading,
    outstandingBills: mapToOutstandingBills({
      bills: getPageOfBillingTransactions,
      me,
      values
    }),
    isLoadingMore,
    hasMoreRows,
    loadMoreRows
  };
};
