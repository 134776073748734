import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getStatusBasedClassName } from '~/modules/resourcing/common/enums';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import useRequestTimelineBlocks from '~/modules/resourcing/common/chart/hooks/useRequestTimelineBlocks';
import PeriodTimelineBlocks from '../PeriodTimelineBlocks';
import useStyles from './useStyles';

const ResourceRequestPeriodTimelineBlocks = memo(
  ({
    classes: classesOverrides,
    chartStartDate,
    resourceRequest,
    scale,
    rowIndex,
    isAllocationBlockPresent,
    periodTimelineBlockClasses
  }) => {
    const classes = useStyles({ classes: classesOverrides, rowIndex });

    const { dynamicPosition } = useMemo(
      () =>
        getDynamicPositionAttributes({
          chartStartDate,
          scale,
          start: mapIsoStringtoUtcObject(resourceRequest.startDate),
          end: mapIsoStringtoUtcObject(resourceRequest.endDate),
          rowIndex,
          isPsaRmpUserSchedulePerfFixEnabled: true
        }),
      [
        chartStartDate,
        scale,
        resourceRequest.startDate,
        resourceRequest.endDate,
        rowIndex
      ]
    );

    const { requestPeriods } = useRequestTimelineBlocks({
      scheduleRules: resourceRequest.scheduleRules,
      chartStartDate,
      scale,
      startDate: mapIsoStringtoUtcObject(resourceRequest.startDate),
      endDate: mapIsoStringtoUtcObject(resourceRequest.endDate),
      projectDefaultScheduleRule: resourceRequest.project.defaultScheduleRule,
      relativeDynamicPositionLeft: dynamicPosition.left
    });

    return (
      <div className={classes.root} style={dynamicPosition}>
        <div
          className={classNames(
            classes.container,
            classes[
              getStatusBasedClassName(
                resourceRequest.requestStatus,
                'container'
              )
            ]
          )}
        >
          {!isAllocationBlockPresent && (
            <PeriodTimelineBlocks
              classes={periodTimelineBlockClasses}
              displayPeriods={requestPeriods}
              scale={scale}
              quantity={resourceRequest.quantity}
              renderPeriod={({ _, loadingLabelClassName }) => (
                <div
                  className={classNames(
                    loadingLabelClassName,
                    resourceRequest.isAdjustedLoading
                      ? classes.loadingAdjusted
                      : null
                  )}
                ></div>
              )}
            />
          )}
        </div>
      </div>
    );
  }
);

ResourceRequestPeriodTimelineBlocks.propTypes = {
  classes: PropTypes.object,
  chartStartDate: PropTypes.object.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  rowIndex: PropTypes.number,
  isAllocationBlockPresent: PropTypes.bool,
  periodTimelineBlockClasses: PropTypes.object
};

export default ResourceRequestPeriodTimelineBlocks;
