import { mapNumberValueOrDefault } from './mapNumberValue';

export const customFieldValueToInput = customFieldValue => ({
  customField: {
    id: customFieldValue.customField.id
  },
  definition: {
    type: {
      uri: customFieldValue.customFieldType.id
    }
  },
  date: customFieldValue.date,
  dropDownOptionUri: customFieldValue.dropDownOptionUri,
  number: mapNumberValueOrDefault(customFieldValue.number),
  text: customFieldValue.text
});
