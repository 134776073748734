import React from 'react';
import { Collapse } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const styles = theme => ({
  stacked: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  cell: {
    height: '50px',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    padding: '0',
    alignItems: 'center'
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  left: {
    justifyContent: 'flex-start'
  },
  right: {
    justifyContent: 'flex-end'
  },
  center: {
    justifyContent: 'center'
  }
});

export const StackedCell = ({
  top,
  bottom,
  hideBottom,
  classes,
  align,
  className
}) => (
  <div className={classNames(classes.stacked, className)}>
    <div className={classNames(classes.cell, classes[align])}>{top}</div>
    <Collapse in={!hideBottom} mountOnEnter unmountOnExit>
      <div
        className={classNames(classes.cell, classes.borderTop, classes[align])}
      >
        {bottom}
      </div>
    </Collapse>
  </div>
);

StackedCell.propTypes = {
  top: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  bottom: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  hideBottom: PropTypes.bool,
  className: PropTypes.string
};

export default withStyles(styles)(StackedCell);
