import { makeStyles } from '@material-ui/core';

export const usePeriodDropdownStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 0, 0, 1),
    flexShrink: 0,
    flexGrow: 0
  }
}));

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2, 0, 0),
    backgroundColor: theme.palette.background.paper,
    flexGrow: 0,
    flexShrink: 0,
    position: 'sticky',
    left: 0,
    zIndex: 10,
    top: theme.spacing(6),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  spacer2: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex'
  },
  toggleGroup: {
    marginLeft: theme.spacing(1)
  },
  title: {
    display: 'flex',
    paddingRight: theme.spacing(5),
    flexGrow: 1,
    ...theme.typography.h6,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));

export default useStyles;
