import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { AVATAR_QUERY } from '~/modules/common/components/Avatars/UserAvatar/useAvatar';

export const REMOVE_AVATAR_MUTATION = gql`
  mutation RemoveAvatar($userId: String!) {
    removeAvatar(userId: $userId) {
      userId
    }
  }
`;

export const removeAvatarUpdate = ({ userUri }) => store => {
  store.writeQuery({
    query: AVATAR_QUERY,
    data: {
      avatar: {
        __typename: 'Avatar',
        id: userUri,
        avatar: null,
        medium: null
      }
    },
    variables: { userUri }
  });
};

const useRemoveAvatar = ({ userUri }) =>
  useMutation(REMOVE_AVATAR_MUTATION, {
    variables: {
      userId: userUri
    },
    update: removeAvatarUpdate({ userUri })
  });

export default useRemoveAvatar;
