import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_HOLIDAYS_FOR_USER_QUERY = gql`
  query getResourceHolidayDetails(
    $userSlug: String!
    $dateRange: DateRangeInput!
  ) {
    resource(userSlug: $userSlug) {
      id
      holidays(dateRange: $dateRange) {
        uri
        date
        name
        effectiveDuration {
          hours
          minutes
          seconds
        }
      }
    }
  }
`;

const useHolidaysForResourceUser = ({ userSlug, dateRange }) => {
  const { loading, error, data: { resource } = {} } = useQuery(
    RESOURCE_HOLIDAYS_FOR_USER_QUERY,
    {
      variables: {
        userSlug,
        dateRange: dateRange
          ? {
              startDate: dateRange.start,
              endDate: dateRange.end
            }
          : null
      }
    }
  );

  const holidays = (resource && resource.holidays) || [];

  return {
    loading,
    holidays,
    error
  };
};

export default useHolidaysForResourceUser;
