import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField, InputAdornment } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ResourceRequestStatus } from '~/types';
import { Decimal } from '~/modules/common/components';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import {
  getTotalHoursForDateRangeFromScheduleRules,
  getDateRangeFromScheduleRules,
  buildScheduleRuleForPeriod,
  mergePeriodRuleIntoScheduleRules,
  getTotalHours,
  getScheduleTotalHours
} from '~/modules/resourcing/common/util';
import {
  roundToDecimals,
  getDayViewRoundedHours,
  getDayViewRoundedPercentage
} from '~/modules/resourcing/common/util/scheduleUtil';
import {
  mapIsoStringtoUtcObject,
  toRepliconDate,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { TimelineBlock } from '~/modules/resourcing/common/chart/components';
import useStyles from './useStyles';

const _patchResourceRequest = request => scheduleRules => ({
  ...request,
  scheduleRules,
  totalHours: getTotalHours({
    scheduleRules,
    quantity: request.quantity || 1
  })
});

const highlightTarget = event => event.target.select();

const ResourceRequestEditor = ({
  scale,
  handlePeriodUpdate,
  handlePreviousPeriod,
  handleNextPeriod,
  periodDetails,
  allocatedHours: allocHours,
  requestedHours,
  resourceRequest
}) => {
  const periodDetailsRequest = resourceRequest;

  const { project } = useProjectContext();

  const resourceRequestStatus = resourceRequest.requestStatus;

  const classes = useStyles();

  const { displayUnit } = useDisplayUnitContext();
  const [allocatedHours, setAllocatedHours] = useState(allocHours);

  useEffect(() => setAllocatedHours(allocHours), [
    setAllocatedHours,
    periodDetailsRequest,
    allocHours
  ]);

  const isHoursMode = displayUnit === DISPLAY_UNIT_ENUM.HOURS;

  const getAllocatedValue = useCallback(
    event => {
      const value =
        event.target && event.target.value ? parseFloat(event.target.value) : 0;

      return isHoursMode ? value : (value * requestedHours) / 100;
    },
    [isHoursMode, requestedHours]
  );

  const handleHoursChange = useCallback(
    event => {
      setAllocatedHours(getAllocatedValue(event));
    },
    [getAllocatedValue]
  );

  const getRequestBasedOnModificationEvent = event => {
    const allocatedValue = getAllocatedValue(event);

    setAllocatedHours(allocatedValue);

    const previousValue = getTotalHoursForDateRangeFromScheduleRules({
      scheduleRules: periodDetailsRequest.scheduleRules,
      start: periodDetails.startDate,
      end: periodDetails.endDate
    });

    if (roundToDecimals(previousValue) === roundToDecimals(allocatedValue)) {
      return { alloc: resourceRequest, allocatedValue };
    }

    const { startDate, endDate } = periodDetails;

    const periodRule = buildScheduleRuleForPeriod({
      start: mapIsoStringtoUtcObject(startDate),
      end: mapIsoStringtoUtcObject(endDate),
      requestStartDate: mapRepliconDateToMidnightUTCString(
        toRepliconDate(periodDetails.startDate)
      ),
      requestEndDate: mapRepliconDateToMidnightUTCString(
        toRepliconDate(periodDetails.endDate)
      ),
      totalHours: allocatedValue * resourceRequest.quantity,
      quantity: resourceRequest.quantity
    });

    const scheduleRules = mergePeriodRuleIntoScheduleRules(
      periodDetailsRequest.scheduleRules
    )(periodRule);

    if (getScheduleTotalHours({ scheduleRules, quantity: 1 }) === 0) {
      return {
        alloc: { ...periodDetailsRequest, scheduleRules: [] },
        allocatedValue
      };
    }

    const [
      {
        dateRange: { startDate: scheduleStartDate }
      }
    ] = scheduleRules;

    const dateRange = getDateRangeFromScheduleRules(scheduleRules);

    const updatedRequestData = _patchResourceRequest({
      ...periodDetailsRequest,
      isAdjustedLoading: scheduleRules.length > 1,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      load:
        (getTotalHours({
          scheduleRules,
          quantity: resourceRequest.quantity
        }) *
          100) /
        getTotalHours({
          scheduleRules: [
            {
              dateRange: {
                startDate: scheduleStartDate,
                endDate:
                  scheduleRules[scheduleRules.length - 1].dateRange.endDate
              },
              do: project.defaultScheduleRule.do
            }
          ],
          quantity: resourceRequest.quantity || 1
        })
    })(scheduleRules);

    handlePeriodUpdate(updatedRequestData);

    return { alloc: updatedRequestData, allocatedValue };
  };

  const handleOnBlur = useCallback(
    event => {
      if (handlePeriodUpdate) {
        event.preventDefault();
        const { alloc } = getRequestBasedOnModificationEvent(event);

        handlePeriodUpdate(alloc);
      }
    },
    [getRequestBasedOnModificationEvent, handlePeriodUpdate]
  );

  const handleHoursKeyPress = useCallback(
    event => {
      if (handlePeriodUpdate && event.key === 'Enter') {
        handleOnBlur(event);
        event.preventDefault();
      }
    },
    [handlePeriodUpdate, handleOnBlur]
  );
  const handleHoursKeyDown = useCallback(
    event => {
      if (event.key !== 'Tab') return;
      if (event.shiftKey && handlePreviousPeriod) {
        const { alloc, allocatedValue } = getRequestBasedOnModificationEvent(
          event
        );

        event.preventDefault();

        handlePreviousPeriod(alloc, allocatedValue);
      } else if (!event.shiftKey && handleNextPeriod) {
        const { alloc, allocatedValue } = getRequestBasedOnModificationEvent(
          event
        );

        event.preventDefault();

        handleNextPeriod(alloc, allocatedValue);
      }
    },
    [handlePreviousPeriod, handleNextPeriod, getRequestBasedOnModificationEvent]
  );

  const value = isHoursMode
    ? allocatedHours
    : roundToDecimals((allocatedHours / requestedHours) * 100);

  const inputProps = useMemo(
    () => ({
      classes: {
        input: classes.hoursFieldInput
      },
      inputProps: {
        min: 0
      },
      disableUnderline: true,
      endAdornment: (
        <InputAdornment className={classes.inputAdornment}>
          {isHoursMode ? 'h' : '%'}
        </InputAdornment>
      )
    }),
    [classes.inputAdornment, classes.hoursFieldInput, isHoursMode]
  );

  const availableValue = isHoursMode
    ? requestedHours - allocatedHours
    : roundToDecimals(
        ((requestedHours - allocatedHours) / requestedHours) * 100
      );

  const detailsClasses = useMemo(
    () => ({
      container: classes.detailsContainer,
      label: classes.detailsLabel
    }),
    [classes.detailsContainer, classes.detailsLabel]
  );

  const hoursValue = value * resourceRequest.quantity;
  const availableHoursValue = availableValue * resourceRequest.quantity;
  const percentageValue = roundToDecimals(
    (allocatedHours / requestedHours) * 100
  );
  const percentage = getDayViewRoundedPercentage({
    percentage: percentageValue,
    scale
  });

  const totalHours = getDayViewRoundedHours({
    hours: hoursValue,
    scale
  });

  const totalAvailableHours = getDayViewRoundedHours({
    hours: availableHoursValue,
    scale
  });

  const availablePercentage = getDayViewRoundedPercentage({
    percentage: availableValue,
    scale
  });

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootToBeHired]:
          resourceRequestStatus === ResourceRequestStatus.Tobehired,
        [classes.rootRejected]:
          resourceRequestStatus === ResourceRequestStatus.Rejected
      })}
      data-qe-id="editorInput"
    >
      <TextField
        type="number"
        step="0.01"
        min="0"
        variant="standard"
        value={value}
        onBlur={handleOnBlur}
        onChange={handleHoursChange}
        onKeyPress={handleHoursKeyPress}
        onKeyDown={handleHoursKeyDown}
        autoFocus
        InputProps={inputProps}
        className={classes.hoursField}
        onFocus={highlightTarget}
      />
      <hr className={classes.hr} />
      {resourceRequest.quantity > 1 && (
        <div className={classes.details}>
          <FormattedMessage id="projectResourcing.requested" />:
          <TimelineBlock
            hours={totalHours}
            showPercentage={value > 0}
            percentage={percentage}
            classes={detailsClasses}
          />
        </div>
      )}
      {resourceRequest.quantity > 1 && (
        <div className={classes.details}>
          {isHoursMode && `(${resourceRequest.quantity} x`}
          {isHoursMode && <Decimal value={value} suffix="h" />}
          {isHoursMode && `)`}
        </div>
      )}
      <div className={classes.details}>
        <FormattedMessage id="projectResourcing.remaining" />:
        <TimelineBlock
          hours={totalAvailableHours}
          showPercentage={availableValue > 0}
          percentage={availablePercentage}
          classes={detailsClasses}
        />
      </div>
      {resourceRequest.quantity > 1 && (
        <div className={classes.details}>
          {isHoursMode && `(${resourceRequest.quantity} x`}
          {isHoursMode && (
            <Decimal
              value={availableValue}
              suffix="h"
              className={classNames({
                [classes.overAllocated]: availableValue < 0
              })}
            />
          )}
          {isHoursMode && `)`}
        </div>
      )}
    </div>
  );
};

ResourceRequestEditor.propTypes = {
  resourceRequest: PropTypes.object,
  allocatedHours: PropTypes.number,
  requestedHours: PropTypes.number,
  handlePeriodUpdate: PropTypes.func,
  handlePreviousPeriod: PropTypes.func,
  handleNextPeriod: PropTypes.func,
  periodDetails: PropTypes.object,
  scale: PropTypes.string
};

export default ResourceRequestEditor;
