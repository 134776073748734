import { useState, useCallback } from 'react';

export const useAddTaskDialogState = ({
  projectId,
  projectName,
  startDate,
  endDate
}) => {
  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false);
  const [isSubtask, setIsSubtask] = useState(false);
  const [parentUri, setParentUri] = useState(projectId);
  const [parentName, setParentName] = useState(projectName);
  const [parentStartDate, setParentStartDate] = useState(startDate);
  const [parentEndDate, setParentEndDate] = useState(endDate);

  const openAddTaskDialog = useCallback(
    ({
      parentUri: inputUri,
      parentName: inputName,
      startDate: inputStartDate,
      endDate: inputEndDate
    }) => {
      setIsAddTaskDialogOpen(true);
      inputUri && setIsSubtask(projectId !== inputUri);
      inputStartDate && setParentStartDate(inputStartDate);
      inputEndDate && setParentEndDate(inputEndDate);
      inputUri && setParentUri(inputUri);
      inputName && setParentName(inputName);
    },
    [
      projectId,
      setIsAddTaskDialogOpen,
      setParentUri,
      setParentStartDate,
      setParentEndDate,
      setIsSubtask,
      setParentName
    ]
  );

  const closeAddTaskDialog = useCallback(() => {
    setIsAddTaskDialogOpen(false);
    setParentUri(projectId);
    setParentStartDate(startDate);
    setParentEndDate(endDate);
    setIsSubtask(false);
    setParentName(projectName);
  }, [
    projectId,
    projectName,
    startDate,
    endDate,
    setIsAddTaskDialogOpen,
    setParentUri,
    setParentStartDate,
    setParentEndDate,
    setIsSubtask,
    setParentName
  ]);

  return {
    isAddTaskDialogOpen,
    isSubtask,
    parentUri,
    parentName,
    parentStartDate,
    parentEndDate,
    openAddTaskDialog,
    closeAddTaskDialog
  };
};

export default useAddTaskDialogState;
