import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const RECOGNIZED_REVENUE_SUMMARY = gql`
  query getRecognizedRevenueSummaryForProject(
    $projectId: String
    $dateRange: DateRangeInput2
  ) {
    project(projectId: $projectId) {
      id
      recognizedRevenueSummary(dateRange: $dateRange) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

export const useRecognizedRevenueSummary = ({ projectUri, dateRange }) => {
  const { data, loading } = useQuery(RECOGNIZED_REVENUE_SUMMARY, {
    variables: {
      projectId: projectUri,
      dateRange:
        dateRange && dateRange.startDate && dateRange.endDate
          ? {
              startDate: {
                day: 1,
                month: dateRange.startDate.month,
                year: dateRange.startDate.year
              },
              endDate: dateRange.endDate
            }
          : null
    },
    skip: !projectUri,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const recognizedRevenueSummary = get(
    data,
    'project.recognizedRevenueSummary',
    null
  );

  return { loading, recognizedRevenueSummary };
};

export default useRecognizedRevenueSummary;
