import React from 'react';
import { PropTypes } from 'prop-types';

import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import { DateRange } from '~/modules/common/components';
import { useNameColumnButtonBaseProps } from './useNameColumnButtonBaseProps';

const useStyles = makeStyles(theme => ({
  projectButton: {
    margin: theme.spacing(-1, -2),
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 250,
    textAlign: 'left'
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    width: 250 - theme.spacing(4)
  }
}));

const useProjectColumnWithNavigationButtonStyles = makeStyles(theme => ({
  projectButton: {
    width: 300
  }
}));

export const NameColumn = ({ classes: classesOverride, record: project }) => {
  const classes = useStyles({
    classesOverride
  });
  const projectColumnClasses = useProjectColumnWithNavigationButtonStyles();

  const props = useNameColumnButtonBaseProps(project);

  return (
    <ButtonBase
      className={classNames(
        classes.projectButton,
        projectColumnClasses.projectButton
      )}
      {...props}
    >
      <Typography className={classes.name}>{project.displayText}</Typography>
      <DateRange start={project.startDate} end={project.endDate} />
    </ButtonBase>
  );
};

NameColumn.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object
};

const StyledNameColumn = NameColumn;

export default StyledNameColumn;
