import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';
import {
  ListTable,
  ListTableThemeProvider
} from '~/modules/common/components/ListTable';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useOnBillingItemRowSelect } from '../hooks';
import { useListColumns } from './useListColumns';
import { useListFooters } from './useListFooters';

const useTableStyles = makeStyles(theme => ({
  tableRoot: {
    '& th:first-child': {
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        zIndex: 11,
        left: 0
      },
      [theme.breakpoints.down('sm')]: {
        left: 'unset'
      }
    },
    '& tbody td:first-child': {
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(5.25),
        position: 'sticky',
        left: 0
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& th:last-child, & tfoot td:last-child': {
        zIndex: 11,
        right: 0
      },
      '& tbody td:last-child': {
        position: 'sticky',
        right: 0
      },
      '& th:nth-child(2)': {
        zIndex: 11,
        left: theme.spacing(5.25)
      },
      '& tbody td:nth-child(2)': {
        position: 'sticky',
        left: theme.spacing(5.25)
      }
    },
    '& tfoot td:first-child': {
      padding: theme.spacing(0),
      zIndex: 11,
      left: 0
    },
    '& tfoot td:nth-child(2)': {
      zIndex: 11,
      left: theme.spacing(5.25),
      borderRight: `1px solid ${theme.palette.grey[200]}`
    }
  },

  tableHeaderCell: {
    height: 50,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.table.body,
    backgroundColor: theme.palette.common.white,
    top: 0,
    position: 'sticky',
    zIndex: 1
  },
  tableCell: {
    height: 50,
    borderBottomWidth: 'thin',
    backgroundColor: theme.palette.grey[100],
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'pre-wrap',
    flexDirection: 'row-reverse',
    zIndex: 1
  },
  tableFooterCell: {
    height: 50,
    fontWeight: theme.typography.fontWeightRegular,
    '& td:last-child': {
      paddingRight: theme.spacing(1) + 2,
      [theme.breakpoints.up('sm')]: {
        position: 'sticky',
        zIndex: 1,
        right: 0
      }
    },
    '& td:first-child': {
      position: 'sticky',
      zIndex: 1,
      left: 0
    },
    '& td:nth-child(2)': {
      position: 'sticky',
      zIndex: 1,
      left: theme.spacing(5.25)
    }
  },
  tableFooterRow: {
    backgroundColor: theme.palette.grey[50],
    botton: 0
  }
}));

const useColumnStyles = makeStyles(theme => ({
  displayText: {
    borderRight: `1px solid ${theme.palette.grey[200]}`
  },
  projectName: {
    minWidth: theme.spacing(25)
  },
  taskName: {
    minWidth: theme.spacing(25)
  },
  billingContractClauseName: {
    minWidth: theme.spacing(25)
  },
  itemType: {
    whiteSpace: 'nowrap'
  },
  user: {
    minWidth: theme.spacing(25),
    '& span': {
      whiteSpace: 'break-spaces'
    }
  },
  quantity: {
    whiteSpace: 'nowrap'
  },
  billingItemRate: {
    whiteSpace: 'nowrap'
  },
  billingAmount: {
    whiteSpace: 'nowrap'
  },
  expenseCode: {
    whiteSpace: 'nowrap'
  },
  subTitle: {
    lineHeight: 1,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const useStyles = makeStyles(theme => ({
  noneMessage: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 2),
    flexGrow: 1
  },
  selectAll: {
    position: 'fixed',
    marginTop: 3,
    zIndex: 100
  }
}));

const itemTypeLabels = {
  FIXED_BID: <FormattedMessage id="itemTypeLabels.fixedBid" />,
  TIMESHEET: <FormattedMessage id="itemTypeLabels.timesheet" />,
  EXPENSE: <FormattedMessage id="itemTypeLabels.expense" />,
  null: <FormattedMessage id="itemTypeLabels.noData" />
};

const billingItemTableResourceKey = ({ intl }) => ({
  selectAriaLabel: intl.formatMessage({
    id: 'billingItemTable.billingItem.select'
  }),
  selectAllAriaLabel: intl.formatMessage({
    id: 'billingItemTable.billingItem.selectAll'
  })
});

const itemTypeDisplayText = billingItems =>
  billingItems.map(billingItem => ({
    ...billingItem,
    itemType: itemTypeLabels[billingItem.itemType]
  }));

export const BillingItemsTable = ({
  billingItems,
  hasMoreRows,
  loadMoreRows,
  columns,
  billingAmount,
  setSelectedBillingItems
}) => {
  const intl = useIntl();
  const isPsaFPOvertimeBillingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFPOvertimeBillingEnabled'
  });
  const {
    onRowSelect,
    onSelectAll,
    selectedRows,
    selectAll
  } = useOnBillingItemRowSelect({
    billingItems,
    setSelectedBillingItems
  });
  const hasMultipleCurrencies = billingItems.some(
    obj =>
      obj.billingAmountOriginal.currency.id !== obj.billingAmount.currency.id
  );
  const providerClasses = useTableStyles();
  const classes = useStyles();
  const columnClasses = useColumnStyles();
  const selectedColumns = useListColumns({
    classes: columnClasses,
    selectedColumns: columns,
    onRowSelect,
    onSelectAll,
    billingItems,
    selectedRows,
    hasMultipleCurrencies,
    resourceKey: billingItemTableResourceKey({ intl }),
    isPsaFPOvertimeBillingEnabled
  });

  const footers = useListFooters({
    selectedColumns: columns,
    hasMultipleCurrencies,
    isPsaFPOvertimeBillingEnabled
  });
  const totals = useMemo(() => ({ billingAmount }), [billingAmount]);

  useEffect(() => {
    if (selectAll) onSelectAll(true);
  }, [selectAll, onSelectAll]);

  return (
    <>
      {!billingItems.length ? (
        <div className={classes.noneMessage}>
          <NoDataItem>
            <FormattedMessage id="addLineItemDialog.noMatchingItem" />
          </NoDataItem>
        </div>
      ) : (
        <>
          <ListTableThemeProvider classes={providerClasses}>
            <ListTable
              variant="table"
              records={itemTypeDisplayText(billingItems)}
              columns={selectedColumns}
              loadMore={loadMoreRows}
              hasMore={hasMoreRows}
              footers={footers}
              totals={totals}
            />
          </ListTableThemeProvider>
        </>
      )}
    </>
  );
};

BillingItemsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  hasMoreRows: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  billingItems: PropTypes.array.isRequired,
  billingAmount: PropTypes.any,
  setSelectedBillingItems: PropTypes.func
};

export default BillingItemsTable;
