import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  loadingButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-2),
      marginLeft: theme.spacing(0.5)
    }
  }
}));

export const BillingCardActionComponent = ({
  saveable,
  isSaving,
  onSave: onSaveWithoutConfirmation,
  onCancel,
  actionComponentCustomProps
}) => {
  const classes = useStyles();
  const {
    onSave: onSaveWithConfirmation,
    context,
    showConfirmationDialogBeforeSave
  } = actionComponentCustomProps;

  const handleSave = useCallback(async () => {
    if (context === 'project' && showConfirmationDialogBeforeSave) {
      onSaveWithConfirmation();
    } else {
      onSaveWithoutConfirmation();
    }
  }, [
    context,
    onSaveWithoutConfirmation,
    onSaveWithConfirmation,
    showConfirmationDialogBeforeSave
  ]);

  return (
    <>
      <Button onClick={onCancel} disabled={isSaving} data-qe-id="Cancel">
        <FormattedMessage id="button.cancel" />
      </Button>
      <LoadingButton
        color="primary"
        onClick={handleSave}
        className={classes.loadingButton}
        disabled={!saveable || isSaving}
        isLoading={isSaving}
        data-qe-id="Save"
      >
        <FormattedMessage id="button.save" />
      </LoadingButton>
    </>
  );
};
BillingCardActionComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  saveable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  actionComponentCustomProps: PropTypes.object
};

export default BillingCardActionComponent;
