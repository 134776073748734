import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PORTFOLIOS_DROPDOWN_DATA_VARIANT } from '../enum';

export const PROJECT_PORTFOLIOS_QUERY = gql`
  query pageOfPortfoliosAvailableForProjectAssignment(
    $input: PageOfProjectPortfolioInput
  ) {
    pageOfPortfoliosAvailableForProjectAssignment(input: $input) {
      id
      displayText
      isAssignable
      hierarchyLevel
    }
  }
`;

export const REQUEST_PORTFOLIOS_QUERY = gql`
  query pageOfPortfoliosAvailableForProjectRequestAssignment(
    $input: PageOfProjectRequestPortfoliosInput
  ) {
    pageOfPortfoliosAvailableForProjectRequestAssignment(input: $input) {
      id
      displayText
      isAssignable
      hierarchyLevel
    }
  }
`;

const mappedPortfolios = ({
  data,
  dataVariant,
  includeNoneOption,
  searchTerm,
  noneOptionText
}) => {
  const portfolios =
    dataVariant === PORTFOLIOS_DROPDOWN_DATA_VARIANT.PROJECT
      ? data.pageOfPortfoliosAvailableForProjectAssignment || []
      : data.pageOfPortfoliosAvailableForProjectRequestAssignment || [];

  const resultedPortfolio = portfolios?.map(portfolio => ({
    ...portfolio,
    value: portfolio.id,
    label: portfolio.displayText,
    key: portfolio.displayText
  }));

  return (resultedPortfolio.length && includeNoneOption && !searchTerm
    ? prependNoneItem(noneOptionText, resultedPortfolio)
    : resultedPortfolio
  ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE);
};

const usePortfolioDropdown = ({
  dataVariant,
  includeNoneOption,
  noneOptionText,
  searchTerm
}) => {
  const query =
    dataVariant === PORTFOLIOS_DROPDOWN_DATA_VARIANT.REQUEST
      ? REQUEST_PORTFOLIOS_QUERY
      : PROJECT_PORTFOLIOS_QUERY;

  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        searchTerm,
        page: 1,
        pageSize: PAGE_SIZE
      }
    }
  });

  return {
    portfolios:
      !loading && data
        ? mappedPortfolios({
            data,
            dataVariant,
            includeNoneOption,
            noneOptionText,
            searchTerm
          })
        : [],
    loading
  };
};

export const fetchPortfolios = ({
  apolloClient,
  intl,
  dataVariant
}) => async searchTerm => {
  const query =
    dataVariant === PORTFOLIOS_DROPDOWN_DATA_VARIANT.REQUEST
      ? REQUEST_PORTFOLIOS_QUERY
      : PROJECT_PORTFOLIOS_QUERY;
  const { data, loading } = await apolloClient.query({
    query,
    context: {
      debounceKey: 'portfolio-dd',
      debounceTimeout: 250
    },
    variables: {
      input: {
        searchTerm,
        page: 1,
        pageSize: PAGE_SIZE
      }
    }
  });

  return !loading && data
    ? mappedPortfolios({
        data,
        dataVariant,
        includeNoneOption: true,
        noneOptionText: intl.formatMessage({
          id: 'portfoliosDropdown.noPortfolio'
        }),
        searchTerm
      })
    : [];
};

export default usePortfolioDropdown;
