import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const AVATAR_UPLOAD_MUTATION = gql`
  mutation UploadAvatar($userId: String!, $avatarContent: String!) {
    putAvatar(userId: $userId, input: { base64: $avatarContent }) {
      avatar {
        id
        avatar
        medium
      }
    }
  }
`;

const useAvatarUpload = ({ userUri, onUploadComplete }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadAvatar] = useMutation(AVATAR_UPLOAD_MUTATION);

  const onUpload = useCallback(
    async files => {
      const { base64Content } = files[0];

      setIsUploading(true);

      await uploadAvatar({
        variables: {
          userId: userUri,
          avatarContent: base64Content
        }
      });

      setIsUploading(false);
      onUploadComplete && onUploadComplete();
    },
    [onUploadComplete, uploadAvatar, userUri]
  );

  return {
    onUpload,
    isUploading
  };
};

export default useAvatarUpload;
