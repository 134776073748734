import { columnGroups, defaultColumnNames } from './columns';

const useEnabledBillingTransactionColumns = ({
  columnClasses,
  selectedColumns,
  canAddPayment,
  tags
}) => ({
  enabledBillingTransactionColumnGroups: columnGroups(
    columnClasses,
    selectedColumns,
    canAddPayment,
    tags
  ),
  enabledBillingTransactionColumnNames: defaultColumnNames(
    columnClasses,
    selectedColumns,
    canAddPayment,
    tags
  )
});

export default useEnabledBillingTransactionColumns;
