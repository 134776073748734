import React from 'react';
import { Formik } from 'formik';
import { PropTypes } from 'prop-types';
import { useValidationSchema, useAddRoleOnSubmit } from './hooks';
import AddRoleDialogContent from './AddRoleDialogContent';
import { useInitialState } from './useInitialState';

export const AddRoleDialog = ({
  open,
  onClose,
  onChange,
  onCancel,
  initialName = '',
  includeSkills = false,
  refetchActiveRoles = false,
  canUserEditSkillAssignment
}) => {
  const validationSchema = useValidationSchema();

  const initialValues = useInitialState({ initialName });

  const { onSubmit } = useAddRoleOnSubmit({
    onClose,
    onChange,
    includeSkills,
    refetchActiveRoles
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <AddRoleDialogContent
        onClose={onClose}
        open={open}
        onCancel={onCancel}
        canUserEditSkillAssignment={canUserEditSkillAssignment}
      />
    </Formik>
  );
};

AddRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  initialName: PropTypes.string,
  includeSkills: PropTypes.bool,
  canUserEditSkillAssignment: PropTypes.bool,
  refetchActiveRoles: PropTypes.bool
};

export default AddRoleDialog;
