import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2)
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  }
}));

export const CardTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
};

CardTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default CardTitle;
