import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  tooltipName: {
    fontWeight: theme.typography.fontWeightBold
  },
  container: {
    display: 'flex',
    minWidth: 0,
    ...theme.typography.body2
  },
  roleName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: theme.spacing(0, 0.5, 0, 0)
  },
  roleCount: {
    flexShrink: 0
  },
  noRole: {
    color: theme.palette.text.secondary
  }
}));

export const getTooltipContent = ({ role, classes }) => (
  <div className={classes.tooltipName}>{role}</div>
);

export const ResourceRequestRoleCount = ({ role, roleCount }) => {
  const intl = useIntl();
  const classes = useStyles();

  const displayRoleText = role?.displayText
    ? role.displayText
    : intl.formatMessage({
        id: `resourceRequestReadonlyItem.noRole`
      });

  return (
    <div
      className={classNames(classes.container, {
        [classes.noRole]: !role?.displayText
      })}
    >
      <Tooltip
        title={getTooltipContent({ role: displayRoleText, classes })}
        placement="bottom-start"
      >
        <span className={classes.roleName}>{displayRoleText}</span>
      </Tooltip>
      <span className={classes.roleCount}>x {roleCount}</span>
    </div>
  );
};

ResourceRequestRoleCount.propTypes = {
  role: PropTypes.object,
  roleCount: PropTypes.number.isRequired
};

export default ResourceRequestRoleCount;
