import { withStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import ReadOnlyContainer from '~/modules/common/components/ReadOnlyContainer';

const styles = theme => ({
  readOnlyValue: {
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    paddingTop: '1px'
  }
});

export const ReadOnlyField = ({
  classes,
  className,
  label,
  value,
  dataQeId
}) => (
  <ReadOnlyContainer label={label}>
    <Typography
      variant="body2"
      className={classNames(classes.readOnlyValue, className)}
      data-qe-id={dataQeId}
    >
      {value}
    </Typography>
  </ReadOnlyContainer>
);

ReadOnlyField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  className: PropTypes.any,
  value: PropTypes.any,
  dataQeId: PropTypes.string
};

export default withStyles(styles)(ReadOnlyField);
