import { ResourceRequestStatus } from '~/types';

export const canShowAllocationTotalsForResourceRequest = requestStatus =>
  requestStatus === ResourceRequestStatus.Tentative ||
  requestStatus === ResourceRequestStatus.Complete;

export const getShowAllocationTotalForResourceRequest = ({
  resourceRequest: { requestStatus }
}) => ({
  showAllocationTotalForResourceRequest: canShowAllocationTotalsForResourceRequest(
    requestStatus
  )
});
