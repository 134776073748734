import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogTitle, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TaskIcon, TaskParentIcon } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    width: '1em',
    height: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const useTitleStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

const RescheduleTaskDialogTitle = ({ task }) => {
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const IconComponent = task.hasChildren ? TaskParentIcon : TaskIcon;

  return (
    <DialogTitle classes={titleClasses}>
      <FormattedMessage id="rescheduleTaskDialog.rescheduleTaskTitle" />
      <IconComponent className={classes.icon} />
      <span>{task.name}</span>
    </DialogTitle>
  );
};

RescheduleTaskDialogTitle.propTypes = {
  task: PropTypes.object
};

export default RescheduleTaskDialogTitle;
