import { useCallback, useState, useEffect } from 'react';
import { useGesture } from 'react-use-gesture';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { RESIZE_DIRECTION_ENUM } from '~/modules/resourcing/common/enums';
import {
  getResizeDirection,
  calculateDeltaDates
} from '~/modules/resourcing/common/hooks/useGestureUtil';

export const useResourcingDragIndicators = ({
  chartStartDate,
  scale,
  entity,
  entityUpdateHandler
}) => {
  const defaultState = entity
    ? {
        startDate: mapIsoStringtoUtcObject(entity.startDate),
        endDate: mapIsoStringtoUtcObject(entity.endDate),
        moveDelta: 0,
        sizeDelta: 0
      }
    : {};

  const [dragDelta, setDragDelta] = useState(0);
  const [dates, setDates] = useState(defaultState);
  const [currentResizeDirection, setResizeDirection] = useState(null);

  const handleDrag = useCallback(
    dragState => {
      const resize = getResizeDirection(dragState.event.target);

      if (
        resize === RESIZE_DIRECTION_ENUM.START ||
        resize === RESIZE_DIRECTION_ENUM.END
      )
        setResizeDirection(resize);

      setDragDelta(dragDelta + dragState.delta[0]);
      setDates(
        calculateDeltaDates({
          chartStartDate,
          scale,
          startDate: mapIsoStringtoUtcObject(entity.startDate),
          endDate: mapIsoStringtoUtcObject(entity.endDate),
          isActive: true,
          resizeDirection: currentResizeDirection,
          sizeDelta: dragState.movement[0]
        })
      );
    },
    [dragDelta, chartStartDate, scale, entity, currentResizeDirection]
  );

  const handleDragEnd = useCallback(() => {
    setDates(dates);
    setDragDelta(0);
    setResizeDirection(null);
    entityUpdateHandler({
      estimatedDate: dates,
      entity
    });
  }, [dates, entity, entityUpdateHandler]);

  const gestureBindEvents = useGesture({
    onDrag: handleDrag,
    onDragEnd: handleDragEnd
  });

  useEffect(() => {
    setDates({
      startDate: mapIsoStringtoUtcObject(entity.startDate),
      endDate: mapIsoStringtoUtcObject(entity.endDate)
    });
  }, [entity.startDate, entity.endDate]);

  return { gestureBindEvents, dragDelta, currentResizeDirection, dates };
};

export default useResourcingDragIndicators;
