import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useDialogState } from '~/modules/common/hooks';
import SideCardSectionDescriptionDialog from '~/modules/common/components/SideCard/SideCardSectionDescriptionDialog';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: ({ noPadding }) => (noPadding ? 0 : theme.spacing(1))
  },
  detail: {
    fontSize: theme.typography.body2.fontSize,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    'white-space': 'pre-wrap'
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.primary.main
  }
}));

export const SideCardSectionDescription = ({ classes, detail }) => {
  const detailClasses = useStyles({ classes });
  const [refCurrent, setRef] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current) {
      setRef(ref);
    }
  }, [ref, refCurrent]);

  const {
    open: dialogOpen,
    openDialog: onMoreClick,
    closeDialog: onDialogClose
  } = useDialogState(false);

  const heightsExist =
    refCurrent &&
    refCurrent.current &&
    refCurrent.current.scrollHeight &&
    refCurrent.current.offsetHeight;

  return (
    <div className={detailClasses.root}>
      <Typography ref={ref} className={detailClasses.detail}>
        {detail}
      </Typography>
      {heightsExist &&
        refCurrent.current.scrollHeight > refCurrent.current.offsetHeight && (
          <Button className={detailClasses.button} onClick={onMoreClick}>
            <FormattedMessage id="projectDetails.more" />
          </Button>
        )}
      {dialogOpen && (
        <SideCardSectionDescriptionDialog
          detail={detail}
          open={dialogOpen}
          onClose={onDialogClose}
        />
      )}
    </div>
  );
};

SideCardSectionDescription.propTypes = {
  classes: PropTypes.object,
  detail: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default SideCardSectionDescription;
