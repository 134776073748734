import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDialogState } from '~/modules/common/hooks';
import User from '~/modules/common/components/User';
import Client from '~/modules/common/components/Client';
import {
  SideCardSectionHeader,
  SideCardSectionDetail
} from '~/modules/common/components/SideCard';
import EditClientsDialog from './EditClientsDialog';

const clientsLabel = <FormattedMessage id="projectBasicInfoCard.clients" />;
const clientRepLabel = (
  <FormattedMessage id="projectBasicInfoCard.clientRepresentative" />
);

const useStyles = makeStyles(theme => ({
  label: {
    paddingLeft: 0,
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

const useUserStyles = makeStyles(theme => ({
  container: {
    padding: 0
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export const Clients = ({
  showEdit,
  canViewClient,
  canEditClient,
  projectId,
  client,
  clientRepresentative,
  projectManagerReference
}) => {
  const classes = useStyles();
  const userClasses = useUserStyles();
  const { open, openDialog, closeDialog } = useDialogState();

  const showClientRepresentative = clientRepresentative || showEdit;

  return (
    <>
      <SideCardSectionHeader
        title={clientsLabel}
        onEditClick={openDialog}
        dataQeId="EditClientsSection"
        showEdit={showEdit && canEditClient}
        ariaLabelKey="button.clientsInfoEditButton"
      />
      {canViewClient && (
        <SideCardSectionDetail
          noPadding
          detail={
            client && (
              <Client
                classes={classes}
                client={client}
                dataQeId="ProjectClient"
              />
            )
          }
        />
      )}
      {showClientRepresentative && (
        <SideCardSectionDetail
          label={clientRepLabel}
          detail={
            clientRepresentative && (
              <User
                classes={userClasses}
                dataQeId="ClientRepUser"
                user={clientRepresentative}
              />
            )
          }
        />
      )}
      <EditClientsDialog
        title={clientsLabel}
        open={open}
        onClose={closeDialog}
        projectId={projectId}
        canViewClient={canViewClient}
        canEditClient={canEditClient}
        client={client}
        clientRepresentative={clientRepresentative}
        projectManagerReference={projectManagerReference}
      />
    </>
  );
};

Clients.propTypes = {
  showEdit: PropTypes.bool,
  canViewClient: PropTypes.bool,
  canEditClient: PropTypes.bool,
  projectId: PropTypes.string,
  client: PropTypes.object,
  clientRepresentative: PropTypes.object,
  projectManagerReference: PropTypes.object
};

export default Clients;
