import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RoleIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 16,2 C 15.03553332885284,2.146930376386631 14.09039902360898,2.329310615421569 13.3046875,2.7890625 8.738866057288689,1.583685491552352 5,4 5.0078125,8.162109375 5.013905825795164,11.40832869237367 7.603655918021177,13.6373828651818 8.184091471825551,17 H 7 c -1.0907027677497,0 -2,0.9092972322503 -2,2 v 3 h 14 v -3 c 0,-1.0907027677497 -0.9092972322503,-2 -2,-2 H 15.67382812565953 C 15.6068924237468,14.80569005921799 15.17475253316026,13 14.27734375,11.193359375 c 0.70454809930381,0.33208608108888 2.822265625,1.62890624999999 2.822265625,1.62890625 0,0 0.92573525188339,-0.62093271160301 2.0078125,-1.798828125 0.31313148252433,-0.33034067205805 0.33245484380199,-0.93983002247075 0.091796875,-1.326171875 C 18.24576809896537,8.16876305326965 16.46922707151216,5.840963781011681 14.828125,4.54296875 Z m -2.44921875,4.609375 c 0.0835424845191,-0.0042370813552 0.16681313060185,0.01255135535507 0.2421875,0.048828125 l 0.8125,0.375 C 15.23420555228048,7.303074815025557 14.80143876149147,8.243432561366265 14.1875,7.94140625 l -0.8125,-0.375 C 12.8927407570454,7.354043589599542 13.02453414680585,6.636501800903748 13.55078125,6.609375 Z" />
    </>
  </SvgIcon>
));

export default RoleIcon;
