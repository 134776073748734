import PropTypes from 'prop-types';
import React, { useState, useRef, useCallback } from 'react';
import { useMeContext } from '~/modules/me';
import {
  useResourceUsers,
  useResourceAllocationChartEventHandlers
} from '~/modules/resourcing/common/hooks';
import { useKeyValueSettingsMutation } from '~/modules/common/hooks';
import { ResourceLegendComponent } from '~/modules/common/components/ResourceLegendComponent';
import DisplayUnitContextProvider from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';
import {
  ResourceAllocationUserChartLoading,
  ResourceAllocationUserChartHeaderRow,
  ResourceAllocationUserChartToolbar,
  NoResourceOrPageOutOfBoundMessage,
  ResourceAllocationUserRow
} from './components';

const LegendComponent = <ResourceLegendComponent />;
const PAGE_SIZE = 20;

export const ResourceAllocationUserChartContent = ({
  leftComponentWidth,
  chartDates,
  onPrevious,
  onNext,
  scale,
  setScale,
  dateRange,
  defaultDisplayUnit,
  searchCriteria,
  currentPageForTimeline,
  setCurrentPageForTimeline
}) => {
  const scaleValue = useRef(scale);
  const [displayUnit, setDisplayUnit] = useState(defaultDisplayUnit);
  const [putKeyValueSettings] = useKeyValueSettingsMutation();

  const {
    handleDisplayUnitChange,
    handleScaleChange
  } = useResourceAllocationChartEventHandlers({
    key: 'allocation_chart_settings',
    setScale,
    scale,
    putKeyValueSettings,
    displayUnit,
    setDisplayUnit
  });

  const { permissionsMap } = useMeContext();

  const { filter } = useFilterBasedOnSearchCriteria({ searchCriteria });
  const { loadingRows, loadingMore, resourceUsers } = useResourceUsers({
    page: currentPageForTimeline,
    pagesize: PAGE_SIZE,
    filter,
    dateRange,
    scale,
    isViewAvailabilityEnabled: Boolean(
      permissionsMap['urn:replicon:user-action:view-availability']
    ),
    isViewResourceAllocation: false,
    skipMerge: true
  });

  const handleTimelineScaleChange = useCallback(
    value => {
      setCurrentPageForTimeline(1);
      handleScaleChange(value);
    },
    [handleScaleChange, setCurrentPageForTimeline]
  );

  return (
    <DisplayUnitContextProvider
      defaultDisplayUnit={displayUnit}
      onDisplayUnitChange={handleDisplayUnitChange}
    >
      <ResourceAllocationUserChartToolbar
        scale={scale}
        dateRange={dateRange}
        onPreviousClick={onPrevious}
        onNextClick={onNext}
        setScale={handleTimelineScaleChange}
        LegendComponent={LegendComponent}
        displayDialog={false}
      />
      <ResourceAllocationUserChartHeaderRow
        resourceTotalCount={resourceUsers?.length || 0}
        currentPage={currentPageForTimeline}
        pageSize={PAGE_SIZE}
        setCurrentPage={setCurrentPageForTimeline}
        loadingRows={loadingRows}
        scale={scale}
        leftComponentWidth={leftComponentWidth}
        chartDates={chartDates}
        pageUsers={resourceUsers}
        showTableHeaderNavigation
      />
      {!loadingRows &&
      scaleValue.current === scale &&
      resourceUsers.length > 0 ? (
        resourceUsers.map(u => (
          <ResourceAllocationUserRow
            key={`resource_user${u.id}`}
            scale={scale}
            resource={u}
            leftComponentWidth={leftComponentWidth}
            chartDisplayDateRange={dateRange}
            chartDates={chartDates}
          />
        ))
      ) : (
        <NoResourceOrPageOutOfBoundMessage
          loadingRows={loadingRows}
          currentPage={currentPageForTimeline}
          setCurrentPage={setCurrentPageForTimeline}
          resourceTotalCount={resourceUsers?.length || 0}
        />
      )}
      {(loadingRows || loadingMore) && <ResourceAllocationUserChartLoading />}
    </DisplayUnitContextProvider>
  );
};

ResourceAllocationUserChartContent.propTypes = {
  leftComponentWidth: PropTypes.number.isRequired,
  chartDates: PropTypes.array,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  scale: PropTypes.any,
  setScale: PropTypes.func,
  dateRange: PropTypes.object,
  searchCriteria: PropTypes.object,
  defaultDisplayUnit: PropTypes.string,
  currentPageForTimeline: PropTypes.number,
  setCurrentPageForTimeline: PropTypes.any
};

export default ResourceAllocationUserChartContent;
