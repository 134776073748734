import { gql } from 'graphql-tag';

export const GET_PAGE_OF_TASK_RESOURCE_ESTIMATE_SUMMARY_FOR_RESOURCE_USER = gql`
  query getPageOfTaskResourceEstimateSummaryForResourceUser(
    $pageSize: Int!
    $page: Int!
    $userId: String!
    $projectId: String!
  ) {
    project(projectId: $projectId) {
      id
      pageOfTaskResourceEstimateSummaryForResourceUser(
        page: $page
        pageSize: $pageSize
        userUri: $userId
      ) {
        task {
          id
          uri
          code
          displayText
        }
        projectRole {
          id
          displayText
        }
        estimateUri
        fullPath {
          id
          uri
          slug
          displayText
        }
      }
    }
  }
`;

const getPageOfTaskResourceEstimateSummaryForResourceUser = async ({
  apolloClient,
  page,
  pageSize,
  userId,
  projectId
}) => {
  const { loading, data } = await apolloClient.query({
    query: GET_PAGE_OF_TASK_RESOURCE_ESTIMATE_SUMMARY_FOR_RESOURCE_USER,
    variables: {
      pageSize,
      page,
      userId,
      projectId
    },
    fetchPolicy: 'network-only'
  });

  return {
    taskResourceEstimateSummaryForResourceUser:
      data?.project?.pageOfTaskResourceEstimateSummaryForResourceUser || [],
    loading
  };
};

export default getPageOfTaskResourceEstimateSummaryForResourceUser;
