/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useState, useCallback, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Popper,
  makeStyles,
  InputAdornment,
  ClickAwayListener
} from '@material-ui/core';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { UserWithRoles } from '~/modules/common/components/User';
import {
  SimpleAutocomplete,
  AutocompleteInput
} from '~/modules/common/components/SearchAutocomplete';
import { LoadingButton } from '~/modules/common/components';
import {
  usePageOfProjectResources,
  useFilteredUsersAssignedToTask
} from '~/modules/common/hooks';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';

const useStyles = makeStyles(theme => ({
  popper: {
    border: `1px solid ${'#e1e4e8'}`,
    boxShadow: theme.shadows[8],
    borderRadius: 6,
    width: 250,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    backgroundColor: theme.palette.background.paper
  },
  autoCompletePopper: {
    [`& .${autocompleteClasses.paper}`]: {
      boxShadow: 'none',
      margin: 0,
      color: 'inherit',
      fontSize: 13
    },
    [`& .${autocompleteClasses.listbox}`]: {
      backgroundColor: theme.palette.background.paper,
      [`& .${autocompleteClasses.option}`]: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent'
        },
        [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
          backgroundColor: theme.palette.action.hover
        }
      }
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
      position: 'relative'
    }
  },
  adornment: {
    '&.MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      margin: 0,
      color: theme.palette.text.secondary
    }
  }
}));

const useAutoCompleteStyles = makeStyles(theme => ({
  root: {
    padding: 10
  },
  input: {
    '& input': {
      fontSize: 14
    }
  },
  inputRoot: {
    height: 40,
    paddingTop: '0px !important',
    backgroundColor: `rgba(0, 0, 0, 0.09)`
  }
}));

const PopperComponent = props => {
  const { disablePortal, anchorEl, open, style, ...other } = props;
  const classes = useStyles();

  return <div {...other} className={classes.autoCompletePopper} />;
};

const addResourceString = (
  <FormattedMessage id="taskResourceAssignments.addResource" />
);
const allocatedResourcesString = (
  <FormattedMessage id="taskResourceAssignments.allocatedResources" />
);
const timesheetAccessString = (
  <FormattedMessage id="taskResourceAssignments.timesheetAccess" />
);

export const ResourceDropDown = ({
  taskId,
  projectSlug,
  assignedRole,
  disabled = false,
  isUpdating = false,
  onResourceAdd,
  selectedUserIds = []
}) => {
  const ref = useRef();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();
  const autoCompleteClasses = useAutoCompleteStyles();
  const { loading, users, hasMore } = usePageOfProjectResources({
    projectSlug,
    searchTerm
  });

  const { loading: filterLoading, userIds } = useFilteredUsersAssignedToTask({
    taskId,
    userIds: users.map(u => u.id),
    skip: loading || !taskId
  });

  const handleClick = useCallback(e => setAnchorEl(e.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOnChange = useCallback(
    (user, e) => {
      onResourceAdd && onResourceAdd(user);
      handleClose();
      setSearchTerm('');
    },
    [onResourceAdd, handleClose]
  );

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'resource-dropdown' : undefined;

  const optionString = useCallback(option => option?.displayText || '', []);

  const groupBy = useCallback(
    user =>
      user.hasAssignedRole ? (
        `${assignedRole.displayText} ${intl.formatMessage({
          id: 'addResource.resources'
        })}`
      ) : user.id === MORE_AVAILABLE_OPTION_ID ? (
        <></>
      ) : user.isUnusedTeamMember ? (
        timesheetAccessString
      ) : (
        allocatedResourcesString
      ),
    [assignedRole, intl]
  );

  const disabledOption = useCallback(
    option =>
      taskId
        ? userIds.includes(option.id) || option.id === MORE_AVAILABLE_OPTION_ID
        : selectedUserIds.includes(option.id) ||
          option.id === MORE_AVAILABLE_OPTION_ID,
    [taskId, userIds, selectedUserIds]
  );

  const placeholder = intl.formatMessage({
    id: 'taskResourceAssignments.search'
  });
  const inputField = useCallback(
    params => (
      <AutocompleteInput
        {...params}
        autoFocus
        fullWidth
        hiddenLabel
        variant="filled"
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start" className={classes.adornment}>
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    ),
    [classes.adornment, placeholder]
  );

  const optionComponent = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <UserWithRoles user={option} />
      ),
    []
  );

  const handleInputChange = useCallback((_, v) => setSearchTerm(v.trim()), [
    setSearchTerm
  ]);

  const options = assignedRole
    ? users
        .map(u => ({
          ...u,
          hasAssignedRole: u.projectRoles?.find(
            r => r.projectRole?.uri === assignedRole.id
          )
            ? 1
            : 0
        }))
        .sort((a, b) => b.hasAssignedRole - a.hasAssignedRole)
    : users;

  const shouldAddMoreAvailableOption =
    !loading && hasMore && options && options.length;

  const optionTypeText = intl.formatMessage({
    id: 'moreOptions.resources'
  });

  shouldAddMoreAvailableOption &&
    options.push({
      id: 'searchAutocomplete.moreAvailable',
      displayText: intl.formatMessage(
        {
          id: MORE_AVAILABLE_OPTION_ID
        },
        {
          option: optionTypeText
        }
      )
    });

  return (
    <>
      <LoadingButton
        color="primary"
        variant="text"
        disableRipple
        aria-describedby={id}
        onClick={handleClick}
        disabled={disabled || isUpdating}
        isLoading={isUpdating}
        dataQeId="AddResource"
      >
        {addResourceString}
      </LoadingButton>
      <Popper
        className={classes.popper}
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <SimpleAutocomplete
              ref={ref}
              open={isOpen}
              classes={autoCompleteClasses}
              loading={loading || filterLoading}
              size="small"
              fullWidth
              options={options}
              variant="standard"
              dataQeId="ResourceDropDown"
              noOptionsText={intl.formatMessage({
                id: 'taskResourceAssignments.noOptions'
              })}
              groupBy={groupBy}
              hasMore={hasMore}
              renderInput={inputField}
              onChange={handleOnChange}
              getOptionLabel={optionString}
              renderOption={optionComponent}
              onInputChange={handleInputChange}
              getOptionDisabled={disabledOption}
              PopperComponent={PopperComponent}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

PopperComponent.propTypes = {
  style: PropTypes.object,
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired
};

ResourceDropDown.propTypes = {
  isUpdating: PropTypes.bool,
  onResourceAdd: PropTypes.func,
  assignedRole: PropTypes.object,
  taskId: PropTypes.string,
  disabled: PropTypes.bool,
  selectedUserIds: PropTypes.array,
  projectSlug: PropTypes.string.isRequired
};

export default ResourceDropDown;
