import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import { BillPlanPreRequisites } from '~/modules/projects/project/BillPlanV2/BillPlanPreRequisites';
import { useMeContext } from '~/modules/me/useMeContext';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { BatchStatus } from '~/modules/common/components';
import {
  readOnlyParameters,
  useProjectBillPlans,
  usePutProjectBillPlans,
  useRecalculateProjectsBillingContractClauses,
  useBillPlanState,
  usePutProjectBillingContract,
  useDefaultBillingContract
} from './hooks';
import { editors } from './editors';
import { formatters } from './formatters';
import ContractAmount from './components/ContractAmount';
import ExpenseScriptParamsEditableCard from './ExpenseScriptParamsEditableCard';

export const TimeAndExpenseData = ({
  editable,
  projectId,
  hasBillingContractAssignment,
  canRecalculateBillingData,
  isProjectCurrencyChanged,
  onSetBillPlanChanged,
  projectSlug,
  clientUri: clientId,
  title,
  viewSummary,
  isBillingContractType,
  ...rest
}) => {
  const me = useMeContext();
  const [saving, setSaving] = useState(false);

  const {
    billPlans: { billPlans },
    dates,
    projectName,
    projectCurrency,
    loading,
    refetchBillPlans
  } = useProjectBillPlans(projectId, !isBillingContractType);

  const { defaultBillingContract } = useDefaultBillingContract();

  const { batchState, setBatchState } = useBillPlanState();

  const { putBillPlans } = usePutProjectBillPlans({
    projectUri: projectId,
    billPlans
  });

  const putProjectBillingContract = usePutProjectBillingContract({
    projectId,
    contractId: defaultBillingContract.contractId
  });

  const {
    recalculateProjectsBillingContractClauses
  } = useRecalculateProjectsBillingContractClauses(projectId);

  const onSave = useCallback(
    async updatedBillPlan => {
      if (!hasBillingContractAssignment && defaultBillingContract.contractId)
        await putProjectBillingContract();

      await putBillPlans({ updatedBillPlan, setBatchState });
      onSetBillPlanChanged();
    },
    [
      putBillPlans,
      setBatchState,
      hasBillingContractAssignment,
      putProjectBillingContract,
      defaultBillingContract.contractId,
      onSetBillPlanChanged
    ]
  );

  const recalculate = useCallback(() => {
    if (canRecalculateBillingData) {
      recalculateProjectsBillingContractClauses(setBatchState);
      onSetBillPlanChanged();
    }
  }, [
    canRecalculateBillingData,
    recalculateProjectsBillingContractClauses,
    setBatchState,
    onSetBillPlanChanged
  ]);

  const onDeleteScript = useCallback(
    planId => () => {
      putBillPlans({
        updatedBillPlan: { id: planId },
        removePlan: true,
        setBatchState
      });
    },
    [putBillPlans, setBatchState]
  );

  const billPlan = (billPlans || []).find(plan => plan.slug === 'expenses');

  if (loading || saving) {
    if (viewSummary) return null;

    return <CardLoading title={title} />;
  }

  return (
    <>
      {batchState.batchInProgress ? (
        <BatchStatus
          batchState={batchState}
          setBatchState={setBatchState}
          onBatchComplete={refetchBillPlans}
        >
          <CardLoading title={title} />
        </BatchStatus>
      ) : (
        <ExpenseScriptParamsEditableCard
          key={billPlan?.id}
          me={me}
          scriptDetails={billPlan}
          HeaderComponent={ContractAmount}
          customEditors={editors}
          customFormatters={formatters}
          editable={editable}
          onSave={onSave}
          onDeleteScript={onDeleteScript}
          recalculate={recalculate}
          readOnlyParameters={readOnlyParameters}
          BillPlanPreRequisites={BillPlanPreRequisites}
          projectSlug={projectSlug}
          projectId={projectId}
          startDate={dates.startDate}
          endDate={dates.endDate}
          projectName={projectName}
          projectCurrency={projectCurrency}
          clientUri={clientId}
          title={title}
          viewSummary={viewSummary}
          canRecalculateBillingData={canRecalculateBillingData}
          isBillingContractType={isBillingContractType}
          setSaving={setSaving}
          {...rest}
        />
      )}
    </>
  );
};

TimeAndExpenseData.propTypes = {
  editable: PropTypes.bool,
  projectId: PropTypes.string,
  hasBillingContractAssignment: PropTypes.bool,
  canRecalculateBillingData: PropTypes.bool,
  isProjectCurrencyChanged: PropTypes.bool,
  onSetBillPlanChanged: PropTypes.func,
  projectSlug: PropTypes.string,
  clientUri: PropTypes.string,
  title: PropTypes.any,
  viewSummary: PropTypes.bool,
  isBillingContractType: PropTypes.bool
};

export default TimeAndExpenseData;
