import { gql } from 'graphql-tag';
import { projectGroupsFragment } from './projectGroupsFragment';

export const UPDATE_PROJECT_GROUPS_MUTATION = gql`
  mutation UpdateProjectGroups($projectInput: ProjectInput!) {
    updateProject2(projectInput: $projectInput) {
      ...ProjectGroups
    }
  }
  ${projectGroupsFragment}
`;
