import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  getDayViewRoundedHours,
  getDayViewRoundedPercentage
} from '~/modules/resourcing/common/util/scheduleUtil';
import { Decimal } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: theme.spacing(0.375)
  }
}));

export const OverlayBlockLabel = ({
  percentage,
  hours,
  showPercentage = true,
  scale
}) => {
  const classes = useStyles();

  return (
    <div className={classes.label}>
      {showPercentage ? (
        <Decimal
          value={getDayViewRoundedPercentage({
            percentage,
            scale
          })}
          suffix="%"
          fixedDecimalScale={false}
          enableFixedFormatDecimalScale
        />
      ) : (
        <Decimal
          value={getDayViewRoundedHours({
            hours,
            scale
          })}
          suffix="h"
          fixedDecimalScale={false}
          enableFixedFormatDecimalScale
        />
      )}
    </div>
  );
};

OverlayBlockLabel.propTypes = {
  percentage: PropTypes.number,
  hours: PropTypes.number,
  showPercentage: PropTypes.bool,
  scale: PropTypes.string
};

export default OverlayBlockLabel;
