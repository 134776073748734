export const INITIAL_STATE = 'INITIAL_STATE';
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';

export default (state, action = {}) => {
  const availability = action.values;

  switch (action.type) {
    case INITIAL_STATE: {
      return {
        ...state,
        availability: action.availability || []
      };
    }

    case UPDATE_AVAILABILITY: {
      return {
        ...state,
        availability
      };
    }

    default:
      return state;
  }
};

export const updateResourceAvailability = availability => ({
  type: UPDATE_AVAILABILITY,
  values: availability
});
