import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { MobileBillingTransactionListItemAmount } from './components';

const useStyles = makeStyles(theme => ({
  footer: {
    background: theme.palette.grey[50],
    margin: theme.spacing(0, -1),
    display: 'flex',
    padding: theme.spacing(0, 1),
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  emptyDiv: {
    margin: 0,
    display: 'flex',
    padding: theme.spacing(1),
    flexGrow: 1,
    flexBasis: '100px',
    flexShrink: 1,
    flexDirection: 'column-reverse'
  }
}));

export const MobileBillingTransactionAmountRow = ({
  balanceAmount,
  balanceWithoutAllocationsAmount
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.footer}>
      {balanceWithoutAllocationsAmount ? (
        <MobileBillingTransactionListItemAmount
          labelResourceKey="billingTransactionList.originalAmount"
          amount={balanceWithoutAllocationsAmount}
        />
      ) : (
        <div className={classes.emptyDiv} />
      )}
      <MobileBillingTransactionListItemAmount
        labelResourceKey="billingTransactionList.balance"
        amount={balanceAmount}
      />
    </Grid>
  );
};

MobileBillingTransactionAmountRow.propTypes = {
  balanceAmount: PropTypes.object,
  balanceWithoutAllocationsAmount: PropTypes.object
};

export default memo(MobileBillingTransactionAmountRow);
