import { useOnTaskClick } from './useOnTaskClick';
import { useOnTaskKeyPress } from './useOnTaskKeyPress';
import { useOnGridHeaderClick } from './useOnGridHeaderClick';
import { useOnBeforeTaskDrag } from './useOnBeforeTaskDrag';
import { useOnAfterTaskDrag } from './useOnAfterTaskDrag';
import { useOnAfterTaskMove } from './useOnAfterTaskMove';
import { useValidateTaskMove } from './useValidateTaskMove';
import { useOnTaskHoverTooltips } from './useOnTaskHoverTooltips';
import { useShowExpandedTasks } from './useShowExpandedTasks';
import { useOnTaskClick2 } from './useOnTaskClick2';
import { useTaskResourceCountUpdate } from './useTaskResourceCountUpdate';

export const useOnEventHandlers = ({
  me,
  intl,
  history,
  editable,
  hasEditTaskPermissions,
  ganttRef,
  setGanttRef,
  setTaskId,
  setAnchorPosition,
  setTaskTimeEntryDateRange,
  setMoveTaskError,
  openMoveTaskErrorDialog,
  openAssignmentPopover,
  openRoleAssignmentPopover,
  openAddTaskDialog,
  moveTask,
  collapseTask,
  expandTask,
  canViewEstimate,
  expandedTasks,
  openRescheduleTaskDialog,
  isRescheduleTaskDialogEnabled,
  setRescheduleTaskState
}) => {
  const onTaskClick = useOnTaskClick({
    me,
    history,
    editable,
    hasEditTaskPermissions,
    setGanttRef,
    setTaskId,
    setAnchorPosition,
    openAssignmentPopover,
    openRoleAssignmentPopover,
    openAddTaskDialog,
    collapseTask,
    expandTask
  });

  const onTaskClick2 = useOnTaskClick2({
    me,
    history,
    hasEditTaskPermissions,
    setGanttRef,
    openAddTaskDialog,
    collapseTask,
    expandTask
  });

  return {
    onTaskClick: me.featureFlags.isPsaRmpTaskAllocation1Enabled
      ? onTaskClick2
      : onTaskClick,
    onTaskKeyPress: useOnTaskKeyPress({
      onTaskClick
    }),
    onGridHeaderClick: useOnGridHeaderClick({
      setGanttRef,
      openAddTaskDialog
    }),
    onBeforeTaskDrag: useOnBeforeTaskDrag,
    onAfterTaskDrag: useOnAfterTaskDrag({
      isRescheduleTaskDialogEnabled,
      openRescheduleTaskDialog,
      setTaskId,
      setTaskTimeEntryDateRange,
      setRescheduleTaskState
    }),

    onAfterTaskMove: useOnAfterTaskMove({ moveTask }),
    validateTaskMove: useValidateTaskMove({
      intl,
      setMoveTaskError,
      openMoveTaskErrorDialog
    }),
    onTaskHoverTooltips: useOnTaskHoverTooltips({
      intl,
      me,
      canViewEstimate
    }),
    showExpandedTasks: useShowExpandedTasks({ expandedTasks }),
    handleTaskResourceCountModification: useTaskResourceCountUpdate({
      gantt: ganttRef
    })
  };
};

export default useOnEventHandlers;
