import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import { BillingItemColumnOption } from '~/types';
import getFormatters from './formatters';

export const buildColumns = ({
  classes,
  resourceKeys,
  editable,
  onLineItemDelete,
  onAmountChange,
  onAmountBlur,
  selectedColumns,
  isLineItemUpdating,
  isLastLineItem,
  onRemove,
  validateLineItemPermission,
  onClose,
  hasAdhocLineItems,
  isPsaFPOvertimeBillingEnabled
}) => {
  const columns = {
    deleteIcon: {
      visible: editable,
      className: classes.deleteIcon,
      sortIndex: -1,
      value: '',
      resourceKeys,
      onLineItemDelete,
      isLastLineItem,
      onRemove,
      validateLineItemPermission,
      onClose
    },
    project: {
      visible: true,
      sortIndex: 0,
      value: <FormattedMessage id="lineItemsTable.project" />,
      className: classes.project
    },
    billingType: {
      align: 'left',
      visible: selectedColumns.includes(BillingItemColumnOption.Itemtype),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.Itemtype),
      value: <FormattedMessage id="lineItemsTable.billingType" />,
      className: classes.billingType
    },
    task: {
      visible: selectedColumns.includes(BillingItemColumnOption.Task),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.Task),
      value: <FormattedMessage id="lineItemsTable.task" />,
      className: classes.task
    },
    user: {
      visible: selectedColumns.includes(BillingItemColumnOption.User),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.User),
      value: <FormattedMessage id="lineItemsTable.resource" />,
      className: classes.user
    },
    description: {
      visible: selectedColumns.includes(BillingItemColumnOption.Description),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.Description),
      value: <FormattedMessage id="lineItemsTable.description" />,
      className: classes.description
    },
    timesheetPeriod: {
      visible: selectedColumns.includes(
        BillingItemColumnOption.TimesheetPeriod
      ),
      sortIndex: selectedColumns.indexOf(
        BillingItemColumnOption.TimesheetPeriod
      ),
      value: <FormattedMessage id="lineItemsTable.timesheetPeriod" />,
      className: classes.billingType
    },
    payCode: {
      visible:
        isPsaFPOvertimeBillingEnabled &&
        selectedColumns.includes(BillingItemColumnOption.Paycode),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.Paycode),
      value: <FormattedMessage id="lineItemsTable.payCode" />,
      className: classes.payCode
    },
    role: {
      visible:
        isPsaFPOvertimeBillingEnabled &&
        selectedColumns.includes(BillingItemColumnOption.Role),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.Role),
      value: <FormattedMessage id="lineItemsTable.role" />,
      className: classes.role
    },
    quantity: {
      visible: selectedColumns.includes(BillingItemColumnOption.Unit),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.Unit),
      value: <FormattedMessage id="lineItemsTable.units" />,
      align: 'right',
      className: classes.quantity
    },
    rate: {
      visible: selectedColumns.includes(BillingItemColumnOption.Rate),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.Rate),
      value: <FormattedMessage id="lineItemsTable.rate" />,
      align: 'right',
      className: classes.rate
    },
    amount: {
      visible: true,
      value: <FormattedMessage id="lineItemsTable.amount" />,
      sortIndex: isPsaFPOvertimeBillingEnabled ? selectedColumns.length + 1 : 7,
      align: 'right',
      className: classes.amount,
      onAmountChange,
      onAmountBlur,
      isLineItemUpdating,
      ariaLabel:
        resourceKeys && resourceKeys.amountAriaLabel
          ? resourceKeys.amountAriaLabel
          : '',
      allowNegative: true,
      isAdhoc: hasAdhocLineItems
    },
    expenseCode: {
      visible: selectedColumns.includes(BillingItemColumnOption.ExpenseCode),
      sortIndex: selectedColumns.indexOf(BillingItemColumnOption.ExpenseCode),
      value: <FormattedMessage id="lineItemsTable.expenseCode" />,
      align: 'left',
      className: classes.expenseCode
    }
  };

  return Object.keys(columns)
    .sort((a, b) => columns[a].sortIndex - columns[b].sortIndex)
    .reduce(
      (retVal, currentVal) => ({
        ...retVal,
        [currentVal]: columns[currentVal]
      }),
      {}
    );
};

export const useListColumns = ({
  classes,
  onAmountChange,
  onAmountBlur,
  editable,
  resourceKeys,
  onLineItemDelete,
  selectedColumns,
  isLineItemUpdating,
  isLastLineItem,
  onRemove,
  onClose,
  validateLineItemPermission,
  hasAdhocLineItems,
  isPsaFPOvertimeBillingEnabled
}) =>
  useColumns({
    columns: buildColumns({
      classes,
      onAmountChange,
      onAmountBlur,
      resourceKeys,
      editable,
      onLineItemDelete,
      selectedColumns,
      isLineItemUpdating,
      isLastLineItem,
      onRemove,
      onClose,
      validateLineItemPermission,
      hasAdhocLineItems,
      isPsaFPOvertimeBillingEnabled
    }),
    renders: getFormatters(editable)
  });
