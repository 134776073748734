import { makeStyles, Divider } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { Money2 } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  actualAmountField: ({ showActuals }) => ({
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: showActuals ? theme.spacing(16.5) : '100%',
    paddingLeft: theme.spacing(0.5),
    '& input': {
      fontSize: showActuals ? 'small' : 'medium'
    }
  }),
  estimatedAmountField: ({ showActuals }) => ({
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: showActuals ? theme.spacing(16.5) : '100%',
    paddingRight: theme.spacing(0.5),
    '& input': {
      fontSize: showActuals ? 'small' : 'medium'
    }
  }),
  currency: {
    fontSize: theme.typography.caption.fontSize,
    '& div': {
      paddingRight: 'unset !important'
    }
  },
  container: {
    display: 'flex'
  },
  billableAmountDivider: {
    height: 'auto',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
    color: theme.palette.grey[100]
  }
}));

export const ExpenseAmountFooter = ({ field, record }) => {
  const { showActuals, estimated, actuals } = record[field];
  const intl = useIntl();
  const classes = useStyles({ showActuals });

  const amountStyles = useMemo(() => ({ currency: classes.currency }), [
    classes.currency
  ]);

  return (
    <div className={classes.container}>
      <Money2
        className={classes.estimatedAmountField}
        classes={amountStyles}
        variant="standard"
        amount={estimated?.amount}
        currency={estimated?.currency}
        currencyEditable={false}
        editable={false}
        hiddenLabel
        ariaLabel={intl.formatMessage({
          id: 'expenseBillPlan.estimatedBillable'
        })}
      />
      {showActuals ? (
        <>
          <Divider
            className={classes.billableAmountDivider}
            orientation="vertical"
          />
          <div className={classes.container}>
            <Money2
              className={classes.actualAmountField}
              classes={amountStyles}
              variant="standard"
              amount={actuals?.amount}
              currency={actuals?.currency}
              currencyEditable={false}
              editable={false}
              hiddenLabel
              ariaLabel={intl.formatMessage({
                id: 'expenseBillPlan.actualBillable'
              })}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

ExpenseAmountFooter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default ExpenseAmountFooter;
