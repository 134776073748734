import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_PERIOD_SCALE } from '~/modules/common/charts/timeline';
import {
  getScaleWidth,
  totalWidth
} from '~/modules/common/charts/timeline/calculations';

import useStyles from './useStyles';

export const ResourceRequestChartFooterRow = ({
  classes: classesOverrides,
  leftComponent,
  leftComponentWidth,
  totalLabel,
  children,
  totalComponentWidth = totalWidth,
  scale = DEFAULT_PERIOD_SCALE,
  scaleItemCount
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const rangeWidth = scaleItemCount * getScaleWidth(scale);
  const containerMinWidth =
    leftComponentWidth + rangeWidth + (children ? totalComponentWidth : 0);

  const minWidthStyle = useMemo(
    () => ({
      minWidth: containerMinWidth
    }),
    [containerMinWidth]
  );

  const leftContainerStyle = useMemo(
    () => ({
      ...{
        flexBasis: leftComponentWidth,
        width: leftComponentWidth
      }
    }),
    [leftComponentWidth]
  );

  return (
    <div className={classes.root} style={minWidthStyle}>
      <div className={classes.leftContainer} style={leftContainerStyle}>
        {leftComponent}
      </div>
      {children && (
        <div className={classes.rightContainer}>
          <div className={classes.totalLabel}>
            <FormattedMessage id={totalLabel} />
          </div>
          <div className={classes.totalItem}>{children}</div>
        </div>
      )}
    </div>
  );
};

ResourceRequestChartFooterRow.propTypes = {
  classes: PropTypes.object,
  scale: PropTypes.string.isRequired,
  scaleItemCount: PropTypes.number.isRequired,
  leftComponent: PropTypes.node,
  leftComponentWidth: PropTypes.number,
  totalLabel: PropTypes.string,
  children: PropTypes.node,
  totalComponentWidth: PropTypes.number
};

export default ResourceRequestChartFooterRow;
