import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PeriodDropdown from '~/modules/common/components/PeriodDropdown';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import ChartDateRangePeriod from './ChartDateRangePeriod';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  avatar: {
    margin: theme.spacing(0.5, 2, 0, 0)
  },
  action: {
    margin: theme.spacing(0, 0, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3)
  },
  headerTitle: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  },
  headerIcon: {
    verticalAlign: 'bottom'
  },
  title: {
    flexShrink: 0,
    margin: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.subtitle1,
      display: 'flex',
      flexShrink: 1
    }
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  dateSelector: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  previous: {
    margin: theme.spacing(-1.5, -0.75, -1.5, -1.5),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(-2)
    }
  },
  next: {
    margin: theme.spacing(-1.5, 0, -1.5, -0.75),
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(-0.5)
  }
}));

export const ChartHeader = ({
  classes: classesOverride,
  title,
  titleId = '',
  avatar,
  showPeriodFilter = false,
  dateRange,
  onPrevButtonClick,
  onNextButtonClick,
  setDateRange,
  scale,
  action,
  onScaleChange,
  showScaleSelector = false,
  showEntireProjectScaleOption = false,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });

  const cssClasses = useMemo(
    () => ({
      root: classes.root,
      avatar: classes.avatar,
      action: classes.action,
      content: classes.content,
      title: classes.headerTitle
    }),
    [
      classes.action,
      classes.avatar,
      classes.content,
      classes.root,
      classes.headerTitle
    ]
  );

  const titleNode = useMemo(
    () => (
      <div className={classes.header}>
        <span
          data-qe-id={dataQeId && `${dataQeId}_Title`}
          className={classes.title}
          id={titleId}
        >
          {title}
        </span>
        {showPeriodFilter && (
          <ChartDateRangePeriod
            onPreviousClick={onPrevButtonClick}
            onNextClick={onNextButtonClick}
            scale={scale}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
      </div>
    ),
    [
      classes,
      titleId,
      dateRange,
      onNextButtonClick,
      onPrevButtonClick,
      scale,
      setDateRange,
      showPeriodFilter,
      title
    ]
  );

  const headerAction = useMemo(
    () =>
      showScaleSelector ? (
        <PeriodDropdown
          value={scale}
          onChange={onScaleChange}
          showEntireProject={showEntireProjectScaleOption}
        />
      ) : null,
    [showScaleSelector, scale, onScaleChange, showEntireProjectScaleOption]
  );

  return (
    <CardHeader
      avatar={avatar}
      action={action || headerAction}
      title={titleNode}
      className={classes.cardHeader}
      classes={cssClasses}
    />
  );
};

ChartHeader.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  avatar: PropTypes.object.isRequired,
  dateRange: PropTypes.object,
  onPrevButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  showPeriodFilter: PropTypes.bool,
  titleId: PropTypes.string,
  showScaleSelector: PropTypes.bool,
  showEntireProjectScaleOption: PropTypes.bool,
  onScaleChange: PropTypes.func,
  setDateRange: PropTypes.func,
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.YEARS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.WEEKS,
    PERIOD_SCALE_ENUM.ENTIRE_PROJECT
  ]),
  action: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dataQeId: PropTypes.string
};

export default ChartHeader;
