import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, CircularProgress } from '@material-ui/core';

const PaperComponent = () => (
  <CircularProgress data-qe-id="paperComponentLoading" size={50} />
);

export const OverlayLoading = ({ classes }) => (
  <Dialog classes={classes} open PaperComponent={PaperComponent}>
    <div />
  </Dialog>
);

OverlayLoading.propTypes = {
  classes: PropTypes.object
};

export default OverlayLoading;
