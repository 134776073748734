import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import {
  DoneSharp as DoneIcon,
  EditSharp as EditIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    flexShrink: 0,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: theme.shadows[4]
    },
    alignSelf: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 20
  }
}));

const ToggleEditButton = ({ edit, onClick, dataQeId }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      variant="contained"
      data-qe-id={
        dataQeId && edit
          ? `${dataQeId}_DoneEditButton`
          : `${dataQeId}_EditButton`
      }
    >
      {edit ? (
        <>
          <DoneIcon className={classes.icon} />
          <FormattedMessage id="button.doneEditing" />
        </>
      ) : (
        <>
          <EditIcon className={classes.icon} />
          <FormattedMessage id="button.edit" />
        </>
      )}
    </Button>
  );
};

ToggleEditButton.propTypes = {
  edit: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  dataQeId: PropTypes.string
};

export default ToggleEditButton;
