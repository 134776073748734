import React from 'react';
import { FormattedMessage } from 'react-intl';
import { array, object, string } from 'yup';

const STRING_MAX_LENGTH = 255;

export const urlFormatRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

const exceededMaxLengthMessage = (
  <FormattedMessage
    id="projectBasicInfoCard.contactInfo.validation.fieldExceededMaxLength"
    values={{ max: STRING_MAX_LENGTH }}
  />
);

export const validationSchema = object().shape({
  contacts: array().of(
    object().shape({
      name: string()
        .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
        .nullable(),
      phoneNumber: string()
        .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
        .nullable(),
      faxNumber: string()
        .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
        .nullable(),
      email: string()
        .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
        .email(
          <FormattedMessage id="projectBasicInfoCard.contactInfo.validation.invalidEmail" />
        )
        .nullable(),
      website: string()
        .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
        .matches(urlFormatRegex, {
          message: (
            <FormattedMessage id="projectBasicInfoCard.contactInfo.validation.invalidUrl" />
          ),
          excludeEmptyString: true
        })
        .nullable()
    })
  )
});
