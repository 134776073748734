import { useState, useCallback, useEffect } from 'react';

export const useOnBillingItemRowSelect = ({
  billingItems: rows,
  setSelectedBillingItems
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(Boolean(rows.length));

  if (rows.length && !selectedRows.length && selectAll)
    setSelectedRows(rows.map(item => item.id));

  const getBillingItemUris = billingItemRows =>
    billingItemRows.length
      ? billingItemRows
          .map(x => x.billingItemUris)
          .reduce((selection, currentItem) => [...selection, ...currentItem])
      : [];

  useEffect(() => {
    const billingItems = {
      any: false,
      includedUris: [],
      excludedUris: []
    };

    if (!selectAll) {
      const selectedBillingItems = rows.filter(row =>
        selectedRows.includes(row.id)
      );

      billingItems.includedUris = getBillingItemUris(selectedBillingItems);
    } else {
      const unSelectedBillingItems = rows.filter(
        row => !selectedRows.includes(row.id)
      );

      billingItems.excludedUris = getBillingItemUris(unSelectedBillingItems);
    }

    billingItems.any = Boolean(
      selectAll ||
        billingItems.includedUris.length ||
        billingItems.excludedUris.length
    );
    setSelectedBillingItems(billingItems);
  }, [rows, selectAll, selectedRows, setSelectedBillingItems]);

  return {
    onRowSelect: useCallback(
      ({
        record,
        event: {
          target: { checked }
        }
      }) => {
        const modifiedSelectedRows = checked
          ? [...selectedRows, record.id]
          : selectedRows.filter(selectedId => record.id !== selectedId);

        setSelectedRows(modifiedSelectedRows);
        if (!checked) setSelectAll(false);
        else
          setSelectAll(
            rows &&
              modifiedSelectedRows &&
              modifiedSelectedRows.length === rows.length
          );
      },
      [rows, selectedRows]
    ),
    onSelectAll: useCallback(
      checked => {
        setSelectAll(checked);
        setSelectedRows(checked ? rows.map(item => item.id) : []);
      },
      [rows]
    ),
    selectedRows,
    selectAll
  };
};

export default useOnBillingItemRowSelect;
