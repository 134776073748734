import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

export const ExpenseType = ({ field, record, column: { expenseCodes } }) => {
  const expenseCode = record[field];

  const displayText = useMemo(() => {
    if (expenseCode && typeof expenseCode === 'object')
      return expenseCode.displayText;

    return (expenseCodes || []).find(e => e.id === expenseCode)?.displayText;
  }, [expenseCode, expenseCodes]);

  return <Typography variant="body2">{displayText}</Typography>;
};

ExpenseType.propTypes = {
  record: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default ExpenseType;
