import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { NoValue } from '~/modules/common/components';

export const QuantityFormatter = ({ field, record }) => {
  const value =
    record[field] && record.unitLabel
      ? `${record[field]} ${record.unitLabel}`
      : record[field]
      ? record[field]
      : null;

  return value ? <Typography variant="body2">{value}</Typography> : <NoValue />;
};

QuantityFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default QuantityFormatter;
