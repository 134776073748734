import { useState, useCallback } from 'react';
import { useProjectResources } from '~/modules/common/hooks';

const useTaskOwnerSearch = ({ projectSlug }) => {
  const [searchText, setSearchText] = useState('');
  const {
    loading,
    resources: { items }
  } = useProjectResources({
    projectSlug
  });

  const updateSearchText = useCallback(
    phrase => {
      setSearchText(phrase);
    },
    [setSearchText]
  );

  return {
    searchText,
    updateSearchText,
    loading,
    users:
      items && items.length > 0
        ? items && items.length > 0
          ? mapResult({ items })
          : []
        : []
  };
};

const mapResult = ({ items }) => {
  const usersWithRoles = [];

  items.forEach(user =>
    (user.projectRoles || []).map(projectRole =>
      projectRole
        ? usersWithRoles.push({
            ...user,
            projectRoles: [projectRole],
            key: `${user.id}-${projectRole.projectRole.uri}`
          })
        : usersWithRoles.push({
            ...user,
            projectRoles: [],
            key: `${user.id}-no-role`
          })
    )
  );

  return usersWithRoles;
};

export default useTaskOwnerSearch;
