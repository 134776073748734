import UnhandledErrorPage from './UnhandledErrorPage';

const unhandledErrorRoute = ({ intl }) => ({
  title: () => intl.formatMessage({ id: 'routes.unhandledError' }),
  hasPermission: true,
  component: UnhandledErrorPage,
  path: '/unhandled-error',
  hidden: true
});

export default unhandledErrorRoute;
