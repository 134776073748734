import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const PUT_SETTINGS = gql`
  mutation updateRevenueRunBatchUri(
    $revenueRunBatchInput: RevenueRunBatchInput!
  ) {
    updateRevenueRunBatchUri(revenueRunBatchInput: $revenueRunBatchInput)
  }
`;

export const usePutSettings = revenueRunUri => {
  const [updateRevenueRunBatchUri] = useMutation(PUT_SETTINGS);

  return {
    updateRevenueRunBatchUri: batchUri => {
      return updateRevenueRunBatchUri({
        variables: {
          revenueRunBatchInput: {
            batchUri,
            revenueRunUri
          }
        }
      });
    }
  };
};

export default usePutSettings;
