import { useSearchableServiceCenters } from '~/modules/common/enhancers';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { serviceCenterTag } from './tag';
import ServiceCenterFacetDetails from './ServiceCenterFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableServiceCenters);

export default {
  tag: serviceCenterTag,
  makeCriteriaProvider,
  DetailsControl: ServiceCenterFacetDetails
};
