import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const getTagsQuery = gql`
  query getTags {
    tags {
      id
      name
      values {
        id
        value
      }
    }
  }
`;

export const useAllTags = () => {
  const { loading, error, data: { tags = [] } = {} } = useQuery(getTagsQuery);

  return {
    isTagLoading: loading,
    tags,
    error
  };
};

export default useAllTags;
