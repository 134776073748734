import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { ListItemText, makeStyles } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CreatableAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import useTagValueOptions from './hooks/useTagValueOptions';

const filter = createFilterOptions();

const useListItemStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const TagsEditValueFormDropdown = ({
  rowIndex,
  value,
  errors,
  onSelectTagValue,
  onCreateTagValue,
  isDisabled,
  tag,
  canAddNewOption = true,
  hiddenLabel = true
}) => {
  const [tagValueSearchOption, setTagValueSearchOption] = useState('');

  const listItemClasses = useListItemStyles();
  const { data, loading } = useTagValueOptions({
    tagId: tag?.tagId ?? '',
    valueSearch: tagValueSearchOption
  });

  const { formatMessage } = useIntl();

  const onListItemClick = useCallback(
    () => option => {
      const optionLabel = option.label.trim();

      return onCreateTagValue(optionLabel);
    },
    [onCreateTagValue]
  );

  const renderValueOption = useCallback(
    option => {
      const { isCreatable } = option;

      return isCreatable ? (
        <>
          <ListItemText
            primary={formatMessage(
              { id: 'tags.createTagValue' },
              { tagName: option.label.trim() }
            )}
            onClick={onListItemClick}
            classes={listItemClasses}
          />
        </>
      ) : (
        <ListItemText primary={option.label} classes={listItemClasses} />
      );
    },
    [onListItemClick, formatMessage, listItemClasses]
  );

  const filterOptions = useCallback(
    (filterOption, params) => {
      const filtered = filter(filterOption, params);

      if (loading) {
        return filtered;
      }

      if (
        canAddNewOption &&
        params.inputValue !== '' &&
        !filterOption.some(o => o.label === params.inputValue)
      ) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          label: params.inputValue
        });
      }

      return filtered;
    },
    [loading, canAddNewOption]
  );

  const handleValueInputChange = useCallback(
    (event, newValue) => setTagValueSearchOption(newValue || ''),
    [setTagValueSearchOption]
  );

  const getValueDisabled = useCallback(
    option =>
      option &&
      value &&
      option.value &&
      value.value &&
      option.value === value.value,
    [value]
  );

  const valueOptions = (!loading && data) || [];

  return (
    <CreatableAutocomplete
      disabled={isDisabled}
      dataQeId={`TagValue_${rowIndex}`}
      value={value}
      fullWidth
      label={formatMessage({ id: 'tags.value' })}
      inputLabel={formatMessage({ id: 'tags.value' })}
      onChange={onSelectTagValue}
      renderOption={renderValueOption}
      filterOptions={filterOptions}
      hiddenLabel={hiddenLabel}
      noOptionsText={formatMessage({
        id: canAddNewOption ? 'tags.noValues' : 'tags.noMatchingValues'
      })}
      options={valueOptions}
      loading={loading}
      optionPropText="label"
      errorMessage={errors}
      showError={!!errors}
      getOptionDisabled={getValueDisabled}
      onInputChange={handleValueInputChange}
      canAddNewOption={canAddNewOption}
    />
  );
};

TagsEditValueFormDropdown.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  value: PropTypes.object,
  errors: PropTypes.string,
  onSelectTagValue: PropTypes.func.isRequired,
  onCreateTagValue: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tag: PropTypes.object.isRequired,
  canAddNewOption: PropTypes.bool,
  hiddenLabel: PropTypes.bool
};

export default TagsEditValueFormDropdown;
