import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useStyles from './useStyles';
import ResourceChartTotalPopperRow from './ResourceChartTotalPopperRow';

export const ResourceChartTotalPopperContent = ({
  requestedHours,
  requestedCost,
  allocatedHours,
  allocatedCost,
  viewCosts,
  viewAllocation,
  viewDifference,
  viewGrandTotal
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-qe-id="ResourceActualSummaryDialog">
      <div
        className={classNames(classes.values, {
          [classes.gridTemplate]: !viewCosts
        })}
      >
        {requestedHours && (
          <ResourceChartTotalPopperRow
            rowName="requestChartRowTotalTooltip.totalRequested"
            hours={requestedHours}
            cost={requestedCost?.amount}
            currencySymbol={requestedCost?.currency?.symbol}
            viewCosts={viewCosts}
            viewDiff={false}
          />
        )}
        {viewAllocation && (
          <ResourceChartTotalPopperRow
            rowName="requestChartRowTotalTooltip.totalAllocated"
            hours={allocatedHours}
            cost={allocatedCost?.amount}
            currencySymbol={allocatedCost?.currency?.symbol}
            viewCosts={viewCosts}
            viewDiff={false}
          />
        )}
        {viewDifference && (
          <ResourceChartTotalPopperRow
            rowName="requestChartRowTotalTooltip.difference"
            hours={requestedHours - allocatedHours}
            cost={(requestedCost?.amount || 0) - (allocatedCost?.amount || 0)}
            currencySymbol={allocatedCost?.currency?.symbol}
            isHoursHigher={allocatedHours > requestedHours}
            isCostHigher={
              (allocatedCost?.amount || 0) > (requestedCost?.amount || 0)
            }
            isTotalRow
            viewCosts={viewCosts}
            viewDiff
          />
        )}
        {viewGrandTotal && (
          <ResourceChartTotalPopperRow
            rowName="requestChartRowTotalTooltip.grandTotal"
            hours={requestedHours + allocatedHours}
            cost={(requestedCost?.amount || 0) + (allocatedCost?.amount || 0)}
            currencySymbol={allocatedCost?.currency?.symbol}
            isTotalRow
            viewCosts={viewCosts}
            viewDiff={false}
          />
        )}
      </div>
    </div>
  );
};

ResourceChartTotalPopperContent.propTypes = {
  requestedHours: PropTypes.number,
  allocatedHours: PropTypes.number,
  requestedCost: PropTypes.object,
  allocatedCost: PropTypes.object,
  viewCosts: PropTypes.bool.isRequired,
  viewAllocation: PropTypes.bool.isRequired,
  viewDifference: PropTypes.bool.isRequired,
  viewGrandTotal: PropTypes.bool
};

export default ResourceChartTotalPopperContent;
