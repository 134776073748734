import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

export const resolveDateTime = dateTime =>
  dateTime.toLocaleString({
    month: 'short',
    year: 'numeric'
  });

export const isFirstDateGreater = (date1, date2) => {
  if (!(date1 && date2)) {
    return false;
  }
  const d1 = new Date(date1).toISOString();
  const d2 = new Date(date2).toISOString();

  if (d1 > d2) return true;

  return false;
};

export const useMonthRangeFacet = ({ setSelected }) => ({
  onMonthChange: ({ startDate, endDate }) => {
    const startMonthValue = startDate
      ? resolveDateTime(mapIsoStringtoUtcObject(startDate))
      : '';
    const endMonthValue = endDate
      ? resolveDateTime(mapIsoStringtoUtcObject(endDate))
      : '';

    setSelected(
      startDate || endDate
        ? [
            {
              dateRange: {
                startDate,
                endDate
              },
              key: `${startMonthValue} - ${endMonthValue}`
            }
          ]
        : []
    );
  }
});

export default useMonthRangeFacet;
