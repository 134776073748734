import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Date } from '~/modules/common/components/ListTable/renderers';
import GroupColumn from '~/modules/common/components/Groups/GroupColumn';
import avatar from './avatar';
import supervisor from './supervisor';
import Role from './Role';
import TagField from './TagField';
import ResourcePool from './ResourcePool';
import CustomFieldColumn from './CustomField';

const StringRender = ({ field, record }) => (
  <Typography variant="body2">{record[field]}</Typography>
);

StringRender.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default {
  displayText: avatar,
  employeeId: StringRender,
  startDate: Date,
  endDate: Date,
  department: StringRender,
  employeeType: StringRender,
  supervisorAssignment: supervisor,
  currentDepartment: GroupColumn,
  currentEmployeeType: GroupColumn,
  currentLocation: GroupColumn,
  currentDivision: GroupColumn,
  currentServiceCenter: GroupColumn,
  currentCostCenter: GroupColumn,
  roles: Role,
  tagValue: TagField,
  allResourcePools: ResourcePool,
  customFields: CustomFieldColumn
};
