import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'formik';
import GridItem from '~/modules/common/components/GridItem';
import {
  getNameAndOnChangeHandlerProps,
  getErrorAndHelperTextProps
} from '../utils';
import CustomField from './CustomField';

const isEven = num => num % 2 === 0;

export const EditCustomFieldDialogForm = ({
  formik: { handleChange, values, errors, setFieldValue }
}) => {
  const { customFieldValues } = values;

  return (
    <Grid container>
      {Object.entries(customFieldValues)
        .filter(
          ([_, customFieldValue]) =>
            customFieldValue.customFieldDefinition.isEnabled &&
            customFieldValue.customFieldDefinition.isVisible
        )
        .map(([key, customFieldValue], ind) => {
          const { name, onChange } = getNameAndOnChangeHandlerProps({
            handleChange,
            setFieldValue,
            path: 'customFieldValues',
            customFieldValue,
            key
          });

          const { error, helperText } = getErrorAndHelperTextProps({
            errors,
            customFieldValue,
            path: 'customFieldValues'
          });

          const {
            customField: { displayText: label }
          } = customFieldValue;

          return (
            <GridItem key={key} rightPadding={isEven(ind)}>
              <CustomField
                variant="filled"
                fullWidth
                editable
                customFieldValue={customFieldValue}
                name={name}
                aria-label={label}
                onChange={onChange}
                error={error}
                helperText={helperText}
              />
            </GridItem>
          );
        })}
    </Grid>
  );
};

EditCustomFieldDialogForm.propTypes = {
  formik: PropTypes.object
};

export default connect(EditCustomFieldDialogForm);
