import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { CardContent, makeStyles } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import BubbleChartIcon from '@material-ui/icons/BubbleChartSharp';
import TableChartIcon from '@material-ui/icons/TableChartSharp';
import { SkillIcon } from '~/modules/common/components/Icons';
import { useResourceManagementContext } from '~/modules/resource-management/common';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';
import {
  DEFAULT_SKILLS_DISPLAY_STYLE,
  SKILLS_DISPLAY_STYLE_ENUM
} from '~/modules/resource-management/common/enums';
import { useSessionStorage } from '~/modules/common/hooks';
import ResourcingCard from '../ResourcingCard';

import SkillsDistributionTableContent from './SkillsDistributionTableContent';
import SkillsDistributionChartContent from './SkillsDistributionChartContent';
import { useSkillsDistributionForUsers } from './hooks';

const title = <FormattedMessage id="resourcingOverview.skills" />;
const avatar = <SkillIcon />;

const useCardStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0)
    },
    overflow: 'unset'
  }
}));

const useContentStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

const useToggleButtonGroupStyles = makeStyles(theme => ({
  group: {
    margin: theme.spacing(-1, 0)
  }
}));

const useToggleButtonstyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(4),
    padding: theme.spacing(1, 1.5),
    lineHeight: 1
  },
  selected: {}
}));

const getSkillAction = ({
  view,
  handleViewChange,
  toggleButtonClasses,
  toggleGroupClasses
}) => (
  <ToggleButtonGroup
    exclusive
    value={view}
    onChange={handleViewChange}
    className={toggleGroupClasses.group}
  >
    <ToggleButton
      classes={toggleButtonClasses}
      value={SKILLS_DISPLAY_STYLE_ENUM.TABLE}
      aria-label="Table View"
    >
      <TableChartIcon />
    </ToggleButton>
    <ToggleButton
      classes={toggleButtonClasses}
      value={SKILLS_DISPLAY_STYLE_ENUM.CHART}
      aria-label="Chart View"
    >
      <BubbleChartIcon />
    </ToggleButton>
  </ToggleButtonGroup>
);

export const SkillsDistributionCard = () => {
  const contentClasses = useContentStyles();
  const cardClasses = useCardStyles();
  const toggleGroupClasses = useToggleButtonGroupStyles();
  const toggleButtonClasses = useToggleButtonstyles();
  const { searchCriteria } = useResourceManagementContext();
  const { filter } = useFilterBasedOnSearchCriteria({ searchCriteria });
  const {
    loading,
    data: skills,
    loadMore,
    hasMore
  } = useSkillsDistributionForUsers({ filter });

  const { storedValue: view, setValue: setView } = useSessionStorage(
    'skill-distribution-view-style',
    DEFAULT_SKILLS_DISPLAY_STYLE
  );

  const handleViewChange = useCallback(
    (_, selected) => {
      setView(selected);
    },
    [setView]
  );

  return (
    <ResourcingCard
      className={cardClasses.root}
      title={title}
      avatar={avatar}
      action={getSkillAction({
        view,
        handleViewChange,
        toggleButtonClasses,
        toggleGroupClasses
      })}
    >
      <CardContent classes={contentClasses}>
        {view === SKILLS_DISPLAY_STYLE_ENUM.CHART ? (
          <SkillsDistributionChartContent
            skills={skills}
            skillsLoading={loading}
          />
        ) : (
          <SkillsDistributionTableContent
            skills={skills}
            loadMore={loadMore}
            hasMore={hasMore}
            skillsLoading={loading}
          />
        )}
      </CardContent>
    </ResourcingCard>
  );
};

export default SkillsDistributionCard;
