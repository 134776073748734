import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const projectRequestsCountSummaryQuery = gql`
  query Eager_GetProjectRequestsCountSummary(
    $projectRequestFilter: ProjectRequestFilter
  ) {
    projectRequestsByStatusTypeCountSummary(filter: $projectRequestFilter) {
      all
      new
      inReview
      approved
      rejected
    }
  }
`;

const useProjectRequestsCountSummaryQuery = ({ projectRequestFilter }) => {
  const { data, loading } = useQuery(projectRequestsCountSummaryQuery, {
    variables: {
      projectRequestFilter
    },
    fetchPolicy: 'network-only'
  });

  const summaryData = get(data, 'projectRequestsByStatusTypeCountSummary', {});

  return {
    loading,
    projectRequestsCountSummary: {
      all: summaryData.all || 0,
      new: summaryData.new || 0,
      inReview: summaryData.inReview || 0,
      approved: summaryData.approved || 0,
      rejected: summaryData.rejected || 0
    }
  };
};

export default useProjectRequestsCountSummaryQuery;
