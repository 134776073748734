import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  GridList,
  Radio,
  Typography,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.secondary,
    ...theme.typography.subtitle1
  },
  listItem: {
    marginLeft: 0,
    marginRight: 0
  },
  listGrid: {
    '& ul:first-child': {
      paddingLeft: theme.spacing(1.2)
    }
  }
}));

const SettingLists = ({
  className,
  label,
  options,
  values,
  setValues,
  multiselect = true,
  disabled = false,
  xs = 4
}) => {
  const classes = useStyles();
  const toggle = useCallback(
    (key, isDisabled) => () => {
      if (isDisabled) {
        return;
      }

      if (!multiselect && !values.includes(key)) {
        setValues([key]);

        return;
      }

      if (values.includes(key)) {
        setValues(values.filter(val => val !== key));
      } else {
        setValues([...values, key]);
      }
    },
    [multiselect, values, setValues]
  );

  const FormComponent = multiselect ? Checkbox : Radio;

  return (
    <Grid
      container
      alignContent="flex-start"
      item
      xs={xs}
      className={className}
    >
      <Grid item xs={12}>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
      <Grid className={classes.listGrid} item xs={12}>
        <GridList cellHeight="auto" component="div">
          {options.map(({ key, label: optionLabel }, index) => {
            const labelId = `checkbox-list-label-${key}`;
            const inputProps = { 'aria-label': labelId };
            const checked = values.includes(key);
            const isDisabled =
              disabled || (multiselect && values.length === 1 && checked);

            return (
              <FormControlLabel
                className={classes.listItem}
                label={optionLabel}
                key={key}
                control={
                  <FormComponent
                    edge="start"
                    checked={checked}
                    disabled={isDisabled}
                    tabIndex={0}
                    inputProps={inputProps}
                    color="primary"
                    onClick={toggle(key, isDisabled)}
                  />
                }
              />
            );
          })}
        </GridList>
      </Grid>
    </Grid>
  );
};

SettingLists.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  values: PropTypes.array,
  setValues: PropTypes.func,
  multiselect: PropTypes.bool,
  disabled: PropTypes.bool,
  xs: PropTypes.number
};

export default SettingLists;
