import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { BillStatus } from '~/types';
import {
  mapToServiceFilter,
  getConsolidatedTypeFilter
} from './useOutstandingBillTabSummary';

export const voidedBillsQuery = gql`
  query getVoidedBillCounts($filter: BillingTransactionFilter2) {
    billingTransactionRowCounts: billingTransactionRowCounts2(filter: $filter)
  }
`;
const summaryType = ['BILL'];

export const useVoidedBillTabSummary = (
  billingTransactionFilter,
  contextFilter
) => {
  const consolidatedTypeFilter =
    billingTransactionFilter.transactionTypes &&
    billingTransactionFilter.transactionTypes.length > 0
      ? getConsolidatedTypeFilter(
          billingTransactionFilter.transactionTypes,
          summaryType
        )
      : summaryType;
  const { data, loading } = useQuery(voidedBillsQuery, {
    variables: {
      filter: mapToServiceFilter({
        contextFilter,
        billingTransactionFilter,
        consolidatedTypeFilter,
        billStatus: [BillStatus.Void]
      })
    },
    fetchPolicy: 'network-only'
  });

  const voidedBillCount = get(data, 'billingTransactionRowCounts', 0);

  return { loading, voidedBillCount };
};

export default useVoidedBillTabSummary;
