import { PropTypes } from 'prop-types';

export const tagPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string
    })
  )
});

export const valuePropType = PropTypes.shape({
  id: PropTypes.string,
  value: PropTypes.string
});

export const tagAssignmentPropType = PropTypes.shape({
  id: PropTypes.string,
  tag: tagPropType,
  value: valuePropType
});

export const selectedTagPropType = PropTypes.shape({
  tag: tagPropType,
  selected: valuePropType
});
