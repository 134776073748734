import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FocusableSpan } from '~/modules/common/components/FocusableSpan';
import { NoValue } from '~/modules/common/components';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  container: {
    paddingLeft: theme.spacing(0.5),
    cursor: 'pointer',
    margin: theme.spacing(0.75, 0, 0.75, 0),
    flex: '1 1 auto',
    minWidth: 0
  },
  secondary: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  taskName: {
    ...theme.typography.body2,
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    lineHeight: 1.5
  },
  taskRole: {
    lineHeight: 1.43,
    margin: 0
  },
  taskCode: {
    marginLeft: theme.spacing(1),
    fontWeight: 'normal',
    fontSize: theme.typography.body2.fontSize
  }
});

const GanttTaskName2 = ({
  task,
  ariaLabel,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div
        className={classNames('gantt_task_content_wrapper', classes.taskName)}
      >
        <FocusableSpan className="gantt_task_name" ariaLabel={ariaLabel}>
          {task.text}
        </FocusableSpan>
        <span
          className={classNames(
            'gantt_secondry_text',
            classes.secondary,
            classes.taskCode
          )}
        >
          {task.code}
        </span>
      </div>
      {isRolledUpTaskEstimateCalculationMethodEnabled &&
      task.$level === 0 ? null : (
        <p
          className={classNames(
            'gantt_secondary_text',
            classes.secondary,
            classes.taskRole
          )}
        >
          {task?.assignedRole?.displayText || <NoValue />}
        </p>
      )}
    </div>
  );
};

GanttTaskName2.propTypes = {
  task: PropTypes.shape({
    text: PropTypes.string.isRequired,
    code: PropTypes.string,
    assignedRole: PropTypes.shape({
      displayText: PropTypes.string
    }),
    $level: PropTypes.number
  }).isRequired,
  ariaLabel: PropTypes.string,
  isRolledUpTaskEstimateCalculationMethodEnabled: PropTypes.bool
};

export default GanttTaskName2;
