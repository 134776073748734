import { GET_PROJECT_REQUEST_DETAILS } from './useProjectRequest';

export const updateResourceAssignmentsInCache = ({
  projectRequestId,
  resourceAssignments
}) => cache => {
  const storedProjectRequestDetails = cache.readQuery({
    query: GET_PROJECT_REQUEST_DETAILS,
    variables: {
      id: projectRequestId,
      page: 1,
      pageSize: 50
    }
  });

  cache.writeQuery({
    query: GET_PROJECT_REQUEST_DETAILS,
    variables: {
      id: projectRequestId,
      page: 1,
      pageSize: 50
    },
    data: {
      projectRequest: {
        ...storedProjectRequestDetails.projectRequest,
        resourceAssignments
      }
    }
  });
};

export default updateResourceAssignmentsInCache;
