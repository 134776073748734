import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const CAN_DELETE_PROJECTS_QUERY = gql`
  query CanDeleteProjects($projectIds: [String!]!) {
    canDeleteProjects(projectIds: $projectIds) {
      archivedUris
      deletedUris
      errors {
        displayText
        project {
          id
          name
        }
      }
    }
  }
`;

export const useCanDeleteProjects = ({ objectIds: projectIds }) => {
  const { loading, error, data } = useQuery(CAN_DELETE_PROJECTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectIds
    },
    errorPolicy: 'all'
  });

  return {
    loading,
    canDeleteUris: data?.canDeleteProjects?.deletedUris || [],
    canArchiveUris: data?.canDeleteProjects?.archivedUris || [],
    errors: data?.canDeleteProjects?.errors || [],
    error
  };
};

export default useCanDeleteProjects;
