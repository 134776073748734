import { makeStyles, Card, CardContent } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';
import CreateBillWithLineItemsDialog from '~/modules/billing-invoicing/billingItem/CreateBillWithLineItemsDialog';
import MobileAvailableToBillListItem from '~/modules/billing-invoicing/common/components/MobileAvailableToBillListItem';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { useDialogState } from '~/modules/common/hooks';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';
import { useMeContext } from '~/modules/me/useMeContext';
import { useNextReferenceNumberSetting } from '~/modules/billing-invoicing/common/hooks';
import { AvailableToBillListTableContextProvider } from './AvailableToBillListTableContextProvider';
import { useListColumns, useListFooters } from './columns';
import { useAvailableToBillTotals, useAvailableToBillRows } from './hooks';
import {
  BulkActionsForBill,
  BulkBillProgressAndConfirmationDialog
} from './components';
import { useBulkCreateBill } from './hooks/useBulkCreateBill';
import { useAvailableToBillSelectRows } from './hooks/useAvailableToBillSelectRows';
import {
  useListTableThemeProviderStyles,
  useColumnStyles
} from './hooks/useAvailableToBillListTableStyles';
import { useBulkBillProgressAndConfirmationDialogActions } from './hooks/useBulkBillProgressAndConfirmationDialogActions';

const useNoDataStyles = makeStyles(theme => ({
  card: {
    paddingTop: theme.spacing(2) + 4,
    color: theme.palette.grey[600],
    boxShadow: 'unset'
  }
}));

export const AvailableToBillListTable = ({
  hover = true,
  projectUri,
  projectName,
  editable,
  pageSize,
  client,
  programId,
  availableToBillFilter,
  refetchAvailableToBillTotal,
  headerLevel,
  setSelectedTab
}) => {
  const [billingBatchState, setBillingBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpManualBulkBillCreationEnabled }
  } = me;

  const columnClasses = useColumnStyles();
  const noDataClasses = useNoDataStyles();

  const {
    open: bulkBillProgressAndConfirmationOpen,
    openDialog: openBulkBillProgressAndConfirmationDialog,
    closeDialog: closeBulkBillProgressAndConfirmationDialog
  } = useDialogState(false);

  const {
    availableToBillRows,
    hasMoreRows,
    loadMoreRows,
    isRowsLoading,
    isLoadingMore,
    refetchAvailableToBillRows
  } = useAvailableToBillRows({
    projectUri,
    client,
    programId,
    availableToBillFilter
  });

  const {
    totals,
    isTotalsLoading,
    refetchFilteredTotals
  } = useAvailableToBillTotals({
    projectUri,
    client,
    programId,
    availableToBillFilter
  });

  const { nextReferenceNumberSetting } = useNextReferenceNumberSetting({
    transactionType: billingTransactionType.BILL,
    skip: !isPsaPrpManualBulkBillCreationEnabled
  });

  const isLoading = isTotalsLoading || isRowsLoading;

  const enhancedFilteredRecords = availableToBillRows
    .filter(x => x.amount.amount !== 0)
    .map(x => ({
      ...x,
      editable,
      projectContext: { projectUri, projectName }
    }));

  const {
    selectedAvailableToBillItems,
    resetSelectedAvailableToBillItems,
    selectedBillCount,
    onSelectClick,
    isRowSelected,
    onSelectAllClick,
    selectAllStatus,
    hasAtleastOneSelectableAvailableToBillRow
  } = useAvailableToBillSelectRows({
    availableToBillFilter,
    editable,
    availableToBillRows: enhancedFilteredRecords,
    isPsaPrpManualBulkBillCreationEnabled
  });

  const {
    bulkCreateBill,
    loading: batchCreationInProgress
  } = useBulkCreateBill({
    selectedAvailableToBillItems,
    setBillingBatchState,
    projectUri
  });

  const areHundredBillsSelected = useCallback(
    () => selectedAvailableToBillItems.length >= 100,
    [selectedAvailableToBillItems]
  );

  const columns = useListColumns({
    classes: columnClasses,
    editable,
    availableToBillStates: {
      isRowSelected,
      onSelectClick,
      onSelectAllClick,
      selectAllStatus,
      hasAtleastOneSelectableAvailableToBillRow,
      areHundredBillsSelected
    },
    isPsaPrpManualBulkBillCreationEnabled
  });
  const footers = useListFooters(
    columnClasses,
    editable,
    isPsaPrpManualBulkBillCreationEnabled
  );

  const providerClasses = useListTableThemeProviderStyles({
    editable,
    headerLevel,
    isPsaPrpManualBulkBillCreationEnabled,
    isBulkBillSelected: selectedAvailableToBillItems.length
  });

  const [selectedRow, setSelectedRow] = useState({});
  const { open, openDialog, closeDialog } = useDialogState(false);

  const onRowClickHandler = useCallback(
    record => {
      setSelectedRow(record);
      editable && openDialog();
    },
    [editable, openDialog]
  );

  const onCellClickHandler = useCallback(
    (event, field) => {
      if (field === 'rowSelector') event.stopPropagation();
      else resetSelectedAvailableToBillItems();
    },
    [resetSelectedAvailableToBillItems]
  );

  const {
    createdBills,
    onBatchComplete,
    onConfirmClick,
    onCancelClick
  } = useBulkBillProgressAndConfirmationDialogActions({
    refetchAvailableToBillRows,
    refetchAvailableToBillTotal,
    refetchFilteredTotals,
    resetSelectedAvailableToBillItems,
    billingBatchState,
    setBillingBatchState,
    onCancel: closeBulkBillProgressAndConfirmationDialog,
    setSelectedTab,
    onCreateBill: bulkCreateBill
  });

  //  Explicitly load additional records due to client filtering of amount 0 records
  const currentPageRows = availableToBillRows.slice(
    -(availableToBillRows.length % pageSize || pageSize)
  );

  !currentPageRows.some(x => x.amount.amount !== 0) &&
    !isLoading &&
    !isLoadingMore &&
    hasMoreRows &&
    loadMoreRows &&
    loadMoreRows();

  return (
    <>
      {!availableToBillRows.length && !isLoading ? (
        <Card className={noDataClasses.card}>
          <CardContent>
            <NoDataItem>
              <FormattedMessage id="availableToBillList.noAvailableToBillData" />
            </NoDataItem>
          </CardContent>
        </Card>
      ) : (
        <AvailableToBillListTableContextProvider
          refetchAvailableToBillRows={refetchAvailableToBillRows}
        >
          <ListTableThemeProvider classes={providerClasses}>
            <ListTable
              hasMore={hasMoreRows}
              loadMore={loadMoreRows}
              footers={footers}
              columns={columns}
              totals={totals}
              variant="table"
              records={enhancedFilteredRecords}
              mobileListItem={MobileAvailableToBillListItem}
              hover={hover && editable}
              onRowClick={onRowClickHandler}
              onCellClick={
                isPsaPrpManualBulkBillCreationEnabled && onCellClickHandler
              }
              isLoading={isLoading}
            />
          </ListTableThemeProvider>
        </AvailableToBillListTableContextProvider>
      )}
      {isPsaPrpManualBulkBillCreationEnabled && (
        <>
          {editable && !isLoading && selectedAvailableToBillItems.length ? (
            <BulkActionsForBill
              openDialog={openBulkBillProgressAndConfirmationDialog}
              selectedBillCount={selectedBillCount}
              resetSelectedAvailableToBillItems={
                resetSelectedAvailableToBillItems
              }
            />
          ) : null}
          {bulkBillProgressAndConfirmationOpen ? (
            <BulkBillProgressAndConfirmationDialog
              open={bulkBillProgressAndConfirmationOpen}
              selectedBillCount={selectedBillCount}
              billingBatchState={billingBatchState}
              setBillingBatchState={setBillingBatchState}
              batchCreationInProgress={batchCreationInProgress}
              createdBills={createdBills}
              onBatchComplete={onBatchComplete}
              onCancelClick={onCancelClick}
              onConfirmClick={onConfirmClick}
              isAutoGeneratedReferenceNumber={
                nextReferenceNumberSetting?.isAutoGeneratedReferenceNumber
              }
            />
          ) : null}
        </>
      )}
      {open && (
        <CreateBillWithLineItemsDialog
          open
          onClose={closeDialog}
          {...selectedRow}
          projectName={projectName}
          projectUri={projectUri}
          refetchAvailableToBillRows={refetchAvailableToBillRows}
          refetchAvailableToBillTotal={refetchAvailableToBillTotal}
          refetchFilteredTotals={refetchFilteredTotals}
        />
      )}
    </>
  );
};

AvailableToBillListTable.propTypes = {
  hover: PropTypes.bool,
  totals: PropTypes.object,
  hasMoreRows: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func,
  isTotalsLoading: PropTypes.bool,
  isRowsLoading: PropTypes.bool,
  pageSize: PropTypes.number,
  refetchAvailableToBillRows: PropTypes.func,
  refetchAvailableToBillTotal: PropTypes.func,
  client: PropTypes.object,
  programId: PropTypes.string,
  availableToBillFilter: PropTypes.object,
  projectUri: PropTypes.string,
  projectName: PropTypes.string,
  editable: PropTypes.bool,
  headerLevel: PropTypes.any,
  setSelectedTab: PropTypes.func
};

export default AvailableToBillListTable;
