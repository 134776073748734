import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';

export const DropdownEditor = ({
  column: { id: columnId, setFieldValue, options, isMobile, label },
  field,
  index,
  record
}) => {
  const onChangeHandler = useCallback(
    value => setFieldValue(`scripts[${index}].${columnId}`, value.id),
    [columnId, index, setFieldValue]
  );

  const dropdownOptions = options.map(o => ({ id: o, displayText: o }));
  const selectedValue = useMemo(
    () => dropdownOptions.find(x => x.id === record[field]),
    [dropdownOptions, field, record]
  );

  return (
    <SimpleDropdown
      variant="filled"
      options={dropdownOptions}
      onChange={onChangeHandler}
      noneOption={false}
      value={selectedValue}
      disableSort
      label={label}
      hiddenLabel={!isMobile}
    />
  );
};

DropdownEditor.propTypes = {
  column: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number
};

export default DropdownEditor;
