import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const activeTasksSummaryQuery = gql`
  query Eager_GetActiveTasksSummary($projectFilter: ProjectFilter2) {
    getActiveTasksSummary(projectFilter: $projectFilter) {
      onTrackTasksCount
      overEstimatedHourTasksCount
      overEstimatedDateTasksCount
      overEstimatedTasksCount
      underEstimatedTasksCount
      total
    }
  }
`;

export const useActiveTasksSummaryQuery = ({ projectFilter }) => {
  const { data, loading } = useQuery(activeTasksSummaryQuery, {
    variables: {
      projectFilter
    },
    fetchPolicy: 'cache-and-network'
  });

  const activeTasksSummary = get(data, 'getActiveTasksSummary', {});

  return {
    loading,
    activeTasksSummary
  };
};

export default useActiveTasksSummaryQuery;
