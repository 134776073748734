import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { rate } from './query';

export const GET_PROJECT_SYSTEM_RATES = gql`
  query getAllProjectSystemRates(
    $projectId: String!
    $page: Int!
    $pageSize: Int!
  ) {
    project(projectId: $projectId) {
      id
      projectCurrentEffectiveSystemRates(page: $page, pageSize: $pageSize) {
        id
        billingRate {
          id
          displayText
        }
        ${rate}
        asOfDate
      }
    }
  }
`;

const useProjectSpecificBillingRates = ({
  projectId,
  page = 1,
  pageSize = 10
}) => {
  const variables = {
    projectId,
    page,
    pageSize
  };

  const { loading, error, data } = useQuery(GET_PROJECT_SYSTEM_RATES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables
  });

  const projectCurrentEffectiveSystemRates =
    data && data.project && data.project.projectCurrentEffectiveSystemRates;

  return {
    loading,
    projectCurrentEffectiveSystemRates: (
      projectCurrentEffectiveSystemRates || []
    ).map(r => ({
      id: r.id,
      entity: r.billingRate,
      asOfDate: r.asOfDate,
      rate: r.rate
    })),
    error
  };
};

export default useProjectSpecificBillingRates;
