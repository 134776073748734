import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const DEFAULT_PAGE_SIZE = 20;

export const getTimeAndExpenseQuery = gql`
  query getTimeAndExpense($timeAndExpenseInput: TimeAndExpenseInput2!) {
    timeAndExpense: timeAndExpense2(timeAndExpenseInput: $timeAndExpenseInput)
  }
`;

export const resolveGroupings = grouping => {
  switch (grouping) {
    case 'entrydate__quarter':
      return ['entrydate__year', 'entrydate__quarter'];
    case 'entrydate__month':
      return ['entrydate__year', 'entrydate__month'];
    case 'entrydate__week':
      return ['entrydate__year', 'entrydate__week'];
    case 'entrydate__day':
      return ['entrydate__year', 'entrydate__month', 'entrydate__day'];
    default:
      return ['entrydate__year'];
  }
};

export const useTimeAndExpenseData = ({ billingFilter, settings }) => {
  const { dateRange: { startDate = null, endDate = null } = {} } = settings;

  const timeAndExpenseInput = {
    billingFilter,
    startDate,
    endDate,
    rows: settings.rows.values,
    columns: settings.columns.values,
    groupings: resolveGroupings(settings.groupings.values[0]),
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };

  const {
    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    data: { timeAndExpense = {} } = {},
    loading,
    error,
    fetchMore
  } = useQuery(getTimeAndExpenseQuery, {
    variables: {
      timeAndExpenseInput
    },
    fetchPolicy: 'network-only'
  });

  const { hasMore = false } = timeAndExpense;

  return {
    error,
    timeAndExpense,
    isLoading: loading,
    hasMore,
    fetchMore,
    timeAndExpenseInput
  };
};
