import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3)
  },
  header: {
    color: theme.palette.text.secondary,
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: theme.typography.body2.fontSize,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 0, 0.5, 0),
    margin: theme.spacing(0, 0, 1, 0)
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  editButton: {
    margin: theme.spacing(-2.5, 0)
  }
}));

export const InfoSection = ({ children, title, editable, onOpen }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const editAriaLabel = formatMessage({
    id: 'button.edit'
  });

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        <span className={classes.title}>{title}</span>
        {editable && (
          <IconButton
            className={classes.editButton}
            aria-label={editAriaLabel}
            onClick={onOpen}
          >
            <EditIcon />
          </IconButton>
        )}
      </Typography>
      {children}
    </div>
  );
};

InfoSection.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  editable: PropTypes.bool.isRequired,
  onOpen: PropTypes.func
};

export default InfoSection;
