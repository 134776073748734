import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const getProjectCodeSettingsQuery = gql`
  query getProjectCodeSettingsForNewProjects {
    projectCodeSettings {
      nextProjectCode
      projectCodeSettingOption
    }
  }
`;

export const useProjectCodeSettings = () => {
  const { data, loading } = useQuery(getProjectCodeSettingsQuery, {
    fetchPolicy: 'no-cache'
  });

  return {
    nextProjectCode:
      data?.projectCodeSettings?.projectCodeSettingOption === 'AUTO_INCREMENT'
        ? data?.projectCodeSettings?.nextProjectCode.toString()
        : '',
    projectCodeSettingsLoading: loading
  };
};
