import { useState, useCallback } from 'react';

const useSelectedColumns = ({
  initialState = [],
  defaultColumns = initialState
}) => {
  const [selectedColumns, setSelectedColumns] = useState(initialState);

  const toggleColumn = useCallback(
    columnKey => {
      if (selectedColumns.includes(columnKey)) {
        setSelectedColumns(selectedColumns.filter(c => c !== columnKey));
      } else {
        setSelectedColumns([...selectedColumns, columnKey]);
      }
    },
    [selectedColumns]
  );

  const resetSelection = useCallback(() => setSelectedColumns(initialState), [
    initialState
  ]);

  const resetToDefault = useCallback(() => setSelectedColumns(defaultColumns), [
    defaultColumns
  ]);

  const onTagKeySelection = useCallback(
    key => {
      if (!selectedColumns.includes(key.value)) {
        setSelectedColumns([...selectedColumns, key.value]);
      }
    },
    [selectedColumns]
  );

  const onRemoveTag = useCallback(
    key => {
      const newFiles = selectedColumns.filter(c => c !== key);

      setSelectedColumns(newFiles);
    },
    [selectedColumns]
  );

  return {
    selectedColumns,
    toggleColumn,
    resetSelection,
    resetToDefault,
    onRemoveTag,
    onTagKeySelection
  };
};

export default useSelectedColumns;
