const useTooltipConditions = ({
  source,
  hasChildTasks,
  hasAlternateEstimatedAtCompletionCostValue,
  hasAlternateExpenseRemainingValue,
  hasAlternateWorkRemainingValue,
  variationHours,
  variationCost,
  isProjectVariant
}) => {
  const genericTooltipCondition = hasChildTasks;
  const estimatedAtCompletionRowTooltipCondition = isProjectVariant
    ? !source.isClosed &&
      (hasChildTasks || hasAlternateEstimatedAtCompletionCostValue)
    : hasChildTasks;
  const expenseRemainigRowTooltipCondition =
    source?.projectEstimatedExpenseRemainingSeriesByMonth?.length > 0 ||
    hasAlternateExpenseRemainingValue;
  const workRemainingTooltipCondition =
    hasChildTasks || hasAlternateWorkRemainingValue;

  const atleastOneRowHasTooltip =
    genericTooltipCondition ||
    estimatedAtCompletionRowTooltipCondition ||
    expenseRemainigRowTooltipCondition;

  const actualsRowMarginCondition =
    atleastOneRowHasTooltip && !genericTooltipCondition;
  const workRemainingRowMarginCondition =
    atleastOneRowHasTooltip && !workRemainingTooltipCondition;
  const estimatedAtCompletionRowMarginCondition =
    atleastOneRowHasTooltip && !estimatedAtCompletionRowTooltipCondition;
  const initialEstimatedRowMarginCondition = atleastOneRowHasTooltip;
  const totalExpensesRowMarginCondition = atleastOneRowHasTooltip;
  const expenseRemainigRowMarginCondition =
    atleastOneRowHasTooltip && !expenseRemainigRowTooltipCondition;

  const mobileTaskHoursVariationRowMarginCondition =
    variationHours === null && hasChildTasks;

  const mobileTaskCostVariationRowMarginCondition =
    (variationCost === null && hasChildTasks) ||
    (atleastOneRowHasTooltip &&
      (!hasChildTasks || (isProjectVariant && source.isClosed)));

  const variationRowMarginCondition = isProjectVariant
    ? (source.isClosed || !hasChildTasks) && atleastOneRowHasTooltip
    : !hasChildTasks && atleastOneRowHasTooltip;

  return {
    actualsRowMarginCondition,
    atleastOneRowHasTooltip,
    estimatedAtCompletionRowMarginCondition,
    expenseRemainigRowMarginCondition,
    hideEstimatedAtCompletionRowTooltip: isProjectVariant && source.isClosed,
    hideVariationRowTooltip: isProjectVariant && source.isClosed,
    initialEstimatedRowMarginCondition,
    mobileTaskCostVariationRowMarginCondition,
    mobileTaskHoursVariationRowMarginCondition,
    totalExpensesRowMarginCondition,
    variationRowMarginCondition,
    workRemainingRowMarginCondition
  };
};

export default useTooltipConditions;
