import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(2),
    borderBottom: [[1, 'solid', theme.palette.grey[100]]],
    '&:last-child': {
      borderBottom: 'none'
    },
    backgroundColor: theme.palette.grey[100],
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    })
  },
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 2, 0),
    padding: 0
  },
  rowItem: {
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  section: {
    display: 'block',
    margin: 0,
    padding: 0,
    border: 'none',
    position: 'relative'
  },
  dates: {
    fontWeight: 'bold'
  },
  allocateButton: {
    margin: theme.spacing(1, 0, 0, -5 / 8)
  },
  valueContainer: {
    display: 'flex'
  }
}));

export default useStyles;
