import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';
import { FormattedDecimal } from '~/modules/common/components/Decimal';
import FormattedMoneyValue from './FormattedMoneyValue';
import { useVariationMessage } from './hooks';
import { TaskVariationIcon } from './TaskVariationIcon';
import { isValueDefined } from './util';

export const TaskVariationPillContent = ({
  intl,
  classes,
  variationStatus,
  fixedDecimalScale = true,
  value,
  variant = 'hours',
  hideStatusMessage = false,
  numberFormat,
  isPSAPRPTaskEstimateCalculationMethodEnabled,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const variationMessage = useVariationMessage({
    intl,
    variationStatus,
    isPSAPRPTaskEstimateCalculationMethodEnabled
  });

  if (
    variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE ||
    !isValueDefined(value)
  ) {
    return null;
  }

  return (
    <div className={classes.variationContainer}>
      {!hideStatusMessage && (
        <span className={classes.variationText}>{variationMessage}</span>
      )}
      <div
        className={classNames(classes.variationPill, classes[variationStatus])}
      >
        <TaskVariationIcon
          classes={classes}
          variationStatus={variationStatus}
        />
        {variant === 'cost' ? (
          <FormattedMoneyValue
            money={value}
            className={classes.variationValue}
            numberFormat={numberFormat}
          />
        ) : (
          <>
            <FormattedDecimal
              className={classes.variationValue}
              value={value}
              fixedDecimalScale={fixedDecimalScale}
              numberFormat={numberFormat}
            />
            <span className={classes.variationLabel}>
              {intl.formatMessage({
                id: isPsaRmpTaskAllocation1Enabled
                  ? 'projectTasksPage.h'
                  : 'projectTasksPage.hrs'
              })}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

TaskVariationPillContent.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  variationStatus: PropTypes.oneOf([
    ESTIMATED_HOURS_VARIATION_STATUS.MATCHING,
    ESTIMATED_HOURS_VARIATION_STATUS.OVER,
    ESTIMATED_HOURS_VARIATION_STATUS.UNDER,
    ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE
  ]).isRequired,
  fixedDecimalScale: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  variant: PropTypes.oneOf(['hours', 'cost']),
  hideStatusMessage: PropTypes.bool,
  numberFormat: PropTypes.object,
  isPSAPRPTaskEstimateCalculationMethodEnabled: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default TaskVariationPillContent;
