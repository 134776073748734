import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { useDialogState } from '~/modules/common/hooks';

export const useQuickAllocationProjectRowActions = ({ resourceRequestIds }) => {
  const intl = useIntl();

  const [selectedResourceRequestId, setSelectedResourceRequestId] = useState(
    null
  );

  const {
    open: showReleaseResourcesDialog,
    openDialog: openReleaseResourcesDialog,
    closeDialog: closeReleaseResourcesDialog
  } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const onDrawerClose = useCallback(() => {
    setSelectedResourceRequestId(null);
  }, []);

  const onDrawerOpen = useCallback(({ resourceRequestId }) => {
    setSelectedResourceRequestId(resourceRequestId);
  }, []);

  const {
    open: showViewMultipleResourceRequestsDialog,
    openDialog: onViewMultipleResourceRequestsDialogOpen,
    closeDialog: onViewMultipleResourceRequestsDialogClose
  } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const releaseResourcesAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.releaseResources'
    }),
    onClick: openReleaseResourcesDialog
  };

  const viewResourceRequestClickHandler = useCallback(() => {
    if (resourceRequestIds?.length === 1) {
      setSelectedResourceRequestId(resourceRequestIds[0]);
    } else {
      onViewMultipleResourceRequestsDialogOpen();
    }
  }, [onViewMultipleResourceRequestsDialogOpen, resourceRequestIds]);

  const viewResourceRequestAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.viewResourceRequest'
    }),
    onClick: viewResourceRequestClickHandler
  };

  const actions = [
    releaseResourcesAction,
    ...(resourceRequestIds?.length ? [viewResourceRequestAction] : [])
  ];

  return {
    actions,
    showReleaseResourcesDialog,
    closeReleaseResourcesDialog,
    showViewMultipleResourceRequestsDialog,
    onViewMultipleResourceRequestsDialogClose,
    selectedResourceRequestId,
    onDrawerClose,
    onDrawerOpen,
    isDrawerOpen: Boolean(selectedResourceRequestId)
  };
};

export default useQuickAllocationProjectRowActions;
