import { lighten } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    top: theme.spacing(1),
    height: theme.spacing(3),
    userSelect: 'none',
    zIndex: 1,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      boxShadow: theme.shadows[4]
    },
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  nonZeroRoot: {
    backgroundColor: theme.palette.allocationChartStatus.actual.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: lighten(
        theme.palette.allocationChartStatus.actual.main,
        0.1
      )
    }
  },
  timeBlock: {
    height: theme.spacing(3),
    display: 'inline-flex'
  },
  arrowUp: {
    width: '0.8em',
    color: 'red'
  },
  arrowDown: {
    width: '0.8em',
    color: 'yellow'
  }
}));

export const useTimeLineStyles = makeStyles(theme => ({
  container: {
    ...theme.typography.caption,
    display: 'inline-flex'
  },
  label: {
    color: theme.palette.allocationChartStatus.actual.hours.nonZero
  },
  zeroLabel: {
    color: theme.palette.allocationChartStatus.actual.hours.zero,
    fontWeight: theme.typography.fontWeightBold
  }
}));
