import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  billTaxLineItemsQuery,
  PAGE_SIZE as TAX_LINE_ITEMS_PAGE_SIZE
} from '~/modules/billing-invoicing/common/components/TaxLineItems/hooks/useTaxLineItems';
import { billingTransactionRefetchQueries } from '~/modules/common/components/RefetchQueries';
import { filterSummarizeColumnOptions } from '~/modules/billing-invoicing/common/util';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { billQuery as lineItemQuery, PAGE_SIZE } from './useLineItems';

const DELETE_LINE_ITEM = gql`
  mutation deleteLineItemForBill($id: String!) {
    deleteLineItemForBill2(id: $id)
  }
`;

export const useDeleteLineItemForBill = ({ billId }) => {
  const [putLineItemForBill] = useMutation(DELETE_LINE_ITEM);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : ['getDraftTotals', 'getOutstandingBillSummary'];

  return {
    deleteLineItemForBill: async (
      lineItemId,
      refetchBill = true,
      summarizeColumnOptions
    ) => {
      const filteredSummarizeColumnOptions = filterSummarizeColumnOptions(
        summarizeColumnOptions
      );
      const refetchQueries = [
        ...billingTransactionRefetchQueries,
        ...queries,
        'getAvailableBillingItemsTotal'
      ];

      if (billId && refetchBill) {
        refetchQueries.push({
          query: lineItemQuery,
          variables: {
            page: 1,
            pageSize: PAGE_SIZE,
            id: billId,
            filter: { isAdhoc: false },
            summarizeColumnOptions: filteredSummarizeColumnOptions
          }
        });
        refetchQueries.push({
          query: lineItemQuery,
          variables: {
            page: 1,
            pageSize: PAGE_SIZE,
            id: billId,
            filter: { isAdhoc: true },
            summarizeColumnOptions: filteredSummarizeColumnOptions
          }
        });
        refetchQueries.push({
          query: billTaxLineItemsQuery,
          variables: {
            page: 1,
            pageSize: TAX_LINE_ITEMS_PAGE_SIZE,
            id: billId,
            filter: { isTaxItem: true }
          }
        });
      }

      await putLineItemForBill({
        variables: {
          id: lineItemId
        },
        skip: !lineItemId,
        refetchQueries
      });
    }
  };
};

export default useDeleteLineItemForBill;
