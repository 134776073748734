import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { NoValue } from '~/modules/common/components';

export const RevenuePeriodFormatter = ({ field, record }) => {
  const { endDate } = record[field];

  return endDate ? (
    DateTime.utc(endDate.year, endDate.month, endDate.date).toLocaleString({
      month: 'short',
      year: 'numeric'
    })
  ) : (
    <NoValue />
  );
};

RevenuePeriodFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default RevenuePeriodFormatter;
