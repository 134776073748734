import { useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';
import { numberAbbreviatedTickFormat } from '../util';

export default ({ gradientId, intl, name }) => {
  const actualBarStyles = useMemo(
    () => ({
      data: {
        fill: `url(#${name}${gradientId})`
      }
    }),
    [gradientId, name]
  );

  const xTickFormat = useCallback(
    tick => DateTime.utc(2019, tick).monthShort,
    []
  );

  const yTickFormat = useCallback(
    tick => numberAbbreviatedTickFormat({ intl, tick }),
    [intl]
  );

  const estimatedBarStyles = {
    data: { fill: `url(#${name}gradientForEstimated)` }
  };

  const xTickValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return {
    xTickValues,
    actualBarStyles,
    estimatedBarStyles,
    yTickFormat,
    xTickFormat
  };
};
