import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  column: {
    width: '100%',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

export const RateNameFormatter = ({ field, record }) => {
  const classes = useStyles();

  return record[field] && record[field].displayText ? (
    <Typography className={classes.column} variant="body2">
      {record[field].displayText}
    </Typography>
  ) : (
    ''
  );
};
RateNameFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default RateNameFormatter;
