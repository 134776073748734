import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Chip } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  root: ({ workflowId }) => ({
    color: theme.palette.getContrastText(
      theme.palette.projectWorkflow[workflowId] || theme.palette.grey[500]
    ),
    backgroundColor:
      theme.palette.projectWorkflow[workflowId] || theme.palette.grey[500]
  }),
  label: {
    padding: theme.spacing(0, 1.25)
  }
}));

export const WorkflowBadge = ({
  classes: classesOverride,
  className,
  workflowId,
  customStatusLabel
}) => {
  const {
    featureFlags: { isPsaPrpRenameProjectStatusEnabled }
  } = useMeContext();
  const classes = useStyles({ classes: classesOverride, workflowId });

  const label =
    isPsaPrpRenameProjectStatusEnabled && customStatusLabel ? (
      customStatusLabel
    ) : (
      <FormattedMessage id={`projectWorkflow.${workflowId}`} />
    );

  return <Chip classes={classes} className={className} label={label} />;
};

WorkflowBadge.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  workflowId: PropTypes.string.isRequired,
  customStatusLabel: PropTypes.string
};

export default WorkflowBadge;
