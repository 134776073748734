import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  bars: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(2),
    justifyContent: 'space-evenly'
  },
  legends: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2)
  }
}));

export const BarsChartLoading = ({ showLegends = true }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {showLegends && (
        <Typography variant="body1" className={classes.legends}>
          <Skeleton width="50%" />
        </Typography>
      )}
      <div className={classes.bars}>
        <Skeleton width="5%" height={350} variant="rect" />
        <Skeleton width="5%" height={300} variant="rect" />
        <Skeleton width="5%" height={250} variant="rect" />
        <Skeleton width="5%" height={350} variant="rect" />
        <Skeleton width="5%" height={200} variant="rect" />
        <Skeleton width="5%" height={100} variant="rect" />
        <Skeleton width="5%" height={300} variant="rect" />
        <Skeleton width="5%" height={300} variant="rect" />
      </div>
    </div>
  );
};

BarsChartLoading.propTypes = {
  showLegends: PropTypes.bool
};

export default BarsChartLoading;
