import useMeContext from '~/modules/me/useMeContext';
import { POLICYTYPE, USER_ACCESS_ROLE } from '~/modules/common/enums';

export const getEffectiveProjectGroupPolicy = ({ userAccessRoles }) => {
  const hasPortfolioRoles = userAccessRoles.some(
    userRole =>
      userRole === USER_ACCESS_ROLE.PORTFOLIO_ADMIN ||
      userRole === USER_ACCESS_ROLE.PORTFOLIO_MANAGER
  );

  return hasPortfolioRoles
    ? POLICYTYPE.PORTFOLIO_MANAGEMENT
    : POLICYTYPE.PROJECT_MANAGEMENT;
};

const useEffectiveProjectGroupPolicy = () => {
  const { userAccessRoles, featureFlags } = useMeContext();

  return getEffectiveProjectGroupPolicy({ userAccessRoles, featureFlags });
};

export default useEffectiveProjectGroupPolicy;
