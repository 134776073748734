import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, Input, IconButton, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import ClearIcon from '@material-ui/icons/ClearSharp';
import ChartDateRangePicker from '~/modules/common/charts/dashboard/ChartDateRangePicker';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import FacetDetailField from '~/modules/common/components/SearchBox/FacetDetailField';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { useMonthFacetHook } from './hooks';

const label = <FormattedMessage id="monthFacetDetails.title" />;

const useStyles = makeStyles(() => ({
  root: {
    padding: 'unset',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export const MonthFacetDetails = ({ selected, setSelected }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { onMonthChange } = useMonthFacetHook({ setSelected });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handleMonthChange = useCallback(
    value => {
      onMonthChange(value);
      handleClose();
    },
    [handleClose, onMonthChange]
  );

  const handleClear = useCallback(() => {
    onMonthChange({});
  }, [onMonthChange]);

  const values = useMemo(() => {
    const hasValue = selected && selected.length > 0;
    const dateRange = { startDate: DateTime.utc() };

    if (hasValue)
      dateRange.startDate = mapIsoStringtoUtcObject(
        selected[0].dateRange.startDate
      );

    return {
      hasValue,
      dateRange,
      value: hasValue
        ? dateRange.startDate.toLocaleString({
            month: 'short',
            year: 'numeric'
          })
        : ''
    };
  }, [selected]);

  const endAdornment = useMemo(
    () => (
      <IconButton
        classes={classes}
        aria-label={intl.formatMessage({
          id: 'monthFacetDetails.clearMonthAriaLabel'
        })}
        role="button"
        onClick={handleClear}
      >
        <ClearIcon />
      </IconButton>
    ),
    [classes, handleClear, intl]
  );

  const inputProps = useMemo(() => ({ onClick: handleClick }), [handleClick]);

  return (
    <FacetDetailField label={label}>
      <FormControl fullWidth>
        <Input
          id="standard-adornment-amount"
          value={values.value}
          endAdornment={values.hasValue ? endAdornment : null}
          inputProps={inputProps}
          fullWidth
          readOnly
        />
      </FormControl>
      <ChartDateRangePicker
        menuId="month-select-menu"
        anchorEl={anchorEl}
        scale={PERIOD_SCALE_ENUM.MONTHS}
        dateRange={values.dateRange}
        onClose={handleClose}
        onChange={handleMonthChange}
      />
    </FacetDetailField>
  );
};

MonthFacetDetails.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default MonthFacetDetails;
