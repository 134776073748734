import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    margin: 0,
    padding: theme.spacing(2)
  },
  heading: {
    textAlign: 'center',
    margin: theme.spacing(0, 0, 1, 0)
  },
  date: {
    fontSize: theme.typography.h2.fontSize,
    display: 'block'
  },
  subTitle: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    display: 'block'
  },
  values: {
    margin: 0,
    padding: 0,
    display: 'grid',
    gridTemplateColumns: [['auto', 'auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0,
    justifyItems: 'stretch',
    alignItems: 'baseline'
  },
  costValues: {
    margin: 0,
    padding: 0,
    display: 'grid',
    gridTemplateColumns: [['auto', 'auto', 'auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0,
    justifyItems: 'stretch',
    alignItems: 'baseline'
  },
  item: {
    display: 'contents'
  },
  label: {
    textAlign: 'left'
  },
  value: {
    textAlign: 'right',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 0, 2)
  },
  diff: {
    lineHeight: `${theme.spacing(1.75)}px`,
    margin: theme.spacing(0, -1 / 4)
  },
  totalItem: {
    '& $label, & $value': {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5)
    }
  }
}));

export const useVarianceStyles = makeStyles(theme => ({
  diffPositive: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

export const usePopperStyles = makeStyles(theme => ({
  popper: {
    transform:
      theme.direction === 'rtl' ? 'translate3d(-55px,315px,0) !important' : null
  }
}));

export default useStyles;
