import React from 'react';
import PropTypes from 'prop-types';
import { useDialogState } from '~/modules/common/hooks';
import RoleScheduleReadOnlyCard from './RoleScheduleReadOnlyCard';
import RoleSchedulesDialog from './RoleSchedulesDialog';

export const RolesInfo = ({
  editable,
  userDetails,
  isResourceDetailsLoading
}) => {
  const {
    open: dialogOpen,
    openDialog,
    closeDialog: onDialogCancel
  } = useDialogState(false);

  return (
    <>
      <RoleScheduleReadOnlyCard
        editable={editable}
        userDetails={userDetails}
        onEdit={openDialog}
        isResourceDetailsLoading={isResourceDetailsLoading}
      />
      <RoleSchedulesDialog
        userDetails={userDetails}
        open={dialogOpen}
        onCancel={onDialogCancel}
        editable={editable}
        isResourceDetailsLoading={isResourceDetailsLoading}
      />
    </>
  );
};

RolesInfo.propTypes = {
  userDetails: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  isResourceDetailsLoading: PropTypes.bool
};

export default RolesInfo;
