import { gql } from 'graphql-tag';
import { projectDate2Fragment } from '../common/fragments/specificProjectFragment';

export const projectsQuery = gql`
  query getProject($projectId: String!) {
    project(projectId: $projectId) {
      ...SpecificProjectDate2Fragment
    }
  }
  ${projectDate2Fragment}
`;
