import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Decimal } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  noRole: {
    fontStyle: 'italic',
    color: theme.palette.text.secondary
  }
}));

export const Title = ({ request }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <span>
        {request.role ? (
          request.role.displayText
        ) : (
          <span className={classes.noRole}>
            {intl.formatMessage({ id: 'resourceRequestTitle.anyRole' })}
          </span>
        )}
      </span>
      {request.quantity > 1 ? (
        <>
          &nbsp;×&nbsp;
          <Decimal value={request.quantity} fixedDecimalScale={false} />
        </>
      ) : null}
    </>
  );
};

Title.propTypes = {
  request: PropTypes.object
};

export default Title;
