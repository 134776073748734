import { PropTypes } from 'prop-types';
import AddIcon from '@material-ui/icons/AddSharp';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React, { useMemo } from 'react';
import { NoDataItem } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  addIcon: {
    verticalAlign: 'bottom'
  },
  card: {
    paddingTop: theme.spacing(2) + 4,
    color: theme.palette.grey[600],
    boxShadow: 'unset'
  },
  cardContent: {
    paddingTop: theme.spacing(1) + 2,
    color: theme.palette.grey[600]
  }
}));

function BillingTransactionListNoData({ resourceKeys }) {
  const classes = useStyles();
  const plusIcon = useMemo(
    () => ({
      plusIcon: <AddIcon aria-label="add" className={classes.addIcon} />
    }),
    [classes.addIcon]
  );

  return (
    <Card className={classes.card}>
      <CardContent>
        <NoDataItem dataQeId="noBillingRecords">
          <FormattedMessage id={resourceKeys.message} />
        </NoDataItem>
      </CardContent>
      {resourceKeys.description && (
        <CardContent className={classes.cardContent}>
          <FormattedMessage id={resourceKeys.description} values={plusIcon} />
        </CardContent>
      )}
    </Card>
  );
}

BillingTransactionListNoData.propTypes = {
  resourceKeys: PropTypes.object.isRequired
};

export default BillingTransactionListNoData;
