import { useEffect, useState } from 'react';

export const useSettingsState = settings => {
  const [rows, setRows] = useState(settings.rows.values);
  const [columns, setColumns] = useState(settings.columns.values);
  const [groupings, setGroupings] = useState(settings.groupings.values);

  useEffect(() => {
    setRows(settings.rows.values);
    setColumns(settings.columns.values);
    setGroupings(settings.groupings.values);
  }, [settings, setRows, setColumns, setGroupings]);

  return {
    rows,
    setRows,
    columns,
    setColumns,
    groupings,
    setGroupings
  };
};

export default useSettingsState;
