import { useMemo } from 'react';
import get from 'lodash.get';
import { hasPermission } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me';
import { AssignmentDialogColumns } from '../enhancers/constants';
import { hasViewCustomFields } from '../enhancers/resourceAllocationDialogColumns';

export const useAssignmentDialogColumnPermission = () => {
  const { groupSettings, permissions, permissionsMap } = useMeContext();

  const permissionsSettings = useMemo(() => {
    const canViewLocation =
      get(groupSettings, 'location', {}).isEnabled &&
      Boolean(permissionsMap['urn:replicon:user-action:view-location']);

    const canViewDivision =
      get(groupSettings, 'division', {}).isEnabled &&
      Boolean(permissionsMap['urn:replicon:user-action:view-division']);

    const canViewDepartment =
      get(groupSettings, 'department', {}).isEnabled &&
      Boolean(permissionsMap['urn:replicon:user-action:view-department']);

    const canViewEmployeeType =
      get(groupSettings, 'employeeType', {}).isEnabled &&
      Boolean(permissionsMap['urn:replicon:user-action:view-employee-type']);

    const canViewServiceCenter =
      get(groupSettings, 'serviceCenter', {}).isEnabled &&
      Boolean(permissionsMap['urn:replicon:user-action:view-service-center']);

    const canViewCostCenter =
      get(groupSettings, 'costCenter', {}).isEnabled &&
      Boolean(permissionsMap['urn:replicon:user-action:view-cost-center']);

    const canViewRole = (permissions || []).some(
      p =>
        p.permissionActionUri === 'urn:replicon:user-action:view-project-role'
    );

    const canViewRequestedCost = Boolean(
      permissionsMap['urn:replicon:project-action:view-cost-data'] ||
        permissionsMap['urn:replicon:user-action:view-cost-data']
    );

    const canViewResourcePool = (permissions || []).some(
      p =>
        p.permissionActionUri === 'urn:replicon:user-action:view-resource-pool'
    );

    const isAvailabilityEnabled =
      hasPermission(permissionsMap)({
        actionUri: 'urn:replicon:user-action:view-availability',
        dataAccessLevelUri:
          'urn:replicon:user-data-access-level:resource-manager'
      }) ||
      hasPermission(permissionsMap)({
        actionUri: 'urn:replicon:user-action:view-availability',
        dataAccessLevelUri:
          'urn:replicon:user-data-access-level:resource-pool-manager'
      });

    const isViewCostEnabled =
      hasPermission(permissionsMap)({
        actionUri: 'urn:replicon:user-action:view-hourly-cost',
        dataAccessLevelUri:
          'urn:replicon:user-data-access-level:resource-manager'
      }) ||
      hasPermission(permissionsMap)({
        actionUri: 'urn:replicon:user-action:view-hourly-cost',
        dataAccessLevelUri:
          'urn:replicon:user-data-access-level:resource-pool-manager'
      });

    const canViewCustomFields = hasViewCustomFields(permissions);

    return {
      [AssignmentDialogColumns.DisplayText]: true,
      [AssignmentDialogColumns.Availability]: isAvailabilityEnabled,
      [AssignmentDialogColumns.SmartMatchIncludingCost]: canViewRequestedCost,
      [AssignmentDialogColumns.SmartMatchExcludingCost]: true,
      [AssignmentDialogColumns.CostRate]: isViewCostEnabled,
      [AssignmentDialogColumns.CurrentCostCenter]: canViewCostCenter,
      [AssignmentDialogColumns.CurrentServiceCenter]: canViewServiceCenter,
      [AssignmentDialogColumns.CurrentDepartment]: canViewDepartment,
      [AssignmentDialogColumns.CurrentEmployeeType]: canViewEmployeeType,
      [AssignmentDialogColumns.CurrentDivision]: canViewDivision,
      [AssignmentDialogColumns.CurrentLocation]: canViewLocation,
      [AssignmentDialogColumns.ResourcePools]: canViewResourcePool,
      [AssignmentDialogColumns.SupervisorAssignment]: true,
      [AssignmentDialogColumns.StartDate]: true,
      [AssignmentDialogColumns.EndDate]: true,
      [AssignmentDialogColumns.Roles]: canViewRole,
      [AssignmentDialogColumns.EmployeeId]: true,
      [AssignmentDialogColumns.Tags]: canViewCustomFields,
      [AssignmentDialogColumns.CustomFields]: canViewCustomFields
    };
  }, [groupSettings, permissions, permissionsMap]);

  return permissionsSettings;
};
