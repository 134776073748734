import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl, FormattedMessage } from 'react-intl';
import { ResourcePoolIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';

const ResourcePoolIndicator = ({ value }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <span
      className={classes.root}
      aria-label={intl.formatMessage({
        id: 'resourceRequestCard.resourcePools'
      })}
    >
      <Tooltip
        title={intl.formatMessage({ id: 'resourceRequestCard.resourcePools' })}
      >
        <ResourcePoolIcon className={classes.icon} />
      </Tooltip>
      <FormattedMessage
        id="resourceRequestCard.resourcePoolRequirements"
        values={{ count: value.length }}
      />
    </span>
  );
};

ResourcePoolIndicator.propTypes = {
  value: PropTypes.array
};

export default ResourcePoolIndicator;
