import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

export const CANCEL_BATCH_MUTATION = gql`
  mutation cancelBatch($batchId: String!) {
    cancelBatch(batchId: $batchId)
  }
`;

export const useCancelBatch = () => {
  const [cancelBatchMutation] = useMutation(CANCEL_BATCH_MUTATION);

  return useCallback(
    batchId =>
      cancelBatchMutation({
        variables: {
          batchId
        }
      }),
    [cancelBatchMutation]
  );
};

export default useCancelBatch;
