import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  label: {
    whiteSpace: 'nowrap'
  },
  baseFilled: {
    marginTop: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[50],
    borderRadius: [[theme.shape.borderRadius, theme.shape.borderRadius, 0, 0]],
    padding: theme.spacing(0, 1.5)
  },
  valueContainerFilled: {
    padding: theme.spacing(2.75, 0, 0.5, 0)
  }
}));

const ReadOnlyDateField = ({ className, label, variant, children }) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classNames(classes.root, className)}
      variant={variant}
      required={false}
    >
      <InputLabel
        shrink
        classes={useMemo(
          () => ({
            root: classes.label
          }),
          [classes.label]
        )}
      >
        {label}
      </InputLabel>
      <div
        className={classNames({
          [classes.baseFilled]: variant === 'filled'
        })}
      >
        <div className={classes.valueContainerFilled}>{children}</div>
      </div>
    </FormControl>
  );
};

ReadOnlyDateField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  variant: PropTypes.oneOf(['standard', 'filled']),
  children: PropTypes.node
};
export default ReadOnlyDateField;
