import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ColumnSettingsDialog,
  useTableSettings
} from '~/modules/common/components/ListTable';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import { useSaveURLSearchParamsEffect } from '~/modules/navigation/useSaveURLSearchParamsEffect';
import {
  useProjectPermissions,
  useEnabledProjectColumns
} from '~/modules/common/hooks/project';
import {
  PROJECTS_TAG_BINDING_KEY,
  TABLE_KEY
} from '~/modules/common/hooks/project/projectColumns';
import ProjectsContextProvider from '../../ProjectsContextProvider';
import { AddProjectButton } from '../AddProject';
import ProjectListPageTabContents from './ProjectListPageTabContents';
import ProjectListPageTabs from './ProjectListPageTabs';
import ProjectSearchBox from './ProjectSearchBox';
import ProjectsSummary from './ProjectsSummary';
import ProjectListPageToolbar from './Toolbar';

const projectsTitle = <FormattedMessage id="routes.projects" />;

const ProjectListPage = () => {
  useSaveURLSearchParamsEffect();
  const {
    enabledProjectColumnNames,
    enabledProjectColumnGroups
  } = useEnabledProjectColumns();
  const { data } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: enabledProjectColumnNames
  });
  const { open, closeDialog, openDialog } = useDialogState();
  const isMobile = useIsBreakpointDown('xs');
  const showMenuButton = useIsBreakpointDown('sm');
  const [hideAddFab, setHideAddFab] = useState(false);

  const onRowSelectionChange = useCallback(
    (records = []) => {
      setHideAddFab(records.length > 0);
    },
    [setHideAddFab]
  );

  const { canAddProject } = useProjectPermissions();

  return (
    <ProjectsContextProvider>
      <StickyHeader
        title={projectsTitle}
        showMenuButton={showMenuButton}
        disableTitleFullWidth={!isMobile}
        rightPadding
      >
        <ProjectListPageToolbar isMobile={isMobile} />
      </StickyHeader>
      {isMobile && <ProjectSearchBox />}
      <ProjectsSummary />
      <StickyHeader level={1} bottomBorder>
        <ProjectListPageTabs onSettingsClick={openDialog} isMobile={isMobile} />
      </StickyHeader>
      <ProjectListPageTabContents onRowSelectionChange={onRowSelectionChange} />
      {!hideAddFab && canAddProject && <AddProjectButton />}
      {data && (
        <ColumnSettingsDialog
          tableKey={TABLE_KEY}
          groups={enabledProjectColumnGroups}
          selectedColumns={data.columns}
          settingsColumnJustify="start"
          sort={data.sort}
          defaultColumns={enabledProjectColumnNames}
          open={open}
          onClose={closeDialog}
          tagsObjectBinding={PROJECTS_TAG_BINDING_KEY}
        />
      )}
    </ProjectsContextProvider>
  );
};

ProjectListPage.propTypes = {};

export default ProjectListPage;
