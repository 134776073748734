import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { ALLOCATION_PERIOD_OVERLAP_TYPE } from '~/modules/resourcing/common/enums';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { PERIOD_HEIGHT, PERIOD_GAP } from './constants';

export const useStyles = makeStyles(() => ({
  overlay: {
    margin: 0,
    zIndex: 1,
    position: 'absolute',
    '&:hover': {
      backgroundColor: `rgba(0, 0, 0, 0.04)`,
      cursor: 'pointer'
    }
  },
  partiallyOverlappedOverlay: {
    zIndex: 2
  }
}));

const renderOverlay = ({
  style,
  handleEvent,
  classes,
  requestStatus,
  formatMessage,
  isPercentageView,
  isPartial
}) => (
  <div
    data-qe-id={`ResourceRequestOverlayPeriodBlock_${requestStatus}`}
    aria-label={formatMessage({
      id: isPercentageView
        ? 'resourceRequestBlockLabel.percentage'
        : 'resourceRequestBlockLabel.hours'
    })}
    role="button"
    tabIndex="0"
    onClick={handleEvent}
    onKeyPress={handleEvent}
    className={classNames(classes.overlay, {
      [classes.partiallyOverlappedOverlay]: isPartial,
      resourceRequestAllocationOverlay: !isPartial
    })}
    style={style}
  />
);

const getHoursPartialOverlayStyles = ({
  overlayStyle,
  scale,
  resourceRequest,
  overlayPeriod,
  chartStartDate
}) => {
  const { hasRequestStart, hasRequestEnd, startDate, endDate } = overlayPeriod;
  const requestStartDate = mapIsoStringtoUtcObject(resourceRequest.startDate);
  const requestEndDate = mapIsoStringtoUtcObject(resourceRequest.endDate);

  const overlapStart = hasRequestStart ? requestStartDate : startDate;
  const overlapEnd = hasRequestEnd ? requestEndDate : endDate;

  return {
    ...overlayStyle,
    ...getDynamicPositionAttributes({
      chartStartDate,
      start: overlapStart,
      end: overlapEnd,
      scale,
      isPsaRmpUserSchedulePerfFixEnabled: true
    }).dynamicPosition
  };
};

const ResourceRequestOverlayBlock = ({
  resourceRequest,
  handleRequestPeriodClick,
  isPercentageView,
  scale,
  chartDisplayDateRange,
  overlayPeriod,
  overlayPeriods
}) => {
  const { dynamicPosition, requestPeriodOverlapType } = overlayPeriod;

  const { formatMessage } = useIntl();

  const classes = useStyles();

  const handleEvent = useCallback(
    event => {
      if (
        event.type === 'click' ||
        event.key === 'Enter' ||
        event.keyCode === 13
      ) {
        handleRequestPeriodClick({
          event,
          requestPeriod: overlayPeriod,
          overlayPeriods
        });
      }
    },
    [handleRequestPeriodClick, overlayPeriod, overlayPeriods]
  );

  const overlayStyle = useMemo(
    () => ({
      top: dynamicPosition.top,
      left: dynamicPosition.left,
      width: dynamicPosition.width,
      height: `${resourceRequest.quantity * PERIOD_HEIGHT +
        (resourceRequest.quantity - 1) * PERIOD_GAP}px`
    }),
    [dynamicPosition, resourceRequest]
  );

  return (
    <>
      {!isPercentageView &&
      requestPeriodOverlapType === ALLOCATION_PERIOD_OVERLAP_TYPE.PARTIAL
        ? renderOverlay({
            style: getHoursPartialOverlayStyles({
              overlayStyle,
              scale,
              chartStartDate: chartDisplayDateRange.startDate,
              overlayPeriod,
              resourceRequest
            }),
            handleEvent,
            classes,
            requestStatus: resourceRequest.requestStatus,
            formatMessage,
            isPartial: true
          })
        : null}
      {renderOverlay({
        style: overlayStyle,
        handleEvent,
        classes,
        requestStatus: resourceRequest.requestStatus,
        formatMessage,
        isPercentageView
      })}
    </>
  );
};

ResourceRequestOverlayBlock.propTypes = {
  resourceRequest: PropTypes.object,
  handleRequestPeriodClick: PropTypes.func,
  isPercentageView: PropTypes.bool,
  scale: PropTypes.string,
  chartDisplayDateRange: PropTypes.object,
  overlayPeriod: PropTypes.object,
  overlayPeriods: PropTypes.array
};

export default ResourceRequestOverlayBlock;
