import React, { useState, useCallback, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import { DateTime } from 'luxon';
import { getLuxonWorkWeekStart } from '~/modules/common/dates/dayOfWeek';
import { defaultSettings } from './defaultSettings';
import usePutSettings from './usePutSettings';
import useGetSettings from './useGetSettings';

export const calcMaxAnchorDate = (endDate, settings, userPreferenceWeek) => {
  const scalingFactor = mapScalingFactor(settings.groupings.values[0]);
  const scale = {
    [scalingFactor]: magnitudeByCurrentPageWidth(settings)
  };

  const isWeek = scalingFactor === 'weeks';

  if (isWeek) {
    let newDate = endDate.minus(scale);

    while (newDate.weekday !== userPreferenceWeek.weekStart) {
      newDate = newDate.minus({ days: 1 });
    }

    return newDate;
  }

  return endDate.minus(scale).startOf(scalingFactor);
};

export const constrainDateInRange = (date, startDate, endDate) =>
  DateTime.min(endDate, DateTime.max(startDate, date));

export const calcDefaultAnchorDate = (
  dateRange,
  settings,
  userPreferenceWeek
) => {
  const today = DateTime.utc();

  if (dateRange) {
    const { startDate, endDate } = dateRange;

    const maxAnchorDate = calcMaxAnchorDate(
      endDate,
      settings,
      userPreferenceWeek
    );

    return constrainDateInRange(today, startDate, maxAnchorDate);
  }

  return today;
};

export const mapScalingFactor = scale =>
  // entrydate__day, entrydate__week, entrydate__month, entrydate__quarter, entrydate__year
  `${scale.replace('entrydate__', '')}s`;

export const magnitudeByCurrentPageWidth = settings => {
  // fixme magic number 150px typical column width
  const columnCellWidth = 150;
  // fixme magic number 60px typical sidebar width
  const sidebarWidth = 60;

  const rowColumnWidth = settings.rows.values.length * columnCellWidth;
  const columnWidth = settings.columns.values.length * columnCellWidth;

  const mag =
    Math.floor(
      (window.innerWidth - sidebarWidth - rowColumnWidth) / columnWidth
    ) - 1;

  if (isFinite(mag)) return Math.max(0, mag);

  return 0;
};

export const resolveDateRange = (settings, userPreferenceWeek) => {
  const { scalingFactor, anchorDate } = settings;

  if (!scalingFactor || !anchorDate) {
    return {
      startDate: null,
      endDate: null
    };
  }
  const isWeek = scalingFactor === 'weeks';
  const numColumnsAfterAnchorDate = {
    [scalingFactor]: magnitudeByCurrentPageWidth(settings)
  };

  if (isWeek) {
    let startDate = anchorDate;

    while (startDate.weekday !== userPreferenceWeek.weekStart) {
      startDate = startDate.minus({ days: 1 });
    }

    startDate = startDate.startOf('day');

    const endDate = startDate
      .plus(numColumnsAfterAnchorDate)
      .plus({ weeks: 1 })
      .minus({ days: 1 })
      .endOf('day');

    return {
      startDate,
      endDate
    };
  }

  return {
    startDate: anchorDate.startOf(scalingFactor),
    endDate: anchorDate.plus(numColumnsAfterAnchorDate).endOf(scalingFactor)
  };
};

export const useSettings = ({ intl, me, id, supportedSettings }) => {
  const userPreferenceWeek = getLuxonWorkWeekStart(me.workWeekStartDay.uri);

  const [isInitialized, setIsInitialized] = useState(false);

  const { saveSettings } = usePutSettings(id);
  const {
    projectTimeAndExpenseDateRange,
    settings: storedSettings,
    loading: storedSettingsLoading
  } = useGetSettings(id);

  const [settings, putSettings] = useState({
    rows: { options: [], values: [] },
    columns: { options: [], values: [] },
    groupings: { options: [], values: [] },
    anchorDate: DateTime.utc(),
    dateRange: {
      endDate: DateTime.utc(),
      startDate: DateTime.utc()
    },
    scalingFactor: 'days'
  });

  const onUpdateSettings = useCallback(
    (values = {}) => {
      const newSettings = {
        ...settings,
        ...values
      };

      if (
        newSettings.groupings.values &&
        newSettings.groupings.values.length > 0 &&
        (settings.groupings.values.length <= 0 ||
          newSettings.groupings.values[0] !== settings.groupings.values[0])
      ) {
        newSettings.scalingFactor = mapScalingFactor(
          newSettings.groupings.values[0]
        );
      }

      newSettings.dateRange = resolveDateRange(newSettings, userPreferenceWeek);

      putSettings(newSettings);
      saveSettings(newSettings);
    },
    [settings, userPreferenceWeek, saveSettings]
  );

  const initializeSettings = useCallback(
    timeAndExpenseSettings => {
      const props = {
        intl,
        me,
        settingsValues: {
          rows: ['username'],
          groupings: ['entrydate__month'],
          columns: ['costamount', 'billablehours'],
          ...timeAndExpenseSettings
        },
        supportedSettings
      };

      const values = {
        rows: defaultSettings.rows(props),
        columns: defaultSettings.columns(props),
        groupings: defaultSettings.groupings(props)
      };

      values.anchorDate =
        props.settingsValues.anchorDate ||
        calcDefaultAnchorDate(
          projectTimeAndExpenseDateRange,
          values,
          userPreferenceWeek
        );

      onUpdateSettings(values);

      setIsInitialized(true);
    },
    [
      intl,
      me,
      supportedSettings,
      projectTimeAndExpenseDateRange,
      userPreferenceWeek,
      onUpdateSettings
    ]
  );

  useEffect(() => {
    if (!isInitialized && !storedSettingsLoading) {
      initializeSettings(storedSettings);
    }
  }, [
    initializeSettings,
    isInitialized,
    storedSettings,
    storedSettingsLoading
  ]);

  return {
    loading: !isInitialized,
    settings,
    onUpdateSettings
  };
};

export const getSettings = ({ settings, hideBilling }) => {
  return {
    ...settings,
    columns: {
      ...settings.columns,
      ...(hideBilling && {
        options: settings.columns.options.filter(
          e => e.key !== 'billableamount'
        )
      }),
      ...(hideBilling && {
        values: settings.columns.values.filter(e => e !== 'billableamount')
      })
    }
  };
};

export const withSettings = BaseComponent => props => {
  const { me, billingType, templateSettings } = props;
  const {
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = me;
  const { loading, settings, onUpdateSettings } = useSettings(props);

  const hideBilling =
    isPsaPrpPsaPpmMergerEnabled &&
    billingType === 'PROJECT' &&
    !templateSettings?.hasBilling;

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <BaseComponent
      {...props}
      settings={
        isPsaPrpPsaPpmMergerEnabled
          ? getSettings({ settings, hideBilling })
          : settings
      }
      onUpdateSettings={onUpdateSettings}
    />
  );
};
