import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
};

export const ResponsiveTable = ({ classes: { root }, children }) => (
  <div className={root}>{children}</div>
);

ResponsiveTable.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default withStyles(styles)(ResponsiveTable);
