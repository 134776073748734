import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';
import ChangeHistorySharpIcon from '@material-ui/icons/ChangeHistorySharp';
import { useDialogState } from '~/modules/common/hooks';
import { CarryOverSummaryDialog } from './CarryOverSummaryDialog';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 'unset',
    color: theme.palette.info.dark,
    background: 'rgb(232, 244, 253)',
    '&:hover': {
      background: 'rgb(232, 244, 253)'
    }
  },
  startIcon: {
    marginRight: 0
  }
}));

const startIcon = <ChangeHistorySharpIcon />;

export const CarryOverSummaryButton = ({
  revenueEntryId,
  revenuePeriodEndDate,
  project
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { open, openDialog, closeDialog } = useDialogState(false);

  return (
    <>
      <Button
        startIcon={startIcon}
        size="small"
        classes={useMemo(
          () => ({
            root: classes.root,
            startIcon: classes.startIcon
          }),
          [classes.root, classes.startIcon]
        )}
        onClick={openDialog}
        aria-label={formatMessage({ id: 'revenueRunSchedule.carryOver' })}
      ></Button>
      {open && (
        <CarryOverSummaryDialog
          revenueEntryId={revenueEntryId}
          revenuePeriodEndDate={revenuePeriodEndDate}
          project={project}
          open={open}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

CarryOverSummaryButton.propTypes = {
  revenueEntryId: PropTypes.string.isRequired,
  revenuePeriodEndDate: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

export default CarryOverSummaryButton;
