import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import User from '~/modules/common/components/User';
import NoProjectManager from './NoProjectManager';

const useStyles = makeStyles(theme => ({
  tooltipContent: {
    margin: 0,
    padding: 0,
    display: 'grid',
    gridTemplateColumns: [['auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: theme.spacing(0.5),
    gridRowGap: theme.spacing(1),
    alignItems: 'center'
  },
  tooltipItem: {
    display: 'contents'
  },
  tooltipLabel: {
    textAlign: 'left',
    fontWeight: 'bold'
  },
  tooltipSkeleton: {
    backgroundColor: 'rgba(255,255,255,0.11)'
  },
  tooltipValue: {
    textAlign: 'left'
  },
  tooltipNoValueName: {
    color: 'inherit'
  },
  tooltipUserList: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  tooltipUserItem: {
    margin: 0,
    padding: theme.spacing(0.5, 0)
  },
  tooltipNoProjectManager: {
    color: 'rgba(255,255,255,0.6)'
  }
}));

const avatarStyles = makeStyles(theme => ({
  avatar: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'center',
    fontSize: '0.9em',
    width: '1.95em',
    height: '1.95em',
    margin: theme.spacing(-0.5, 0.5, -0.5, 0)
  },
  container: { padding: 0 }
}));

const MAX_LIMIT = 20;

export const ProjectManagersTooltipTitle = ({
  intl,
  coManagers,
  coManagerCount,
  projectManager,
  isCoManagerLoading
}) => {
  const classes = useStyles();
  const avatarClasses = avatarStyles();

  const coManagersToShowInList = (coManagers || []).slice(0, MAX_LIMIT);

  return (
    <div className={classes.tooltipContent}>
      <div className={classes.tooltipItem}>
        <span className={classes.tooltipLabel}>{`${intl.formatMessage({
          id: 'projectList.projectLeader'
        })}: `}</span>
        {projectManager ? (
          <User user={projectManager} classes={avatarClasses} size="small" />
        ) : (
          <NoProjectManager
            intl={intl}
            className={classes.tooltipNoProjectManager}
          />
        )}
      </div>
      {coManagerCount > 0 ? (
        <div className={classes.tooltipItem}>
          <span className={classes.tooltipLabel}>{`${intl.formatMessage({
            id: 'projectList.coManagers'
          })}: `}</span>
          {isCoManagerLoading ? (
            <Skeleton width={96} className={classes.tooltipSkeleton} />
          ) : (
            <ul className={classes.tooltipUserList}>
              {coManagersToShowInList.map((coManager, index) => (
                <li key={coManager.id} className={classes.tooltipItem}>
                  <User user={coManager} classes={avatarClasses} size="small" />
                  {coManagers.length > MAX_LIMIT && index === MAX_LIMIT - 1
                    ? `${coManagers.length - MAX_LIMIT} ${intl.formatMessage({
                        id: 'projectList.more'
                      })}...`
                    : null}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : null}
    </div>
  );
};

ProjectManagersTooltipTitle.propTypes = {
  intl: PropTypes.object,
  coManagers: PropTypes.array,
  projectManager: PropTypes.object,
  coManagerCount: PropTypes.number,
  isCoManagerLoading: PropTypes.bool
};

export default ProjectManagersTooltipTitle;
