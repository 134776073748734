import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  viewSummaryButton: {
    margin: theme.spacing(1, 2)
  }
}));

const ViewSummaryButton = ({ onViewSummaryClick }) => {
  const classes = useStyles();

  return (
    <Button
      color="primary"
      onClick={onViewSummaryClick}
      className={classes.viewSummaryButton}
    >
      <FormattedMessage id="advancedRateCard.viewSummary" />
    </Button>
  );
};

ViewSummaryButton.propTypes = {
  onViewSummaryClick: PropTypes.func
};

export default ViewSummaryButton;
