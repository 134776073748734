import {
  AmountFormatter,
  EditableAmountWithExchangeRate,
  StringFormatter,
  AmountWithExchangeRate,
  DueDateFormatter
} from '~/modules/billing-invoicing/common/columnFormatters';

import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';

const getFormatters = editable => ({
  displayText: StringFormatter,
  dueDate: DueDateFormatter,
  balanceAmount: MoneyFormatter,
  allocatedBillAmount: editable
    ? EditableAmountWithExchangeRate
    : AmountWithExchangeRate,
  amount: editable ? AmountFormatter : MoneyFormatter,
  amountTotal: MoneyFormatter
});

export default getFormatters;
