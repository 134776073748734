import { useCallback, useState } from 'react';

export const updateQueryWithPagedResult = (prev, { fetchMoreResult }) => {
  if (
    !fetchMoreResult ||
    (fetchMoreResult.timeAndExpense &&
      (fetchMoreResult.timeAndExpense.count <= 0 ||
        fetchMoreResult.timeAndExpense.records.length === 0))
  )
    return prev;

  return {
    ...fetchMoreResult,
    timeAndExpense: {
      ...fetchMoreResult.timeAndExpense,
      records: prev.timeAndExpense.records.map((record, index) => ({
        ...record,
        rows: [
          ...record.rows,
          ...fetchMoreResult.timeAndExpense.records[index].rows
        ]
      })),
      rowTotals: {
        ...fetchMoreResult.timeAndExpense.rowTotals,
        rows: [
          ...prev.timeAndExpense.rowTotals.rows,
          ...fetchMoreResult.timeAndExpense.rowTotals.rows
        ]
      }
    }
  };
};

export const useTimeAndExpensePaged = ({
  fetchMore,
  onUpdate,
  timeAndExpenseInput
}) => {
  const [page, setPage] = useState(1);

  const loadMore = useCallback(async () => {
    const nextPage = page + 1;

    try {
      await fetchMore({
        variables: {
          timeAndExpenseInput: {
            ...timeAndExpenseInput,
            page: nextPage
          }
        },
        updateQuery: updateQueryWithPagedResult
      });
      setPage(nextPage);
      // eslint-disable-next-line no-empty
    } catch {}
  }, [fetchMore, page, timeAndExpenseInput, setPage]);

  const $onUpdate = useCallback(
    settings => {
      setPage(1);
      onUpdate(settings);
    },
    [setPage, onUpdate]
  );

  return {
    loadMore,
    onUpdate: $onUpdate
  };
};
