export const TIME_AND_EXPENSE_ENTRY_TYPE = {
  NO: 'urn:replicon:time-and-expense-entry-type:no',
  BILLABLE: 'urn:replicon:time-and-expense-entry-type:billable',
  NON_BILLABLE: 'urn:replicon:time-and-expense-entry-type:non-billable',
  BILLABLE_AND_NON_BILLABLE:
    'urn:replicon:time-and-expense-entry-type:billable-and-non-billable'
};

export const TIME_AND_EXPENSE_ENTRY_TYPE_ENUM_FROM_URI = {
  ...Object.entries(TIME_AND_EXPENSE_ENTRY_TYPE).reduce((acc, [key, value]) => {
    acc[value] = key;

    return acc;
  }, {})
};
