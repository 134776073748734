import { useCallback } from 'react';
import { usePutProjectRates } from '~/modules/rateCard/hooks/usePutProjectRates';

const rateUpdate = (setRatesModifier, count) => {
  return setRatesModifier(prevState => {
    return {
      clientRates: true,
      rateCreated: prevState.rateCreated,
      ratesAddedCount: count,
      projectRateCount: prevState.specificRate,
      specificRate: prevState.specificRate
    };
  });
};

export const useCopyFromClient = ({
  setCopyClientRateInProgress,
  defaultRates,
  projectUri,
  setRatesModifier,
  count,
  closeDialog
}) => {
  const { putProjectRates } = usePutProjectRates();
  const copyFromClient = useCallback(async () => {
    setCopyClientRateInProgress(true);
    Object.keys(defaultRates).forEach(async key => {
      await putProjectRates({
        projectId: projectUri,
        rates: defaultRates[key],
        rateTypeKey: key
      });
    });
    setTimeout(() => {
      rateUpdate(setRatesModifier, count);
      setCopyClientRateInProgress(false);
      closeDialog();
    }, 500);
  }, [
    setCopyClientRateInProgress,
    defaultRates,
    putProjectRates,
    projectUri,
    setRatesModifier,
    count,
    closeDialog
  ]);

  return {
    copyFromClient
  };
};
export default useCopyFromClient;
