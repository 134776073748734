import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  FormControl,
  MenuItem,
  OutlinedInput
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';

const useStyles = makeStyles(theme => ({
  formControl: {},
  root: {
    margin: 0
  },
  select: {
    fontSize: theme.typography.body2.fontSize,
    minHeight: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1, 3, 1, 1.5),
    color: theme.palette.text.secondary
  }
}));

export const PERIOD_DROPDOWN_SCALE_ENUM = {
  YEARS: PERIOD_SCALE_ENUM.YEARS,
  QUARTERS: PERIOD_SCALE_ENUM.QUARTERS,
  MONTHS: PERIOD_SCALE_ENUM.MONTHS,
  WEEKS: PERIOD_SCALE_ENUM.WEEKS,
  DAYS: PERIOD_SCALE_ENUM.DAYS,
  ENTIRE_PROJECT: PERIOD_SCALE_ENUM.ENTIRE_PROJECT
};

export const DEFAULT_PERIOD_SCALE = PERIOD_SCALE_ENUM.MONTHS;

const outlinedInput = <OutlinedInput labelWidth={0} />;

const defaultOptions = {
  days: {
    value: PERIOD_DROPDOWN_SCALE_ENUM.DAYS,
    messageId: 'periodDropdown.day',
    dataQeId: 'dayOption'
  },
  weeks: {
    value: PERIOD_DROPDOWN_SCALE_ENUM.WEEKS,
    messageId: 'periodDropdown.week',
    dataQeId: 'weekOption'
  },
  months: {
    value: PERIOD_DROPDOWN_SCALE_ENUM.MONTHS,
    messageId: 'periodDropdown.month',
    dataQeId: 'monthOption'
  },
  quarters: {
    value: PERIOD_DROPDOWN_SCALE_ENUM.QUARTERS,
    messageId: 'periodDropdown.quarter',
    dataQeId: 'quarterOption'
  },
  years: {
    value: PERIOD_DROPDOWN_SCALE_ENUM.YEARS,
    messageId: 'periodDropdown.year',
    dataQeId: 'yearOption'
  },
  entireProject: {
    value: PERIOD_DROPDOWN_SCALE_ENUM.ENTIRE_PROJECT,
    messageId: 'periodDropdown.entireProject',
    dataQeId: 'entireProjectOption'
  }
};

export const PeriodDropdown = ({
  classes: classesOverrides,
  value,
  onChange,
  showYears = true,
  showMonths = true,
  showDays = true,
  showQuarters = true,
  showWeeks = true,
  showEntireProject = false,
  dataQeId,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const menuProps = useMemo(
    () => ({
      MenuListProps: {
        dense: true,
        'aria-label': 'periodDropdown',
        ...(dataQeId && { 'data-qe-id': `${dataQeId}_PeriodDropdownList` })
      },
      PaperProps: {
        role: 'main'
      }
    }),
    [dataQeId]
  );

  const handleChange = useCallback(
    event => {
      event.target.value && onChange(event.target.value);
    },
    [onChange]
  );

  const options = [
    ...(showDays ? [defaultOptions.days] : []),
    ...(showWeeks ? [defaultOptions.weeks] : []),
    ...(showMonths ? [defaultOptions.months] : []),
    ...(showQuarters ? [defaultOptions.quarters] : []),
    ...(showYears ? [defaultOptions.years] : []),
    ...(showEntireProject ? [defaultOptions.entireProject] : [])
  ];

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        input={outlinedInput}
        data-qe-id="PeriodDropdown"
        onChange={handleChange}
        classes={useMemo(
          () => ({ root: classes.root, select: classes.select }),
          [classes.root, classes.select]
        )}
        MenuProps={menuProps}
        {...rest}
      >
        {options.map(
          ({ value: menuValue, messageId, dataQeId: optionDataQeId }) => (
            <MenuItem
              key={messageId}
              value={menuValue}
              data-qe-id={optionDataQeId}
            >
              <FormattedMessage id={messageId} />
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

PeriodDropdown.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataQeId: PropTypes.string,
  showYears: PropTypes.bool,
  showMonths: PropTypes.bool,
  showDays: PropTypes.bool,
  showQuarters: PropTypes.bool,
  showWeeks: PropTypes.bool,
  showEntireProject: PropTypes.bool
};

export default PeriodDropdown;
