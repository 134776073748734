import { useIntl } from 'react-intl';
import { BillStatus } from '~/types';

export const status = intl => [
  {
    id: BillStatus.Draft,
    label: intl.formatMessage({
      id: 'billingTransactionList.statusLabel.draft'
    })
  },
  {
    id: 'ISSUED_UNPAID',
    label: intl.formatMessage({
      id: 'billingTransactionList.statusLabel.issuedUnpaid'
    })
  },
  {
    id: 'ISSUED_PARTIALLYPAID',
    label: intl.formatMessage({
      id: 'billingTransactionList.statusLabel.issuedPartiallyPaid'
    })
  },
  {
    id: 'ISSUED_PAID',
    label: intl.formatMessage({
      id: 'billingTransactionList.statusLabel.issuedPaid'
    })
  }
];

const mappedBillStatus = intl =>
  status(intl).map(s => ({
    ...s,
    value: s.id,
    label: s.label,
    key: s.label,
    name: s.label
  }));

export const useBillStatus = () => {
  const intl = useIntl();

  return {
    searchBillStatus: mappedBillStatus(intl)
  };
};

export const searchBillStatus = ({ intl }) => async (searchTerm = '') =>
  mappedBillStatus(intl).filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const useSearchableBillStatus = () => {
  const intl = useIntl();

  return {
    searchBillStatus: searchBillStatus({
      intl
    })
  };
};

export default useBillStatus;
