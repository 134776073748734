import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import common from '@material-ui/core/colors/common';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(0, 0, 0, 1)
  },
  rootLegend: {
    display: 'flex',
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    alignSelf: 'center',
    border: props => `1px ${props.borderStyle} ${props.borderColor}`
  },
  legendText: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 0, 0, 1)
  },
  legendBackgroundColor: {
    backgroundColor: props => props.color
  },
  legendFontColor: {
    color: theme.palette.text.primary
  },
  legendCustom: props => props.custom
}));

export const ChartLegend = ({
  classes: classesOverride,
  label,
  color,
  labelColor,
  borderColor = common.white,
  borderStyle = 'solid',
  custom = {}
}) => {
  const classes = useStyles({
    classes: classesOverride,
    color,
    labelColor,
    borderColor,
    borderStyle,
    custom
  });

  return Object.keys(custom).length ? (
    <div className={classes.container}>
      <span className={classes.legendCustom} />
      <Typography
        className={classNames(classes.legendText, classes.legendFontColor)}
      >
        {label}
      </Typography>
    </div>
  ) : (
    <div className={classes.container}>
      <span
        className={classNames(
          classes.rootLegend,
          classes.legendBackgroundColor
        )}
      />
      <Typography
        className={classNames(classes.legendText, classes.legendFontColor)}
      >
        {label}
      </Typography>
    </div>
  );
};

ChartLegend.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  color: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  borderStyle: PropTypes.string,
  borderColor: PropTypes.string,
  custom: PropTypes.object
};

export default ChartLegend;
