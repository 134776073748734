import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { getError, hasError } from '~/util';
import { DateField } from '~/modules/common/components';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { useFormOnChangeHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  approvalCheckbox: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const CertificateEditable = ({ submitError, readOnly }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { values, errors, setFieldValue } = useFormikContext();
  const {
    name,
    issuer,
    issueDate,
    expiryDate,
    credentialId,
    credentialUrl,
    doesNotExpire
  } = values;
  const {
    onNameChange,
    onIssuerChange,
    onExpiresChange,
    onIssueDateChange,
    onExpiryDateChange,
    onCredentialIdChange,
    onCredentialURLChange
  } = useFormOnChangeHandlers({ values, setFieldValue });

  const fieldLabels = {
    name: formatMessage({ id: 'resourceCertificatesAndLicensesCard.name' }),
    issuer: formatMessage({
      id: 'resourceCertificatesAndLicensesCard.issuingOrganization'
    }),
    issueDate: formatMessage({
      id: 'resourceCertificatesAndLicensesCard.issueDate'
    }),
    expiryDate: formatMessage({
      id: 'resourceCertificatesAndLicensesCard.expiryDate'
    }),
    doesNotExpire: formatMessage({
      id: 'resourceCertificatesAndLicensesCard.doesntExpire'
    }),
    credentialId: formatMessage({
      id: 'resourceCertificatesAndLicensesCard.credentialId'
    }),
    credentialUrl: formatMessage({
      id: 'resourceCertificatesAndLicensesCard.credentialUrl'
    })
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          data-qe-id="LicenseNameInput"
          variant="filled"
          required
          disabled={readOnly}
          label={fieldLabels.name}
          inputProps={getAriaLabel(fieldLabels.name)}
          value={name || ''}
          onChange={onNameChange}
          fullWidth
          error={hasError(errors, 'name') || hasError(submitError, 'reason')}
          helperText={
            getError(errors, 'name') || getError(submitError, 'reason')
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          data-qe-id="LicenseIssuerInput"
          variant="filled"
          disabled={readOnly}
          label={fieldLabels.issuer}
          inputProps={getAriaLabel(fieldLabels.issuer)}
          value={issuer || ''}
          onChange={onIssuerChange}
          fullWidth
          error={hasError(errors, 'issuer')}
          helperText={getError(errors, 'issuer')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <DateField
          dataQeId="IssueDateInput"
          variant="filled"
          label={fieldLabels.issueDate}
          ariaLabel={fieldLabels.issueDate}
          value={issueDate}
          onChange={onIssueDateChange}
          editable
          readOnlyComponent="TextField"
          disabled={readOnly}
          useDateFieldStyles={false}
          error={hasError(errors, 'issueDate')}
          helperText={getError(errors, 'issueDate')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <DateField
          dataQeId="ExpiryDateInput"
          variant="filled"
          label={fieldLabels.expiryDate}
          ariaLabel={fieldLabels.expiryDate}
          value={expiryDate}
          disabled={!!doesNotExpire || readOnly}
          editable
          readOnlyComponent="TextField"
          useDateFieldStyles={false}
          minDate={
            issueDate ? mapRepliconDateToUtcObject(issueDate) : undefined
          }
          onChange={onExpiryDateChange}
          error={hasError(errors, 'expiryDate')}
          helperText={getError(errors, 'expiryDate')}
        />
      </Grid>
      <Grid className={classes.approvalCheckbox} item xs={12} sm={6}>
        <FormControlLabel
          disabled={readOnly}
          control={
            // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
            <Checkbox
              data-qe-id="LicenseExpireCheckbox"
              checked={!!doesNotExpire}
              onChange={onExpiresChange}
              color="primary"
              value="primary"
            />
          }
          label={fieldLabels.doesNotExpire}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          data-qe-id="LicenseCredentialIdInput"
          variant="filled"
          disabled={readOnly}
          label={fieldLabels.credentialId}
          inputProps={getAriaLabel(fieldLabels.credentialId)}
          value={credentialId || ''}
          onChange={onCredentialIdChange}
          fullWidth
          error={hasError(errors, 'credentialId')}
          helperText={getError(errors, 'credentialId')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          data-qe-id="LicenseCredentialUrlInput"
          type="url"
          variant="filled"
          disabled={readOnly}
          label={fieldLabels.credentialUrl}
          inputProps={getAriaLabel(fieldLabels.credentialUrl)}
          value={credentialUrl || ''}
          onChange={onCredentialURLChange}
          fullWidth
          error={hasError(errors, 'credentialUrl')}
          helperText={getError(errors, 'credentialUrl')}
        />
      </Grid>
    </Grid>
  );
};

CertificateEditable.propTypes = {
  readOnly: PropTypes.bool,
  submitError: PropTypes.shape({
    type: PropTypes.string,
    reason: PropTypes.string
  })
};

export default CertificateEditable;
