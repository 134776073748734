import get from 'lodash.get';

export const useErrors = ({
  errors,
  errorKey,
  index,
  entryId,
  rateTypeId,
  rateTypeReferenceKey
}) => ({
  rateTypeValidationErrors: () => {
    const validationError = get(
      errors,
      `${errorKey}.[${index}].${rateTypeReferenceKey}`
    );

    if (validationError)
      return {
        hasError: true,
        helperText: validationError
      };

    const hasErrorInner =
      rateTypeId &&
      errors[errorKey] &&
      errors[errorKey].duplicates &&
      errors[errorKey].duplicates.some(rid => rid === rateTypeId);
    const helperTextInner = hasErrorInner ? errors[errorKey].error : null;

    return { hasError: hasErrorInner, helperText: helperTextInner };
  },

  dateValidationErrors: () => {
    const validationError = get(
      errors,
      `${errorKey}.[${index}].billingScheduleEntries`
    );
    const hasError =
      get(validationError, 'entryWithValidationError') === entryId;

    return {
      error: hasError,
      helperText: hasError ? get(validationError, 'error') : ''
    };
  }
});
