import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { SearchBox } from '~/modules/common/components';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import useProjectFacets from '~/modules/projects/useFacets';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2)
  }
}));

export const ProjectSearchBox = ({ classes }) => {
  const cls = useStyles({ classes });
  const {
    searchCriteria,
    setSearchCriteria,
    setCurrentPage
  } = useProjectsContext();
  const setSearchCriteriaWithPageReset = useCallback(
    props => {
      setSearchCriteria(props);
      setCurrentPage(1);
    },
    [setSearchCriteria, setCurrentPage]
  );
  const { facets, showCostOverBudgetOptions } = useProjectFacets();

  return (
    <div className={cls.root}>
      <SearchBox
        dataQeId="Projects-SearchBar"
        facets={facets}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteriaWithPageReset}
        showCostOverBudgetOptions={showCostOverBudgetOptions}
      />
    </div>
  );
};

ProjectSearchBox.propTypes = {
  classes: PropTypes.object
};

export default ProjectSearchBox;
