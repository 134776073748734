import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    fontWeight: 400
  }
}));

export const ReleaseResourceRequestDialogTitle = ({
  role,
  onClose,
  headerId
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <>
        <FormattedMessage
          id={
            headerId || 'releaseResourceRequestDialog.releaseResourcesTitleNew'
          }
          values={{
            role: role
              ? `${role.displayText}`
              : `${intl.formatMessage({
                  id: 'releaseResourceRequestDialog.noRole'
                })}`,
            span: chunks => <span className={classes.title}>{chunks}</span>
          }}
        />
      </>
      <IconButton
        aria-label={intl.formatMessage({
          id: 'releaseResourceRequestDialog.close'
        })}
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

ReleaseResourceRequestDialogTitle.propTypes = {
  role: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  headerId: PropTypes.string
};

export default ReleaseResourceRequestDialogTitle;
