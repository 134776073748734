import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Divider, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Waypoint } from 'react-waypoint';
import { NoDataItem } from '~/modules/common/components';
import { RateEditor } from './RateEditor';

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(1)
  },
  addButton: {
    paddingLeft: 0
  },
  noData: {
    marginTop: 'auto',
    marginBottom: 'auto',
    height: theme.spacing(5),
    fontSize: '1rem'
  }
}));

const rateTypeReferenceKeys = {
  projectRate: 'projectReference',
  roleRates: 'roleReference',
  userRates: 'userReference'
};

export const RateCardEditor = ({
  rates,
  rateTypeKey,
  rateEditHandlers,
  errors,
  setFieldValue,
  hasMoreResourceRateRows,
  hasMoreRoleRateRows,
  loadMoreRows,
  projectId,
  projectSlug,
  context
}) => {
  const loadMore = useCallback(() => {
    loadMoreRows({ rateTypeKey });
  }, [loadMoreRows, rateTypeKey]);
  const classes = useStyles();
  const hasRates = useMemo(
    () =>
      rates.some(
        r => r && r.billingScheduleEntries && r.billingScheduleEntries.length
      ),
    [rates]
  );

  const onAddRate = useCallback(
    () => rateEditHandlers.onAddNewRate({ field: `${rateTypeKey}`, rates }),
    [rates, rateTypeKey, rateEditHandlers]
  );

  return (
    <>
      {rates.map((rate, index) => {
        const scheduleEntries = rate.billingScheduleEntries;
        const rateTypeReference = rate[`${rateTypeReferenceKeys[rateTypeKey]}`];

        return scheduleEntries.length ? (
          <>
            <RateEditor
              key={rate.id}
              rateIndex={index}
              scheduleEntries={scheduleEntries}
              rateTypeReference={rateTypeReference}
              rateTypeReferenceKey={rateTypeReferenceKeys[rateTypeKey]}
              rateTypeKey={rateTypeKey}
              errors={errors}
              hasMoreRows={
                (rateTypeKey === 'roleRates' && hasMoreRoleRateRows) ||
                (rateTypeKey === 'userRates' && hasMoreResourceRateRows)
              }
              loadMoreRows={loadMoreRows}
              setFieldValue={setFieldValue}
              projectId={projectId}
              projectSlug={projectSlug}
              context={context}
            />
            <Divider variant="fullWidth" className={classes.divider} />
          </>
        ) : null;
      })}
      {((rateTypeKey === 'roleRates' && hasMoreRoleRateRows) ||
        (rateTypeKey === 'userRates' && hasMoreResourceRateRows)) &&
        loadMoreRows && (
          <Waypoint
            bottomOffset="-20%"
            onEnter={loadMore}
            key={(rates || []).length}
          />
        )}
      {!hasRates && (
        <div className={classes.noData}>
          <NoDataItem>
            <FormattedMessage
              id={`rateCard.noData.${context}.${rateTypeKey}`}
            />
          </NoDataItem>
        </div>
      )}
      {rateTypeKey !== 'projectRate' && (
        <div>
          <Button
            className={classes.addButton}
            color="primary"
            onClick={onAddRate}
          >
            <FormattedMessage id="rateCard.addRate" />
          </Button>
        </div>
      )}
    </>
  );
};

RateCardEditor.propTypes = {
  rates: PropTypes.array.isRequired,
  rateTypeKey: PropTypes.string,
  rateEditHandlers: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  hasMoreResourceRateRows: PropTypes.bool,
  hasMoreRoleRateRows: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  projectId: PropTypes.string,
  projectSlug: PropTypes.string,
  context: PropTypes.string
};

export default RateCardEditor;
