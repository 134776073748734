import React, { useState, useCallback, useMemo } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { RemoveCircleSharp } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { useIsBreakpointDown } from '~/modules/common/hooks';
import TagKeyDropdown from '~/modules/tags/components/Dropdowns/TagKeyDropdown';
import useIsTagOptionDisabled from '~/modules/tags/hooks/useIsTagOptionDisabled';
import SelectTagButton from './SelectTagButton';
import TagFacetDetails from './TagFacetDetails';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  ListItem: {
    height: 27,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 0,
    paddingRight: 0
  },
  list: {
    flexGrow: 1,
    flexShrink: 1
  },
  tagFacetTitle: {
    marginRight: 16,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: 0,
    flexGrow: 0,
    flexBasis: '64px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      margin: '48px 0 0 0'
    }
  }
}));

export const TagsFilter = ({ setSelected, selected }) => {
  const [tagFilters, setTagFilters] = useState(selected.map(t => t.tag));
  const isMobile = useIsBreakpointDown('md');

  const { formatMessage } = useIntl();

  const onClick = useCallback(() => {
    const newFilters = [...tagFilters, {}];

    return setTagFilters(newFilters);
  }, [tagFilters, setTagFilters]);

  const classes = useStyles();

  const onTagKeySelection = useCallback(
    index => selectedOption => {
      const tagsList = [...tagFilters];

      tagsList[index] = selectedOption;

      setTagFilters(tagsList);
    },
    [tagFilters]
  );

  const onTagValueSelection = useCallback(
    (tagKeyInfo, index) => selectedOption => {
      const value = selectedOption
        ? {
            displayText: `${tagKeyInfo.label}+${selectedOption.label}`,
            label: `${tagKeyInfo.label}+${selectedOption.label}`,
            value: selectedOption.value,
            id: selectedOption.value,
            key: `${tagKeyInfo.label}+${selectedOption.label}`,
            tag: tagKeyInfo
          }
        : {};

      const tagsValueList = [...selected];

      tagsValueList[index] = value;

      setSelected(tagsValueList);
    },
    [selected, setSelected]
  );

  const onRemove = useCallback(
    index => () => {
      const tagsValueList = [...selected];
      const newTagFilters = [...tagFilters];

      tagsValueList.splice(index, 1);
      newTagFilters.splice(index, 1);

      setTagFilters(newTagFilters);
      setSelected(tagsValueList);
    },
    [selected, setSelected, tagFilters]
  );

  const data = useMemo(() => ({ label: 'key' }), []);

  const selectedTagKeyIds = selected.map(k => (k.tag ? k.tag.value : null));
  const isTagKeyDisabled = useIsTagOptionDisabled(selectedTagKeyIds);

  const onSelect = useCallback(e => e.preventDefault(), []);

  return (
    <div className={classes.root}>
      <span className={classes.tagFacetTitle}>Tags</span>
      <List className={classes.list} component="div">
        {tagFilters.map((t, i) =>
          Object.keys(t).length === 0 ? (
            <ListItem
              key="new-item"
              className={classes.ListItem}
              onClick={onSelect}
              component="div"
            >
              <TagKeyDropdown
                disableAttachment={isMobile}
                menuPlacement={isMobile ? 'top' : ''}
                onChange={onTagKeySelection(i)}
                isOptionDisabled={isTagKeyDisabled}
              />
              <IconButton
                onClick={onRemove(i)}
                aria-label={formatMessage({ id: 'button.remove' })}
              >
                <RemoveCircleSharp color="action" fontSize="small" />
              </IconButton>
            </ListItem>
          ) : (
            <TagFacetDetails
              onClick={onSelect}
              className={classes.ListItem}
              disableAttachment={isMobile}
              menuPlacement={isMobile ? 'top' : ''}
              key={t.value}
              label={t.tag ? t.tag.label : t.label}
              tagKey={t.value}
              selected={selected[i] && selected[i]}
              onChange={onTagValueSelection(t, i)}
              onRemove={onRemove(i)}
            />
          )
        )}
        <SelectTagButton
          data={data}
          onClick={onClick}
          disabled={
            tagFilters.length > selected.length || selected.some(t => !t.value)
          }
        />
      </List>
    </div>
  );
};

TagsFilter.propTypes = {
  setSelected: PropTypes.func,
  selected: PropTypes.array
};

export default TagsFilter;
