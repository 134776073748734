import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';
import { UserAllocationsSummaryContext } from './UserAllocationsSummaryContext';

export const calculateUserOverAllocatedPeriodsMap = ({
  chartDisplayPeriods,
  resourceAllocationScheduleRules,
  userTaskAllocationsSummaryScheduleRules
}) =>
  chartDisplayPeriods.reduce((acc, period) => {
    const { startDate, endDate } = period;
    const periodResourceAllocationTotalHours = getTotalHoursForDateRangeFromScheduleRules(
      {
        start: startDate,
        end: endDate,
        scheduleRules: resourceAllocationScheduleRules
      }
    );
    const periodUserTaskAllocationTotalHours = getTotalHoursForDateRangeFromScheduleRules(
      {
        start: startDate,
        end: endDate,
        scheduleRules: userTaskAllocationsSummaryScheduleRules
      }
    );

    const periodAvailableTaskAllocationHours =
      periodResourceAllocationTotalHours - periodUserTaskAllocationTotalHours;

    if (periodAvailableTaskAllocationHours < 0) {
      acc[startDate.toISO()] = {
        periodStartDate: startDate,
        periodEndDate: endDate,
        periodUserTaskAllocationTotalHours,
        periodResourceAllocationTotalHours,
        periodAvailableTaskAllocationHours
      };
    }

    return acc;
  }, {});

const UserAllocationsSummaryContextProvider = ({
  resourceAllocationScheduleRules,
  loadingResourceAllocation,
  userTaskAllocationsSummaryScheduleRules,
  userTaskAllocationsSummaryLoading,
  chartDisplayPeriods,
  children
}) => {
  const value = useMemo(
    () => ({
      resourceAllocationScheduleRules,
      loadingResourceAllocation,
      userTaskAllocationsSummaryScheduleRules,
      userTaskAllocationsSummaryLoading,
      userOverAllocatedPeriodsMap:
        userTaskAllocationsSummaryScheduleRules?.length &&
        resourceAllocationScheduleRules?.length
          ? calculateUserOverAllocatedPeriodsMap({
              resourceAllocationScheduleRules,
              userTaskAllocationsSummaryScheduleRules,
              chartDisplayPeriods
            })
          : {}
    }),
    [
      chartDisplayPeriods,
      loadingResourceAllocation,
      resourceAllocationScheduleRules,
      userTaskAllocationsSummaryLoading,
      userTaskAllocationsSummaryScheduleRules
    ]
  );

  return (
    <UserAllocationsSummaryContext.Provider value={value}>
      {children}
    </UserAllocationsSummaryContext.Provider>
  );
};

UserAllocationsSummaryContextProvider.propTypes = {
  children: PropTypes.node,
  resourceAllocationScheduleRules: PropTypes.array,
  loadingResourceAllocation: PropTypes.bool,
  userTaskAllocationsSummaryScheduleRules: PropTypes.array,
  userTaskAllocationsSummaryLoading: PropTypes.bool,
  chartDisplayPeriods: PropTypes.array
};

export default UserAllocationsSummaryContextProvider;
