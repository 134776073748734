import { getChartDates } from '~/modules/common/charts/timeline/calculations';
import {
  getDateRangeFromScheduleRules,
  shouldExcludeDay
} from '~/modules/resourcing/common/util';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me/useMeContext';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';

export const getScheduleHours = (scheduleRules, date) =>
  scheduleRules.reduce((acc, schedule) => {
    const scheduleStartDate = mapIsoStringtoUtcObject(
      schedule.dateRange.startDate
    );
    const scheduleEndDate = mapIsoStringtoUtcObject(schedule.dateRange.endDate);
    const { excludeWeekdays } = schedule.do;

    if (
      date >= scheduleStartDate &&
      date <= scheduleEndDate &&
      !shouldExcludeDay(date, excludeWeekdays)
    ) {
      return (acc + schedule.do.setHours) * (schedule.do.load / 100);
    }

    return acc;
  }, 0);

export const getHoursEqual = (
  chartDates,
  availableScheduleRulesWhatFits,
  scheduleRules
) =>
  chartDates.every(chartDate => {
    const startDate = mapRepliconDateToUtcObject(chartDate.start);
    const endDate = mapRepliconDateToUtcObject(chartDate.end);

    let date = startDate;
    let availableHours = 0;
    let scheduleHours = 0;

    while (date <= endDate) {
      availableHours += getScheduleHours(availableScheduleRulesWhatFits, date);

      scheduleHours += getScheduleHours(scheduleRules, date);
      date = date.plus({ days: 1 });
    }

    return roundToDecimals(availableHours) === roundToDecimals(scheduleHours);
  });

export default ({
  smartFitmentDetails = {},
  scheduleRules,
  isLoading = false,
  scale
}) => {
  const {
    availableScheduleRulesWhatFits = [],
    requestedHours = 0,
    availableHoursWhatFits = 0
  } = smartFitmentDetails || {};

  const me = useMeContext();

  if (isLoading) return false;

  const { startDate, endDate } = getDateRangeFromScheduleRules(scheduleRules);

  const { chartDates: scheduleDates } = getChartDates({
    me,
    chartStartDate: startDate,
    chartEndDate: endDate,
    scale
  });

  const isHoursEqual =
    availableScheduleRulesWhatFits &&
    availableScheduleRulesWhatFits.length > 0 &&
    getHoursEqual(scheduleDates, availableScheduleRulesWhatFits, scheduleRules);

  return !(
    !smartFitmentDetails ||
    (isHoursEqual &&
      roundToDecimals(requestedHours) ===
        roundToDecimals(availableHoursWhatFits))
  );
};
