import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import RequiredCustomFieldValue from './RequiredCustomFieldValue';

const RequiredCustomFields = ({
  customFieldValues,
  errors,
  handleChange,
  setFieldValue
}) => (
  <Grid container spacing={2} justifyContent="space-evenly">
    {customFieldValues &&
      Object.entries(customFieldValues).map(([key, customFieldValue]) => (
        <Grid key={key} item xs={12} md={6}>
          <RequiredCustomFieldValue
            key={key}
            id={key}
            customFieldValue={customFieldValue}
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
      ))}
  </Grid>
);

RequiredCustomFields.propTypes = {
  customFieldValues: PropTypes.object,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default RequiredCustomFields;
