import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { BillStatus } from '~/types';

const billBackgroundColor = {
  FULLY_ALLOCATED: theme.palette.bill.paid.main,
  PARTIALLY_ALLOCATED: theme.palette.bill.partiallyPaid.main,
  UNALLOCATED: theme.palette.bill.unpaid.main,
  DRAFT: theme.palette.bill.draft.main,
  VOID: theme.palette.bill.void.main,
  ISSUED_UNPAID: theme.palette.bill.unpaid.main,
  ISSUED_PAID: theme.palette.bill.paid.main,
  ISSUED_PARTIALLYPAID: theme.palette.bill.partiallyPaid.main
};

const backgroundColor = {
  FULLY_ALLOCATED: theme.palette.payment.allocated.main,
  PARTIALLY_ALLOCATED: theme.palette.payment.partiallyAllocated.main,
  UNALLOCATED: theme.palette.payment.unallocated.main
};

const creditMemoLabel = {
  FULLY_ALLOCATED: 'billingTransactionList.statusLabel.closed',
  PARTIALLY_ALLOCATED: 'billingTransactionList.statusLabel.partiallyClosed',
  UNALLOCATED: 'billingTransactionList.statusLabel.open'
};

const billLabel = {
  FULLY_ALLOCATED: 'billingTransactionList.statusLabel.paid',
  PARTIALLY_ALLOCATED: 'billingTransactionList.statusLabel.partiallyPaid',
  UNALLOCATED: 'billingTransactionList.statusLabel.unPaid',
  DRAFT: 'billingTransactionList.statusLabel.draft',
  VOID: 'billingTransactionList.statusLabel.void',
  ISSUED_UNPAID: 'billingTransactionList.statusLabel.issuedUnpaid',
  ISSUED_PAID: 'billingTransactionList.statusLabel.issuedPaid',
  ISSUED_PARTIALLYPAID: 'billingTransactionList.statusLabel.issuedPartiallyPaid'
};

const paymentLabel = {
  FULLY_ALLOCATED: 'billingTransactionList.statusLabel.allocated',
  PARTIALLY_ALLOCATED: 'billingTransactionList.statusLabel.partiallyAllocated',
  UNALLOCATED: 'billingTransactionList.statusLabel.unallocated'
};

const transactionType = {
  BILL: billLabel,
  PAYMENT: paymentLabel,
  CREDITMEMO: creditMemoLabel
};

const backgroundColors = {
  BILL: billBackgroundColor,
  PAYMENT: backgroundColor,
  CREDITMEMO: backgroundColor
};

export const getStatusStyles = (
  transactionTypeId,
  balanceStatus,
  draftSubStatus
) => ({
  backgroundColor: draftSubStatus?.displayText
    ? backgroundColors[transactionTypeId][BillStatus.Draft]
    : backgroundColors[transactionTypeId][balanceStatus],
  color: theme.palette.common.black
});

export const getStatusLabel = (transactionTypeId, balanceStatus) =>
  transactionType[transactionTypeId][balanceStatus];
