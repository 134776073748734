import React from 'react';
import { PropTypes } from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { MobileListItemLoading } from '~/modules/common/components/DetailsPage';
import { MobileList } from '~/modules/common/components/ListTable/MobileList';
import { MobileResourceAllocationItem } from './components';
import { useStyles } from './useStyles';

const MobileResourceAllocations = ({
  resourceAllocations,
  isEditSwitchEnabled,
  loadMoreRows,
  hasMoreRows,
  loadingRows
}) => {
  const classes = useStyles();

  return (
    <>
      <MobileList
        classes={classes}
        mobileListItem={MobileResourceAllocationItem}
        records={resourceAllocations}
        editable={isEditSwitchEnabled}
        subHeaderId="mobileResourcePlanning.resources"
      />

      {!loadingRows && hasMoreRows && (
        <Waypoint
          fireOnRapidScroll
          bottomOffset="-50%"
          onEnter={loadMoreRows}
        />
      )}
      {loadingRows && <MobileListItemLoading />}
    </>
  );
};

MobileResourceAllocations.propTypes = {
  resourceAllocations: PropTypes.array.isRequired,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  hasMoreRows: PropTypes.bool.isRequired,
  loadingRows: PropTypes.bool.isRequired
};

export default MobileResourceAllocations;
