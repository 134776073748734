import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useResourceOverviewToolbarContext } from '~/modules/resource-management/ResourcingOverview/hooks';
import { Decimal, Percentage } from '~/modules/common/components';
import OkButton from '~/modules/common/components/OkButton';
import { getDateRange } from '../hooks/useResourceAllocationDataPoints';
import {
  resourceDialogLabels,
  projectDialogLabels,
  resourceRequestLabels
} from '../../hooks';
import { useLegendsResource } from '../../hooks/useLegends';
import ResourceAllocationDialogRow from './ResourceAllocationDialogRow';
import { useStyles } from './useStyles';

const useDialogStyles = makeStyles(theme => ({
  paper: {
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: '350px'
    },
    display: 'grid',
    justifyContent: 'stretch',
    justifyItems: 'stretch',
    alignItems: 'baseline',
    gridTemplateColumns: [['auto', 'auto', 'auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0
  }
}));

export const ResourceAllocationDialog = ({
  open,
  onCancel,
  allocationDataHours,
  allocationDataPercentage,
  allocationDataFTE
}) => {
  const { periodScale } = useResourceOverviewToolbarContext();
  const classes = useStyles();

  const resourceRow = useLegendsResource(
    allocationDataHours,
    allocationDataFTE,
    allocationDataPercentage,
    resourceDialogLabels
  );

  const projectRow = useLegendsResource(
    allocationDataHours,
    allocationDataFTE,
    allocationDataPercentage,
    projectDialogLabels
  );

  const resourceRequestRow = useLegendsResource(
    allocationDataHours,
    allocationDataFTE,
    allocationDataPercentage,
    resourceRequestLabels
  );

  const workingFTE =
    allocationDataFTE.totalSchedule -
    allocationDataFTE.holiday -
    allocationDataFTE.timeoff;

  const workingHours =
    allocationDataHours.totalSchedule -
    allocationDataHours.holiday -
    allocationDataHours.timeoff;

  const workingPercent =
    allocationDataPercentage.totalSchedule -
    allocationDataPercentage.holiday -
    allocationDataPercentage.timeoff;

  const allocatedFTE =
    allocationDataFTE.allocated + allocationDataFTE.overAllocated;
  const allocatedHours =
    allocationDataHours.allocated + allocationDataHours.overAllocated;
  const allocatedPercent =
    allocationDataPercentage.allocated + allocationDataPercentage.overAllocated;

  const availabilityFTE = workingFTE - allocationDataFTE.allocated;
  const availabilityHours = workingHours - allocationDataHours.allocated;
  const availabilityPercent =
    workingPercent - allocationDataPercentage.allocated;

  return (
    <Dialog classes={useDialogStyles()} open={open} onClose={onCancel}>
      <DialogTitle>
        <Typography className={classes.dialogTitleText}>
          {getDateRange({
            dateRange: allocationDataHours.dateRange,
            periodScale
          })}
        </Typography>
      </DialogTitle>

      <div className={classes.section}>
        <Typography className={classes.label}>
          <FormattedMessage id="resourcingOverviewChart.dialogSections.resources" />
        </Typography>
        <Typography className={classes.fte}>
          <FormattedMessage id="resourcingOverviewChart.dialogSections.fte" />
        </Typography>
        <Typography className={classes.hours}>
          <FormattedMessage id="resourcingOverviewChart.hours" />
        </Typography>
        <Typography className={classes.percent}>
          <FormattedMessage id="resourcingOverviewChart.percentage" />
        </Typography>
      </div>

      {resourceRow.map(data => (
        <ResourceAllocationDialogRow key={data.label} rowData={data} />
      ))}

      <div className={classes.section}>
        <Typography className={classNames(classes.label, classes.totalLabel)}>
          <FormattedMessage id="resourcingOverviewChart.dialogSections.working" />
        </Typography>
        <Decimal className={classes.fte} value={workingFTE} />
        <Decimal className={classes.hours} value={workingHours} />
        <Percentage className={classes.percent} value={workingPercent} />
      </div>

      <div className={classes.section}>
        <Typography className={classes.label}>
          <FormattedMessage id="resourcingOverviewChart.dialogSections.projectAllocation" />
        </Typography>
      </div>
      {projectRow.map(data => (
        <ResourceAllocationDialogRow key={data.label} rowData={data} showHelp />
      ))}

      <div className={classes.section}>
        <Typography className={classNames(classes.label, classes.totalLabel)}>
          <FormattedMessage id="resourcingOverviewChart.legends.allocated" />
        </Typography>
        <Decimal className={classes.fte} value={allocatedFTE} />
        <Decimal className={classes.hours} value={allocatedHours} />
        <Percentage className={classes.percent} value={allocatedPercent} />
      </div>

      <div className={classes.section}>
        <Typography className={classes.note}>
          <sup>&dagger;</sup>
          <FormattedMessage id="resourcingOverviewChart.footnote" />
        </Typography>
        <Typography
          className={classNames(
            classes.label,
            classes.availabilitySection,
            classes.totalLabel
          )}
        >
          <FormattedMessage id="resourcingOverviewChart.dialogSections.availability" />
        </Typography>

        <Decimal
          className={classNames(classes.fte, classes.availabilitySection)}
          value={availabilityFTE}
        />
        <Decimal
          className={classNames(classes.hours, classes.availabilitySection)}
          value={availabilityHours}
        />
        <Percentage
          className={classNames(classes.percent, classes.availabilitySection)}
          value={availabilityPercent}
        />
      </div>
      <div className={classes.section}>
        <Typography className={classes.label}>
          <FormattedMessage id="resourcingOverviewChart.dialogSections.resourceRequests" />
        </Typography>
      </div>
      {resourceRequestRow.map(data => (
        <ResourceAllocationDialogRow key={data.label} rowData={data} />
      ))}

      <DialogActions className={classes.button}>
        <OkButton variant="text" onClick={onCancel} />
      </DialogActions>
    </Dialog>
  );
};

ResourceAllocationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  allocationDataHours: PropTypes.object,
  allocationDataPercentage: PropTypes.object,
  allocationDataFTE: PropTypes.object
};

export default ResourceAllocationDialog;
