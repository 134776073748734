import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { rate } from './query';

export const GET_BILLING_RATE_FOR_USERS = gql`
  query getBillingRateUserOptions($projectId: String!, $asOfDate: DateObject) {
    project(projectId: $projectId) {
      id
      teamMemberEffectiveBillingRates(asOfDate: $asOfDate) {
        id
        displayText
        ${rate}
      }
    }
  }
`;

const useBillingRateUserOptions = ({ projectId, asOfDate = null }) => {
  const { loading, error, data } = useQuery(GET_BILLING_RATE_FOR_USERS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      projectId,
      asOfDate
    }
  });

  const billingRateUserOptions = get(
    data,
    'project.teamMemberEffectiveBillingRates',
    []
  );

  return {
    loading,
    error,
    billingRateUserOptions
  };
};

export default useBillingRateUserOptions;
