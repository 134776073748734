import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useEffect } from 'react';
import { PropTypes } from 'prop-types';

export const BATCH_QUERY = gql`
  query getBillingBatchStatus($batchId: String!) {
    getBillingBatchStatus(batchId: $batchId) {
      executionState
      overallProgress {
        currentProgress
        maximumProgress
      }
    }
  }
`;

export const BillingBatchStatus = ({
  billingBatchState: { batchId, batchInProgress },
  setBillingBatchState,
  onBatchComplete,
  children
}) => {
  const { stopPolling, data: { getBillingBatchStatus = {} } = {} } = useQuery(
    BATCH_QUERY,
    {
      variables: {
        batchId
      },
      fetchPolicy: 'network-only',
      pollInterval: 500
    }
  );

  const { executionState } = getBillingBatchStatus;

  const hasProcessed =
    executionState === 'SUCCEEDED' ||
    executionState === 'CANCELLED' ||
    executionState === 'FAILED';

  useEffect(() => {
    if (!hasProcessed) return;
    stopPolling();
    setBillingBatchState({
      batchId: null,
      batchInProgress: false
    });
    onBatchComplete({ batchId });
  }, [
    executionState,
    hasProcessed,
    onBatchComplete,
    setBillingBatchState,
    stopPolling,
    batchId
  ]);

  return batchInProgress && children;
};

BillingBatchStatus.propTypes = {
  batchState: PropTypes.object,
  setBatchState: PropTypes.func
};

export default BillingBatchStatus;
