import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'formik';
import { DialogActions } from '@material-ui/core';
import { CancelButton, LoadingButton } from '~/modules/common/components';
import { hasErrors } from '~/util';

export const AddClientDialogActions = ({
  onClose,
  formik: { handleSubmit, handleReset, isSubmitting, errors },
  onCancel
}) => {
  const onCancelClick = useCallback(() => {
    if (typeof onCancel === 'function') onCancel();
    handleReset();
    onClose();
  }, [onCancel, onClose, handleReset]);

  const formError = hasErrors(errors);
  const disabled = isSubmitting || formError;

  return (
    <DialogActions>
      <CancelButton
        dataQeId="AddClientDialog"
        onClick={onCancelClick}
        disabled={isSubmitting}
      />
      <LoadingButton
        color="primary"
        onClick={handleSubmit}
        disabled={disabled}
        isLoading={isSubmitting}
        dataQeId="AddClientDialog"
      >
        <FormattedMessage id="clientList.addClient" />
      </LoadingButton>
    </DialogActions>
  );
};

AddClientDialogActions.propTypes = {
  onClose: PropTypes.func,
  formik: PropTypes.object,
  onCancel: PropTypes.func
};

export default connect(AddClientDialogActions);
