import RateCardIcon from '@material-ui/icons/AttachMoneySharp';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { useMeContext } from '~/modules/me/useMeContext';
import { getTodayForUser } from '~/modules/common/dates/today';
import {
  useReadOnlyBillingRates,
  useEditableBillingRates,
  useBillingRateOptions
} from './hooks';
import {
  useStyles,
  useCardStyles,
  useHeaderStyles
} from './hooks/useBillingRateStyles';
import { BillingRateCardEditable } from './BillingRateCardEditable';

const avatar = <RateCardIcon />;

export const BillingRateCard = ({
  projectUri: projectId,
  projectSlug,
  canViewTeam,
  editable
}) => {
  const me = useMeContext();
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const cardClasses = useCardStyles();

  const {
    effectiveProjectBillingRates: {
      loadingRows: billingRatesLoading,
      projectCurrentEffectiveBillingRates,
      loadMoreRows: loadBillingRates,
      hasMoreRows: hasMoreBillingRates,
      isLoadingMore: isLoadingMoreBillingRates,
      setPage: setPageForBillingRates
    },
    effectiveProjectSpecificBillingRates: {
      loading: loadingSystemRate,
      projectCurrentEffectiveSystemRates
    },
    effectiveProjectUserBillingRates: {
      loadingRows: loadingUserRates,
      userCurrentEffectiveRates,
      loadMoreRows: loadMoreUserBillingRates,
      hasMoreRows: hasMoreUserBillingRates,
      isLoadingMore: isLoadingMoreUserRates,
      setPage: setPageForUserBillingRates
    },
    effectiveRatesCount: { counts: rateCount }
  } = useReadOnlyBillingRates({ projectId });

  const {
    projectSpecificBillingRateSchedules: {
      loading: loadingEditableProjectSpecificRates,
      projectSpecificBillingRateSchedules
    },
    projectBillingRateSchedules: {
      projectBillingRateSchedules,
      loading: loadingEditableBillingRates,
      hasMoreRows: hasMoreEditableBillingRates,
      isLoadingMore: loadingMoreEditableBillingRates,
      loadMoreRows: loadEditableBillingRates
    },
    userBillingRateSchedules: {
      userBillingRateSchedules,
      loading: loadingEditableUserRates,
      hasMoreRows: hasMoreEditableUserRates,
      isLoadingMore: loadingMoreEditableUserRates,
      loadMoreRows: loadEditableUserRates
    }
  } = useEditableBillingRates({
    projectId
  });
  const { billingRateOptions } = useBillingRateOptions({
    projectId,
    asOfDate: getTodayForUser(me)
  });

  const isRatesLoading = useMemo(() => {
    return (
      (billingRatesLoading && !isLoadingMoreBillingRates) ||
      loadingSystemRate ||
      (loadingUserRates && !isLoadingMoreUserRates) ||
      (loadingEditableBillingRates && !loadingMoreEditableBillingRates) ||
      (loadingEditableUserRates && !loadingMoreEditableUserRates) ||
      loadingEditableProjectSpecificRates
    );
  }, [
    billingRatesLoading,
    isLoadingMoreBillingRates,
    isLoadingMoreUserRates,
    loadingEditableBillingRates,
    loadingEditableProjectSpecificRates,
    loadingEditableUserRates,
    loadingMoreEditableBillingRates,
    loadingMoreEditableUserRates,
    loadingSystemRate,
    loadingUserRates
  ]);
  const onBillingRatesSave = useCallback(() => setPageForBillingRates(1), [
    setPageForBillingRates
  ]);
  const onUserBillingRateSave = useCallback(
    () => setPageForUserBillingRates(1),
    [setPageForUserBillingRates]
  );
  const title = useMemo(
    () => (
      <>
        <Typography variant="h6">
          <FormattedMessage id="billingRateCard.title.main" />
        </Typography>
      </>
    ),
    []
  );
  const refetchQueries = useMemo(
    () => ({
      projectSpecific: [
        'getAllProjectSystemRates',
        'getPageOfProjectSpecificBillingRateSchedules',
        'getProjectBillingRateCounts'
      ],
      billingRate: [
        'getAllProjectRates',
        'getPageOfProjectBillingRateSchedules',
        'getProjectBillingRateCounts'
      ],
      userRate: [
        'getPageOfUserBillingRateSchedules',
        'getAllProjectUserRates',
        'getProjectBillingRateCounts'
      ]
    }),
    []
  );

  if (isRatesLoading) return <CardLoading avatar={avatar} title={title} />;

  return (
    <>
      <Card className={classes.root} data-qe-id="RateCard">
        <CardHeader avatar={avatar} title={title} classes={headerClasses} />
        <Divider variant="fullWidth" />
        <CardContent classes={cardClasses}>
          <BillingRateCardEditable
            editable={editable}
            rateType="projectRate"
            projectId={projectId}
            count={rateCount.projectEffectiveSystemRateCount}
            rates={projectCurrentEffectiveSystemRates}
            billingRates={projectSpecificBillingRateSchedules}
            refetchQueries={refetchQueries.projectSpecific}
            addOrRemoveBillingRate={false}
          />
          <BillingRateCardEditable
            editable={editable}
            rateType="billingRate"
            projectId={projectId}
            loadMoreRows={loadBillingRates}
            hasMoreRows={hasMoreBillingRates}
            hasMoreEditableBillingRates={hasMoreEditableBillingRates}
            loadEditableBillingRates={loadEditableBillingRates}
            count={rateCount.projectEffectiveBillingRateCount}
            rates={projectCurrentEffectiveBillingRates}
            billingRates={projectBillingRateSchedules}
            addOrRemoveBillingRate
            billingRateOptions={billingRateOptions}
            onBillingRatesSave={onBillingRatesSave}
            refetchQueries={refetchQueries.billingRate}
          />
          <BillingRateCardEditable
            editable={editable}
            rateType="userRate"
            projectId={projectId}
            loadMoreRows={loadMoreUserBillingRates}
            hasMoreRows={hasMoreUserBillingRates}
            hasMoreEditableBillingRates={hasMoreEditableUserRates}
            loadEditableBillingRates={loadEditableUserRates}
            count={rateCount.userEffectiveBillingRateCount}
            rates={userCurrentEffectiveRates}
            billingRates={userBillingRateSchedules}
            addOrRemoveBillingRate={false}
            onBillingRatesSave={onUserBillingRateSave}
            refetchQueries={refetchQueries.userRate}
          />
          {canViewTeam && (
            <div className={classes.teamRateLinkContainer}>
              <Link
                className={classes.link}
                to={`/projects/${projectSlug}/resourcingplan/timesheet-access`}
              >
                <Typography className={classes.manageTeamRates} color="primary">
                  <FormattedMessage id="billingRateCard.manageTeamRates" />
                </Typography>
              </Link>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

BillingRateCard.propTypes = {
  projectUri: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  canViewTeam: PropTypes.bool,
  projectSlug: PropTypes.string
};

export default BillingRateCard;
