import { DateTime } from 'luxon';

export const COSTS_TYPE_ENUM = {
  ESTIMATED: 'ESTIMATED',
  ACTUALS: 'ACTUALS'
};

export const computeChartPoints = (costSeriesData, type, costCurrency) =>
  costSeriesData.map(
    ({ period: { periodStart }, costAmountEstimated, costAmountActual }) => {
      const costValue =
        type === COSTS_TYPE_ENUM.ESTIMATED
          ? costAmountEstimated || 0
          : costAmountActual || 0;
      const { month } = DateTime.fromISO(periodStart, { zone: 'utc' });

      return {
        month,
        value: costValue,
        label: `${costCurrency.symbol} ${costValue}`
      };
    }
  );

export const useCostChartData = ({ costSeriesData, costCurrency }) => ({
  estimatedDataPoints: computeChartPoints(
    costSeriesData,
    COSTS_TYPE_ENUM.ESTIMATED,
    costCurrency
  ),
  actualDataPoints: computeChartPoints(
    costSeriesData,
    COSTS_TYPE_ENUM.ACTUALS,
    costCurrency
  )
});

export default useCostChartData;
