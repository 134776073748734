import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  container: {
    cursor: ({ onClick }) => (onClick ? 'pointer' : 'default'),
    textDecoration: 'none',
    justifyContent: 'normal'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  colorLegend: {
    display: 'flex',
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    alignSelf: 'center',
    border: props => `1px solid ${props.borderColor}`
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body1.fontSize,
    textDecoration: 'none'
  },
  label: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
    textTransform: 'none'
  },
  itemColor: {
    backgroundColor: props => props.colorScale
  }
}));

export const DonutLegend = ({
  legend: { title, value, colorScale, dataQeId },
  classes: classesOverride,
  onClick
}) => {
  const classes = useStyles({ classes: classesOverride, colorScale, onClick });

  return (
    <Button
      data-qe-id={dataQeId ? `${dataQeId}_legendButton` : undefined}
      className={classes.container}
      onClick={onClick}
    >
      <span className={classNames(classes.colorLegend, classes.itemColor)} />
      <Typography
        component="div"
        className={classNames(classes.container, classes.labelContainer)}
      >
        <Typography className={classes.label} variant="subtitle1">
          {title}
        </Typography>
        <Typography
          data-qe-id={dataQeId ? `${dataQeId}_legendValue` : undefined}
          className={classes.value}
          component="div"
          variant="body2"
        >
          {value}
        </Typography>
      </Typography>
    </Button>
  );
};

DonutLegend.propTypes = {
  legend: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number
    ]),
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number
    ]),
    colorScale: PropTypes.string.isRequired,
    dataQeId: PropTypes.string
  }),
  classes: PropTypes.object,
  onClick: PropTypes.func
};

export default DonutLegend;
