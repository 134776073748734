import { gql } from 'graphql-tag';

export const GET_RESOURCE_PLAN_ACTUALS_USERS = gql`
  query GetResourcePlanActualUsers(
    $projectId: String!
    $filter: ProjectActualSummaryFilter!
    $pageSize: Int!
    $page: Int!
    $isSortByUserAscending: Boolean!
  ) {
    project(projectId: $projectId) {
      id
      resourcePlanActualUsers(
        page: $page
        pageSize: $pageSize
        isSortByUserAscending: $isSortByUserAscending
        filter: $filter
      ) {
        id
        uri
        displayText
      }
    }
  }
`;

export const getResourcePlanActualUsers = async ({
  page,
  pageSize,
  projectId,
  apolloClient,
  filter,
  isSortByUserAscending
}) => {
  const { data } = await apolloClient.query({
    query: GET_RESOURCE_PLAN_ACTUALS_USERS,
    variables: {
      projectId,
      pageSize,
      page: page + 1,
      filter,
      isSortByUserAscending
    }
  });

  return (
    data?.project?.resourcePlanActualUsers.map(actuals => ({
      ...actuals,
      isActualEntry: true
    })) || []
  );
};
