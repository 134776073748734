import React from 'react';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useTaskResourceAllocationSummary } from '~/modules/common/hooks';
import { useInheritStyles } from './useStyles';

const label = <FormattedMessage id="taskResourceAssignments.inheritLabel" />;

export const InheritResources = ({ taskId, checked, onChange }) => {
  const classes = useInheritStyles();
  const {
    loading,
    resourceAllocationSummary
  } = useTaskResourceAllocationSummary({
    taskId
  });

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            color="primary"
            data-qe-id="InheritResources_Checkbox"
          />
        }
        label={label}
        labelPlacement="end"
      />
      {!loading && checked && (
        <Typography
          className={classes.message}
          data-qe-id="InheritResources_Message"
        >
          <FormattedMessage
            id="taskResourceAssignments.inheritMessage"
            values={{ count: resourceAllocationSummary?.count || 0 }}
          />
        </Typography>
      )}
    </div>
  );
};

export default InheritResources;

InheritResources.propTypes = {
  taskId: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};
