import { useCallback } from 'react';
import {
  LINK_SOURCE_URI,
  FILE_SOURCE_URI
} from '~/modules/attachments/uploader/uris';

const mapFileToUpload = file => {
  const fileKeyValue = file.keyValues
    ? file.keyValues.find(
        kv => kv.keyUri === 'urn:replicon:binary-object-keyvalue-key:file-name'
      )
    : null;
  const source = fileKeyValue ? FILE_SOURCE_URI : LINK_SOURCE_URI;
  const mappedFile =
    source === FILE_SOURCE_URI
      ? {
          filename: fileKeyValue.value.text,
          base64Content: file.data.base64Content,
          mimeType: file.data.mimeType,
          size: file.data.size,
          source
        }
      : {
          linkName: file.linkName || file.linkUri,
          linkUri: file.linkUri,
          source
        };

  return mappedFile;
};

const useOnFileUpload = ({ setIsUploading, uploadFilesCallback }) => {
  const onFileUpload = useCallback(
    async files => {
      setIsUploading(true);

      // Links are uploaded as a single object, but files come in arrays
      // to support multi-file uploads
      const filesArray = Array.isArray(files) ? files : [files];
      const mappedFiles = filesArray.map(mapFileToUpload);

      await uploadFilesCallback(mappedFiles)();
    },
    [setIsUploading, uploadFilesCallback]
  );

  return onFileUpload;
};

export default useOnFileUpload;
