import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const ProjectTimeEnteredSummary = gql`
  query GetProjectTimeEnteredSummary($projectId: String!) {
    project(projectId: $projectId) {
      id
      budgetHours
      estimatedHours
      totalAllocatedHours
      timeEnteredSummary {
        timeEnteredActual
        timeEnteredTaskActual
        timeEnteredTaskEstimatedAtCompletion
        timeEnteredAllocatedResourceActual
      }
    }
  }
`;

export const useBudgetHoursSummary = ({ projectId }) => {
  const { loading, data } = useQuery(ProjectTimeEnteredSummary, {
    variables: { projectId },
    fetchPolicy: 'cache-and-network'
  });

  return {
    projectHoursSummary: loading
      ? {}
      : {
          timeEnteredSummary: data?.project?.timeEnteredSummary,
          budgetHours: data?.project?.budgetHours,
          estimatedHours: data?.project?.estimatedHours,
          totalAllocatedHours: data?.project?.totalAllocatedHours
        },
    isSummaryLoading: loading
  };
};

export default useBudgetHoursSummary;
