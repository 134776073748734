import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';

const style = { width: 50 };

export const ReleaseResourceRequestDialogTableHeader = ({
  usersCount,
  selectedCount,
  onSelectAllClick,
  headerId = 'releaseResourceRequestDialog.user'
}) => (
  <TableHead>
    <TableRow>
      <TableCell padding="none" style={style}>
        <Checkbox
          indeterminate={selectedCount > 0 && selectedCount < usersCount}
          checked={selectedCount === usersCount}
          onChange={onSelectAllClick}
        />
      </TableCell>
      <TableCell padding="none">
        <FormattedMessage
          id={headerId}
          values={useMemo(
            () => ({
              count: `(${usersCount})`
            }),
            [usersCount]
          )}
        />
      </TableCell>
      <TableCell padding="none">
        <FormattedMessage id="releaseResourceRequestDialog.allocationDate" />
      </TableCell>
    </TableRow>
  </TableHead>
);

ReleaseResourceRequestDialogTableHeader.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
  selectedCount: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired,
  headerId: PropTypes.string
};

export default ReleaseResourceRequestDialogTableHeader;
