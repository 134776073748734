import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const PROGRAMS_DROPDOWN_QUERY = gql`
  query ProgramDropdownSearch {
    activePrograms {
      displayText
      slug
      id
    }
  }
`;

const programDropdownOptionMapper = program => ({
  label: program.displayText,
  value: program.id,
  slug: program.slug
});

const useProgramDropdownOptionsForProject = skip => {
  const { data, loading } = useQuery(PROGRAMS_DROPDOWN_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip
  });

  return {
    options: loading
      ? []
      : get(data, 'activePrograms', []).map(programDropdownOptionMapper),
    loading
  };
};

export default useProgramDropdownOptionsForProject;
