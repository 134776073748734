import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { DonutChartIcon as StagesIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  title: {
    paddingLeft: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  icon: {
    marginLeft: theme.spacing(1.5)
  }
}));

export const ProjectStatusChartHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <StagesIcon className={classes.icon} />
      <Typography className={classes.title} variant="body2">
        {title}
      </Typography>
    </div>
  );
};

ProjectStatusChartHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default ProjectStatusChartHeader;
