import React from 'react';
import { PropTypes } from 'prop-types';
import EditIcon from '@material-ui/icons/EditSharp';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';

export const EditButton = ({ toggleEditable }) => {
  const intl = useIntl();

  return (
    <IconButton
      data-qe-id="EditButton"
      onClick={toggleEditable}
      aria-label={intl.formatMessage({
        id: 'button.edit'
      })}
    >
      <EditIcon />
    </IconButton>
  );
};

EditButton.propTypes = {
  toggleEditable: PropTypes.func.isRequired
};

export default EditButton;
