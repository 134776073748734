import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const VALIDATE_TASK_NAME_MUTATION = gql`
  mutation validateTaskName($input: ValidateTaskNameInput!) {
    validateTaskName: validateTaskName2(input: $input) {
      isValid
    }
  }
`;

const useValidateTaskName = () => useMutation(VALIDATE_TASK_NAME_MUTATION);

export default useValidateTaskName;
