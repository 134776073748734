import React from 'react';
import Badge from '@material-ui/core/Badge';
import { ResourceRequestIcon } from '~/modules/common/components/Icons';
import { ResourcePlanGroupBy, ResourceRequestAccessLevel } from '~/types';
import {
  useResourcePlanSummaryCounts,
  useResourcePlanSummaryCountRefetchSubscription
} from './common/hooks';

export const MAX_NOTIFICATIONS = 9;

export const ResourcingBadgeIcon = () => {
  const { loading, pendingCount = 0, refetch } = useResourcePlanSummaryCounts({
    resourcePlanGroup: ResourcePlanGroupBy.Project,
    requestAccessLevel: ResourceRequestAccessLevel.ResourceManager
  });

  useResourcePlanSummaryCountRefetchSubscription({ refetch });

  if (!loading && pendingCount > 0) {
    return (
      <Badge
        overlap="rectangular"
        badgeContent={
          pendingCount > MAX_NOTIFICATIONS
            ? `${MAX_NOTIFICATIONS}+`
            : pendingCount
        }
        color="secondary"
        data-qe-id="resourcing_Count"
      >
        <ResourceRequestIcon />
      </Badge>
    );
  }

  return <ResourceRequestIcon />;
};

export default ResourcingBadgeIcon;
