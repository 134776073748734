import React from 'react';
import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';
import { DateFormatter } from './DateFormatter';
import { RateNameFormatter } from './RateNameFormatter';
import { UserFormatter } from './UserFormatter';

export default {
  userReference: UserFormatter,
  roleReference: RateNameFormatter,
  effectiveDate: DateFormatter,
  amount: MoneyFormatter,
  emptyCell: () => <div />
};
