import { getScheduleRulesFromAllocationPeriods } from '~/modules/common/hooks/resourcing';
import {
  getDateRangeFromScheduleRules,
  getTotalHours,
  getTotalHoursForDateRangeFromScheduleRules,
  mergePeriodRulesIntoScheduleRules
} from '~/modules/resourcing/common/util';

import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';

export const getAvailabilityHoursSeriesForFullyAllocated = ({
  resourceAllocationSeriesData,
  scheduleRules
}) =>
  (resourceAllocationSeriesData || []).map(seriesData => {
    const totalAllocatedHours = seriesData.allocated + seriesData.overAllocated;

    const currentAllocatedHours = getTotalHoursForDateRangeFromScheduleRules({
      scheduleRules,
      start: mapRepliconDateToUtcObject(seriesData.dateRange.startDate),
      end: mapRepliconDateToUtcObject(seriesData.dateRange.endDate)
    });

    const availability = Math.max(
      seriesData.totalSchedule -
        seriesData.timeoff -
        seriesData.holiday -
        totalAllocatedHours +
        currentAllocatedHours,
      0
    );

    return {
      hours: availability,
      date: mapRepliconDateToUtcObject(seriesData.dateRange.startDate)
    };
  });

export const getResourceAllocationWithAllocationSeriesData = ({
  resourceAllocationSeriesData,
  scheduleRules,
  allocation,
  newAllocatedHours
}) => {
  const newAvailabilityHoursSeriesByDay = getAvailabilityHoursSeriesForFullyAllocated(
    {
      resourceAllocationSeriesData,
      scheduleRules
    }
  );

  return getResourceAllocation({
    allocation,
    newAllocatedHours,
    availabilityHoursSeriesByDay: newAvailabilityHoursSeriesByDay
  });
};

export const getResourceAllocation = ({
  allocation,
  newAllocatedHours,
  availabilityHoursSeriesByDay
}) => {
  const totalAvailableHours = availabilityHoursSeriesByDay.reduce(
    (acc, entry) => acc + entry.hours,
    0
  );

  const factor =
    totalAvailableHours > 0
      ? newAllocatedHours / totalAvailableHours
      : newAllocatedHours / availabilityHoursSeriesByDay.length;

  const factoredAllocationHoursSeriesByDay = availabilityHoursSeriesByDay.map(
    entry => ({
      date: entry.date,
      hours: totalAvailableHours !== 0 ? entry.hours * factor : factor
    })
  );

  const periodRuleWithUpdatedRules = getScheduleRulesFromAllocationPeriods(
    factoredAllocationHoursSeriesByDay
  );

  const updatedScheduleRules = mergePeriodRulesIntoScheduleRules(
    allocation.scheduleRules,
    true
  )(periodRuleWithUpdatedRules);

  const updatedTotalHours = getTotalHours({
    scheduleRules: updatedScheduleRules
  });

  const dateRange = getDateRangeFromScheduleRules(updatedScheduleRules);

  const alloc = !dateRange
    ? null
    : {
        ...allocation,
        ...dateRange,
        quantity: undefined,
        isAdjustedLoading: true,
        load: 100,
        totalHours: updatedTotalHours,
        __typename: 'ResourceAllocation',
        scheduleRules: updatedScheduleRules
      };

  return { alloc };
};

export default getResourceAllocation;
