import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { TagIcon } from '~/modules/common/components/Icons';
import { tagAssignmentPropType } from '~/modules/common/components/AddTags/PropTypes';
import useStyles from './useStyles';
import useTagStyles from './useTagStyles';

export const TagToolTipContent = ({ tags, classes, intl }) => {
  return (
    <>
      <div className={classes.tooltipTitle}>
        {intl.formatMessage({ id: 'resourceRequestTagField.tags' })}
      </div>
      <div className={classes.tooltipContent}>
        <ul className={classes.detailList}>
          {tags.map(({ tag, value }) => (
            <li key={tag.id} className={classes.assignment}>
              <span>{tag.name}</span>
              <span>{value.value}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

TagToolTipContent.propTypes = {
  tags: PropTypes.arrayOf(tagAssignmentPropType),
  classes: PropTypes.object,
  intl: PropTypes.object
};

export const ResourceRequestTagsField = ({ tags, tooltipClasses }) => {
  const intl = useIntl();
  const classes = useStyles();
  const tagClasses = useTagStyles();

  const title = useMemo(
    () => <TagToolTipContent tags={tags} intl={intl} classes={tagClasses} />,
    [tags, intl, tagClasses]
  );

  return (
    <Tooltip title={title} classes={tooltipClasses}>
      <TagIcon className={classes.detailsIndicator} />
    </Tooltip>
  );
};

ResourceRequestTagsField.propTypes = {
  tags: PropTypes.arrayOf(tagAssignmentPropType),
  tooltipClasses: PropTypes.object
};

export default ResourceRequestTagsField;
