import { useQuery } from '@apollo/client';
import {
  projectTasksQuery,
  DEFAULT_PAGE_SIZE
} from '~/modules/projects/tasks/hooks/useProjectTasks';
import { BILLING_PLAN_SLUG_ENUM } from '~/modules/projects/project/common/enums';

export const useProjectTasksCount = ({ projectSlug, billPlanSlug }) => {
  const projectTasks = useQuery(projectTasksQuery, {
    variables: {
      projectSlug,
      isMilestone: true,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    skip: billPlanSlug !== BILLING_PLAN_SLUG_ENUM.fixedBidMilestone,
    fetchPolicy: 'network-only'
  });
  const { tasksCount = 0 } = projectTasks.data?.project || {};
  const { loading } = projectTasks;

  return { tasksCount, loadingTasksCount: loading };
};
