import { isNullOrUndefined } from '~/modules/common/util';

export const getValues = ({ budget, actual, estimatedAtCompletion }) => {
  const budgetRemaining = !isNullOrUndefined(budget)
    ? budget - (actual || 0)
    : null;
  const estimatedBudgetRemaining =
    !isNullOrUndefined(budget) && !isNullOrUndefined(estimatedAtCompletion)
      ? budget - estimatedAtCompletion
      : null;

  const percentBudgetBurned =
    !isNullOrUndefined(budget) && budget !== 0 ? (actual / budget) * 100 : null;
  const percentScopeCompleted =
    !isNullOrUndefined(estimatedAtCompletion) && estimatedAtCompletion !== 0
      ? (actual / estimatedAtCompletion) * 100
      : null;

  return {
    budgetRemaining,
    estimatedBudgetRemaining,
    percentBudgetBurned,
    percentScopeCompleted
  };
};

export const getProgressHourChartData = ({
  progressChartProps,
  isPsaPpmCostEacAlternateHoursEacEnabled
}) => {
  const { rolledUpSummary, budgetHours } = progressChartProps;

  const actualHours = rolledUpSummary?.actualHours || 0;
  const estimatedAtCompletionHours =
    rolledUpSummary?.totalEstimatedAtCompletionHours;

  const estimatedHoursRemaining = isPsaPpmCostEacAlternateHoursEacEnabled
    ? !isNullOrUndefined(estimatedAtCompletionHours)
      ? estimatedAtCompletionHours - actualHours
      : undefined
    : rolledUpSummary?.totalEstimatedRemainingHours;

  const {
    budgetRemaining,
    estimatedBudgetRemaining,
    percentBudgetBurned,
    percentScopeCompleted
  } = getValues({
    budget: budgetHours,
    actual: actualHours,
    estimatedAtCompletion: estimatedAtCompletionHours
  });

  return {
    actual: actualHours,
    budget: budgetHours,
    estimatedAtCompletion: estimatedAtCompletionHours,
    estimatedBudgetRemaining,
    estimatedRemaining: estimatedHoursRemaining,
    budgetRemaining,
    percentBudgetBurned,
    percentScopeCompleted
  };
};

export const getProgressCostChartData = ({ progressChartProps }) => {
  const {
    budgetedCostAmount,
    actualCostAmount,
    estimatedAtCompletionCostAmount,
    estimatedRemainingCostAmount
  } = progressChartProps;

  const {
    budgetRemaining,
    estimatedBudgetRemaining,
    percentBudgetBurned,
    percentScopeCompleted
  } = getValues({
    budget: budgetedCostAmount,
    actual: actualCostAmount,
    estimatedAtCompletion: estimatedAtCompletionCostAmount
  });

  return {
    budget: budgetedCostAmount,
    actual: actualCostAmount,
    estimatedAtCompletion: estimatedAtCompletionCostAmount,
    estimatedBudgetRemaining,
    estimatedRemaining: estimatedRemainingCostAmount,
    budgetRemaining,
    percentBudgetBurned,
    percentScopeCompleted
  };
};
