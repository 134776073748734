import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useUpdateVendorStatus } from '~/modules/vendors/hooks/useUpdateVendorStatusHandlers';
import { DeleteStatus } from '~/types';

export const DELETE_VENDOR = gql`
  mutation DeleteVendor($id: String!) {
    deleteVendor(id: $id) {
      id
      status
    }
  }
`;

export const useDeleteVendor = ({ objectId: id, objectName: name }) => {
  const { updateVendor } = useUpdateVendorStatus({
    id
  });
  const [deleteVendor] = useMutation(DELETE_VENDOR, {
    onCompleted: data => {
      if (data?.deleteVendor?.status === DeleteStatus.Disabled) {
        updateVendor({ id, name, isActive: false });
      }
    }
  });

  return deleteVendor;
};
