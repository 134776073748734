import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const PUT_TABLE_SETTINGS_MUTATION = gql`
  mutation Eager_PutTableSettings(
    $tableKey: String!
    $columns: [String!]
    $sort: SortSettingInput
  ) {
    putTableSetting2(
      input: { tableKey: $tableKey, columns: $columns, sort: $sort }
    ) {
      tableSetting {
        id
        columns
        sort {
          field
          direction
          customFieldUri
        }
      }
    }
  }
`;

const usePutTableSettings = () => useMutation(PUT_TABLE_SETTINGS_MUTATION);

export default usePutTableSettings;
