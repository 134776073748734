import { useState, useCallback } from 'react';

const useDetailsMenuHandler = () => {
  const [isDetailsMenuVisible, setDetailsMenuVisibility] = useState(false);

  return {
    isDetailsMenuVisible,
    toggleDetailsMenuVisibility: useCallback(
      event => setDetailsMenuVisibility(!isDetailsMenuVisible),
      [isDetailsMenuVisible, setDetailsMenuVisibility]
    ),
    showDetailsMenu: useCallback(event => setDetailsMenuVisibility(true), []),
    hideDetailsMenu: useCallback(event => setDetailsMenuVisibility(false), []),
    handleKeyDown: useCallback(
      event => {
        if (isDetailsMenuVisible && event.key === 'Escape') {
          setDetailsMenuVisibility(false);
          event.stopPropagation();
        }
      },
      [isDetailsMenuVisible]
    )
  };
};

export default useDetailsMenuHandler;
