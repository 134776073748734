import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paperScrollBody: {},

  paperMinWidth_xs: {
    minWidth: Math.max(theme.breakpoints.values.xs, 444),
    '&$paperScrollBody': {
      [theme.breakpoints.down(
        Math.max(theme.breakpoints.values.xs, 444) + 32 * 2
      )]: {
        maxWidth: 'calc(100% - 64px)'
      }
    }
  },
  paperMinWidth_sm: {
    minWidth: theme.breakpoints.values.sm,
    '&$paperScrollBody': {
      [theme.breakpoints.down(theme.breakpoints.values.sm + 32 * 2)]: {
        maxWidth: 'calc(100% - 64px)'
      }
    }
  },
  paperMinWidth_md: {
    minWidth: theme.breakpoints.values.md,
    '&$paperScrollBody': {
      [theme.breakpoints.down(theme.breakpoints.values.md + 32 * 2)]: {
        maxWidth: 'calc(100% - 64px)'
      }
    }
  },
  paperMinWidth_lg: {
    minWidth: theme.breakpoints.values.lg,
    '&$paperScrollBody': {
      [theme.breakpoints.down(theme.breakpoints.values.lg + 32 * 2)]: {
        maxWidth: 'calc(100% - 64px)'
      }
    }
  },
  paperMinWidth_xl: {
    minWidth: theme.breakpoints.values.xl,
    '&$paperScrollBody': {
      [theme.breakpoints.down(theme.breakpoints.values.xl + 32 * 2)]: {
        maxWidth: 'calc(100% - 64px)'
      }
    }
  },
  spacer: {
    flexGrow: 1
  }
}));
