import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  tryLoadCachedResourceRequestsQuery,
  tryLoadCachedResourceRequestQuery
} from '~/modules/resourcing/common/util/resourceRequestUtil';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';
import resourceRequestQuery from '~/modules/resourcing/common/resourceRequestQuery';

export const DELETE_RESOURCE_ALLOCATION = gql`
  mutation DeleteResourceAllocation($input: DeleteResourceAllocationInput!) {
    deleteResourceAllocation2(input: $input) {
      id
    }
  }
`;

export const updateCache = ({ projectUri, resourceRequestId }) => (
  proxy,
  mutationResponse
) => {
  const {
    data: {
      isOptimistic,
      deleteResourceAllocation2: { id: resourceAllocationId }
    }
  } = mutationResponse;

  const cachedResourceRequests = tryLoadCachedResourceRequestsQuery({
    proxy,
    projectUri,
    optimistic: isOptimistic
  });

  const cachedResourceRequest = tryLoadCachedResourceRequestQuery({
    proxy,
    resourceRequestId,
    optimistic: isOptimistic
  });

  if (cachedResourceRequests) {
    const { resourceRequests: data } = cachedResourceRequests;

    proxy.writeQuery({
      query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
      variables: {
        projectUri,
        cursor: null,
        limit: 500
      },
      data: {
        resourceRequests: {
          ...data,
          resourceRequests: data.resourceRequests.map(req =>
            req.id === resourceRequestId
              ? {
                  ...req,
                  resourceAllocations: req.resourceAllocations.filter(
                    sr => sr.id !== resourceAllocationId
                  )
                }
              : req
          )
        }
      }
    });
  }

  if (cachedResourceRequest) {
    const { resourceRequest: data } = cachedResourceRequest;
    const { resourceAllocations: prevResourceAllocations } = data;

    proxy.writeQuery({
      query: resourceRequestQuery,
      variables: {
        id: resourceRequestId
      },
      data: {
        resourceRequest: {
          ...data,
          resourceAllocations: prevResourceAllocations.filter(
            sr => sr.id !== resourceAllocationId
          )
        }
      }
    });
  }
  // only for resource manager flow
  if (!cachedResourceRequest && !cachedResourceRequests) {
    updateDeleteCacheForOnlyResourceManagerFlow({
      proxy,
      resourceRequestId,
      resourceAllocationId
    });
  }
};

export const updateDeleteCacheForOnlyResourceManagerFlow = ({
  proxy,
  resourceRequestId,
  resourceAllocationId
}) => {
  const cachedFragmentId = `ResourceRequest:${resourceRequestId}`;

  const cachedRequest = tryLoadCachedFragment(proxy, cachedFragmentId);

  if (cachedRequest) {
    proxy.writeFragment({
      id: cachedFragmentId,
      fragment: specificResourceRequestWithAllocationsFragment,
      fragmentName: 'SpecificResourceRequestWithAllocation',
      data: {
        ...cachedRequest,
        resourceAllocations: cachedRequest.resourceAllocations.filter(
          c => c.id !== resourceAllocationId
        )
      }
    });
  }
};

const tryLoadCachedFragment = (proxy, fragmentId) => {
  try {
    return proxy.readFragment({
      id: fragmentId,
      fragmentName: 'SpecificResourceRequestWithAllocation',
      fragment: specificResourceRequestWithAllocationsFragment
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);

    return null;
  }
};

export const useDeleteResourceAllocation = ({
  projectUri,
  resourceRequestId
}) => {
  const [deleteResourceAllocation] = useMutation(
    DELETE_RESOURCE_ALLOCATION,
    {}
  );

  return useCallback(
    async ({ resourceAllocationId }) => {
      await deleteResourceAllocation({
        variables: {
          input: {
            id: resourceAllocationId
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          isOptimistic: true,
          deleteResourceAllocation2: {
            __typename: 'DeleteResourceAllocationResult',
            id: resourceAllocationId
          }
        },
        update: updateCache({ projectUri, resourceRequestId })
      });
    },
    [deleteResourceAllocation, projectUri, resourceRequestId]
  );
};
