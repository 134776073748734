import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ChartTotalItems } from '~/modules/common/charts/timeline/components';
import { FormattedHoursAndCost } from '~/modules/resourcing/common/components';

const useStyles = makeStyles(theme => ({
  hours: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  },
  cost: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  currency: {
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const ResourcePlanAllocationTotal = ({
  totalHours,
  totalCosts,
  qeIdTag,
  hideCosts = false
}) => {
  const classes = useStyles();

  const allocated = useMemo(
    () => (
      <FormattedHoursAndCost
        classes={classes}
        hours={totalHours}
        hoursSuffix="h"
        costs={totalCosts}
        hideCosts={hideCosts}
        showAbbreviatedValue={false}
      />
    ),
    [totalCosts, classes, hideCosts, totalHours]
  );

  return <ChartTotalItems qeIdTag={qeIdTag} allocated={allocated} />;
};

ResourcePlanAllocationTotal.propTypes = {
  qeIdTag: PropTypes.string,
  totalHours: PropTypes.number.isRequired,
  totalCosts: PropTypes.array.isRequired,
  hideCosts: PropTypes.bool
};

export default ResourcePlanAllocationTotal;
