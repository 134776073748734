import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import UserItem from '../components/UserACItem';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import { useProgramManagers } from './enhancers/useProgramManagers';

const renderOption = option => <UserItem option={option} />;

export const programManagerLabel = (
  <FormattedMessage id="search.programManager" />
);

export const ProgramManagerFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const { formatMessage } = useIntl();
  const getOptionDisabled = useCallback(
    option => selected.some(v => v.id === option.id),
    [selected]
  );

  const onValueChange = useCallback(
    selectedOptions => onChange({ selectedOptions, setSelected }),
    [setSelected]
  );

  const classes = useStyles();

  const { programManagers, loading } = useProgramManagers();

  const options = (!loading && programManagers) || [];

  return (
    <FacetDetailField label={programManagerLabel} className={className}>
      <SimpleAutocomplete
        options={options}
        variant="standard"
        loading={loading}
        getOptionDisabled={getOptionDisabled}
        hiddenLabel
        noOptionsText={formatMessage({ id: 'program.noProgramManager' })}
        inputLabel={formatMessage({ id: 'search.programManager' })}
        onChange={onValueChange}
        fullWidth
        value={selected}
        optionPropText="label"
        classes={classes}
        multiple
        renderOption={renderOption}
      />
    </FacetDetailField>
  );
};

ProgramManagerFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ProgramManagerFacetDetails;
