import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

export const CreateProjectColumn = ({
  column: { onCreateProject },
  record
}) => {
  const handleClick = useCallback(() => onCreateProject(record), [
    onCreateProject,
    record
  ]);

  return (
    <>
      {record.status && record.status.type === 'APPROVED' ? (
        <Button size="small" onClick={handleClick}>
          <FormattedMessage id="projectRequest.createProject" />
        </Button>
      ) : null}
    </>
  );
};
CreateProjectColumn.propTypes = {
  column: PropTypes.object,
  record: PropTypes.object
};
export default CreateProjectColumn;
