import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ResourceSearchBox } from '~/modules/common/components';
import { ResourceManagementContext } from '../../context';

const useStyles = makeStyles(theme => ({
  search: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      padding: theme.spacing(0, 2, 1, 2)
    },
    backgroundColor: theme.palette.background.paper
  }
}));

export const InnerToolbar = ({
  classes,
  setSearchCriteria,
  searchCriteria,
  setCurrentPage
}) => {
  const setSearchCriteriaWithPageReset = useCallback(
    props => {
      setSearchCriteria(props);
      Boolean(setCurrentPage) && setCurrentPage(1);
    },
    [setSearchCriteria, setCurrentPage]
  );

  return (
    <>
      {setSearchCriteriaWithPageReset && (
        <div className={classes.search}>
          <ResourceSearchBox
            dataQeId="ResourcePool-SearchBar"
            setSearchCriteria={setSearchCriteriaWithPageReset}
            searchCriteria={searchCriteria}
          />
        </div>
      )}
    </>
  );
};

InnerToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func
};

const Toolbar = ({ classes: classesOverride, setCurrentPage }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ResourceManagementContext.Consumer>
      {ctx => (
        <InnerToolbar
          classes={classes}
          {...ctx}
          setCurrentPage={setCurrentPage}
        />
      )}
    </ResourceManagementContext.Consumer>
  );
};

Toolbar.propTypes = {
  classes: PropTypes.object,
  setCurrentPage: PropTypes.func
};

export default Toolbar;
