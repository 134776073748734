import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { makeStyles, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningSharp';

const useStyles = makeStyles(theme => ({
  warningIcon: {
    color: theme.palette.warning.main,
    margin: 'auto 0'
  }
}));

const Warning = ({ message }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Tooltip
      title={message}
      aria-label={intl.formatMessage({ id: 'warning.warning' })}
    >
      <WarningIcon className={classes.warningIcon} />
    </Tooltip>
  );
};

Warning.propTypes = {
  message: PropTypes.string.isRequired
};

export default Warning;
