import { makeStyles, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 0, 0, 1)
  },
  rateSets: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(-0.2)
  },
  definedByDimensions: { marginTop: theme.spacing(-0.15) }
}));

export const AdvancedRateCardReadOnlyContent = ({
  projectRateCount,
  projectDimensions
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const { rateCount, dimensions } = useMemo(
    () => ({
      rateCount: { count: projectRateCount },
      dimensions: {
        dimensions:
          projectDimensions
            .map(d => d.displayText)
            .join(formatMessage({ id: 'advancedRateCard.separator' })) ||
          formatMessage({ id: 'advancedRateCard.defaultRate' })
      }
    }),
    [formatMessage, projectDimensions, projectRateCount]
  );

  return (
    <div className={classes.root}>
      <Typography
        variant="body2"
        className={classes.rateSets}
        data-qe-id="RateSet"
      >
        <FormattedMessage
          id={
            rateCount.count === 1
              ? 'advancedRateCard.rateSet'
              : 'advancedRateCard.ratesSet'
          }
          values={rateCount}
        />
      </Typography>
      &nbsp; &ndash; &nbsp;
      <Typography
        variant="body2"
        className={classes.definedByDimensions}
        data-qe-id="DefinedByDimensions"
      >
        <FormattedMessage
          id="advancedRateCard.definedByDimensions"
          values={dimensions}
        />
      </Typography>
    </div>
  );
};

AdvancedRateCardReadOnlyContent.propTypes = {
  projectRateCount: PropTypes.number,
  projectDimensions: PropTypes.array
};

export default AdvancedRateCardReadOnlyContent;
