import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const VOID_BILL = gql`
  mutation voidBill($input: VoidBillInput!) {
    voidBill(voidBillInput: $input) {
      success
    }
  }
`;

export const useVoidBill = ({ billId }) => {
  const [voidBill] = useMutation(VOID_BILL);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : ['getOutstandingBillSummary'];

  return {
    voidBill: async description => {
      await voidBill({
        variables: {
          input: {
            billId,
            voidBillDescription: description
          }
        },
        refetchQueries: [
          'getBillDetails',
          'getBillTaxLineItems',
          'getBillLineItems',
          'getBillAssociatedPayments',
          'getBillAssociatedCreditMemos',
          'getPageOfBillingTransactions',
          'getBillingTransactionTotals',
          'getVoidedBillCounts',
          'getAvailableBillingItemsTotal',
          'getDraftBillSummary',
          ...queries
        ]
      });
    }
  };
};

export default useVoidBill;
