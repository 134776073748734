import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { TASK_STATUS } from '~/modules/common/enums';
import { useMeContext } from '~/modules/me/useMeContext';
import { getEquivalentRolledUpTaskStatus } from '~/modules/common/components/ProjectDrawer/util';
import Decimal from '~/modules/common/components/Decimal';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  notStarted: {
    color: theme.palette.taskStatus.NOTSTARTED.color
  },
  value: {
    fontWeight: 600,
    fontSize: theme.spacing(1.75)
  },
  noValue: {
    fontWeight: 400
  }
});

export const ActualHoursFormatter = ({ field, record }) => {
  const classes = useStyles();
  const value = record[field];
  const rolledUpTaskStatus = getEquivalentRolledUpTaskStatus({
    status: record.taskStatus,
    actualHours: record.actualHours
  });

  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();

  const taskStatus = isRolledUpTaskEstimateCalculationMethodEnabled
    ? rolledUpTaskStatus
    : record.taskStatus;

  return value !== null ? (
    taskStatus === TASK_STATUS.NOTSTARTED ? (
      <span className={classes.notStarted}>
        <FormattedMessage id="taskDrawer.notStarted" />
      </span>
    ) : (
      <Decimal
        className={value !== 0 ? classes.value : classes.noValue}
        value={value}
        fixedDecimalScale
      />
    )
  ) : (
    <FormattedMessage id="taskDrawer.na" />
  );
};

ActualHoursFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default ActualHoursFormatter;
