import { Input, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ExpandLessSharp, ExpandMoreSharp } from '@material-ui/icons';
import { PropTypes } from 'prop-types';

import { FormSelectField } from '~/modules/common/components';
import { BillingTransactionType, BillStatus } from '~/types';
import { useMenuProps } from '~/modules/common/hooks';
import { getStatusStyles } from '../BillingTransactionStatusBadge/util';
import { ChipLabel } from '../common/ChipLabel';
import { useSubStatusDropdown } from './hooks/useSubStatusDropdown';

const useStyles = (transactionType, billStatus) =>
  makeStyles(theme => ({
    root: {},
    field: () => ({
      ...getStatusStyles(transactionType, billStatus),
      borderRadius: theme.spacing(2),
      fontWeight: 400,
      width: 'min-content'
    }),
    select: {
      fontSize: theme.spacing(1.5),
      fontWeight: 400,
      color: theme.palette.text.main,
      padding: theme.spacing(0.25, 1.5, 0, 1.5),
      width: 'min-content'
    },
    icon: () => ({
      color: theme.palette.text.main,
      right: theme.spacing(0.5)
    }),
    inputBase: {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      padding: 0,
      color: theme.palette.common.main,
      height: theme.spacing(4),
      '&.MuiInputBase-root.Mui-disabled': {
        color: theme.palette.common.main
      },
      justifyContent: 'center',
      '& .MuiSelect-select': {
        backgroundColor: 'transparent'
      }
    }
  }));

const renderValue = currentStatus => () =>
  ChipLabel(
    BillingTransactionType.Bill,
    BillStatus.Draft,
    currentStatus?.text
      ? {
          displayText: currentStatus.text
        }
      : null
  );

const InputBase = classes => (
  <Input className={classes.inputBase} disableUnderline />
);

const getSelectClasses = classes => ({
  icon: classes.icon,
  select: classes.select
});

export const SubStatusDropdown = ({
  billSubStatus = null,
  setFieldValue,
  handleSubmit,
  enabledBillSubStatusList
}) => {
  const classes = useStyles(BillingTransactionType.Bill, BillStatus.Draft)();
  const menuProps = useMenuProps({ messageId: 'billDetails.draftSubStatus' });
  const {
    open,
    currentStatus,
    handleClose,
    handleOpen,
    handleStatusChange
  } = useSubStatusDropdown({
    billSubStatus,
    setFieldValue,
    handleSubmit
  });
  const selectClasses = getSelectClasses(classes);

  return (
    <FormSelectField
      className={classes.field}
      classes={selectClasses}
      value={currentStatus}
      onChange={handleStatusChange}
      onOpen={handleOpen}
      onClose={handleClose}
      input={InputBase(classes)}
      IconComponent={open ? ExpandLessSharp : ExpandMoreSharp}
      renderValue={renderValue(currentStatus)}
      MenuProps={menuProps}
    >
      {enabledBillSubStatusList?.map(option => (
        <MenuItem key={option?.id} value={option}>
          {ChipLabel(BillingTransactionType.Bill, BillStatus.Draft, {
            displayText: option?.text
          })}
        </MenuItem>
      ))}
    </FormSelectField>
  );
};

SubStatusDropdown.propTypes = {
  enabledBillSubStatusList: PropTypes.array,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  billSubStatus: PropTypes.object
};

export default SubStatusDropdown;
