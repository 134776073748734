import { useMeContext } from '~/modules/me';

const NOT_RATED = 'Not Rated';

export const useScoreMetricLables = scoreMetricKey => {
  const { projectRequestScoreScaleDetails } = useMeContext();

  if (!projectRequestScoreScaleDetails) {
    return null;
  }

  const scoreTooltip = projectRequestScoreScaleDetails[scoreMetricKey];
  const result = scoreTooltip.reduce(
    (ret, curr) => ({ ...ret, [curr.scale.toString()]: curr.label }),
    {}
  );

  return { ...result, '0': NOT_RATED };
};

export default useScoreMetricLables;
