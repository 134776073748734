import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { TASK_STATUS } from '~/modules/common/enums';

const useStyles = makeStyles(theme => ({
  alert: {
    padding: theme.spacing(1, 1, 1, 0)
  },
  loading: {
    marginRight: theme.spacing(1)
  },
  error: { color: '#611A15' },
  bulletList: { padding: theme.spacing(1, 3) },
  resultHeader: { fontWeight: 500 }
}));

export const ImpactedTasksBanner = ({
  isLoading,
  canEditTask,
  impactedTasks,
  selectedUsersForReleasing
}) => {
  const classes = useStyles();
  const notStartedTasks = impactedTasks.filter(
    task => task.status === TASK_STATUS.NOTSTARTED
  );
  const inProgressTasks = impactedTasks.filter(
    task => task.status === TASK_STATUS.INPROGRESS
  );

  const taskLabel = useMemo(
    () =>
      notStartedTasks.length + inProgressTasks.length > 1 ? (
        <FormattedMessage id="releaseResourceRequestDialog.tasks" />
      ) : (
        <FormattedMessage id="releaseResourceRequestDialog.task" />
      ),
    [notStartedTasks.length, inProgressTasks.length]
  );

  const notStartedAndInProgressTasksValues = useMemo(
    () => ({
      inProgress: inProgressTasks.length,
      notStarted: notStartedTasks.length,
      taskLabel
    }),
    [taskLabel, notStartedTasks.length, inProgressTasks.length]
  );

  const impactedTasksValues = useMemo(
    () => ({
      count:
        inProgressTasks.length > 0
          ? inProgressTasks.length
          : notStartedTasks.length,
      type:
        inProgressTasks.length > 0 ? (
          <FormattedMessage id="releaseResourceRequestDialog.inProgress" />
        ) : (
          <FormattedMessage id="releaseResourceRequestDialog.notStarted" />
        ),
      taskLabel
    }),
    [taskLabel, notStartedTasks.length, inProgressTasks.length]
  );

  const releaseResourceInfo = ({ user }) => ({
    user: user.displayText
  });

  if (
    !canEditTask &&
    impactedTasks.length > 0 &&
    selectedUsersForReleasing.length > 0
  )
    return (
      <div className={classes.alert}>
        <Alert icon={false} severity="error">
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classNames(classes.error, classes.resultHeader)}
          >
            <FormattedMessage id="releaseResourceRequestDialog.noEditTaskPermissionMessage" />
          </Typography>
          <div className={classes.bulletList}>
            {selectedUsersForReleasing.map(user => (
              <li className={classes.error} key={user.id}>
                <FormattedMessage
                  id="releaseResourceRequestDialog.resourceCanNotRelease"
                  values={releaseResourceInfo({ user })}
                />
              </li>
            ))}
          </div>
        </Alert>
      </div>
    );

  if (isLoading) return <Skeleton height={50} className={classes.loading} />;

  return impactedTasks.length > 0 ? (
    <div className={classes.alert}>
      <Alert icon={false} severity="warning">
        {notStartedTasks.length > 0 && inProgressTasks.length > 0 ? (
          <FormattedMessage
            id="releaseResourceRequestDialog.notStartedAndInProgressTasks"
            values={notStartedAndInProgressTasksValues}
          />
        ) : (
          <FormattedMessage
            id="releaseResourceRequestDialog.impactedTask"
            values={impactedTasksValues}
          />
        )}
      </Alert>
    </div>
  ) : null;
};

ImpactedTasksBanner.propTypes = {
  isLoading: PropTypes.bool,
  canEditTask: PropTypes.bool,
  impactedTasks: PropTypes.array,
  selectedUsersForReleasing: PropTypes.array
};

export default ImpactedTasksBanner;
