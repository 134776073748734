import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const SEND_BILL = gql`
  mutation SendBillByEmail($sendBillEmailInput: SendBillEmailInput!) {
    sendBillByEmail(sendBillEmailInput: $sendBillEmailInput) {
      error {
        reason
      }
    }
  }
`;

export const useSendBill = billId => {
  const [sendBill] = useMutation(SEND_BILL);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : ['getDraftTotals'];

  return {
    sendBill: details => {
      const { emailTo, emailFrom, emailCc, subject, message } = details || {};

      return sendBill({
        variables: {
          sendBillEmailInput: {
            notificationEmailInput: {
              emailTo,
              replyTo: emailFrom,
              ccTo: emailCc,
              subject,
              textBody: message
            },
            billId
          }
        },
        refetchQueries: ['getBillDetails', ...queries],
        awaitRefetchQueries: true
      });
    }
  };
};

export default useSendBill;
