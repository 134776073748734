import { useCallback } from 'react';

export const AFTER_TARGET = 'AFTER_TARGET';
export const BEFORE_TARGET = 'BEFORE_TARGET';
export const CHILD_OF_TARGET = 'CHILD_OF_TARGET';

export const useOnAfterTaskMove = ({ moveTask }) =>
  useCallback(
    gantt => async (id, parent) => {
      const tasks = gantt.getTaskBy(task => task.parent === parent);
      const movedTaskIndex = tasks.findIndex(task => task.id === id);

      const taskBeforeMovedTask = tasks[movedTaskIndex - 1];
      const taskAfterMovedTask = tasks[movedTaskIndex + 1];

      const parentId = parent === 0 ? null : parent;

      const targetId = taskBeforeMovedTask
        ? taskBeforeMovedTask.id
        : taskAfterMovedTask
        ? taskAfterMovedTask.id
        : parentId;

      const method = taskBeforeMovedTask
        ? AFTER_TARGET
        : taskAfterMovedTask
        ? BEFORE_TARGET
        : CHILD_OF_TARGET;

      const error = await moveTask(id, targetId, method);

      if (error) {
        gantt.undo();
      }
    },
    [moveTask]
  );

export default useOnAfterTaskMove;
