import PropTypes from 'prop-types';
import React from 'react';
import { Popper } from '@mui/material';

const FullWidthPopper = ({
  anchorEl,
  children,
  className,
  open,
  role,
  style
}) => (
  <Popper
    anchorEl={anchorEl}
    className={className}
    role={role}
    open={open}
    placement="bottom-start"
    style={style}
  >
    {children}
  </Popper>
);

FullWidthPopper.propTypes = {
  anchorEl: PropTypes.object,
  role: PropTypes.string,
  style: PropTypes.object,
  open: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.object
};

export default FullWidthPopper;
