import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import { useTimeAndExpenseThemeContext } from '../../TimeAndExpenseDetailsThemeContextProvider';
import { GroupedHeadersPlaceholders } from './GroupedHeaders';
import withStyles from './withStyles';

const ToolTipWrapper = ({ condition, value, children }) =>
  condition ? (
    <Tooltip title={value} placement="bottom-start">
      {children}
    </Tooltip>
  ) : (
    children
  );

export const RowGroupingSubColumn = ({
  classes,
  group,
  rows,
  hasMore,
  skeletons,
  formatters,
  heightMap
}) => {
  const themeClasses = useTimeAndExpenseThemeContext();

  return (
    <div className={classes.subcolumn}>
      <div className={classNames(classes.subheader, themeClasses.subheader)}>
        {group.label}
      </div>
      {rows.map(({ groupings }, index) =>
        groupings
          .filter(({ key }) => key === group.key)
          .map(({ value }) => {
            const { key, value: $value, Component } =
              formatters && formatters[group.key]
                ? formatters[group.key](value)
                : { key: value, value };

            return (
              <div key={key} className={classes.cell}>
                <ToolTipWrapper condition={group.key === 'task'} value={$value}>
                  <div
                    className={
                      group.key === 'task' ? classes.taskDatum : classes.datum
                    }
                    style={{ minHeight: `${heightMap[index]}px` }}
                  >
                    {Component || $value || '–'}
                  </div>
                </ToolTipWrapper>
              </div>
            );
          })
      )}
      {hasMore &&
        skeletons.map(value => (
          <div key={value} className={classes.cell}>
            <div className={classes.datum}>
              <Skeleton />
            </div>
          </div>
        ))}
    </div>
  );
};

RowGroupingSubColumn.propTypes = {
  classes: PropTypes.object,
  group: PropTypes.object,
  rows: PropTypes.array,
  hasMore: PropTypes.bool,
  skeletons: PropTypes.array,
  formatters: PropTypes.object,
  heightMap: PropTypes.object
};

export const LeftColumn = ({
  intl,
  classes,
  settings,
  record,
  hasMore,
  skeletons
}) => (
  <div className={classes.leftColumn}>
    <GroupedHeadersPlaceholders record={record} />
    <div className={classes.subcolumns}>
      {settings.rows.options
        .filter(({ key }) => settings.rows.values.includes(key))
        .map(group => (
          <RowGroupingSubColumn
            key={group.key}
            classes={classes}
            group={group}
            formatters={settings.rows.formatters}
            rows={record.rows}
            hasMore={hasMore}
            skeletons={skeletons}
            heightMap={settings.heightMap}
          />
        ))}
    </div>
    <div
      className={classes.footer}
      style={{ minHeight: `${settings.heightMap.footer + 16}px` }}
    >
      {intl.formatMessage({ id: 'timeAndExpenseTab.total' })}
    </div>
  </div>
);

LeftColumn.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  settings: PropTypes.object,
  record: PropTypes.object,
  hasMore: PropTypes.bool,
  skeletons: PropTypes.array
};

ToolTipWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

export default withStyles(LeftColumn);
