import React from 'react';
import { Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { ChartSkeletonRows } from '~/modules/common/charts/timeline/components';

const useLeftComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 40,
    width: 350,
    display: 'flex',
    alignItems: 'center'
  },
  nameContainer: {
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden'
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

export const RowSkeletonLeftComponent = () => {
  const classes = useLeftComponentStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <div className={classes.nameContainer}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={150}
          height={16}
        />
      </div>
    </Paper>
  );
};

export const QuickAllocationUserChartLoading = () => (
  <ChartSkeletonRows LeftComponent={RowSkeletonLeftComponent} />
);

export default QuickAllocationUserChartLoading;
