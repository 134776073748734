import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  DragEndIndicator,
  DragStartIndicator,
  TimelineBlock
} from '~/modules/resourcing/common/chart/components';
import { RESIZE_DIRECTION_ENUM } from '~/modules/resourcing/common/enums';
import { getDayViewRoundedHours } from '~/modules/resourcing/common/util/scheduleUtil';
import { PERIOD_HEIGHT, PERIOD_GAP } from './constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: theme.spacing(2.5),
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  summaryBlockContainer: {
    borderRight: `1px solid ${theme.palette.grey[500]}`
  },
  cursorChange: {
    cursor: 'pointer'
  },
  container: {
    height: theme.spacing(3),
    userSelect: 'none',
    flexShrink: 0,
    borderRadius: theme.spacing(0.25),
    borderTop: `2px dashed ${theme.palette.grey[700]}`,
    borderBottom: `2px dashed ${theme.palette.grey[700]}`
  },
  leftBorder: {
    borderLeft: `2px dashed ${theme.palette.grey[700]}`
  },
  rightBorder: {
    borderRight: `2px dashed ${theme.palette.grey[700]}`
  },
  resizeContainer: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    width: theme.spacing(2.5),
    height: ({ quantity }) =>
      `${quantity * theme.spacing(3) + (quantity - 1) * theme.spacing(0.5)}px `,
    '&:hover $resizeHandle': {
      opacity: 1
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      opacity: 0.8
    }
  },
  resizeHandleEnd: {
    left: `calc(100% - ${theme.spacing(2.5)}px)`
  }
}));
const ResourceRequestTimelineBlock = ({
  rowIndex,
  isEditable,
  scale,
  resourceRequest,
  dragState,
  periodBlockClasses,
  dragIndicatorClasses,
  isPercentageView,
  requestPeriod
}) => {
  const {
    totalHours,
    dynamicPosition,
    hasRequestEnd,
    hasRequestStart
  } = requestPeriod;
  const classes = useStyles({
    quantity: resourceRequest.quantity
  });

  const periodBlockDynamicPosition = useMemo(() => {
    const leftMostPeriod =
      hasRequestStart &&
      dragState?.currentResizeDirection === RESIZE_DIRECTION_ENUM.START;
    const rightMostPeriod =
      hasRequestEnd &&
      dragState?.currentResizeDirection === RESIZE_DIRECTION_ENUM.END;

    return {
      left: leftMostPeriod
        ? dragState.dragDelta + dynamicPosition.left
        : dynamicPosition.left,
      width:
        leftMostPeriod && dragState.dragDelta < 0
          ? dynamicPosition.width - dragState.dragDelta
          : rightMostPeriod
          ? dynamicPosition.width + dragState.dragDelta
          : dynamicPosition.width,
      top:
        rowIndex === 0
          ? dynamicPosition.top
          : dynamicPosition.top +
            PERIOD_HEIGHT * rowIndex +
            (PERIOD_GAP * rowIndex - 1)
    };
  }, [hasRequestStart, dragState, hasRequestEnd, dynamicPosition, rowIndex]);

  const totalAlocatedHours = getDayViewRoundedHours({
    hours: totalHours,
    scale
  });

  return (
    <>
      <div
        className={classNames(classes.root, {
          [classes.cursorChange]: isEditable
        })}
        style={periodBlockDynamicPosition}
      >
        <div
          className={classNames(classes.container, {
            [classes.summaryBlockContainer]: !hasRequestEnd,
            [classes.leftBorder]: hasRequestStart,
            [classes.rightBorder]: hasRequestEnd
          })}
          aria-hidden="true"
        >
          {isPercentageView ? (
            <div className={periodBlockClasses.container} />
          ) : (
            <TimelineBlock
              dataQeId="periodOverlayTimelineBlock"
              hours={totalAlocatedHours}
              showPercentage={false}
              classes={periodBlockClasses}
            />
          )}
        </div>
        {rowIndex === 0 && isEditable && (
          <>
            {hasRequestStart && (
              <div className={classes.resizeContainer}>
                <DragStartIndicator
                  classes={dragIndicatorClasses}
                  gestureBindEvents={dragState.gestureBindEvents}
                  startDate={dragState.dates.startDate}
                  showTooltip
                />
              </div>
            )}
            {hasRequestEnd && (
              <div
                className={classNames(
                  classes.resizeContainer,
                  classes.resizeHandleEnd
                )}
              >
                <DragEndIndicator
                  classes={dragIndicatorClasses}
                  gestureBindEvents={dragState.gestureBindEvents}
                  endDate={dragState.dates.endDate}
                  showTooltip
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

ResourceRequestTimelineBlock.propTypes = {
  rowIndex: PropTypes.number,
  scale: PropTypes.string,
  isEditable: PropTypes.bool,
  resourceRequest: PropTypes.object,
  dragState: PropTypes.object,
  isPercentageView: PropTypes.bool,
  periodBlockClasses: PropTypes.object,
  dragIndicatorClasses: PropTypes.object,
  requestPeriod: PropTypes.object.isRequired
};

export default ResourceRequestTimelineBlock;
