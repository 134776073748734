import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const useStyles = makeStyles(theme => ({
  titleRoot: {
    display: 'flex',
    width: '100%'
  },
  allowedExpenseTypes: {
    fontSize: '0.875rem'
  },
  expenseTypes: {
    marginLeft: 'auto',
    paddingRight: theme.spacing(1)
  }
}));

export const TimeAndExpenseTitleComponent = ({ expenseTypesCount }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleRoot}>
      <Typography variant="subtitle2" className={classes.allowedExpenseTypes}>
        <FormattedMessage id="projectTimeAndExpenseCard.allowedExpenseTypes" />
      </Typography>
      <Typography variant="caption" className={classes.expenseTypes}>
        <FormattedMessage
          id="projectTimeAndExpenseCard.expenseTypes"
          values={expenseTypesCount}
        />
      </Typography>
    </div>
  );
};

TimeAndExpenseTitleComponent.propTypes = {
  expenseTypesCount: PropTypes.object
};
