import React from 'react';
import { LiveMessenger } from 'react-aria-live';

export const announcePoliteHandler = ({ announcePolite }) => (
  message,
  delay
) => {
  if (delay) {
    setTimeout(() => announcePolite(message), delay);
  } else {
    announcePolite(message);
  }
};

export const announceAssertiveHandler = ({ announceAssertive }) => (
  message,
  delay
) => {
  if (delay) {
    setTimeout(() => announceAssertive(message), delay);
  } else {
    announceAssertive(message);
  }
};

const withA11yMessenger = Base => props => (
  <LiveMessenger>
    {({ announceAssertive, announcePolite }) => (
      <Base
        announceAssertiveHandler={announceAssertiveHandler({
          announceAssertive
        })}
        announcePoliteHandler={announcePoliteHandler({ announcePolite })}
        {...props}
      />
    )}
  </LiveMessenger>
);

export default withA11yMessenger;
