import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { mapTaxRatesForTaxProfile } from './util';

export const GET_TAX_RATES = gql`
  query getTaxRatesForTaxProfile($taxProfileUri: String, $asOfDate: Date2) {
    taxRatesForTaxProfile: taxRatesForTaxProfile2(
      taxProfileUri: $taxProfileUri
      asOfDate: $asOfDate
    ) {
      effectiveDate: effectiveDate2
      endDate: endDate2
      taxRateKeyValues
    }
  }
`;

export const useTaxRatesForTaxProfile = ({ taxProfileUri, asOfDate }) => {
  const { data, loading, refetch } = useQuery(GET_TAX_RATES, {
    variables: {
      taxProfileUri,
      asOfDate
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const taxRatesForTaxProfile = get(data, 'taxRatesForTaxProfile', {});
  const { taxRateHeaders, taxRateRows } = mapTaxRatesForTaxProfile({
    loading,
    taxRatesForTaxProfile
  });

  return {
    loading,
    refetchTaxRatesForTaxProfile: refetch,
    taxRateHeaders,
    taxRateRows
  };
};

export default useTaxRatesForTaxProfile;
