import { useIntl } from 'react-intl';

export const useResourceLabels = () => {
  const intl = useIntl();

  return {
    getAddEntryLabel: displayText =>
      intl.formatMessage(
        { id: 'advancedRateCard.addDimensionValue' },
        { displayText }
      ),
    getSelectDimensionLabel: displayText =>
      intl.formatMessage(
        { id: 'advancedRateCard.selectDimensionValue' },
        { displayText }
      ),
    defaultLabel: intl.formatMessage({ id: 'advancedRateCard.defaultRate' })
  };
};
