import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, ButtonBase } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: theme.palette.grey[100],
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    },
    width: '100%',
    justifyContent: 'flex-start'
  },
  align: {
    marginRight: theme.spacing(6),
    maxWidth: theme.spacing(4)
  }
}));

export const OverbudgetExpansionRow = ({
  children,
  to,
  onClick,
  classes: overrideClasses
}) => {
  const classes = useStyles({ classes: overrideClasses });
  const component = to ? Link : undefined;

  return (
    <ButtonBase
      className={classes.row}
      onClick={onClick}
      to={to}
      component={component}
    >
      <span className={classes.align} />
      {children}
    </ButtonBase>
  );
};

OverbudgetExpansionRow.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  to: PropTypes.string,
  onClick: PropTypes.func
};

export default OverbudgetExpansionRow;
