import { useMemo } from 'react';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import { abbreviatedDecimalValue } from '../../util';
import useProfitabilitySeriesDataMap from './useProfitabilitySeriesDataMap';

const periodResolution = {
  [PERIOD_SCALE_ENUM.MONTHS]: 'MONTHLY',
  [PERIOD_SCALE_ENUM.WEEKS]: 'WEEKLY',
  [PERIOD_SCALE_ENUM.YEARS]: 'YEARLY',
  [PERIOD_SCALE_ENUM.DAYS]: 'DAILY',
  [PERIOD_SCALE_ENUM.QUARTERS]: 'QUARTERLY'
};

export const useProfitabilityData = ({
  chartDates,
  scale,
  dateRange,
  filter,
  intl,
  useTotalActualCostAmountSeries,
  useTotalActualRevenueAmountSeries
}) => {
  const {
    loading: actualCostLoading,
    totalActualCostAmountSeries
  } = useTotalActualCostAmountSeries({
    dateRange,
    periodResolution: periodResolution[scale],
    filter
  });

  const {
    loading: revenueLoading,
    totalActualRevenueAmountSeries
  } = useTotalActualRevenueAmountSeries({
    dateRange,
    periodResolution: periodResolution[scale],
    filter
  });

  const { profitabilitySeriesDataMap } = useProfitabilitySeriesDataMap({
    periods: chartDates,
    scale,
    totalActualRevenueAmountSeries,
    totalActualCostAmountSeries
  });

  const data = useMemo(
    () =>
      chartDates.map(period => {
        const barValue = profitabilitySeriesDataMap[period.key];
        const sign = Math.sign(barValue);
        const value = sign >= 0 ? { y: barValue } : { y: 0, y0: barValue };
        const currency = intl.formatNumber(barValue, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        });

        return {
          ...period,
          x: period.label,
          ...value,
          label: `${profitabilitySeriesDataMap.currencySymbol} ${currency}`,
          value: abbreviatedDecimalValue(intl, barValue)
        };
      }),
    [chartDates, profitabilitySeriesDataMap, intl]
  );

  return {
    data,
    loading: actualCostLoading || revenueLoading,
    currencySymbol: profitabilitySeriesDataMap.currencySymbol
  };
};

export default useProfitabilityData;
