import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { useListColumns } from './columns';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: ({ cellBorders }) => ({
    width: '100%',
    ...cellBorders
  }),
  tableHeaderCell: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: '#000',
    padding: theme.spacing(1, 1.5),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40
  },
  tableCell: {
    height: 40,
    whiteSpace: 'normal',
    padding: theme.spacing(0.5, 1.5),
    borderBottom: 'none',
    borderTop: `1px solid ${theme.palette.table.border}`
  }
}));

const useCardStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    boxShadow: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const getCellBorders = (rates, rateTypeReferenceKey) =>
  rates.reduce(
    (prev, current, index) => ({
      ...prev,
      [`& tr:nth-child(${index + 1}) td:first-child`]:
        rateTypeReferenceKey !== 'projectReference' &&
        !current[rateTypeReferenceKey]
          ? { borderTop: 'none' }
          : { borderTop: '1px solid rgb(0 0 0 / 12%)' }
    }),
    {}
  );

export const RatesTable = ({ rates, rateTypeReferenceKey }) => {
  const cellBorders = useMemo(
    () => getCellBorders(rates, rateTypeReferenceKey),
    [rateTypeReferenceKey, rates]
  );
  const providerClasses = useListTableThemeProviderStyles({ cellBorders });
  const cardClasses = useCardStyles();
  const columns = useListColumns({ rateTypeReferenceKey });

  return (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTable
        variant="table"
        classes={cardClasses}
        columns={columns}
        records={rates}
      />
    </ListTableThemeProvider>
  );
};

RatesTable.propTypes = {
  rateTypeReferenceKey: PropTypes.string,
  rates: PropTypes.array.isRequired
};

export default RatesTable;
