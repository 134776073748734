import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { billingTransactionType } from '../enums';

const policyValueType = {
  [billingTransactionType.BILL]: 'bill',
  [billingTransactionType.CREDITMEMO]: 'creditMemo',
  [billingTransactionType.PAYMENT]: 'payment'
};

export const GET_REFERENCE_NUMBER_SETTING = gql`
  query getReferenceNumberSetting(
    $includeBill: Boolean!
    $includeCreditMemo: Boolean!
    $includePayment: Boolean!
  ) {
    transactionReferenceNumberSetting {
      id
      displayText
      policyValues {
        bill @include(if: $includeBill) {
          canUseNumberSetting
          autoGeneratedNumber
          editAutoGeneratedNumber
          prefix
          hasLeadingZero
          minimumDigits
          nextNumber
        }
        creditMemo @include(if: $includeCreditMemo) {
          canUseNumberSetting
          autoGeneratedNumber
          editAutoGeneratedNumber
          prefix
          hasLeadingZero
          minimumDigits
          nextNumber
        }
        payment @include(if: $includePayment) {
          canUseNumberSetting
          autoGeneratedNumber
          editAutoGeneratedNumber
          prefix
          hasLeadingZero
          minimumDigits
          nextNumber
        }
      }
    }
  }
`;

export const useReferenceNumberSetting = transactionType => {
  const { data, loading } = useQuery(GET_REFERENCE_NUMBER_SETTING, {
    variables: {
      includeBill: transactionType === billingTransactionType.BILL,
      includeCreditMemo: transactionType === billingTransactionType.CREDITMEMO,
      includePayment: transactionType === billingTransactionType.PAYMENT
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const referenceNumberSetting = get(
    data,
    `transactionReferenceNumberSetting.policyValues.${policyValueType[transactionType]}`,
    {}
  );

  return {
    loading,
    referenceNumberSetting
  };
};

export default useReferenceNumberSetting;
