import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const COUNT_OF_RESOURCE_POOLS_FOR_USER = gql`
  query getCountOfAssignedResourcePoolsForUser(
    $userSlug: String!
    $searchFilter: ResourcePoolSearchFilter!
  ) {
    resource(userSlug: $userSlug) {
      id
      assignedResourcePoolCount(searchFilter: $searchFilter)
    }
  }
`;

const useAssignedResourcePoolCount = ({ userSlug, searchFilter }) => {
  const { loading, data } = useQuery(COUNT_OF_RESOURCE_POOLS_FOR_USER, {
    variables: {
      userSlug,
      searchFilter
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  });

  const resource = get(data, 'resource') || {};
  const { assignedResourcePoolCount } = resource;

  return {
    assignedResourcePoolCount,
    loading
  };
};

export default useAssignedResourcePoolCount;
