import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import BillingItemType from './BillingItemTypeItem';
import { useBillingItemType } from './enhancers/useBillingItemType';

const renderOption = option => <BillingItemType value={option.label} />;

export const BillingItemTypeFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const intl = useIntl();

  const { searchBillingItemType } = useBillingItemType(intl);

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option => selected.some(v => v.id === option.id),
    [selected]
  );

  const classes = useStyles();

  const { formatMessage } = intl;

  return (
    <FacetDetailField
      label={formatMessage({ id: 'search.billingItemType' })}
      className={className}
    >
      <SimpleAutocomplete
        options={searchBillingItemType}
        value={selected}
        variant="standard"
        onChange={onValueChange}
        multiple
        fullWidth
        classes={classes}
        hiddenLabel
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        optionPropText="label"
      />
    </FacetDetailField>
  );
};

BillingItemTypeFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default BillingItemTypeFacetDetails;
