/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDialogState } from '~/modules/common/hooks';
import CertificateReadOnly from './CertificateReadOnly';
import CertificateDialog from './EditCertificateDialog';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(-2)
  }
}));

export const EditCertificate = ({ userUri, editable, certificate }) => {
  const classes = useStyles();
  const {
    open: dialogOpen,
    openDialog: onEdit,
    closeDialog: onCancel
  } = useDialogState(false);

  return (
    <div className={classes.container}>
      <CertificateReadOnly
        certificate={certificate}
        editable={editable}
        onEdit={onEdit}
      />
      <CertificateDialog
        certificate={certificate}
        open={dialogOpen}
        onCancel={onCancel}
        userUri={userUri}
      />
    </div>
  );
};

EditCertificate.propTypes = {
  editable: PropTypes.bool.isRequired,
  certificate: PropTypes.object,
  userUri: PropTypes.string
};

export default EditCertificate;
