import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { CustomFieldValueFragmentDoc } from '~/types';
import specificResourceFragment from '~/modules/resource-management/enhancers/specificResourceFragment';

export const UPDATE_USER_CUSTOM_FIELDS_MUTATION = gql`
  mutation UpdateUserCustomFields($input: UpdateUserCustomFieldInput!) {
    updateUserCustomFields2(input: $input) {
      customFieldValues {
        ...CustomFieldValue
      }
    }
  }
  ${CustomFieldValueFragmentDoc}
`;

export const updateCache = userUri => (proxy, mutationResponse) => {
  const {
    data: {
      updateUserCustomFields2: { customFieldValues }
    }
  } = mutationResponse;

  const cachedFragmentId = `ResourceDetails:${userUri}`;

  const cachedUser = proxy.readFragment({
    id: cachedFragmentId,
    fragment: specificResourceFragment,
    fragmentName: 'SpecificResourceFragment'
  });

  proxy.writeFragment({
    id: cachedFragmentId,
    fragmentName: 'SpecificResourceFragment',
    fragment: specificResourceFragment,
    data: {
      ...cachedUser,
      customFieldValues
    }
  });
};

export const useUpdateUserCustomFieldsMutation = () =>
  useMutation(UPDATE_USER_CUSTOM_FIELDS_MUTATION);

const useUpdateUserCustomFields = ({ mapValuesOnSubmit, onClose }) => {
  const [error, setError] = useState();

  const clearError = useCallback(() => setError(null), [setError]);

  const [updateUserCustomFields] = useUpdateUserCustomFieldsMutation();

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const payload = mapValuesOnSubmit ? mapValuesOnSubmit(values) : values;

      try {
        await updateUserCustomFields({
          variables: {
            input: payload
          },
          update: updateCache(values.id)
        });
      } catch (err) {
        setError(err);
      } finally {
        setSubmitting(false);
        onClose();
      }
    },
    [updateUserCustomFields, setError, onClose, mapValuesOnSubmit]
  );

  return { onSubmit, error, clearError };
};

export default useUpdateUserCustomFields;
