import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { OverEstimatedProjectType } from '~/types';
import { OverviewItemCard, NoData } from '~/modules/common/charts/dashboard';
import { FlatOverviewItemExpansionPanel } from '~/modules/common/components/OverviewItemExpansionPanel';
import { ProjectIcon } from '~/modules/common/components/Icons';
import { useMeContext } from '~/modules/me';
import OverbudgetTitle from './OverbudgetTitle';
import PanelTitle from './PanelTitle';
import ProjectStatusChart from './ProjectStatusChart';
import ProjectsCardLoading from './ProjectsCardLoading';
import ProjectsCardHelp from './ProjectsCardHelp';

const projectsTitle = <FormattedMessage id="clientPageCharts.projects" />;
const projectAvatar = <ProjectIcon />;
const noProjectsTitle = (
  <FormattedMessage id="clientsOverview.projects.noProjectsData" />
);

const overEstimatedTitle = ({ canViewProjectCostData }) => (
  <OverbudgetTitle
    messageId="clientsOverview.projects.overEstimate"
    highlightMsgId={
      canViewProjectCostData
        ? 'clientsOverview.projects.eitherHoursOrCostOverbudget'
        : 'clientsOverview.projects.overHoursBudget'
    }
  />
);
const overEndDateTitle = (
  <OverbudgetTitle
    messageId="clientsOverview.projects.overEstimate"
    highlightMsgId="clientsOverview.projects.overEndDate"
  />
);
const useCardStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  subtitle: {
    ...theme.typography.body1,
    color: theme.palette.text.main,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const useProjectStatusChartStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

export const ProjectsCard = ({
  projectCountsSummary,
  ExpansionPanelComponent
}) => {
  const cardClasses = useCardStyles();
  const projectStatusChartClasses = useProjectStatusChartStyles();
  const {
    featureFlags: { isPsaPpmCostEacEnhancementsEnabled },
    permissionsMap
  } = useMeContext();

  const canViewProjectCostData =
    isPsaPpmCostEacEnhancementsEnabled &&
    Boolean(permissionsMap['urn:replicon:project-action:view-cost-data']);

  const {
    total,
    overEndDate,
    overBudgetHours,
    overEstimated,
    onTrack,
    loading,
    overHoursOrCostBudget
  } = projectCountsSummary;

  const overBudgetCount = canViewProjectCostData
    ? overHoursOrCostBudget
    : overBudgetHours;

  const overEstimatedProjectTypeForOverBudget = canViewProjectCostData
    ? OverEstimatedProjectType.BudgetHoursOrCost
    : OverEstimatedProjectType.BudgetHours;

  const action = useMemo(() => <ProjectsCardHelp />, []);

  return (
    <OverviewItemCard
      classes={cardClasses}
      title={projectsTitle}
      avatar={projectAvatar}
      action={action}
      dataQeId="Projects"
    >
      {loading ? (
        <ProjectsCardLoading />
      ) : (
        <>
          {overBudgetCount > 0 && (
            <FlatOverviewItemExpansionPanel
              counts={overBudgetCount}
              title={overEstimatedTitle({
                canViewProjectCostData
              })}
              TitleComponent={PanelTitle}
              square
              elevation={0}
              dataQeId="ProjectsOverBudgetHours"
            >
              <ExpansionPanelComponent
                overEstimatedProjectType={overEstimatedProjectTypeForOverBudget}
              />
            </FlatOverviewItemExpansionPanel>
          )}
          {overEndDate > 0 && (
            <FlatOverviewItemExpansionPanel
              counts={overEndDate}
              title={overEndDateTitle}
              TitleComponent={PanelTitle}
              square
              elevation={0}
              dataQeId="ProjectsOverEndDate"
            >
              <ExpansionPanelComponent
                overEstimatedProjectType={OverEstimatedProjectType.EndDate}
              />
            </FlatOverviewItemExpansionPanel>
          )}
          {total > 0 ? (
            <ProjectStatusChart
              classes={projectStatusChartClasses}
              total={total}
              overEstimated={overEstimated}
              onTrack={onTrack}
            />
          ) : (
            <NoData message={noProjectsTitle} dataQeId="NoProjectsAllocated" />
          )}
        </>
      )}
    </OverviewItemCard>
  );
};

ProjectsCard.propTypes = {
  projectCountsSummary: PropTypes.shape({
    total: PropTypes.number.isRequired,
    overEndDate: PropTypes.number.isRequired,
    overBudgetHours: PropTypes.number.isRequired,
    overEstimated: PropTypes.number.isRequired,
    onTrack: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    overHoursOrCostBudget: PropTypes.number
  }),
  ExpansionPanelComponent: PropTypes.any.isRequired
};

export default ProjectsCard;
