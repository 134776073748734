import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsBreakpointDown, useDialogState } from '~/modules/common/hooks';
import { usePageTitle } from '~/modules/common/title';
import { useSaveURLSearchParamsEffect } from '~/modules/navigation/useSaveURLSearchParamsEffect';
import StickyHeader from '../common/components/StickyHeader/StickyHeader';
import {
  ResourceManagementTabs,
  RESOURCE_MANAGEMENT_TAB_ENUM,
  Toolbar,
  ResourceManagementContext,
  withResourceSearchContextProvider,
  ResourceManagementActionIcons
} from './common';
import ResourceAllocationUserChart from './ResourceAllocationUserChart';
import ResourceUserList from './ResourceUserList';
import ResourcingOverview from './ResourcingOverview';
import { useResourceManagementTabProps } from './useResourceManagementTabProps';

const pageTitle = <FormattedMessage id="routes.resourceManagement" />;

export const ResourceManagement = () => {
  useSaveURLSearchParamsEffect();
  const isMobile = useIsBreakpointDown('sm');

  const {
    selectedTab,
    onTabChange,
    currentPageForOverview,
    setCurrentPageForOverview,
    currentPageForTimeline,
    setCurrentPageForTimeline
  } = useResourceManagementTabProps(isMobile);

  const { open, openDialog, closeDialog } = useDialogState();

  usePageTitle([
    { messageId: 'routes.resourceManagement' },
    { messageId: `resourceManagementTabs.${selectedTab.toLowerCase()}` }
  ]);

  if (selectedTab === RESOURCE_MANAGEMENT_TAB_ENUM.LIST)
    return (
      <ResourceUserList
        isMobile={isMobile}
        selectedTab={selectedTab}
        setSelectedTab={onTabChange}
        openSettings={open}
        openSettingsDialog={openDialog}
        closeSettingsDialog={closeDialog}
      />
    );

  return (
    <div>
      <StickyHeader
        showMenuButton={isMobile}
        title={pageTitle}
        disableTitleFullWidth
        rightPadding
      >
        {!isMobile && (
          <Toolbar
            setCurrentPage={
              (selectedTab === RESOURCE_MANAGEMENT_TAB_ENUM.OVERVIEW &&
                setCurrentPageForOverview) ||
              (selectedTab === RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE &&
                setCurrentPageForTimeline) ||
              null
            }
          />
        )}
      </StickyHeader>
      {isMobile && <Toolbar />}
      <StickyHeader level={1} bottomBorder>
        <ResourceManagementTabs
          onChange={onTabChange}
          selectedTab={selectedTab}
        />
        <ResourceManagementActionIcons
          selectedTab={selectedTab}
          onSettingsClick={openDialog}
        />
      </StickyHeader>

      {selectedTab === RESOURCE_MANAGEMENT_TAB_ENUM.OVERVIEW && (
        <ResourcingOverview
          currentPage={currentPageForOverview}
          setCurrentPage={setCurrentPageForOverview}
        />
      )}
      {selectedTab === RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE && (
        <ResourceManagementContext.Consumer>
          {({ searchCriteria, setSearchCriteria }) => (
            <ResourceAllocationUserChart
              searchCriteria={searchCriteria}
              setSearchCriteria={setSearchCriteria}
              currentPageForTimeline={currentPageForTimeline}
              setCurrentPageForTimeline={setCurrentPageForTimeline}
            />
          )}
        </ResourceManagementContext.Consumer>
      )}
    </div>
  );
};

export default withResourceSearchContextProvider(ResourceManagement);
