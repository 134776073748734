import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { getTodayForUser } from '~/modules/common/dates/today';
import { useMeContext } from '~/modules/me/useMeContext';
import { dateToPosition } from '~/modules/common/charts/timeline/calculations';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import { compareDateObject } from '~/modules/common/dates/compare';
import useStyles from './useStyles';

export const ResourcingCurrentDateLine = ({
  scale,
  chartDates,
  leftWidth,
  footerHeight = 0
}) => {
  const me = useMeContext();
  const classes = useStyles({ footerHeight });
  const todayDate = getTodayForUser(me);

  const chartStartDate = chartDates[0].start;
  const chartEndDate = chartDates[chartDates.length - 1].end;

  const today = mapRepliconDateToUtcObject(todayDate);
  const dateLineWidth =
    today >= chartStartDate &&
    today <= chartEndDate &&
    dateToPosition({
      chartStartDate,
      date: scale === PERIOD_SCALE_ENUM.DAYS ? today.plus({ days: 1 }) : today,
      scale
    });

  const currentLineDynamicStyle = useMemo(
    () => ({
      left: `calc(${leftWidth}px + ${
        scale === PERIOD_SCALE_ENUM.DAYS ? dateLineWidth - 2 : dateLineWidth
      }px)`
    }),
    [dateLineWidth, scale, leftWidth]
  );

  return (
    (Boolean(dateLineWidth) ||
      compareDateObject(today, chartStartDate) === 0) && (
      <div className={classes.root} style={currentLineDynamicStyle}>
        <div className={classes.bubble}>{todayDate.day}</div>
      </div>
    )
  );
};

ResourcingCurrentDateLine.propTypes = {
  chartDates: PropTypes.array.isRequired,
  scale: PropTypes.string,
  footerHeight: PropTypes.number,
  leftWidth: PropTypes.number
};

export default ResourcingCurrentDateLine;
