import { useLayoutEffect } from 'react';
import { usePageLayoutContext } from '~/modules/navigation/components/PageLayout/PageLayoutContext';

export const useResetHorizontalScrollPositionEffect = ({ trigger }) => {
  const { mainRef } = usePageLayoutContext();

  useLayoutEffect(() => {
    mainRef?.current?.scrollTo({ left: 0 });
  }, [mainRef, trigger]);
};

export default useResetHorizontalScrollPositionEffect;
