export const CHILD_INFO_DIALOG_COLUMN_TYPE = {
  TASK_NAME: 'TASK_NAME',
  ACTUAL_HOURS: 'ACTUAL_HOURS',
  WORK_REMAINING: 'WORK_REMAINING',
  ESTIMATED_AT_COMPLETION: 'ESTIMATED_AT_COMPLETION',
  HOURS_VARIATION: 'HOURS_VARIATION',
  DATE_VARIATION: 'DATE_VARIATION',
  ACTUAL_COST: 'ACTUAL_COST',
  MONTH: 'MONTH',
  ESTIMATED_EXPENSES: 'ESTIMATED_EXPENSES',
  COST_REMAINING: 'COST_REMAINING',
  COST_VARIATION: 'COST_VARIATION',
  COST_ESTIMATED_AT_COMPLETION: 'COST_ESTIMATED_AT_COMPLETION'
};
