import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { withA11yMessenger } from '~/modules/common/a11y';
import QualityIcon from '../QualityIcon';

const QualityConstants = [
  { value: 2, label: 'qualityOptions.great' },
  { value: 1, label: 'qualityOptions.good' },
  { value: 0, label: 'qualityOptions.neutral' },
  { value: -1, label: 'qualityOptions.bad' },
  { value: -2, label: 'qualityOptions.horrible' }
];

export const QualityMenu = ({
  isOpen,
  anchor,
  quality,
  onChange,
  announcePoliteHandler,
  onClose
}) => {
  const intl = useIntl();
  const handleQualityChange = iconQuality => () => {
    onChange(iconQuality.value);
    onClose();
    announcePoliteHandler(intl.formatMessage({ id: iconQuality.label }), 2000);
  };

  return (
    <Menu id="quality-menu" open={isOpen} onClose={onClose} anchorEl={anchor}>
      {QualityConstants.map((q, index) => (
        <MenuItem
          key={q.value}
          aria-label={intl.formatMessage({
            id: q.label
          })}
          onClick={handleQualityChange(q)}
          selected={q.value === quality}
        >
          <QualityIcon quality={q.value} />
        </MenuItem>
      ))}
    </Menu>
  );
};

QualityMenu.propTypes = {
  quality: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  announcePoliteHandler: PropTypes.func.isRequired,
  anchor: PropTypes.object
};

export default withA11yMessenger(QualityMenu);
