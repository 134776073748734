import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const getCurrentlyAssociatedAmount = (
  associatedCreditItems,
  selectedItemId,
  allocationProperty
) => {
  const selectedAssociation = associatedCreditItems.filter(
    x => x.id === selectedItemId
  )[0];

  const currentAssociation = {
    allocatedBillAmount: selectedAssociation
      ? selectedAssociation.allocatedBillAmount.amount
      : 0
  };

  currentAssociation[allocationProperty] = selectedAssociation
    ? selectedAssociation[allocationProperty].amount
    : 0;

  return currentAssociation;
};

export const mapToCreditMemo = ({
  id,
  allocatedBillAmount: { amount: billAmount, currency: billCurrency },
  allocatedCreditMemoAmount: {
    amount: creditMemoAmount,
    currency: creditMemoCurrency
  }
}) => ({
  id,
  allocatedBillAmount: {
    amount: billAmount,
    currency: {
      id: billCurrency.id
    }
  },
  allocatedCreditMemoAmount: {
    amount: creditMemoAmount,
    currency: {
      id: creditMemoCurrency.id
    }
  }
});

export const mapToPayment = ({
  id,
  allocatedBillAmount: { amount: billAmount, currency: billCurrency },
  allocatedBillPaymentAmount: {
    amount: paymentAmount,
    currency: paymentCurrency
  }
}) => ({
  id,
  allocatedBillAmount: {
    amount: billAmount,
    currency: {
      id: billCurrency.id
    }
  },
  allocatedBillPaymentAmount: {
    amount: paymentAmount,
    currency: {
      id: paymentCurrency.id
    }
  }
});

const mapExcludingSelectedItem = (
  associatedCreditItems,
  selectedItemId,
  mapper
) => associatedCreditItems.filter(x => x.id !== selectedItemId).map(mapper);

const canBeFullyAllocated = (maxContributionAmount, remainingBillBalance) =>
  getRoundedValue(maxContributionAmount, 2) <=
  getRoundedValue(remainingBillBalance, 2);

export const mapToAssociatedCreditsInput = ({
  billBalanceTotal,
  associatedCreditItems,
  selectedItem: {
    id: selectedItemId,
    balanceAmount,
    balanceAmountInOriginalCurrency
  },
  mapper,
  allocationProperty
}) => {
  const currentAssociation = getCurrentlyAssociatedAmount(
    associatedCreditItems,
    selectedItemId,
    allocationProperty
  );

  const maxContributionAmount = balanceAmount.amount;

  const remainingBillBalance =
    billBalanceTotal.amount > 0 ? billBalanceTotal.amount : 0;

  const amountAssociation = {
    amount:
      currentAssociation.allocatedBillAmount +
      Math.min(maxContributionAmount, remainingBillBalance),
    currency: {
      id: balanceAmount.currency.id
    }
  };

  const maxContributionAmountInOriginalCurrency = canBeFullyAllocated(
    maxContributionAmount,
    remainingBillBalance
  )
    ? balanceAmountInOriginalCurrency.amount
    : balanceAmount.currency.id === balanceAmountInOriginalCurrency.currency.id
    ? Math.min(maxContributionAmount, remainingBillBalance)
    : null;

  const amountAssociationInOriginalCurrency =
    maxContributionAmountInOriginalCurrency !== null
      ? {
          amount:
            currentAssociation[allocationProperty] +
            maxContributionAmountInOriginalCurrency,
          currency: {
            id: balanceAmountInOriginalCurrency.currency.id
          }
        }
      : {};

  const newAssociation = {
    id: selectedItemId,
    allocatedBillAmount: amountAssociation
  };

  newAssociation[allocationProperty] = amountAssociationInOriginalCurrency;
  const associatedCreditsInput = [
    ...mapExcludingSelectedItem(associatedCreditItems, selectedItemId, mapper),
    newAssociation
  ];

  return {
    associatedCreditsInput
  };
};

export const mapToDeleteCreditMemosInput = ({
  billId,
  associatedCreditMemos,
  selectedCreditMemo: { id: itemIdToDelete }
}) => ({
  billId,
  associatedCreditMemos: mapExcludingSelectedItem(
    associatedCreditMemos,
    itemIdToDelete,
    mapToCreditMemo
  )
});

export const mapToCreditMemosInput = ({
  billId,
  billBalanceTotal,
  associatedCreditMemos,
  selectedCreditMemo
}) => {
  const { associatedCreditsInput } = mapToAssociatedCreditsInput({
    billBalanceTotal,
    associatedCreditItems: associatedCreditMemos,
    selectedItem: selectedCreditMemo,
    mapper: mapToCreditMemo,
    allocationProperty: 'allocatedCreditMemoAmount'
  });

  return {
    billId,
    associatedCreditMemos: associatedCreditsInput
  };
};

export const mapToDeleteBillPaymentsInput = ({
  billId,
  associatedBillPayments,
  selectedBillPayment: { id: itemIdToDelete }
}) => ({
  billId,
  associatedBillPayments: mapExcludingSelectedItem(
    associatedBillPayments,
    itemIdToDelete,
    mapToPayment
  )
});

export const mapToBillPaymentsInput = ({
  billId,
  billBalanceTotal,
  associatedBillPayments,
  selectedBillPayment
}) => {
  const { associatedCreditsInput } = mapToAssociatedCreditsInput({
    billBalanceTotal,
    associatedCreditItems: associatedBillPayments,
    selectedItem: selectedBillPayment,
    mapper: mapToPayment,
    allocationProperty: 'allocatedBillPaymentAmount'
  });

  return {
    billId,
    associatedBillPayments: associatedCreditsInput
  };
};
