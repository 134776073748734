/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import ChevronDateButtons from '~/modules/common/components/ChevronDateButtons';
import {
  ResourcingLegends,
  resourcingLegendItems
} from '~/modules/resourcing/common/components';
import { ResourceAllocationStatus } from '~/types';
import CalendarMenuItem from './CalendarMenuItem';

const useStyles = makeStyles(theme => ({
  dateSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    flexShrink: 0
  },
  dateControllers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  calendarControllers: {
    alignItems: 'center'
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  },
  avatar: {
    color: theme.palette.text.secondary
  }
}));

const useResourcingStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(2)
  }
}));

export const useAllocationStyles = makeStyles(theme => ({
  root: {
    color: 'default',
    '&$checked': {
      color: lightBlue[200]
    }
  },
  checked: {}
}));

export const CalendarHeader = ({
  classes: classesOverride,
  period,
  title,
  onPrevButtonClick,
  onNextButtonClick,
  onViewChange,
  showCalendarLegend
}) => {
  const classes = useStyles({ classesOverride });
  const resourcingClasses = useResourcingStyles();

  const legends = useMemo(
    () => [
      ...(showCalendarLegend(ResourceAllocationStatus.Rejected)
        ? [resourcingLegendItems.rejectedAllocation]
        : []),
      ...(showCalendarLegend(ResourceAllocationStatus.Proposed)
        ? [resourcingLegendItems.proposedAllocation]
        : []),
      ...(showCalendarLegend(ResourceAllocationStatus.Draft)
        ? [resourcingLegendItems.pendingAllocation]
        : []),
      resourcingLegendItems.allocation,
      resourcingLegendItems.timeoffAllocation
    ],
    [showCalendarLegend]
  );

  return (
    <>
      <div className={classes.dateControllers}>
        <div className={classes.dateSelector}>
          <ChevronDateButtons
            onPreviousClick={onPrevButtonClick}
            onNextClick={onNextButtonClick}
          />
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
        </div>
      </div>
      <FormGroup className={classes.calendarControllers} row>
        <ResourcingLegends legends={legends} classes={resourcingClasses} />
        <CalendarMenuItem period={period} onViewChange={onViewChange} />
      </FormGroup>
    </>
  );
};

CalendarHeader.propTypes = {
  classes: PropTypes.object,
  period: PropTypes.string.isRequired,
  showCalendarLegend: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onPrevButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired
};

export default CalendarHeader;
