import { DateTime } from 'luxon';
import { dateToMidnightUTCObject } from '~/modules/common/dates/convert';
import { PERIOD_SCALE_ENUM, DEFAULT_PERIOD_SCALE } from '../periodScale';

export const actionsWidth = 64;
export const itemWidth = 200;
export const yearWidth = 240;
export const quarterWidth = 126;
export const monthWidth = 128;
export const weekWidth = 112;
export const dayWidth = 40;
export const totalWidth = 190;
export const projectHandleWidth = 16;

const daysPerPeriodMap = {
  [PERIOD_SCALE_ENUM.DAYS]: 1,
  [PERIOD_SCALE_ENUM.WEEKS]: 7,
  [PERIOD_SCALE_ENUM.MONTHS]: 31,
  [PERIOD_SCALE_ENUM.QUARTERS]: 93,
  [PERIOD_SCALE_ENUM.YEARS]: 365
};

export const getScaleWidth = scale => {
  switch (scale) {
    case PERIOD_SCALE_ENUM.DAYS:
      return dayWidth;
    case PERIOD_SCALE_ENUM.WEEKS:
      return weekWidth;
    case PERIOD_SCALE_ENUM.QUARTERS:
      return quarterWidth;
    case PERIOD_SCALE_ENUM.YEARS:
      return yearWidth;
    default:
      return monthWidth;
  }
};

export const getAdjustmentDays = scale => {
  const totalWidths = getScaleWidth(scale);

  const daysPerPeriod = daysPerPeriodMap[scale] || 31;

  return Math.ceil(projectHandleWidth / (totalWidths / daysPerPeriod));
};

export const getFullWidth = (scale, periodLength) =>
  getScaleWidth(scale) * periodLength + 400 + 224;

export const getOffset = ({
  chartStartDate,
  date,
  scale = DEFAULT_PERIOD_SCALE,
  isPsaRmpUserSchedulePerfFixEnabled
}) => {
  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    return date.diff(chartStartDate, 'weeks').toObject().weeks;
  }

  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return date.diff(chartStartDate, 'days').toObject().days;
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    const monthsInQuarter = 3;

    const monthsFromStartDate = date.diff(chartStartDate, 'months').toObject()
      .months;

    return monthsFromStartDate / monthsInQuarter;
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    const { daysInYear, year } = date;

    const daysFromStartDate = date.diff(chartStartDate, 'days').toObject().days;

    const firstDateOfYear = dateToMidnightUTCObject(
      DateTime.fromObject({ day: 1, month: 1, year })
    );

    return isPsaRmpUserSchedulePerfFixEnabled
      ? Math.floor(date.diff(chartStartDate, 'years').toObject().years) +
          date.diff(firstDateOfYear, 'days').toObject().days / daysInYear
      : (daysFromStartDate - 1) / daysInYear;
  }

  return (
    Math.floor(date.diff(chartStartDate, 'months').toObject().months) +
    (date.day - 1) / date.daysInMonth
  );
};

export const positionToDate = ({
  chartStartDate,
  position,
  scale = DEFAULT_PERIOD_SCALE
}) => {
  const offset = position / getScaleWidth(scale);
  const scaleOffset = Math.floor(offset);

  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    return chartStartDate.plus({ days: offset * 7 });
  }

  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return chartStartDate.plus({ days: offset });
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    const date = chartStartDate.plus({ quarters: scaleOffset });
    const dayOffset = offset - scaleOffset;

    return date.plus({ days: dayOffset * date.daysInMonth * 3 });
  }
  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    const date = chartStartDate.plus({ years: scaleOffset });
    const dayOffset = offset - scaleOffset;

    return date.plus({ days: dayOffset * date.daysInMonth * 12 });
  }

  const date = chartStartDate.plus({ months: scaleOffset });
  const dayOffset = offset - scaleOffset;

  return date.plus({ days: dayOffset * date.daysInMonth });
};

export const dateToPosition = ({
  chartStartDate,
  date,
  scale,
  isPsaRmpUserSchedulePerfFixEnabled
}) =>
  getOffset({
    chartStartDate,
    date,
    scale,
    isPsaRmpUserSchedulePerfFixEnabled
  }) * getScaleWidth(scale);

export const getDynamicPositionAttributes = ({
  chartStartDate,
  start,
  end,
  scale,
  rowIndex = 0,
  relativeDynamicPositionLeft = 0,
  isPsaRmpUserSchedulePerfFixEnabled
}) => {
  const endDate = isPsaRmpUserSchedulePerfFixEnabled
    ? dateToMidnightUTCObject(end)
    : end;

  const left = dateToPosition({
    chartStartDate,
    date: start,
    scale,
    isPsaRmpUserSchedulePerfFixEnabled
  });

  const right = dateToPosition({
    chartStartDate,
    date: endDate.plus({ days: 1 }),
    scale,
    isPsaRmpUserSchedulePerfFixEnabled
  });

  const width = right - left;

  return {
    dynamicPosition: {
      left: left - relativeDynamicPositionLeft,
      top: 8 + rowIndex * 26,
      width
    }
  };
};
