import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AbbreviatedDecimal } from '~/modules/common/components';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

export const statusColors = {
  Draft: {
    dark: theme.palette.bill.draft.main,
    light: theme.palette.bill.draft.light
  },
  Unpaid: {
    dark: theme.palette.bill.unpaid.main,
    light: theme.palette.bill.unpaid.light
  },
  Paid: {
    dark: theme.palette.bill.paid.main,
    light: theme.palette.bill.paid.light
  },
  Overdue: {
    dark: theme.palette.error.dark,
    light: theme.palette.error.light
  },
  Unallocated: {
    dark: theme.palette.payment.unallocated.main,
    light: theme.palette.payment.unallocated.light
  },
  Voided: {
    dark: theme.palette.project.archived.main,
    light: theme.palette.project.archived.light
  }
};

export const totalsDisplayText = (total, allowNegative = true) => (
  <AbbreviatedDecimal
    prefix={`${total.currency.symbol} `}
    value={total.amount}
    allowNegative={allowNegative}
  />
);

export const getStatusCountSummaryBasedProps = ({
  draftSummary,
  issuedSummary,
  outstandingBillSummary,
  unallocatedSummary,
  overdueSummary,
  voidedBillCount
} = {}) => ({
  statusOptions: [
    {
      id: 'draft',
      displayText: <FormattedMessage id="billingTransactionList.draft" />,
      color: statusColors.Draft,
      totals:
        draftSummary && draftSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(draftSummary.balanceAmountInBaseCurrency)
          : 0,
      dataQeId: 'Draft'
    },
    {
      id: 'issued',
      displayText: <FormattedMessage id="billingTransactionList.issued" />,
      color: statusColors.Paid,
      totals:
        issuedSummary &&
        issuedSummary.balanceWithoutAllocationsAmountInBaseCurrency
          ? totalsDisplayText(
              issuedSummary.balanceWithoutAllocationsAmountInBaseCurrency
            )
          : 0,
      dataQeId: 'Issued'
    },
    {
      id: 'outstanding',
      displayText: <FormattedMessage id="billingTransactionList.outstanding" />,
      color: statusColors.Unpaid,
      totals:
        outstandingBillSummary &&
        outstandingBillSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(
              outstandingBillSummary.balanceAmountInBaseCurrency
            )
          : 0,
      dataQeId: 'Outstanding'
    },
    {
      id: 'overdue',
      displayText: (
        <FormattedMessage id="billingTransactionList.overdueBills" />
      ),
      color: statusColors.Overdue,
      totals:
        overdueSummary && overdueSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(overdueSummary.balanceAmountInBaseCurrency)
          : 0,
      dataQeId: 'Overdue'
    },
    {
      id: 'unallocated',
      displayText: <FormattedMessage id="billingTransactionList.unallocated" />,
      color: statusColors.Unallocated,
      totals:
        unallocatedSummary && unallocatedSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(
              unallocatedSummary.balanceAmountInBaseCurrency,
              false
            )
          : 0,
      dataQeId: 'Unallocated'
    }
  ],
  voidedBill: {
    id: 'void',
    displayText: <FormattedMessage id="billingTransactionList.void" />,
    color: statusColors.Voided,
    totals: voidedBillCount,
    dataQeId: 'Void'
  }
});
