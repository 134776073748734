import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';

export const useProjectFilterWithExcludingArchiveProjects = ({
  projectFilter
}) => ({
  ...projectFilter,
  executionPhase: [
    PROJECT_EXECUTION_PHASE.INITIATE,
    PROJECT_EXECUTION_PHASE.EXECUTION,
    PROJECT_EXECUTION_PHASE.PLANNING,
    PROJECT_EXECUTION_PHASE.CLOSEOUT
  ]
});
