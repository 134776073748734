import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';

export const CostTypeDropdown = ({
  variant = 'filled',
  value,
  onChange,
  formControlMargin,
  dataQeId
}) => {
  const intl = useIntl();

  const costTypeOptions = [
    'urn:replicon:cost-type:capital',
    'urn:replicon:cost-type:operational'
  ];

  const options = costTypeOptions.map(key => ({
    id: key,
    displayText: intl.formatMessage({ id: `projectFinancialsCard.${key}` })
  }));

  const selectedValue = useMemo(
    () => (value ? options.find(x => x.id === value.id) : null),
    [options, value]
  );

  return (
    <SimpleAutocomplete
      variant={variant}
      options={options}
      value={selectedValue}
      onChange={onChange}
      optionPropText="displayText"
      inputLabel={intl.formatMessage({ id: `projectFinancialsCard.costType` })}
      formControlMargin={formControlMargin}
      dataQeId={dataQeId}
    />
  );
};

CostTypeDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  formControlMargin: PropTypes.string,
  dataQeId: PropTypes.string,
  variant: PropTypes.string
};

export default CostTypeDropdown;
