import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { LongString } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    verticalAlign: 'top',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'flex-start'
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'start',
    fontSize: theme.typography.body2.fontSize,
    width: '1.5em',
    height: '1.5em',
    margin: [[0, '0.375em', 0, '0']],
    color: theme.palette.text.secondary
  },
  noValueName: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  label: {
    width: '100%'
  }
}));

export const InfoItem = ({
  classes: classesOverride,
  icon,
  value,
  noValue,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <span data-qe-id={dataQeId} className={classes.root}>
      {icon ? React.cloneElement(icon, { className: classes.icon }) : null}
      <span
        className={classNames(classes.label, {
          [classes.noValueName]: noValue
        })}
      >
        <LongString>{value}</LongString>
      </span>
    </span>
  );
};

InfoItem.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.node.isRequired,
  noValue: PropTypes.bool.isRequired,
  icon: PropTypes.node,
  dataQeId: PropTypes.string
};

export default InfoItem;
