import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';

export const RESOURCE_PLAN_BULK_USER_ACTUAL_SERIES_BY_ROLES_QUERY = gql`
  query getResourcePlanBulkUsersActualSeriesByRoles(
    $projectId: String!
    $userUris: [String!]!
    $dateRange: DateRangeInput!
    $periodResolution: PeriodResolutionOption!
  ) {
    project(projectId: $projectId) {
      id
      bulkResourcePlanUserActualSeriesByRole(
        userUris: $userUris
        dateRange: $dateRange
        periodResolution: $periodResolution
      ) {
        user {
          id
          uri
          slug
          displayText
        }
        actualSeriesByRole {
          role {
            id
            displayText
          }
          periods {
            hours {
              hours
              minutes
              seconds
            }
            dateRange {
              startDate: startDate2
              endDate: endDate2
            }
          }
        }
      }
    }
  }
`;

const useResourcePlanBulkUserActualSeriesByRoles = ({
  dateRange,
  scale,
  userUris,
  projectId,
  skip
}) => {
  const { data, loading } = useQuery(
    RESOURCE_PLAN_BULK_USER_ACTUAL_SERIES_BY_ROLES_QUERY,
    {
      variables: {
        dateRange,
        userUris,
        projectId,
        periodResolution: mapScaleToPeriodResolution(scale)
      },
      skip
    }
  );

  const bulkResourcePlanUserActualSeriesByRole =
    data?.project.bulkResourcePlanUserActualSeriesByRole || [];

  return {
    isLoading: loading,
    bulkResourcePlanUserActualSeriesByRoleMap: bulkResourcePlanUserActualSeriesByRole.reduce(
      (acc, entry) => {
        acc[entry.user.uri] = entry;

        return acc;
      },
      {}
    )
  };
};

export default useResourcePlanBulkUserActualSeriesByRoles;
