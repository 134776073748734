import * as yup from 'yup';
import { useMemo } from 'react';

export const initialState = ({ tags = [] }) => ({
  tags: tags.map(tag => ({
    tagId: tag.tag.id,
    tagName: tag.tag.name,
    tagValueId: tag.value.id,
    tagValue: tag.value.value
  }))
});

const MAX_CHARACTERS = 50;

export const buildValidationSchema = intl =>
  yup.object({
    tags: yup.array(
      yup.object({
        tagId: yup.string(),
        tagName: yup
          .string()
          .trim()
          .max(
            MAX_CHARACTERS,
            intl.formatMessage(
              {
                id: 'tags.exceedsMaxCharactersError'
              },
              { count: MAX_CHARACTERS }
            )
          )
          .required(
            intl.formatMessage({
              id: 'tags.noTagError'
            })
          ),
        tagValueId: yup.string(),
        tagValue: yup
          .string()
          .trim()
          .max(
            MAX_CHARACTERS,
            intl.formatMessage(
              {
                id: 'tags.exceedsMaxCharactersError'
              },
              { count: MAX_CHARACTERS }
            )
          )
          .required(
            intl.formatMessage({
              id: 'tags.noValueError'
            })
          )
      })
    )
  });

export const useTagsEditFormState = ({ tags, intl }) => {
  const initialValues = useMemo(() => initialState({ tags }), [tags]);
  const validationSchema = useMemo(() => buildValidationSchema(intl), [intl]);

  return { initialValues, validationSchema };
};
