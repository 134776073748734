import { CHILD_INFO_DIALOG_COLUMN_TYPE } from '~/modules/common/enums';

const getColumn = ({ selectedColumns, column, isRTL }) => {
  return {
    groupId: column,
    visible: selectedColumns.includes(column),
    align:
      column === CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME ||
      column === CHILD_INFO_DIALOG_COLUMN_TYPE.MONTH
        ? isRTL
          ? 'right'
          : 'left'
        : isRTL
        ? 'left'
        : 'right'
  };
};

export const buildColumns = ({ selectedColumns = [], isRTL }) => ({
  name: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
    isRTL
  }),
  actualHours: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.ACTUAL_HOURS,
    isRTL
  }),
  totalEstimatedRemainingHours: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.WORK_REMAINING,
    isRTL
  }),
  totalEstimatedAtCompletionHours: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.ESTIMATED_AT_COMPLETION,
    isRTL
  }),
  hoursVariation: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.HOURS_VARIATION,
    isRTL
  }),
  dateVariation: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.DATE_VARIATION,
    isRTL
  }),
  actualCost: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.ACTUAL_COST,
    isRTL
  }),
  month: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.MONTH,
    isRTL
  }),
  estimatedExpenses: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.ESTIMATED_EXPENSES,
    isRTL
  }),
  totalEstimatedRemainingCost: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.COST_REMAINING,
    isRTL
  }),
  totalEstimatedAtCompletionCost: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.COST_ESTIMATED_AT_COMPLETION,
    isRTL
  }),
  costVariation: getColumn({
    selectedColumns,
    column: CHILD_INFO_DIALOG_COLUMN_TYPE.COST_VARIATION,
    isRTL
  })
});
