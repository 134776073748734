import React, { PropsWithChildren, ReactElement } from 'react';

import { Tooltip, ValueLabelProps } from '@material-ui/core';

const SliderValueLabel = ({
  children,
  open,
  value
}: PropsWithChildren<ValueLabelProps>): ReactElement => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

export default SliderValueLabel;
