import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  percentage: {
    color: ({ percentage }) =>
      percentage >= 50 ? theme.palette.common.white : theme.palette.grey[700],
    position: 'absolute',
    top: 0,
    width: '100%',
    textAlign: 'center'
  },
  linearProgressRoot: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(3),
    height: theme.spacing(2.5)
  },
  linearProgressBar: {
    borderRadius: theme.spacing(3)
  }
}));

export default useStyles;
