import React, { useMemo, useState } from 'react';
import { useSearchableBillingItemType } from './useBillingItemType';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const mapOptions = billingItemTypes =>
  billingItemTypes.map(({ name, id }) => ({ key: name, value: id }));

export const matchOption = searchBillingItemType => async (_, term) => {
  if (!term) {
    return null;
  }

  const billingItemTypes = await searchBillingItemType(cleanSearchTerm(term));

  const option = billingItemTypes.shift();

  if (option) {
    return {
      ...option,
      key: option.name
    };
  }

  return null;
};

const makeCriteriaProvider = (tag, context) => BaseComponent => props => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const { searchBillingItemType } = useSearchableBillingItemType();

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption(searchBillingItemType),
      selected,
      setSelected,
      suggestions: searchBillingItemType
    }),
    [searchBillingItemType, selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

export default makeCriteriaProvider;
