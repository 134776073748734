import { gql, useApolloClient } from '@apollo/client';

export const TASK_RESOURCE_ESTIMATE_QUERY = gql`
  query getTaskResourceEstimate(
    $resourceEstimateUri: String!
    $includeTaskResourceUserHoursProgressSummary: Boolean!
    $includeTaskResourceUserAllocation: Boolean!
  ) {
    taskResourceEstimateDetails(resourceEstimateUri: $resourceEstimateUri) {
      id
      taskId
      initialEstimatedHours
      resourceUser {
        id
        uri
        slug
        displayText
      }
      projectRole {
        id
        displayText
      }
      taskResourceUserHoursProgressSummary
        @include(if: $includeTaskResourceUserHoursProgressSummary) {
        actualHours
        estimatedAtCompletionHours
        estimatedRemainingHours
      }
      taskResourceUserAllocation
        @include(if: $includeTaskResourceUserAllocation) {
        id
        taskUri
        projectUri
        roleUri
        startDate
        endDate
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
        totalHours
      }
    }
  }
`;
export const useTaskResourceEstimate = () => {
  const apolloClient = useApolloClient();

  return {
    getTaskResourceEstimate: async ({
      resourceEstimateUri,
      includeTaskResourceUserHoursProgressSummary,
      includeTaskResourceUserAllocation,
      skip
    }) => {
      if (skip) {
        return {
          taskResourceEstimate: null,
          loading: false
        };
      }

      const {
        data: { taskResourceEstimateDetails },
        loading
      } = await apolloClient.query({
        query: TASK_RESOURCE_ESTIMATE_QUERY,
        variables: {
          resourceEstimateUri,
          includeTaskResourceUserHoursProgressSummary,
          includeTaskResourceUserAllocation
        },
        fetchPolicy: 'network-only'
      });

      return { taskResourceEstimate: taskResourceEstimateDetails, loading };
    }
  };
};
