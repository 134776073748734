import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { omitForbiddenUpdateFields } from '~/modules/resourcing/common/util/resourceAllocationUtil';
import { UPDATE_RESOURCE_ALLOCATION } from '~/modules/resourcing/hooks/useUpdateResourceAllocation';
import useQuickAllocationContext from './useQuickAllocationContext';
import { updateResourceUsersSummaryAllocationBar } from './useFulfilledResourceAllocationHandler';

export const updateCache = ({
  resourceUsersCurrentPage,
  dateRange,
  userSort,
  periodResolution,
  existingResourceAllocation,
  filter
}) => (proxy, mutationResponse) => {
  const {
    data: {
      updateResourceAllocation2: { resourceAllocation }
    }
  } = mutationResponse;

  updateResourceUsersSummaryAllocationBar({
    resourceUsersCurrentPage,
    proxy,
    resourceAllocation,
    sort: userSort,
    periodResolution,
    dateRange,
    existingResourceAllocation,
    filter,
    updateHoursCallback: ({ periodHours, existingPeriodHours = 0, summary }) =>
      periodHours > 0
        ? periodHours - existingPeriodHours + summary.allocatedDuration.hours
        : existingPeriodHours > 0
        ? summary.allocatedDuration.hours - existingPeriodHours
        : summary.allocatedDuration.hours
  });
};

const useResourceAllocationUpdateHandler = () => {
  const {
    resourceUsersCurrentPage,
    sort: userSort,
    dateRange,
    periodResolution,
    filter,
    projectStatusFilterOption
  } = useQuickAllocationContext();

  const [updateResourceAllocation, { data, loading }] = useMutation(
    UPDATE_RESOURCE_ALLOCATION
  );

  const onUpdateResourceAllocation = useCallback(
    ({ allocation, existingResourceAllocation }) =>
      updateResourceAllocation({
        variables: {
          input: omitForbiddenUpdateFields(allocation)
        },
        update: updateCache({
          resourceUsersCurrentPage,
          dateRange,
          userSort: [userSort],
          periodResolution,
          existingResourceAllocation,
          filter,
          projectStatusFilterOption
        })
      }),
    [
      updateResourceAllocation,
      resourceUsersCurrentPage,
      dateRange,
      userSort,
      periodResolution,
      filter,
      projectStatusFilterOption
    ]
  );

  return {
    onUpdateResourceAllocation,
    loading,
    data
  };
};

export default useResourceAllocationUpdateHandler;
