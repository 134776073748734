import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';

export const messages = {
  totalSchedule: 'resourcingOverviewChart.legends.totalResources',
  allocated: 'resourcingOverviewChart.legends.allocated',
  requested: 'resourcingOverviewChart.legends.pendingRequests',
  available: 'resourcingOverviewChart.legends.availableResource',
  toBeHired: 'resourcingOverviewChart.legends.toBeHired',
  overAllocated: 'resourcingOverviewChart.legends.overAllocated',
  timeoff: 'resourcingOverviewChart.legends.timeoff',
  holiday: 'resourcingOverviewChart.legends.holidays',
  fittingAllocation: 'resourcingOverviewChart.legends.fittingAllocation'
};

export const allocationLegendLabels = {
  available: messages.available,
  allocated: messages.allocated,
  overAllocated: messages.overAllocated,
  timeoff: messages.timeoff,
  holiday: messages.holiday,
  requested: messages.requested,
  toBeHired: messages.toBeHired
};

export const allocationDialogLabels = {
  totalSchedule: messages.totalSchedule,
  allocated: messages.allocated,
  requested: messages.requested,
  available: messages.available,
  toBeHired: messages.toBeHired,
  overAllocated: messages.overAllocated,
  timeoff: messages.timeoff,
  holiday: messages.holiday
};

export const resourceDialogLabels = {
  totalSchedule: messages.totalSchedule,
  holiday: messages.holiday,
  timeoff: messages.timeoff
};

export const projectDialogLabels = {
  allocated: messages.fittingAllocation,
  overAllocated: messages.overAllocated
};

export const resourceRequestLabels = {
  toBeHired: messages.toBeHired,
  requested: messages.requested
};

const useDataColors = () => {
  const theme = useTheme();

  return useMemo(
    () => ({
      totalSchedule: theme.palette.common.white,
      allocated: theme.palette.resourceAllocation.allocated,
      requested: theme.palette.resourceRequest.pending.main,
      available: theme.palette.common.white,
      toBeHired: theme.palette.resourceRequest.toBeHired.main,
      overAllocated: theme.palette.resourceAllocation.overAllocated,
      timeoff: theme.palette.resourceAllocation.timeOff,
      holiday: theme.palette.resourceAllocation.holiday
    }),
    [
      theme.palette.common.white,
      theme.palette.resourceAllocation.allocated,
      theme.palette.resourceRequest.pending.main,
      theme.palette.resourceRequest.toBeHired.main,
      theme.palette.resourceAllocation.overAllocated,
      theme.palette.resourceAllocation.timeOff,
      theme.palette.resourceAllocation.holiday
    ]
  );
};

const useLegends = (allocationData, displayLabels) => {
  const theme = useTheme();
  const legendColors = useDataColors();

  const intl = useIntl();

  return useMemo(
    () =>
      Object.keys(displayLabels).map(key => {
        const legendConfig = {
          label: intl.formatMessage({ id: displayLabels[key] }),
          color: legendColors[key],
          labelColor: theme.palette.common.black,
          value: allocationData[key]
        };

        return key === 'available'
          ? { ...legendConfig, borderColor: theme.palette.common.black }
          : legendConfig;
      }),
    [
      allocationData,
      displayLabels,
      intl,
      legendColors,
      theme.palette.common.black
    ]
  );
};

export const useLegendsResource = (
  allocationDataHours,
  allocationDataFTE,
  allocationDataPercentage,
  displayLabels
) => {
  const theme = useTheme();
  const legendColors = useDataColors();

  const intl = useIntl();

  return useMemo(
    () =>
      Object.keys(displayLabels).map(key => {
        const legendConfig = {
          label: intl.formatMessage({ id: displayLabels[key] }),
          color: legendColors[key],
          labelColor: theme.palette.common.black,
          hoursValue: allocationDataHours[key],
          fteValue: allocationDataFTE[key],
          percentValue: allocationDataPercentage[key]
        };

        return key === 'available'
          ? { ...legendConfig, borderColor: theme.palette.common.black }
          : legendConfig;
      }),
    [
      displayLabels,
      intl,
      legendColors,
      theme.palette.common.black,
      allocationDataHours,
      allocationDataFTE,
      allocationDataPercentage
    ]
  );
};

export default useLegends;
