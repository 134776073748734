import { useCallback, useState } from 'react';
import { BillStatus } from '~/types';

export default ({
  handleIssueSyncClick,
  handleIssuePrintClick,
  handleIssueEmailClick,
  record,
  openBillIssueDialog
}) => {
  const [confirmationDialogName, setConfirmationDialogName] = useState('sync');
  const billIssueDialogName = useCallback(
    dialogName => {
      setConfirmationDialogName(dialogName);
      openBillIssueDialog();
    },
    [openBillIssueDialog]
  );
  const menuItemClick = useCallback(
    value => async () => {
      if (record.billStatus === BillStatus.Draft) {
        billIssueDialogName(value);

        return;
      }
      if (value === 'sync') {
        await handleIssueSyncClick();
      }
      if (value === 'print') {
        await handleIssuePrintClick();
      }
      if (value === 'email') {
        await handleIssueEmailClick();
      }
    },
    [
      record.billStatus,
      handleIssueEmailClick,
      handleIssuePrintClick,
      handleIssueSyncClick,
      billIssueDialogName
    ]
  );

  return {
    menuItemClick,
    confirmationDialogName
  };
};
