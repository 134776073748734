import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { usePageTitle } from '~/modules/common/title';
import HomeButton from '~/modules/common/components/HomeButton';
import StickyHeader from '../components/StickyHeader/StickyHeader';
import { useIsBreakpointDown } from '../hooks/useBreakpoint';
import { UnhandledErrorDescription, UnhandledErrorTitle } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh'
  },
  container: {
    textAlign: 'center'
  },
  logo404: {
    width: '100%',
    maxWidth: '70%',
    marginBottom: theme.spacing(1)
  }
}));

const pageTitle = <FormattedMessage id="routes.unhandledError" />;

export const UnhandledErrorPage = ({ eventId }) => {
  usePageTitle([{ messageId: 'routes.unhandledError' }]);

  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const handleClick = () => {
    window.location.replace('/');
  };

  return (
    <>
      <StickyHeader showMenuButton={isMobile} title={pageTitle} />
      <div className={classes.root}>
        <div className={classes.container}>
          <UnhandledErrorTitle />
          <UnhandledErrorDescription eventId={eventId} />
          <HomeButton to="/" onClick={handleClick} />
        </div>
      </div>
    </>
  );
};

UnhandledErrorPage.propTypes = {
  eventId: PropTypes.string
};

export default UnhandledErrorPage;
