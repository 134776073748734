import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const currenciesQuery = gql`
  query getCurrencies2 {
    currencies {
      id
      uri
      name
      symbol
      displayText
    }
  }
`;

export const useCurrencies = () => {
  const { data } = useQuery(currenciesQuery);
  const currencies = get(data, 'currencies', []);

  return currencies;
};
