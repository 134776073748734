import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import HomeButton from '~/modules/common/components/HomeButton';
import {
  UnauthorizedDescription,
  UnauthorizedTitle
} from '~/modules/common/unauthorized/components';
import { usePageTitle } from '~/modules/common/title';
import StickyHeader from '../components/StickyHeader/StickyHeader';
import { useIsBreakpointDown } from '../hooks/useBreakpoint';
import withStyles from './withStyles';

const pageTitle = <FormattedMessage id="routes.notAuthorized" />;

export const NotAuthorizedPage = ({ classes }) => {
  usePageTitle([{ messageId: 'routes.notAuthorized' }]);
  const isMobile = useIsBreakpointDown('sm');

  return (
    <>
      <StickyHeader showMenuButton={isMobile} title={pageTitle} />
      <div className={classes.root}>
        <div className={classes.container}>
          <UnauthorizedTitle />
          <UnauthorizedDescription descriptionId="notAuthorized.canNotViewPage" />
          <Hidden smUp>
            <HomeButton to="/" />
          </Hidden>
        </div>
      </div>
    </>
  );
};

NotAuthorizedPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(NotAuthorizedPage);
