import { gql } from 'graphql-tag';
import projectTotalsFragment2, {
  ALLOCATION_STATUS_LIST,
  RESOURCE_REQUEST_STATUS_LIST
} from '~/modules/resourcing/common/fragments/projectTotalsFragment2';
import { normalizeFilter } from '../common/util/resourceRequestUtil';
import { getCostAndHoursDelta } from '../util/getCostAndHoursDelta';

export const GET_RESOURCE_REQUEST_TOTALS = gql`
  fragment ResourceRequestFragment on ResourceRequest {
    id
    totalHours
    totalRequestedCostByCurrency {
      baseCurrency {
        currency {
          id
        }
        amount
      }
      projectBudgetCurrency {
        currency {
          id
        }
        amount
      }
    }
  }
`;

export const readRequestedTotalCostAndTotalHoursFromCache = ({
  proxy,
  request
}) => {
  return proxy.readFragment({
    id: proxy.identify({
      __typename: request.__typename,
      id: request.id
    }),
    fragment: GET_RESOURCE_REQUEST_TOTALS
  });
};

export const writeRequestedTotalCostAndTotalHoursToCache = ({
  proxy,
  request,
  updatedData
}) => {
  proxy.writeFragment({
    id: proxy.identify({
      __typename: request.__typename,
      id: request.id
    }),
    fragment: GET_RESOURCE_REQUEST_TOTALS,
    data: updatedData
  });
};

export const invokeUpdateResourceRequestAggregateCache = ({
  proxy,
  projectId,
  costAndHoursDelta,
  filter,
  isResourceActualModeEnabled
}) => {
  const projectData = {
    __typename: 'Project',
    id: projectId
  };

  const cacheID = proxy.identify(projectData);
  const normalizedFilter = normalizeFilter(filter || {});

  const queryVariables = {
    allocationStatusList: ALLOCATION_STATUS_LIST,
    includeActualsResourceUsers: isResourceActualModeEnabled
  };

  const totals = proxy.readFragment({
    id: cacheID,
    fragment: projectTotalsFragment2,
    variables: {
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      filter: normalizedFilter,
      ...queryVariables
    }
  });

  if (!totals) return;

  const { resourceRequestsTotals } = totals;

  const newResourceRequestTotals = {
    ...resourceRequestsTotals,
    totalCost: {
      ...resourceRequestsTotals.totalCost,
      amount:
        resourceRequestsTotals.totalCost.amount +
        costAndHoursDelta.totalCost.costDelta
    },
    totalHours:
      resourceRequestsTotals.totalHours +
      costAndHoursDelta.totalHours.hoursDelta
  };

  proxy.writeFragment({
    id: cacheID,
    fragment: projectTotalsFragment2,
    variables: {
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      filter: normalizedFilter,
      ...queryVariables
    },
    data: {
      ...totals,
      resourceRequestsTotals: newResourceRequestTotals
    }
  });
};

export const updateProjectTotalsCacheForNewRequest = ({
  proxy,
  newRequest,
  filter,
  isResourceActualModeEnabled
}) => {
  const costAndHoursDelta = getCostAndHoursDelta({
    newTotalCost:
      newRequest.totalRequestedCostByCurrency?.projectBudgetCurrency ||
      newRequest.totalRequestedCostByCurrency?.baseCurrency,
    newHours: newRequest.totalHours
  });

  invokeUpdateResourceRequestAggregateCache({
    proxy,
    projectId: newRequest.projectUri,
    costAndHoursDelta,
    filter: filter || {},
    isResourceActualModeEnabled
  });
};
