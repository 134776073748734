import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const RESOURCE_POOLS_FOR_USER = gql`
  query getPageOfAssignedResourcePoolsForUser(
    $userSlug: String!
    $page: Int!
    $pageSize: Int!
    $searchFilter: ResourcePoolSearchFilter!
  ) {
    resource(userSlug: $userSlug) {
      id
      resourcePools(
        page: $page
        pageSize: $pageSize
        searchFilter: $searchFilter
      ) {
        id
        displayText
      }
    }
  }
`;

const useAssignedResourcePools = ({
  userSlug,
  page = 1,
  pageSize,
  searchFilter
}) => {
  const { loading, data, refetch } = useQuery(RESOURCE_POOLS_FOR_USER, {
    variables: {
      userSlug,
      page,
      pageSize,
      searchFilter
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  });

  const resource = get(data, 'resource') || {};
  const { resourcePools = [] } = resource;

  return {
    resourcePools,
    loading,
    refetch
  };
};

export default useAssignedResourcePools;
