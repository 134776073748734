import { useCallback } from 'react';

export const HEADER_ADD = 'add';

export const useOnGridHeaderClick = ({ setGanttRef, openAddTaskDialog }) =>
  useCallback(
    gantt => name => {
      switch (name) {
        case HEADER_ADD: {
          setGanttRef(gantt);
          openAddTaskDialog();
          break;
        }
        default:
          return false;
      }

      return true;
    },
    [setGanttRef, openAddTaskDialog]
  );

export default useOnGridHeaderClick;
