import React from 'react';
import { Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { ChartSkeletonRows } from '~/modules/common/charts/timeline/components';

const useLeftComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 40,
    width: 350,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden',
    borderRight: 1,
    padding: theme.spacing(0, 0.5)
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

export const RowSkeletonLeftComponent = () => {
  const classes = useLeftComponentStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={10}
          height={12}
        />
      </div>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={250}
          height={12}
        />
      </div>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={90}
          height={12}
        />
      </div>
    </Paper>
  );
};

const useRightComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 30,
    width: 200,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden'
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

export const RowSkeletonRightComponent = () => {
  const classes = useRightComponentStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={200}
          height={12}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  leftComponent: {
    width: 350
  },
  rightComponent: {
    width: 200
  }
}));

const QuickAllocationProjectChartRowsLoading = ({
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ChartSkeletonRows
      classes={classes}
      LeftComponent={RowSkeletonLeftComponent}
    />
  );
};

QuickAllocationProjectChartRowsLoading.propTypes = {
  classes: PropTypes.object
};

export default QuickAllocationProjectChartRowsLoading;
