import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { MilestoneBillPlanTitle } from '~/modules/projects/project/BillPlanV2/components';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.body2.fontSize
  },
  description: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.error.dark,
    marginLeft: theme.spacing(3.5)
  }
}));

export const TaskNameFormatter = ({
  classes: classesOverride,
  field,
  record
}) => {
  const { displayText, errorDetails } = record[field] || {};
  const classes = useStyles({ classes: classesOverride });

  return (
    <>
      <MilestoneBillPlanTitle
        displayText={displayText}
        errorCode={errorDetails && errorDetails.milestoneErrorCode}
        classes={useMemo(() => ({ title: classes.title }), [classes.title])}
      />
      {errorDetails && errorDetails.milestoneErrorCode === 'NO_MORE_MILESTONE' && (
        <Typography className={classes.description}>
          <FormattedMessage id="billPlan.taskIsNotAMilestone" />
        </Typography>
      )}
    </>
  );
};

TaskNameFormatter.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default TaskNameFormatter;
