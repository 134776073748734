import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const UPDATE_CURRENCY = gql`
  mutation updateCurrencyForBill($input: UpdateCurrencyForBillInput!) {
    updateCurrencyForBill2(input: $input) {
      batchUri
    }
  }
`;

export const useUpdateCurrencyForBill = ({ billId, setBatchState }) => {
  const [updateCurrencyForBill] = useMutation(UPDATE_CURRENCY);

  return {
    updateCurrencyForBill: async ({ currencyId }) => {
      const result = await updateCurrencyForBill({
        variables: {
          input: { billId, currencyId }
        }
      });

      setBatchState({
        batchId: result?.data?.updateCurrencyForBill2?.batchUri,
        batchInProgress: true
      });
    }
  };
};

export default useUpdateCurrencyForBill;
