import React from 'react';
import PropTypes from 'prop-types';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  }
}));

export const SideCard = ({ classes, children }) => {
  const paperClasses = useStyles({ classes });

  return (
    <Paper classes={paperClasses} square elevation={1}>
      {children}
    </Paper>
  );
};

SideCard.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node
};

export default SideCard;
