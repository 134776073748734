export const dialogContentForDeletion = {
  title: 'removeProjectDialog.title',
  content: 'removeProjectDialog.content',
  action: 'removeProjectDialog.action',
  cancel: 'removeProjectDialog.cancel'
};

export const dialogContentForDisable = {
  title: 'disableProjectDialog.title',
  content: 'disableProjectDialog.content',
  secondaryContent: 'disableProjectDialog.secondaryContent',
  action: 'disableProjectDialog.action',
  cancel: 'disableProjectDialog.cancel'
};

export default ({ canBeDeleted }) =>
  canBeDeleted ? dialogContentForDeletion : dialogContentForDisable;
