import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { ResourceRequestAccessLevel } from '~/types';
import { LegacyResourceRequestItem } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestItem';
import { checkIfResourceAllocationIsReadOnly } from '~/modules/resourcing/enhancers/withIsResourceAllocationReadonly';
import { useRedirectToAssignResource } from '~/modules/resourcing/hooks';
import AssignResourceButton from '~/modules/resourcing/common/components/AssignResourceButton';
import { useHasPermission } from '~/modules/common/permissions';
import { ResourceRequestAllocationActions } from '~/modules/resourcing/common/components';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { RESOURCING_ALLOCATION_MODEL } from '~/modules/common/enums';
import { useMergeDialogState } from '~/modules/resourcing/common/hooks';
import AllocatedResourceRequestUserItem from './components/AllocatedResourceRequestUserItem';
import useStyles from './useStyles';

export const AllocatedResourceRequestItem = ({
  classes: { openButton, ...classesOverrides },
  qeIdTag,
  resourceRequest,
  displayEntityType,
  maxSkillLevel,
  requestAccessLevel,
  project
}) => {
  const history = useHistory();
  const classes = useStyles({ classes: classesOverrides });

  const mergeDialogState = useMergeDialogState();
  const { resourcingAllocationModel } = useProjectContext();
  const { isResourceAllocationReadOnly } = checkIfResourceAllocationIsReadOnly({
    resourceRequest
  });

  const { resourceAllocations } = resourceRequest;

  const isResourceAllocationExists = resourceAllocations.length !== 0;

  const onAssignResourceClick = useRedirectToAssignResource({
    history,
    resourceRequest
  });

  const reqClasses = useMemo(() => ({ openButton }), [openButton]);

  const isEditAssignmentEnabled = useHasPermission({
    actionUri:
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
  });

  const actions = useMemo(
    () => (
      <ResourceRequestAllocationActions
        project={project}
        resourceRequest={resourceRequest}
        requestAccessLevel={requestAccessLevel}
        isProjectManagerView={false}
        isProjectResourcingTab
        isResourceRequestMergeEnabled={
          resourcingAllocationModel !== RESOURCING_ALLOCATION_MODEL.MODEL_0
        }
        mergeDialogState={mergeDialogState}
      />
    ),
    [
      project,
      resourceRequest,
      requestAccessLevel,
      resourcingAllocationModel,
      mergeDialogState
    ]
  );

  const resourceRequestItemClasses = useMemo(
    () => ({
      column: classes.column,
      clickableColumn: classes.clickableColumn,
      openButton
    }),
    [classes.clickableColumn, classes.column, openButton]
  );

  return (
    <>
      <div className={classNames(classes.allocationRoot, classes.roleItem)}>
        <LegacyResourceRequestItem
          qeIdTag={qeIdTag}
          actions={actions}
          displayEntityType={displayEntityType}
          resourceRequest={resourceRequest}
          project={project}
          isReadOnly
          maxSkillLevel={maxSkillLevel}
          classesOverrides={resourceRequestItemClasses}
        />
      </div>
      {!isResourceAllocationExists ? (
        <div className={classes.allocation}>
          <AssignResourceButton
            qeIdTag={qeIdTag}
            quantity={resourceRequest.quantity}
            onClick={onAssignResourceClick}
          />
        </div>
      ) : (
        <div className={classes.resourceItems}>
          {resourceAllocations.map((resourceAllocation, index) => {
            const resourceUri = resourceAllocation.user
              ? resourceAllocation.user.userUri
              : `NoUser`;

            return (
              <div key={uuidv4()} className={classes.userItem}>
                <AllocatedResourceRequestUserItem
                  classes={reqClasses}
                  qeIdTag={`${qeIdTag}_${resourceUri}`}
                  isReadonly={
                    isResourceAllocationReadOnly || !isEditAssignmentEnabled
                  }
                  resourceRequest={resourceRequest}
                  resourceAllocation={resourceAllocation}
                  resourceAllocationUserUri={resourceAllocation.user.userUri}
                  resourceAllocationLoad={resourceAllocation.load}
                  requestAccessLevel={requestAccessLevel}
                  isAdjustedLoading={resourceAllocation.isAdjustedLoading}
                  project={project}
                />
              </div>
            );
          })}
          {!isResourceAllocationReadOnly &&
            resourceAllocations.length < resourceRequest.quantity && (
              <div className={classes.allocationAndResource}>
                <AssignResourceButton
                  qeIdTag={qeIdTag}
                  quantity={
                    resourceRequest.quantity - resourceAllocations.length
                  }
                  onClick={onAssignResourceClick}
                />
              </div>
            )}
        </div>
      )}
    </>
  );
};

AllocatedResourceRequestItem.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  resourceRequest: PropTypes.object.isRequired,
  displayEntityType: PropTypes.string,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ]),
  project: PropTypes.object,
  maxSkillLevel: PropTypes.number
};

export default AllocatedResourceRequestItem;
