import React from 'react';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { PropTypes } from 'prop-types';
import { AddSharp as AddIcon } from '@material-ui/icons';

export const SelectTagButton = ({ onClick, data: { label }, disabled }) => {
  return disabled ? null : (
    <Button size="small" onClick={onClick} disabled={disabled}>
      <AddIcon color="primary" />
      <Typography variant="inherit" color="primary">
        {label}
      </Typography>
    </Button>
  );
};

SelectTagButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  disabled: PropTypes.bool
};

export default SelectTagButton;
