import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me';
import {
  Decimal,
  Percentage,
  NoValue,
  SummaryFormattedNumber
} from '~/modules/common/components';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { ResourceRequestStatus } from '~/types';
import { roundToDecimals } from '~/modules/resourcing/common/util';
import {
  CostVarianceNumber,
  HoursVarianceNumber
} from '~/modules/resourcing/common/components/VarianceStyledFormattedNumber';
import useStyles from './useStyles';

const ResourceUserRequestedCostAllocationTooltip = ({
  totalAllocatedHours,
  loading,
  totalCost,
  startDate,
  endDate,
  resourceRequest,
  hideCost = false
}) => {
  const { load: requestedLoading, requestStatus } = resourceRequest;

  const requestedHours = resourceRequest.scheduleRules
    ? getTotalHoursForDateRangeFromScheduleRules({
        start: startDate,
        end: endDate,
        scheduleRules: resourceRequest.scheduleRules
      })
    : 0;

  const requestedCost = {
    amount: requestedHours * resourceRequest.roleRate,
    currency: resourceRequest.currency
  };

  const { permissionsMap } = useMeContext();

  const hasCostPermissions = Boolean(
    permissionsMap['urn:replicon:project-action:view-cost-data'] ||
      permissionsMap['urn:replicon:user-action:view-cost-data']
  );

  const showCostField = hasCostPermissions && !hideCost;

  const showCostValue =
    requestedCost.currency && totalCost.symbol
      ? !(requestedCost.currency.displayText !== totalCost.symbol)
      : true;

  const classes = useStyles();

  const { formatMessage } = useIntl();

  const loadingDifference = requestedLoading - loading;
  const hoursDifference = requestedHours - totalAllocatedHours;

  const amountDifference =
    (requestedCost?.amount || 0) - (totalCost?.amount || 0);

  const requestedCostAmount = requestedCost?.amount || 0;
  const totalCostAmount = totalCost?.amount || 0;

  return (
    <>
      <div className={classes.title}>
        {shortDateRangeFormat({ start: startDate, end: endDate })}
        <br />
      </div>

      <ul
        className={classNames(classes.list, {
          [classes.listNoCost]: !showCostField
        })}
      >
        {requestStatus && requestStatus !== ResourceRequestStatus.Complete && (
          <>
            <li className={classes.item}>
              <FormattedMessage id="resourceAssignmentDialog.requested" />
            </li>
            <li className={classes.item}>
              <Decimal
                value={requestedHours}
                suffix={formatMessage({
                  id: 'resourceAssignmentDialog.hoursSuffix'
                })}
                fixedDecimalScale={false}
                className={classes.value}
              />
            </li>
            <li className={classes.item}>
              <Percentage
                value={requestedLoading}
                fixedDecimalScale={false}
                className={classes.value}
              />
            </li>
            {showCostField && (
              <li className={classes.item}>
                <Decimal
                  value={requestedCost?.amount || 0}
                  prefix={
                    requestedCost.currency?.displayText || totalCost?.symbol
                  }
                  fixedDecimalScale={false}
                  className={classes.value}
                />
              </li>
            )}
          </>
        )}
        <li className={classes.item}>
          <FormattedMessage id="resourceAssignmentDialog.allocated" />
        </li>
        <li className={classes.item}>
          <Decimal
            value={totalAllocatedHours}
            suffix={formatMessage({
              id: 'resourceAssignmentDialog.hoursSuffix'
            })}
            fixedDecimalScale={false}
            className={classes.value}
          />
        </li>
        <li className={classes.item}>
          <Percentage
            value={loading}
            fixedDecimalScale={false}
            className={classes.value}
          />
        </li>
        {showCostField && (
          <li className={classes.item}>
            {totalCost ? (
              <Decimal
                value={totalCost.amount}
                prefix={totalCost.symbol}
                fixedDecimalScale={false}
                className={classes.value}
              />
            ) : (
              <NoValue />
            )}
          </li>
        )}
        {requestStatus && requestStatus !== ResourceRequestStatus.Complete && (
          <>
            <li className={classes.item}>
              <FormattedMessage id="resourceAssignmentDialog.difference" />
            </li>
            <li className={classes.item}>
              {roundToDecimals(hoursDifference) === 0 ? (
                <Decimal
                  value={0}
                  suffix={formatMessage({
                    id: 'resourceAssignmentDialog.hoursSuffix'
                  })}
                  fixedDecimalScale={false}
                  className={classes.value}
                />
              ) : (
                <HoursVarianceNumber
                  value={hoursDifference}
                  showAddIcon={totalAllocatedHours > requestedHours}
                  formattedMessageKey="resourceActualSummaryDialog.hours"
                />
              )}
            </li>
            <li className={classes.item}>
              {roundToDecimals(loadingDifference) === 0 ? (
                <Percentage
                  value={0}
                  fixedDecimalScale={false}
                  className={classes.value}
                />
              ) : (
                <SummaryFormattedNumber
                  value={loadingDifference}
                  showAddIcon={loading > requestedLoading}
                  isHigher={loading > requestedLoading}
                  formattedMessageKey="resourceActualSummaryDialog.percentage"
                />
              )}
            </li>
            {showCostField && (
              <li className={classes.item}>
                {showCostValue ? (
                  roundToDecimals(amountDifference) === 0 ? (
                    <Decimal
                      value={0}
                      prefix={
                        requestedCost.currency?.displayText ||
                        totalCost.currency?.symbol
                      }
                      fixedDecimalScale={false}
                      className={classes.value}
                    />
                  ) : (
                    <CostVarianceNumber
                      value={amountDifference}
                      prefix={
                        requestedCost.currency?.displayText ||
                        totalCost.currency?.symbol
                      }
                      showAddIcon={totalCostAmount > requestedCostAmount}
                    />
                  )
                ) : (
                  <NoValue />
                )}
              </li>
            )}
          </>
        )}
      </ul>
    </>
  );
};

ResourceUserRequestedCostAllocationTooltip.propTypes = {
  totalAllocatedHours: PropTypes.number,
  loading: PropTypes.number.isRequired,
  totalCost: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  requestStatus: PropTypes.string,
  resourceRequest: PropTypes.object,
  hideCost: PropTypes.bool
};

export default ResourceUserRequestedCostAllocationTooltip;
