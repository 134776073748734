import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RemoveCircleSharp } from '@material-ui/icons';

import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton
} from '@material-ui/core';

import FileIcon from '~/modules/attachments/icons/FileIcon';

import FileUploadFileSize from './FileUploadFileSize';

const primaryTypographyProps = {
  component: 'p',
  noWrap: true
};

const FileUploadListItem = ({ fileName, fileSize, onRemoveItem, index }) => {
  const fileParts = fileName.split('.');
  const fileNameOnly = fileParts[0];
  const fileExtension = fileParts[fileParts.length - 1];
  const fileSizeText = useMemo(
    () => (
      <>
        {fileExtension} - <FileUploadFileSize fileSizeBytes={fileSize} />
      </>
    ),
    [fileExtension, fileSize]
  );

  const onClick = useCallback(
    e => {
      e.stopPropagation();
      onRemoveItem(index);
    },
    [index, onRemoveItem]
  );

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <FileIcon type={fileExtension} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={primaryTypographyProps}
        primary={fileNameOnly}
        secondary={fileSizeText}
      />
      <IconButton onClick={onClick}>
        <RemoveCircleSharp color="secondary" fontSize="small" />
      </IconButton>
    </ListItem>
  );
};

FileUploadListItem.propTypes = {
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  onRemoveItem: PropTypes.func,
  index: PropTypes.number
};

export default FileUploadListItem;
