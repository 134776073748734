import PropTypes from 'prop-types';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';

const PeriodLabel = ({ scale, start, end }) => {
  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    return shortDateRangeFormat({ start, end });
  }

  return start.toLocaleString({ month: 'long', year: 'numeric' });
};

PeriodLabel.propTypes = {
  scale: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object
};

export default PeriodLabel;
