import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
  IconButton,
  ListItemIcon,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { NoValue, DateRange } from '~/modules/common/components';
import {
  MilestoneIcon,
  OpenInDrawerIcon
} from '~/modules/common/components/Icons';
import { useDialogState } from '~/modules/common/hooks';
import { RemoveTaskAssignmentConfirmationDialog } from '~/modules/projects/resourcing-plan/components';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import { useOnChangeHandlers } from '../hooks';
import { AssignmentInfo } from './AssignmentInfo';

const indentWidths = [16, 40, 56, 72, 88, 104, 120, 136, 152, 168, 184, 200];

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 0,
    paddingBottom: 0,
    cursor: 'default',
    '&:hover': {
      background: 'none'
    }
  },
  taskRowContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflow: 'hidden',
    width: '65%'
  },
  assignmentInfoContainer: {
    width: '35%'
  },
  indent: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'stretch'
  },
  primary: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold
  },
  dateRange: {
    color: theme.palette.text.secondary
  },
  milestoneIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(0, 0.5, 0, 0),
    verticalAlign: 'text-bottom'
  },
  taskDetailsContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    flexDirection: 'column',
    width: '100%'
  },
  checkArea: {
    minWidth: theme.spacing(4),
    width: theme.spacing(4)
  },
  circularProgress: {
    paddingLeft: theme.spacing(1)
  }
}));

export const TaskAssignmentRow = ({
  task,
  resource,
  resourceEstimate,
  permittedActionUris,
  projectId,
  primaryRole,
  onTaskAssignmentUpdateSuccess
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const label = useMemo(
    () => ({
      checkBox: formatMessage({ id: 'taskAssignmentDialog.select' }),
      openDrawerIcon: formatMessage({ id: 'taskAssignmentDialog.openDrawer' })
    }),
    [formatMessage]
  );

  const {
    id: taskId,
    displayText,
    fullPath,
    startDate,
    endDate,
    isMilestone,
    assignedRole: taskRole,
    totalResourceEstimateHours,
    estimatedHours: taskEstimatedHours
  } = task;

  const { estimateId, projectRole, initialEstimatedHours } =
    resourceEstimate || {};

  const indentWidth =
    typeof indentWidths[fullPath.length - 1] === 'number'
      ? indentWidths[fullPath.length - 1]
      : 0;

  const [isAssignedTask, setIsAssignedTask] = useState(
    Boolean(estimateId || estimateId === null)
  );

  const defaultRoleForUnassignedTask = taskRole || primaryRole;

  const [roleDisplayText, setRoleDisplayText] = useState(
    isAssignedTask
      ? projectRole?.displayText
      : defaultRoleForUnassignedTask?.displayText
  );

  const [estimatedHours, setEstimatedHours] = useState(
    isAssignedTask
      ? initialEstimatedHours
      : taskEstimatedHours > totalResourceEstimateHours
      ? roundToDecimals(taskEstimatedHours - totalResourceEstimateHours)
      : undefined
  );

  const {
    open: removeTaskAssignmentDialogOpen,
    openDialog: openRemoveTaskAssignmentDialog,
    closeDialog: closeRemoveTaskAssignmentDialog
  } = useDialogState(false);

  const {
    loading,
    handleTaskClick,
    handleDrawerIconClick,
    onRemoveTaskAssignmentSuccess
  } = useOnChangeHandlers({
    defaultRole: defaultRoleForUnassignedTask,
    estimatedHours,
    isAssignedTask,
    taskId,
    userId: resource.id,
    setIsAssignedTask,
    setRoleDisplayText,
    setEstimatedHours,
    closeRemoveTaskAssignmentDialog,
    openRemoveTaskAssignmentDialog,
    onTaskAssignmentUpdateSuccess
  });

  const dateRangeClasses = useMemo(
    () => ({
      range: classes.dateRange
    }),
    [classes.dateRange]
  );

  const canEditTask = permittedActionUris.includes(
    'urn:replicon:project-action:edit-tasks'
  );

  const canViewTask = permittedActionUris.includes(
    'urn:replicon:project-action:view-tasks'
  );

  return (
    <>
      {removeTaskAssignmentDialogOpen && (
        <RemoveTaskAssignmentConfirmationDialog
          open={removeTaskAssignmentDialogOpen}
          onClose={closeRemoveTaskAssignmentDialog}
          onRemoveTaskAssignmentSuccess={onRemoveTaskAssignmentSuccess}
          task={task}
          user={resource}
          projectId={projectId}
        />
      )}
      <ListItem className={classes.root} disabled={loading}>
        <ListItemIcon className={classes.checkArea}>
          {loading ? (
            <span className={classes.circularProgress}>
              <CircularProgress size={20} />
            </span>
          ) : (
            <Checkbox
              onChange={canEditTask ? handleTaskClick : undefined}
              checked={isAssignedTask}
              color="primary"
              disableRipple
              disabled={!canEditTask}
              inputProps={getAriaLabel(label.checkBox)}
            />
          )}
        </ListItemIcon>
        <div className={classes.taskRowContainer}>
          <div
            className={classes.indent}
            style={useMemo(() => ({ width: indentWidth }), [indentWidth])}
          />
          <div className={classes.taskDetailsContainer}>
            <span className={classes.primary}>
              {isMilestone ? (
                <MilestoneIcon className={classes.milestoneIcon} />
              ) : null}
              {displayText || <NoValue />}
            </span>
            <DateRange
              start={startDate}
              end={endDate}
              classes={dateRangeClasses}
            />
          </div>
          {canViewTask && (
            <ListItemIcon>
              <IconButton
                edge="end"
                component={Link}
                to={`#/task/${taskId}`}
                onClick={handleDrawerIconClick}
                aria-label={label.openDrawerIcon}
              >
                <OpenInDrawerIcon />
              </IconButton>
            </ListItemIcon>
          )}
        </div>
        <div className={classes.assignmentInfoContainer}>
          <AssignmentInfo
            roleDisplayText={roleDisplayText}
            estimatedHours={estimatedHours}
            isAssignedTask={isAssignedTask}
          />
        </div>
      </ListItem>
    </>
  );
};

TaskAssignmentRow.propTypes = {
  task: PropTypes.object,
  resource: PropTypes.object.isRequired,
  resourceEstimate: PropTypes.object,
  permittedActionUris: PropTypes.array,
  projectId: PropTypes.string.isRequired,
  primaryRole: PropTypes.object,
  onTaskAssignmentUpdateSuccess: PropTypes.func.isRequired
};

export default TaskAssignmentRow;
