import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import Decimal from '~/modules/common/components/Decimal';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  value: {
    fontWeight: 600,
    fontSize: theme.spacing(1.75)
  },
  noValue: {
    fontWeight: 400
  }
});

export const HoursFormatter = ({ field, record }) => {
  const classes = useStyles();
  const value = record[field];

  return value !== null ? (
    <Decimal
      className={value !== 0 ? classes.value : classes.noValue}
      value={value}
      fixedDecimalScale
    />
  ) : (
    <FormattedMessage id="taskDrawer.na" />
  );
};

HoursFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default HoursFormatter;
