import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { CountAndText } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  rejected: {
    borderLeft: `${theme.spacing(0.5)}px solid ${
      theme.palette.resourceRequest.requestRejected.main
    }`
  },
  proposed: {
    borderLeft: `${theme.spacing(0.5)}px solid ${
      theme.palette.resourceRequest.proposed.main
    }`
  },
  draft: {
    borderLeft: `${theme.spacing(0.5)}px solid ${
      theme.palette.resourceRequest.draft.main
    }`
  }
}));

export const ProjectRow = ({
  classes: classesOverride,
  resourceRequestsProject,
  requestType
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ButtonBase
      data-qe-id={`${requestType}-${resourceRequestsProject.project.displayText}`}
      className={classNames(classes.root, classes[requestType])}
      component={Link}
      to={`/projects/${resourceRequestsProject.project.slug}/resourcingplan`}
      disableRipple
    >
      <CountAndText
        count={resourceRequestsProject.requestCounts}
        text={resourceRequestsProject.project.displayText}
        textVariant="body2"
      />
    </ButtonBase>
  );
};

ProjectRow.propTypes = {
  classes: PropTypes.object,
  resourceRequestsProject: PropTypes.object,
  requestType: PropTypes.string
};

export default ProjectRow;
