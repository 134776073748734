import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import User from '~/modules/common/components/User';
import UserAvailabilityDropdown from '~/modules/common/components/UserAvailabilityDropdown';
import { ResourceAllocationUserRoleColumn } from './components';

export const LeftItemContentModel0 = ({
  hasResourceAllocations,
  isEditAllowed,
  classes,
  userClasses,
  chartDateRange,
  onUserChange,
  allocatedUser,
  userAvailabilityDropdownInputRef,
  onBlur,
  formatMessage,
  resourceAllocationReferencesForUser,
  isResourceActualModeEnabled,
  actualsClasses,
  rowStyles,
  actions,
  qeIdTag,
  ActionsComponent,
  actionsComponentProps
}) => {
  return (
    <>
      <div className={classes.rowActions}>
        <ActionsComponent {...actionsComponentProps} />
      </div>
      {hasResourceAllocations && isEditAllowed ? (
        <div
          className={classNames(classes.userSelectionWrapper, {
            [classes.userSelectionWrapperWithNoActions]: !actions.length
          })}
        >
          <UserAvailabilityDropdown
            dataQeId={`${qeIdTag}_UserAvailabilityDropdown`}
            userClassesOverride={userClasses}
            dateRange={chartDateRange}
            onChange={onUserChange}
            value={allocatedUser.user}
            userRole={allocatedUser.roles?.[0]?.role}
            inputRef={userAvailabilityDropdownInputRef}
            onBlur={onBlur}
            placeholder={formatMessage({
              id: 'addResource.selectResource'
            })}
            optionTypeText={formatMessage({
              id: 'moreOptions.users'
            })}
          />
        </div>
      ) : (
        <div
          className={classNames(
            actions.length
              ? classes.readOnlyUserWithActions
              : classes.readOnlyUser
          )}
        >
          <User user={allocatedUser.user} />
        </div>
      )}
      <div className={classes.rolesContainer}>
        {(resourceAllocationReferencesForUser || []).map(
          ({ role, key, allocationId }, index, array) => (
            <ResourceAllocationUserRoleColumn
              classes={
                role?.id !== array[index + 1]?.role?.id
                  ? isResourceActualModeEnabled && allocationId
                    ? actualsClasses
                    : rowStyles
                  : null
              }
              key={key}
              allocationId={allocationId}
              isEditAllowed={isEditAllowed}
              role={role}
              userId={allocatedUser.user.id}
              qeIdTag={qeIdTag}
            />
          )
        )}
      </div>
    </>
  );
};

LeftItemContentModel0.propTypes = {
  hasResourceAllocations: PropTypes.bool,
  isEditAllowed: PropTypes.bool,
  classes: PropTypes.object,
  actions: PropTypes.array,
  qeIdTag: PropTypes.string,
  userClasses: PropTypes.object,
  chartDateRange: PropTypes.object,
  onUserChange: PropTypes.func,
  allocatedUser: PropTypes.object,
  userAvailabilityDropdownInputRef: PropTypes.object,
  onBlur: PropTypes.func,
  formatMessage: PropTypes.func,
  resourceAllocationReferencesForUser: PropTypes.array,
  isResourceActualModeEnabled: PropTypes.bool,
  isPsaRmpImportDeleteAllocationEnabled: PropTypes.bool,
  actualsClassesFFOn: PropTypes.object,
  actualsClasses: PropTypes.object,
  rowStyles: PropTypes.object,
  ActionsComponent: PropTypes.elementType.isRequired,
  actionsComponentProps: PropTypes.object
};

export default LeftItemContentModel0;
