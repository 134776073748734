import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';

export const USERS = gql`
  query GetUsersByPolicy(
    $page: Int
    $pagesize: Int
    $searchTerm: String
    $policyId: String
    $userAccessRoleUri: String
  ) {
    users(
      page: $page
      pagesize: $pagesize
      searchTerm: $searchTerm
      policyId: $policyId
      userAccessRoleUri: $userAccessRoleUri
    ) {
      id
      displayText
    }
  }
`;

export const useUsersByPolicy = ({
  policyId,
  searchTerm,
  userAccessRoleUri,
  skip
}) => {
  const { data, loading } = useQuery(USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pagesize: PAGE_SIZE + 1,
      searchTerm,
      policyId,
      userAccessRoleUri
    },
    context: {
      debounceKey: 'user-search',
      debounceTimeout: 250
    },
    skip
  });

  const options = get(data, 'users', []);

  return {
    loading,
    hasMore: options.length > PAGE_SIZE,
    options: options.slice(0, PAGE_SIZE)
  };
};

export default useUsersByPolicy;
