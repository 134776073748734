export const billingTransactionTypes = [
  {
    id: 'BILL',
    name: 'Bill',
    displayText: 'billingEntryAmountType.bill'
  },
  {
    id: 'PAYMENT',
    name: 'Payment',
    displayText: 'billingEntryAmountType.payment'
  },
  {
    id: 'CREDITMEMO',
    name: 'Credit Memo',
    displayText: 'billingEntryAmountType.creditMemo'
  }
];
