import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress
} from '@material-ui/core';
import {
  useActionStyles,
  useContentStyles,
  useSpinnerStyles
} from './useStyles';

export const ReleaseAllocationsDialogLoadingContent = ({ onCancelClick }) => {
  const actionClasses = useActionStyles();
  const contentClasses = useContentStyles();
  const loadingStyles = useSpinnerStyles();

  return (
    <>
      <DialogContent
        id="release-allocations-dialog-content"
        classes={contentClasses}
      >
        <div className={loadingStyles.progress}>
          <CircularProgress size={24} className={loadingStyles.spinner} />
        </div>
      </DialogContent>
      <DialogActions classes={actionClasses}>
        <Button onClick={onCancelClick}>
          <FormattedMessage id="releaseResourceRequestDialog.cancel" />
        </Button>
      </DialogActions>
    </>
  );
};

ReleaseAllocationsDialogLoadingContent.propTypes = {
  onCancelClick: PropTypes.func.isRequired
};

export default ReleaseAllocationsDialogLoadingContent;
