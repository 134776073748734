import React from 'react';
import PropTypes from 'prop-types';
import { Percentage, NoValue } from '~/modules/common/components';

export const Percent = ({ field, record, className }) => {
  const percent = record[field];

  return percent ? (
    <Percentage value={percent} className={className} />
  ) : (
    <NoValue />
  );
};

Percent.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string
};

export default Percent;
