import React from 'react';
import { PropTypes } from 'prop-types';
import { ProgramIcon } from '~/modules/common/components/Icons';
import LabelledIcon from '../LabelledIcon';

const Program = ({ classes, program: { displayText } }) => (
  <LabelledIcon
    classes={classes}
    icon={<ProgramIcon size="xsmall" />}
    label={displayText}
  />
);

Program.propTypes = {
  classes: PropTypes.object,
  program: PropTypes.shape({
    displayText: PropTypes.string.isRequired
  }).isRequired
};

export default Program;
