import React from 'react';
import { skillTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import SkillFacetDetails from './SkillFacetDetails';

const SkillFacetDetailsWithNoNoneOption = props => (
  <SkillFacetDetails {...props} includeNoneOption={false} />
);

export const SkillFacetWithNoNoneOption = {
  tag: skillTag,
  makeCriteriaProvider,
  DetailsControl: SkillFacetDetailsWithNoNoneOption
};

export default {
  tag: skillTag,
  makeCriteriaProvider,
  DetailsControl: SkillFacetDetails
};
