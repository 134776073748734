import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { NoDataItem, AddFabButton } from '~/modules/common/components';
import { useHasPermission } from '~/modules/common/permissions';
import { useDialogState } from '~/modules/common/hooks';
import AddResourceRequestDrawer from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/AddResourceRequestDrawer';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import ResourceRequestSearchbox from '../components/ResourceRequestSearchbox';
import {
  useProjectResourceRequests,
  useResourceRequestToolbarContext
} from '../hooks';
import { useCompletedProjectResourceAllocations } from '../ResourceRequestChart/components/ResourceAllocationChart/hooks';
import MobileResourceRequests from './MobileResourceRequests';
import MobileResourceAllocations from './MobileResourceAllocations';

export const useNoDataClasses = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0, 0, 3),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const useToolBarStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0, 1, 0, 0)
  }
}));

export const MobileResourcingPlanPage = ({ isEditSwitchEnabled }) => {
  const intl = useIntl();
  const toolbarClasses = useToolBarStyles();
  const noDataClasses = useNoDataClasses();
  const { open, closeDialog, openDialog } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });
  const { project } = useProjectContext();
  const {
    filter,
    setSearchCriteria,
    searchCriteria,
    sort
  } = useResourceRequestToolbarContext();

  const {
    loadingRows,
    resourceRequests,
    loadMoreRows,
    hasMoreRows
  } = useProjectResourceRequests({
    ...filter,
    projectUri: project.id,
    sort
  });

  const {
    resourceAllocations,
    loadingRows: loadingAllocationRows,
    loadMoreRows: loadMoreAllocationRows,
    hasMoreRows: hasMoreAllocationRows
  } = useCompletedProjectResourceAllocations({
    ...filter,
    projectUri: project.id
  });

  const editProjectEnabled = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });

  const hasResourceRequests = Boolean(resourceRequests.length > 0);
  const hasAllocations = Boolean(resourceAllocations.length > 0);

  return (
    <>
      <StickyHeader level={3}>
        <div className={toolbarClasses.toolbar}>
          <ResourceRequestSearchbox
            searchCriteria={searchCriteria}
            setSearchCriteria={setSearchCriteria}
          />
        </div>
      </StickyHeader>
      {hasResourceRequests && (
        <MobileResourceRequests
          resourceRequests={resourceRequests}
          loadMoreRows={loadMoreRows}
          hasMoreRows={hasMoreRows}
          loadingRows={loadingRows}
          isEditSwitchEnabled={isEditSwitchEnabled}
        />
      )}
      {hasAllocations && (
        <MobileResourceAllocations
          resourceAllocations={resourceAllocations}
          loadMoreRows={loadMoreAllocationRows}
          hasMoreRows={hasMoreAllocationRows}
          loadingRows={loadingAllocationRows}
          isEditSwitchEnabled={isEditSwitchEnabled}
        />
      )}
      {!hasResourceRequests && !hasAllocations && (
        <NoDataItem classes={noDataClasses}>
          <FormattedMessage id="mobileResourcePlanning.noPlanning" />
        </NoDataItem>
      )}
      {editProjectEnabled && isEditSwitchEnabled && (
        <AddFabButton
          label={intl.formatMessage({ id: 'addResourceRequestButton.label' })}
          shortLabel={intl.formatMessage({
            id: 'addResourceRequestButton.shortLabel'
          })}
          dataQeId="addResourceRequestOpenDialog"
          onClick={openDialog}
        />
      )}
      {open && (
        <AddResourceRequestDrawer
          project={project}
          onClose={closeDialog}
          open={open}
          isMobile
        />
      )}
    </>
  );
};

MobileResourcingPlanPage.propTypes = {
  isEditSwitchEnabled: PropTypes.bool.isRequired
};

export default MobileResourcingPlanPage;
