import React, { useContext } from 'react';

const defaultContext = {
  navigatePage: null,
  currentPage: null,
  maximumPageSize: null,
  disableInfiniteScroll: false
};

export const ListTableNavigationContext = React.createContext(defaultContext);

export const useListTableNavigationContext = () =>
  useContext(ListTableNavigationContext);
