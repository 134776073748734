import { useCallback } from 'react';

export const useGanttTaskCollapseExpandHandler = ({
  expandedTasks,
  setExpandedTasks
}) => {
  const collapseTask = useCallback(
    id => {
      if (id && expandedTasks.tasks.includes(id)) {
        setExpandedTasks({
          ...expandedTasks,
          tasks: expandedTasks.tasks.filter(task => task !== id)
        });
      }
    },
    [expandedTasks, setExpandedTasks]
  );

  const expandTask = useCallback(
    id => {
      if (id && !expandedTasks.tasks.includes(id)) {
        setExpandedTasks({
          ...expandedTasks,
          tasks: [...expandedTasks.tasks, id]
        });
      }
    },
    [expandedTasks, setExpandedTasks]
  );

  return {
    collapseTask,
    expandTask
  };
};

export default useGanttTaskCollapseExpandHandler;
