import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const projectCostsQuery = gql`
  query GetProjectCostSeries(
    $projectId: String!
    $dateRange: DateTimeRangeInput!
    $currencyId: String
  ) {
    project(projectId: $projectId) {
      id
      costSeriesData(dateRange: $dateRange, currencyId: $currencyId) {
        currency {
          id
          symbol
        }
        dataPoints {
          period {
            periodStart: periodStart2
            periodEnd: periodEnd2
          }
          costAmountActual
          costAmountEstimated
        }
      }
    }
  }
`;

export const useProjectCosts = ({
  chartDataInput: { dateRange, currencyId, projectId }
}) => {
  const { loading, data } = useQuery(projectCostsQuery, {
    variables: { projectId, dateRange, currencyId },
    fetchPolicy: 'cache-and-network'
  });

  return {
    costSeriesData: (!loading && data.project?.costSeriesData.dataPoints) || [],
    costCurrency: (!loading && data.project?.costSeriesData.currency) || null,
    loading
  };
};

export default useProjectCosts;
