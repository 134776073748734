import { useMeContext } from '~/modules/me/useMeContext';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';

export const useMuiDrawerOverriddenProps = () => {
  const {
    featureFlags: { PSAPRPTaskEstimateCalculation }
  } = useMeContext();

  const isTaskAssignmentsEnabled =
    PSAPRPTaskEstimateCalculation ===
    TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT;

  if (isTaskAssignmentsEnabled)
    return {
      modalProps: { disableEnforceFocus: true }
    };

  return {};
};

export default useMuiDrawerOverriddenProps;
