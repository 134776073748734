import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RangeBoundaries } from '~/modules/common/charts/timeline/components';
import ResourceRequestBlockGroup2 from '../ResourceRequestBlockGroup/ResourceRequestBlockGroup2';

const useDelaStyles = makeStyles(theme => ({
  startBoundary: {
    background: theme.palette.Dela.darkBackground
  },
  endBoundary: {
    background: theme.palette.Dela.darkBackground
  }
}));

export const ResourceRequestChartTimeLineRow = ({
  scale,
  chartDates,
  startDate,
  endDate,
  chartDisplayDateRange,
  resourceRequest,
  resourceAllocations,
  isEditSwitchEnabled,
  displayUnit,
  dynamicContainerHeight
}) => {
  const delaClasses = useDelaStyles();

  const [{ start: chartStartDate }] = chartDates;

  return (
    <>
      <RangeBoundaries
        classes={
          resourceRequest.isDelaDraftResourceRequest ? delaClasses : null
        }
        chartStartDate={chartStartDate}
        scale={scale}
        start={startDate}
        end={endDate}
      />
      <ResourceRequestBlockGroup2
        resourceRequest={resourceRequest}
        chartDates={chartDates}
        scale={scale}
        isEditSwitchEnabled={isEditSwitchEnabled}
        resourceAllocations={resourceAllocations}
        chartDisplayDateRange={chartDisplayDateRange}
        displayUnit={displayUnit}
        dynamicContainerHeight={dynamicContainerHeight}
      />
    </>
  );
};

ResourceRequestChartTimeLineRow.propTypes = {
  scale: PropTypes.string.isRequired,
  chartDates: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocations: PropTypes.array,
  isEditSwitchEnabled: PropTypes.bool,
  chartDisplayDateRange: PropTypes.object.isRequired,
  displayUnit: PropTypes.string,
  dynamicContainerHeight: PropTypes.number
};

export default ResourceRequestChartTimeLineRow;
