import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import omit from 'lodash/fp/omit';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';

const omitForbiddenFields = flow(
  omit('__typename'),
  update('currency', flow(omit('__typename')))
);

const isValid = obj =>
  obj &&
  typeof obj === 'object' &&
  Object.prototype.hasOwnProperty.call(obj, 'id') &&
  obj.id;

export const mapToProjectInput = ({
  client,
  program,
  portfolio,
  projectManagerReference,
  projectManagementType,
  isTimeEntryAllowed,
  timeAndExpenseEntryType,
  estimatedCost,
  projectTemplate,
  ...rest
}) => ({
  projectUri: null,
  clients: isValid(client)
    ? [
        {
          client: {
            id: client.id,
            displayText: client.displayText
          },
          costAllocationPercentage: 100
        }
      ]
    : [],
  portfolioId: portfolio?.id || null,
  program: isValid(program)
    ? {
        id: program.id,
        slug: program.slug,
        displayText: program.displayText
      }
    : null,
  projectManagerReference: isValid(projectManagerReference)
    ? {
        id: projectManagerReference.id,
        displayText: projectManagerReference.displayText
      }
    : null,
  keyValues: [
    {
      keyUri: 'urn:replicon:project-key-value-key:project-management-type',
      value: {
        uri: projectManagementType,
        id: projectManagementType
      }
    },
    ...[
      isValid(projectTemplate)
        ? {
            keyUri: 'urn:replicon:project-key-value-key:project-template',
            value: {
              uri: projectTemplate.id
            }
          }
        : null
    ].filter(x => x)
  ],
  isTimeEntryAllowed,
  timeAndExpenseEntryType: isTimeEntryAllowed
    ? timeAndExpenseEntryType
    : {
        id: TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE
      },
  estimatedCost: omitForbiddenFields(estimatedCost),
  ...rest
});
