import get from 'lodash.get';

import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';

import { useGetProjectsFilteredByResourceRequestStatusQuery } from './useGetProjectsFilteredByResourceRequestStatusQuery';

export const useProjectsFilteredByResourceRequestStatuQuery = ({
  pageSize,
  isManaged,
  searchCriteria,
  resourceRequestStatus,
  paginationToken: token,
  quickFilter
}) => {
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter
  });

  const options = {
    variables: {
      pageSize,
      projectFilter: { ...projectFilter, isManaged },
      resourceRequestStatus,
      paginationToken: token
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  };

  const {
    data,
    fetchMore,
    loading
  } = useGetProjectsFilteredByResourceRequestStatusQuery(options);

  const resourceRequestsProjects = get(
    data,
    'projectsFilteredByResourceRequestStatus.resourceRequestsProjects',
    []
  );
  const paginationToken = get(
    data,
    'projectsFilteredByResourceRequestStatus.paginationToken',
    null
  );

  return {
    loading,
    resourceRequestsProjects,
    paginationToken,
    fetchMore
  };
};
