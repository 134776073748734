import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LinkedinIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="m19 3h-14c-1.105 0-2 0.895-2 2v14c0 1.105 0.895 2 2 2h14c1.105 0 2-0.895 2-2v-14c0-1.105-0.895-2-2-2zm-10 14h-2.523v-7h2.523v7zm-1.306-8.283c-0.771 0-1.286-0.514-1.286-1.2s0.514-1.2 1.371-1.2c0.771 0 1.286 0.514 1.286 1.2s-0.514 1.2-1.371 1.2zm10.306 8.283h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058 0.163-1.058 1.302v3.826h-2.523v-7h2.523v0.977c0.325-0.57 0.976-0.977 2.197-0.977s2.198 0.977 2.198 3.174v3.826z" />
    </>
  </SvgIcon>
));

export default LinkedinIcon;
