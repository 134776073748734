import { gql } from 'graphql-tag';

const allocationTotalsFragment = gql`
  fragment AllocationTotalsFragment on ResourceAllocation {
    totalHours
    totalUserCostByCurrency {
      baseCurrency {
        currency {
          id
        }
        amount
      }
      projectBudgetCurrency {
        currency {
          id
        }
        amount
      }
    }
    totalRoleCostByCurrency {
      baseCurrency {
        currency {
          id
        }
        amount
      }
      projectBudgetCurrency {
        currency {
          id
        }
        amount
      }
    }
  }
`;

export default allocationTotalsFragment;
