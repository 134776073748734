import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default checklist => {
  const useAddMissingId = useCallback(() => {
    const checkList = {};

    for (const id in checklist) {
      if (Object.prototype.hasOwnProperty.call(checklist, id)) {
        // eslint-disable-next-line no-param-reassign
        checkList[id] = checklist[id].map(item => ({
          ...item,
          id: item.id !== undefined && item.id !== null ? item.id : uuidv4()
        }));
      }
    }

    return checkList;
  }, [checklist]);

  return useAddMissingId();
};
