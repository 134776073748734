import { mapEnabledFieldsToVariables } from '~/modules/common/util';
import { columns } from './ClientsPage/clientColumns';

const columnVarColumnMap = {
  includeCode: columns.code,
  includeClientManager: columns.clientManager,
  includeIsActive: columns.isActive,
  includeProjectCount: columns.projectCount,
  includeTotalEstimatedHours: columns.totalEstimatedHours,
  includeTotalActualHours: columns.totalActualHours,
  includeTotalEstimatedCost: columns.totalEstimatedCost,
  includeTotalActualExpenseCost: columns.totalActualExpenseCost,
  includeTotalActualResourceCost: columns.totalActualResourceCost,
  includeTotalActualCost: columns.totalActualCost,
  includeTotalEstimatedBilling: columns.totalEstimatedBilling,
  includeTotalActualBilling: columns.totalActualBilling,
  includeTaxProfile: columns.taxProfile
};

export const columnArguments = `
$includeCode: Boolean!
$includeClientManager: Boolean!
$includeIsActive: Boolean!
$includeProjectCount: Boolean!
$includeTotalEstimatedHours: Boolean!
$includeTotalActualHours: Boolean!
$includeTotalEstimatedCost: Boolean!
$includeTotalActualExpenseCost: Boolean!
$includeTotalActualResourceCost: Boolean!
$includeTotalActualCost: Boolean!
$includeTotalEstimatedBilling: Boolean!
$includeTotalActualBilling: Boolean!
$includeTaxProfile: Boolean!
  `;

export const enabledFieldsToVariables = enabledFields =>
  mapEnabledFieldsToVariables(enabledFields, columnVarColumnMap);
