import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '../LoadingButton';

const useStyles = makeStyles(theme => ({
  noPermissionMessage: {
    marginBottom: theme.spacing(2)
  },
  archiveMessage: {
    color: theme.palette.text.primary
  }
}));

export const BulkDeleteConfirmationDialogContent = ({
  onClose,
  onDelete,
  batchState,
  dialogContent,
  enableRemove = false
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        {dialogContent.errorMessage && (
          <Alert
            icon={false}
            severity="error"
            className={classes.noPermissionMessage}
          >
            {dialogContent.errorMessage}
          </Alert>
        )}
        {dialogContent.info && (
          <DialogContentText className={classes.archiveMessage}>
            {dialogContent.info}
          </DialogContentText>
        )}
        <DialogContentText>{dialogContent.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={batchState.batchInProgress}>
          <FormattedMessage id="bulkRemoveDialog.actions.cancel" />
        </Button>
        <LoadingButton
          color="secondary"
          onClick={onDelete}
          disabled={batchState.batchInProgress || !enableRemove}
          isLoading={batchState.batchInProgress}
        >
          {dialogContent.title}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

BulkDeleteConfirmationDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  batchState: PropTypes.object.isRequired,
  dialogContent: PropTypes.object.isRequired,
  enableRemove: PropTypes.bool.isRequired
};

export default BulkDeleteConfirmationDialogContent;
