import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY = gql`
  query BulkGetTaskResourceAllocations(
    $filter: TaskResourceUserAllocationsForUserFilter!
  ) {
    taskResourceUserAllocationsForUser(filter: $filter) {
      id
      taskUri
      projectUri
      allocationUserUri
      roleUri
      scheduleRules {
        dateRange {
          endDate
          startDate
        }
        do
      }
      taskResourceEstimateUri
      resourceAllocationUri
      startDate
      endDate
      totalHours
    }
  }
`;

const useBulkGetTaskResourceUserAllocations = ({
  userUri,
  projectUri,
  taskUris
}) => {
  const { loading, data } = useQuery(BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY, {
    variables: {
      filter: {
        userUri,
        projectUri,
        taskUris
      }
    },
    fetchPolicy: 'network-only'
  });

  return {
    taskResourceUserAllocations: data?.taskResourceUserAllocationsForUser || [],
    loading
  };
};

export default useBulkGetTaskResourceUserAllocations;
