import { makeCriteriaProvider } from '../ProjectManagerFacet/enhancers';
import ProjectCoManagerFacetDetails from './ProjectCoManagerFacetDetails';
import { projectCoManagerTag } from './tag';

export default {
  makeCriteriaProvider,
  tag: projectCoManagerTag,
  DetailsControl: ProjectCoManagerFacetDetails,
  SuggestionItem: () => null
};
