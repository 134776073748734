import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from './useStyles';

export const MobileResourceAllocationTitle = ({ resourceAllocation }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <span className={classes.roleName}>
          {resourceAllocation.user ? (
            <span className={classes.userName}>
              {resourceAllocation.user.user.displayText}
            </span>
          ) : (
            <FormattedMessage id="mobileResourcePlanning.noRole" />
          )}
          (
          {resourceAllocation.role ? (
            <span className={classes.roleName}>
              {resourceAllocation.role.displayText}
            </span>
          ) : (
            <FormattedMessage id="mobileResourcePlanning.anyRole" />
          )}
          )
        </span>
      </div>
    </div>
  );
};

MobileResourceAllocationTitle.propTypes = {
  resourceAllocation: PropTypes.object
};

export default MobileResourceAllocationTitle;
