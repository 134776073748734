import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  currencyRoot: {},
  adornmentRoot: {
    marginTop: '0px',
    margin: theme.spacing(0, 1, 1, 0)
  },
  amountField: {
    height: 'auto',
    margin: theme.spacing(0, 1, 1, 0)
  },
  dateField: {
    height: 'auto',
    margin: theme.spacing(0, 1, 1, 0)
  },
  addButton: {
    alignSelf: 'center',
    height: 'fit-content',
    marginLeft: theme.spacing(-1)
  },
  removeButton: {
    alignSelf: 'center',
    height: 'fit-content',
    marginLeft: theme.spacing(-1)
  },
  initialRate: {
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0
    },
    fontSize: '1rem'
  },
  userContainer: {
    '& div div div': {
      height: theme.spacing(5.9)
    }
  },
  amountContainer: {
    '& div div div': {
      marginTop: '1.5px !important'
    }
  },
  padding: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  dateFieldContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    },
    '& div': {
      [theme.breakpoints.up('sm')]: {
        width: '99% !important'
      }
    }
  },
  roleContainer: {
    '& label': {
      transform: 'translate(12px, 7px) scale(0.75) !important'
    }
  }
}));
