import { useState } from 'react';
import { useDialogState } from '~/modules/common/hooks';

export const useMergeDialogState = () => {
  const { open, closeDialog, openDialog } = useDialogState();
  const [existingAllocationState, setExistingAllocationState] = useState({
    hasExistingAllocation: false,
    hasExistingAllocationLoading: false
  });

  return {
    open,
    openDialog,
    closeDialog,
    existingAllocationState,
    setExistingAllocationState
  };
};

export default useMergeDialogState;
