import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
    display: 'block',
    padding: theme.spacing(1, 0)
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  itemName: {
    flex: '1 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  extension: {
    flex: '0 0 auto',
    flexBasis: 50,
    padding: theme.spacing(0, 1)
  },
  size: {
    flex: '0 0 auto',
    flexBasis: 90,
    textAlign: 'right',
    padding: theme.spacing(0, 1)
  },
  date: {
    flex: '0 0 auto',
    flexBasis: 100,
    padding: theme.spacing(0, 1)
  },
  action: {
    flex: '0 0 auto',
    flexBasis: 36
  },
  lastListItem: {
    borderBottom: 'unset'
  },
  actionButton: {
    margin: theme.spacing(-1.5, -1.5)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  userAvatar: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default useStyles;
