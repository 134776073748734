import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { POLICYTYPE } from '~/modules/common/enums';
import useServiceCenters from '~/modules/common/enhancers/useServiceCenters';
import { onChange } from '../enhancers/facetHelpers';

import GroupFacetDetails from '../GroupFacetDetails';

export const ServiceCenterFacetDetails = ({
  className,
  selected,
  setSelected,
  policy = POLICYTYPE.PROJECT_MANAGEMENT
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const noneOptionKey = formatMessage({ id: 'NONE' });
  const noneOptionText = formatMessage({
    id: 'serviceCenterDropdown.noServiceCenter'
  });

  const { serviceCenters, loading, hasMore } = useServiceCenters({
    searchTerm,
    policy,
    includeNoneOption: true,
    noneOptionText,
    noneOptionKey
  });

  return (
    <GroupFacetDetails
      label={formatMessage({ id: 'search.serviceCenter' })}
      className={className}
      selected={selected}
      onChange={onChange}
      setSelected={setSelected}
      isLoading={loading}
      setSearchTerm={setSearchTerm}
      options={serviceCenters}
      noOptionsLabel={formatMessage({
        id: 'serviceCenterDropdown.noServiceCenter'
      })}
      hasMore={hasMore}
      optionTypeText={formatMessage({
        id: 'moreOptions.serviceCenter'
      })}
    />
  );
};

ServiceCenterFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  policy: PropTypes.oneOf([
    POLICYTYPE.PROJECT_MANAGEMENT,
    POLICYTYPE.PORTFOLIO_MANAGEMENT,
    POLICYTYPE.RESOURCE_MANAGEMENT,
    POLICYTYPE.REVENUE_MANAGEMENT
  ])
};

export default ServiceCenterFacetDetails;
