import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ResourceRequestTimelineBlocksReadOnly from '~/modules/resourcing/common/components/ResourceRequestTimelineBlocksReadOnly';
import ResourceRequestTimelineBlocks from '~/modules/resourcing/common/components/ResourceRequestTimelineBlocks';
import { ResourceAllocationTimelineBlocks } from '~/modules/resourcing/common/components/ResourceRequestUserAllocationBlock';
import useUpdateResourceAllocation from '~/modules/resourcing/hooks/useUpdateResourceAllocation';
import { useMeContext } from '~/modules/me';
import { useProjectContext } from '~/modules/resourcing/common/contexts';

const mapSeriesToScheduleDurationByPeriodMap = resourceAllocation => {
  const scheduleDurationByPeriodMap = (
    resourceAllocation.user.scheduleDurationSeries || []
  ).reduce((acc, duration) => {
    acc[duration.dateRange.startDate] = duration;

    return acc;
  }, {});

  return {
    ...resourceAllocation,
    user: {
      ...resourceAllocation.user,
      scheduleDurationByPeriodMap
    }
  };
};

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  }
}));

export const AllocatedResourceRequestBlockGroup = memo(
  ({
    resourceRequest,
    chartDisplayDateRange,
    scale,
    chartDisplayPeriods,
    displayUnit
  }) => {
    const classes = useStyles();
    const { project } = useProjectContext();
    const { permissionsMap } = useMeContext();
    const isEditEnabled = Boolean(
      permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
    );

    const { onUpdateResourceAllocation } = useUpdateResourceAllocation();
    const onAllocationChange = useCallback(
      evt =>
        onUpdateResourceAllocation({
          allocation: evt.values
        }),
      [onUpdateResourceAllocation]
    );

    return (
      <>
        <div className={classes.root}>
          {(resourceRequest.resourceAllocations || []).map(allocation => (
            <ResourceAllocationTimelineBlocks
              key={allocation.id}
              allocation={mapSeriesToScheduleDurationByPeriodMap(allocation)}
              chartDisplayPeriods={chartDisplayPeriods}
              scale={scale}
              isEditable={isEditEnabled}
              chartDisplayDateRange={chartDisplayDateRange}
              onAllocationChange={onAllocationChange}
              showResizeIndicator={isEditEnabled}
              forceReadOnlyOverlays
            />
          ))}
        </div>
        {resourceRequest.resourceAllocations?.length > 0 ? (
          <ResourceRequestTimelineBlocksReadOnly
            resourceRequest={resourceRequest}
            chartDisplayDateRange={chartDisplayDateRange}
            scale={scale}
            project={project}
            chartDisplayPeriods={chartDisplayPeriods}
          />
        ) : (
          <ResourceRequestTimelineBlocks
            resourceRequest={resourceRequest}
            displayUnit={displayUnit}
            isEditable={false}
            chartDisplayDateRange={chartDisplayDateRange}
            scale={scale}
            project={project}
            chartDisplayPeriods={chartDisplayPeriods}
          />
        )}
      </>
    );
  }
);

AllocatedResourceRequestBlockGroup.propTypes = {
  scale: PropTypes.string.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  chartDisplayPeriods: PropTypes.array,
  displayUnit: PropTypes.string
};

export default AllocatedResourceRequestBlockGroup;
