import { gql } from 'graphql-tag';
import { notificationFragment } from './notificationFragment';

export const UNREAD_NOTIFICATIONS_QUERY = gql`
  query Eager_GetMyUnreadNotifications {
    me {
      id
      unreadNotifications {
        ...Notification
      }
      unreadNotificationsCount
    }
  }
  ${notificationFragment}
`;
