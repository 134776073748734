import React, { useState, useMemo } from 'react';
import { scoredSuggestions } from '../../../scoredSuggestions';
import { billingTransactionTypes } from './billingTransactionTypes';

export const mapOptions = types =>
  types.map(({ name, id }) => ({ key: name, label: name, value: id }));

export const matchOption = (options, term) => {
  const type = options.find(c => c.key.toLowerCase() === term.toLowerCase());

  if (type) {
    return type;
  }

  return null;
};

const withCriteriaProvider = (tag, context) => BaseComponent => props => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);

  const options = mapOptions(billingTransactionTypes);

  const value = useMemo(
    () => ({
      options,
      matchOption,
      selected,
      setSelected,
      suggestions: scoredSuggestions(options)
    }),
    [options, selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
