import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  useURLSearchParams,
  useIsBreakpointDown,
  useQuickFilterState,
  useSessionStorage
} from '~/modules/common/hooks';
import { useSearchState } from '~/modules/common/components/SearchBox';
import { ProjectManagementType } from '~/types';
import { PROJECTS_SUMMARY_FILTER_TABS } from '~/modules/common/enums';
import { OVERVIEW } from './projectListTabs';
import { ProjectsContext } from './ProjectsContext';

const buildQueryString = ({ view, type }) => `?view=${view}&type=${type}`;

export const handleProjectTypeClick = ({
  history,
  view,
  setType,
  setCurrentPage
}) => (_, newType) => {
  // prevent untoggling managed / unmanaged toggle buttons for now
  if (!newType) return;

  setType(newType);
  setCurrentPage(1);

  const path = {
    pathname: '/projects',
    search: buildQueryString({ view, type: newType })
  };

  history.replace(path);
};

export const handleTabChange = ({ history, projectType, setProjectsView }) => (
  _,
  newView
) => {
  setProjectsView(newView);
  const path = {
    pathname: '/projects',
    search: buildQueryString({ view: newView, type: projectType })
  };

  history.replace(path);
};

export const getViewFromQueryOrDefault = ({ query, defaultView }) =>
  query.get('view') || defaultView;

export default function ProjectsContextProvider({ children }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [
    quickFilter,
    setQuickFilter,
    loadingQuickFilters
  ] = useQuickFilterState({
    filterKey: 'project-quick-filter',
    defaultValue: PROJECTS_SUMMARY_FILTER_TABS.ALL_PROJECTS
  });

  const {
    storedValue: projectsView,
    setValue: setProjectsView
  } = useSessionStorage('projects-view', OVERVIEW);

  const { storedValue: type, setValue: setType } = useSessionStorage(
    'projects-type',
    ProjectManagementType.Managed
  );

  const handleSetQuickFilter = useCallback(
    (newAllKey, newKey) => {
      setQuickFilter({ filterByAccess: newAllKey, filterByStatus: newKey });
      setCurrentPage(1);
    },
    [setQuickFilter, setCurrentPage]
  );

  const summaryFilterState = {
    quickFilter,
    setKeys: handleSetQuickFilter,
    isLoadingQuickFilters: loadingQuickFilters
  };

  const [searchCriteria, setSearchCriteria] = useSearchState('projects');
  const query = useURLSearchParams();
  const history = useHistory();
  const isMobile = useIsBreakpointDown('xs');

  const view = getViewFromQueryOrDefault({
    isMobile,
    query,
    defaultView: projectsView
  });
  const projectType = query.get('type') || type;

  const onProjectTypeClick = useCallback(
    handleProjectTypeClick({ history, view, setType, setCurrentPage }),
    [history, view, setCurrentPage]
  );

  const onTabChange = useCallback(
    handleTabChange({ history, projectType, setProjectsView }),
    [history, projectType, setProjectsView]
  );

  const value = useMemo(
    () => ({
      projectType,
      view,
      onProjectTypeClick,
      onTabChange,
      searchCriteria,
      setSearchCriteria,
      summaryFilterState,
      currentPage,
      setCurrentPage
    }),
    [
      onProjectTypeClick,
      onTabChange,
      projectType,
      searchCriteria,
      setSearchCriteria,
      view,
      summaryFilterState,
      currentPage,
      setCurrentPage
    ]
  );

  return (
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  );
}

ProjectsContextProvider.propTypes = {
  children: PropTypes.node
};

export const withProjectContextProvider = BaseComponent => props => (
  <ProjectsContextProvider>
    <BaseComponent {...props} />
  </ProjectsContextProvider>
);
