import { useCallback } from 'react';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';

const getHasAssignedRole = ({ userRoles, assignedRole }) =>
  Number(Boolean(userRoles?.find(r => r.projectRole?.uri === assignedRole.id)));

export const useDropdownHandlers = ({
  onResourceChange,
  setSearchTerm,
  formatMessage,
  assignedRole,
  selectedResources,
  assignedUserIds
}) => {
  const handleOnChange = useCallback(
    resources => {
      const resource =
        resources && resources.length ? resources[resources.length - 1] : null;

      onResourceChange(resource);
      setSearchTerm('');
    },
    [onResourceChange, setSearchTerm]
  );

  const userIds = assignedUserIds.length ? assignedUserIds : selectedResources;
  const getOptionDisabled = useCallback(
    option =>
      Boolean(userIds.find(resource => resource === option?.id)) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [userIds]
  );

  const handleInputChange = (_, v) => setSearchTerm(v.trim());

  const getGroupBy = useCallback(
    user =>
      user.hasAssignedRole
        ? `${assignedRole.displayText} ${formatMessage({
            id: 'addResource.resources'
          })}`
        : user.id === MORE_AVAILABLE_OPTION_ID
        ? null
        : user.isUnusedTeamMember
        ? formatMessage({
            id: 'taskResourceAssignments.timesheetAccess'
          })
        : formatMessage({
            id: 'taskResourceAssignments.allocatedResources'
          }),
    [assignedRole, formatMessage]
  );

  const getOptions = useCallback(
    ({ users, hasMore }) => {
      const options = assignedRole
        ? users
            .map(u => ({
              ...u,
              hasAssignedRole: getHasAssignedRole({
                userRoles: u.projectRoles,
                assignedRole
              })
            }))
            .sort((a, b) => b.hasAssignedRole - a.hasAssignedRole)
        : users;

      const shouldAddMoreAvailableOption = hasMore && options?.length;

      shouldAddMoreAvailableOption &&
        options.push({
          id: 'searchAutocomplete.moreAvailable',
          displayText: formatMessage(
            {
              id: MORE_AVAILABLE_OPTION_ID
            },
            {
              option: formatMessage({
                id: 'moreOptions.resources'
              })
            }
          )
        });

      return options;
    },
    [assignedRole, formatMessage]
  );

  return {
    handleOnChange,
    getOptionDisabled,
    handleInputChange,
    getGroupBy,
    getOptions
  };
};

export default useDropdownHandlers;
