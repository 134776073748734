import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const GET_PROJECT_REQUEST_DETAILS = gql`
  query projectRequest($id: String!, $page: Int!, $pageSize: Int!) {
    projectRequest(id: $id) {
      id
      name
      createdBy {
        displayText
        id
      }
      description
      requestedBy
      portfolio {
        id
        displayText
        ancestors {
          id
          displayText
        }
        portfolioCurrency {
          id
          displayText
        }
      }
      status {
        name
        type
      }
      score {
        value
        risk
        investment
        total
        overrideTotal
        effectiveTotal
      }
      statusGates {
        status {
          name
          type
        }
        gates {
          uri
          displayText
          isDefault
          value
        }
      }
      estimatedCost {
        amount
        currency {
          id
          displayText
        }
      }
      estimatedCostOverride {
        amount
        currency {
          id
          displayText
        }
      }
      permittedActionUris
      resourceAssignments(page: $page, pageSize: $pageSize) {
        id
        role {
          id
          displayText
        }
        quantity
      }
      startDate
      timelineDuration {
        periodQuantity
        periodResolution
      }
    }
  }
`;

export const useProjectRequest = id => {
  const { data, loading } = useQuery(GET_PROJECT_REQUEST_DETAILS, {
    variables: {
      id,
      page: 1,
      pageSize: 50
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !id
  });

  const projectRequestDetails = !loading && data ? data.projectRequest : {};

  return {
    loading,
    projectRequestDetails
  };
};

export default useProjectRequest;
