import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TaskDoneOverrideIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12 2 C 6.480000000000003 2 2 6.480000000000001 2 12 C 2 13.54551488484544 2.361397021337377 15.00358843514369 2.98828125 16.310546875 L 2.94921875 14 L 6.94921875 14 L 7 17 L 10 17 L 10 21 L 7.666015625 21 C 8.978943252195359 21.63400188790465 10.44517162828181 22 12 22 C 17.52 22 22 17.52 22 12 C 22 6.480000000000001 17.52 2 12 2 z M 17.58984375 6.580078125 L 19 8 L 11 15.830078125 L 6.58984375 11.41015625 L 8 10 L 11 13 L 17.58984375 6.580078125 z M 4 15 L 4 18 L 1 18 L 1 20 L 4 20 L 4 22.990234375 L 6 22.990234375 L 6 20 L 9 20 L 9 18 L 6 18 L 6 15 L 4 15 z " />
    </>
  </SvgIcon>
));

export default TaskDoneOverrideIcon;
