import { useMemo } from 'react';
import { extractCurrencyIdsFromBills } from './util';

export const useIsMultiCurrency = ({
  values: { currency: { id: currencyId } = {} } = {},
  associatedAndOutstandingBills = []
}) =>
  useMemo(
    () => ({
      isMultiCurrency:
        extractCurrencyIdsFromBills(currencyId, associatedAndOutstandingBills)
          .length > 0
    }),
    [currencyId, associatedAndOutstandingBills]
  );
