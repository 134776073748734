import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';

const EmptyRevenueRecognition = () => (
  <NoDataItem>
    <FormattedMessage id="projectRevenueRecognitionCard.empty" />
  </NoDataItem>
);

export default EmptyRevenueRecognition;
