import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useMeContext } from '~/modules/me';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject,
  toRepliconDate
} from '~/modules/common/dates/convert';
import { buildResourceAllocation } from '~/modules/resourcing/hooks/useCreateResourceAllocationHandler';
import { ResourceAllocationStatus } from '~/types';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { compareDateObject } from '~/modules/common/dates/compare';
import { buildUpdatedScheduleRules } from '../../../util';
import { ChangeAllocationUserOptionItemValue } from '../components/constants';

export const getInitialValues = ({
  resourceAllocations,
  currentTimeInUserTimeZone
}) => {
  const today = DateTime.fromISO(currentTimeInUserTimeZone, { setZone: true });

  const currentDate = DateTime.utc(
    today.year,
    today.month,
    today.day,
    0,
    0,
    0,
    0
  );

  const nextDate = currentDate.plus({
    days: 1
  });

  const utcObjectAllocationStartDate = mapRepliconDateToUtcObject(
    resourceAllocations[0].startDate
  );

  return {
    selectedAllocationId: resourceAllocations[0].id,
    changeAllocationType: ChangeAllocationUserOptionItemValue.FULL,
    asOfDate:
      nextDate > utcObjectAllocationStartDate
        ? toRepliconDate(nextDate)
        : toRepliconDate(utcObjectAllocationStartDate),
    isTasksChecked: false,
    impactedTasks: []
  };
};

export const getReleaseType = ({
  resourceAllocation,
  asOfDate,
  changeAllocationType
}) => {
  if (changeAllocationType === ChangeAllocationUserOptionItemValue.FULL)
    return changeAllocationType;

  const asOfDateUTC = mapRepliconDateToUtcObject(asOfDate);

  // asOfDate after allocation end
  if (asOfDateUTC > mapIsoStringtoUtcObject(resourceAllocation.endDate)) {
    return null;
  }

  const startDateUTC = mapIsoStringtoUtcObject(resourceAllocation.startDate);

  // asOfDate before allocation start
  if (
    compareDateObject(asOfDateUTC, startDateUTC) === 0 ||
    asOfDateUTC < startDateUTC
  ) {
    return ChangeAllocationUserOptionItemValue.FULL;
  }

  return changeAllocationType;
};

const useEditAllocationUserDialogState = ({
  onDialogClose,
  resourceAllocations,
  handleEditResourceAllocation,
  newResourceUser
}) => {
  const me = useMeContext();
  const { currentTimeInUserTimeZone } = me;
  const { project } = useProjectContext();

  const onSubmit = ({
    changeAllocationType,
    asOfDate,
    selectedAllocationId,
    impactedTasks
  }) => {
    const resourceAllocation = resourceAllocations.find(
      alloc => alloc.id === selectedAllocationId
    );

    const releaseType = getReleaseType({
      resourceAllocation,
      asOfDate,
      changeAllocationType
    });

    if (!releaseType) {
      onDialogClose();

      return null;
    }

    if (releaseType === ChangeAllocationUserOptionItemValue.ASOFDATE) {
      const newAllocation = buildResourceAllocation({
        user: newResourceUser,
        me,
        allocationId: null,
        roleUri: resourceAllocation.requestedRoleUri,
        allocationStatus: ResourceAllocationStatus.Committed,
        load: resourceAllocation.load,
        projectUri: resourceAllocation.projectUri,
        scheduleRules: buildUpdatedScheduleRules({
          start: mapRepliconDateToUtcObject(asOfDate),
          end: mapIsoStringtoUtcObject(resourceAllocation.endDate),
          scheduleRules: resourceAllocation.scheduleRules,
          defaultScheduleRule: project.defaultScheduleRule
        })
      });

      const previousDate = mapRepliconDateToUtcObject(asOfDate).minus({
        days: 1
      });

      const existingPatchedAllocation = {
        ...resourceAllocation,
        endDate: previousDate.toISO(),
        scheduleRules: buildUpdatedScheduleRules({
          start: mapIsoStringtoUtcObject(resourceAllocation.startDate),
          end: previousDate,
          scheduleRules: resourceAllocation.scheduleRules,
          defaultScheduleRule: project.defaultScheduleRule
        })
      };

      return handleEditResourceAllocation({
        isAsOf: true,
        patchedAllocation: existingPatchedAllocation,
        resourceAllocation: newAllocation,
        onDialogClose,
        impactedTasks,
        existingUser: resourceAllocation.user
      });
    }

    return handleEditResourceAllocation({
      isAsOf: false,
      resourceAllocation: {
        ...resourceAllocation,
        user: {
          id: newResourceUser.id,
          costCurrencyUri: newResourceUser.currentCostRate
            ? newResourceUser.currentCostRate.currency.id
            : null,
          costCurrency: newResourceUser.currentCostRate
            ? newResourceUser.currentCostRate.currency
            : null,
          costRate: newResourceUser.currentCostRate
            ? newResourceUser.currentCostRate.amount
            : null,
          displayText: newResourceUser.displayText,
          userUri: newResourceUser.id,
          user: {
            displayText: newResourceUser.displayText,
            uri: newResourceUser.id,
            roles: newResourceUser.roles,
            slug: newResourceUser.slug,
            permittedActionUris: newResourceUser.permittedActionUris || []
          }
        }
      },
      existingUser: resourceAllocation.user,
      onDialogClose,
      impactedTasks
    });
  };

  const formik = useFormik({
    initialValues: getInitialValues({
      resourceAllocations,
      currentTimeInUserTimeZone
    }),
    onSubmit
  });

  return formik;
};

export default useEditAllocationUserDialogState;
