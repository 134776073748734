import { useState, useCallback, useEffect } from 'react';

const highlightTarget = event => event.target.select();

export const useChangeOnBlurInputHandlers = ({
  value,
  onChangeCallback,
  triggerOnChange
}) => {
  const [numberValue, setNumberValue] = useState();

  useEffect(() => setNumberValue(value), [value]);

  const handleOnValueChange = useCallback(
    number => {
      setNumberValue(number || 0);
      if (triggerOnChange) onChangeCallback(number || 0);
    },
    [triggerOnChange, onChangeCallback]
  );

  const handleOnBlur = useCallback(() => {
    onChangeCallback && onChangeCallback(numberValue);
  }, [onChangeCallback, numberValue]);

  return {
    numberValue,
    handleOnValueChange,
    handleOnBlur,
    highlightTarget
  };
};

export default useChangeOnBlurInputHandlers;
