import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cell: {
    textAlign: 'right',
    padding: theme.spacing(0, 0, 0, 2),
    fontWeight: 'bold'
  }
}));

export const ActualSummaryPeriodPopupTableHeader = () => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.cell}>&nbsp;</span>
      <span className={classes.cell}>
        <FormattedMessage id="actualsSummaryPeriodPopup.allocated" />
      </span>
      <span className={classes.cell}>
        <FormattedMessage id="actualsSummaryPeriodPopup.actuals" />
      </span>
      <span className={classes.cell}>
        <FormattedMessage id="actualsSummaryPeriodPopup.difference" />
      </span>
    </>
  );
};

export default ActualSummaryPeriodPopupTableHeader;
