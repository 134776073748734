import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AsOfDate } from './AsOfDate';

export const NumericFormatter = ({ record, field }) => {
  const { value, asOfDate } = record[field];
  const displayText = useMemo(() => parseFloat(value.toFixed(4)), [value]);

  return (
    <>
      <Typography>{displayText}</Typography>
      <AsOfDate date={asOfDate} />
    </>
  );
};

NumericFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default NumericFormatter;
