import eventemitter from 'eventemitter3';

const eventBus = new eventemitter.EventEmitter();

eventBus.subscribe = eventBus.on;
eventBus.unsubscribe = eventBus.off;
eventBus.subscribeOnce = eventBus.once;
eventBus.publish = (eventName, payload) => {
  if (eventBus.listenerCount(eventName) > 0) {
    eventBus.emit(eventName, payload);
  }
};
eventBus.publishAll = events => {
  if (events && events.length > 0) {
    events.forEach(({ eventName, payload }) => {
      eventBus.publish(eventName, payload);
    });
  }
};
export default eventBus;
