import React from 'react';
import { PropTypes } from 'prop-types';
import {
  SecondaryActions,
  RequestActionButton
} from '~/modules/resourcing/common/components';
import { useAppContext } from '~/modules/App/AppContext';
import useStyles from './useStyles';
import { useAddResourceRequestDrawerActions } from './hooks';

export const AddResourceRequestDrawerActions = ({
  hasError = false,
  drawerOnClose,
  onResourceRequestSave,
  onResourceRequestSubmit
}) => {
  const classes = useStyles();

  const { dispatch } = useAppContext();

  const actions = useAddResourceRequestDrawerActions({
    closeDrawer: drawerOnClose,
    setSnackBarMessage: dispatch,
    onResourceRequestSave,
    onResourceRequestSubmit
  });

  const secondaryActions = actions
    .filter(action => action.secondary)
    .map(action => ({
      ...action,
      disabled: action.id === 'remove-request' ? false : hasError
    }));
  const primaryActions = actions.filter(action => !action.secondary);

  return (
    <>
      {(primaryActions || []).map((primaryAction, index) => {
        const { buttonComponent, ...primaryActionProps } = primaryAction;
        const Component = buttonComponent || RequestActionButton;

        return (
          <Component
            key={primaryActionProps.label}
            disabled={hasError}
            className={index > 0 ? classes.buttonSpacing : null}
            {...primaryActionProps}
          />
        );
      })}
      {secondaryActions.length > 0 && (
        <>
          <div className={classes.spacer} />
          <SecondaryActions actions={secondaryActions} />
        </>
      )}
    </>
  );
};

AddResourceRequestDrawerActions.propTypes = {
  hasError: PropTypes.bool,
  drawerOnClose: PropTypes.func,
  onResourceRequestSave: PropTypes.func,
  onResourceRequestSubmit: PropTypes.func
};

export default AddResourceRequestDrawerActions;
