import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    paddingLeft: theme.spacing(0.5),
    display: 'inline-block',
    marginTop: theme.spacing(0.25)
  },
  iconLabel: { paddingLeft: theme.spacing(0.5) },
  labelIcon: {
    fontSize: theme.spacing(2.25),
    margin: theme.spacing(-0.5, 0),
    color: ({ omitColor }) =>
      omitColor ? undefined : theme.palette.text.secondary
  }
}));

export default useStyles;
