import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import {
  useStyledGroupIcons,
  useGroupLabels
} from '~/modules/common/components/Groups/hooks';
import { useStyles } from './useStyles';

export const GroupsTitle = ({ itemsMap }) => {
  const labels = useGroupLabels();
  const icons = useStyledGroupIcons({ omitColor: true });
  const classes = useStyles();
  const sortedGroups = Object.keys(labels)
    .sort((a, b) => a.localeCompare(b))
    .map(key =>
      itemsMap[key] && itemsMap[key].value
        ? { key, icon: icons[key], value: itemsMap[key].value }
        : itemsMap[key] && itemsMap[key].isRequired
        ? { key, icon: icons[key], labelValues: { groupName: labels[key] } }
        : null
    )
    .filter(x => x);

  return sortedGroups.map((element, index) => (
    <span
      key={element.key}
      className={classNames({
        [classes.requiredMissing]: !element.value
      })}
    >
      <span
        className={classNames({
          [classes.iconContainer]: index !== 0
        })}
      >
        {element.icon}
      </span>
      <span className={classes.text}>
        {element.value ? (
          element.value.displayText
        ) : (
          <FormattedMessage
            id="resourceRequestDrawerDetails.noGroup"
            values={element.labelValues}
          />
        )}
      </span>
    </span>
  ));
};

const GroupItemPropTypes = PropTypes.shape({
  value: PropTypes.object,
  isRequired: PropTypes.bool
});

GroupsTitle.propTypes = {
  itemsMap: PropTypes.shape({
    costCenter: GroupItemPropTypes,
    division: GroupItemPropTypes,
    location: GroupItemPropTypes,
    department: GroupItemPropTypes,
    employeeType: GroupItemPropTypes,
    serviceCenter: GroupItemPropTypes
  })
};

export default GroupsTitle;
