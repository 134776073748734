import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import get from 'lodash.get';
import { FormattedNumber } from 'react-intl';
import { NoValue } from '~/modules/common/components';
import { isNumeric } from '~/modules/common/numbers';

export const CustomFieldColumn = ({ field, record }) => {
  const customFieldValue =
    ((record?.customFieldValues || []).length &&
      record.customFieldValues.find(x => x.customField.uri === field)) ||
    {};

  const isNumericType =
    get(customFieldValue, 'customFieldDefinition.type.uri') ===
    'urn:replicon:custom-field-type:numeric';

  const digits = get(
    customFieldValue,
    'customFieldDefinition.numericConfiguration.decimalPlaces',
    0
  );

  const value = customFieldValue.text;
  const hasValue = Boolean(value);
  const formattedValue =
    hasValue && isNumericType && isNumeric(Number(value)) ? (
      <FormattedNumber
        value={value}
        minimumFractionDigits={digits}
        maximumFractionDigits={digits}
      />
    ) : hasValue ? (
      value
    ) : null;

  return hasValue ? (
    <Typography align={isNumericType ? 'right' : 'left'} variant="body2">
      {formattedValue}
    </Typography>
  ) : (
    <NoValue />
  );
};

CustomFieldColumn.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string
};

export default CustomFieldColumn;
