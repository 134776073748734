import React from 'react';
import PropTypes from 'prop-types';
import { User as UserAvatar } from '~/modules/common/components';

export const User = ({ field, record }) => {
  const user = record[field];

  return <UserAvatar user={user} showTooltip />;
};

User.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default User;
