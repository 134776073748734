import React from 'react';
import { Caption } from '~/modules/projects/project/common/components/ContactInfo';

export const buildColumns = ({ addressHandlers, countries, isReadOnly }) => ({
  address: {
    visible: true,
    addressHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.addressInfo.fields.address" />
    )
  },
  city: {
    visible: true,
    addressHandlers,
    value: <Caption messageId="projectBasicInfoCard.addressInfo.fields.city" />
  },
  stateProvince: {
    visible: true,
    addressHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.addressInfo.fields.stateProvince" />
    )
  },
  zipPostalCode: {
    visible: true,
    addressHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.addressInfo.fields.zipPostalCode" />
    )
  },
  country: {
    visible: true,
    addressHandlers,
    countries,
    value: (
      <Caption messageId="projectBasicInfoCard.addressInfo.fields.country" />
    )
  },
  addressType: {
    visible: true,
    addressHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.addressInfo.fields.addressType" />
    )
  },
  removeAddress: {
    value: '',
    addressHandlers,
    visible: !isReadOnly
  }
});
