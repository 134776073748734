import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuItem, makeStyles, Typography } from '@material-ui/core';
import FormSelectField from '~/modules/common/components/FormSelectField';
import { MoneyValue } from '~/modules/common/components';

export const useStyles = makeStyles(theme => ({
  rate: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(0.5)
  },
  name: {
    padding: theme.spacing(1)
  }
}));

export const hadleRenderValue = value => () => (value ? value.displayText : '');

export const BillingRateDropdown = ({
  value,
  onChange,
  options = [],
  error,
  helperText,
  disabled = false,
  autoFocus
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const handleChange = useCallback(
    event => {
      onChange(options.find(x => x.id === event.target.value));
    },
    [options, onChange]
  );

  const renderValue = useMemo(
    () => hadleRenderValue(options.find(x => x.id === value)),
    [options, value]
  );

  return (
    <>
      {disabled ? (
        <Typography className={classes.name}>{renderValue()}</Typography>
      ) : (
        <FormSelectField
          label={intl.formatMessage({
            id: 'billingRateCard.columns.name'
          })}
          fullWidth
          value={options.length ? value : ''}
          onChange={handleChange}
          renderValue={renderValue}
          variant="filled"
          error={error}
          helperText={helperText}
          autoFocus={autoFocus}
        >
          {options.length ? (
            options.map(opt => {
              const displayText = <strong>{opt.displayText}</strong>;
              const { rate } = opt;
              const rateValue = (
                <MoneyValue money={rate} className={classes.rate} />
              );

              return (
                <MenuItem key={opt.id} value={opt.id}>
                  <FormattedMessage
                    id="billingRateCard.rateWithvalue"
                    values={{ displayText, rateValue }}
                  />
                </MenuItem>
              );
            })
          ) : (
            <MenuItem key="no_billingRate" value="" disabled>
              <FormattedMessage id="billingRateCard.noBillingRate" />
            </MenuItem>
          )}
        </FormSelectField>
      )}
    </>
  );
};

BillingRateDropdown.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default BillingRateDropdown;
