import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { useTimeAndExpenseThemeContext } from '../../TimeAndExpenseDetailsThemeContextProvider';
import withStyles from './withStyles';
import { GroupedHeadersPlaceholders } from './GroupedHeaders';
import { rowGroupingIdentity } from './GroupedColumn';

export const RowTotalsColumn = ({
  intl,
  classes,
  settings,
  rowTotals,
  hasMore,
  skeletons
}) => {
  const themeClasses = useTimeAndExpenseThemeContext();

  return (
    <div className={classes.rowTotalsColumn}>
      <GroupedHeadersPlaceholders
        record={rowTotals}
        label={intl.formatMessage({ id: 'timeAndExpenseTab.rowTotals' })}
      />
      <div className={classes.subcolumns}>
        {settings.columns.options
          .filter(({ key }) => settings.columns.values.includes(key))
          .map(column => (
            <div key={column.key} className={classes.subcolumn}>
              <div
                className={classNames(
                  classes.subheader,
                  themeClasses.subheader
                )}
              >
                {column.label}
              </div>
              {rowTotals.rows.map(({ groupings, cells }) => (
                <div
                  key={rowGroupingIdentity(groupings)}
                  className={classes.cell}
                >
                  <div className={classes.boldDatum}>
                    {settings.columns.formatters[column.key](cells[column.key])}
                  </div>
                </div>
              ))}
              {hasMore &&
                skeletons.map(value => (
                  <div key={value} className={classes.cell}>
                    <div className={classes.boldDatum}>
                      <Skeleton />
                    </div>
                  </div>
                ))}
              <div className={classes.footer}>
                {settings.columns.formatters[column.key](
                  rowTotals.totals[column.key]
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

RowTotalsColumn.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  settings: PropTypes.object,
  rowTotals: PropTypes.object,
  hasMore: PropTypes.bool,
  skeletons: PropTypes.array
};

export default withStyles(RowTotalsColumn);
