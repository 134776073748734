import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLoadMore2 as useLoadMore } from '~/modules/common/hooks';

export const PAGE_SIZE = 100;

export const TASK_ASSIGNMENT_SUMMARY_QUERY = gql`
  query taskAssignmentSummary(
    $page: Int!
    $pageSize: Int!
    $input: PageOfTaskAssignmentSummaryForProjectAndResourceUserInput
  ) {
    pageOfTaskAssignmentSummaryForProjectAndResourceUser(
      input: $input
      page: $page
      pageSize: $pageSize
    ) {
      taskSummary {
        task {
          id
          assignedRole {
            id
            displayText
          }
          displayText
          fullPath {
            id
            uri
            displayText
          }
          startDate
          endDate
          hasChildren
          estimatedHours
          isMilestone
          totalResourceEstimateHours
        }
        resourceEstimate {
          initialEstimatedHours
          estimateId
          projectRole {
            id
            displayText
          }
        }
      }
    }
  }
`;

export const useGetPageOfTaskAssignmentSummary = ({
  page = 1,
  pageSize = PAGE_SIZE,
  projectId,
  userId,
  textSearch
}) => {
  const { loading, data, fetchMore, refetch } = useQuery(
    TASK_ASSIGNMENT_SUMMARY_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        page,
        pageSize,
        input: {
          projectId,
          userId,
          filter: {
            isOpen: true,
            textSearch
          }
        }
      },
      errorPolicy: 'all'
    }
  );

  const assignmentSummary =
    data?.pageOfTaskAssignmentSummaryForProjectAndResourceUser?.taskSummary ||
    [];

  const { hasMore, loadingMore, loadMore } = useLoadMore({
    totalFetchedItems: assignmentSummary.length,
    fetchMore,
    loading,
    pageSize
  });

  return {
    loading,
    refetch,
    assignmentSummary,
    hasMoreAndNotLoading: hasMore && !loading && !loadingMore,
    loadMore,
    loadingMore
  };
};

export default useGetPageOfTaskAssignmentSummary;
