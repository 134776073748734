import React from 'react';
import { DialogContent, Dialog } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DialogButtonActions from '~/modules/common/components/DialogButtonActions';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import AddPortfolioForm from './AddPortfolioForm';
import AddPortfolioDialogHeader from './AddPortfolioDialogHeader';

const labels = {
  addPortfolio: <FormattedMessage id="portfolio.addPortfolioLabel" />,
  addSubPortfolio: <FormattedMessage id="portfolio.addSubPortfolioLabel" />
};

const addPortfolioDialogRole = {
  role: 'presentation'
};

export const AddPortfolioDialogContent = ({ open, onClose, parentName }) => {
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={addPortfolioDialogRole}
    >
      <AddPortfolioDialogHeader parentName={parentName} />
      <DialogContent>
        <AddPortfolioForm isSubPortfolio={Boolean(parentName)} />
      </DialogContent>
      <DialogButtonActions
        onClose={onClose}
        cancelButtonDataQeId="AddPortfolioCancel"
        addButtonDataQeId="AddPortfolioAdd"
        primaryButtonMessage={
          parentName ? labels.addSubPortfolio : labels.addPortfolio
        }
      />
    </Dialog>
  );
};

AddPortfolioDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  parentName: PropTypes.string
};

export default AddPortfolioDialogContent;
