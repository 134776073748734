import { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

export const useHasSomePermissions = actionDALUris => {
  const me = useMeContext();

  return useMemo(() => {
    for (const actionDALUri of actionDALUris) {
      const { actionUri, dataAccessLevelUri = null } = actionDALUri;

      const permission = me.permissions.find(
        p => p.permissionActionUri === actionUri
      );

      if (!permission) continue;

      if (!dataAccessLevelUri) return true;

      if (permission.dataAccessLevelUris.includes(dataAccessLevelUri))
        return true;
    }

    return false;
  }, [me.permissions, actionDALUris]);
};
