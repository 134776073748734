import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import {
  Grid,
  makeStyles,
  Divider,
  Button,
  Typography
} from '@material-ui/core';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import { ScreenReadableIconWrapper } from '~/modules/common/components';
import { useMeContext } from '~/modules/me';
import { useBillingRateEntryHandler } from '../hooks';
import { ScheduleEntryRow } from './ScheduleEntryRow';
import { BillingRateDropdown } from './BillingRateDropdown';
import { RemoveRowNotificationDialog } from './RemoveRowNotificationDialog';

export const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(1)
  },
  deleteItem: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex'
  },
  deleteEntryContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  deleteIcon: {
    color: theme.palette.text.primary
  },
  displayText: {
    padding: theme.spacing(1)
  }
}));
export const BillingRateEntryRow = ({
  id,
  billingRate,
  setFieldValue,
  billingRateIndex,
  onDeleteBillingRate,
  dropdownOptions,
  addOrRemoveBillingRate,
  onConfirmRemoveEntryRow,
  errors,
  projectSlug,
  isMobile
}) => {
  const me = useMeContext();
  const classes = useStyles({ isMobile });
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [removeErrorDetails, setRemoveErrorDetails] = useState({});
  const {
    onRemoveScheduleEntryRow,
    onAddScheduleEntryRow,
    onBillingRateChange,
    onDeleteBillingRateRow,
    onConfirmDeleteEntryRow,
    onCloseNotificationDialog
  } = useBillingRateEntryHandler({
    setOpenNotificationDialog,
    setRemoveErrorDetails,
    onDeleteBillingRate,
    onConfirmRemoveEntryRow,
    billingRateIndex,
    billingRate,
    setFieldValue,
    me,
    id
  });

  const { error, helperText } = useMemo(() => {
    const requiredError = get(
      errors,
      `billingRates.required[${billingRate.id}]`,
      null
    );
    const duplicateError = get(
      errors,
      `billingRates.duplicate[${billingRate.id}]`,
      null
    );

    return {
      error: Boolean(requiredError || duplicateError),
      helperText: requiredError || duplicateError
    };
  }, [billingRate.id, errors]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={isMobile ? 12 : 4} className={classes.item}>
        {addOrRemoveBillingRate ? (
          <BillingRateDropdown
            options={dropdownOptions}
            value={billingRate.id}
            onChange={onBillingRateChange}
            error={error}
            helperText={helperText}
            disabled={false}
            autoFocus={billingRateIndex === 0}
          />
        ) : (
          <Typography className={classes.displayText}>
            {billingRate.displayText}
          </Typography>
        )}
      </Grid>
      <Grid item xs={isMobile ? 12 : 7} className={classes.item}>
        {billingRate.billingScheduleEntries.map((scheduleEntry, index) => (
          <ScheduleEntryRow
            key={scheduleEntry.id}
            scheduleEntry={scheduleEntry}
            setFieldValue={setFieldValue}
            billingRateIndex={billingRateIndex}
            scheduleEntryIndex={index}
            onRemoveEntryRow={onRemoveScheduleEntryRow}
            onAddEntryRow={onAddScheduleEntryRow}
            errors={errors}
            projectSlug={projectSlug}
            isMobile={isMobile}
          ></ScheduleEntryRow>
        ))}
      </Grid>
      <Grid item xs={isMobile ? 12 : 1} className={classes.deleteItem}>
        {addOrRemoveBillingRate ? (
          <>
            {isMobile ? (
              <Button
                color="secondary"
                onClick={onDeleteBillingRateRow}
                className={classes.deleteBtn}
              >
                <FormattedMessage id="billingRateCard.removeRateIcon" />
              </Button>
            ) : (
              <>
                <Divider orientation="vertical" />
                <div className={classes.deleteEntryContainer}>
                  <ScreenReadableIconWrapper
                    iconAriaLabel="billingRateCard.removeRateButton"
                    buttonAriaLabel="billingRateCard.removeRateIcon"
                    role="img"
                    onClick={onDeleteBillingRateRow}
                  >
                    <DeleteSharpIcon fontSize="default" />
                  </ScreenReadableIconWrapper>
                </div>
              </>
            )}
          </>
        ) : null}
      </Grid>
      <RemoveRowNotificationDialog
        open={openNotificationDialog}
        onClose={onCloseNotificationDialog}
        onConfirm={onConfirmDeleteEntryRow}
        isMobile={isMobile}
        details={removeErrorDetails}
      />
    </Grid>
  );
};

BillingRateEntryRow.propTypes = {
  id: PropTypes.string,
  billingRate: PropTypes.object,
  setFieldValue: PropTypes.func,
  billingRateIndex: PropTypes.number,
  onDeleteBillingRate: PropTypes.func,
  dropdownOptions: PropTypes.array,
  addOrRemoveBillingRate: PropTypes.bool,
  errors: PropTypes.object,
  projectSlug: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  onConfirmRemoveEntryRow: PropTypes.func
};

export default BillingRateEntryRow;
