import { useCallback, useMemo } from 'react';

export const useDimensionValueEditorHook = ({
  record,
  nextRecord,
  previousRecord,
  dimensionIndex,
  dimensionId,
  projectDimensions,
  onAddNewEntry,
  index,
  onChange,
  objectType,
  onInputChange,
  readOnly
}) => {
  const showAddRate = useMemo(() => {
    if (readOnly) return false;
    if (
      !nextRecord &&
      projectDimensions &&
      projectDimensions[0].id !== dimensionId
    ) {
      return true;
    }

    const { id: previousDimensionId } =
      dimensionIndex > 0 ? projectDimensions[dimensionIndex - 1] : {};

    if (!previousDimensionId) {
      return false;
    }

    return (
      nextRecord[previousDimensionId]?.id !== record[previousDimensionId]?.id
    );
  }, [
    dimensionId,
    dimensionIndex,
    nextRecord,
    projectDimensions,
    record,
    readOnly
  ]);

  const showValue = useMemo(() => {
    if (!previousRecord) {
      return true;
    }

    return previousRecord[dimensionId]?.id !== record[dimensionId]?.id;
  }, [dimensionId, previousRecord, record]);

  const handleChange = useCallback(
    newValue => {
      if (onChange) {
        onChange({ index, dimensionId, value: newValue, objectType });
        if (onInputChange) onInputChange(objectType, newValue)();
      }
    },
    [onChange, index, dimensionId, objectType, onInputChange]
  );

  const onAddEntryClick = useCallback(() => {
    if (onAddNewEntry) {
      onAddNewEntry(record, dimensionIndex, index);
    }
  }, [onAddNewEntry, record, dimensionIndex, index]);

  return { showAddRate, showValue, onAddEntryClick, handleChange };
};
