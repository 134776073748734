import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, MenuItem } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
  level0: {
    paddingLeft: theme.spacing(2)
  },
  level1: {
    paddingLeft: theme.spacing(4)
  },
  level2: {
    paddingLeft: theme.spacing(6)
  },
  level3: {
    paddingLeft: theme.spacing(8)
  },
  level4: {
    paddingLeft: theme.spacing(10)
  },
  level5: {
    paddingLeft: theme.spacing(12)
  },
  level6: {
    paddingLeft: theme.spacing(14)
  },
  level7: {
    paddingLeft: theme.spacing(16)
  },
  level8: {
    paddingLeft: theme.spacing(18)
  },
  level9: {
    paddingLeft: theme.spacing(20)
  }
}));

export const HierarchyMenuItem = ({
  hierarchyLevel = 0,
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <MenuItem {...otherProps} className={classes[`level${hierarchyLevel}`]}>
      {children}
    </MenuItem>
  );
};

HierarchyMenuItem.propTypes = {
  hierarchyLevel: PropTypes.number,
  children: PropTypes.node
};

export default HierarchyMenuItem;
