import React from 'react';
import { FormattedMessage } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const ACTUAL_HOUR_COLOR = theme.palette.hours.actual;
const ACTUAL_HOUR_LABEL_COLOR = theme.palette.hours.actualLabel;
const ESTIMATED_BAR_COLOR = theme.palette.hours.estimated;

export const useHourChartConfig = () => ({
  actualBarColor: ACTUAL_HOUR_COLOR,
  actualLabelColor: ACTUAL_HOUR_LABEL_COLOR,
  gradientId: 'gradientForActualHours',
  legends: [
    {
      label: <FormattedMessage id="chart.hoursWorked" />,
      color: ACTUAL_HOUR_COLOR
    },
    {
      label: <FormattedMessage id="chart.allocatedHours" />,
      color: ESTIMATED_BAR_COLOR
    }
  ]
});

export default useHourChartConfig;
