import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ReadOnlyContent } from '~/modules/common/components/EditableCard';
import ReadOnlySection from '../../common/components/ReadOnlySection';
import GroupsInformationReadOnly from './GroupsInformationReadOnly';

const title = <FormattedMessage id="resourceBasicInfoCard.groups" />;
const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(0, -2, -2, -2)
  }
}));

export const GroupsInformation = ({ userDetails, permittedActions }) => {
  const classes = useStyles();

  const isGroupSectionEnabled =
    permittedActions.includes('urn:replicon:user-action:view-location') ||
    permittedActions.includes('urn:replicon:user-action:view-division') ||
    permittedActions.includes('urn:replicon:user-action:view-service-center') ||
    permittedActions.includes('urn:replicon:user-action:view-cost-center') ||
    permittedActions.includes('urn:replicon:user-action:view-department') ||
    permittedActions.includes('urn:replicon:user-action:view-employee-type');

  return (
    isGroupSectionEnabled && (
      <div className={classes.card}>
        <ReadOnlySection title={title} editable={false}>
          <ReadOnlyContent>
            <GroupsInformationReadOnly
              userDetails={userDetails}
              permittedActions={permittedActions}
            />
          </ReadOnlyContent>
        </ReadOnlySection>
      </div>
    )
  );
};

GroupsInformation.propTypes = {
  userDetails: PropTypes.object,
  permittedActions: PropTypes.array.isRequired
};

export default GroupsInformation;
