import React from 'react';
import { PropTypes } from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import CommentIcon from '@material-ui/icons/CommentSharp';
import useStyles from './useStyles';

export const CommentIconAdornment = () => {
  const classes = useStyles();

  return (
    <InputAdornment position="start" className={classes.adornment}>
      <CommentIcon className={classes.inputIcon} />
    </InputAdornment>
  );
};

export const InputFieldAdornment = ({
  position = 'start',
  adornmentSymbol
}) => (
  <InputAdornment position={position}>
    <span>{adornmentSymbol}</span>
  </InputAdornment>
);

InputFieldAdornment.propTypes = {
  position: PropTypes.string,
  adornmentSymbol: PropTypes.string.isRequired
};
