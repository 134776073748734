import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(0, 0, 0, 2)
  },
  root: {
    margin: 0
  },
  select: {
    fontSize: theme.typography.body2.fontSize,
    minHeight: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1, 3, 1, 1.5),
    color: theme.palette.text.secondary
  }
});

const inputComponent = <OutlinedInput labelWidth={0} />;
const menuProps = {
  MenuListProps: {
    dense: true
  }
};

export const BillingOverviewPeriodDropdown = ({ classes, scale, setScale }) => {
  const handleChange = useCallback(
    event => {
      event.target.value && setScale(event.target.value);
    },
    [setScale]
  );

  return (
    <FormControl className={classes.formControl}>
      <Select
        classes={useMemo(
          () => ({ root: classes.root, select: classes.select }),
          [classes.root, classes.select]
        )}
        value={scale}
        onChange={handleChange}
        input={inputComponent}
        MenuProps={menuProps}
      >
        <MenuItem value={PERIOD_SCALE_ENUM.DAYS}>
          <FormattedMessage id="billingOverviewPeriodDropdown.day" />
        </MenuItem>
        <MenuItem value={PERIOD_SCALE_ENUM.WEEKS}>
          <FormattedMessage id="billingOverviewPeriodDropdown.week" />
        </MenuItem>
        <MenuItem value={PERIOD_SCALE_ENUM.MONTHS}>
          <FormattedMessage id="billingOverviewPeriodDropdown.month" />
        </MenuItem>
        <MenuItem value={PERIOD_SCALE_ENUM.QUARTERS}>
          <FormattedMessage id="billingOverviewPeriodDropdown.quarter" />
        </MenuItem>
        <MenuItem value={PERIOD_SCALE_ENUM.YEARS}>
          <FormattedMessage id="billingOverviewPeriodDropdown.year" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

BillingOverviewPeriodDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  setScale: PropTypes.func.isRequired
};

export default withStyles(styles)(BillingOverviewPeriodDropdown);
