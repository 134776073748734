import { useCallback } from 'react';
import get from 'lodash.get';
import { removeAllTooltips } from './useOnTaskHoverTooltips';
import {
  getTaskEventHandlers,
  openTaskDrawer,
  openProjectDrawer
} from './task-event-handlers';

export const TASK_TREE_INDENT = 'gantt_tree_indent';
export const TASK_TREE_BLANK_ICON_CELL = 'gantt_tree_icon gantt_blank';
export const TASK_TREE_ICON = 'gantt_tree_icon gantt_file';
export const TASK_TREE_ROW = 'gantt_tree_content';
export const TASK_TREE_CELL = 'gantt_cell';
export const TASK_TREE_NAME = 'gantt_task_name';
export const TASK_BAR = 'gantt_task_content';
export const TASK_ADD = 'gantt_add';
export const TASK_COLLAPSE = 'gantt_tree_icon gantt_close';
export const TASK_EXPAND = 'gantt_tree_icon gantt_open';
export const TASK_ASSIGNED_TO = 'gantt_assignto';
export const TASK_ASSIGN_ROLE = 'gantt_assignRole';
export const TASK_MISSING_DATE_CONTAINER = 'gantt_task_bar_name_tooltip';

export const useOnTaskClick = ({
  me,
  history,
  editable,
  hasEditTaskPermissions,
  setGanttRef,
  setTaskId,
  setAnchorPosition,
  openAssignmentPopover,
  openRoleAssignmentPopover,
  openAddTaskDialog,
  collapseTask,
  expandTask
}) =>
  useCallback(
    gantt => (id, event) => {
      const isProject = gantt.getTaskByIndex(0).id === id;
      const {
        target: { className, parentNode },
        clientY,
        clientX
      } = event;

      const openTaskOwnerMenu = () => {
        const task = gantt.getTask(id);

        setGanttRef(gantt);
        setTaskId(id);
        setAnchorPosition({ top: clientY, left: clientX });
        openAssignmentPopover(task.text);
      };

      const openTaskRoleMenu = () => {
        setGanttRef(gantt);
        setTaskId(id);
        setAnchorPosition({ top: clientY, left: clientX });
        openRoleAssignmentPopover();
      };

      const { isRolledUpTaskEstimateCalculationMethodEnabled } = me;

      const enableMissingDatePill =
        isRolledUpTaskEstimateCalculationMethodEnabled &&
        !isProject &&
        parentNode &&
        parentNode.className === TASK_MISSING_DATE_CONTAINER;

      const {
        onTaskCollapse,
        onTaskExpand,
        onTaskAdd,
        onTaskBarClick,
        onDrawerOpen
      } = getTaskEventHandlers({
        gantt,
        id,
        isRolledUpTaskEstimateCalculationMethodEnabled,
        isProject,
        collapseTask,
        expandTask,
        openAddTaskDialog,
        history,
        editable,
        setGanttRef
      });

      switch (className) {
        case TASK_ASSIGNED_TO: {
          if (!editable || !hasEditTaskPermissions) break;

          openTaskOwnerMenu();
          break;
        }
        case TASK_ASSIGN_ROLE: {
          if (!editable || !hasEditTaskPermissions) break;

          openTaskRoleMenu();
          break;
        }
        case TASK_TREE_ROW: {
          if (isRolledUpTaskEstimateCalculationMethodEnabled && isProject) {
            openProjectDrawer({ history, gantt, expandTask, id });

            break;
          }

          const parentClassName = get(
            event,
            'target.parentNode.className',
            null
          );

          if (
            parentClassName === TASK_ASSIGNED_TO &&
            editable &&
            hasEditTaskPermissions
          ) {
            openTaskOwnerMenu();

            break;
          }

          if (
            parentClassName === TASK_ASSIGN_ROLE &&
            editable &&
            hasEditTaskPermissions
          ) {
            openTaskRoleMenu();

            break;
          }
          openTaskDrawer({ history, gantt, id, editable, expandTask });
          break;
        }

        case TASK_TREE_NAME:
        case TASK_TREE_INDENT:
        case TASK_TREE_BLANK_ICON_CELL:
        case TASK_TREE_CELL:
        case TASK_TREE_ICON: {
          return onDrawerOpen();
        }
        case TASK_ADD: {
          return onTaskAdd();
        }
        case TASK_COLLAPSE: {
          return onTaskCollapse();
        }
        case TASK_EXPAND: {
          return onTaskExpand();
        }
        case TASK_BAR: {
          return onTaskBarClick();
        }
        default:
          if (enableMissingDatePill) {
            removeAllTooltips();
            openTaskDrawer({ history, gantt, id, editable, expandTask });
            break;
          }

          return false;
      }

      return true;
    },
    [
      collapseTask,
      editable,
      expandTask,
      hasEditTaskPermissions,
      history,
      me,
      openAddTaskDialog,
      openAssignmentPopover,
      openRoleAssignmentPopover,
      setAnchorPosition,
      setGanttRef,
      setTaskId
    ]
  );

export default useOnTaskClick;
