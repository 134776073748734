import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useIsBreakpointDown } from '../../hooks';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '552px',
    height: '442px'
  },
  mobileContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}));

export const SavingProgress = () => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  return (
    <div className={isMobile ? classes.mobileContainer : classes.container}>
      <CircularProgress data-qe-id="savingProgress" />
    </div>
  );
};

SavingProgress.propTypes = {};

export default SavingProgress;
