import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ArrowForwardRounded } from '@material-ui/icons';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { TooltipDate } from './TooltipDate';

const useStyles = makeStyles({
  headerContainer: {
    backgroundColor: '#ebebeb',
    margin: theme.spacing(-1.25, -1.25, 1, -1.25),
    padding: theme.spacing(0.6, 1.25, 1, 1.25)
  },
  taskNameContainer: {
    textOverflow: 'ellipsis',
    width: '290px',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  taskNameLabel: {
    fontSize: theme.spacing(1.5) - 1,
    fontWeight: '500',
    marginRight: theme.spacing(1.25)
  },
  taskName: {
    fontSize: theme.spacing(1.5) + 1,
    fontWeight: '600'
  },
  flexContainer: {
    display: 'flex'
  },
  subHeadingContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pillSubheading: {
    display: 'flex',
    flexDirection: 'column'
  },
  noDatePillSubheading: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(5)
  },
  statusPill: {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(0, 2),
    fontSize: theme.spacing(1.5),
    fontWeight: '600',
    backgroundColor: ({ taskStatus }) =>
      theme.palette.taskStatus[taskStatus].color
  },
  dateIcon: {
    height: '100%',
    fontSize: theme.spacing(2.25),
    fill: theme.palette.taskRollUp.text.subHeading,
    margin: theme.spacing(0, 1, 0, 1)
  },
  subheadingLabel: {
    fontSize: theme.spacing(1.25),
    color: theme.palette.taskRollUp.text.subHeading
  },
  headerContainerWithFFOn: {
    padding: theme.spacing(0.6, 1.25, 1, 1.25),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  taskNameLabelWithFFOn: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },
  taskNameWithFFOn: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },
  subheadingLabelWithFFOn: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
});

export const TooltipHeader = ({
  intl,
  task,
  isTask,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles({
    taskStatus: task.taskStatus
  });
  const hasStartDate = task.startDate !== null;
  const hasEndDate = task.endDate !== null;
  const taskLabel = intl.formatMessage({
    id: isTask ? 'projectTasksPage.taskName' : 'projectTasksPage.projectName'
  });
  const taskStatusText = intl.formatMessage({
    id: isTask
      ? 'projectTasksPage.taskStatus'
      : 'projectTasksPage.projectStatus'
  });
  const status = intl.formatMessage({
    id: `projectTasksPage.tasksStatus.${task.taskStatus}`
  });

  return (
    <div
      className={classNames(classes.headerContainer, {
        [classes.headerContainerWithFFOn]: isPsaRmpTaskAllocation1Enabled
      })}
    >
      <div className={classes.taskNameContainer}>
        <span
          className={classNames(classes.taskNameLabel, {
            [classes.taskNameLabelWithFFOn]: isPsaRmpTaskAllocation1Enabled
          })}
        >
          {taskLabel}:
        </span>
        <span
          className={classNames(classes.taskName, {
            [classes.taskNameWithFFOn]: isPsaRmpTaskAllocation1Enabled
          })}
        >
          {task.text}
        </span>
      </div>
      <div
        className={classNames({
          [classes.flexContainer]: !hasStartDate && !hasEndDate,
          [classes.subHeadingContainer]: hasStartDate || hasEndDate
        })}
      >
        <div
          className={classNames({
            [classes.noDatePillSubheading]: !hasStartDate && !hasEndDate,
            [classes.pillSubheading]: hasStartDate || hasEndDate
          })}
        >
          <span
            className={classNames(classes.subheadingLabel, {
              [classes.subheadingLabelWithFFOn]: isPsaRmpTaskAllocation1Enabled
            })}
          >
            {taskStatusText}
          </span>
          <span className={classes.statusPill}>{status}</span>
        </div>
        <div className={classes.flexContainer}>
          <TooltipDate
            intl={intl}
            task={task}
            isStartDate
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
          <ArrowForwardRounded className={classes.dateIcon} />
          <TooltipDate
            intl={intl}
            task={task}
            isStartDate={false}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
        </div>
      </div>
    </div>
  );
};

TooltipHeader.propTypes = {
  intl: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  isTask: PropTypes.bool.isRequired,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default TooltipHeader;
