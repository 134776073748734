import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const UPDATE_EXTENSION_FIELD_MUTATION = gql`
  mutation Eager_updateExtensionField(
    $objectUri: String!
    $value: ExtensionFieldValueInput!
  ) {
    updateExtensionField2(objectUri: $objectUri, value: $value)
  }
`;

export const useUpdateExtensionField = options =>
  useMutation(UPDATE_EXTENSION_FIELD_MUTATION, options);
