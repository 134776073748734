import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const PUT_PROJECT_REV_REC_SETTINGS = gql`
  mutation updateProjectRevenueRecognitionBatchUri(
    $revenueRecognitionBatchInput: RevenueRecognitionBatchInput!
  ) {
    putProjectRevenueRecognitionContractBatchUri2(
      revenueRecognitionBatchInput: $revenueRecognitionBatchInput
    )
  }
`;

export const usePutProjectRevenueRecognitionSettings = revenueRunUri => {
  const [updateProjectRevenueRecognitionBatchUri] = useMutation(
    PUT_PROJECT_REV_REC_SETTINGS
  );

  return {
    updateProjectRevenueRecognitionBatchUri: batchUri => {
      return updateProjectRevenueRecognitionBatchUri({
        variables: {
          revenueRecognitionBatchInput: {
            batchUri,
            revenueRunUri
          }
        }
      });
    }
  };
};

export default usePutProjectRevenueRecognitionSettings;
