import { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

export const useHasUserAccessRole = ({ roleUri }) => {
  const me = useMeContext();

  return useMemo(() => me.userAccessRoles.some(role => role === roleUri), [
    me.userAccessRoles,
    roleUri
  ]);
};
