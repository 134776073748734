import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { MoneyValue } from '~/modules/common/components/Money';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';
import withStyles from './withStyles';

const expandIcon = <ExpandMoreSharpIcon />;

export const DrawerExpansionPanel = ({
  classes,
  resourceKeys,
  children,
  amountTotal,
  defaultExpanded = true,
  expanded,
  setExpanded
}) => {
  const amountTotalDisplay = {
    ...amountTotal,
    amount: getRoundedValue(amountTotal.amount, 2)
  };

  const isLineItemsPanel = resourceKeys?.title?.includes('lineItems');

  const handleChange = useCallback(
    (event, _expanded) => {
      setExpanded(!expanded);
    },
    [expanded, setExpanded]
  );

  return (
    <Accordion
      className={classes.expansionPanel}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      expanded={expanded}
    >
      <AccordionSummary
        classes={useMemo(
          () => ({
            root: classes.expansionPanelSummary,
            content: classes.expansionPanelSummaryContent,
            expanded: classes.expanded,
            expandIcon: classes.expandIcon
          }),
          [
            classes.expandIcon,
            classes.expanded,
            classes.expansionPanelSummary,
            classes.expansionPanelSummaryContent
          ]
        )}
        expandIcon={expandIcon}
        data-qe-id={`${resourceKeys.title}_expandCollapseIcon`}
        id={`${resourceKeys.title}_expandCollapseIcon`}
        aria-controls={resourceKeys.title}
      >
        <div className={classes.expansionPanelSummaryTitle}>
          <FormattedMessage id={resourceKeys.title} />
        </div>
        {!isLineItemsPanel && (
          <div className={classes.amountTotal}>
            <FormattedMessage id={resourceKeys.allocatedAmount} />
          </div>
        )}
        <div className={classes.amountTotal}>
          <MoneyValue
            money={amountTotalDisplay}
            dataQeId={`${resourceKeys.title}_totalAmount`}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionPanelDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

DrawerExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  resourceKeys: PropTypes.object.isRequired,
  amountTotal: PropTypes.object.isRequired,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func
};
export default withStyles(DrawerExpansionPanel);
