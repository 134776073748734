import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  makeStyles,
  CircularProgress,
  DialogContent,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center'
  }
}));

export const MergeResourceRequestDialogContent = ({
  isLoading,
  resourceAllocationCount
}) => {
  const classes = useStyles();

  return (
    <DialogContent className={isLoading ? classes.root : null}>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage
            id="mergeResourceRequestDialog.mergeResourceDescription"
            values={{ count: resourceAllocationCount }}
          />
        </Typography>
      )}
    </DialogContent>
  );
};

MergeResourceRequestDialogContent.propTypes = {
  isLoading: PropTypes.bool,
  resourceAllocationCount: PropTypes.number
};

export default MergeResourceRequestDialogContent;
