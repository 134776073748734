import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useMeContext } from '~/modules/me';
import ResourceRequestCommentField from './ResourceRequestCommentField';
import ResourceRequestLocationField from './ResourceRequestLocationField';
import ResourceRequestDepartmentField from './ResourceRequestDepartmentField';
import ResourceRequestDivisionField from './ResourceRequestDivisionField';
import ResourceRequestCostCenterField from './ResourceRequestCostCenterField';
import ResourceRequestServiceCenterField from './ResourceRequestServiceCenterField';
import ResourceRequestEmployeeTypeField from './ResourceRequestEmployeeTypeField';
import ResourceRequestSkillsField from './ResourceRequestSkillsField';
import ResourceRequestTagsField from './ResourceRequestTagsField';
import ResourceRequestRequestedCostField from './ResourceRequestRequestedCostField';
import ResourceRequestResourcePoolsField from './ResourceRequestResourcePoolsField';
import ResourceRequestPreferredResourcesField from './ResourceRequestPreferredResourcesField';
import { useTooltipStyles } from './useStyles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    minWidth: theme.spacing(7),
    zIndex: 1
  }
}));

export const ResourceRequestDetailIndicator = ({
  resourceRequest,
  maxSkillLevel
}) => {
  const classes = useStyles();
  const tooltipPopperClasses = useTooltipStyles();

  const { permissionsMap } = useMeContext();

  const {
    comment,
    serviceCenter,
    location,
    skills,
    tags,
    employeeType,
    division,
    costCenter,
    department,
    totalHours,
    roleRate,
    currency,
    preferredResources
  } = resourceRequest;

  const canViewTags =
    permissionsMap['urn:replicon:user-action:view-custom-fields'];

  const canViewRequestedCost =
    permissionsMap['urn:replicon:project-action:view-cost-data'] ||
    permissionsMap['urn:replicon:user-action:view-cost-data'];

  return (
    <div className={classes.root}>
      {Boolean(preferredResources?.length) && (
        <ResourceRequestPreferredResourcesField
          preferredResources={preferredResources}
          tooltipClasses={tooltipPopperClasses}
        />
      )}
      {costCenter && <ResourceRequestCostCenterField value={costCenter} />}
      {department && <ResourceRequestDepartmentField value={department} />}
      {division && <ResourceRequestDivisionField value={division} />}
      {employeeType && (
        <ResourceRequestEmployeeTypeField value={employeeType} />
      )}
      {location && <ResourceRequestLocationField value={location} />}
      {serviceCenter && (
        <ResourceRequestServiceCenterField value={serviceCenter} />
      )}
      {skills && skills.length !== 0 && (
        <ResourceRequestSkillsField
          skills={skills}
          maxSkillLevel={maxSkillLevel}
          tooltipClasses={tooltipPopperClasses}
        />
      )}
      {canViewTags && tags && tags.length !== 0 && (
        <ResourceRequestTagsField
          tags={tags}
          tooltipClasses={tooltipPopperClasses}
        />
      )}
      {typeof comment === 'string' && comment.length > 0 && (
        <ResourceRequestCommentField
          value={comment}
          tooltipClasses={tooltipPopperClasses}
        />
      )}
      {canViewRequestedCost && roleRate > 0 && currency && (
        <ResourceRequestRequestedCostField
          roleRate={roleRate}
          currency={currency}
          totalHours={totalHours}
          tooltipClasses={tooltipPopperClasses}
        />
      )}
      {resourceRequest.resourcePools &&
        resourceRequest.resourcePools.length !== 0 && (
          <ResourceRequestResourcePoolsField
            resourcePools={resourceRequest.resourcePools}
            tooltipClasses={tooltipPopperClasses}
          />
        )}
    </div>
  );
};

ResourceRequestDetailIndicator.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  maxSkillLevel: PropTypes.number
};

export default ResourceRequestDetailIndicator;
