import React from 'react';
import { FormattedMessage } from 'react-intl';

export const resourcingLegendItems = {
  request: {
    label: <FormattedMessage id="resourcingLegends.request" />,
    className: 'request',
    barType: 'dashedBar'
  },
  requestRejected: {
    label: <FormattedMessage id="resourcingLegends.requestRejected" />,
    className: 'rejected',
    barType: 'dashedBar'
  },
  toBeHired: {
    label: <FormattedMessage id="resourcingLegends.toBeHired" />,
    className: 'toBeHired',
    barType: 'dashedBar'
  },
  resourceRejected: {
    label: <FormattedMessage id="resourcingLegends.resourceRejected" />,
    className: 'rejected',
    barType: 'roundBar'
  },
  pending: {
    label: <FormattedMessage id="resourcingLegends.pending" />,
    className: 'pending',
    barType: 'roundBar'
  },
  proposed: {
    label: <FormattedMessage id="resourcingLegends.proposed" />,
    className: 'proposed',
    barType: 'roundBar'
  },
  complete: {
    label: <FormattedMessage id="resourcingLegends.complete" />,
    className: 'complete',
    barType: 'roundBar'
  },
  allocatedProgress: {
    isProgressBarLegend: true,
    className: 'allocated',
    label: <FormattedMessage id="resourcingLegends.allocated" />
  },
  overAllocated: {
    className: 'overAllocated',
    label: <FormattedMessage id="resourcingLegends.overAllocated" />
  },
  timeOff: {
    className: 'timeOff',
    label: <FormattedMessage id="resourcingLegends.timeOff" />
  },
  holiday: {
    className: 'holiday',
    label: <FormattedMessage id="resourcingLegends.holidays" />
  },
  notWorking: {
    className: 'notWorking',
    label: <FormattedMessage id="resourcingLegends.notWorking" />
  },
  rejectedAllocation: {
    className: 'rejected',
    label: <FormattedMessage id="resourcingLegends.rejectedAllocation" />,
    barType: 'roundBar'
  },
  proposedAllocation: {
    className: 'proposed',
    label: <FormattedMessage id="resourcingLegends.proposedAllocation" />,
    barType: 'roundBar'
  },
  pendingAllocation: {
    className: 'pending',
    label: <FormattedMessage id="resourcingLegends.pendingAllocation" />,
    barType: 'roundBar'
  },
  allocation: {
    className: 'complete',
    label: <FormattedMessage id="resourcingLegends.allocation" />,
    barType: 'roundBar'
  },
  timeoffAllocation: {
    className: 'timeOffAllocation',
    label: <FormattedMessage id="resourcingLegends.timeOff" />,
    barType: 'roundBar'
  }
};
