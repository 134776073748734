import {
  useOutstandingBills,
  mergeAssociatedAndOutstandingBills
} from '~/modules/billing-invoicing/common/hooks';

export const useAssociatedAndOutStandingBills = ({
  me,
  values,
  client,
  billPayment,
  billId,
  billBalanceTotal,
  isRefund,
  showAllOutstandingBills
}) => {
  const {
    outstandingBills,
    loading,
    hasMoreRows,
    isLoadingMore,
    loadMoreRows
  } = useOutstandingBills({
    me,
    values,
    client,
    billPayment,
    showAllOutstandingBills,
    billId
  });

  const { associatedAndOutstandingBills } = mergeAssociatedAndOutstandingBills({
    outstandingBills,
    creditItem: values || billPayment,
    billId,
    billBalanceTotal,
    isRefund
  });

  return {
    loading,
    hasMoreRows,
    isLoadingMore,
    loadMoreRows,
    associatedAndOutstandingBills
  };
};
