import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { deepPure } from '~/util';
import { useDialogState } from '~/modules/common/hooks';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import UserAllocationSummaryBlock from '../UserAllocationSummaryBlock';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1
  },
  container: {}
}));

export const HolidaySummaryBlock = ({
  scale,
  chartStartDate,
  holidaySummaryPeriod,
  holidays,
  showTooltip = true,
  clickable = false,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const theme = useTheme();

  const { name, totalHolidayHours, totalAvailableHours } = holidaySummaryPeriod;

  const dynamicStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.resourceAllocation.notWorking
    }),
    [theme.palette.resourceAllocation.notWorking]
  );

  const clippedChartDisplayDateRange = useMemo(
    () => ({
      start: holidaySummaryPeriod.startDate,
      end: holidaySummaryPeriod.startDate
    }),
    [holidaySummaryPeriod.startDate]
  );

  const period = useMemo(
    () => ({
      startDate: holidaySummaryPeriod.startDate,
      endDate: holidaySummaryPeriod.startDate
    }),
    [holidaySummaryPeriod.startDate]
  );

  const filteredHolidays = useMemo(
    () =>
      (holidays || []).filter(
        holiday =>
          mapRepliconDateToUtcObject(holiday.date).valueOf() ===
          mapIsoStringtoUtcObject(holidaySummaryPeriod.startDate).valueOf()
      ),
    [holidays, holidaySummaryPeriod.startDate]
  );

  const {
    open: showTimeoffTooltip,
    openDialog: openTimeoffTooltipDialog,
    closeDialog: closeTimeoffTooltipDialog
  } = useDialogState(false);

  const handleTimeoffToolTipClick = useCallback(() => {
    showTimeoffTooltip
      ? closeTimeoffTooltipDialog()
      : openTimeoffTooltipDialog();
  }, [closeTimeoffTooltipDialog, openTimeoffTooltipDialog, showTimeoffTooltip]);

  const showTooltipConditonal = clickable
    ? showTooltip && showTimeoffTooltip
    : showTooltip;

  const allocationBlock = (
    <UserAllocationSummaryBlock
      scale={scale}
      classes={classes}
      clippedChartDisplayDateRange={clippedChartDisplayDateRange}
      period={period}
      chartStartDate={chartStartDate}
      name={name}
      totalHours={totalHolidayHours}
      totalAvailableHours={totalAvailableHours}
      holidays={filteredHolidays}
      loadRatio={totalHolidayHours / totalAvailableHours}
      dynamicStyle={dynamicStyle}
      hideTotalAllocationLabel
      showLabel={false}
      showTooltip={showTooltipConditonal}
      clickable={clickable}
    />
  );

  if (clickable) {
    return (
      <div
        onClick={handleTimeoffToolTipClick}
        onMouseDown={handleTimeoffToolTipClick}
        onMouseLeave={closeTimeoffTooltipDialog}
        onKeyDown={handleTimeoffToolTipClick}
        role="presentation"
      >
        {allocationBlock}
      </div>
    );
  }

  return allocationBlock;
};

HolidaySummaryBlock.propTypes = {
  holidaySummaryPeriod: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  showTooltip: PropTypes.bool,
  clickable: PropTypes.bool
};

export default deepPure(HolidaySummaryBlock);
