import { useCallback } from 'react';
import {
  getUpdatedParameters,
  getCreateExpenseScript,
  getBillableTypeChangedStatus,
  getMonthlyExpensesPeriods
} from '~/modules/projects/project/BillPlanV2/hooks/utils';
import { isNullOrUndefined } from '~/modules/common/util';
import { ExpenseEntryType } from '~/types';

const updateEstimatedAmount = ({
  index,
  key,
  billableType,
  setFieldValue,
  amount
}) => {
  setFieldValue(
    `monthlyExpenses[${index}].${key}.estimated.${billableType}.amount`,
    amount
  );
};

const updateBillableEstimated = ({
  monthlyExpenses,
  record,
  index,
  billableType,
  setFieldValue
}) => {
  const monthlyExpensesPeriods = getMonthlyExpensesPeriods(
    monthlyExpenses[index]
  );

  monthlyExpensesPeriods.forEach(period => {
    if (record[period]?.estimated?.billableAmount?.amount)
      updateEstimatedAmount({
        index,
        key: period,
        billableType: 'billableAmount',
        setFieldValue,
        amount: 0
      });
  });
  if (monthlyExpenses[index]?.totalEstimates?.estimated?.billableAmount?.amount)
    updateEstimatedAmount({
      index,
      key: 'totalEstimates',
      billableType: 'billableAmount',
      setFieldValue,
      amount: 0
    });
};

const updateNonBillableEstimated = ({
  monthlyExpenses,
  record,
  index,
  billableType,
  setFieldValue
}) => {
  const monthlyExpensesPeriods = getMonthlyExpensesPeriods(
    monthlyExpenses[index]
  );

  monthlyExpensesPeriods.forEach(period => {
    if (record[period]?.estimated?.nonBillableAmount?.amount)
      updateEstimatedAmount({
        index,
        key: period,
        billableType: 'nonBillableAmount',
        setFieldValue,
        amount: 0
      });
  });
  if (
    monthlyExpenses[index]?.totalEstimates?.estimated?.nonBillableAmount?.amount
  )
    updateEstimatedAmount({
      index,
      key: 'totalEstimates',
      billableType: 'nonBillableAmount',
      setFieldValue,
      amount: 0
    });
};

export const useExpenseScriptFormatter = ({ values, setFieldValue }) => {
  const onBillableTypeChangeHandler = useCallback(
    ({ billableType, index, record }) => {
      const {
        values: { scripts, monthlyExpenses, parameters },
        editable,
        viewSummary,
        readOnlyParameters
      } = values;

      const updatedParameters = getUpdatedParameters(
        editable,
        parameters,
        readOnlyParameters,
        viewSummary
      );

      setFieldValue(
        `monthlyExpenses[${index}].expenseCode.expenseEntryType`,
        billableType.uri
      );
      setFieldValue(
        `projectExpenseCodes[${index}].expenseEntryType`,
        billableType.uri
      );

      if (billableType.uri === ExpenseEntryType.NonBillable) {
        setFieldValue(
          'scripts',
          scripts.filter(script => !(script.scriptId === record.scriptId))
        );
      } else if (isNullOrUndefined(record.scriptId)) {
        setFieldValue('scripts', [
          ...scripts,
          getCreateExpenseScript(updatedParameters, {
            ...record.expenseCode,
            expenseEntryType: billableType.uri
          })
        ]);
      }

      const {
        billableToNonBillableOrNonBillableToBillable,
        billableAndNonBillableToBillable,
        billableAndNonBillableToNonBillable
      } = getBillableTypeChangedStatus({
        prevBillableTypeStatus: record.expenseCode.expenseEntryType,
        currBillableTypeStatus: billableType.uri
      });

      const inputProps = {
        monthlyExpenses,
        record,
        index,
        billableType,
        setFieldValue
      };

      switch (true) {
        case billableToNonBillableOrNonBillableToBillable: {
          updateBillableEstimated(inputProps);
          updateNonBillableEstimated(inputProps);
          break;
        }
        case billableAndNonBillableToBillable: {
          updateNonBillableEstimated(inputProps);
          break;
        }
        case billableAndNonBillableToNonBillable: {
          updateBillableEstimated(inputProps);
          break;
        }
        default:
          break;
      }
    },
    [setFieldValue, values]
  );

  const onMarkupChangeHandler = useCallback(
    ({ columnId, newPercentage, record }) => {
      const {
        values: { scripts }
      } = values;
      const newScript = scripts.map(script => {
        if (script.scriptId === record.scriptId) {
          return { ...script, [columnId]: (newPercentage + 100) / 100 };
        }

        return script;
      });

      setFieldValue('scripts', newScript);
    },
    [setFieldValue, values]
  );

  return {
    onBillableTypeChangeHandler,
    onMarkupChangeHandler
  };
};
