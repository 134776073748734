import get from 'lodash.get';
import { useCallback } from 'react';

export const hasErrors = errors =>
  Boolean(errors && Object.keys(errors).length > 0);
export const hasError = (errors, path) => Boolean(get(errors, path));
export const getError = (errors, path) => get(errors, path);

export const makeSimpleEventChangeHandler = fieldName => ({
  setFieldValue
}) => event => setFieldValue(fieldName, event.target.value);

export const makeSimpleChangeHandler = fieldName => ({
  setFieldValue
}) => value => setFieldValue(fieldName, value);

export const useSimpleChangeHandler = setFieldValue =>
  useCallback(
    fieldName => makeSimpleChangeHandler(fieldName)({ setFieldValue }),
    [setFieldValue]
  );
