import { gql } from 'graphql-tag';

export const resourceRequestDeletedSubscription = gql`
  subscription ResourceRequestDeleted {
    resourceRequestDeleted {
      id
      projectUri
      requestStatus
    }
  }
`;
