import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

export const DIFF_VALUE_TYPE = {
  NEUTRAL: 'NEUTRAL',
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  WARNING: 'WARNING',
  INFO: 'INFO'
};

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.125, 0.25),
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0, 0.5),
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary
  },
  diffValuePositive: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark
  },
  diffValueNegative: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffValueWarning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  },
  diffValueInfo: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark
  }
}));

const DiffValue = ({
  diffValueType = DIFF_VALUE_TYPE.NEUTRAL,
  children,
  classes: classesOverrides,
  className
}) => {
  const classes = useStyles({ classes: classesOverrides });

  if (Children.count(children) > 0) {
    return (
      <span
        className={classNames(classes.root, className, {
          [classes.diffValuePositive]:
            diffValueType === DIFF_VALUE_TYPE.POSITIVE,
          [classes.diffValueNegative]:
            diffValueType === DIFF_VALUE_TYPE.NEGATIVE,
          [classes.diffValueWarning]: diffValueType === DIFF_VALUE_TYPE.WARNING,
          [classes.diffValueInfo]: diffValueType === DIFF_VALUE_TYPE.INFO
        })}
      >
        {children}
      </span>
    );
  }

  return null;
};

DiffValue.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  diffValueType: PropTypes.oneOf([
    DIFF_VALUE_TYPE.WARNING,
    DIFF_VALUE_TYPE.POSITIVE,
    DIFF_VALUE_TYPE.NEUTRAL,
    DIFF_VALUE_TYPE.NEGATIVE,
    DIFF_VALUE_TYPE.INFO
  ])
};

export default DiffValue;
