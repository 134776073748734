import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HoursIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" />
    </>
  </SvgIcon>
));

export default HoursIcon;
