import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MoneyValue } from '~/modules/common/components/Money';

const useStyles = makeStyles(theme => ({
  profit: {
    color: theme.palette.success.main
  },
  loss: {
    color: theme.palette.error.main
  }
}));

export const ProfitabilityDrilldownProfitValue = ({ money, ...rest }) => {
  const classes = useStyles();

  const className =
    money && money.amount > 0
      ? classes.profit
      : money && money.amount < 0
      ? classes.loss
      : null;

  return <MoneyValue money={money} {...rest} className={className} />;
};

ProfitabilityDrilldownProfitValue.propTypes = {
  money: PropTypes.object
};

export default ProfitabilityDrilldownProfitValue;
