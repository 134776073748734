import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { get } from 'lodash';

export const CUSTOM_PROJECT_STATUS_NAMES_QUERY = gql`
  query GetProjectStatusNames {
    customProjectStatusNames {
      uri
      slug
      text
    }
  }
`;

export const useCustomProjectStatusNamesQuery = options => {
  const { data, loading } = useQuery(
    CUSTOM_PROJECT_STATUS_NAMES_QUERY,
    options
  );

  const customProjectStatusNames = get(data, 'customProjectStatusNames', []);

  const mapCustomStatusNames = status => {
    if (status.length === 0) {
      return null;
    }

    return status.reduce((acc, mapping) => {
      acc[mapping.slug] = mapping.text;
      acc[mapping.slug.toUpperCase()] = mapping.text;
      acc[mapping.uri] = mapping.text;

      return acc;
    }, {});
  };

  return {
    isProjectCustomStatusNamesLoading: loading,
    customStatusMapping: mapCustomStatusNames(customProjectStatusNames)
  };
};
