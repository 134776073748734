import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import ResponsiveTableColumn from './ResponsiveTableColumn';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    color: theme.palette.table.header
  },
  text: {
    color: 'inherit',
    lineHeight: 1.15
  }
});

export const ResponsiveTableHeaderColumn = ({
  classes,
  children,
  size = 'md',
  className,
  ...rest
}) => (
  <ResponsiveTableColumn
    size={size}
    className={classNames(classes.root, className)}
    {...rest}
  >
    <Typography variant="caption" className={classes.text}>
      {children}
    </Typography>
  </ResponsiveTableColumn>
);

ResponsiveTableHeaderColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  className: PropTypes.string
};

export default withStyles(styles)(ResponsiveTableHeaderColumn);
