import { useRef } from 'react';
import { useSubscription } from '@apollo/client';
import { debounce, scope } from '~/modules/common/debounce';

import { ResourceRequestStatus } from '~/types';
import {
  resourceRequestDeletedSubscription,
  resourceRequestSubmittedSubscription,
  resourceRequestUnsubmittedSubscription
} from '../../graphql';

export const useResourcePlanSummaryCountsSubscription = ({ refetch }) => {
  useRefetchOnDeleteSubscription({ refetch });
  useRefetchOnSubmitSubscription({ refetch });
  useRefetchOnUnsubmittedSubscription({ refetch });
};

const DEBOUNCE_INTERVAL = 5000;

export const useRefetchOnDeleteSubscription = ({ refetch }) => {
  const debounceScope = useRef(scope());
  const debouncedRefetch = ({ subscriptionData }) => {
    const {
      data: { resourceRequestDeleted }
    } = subscriptionData || {};

    if (!resourceRequestDeleted) {
      return;
    }
    const { requestStatus } = resourceRequestDeleted;

    if (requestStatus === ResourceRequestStatus.Submitted) {
      debounce(debounceScope.current, refetch, DEBOUNCE_INTERVAL);
    }
  };

  useSubscription(resourceRequestDeletedSubscription, {
    onSubscriptionData: debouncedRefetch
  });
};

export const useRefetchOnSubmitSubscription = ({ refetch }) => {
  const debounceScope = useRef(scope());
  const debouncedRefetch = () =>
    debounce(debounceScope.current, refetch, DEBOUNCE_INTERVAL);

  useSubscription(resourceRequestSubmittedSubscription, {
    onSubscriptionData: debouncedRefetch
  });
};

export const useRefetchOnUnsubmittedSubscription = ({ refetch }) => {
  const debounceScope = useRef(scope());
  const debouncedRefetch = () =>
    debounce(debounceScope.current, refetch, DEBOUNCE_INTERVAL);

  useSubscription(resourceRequestUnsubmittedSubscription, {
    onSubscriptionData: debouncedRefetch
  });
};

export default useResourcePlanSummaryCountsSubscription;
