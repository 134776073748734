import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  container: {
    padding: 0
  }
}));

export const AvatarLoading = ({ size = 80 }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Skeleton variant="circle" width={size} height={size} />
    </div>
  );
};

AvatarLoading.propTypes = {
  size: PropTypes.number
};

export default AvatarLoading;
