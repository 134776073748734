import { computeTableColumns } from '~/modules/common/components/ListTable';
import CustomFieldColumn from './enhancers/renders/CustomField';

export const useResourceUserListTableConfig = ({
  tagFieldUris = [],
  renders = {},
  columnSpec,
  enabledFields = []
}) => {
  const combinedRenders = tagFieldUris.reduce(
    (o, i) => ({
      ...o,
      [i]: renders.tagValue
    }),
    renders
  );
  const { headers, columns, footers } = computeTableColumns({
    columnSpec,
    renders: combinedRenders,
    defaultRender: CustomFieldColumn
  })({
    enabledFields
  });

  return { headers, columns, footers };
};
export default useResourceUserListTableConfig;
