import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import EntityName from '~/modules/common/components/entities/common/EntityName';
import { GroupFullPath } from '~/modules/common/components';
import { ResourceUserAvailabilityGroupType } from '~/types';

const useStyles = makeStyles(theme => ({
  button: {
    overflow: 'hidden',
    color: theme.palette.text.primary,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'start'
  },
  name: {
    fontSize: theme.typography.caption.fontSize
  },
  noValue: {
    paddingLeft: theme.spacing(0),
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  }
}));

const useGroupStyles = makeStyles(() => ({
  root: {
    display: 'block',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    textAlign: 'start'
  },
  pathContainer: {
    display: 'inline'
  }
}));

export const GroupField = ({ record, groupBy, onClick }) => {
  const classes = useStyles();
  const field = record[groupBy];
  const intl = useIntl();

  const group = useMemo(
    () =>
      field
        ? {
            fullPath: field.fullPath,
            displayText: field.displayText,
            uri: field.uri
          }
        : null,
    [field]
  );

  const handleOnClick = useCallback(
    () =>
      onClick({
        [groupBy]: (group && group.uri) || NONE_OPTION_KEY,
        label:
          (group && group.displayText) || intl.formatMessage({ id: 'NONE' })
      }),
    [onClick, groupBy, group, intl]
  );

  const noValueText = !group && (
    <span className={classes.noValue}>
      {groupBy === ResourceUserAvailabilityGroupType.Location && (
        <FormattedMessage id="resourceAvailabilityCard.noLocation" />
      )}
      {groupBy === ResourceUserAvailabilityGroupType.Division && (
        <FormattedMessage id="resourceAvailabilityCard.noDivision" />
      )}
      {groupBy === ResourceUserAvailabilityGroupType.ServiceCenter && (
        <FormattedMessage id="resourceAvailabilityCard.noServiceCenter" />
      )}
      {groupBy === ResourceUserAvailabilityGroupType.CostCenter && (
        <FormattedMessage id="resourceAvailabilityCard.noCostCenter" />
      )}
      {groupBy === ResourceUserAvailabilityGroupType.Department && (
        <FormattedMessage id="resourceAvailabilityCard.noDepartment" />
      )}
      {groupBy === ResourceUserAvailabilityGroupType.EmployeeType && (
        <FormattedMessage id="resourceAvailabilityCard.noEmployeeType" />
      )}
    </span>
  );

  const fullPathClasses = useGroupStyles();

  return (
    <ButtonBase className={classes.button} onClick={handleOnClick}>
      {group && group.fullPath && (
        <GroupFullPath classes={fullPathClasses} fullPath={group.fullPath} />
      )}
      {group ? (
        <EntityName className={classes.name} name={group.displayText} />
      ) : (
        noValueText
      )}
    </ButtonBase>
  );
};

GroupField.propTypes = {
  groupBy: PropTypes.oneOf([
    ResourceUserAvailabilityGroupType.Department,
    ResourceUserAvailabilityGroupType.Division,
    ResourceUserAvailabilityGroupType.ServiceCenter,
    ResourceUserAvailabilityGroupType.CostCenter,
    ResourceUserAvailabilityGroupType.EmployeeType,
    ResourceUserAvailabilityGroupType.Location
  ]),
  record: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default GroupField;
