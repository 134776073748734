import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const INTITIAL_REVENUE_PERIOD_START_QUERY = gql`
  query initialRevenuePeriodStartDate {
    initialRevenuePeriodStartDate
  }
`;

export const useInitialRevenuePeriodStartDate = () => {
  const { data: { initialRevenuePeriodStartDate } = {}, loading } = useQuery(
    INTITIAL_REVENUE_PERIOD_START_QUERY
  );

  return {
    initialRevenuePeriodStartDate,
    loading
  };
};

export default useInitialRevenuePeriodStartDate;
