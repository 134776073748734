import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';

export const creditMemoLineItemsQuery = gql`
  query getCreditMemoLineItems($page: Int!, $pageSize: Int!, $id: String!) {
    getCreditMemo(id: $id) {
      id
      lineItems(page: $page, pageSize: $pageSize) {
        lineItems {
          id
          amount {
            amount
            currency {
              id: uri
              symbol
              displayText
            }
          }
          project {
            id
            displayText
          }
        }
      }
    }
  }
`;

export const updateQueryOnFetchMoreResult = (
  prev = { getCreditMemo: { lineItems: { lineItems: [] } } },
  {
    fetchMoreResult = {
      getCreditMemo: { lineItems: { lineItems: [] } }
    }
  }
) => {
  if (!fetchMoreResult) return prev;

  return {
    getCreditMemo: {
      ...prev.getCreditMemo,
      lineItems: {
        ...prev.getCreditMemo.lineItems,
        lineItems: [
          ...prev.getCreditMemo.lineItems.lineItems,
          ...fetchMoreResult.getCreditMemo.lineItems.lineItems
        ]
      }
    }
  };
};

export const PAGE_SIZE = 50;

export const useCreditMemoLineItems = creditMemoId => {
  const [loadingMore, setLoadingMore] = useState(false);

  const { data, loading, refetch, fetchMore } = useQuery(
    creditMemoLineItemsQuery,
    {
      variables: {
        page: 1,
        pageSize: PAGE_SIZE,
        id: creditMemoId
      },
      skip: !creditMemoId,
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const lineItems = get(data, 'getCreditMemo.lineItems.lineItems', []);

  const hasMore = lineItems.length && lineItems.length % PAGE_SIZE === 0;

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    setLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          id: creditMemoId,
          page: lineItems.length / PAGE_SIZE + 1,
          pageSize: PAGE_SIZE
        },
        updateQuery: updateQueryOnFetchMoreResult
      });
    } finally {
      setLoadingMore(false);
    }
  }, [creditMemoId, fetchMore, setLoadingMore, hasMore, lineItems]);

  return {
    loading,
    refetch,
    lineItems,
    hasMore,
    loadMore,
    loadingMore
  };
};

export default useCreditMemoLineItems;
