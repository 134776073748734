import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { BATCH_STATUS_QUERY } from '~/modules/common/hooks/useBatchStatus';

export const batchStatusEnums = {
  SUCCEEDED: 'SUCCEEDED',
  IN_PROGRESS: 'IN_PROGRESS'
};

const GET_EXPORT_URI = gql`
  query getExportUri(
    $tableKey: String!
    $filter: ExportTableFilter
    $activeColumns: [String]!
    $dateRange: DateRangeInput
    $periodResolution: PeriodResolutionOption
    $billingFilter: BillingFilter
    $filename: String
    $activeColumnNameOverrides: [ColumnNameOverrides]
  ) {
    getExportUri(
      tableKey: $tableKey
      filter: $filter
      dateRange: $dateRange
      periodResolution: $periodResolution
      activeColumns: $activeColumns
      billingFilter: $billingFilter
      filename: $filename
      activeColumnNameOverrides: $activeColumnNameOverrides
    ) {
      uri
    }
  }
`;

const GET_DOWNLOAD_LINK = gql`
  query getDownloadUrl($tableKey: String!, $exportUri: String!) {
    getDownloadUrl(tableKey: $tableKey, exportUri: $exportUri) {
      url
    }
  }
`;

export const useGetExportUri = ({
  tableKey,
  exportFilter: filter,
  activeColumns,
  dateRange,
  billingFilter,
  periodResolution,
  filename,
  activeColumnNameOverrides
}) => {
  const { data, loading, error } = useQuery(GET_EXPORT_URI, {
    variables: {
      tableKey,
      filter,
      activeColumns,
      periodResolution,
      dateRange,
      billingFilter,
      filename,
      activeColumnNameOverrides
    },
    fetchPolicy: 'network-only'
  });

  return {
    data: data && data.getExportUri.uri,
    loading,
    error
  };
};

export const useGetBatchStatus = ({ batchId }) => {
  const { data, loading, error } = useQuery(BATCH_STATUS_QUERY, {
    variables: { batchId },
    fetchPolicy: 'network-only',
    pollInterval: 500,
    skip: !batchId
  });

  return {
    data: data && data.getBatchStatus.executionState,
    loading,
    error
  };
};

export const useGetDownloadUrl = ({ tableKey, exportUri, batchStatus }) => {
  const { data, loading, error } = useQuery(GET_DOWNLOAD_LINK, {
    variables: { tableKey, exportUri },
    skip: batchStatus !== batchStatusEnums.SUCCEEDED,
    fetchPolicy: 'network-only'
  });

  return {
    data: data && data.getDownloadUrl.url,
    loading,
    error
  };
};

export const ExportStatus = ({
  onExportComplete,
  onExportFail,
  tableKey,
  children,
  exportFilter,
  dateRange,
  billingFilter,
  periodResolution,
  activeColumns,
  filename,
  activeColumnNameOverrides
}) => {
  const { data: exportUri } = useGetExportUri({
    tableKey,
    exportFilter,
    dateRange,
    periodResolution,
    activeColumns,
    billingFilter,
    filename,
    activeColumnNameOverrides
  });

  const { data: batchStatus, loading: batchStatusLoading } = useGetBatchStatus({
    batchId: exportUri
  });

  const { data: downloadUrl, loading: downloadUrlLoading } = useGetDownloadUrl({
    tableKey,
    exportUri,
    batchStatus
  });

  useEffect(() => {
    if (
      downloadUrl &&
      !downloadUrlLoading &&
      batchStatus === batchStatusEnums.SUCCEEDED
    ) {
      window.open(downloadUrl, '_blank');
      onExportComplete();
    }

    if (
      batchStatus &&
      !batchStatusLoading &&
      batchStatus !== batchStatusEnums.SUCCEEDED &&
      batchStatus !== batchStatusEnums.IN_PROGRESS &&
      !downloadUrlLoading
    ) {
      onExportFail();
    }
  }, [
    downloadUrl,
    downloadUrlLoading,
    batchStatusLoading,
    batchStatus,
    onExportComplete,
    onExportFail
  ]);

  return children;
};
