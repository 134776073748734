import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 0, 1, 1),
    flexDirection: 'column',
    overflow: 'hidden'
  },
  title: {
    display: 'flex',
    flexDirection: 'row'
  },
  roleName: {
    display: 'block',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  rate: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize
  },
  datesDiffView: {
    color: theme.palette.text.secondary
  },
  endDate: {
    color: theme.palette.text.secondary
  },
  startDate: {
    color: theme.palette.text.secondary
  }
}));

export const useRequestAllocatedDatesStyles = makeStyles(theme => ({
  datesDiffView: {
    color: theme.palette.text.secondary
  },
  end: {
    color: theme.palette.text.secondary
  },
  start: {
    color: theme.palette.text.secondary
  }
}));

export default useStyles;
