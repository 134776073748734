import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import ResourceRequestCardTotal from '../ResourceRequestCardTotal';

export const getAllocatedCostForRequestCurrency = (
  resourceAllocatedCost,
  currencyUri
) =>
  resourceAllocatedCost.find(ra => ra.currencyUri === currencyUri) ||
  resourceAllocatedCost[0];

export const ResourceRequestCardCost = ({
  resourceRequest: { role },
  resourceRequestCost,
  resourceAllocatedCost = [],
  deltaCost,
  showAllocationTotals
}) => {
  const intl = useIntl();
  const {
    amount: resourceRequestAmount,
    currencySymbol: resourceRequestCurrencySymbol,
    currencyUri
  } = resourceRequestCost;
  const label = showAllocationTotals
    ? intl.formatMessage({
        id: 'mobileResourcePlanning.totalAllocatedCost'
      })
    : intl.formatMessage({
        id: 'mobileResourcePlanning.totalRequestedCost'
      });
  const allocatedCost = getAllocatedCostForRequestCurrency(
    resourceAllocatedCost,
    currencyUri
  );

  return (
    <ResourceRequestCardTotal
      label={label}
      showResourceRequestedTotals={!!role}
      showAllocationTotals={showAllocationTotals}
      resourceRequestTotal={resourceRequestAmount}
      resourceAllocatedTotal={allocatedCost && allocatedCost.amount}
      resourceRequestPrefix={resourceRequestCurrencySymbol}
      resourceAllocatedPrefix={allocatedCost && allocatedCost.currencySymbol}
      deltaTotal={deltaCost.amount}
      deltaPrefix={deltaCost.currencySymbol}
    />
  );
};

ResourceRequestCardCost.propTypes = {
  resourceRequest: PropTypes.object,
  resourceRequestCost: PropTypes.object,
  resourceAllocatedCost: PropTypes.array,
  showAllocationTotals: PropTypes.bool,
  deltaCost: PropTypes.object
};

export default ResourceRequestCardCost;
