import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import ResourceAllocationCardTotal from '../ResourceAllocationCardTotal';

export const ResourceAllocationCardCost = ({
  resourceAllocatedCost,
  currencySymbol
}) => {
  const intl = useIntl();

  return (
    <ResourceAllocationCardTotal
      label={intl.formatMessage({
        id: 'mobileResourcePlanning.totalAllocatedCost'
      })}
      resourceAllocatedTotal={
        resourceAllocatedCost && resourceAllocatedCost.amount
      }
      resourceAllocatedPrefix={
        resourceAllocatedCost && resourceAllocatedCost.currencySymbol
      }
    />
  );
};

ResourceAllocationCardCost.propTypes = {
  resourceAllocatedCost: PropTypes.object,
  currencySymbol: PropTypes.string
};

export default ResourceAllocationCardCost;
