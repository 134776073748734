import { useEffect } from 'react';
import { usePrevious } from '~/modules/common/hooks';

const useEstimatedDateChangeHandler = ({
  down,
  onValueChange,
  estimatedDate
}) => {
  const prevDown = usePrevious(down);
  const prevEstimatedDate = usePrevious(estimatedDate);

  useEffect(() => {
    if (prevDown && !down && onValueChange) {
      onValueChange(prevEstimatedDate);
    }
  }, [onValueChange, down, prevDown, prevEstimatedDate]);
};

export default useEstimatedDateChangeHandler;
