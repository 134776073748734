import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  resourceEstimatesSummary: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'end',
    paddingLeft: theme.spacing(1)
  }
});

const ResourceEstimatesSummary = ({
  classes: classesOverride,
  resourceEstimatesSummary,
  intl
}) => {
  const { resourceEstimatesWithUsersCount, resourceEstimatesCount } =
    resourceEstimatesSummary || {};

  const classes = useStyles({ classes: classesOverride });

  return resourceEstimatesCount ? (
    <span className={classes.resourceEstimatesSummary}>
      {intl.formatMessage(
        {
          id:
            resourceEstimatesWithUsersCount === resourceEstimatesCount
              ? 'projectTasksPage.allResourcesCount'
              : 'projectTasksPage.partialResourcesCount'
        },
        {
          resourceEstimatesWithUsersCount,
          resourceEstimatesCount
        }
      )}
    </span>
  ) : null;
};

ResourceEstimatesSummary.propTypes = {
  classes: PropTypes.object,
  resourceEstimatesSummary: PropTypes.object,
  intl: PropTypes.object
};

export default ResourceEstimatesSummary;
