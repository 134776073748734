const AMOUNT_MAX = 999999999999;

const getAmountValidationError = ({ intl, amount }) => {
  if (amount > AMOUNT_MAX) {
    return intl.formatMessage(
      {
        id: 'scriptParamEditor.validations.maxAmount'
      },
      { maxAmount: AMOUNT_MAX }
    );
  }

  return null;
};

const notAboveZeroValidationError = ({ intl, amount }) => {
  if (amount <= 0) {
    return intl.formatMessage({
      id: 'scriptParamEditor.validations.aboveZeroAmount'
    });
  }

  return null;
};

export const customValidtorOnKey = {
  'urn:replicon:script-key:parameter:amount': (value, intl) =>
    getAmountValidationError({
      intl,
      amount: value['urn:replicon:script-key:parameter:amount'].amount
    }),

  'urn:replicon:script-key:parameter:milestone-amount': (value, intl) =>
    getAmountValidationError({
      intl,
      amount: value['urn:replicon:script-key:parameter:milestone-amount'].amount
    }),

  'urn:replicon:script-key:parameter:total-contract-value': (
    value,
    intl,
    featureFlags
  ) =>
    getAmountValidationError({
      intl,
      amount:
        value['urn:replicon:script-key:parameter:total-contract-value'].amount
    }) ||
    notAboveZeroValidationError({
      intl,
      amount:
        value['urn:replicon:script-key:parameter:total-contract-value'].amount
    })
};

export const duplicateValidator = {
  'urn:replicon:script-key:parameter:task': (fieldValues, value, key) =>
    fieldValues.filter(x => value[key].id && x.id === value[key].id).length > 1
};
