import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 0
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  },
  secondaryLabel: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  }
}));

const ColumnGroupOption = memo(
  ({ label, checked, columnId, disabled, onClick, secondaryLabel }) => {
    const {
      secondaryLabel: secondaryLabelClass,
      ...standardClasses
    } = useStyles();
    const onCheckboxClick = () => onClick(columnId);

    return (
      <FormControlLabel
        classes={standardClasses}
        control={
          <Checkbox
            color="primary"
            checked={checked}
            onClick={onCheckboxClick}
            disabled={disabled}
            data-qe-id={columnId}
          />
        }
        label={
          secondaryLabel ? (
            <>
              <Typography
                data-testid="main-label"
                className={standardClasses.label}
              >
                {label}
              </Typography>
              <Typography
                data-testid="secondary-label"
                className={secondaryLabelClass}
              >
                ({secondaryLabel})
              </Typography>
            </>
          ) : (
            label
          )
        }
      />
    );
  }
);

ColumnGroupOption.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  secondaryLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  checked: PropTypes.bool.isRequired,
  columnId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default ColumnGroupOption;
