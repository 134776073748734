import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearSharp';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0,
    bottom: 0,
    zIndex: 11,
    backgroundColor: theme.palette.grey.A700,
    color: theme.palette.getContrastText(theme.palette.grey.A700),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0, 2, 0, 0),
    flex: '0 0 auto'
  },
  clearButton: {
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing(2.25) / 2,
    color: 'inherit'
  },
  selection: {
    ...theme.typography.subtitle2,
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing(0, 2, 0, 0)
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  status: { paddingLeft: theme.spacing(1) }
}));

const SelectionBar = ({ count, onClear, onRemove, onStatusChange }) => {
  const classes = useStyles();
  const values = useMemo(() => ({ count }), [count]);

  if (!count) return null;

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.clearButton}
        onClick={onClear}
        data-qe-id="selectionBar-ClearButton"
      >
        <ClearIcon />
      </IconButton>
      <span className={classes.selection}>
        <FormattedMessage
          id="selectionBar.selected"
          values={values}
          data-qe-id="selectionBar-SelectionCount"
        />
      </span>
      <div className={classes.spacer} />
      <Button
        variant="contained"
        onClick={onRemove}
        data-qe-id="selectionBar-RemoveButton"
      >
        <FormattedMessage id="selectionBar.remove" />
      </Button>
      <div className={classes.status} />
      <Button
        variant="contained"
        onClick={onStatusChange}
        color="primary"
        data-qe-id="selectionBar-StatusButton"
      >
        <FormattedMessage id="selectionBar.status" />
      </Button>
    </div>
  );
};

SelectionBar.propTypes = {
  count: PropTypes.number.isRequired,
  onClear: PropTypes.func,
  onRemove: PropTypes.func,
  onStatusChange: PropTypes.func
};

export default SelectionBar;
