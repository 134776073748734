import { useState } from 'react';

const initialState = ({ searchCriteria }) =>
  (searchCriteria.keywords || []).join(' ');

const stringFromEvent = event =>
  (event && event.target ? event.target.value : event) || '';

export const useGenericSearchInputHandler = ({ searchCriteria }) => {
  const [genericSearchValue, setGenericSearchValue] = useState(
    initialState({ searchCriteria })
  );

  return {
    genericSearchValue,
    handleGenericSearchValueChange: event => {
      const searchValue = stringFromEvent(event);

      setGenericSearchValue(searchValue);
    }
  };
};

export default useGenericSearchInputHandler;
