import PropTypes from 'prop-types';
import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { ResourcePlanAllocationTotal } from '~/modules/resourcing/components';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    padding: theme.spacing(0.5, 0, 0, 1.25)
  },
  hourOnlyPaddingTop: {
    paddingTop: theme.spacing(1.25)
  }
}));

const TotalsFooterPopoverCell = ({
  handleClick,
  handleKeyDown,
  onClickAway,
  allocationsTotals,
  resourceRequestsTotals,
  loading,
  canViewCostData,
  children
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <div
        className={classNames(classes.skeletonContainer, {
          [classes.hourOnlyPaddingTop]: !canViewCostData
        })}
      >
        <Skeleton variant="text" width={64} height={16} />
        {canViewCostData && <Skeleton variant="text" width={80} height={12} />}
      </div>
    );
  }
  if (!allocationsTotals && !resourceRequestsTotals) {
    return null;
  }

  const { totalCost: totalAllocatedCosts, totalHours: totalAllocatedHours } =
    allocationsTotals || {};

  const { totalCost: totalRequestedCosts, totalHours: totalRequestedHours } =
    resourceRequestsTotals || {};

  const totalCostsAmount =
    (totalAllocatedCosts?.amount || 0) + (totalRequestedCosts?.amount || 0);

  const totalCosts =
    totalAllocatedCosts || totalRequestedCosts
      ? [
          {
            currencySymbol:
              totalAllocatedCosts?.currency?.symbol ||
              totalRequestedCosts?.currency?.symbol,
            amount: totalCostsAmount >= 0 ? totalCostsAmount : 0
          }
        ]
      : null;

  const totalHours = parseFloat(
    ((totalAllocatedHours || 0) + (totalRequestedHours || 0)).toFixed(2)
  );

  return (
    (totalCosts?.[0]?.amount || totalHours) && (
      <>
        <ClickAwayListener onClickAway={onClickAway}>
          <div
            onClick={handleClick}
            className={classes.root}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            <ResourcePlanAllocationTotal
              qeIdTag="Total"
              totalHours={totalHours}
              totalCosts={totalCosts}
              hideCosts={!canViewCostData}
            />
          </div>
        </ClickAwayListener>

        {children}
      </>
    )
  );
};

TotalsFooterPopoverCell.propTypes = {
  children: PropTypes.node,
  handleClick: PropTypes.func,
  handleKeyDown: PropTypes.func,
  onClickAway: PropTypes.func,
  allocationsTotals: PropTypes.object,
  resourceRequestsTotals: PropTypes.object,
  loading: PropTypes.bool,
  canViewCostData: PropTypes.bool
};

export default TotalsFooterPopoverCell;
