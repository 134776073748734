import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles
} from '@material-ui/core';
import { LoadingButton, CancelButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  confirmationMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  container: {
    fontSize: '1rem'
  },
  thisWillCopy: {
    marginBottom: theme.spacing(0.2)
  },
  copyingRates: {
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(15.5),
    marginRight: theme.spacing(25)
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    maxWidth: '400px'
  }
}));

const resourceKeys = {
  header: 'rateCard.copyFromClientConfirmationDialog.copyFromClient',
  actionButton: 'rateCard.copyFromClientConfirmationDialog.ok',
  cancelButton: 'rateCard.copyFromClientConfirmationDialog.cancel',
  confirmationMessage:
    'rateCard.copyFromClientConfirmationDialog.confirmationMessage',
  thisWillCopy: 'rateCard.copyFromClientConfirmationDialog.thisWillCopy',
  projectRates: 'rateCard.copyFromClientConfirmationDialog.projectRates',
  roleRates: 'rateCard.copyFromClientConfirmationDialog.roleRates',
  resourceRates: 'rateCard.copyFromClientConfirmationDialog.resourceRates'
};

export const RateCopyConfirmationDialog = ({
  openDialog,
  onDialogClose,
  onConfirmClick,
  defaultRates,
  copyClientRateInProgress
}) => {
  const classes = useStyles();

  const { projectRatesCount, roleRatesCount, resourceRatesCount } = useMemo(
    () => ({
      projectRatesCount: (defaultRates.projectRate || []).length,
      roleRatesCount: (defaultRates.roleRates || []).length,
      resourceRatesCount: (defaultRates.userRates || []).length
    }),
    [defaultRates.projectRate, defaultRates.roleRates, defaultRates.userRates]
  );

  return (
    <>
      <Dialog
        open={openDialog}
        classes={useDialogStyles()}
        onClose={onDialogClose}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth={false}
        aria-labelledby="rate-copy-information"
        aria-describedby="rate-copy-information"
      >
        {copyClientRateInProgress ? (
          <>
            <DialogTitle>
              <FormattedMessage id={resourceKeys.header} />
            </DialogTitle>
            <DialogContent className={classes.container}>
              <Grid item xs={12} className={classes.copyingRates}>
                <FormattedMessage id="rateCard.copyingFromClient" />
              </Grid>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>
              <FormattedMessage id={resourceKeys.header} />
            </DialogTitle>
            <DialogContent className={classes.container}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <FormattedMessage id={resourceKeys.thisWillCopy} />
                </Grid>
                <Grid item xs={12}>
                  <strong>{`${projectRatesCount} `}</strong>
                  <FormattedMessage id={resourceKeys.projectRates} />
                </Grid>
                <Grid item xs={12}>
                  <strong>{`${roleRatesCount} `}</strong>
                  <FormattedMessage id={resourceKeys.roleRates} />
                </Grid>
                <Grid item xs={12}>
                  <strong>{`${resourceRatesCount} `}</strong>
                  <FormattedMessage id={resourceKeys.resourceRates} />
                </Grid>
                <Grid item xs={12} className={classes.confirmationMessage}>
                  <FormattedMessage id={resourceKeys.confirmationMessage} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onDialogClose}>
                <FormattedMessage id={resourceKeys.cancelButton} />
              </CancelButton>
              <LoadingButton color="primary" onClick={onConfirmClick}>
                <FormattedMessage id={resourceKeys.actionButton} />
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

RateCopyConfirmationDialog.propTypes = {
  openDialog: PropTypes.bool,
  onDialogClose: PropTypes.func,
  onConfirmClick: PropTypes.func,
  defaultRates: PropTypes.object,
  copyClientRateInProgress: PropTypes.bool
};

export default RateCopyConfirmationDialog;
