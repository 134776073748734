import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import { AddIconButton } from '~/modules/common/components';

const styles = theme => ({
  button: {
    marginTop: theme.spacing(2)
  }
});

const NewAttachment = ({ classes, onClick, disabled, hasAttachment }) => {
  const addButtonClasses = useMemo(() => ({ root: classes.button }), [
    classes.button
  ]);

  return (
    <AddIconButton
      onClick={onClick}
      disabled={disabled}
      data-qe-id="addFileOrUrl"
      classes={hasAttachment ? null : addButtonClasses}
    >
      <FormattedMessage id="attachments.addFileOrUrl" />
    </AddIconButton>
  );
};

NewAttachment.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasAttachment: PropTypes.bool,
  classes: PropTypes.object
};

export default withStyles(styles)(NewAttachment);
