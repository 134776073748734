import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import AvailabilityPeriod from '~/modules/resourcing/common/components/AvailabilityPeriod';
import { convertToComparableValue } from './util';
import { useTaskAllocationOnChangeHandlers } from './useTaskAllocationOnChangeHandlers';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption
  },
  hoursField: ({ targetWidth }) => ({
    width: Math.max(targetWidth || 0, theme.spacing(4.5))
  }),
  hoursFieldInput: ({ targetHeight }) => ({
    textAlign: 'right',
    padding: theme.spacing(0.5, 0),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    height: Math.max(targetHeight || 0, theme.spacing(2))
  }),
  inputAdornment: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 0.5)
  },

  details: {
    display: 'flex',
    padding: theme.spacing(0.25, 0.25),
    textAlign: 'right',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary
  },
  overAllocated: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  },
  value: {
    marginLeft: theme.spacing(1)
  }
}));

const highlightTarget = event => event.target.select();

export const TaskAllocationEditor = ({
  classes: classesOverrides,
  allocatedHours: initialAllocatedHours,
  availableHours,
  isAvailabilityLoading,
  scheduledHours = 0,
  targetHeight,
  targetWidth,
  handlePeriodUpdate,
  onAllocationChange,
  handlePreviousPeriod,
  handleNextPeriod
}) => {
  const classes = useStyles({
    targetWidth,
    targetHeight,
    classes: classesOverrides
  });

  const { displayUnit } = useDisplayUnitContext();
  const [allocatedHours, setAllocatedHours] = useState(initialAllocatedHours);

  const isHoursMode =
    displayUnit === DISPLAY_UNIT_ENUM.HOURS || scheduledHours === 0;

  const value = convertToComparableValue({
    isHoursMode,
    hours: allocatedHours,
    scheduledHours
  });

  const inputProps = useMemo(
    () => ({
      classes: {
        input: classes.hoursFieldInput
      },
      disableUnderline: true,
      inputProps: {
        min: 0
      },
      endAdornment: (
        <InputAdornment className={classes.inputAdornment}>
          {isHoursMode ? 'h' : '%'}
        </InputAdornment>
      )
    }),
    [classes.inputAdornment, classes.hoursFieldInput, isHoursMode]
  );

  const availableValue = convertToComparableValue({
    hours: availableHours - allocatedHours,
    isHoursMode,
    scheduledHours
  });

  const {
    handleOnBlur,
    handleHoursChange,
    handleHoursKeyDown,
    handleHoursKeyPress
  } = useTaskAllocationOnChangeHandlers({
    setAllocatedHours,
    isHoursMode,
    scheduledHours,
    initialAllocatedHours,
    onAllocationChange,
    handlePeriodUpdate,
    handleNextPeriod,
    handlePreviousPeriod
  });

  return (
    <div className={classes.root} data-qe-id="popoverInput">
      <TextField
        type="number"
        step="0.01"
        min="0"
        variant="standard"
        value={value}
        onChange={handleHoursChange}
        onBlur={handleOnBlur}
        onKeyPress={handleHoursKeyPress}
        onKeyDown={handleHoursKeyDown}
        autoFocus
        InputProps={inputProps}
        className={classes.hoursField}
        onFocus={highlightTarget}
      />
      <div className={classes.details}>
        <FormattedMessage id="taskAllocationEditor.remaining" />
        {!isAvailabilityLoading ? (
          <AvailabilityPeriod
            availableHours={availableValue}
            isHoursMode={isHoursMode}
            classes={classes}
          />
        ) : (
          <CircularProgress size={16} color="primary" />
        )}
      </div>
    </div>
  );
};

TaskAllocationEditor.propTypes = {
  classes: PropTypes.object,
  allocatedHours: PropTypes.number,
  availableHours: PropTypes.number,
  isAvailabilityLoading: PropTypes.bool,
  scheduledHours: PropTypes.number,
  targetHeight: PropTypes.number,
  targetWidth: PropTypes.number,
  handlePeriodUpdate: PropTypes.func,
  onAllocationChange: PropTypes.func,
  handlePreviousPeriod: PropTypes.func,
  handleNextPeriod: PropTypes.func
};
