import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import ClientItem from './ClientItem';
import { useSearchableClients } from './enhancers/useSearchableClients';

const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : option.key === NONE_OPTION_KEY ? (
    <NoneDropdownItem value={option.displayText} />
  ) : (
    <ClientItem value={option.displayText} />
  );

export const ClientFacetDetails = ({ className, selected, setSelected }) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();

  const noneOptionText = formatMessage({
    id: 'client.noClient'
  });

  const { data: options, loading, hasMore } = useSearchableClients({
    searchTerm,
    includeNoneOption: true,
    noneOptionText
  });

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );
  const clientValues = !loading ? options : [];

  return (
    <FacetDetailField
      label={formatMessage({ id: 'search.client' })}
      className={className}
    >
      <SimpleAutocomplete
        loading={loading}
        options={clientValues}
        classes={classes}
        value={selected}
        variant="standard"
        onChange={onValueChange}
        multiple
        fullWidth
        hiddenLabel
        noOptionsText={noneOptionText}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        inputLabel={formatMessage({
          id: 'clientFacet.searchDropdownAriaLabel'
        })}
        onInputChange={onInputChange}
        optionPropText="displayText"
        hasMore={hasMore}
        optionTypeText={formatMessage({ id: 'moreOptions.clients' })}
      />
    </FacetDetailField>
  );
};

ClientFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ClientFacetDetails;
