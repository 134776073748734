import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const supportLink = {
  support: (
    <a href="https://www.replicon.com/polaris-psa/contact-support/">
      {' '}
      <FormattedMessage id="commonError.support" />
    </a>
  )
};

export const FormErrorAlert = ({ errorMessage = '' }) => {
  const classes = useStyles();
  const unexpectedError = (errorMessage || '').includes(
    'Unexpected error value'
  );

  const errorText =
    errorMessage && !unexpectedError ? (
      errorMessage
    ) : (
      <FormattedMessage
        id="commonError.formErrorAlertMessage"
        values={supportLink}
      />
    );

  return (
    <div className={classes.root}>
      <Alert icon={false} severity="error">
        {errorText}
      </Alert>
    </div>
  );
};

FormErrorAlert.propTypes = {
  errorMessage: PropTypes.string
};

export default FormErrorAlert;
