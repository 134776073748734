import { gql } from 'graphql-tag';
import { CustomFieldValueFragmentDoc } from '~/types';

export const updateClientFragment = gql`
  fragment UpdateClient on Client {
    id
    name
    slug
    code
    isActive
    comment
    defaultInvoicePaymentTerm
    invoiceTemplate {
      id
      displayText
    }
    invoiceCurrency {
      id
      symbol
    }
    clientManager {
      id
      displayText
    }
    contactName
    contactInfo {
      address
      city
      country {
        id
        displayText
      }
      email
      faxNumber
      phoneNumber
      stateProvince
      website
      zipPostalCode
    }
    billingContactInfo {
      address
      city
      country {
        id
        displayText
      }
      email
      faxNumber
      phoneNumber
      stateProvince
      website
      zipPostalCode
    }
    clientRepresentatives {
      id
      displayText
    }
    comanagers {
      id
      displayText
    }
    customFieldValues {
      ...CustomFieldValue
    }
  }
  ${CustomFieldValueFragmentDoc}
`;
