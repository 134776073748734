import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListTable,
  PAGINATION_TYPE,
  ListTablePropTypes,
  ListTableNavigationContext
} from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';
import renders from '../enhancers/renders';

import { useResourceUserListPagination } from '../enhancers/useResourceUserPaginated';
import { useResourceUserListTableConfig } from '../useResourceUserListTableConfig';
import NoResourcesData from '../NoResourcesData';
import getColumnGroups from './resourceColumns';
import ResoureUserListItem from './ResourceUserListItem';
import useStyles from './useStyles';

const useListTableClasses = makeStyles(() => ({
  card: {
    overflow: 'inherit'
  }
}));

const useContainerClasses = makeStyles(() => ({
  root: {
    minWidth: 'max-content'
  }
}));

const RESOURCE_LIST_PAGE_SIZE = 20;

export const ResourceUserListTable = ({
  currentPage,
  customFieldDefinitions,
  customFieldUris,
  enabledFields,
  isMobile,
  me,
  onSortChange,
  tagFieldUris,
  tagFieldData,
  setCurrentPage,
  settingsLoading,
  sort
}) => {
  const tableClasses = useStyles();
  const listTableClasses = useListTableClasses();
  const containerClasses = useContainerClasses();

  const {
    loadMoreResources,
    hasMoreResources,
    loading,
    error,
    resources
  } = useResourceUserListPagination({
    page: currentPage,
    pagesize: RESOURCE_LIST_PAGE_SIZE,
    tagFieldUris,
    sort,
    customFieldUris,
    enabledFields,
    me,
    skip: settingsLoading
  });

  const resourceListNavigationContext = useMemo(
    () => ({
      navigatePage: setCurrentPage,
      currentPage,
      maximumPageSize: RESOURCE_LIST_PAGE_SIZE,
      disableInfiniteScroll: !isMobile
    }),
    [setCurrentPage, currentPage, isMobile]
  );

  const isLoading = loading || settingsLoading;
  const columnSpec = getColumnGroups({
    customFieldDefinitions,
    tagFieldData,
    me
  });
  const { headers, columns } = useResourceUserListTableConfig({
    tagFieldUris,
    renders,
    enabledFields,
    columnSpec
  });

  const showNoResourcesDataComponent =
    !isLoading && resources?.length === 0 && currentPage === 1;

  if (error) return <RedirectToUnhandledError />;

  return (
    <div className={containerClasses.root}>
      <ListTableThemeProvider classes={tableClasses}>
        {showNoResourcesDataComponent ? (
          <NoResourcesData />
        ) : (
          <ListTableNavigationContext.Provider
            value={resourceListNavigationContext}
          >
            <ListTable
              classes={listTableClasses}
              isLoading={isLoading}
              variant="table"
              dataQeId="ResourceDetailsTable"
              hasMore={hasMoreResources}
              loadMore={loadMoreResources}
              headers={headers}
              columns={columns}
              records={resources}
              mobileListItem={ResoureUserListItem}
              onSortChange={onSortChange}
              sort={sort}
              useInlineStyles={false}
              paginationType={PAGINATION_TYPE.NAVIGATION}
              isMobile={isMobile}
            />
          </ListTableNavigationContext.Provider>
        )}
      </ListTableThemeProvider>
    </div>
  );
};

ResourceUserListTable.propTypes = {
  settingsLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  sort: ListTablePropTypes.sort,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  customFieldDefinitions: PropTypes.array,
  customFieldUris: PropTypes.array,
  enabledFields: PropTypes.array,
  isMobile: PropTypes.bool,
  me: PropTypes.object,
  tagFieldUris: PropTypes.array,
  tagFieldData: PropTypes.array
};

export default ResourceUserListTable;
