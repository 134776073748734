import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const useStyles = makeStyles(theme => ({
  input: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.body1.fontSize,
    paddingTop: '23px',
    paddingBottom: '6px',
    paddingLeft: '12px'
  },
  percentageLabel: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const amountKeyUri = 'urn:replicon:script-key:parameter:milestone-amount';
const totalAmountKeyUri = 'milestone-total-amount';
const totalPercentageKeyUri = 'milestone-total-percentage';

export const MilestonePercentageEditor = ({
  column: {
    id: columnId,
    setFieldValue,
    hasMaxVal = false,
    hasHeaderComponent,
    headerComponentProps: { contractAmount },
    footerProps,
    errors,
    label,
    isMobile
  },
  field,
  record,
  index,
  classes: classesOverride
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const percentage = useMemo(() => record[field] || 0, [field, record]);
  const inputClasses = useMemo(() => ({ input: classes.input }), [
    classes.input
  ]);

  const onChangeHandler = useCallback(
    e => {
      const newPercentage = getRoundedValue(e.target.value || 0, 2);

      setFieldValue(`scripts[${index}].${columnId}`, newPercentage);
      if (hasHeaderComponent) {
        const updatedAmount = getRoundedValue(
          (contractAmount.amount * newPercentage) / 100,
          2
        );

        setFieldValue(
          `scripts[${index}].${amountKeyUri}.amount`,
          updatedAmount
        );
        if (footerProps) {
          setFieldValue(
            `footerProps.${totalAmountKeyUri}.amount`,
            getRoundedValue(
              footerProps[totalAmountKeyUri].amount -
                record[amountKeyUri].amount +
                updatedAmount,
              2
            ) || 0
          );
        }
      }
      if (footerProps) {
        setFieldValue(
          `footerProps.${totalPercentageKeyUri}.value`,
          getRoundedValue(
            (footerProps[totalPercentageKeyUri].value || 0) -
              percentage +
              newPercentage,
            2
          ) || 0
        );
      }
    },
    [
      columnId,
      contractAmount.amount,
      footerProps,
      hasHeaderComponent,
      index,
      percentage,
      record,
      setFieldValue
    ]
  );

  const maxVal = useMemo(() => (hasMaxVal ? 100 : undefined), [hasMaxVal]);

  const requiredError = useMemo(
    () => get(errors, `parameters['${columnId}'][${index}]`),
    [columnId, errors, index]
  );

  return (
    <TextField
      id="percentage"
      type="number"
      value={percentage}
      onChange={onChangeHandler}
      error={Boolean(requiredError)}
      helperText={requiredError}
      fullWidth={isMobile}
      label={label}
      hiddenLabel={!isMobile}
      InputProps={{
        classes: inputClasses,
        endAdornment: (
          <InputAdornment position="start">
            <span className={classes.percentageLabel}>%</span>
          </InputAdornment>
        ),
        inputProps: {
          min: 0,
          max: maxVal,
          step: 0.01,
          'aria-label': formatMessage({ id: 'billPlan.percentage' })
        }
      }}
    />
  );
};

MilestonePercentageEditor.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  index: PropTypes.number,
  field: PropTypes.string.isRequired
};

export default MilestonePercentageEditor;
