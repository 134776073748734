import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ExpenseIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 11.998046875 2 C 3.099850963168739 2.009987777214974 -1.345527485536803 12.77090512414528 4.94921875 19.05859375 C 11.24396498553681 25.34628237585472 21.99999439461305 20.88843589221643 22 11.990234375 C 22.0033919475743 6.467030784167899 17.52124318526471 1.990412019642456 11.998046875 2 z M 11.216796875 7 L 12.880859375 7 L 12.880859375 8.216796875 A 2.214053145677977 2.214053145677977 0 0 1 14.666015625 10.33203125 L 13.439453125 10.33203125 C 13.4068935199165 9.63777644483247 13.04017312060183 9.166015625 12.05078125 9.166015625 C 11.11180425178553 9.166015625 10.55078125 9.587330596510343 10.55078125 10.193359375 C 10.55078125 10.72166522522582 10.95537475547474 11.0609752044822 12.216796875 11.388671875 C 13.47821899452526 11.71636854551781 14.828125 12.25851961289518 14.828125 13.833984375 C 14.828125 14.97357055292249 13.96568194648672 15.59657865671936 12.8828125 15.806640625 L 12.8828125 17 L 11.216796875 17 L 11.216796875 15.794921875 A 2.327486608549525 2.327486608549525 0 0 1 9.171875 13.666015625 L 10.39453125 13.666015625 C 10.45544922080139 14.32140896603561 10.90594352287049 14.833984375 12.05078125 14.833984375 C 13.27859345460036 14.833984375 13.55078125 14.22320684211217 13.55078125 13.83984375 C 13.55078125 13.32309130802961 13.27132211033505 12.83294821972482 11.8828125 12.5 C 10.33255517408885 12.12714000630186 9.271484375 11.48855675119473 9.271484375 10.205078125 A 2.167839512656236 2.167839512656236 0 0 1 11.216796875 8.201171875 L 11.216796875 7 z " />
    </>
  </SvgIcon>
));

export default ExpenseIcon;
