import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import useResourcePools from '~/modules/common/hooks/useResourcePools';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import ResourcePoolDropdownOption from './ResourcePoolDropdownOption';

export const ResourcePoolFacetDetails = ({
  className,
  selected: selectedResourcePools,
  setSelected: setSelectedResourcePools
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const resourcePoolLabel = formatMessage({
    id: 'resourcePoolSearchBox.resourcePool'
  });

  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const { resourcePools, hasMore, isLoading } = useResourcePools({
    includeNoneOption: false,
    noneOptionText: formatMessage({
      id: 'resourcePoolSearchBox.noResourcePool'
    }),
    searchFilter: { searchTextParam: searchTerm, includeOnlyEnabled: true }
  });

  const renderOption = useCallback(
    option =>
      option.key === formatMessage({ id: 'NONE' }) ? (
        <NoneDropdownItem value={option.displayText} />
      ) : option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <ResourcePoolDropdownOption value={option.label} />
      ),
    [formatMessage]
  );

  const getOptionDisabled = useCallback(
    option =>
      selectedResourcePools.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selectedResourcePools]
  );

  const onValueChange = useCallback(
    selectedOptions =>
      onChange({ selectedOptions, setSelected: setSelectedResourcePools }),
    [setSelectedResourcePools]
  );

  const resourcePoolOptions = (!isLoading && resourcePools) || [];

  return (
    <FacetDetailField label={resourcePoolLabel} className={className}>
      <SimpleAutocomplete
        fullWidth
        classes={classes}
        variant="standard"
        value={selectedResourcePools}
        options={resourcePoolOptions}
        loading={isLoading}
        hasMore={hasMore}
        multiple
        hiddenLabel
        inputLabel={resourcePoolLabel}
        renderOption={renderOption}
        getOptionDisabled={getOptionDisabled}
        onChange={onValueChange}
        label={resourcePoolLabel}
        onInputChange={handleInputChange}
        optionPropText="label"
        optionTypeText={formatMessage({
          id: 'moreOptions.resourcePools'
        })}
        noOptionsText={formatMessage({
          id: 'resourcePoolSearchBox.noResourcePools'
        })}
      />
    </FacetDetailField>
  );
};

ResourcePoolFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ResourcePoolFacetDetails;
