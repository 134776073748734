import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { object, string, mixed, number } from 'yup';
import { ProjectRequestPeriodResolution } from '~/types';
import { isNullOrUndefined } from '~/modules/common/util';
import { useProjectRequestPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import { ESTIMATED_COST_MAX_AMOUNT } from '~/modules/project-request/ProjectRequestPage/hooks/constants';
import { useMeContext } from '~/modules/me/useMeContext';
import { usePutProjectRequest } from './usePutProjectRequest';

export const validationResourceKeys = {
  nameRequired: 'projectRequest.validations.nameRequired',
  nameExceedsMaxLength: 'projectRequest.validations.nameExceedsMaxLength',
  descriptionRequired: 'projectRequest.validations.descriptionRequired',
  requestedByExceedsMaxLength:
    'projectRequest.validations.requestedByExceedsMaxLength'
};

const buildRequiredFieldValidator = ({ message }) => ({
  name: 'isRequired',
  message,
  test: field => Boolean(field && field.id)
});

const buildPortfolioFieldValidator = ({ intl }) => {
  let validator = mixed();
  const message = intl.formatMessage({
    id: 'projectRequest.validations.portfolioIsRequired'
  });

  validator = validator.test(buildRequiredFieldValidator({ message }));

  return validator;
};

export const validationMessage = max =>
  `Exceeds max allowed value: ${max.toLocaleString('en')}`;

export const validationSchema = ({ intl, canViewAllProjectRequest }) => {
  const portfolioFieldValidatorCheck = !canViewAllProjectRequest;

  return object().shape({
    name: string()
      .trim()
      .required(
        intl.formatMessage({
          id: validationResourceKeys.nameRequired
        })
      )
      .max(
        255,
        intl.formatMessage({
          id: validationResourceKeys.nameExceedsMaxLength
        })
      ),
    description: string()
      .trim()
      .required(
        intl.formatMessage({
          id: validationResourceKeys.descriptionRequired
        })
      ),
    requestedBy: string()
      .trim()
      .max(
        255,
        intl.formatMessage({
          id: validationResourceKeys.requestedByExceedsMaxLength
        })
      ),
    portfolio: portfolioFieldValidatorCheck
      ? buildPortfolioFieldValidator({
          intl
        })
      : null,
    estimatedCostOverride: object()
      .shape({
        amount: number().max(
          ESTIMATED_COST_MAX_AMOUNT,
          validationMessage(ESTIMATED_COST_MAX_AMOUNT)
        )
      })
      .nullable()
  });
};

export const initialState = ({
  projectRequestDetails,
  assignedPortfolioId,
  baseCurrency
}) => {
  const {
    id,
    name,
    description,
    requestedBy,
    createdBy,
    status,
    score,
    portfolio,
    permittedActionUris,
    startDate,
    timelineDuration,
    estimatedCostOverride,
    estimatedCost
  } = projectRequestDetails || {};

  const { value, risk, investment, total, overrideTotal } = score || {};

  return {
    id: id || null,
    name: name || '',
    description: description || '',
    requestedBy: requestedBy || '',
    createdBy: createdBy || null,
    status: status || null,
    score: {
      value: value || 0,
      risk: risk || 0,
      investment: investment || 0,
      total: isNullOrUndefined(total) ? null : total,
      overrideTotal: isNullOrUndefined(overrideTotal) ? null : overrideTotal
    },
    portfolio:
      portfolio || (assignedPortfolioId ? { id: assignedPortfolioId } : null),
    permittedActionUris: permittedActionUris || [],
    startDate: startDate || null,
    periodQuantity: timelineDuration?.periodQuantity || 1,
    periodResolution:
      timelineDuration?.periodResolution ||
      ProjectRequestPeriodResolution.Month,
    estimatedCost: estimatedCost || null,
    estimatedCostOverride: estimatedCostOverride || null,
    initialEstimatedCostCurrency: portfolio?.portfolioCurrency || baseCurrency
  };
};

export const useOnSubmit = ({ onClose, setEditable }) => {
  const { putProjectRequest } = usePutProjectRequest();
  const history = useHistory();

  const onSubmit = useCallback(
    async values => {
      const { id, name, description, requestedBy, portfolio } = values;

      const { data } = await putProjectRequest({
        id,
        name,
        description,
        requestedBy,
        portfolioId: portfolio && portfolio.id
      });

      if (!id) {
        history.push({
          hash: `#/request/${data.putProjectRequest.projectRequestUri}`,
          state: {
            isDocumentsExpanded: true
          }
        });
        onClose();
      } else {
        setEditable && setEditable(false);
      }
    },
    [history, onClose, putProjectRequest, setEditable]
  );

  return onSubmit;
};

const useFormState = ({
  onClose,
  projectRequestDetails,
  setEditable,
  assignedPortfolioId
}) => {
  const intl = useIntl();
  const me = useMeContext();
  const { canViewAllProjectRequest } = useProjectRequestPermissions();

  return {
    initialValues: initialState({
      projectRequestDetails,
      assignedPortfolioId,
      baseCurrency: me.baseCurrency
    }),
    validationSchema: validationSchema({
      intl,
      canViewAllProjectRequest
    }),
    onSubmit: useOnSubmit({ onClose, setEditable })
  };
};

export default useFormState;
