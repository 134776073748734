import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import { RESOURCE_REQUESTS_ALLOCATIONS_QUERY as RESOURCING_RESOURCE_REQUESTS_QUERY } from '~/modules/resourcing/hooks/useResourceRequests';
import {
  writeRequestedTotalCostAndTotalHoursToCache,
  invokeUpdateResourceRequestAggregateCache
} from '~/modules/resourcing/enhancers/updateResourceRequestTotalsCache';
import { getCostAndHoursDelta } from '../../util/getCostAndHoursDelta';
import { useGetResourceRequestFilter } from './useGetResourceRequestFilter';

export const DELETE_RESOURCE_REQUEST = gql`
  mutation DeleteResourceRequest($input: DeleteResourceRequestInput!) {
    deleteResourceRequest2(input: $input) {
      id
    }
  }
`;

export const updateCache = ({
  projectUri,
  resourceRequestsQueryVariables,
  isFromDrawer,
  request,
  filter,
  isResourceActualModeEnabled
}) => (proxy, mutationResponse) => {
  const {
    data: {
      isOptimistic,
      deleteResourceRequest2: { id: resourceRequestId }
    }
  } = mutationResponse;

  const cachedResourceRequests = tryLoadCachedResourceRequestsQuery({
    proxy,
    projectUri,
    isOptimistic,
    resourceRequestsQueryVariables,
    isFromDrawer
  });

  if (cachedResourceRequests) {
    const { resourceRequests: data } = cachedResourceRequests;

    const remainingResourceRequests = data.resourceRequests
      .filter(sr => sr.id !== resourceRequestId)
      .map(sr => (sr.project ? sr : { ...sr, project: null }));

    proxy.writeQuery({
      query:
        resourceRequestsQueryVariables &&
        resourceRequestsQueryVariables.requestAccessLevel
          ? RESOURCING_RESOURCE_REQUESTS_QUERY
          : RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
      variables: resourceRequestsQueryVariables || {
        projectUri,
        cursor: null,
        limit: 500
      },
      data: {
        resourceRequests: {
          ...data,
          resourceRequests: remainingResourceRequests
        }
      }
    });
  }

  const costAndHoursDelta = getCostAndHoursDelta({
    initialTotalCost: {
      currency: request.currencyUri,
      amount: request.roleRate * request.totalHours
    },
    initialHours: request.totalHours
  });

  const updatedData = {
    id: request.id,
    totalRequestedCostByCurrency: {
      baseCurrency: {
        amount: 0,
        currency: null,
        __typename: 'MoneyValue'
      },
      projectBudgetCurrency: null,
      __typename: 'ResourceRequestTotalCostByCurrency'
    },
    totalHours: 0
  };

  writeRequestedTotalCostAndTotalHoursToCache({
    proxy,
    request,
    updatedData
  });

  if (costAndHoursDelta && Object.keys(costAndHoursDelta).length > 0) {
    invokeUpdateResourceRequestAggregateCache({
      proxy,
      projectId: request.projectUri,
      costAndHoursDelta,
      filter: filter || {},
      isResourceActualModeEnabled
    });
  }
};

const tryLoadCachedResourceRequestsQuery = ({
  proxy,
  projectUri,
  optimistic,
  resourceRequestsQueryVariables
}) => {
  try {
    return proxy.readQuery(
      {
        query:
          resourceRequestsQueryVariables &&
          resourceRequestsQueryVariables.requestAccessLevel
            ? RESOURCING_RESOURCE_REQUESTS_QUERY
            : RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
        variables: resourceRequestsQueryVariables || {
          projectUri,
          limit: 500,
          cursor: null
        }
      },
      optimistic
    );
  } catch (e) {
    return null;
  }
};

export const useDeleteResourceRequestHandler = ({
  isResourceActualModeEnabled
}) => {
  const [deleteResourceRequest] = useMutation(DELETE_RESOURCE_REQUEST);
  const filter = useGetResourceRequestFilter();

  const deleteResourceRequestFromProject = ({
    projectUri,
    resourceRequestsQueryVariables,
    isFromDrawer,
    request
  }) => {
    deleteResourceRequest({
      variables: {
        input: {
          id: request.id
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        isOptimistic: true,
        deleteResourceRequest2: {
          __typename: 'DeleteResourceRequestResult',
          id: request.id
        }
      },
      update: updateCache({
        projectUri,
        resourceRequestsQueryVariables,
        isFromDrawer,
        request,
        filter,
        isResourceActualModeEnabled
      })
    });
  };

  return { deleteResourceRequestFromProject };
};

export default useDeleteResourceRequestHandler;
