import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: theme.spacing(6),
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2, 0, 2)
  }
}));

export const DrilldownTableLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton width="100%" height={30} />
    </div>
  );
};

export default DrilldownTableLoading;
