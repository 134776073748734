import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  iconContainer: {
    paddingLeft: theme.spacing(0.5)
  },
  text: {
    paddingLeft: theme.spacing(0.25)
  },
  requiredMissing: {
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontStyle: 'italic',
    fontWeight: 'normal',
    textTransform: 'none'
  }
}));
