import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ResourceRequestDetailIndicator from '../ResourceRequestDetailIndicator';
import useStyles, { useRequestIndicatorStyles } from './useStyles';

export const ResourceRequestResourceInfo = ({
  resourceRequest,
  qeIdTag,
  maxSkillLevel
}) => {
  const classes = useStyles();
  const requestIndicatorClasses = useRequestIndicatorStyles();

  return (
    <div className={classes.root}>
      <div className={classes.quantityRow}>
        <span
          className={classes.quantity}
          data-qe-id={
            qeIdTag
              ? `ResourceRequestQuantity_${qeIdTag}`
              : 'ResourceRequestQuantity'
          }
        >
          {resourceRequest.quantity}{' '}
        </span>
        <FormattedMessage id="resourceRequestItem.requested" />
      </div>
      <div className={classes.groupsRow}>
        <ResourceRequestDetailIndicator
          maxSkillLevel={maxSkillLevel}
          resourceRequest={resourceRequest}
          classes={requestIndicatorClasses}
        />
      </div>
    </div>
  );
};

ResourceRequestResourceInfo.propTypes = {
  resourceRequest: PropTypes.object,
  qeIdTag: PropTypes.string,
  maxSkillLevel: PropTypes.number
};

export default ResourceRequestResourceInfo;
