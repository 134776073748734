import React from 'react';
import PropTypes from 'prop-types';

const MainWrapper = ({ children }) => {
  return <main>{children}</main>;
};

MainWrapper.propTypes = {
  children: PropTypes.node
};

export default MainWrapper;
