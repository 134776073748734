import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    textDecoration: 'none'
  },
  nameRow: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));
