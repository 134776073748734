import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { NoValue } from '~/modules/common/components';

const labelIds = {
  name: 'projectBasicInfoCard.contactInfo.fields.name',
  email: 'projectBasicInfoCard.contactInfo.fields.email',
  phoneNumber: 'projectBasicInfoCard.contactInfo.fields.phoneNumber',
  faxNumber: 'projectBasicInfoCard.contactInfo.fields.faxNumber',
  website: 'projectBasicInfoCard.contactInfo.fields.website',
  contactType: 'projectBasicInfoCard.contactInfo.fields.contactType'
};

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.75),
      color: theme.palette.text.primary
    }
  },
  text: {
    textWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.75),
      color: theme.palette.text.primary
    }
  },
  label: {
    color: theme.palette.text.secondary
  }
}));

const ContactFieldReadOnly = ({ record, field, children }) => {
  const isMobile = useIsBreakpointDown('sm');

  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      {isMobile && (
        <Typography variant="caption" className={classes.label}>
          {formatMessage({ id: labelIds[field] })}
        </Typography>
      )}
      <Typography variant="body2" tabIndex={0} className={classes.text}>
        {children || record[field] || <NoValue classes={classes} />}
      </Typography>
    </>
  );
};

ContactFieldReadOnly.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string,
  children: PropTypes.node
};

export default ContactFieldReadOnly;
