import { useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { array, object, string } from 'yup';

export const buildValidationSchema = intl =>
  object().shape({
    gates: array().of(
      object().shape({
        uri: string().isRequired,
        displayText: string()
          .trim()
          .required(
            intl.formatMessage({
              id: 'projectRequest.validations.gateDisplayTextRequired'
            })
          )
      })
    )
  });

export const useOnSubmit = ({ updateGates, setEditableGates }) =>
  useCallback(
    async values => {
      const { gates } = values;

      await updateGates(
        gates.map(gate => ({
          uri: gate.uri,
          displayText: gate.displayText,
          value: gate.value
        }))
      );
      setEditableGates(false);
    },
    [setEditableGates, updateGates]
  );

export const useGatesFormik = ({ gates, updateGates, setEditableGates }) => {
  const onSubmit = useOnSubmit({ updateGates, setEditableGates });
  const intl = useIntl();
  const validationSchema = useMemo(() => buildValidationSchema(intl), [intl]);

  return useFormik({
    initialValues: { gates },
    enableReinitialize: true,
    onSubmit,
    validationSchema,
    validateOnChange: false
  });
};

export default useGatesFormik;
