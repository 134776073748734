import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ApproveIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12 2 C 6.480000000000001 2 2 6.480000000000004 2 12 C 2 17.52 6.480000000000001 22 12 22 C 17.52 22 22 17.52 22 12 C 22 6.480000000000004 17.52 2 12 2 z M 16.58984375 7.580078125 L 18 9 L 10 17 L 6 13 L 7.41015625 11.58984375 L 10 14.169921875 L 16.58984375 7.580078125 z " />
    </>
  </SvgIcon>
));

export default ApproveIcon;
