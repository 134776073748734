import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';

export const CURRENCIES_QUERY = gql`
  query currencies {
    currencies {
      id
      displayText: symbol
    }
  }
`;

const useStyles = makeStyles(() => ({
  field: {
    height: '48px',
    borderRadius: '8px',
    marginTop: '8px',
    width: '100%'
  },
  shimmer: {},
  label: {}
}));

export const CurrencyDropdown = ({ label, ...rest }) => {
  const classes = useStyles();
  const { data, loading } = useQuery(CURRENCIES_QUERY, {
    variables: {},
    fetchPolicy: 'cache-and-network'
  });

  const currencies = get(data, 'currencies', []);

  if (loading) return <Skeleton variant="rect" className={classes.field} />;

  return <SimpleDropdown label={label} options={currencies} {...rest} />;
};

CurrencyDropdown.propTypes = {
  label: PropTypes.any
};

export default CurrencyDropdown;
