import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const TAG_OPTIONS_QUERY = gql`
  query GetTagOptions($search: String) {
    tags(page: 1, pageSize: 20, searchTerm: $search) {
      id
      name
    }
  }
`;

const mapOptions = options =>
  options.map(option => ({
    label: option.name,
    value: option.id
  }));

const useTagOptions = ({ search }) => {
  const { data, loading, refetch } = useQuery(TAG_OPTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search
    },
    context: {
      debounceKey: 'tag-search',
      debounceTimeout: 250
    }
  });

  const resultOptions =
    data && data.tags && Array.isArray(data.tags) ? mapOptions(data.tags) : [];

  return {
    loading,
    refetch,
    data: resultOptions
  };
};

export default useTagOptions;
