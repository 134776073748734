import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/TodaySharp';
import ClearIcon from '@material-ui/icons/ClearSharp';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core';
import { DatePicker } from '~/modules/common/components';

import { getLuxonJsDateFormatFromMe } from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me/useMeContext';
import FacetDetailField from '../../FacetDetailField';
import { useDateRangeFacetHandlers } from './useDateRangeFacetHandlers';

const label = <FormattedMessage id="dateRangeFacetDetails.title" />;
const popoverProps = { disablePortal: true };

const useStyles = makeStyles(theme => ({
  dateRangeContainer: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  datePicker: {
    flexGrow: 1,
    flexShrink: 1,
    margin: 0,
    fontSize: theme.typography.caption.fontSize
  },
  datePickerInput: {
    fontSize: theme.typography.caption.fontSize
  },
  clearButton: {
    margin: theme.spacing(-1.5)
  },
  divider: {
    margin: theme.spacing(0, 2),
    color: theme.palette.text.secondary
  },
  todayButton: {
    margin: theme.spacing(-1.5, -1.5, -1.5, 0),
    color: theme.palette.text.secondary
  }
}));

export const DateRangeFacetDetails = ({ selected, setSelected }) => {
  const classes = useStyles();
  const intl = useIntl();
  const me = useMeContext();
  const { onDateChange } = useDateRangeFacetHandlers({ setSelected });
  const onEndChange = useCallback(
    newEndDate => {
      const startDate = selected.length ? selected[0].range.startDate : null;
      const sDate = startDate && new Date(startDate).toISOString();
      const eDate = new Date(newEndDate).toISOString();

      onDateChange(sDate > eDate ? newEndDate : startDate, newEndDate);
    },
    [onDateChange, selected]
  );

  const onStartChange = useCallback(
    newStartDate => {
      const endDate = selected.length ? selected[0].range.endDate : null;
      const sDate = new Date(newStartDate).toISOString();
      const eDate = endDate && new Date(endDate).toISOString();

      onDateChange(newStartDate, eDate < sDate ? newStartDate : endDate);
    },
    [onDateChange, selected]
  );

  const onStartClear = useCallback(
    e => onDateChange(null, selected.length ? selected[0].range.endDate : null),
    [onDateChange, selected]
  );
  const onEndClear = useCallback(
    e =>
      onDateChange(selected.length ? selected[0].range.startDate : null, null),
    [onDateChange, selected]
  );
  const onTodayClick = useCallback(
    e => onDateChange(DateTime.local(), DateTime.local()),
    [onDateChange]
  );

  const luxonFormat = getLuxonJsDateFormatFromMe(me);

  return (
    <FacetDetailField label={label}>
      <div className={classes.dateRangeContainer}>
        <DatePicker
          variant="inline"
          inputVariant="standard"
          autoOk
          margin="dense"
          fullWidth
          format={luxonFormat}
          inputProps={{
            'aria-label': intl.formatMessage({
              id: 'dateRangeFacetDetails.startDateAriaLabel'
            })
          }}
          PopoverProps={popoverProps}
          className={classes.datePicker}
          value={selected.length > 0 ? selected[0].range.startDate : null}
          onChange={onStartChange}
        />
        {selected.length > 0 && selected[0].range.startDate ? (
          <InputAdornment position="end">
            <IconButton
              aria-label={intl.formatMessage({
                id: 'dateRangeFacetDetails.clearDateAriaLabel'
              })}
              role="button"
              className={classes.clearButton}
              onClick={onStartClear}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : null}
        <span className={classes.divider}>&mdash;</span>

        <DatePicker
          variant="inline"
          inputVariant="standard"
          autoOk
          margin="dense"
          format={luxonFormat}
          inputProps={{
            'aria-label': intl.formatMessage({
              id: 'dateRangeFacetDetails.endDateAriaLabel'
            })
          }}
          PopoverProps={popoverProps}
          fullWidth
          className={classes.datePicker}
          value={selected.length > 0 ? selected[0].range.endDate : null}
          onChange={onEndChange}
        />
        {selected.length && selected[0].range.endDate ? (
          <InputAdornment position="end">
            <IconButton
              aria-label={intl.formatMessage({
                id: 'dateRangeFacetDetails.clearDateAriaLabel'
              })}
              role="button"
              className={classes.clearButton}
              onClick={onEndClear}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : null}
        <IconButton
          className={classes.todayButton}
          onClick={onTodayClick}
          aria-label={intl.formatMessage({
            id: 'dateRangeFacetDetails.today'
          })}
          role="button"
          data-qe-id="todayCalendar"
        >
          <TodayIcon />
        </IconButton>
      </div>
    </FacetDetailField>
  );
};

DateRangeFacetDetails.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default DateRangeFacetDetails;
