import { useCallback } from 'react';

export const useFormChange = ({ setFieldValue }) => {
  const onSubjectFieldChange = useCallback(
    e => {
      setFieldValue('subject', e.target.value);
    },
    [setFieldValue]
  );
  const onEmailToFieldChange = useCallback(
    e => {
      setFieldValue('emailTo', e.target.value);
    },
    [setFieldValue]
  );
  const onEmailFromFieldChange = useCallback(
    e => {
      setFieldValue('emailFrom', e.target.value);
    },
    [setFieldValue]
  );
  const onMessageFieldChange = useCallback(
    e => {
      setFieldValue('message', e.target.value);
    },
    [setFieldValue]
  );
  const onEmailCcFieldChange = useCallback(
    e => {
      setFieldValue('emailCc', e.target.value);
    },
    [setFieldValue]
  );

  return {
    onSubjectFieldChange,
    onEmailToFieldChange,
    onEmailFromFieldChange,
    onMessageFieldChange,
    onEmailCcFieldChange
  };
};
