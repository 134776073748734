import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, makeStyles, InputAdornment, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import InfoIcon from '@material-ui/icons/Info';
import { hasError } from '~/util';
import {
  NoValue,
  DateField,
  Decimal,
  SummaryFormattedNumber,
  Hours
} from '~/modules/common/components';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { useFormChangeHandlers } from '../hooks';
import FieldLabel from './FieldLabel';
import EditorPopoverTooltip from './EditorPopoverTooltip';

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  title: {
    ...theme.typography.subtitle1,
    paddingTop: theme.spacing(2)
  },
  inputAdornment: {
    paddingLeft: theme.spacing(0.5),
    margin: 0
  },
  infoIcon: {
    cursor: 'help',
    color: theme.palette.text.secondary
  },
  taskAllocationGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  error: {
    color: theme.palette.error.main
  },
  diff: {
    display: 'flex',
    marginTop: theme.spacing(1),
    alignItems: 'center'
  },
  taskAllocation: {
    display: 'flex',
    justifyContent: 'end'
  },
  hoursLabel: {
    textAlign: 'right',
    paddingRight: '14px'
  },
  label: {
    color: theme.palette.text.primary
  },
  projectLabel: {
    color: theme.palette.text.secondary
  },
  selectDatesPrompt: {
    marginLeft: theme.spacing(2),
    minWidth: theme.spacing(20)
  },
  noValue: {
    display: 'flex',
    paddingRight: theme.spacing(1.75),
    justifyContent: 'end'
  }
}));

const useAllocatedHoursStyles = makeStyles(theme => ({
  input: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const startDateLabel = <FormattedMessage id="taskAllocationEditor.startDate" />;
const endDateLabel = <FormattedMessage id="taskAllocationEditor.endDate" />;

const useSummaryFormattedNumberStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    fontWeight: 'bold'
  },
  diffPositive: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

export const getAllocationVariance = ({ estimatedHours, allocatedHours }) =>
  estimatedHours ? allocatedHours - estimatedHours : null;

export const getVarianceMessageId = ({
  allocationVariance,
  taskResourceUserAllocationId
}) => {
  if (taskResourceUserAllocationId) {
    return allocationVariance > 0
      ? 'taskAllocationEditor.aboveEstimatedAtCompletion'
      : 'taskAllocationEditor.belowEstimatedAtCompletion';
  }

  return allocationVariance > 0
    ? 'taskAllocationEditor.aboveTaskEstimate'
    : 'taskAllocationEditor.belowTaskEstimate';
};

export const getDisabledDatesFromResourceAllocation = ({
  resourceAllocation
}) => date => {
  const { startDate, endDate } = resourceAllocation;
  const allocationStartDate = mapIsoStringtoUtcObject(startDate);
  const allocationEndDate = mapIsoStringtoUtcObject(endDate);
  const currentDay = mapRepliconDateToUtcObject(date);

  return currentDay > allocationEndDate || currentDay < allocationStartDate;
};

const EditorPopoverContent = ({
  resourceAllocation,
  otherTaskAllocationsSummary,
  initialEstimatedHours,
  estimatedAtCompletionHours,
  taskResourceUserAllocation
}) => {
  const { scheduleRules: resourceAllocationScheduleRules } = resourceAllocation;
  const {
    scheduleRules: otherTaskAllocationsSummaryScheduleRules
  } = otherTaskAllocationsSummary;
  const { values, errors, setValues } = useFormikContext();

  const { startDate, endDate, projectAvailableHours, allocatedHours } = values;
  const roundedProjectAvailableHours = roundToDecimals(projectAvailableHours);
  const roundedAllocatedHours = roundToDecimals(allocatedHours);

  const remainingHours = roundedProjectAvailableHours - roundedAllocatedHours;

  const allocationVariance = getAllocationVariance({
    estimatedHours: taskResourceUserAllocation?.id
      ? estimatedAtCompletionHours
      : initialEstimatedHours,
    allocatedHours
  });

  const {
    onStartDateChange,
    onEndDateChange,
    onTaskAllocationHoursChange
  } = useFormChangeHandlers({
    values,
    setValues,
    startDate,
    endDate,
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules,
    initialEstimatedHours
  });

  const { formatMessage } = useIntl();

  const classes = useStyles();
  const summaryFormattedNumberClasses = useSummaryFormattedNumberStyles();
  const allocatedHoursClasses = useAllocatedHoursStyles();

  const endAdornment = useMemo(
    () => (
      <InputAdornment className={classes.inputAdornment}>
        <FieldLabel
          messageId="taskAllocationEditor.hoursSuffix"
          variant="body2"
          color="textSecondary"
        />
      </InputAdornment>
    ),
    [classes.inputAdornment]
  );

  const availableProjectLabelValue = useMemo(
    () => ({
      project: (
        <span className={classes.projectLabel}>
          <FormattedMessage id="taskAllocationEditor.project" />
        </span>
      )
    }),
    [classes.projectLabel]
  );

  const availableHoursLabelValue = useMemo(
    () => ({
      hours: (
        <Decimal
          className={classes.label}
          value={roundedProjectAvailableHours}
        />
      )
    }),
    [classes.label, roundedProjectAvailableHours]
  );

  const remainingHoursLabelValue = useMemo(
    () => ({
      hours: (
        <Decimal
          value={remainingHours}
          className={classNames({
            [classes.error]: remainingHours < 0,
            [classes.label]: remainingHours >= 0
          })}
        />
      )
    }),
    [classes.error, classes.label, remainingHours]
  );

  const title = useMemo(
    () => (
      <EditorPopoverTooltip
        endDate={endDate}
        otherTaskAllocationsSummaryScheduleRules={
          otherTaskAllocationsSummaryScheduleRules
        }
        projectAvailableHours={projectAvailableHours}
        resourceAllocationScheduleRules={resourceAllocationScheduleRules}
        startDate={startDate}
      />
    ),
    [
      endDate,
      otherTaskAllocationsSummaryScheduleRules,
      projectAvailableHours,
      resourceAllocationScheduleRules,
      startDate
    ]
  );
  const shouldDisableDateFunc = getDisabledDatesFromResourceAllocation({
    resourceAllocation
  });

  return (
    <>
      <FieldLabel
        messageId="taskAllocationEditor.taskAllocation"
        className={classes.title}
      />
      <Grid>
        <Grid item container spacing={2} className={classes.datePicker}>
          <Grid item xs={12} sm={6}>
            <DateField
              clearable={false}
              variant="outlined"
              value={startDate}
              editable
              ariaLabel={startDateLabel}
              onChange={onStartDateChange}
              label={startDateLabel}
              error={hasError(errors, 'startDate')}
              shouldDisableDate={shouldDisableDateFunc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateField
              clearable={false}
              variant="outlined"
              value={endDate}
              editable
              onChange={onEndDateChange}
              ariaLabel={endDateLabel}
              label={endDateLabel}
              error={hasError(errors, 'endDate')}
              shouldDisableDate={shouldDisableDateFunc}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FieldLabel
              variant="body2"
              messageId="taskAllocationEditor.availableProject"
              messageValues={availableProjectLabelValue}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {startDate && endDate ? (
              <FieldLabel
                messageId="taskAllocationEditor.hours"
                messageValues={availableHoursLabelValue}
                variant="body2"
                className={classes.hoursLabel}
                color="textSecondary"
              />
            ) : (
              <FieldLabel
                messageId="taskAllocationEditor.selectDatesPrompt"
                variant="caption"
                color="textSecondary"
                className={classes.selectDatesPrompt}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {startDate && endDate && (
              <Tooltip title={title} placement="right">
                <InfoIcon className={classes.infoIcon} fontSize="small" />
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3} className={classes.taskAllocationGrid}>
            <FieldLabel
              variant="body2"
              messageId="taskAllocationEditor.taskAllocation"
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.taskAllocation}>
            <Hours
              classes={allocatedHoursClasses}
              autoFocus
              value={allocatedHours}
              variant="outlined"
              onChange={onTaskAllocationHoursChange}
              endAdornment={endAdornment}
              error={hasError(errors, 'allocatedHours')}
              ariaLabel={formatMessage({
                id: 'taskAllocationEditor.taskAllocation'
              })}
              align="right"
              isEditible
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {Boolean(allocationVariance) && (
              <div className={classes.diff}>
                <SummaryFormattedNumber
                  classes={summaryFormattedNumberClasses}
                  value={allocationVariance}
                  formattedMessageKey="taskAllocationEditor.diffHours"
                  showAddIcon={allocationVariance > 0}
                  isHigher={allocationVariance > 0}
                />
                <FieldLabel
                  messageId={getVarianceMessageId({
                    allocationVariance,
                    taskResourceUserAllocationId: taskResourceUserAllocation?.id
                  })}
                  color="textSecondary"
                  variant="body2"
                />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FieldLabel
              variant="body2"
              messageId="taskAllocationEditor.remaining"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {startDate && endDate ? (
              <FieldLabel
                messageId="taskAllocationEditor.hours"
                variant="body2"
                color="textSecondary"
                className={classNames(classes.hoursLabel, {
                  [classes.error]: remainingHours < 0
                })}
                messageValues={remainingHoursLabelValue}
              />
            ) : (
              <div className={classes.noValue}>
                <NoValue />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

EditorPopoverContent.propTypes = {
  resourceAllocation: PropTypes.object,
  otherTaskAllocationsSummary: PropTypes.object,
  initialEstimatedHours: PropTypes.number,
  estimatedAtCompletionHours: PropTypes.number,
  taskResourceUserAllocation: PropTypes.object
};

export default EditorPopoverContent;
