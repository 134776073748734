import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';

const AnalyticsIcon = ({ className }) => (
  <SvgIcon className={className}>
    <>
      <path d="M3,3v18h18V3H3z M9,17H7v-5h2V17z M13,17h-2v-3h2V17z M13,12h-2v-2h2V12z M17,17h-2V7h2V17z" />
    </>
  </SvgIcon>
);

AnalyticsIcon.propTypes = {
  className: PropTypes.string
};

export default AnalyticsIcon;
