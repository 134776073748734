import { getWorkDaysBetweenDates } from '~/modules/common/dates/compare';
import { USER_SCHEDULE_HOURS } from '~/modules/resourcing/common/constants';

export const convertHoursToFTE = ({ startDate, endDate, hours }) => {
  if ((hours || 0) === 0) {
    return 0;
  }
  if (!startDate || !endDate) {
    return null;
  }
  const workDays =
    getWorkDaysBetweenDates({
      startDateInRepliconFormat: startDate,
      endDateInRepliconFormat: endDate
    }) || 0;

  if (workDays === 0) {
    return 0;
  }

  return hours / (USER_SCHEDULE_HOURS * workDays);
};

export const convertHoursToPercentage = ({ hours, totalHours }) =>
  (hours || 0) === 0 || (totalHours || 0) === 0
    ? 0
    : (hours / totalHours) * 100;

export const convertResourceAllocationSeriesDataToFTEUnits = resourceAllocationSeriesData =>
  (resourceAllocationSeriesData || []).map(data => ({
    ...data,
    timeoff: convertHoursToFTE({
      ...data.dateRange,
      hours: data.timeoff
    }),
    holiday: convertHoursToFTE({
      ...data.dateRange,
      hours: data.holiday
    }),
    totalSchedule: convertHoursToFTE({
      ...data.dateRange,
      hours: data.totalSchedule
    }),
    allocated: convertHoursToFTE({
      ...data.dateRange,
      hours: data.allocated
    }),
    overAllocated: convertHoursToFTE({
      ...data.dateRange,
      hours: data.overAllocated
    }),
    requested: convertHoursToFTE({
      ...data.dateRange,
      hours: data.requested
    }),
    toBeHired: convertHoursToFTE({
      ...data.dateRange,
      hours: data.toBeHired
    })
  }));

export const convertResourceAllocationSeriesDataToPercentageUnits = resourceAllocationSeriesData =>
  (resourceAllocationSeriesData || []).map(data => ({
    ...data,
    timeoff: convertHoursToPercentage({
      hours: data.timeoff,
      totalHours: data.totalSchedule
    }),
    holiday: convertHoursToPercentage({
      hours: data.holiday,
      totalHours: data.totalSchedule
    }),
    totalSchedule: convertHoursToPercentage({
      hours: data.totalSchedule,
      totalHours: data.totalSchedule
    }),
    allocated: convertHoursToPercentage({
      hours: data.allocated,
      totalHours: data.totalSchedule
    }),
    overAllocated: convertHoursToPercentage({
      hours: data.overAllocated,
      totalHours: data.totalSchedule
    }),
    requested: convertHoursToPercentage({
      hours: data.requested,
      totalHours: data.totalSchedule
    }),
    toBeHired: convertHoursToPercentage({
      hours: data.toBeHired
    })
  }));
