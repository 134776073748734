import { useCallback } from 'react';

export default actions => {
  const useFilterImportExportActions = useCallback(() => {
    const validActions = [];

    for (const actionObj of actions) {
      if (actionObj) {
        validActions.push(actionObj);
      }
    }

    return validActions;
  }, [actions]);

  return useFilterImportExportActions();
};
