import { orderBy } from 'lodash';

export const mergeAssociatedAndOutstandingBills = ({
  billId,
  billBalanceTotal,
  creditItem: { associatedBills = [], currency = null } = {},
  outstandingBills = [],
  isRefund = false
}) => {
  const actualOutstandingBill = outstandingBills.filter(bill =>
    isRefund ? bill.balanceAmount.amount < 0 : bill.balanceAmount.amount > 0
  );
  const outstandingBillsWithCreditCurrency = currency
    ? actualOutstandingBill.map(bill => ({
        ...bill,
        amount: {
          ...bill.amount,
          currency
        }
      }))
    : actualOutstandingBill;
  const updatedOutstandingBills =
    billId && billBalanceTotal
      ? outstandingBillsWithCreditCurrency.map(oBill =>
          oBill.id === billId
            ? {
                ...oBill,
                amount: {
                  ...oBill.amount,
                  amount: billBalanceTotal.amount,
                  currency: billBalanceTotal.currency
                },
                allocatedBillAmount: {
                  ...oBill.amount,
                  amount: billBalanceTotal.amount,
                  currency: billBalanceTotal.currency
                }
              }
            : {
                ...oBill,
                amount: {
                  ...oBill.amount,
                  currency: billBalanceTotal.currency
                }
              }
        )
      : outstandingBillsWithCreditCurrency;

  const associatedAndOutstandingBills = associatedBills.concat(
    updatedOutstandingBills.filter(
      oBill => !associatedBills.some(abill => abill.id === oBill.id)
    )
  );

  return {
    associatedAndOutstandingBills: orderBy(
      associatedAndOutstandingBills,
      ['dueDate.dateValue', 'id'],
      ['asc', 'asc']
    )
  };
};

export const mapExchangeRateToCurrency = (
  sourceCurrencyId,
  exchangeRates = []
) => {
  const exchangeRateMap = {};

  exchangeRateMap[sourceCurrencyId] = exchangeRates.reduce(
    (accum, exchangeRate) => ({
      ...accum,
      [exchangeRate.currencyId]: exchangeRate.rate
    }),
    {}
  );

  return exchangeRateMap;
};

export const extractCurrencyIdsFromBills = (currencyId, bills) => [
  ...new Set(
    bills
      .filter(item => item.balanceAmount.currency.id !== currencyId)
      .map(bill => bill.balanceAmount.currency.id)
  )
];

export const getExchangeRate = (
  exchangeRate,
  sourceCurrencyId,
  targetCurrencyId
) => {
  if (sourceCurrencyId === targetCurrencyId || !exchangeRate[sourceCurrencyId])
    return 1;

  return exchangeRate[sourceCurrencyId][targetCurrencyId]
    ? exchangeRate[sourceCurrencyId][targetCurrencyId]
    : 1;
};

export const getRoundedValue = (value, precision = 4) => {
  const roundedValue =
    Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

  return roundedValue === 0 ? 0 : roundedValue;
};
