import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  CostCenterName,
  DepartmentName,
  DivisionName,
  EmployeeTypeName,
  LocationName,
  ServiceCenterName
} from '~/modules/common/components/Groups';
import {
  SideCardSectionHeader,
  SideCardSectionDetail
} from '~/modules/common/components/SideCard';
import NoDataItem from '~/modules/common/components/NoDataItem';
import { useDialogState } from '~/modules/common/hooks';
import EditGroupsDialog from './EditGroupsDialog';

const groupsLabel = <FormattedMessage id="projectBasicInfoCard.groups" />;

export const noGroupsText = (
  <NoDataItem>
    <FormattedMessage id="projectBasicInfoCard.noGroups" />
  </NoDataItem>
);

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(1)
  },
  detail: {
    '& span': {
      fontSize: theme.typography.body2.fontSize
    }
  }
}));

export const Groups = ({
  projectId,
  showEdit,
  hasGroups,
  groupSettings,
  costCenter,
  department,
  division,
  employeeType,
  location,
  serviceCenter,
  legacyDepartmentDisabled,
  legacyEmployeeTypeDisabled,
  permittedActionUris
}) => {
  const classes = useStyles();
  const detailClasses = useMemo(
    () => ({
      detail: classes.detail
    }),
    [classes.detail]
  );
  const { open, openDialog, closeDialog } = useDialogState();

  return (
    <>
      <SideCardSectionHeader
        title={groupsLabel}
        onEditClick={openDialog}
        dataQeId="EditGroupsSection"
        showEdit={showEdit}
        ariaLabelKey="button.groupsInfoEditButton"
      />
      <div className={classes.content}>
        {!hasGroups && (
          <SideCardSectionDetail noPadding detail={noGroupsText} />
        )}
        {costCenter &&
          permittedActionUris.includes(
            'urn:replicon:project-action:view-cost-center'
          ) && (
            <SideCardSectionDetail
              classes={detailClasses}
              noPadding
              detail={<CostCenterName value={costCenter} />}
            />
          )}
        {division &&
          permittedActionUris.includes(
            'urn:replicon:project-action:view-division'
          ) && (
            <SideCardSectionDetail
              classes={detailClasses}
              noPadding
              detail={<DivisionName value={division} />}
            />
          )}
        {location &&
          permittedActionUris.includes(
            'urn:replicon:project-action:view-location'
          ) && (
            <SideCardSectionDetail
              classes={detailClasses}
              noPadding
              detail={<LocationName value={location} />}
            />
          )}
        {serviceCenter &&
          permittedActionUris.includes(
            'urn:replicon:project-action:view-service-center'
          ) && (
            <SideCardSectionDetail
              classes={detailClasses}
              noPadding
              detail={<ServiceCenterName value={serviceCenter} />}
            />
          )}
        {department &&
          legacyDepartmentDisabled &&
          permittedActionUris.includes(
            'urn:replicon:project-action:view-department'
          ) && (
            <SideCardSectionDetail
              classes={detailClasses}
              noPadding
              detail={<DepartmentName value={department} />}
            />
          )}
        {employeeType &&
          legacyEmployeeTypeDisabled &&
          permittedActionUris.includes(
            'urn:replicon:project-action:view-employee-type'
          ) && (
            <SideCardSectionDetail
              classes={detailClasses}
              noPadding
              detail={<EmployeeTypeName value={employeeType} />}
            />
          )}
      </div>
      <EditGroupsDialog
        title={groupsLabel}
        open={open}
        onClose={closeDialog}
        projectId={projectId}
        groupSettings={groupSettings}
        costCenter={costCenter}
        department={department}
        division={division}
        employeeType={employeeType}
        location={location}
        serviceCenter={serviceCenter}
        legacyDepartmentDisabled={legacyDepartmentDisabled}
        legacyEmployeeTypeDisabled={legacyEmployeeTypeDisabled}
      />
    </>
  );
};

Groups.propTypes = {
  projectId: PropTypes.string,
  showEdit: PropTypes.bool,
  hasGroups: PropTypes.bool,
  groupSettings: PropTypes.object,
  costCenter: PropTypes.object,
  department: PropTypes.object,
  division: PropTypes.object,
  employeeType: PropTypes.object,
  location: PropTypes.object,
  serviceCenter: PropTypes.object,
  legacyDepartmentDisabled: PropTypes.bool,
  legacyEmployeeTypeDisabled: PropTypes.bool,
  permittedActionUris: PropTypes.arrayOf(PropTypes.string)
};

export default Groups;
