import React from 'react';
import PropTypes from 'prop-types';
import { DivisionIcon } from '~/modules/common/components';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupIconField from './GroupIconField';

export const ResourceRequestDivisionField = ({ value, divisionSettings }) => (
  <GroupIconField
    value={value}
    GroupIcon={DivisionIcon}
    groupSettings={divisionSettings}
    labelKey="resourceRequestItem.division"
  />
);

ResourceRequestDivisionField.propTypes = {
  value: PropTypes.object,
  divisionSettings: PropTypes.object.isRequired
};
export default withGroupSettings(
  'division',
  'divisionSettings'
)(ResourceRequestDivisionField);
