import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { ResourceRoleScheduleFragmentDoc } from '~/types';

export const UPDATE_ROLES_SCHEDULE = gql`
  mutation updateRoleSchedules($input: UpdateUserRoleSchedulesInput!) {
    updateRoleSchedules(input: $input) {
      roleSchedules {
        effectiveDate
        projectRoles {
          projectRole {
            uri
            name
          }
          isPrimary
        }
      }
    }
  }
`;

export const updateCache = ({ userUri }) => (
  cache,
  {
    data: {
      updateRoleSchedules: { roleSchedules: updatedRoles }
    }
  }
) => {
  const cachedFragmentId = `ResourceDetails:${userUri}`;
  const cachedResourceDetails = cache.readFragment({
    id: cachedFragmentId,
    fragment: ResourceRoleScheduleFragmentDoc
  });

  cache.writeFragment({
    id: cachedFragmentId,
    fragment: ResourceRoleScheduleFragmentDoc,
    data: {
      ...cachedResourceDetails,
      roleScheduleDetails: [...updatedRoles]
    }
  });
};

export const useUpdateRoleSchedule = ({ userUri }) =>
  useMutation(UPDATE_ROLES_SCHEDULE, {
    update: updateCache({ userUri })
  });

export const onScheduleSubmit = ({
  userUri,
  onCancel,
  updateRoleSchedule
}) => values => {
  const { schedule: schedules } = values;

  const convertRoles = roles => {
    return roles
      .filter(item => Boolean(item.role))
      .map(c => ({
        uri: c.role.id,
        isPrimary: c.isPrimary
      }));
  };

  const roleSchedules = schedules.map(schedule => ({
    effectiveDate: schedule.effectiveDate && schedule.effectiveDate,
    projectRoles: convertRoles(schedule.projectRoles)
  }));

  updateRoleSchedule({
    variables: {
      input: { userUri, scheduleEntries: roleSchedules }
    }
  });

  onCancel();
};
