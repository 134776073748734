import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { AddCircleSharp, RemoveCircleSharp } from '@material-ui/icons';
import PropTypes from 'prop-types';

export const AddRemoveEntry = ({
  record,
  index,
  column: { onAddEntry, onRemoveEntry }
}) => {
  const { isInitialRate } = record;
  const { formatMessage } = useIntl();
  const onRemoveClick = useCallback(() => {
    if (onRemoveEntry) {
      onRemoveEntry(index, record);
    }
  }, [index, onRemoveEntry, record]);

  const onAddClick = useCallback(() => {
    if (onAddEntry) {
      onAddEntry(index, record);
    }
  }, [index, onAddEntry, record]);

  return (
    <>
      {isInitialRate ? (
        <IconButton
          data-qe-id="AddEffectiveDateIcon"
          aria-label={formatMessage({
            id: 'advancedRateCard.addEntry'
          })}
          onClick={onAddClick}
          color="primary"
          disabled={record.disabled || record.isDimensionDeleted}
        >
          <AddCircleSharp />
        </IconButton>
      ) : (
        <IconButton
          data-qe-id="RemoveEntry"
          aria-label={formatMessage({
            id: 'advancedRateCard.removeEntry'
          })}
          onClick={onRemoveClick}
          disabled={record.disabled || record.isDimensionDeleted}
        >
          <RemoveCircleSharp />
        </IconButton>
      )}
    </>
  );
};

AddRemoveEntry.propTypes = {
  record: PropTypes.object,
  index: PropTypes.number,
  column: PropTypes.object
};
