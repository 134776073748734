import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AdditionalRoleDropdownComponent from './AdditionalRoleDropdownComponent';

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: theme.spacing(1)
  }
}));

export const RoleScheduleEditableAdditionalFormRow = ({
  classes: classesOverride,
  additionalRoles,
  scheduleIndex,
  dropdownFieldKey,
  form,
  label,
  dataQeId,
  permittedActions
}) => {
  const classes = useStyles(classesOverride);
  const handleOnChange = ({
    form: {
      values: { schedule },
      setValues,
      setFieldValue,
      ...rest
    }
  }) => value => {
    const roles = schedule[scheduleIndex].projectRoles;
    const primaryRole = schedule[scheduleIndex].projectRoles[0];

    // Single role
    if (value.displayText) {
      const mappedRole = {
        role: {
          displayText: value.displayText,
          id: value.id
        },
        isPrimary: false
      };

      return setFieldValue(`schedule[${scheduleIndex}].projectRoles`, [
        ...roles,
        mappedRole
      ]);
    }

    // Creatable
    if (value.some(val => val.isCreatable)) {
      return true;
    }

    // Multiple
    const addRoles = value.map(val => ({
      role: {
        displayText: val.displayText,
        id: val.id
      },
      isPrimary: false
    }));

    return setFieldValue(`schedule[${scheduleIndex}].projectRoles`, [
      primaryRole,
      ...addRoles
    ]);
  };

  const onChange = useCallback(handleOnChange({ form }), [form]);

  const disabledValues = useMemo(() => {
    return (
      form.values &&
      form.values.schedule &&
      form.values.schedule[scheduleIndex] &&
      form.values.schedule[scheduleIndex].projectRoles.map(
        val => val.role && val.role.id
      )
    );
  }, [form.values, scheduleIndex]);

  return (
    <Grid data-qe-id={dataQeId} item xs={12} classes={classes}>
      <Field
        name={dropdownFieldKey}
        onChange={onChange}
        label={label}
        component={AdditionalRoleDropdownComponent}
        disabledValues={disabledValues}
        permittedActions={permittedActions}
        values={additionalRoles}
      />
    </Grid>
  );
};

RoleScheduleEditableAdditionalFormRow.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  additionalRoles: PropTypes.array,
  label: PropTypes.string,
  scheduleIndex: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  dropdownFieldKey: PropTypes.string.isRequired,
  permittedActions: PropTypes.array
};

export default RoleScheduleEditableAdditionalFormRow;
