import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';

export const hasViewCustomFields = permissions =>
  (permissions || []).filter(
    p => p.permissionActionUri === 'urn:replicon:user-action:view-custom-fields'
  ).length !== 0;

export const getColumns = (
  customFieldDefinitions = [],
  tagFieldData = [],
  me = {}
) => {
  const {
    groupSettings,
    legacyDepartmentDisabled,
    legacyEmployeeTypeDisabled,
    permissions
  } = me;
  const customFieldColumns = hasViewCustomFields(permissions)
    ? mapCustomFieldsToColumns(customFieldDefinitions)
    : [];
  const tagFieldColumns = hasViewCustomFields(permissions)
    ? mapTagFieldsToColumns(tagFieldData)
    : {};

  const locationSettings = get(groupSettings, 'location', {});
  const divisionSettings = get(groupSettings, 'division', {});
  const departmentSettings = get(groupSettings, 'department', {});
  const employeeTypeSettings = get(groupSettings, 'employeeType', {});
  const serviceCenterSettings = get(groupSettings, 'serviceCenter', {});
  const costCenterSettings = get(groupSettings, 'costCenter', {});
  const canViewRole = (permissions || []).some(
    p => p.permissionActionUri === 'urn:replicon:user-action:view-project-role'
  );

  const canViewResourcePool = (permissions || []).some(
    p => p.permissionActionUri === 'urn:replicon:user-action:view-resource-pool'
  );

  return {
    displayText: {
      field: 'displayText',
      value: <FormattedMessage id="resourceManagement.list.name" />,
      required: true,
      fixed: true,
      includesNavigationComponent: true
    },
    employeeId: {
      field: 'employeeId',
      value: <FormattedMessage id="resourceManagement.list.employeeId" />
    },
    startDate: {
      field: 'startDate',
      value: <FormattedMessage id="resourceManagement.list.startDate" />
    },
    endDate: {
      field: 'endDate',
      value: <FormattedMessage id="resourceManagement.list.endDate" />
    },
    ...(canViewRole
      ? {
          roles: {
            field: 'roles',
            value: <FormattedMessage id="resourceManagement.list.role" />
          }
        }
      : {}),
    supervisorAssignment: {
      field: 'supervisorAssignment',
      value: <FormattedMessage id="resourceManagement.list.supervisor" />
    },
    ...(costCenterSettings.isEnabled
      ? {
          currentCostCenter: {
            field: 'currentCostCenter',
            value: <FormattedMessage id="resourceManagement.list.costCenter" />,
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(!legacyDepartmentDisabled
      ? {
          department: {
            field: 'department',
            value: <FormattedMessage id="resourceManagement.list.department" />,
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(departmentSettings.isEnabled && legacyDepartmentDisabled
      ? {
          currentDepartment: {
            field: 'currentDepartment',
            value: <FormattedMessage id="resourceManagement.list.department" />,
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(divisionSettings.isEnabled
      ? {
          currentDivision: {
            field: 'currentDivision',
            value: <FormattedMessage id="resourceManagement.list.division" />,
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(employeeTypeSettings.isEnabled && legacyEmployeeTypeDisabled
      ? {
          currentEmployeeType: {
            field: 'currentEmployeeType',
            value: (
              <FormattedMessage id="resourceManagement.list.employeeType" />
            ),
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(!legacyEmployeeTypeDisabled
      ? {
          employeeType: {
            field: 'employeeType',
            value: (
              <FormattedMessage id="resourceManagement.list.employeeType" />
            ),
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(locationSettings.isEnabled
      ? {
          currentLocation: {
            field: 'currentLocation',
            value: <FormattedMessage id="resourceManagement.list.location" />,
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(serviceCenterSettings.isEnabled
      ? {
          currentServiceCenter: {
            field: 'currentServiceCenter',
            value: (
              <FormattedMessage id="resourceManagement.list.serviceCenter" />
            ),
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...(canViewResourcePool
      ? {
          resourcePools: {
            field: 'resourcePools',
            value: (
              <FormattedMessage id="resourceManagement.list.resourcePools" />
            ),
            category: {
              displayText: 'resourceAllocationUserChartDialog.groups'
            }
          }
        }
      : {}),
    ...customFieldColumns,
    ...tagFieldColumns
  };
};

const mapCustomFieldsToColumns = customFieldDefinitions =>
  customFieldDefinitions.reduce(
    (acc, customField) =>
      Object.assign(acc, {
        [customField.id]: {
          field: customField.id,
          value: customField.displayText,
          category: {
            displayText: 'resourceAllocationUserChartDialog.customFields',
            id: 'customFields'
          }
        }
      }),
    {}
  );

const mapTagFieldsToColumns = tagFieldData =>
  tagFieldData.reduce(
    (acc, tagField) =>
      Object.assign(acc, {
        [tagField.value || tagField.id]: {
          field: tagField.value || tagField.id,
          value: tagField.label || tagField.name,
          category: {
            displayText: 'resourceAllocationUserChartDialog.tags',
            id: 'tags'
          }
        }
      }),
    {}
  );

const getColumnGroups = ({
  customFieldDefinitions = [],
  tagFieldData = [],
  me
} = {}) => {
  const columns = getColumns(customFieldDefinitions, tagFieldData, me);

  return Object.keys(columns).map(c => columns[c]);
};

export default getColumnGroups;
