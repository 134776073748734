import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useMemo, memo } from 'react';
import { dateToPosition } from '~/modules/common/charts/timeline/calculations';

const useStyles = makeStyles(theme => ({
  boundary: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.grey[200]
  },
  startBoundary: {
    left: 0,
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  endBoundary: {
    right: 0,
    borderLeft: `1px solid ${theme.palette.grey[400]}`
  }
}));

export const RangeBoundaries = memo(
  ({ classes: classesOverride, chartStartDate, start, end, scale }) => {
    const classes = useStyles({ classes: classesOverride });
    const startStyle = useMemo(
      () =>
        start
          ? {
              width: Math.max(
                0,
                dateToPosition({
                  chartStartDate,
                  date: DateTime.fromISO(start).toUTC(),
                  scale,
                  isPsaRmpUserSchedulePerfFixEnabled: true
                })
              ),
              opacity: 0.6
            }
          : null,
      [chartStartDate, scale, start]
    );

    const endStyle = useMemo(
      () =>
        end
          ? {
              left: dateToPosition({
                chartStartDate,
                date: DateTime.fromISO(end)
                  .toUTC()
                  .plus({ days: 1 }),
                scale,
                isPsaRmpUserSchedulePerfFixEnabled: true
              }),
              opacity: 0.6
            }
          : null,
      [chartStartDate, end, scale]
    );

    return (
      <>
        {start ? (
          <div
            className={classNames(classes.boundary, classes.startBoundary)}
            style={startStyle}
          />
        ) : null}
        {end ? (
          <div
            className={classNames(classes.boundary, classes.endBoundary)}
            style={endStyle}
          />
        ) : null}
      </>
    );
  }
);

RangeBoundaries.propTypes = {
  classes: PropTypes.object,
  scale: PropTypes.string,
  chartStartDate: PropTypes.object.isRequired,
  start: PropTypes.string,
  end: PropTypes.string
};

export default RangeBoundaries;
