import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, makeStyles } from '@material-ui/core';
import CreatableOption from './CreatableOption';

const useStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

export const CreatableDropdownItem = ({
  classes: classesOverrides,
  option,
  onCreate,
  primaryLabelTextId,
  optionPropText = 'label'
}) => {
  const { isCreatable } = option;
  const classes = useStyles({ classes: classesOverrides });

  return isCreatable ? (
    <CreatableOption
      option={option}
      onCreate={onCreate}
      primaryLabelTextId={primaryLabelTextId}
      optionPropText={optionPropText}
    />
  ) : (
    <ListItemText primary={option[optionPropText]} classes={classes} />
  );
};

CreatableDropdownItem.propTypes = {
  classes: PropTypes.object,
  option: PropTypes.object.isRequired,
  primaryLabelTextId: PropTypes.string,
  optionPropText: PropTypes.string,
  onCreate: PropTypes.func
};

export default CreatableDropdownItem;
