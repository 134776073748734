import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1)
  },
  title: {
    color: theme.palette.text.secondary,
    lineHeight: 1
  },
  primaryValue: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize
  },
  secondaryValue: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1
  },
  delta: {
    borderRadius: '4px',
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.125, 0.75),
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1
  }
}));

export const requestAllocationTotalsStyles = makeStyles(theme => ({
  value: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize
  }
}));

export default useStyles;
