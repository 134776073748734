import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { NoValue } from '~/modules/common/components';
import User from '~/modules/common/components/User';
import Date from '~/modules/common/components/Date';

const useStyles = makeStyles(theme => ({
  user: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize
  },
  asOf: {
    display: 'flex',
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(4) - 4
  },
  lineItem: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 'unset'
  }
}));

export const SupervisorColumn = ({
  classes: classesOverride,
  record: resourcePool
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const userClasses = useMemo(() => ({ name: classes.user }), [classes.user]);

  return resourcePool.supervisorAssignment ? (
    <div className={classes.user}>
      <User
        user={resourcePool.supervisorAssignment.supervisor}
        classes={userClasses}
      />
      {resourcePool.supervisorAssignment.effectiveDate && (
        <div className={classes.asOf}>
          {intl.formatMessage({ id: 'resourceManagementList.asOf' })}
          <Date
            className={classes.lineItem}
            value={resourcePool.supervisorAssignment.effectiveDate}
          />
        </div>
      )}
    </div>
  ) : (
    <NoValue />
  );
};

SupervisorColumn.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired
};

export default SupervisorColumn;
