import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  Radio,
  TextField,
  makeStyles
} from '@material-ui/core';
import { LoadingButton } from '~/modules/common/components';
import FormDateField from '~/modules/common/components/FormDateField';
import {
  mapIsoStringtoUtcObject,
  isoStringToObjectWithCache as isoStringToObject
} from '~/modules/common/dates/convert';
import { ReleaseResourceAllocationType } from '~/types';
import ImpactedTasksBanner from '../ImpactedTasksBanner';
import ImpactedTaskSummaryBanner from '../ImpactedTaskSummaryBanner/ImpactedTaskSummaryBanner';

const useConfirmStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(-0.5)
  }
}));

const useDateFieldStyles = makeStyles(() => ({
  input: {
    display: 'inline'
  }
}));

const useCommentStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(2)
  }
}));

const useStyles = makeStyles(theme => ({
  date: {
    backgroundColor: theme.palette.grey[300]
  },
  asOf: {
    position: 'absolute',
    right: theme.spacing(5)
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: 500
  }
}));

const inputCommentProps = {
  disableUnderline: true
};

export const ReleaseResourceRequestDialogActions = ({
  onCancelClick,
  onReleaseClick,
  releaseConfirmed,
  releaseDate,
  onReleaseConfirmChange,
  onReleaseDateChange,
  requestStartDate,
  requestEndDate,
  onCommentsChange,
  comment,
  isReleaseLoading = false,
  noneSelected,
  releaseType,
  onReleaseTypeChange,
  impactedTasks,
  isLoadingImpactedTasksSummary,
  selectedUsersForReleasing,
  canEditTask,
  allocations,
  usersAssignedImpactedTaskSummary,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const confirmClasses = useConfirmStyles();
  const dateClasses = useDateFieldStyles();
  const commentClasses = useCommentStyles();
  const memoizedOptions = useMemo(
    () => ({
      releaseConfirm: (
        <Checkbox
          checked={releaseConfirmed}
          onChange={onReleaseConfirmChange}
        />
      ),
      releaseTypeEntirelyInputProps: {
        'aria-label': intl.formatMessage({
          id: 'releaseResourceRequestDialog.releaseEntirely'
        })
      },
      releaseTypeAsOfInputProps: {
        'aria-label': intl.formatMessage({
          id: 'releaseResourceRequestDialog.asOf'
        })
      }
    }),
    [intl, onReleaseConfirmChange, releaseConfirmed]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Radio
            checked={releaseType === ReleaseResourceAllocationType.Entirely}
            onChange={onReleaseTypeChange}
            value={ReleaseResourceAllocationType.Entirely}
            name="releaseType"
            inputProps={memoizedOptions.releaseTypeEntirelyInputProps}
          />
          <FormattedMessage id="releaseResourceRequestDialog.releaseEntirely" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Radio
            checked={releaseType === ReleaseResourceAllocationType.Asof}
            onChange={onReleaseTypeChange}
            value={ReleaseResourceAllocationType.Asof}
            name="releaseType"
            inputProps={memoizedOptions.releaseTypeAsOfInputProps}
          />
          <FormDateField
            readOnlyComponent="TextField"
            variant="filled"
            classes={dateClasses}
            clearable={false}
            minDate={
              requestStartDate && mapIsoStringtoUtcObject(requestStartDate)
            }
            maxDate={requestEndDate && mapIsoStringtoUtcObject(requestEndDate)}
            editable={releaseType === ReleaseResourceAllocationType.Asof}
            onChange={onReleaseDateChange}
            useDateFieldStyles={false}
            containerClassName={classes.date}
            fullWidth={false}
            label={intl.formatMessage({
              id: 'releaseResourceRequestDialog.asOf'
            })}
            value={releaseDate ? isoStringToObject(releaseDate) : null}
            placeholder={intl.formatMessage({
              id: 'releaseResourceRequestDialog.todaysDate'
            })}
          />
        </Grid>
      </Grid>
      <br />
      {!isPsaRmpTaskAllocation1Enabled && (
        <ImpactedTasksBanner
          isLoading={isLoadingImpactedTasksSummary}
          canEditTask={canEditTask}
          impactedTasks={impactedTasks}
          selectedUsersForReleasing={selectedUsersForReleasing}
        />
      )}
      {isPsaRmpTaskAllocation1Enabled && (
        <ImpactedTaskSummaryBanner
          usersAssignedImpactedTaskSummary={usersAssignedImpactedTaskSummary}
          releaseDate={
            releaseType === ReleaseResourceAllocationType.Asof
              ? releaseDate
              : null
          }
          isLoading={isLoadingImpactedTasksSummary}
        />
      )}
      <TextField
        InputProps={inputCommentProps}
        variant="filled"
        label={intl.formatMessage({
          id: 'releaseResourceRequestDialog.comment'
        })}
        classes={commentClasses}
        onChange={onCommentsChange}
        placeholder={intl.formatMessage({
          id: 'releaseResourceRequestDialog.comment'
        })}
        value={comment}
        fullWidth
        multiline
      />
      <div className={classes.actionContainer}>
        {!isPsaRmpTaskAllocation1Enabled && (
          <FormControlLabel
            control={memoizedOptions.releaseConfirm}
            classes={confirmClasses}
            label={intl.formatMessage({
              id: 'releaseResourceRequestDialog.confirmation'
            })}
          />
        )}
        <Button onClick={onCancelClick}>
          <FormattedMessage id="releaseResourceRequestDialog.cancel" />
        </Button>
        <LoadingButton
          isLoading={isReleaseLoading}
          onClick={onReleaseClick}
          color="secondary"
          autoFocus
          disabled={
            isLoadingImpactedTasksSummary ||
            (!releaseConfirmed && !isPsaRmpTaskAllocation1Enabled) ||
            (noneSelected && allocations.length > 0) ||
            (!canEditTask &&
              selectedUsersForReleasing.length > 0 &&
              impactedTasks.length > 0)
          }
        >
          <FormattedMessage id="releaseResourceRequestDialog.release" />
        </LoadingButton>
      </div>
    </>
  );
};

ReleaseResourceRequestDialogActions.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onReleaseClick: PropTypes.func.isRequired,
  noneSelected: PropTypes.bool.isRequired,
  releaseConfirmed: PropTypes.bool.isRequired,
  onReleaseConfirmChange: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  onReleaseDateChange: PropTypes.func.isRequired,
  onCommentsChange: PropTypes.func.isRequired,
  releaseType: PropTypes.oneOf([
    ReleaseResourceAllocationType.Entirely,
    ReleaseResourceAllocationType.Asof
  ]),
  releaseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  requestStartDate: PropTypes.string,
  requestEndDate: PropTypes.string,
  onReleaseTypeChange: PropTypes.func.isRequired,
  isReleaseLoading: PropTypes.bool,
  isLoadingImpactedTasksSummary: PropTypes.bool,
  impactedTasks: PropTypes.array,
  selectedUsersForReleasing: PropTypes.array,
  canEditTask: PropTypes.bool,
  allocations: PropTypes.array,
  usersAssignedImpactedTaskSummary: PropTypes.array,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default ReleaseResourceRequestDialogActions;
