import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownSharp';
import SearchIcon from '@material-ui/icons/SearchSharp';
import { makeStyles } from '@material-ui/core';
import {
  useSearchState,
  useDetailsMenuHandler,
  useSearchInputHandler
} from './hooks';
import { withSearchFacetProviders } from './enhancers';

import SearchFacets from './SearchFacets';
import SearchInput from './SearchInput';
import SearchDetails from './SearchDetails';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(0, 0, 0, 1),
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    boxShadow: 'none',
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    })
  },
  rootActive: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    color: theme.palette.grey[800]
  },
  searchIcon: {
    color: theme.palette.text.secondary
  },
  inputRoot: {
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1),
    lineHeight: `${theme.spacing(3)}px`,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle2.fontSize,
    flexGrow: 0,
    '&::placeholder': {
      color: theme.palette.grey[800],
      opacity: 0.9
    }
  },
  iconButton: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  searchButton: {
    flexShrink: 0,
    flexGrow: 0,
    alignSelf: 'stretch',
    borderRadius: 0,
    boxShadow: 'none',
    minWidth: 0,
    width: 0,
    paddingLeft: 0,
    paddingRight: 0,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    })
  },
  searchButtonUnappliedChanges: {
    width: theme.spacing(11),
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  searchButtonIcon: {
    verticalAlign: 'middle'
  },
  searchButtonLabel: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  clearButton: {
    marginRight: -theme.spacing(1) * 1.5
  },
  menuButton: {},
  searchFacet: {},

  menu: {
    position: 'absolute',
    top: 'calc(100% - 2px)',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal,
    borderRadius: 0
  }
}));

const SearchClickAway = ({ onClickAway, children }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'), {
    noSsr: true
  });

  if (isMobile) {
    return children;
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
  );
};

SearchClickAway.propTypes = {
  onClickAway: PropTypes.func,
  children: PropTypes.any
};

export const SearchBox = ({
  className,
  autoFocus,
  facets = [],
  searchCriteria,
  setSearchCriteria,
  dataQeId,
  ariaLabel,
  searchInputAriaLabel,
  searchIconAriaLabel,
  hideSearch,
  externalSearch,
  searchHintLabel: searchHintLabelOverride,
  searchFacetClasses,
  showSearchFacetsInFullScreen,
  searchInputId,
  popperComponent
}) => {
  const classes = useStyles();
  const {
    searchCriteria: criteria,
    setSearchCriteria: setCriteria,
    searchPhrase,
    makeSetSearchPhrase,
    setSearchPhraseInternal
  } = useSearchState({
    searchCriteria,
    setSearchCriteria
  });

  const {
    handleKeyDown,
    hideDetailsMenu,
    isDetailsMenuVisible,
    toggleDetailsMenuVisibility
  } = useDetailsMenuHandler();

  const {
    handleSearchInputFocus,
    makeHandleClearSearchTerm,
    makeHandleSearchInputChange,
    makeHandleSearchInputSearch
  } = useSearchInputHandler({
    searchPhrase,
    hideDetailsMenu,
    makeSetSearchPhrase
  });

  const { formatMessage } = useIntl();
  const searchTitle = formatMessage({ id: 'search.title' });
  const searchHintLabel = searchHintLabelOverride
    ? formatMessage({ id: searchHintLabelOverride })
    : formatMessage({ id: 'searchBox.search' });
  const searchDialogCloseLabel = formatMessage({ id: 'searchBox.close' });

  return (
    <SearchClickAway onClickAway={hideDetailsMenu}>
      <div
        className={classNames(classes.root, className, {
          [classes.rootActive]: isDetailsMenuVisible
        })}
      >
        <SearchIcon className={classes.searchIcon} />
        {externalSearch}
        <SearchInput
          classes={classes}
          dataQeId={dataQeId}
          inputAriaLabel={searchInputAriaLabel || searchTitle}
          ariaLabel={ariaLabel}
          autoFocus={autoFocus}
          facets={facets}
          setSearchPhraseInternal={setSearchPhraseInternal}
          makeHandleSearchInputChange={makeHandleSearchInputChange}
          makeHandleClearSearchTerm={makeHandleClearSearchTerm}
          searchHintLabel={searchHintLabel}
          hideSearch={hideSearch}
          searchPhrase={searchPhrase}
          handleSearchInputFocus={handleSearchInputFocus}
          makeHandleSearchInputSearch={makeHandleSearchInputSearch}
          popperComponent={popperComponent}
          searchInputId={searchInputId}
        />
        <IconButton
          data-qe-id={`${dataQeId}_Load`}
          aria-label={searchIconAriaLabel || searchHintLabel}
          className={classNames(classes.iconButton, classes.menuButton)}
          onClick={toggleDetailsMenuVisibility}
          onKeyDown={handleKeyDown}
        >
          <ArrowDropDownIcon />
        </IconButton>
        {isDetailsMenuVisible && (
          <SearchDetails
            classes={classes}
            isOpen={isDetailsMenuVisible}
            close={hideDetailsMenu}
            searchDialogCloseLabel={searchDialogCloseLabel}
            showSearchFacetsInFullScreen={showSearchFacetsInFullScreen}
          >
            <SearchFacets
              qeIdTag={dataQeId}
              className={classes.searchFacets}
              searchCriteria={criteria}
              setSearchCriteria={setCriteria}
              facets={facets}
              hideDetailsMenu={hideDetailsMenu}
              hideSearch={hideSearch}
              searchFacetClasses={searchFacetClasses}
            />
          </SearchDetails>
        )}
      </div>
    </SearchClickAway>
  );
};

SearchBox.propTypes = {
  searchFacetClasses: PropTypes.object,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  facets: PropTypes.array.isRequired,
  searchCriteria: PropTypes.object.isRequired,
  setSearchCriteria: PropTypes.func.isRequired,
  dataQeId: PropTypes.string,
  ariaLabel: PropTypes.string,
  searchInputAriaLabel: PropTypes.string,
  searchIconAriaLabel: PropTypes.string,
  hideSearch: PropTypes.bool,
  externalSearch: PropTypes.node,
  searchHintLabel: PropTypes.string,
  showSearchFacetsInFullScreen: PropTypes.bool,
  searchInputId: PropTypes.string,
  popperComponent: PropTypes.object
};

export default withSearchFacetProviders(SearchBox);
