import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { BillStatus } from '~/types';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import BillStatusItem from './BillStatusItem';
import { useBillStatusAndSubStatus } from './enhancers/useBillStatusAndSubStatus';

const getOptionSelected = (option, value) => option.id === value.id;
const renderOption = option => <BillStatusItem value={option.label} />;

export const BillStatusFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const intl = useIntl();

  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  const { billStatusAndSubStatus } = useBillStatusAndSubStatus(
    BillStatus.Draft,
    isPsaPswatBillingDraftSubStatusEnabled
  );

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option => selected.some(v => v.id === option.id),
    [selected]
  );

  const classes = useStyles();

  const { formatMessage } = intl;

  return (
    <FacetDetailField
      label={formatMessage({ id: 'search.status' })}
      className={className}
    >
      <SimpleAutocomplete
        options={billStatusAndSubStatus}
        value={selected}
        variant="standard"
        onChange={onValueChange}
        multiple
        fullWidth
        classes={classes}
        hiddenLabel
        getOptionSelected={getOptionSelected}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        optionPropText="label"
      />
    </FacetDetailField>
  );
};

BillStatusFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default BillStatusFacetDetails;
