import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import {
  DEFAULT_PERIOD_SCALE,
  DEFAULT_DISPLAY_UNIT
} from '~/modules/resourcing/common/enums';
import { useMeContext } from '~/modules/me/useMeContext';
import { useGetKeyValueSettings } from '~/modules/common/hooks';
import DisplayUnitContextProvider from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import useResourcingStateCallbacks from '~/modules/resourcing/hooks/useResourcingStateCallbacks';
import { useGetChartDates } from '~/modules/resourcing/hooks/useGetResourcingChartInformation';
import { ResourcePlanGroupBy } from '~/types';
import { useChartDisplayPeriods } from '../common/chart/hooks';

export const ResourceAllocationToolbarContext = createContext();

const RESOURCE_REQUEST_CHART_SETTINGS = 'resource_request_chart_settings';

export const ResourceAllocationToolbarContextProvider = ({
  children,
  width
}) => {
  const {
    featureFlags: { isPsaRmpBugFixes2024 }
  } = useMeContext();

  const {
    isLoading: isResourceRequestChartSettingsLoading,
    keyValueSettings: chartSettings
  } = useGetKeyValueSettings(
    RESOURCE_REQUEST_CHART_SETTINGS,
    !isPsaRmpBugFixes2024
  );

  const [scale, setScale] = useState(DEFAULT_PERIOD_SCALE);

  const [displayUnit, setDisplayUnit] = useState(DEFAULT_DISPLAY_UNIT);

  const { onDisplayUnitChange, onScaleChange } = useResourcingStateCallbacks({
    scale,
    displayUnit,
    setDisplayUnit,
    setScale,
    key: isPsaRmpBugFixes2024
      ? RESOURCE_REQUEST_CHART_SETTINGS
      : 'assignment_chart_settings',
    isPsaRmpBugFixes2024
  });
  const [resourcePlanGroup, setResourcePlanGroup] = useState(
    ResourcePlanGroupBy.Project
  );

  const date = DateTime.utc().minus({ month: 1 });
  const startDate = DateTime.utc(date.year, date.month, 1);
  const endDate = startDate.plus({ month: 13 }).minus({ day: 1 });

  const {
    chartDates,
    onPrevious,
    onNext,
    dateRange: fixedRangeDateRange,
    setFixedRangeScale
  } = useGetChartDates({
    width,
    startDate,
    endDate
  });

  const chartDisplayPeriods = useChartDisplayPeriods({
    chartDisplayDateRange: fixedRangeDateRange,
    scale
  });

  const onScaleChangeHandler = useCallback(
    newValue => {
      onScaleChange(newValue);
      setFixedRangeScale(newValue);
    },
    [onScaleChange, setFixedRangeScale]
  );

  const value = useMemo(
    () => ({
      scale,
      setScale: onScaleChangeHandler,
      displayUnit,
      setDisplayUnit: onDisplayUnitChange,
      resourcePlanGroup,
      setResourcePlanGroup,
      dateRange: fixedRangeDateRange,
      chartDates,
      onPreviousClick: onPrevious,
      onNextClick: onNext,
      chartDisplayPeriods,
      isResourceRequestChartSettingsLoading
    }),
    [
      scale,
      onScaleChangeHandler,
      displayUnit,
      onDisplayUnitChange,
      resourcePlanGroup,
      setResourcePlanGroup,
      chartDates,
      onPrevious,
      onNext,
      fixedRangeDateRange,
      chartDisplayPeriods,
      isResourceRequestChartSettingsLoading
    ]
  );

  useEffect(() => {
    if (isPsaRmpBugFixes2024 && !isResourceRequestChartSettingsLoading) {
      setScale(chartSettings?.scale || DEFAULT_PERIOD_SCALE);
      setDisplayUnit(chartSettings?.displayUnit || DEFAULT_DISPLAY_UNIT);
    }
  }, [
    chartSettings,
    isPsaRmpBugFixes2024,
    isResourceRequestChartSettingsLoading
  ]);

  return (
    <DisplayUnitContextProvider
      defaultDisplayUnit={displayUnit}
      onDisplayUnitChange={onDisplayUnitChange}
    >
      <ResourceAllocationToolbarContext.Provider value={value}>
        {children}
      </ResourceAllocationToolbarContext.Provider>
    </DisplayUnitContextProvider>
  );
};
ResourceAllocationToolbarContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number
};

export const useResourceAllocationToolbarStateContext = () => {
  const {
    dateRange,
    onPreviousClick,
    onNextClick,
    displayUnit,
    setDisplayUnit,
    scale,
    setScale,
    resourcePlanGroup,
    setResourcePlanGroup,
    chartDates,
    chartDisplayPeriods,
    isResourceRequestChartSettingsLoading
  } = useContext(ResourceAllocationToolbarContext) || {};

  return {
    dateRange,
    onPreviousClick,
    onNextClick,
    displayUnit,
    setDisplayUnit,
    scale,
    setScale,
    resourcePlanGroup,
    setResourcePlanGroup,
    chartDates,
    chartDisplayPeriods,
    isResourceRequestChartSettingsLoading
  };
};
export default useResourceAllocationToolbarStateContext;
