import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import omit from 'lodash/fp/omit';
import { toRepliconDate } from '~/modules/common/dates/convert';
import projectRatesFragment from '~/modules/projects/project/RateCard/enhancers/projectRates.fragment';
import { isNullOrUndefined } from '~/modules/common/util';

const omitForbiddenFields = flow(
  omit('__typename'),
  update('currency', flow(omit('__typename')))
);

export const UPDATE_PROJECT_RATES = gql`
  mutation updateProjectRates(
    $projectId: String!
    $rates: [ProjectRateInput2!]
  ) {
    updateProjectRates: updateProjectRates(
      projectId: $projectId
      rates: $rates
    ) {
      project {
        id
        ...ProjectRates
      }
    }
  }
  ${projectRatesFragment}
`;

export const RATE_TYPE = {
  userRates: 'RESOURCE',
  projectRate: 'PROJECT',
  roleRates: 'ROLE'
};

const RATE_TYPE_KEY = {
  userRates: 'userRates',
  roleRates: 'roleRates'
};

const mapToScheduleEntryInput = ({ effectiveDate, amount }) => ({
  effectiveDate: toRepliconDate(effectiveDate),
  amount: omitForbiddenFields(amount)
});

const getRoleId = (rate, rateTypeKey) =>
  rateTypeKey === RATE_TYPE_KEY.roleRates && rate?.roleReference?.id
    ? rate.roleReference.id
    : null;

const getUserId = (rate, rateTypeKey) =>
  rateTypeKey === RATE_TYPE_KEY.userRates && rate?.userReference?.id
    ? rate.userReference.id
    : null;

const mapToProjectRatesInput = (rates, rateTypeKey) =>
  rates.map(r => ({
    rateType: RATE_TYPE[rateTypeKey],
    roleId: getRoleId(r, rateTypeKey),
    userId: getUserId(r, rateTypeKey),
    billingScheduleEntries: (r.billingScheduleEntries || []).map(
      mapToScheduleEntryInput
    ),
    costScheduleEntries: (r.costScheduleEntries || []).map(
      mapToScheduleEntryInput
    )
  }));

export const useUpdateProjectRates = () => {
  const [updateProjectRates] = useMutation(UPDATE_PROJECT_RATES);

  return {
    updateProjectRates: async ({
      projectId,
      newRates,
      rateTypeKey,
      setRatesModifier
    }) => {
      const { data } =
        (await updateProjectRates({
          variables: {
            projectId,
            rates:
              newRates && newRates.length
                ? mapToProjectRatesInput(newRates, rateTypeKey)
                : [
                    {
                      rateType: RATE_TYPE[rateTypeKey],
                      billingScheduleEntries: [],
                      costScheduleEntries: []
                    }
                  ]
          },
          refetchQueries: ['getAllProjectRates', 'getEffectiveRates'],
          awaitRefetchQueries: true
        })) || {};

      if (data && setRatesModifier) {
        const { projectRate, roleRates, userRates } =
          data.updateProjectRates && data.updateProjectRates.project
            ? data.updateProjectRates.project
            : {};
        const projectRateCount = newRates && newRates.length;
        const rateCreated =
          !isNullOrUndefined(projectRate) ||
          Boolean(roleRates.length) ||
          Boolean(userRates.length);

        setRatesModifier({
          clientRates: rateCreated,
          rateCreated,
          ratesAddedCount: 0,
          projectRateCount,
          specificRate: rateTypeKey
        });
      }

      return Promise.resolve();
    }
  };
};
