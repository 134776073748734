import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: ({ rowIndex = 0 }) => `${theme.spacing(1) + rowIndex * 26}px`,
    height: theme.spacing(3),
    userSelect: 'none'
  },
  container: {
    width: '100%',
    ...theme.typography.caption,
    cursor: 'default',
    height: `${theme.spacing(3)}px`,
    border: `2px dashed ${theme.palette.grey[700]}`,
    borderRadius: theme.spacing(0.25)
  },
  containerToBeHired: {
    borderRadius: 0,
    backgroundColor: theme.palette.resourceRequest.toBeHired.main
  },
  containerRejected: {
    borderRadius: 0,
    backgroundColor: theme.palette.resourceRequest.requestRejected.main
  },
  loadingAdjusted: {
    fontStyle: 'italic'
  }
}));

export default useStyles;
