import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, alpha, useTheme } from '@material-ui/core/styles';
import { useThemeWidth } from './summaryFilterUtil';
import GradientScrollButton from './GradientScrollButton';
import SummaryItem from './SummaryItem';
import useRefScroll from './useRefScroll';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    flexGrow: 0,
    flexShrink: 1,
    padding: 0,
    margin: theme.spacing(0, 0, 0, 2),
    minHeight: theme.spacing(5),
    opacity: 1,
    overflow: 'hidden'
  },
  scroller: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    overflowY: 'hidden',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  scrollButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    background: alpha(theme.palette.common.white, 0.6)
  },
  scrollButtonLeft: {
    left: 0
  },
  scrollButtonRight: {
    right: 0
  }
}));

export const SummaryItemsTab = ({
  isValueLoading,
  summaryItems,
  allSelected,
  summaryKey,
  showValue,
  onChange,
  value
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const tabWidth = useThemeWidth(theme);
  const scrollerRef = useRef();
  const [scrollLeft, scrollRight] = useRefScroll(scrollerRef);

  const handleScrollLeftClick = useCallback(() => {
    if (scrollerRef.current)
      scrollerRef.current.scrollBy(tabWidth * 1.5 * -8, 0);
  }, [tabWidth]);
  const handleScrollRightClick = useCallback(() => {
    if (scrollerRef.current)
      scrollerRef.current.scrollBy(tabWidth * 1.5 * 8, 0);
  }, [tabWidth]);

  const handleItemClick = useCallback(
    (event, key) => {
      if (onChange) onChange(event, value, key);
    },
    [onChange, value]
  );

  return (
    <div className={classes.root}>
      <div className={classes.scroller} ref={scrollerRef}>
        {summaryItems.map((summaryItem, index) => (
          <SummaryItem
            showValue={showValue}
            key={summaryItem.key}
            summaryItem={summaryItem}
            onClick={handleItemClick}
            selected={!!(allSelected || summaryKey === summaryItem.key)}
            first={index === 0}
            last={summaryItems.length - index === 1}
            isValueLoading={isValueLoading}
          />
        ))}
        {scrollLeft > 0 ? (
          <GradientScrollButton
            direction="left"
            orientation="horizontal"
            visible
            className={classNames(
              classes.scrollButton,
              classes.scrollButtonLeft
            )}
            onClick={handleScrollLeftClick}
          />
        ) : null}
        {scrollRight > 0 ? (
          <GradientScrollButton
            direction="right"
            orientation="horizontal"
            visible
            className={classNames(
              classes.scrollButton,
              classes.scrollButtonRight
            )}
            onClick={handleScrollRightClick}
          />
        ) : null}
      </div>
    </div>
  );
};

SummaryItemsTab.propTypes = {
  allSelected: PropTypes.bool,
  summaryKey: PropTypes.string,
  onChange: PropTypes.func,
  summaryItems: PropTypes.array,
  value: PropTypes.string,
  showValue: PropTypes.bool,
  isValueLoading: PropTypes.bool
};

export default SummaryItemsTab;
