import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest
    }),
    listStyleType: 'none'
  },
  titleLabel: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary
  },
  titleButtonLabel: {
    flexGrow: 1,
    flexShrink: 1
  },
  content: {
    margin: theme.spacing(1, 0, 0, 0),
    padding: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

const ExpandableRequestedAmount = ({
  className,
  children,
  isItemExpanded = false,
  expandableButtonLabel
}) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(isItemExpanded);
  const handleClick = useCallback(() => setIsExpanded(expanded => !expanded), [
    setIsExpanded
  ]);

  const moreIcon = useMemo(
    () => (
      <ExpandMoreIcon
        className={classNames(classes.expand, {
          [classes.expandOpen]: isExpanded
        })}
      />
    ),
    [isExpanded, classes.expand, classes.expandOpen]
  );

  return (
    <ul className={classNames(classes.root, className)}>
      <li>
        <ButtonBase onClick={handleClick} component="h4">
          {moreIcon}
          <span
            className={classNames(classes.titleLabel, classes.titleButtonLabel)}
          >
            {expandableButtonLabel}
          </span>
        </ButtonBase>
      </li>
      <Collapse component="li" in={isExpanded} timeout="auto" unmountOnExit>
        <div className={classes.content}>{children}</div>
      </Collapse>
    </ul>
  );
};

ExpandableRequestedAmount.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isItemExpanded: PropTypes.bool,
  expandableButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default ExpandableRequestedAmount;
