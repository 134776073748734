import React from 'react';
import { FormattedMessage } from 'react-intl';

const dayNames = [
  <FormattedMessage key="monday" id="dayOfWeek.monday" />,
  <FormattedMessage key="tuesday" id="dayOfWeek.tuesday" />,
  <FormattedMessage key="wednesday" id="dayOfWeek.wednesday" />,
  <FormattedMessage key="thursday" id="dayOfWeek.thursday" />,
  <FormattedMessage key="friday" id="dayOfWeek.friday" />,
  <FormattedMessage key="saturday" id="dayOfWeek.saturday" />,
  <FormattedMessage key="sunday" id="dayOfWeek.sunday" />
];

export const weekUris = [
  'urn:replicon:day-of-week:monday',
  'urn:replicon:day-of-week:tuesday',
  'urn:replicon:day-of-week:wednesday',
  'urn:replicon:day-of-week:thursday',
  'urn:replicon:day-of-week:friday',
  'urn:replicon:day-of-week:saturday',
  'urn:replicon:day-of-week:sunday'
];

export const getDayNameByLuxonWeekdayIndex = index => {
  if (index >= 1 && index <= 7) return dayNames[index - 1];

  throw new Error(`index ${index} out of range`);
};

export const getLuxonWorkWeekStart = workWeekStartUri => {
  const weekStart = weekUris.indexOf(workWeekStartUri) + 1;
  const weekEnd = ((weekStart - 2 + 7) % 7) + 1;

  return { weekStart, weekEnd };
};

export const getUserPreferenceWeekStart = (me, date) => {
  const weekStartUri = me?.workWeekStartDay?.uri;
  const userPreferenceWeek = getLuxonWorkWeekStart(weekStartUri ?? weekUris[0]);

  let weekStart = date;

  while (weekStart.weekday !== userPreferenceWeek.weekStart) {
    weekStart = weekStart.minus({ days: 1 });
  }

  return weekStart.startOf('day');
};

export const getUserPreferenceWeekEnd = (me, date) => {
  const weekStartUri = me?.workWeekStartDay?.uri;
  const userPreferenceWeek = getLuxonWorkWeekStart(weekStartUri ?? weekUris[0]);
  let weekEnd = date;

  while (weekEnd.weekday !== userPreferenceWeek.weekEnd) {
    weekEnd = weekEnd.plus({ days: 1 });
  }

  return weekEnd.endOf('day');
};

export const getUserPreferenceEndOfWeekDate = (weekStartUri, date) => {
  const userPreferenceWeek = getLuxonWorkWeekStart(weekStartUri ?? weekUris[0]);
  let weekEnd = date;

  while (weekEnd.weekday !== userPreferenceWeek.weekEnd) {
    weekEnd = weekEnd.plus({ days: 1 });
  }

  return weekEnd.startOf('day');
};
