import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TaskNotDoneOverrideIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12 2 C 9.963530145453502 2 8.070959653682177 2.613467099817711 6.490234375 3.66015625 L 13.5 10.669921875 L 17.58984375 6.580078125 L 19 8 L 14.9140625 12.0859375 L 20.33984375 17.509765625 C 21.38653290018229 15.92904034631782 22 14.0364698545465 22 12 C 22 6.480000000000003 17.52 2 12 2 z M 3.515625 3.515625 L 2.099609375 4.9296875 L 3.66015625 6.490234375 C 2.613377899711192 8.070870453575656 1.999999999999999 9.963530145453504 2 12 C 2 17.52 6.480000000000001 22 12 22 C 14.0364698545465 22 15.92912954642434 21.38662210028881 17.509765625 20.33984375 L 19.0703125 21.900390625 L 20.484375 20.484375 L 19.0703125 19.0703125 L 13.5 13.5 L 12.0859375 12.0859375 L 4.9296875 4.9296875 L 3.515625 3.515625 z M 6.41015625 10.58984375 L 10 14.169921875 L 10.669921875 13.5 L 12.0859375 14.9140625 L 10 17 L 5 12 L 6.41015625 10.58984375 z " />
    </>
  </SvgIcon>
));

export default TaskNotDoneOverrideIcon;
