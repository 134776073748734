import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import BulkProjectStatusChangeProgressDialogContent from './BulkProjectStatusChangeProgressDialogContent';
import BulkProjectStatusChangeDialogContent from './BulkProjectStatusChangeDialogContent';

const confirmationDialogRole = {
  role: 'presentation'
};

export const BulkProjectStatusChangeDialog = ({
  open,
  onClose,
  projects: selectedProjects,
  onBatchComplete,
  bulkUpdateProjectState,
  setBulkUpdateProjectState
}) => {
  const isPsaPrpProjectStatusChangeNotificationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpProjectStatusChangeNotificationEnabled'
  });
  const [projectWorkflowState, setProjectWorkflowState] = useState(null);
  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });
  const { formatMessage } = useIntl();

  const projectsEditPermission = (selectedProjects || []).filter(project =>
    project.permittedActionUris.includes(
      'urn:replicon:project-action:edit-project'
    )
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={confirmationDialogRole}
    >
      <DialogTitle>
        {formatMessage({
          id: 'bulkProjectEditStatus.editStatus'
        })}
      </DialogTitle>
      {(isPsaPrpProjectStatusChangeNotificationEnabled &&
        (bulkUpdateProjectState.inProgress ||
          bulkUpdateProjectState.complete)) ||
      batchState.batchId ? (
        <BulkProjectStatusChangeProgressDialogContent
          onClose={onClose}
          batchId={batchState.batchId}
          batchState={batchState}
          setBatchState={setBatchState}
          itemCounts={projectsEditPermission.length}
          onBatchComplete={onBatchComplete}
          projectWorkflowState={projectWorkflowState}
          bulkUpdateProjectState={bulkUpdateProjectState}
          setBulkUpdateProjectState={setBulkUpdateProjectState}
        />
      ) : (
        <BulkProjectStatusChangeDialogContent
          onClose={onClose}
          projects={selectedProjects}
          batchState={batchState}
          setBatchState={setBatchState}
          projectWorkflowState={projectWorkflowState}
          setProjectWorkflowState={setProjectWorkflowState}
          bulkUpdateProjectState={bulkUpdateProjectState}
          setBulkUpdateProjectState={setBulkUpdateProjectState}
        />
      )}
    </Dialog>
  );
};

BulkProjectStatusChangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  bulkUpdateProjectState: PropTypes.object.isRequired,
  setBulkUpdateProjectState: PropTypes.func.isRequired
};

export default BulkProjectStatusChangeDialog;
