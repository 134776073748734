import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { NoDataItem } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 3, 4, 3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(8)
  }
}));

export const NoData = ({ message, dataQeId }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <NoDataItem dataQeId={dataQeId}>{message}</NoDataItem>
    </Paper>
  );
};

NoData.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dataQeId: PropTypes.string
};

export default NoData;
