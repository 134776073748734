import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(0, 0, 1, 0)
  },
  roleName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const RoleItem = ({ role, dataQeId }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.root}>
      <div data-qe-id={dataQeId} className={classes.roleName}>
        {role.name}
      </div>
    </ListItem>
  );
};

RoleItem.propTypes = {
  role: PropTypes.object.isRequired,
  dataQeId: PropTypes.string
};

export default RoleItem;
