import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineOppositeContent
} from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Typography, DialogContent } from '@material-ui/core';
import { compareDateObjects } from '~/modules/common/dates/compare';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import TimelineCalendarContent from './TimelineCalendarContent';
import { RoleScheduleFormRows } from './RoleScheduleFormRows';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    },
    padding: theme.spacing(1, 2)
  },
  oppositeContent: {
    flex: '0'
  },
  calendarContent: {
    flex: '0',
    flexBasis: '120px'
  },
  connector: {
    backgroundColor: theme.palette.primary.main
  }
}));

export const RoleScheduleFormContent = ({
  classes: classesOverride,
  form,
  form: {
    values: { schedule: schedules },
    setValues
  },
  defaultAddValue,
  remove: removeSchedule,
  push: pushSchedule,
  fieldArrayName,
  permittedActions,
  editable
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverride });

  const isMobile = useIsBreakpointDown('xs');

  const onScheduleAdd = useCallback(() => {
    const dates = schedules
      .map(schedule => schedule.effectiveDate)
      .filter(date => date);

    const lastDate = dates[dates.length - 1];

    const lastSchedule = schedules[schedules.length - 1];
    const { year, month, day } = DateTime.local().toObject();

    const effectiveDate =
      compareDateObjects(lastDate, { year, month, day }) === -1
        ? { year, month, day }
        : lastDate
        ? DateTime.fromObject(lastDate)
            .plus({
              days: 1
            })
            .toObject()
        : { year, month, day };

    pushSchedule({
      ...(lastSchedule
        ? { projectRoles: lastSchedule.projectRoles }
        : { ...defaultAddValue }),
      effectiveDate
    });
  }, [defaultAddValue, pushSchedule, schedules]);

  if (schedules.length === 0) {
    pushSchedule(defaultAddValue);
  }

  const mappedSchedules = ((schedules.length > 0 && schedules) || []).map(
    (_, scheduleIndex) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <TimelineItem key={`schedule-${scheduleIndex}`}>
          {!isMobile ? (
            <TimelineOppositeContent className={classes.calendarContent}>
              <TimelineCalendarContent
                schedules={schedules}
                setValues={setValues}
                scheduleIndex={scheduleIndex}
                editable={editable}
              />
            </TimelineOppositeContent>
          ) : (
            <TimelineOppositeContent
              className={classes.oppositeContent}
            ></TimelineOppositeContent>
          )}
          {scheduleIndex > 0 ? (
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector className={classes.connector} />
            </TimelineSeparator>
          ) : (
            <TimelineDot color="primary" />
          )}
          <RoleScheduleFormRows
            scheduleIndex={scheduleIndex}
            form={form}
            removeSchedule={removeSchedule}
            fieldArrayName={fieldArrayName}
            permittedActions={permittedActions}
            editable={editable}
          />
        </TimelineItem>
      );
    }
  );

  return (
    <DialogContent className={classes.root}>
      <Timeline align="left">
        {editable && (
          <TimelineItem>
            {!isMobile ? (
              <TimelineOppositeContent
                className={classes.calendarContent}
              ></TimelineOppositeContent>
            ) : (
              <TimelineOppositeContent
                className={classes.oppositeContent}
              ></TimelineOppositeContent>
            )}
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Button onClick={onScheduleAdd}>
                <Typography variant="p">
                  {formatMessage({ id: 'rolesForm.newRoleAsOf' })}
                </Typography>
              </Button>
            </TimelineContent>
          </TimelineItem>
        )}
        {mappedSchedules.reverse()}
      </Timeline>
    </DialogContent>
  );
};

RoleScheduleFormContent.propTypes = {
  classes: PropTypes.object,
  defaultAddValue: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  fieldArrayName: PropTypes.string,
  permittedActions: PropTypes.array.isRequired,
  form: PropTypes.object,
  editable: PropTypes.bool.isRequired
};

export default RoleScheduleFormContent;
