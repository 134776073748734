import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, connect } from 'formik';

import { FormattedMessage } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';

import AddIconButton from '~/modules/common/components/AddIconButton';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles({
  addIconButton: {
    marginRight: 'auto'
  }
});

export const AddTagButton = ({ push }) => {
  const classes = useStyles();
  const addTag = useCallback(() => push({}), [push]);

  return (
    <AddIconButton
      data-qe-id="addTagButton"
      className={classes.addIconButton}
      onClick={addTag}
    >
      <FormattedMessage id="button.add" />
    </AddIconButton>
  );
};

AddTagButton.propTypes = {
  push: PropTypes.func.isRequired
};

export const CancelButton = connect(({ formik, onClick }) => {
  const onCancelClick = useCallback(() => {
    onClick();
    formik.resetForm();
  }, [formik, onClick]);

  return (
    <Button onClick={onCancelClick} data-qe-id="EditCard_Cancel">
      <FormattedMessage id="button.cancel" />
    </Button>
  );
});

const TagsCardActions = ({ onCancel, onSave, saveable, isSaving }) => (
  <>
    <FieldArray name="tags" component={AddTagButton} />
    <CancelButton onClick={onCancel} />
    <LoadingButton
      color="primary"
      onClick={onSave}
      disabled={!saveable || isSaving}
      isLoading={isSaving}
      data-qe-id="EditCard_Save"
    >
      <FormattedMessage id="button.save" />
    </LoadingButton>
  </>
);

TagsCardActions.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  saveable: PropTypes.bool,
  isSaving: PropTypes.bool
};

export default TagsCardActions;
