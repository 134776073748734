import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProjectManagementType } from '~/types';

export const projectTypeTabs = ({ managed, unmanaged } = {}) => [
  {
    key: ProjectManagementType.Managed,
    label: <FormattedMessage id="projectTypes.managed" />,
    value: managed,
    'data-qe-id': 'ManagedToggleButton'
  },
  {
    key: ProjectManagementType.Unmanaged,
    label: <FormattedMessage id="projectTypes.unmanaged" />,
    value: unmanaged,
    'data-qe-id': 'UnmanagedToggleButton'
  }
];
