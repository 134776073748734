import React, { useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@mui/material/ToggleButton';
import { PropTypes } from 'prop-types';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Skeleton } from '@material-ui/lab';
import { SummaryCardHelp } from '~/modules/common/components';
import { PROJECT_PROGRESS_TYPE_ENUM } from '~/modules/projects/project/common/enums/projectProgressType';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    '& button': {
      padding: theme.spacing(1.375),
      height: theme.spacing(4),
      width: '50%',
      fontFamily: theme.typography.button.fontFamily,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1)
      }
    }
  }
}));

const ProgressSummaryChartHelp = ({ projectSummaryChartProps }) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    featureFlags: { isPsaProjectRiskEnabled, isPsaPrpRiskScoreEnabled }
  } = useMeContext();

  const {
    showCostContent,
    projectProgressCardSettingsLoading,
    projectProgressView,
    saveProjectProgressCardSetting
  } = projectSummaryChartProps;

  const toolTipContent =
    showCostContent && projectProgressView === PROJECT_PROGRESS_TYPE_ENUM.COST
      ? costContent({ intl, isPsaProjectRiskEnabled })
      : hourContent({
          intl,
          isPsaProjectRiskEnabled,
          isPsaPrpRiskScoreEnabled
        });

  const handleChange = useCallback(
    async (_, selectedView) => {
      if (selectedView !== null) {
        await saveProjectProgressCardSetting(selectedView);
      }
    },
    [saveProjectProgressCardSetting]
  );

  return (
    <>
      {showCostContent ? (
        <>
          {projectProgressCardSettingsLoading ? (
            <>
              <Skeleton variant="text" width={120} height={50} />
            </>
          ) : (
            <>
              <ToggleButtonGroup
                classes={classes}
                size="small"
                value={projectProgressView}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton
                  data-qe-id="ProgressChart_Hours"
                  value={PROJECT_PROGRESS_TYPE_ENUM.HOUR}
                >
                  <FormattedMessage id="dashboardProgressChart.hours" />
                </ToggleButton>
                <ToggleButton
                  data-qe-id="ProgressChart_Cost"
                  value={PROJECT_PROGRESS_TYPE_ENUM.COST}
                >
                  <FormattedMessage id="dashboardProgressChart.cost" />
                </ToggleButton>
              </ToggleButtonGroup>
              <SummaryCardHelp content={toolTipContent} />
            </>
          )}
        </>
      ) : (
        <SummaryCardHelp content={toolTipContent} />
      )}
    </>
  );
};

const hourContent = ({
  intl,
  isPsaProjectRiskEnabled,
  isPsaPrpRiskScoreEnabled
}) => {
  const content = [
    {
      title: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.budgetBurned'
      }),
      message: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.budgetBurnedAlert'
      })
    },
    {
      title: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.scopeCompleted'
      }),
      message: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.scopeCompletedAlert'
      })
    },
    {
      title: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.variation'
      }),
      message: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.variationAlert'
      })
    }
  ];

  if (isPsaProjectRiskEnabled) {
    if (isPsaPrpRiskScoreEnabled) {
      content.push({
        title: intl.formatMessage({
          id: 'projectProgressSummaryChartHelp.projectRiskScore'
        }),
        message: intl.formatMessage({
          id: 'projectProgressSummaryChartHelp.projectRiskScoreAlert'
        })
      });
    } else {
      content.push({
        title: intl.formatMessage({
          id: 'projectProgressSummaryChartHelp.projectRisk'
        }),
        message: intl.formatMessage({
          id: 'projectProgressSummaryChartHelp.projectRiskAlert'
        })
      });
    }
  }

  return content;
};

const costContent = ({ intl, isPsaProjectRiskEnabled }) => {
  const content = [
    {
      title: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.budgetBurned'
      }),
      message: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.costBudgetBurnedAlert'
      })
    },
    {
      title: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.scopeCompleted'
      }),
      message: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.costScopeCompletedAlert'
      })
    },
    {
      title: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.variation'
      }),
      message: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.costVariationAlert'
      })
    }
  ];

  if (isPsaProjectRiskEnabled) {
    content.push({
      title: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.projectRisk'
      }),
      message: intl.formatMessage({
        id: 'projectProgressSummaryChartHelp.projectRiskAlert'
      })
    });
  }

  return content;
};

ProgressSummaryChartHelp.propTypes = {
  projectSummaryChartProps: PropTypes.object
};

export default ProgressSummaryChartHelp;
