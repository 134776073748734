import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  removeAllButton: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2)
    },
    [theme.breakpoints.up(400)]: {
      whiteSpace: 'nowrap'
    }
  },
  loadingButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-2),
      marginLeft: theme.spacing(0.5)
    }
  }
}));

export const RateCardActionComponent = ({
  onRemove,
  actionLabel,
  saveable,
  isSaving,
  onCancel,
  onSave,
  validateForm
}) => {
  const classes = useStyles();

  const handleSave = useCallback(async () => {
    if (validateForm) {
      const errors = await validateForm();

      if (Object.keys(errors).length) return;
    }
    onSave();
  }, [onSave, validateForm]);

  return (
    <Grid item xs={12} container spacing={0}>
      <Grid
        item
        xs={6}
        sm={7}
        md={6}
        container
        justifyContent="flex-start"
        spacing={0}
      >
        <Button
          color="secondary"
          onClick={onRemove}
          className={classes.removeAllButton}
        >
          <FormattedMessage id={`rateCard.remove.${actionLabel}`} />
        </Button>
      </Grid>

      <Grid
        item
        xs={6}
        sm={5}
        md={6}
        container
        justifyContent="flex-end"
        spacing={0}
      >
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          className={classes.loadingButton}
          disabled={!saveable || isSaving}
          isLoading={isSaving}
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
RateCardActionComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  saveable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  actionLabel: PropTypes.string,
  validateForm: PropTypes.func
};

export default RateCardActionComponent;
