import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, makeStyles } from '@material-ui/core';
import CreatableProgramOption from './CreatableProgramOption';

const useStyles = makeStyles(theme => ({
  primary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const ProgramDropdownItem = ({ option, onCreate }) => {
  const { isCreatable } = option;

  const classes = useStyles();

  return isCreatable ? (
    <CreatableProgramOption option={option} onCreate={onCreate} />
  ) : (
    <ListItemText primary={option.label} classes={classes} />
  );
};

ProgramDropdownItem.propTypes = {
  option: PropTypes.object,
  onCreate: PropTypes.func
};

export default ProgramDropdownItem;
