import React, { memo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DeleteGroup } from './renderers/DeleteGroup';

const CellRender = memo(
  ({
    index,
    field,
    column,
    record,
    component: Component,
    dataQeId,
    nextRecord,
    previousRecord,
    focusOnLastRow,
    setFocusOnLastRow,
    readOnly,
    loadingMore
  }) => (
    <Component
      index={index}
      field={field}
      column={column}
      record={record}
      dataQeId={dataQeId}
      nextRecord={nextRecord}
      previousRecord={previousRecord}
      focusOnLastRow={focusOnLastRow}
      setFocusOnLastRow={setFocusOnLastRow}
      readOnly={readOnly}
      loadingMore={loadingMore}
    />
  )
);

CellRender.propTypes = {
  index: PropTypes.number,
  field: PropTypes.string,
  column: PropTypes.object,
  nextRecord: PropTypes.object,
  previousRecord: PropTypes.object,
  record: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  dataQeId: PropTypes.string,
  focusOnLastRow: PropTypes.bool,
  setFocusOnLastRow: PropTypes.func,
  readOnly: PropTypes.bool,
  loadingMore: PropTypes.bool
};

export const RateListRowRender = memo(
  ({
    record,
    nextRecord,
    previousRecord,
    classes,
    columnStyles,
    columns,
    rowIndex,
    openDeleteDialog,
    onDeleteGroup,
    focusOnLastRow,
    setFocusOnLastRow,
    readOnly,
    loadingMore
  }) => {
    let showBorder = false;

    return (
      <TableRow
        key={record.id}
        className={classes.tableRow}
        data-qe-id={rowIndex}
      >
        {Object.keys(columns).map((field, index) => {
          const dataQeId = `${record.id}_${field}`;

          showBorder =
            (columns[field].isDimensionColumns &&
              (showBorder ||
                (nextRecord && record[field].id !== nextRecord[field].id))) ||
            (!columns[field].isDimensionColumns && record.isInitialRate);

          return (
            <TableCell
              key={`${record.id}:${field}`}
              data-qe-id={dataQeId}
              className={classNames(
                classes.tableCell,
                columns[field].className,
                {
                  [classes.groupLastRowCell]: showBorder
                }
              )}
              style={columnStyles[field]}
              align={columns[field].align}
              field={field}
            >
              <CellRender
                component={columns[field].render}
                field={field}
                column={columns[field]}
                record={record}
                nextRecord={nextRecord}
                previousRecord={previousRecord}
                index={rowIndex - 1}
                dataQeId={dataQeId}
                focusOnLastRow={focusOnLastRow}
                setFocusOnLastRow={setFocusOnLastRow}
                readOnly={readOnly}
                loadingMore={loadingMore}
              />
            </TableCell>
          );
        })}
        <TableCell
          key="delete-group"
          className={classes.deleteGroupCell}
          rowSpan={record.totalEntries}
        >
          {!readOnly &&
          previousRecord &&
          previousRecord.groupId !== record.groupId ? (
            <DeleteGroup
              field="deleteGroup"
              record={record}
              openDeleteDialog={openDeleteDialog}
              onDeleteGroup={onDeleteGroup}
            />
          ) : null}
        </TableCell>
      </TableRow>
    );
  }
);

RateListRowRender.propTypes = {
  record: PropTypes.any,
  nextRecord: PropTypes.object,
  previousRecord: PropTypes.object,
  columnStyles: PropTypes.object,
  columns: PropTypes.object,
  classes: PropTypes.object,
  rowIndex: PropTypes.number,
  openDeleteDialog: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  focusOnLastRow: PropTypes.bool,
  setFocusOnLastRow: PropTypes.func,
  readOnly: PropTypes.bool,
  loadingMore: PropTypes.bool
};

export default RateListRowRender;
