/* eslint-disable react/jsx-max-depth */
import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useDialogState, useMenuState } from '~/modules/common/hooks';
import AddCertificateDialog from '../Certificates/AddCertificateDialog';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.main,
    padding: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
    fontStyle: 'italic',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[600], 0.5)
    }
  },
  menuItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: 0,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  certificates: {
    borderBottom: `1px solid ${theme.palette.table.border}`
  },
  addCertificateMenu: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: 0
  },
  addCertificate: {
    padding: 0,
    textTransform: 'none',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main
  }
}));

export const SkillCertificateAssignment = ({
  classes: classesOverride,
  certificates = [],
  userDetails,
  dataQeId,
  onCertificateUpdate
}) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    open: dialogOpen,
    openDialog: onAddCertificate,
    closeDialog: onCancel
  } = useDialogState(false);

  const {
    anchorEl,
    onMenuClose: onClose,
    onMenuClick: onOpen
  } = useMenuState();

  const onAdd = useCallback(
    event => {
      onCertificateUpdate(event.target.id);
      onClose();
    },
    [onClose, onCertificateUpdate]
  );

  return (
    <>
      <Button
        data-qe-id={`${dataQeId}_AddButton`}
        className={classes.button}
        aria-owns={anchorEl ? 'certificates-menu' : undefined}
        aria-haspopup="true"
        color="primary"
        variant="contained"
        onClick={onOpen}
      >
        <FormattedMessage id="skillInfoCard.addCertificate" />
      </Button>
      <Menu
        id="certificates-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        className={classes.certificates}
      >
        {certificates.map(cert => (
          <MenuItem
            onClick={onAdd}
            className={classes.menuItem}
            id={cert.id}
            key={cert.id}
          >
            {cert.name}
          </MenuItem>
        ))}
        <MenuItem className={classes.addCertificateMenu}>
          <Button className={classes.addCertificate} onClick={onAddCertificate}>
            <FormattedMessage id="skillInfoCard.addNewCertificate" />
          </Button>
        </MenuItem>
      </Menu>
      <AddCertificateDialog
        open={dialogOpen}
        onCancel={onCancel}
        userUri={userDetails.uri}
      />
    </>
  );
};

SkillCertificateAssignment.propTypes = {
  classes: PropTypes.object,
  certificates: PropTypes.array,
  userDetails: PropTypes.object,
  dataQeId: PropTypes.string,
  onCertificateUpdate: PropTypes.func
};

export default SkillCertificateAssignment;
