import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Decimal } from '~/modules/common/components';
import useStyles from './useStyles';

export const AvailabilityPeriodTooltipContent = ({
  resourceCount,
  availableFte,
  availableHours,
  availabilityPercentage,
  allocatedFte,
  allocatedHours,
  allocatedPercentage,
  holidayFte,
  holidayHours,
  holidayPercentage,
  timeOffFte,
  timeOffHours,
  timeOffPercentage,
  overAllocatedFte,
  overAllocatedHours,
  overAllocatedPercentage,
  scheduledHours
}) => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      <li className={classNames(classes.item, classes.header)}>
        <span className={classes.label}></span>
        <span className={classNames(classes.value, classes.fte)}>
          <FormattedMessage id="resourceAvailabilityCard.fte" />
        </span>
        <span className={classNames(classes.value, classes.hours)}>
          <FormattedMessage id="resourceAvailabilityCard.hours" />
        </span>
        <span className={classNames(classes.value, classes.percent)}></span>
      </li>
      <li className={classes.item}>
        <span className={classes.label}>
          <FormattedMessage id="resourceAvailabilityCard.totalResources" />
        </span>

        <span className={classes.value}>
          {resourceCount && (
            <Decimal value={resourceCount} fixedDecimalScale={false} />
          )}
        </span>

        <span className={classes.value}>
          <Decimal value={scheduledHours} />
        </span>
        <span className={classes.percent} />
      </li>

      {holidayHours !== 0 && (
        <li className={classes.item}>
          <span className={classes.label}>
            <FormattedMessage id="resourceAvailabilityCard.holiday" />
          </span>
          <span className={classes.value}>
            <Decimal value={holidayFte} fixedDecimalScale={false} />
          </span>
          <span className={classes.value}>
            <Decimal value={holidayHours} />
          </span>
          <span className={classes.value}>
            <Decimal
              value={holidayPercentage}
              suffix="%"
              fixedDecimalScale={false}
            />
          </span>
        </li>
      )}
      {timeOffHours !== 0 && (
        <li className={classes.item}>
          <span className={classes.label}>
            <FormattedMessage id="resourceAvailabilityCard.timeOff" />
          </span>
          <span className={classes.value}>
            <Decimal value={timeOffFte} fixedDecimalScale={false} />
          </span>
          <span className={classes.value}>
            <Decimal value={timeOffHours} />
          </span>
          <span className={classes.value}>
            <Decimal
              value={timeOffPercentage}
              suffix="%"
              fixedDecimalScale={false}
            />
          </span>
        </li>
      )}
      <li className={classes.item}>
        <span className={classes.label}>
          <FormattedMessage id="resourceAvailabilityCard.allocated" />
        </span>
        <span className={classes.value}>
          <Decimal
            value={allocatedFte + overAllocatedFte}
            fixedDecimalScale={false}
          />
        </span>
        <span className={classes.value}>
          <Decimal value={allocatedHours + overAllocatedHours} />
        </span>
        <span className={classes.value}>
          <Decimal
            value={allocatedPercentage + overAllocatedPercentage}
            suffix="%"
            fixedDecimalScale={false}
          />
        </span>
      </li>

      <li className={classNames(classes.item, classes.fittingAllocation)}>
        <span className={classes.label}>
          <FormattedMessage id="resourceAvailabilityCard.fittingAllocation" />
        </span>
        <span className={classes.value}>
          <Decimal value={allocatedFte} fixedDecimalScale={false} />
        </span>
        <span className={classes.value}>
          <Decimal value={allocatedHours} />
        </span>
        <span className={classes.value}>
          <Decimal
            value={allocatedPercentage}
            suffix="%"
            fixedDecimalScale={false}
          />
        </span>
      </li>
      {overAllocatedHours !== 0 && (
        <li className={classes.item}>
          <span className={classes.label}>
            <FormattedMessage id="resourceAvailabilityCard.overAllocated" />
          </span>
          <span className={classes.value}>
            <Decimal value={overAllocatedFte} fixedDecimalScale={false} />
          </span>
          <span className={classes.value}>
            <Decimal value={overAllocatedHours} />
          </span>
          <span className={classes.value}>
            <Decimal
              value={overAllocatedPercentage}
              suffix="%"
              fixedDecimalScale={false}
            />
          </span>
        </li>
      )}

      <li className={classes.item}>
        <span className={classes.label}>
          <FormattedMessage id="resourceAvailabilityCard.availability" />
        </span>
        <span className={classes.value}>
          <Decimal value={availableFte} fixedDecimalScale={false} />
        </span>
        <span className={classes.value}>
          <Decimal value={availableHours} />
        </span>
        <span className={classes.value}>
          <Decimal
            value={availabilityPercentage}
            suffix="%"
            fixedDecimalScale={false}
          />
        </span>
      </li>
    </ul>
  );
};

AvailabilityPeriodTooltipContent.propTypes = {
  resourceCount: PropTypes.number,
  availableFte: PropTypes.number.isRequired,
  availableHours: PropTypes.number.isRequired,
  availabilityPercentage: PropTypes.number.isRequired,
  allocatedFte: PropTypes.number.isRequired,
  allocatedHours: PropTypes.number.isRequired,
  allocatedPercentage: PropTypes.number.isRequired,
  overAllocatedFte: PropTypes.number.isRequired,
  overAllocatedHours: PropTypes.number.isRequired,
  overAllocatedPercentage: PropTypes.number.isRequired,
  scheduledHours: PropTypes.number.isRequired,
  holidayFte: PropTypes.number.isRequired,
  holidayHours: PropTypes.number.isRequired,
  holidayPercentage: PropTypes.number.isRequired,
  timeOffFte: PropTypes.number.isRequired,
  timeOffHours: PropTypes.number.isRequired,
  timeOffPercentage: PropTypes.number.isRequired
};

export default AvailabilityPeriodTooltipContent;
