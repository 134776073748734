import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const INVOICE_DEFAULT_TEMPLATE_QUERY = gql`
  query getInvoiceDefaultTemplate {
    invoiceDefaultTemplate {
      id
      displayText
    }
  }
`;

export const useInvoiceDefaultTemplate = () => {
  const { data, loading } = useQuery(INVOICE_DEFAULT_TEMPLATE_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const invoiceDefaultTemplate = get(data, 'invoiceDefaultTemplate', null);

  return {
    loading,
    invoiceDefaultTemplate
  };
};
export default useInvoiceDefaultTemplate;
