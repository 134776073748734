import React from 'react';
import { PropTypes } from 'prop-types';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  name: {
    fontSize: theme.typography.body2.fontSize,
    maxWidth: 250
  }
}));

export const ClientColumn = ({
  classes: classesOverride,
  record: { client = null }
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <Typography className={classes.name}>
      {client === null ? <NoValue /> : client.displayText}
    </Typography>
  );
};

ClientColumn.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object
};

const StyledClientColumn = ClientColumn;

export default StyledClientColumn;
