import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { AbbreviatedDecimal } from '~/modules/common/components';
import OverviewItemTooltipContent from './OverviewItemTooltipContent';
import OverviewItemPercentageTooltipContent from './OverviewItemPercentageTooltipContent';

export const OverviewItemTooltip = ({
  currencySymbol,
  value,
  moneySettings,
  numberSettings,
  intlKey,
  id,
  isPercentage,
  dataQeId
}) => {
  const numberFormatSettings = currencySymbol ? moneySettings : numberSettings;

  const tooltipContent = useMemo(
    () =>
      isPercentage ? (
        <OverviewItemPercentageTooltipContent
          value={value * 100}
          numberFormatSettings={numberFormatSettings}
        ></OverviewItemPercentageTooltipContent>
      ) : (
        <OverviewItemTooltipContent
          currencySymbol={currencySymbol}
          value={value}
          numberFormatSettings={numberFormatSettings}
        ></OverviewItemTooltipContent>
      ),
    [currencySymbol, value, numberFormatSettings, isPercentage]
  );

  const abbreviatedDecimalComponent = (
    <AbbreviatedDecimal
      dataQeId={dataQeId && `${dataQeId}_Tooltip`}
      value={value}
    />
  );

  const values = useMemo(
    () => ({
      currency: currencySymbol,
      [intlKey]: abbreviatedDecimalComponent
    }),
    [currencySymbol, intlKey, abbreviatedDecimalComponent]
  );

  return (
    <Tooltip title={tooltipContent}>
      <span>
        {isPercentage ? (
          <FormattedNumber
            id={id}
            value={value}
            // eslint-disable-next-line react/style-prop-object
            style="percent"
            maximumFractionDigits={numberSettings.decimalScale}
          />
        ) : (
          <FormattedMessage id={id} values={values} />
        )}
      </span>
    </Tooltip>
  );
};

OverviewItemTooltip.propTypes = {
  currencySymbol: PropTypes.string,
  value: PropTypes.number,
  moneySettings: PropTypes.shape({
    thousandSeparator: PropTypes.string,
    decimalSeparator: PropTypes.string,
    thousandsGroupStyle: PropTypes.string,
    decimalScale: PropTypes.number
  }),
  numberSettings: PropTypes.shape({
    thousandSeparator: PropTypes.string,
    decimalSeparator: PropTypes.string,
    thousandsGroupStyle: PropTypes.string,
    decimalScale: PropTypes.number
  }),
  intlKey: PropTypes.string,
  id: PropTypes.string,
  isPercentage: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default OverviewItemTooltip;
