import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  label: {
    flexBasis: theme.spacing(11),
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 2, 0, 0),
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      margin: 0
    }
  }
});

export default withStyles(styles);
