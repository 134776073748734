import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const CREATE_INVOICE_FROM_BILLING_ITEMS = gql`
  mutation createBillFromBillingItemsBatch(
    $billInput: BillInput2!
    $billFromBillingItemsBatchParameter: LineItemsFromBillingItemsBatchInput2!
  ) {
    createBillFromBillingItemsBatch: createBillFromBillingItemsBatch3(
      billInput: $billInput
      billFromBillingItemsBatchParameter: $billFromBillingItemsBatchParameter
    ) {
      batchUri
    }
  }
`;

export const useCreateInvoiceFromBillItemsBatch = ({
  setBillingBatchState
}) => {
  const [createInvoiceFromBillItemsBatch] = useMutation(
    CREATE_INVOICE_FROM_BILLING_ITEMS
  );

  return {
    createInvoiceFromBillItemsBatch: async ({
      billInput,
      billingItemColumnOption,
      billingItemSearch,
      selectedBillingItems: {
        includedUris: billingItemsIncludedUris,
        excludedUris: billingItemsExcludedUris
      }
    }) => {
      const { data } =
        (await createInvoiceFromBillItemsBatch({
          variables: {
            billInput,
            billFromBillingItemsBatchParameter: {
              billingItemColumnOption,
              billingItemSearch: {
                textSearch: billingItemSearch.text,
                userUris: billingItemSearch.userUris,
                projectUris: billingItemSearch.projectUris,
                dateRange: billingItemSearch.dateRange,
                billingItemTypes: billingItemSearch.billingItemTypes
              },
              billingItemsIncludedUris,
              billingItemsExcludedUris
            }
          },

          awaitRefetchQueries: true
        })) || {};

      const batchId = get(
        data,
        'createBillFromBillingItemsBatch.batchUri',
        null
      );

      setBillingBatchState({
        batchId,
        batchInProgress: true
      });
    }
  };
};

export default useCreateInvoiceFromBillItemsBatch;
