import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { TimelineEditorOverlay } from '~/modules/resourcing/common/components';
import {
  withAvailabilityContextProvider,
  useAvailabilityContext
} from '~/modules/resourcing/common/hooks/useAvailabilityContext';
import { deepPure } from '~/util';
import {
  useQuickAllocationContext,
  useQuickAllocationUpdateResourceAllocation
} from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import { useResourceTimelineEditorChangeHandlers } from '~/modules/resourcing/common/hooks';
import { ResourceAllocationTimelineBlocks } from '~/modules/resourcing/common/components/ResourceRequestUserAllocationBlock';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useDialogState } from '~/modules/common/hooks';
import { QaReleaseAllocationsDialog } from '../../../QaReleaseAllocationsDialog';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: -theme.spacing(0.5)
  }
}));

export const QuickAllocationChartTimelineItem2 = ({
  allocatedProject,
  chartDisplayPeriods,
  isEditEnabled,
  resourceAllocation,
  resourceUser,
  releaseResourceAllocation
}) => {
  const { project } = resourceAllocation;

  const {
    chartDates,
    dateRange: chartDisplayDateRange,
    scale,
    displayUnit
  } = useQuickAllocationContext();

  const {
    open: showReleaseResourcesDialog,
    openDialog: openReleaseResourcesDialog,
    closeDialog: closeReleaseResourcesDialog
  } = useDialogState(false);

  const isPercentageMode = displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE;

  const { setAvailabilityLoading } = useAvailabilityContext();
  const [allocationPeriodEditTarget, setAllocationPeriodEditTarget] = useState(
    null
  );

  const newPeriodEditorChangeHandlers = useResourceTimelineEditorChangeHandlers(
    {
      allocationPeriodEditTarget,
      chartDates,
      isPercentageMode,
      setAllocationPeriodEditTarget,
      resourceAllocation
    }
  );
  const classes = useStyles();

  const {
    onPatchResourceAllocation
  } = useQuickAllocationUpdateResourceAllocation();

  const onPeriodClose = useCallback(
    evt => {
      setAvailabilityLoading();
      onPatchResourceAllocation({
        project,
        allocation: {
          ...evt.values,
          requestedRoleUri: resourceAllocation.role?.uri
        },
        originalAllocation: resourceAllocation,
        openReleaseResourcesDialog
      });
    },
    [
      resourceAllocation,
      setAvailabilityLoading,
      onPatchResourceAllocation,
      project,
      openReleaseResourcesDialog
    ]
  );

  const {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  } = newPeriodEditorChangeHandlers;

  return (
    <div className={classes.root}>
      <ResourceAllocationTimelineBlocks
        allocation={resourceAllocation}
        chartDisplayPeriods={chartDisplayPeriods}
        scale={scale}
        isEditable={isEditEnabled}
        chartDisplayDateRange={chartDisplayDateRange}
        onAllocationChange={onPeriodClose}
        handleAllocationPeriodClick={handleAllocationPeriodClick}
      />
      {isEditEnabled && allocationPeriodEditTarget?.anchorEl && (
        <TimelineEditorOverlay
          allocationPeriodEditTarget={allocationPeriodEditTarget}
          chartDisplayDateRange={chartDisplayDateRange}
          handleAllocationPeriodClose={handleAllocationPeriodClose}
          onChange={onPeriodClose}
          resourceAllocation={resourceAllocation}
          setNextPeriod={setNextPeriod}
          setPreviousPeriod={setPreviousPeriod}
          isPersistedAllocation
        />
      )}
      {showReleaseResourcesDialog && (
        <QaReleaseAllocationsDialog
          allocatedProject={allocatedProject}
          user={resourceUser}
          canEditTask={project.permittedActionUris.includes(
            'urn:replicon:project-action:edit-tasks'
          )}
          onCancelClick={closeReleaseResourcesDialog}
          open={showReleaseResourcesDialog}
          onReleaseResourceAllocation={releaseResourceAllocation}
          selectedAllocationId={resourceAllocation.id}
        />
      )}
    </div>
  );
};

QuickAllocationChartTimelineItem2.propTypes = {
  chartDisplayPeriods: PropTypes.array,
  resourceAllocation: PropTypes.object.isRequired,
  releaseResourceAllocation: PropTypes.func,
  isEditEnabled: PropTypes.bool,
  resourceUser: PropTypes.object,
  allocatedProject: PropTypes.object
};

export default deepPure(
  withAvailabilityContextProvider(QuickAllocationChartTimelineItem2)
);
