export const FIXED_BID_MILESTONE_KEY_URI =
  'urn:replicon:script-key:billing-contract-clause-script-pre-requisites:milestone-task-required';

export const TIME_BILLING_KEY_URI =
  'urn:replicon:script-key:billing-contract-clause-script-pre-requisites:rate-required';

export const PRE_REQUISITE_KEY =
  'urn:replicon:script-key:billing-contract-clause-script-pre-requisites';

export const setCollection = preRequisiteValues =>
  preRequisiteValues.length ? preRequisiteValues[0]?.value?.collection : [];

export const setMilestoneTaskAvailable = (preRequisiteValues = []) => {
  const collection = setCollection(preRequisiteValues);

  return (
    collection.length &&
    collection[0]?.uri === FIXED_BID_MILESTONE_KEY_URI &&
    collection[0]?.bool
  );
};

export const setRateAvailable = preRequisiteValues => {
  const collection = setCollection(preRequisiteValues);

  return (
    collection.length &&
    collection[0]?.uri === TIME_BILLING_KEY_URI &&
    collection[0]?.bool
  );
};

export const setPrerequisites = parameters => {
  const timeBillVal = parameters.filter(param => {
    return (
      Boolean(param.value && param.value.collection) &&
      param.keyUri === PRE_REQUISITE_KEY
    );
  });

  return timeBillVal;
};
