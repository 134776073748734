import { useMemo, useState, useEffect } from 'react';
import searchPhraseFormatters from './searchPhraseFormatters';

const searchTermRegex = /((?!["'])\w+:)|[^\s"',:]+|"([^"]*)"|'([^']*)'|(,)/gm;

export const parseTerms = phrase => {
  const terms = [];
  let match;

  while ((match = searchTermRegex.exec(phrase)) !== null) {
    if (match.index === searchTermRegex.lastIndex) {
      searchTermRegex.lastIndex++;
    }

    if (match.length > 0 && match[0]) {
      terms.push(match[0]);
    }
  }

  return terms;
};

export const mapAssociatedTerms = (terms, index) => {
  const matches = [];

  let i = index;

  let shouldAppend = true;

  for (; i < terms.length && shouldAppend; i++) {
    const term = terms[i];

    if (!term) {
      continue;
    }

    if (term.endsWith(':')) {
      break;
    }

    let matchableTerm = term;

    if (matchableTerm.startsWith('"') && matchableTerm.endsWith('"')) {
      matchableTerm = matchableTerm.slice(1, -1);
    }

    matches.push(matchableTerm);

    // look ahead for a comma
    shouldAppend = i + 1 < terms.length && terms[i + 1] === ',';

    // skip next, its a comma
    if (shouldAppend) {
      i++;
    } else {
      // stop immediately without incrementing index
      break;
    }
  }

  return {
    index: i,
    matches
  };
};

export const classifySearchPhrase = phrase => {
  const criterions = {};
  const keywords = [];

  const terms = parseTerms(phrase);

  for (let i = 0; i < terms.length; i++) {
    const term = terms[i];

    if (term && !term.endsWith(':')) {
      keywords.push(term);
      continue;
    }

    const tag = term;

    i++; // move to next after tag

    const { matches, index } = mapAssociatedTerms(terms, i);

    criterions[tag] = matches;

    i = index;
  }

  return {
    keywords,
    criterions
  };
};

export const createMatchResolver = (tag, criteria, terms) =>
  Promise.all(
    terms.map(term => criteria.matchOption(criteria.options, term))
  ).then(matches => {
    const selected = matches.filter(match => !!match);

    criteria.setSelected(selected);

    return {
      tag,
      selected
    };
  });

export const makeSetSearchPhrase = ({
  searchMatchDebounce,
  setSearchMatchDebounce,
  setSearchPhraseInternal,
  setSearchCriteriaInternal
}) => criterions => value => {
  const phrase = value || '';

  let debounceTimeout = 500; // 0.5 seconds

  if (phrase === '') {
    debounceTimeout = 0; // immediate
  }

  setSearchPhraseInternal(phrase);

  if (searchMatchDebounce !== null) {
    clearTimeout(searchMatchDebounce);
  }

  const timer = setTimeout(async () => {
    const { keywords, criterions: $criterions } = classifySearchPhrase(phrase);

    const matchResolvers = Object.keys(criterions).map(tag =>
      createMatchResolver(tag, criterions[tag], $criterions[tag] || [])
    );

    const resolvedSelections = await Promise.all(matchResolvers);

    setSearchCriteriaInternal({
      keywords,
      criterions: resolvedSelections.reduce(
        (acc, { tag, selected }) => ({
          ...acc,
          [tag]: selected
        }),
        {}
      )
    });
  }, debounceTimeout);

  setSearchMatchDebounce(timer);
};

const criterionToSearchPhrase = (criterion, formatFunc) =>
  criterion.map(({ key }) => formatFunc(key)).join(', ');

export const constructSearchPhrase = ({ keywords, criterions }) => {
  const facetPhrase = Object.keys(criterions).reduce(
    (acc, tag) =>
      criterions[tag].length > 0
        ? `${acc} ${tag}${criterionToSearchPhrase(
            criterions[tag],
            searchPhraseFormatters(tag)
          )}`
        : acc,
    ''
  );

  return `${keywords.join(' ')}${facetPhrase}`;
};

export const makeSetSearchCriteria = ({
  setSearchPhraseInternal,
  setSearchCriteriaInternal
}) => criteria => {
  setSearchPhraseInternal(constructSearchPhrase(criteria));
  setSearchCriteriaInternal(criteria);
};

const useSearchState = ({
  searchCriteria,
  setSearchCriteria: setSearchCriteriaInternal
}) => {
  const $searchPhrase = constructSearchPhrase(searchCriteria);
  const [searchPhrase, setSearchPhraseInternal] = useState($searchPhrase);
  const [searchMatchDebounce, setSearchMatchDebounce] = useState(null);

  useEffect(() => {
    setSearchPhraseInternal($searchPhrase);
  }, [$searchPhrase]);

  const setSearchPhraseExternal = useMemo(
    () =>
      makeSetSearchPhrase({
        searchMatchDebounce,
        setSearchMatchDebounce,
        setSearchPhraseInternal,
        setSearchCriteriaInternal
      }),
    [
      searchMatchDebounce,
      setSearchMatchDebounce,
      setSearchPhraseInternal,
      setSearchCriteriaInternal
    ]
  );

  const setSearchCriteria = useMemo(
    () =>
      makeSetSearchCriteria({
        setSearchCriteriaInternal,
        setSearchPhraseInternal
      }),
    [setSearchCriteriaInternal, setSearchPhraseInternal]
  );

  return {
    searchPhrase,
    searchCriteria,
    setSearchCriteria,
    makeSetSearchPhrase: setSearchPhraseExternal,
    setSearchPhraseInternal
  };
};

export default useSearchState;
