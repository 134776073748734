import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlagIconSharp from '@material-ui/icons/FlagSharp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  isFlagged: {
    color: theme.palette.error.main
  },
  notFlagged: {
    opacity: 0.3
  }
}));

const FlagIcon = ({
  classes: classesOverride,
  className,
  isFlagged = false
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <FlagIconSharp
      className={classNames(
        className,
        isFlagged ? classes.isFlagged : classes.notFlagged
      )}
    />
  );
};

FlagIcon.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  isFlagged: PropTypes.bool
};

export default FlagIcon;
