export const dialogContentForDeletion = {
  title: 'removeClientDialog.title',
  content: 'removeClientDialog.content',
  action: 'removeClientDialog.action',
  cancel: 'removeClientDialog.cancel'
};

export const dialogContentForDisable = {
  title: 'disableClientDialog.title',
  content: 'disableClientDialog.content',
  secondaryContent: 'disableClientDialog.secondaryContent',
  action: 'disableClientDialog.action',
  cancel: 'disableClientDialog.cancel'
};

export default ({ canBeDeleted }) =>
  canBeDeleted ? dialogContentForDeletion : dialogContentForDisable;
