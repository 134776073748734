import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import {
  mapRepliconDateToUtcObject,
  isoStringToObject
} from '~/modules/common/dates/convert';

const sortByRoleDisplayText = rate =>
  rate.roleReference.displayText.toLowerCase();

const sortByUserDisplayText = rate =>
  rate.userReference.displayText.toLowerCase();

export const mapToProjectRates = ({
  projectRate,
  roleRates,
  userRates,
  excludePastEntries
} = {}) => ({
  projectRate: (projectRate ? [projectRate] : []).map(
    mapRateScheduleEntries(excludePastEntries)
  ),
  roleRates: orderBy(roleRates || [], [sortByRoleDisplayText], ['asc']).map(
    mapRateScheduleEntries(excludePastEntries)
  ),
  userRates: orderBy(userRates || [], [sortByUserDisplayText], ['asc']).map(
    mapRateScheduleEntries(excludePastEntries)
  )
});

export const mapToDefaultRates = details => ({
  defaultRates: mapToProjectRates({
    ...(details || {}),
    excludePastEntries: true
  })
});

export const mapToDefaultRoleRates = ({ roles, defaultRates }) => {
  const targetRoles = roles || [];

  if (defaultRates && defaultRates.roleRates) {
    const defaultRoleRatesMap = defaultRates.roleRates.reduce(
      (ratesMap, rate) => ({ ...ratesMap, [rate.id]: rate }),
      {}
    );

    return {
      defaultRoleRates: targetRoles.map(role =>
        mapRateScheduleEntries(false)(
          defaultRoleRatesMap[role.id] ? defaultRoleRatesMap[role.id] : role
        )
      )
    };
  }

  return {
    defaultRoleRates: targetRoles.map(mapRateScheduleEntries(false))
  };
};

export const sortScheduleEntries = ({
  scheduleEntries,
  excludePastEntries
}) => {
  const updatedEntries = scheduleEntries.map(entry => {
    const id = v4();

    return {
      ...entry,
      entryId: id,
      key: id,
      effectiveDate:
        entry.effectiveDate &&
        mapRepliconDateToUtcObject(isoStringToObject(entry.effectiveDate))
    };
  });

  const initialEntry = updatedEntries.filter(entry => !entry.effectiveDate);
  const sortedScheduleEntries = [
    ...orderBy(
      updatedEntries.filter(entry => entry.effectiveDate),
      ['effectiveDate'],
      ['desc']
    ),
    ...initialEntry
  ];
  const toDateKey = date => date.toFormat('yyyy-MM-dd');
  const today = toDateKey(DateTime.local());
  const isEffectiveToday = date => date && toDateKey(date) <= today;

  const currentlyEffectiveEntry =
    sortedScheduleEntries.find(
      ({ effectiveDate }) => isEffectiveToday(effectiveDate) || !effectiveDate
    ) || {};

  return excludePastEntries
    ? sortedScheduleEntries
        .map(entry =>
          entry.entryId === currentlyEffectiveEntry.entryId
            ? { ...entry, effectiveDate: null, isEffectiveAsOfToday: true }
            : entry.effectiveDate < currentlyEffectiveEntry.effectiveDate
            ? null
            : entry
        )
        .filter(entry => entry)
    : sortedScheduleEntries.map(entry =>
        entry.entryId === currentlyEffectiveEntry.entryId
          ? { ...entry, isEffectiveAsOfToday: true }
          : entry
      );
};

export const mapRateScheduleEntries = excludePastEntries => ({
  billingScheduleEntries,
  costScheduleEntries,
  effectiveBillingRate,
  ...rest
}) => {
  const id = v4();

  return {
    ...rest,
    billingScheduleEntries:
      billingScheduleEntries && billingScheduleEntries.length
        ? sortScheduleEntries({
            scheduleEntries: billingScheduleEntries,
            excludePastEntries
          })
        : [],
    costScheduleEntries:
      costScheduleEntries && costScheduleEntries.length
        ? sortScheduleEntries({
            scheduleEntries: costScheduleEntries,
            excludePastEntries
          })
        : [],
    effectiveBillingRate: effectiveBillingRate
      ? {
          ...effectiveBillingRate,
          entryId: id,
          key: id,
          effectiveDate:
            effectiveBillingRate.effectiveDate &&
            mapRepliconDateToUtcObject(
              isoStringToObject(effectiveBillingRate.effectiveDate)
            )
        }
      : null
  };
};
