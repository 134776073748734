import { PropTypes } from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { Decimal, NoValue, Percentage } from '~/modules/common/components';
import { RequestAllocatedDatesDiffView } from '~/modules/resourcing/common/components';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import useStyles, { useRequestAllocatedDatesStyles } from './useStyles';

export const MobileResourceRequestTitle = ({
  resourceRequest,
  resourceAllocations = []
}) => {
  const classes = useStyles();
  const requestAllocatedDatesClasses = useRequestAllocatedDatesStyles();
  const showQuantity = resourceRequest.quantity > 1;
  const { role } = resourceRequest;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {role ? (
          <span className={classes.roleName}>{role.displayText}</span>
        ) : (
          <NoValue className={classes.roleName} />
        )}
        {showQuantity ? (
          <>
            <span className={classes.rate}>&nbsp;&times;&nbsp;</span>
            <Decimal
              className={classes.rate}
              value={resourceRequest.quantity}
              fixedDecimalScale={false}
            />
          </>
        ) : null}
        <span className={classes.rate}>&nbsp;@&nbsp;</span>
        <Percentage
          className={classes.rate}
          value={resourceRequest.load}
          fixedDecimalScale={false}
        />
        {resourceRequest.isAdjustedLoading && '*'}
      </div>
      {resourceAllocations.length > 0 ? (
        <RequestAllocatedDatesDiffView
          classes={requestAllocatedDatesClasses}
          resourceRequest={resourceRequest}
          resourceAllocations={resourceAllocations}
        />
      ) : (
        <Typography
          className={classes.datesDiffView}
          component="div"
          variant="body2"
        >
          {shortDateRangeFormat({
            start: mapIsoStringtoUtcObject(resourceRequest.startDate),
            end: mapIsoStringtoUtcObject(resourceRequest.endDate)
          })}
        </Typography>
      )}
    </div>
  );
};

MobileResourceRequestTitle.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocations: PropTypes.array
};

export default MobileResourceRequestTitle;
