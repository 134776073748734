import { useState } from 'react';

export const useRevenueState = () => {
  const [forecastBatchState, setForecastBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });

  return {
    forecastBatchState,
    setForecastBatchState
  };
};

export default useRevenueState;
