import { AddressType, ContactType } from '~/types';

export const ADDRESS_TYPE = {
  PRIMARY: AddressType.Primary,
  BILLING: AddressType.Billing,
  OTHERS: AddressType.Others
};

export const CONTACT_TYPE = {
  PRIMARY: ContactType.Primary,
  BILLING: ContactType.Billing,
  OTHERS: ContactType.Others
};
