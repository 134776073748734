import { useCallback } from 'react';

const useTooltipTitleProps = ({
  value,
  prefix = '',
  suffix = '',
  precision = 2,
  minPrecision = 2
}) => {
  const renderText = useCallback(
    text =>
      `${prefix}${text.replace(
        new RegExp(`([.,]\\d{${minPrecision},}?)0*$`),
        '$1'
      )}${suffix}`,
    [minPrecision, prefix, suffix]
  );

  return {
    value,
    precision,
    renderText,
    fixedDecimalScale: true
  };
};

export default useTooltipTitleProps;
