import React from 'react';
import { PORTFOLIOS_DROPDOWN_DATA_VARIANT } from '~/modules/common/components/PortfoliosDropDown/enum';
import {
  useSearchableProjectPortfolios,
  useSearchableRequestPortfolios
} from '~/modules/common/components/SearchBox/Facets/PortfolioFacet/useSearchablePortfolios';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import PortfolioFacetDetails from './PortfolioFacetDetails';
import { portfolioTag } from './tag';

const createVariant = variant => (props = {}) => (
  <PortfolioFacetDetails dataVariant={variant} {...props} />
);

export const ProjectRequestPortfolioFacet = {
  tag: portfolioTag,
  makeCriteriaProvider: (tag, context) =>
    withCustomFetchCriteriaProvider(
      tag,
      context,
      useSearchableRequestPortfolios
    ),
  DetailsControl: createVariant(PORTFOLIOS_DROPDOWN_DATA_VARIANT.REQUEST)
};

export default {
  tag: portfolioTag,
  makeCriteriaProvider: (tag, context) =>
    withCustomFetchCriteriaProvider(
      tag,
      context,
      useSearchableProjectPortfolios
    ),
  DetailsControl: PortfolioFacetDetails
};
