import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NoValue } from '~/modules/common/components';

const styles = () => ({
  column: {
    whiteSpace: 'break-spaces',
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
});

export const ProjectFormatter = ({ field, record, classes }) =>
  record[field] && record[field].displayText ? (
    <Typography className={classes.column} variant="body2">
      {record[field].displayText}
    </Typography>
  ) : (
    <NoValue />
  );

ProjectFormatter.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default withStyles(styles)(ProjectFormatter);
