import React from 'react';
import { PropTypes } from 'prop-types';

import {
  DialogContent,
  DialogActions,
  makeStyles,
  Button
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { BACKGROUND_JOB_STATUS } from '~/modules/common/enums';
import { useBackgroundJobStatus } from '~/modules/common/hooks/useBackgroundJobStatus';

const useStyles = makeStyles(theme => ({
  root: {},
  backgroundMessage: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0, 0, 0)
  },
  duplicationFailed: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FDECEA'
  },
  error: { color: '#611A15' }
}));

export const CopyingDialogContent = ({ onClose, jobState }) => {
  const classes = useStyles();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { jobId } = jobState;

  const {
    status,
    result: { project, error } = {},
    hasProcessed
  } = useBackgroundJobStatus({ jobId });

  const projectSlug = project && project.slug ? project.slug : null;

  const handleCancel = React.useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const handleGoToNewProject = React.useCallback(() => {
    history.push(`/projects/${projectSlug}?edit=true`);
    if (onClose) onClose();
  }, [projectSlug, history, onClose]);

  const copySucceeded = Boolean(
    hasProcessed && status === BACKGROUND_JOB_STATUS.SUCCEEDED && projectSlug
  );

  return (
    <>
      <DialogContent className={error ? classes.error : classes.root}>
        {hasProcessed ? (
          <CopyingResult
            error={error}
            classes={classes}
            formatMessage={formatMessage}
            copySucceeded={copySucceeded}
          ></CopyingResult>
        ) : (
          <>
            <Typography variant="subtitle2" gutterBottom>
              {formatMessage({ id: 'duplicateProject.creatingProject' })}
            </Typography>
            <LinearProgress />
            <div className={classes.backgroundMessage}>
              {formatMessage({ id: 'duplicateProject.duplicationMessage' })}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          {formatMessage({ id: 'duplicateProject.close' })}
        </Button>
        {copySucceeded && (
          <Button color="primary" onClick={handleGoToNewProject}>
            {formatMessage({ id: 'duplicateProject.goToNewProject' })}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export const CopyingResult = ({
  error,
  classes,
  formatMessage,
  copySucceeded
}) =>
  copySucceeded ? (
    <Typography variant="subtitle2" gutterBottom>
      {formatMessage({ id: 'duplicateProject.duplicationComplete' })}
    </Typography>
  ) : (
    <div className={classes.duplicationFailed}>
      <CopyingFailed
        error={error}
        classes={classes}
        formatMessage={formatMessage}
      />
    </div>
  );

export const CopyingFailed = ({ classes, error, formatMessage }) => {
  const errorMessageKeys = [
    error && error.duplicateResourceRequestsError
      ? 'duplicateResourceRequestsError'
      : null,
    error && error.duplicateTasksError ? 'duplicateTasksError' : null,
    error && error.duplicateBillPlanError ? 'duplicateBillPlanError' : null
  ].filter(k => k);

  return !errorMessageKeys.length ? (
    <Typography className={classes.error} variant="body2" gutterBottom>
      {formatMessage({ id: `duplicateProject.error` })}
    </Typography>
  ) : (
    <>
      <Typography className={classes.error} variant="subtitle1" gutterBottom>
        {formatMessage({
          id: 'duplicateProject.projectDuplicatedWithErrors'
        })}
      </Typography>
      <ul>
        {errorMessageKeys.map(k => (
          <li classes={classes.error} key={k}>
            <Typography variant="body2" gutterBottom>
              {formatMessage({ id: `duplicateProject.${k}` })}
            </Typography>
          </li>
        ))}
      </ul>
    </>
  );
};

CopyingResult.propTypes = {
  copySucceeded: PropTypes.bool,
  formatMessage: PropTypes.func,
  classes: PropTypes.object,
  error: PropTypes.object
};

CopyingFailed.propTypes = {
  formatMessage: PropTypes.func,
  classes: PropTypes.object,
  error: PropTypes.object
};

CopyingDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  jobState: PropTypes.object
};

export default CopyingDialogContent;
