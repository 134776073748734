import PropTypes from 'prop-types';
import React from 'react';
import { PeriodSelector, PeriodDropdown } from '~/modules/common/components';
import {
  ResourcingDisplayUnitToggleButtonGroup,
  resourcingLegendItems,
  ResourcingLegends,
  ResourcingActualToggleButton
} from '~/modules/resourcing/common/components';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import useQuickAllocationContext from '../../hooks/useQuickAllocationContext';
import { useStyles, usePeriodDropdownStyles } from './useStyles';

const legends = [
  resourcingLegendItems.resourceRejected,
  resourcingLegendItems.pending,
  resourcingLegendItems.proposed,
  resourcingLegendItems.overAllocated,
  resourcingLegendItems.notWorking,
  resourcingLegendItems.timeoffAllocation,
  resourcingLegendItems.complete,
  resourcingLegendItems.allocatedProgress
];

const QuickAllocationUserChartToolbar = ({
  classes: classesOverride,
  setScale,
  onPreviousClick,
  onNextClick,
  setShowActualChange,
  resourceUsersLoading
}) => {
  const { displayUnit, setDisplayUnit } = useDisplayUnitContext();
  const { scale, dateRange, isShowActualEnabled } = useQuickAllocationContext();

  const classes = useStyles({ classes: classesOverride });
  const periodClasses = usePeriodDropdownStyles();

  return (
    <div className={classes.root}>
      <div className={classes.spacer2}>
        <PeriodSelector
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          dateRange={dateRange}
          isLoading={resourceUsersLoading}
        />
      </div>
      <div className={classes.spacer} />
      <ResourcingLegends legends={legends} />

      <ResourcingActualToggleButton
        isResourceActualModeEnabled={isShowActualEnabled}
        setIsResourceActualModeEnabled={setShowActualChange}
      />

      <div className={classes.toggleGroup}>
        <ResourcingDisplayUnitToggleButtonGroup
          displayUnit={displayUnit}
          setDisplayUnit={setDisplayUnit}
        />
      </div>
      <PeriodDropdown
        classes={periodClasses}
        value={scale}
        onChange={setScale}
      />
    </div>
  );
};

QuickAllocationUserChartToolbar.propTypes = {
  classes: PropTypes.object,
  setScale: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  setShowActualChange: PropTypes.func.isRequired,
  resourceUsersLoading: PropTypes.bool
};

export default QuickAllocationUserChartToolbar;
