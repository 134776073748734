import {
  Divider,
  InputAdornment,
  Popover,
  Typography,
  makeStyles
} from '@material-ui/core';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NoValue, Hours } from '~/modules/common/components';
import { ESTIMATED_HOURS_MAX } from '~/modules/common/components/TaskDrawer/EditTask/constants';
import useOnChangeHandlers from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/hooks/useOnChangeHandlers';
import { FormattedDeltaChip } from '../../FormattedDeltaChip';
import { PopoverItemMetric } from './PopoverItemMetric';

const canViewCostData = false;

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0.5, 0)
  },
  divider: {
    margin: theme.spacing(0, 0, 1)
  },
  label: {
    minWidth: theme.spacing(22),
    alignContent: 'center'
  },
  rowContainer: {
    display: 'flex',
    padding: theme.spacing(0, 0, 1)
  },
  values: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    textAlign: 'right',
    fontWeight: theme.typography.fontWeightBold
  },
  cost: {
    minWidth: theme.spacing(12.25)
  },
  diffHours: {
    minWidth: theme.spacing(14)
  },
  diffCost: {
    padding: theme.spacing(0, 2.5, 0, 0),
    minWidth: theme.spacing(16)
  },
  inputAdornment: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.spacing(42.5),
    minHeight: theme.spacing(9),
    padding: theme.spacing(1.75, 1.5, 1)
  }
}));

const useDifferenceStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.25, 0.5, 0.25, 0.25),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const useTaskResourceEstimatesStyles = makeStyles(theme => ({
  input: {
    maxWidth: theme.spacing(6),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}));

const ResourceEstimatedHoursEditor = ({
  anchorEl,
  setAnchorEl,
  resourceEstimate,
  rowIndex
}) => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const differenceClasses = useDifferenceStyles();
  const taskResourceEstimatesClasses = useTaskResourceEstimatesStyles();

  const endAdornment = useMemo(
    () => (
      <InputAdornment position="end" className={classes.inputAdornment}>
        <FormattedMessage id="taskResourceEstimates.hoursAdornment" />
      </InputAdornment>
    ),
    [classes.inputAdornment]
  );

  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext();

  const {
    initialEstimatedHours,
    taskResourceUserHoursProgressSummary
  } = resourceEstimate;

  const { actualHours, estimatedAtCompletionHours, estimatedRemainingHours } =
    taskResourceUserHoursProgressSummary || {};

  const [estimatedHours, setEstimatedHours] = useState(
    initialEstimatedHours || null
  );

  const { onEstimateChange } = useOnChangeHandlers({
    resourceEstimate,
    setFieldValue,
    rowIndex
  });

  const onChange = useCallback(
    event => {
      setEstimatedHours(Math.min(ESTIMATED_HOURS_MAX, event.target.value));
    },
    [setEstimatedHours]
  );

  const handleOnBlur = useCallback(() => {
    if (initialEstimatedHours === estimatedHours) return;

    onEstimateChange(estimatedHours);
  }, [initialEstimatedHours, estimatedHours, onEstimateChange]);

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleOnBlur(event);
      }
    },
    [handleOnBlur]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const hoursDifference = estimatedAtCompletionHours - estimatedHours;

  return (
    <Popover
      id="task-estimate-resource-editor"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={popoverClasses}
    >
      <PopoverItemMetric
        formattedMessageKey="taskResourceEstimates.actual"
        hours={actualHours}
        canViewCostData={canViewCostData}
      />
      <PopoverItemMetric
        formattedMessageKey="taskResourceEstimates.workRemaining"
        hours={estimatedRemainingHours}
        canViewCostData={canViewCostData}
        showNoValue
      />
      <Divider className={classes.divider} />
      <PopoverItemMetric
        formattedMessageKey="taskResourceEstimates.estimatedAtCompletion"
        hours={estimatedAtCompletionHours}
        canViewCostData={canViewCostData}
        showNoValue
      />

      <div className={classes.rowContainer}>
        <Typography variant="body2" className={classes.label}>
          <FormattedMessage id="taskResourceEstimates.originalEstimate" />
        </Typography>

        <Hours
          ariaLabel={formatMessage({
            id: 'taskResourceEstimates.hoursInputField'
          })}
          autoFocus
          classes={taskResourceEstimatesClasses}
          endAdornment={endAdornment}
          fullWidth={false}
          isEditible
          onChange={onChange}
          onBlur={handleOnBlur}
          onKeyDown={onKeyDown}
          value={estimatedHours}
          variant="outlined"
        />

        {canViewCostData && (
          <NoValue className={classNames(classes.values, classes.cost)} />
        )}
      </div>

      <div className={classes.rowContainer}>
        <Typography variant="body2" className={classes.label}>
          <FormattedMessage id="taskResourceEstimates.difference" />
        </Typography>
        <div className={classNames(classes.values, classes.diffHours)}>
          {hoursDifference ? (
            <FormattedDeltaChip
              isHour
              isRoundedValue={false}
              fixedDecimalScale
              formattedMessageKey="taskResourceEstimates.hoursInput"
              value={hoursDifference}
              showAddIcon={hoursDifference > 0}
              classes={differenceClasses}
            />
          ) : (
            <NoValue />
          )}
        </div>
        {canViewCostData && (
          <NoValue className={classNames(classes.values, classes.diffCost)} />
        )}
      </div>
    </Popover>
  );
};

ResourceEstimatedHoursEditor.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  resourceEstimate: PropTypes.object,
  rowIndex: PropTypes.number
};

export default ResourceEstimatedHoursEditor;
