import React from 'react';
import { PropTypes } from 'prop-types';
import { Percentage } from '~/modules/common/components';
import UserWithRoles from './UserWithRoles';
import User from './User';

export const UserWithAvailability = ({
  user,
  dataQeId,
  size = 'xsmall',
  disablePadding,
  classes,
  isAvailabilityEnabled = false,
  isProjectRoleEnabled = false,
  showTooltip
}) => {
  const UserComponent = isProjectRoleEnabled ? UserWithRoles : User;

  return (
    <>
      <UserComponent
        user={user}
        dataQeId={dataQeId}
        size={size}
        disablePadding={disablePadding}
        classes={classes}
        showTooltip={showTooltip}
      />
      {isAvailabilityEnabled && (
        <Percentage
          value={user.availabilityPercentage}
          precision={0}
          fixedDecimalScale={false}
        />
      )}
    </>
  );
};

UserWithAvailability.propTypes = {
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  user: PropTypes.object.isRequired,
  isAvailabilityEnabled: PropTypes.bool,
  isProjectRoleEnabled: PropTypes.bool,
  size: PropTypes.string,
  disablePadding: PropTypes.bool,
  showTooltip: PropTypes.bool
};

export default UserWithAvailability;
