import { DateTime } from 'luxon';
import { ReleaseResourceAllocationType } from '~/types';

export const getImpactedTasksByReleasingResources = ({
  resources,
  allocations,
  releaseDate,
  releaseType
}) => {
  if (resources.length === 0)
    return {
      selectedUsersForReleasing: [],
      impactedTasks: []
    };

  const usersWithOnlyOneAllocation = resources.filter(
    resource => resource.allocationCount === 1
  );

  const selectedUsers = usersWithOnlyOneAllocation
    .map(user =>
      allocations.find(
        allocation => allocation.isSelected && allocation.user.uri === user.id
      )
        ? user
        : null
    )
    .filter(x => x);

  const tasks = (selectedUsers || []).reduce((accumulator, currentValue) => {
    return [...accumulator, ...currentValue.assignedTasks];
  }, []);

  return {
    selectedUsersForReleasing: selectedUsers,
    impactedTasks:
      releaseType === ReleaseResourceAllocationType.Entirely
        ? tasks
        : tasks.filter(task =>
            isAsOfDateFallingBetweenTaskStartAndEndDate({
              asOfDate: releaseDate,
              task
            })
          )
  };
};

export const isAsOfDateFallingBetweenTaskStartAndEndDate = ({
  asOfDate,
  task
}) => {
  const { endDate, startDate } = task;

  if (!endDate && !startDate) return true;

  const start = startDate
    ? DateTime.fromISO(startDate, { setZone: true })
    : null;
  const end = endDate ? DateTime.fromISO(endDate, { setZone: true }) : null;
  const dateToCompare = DateTime.fromISO(asOfDate, { setZone: true });

  if (!start) return dateToCompare <= end;

  if (!end) return start <= dateToCompare;

  return start <= dateToCompare && dateToCompare <= end;
};
