import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar/UserAvatar';
import { getEffectiveSchedule } from '~/modules/common/dates/schedule';
import { ResourceRoleSchedule } from '../entities/role';
import UserWithRoleScheduleToolTip from './UserWithRoleScheduleToolTip';

const useStyles = makeStyles(theme => ({
  root: { display: 'flex', alignItems: 'center' },
  avatar: {
    flexGrow: 0,
    flexShrink: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.typography.caption.fontSize
  },
  rows: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0, 0, 0, 1),
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  row: {
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(2)}px`,
    width: 128
  },
  nameRow: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },
  roleRow: {
    color: theme.palette.text.secondary
  }
}));

const getUserContent = (user, classes) => (
  <div className={classNames(classes.row, classes.nameRow)}>
    {user.displayText}
  </div>
);

export const UserWithRoleSchedules = ({
  classes: classesOverride,
  user,
  showTooltip = false
}) => {
  const classes = useStyles({ classes: classesOverride });

  const effectiveSchedule = getEffectiveSchedule(user.roleSchedules || []);

  const title = useMemo(
    () => <UserWithRoleScheduleToolTip effectiveSchedule={effectiveSchedule} />,
    [effectiveSchedule]
  );

  return (
    <div className={classes.root}>
      <UserAvatar user={user} size="small" className={classes.avatar} />
      <div className={classes.rows}>
        {showTooltip ? (
          <Tooltip title={title}>{getUserContent(user, classes)}</Tooltip>
        ) : (
          getUserContent(user, classes)
        )}
        {user.roleSchedules && (
          <div className={classNames(classes.row, classes.roleRow)}>
            <ResourceRoleSchedule effectiveSchedule={effectiveSchedule} />
          </div>
        )}
      </div>
    </div>
  );
};

UserWithRoleSchedules.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    projectRoles: PropTypes.array,
    roles: PropTypes.array,
    roleSchedules: PropTypes.array
  }),
  showTooltip: PropTypes.bool
};

export default UserWithRoleSchedules;
