import React, { useMemo } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NoValue } from '~/modules/common/components';
import { OverviewItemTooltip } from '~/modules/common/charts/dashboard';
import { useMeContext } from '~/modules/me';
import {
  getReactMoneyFormatFromMe,
  getReactNumberFormatFromMe
} from '~/modules/common/numbers';
import { TimeAndExpenseSummaryLoading } from './TimeAndExpenseSummaryLoading';

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: theme.spacing(2)
  },
  amount: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },
  totals: {
    color: theme.palette.text.secondary
  },
  title: {
    color: theme.palette.text.secondary
  }
}));

const getAmountOrDefault = amount => amount || 0;

export const TimeAndExpenseSummary = ({
  expenseBudgetedCost,
  expenseActualsSummary,
  estimatedExpensesSummary,
  loading,
  canViewCostData,
  canViewExpenseCodes
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const moneySettings = getReactMoneyFormatFromMe(me);
  const numberSettings = getReactNumberFormatFromMe(me);
  const estimatedAmount = useMemo(() => {
    if (!estimatedExpensesSummary || !estimatedExpensesSummary.length)
      return null;

    const amount = estimatedExpensesSummary.reduce(
      (retVal, curr) =>
        retVal +
        getAmountOrDefault(curr.billableAmount) +
        getAmountOrDefault(curr.nonBillableAmount),
      0
    );

    return {
      amount,
      currency: estimatedExpensesSummary[0].currency
    };
  }, [estimatedExpensesSummary]);

  const actualAmount = useMemo(() => {
    if (!expenseActualsSummary || !expenseActualsSummary.length) return null;

    const amount = expenseActualsSummary.reduce(
      (retVal, curr) =>
        retVal +
        getAmountOrDefault(curr.billableAmount) +
        getAmountOrDefault(curr.nonBillableAmount),
      0
    );

    return {
      amount,
      currency: expenseActualsSummary[0].currency
    };
  }, [expenseActualsSummary]);

  if (!canViewExpenseCodes && !canViewCostData) return null;

  if (loading) return <TimeAndExpenseSummaryLoading />;

  return (
    <Grid container spacing={0}>
      {canViewCostData ? (
        <Grid item xs={4} className={classes.margin}>
          <>
            <Typography variant="caption" className={classes.totals}>
              <FormattedMessage id="projectTimeAndExpenseCard.budgetExpenseCost" />
            </Typography>
            <Typography variant="body2" className={classes.amount}>
              {expenseBudgetedCost ? (
                <OverviewItemTooltip
                  currencySymbol={
                    expenseBudgetedCost?.currency?.displayText ||
                    expenseBudgetedCost?.currency?.symbol
                  }
                  value={expenseBudgetedCost.amount}
                  moneySettings={moneySettings}
                  numberSettings={numberSettings}
                  intlKey="cost"
                  id="projectTimeAndExpenseCard.costDescription"
                  dataQeId="BudgetExpenseCost"
                />
              ) : (
                <NoValue />
              )}
            </Typography>
          </>
        </Grid>
      ) : null}

      {canViewExpenseCodes ? (
        <>
          <Grid item xs={canViewCostData ? 4 : 6} className={classes.margin}>
            <>
              <Typography variant="caption" className={classes.totals}>
                <FormattedMessage id="projectTimeAndExpenseCard.estimatedAmount" />
              </Typography>
              <Typography variant="body2" className={classes.amount}>
                {estimatedAmount ? (
                  <OverviewItemTooltip
                    currencySymbol={
                      estimatedAmount?.currency?.displayText ||
                      estimatedAmount?.currency?.symbol
                    }
                    value={estimatedAmount.amount}
                    moneySettings={moneySettings}
                    numberSettings={numberSettings}
                    intlKey="cost"
                    id="projectTimeAndExpenseCard.costDescription"
                    dataQeId="EstimatedAmount"
                  />
                ) : (
                  <NoValue />
                )}
              </Typography>
            </>
          </Grid>
          <Grid item xs={canViewCostData ? 4 : 6} className={classes.margin}>
            <>
              <Typography variant="caption" className={classes.totals}>
                <FormattedMessage id="projectTimeAndExpenseCard.actualAmount" />
              </Typography>
              <Typography variant="body2" className={classes.amount}>
                {actualAmount ? (
                  <OverviewItemTooltip
                    currencySymbol={
                      actualAmount?.currency?.displayText ||
                      actualAmount?.currency?.symbol
                    }
                    value={actualAmount.amount}
                    moneySettings={moneySettings}
                    numberSettings={numberSettings}
                    intlKey="cost"
                    id="projectTimeAndExpenseCard.costDescription"
                    dataQeId="ActualAmount"
                  />
                ) : (
                  <NoValue />
                )}
              </Typography>
            </>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

TimeAndExpenseSummary.propTypes = {
  expenseBudgetedCost: PropTypes.object,
  expenseActualsSummary: PropTypes.array,
  estimatedExpensesSummary: PropTypes.array,
  loading: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  canViewExpenseCodes: PropTypes.bool
};
