import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography, withStyles } from '@material-ui/core';

const styles = {
  root: {
    display: 'inline-flex',
    verticalAlign: 'top',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'flex-start'
  }
};

const EntityName = ({ classes, className, name, ...rest }) => (
  <Typography
    data-qe-id={`${name}_label`}
    component="span"
    className={classNames(classes.root, className)}
    variant="body2"
    {...rest}
  >
    {name}
  </Typography>
);

EntityName.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(EntityName);
