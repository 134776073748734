import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGoBackContext } from './GoBackContext';

export const useSaveURLSearchParamsEffect = () => {
  const location = useLocation();
  const { search, setSearch } = useGoBackContext();

  useEffect(() => {
    if (search !== location.search) {
      setSearch(location.search);
    }
  }, [location.search, search, setSearch]);
};
