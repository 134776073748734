import { PropTypes } from 'prop-types';
import { SortDirection } from '~/types';

const columns = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    type: PropTypes.string,
    group: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }),
    visible: PropTypes.bool.isRequired,
    render: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    sort: PropTypes.string,
    align: PropTypes.string,
    className: PropTypes.string
  })
).isRequired;

const headers = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        column: PropTypes.string,
        align: PropTypes.string,
        className: PropTypes.string,
        colSpan: PropTypes.number,
        rowSpan: PropTypes.number,
        fixed: PropTypes.bool
      })
    )
  })
);

const footers = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    visible: PropTypes.bool.isRequired,
    align: PropTypes.string,
    className: PropTypes.string,
    colSpan: PropTypes.number,
    rowSpan: PropTypes.number,
    fixed: PropTypes.bool
  })
);

const sort = PropTypes.shape({
  field: PropTypes.string.isRequired,
  direction: PropTypes.oneOf([SortDirection.Asc, SortDirection.Desc]),
  customFieldUri: PropTypes.string
});

const sortArray = PropTypes.arrayOf(sort);

const selected = PropTypes.shape({
  records: PropTypes.array.isRequired,
  isSelectAll: PropTypes.bool.isRequired
});

export default {
  columns,
  headers,
  footers,
  sort,
  sortArray,
  selected
};
