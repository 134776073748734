import { PropTypes } from 'prop-types';
import React from 'react';

export const Gradient = ({
  actualBarTopColor,
  actualBarBottomColor,
  actualBarGradientId,
  estimatedBarTopColor,
  estimatedBarBottomColor,
  estimatedBarGradientId
}) => (
  <svg height="0" width="0">
    <defs>
      <linearGradient
        id={actualBarGradientId}
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop offset="0%" stopColor={actualBarTopColor} stopOpacity={1} />
        <stop
          offset="100%"
          stopColor={actualBarBottomColor}
          stopOpacity={0.2}
        />
      </linearGradient>
      <linearGradient
        id={estimatedBarGradientId}
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop offset="0%" stopColor={estimatedBarTopColor} stopOpacity={0.6} />
        <stop
          offset="100%"
          stopColor={estimatedBarBottomColor}
          stopOpacity={0.6}
        />
      </linearGradient>
    </defs>
  </svg>
);

Gradient.propTypes = {
  actualBarTopColor: PropTypes.string.isRequired,
  actualBarBottomColor: PropTypes.string.isRequired,
  actualBarGradientId: PropTypes.string.isRequired,
  estimatedBarTopColor: PropTypes.string.isRequired,
  estimatedBarBottomColor: PropTypes.string.isRequired,
  estimatedBarGradientId: PropTypes.string.isRequired
};

export default Gradient;
