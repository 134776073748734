import { Card, makeStyles, Typography } from '@material-ui/core';
import ErrorOutlined from '@material-ui/icons/ErrorOutlineSharp';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  error: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10vh',
    paddingBottom: '15vh',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const ErrorCard = ({ error }) => {
  const classes = useStyles();

  return (
    <Card>
      <div className={classes.error}>
        <ErrorOutlined />
        <Typography variant="h6">
          <FormattedMessage id="centeredError.errorOccurred" />
          {error ? `: ${error}` : ''}
        </Typography>
      </div>
    </Card>
  );
};

ErrorCard.propTypes = {
  error: PropTypes.string
};

export default ErrorCard;
