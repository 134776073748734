import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(1),
    justifyContent: 'space-evenly'
  },
  legends: {
    display: 'flex',
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-end'
  }
}));

export const ResourceAllocationChartSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1" className={classes.legends}>
        <Skeleton width="60%" />
      </Typography>
      <div className={classes.root}>
        <Skeleton width="6%" height={250} variant="rect" />
        <Skeleton width="6%" height={20} variant="rect" />
        <Skeleton width="6%" height={150} variant="rect" />
        <Skeleton width="6%" height={300} variant="rect" />
        <Skeleton width="6%" height={60} variant="rect" />
        <Skeleton width="6%" height={40} variant="rect" />
        <Skeleton width="6%" height={160} variant="rect" />
        <Skeleton width="6%" height={120} variant="rect" />
      </div>
    </>
  );
};

export default ResourceAllocationChartSkeleton;
