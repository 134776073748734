import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { DateTime } from 'luxon';
import { mapRateScheduleEntries } from '~/modules/rateCard/enhancers/mapping-util';
import { getTodayForUser } from '~/modules/common/dates/today';

export const GET_EFFECTIVE_CLIENT_RATES = gql`
  query getEffectiveRates(
    $uri: String!
    $roleRatePage: Int
    $roleRatePageSize: Int
    $resourceRatePage: Int
    $resourceRatePageSize: Int
    $effectiveDateRange: DateRangeInput2
    $effectiveDateRangeInput: DateRangeInput!
  ) {
    client(uri: $uri) {
      id
      projectRate: projectRate2(effectiveDateRange: $effectiveDateRange) {
        id
        effectiveBillingRate(effectiveDateRange: $effectiveDateRangeInput) {
          effectiveDate: effectiveDate2
          amount {
            currency {
              id
              symbol
              displayText: symbol
            }
            amount
          }
        }
      }
      roleRates: roleRates2(
        page: $roleRatePage
        pageSize: $roleRatePageSize
        effectiveDateRange: $effectiveDateRange
      ) {
        id
        roleReference {
          id
          displayText
        }
        effectiveBillingRate(effectiveDateRange: $effectiveDateRangeInput) {
          effectiveDate: effectiveDate2
          amount {
            currency {
              id
              symbol
              displayText: symbol
            }
            amount
          }
        }
      }
      roleRatesCount: roleRatesCount2(effectiveDateRange: $effectiveDateRange)
      userRatesCount: userRatesCount2(effectiveDateRange: $effectiveDateRange)
      userRates: userRates2(
        page: $resourceRatePage
        pageSize: $resourceRatePageSize
        effectiveDateRange: $effectiveDateRange
      ) {
        id
        userReference {
          id
          displayText
        }
        effectiveBillingRate(effectiveDateRange: $effectiveDateRangeInput) {
          effectiveDate: effectiveDate2
          amount {
            currency {
              id
              symbol
              displayText: symbol
            }
            amount
          }
        }
      }
    }
  }
`;

const getToday = me => {
  const { day, month, year } = getTodayForUser(me);

  return DateTime.utc(year, month, day);
};

const loadMoreRows = async ({ rateTypeKey, fetchMore, variables, rates }) => {
  await fetchMore({
    query: GET_EFFECTIVE_CLIENT_RATES,
    variables: {
      ...variables,
      roleRatePage:
        rateTypeKey === 'roleRates'
          ? Math.ceil(rates.length / variables.roleRatePageSize) + 1
          : -1,
      resourceRatePage:
        rateTypeKey === 'userRates'
          ? Math.ceil(rates.length / variables.resourceRatePageSize) + 1
          : -1
    },
    updateQuery: (previousResult, { fetchMoreResult: { client } }) => ({
      ...previousResult,
      client: {
        ...previousResult.client,
        [rateTypeKey]: [
          ...previousResult.client[rateTypeKey],
          ...client[rateTypeKey]
        ]
      }
    })
  });
};

export const useClientRates = ({
  clientId,
  roleRatePage = 1,
  roleRatePageSize = 10,
  resourceRatePage = 1,
  resourceRatePageSize = 10,
  rateTypeKey,
  effectiveDateRange,
  context,
  me
}) => {
  const today = getToday(me);
  const { data, loading, fetchMore, variables, error, refetch } = useQuery(
    GET_EFFECTIVE_CLIENT_RATES,
    {
      variables: {
        uri: clientId,
        roleRatePage: rateTypeKey === 'roleRates' ? roleRatePage : -1,
        roleRatePageSize,
        resourceRatePage: rateTypeKey === 'userRates' ? resourceRatePage : -1,
        resourceRatePageSize,
        effectiveDateRange: effectiveDateRange || {
          startDate: today,
          endDate: today
        },
        effectiveDateRangeInput: effectiveDateRange || {
          startDate: today,
          endDate: today
        }
      },
      skip: context === 'project',
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const rates = get(data, `client.${rateTypeKey}`, []);
  const count = get(data, `client.${rateTypeKey}Count`, 0);

  return {
    rates: loading
      ? []
      : rateTypeKey === 'projectRate'
      ? rates
        ? [rates].map(mapRateScheduleEntries(false))
        : []
      : (rates || []).map(mapRateScheduleEntries(false)),
    hasMoreRows: rates && rates.length < count,
    count,
    loadMoreRows: useCallback(
      () =>
        loadMoreRows({
          rateTypeKey,
          fetchMore,
          variables,
          rates
        }),
      [fetchMore, rateTypeKey, rates, variables]
    ),
    hasServiceError: Boolean(error),
    isClientRatesLoading: loading,
    refetchClientRates: refetch
  };
};
