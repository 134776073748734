import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DonutChartIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 11 2.05078125 C 5.950828056233699 2.558984981115587 2.008160014613862 6.818287227970161 2.001953125 12 C 2.0085653576667 17.52010629992757 6.48184682507243 21.99338776733333 12.001953125 22 C 14.40143836631721 21.99712578819418 16.60120185591225 21.14820959839735 18.32421875 19.73828125 L 13.287109375 14.701171875 C 12.89641321655666 14.88748174251114 12.46364013086827 15 12.001953125 15 C 10.34509887614511 15 9.001953125000002 13.65685424885489 9.001953125 12 C 9.001953125 10.69614447789921 9.838110772385926 9.59720962514708 11 9.18359375 L 11 2.05078125 z M 13 2.05078125 L 13 9.18359375 C 13.15351901448635 9.238028367095701 13.2985844288715 9.305242949871081 13.439453125 9.3828125 L 18.76171875 4.65234375 C 17.21107564153884 3.22480618488515 15.21543181307633 2.272905763878225 13 2.05078125 z M 20.091796875 6.146484375 L 14.77734375 10.869140625 C 14.91978958067048 11.2184674650212 15.001953125 11.59946252811639 15.001953125 12 C 15.001953125 12.46263308579833 14.8882002600612 12.89580421064505 14.701171875 13.287109375 L 19.73828125 18.32421875 C 21.14924681952383 16.60088896027481 21.9990777378534 14.40046645404868 22.001953125 12 C 21.99933080120845 9.810799043051539 21.28614921603609 7.793079169394125 20.091796875 6.146484375 z " />
    </>
  </SvgIcon>
));

export default DonutChartIcon;
