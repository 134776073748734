import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const billTaxLineItemsQuery = gql`
  query getBillTaxLineItems(
    $page: Int!
    $pageSize: Int!
    $id: String!
    $filter: LineItemFilter
  ) {
    getBill(id: $id) {
      id
      lineItems(page: $page, pageSize: $pageSize, filter: $filter) {
        lineItems {
          id
          amount {
            amount
            currency {
              id
              symbol
              displayText
            }
          }
          description
          notes
          keyValues
        }
      }
    }
  }
`;

export const PAGE_SIZE = 50;

export const useTaxLineItems = billId => {
  const { data, loading } = useQuery(billTaxLineItemsQuery, {
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
      id: billId,
      filter: { isTaxItem: true }
    },
    skip: !billId,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const lineItems = get(data, 'getBill.lineItems.lineItems', []);

  return {
    loading,
    taxLineItems: lineItems
  };
};

export default useTaxLineItems;
