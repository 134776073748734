import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FieldLabel } from './TaskAllocationEditor';

export const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1)
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left'
};

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    width: theme.spacing(38)
  }
}));

const TaskNoResourcePopover = ({ anchorEl, setAnchorEl }) => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open
        onClose={onClose}
        classes={popoverClasses}
        anchorOrigin={anchorOrigin}
      >
        <FieldLabel
          variant="body2"
          color="textSecondary"
          messageId="taskNoResourcePopover.message"
        />
        <div className={classes.footer}>
          <Button
            variant="contained"
            onClick={onClose}
            size="small"
            className={classes.button}
          >
            <FormattedMessage id="button.ok" />
          </Button>
        </div>
      </Popover>
    </>
  );
};

TaskNoResourcePopover.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func
};

export default TaskNoResourcePopover;
