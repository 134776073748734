import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AddIconButton, NoneMenuItem } from '~/modules/common/components';
import { useMenuState } from '~/modules/common/hooks';
import { useClientProjects } from '~/modules/billing-invoicing/common/hooks';

const useStyles = makeStyles(theme => ({
  addIconButton: {
    marginTop: theme.spacing(1.5)
  }
}));

const menuProps = {
  origin: { vertical: 'top', horizontal: 'left' }
};

const menuListProps = {
  dense: true
};

export const AddProjectLineItemButton = ({ client, onSelection }) => {
  const classes = useStyles();
  const { loading, clientProjects = [] } = useClientProjects({
    client,
    skip: !client || !client.id
  });

  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const isOpen = Boolean(anchorEl);

  const onMenuItemClick = useCallback(
    item => async () => {
      onSelection(item);
      onMenuClose(null);
    },
    [onMenuClose, onSelection]
  );

  const addIconButtonClasses = useMemo(
    () => ({ root: classes.addIconButton }),
    [classes.addIconButton]
  );

  if (loading) return null;

  return (
    <>
      <AddIconButton
        onClick={onMenuClick}
        classes={addIconButtonClasses}
        data-qe-id="billing-addProjectButton"
      >
        <FormattedMessage id="addProjectLineItemButton.project" />
      </AddIconButton>
      <Menu
        id="assign-project-line-item"
        anchorEl={anchorEl}
        anchorOrigin={menuProps.origin}
        transformOrigin={menuProps.origin}
        open={isOpen}
        onClose={onMenuClose}
        MenuListProps={menuListProps}
      >
        <NoneMenuItem value="" onClick={onMenuItemClick()}>
          <FormattedMessage id="addProjectLineItemButton.noProject" />
        </NoneMenuItem>
        {clientProjects &&
          clientProjects.map &&
          clientProjects.map(item => (
            <MenuItem key={item.id} onClick={onMenuItemClick(item)}>
              {item.displayText}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
AddProjectLineItemButton.propTypes = {
  client: PropTypes.object.isRequired,
  onSelection: PropTypes.func
};

export default AddProjectLineItemButton;
