import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const getClientDetailsForBillQuery = gql`
  query client($uri: String!) {
    client(uri: $uri) {
      id
      contactName
      contactInfo {
        email
      }
    }
  }
`;

export const useGetClientDetailsForBill = ({ bill }) => {
  const { id } = (bill && bill.client) || {};

  const { data, loading } = useQuery(getClientDetailsForBillQuery, {
    variables: {
      uri: id
    },
    fetchPolicy: 'network-only'
  });

  const client = get(data, 'client', {});

  return {
    loading,
    clientDetails: client
      ? { contactName: client.contactName, contactInfo: client.contactName }
      : {}
  };
};
