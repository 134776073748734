import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { hasErrors } from '~/util';
import {
  MoreMenu,
  MoreMenuOption,
  LoadingButton
} from '~/modules/common/components';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'end'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  menu: {
    padding: theme.spacing(0, 1)
  },
  menuOption: {
    color: theme.palette.secondary.main
  }
}));

const EditorPopoverFooter = ({ onClose, hasTaskResourceUserAllocation }) => {
  const {
    isSubmitting,
    submitForm,
    values: { projectAvailableHours, allocatedHours },
    errors
  } = useFormikContext();

  const {
    dialogProps: { openRemoveAllocationDialog }
  } = useTaskEstimateRowContext();

  const onRemoveAllocatedTimeClick = useCallback(() => {
    openRemoveAllocationDialog();
    onClose();
  }, [onClose, openRemoveAllocationDialog]);

  const remainingHours =
    roundToDecimals(projectAvailableHours) - roundToDecimals(allocatedHours);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {hasTaskResourceUserAllocation && (
        <MoreMenu className={classes.menu}>
          <MoreMenuOption
            onClick={onRemoveAllocatedTimeClick}
            className={classes.menuOption}
          >
            <Typography variant="body2">
              <FormattedMessage id="taskAllocationEditor.removeAllocatedTime" />
            </Typography>
          </MoreMenuOption>
        </MoreMenu>
      )}
      <Button variant="contained" onClick={onClose} size="small">
        <FormattedMessage id="button.cancel" />
      </Button>
      <LoadingButton
        color="primary"
        className={classes.button}
        variant="contained"
        onClick={submitForm}
        size="small"
        isLoading={isSubmitting}
        disabled={hasErrors(errors) || remainingHours < 0}
      >
        <FormattedMessage id="taskAllocationEditor.setAllocation" />
      </LoadingButton>
    </div>
  );
};

EditorPopoverFooter.propTypes = {
  onClose: PropTypes.func,
  hasTaskResourceUserAllocation: PropTypes.bool.isRequired
};

export default EditorPopoverFooter;
