import gql from 'graphql-tag';

export const tryLoadPortfolioAncestoryFromCache = (proxy, portfolioId) => {
  try {
    const fragment = gql`
      fragment Portfolio on Portfolio {
        ancestors {
          portfolioManager {
            id
          }
        }
        portfolioManager {
          id
        }
      }
    `;

    return proxy.readFragment({
      id: `Portfolio:${portfolioId}`,
      fragment
    });
  } catch (e) {
    return undefined;
  }
};
