import React from 'react';
import PropTypes from 'prop-types';
import { PeriodTimelineBlocksLoading } from '~/modules/resourcing/common/chart/components';
import { useGetSmartFitResourceAvailablityHoursOptions } from '~/modules/resourcing/hooks';
import ResourceAssignmentSmartFitConfirmationDialog from '../ResourceAssignmentSmartFitConfirmationDialog';
import useShowSmartMatchDialog from './useShowSmartMatchDialog';

import useStyles from './useStyles';

const ShowSmartFitDialogOrLoading = ({
  classes: classesOverrides,
  resourceUser,
  scheduleRules,
  scale,
  chartStartDate,
  createAllocationWithUserAndOverrideRules,
  closeShowSmartFitDialogOrLoading,
  projectDefaultScheduleRule
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const {
    isLoading,
    smartFitmentDetails
  } = useGetSmartFitResourceAvailablityHoursOptions({
    userUri: resourceUser.id,
    scale,
    scheduleRules: scheduleRules.map(r => ({
      dateRange: {
        startDate: r.dateRange.startDate,
        endDate: r.dateRange.endDate
      },
      do: r.do
    }))
  });

  const showSmartMatchDialog = useShowSmartMatchDialog({
    smartFitmentDetails,
    isLoading,
    scale,
    scheduleRules
  });

  if (isLoading)
    return (
      <PeriodTimelineBlocksLoading
        classes={classes}
        scale={scale}
        chartStartDate={chartStartDate}
        scheduleRules={scheduleRules}
        projectDefaultScheduleRule={projectDefaultScheduleRule}
      />
    );

  const { availableScheduleRulesWhatFits = [] } = smartFitmentDetails || {};

  if (!showSmartMatchDialog) {
    createAllocationWithUserAndOverrideRules(
      availableScheduleRulesWhatFits || scheduleRules
    );

    return null;
  }

  return (
    <ResourceAssignmentSmartFitConfirmationDialog
      smartFitmentDetails={smartFitmentDetails}
      scale={scale}
      scheduleRules={scheduleRules}
      resourceUser={resourceUser}
      createAllocationWithUserAndOverrideRules={
        createAllocationWithUserAndOverrideRules
      }
      closeShowSmartFitDialogOrLoading={closeShowSmartFitDialogOrLoading}
    />
  );
};

ShowSmartFitDialogOrLoading.propTypes = {
  classes: PropTypes.object,
  closeShowSmartFitDialogOrLoading: PropTypes.func,
  createAllocationWithUserAndOverrideRules: PropTypes.func,
  scale: PropTypes.string,
  resourceUser: PropTypes.object,
  chartStartDate: PropTypes.object,
  scheduleRules: PropTypes.array,
  projectDefaultScheduleRule: PropTypes.object
};

export default ShowSmartFitDialogOrLoading;
