import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { NoDataItem, User } from '~/modules/common/components';
import { useStyles, useUserOverrideStyles } from './styles';

export const PreferredResourcesReadOnlyTable = ({ preferredResources }) => {
  const classes = useStyles();
  const userOverrideClasses = useUserOverrideStyles();

  return preferredResources?.length ? (
    <Table size="small">
      <TableBody>
        {preferredResources.map(resource => (
          <TableRow key={resource.displayText}>
            <TableCell className={classes.user}>
              <User user={resource} classes={userOverrideClasses} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <NoDataItem>
      <FormattedMessage id="resourceRequestDrawerDetails.noPreferredResources" />
    </NoDataItem>
  );
};

PreferredResourcesReadOnlyTable.propTypes = {
  preferredResources: PropTypes.array
};

export default PreferredResourcesReadOnlyTable;
