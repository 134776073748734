import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

export const projectClientsQuery = gql`
  query getProjectClients($page: Int!, $pageSize: Int!, $searchTerm: String) {
    projectClients(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      totalItems
      items {
        id
        displayText
      }
    }
  }
`;

const searchableProjectClients = ({
  apolloClient
}) => setHasMore => async searchTerm => {
  const result = await apolloClient.query({
    query: projectClientsQuery,
    variables: {
      page: 1,
      pageSize: 50,
      searchTerm
    }
  });

  const clients = result.data.projectClients.items.map(client => ({
    ...client,
    value: client.id,
    label: client.displayText,
    key: client.displayText
  }));

  setHasMore(result.data.projectClients.totalItems > clients.length);

  return clients;
};

export const useSearchableProjectClients = () => {
  const apolloClient = useApolloClient();

  return {
    searchClients: searchableProjectClients({
      apolloClient
    })
  };
};

export default useSearchableProjectClients;
