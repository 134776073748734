import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: theme.spacing(30)
  },
  row: {
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  addProjectDropdown: {
    padding: theme.spacing(2, 1, 0, 1),
    margin: theme.spacing(1)
  },
  resourceDropdown: {
    padding: theme.spacing(2, 1, 0, 1),
    margin: theme.spacing(-2, 1, 1, 1)
  },
  resourceDropdownFFOn: {
    padding: theme.spacing(3, 1, 0, 1),
    margin: theme.spacing(-2, 1, 1, 1)
  },
  input: {
    ...theme.typography.caption
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  amountRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  loading: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1, 0, 0)
  },
  allocated: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1, 0, 0)
  },
  dateField: {
    padding: theme.spacing(0, 1, 0, 0)
  },
  actionButton: {
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    margin: theme.spacing(0, 2, 0, 0)
  },
  underloaded: {
    color: theme.palette.error.dark
  },
  dateRowOnTop: {
    paddingTop: theme.spacing(2)
  },
  alertMessage: {
    padding: theme.spacing(0, 2, 0, 2)
  }
}));

export const useRowStyles = makeStyles(theme => ({
  row: {
    margin: theme.spacing(-2, 1, 1, 1)
  }
}));

export const usePopperStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: theme.direction === 'rtl' && {
    popper: {
      translate: '400px',
      right: 'unset !important',
      left: 0
    },
    arrow: {
      transform: 'unset',
      marginRight: 'unset',
      left: '0 !important'
    }
  }
}));

export const useRoleStyles = makeStyles(theme => ({
  row: {
    margin: theme.spacing(-3, 1, 1, 1)
  }
}));

export default useStyles;
