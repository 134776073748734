import React from 'react';
import PropTypes from 'prop-types';
import { PROJECT_PROGRESS_TYPE_ENUM } from '~/modules/projects/project/common/enums/projectProgressType';
import { ProgressChartLoading } from '~/modules/common/charts/dashboard';
import { ProgressCostChart } from '~/modules/projects/project/charts/ProgressCostChart';
import { ProgressHourChart } from '~/modules/projects/project/charts/ProgressHourChart';
import { useProjectProgresSummary } from '~/modules/projects/project/charts/hooks/useProjectProgressSummary';
import { useMeContext } from '~/modules/me/useMeContext';

export const ProjectProgressChart = ({ chartDataInput }) => {
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();

  const {
    projectId,
    showCostContent,
    projectProgressView,
    projectCurrency
  } = chartDataInput;

  const { hourSummary, costSummary, isLoading } = useProjectProgresSummary({
    projectId,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  if (isLoading) return <ProgressChartLoading />;

  return (
    <>
      {showCostContent &&
      projectProgressView === PROJECT_PROGRESS_TYPE_ENUM.COST ? (
        <>
          <ProgressCostChart
            projectId={projectId}
            endDate={chartDataInput.endDate}
            projectCurrency={projectCurrency}
            summary={costSummary}
          />
        </>
      ) : (
        <ProgressHourChart
          projectId={projectId}
          endDate={chartDataInput.endDate}
          summary={hourSummary}
        />
      )}
    </>
  );
};

ProjectProgressChart.propTypes = {
  chartDataInput: PropTypes.object
};

export default ProjectProgressChart;
