import { useLazyQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import NoValue from '~/modules/common/components/NoValue';
import Decimal from '~/modules/common/components/Decimal';
import User from '~/modules/common/components/User';
import NoProjectManager from '~/modules/common/components/ProjectManager/NoProjectManager';
import ProjectManagersTooltipTitle from '~/modules/common/components/ProjectManager/ProjectManagersTooltipTitle';

const useStyles = makeStyles(theme => ({
  root: { display: 'flex', alignItems: 'center' },
  additional: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: 'normal'
  },
  noProjectManager: {
    color: theme.palette.text.disabled
  }
}));

const tooltipStyles = makeStyles(theme => ({
  tooltip: { maxWidth: 'none' }
}));

export const GET_PROJECT_CO_MANAGERS_QUERY = gql`
  query GetProjectCoManagers($projectId: String!) {
    project(projectId: $projectId) {
      id
      coManagers {
        displayText
        id
      }
    }
  }
`;

const ProjectLeader = ({ field, record }) => {
  const intl = useIntl();

  const projectManager = record[field];
  const { id: projectId, coManagerCount, permittedActionUris } = record;
  const canViewCoManagers = (permittedActionUris || []).includes(
    'urn:replicon:project-action:view-project-sharing'
  );
  const classes = useStyles();
  const tooltipClasses = tooltipStyles();

  const [getProjectCoManagers, { loading, data }] = useLazyQuery(
    GET_PROJECT_CO_MANAGERS_QUERY,
    {
      variables: { projectId }
    }
  );
  const project = get(data, 'project', {});

  const projectManagersTooltipTitle = useMemo(
    () => (
      <ProjectManagersTooltipTitle
        intl={intl}
        coManagers={project.coManagers || []}
        coManagerCount={coManagerCount}
        projectManager={projectManager}
        isCoManagerLoading={loading}
      />
    ),
    [intl, loading, projectManager, coManagerCount, project.coManagers]
  );

  return canViewCoManagers && coManagerCount && coManagerCount > 0 ? (
    <Tooltip
      title={projectManagersTooltipTitle}
      onOpen={getProjectCoManagers}
      classes={tooltipClasses}
      enterTouchDelay={0}
    >
      <div className={classes.root}>
        {projectManager ? (
          <User user={projectManager} />
        ) : (
          <NoProjectManager intl={intl} className={classes.noProjectManager} />
        )}
        <Decimal
          value={coManagerCount}
          precision={0}
          prefix="+"
          className={classes.additional}
        />
      </div>
    </Tooltip>
  ) : projectManager ? (
    <User user={projectManager} />
  ) : (
    <NoValue />
  );
};

ProjectLeader.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default ProjectLeader;
