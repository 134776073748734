import { useState, useCallback } from 'react';

export const useChipMenuProps = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = useCallback(event => setAnchorEl(event.currentTarget), [
    setAnchorEl
  ]);

  const closeMenu = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const open = Boolean(anchorEl);

  return {
    anchorEl,
    openMenu,
    closeMenu,
    open
  };
};
