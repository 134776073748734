import { useMemo } from 'react';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { USER_SCHEDULE_HOURS } from '../constants/scheduleHours';

export const useHolidaySummaryPeriods = ({
  resource = {},
  hideHolidays = false
}) => {
  const { holidays } = resource;

  return useMemo(
    () =>
      hideHolidays
        ? []
        : (holidays || []).map(timeoff => {
            const { date, ...rest } = timeoff;

            return {
              startDate: date && mapRepliconDateToUtcObject(date),
              totalHolidayHours: USER_SCHEDULE_HOURS /* This should consider shifts in future */,
              totalAvailableHours: USER_SCHEDULE_HOURS /* This should consider shifts in future */,
              ...rest
            };
          }),
    [holidays, hideHolidays]
  );
};

export default useHolidaySummaryPeriods;
