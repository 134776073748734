import React from 'react';
import User from '~/modules/common/components/User';
import { programManagerTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import ProgramManagerFacetDetails from './ProgramManagerFacetDetails';

export default {
  makeCriteriaProvider,
  tag: programManagerTag,
  DetailsControl: ProgramManagerFacetDetails,
  // eslint-disable-next-line react/prop-types
  SuggestionItem: ({ option }) => <User user={option} />
};
