import React from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  skeleton: {
    marginLeft: theme.spacing(2)
  }
}));

export const TabBarLoading = () => {
  const classes = useStyles();

  return <Skeleton width="40%" height={48} className={classes.skeleton} />;
};

export default TabBarLoading;
