import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import User from '~/modules/common/components/User/User';

const useStyles = makeStyles({
  avatar: {
    marginTop: '-7px',
    marginBottom: '-7px'
  },
  name: {
    lineHeight: 'unset'
  },
  label: {},
  container: {
    paddingTop: 'unset',
    paddingBottom: 'unset',
    overflow: 'visible'
  }
});

const UserCell = ({ user }) => {
  const classes = useStyles();

  return <User classes={classes} user={user} />;
};

UserCell.propTypes = {
  user: PropTypes.object
};

export default UserCell;
