import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  availabilitySection: {
    backgroundColor: theme.palette.grey[200]
  },
  dialogTitleText: {
    alignSelf: 'center',
    color: theme.palette.text.primary,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    fontFamily: theme.typography.h6.fontFamily
  },
  root: {
    display: 'grid',
    justifyContent: 'stretch',
    justifyItems: 'stretch',
    alignItems: 'baseline',
    gridTemplateColumns: [['auto', 'auto', 'auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0
  },
  section: {
    display: 'contents'
  },
  item: {
    display: 'contents'
  },
  label: {
    gridColumnStart: 1,
    padding: theme.spacing(0.5, 2, 0.5, 1),
    borderTop: `1px solid ${theme.palette.grey[200]}`
  },
  fte: {
    gridColumnStart: 2,
    padding: theme.spacing(0.5, 2),
    textAlign: 'right',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    fontSize: theme.typography.caption.fontSize
  },
  hours: {
    gridColumnStart: 3,
    textAlign: 'right',
    padding: theme.spacing(0.5, 2),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    fontSize: theme.typography.caption.fontSize
  },
  percent: {
    gridColumnStart: 4,
    textAlign: 'right',
    padding: theme.spacing(0.5, 2),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    fontSize: theme.typography.caption.fontSize
  },
  note: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    gridColumnStart: 1,
    gridColumnEnd: 6,
    padding: theme.spacing(0.5, 2)
  },
  totalLabel: {
    fontSize: theme.typography.caption.fontSize,
    paddingLeft: theme.spacing(4.5)
  },
  button: {
    gridColumnStart: 4
  }
}));
