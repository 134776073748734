import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  MenuItem,
  Select,
  FilledInput,
  InputLabel,
  makeStyles
} from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { NoneMenuItem } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  select: {
    paddingTop: `${theme.spacing(1.5)}px !important`,
    paddingBottom: `${theme.spacing(1.5)}px !important`,
    [theme.breakpoints.down('sm')]: {
      paddingTop: `${theme.spacing(2.5)}px !important`,
      maxWidth: theme.spacing(20)
    }
  },
  label: {
    '&.MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 14px) scale(1)',
      opacity: '0.6 !important'
    }
  }
}));

const input = <FilledInput />;
const label = <FormattedMessage id="client.country" />;

export const CountryDropdown = ({
  record,
  field,
  index,
  column: { addressHandlers, countries }
}) => {
  const isMobile = useIsBreakpointDown('sm');

  const classes = useStyles();
  const handleChange = useCallback(
    event => {
      addressHandlers.onCountryChange({ index, value: event.target.value });
    },
    [addressHandlers, index]
  );

  return (
    <>
      <FormControl variant="filled" fullWidth>
        <InputLabel
          shrink={Boolean(record[field]?.id)}
          className={!isMobile && classes.label}
        >
          {record[field]?.id && !isMobile ? '' : label}
        </InputLabel>
        <Select
          data-qe-id={`${field}`}
          placeholder="Country"
          name="contactInfo.countryUri"
          value={record[field]?.id}
          input={input}
          onChange={handleChange}
          classes={useMemo(() => ({ select: classes.select }), [
            classes.select
          ])}
          fullWidth
        >
          <NoneMenuItem value="">
            <FormattedMessage id="country.none" />
          </NoneMenuItem>
          {countries.map(country => (
            <MenuItem key={country.id} value={country.id}>
              {country.displayText}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

CountryDropdown.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string,
  index: PropTypes.number,
  column: PropTypes.object
};

export default CountryDropdown;
