import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ContactIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 22,4 H 2 V 20 H 22 Z M 12,6.75 c 1.24,0 2.25,1.009999999999998 2.25,2.25 0,1.24 -1.01,2.25 -2.25,2.25 C 10.76,11.25 9.75,10.24 9.75,9 9.75,7.759999999999998 10.76,6.75 12,6.75 Z M 17,17 H 7 v -1.5 c 0,-1.67 3.33,-2.5 5,-2.5 1.67,0 5,0.83 5,2.5 z" />
    </>
  </SvgIcon>
));

export default ContactIcon;
