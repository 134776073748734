import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useContactInfoListItemContext } from '../hooks/useContactInfoListItemContext';
import {
  ContactField,
  RemoveContactButton,
  ContactTypeDropdown
} from '../columns/formatters/index';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2.5),
    paddingTop: index => (index !== 0 ? theme.spacing(1.5) : theme.spacing(0)),
    paddingLeft: theme.spacing(0)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(0)
  },
  contactField: {
    padding: theme.spacing(0.5),
    width: '50%'
  }
}));

const EditableMobileContactInfoListItem = ({ record, rowIndex: index }) => {
  const { contactHandlers } = useContactInfoListItemContext();

  const classes = useStyles(index);
  const { name, email, phoneNumber, faxNumber, website, contactType } = record;

  const orderByContactField = useMemo(
    () => ({
      name,
      email,
      phoneNumber,
      faxNumber,
      website,
      contactType
    }),
    [contactType, email, faxNumber, name, phoneNumber, website]
  );

  const handlers = useMemo(() => ({ contactHandlers }), [contactHandlers]);

  const contactRows = useMemo(() => {
    const fields = Object.keys(orderByContactField);
    const rows = [];

    for (let i = 0; i < fields.length; i += 2) {
      rows.push(
        <Grid key={i} className={classes.row}>
          <Grid className={classes.contactField}>
            <ContactField
              field={fields[i]}
              record={record}
              index={index}
              column={handlers}
            />
          </Grid>
          <Grid className={classes.contactField}>
            {i + 1 === fields.length - 1 ? (
              <ContactTypeDropdown
                field={fields[i + 1]}
                record={record}
                column={handlers}
                index={index}
              />
            ) : (
              <ContactField
                field={fields[i + 1]}
                record={record}
                column={handlers}
                index={index}
              />
            )}
          </Grid>
        </Grid>
      );
    }

    return rows;
  }, [orderByContactField, classes, record, index, handlers]);

  return (
    <Grid className={classes.container}>
      <Grid className={classes.column}>{contactRows}</Grid>
      <Grid>
        <RemoveContactButton record={record} column={handlers} />
      </Grid>
    </Grid>
  );
};

EditableMobileContactInfoListItem.propTypes = {
  record: PropTypes.object,
  rowIndex: PropTypes.number
};

export default EditableMobileContactInfoListItem;
