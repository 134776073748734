import { useApolloClient } from '@apollo/client';
import { useFormik } from 'formik';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import resolveValueOrDefault from '~/modules/common/components/FormCustomField/resolveValueOrDefault';
import {
  mapRepliconDateToMidnightUTCString,
  toRepliconDate,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import {
  TIME_AND_EXPENSE_ENTRY_TYPE,
  TIME_AND_EXPENSE_ENTRY_TYPE_ENUM_FROM_URI,
  TIMESHEET_ACCESS_TYPE
} from '~/modules/common/enums';
import { isNumeric } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me';
import { equalDateTimes } from '~/modules/common/dates/compare';
import useUpdateTask from './useUpdateTask';
import validationSchema from './validationSchema';

export const TASK_ANCESTORS_WITH_ROLLEDUP_SUMMARY_QUERY = gql`
  query GetTasksAncestorsWithRolledUpSummary($taskId: String!) {
    task(taskId: $taskId) {
      id
      rolledUpSummary {
        id
        actualHours
        totalEstimatedAtCompletionHours
        totalEstimatedRemainingHours
        earliestTimeEntryDate
        estimatedCompletionDate
      }
      ancestors {
        __typename
        ... on Task {
          id
          rolledUpSummary {
            id
            actualHours
            totalEstimatedAtCompletionHours
            totalEstimatedRemainingHours
            earliestTimeEntryDate
            estimatedCompletionDate
          }
        }
        ... on Project {
          id
          rolledUpSummary {
            id
            actualHours
            totalEstimatedAtCompletionHours
            totalEstimatedRemainingHours
            earliestTimeEntryDate
            estimatedCompletionDate
          }
        }
      }
    }
  }
`;

export const pickFileAttributes = ({ base64Content, keyValues, mimeType }) => ({
  base64Content,
  keyValues,
  mimeType
});

const timesheetAccessGroups = {
  [TIMESHEET_ACCESS_TYPE.COST_CENTERS]: 'costCenterUris',
  [TIMESHEET_ACCESS_TYPE.DEPARTMENT_GROUP]: 'departmentGroupUris',
  [TIMESHEET_ACCESS_TYPE.DIVISIONS]: 'divisionUris',
  [TIMESHEET_ACCESS_TYPE.EMPLOYEE_TYPE_GROUP]: 'employeeTypeGroupUris',
  [TIMESHEET_ACCESS_TYPE.LOCATIONS]: 'locationUris',
  [TIMESHEET_ACCESS_TYPE.SERVICE_CENTERS]: 'serviceCenterUris'
};

export const mapToTimesheetAccessInput = timesheetAccess =>
  (timesheetAccess || []).reduce((acc, val) => {
    if (val?.group && val.group.id !== TIMESHEET_ACCESS_TYPE.ALL_USERS) {
      const groupKey = timesheetAccessGroups[val.group.id];

      acc[groupKey] = acc[groupKey] || [];
      acc[groupKey].push(val.id);
    } else {
      acc.allUsers = val.id;
    }

    return acc;
  }, {});

export const mapValuesToUpdateTaskInput = (
  {
    assignedUser: initialAssignedUser = {},
    assignedRole: initialAssignedRole = {},
    initialTimeAndExpenseEntryType
  } = {},
  {
    id,
    name,
    code,
    startDate,
    endDate,
    description,
    isTimeEntryAllowed,
    isMilestone,
    initialEstimatedHours,
    initialEstimatedCost,
    timeAndExpenseEntryType = {},
    assignedUser = {},
    assignedRole = {},
    extensionFieldValues = [],
    assignedUserRoleId,
    costType,
    assignedTimesheetAccessUris,
    unAssignedTimesheetAccessUris,
    ...customFieldValues
  } = {},
  customFields,
  customFieldDefinitions = [],
  project,
  isPsaRmpTaskAllocation1Enabled
) => {
  const initialAssignedUsrUri = initialAssignedUser
    ? initialAssignedUser.id
    : null;

  const currentAssignedUsrUri = assignedUser ? assignedUser.id : null;

  const initialAssignedRoleUri = initialAssignedRole
    ? initialAssignedRole.id
    : null;

  const currentAssignedRoleUri = assignedRole ? assignedRole.id : null;
  const billingTypeId =
    project && project.billingType ? project.billingType.id : null;

  const handleNullOrUndefinedAmount = isPsaRmpTaskAllocation1Enabled
    ? {
        currency: initialEstimatedCost.currency,
        amount: 0
      }
    : undefined;

  return {
    name,
    code: code || '',
    description: description || '',
    taskUri: id,
    startDate: isPsaRmpTaskAllocation1Enabled
      ? undefined
      : startDate
      ? mapRepliconDateToMidnightUTCString(startDate)
      : null,
    endDate: isPsaRmpTaskAllocation1Enabled
      ? undefined
      : endDate
      ? mapRepliconDateToMidnightUTCString(endDate)
      : null,
    isTimeEntryAllowed,
    isMilestone,
    assignedTimesheetAccessUris: mapToTimesheetAccessInput(
      assignedTimesheetAccessUris
    ),
    unAssignedTimesheetAccessUris: mapToTimesheetAccessInput(
      unAssignedTimesheetAccessUris
    ),
    timeAndExpenseEntryType:
      billingTypeId === 'urn:replicon:billing-type:non-billable'
        ? undefined
        : isTimeEntryAllowed
        ? TIME_AND_EXPENSE_ENTRY_TYPE_ENUM_FROM_URI[timeAndExpenseEntryType.id]
        : TIME_AND_EXPENSE_ENTRY_TYPE_ENUM_FROM_URI[
            initialTimeAndExpenseEntryType.uri
          ],
    initialEstimatedHours: isNumeric(initialEstimatedHours)
      ? initialEstimatedHours
      : null,
    initialEstimatedCost: isNumeric(initialEstimatedCost?.amount)
      ? {
          currency: initialEstimatedCost.currency,
          amount: initialEstimatedCost.amount
        }
      : handleNullOrUndefinedAmount,
    costTypeUri: costType ? costType.id : null,
    extensionFieldValues: extensionFieldValues.map(e => ({
      numericValue: e.numericValue,
      tag: e.tag ? { uri: e.tag.id } : null,
      textValue: e.textValue,
      fileValue: e.fileValue
        ? {
            data: e.fileValue.data && pickFileAttributes(e.fileValue.data),
            identityUri: e.fileValue.identityUri
          }
        : null,
      definition: {
        uri: e.definition.id
      }
    })),
    customFields: customFieldDefinitions.map(definition => {
      let customField = customFields.find(
        field => field.customField.id === definition.uri
      );

      switch (definition.type.uri) {
        case 'urn:replicon:custom-field-type:text': {
          customField = {
            ...customField,
            text: customFieldValues[definition.uri] || null
          };
          break;
        }
        case 'urn:replicon:custom-field-type:numeric': {
          customField = {
            ...customField,
            number: customFieldValues[definition.uri] || null
          };
          break;
        }
        case 'urn:replicon:custom-field-type:date': {
          customField = {
            ...customField,
            date: customFieldValues[definition.uri] || null
          };
          break;
        }
        case 'urn:replicon:custom-field-type:drop-down': {
          customField = {
            ...customField,
            dropDownOptionUri: customFieldValues[definition.uri] || null
          };
          break;
        }
        default:
          break;
      }

      customField.definition = definition;

      return customField;
    }),
    ...(initialAssignedUsrUri !== currentAssignedUsrUri
      ? { assignedUserUri: currentAssignedUsrUri }
      : {}),
    ...(initialAssignedRoleUri !== currentAssignedRoleUri
      ? { assignedRoleUri: currentAssignedRoleUri }
      : {}),
    assignedUserRoleId
  };
};

export const useFormState = ({
  task: originalTask,
  customFieldDefinitions,
  stopEditing,
  dispatch,
  setFormSubmitting,
  openRescheduleTaskDialog
}) => {
  const me = useMeContext();
  const client = useApolloClient();
  const intl = useIntl();

  const { updateTask } = useUpdateTask();
  const {
    baseCurrency,
    featureFlags: {
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaRmpTaskAllocation1Enabled
    },
    hasViewProjectBillingOptions
  } = me;

  const {
    assignedRole,
    assignedUser,
    assignedUserRoleId,
    code,
    costTypeUri,
    customFields,
    description,
    endDate,
    initialEstimatedCost,
    initialEstimatedHours,
    isMilestone,
    isTimeEntryAllowed,
    project,
    startDate,
    timeAndExpenseEntryType,
    timesheetAccessAssignments
  } = originalTask || {};

  const initialValues = {
    ...originalTask,
    code: code || '',
    description: description || '',
    startDate: startDate ? toRepliconDate(startDate) : null,
    isMilestone: isMilestone || false,
    endDate: endDate ? toRepliconDate(endDate) : null,
    assignedUser,
    assignedRole,
    initialTimeAndExpenseEntryType:
      hasViewProjectBillingOptions && isTimeEntryAllowed
        ? timeAndExpenseEntryType
        : {
            uri: TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE
          },
    timeAndExpenseEntryType: {
      id:
        project &&
        project.billingType &&
        project.billingType.id === 'urn:replicon:billing-type:non-billable'
          ? TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE
          : isTimeEntryAllowed
          ? (hasViewProjectBillingOptions &&
              timeAndExpenseEntryType &&
              timeAndExpenseEntryType.uri) ||
            TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE
          : TIME_AND_EXPENSE_ENTRY_TYPE.NO
    },
    initialEstimatedCost: initialEstimatedCost || {
      amount: null,
      currency:
        (isPsaPpmCostEacEnhancementsEnabled &&
          project?.defaultBillingCurrency) ||
        baseCurrency
    },
    ...customFieldDefinitions.reduce(
      (fields, definition) => ({
        ...fields,
        [definition.uri]: resolveValueOrDefault(customFields, definition) || ''
      }),
      {}
    ),
    assignedUserRoleId,
    costType: { id: costTypeUri } || null,
    assignedTimesheetAccessUris: timesheetAccessAssignments || [],
    unAssignedTimesheetAccessUris: []
  };

  const onSubmit = useCallback(
    (values, { setStatus, setSubmitting }) => {
      setStatus(null);
      isPsaRmpTaskAllocation1Enabled && setFormSubmitting(true);

      const input = mapValuesToUpdateTaskInput(
        initialValues,
        values,
        customFields,
        customFieldDefinitions,
        project,
        isPsaRmpTaskAllocation1Enabled
      );

      const timeEnteredSummary = async (originalValue, updatedInput) => {
        if (originalValue === updatedInput.initialEstimatedHours) {
          return false;
        }

        dispatch({
          type: 'RECALCULATE-GANTT-CHART-DATA',
          recalculatingGanttChartData: true
        });

        await client.query({
          query: TASK_ANCESTORS_WITH_ROLLEDUP_SUMMARY_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            taskId: updatedInput.taskUri
          }
        });

        dispatch({
          type: 'RECALCULATE-GANTT-CHART-DATA',
          recalculatingGanttChartData: false
        });

        return true;
      };

      const previousStartDate = startDate
        ? mapRepliconDateToUtcObject(startDate)
        : null;
      const newStartDate = values?.startDate
        ? mapRepliconDateToUtcObject(values.startDate)
        : null;
      const previousEndDate = endDate
        ? mapRepliconDateToUtcObject(endDate)
        : null;
      const newEndDate = values?.endDate
        ? mapRepliconDateToUtcObject(values.endDate)
        : null;

      const hasTaskDateRangeChanged =
        !equalDateTimes(previousStartDate, newStartDate) ||
        !equalDateTimes(previousEndDate, newEndDate);

      return updateTask(input)
        .then(
          ({
            data: {
              updateTask: { task, error }
            }
          }) => {
            if (!task && error) {
              setStatus(error.reason);
              isPsaRmpTaskAllocation1Enabled
                ? setFormSubmitting(false)
                : setSubmitting(false);

              return;
            }
            if (isPsaRmpTaskAllocation1Enabled) {
              if (!hasTaskDateRangeChanged) {
                setFormSubmitting(false);
                stopEditing();
              } else {
                openRescheduleTaskDialog();
              }
            }

            const { isRolledUpTaskEstimateCalculationMethodEnabled } = me;

            if (isRolledUpTaskEstimateCalculationMethodEnabled) {
              timeEnteredSummary(initialEstimatedHours, input);
            }

            !isPsaRmpTaskAllocation1Enabled && stopEditing();
          }
        )
        .catch(e => {
          setStatus(e);
          setSubmitting(false);
        });
    },
    [
      client,
      customFieldDefinitions,
      customFields,
      dispatch,
      endDate,
      initialEstimatedHours,
      initialValues,
      isPsaRmpTaskAllocation1Enabled,
      me,
      openRescheduleTaskDialog,
      project,
      startDate,
      stopEditing,
      updateTask
    ]
  );

  return useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema({ customFieldDefinitions, intl })
  });
};

export default useFormState;
