import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';

export const billAssociatedPaymentQuery = gql`
  query getBillAssociatedPayments($page: Int!, $pageSize: Int!, $id: String!) {
    getBill(id: $id) {
      id
      associatedBillPayments(page: $page, pageSize: $pageSize) {
        associatedBillPayments {
          id
          displayText
          paymentDate
          allocatedBillAmount {
            amount
            currency {
              id
              symbol
            }
          }
          allocatedBillPaymentAmount {
            amount
            currency {
              id
              symbol
            }
          }
        }
      }
    }
  }
`;

export const updateQueryOnFetchMoreResult = (
  prev = {
    getBill: { associatedBillPayments: { associatedBillPayments: [] } }
  },
  {
    fetchMoreResult = {
      getBill: { associatedBillPayments: { associatedBillPayments: [] } }
    }
  }
) => {
  if (!fetchMoreResult) return prev;

  return {
    getBill: {
      ...prev.getBill,
      associatedBillPayments: {
        ...prev.getBill.associatedBillPayments,
        associatedBillPayments: [
          ...prev.getBill.associatedBillPayments.associatedBillPayments,
          ...fetchMoreResult.getBill.associatedBillPayments
            .associatedBillPayments
        ]
      }
    }
  };
};

export const PAGE_SIZE = 50;

export const useAssociatedBillPayments = billId => {
  const [loadingMore, setLoadingMore] = useState(false);

  const { data, loading, refetch, fetchMore } = useQuery(
    billAssociatedPaymentQuery,
    {
      variables: {
        page: 1,
        pageSize: PAGE_SIZE,
        id: billId
      },
      skip: !billId,
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const associatedBillPayments = get(
    data,
    'getBill.associatedBillPayments.associatedBillPayments',
    []
  );

  const hasMore = Boolean(
    associatedBillPayments.length &&
      associatedBillPayments.length % PAGE_SIZE === 0
  );

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    setLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          id: billId,
          page: associatedBillPayments.length / PAGE_SIZE + 1,
          pageSize: PAGE_SIZE
        },
        updateQuery: updateQueryOnFetchMoreResult
      });
    } finally {
      setLoadingMore(false);
    }
  }, [billId, fetchMore, setLoadingMore, hasMore, associatedBillPayments]);

  return {
    loading,
    refetch,
    associatedBillPayments,
    hasMore,
    loadMore,
    loadingMore
  };
};

export default useAssociatedBillPayments;
