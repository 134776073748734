import get from 'lodash.get';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const RESOURCE_USER_COUNT_QUERY = gql`
  query Eager_GetResourceUsersCount($filter: GetResourceUsersFilter) {
    resourceUsersCount(filter: $filter) {
      count
    }
  }
`;

export const useResourceUsersCount = filter => {
  const { data, loading } = useQuery(RESOURCE_USER_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter
    }
  });

  const { count } = get(data, 'resourceUsersCount', {});

  return {
    isLoading: loading,
    resourceUsersCount: count || 0
  };
};

export default useResourceUsersCount;
