import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleDropdown } from '~/modules/common/components';

export const PAYMENT_METHODS_QUERY = gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      displayText
    }
  }
`;

export const PaymentMethodDropdown = ({
  variant,
  ariaLabel,
  label,
  value,
  onChange,
  dataQeId
}) => {
  const { data } = useQuery(PAYMENT_METHODS_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const options = React.useMemo(
    () => (data && data.getPaymentMethods ? data.getPaymentMethods : []),
    [data]
  );

  return (
    <SimpleDropdown
      variant={variant}
      ariaLabel={ariaLabel}
      label={label}
      value={value}
      onChange={onChange}
      data-qe-id={dataQeId}
      options={options}
    />
  );
};

PaymentMethodDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dataQeId: PropTypes.string
};

export default PaymentMethodDropdown;
