import { OverEstimatedProjectType } from '~/types';

export const OVERBUDGET_OPTION_KEYS = {
  COMPLETIONDATE_OVERDUE: 'completionDateOverdue',
  HOURS_OVERBUDGET: 'hoursOverbudget',
  COST_OVERBUDGET: 'costOverbudget',
  OVERBUDGET: 'overbudget'
};

export const mapOverEstimatedProjectTypeToOverbudgetOptionKeys = overEstimatedProjectType =>
  ({
    [OverEstimatedProjectType.BudgetHours]:
      OVERBUDGET_OPTION_KEYS.HOURS_OVERBUDGET,
    [OverEstimatedProjectType.EndDate]:
      OVERBUDGET_OPTION_KEYS.COMPLETIONDATE_OVERDUE,
    [OverEstimatedProjectType.BudgetHoursOrCost]:
      OVERBUDGET_OPTION_KEYS.OVERBUDGET
  }[overEstimatedProjectType]);
