import OverbudgetFacetDetails from './OverbudgetFacetDetails';
import makeCriteriaProvider from './makeCriteriaProvider';

export const overbudgetTag = 'overbudget:';

export default {
  tag: overbudgetTag,
  makeCriteriaProvider,
  DetailsControl: OverbudgetFacetDetails
};

export { mapOverEstimatedProjectTypeToOverbudgetOptionKeys } from './overBudgetOptionKeys';
