import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  makeStyles,
  ListItemIcon,
  ListItemText,
  ButtonBase
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddSharp';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'start',
    margin: theme.spacing(0, -2)
  },
  icon: {
    padding: theme.spacing(1),
    minWidth: 42
  }
}));

const useListItemStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const primaryTypographyProps = {
  color: 'primary'
};

const CreatableCategoryOption = ({ option, onCreate }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const handleClick = useCallback(
    event => onCreate(event, option.displayText),
    [option.displayText, onCreate]
  );

  const listItemTextClasses = useListItemStyles();

  return (
    <ButtonBase className={classes.root} onClick={handleClick}>
      <ListItemIcon className={classes.icon}>
        <AddIcon color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={`${formatMessage({
          id: 'skillsDropdown.category'
        })} "${option.displayText}"`}
        primaryTypographyProps={primaryTypographyProps}
        classes={listItemTextClasses}
      />
    </ButtonBase>
  );
};

CreatableCategoryOption.propTypes = {
  option: PropTypes.object.isRequired,
  onCreate: PropTypes.func
};

export default CreatableCategoryOption;
