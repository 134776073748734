import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import { useDialogState } from '~/modules/common/hooks';
import { useHasPermission } from '~/modules/common/permissions';
import { ListTablePropTypes } from '~/modules/common/components/ListTable';
import {
  useEnabledColumns,
  usePageOfProjectRequests,
  useProjectRequestTotals
} from './hooks';
import ProjectRequestListTable from './ProjectRequestListTable';
import { CreateProjectFromRequestDialog } from './CreateProjectFromRequestDialog';

const useColumnStyle = makeStyles(theme => ({
  createProject: {
    '& button': {
      color: theme.palette.primary.main
    },
    maxWidth: 250,
    borderRight: `1px solid ${theme.palette.table.border}`
  }
}));

export const ProjectRequestList = ({
  isMobile,
  selectedColumnNames,
  isSortLoading,
  onSortChange,
  sortCriteria,
  searchCriteria,
  summaryFilterState
}) => {
  const classes = useColumnStyle();
  const canEditProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });
  const { open, openDialog, closeDialog } = useDialogState(false);
  const [requestDetails, setRequestDetails] = useState({});
  const onCreateProject = useCallback(
    request => {
      setRequestDetails(request);
      canEditProject && openDialog();
    },
    [canEditProject, openDialog]
  );

  const { enabledColumnGroups, getServiceDataColumns } = useEnabledColumns({
    classes,
    canEditProject,
    onCreateProject
  });

  const projectRequestListColumns = getServiceDataColumns({
    isMobile,
    selectedColumnNames
  });

  const {
    loading,
    loadingMore,
    projectRequests,
    loadMore,
    hasMore
  } = usePageOfProjectRequests({
    sort: sortCriteria,
    columns: projectRequestListColumns,
    searchCriteria,
    summaryFilterState
  });

  const { totals, isTotalsLoading } = useProjectRequestTotals({
    columns: projectRequestListColumns,
    searchCriteria,
    summaryFilterState
  });

  return (
    <ErrorBoundary>
      <ProjectRequestListTable
        enabledFields={selectedColumnNames}
        enabledColumnGroups={enabledColumnGroups}
        isLoading={
          (loading && !loadingMore) || isTotalsLoading || isSortLoading
        }
        loadMore={loadMore}
        hasMore={hasMore}
        projectRequests={projectRequests}
        totals={totals}
        sortCriteria={sortCriteria}
        onSortChange={onSortChange}
      />
      {open && (
        <CreateProjectFromRequestDialog
          open={open}
          onClose={closeDialog}
          projectRequestName={requestDetails?.name}
          portfolioId={requestDetails?.portfolio?.id}
          portfolioName={requestDetails?.portfolio?.displayText}
          projectRequestId={requestDetails?.id}
        />
      )}
    </ErrorBoundary>
  );
};
ProjectRequestList.propTypes = {
  isMobile: PropTypes.bool,
  selectedColumnNames: PropTypes.array,
  isSortLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  sortCriteria: ListTablePropTypes.sort,
  searchCriteria: PropTypes.object,
  summaryFilterState: PropTypes.object
};

export default ProjectRequestList;
