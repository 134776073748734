import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import Date from '~/modules/common/components/Date';
import User from '~/modules/common/components/User';
import { NoValue } from '~/modules/common/components/NoValue';

const useStyles = makeStyles(theme => ({
  user: {
    paddingTop: 0,
    paddingBottom: 0
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  asOf: {
    display: 'flex',
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(4) - 4
  },
  lineItem: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 'unset'
  }
}));

export const SupervisorsInfoReadOnly = ({
  classes: classesOverride,
  editable,
  userDetails
}) => {
  const classes = useStyles({ classes: classesOverride });
  const intl = useIntl();
  const supervisor = userDetails.supervisorAssignment
    ? userDetails.supervisorAssignment.supervisor
    : null;
  const currentEffectiveDate = useMemo(
    () =>
      supervisor && userDetails.supervisorAssignments
        ? userDetails.supervisorAssignments.find(
            a => a.supervisor.uri === supervisor.uri
          ).effectiveDate
        : null,
    [userDetails, supervisor]
  );

  const userClasses = useMemo(
    () => ({
      name: classes.user,
      label: classes.label
    }),
    [classes]
  );

  return (
    <>
      {supervisor ? (
        <User
          dataQeId="resource_Supervisor"
          user={supervisor}
          classes={userClasses}
        />
      ) : (
        <NoValue classes={userClasses} />
      )}
      {currentEffectiveDate && (
        <div className={classes.asOf}>
          {intl.formatMessage({ id: 'resourceBasicInfoCard.asOf' })}
          <Date
            data-qe-id="SupervisorDate"
            className={classes.lineItem}
            value={currentEffectiveDate}
          />
        </div>
      )}
    </>
  );
};

SupervisorsInfoReadOnly.propTypes = {
  userDetails: PropTypes.object,
  editable: PropTypes.bool,
  classes: PropTypes.object
};
export default SupervisorsInfoReadOnly;
