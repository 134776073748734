import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  tabItem: {
    minHeight: '80vh'
  }
});

const TabItem = ({ label, children, classes }) => (
  <div className={classes.tabItem}>{children}</div>
);

TabItem.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default withStyles(styles)(TabItem);
