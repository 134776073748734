import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import HierarchyOptionItem from '~/modules/common/components/HierarchyOptionItem';
import { PORTFOLIOS_DROPDOWN_DATA_VARIANT } from '~/modules/common/components/PortfoliosDropDown/enum';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useSearchablePortfolios from './useSearchablePortfolios';

const renderOption = option =>
  option.key === NONE_OPTION_KEY ? (
    <NoneDropdownItem value={option.displayText} />
  ) : (
    <HierarchyOptionItem hierarchyLevel={option.hierarchyLevel}>
      {option.displayText}
    </HierarchyOptionItem>
  );

export const PortfolioFacetDetails = ({
  className,
  selected,
  setSelected,
  dataVariant = PORTFOLIOS_DROPDOWN_DATA_VARIANT.PROJECT
}) => {
  const { formatMessage } = useIntl();

  const [searchTerm, setSearchTerm] = useState('');

  const noneOptionText = formatMessage({
    id: 'portfoliosDropdown.noPortfolio'
  });

  const { portfolios, loading } = useSearchablePortfolios({
    dataVariant,
    includeNoneOption: true,
    noneOptionText,
    searchTerm
  });

  const label = formatMessage({ id: 'search.portfolio' });

  const handleValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      (option.key !== NONE_OPTION_KEY && !option.isSelectable),
    [selected]
  );
  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const filterOptions = useCallback(
    (filteredOptions, _) => filteredOptions,
    []
  );

  return (
    <FacetDetailField label={label} className={className}>
      <SimpleAutocomplete
        fullWidth
        hiddenLabel
        options={portfolios}
        filterOptions={filterOptions}
        multiple
        noOptionsText={noneOptionText}
        loading={loading}
        inputLabel={label}
        optionPropText="displayText"
        getOptionDisabled={getOptionDisabled}
        value={selected}
        onInputChange={onInputChange}
        onChange={handleValueChange}
        variant="standard"
        renderOption={renderOption}
        optionTypeText={formatMessage({
          id: 'portfoliosDropdown.portfolios'
        })}
      />
    </FacetDetailField>
  );
};

PortfolioFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  dataVariant: PropTypes.string
};

export default PortfolioFacetDetails;
