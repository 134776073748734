import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Decimal } from '~/modules/common/components/Decimal/Decimal';

const useStyles = makeStyles(theme => ({
  message: {
    fontSize: theme.typography.caption,
    color: theme.palette.text.secondary
  }
}));

export const ProgressItemTooltip = ({
  className,
  value,
  fixedDecimalScale,
  suffix,
  prefix,
  dataQeId
}) => {
  const classes = useStyles();

  const tooltipContent = useMemo(
    () => <Decimal value={value} suffix={suffix} prefix={prefix} />,
    [value, suffix, prefix]
  );

  return (
    <Tooltip className={classes.message} title={tooltipContent}>
      <span className={className}>
        <Decimal
          value={value}
          fixedDecimalScale={fixedDecimalScale}
          suffix={suffix}
          prefix={prefix}
          dataQeId={dataQeId}
        />
      </span>
    </Tooltip>
  );
};

ProgressItemTooltip.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  fixedDecimalScale: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  dataQeId: PropTypes.string
};

export default ProgressItemTooltip;
