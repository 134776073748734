import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import {
  compactScheduleRules,
  getDateRangeFromScheduleRules,
  getTotalHoursForDateRangeFromScheduleRules,
  shouldExcludeDay
} from './scheduleUtil';

export const getRulesFromPeriods = ({ allocationPeriods, excludeWeekdays }) => {
  const scheduleRules = [];

  if (allocationPeriods.length === 0) return scheduleRules;

  let isFirstWorkingEntryMatched = false;
  let startDateOfSchedulePeriod = null;
  let scheduleHoursForSchedulePeriod = null;

  for (let i = 0; i < allocationPeriods.length; i++) {
    if (shouldExcludeDay(allocationPeriods[i].date, excludeWeekdays)) {
      continue;
    }

    const { date, hours } = allocationPeriods[i];

    if (!isFirstWorkingEntryMatched) {
      startDateOfSchedulePeriod = date;
      scheduleHoursForSchedulePeriod = hours;
      isFirstWorkingEntryMatched = true;
      continue;
    }

    if (hours !== scheduleHoursForSchedulePeriod) {
      const previousEntry = allocationPeriods[i - 1];

      scheduleRules.push({
        dateRange: {
          startDate: startDateOfSchedulePeriod.toISO(),
          endDate: previousEntry.date.toISO()
        },
        do: {
          load: 100,
          setHours: scheduleHoursForSchedulePeriod,
          excludeWeekdays
        }
      });

      startDateOfSchedulePeriod = date;
      scheduleHoursForSchedulePeriod = hours;
    }
  }

  const entry = allocationPeriods[allocationPeriods.length - 1];

  scheduleRules.push({
    dateRange: {
      startDate: startDateOfSchedulePeriod.toISO(),
      endDate: entry.date.toISO()
    },
    do: {
      load: 100,
      setHours: scheduleHoursForSchedulePeriod,
      excludeWeekdays
    }
  });

  return scheduleRules.filter(({ do: { setHours } }) => setHours > 0);
};

const mergePeriodRulesIntoScheduleRules = (
  scheduleRules = []
) => periodRules => {
  const {
    startDate: scheduleStart,
    endDate: scheduleEnd
  } = getDateRangeFromScheduleRules(scheduleRules);
  const {
    startDate: periodStart,
    endDate: periodEnd
  } = getDateRangeFromScheduleRules(periodRules);

  if (periodStart > scheduleEnd) {
    return [...scheduleRules, ...periodRules];
  }

  if (scheduleStart > periodEnd) {
    return [...periodRules, ...scheduleRules];
  }

  const sortedDates = [periodStart, periodEnd, scheduleStart, scheduleEnd].sort(
    (a, b) => mapIsoStringtoUtcObject(a) - mapIsoStringtoUtcObject(b)
  );

  const firstDate = mapIsoStringtoUtcObject(sortedDates[0]);
  const lastDate = mapIsoStringtoUtcObject(sortedDates[sortedDates.length - 1]);
  const periods = [];
  let currentDay = firstDate;

  while (currentDay <= lastDate) {
    const periodHours =
      getTotalHoursForDateRangeFromScheduleRules({
        start: currentDay,
        end: currentDay,
        scheduleRules: periodRules
      }) || 0;

    const scheduleHours =
      getTotalHoursForDateRangeFromScheduleRules({
        start: currentDay,
        end: currentDay,
        scheduleRules
      }) || 0;

    const totalHours = periodHours + scheduleHours;

    periods.push({
      date: currentDay,
      hours: totalHours
    });

    currentDay = currentDay.plus({ days: 1 });
  }

  return compactScheduleRules(
    getRulesFromPeriods({
      allocationPeriods: periods,
      excludeWeekdays: []
    })
  );
};

export default mergePeriodRulesIntoScheduleRules;
