import useBillingRateCounts from './useBillingRateCounts';
import useProjectBillingRates from './useProjectBillingRates';
import useProjectUserBillingRates from './useProjectUserBillingRates';
import useProjectSpecificBillingRates from './useProjectSpecificBillingRates';

export const useReadOnlyBillingRates = ({ projectId }) => {
  const effectiveProjectBillingRates = useProjectBillingRates({ projectId });
  const effectiveProjectSpecificBillingRates = useProjectSpecificBillingRates({
    projectId
  });
  const effectiveProjectUserBillingRates = useProjectUserBillingRates({
    projectId
  });
  const effectiveRatesCount = useBillingRateCounts({
    projectId
  });

  return {
    effectiveProjectBillingRates,
    effectiveProjectSpecificBillingRates,
    effectiveProjectUserBillingRates,
    effectiveRatesCount
  };
};

export default useReadOnlyBillingRates;
