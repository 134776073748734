import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const IconButtonLink = props => {
  const { children, to, ...buttonProps } = props;

  return (
    <IconButton component={Link} to={to} {...buttonProps}>
      {children}
    </IconButton>
  );
};

IconButtonLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
};

export default IconButtonLink;
