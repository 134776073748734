import { canViewProjectDetailsRoute } from '../project/projectRoute';
import ResourcingPlanPage from './ResourcingPlanPage';

const route = ({ intl, me }) => ({
  path: '/projects/:slug/resourcingplan',
  title: () => intl.formatMessage({ id: 'projectResourcing.resourcePlan' }),
  component: ResourcingPlanPage,
  hasPermission: canViewProjectDetailsRoute(me),
  exact: true
});

export default route;
