import { v4 } from 'uuid';
import { compareDateObjects } from '~/modules/common/dates/compare';
import { isoStringToObject } from '~/modules/common/dates/convert';
import { getEffectiveRateFromScheduleEntries } from '~/modules/projects/project/utils';
import {
  GET_CURRENT_BILLING_RATE_FOR_USER,
  PROJECT_ROLE
} from '~/modules/rateCard/hooks/query';
import { DimensionObjectType } from '~/types';
import { DEFAULT_RATE } from './enum';

export const mapQueryAndVariables = (objectType, value) => {
  switch (objectType) {
    case DimensionObjectType.ProjectRole:
      return {
        query: PROJECT_ROLE,
        variables: {
          id: value.id
        }
      };
    case DimensionObjectType.User:
      return {
        query: GET_CURRENT_BILLING_RATE_FOR_USER,
        variables: {
          userId: value.id
        }
      };
    default:
      return {};
  }
};

export const areValuesEqual = (previousValue, currentValue) =>
  typeof previousValue === typeof currentValue &&
  (previousValue === currentValue || previousValue?.id === currentValue?.id);

export const areInitialAndCurrentProjectDimensionsEqual = (
  initialProjectDimensions,
  projectDimensions
) =>
  initialProjectDimensions?.length === projectDimensions.length &&
  projectDimensions.every(({ id }, i) => initialProjectDimensions[i].id === id);

export const mapProjectRatesInputForRemovedRates = (
  removedProjectRateEntryGroup,
  projectDimensions
) => {
  return removedProjectRateEntryGroup.map(r => {
    return {
      dimensions: projectDimensions
        .filter(d => r[d.id].value && r[d.id].value !== DEFAULT_RATE)
        .map(d => ({
          dimensionUri: d.id,
          objectUri: r[d.id]?.value?.id
        })),
      entries: []
    };
  });
};

const isNotDefaultRate = (currentRate, dimensionId) =>
  Boolean(
    currentRate[dimensionId] &&
      currentRate[dimensionId]?.value &&
      currentRate[dimensionId]?.value !== DEFAULT_RATE
  );

const isCurrentDimensionValueSameAsPrevDimensionValue = (
  previousDimensionId,
  rate,
  currentRate
) =>
  !previousDimensionId ||
  rate[previousDimensionId]?.id === currentRate[previousDimensionId]?.id;

const checkCurrentRateExistsInProjectRates = (r, dimensionId, currentRate) =>
  Boolean(
    areValuesEqual(r[dimensionId]?.value, currentRate[dimensionId]?.value)
  );

const hasDifferentDimensionId = (rate, currentRate, dimensionId) =>
  currentRate.id !== rate.id &&
  rate[dimensionId]?.id !== currentRate[dimensionId]?.id;

export const hasSameValueForDimension = (
  projectRates,
  currentRate,
  dimensionId,
  previousDimensionId
) =>
  isNotDefaultRate(currentRate, dimensionId) &&
  projectRates.some(
    rate =>
      hasDifferentDimensionId(rate, currentRate, dimensionId) &&
      isCurrentDimensionValueSameAsPrevDimensionValue(
        previousDimensionId,
        rate,
        currentRate
      ) &&
      checkCurrentRateExistsInProjectRates(rate, dimensionId, currentRate)
  );

export const hasSameEffectiveDate = (
  isInitialRate,
  effectiveDate,
  rates,
  currentRate
) =>
  currentRate.effectiveDate &&
  !isInitialRate &&
  effectiveDate &&
  rates.some(
    rate =>
      currentRate.id !== rate.id &&
      rate.effectiveDate &&
      compareDateObjects(currentRate.effectiveDate, rate.effectiveDate) === 0
  );

export const getAmount = ({
  objectType,
  data,
  todayForUser,
  rate,
  projectCurrency
}) => {
  switch (objectType) {
    case DimensionObjectType.ProjectRole:
      if (!data) {
        return { amount: 0, currency: projectCurrency };
      }

      return (
        getEffectiveRateFromScheduleEntries(
          data?.role?.billingScheduleEntries,
          todayForUser
        )?.amount || rate.amount
      );
    case DimensionObjectType.User:
      return data?.getCurrentBillingRateForUser?.rate || rate.amount;
    default:
      return rate.amount;
  }
};

export const getErrorForKey = ({ index, key, intl, errors, field, type }) => ({
  ...errors[key],
  [index]: intl.formatMessage(
    {
      id: `advancedRateCard.validations.${type}`
    },
    {
      field
    }
  )
});

const lastRateBelongsToSameGroup = (updatedProjectRates, rate) =>
  updatedProjectRates[updatedProjectRates.length - 1] &&
  updatedProjectRates[updatedProjectRates.length - 1].groupId === rate.groupId;

export const addNewDimensionToProjectRates = (projectRates, dimension) => {
  const updatedProjectRates = [];
  let lastEntryDimensionValueId = '';

  for (const { default: _, ...r } of projectRates) {
    if (lastRateBelongsToSameGroup(updatedProjectRates, r)) {
      updatedProjectRates.push({
        ...r,
        [dimension.id]: {
          id: lastEntryDimensionValueId,
          value: DEFAULT_RATE
        }
      });
    } else {
      lastEntryDimensionValueId = v4();
      updatedProjectRates.push({
        ...r,
        [dimension.id]: {
          id: lastEntryDimensionValueId,
          value: DEFAULT_RATE
        }
      });
    }
  }

  return updatedProjectRates;
};

const mapDimensionToKey = dimensions =>
  dimensions
    .map(d => `${d.dimensionUri}${d.dimensionValueReference.id}`)
    .sort()
    .join('') || DEFAULT_RATE;

export const mapDimensions = (dimensions, projectDimensions, lastEntry) => {
  const dimensionValueMap = dimensions.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.dimensionUri]: curr.dimensionValueReference
    }),
    {}
  );

  let isDimensionValueSameTillPreviousDimension = true;

  const dimensionMap = {};

  return projectDimensions.reduce((prevDimensions, { id }) => {
    const value = dimensionValueMap[id] || DEFAULT_RATE;

    dimensionMap[id] = {
      value
    };

    if (!lastEntry) {
      dimensionMap[id].id = v4();

      return prevDimensions;
    }

    if (
      isDimensionValueSameTillPreviousDimension &&
      areValuesEqual(lastEntry[id]?.value, value)
    ) {
      dimensionMap[id].id = lastEntry[id]?.id;

      return prevDimensions;
    }
    dimensionMap[id].id = v4();
    isDimensionValueSameTillPreviousDimension = false;

    return dimensionMap;
  }, dimensionMap);
};

export const mapProjectRates = (
  projectRates,
  projectDimensions,
  loadingMore
) => {
  const result = [];
  const effectiveDatedEntriesCountMap = {};

  let groupKey = '';

  for (const {
    amount,
    effectiveDate,
    dimensions,
    id,
    isDimensionDeleted
  } of projectRates) {
    const key = mapDimensionToKey(dimensions || []);

    const lastEntry = result[result.length - 1];

    const d = mapDimensions(dimensions || [], projectDimensions, lastEntry);

    const groupId = groupKey === key ? lastEntry.groupId : v4();

    groupKey = key;

    if (!effectiveDatedEntriesCountMap[groupId]) {
      effectiveDatedEntriesCountMap[groupId] = 1;
    } else {
      effectiveDatedEntriesCountMap[groupId]++;
    }

    result.push({
      ...d,
      id,
      effectiveDate: isoStringToObject(effectiveDate),
      amount,
      groupId,
      isPersisted: true,
      isInitialRate: !effectiveDate,
      isDimensionDeleted
    });
  }

  const { groupId: lastEntryGroupId, effectiveDate } =
    result[result.length - 1] || {};

  return result.map(({ key, ...r }) => ({
    ...r,
    disabled: loadingMore || (r.groupId === lastEntryGroupId && effectiveDate),
    totalEntries: effectiveDatedEntriesCountMap[r.groupId]
  }));
};

const mapEntry = (amount, effectiveDate, projectCurrency, isInitialRate) => ({
  amount: {
    amount: amount?.amount || 0,
    currency: { id: amount?.currency?.id || projectCurrency.id }
  },
  effectiveDate: isInitialRate ? null : effectiveDate
});

export const mapProjectRatesInput = (
  projectRates,
  projectCurrency,
  projectDimensions
) =>
  Object.values(
    projectRates.reduce((retVal, currVal) => {
      const {
        groupId,
        amount,
        effectiveDate,
        isInitialRate,
        default: defaultRate,
        ...rest
      } = currVal;

      if (rest.disabled) {
        return retVal;
      }

      return {
        ...retVal,
        [groupId]: retVal[groupId]
          ? {
              ...retVal[groupId],
              entries: [
                ...retVal[groupId].entries,
                mapEntry(amount, effectiveDate, projectCurrency, isInitialRate)
              ]
            }
          : {
              dimensions: projectDimensions
                .filter(
                  d => rest[d.id].value && rest[d.id].value !== DEFAULT_RATE
                )
                .map(d => ({
                  dimensionUri: d.id,
                  objectUri: rest[d.id]?.value?.id
                })),
              entries: [
                mapEntry(amount, effectiveDate, projectCurrency, isInitialRate)
              ]
            }
      };
    }, {})
  );

export const getCreateDefaultEntry = (dimensions, currency) => [
  {
    ...(dimensions.length > 0
      ? dimensions.reduce(
          (retVal, curr) => ({
            ...retVal,
            [curr.id]: { id: v4(), value: DEFAULT_RATE }
          }),
          {}
        )
      : { default: { value: DEFAULT_RATE } }),
    effectiveDate: null,
    amount: {
      amount: 0,
      currency
    },
    groupId: v4(),
    id: v4(),
    isInitialRate: true,
    totalEntries: 1
  }
];

export const getCreateNewEntry = (
  dimensions,
  currency,
  dimensionIndex,
  record
) =>
  dimensions.reduce(
    (retVal, curr, i) => ({
      ...retVal,
      [curr.id]:
        i < dimensionIndex
          ? record[curr.id]
          : { id: v4(), value: i === dimensionIndex ? null : DEFAULT_RATE }
    }),
    {
      effectiveDate: null,
      amount: {
        amount: 0,
        currency
      },
      groupId: v4(),
      id: v4(),
      isInitialRate: true,
      totalEntries: 1
    }
  );
