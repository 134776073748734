import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import {
  yearWidth,
  quarterWidth,
  monthWidth,
  weekWidth,
  dayWidth
} from '~/modules/common/charts/timeline/calculations';
import HeaderTimeItemContent from './HeaderTimeItemContent';
import HeaderTimeItemTooltipContent from './HeaderTimeItemTooltipContent';

const useStyles = makeStyles(theme => ({
  timeBlock: {
    height: theme.spacing(5),
    padding: 0,
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(6)}px`,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    display: 'inline-block'
  },
  year: {
    width: yearWidth
  },
  quarter: {
    width: quarterWidth,
    padding: theme.spacing(0.5, 0),
    lineHeight: `${theme.spacing(2)}px`
  },
  month: {
    width: monthWidth
  },
  week: {
    width: weekWidth
  },
  day: {
    width: dayWidth,
    padding: theme.spacing(0.5, 0),
    lineHeight: `${theme.spacing(2)}px`
  }
}));

const getTitle = (scale, start, end) => (
  <HeaderTimeItemTooltipContent scale={scale} start={start} end={end} />
);

export const HeaderTimeItems = ({
  classes: classesOverrides,
  scale,
  chartDates
}) => {
  const classes = useStyles({ classes: classesOverrides });

  return chartDates.map(({ start, end }) => (
    <Tooltip title={getTitle(scale, start, end)} key={start}>
      <div
        className={classNames(classes.timeBlock, {
          [classes.year]: scale === PERIOD_SCALE_ENUM.YEARS,
          [classes.quarter]: scale === PERIOD_SCALE_ENUM.QUARTERS,
          [classes.month]: scale === PERIOD_SCALE_ENUM.MONTHS,
          [classes.week]: scale === PERIOD_SCALE_ENUM.WEEKS,
          [classes.day]: scale === PERIOD_SCALE_ENUM.DAYS
        })}
      >
        <HeaderTimeItemContent scale={scale} start={start} end={end} />
      </div>
    </Tooltip>
  ));
};

HeaderTimeItems.propTypes = {
  classes: PropTypes.object,
  chartDates: PropTypes.array.isRequired,
  scale: PropTypes.string.isRequired
};

export default HeaderTimeItems;
