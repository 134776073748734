import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { TextField, makeStyles, styled } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { getError } from '~/util';

const labelIds = {
  name: 'projectBasicInfoCard.contactInfo.fields.name',
  email: 'projectBasicInfoCard.contactInfo.fields.email',
  phoneNumber: 'projectBasicInfoCard.contactInfo.fields.phoneNumber',
  faxNumber: 'projectBasicInfoCard.contactInfo.fields.faxNumber',
  website: 'projectBasicInfoCard.contactInfo.fields.website'
};

const useTextFieldStyles = makeStyles(theme => ({
  input: {
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2.5)
      }
    },
    '& .MuiFormLabel-root': {
      paddingTop: theme.spacing(0)
    }
  }
}));

const usePlaceholderStyles = makeStyles(theme => ({
  input: {
    '&::placeholder': {
      color: `rgb(0, 0, 0)`,
      opacity: '0.6 !important',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily
    }
  }
}));

export const StyledTextField = styled(TextField)({
  '& .MuiFormHelperText-contained': {
    marginLeft: 0,
    textWrap: 'wrap'
  }
});

const ContactField = ({
  record,
  field,
  index,
  column: { contactHandlers }
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const textFieldClasses = useTextFieldStyles();
  const placeholderStyles = usePlaceholderStyles();

  const intl = useIntl();

  const { errors } = useFormikContext();

  const labelField = intl.formatMessage({ id: labelIds[field] });
  const textFieldInputProps = useMemo(() => {
    const inputProps = {
      inputProps: {
        classes: { input: placeholderStyles.input },
        'aria-label': labelField
      }
    };

    return inputProps;
  }, [labelField, placeholderStyles]);

  const handleChange = useCallback(
    event => {
      contactHandlers.onContactChange({
        index,
        field,
        value: event.target.value
      });
    },
    [contactHandlers, index, field]
  );

  return (
    <StyledTextField
      data-qe-id={`${field}`}
      className={textFieldClasses.input}
      variant="filled"
      label={isMobile && labelField ? labelField : ''}
      placeholder={!isMobile && labelField ? labelField : ''}
      InputProps={textFieldInputProps}
      value={record[field]}
      fullWidth
      onChange={handleChange}
      error={Boolean(getError(errors, `contacts[${index}].${field}`))}
      helperText={getError(errors, `contacts[${index}].${field}`)}
    />
  );
};

ContactField.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string,
  index: PropTypes.number,
  column: PropTypes.object
};

export default ContactField;
