import { makeStyles } from '@material-ui/styles';

const useTagStyles = makeStyles(theme => ({
  tooltipTitle: {
    fontWeight: 'bold',
    fontSize: theme.typography.caption.fontSize
  },
  detailList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'grid',
    gridTemplateColumns: [['auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: theme.spacing(1),
    gridRowGap: 0,
    justifyContent: 'start',
    justifyItems: 'start',
    alignItems: 'start',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  },
  tooltipContent: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    margin: theme.spacing(0.25, 0, 0, 0)
  },
  assignment: {
    display: 'contents'
  }
}));

export default useTagStyles;
