import { Dialog, DialogTitle, makeStyles, Tab, Tabs } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AddProjectDialogForm from '~/modules/common/components/AddProjectDialogForm';
import { CopyProjectDialogDetails } from '~/modules/common/components/CopyProjectDialog/CopyProjectDialogDetails';
import { COPY_PROJECT_FLOW } from '~/modules/common/components/CopyProjectDialog/enums';
import { DIALOG_FORM_TYPE } from '~/modules/common/enums/AddProjectDialogFormTypes';
import { useRequiredCustomFieldDefinitions } from '~/modules/customFields/hooks/useRequiredCustomFieldDefinitions';
import {
  useAddProjectForm,
  useCopyProjectPermissions,
  useProjectCodeSettings
} from '~/modules/common/hooks/project';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    }
  },
  title: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  titleText: {
    padding: theme.spacing(1, 2),
    ...theme.typography.h6
  }
}));

const addProjectDialogRole = {
  role: 'presentation'
};

const addProject = <FormattedMessage id="addProject.addNewProject" />;
const copyProject = <FormattedMessage id="duplicateProject.title" />;

export const AddProjectDialog = ({
  open,
  onClose,
  showCopyProjectTab = true,
  projectType,
  replaceHistoryOnClose
}) => {
  const { customFieldDefinitions } = useRequiredCustomFieldDefinitions(
    'Project'
  );
  const fullScreen = useIsBreakpointDown('sm');
  const classes = useStyles();
  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [
    classes.paper
  ]);

  const {
    nextProjectCode,
    projectCodeSettingsLoading
  } = useProjectCodeSettings();

  const form = useAddProjectForm({
    type: DIALOG_FORM_TYPE.PROJECT,
    projectType,
    customFieldDefinitions,
    replaceHistoryOnClose,
    onClose,
    refetchQueries: ['getProjectsByPage'],
    projectCode: nextProjectCode
  });
  const { handleReset } = form;
  const [tab, setTab] = useState(0);
  const handleTabChange = useCallback((_, value) => setTab(value), [setTab]);

  const handleOnClose = useCallback(() => {
    handleReset();
    setTab(0);
    onClose();
  }, [handleReset, onClose]);

  const {
    canCopyProject,
    hasEditClientPermission,
    hasEditProgramPermission
  } = useCopyProjectPermissions();

  const enableCopyProjectTab = showCopyProjectTab && canCopyProject;

  if (projectCodeSettingsLoading) return null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      classes={dialogClasses}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={addProjectDialogRole}
    >
      <DialogTitle className={enableCopyProjectTab ? classes.title : ''}>
        {enableCopyProjectTab ? (
          <Tabs value={tab} indicatorColor="primary" onChange={handleTabChange}>
            <Tab label={addProject} />
            <Tab label={copyProject} />
          </Tabs>
        ) : (
          <FormattedMessage id="addProject.addNewProject" />
        )}
      </DialogTitle>
      {enableCopyProjectTab ? (
        <TabView
          tab={tab}
          form={form}
          projectType={projectType}
          canEditClient={hasEditClientPermission}
          canEditProgram={hasEditProgramPermission}
          handleOnClose={handleOnClose}
        />
      ) : (
        <AddProjectDialogForm
          type={DIALOG_FORM_TYPE.PROJECT}
          onCancel={handleOnClose}
          {...form}
        />
      )}
    </Dialog>
  );
};

export const TabView = ({
  tab,
  form,
  projectType,
  canEditClient,
  canEditProgram,
  handleOnClose
}) =>
  tab === 0 ? (
    <AddProjectDialogForm
      type={DIALOG_FORM_TYPE.PROJECT}
      onCancel={handleOnClose}
      {...form}
    />
  ) : (
    <CopyProjectDialogDetails
      onClose={handleOnClose}
      screenFlow={COPY_PROJECT_FLOW.ADD_PROJECT}
      projectType={projectType}
      canEditClient={canEditClient}
      canEditProgram={canEditProgram}
    />
  );

TabView.propTypes = {
  tab: PropTypes.number,
  form: PropTypes.object,
  handleOnClose: PropTypes.func.isRequired,
  canEditProgram: PropTypes.bool.isRequired,
  canEditClient: PropTypes.bool.isRequired,
  projectType: PropTypes.string.isRequired
};

AddProjectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  replaceHistoryOnClose: PropTypes.bool,
  projectType: PropTypes.string.isRequired,
  showCopyProjectTab: PropTypes.bool
};

export default AddProjectDialog;
