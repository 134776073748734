import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Percentage } from '~/modules/common/components';

export const OverviewItemPercentageTooltipContent = ({
  value,
  numberFormatSettings
}) => {
  const tooltipTitleContent = useMemo(
    () => (
      <NumberFormat
        displayType="text"
        value={value}
        suffix="%"
        {...numberFormatSettings}
        fixedDecimalScale
      ></NumberFormat>
    ),
    [numberFormatSettings, value]
  );

  return (
    <Percentage
      title={tooltipTitleContent}
      value={value}
      fixedDecimalScale={false}
    />
  );
};

OverviewItemPercentageTooltipContent.propTypes = {
  value: PropTypes.number,
  numberFormatSettings: PropTypes.shape({
    thousandSeparator: PropTypes.string,
    decimalSeparator: PropTypes.string,
    thousandsGroupStyle: PropTypes.string,
    decimalScale: PropTypes.number
  })
};

export default OverviewItemPercentageTooltipContent;
