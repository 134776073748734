import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '@material-ui/core';
import ChipMenu from '../ChipMenu';
import useStyles from './useStyles';

export const labels = {
  active: <FormattedMessage id="status.active" />,
  inactive: <FormattedMessage id="status.inactive" />
};

export const StatusChipMenu = ({
  classes,
  editable,
  isActive,
  onActiveClick,
  onInactiveClick,
  size = 'medium',
  ...chipMenuProps
}) => {
  const cssClasses = useStyles({ classes, isActive, size });

  const chipClasses = useMemo(
    () => ({
      ...cssClasses,
      deleteIcon: cssClasses.root,
      clickableColorPrimary: cssClasses.clickableColorPrimary,
      clickableColorSecondary: cssClasses.clickableColorSecondary
    }),
    [cssClasses]
  );

  const statusLabel = isActive ? labels.active : labels.inactive;

  return (
    <ChipMenu
      {...chipMenuProps}
      editable={editable}
      label={statusLabel}
      classes={chipClasses}
      color={isActive ? 'primary' : 'secondary'}
      size={size}
    >
      <MenuItem onClick={onActiveClick}>{labels.active}</MenuItem>
      <MenuItem onClick={onInactiveClick}>{labels.inactive}</MenuItem>
    </ChipMenu>
  );
};

StatusChipMenu.propTypes = {
  classes: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onActiveClick: PropTypes.func.isRequired,
  onInactiveClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium'])
};

export default StatusChipMenu;
