import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { validationResourceKeys } from './useFormState';

export const useFormOnChange = ({ setFieldValue, setFieldError }) => {
  const intl = useIntl();
  const onNameChange = useCallback(
    event => {
      const { value } = event.target;

      setFieldValue('name', value);
      setFieldError(
        'name',
        !value
          ? intl.formatMessage({
              id: validationResourceKeys.nameRequired
            })
          : value.length > 255
          ? intl.formatMessage({
              id: validationResourceKeys.nameExceedsMaxLength
            })
          : null
      );
    },
    [intl, setFieldError, setFieldValue]
  );
  const onDescriptionChange = useCallback(
    event => {
      const { value } = event.target;

      setFieldValue('description', value);
      setFieldError(
        'description',
        !value
          ? intl.formatMessage({
              id: validationResourceKeys.descriptionRequired
            })
          : null
      );
    },
    [intl, setFieldError, setFieldValue]
  );
  const onRequestedByChange = useCallback(
    event => {
      const { value } = event.target;

      setFieldValue('requestedBy', value);
      setFieldError(
        'requestedBy',
        value && value.length > 255
          ? intl.formatMessage({
              id: validationResourceKeys.requestedByExceedsMaxLength
            })
          : null
      );
    },
    [intl, setFieldError, setFieldValue]
  );
  const onPortfolioChange = useCallback(
    value => setFieldValue('portfolio', value),
    [setFieldValue]
  );

  return {
    onNameChange,
    onDescriptionChange,
    onRequestedByChange,
    onPortfolioChange
  };
};
export default useFormOnChange;
