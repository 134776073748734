import React from 'react';
import PropTypes from 'prop-types';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { deepPure } from '~/util';
import {
  useHolidaySummaryPeriods,
  useTimeoffSummaryPeriods
} from '~/modules/resourcing/common/hooks';
import { getDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';
import HolidaySummaryBlocks from '~/modules/resourcing/common/components/HolidaySummaryBlock';
import TimeOffSummaryBlocks from '~/modules/resourcing/common/components/TimeOffSummaryBlock';
import ResourceUserPeriodSummaryBlock from './ResourceUserPeriodSummaryBlock';

const mapToEntryDates = entries =>
  entries.map(entry => ({
    ...entry,
    startDate: mapRepliconDateToUtcObject(entry.dateRange.startDate),
    endDate: mapRepliconDateToUtcObject(entry.dateRange.endDate)
  }));

export const ResourceUserPeriodSummaryBlocks = ({
  chartStartDate,
  scale,
  isAllocationsLazyLoaded,
  resourceAvailabilitySummarySeries,
  resourceAllocations,
  timeoffs,
  holidays,
  chartDisplayDateRange,
  showToolTip,
  resourceId,
  ToolTipComponent
}) => {
  const timeOffPeriods = mapToEntryDates(timeoffs || []);
  const holidayPeriods = (holidays || []).map(h => ({
    ...h,
    entryDate: mapRepliconDateToUtcObject(h.date)
  }));

  const timeoffSummaryPeriods = useTimeoffSummaryPeriods({
    resource: { timeoffs: timeoffs || [] }
  });
  const holidaySummaryPeriods = useHolidaySummaryPeriods({
    resource: { holidays: holidays || [] }
  });

  return (
    <>
      {(resourceAvailabilitySummarySeries || []).map(entry => {
        const startDate = mapIsoStringtoUtcObject(entry.dateRange.startDate);
        const endDate = mapIsoStringtoUtcObject(entry.dateRange.endDate);

        const holidayEntries = holidayPeriods.filter(
          c => c.entryDate <= endDate && c.entryDate >= startDate
        );

        const timeoffEntries = timeOffPeriods.filter(
          c => c.startDate <= endDate && c.endDate >= startDate
        );

        const filterAllocations = (resourceAllocations || []).filter(r => {
          const dateRange = getDateRangeFromScheduleRules(r.scheduleRules);

          if (!dateRange) return false;

          const scheduleStart = mapIsoStringtoUtcObject(dateRange.startDate);
          const scheduleEnd = mapIsoStringtoUtcObject(dateRange.endDate);

          return scheduleStart <= endDate && scheduleEnd >= startDate;
        });

        return (
          <div key={entry.dateRange.startDate}>
            <ResourceUserPeriodSummaryBlock
              key={entry.dateRange.startDate}
              scale={scale}
              chartStartDate={chartStartDate}
              startDate={startDate}
              endDate={endDate}
              resourceAllocations={filterAllocations}
              allocatedDuration={entry.allocatedDuration}
              holidayDuration={entry.holidayDuration}
              scheduledDuration={entry.scheduledDuration}
              timeOffDuration={entry.timeOffDuration}
              holidays={holidayEntries}
              timeOffs={timeoffEntries}
              showToolTip={showToolTip}
              resourceId={resourceId}
              isAllocationsLazyLoaded={isAllocationsLazyLoaded}
              ToolTipComponent={ToolTipComponent}
            />
          </div>
        );
      })}
      <>
        <HolidaySummaryBlocks
          scale={scale}
          chartStartDate={chartStartDate}
          holidaySummaryPeriods={holidaySummaryPeriods}
          chartDisplayDateRange={chartDisplayDateRange}
          showTooltip={false}
        />
        <TimeOffSummaryBlocks
          scale={scale}
          chartStartDate={chartStartDate}
          timeoffSummaryPeriods={timeoffSummaryPeriods}
          chartDisplayDateRange={chartDisplayDateRange}
          showTooltip={false}
        />
      </>
    </>
  );
};

ResourceUserPeriodSummaryBlocks.propTypes = {
  chartDisplayDateRange: PropTypes.object.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  resourceAvailabilitySummarySeries: PropTypes.array,
  resourceAllocations: PropTypes.array,
  resourceId: PropTypes.string.isRequired,
  holidays: PropTypes.array,
  timeoffs: PropTypes.array,
  showToolTip: PropTypes.bool,
  isAllocationsLazyLoaded: PropTypes.bool.isRequired,
  ToolTipComponent: PropTypes.func
};

export default deepPure(ResourceUserPeriodSummaryBlocks);
