import React, { useCallback, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from 'react-intl';
import {
  Menu,
  MenuItem,
  IconButton,
  DialogContentText,
  Typography
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { MoneyValue } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import RemoveEntityConfirmationDialog from './RemoveEntityConfirmationDialog';

const useStyles = makeStyles(theme => ({
  removeButton: {
    padding: 0
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  LastLineItemMessage: {
    marginTop: theme.spacing(1)
  }
}));

export const DeleteLineItem = ({
  column: {
    resourceKeys,
    onLineItemDelete,
    isLastLineItem = true,
    onRemove,
    onClose,
    validateLineItemPermission = false
  },
  record
}) => {
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const isPermissionAssociated = Boolean(
    validateLineItemPermission && record.hasAccessToLineItemProject
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = useCallback(
    event => setAnchorEl(event.currentTarget),
    []
  );

  const classes = useStyles();

  const onMenuClose = useCallback(() => setAnchorEl(null), []);
  const openConfirmDialog = useCallback(() => {
    onMenuClose();
    openRemoveDialog();
  }, [onMenuClose, openRemoveDialog]);

  const [lineItemDeleteInProgress, setLineItemDeleteInProgress] = useState(
    false
  );
  const onDeleteConfirm = useCallback(async () => {
    setLineItemDeleteInProgress(true);
    await onLineItemDelete(record, isPermissionAssociated);
    setLineItemDeleteInProgress(false);
    closeRemoveDialog();
  }, [closeRemoveDialog, isPermissionAssociated, onLineItemDelete, record]);

  const onDeleteBillConfirmClick = useCallback(() => {
    onRemove();
    closeRemoveDialog();
  }, [closeRemoveDialog, onRemove]);

  const values = useMemo(
    () => ({
      amount: (
        <strong>
          <MoneyValue money={record.amount} />
        </strong>
      ),
      project:
        record.project === null ? null : (
          <strong>{record.project.displayText}</strong>
        )
    }),
    [record]
  );

  return (
    <>
      <IconButton
        aria-label={resourceKeys.deleteLineItemAriaLabel}
        onClick={handleClick}
        className={classes.removeButton}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={onMenuClose}
      >
        <MenuItem key="item-Delete" onClick={openConfirmDialog}>
          <FormattedMessage id={`${resourceKeys.removeLabel}`} />
        </MenuItem>
      </Menu>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={resourceKeys}
          open={showRemoveDialog}
          onClose={lineItemDeleteInProgress ? null : closeRemoveDialog}
          onConfirm={onDeleteConfirm}
          onDeleteBillConfirmClick={onDeleteBillConfirmClick}
          isLastLineItem={isLastLineItem}
          values={values}
          isRemoving={lineItemDeleteInProgress}
          disabled={lineItemDeleteInProgress}
        >
          {isPermissionAssociated && (
            <Typography
              className={classes.warning}
              variant="body2"
              component="div"
            >
              <FormattedMessage id={`${resourceKeys.permissionWarning}`} />
              <div>
                <FormattedMessage
                  id={`${resourceKeys.permissionRemovedWarning}`}
                />
              </div>
            </Typography>
          )}

          <DialogContentText>
            {record.project !== null ? (
              <FormattedMessage
                id={`${resourceKeys.removeMessage}`}
                values={values}
              />
            ) : (
              <FormattedMessage
                id={`${resourceKeys.removeLineItemMessageForNoProject}`}
                values={values}
              />
            )}

            {isLastLineItem && (
              <div className={classes.LastLineItemMessage}>
                <FormattedMessage id={`${resourceKeys.lastLineItemMessage}`} />
              </div>
            )}
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

DeleteLineItem.propTypes = {
  column: PropTypes.object,
  record: PropTypes.object
};

export default DeleteLineItem;
