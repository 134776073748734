import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  noValue: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    fontStyle: 'italic',
    color: theme.palette.text.secondary,
    ...(isPSATaskEstimateRollUpMobileViewEnabled && {
      fontSize: theme.spacing(1.75),
      fontWeight: 600,
      fontStyle: 'normal'
    })
  })
}));

export const TaskCardNoValue = ({ id }) => {
  const {
    featureFlags: { isPSATaskEstimateRollUpMobileViewEnabled }
  } = useMeContext();
  const classes = useStyles({ isPSATaskEstimateRollUpMobileViewEnabled });

  return (
    <Typography className={classes.noValue} variant="body2">
      <FormattedMessage id={id} />
    </Typography>
  );
};

TaskCardNoValue.propTypes = {
  id: PropTypes.string.isRequired
};

export default TaskCardNoValue;
