import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
  makeStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { MoneyValue } from '~/modules/common/components/Money';
import { useDialogState } from '~/modules/common/hooks';
import { SendBillDialog } from '~/modules/billing-invoicing/common/components/SendBillDialog';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';
import { FooterWrapper } from '~/modules/common/components/LayoutWrapper';
import { ReadOnlyActionButtons } from './ReadOnlyActionButtons';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    bottom: 0,
    position: 'sticky',
    boxShadow: theme.shadows[2],
    background: theme.palette.common.white,
    zIndex: 11,
    padding: theme.spacing(1, 2),
    minHeight: '-webkit-max-content'
  },
  deleteContainer: {
    paddingLeft: theme.spacing(10),
    width: '30%'
  },
  action: {
    margin: theme.spacing(0, 2, 0, 0)
  },
  balanceContainer: {
    flexGrow: 1,
    textAlign: 'right',
    flexShrink: 1
  },
  balanceLabel: {
    color: theme.palette.text.secondary
  },
  balanceAmount: {
    fontWeight: theme.typography.fontWeightBold,
    margin: '0px 4px 0px 4px',
    whiteSpace: 'nowrap'
  },
  link: {
    '&:focus, &:hover, &:active': {
      textDecoration: 'none'
    }
  }
});

const useActionIconStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2, 0, -2)
  }
}));

export const DrawerFooter = ({
  isSaving,
  bill,
  classes,
  canDelete,
  resourceKeys,
  balanceTotal,
  showTotal = true,
  onCancel,
  onAdd,
  onDelete,
  linkProps = null,
  editable = true,
  showReadOnlyBillActions = true,
  onCloseDrawer,
  refetchAvailableToBillTotals,
  billPermission,
  summarizeColumnOptions
}) => {
  const balanceTotalDisplay = useMemo(
    () => ({
      ...balanceTotal,
      amount:
        balanceTotal && balanceTotal.amount
          ? getRoundedValue(balanceTotal.amount, 2)
          : 0
    }),
    [balanceTotal]
  );

  const actionIconClasses = useActionIconStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const onClose = useCallback(() => setAnchorEl(null), []);
  const deleteItem = useCallback(() => {
    onClose();
    onDelete();
  }, [onClose, onDelete]);

  const handleClick = useCallback(
    event => setAnchorEl(event.currentTarget),
    []
  );
  const {
    open: dialogOpen,
    openDialog: onMenuClick,
    closeDialog: onDialogClose
  } = useDialogState(false);

  return (
    <FooterWrapper>
      <div className={classes.root}>
        {editable && (
          <>
            <Button
              className={classes.action}
              variant="contained"
              disabled={isSaving}
              onClick={onAdd}
              color="primary"
            >
              <FormattedMessage id={`${resourceKeys.actionButton}`} />
            </Button>
            <Button
              className={classes.action}
              variant="contained"
              onClick={onCancel}
              disabled={isSaving}
              color="default"
            >
              <FormattedMessage id="addDialog.cancel" />
            </Button>
          </>
        )}
        {canDelete && (
          <>
            <IconButton
              aria-label={resourceKeys.deleteMenu}
              onClick={handleClick}
              data-qe-id="deleteItemMenu"
              classes={actionIconClasses}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={onClose}
            >
              <MenuItem key="item-Delete" onClick={deleteItem}>
                <FormattedMessage id={`${resourceKeys.deleteButton}`} />
              </MenuItem>
            </Menu>
          </>
        )}
        {!editable && linkProps && showReadOnlyBillActions && (
          <>
            <ReadOnlyActionButtons
              onMenuClick={onMenuClick}
              onDelete={onDelete}
              resourceKeys={resourceKeys}
              bill={bill}
              onCloseDrawer={onCloseDrawer}
              refetchAvailableToBillTotals={refetchAvailableToBillTotals}
              billPermission={billPermission}
              summarizeColumnOptions={summarizeColumnOptions}
            />
            {Boolean(dialogOpen) && (
              <SendBillDialog
                bill={bill}
                open={dialogOpen}
                onClose={onDialogClose}
              />
            )}
          </>
        )}
        {showTotal && (
          <div className={classes.balanceContainer}>
            <FormattedMessage
              id="addDialog.balance"
              className={classes.balanceLabel}
            />
            <MoneyValue
              money={balanceTotalDisplay}
              className={classes.balanceAmount}
              dataQeId="balanceAmount"
            />
          </div>
        )}
      </div>
    </FooterWrapper>
  );
};

DrawerFooter.propTypes = {
  balanceTotal: PropTypes.object,
  editable: PropTypes.bool,
  isSaving: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  canDelete: PropTypes.bool,
  showTotal: PropTypes.bool,
  resourceKeys: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  showReadOnlyBillActions: PropTypes.bool,
  bill: PropTypes.object,
  onCloseDrawer: PropTypes.func,
  linkProps: PropTypes.object,
  billPermission: PropTypes.object,
  refetchAvailableToBillTotals: PropTypes.func,
  summarizeColumnOptions: PropTypes.any
};

export default withStyles(styles)(DrawerFooter);
