import React, { Suspense, memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withResizeDetector } from 'react-resize-detector';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { LinearQueryProgress } from '~/modules/common';
import { retryLoading } from '~/util';
import useResourceGridWidthHeightExcludePropCheckHack from '~/modules/common/hooks/useResourceGridWidthHeightExcludePropCheckHack';

export const ResourcingPlans = React.lazy(() =>
  retryLoading(() => import('./ResourcingPlans'))
);

export const ResourcingProjectPlan = React.lazy(() =>
  retryLoading(() => import('./ResourcingProjectPlan'))
);
export const ResourcingContextContainer = React.lazy(() =>
  retryLoading(() => import('./contexts/ResourcingContextContainer'))
);
const linearProgress = <LinearQueryProgress />;

export const ResourceRequestsRedirectInternal = props => (
  <Suspense fallback={linearProgress}>
    <Switch>
      <Route
        key="/resourcing/project/:slug"
        path="/resourcing/project/:slug"
        exact
      >
        <ResourcingContextContainer {...props}>
          <ResourcingProjectPlan data-testid="ResourcingProjectPlan" />
        </ResourcingContextContainer>
      </Route>
      <Route key="/resourcing/:planStatus" path="/resourcing/:planStatus" exact>
        <ResourcingContextContainer {...props}>
          <ResourcingPlans data-testid="ResourcingPlanStatus" />
        </ResourcingContextContainer>
      </Route>
      <Route key="/resourcing" path="/resourcing" exact>
        <ResourcingContextContainer {...props}>
          <ResourcingPlans data-testid="ResourcingPlans" />
        </ResourcingContextContainer>
      </Route>
      <Route key="notFound_subtab" path="*" component={RedirectToNotFound} />
    </Switch>
  </Suspense>
);
ResourceRequestsRedirectInternal.propTypes = {};

const ResourceRequestsRedirect = withResizeDetector(
  memo(
    props => <ResourceRequestsRedirectInternal {...props} />,
    useResourceGridWidthHeightExcludePropCheckHack
  )
);

export default ResourceRequestsRedirect;
