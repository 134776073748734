import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import UserItem from '../components/UserACItem';
import { onChange } from '../enhancers/facetHelpers';
import { useUsers } from './enhancers/useUsers';

const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : (
    <UserItem option={option} />
  );

export const userLabel = <FormattedMessage id="search.user" />;

export const UserFacetDetails = ({
  className,
  selected,
  setSelected,
  userAccessRoleUri,
  policyId,
  defaults = {}
}) => {
  const { formatMessage } = useIntl();

  const {
    noOptionsText = formatMessage({ id: 'userDropdown.noUser' }),
    label = formatMessage({ id: 'search.user' })
  } = defaults;
  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );

  const [searchTerm, setSearchTerm] = useState('');

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const onValueChange = useCallback(
    selectedOptions => onChange({ selectedOptions, setSelected }),
    [setSelected]
  );

  const { users, hasMore, loading } = useUsers(
    searchTerm,
    userAccessRoleUri,
    policyId
  );

  const options = (!loading && users) || [];

  return (
    <FacetDetailField label={label} className={className}>
      <SimpleAutocomplete
        options={options}
        variant="standard"
        loading={loading}
        onInputChange={onInputChange}
        getOptionDisabled={getOptionDisabled}
        hiddenLabel
        inputLabel={label}
        noOptionsText={noOptionsText}
        onChange={onValueChange}
        fullWidth
        value={selected}
        optionPropText="label"
        multiple
        renderOption={renderOption}
        optionTypeText={formatMessage({ id: 'moreOptions.resources' })}
        hasMore={hasMore}
      />
    </FacetDetailField>
  );
};

UserFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  defaults: PropTypes.object,
  userAccessRoleUri: PropTypes.string,
  policyId: PropTypes.string,
  setSelected: PropTypes.func.isRequired
};

export default UserFacetDetails;
