import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  List,
  Typography,
  makeStyles,
  Divider
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import { Skeleton } from '@mui/material';
import { User, DateField } from '~/modules/common/components';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject,
  toRepliconDate
} from '~/modules/common/dates/convert';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import useTaskOwnedByResources from '../../ReleaseResourceRequestDialog/hooks/useTaskOwnedByResources';
import useEditAllocationUserDialogState from '../hooks/useEditAllocationUserDialogState';
import useEditAllocationUserDialogFormOnChange from '../hooks/useEditAllocationUserDialogFormOnChange';
import ChangeAllocationUserOptionItem from './ChangeAllocationUserOptionItem';
import { ChangeAllocationUserOptionItemValue } from './constants';
import ChangeAllocationImpactedTasksConfirmation from './ChangeAllocationImpactedTasksConfirmation';
import EditAllocationUserListItem from './EditAllocationUserListItem';

const useListStyles = makeStyles(theme => ({
  primary: {
    fontWeight: 'normal',
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(-2)
  },
  secondary: {}
}));

export const useUserStyles = makeStyles(theme => ({
  name: {
    fontWeight: 'bold',
    ...theme.typography.h6,
    verticalAlign: 'bottom'
  },
  container: {
    padding: 0,
    display: 'inline-flex'
  }
}));

const useDateClasses = makeStyles(theme => ({
  input: {
    ...theme.typography.body2
  }
}));

const useRootClasses = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1, 0.5, 0)
  }
}));

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.table.border,
    height: '0.5px',
    borderRadius: '2px'
  },
  optionList: {
    margin: theme.spacing(0, -1),
    paddingBottom: 0
  },
  clickProtectionWrapper: {
    marginLeft: theme.spacing(1)
  },
  roleHeading: {
    width: theme.spacing(25)
  },
  dialogContent: {
    paddingTop: 0
  },
  pathIcon: {
    flexShrink: 0,
    flexGrow: 0,
    margin: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
    verticalAlign: 'middle'
  },
  detail: {
    display: 'flex',
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.table.border}`,
    '& p': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  allocationPrompt: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '2px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 1, 0, 1)
  },
  allocationDetails: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '2px',
    padding: theme.spacing(0, 1),
    paddingTop: theme.spacing(1)
  },
  dialogTitle: {
    paddingLeft: theme.spacing(4)
  }
}));

const useTaskConfirmationStyles = makeStyles(theme => ({
  alertText: {
    ...theme.typography.body2,
    textAlign: 'left',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25)
  },
  permissionContainer: {
    marginLeft: 0,
    marginRight: 0,
    paddingBottom: theme.spacing(1),
    display: 'flex',
    backgroundColor: theme.palette.common.highlight,
    borderRadius: '2px'
  },
  tasksSummaryContainer: {
    ...theme.typography.subtitle2,
    backgroundColor: theme.palette.common.highlight,
    borderRadius: '2px',
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(2, 0, 0, 0),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.primary
  },
  changeTask: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useTypographyStyles = makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

const findAllocationFromId = ({ id, resourceAllocations }) =>
  resourceAllocations.find(alloc => alloc.id === id);

export const getImpactedTasks = ({
  tasks,
  changeAllocationType,
  selectedAllocation,
  asOfDate
}) => {
  const impactedTasksAsOfDate =
    changeAllocationType === ChangeAllocationUserOptionItemValue.FULL
      ? mapIsoStringtoUtcObject(selectedAllocation.startDate)
      : mapRepliconDateToUtcObject(asOfDate);

  return (tasks || []).filter(({ startDate, endDate }) => {
    const startDateInRange = startDate
      ? mapIsoStringtoUtcObject(startDate) <=
        mapIsoStringtoUtcObject(selectedAllocation.endDate)
      : true;

    const endDateInRange = endDate
      ? impactedTasksAsOfDate <= mapIsoStringtoUtcObject(endDate)
      : true;

    return startDateInRange && endDateInRange;
  });
};

const EditAllocationUserDialog = ({
  resourceAllocations,
  open,
  onClose,
  newResourceUser,
  allocatedUser,
  handleEditResourceAllocation
}) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const listClasses = useListStyles();
  const dateClasses = useDateClasses();
  const userClasses = useUserStyles();
  const rootClasses = useRootClasses();
  const taskConfirmationClasses = useTaskConfirmationStyles();

  const { formatMessage } = useIntl();
  const {
    project: { permittedActionUris }
  } = useProjectContext();

  const {
    values: {
      changeAllocationType,
      selectedAllocationId,
      asOfDate,
      isTasksChecked
    },
    setFieldValue,
    submitForm,
    errors
  } = useEditAllocationUserDialogState({
    resourceAllocations,
    handleEditResourceAllocation,
    onDialogClose: onClose,
    newResourceUser,
    allocatedUser
  });

  const selectedAllocation = findAllocationFromId({
    id: selectedAllocationId,
    resourceAllocations
  });

  const {
    resources,
    isLoading: isLoadingImpactedTasksSummary
  } = useTaskOwnedByResources({
    projectId: selectedAllocation.projectUri,
    resourceIds: [selectedAllocation.user.userUri]
  });

  const tasks = resources.length && resources[0].assignedTasks;

  const impactedTasks = getImpactedTasks({
    tasks,
    selectedAllocation,
    changeAllocationType,
    asOfDate
  });

  const {
    onAllocationChange,
    onDateChange,
    onAllocationTypeChange,
    onTasksCheckedChange
  } = useEditAllocationUserDialogFormOnChange({
    setFieldValue,
    impactedTasks
  });

  const showRadioButton = resourceAllocations.length > 1;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-allocation-user-dialog-title"
      aria-describedby="edit-allocation-user-dialog-description"
    >
      <DialogTitle
        id="edit-allocation-user-dialog-title"
        className={classes.dialogTitle}
      >
        {formatMessage({
          id: 'editAllocationUserDialog.changing'
        })}
        : <User user={allocatedUser.user} classes={userClasses} />
        <ArrowForwardIcon
          fontSize="small"
          className={classes.pathIcon}
          mirror="rtl"
        />
        <User user={newResourceUser} classes={userClasses} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.allocationDetails}>
          <div className={classes.detail}>
            <div className={classes.roleHeading}>
              <Typography
                variant="subtitle2"
                classes={typographyClasses}
                noWrap
              >
                {formatMessage({
                  id: 'editAllocationUserDialog.roles'
                })}
              </Typography>
            </div>
            <Typography variant="subtitle2" classes={typographyClasses} noWrap>
              {formatMessage({
                id: 'editAllocationUserDialog.allocation'
              })}
            </Typography>
          </div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedAllocationId}
            name="radio-buttons-group"
          >
            <List component="div" className={classes.optionList} dense={false}>
              {resourceAllocations.map(alloc => (
                <EditAllocationUserListItem
                  key={alloc.id}
                  onAllocationChange={onAllocationChange}
                  allocation={alloc}
                  showRadioButton={showRadioButton}
                />
              ))}
            </List>
          </RadioGroup>
        </div>
        <div className={classes.allocationPrompt}>
          <Typography variant="subtitle2" classes={typographyClasses}>
            {formatMessage({
              id: 'editAllocationUserDialog.changeAllocationPrompt'
            })}
          </Typography>
          <RadioGroup
            aria-label={formatMessage({
              id: 'editAllocationUserDialog.changeResourceOptions'
            })}
            name="changeResourceOptions"
            value={changeAllocationType}
          >
            <List component="div" className={classes.optionList} dense={false}>
              <ChangeAllocationUserOptionItem
                listClasses={listClasses}
                classes={rootClasses}
                value={ChangeAllocationUserOptionItemValue.ASOFDATE}
                primary={
                  <>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'editAllocationUserDialog.changeAsOf'
                      })}
                    </Typography>
                    <span className={classes.clickProtectionWrapper}>
                      <DateField
                        editable
                        onChange={onDateChange}
                        fullWidth={false}
                        hiddenLabel
                        value={toRepliconDate(asOfDate)}
                        allowKeyboardControl={false}
                        variant="filled"
                        classes={dateClasses}
                      />
                    </span>
                  </>
                }
                onSelect={onAllocationTypeChange}
              />
              <ChangeAllocationUserOptionItem
                listClasses={listClasses}
                classes={rootClasses}
                value={ChangeAllocationUserOptionItemValue.FULL}
                primary={
                  <>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'editAllocationUserDialog.changeForEntireAllocation'
                      })}
                    </Typography>
                  </>
                }
                onSelect={onAllocationTypeChange}
              />
            </List>
          </RadioGroup>
        </div>
        {isLoadingImpactedTasksSummary ? (
          <Skeleton width="100%" height={70} />
        ) : (
          <ChangeAllocationImpactedTasksConfirmation
            selectedOption={changeAllocationType}
            tasksChecked={isTasksChecked}
            impactedTasks={impactedTasks}
            setTasksChecked={onTasksCheckedChange}
            existingResourceUser={allocatedUser.user}
            newResourceUser={newResourceUser}
            permissions={permittedActionUris}
            classes={taskConfirmationClasses}
          />
        )}
        <Divider variant="fullWidth" className={classes.divider} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {formatMessage({
            id: 'editAllocationUserDialog.cancel'
          })}
        </Button>
        <Button
          onClick={submitForm}
          color="primary"
          disabled={errors.length > 0}
        >
          {formatMessage({
            id: 'editAllocationUserDialog.changeResource'
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditAllocationUserDialog.propTypes = {
  resourceAllocations: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  newResourceUser: PropTypes.object.isRequired,
  handleEditResourceAllocation: PropTypes.func.isRequired,
  allocatedUser: PropTypes.object.isRequired
};

export default EditAllocationUserDialog;
