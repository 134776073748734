import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { recursivelyStripTypeName } from '~/util';
import { extensionFieldValueFragment } from '~/modules/extensionFields/fragments';

const UPDATE_TASK = gql`
  mutation UpdateTask($updateTaskInput: UpdateTaskInput!) {
    updateTask(input: $updateTaskInput) {
      task {
        id
        name
        displayText
        code
        startDate
        endDate
        description
        isTimeEntryAllowed
        isMilestone
        initialEstimatedHours
        initialEstimatedCost {
          amount
          currency {
            id
            displayText
          }
        }
        extensionFieldValues {
          ...ExtensionFieldValue
        }
        assignedUserUri
        assignedRoleUri
        costTypeUri
        timeAndExpenseEntryType {
          uri
        }
        assignedUserRoleId
        costTypeUri
      }
      error {
        type
        reason
      }
    }
  }
  ${extensionFieldValueFragment}
`;

const useUpdateTask = () => {
  const [updateTaskMutation] = useMutation(UPDATE_TASK);
  const updateTask = updateTaskInput => {
    const input = recursivelyStripTypeName(updateTaskInput);

    return updateTaskMutation({
      variables: {
        updateTaskInput: {
          ...input,
          assignedTimesheetAccessUris:
            updateTaskInput.assignedTimesheetAccessUris,
          unAssignedTimesheetAccessUris:
            updateTaskInput.unAssignedTimesheetAccessUris
        }
      },
      refetchQueries: [
        'Eager_getTaskDetails',
        'GetMyTasks',
        'getProjectBySlug'
      ],
      awaitRefetchQueries: true
    });
  };

  return { updateTask };
};

export default useUpdateTask;
