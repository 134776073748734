/* eslint-disable react/no-set-state */

/**
 * Wrap components that may fail in this component to prevent them
 * from crashing the entire application when an error occurs.
 *
 * Roughly based on https://github.com/bvaughn/react-error-boundary
 *
 * onDidCatch method in @hocs/with-lifecycle doesn't provide access
 * to the component props which means it's almost entirely useless.
 * We need to use a class here to be able to implement componentDidCatch.
 */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import ErrorBoundaryFallbackComponent from './ErrorBoundaryFallbackComponent';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      info: null,
      error: null,
      eventId: null
    };
  }

  componentDidCatch(error, info) {
    const { onError, reportToSentry } = this.props;

    if (reportToSentry) {
      Sentry.withScope(scope => {
        scope.setExtra(info);
        const eventId = Sentry.captureException(error);

        this.setState({ eventId });
      });
    }

    if (typeof onError === 'function') {
      try {
        onError(error);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    this.setState({
      error,
      info
    });
  }

  render() {
    const { error, info, eventId } = this.state;
    const {
      children,
      FallbackComponent = ErrorBoundaryFallbackComponent,
      fallbackComponentProps = {}
    } = this.props;

    if (error !== null) {
      return (
        <FallbackComponent
          error={error}
          eventId={eventId}
          componentStack={info ? info.componentStack : ''}
          {...fallbackComponentProps}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  reportToSentry: true
};

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  FallbackComponent: PropTypes.any,
  fallbackComponentProps: PropTypes.object,
  reportToSentry: PropTypes.bool,
  onError: PropTypes.func
};

export default ErrorBoundary;
