import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  spinner: {
    color: theme.palette.text.disabled,
    position: 'absolute'
  }
}));

export const LoadingButton = ({
  children,
  isLoading,
  spinnerSize = 24,
  spinnerClassName,
  dataQeId,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      data-qe-id={dataQeId && `${dataQeId}_LoadingButton`}
      role={isLoading ? 'presentation' : 'button'}
      {...rest}
    >
      {isLoading && (
        <CircularProgress
          className={classNames(classes.spinner, spinnerClassName)}
          size={spinnerSize}
        />
      )}
      <span
        style={{
          visibility: isLoading ? 'hidden' : 'visible'
        }}
      >
        {children}
      </span>
    </Button>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  spinnerSize: PropTypes.number,
  spinnerClassName: PropTypes.string,
  dataQeId: PropTypes.string
};

export default LoadingButton;
