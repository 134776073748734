export const allowedDataAccessLevelUris = [
  'urn:replicon:user-data-access-level:project-manager',
  'urn:replicon:user-data-access-level:resource-manager',
  'urn:replicon:user-data-access-level:resource-pool-manager',
  'urn:replicon:user-data-access-level:administrative-data-access-scope'
];

const useProjectResourcePlanPermissions = permissionsMap => ({
  isViewAdvancedSettingsEnabled: (
    permissionsMap['urn:replicon:user-action:view-advanced-settings']
      ?.dataAccessLevelUris || []
  ).some(sr => allowedDataAccessLevelUris.includes(sr)),
  isViewAvailabilitySettingsEnabled: (
    permissionsMap['urn:replicon:user-action:view-availability']
      ?.dataAccessLevelUris || []
  ).some(sr => allowedDataAccessLevelUris.includes(sr))
});

export default useProjectResourcePlanPermissions;
