import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { ReleaseResourceAllocationType } from '~/types';
import { specificResourceAllocationFragment } from '~/modules/resourcing/common/fragments';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me';
import {
  QUICK_ALLOCATIONS_QUERY,
  tryLoadCachedAllocationQuery
} from '../../hooks/useQuickAllocationProjectAllocationsByIds';
import useQuickAllocationContext from '../../hooks/useQuickAllocationContext';
import {
  tryLoadCachedResourceUsersQuery,
  getUpdatedResourceUsersSummaryAllocation
} from '../../hooks/useFulfilledResourceAllocationHandler';
import { RESOURCE_USERS_QUERY } from '../../hooks/useResourceUsers';

export const RELEASE_RESOURCE_ALLOCATION = gql`
  mutation releaseResourceAllocations(
    $input: BulkReleaseResourceAllocationsInput!
  ) {
    bulkReleaseResourceAllocations(input: $input) {
      resourceAllocations {
        ...SpecificResourceAllocation
      }
    }
  }
  ${specificResourceAllocationFragment}
`;

export const updateResourceAllocationsQueryCache = ({
  proxy,
  resourceAllocations,
  allocationDetailsQueryCacheProps: { allocationIds },
  releaseType,
  releaseDate
}) => {
  const allocationQueryvariables = {
    filter: { resourceAllocationIds: allocationIds }
  };
  const results = tryLoadCachedAllocationQuery({
    proxy,
    variables: allocationQueryvariables
  });

  if (!results) return;
  const {
    resourceAllocations: {
      resourceAllocations: cachedAllocations,
      nextPageCursor
    }
  } = results;

  proxy.writeQuery({
    query: QUICK_ALLOCATIONS_QUERY,
    variables: allocationQueryvariables,
    data: {
      resourceAllocations: {
        resourceAllocations: cachedAllocations.filter(cachedAllocation => {
          const matchingAllocation = resourceAllocations.find(
            resourceAllocation => resourceAllocation.id === cachedAllocation.id
          );

          return (
            matchingAllocation === undefined ||
            (releaseType === ReleaseResourceAllocationType.Asof &&
              mapIsoStringtoUtcObject(matchingAllocation.startDate) <
                mapIsoStringtoUtcObject(releaseDate))
          );
        }),
        nextPageCursor,
        __typename: 'ResourceAllocationsResponse'
      }
    }
  });
};

export const getReleaseResourceSnackBarMessageId = ({
  asOfDate,
  releasedRoles
}) =>
  asOfDate
    ? releasedRoles.length === 1
      ? 'releaseResourceAllocationSnackBar.releaseResourceAsOf'
      : 'releaseResourceAllocationSnackBar.releaseResourceMultipleRolesAsOf'
    : releasedRoles.length === 1
    ? 'releaseResourceAllocationSnackBar.releaseResource'
    : 'releaseResourceAllocationSnackBar.releaseResourceMultipleRoles';

export const setSnackBarMessage = ({
  asOfDate,
  userName,
  projectName,
  releasedRoles,
  setSnackBarState,
  formatMessage
}) => {
  const snackBarMessageId = getReleaseResourceSnackBarMessageId({
    asOfDate: Boolean(asOfDate),
    releasedRoles
  });

  setSnackBarState({
    open: true,
    message: formatMessage(
      { id: snackBarMessageId },
      {
        username: userName,
        projectname: projectName,
        role:
          releasedRoles[0] ||
          formatMessage({
            id: 'releaseResourceAllocationSnackBar.noRole'
          }),
        count: releasedRoles.length,
        date: asOfDate
      }
    )
  });
};

export const updateUserSummaryCacheOnDelete = ({
  mutationResponse,
  proxy,
  releasedResourcesInfo: { selectedAllocations },
  userSummaryQueryCacheProps
}) => {
  const {
    resourceAllocations: allResourceAllocations,
    resourceUsersCurrentPage,
    dateRange,
    userSort,
    periodResolution,
    filter,
    isAvailabilityEnabled,
    isViewResourceActualsEnabled
  } = userSummaryQueryCacheProps;

  const previousResourceAllocations = allResourceAllocations.filter(alloc => {
    const releaseAlloc = selectedAllocations.find(
      releasedAlloc => releasedAlloc.id === alloc.id
    );

    return Boolean(releaseAlloc);
  });

  const variables = {
    dateRange,
    filter,
    isAvailabilityEnabled,
    isViewResourceActualsEnabled,
    page: resourceUsersCurrentPage,
    pagesize: 50,
    periodResolution,
    sort: userSort,
    skipMerge: true
  };

  const results = tryLoadCachedResourceUsersQuery({
    proxy,
    variables
  });

  if (!results) return;

  const { resourceUsers2 } = results;

  const releasedResourceUsers = previousResourceAllocations.reduce(
    (acc, alloc) =>
      getUpdatedResourceUsersSummaryAllocation({
        resourceUsers: acc,
        resourceAllocation: alloc,
        updateHoursCallback: ({ periodHours, summary }) =>
          periodHours > 0
            ? Math.round(summary.allocatedDuration.hours * 100) / 100 -
              periodHours
            : summary.allocatedDuration.hours
      }),
    resourceUsers2
  );

  const {
    data: {
      bulkReleaseResourceAllocations: {
        resourceAllocations: asOfResourceAllocations
      }
    }
  } = mutationResponse;

  const updatedResourceUsers = asOfResourceAllocations.reduce(
    (acc, alloc) =>
      getUpdatedResourceUsersSummaryAllocation({
        resourceUsers: acc,
        resourceAllocation: alloc,
        updateHoursCallback: ({ periodHours, summary }) =>
          periodHours > 0
            ? summary.allocatedDuration.hours + periodHours
            : summary.allocatedDuration.hours
      }),
    releasedResourceUsers
  );

  proxy.writeQuery({
    query: RESOURCE_USERS_QUERY,
    variables,
    data: {
      resourceUsers2: updatedResourceUsers
    }
  });
};

export const updateAllocatedProjectsCacheOnDelete = ({
  allocationDetailsQueryCacheProps,
  formatMessage,
  handleRemoveResourceAllocation,
  mutationResponse,
  projectId,
  projectName,
  proxy,
  userName,
  releasedResourcesInfo,
  setSnackBarState
}) => {
  const {
    selectedAllocations,
    releaseType,
    releaseDate,
    formattedReleaseDate
  } = releasedResourcesInfo;

  const releasedRoles = selectedAllocations.map(
    allocation => allocation.role?.displayText
  );

  setSnackBarMessage({
    userName,
    projectName,
    releasedRoles,
    asOfDate:
      releaseType === ReleaseResourceAllocationType.Asof
        ? formattedReleaseDate
        : undefined,
    setSnackBarState,
    formatMessage
  });
  const {
    data: {
      bulkReleaseResourceAllocations: { resourceAllocations }
    }
  } = mutationResponse;

  if (
    releaseType === ReleaseResourceAllocationType.Asof &&
    resourceAllocations.length !== 0
  ) {
    updateResourceAllocationsQueryCache({
      proxy,
      resourceAllocations,
      allocationDetailsQueryCacheProps,
      releaseType,
      releaseDate
    });
  }

  const releasedAllocationsIdsFromInput = selectedAllocations.map(
    allocation => allocation.id
  );

  const deletedAllocationIds = releasedAllocationsIdsFromInput.filter(
    id => !resourceAllocations.some(alloc => alloc.id === id)
  );

  if (deletedAllocationIds.length === 0) return;
  handleRemoveResourceAllocation({
    deletedAllocationIds,
    projectId
  });
};

export const updateCaches = ({
  allocationDetailsQueryCacheProps,
  formatMessage,
  handleRemoveResourceAllocation,
  projectId,
  projectName,
  releasedResourcesInfo,
  setSnackBarState,
  userName,
  userSummaryQueryCacheProps
}) => (proxy, mutationResponse) => {
  updateAllocatedProjectsCacheOnDelete({
    allocationDetailsQueryCacheProps,
    formatMessage,
    handleRemoveResourceAllocation,
    mutationResponse,
    projectId,
    projectName,
    proxy,
    releasedResourcesInfo,
    setSnackBarState,
    userName
  });

  updateUserSummaryCacheOnDelete({
    mutationResponse,
    proxy,
    releasedResourcesInfo,
    userSummaryQueryCacheProps
  });
};

const useBulkReleaseResourceAllocations = ({
  allocatedProject,
  handleRemoveResourceAllocation,
  resourceAllocations,
  setSnackBarState,
  userName
}) => {
  const { permissionsMap } = useMeContext();
  const allocationIds = allocatedProject.roles.reduce(
    (ids, role) => [
      ...ids,
      ...role.resourceAllocationReference.map(ref => ref.id)
    ],
    []
  );

  const {
    resourceUsersCurrentPage,
    sort: userSort,
    dateRange,
    periodResolution,
    filter,
    isShowActualEnabled
  } = useQuickAllocationContext();

  const [releaseAllocations, { data, loading }] = useMutation(
    RELEASE_RESOURCE_ALLOCATION
  );
  const { formatMessage } = useIntl();

  const canViewAvailability = Boolean(
    permissionsMap['urn:replicon:user-action:view-availability']
  );

  const releaseResourceAllocationCallback = useCallback(
    releasedResourcesInfo =>
      releaseAllocations({
        variables: {
          input: {
            releaseType: releasedResourcesInfo.releaseType,
            allocationIds: releasedResourcesInfo.selectedAllocations.map(
              allocation => allocation.id
            ),
            releaseDate: releasedResourcesInfo.releaseDate,
            comment: releasedResourcesInfo.comment
          }
        },
        update: updateCaches({
          handleRemoveResourceAllocation,
          projectId: allocatedProject.project.id,
          allocationDetailsQueryCacheProps: {
            allocationIds
          },
          releasedResourcesInfo,
          projectName: allocatedProject.project.displayText,
          userName,
          setSnackBarState,
          formatMessage,
          userSummaryQueryCacheProps: {
            resourceUsersCurrentPage,
            userSort: [userSort],
            dateRange,
            periodResolution,
            filter,
            isAvailabilityEnabled: canViewAvailability,
            resourceAllocations,
            isViewResourceActualsEnabled: isShowActualEnabled
          }
        })
      }),
    [
      allocatedProject,
      allocationIds,
      canViewAvailability,
      dateRange,
      filter,
      formatMessage,
      handleRemoveResourceAllocation,
      isShowActualEnabled,
      periodResolution,
      releaseAllocations,
      resourceAllocations,
      resourceUsersCurrentPage,
      setSnackBarState,
      userName,
      userSort
    ]
  );

  return {
    releaseResourceAllocation: releaseResourceAllocationCallback,
    data,
    loading
  };
};

export default useBulkReleaseResourceAllocations;
