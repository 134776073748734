import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { mapPaymentsToAssociatedBills } from '~/modules/billing-invoicing/common/util';
import { isoToReplicon } from '~/util/date';

export const billPaymentQuery = gql`
  query getBillPayment($id: String!) {
    getBillPayment(id: $id) {
      id
      displayId
      description
      paymentDate: paymentDate2
      paymentMethodUri
      paymentMethod {
        id
        displayText
      }
      paymentAmount {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      client {
        id
        displayText
      }
      balanceStatus
      associatedBills(page: 1, pageSize: 20) {
        associatedBills {
          id
          displayText
          dueDate: dueDate2
          balanceAmount {
            amount
            currency {
              id
              symbol
            }
          }
          allocatedBillAmount {
            amount
            currency {
              id
              displayText
              symbol
            }
          }
          allocatedBillPaymentAmount {
            amount
            currency {
              id
              displayText
              symbol
            }
          }
        }
      }
    }
  }
`;

export const useBillPaymentDetails = ({ id }) => {
  const { data, loading } = useQuery(billPaymentQuery, {
    variables: {
      id
    },
    skip: !id,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const getBillPayment = get(data, 'getBillPayment', {
    paymentAmount: {},
    associatedBills: {}
  });

  const {
    paymentAmount: { amount, currency },
    paymentMethod = {},
    associatedBills: { associatedBills }
  } = getBillPayment;

  return {
    loading,
    billPayment: {
      ...getBillPayment,
      currency,
      paymentAmount: amount,
      paymentMethod,
      isRefund: amount < 0,
      associatedBills: mapPaymentsToAssociatedBills(associatedBills),
      paymentDate: isoToReplicon(getBillPayment.paymentDate)
    }
  };
};

export default useBillPaymentDetails;
