import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PROJECT_STATUS,
  PROJECT_EXECUTION_PHASE,
  PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS
} from '~/modules/common/enums';

export const executionPhases = {
  [PROJECT_STATUS.INITIATE]: {
    value: PROJECT_EXECUTION_PHASE.INITIATE,
    key: 'Initiate'
  },
  [PROJECT_STATUS.PLANNING]: {
    value: PROJECT_EXECUTION_PHASE.PLANNING,
    key: 'Planning'
  },
  [PROJECT_STATUS.EXECUTION]: {
    value: PROJECT_EXECUTION_PHASE.EXECUTION,
    key: 'Execution'
  },
  [PROJECT_STATUS.CLOSEOUT]: {
    value: PROJECT_EXECUTION_PHASE.CLOSEOUT,
    key: 'Close Out'
  },
  [PROJECT_STATUS.ARCHIVED]: {
    value: PROJECT_EXECUTION_PHASE.ARCHIVED,
    key: 'Archived'
  }
};

const useGetClickHandler = ({ status, setSearchCriteria, setKeys }) => {
  const history = useHistory();

  return useCallback(() => {
    const { value: executionPhaseId } = executionPhases[status];

    setKeys &&
      setKeys(
        PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS,
        executionPhaseId
      );
    setSearchCriteria &&
      setSearchCriteria({
        keywords: [],
        criterions: {}
      });
    history.push({
      pathname: `${history.location.pathname}/projects`,
      state: {
        executionPhaseId
      }
    });
  }, [status, setKeys, setSearchCriteria, history]);
};

export const useProjectsSummaryStatusClickHandler = (
  setSearchCriteria,
  setKeys
) => {
  const initiateHandler = useGetClickHandler({
    status: PROJECT_STATUS.INITIATE,
    setSearchCriteria,
    setKeys
  });

  const planningHandler = useGetClickHandler({
    status: PROJECT_STATUS.PLANNING,
    setSearchCriteria,
    setKeys
  });

  const executionHandler = useGetClickHandler({
    status: PROJECT_STATUS.EXECUTION,
    setSearchCriteria,
    setKeys
  });

  const closeOutHandler = useGetClickHandler({
    status: PROJECT_STATUS.CLOSEOUT,
    setSearchCriteria,
    setKeys
  });

  const archivedHandler = useGetClickHandler({
    status: PROJECT_STATUS.ARCHIVED,
    setSearchCriteria,
    setKeys
  });

  const onDataClick = useCallback(
    (_, e) => {
      if (e && e.datum && e.datum.id) {
        const eventId = e.datum.id;

        if (eventId === PROJECT_STATUS.INITIATE) return initiateHandler();

        if (eventId === PROJECT_STATUS.PLANNING) return planningHandler();

        if (eventId === PROJECT_STATUS.EXECUTION) return executionHandler();

        if (eventId === PROJECT_STATUS.ARCHIVED) return archivedHandler();

        if (eventId === PROJECT_STATUS.CLOSEOUT) return closeOutHandler();
      }

      return null;
    },
    [
      initiateHandler,
      planningHandler,
      executionHandler,
      archivedHandler,
      closeOutHandler
    ]
  );

  return useMemo(
    () => ({
      events: [
        {
          childName: ['all'],
          target: 'data',
          eventHandlers: {
            onClick: onDataClick
          }
        }
      ],
      legendClickHandlers: {
        [PROJECT_STATUS.INITIATE]: initiateHandler,
        [PROJECT_STATUS.PLANNING]: planningHandler,
        [PROJECT_STATUS.EXECUTION]: executionHandler,
        [PROJECT_STATUS.CLOSEOUT]: closeOutHandler,
        [PROJECT_STATUS.ARCHIVED]: archivedHandler
      }
    }),
    [
      closeOutHandler,
      executionHandler,
      initiateHandler,
      planningHandler,
      archivedHandler,
      onDataClick
    ]
  );
};
