import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { useTimeAndExpenseThemeContext } from '../../TimeAndExpenseDetailsThemeContextProvider';
import withStyles from './withStyles';
import GroupedHeaders from './GroupedHeaders';

export const rowGroupingIdentity = (groupings, index) =>
  groupings.reduce(
    (identity, grouping) =>
      `${identity}:${grouping.key}-${index}-${
        grouping.value && grouping.value.uri
          ? grouping.value.uri
          : grouping.value
      }`,
    ''
  );

export const GroupedColumn = ({
  classes,
  settings,
  records,
  record,
  index,
  hasMore,
  skeletons
}) => {
  const themeClasses = useTimeAndExpenseThemeContext();

  return (
    <div className={classes.column}>
      <GroupedHeaders
        settings={settings}
        record={record}
        index={index}
        records={records}
      />
      <div className={classes.subcolumns}>
        {settings.columns.options
          .filter(({ key }) => settings.columns.values.includes(key))
          .map(column => (
            <div key={column.key} className={classes.subcolumn}>
              <div
                className={classNames(
                  classes.subheader,
                  classes.datumSubheader,
                  themeClasses.subheader
                )}
              >
                {column.label}
              </div>
              {record.rows.map(({ groupings, cells }, i) => (
                <div
                  key={rowGroupingIdentity(groupings, i)}
                  className={classes.cell}
                >
                  <div
                    className={classes.boldDatum}
                    style={{ minHeight: `${settings.heightMap[i]}px` }}
                  >
                    {settings.columns.formatters[column.key](cells[column.key])}
                  </div>
                </div>
              ))}
              {hasMore &&
                skeletons.map(value => (
                  <div key={value} className={classes.cell}>
                    <div className={classes.boldDatum}>
                      <Skeleton />
                    </div>
                  </div>
                ))}
              <div
                className={classes.footer}
                style={{ minHeight: `${settings.heightMap.footer + 16}px` }}
              >
                {settings.columns.formatters[column.key](
                  record.totals[column.key]
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

GroupedColumn.propTypes = {
  classes: PropTypes.object,
  settings: PropTypes.object,
  records: PropTypes.array,
  record: PropTypes.object,
  index: PropTypes.number,
  hasMore: PropTypes.bool,
  skeletons: PropTypes.array
};

export default withStyles(GroupedColumn);
