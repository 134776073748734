import React, { ReactElement, useMemo } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { makeStyles, ButtonBase, alpha } from '@material-ui/core';
import { RequestAttributeWeightage } from '~/types';

const useStyles = makeStyles(theme => {
  const hoverColor = alpha(
    theme.palette.text.primary,
    theme.palette.action.hoverOpacity
  );

  return {
    root: {
      ...theme.typography.caption,
      textTransform: 'none',
      display: 'inline-block',
      background: theme.palette.grey[100],
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 0, 1.5),
      padding: theme.spacing(0, 0.5, 0, 0.25),
      lineHeight: `${theme.spacing(2)}px`,
      position: 'relative',
      '&:before': {
        content: `' '`,
        display: 'inline-block',
        position: 'absolute',
        top: 0,
        height: 0,
        width: 0,
        overflow: 'hidden',
        left: theme.spacing(-1),
        borderTop: `${theme.spacing(1)}px solid transparent`,
        borderBottom: `${theme.spacing(1)}px solid transparent`,
        borderRight: `${theme.spacing(1)}px solid ${theme.palette.grey[100]}`
      },
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: hoverColor,
        '&:before': {
          borderRightColor: hoverColor
        },
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    },
    label: {}
  };
});

const WeightTag = ({
  weight,
  onClick,
  className
}: {
  weight?: RequestAttributeWeightage;
  onClick?: () => void;
  className?: string;
}): ReactElement | null => {
  const classes = useStyles();
  const intl = useIntl();

  const weightLabels = useMemo(
    () => ({
      [RequestAttributeWeightage.NiceToHave]: intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.NiceToHave}`
      }),
      [RequestAttributeWeightage.Useful]: intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Useful}`
      }),
      [RequestAttributeWeightage.Normal]: intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Normal}`
      }),
      [RequestAttributeWeightage.Important]: intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Important}`
      }),
      [RequestAttributeWeightage.Required]: intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Required}`
      })
    }),
    [intl]
  );

  const RootComponent: any = onClick ? ButtonBase : 'span';
  const componentSpecificArgs = useMemo(
    () =>
      onClick
        ? {
            component: 'span',
            onClick
          }
        : {},
    [onClick]
  );

  if (!weight || weight === RequestAttributeWeightage.Normal) {
    return null;
  }

  return (
    <RootComponent
      className={classnames(classes.root, className)}
      {...componentSpecificArgs}
    >
      <span className={classes.label}>{weightLabels[weight]}</span>
    </RootComponent>
  );
};

export default WeightTag;
