import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmployeeIDIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 10 3 L 8.888671875 8 L 4.5 8 L 4 10 L 8.4453125 10 L 7.5546875 14 L 3.5 14 L 3 16 L 7.111328125 16 L 6 21 L 8 21 L 9.111328125 16 L 13.111328125 16 L 12 21 L 14 21 L 15.111328125 16 L 19.5 16 L 20 14 L 15.5546875 14 L 16.4453125 10 L 20.5 10 L 21 8 L 16.888671875 8 L 18 3 L 16 3 L 14.888671875 8 L 10.888671875 8 L 12 3 L 10 3 z M 10.4453125 10 L 14.4453125 10 L 13.5546875 14 L 9.5546875 14 L 10.4453125 10 z " />
    </>
  </SvgIcon>
));

export default EmployeeIDIcon;
