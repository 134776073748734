import { withStyles, makeStyles } from '@material-ui/core';

export const styles = theme => ({
  container: {
    padding: theme.spacing(0, 1, 1, 1),
    margin: 0,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.table.border}`,
    minHeight: '-webkit-max-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  root: {},
  add: {
    margin: theme.spacing(1.5, 0, 0, 0),
    flexShrink: 0,
    flexGrow: 0
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
});

export const useNoDataStyles = makeStyles(theme => ({
  noData: {
    padding: theme.spacing(0, 0, 0, 1)
  }
}));

export default withStyles(styles);
