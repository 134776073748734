import { useCallback } from 'react';
import usePutProjectRequestScore from './usePutProjectRequestScore';

export const calculateTotal = scoreToCalculateTotalFrom => {
  const { value, investment, risk } = scoreToCalculateTotalFrom;

  if (!value || !investment) return null;

  const roi = ((value - investment) * 100) / investment;

  if (!risk || risk === 1) return roi;

  return roi < 0
    ? roi * (1 + (risk - 1) * 0.05)
    : roi * (1 - (risk - 1) * 0.05);
};

export const useScoreItemHandlers = ({
  id,
  setFieldValue,
  score,
  scoreMetricKey
}) => {
  const { putProjectRequestScore } = usePutProjectRequestScore({ id, score });
  const handleSubmitScore = useCallback(() => {
    putProjectRequestScore();
  }, [putProjectRequestScore]);

  const handleScoreChange = useCallback(
    (_, v) => {
      setFieldValue(`score.${scoreMetricKey}`, v);
      setFieldValue(
        'score.total',
        calculateTotal({ ...score, [scoreMetricKey]: v })
      );
    },
    [score, scoreMetricKey, setFieldValue]
  );

  return { handleSubmitScore, handleScoreChange };
};

export default useScoreItemHandlers;
