import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { isoStringToFormattedISOString } from '~/modules/common/dates/convert';

const useStyles = makeStyles({
  subheading: {
    display: 'flex',
    flexDirection: 'column'
  },
  subheadingLabel: {
    fontSize: theme.spacing(1.25),
    color: theme.palette.taskRollUp.text.subHeading
  },
  noDate: {
    color: theme.palette.error.dark
  },
  date: {
    fontSize: theme.spacing(1.5),
    fontWeight: '600'
  },
  noDateIcon: {
    fontSize: theme.spacing(2.25),
    fill: theme.palette.error.dark
  },
  noStartDateIcon: {
    marginLeft: theme.spacing(1.5)
  },
  noEndDateIcon: {
    marginLeft: theme.spacing(1.25)
  },
  dateWithFFOn: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },
  subheadingLabelWithFFOn: {
    fontSize: theme.typography.caption.fontSize
  }
});

export const TooltipDate = ({
  intl,
  task,
  isStartDate,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles();

  const label = intl.formatMessage({
    id: isStartDate ? 'projectTasksPage.startDate' : 'projectTasksPage.endDate'
  });
  const hasDate = isStartDate ? task.startDate !== null : task.endDate !== null;
  const formattedDate =
    hasDate &&
    isoStringToFormattedISOString(
      isStartDate ? task.startDate : task.endDate,
      'DD'
    );

  return (
    <div className={classes.subheading}>
      <span
        className={classNames(classes.subheadingLabel, {
          [classes.noDate]: !hasDate,
          [classes.subheadingLabelWithFFOn]: isPsaRmpTaskAllocation1Enabled
        })}
      >
        {label}
      </span>
      <span
        className={classNames(classes.date, {
          [classes.dateWithFFOn]: isPsaRmpTaskAllocation1Enabled
        })}
      >
        {hasDate ? (
          formattedDate
        ) : (
          <ReportProblemOutlined
            className={classNames(classes.noDateIcon, {
              [classes.noStartDateIcon]: isStartDate,
              [classes.noEndDateIcon]: !isStartDate
            })}
          />
        )}
      </span>
    </div>
  );
};

TooltipDate.propTypes = {
  intl: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  isStartDate: PropTypes.bool.isRequired,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default TooltipDate;
