import React from 'react';
import { useIntl } from 'react-intl';
import { AddFabButton } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { useProjectsContext } from '../../ProjectsContext';
import AddProjectDialog from './AddProjectDialog';

export const AddProject = () => {
  const { open, openDialog, closeDialog } = useDialogState();
  const { projectType } = useProjectsContext();
  const { formatMessage } = useIntl();

  return (
    <>
      <AddFabButton
        label={formatMessage({ id: 'projectAddFab.label' })}
        shortLabel={formatMessage({ id: 'projectAddFab.shortLabel' })}
        dataQeId="addProjectOpenDialog"
        onClick={openDialog}
      />
      {open && (
        <AddProjectDialog
          onClose={closeDialog}
          open={open}
          projectType={projectType}
          replaceHistoryOnClose
        />
      )}
    </>
  );
};

export default AddProject;
