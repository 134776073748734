import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import BillingOverviewTable from '../BillingOverviewTable';
import { BillingOverviewTableContextProvider } from '../BillingOverviewTable/BillingOverviewTableContext';
import BillingOverviewToolbar from '../BillingOverviewToolbar';
import BillingOverviewTabLoading from './BillingOverviewTabLoading';

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: '100%'
  }
}));

export const BillingOverviewTab = ({
  loading,
  result,
  fixedRange,
  displayTotalColumn = false,
  handleCallToAction,
  headerLevel = 0
}) => {
  const classes = useStyles();
  const {
    scale,
    chartDates,
    onPrevious,
    onNext,
    setScale,
    dateRange,
    setDate
  } = fixedRange;

  const setDateRange = useCallback(
    date => {
      setDate(date.startDate ? date.startDate : date);
    },
    [setDate]
  );

  return (
    <>
      <BillingOverviewToolbar
        scale={scale}
        onPrevious={onPrevious}
        onNext={onNext}
        setScale={setScale}
        dateRange={dateRange}
        setDate={setDateRange}
      />
      {loading ? (
        <BillingOverviewTabLoading />
      ) : (
        <div className={classes.tableContainer}>
          <BillingOverviewTableContextProvider
            scale={scale}
            dateRange={dateRange}
            chartDates={chartDates}
            headerLevel={headerLevel}
          >
            <BillingOverviewTable
              billingOverview={result}
              displayTotalColumn={displayTotalColumn}
              handleCallToAction={handleCallToAction}
            />
          </BillingOverviewTableContextProvider>
        </div>
      )}
    </>
  );
};

BillingOverviewTab.propTypes = {
  loading: PropTypes.bool,
  result: PropTypes.object,
  fixedRange: PropTypes.shape({
    chartDates: PropTypes.array.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    scale: PropTypes.string.isRequired,
    setScale: PropTypes.func.isRequired,
    dateRange: PropTypes.object.isRequired,
    setDate: PropTypes.func.isRequired
  }),
  headerLevel: PropTypes.number,
  displayTotalColumn: PropTypes.bool,
  handleCallToAction: PropTypes.func
};

export default BillingOverviewTab;
