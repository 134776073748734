import { v4 } from 'uuid';
import { getExchangeRate, getRoundedValue } from './util';

const transformDropdownResult = result => ({
  displayText: result && result.label,
  id: result && result.value
});

export const onClientChange = ({
  setFieldValue,
  values: { lineItems }
}) => async value => {
  await setFieldValue('client', transformDropdownResult(value));
  await setFieldValue('associatedBills', []);
  if (lineItems && lineItems.length) setFieldValue('lineItems', [lineItems[0]]);
};

export const onDisplayIdChange = ({ setFieldValue }) => e =>
  setFieldValue('displayId', e.target.value);

export const onDescriptionChange = ({ setFieldValue }) => e =>
  setFieldValue('description', e && typeof e === 'string' ? e : e.target.value);

export const onPONumberChange = ({ setFieldValue }) => e =>
  setFieldValue('poNumber', e.target.value);

export const onNotesForCustomerChange = ({ setFieldValue }) => e =>
  setFieldValue('notesForCustomer', e.target.value);

export const onInternalNotesChange = ({ setFieldValue }) => e =>
  setFieldValue(
    'internalNotes',
    e && typeof e === 'string' ? e : e.target.value
  );

export const onShowCommentsChange = ({ setFieldValue }) => value =>
  setFieldValue('showComments', value);

export const onProjectLineItemAdd = ({
  setFieldValue,
  lineItems,
  itemCurrency,
  selectedProject
}) => {
  const isExistingProject = lineItems.some(
    ({ project }) =>
      (project && selectedProject && project.id === selectedProject.id) ||
      (!selectedProject && project.id === null)
  );

  const updatedLineItems = lineItems.map(lineItem =>
    lineItem && lineItem.project && lineItem.project.hasFocus
      ? { ...lineItem, project: { ...lineItem.project, hasFocus: false } }
      : lineItem
  );

  if (!isExistingProject) {
    setFieldValue('lineItems', [
      ...updatedLineItems,
      {
        key: v4(),
        id: null,
        project: selectedProject
          ? {
              id: selectedProject.id,
              displayText: selectedProject.displayText,
              hasFocus: true
            }
          : { hasFocus: true, id: null },
        amount: { amount: 0, currency: itemCurrency }
      }
    ]);
  }
};

export const onAssociatedBillAmountChange = ({
  setFieldValue,
  associatedBills,
  balanceField,
  billCreditBalanceTotal,
  newValue,
  record,
  currency,
  exchangeRates
}) => {
  const updatedAmount = newValue || 0;
  const { id: recordId, balanceAmount } = record;
  let updatedBills = [];

  const billExchangeRate = getExchangeRate(
    exchangeRates,
    currency.id,
    balanceAmount.currency.id
  );

  if (associatedBills.some(bill => bill.id === recordId))
    updatedBills = associatedBills.map(bill =>
      bill.id === recordId
        ? {
            ...bill,
            amount: { ...bill.amount, amount: updatedAmount || 0 },
            allocatedBillAmount: {
              ...bill.allocatedBillAmount,
              amount: billExchangeRate * updatedAmount || 0
            }
          }
        : bill
    );
  else {
    updatedBills = associatedBills.concat({
      ...record,
      amount: { ...record.amount, amount: updatedAmount },
      allocatedBillAmount: {
        ...record.allocatedBillAmount,
        amount: billExchangeRate * updatedAmount
      }
    });
  }

  setFieldValue('associatedBills', updatedBills);
  const currentItem = associatedBills.find(bill => bill.id === recordId);
  const currentAmount =
    currentItem && currentItem.amount ? currentItem.amount.amount : 0;

  const newBalance =
    ((billCreditBalanceTotal && billCreditBalanceTotal.amount) || 0) +
    currentAmount -
    updatedAmount;

  setFieldValue(balanceField, getRoundedValue(newBalance));
};

export const onAllocatedBillAmountChange = ({
  setFieldValue,
  associatedBills,
  newValue,
  record
}) => {
  const updatedAmount = newValue || 0;
  const { id: recordId } = record;
  let updatedBills = [];

  if (associatedBills.some(bill => bill.id === recordId))
    updatedBills = associatedBills.map(bill =>
      bill.id === recordId
        ? {
            ...bill,
            allocatedBillAmount: {
              ...bill.allocatedBillAmount,
              amount: updatedAmount || 0
            }
          }
        : bill
    );
  else {
    updatedBills = associatedBills.concat({
      ...record,
      allocatedBillAmount: {
        ...record.allocatedBillAmount,
        allocatedBillAmount: updatedAmount
      }
    });
  }

  setFieldValue('associatedBills', updatedBills);
};
