import { useSubscription } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESCHEDULE_SUBSCRIPTION = gql`
  subscription RescheduleProjectStarted {
    rescheduleProjectStarted {
      id
      result
      status
    }
  }
`;

export const useRescheduleProjectSubscription = () => {
  const {
    data: { rescheduleProjectStarted: { id, result = {}, status } = {} } = {}
  } = useSubscription(RESCHEDULE_SUBSCRIPTION, {});

  return {
    result,
    status,
    id
  };
};

export default useRescheduleProjectSubscription;
