import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  task: {
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'break-spaces'
  },
  noTask: {
    color: alpha(theme.palette.common.black, 0.54),
    fontStyle: 'italic',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const TaskFormatter = ({ field, record }) => {
  const classes = useStyles();
  const displayText =
    record[field] && record[field].displayText
      ? record[field].displayText
      : record[field];

  return displayText ? (
    <Typography className={classes.task} component="div">
      {displayText}
    </Typography>
  ) : (
    <Typography className={classes.noTask} component="div">
      <FormattedMessage id="addLineItemDialog.noTask" />
    </Typography>
  );
};

TaskFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default TaskFormatter;
