import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import withHighlighting from './withHighlighting';

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0
  },
  message: {
    margin: 0,
    marginTop: '1em'
  }
}));

const withProjectCurrencyToolTip = (WrappedComponent, value, limit) => {
  let ComponentWithHighlighting = WrappedComponent;

  if (limit) {
    ComponentWithHighlighting = withHighlighting(
      WrappedComponent,
      value,
      limit
    );
  }

  const NewComponent = ({ field, record }) => {
    const classes = useStyles();
    const intl = useIntl();
    const baseAmount = get(record, value, null);
    const projectCurrencyAmount = record[field]?.amount;
    const isOLAPCalculationInProgress = baseAmount && !projectCurrencyAmount;
    const ToolTipContent = (
      <>
        <h3 data-testid="tooltip-title" className={classes.title}>
          {intl.formatMessage({
            id: 'projectList.valueUnavailableTooltip.title'
          })}
        </h3>
        <p data-testid="tooltip-content" className={classes.message}>
          {intl.formatMessage({
            id: 'projectList.valueUnavailableTooltip.content'
          })}
        </p>
      </>
    );

    return isOLAPCalculationInProgress ? (
      <Tooltip
        data-testid="olap-calculation-tooltip"
        title={ToolTipContent}
        placement="right"
      >
        <div>
          <ComponentWithHighlighting
            field={field}
            record={record}
          ></ComponentWithHighlighting>
        </div>
      </Tooltip>
    ) : (
      <ComponentWithHighlighting
        field={field}
        record={record}
      ></ComponentWithHighlighting>
    );
  };

  NewComponent.propTypes = {
    field: PropTypes.string,
    record: PropTypes.object
  };

  return NewComponent;
};

export default withProjectCurrencyToolTip;
