import { object, mixed } from 'yup';
import { DateTime } from 'luxon';
import {
  DROP_DOWN_TYPE_URI,
  DATE_TYPE_URI,
  NUMERIC_TYPE_URI,
  TEXT_TYPE_URI
} from '~/modules/customFields/definitionTypes';
import { compareDateObjects } from '~/modules/common/dates/compare';

export const validateCustomFieldRequiredValue = customField => {
  const { customFieldType } = customField;

  switch (customFieldType.id) {
    case DATE_TYPE_URI:
      return Boolean(customField.date);
    case DROP_DOWN_TYPE_URI:
      return Boolean(customField.dropDownOptionUri);
    case NUMERIC_TYPE_URI:
      return Boolean(customField.number);
    case TEXT_TYPE_URI:
    default:
      return Boolean(customField.text);
  }
};

export const buildRequiredCustomFieldValidator = ({ definition, intl }) => ({
  name: 'isRequired',
  message: intl.formatMessage(
    {
      id: 'addProject.customFieldRequired'
    },
    { name: definition.displayText }
  ),
  test: validateCustomFieldRequiredValue
});

export const validateNumericMinValue = customField => {
  const {
    number,
    customFieldDefinition: { numericConfiguration }
  } = customField;

  const { minimumNumericValue } = numericConfiguration;

  if (!number || !minimumNumericValue) return true;

  return number >= minimumNumericValue;
};

export const buildNumericMinValidator = ({ definition, intl }) => ({
  name: 'numericMinValue',
  message: intl.formatMessage(
    {
      id: 'addProject.customFieldNumericMinValue'
    },
    {
      name: definition.displayText,
      value: definition.numericConfiguration.minimumNumericValue
    }
  ),
  test: validateNumericMinValue
});

export const validateNumericMaxValue = customField => {
  const {
    number,
    customFieldDefinition: { numericConfiguration }
  } = customField;

  const { maximumNumericValue } = numericConfiguration;

  if (!number || !maximumNumericValue) return true;

  return number <= maximumNumericValue;
};

export const buildNumericMaxValidator = ({ definition, intl }) => ({
  name: 'numericMaxValue',
  message: intl.formatMessage(
    {
      id: 'addProject.customFieldNumericMaxValue'
    },
    {
      name: definition.displayText,
      value: definition.numericConfiguration.maximumNumericValue
    }
  ),
  test: validateNumericMaxValue
});

export const validateTextLength = customFieldValue => {
  const {
    text,
    customFieldDefinition: { textConfiguration }
  } = customFieldValue;

  const { maximumLength } = textConfiguration;

  if (!text || !maximumLength) return true;

  return text.length <= maximumLength;
};

export const buildTextLengthValidator = ({ definition, intl }) => ({
  name: 'textMaxLength',
  message: intl.formatMessage(
    {
      id: 'addProject.customFieldTextLength'
    },
    {
      name: definition.displayText,
      value: definition.textConfiguration.maximumLength
    }
  ),
  test: validateTextLength
});

export const validateDateMinValue = customField => {
  const {
    date,
    customFieldDefinition: { dateConfiguration }
  } = customField;

  if (dateConfiguration.minimumDate && date) {
    return compareDateObjects(date, dateConfiguration.minimumDate) !== -1;
  }

  return true;
};

export const buildDateMinValidator = ({ definition, intl }) => ({
  name: 'dateMinValue',
  message: intl.formatMessage(
    {
      id: 'addProject.minDate'
    },
    {
      name: definition.displayText,
      value: validationMessageDateString(
        definition.dateConfiguration.minimumDate
      )
    }
  ),
  test: validateDateMinValue
});

export const validateDateMaxValue = customField => {
  const {
    date,
    customFieldDefinition: { dateConfiguration }
  } = customField;

  if (dateConfiguration.maximumDate && date) {
    return compareDateObjects(date, dateConfiguration.maximumDate) !== 1;
  }

  return true;
};

export const buildDateMaxValidator = ({ definition, intl }) => ({
  name: 'dateMaxValue',
  message: intl.formatMessage(
    {
      id: 'addProject.maxDate'
    },
    {
      name: definition.displayText,
      value: validationMessageDateString(
        definition.dateConfiguration.maximumDate
      )
    }
  ),
  test: validateDateMaxValue
});

const validationMessageDateString = date => {
  if (date) {
    const validationDate = DateTime.fromObject(date).endOf('day');

    return validationDate.toISODate();
  }

  return null;
};

export const buildCustomFieldValidator = ({ definition, intl }) => {
  let validator = mixed();

  const { isRequired, type } = definition;

  if (isRequired) {
    validator = validator.test(
      buildRequiredCustomFieldValidator({ definition, intl })
    );
  }

  switch (type.uri) {
    case NUMERIC_TYPE_URI:
      return validator
        .test(buildNumericMinValidator({ definition, intl }))
        .test(buildNumericMaxValidator({ definition, intl }));
    case TEXT_TYPE_URI:
      return validator.test(buildTextLengthValidator({ definition, intl }));
    case DATE_TYPE_URI:
      return validator
        .test(buildDateMinValidator({ definition, intl }))
        .test(buildDateMaxValidator({ definition, intl }));

    default:
      return validator;
  }
};

export const buildCustomFieldValidationSchema = ({
  customFieldDefinitions,
  intl
}) =>
  object().shape(
    customFieldDefinitions.reduce((customFieldsSchema, definition) => {
      const { id } = definition;

      const validator = buildCustomFieldValidator({ definition, intl });

      return {
        ...customFieldsSchema,
        [id]: validator
      };
    }, {})
  );

export const customFieldValidationSchema = ({ customFieldDefinitions, intl }) =>
  object().shape({
    customFieldValues: buildCustomFieldValidationSchema({
      customFieldDefinitions,
      intl
    })
  });
