import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useLoadMore2 as useLoadMore } from './useLoadMore2';

export const TASK_QUERY = gql`
  query getTaskResourceAllocations(
    $taskId: String!
    $page: Int!
    $pageSize: Int!
  ) {
    task(taskId: $taskId) {
      id
      resourceAllocations(page: $page, pageSize: $pageSize) {
        resource {
          id
          displayText
        }
        allocatedHours
      }
    }
  }
`;

export const DEFAULT_PAGE_SIZE = 10;

export const useTaskResourceAllocations = ({ taskId, page }) => {
  const { data, loading, fetchMore } = useQuery(TASK_QUERY, {
    variables: {
      taskId,
      page: page || 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'network-only'
  });

  const resourceAllocations = get(data, 'task.resourceAllocations', []) || [];

  const { hasMore, loadingMore, loadMore } = useLoadMore({
    totalFetchedItems: resourceAllocations.length,
    fetchMore,
    loading,
    pageSize: DEFAULT_PAGE_SIZE
  });

  return {
    loading,
    loadingMore,
    hasMore,
    loadMore,
    resourceAllocations
  };
};

export default useTaskResourceAllocations;
