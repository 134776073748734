import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TaskParentIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 20,2 H 13.82 C 13.4,0.4 12.3,0 11,0 c -1.3,0 -2.4,0.84 -2.82,2 H 2 v 18 h 18 z m -9,0 c 0.55,0 1,0.45 1,1 0,0.551 -0.45,1 -1,1 -0.55,0 -1,-0.45 -1,-1 0,-0.55 0.45,-1 1,-1 z m 2,14 H 6 V 14 H 13 Z m 3,-4 H 6 V 10 H 16 Z M 16,8 H 6 V 6 h 10 z" />
      <path d="M 22 6 L 22 22 L 6 22 L 6 24 L 24 24 L 24 6 L 22 6 z " />
    </>
  </SvgIcon>
));

export default TaskParentIcon;
