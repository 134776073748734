import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Menu, makeStyles, Tooltip } from '@material-ui/core';
import SelectIcon from '@material-ui/icons/ExpandMoreSharp';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    height: theme.spacing(4),
    padding: theme.spacing(0, 1)
  },
  deleteIcon: {
    color: theme.palette.primary.contrastText,
    transform: ({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)'),
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  clickableColorSecondary: {},
  colorSecondary: {},
  colorPrimary: {},
  clickableColorPrimary: {}
}));

export const TooltipWrapper = ({ children, showTooltip, tooltipProps }) => {
  return (
    <>
      {showTooltip ? (
        <Tooltip {...tooltipProps}>{children}</Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

TooltipWrapper.propTypes = {
  children: PropTypes.node,
  showTooltip: PropTypes.bool,
  tooltipProps: PropTypes.object
};

export const ChipMenu = ({
  classes: classesOverride,
  open,
  color = 'primary',
  openMenu,
  closeMenu,
  anchorEl,
  editable,
  label,
  children,
  size = 'small',
  showTooltip = false,
  tooltipProps = {}
}) => {
  const classes = useStyles({ classes: classesOverride, open });

  return (
    <>
      <TooltipWrapper showTooltip={showTooltip} tooltipProps={tooltipProps}>
        <Chip
          classes={classes}
          color={color}
          size={size}
          label={label}
          onClick={editable ? openMenu : undefined}
          deleteIcon={<SelectIcon />}
          onDelete={editable ? openMenu : undefined}
        />
      </TooltipWrapper>
      {editable && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          role="main"
          MenuListProps={{ role: 'menu' }}
          onClose={closeMenu}
        >
          {children}
        </Menu>
      )}
    </>
  );
};

ChipMenu.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  openMenu: PropTypes.func,
  closeMenu: PropTypes.func,
  anchorEl: PropTypes.element,
  color: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  editable: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium']),
  showTooltip: PropTypes.bool,
  tooltipProps: PropTypes.object
};

export default ChipMenu;
