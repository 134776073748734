import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback, useEffect } from 'react';
import { rate } from './query';

export const GET_PROJECT_USER_RATES = gql`
  query getAllProjectUserRates(
    $projectId: String!
    $page: Int!
    $pageSize: Int!
  ) {
    project(projectId: $projectId) {
      id
      userCurrentEffectiveRates(page: $page, pageSize: $pageSize) {
        id
        user {
          id
          displayText
        }
        ${rate}
        asOfDate
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const updateQuery = (
  previousResult,
  { fetchMoreResult: { project: nextResult } }
) => {
  const prevResult = previousResult?.project.userCurrentEffectiveRates || [];
  const nextResults = nextResult.userCurrentEffectiveRates || [];

  return {
    project: {
      ...previousResult.project,
      userCurrentEffectiveRates: [...prevResult, ...nextResults]
    }
  };
};

const useProjectUserBillingRates = ({ projectId, pageSize }) => {
  const [page, setPage] = useState(1);
  const variables = {
    page: 1,
    pageSize: pageSize || DEFAULT_PAGE_SIZE,
    projectId
  };

  const { loading, error, data, fetchMore } = useQuery(GET_PROJECT_USER_RATES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables
  });

  const userCurrentEffectiveRates =
    data && data.project && data.project.userCurrentEffectiveRates;

  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(
    () =>
      setHasMoreRows(
        Boolean(
          userCurrentEffectiveRates &&
            userCurrentEffectiveRates.length > 0 &&
            userCurrentEffectiveRates.length % DEFAULT_PAGE_SIZE === 0
        )
      ),
    [userCurrentEffectiveRates, setHasMoreRows]
  );

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows || isLoadingMore) return;
    setIsLoadingMore(true);
    const nextPage = page + 1;

    const {
      data: {
        project: { userCurrentEffectiveRates: newEntries }
      }
    } = await fetchMore({
      query: GET_PROJECT_USER_RATES,
      variables: {
        ...variables,
        page: nextPage
      },
      updateQuery
    });

    if (newEntries.length > 0) {
      setPage(nextPage);
    }

    setHasMoreRows((newEntries || []).length === DEFAULT_PAGE_SIZE);
    setIsLoadingMore(false);
  }, [page, fetchMore, variables]);

  return {
    loadingRows: loading,
    userCurrentEffectiveRates: (userCurrentEffectiveRates || []).map(r => ({
      id: r.id,
      entity: r.user,
      asOfDate: r.asOfDate,
      rate: r.rate
    })),
    error,
    loadMoreRows,
    hasMoreRows,
    isLoadingMore,
    setPage
  };
};

export default useProjectUserBillingRates;
