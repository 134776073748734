import PropTypes from 'prop-types';
import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useReducer,
  useCallback
} from 'react';
import { withResizeDetector } from 'react-resize-detector';
import { useWidthExcludePropCheckHack } from '~/modules/common/hooks';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import {
  createFixedWidthView,
  useFixedRange
} from '~/modules/common/charts/timeline/calculations';
import { QuickAllocationUserChartLoading } from './components';
import QuickAllocationUserChartContent from './QuickAllocationUserChartContent';

const leftComponentWidth = 350;

export const RESOURCE_USER_EXPAND = 'RESOURCE_USER_EXPAND';
export const RESOURCE_USER_COLLAPSE = 'RESOURCE_USER_COLLAPSE';

export const handleResourceUsersExpandedState = (state, action) => {
  switch (action.type) {
    case RESOURCE_USER_EXPAND:
      return { ...state, [action.value]: action.value };

    case RESOURCE_USER_COLLAPSE:
      return { ...state, [action.value]: undefined };

    default:
      throw new Error();
  }
};

export const UserChart = memo(
  ({
    width,
    setSearchCriteria,
    searchCriteria,
    chartSettings,
    projectStatusFilterOption
  }) => {
    const [isShowActualEnabled, setIsShowActualEnabled] = useState(
      (chartSettings && chartSettings.showActuals) || false
    );
    const [page, setPage] = useState(1);

    useEffect(() => setPage(1), [searchCriteria]);

    const [state, dispatch] = useReducer(handleResourceUsersExpandedState, {});

    const handleExpand = useCallback(
      resourceId =>
        dispatch({
          type: state[resourceId]
            ? RESOURCE_USER_COLLAPSE
            : RESOURCE_USER_EXPAND,
          value: resourceId
        }),
      [state, dispatch]
    );

    const rangeView = useMemo(
      () => createFixedWidthView(Math.floor(width), leftComponentWidth),
      [width]
    );

    const {
      scale,
      date,
      chartDates,
      dateRange,
      setScale,
      setDate,
      onPrevious,
      onNext
    } = useFixedRange({
      getPeriodCount: rangeView.getPeriodCount,
      anchor: 0,
      defaults:
        chartSettings && chartSettings.scale
          ? {
              scale: chartSettings.scale
            }
          : null
    });

    const handleScaleChange = useCallback(
      newScaleValue => {
        setPage(1);
        setScale(newScaleValue);
      },
      [setScale, setPage]
    );

    return (
      <QuickAllocationUserChartContent
        key={scale}
        scale={scale}
        date={date}
        chartDates={chartDates}
        dateRange={dateRange}
        setScale={handleScaleChange}
        setDate={setDate}
        onPrevious={onPrevious}
        onNext={onNext}
        width={width}
        page={page}
        setPage={setPage}
        leftComponentWidth={leftComponentWidth}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        defaultDisplayUnit={
          (chartSettings && chartSettings.displayUnit) ||
          DISPLAY_UNIT_ENUM.PERCENTAGE
        }
        handleExpand={handleExpand}
        userExpandState={state}
        projectStatusFilterOption={projectStatusFilterOption}
        isShowActualEnabled={isShowActualEnabled}
        setIsShowActualEnabled={setIsShowActualEnabled}
      />
    );
  },
  useWidthExcludePropCheckHack
);

export const QuickAllocationUserChart = ({
  searchCriteria,
  setSearchCriteria,
  width,
  projectStatusFilterOption,
  chartSettings
}) => {
  if (!width) return <QuickAllocationUserChartLoading />;

  return (
    <UserChart
      chartSettings={chartSettings}
      width={width}
      searchCriteria={searchCriteria}
      setSearchCriteria={setSearchCriteria}
      projectStatusFilterOption={projectStatusFilterOption}
    />
  );
};

QuickAllocationUserChart.propTypes = {
  width: PropTypes.number,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object,
  projectStatusFilterOption: PropTypes.object,
  chartSettings: PropTypes.object
};

UserChart.propTypes = {
  width: PropTypes.number,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object,
  chartSettings: PropTypes.object,
  projectStatusFilterOption: PropTypes.object
};

export default withResizeDetector(QuickAllocationUserChart);
