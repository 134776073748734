import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TargetBillableHoursIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12,22 A 10.011,10.011 0 0 1 2,12 10.011,10.011 0 0 1 12,2 10.011,10.011 0 0 1 22,12 10.011,10.011 0 0 1 12,22 Z M 12,4.093000000000075 A 7.917,7.917 0 0 0 4.093000000000075,12.00099999999998 7.916000000000003,7.916000000000003 0 0 0 12,19.9079999999999 7.917,7.917 0 0 0 19.9079999999999,12.00099999999998 7.917,7.917 0 0 0 12,4.091999999999871 Z" />
      <path d="M 12.00099999999998,17.58000000000015 A 5.585,5.585 0 0 1 6.422000000000026,12.0010000000002 5.585,5.585 0 0 1 12.00099999999998,6.422000000000253 5.585,5.585 0 0 1 17.57999999999993,12.0010000000002 5.585,5.585 0 0 1 12.00099999999998,17.58000000000015 Z m 0,-8.856999999999967 A 3.281,3.281 0 0 0 8.722999999999956,12.0010000000002 3.281,3.281 0 0 0 12.00099999999998,15.27800000000025 3.281,3.281 0 0 0 15.27800000000002,12.0010000000002 3.281,3.281 0 0 0 12.00099999999998,8.721000000000231 Z" />
      <path d="m 12.00000000000005,10.57500000000005 a 1.425,1.425 0 1 0 1.425,1.425 1.426,1.426 0 0 0 -1.425,-1.425 z m 0,2.565 z" />
      <path d="m 1,11.5 v 1 h 22 v -1 z" />
      <path d="m 11.5,1 v 22 h 1 V 1 Z" />
    </>
  </SvgIcon>
));

export default TargetBillableHoursIcon;
