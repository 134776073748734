import { useSearchableLocations } from '~/modules/common/enhancers';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { locationTag } from './tag';
import LocationFacetDetails from './LocationFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableLocations);

export default {
  tag: locationTag,
  makeCriteriaProvider,
  DetailsControl: LocationFacetDetails
};
