import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLoadMore } from '~/modules/common/hooks/useLoadMoreResults';

export const DEFAULT_PAGE_SIZE = 50;

export const SKILL_DISTRIBUTION_FOR_USERS_QUERY = gql`
  query Eager_skillDistributionForUsers(
    $page: Int!
    $pageSize: Int!
    $dataAccessLevels: [String]
    $filter: SkillDistributionForUsersSummaryFilter
  ) {
    skillDistributionForUsers(
      page: $page
      pageSize: $pageSize
      dataAccessLevels: $dataAccessLevels
      filter: $filter
    ) {
      skill {
        id
        displayText
      }
      category {
        uri
        displayText
      }
      userCount
    }
  }
`;

const useSkillsDistributionForUsers = ({ filter }) => {
  const { loading, data, fetchMore, networkStatus } = useQuery(
    SKILL_DISTRIBUTION_FOR_USERS_QUERY,
    {
      variables: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        filter,
        dataAccessLevels: [
          'urn:replicon:user-data-access-level:resource-manager',
          'urn:replicon:user-data-access-level:resource-pool-manager'
        ]
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  const result = get(data, 'skillDistributionForUsers', []);

  const { loadMore, hasMore } = useLoadMore({
    variables: { pageSize: DEFAULT_PAGE_SIZE },
    fetchMore,
    fetchMoreResultPath: 'skillDistributionForUsers',
    loading,
    networkStatus,
    initialHasMore:
      result && result.length > 0 && result.length % DEFAULT_PAGE_SIZE === 0
  });

  return {
    loading,
    loadMore,
    hasMore,
    data: result.map(({ category, skill, userCount }) => ({
      category,
      skill,
      userCount
    }))
  };
};

export default useSkillsDistributionForUsers;
