import { PropTypes } from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import DonutLegend from './DonutLegend';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column'
  }
});

export const DonutLegends = ({
  classes: classesOverride,
  legends,
  legendClasses,
  legendClickHandlers
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.container}>
      {legends.map((legend, i) => (
        <DonutLegend
          classes={legendClasses}
          key={legend.key}
          legend={legend}
          onClick={legendClickHandlers[legend.key]}
        />
      ))}
    </div>
  );
};

DonutLegends.propTypes = {
  classes: PropTypes.object,
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
      ]),
      value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
      ]),
      colorScale: PropTypes.string.isRequired
    })
  ),
  legendClasses: PropTypes.object,
  legendClickHandlers: PropTypes.object
};

export default DonutLegends;
