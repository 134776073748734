import { gql } from 'graphql-tag';

export const fullProgramFragment = gql`
  fragment FullProgram on Program {
    id
    name
    programManagerReference {
      id
      uri
      displayText
    }
    budget {
      capital {
        amount
        currency {
          id
          displayText
          symbol
        }
      }
      operational {
        amount
        currency {
          id
          displayText
          symbol
        }
      }
      total {
        amount
        currency {
          id
          displayText
          symbol
        }
      }
    }
    startDate
    endDate
    isActive
    slug
  }
`;
