import { gql } from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import get from 'lodash.get';
import { getScheduleTotalHours } from '../util';

export const GET_SCHEDULED_RULES_FOR_USER_AND_DATERANGE = gql`
  query GetUserScheduleRulesForDateRange(
    $userUri: String!
    $dateRange: DateRangeInput!
  ) {
    getUserScheduleRulesForDateRange(userUri: $userUri, dateRange: $dateRange) {
      dateRange {
        startDate: startDate2
        endDate: endDate2
      }
      do
    }
  }
`;

export const useGetScheduleRulesForUserAndDateRange = ({
  userUri,
  dateRange
}) => {
  const { data, loading } = useQuery(
    GET_SCHEDULED_RULES_FOR_USER_AND_DATERANGE,
    {
      variables: {
        userUri,
        dateRange
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const scheduleRules = get(data, 'getUserScheduleRulesForDateRange', []);

  return {
    scheduledHours: getScheduleTotalHours({ scheduleRules, quantity: 1 }),
    scheduleRules,
    loading
  };
};

export const useGetScheduledRulesForUserAndDateRangeCallback = () => {
  const apolloClient = useApolloClient();

  const getUserScheduleRulesForDateRange = useCallback(
    async (userUri, dateRange) => {
      const { data, loading } = await apolloClient.query({
        query: GET_SCHEDULED_RULES_FOR_USER_AND_DATERANGE,
        variables: {
          userUri,
          dateRange
        },
        fetchPolicy: 'network-only'
      });

      const scheduleRules = get(data, 'getUserScheduleRulesForDateRange', []);

      return {
        scheduledHours: getScheduleTotalHours({ scheduleRules, quantity: 1 }),
        scheduleRules,
        loading
      };
    },
    [apolloClient]
  );

  return {
    getUserScheduleRulesForDateRange
  };
};
