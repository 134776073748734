import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import { SettingsDialog } from '~/modules/time-expense/components/Settings';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import { PERIOD_RESOLUTION_ENUM } from '~/modules/resourcing/common/enums';
import PeriodDropdown from '~/modules/common/components/PeriodDropdown/PeriodDropdown';
import ChartDateRangePeriod from '~/modules/common/charts/dashboard/ChartDateRangePeriod';
import ChartDateRangeLabel from '~/modules/common/charts/dashboard/ChartDateRangeLabel';
import TimeAndExpenseImportExportMenu from './TimeAndExpenseImportExportMenu';

const GROUPINGS_PERIOD_SCALE_MAP = {
  entrydate__day: PERIOD_SCALE_ENUM.DAYS,
  entrydate__week: PERIOD_SCALE_ENUM.WEEKS,
  entrydate__month: PERIOD_SCALE_ENUM.MONTHS,
  entrydate__quarter: PERIOD_SCALE_ENUM.QUARTERS,
  entrydate__year: PERIOD_SCALE_ENUM.YEARS
};

const PERIOD_SCALE_GROUPINGS_MAP = {
  [PERIOD_SCALE_ENUM.DAYS]: 'entrydate__day',
  [PERIOD_SCALE_ENUM.WEEKS]: 'entrydate__week',
  [PERIOD_SCALE_ENUM.MONTHS]: 'entrydate__month',
  [PERIOD_SCALE_ENUM.QUARTERS]: 'entrydate__quarter',
  [PERIOD_SCALE_ENUM.YEARS]: 'entrydate__year'
};

const useStyles = makeStyles(theme => ({
  period: {
    flexGrow: 1
  }
}));

const Toolbar = ({
  intl,
  settings,
  onUpdate,
  isLoading,
  billingFilter,
  dataQeId
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const setAnchorDate = useCallback(
    value => {
      onUpdate({
        ...settings,
        anchorDate: value
      });
    },
    [settings, onUpdate]
  );

  const setDateRange = useCallback(
    ({ startDate }) => setAnchorDate(startDate),
    [setAnchorDate]
  );

  const prevDateRange = useCallback(() => {
    const scale = {
      [settings.scalingFactor]: 1
    };

    return setAnchorDate(settings.anchorDate.minus(scale));
  }, [settings.scalingFactor, settings.anchorDate, setAnchorDate]);

  const nextDateRange = useCallback(() => {
    const scale = {
      [settings.scalingFactor]: 1
    };

    return setAnchorDate(settings.anchorDate.plus(scale));
  }, [settings.scalingFactor, settings.anchorDate, setAnchorDate]);

  const setPeriodScale = useCallback(
    periodScale => {
      onUpdate({
        ...settings,
        groupings: {
          ...settings.groupings,
          values: [PERIOD_SCALE_GROUPINGS_MAP[periodScale]]
        }
      });
    },
    [settings, onUpdate]
  );

  const periodScale = GROUPINGS_PERIOD_SCALE_MAP[settings.groupings.values[0]];

  return (
    <>
      <div className={classes.period}>
        <ChartDateRangePeriod
          onPreviousClick={prevDateRange}
          onNextClick={nextDateRange}
          scale={periodScale}
          dateRange={settings.dateRange}
          setDateRange={setDateRange}
        >
          <span>
            <ChartDateRangeLabel
              scale={periodScale}
              start={settings.dateRange.startDate}
              end={settings.dateRange.startDate}
            />
            &nbsp;—&nbsp;
            <ChartDateRangeLabel
              scale={periodScale}
              start={settings.dateRange.endDate}
              end={settings.dateRange.endDate}
            />
          </span>
        </ChartDateRangePeriod>
      </div>
      <PeriodDropdown
        dataQeId={dataQeId}
        value={periodScale}
        onChange={setPeriodScale}
      />
      <TimeAndExpenseImportExportMenu
        settings={settings}
        billingFilter={billingFilter}
        periodResolution={PERIOD_RESOLUTION_ENUM[periodScale]}
      />
      <IconButton
        onClick={onOpen}
        data-qe-id={dataQeId && `${dataQeId}_SettingsButton`}
        aria-label={intl.formatMessage({ id: 'settingsActionLink.settings' })}
      >
        <SettingsIcon />
      </IconButton>
      <SettingsDialog
        open={open}
        intl={intl}
        settings={settings}
        onUpdate={onUpdate}
        disabled={isLoading}
        onClose={onClose}
      />
    </>
  );
};

Toolbar.propTypes = {
  intl: PropTypes.object,
  settings: PropTypes.object,
  billingFilter: PropTypes.object,
  onUpdate: PropTypes.func,
  isLoading: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default Toolbar;
