import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';

export const billingRowsQuery = gql`
  query getPageOfBillingTransactions(
    $page: Int!
    $pageSize: Int!
    $sort: BillingTransactionSort
    $filter: BillingTransactionFilter
    $tagIds: [String!]
    $isPsaPswatBillingDraftSubStatusEnabled: Boolean = false
  ) {
    getPageOfBillingTransactions(
      page: $page
      pageSize: $pageSize
      sort: $sort
      filter: $filter
      tagIds: $tagIds
    ) {
      id
      displayText
      pastDueDays
      client {
        id
        displayText
      }
      transactionDate
      dueDate {
        textValue
        dateValue
      }
      period {
        dateRange {
          startDate
          endDate
        }
        dateRangeValue
      }
      type
      balanceAmount {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      balanceWithoutAllocationsAmount {
        amount
        currency {
          id
          symbol
        }
      }
      balanceStatus
      description
      billStatus
      draftSubStatus @include(if: $isPsaPswatBillingDraftSubStatusEnabled) {
        id
        displayText
      }
      tags {
        tag {
          id
          name
        }
        value {
          id
          value
        }
      }
    }
  }
`;

export const mapToServiceFilter = ({
  projectUri,
  clientUri,
  programUri,
  billingTransactionFilter: {
    clients: clientUris,
    transactionTypes,
    dateRange,
    text,
    balanceStatusList,
    billingTransactionUris,
    isOverdue,
    dueDate,
    tags,
    billStatus,
    draftSubStatusUris = []
  } = {}
}) => ({
  projectUri,
  clientUris: clientUri ? [clientUri] : clientUris,
  programUri,
  transactionTypes,
  billingTransactionUris,
  dateRange,
  searchTerm: text,
  balanceStatusList,
  isOverdue,
  dueDate,
  tags,
  billStatus,
  draftSubStatusUris
});

export const useBillingTransactionRows = ({
  page = 1,
  pageSize = 100,
  sort,
  projectUri,
  clientUri,
  programId,
  billingTransactionFilter,
  tagIds = [],
  isPsaPswatBillingDraftSubStatusEnabled = false
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, loading, fetchMore, variables, refetch } = useQuery(
    billingRowsQuery,
    {
      variables: {
        page,
        pageSize,
        filter: mapToServiceFilter({
          projectUri,
          clientUri,
          programUri: programId,
          billingTransactionFilter
        }),
        sort,
        tagIds,
        isPsaPswatBillingDraftSubStatusEnabled
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );
  const getPageOfBillingTransactions = get(
    data,
    'getPageOfBillingTransactions',
    []
  );
  const hasMoreRows =
    getPageOfBillingTransactions &&
    getPageOfBillingTransactions.length !== 0 &&
    getPageOfBillingTransactions.length % variables.pageSize === 0;

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows) {
      return;
    }

    setIsLoadingMore(true);

    try {
      await fetchMore({
        query: billingRowsQuery,
        variables: {
          page: getPageOfBillingTransactions.length / variables.pageSize + 1,
          filter: variables.filter,
          pageSize: variables.pageSize,
          sort: variables.sort,
          tagIds: variables.tagIds,
          isPsaPswatBillingDraftSubStatusEnabled:
            variables.isPsaPswatBillingDraftSubStatusEnabled
        },
        updateQuery: (
          previousResult,
          { fetchMoreResult: { getPageOfBillingTransactions: nextResult } }
        ) => ({
          getPageOfBillingTransactions: [
            ...previousResult.getPageOfBillingTransactions,
            ...nextResult
          ]
        })
      });
    } finally {
      setIsLoadingMore(false);
    }
  }, [fetchMore, getPageOfBillingTransactions.length, hasMoreRows, variables]);

  return {
    billingRows: getPageOfBillingTransactions,
    hasMoreRows,
    isLoadingMore,
    loadMoreRows,
    isRowsLoading: loading,
    refetch
  };
};

export default useBillingTransactionRows;
