import { TASK_STATUS } from '~/modules/common/enums';

export const getEquivalentTaskStatus = status =>
  ({
    'In Progress': TASK_STATUS.INPROGRESS,
    Completed: TASK_STATUS.COMPLETED
  }[status] || TASK_STATUS.NOTSTARTED);

export const getEquivalentRolledUpTaskStatus = ({ status, actualHours }) =>
  status && status.toUpperCase() === TASK_STATUS.COMPLETED
    ? TASK_STATUS.COMPLETED
    : actualHours
    ? TASK_STATUS.INPROGRESS
    : TASK_STATUS.NOTSTARTED;

export const getRolledUpEstimatedAtCompletionHours = ({
  status,
  actualHours,
  totalEstimatedAtCompletionHours
}) =>
  status && status.toUpperCase() === TASK_STATUS.COMPLETED
    ? actualHours
    : totalEstimatedAtCompletionHours;

export const isProjectClosed = workflow =>
  workflow?.uri === 'urn:replicon-psa:project-stage:close-out' ||
  workflow?.uri === 'urn:replicon-psa:project-stage:archived';
