import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { EXPORT_OPTIONS } from '~/modules/common/components/ImportExportMenu/enums/ImportExportMenuEnums';
import ImportExportGroup from '~/modules/common/components/ImportExportMenu';

export const useStyles = makeStyles(() => ({
  container: {}
}));

const { EXPORT, CSV_EXPORT } = EXPORT_OPTIONS;

const TABLE_KEY = 'psa-time-and-expense';

export const TimeAndExpenseImportExportMenu = ({
  settings = {},
  billingFilter = {},
  periodResolution
}) => {
  const classes = useStyles();

  const exportColumns = [...settings.rows.values, ...settings.columns.values];

  const actions = useMemo(
    () => [
      {
        label: EXPORT,
        actionItems: [
          {
            label: CSV_EXPORT,
            tableKey: TABLE_KEY,
            activeColumns: exportColumns
          }
        ]
      }
    ],
    [exportColumns]
  );

  return (
    <div className={classes.container}>
      <ImportExportGroup
        actions={actions}
        billingFilter={billingFilter}
        isMobile={false}
        dateRange={settings.dateRange}
        periodResolution={periodResolution}
        menuId="time-expense-import-export-menu"
      />
    </div>
  );
};

TimeAndExpenseImportExportMenu.propTypes = {
  settings: PropTypes.object,
  billingFilter: PropTypes.object,
  periodResolution: PropTypes.string
};

export default TimeAndExpenseImportExportMenu;
