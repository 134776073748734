import { useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import get from 'lodash.get';
import { object, string } from 'yup';
import { ProjectManagementType } from '~/types';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { extractFirstErrorMessage } from '~/modules/common/graphql/errors';
import {
  mapRepliconDateToMidnightUTCString,
  isoStringToObjectWithCache as isoStringToObject
} from '~/modules/common/dates/convert';
import { compareISOStrings } from '~/modules/common/dates/compare';
import { isValid } from '~/modules/projects/project/validator';
import { useMeContext } from '~/modules/me';

import { useProjectCopyBatchMutation } from './hooks';
import { COPY_PROJECT_FLOW } from './enums';

const STRING_MAX_LENGTH = 255;
const MAX_CODE_LENGTH = 50;

const noEntry = { displayText: null, id: null };

export const useForm = ({
  client,
  project = {},
  program,
  portfolio,
  screenFlow,
  projectType,
  canCopyTasks,
  canCopyBillPlan,
  earnedRevenueScript
}) => {
  const intl = useIntl();
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpTaskCodeOnTaskCreationEnabled }
  } = me;

  const [jobState, setJobState] = useState({
    jobId: null,
    jobInProgress: false
  });

  const validationSchema = () =>
    object().shape({
      name: string()
        .trim()
        .max(
          STRING_MAX_LENGTH,
          intl.formatMessage({
            id: 'duplicateProject.projectNameExceedsMaxLength'
          })
        )
        .required(
          intl.formatMessage({
            id: 'duplicateProject.enterProjectNameErrorMsg'
          })
        ),
      ...(isPsaPrpTaskCodeOnTaskCreationEnabled
        ? {
            code: string()
              .max(
                MAX_CODE_LENGTH,
                intl.formatMessage(
                  { id: 'duplicateProject.projectCodeExceedsMaxLength' },
                  { max: MAX_CODE_LENGTH }
                )
              )
              .nullable()
          }
        : {}),
      client: object().test(
        'clientTest',
        intl.formatMessage({
          id: 'duplicateProject.enterClientErrorMsg'
        }),
        function test(value) {
          const { rateCardCopyOption } = this.parent;

          return !(
            rateCardCopyOption ===
              'urn:replicon:rate-table-entry-copy-option:copy-from-client' &&
            value.id === null
          );
        }
      )
    });

  const projectManagerInitialValue =
    screenFlow === COPY_PROJECT_FLOW.ADD_PROJECT
      ? me.userAccessRoles.includes(USER_ACCESS_ROLE.PROJECT_MANAGER)
        ? {
            id: me.uri,
            displayText: me.displayText
          }
        : null
      : null;

  const initialValues = useMemo(
    () => ({
      name: '',
      client: client || project.client || noEntry,
      program: program || project.program || noEntry,
      portfolio: portfolio || project.portfolio || undefined,
      endDate: project.endDate ? isoStringToObject(project.endDate) : null,
      project: project.projectId ? { id: project.projectId } : null,
      startDate: project.startDate
        ? isoStringToObject(project.startDate)
        : null,
      projectManager: projectManagerInitialValue,
      isCopyTasksChecked: !!canCopyTasks,
      projectManagementType:
        projectType === ProjectManagementType.Unmanaged
          ? 'urn:replicon:project-management-type:unmanaged'
          : 'urn:replicon:project-management-type:managed',
      sourceProjectStartDate: project.startDate,
      earnedRevenueScript: me.featureFlags?.isPsaEarnedRevenueEnabled
        ? earnedRevenueScript
        : null,
      isCopyResourceRequestsChecked: true,
      isCopyBillPlanChecked: Boolean(canCopyBillPlan),
      canCopyBillPlan: Boolean(canCopyBillPlan),
      rateCardCopyOption:
        'urn:replicon:rate-table-entry-copy-option:copy-from-project'
    }),
    [
      client,
      program,
      portfolio,
      project.client,
      project.program,
      project.portfolio,
      project.endDate,
      project.projectId,
      project.startDate,
      projectManagerInitialValue,
      canCopyTasks,
      projectType,
      me.featureFlags,
      earnedRevenueScript,
      canCopyBillPlan
    ]
  );

  const onSubmit = useOnSubmit({ setJobState });

  return {
    form: useFormik({
      initialValues,
      validationSchema,
      onSubmit,
      enableReinitialize: true
    }),
    jobState,
    setJobState
  };
};

const handleError = ({ err, setFieldError }) => {
  const validationError = get(
    err,
    'graphQLErrors[0].extensions.exception.validationError'
  );

  if (validationError) {
    const duplicationNameError =
      validationError.failureUri ===
      'urn:replicon:project-copy-error:destination-project-name-already-exists';

    setFieldError(
      'name',
      duplicationNameError ? validationError.displayText : null
    );
  } else {
    setFieldError('unhandled', extractFirstErrorMessage(err));
  }
};

const mapCopyOptionToEnum = option => {
  if (option) {
    return option ===
      'urn:replicon:rate-table-entry-copy-option:copy-from-project'
      ? 'COPY_FROM_PROJECT'
      : 'COPY_FROM_CLIENT';
  }

  return null;
};

const mapProjectManagementTypeToEnum = option => {
  if (option) {
    return option === 'urn:replicon:project-management-type:unmanaged'
      ? 'UNMANAGED'
      : 'MANAGED';
  }

  return null;
};

const mapValuesOnSubmit = ({
  name,
  code,
  client,
  startDate,
  endDate,
  program,
  project,
  portfolio,
  projectManager,
  earnedRevenueScript,
  isCopyTasksChecked,
  projectManagementType,
  sourceProjectStartDate,
  isCopyResourceRequestsChecked,
  isCopyBillPlanChecked,
  rateCardCopyOption
}) => ({
  newProjectName: name,
  newProjectCode: code,
  earnedRevenueScriptId: earnedRevenueScript ? earnedRevenueScript.id : null,
  sourceProjectId: isValid(project) ? project.id : null,
  programId: isValid(program) ? program.id : null,
  portfolioId: portfolio?.id || undefined,
  startDate: startDate || null,
  endDate: endDate || null,
  clients: isValid(client)
    ? [
        {
          client: {
            uri: client.id
          },
          costAllocationPercentage: 100
        }
      ]
    : [],
  shiftByProjectStartDateOffset: isCopiedProjectstartDateSameAsSourceProjectStartDate(
    {
      copiedProjectStartDate: startDate,
      sourceProjectStartDate
    }
  ),
  projectManagerId: isValid(projectManager) ? projectManager.id : null,
  isCopyTasksChecked,
  isCopyResourceRequestsChecked,
  isCopyBillPlanChecked,
  rateCardCopyOption: mapCopyOptionToEnum(rateCardCopyOption),
  projectManagementType: mapProjectManagementTypeToEnum(projectManagementType)
});

export const useOnSubmit = ({ setJobState }) => {
  const [createProjectCopyBatch] = useProjectCopyBatchMutation();

  return useCallback(
    (values, bag) => {
      const projectCopyBatch = async () => {
        bag.setSubmitting(true);
        try {
          const payload = mapValuesOnSubmit(values);

          const { data } = await createProjectCopyBatch({
            variables: {
              projectCopyInputs: payload
            }
          });

          const {
            createProjectCopyBatch3: { jobId = '', batchId = '' }
          } = data;

          setJobState({
            jobId,
            jobInProgress: true,
            copyProjectBatchId: batchId
          });
        } catch (err) {
          handleError({
            err,
            setFieldError: bag.setFieldError
          });
        } finally {
          bag.setSubmitting(false);
        }
      };

      projectCopyBatch();
    },
    [setJobState, createProjectCopyBatch]
  );
};

const isCopiedProjectstartDateSameAsSourceProjectStartDate = ({
  copiedProjectStartDate,
  sourceProjectStartDate
}) => {
  if (!copiedProjectStartDate || !sourceProjectStartDate) return false;

  const copiedProjectStartDateIsoString = mapRepliconDateToMidnightUTCString(
    copiedProjectStartDate
  );

  const value = compareISOStrings(
    sourceProjectStartDate,
    copiedProjectStartDateIsoString
  );

  return value !== 0;
};
