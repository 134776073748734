const { useCallback } = require('react');
const { useReducer } = require('react');

export const ALLOCATED_USER_EXPAND = 'ALLOCATED_USER_EXPAND';
export const ALLOCATED_USER_COLLAPSE = 'ALLOCATED_USER_COLLAPSE';

export const handleAllocatedUsersExpandedState = (state, action) => {
  switch (action.type) {
    case ALLOCATED_USER_EXPAND:
      return { ...state, [action.value]: action.value };

    case ALLOCATED_USER_COLLAPSE:
      return { ...state, [action.value]: undefined };

    default:
      throw new Error();
  }
};

export const useUserRowExpansionPanelStateReducer = () => {
  const [userExpandState, dispatch] = useReducer(
    handleAllocatedUsersExpandedState,
    {}
  );

  const onUserRowExpansionPanelClick = useCallback(
    allocatedUserId =>
      dispatch({
        type: userExpandState[allocatedUserId]
          ? ALLOCATED_USER_COLLAPSE
          : ALLOCATED_USER_EXPAND,
        value: allocatedUserId
      }),
    [userExpandState, dispatch]
  );

  return {
    userExpandState,
    onUserRowExpansionPanelClick
  };
};

export default useUserRowExpansionPanelStateReducer;
