import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { getFirstErrorCode } from '~/modules/common/graphql/errors';
import { NoData } from '../common';

const useStyles = makeStyles(theme => ({
  tooManyResourcesMessage: {
    margin: theme.spacing(4, 3, 4, 3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(8),
    color: theme.palette.text.secondary
  }
}));

const checkSearchRefineError = error => error === 'MAX_LIMIT_EXCEEDED_ERROR';

export const ResourcesCardError = ({
  errorMessage,
  errorCode,
  errorSeries
}) => {
  const classes = useStyles();

  const errorCodeSeries = getFirstErrorCode(errorSeries);

  const isSearchRefineError = useMemo(
    () =>
      checkSearchRefineError(errorCode) ||
      checkSearchRefineError(errorCodeSeries),
    [errorCode, errorCodeSeries]
  );

  return (
    <>
      {isSearchRefineError ? (
        <div className={classes.tooManyResourcesMessage}>
          <div>
            <h3>
              <b>
                <FormattedMessage id="projectOverview.tooManyVisibleProjects" />
              </b>
            </h3>
            <p>
              <FormattedMessage id="projectOverview.tooManyResourcesForSummary" />
              <br />
              <FormattedMessage id="projectOverview.refineSearchError" />
            </p>
          </div>
        </div>
      ) : errorMessage ? (
        <NoData message={errorMessage} />
      ) : (
        <NoData message={errorSeries.message} />
      )}
    </>
  );
};

ResourcesCardError.propTypes = {
  errorMessage: PropTypes.string,
  errorCode: PropTypes.string,
  errorSeries: PropTypes.object
};

export default ResourcesCardError;
