import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Chip, TextField, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { User } from '~/modules/common/components';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';
import MoreResult from '~/modules/common/components/MoreResult';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';

const useUserChipStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: theme.palette.grey[400]
  },
  listItem: {
    width: '100%'
  },
  avatar: {
    color: 'inherit'
  }
}));

const useUserClasses = makeStyles(theme => ({
  label: {
    color: theme.palette.text.primary
  },
  selectedLabel: {
    color: theme.palette.grey[500]
  }
}));

const getOptionLabel = option => option.displayText;

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const CoManagerSelect = ({
  coManagers = [],
  onChange,
  loading,
  options,
  hasMore,
  optionTypeText,
  handleInputChange
}) => {
  const classes = useUserChipStyles();
  const userClasses = useUserClasses();
  const { formatMessage } = useIntl();
  const optionText = {
    loading: formatMessage({ id: 'taskBeatTable.loading' }),
    noOptions: formatMessage({ id: 'client.noComanagers' }),
    renderInputLabel: formatMessage({ id: 'client.comanagers' })
  };

  const renderInput = useCallback(
    params => <TextField {...params} label={optionText.renderInputLabel} />,
    [optionText.renderInputLabel]
  );

  const listItemClasses = useMemo(() => ({ root: classes.listItem }), [
    classes.listItem
  ]);

  const chipClasses = useMemo(() => ({ root: classes.chip }), [classes.chip]);

  const getUserClasses = selected => ({
    label: selected ? userClasses.selectedLabel : userClasses.label
  });

  const renderOption = useCallback(
    (option, { selected }) =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <ListItemIcon classes={listItemClasses}>
          <User
            user={option}
            disablePadding
            classes={getUserClasses(selected)}
          />
        </ListItemIcon>
      ),
    [listItemClasses]
  );

  const renderAvatar = useCallback(
    option => <UserAvatar className={classes.avatar} user={option} />,
    [classes.avatar]
  );

  const renderTags = useCallback(
    (values, getTagProps) =>
      values.map((option, index) => (
        <Chip
          classes={chipClasses}
          key={option.id}
          size="small"
          avatar={renderAvatar(option)}
          label={option.displayText}
          {...getTagProps({ index })}
        />
      )),
    [renderAvatar, chipClasses]
  );

  return (
    <SimpleAutocomplete
      dataQeId="CoManagerDropdown"
      loading={loading}
      options={options}
      size="small"
      loadingText={optionText.loading}
      noOptionsText={optionText.noOptions}
      fullWidth
      value={coManagers}
      multiple
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      renderOption={renderOption}
      renderTags={renderTags}
      getOptionDisabled={getOptionDisabled}
      hasMore={hasMore}
      optionTypeText={optionTypeText}
    />
  );
};

CoManagerSelect.propTypes = {
  coManagers: PropTypes.array,
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool,
  optionTypeText: PropTypes.string,
  handleInputChange: PropTypes.func
};

export default CoManagerSelect;
