import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddResourceIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 8,10 H 5 V 7 H 3 v 3 H 0 v 2 h 3 v 3 h 2 v -3 h 3 z m 10,1 C 19.66,11 20.99,9.66 20.99,8 20.99,6.34 19.66,5 18,5 17.68,5 17.37,5.05 17.09,5.140000000000001 17.66,5.950000000000002 17.99,6.930000000000001 17.99,8 17.99,9.070000000000002 17.65,10.04 17.09,10.86 17.37,10.95 17.68,11 18,11 Z m -5,0 c 1.66,0 2.99,-1.34 2.99,-3 0,-1.66 -1.33,-3 -2.99,-3 -1.66,0 -3,1.34 -3,3 0,1.66 1.34,3 3,3 z m 6.62,2.16 C 20.45,13.89 21,14.82 21,16 v 2 h 3 v -2 c 0,-1.54 -2.37,-2.49 -4.38,-2.84 z M 13,13 c -2,0 -6,1 -6,3 v 2 h 12 v -2 c 0,-2 -4,-3 -6,-3 z" />
    </>
  </SvgIcon>
));

export default AddResourceIcon;
