import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import { RoleName } from '~/modules/common/components/entities/role';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.text.primary,
    overflow: 'hidden',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    textAlign: 'start',
    padding: theme.spacing(0.5, 0)
  },
  noValue: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  roleName: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  }
}));

export const RoleField = ({ record: { role }, onClick }) => {
  const intl = useIntl();
  const classes = useStyles();
  const projectRole = useMemo(
    () => (role ? { displayText: role.displayText, uri: role.uri } : null),
    [role]
  );

  const handleOnClick = useCallback(
    () =>
      onClick({
        role: (role && role.uri) || NONE_OPTION_KEY,
        label: (role && role.displayText) || intl.formatMessage({ id: 'NONE' })
      }),
    [onClick, role, intl]
  );

  return (
    <ButtonBase className={classes.button} onClick={handleOnClick}>
      {projectRole ? (
        <RoleName
          className={classes.roleName}
          role={projectRole}
          showRate={false}
          showTooltip={false}
        />
      ) : (
        <span className={classes.noValue}>
          <FormattedMessage id="roleFormatter.noRole" />
        </span>
      )}
    </ButtonBase>
  );
};

RoleField.propTypes = {
  record: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default RoleField;
