/* eslint-disable react/jsx-max-depth */
import { DialogContent, Grid, TextField, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePutLineItemForBill } from '~/modules/billing-invoicing/bill/hooks';
import { Money2 } from '~/modules/common/components';
import NumberInput from '~/modules/common/components/NumberInput';
import { getError, hasError } from '~/util';
import AddLineItemsDialogFooter from './AddLineItemsDialogFooter';
import SearchableProjectDropdown from './Dropdown/SearchableProjectDropdown';
import {
  usePutCustomLineItemFormChange,
  usePutCustomLineItemFormState
} from './hooks';

const resourceLabels = {
  amount: <FormattedMessage id="customLineItem.amount" />,
  description: <FormattedMessage id="customLineItem.description" />,
  rate: <FormattedMessage id="customLineItem.rate" />,
  date: <FormattedMessage id="customLineItem.date" />,
  label: <FormattedMessage id="customLineItem.label" />,
  unit: <FormattedMessage id="customLineItem.unit" />
};

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0
    },
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: '560px',
    minHeight: '88px'
  },
  project: {
    padding: theme.spacing(0, 1, 1, 1)
  },
  unit: {
    padding: theme.spacing(1, 0, 1, 1)
  },
  label: {
    padding: theme.spacing(1, 1, 0, 0)
  },
  custom: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1)
    },
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'baseline',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  }
}));

export const CustomLineItemTab = ({
  client,
  billCurrency,
  setLineItemAdding,
  onClose,
  hideUnitAndRate = true,
  billDetails
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { putLineItemForBill } = usePutLineItemForBill();
  const formik = usePutCustomLineItemFormState({
    putLineItemForBill,
    billDetails
  });
  const { values, setFieldValue, errors, handleSubmit } = formik;
  const { project, description, unit, label, rate, amount } = values;
  const {
    onAmountChange,
    onRateChange,
    onLabelChange,
    onUnitChange,
    onDescriptionChange,
    onProjectChange
  } = usePutCustomLineItemFormChange({ setFieldValue });
  const onAddLineItems = useCallback(async () => {
    setLineItemAdding(true);
    await handleSubmit(values);
    setLineItemAdding(false);
    onClose();
  }, [setLineItemAdding, handleSubmit, values, onClose]);

  const accesibilityResourceLabels = useMemo(
    () => ({
      projectAriaLabel: intl.formatMessage({ id: 'customLineItem.project' }),
      currencyAriaLabel: intl.formatMessage({ id: 'customLineItem.amount' })
    }),
    [intl]
  );

  return (
    <>
      <DialogContent className={classes.custom}>
        <Grid container className={classes.container} spacing={2}>
          <Grid
            item
            xs={12}
            className={classes.project}
            data-qe-id="addLineItems.custom.project"
          >
            <SearchableProjectDropdown
              clientId={client.id}
              value={project}
              ariaLabel={accesibilityResourceLabels.projectAriaLabel}
              onChange={onProjectChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="addLineItems.custom.description"
              variant="filled"
              fullWidth
              label={resourceLabels.description}
              onChange={onDescriptionChange}
              value={description}
              error={hasError(errors, 'description')}
              helperText={getError(errors, 'description')}
              data-qe-id="addLineItems.custom.description"
            />
          </Grid>
          {!hideUnitAndRate && (
            <>
              <Grid item xs={8} sm={4} className={classes.unit}>
                <NumberInput
                  variant="filled"
                  label={resourceLabels.unit}
                  onValueChange={onUnitChange}
                  value={unit}
                  fullWidth
                  useNumberLabelStyles={false}
                  useNumberInputStyles={false}
                />
              </Grid>
              <Grid item xs={4} sm={2} className={classes.label}>
                <TextField
                  variant="filled"
                  fullWidth
                  label={resourceLabels.label}
                  onChange={onLabelChange}
                  value={label || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Money2
                  variant="filled"
                  label={resourceLabels.rate}
                  currencyEditable={false}
                  amount={rate}
                  currency={billCurrency}
                  onAmountChange={onRateChange}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <Money2
              id="addLineItems.custom.amount"
              variant="filled"
              label={resourceLabels.amount}
              labelClassName={classes.inputLabel}
              currencyEditable={false}
              currencyAriaLabel={accesibilityResourceLabels.currencyAriaLabel}
              amount={amount}
              currency={billCurrency}
              onAmountChange={onAmountChange}
              allowNegative
              data-qe-id="addLineItems.custom.amount"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <AddLineItemsDialogFooter
        onAddLineItems={onAddLineItems}
        onClose={onClose}
        disableAdd={false}
        addButtonLabel="addLineItemDialog.addLineItems"
        saveInProgress={false}
      />
    </>
  );
};

CustomLineItemTab.propTypes = {
  client: PropTypes.object.isRequired,
  billCurrency: PropTypes.object.isRequired,
  setLineItemAdding: PropTypes.func,
  onClose: PropTypes.func,
  hideUnitAndRate: PropTypes.bool,
  billDetails: PropTypes.object.isRequired
};

export default CustomLineItemTab;
