import { PropTypes } from 'prop-types';
import React from 'react';
import { HierarchyColumn } from '../HierarchyColumn';

export const GroupColumn = ({ field, record }) => (
  <HierarchyColumn field={field} record={record} />
);

GroupColumn.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default GroupColumn;
