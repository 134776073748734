import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import UserAllocationSummaryBlock from '../UserAllocationSummaryBlock';
import useAllocationDynamicStyles from './useAllocationDynamicStyles';

export const useAllocationSummaryBlockStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(3)
  },
  root: {
    zIndex: 4
  }
}));

const AllocationSummaryBlock = ({
  scale,
  chartStartDate,
  userAllocationSummaryPeriod,
  chartDisplayDateRange
}) => {
  const { loadRatio, projectsWithLoad } = userAllocationSummaryPeriod;

  const { dynamicStyle } = useAllocationDynamicStyles({
    loadRatio
  });

  const classes = useAllocationSummaryBlockStyles();

  const allocationDistributionItems = useMemo(
    () =>
      projectsWithLoad.map(x => ({
        name: x.projectName,
        allocationStatus: x.allocationStatus,
        totalHours: x.totalHours,
        load: x.load
      })),
    [projectsWithLoad]
  );

  const clippedChartDisplayDateRange = useMemo(
    () => ({
      start:
        userAllocationSummaryPeriod.startDate > chartDisplayDateRange.startDate
          ? userAllocationSummaryPeriod.startDate
          : chartDisplayDateRange.startDate,
      end:
        userAllocationSummaryPeriod.endDate < chartDisplayDateRange.endDate
          ? userAllocationSummaryPeriod.endDate
          : chartDisplayDateRange.endDate
    }),
    [
      userAllocationSummaryPeriod.startDate,
      userAllocationSummaryPeriod.endDate,
      chartDisplayDateRange.endDate,
      chartDisplayDateRange.startDate
    ]
  );

  const period = useMemo(
    () => ({
      startDate: userAllocationSummaryPeriod.startDate,
      endDate: userAllocationSummaryPeriod.endDate
    }),
    [userAllocationSummaryPeriod.startDate, userAllocationSummaryPeriod.endDate]
  );

  return (
    <UserAllocationSummaryBlock
      classes={classes}
      scale={scale}
      clippedChartDisplayDateRange={clippedChartDisplayDateRange}
      loadRatio={userAllocationSummaryPeriod.loadRatio}
      totalHours={userAllocationSummaryPeriod.totalAllocatedHours}
      containsAllocation={userAllocationSummaryPeriod.containsAllocation}
      period={period}
      chartStartDate={chartStartDate}
      allocationDistributionItems={allocationDistributionItems}
      dynamicStyle={dynamicStyle}
    />
  );
};

AllocationSummaryBlock.propTypes = {
  userAllocationSummaryPeriod: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired
};

export default AllocationSummaryBlock;
