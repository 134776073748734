import React from 'react';
import PropTypes from 'prop-types';
import { CHILD_INFO_DIALOG_COLUMN_TYPE } from '~/modules/common/enums';
import TaskSummaryRow from './TaskSummaryRow';

const EstimatedAtCompletionRow = ({
  childTasks,
  classes,
  date,
  estimatedAtCompletionAlternateTooltipComponent,
  estimatedAtCompletionAlternateHoursTooltipComponent,
  hasMore,
  hideCostTooltip = false,
  hideHoursTooltip = false,
  label,
  loadMore,
  loadingMore,
  noDateLabel,
  rolledUpCostSummary,
  rolledUpHoursSummary,
  showCost
}) => {
  const {
    totalEstimatedAtCompletionCostInProjectCurrency: totalEstimatedAtCompletionCost
  } = rolledUpCostSummary || {};

  return (
    <TaskSummaryRow
      dataQeId="Estimated_At_Completion_Row"
      classes={classes}
      label={label}
      selectedColumns={[
        CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
        CHILD_INFO_DIALOG_COLUMN_TYPE.ESTIMATED_AT_COMPLETION
      ]}
      selectedCostColumns={[
        CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
        CHILD_INFO_DIALOG_COLUMN_TYPE.COST_ESTIMATED_AT_COMPLETION
      ]}
      hours={rolledUpHoursSummary?.totalEstimatedAtCompletionHours}
      cost={totalEstimatedAtCompletionCost}
      date={date}
      noDateLabel={noDateLabel}
      childTasks={childTasks}
      hasMore={hasMore}
      loadMore={loadMore}
      loadingMore={loadingMore}
      showCost={showCost}
      hideCostTooltip={hideCostTooltip}
      hideHoursTooltip={hideHoursTooltip}
      hoursTooltipComponent={
        estimatedAtCompletionAlternateHoursTooltipComponent
      }
      costTooltipComponent={estimatedAtCompletionAlternateTooltipComponent}
    />
  );
};

EstimatedAtCompletionRow.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.object,
  rolledUpCostSummary: PropTypes.object,
  rolledUpHoursSummary: PropTypes.object,
  date: PropTypes.string,
  noDateLabel: PropTypes.string,
  childTasks: PropTypes.array,
  hasMore: PropTypes.bool,
  hideCostTooltip: PropTypes.bool,
  hideHoursTooltip: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  showCost: PropTypes.bool,
  estimatedAtCompletionAlternateTooltipComponent: PropTypes.node,
  estimatedAtCompletionAlternateHoursTooltipComponent: PropTypes.node
};

export default EstimatedAtCompletionRow;
