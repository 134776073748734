import { useCallback } from 'react';
import { objectMutations } from './mutations';

export const getProgramStatus = ({ deleteProgram2: { status } }) => status;

export const getClientStatus = ({ deleteClient2: { status } }) => status;

export const getProjectStatus = ({ deleteProject2: { status } }) => status;

export const getVendorStatus = ({ deleteVendor: { status } }) => status;

export const objectStatus = {
  PROGRAM: getProgramStatus,
  CLIENT: getClientStatus,
  PROJECT: getProjectStatus,
  VENDOR: getVendorStatus
};

export const getStatus = ({ objectType, result }) => {
  if (!objectStatus[objectType]) return null;
  const { data } = result;

  return objectStatus[objectType](data);
};

export const useDeleteObject = ({
  objectId,
  objectType,
  setStatus,
  closeDialog,
  setIsDeleting,
  objectName
}) => {
  const deleteObject = objectMutations[objectType]({ objectId, objectName });

  return useCallback(async () => {
    setIsDeleting(true);
    const result = await deleteObject({
      variables: { id: objectId }
    });

    setIsDeleting(false);
    const status = getStatus({ objectType, result });

    if (status) {
      setStatus(status);
    }

    closeDialog();
  }, [
    setIsDeleting,
    deleteObject,
    objectId,
    objectType,
    closeDialog,
    setStatus
  ]);
};
