import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const getSkillLevelsQuery = gql`
  query getEnabledSkillLevels {
    enabledSkillLevels {
      uri
      id
      displayText
      rating
    }
  }
`;

export const useSkillLevels = () => {
  const { loading, error, data: { enabledSkillLevels = [] } = {} } = useQuery(
    getSkillLevelsQuery,
    {}
  );

  return {
    isSkillLoading: loading,
    skillLevels: enabledSkillLevels,
    error,
    maxSkillLevel: Math.max(
      ...(enabledSkillLevels || []).map(skill => skill.rating),
      0
    )
  };
};

export default useSkillLevels;
