import React from 'react';
import PropTypes from 'prop-types';
import ResourceRequestUserAllocationPeriodOverlay from '../ResourceRequestUserAllocationPeriodOverlay';

const ResourceRequestUserAllocationOverlay = ({
  handleAllocationPeriodClick,
  allocation,
  scale,
  classes,
  isEditable = true,
  requestPeriods,
  resourceAvailabilitySummarySeries,
  resourceRequest
}) =>
  (
    requestPeriods || []
  ).map(
    ({
      key,
      startDate,
      endDate,
      totalHours,
      totalScheduleHours,
      dynamicPosition,
      percentage,
      totalCost,
      allocationPeriodOverlapType
    }) => (
      <ResourceRequestUserAllocationPeriodOverlay
        key={key}
        startDate={startDate}
        endDate={endDate}
        totalHours={totalHours}
        requestPeriods={requestPeriods}
        allocation={allocation}
        scale={scale}
        classes={classes}
        isEditable={isEditable}
        totalScheduleHours={totalScheduleHours}
        dynamicPosition={dynamicPosition}
        handleAllocationPeriodClick={handleAllocationPeriodClick}
        resourceAvailabilitySummarySeries={resourceAvailabilitySummarySeries}
        percentage={percentage}
        totalCost={totalCost}
        resourceRequest={resourceRequest}
        allocationPeriodOverlapType={allocationPeriodOverlapType}
      />
    )
  );

ResourceRequestUserAllocationOverlay.propTypes = {
  handleAllocationPeriodClick: PropTypes.func.isRequired,
  allocation: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  scale: PropTypes.string.isRequired,
  resourceAvailabilitySummarySeries: PropTypes.array,
  displayUnit: PropTypes.string
};

export default ResourceRequestUserAllocationOverlay;
