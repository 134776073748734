import { object, string } from 'yup';

const buildValidationSchema = intl =>
  object().shape({
    category: object()
      .required(
        intl.formatMessage({
          id: 'skillsDropdown.pleaseSelectCategory'
        })
      )
      .nullable()
      .shape({
        id: string(),
        name: string().max(
          50,
          intl.formatMessage({
            id: 'skillsDropdown.categoryExceedsMaxLength'
          })
        )
      }),
    name: string()
      .required(
        intl.formatMessage({ id: 'addProject.enterProjectNameErrorMsg' })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'skillsDropdown.nameExceedsMaxLength'
        })
      ),
    description: string().max(
      255,
      intl.formatMessage({
        id: 'skillsDropdown.descriptionExceedsMaxLength'
      })
    )
  });

export default buildValidationSchema;
