/* eslint-disable react/no-array-index-key */
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryTheme,
  VictoryContainer,
  VictoryLabel
} from 'victory';
import { useVictoryChartContainerStyles } from '~/modules/common/charts/dashboard/config';
import { ChartLegends } from '~/modules/common/charts/dashboard';
import chartStyles from './chartStyles';
import useChartConfiguration from './useChartConfiguration';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: '100%'
    },
    flexDirection: 'column',
    padding: theme.spacing(0, 1, 1),
    flip: false
  },
  legend: {
    paddingTop: 0
  }
}));

const dependentAxisYDomain = { y: [0, 10] };

export const ResourceFulfillmentChart = ({
  classes: classesOverride,
  seriesData,
  titleId,
  dateRanges
}) => {
  const chartStyle = chartStyles(useTheme());
  const intl = useIntl();
  const {
    dataSet,
    colorScale,
    xTickFormat,
    chartLegends,
    yTickFormat
  } = useChartConfiguration({
    seriesData,
    dateRanges,
    intl
  });
  const classes = useStyles({ classes: classesOverride });
  const victoryContainerClasses = useVictoryChartContainerStyles();
  const tickLabel = useMemo(() => <VictoryLabel direction="ltr" />, []);

  return (
    <div
      className={classNames(
        classes.mainContainer,
        victoryContainerClasses.container
      )}
    >
      <ChartLegends className={classes.legend} legends={chartLegends} />
      <VictoryChart
        theme={VictoryTheme.material}
        width={chartStyle.victoryChart.width}
        height={chartStyle.victoryChart.height}
        padding={chartStyle.victoryChart.padding}
        domainPadding={chartStyle.victoryChart.domainPadding}
        containerComponent={<VictoryContainer aria-labelledby={titleId} />}
      >
        <VictoryStack colorScale={colorScale}>
          {dataSet.map((data, i) => (
            <VictoryBar
              data={data}
              key={`VictoryBar${i}`}
              barWidth={56.2}
              alignment="start"
            />
          ))}
        </VictoryStack>
        <VictoryAxis
          dependentAxis
          tickFormat={yTickFormat}
          domain={dependentAxisYDomain}
          style={chartStyle.victoryAxis.y}
          label={intl.formatMessage({
            id: 'projectOverview.requestedResources'
          })}
          tickLabelComponent={tickLabel}
        />
        <VictoryAxis
          fixLabelOverlap={false}
          tickFormat={xTickFormat}
          style={chartStyle.victoryAxis.x}
          tickLabelComponent={tickLabel}
        />
      </VictoryChart>
    </div>
  );
};

ResourceFulfillmentChart.propTypes = {
  classes: PropTypes.object,
  seriesData: PropTypes.array,
  titleId: PropTypes.string,
  dateRanges: PropTypes.array
};

export default ResourceFulfillmentChart;
