import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, Grid } from '@material-ui/core';

const LoadingChartComponents = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Typography component="div" variant="h1">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="h3">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="h4">
          <Skeleton />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoadingChartComponents;
