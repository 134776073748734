/* eslint-disable max-nested-callbacks */
import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircleSharp';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Menu,
  MenuItem,
  makeStyles,
  IconButton,
  ListItemText,
  Chip
} from '@material-ui/core';
import { useMenuState, useHasFeatureFlag } from '~/modules/common/hooks';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import { buildColumns } from './BillingItemsTable/columns';

const useStyles = makeStyles(theme => ({
  addButton: {
    padding: 0
  },
  columnChip: {
    margin: theme.spacing(0, 0.5, 0.125, 0)
  },
  groupByLabel: {
    flexGrow: 0,
    flexShrink: 0,
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  }
}));

export const BillingItemGroup = ({ columns, setColumns }) => {
  const isPsaFPOvertimeBillingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFPOvertimeBillingEnabled'
  });
  const classes = useStyles();
  const intl = useIntl();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const ariaLabel = intl.formatMessage({
    id: 'addLineItemDialog.billingItemGroup.groupBy'
  });
  const columnOptions = Object.keys(
    buildColumns({ isPsaFPOvertimeBillingEnabled })
  ).map(key => buildColumns({})[key]);

  const isOpen = Boolean(anchorEl);
  const onMenuItemClick = useCallback(
    item => async () => {
      const existingColumns = Object.assign([], columns);

      existingColumns.push(item);
      setColumns(existingColumns);
      onMenuClose();
    },
    [columns, onMenuClose, setColumns]
  );

  const onDeleteClick = useCallback(
    item => async () => {
      const existingColumns = Object.assign([], columns);

      setColumns(existingColumns.filter(i => i !== item));
      onMenuClose();
    },
    [columns, onMenuClose, setColumns]
  );

  const groupingColumnOptions = useMemo(() => {
    const addedMap = new Map();

    columns.forEach(column => {
      const option = columnOptions.find(p => p.groupId === column);

      addedMap.set(option.groupId, true);
    });

    return columnOptions.filter(q => !addedMap.has(q.groupId) && q.groupId);
  }, [columnOptions, columns]);

  return (
    <>
      <span className={classes.groupByLabel}>
        <FormattedMessage id="addLineItemDialog.showLineItemsBy" />
      </span>
      {columns.map(column => {
        const opt = columnOptions.find(p => p.groupId === column);

        if (
          !isPsaFPOvertimeBillingEnabled &&
          (opt.groupId === BILLING_COLUMN_TYPE.PAYCODE ||
            opt.groupId === BILLING_COLUMN_TYPE.ROLE)
        ) {
          return null;
        }

        return (
          <Chip
            key={column}
            size="small"
            label={opt.value}
            disabled={opt.groupId === 'PROJECT'}
            className={classes.columnChip}
            onDelete={onDeleteClick(column)}
            data-qe-id={`${column}_deleteBillingItemGroup`}
          />
        );
      })}
      {groupingColumnOptions.length > 0 && (
        <IconButton
          aria-label={ariaLabel}
          className={classes.addButton}
          onClick={onMenuClick}
          data-qe-id="addBillingItemGroup"
        >
          <AddCircleIcon />
        </IconButton>
      )}
      <Menu anchorEl={anchorEl} open={isOpen} onClose={onMenuClose}>
        {groupingColumnOptions.map(option => {
          if (
            !isPsaFPOvertimeBillingEnabled &&
            (option.groupId === BILLING_COLUMN_TYPE.PAYCODE ||
              option.groupId === BILLING_COLUMN_TYPE.ROLE)
          ) {
            return null;
          }

          return (
            <MenuItem
              key={option.groupId}
              value={option.groupId}
              onClick={onMenuItemClick(option.groupId)}
            >
              <ListItemText primary={option.value} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

BillingItemGroup.propTypes = {
  columns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired
};

export default BillingItemGroup;
