import { useMemo } from 'react';
import { PERIOD_SCALE_ENUM } from '~/modules/resourcing/common/enums';
import { OVERVIEW_UNITS_ENUM } from '~/modules/resource-management/common/enums';
import { useResourceOverviewToolbarContext } from '~/modules/resource-management/ResourcingOverview/hooks';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { useResourceManagementContext } from '~/modules/resource-management/common';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';
import { getFormattedDateForScale } from '~/modules/resource-management/ResourcingOverview/util';
import { useResourceAllocationSeriesData } from '~/modules/resourcing/common/hooks';
import {
  convertResourceAllocationSeriesDataToFTEUnits,
  convertResourceAllocationSeriesDataToPercentageUnits
} from './fte-conversion';

const periodResolution = {
  [PERIOD_SCALE_ENUM.YEARS]: 'YEARLY',
  [PERIOD_SCALE_ENUM.QUARTERS]: 'QUARTERLY',
  [PERIOD_SCALE_ENUM.DAYS]: 'DAILY',
  [PERIOD_SCALE_ENUM.MONTHS]: 'MONTHLY',
  [PERIOD_SCALE_ENUM.WEEKS]: 'WEEKLY'
};

export const getDateRange = ({ dateRange: { startDate }, periodScale }) =>
  getFormattedDateForScale(periodScale, mapRepliconDateToUtcObject(startDate));

export default ({ overViewUnit }) => {
  const { dateRange, periodScale } = useResourceOverviewToolbarContext();
  const { searchCriteria } = useResourceManagementContext();
  const { filter } = useFilterBasedOnSearchCriteria({ searchCriteria });
  const {
    loading,
    resourceAllocationSeriesData
  } = useResourceAllocationSeriesData({
    dateRange,
    periodResolution: periodResolution[periodScale],
    filter
  });

  const resourceAllocationSeriesDataInDesiredUnits =
    overViewUnit === OVERVIEW_UNITS_ENUM.FTE
      ? convertResourceAllocationSeriesDataToFTEUnits(
          resourceAllocationSeriesData
        )
      : overViewUnit === OVERVIEW_UNITS_ENUM.PERCENTAGE
      ? convertResourceAllocationSeriesDataToPercentageUnits(
          resourceAllocationSeriesData
        )
      : resourceAllocationSeriesData;

  return useMemo(() => {
    if (!loading) {
      const totalScheduleData = resourceAllocationSeriesDataInDesiredUnits.map(
        ({ dateRange: dateRanges, totalSchedule }) => ({
          dates: getDateRange({ dateRange: dateRanges, periodScale }),
          value: totalSchedule
        })
      );
      const overviewDataPoints = {
        overAllocated: [],
        holiday: [],
        timeOff: [],
        allocated: [],
        requested: [],
        available: [],
        toBeHired: []
      };

      const rawData = resourceAllocationSeriesDataInDesiredUnits.map(
        allocation => {
          const date = getDateRange({
            dateRange: allocation.dateRange,
            periodScale
          });
          const others =
            (allocation.timeoff || 0) +
            (allocation.holiday || 0) +
            (allocation.requested || 0) +
            (allocation.allocated || 0);

          const available = allocation.totalSchedule - others;

          overviewDataPoints.overAllocated.push({
            dates: date,
            value: -allocation.overAllocated
          });

          overviewDataPoints.holiday.push({
            dates: date,
            value: allocation.holiday
          });

          overviewDataPoints.timeOff.push({
            dates: date,
            value: allocation.timeoff
          });

          overviewDataPoints.allocated.push({
            dates: date,
            value: allocation.allocated
          });

          overviewDataPoints.requested.push({
            dates: date,
            value: allocation.requested
          });

          overviewDataPoints.available.push({
            dates: date,
            value: Math.max(0, available),
            // drawing bars available should exclude the requested to match, dont change
            labelValue: Math.abs(
              Number(
                Math.max(0, available + (allocation.requested || 0)).toFixed(1)
              )
            )
          });

          overviewDataPoints.toBeHired.push({
            dates: date,
            value: allocation.toBeHired
          });

          return {
            ...allocation,
            available: Math.max(0, available + (allocation.requested || 0)) // available shouldnt exclude the requested
          };
        }
      );

      return {
        loading,
        dataPoints: [
          {
            id: 'overAllocated',
            dataPoints: overviewDataPoints.overAllocated
          },
          {
            id: 'holiday',
            dataPoints: overviewDataPoints.holiday
          },
          {
            id: 'timeOff',
            dataPoints: overviewDataPoints.timeOff
          },
          {
            id: 'allocated',
            dataPoints: overviewDataPoints.allocated
          },
          {
            id: 'requested',
            dataPoints: overviewDataPoints.requested
          },
          {
            id: 'available',
            dataPoints: overviewDataPoints.available
          },
          {
            id: 'toBeHired',
            dataPoints: overviewDataPoints.toBeHired
          }
        ],
        totalSchedule: totalScheduleData,
        resourceAllocationSeriesData: rawData,
        unit: overViewUnit,
        resourceSeriesDataInFteUnits: convertResourceAllocationSeriesDataToFTEUnits(
          resourceAllocationSeriesData
        ),
        resourceSeriesDataInPercentage: convertResourceAllocationSeriesDataToPercentageUnits(
          resourceAllocationSeriesData
        ),
        resourceSeriesDataInHours: resourceAllocationSeriesData
      };
    }

    return {
      loading
    };
  }, [
    loading,
    overViewUnit,
    periodScale,
    resourceAllocationSeriesData,
    resourceAllocationSeriesDataInDesiredUnits
  ]);
};
