import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';

export const TAG_FILTER_VALUES_QUERY = gql`
  query getTagFilterValues($tagId: String!) {
    tag(id: $tagId) {
      values {
        id
        value
      }
    }
  }
`;

const mapOptions = options =>
  options.map(option => ({
    label: option.value,
    value: option.id
  }));

export const useTagValues = ({ tagId }) => {
  const { data, loading } = useQuery(TAG_FILTER_VALUES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      tagId
    }
  });

  const resultOptions =
    data && data.tag && data.tag.values && Array.isArray(data.tag.values)
      ? mapOptions(data.tag.values)
      : [];

  return {
    data: resultOptions,
    loading
  };
};

export const TagValueDropdown = ({
  value,
  onChange,
  intl,
  tagId,
  disableAttachment,
  menuPlacement
}) => {
  const { loading, data } = useTagValues({ tagId });

  const selectedValue = useMemo(
    () =>
      value && value.label && value.value
        ? {
            label: value.label,
            value: value.value
          }
        : null,
    [value]
  );
  const change = useCallback(
    val => {
      if (!val) {
        return null;
      }

      return onChange(val);
    },
    [onChange]
  );

  const getValueDisabled = useCallback(
    option =>
      option &&
      selectedValue &&
      option.value &&
      selectedValue.value &&
      option.value === selectedValue.value,
    [selectedValue]
  );

  return (
    <SimpleAutocomplete
      value={selectedValue}
      onChange={change}
      optionPropText="label"
      variant="standard"
      options={data}
      loading={loading}
      hiddenLabel
      fullWidth
      getOptionDisabled={getValueDisabled}
    />
  );
};

TagValueDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  tagId: PropTypes.string.isRequired,
  intl: PropTypes.object,
  menuPlacement: PropTypes.string,
  disableAttachment: PropTypes.bool
};

export default injectIntl(TagValueDropdown);
