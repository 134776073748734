import { useCallback, useMemo } from 'react';
import { useHasPermission } from '~/modules/common/permissions';

const HIDE_BILL_PLAN_SLUGS = ['fixed-bid-milestone'];

export const useSetSaveable = ({
  setSaveable,
  billPlanSlug,
  editable,
  showScriptParamsEditorTable = true
}) => {
  const canViewTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-tasks'
  });

  const canEditTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-tasks'
  });

  const hideAddFixedBid = useMemo(
    () =>
      HIDE_BILL_PLAN_SLUGS.includes(billPlanSlug) &&
      (!canViewTasks || !canEditTasks),
    [billPlanSlug, canViewTasks, canEditTasks]
  );

  const setSaveableCallback = useCallback(() => {
    setSaveable(!hideAddFixedBid);
  }, [setSaveable, hideAddFixedBid]);

  const showAddButton = useMemo(
    () => showScriptParamsEditorTable && editable && !hideAddFixedBid,
    [editable, hideAddFixedBid, showScriptParamsEditorTable]
  );

  return {
    showAddButton,
    setSaveableCallback
  };
};

export default useSetSaveable;
