import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PillChartColors } from '~/modules/common/charts/dashboard';

const useProjectStatusChartData = ({ overEstimated = 0, onTrack = 0 }) => {
  const data = [
    {
      value: overEstimated,
      label: <FormattedMessage id="projectOverview.overEstimation" />,
      className: 'overEstimation',
      color: PillChartColors.overEstimation,
      key: 'overEstimation'
    },
    {
      value: onTrack,
      label: <FormattedMessage id="projectOverview.onTrack" />,
      className: 'onTrack',
      color: PillChartColors.onTrack,
      key: 'onTrack'
    }
  ];

  const legends = [
    {
      label: <FormattedMessage id="clientsOverview.projects.overBudget" />,
      color: PillChartColors.overEstimation
    },
    {
      label: <FormattedMessage id="clientsOverview.projects.onTrack" />,
      color: PillChartColors.onTrack
    }
  ];

  return { data, legends };
};

export default useProjectStatusChartData;
