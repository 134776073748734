import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { useMeContext } from '~/modules/me/useMeContext';
import DateRange from '~/modules/common/components/DateRange';

const useStyles = makeStyles(theme => ({
  container: ({ isProject }) => ({
    marginTop: theme.spacing(1),
    paddingLeft: isProject ? 0 : theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(1.5)
  },
  dateRangeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  date: {
    fontSize: theme.spacing(1.5),
    fontWeight: 400,
    color: theme.palette.taskRollUp.text.light
  },
  spacer: {
    color: theme.palette.taskRollUp.text.light
  },
  icon: {
    fontSize: theme.spacing(2.25),
    fill: theme.palette.error.main
  },
  statusPill: ({ taskStatus }) => ({
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(1.5),
    fontWeight: '600',
    color: theme.palette.text.main,
    backgroundColor: theme.palette.taskStatus[taskStatus].color
  })
}));

const TaskCardSubHeader = ({
  startDate,
  endDate,
  taskStatus,
  isProject = false
}) => {
  const {
    featureFlags: { isPSATaskEstimateRollUpMobileViewEnabled }
  } = useMeContext();
  const classes = useStyles({ taskStatus, isProject });
  const dateClasses = useMemo(
    () => ({
      date: classes.date,
      spacer: classes.spacer
    }),
    [classes.date, classes.spacer]
  );
  const missingDateMessage =
    !startDate && !endDate
      ? 'projectTasksPage.datesAreMissing'
      : !startDate
      ? 'projectTasksPage.startDateMissing'
      : 'projectTasksPage.endDateMissing';

  return isPSATaskEstimateRollUpMobileViewEnabled ? (
    <div className={classes.container}>
      <div className={classes.dateContainer}>
        <div className={classes.dateRangeContainer}>
          {!startDate && <ReportProblemOutlined className={classes.icon} />}
          <DateRange classes={dateClasses} start={startDate} end={endDate} />
          {!endDate && <ReportProblemOutlined className={classes.icon} />}
        </div>
        {(!startDate || !endDate) && (
          <FormattedMessage id={missingDateMessage} />
        )}
      </div>
      <span className={classes.statusPill}>
        <FormattedMessage id={`projectTasksPage.tasksStatus.${taskStatus}`} />
      </span>
    </div>
  ) : (
    <DateRange start={startDate} end={endDate} />
  );
};

TaskCardSubHeader.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  taskStatus: PropTypes.string,
  isProject: PropTypes.bool
};

export default TaskCardSubHeader;
