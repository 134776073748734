import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BillStatus } from '~/types';
import {
  AssociatedBillCreditsTable,
  DrawerExpansionPanel
} from '~/modules/billing-invoicing/common/components';
import { AssignBillCreditButton } from './components';
import {
  useBillPaymentHooks,
  useCreditMemoHooks,
  useAvailableCreditMemos,
  useAvailableBillPayments,
  usePutCreditMemosForBill,
  usePutBillPaymentsForBill,
  useAssociatedBillPayments,
  useAssociatedCreditMemos,
  useAssociatedCreditsTotalProps
} from './hooks';

export const BillCreditsSection = ({
  history,
  billId,
  billCurrency,
  client,
  billBalanceTotal,
  queryParams,
  permissions,
  setAssociateCreditTotal,
  billStatus
}) => {
  const intl = useIntl();
  const { id: billCurrencyId } = billCurrency;
  const [paymentExpanded, setPaymentExpanded] = useState(false);
  const [creditMemoExpanded, setCreditMemoExpanded] = useState(false);
  const { canAddPaymentOrCreditMemo } = permissions;

  const canEditBillPaymentsOrCreditMemo = canAddPaymentOrCreditMemo;

  const {
    loading: availableCreditMemosLoading,
    availableCreditMemos
  } = useAvailableCreditMemos({
    billId,
    canEditBill: canEditBillPaymentsOrCreditMemo
  });
  const {
    loading: availableBillPaymentsLoading,
    availableBillPayments
  } = useAvailableBillPayments({
    billId,
    canEditBill: canEditBillPaymentsOrCreditMemo
  });
  const { putCreditMemosForBill } = usePutCreditMemosForBill();
  const { putBillPaymentsForBill } = usePutBillPaymentsForBill();

  const {
    associatedBillPayments,
    hasMore: hasMoreAssociatedBillPayments,
    loadMore: loadMoreAssociatedBillPayments
  } = useAssociatedBillPayments(billId);

  const {
    associatedCreditMemos,
    hasMore: hasMoreAssociatedCreditMemos,
    loadMore: loadMoreAssociatedCreditMemos
  } = useAssociatedCreditMemos(billId);

  const {
    associatedCreditMemoTotal,
    associatedBillPaymentsTotal
  } = useAssociatedCreditsTotalProps({
    billCurrency,
    associatedCreditMemos,
    associatedBillPayments,
    setAssociateCreditTotal
  });

  const {
    isCreditMemoAssignmentInProgress,
    creditMemoPanelResourceKeys,
    creditMemoTableResourceKeys,
    creditMemoAssignButtonResourceKeys,
    handleCreditMemoClick,
    handleDeleteCreditMemoClick,
    hasMutiCurrencyAssociationsForCreditMemo
  } = useCreditMemoHooks({
    intl,
    billId,
    billCurrencyId,
    associatedCreditMemos,
    billBalanceTotal,
    putCreditMemosForBill
  });

  const isRefund =
    billBalanceTotal.amount < 0 || associatedBillPaymentsTotal.amount < 0;

  const {
    isPaymentAssignmentInProgress,
    paymentPanelResourceKeys,
    paymentTableResourceKeys,
    paymentAssignButtonResourceKeys,
    handlePaymentClick,
    handleDeletePaymentClick,
    hasMutiCurrencyAssociationsForPayment
  } = useBillPaymentHooks({
    intl,
    billId,
    billCurrencyId,
    billBalanceTotal,
    associatedBillPayments,
    putBillPaymentsForBill,
    isRefund
  });

  useEffect(() => {
    setPaymentExpanded(billStatus === BillStatus.Billed);
    setCreditMemoExpanded(billStatus === BillStatus.Billed);
  }, [billStatus]);

  return (
    <>
      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={paymentPanelResourceKeys}
          amountTotal={associatedBillPaymentsTotal}
          defaultExpanded={false}
          expanded={paymentExpanded}
          setExpanded={setPaymentExpanded}
        >
          {isPaymentAssignmentInProgress && (
            <CircularProgress
              data-qe-id="isPaymentAssignmentInProgress"
              size={24}
            />
          )}
          <AssociatedBillCreditsTable
            history={history}
            itemType="PAYMENT"
            associatedItems={associatedBillPayments}
            allocatedAmountTotal={associatedBillPaymentsTotal}
            resourceKeys={paymentTableResourceKeys}
            onDelete={handleDeletePaymentClick}
            dateColumnKey="paymentDate"
            creditColumnKey="allocatedBillPaymentAmount"
            isMultiCurrency={hasMutiCurrencyAssociationsForPayment}
            editable={canEditBillPaymentsOrCreditMemo}
            loadMore={loadMoreAssociatedBillPayments}
            hasMore={hasMoreAssociatedBillPayments}
          />
          {Boolean(
            !availableBillPaymentsLoading &&
              billBalanceTotal.amount > 0 &&
              !isPaymentAssignmentInProgress &&
              canEditBillPaymentsOrCreditMemo
          ) && (
            <AssignBillCreditButton
              variant="payment"
              resourceKeys={paymentAssignButtonResourceKeys}
              availableBillCredits={availableBillPayments}
              billId={billId}
              client={client}
              billBalanceTotal={billBalanceTotal}
              queryParams={queryParams}
              onSelection={handlePaymentClick}
              dataQeId="billing-addBillPaymentButton"
              billStatus={billStatus}
            />
          )}
        </DrawerExpansionPanel>
      </Grid>
      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={creditMemoPanelResourceKeys}
          amountTotal={associatedCreditMemoTotal}
          defaultExpanded={false}
          expanded={creditMemoExpanded}
          setExpanded={setCreditMemoExpanded}
        >
          {isCreditMemoAssignmentInProgress && (
            <CircularProgress
              data-qe-id="isCreditMemoAssignmentInProgress"
              size={24}
            />
          )}
          <AssociatedBillCreditsTable
            history={history}
            itemType="CREDITMEMO"
            associatedItems={associatedCreditMemos}
            allocatedAmountTotal={associatedCreditMemoTotal}
            resourceKeys={creditMemoTableResourceKeys}
            onDelete={handleDeleteCreditMemoClick}
            dateColumnKey="creditDate"
            creditColumnKey="allocatedCreditMemoAmount"
            isMultiCurrency={hasMutiCurrencyAssociationsForCreditMemo}
            editable={canEditBillPaymentsOrCreditMemo}
            hasMore={hasMoreAssociatedCreditMemos}
            loadMore={loadMoreAssociatedCreditMemos}
          />
          {Boolean(
            !availableCreditMemosLoading &&
              billBalanceTotal.amount > 0 &&
              !isCreditMemoAssignmentInProgress &&
              canEditBillPaymentsOrCreditMemo
          ) && (
            <AssignBillCreditButton
              variant="creditMemo"
              resourceKeys={creditMemoAssignButtonResourceKeys}
              availableBillCredits={availableCreditMemos}
              billId={billId}
              client={client}
              billBalanceTotal={billBalanceTotal}
              queryParams={queryParams}
              onSelection={handleCreditMemoClick}
              dataQeId="billing-addBillCreditMemoButton"
              billStatus={billStatus}
            />
          )}
        </DrawerExpansionPanel>
      </Grid>
    </>
  );
};
BillCreditsSection.propTypes = {
  history: PropTypes.object.isRequired,
  billId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  billBalanceTotal: PropTypes.object,
  queryParams: PropTypes.any,
  permissions: PropTypes.object,
  billCurrency: PropTypes.object,
  setAssociateCreditTotal: PropTypes.func,
  billStatus: PropTypes.string
};

export default BillCreditsSection;
