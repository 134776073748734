import { gql } from 'graphql-tag';
import { SpecificResourceRequestFragmentDoc } from '~/types';
import {
  specificResourceRequestWithAllocationsFragment,
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment,
  specificResourceAllocationTimeOffFragment,
  specificResourceAllocationHolidaysFragment,
  resourceRequestTotalsFragment,
  allocationTotalsFragment
} from '~/modules/resourcing/common/fragments';

const RESOURCE_REQUESTS_ALLOCATIONS_QUERY = gql`
  query resourceRequests(
    $cursor: String
    $limit: Int = 500
    $projectUri: String
    $filter: ResourceRequestsFilter
    $sort: ResourceRequestSort
    $requestStatusList: [ResourceRequestStatus]
    $minimumLimit: Int
  ) {
    resourceRequests(
      limit: $limit
      cursor: $cursor
      projectUri: $projectUri
      filter: $filter
      sort: $sort
      requestStatusList: $requestStatusList
      minimumLimit: $minimumLimit
    ) {
      resourceRequests {
        ...SpecificResourceRequestWithAllocation
        ...ResourceRequestTotalsFragment
        resourceAllocations {
          ...AllocationTotalsFragment
        }
      }
      nextPageCursor
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
  ${resourceRequestTotalsFragment}
  ${allocationTotalsFragment}
`;

export default RESOURCE_REQUESTS_ALLOCATIONS_QUERY;

export const RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF = gql`
  query resourceRequestWithTimeoff(
    $cursor: String
    $limit: Int = 500
    $projectUri: String
    $filter: ResourceRequestsFilter
    $sort: ResourceRequestSort
    $showTimeOff: Boolean!
    $chartDateRange: DateRangeInput
    $showHolidays: Boolean!
    $requestStatusList: [ResourceRequestStatus]
    $minimumLimit: Int
  ) {
    resourceRequests(
      limit: $limit
      cursor: $cursor
      projectUri: $projectUri
      filter: $filter
      sort: $sort
      requestStatusList: $requestStatusList
      minimumLimit: $minimumLimit
    ) {
      resourceRequests {
        ...SpecificResourceRequest
        ...ResourceRequestTotalsFragment
        resourceAllocations {
          ...SpecificResourceAllocation
          user {
            ...SpecificResourceAllocationUser
            ...SpecificResourceAllocationTimeOff
            ...SpecificResourceAllocationHolidays
          }
          ...AllocationTotalsFragment
        }
      }
      nextPageCursor
    }
  }
  ${SpecificResourceRequestFragmentDoc}
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
  ${specificResourceAllocationTimeOffFragment}
  ${specificResourceAllocationHolidaysFragment}
  ${resourceRequestTotalsFragment}
  ${allocationTotalsFragment}
`;
