import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';

const useStyles = makeStyles(theme => ({
  warning: {
    whiteSpace: 'pre-line'
  }
}));

const warningMessageLabel = {
  warningWithTCV: 'projectRevenueRecognitionCard.warningMessageWithTCV',
  warningWithoutTCV: 'projectRevenueRecognitionCard.warningMessage',
  baseCurrencyWarning: 'projectRevenueRecognitionCard.baseCurrencyWarning'
};

export const RevenueRecognitionWarning = ({
  includesContractValue,
  showWarning,
  asOfCloseDate,
  recognizedRevenueSummary
}) => {
  const classes = useStyles();
  const warningMessage = useMemo(
    () => ({
      date: asOfCloseDate
        ? mapRepliconDateToUtcObject(asOfCloseDate).toLocaleString({
            month: 'short',
            year: 'numeric'
          })
        : null,
      openMonth: asOfCloseDate
        ? mapRepliconDateToUtcObject(asOfCloseDate)
            .plus({ month: 1 })
            .toLocaleString({
              month: 'short',
              year: 'numeric'
            })
        : null,
      currency: recognizedRevenueSummary
        ? recognizedRevenueSummary.currency.displayText
        : null,
      recognizedAmount: recognizedRevenueSummary
        ? recognizedRevenueSummary.amount
        : 0
    }),
    [asOfCloseDate, recognizedRevenueSummary]
  );

  return (
    <>
      {asOfCloseDate && showWarning ? (
        <Alert severity="warning" size="small" className={classes.warning}>
          <FormattedMessage
            id={
              includesContractValue
                ? warningMessageLabel.warningWithTCV
                : warningMessageLabel.warningWithoutTCV
            }
            values={warningMessage}
          />
        </Alert>
      ) : (
        <>
          {includesContractValue && showWarning ? (
            <Alert severity="warning" size="small" className={classes.warning}>
              <FormattedMessage id={warningMessageLabel.baseCurrencyWarning} />
            </Alert>
          ) : null}
        </>
      )}
    </>
  );
};

RevenueRecognitionWarning.propTypes = {
  includesContractValue: PropTypes.bool,
  showWarning: PropTypes.bool,
  asOfCloseDate: PropTypes.object,
  recognizedRevenueSummary: PropTypes.object
};

export default RevenueRecognitionWarning;
