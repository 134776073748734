import React, { useState, useMemo, createContext, useContext } from 'react';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { itemTypes } from '~/modules/common/components/SearchBox/Facets/BillingItemTypeFacet/enhancers/useBillingItemType';

export const AddLineItemsDialogContext = createContext();

export const useLineItemsDialogContext = () =>
  useContext(AddLineItemsDialogContext);

export const LineItemsSearchContextProvider = ({
  availableToBillEndDate,
  availableToBillStartDate,
  availableToBillItemType,
  projectUri,
  projectName,
  children,
  ...props
}) => {
  const intl = useIntl();
  const startDate = availableToBillStartDate
    ? DateTime.fromObject(availableToBillStartDate)
    : null;
  const endDate = availableToBillEndDate
    ? DateTime.fromObject(availableToBillEndDate)
    : null;

  const [searchCriteria, setSearchCriteria] = useState({
    keywords: [],
    criterions: {
      'date:':
        startDate && endDate
          ? [
              {
                range: {
                  startDate,
                  endDate
                },
                key: `(${startDate.toLocaleString(
                  DateTime.DATE_MED
                )} - ${endDate.toLocaleString(DateTime.DATE_MED)})`
              }
            ]
          : [],

      'project:': projectUri
        ? [
            {
              value: projectUri,
              key: projectName,
              displayText: projectName,
              label: projectName
            }
          ]
        : [],

      'itemType:': availableToBillItemType
        ? [
            {
              value: availableToBillItemType,
              key: itemTypes(intl).find(
                itemType => itemType.id === availableToBillItemType
              ).label
            }
          ]
        : []
    }
  });

  const values = useMemo(
    () => ({
      searchCriteria,
      setSearchCriteria,
      ...props
    }),
    [props, searchCriteria]
  );

  return (
    <AddLineItemsDialogContext.Provider value={values}>
      {children}
    </AddLineItemsDialogContext.Provider>
  );
};
LineItemsSearchContextProvider.propTypes = {
  children: PropTypes.node,
  availableToBillEndDate: PropTypes.object,
  availableToBillStartDate: PropTypes.object,
  availableToBillItemType: PropTypes.object,
  projectUri: PropTypes.string,
  projectName: PropTypes.string
};

export default LineItemsSearchContextProvider;
