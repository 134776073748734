/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Divider,
  CardHeader,
  Collapse
} from '@material-ui/core';
import PeriodDropdown from '~/modules/common/components/PeriodDropdown';
import { ProfitIcon } from '~/modules/common/components/Icons';
import ChartTitleWithPeriodSelector from '../ChartTitleWithPeriodSelector';
import ProfitabilityChart from './ProfitabilityChart';
import ProfitabilityDrilldown from './ProfitabilityDrilldown';

const chartAvatar = <ProfitIcon />;

const useHeaderStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary
  },
  root: {
    padding: theme.spacing(1.5, 1.75)
  },
  content: {
    [theme.breakpoints.up('md')]: {
      flex: '0 0 auto'
    }
  },
  action: {
    margin: 0,
    flex: '1 1 auto',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end'
  },
  avatar: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
}));

const useContentStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&:last-child': {
      paddingBottom: ({ selectedPeriod }) =>
        selectedPeriod ? 0 : theme.spacing(1.5)
    }
  }
}));

export const getChartPeriodCount = breakPoint => {
  switch (breakPoint) {
    case 'xl':
      return 12;
    case 'lg':
      return 10;
    case 'md':
      return 8;
    default:
      return 3;
  }
};

export const ProfitabilityCard = ({
  intl,
  filter,
  fixedRange,
  loading,
  data,
  currencySymbol,
  useProfitabilityListData,
  onRowClick,
  title,
  titleId = '',
  noItemsMessageId,
  drillDownTableTitle,
  dataQeId
}) => {
  const cardHeaderClasses = useHeaderStyles();

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const cardContentClasses = useContentStyles({ selectedPeriod });

  const {
    chartDates,
    onPrevious,
    onNext,
    scale,
    setScale,
    dateRange
  } = fixedRange;

  useEffect(() => {
    setSelectedPeriod(null);
  }, [scale]);

  useEffect(() => {
    selectedPeriod &&
      !data.some(p => p.key === selectedPeriod.key) &&
      setSelectedPeriod(null);
  }, [selectedPeriod, setSelectedPeriod, data]);

  const noItemsMessage = intl.formatMessage(
    {
      id: noItemsMessageId
    },
    {
      period: selectedPeriod ? selectedPeriod.label : null
    }
  );

  return (
    <Card>
      <CardHeader
        classes={cardHeaderClasses}
        avatar={chartAvatar}
        title={
          <ChartTitleWithPeriodSelector
            title={title}
            titleId={titleId}
            dataQeId={dataQeId}
            dateRange={dateRange}
            onPreviousClick={onPrevious}
            onNextClick={onNext}
          />
        }
        action={<PeriodDropdown value={scale} onChange={setScale} />}
      />
      <Divider />
      <CardContent classes={cardContentClasses}>
        <Collapse in={!!selectedPeriod} timeout="auto" unmountOnExit>
          <ProfitabilityDrilldown
            chartDates={chartDates}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            filter={filter}
            useProfitabilityListData={useProfitabilityListData}
            label={drillDownTableTitle}
            onRowClick={onRowClick}
            noItemsMessage={noItemsMessage}
          />
        </Collapse>
        <Collapse in={!selectedPeriod} timeout="auto" unmountOnExit>
          <ProfitabilityChart
            titleId={titleId}
            loading={loading}
            data={data}
            currencySymbol={currencySymbol}
            chartDates={chartDates}
            setSelectedPeriod={setSelectedPeriod}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
};

ProfitabilityCard.propTypes = {
  intl: PropTypes.object.isRequired,
  filter: PropTypes.object,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  titleId: PropTypes.string,
  fixedRange: PropTypes.shape({
    chartDates: PropTypes.array.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    scale: PropTypes.string.isRequired,
    setScale: PropTypes.func.isRequired,
    dateRange: PropTypes.object.isRequired
  }),
  currencySymbol: PropTypes.string,
  data: PropTypes.array.isRequired,
  useProfitabilityListData: PropTypes.func.isRequired,
  noItemsMessageId: PropTypes.string.isRequired,
  drillDownTableTitle: PropTypes.string.isRequired,
  dataQeId: PropTypes.string
};

export default injectIntl(ProfitabilityCard);
