import {
  ProjectFormatter,
  AmountFormatter,
  UserFormatter,
  TaskFormatter,
  StringFormatter,
  DateRangeFormatter,
  AdhocDescriptionFormatter,
  ExpenseCodeFormatter
} from '~/modules/billing-invoicing/common/columnFormatters';
import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';
import DeleteLineItem from './DeleteLineItem';
import { QuantityFormatter } from './QuantityFormatter';

const getFormatters = editable => ({
  deleteIcon: DeleteLineItem,
  project: ProjectFormatter,
  amount: editable ? AmountFormatter : MoneyFormatter,
  lineItemsTotal: MoneyFormatter,
  task: TaskFormatter,
  user: UserFormatter,
  billingType: StringFormatter,
  billingContractScriptName: StringFormatter,
  quantity: QuantityFormatter,
  rate: MoneyFormatter,
  description: AdhocDescriptionFormatter,
  timesheetPeriod: DateRangeFormatter,
  payCode: StringFormatter,
  role: StringFormatter,
  expenseCode: ExpenseCodeFormatter
});

export default getFormatters;
