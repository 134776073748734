export const mapEnabledFieldsToVariables = (enabledFields, columnMap) => {
  const fields = new Set(enabledFields);
  const keys = Object.keys(columnMap);
  const cols = Object.values(columnMap);

  const variables = cols.reduce(
    (o, c, i) => ({
      ...o,
      [keys[i]]: Boolean(c && fields.has(c.field))
    }),
    {}
  );

  return variables;
};
