import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '~/modules/common/components';
import SyncBillRecalculateBatch from './SyncBillRecalculateBatch';

const useStyles = makeStyles(theme => ({
  description: {
    whiteSpace: 'pre-line'
  }
}));

export const SyncConfirmationDialog = ({
  open,
  onSync,
  onCancel,
  isSyncing,
  recalculationBatchState,
  setRecalculationBatchState,
  onBatchComplete
}) => {
  const classes = useStyles();
  const billStatusUpdateRecalculate = useMemo(
    () => recalculationBatchState.batchInProgress,
    [recalculationBatchState.batchInProgress]
  );

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage id="billingTransactionList.syncInvoice" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.description}>
          <FormattedMessage id="billingTransactionList.issueSyncDialogDescription" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        {billStatusUpdateRecalculate ? (
          <SyncBillRecalculateBatch
            batchState={recalculationBatchState}
            setBatchState={setRecalculationBatchState}
            onBatchComplete={onBatchComplete}
          />
        ) : (
          <LoadingButton
            color="primary"
            onClick={onSync}
            isLoading={isSyncing}
            disabled={isSyncing}
            autoFocus
          >
            <FormattedMessage id="billingTransactionList.syncDialogConfirmButton" />
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
SyncConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onSync: PropTypes.func,
  onCancel: PropTypes.func,
  isSyncing: PropTypes.bool,
  setRecalculationBatchState: PropTypes.func,
  recalculationBatchState: PropTypes.object,
  onBatchComplete: PropTypes.func
};

export default SyncConfirmationDialog;
