import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useThemeBreakpoint = theme => {
  let breakpoint = 'xl';

  if (useMediaQuery(theme.breakpoints.only('xs'))) breakpoint = 'xs';
  if (useMediaQuery(theme.breakpoints.only('sm'))) breakpoint = 'sm';
  if (useMediaQuery(theme.breakpoints.only('md'))) breakpoint = 'md';
  if (useMediaQuery(theme.breakpoints.only('lg'))) breakpoint = 'lg';

  return breakpoint;
};

export default useThemeBreakpoint;
