import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'formik';
import { DialogActions } from '@material-ui/core';
import { CancelButton, LoadingButton } from '~/modules/common/components';
import { hasErrors } from '~/util';

export const EditProjectDialogActions = ({
  onClose,
  formik: { handleSubmit, handleReset, errors, isSubmitting }
}) => {
  const onCancelClick = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  const formError = hasErrors(errors);

  return (
    <DialogActions>
      <CancelButton
        data-qe-id="EditCard_Cancel"
        onClick={onCancelClick}
        disabled={isSubmitting}
      />
      <LoadingButton
        color="primary"
        onClick={handleSubmit}
        disabled={formError}
        isLoading={isSubmitting}
        data-qe-id="EditCard_Save"
      >
        <FormattedMessage id="button.save" />
      </LoadingButton>
    </DialogActions>
  );
};

EditProjectDialogActions.propTypes = {
  onClose: PropTypes.func,
  formik: PropTypes.object
};

export default connect(EditProjectDialogActions);
