import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { TagIcon } from '~/modules/common/components/Icons';
import TagKeyDropdown from '~/modules/tags/components/Dropdowns/TagKeyDropdown';
import TagList from '~/modules/tags/components/TagList';
import useIsTagOptionDisabled from '~/modules/tags/hooks/useIsTagOptionDisabled';

const useStyles = makeStyles(theme => ({
  tagGridItem: {
    minWidth: 216,
    paddingTop: 20
  },
  tagSelect: {
    paddingTop: 16
  },
  tagTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  tagIcon: {
    marginBottom: '-6px',
    marginRight: 6
  }
}));

const TagColumnGroup = ({
  onTagKeySelection,
  selectedColumns,
  onRemoveTag,
  tagsObjectBinding
}) => {
  const classes = useStyles();

  const selectedTagIds = selectedColumns.filter(c =>
    /^urn:replicon-tenant:[^:]+:object-extension-tag-definition:.*$/.exec(c)
  );

  const isTagKeyDisabled = useIsTagOptionDisabled(selectedTagIds);

  return (
    <Grid className={classes.tagGridItem}>
      <Typography className={classes.tagTitle}>
        <TagIcon className={classes.tagIcon} />
        <FormattedMessage id="tags.title" />
      </Typography>
      <TagKeyDropdown
        className={classes.tagSelect}
        variant="filled"
        dataQeId="AddTagDropdown"
        onChange={onTagKeySelection}
        isOptionDisabled={isTagKeyDisabled}
        editable
        objectBinding={tagsObjectBinding}
      />
      <TagList
        className={classes.tagList}
        tags={selectedTagIds}
        onRemoveTag={onRemoveTag}
      />
    </Grid>
  );
};

TagColumnGroup.propTypes = {
  onTagKeySelection: PropTypes.func,
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  onRemoveTag: PropTypes.func,
  tagsObjectBinding: PropTypes.string.isRequired
};

export default TagColumnGroup;
