import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export const ReadOnlyCardContentLoading = () => (
  <>
    <Skeleton width="70%" height={25} />
    <Skeleton width="70%" height={25} />
    <Skeleton width="70%" height={25} />
  </>
);

export default ReadOnlyCardContentLoading;
