import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DivisionIcon = React.forwardRef((props, ref) => (
  <SvgIcon data-qe-id="DivisionIcon" {...props} ref={ref}>
    <>
      <path d="m 3,4 v 6 H 9 V 8 h 2 v 10 h 4 v 2 h 6 v -6 h -6 v 2 H 13 V 8 h 2 v 2 h 6 V 4 H 15 V 6 H 9 V 4 Z" />
    </>
  </SvgIcon>
));

export default DivisionIcon;
