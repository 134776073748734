import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddSharp';

const useStyles = makeStyles(theme => ({
  icon: {
    padding: theme.spacing(1),
    minWidth: 42
  }
}));

const primaryTypographyProps = {
  color: 'primary'
};

const CreatableOption = ({
  classes,
  inputValue,
  label,
  onCreateOption,
  innerProps
}) => {
  const cls = useStyles({ classes });
  const newOptionName = inputValue ? `"${inputValue}"` : '';
  const text = `${label} ${newOptionName}`;

  const handleClick = useCallback(
    () => onCreateOption && onCreateOption(inputValue),
    [onCreateOption, inputValue]
  );

  return (
    <MenuItem disableGutters onClick={handleClick} {...innerProps}>
      <ListItemIcon className={cls.icon}>
        <AddIcon color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={primaryTypographyProps}
      />
    </MenuItem>
  );
};

CreatableOption.propTypes = {
  classes: PropTypes.object,
  inputValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  onCreateOption: PropTypes.func,
  innerProps: PropTypes.object
};

export default CreatableOption;
