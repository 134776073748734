import { useCallback } from 'react';
import { debounce, scope } from '~/modules/common/debounce';
import {
  getTransitionUrl,
  formatPutBillInput
} from '~/modules/billing-invoicing/common/util';
import {
  getValidationErrorByUri,
  getValidationErrorByDisplayText
} from '~/modules/common/graphql/errors';

const calculationStatusScope = scope();

export const setDisplayIdError = ({
  error,
  setFieldError,
  intl,
  setCreateAndView,
  setSaving
}) => {
  setCreateAndView(false);
  setSaving(false);
  const displayIdError =
    getValidationErrorByUri(
      error,
      'urn:replicon:validation-failure:invoice-number-is-used-by-another-invoice'
    ) ||
    getValidationErrorByDisplayText(
      error,
      'The specified Invoice already exists.'
    );

  if (displayIdError) {
    setFieldError('displayId', 'duplicateReferenceNumber');
  }
};

export const useAddLineItemsHooks = ({
  createBill,
  validateForm,
  setFieldError,
  setSaving,
  setCreateAndView,
  billId,
  setSummarizeColumOptions,
  createInvoiceItemsFromBillingItemsBatch,
  createInvoiceFromBillItemsBatch,
  fetchCalculationStatus,
  fetchBatchResults,
  columns,
  values,
  billingItemSearch,
  selectedBillingItems,
  setLineItemAdding,
  onClose,
  refetchAvailableToBillRows,
  refetchAvailableToBillTotal,
  refetchFilteredTotals,
  history,
  params,
  intl,
  putBill,
  billDetails,
  isPsaPrpBillingDefaultForProjectEnabled
}) => {
  const onAddLineItems = useCallback(
    async viewBill => {
      if (createBill) {
        const errors = await validateForm();

        if (Object.keys(errors).length) return;
        const {
          displayId,
          description,
          billingAddress,
          issueDate,
          paymentTerms,
          poNumber,
          period,
          dueDate,
          client: { id: clientUri },
          billCurrency: { id: currencyUri },
          invoiceTemplate,
          notesForCustomer,
          internalNotes,
          showComments,
          taxProfile
        } = values;

        if (viewBill) {
          setCreateAndView(true);
        } else setSaving(true);

        try {
          await createInvoiceFromBillItemsBatch({
            billInput: {
              id: null,
              displayId,
              billingAddress,
              description,
              issueDate,
              paymentTerms,
              poNumber,
              period,
              dueDate,
              clientUri,
              currencyUri,
              ...(isPsaPrpBillingDefaultForProjectEnabled
                ? { taxProfileUri: taxProfile && taxProfile.id }
                : {}),
              invoiceTemplateUri: invoiceTemplate && invoiceTemplate.id,
              lineItems: [],
              summarizeColumn: columns,
              notesForCustomer,
              internalNotes,
              showComments
            },
            billingItemColumnOption: columns,
            billingItemSearch,
            selectedBillingItems
          });
        } catch (error) {
          setDisplayIdError({
            error,
            setFieldError,
            intl,
            setCreateAndView,
            setSaving
          });
        }
      } else {
        setLineItemAdding(true);
        setSummarizeColumOptions(columns);
        await createInvoiceItemsFromBillingItemsBatch({
          billId,
          billingItemColumnOption: columns,
          billingItemSearch,
          selectedBillingItems
        });
        const formattedPutBillInput = formatPutBillInput(
          billDetails,
          isPsaPrpBillingDefaultForProjectEnabled
        );

        formattedPutBillInput.summarizeColumn = columns;

        await putBill(formattedPutBillInput);
        setLineItemAdding(false);
        onClose();
      }
    },
    [
      createBill,
      validateForm,
      values,
      setSaving,
      setCreateAndView,
      createInvoiceFromBillItemsBatch,
      isPsaPrpBillingDefaultForProjectEnabled,
      columns,
      billingItemSearch,
      selectedBillingItems,
      setFieldError,
      intl,
      setLineItemAdding,
      setSummarizeColumOptions,
      createInvoiceItemsFromBillingItemsBatch,
      billId,
      billDetails,
      putBill,
      onClose
    ]
  );

  const onCalculationComplete = useCallback(
    async ({ bill, viewBill }) => {
      const {
        data: { isBillDataReflectedInAvailableToBillData: status } = {}
      } = bill ? await fetchCalculationStatus({ billId: bill }) : {};

      if (status || !bill) {
        if (refetchAvailableToBillRows) await refetchAvailableToBillRows();
        if (refetchAvailableToBillTotal) await refetchAvailableToBillTotal();
        if (refetchFilteredTotals) await refetchFilteredTotals();
        setSaving(false);
        setCreateAndView(false);
        onClose();
        if (viewBill) {
          const record = { id: bill, type: 'BILL' };
          const path = history.location.pathname;
          const transitionUrl = getTransitionUrl({
            path,
            record,
            slug: params.slug
          });

          history.push(transitionUrl);
        }
      } else {
        await debounce(
          calculationStatusScope,
          async () => {
            await onCalculationComplete({ bill, viewBill });
          },
          500
        );
      }
    },
    [
      onClose,
      refetchAvailableToBillRows,
      refetchAvailableToBillTotal,
      refetchFilteredTotals,
      fetchCalculationStatus,
      setSaving,
      setCreateAndView,
      history,
      params
    ]
  );

  const onBatchComplete = useCallback(
    async ({ batchId = {} }) => {
      if (createBill && refetchAvailableToBillRows) {
        const result = await fetchBatchResults({ batchId });

        const {
          data: {
            getCreateBillFromBillingItemsBatchResults: { billId: bill } = {}
          } = {}
        } = result;

        await onCalculationComplete({ bill, viewBill: false });
      }
    },
    [
      createBill,
      refetchAvailableToBillRows,
      fetchBatchResults,
      onCalculationComplete
    ]
  );

  const onBatchCompleteViewBill = useCallback(
    async ({ batchId = {} }) => {
      if (createBill && refetchAvailableToBillRows) {
        const result = await fetchBatchResults({ batchId });

        const {
          data: {
            getCreateBillFromBillingItemsBatchResults: { billId: bill } = {}
          } = {}
        } = result;

        await onCalculationComplete({ bill, viewBill: true });
      }
    },
    [
      createBill,
      refetchAvailableToBillRows,
      fetchBatchResults,
      onCalculationComplete
    ]
  );

  return {
    onAddLineItems,
    onBatchComplete,
    onBatchCompleteViewBill
  };
};

export default useAddLineItemsHooks;
