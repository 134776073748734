import { useMeContext } from '~/modules/me';

export default ({ showRevenue }) => {
  const {
    hasViewProjectCostType,
    isRolledUpTaskEstimateCalculationMethodEnabled
  } = useMeContext();

  return {
    showMargin: showRevenue && isRolledUpTaskEstimateCalculationMethodEnabled,
    showHours:
      hasViewProjectCostType ||
      (showRevenue && !isRolledUpTaskEstimateCalculationMethodEnabled)
  };
};
