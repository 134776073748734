import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseSharp';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';

const SearchDetails = ({
  classes,
  children,
  isOpen,
  close,
  searchDialogCloseLabel,
  showSearchFacetsInFullScreen = true
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'), {
    noSsr: true
  });

  if (isMobile && showSearchFacetsInFullScreen) {
    return (
      <Dialog open={isOpen} fullScreen>
        <DialogTitle>
          <IconButton
            data-qe-id="Search_Details_Close_Button"
            color="inherit"
            onClick={close}
            aria-label={searchDialogCloseLabel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    );
  }

  return (
    <Paper
      id="search-menu"
      aria-label={formatMessage({ id: 'search.detailsAriaLabel' })}
      role="dialog"
      className={classes.menu}
    >
      {children}
    </Paper>
  );
};

SearchDetails.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  searchDialogCloseLabel: PropTypes.string,
  showSearchFacetsInFullScreen: PropTypes.bool
};

export default SearchDetails;
