import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import flow from 'lodash/fp/flow';
import omit from 'lodash/fp/omit';
import update from 'lodash/fp/update';
import { toRepliconDate } from '~/modules/common/dates/convert';

export const UPDATE_BILLING_RATES = gql`
  mutation updateProjectBillingRates(
    $projectId: String!
    $billingRatesInput: ProjectBillingRatesInput
  ) {
    updateProjectBillingRates(
      projectId: $projectId
      billingRatesInput: $billingRatesInput
    ) {
      id
    }
  }
`;
const omitForbiddenFields = flow(
  omit('__typename'),
  update('currency', flow(omit('__typename')))
);

export const mapToBillingRatesInput = (billingRates, rateType) =>
  billingRates.map(br => ({
    id: br.id,
    billingRate: {
      id: br.billingRate.id,
      type: rateType,
      billingScheduleEntries: br.billingRate.billingScheduleEntries.map(
        brse => ({
          effectiveDate: toRepliconDate(brse.effectiveDate),
          rate: omitForbiddenFields(brse.rate)
        })
      )
    }
  }));

export const useUpdateBillingRates = ({
  projectId,
  rateType,
  refetchQueries
}) => {
  const [updateProjectBillingRates] = useMutation(UPDATE_BILLING_RATES);

  return {
    updateBillingRates: ({
      dirtyBillingRates,
      removedBillingRates,
      newAddedBillingRates
    }) => {
      return updateProjectBillingRates({
        variables: {
          projectId,
          billingRatesInput: {
            dirtyBillingRates: mapToBillingRatesInput(
              dirtyBillingRates,
              rateType
            ),
            removedBillingRates: mapToBillingRatesInput(
              removedBillingRates,
              rateType
            ),
            newAddedBillingRates: mapToBillingRatesInput(
              newAddedBillingRates,
              rateType
            )
          }
        },
        refetchQueries
      });
    }
  };
};
export default useUpdateBillingRates;
