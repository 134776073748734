export const ensureNumber = (value, defaultValue) => {
  const val = Number.parseFloat(value);

  return Number.isNaN(val) ? ensureNumber(defaultValue, 0) : val;
};

export const mapValueToPrecise = defaultPrecision => ({
  value,
  precision
}) => ({
  value,
  precise: value.toFixed(ensureNumber(precision, defaultPrecision))
});

export const usePrecision = ({ value, defaultValue = 0, precision = 2 }) => {
  const precisedValue = ensureNumber(value, defaultValue);
  const { precise } = mapValueToPrecise(precision)({
    value: precisedValue,
    precision
  });

  return {
    preciseVal: Number(precise),
    precisedValue,
    precise
  };
};

export const useDecimalPrecision = ({ value, precision = 2 }) => {
  const { precise } = usePrecision({
    value,
    precision
  });

  return parseFloat(precise);
};
