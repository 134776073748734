import React from 'react';
import { PropTypes } from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from '@material-ui/core';

export const FormSelectField = ({
  label,
  value,
  editable = true,
  fullWidth = true,
  shrink = true,
  variant = 'standard',
  open,
  onClose,
  onOpen,
  children,
  error,
  helperText,
  classes,
  className,
  required,
  dataQeId,
  ...rest
}) => (
  <FormControl
    className={className}
    disabled={!editable}
    fullWidth
    required={required}
    variant={variant}
    error={error}
  >
    {label && (
      <InputLabel htmlFor="form-select-field" shrink={shrink} error={error}>
        {label}
      </InputLabel>
    )}
    <Select
      classes={classes}
      variant={variant}
      open={open}
      onClose={onClose}
      label={label}
      onOpen={onOpen}
      displayEmpty
      value={value || ''}
      error={error}
      data-qe-id={dataQeId && `${dataQeId}_FormSelectField`}
      {...rest}
    >
      {children}
    </Select>
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
);

FormSelectField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  value: PropTypes.any,
  editable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  shrink: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  required: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default FormSelectField;
