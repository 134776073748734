import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SearchBox } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    top: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar - 2,
    backgroundColor: theme.palette.common.white,
    flex: 1
  }
}));

const facets = [];

export const TaskAssignmentSearchBox = ({
  searchCriteria,
  setSearchCriteria
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchBox
        dataQeId="Task-Assignment-SearchBar"
        searchCriteria={searchCriteria}
        facets={facets}
        setSearchCriteria={setSearchCriteria}
      />
    </div>
  );
};

TaskAssignmentSearchBox.propTypes = {
  searchCriteria: PropTypes.object.isRequired,
  setSearchCriteria: PropTypes.func.isRequired
};

export default TaskAssignmentSearchBox;
