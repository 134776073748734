import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { deepPure } from '~/util';
import { generateSequence } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import useRequestPeriodTimelineBlocks from '~/modules/resourcing/common/chart/hooks/useRequestPeriodTimelineBlocks';
import ResourceRequestTimelineBlockReadOnly from './ResourceRequestTimelineBlockReadOnly';

export const useStyles = makeStyles(theme => ({
  timeItem: {
    display: 'flex',
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5)
  },
  periodBlock: {
    backgroundColor: 'transparent'
  },
  toBeHired: {
    backgroundColor: theme.palette.resourceRequest.toBeHired.main,
    height: '100%'
  },
  rejected: {
    backgroundColor: theme.palette.resourceRequest.requestRejected.main,
    height: '100%'
  }
}));

export const ResourceRequestTimelineBlocksReadOnly = ({
  resourceRequest,
  chartDisplayDateRange,
  scale,
  project,
  chartDisplayPeriods
}) => {
  const classes = useStyles();
  const periodBlockClasses = useMemo(
    () => ({
      container: classNames(classes.periodBlock, {
        [classes.toBeHired]:
          resourceRequest.requestStatus === ResourceRequestStatus.Tobehired,
        [classes.rejected]:
          resourceRequest.requestStatus === ResourceRequestStatus.Rejected
      })
    }),
    [
      classes.periodBlock,
      classes.toBeHired,
      classes.rejected,
      resourceRequest.requestStatus
    ]
  );

  const { requestPeriods } = useRequestPeriodTimelineBlocks({
    resourceRequest,
    chartDisplayDateRange,
    chartDisplayPeriods,
    scale,
    project
  });

  return (
    <div className={`${classes.timeItem} resourceRequestUserAllocation`}>
      {generateSequence(resourceRequest.quantity).map((_, rowIndex) =>
        requestPeriods.map(requestPeriod => (
          <ResourceRequestTimelineBlockReadOnly
            key={requestPeriod.key}
            requestPeriod={requestPeriod}
            resourceRequest={resourceRequest}
            rowIndex={rowIndex}
            periodBlockClasses={periodBlockClasses}
          />
        ))
      )}
    </div>
  );
};

ResourceRequestTimelineBlocksReadOnly.propTypes = {
  resourceRequest: PropTypes.object,
  chartDisplayDateRange: PropTypes.object,
  scale: PropTypes.string,
  project: PropTypes.object,
  chartDisplayPeriods: PropTypes.array
};

export default deepPure(ResourceRequestTimelineBlocksReadOnly);
