import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_USER_SCHEDULE_DETAILS_QUERY = gql`
  query GetScheduleDetailsForResourceUser(
    $filter: GetResourceUsersFilter!
    $dateRange: DateRangeInput
  ) {
    resourceUsers2(filter: $filter) {
      id
      scheduleDurationByDay(dateRange: $dateRange) {
        date
        hours
      }
    }
  }
`;

const useScheduleDetailsForResourceUser = ({
  userId,
  dateRange,
  periodResolution,
  skip = false,
  includeDisabled = true
}) => {
  const { data, loading } = useQuery(RESOURCE_USER_SCHEDULE_DETAILS_QUERY, {
    variables: {
      filter: { userIds: [userId], includeDisabled },
      dateRange,
      periodResolution
    },
    skip
  });

  return {
    userScheduleDetails: data?.resourceUsers2?.[0] || {},
    isLoading: loading
  };
};

export default useScheduleDetailsForResourceUser;
