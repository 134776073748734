import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_REQUEST_USER_MATCH_QUERY = gql`
  query ResourceRequestUsermatch(
    $resourceRequestId: String!
    $userId: String!
  ) {
    resourceRequestUsermatch(
      resourceRequestId: $resourceRequestId
      userId: $userId
    ) {
      id
      matchScore
      metaData {
        matchingDimensions
        unMatchedDimensions
      }
    }
  }
`;

const useResourceRequestUserMatchScore = ({ resourceRequestId, userId }) => {
  const { loading, error, data } = useQuery(RESOURCE_REQUEST_USER_MATCH_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      resourceRequestId,
      userId
    }
  });

  const resourceRequestUsermatch = data && data.resourceRequestUsermatch;

  return {
    loading,
    error,
    resourceRequestUsermatch: resourceRequestUsermatch && {
      id: resourceRequestUsermatch.id,
      score: resourceRequestUsermatch.matchScore,
      matchingDimensions:
        resourceRequestUsermatch.metaData &&
        resourceRequestUsermatch.metaData.matchingDimensions,
      unMatchedDimensions:
        resourceRequestUsermatch.metaData &&
        resourceRequestUsermatch.metaData.unMatchedDimensions
    }
  };
};

export default useResourceRequestUserMatchScore;
