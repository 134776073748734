import { SELECTED_STATUS } from '~/modules/billing-invoicing/common/components/BillingTransactionTab/enums';

export const getSelectedItemsCount = ({
  allSelectedStatus,
  totalCount,
  excludedItems,
  selectedItems
}) => {
  if (allSelectedStatus === SELECTED_STATUS.ALL) {
    return totalCount - excludedItems.length;
  }

  return selectedItems.length;
};

export const getSyncBillsInputProps = ({
  selectedItems,
  allSelectedStatus,
  excludedItems,
  billingTransactionFilter
}) => {
  if (allSelectedStatus === SELECTED_STATUS.ALL) {
    const transactionTypes =
      billingTransactionFilter && billingTransactionFilter.transactionTypes
        ? billingTransactionFilter.transactionTypes.filter(x => x === 'BILL')
        : ['BILL'];

    const excludedBillIds = excludedItems
      .filter(x => x.type === 'BILL')
      .map(x => x.id);

    return {
      isAllSelected: true,
      includedBillIds: [],
      excludedBillIds,
      filter: billingTransactionFilter
        ? { ...billingTransactionFilter, transactionTypes }
        : { transactionTypes }
    };
  }
  const selectedBillIds = selectedItems
    .filter(x => x.type === 'BILL')
    .map(x => x.id);

  return {
    isAllSelected: false,
    includedBillIds: selectedBillIds,
    excludedBillIds: [],
    filter: {}
  };
};
