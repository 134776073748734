import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DepartmentIcon = React.forwardRef((props, ref) => (
  <SvgIcon data-qe-id="DepartmentIcon" {...props} ref={ref}>
    <>
      <path d="m 9,3 v 6 h 2.001953125 v 2 H 5 l 0.001953125,4 H 3 v 6 H 9 V 15 H 7.001953125 v -2 h 10 v 2 H 15 v 6 h 6 V 15 H 19.001953125 L 19,11 H 13.001953125 V 9 H 15 V 3 Z" />
    </>
  </SvgIcon>
));

export default DepartmentIcon;
