import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FormField, NoValueTextField } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  field: {
    padding: '3px 0px 0px 8px'
  },
  input: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const inputProps = { disableUnderline: true };

export const ReadOnlyNoValue = ({
  classes: overrideClasses,
  className,
  fullWidth,
  label
}) => {
  const classes = useStyles({ classes: overrideClasses });

  return (
    <FormField className={classNames(classes.field, className)}>
      <NoValueTextField
        disabled
        fullWidth={fullWidth}
        label={label}
        InputProps={inputProps}
      />
    </FormField>
  );
};

ReadOnlyNoValue.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default ReadOnlyNoValue;
