import React from 'react';
import { PropTypes } from 'prop-types';
import { ClientIcon } from '~/modules/common/components/Icons';
import LabelledIcon from '../LabelledIcon';

const icon = <ClientIcon size="xsmall" />;

const Client = ({
  classes,
  client: { displayText },
  disablePadding = false,
  dataQeId = 'Client'
}) => (
  <LabelledIcon
    classes={classes}
    icon={icon}
    label={displayText}
    dataQeId={dataQeId}
    disablePadding={disablePadding}
  />
);

Client.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.shape({
    displayText: PropTypes.string
  }).isRequired,
  dataQeId: PropTypes.string,
  disablePadding: PropTypes.bool
};

export default Client;
