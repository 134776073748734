import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const PAGINATED_CLIENTS_DROPDOWN_QUERY = gql`
  query ClientDropdownSearch($clientFilter: ClientFilter2) {
    clients(page: 1, pageSize: 20, clientFilter: $clientFilter) {
      items {
        id
        displayText
      }
    }
  }
`;

export const clientDropdownOptionMapper = clients =>
  clients.items.map(client => ({
    label: client.displayText,
    value: client.id
  }));

const useClientDropdownOptions = searchText => {
  const { loading, data, refetch } = useQuery(
    PAGINATED_CLIENTS_DROPDOWN_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        clientFilter: searchText ? { text: searchText } : {}
      },
      context: {
        debounceKey: 'client-dropdown-key',
        debounceTimeout: 250
      }
    }
  );
  const clients = get(data, 'clients', []);

  return {
    clients: loading ? [] : clientDropdownOptionMapper(clients),
    loading,
    refetchOptions: refetch
  };
};

export default useClientDropdownOptions;
