import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useURLSearchParams, useSessionStorage } from '~/modules/common/hooks';
import { RESOURCE_MANAGEMENT_TAB_ENUM } from './common';

const buildQueryString = ({ view }) => `?view=${view}`;

export const handleTabChange = ({
  history,
  setResourceManagementView,
  setCurrentPageForOverview,
  setCurrentPageForTimeline
}) => (_, newView) => {
  setResourceManagementView(newView);
  setCurrentPageForOverview(1);
  setCurrentPageForTimeline(1);
  const path = {
    pathname: '/resource-pool',
    search: buildQueryString({ view: newView })
  };

  history.replace(path);
};

export const getViewFromQueryOrDefault = ({
  queryParams,
  defaultView,
  isMobile
}) => {
  const view = queryParams.get('view') || defaultView;

  if (view === RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE && isMobile) {
    return RESOURCE_MANAGEMENT_TAB_ENUM.LIST;
  }

  return view;
};

export const useResourceManagementTabProps = isMobile => {
  const queryParams = useURLSearchParams();
  const history = useHistory();
  const [currentPageForTimeline, setCurrentPageForTimeline] = useState(1);
  const [currentPageForOverview, setCurrentPageForOverview] = useState(1);

  const {
    storedValue: resourcePoolView,
    setValue: setResourceManagementView
  } = useSessionStorage(
    'resource-pool-view',
    RESOURCE_MANAGEMENT_TAB_ENUM.OVERVIEW
  );

  const selectedTab = getViewFromQueryOrDefault({
    queryParams,
    defaultView: resourcePoolView,
    isMobile
  });

  const onTabChange = useCallback(
    handleTabChange({
      history,
      setResourceManagementView,
      setCurrentPageForOverview,
      setCurrentPageForTimeline
    }),
    [history]
  );

  return {
    selectedTab,
    onTabChange,
    currentPageForOverview,
    setCurrentPageForOverview,
    currentPageForTimeline,
    setCurrentPageForTimeline
  };
};
