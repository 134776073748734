import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddLineItemsDialog from '~/modules/billing-invoicing/billingItem/AddLineItemsDialog';

export const CreateBillWithLineItemsDialog = props => {
  const [billingBatchState, setBillingBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });

  return (
    <AddLineItemsDialog
      createBill
      setBillingBatchState={setBillingBatchState}
      billingBatchState={billingBatchState}
      {...props}
    />
  );
};

CreateBillWithLineItemsDialog.propTypes = {
  props: PropTypes.object
};

export default CreateBillWithLineItemsDialog;
