import { gql } from 'graphql-tag';

const specificResourceAllocationHolidaysFragment = gql`
  fragment SpecificResourceAllocationHolidays on ResourceAllocationUser {
    holidays(dateRange: $chartDateRange) @include(if: $showHolidays) {
      date
      uri
      name
      duration {
        hours
        minutes
        seconds
      }
      effectiveDuration {
        hours
        minutes
        seconds
      }
    }
  }
`;

export default specificResourceAllocationHolidaysFragment;
