import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OpenInDrawerIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="m 16,3 v 18 h 6 V 3 Z M 9.5,7 8.08984375,8.41015625 10.669921875,11 H 1 v 2 h 9.669921875 L 8.08984375,15.58984375 9.5,17 l 5,-5 z" />
    </>
  </SvgIcon>
));

export default OpenInDrawerIcon;
