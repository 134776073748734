import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import { BILLING_PLAN_SLUG_ENUM } from '~/modules/projects/project/common/enums';
import { usePageOfProjectRate } from '~/modules/rateCard/advancedRateCard/components/hooks/usePageOfProjectRate';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { GET_PORJECT_RATE_TABLE_DIMENSIONS } from '~/modules/rateCard/advancedRateCard/components/hooks';
import { setMilestoneTaskAvailable, setRateAvailable } from '../../helpers';
import setErrorTitle from '../utils';
import { useEffectiveProjectRate } from './useEffectiveProjectRate';

import { useProjectTasksCount } from './useProjectTasksCount';

const showRateCardSetupRequiredError = ({
  isPsaFpAdvancedRateCardEnabled,
  loading,
  projectRates,
  rateAvailable,
  projectRate,
  loadingProjectRate
}) => {
  if (isPsaFpAdvancedRateCardEnabled) {
    return !loading && !projectRates.length;
  }

  return rateAvailable && !projectRate && !loadingProjectRate;
};

const showNoBillingRatesDefinedMessage = ({
  isPsaFpAdvancedRateCardEnabled,
  canViewBillingRates,
  canEditBillingRates,
  loading,
  projectRateCount,
  count,
  loadingProjectRate
}) => {
  if (isPsaFpAdvancedRateCardEnabled) {
    return (
      canViewBillingRates &&
      !canEditBillingRates &&
      !loading &&
      !projectRateCount
    );
  }

  return (
    canViewBillingRates && !canEditBillingRates && !count && !loadingProjectRate
  );
};

export const useBillPlanError = ({
  projectDetails,
  preRequisiteValues,
  billPlanSlug,
  canViewParams,
  canEditParams,
  isExistingBillPlan,
  canViewBillingRates,
  canEditBillingRates
}) => {
  const apolloClient = useApolloClient();
  const isPsaFpAdvancedRateCardEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpAdvancedRateCardEnabled'
  });
  const { projectId, projectSlug } = projectDetails;
  const cache = apolloClient.readQuery({
    query: GET_PORJECT_RATE_TABLE_DIMENSIONS,
    variables: {
      projectId
    }
  });

  const { loading, projectRates, projectRateCount } = usePageOfProjectRate({
    projectId,
    dimensionUris: (cache?.project?.rateTableDimension?.dimensions || []).map(
      d => d.id
    ),
    skip:
      !isPsaFpAdvancedRateCardEnabled ||
      !canViewBillingRates ||
      !(billPlanSlug === BILLING_PLAN_SLUG_ENUM.timeBilling)
  });

  const { tasksCount = 0, loadingTasksCount } = useProjectTasksCount({
    projectSlug,
    billPlanSlug
  });
  const {
    projectRate = null,
    loadingProjectRate,
    roleRatesCount = 0,
    userRates,
    roleRates,
    userRatesCount = 0
  } = useEffectiveProjectRate({
    projectId,
    billPlanSlug,
    skip: isPsaFpAdvancedRateCardEnabled
  });
  const { ratesCount = 0 } = useMemo(
    () => ({
      ratesCount: roleRatesCount + userRatesCount
    }),
    [roleRatesCount, userRatesCount]
  );
  const rates = useMemo(
    () => ({
      userRates: userRates || [],
      roleRates: roleRates || [],
      projectRate: (projectRate && projectRate ? [projectRate] : []) || []
    }),
    [projectRate, roleRates, userRates]
  );

  const { projectRatesCount, roleRatesCounts, resourceRatesCount } = useMemo(
    () => ({
      projectRatesCount: (rates.projectRate || []).length,
      roleRatesCounts: (rates.roleRates || []).length,
      resourceRatesCount: (rates.userRates || []).length
    }),
    [rates.projectRate, rates.roleRates, rates.userRates]
  );

  const { count } = useMemo(
    () => ({
      count: projectRatesCount + roleRatesCounts + resourceRatesCount
    }),
    [projectRatesCount, resourceRatesCount, roleRatesCounts]
  );

  const { formatMessage } = useIntl();

  let errorTitle = '';
  let rateAvailable = false;
  let milestoneTaskAvailable = false;
  let hasViewRatePermission = true;
  let hasViewEditRatePermission = true;

  switch (billPlanSlug) {
    case BILLING_PLAN_SLUG_ENUM.fixedBidMilestone:
      milestoneTaskAvailable = setMilestoneTaskAvailable(preRequisiteValues);

      if (!milestoneTaskAvailable || loadingTasksCount) {
        return {
          errorTitle,
          milestoneTaskAvailable,
          rateAvailable,
          ratesCount
        };
      }
      errorTitle = setErrorTitle({
        canViewParams,
        canEditParams,
        tasksCount,
        isExistingBillPlan,
        formatMessage
      });
      break;
    case BILLING_PLAN_SLUG_ENUM.timeBilling:
      rateAvailable = setRateAvailable(preRequisiteValues);
      if (!canViewBillingRates && !canEditBillingRates) {
        errorTitle = formatMessage({
          id: 'billPlanPreRequisites.noViewEditRatePermission'
        });
        hasViewEditRatePermission = false;

        return {
          errorTitle,
          milestoneTaskAvailable,
          rateAvailable,
          hasViewRatePermission,
          hasViewEditRatePermission
        };
      }

      if (
        showNoBillingRatesDefinedMessage({
          isPsaFpAdvancedRateCardEnabled,
          canViewBillingRates,
          canEditBillingRates,
          loading,
          projectRateCount,
          count,
          loadingProjectRate
        })
      ) {
        errorTitle = formatMessage({
          id: 'billPlanPreRequisites.noBillingRatesDefinedMessage'
        });
        hasViewRatePermission = false;

        return {
          errorTitle,
          milestoneTaskAvailable,
          rateAvailable,
          hasViewRatePermission,
          hasViewEditRatePermission
        };
      }

      errorTitle = showRateCardSetupRequiredError({
        isPsaFpAdvancedRateCardEnabled,
        loading,
        projectRates,
        rateAvailable,
        projectRate,
        loadingProjectRate
      })
        ? formatMessage({
            id: 'billPlanPreRequisites.rateCardSetupRequired'
          })
        : '';
      break;
    default:
      break;
  }

  return {
    errorTitle,
    milestoneTaskAvailable,
    rateAvailable,
    ratesCount: isPsaFpAdvancedRateCardEnabled ? projectRateCount : ratesCount,
    rates: isPsaFpAdvancedRateCardEnabled ? projectRates : rates,
    hasViewRatePermission,
    hasViewEditRatePermission
  };
};

export default useBillPlanError;
