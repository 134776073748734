import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

export const TagFieldColumn = ({ field, record: { tags = [] } }) => {
  const tagItem =
    (tags && tags.find(x => x && x.tag && x.tag.id === field)) || {};

  return (
    <>
      {tagItem && tagItem.value ? (
        <Typography align="left" variant="body2">
          <FormattedMessage
            id={`billingTransactionList.${tagItem.value.value}`}
            defaultMessage={tagItem.value.value}
          />
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};

TagFieldColumn.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string
};

export default TagFieldColumn;
