import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { RequestedCostIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';
import { RequestedCostTooltipContent } from './RequestedCostTooltipContent';

export const ResourceRequestRequestedCostField = ({
  currency,
  roleRate,
  totalHours,
  tooltipClasses
}) => {
  const classes = useStyles();

  const moneyObject = {
    currency,
    amount: roleRate
  };

  const totalCost = {
    amount: roleRate * totalHours,
    currency
  };

  const title = useMemo(
    () => (
      <RequestedCostTooltipContent
        classes={classes}
        moneyObject={moneyObject}
        totalCost={totalCost}
      />
    ),
    [classes, moneyObject, totalCost]
  );

  return (
    <Tooltip title={title} classes={tooltipClasses}>
      <RequestedCostIcon className={classes.detailsIndicator} />
    </Tooltip>
  );
};

ResourceRequestRequestedCostField.propTypes = {
  currency: PropTypes.object.isRequired,
  roleRate: PropTypes.number.isRequired,
  totalHours: PropTypes.number.isRequired,
  tooltipClasses: PropTypes.object
};

export default ResourceRequestRequestedCostField;
