import { object, string, mixed } from 'yup';

export const buildValidationSchema = ({ intl, isClientRequired }) =>
  object().shape({
    client: isClientRequired
      ? object().shape({
          id: string()
            .trim()
            .required(intl.formatMessage({ id: 'addProject.clientIsRequired' }))
        })
      : mixed()
  });
