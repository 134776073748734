import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  rightPadding: {
    paddingRight: theme.spacing(1)
  }
}));

export const GridItem = ({ classes, fullWidth, rightPadding, children }) => {
  const isMobile = useIsBreakpointDown('sm');
  const gridClasses = useStyles({ classes });

  const size = fullWidth || isMobile ? 12 : 6;

  const className = classNames(
    gridClasses.root,
    rightPadding && !isMobile && gridClasses.rightPadding
  );

  return (
    <Grid className={className} item xs={size} sm={size}>
      {children}
    </Grid>
  );
};

GridItem.propTypes = {
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  rightPadding: PropTypes.bool,
  children: PropTypes.node
};

export default GridItem;
