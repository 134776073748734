import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  detailsIndicator: {
    fontSize: 18,
    color: theme.palette.text.hint
  },
  detailsIndicatorTooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.caption.fontSize
  },
  detailsIndicatorTooltipContent: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    margin: theme.spacing(0.25, 0, 0, 0)
  },
  noBulletList: {
    listStyle: 'none',
    padding: 0,
    margin: theme.spacing(0.25, 0, 0, 0.5)
  }
}));

export const useTooltipStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: theme.direction === 'rtl' && {
    popper: {
      right: 'unset !important'
    },
    tooltip: {
      width: theme.spacing(20)
    }
  }
}));

export default useStyles;
