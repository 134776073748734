import deepEqual from 'fast-deep-equal';
import calcScrollBarWidth from 'scrollbar-width';

const useResourceGridWidthHeightExcludePropCheckHack = (
  prevProps,
  nextProps
) => {
  const { width: prevWidth, height: prevHeight, ...prevRestProps } = prevProps;
  const { width: nextWidth, height: nextHeight, ...nextRestProps } = nextProps;

  // prevent re-rendering when vertical & horizontal scroll bar appears and reduces the width
  const widthChanged =
    Math.abs(Math.floor(nextWidth) - Math.floor(prevWidth)) <=
    (calcScrollBarWidth() || 0);

  const heightChanged =
    Math.abs(Math.floor(nextHeight) - Math.floor(prevHeight)) <=
    (calcScrollBarWidth() || 0);

  return (
    deepEqual(prevRestProps, nextRestProps) && (heightChanged || widthChanged)
  );
};

export default useResourceGridWidthHeightExcludePropCheckHack;
