import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { FilledInput } from '@material-ui/core';
import { CONTACT_TYPE } from '~/modules/common/enums';
import { useMeContext } from '~/modules/me';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { ContactType } from '~/types';

const useDropDownStyles = makeStyles(theme => ({
  select: {
    paddingTop: `${theme.spacing(1.5)}px !important`,
    paddingBottom: `${theme.spacing(1.5)}px !important`,
    [theme.breakpoints.down('sm')]: {
      paddingTop: `${theme.spacing(2.5)}px !important`
    }
  }
}));

export const ContactTypeDropdown = ({
  record,
  field,
  index,
  column: { contactHandlers }
}) => {
  const me = useMeContext();
  const { hasViewProjectBillingOptions } = me;
  const intl = useIntl();
  const {
    values: { contacts }
  } = useFormikContext();

  const isMobile = useIsBreakpointDown('sm');

  const dropDownClasses = useDropDownStyles();

  const selectProps = useMemo(() => {
    return {
      input: <FilledInput />,
      classes: dropDownClasses
    };
  }, [dropDownClasses]);

  const options = useMemo(() => {
    const baseOptions = [
      {
        id: ContactType.Others,
        displayText: intl.formatMessage({
          id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE.OTHERS}`
        })
      },
      {
        id: ContactType.Primary,
        displayText: intl.formatMessage({
          id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE.PRIMARY}`
        })
      }
    ];

    if (hasViewProjectBillingOptions) {
      baseOptions.unshift({
        id: ContactType.Billing,
        displayText: intl.formatMessage({
          id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${CONTACT_TYPE.BILLING}`
        })
      });
    }

    return baseOptions;
  }, [intl, hasViewProjectBillingOptions]);

  const selectedValue = useMemo(() => {
    return options.find(x => x.id === CONTACT_TYPE[record[field]]);
  }, [field, options, record]);

  const excludedContactTypes = new Set(
    contacts
      .filter((_, i) => i !== index)
      .map(contact => CONTACT_TYPE[contact.contactType])
  );

  const handleChange = useCallback(
    value => {
      contactHandlers.onContactChange({
        index,
        field,
        value: value.id
      });
    },
    [contactHandlers, index, field]
  );

  return (
    <SimpleDropdown
      dataQeId={`${field}`}
      fullWidth
      variant="filled"
      label={
        isMobile &&
        intl.formatMessage({
          id: 'projectBasicInfoCard.contactInfo.fields.contactType'
        })
      }
      options={options.filter(option => {
        if (
          option.id === ContactType.Primary ||
          option.id === ContactType.Billing
        ) {
          return !excludedContactTypes.has(option.id);
        }

        return true;
      })}
      onChange={handleChange}
      value={selectedValue}
      noneOption={false}
      selectProps={selectProps}
      disableSort
    />
  );
};

ContactTypeDropdown.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string,
  index: PropTypes.number,
  column: PropTypes.object
};

export default ContactTypeDropdown;
