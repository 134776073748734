import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { fileIconMap } from './fileIconMap';

export const FileIcon = ({ type, ...svgProps }) => {
  const path = fileIconMap[type.toLowerCase()];

  return (
    <SvgIcon {...svgProps}>
      <path d={path} />
    </SvgIcon>
  );
};

FileIcon.propTypes = {
  type: PropTypes.string
};

export default FileIcon;
