import { gql } from 'graphql-tag';
import {
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment,
  specificResourceAllocationTimeOffFragment,
  specificResourceAllocationHolidaysFragment,
  allocationTotalsFragment,
  resourceRequestTotalsFragment
} from '~/modules/resourcing/common/fragments';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import {
  ResourceAllocationStatus,
  ResourceRequestStatus,
  SpecificResourceRequestFragmentDoc
} from '~/types';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY, {
  RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
} from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import { invokeUpdateResourceAllocationAggregateCache } from '~/modules/resourcing/enhancers/updateAllocationTotalsCache';
import { getTotalHoursAndCostFromAllocations } from '~/modules/resourcing/util/getTotalHoursAndCostFromAllocations';
import { getCostAndHoursDelta } from '~/modules/resourcing/util/getCostAndHoursDelta';
import { invokeUpdateResourceRequestAggregateCache } from '~/modules/resourcing/enhancers/updateResourceRequestTotalsCache';

export const ALLOCATIONS_QUERY = gql`
  query Eager_GetAllocations(
    $projectUri: String
    $allocationStatusList: [ResourceAllocationStatus]
    $limit: Int
    $cursor: String
    $sort: ResourceAllocationSort
    $showTimeOff: Boolean!
    $showHolidays: Boolean!
    $filter: ResourceAllocationFilter
    $chartDateRange: DateRangeInput
  ) {
    resourceAllocations(
      projectUri: $projectUri
      limit: $limit
      cursor: $cursor
      allocationStatusList: $allocationStatusList
      sort: $sort
      filter: $filter
    ) {
      resourceAllocations {
        ...SpecificResourceAllocation
        role {
          uri
          id
          displayText
        }
        user {
          ...SpecificResourceAllocationUser
          ...SpecificResourceAllocationTimeOff
          ...SpecificResourceAllocationHolidays
        }
        ...AllocationTotalsFragment
      }
      nextPageCursor
    }
  }
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
  ${specificResourceAllocationTimeOffFragment}
  ${specificResourceAllocationHolidaysFragment}
  ${allocationTotalsFragment}
`;

export const tryLoadCachedAllocationQuery = ({ proxy, variables }) => {
  try {
    return proxy.readQuery({
      query: ALLOCATIONS_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

export const RELEASE_RESOURCE_ALLOCATIONS = gql`
  mutation releaseResourceAllocations(
    $input: BulkReleaseResourceAllocationsInput!
    $showTimeOff: Boolean!
    $showHolidays: Boolean!
    $chartDateRange: DateRangeInput
  ) {
    bulkReleaseResourceAllocations(input: $input) {
      resourceRequests {
        ...SpecificResourceRequest
        ...ResourceRequestTotalsFragment
      }
      resourceAllocations {
        ...SpecificResourceAllocation
        role {
          uri
          id
          displayText
        }
        user {
          ...SpecificResourceAllocationUser
          ...SpecificResourceAllocationTimeOff
          ...SpecificResourceAllocationHolidays
        }
        ...AllocationTotalsFragment
      }
    }
  }
  ${SpecificResourceRequestFragmentDoc}
  ${resourceRequestTotalsFragment}
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
  ${specificResourceAllocationTimeOffFragment}
  ${specificResourceAllocationHolidaysFragment}
  ${allocationTotalsFragment}
`;

export const updateCache = ({
  projectId,
  resourceRequestId,
  limit = 200,
  showTimeOff,
  chartDateRange,
  showHolidays,
  resourceRequestsQueryVariables,
  initialTotalAllocationsCostAndHours,
  filter
}) => (proxy, mutationResponse) => {
  const {
    data: {
      bulkReleaseResourceAllocations: { resourceAllocations, resourceRequests }
    }
  } = mutationResponse;

  const variables = {
    limit,
    projectUri: projectId,
    allocationStatusList: [ResourceAllocationStatus.Committed],
    showTimeOff,
    chartDateRange: chartDateRange
      ? {
          startDate: dateToMidnightUTCString(chartDateRange.startDate),
          endDate: dateToMidnightUTCString(chartDateRange.endDate)
        }
      : null,
    showHolidays,
    filter: resourceRequestsQueryVariables &&
      resourceRequestsQueryVariables.filter && {
        searchText: resourceRequestsQueryVariables.filter.text,
        users: resourceRequestsQueryVariables.filter.users,
        roles: resourceRequestsQueryVariables.filter.roles
      },
    sort: resourceRequestsQueryVariables && resourceRequestsQueryVariables.sort
  };

  if (initialTotalAllocationsCostAndHours) {
    const isReleasedEntirely = (resourceAllocations || []).length === 0;
    const afterReleaseTotalAllocationsCostAndHours = isReleasedEntirely
      ? {
          totalHours: 0,
          totalCost: { currency: null, amount: 0 }
        }
      : getTotalHoursAndCostFromAllocations({
          allocations: resourceAllocations
        });
    const allocationCostAndHoursDelta = getCostAndHoursDelta({
      newTotalCost: afterReleaseTotalAllocationsCostAndHours.totalCost,
      newHours: afterReleaseTotalAllocationsCostAndHours.totalHours,
      initialTotalCost: initialTotalAllocationsCostAndHours.totalCost,
      initialHours: initialTotalAllocationsCostAndHours.totalHours
    });

    invokeUpdateResourceAllocationAggregateCache({
      proxy,
      projectId,
      filter: filter || {},
      costAndHoursDelta: allocationCostAndHoursDelta
    });

    if (isReleasedEntirely) {
      const requestedCostAndHoursDelta = getCostAndHoursDelta({
        newTotalCost:
          resourceRequests[0].totalRequestedCostByCurrency
            ?.projectBudgetCurrency ||
          resourceRequests[0].totalRequestedCostByCurrency?.baseCurrency,
        newHours: resourceRequests[0].totalHours
      });

      invokeUpdateResourceRequestAggregateCache({
        proxy,
        projectId,
        costAndHoursDelta: requestedCostAndHoursDelta,
        filter: filter || {}
      });
    }
  }

  const results = tryLoadCachedAllocationQuery({
    proxy,
    variables
  });

  if (results) {
    const {
      resourceAllocations: {
        resourceAllocations: cachedAllocations,
        nextPageCursor
      }
    } = results;

    proxy.writeQuery({
      query: ALLOCATIONS_QUERY,
      variables,
      data: {
        resourceAllocations: {
          resourceAllocations: cachedAllocations.filter(alloc =>
            resourceRequestId === alloc.resourceRequestId
              ? resourceAllocations.find(
                  resourceAllocation => resourceAllocation.id === alloc.id
                )
              : alloc
          ),
          nextPageCursor
        }
      }
    });
  }

  const cacheQuery =
    showHolidays || showTimeOff
      ? RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
      : RESOURCE_REQUESTS_ALLOCATIONS_QUERY;

  const cacheVariables = resourceRequestsQueryVariables || {
    projectUri: projectId,
    limit: 500,
    cursor: null
  };

  try {
    const cachedResourceRequests = proxy.readQuery({
      query: cacheQuery,
      variables: cacheVariables
    });

    if (cachedResourceRequests) {
      const { resourceRequests: data } = cachedResourceRequests;

      proxy.writeQuery({
        query: cacheQuery,
        variables: cacheVariables,
        data: {
          resourceRequests: {
            ...data,
            resourceRequests:
              resourceRequests[0] &&
              resourceRequests[0].requestStatus ===
                ResourceRequestStatus.Submitted &&
              !data.resourceRequests.some(req => req.id === resourceRequestId)
                ? [
                    ...data.resourceRequests,
                    { ...resourceRequests[0], resourceAllocations: [] }
                  ]
                : data.resourceRequests.map(req =>
                    req.id === resourceRequestId
                      ? {
                          ...req,
                          resourceAllocations: resourceAllocations.filter(
                            allocation =>
                              allocation.resourceRequestId === resourceRequestId
                          )
                        }
                      : req
                  )
          }
        }
      });
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
