import { makeStyles } from '@material-ui/core/styles';

const useHighlightStyles = makeStyles(theme => ({
  root: ({ value, limit }) =>
    value && limit && value > limit
      ? {
          fontWeight: 'bold',
          color: theme.palette.error.dark
        }
      : {}
}));

export default useHighlightStyles;
