import { gql } from 'graphql-tag';

const clientRatesFragment = gql`
  fragment ClientRates on Client {
    projectRate: projectRate2 {
      id
      billingScheduleEntries {
        effectiveDate: effectiveDate2
        amount {
          currency {
            id
            symbol
            displayText: symbol
          }
          amount
        }
      }
    }
    roleRates: roleRates2 {
      id
      roleReference {
        id
        displayText
      }
      billingScheduleEntries {
        effectiveDate: effectiveDate2
        amount {
          currency {
            id
            symbol
            displayText: symbol
          }
          amount
        }
      }
    }
    userRates: userRates2 {
      id
      userReference {
        id
        displayText
      }
      billingScheduleEntries {
        effectiveDate: effectiveDate2
        amount {
          currency {
            id
            symbol
            displayText: symbol
          }
          amount
        }
      }
    }
  }
`;

export default clientRatesFragment;
