import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeftSharp';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  backButton: {
    margin: theme.spacing(-1.5, 0, -1.5, -0.5),
    paddingRight: theme.spacing(0.5),
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%'
  },
  nextButton: {
    margin: theme.spacing(-1.5, -1.5, -1.5, 0),
    borderTopLeftRadius: '25%',
    borderBottomLeftRadius: '25%',
    paddingLeft: theme.spacing(0.5)
  },
  caption: {
    ...theme.typography.caption,
    flexGrow: 0,
    flexShrink: 1,
    color: theme.palette.text.secondary
  }
}));

export const checkForNavigationButtonState = ({
  currentPage,
  recordCount,
  pageSize
}) => {
  const hasMoreData = recordCount === pageSize;
  const hasOnlyOnePageOfData = currentPage === 1 && !hasMoreData;
  const isEndOfNavigation = currentPage > 1 && !hasMoreData;

  if (hasOnlyOnePageOfData || isEndOfNavigation) return true;

  return false;
};

const DisplayLabel = ({ from, to, count }) => {
  const { formatMessage } = useIntl();

  if (count === -1) {
    return formatMessage(
      { id: 'listTableNavigation.moreProjects' },
      { from, to }
    );
  }

  if (from === 0) {
    return formatMessage({ id: 'listTableNavigation.pageOutOfBound' }, { to });
  }

  return formatMessage(
    { id: 'listTableNavigation.fromToAndCount' },
    { from, to, count }
  );
};

const ListTableNavigation = ({
  classes: classesOverride,
  pageSize,
  recordCount,
  currentPage,
  navigatePage,
  stopPropagation = false
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const recordFrom =
    recordCount === 0
      ? recordCount
      : pageSize * Math.max(0, currentPage - 1) + 1;
  const recordTo =
    recordCount < pageSize
      ? (currentPage - 1) * pageSize + recordCount
      : currentPage * pageSize;

  const handlePreviousClick = useCallback(
    event => {
      navigatePage(currentPage - 1);
      stopPropagation && event.stopPropagation();
    },
    [navigatePage, currentPage, stopPropagation]
  );

  const handleNextClick = useCallback(
    event => {
      navigatePage(currentPage + 1);
      stopPropagation && event.stopPropagation();
    },
    [navigatePage, currentPage, stopPropagation]
  );

  const isEndOfNavigation = checkForNavigationButtonState({
    currentPage,
    pageSize,
    recordCount
  });

  return (
    <nav
      className={classes.root}
      aria-label={formatMessage({ id: 'listTableNavigation.pagination' })}
    >
      <span className={classes.caption}>
        <DisplayLabel
          from={recordFrom}
          to={recordTo}
          count={
            recordCount >= 0 && recordCount === pageSize
              ? -1
              : (currentPage - 1) * pageSize + recordCount
          }
        />
      </span>
      <IconButton
        className={classes.backButton}
        onClick={handlePreviousClick}
        disabled={currentPage === 1}
        aria-label={formatMessage({ id: 'dateButton.previous' })}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        className={classes.nextButton}
        onClick={handleNextClick}
        disabled={isEndOfNavigation}
        aria-label={formatMessage({ id: 'dateButton.next' })}
      >
        <ChevronRightIcon />
      </IconButton>
    </nav>
  );
};

ListTableNavigation.propTypes = {
  classes: PropTypes.object,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  navigatePage: PropTypes.func.isRequired,
  stopPropagation: PropTypes.bool
};

export default ListTableNavigation;
