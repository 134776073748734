import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ListTable,
  ListTableThemeProvider,
  PAGINATION_TYPE,
  ListTableNavigationContext
} from '~/modules/common/components/ListTable';
import { OVERVIEW_UNITS_ENUM } from '~/modules/resource-management/common/enums';
import { ResourceUserAvailabilityGroupType } from '~/types';
import NoAvailabilityRows from '~/modules/resource-management/NoAvailabilityRows';
import { useColumns } from './hooks';
import {
  useListColumnsStyles,
  useListTableThemeProviderStyles,
  useListTableStyles
} from './useStyles';

const DEFAULT_PAGE_SIZE = 20;

export const AvailabilityTable = ({
  groupBy,
  loadingRows,
  loadingMore,
  rows,
  totals,
  loadMoreRows,
  hasMoreRows,
  dateRange,
  periodScale,
  overViewUnit,
  onSortChange,
  sort,
  setCurrentPage,
  currentPage
}) => {
  const cssColumnClasses = useListColumnsStyles();
  const providerClasses = useListTableThemeProviderStyles();

  const listTableClasses = useListTableStyles();
  const { headers, listColumns, footers } = useColumns({
    classes: cssColumnClasses,
    groupBy,
    dateRange,
    periodScale,
    overViewUnit,
    totals
  });

  const resourceListNavigationContext = useMemo(
    () => ({
      navigatePage: setCurrentPage,
      currentPage,
      maximumPageSize: DEFAULT_PAGE_SIZE,
      disableInfiniteScroll: true
    }),
    [currentPage, setCurrentPage]
  );

  return (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTableNavigationContext.Provider
        value={resourceListNavigationContext}
      >
        {(rows?.length || 0) === 0 && !loadingRows && currentPage === 1 ? (
          <NoAvailabilityRows groupType={sort.field} />
        ) : (
          <ListTable
            classes={listTableClasses}
            headers={headers}
            columns={listColumns}
            records={rows}
            footers={footers}
            totals={footers}
            hasMore={hasMoreRows}
            loadMore={loadMoreRows}
            isLoading={loadingRows || loadingMore}
            onSortChange={onSortChange}
            variant="table"
            sort={sort}
            useInlineStyles={false}
            showLoadingComponent={loadingRows}
            showLoadMoreSkeleton={loadingMore}
            paginationType={PAGINATION_TYPE.NAVIGATION}
            isMobile={false}
          />
        )}
      </ListTableNavigationContext.Provider>
    </ListTableThemeProvider>
  );
};

AvailabilityTable.propTypes = {
  loadingRows: PropTypes.bool.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  loadMoreRows: PropTypes.func.isRequired,
  hasMoreRows: PropTypes.bool.isRequired,
  dateRange: PropTypes.object.isRequired,
  periodScale: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  totals: PropTypes.object,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  groupBy: PropTypes.oneOf([
    ResourceUserAvailabilityGroupType.Role,
    ResourceUserAvailabilityGroupType.Location,
    ResourceUserAvailabilityGroupType.Division,
    ResourceUserAvailabilityGroupType.ServiceCenter,
    ResourceUserAvailabilityGroupType.CostCenter,
    ResourceUserAvailabilityGroupType.Department,
    ResourceUserAvailabilityGroupType.EmployeeType
  ]).isRequired,
  overViewUnit: PropTypes.oneOf([
    OVERVIEW_UNITS_ENUM.HOURS,
    OVERVIEW_UNITS_ENUM.PERCENTAGE,
    OVERVIEW_UNITS_ENUM.FTE
  ]).isRequired
};

export default AvailabilityTable;
