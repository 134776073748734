import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const REMOVE_TASK_RESOURCE_ESTIMATES = gql`
  mutation removeTaskResourceEstimate(
    $input: RemoveTaskResourceEstimateInput!
  ) {
    removeTaskResourceEstimate(input: $input) {
      taskResourceEstimateId
    }
  }
`;

export const useRemoveTaskResourceEstimate = () => {
  const [removeTaskResourceEstimate, { data, loading }] = useMutation(
    REMOVE_TASK_RESOURCE_ESTIMATES
  );

  const removeTaskResourceEstimateCallback = useCallback(
    resourceEstimate =>
      removeTaskResourceEstimate({
        variables: {
          input: resourceEstimate
        }
      }),
    [removeTaskResourceEstimate]
  );

  return {
    removeTaskResourceEstimate: removeTaskResourceEstimateCallback,
    data,
    loading
  };
};

export default useRemoveTaskResourceEstimate;
