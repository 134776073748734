import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProjectIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 10,4 H 2.000000000000003 V 20 H 22 V 6 H 12 Z" />
    </>
  </SvgIcon>
));

export default ProjectIcon;
