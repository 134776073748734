import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const UPDATE_PROJECT_ADDRESSES = gql`
  mutation UpdateProjectAddresses($input: UpdateProjectAddressesInput!) {
    updateProjectAddresses(input: $input) {
      project {
        id
        addresses {
          address
          city
          country {
            id
            displayText
          }
          stateProvince
          zipPostalCode
          addressType
        }
      }
    }
  }
`;

const getInputAddresses = addresses => {
  return addresses.filter(address =>
    Object.keys(address).some(
      k => k !== 'id' && k !== 'addressType' && address[k]
    )
  );
};

export const useUpdateProjectAddresses = ({
  projectId,
  setIsSubmitting,
  onClose
}) => {
  const [updateProjectAddresses] = useMutation(UPDATE_PROJECT_ADDRESSES);

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      setIsSubmitting(true);
      const { addresses } = values;
      const inputAddresses = getInputAddresses(addresses);

      const input = {
        projectId,
        addresses: inputAddresses.map(
          ({
            address,
            city,
            stateProvince,
            zipPostalCode,
            country,
            addressType
          }) => ({
            address: address || null,
            city: city || null,
            stateProvince: stateProvince || null,
            zipPostalCode: zipPostalCode || null,
            country:
              country && country.id
                ? { id: country.id, displayText: country.displayText }
                : null,
            addressType
          })
        )
      };

      await updateProjectAddresses({
        variables: { input },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProjectAddresses: {
            __typename: 'UpdateProjectAddressesResponse',
            project: {
              __typename: 'Project',
              id: projectId,
              addresses:
                inputAddresses.map(address => ({
                  __typename: 'Addresses',
                  ...(address.country && address.country.id
                    ? { country: { __typename: 'Country', ...address.country } }
                    : {}),
                  ...address
                })) || []
            }
          }
        }
      });
      resetForm();
      onClose();
      setIsSubmitting(false);
    },
    [onClose, projectId, setIsSubmitting, updateProjectAddresses]
  );

  return { onSubmit };
};

export default useUpdateProjectAddresses;
