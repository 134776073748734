import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { CustomChip } from '~/modules/projects/project/common/components/ContactInfo';
import { ADDRESS_TYPE } from '~/modules/common/enums';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(0.5)
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: theme.spacing(0.25)
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'hidden'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export const AddressLine = ({ text, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftSide}>
        <div className={classes.nameContainer}>
          <Typography variant="body2" noWrap className={classes.name}>
            {text}
          </Typography>
        </div>
      </div>
      {label && <CustomChip label={label} />}
    </div>
  );
};

AddressLine.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

const Address = ({ item }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const {
    address,
    city,
    country,
    stateProvince,
    zipPostalCode,
    addressType
  } = item;

  const formattedCityStateZip = [city, stateProvince, zipPostalCode]
    .filter(Boolean)
    .join(', ');

  return (
    <div className={classes.container}>
      {address && (
        <AddressLine
          text={address}
          label={
            addressType !== ADDRESS_TYPE.OTHERS &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${ADDRESS_TYPE[addressType]}`
            })
          }
        />
      )}
      {formattedCityStateZip && (
        <AddressLine
          text={formattedCityStateZip}
          label={
            addressType !== ADDRESS_TYPE.OTHERS &&
            !address &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${ADDRESS_TYPE[addressType]}`
            })
          }
        />
      )}
      {country && (
        <AddressLine
          text={country.displayText}
          label={
            addressType !== ADDRESS_TYPE.OTHERS &&
            !address &&
            !formattedCityStateZip &&
            formatMessage({
              id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${ADDRESS_TYPE[addressType]}`
            })
          }
        />
      )}
    </div>
  );
};

Address.propTypes = {
  item: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.shape({
      id: PropTypes.string,
      displayText: PropTypes.string
    }),
    stateProvince: PropTypes.string,
    zipPostalCode: PropTypes.string,
    addressType: PropTypes.string
  })
};

export default Address;
