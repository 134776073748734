import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingBottom: theme.spacing(2),
    ...(isPSATaskEstimateRollUpMobileViewEnabled && {
      backgroundColor: '#cacaca'
    })
  }),
  showClosedTasks: {
    padding: theme.spacing(0, 1)
  },
  noPaddingTop: {
    paddingTop: 0
  },
  noPaddingBottom: {
    paddingBottom: 0
  },
  listItem: {},
  project: {
    backgroundColor: '#cacaca'
  },
  child: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    backgroundColor: theme.palette.grey[100],
    ...(isPSATaskEstimateRollUpMobileViewEnabled && {
      paddingBottom: theme.spacing(0.625),
      backgroundColor: '#cacaca'
    })
  }),
  child0: {},
  child1: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(1)
      : theme.spacing(2)
  }),
  child2: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(1.5)
      : theme.spacing(4)
  }),
  child3: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(2)
      : theme.spacing(6)
  }),
  child4: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(2.5)
      : theme.spacing(7)
  }),
  child5: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(3)
      : theme.spacing(8)
  }),
  child6: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(3.5)
      : theme.spacing(9)
  }),
  child7: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(4)
      : theme.spacing(10)
  }),
  child8: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(4.5)
      : theme.spacing(11)
  }),
  child9: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(5)
      : theme.spacing(12)
  }),
  child10: ({ isPSATaskEstimateRollUpMobileViewEnabled }) => ({
    paddingLeft: isPSATaskEstimateRollUpMobileViewEnabled
      ? theme.spacing(5.5)
      : theme.spacing(13)
  })
}));

export default useStyles;
