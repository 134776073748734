import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const CLIENT_CURRENCY_QUERY = gql`
  query GetClientDetails($uri: String!) {
    client(uri: $uri) {
      id
      invoiceCurrency {
        id
        displayText
        name
        uri
        symbol
      }
    }
  }
`;

export const useClientCurrency = ({ client, skipClient = false }) => {
  const { data, refetch } = useQuery(CLIENT_CURRENCY_QUERY, {
    variables: {
      uri: client ? client.id : ''
    },
    skip: skipClient
  });

  const clientCurrency = get(data, 'client', {});

  return {
    clientCurrency,
    refetch
  };
};
export default useClientCurrency;
