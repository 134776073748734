import React from 'react';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me';
import { SummaryCardHelp } from '~/modules/common/components';

const content = ({ formatMessage, canViewProjectCostData }) =>
  [
    canViewProjectCostData && {
      title: formatMessage({ id: 'projectsCardHelp.overBudget' }),
      message: formatMessage({ id: 'projectsCardHelp.overBudgetAlert' })
    },
    !canViewProjectCostData && {
      title: formatMessage({ id: 'projectsCardHelp.overHoursBudget' }),
      message: formatMessage({
        id: 'projectsCardHelp.overHoursBudgetAlert'
      })
    },
    {
      title: formatMessage({ id: 'projectsCardHelp.overEndDate' }),
      message: formatMessage({ id: 'projectsCardHelp.overEndDateAlert' })
    }
  ].filter(x => x);

const ProjectsCardHelp = () => {
  const { formatMessage } = useIntl();
  const {
    featureFlags: { isPsaPpmCostEacEnhancementsEnabled },
    permissionsMap
  } = useMeContext();
  const canViewProjectCostData =
    isPsaPpmCostEacEnhancementsEnabled &&
    Boolean(permissionsMap['urn:replicon:project-action:view-cost-data']);

  const toolTipContent = content({ formatMessage, canViewProjectCostData });

  return <SummaryCardHelp content={toolTipContent} />;
};

export default ProjectsCardHelp;
