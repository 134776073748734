import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  itemWidth,
  actionsWidth
} from '~/modules/common/charts/timeline/calculations';
import {
  usePopperHandlers,
  ResourceChartTotalPopper
} from '~/modules/resourcing/components/ResourceChartTotalPopper';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { ResourceRequestIcon } from '~/modules/common/components';
import { useResourceRequestToolbarContext } from '../../../hooks';
import ResourceRequestChartFooterRow from '../ResourceRequestChartFooterRow';
import TotalsFooterPopoverCell from '../TotalsFooterPopoverCell/TotalsFooterPopoverCell';
import { useProjectTotals2 } from './hooks/useProjectTotals2';

const leftComponentWidth = actionsWidth + itemWidth * 2;

const useStyles = makeStyles(theme => ({
  actionButton: {
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    margin: theme.spacing(0, 2, 0, 0)
  }
}));

const actionButtonIcon = <ResourceRequestIcon />;

export const ResourceRequestChartFooter = ({
  handleAddResourceRequestClick,
  isEditSwitchEnabled
}) => {
  const {
    project: { permittedActionUris }
  } = useProjectContext();

  const canEditProject = permittedActionUris.includes(
    'urn:replicon:project-action:edit-project'
  );
  const canViewCostData = permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );
  const classes = useStyles();
  const {
    scale,
    chartDates,
    filter,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();

  const leftComponent = useMemo(
    () =>
      isEditSwitchEnabled && canEditProject ? (
        <Button
          className={classes.actionButton}
          size="small"
          color="primary"
          variant="text"
          onClick={handleAddResourceRequestClick}
          startIcon={actionButtonIcon}
        >
          <FormattedMessage id="resourceRequestButtons.requestResources" />
        </Button>
      ) : null,
    [
      isEditSwitchEnabled,
      canEditProject,
      classes,
      handleAddResourceRequestClick
    ]
  );

  const { loading, resourceRequestsTotals } = useProjectTotals2({
    filter,
    includeActualsResourceUsers: isResourceActualModeEnabled
  });
  const {
    anchorEl,
    onMenuClose,
    onMenuClick,
    handleKeyDown
  } = usePopperHandlers();

  const showTotalComponent = Boolean(
    resourceRequestsTotals?.totalHours ||
      resourceRequestsTotals?.totalCost?.amount
  );

  return (
    <ResourceRequestChartFooterRow
      scale={scale}
      scaleItemCount={chartDates.length}
      leftComponentWidth={leftComponentWidth}
      totalLabel="resourceRequestChartFooter.totalRequested"
      leftComponent={leftComponent}
    >
      {showTotalComponent && (
        <TotalsFooterPopoverCell
          resourceRequestsTotals={resourceRequestsTotals}
          handleClick={onMenuClick}
          handleKeyDown={handleKeyDown}
          onClickAway={onMenuClose}
          canViewCostData={canViewCostData}
          loading={loading}
        >
          <ResourceChartTotalPopper
            id="resource-request-total-popper"
            anchorEl={anchorEl}
            onClose={onMenuClose}
            requestedHours={resourceRequestsTotals.totalHours}
            requestedCost={resourceRequestsTotals.totalCost}
            viewCosts={canViewCostData}
            viewAllocation={false}
            viewDifference={false}
          />
        </TotalsFooterPopoverCell>
      )}
    </ResourceRequestChartFooterRow>
  );
};

ResourceRequestChartFooter.propTypes = {
  handleAddResourceRequestClick: PropTypes.func,
  isEditSwitchEnabled: PropTypes.bool
};

export default ResourceRequestChartFooter;
