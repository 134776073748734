import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { TASK_STATUS } from '~/modules/common/enums';
import { getEquivalentRolledUpTaskStatus } from '~/modules/common/components/ProjectDrawer/util';
import { MoneyValue } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  notStarted: {
    color: theme.palette.taskStatus.NOTSTARTED.color
  },
  value: {
    fontWeight: 600,
    fontSize: theme.spacing(1.75)
  },
  noValue: {
    fontWeight: 400
  }
});

export const ActualCostFormatter = ({ field, record }) => {
  const classes = useStyles();
  const value = record[field];
  const rolledUpTaskStatus = getEquivalentRolledUpTaskStatus({
    status: record.taskStatus,
    actualHours: record.actualHours
  });

  return (
    <>
      {rolledUpTaskStatus === TASK_STATUS.NOTSTARTED ? (
        <span className={classes.notStarted}>
          <FormattedMessage id="taskDrawer.notStarted" />
        </span>
      ) : isNullOrUndefined(value) ? (
        <FormattedMessage id="taskDrawer.na" />
      ) : (
        <MoneyValue
          money={value}
          className={value.amount !== 0 ? classes.value : classes.noValue}
        />
      )}
    </>
  );
};

ActualCostFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default ActualCostFormatter;
