import { useIntl } from 'react-intl';
import { getProjectStatusClauses } from '~/modules/common/components/SearchBox/Facets/ProjectStatusPolicyFacet/projectStatusClause';

const filterAndMapProjectStatusPolicies = (projectStatusClause, searchTerm) =>
  (projectStatusClause || [])
    .filter(
      policy =>
        policy &&
        policy.displayText &&
        policy.displayText
          .toLocaleLowerCase()
          .includes((searchTerm || '').toLocaleLowerCase())
    )
    .map(policy => ({
      ...policy,
      value: policy.id,
      label: policy.displayText,
      key: policy.displayText
    }));

export const useProjectStatusPolicy = ({
  searchTerm,
  customStatusMapping,
  isCustomStatusNameEnabled
}) => {
  const intl = useIntl();
  const projectPolicies = filterAndMapProjectStatusPolicies(
    getProjectStatusClauses(
      intl,
      customStatusMapping,
      isCustomStatusNameEnabled
    ),
    searchTerm
  );

  return {
    projectPolicies: (projectPolicies.length && projectPolicies) || {}
  };
};

export const searchableProjectPolicies = ({ intl }) => async searchTerm => {
  return filterAndMapProjectStatusPolicies(
    getProjectStatusClauses(intl),
    searchTerm
  );
};

export const useSearchableProjectOptions = () => {
  const intl = useIntl();

  return {
    fetchOptions: searchableProjectPolicies({
      intl
    })
  };
};
