import React from 'react';
import PropTypes from 'prop-types';
import { Link, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import SlackLogo from '../../common/slack.svg';

const useStyles = makeStyles(theme => ({
  link: {
    marginLeft: theme.spacing(1)
  }
}));

export const SlackChannel = ({ slackChannel }) => {
  const classes = useStyles();

  return (
    <>
      <img src={SlackLogo} alt="Slack Logo" />
      <Link
        className={classes.link}
        href={`https://slack.com/app_redirect?channel=${slackChannel}`}
        target="_blank"
      >
        <FormattedMessage id="projectBasicInfo.openSlackChannel" />
      </Link>
    </>
  );
};

SlackChannel.propTypes = {
  slackChannel: PropTypes.string
};

export default SlackChannel;
