import { useCallback } from 'react';
import useDeleteResourceRequest from './useDeleteResourceRequest';

const useResourceAssignmentEventHandlers = ({
  onCloseAssignmentDialog,
  isResourceActualModeEnabled
}) => {
  const deleteResourceRequest = useDeleteResourceRequest({
    isResourceActualModeEnabled
  });
  const onDeleteResourceRequest = useCallback(
    async ({ request }) => {
      await deleteResourceRequest({
        request
      });
      onCloseAssignmentDialog();
    },
    [deleteResourceRequest, onCloseAssignmentDialog]
  );

  return { onDeleteResourceRequest };
};

export default useResourceAssignmentEventHandlers;
