import { gql } from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import {
  ResourceRequestStatus,
  SpecificResourceRequestFragmentDoc
} from '~/types';

import {
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment,
  specificResourceAllocationTimeOffFragment,
  specificResourceAllocationHolidaysFragment,
  specificResourceRequestWithAllocationsFragment
} from '~/modules/resourcing/common/fragments';
import { useUpdateCompletedResourceAllocationProjectCacheOnAddWithProposedRequest2 } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks/useUpdateCompletedResourceAllocationCache';

import {
  mapToServiceRequest,
  mapToServiceRequestWithTimeoff
} from '../../util';

export const COMPLETE_RESOURCE_REQUEST = gql`
  mutation markResourceRequestAsComplete(
    $input: MarkResourceRequestAsCompleteInput!
  ) {
    markResourceRequestAsComplete2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
      }
      isOptimistic @client
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
`;

export const COMPLETE_RESOURCE_REQUEST_WITH_TIMEOFF = gql`
  mutation markResourceRequestAsComplete(
    $input: MarkResourceRequestAsCompleteInput!
    $showTimeOff: Boolean!
    $chartDateRange: DateRangeInput
    $showHolidays: Boolean!
  ) {
    markResourceRequestAsComplete2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequest
        resourceAllocations {
          ...SpecificResourceAllocation
          role {
            uri
            id
            displayText
          }
          user {
            ...SpecificResourceAllocationUser
            ...SpecificResourceAllocationTimeOff
            ...SpecificResourceAllocationHolidays
          }
        }
      }
      isOptimistic @client
    }
  }
  ${SpecificResourceRequestFragmentDoc}
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
  ${specificResourceAllocationTimeOffFragment}
  ${specificResourceAllocationHolidaysFragment}
`;

export const invokeMarkResourceRequestAsComplete = markResourceRequestAsComplete => ({
  request,
  chartSelectedTab,
  requestAccessLevel,
  showTimeOff,
  chartDateRange,
  showHolidays,
  filter,
  sort,
  allocationChartRef
}) => {
  const requestParams = {
    request,
    responseType: 'markResourceRequestAsComplete2',
    responseTypeName: 'MarkResourceRequestAsCompleteResult',
    requestStatus: ResourceRequestStatus.Complete,
    chartSelectedTab,
    requestAccessLevel,
    refetchQueries: ['getProjectTotalsBySlug']
  };

  allocationChartRef?.current.setResourceRequestAcceptedLoading(true);

  if (!showTimeOff && !showHolidays) {
    markResourceRequestAsComplete(
      mapToServiceRequest({
        ...requestParams,
        cacheUpdate: useUpdateCompletedResourceAllocationProjectCacheOnAddWithProposedRequest2(
          {
            allocationChartRef
          }
        )
      })
    );
  } else {
    markResourceRequestAsComplete(
      mapToServiceRequestWithTimeoff({
        ...requestParams,
        cacheUpdate: useUpdateCompletedResourceAllocationProjectCacheOnAddWithProposedRequest2(
          {
            allocationChartRef
          }
        ),
        chartDateRange,
        showTimeOff,
        showHolidays,
        allocationChartRef
      })
    );
  }
};

export default graphql(COMPLETE_RESOURCE_REQUEST, {
  name: 'markResourceRequestAsComplete'
});
