import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getAllocationStatusBasedClassName } from '~/modules/resourcing/common/enums';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import useUpdateResourceAllocation from '~/modules/resourcing/hooks/useUpdateResourceAllocation';
import useAllocationTimelineBlocks from '~/modules/resourcing/common/chart/hooks/useAllocationTimelineBlocks';
import PeriodTimelineBlocks from '../PeriodTimelineBlocks';
import { useResourceAllocationDragIndicators } from '../../../hooks';
import useStyles from './useStyles';

const ResourceAllocationPeriodTimelineBlocks = memo(
  ({
    classes: classesOverrides,
    chartStartDate,
    resourceAllocation,
    scale,
    rowIndex,
    periodTimelineBlockClasses
  }) => {
    const classes = useStyles({ classes: classesOverrides, rowIndex });

    const { dynamicPosition } = useMemo(
      () =>
        getDynamicPositionAttributes({
          chartStartDate,
          scale,
          start: mapIsoStringtoUtcObject(resourceAllocation.startDate),
          end: mapIsoStringtoUtcObject(resourceAllocation.endDate),
          rowIndex,
          isPsaRmpUserSchedulePerfFixEnabled: true
        }),
      [
        chartStartDate,
        scale,
        resourceAllocation.startDate,
        resourceAllocation.endDate,
        rowIndex
      ]
    );

    const { onUpdateResourceAllocation } = useUpdateResourceAllocation();
    const onAllocationChange = useCallback(
      evt =>
        onUpdateResourceAllocation({
          allocation: evt.values
        }),
      [onUpdateResourceAllocation]
    );

    const {
      gestureBindEvents,
      dragDelta,
      currentResizeDirection
    } = useResourceAllocationDragIndicators({
      resourceAllocation,
      chartStartDate,
      scale,
      onAllocationChange
    });

    const { allocationPeriods } = useAllocationTimelineBlocks({
      allocation: resourceAllocation,
      scale,
      showTotalCost: false,
      relativeDynamicPositionLeft: dynamicPosition.left,
      allocationScheduleDurationHours:
        resourceAllocation.user.scheduleDurationByDay,
      chartStartDate
    });

    return (
      <div className={classes.root} style={dynamicPosition}>
        <div
          className={classNames(
            classes.container,
            classes[
              getAllocationStatusBasedClassName(
                resourceAllocation.allocationStatus,
                'container'
              )
            ]
          )}
        >
          <PeriodTimelineBlocks
            classes={periodTimelineBlockClasses}
            displayPeriods={allocationPeriods}
            scale={scale}
            renderPeriod={({ _, loadingLabelClassName }) => (
              <div
                className={classNames(
                  loadingLabelClassName,
                  resourceAllocation.isAdjustedLoading
                    ? classes.loadingAdjusted
                    : null
                )}
              ></div>
            )}
            gestureBindEvents={gestureBindEvents}
            dragDelta={dragDelta}
            resizeDirection={currentResizeDirection}
          />
        </div>
      </div>
    );
  }
);

ResourceAllocationPeriodTimelineBlocks.propTypes = {
  classes: PropTypes.object,
  chartStartDate: PropTypes.object.isRequired,
  resourceAllocation: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  rowIndex: PropTypes.number,
  periodTimelineBlockClasses: PropTypes.object
};
export default ResourceAllocationPeriodTimelineBlocks;
