import React, { useMemo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  legends: {
    padding: theme.spacing(0, 2, 2, 0),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  projectsCount: {
    padding: theme.spacing(2, 2)
  },
  capsule: { borderRadius: 20, margin: theme.spacing(0, 2, 3, 2) }
}));

export const ActiveTaskCardLoading = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton width="100%" height={96} variant="rect" />
      <Typography variant="h3" className={classes.projectsCount}>
        <Skeleton width="50%" />
      </Typography>

      <Typography variant="body1" className={classes.legends}>
        <Skeleton width="40%" />
      </Typography>
      <Skeleton
        height={40}
        variant="rect"
        classes={useMemo(() => ({ root: classes.capsule }), [classes.capsule])}
      />
    </>
  );
};

export default ActiveTaskCardLoading;
