import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/EditSharp';
import DoneIcon from '@material-ui/icons/DoneSharp';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.spacing(1),
    flexShrink: 0,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: theme.shadows[4]
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 20
  }
}));

export const EditResourceButton = ({
  classes: classesOverride,
  editable,
  toggleEditable,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Button
      data-qe-id={dataQeId}
      variant="contained"
      onClick={toggleEditable}
      className={classes.button}
    >
      {!editable ? (
        <>
          <EditIcon data-qe-id={`${dataQeId}_Edit`} className={classes.icon} />
          <FormattedMessage id="button.edit" />
        </>
      ) : (
        <>
          <DoneIcon data-qe-id={`${dataQeId}_Done`} className={classes.icon} />
          <FormattedMessage
            id={isMobile ? 'button.done' : 'button.doneEditing'}
          />
        </>
      )}
    </Button>
  );
};

EditResourceButton.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  toggleEditable: PropTypes.func.isRequired
};

export default EditResourceButton;
