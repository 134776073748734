import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

const MoreMenuOption = React.forwardRef(
  ({ onClick, closeMenu, children, ...rest }, ref) => (
    <MenuItem onClick={onClick} {...rest}>
      {children}
    </MenuItem>
  )
);

MoreMenuOption.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  closeMenu: PropTypes.bool
};

export default MoreMenuOption;
