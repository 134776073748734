import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  AbbreviatedDecimal,
  NoValue,
  Decimal
} from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  allocatedHours: {},
  plannedHours: {},
  tooltipContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridColumnGap: theme.spacing(2)
  },
  hours: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column'
  }
}));

export const RowTotalHours = ({
  qeIdTag,
  classes: classesOverrides,
  showLabel = true,
  showAbbreviatedValue = true,
  plannedHours,
  allocatedHours
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const Component = showAbbreviatedValue ? AbbreviatedDecimal : Decimal;

  return (
    <>
      <div
        data-qe-id={`${qeIdTag}_AllocateHours`}
        className={classNames(classes.tooltipContent, classes.allocatedHours)}
      >
        {showLabel && <FormattedMessage id="rowTotalTooltip.allocatedHours" />}
        <div className={classes.hours}>
          {typeof allocatedHours === 'number' && allocatedHours ? (
            <Component value={allocatedHours} />
          ) : (
            <NoValue />
          )}
        </div>
      </div>
      <div
        data-qe-id={`${qeIdTag}_PlannedHours`}
        className={classNames(classes.tooltipContent, classes.plannedHours)}
      >
        {showLabel && <FormattedMessage id="rowTotalTooltip.plannedHours" />}
        <div className={classes.hours}>
          {typeof plannedHours === 'number' && plannedHours ? (
            <Component value={plannedHours} />
          ) : (
            <NoValue />
          )}
        </div>
      </div>
    </>
  );
};

RowTotalHours.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  plannedHours: PropTypes.number,
  allocatedHours: PropTypes.number,
  showLabel: PropTypes.bool,
  showAbbreviatedValue: PropTypes.bool
};

export default RowTotalHours;
