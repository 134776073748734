import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(2)
  },
  header: {
    color: theme.palette.text.secondary,
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: theme.typography.body2.fontSize,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 0, 0.5, 0),
    margin: theme.spacing(0, 0, 1, 0)
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'start',
    fontSize: theme.typography.body2.fontSize,
    width: '1.5em',
    height: '1.5em',
    margin: [[0, '0.375em', 0, '0']],
    color: theme.palette.text.secondary
  },
  editButton: {
    margin: theme.spacing(-2.5, 0)
  },
  noValueName: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  button: {
    padding: theme.spacing(0.25, 0.5, 0),
    margin: theme.spacing(0, 0, 0.5, -0.5)
  }
}));

export const useDialogStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  root: {
    width: '75%',
    '& .MuiInputBase-input': {
      fontSize: theme.typography.fontSize
    }
  }
}));

export const useTableStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    color: theme.palette.primary.main,
    fontSize: theme.typography.caption.fontSize,
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1, 2)
  },
  table: {
    fontSize: theme.typography.caption.fontSize,
    border: `1px solid ${theme.palette.table.border}`
  },
  cell: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0.75, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footerCell: {
    border: 'none',
    fontSize: theme.typography.caption.fontSize
  },
  cellText: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0.75, 2)
  },
  noResourcePools: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0.75, 2),
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    lineHeight: 1.8
  },
  button: {
    fontSize: theme.typography.caption.fontSize,
    textTransform: 'none',
    padding: theme.spacing(0.5)
  },
  deleteButton: {
    minWidth: theme.spacing(5)
  },
  skeletonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(4),
    width: '100%'
  }
}));

export const useDropdownStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    width: theme.spacing(40)
  },
  primary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const useSnackbarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.success.light,
    borderLeft: `${theme.spacing(1)}px solid ${theme.palette.success.main}`,
    color: theme.palette.success.contrastText
  }
}));
