import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RECALCULATE_CONTRACT_CLAUSE = gql`
  mutation recalculateProjectsBillingContractClauses($projectUris: [String!]) {
    recalculateProjectsBillingContractClauses2(projectUris: $projectUris) {
      batchUri
    }
  }
`;
export const useRecalculateProjectsBillingContractClauses = projectUri => {
  const [recalculateProjectsBillingContractClauses] = useMutation(
    RECALCULATE_CONTRACT_CLAUSE
  );

  return {
    recalculateProjectsBillingContractClauses: setBatchState =>
      recalculateProjectsBillingContractClauses({
        variables: { projectUris: [projectUri] },
        update: (
          proxy,
          {
            data: {
              recalculateProjectsBillingContractClauses2: { batchUri }
            }
          }
        ) => {
          setBatchState({
            batchId: batchUri,
            batchInProgress: true
          });
        }
      })
  };
};

export default useRecalculateProjectsBillingContractClauses;
