import { ExpenseType as ExpenseTypeEditor } from '../ExpenseType';
import FrequencyEditor from './FrequencyEditor';
import MilestoneTaskEditor from './MilestoneTaskEditor';
import MilestonePercentageEditor from './MilestonePercentageEditor';
import MarkupEditor from './MarkupEditor';
import MilestoneAmountEditor from './MilestoneAmountEditor';
import MilestoneTotalAmount from './MilestoneTotalAmount';
import MilestoneTotalPercentage from './MilestoneTotalPercentage';
import DateEditorForDateRange from './DateEditorForDateRange';

export const editors = {
  'urn:replicon:script-key:parameter:frequency': FrequencyEditor,
  'urn:replicon:script-key:parameter:task': MilestoneTaskEditor,
  'urn:replicon:script-key:parameter:percentage': MilestonePercentageEditor,
  'urn:replicon:script-key:parameter:markup': MarkupEditor,
  'urn:replicon:script-key:parameter:expense-type': ExpenseTypeEditor,
  'urn:replicon:script-key:parameter:milestone-amount': MilestoneAmountEditor,
  'milestone-total-amount': MilestoneTotalAmount,
  'milestone-total-percentage': MilestoneTotalPercentage,
  'urn:replicon:script-key:parameter:start-date': DateEditorForDateRange,
  'urn:replicon:script-key:parameter:end-date': DateEditorForDateRange
};
