import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PROJECT_STATUS } from '~/modules/common/enums';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const mobileChartStyle = {
  width: 250,
  height: 250,
  innerRadius: 75,
  titleX: 125,
  titleY: 125,
  valueX: 125,
  valueY: 150
};

export const dataPoints = countSummary =>
  countSummary.total === 0
    ? [{ y: 1 }]
    : [
        { y: countSummary.initiate, id: PROJECT_STATUS.INITIATE },
        { y: countSummary.planning, id: PROJECT_STATUS.PLANNING },
        { y: countSummary.execution, id: PROJECT_STATUS.EXECUTION },
        { y: countSummary.closed, id: PROJECT_STATUS.CLOSEOUT }
      ];

const statusColors = {
  initiate: theme.palette.project.initiate.main,
  planning: theme.palette.project.planning.main,
  execution: theme.palette.project.execution.main,
  closeout: theme.palette.project.closeout.main,
  zeroTotal: theme.palette.common.white
};

export const legends = (
  countSummary,
  isCustomStatusNameEnabled,
  customStatusMapping
) => [
  {
    value: countSummary.initiate,
    title:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_STATUS.INITIATE] ? (
        customStatusMapping[PROJECT_STATUS.INITIATE]
      ) : (
        <FormattedMessage id="projectStatus.initiate" />
      ),
    colorScale: statusColors.initiate,
    key: PROJECT_STATUS.INITIATE
  },
  {
    value: countSummary.planning,
    title:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_STATUS.PLANNING] ? (
        customStatusMapping[PROJECT_STATUS.PLANNING]
      ) : (
        <FormattedMessage id="projectStatus.planning" />
      ),
    colorScale: statusColors.planning,
    key: PROJECT_STATUS.PLANNING
  },
  {
    value: countSummary.execution,
    title:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_STATUS.EXECUTION] ? (
        customStatusMapping[PROJECT_STATUS.EXECUTION]
      ) : (
        <FormattedMessage id="projectStatus.execution" />
      ),
    colorScale: statusColors.execution,
    key: PROJECT_STATUS.EXECUTION
  },
  {
    value: countSummary.closed,
    title:
      isCustomStatusNameEnabled &&
      customStatusMapping[PROJECT_STATUS.CLOSEOUT] ? (
        customStatusMapping[PROJECT_STATUS.CLOSEOUT]
      ) : (
        <FormattedMessage id="projectStatus.closeout" />
      ),
    colorScale: statusColors.closeout,
    key: PROJECT_STATUS.CLOSEOUT
  }
];

const colorScale = countSummary =>
  countSummary.total === 0
    ? [statusColors.zeroTotal]
    : [
        statusColors.initiate,
        statusColors.planning,
        statusColors.execution,
        statusColors.closeout
      ];

export const useProjectStatusConfig = ({
  chartDataInput: { projectsStatusCountSummary },
  isCustomStatusNameEnabled,
  customStatusMapping
}) => ({
  colorScale: projectsStatusCountSummary
    ? colorScale(projectsStatusCountSummary)
    : [],
  legends: projectsStatusCountSummary
    ? legends(
        projectsStatusCountSummary,
        isCustomStatusNameEnabled,
        customStatusMapping
      )
    : [],
  dataPoints: projectsStatusCountSummary
    ? dataPoints(projectsStatusCountSummary)
    : [],
  mobileChartStyle
});

export default useProjectStatusConfig;
