import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const AVATAR_QUERY = gql`
  query GetAvatar($userUri: String!) {
    avatar(userId: $userUri) {
      id
      avatar
      medium
    }
  }
`;

const useAvatar = ({ userUri, size = 'avatar' }) => {
  const { data } = useQuery(AVATAR_QUERY, {
    variables: { userUri }
  });

  return data && data.avatar ? data.avatar[size] : null;
};

export default useAvatar;
