import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';

export const useVariationColors = ({
  variationStatus,
  days,
  isPsaPpmCostEacEnhancementsEnabled
}) => {
  const isDaysDefined = days !== undefined && days !== null;

  const isOver =
    (variationStatus &&
      variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.OVER) ||
    (isDaysDefined && days > 0);

  const isUnder =
    (variationStatus &&
      variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.UNDER) ||
    (isDaysDefined && days < 0);

  const isMissing =
    variationStatus &&
    variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE &&
    days === undefined;

  const color = isPsaPpmCostEacEnhancementsEnabled
    ? isOver
      ? theme.palette.error.dark
      : isUnder
      ? theme.palette.success.dark
      : '#656565'
    : isOver
    ? theme.palette.taskRollUp.exceeded.main
    : isUnder
    ? theme.palette.taskRollUp.under.main
    : '#656565';

  const backgroundColor = isPsaPpmCostEacEnhancementsEnabled
    ? isOver
      ? theme.palette.error.light
      : isUnder
      ? theme.palette.success.light
      : '#ebebeb'
    : isOver
    ? theme.palette.taskRollUp.exceeded.light
    : isUnder
    ? theme.palette.taskRollUp.under.light
    : '#ebebeb';

  const fill = isPsaPpmCostEacEnhancementsEnabled
    ? isOver
      ? theme.palette.error.dark
      : isUnder
      ? theme.palette.success.dark
      : isMissing
      ? theme.palette.taskRollUp.missing.main
      : theme.palette.success.main
    : isOver
    ? theme.palette.taskRollUp.exceeded.main
    : isUnder
    ? theme.palette.taskRollUp.under.main
    : isMissing
    ? theme.palette.taskRollUp.missing.main
    : theme.palette.success.main;

  return {
    color,
    backgroundColor,
    fill
  };
};

export default useVariationColors;
