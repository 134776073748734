/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0
  },
  datesRow: {
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  data: {
    margin: theme.spacing(0, 3, 3, 3)
  },
  option: {
    display: 'flex',
    flexDirection: 'row'
  },
  skeletonArrow: {
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    alignSelf: 'center',
    margin: theme.spacing(0, 1)
  },
  skeletonDate: {
    margin: theme.spacing(2, 0)
  },
  skeletonCheckBox: {
    margin: theme.spacing(2, 0)
  },
  skeletonLabel: {
    margin: theme.spacing(2)
  }
}));

export const RescheduleProjectSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.data}>
        <div className={classes.datesRow}>
          <Skeleton width="50%" height={47} className={classes.skeletonDate} />
          <Skeleton width={22} height={22} className={classes.skeletonArrow} />
          <Skeleton width="50%" height={47} className={classes.skeletonDate} />
        </div>
        {[...Array(3)].map((_, index) => (
          <div key={`Option_${index}`} className={classes.option}>
            <Skeleton
              key={`CheckBox_${index}`}
              width={22}
              height={25}
              className={classes.skeletonCheckBox}
            />
            <Skeleton
              key={`Label_${index}`}
              width="60%"
              height={25}
              className={classes.skeletonLabel}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

RescheduleProjectSkeleton.propTypes = {};

export default RescheduleProjectSkeleton;
