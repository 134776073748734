import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDeleteResourceRequestHandler } from '~/modules/resourcing/common/hooks';
import { useSessionStorage } from '~/modules/common/hooks';
import { ResourceRequestDeleteActions } from '~/modules/resourcing/common/components';

export const ResourceRequestPeriodDeleteDialog = ({
  resourceRequest,
  closeRemoveRequestDialog,
  showRemoveRequestDialog,
  isResourceActualModeEnabled
}) => {
  const { deleteResourceRequestFromProject } = useDeleteResourceRequestHandler({
    isResourceActualModeEnabled
  });

  const { storedValue: resourceRequestsQueryVariables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const onDeleteRequestClick = useCallback(() => {
    closeRemoveRequestDialog();
    deleteResourceRequestFromProject({
      projectUri: resourceRequest.projectUri,
      resourceRequestsQueryVariables: resourceRequestsQueryVariables
        ? {
            ...resourceRequestsQueryVariables,
            projectUri: resourceRequest.projectUri
          }
        : null,
      request: resourceRequest
    });
  }, [
    closeRemoveRequestDialog,
    deleteResourceRequestFromProject,
    resourceRequest,
    resourceRequestsQueryVariables
  ]);

  return (
    <ResourceRequestDeleteActions
      showRemoveRequestDialog={showRemoveRequestDialog}
      closeRemoveRequestDialog={closeRemoveRequestDialog}
      onDeleteRequestClick={onDeleteRequestClick}
    />
  );
};

ResourceRequestPeriodDeleteDialog.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  closeRemoveRequestDialog: PropTypes.func.isRequired,
  showRemoveRequestDialog: PropTypes.bool.isRequired,
  isResourceActualModeEnabled: PropTypes.bool
};

export default ResourceRequestPeriodDeleteDialog;
