import {
  useGetMyDefaultRequestAttributeWeightsQuery,
  useSetMyDefaultRequestAttributeWeightsMutation
} from '~/types';

const useUpdateDefaultRequestAttributeWeights = () => {
  const { refetch } = useGetMyDefaultRequestAttributeWeightsQuery({
    skip: true
  });
  const [
    setWeights,
    { loading: settingDefaults }
  ] = useSetMyDefaultRequestAttributeWeightsMutation({
    update: () => {
      refetch();
    }
  });

  return {
    setWeights,
    settingDefaults
  };
};

export default useUpdateDefaultRequestAttributeWeights;
