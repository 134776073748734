import { mapEnabledFieldsToVariables } from '~/modules/common/util';
import { columns } from './hooks/requestColumns';

const columnVarColumnMap = () => {
  const {
    createdBy,
    effectiveTotal,
    investment,
    portfolio,
    risk,
    value,
    estimatedStartDate,
    estimatedDuration,
    resourceRequired,
    estimatedCostInBaseCurrency,
    estimatedCostInPortfolioCurrency
  } = columns();

  return {
    includeCreatedBy: createdBy,
    includeEffectiveTotal: effectiveTotal,
    includeInvestment: investment,
    includePortfolio: portfolio,
    includeRisk: risk,
    includeValue: value,
    includeEstimatedStartDate: estimatedStartDate,
    includeEstimatedDuration: estimatedDuration,
    includeResourceAssignmentsCount: resourceRequired,
    includeEstimatedCostInBaseCurrency: estimatedCostInBaseCurrency,
    includeEstimatedCostInPortfolioCurrency: estimatedCostInPortfolioCurrency
  };
};

export const columnArguments = `
$includeCreatedBy: Boolean!
$includeScore: Boolean!
$includeEffectiveTotal: Boolean!
$includeInvestment: Boolean!
$includeRisk: Boolean!
$includeValue: Boolean!
$includePortfolio: Boolean!
$includeEstimatedStartDate: Boolean!
$includeEstimatedDuration: Boolean!
$includeResourceAssignmentsCount: Boolean!
$includeEstimatedCostInBaseCurrency: Boolean!
$includeEstimatedCostInPortfolioCurrency: Boolean!
  `;

export const enabledFieldsToVariables = ({ columns: enabledFields }) => {
  const values = mapEnabledFieldsToVariables(
    enabledFields,
    columnVarColumnMap()
  );

  return {
    ...values,
    includeScore: Boolean(
      values.includeEffectiveTotal ||
        values.includeInvestment ||
        values.includeRisk ||
        values.includeValue
    )
  };
};
