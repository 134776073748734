import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const BULK_MARK_RESOURCE_REQUESTS_AS_SUBMITTED = gql`
  mutation bulkMarkResourceRequestsAsSubmitted2(
    $input: BulkMarkResourceRequestsAsSubmittedInput!
  ) {
    bulkMarkResourceRequestsAsSubmitted2(input: $input) {
      resourceRequests {
        id
        requestStatus
      }
    }
  }
`;

export const useBulkMarkResourceRequestsAsSubmitted = () => {
  const [bulkMarkResourceRequestsAsSubmitted] = useMutation(
    BULK_MARK_RESOURCE_REQUESTS_AS_SUBMITTED
  );

  const bulkMarkResourceRequestsAsSubmittedCallback = useCallback(
    resourceRequestIds =>
      bulkMarkResourceRequestsAsSubmitted({
        variables: {
          input: {
            resourceRequestIds
          }
        }
      }),
    [bulkMarkResourceRequestsAsSubmitted]
  );

  return {
    bulkMarkResourceRequestsAsSubmitted: bulkMarkResourceRequestsAsSubmittedCallback
  };
};

export default useBulkMarkResourceRequestsAsSubmitted;
