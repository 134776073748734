/* eslint-disable @typescript-eslint/camelcase */

import React, { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHasFeatureFlag, useIsBreakpointDown } from '~/modules/common/hooks';
import { RequestAttributeWeights, RequestAttributeWeightage } from '~/types';
import ImportanceForm from './ImportanceForm';
import { useStyles } from './useStyles';
import useUpdateDefaultRequestAttributeWeights from './useUpdateDefaultRequestAttributeWeights';

export const isDifferentThanDefault = (
  values: RequestAttributeWeights,
  defaults: RequestAttributeWeights
): boolean =>
  values.location !== defaults?.location ||
  values.division !== defaults?.division ||
  values.serviceCenter !== defaults?.serviceCenter ||
  values.costCenter !== defaults?.costCenter ||
  values.department !== defaults?.department ||
  values.employeeType !== defaults?.employeeType ||
  values.role !== defaults?.role ||
  values.skills !== defaults?.skills ||
  values.tags !== defaults?.tags ||
  values.requestedCostRate !== defaults?.requestedCostRate ||
  values.resourcePools !== defaults?.resourcePools ||
  values.preferredResources !== defaults?.preferredResources;

const ResourceRequestImportanceDialog = ({
  defaults,
  minWidth = 'sm',
  onChange,
  onClose,
  open,
  values,
  weights
}: {
  weights: RequestAttributeWeights;
  defaults: RequestAttributeWeights;
  values: {
    location: any;
    division: any;
    serviceCenter: any;
    costCenter: any;
    department: any;
    employeeType: any;
    role: any;
    skills: any;
    tags: any;
    requestedCostRate: any;
    resourcePools: any;
    preferredResources: any;
  };
  open: boolean;
  onClose: () => void;
  onChange?: (
    weights: Required<Omit<RequestAttributeWeights, '__typename'>>
  ) => Promise<void> | void;
  minWidth?: string;
}): ReactElement => {
  const classes = useStyles();
  const {
    values: formWeights,
    submitForm,
    resetForm,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: weights?.location || RequestAttributeWeightage.Normal,
      division: weights?.division || RequestAttributeWeightage.Normal,
      serviceCenter: weights?.serviceCenter || RequestAttributeWeightage.Normal,
      costCenter: weights?.costCenter || RequestAttributeWeightage.Normal,
      department: weights?.department || RequestAttributeWeightage.Normal,
      employeeType: weights?.employeeType || RequestAttributeWeightage.Normal,
      role: weights?.role || RequestAttributeWeightage.Normal,
      skills: weights?.skills || RequestAttributeWeightage.Normal,
      tags: weights?.tags || RequestAttributeWeightage.Normal,
      requestedCostRate:
        weights?.requestedCostRate || RequestAttributeWeightage.Normal,
      resourcePools: weights?.resourcePools || RequestAttributeWeightage.Normal,
      preferredResources:
        weights?.preferredResources || RequestAttributeWeightage.Normal
    },
    onSubmit: async formValues => {
      if (onChange) {
        await onChange(formValues);
        onClose();
      }
    }
  });

  const cancel = useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const isFullscreen = useIsBreakpointDown(minWidth);

  const dialogClasses = useMemo(
    () => ({
      paper: classNames({
        [classes.paperMinWidth_xs]: !isFullscreen && minWidth === 'xs',
        [classes.paperMinWidth_sm]: !isFullscreen && minWidth === 'sm',
        [classes.paperMinWidth_md]: !isFullscreen && minWidth === 'md',
        [classes.paperMinWidth_lg]: !isFullscreen && minWidth === 'lg',
        [classes.paperMinWidth_xl]: !isFullscreen && minWidth === 'xl'
      })
    }),
    [classes, minWidth, isFullscreen]
  );

  const {
    setWeights,
    settingDefaults
  } = useUpdateDefaultRequestAttributeWeights();

  const setWeightsCallback = useCallback(() => {
    if (!setWeights || settingDefaults) {
      return;
    }
    setWeights({
      variables: {
        input: formWeights
      }
    });
  }, [setWeights, settingDefaults, formWeights]);

  return (
    <Dialog
      fullScreen={isFullscreen}
      maxWidth={false}
      classes={dialogClasses}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="resource-request-importance-dialog-title"
      open={open}
    >
      <DialogTitle id="resource-request-importance-dialog-title">
        <FormattedMessage id="resourceRequestDrawerDetails.qualificationImportance" />
      </DialogTitle>
      <DialogContent>
        <ImportanceForm
          weights={formWeights}
          values={values}
          setFieldValue={setFieldValue}
          readonly={!onChange}
        />
      </DialogContent>
      <DialogActions>
        {onChange && open && isDifferentThanDefault(formWeights, defaults) && (
          <Button disabled={settingDefaults} onClick={setWeightsCallback}>
            <FormattedMessage id="resourceRequestDrawerDetails.setAsMyDefault" />
          </Button>
        )}
        <div className={classes.spacer}></div>
        {onChange ? (
          <>
            <Button onClick={cancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button onClick={submitForm} color="primary">
              <FormattedMessage id="resourceRequestDrawerDetails.setImportance" />
            </Button>
          </>
        ) : (
          <Button onClick={onClose}>
            <FormattedMessage id="button.ok" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResourceRequestImportanceDialog;
