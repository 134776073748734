import { useDeleteProgram as deleteProgram } from './program';
import { useDeleteClient as deleteClient } from './client';
import { useDeleteProject as deleteProject } from './project';
import { useDeleteVendor as deleteVendor } from './vendor';

export const objectMutations = {
  PROGRAM: deleteProgram,
  CLIENT: deleteClient,
  PROJECT: deleteProject,
  VENDOR: deleteVendor
};
