import { useCallback } from 'react';
import { SortDirection } from '~/types';
import { usePutTableSettings } from './hooks';

const invertSortDirection = dir =>
  dir === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

const useSetListSort = ({ tableKey, currentSort, currentColumns }) => {
  const [setSortSettings, { loading }] = usePutTableSettings();
  const { field, direction, customFieldUri } = currentSort || {};

  const setSortField = useCallback(
    (newField, newCustomFieldUri) => {
      const newDirection = invertSortDirection(
        field === newField && customFieldUri === newCustomFieldUri && direction
      );

      setSortSettings({
        variables: {
          tableKey,
          sort: {
            field: newField,
            direction: newDirection,
            customFieldUri: newCustomFieldUri
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          putTableSetting2: {
            __typename: 'PutTableSettingResult',
            tableSetting: {
              __typename: 'TableSetting',
              id: tableKey,
              columns: currentColumns,
              sort: {
                __typename: 'SortSetting',
                field: newField,
                direction: newDirection,
                customFieldUri: newCustomFieldUri
              }
            }
          }
        }
      });
    },
    [
      currentColumns,
      customFieldUri,
      direction,
      field,
      setSortSettings,
      tableKey
    ]
  );

  return { onSortChange: setSortField, loading };
};

export default useSetListSort;
