import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import formatters from './formatters';

const getRateColumn = rateTypeReferenceKey =>
  ({
    userReference: {
      userReference: {
        id: 'userReference',
        visible: true,
        value: <FormattedMessage id="rateTable.resource" />
      }
    },
    roleReference: {
      roleReference: {
        id: 'roleReference',
        visible: true,
        value: <FormattedMessage id="rateTable.role" />
      }
    },
    default: {
      emptyCell: {
        id: 'emptyCell',
        visible: true,
        value: ''
      }
    }
  }[rateTypeReferenceKey || 'default']);

export const buildColumns = ({ rateTypeReferenceKey }) => ({
  ...getRateColumn(rateTypeReferenceKey),
  effectiveDate: {
    id: 'effectiveDate',
    visible: true,
    value: <FormattedMessage id="rateTable.effectiveDate" />
  },
  amount: {
    id: 'billingScheduleEntries',
    visible: true,
    value: <FormattedMessage id="rateTable.billingRate" />,
    align: 'right'
  }
});

export const useListColumns = ({ rateTypeReferenceKey }) =>
  useColumns({
    columns: buildColumns({ rateTypeReferenceKey }),
    renders: formatters
  });
