import { useState, useMemo, useCallback, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useTheme } from '@material-ui/styles';
import { useMeContext } from '~/modules/me/useMeContext';
import useThemeBreakpoint from '~/modules/common/hooks/useThemeBreakpoint';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { PERIOD_SCALE_ENUM } from '../periodScale';
import { getPeriodDateRangeByScale } from './periodDates';

export const scales = [
  PERIOD_SCALE_ENUM.DAYS,
  PERIOD_SCALE_ENUM.WEEKS,
  PERIOD_SCALE_ENUM.MONTHS,
  PERIOD_SCALE_ENUM.QUARTERS,
  PERIOD_SCALE_ENUM.YEARS
];

const calculateOffset = (periodCount, anchor) => {
  if (typeof anchor === 'number') {
    if (anchor <= 0) return 0;
    if (anchor >= 1) return Math.max(0, periodCount - 1);

    return Math.floor((periodCount - 1) * anchor);
  }

  return 0;
};

export const createPeriods = (
  me,
  start,
  scale,
  periodCount,
  anchor,
  isPast,
  centerOffset = false
) => {
  const anchorOffset = calculateOffset(periodCount, anchor);
  const pastAwareStart = isPast
    ? centerOffset
      ? start.minus({ [scale]: periodCount - 5 })
      : start.minus({ [scale]: periodCount - 1 })
    : start;
  const periods = new Array(periodCount).fill({}).map((_, index) => {
    const anchorAdjustedStart = pastAwareStart.plus({
      [scale]: index - anchorOffset
    });

    return getPeriodDateRangeByScale({ me, start: anchorAdjustedStart, scale });
  });

  return periods;
};

const defaultValues = {
  date: DateTime.utc(),
  scale: PERIOD_SCALE_ENUM.MONTHS,
  periodCount: 3
};

const defaultDate = defaults =>
  mapRepliconDateToUtcObject(
    defaults && isValidDate(defaults.date) ? defaults.date : defaultValues.date
  );

export const isValidDate = date => date instanceof DateTime && date.isValid;

export const isValidScale = scale => scales.some(option => option === scale);

export const isValidPeriodCount = periodCount =>
  typeof periodCount === 'number' && isFinite(periodCount) && periodCount > 0;

export const useFixedRange = (options = {}) => {
  const {
    getPeriodCount,
    anchor,
    defaults,
    isPast = false,
    centerOffset = false
  } = options;
  const theme = useTheme();

  const breakpoint = useThemeBreakpoint(theme);

  const scaleValue =
    defaults && isValidScale(defaults.scale)
      ? defaults.scale
      : defaultValues.scale;

  const [scale, setScale] = useState(scaleValue);

  useEffect(() => setScale(scaleValue), [setScale, scaleValue]);

  const periodCountMemo = useMemo(() => {
    if (getPeriodCount) {
      const periodCount = getPeriodCount(breakpoint, scale);

      if (isValidPeriodCount(periodCount)) return periodCount;
    }

    return defaultValues.periodCount;
  }, [breakpoint, scale, getPeriodCount]);

  const [date, setDate] = useState(defaultDate(defaults));
  const me = useMeContext();
  const chartDates = useMemo(
    () =>
      createPeriods(
        me,
        date,
        scale,
        periodCountMemo,
        anchor,
        isPast,
        centerOffset
      ),
    [me, date, scale, periodCountMemo, anchor, isPast, centerOffset]
  );

  const dateRange = useMemo(
    () => ({
      startDate: chartDates[0].start,
      endDate: chartDates[chartDates.length - 1].end
    }),
    [chartDates]
  );

  const handleSetScale = useCallback(
    newValue => {
      if (isValidScale(newValue)) setScale(newValue);
    },
    [setScale]
  );

  const handleSetDate = useCallback(
    newValue => {
      if (isValidDate(newValue)) setDate(newValue);
    },
    [setDate]
  );

  const handlePrevious = useCallback(() => {
    setDate(date.minus({ [`${scale}`]: 1 }));
  }, [date, scale, setDate]);

  const handleNext = useCallback(() => {
    setDate(date.plus({ [`${scale}`]: 1 }));
  }, [date, scale, setDate]);

  const resetDate = useCallback(() => {
    setDate(defaultDate(defaults));
  }, [setDate, defaultDate, defaults]);

  return {
    scale,
    date,
    chartDates,
    dateRange,
    setScale: handleSetScale,
    setDate: handleSetDate,
    onPrevious: handlePrevious,
    onNext: handleNext,
    resetDate
  };
};

export default useFixedRange;
