import React from 'react';
import { Slide, Snackbar, SnackbarContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbarStyles } from './useStyles';

const defaultSnackBarPosition = {
  vertical: 'top',
  horizontal: 'center'
};

const ResourceUserSnackbar = ({
  open,
  message,
  snackBarPosition = defaultSnackBarPosition
}) => {
  const classes = useSnackbarStyles();

  return (
    <Snackbar
      anchorOrigin={snackBarPosition}
      open={open}
      TransitionComponent={Slide}
    >
      <SnackbarContent classes={classes} message={message} />
    </Snackbar>
  );
};

ResourceUserSnackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  snackBarPosition: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  })
};

export default ResourceUserSnackbar;
