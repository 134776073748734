import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListTable,
  computeTableColumns,
  ListTableThemeProvider,
  ListTablePropTypes
} from '~/modules/common/components/ListTable';
import {
  Variance,
  MoneyVariance
} from '~/modules/common/components/ListTable/renderers';
import name from './name';
import useProjectColumns from './useProjectColumns';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`,
    'th:last-child, & tbody tr td:last-child': {
      paddingRight: theme.spacing(1.5)
    }
  },
  tableHeaderCell: {
    height: 0,
    borderBottom: 'none',
    padding: theme.spacing(1, 2),
    '& span': {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary,
      fontWeight: 'normal',
      flexDirection: 'row-reverse'
    }
  },
  tableCell: {
    height: 0,
    borderBottom: 'none',
    padding: theme.spacing(1, 2),
    '& span': {
      margin: 0
    },
    '& p': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.primary
    },
    whiteSpace: 'normal'
  },
  tableBody: {
    backgroundColor: theme.palette.grey[100]
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const useColumnWidthstyles = makeStyles(theme => ({
  name: {
    overflowWrap: 'anywhere'
  }
}));

const renders = {
  name,
  estimatedBudgetHoursRemaining: Variance,
  estimatedBudgetCostRemaining: MoneyVariance
};

export const ProjectList = ({ projects, sort, onSortChange, isLoading }) => {
  const providerClasses = useListTableThemeProviderStyles();
  const columnWidthClass = useColumnWidthstyles();
  const { projectColumnNames, projectColumnGroup } = useProjectColumns();

  const { headers, columns } = computeTableColumns({
    columnSpec: projectColumnGroup,
    renders
  })({
    enabledFields: projectColumnNames,
    columnClasses: columnWidthClass
  });

  return (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTable
        columns={columns}
        headers={headers}
        records={projects}
        sort={sort}
        onSortChange={onSortChange}
        isLoading={isLoading}
        variant="table"
      />
    </ListTableThemeProvider>
  );
};

ProjectList.propTypes = {
  projects: PropTypes.array,
  onSortChange: PropTypes.func,
  sort: ListTablePropTypes.sort,
  isLoading: PropTypes.bool
};
