import { makeStyles } from '@material-ui/core/styles';

export const useDialogStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.breakpoints.values.sm
  }
}));

const useStyles = makeStyles(() => ({
  allocationSummary: {
    fontWeight: 'normal'
  },
  roleName: {},
  allocationDates: {}
}));

export default useStyles;
