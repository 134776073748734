import React from 'react';
import ResourceRequestsRedirect from './ResourceRequestsRedirect';
import ResourcingBadgeIcon from './ResourcingBadgeIcon';

export const hasPermission = ({ permissions }) =>
  Boolean(
    permissions.find(
      p =>
        p.permissionActionUri ===
        'urn:replicon:resource-assignment-action:view-resource-assignments'
    )
  );

const resourcingRoute = ({ intl, me, isMobile }) => {
  return {
    path: '/resourcing',
    icon: () => <ResourcingBadgeIcon />,
    isSideBarRoute: true,
    hidden: isMobile,
    showDivider: true,
    component: ResourceRequestsRedirect,
    title: () => intl.formatMessage({ id: 'routes.resourceAllocation' }),
    hasPermission: hasPermission(me)
  };
};

export default resourcingRoute;
