import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { NoValue } from '~/modules/common/components';
import { FocusableSpan } from '~/modules/common/components/FocusableSpan';

const useNoValueStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.primary
  }
}));

const GanttColumn = ({
  me,
  className,
  classes,
  item,
  assignEnabled = false,
  assignLabel,
  toolTipClassKey
}) => {
  const noValueClasses = useNoValueStyles();

  const content = item ? (
    item.displayText
  ) : !assignEnabled ? (
    <NoValue classes={noValueClasses} />
  ) : (
    assignLabel
  );

  return (
    <div
      className={classNames(
        toolTipClassKey,
        item
          ? classes.gantColumnName
          : assignEnabled
          ? me.isRolledUpTaskEstimateCalculationMethodEnabled
            ? classes.ganttAssignToLabel2
            : classes.ganttAssignToLabel
          : noValueClasses.root
      )}
    >
      {assignEnabled ? (
        <FocusableSpan
          className={className}
          aria-label={(item && item.displayText) || assignLabel}
        >
          {content}
        </FocusableSpan>
      ) : (
        <span
          className={className}
          aria-label={(item && item.displayText) || assignLabel}
        >
          {content}
        </span>
      )}
    </div>
  );
};

GanttColumn.propTypes = {
  me: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
  item: PropTypes.shape({
    displayText: PropTypes.string.isRequired
  }),
  toolTipClassKey: PropTypes.string.isRequired,
  assignLabel: PropTypes.string.isRequired,
  assignEnabled: PropTypes.bool
};

export default GanttColumn;
