import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TaskEstimateRowContext } from './TaskEstimateRowContext';

const TaskEstimateRowContextProvider = ({
  canEditTasks,
  dialogProps,
  resourceEstimate,
  task,
  children
}) => {
  const value = useMemo(
    () => ({
      canEditTasks,
      dialogProps,
      resourceEstimate,
      task
    }),
    [canEditTasks, dialogProps, resourceEstimate, task]
  );

  return (
    <TaskEstimateRowContext.Provider value={value}>
      {children}
    </TaskEstimateRowContext.Provider>
  );
};

TaskEstimateRowContextProvider.propTypes = {
  children: PropTypes.node,
  canEditTasks: PropTypes.bool,
  dialogProps: PropTypes.object,
  resourceEstimate: PropTypes.object,
  task: PropTypes.object
};

export default TaskEstimateRowContextProvider;
