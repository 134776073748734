import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    zIndex: 6,
    top: theme.spacing(15),
    height: theme.spacing(3),
    border: 'none'
  },
  itemContainer: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5, 0, 5.5),
    height: theme.spacing(3)
  }
}));

export const useLeftComponentStyles = makeStyles(theme => ({
  resourceHeader: {},
  roleHeader: {
    flexDirection: 'row-reverse'
  }
}));

export default useStyles;
