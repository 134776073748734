import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { DialogActions } from '@material-ui/core';
import { CancelButton, LoadingButton } from '~/modules/common/components';
import { hasErrors } from '~/util';

export const DialogButtonActions = ({
  onClose,
  addButtonDataQeId,
  cancelButtonDataQeId,
  primaryButtonMessage,
  disableOnError = true,
  formik: { handleSubmit, handleReset, isSubmitting, errors },
  onCancel
}) => {
  const onCancelClick = useCallback(() => {
    if (typeof onCancel === 'function') onCancel();
    handleReset();
    onClose();
  }, [handleReset, onCancel, onClose]);

  const disabled = isSubmitting || (disableOnError && hasErrors(errors));

  return (
    <DialogActions>
      <CancelButton
        onClick={onCancelClick}
        disabled={isSubmitting}
        data-qe-id={cancelButtonDataQeId}
      />
      <LoadingButton
        color="primary"
        onClick={handleSubmit}
        disabled={disabled}
        isLoading={isSubmitting}
        data-qe-id={addButtonDataQeId}
      >
        {primaryButtonMessage}
      </LoadingButton>
    </DialogActions>
  );
};

DialogButtonActions.propTypes = {
  onClose: PropTypes.func,
  formik: PropTypes.object,
  cancelButtonDataQeId: PropTypes.string,
  addButtonDataQeId: PropTypes.string,
  disableOnError: PropTypes.bool,
  primaryButtonMessage: PropTypes.node,
  onCancel: PropTypes.func
};

export default connect(DialogButtonActions);
