import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateField } from '~/modules/common/components';
import {
  getLuxonJsDateFormatFromMe,
  toRepliconDate
} from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  BillingPlanFrequencyDropdown,
  DayOfMonthDropdown,
  DayOfWeekDropdown,
  SemiMonthlyDaysDropdown
} from '~/modules/projects/project/common/components';
import { BILLING_PLAN_FREQUENCY_ENUM } from '~/modules/projects/project/common/enums';
import { propertiesToRelativeDateMap } from './util';

const useStyles = makeStyles(theme => ({
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    display: 'inline',
    maxWidth: 170,
    '& .MuiFormControl-root .MuiFilledInput-root': {
      borderTopLeftRadius: 0
    }
  },
  frequency: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    whiteSpace: 'nowrap'
  },
  prefix: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    width: ({ isMobile }) => (isMobile ? 20 : 60),
    whiteSpace: 'break-spaces',
    lineHeight: '1.22em',
    flexGrow: 0,
    flexShrink: 0,
    display: 'inline-flex',
    backgroundColor: '#e9e9e9',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid #00000061`
  }
}));

export const FrequencyEditor = ({
  column: { setFieldValue, options: frequencies, label, isMobile },
  field,
  record,
  index,
  classes: classesOverride
}) => {
  const me = useMeContext();
  const classes = useStyles({ classes: classesOverride, isMobile });
  const columnId = 'urn:replicon:script-key:parameter:frequency-formatted';
  const relativeDateColumnId =
    'urn:replicon:script-key:parameter:relative-date';
  const { frequency, dayOfWeek, dayOfMonth } = record[columnId] || {
    frequency: 'Daily'
  };

  const relativeDate = record[relativeDateColumnId];
  const frequencyChangeHandler = useCallback(
    value => {
      setFieldValue(`scripts[${index}].${field}`, value.id);
      setFieldValue(`scripts[${index}].${columnId}.frequency`, value.id);

      const getRelativeDate = propertiesToRelativeDateMap[value.id];

      getRelativeDate &&
        setFieldValue(
          `scripts[${index}].${relativeDateColumnId}`,
          getRelativeDate()
        );

      value.id === 'Weekly'
        ? setFieldValue(`scripts[${index}].${columnId}.dayOfWeek`, 'MONDAY')
        : value.id === 'Monthly' || value.id === 'Semi-Monthly'
        ? setFieldValue(`scripts[${index}].${columnId}.dayOfMonth`, 1)
        : setFieldValue(
            `scripts[${index}].${relativeDateColumnId}`,
            toRepliconDate(DateTime.local())
          );
    },
    [setFieldValue, index, field]
  );

  const dayOfWeekChangeHandler = useCallback(
    value => {
      const getRelativeDate = propertiesToRelativeDateMap.Weekly;

      setFieldValue(
        `scripts[${index}].${relativeDateColumnId}`,
        getRelativeDate(value.id)
      );
      setFieldValue(`scripts[${index}].${columnId}.dayOfWeek`, value.id);
    },
    [setFieldValue, index, relativeDateColumnId]
  );

  const dayOfMonthChangeHandler = useCallback(
    value => {
      const getRelativeDate = propertiesToRelativeDateMap.Monthly;

      setFieldValue(
        `scripts[${index}].${relativeDateColumnId}`,
        getRelativeDate(value.id)
      );
      setFieldValue(`scripts[${index}].${columnId}.dayOfMonth`, value.id);
    },
    [setFieldValue, index, columnId]
  );

  const relativeDateChangeHandler = useCallback(
    value => {
      setFieldValue(`scripts[${index}].${relativeDateColumnId}`, value);
    },
    [setFieldValue, index]
  );

  const dateFormat =
    frequency === BILLING_PLAN_FREQUENCY_ENUM.BIWEEKLY
      ? `EEEE (${getLuxonJsDateFormatFromMe(me)})`
      : 'MMM d';

  return (
    <div className={classes.frequency}>
      <BillingPlanFrequencyDropdown
        frequencies={frequencies}
        value={frequency}
        onChange={frequencyChangeHandler}
        label={label}
        hiddenLabel={!isMobile}
      />

      {frequency === BILLING_PLAN_FREQUENCY_ENUM.WEEKLY && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billPlan.on" />
          </Typography>
          <DayOfWeekDropdown
            value={dayOfWeek}
            onChange={dayOfWeekChangeHandler}
            hiddenLabel={!isMobile}
          />
        </>
      )}
      {frequency === BILLING_PLAN_FREQUENCY_ENUM.MONTHLY && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billPlan.onThe" />
          </Typography>
          <DayOfMonthDropdown
            value={dayOfMonth}
            onChange={dayOfMonthChangeHandler}
            hiddenLabel={!isMobile}
          />
        </>
      )}
      {frequency === BILLING_PLAN_FREQUENCY_ENUM.SEMIMONTHLY && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billPlan.onThe" />
          </Typography>
          <SemiMonthlyDaysDropdown
            value={dayOfMonth}
            onChange={dayOfMonthChangeHandler}
            hiddenLabel={!isMobile}
          />
        </>
      )}
      {(frequency === BILLING_PLAN_FREQUENCY_ENUM.YEARLY ||
        frequency === BILLING_PLAN_FREQUENCY_ENUM.QUARTERLY ||
        frequency === BILLING_PLAN_FREQUENCY_ENUM.BIWEEKLY) && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage
              id={
                frequency === BILLING_PLAN_FREQUENCY_ENUM.YEARLY
                  ? 'billPlan.on'
                  : 'billPlan.from'
              }
            />
          </Typography>
          <div className={classes.date}>
            <DateField
              clearable={false}
              value={relativeDate}
              editable
              useDateFieldStyles={false}
              format={dateFormat}
              onChange={relativeDateChangeHandler}
              hiddenLabel={!isMobile}
            />
          </div>
        </>
      )}
    </div>
  );
};

FrequencyEditor.propTypes = {
  column: PropTypes.object.isRequired,
  index: PropTypes.number,
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default FrequencyEditor;
