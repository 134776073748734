import {
  getNextPeriodStartDateByScale,
  getPeriodDateRangeByScale,
  getRangeEndDateByScale
} from '~/modules/common/charts/timeline/calculations/periodDates';
import { useMeContext } from '~/modules/me/useMeContext';

export const getPeriodsByScale = ({ me, rangeStart, rangeEnd, scale }) => {
  if (!rangeStart && !rangeEnd) return { displayPeriods: [] };

  let start = rangeStart;

  const rangeEndDate = getRangeEndDateByScale({ me, end: rangeEnd, scale });
  const periods = [];

  while (start <= rangeEndDate) {
    periods.push(getPeriodDateRangeByScale({ me, start, scale }));
    start = getNextPeriodStartDateByScale({ me, start, scale });
  }

  return {
    displayPeriods: periods
  };
};

export const usePeriodsByScale = ({ rangeStart, rangeEnd, scale }) => {
  const me = useMeContext();

  return getPeriodsByScale({ me, rangeStart, rangeEnd, scale });
};

export default usePeriodsByScale;
