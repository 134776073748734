import React from 'react';
import { FormattedMessage } from 'react-intl';

export const getQuickFilterSummaryTabs = ({
  statusOptions,
  voidedBill
} = {}) => ({
  allItems: [
    {
      key: 'all-transaction',
      label: <FormattedMessage id="billingTransactionList.allBills" />,
      dataQeId: 'AllTransactions'
    }
  ],
  transactionPhases: statusOptions.map(statusOption => ({
    key: statusOption.id,
    label: statusOption.displayText,
    color: statusOption.color.dark,
    value: statusOption.totals,
    dataQeId: statusOption.dataQeId
  })),
  alternativeItems: voidedBill
    ? [
        {
          key: voidedBill.id,
          label: voidedBill.displayText,
          color: voidedBill.color.dark,
          value: voidedBill.totals,
          dataQeId: voidedBill.dataQeId
        }
      ]
    : null
});
