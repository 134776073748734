import { getScheduleTotals } from '~/modules/resourcing/common/util';

export const getResourceRequestTotal = ({
  scheduleRules,
  quantity,
  roleRate,
  currency,
  totalHours,
  exchangeRateValues,
  contextCurrency
}) => {
  const exchangeRate =
    contextCurrency && exchangeRateValues
      ? Object.values(exchangeRateValues)
          .filter(e => e)
          .find(r => r.currencyId === contextCurrency.id)
      : null;

  const conversionRate = exchangeRate ? exchangeRate.rate || 1 : 1;
  const costCurrency =
    exchangeRate && exchangeRate.rate ? contextCurrency : currency;
  const { totalHours: requestTotalHours, totalCost } = getScheduleTotals({
    scheduleRules,
    quantity,
    rate: conversionRate * roleRate,
    currencyUri: costCurrency && costCurrency.id,
    currencySymbol: costCurrency && costCurrency.displayText
  });

  return {
    resourceRequestTotalHours: Number(
      (totalHours || requestTotalHours || 0).toFixed(4)
    ),
    resourceRequestTotalCost:
      totalHours !== undefined && totalHours !== null
        ? {
            ...totalCost,
            amount: totalHours * conversionRate * roleRate
          }
        : totalCost
  };
};
