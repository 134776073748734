import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const DELETE_TASK_RESOURCE_USER_ALLOCATION = gql`
  mutation deleteTaskResourceUserAllocation(
    $input: DeleteTaskResourceUserAllocationInput!
  ) {
    deleteTaskResourceUserAllocation(input: $input) {
      taskAllocationId
    }
  }
`;

export const useDeleteTaskResourceUserAllocation = ({ updateCache } = {}) => {
  const [deleteTaskResourceUserAllocation, { data, loading }] = useMutation(
    DELETE_TASK_RESOURCE_USER_ALLOCATION
  );

  const deleteTaskResourceUserAllocationCallback = useCallback(
    ({ taskUri, projectUri, taskAllocationId }) =>
      deleteTaskResourceUserAllocation({
        variables: {
          input: {
            taskUri,
            projectUri,
            taskAllocationId
          }
        },
        update: updateCache
      }),
    [deleteTaskResourceUserAllocation, updateCache]
  );

  return {
    deleteTaskResourceUserAllocation: deleteTaskResourceUserAllocationCallback,
    data,
    loading
  };
};

export default useDeleteTaskResourceUserAllocation;
