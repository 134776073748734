import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles, alpha } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import ButtonBase from '@material-ui/core/ButtonBase';

import { useMeContext } from '~/modules/me';
import SummaryTabLabel from './SummaryTabLabel';
import { createTabWidthStyles } from './summaryFilterUtil';

const useStyles = isPsaPrpRenameProjectStatus2Enabled =>
  makeStyles(theme => ({
    root: props => {
      const backgroundColor = alpha(props.summaryItem.color, 0.2);

      return {
        flexGrow: 0,
        flexShrink: 0,
        padding: theme.spacing(0, 1),
        ...createTabWidthStyles(theme, isPsaPrpRenameProjectStatus2Enabled),
        transition: theme.transitions.create(),
        textTransform: 'uppercase',
        color: theme.palette.getContrastText(backgroundColor),
        backgroundColor,
        '&:hover': {
          backgroundColor: alpha(props.summaryItem.color, 0.25)
        }
      };
    },
    selected: props => ({
      color: theme.palette.getContrastText(props.summaryItem.color),
      backgroundColor: props.summaryItem.color,
      '&:hover': {
        backgroundColor: darken(props.summaryItem.color, 0.05)
      }
    }),
    first: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    last: {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    }
  }));

const SummaryItem = ({
  isValueLoading,
  summaryItem,
  showValue,
  className,
  component,
  selected,
  onClick,
  first,
  last
}) => {
  const {
    featureFlags: { isPsaPrpRenameProjectStatus2Enabled }
  } = useMeContext();

  const classes = useStyles(isPsaPrpRenameProjectStatus2Enabled)({
    summaryItem
  });

  const handleClick = useCallback(
    event => {
      if (onClick) {
        onClick(event, summaryItem.key);
      }
    },
    [summaryItem, onClick]
  );

  return (
    <ButtonBase
      className={classNames(classes.root, className, {
        [classes.important]: summaryItem.important,
        [classes.selected]: selected,
        [classes.first]: first,
        [classes.last]: last
      })}
      onClick={handleClick}
      role="tab"
      aria-selected={selected}
      component={component}
    >
      <SummaryTabLabel
        showValue={showValue}
        label={summaryItem.label}
        value={summaryItem.value}
        isValueLoading={isValueLoading}
        dataQeId={summaryItem.dataQeId}
      />
    </ButtonBase>
  );
};

SummaryItem.propTypes = {
  className: PropTypes.string,
  summaryItem: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  onClick: PropTypes.func,
  component: PropTypes.elementType,
  showValue: PropTypes.bool,
  isValueLoading: PropTypes.bool
};

export default SummaryItem;
