import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  container: {
    height: 300,
    width: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(23),
    justifyContent: 'center',
    gap: theme.spacing(1)
  },
  loadingText: {
    ...theme.typography.body1,
    color: theme.palette.text.primary
  }
}));

export const RescheduleTaskDialogContentLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.loadingContainer}>
        <CircularProgress size={24} />
        <span className={classes.loadingText}>
          <FormattedMessage id="rescheduleTaskDialog.onSubmitLoadingMessage" />
        </span>
      </div>
    </div>
  );
};

export default RescheduleTaskDialogContentLoading;
