import { CardContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHourChartConfig } from '~/modules/common/charts/dashboard/config';
import { BarsChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import BudgetHoursChartLegends from '../BudgetHoursChartLegends';
import { useProjectBudgetHoursChartData } from '../hooks/useProjectBudgetHoursChartData';
import { useBudgetHoursSummary } from '../hooks/useBudgetHoursSummary';
import BudgetChart from './BudgetChart';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  }
}));

export const BudgetHoursSummaryChart = ({
  projectDetails,
  projectBudgetHoursTitleId
}) => {
  const classes = useStyles();

  const { actualBarColor, actualLabelColor } = useHourChartConfig();
  const { projectId } = projectDetails;
  const { projectHoursSummary, isSummaryLoading } = useBudgetHoursSummary({
    projectId
  });

  const {
    estimatedDataPoints,
    actualDataPoints
  } = useProjectBudgetHoursChartData(projectHoursSummary, isSummaryLoading);

  return (
    <CardContent>
      {isSummaryLoading ? (
        <BarsChartLoading showLegends={false} />
      ) : (
        <div className={classes.content}>
          <BudgetChart
            titleId={projectBudgetHoursTitleId}
            estimatedDataPoints={estimatedDataPoints}
            actualDataPoints={actualDataPoints}
            actualBarColor={actualBarColor}
            actualLabelColor={actualLabelColor}
            gradientId="gradientForBudgetHours"
            maxBudgetLimit={projectHoursSummary.budgetHours}
          />
          <BudgetHoursChartLegends
            actualBarColor={actualBarColor}
            projectHoursSummary={projectHoursSummary}
          />
        </div>
      )}
    </CardContent>
  );
};

BudgetHoursSummaryChart.propTypes = {
  projectDetails: PropTypes.object,
  projectBudgetHoursTitleId: PropTypes.string
};

export default BudgetHoursSummaryChart;
