import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProposeAllIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 18.555,14.3766875 12,20.909890625 V 23.822 l 8,-8 z M 4,16 v 2 h 6 v -2 z" />
      <path d="M 20,10 12,2 V 6 H 4 v 8 h 8 v 4 z" />
    </>
  </SvgIcon>
));

export default ProposeAllIcon;
