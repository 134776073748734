import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { useProjectManagers } from '~/modules/common/hooks';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import FacetDetailField from '../../FacetDetailField';
import UserItem from '../components/UserACItem';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';

const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : (
    <UserItem option={option} />
  );

export const projectManagerLabel = (
  <FormattedMessage id="search.projectManager" />
);

const getOptionSelected = (option, value) => option.id === value.id;

export const ProjectManagerFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const { formatMessage } = useIntl();
  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );
  const [searchTerm, setSearchTerm] = useState('');

  const onValueChange = useCallback(
    selectedOptions => onChange({ selectedOptions, setSelected }),
    [setSelected]
  );

  const classes = useStyles();

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const { options: projectManagers, isLoading, hasMore } = useProjectManagers({
    searchTerm
  });

  const options = (!isLoading && projectManagers) || [];

  return (
    <FacetDetailField label={projectManagerLabel} className={className}>
      <SimpleAutocomplete
        options={options}
        variant="standard"
        loading={isLoading}
        onInputChange={onInputChange}
        getOptionDisabled={getOptionDisabled}
        inputLabel={formatMessage({ id: 'search.projectManager' })}
        hiddenLabel
        noOptionsText={formatMessage({ id: 'project.noProjectManager' })}
        onChange={onValueChange}
        fullWidth
        value={selected}
        optionPropText="label"
        classes={classes}
        multiple
        renderOption={renderOption}
        hasMore={hasMore}
        optionTypeText={formatMessage({ id: 'moreOptions.projectManagers' })}
        getOptionSelected={getOptionSelected}
      />
    </FacetDetailField>
  );
};

ProjectManagerFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ProjectManagerFacetDetails;
