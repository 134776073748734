import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { NumberInput } from '~/modules/common/components';

export const NumericEditor = ({
  column: { id: columnId, setFieldValue, isMobile, label },
  field,
  index,
  record
}) => {
  const onChangeHandler = useCallback(
    e => setFieldValue(`scripts[${index}].${columnId}`, e.target.value),
    [columnId, index, setFieldValue]
  );

  return (
    <NumberInput
      variant="filled"
      onValueChange={onChangeHandler}
      value={record[field]}
      fullWidth={isMobile}
      hiddenLabel={!isMobile}
      label={label}
    />
  );
};

NumericEditor.propTypes = {
  column: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number
};

export default NumericEditor;
