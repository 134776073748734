import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { deepPure } from '~/util';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { convertDurationToHours } from '~/modules/common/util';
import { TimelineBlock } from '~/modules/resourcing/common/chart/components';
import {
  getDayViewRoundedHours,
  getDayViewRoundedPercentage
} from '~/modules/resourcing/common/util/scheduleUtil';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import ResourceUserPeriodSummaryTooltip from '~/modules/resourcing/common/components/ResourceUserPeriodSummaryTooltip/ResourceUserPeriodSummaryTooltip';
import useStyles from './useStyles';

const { allocated, overAllocated } = theme.palette.resourceAllocation;

const getLoadingColor = ({ load }) =>
  load > 100 ? overAllocated : alpha(allocated, Math.max(0, load / 100));

export const ResourceUserPeriodSummaryBlock = ({
  allocatedDuration,
  chartStartDate,
  endDate,
  holidayDuration,
  holidays,
  isAllocationsLazyLoaded,
  resourceAllocations,
  resourceId,
  scale,
  showToolTip = true,
  startDate,
  scheduledDuration,
  timeOffDuration,
  timeOffs,
  ToolTipComponent
}) => {
  const [isHover, setIsHover] = useState(false);
  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const handleMouseEnter = () => setIsHover(true);
  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const handleMouseLeave = () => setIsHover(false);

  const { dynamicPosition } = useMemo(
    () =>
      getDynamicPositionAttributes({
        chartStartDate,
        start: startDate,
        end: endDate,
        scale,
        isPsaRmpUserSchedulePerfFixEnabled: true
      }),
    [startDate, endDate, chartStartDate, scale]
  );

  const { scheduledHours, totalAllocatedHours, allocatedHours } = useMemo(
    () => ({
      scheduledHours: convertDurationToHours(scheduledDuration),
      allocatedHours: convertDurationToHours(allocatedDuration),
      totalAllocatedHours:
        convertDurationToHours(allocatedDuration) +
        convertDurationToHours(holidayDuration) +
        convertDurationToHours(timeOffDuration)
    }),
    [scheduledDuration, allocatedDuration, holidayDuration, timeOffDuration]
  );

  const loading = (totalAllocatedHours * 100) / scheduledHours;
  const percentage = getDayViewRoundedPercentage({
    percentage: loading,
    scale
  });

  const totalHours = getDayViewRoundedHours({
    hours: totalAllocatedHours,
    scale
  });

  const classes = useStyles();

  const load = allocatedHours > 0 ? loading : 0;

  const backgroundColor = getLoadingColor({ load });

  // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
  const dynamicStyle = {
    ...dynamicPosition,
    backgroundColor: isHover ? darken(backgroundColor, 0.1) : backgroundColor,
    borderRight: load > 0 ? `1px solid ${theme.palette.grey[500]}` : `none`
  };

  const UserPeriodSummaryToolTipComponent =
    ToolTipComponent || ResourceUserPeriodSummaryTooltip;

  return (
    <>
      {showToolTip && (
        <UserPeriodSummaryToolTipComponent
          resourceAllocations={resourceAllocations}
          holidays={holidays}
          timeOffs={timeOffs}
          startDate={startDate}
          endDate={endDate}
          totalAllocatedHours={totalAllocatedHours}
          scheduledHours={scheduledHours}
          resourceId={resourceId}
          isAllocationsLazyLoaded={isAllocationsLazyLoaded}
        >
          <div
            className={classes.root}
            style={dynamicStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <TimelineBlock
              hours={totalHours}
              percentage={percentage}
              showPercentage={scheduledHours > 0}
            />
          </div>
        </UserPeriodSummaryToolTipComponent>
      )}
      {!showToolTip && (
        <div className={classes.root} style={dynamicStyle}>
          <TimelineBlock
            hours={totalHours}
            percentage={percentage}
            showPercentage={scheduledHours > 0}
          />
        </div>
      )}
    </>
  );
};

ResourceUserPeriodSummaryBlock.propTypes = {
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  allocatedDuration: PropTypes.object.isRequired,
  holidayDuration: PropTypes.object.isRequired,
  scheduledDuration: PropTypes.object.isRequired,
  timeOffDuration: PropTypes.object.isRequired,
  resourceAllocations: PropTypes.array,
  holidays: PropTypes.array,
  timeOffs: PropTypes.array,
  showToolTip: PropTypes.bool,
  resourceId: PropTypes.string.isRequired,
  isAllocationsLazyLoaded: PropTypes.bool.isRequired,
  ToolTipComponent: PropTypes.func
};

export default deepPure(ResourceUserPeriodSummaryBlock);
