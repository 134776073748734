/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { OVERBUDGET_OPTION_KEYS } from './overBudgetOptionKeys';

const mapOptions = options => options.map(({ id }) => ({ key: id, value: id }));

const matchOption = (options, term) => {
  const option = options.find(c => c.key.toLowerCase() === term.toLowerCase());

  if (option) {
    return option;
  }

  return null;
};

export const getOverbudgetOptions = showCostOverBudgetOptions =>
  [
    {
      id: OVERBUDGET_OPTION_KEYS.HOURS_OVERBUDGET
    },
    showCostOverBudgetOptions && { id: OVERBUDGET_OPTION_KEYS.COST_OVERBUDGET },
    showCostOverBudgetOptions && {
      id: OVERBUDGET_OPTION_KEYS.OVERBUDGET
    },
    {
      id: OVERBUDGET_OPTION_KEYS.COMPLETIONDATE_OVERDUE
    }
  ].filter(x => x);

const withCriteriaProvider = (tag, context) => BaseComponent => props => {
  const {
    searchCriteria: { criterions = {} } = {},
    showCostOverBudgetOptions
  } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);

  const value = useMemo(
    () => ({
      options: mapOptions(getOverbudgetOptions(showCostOverBudgetOptions)),
      matchOption,
      selected,
      setSelected
    }),
    [selected, showCostOverBudgetOptions]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
