import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { orderBy } from 'lodash';
import { ScriptParamsEditorTable } from '~/modules/projects/project/ScriptParamsEditableCard/components';
import { RevenuePolicyDropdown } from './components';
import RevenueRecognitionWarning from './RevenueRecognitionWarning';

const useStyles = makeStyles(theme => ({
  description: {
    padding: theme.spacing(0, 0, 3, 0),
    fontSize: theme.typography.body2.fontSize
  }
}));

export const RevenueRecognitionEditable = ({
  scriptId,
  description,
  parameters,
  scripts,
  setFieldValue,
  revenuePolicies,
  errors,
  customEditors,
  isMobile,
  asOfCloseDate,
  recognizedRevenueSummary
}) => {
  const classes = useStyles();
  const contractValue = useMemo(
    () =>
      (parameters || []).filter(
        x => x.id === 'urn:replicon:script-key:parameter:total-contract-value'
      ),
    [parameters]
  );

  const [showWarning, setShowWarning] = useState(false);

  const onChangeHandler = useCallback(
    val => {
      const newScript = val
        ? val.parameters.reduce(
            (retVal, current) => ({
              ...retVal,
              [current.id]: current.defaultValue
            }),
            {}
          )
        : null;

      setFieldValue('id', val ? val.script.id : null);
      setFieldValue('displayText', val ? val.script.name : null);
      setFieldValue('description', val ? val.script.description : null);
      setFieldValue(
        'parameters',
        val ? orderBy(val.parameters, ['sortIndex'], ['asc']) : []
      );
      setFieldValue('scripts', val ? [newScript] : []);
      if (parameters.length > 0) setShowWarning(true);
    },
    [setFieldValue, setShowWarning, parameters]
  );

  const onShowWarning = useCallback(val => {
    setShowWarning(val);
  });
  const selectedPolicy = useMemo(
    () => revenuePolicies.find(x => x.id === scriptId),
    [revenuePolicies, scriptId]
  );

  return (
    <>
      <RevenuePolicyDropdown
        selectedPolicy={selectedPolicy}
        revenuePolicies={revenuePolicies}
        onChange={onChangeHandler}
      />
      {description ? (
        <Typography className={classes.description} component="div">
          {description}
        </Typography>
      ) : null}
      {scriptId ? (
        <ScriptParamsEditorTable
          editable
          parameters={parameters}
          scripts={scripts}
          setFieldValue={setFieldValue}
          canDeleteRow={false}
          errors={errors}
          customEditors={customEditors}
          isMobile={isMobile}
          onShowWarning={onShowWarning}
        />
      ) : null}
      <RevenueRecognitionWarning
        includesContractValue={contractValue.length > 0}
        showWarning={showWarning}
        asOfCloseDate={asOfCloseDate}
        recognizedRevenueSummary={recognizedRevenueSummary}
      />
    </>
  );
};

RevenueRecognitionEditable.propTypes = {
  scriptId: PropTypes.string,
  description: PropTypes.string,
  parameters: PropTypes.array,
  scripts: PropTypes.array,
  setFieldValue: PropTypes.func,
  revenuePolicies: PropTypes.array,
  errors: PropTypes.object,
  customEditors: PropTypes.object,
  isMobile: PropTypes.bool,
  asOfCloseDate: PropTypes.object,
  recognizedRevenueSummary: PropTypes.object
};

export default RevenueRecognitionEditable;
