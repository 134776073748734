import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ListItemText } from '@material-ui/core';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const ResourcePoolSelect = ({
  resourcePools = [],
  onChange,
  loading,
  options,
  hasMore,
  optionTypeText,
  handleInputChange,
  label,
  hasError,
  helperText,
  alwaysShowHelperText
}) => {
  const { formatMessage } = useIntl();
  const optionText = {
    loading: formatMessage({ id: 'groupsDropdown.loading' }),
    noOptions: formatMessage({
      id: 'resourcePoolsDropdown.noResourcePools'
    })
  };

  const renderOption = useCallback(option => {
    if (option.id === MORE_AVAILABLE_OPTION_ID) {
      return option.displayText;
    }

    return <ListItemText primary={option.displayText} />;
  }, []);

  const value = resourcePools || [];

  return (
    <SimpleAutocomplete
      dataQeId="ResourcePoolsDropdown"
      loading={loading}
      options={options}
      size="small"
      loadingText={optionText.loading}
      noOptionsText={optionText.noOptions}
      fullWidth
      value={value}
      multiple
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      onInputChange={handleInputChange}
      optionPropText="displayText"
      inputLabel={label}
      getOptionDisabled={getOptionDisabled}
      hasMore={hasMore}
      optionTypeText={optionTypeText}
      hasError={hasError}
      renderOption={renderOption}
      helperText={helperText}
      alwaysShowHelperText={alwaysShowHelperText}
      filterSelectedOptions
    />
  );
};

ResourcePoolSelect.propTypes = {
  resourcePools: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool,
  optionTypeText: PropTypes.string,
  handleInputChange: PropTypes.func,
  label: PropTypes.object,
  hasError: PropTypes.bool,
  helperText: PropTypes.any,
  alwaysShowHelperText: PropTypes.bool
};

export default ResourcePoolSelect;
