import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Typography, makeStyles, Tooltip } from '@material-ui/core';
import { NoDataItem } from '~/modules/common/components';
import TagsPropTypes from '../../propTypes';
import TagsCardContentSkeleton from './TagsCardContentSkeleton';

const useTagStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '25%',
    minWidth: '25%',
    padding: theme.spacing(1)
  },
  tagLabel: {
    color: theme.palette.text.secondary
  },
  tagValue: {}
}));

export const Tag = ({ tag, classes }) => {
  const tagClasses = useTagStyles();

  return (
    <div className={classNames(tagClasses.root, classes.tagItem)}>
      <Tooltip title={tag.tag.name}>
        <Typography
          noWrap
          variant="caption"
          className={classNames(tagClasses.tagLabel, classes.tagLabel)}
        >
          {tag.tag.name}
        </Typography>
      </Tooltip>
      <Tooltip title={tag.value.value}>
        <Typography
          noWrap
          className={classNames(tagClasses.tagValue, classes.tagValue)}
        >
          {tag.value.value}
        </Typography>
      </Tooltip>
    </div>
  );
};

Tag.propTypes = {
  classes: PropTypes.object,
  tag: TagsPropTypes.tag
};

const useTagsCardContentStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

const TagsCardContentReadOnly = ({ tags, loading, classes }) => {
  const containerClasses = useTagsCardContentStyles();

  const tagSkeletonClasses = useMemo(
    () => ({
      tagSkeletonContainer: classes.tagSkeletonContainer,
      tagSkeletonItem: classes.tagSkeletonItem
    }),
    [classes.tagSkeletonContainer, classes.tagSkeletonItem]
  );

  const noDataClasses = useMemo(
    () => ({
      root: classes.noTags
    }),
    [classes.noTags]
  );

  if (loading) {
    return <TagsCardContentSkeleton classes={tagSkeletonClasses} />;
  }

  return (
    <div className={classNames(containerClasses.root, classes.readOnlyCard)}>
      {(tags || []).length === 0 && (
        <NoDataItem classes={noDataClasses}>
          <FormattedMessage id="tags.noTags" />
        </NoDataItem>
      )}
      {(tags || []).map(tag => (
        <Tag classes={classes} tag={tag} key={tag.id} />
      ))}
    </div>
  );
};

TagsCardContentReadOnly.propTypes = {
  classes: PropTypes.object,
  tags: TagsPropTypes.tags,
  loading: PropTypes.bool
};

export default TagsCardContentReadOnly;
