import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Alert from '@material-ui/lab/Alert';

const noResourceRequestCreatedInfo = (
  <FormattedMessage id="rescheduleProject.noResourceRequests" />
);

const useStyles = makeStyles(theme => ({
  message: {
    padding: 0
  }
}));

export const ResourceRequestsInfo = ({
  resourceRequestsStatusCountSummary
}) => {
  const classes = useStyles();

  const { total, completed, proposed } = resourceRequestsStatusCountSummary;
  const showResourceRequestInfo = total === 0 || completed > 0 || proposed > 0;
  const acceptedResources = {
    value: completed
  };

  const alertClasses = useMemo(() => ({ message: classes.message }), [
    classes.message
  ]);

  const proposedResources = {
    value: proposed
  };

  const resourcesInfo = useMemo(
    () => (
      <>
        {completed > 0 ? (
          <FormattedMessage
            id="rescheduleProject.acceptedResourcesCount"
            values={acceptedResources}
          />
        ) : null}
        {proposed > 0 ? (
          <FormattedMessage
            id="rescheduleProject.proposedResourcesCount"
            values={proposedResources}
          />
        ) : null}
      </>
    ),
    [proposed, completed, acceptedResources, proposedResources]
  );

  return showResourceRequestInfo ? (
    <Alert icon={false} severity="info" size="small" classes={alertClasses}>
      {total === 0 ? noResourceRequestCreatedInfo : resourcesInfo}
    </Alert>
  ) : null;
};

ResourceRequestsInfo.propTypes = {
  resourceRequestsStatusCountSummary: PropTypes.object
};

export default ResourceRequestsInfo;
