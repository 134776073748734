import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  expansionPanel: {
    boxShadow: 'none',
    padding: 0
  },
  expansionPanelDetails: {
    display: 'block',
    overflow: 'auto',
    padding: 0
  },
  expansionPanelSummary: {
    display: 'inline-flex',
    backgroundColor: theme.palette.grey[300],
    fontSize: theme.typography.body1.fontSize,
    width: '100%',
    padding: 0,
    '&$expanded': {
      minHeight: 0,
      padding: 0
    }
  },
  expansionPanelSummaryContent: {
    margin: 0,
    display: 'flex',
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {},
  expandIcon: { marginRight: 0 }
}));

const expandMoreIcon = <ExpandMoreIcon />;

export const CardExpansionPanel = ({
  classes: classesOverride,
  resourceKeys,
  children,
  TitleComponent,
  defaultExpanded = true,
  titleComponentProps,
  onChange,
  square = false,
  elevation = 1,
  defaultExpandIcon = true,
  dataQeId
}) => {
  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  const classes = useStyles({ classes: classesOverride });
  const panelSummaryClasses = useMemo(
    () => ({
      root: classes.expansionPanelSummary,
      content: classes.expansionPanelSummaryContent,
      expanded: classes.expanded,
      expandIcon: classes.expandIcon
    }),
    [
      classes.expandIcon,
      classes.expanded,
      classes.expansionPanelSummary,
      classes.expansionPanelSummaryContent
    ]
  );
  const [expanded, setExpanded] = React.useState(
    isPsaRmpTaskAllocation1Enabled ? defaultExpanded : false
  );
  const handleChange = useCallback(
    (event, _expanded) => {
      setExpanded(!expanded);
      onChange && onChange(event, _expanded);
    },
    [expanded, onChange]
  );

  return (
    <Accordion
      className={classes.expansionPanel}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      square={square}
      elevation={elevation}
    >
      <AccordionSummary
        classes={panelSummaryClasses}
        expandIcon={defaultExpandIcon ? expandMoreIcon : null}
        data-qe-id={dataQeId}
      >
        {TitleComponent ? (
          <TitleComponent {...titleComponentProps} expanded={expanded} />
        ) : (
          <FormattedMessage id={resourceKeys.title} />
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.expansionPanelDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

CardExpansionPanel.propTypes = {
  classes: PropTypes.object,
  resourceKeys: PropTypes.object,
  children: PropTypes.node,
  TitleComponent: PropTypes.elementType,
  defaultExpanded: PropTypes.bool,
  titleComponentProps: PropTypes.object,
  onChange: PropTypes.func,
  square: PropTypes.bool,
  elevation: PropTypes.number,
  defaultExpandIcon: PropTypes.bool,
  dataQeId: PropTypes.string
};
export default CardExpansionPanel;
