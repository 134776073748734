import { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

export const useHasFeatureFlag = ({ featureFlag }) => {
  const me = useMeContext();

  return useMemo(() => me.featureFlags[featureFlag] || false, [
    featureFlag,
    me.featureFlags
  ]);
};
