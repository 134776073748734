import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import SettingsButton from '~/modules/common/components/ListTableToolBar/SettingsButton';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import { RESOURCE_MANAGEMENT_TAB_ENUM } from '../../enums';
import ResourceManagementImportExportMenu from '../ResourceManagementImportExportMenu';

const useStyles = makeStyles(() => ({
  margin: {
    marginLeft: 'auto'
  }
}));

export const ResourceManagementActionIcons = ({
  onSettingsClick,
  selectedTab
}) => {
  const { uiRootPath, permissionsMap } = useMeContext();

  const isMobile = useIsBreakpointDown('sm');
  const showSettingsButton =
    !isMobile && selectedTab === RESOURCE_MANAGEMENT_TAB_ENUM.LIST;
  const showResourcePoolShortcut =
    permissionsMap['urn:replicon:resource-pool-action:view-resource-pool'] &&
    [
      RESOURCE_MANAGEMENT_TAB_ENUM.LIST,
      RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE
    ].includes(selectedTab);

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.margin}>
      {showResourcePoolShortcut && !isMobile && (
        <Button color="primary" href={`${uiRootPath}admin/resource-pools`}>
          <FormattedMessage id="resourceManagement.resourcePools" />
        </Button>
      )}
      <ResourceManagementImportExportMenu
        isMobile={isMobile}
        selectedTab={selectedTab}
      />
      {showSettingsButton && (
        <SettingsButton
          data-qe-id="ResourceSettingButton"
          onClick={onSettingsClick}
          aria-label={intl.formatMessage({
            id: 'resourceManagement.settingsButton'
          })}
        />
      )}
    </div>
  );
};

ResourceManagementActionIcons.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
  selectedTab: PropTypes.oneOf([
    RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE,
    RESOURCE_MANAGEMENT_TAB_ENUM.LIST,
    RESOURCE_MANAGEMENT_TAB_ENUM.OVERVIEW
  ])
};

export default ResourceManagementActionIcons;
