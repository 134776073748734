import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BillStatus, BillingTransactionBalanceStatus } from '~/types';
import { getStartOfTodayUTCString } from '~/modules/common/dates/today';

const useStyles = makeStyles(theme => ({
  overdue: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.dark
  },
  column: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
}));

export const DueDateFormatter = ({ field, record }) => {
  const classes = useStyles();

  const textValue = record[field] ? record[field].textValue : null;
  const dateValue =
    record[field] && record[field].dateValue ? record[field].dateValue : null;

  const { balanceStatus, billStatus } = record;
  const isAllocated =
    balanceStatus === BillingTransactionBalanceStatus.FullyAllocated;
  const isDraft = billStatus === BillStatus.Draft;

  const today = getStartOfTodayUTCString();

  return (
    <Typography
      className={classnames(
        classes.column,
        dateValue && today > dateValue && !isAllocated && !isDraft
          ? classes.overdue
          : null
      )}
      variant="body2"
      data-qe-id="DueDateFormatter"
    >
      {textValue}
    </Typography>
  );
};

DueDateFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default DueDateFormatter;
