import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Menu } from '@material-ui/core';
import ChartDateRangeQuarterOption from './ChartDateRangeQuarterOption';

export const quarterFormat = start => start.toFormat('Qq yyyy');

export const quarterRangeFormat = (start, end) =>
  `${start.toLocaleString({ month: 'short' })} — ${end.toLocaleString({
    month: 'short',
    year: 'numeric'
  })}`;

const menuListProps = {
  dense: true
};

const anchorProps = { vertical: 'top', horizontal: 'left' };

export const createQuarterOptions = (
  date,
  previousCount = 5,
  nextCount = 10
) => {
  const periodCount = previousCount + 1 + nextCount;
  const periods = new Array(periodCount).fill({}).map((_, index) => {
    const quarterDate = new DateTime(date).plus({
      quarter: index - previousCount
    });

    const periodStart = new DateTime(quarterDate).startOf('quarter');
    const periodEnd = new DateTime(quarterDate).endOf('quarter');

    return {
      key: quarterDate.toFormat('yyyy-MM-DD'),
      label: quarterFormat(periodStart),
      dateLabel: quarterRangeFormat(periodStart, periodEnd),
      isSelected: index - previousCount === 0,
      value: {
        startDate: periodStart,
        endDate: periodEnd
      }
    };
  });

  return periods;
};

export const ChartDateRangeQuarterPicker = ({
  dateRange,
  menuId,
  anchorEl,
  onChange,
  onClose
}) => {
  const isOpen = Boolean(anchorEl);

  const handleQuarterMenuItemClick = useCallback(
    value => {
      if (onChange) onChange(value);
      if (onClose) onClose();
    },
    [onChange, onClose]
  );

  const quarterOptions = createQuarterOptions(dateRange.startDate);

  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={anchorProps}
      transformOrigin={anchorProps}
      open={isOpen}
      onClose={onClose}
      MenuListProps={menuListProps}
    >
      {quarterOptions.map(({ key, ...option }) => (
        <ChartDateRangeQuarterOption
          key={key}
          {...option}
          onClick={handleQuarterMenuItemClick}
        />
      ))}
    </Menu>
  );
};

ChartDateRangeQuarterPicker.propTypes = {
  dateRange: PropTypes.object.isRequired,
  menuId: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ChartDateRangeQuarterPicker;
