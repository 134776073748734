import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import React, { useState, useCallback } from 'react';
import { useUsersByPolicy } from '~/modules/common/hooks/useUserByPolicy';
import { POLICYTYPE } from '~/modules/common/enums';
import SearchableUserDropDown from './SearchableUserDropDown';

export const UserDropdown = ({
  value,
  label,
  onChange,
  hasError,
  autoFocus,
  helperText,
  noOptionsText,
  getOptionDisabled,
  getOptionLabel,
  disableClearable,
  userAccessRoleUri
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const { hasMore, loading, options } = useUsersByPolicy({
    policyId: POLICYTYPE.PROJECT_MANAGEMENT,
    searchTerm: value ? '' : searchTerm,
    userAccessRoleUri
  });

  return (
    <SearchableUserDropDown
      label={label}
      ariaLabel={formatMessage({ id: 'userDropdown.user' })}
      value={value}
      hasMore={hasMore}
      loading={loading}
      options={options}
      onChange={onChange}
      hasError={hasError}
      helperText={helperText}
      noOptionsText={noOptionsText}
      handleInputChange={handleInputChange}
      autoFocus={autoFocus}
      optionTypeText={formatMessage({
        id: 'moreOptions.users'
      })}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      disableClearable={disableClearable}
    />
  );
};

UserDropdown.propTypes = {
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasError: PropTypes.bool,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.node,
  noOptionsText: PropTypes.node,
  getOptionDisabled: PropTypes.func,
  disableClearable: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  userAccessRoleUri: PropTypes.string
};

export default UserDropdown;
