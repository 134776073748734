import { makeStyles } from '@material-ui/core/styles';

export const useDateRangeStyles = makeStyles(theme => ({
  range: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  date: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const useStyles = makeStyles(theme => ({
  root: {},
  headline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  titleArea: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    paddingRight: theme.spacing(1)
  },
  breadcrumb: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(-1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.typography.body2.fontSize
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  noRole: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: 'normal'
  },
  name: {
    flexGrow: 0,
    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  quantity: {
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: 'normal'
  },
  loading: {},
  overloaded: {
    color: theme.palette.error.dark
  },
  statusBadge: {
    flexGrow: 0,
    flexShrink: 0,
    margin: theme.spacing(0, 0, 0, 0.5)
  },
  subtext: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  }
}));

export default useStyles;
