import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PROJECT_REQUEST_LIST_PAGE_TYPE } from '~/modules/common/enums/ProjectRequestListPageType';

export const columns = ({ classes = {}, editable, onCreateProject } = {}) => ({
  name: {
    field: 'name',
    value: <FormattedMessage id="projectRequest.name" />,
    required: true,
    fixed: true,
    isDefault: true,
    sortable: true
  },
  status: {
    field: 'status',
    value: <FormattedMessage id="projectRequest.status" />,
    sortable: false,
    isDefault: true
  },
  description: {
    field: 'description',
    value: <FormattedMessage id="projectRequest.description" />,
    required: false,
    isDefault: true
  },
  requestedBy: {
    field: 'requestedBy',
    value: <FormattedMessage id="projectRequest.requestedBy" />,
    required: false,
    isDefault: true
  },
  portfolio: {
    field: 'portfolio',
    value: <FormattedMessage id="projectRequest.portfolio" />,
    sortable: true,
    isDefault: true
  },
  createdBy: {
    field: 'createdBy',
    value: <FormattedMessage id="projectRequest.createdBy" />,
    required: false
  },
  createProject: {
    field: 'createProject',
    required: true,
    hideInSettingsDialog: true,
    value: '',
    className: classes.createProject,
    onCreateProject,
    isClientOnly: true,
    isDefault: true
  },
  investment: {
    field: 'investment',
    value: <FormattedMessage id="projectRequest.scoreDetails.investment" />,
    required: false,
    sortable: true,
    align: 'right'
  },
  risk: {
    field: 'risk',
    value: <FormattedMessage id="projectRequest.scoreDetails.risk" />,
    required: false,
    sortable: true,
    align: 'right'
  },
  value: {
    field: 'value',
    value: <FormattedMessage id="projectRequest.scoreDetails.value" />,
    required: false,
    sortable: true,
    align: 'right'
  },
  effectiveTotal: {
    field: 'effectiveTotal',
    value: <FormattedMessage id="projectRequest.scoreDetails.total" />,
    required: false,
    sortable: true,
    isDefault: true,
    align: 'right'
  },
  estimatedStartDate: {
    field: 'startDate',
    value: (
      <FormattedMessage id="projectRequest.planningDetails.estimatedStartDate" />
    ),
    required: false,
    sortable: true,
    align: 'right'
  },
  estimatedDuration: {
    field: 'estimatedDuration',
    value: (
      <FormattedMessage id="projectRequest.planningDetails.estimatedDuration" />
    ),
    required: false,
    sortable: true,
    align: 'right'
  },
  resourceRequired: {
    field: 'resourceAssignmentsCount',
    value: (
      <FormattedMessage id="projectRequest.planningDetails.resourcesRequired" />
    ),
    required: false,
    sortable: true,
    align: 'right'
  },
  estimatedCostInBaseCurrency: {
    field: 'estimatedCostInBaseCurrency',
    value: (
      <FormattedMessage id="projectRequest.planningDetails.estimatedCost" />
    ),
    required: false,
    sortable: true,
    isDefault: true,
    align: 'right'
  },
  estimatedCostInPortfolioCurrency: {
    field: 'estimatedCostInPortfolioCurrency',
    value: (
      <FormattedMessage id="projectRequest.planningDetails.estimatedCost" />
    ),
    required: false,
    sortable: true,
    align: 'right'
  }
});

const footers = {
  total: {
    fixed: true,
    value: <FormattedMessage id="projectList.total" />,
    align: 'right'
  },
  resourceAssignmentsCount: {
    field: 'resourceAssignmentsCount',
    requiredColumn: 'resourceAssignmentsCount',
    align: 'right'
  },
  estimatedCostInBaseCurrency: {
    field: 'estimatedCostInBaseCurrency',
    requiredColumn: 'estimatedCostInBaseCurrency',
    align: 'right'
  },
  estimatedCostInPortfolioCurrency: {
    field: 'estimatedCostInPortfolioCurrency',
    requiredColumn: 'estimatedCostInPortfolioCurrency',
    align: 'right'
  }
};

const emptyFooter = field => ({
  field,
  requiredColumn: field
});

export const getEnabledColumnGroups = ({
  classes,
  canEditProject,
  onCreateProject,
  canViewPlanningData,
  projectRequestListPageType
}) => {
  const isPortfolioProjectRequestListPage =
    projectRequestListPageType === PROJECT_REQUEST_LIST_PAGE_TYPE.PORTFOLIO;
  const {
    name,
    status,
    description,
    requestedBy,
    portfolio,
    createdBy,
    createProject,
    effectiveTotal,
    value,
    investment,
    risk,
    estimatedStartDate,
    estimatedDuration,
    resourceRequired,
    estimatedCostInBaseCurrency,
    estimatedCostInPortfolioCurrency
  } = columns({ classes, canEditProject, onCreateProject });

  return [
    {
      id: 'basic',
      title: null,
      columns: [
        name,
        status,
        description,
        requestedBy,
        portfolio,
        createdBy,
        canEditProject && createProject
      ].filter(x => x),
      footers: [
        footers.total,
        emptyFooter(status.field),
        emptyFooter(description.field),
        emptyFooter(requestedBy.field),
        emptyFooter(portfolio.field),
        emptyFooter(createdBy.field),
        canEditProject && emptyFooter(createProject.field)
      ].filter(x => x)
    },
    {
      id: 'score',
      title: <FormattedMessage id="projectRequest.score" />,
      columns: [effectiveTotal, value, investment, risk],
      footers: [
        emptyFooter(effectiveTotal.field),
        emptyFooter(value.field),
        emptyFooter(investment.field),
        emptyFooter(risk.field)
      ]
    },
    ...(canViewPlanningData
      ? [
          {
            id: 'planning',
            title: <FormattedMessage id="projectRequest.planning" />,
            columns: [
              estimatedStartDate,
              estimatedDuration,
              resourceRequired,
              estimatedCostInBaseCurrency,
              isPortfolioProjectRequestListPage &&
                estimatedCostInPortfolioCurrency
            ].filter(x => x),
            footers: [
              emptyFooter(estimatedStartDate.field),
              emptyFooter(estimatedDuration.field),
              footers.resourceAssignmentsCount,
              footers.estimatedCostInBaseCurrency,
              footers.estimatedCostInPortfolioCurrency
            ]
          }
        ]
      : [])
  ];
};

export const getServiceDataColumns = ({
  isMobile,
  selectedColumnNames = [],
  fetchPortfolioColumn
}) => {
  const allColumns = columns();
  const mandatoryDataColumnNames = [
    allColumns.name.field,
    allColumns.description.field,
    allColumns.requestedBy.field,
    allColumns.status.field
  ];

  return isMobile
    ? [
        ...mandatoryDataColumnNames,
        allColumns.createdBy.field,
        allColumns.effectiveTotal.field,
        fetchPortfolioColumn && allColumns.portfolio.field
      ].filter(c => c)
    : [
        ...new Set(
          [...selectedColumnNames, ...mandatoryDataColumnNames].filter(
            c => c !== allColumns.createProject.field
          )
        )
      ];
};
