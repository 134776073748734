import useProjectBillingRateSchedules from './useProjectBillingRateSchedules';
import useUserBillingRateSchedules from './useUserBillingRateSchedules';
import useProjectSpecificBillingRateSchedules from './useProjectSpecificBillingRateSchedules';

export const useEditableBillingRates = ({ projectId }) => {
  const projectSpecificBillingRateSchedules = useProjectSpecificBillingRateSchedules(
    { projectId }
  );
  const projectBillingRateSchedules = useProjectBillingRateSchedules({
    projectId
  });
  const userBillingRateSchedules = useUserBillingRateSchedules({
    projectId
  });

  return {
    projectSpecificBillingRateSchedules,
    projectBillingRateSchedules,
    userBillingRateSchedules
  };
};
export default useEditableBillingRates;
