import React from 'react';

import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SummaryCardHelp } from '~/modules/common/components';

const ResourceCostHelp = ({ type }) => {
  const intl = useIntl();

  const toolTipContent = content(intl, type);

  return <SummaryCardHelp content={toolTipContent} />;
};

const content = (intl, type) => {
  const actualAlertId = `resourceCostHelp.${type}ActualAlert`;
  const allocatedAlertId = `resourceCostHelp.${type}AllocatedAlert`;

  return [
    {
      title: intl.formatMessage({ id: 'resourceCostHelp.actual' }),
      message: intl.formatMessage({ id: actualAlertId })
    },
    {
      title: intl.formatMessage({ id: 'resourceCostHelp.allocated' }),
      message: intl.formatMessage({ id: allocatedAlertId })
    }
  ];
};

ResourceCostHelp.propTypes = {
  type: PropTypes.string.isRequired
};

export default ResourceCostHelp;
