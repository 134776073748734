import { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

export const hasPermission = permissionsMap => ({
  actionUri,
  dataAccessLevelUri
}) => {
  const actionDetails = permissionsMap[actionUri];
  const hasDAL = dataAccessLevelUri
    ? actionDetails?.dataAccessLevelUris?.some(d => d === dataAccessLevelUri)
    : true;

  return Boolean(actionDetails) && hasDAL;
};

export const useHasPermission = ({ actionUri, dataAccessLevelUri = null }) => {
  const me = useMeContext();

  return useMemo(
    () => hasPermission(me.permissionsMap)({ actionUri, dataAccessLevelUri }),
    [actionUri, dataAccessLevelUri, me.permissionsMap]
  );
};
