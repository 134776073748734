import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginBottom: theme.spacing(2)
  }
}));

export const ExpenseCardActionComponent = ({
  onCancel,
  onSave,
  validateForm,
  onRemove,
  actionLabel,
  saveable,
  isSaving,
  actionComponentCustomProps
}) => {
  const classes = useStyles();
  const {
    showAddExpenseTypeButton,
    onAddExpenseTypeClick,
    availableExpenseCodes,
    showRemoveAction,
    showSaveButton,
    cancelButtonLabelKey
  } = actionComponentCustomProps || {};

  const handleSave = useCallback(async () => {
    if (validateForm) {
      const errors = await validateForm();

      if (Object.keys(errors).length) return;
    }
    onSave();
  }, [onSave, validateForm]);

  return (
    <>
      {showAddExpenseTypeButton ? (
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            onClick={onAddExpenseTypeClick}
            data-qe-id="add"
            disabled={Boolean(!availableExpenseCodes?.length)}
          >
            <FormattedMessage id="expenseBillPlan.addExpenseType" />
          </Button>
        </div>
      ) : null}
      <Grid item xs={12} container spacing={0}>
        <Grid item xs={6} container justifyContent="flex-start" spacing={0}>
          {showRemoveAction && (
            <Button color="secondary" onClick={onRemove}>
              <FormattedMessage
                id="expenseBillPlan.remove"
                values={actionLabel.remove}
              />
            </Button>
          )}
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end" spacing={0}>
          <Button onClick={onCancel}>
            <FormattedMessage id={cancelButtonLabelKey} />
          </Button>
          {showSaveButton && (
            <LoadingButton
              color="primary"
              onClick={handleSave}
              disabled={!saveable || isSaving}
              isLoading={isSaving}
            >
              <FormattedMessage id="button.save" />
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};
ExpenseCardActionComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  actionLabel: PropTypes.object.isRequired,
  saveable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  validateForm: PropTypes.func,
  actionComponentCustomProps: PropTypes.object
};

export default ExpenseCardActionComponent;
