import { useState } from 'react';

export const useEarnedRevenueState = () => {
  const [recalculationBatchState, setRecalculationBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });

  return {
    recalculationBatchState,
    setRecalculationBatchState
  };
};

export default useEarnedRevenueState;
