import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const ADD_ROLE = gql`
  mutation AddRole(
    $addRoleInput: AddRoleInput!
    $includeSkills: Boolean!
    $isCurrentRateEnabled: Boolean!
  ) {
    addRole2(addRoleInput: $addRoleInput) {
      id
      displayText
      currentRate @include(if: $isCurrentRateEnabled) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      skills @include(if: $includeSkills) {
        id
        displayText
        category {
          id
          displayText
        }
        skillLevel {
          id
          rating
        }
      }
    }
  }
`;

export const useAddRoleMutation = () => useMutation(ADD_ROLE, {});

export default useAddRoleMutation;
