import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import { useMenuState } from '~/modules/common/hooks';

export const withCloseMenu = closeMenuHandler => WrappedComponent => {
  if (!WrappedComponent) return WrappedComponent;

  const { onClick: originalOnClick, closeMenu = true } = WrappedComponent.props;

  const newOnClick = () => {
    if (originalOnClick) {
      originalOnClick();
    }

    if (closeMenu) {
      closeMenuHandler();
    }
  };

  return React.cloneElement(WrappedComponent, { onClick: newOnClick });
};

export const MoreMenu = ({
  className,
  PaperProps,
  children,
  ButtonComponent = IconButton, // Note: This will override the MenuComponent
  MenuComponent = MoreVertIcon,
  title
}) => {
  const intl = useIntl();
  const {
    anchorEl,
    onMenuClose: closePopover,
    onMenuClick: openPopover
  } = useMenuState();
  const isPopoverOpen = Boolean(anchorEl);
  const Button = (
    <ButtonComponent
      className={className}
      onClick={openPopover}
      aria-haspopup="true"
      aria-label={intl.formatMessage({
        id: 'moreMenu.title'
      })}
      onClose={closePopover}
      data-qe-id="MoreMenu"
    >
      <MenuComponent />
    </ButtonComponent>
  );

  return (
    <>
      {title ? <Tooltip title={title}>{Button}</Tooltip> : Button}
      <Menu
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        PaperProps={PaperProps}
      >
        {React.Children.map(children, withCloseMenu(closePopover))}
      </Menu>
    </>
  );
};

MoreMenu.propTypes = {
  children: PropTypes.node.isRequired,
  ButtonComponent: PropTypes.any,
  MenuComponent: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  PaperProps: PropTypes.object
};

export default MoreMenu;
