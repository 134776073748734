import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select, OutlinedInput } from '@material-ui/core';
import { PERIOD_ENUM } from './period';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0
  },
  select: {
    minHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1, 3, 1, 1.5)
  }
}));

const menuProps = intl => ({
  MenuListProps: {
    dense: true,
    'aria-label': intl.formatMessage({ id: 'calendar.periodDropdown' })
  },
  PaperProps: {
    role: 'main'
  }
});

export const CalendarMenuItem = ({ period, onViewChange }) => {
  const outlineInput = useMemo(() => <OutlinedInput labelWidth={0} />, []);
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Select
      data-qe-id="PeriodDropdown"
      value={period}
      input={outlineInput}
      onChange={onViewChange}
      MenuProps={menuProps(intl)}
      classes={useMemo(() => ({ root: classes.root, select: classes.select }), [
        classes.root,
        classes.select
      ])}
    >
      <MenuItem data-qe-id="DayMenuItem" value={PERIOD_ENUM.DAYS}>
        <FormattedMessage id="calendar.day" />
      </MenuItem>
      <MenuItem data-qe-id="WeekMenuItem" value={PERIOD_ENUM.WEEKS}>
        <FormattedMessage id="calendar.week" />
      </MenuItem>
      <MenuItem data-qe-id="MonthMenuItem" value={PERIOD_ENUM.MONTHS}>
        <FormattedMessage id="calendar.month" />
      </MenuItem>
    </Select>
  );
};

CalendarMenuItem.propTypes = {
  period: PropTypes.string.isRequired,
  onViewChange: PropTypes.func.isRequired
};

export default CalendarMenuItem;
