import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo, memo } from 'react';
import {
  RangeBoundaries,
  StyledChartRangeItem2
} from '~/modules/common/charts/timeline/components';
import { getRangeBoundaries } from '~/modules/resourcing/common/util/resourceAllocationUtil';
import {
  useHolidaySummaryPeriods,
  useTimeoffSummaryPeriods
} from '~/modules/resourcing/common/hooks';
import { ResourceActualHoursSummaryBlocks2 } from '~/modules/resourcing/common/components/ResourceActualHoursSummaryBlocks';
import useQuickAllocationContext from '../../hooks/useQuickAllocationContext';
import useStyles, { useCssStyles } from './useStyles';
import {
  QuickAllocationProjectGroupDetail2,
  QuickAllocationUserPeriodSummaryBlocks
} from './components';
import QuickAllocationUserLeftItem from './components/QuickAllocationUserLeftItem';

const QuickAllocationUserRow2 = ({
  chartDisplayPeriods,
  resource,
  isExpanded,
  handleExpand,
  resourceUserActualSeries
}) => {
  const {
    scale,
    dateRange: chartDisplayDateRange,
    chartDates,
    leftComponentWidth,
    chartStartDate,
    isShowActualEnabled
  } = useQuickAllocationContext();

  const classes = useStyles();

  const cssClasses = useCssStyles();

  const { rangeMinDate, rangeMaxDate } = getRangeBoundaries({
    resourceUser: resource,
    dateRange: chartDisplayDateRange
  });

  const timeoffSummaryPeriods = useTimeoffSummaryPeriods({
    resource
  });
  const holidaySummaryPeriods = useHolidaySummaryPeriods({
    resource
  });

  const leftComponent = useMemo(
    () => (
      <QuickAllocationUserLeftItem
        user={resource}
        handleExpand={handleExpand}
        isExpanded={isExpanded}
        isShowActualEnabled={isShowActualEnabled}
      />
    ),
    [resource, handleExpand, isExpanded, isShowActualEnabled]
  );

  const timeLineComponent = useMemo(
    () => (
      <>
        <RangeBoundaries
          scale={scale}
          chartStartDate={chartStartDate}
          start={rangeMinDate.toISO()}
          end={rangeMaxDate.toISO()}
        />
        <QuickAllocationUserPeriodSummaryBlocks
          resourceAvailabilitySummarySeries={
            resource.resourceAvailabilitySummarySeries
          }
          chartDisplayDateRange={chartDisplayDateRange}
          holidaySummaryPeriods={holidaySummaryPeriods}
          timeoffSummaryPeriods={timeoffSummaryPeriods}
          holidays={resource.holidays}
          timeOffs={resource.timeoffs}
          resourceId={resource.id}
          showTimeOffRow={isExpanded}
          showActuals={isShowActualEnabled}
        />
        {isShowActualEnabled && (
          <ResourceActualHoursSummaryBlocks2
            dataQeId="UserRow"
            chartDisplayPeriods={chartDisplayPeriods}
            scale={scale}
            resourceUser={resource}
            resourceUserActualTimePeriods={resourceUserActualSeries}
            resourceAvailabilityTimePeriods={
              resource.resourceAvailabilitySummarySeries
            }
          />
        )}
      </>
    ),
    [
      scale,
      chartStartDate,
      rangeMinDate,
      rangeMaxDate,
      resource,
      chartDisplayDateRange,
      holidaySummaryPeriods,
      timeoffSummaryPeriods,
      isExpanded,
      isShowActualEnabled,
      chartDisplayPeriods,
      resourceUserActualSeries
    ]
  );

  return (
    <Paper elevation={0} square>
      {isExpanded && <div className={classes.spacer} />}
      <StyledChartRangeItem2
        classes={cssClasses}
        scale={scale}
        scaleItemCount={chartDates.length}
        chartStartDate={chartStartDate}
        leftComponentWidth={leftComponentWidth}
      >
        {leftComponent}
        {timeLineComponent}
      </StyledChartRangeItem2>

      {isExpanded && (
        <QuickAllocationProjectGroupDetail2
          user={resource}
          rangeMinDate={rangeMinDate}
          rangeMaxDate={rangeMaxDate}
          holidaySummaryPeriods={holidaySummaryPeriods}
          timeoffSummaryPeriods={timeoffSummaryPeriods}
        />
      )}
    </Paper>
  );
};

QuickAllocationUserRow2.propTypes = {
  chartDisplayPeriods: PropTypes.array,
  resource: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  handleExpand: PropTypes.func,
  resourceUserActualSeries: PropTypes.array
};

export default memo(QuickAllocationUserRow2);
