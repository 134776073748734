import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ProgramDropdown } from '../../project/common/components';
import useProgramDropdownOptionsForProject from './useProgramDropdownOptionsForProject';
import useMyProgramDropDownOptionsForProject from './useMyProgramDropDownOptionsForProject';

export const ProgramDropdownForProject = ({
  value,
  onChange,
  shouldFetchOnlyMyPrograms = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const { options, loading } = useProgramDropdownOptionsForProject(
    shouldFetchOnlyMyPrograms
  );

  const {
    hasMore,
    options: myProgramOptions,
    loading: myProgramOptionsLoading
  } = useMyProgramDropDownOptionsForProject(!shouldFetchOnlyMyPrograms);

  return (
    <ProgramDropdown
      hasMore={hasMore}
      options={shouldFetchOnlyMyPrograms ? myProgramOptions : options}
      isLoading={shouldFetchOnlyMyPrograms ? myProgramOptionsLoading : loading}
      value={value}
      onChange={onChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.programs'
      })}
      {...rest}
    />
  );
};

ProgramDropdownForProject.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  shouldFetchOnlyMyPrograms: PropTypes.bool
};

export default ProgramDropdownForProject;
