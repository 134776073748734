import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { CustomChip } from '~/modules/projects/project/common/components/ContactInfo';
import { CONTACT_TYPE } from '~/modules/common/enums';
import { NoValue } from '~/modules/common/components';
import ContactFieldReadOnly from './ContactFieldReadOnly';

const ContactTypeReadOnly = ({ record, field }) => {
  const { formatMessage } = useIntl();
  const value = formatMessage({
    id: `projectBasicInfoCard.contactInfo.contactTypeOptions.${
      CONTACT_TYPE[record[field]]
    }`
  });

  return (
    <ContactFieldReadOnly record={record} field={field}>
      {record[field] !== CONTACT_TYPE.OTHERS ? (
        <CustomChip label={value} />
      ) : (
        value || <NoValue />
      )}
    </ContactFieldReadOnly>
  );
};

ContactTypeReadOnly.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string
};

export default ContactTypeReadOnly;
