import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContent, DialogActions } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import CurrentProgressBarContent from '~/modules/common/components/CurrentProgressBarContent';
import { useBatchStatus, useCancelBatch } from '../../hooks';

const progressInfo = <FormattedMessage id="bulkRemoveDialog.info.cancel" />;

export const BulkDeleteProgressDialogContent = ({
  onClose,
  batchId,
  onError,
  objectCount,
  setBatchState
}) => {
  const {
    error,
    hasProcessed,
    overallProgress: { currentProgress = 0 } = {}
  } = useBatchStatus({ batchId });

  const cancelBatch = useCancelBatch();

  const handleClose = useCallback(() => {
    cancelBatch(batchId);
    onClose();
  }, [cancelBatch, onClose, batchId]);

  useEffect(() => {
    if (hasProcessed)
      setBatchState({ batchId, batchInProgress: false, batchCompleted: true });
    if (error) onError(error);
  }, [setBatchState, batchId, hasProcessed, error, onError]);

  return (
    <>
      <DialogContent>
        <CurrentProgressBarContent
          itemCounts={objectCount}
          progressInfo={progressInfo}
          currentProgress={currentProgress}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="bulkRemoveDialog.actions.cancel" />
        </Button>
      </DialogActions>
    </>
  );
};

BulkDeleteProgressDialogContent.propTypes = {
  onError: PropTypes.func,
  batchId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setBatchState: PropTypes.func.isRequired,
  objectCount: PropTypes.number.isRequired
};

export default BulkDeleteProgressDialogContent;
