import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NoDataItem from '../NoDataItem';

const useStyles = makeStyles(theme => ({
  message: {
    margin: '1em'
  },
  root: {}
}));

const EmptyTasksCardContent = ({ message, Container = 'div' }) => {
  const classes = useStyles();

  return (
    <Container className={classes.message}>
      <NoDataItem
        classes={useMemo(() => ({ root: classes.root }), [classes.root])}
      >
        {message}
      </NoDataItem>
    </Container>
  );
};

EmptyTasksCardContent.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  Container: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func
  ])
};

export default EmptyTasksCardContent;
