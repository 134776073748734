import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ImportExportIcon from '@material-ui/icons/ImportExportSharp';
import IconButton from '@material-ui/core/IconButton';

const ImportExportMenuButton = props => {
  const { handleMenuOpen } = props;
  const { formatMessage } = useIntl();

  return (
    <IconButton
      aria-label={formatMessage({ id: 'importExportMenu.title' })}
      onClick={handleMenuOpen}
    >
      <ImportExportIcon />
    </IconButton>
  );
};

ImportExportMenuButton.propTypes = {
  handleMenuOpen: PropTypes.func.isRequired
};

export default ImportExportMenuButton;
