import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Paper, Grid } from '@material-ui/core';

const useLeftComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  }
}));

const SkeletonLeftComponent = () => {
  const classes = useLeftComponentStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <Skeleton width="80%" height={20} />
    </Paper>
  );
};

const useTotalComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 40,
    display: 'flex',
    alignItems: 'center'
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

const SkeletonTotalComponent = () => {
  const classes = useTotalComponentStyles();

  return (
    <div className={classes.root}>
      <Skeleton className={classes.spacing} width="100%" height={15} />
    </div>
  );
};

const useSkeletonMiddleComponentStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  root: {
    padding: theme.spacing(0.5, 2),
    width: '100%',
    height: 40,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.table.border}`
  }
}));

const SkeletonMiddleComponent = () => {
  const classes = useSkeletonMiddleComponentStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <Skeleton height={24} width="18%" />
      <Skeleton height={24} width="18%" />
      <Skeleton height={24} width="18%" />
      <Skeleton height={24} width="18%" />
      <Skeleton height={24} width="18%" />
    </Paper>
  );
};

const useRowStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  leftComponent: {
    width: '15%'
  },
  totalComponent: {
    width: '15%',
    display: 'flex',
    flexDirection: 'column'
  },
  middle: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  }
}));

const SkeletonRow = () => {
  const classes = useRowStyles();

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.leftComponent} item>
        <SkeletonLeftComponent />
      </Grid>
      <Grid className={classes.middle} item>
        <SkeletonMiddleComponent />
        <SkeletonMiddleComponent />
        <SkeletonMiddleComponent />
      </Grid>
      <Grid className={classes.totalComponent} item>
        <SkeletonTotalComponent />
        <SkeletonTotalComponent />
        <SkeletonTotalComponent />
      </Grid>
    </Grid>
  );
};

export const BillingOverviewTabLoading = () => (
  <>
    <SkeletonRow />
    <SkeletonRow />
    <SkeletonRow />
  </>
);

export default BillingOverviewTabLoading;
