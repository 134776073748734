import React from 'react';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { retryLoading } from '~/util';
import ProjectNavigationBreadcrumb from './ProjectNavigationBreadcrumb';

const ProjectPage = React.lazy(() =>
  retryLoading(() => import('./ProjectPage'))
);

export const canViewProjectDetailsRoute = ({
  permissionsMap,
  userAccessRoles
}) =>
  Boolean(permissionsMap['urn:replicon:project-action:view-project']) &&
  userAccessRoles.some(
    role =>
      role === USER_ACCESS_ROLE.BILLING_MANAGER ||
      role === USER_ACCESS_ROLE.CLIENT_MANAGER ||
      role === USER_ACCESS_ROLE.PORTFOLIO_ADMIN ||
      role === USER_ACCESS_ROLE.PORTFOLIO_MANAGER ||
      role === USER_ACCESS_ROLE.PROGRAM_MANAGER ||
      role === USER_ACCESS_ROLE.PROJECT_ADMIN ||
      role === USER_ACCESS_ROLE.PROJECT_MANAGER
  );

const projectRoute = ({ me }) => ({
  path: '/projects/:slug',
  icon: () => null,
  hidden: true,
  title: () => <ProjectNavigationBreadcrumb />,
  component: ProjectPage,
  hasPermission: canViewProjectDetailsRoute(me)
});

export default projectRoute;
