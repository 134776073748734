import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_ALLOCATIONS_FOR_USER_QUERY = gql`
  query GetAllocationsForResourceUser(
    $userUri: String
    $startDate: String
    $endDate: String
    $limit: Int
  ) {
    resourceAllocations(
      userUri: $userUri
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      resourceAllocations {
        id
        projectUri
        project {
          id
          displayText
        }
        allocationStatus
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
      }
    }
  }
`;

const useAllocationsForResourceUser = ({ userUri, dateRange }) => {
  const { loading, error, data } = useQuery(
    RESOURCE_ALLOCATIONS_FOR_USER_QUERY,
    {
      variables: {
        userUri,
        startDate: dateRange ? dateRange.start : null,
        endDate: dateRange ? dateRange.end : null,
        limit: 500
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const allocations =
    data &&
    data.resourceAllocations &&
    data.resourceAllocations.resourceAllocations;

  return {
    loading,
    allocations: allocations || [],
    error
  };
};

export default useAllocationsForResourceUser;
