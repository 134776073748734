import React from 'react';
import { FormattedMessage } from 'react-intl';
import { array, object, string } from 'yup';

const STRING_MAX_LENGTH = 255;
const exceededMaxLengthMessage = (
  <FormattedMessage
    id="projectBasicInfoCard.addressInfo.validation.fieldExceededMaxLength"
    values={{ max: STRING_MAX_LENGTH }}
  />
);

export const addressValidationSchema = object().shape({
  addresses: array().of(
    object().shape({
      address: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
      city: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
      stateProvince: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
      zipPostalCode: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
    })
  )
});
