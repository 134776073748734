import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_ENABLED_TAG_FIELDS_QUERY = gql`
  query getEnabledTagFields($tagIds: [String!]) {
    tags(tagIds: $tagIds) {
      id
      name
    }
  }
`;

export const useGetEnabledTagFieldsQuery = enabledFields => {
  const tagIds = enabledFields?.filter(c =>
    /^urn:replicon-tenant:[^:]+:object-extension-tag-definition:.*$/.exec(c)
  );
  const queryData = useQuery(GET_ENABLED_TAG_FIELDS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      tagIds
    }
  });

  return {
    data: queryData.data ? queryData.data.tags : [],
    loading: queryData.loading
  };
};
