import { withCustomFetchCriteriaProvider } from '../enhancers';
import { projectStatusPolicyTag } from './tag';
import ProjectStatusPolicyFacetDetails from './ProjectStatusPolicyFacetDetails';
import { useSearchableProjectOptions } from './hooks/useProjectStatusPolicy';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableProjectOptions);

export default {
  tag: projectStatusPolicyTag,
  makeCriteriaProvider,
  DetailsControl: ProjectStatusPolicyFacetDetails
};
