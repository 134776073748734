import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { useDecimalPrecision } from '~/modules/common/components/Decimal/usePrecision';
import useHighlightStyles from './useHighlightStyles';

const withHighlighting = (WrappedComponent, value, limit) => {
  const NewComponent = ({ field, record }) => {
    // if value/limit is not on object, use actual value

    const precisedValue = useDecimalPrecision({
      value: get(record, value, value),
      precision: 2
    });
    const precisedLimit = useDecimalPrecision({
      value: get(record, limit, limit),
      precision: 2
    });

    const classes = useHighlightStyles({
      value: precisedValue,
      limit: precisedLimit
    });

    return (
      <WrappedComponent
        field={field}
        record={record}
        className={classes.root}
      ></WrappedComponent>
    );
  };

  NewComponent.propTypes = {
    field: PropTypes.string,
    record: PropTypes.object
  };

  return NewComponent;
};

export default withHighlighting;
