import { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

export const useActionComponentCustomProps = ({
  canEditExpenseCodes,
  context,
  editable,
  canEditBillingContracts,
  displayText,
  availableExpenseCodes,
  onAddExpenseTypeClick,
  viewSummary
}) => {
  const { hasViewProjectCostType } = useMeContext();

  const isPPMProduct = Boolean(hasViewProjectCostType);

  return useMemo(
    () => ({
      showAddExpenseTypeButton:
        editable &&
        canEditExpenseCodes &&
        (isPPMProduct || canEditBillingContracts),
      onAddExpenseTypeClick,
      availableExpenseCodes,
      showRemoveAction:
        context === 'timeAndExpense' ? false : canEditExpenseCodes,
      showSaveButton: !viewSummary,
      cancelButtonLabelKey: viewSummary ? 'button.close' : 'button.cancel'
    }),
    [
      editable,
      canEditExpenseCodes,
      isPPMProduct,
      canEditBillingContracts,
      onAddExpenseTypeClick,
      availableExpenseCodes,
      context,
      viewSummary
    ]
  );
};
