import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_USER_TASK_ASSIGNMENT_SUMMARY_QUERY = gql`
  query resourceUserTaskAssignmentSummary(
    $input: ResourceUserTaskAssignmentSummaryInput!
  ) {
    resourceUserTaskAssignmentSummary(input: $input) {
      taskId
      userId
      resourceEstimate {
        estimateId
        initialEstimatedHours
        projectRole {
          id
          displayText
        }
      }
      taskResourceUserAllocation {
        id
        taskUri
        projectUri
        roleUri
        startDate
        endDate
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
        totalHours
      }
      taskResourceUserHoursProgressSummary {
        actualHours
        estimatedAtCompletionHours
        estimatedRemainingHours
      }
    }
  }
`;

export const useResourceUserTaskAssignmentSummary = ({
  userId,
  projectId,
  taskId
}) => {
  const { loading, data } = useQuery(
    RESOURCE_USER_TASK_ASSIGNMENT_SUMMARY_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          userId,
          projectId,
          taskId
        }
      }
    }
  );

  return {
    loading,
    taskAssignmentSummary: data
  };
};
