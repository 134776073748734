import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { useMeContext } from '~/modules/me';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import ProjectStatusPolicy from './ProjectStatusPolicy';
import { useProjectStatusPolicy } from './hooks';

export const ProjectStatusPolicyFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const intl = useIntl();

  const {
    featureFlags: { isPsaPrpRenameProjectStatusEnabled }
  } = useMeContext();

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const { projectPolicies } = useProjectStatusPolicy({
    intl,
    customStatusMapping,
    isCustomStatusNameEnabled
  });

  const renderOption = useCallback(
    option => option.label && <ProjectStatusPolicy value={option.label} />,
    []
  );

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option => selected.some(v => v.id === option.id),
    [selected]
  );

  const resourceLables = useMemo(
    () => ({
      projectStatusPolicy: intl.formatMessage({
        id: 'search.projectStatusPolicy'
      })
    }),
    [intl]
  );

  const classes = useStyles();

  return (
    <FacetDetailField
      label={resourceLables.projectStatusPolicy}
      className={className}
    >
      <SimpleAutocomplete
        options={projectPolicies}
        value={selected}
        variant="standard"
        onChange={onValueChange}
        multiple
        fullWidth
        classes={classes}
        hiddenLabel
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        optionPropText="label"
      />
    </FacetDetailField>
  );
};

ProjectStatusPolicyFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ProjectStatusPolicyFacetDetails;
