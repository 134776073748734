import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0, 2, 0),
    backgroundColor: 'inherit'
  }
}));

export default useStyles;
