import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, ListItemIcon, ListItemText, Radio } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  radio: {}
}));

const useTextStyles = makeStyles(() => ({
  primary: {
    fontWeight: 'bold'
  },
  secondary: {}
}));

const AssignmentDialogOptionItem = ({
  classes: classesOverride,
  value,
  inputProps,
  primary,
  secondary,
  onSelect
}) => {
  const classes = useStyles({ classes: classesOverride });
  const textClasses = useTextStyles({ classes: classesOverride });

  const handleClick = useCallback(() => {
    if (onSelect) onSelect(value);
  }, [onSelect, value]);

  return (
    <ListItem
      button
      key={value}
      dense={false}
      className={classes.root}
      onClick={handleClick}
    >
      <ListItemIcon>
        <Radio
          color="primary"
          value={value}
          disableRipple
          inputProps={inputProps}
          className={classes.radio}
        />
      </ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={secondary}
        classes={textClasses}
      />
    </ListItem>
  );
};

AssignmentDialogOptionItem.propTypes = {
  classes: PropTypes.object,
  inputProps: PropTypes.object,
  value: PropTypes.string.isRequired,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSelect: PropTypes.func
};

export default AssignmentDialogOptionItem;
