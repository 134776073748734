import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  count: {
    fontWeight: theme.typography.fontWeightBold,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(5),
    marginRight: theme.spacing(2),
    maxWidth: theme.spacing(5),
    overflow: 'hidden'
  },
  text: {
    flexGrow: 1
  }
}));

export const CountAndText = ({ count = '', text, textVariant = 'body1' }) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.count}
        component="span"
        align="right"
        variant={textVariant}
      >
        {count}
      </Typography>
      <Typography
        className={classes.text}
        component="span"
        variant={textVariant}
      >
        {text}
      </Typography>
    </>
  );
};

CountAndText.propTypes = {
  count: PropTypes.number.isRequired,
  textVariant: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default CountAndText;
