import useThemeBreakpoint from '~/modules/common/hooks/useThemeBreakpoint';

export const genericAllItem = {
  key: 'all',
  label: 'All'
};

const tabWidthsFfEnabled = {
  xl: 24,
  lg: 20,
  md: 17,
  sm: 14,
  xs: 14
};

const tabWidths = {
  xl: 20,
  lg: 18,
  md: 15,
  sm: 14,
  xs: 14
};

export const createTabWidthStyles = (
  theme,
  isPsaPrpRenameProjectStatus2Enabled
) => ({
  flexBasis: theme.spacing(
    isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.md : tabWidths.md
  ),
  minWidth: theme.spacing(
    isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.md : tabWidths.md
  ),
  width: theme.spacing(
    isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.md : tabWidths.md
  ),
  height: theme.spacing(5),
  [theme.breakpoints.only('xl')]: {
    flexBasis: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.xl : tabWidths.xl
    ),
    minWidth: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.xl : tabWidths.xl
    ),
    width: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.xl : tabWidths.xl
    ),
    height: theme.spacing(5)
  },
  [theme.breakpoints.only('lg')]: {
    flexBasis: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.lg : tabWidths.lg
    ),
    minWidth: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.lg : tabWidths.lg
    ),
    width: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.lg : tabWidths.lg
    ),
    height: theme.spacing(5)
  },
  [theme.breakpoints.only('sm')]: {
    flexBasis: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.sm : tabWidths.sm
    ),
    minWidth: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.sm : tabWidths.sm
    ),
    width: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.sm : tabWidths.sm
    ),
    height: theme.spacing(5)
  },
  [theme.breakpoints.only('xs')]: {
    flexBasis: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.xs : tabWidths.xs
    ),
    minWidth: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.xs : tabWidths.xs
    ),
    width: theme.spacing(
      isPsaPrpRenameProjectStatus2Enabled ? tabWidthsFfEnabled.xs : tabWidths.xs
    ),
    height: theme.spacing(5)
  }
});

export const useThemeWidth = theme => {
  const breakpoint = useThemeBreakpoint(theme);

  return tabWidths[breakpoint];
};
