import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tabs, Tab } from '@material-ui/core';
import useResourceAllocationChartTabStateContext, {
  RESOURCE_ALLOCATION_CHART_TAB_ENUM
} from '~/modules/resourcing/hooks/useResourceAllocationChartTabStateContext';
import { ResourceRequestAccessLevel, ResourcePlanGroupBy } from '~/types';
import { useResourcePlanSummaryCounts } from '~/modules/resourcing/common/hooks';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import useProjectResourcingContext from '~/modules/resourcing/contexts/useProjectResourcingContext';
import { useResourcePlanFilterBasedOnSearchCriteria } from '~/modules/resourcing/hooks';

const resourceLabels = {
  all: <FormattedMessage id="resourceAllocationChartTabs.all" />
};

export const ResourceAllocationChartTabs = memo(
  ({
    projectUri,
    resourcePlanGroup,
    showCount = true,
    requestAccessLevel = ResourceRequestAccessLevel.ResourceManager
  }) => {
    const {
      selectedChartTab,
      setSelectedChartTab
    } = useResourceAllocationChartTabStateContext();

    const { searchCriteria } = useProjectResourcingContext();
    const { planFilter } = useResourcePlanFilterBasedOnSearchCriteria({
      searchCriteria
    });

    const handleTabChange = useCallback(
      (_, value) => {
        setSelectedChartTab(value);
      },
      [setSelectedChartTab]
    );

    const {
      loading,
      pendingCount,
      toBeHiredCount,
      proposedCount
    } = useResourcePlanSummaryCounts({
      resourcePlanFilter:
        planFilter || projectUri
          ? {
              ...planFilter,
              projects: projectUri
                ? [...((planFilter && planFilter.projects) || []), projectUri]
                : planFilter && planFilter.projects
            }
          : undefined,
      resourcePlanGroup,
      requestAccessLevel
    });

    const pendingValues = useMemo(
      () => ({
        count: loading || !showCount ? '' : `(${pendingCount})`
      }),
      [loading, pendingCount, showCount]
    );

    const toBeHiredValues = useMemo(
      () => ({
        count: loading || !showCount ? '' : `(${toBeHiredCount})`
      }),
      [loading, toBeHiredCount, showCount]
    );

    const proposedValues = useMemo(
      () => ({
        count: loading || !showCount ? '' : `(${proposedCount})`
      }),
      [loading, proposedCount, showCount]
    );

    return (
      <StickyHeader level={2}>
        <Tabs
          indicatorColor="primary"
          value={selectedChartTab}
          onChange={handleTabChange}
        >
          <Tab
            data-qe-id="PendingTab"
            value={RESOURCE_ALLOCATION_CHART_TAB_ENUM.PENDING}
            label={useMemo(
              () => (
                <FormattedMessage
                  id="resourceAllocationChartTabs.pending"
                  values={pendingValues}
                />
              ),
              [pendingValues]
            )}
          />
          <Tab
            data-qe-id="ProposedTab"
            value={RESOURCE_ALLOCATION_CHART_TAB_ENUM.PROPOSED}
            label={useMemo(
              () => (
                <FormattedMessage
                  id="resourceAllocationChartTabs.proposed"
                  values={proposedValues}
                />
              ),
              [proposedValues]
            )}
          />
          <Tab
            data-qe-id="ToBeHiredTab"
            value={RESOURCE_ALLOCATION_CHART_TAB_ENUM.TOBEHIRED}
            label={useMemo(
              () => (
                <FormattedMessage
                  id="resourceAllocationChartTabs.toBeHired"
                  values={toBeHiredValues}
                />
              ),
              [toBeHiredValues]
            )}
          />
          <Tab
            data-qe-id="AllTab"
            value={RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL}
            label={resourceLabels.all}
          />
        </Tabs>
      </StickyHeader>
    );
  }
);

ResourceAllocationChartTabs.propTypes = {
  resourcePlanGroup: PropTypes.oneOf([
    ResourcePlanGroupBy.Project,
    ResourcePlanGroupBy.Role
  ]).isRequired,
  projectUri: PropTypes.string,
  showCount: PropTypes.bool,
  requestAccessLevel: PropTypes.string
};

export default ResourceAllocationChartTabs;
