export const useProjectInfoLGUpViewLayout = (
  visibleCards,
  isPsaPpmCostEacEnhancementsEnabled
) => {
  const financialCardIndex = visibleCards.findIndex(
    c => c?.key === 'financial'
  );

  const splitIndex =
    isPsaPpmCostEacEnhancementsEnabled && financialCardIndex !== -1
      ? financialCardIndex
      : 4;

  return {
    secondColumnVisibleCards: visibleCards.slice(0, splitIndex),
    thirdColumnVisibleCards: visibleCards.slice(splitIndex)
  };
};
