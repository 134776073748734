import {
  ContactFieldReadOnly,
  ContactTypeReadOnly,
  ContactField,
  ContactTypeDropdown,
  RemoveContactButton
} from './formatters/index';

export const getFormatters = ({ isReadOnly }) => ({
  name: isReadOnly ? ContactFieldReadOnly : ContactField,
  email: isReadOnly ? ContactFieldReadOnly : ContactField,
  phoneNumber: isReadOnly ? ContactFieldReadOnly : ContactField,
  faxNumber: isReadOnly ? ContactFieldReadOnly : ContactField,
  website: isReadOnly ? ContactFieldReadOnly : ContactField,
  contactType: isReadOnly ? ContactTypeReadOnly : ContactTypeDropdown,
  removeContact: RemoveContactButton
});
