import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListItemText, makeStyles } from '@material-ui/core';
import { FocusableSpan } from '~/modules/common/components/FocusableSpan';

const useStyles = makeStyles(theme => ({
  primary: {},
  secondary: {
    ...theme.typography.caption,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const GanttTaskName = ({
  className,
  task,
  ariaLabel,
  isPsaPrpTaskCodeOnTaskCreationEnabled
}) => {
  const classes = useStyles({ classes: { primary: className } });

  const primary = useMemo(
    () => (
      <FocusableSpan className="gantt_task_name" ariaLabel={ariaLabel}>
        {task.text}
      </FocusableSpan>
    ),
    [ariaLabel, task.text]
  );

  if (isPsaPrpTaskCodeOnTaskCreationEnabled)
    return (
      <ListItemText
        classes={classes}
        primary={primary}
        secondary={task.code || '–'}
      />
    );

  return (
    <div className={className}>
      <FocusableSpan className="gantt_task_name" aria-label={ariaLabel}>
        {task.text}
      </FocusableSpan>
    </div>
  );
};

GanttTaskName.propTypes = {
  className: PropTypes.string,
  task: PropTypes.shape({
    text: PropTypes.string.isRequired,
    code: PropTypes.string
  }).isRequired,
  ariaLabel: PropTypes.string,
  isPsaPrpTaskCodeOnTaskCreationEnabled: PropTypes.bool
};

export default GanttTaskName;
