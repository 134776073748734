import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_REQUEST_STATUS_SUMMARY_COUNT_QUERY = gql`
  query Eager_ResourceRequestsStatusCountSummary($projects: [String]) {
    resourceRequestsStatusCountSummary(projects: $projects) {
      proposed
      rejected
      draft
      submitted
      toBeHired
      completed
      allocationRejected
    }
  }
`;

const useResourceRequestsStatusCountSummary = ({ projectId }) => {
  const {
    loading,
    error,
    data: { resourceRequestsStatusCountSummary = {} } = {}
  } = useQuery(RESOURCE_REQUEST_STATUS_SUMMARY_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projects: [projectId]
    }
  });

  return {
    loading,
    error,
    resourceRequestsStatusCountSummary: {
      ...resourceRequestsStatusCountSummary,
      total: loading ? 0 : getTotal(resourceRequestsStatusCountSummary)
    }
  };
};

const getTotal = resourceRequestsStatusCountSummary =>
  resourceRequestsStatusCountSummary.proposed +
  resourceRequestsStatusCountSummary.rejected +
  resourceRequestsStatusCountSummary.draft +
  resourceRequestsStatusCountSummary.submitted +
  resourceRequestsStatusCountSummary.toBeHired +
  resourceRequestsStatusCountSummary.completed +
  resourceRequestsStatusCountSummary.allocationRejected;

export default useResourceRequestsStatusCountSummary;
