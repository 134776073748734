import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { useContextualFacets } from '../enhancers';

export const SearchButton = ({
  qeIdTag,
  genericSearchValue,
  facets,
  setSearchCriteria,
  hideDetailsMenu
}) => {
  const facetSelections = useContextualFacets(facets).reduce(
    (acc, { tag, selected }) => ({
      ...acc,
      [tag]: selected
    }),
    {}
  );

  const handleSearch = useCallback(
    event => {
      setSearchCriteria({
        keywords:
          genericSearchValue && genericSearchValue !== ''
            ? genericSearchValue.split(' ')
            : [],
        criterions: facetSelections
      });
      hideDetailsMenu();
    },
    [facetSelections, genericSearchValue, hideDetailsMenu, setSearchCriteria]
  );

  return (
    <Button
      data-qe-id={`${qeIdTag}_Search`}
      data-test="search-button"
      onClick={handleSearch}
      variant="contained"
      color="primary"
    >
      <FormattedMessage id="search.title" />
    </Button>
  );
};

SearchButton.propTypes = {
  qeIdTag: PropTypes.string,
  facets: PropTypes.array.isRequired,
  genericSearchValue: PropTypes.string,
  hideDetailsMenu: PropTypes.func,
  setSearchCriteria: PropTypes.func.isRequired
};

export default SearchButton;
