import { useCallback } from 'react';
import { CONTEXT_ENUM, RATE_TYPE_KEY_ENUM } from '../enums';
import { useUpdateClientRates } from './useUpdateClientRates';
import { useUpdateProjectRates } from './useUpdateProjectRates';

const filterUserRates = (userIdFromInitialRate, rates) =>
  !rates.some(({ userReference: id }) => id === userIdFromInitialRate);

const filterRoleRates = (roleIdFromInitialRate, rates) =>
  !rates.some(({ roleReference: id }) => id === roleIdFromInitialRate);

export const useModifyRates = ({
  context,
  projectId,
  initialRates,
  rates,
  rateTypeKey,
  clientId,
  resetForm,
  setRatesModifier
}) => {
  const { updateProjectRates } = useUpdateProjectRates();
  const { updateClientRates } = useUpdateClientRates();

  const modifyRates = useCallback(async () => {
    const dirtyBillingRates = rates.filter(rate => rate.isDirty);
    let deletedBilingRates =
      rateTypeKey === RATE_TYPE_KEY_ENUM.userRates
        ? initialRates.filter(({ userReference: userId }) =>
            filterUserRates(userId, rates)
          )
        : rateTypeKey === RATE_TYPE_KEY_ENUM.roleRates
        ? initialRates.filter(({ roleReference: roleId }) =>
            filterRoleRates(roleId, rates)
          )
        : [];

    deletedBilingRates = deletedBilingRates.map(billingRate => {
      return { ...billingRate, billingScheduleEntries: [] } || {};
    });

    let newRates = [...deletedBilingRates, ...dirtyBillingRates];

    // for handling save without changes case
    if (!newRates.length) newRates = rates;

    if (context === CONTEXT_ENUM.project && projectId) {
      await updateProjectRates({
        projectId,
        newRates,
        rateTypeKey,
        setRatesModifier
      });
    }
    if (context === CONTEXT_ENUM.client && clientId)
      updateClientRates({ clientId, rateTypeKey, rates: newRates });
    resetForm();
  }, [
    rates,
    rateTypeKey,
    initialRates,
    context,
    projectId,
    clientId,
    updateClientRates,
    updateProjectRates,
    setRatesModifier,
    resetForm
  ]);

  return {
    modifyRates
  };
};
