import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { MoneyValue } from '~/modules/common/components/Money';
import { useScriptParamsEditableModifier } from '~/modules/projects/project/BillPlanV2/BillPlanPreRequisites/hooks/useScriptParamsEditableModifier';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import DeleteScriptConfirmationDialog from '~/modules/projects/project/BillPlanV2/components/DeleteScriptConfirmationDialog';
import ScriptParamsEditor from './components/ScriptParamsEditor';
import { withFormState } from './enhancers';
import BillPlanActionComponent from './BillPlanActionComponent';
import { ErrorTitle } from './components';

const useCardContentStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0
  },
  card: {
    padding: 0
  },
  content: {
    padding: theme.spacing(2)
  }
}));
const taskKeyUri = 'urn:replicon:script-key:parameter:task';

export const ScriptParamsEditableCard = ({
  values,
  allowAddScriptParam,
  onDeleteScript,
  onDeleteRow,
  setFieldValue,
  customEditors,
  customFormatters,
  handleSubmit,
  resetForm,
  onCancelCallback,
  errors,
  editable,
  additionalProps,
  validateForm,
  readOnlyParameters,
  HeaderComponent,
  scriptDetails,
  BillPlanPreRequisites,
  projectSlug,
  projectId,
  clientUri,
  startDate,
  endDate,
  projectName,
  projectPermissions,
  isSubmitting,
  projectCurrency
}) => {
  const { formatMessage } = useIntl();
  const {
    displayText,
    scriptType,
    estimatedAmount,
    id: planId,
    hasHeaderComponent
  } = values;
  const isMobile = useIsBreakpointDown('sm');
  const ariaLabel = formatMessage(
    {
      id: 'scriptParamEditor.scriptParamsEditableCardDialog'
    },
    {
      text: displayText
    }
  );
  const { open: dialogOpen, openDialog, closeDialog } = useDialogState(false);

  const classes = useCardContentStyles();
  const subTitle = useMemo(
    () =>
      estimatedAmount && (
        <>
          <FormattedMessage id="billPlan.estimatedAmount" />
          <MoneyValue money={estimatedAmount} />
        </>
      ),
    [estimatedAmount]
  );
  const actionLabel = useMemo(
    () => ({
      remove: { scriptType }
    }),
    [scriptType]
  );

  const onDeleteScriptHandler = useCallback(() => {
    openDialog();
  }, [openDialog]);

  const onCancel = useCallback(() => {
    resetForm();
    onCancelCallback && onCancelCallback();
  }, [resetForm, onCancelCallback]);

  const displayTitle = useMemo(
    () =>
      values.scripts.some(
        s => s && s[taskKeyUri] && s[taskKeyUri].errorDetails
      ) ? (
        <ErrorTitle displayText={displayText} hasError />
      ) : (
        displayText
      ),
    [displayText, values]
  );

  const { showScriptParamsEditorTable } = useScriptParamsEditableModifier({
    scriptDetails
  });

  const actionComponentCustomProps = useMemo(
    () => ({
      showRemoveAction: showScriptParamsEditorTable
    }),
    [showScriptParamsEditorTable]
  );

  const projectDetails = useMemo(
    () => ({
      startDate,
      endDate,
      projectId,
      projectName,
      projectSlug,
      projectCurrency,
      projectPermissions
    }),
    [
      endDate,
      projectCurrency,
      projectId,
      projectName,
      projectPermissions,
      projectSlug,
      startDate
    ]
  );

  return (
    <>
      <EditableCard
        editable={editable}
        className={classes.card}
        editContentClassName={classes.root}
        classes={useMemo(
          () => ({
            card: classes.card
          }),
          [classes.card]
        )}
        edit={values.isAddMode}
        dataQeId={displayTitle}
        fullScreen={isMobile}
        ariaLabel={ariaLabel}
      >
        {!values.isAddMode && (
          <ReadOnly title={displayTitle} subTitle={subTitle} expandable>
            <ReadOnlyContent>
              <ScriptParamsEditor
                editable={false}
                values={values}
                readOnlyParameters={readOnlyParameters}
                allowAddScriptParam={allowAddScriptParam}
                customFormatters={customFormatters}
                isMobile={isMobile}
                {...additionalProps}
              />
            </ReadOnlyContent>
          </ReadOnly>
        )}
        <Edit
          saveable={!isSubmitting}
          onSave={handleSubmit}
          validateForm={validateForm}
          onCancel={onCancel}
          onRemove={onDeleteScriptHandler}
          actionLabel={actionLabel}
          actionComponentCustomProps={actionComponentCustomProps}
          ActionComponent={BillPlanActionComponent}
        >
          <EditTitle>{`${scriptType}: ${displayText}`}</EditTitle>
          <EditContent className={classes.content}>
            {Boolean(BillPlanPreRequisites) && (
              <BillPlanPreRequisites
                projectDetails={projectDetails}
                clientUri={clientUri}
                scriptDetails={scriptDetails}
              />
            )}
            <ScriptParamsEditor
              editable
              values={values}
              readOnlyParameters={readOnlyParameters}
              allowAddScriptParam={allowAddScriptParam}
              setFieldValue={setFieldValue}
              customEditors={customEditors}
              HeaderComponent={hasHeaderComponent ? HeaderComponent : null}
              errors={errors}
              onDeleteRow={onDeleteRow}
              isMobile={isMobile}
              BillPlanPreRequisites={
                values.isAddMode ? BillPlanPreRequisites : null
              }
              {...additionalProps}
              slug={scriptDetails.slug}
              showScriptParamsEditorTable={showScriptParamsEditorTable}
            />
          </EditContent>
        </Edit>
      </EditableCard>
      {dialogOpen && (
        <DeleteScriptConfirmationDialog
          openDialog={dialogOpen}
          onDialogClose={closeDialog}
          onConformClick={onDeleteScript(planId)}
          scriptType={displayText}
        />
      )}
    </>
  );
};

ScriptParamsEditableCard.propTypes = {
  intl: PropTypes.object,
  values: PropTypes.object.isRequired,
  allowAddScriptParam: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  onDeleteScript: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func,
  validateForm: PropTypes.func,
  onCancelCallback: PropTypes.func,
  customEditors: PropTypes.object,
  customFormatters: PropTypes.object,
  errors: PropTypes.object,
  editable: PropTypes.bool,
  isAddMode: PropTypes.bool,
  readOnlyParameters: PropTypes.array,
  additionalProps: PropTypes.object,
  HeaderComponent: PropTypes.func,
  onDeleteRow: PropTypes.func,
  BillPlanPreRequisites: PropTypes.func,
  scriptDetails: PropTypes.object,
  projectSlug: PropTypes.string,
  projectId: PropTypes.string,
  canViewParams: PropTypes.bool,
  canEditParams: PropTypes.bool,
  clientUri: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  projectName: PropTypes.string,
  showScriptParamsEditorTable: PropTypes.bool,
  projectPermissions: PropTypes.object,
  isSubmitting: PropTypes.bool,
  projectCurrency: PropTypes.object
};

export default withFormState(ScriptParamsEditableCard);
