import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  PanelTitle,
  ProjectsFlatOverviewItemExpansionPanel
} from '../../common';
import ProjectsExpansionPanelDetails from './ProjectsExpansionPanelDetails';

export const OVER_ESTIMATED_TASK_ENUM = {
  OVER_ESTIMATED_COMPLETION_DATE: 'OVER_ESTIMATED_COMPLETION_DATE',
  OVER_ESTIMATED_HOURS: 'OVER_ESTIMATED_HOURS'
};

const titleMessageValues = {
  suffix: (
    <strong>
      <FormattedMessage id="projectOverview.hoursEstimate" />
    </strong>
  )
};

export const TasksSummary = ({ activeTasksSummary }) => {
  const overEstimatedHoursTitle = useMemo(
    () => (
      <FormattedMessage
        id="projectOverview.overEstimate"
        values={titleMessageValues}
      />
    ),
    []
  );
  const overEstimatedDateTitle = useMemo(
    () => (
      <FormattedMessage
        id="projectOverview.overEstimate"
        values={{
          suffix: (
            <strong>
              <FormattedMessage id="projectOverview.endDate" />
            </strong>
          )
        }}
      />
    ),
    []
  );

  return (
    <>
      {activeTasksSummary.overEstimatedHourTasksCount > 0 && (
        <ProjectsFlatOverviewItemExpansionPanel
          counts={activeTasksSummary.overEstimatedHourTasksCount}
          TitleComponent={PanelTitle}
          title={overEstimatedHoursTitle}
        >
          <ProjectsExpansionPanelDetails
            overEstimatedTaskType={
              OVER_ESTIMATED_TASK_ENUM.OVER_ESTIMATED_HOURS
            }
          />
        </ProjectsFlatOverviewItemExpansionPanel>
      )}
      {activeTasksSummary.overEstimatedDateTasksCount > 0 && (
        <ProjectsFlatOverviewItemExpansionPanel
          counts={activeTasksSummary.overEstimatedDateTasksCount}
          TitleComponent={PanelTitle}
          title={overEstimatedDateTitle}
        >
          <ProjectsExpansionPanelDetails
            overEstimatedTaskType={
              OVER_ESTIMATED_TASK_ENUM.OVER_ESTIMATED_COMPLETION_DATE
            }
          />
        </ProjectsFlatOverviewItemExpansionPanel>
      )}
    </>
  );
};

TasksSummary.propTypes = {
  activeTasksSummary: PropTypes.object
};

export default TasksSummary;
