import { useMemo } from 'react';
import useFilterBasedOnSearchCriteria from './useFilterBasedOnSearchCriteria';

const useResourcePlanFilterBasedOnSearchCriteria = ({ searchCriteria }) => {
  const { filter } = useFilterBasedOnSearchCriteria({ searchCriteria });

  return useMemo(
    () => ({
      planFilter: filter
        ? {
            ...filter,
            text: filter && filter.name,
            name: undefined
          }
        : undefined
    }),
    [filter]
  );
};

export default useResourcePlanFilterBasedOnSearchCriteria;
