import React from 'react';
import { useSearchableRoles } from '~/modules/common/hooks/useRoles';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { roleTag, primaryRoleTag } from './tag';
import RoleFacetDetails from './RoleFacetDetails';

const createFacetControl = labelResourceKey => (props = {}) => (
  <RoleFacetDetails labelResourceKey={labelResourceKey} {...props} />
);

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableRoles);

export const primaryRoleFacet = {
  tag: primaryRoleTag,
  makeCriteriaProvider,
  DetailsControl: createFacetControl('resourcePoolSearchBox.primaryRole')
};

export default {
  tag: roleTag,
  makeCriteriaProvider,
  DetailsControl: createFacetControl('resourcePoolSearchBox.role')
};
