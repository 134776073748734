import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';

export const useBuildBillLineItemOptions = ({
  classes = {},
  selectedColumns = [],
  isPsaFPOvertimeBillingEnabled
}) => {
  const lineItemOptions = {
    projectName: {
      groupId: BILLING_COLUMN_TYPE.PROJECT,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.PROJECT),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.PROJECT),
      value: <FormattedMessage id="billingItemTable.project" />,
      className: classes.projectName
    },
    itemType: {
      groupId: BILLING_COLUMN_TYPE.ITEMTYPE,
      align: 'left',
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.ITEMTYPE),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.ITEMTYPE),
      value: <FormattedMessage id="billingItemTable.itemType" />,
      className: classes.itemType
    },
    taskName: {
      groupId: BILLING_COLUMN_TYPE.TASK,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.TASK),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.TASK),
      value: <FormattedMessage id="billingItemTable.task" />,
      className: classes.taskName
    },
    user: {
      groupId: BILLING_COLUMN_TYPE.USER,
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.USER),
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.USER),
      value: <FormattedMessage id="billingItemTable.resource" />,
      grouping: true,
      className: classes.user
    },
    timesheetPeriod: {
      groupId: BILLING_COLUMN_TYPE.TIMESHEET_PERIOD,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.TIMESHEET_PERIOD),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.TIMESHEET_PERIOD),
      value: <FormattedMessage id="billingItemTable.timesheetPeriod" />,
      className: classes.timesheetPeriod
    },
    ...(isPsaFPOvertimeBillingEnabled && {
      payCode: {
        groupId: BILLING_COLUMN_TYPE.PAYCODE,
        sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.PAYCODE),
        visible: selectedColumns.includes(BILLING_COLUMN_TYPE.PAYCODE),
        value: <FormattedMessage id="billingItemTable.payCode" />,
        className: classes.payCode
      }
    }),
    ...(isPsaFPOvertimeBillingEnabled && {
      role: {
        groupId: BILLING_COLUMN_TYPE.ROLE,
        sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.ROLE),
        visible: selectedColumns.includes(BILLING_COLUMN_TYPE.ROLE),
        value: <FormattedMessage id="billingItemTable.role" />,
        className: classes.role
      }
    }),
    expenseCode: {
      groupId: BILLING_COLUMN_TYPE.EXPENSE_CODE,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.EXPENSE_CODE),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.EXPENSE_CODE),
      align: 'left',
      value: <FormattedMessage id="billingItemTable.expenseCode" />,
      className: classes.expenseCode
    },
    quantity: {
      groupId: BILLING_COLUMN_TYPE.UNIT,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.UNIT),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.UNIT),
      align: 'right',
      value: <FormattedMessage id="billingItemTable.units" />,
      className: classes.quantity
    },
    billingItemRate: {
      groupId: BILLING_COLUMN_TYPE.RATE,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.RATE),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.RATE),
      value: <FormattedMessage id="billingItemTable.rate" />,
      align: 'right',
      className: classes.billingItemRate
    }
  };

  return Object.values(lineItemOptions).sort(
    (a, b) => a.sortIndex - b.sortIndex
  );
};
