export const getCostAndHoursDelta = ({
  newTotalCost = { currency: null, amount: 0 },
  newHours = 0,
  initialTotalCost = { currency: null, amount: 0 },
  initialHours = 0
}) => {
  return {
    totalCost: {
      currency: newTotalCost?.currency || initialTotalCost?.currency,
      costDelta: (newTotalCost?.amount || 0) - (initialTotalCost?.amount || 0)
    },
    totalHours: {
      hoursDelta: (newHours || 0) - (initialHours || 0)
    }
  };
};
