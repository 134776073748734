import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { PROJECT_DETAILS_QUERY } from '~/modules/projects/project/ProjectPage/hooks/useProjectDetails';

export const UPDATE_CO_MANAGERS_MUTATION = gql`
  mutation UpdateCoManagers($projectId: String!, $userIds: [String!]!) {
    updateCoManagers(projectId: $projectId, userIds: $userIds)
  }
`;

export const updateCache = ({ projectSlug, coManagers }) => cache => {
  const storedResult = cache.readQuery({
    query: PROJECT_DETAILS_QUERY,
    variables: {
      projectSlug
    }
  });

  cache.writeQuery({
    query: PROJECT_DETAILS_QUERY,
    variables: {
      projectSlug
    },
    data: {
      project: {
        ...storedResult.project,
        coManagers
      }
    }
  });
};

export const useUpdateCoManagers = ({ projectSlug }) => {
  const [updateCoManagers] = useMutation(UPDATE_CO_MANAGERS_MUTATION);

  return {
    updateCoManagers: useCallback(
      ({ projectUri, coManagers }) =>
        updateCoManagers({
          variables: {
            projectId: projectUri,
            userIds: coManagers.map(e => e.id)
          },
          update: updateCache({ projectSlug, coManagers })
        }),
      [projectSlug, updateCoManagers]
    )
  };
};
