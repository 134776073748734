import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  projectName: {
    fontSize: theme.typography.caption.fontSize
  },
  date: {
    fontSize: theme.typography.caption.fontSize
  },
  projectList: {
    padding: 0,
    margin: theme.spacing(1, 0, 0, 0),
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0
  },
  allocationItem: {
    padding: 0,
    margin: 0,
    display: 'contents'
  },
  value: {
    textAlign: 'left',
    paddingRight: theme.spacing(1)
  },
  label: {
    textAlign: 'left'
  },
  totalItem: {
    '& $percentage, & $label': {
      fontWeight: theme.typography.fontWeightBold,
      borderTop: '1px solid #fff'
    }
  }
}));

export const useDateStyles = makeStyles(theme => ({
  date: {
    fontSize: theme.typography.caption.fontSize
  }
}));
