import { gql } from 'graphql-tag';

export const CLIENT_TOTALS_QUERY = gql`
  query Eager_clientTotals($clientFilter: ClientFilter2) {
    clientTotals(clientFilter: $clientFilter) {
      projectCount
      totalEstimatedHours
      totalActualHours
      totalEstimatedCost {
        amount
        currency {
          id
          symbol
        }
      }
      totalActualExpenseCost {
        amount
        currency {
          id
          symbol
        }
      }
      totalActualResourceCost {
        amount
        currency {
          id
          symbol
        }
      }
      totalActualCost {
        amount
        currency {
          id
          symbol
        }
      }
      totalEstimatedBilling {
        amount
        currency {
          id
          symbol
        }
      }
      totalActualBilling {
        amount
        currency {
          id
          symbol
        }
      }
      totalActualRevenue {
        amount
        currency {
          id
          symbol
        }
      }
    }
  }
`;
