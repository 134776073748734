export const PORTFOLIO_TABS = { LIST: 'list' };
export const PORTFOLIO_SUMMARY_FILTER_TABS = {
  ALL_PORTFOLIOS: 'ALL_PORTFOLIOS',
  MY_PORTFOLIOS: 'MY_PORTFOLIOS',
  INACTIVE: 'INACTIVE'
};
export const PORTFOLIO_BUDGET_OPTIONS = {
  TOTAL: 'TOTAL',
  CAPEX_OPEX: 'CAPEX_OPEX'
};
