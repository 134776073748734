import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  RequestActionButton,
  SubmittedRequestActionButton
} from '~/modules/resourcing/common/components';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useIsBreakpointUp } from '~/modules/common/hooks';
import { hasPermission } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me/useMeContext';

export const useAddResourceRequestDrawerActions = ({
  closeDrawer,
  setSnackBarMessage,
  onResourceRequestSave,
  onResourceRequestSubmit
}) => {
  const { userAccessRoles, permissionsMap } = useMeContext();
  const isSMViewAndUp = useIsBreakpointUp('sm');
  const isMDViewAndUp = useIsBreakpointUp('md');
  const history = useHistory();
  const { formatMessage } = useIntl();

  const isViewUserEnabledForResourceManager = hasPermission(permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-user',
    dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
  });

  const isViewUserEnabledForResourcePoolManager = hasPermission(permissionsMap)(
    {
      actionUri: 'urn:replicon:user-action:view-user',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    }
  );

  const isViewUserEnabled =
    isViewUserEnabledForResourceManager ||
    isViewUserEnabledForResourcePoolManager;

  const canEditResourceAssignments = hasPermission(permissionsMap)({
    actionUri:
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
  });

  const isUserHasResourceManagerRole = userAccessRoles.includes(
    USER_ACCESS_ROLE.RESOURCE_MANAGER
  );

  const showAllocateResourcesButtonForDraftRequest =
    isMDViewAndUp &&
    isViewUserEnabled &&
    canEditResourceAssignments &&
    isUserHasResourceManagerRole;

  const assignResourceAction = {
    id: 'assign-resource',
    overflow: 'never',
    variant: 'contained',
    label: formatMessage({
      id: 'resourceAllocationActions.allocateResource'
    }),
    onClick: useCallback(async () => {
      const { requestId, projectId } = await onResourceRequestSubmit();

      history.push(
        `/projectResourcing/assignment/${projectId}/all/${requestId}`
      );
    }, [history, onResourceRequestSubmit]),
    buttonComponent: SubmittedRequestActionButton
  };

  return [
    showAllocateResourcesButtonForDraftRequest && assignResourceAction,
    {
      id: 'submit',
      overflow: 'never',
      variant: 'contained',
      label: formatMessage({ id: 'resourceRequestActions.submitRequest' }),
      onClick: useCallback(async () => {
        await onResourceRequestSubmit();
        setSnackBarMessage({
          type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
          resourceRequestButtonActionMessage: formatMessage({
            id: 'resourceRequestActions.requestSubmitted'
          })
        });
      }, [formatMessage, onResourceRequestSubmit, setSnackBarMessage]),
      buttonComponent: SubmittedRequestActionButton,
      secondary: showAllocateResourcesButtonForDraftRequest
    },
    {
      id: 'save',
      overflow: 'xs',
      variant: 'contained',
      label: formatMessage({ id: 'resourceRequestActions.saveDraft' }),
      onClick: useCallback(async () => {
        await onResourceRequestSave();
        setSnackBarMessage({
          type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
          resourceRequestButtonActionMessage: formatMessage({
            id: 'resourceRequestActions.requestSaved'
          })
        });
      }, [formatMessage, onResourceRequestSave, setSnackBarMessage]),
      buttonComponent: RequestActionButton,
      secondary: !isSMViewAndUp
    },
    {
      id: 'cancel',
      overflow: 'never',
      label: formatMessage({ id: 'resourceRequestActions.cancel' }),
      onClick: closeDrawer
    }
  ].filter(action => action);
};

export default useAddResourceRequestDrawerActions;
