import { DateTime } from 'luxon';
import memoize from 'lodash.memoize';
import get from 'lodash.get';

export const isoStringToObject = date => {
  const { day, month, year } = DateTime.fromISO(date, { setZone: true });

  return day && month && year ? { day, month, year } : null;
};

export const isoStringToMidnightJSDate = date => {
  const d = isoStringToObject(date);

  return new Date(d.year, d.month - 1, d.day);
};

export const isoStringToObjectWithCache = memoize(isoStringToObject);

export const dateToMidnightUTCString = date => {
  const d = DateTime.fromISO(date, { setZone: true });

  return DateTime.utc(d.year, d.month, d.day, 0, 0, 0, 0).toISO();
};

export const dateToMidnightUTCObject = date => {
  const d = DateTime.fromISO(date, { setZone: true });

  return DateTime.utc(d.year, d.month, d.day, 0, 0, 0, 0);
};

export const mapRepliconDateToMidnightUTCString = date =>
  mapRepliconDateToUtcObject(date).toISO();

export const mapRepliconDateToUtcObject = date =>
  DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0);

export const mapRepliconDateToJSDate = date =>
  date && new Date(date.year, date.month - 1, date.day);

export const momentDateToISOString = date =>
  DateTime.fromObject({
    year: date.year(),
    month: date.month() + 1,
    day: date.date(),
    zone: 'utc'
  }).toISO();

export const mapJSDateToRepliconDate = d =>
  d
    ? {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      }
    : null;

export const mapIsoStringtoUtcObject = dateString =>
  DateTime.fromISO(dateString, { setZone: true });

export const mapMomentDateToRepliconDate = date =>
  date
    ? {
        year: date.year(),
        month: date.month() + 1,
        day: date.date()
      }
    : null;

export const toRepliconDate = dateTime =>
  dateTime
    ? {
        year: dateTime.year,
        month: dateTime.month,
        day: dateTime.day
      }
    : null;

export const objectToUTCString = date =>
  DateTime.fromObject({ ...date, zone: 'utc' }).toISO();

export const objectToFormatString = (date, format) =>
  date && format
    ? DateTime.fromObject({ ...date, zone: 'utc' }).toFormat(format)
    : null;

export const getLuxonJsDateFormatStringFromDotNetPattern = (
  dotNetPattern = 'MMM d, yyyy'
) => {
  let luxonFormatString = dotNetPattern;

  luxonFormatString = luxonFormatString.replace(/dddd/g, 'cccc');
  luxonFormatString = luxonFormatString.replace(/ddd/g, 'ccc');
  luxonFormatString = luxonFormatString.replace(/tt/g, 'a');

  return luxonFormatString;
};

export const getLuxonJsDateFormatFromMe = me => {
  const dotNetPattern = get(
    me,
    'locale.dateTimeSettings.shortDateFormat.dotNetPattern'
  );

  return dotNetPattern
    ? getLuxonJsDateFormatStringFromDotNetPattern(dotNetPattern)
    : 'MMM d, yyyy';
};

export const getLuxonJsDateTimeFormatFromMe = me => {
  const dotNetPattern = get(
    me,
    'locale.dateTimeSettings.shortDateFormat.dotNetPattern'
  );
  const dotNetTimePattern = get(
    me,
    'locale.dateTimeSettings.shortTimeFormat.dotNetPattern'
  );

  const combinedPattern = `${dotNetPattern} ${dotNetTimePattern}`;

  return dotNetPattern && dotNetTimePattern
    ? getLuxonJsDateFormatStringFromDotNetPattern(combinedPattern)
    : "MMM d, yyyy h':'mm tt";
};

export const dateObjectToIsoDateOrDefault = (date, defaultVal = null) =>
  date ? DateTime.fromObject(date).toISODate() : defaultVal;

export const mapJsDateToLuxonUtcDate = JSDate => {
  if (!JSDate) {
    return null;
  }

  return DateTime.fromObject({
    year: JSDate.getFullYear(),
    month: JSDate.getMonth() + 1,
    day: JSDate.getDate(),
    zone: 'utc'
  });
};

export const isoStringToFormattedISOString = (isoString, format) => {
  if (!isoString) {
    return null;
  }

  return mapIsoStringtoUtcObject(isoString).toFormat(format);
};
