/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { Decimal } from '~/modules/common/components';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import {
  DISPLAY_UNIT_ENUM,
  RESIZE_DIRECTION_ENUM
} from '~/modules/resourcing/common/enums';
import {
  getDayViewRoundedHours,
  getDayViewRoundedPercentage
} from '~/modules/resourcing/common/util/scheduleUtil';
import { DragEndIndicator, DragStartIndicator } from '../DragIndicator';
import useStyles from './useStyles';

const PeriodTimelineBlocks = ({
  classes: classesOverrides,
  scale,
  onPeriodItemClick,
  renderPeriodItem,
  quantity = 1,
  renderPeriodItemTooltip,
  gestureBindEvents,
  dragDelta,
  resizeDirection,
  displayPeriods = [],
  qeIdTag
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const dragIndicatorClasses = useMemo(
    () => ({
      resizeHandle: classes.resizeHandle,
      resizeHandleStart: classes.resizeHandleStart,
      resizeHandleEnd: classes.resizeHandleEnd,
      resizeIcon: classes.resizeIcon
    }),
    [
      classes.resizeHandle,
      classes.resizeHandleEnd,
      classes.resizeHandleStart,
      classes.resizeIcon
    ]
  );

  const { displayUnit = DISPLAY_UNIT_ENUM.HOURS } =
    useDisplayUnitContext() || {};

  const handlePeriodItemClick = useCallback(
    ({ event, startDate, endDate, totalHours }) => {
      if (onPeriodItemClick)
        onPeriodItemClick({ event, startDate, endDate, totalHours });
    },
    [onPeriodItemClick]
  );
  const displayPeriodStartDate =
    displayPeriods.length && displayPeriods[0].startDate;
  const displayPeriodEndDate =
    displayPeriods.length && displayPeriods[displayPeriods.length - 1].endDate;

  const onDragDynamicAttributes = ({ startDate, endDate, dynamicPosition }) => {
    const leftMostPeriod =
      displayPeriodStartDate === startDate &&
      resizeDirection === RESIZE_DIRECTION_ENUM.START;
    const rightMostPeriod =
      displayPeriodEndDate === endDate &&
      resizeDirection === RESIZE_DIRECTION_ENUM.END;

    return {
      ...dynamicPosition,
      top: '0px',
      left: leftMostPeriod
        ? dragDelta + dynamicPosition.left
        : dynamicPosition.left,
      width:
        leftMostPeriod && dragDelta < 0
          ? dynamicPosition.width - dragDelta
          : rightMostPeriod
          ? dynamicPosition.width + dragDelta
          : dynamicPosition.width
    };
  };

  return (
    <div
      className={classes.root}
      data-qe-id={
        qeIdTag ? `PeriodTimelineBlocks_${qeIdTag}` : 'PeriodTimelineBlocks'
      }
    >
      {displayPeriods.map(
        (
          { dynamicPosition, startDate, endDate, totalHours, percentage, key },
          index
        ) => {
          const dynamicePositionOverride = onDragDynamicAttributes({
            startDate,
            endDate,
            dynamicPosition
          });

          const percentageValue = getDayViewRoundedPercentage({
            percentage,
            scale
          });
          const totalAllocatedHours = getDayViewRoundedHours({
            hours: totalHours,
            scale
          });

          const periodContent = (
            <div
              role="button"
              key={key}
              className={classNames(classes.period, classes.periodClickable)}
              style={dynamicePositionOverride}
              onClick={event =>
                handlePeriodItemClick({ event, startDate, endDate, totalHours })
              }
              tabIndex="0"
            >
              {renderPeriodItem ? (
                renderPeriodItem({
                  startDate,
                  endDate,
                  totalHours,
                  percentage
                })
              ) : displayUnit === DISPLAY_UNIT_ENUM.HOURS ||
                !isFinite(percentageValue) ? (
                <div className={classes.loadingLabel}>
                  <Decimal
                    value={totalAllocatedHours / (quantity || 1)}
                    suffix="h"
                    fixedDecimalScale={false}
                    enableFixedFormatDecimalScale
                  />
                </div>
              ) : (
                <div className={classes.loadingLabel}>
                  <Decimal
                    value={percentageValue}
                    suffix="%"
                    fixedDecimalScale={false}
                  />
                </div>
              )}
              {displayPeriodStartDate === startDate && gestureBindEvents && (
                <DragStartIndicator
                  classes={dragIndicatorClasses}
                  gestureBindEvents={gestureBindEvents}
                />
              )}
              {displayPeriodEndDate === endDate && gestureBindEvents && (
                <DragEndIndicator
                  classes={dragIndicatorClasses}
                  gestureBindEvents={gestureBindEvents}
                />
              )}
            </div>
          );

          return renderPeriodItemTooltip ? (
            <Tooltip
              key={key}
              title={renderPeriodItemTooltip({
                dynamicPosition,
                startDate,
                endDate,
                totalHours
              })}
            >
              {periodContent}
            </Tooltip>
          ) : (
            periodContent
          );
        }
      )}
    </div>
  );
};

PeriodTimelineBlocks.propTypes = {
  scale: PropTypes.string.isRequired,
  onPeriodItemClick: PropTypes.func,
  renderPeriodItem: PropTypes.func,
  renderPeriodItemTooltip: PropTypes.func,
  classes: PropTypes.object,
  quantity: PropTypes.number,
  gestureBindEvents: PropTypes.func,
  dragDelta: PropTypes.number,
  resizeDirection: PropTypes.oneOf([
    RESIZE_DIRECTION_ENUM.START,
    RESIZE_DIRECTION_ENUM.END
  ]),
  displayPeriods: PropTypes.array,
  qeIdTag: PropTypes.string
};

export default PeriodTimelineBlocks;
