import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  ListSubheader
} from '@material-ui/core';
import { UserWithRoles } from '~/modules/common/components/User';
import useTaskOwnerSearch from '~/modules/tasks/components/TaskOwnerDropDown/useTaskOwnerSearch';
import TaskOwnerSearchMenuList from '~/modules/tasks/components/TaskOwnerDropDown/TaskOwnerSearchMenuList';
import { useProjectResourcesWithRole } from './useProjectResourcesWithRole';

const useStyles = makeStyles(theme => ({
  menu: {
    maxWidth: 320
  },
  title: {
    padding: theme.spacing(1, 2)
  },
  midListSubheader: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2)
  },
  noMessage: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 2)
  }
}));

const isUserSelected = ({ user, assignedUser }) =>
  assignedUser && assignedUser.key ? user.key === assignedUser.key : false;

export const AssignmentMenu = ({
  taskName,
  projectSlug,
  anchorPosition,
  open,
  onClose,
  onUserClick,
  assignedRole,
  assignedUser = {},
  assignedUserRoleId
}) => {
  const classes = useStyles();
  const { loading, users, searchText, updateSearchText } = useTaskOwnerSearch({
    projectSlug
  });

  const [taskOwners, setTaskOwners] = useState(users || []);

  const selectedUser = useMemo(
    () =>
      assignedUser
        ? {
            ...assignedUser,
            key: assignedUserRoleId
              ? `${assignedUser.id}-${assignedUserRoleId}`
              : `${assignedUser.id}-no-role`
          }
        : {},
    [assignedUser, assignedUserRoleId]
  );

  useEffect(() => {
    if (!loading) {
      setTaskOwners(users || []);
    }
  }, [loading, setTaskOwners]);

  const onSearchChange = useCallback(
    event => {
      if (event.target && event.target.value) {
        const filteredUsers = (users || []).filter(user =>
          user.displayText
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        );

        setTaskOwners(filteredUsers);
      } else {
        setTaskOwners(users || []);
      }
      updateSearchText(event.target.value || '');
    },
    [users, updateSearchText]
  );

  const makeOnChange = useCallback(user => () => onUserClick(user), [
    onUserClick
  ]);

  const menuListTitle = useMemo(() => {
    const titleValues = {
      task: <strong>{taskName}</strong>
    };

    return (
      <Typography className={classes.title} variant="subtitle2" noWrap>
        <FormattedMessage
          id="projectTasksPage.taskOwnerFor"
          values={titleValues}
        />
      </Typography>
    );
  }, [classes.title, taskName]);

  const MenuListProps = useMemo(
    () => ({
      component: TaskOwnerSearchMenuList,
      searchText,
      onSearchChange,
      loading,
      title: menuListTitle
    }),
    [searchText, onSearchChange, loading, menuListTitle]
  );

  const menuClasses = useMemo(
    () => ({
      paper: classes.menu
    }),
    [classes.menu]
  );

  const [
    projectResourcesWithSelectedRole,
    otherProjectResources
  ] = useProjectResourcesWithRole({
    taskOwners: taskOwners || [],
    assignedRole
  });

  return (
    <Menu
      classes={menuClasses}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      open={open}
      onClose={onClose}
      MenuListProps={MenuListProps}
    >
      {assignedRole ? (
        <TaskOwnerWithSelectedRole
          onUserClick={onUserClick}
          makeOnChange={makeOnChange}
          assignedRole={assignedRole}
          assignedUser={selectedUser}
          projectResourcesWithSelectedRole={projectResourcesWithSelectedRole}
        />
      ) : null}
      <MenuItem value="" onClick={makeOnChange()}>
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="projectTasksPage.noTaskOwner" />
        </Typography>
      </MenuItem>
      {otherProjectResources
        .filter(user => user.isEnabled)
        .map(user => (
          <MenuItem
            key={user.key}
            value={user.key}
            onClick={makeOnChange(user)}
            selected={isUserSelected({ user, assignedUser: selectedUser })}
          >
            <UserWithRoles user={user} />
          </MenuItem>
        ))}
    </Menu>
  );
};

export const TaskOwnerWithSelectedRole = ({
  makeOnChange,
  assignedRole,
  assignedUser,
  projectResourcesWithSelectedRole
}) => {
  const classes = useStyles();

  const noResourcesAllocatedTitle = useMemo(() => {
    const titleValues = {
      role: <strong>{assignedRole.displayText}</strong>
    };

    return (
      <div key="no-resources-message" className={classes.noMessage}>
        <FormattedMessage
          id="projectTasksPage.noResourcesAllocated"
          values={titleValues}
        />
      </div>
    );
  }, [classes.noMessage, assignedRole.displayText]);

  return assignedRole ? (
    <>
      <ListSubheader key="role-name">{assignedRole.displayText}</ListSubheader>
      {projectResourcesWithSelectedRole.length > 0
        ? projectResourcesWithSelectedRole
            .filter(user => user.isEnabled)
            .map(user => (
              <MenuItem
                key={user.key}
                value={user.key}
                onClick={makeOnChange(user)}
                selected={isUserSelected({ user, assignedUser })}
              >
                <UserWithRoles user={user} />
              </MenuItem>
            ))
        : noResourcesAllocatedTitle}
      <ListSubheader key="subheader-owner" className={classes.midListSubheader}>
        <FormattedMessage id="projectTasksPage.otherProjectResources" />
      </ListSubheader>
    </>
  ) : null;
};

TaskOwnerWithSelectedRole.propTypes = {
  makeOnChange: PropTypes.func,
  assignedRole: PropTypes.object,
  assignedUser: PropTypes.object,
  projectResourcesWithSelectedRole: PropTypes.array
};

AssignmentMenu.propTypes = {
  taskName: PropTypes.string,
  projectSlug: PropTypes.string.isRequired,
  anchorPosition: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUserClick: PropTypes.func,
  assignedRole: PropTypes.object,
  assignedUser: PropTypes.object,
  assignedUserRoleId: PropTypes.string
};

export default AssignmentMenu;
