import React, { useCallback } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { useSkillCategories } from '~/modules/common/hooks';
import SkillCategoryDropdownItem from './SkillCategoryDropdownItem';

const filter = createFilterOptions();

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const getOptionLabel = option => option.displayText;

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (
    params.inputValue !== '' &&
    !options.some(o => o.displayText === params.inputValue)
  ) {
    filtered.push({
      isCreatable: true,
      id: params.inputValue,
      displayText: params.inputValue
    });
  }

  return filtered;
};

const SkillCategoryDropdown = () => {
  const { formatMessage } = useIntl();
  const { loading, skillCategories } = useSkillCategories();
  const [field, , helpers] = useField('category');
  const { setValue } = helpers;

  const createNewValue = newOption => ({
    name: newOption,
    displayText: newOption,
    isNew: true
  });

  const onChange = useCallback(
    (event, newValue) => {
      if (newValue?.isCreatable && event.keyCode !== 13) {
        return null;
      }
      const changeValue =
        event.keyCode === 13 && newValue?.isCreatable
          ? createNewValue(newValue.displayText)
          : newValue;

      return changeValue
        ? setValue({
            id: changeValue.id,
            name: changeValue.displayText,
            displayText: changeValue.displayText,
            isNew: changeValue.isNew
          })
        : setValue(null);
    },
    [setValue]
  );

  const onCreateOption = useCallback(
    (event, newOption) => onChange(event, createNewValue(newOption)),
    [onChange]
  );

  const label = formatMessage({ id: 'skillsDropdown.category' });

  const renderInput = useCallback(
    params => <TextField {...params} label={label} required />,
    [label]
  );

  const { value } = field;

  const renderOption = useCallback(
    option => (
      <SkillCategoryDropdownItem option={option} onCreate={onCreateOption} />
    ),
    [onCreateOption]
  );

  return (
    <Autocomplete
      loading={loading}
      forcePopupIcon
      filterOptions={filterOptions}
      fullWidth
      freeSolo
      value={value}
      onChange={onChange}
      options={skillCategories}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
    />
  );
};

export default SkillCategoryDropdown;
