import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { NoDataItem } from '~/modules/common/components';
import { ListTable } from '~/modules/common/components/ListTable';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

import { useMeContext } from '~/modules/me';
import { useListColumns } from './columns';
import { useListFooters } from './footers';
import {
  useListTableThemeProviderStyles,
  useColumnStyles,
  useTableStyles,
  useNoDataStyles
} from './useLineItemsStyles';

const accesibilityLables = ({ intl }) => ({
  deleteLineItemAriaLabel: intl.formatMessage({
    id: 'lineItemsTable.lineItem.deleteLineItemAriaLabel'
  }),
  amountAriaLabel: intl.formatMessage({
    id: 'lineItemsTable.lineItem.amountAriaLabel'
  })
});

const itemTypeLabels = {
  FIXED_BID: <FormattedMessage id="itemTypeLabels.fixedBid" />,
  TIMESHEET: <FormattedMessage id="itemTypeLabels.timesheet" />,
  EXPENSE: <FormattedMessage id="itemTypeLabels.expense" />,
  null: <FormattedMessage id="itemTypeLabels.noData" />
};

export const mapLineItemsItemTypeDisplayText = lineItems =>
  lineItems.map(lineItem => ({
    ...lineItem,
    billingType: itemTypeLabels[lineItem.billingType]
  }));

export const LineItemsTable = ({
  lineItems,
  onAmountBlur,
  onAmountChange,
  lineItemsTotal,
  editable,
  onDelete,
  isLoading,
  loadMore,
  hasMore,
  resourceKeys,
  summarizeColumnOptions = [],
  isLineItemUpdating,
  onRemove,
  onClose,
  hasAccessLineItems,
  hasAdhocLineItems = false,
  allLineItemsLength
}) => {
  const me = useMeContext();
  const isPsaFPOvertimeBillingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFPOvertimeBillingEnabled'
  });
  const intl = useIntl();
  const { deleteLineItemAriaLabel, amountAriaLabel } = accesibilityLables({
    intl
  });

  const isBillingManager = me.userAccessRoles.includes(
    USER_ACCESS_ROLE.BILLING_MANAGER
  );

  const validateLineItemPermission =
    !isBillingManager && hasAccessLineItems && hasAccessLineItems === 1;

  const providerClasses = useListTableThemeProviderStyles({
    editable,
    hasAdhocLineItems
  });
  const columnClasses = useColumnStyles();
  const classes = useTableStyles();
  const noDataClasses = useNoDataStyles();

  const columns = useListColumns({
    classes: columnClasses,
    resourceKeys: {
      ...resourceKeys,
      deleteLineItemAriaLabel,
      amountAriaLabel
    },
    validateLineItemPermission,
    onLineItemDelete: onDelete,
    onAmountChange,
    onAmountBlur,
    editable,
    selectedColumns: summarizeColumnOptions,
    isLineItemUpdating,
    isLastLineItem: allLineItemsLength === 1,
    onRemove,
    onClose,
    hasAdhocLineItems,
    isPsaFPOvertimeBillingEnabled
  });

  const footers = useListFooters({
    classes: columnClasses,
    editable,
    selectedColumns: summarizeColumnOptions,
    resourceKeys,
    isPsaFPOvertimeBillingEnabled
  });

  const totals = useMemo(
    () => ({
      lineItemsTotal: {
        ...lineItemsTotal,
        amount: getRoundedValue(lineItemsTotal.amount, 2)
      }
    }),
    [lineItemsTotal]
  );

  return lineItems.length === 0 ? (
    <div className={noDataClasses.noData}>
      {resourceKeys.noDataMessage && (
        <NoDataItem>
          <FormattedMessage id={resourceKeys.noDataMessage} />
        </NoDataItem>
      )}
    </div>
  ) : (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTable
        hover={false}
        variant="table"
        classes={classes}
        columns={columns}
        records={mapLineItemsItemTypeDisplayText(lineItems)}
        footers={footers}
        totals={totals}
        hasMore={hasMore}
        loadMore={loadMore}
        isLoading={isLoading}
      />
    </ListTableThemeProvider>
  );
};

LineItemsTable.propTypes = {
  lineItems: PropTypes.array.isRequired,
  lineItemsTotal: PropTypes.object.isRequired,
  onAmountBlur: PropTypes.func,
  onAmountChange: PropTypes.func,
  editable: PropTypes.bool,
  resourceKeys: PropTypes.object,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  summarizeColumnOptions: PropTypes.array,
  isLineItemUpdating: PropTypes.bool,
  onRemove: PropTypes.func,
  onClose: PropTypes.func,
  hasAccessLineItems: PropTypes.any,
  hasAdhocLineItems: PropTypes.bool,
  allLineItemsLength: PropTypes.any
};

export default LineItemsTable;
