import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useHasPermission } from '~/modules/common/permissions';
import { convertDurationToHours } from '~/modules/common/util/util';
import { useQuickAllocationContext } from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import { UserRoleActualsTimeLineRow } from '~/modules/resourcing/common/components';
import { useBulkReleaseResourceAllocations } from '../../../QaReleaseAllocationsDialog';
import QuickAllocationChartTimelineItem2 from './QuickAllocationChartTimelineItem2';

const mergeUserScheduleDetails = ({
  allocation,
  userScheduleDetails: {
    scheduleDurationByDay,
    scheduleDurationByPeriodMap,
    holidays,
    timeoffs
  }
}) => ({
  ...allocation,
  user: {
    ...allocation.user,
    scheduleDurationByDay,
    scheduleDurationByPeriodMap,
    holidays,
    timeoffs
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    overflow: 'hidden'
  },
  rootWithBorder: {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  loadingRoot: {
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  singleAllocationBar: {
    width: '100%',
    paddingTop: theme.spacing(1)
  }
}));
const useActualStyles = makeStyles(theme => ({
  root: ({ allocationsCount }) => ({
    minHeight: (allocationsCount + 1) * theme.spacing(4) + 8
  })
}));

export const QuickAllocationChartTimeline = ({
  actualSummaryBlockPopupProps,
  handleRemoveResourceAllocation,
  loading,
  resourceAllocations,
  resourceUser,
  actualsData,
  index,
  userScheduleDetails,
  classes: classesOverride,
  chartDisplayPeriods,
  allocatedProject,
  setSnackBarState
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { scale, dateRange, isShowActualEnabled } = useQuickAllocationContext();
  const actualClasses = useActualStyles({
    allocationsCount: resourceAllocations?.length || 0
  });

  const userScheduleDetailsOverride = useMemo(() => {
    const {
      resourceAvailabilitySummarySeries,
      holidays,
      timeoffs
    } = resourceUser;

    return {
      ...userScheduleDetails,
      holidays,
      timeoffs,
      scheduleDurationByPeriodMap: (
        resourceAvailabilitySummarySeries || []
      ).reduce((acc, entry) => {
        acc[entry.dateRange.startDate] = {
          ...entry,
          hours: convertDurationToHours(entry.scheduledDuration)
        };

        return acc;
      }, {})
    };
  }, [resourceUser, userScheduleDetails]);

  const isEditAllowed = useHasPermission({
    actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
  });

  const { releaseResourceAllocation } = useBulkReleaseResourceAllocations({
    allocatedProject,
    handleRemoveResourceAllocation,
    resourceAllocations,
    setSnackBarState,
    userName: resourceUser.displayText
  });

  return (
    <div
      className={classNames(classes.root, {
        [actualClasses.root]: !loading && isShowActualEnabled,
        [classes.loadingRoot]: loading,
        [classes.rootWithBorder]: index > 0
      })}
    >
      {loading ? (
        <Skeleton variant="rect" />
      ) : (
        (resourceAllocations || []).map(allocation => {
          return (
            <div key={allocation.id} className={classes.singleAllocationBar}>
              <QuickAllocationChartTimelineItem2
                chartDisplayPeriods={chartDisplayPeriods}
                isEditEnabled={isEditAllowed}
                resourceAllocation={mergeUserScheduleDetails({
                  allocation,
                  userScheduleDetails: userScheduleDetailsOverride
                })}
                resourceUser={resourceUser}
                allocatedProject={allocatedProject}
                releaseResourceAllocation={releaseResourceAllocation}
              />
            </div>
          );
        })
      )}
      {isShowActualEnabled &&
        (loading ? (
          <Skeleton variant="rect" />
        ) : (
          <UserRoleActualsTimeLineRow
            actualSummaryBlockPopupProps={actualSummaryBlockPopupProps}
            actualsData={actualsData}
            chartDisplayDateRange={dateRange}
            chartDisplayPeriods={chartDisplayPeriods}
            resourceAllocations={resourceAllocations}
            scale={scale}
            user={resourceUser}
            userScheduleDetails={userScheduleDetailsOverride}
          />
        ))}
    </div>
  );
};

export default QuickAllocationChartTimeline;

QuickAllocationChartTimeline.propTypes = {
  actualSummaryBlockPopupProps: PropTypes.object,
  actualsData: PropTypes.object,
  handleRemoveResourceAllocation: PropTypes.func,
  classes: PropTypes.object,
  chartDisplayPeriods: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  resourceAllocations: PropTypes.array,
  resourceUser: PropTypes.object,
  allocatedProject: PropTypes.object,
  userScheduleDetails: PropTypes.object.isRequired,
  setSnackBarState: PropTypes.func,
  index: PropTypes.number
};
