import React, { useMemo, useState } from 'react';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const mapOptions = clients =>
  clients.map(({ displayText, id }) => ({ key: displayText, value: id }));

export const matchOption = searchClients => async (_, term) => {
  if (!term) {
    return null;
  }

  const clients = await searchClients(cleanSearchTerm(term));

  const option = clients.shift();

  if (option) {
    return {
      ...option,
      key: option.displayText
    };
  }

  return null;
};

export const withCriteriaProvider = (
  tag,
  context,
  useSearchableClientsWithHasMore
) => BaseComponent => props => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const [hasMore, setHasMore] = useState(false);
  const { searchClients } = useSearchableClientsWithHasMore();
  const $searchClients = searchClients(setHasMore);

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption($searchClients),
      selected,
      setSelected,
      searchClients: $searchClients,
      hasMore,
      setHasMore,
      suggestions: $searchClients
    }),
    [$searchClients, selected, hasMore, setHasMore]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};
