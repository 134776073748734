import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const GET_TAG_ID = gql`
  query getTagId($slug: String!) {
    tagId(slug: $slug)
  }
`;

export const useTagId = ({ slug }) => {
  const { loading, data } = useQuery(GET_TAG_ID, {
    variables: {
      slug
    },
    fetchPolicy: 'cache-and-network'
  });
  const tagId = get(data, 'tagId', null);

  return {
    tagId: loading ? null : tagId,
    loading
  };
};

export default useTagId;
