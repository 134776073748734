import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { ResourceAllocationStatus } from '~/types';

export const resourceAllocationsCountByAllocationStatusQuery = gql`
  query Eager_GetResourceAllocationsCountByAllocationStatus(
    $allocationStatusList: [ResourceAllocationStatus]
    $projectFilter: ProjectFilter
  ) {
    resourceAllocationCount(
      allocationStatusList: $allocationStatusList
      projectFilter: $projectFilter
    )
  }
`;

export const useResourceAllocationsCount = ({ projectFilter }) => {
  const { data, loading } = useQuery(
    resourceAllocationsCountByAllocationStatusQuery,
    {
      variables: {
        allocationStatusList: [ResourceAllocationStatus.Committed],
        projectFilter
      },
      fetchPolicy: 'network-only'
    }
  );

  const resourceAllocationsCount = get(data, 'resourceAllocationCount', {});

  return {
    loading,
    resourceAllocationsCount
  };
};

export default useResourceAllocationsCount;
