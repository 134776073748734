import React, { useMemo } from 'react';
import AlternateTooltip from '~/modules/common/components/TaskStatusSection/Table/components/ChildInfoDialog/AlternateTooltip';
import { isNullOrUndefined } from '~/modules/common/util';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useAlternateTooltipStyles } from './styles';

export const useAlternateHoursTooltips = ({
  isProjectVariant,
  hasChildTasks,
  estimatedHours,
  rolledUpSummary,
  isClosed
}) => {
  const classesOverride = useAlternateTooltipStyles();
  const isPsaPpmCostEacAlternateHoursEacEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPpmCostEacAlternateHoursEacEnabled'
  });
  const {
    totalProjectTasksEstimatedAtCompletionHours,
    totalEstimatedAtCompletionHours,
    actualHours
  } = rolledUpSummary || {};

  const hasAlternateEstimatedAtCompletionHoursValue =
    isPsaPpmCostEacAlternateHoursEacEnabled &&
    !isClosed &&
    isProjectVariant &&
    !actualHours &&
    !isNullOrUndefined(totalEstimatedAtCompletionHours) &&
    isNullOrUndefined(totalProjectTasksEstimatedAtCompletionHours);

  const estimatedAtCompletionHoursRowTooltipCondition = isProjectVariant
    ? !isClosed &&
      (hasChildTasks || hasAlternateEstimatedAtCompletionHoursValue)
    : hasChildTasks;

  const hasAlternateWorkRemainingValue =
    isPsaPpmCostEacAlternateHoursEacEnabled && isProjectVariant && isClosed;

  const workRemainingHoursRowTooltipCondition =
    hasChildTasks || hasAlternateWorkRemainingValue;

  const hideHoursTooltip =
    isPsaPpmCostEacAlternateHoursEacEnabled && isProjectVariant && isClosed;

  const atleastOneHoursRowHasTooltip =
    estimatedAtCompletionHoursRowTooltipCondition ||
    hasChildTasks ||
    workRemainingHoursRowTooltipCondition;

  const estimatedAtCompletionAlternateHoursTooltipComponent = useMemo(
    () =>
      hasAlternateEstimatedAtCompletionHoursValue ? (
        <AlternateTooltip
          messageId={
            !isNullOrUndefined(estimatedHours)
              ? 'projectDrawer.hoursEacFromProjectEstimatesMessage'
              : 'projectDrawer.hoursEacFromProjectBudgetMessage'
          }
        />
      ) : null,
    [estimatedHours, hasAlternateEstimatedAtCompletionHoursValue]
  );

  const workRemainingAlternateHoursTooltipComponent = useMemo(
    () =>
      hasAlternateWorkRemainingValue ? (
        <AlternateTooltip
          classes={classesOverride}
          messageId="projectDrawer.workRemainingForClosedProjectMessage"
        />
      ) : null,
    [classesOverride, hasAlternateWorkRemainingValue]
  );

  return {
    estimatedAtCompletionHoursRowTooltipCondition,
    workRemainingHoursRowTooltipCondition,
    atleastOneHoursRowHasTooltip,
    hideVariationRowHoursTooltip: hideHoursTooltip,
    hideEstimatedAtCompletionRowHoursTooltip: hideHoursTooltip,
    estimatedAtCompletionAlternateHoursTooltipComponent,
    workRemainingAlternateHoursTooltipComponent
  };
};

export default useAlternateHoursTooltips;

export const hasRowMarginCondition = (atleastOneRowHasTooltip, rowHasTooltip) =>
  !rowHasTooltip && atleastOneRowHasTooltip;

export const hasVariationRowMarginCondition = ({
  variationHours,
  hasChildTasks,
  atleastOneHoursRowHasTooltip,
  hideTooltip
}) =>
  hideTooltip ||
  (variationHours === null && hasChildTasks) ||
  hasRowMarginCondition(atleastOneHoursRowHasTooltip, hasChildTasks);
