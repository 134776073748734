import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  DialogActions,
  DialogContent,
  Divider,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { DateField, Alert } from '~/modules/common/components';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import Date from '~/modules/common/components/Date';
import {
  mapRepliconDateToUtcObject,
  getLuxonJsDateFormatFromMe,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { compareISOStrings } from '~/modules/common/dates/compare';
import { useMeContext } from '~/modules/me/useMeContext';
import ReadOnlyDateField from './ReadOnlyDateField';
import RescheduleProjectOptions from './RescheduleProjectOptions';
import AdjustWorkDays from './AdjustWorkDays';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0
  },
  datesRow: {
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  arrow: {
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    alignSelf: 'center',
    margin: theme.spacing(0, 1)
  },
  alert: {
    marginTop: theme.spacing(2)
  },
  date: { fontSize: theme.typography.body1.fontSize }
}));

export const differenceInDays = (date1, date2) => {
  const { days } = date1.diff(date2, 'days').toObject();

  return days;
};

const originalStartDateLabel = (
  <FormattedMessage id="rescheduleProject.originalStartDate" />
);
const newStartDateLabel = (
  <FormattedMessage id="rescheduleProject.newStartDate" />
);
const cancel = <FormattedMessage id="rescheduleProject.cancel" />;
const title = <FormattedMessage id="rescheduleProject.title" />;

export const RescheduleProjectDialogContentData = ({
  form,
  project,
  onClose,
  canRescheduleTasks,
  canRescheduleBillPlan,
  canRescheduleResourceRequests,
  tasksStatusCountSummary,
  resourceRequestsStatusCountSummary
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const {
    hasEditProjectBillingContracts,
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = me;
  const { formatMessage } = useIntl();

  const {
    values,
    errors,
    handleReset,
    setFieldValue,
    isSubmitting,
    handleSubmit
  } = form;

  const {
    newStartDate,
    tasksToReschedule,
    originalStartDate,
    isAdjustWorkDaysChecked,
    isRescheduleTasksChecked,
    isRescheduleBillPlanChecked,
    isRescheduleResourceRequestsChecked,
    resourceRequestsToRescheduleByStatus
  } = values;

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  const handleNewStartDateChange = useCallback(
    selectedStartDate => {
      setFieldValue('newStartDate', selectedStartDate);
    },
    [setFieldValue]
  );

  const handleOriginalStartDateChange = useCallback(
    selectedStartDate => {
      setFieldValue('originalStartDate', selectedStartDate);
    },
    [setFieldValue]
  );

  const { startDate, endDate } = project;

  const date = useMemo(
    () => <Date value={startDate} className={classes.date} />,
    [startDate, classes.date]
  );
  const dateOffSet = useMemo(() => {
    if (!originalStartDate || !newStartDate) return 0;
    const projectOriginalStartDate = mapRepliconDateToUtcObject(
      originalStartDate
    );
    const projectNewStartDate = mapRepliconDateToUtcObject(newStartDate);
    const { days } = projectNewStartDate
      .diff(projectOriginalStartDate, 'days')
      .toObject();

    return days;
  }, [newStartDate, originalStartDate]);

  const offsetValue = useMemo(() => {
    const value = Math.abs(dateOffSet);

    return {
      value,
      day: (
        <FormattedMessage
          id={value <= 1 ? 'rescheduleProject.day' : 'rescheduleProject.days'}
        />
      )
    };
  }, [dateOffSet]);

  const bannerProps = useMemo(() => {
    if (originalStartDate && endDate) {
      const originalStartDateTime = DateTime.utc(
        originalStartDate.year,
        originalStartDate.month,
        originalStartDate.day
      );

      const endDateTime = DateTime.fromISO(endDate, { setZone: true });

      const luxonFormat = getLuxonJsDateFormatFromMe(me);

      const data = {
        originalStartDate: originalStartDateTime.toFormat(luxonFormat),
        endDate: endDateTime.toFormat(luxonFormat)
      };
      const projectStartDateGreaterThanEndDate = (
        <FormattedMessage
          id="rescheduleProject.projectStartDateGreaterThanEndDate"
          values={data}
        />
      );

      return {
        message: projectStartDateGreaterThanEndDate,
        isProjectStartDateIsGreaterThanEndDate:
          compareISOStrings(
            endDate,
            mapRepliconDateToMidnightUTCString(originalStartDate)
          ) === -1
      };
    }

    return {};
  }, [originalStartDate, endDate, me]);

  const alertClasses = useMemo(() => ({ root: classes.alert }), [
    classes.alert
  ]);

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting: project?.projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  return (
    <>
      <DialogContent className={classes.root}>
        <div className={classes.datesRow}>
          {startDate ? (
            <ReadOnlyDateField label={originalStartDateLabel} variant="filled">
              {date}
            </ReadOnlyDateField>
          ) : (
            <DateField
              name="originalStartDate"
              variant="filled"
              label={originalStartDateLabel}
              ariaLabel={formatMessage({
                id: 'rescheduleProject.originalStartDate'
              })}
              onChange={handleOriginalStartDateChange}
              value={originalStartDate}
              editable
              required
              clearable={false}
              error={Boolean(errors.originalStartDate)}
              helperText={errors.originalStartDate}
            />
          )}
          <ArrowForwardIcon className={classes.arrow} />
          <DateField
            name="newStartDate"
            variant="filled"
            label={newStartDateLabel}
            ariaLabel={formatMessage({ id: 'rescheduleProject.newStartDate' })}
            onChange={handleNewStartDateChange}
            value={newStartDate}
            editable
            required
            clearable={false}
            error={Boolean(errors.newStartDate)}
            helperText={errors.newStartDate}
          />
        </div>
        {bannerProps.isProjectStartDateIsGreaterThanEndDate ? (
          <Alert
            severity="error"
            message={bannerProps.message}
            classes={alertClasses}
          />
        ) : null}
        <AdjustWorkDays
          dateOffSet={dateOffSet}
          offsetValue={offsetValue}
          setFieldValue={setFieldValue}
          isAdjustWorkDaysChecked={isAdjustWorkDaysChecked}
        />
        <Divider />
        <RescheduleProjectOptions
          project={project}
          setFieldValue={setFieldValue}
          showBillPlan={
            isPsaPrpPsaPpmMergerEnabled
              ? templateSettings?.hasBilling &&
                Boolean(hasEditProjectBillingContracts)
              : Boolean(hasEditProjectBillingContracts)
          }
          canRescheduleBillPlan={canRescheduleBillPlan}
          canRescheduleTasks={canRescheduleTasks}
          isRescheduleTasksChecked={isRescheduleTasksChecked}
          canRescheduleResourceRequests={canRescheduleResourceRequests}
          isRescheduleBillPlanChecked={isRescheduleBillPlanChecked}
          isRescheduleResourceRequestsChecked={
            isRescheduleResourceRequestsChecked
          }
          tasksStatusCountSummary={tasksStatusCountSummary}
          tasksToReschedule={tasksToReschedule}
          resourceRequestsStatusCountSummary={
            resourceRequestsStatusCountSummary
          }
          resourceRequestsToRescheduleByStatus={
            resourceRequestsToRescheduleByStatus
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          {cancel}
        </Button>
        <LoadingButton
          color="primary"
          disabled={
            dateOffSet === 0 ||
            bannerProps.isProjectStartDateIsGreaterThanEndDate
          }
          isLoading={isSubmitting}
          type="submit"
          onClick={handleSubmit}
        >
          {title}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

RescheduleProjectDialogContentData.propTypes = {
  form: PropTypes.object,
  project: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  canRescheduleTasks: PropTypes.bool,
  canRescheduleBillPlan: PropTypes.bool,
  canRescheduleResourceRequests: PropTypes.bool,
  tasksStatusCountSummary: PropTypes.object,
  resourceRequestsStatusCountSummary: PropTypes.object
};

export default RescheduleProjectDialogContentData;
