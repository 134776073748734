import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.palette.table.border}`
  }
});

export const ResponsiveTableRow = ({
  classes: { root },
  className,
  children
}) => <div className={classNames(root, className)}>{children}</div>;

ResponsiveTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

export default withStyles(styles)(ResponsiveTableRow);
