import { gql } from 'graphql-tag';
import {
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment
} from '~/modules/resourcing/common/fragments';
import { ResourceAllocationStatus } from '~/types';

export const GET_EXISTING_ALLOCATION_QUERY = gql`
  query Eager_GetExistingAllocation(
    $projectUri: String
    $userUri: String
    $filter: ResourceAllocationFilter
    $allocationStatusList: [ResourceAllocationStatus!]
  ) {
    resourceAllocations(
      projectUri: $projectUri
      userUri: $userUri
      filter: $filter
      allocationStatusList: $allocationStatusList
    ) {
      resourceAllocations {
        ...SpecificResourceAllocation
        role {
          uri
          id
          displayText
        }
        user {
          ...SpecificResourceAllocationUser
        }
      }
    }
  }
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
`;

export const HAS_EXISTING_ALLOCATION_QUERY = gql`
  query HasExistingAllocation(
    $projectUri: String
    $filter: ResourceAllocationFilter
    $allocationStatusList: [ResourceAllocationStatus!]
  ) {
    resourceAllocations(
      projectUri: $projectUri
      filter: $filter
      allocationStatusList: $allocationStatusList
    ) {
      resourceAllocations {
        id
      }
    }
  }
`;

export const getHasExistingAllocation = async ({
  apolloClient,
  projectUri,
  userUris
}) => {
  const { data, loading } = await apolloClient.query({
    query: HAS_EXISTING_ALLOCATION_QUERY,
    variables: {
      filter: {
        users: userUris
      },
      projectUri,
      allocationStatusList: [ResourceAllocationStatus.Committed]
    },
    fetchPolicy: 'network-only'
  });

  return {
    hasExistingAllocation: Boolean(
      data?.resourceAllocations?.resourceAllocations?.length
    ),
    isLoading: loading
  };
};
