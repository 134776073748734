import React from 'react';
import PropTypes from 'prop-types';
import CostCenterIcon from './CostCenterIcon';
import DepartmentIcon from './DepartmentIcon';
import DivisionIcon from './DivisionIcon';
import EmployeeTypeIcon from './EmployeeTypeIcon';
import LocationIcon from './LocationIcon';
import ServiceCenterIcon from './ServiceCenterIcon';

const groupIconMap = {
  costCenter: CostCenterIcon,
  department: DepartmentIcon,
  division: DivisionIcon,
  employeeType: EmployeeTypeIcon,
  location: LocationIcon,
  serviceCenter: ServiceCenterIcon
};

export const GroupIcon = ({ groupType, variant = 'icon' }) => {
  const Icon = groupIconMap[groupType];

  return <Icon variant={variant} />;
};

GroupIcon.propTypes = {
  groupType: PropTypes.oneOf([
    'costCenter',
    'department',
    'division',
    'employeeType',
    'location',
    'serviceCenter'
  ]),
  variant: PropTypes.oneOf(['icon', 'avatar'])
};

export default GroupIcon;
