export const IMPORT_OPTIONS = {
  IMPORT: 'importExportMenu.options.import',
  CSV_IMPORT: 'importExportMenu.options.csvImport',
  SALESFORCE: 'importExportMenu.options.salesforceConnector'
};

export const EXPORT_OPTIONS = {
  EXPORT: 'importExportMenu.options.export',
  CSV_EXPORT: 'importExportMenu.options.csvExport'
};

export const STATUS_OPTIONS = {
  ENABLED: 'importExportMenu.options.enabled',
  NOT_ENABLED: 'importExportMenu.options.notEnabled'
};
