import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import {
  TableRow,
  ListItemText,
  TableCell,
  makeStyles
} from '@material-ui/core';
import { MoneyValue } from '~/modules/common/components/Money';

const useStyles = makeStyles(theme => ({
  description: {
    paddingRight: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize
  },
  cell: { borderBottom: 'none', paddingTop: 0, paddingBottom: 0 },
  firstCell: {
    borderBottom: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(10)
  },
  amount: {
    fontSize: theme.typography.body2.fontSize,
    textAlign: 'right',
    display: 'block'
  },
  notes: { fontSize: theme.typography.caption.fontSize }
}));

export const TaxLineItemsRow = ({ record }) => {
  const classes = useStyles();
  const cellClass = useMemo(() => ({ root: classes.cell }), [classes.cell]);
  const firstCellClass = useMemo(() => ({ root: classes.firstCell }), [
    classes.firstCell
  ]);
  const { description, notes, amount } = record;
  const primary = useMemo(
    () => <span className={classes.description}>{description}</span>,
    [classes.description, description]
  );
  const secondary = useMemo(
    () => <span className={classes.notes}>{notes}</span>,
    [classes.notes, notes]
  );

  return (
    <TableRow>
      <TableCell classes={firstCellClass}>
        <ListItemText primary={primary} secondary={secondary} />
      </TableCell>
      <TableCell classes={cellClass}>
        <MoneyValue className={classes.amount} money={amount} />
      </TableCell>
    </TableRow>
  );
};

TaxLineItemsRow.propTypes = {
  record: PropTypes.object.isRequired
};

export default TaxLineItemsRow;
