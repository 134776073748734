import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ArrowIcon from '@material-ui/icons/ArrowForwardSharp';
import { Typography } from '@material-ui/core';
import DateRange from '~/modules/common/components/DateRange';
import Decimal from '~/modules/common/components/Decimal';

const InProgressRow = ({ intl, classes, facts }) => (
  <>
    <div className={classNames(classes.row, classes.actual)} role="row">
      <div
        className={classNames(classes.cell, classes.label, classes.rowHeader)}
        role="rowheader"
      >
        <FormattedMessage id="taskStatus.actual" />
      </div>
      <div
        data-qe-id="Status_TotalHours"
        className={classNames(classes.cell, classes.hours)}
        role="cell"
      >
        <Decimal className={classes.fact} value={facts.actual.hours} />
      </div>
      <div
        data-qe-id="Status_EstimatedCompletion_Hours"
        className={classNames(classes.cell, classes.hoursEstimate)}
        aria-label={intl.formatMessage({
          id: 'taskStatus.estimatedHoursAtCompletion'
        })}
      >
        <ArrowIcon className={classes.arrow} />
        <Decimal
          className={classNames(
            classes.fact,
            classes.estimatedPart,
            facts.estimated.delta > 0 ? classes.overPlan : classes.onPlan
          )}
          value={facts.estimated.hours}
        />
        <sup className={classes.projectedMark}>†</sup>
        {facts.estimated.delta > 0 && (
          <Typography
            component="span"
            className={classNames(classes.fact, classes.diffNegative)}
          >
            +<Decimal value={facts.estimated.delta} />
          </Typography>
        )}
      </div>
      <div
        data-qe-id="Status_Estimated_EndDate"
        className={classNames(classes.cell, classes.dates)}
        role="cell"
      >
        <DateRange
          classes={{
            range:
              facts.estimated.date.delta > 0
                ? classes.dateRangeOverPlan
                : classes.dateRangeOnPlan,
            date: classes.fact
          }}
          start={facts.actual.date.start}
          end={facts.estimated.date.end}
        />
        <sup className={classes.projectedMark}>†</sup>
        {facts.estimated.date.delta > 0 && (
          <Typography
            component="span"
            className={classNames(classes.fact, classes.diffNegative)}
          >
            <FormattedMessage
              id="taskStatus.additionalDays"
              values={{
                days: facts.estimated.date.delta
              }}
            />
          </Typography>
        )}
      </div>
    </div>
    <div className={classes.row} role="row">
      <div
        className={classNames(classes.cell, classes.label, classes.subtext)}
        role="cell"
      >
        <sup className={classes.projectedMark}>†</sup>
        <FormattedMessage id="taskStatus.estimatedAtCompletion" />
      </div>
    </div>
  </>
);

InProgressRow.propTypes = {
  intl: PropTypes.object,
  classes: PropTypes.object,
  facts: PropTypes.object
};

export default InProgressRow;
