import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import CommentIcon from '@material-ui/icons/CommentSharp';
import useStyles from './useStyles';

const CommentsIndicator = ({ value }) => {
  const classes = useStyles();
  const intl = useIntl();

  if (!value || (value && value.trim().length === 0)) return null;

  return (
    <span
      className={classes.root}
      aria-label={intl.formatMessage({ id: 'resourceRequestCard.comment' })}
    >
      <Tooltip
        title={intl.formatMessage({ id: 'resourceRequestCard.comment' })}
      >
        <CommentIcon className={classes.icon} />
      </Tooltip>
      {value}
    </span>
  );
};

CommentsIndicator.propTypes = {
  value: PropTypes.string
};

export default CommentsIndicator;
