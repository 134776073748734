import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import { useMeContext } from '~/modules/me/useMeContext';

import AllItemTab from './AllItemTab';
import AlternativeItemTab from './AlternativeItemTab';
import SummaryItemsTab from './SummaryItemsTab';
import { genericAllItem } from './summaryFilterUtil';
import SummaryTabsSpacer from './SummaryTabsSpacer';

export const SUMMARY_ITEMS = 'SUMMARY_ITEMS';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    padding: 0,
    width: '100%',
    minHeight: theme.spacing(5)
  }
}));

const calculateValue = ({
  allItems,
  summaryItems,
  alternativeItems,
  allKey,
  key
}) => {
  if (summaryItems && summaryItems.some(summaryItem => key === summaryItem.key))
    return SUMMARY_ITEMS;
  if (
    alternativeItems &&
    alternativeItems.some(alternativeItem => key === alternativeItem.key)
  )
    return key;
  if (allItems && allItems.length > 0) {
    if (allItems.some(allItem => allKey === allItem.key)) return allKey;

    return allItems[0].key;
  }

  return genericAllItem.key;
};
const findTabIndicatorColor = ({ key, summaryItems }) => {
  if (key) {
    const tabItem = summaryItems
      ? summaryItems.find(summaryItem => key === summaryItem.key)
      : null;

    if (tabItem && tabItem.color) return tabItem.color;
  }

  return null;
};

const handleChangeWithNoAction = event => event.stopPropagation();

export const QuickFilterSummaryTabs = ({
  allItems = [],
  summaryItems = [],
  summaryFilterState: { isLoadingQuickFilters, quickFilter, setKeys },
  alternativeItems = [],
  showValue = true,
  isValueLoading,
  onResetSelection
}) => {
  const classes = useStyles();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();

  const value = useMemo(
    () =>
      calculateValue({
        allItems,
        summaryItems,
        alternativeItems,
        allKey: quickFilter.filterByAccess,
        key: quickFilter.filterByStatus
      }),
    [allItems, summaryItems, alternativeItems, quickFilter]
  );
  const handleChange = useCallback(
    (event, newValue, summaryItemKey) => {
      onResetSelection && onResetSelection();
      if (newValue === SUMMARY_ITEMS) {
        // tab action for status like initiate, outstanding
        setKeys(
          quickFilter.filterByAccess,
          quickFilter.filterByStatus === summaryItemKey ? null : summaryItemKey
        );
      } else if (
        // tab action for secondary item like archived, inactive
        alternativeItems &&
        alternativeItems.some(
          alternativeItem => alternativeItem.key === newValue
        )
      ) {
        // tab action for all or my summary
        setKeys(
          quickFilter.filterByAccess,
          quickFilter.filterByStatus === newValue ? null : newValue
        );
      } else {
        setKeys(newValue, null);
      }
    },
    [
      setKeys,
      alternativeItems,
      onResetSelection,
      quickFilter.filterByAccess,
      quickFilter.filterByStatus
    ]
  );

  const tabIndicatorColor = useMemo(
    () =>
      findTabIndicatorColor({ key: quickFilter.filterByStatus, summaryItems }),
    [quickFilter.filterByStatus, summaryItems]
  );

  const tabIndicatorClasses = useMemo(
    () => ({
      style: {
        backgroundColor: tabIndicatorColor
      }
    }),
    [tabIndicatorColor]
  );

  // checking If any secondary action is selected or not If selected use this boolean to make no sub selection of any primary action
  const isAlternativeItemSelected = useMemo(
    () =>
      alternativeItems &&
      alternativeItems.some(alternativeItem => value === alternativeItem.key),
    [alternativeItems, value]
  );

  const doNotAllowTabAction = isValueLoading || isLoadingQuickFilters;

  return (
    <Tabs
      classes={classes}
      value={value}
      onChange={doNotAllowTabAction ? handleChangeWithNoAction : handleChange}
      indicatorColor="primary"
      TabIndicatorProps={tabIndicatorColor ? tabIndicatorClasses : {}}
    >
      {allItems && allItems.length > 0 ? (
        allItems.map((allItem, index) => (
          <AllItemTab
            showValue={showValue}
            value={allItem.key}
            key={allItem.key}
            allItem={allItem}
            subSelected={
              quickFilter.filterByAccess === allItem.key &&
              !isAlternativeItemSelected
            }
            first={index === 0}
            last={allItems.length - index === 1}
            isValueLoading={isValueLoading}
            isPsaPrpAccessibilityIssuesEnabled={
              isPsaPrpAccessibilityIssuesEnabled
            }
          />
        ))
      ) : (
        <AllItemTab
          showValue={showValue}
          value={genericAllItem.key}
          allItem={genericAllItem}
          first
          last
          isValueLoading={isValueLoading}
          isPsaPrpAccessibilityIssuesEnabled={
            isPsaPrpAccessibilityIssuesEnabled
          }
        />
      )}
      <SummaryItemsTab
        showValue={showValue}
        value={SUMMARY_ITEMS}
        summaryItems={summaryItems}
        allSelected={
          !(
            value === SUMMARY_ITEMS ||
            (alternativeItems &&
              alternativeItems.some(
                alternativeItem => value === alternativeItem.key
              ))
          )
        }
        summaryKey={quickFilter.filterByStatus}
        isValueLoading={isValueLoading}
      />
      <SummaryTabsSpacer
        last={!(alternativeItems && alternativeItems.length > 0)}
      />
      {alternativeItems && alternativeItems.length > 0
        ? alternativeItems.map((alternativeItem, index) => (
            <AlternativeItemTab
              value={alternativeItem.key}
              key={alternativeItem.key}
              alternativeItem={alternativeItem}
              first={index === 0}
              last={alternativeItems.length - index === 1}
              isValueLoading={isValueLoading}
            />
          ))
        : null}
    </Tabs>
  );
};

QuickFilterSummaryTabs.propTypes = {
  allItems: PropTypes.array,
  showValue: PropTypes.bool,
  summaryItems: PropTypes.array,
  summaryFilterState: PropTypes.object,
  alternativeItems: PropTypes.array,
  isValueLoading: PropTypes.bool,
  onResetSelection: PropTypes.func
};

export default QuickFilterSummaryTabs;
