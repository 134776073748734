import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Collapse from '@material-ui/core/Collapse';
import TabIndicator from '@material-ui/core/Tabs/TabIndicator';

import { genericAllItem } from './summaryFilterUtil';
import SummaryItem from './SummaryItem';
import SummarySetItem from './SummarySetItem';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper
  },
  scrollSection: {
    overflowY: 'hidden',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    scrollbarColor: [[theme.palette.grey[400], 'transparent']],
    '&::-webkit-scrollbar': {
      height: 1,
      backgroundColor: 'transparent',
      display: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400]
    },
    '-ms-overflow-style': 'none'
  },
  setItems: {
    listStyle: 'none',
    margin: theme.spacing(0, 0, 1, 0),
    padding: theme.spacing(1, 2, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  summaryItems: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    minHeight: theme.spacing(5)
  },
  summaryItem: {
    flexGrow: 1,
    flexBasis: theme.spacing(10),
    minWidth: theme.spacing(10),
    width: theme.spacing(10)
  },
  indicator: {}
}));

const findTabIndicatorColor = ({ key, summaryItems }) => {
  if (key) {
    const summaryItem = summaryItems
      ? summaryItems.find(item => key === item.key)
      : null;

    if (summaryItem && summaryItem.color) return summaryItem.color;
  }

  return null;
};

const calculateState = ({
  allItems,
  summaryItems,
  alternativeItems,
  allKey,
  key
}) => {
  const summaryKey =
    summaryItems &&
    summaryItems.length > 0 &&
    summaryItems.some(summaryItem => key === summaryItem.key)
      ? key
      : null;

  if (
    alternativeItems &&
    alternativeItems.some(alternativeItem => key === alternativeItem.key)
  )
    return [key, false, summaryKey];
  const hasSummaryItems = !!(summaryItems && summaryItems.length > 0);

  if (allItems && allItems.length > 0) {
    if (allItems.some(allItem => allKey === allItem.key))
      return [allKey, hasSummaryItems, summaryKey];

    return [allItems[0].key, hasSummaryItems, summaryKey];
  }

  return [genericAllItem.key, hasSummaryItems, summaryKey];
};

const handleChangeWithNoAction = event => event.stopPropagation();

export const MobileQuickFilterSummaryTabs = ({
  className,
  allItems = [],
  summaryItems = [],
  isValueLoading,
  alternativeItems = [],
  summaryFilterState: { isLoadingQuickFilters, quickFilter, setKeys },
  showSingleAllItem = false,
  onResetSelection
}) => {
  const classes = useStyles();

  const [setKey, hasSummaryItems, summaryKey] = useMemo(
    () =>
      calculateState({
        allItems,
        summaryItems,
        alternativeItems,
        allKey: quickFilter.filterByAccess,
        key: quickFilter.filterByStatus
      }),
    [
      allItems,
      summaryItems,
      alternativeItems,
      quickFilter.filterByAccess,
      quickFilter.filterByStatus
    ]
  );

  const tabIndicatorColor = useMemo(
    () =>
      findTabIndicatorColor({
        key: quickFilter.filterByStatus,
        summaryItems
      }),
    [quickFilter.filterByStatus, summaryItems]
  );

  const handleSetItemClick = useCallback(
    (event, key) => {
      onResetSelection && onResetSelection();
      if (
        alternativeItems &&
        alternativeItems.some(alternativeItem => alternativeItem.key === key)
      ) {
        // tab action for secondary item like archived, inactive
        setKeys(
          quickFilter.filterByAccess,
          quickFilter.filterByStatus === key ? null : key
        );
      } else {
        // tab action for all or my summary
        setKeys(key, null);
      }
    },
    [
      onResetSelection,
      alternativeItems,
      setKeys,
      quickFilter.filterByAccess,
      quickFilter.filterByStatus
    ]
  );

  // tab action for status like initiate, outstanding
  const handleSummaryItemClick = useCallback(
    (event, key) => {
      onResetSelection && onResetSelection();
      setKeys(
        quickFilter.filterByAccess,
        quickFilter.filterByStatus === key ? null : key
      );
    },
    [
      onResetSelection,
      setKeys,
      quickFilter.filterByAccess,
      quickFilter.filterByStatus
    ]
  );

  const tabIndicatorBackgroundColor = useMemo(
    () => ({ backgroundColor: tabIndicatorColor }),
    [tabIndicatorColor]
  );

  const doNotAllowTabAction = isValueLoading || isLoadingQuickFilters;

  const onSummaryItemClick = doNotAllowTabAction
    ? handleChangeWithNoAction
    : handleSetItemClick;

  return (
    <div className={classNames(classes.root, className)}>
      {(allItems && allItems.length > 1) ||
      (alternativeItems && alternativeItems.length > 0) ||
      showSingleAllItem ? (
        <div className={classes.scrollSection}>
          <ul className={classes.setItems} role="tablist">
            {allItems && allItems.length > 0 ? (
              allItems.map(allItem => (
                <SummarySetItem
                  value={allItem.key}
                  key={allItem.key}
                  item={allItem}
                  onClick={onSummaryItemClick}
                  selected={allItem.key === setKey}
                  isValueLoading={isValueLoading}
                />
              ))
            ) : (
              <SummarySetItem
                value={genericAllItem.key}
                item={genericAllItem}
                onClick={onSummaryItemClick}
                selected={genericAllItem.key === setKey}
                isValueLoading={isValueLoading}
              />
            )}
            <li className={classes.spacer} role="presentation" />
            {alternativeItems && alternativeItems.length > 0
              ? alternativeItems.map(alternativeItem => (
                  <SummarySetItem
                    value={alternativeItem.key}
                    key={alternativeItem.key}
                    item={alternativeItem}
                    onClick={onSummaryItemClick}
                    selected={alternativeItem.key === setKey}
                    isValueLoading={isValueLoading}
                  />
                ))
              : null}
          </ul>
        </div>
      ) : null}
      {summaryItems && summaryItems.length > 0 ? (
        <Collapse in={hasSummaryItems} timeout="auto" unmountOnExit>
          <div className={classes.scrollSection}>
            <ul className={classes.summaryItems} role="tablist">
              {summaryItems.map(summaryItem => (
                <SummaryItem
                  key={summaryItem.key}
                  summaryItem={summaryItem}
                  onClick={
                    doNotAllowTabAction
                      ? handleChangeWithNoAction
                      : handleSummaryItemClick
                  }
                  selected={!!(!summaryKey || summaryKey === summaryItem.key)}
                  component="li"
                  className={classes.summaryItem}
                  showValue
                  isValueLoading={isValueLoading}
                />
              ))}
            </ul>
            {summaryKey ? (
              <TabIndicator
                className={classes.indicator}
                orientation="horizontal"
                color="primary"
                style={tabIndicatorColor ? tabIndicatorBackgroundColor : null}
              />
            ) : null}
          </div>
        </Collapse>
      ) : null}
    </div>
  );
};

MobileQuickFilterSummaryTabs.propTypes = {
  className: PropTypes.string,
  allItems: PropTypes.array,
  summaryItems: PropTypes.array,
  alternativeItems: PropTypes.array,
  summaryFilterState: PropTypes.object,
  isValueLoading: PropTypes.bool,
  showSingleAllItem: PropTypes.bool,
  onResetSelection: PropTypes.func
};

export default MobileQuickFilterSummaryTabs;
