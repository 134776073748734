import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';

const useNoSkillsStyles = makeStyles(theme => ({
  noSkills: {
    padding: theme.spacing(1.5, 2, 0, 1.5)
  }
}));

export const NoSkillsMessage = () => {
  const classes = useNoSkillsStyles();

  return (
    <div className={classes.noSkills}>
      <NoDataItem>
        <FormattedMessage id="resourcingOverview.noSkills" />
      </NoDataItem>
    </div>
  );
};

export default NoSkillsMessage;
