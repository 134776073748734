import { isValid } from '../../../validator';

export const optimisticResponse = ({
  projectUri,
  clients,
  clientRepresentative
}) => ({
  __typename: 'Mutation',
  updateProject2: {
    __typename: 'Project',
    id: projectUri,
    clients: clients.map(({ client }) => ({
      __typename: 'Client',
      client: {
        __typename: 'ClientDetails',
        id: client.id,
        displayText: client.displayText
      }
    })),
    clientRepresentative: isValid(clientRepresentative)
      ? {
          __typename: 'ClientRepresentative',
          id: clientRepresentative.id,
          displayText: clientRepresentative.displayText
        }
      : null
  }
});
