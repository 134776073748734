import { PropTypes } from 'prop-types';
import React from 'react';
import AvailableToBillListTable from '../AvailableToBillListTable';
import Toolbar from './Toolbar';

export const AvailableToBillSubTab = ({
  searchFacets,
  permissions: { canAddBill },
  editable,
  ...rest
}) => (
  <>
    <Toolbar searchFacets={searchFacets} />
    <AvailableToBillListTable {...rest} editable={editable && canAddBill} />
  </>
);

AvailableToBillSubTab.propTypes = {
  searchFacets: PropTypes.array,
  permissions: PropTypes.object.isRequired,
  editable: PropTypes.bool
};

export default AvailableToBillSubTab;
