import { ButtonBase, DialogContentText, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditSharp as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  NoValue,
  Percentage,
  RemoveEntityConfirmationDialog,
  User
} from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import IconLinkButton from '~/modules/resourcing/common/components/IconLinkButton';
import { useAllocationHandlers } from '~/modules/resourcing/common/hooks';
import { ResourceRequestStatus } from '~/types';
import useStyles from './useStyles';

const useAvatarStyleOverride = makeStyles(theme => ({
  name: {
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 130
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    margin: [['-0.28em', '0.28em', '-0.28em', 0]],
    width: '1.95em',
    height: '1.95em',
    flexGrow: 0,
    fontSize: theme.typography.caption.fontSize,
    alignSelf: 'center',
    flexShrink: 0
  }
}));

const removeDialogResourceKeys = {
  header: 'resourceAllocationEditableUserItem.removeResourceAllocation',
  actionButton:
    'resourceAllocationEditableUserItem.removeResourceAllocatinAction'
};

export const ResourceAllocationEditableUserItem = ({
  classes: classesOverride,
  qeIdTag,
  resourceAllocation,
  resourceAllocationLoad,
  resourceAllocationUser,
  resourceRequest
}) => {
  const { formatMessage } = useIntl();

  const classes = useStyles({ classes: classesOverride });
  const userClasses = useAvatarStyleOverride();
  const history = useHistory();

  const isViewUserEnabled =
    resourceAllocationUser.permittedActionUris &&
    resourceAllocationUser.permittedActionUris.includes(
      'urn:replicon:user-action:view-user'
    );

  const me = useMeContext();
  const { onDeleteResourceAllocation } = useAllocationHandlers({
    resourceRequest,
    me
  });

  const {
    open: showRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const onDeleteClick = useCallback(() => {
    onDeleteResourceAllocation(resourceAllocation);
    closeRemoveDialog();
  }, [onDeleteResourceAllocation, resourceAllocation, closeRemoveDialog]);

  const iconLinkButtonClasses = useMemo(
    () => ({ openButton: classes.openButton }),
    [classes.openButton]
  );

  const editIconClasses = useMemo(() => ({ root: classes.openButton }), [
    classes.openButton
  ]);

  const toLink = `#/resource/${resourceAllocationUser.slug}`;

  const resourcingToLink = `/resourcing/assignment/${resourceAllocation.projectUri}/all/${resourceAllocation.resourceRequestId}`;
  const openResourceAllocation = useCallback(
    () => history.push(resourcingToLink),
    [history, resourcingToLink]
  );

  return (
    <>
      <div data-qe-id={`${qeIdTag}_Div`} className={classes.root}>
        <ButtonBase
          className={classes.resouceAllocationCellClick}
          onClick={openResourceAllocation}
        >
          {resourceAllocationUser && (
            <User user={resourceAllocationUser} classes={userClasses} />
          )}
          {!resourceAllocationUser && <NoValue />}
          <span className={classes.atDivider}>@</span>
          <div className={classes.loadingContainer}>
            <Percentage
              value={resourceAllocationLoad}
              precision={0}
              className={classes.loading}
            />
          </div>
        </ButtonBase>
        {(resourceRequest.requestStatus === ResourceRequestStatus.Submitted ||
          resourceRequest.requestStatus ===
            ResourceRequestStatus.Allocationrejected) && (
          <>
            <IconButton
              size="small"
              classes={editIconClasses}
              onClick={openResourceAllocation}
              aria-label={formatMessage({
                id: 'resourceAllocationEditableUserItem.editResourceAllocation'
              })}
            >
              <EditIcon className={classes.icon} />
            </IconButton>
          </>
        )}
        {isViewUserEnabled && (
          <div>
            <IconLinkButton
              classes={iconLinkButtonClasses}
              toLink={toLink}
              ariaLabel={
                formatMessage({
                  id: 'resourceRequestItem.viewMoreDetails'
                }) + resourceAllocationUser.displayText
              }
            />
          </div>
        )}
      </div>
      <RemoveEntityConfirmationDialog
        resourceKeys={removeDialogResourceKeys}
        open={showRemoveDialog}
        onClose={closeRemoveDialog}
        onConfirm={onDeleteClick}
      >
        <DialogContentText>
          <FormattedMessage id="resourceAllocationEditableUserItem.removeResourceAllocationContent" />
        </DialogContentText>
      </RemoveEntityConfirmationDialog>
    </>
  );
};

ResourceAllocationEditableUserItem.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  resourceAllocationUser: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    permittedActionUris: PropTypes.array,
    slug: PropTypes.string
  }),
  resourceAllocation: PropTypes.object.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocationLoad: PropTypes.number
};

export default ResourceAllocationEditableUserItem;
