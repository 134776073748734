import React, { useCallback } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import User from '~/modules/common/components/User';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(0.5),
    minHeight: theme.spacing(5),
    '&:hover': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    alignItems: 'center'
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: theme.spacing(-0.5)
  },
  expandIconOpen: {
    transform: 'rotate(180deg)'
  },
  actionIcon: {
    alignSelf: 'center'
  }
}));

const useUserStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: theme.spacing(1),
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  }
}));

export const LeftItemContentModel1 = ({
  allocatedUser,
  isUserRowExpanded,
  ActionsComponent,
  actionsComponentProps,
  onUserRowExpansionPanelClick
}) => {
  const classes = useStyles();
  const userClasses = useUserStyles();

  const onChange = useCallback(
    event => {
      if (
        event.type === 'click' ||
        event.key === 'Enter' ||
        event.keyCode === 13
      ) {
        onUserRowExpansionPanelClick(allocatedUser.user.id);
      }
    },
    [allocatedUser.user.id, onUserRowExpansionPanelClick]
  );

  return (
    <>
      <div
        className={classes.root}
        onClick={onChange}
        onKeyDown={onChange}
        role="button"
        tabIndex="0"
      >
        <ExpandMoreIcon
          className={classNames(classes.expandIcon, {
            [classes.expandIconOpen]: isUserRowExpanded
          })}
        />
        <User
          user={allocatedUser.user}
          dataQeId="user"
          size="small"
          disablePadding
          showTooltip
          disabled={false}
          classes={userClasses}
        />
      </div>
      <div className={classes.actionIcon}>
        <ActionsComponent {...actionsComponentProps} />
      </div>
    </>
  );
};

LeftItemContentModel1.propTypes = {
  allocatedUser: PropTypes.object,
  isUserRowExpanded: PropTypes.bool,
  ActionsComponent: PropTypes.elementType.isRequired,
  actionsComponentProps: PropTypes.object.isRequired,
  onUserRowExpansionPanelClick: PropTypes.func.isRequired
};

export default LeftItemContentModel1;
