import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback, useMemo } from 'react';
import { useMeContext } from '~/modules/me';
import { debounce, scope } from '~/modules/common/debounce';
import { useTaskSummaryContext } from '../../TaskSummaryContext';

const refetchTaskSummaryScope = scope();

export const TASK_SUMMARY_QUERY = gql`
  query getTaskSummary($taskId: String!,${`$includeRolledUpCostSummary: Boolean = false`}) {
    task(taskId: $taskId) {
      id
      rolledUpSummary {
        id
        actualHours
        totalEstimatedAtCompletionHours
        totalEstimatedRemainingHours
      }
      rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
        actualCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalEstimatedAtCompletionCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalEstimatedRemainingCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        initialEstimatedCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
      }
    }
  }
`;

export const useTaskSummary = ({ taskId }) => {
  const apolloClient = useApolloClient();
  const { setTaskSummaryLoading } = useTaskSummaryContext();

  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled }
  } = useMeContext();

  const variables = useMemo(
    () => ({
      taskId,
      includeRolledUpCostSummary: isPsaPpmEstimatedCostAtCompletionEnabled
    }),
    [isPsaPpmEstimatedCostAtCompletionEnabled, taskId]
  );

  const handleRefetchTaskSummary = useCallback(async () => {
    if (!isRolledUpTaskEstimateCalculationMethodEnabled) return;

    await debounce(
      refetchTaskSummaryScope,
      async () => {
        setTaskSummaryLoading(true);

        const { loading } = await apolloClient.query({
          query: TASK_SUMMARY_QUERY,
          variables,
          fetchPolicy: 'network-only'
        });

        setTaskSummaryLoading(loading);
      },
      5000
    );
  }, [
    apolloClient,
    isRolledUpTaskEstimateCalculationMethodEnabled,
    setTaskSummaryLoading,
    variables
  ]);

  return {
    handleRefetchTaskSummary
  };
};

export default useTaskSummary;
