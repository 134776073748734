import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Grid, Tooltip } from '@material-ui/core';
import { shortDateRangeFormat } from '~/modules/common/dates/format';

export const ActualHoursText = ({ actualHours }) => (
  <span>
    <FormattedMessage id="projectList.actualHours" />: {actualHours}
  </span>
);

ActualHoursText.propTypes = {
  actualHours: PropTypes.node
};

export const BudgetHoursText = ({ budgetHours }) => (
  <span>
    <FormattedMessage id="projectList.budgetHours" />: {budgetHours}
  </span>
);

BudgetHoursText.propTypes = {
  budgetHours: PropTypes.node
};

export const ProjectTimelineChartRowBarTooltip = ({
  startDate,
  endDate,
  actualHours,
  budgetHours,
  canViewBudgetHours,
  children
}) => {
  const title = useMemo(
    () => (
      <Grid container direction="column">
        <Grid item>
          {shortDateRangeFormat({ start: startDate, end: endDate })}
        </Grid>
        {canViewBudgetHours && (
          <Grid item>
            <BudgetHoursText budgetHours={budgetHours} />
          </Grid>
        )}
        <Grid item>
          <ActualHoursText actualHours={actualHours} />
        </Grid>
      </Grid>
    ),
    [actualHours, budgetHours, canViewBudgetHours, endDate, startDate]
  );

  return <Tooltip title={title}>{children}</Tooltip>;
};

ProjectTimelineChartRowBarTooltip.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  actualHours: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  budgetHours: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  canViewBudgetHours: PropTypes.bool,
  children: PropTypes.node
};

export default ProjectTimelineChartRowBarTooltip;
