import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import BillingOverviewValueRow from './BillingOverviewValueRow';

export const EstimatedBillingValueRowGroup = ({
  sectionData,
  expanded,
  onExpandChange,
  displayTotalColumn
}) => {
  const { amountTotal, billPlans, loading } = sectionData;

  return (
    <>
      <BillingOverviewValueRow
        rowData={amountTotal}
        rowTitle="billingOverviewTable.estimatedBilling"
        expanded={expanded}
        onExpandChange={onExpandChange}
        collapsibleHeader
        totalRow
        displayTotalColumn={displayTotalColumn}
        useCellBorderClass
      />
      {loading && expanded ? (
        <Skeleton variant="rect" width="80%" height={30} />
      ) : (
        expanded &&
        Object.keys(billPlans).map(bpKey => (
          <BillingOverviewValueRow
            key={bpKey}
            subRow
            rowData={billPlans[bpKey]}
            rowTitle={billPlans[bpKey].displayText}
            displayTotalColumn={displayTotalColumn}
            dataQeId={`estimatedBillingSection.${bpKey}`}
          />
        ))
      )}
    </>
  );
};

EstimatedBillingValueRowGroup.propTypes = {
  sectionData: PropTypes.object,
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  displayTotalColumn: PropTypes.bool
};

export default EstimatedBillingValueRowGroup;
