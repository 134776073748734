import { useCallback } from 'react';
import { BillStatus } from '~/types';

export default ({
  options,
  handleIssueSyncClick,
  handleIssuePrintClick,
  handleIssueEmailClick,
  setLoading,
  setSelectedIndex,
  selectedIndex,
  setOpen,
  billStatus,
  openBillIssueDialog
}) => {
  const handleClick = useCallback(async () => {
    if (billStatus === BillStatus.Draft) {
      openBillIssueDialog(options[selectedIndex].value);

      return;
    }
    if (options[selectedIndex].value === 'sync') {
      setLoading(true);
      await handleIssueSyncClick();
      setLoading(false);
    }
    if (options[selectedIndex].value === 'print') {
      setLoading(true);
      await handleIssuePrintClick();
      setLoading(false);
    }
    if (options[selectedIndex].value === 'email') {
      setLoading(true);
      await handleIssueEmailClick();
      setLoading(false);
    }
  }, [
    handleIssueEmailClick,
    handleIssuePrintClick,
    handleIssueSyncClick,
    options,
    selectedIndex,
    setLoading,
    billStatus,
    openBillIssueDialog
  ]);

  const menuItemClick = useCallback(
    e => {
      setSelectedIndex(e.target.value);
      setOpen(false);
    },
    [setOpen, setSelectedIndex]
  );

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, [setOpen]);

  return {
    handleClick,
    menuItemClick,
    handleToggle
  };
};
