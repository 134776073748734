/* eslint-disable react/prop-types */
import rtlDetect from 'rtl-detect';
import { MeType } from '../me';

const getLocale = (me: MeType): string | null | undefined =>
  me?.locale?.language?.languageCode;

export const useDirection = (me: MeType): string => {
  const locale = getLocale(me);
  const direction = locale && rtlDetect.isRtlLang(locale) ? 'rtl' : 'ltr';

  return direction;
};

export default useDirection;
