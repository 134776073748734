import { makeStyles } from '@material-ui/styles';

export const usePopperStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: theme.direction === 'rtl' && {
    popper: {
      translate: '180px',
      right: 'unset !important',
      left: 0
    }
  },
  popper: {
    zIndex: 20
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    margin: 0,
    padding: theme.spacing(1)
  },
  values: {
    margin: 0,
    padding: 0,
    display: 'grid',
    gridTemplateColumns: [['auto', 'auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0,
    justifyItems: 'stretch',
    alignItems: 'baseline'
  },
  value: {
    textAlign: 'right',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 0, 2)
  },
  gridTemplate: {
    gridTemplateColumns: [['auto', 'auto']]
  },
  item: {
    display: 'contents'
  },
  label: {
    textAlign: 'left'
  },
  totalItem: {
    '& $label, & $value': {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5)
    }
  },
  container: {
    display: 'inline-block',
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    lineHeight: '14px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  diffPositive: {
    marginRight: theme.spacing(-0.5)
  },
  diffNegative: {
    marginRight: theme.spacing(-0.5)
  },
  icon: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(-0.125, 0, -0.125, 0),
    paddingTop: theme.spacing(0.375)
  }
}));

export default useStyles;
