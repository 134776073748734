import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import ResourceRequestCardTotal from '../ResourceRequestCardTotal';

export const ResourceRequestCardHours = ({
  resourceRequestHours,
  allocatedHours,
  deltaHours,
  showAllocationTotals
}) => {
  const intl = useIntl();
  const label = showAllocationTotals
    ? intl.formatMessage({
        id: 'mobileResourcePlanning.totalAllocatedHours'
      })
    : intl.formatMessage({
        id: 'mobileResourcePlanning.totalRequestedHours'
      });

  return (
    <ResourceRequestCardTotal
      label={label}
      showAllocationTotals={showAllocationTotals}
      showResourceRequestedTotals={!showAllocationTotals}
      resourceRequestTotal={resourceRequestHours}
      resourceAllocatedTotal={allocatedHours}
      deltaTotal={deltaHours}
    />
  );
};

ResourceRequestCardHours.propTypes = {
  resourceRequestHours: PropTypes.number,
  allocatedHours: PropTypes.number,
  deltaHours: PropTypes.number,
  showAllocationTotals: PropTypes.bool
};

export default ResourceRequestCardHours;
