import get from 'lodash.get';
import { hasError, getError } from '~/util';
import {
  DATE_TYPE_URI,
  DROP_DOWN_TYPE_URI,
  NUMERIC_TYPE_URI,
  TEXT_TYPE_URI
} from '../definitionTypes';
import { mapNumberValueOrDefault } from './mapNumberValue';

export const resolveCustomFieldValueOrDefault = customFieldValue => {
  const { customFieldDefinition } = customFieldValue;

  const {
    defaultDropDownValue,
    textConfiguration,
    dateConfiguration,
    numericConfiguration
  } = customFieldDefinition;

  const defaultDropDown = get(defaultDropDownValue, 'uri', null);
  const defaultText = get(textConfiguration, 'defaultTextValue', null);
  const defaultDate = get(dateConfiguration, 'defaults.value', null);
  const defaultNumber = get(numericConfiguration, 'defaultNumericValue', null);

  return {
    ...customFieldValue,
    date: customFieldValue.date || defaultDate,
    dropDownOptionUri: customFieldValue.dropDownOptionUri || defaultDropDown,
    number: mapNumberValueOrDefault(customFieldValue.number, defaultNumber),
    text: customFieldValue.text || defaultText
  };
};

export const buildCustomFieldFormValues = customFieldValues =>
  customFieldValues
    .map(resolveCustomFieldValueOrDefault)
    .reduce((values, customFieldValue) => {
      const {
        customField: { id }
      } = customFieldValue;

      return {
        ...values,
        [id]: customFieldValue
      };
    }, {});

export const buildCustomFieldFormValuesFromDefinitions = definitions =>
  definitions.reduce((customFieldValues, definition) => {
    const { id, displayText, type } = definition;

    const customFieldValue = {
      customFieldDefinition: definition,
      customField: {
        id,
        displayText
      },
      customFieldType: {
        id: type.uri
      },
      date: null,
      dropDownOptionUri: null,
      number: null,
      text: null
    };

    return {
      ...customFieldValues,
      [id]: resolveCustomFieldValueOrDefault(customFieldValue)
    };
  }, {});

const customFieldTypeValuePathMap = {
  [DATE_TYPE_URI]: 'date',
  [DROP_DOWN_TYPE_URI]: 'dropDownOptionUri',
  [NUMERIC_TYPE_URI]: 'number',
  [TEXT_TYPE_URI]: 'text'
};

const getOnChangeHandler = ({ handleChange, setFieldValue, name, typeUri }) => {
  switch (typeUri) {
    case DATE_TYPE_URI:
      return date => setFieldValue(name, date);
    case DROP_DOWN_TYPE_URI:
    case NUMERIC_TYPE_URI:
    case TEXT_TYPE_URI:
    default:
      return handleChange;
  }
};

export const getNameAndOnChangeHandlerProps = ({
  handleChange,
  setFieldValue,
  path,
  customFieldValue,
  key
}) => {
  const typeUri = customFieldValue.customFieldType.id;

  const name = `${path}.${key}.${customFieldTypeValuePathMap[typeUri]}`;
  const onChange = getOnChangeHandler({
    handleChange,
    setFieldValue,
    name,
    typeUri
  });

  return {
    name,
    onChange
  };
};

export const getErrorAndHelperTextProps = ({
  errors,
  customFieldValue,
  path
}) => {
  const errorPath = `${path}[${customFieldValue.customField.id}]`;

  return {
    error: hasError(errors, errorPath),
    helperText: getError(errors, errorPath)
  };
};
