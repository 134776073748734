import React from 'react';
import { useIntl } from 'react-intl';
import { ResourcePoolIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';

export const useStyledPoolLabel = ({ isRequired } = false) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <ResourcePoolIcon key="resourcePool" className={classes.labelIcon} />
      <span className={classes.label}>
        {formatMessage({
          id: 'search.resourcePool'
        }) + (isRequired ? ' *' : '')}
      </span>
    </>
  );
};

export default useStyledPoolLabel;
