import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

/** This component should be used only as an input to a Material UI input component
 *  as an `inputComponent`.
 */
export const FormattedNumberInput = ({
  inputRef,
  name,
  onChange,
  decimals,
  fixedDecimalScale,
  onValueChange,
  ariaLabel,
  ...rest
}) => {
  const onChangeForward = useCallback(
    (values, event) =>
      onValueChange
        ? onValueChange({
            target: {
              type: 'number',
              name,
              value: values.floatValue
            }
          })
        : null,
    [name, onValueChange]
  );

  return (
    <NumberFormat
      getInputRef={inputRef}
      onChange={onChange}
      onValueChange={onChangeForward}
      thousandSeparator
      decimalScale={decimals}
      fixedDecimalScale={fixedDecimalScale}
      aria-label={ariaLabel}
      {...rest}
    />
  );
};

FormattedNumberInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  decimals: PropTypes.number,
  ariaLabel: PropTypes.string,
  fixedDecimalScale: PropTypes.bool.isRequired
};

export default FormattedNumberInput;
