export const PROJECT_WORKFLOW_STAGE_TYPE = {
  INITIATE: 'urn:replicon-psa:project-stage:initiate',
  PLANNING: 'urn:replicon-psa:project-stage:planning',
  EXECUTION: 'urn:replicon-psa:project-stage:execution',
  CLOSEOUT: 'urn:replicon-psa:project-stage:close-out',
  ARCHIVED: 'urn:replicon-psa:project-stage:archived'
};

export const getProjectStatusClauses = (
  intl,
  customStatusMapping,
  isCustomStatusNameEnabled
) => {
  return [
    {
      id: PROJECT_WORKFLOW_STAGE_TYPE.INITIATE,
      displayText:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.INITIATE]
          ? customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.INITIATE]
          : intl.formatMessage({
              id: 'projectStatus.initiate'
            })
    },
    {
      id: PROJECT_WORKFLOW_STAGE_TYPE.PLANNING,
      displayText:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.PLANNING]
          ? customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.PLANNING]
          : intl.formatMessage({
              id: 'projectStatus.planning'
            })
    },
    {
      id: PROJECT_WORKFLOW_STAGE_TYPE.EXECUTION,
      displayText:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.EXECUTION]
          ? customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.EXECUTION]
          : intl.formatMessage({
              id: 'projectStatus.execution'
            })
    },
    {
      id: PROJECT_WORKFLOW_STAGE_TYPE.CLOSEOUT,
      displayText:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.CLOSEOUT]
          ? customStatusMapping[PROJECT_WORKFLOW_STAGE_TYPE.CLOSEOUT]
          : intl.formatMessage({
              id: 'projectStatus.closeout'
            })
    },
    {
      id: PROJECT_WORKFLOW_STAGE_TYPE.ARCHIVED,
      displayText: intl.formatMessage({
        id: 'projectStatus.archived'
      })
    }
  ];
};
