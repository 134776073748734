import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import LoadingButton from '../LoadingButton';

const RemoveEntityConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  isRemoving = false,
  resourceKeys,
  children,
  disabled = false,
  ...other
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="entity-confirmation-dialog-title"
    aria-describedby="entity-confirmation-dialog-description"
    {...other}
  >
    <DialogTitle id="entity-confirmation-dialog-title">
      <FormattedMessage id={`${resourceKeys.header}`} />
    </DialogTitle>
    <DialogContent id="entity-confirmation-dialog-description">
      {children}
    </DialogContent>
    <DialogActions>
      <Button
        data-qe-id="CancelDialogButton"
        onClick={onClose}
        disabled={disabled}
      >
        <FormattedMessage id="resourceRequestActions.cancel" />
      </Button>
      <LoadingButton
        data-qe-id="ConfirmDialogButton"
        onClick={onConfirm}
        color="secondary"
        isLoading={isRemoving}
        disabled={isRemoving}
        autoFocus
      >
        <FormattedMessage id={`${resourceKeys.actionButton}`} />
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

RemoveEntityConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  resourceKeys: PropTypes.object.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isRemoving: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RemoveEntityConfirmationDialog;
