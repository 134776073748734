import { Card, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useProjectInfoPermissions } from '~/modules/projects/project/ProjectInfo/useProjectInfoPermissions';
import ChartHeader from '~/modules/common/charts/dashboard/ChartHeader';
import { HoursIcon, CostIcon } from '~/modules/common/components/Icons';
import { PROJECT_PROGRESS_TYPE_ENUM } from '~/modules/projects/project/common/enums/projectProgressType';
import BudgetHoursSummaryChart from './BudgetHoursSummaryChart';
import BudgetCostSummaryChart from './BudgetCostSummaryChart';
import BudgetSummaryChartHelp from './BudgetSummaryChartHelp';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0, 2, 0),
    overflow: 'visible'
  }
}));

const projectBudgetHoursTitleId = 'projectBudgetHours';

const projectBudgetCostTitleId = 'projectBudgetcost';

const BudgetHoursChartTitle = (
  <span id={projectBudgetHoursTitleId}>
    <FormattedMessage id="projectPage.budgetHours" />
  </span>
);
const BudgetCostsChartTitle = (
  <span id={projectBudgetCostTitleId}>
    <FormattedMessage id="projectPage.budgetCosts" />
  </span>
);
const BudgetHoursChartAvatar = <HoursIcon />;
const BudgetCostChartAvatar = <CostIcon />;

const action = projectBudgetChartProps => (
  <BudgetSummaryChartHelp projectBudgetChartProps={projectBudgetChartProps} />
);

export const BudgetSummaryChart = ({ projectDetails }) => {
  const classes = useStyles();

  const {
    canViewProjectCostData,
    canViewProjectEstimates
  } = useProjectInfoPermissions({
    ...projectDetails
  });

  const [projectBudgetView, setProjectBudgetView] = useState(
    PROJECT_PROGRESS_TYPE_ENUM.HOUR
  );

  const showCostContent = canViewProjectCostData && canViewProjectEstimates;

  const projectBudgetChartProps = useMemo(
    () => ({
      showCostContent,
      projectBudgetView,
      setProjectBudgetView
    }),
    [projectBudgetView, showCostContent]
  );

  return (
    <Card className={classes.root}>
      <ChartHeader
        title={
          projectBudgetView === PROJECT_PROGRESS_TYPE_ENUM.HOUR
            ? BudgetHoursChartTitle
            : BudgetCostsChartTitle
        }
        avatar={
          projectBudgetView === PROJECT_PROGRESS_TYPE_ENUM.HOUR
            ? BudgetHoursChartAvatar
            : BudgetCostChartAvatar
        }
        action={action(projectBudgetChartProps)}
      />
      {projectBudgetView === PROJECT_PROGRESS_TYPE_ENUM.HOUR ? (
        <BudgetHoursSummaryChart
          projectDetails={projectDetails}
          projectBudgetHoursTitleId={projectBudgetHoursTitleId}
        />
      ) : (
        <BudgetCostSummaryChart
          projectDetails={projectDetails}
          projectBudgetCostTitleId={projectBudgetCostTitleId}
        />
      )}
    </Card>
  );
};

BudgetSummaryChart.propTypes = {
  projectDetails: PropTypes.object
};

export default BudgetSummaryChart;
