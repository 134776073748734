import React, { memo, useContext, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { TableCell as MuiTableCell } from '@material-ui/core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ListTableVariantContext from '../../../ListTableVariantContext';

const useStyles = makeStyles(() => ({
  tableCellFixed: {
    position: 'sticky',
    left: '0px'
  },
  tableCellLeftOfFixed: {
    // paddingLeft: '324px'
  }
}));

export const TableCell = ({
  classes: classesOverride,
  className,
  fixed,
  leftOfFixed,
  children,
  field,
  onCellClick,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const variant = useContext(ListTableVariantContext);

  const Component = variant === 'table' ? MuiTableCell : 'div';

  const onClickHandler = useCallback(
    event => {
      onCellClick && onCellClick(event, field);
    },
    [field, onCellClick]
  );

  return (
    <Component
      onClick={onClickHandler}
      className={classnames(
        classes.tableCell,
        fixed ? classes.tableCellFixed : undefined,
        leftOfFixed ? classes.tableCellLeftOfFixed : undefined,
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

TableCell.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  leftOfFixed: PropTypes.bool,
  children: PropTypes.any,
  field: PropTypes.string,
  onCellClick: PropTypes.func
};

export default memo(TableCell);
