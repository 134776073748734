import { useMeContext } from '~/modules/me/useMeContext';
import { PROJECT_DAL } from '~/modules/common/enums';

export const useProjectCostPermissions = () => {
  const { permissionsMap } = useMeContext();
  const editProjectestimates =
    permissionsMap['urn:replicon:project-action:edit-project-estimates'];
  const editProjectCostData =
    permissionsMap['urn:replicon:project-action:edit-cost-data'];

  return {
    canEditProjectEstimates: Boolean(
      editProjectestimates &&
        editProjectestimates.dataAccessLevelUris.some(
          dal =>
            dal === PROJECT_DAL.ALL || dal === PROJECT_DAL.PORTFOLIO_MANAGER
        )
    ),
    canEditProjectCostData: Boolean(
      editProjectCostData &&
        editProjectCostData.dataAccessLevelUris.some(
          dal =>
            dal === PROJECT_DAL.ALL || dal === PROJECT_DAL.PORTFOLIO_MANAGER
        )
    )
  };
};
