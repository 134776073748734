import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import SkillRating from './SkillRating';
import useStyles from './useStyles';

export const ResourceRequestSkillRow = ({
  skill,
  classes: classesOverride,
  onSkillRemove,
  maxSkillLevel,
  onSkillRatingUpdate
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverride });

  const onRemoveCallback = useCallback(() => onSkillRemove(skill), [
    onSkillRemove,
    skill
  ]);

  return (
    <>
      <TableRow>
        <TableCell className={classes.resourceRequestSkillNameCell}>
          <div className={classes.skillName}>{skill.displayText}</div>
        </TableCell>
        <TableCell className={classes.cell} />
        <TableCell
          data-qe-id={`${skill.displayText}_RatingSection`}
          className={classes.cell}
        >
          {Boolean(maxSkillLevel) && (
            <SkillRating
              dataQeId={`${skill.displayText}_Rating`}
              skillAssignment={skill}
              maxSkillLevel={maxSkillLevel}
              onSkillRatingUpdate={onSkillRatingUpdate}
              editable
            />
          )}
        </TableCell>
        <TableCell className={classes.removeIconCell}>
          <IconButton
            data-qe-id={`${skill.displayText}_Delete_Skill`}
            onClick={onRemoveCallback}
            className={classes.button}
            aria-label={formatMessage({ id: 'button.delete' })}
          >
            <RemoveCircle />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

ResourceRequestSkillRow.propTypes = {
  classes: PropTypes.object,
  skill: PropTypes.object.isRequired,
  onSkillRemove: PropTypes.func,
  onSkillRatingUpdate: PropTypes.func,
  maxSkillLevel: PropTypes.number
};

export default ResourceRequestSkillRow;
