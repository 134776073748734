export const getMergedUserData = combinedPaginationData => {
  return Object.values(
    (combinedPaginationData || []).reduce((acc, currrentUser) => {
      acc[currrentUser.uri] = acc[currrentUser.uri] || currrentUser;

      return acc;
    }, {})
  );
};

export const getUntouchedAndDisplacedUsers = ({ data, oldUser, newUsers }) => {
  const { untouchedUsers, newUsersWithActuals } = data.reduce(
    (acc, user) => {
      if (newUsers.some(newUser => newUser.uri === user.uri)) {
        if (user.isActualEntry) acc.newUsersWithActuals.push(user);
      } else if (user.uri !== oldUser?.uri || user.isActualEntry)
        acc.untouchedUsers.push(user);

      return acc;
    },
    { untouchedUsers: [], newUsersWithActuals: [] }
  );

  const displacedUsers = newUsers.reduce((acc, newUser) => {
    const newUserWithActualEntry = newUsersWithActuals.find(
      user => user.uri === newUser.uri
    );

    return [
      ...acc,
      ...(newUserWithActualEntry ? [newUserWithActualEntry] : []),
      {
        displayText: newUser.displayText,
        id: newUser.uri,
        uri: newUser.uri,
        __typename: 'UserReference'
      }
    ];
  }, []);

  return { untouchedUsers, displacedUsers };
};

export const getInsertIndexInMergedArray = ({
  currentPage,
  pageSize,
  untouchedUsersMergedLength
}) => {
  const pageEndIndex = currentPage * pageSize - 1;

  return Math.min(untouchedUsersMergedLength, pageEndIndex);
};

export const getInsertIndex = ({ currentPage, pageSize, untouchedUsers }) => {
  const untouchedUsersMerged = getMergedUserData(untouchedUsers);

  const insertIndexInMergedArray = getInsertIndexInMergedArray({
    currentPage,
    pageSize,
    untouchedUsersMergedLength: untouchedUsersMerged.length
  });

  if (untouchedUsers.length === untouchedUsersMerged.length)
    return insertIndexInMergedArray;

  return (
    untouchedUsers
      .map(user => user.uri)
      .lastIndexOf(untouchedUsersMerged[insertIndexInMergedArray - 1].uri) + 1
  );
};

export const shouldDecrementCurrentPage = ({
  currentPage,
  pageSize,
  insertIndex
}) => {
  const pageStartIndex = (currentPage - 1) * pageSize;

  return pageStartIndex > insertIndex;
};
