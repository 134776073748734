import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: theme.spacing(4.5)
  },
  leftContainer: {
    left: 0,
    display: 'flex',
    position: 'sticky',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25, 0, 0.25, 0.5)
  },
  rightContainer: {
    position: 'sticky',
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  totalItem: {
    width: theme.spacing(23.125),
    textAlign: 'right',
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.1)
    },
    height: theme.spacing(4.5)
  },
  totalLabel: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 1)
  }
}));

export default useStyles;
