import React from 'react';
import { Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { User, Decimal } from '~/modules/common/components';
import { useTitleStyles } from './useStyles';

const MAX_RESOURCES_TO_SHOW_IN_TITLE = 1;

export const TaskResourceAllocationTitle = ({
  expanded = false,
  resourceAllocations = [],
  resourceAllocationCount = 0
}) => {
  const classes = useTitleStyles();

  const users = resourceAllocations
    .slice(0, MAX_RESOURCES_TO_SHOW_IN_TITLE)
    .map(a => a.resource);
  const hasMore = resourceAllocationCount > MAX_RESOURCES_TO_SHOW_IN_TITLE;

  return (
    <div className={classes.titleRoot}>
      <Typography variant="subtitle2" className={classes.title}>
        <FormattedMessage id="taskResourceAssignments.title" />
      </Typography>
      {expanded && (
        <ul className={classes.list}>
          {users.map(u => (
            <li className={classes.item} key={u.id}>
              <User user={u} showTooltip />
            </li>
          ))}
          {hasMore && (
            <li className={classes.item}>
              <Typography>
                <Decimal
                  value={
                    resourceAllocationCount - MAX_RESOURCES_TO_SHOW_IN_TITLE
                  }
                  precision={0}
                  prefix="+"
                  suffix=" "
                />
                <FormattedMessage id="taskResourceAssignments.more" />
              </Typography>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default TaskResourceAllocationTitle;

TaskResourceAllocationTitle.propTypes = {
  expanded: PropTypes.bool,
  resourceAllocations: PropTypes.array,
  resourceAllocationCount: PropTypes.number
};
