import { useIntl } from 'react-intl';
import { useDialogState } from '~/modules/common/hooks';

const useTaskAssignmentRowActions = ({ hasTaskAllocation }) => {
  const intl = useIntl();
  const {
    open: removeTaskAssignmentDialogOpen,
    openDialog: openRemoveTaskAssignmentDialog,
    closeDialog: closeRemoveTaskAssignmentDialog
  } = useDialogState(false);

  const {
    open: removeTaskAllocationDialogOpen,
    openDialog: openRemoveTaskAllocationDialog,
    closeDialog: closeRemoveTaskAllocationDialog
  } = useDialogState(false);

  const removeAllocatedTimeAction = {
    label: intl.formatMessage({
      id: 'taskAllocationActions.removeAllocatedTime'
    }),
    onClick: () => {
      openRemoveTaskAllocationDialog();
    }
  };

  const removeAssignmentAction = {
    label: intl.formatMessage({
      id: 'taskAllocationActions.removeTaskAssignment'
    }),
    onClick: () => {
      openRemoveTaskAssignmentDialog();
    }
  };

  const actions = [];

  if (hasTaskAllocation) actions.push(removeAllocatedTimeAction);
  actions.push(removeAssignmentAction);

  return {
    actions,
    removeTaskAssignmentDialogOpen,
    openRemoveTaskAssignmentDialog,
    closeRemoveTaskAssignmentDialog,
    removeTaskAllocationDialogOpen,
    closeRemoveTaskAllocationDialog
  };
};

export default useTaskAssignmentRowActions;
