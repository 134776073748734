import uniqBy from 'lodash/fp/uniqBy';
import { useMemo } from 'react';
import {
  lightBlue,
  amber,
  purple,
  deepPurple,
  teal,
  lime,
  brown,
  indigo,
  green,
  pink,
  yellow,
  grey,
  deepOrange,
  cyan,
  blueGrey,
  orange,
  red,
  blue,
  lightGreen,
  common
} from '@material-ui/core/colors';

const colorOptions = [
  lightBlue[500],
  amber[500],
  purple[500],
  deepPurple[500],
  teal[500],
  lime[500],
  brown[500],
  indigo[500],
  green[500],
  pink[500],
  yellow[500],
  grey[500],
  deepOrange[500],
  cyan[500],
  blueGrey[500],
  orange[500],
  red[500],
  blue[500],
  lightGreen[500]
];
const useSkillsDistributionBubbleChartData = skillDistributionData =>
  useMemo(() => {
    const skillDistribution = skillDistributionData.map(x => ({
      label: x.skill.displayText,
      value: x.userCount,
      category: x.category
    }));

    const allCategories = skillDistribution.map(p => p.category);

    const uniqueCategories = uniqBy('uri', allCategories);
    const legends = uniqueCategories.map((category, index) => ({
      ...category,
      label: category.displayText,
      color: colorOptions[index % colorOptions.length],
      labelColor: common.black
    }));

    const findColor = c => {
      const { color } = legends.find(m => m.label === c.category.displayText);

      return color;
    };
    const chartData = skillDistributionData.map(c => ({
      label: c.skill.displayText,
      value: c.userCount,
      id: c.skill.id,
      color: findColor(c)
    }));

    return { chartData: chartData || [], legends: legends || [] };
  }, [skillDistributionData]);

export default useSkillsDistributionBubbleChartData;
