import { useEffect } from 'react';

const DATE_RANGE_HIDE_KEYS = ['Escape'];

export const useBillPeriodSelector = setFocusedInput => {
  useEffect(() => {
    const closeBillPeriodSelectorOnKeyUp = e => {
      if (DATE_RANGE_HIDE_KEYS.includes(e.key)) {
        setFocusedInput(null);
        e.target.blur();
      }
    };

    window.addEventListener('keyup', closeBillPeriodSelectorOnKeyUp);

    return () =>
      window.removeEventListener('keyup', closeBillPeriodSelectorOnKeyUp);
  }, [setFocusedInput]);
};

export default useBillPeriodSelector;
