import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    width: '100%'
  },
  statusBar: {
    display: 'flex',
    alignItems: 'flex-start',
    flexBasis: theme.spacing(1) / 2,
    zIndex: 10
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row'
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default useStyles;
