import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  variationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(-1)
  },
  variationText: {
    fontSize: theme.spacing(1.25)
  },
  variationPill: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.25, 0.75, 0),
    borderRadius: theme.spacing(0.5) - 1
  },
  OVER: ({ isPsaPpmCostEacEnhancementsEnabled }) => ({
    color: isPsaPpmCostEacEnhancementsEnabled
      ? theme.palette.error.dark
      : theme.palette.taskRollUp.exceeded.main,
    backgroundColor: isPsaPpmCostEacEnhancementsEnabled
      ? theme.palette.error.light
      : theme.palette.taskRollUp.exceeded.light,
    fill: isPsaPpmCostEacEnhancementsEnabled
      ? theme.palette.error.dark
      : theme.palette.taskRollUp.exceeded.main
  }),
  UNDER: ({ isPsaPpmCostEacEnhancementsEnabled }) => ({
    color: isPsaPpmCostEacEnhancementsEnabled
      ? theme.palette.success.dark
      : theme.palette.taskRollUp.under.main,
    backgroundColor: isPsaPpmCostEacEnhancementsEnabled
      ? theme.palette.success.light
      : theme.palette.taskRollUp.under.light,
    fill: isPsaPpmCostEacEnhancementsEnabled
      ? theme.palette.success.dark
      : theme.palette.taskRollUp.under.main
  }),
  MISSING_ESTIMATE: {
    color: '#656565',
    backgroundColor: '#ebebeb',
    fill: theme.palette.taskRollUp.missing.main
  },
  MATCHING: {
    color: '#656565',
    backgroundColor: theme.palette.grey[300],
    fill: theme.palette.success.main
  },
  variationValue: {
    fontSize: theme.spacing(2) - 1,
    fontWeight: 600
  },
  variationLabel: {
    fontSize: theme.spacing(1.5),
    whiteSpace: 'pre'
  },
  variationIcon: {
    fontSize: theme.spacing(2.5),
    marginRight: theme.spacing(1),
    '&$OVER': {
      marginRight: theme.spacing(0.25)
    },
    '&$UNDER': {
      marginRight: theme.spacing(0.25)
    }
  }
});

export default useStyles;
