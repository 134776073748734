import { Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import {
  Decimal,
  NoValue,
  DIFF_VALUE_TYPE,
  DiffValue
} from '~/modules/common/components';
import useStyles, { useCaptionStyles } from './useStyles';

export const RequestAllocationTotals = ({
  classes: classesOverride,
  label,
  showAllocationTotals,
  resourceAllocatedPrefix,
  resourceAllocatedTotal,
  deltaPrefix,
  deltaTotal = 0,
  fixedDecimalScale,
  precision,
  showDiff = false
}) => {
  const classes = useStyles({ classes: classesOverride });
  const cssClasses = useCaptionStyles();

  return (
    <div className={classes.root}>
      <Typography variant="caption" classes={cssClasses}>
        {label}
      </Typography>
      {showAllocationTotals &&
        (resourceAllocatedTotal ? (
          <div>
            <Decimal
              value={resourceAllocatedTotal}
              prefix={resourceAllocatedPrefix && `${resourceAllocatedPrefix} `}
              className={classes.value}
              fixedDecimalScale={fixedDecimalScale}
              precision={precision}
            />
            {deltaTotal !== 0 && !showDiff && (
              <Decimal
                value={deltaTotal}
                className={classes.delta}
                prefix={deltaPrefix && `${deltaPrefix} `}
                fixedDecimalScale={fixedDecimalScale}
                precision={precision}
              />
            )}
            {deltaTotal !== 0 && showDiff && (
              <DiffValue
                diffValueType={
                  deltaTotal > 0
                    ? DIFF_VALUE_TYPE.WARNING
                    : DIFF_VALUE_TYPE.NEGATIVE
                }
              >
                <Decimal
                  value={deltaTotal}
                  prefix={deltaPrefix && `${deltaPrefix} `}
                  fixedDecimalScale={fixedDecimalScale}
                  precision={precision}
                />
              </DiffValue>
            )}
          </div>
        ) : (
          <NoValue className={classes.value} />
        ))}
    </div>
  );
};

RequestAllocationTotals.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  resourceAllocatedTotal: PropTypes.number,
  showAllocationTotals: PropTypes.bool,
  resourceAllocatedPrefix: PropTypes.string,
  deltaPrefix: PropTypes.string,
  deltaTotal: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  precision: PropTypes.number,
  showDiff: PropTypes.bool
};

export default RequestAllocationTotals;
