import { gql } from 'graphql-tag';

export const extensionFieldValueFragment = gql`
  fragment ExtensionFieldValue on ExtensionFieldValue {
    definitionTypeUri
    definition {
      id
      definitionTypeUri
      displayText
      slug
      tags {
        id
        displayText
      }
      fileDetails {
        id
        name
        enabledSourceUris
      }
    }
    textValue
    numericValue
    tag {
      id
      displayText
    }
    fileValue {
      identityUri
      keyValues {
        keyUri
        value {
          text
        }
      }
      linkUri
    }
  }
`;
