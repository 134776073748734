import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { billingTransactionRefetchQueries } from '~/modules/common/components/RefetchQueries';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const DELETE_BILL = gql`
  mutation deleteBill($id: String!) {
    deleteBill2(id: $id)
  }
`;

export const useDeleteBill = createAndView => {
  const [deleteBill] = useMutation(DELETE_BILL);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : ['getDraftTotals', 'getOutstandingBillSummary'];

  return {
    deleteBill: async billId => {
      await deleteBill({
        variables: {
          id: billId
        },
        skip: !billId,
        refetchQueries: createAndView
          ? ['getPageOfAvailableToBill']
          : [...billingTransactionRefetchQueries, ...queries]
      });
    }
  };
};

export default useDeleteBill;
