import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { Link, makeStyles } from '@material-ui/core';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';

const useStyles = makeStyles(theme => ({
  message: {
    margin: 0,
    '& + &': {
      marginTop: theme.spacing(1)
    },
    display: 'inline-block',
    background: '#fff',
    zIndex: 1400,
    padding: 0,
    lineHeight: `${theme.spacing(2)}px`,
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.375)
  }
}));

const allocatedResourcesString = (
  <FormattedMessage id="rateCard.resourceAssignments.allocatedResources" />
);

const timesheetAccessString = (
  <FormattedMessage id="rateCard.resourceAssignments.timesheetAccess" />
);

const getPopperMessage = (formatMessage, projectSlug) => {
  const allocateLink = (
    <Link component={RouterLink} to={`/projects/${projectSlug}/resourcingplan`}>
      {formatMessage({
        id: 'rateCard.resourceAssignments.allocateMessage'
      })}
    </Link>
  );
  const timesheetAccessLink = (
    <Link
      component={RouterLink}
      to={`/projects/${projectSlug}/resourcingplan/timesheet-access`}
    >
      {formatMessage({
        id: 'rateCard.resourceAssignments.timesheetAccessMessage'
      })}
    </Link>
  );

  return formatMessage(
    {
      id: 'rateCard.resourceAssignments.resourceNotFoundMessage'
    },
    {
      allocate: allocateLink,
      timesheetAccess: timesheetAccessLink
    }
  );
};

const getHeader = (classes, message) => (
  <p className={classes.message}>{message}</p>
);

export const useResourceDropdownGroupBy = projectSlug => {
  const classes = useStyles();
  const intl = useIntl();

  const message = getPopperMessage(intl.formatMessage, projectSlug);

  return user =>
    user.heading ? (
      getHeader(classes, message)
    ) : user.id === MORE_AVAILABLE_OPTION_ID ? (
      <></>
    ) : user.isUnusedTeamMember ? (
      timesheetAccessString
    ) : (
      allocatedResourcesString
    );
};
