import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  info: {
    whiteSpace: 'pre-line'
  }
}));
export const RemoveRowNotificationDialog = ({
  open,
  onConfirm,
  onClose,
  isMobile,
  details
}) => {
  const classes = useStyles();
  const usersValues = useMemo(
    () => ({
      users: details.resourcesAssignedToBillingRate
        ? details.resourcesAssignedToBillingRate
            .map(x => x.user && x.user.displayText)
            .filter(x => x)
            .join('; ')
        : null
    }),
    [details.resourcesAssignedToBillingRate]
  );

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="body1" className={classes.title}>
          <FormattedMessage id="billingRateCard.title.main" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" className={classes.info}>
          <FormattedMessage
            id="billingRateCard.removeInfo"
            values={usersValues}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          <FormattedMessage id="billingRateCard.cancel" />
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          <FormattedMessage id="billingRateCard.remove" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
RemoveRowNotificationDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  isMobile: PropTypes.bool,
  details: PropTypes.object
};
export default RemoveRowNotificationDialog;
