import React from 'react';
import { PropTypes } from 'prop-types';
import NumberInput from '../NumberInput';

const FormNumberField = ({ editable = true, fullWidth = true, ...rest }) => (
  <NumberInput fullWidth={fullWidth} disabled={!editable} {...rest} />
);

FormNumberField.propTypes = {
  editable: PropTypes.bool,
  fullWidth: PropTypes.bool
};

export default FormNumberField;
