import { useFormik } from 'formik';
import { object, array, string } from 'yup';
import { PROJECT_STATUS_FILTER } from './enum';

export const getInitialValues = ({ option, statuses }) => {
  return {
    option,
    statuses
  };
};

export const validationSchema = () =>
  object().shape({
    option: string().required(),
    statuses: array().when('option', (option, schema) =>
      option === PROJECT_STATUS_FILTER.CUSTOM_STATUS
        ? schema.required()
        : schema
    )
  });

const useProjectStatusFilterState = ({
  projectStatusFilterOption,
  onDialogClose,
  setProjectStatusFilterOption
}) => {
  const onSubmit = val => {
    setProjectStatusFilterOption(val);
    onDialogClose();
  };

  const formik = useFormik({
    initialValues: getInitialValues({
      option: projectStatusFilterOption.option,
      statuses: projectStatusFilterOption.statuses
    }),
    onSubmit,
    validationSchema: validationSchema()
  });

  return formik;
};

export default useProjectStatusFilterState;
