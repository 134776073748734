import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import HierarchyOptionItem from '~/modules/common/components/HierarchyOptionItem';
import MoreResult from '~/modules/common/components/MoreResult';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';

const getOptionSelected = (option, value) => option.id === value.id;

export const GroupFacetDetails = ({
  className,
  selected,
  setSelected,
  setSearchTerm,
  noOptionsLabel,
  isLoading,
  options,
  label,
  hasMore,
  optionTypeText
}) => {
  const { formatMessage } = useIntl();

  const renderOption = useCallback(
    option =>
      option.key === formatMessage({ id: 'NONE' }) ? (
        <NoneDropdownItem value={option.displayText} />
      ) : option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <HierarchyOptionItem hierarchyLevel={option.hierarchyLevel}>
          {option.displayText}
        </HierarchyOptionItem>
      ),
    [formatMessage]
  );

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );

  const values = isLoading ? [] : options;

  return (
    <FacetDetailField label={label} className={className}>
      <SimpleAutocomplete
        loading={isLoading}
        options={values}
        value={selected}
        getOptionSelected={getOptionSelected}
        variant="standard"
        onChange={onValueChange}
        multiple
        fullWidth
        hiddenLabel
        inputLabel={label}
        noOptionsText={noOptionsLabel}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        label={label}
        onInputChange={onInputChange}
        optionPropText="label"
        hasMore={hasMore}
        optionTypeText={optionTypeText}
      />
    </FacetDetailField>
  );
};

GroupFacetDetails.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  setSearchTerm: PropTypes.func.isRequired,
  noOptionsLabel: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  optionTypeText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default GroupFacetDetails;
