import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { DateTime } from 'luxon';
import useOverviewStyle from './overviewStyles';

const BillingOverviewTableContext = React.createContext();

export const BillingOverviewTableContextProvider = ({
  scale,
  children,
  chartDates,
  headerLevel = 0
}) => {
  const overviewClasses = useOverviewStyle({ headerLevel });

  const currentPeriod = DateTime.local()
    .startOf(scale)
    .toFormat(`'${scale}'-yyyy-MM-dd`);

  const periods = chartDates.map(period => ({
    ...period,
    isCurrentPeriod: period.key === currentPeriod
  }));

  const value = useMemo(() => ({ scale, periods, overviewClasses }), [
    periods,
    scale,
    overviewClasses
  ]);

  return (
    <BillingOverviewTableContext.Provider value={value}>
      {children}
    </BillingOverviewTableContext.Provider>
  );
};

BillingOverviewTableContextProvider.propTypes = {
  scale: PropTypes.string.isRequired,
  chartDates: PropTypes.array,
  children: PropTypes.node.isRequired,
  headerLevel: PropTypes.number
};

export const useBillingOverviewTableContext = () =>
  useContext(BillingOverviewTableContext);

export default BillingOverviewTableContext;
