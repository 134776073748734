import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';

export const GET_PAGE_OF_PAY_CODES = gql`
  query getPageOfPayCodeAvailableForBillingRate(
    $page: Int!
    $pagesize: Int!
    $searchTerm: String
  ) {
    pageOfPayCodeAvailableForBillingRatesAssignment(
      page: $page
      pagesize: $pagesize
      searchTerm: $searchTerm
    ) {
      id
      displayText
    }
  }
`;

export const usePageOfPayCode = ({ searchTerm, skip }) => {
  const { data, loading } = useQuery(GET_PAGE_OF_PAY_CODES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pagesize: PAGE_SIZE + 1,
      searchTerm
    },
    context: {
      debounceKey: 'pay-code-search',
      debounceTimeout: 250
    },
    skip
  });

  const options = data?.pageOfPayCodeAvailableForBillingRatesAssignment || [];

  return {
    loading,
    hasMore: options.length > PAGE_SIZE,
    options: options.slice(0, PAGE_SIZE)
  };
};

export default usePageOfPayCode;
