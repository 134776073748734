import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { ResourceRequestAccessLevel } from '~/types';
import { useResourceManagementContext } from '~/modules/resource-management/common';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';

export const RESOURCE_REQUEST_STATUS_SUMMARY_COUNT_QUERY = gql`
  query Eager_ResourceRequestsStatusCountSummary(
    $roles: [String]
    $skills: [String]
    $locations: [String]
    $divisions: [String]
    $serviceCenters: [String]
    $costCenters: [String]
    $departments: [String]
    $employeeTypes: [String]
    $resourcePools: [String!]
    $requestAccessLevel: ResourceRequestAccessLevel
  ) {
    resourceRequestsStatusCountSummary(
      roles: $roles
      skills: $skills
      locations: $locations
      divisions: $divisions
      serviceCenters: $serviceCenters
      costCenters: $costCenters
      departments: $departments
      employeeTypes: $employeeTypes
      resourcePools: $resourcePools
      requestAccessLevel: $requestAccessLevel
    ) {
      submitted
      toBeHired
      proposed
      allocationRejected
    }
  }
`;

const useResourceRequestsStatusSummaryCounts = () => {
  const { searchCriteria } = useResourceManagementContext();

  const {
    filter: {
      primaryRoles = [],
      roles = [],
      locations,
      divisions,
      serviceCenters,
      costCenters,
      departments,
      employeeTypes,
      skills,
      name,
      userIds,
      resourcePools
    } = {}
  } = useFilterBasedOnSearchCriteria({ searchCriteria });

  const {
    loading,
    error,
    data: { resourceRequestsStatusCountSummary = {} } = {}
  } = useQuery(RESOURCE_REQUEST_STATUS_SUMMARY_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      roles: [...primaryRoles, ...roles],
      skills,
      locations,
      divisions,
      serviceCenters,
      costCenters,
      departments,
      employeeTypes,
      resourcePools,
      requestAccessLevel: ResourceRequestAccessLevel.ResourceManager
    }
  });

  // applying name to resource requests doesnt make any sense, hence it becomes empty
  if (name && name.length !== 0) {
    return {
      loading,
      error,
      resourceRequestsStatusCountSummary: {
        submitted: 0,
        toBeHired: 0,
        proposed: 0,
        allocationRejected: 0
      }
    };
  }

  if (userIds && userIds.length !== 0) {
    return {
      loading,
      error,
      resourceRequestsStatusCountSummary: {
        submitted: 0,
        toBeHired: 0,
        proposed: 0,
        allocationRejected: 0
      }
    };
  }

  return {
    loading,
    error,
    resourceRequestsStatusCountSummary
  };
};

export default useResourceRequestsStatusSummaryCounts;
