import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import User from '~/modules/common/components/User';

const useStyles = makeStyles(theme => ({
  user: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize
  },
  noUser: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const UserFormatter = ({ field, record }) => {
  const classes = useStyles();
  const userClasses = useMemo(() => ({ name: classes.user }), [classes.user]);

  return record[field] ? (
    <User user={record[field]} classes={userClasses} />
  ) : (
    <Typography className={classes.noUser} component="div">
      <FormattedMessage id="addLineItemDialog.noUser" />
    </Typography>
  );
};

UserFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default UserFormatter;
