import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';

export const billAssociatedCreditMemoQuery = gql`
  query getBillAssociatedCreditMemos(
    $page: Int!
    $pageSize: Int!
    $id: String!
  ) {
    getBill(id: $id) {
      id
      associatedCreditMemos(page: $page, pageSize: $pageSize) {
        associatedCreditMemos {
          id
          displayText
          creditDate
          allocatedBillAmount {
            amount
            currency {
              id
              symbol
            }
          }
          allocatedCreditMemoAmount {
            amount
            currency {
              id
              symbol
            }
          }
        }
      }
    }
  }
`;

export const updateQueryOnFetchMoreResult = (
  prev = {
    getBill: { associatedCreditMemos: { associatedCreditMemos: [] } }
  },
  {
    fetchMoreResult = {
      getBill: { associatedCreditMemos: { associatedCreditMemos: [] } }
    }
  }
) => {
  if (!fetchMoreResult) return prev;

  return {
    getBill: {
      ...prev.getBill,
      associatedCreditMemos: {
        ...prev.getBill.associatedCreditMemos,
        associatedCreditMemos: [
          ...prev.getBill.associatedCreditMemos.associatedCreditMemos,
          ...fetchMoreResult.getBill.associatedCreditMemos.associatedCreditMemos
        ]
      }
    }
  };
};

export const PAGE_SIZE = 50;

export const useAssociatedCreditMemos = billId => {
  const [loadingMore, setLoadingMore] = useState(false);

  const { data, loading, refetch, fetchMore } = useQuery(
    billAssociatedCreditMemoQuery,
    {
      variables: {
        page: 1,
        pageSize: PAGE_SIZE,
        id: billId
      },
      skip: !billId,
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const associatedCreditMemos = get(
    data,
    'getBill.associatedCreditMemos.associatedCreditMemos',
    []
  );

  const hasMore = Boolean(
    associatedCreditMemos.length &&
      associatedCreditMemos.length % PAGE_SIZE === 0
  );

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          id: billId,
          page: associatedCreditMemos.length / PAGE_SIZE + 1,
          pageSize: PAGE_SIZE
        },
        updateQuery: updateQueryOnFetchMoreResult
      });
    } finally {
      setLoadingMore(false);
    }
  }, [billId, fetchMore, setLoadingMore, hasMore, associatedCreditMemos]);

  return {
    loading,
    refetch,
    associatedCreditMemos,
    hasMore,
    loadMore,
    loadingMore
  };
};

export default useAssociatedCreditMemos;
