import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { mapToServiceFilter } from './useBillingTransactionRows';

export const BILLING_TRANSACTION_ROW_COUNT = gql`
  query getBillingTransactionRowCounts($filter: BillingTransactionFilter2) {
    billingTransactionRowCounts: billingTransactionRowCounts2(filter: $filter)
  }
`;

export const useBillingTransactionRowCounts = ({
  projectUri,
  clientUri,
  programId,
  billingTransactionFilter
}) => {
  const { data, loading } = useQuery(BILLING_TRANSACTION_ROW_COUNT, {
    variables: {
      filter: mapToServiceFilter({
        projectUri,
        clientUri,
        programUri: programId,
        billingTransactionFilter
      })
    },

    fetchPolicy: 'cache-and-network'
  });

  const billingTransactionRowCounts = get(
    data,
    'billingTransactionRowCounts',
    0
  );

  return { count: billingTransactionRowCounts, isRowCountLoading: loading };
};

export default useBillingTransactionRowCounts;
