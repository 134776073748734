import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogTitle, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  resource: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const TaskAssignmentDialogTitle = ({ resource }) => {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography>
      <Typography data-qe-id="TaskAssignmentDialog_Title" variant="h6">
        <FormattedMessage id="taskAssignmentDialog.title" />
        <span className={classes.resource}>{resource.displayText}</span>
      </Typography>
    </DialogTitle>
  );
};

TaskAssignmentDialogTitle.propTypes = {
  resource: PropTypes.object.isRequired
};
