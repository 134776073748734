import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export const FileFieldText = ({ value }) => {
  const classes = useStyles();

  const { linkName, linkUri } = useMemo(() => {
    if (!value) {
      return {
        linkName: '',
        linkUri: ''
      };
    }

    const fileKeyValue = value.keyValues
      ? value.keyValues.find(
          kv =>
            kv.keyUri === 'urn:replicon:binary-object-keyvalue-key:file-name'
        )
      : null;

    const name = fileKeyValue ? fileKeyValue.value.text : value.linkUri;

    return {
      linkName: name,
      linkUri: value.linkUri
    };
  }, [value]);

  return (
    <span className={classes.root}>
      <Link href={linkUri} target="_blank" rel="noopener noreferrer">
        {linkName}
      </Link>
    </span>
  );
};

FileFieldText.propTypes = {
  value: PropTypes.object
};

export default FileFieldText;
