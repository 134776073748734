import { gql } from 'graphql-tag';
import { TaskEntryDetailsDataFragmentDoc } from '~/types';
import { extensionFieldValueFragment } from '~/modules/extensionFields/fragments';

export const taskDetailsQuery = gql`
  query getTaskDetails($taskId: String!, $effectiveTimestamp: Date!) {
    task(taskId: $taskId) {
      id
      uri
      slug
      displayText
      name
      code
      description
      initialEstimatedHours
      totalWorkToDate(effectiveTimestamp: $effectiveTimestamp)
      startDate
      endDate
      progressStatus(effectiveTimestamp: $effectiveTimestamp)
      fullPath {
        id
        uri
        slug
        displayText
      }
      projectReference {
        id
        displayText
      }
      taskEntries(effectiveTimestamp: $effectiveTimestamp) {
        ...TaskEntryDetailsData
        rowNumber
      }
      extensionFieldValues {
        ...ExtensionFieldValue
      }
    }
  }
  ${extensionFieldValueFragment}
  ${TaskEntryDetailsDataFragmentDoc}
`;
