import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  taskFieldLabel: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize
  }
}));

export const TaskField = ({ label, children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body2" className={classes.taskFieldLabel}>
          {label}
        </Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

TaskField.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default TaskField;
