import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  divider: ({ isMobile }) => ({
    marginTop: theme.spacing(isMobile ? 0.5 : 2),
    height: theme.spacing(0.25),
    width: '94%',
    left: theme.spacing(isMobile ? 1 : 3) + 1,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  })
}));

const Divider = ({ classes }) => {
  const isMobile = useMediaQuery('(max-width:690px)', { noSsr: true });
  const dividerClasses = useStyles({ classes, isMobile });

  return <div className={classNames(dividerClasses, classes)} />;
};

Divider.propTypes = {
  classes: PropTypes.object
};

export default Divider;
