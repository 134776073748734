import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const CREATE_PROJECT_REVENUE_POLICY_RECALCULATE_QUERY = gql`
  mutation createProjectRevenuePolicyScriptRecalculationBatch(
    $projectId: String!
  ) {
    createProjectRevenuePolicyScriptRecalculationBatch2(projectId: $projectId) {
      batchUri
    }
  }
`;

export const useCreateProjectRevenuePolicyScriptRecalculationBatch = () => {
  const [revenuePolicyScriptRecalculationBatch] = useMutation(
    CREATE_PROJECT_REVENUE_POLICY_RECALCULATE_QUERY
  );

  return {
    revenuePolicyScriptRecalculationBatch: async ({ projectId }) => {
      const { data } =
        (await revenuePolicyScriptRecalculationBatch({
          variables: {
            projectId
          }
        })) || {};

      const result = get(
        data,
        'createProjectRevenuePolicyScriptRecalculationBatch2',
        {}
      );

      return result;
    }
  };
};

export default useCreateProjectRevenuePolicyScriptRecalculationBatch;
