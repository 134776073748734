import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from './withStyles';

export const FacetDetailField = ({ classes, className, label, children }) => (
  <div className={classNames(classes.root, className)}>
    <span className={classes.label}>{label}</span>
    {children}
  </div>
);

FacetDetailField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

export default withStyles(FacetDetailField);
