import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { RequestActionButton } from '~/modules/resourcing/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.table.border}`,
    width: '100%',
    zIndex: '1000',
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1, 2, 1, 2)
  }
}));

export const ResourceRequestDrawerActions = ({ onClose }) => {
  const classes = useStyles();

  const intl = useIntl();

  return (
    <div className={classes.root}>
      <RequestActionButton
        label={intl.formatMessage({ id: 'resourceRequestActions.ok' })}
        onClick={onClose}
        id="okActionBtn"
      />
    </div>
  );
};

ResourceRequestDrawerActions.propTypes = {
  onClose: PropTypes.func
};

export default ResourceRequestDrawerActions;
