import { useCallback } from 'react';
import { removeAllTooltips } from './useOnTaskHoverTooltips';
import { getTaskEventHandlers, openTaskDrawer } from './task-event-handlers';

export const TASK_TREE_ROW = 'gantt_tree_content';
export const TASK_TREE_NAME = 'gantt_task_name';
export const TASK_BAR = 'gantt_task_content';
export const TASK_ADD = 'gantt_add';
export const TASK_COLLAPSE = 'gantt_tree_icon gantt_close';
export const TASK_EXPAND = 'gantt_tree_icon gantt_open';
export const TASK_MISSING_DATE_CONTAINER = 'gantt_task_bar_name_tooltip';
export const TREE_PROJECT_ICON_ID = 'gantt_tree_project_icon';

export const TASK_NAME_CONTENT = 'gantt_task_content_wrapper';
export const TASK_SECONDARY_TEXT = 'gantt_secondary_text';
export const SVG_ICON = 'MuiSvgIcon-root';
export const TASK_NAME_CONTAINER = 'gantt_TaskName_Container';
export const RESOURCE_ESTIMATES_SUMMARY = 'resourceEstimatesSummary';

const isObject = value => {
  const type = typeof value;

  return value !== null && type === 'object';
};

const includesClassNameText = (className, text) => className?.includes(text);

const hasSvgIcon = ele =>
  isObject(ele) && includesClassNameText(ele?.baseVal, SVG_ICON);

export const isIconClick = ({ className, parentNodeClassName }) =>
  hasSvgIcon(className) || hasSvgIcon(parentNodeClassName);

export const isTaskNameAreaClick = ({ className, parentNodeClassName }) =>
  includesClassNameText(className, TASK_NAME_CONTENT) ||
  includesClassNameText(className, TASK_SECONDARY_TEXT) ||
  includesClassNameText(className, TASK_NAME_CONTAINER) ||
  includesClassNameText(parentNodeClassName, TASK_NAME_CONTENT) ||
  includesClassNameText(parentNodeClassName, TASK_SECONDARY_TEXT) ||
  includesClassNameText(parentNodeClassName, TASK_NAME_CONTAINER) ||
  includesClassNameText(parentNodeClassName, RESOURCE_ESTIMATES_SUMMARY);

export const useOnTaskClick2 = ({
  me,
  history,
  hasEditTaskPermissions,
  setGanttRef,
  openAddTaskDialog,
  collapseTask,
  expandTask
}) =>
  useCallback(
    gantt => (id, event) => {
      const isProject = gantt.getTaskByIndex(0).id === id;
      const { target } = event;

      const { className, parentNode } = target;

      const { isRolledUpTaskEstimateCalculationMethodEnabled } = me;

      const {
        onTaskCollapse,
        onDrawerOpen,
        onTaskExpand,
        onTaskAdd,
        onTaskBarClick
      } = getTaskEventHandlers({
        gantt,
        id,
        isRolledUpTaskEstimateCalculationMethodEnabled,
        isProject,
        collapseTask,
        expandTask,
        openAddTaskDialog,
        history,
        editable: false,
        setGanttRef
      });

      if (
        isIconClick({ className, parentNodeClassName: parentNode.className })
      ) {
        return onDrawerOpen();
      }

      if (
        isTaskNameAreaClick({
          className,
          parentNodeClassName: parentNode?.className
        })
      ) {
        return onDrawerOpen();
      }

      const enableMissingDatePill =
        isRolledUpTaskEstimateCalculationMethodEnabled &&
        !isProject &&
        parentNode &&
        parentNode.className === TASK_MISSING_DATE_CONTAINER;

      switch (className) {
        case TASK_TREE_ROW:
        case TASK_TREE_NAME: {
          return onDrawerOpen();
        }
        case TASK_ADD: {
          return onTaskAdd();
        }
        case TASK_COLLAPSE: {
          return onTaskCollapse();
        }
        case TASK_EXPAND: {
          return onTaskExpand();
        }
        case TASK_BAR: {
          return onTaskBarClick();
        }
        default:
          if (enableMissingDatePill) {
            removeAllTooltips();
            openTaskDrawer({ history, gantt, id, editable: false, expandTask });
            break;
          }

          return false;
      }

      return true;
    },
    [collapseTask, expandTask, history, me, openAddTaskDialog, setGanttRef]
  );

export default useOnTaskClick2;
