import { gql } from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { ResourceRequestStatus } from '~/types';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';
import { mapToServiceRequest } from '../../util';

export const TENTATIVE_RESOURCE_REQUEST = gql`
  mutation MarkResourceRequestAsTentative(
    $input: MarkResourceRequestAsTentativeInput!
  ) {
    markResourceRequestAsTentative2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
`;

export const invokeMarkResourceRequestAsTentative = markResourceRequestAsTentative => (
  request,
  chartSelectedTab,
  requestAccessLevel
) =>
  markResourceRequestAsTentative(
    mapToServiceRequest({
      request,
      responseType: 'markResourceRequestAsTentative2',
      responseTypeName: 'MarkResourceRequestAsTentativeResult',
      requestStatus: ResourceRequestStatus.Tentative,
      chartSelectedTab,
      requestAccessLevel
    })
  );

export default graphql(TENTATIVE_RESOURCE_REQUEST, {
  name: 'markResourceRequestAsTentative'
});
