import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment
} from '~/modules/resourcing/common/fragments';

export const QUICK_ALLOCATIONS_QUERY = gql`
  query BulkGetQuickAllocations($filter: ResourceAllocationFilter) {
    resourceAllocations(filter: $filter) {
      resourceAllocations {
        ...SpecificResourceAllocation
        role {
          uri
          id
          displayText
        }
        user {
          ...SpecificResourceAllocationUser
        }
        project {
          id
          startDate
          endDate
          name
          displayText
          permittedActionUris
          defaultScheduleRule {
            dateRange {
              startDate
              endDate
            }
            do
          }
        }
      }
      nextPageCursor
    }
  }
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
`;

export const tryLoadCachedAllocationQuery = ({ proxy, variables }) => {
  try {
    return proxy.readQuery({
      query: QUICK_ALLOCATIONS_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

const useQuickAllocationProjectAllocationsByIds = ({
  allocationIds,
  skip = false
}) => {
  const variables = {
    filter: {
      resourceAllocationIds: allocationIds
    }
  };

  const { loading, data } = useQuery(QUICK_ALLOCATIONS_QUERY, {
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip
  });

  const resourceAllocations =
    data?.resourceAllocations?.resourceAllocations || [];

  return {
    loadingAllocations: loading,
    resourceAllocations
  };
};

export default useQuickAllocationProjectAllocationsByIds;
