import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { projectWorkflowFragment } from '../fragments/projectWorkflow.fragment';

export const PROJECT_WORKFLOW_QUERY = gql`
  query GetProjectWorkflow($projectId: String!) {
    project(projectId: $projectId) {
      id
      workflow {
        ...ProjectWorkflow
      }
      workflowChecklist {
        id
        checklist
      }
    }
  }
  ${projectWorkflowFragment}
`;

export const useProjectWorkflowQuery = options =>
  useQuery(PROJECT_WORKFLOW_QUERY, options);
