import { roleTag } from '~/modules/common/components/SearchBox/Facets/RoleFacet/tag';
import { resourceTag } from '~/modules/common/components/SearchBox/Facets/UserFacet/tag';
import { resourcePoolTag } from '~/modules/common/components/SearchBox/Facets/ResourcePoolFacet/tag';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import { checkForCriteria } from '~/modules/common/util';

const allTags = [roleTag, resourceTag, resourcePoolTag];

export const getForCriteria = (criterions, tag) =>
  checkForCriteria(criterions, tag)
    ? criterions[tag].map(v => {
        if (v.value === NONE_OPTION_KEY) return null;

        return v.value;
      })
    : undefined;

export const getIdsForCriteria = (criterions, tag) =>
  criterions && criterions[tag] && criterions[tag].length > 0
    ? criterions[tag].map(v => v.id)
    : undefined;

const useProjectResourcingPlanSearch = ({
  searchCriteria: { keywords, criterions }
}) => {
  if (
    !(
      (keywords && keywords.length > 0) ||
      allTags.some(tag => checkForCriteria(criterions, tag))
    )
  ) {
    return {};
  }

  return {
    searchText:
      keywords && keywords.length > 0 ? keywords.join(' ') : undefined,
    roleUris: getForCriteria(criterions, roleTag),
    userIds: getIdsForCriteria(criterions, resourceTag),
    resourcePoolIds: getIdsForCriteria(criterions, resourcePoolTag)
  };
};

export default useProjectResourcingPlanSearch;
