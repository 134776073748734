import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useDialogState } from '~/modules/common/hooks';
import { useBlockNavigation } from './useBlockNavigation';
import { useBlockExternalNavigation } from './useBlockExternalNavigation';
import UnsavedChangesPromptDialog from './UnsavedChangesPromptDialog';

export const handleOk = ({
  blockedAction,
  closeDialog,
  history,
  unblockNavigation
}) => {
  closeDialog();

  const { action } = blockedAction;

  if (action === 'POP') {
    history.goBack();

    return;
  }

  unblockNavigation();
  history.push(blockedAction.location);
};

export const UnsavedChangesPrompt = ({
  when,
  history,
  location,
  handleOnOKClick
}) => {
  const { open, openDialog, closeDialog } = useDialogState();
  const [blockedAction, setBlockedAction] = useState(null);
  const unblockRef = useRef();
  const unblockNavigation = () => unblockRef.current && unblockRef.current();

  useBlockNavigation({
    when,
    history,
    currentLocation: location,
    setBlockedAction,
    unblockRef,
    openDialog
  });

  const onOkClick = useCallback(() => {
    handleOk({
      blockedAction,
      closeDialog,
      history,
      unblockNavigation
    });
    if (handleOnOKClick) {
      handleOnOKClick();
    }
  }, [blockedAction, closeDialog, handleOnOKClick, history, unblockNavigation]);

  useBlockExternalNavigation(when);

  return (
    <UnsavedChangesPromptDialog
      open={open}
      onCancelClick={closeDialog}
      onOkClick={onOkClick}
    />
  );
};

UnsavedChangesPrompt.propTypes = {
  when: PropTypes.bool.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  handleOnOKClick: PropTypes.func
};

export default withRouter(UnsavedChangesPrompt);
