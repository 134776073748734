import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@keyframes rotateAnimation': {
    from: { transform: 'rotate(360deg)' },
    to: { transform: 'rotate(0deg)' }
  },

  score: {
    margin: theme.spacing(0.5)
  },

  tooltipTitle: {
    fontWeight: 'bold',
    margin: theme.spacing(0.25, 0, 0.5, 0),
    fontSize: theme.typography.caption.fontSize
  },

  matchList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: [[`${theme.spacing(2)}px`, 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: theme.spacing(0.5),
    gridRowGap: 0,
    justifyItems: 'start',
    alignItems: 'center'
  },
  matchItem: {
    display: 'contents'
  },
  matchIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  matchIconMatch: {
    color: theme.palette.success.main
  },
  matchIconPartialMatch: {
    color: theme.palette.warning.main
  },
  matchIconNotMatch: {
    color: theme.palette.error.main
  },
  matchLabel: {},
  matchDetails: { marginLeft: theme.spacing(0.5) },

  calculationTime: {
    margin: theme.spacing(1, 0, 0, 0)
  }
}));

export default useStyles;
