import { gql } from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';
import { makeTenantScopedUri } from '~/util';
import { ResourceAllocationStatus } from '~/types';
import { getDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';
import { appendScheduleRuleTypeNameFields } from '~/modules/resourcing/common/util/resourceAllocationUtil';
import { tryLoadCachedResourceRequestsQuery } from '~/modules/resourcing/common/util/resourceRequestUtil';
import { RESOURCE_USER_TYPE } from '~/modules/resourcing/enums';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import { specificResourceAllocationFragment } from '~/modules/resourcing/common/fragments';

export const CREATE_RESOURCE_ALLOCATION = gql`
  mutation CreateResourceAllocation($input: CreateResourceAllocationInput!) {
    createResourceAllocation2(input: $input) {
      resourceAllocation {
        ...SpecificResourceAllocation
      }
    }
  }
  ${specificResourceAllocationFragment}
`;

export const appendToProjectResourceAllocations = (
  existingResourceAllocations,
  newAllocation
) => [...existingResourceAllocations, newAllocation];

export const updateCache = (updateFunction, projectId) => (
  proxy,
  mutationResponse
) => {
  const {
    data: {
      createResourceAllocation2: {
        isOptimistic,
        resourceAllocation: newAllocation
      }
    }
  } = mutationResponse;

  invokeUpdateCache({
    proxy,
    updateFunction,
    projectId,
    newAllocation,
    isOptimistic
  });
};

export const invokeUpdateCache = ({
  proxy,
  updateFunction,
  projectId,
  newAllocation,
  isOptimistic
}) => {
  const cachedResourceRequests = tryLoadCachedResourceRequestsQuery({
    proxy,
    projectUri: projectId,
    optimistic: isOptimistic
  });

  if (cachedResourceRequests) {
    const { resourceRequests: data } = cachedResourceRequests;

    proxy.writeQuery({
      query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
      variables: {
        projectUri: projectId,
        cursor: null,
        limit: 500
      },
      data: {
        resourceRequests: {
          ...data,
          resourceRequests: data.resourceRequests.map(req =>
            req.id === newAllocation.resourceRequestId
              ? {
                  ...req,
                  resourceAllocations: updateFunction(
                    req.resourceAllocations,
                    newAllocation
                  )
                }
              : req
          )
        }
      }
    });
  }
};

export const buildResourceAllocationFromResourceRequest = ({
  resourceRequest,
  scheduleRules,
  user,
  me
}) => {
  const dateRange = getDateRangeFromScheduleRules(scheduleRules);

  const { matchScore } = user;

  const currentCostRate = user.costRateAsOf;

  return {
    __typename: 'ResourceAllocation',
    id: makeTenantScopedUri(me, 'psa-resource-allocation', uuidv4()),
    projectUri: resourceRequest.projectUri,
    resourceRequestId: resourceRequest ? resourceRequest.id : null,
    ...(dateRange || {}),
    scheduleRules,
    isAdjustedLoading:
      (resourceRequest && resourceRequest.isAdjustedLoading) || false,
    load: (resourceRequest && resourceRequest.load) || 100,
    user: user
      ? {
          user: {
            slug: user.slug,
            displayText: user.displayText,
            uri: user.id,
            permittedActionUris: [],
            roles: user.roles || [],
            __typename: 'UserReference'
          },
          userUri: user.id,
          costRate: currentCostRate ? currentCostRate.amount : null,
          costCurrencyUri: currentCostRate ? currentCostRate.currency.id : null,
          costCurrency: currentCostRate ? currentCostRate.currency : null,
          userType: RESOURCE_USER_TYPE.ASSIGNED,
          primaryRoleCostRate: user.primaryRoleCostRate || 0,
          primaryRoleCostCurrencyUri: user.primaryRoleCostCurrencyUri || null,
          primaryRoleCostCurrency: user.primaryRoleCostCurrency || null,
          __typename: 'ResourceAllocationUser'
        }
      : { userType: RESOURCE_USER_TYPE.UNASSIGNED },
    allocationStatus: ResourceAllocationStatus.Draft,
    requestedRoleUri:
      resourceRequest && resourceRequest.role ? resourceRequest.role.id : null,
    matchScore: matchScore && matchScore.score,
    comment: null,
    exchangeRateValues: null,
    roleCostExchangeRateValues: null,
    matchScoreDimensions: matchScore
      ? {
          matchedDimensions: matchScore.matchingDimensions || [],
          unmatchedDimensions: matchScore.unMatchedDimensions || []
        }
      : {}
  };
};

export const buildResourceAllocation = ({
  user,
  me,
  roleUri,
  load,
  projectUri,
  scheduleRules,
  allocationStatus,
  allocationId = null
}) => {
  const dateRange = getDateRangeFromScheduleRules(scheduleRules);

  const { matchScore } = user;

  return {
    __typename: 'ResourceAllocation',
    id:
      allocationId ||
      makeTenantScopedUri(me, 'psa-resource-allocation', uuidv4()),
    projectUri,
    ...(dateRange || {}),
    scheduleRules,
    load: load || 100,
    user: user
      ? {
          user: {
            slug: user.slug,
            displayText: user.displayText,
            uri: user.id || user.uri || user.userUri,
            permittedActionUris: [],
            roles: user.roles || [],
            __typename: 'UserReference'
          },
          userUri: user.id || user.uri || user.userUri,
          costRate: user.currentCostRate ? user.currentCostRate.amount : null,
          costCurrencyUri: user.currentCostRate
            ? user.currentCostRate.currency.id
            : null,
          costCurrency: user.currentCostRate
            ? user.currentCostRate.currency
            : null,
          userType: RESOURCE_USER_TYPE.ASSIGNED,
          primaryRoleCostRate: user.primaryRoleCostRate || 0,
          primaryRoleCostCurrencyUri: user.primaryRoleCostCurrencyUri || null,
          primaryRoleCostCurrency: user.primaryRoleCostCurrency || null,
          __typename: 'ResourceAllocationUser'
        }
      : { userType: RESOURCE_USER_TYPE.UNASSIGNED },
    allocationStatus,
    requestedRoleUri: roleUri,
    matchScore: matchScore && matchScore.score,
    exchangeRateValues: null,
    roleCostExchangeRateValues: null
  };
};

export const buildCreateResourceAllocationOptimisticResponse = ({
  allocation,
  user
}) => ({
  __typename: 'Mutation',
  createResourceAllocation2: {
    __typename: 'CreateResourceAllocationResult',
    resourceAllocation: {
      isOptimistic: true,
      __typename: 'ResourceAllocation',
      id: allocation.id,
      resourceRequestId: allocation.resourceRequestId,
      projectUri: allocation.projectUri,
      user: {
        ...allocation.user,
        costCurrency: user.currentCostRate
          ? user.currentCostRate.currency
          : null,
        user: {
          slug: user.slug,
          displayText: user.displayText,
          uri: user.id,
          permittedActionUris: [],
          roles: user.roles || [],
          __typename: 'UserReference'
        },
        primaryRoleCostRate: user.primaryRoleCostRate || 0,
        primaryRoleCostCurrencyUri: user.primaryRoleCostCurrencyUri || null,
        primaryRoleCostCurrency: user.primaryRoleCostCurrency
          ? {
              __typename: 'Currency',
              id: user.primaryRoleCostCurrency.id,
              displayText: user.primaryRoleCostCurrency.displayText
            }
          : null,
        __typename: 'ResourceAllocationUser'
      },
      allocationStatus:
        allocation.allocationStatus || ResourceAllocationStatus.Draft,
      isAdjustedLoading: allocation.isAdjustedLoading,
      scheduleRules: allocation.scheduleRules.map(
        appendScheduleRuleTypeNameFields
      ),
      endDate:
        allocation.endDate || allocation.scheduleRules[0].dateRange.endDate,
      startDate:
        allocation.startDate || allocation.scheduleRules[0].dateRange.startDate,
      load: allocation.load,
      exchangeRateValues: allocation.exchangeRateValues || null,
      roleCostExchangeRateValues: allocation.roleCostExchangeRateValues || null
    }
  }
});
