import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useColumns } from '~/modules/common/components/ListTable';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import getFormatters from './formatters';

export const buildFooters = ({
  selectedColumns,
  hasMultipleCurrencies,
  isPsaFPOvertimeBillingEnabled
}) => ({
  rowSelector: {
    id: 'rowSelector',
    visible: true
  },
  displayText: {
    id: 'amount',
    align: 'right',
    value: <FormattedMessage id="lineItemsTable.total" />,
    visible: true
  },
  itemType: {
    id: 'itemType',
    visible: selectedColumns.includes(BILLING_COLUMN_TYPE.ITEMTYPE)
  },
  taskName: {
    id: 'task',
    visible: selectedColumns.includes(BILLING_COLUMN_TYPE.TASK)
  },
  user: {
    id: 'users',
    visible: selectedColumns.includes(BILLING_COLUMN_TYPE.USER)
  },
  quantity: {
    id: 'quantity',
    visible: selectedColumns.includes(BILLING_COLUMN_TYPE.UNIT)
  },
  billingItemRate: {
    id: 'billingItemRate',
    visible: selectedColumns.includes(BILLING_COLUMN_TYPE.RATE)
  },
  timesheetPeriod: {
    id: 'timesheetPeriod',
    visible: selectedColumns.includes(BILLING_COLUMN_TYPE.TIMESHEET_PERIOD)
  },
  payCode: {
    id: 'payCode',
    visible:
      isPsaFPOvertimeBillingEnabled &&
      selectedColumns.includes(BILLING_COLUMN_TYPE.PAYCODE)
  },
  role: {
    id: 'role',
    visible:
      isPsaFPOvertimeBillingEnabled &&
      selectedColumns.includes(BILLING_COLUMN_TYPE.ROLE)
  },
  billingAmountOriginal: {
    id: 'billingAmountOriginal',
    visible: hasMultipleCurrencies
  },
  expenseCode: {
    id: 'expenseCode',
    visible: selectedColumns.includes(BILLING_COLUMN_TYPE.EXPENSE_CODE)
  },
  billingAmount: {
    id: 'billingAmount',
    visible: true,
    align: 'right'
  }
});

export const useListFooters = props =>
  useColumns({
    columns: buildFooters(props),
    renders: getFormatters()
  });
