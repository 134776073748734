import PropTypes from 'prop-types';
import React from 'react';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import ResourceChartTotalPopperContent from './ResourceChartTotalPopperContent';
import { usePopperStyles } from './useStyles';

export const ResourceChartTotalPopper = ({
  id,
  anchorEl,
  onMenuClose,
  requestedHours,
  requestedCost,
  allocatedHours,
  allocatedCost,
  viewCosts,
  viewAllocation,
  viewDifference,
  viewGrandTotal = false
}) => {
  const popperClasses = usePopperStyles();

  return (
    <ArrowPopup
      id={id}
      anchorEl={anchorEl}
      placement="left"
      onClose={onMenuClose}
      preventOverflow
      classes={popperClasses}
    >
      <ResourceChartTotalPopperContent
        requestedHours={requestedHours}
        requestedCost={requestedCost}
        allocatedHours={allocatedHours}
        allocatedCost={allocatedCost}
        viewCosts={viewCosts}
        viewAllocation={viewAllocation}
        viewDifference={viewDifference}
        viewGrandTotal={viewGrandTotal}
      />
    </ArrowPopup>
  );
};

ResourceChartTotalPopper.propTypes = {
  id: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  onMenuClose: PropTypes.func,
  requestedHours: PropTypes.number,
  requestedCost: PropTypes.object,
  allocatedHours: PropTypes.number,
  allocatedCost: PropTypes.object,
  viewCosts: PropTypes.bool.isRequired,
  viewAllocation: PropTypes.bool.isRequired,
  viewDifference: PropTypes.bool.isRequired,
  viewGrandTotal: PropTypes.bool
};

export default ResourceChartTotalPopper;
