import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Menu,
  MenuItem,
  Chip,
  Typography,
  Grid,
  makeStyles
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useMenuState } from '~/modules/common/hooks';
import { useOnChangeHandlers } from './useOnChangeHandlers';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  title: {
    color: theme.palette.text.secondary
  }
}));

const ExpenseCodeChips = ({ editable }) => {
  const classes = useStyles();
  const {
    values: { expenseCodes, isAddExpenseCodeButtonEnabled },
    setFieldValue
  } = useFormikContext();
  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();
  const { onAdd, onRemove } = useOnChangeHandlers({
    setFieldValue,
    expenseCodes,
    onMenuClose
  });

  return (
    <div>
      <div>
        <Typography variant="subtitle1" className={classes.title}>
          <FormattedMessage id="projectTimeAndExpenseCard.expenseCodes" />
        </Typography>
      </div>
      {editable && (
        <div>
          <Button
            className={classes.button}
            aria-owns={anchorEl ? 'expense-codes-menu' : undefined}
            aria-haspopup="true"
            color="primary"
            variant="contained"
            onClick={onMenuClick}
            disabled={!isAddExpenseCodeButtonEnabled}
          >
            <FormattedMessage id="projectTimeAndExpenseCard.addExpenseCode" />
          </Button>
          <Menu
            id="expense-codes-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onMenuClose}
          >
            {expenseCodes
              .filter(code => !code.isExpenseEntryToThisCodeAllowed)
              .map(code => (
                <MenuItem onClick={onAdd} id={code.id} key={code.id}>
                  {code.displayText}
                </MenuItem>
              ))}
          </Menu>
        </div>
      )}
      <Grid container spacing={1}>
        {expenseCodes
          .filter(code => code.isExpenseEntryToThisCodeAllowed)
          .map(code => (
            <Grid item key={code.id}>
              <Chip
                id={code.id}
                label={code.displayText}
                onDelete={editable ? onRemove({ id: code.id }) : undefined}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

ExpenseCodeChips.propTypes = {
  editable: PropTypes.bool
};

export default ExpenseCodeChips;
