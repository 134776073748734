import React from 'react';
import { IconButton } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import { useIntl } from 'react-intl';

export const SettingsButton = ({ dataQeId, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <IconButton
      data-qe-id={dataQeId && `${dataQeId}_SettingsButton`}
      {...props}
      aria-label={formatMessage({ id: 'settingsActionLink.settings' })}
    >
      <SettingsIcon />
    </IconButton>
  );
};

SettingsButton.propTypes = {
  dataQeId: PropTypes.string
};

export default SettingsButton;
