import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useAvatar from '~/modules/common/components/Avatars/UserAvatar/useAvatar';
import AvatarUploadDialogContent from './AvatarUploadDialogContent';
import AvatarUploadDialogRemoveButton from './AvatarUploadDialogRemoveButton';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  removeButton: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1)
  }
}));

const avatarUploadDialogRole = {
  role: 'presentation'
};

const AvatarUploadDialog = ({ isOpen, onClose, userUri }) => {
  const classes = useStyles();

  const avatarUrl = useAvatar({ userUri, size: 'medium' });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      TransitionProps={avatarUploadDialogRole}
    >
      <DialogTitle>
        <FormattedMessage id="avatarUploadDialog.title" />
      </DialogTitle>
      <DialogContent>
        <AvatarUploadDialogContent
          userUri={userUri}
          onUploadComplete={onClose}
        />
      </DialogContent>
      <DialogActions>
        {avatarUrl && (
          <AvatarUploadDialogRemoveButton
            className={classes.removeButton}
            userUri={userUri}
            onRemove={onClose}
          />
        )}
        <Button className={classes.cancelButton} onClick={onClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AvatarUploadDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userUri: PropTypes.string.isRequired
};

export default AvatarUploadDialog;
