import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  FormControl,
  MenuItem,
  OutlinedInput
} from '@material-ui/core';
import { ResourceUserAvailabilityGroupType } from '~/types';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0)
  }
}));

const useSelectStyles = makeStyles(theme => ({
  root: {
    margin: 0
  },
  select: {
    fontSize: theme.typography.body2.fontSize,
    minHeight: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1, 3, 1, 1.5),
    color: theme.palette.text.secondary
  }
}));

const outlinedInput = <OutlinedInput labelWidth={0} />;
const menuProps = {
  MenuListProps: {
    dense: true,
    'aria-label': <FormattedMessage id="dimensionDropdown.selectDimension" />
  }
};

const defaultOptions = {
  role: {
    value: ResourceUserAvailabilityGroupType.Role,
    label: 'dimensionDropdown.byRole'
  },
  location: {
    value: ResourceUserAvailabilityGroupType.Location,
    label: 'dimensionDropdown.byLocation'
  },
  division: {
    value: ResourceUserAvailabilityGroupType.Division,
    label: 'dimensionDropdown.byDivision'
  },
  serviceCenter: {
    value: ResourceUserAvailabilityGroupType.ServiceCenter,
    label: 'dimensionDropdown.byServiceCenter'
  },
  costCenter: {
    value: ResourceUserAvailabilityGroupType.CostCenter,
    label: 'dimensionDropdown.byCostCenter'
  },
  department: {
    value: ResourceUserAvailabilityGroupType.Department,
    label: 'dimensionDropdown.byDepartment'
  },
  employeeType: {
    value: ResourceUserAvailabilityGroupType.EmployeeType,
    label: 'dimensionDropdown.byEmployeeType'
  }
};

export const AvailabilityDimensionDropdown = ({
  value,
  onChange,
  isViewRoleEnabled,
  isViewCostCenterEnabled,
  isViewDivisionEnabled,
  isViewDepartmentEnabled,
  isViewEmployeeTypeEnabled,
  isViewLocationEnabled,
  isViewServiceCenterEnabled
}) => {
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const handleChange = useCallback(
    event => {
      event.target.value && onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        input={outlinedInput}
        onChange={handleChange}
        classes={selectClasses}
        MenuProps={menuProps}
      >
        {isViewCostCenterEnabled && (
          <MenuItem value={defaultOptions.costCenter.value}>
            <FormattedMessage id={defaultOptions.costCenter.label} />
          </MenuItem>
        )}
        {isViewDepartmentEnabled && (
          <MenuItem value={defaultOptions.department.value}>
            <FormattedMessage id={defaultOptions.department.label} />
          </MenuItem>
        )}
        {isViewDivisionEnabled && (
          <MenuItem value={defaultOptions.division.value}>
            <FormattedMessage id={defaultOptions.division.label} />
          </MenuItem>
        )}
        {isViewEmployeeTypeEnabled && (
          <MenuItem value={defaultOptions.employeeType.value}>
            <FormattedMessage id={defaultOptions.employeeType.label} />
          </MenuItem>
        )}
        {isViewLocationEnabled && (
          <MenuItem value={defaultOptions.location.value}>
            <FormattedMessage id={defaultOptions.location.label} />
          </MenuItem>
        )}
        {isViewRoleEnabled && (
          <MenuItem value={defaultOptions.role.value}>
            <FormattedMessage id={defaultOptions.role.label} />
          </MenuItem>
        )}
        {isViewServiceCenterEnabled && (
          <MenuItem value={defaultOptions.serviceCenter.value}>
            <FormattedMessage id={defaultOptions.serviceCenter.label} />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

AvailabilityDimensionDropdown.propTypes = {
  value: PropTypes.oneOf([
    ResourceUserAvailabilityGroupType.Role,
    ResourceUserAvailabilityGroupType.ServiceCenter,
    ResourceUserAvailabilityGroupType.CostCenter,
    ResourceUserAvailabilityGroupType.Division,
    ResourceUserAvailabilityGroupType.CostCenter,
    ResourceUserAvailabilityGroupType.Location,
    ResourceUserAvailabilityGroupType.Department,
    ResourceUserAvailabilityGroupType.EmployeeType
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  isViewRoleEnabled: PropTypes.bool.isRequired,
  isViewCostCenterEnabled: PropTypes.bool.isRequired,
  isViewDivisionEnabled: PropTypes.bool.isRequired,
  isViewDepartmentEnabled: PropTypes.bool.isRequired,
  isViewEmployeeTypeEnabled: PropTypes.bool.isRequired,
  isViewLocationEnabled: PropTypes.bool.isRequired,
  isViewServiceCenterEnabled: PropTypes.bool.isRequired
};

export default AvailabilityDimensionDropdown;
