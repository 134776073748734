import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  SpecificResourceRequestFragmentDoc,
  ResourceRequestStatus
} from '~/types';
import { tryLoadCachedResourceRequestQuery } from '~/modules/resourcing/common/util/resourceRequestUtil';
import {
  resourceRequestTotalsFragment,
  specificResourceRequestWithAllocationsFragment
} from '~/modules/resourcing/common/fragments';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import useMeContext from '~/modules/me/useMeContext';
import { invokeUpdateResourceAllocationForRequestsAggregateCache } from '~/modules/resourcing/enhancers/updateAllocationTotalsCache';
import resourceRequestQuery from '~/modules/resourcing/common/resourceRequestQuery';
import { useGetResourceRequestFilter } from '../common/hooks/useGetResourceRequestFilter';
import { getCostAndHoursDelta } from '../util/getCostAndHoursDelta';
import { getTotalHoursAndCostFromAllocations } from '../util/getTotalHoursAndCostFromAllocations';
import { invokeUpdateResourceRequestAggregateCache } from '../enhancers/updateResourceRequestTotalsCache';

const DELETE_RESOURCE_ALLOCATIONS_FOR_RESOURCE_REQUEST = gql`
  mutation DeleteResourceAllocationsForResourceRequest(
    $input: DeleteResourceAllocationsForResourceRequestInput!
  ) {
    deleteResourceAllocationsForResourceRequest(input: $input) {
      resourceRequest {
        ...SpecificResourceRequest
        ...ResourceRequestTotalsFragment
      }
    }
  }
  ${SpecificResourceRequestFragmentDoc}
  ${resourceRequestTotalsFragment}
`;

export const readCachedResourceRequestsQueryData = ({
  proxy,
  optimistic,
  resourceRequestsQuery,
  resourceRequestsQueryVariables
}) => {
  try {
    return proxy.readQuery(
      {
        query: resourceRequestsQuery,
        variables: resourceRequestsQueryVariables
      },
      optimistic
    );
  } catch (e) {
    return null;
  }
};

export const updateCache = ({
  projectUri,
  resourceCostMode,
  initialTotalAllocationsCostAndHours,
  filter,
  isResourceActualModeEnabled,
  resourceRequestsQuery,
  resourceRequestsQueryVariables
}) => (proxy, mutationResponse) => {
  const {
    data: {
      isOptimistic,
      deleteResourceAllocationsForResourceRequest: { resourceRequest }
    }
  } = mutationResponse;

  const cachedResourceRequests = readCachedResourceRequestsQueryData({
    proxy,
    optimistic: isOptimistic,
    resourceRequestsQuery,
    resourceRequestsQueryVariables
  });

  const cachedResourceRequest = tryLoadCachedResourceRequestQuery({
    proxy,
    resourceRequestId: resourceRequest.id,
    optimistic: isOptimistic
  });

  if (cachedResourceRequests) {
    const { resourceRequests: data } = cachedResourceRequests;

    proxy.writeQuery({
      query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
      variables: {
        projectUri,
        cursor: null,
        limit: 500
      },
      data: {
        resourceRequests: {
          ...data,
          resourceRequests: data.resourceRequests.map(req =>
            req.id === resourceRequest.id
              ? {
                  ...req,
                  resourceAllocations: []
                }
              : req
          )
        }
      }
    });
  }

  if (cachedResourceRequest) {
    const { resourceRequest: data } = cachedResourceRequest;

    proxy.writeQuery({
      query: resourceRequestQuery,
      variables: {
        id: resourceRequest.id
      },
      data: {
        resourceRequest: {
          ...data,
          resourceAllocations: []
        }
      }
    });
  }
  // only for resource manager flow
  if (!cachedResourceRequest && !cachedResourceRequests) {
    updateDeleteCacheForOnlyResourceManagerFlow({
      proxy,
      resourceRequestId: resourceRequest.id
    });
  }

  if (initialTotalAllocationsCostAndHours) {
    const costAndHoursDelta = getCostAndHoursDelta({
      newTotalCost: null,
      newHours: 0,
      initialTotalCost: initialTotalAllocationsCostAndHours.totalCost,
      initialHours: initialTotalAllocationsCostAndHours.totalHours
    });

    invokeUpdateResourceAllocationForRequestsAggregateCache({
      proxy,
      projectId: projectUri,
      costAndHoursDelta,
      resourceCostMode,
      filter: filter || {},
      isResourceActualModeEnabled
    });

    const requestedCostAndHoursDelta = getCostAndHoursDelta({
      newTotalCost:
        resourceRequest.totalRequestedCostByCurrency?.projectBudgetCurrency ||
        resourceRequest.totalRequestedCostByCurrency?.baseCurrency,
      newHours: resourceRequest.totalHours
    });

    invokeUpdateResourceRequestAggregateCache({
      proxy,
      projectId: projectUri,
      costAndHoursDelta: requestedCostAndHoursDelta,
      filter: filter || {},
      isResourceActualModeEnabled
    });
  }
};

const tryLoadCachedFragment = (proxy, fragmentId) => {
  try {
    return proxy.readFragment({
      id: fragmentId,
      fragmentName: 'SpecificResourceRequestWithAllocation',
      fragment: specificResourceRequestWithAllocationsFragment
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);

    return null;
  }
};

export const updateDeleteCacheForOnlyResourceManagerFlow = ({
  proxy,
  resourceRequestId
}) => {
  const cachedFragmentId = `ResourceRequest:${resourceRequestId}`;

  const cachedRequest = tryLoadCachedFragment(proxy, cachedFragmentId);

  if (cachedRequest) {
    proxy.writeFragment({
      id: cachedFragmentId,
      fragment: specificResourceRequestWithAllocationsFragment,
      fragmentName: 'SpecificResourceRequestWithAllocation',
      data: {
        ...cachedRequest,
        resourceAllocations: []
      }
    });
  }
};

const useDeleteResourceAllocationsForResourceRequestHandler = ({
  isResourceActualModeEnabled,
  resourceRequestsQuery,
  resourceRequestsQueryVariables
}) => {
  const [deleteResourceAllocationsForRequest] = useMutation(
    DELETE_RESOURCE_ALLOCATIONS_FOR_RESOURCE_REQUEST
  );

  const filter = useGetResourceRequestFilter();

  const { resourceCostMode } = useMeContext();

  const deleteResourceAllocationsForResourceRequest = useCallback(
    async ({ projectUri, resourceRequest }) => {
      const initialTotalAllocationsCostAndHours = getTotalHoursAndCostFromAllocations(
        {
          allocations: resourceRequest?.resourceAllocations || [],
          resourceCostMode
        }
      );

      await deleteResourceAllocationsForRequest({
        variables: {
          input: {
            resourceRequestId: resourceRequest.id
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          isOptimistic: true,
          deleteResourceAllocationsForResourceRequest: {
            __typename: 'DeleteResourceAllocationsForResourceRequestResult',
            resourceRequest: {
              ...resourceRequest,
              requestStatus: ResourceRequestStatus.Submitted
            }
          }
        },
        update: updateCache({
          projectUri,
          resourceCostMode,
          initialTotalAllocationsCostAndHours,
          filter,
          isResourceActualModeEnabled,
          resourceRequestsQuery,
          resourceRequestsQueryVariables
        })
      });
    },
    [
      resourceCostMode,
      deleteResourceAllocationsForRequest,
      filter,
      isResourceActualModeEnabled,
      resourceRequestsQuery,
      resourceRequestsQueryVariables
    ]
  );

  return { deleteResourceAllocationsForResourceRequest };
};

export default useDeleteResourceAllocationsForResourceRequestHandler;
