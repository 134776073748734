/* eslint-disable react/jsx-max-depth */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { NoDataItem } from '~/modules/common/components';
import {
  getScaleWidth,
  itemWidth,
  totalWidth
} from '~/modules/common/charts/timeline/calculations';

import { PERIOD_SCALE_ENUM } from '~/modules/resourcing/common/enums';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  spaceContainer: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  itemContainer: {
    flexShrink: 1,
    padding: theme.spacing(1, 1, 1, 2),
    backgroundColor: theme.palette.background.paper,
    lineHeight: `${theme.spacing(3)}px`,
    position: 'sticky',
    left: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));

export const NoDataChartRow = ({
  scale,
  scaleItemCount,
  className,
  resourceKey = 'resourcing.noResourceRequests'
}) => {
  const classes = useStyles();
  const rangeWidth = scaleItemCount * getScaleWidth(scale);

  const { containerStyle, innerContainerStyle } = useMemo(
    () => ({
      containerStyle: {
        minWidth: itemWidth + rangeWidth + totalWidth
      },
      innerContainerStyle: { width: itemWidth + rangeWidth }
    }),
    [rangeWidth]
  );

  return (
    <div className={classNames(classes.root, className)} style={containerStyle}>
      <div className={classes.spaceContainer} style={innerContainerStyle}>
        <div className={classes.itemContainer}>
          <NoDataItem>
            <FormattedMessage id={resourceKey} />
          </NoDataItem>
        </div>
      </div>
    </div>
  );
};

NoDataChartRow.propTypes = {
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.WEEKS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.OVERVIEW
  ]),
  className: PropTypes.string,
  scaleItemCount: PropTypes.number.isRequired,
  resourceKey: PropTypes.string
};

export default NoDataChartRow;
