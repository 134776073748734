import { useMemo } from 'react';
import { RequestAttributeWeightage } from '~/types';

const useResourceRequestDrawerDetailsFieldValues = ({ fields }) => {
  const {
    role,
    roleRate,
    currency,
    skills,
    tags,
    resourcePools,
    requestAttributeWeights,
    preferredResources
  } = fields;

  const requiredFields = useMemo(
    () => ({
      location:
        requestAttributeWeights.value?.location ===
        RequestAttributeWeightage.Required,
      division:
        requestAttributeWeights.value?.division ===
        RequestAttributeWeightage.Required,
      serviceCenter:
        requestAttributeWeights.value?.serviceCenter ===
        RequestAttributeWeightage.Required,
      costCenter:
        requestAttributeWeights.value?.costCenter ===
        RequestAttributeWeightage.Required,
      department:
        requestAttributeWeights.value?.department ===
        RequestAttributeWeightage.Required,
      employeeType:
        requestAttributeWeights.value?.employeeType ===
        RequestAttributeWeightage.Required,
      role:
        requestAttributeWeights.value?.role ===
        RequestAttributeWeightage.Required,
      skills:
        requestAttributeWeights.value?.skills ===
        RequestAttributeWeightage.Required,
      tags:
        requestAttributeWeights.value?.tags ===
        RequestAttributeWeightage.Required,
      requestedCostRate:
        requestAttributeWeights.value?.requestedCostRate ===
        RequestAttributeWeightage.Required,
      resourcePools:
        requestAttributeWeights.value?.resourcePools ===
        RequestAttributeWeightage.Required,
      preferredResources:
        requestAttributeWeights.value?.preferredResources ===
        RequestAttributeWeightage.Required
    }),
    [requestAttributeWeights]
  );

  const importanceValues = useMemo(
    () => ({
      location: fields.location.value,
      division: fields.division.value,
      serviceCenter: fields.serviceCenter.value,
      costCenter: fields.costCenter.value,
      department: fields.department.value,
      employeeType: fields.employeeType.value,
      role: role.value,
      skills: skills.value,
      tags: tags.value,
      requestedCostRate: {
        amount: roleRate.value,
        currency: currency.value
      },
      resourcePools: resourcePools?.value,
      preferredResources: preferredResources?.value
    }),
    [
      fields.location.value,
      fields.division.value,
      fields.serviceCenter.value,
      fields.costCenter.value,
      fields.department.value,
      fields.employeeType.value,
      role.value,
      skills.value,
      tags.value,
      roleRate.value,
      currency.value,
      resourcePools,
      preferredResources
    ]
  );

  return {
    importanceValues,
    requiredFields
  };
};

export default useResourceRequestDrawerDetailsFieldValues;
