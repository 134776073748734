import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInvoiceTemplates } from './useInvoiceTemplates';

const noOptionsText = (
  <FormattedMessage id="invoiceTemplate.noInvoiceTemplate" />
);
const getOptionLabel = option => option.displayText;
const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const renderOption = option => (
  <Typography variant="body2">{option.displayText}</Typography>
);

export const InvoiceTemplateDropdown = ({
  onChange,
  value,
  label,
  hasError,
  helperText,
  disableClearable = false,
  dataQeId
}) => {
  const { formatMessage } = useIntl();
  const { loading, invoiceTemplates: options } = useInvoiceTemplates();

  const updateOptions = options;

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        label={label || formatMessage({ id: 'invoiceTemplate.label' })}
      />
    ),
    [formatMessage, label]
  );

  return (
    <>
      <Autocomplete
        loading={loading}
        forcePopupIcon
        fullWidth
        disableClearable={disableClearable}
        value={value}
        onChange={onChange}
        options={updateOptions}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        getOptionSelected={getOptionSelected}
        noOptionsText={noOptionsText}
        renderOption={renderOption}
        data-qe-id={dataQeId}
      />
      {helperText && (
        <FormHelperText error={hasError}>{helperText}</FormHelperText>
      )}
    </>
  );
};

InvoiceTemplateDropdown.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  disableClearable: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default InvoiceTemplateDropdown;
