import {
  Menu,
  MenuItem,
  Button,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import AddCircleIcon from '@material-ui/icons/AddCircleSharp';
import { useMenuState } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.palette.primary.main,
    float: 'left',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5)
  }
}));

const menuProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
  listProp: {
    dense: true
  }
};

const addIcon = <AddCircleIcon />;

export const AddRateTypeButton = ({ onAddRateType, rates }) => {
  const classes = useStyles();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const isOpen = Boolean(anchorEl);

  const onMenuItemClick = useCallback(
    rateType => async () => {
      onAddRateType(rateType);
      onMenuClose(null);
    },
    [onAddRateType, onMenuClose]
  );

  const availableRatesToAdd = useMemo(
    () => Object.keys(rates).filter(key => !rates[key].length),
    [rates]
  );

  return (
    <>
      <Button
        startIcon={addIcon}
        className={classes.addButton}
        onClick={onMenuClick}
        disabled={!availableRatesToAdd.length}
        size="small"
        data-qe-id="addRateType"
      >
        <FormattedMessage id="rateCard.rateType.title" />
      </Button>
      <Menu
        id="add-rate-type"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={menuProps.anchorOrigin}
        transformOrigin={menuProps.transformOrigin}
        open={isOpen}
        onClose={onMenuClose}
        MenuListProps={menuProps.listProp}
      >
        {availableRatesToAdd.map(rateType => (
          <MenuItem key={rateType} onClick={onMenuItemClick(rateType)}>
            <ListItemText
              primary={<FormattedMessage id={`rateCard.title.${rateType}`} />}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
AddRateTypeButton.propTypes = {
  rates: PropTypes.object,
  onAddRateType: PropTypes.func
};

export default AddRateTypeButton;
