import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { billingScheduleEntries } from './query';

export const GET_PROJECT_BILLINGRATE_SCHEDULES = gql`
  query getPageOfProjectSpecificBillingRateSchedules(
    $projectId: String!
    $page: Int!
    $pageSize: Int!
  ) {
    project(projectId: $projectId) {
      id
      pageOfProjectSpecificBillingRateSchedules(page: $page, pageSize: $pageSize) {
        id
        billingRate {
          id
          displayText
          ${billingScheduleEntries}
        }
      }
    }
  }
`;

const useProjectSpecificBillingRateSchedules = ({
  projectId,
  page = 1,
  pageSize = 10
}) => {
  const { loading, error, data } = useQuery(GET_PROJECT_BILLINGRATE_SCHEDULES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
    variables: {
      projectId,
      page,
      pageSize
    }
  });

  const pageOfProjectSpecificBillingRateSchedules = get(
    data,
    'project.pageOfProjectSpecificBillingRateSchedules',
    []
  );

  return {
    loading,
    error,
    projectSpecificBillingRateSchedules: pageOfProjectSpecificBillingRateSchedules
  };
};

export default useProjectSpecificBillingRateSchedules;
