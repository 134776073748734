import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import isempty from 'lodash.isempty';
import { connect } from 'formik';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { CustomField } from '~/modules/customFields/components';
import { ExtensionField } from '~/modules/extensionFields/components';
import {
  getNameAndOnChangeHandlerProps,
  getErrorAndHelperTextProps
} from '~/modules/customFields/utils';
import { GridItem } from '~/modules/common/components';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { useExtensionFieldChangeHandler } from './useExtensionFieldChangeHandler';

const isEven = num => num % 2 === 0;

export const EditAdditionalInfoForm = ({
  formik: { errors, values, handleChange, setFieldValue },
  canViewCustomFields,
  canEditCustomFields
}) => {
  const { customFieldValues, extensionFieldValues } = values;

  const handleExtensionFieldValueChange = useExtensionFieldChangeHandler();

  const [definitionId, setDefinitionId] = useState('');

  const visibleCustomFieldValues = useMemo(() => {
    if (!canViewCustomFields || isempty(customFieldValues)) {
      return [];
    }

    return Object.entries(customFieldValues)
      .filter(
        ([_, customFieldValue]) =>
          customFieldValue.customFieldDefinition.isEnabled &&
          customFieldValue.customFieldDefinition.isVisible
      )
      .map(([key, customFieldValue], ind) => {
        const { name, onChange } = getNameAndOnChangeHandlerProps({
          handleChange,
          setFieldValue,
          path: 'customFieldValues',
          customFieldValue,
          key
        });

        const { error, helperText } = getErrorAndHelperTextProps({
          errors,
          customFieldValue,
          path: 'customFieldValues'
        });

        return {
          key,
          customFieldValue,
          name,
          onChange,
          error,
          helperText,
          rightPadding: isEven(ind)
        };
      });
  }, [
    canViewCustomFields,
    customFieldValues,
    errors,
    handleChange,
    setFieldValue
  ]);

  const extensionFieldStartInd = visibleCustomFieldValues.length;

  const visibleExtensionFieldValues = useMemo(() => {
    if (isempty(extensionFieldValues)) return [];

    return Object.entries(extensionFieldValues).map(
      ([key, extensionFieldValue], ind) => ({
        key,
        extensionFieldValue,
        onChange: handleExtensionFieldValueChange({
          id: key,
          definitionTypeUri: extensionFieldValue.definitionTypeUri,
          setFieldValue
        }),
        rightPadding: isEven(ind + extensionFieldStartInd)
      })
    );
  }, [extensionFieldValues, setFieldValue]);

  return (
    <>
      {!isEmpty(errors) && <FormErrorAlert />}
      <Grid container>
        {visibleCustomFieldValues.map(
          ({
            customFieldValue,
            key,
            name,
            onChange,
            error,
            helperText,
            rightPadding
          }) => (
            <GridItem key={key} rightPadding={rightPadding}>
              <CustomField
                variant="filled"
                fullWidth
                editable={canEditCustomFields}
                customFieldValue={customFieldValue}
                name={name}
                onChange={onChange}
                error={error}
                helperText={helperText}
              />
            </GridItem>
          )
        )}
        {visibleExtensionFieldValues.map(
          ({ key, extensionFieldValue, onChange, rightPadding }) => (
            <GridItem key={key} rightPadding={rightPadding}>
              <ExtensionField
                variant="filled"
                editable
                extensionField={extensionFieldValue}
                onChange={onChange}
                definitionId={definitionId}
                setDefinitionId={setDefinitionId}
              />
            </GridItem>
          )
        )}
      </Grid>
    </>
  );
};

EditAdditionalInfoForm.propTypes = {
  formik: PropTypes.object,
  canViewCustomFields: PropTypes.bool,
  canEditCustomFields: PropTypes.bool
};

export default connect(EditAdditionalInfoForm);
