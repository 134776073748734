import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import {
  useDialogState,
  useMenuState,
  useIsBreakpointOnly
} from '~/modules/common/hooks';
import { MoneyValue } from '~/modules/common/components';
import Divider from './components/Divider';
import ChildInfoDialog from './components/ChildInfoDialog/ChildInfoDialog';
import HoursCell from './components/HoursCell';

const useStyles = makeStyles({
  row: {
    display: 'contents'
  },
  cell: {
    display: 'flex',
    padding: theme.spacing(0, 2),
    whiteSpace: 'nowrap'
  },
  labelCell: ({ isXsBreakPoint, isMobile }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontSize: isXsBreakPoint
      ? theme.spacing(1.5)
      : theme.typography.body2.fontSize,
    padding: isMobile ? theme.spacing(1, 0, 0, 2) : theme.spacing(1, 1, 1, 2),
    marginTop: theme.spacing(0)
  }),
  costCell: {
    fontWeight: theme.typography.body2.fontWeight,
    justifySelf: 'end',
    paddingRight: 0,
    paddingLeft: theme.spacing(5)
  },
  datesCell: ({ showCost }) => ({
    fontWeight: theme.typography.body2.fontWeight,
    paddingLeft: showCost ? theme.spacing(5) : theme.spacing(12.5)
  }),
  date: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: 'inherit'
  },
  fact: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: 'inherit'
  },
  na: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  },
  icon: {
    cursor: 'pointer',
    marginBottom: theme.spacing(-0.5) - 1,
    marginLeft: theme.spacing(1),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.75)
  }
});

const TaskSummaryRow = ({
  childTasks = [],
  classes: classesOverride,
  cost = null,
  costTooltipComponent,
  hoursTooltipComponent,
  dataQeId,
  date = null,
  hasDivider = false,
  hasMore,
  hours = null,
  hideCostTooltip = false,
  hideHoursTooltip = false,
  label,
  loadMore,
  loadingMore,
  noDateLabel = null,
  selectedColumns,
  selectedCostColumns,
  showCost,
  showHours = true
}) => {
  const {
    open: costDialogOpen,
    openDialog: openCostDialog,
    closeDialog: closeCostDialog
  } = useDialogState(false);

  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();
  const isXsBreakPoint = useIsBreakpointOnly('xs');
  const isMobile = useMediaQuery('(max-width:690px)', { noSsr: true });
  const { hoursCell, ...summaryClassesOverride } = classesOverride;

  const classes = useStyles({
    classes: summaryClassesOverride,
    isXsBreakPoint,
    isMobile,
    showCost
  });
  const hoursClasses = useMemo(
    () => ({
      hoursCell,
      icon: classesOverride.icon
    }),
    [hoursCell, classesOverride.icon]
  );

  const handleCostInfoClick = useCallback(
    event => {
      onMenuClick(event);
      openCostDialog();
    },
    [onMenuClick, openCostDialog]
  );

  const handleCloseCostPopover = useCallback(() => {
    onMenuClose();
    closeCostDialog();
  }, [onMenuClose, closeCostDialog]);

  return (
    <div className={classes.row} role="row">
      <div className={classes.labelCell} role="rowheader">
        <FormattedMessage id={label} />:{hasDivider && <Divider />}
      </div>
      <HoursCell
        classes={hoursClasses}
        childTasks={childTasks}
        dataQeId={dataQeId}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        hours={hours}
        selectedColumns={selectedColumns}
        showValue={showHours}
        tooltipComponent={hoursTooltipComponent}
        hideTooltip={hideHoursTooltip}
      />
      {showCost && (
        <div className={classNames(classes.cell, classes.costCell)} role="cell">
          {cost === null ? (
            <Typography className={classes.na} data-qe-id={`${dataQeId}_Cost`}>
              <FormattedMessage id="taskDrawer.na" />
            </Typography>
          ) : (
            <MoneyValue
              dataQeId={`${dataQeId}_Cost`}
              className={classes.fact}
              money={cost}
            />
          )}
          {!hideCostTooltip &&
            !costTooltipComponent &&
            childTasks.length !== 0 && (
              <>
                <InfoSharpIcon
                  className={classes.icon}
                  onClick={handleCostInfoClick}
                />
                <ChildInfoDialog
                  childTasks={childTasks}
                  selectedColumns={selectedCostColumns}
                  open={costDialogOpen}
                  onClose={handleCloseCostPopover}
                  anchorEl={anchorEl}
                  hasMore={hasMore}
                  loadMore={loadMore}
                  loadingMore={loadingMore}
                />
              </>
            )}
          {!hideCostTooltip && costTooltipComponent}
        </div>
      )}
      <div className={classNames(classes.cell, classes.datesCell)} role="cell">
        {date ? (
          <span data-qe-id={`${dataQeId}_Date`} className={classes.date}>
            {date}
          </span>
        ) : (
          noDateLabel && (
            <Typography className={classes.na}>
              <FormattedMessage id={noDateLabel} />
            </Typography>
          )
        )}
      </div>
    </div>
  );
};

TaskSummaryRow.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  selectedColumns: PropTypes.array,
  selectedCostColumns: PropTypes.array,
  hideCostTooltip: PropTypes.bool,
  hideHoursTooltip: PropTypes.bool,
  hours: PropTypes.number,
  date: PropTypes.string,
  noDateLabel: PropTypes.string,
  hasDivider: PropTypes.bool,
  childTasks: PropTypes.array,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  cost: PropTypes.object,
  showCost: PropTypes.bool,
  showHours: PropTypes.bool,
  costTooltipComponent: PropTypes.node,
  hoursTooltipComponent: PropTypes.node
};

export default TaskSummaryRow;
