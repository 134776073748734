import { useMemo } from 'react';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { useMeContext } from '~/modules/me/useMeContext';
import { getPeriodsByScale } from '~/modules/resourcing/common/hooks/usePeriodsByScale';

const useChartDisplayPeriods = ({ chartDisplayDateRange, scale }) => {
  const me = useMeContext();

  const {
    startDate: chartStartDate,
    endDate: chartEndDate
  } = chartDisplayDateRange;

  return useMemo(() => {
    const { displayPeriods } = getPeriodsByScale({
      me,
      rangeStart: chartStartDate,
      rangeEnd: chartEndDate,
      scale
    });

    return displayPeriods.map(range => {
      const { start, end, key } = range;

      return {
        key,
        startDate: start,
        endDate: end,
        dynamicPosition: getDynamicPositionAttributes({
          chartStartDate,
          start,
          end,
          scale,
          isPsaRmpUserSchedulePerfFixEnabled: true
        }).dynamicPosition
      };
    });
  }, [chartEndDate, chartStartDate, me, scale]);
};

export default useChartDisplayPeriods;
