import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useLocations from '~/modules/common/enhancers/useLocations';
import { POLICYTYPE } from '~/modules/common/enums';
import { onChange } from '../enhancers/facetHelpers';

import GroupFacetDetails from '../GroupFacetDetails';

export const LocationFacetDetails = ({
  className,
  selected,
  setSelected,
  policy = POLICYTYPE.PROJECT_MANAGEMENT
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const noneOptionText = formatMessage({ id: 'locationsDropdown.noLocation' });

  const { locations, loading, hasMore } = useLocations({
    searchTerm,
    policy,
    includeNoneOption: true,
    noneOptionText
  });

  return (
    <GroupFacetDetails
      label={formatMessage({ id: 'search.location' })}
      className={className}
      selected={selected}
      onChange={onChange}
      setSelected={setSelected}
      isLoading={loading}
      setSearchTerm={setSearchTerm}
      options={locations}
      noOptionsLabel={noneOptionText}
      hasMore={hasMore}
      optionTypeText={formatMessage({
        id: 'moreOptions.location'
      })}
    />
  );
};

LocationFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  policy: PropTypes.oneOf([
    POLICYTYPE.PROJECT_MANAGEMENT,
    POLICYTYPE.PORTFOLIO_MANAGEMENT,
    POLICYTYPE.RESOURCE_MANAGEMENT,
    POLICYTYPE.REVENUE_MANAGEMENT
  ])
};

export default LocationFacetDetails;
