import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { NoValue, Decimal } from '~/modules/common/components';
import {
  CostVarianceNumber,
  HoursVarianceNumber
} from '~/modules/resourcing/common/components/VarianceStyledFormattedNumber';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'contents'
  },
  label: {
    textAlign: 'left'
  },
  value: {
    textAlign: 'right',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 0, 2)
  },
  totalItem: {
    '& $label, & $value': {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5)
    }
  }
}));

const useVarianceStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(-0.5),
    paddingRight: theme.spacing(0.5)
  }
}));

export const ResourceChartTotalPopperRow = ({
  rowName,
  hours,
  cost,
  currencySymbol,
  isHoursHigher,
  isCostHigher,
  isTotalRow,
  viewDiff,
  viewCosts
}) => {
  const classes = useStyles();
  const varianceClasses = useVarianceStyles();

  const hourValue = useMemo(
    () => ({
      value: <Decimal value={hours} />
    }),
    [hours]
  );

  const hoursRow = viewDiff ? (
    <HoursVarianceNumber
      classes={varianceClasses}
      value={hours}
      showAddIcon={isHoursHigher}
      formattedMessageKey="resourceActualSummaryDialog.hours"
      fixedDecimalScale
      isRoundedValue={false}
    />
  ) : (
    <FormattedMessage
      id="resourceActualSummaryDialog.hours"
      values={hourValue}
    />
  );

  const costRow = viewDiff ? (
    <CostVarianceNumber
      classes={varianceClasses}
      value={cost}
      prefix={currencySymbol}
      showAddIcon={isCostHigher}
      fixedDecimalScale
      isRoundedValue={false}
    />
  ) : (
    <Decimal value={cost} prefix={currencySymbol} />
  );

  return (
    <div
      className={classNames(classes.item, { [classes.totalItem]: isTotalRow })}
    >
      <span className={classes.label}>
        <FormattedMessage id={rowName} />
      </span>
      <span className={classes.value}>{hours ? hoursRow : <NoValue />}</span>
      {viewCosts && (
        <span className={viewCosts && classes.value}>
          {cost && cost !== 0 ? costRow : <NoValue />}
        </span>
      )}
    </div>
  );
};

ResourceChartTotalPopperRow.propTypes = {
  rowName: PropTypes.string,
  hours: PropTypes.number,
  cost: PropTypes.number,
  currencySymbol: PropTypes.string,
  isHoursHigher: PropTypes.bool,
  isCostHigher: PropTypes.bool,
  isTotalRow: PropTypes.bool,
  viewDiff: PropTypes.bool,
  viewCosts: PropTypes.bool
};

export default ResourceChartTotalPopperRow;
