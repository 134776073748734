import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import { useIntl } from 'react-intl';
import { array, object, number, bool, string } from 'yup';
import RoleScheduleEditableFormActionButtons from './RolesEditableScheduleForm/RoleScheduleEditableFormActionButtons';
import RoleScheduleReadOnlyFormActionButtons from './RolesReadOnlyScheduleForm/RoleScheduleReadOnlyFormActionButtons';
import RoleScheduleFormContent from './RoleScheduleFormContent';

export const roleSchedulesValidationSchema = () =>
  object().shape({
    schedule: array().of(
      object().shape({
        effectiveDate: object()
          .shape({
            day: number(),
            month: number(),
            year: number()
          })
          .nullable(),
        projectRoles: array().of(
          object().shape({
            isPrimary: bool().isRequired,
            role: object()
              .shape({
                id: string().isRequired,
                displayText: string().isRequired
              })
              .nullable()
          })
        )
      })
    )
  });

export const RolesScheduleForm = ({
  onSubmit,
  fieldArrayName,
  fieldValues,
  defaultAddValue,
  dropdownFieldKey,
  onCancel,
  permittedActions,
  editable
}) => {
  const intl = useIntl();
  const formikProps = useMemo(
    () => ({
      defaultAddValue,
      dropdownFieldKey,
      onCancel,
      fieldArrayName,
      fieldValues,
      permittedActions,
      editable
    }),
    [
      defaultAddValue,
      dropdownFieldKey,
      onCancel,
      fieldArrayName,
      fieldValues,
      permittedActions,
      editable
    ]
  );

  const initialValues = useMemo(() => ({ [fieldArrayName]: fieldValues }), [
    fieldArrayName,
    fieldValues
  ]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={roleSchedulesValidationSchema(intl)}
        onSubmit={onSubmit}
        render={renderRoleSchedulesFormWithFieldArray(formikProps)}
      />
    </>
  );
};

export const renderRoleSchedulesFormWithFieldArray = ({
  fieldArrayName,
  ...rest
}) => formikProps => (
  <FieldArray
    name={fieldArrayName}
    render={renderRoleSchedulesFormContent({
      ...formikProps,
      ...rest,
      fieldArrayName
    })}
  />
);

export const renderRoleSchedulesFormContent = ({
  values,
  errors,
  fieldArrayName,
  onCancel,
  defaultAddValue,
  classes,
  permittedActions,
  editable
}) => ({ form, remove, push }) => {
  const RoleScheduleFormActionButtons = editable
    ? RoleScheduleEditableFormActionButtons
    : RoleScheduleReadOnlyFormActionButtons;

  return (
    <>
      <RoleScheduleFormContent
        schedules={values[fieldArrayName]}
        fieldArrayName={fieldArrayName}
        form={form}
        remove={remove}
        defaultAddValue={defaultAddValue}
        push={push}
        permittedActions={permittedActions}
        classes={classes}
        errors={errors}
        editable={editable}
      />
      <RoleScheduleFormActionButtons
        onCancel={onCancel}
        onOk={onCancel}
        form={form}
      />
    </>
  );
};

RolesScheduleForm.propTypes = {
  fieldValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fieldArrayName: PropTypes.string.isRequired,
  defaultAddValue: PropTypes.object,
  dropdownFieldKey: PropTypes.string.isRequired,
  permittedActions: PropTypes.array,
  editable: PropTypes.bool.isRequired
};

export default RolesScheduleForm;
