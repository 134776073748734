import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';
import { extractFirstValidationError } from '~/modules/common/graphql/errors';
import useAddSkillMutation from './useAddSkillMutation';

const mapValuesOnSubmit = ({ name, category, description }) => {
  const skillCategory = {
    id: category.isNew ? null : category.id,
    name: category.name
  };

  return {
    skillName: name,
    category: skillCategory,
    description
  };
};

const buildOptimisticResponse = ({ name, category, description }) => {
  const skillId = uuid();
  const categoryId = category.isNew ? uuid() : category.id;

  return {
    __typename: 'Mutation',
    addSkill: {
      __typename: 'AddSkillResponse',
      id: skillId,
      uri: skillId,
      displayText: name,
      description,
      category: {
        displayText: category.name,
        id: categoryId,
        uri: categoryId,
        __typename: 'Category'
      }
    }
  };
};

export const useAddSkillOnSubmit = ({ onClose, onSkillAdd }) => {
  const [addSkill] = useAddSkillMutation();
  const intl = useIntl();

  const onSubmit = useCallback(
    async (skill, { setSubmitting, setFieldError }) => {
      setSubmitting(true);

      try {
        const { data } = await addSkill({
          variables: {
            addSkillsInput: mapValuesOnSubmit(skill)
          },
          optimisticResponse: buildOptimisticResponse(skill)
        });

        const { addSkill: newSkill = {} } = data;

        onSkillAdd(newSkill);

        setSubmitting(false);
        onClose();
      } catch (err) {
        setSubmitting(false);
        const validationErrors = extractFirstValidationError(err);

        if (validationErrors) {
          const duplicationNameError = validationErrors.find(
            v =>
              v.failureUri ===
              'urn:replicon:validation-failure:skill-name-duplicated-within-skill-category'
          );

          const duplicationCategoryError = validationErrors.find(
            v =>
              v.failureUri ===
              'urn:replicon:validation-failure:skill-category-name-duplicated'
          );

          setFieldError(
            'name',
            duplicationNameError
              ? intl.formatMessage({
                  id: 'skillsDropdown.duplicateSkill'
                })
              : null
          );

          setFieldError(
            'category',
            duplicationCategoryError
              ? intl.formatMessage({
                  id: 'skillsDropdown.duplicateSkillCategory'
                })
              : null
          );
        }
      }
    },
    [onClose, addSkill, intl, onSkillAdd]
  );

  return { onSubmit };
};

export default useAddSkillOnSubmit;
