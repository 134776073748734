import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useTheme from '@material-ui/core/styles/useTheme';
import { useHasPermission } from '~/modules/common/permissions';
import { ProjectRequestStatusType } from '~/types';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';

export const useProjectRequestDrawerActions = ({
  editable,
  deleteItem,
  openRequestHistoryDialog,
  onCreateProject,
  projectRequestStatus: { type },
  onRequestStatusChange,
  approveProjectRequestHandler
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const { canEditProjectRequest } = useProjectRequestObjectPermissions();

  const canEditProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });

  const reviewRequestAction = {
    id: 'review-request',
    overflow: 'never',
    variant: 'contained',
    label: intl.formatMessage({
      id: 'projectRequestStatus.drawerButtons.reviewRequest'
    }),
    onClick: useCallback(() => {
      onRequestStatusChange(ProjectRequestStatusType.InReview);
    }, [onRequestStatusChange]),
    secondary: type !== ProjectRequestStatusType.New,
    paletteclassvalue: theme.palette.projectRequestStatusButtons.pending,
    statusType: ProjectRequestStatusType.InReview
  };

  const approveRequestAction = {
    id: 'approve-request',
    overflow: 'xs',
    variant: 'contained',
    label: intl.formatMessage({
      id: 'projectRequestStatus.drawerButtons.approveRequest'
    }),
    onClick: approveProjectRequestHandler,
    secondary: type !== ProjectRequestStatusType.InReview,
    paletteclassvalue: theme.palette.projectRequestStatusButtons.approve,
    statusType: ProjectRequestStatusType.Approved
  };

  const createProjectAction = {
    id: 'create-project',
    overflow: 'xs',
    variant: 'contained',
    label: intl.formatMessage({
      id: 'projectRequest.createProject'
    }),
    onClick: onCreateProject,
    paletteclassvalue: theme.palette.projectRequestStatusButtons.primary
  };

  const rejectRequestAction = {
    id: 'reject-request',
    overflow: 'never',
    label: intl.formatMessage({
      id: 'projectRequestStatus.drawerButtons.rejectRequest'
    }),
    onClick: useCallback(() => {
      onRequestStatusChange(ProjectRequestStatusType.Rejected);
    }, [onRequestStatusChange]),
    secondary:
      type !== ProjectRequestStatusType.New &&
      type !== ProjectRequestStatusType.InReview,
    statusType: ProjectRequestStatusType.Rejected
  };

  const removeRequestAction = {
    id: 'remove-request',
    label: intl.formatMessage({
      id: 'projectRequest.removeRequest'
    }),
    onClick: deleteItem,
    secondary: true,
    className: 'removeRequest'
  };

  const requestHistoryAction = {
    id: 'request-history',
    overflow: 'never',
    label: intl.formatMessage({
      id: 'projectRequest.requestHistory'
    }),
    onClick: openRequestHistoryDialog,
    secondary: true
  };

  return [
    ...(canEditProjectRequest && !editable
      ? type === ProjectRequestStatusType.New
        ? [reviewRequestAction, approveRequestAction, rejectRequestAction]
        : type === ProjectRequestStatusType.InReview
        ? [approveRequestAction, rejectRequestAction]
        : type === ProjectRequestStatusType.Approved
        ? [reviewRequestAction, rejectRequestAction]
        : type === ProjectRequestStatusType.Rejected
        ? [reviewRequestAction, approveRequestAction]
        : []
      : []),
    requestHistoryAction,
    canEditProjectRequest && removeRequestAction,
    type === ProjectRequestStatusType.Approved &&
      !editable &&
      canEditProject &&
      createProjectAction
  ].filter(x => Boolean(x));
};

export default useProjectRequestDrawerActions;
