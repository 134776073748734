import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupSharp as GroupIcon } from '@material-ui/icons';

import { OverviewItemCard } from '~/modules/common/charts/dashboard';
import { ProjectManagementType } from '~/types';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';

import { NoData, CardTitle } from '../common';
import ResourceRequestsSummary from './ResourceRequestsSummary';
import {
  useResourceRequestsCountSummaryQuery,
  useDateRangesForChartData,
  useResourceRequestsSeriesData
} from './hooks';
import ResourcesCardLoading from './ResourcesCardLoading';
import ResourceFulfillmentChart from './ResourceFulfillmentChart';
import ResourcesCardHelp from './ResourcesCardHelp';
import ResourcesCardError from './ResourcesCardError';

const resourceFulfillmentTitleId = 'resourceFulfillment';

const resourcesTitle = <FormattedMessage id="projectOverview.resources" />;

export const resourcesIcon = <GroupIcon />;

const NUMBER_OF_WEEKS = 8;

const noRequestsMessage = (
  <FormattedMessage id="projectOverview.noResourceRequests" />
);

const resourceFulfillment = (
  <span id={resourceFulfillmentTitleId}>
    <FormattedMessage id="projectOverview.resourceFulfillment" />
  </span>
);

export const ResourcesCard = () => {
  const intl = useIntl();

  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();

  const isManaged =
    projectType === ProjectManagementType.Managed
      ? true
      : projectType === ProjectManagementType.Unmanaged
      ? false
      : null;

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });

  const {
    loading: isCountLoading,
    resourceRequestsCountSummary,
    errorMessage,
    errorCode
  } = useResourceRequestsCountSummaryQuery({
    projectFilter: { ...projectFilter, isManaged },
    intl
  });

  const dateRanges = useDateRangesForChartData({
    numberOfWeeks: NUMBER_OF_WEEKS
  });
  const {
    loading: isChartDataLoading,
    resourceRequestsSeriesData,
    error: errorSeries
  } = useResourceRequestsSeriesData({
    dateRanges,
    projectFilter: { ...projectFilter, isManaged }
  });

  const action = useMemo(() => <ResourcesCardHelp />, []);
  const isLoading = isCountLoading || isChartDataLoading;

  return (
    <OverviewItemCard
      title={resourcesTitle}
      avatar={resourcesIcon}
      action={action}
    >
      {isLoading ? (
        <ResourcesCardLoading />
      ) : (
        <>
          {' '}
          {errorCode || errorSeries ? (
            <ResourcesCardError
              errorMessage={errorMessage}
              errorCode={errorCode}
              errorSeries={errorSeries}
            />
          ) : (
            <ResourcesSummaryAndChart
              resourceRequestsCountSummary={resourceRequestsCountSummary}
              resourceRequestsSeriesData={resourceRequestsSeriesData}
              dateRanges={dateRanges}
            />
          )}
        </>
      )}
    </OverviewItemCard>
  );
};

export const ResourcesSummaryAndChart = ({
  dateRanges,
  resourceRequestsSeriesData,
  resourceRequestsCountSummary
}) => (
  <>
    {resourceRequestsCountSummary?.total > 0 ? (
      <ResourceRequestsSummary
        resourceRequestsCountSummary={resourceRequestsCountSummary}
      />
    ) : (
      <NoData message={noRequestsMessage} />
    )}
    <CardTitle title={resourceFulfillment} />
    <ResourceFulfillmentChart
      titleId={resourceFulfillmentTitleId}
      seriesData={resourceRequestsSeriesData}
      dateRanges={dateRanges}
    />
  </>
);

ResourcesSummaryAndChart.propTypes = {
  dateRanges: PropTypes.array,
  resourceRequestsSeriesData: PropTypes.array,
  resourceRequestsCountSummary: PropTypes.object
};

export default ResourcesCard;
