import { object, number } from 'yup';
import { useFormikContext as useDrawerFormikContext } from 'formik';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject,
  toRepliconDate
} from '~/modules/common/dates/convert';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import useOnSubmit from './useOnSubmit';

export const getProjectAvailableHours = ({
  start,
  end,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules
}) =>
  getTotalHoursForDateRangeFromScheduleRules({
    start,
    end,
    scheduleRules: resourceAllocationScheduleRules
  }) -
  getTotalHoursForDateRangeFromScheduleRules({
    start,
    end,
    scheduleRules: otherTaskAllocationsSummaryScheduleRules || []
  });

export const getInitialEstimatedHoursForTaskAllocation = ({
  initialEstimatedHours,
  projectAvailableHours
}) => {
  if (initialEstimatedHours) {
    return Math.min(initialEstimatedHours, projectAvailableHours);
  }

  return projectAvailableHours;
};

export const getDateRangeForNewTaskAllocation = ({
  taskStartDate,
  taskEndDate,
  resourceAllocationStartDate,
  resourceAllocationEndDate
}) => {
  const taskStart = taskStartDate && mapRepliconDateToUtcObject(taskStartDate);
  const taskEnd = taskEndDate && mapRepliconDateToUtcObject(taskEndDate);

  const allocationStart = mapIsoStringtoUtcObject(resourceAllocationStartDate);
  const allocationEnd = mapIsoStringtoUtcObject(resourceAllocationEndDate);

  const startDate =
    taskStart &&
    (allocationStart < taskStart || (taskEnd && allocationStart > taskEnd))
      ? taskStart
      : allocationStart;

  const endDate =
    taskEnd &&
    (allocationEnd > taskEnd || (taskStart && allocationEnd < taskStart))
      ? taskEnd
      : allocationEnd;

  return {
    startDate,
    endDate: startDate > endDate ? null : endDate
  };
};

export const getInitialValues = ({
  taskStartDate,
  taskEndDate,
  resourceAllocationEndDate,
  resourceAllocationStartDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  initialEstimatedHours,
  taskResourceUserAllocation
}) => {
  const { startDate, endDate } = taskResourceUserAllocation
    ? {
        startDate: mapIsoStringtoUtcObject(
          taskResourceUserAllocation.startDate
        ),
        endDate: mapIsoStringtoUtcObject(taskResourceUserAllocation.endDate)
      }
    : getDateRangeForNewTaskAllocation({
        taskStartDate,
        taskEndDate,
        resourceAllocationStartDate,
        resourceAllocationEndDate
      });

  const projectAvailableHours = getProjectAvailableHours({
    start: startDate,
    end: endDate,
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules
  });

  const allocatedHours = taskResourceUserAllocation
    ? taskResourceUserAllocation.totalHours
    : getInitialEstimatedHoursForTaskAllocation({
        initialEstimatedHours,
        projectAvailableHours
      });

  return {
    startDate: toRepliconDate(startDate),
    endDate: toRepliconDate(endDate),
    projectAvailableHours,
    allocatedHours: startDate && endDate ? allocatedHours : null
  };
};

export const buildValidationSchema = () =>
  object().shape({
    startDate: object()
      .nullable()
      .required(),
    endDate: object()
      .nullable()
      .required(),
    allocatedHours: number()
      .required()
      .moreThan(0)
  });

const useFormState = ({
  onClose,
  projectId,
  taskId,
  userId,
  roleId,
  resourceEstimateId,
  taskStartDate,
  taskEndDate,
  otherTaskAllocationsSummary,
  resourceAllocation,
  initialEstimatedHours,
  taskResourceUserAllocation
}) => {
  const { tenantSlug } = useMeContext();

  const {
    setFieldValue,
    values: { resourceEstimates }
  } = useDrawerFormikContext();
  const {
    scheduleRules: resourceAllocationScheduleRules,
    startDate: resourceAllocationStartDate,
    endDate: resourceAllocationEndDate,
    totalHours
  } = resourceAllocation;
  const {
    scheduleRules: otherTaskAllocationsSummaryScheduleRules
  } = otherTaskAllocationsSummary;
  const handleSubmit = useOnSubmit({
    setFieldValue,
    resourceEstimates
  });
  const onSubmit = async (values, { setSubmitting }) => {
    await handleSubmit({
      setSubmitting,
      projectId,
      taskId,
      userId,
      resourceEstimateId,
      tenantSlug,
      roleId,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      values,
      taskResourceUserAllocation
    });
    onClose();
  };

  return {
    initialValues: getInitialValues({
      taskStartDate,
      taskEndDate,
      tenantSlug,
      resourceAllocationStartDate,
      resourceAllocationEndDate,
      totalHours,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      initialEstimatedHours,
      taskResourceUserAllocation
    }),
    validationSchema: buildValidationSchema(),
    onSubmit
  };
};

export default useFormState;
