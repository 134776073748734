import { Divider, makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerFooter,
  DrawerContent,
  DrawerHeader
} from '~/modules/common/components/Drawer';
import { SavingProgress } from '~/modules/common/components';
import useMeContext from '~/modules/me/useMeContext';
import { getResourceRequestFromState } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestDrawer/hooks/useResourceRequestDrawerForm';
import {
  ResourceRequestDrawerDetails,
  ResourceRequestDrawerTitle
} from '~/modules/resourcing/common/components/ResourceRequestDrawer';
import { useResourceRequestActions, useAddResourceRequestForm } from './hooks';
import AddResourceRequestDrawerActions from './AddResourceRequestDrawerActions';
import { useActionButtonStyles } from './useActionButtonStyles';

const useContentStyles = makeStyles(theme => ({
  contentArea: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0)
  }
}));

const useStyles = makeStyles(theme => ({
  headerArea: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0, 1, 2)
  },
  grow: {
    flexGrow: 0
  }
}));

const useFooterStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.drawer
  }
}));

export const AddResourceRequestDrawerDetails = ({
  project,
  onClose,
  isMobile
}) => {
  const classes = useStyles();
  const footerClasses = useFooterStyles();
  const contentClasses = useContentStyles();
  const actionButtonClasses = useActionButtonStyles();
  const { defaultRequestAttributeWeights, baseCurrency } = useMeContext();

  const [savingResourceRequest, setResourceRequestSaving] = useState(false);
  const { form } = useAddResourceRequestForm({
    project,
    defaultRequestAttributeWeights,
    baseCurrency
  });

  const { onSubmit, onSave } = useResourceRequestActions({
    form,
    project,
    onClose,
    setResourceRequestSaving
  });

  const { resourceRequestFromState } = getResourceRequestFromState({
    form,
    project
  });

  const { handleReset, hasError } = form;

  const onCancel = useCallback(() => {
    onClose();
    handleReset();
  }, [onClose, handleReset]);

  return (
    <>
      <DrawerHeader classes={classes} onClose={onCancel}>
        <ResourceRequestDrawerTitle
          project={project}
          resourceRequest={resourceRequestFromState}
        />
      </DrawerHeader>
      <Divider />
      <DrawerContent className={contentClasses.contentArea}>
        {savingResourceRequest ? (
          <SavingProgress />
        ) : (
          !savingResourceRequest && (
            <ResourceRequestDrawerDetails
              form={form}
              project={project}
              drawerOnClose={onClose}
              resourceRequest={resourceRequestFromState}
              isMobile={isMobile}
            />
          )
        )}
        <DrawerFooter classes={footerClasses}>
          {!savingResourceRequest ? (
            <div className={actionButtonClasses.root}>
              <AddResourceRequestDrawerActions
                hasError={hasError}
                drawerOnClose={onClose}
                onResourceRequestSave={onSave}
                onResourceRequestSubmit={onSubmit}
                setResourceRequestSaving={setResourceRequestSaving}
              />
            </div>
          ) : null}
        </DrawerFooter>
      </DrawerContent>
    </>
  );
};

AddResourceRequestDrawerDetails.propTypes = {
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  isMobile: PropTypes.bool
};

export default AddResourceRequestDrawerDetails;
