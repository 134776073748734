import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { usePortfolioPermissions } from './usePortfolioPermissions';

const PAGE_SIZE = 200;

export const PROJECT_PORTFOLIOS_QUERY = gql`
  query pageOfPortfoliosAvailableForProjectAssignment(
    $input: PageOfProjectPortfolioInput
  ) {
    pageOfPortfoliosAvailableForProjectAssignment(input: $input) {
      id
      isAssignable
    }
  }
`;

export const useIsPortfolioAssignable = ({ portfolioId, portfolioName }) => {
  const { canViewAllPortfolios } = usePortfolioPermissions();
  const skip = !portfolioId || canViewAllPortfolios;
  const { data, loading } = useQuery(PROJECT_PORTFOLIOS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        searchTerm: portfolioName,
        page: 1,
        pageSize: PAGE_SIZE
      },
      skip
    }
  });

  const result = data?.pageOfPortfoliosAvailableForProjectAssignment || [];

  return {
    isPortfolioAssignable:
      canViewAllPortfolios ||
      (!loading && result.some(p => p.id === portfolioId && p.isAssignable)),
    loading
  };
};
