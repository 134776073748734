import React from 'react';
import { PropTypes } from 'prop-types';
import Decimal from '~/modules/common/components/Decimal';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';

const getPrefix = ({ variationStatus }) =>
  variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.OVER
    ? '+ '
    : variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.UNDER
    ? '- '
    : '';

export const NotStartedVariationValue = ({
  className,
  variant,
  variation,
  variationStatus
}) => {
  return (
    <Decimal
      className={className}
      value={variant === 'hours' ? variation : variation.amount}
      prefix={
        variant === 'hours'
          ? getPrefix({ variationStatus })
          : `${getPrefix({ variationStatus })}${variation.currency.symbol} `
      }
    />
  );
};

export default NotStartedVariationValue;

NotStartedVariationValue.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['cost', 'hours']),
  variation: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  variationStatus: PropTypes.string
};
