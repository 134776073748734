import React from 'react';
import PropTypes from 'prop-types';
import NoValue from './NoValue';

const isNil = value => value === null || value === undefined;
const isInvalidNumber = value => typeof value === 'number' && isNaN(value);

const OptionalField = ({ FallbackComponent = NoValue, value, children }) => {
  if (!isNil(value) && !isInvalidNumber(value)) {
    return children;
  }

  return <FallbackComponent />;
};

OptionalField.propTypes = {
  children: PropTypes.node.isRequired,
  FallbackComponent: PropTypes.any,
  value: PropTypes.any
};

export const withOptionalField = (
  valueProp,
  FallbackComponent
) => Component => props => {
  const isFunction = typeof valueProp === 'function';
  // eslint-disable-next-line react/destructuring-assignment
  const value = isFunction ? valueProp(props) : props[valueProp];

  return (
    <OptionalField FallbackComponent={FallbackComponent} value={value}>
      <Component {...props} />
    </OptionalField>
  );
};

export default OptionalField;
