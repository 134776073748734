import { isoStringToObject } from '~/modules/common/dates/convert';
import { amountFormatter } from '~/modules/projects/project/common/scriptEnhancers';

export const parameterDefaultValueFormatterByKeyUri = {
  'urn:replicon:script-key:parameter:contract-start-date': ({
    defaultValue,
    projectStartDate
  }) => isoStringToObject(projectStartDate) || defaultValue,
  'urn:replicon:script-key:parameter:contract-end-date': ({
    defaultValue,
    projectEndDate
  }) => isoStringToObject(projectEndDate) || defaultValue,
  'urn:replicon:script-key:parameter:total-contract-value': ({
    me,
    defaultValue,
    contractValue
  }) => contractValue || amountFormatter(defaultValue, me)
};
