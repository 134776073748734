import React from 'react';
import GenericCostCenterDropdown from '../CostCenterDropdown';
import GenericDepartmentDropdown from '../DepartmentDropdown';
import GenericDivisionDropdown from '../DivisionDropdown';
import GenericEmployeeTypeDropdown from '../EmployeeTypeDropdown';
import GenericLocationsDropdown from '../LocationsDropdown';
import GenericServiceCenterDropdown from '../ServiceCenterDropdown';
import useEffectiveProjectGroupPolicy from './useEffectiveProjectGroupPolicy';

export const CostCenterDropdown = props => {
  const policy = useEffectiveProjectGroupPolicy();

  return <GenericCostCenterDropdown {...props} policy={policy} />;
};

export const DepartmentDropdown = props => {
  const policy = useEffectiveProjectGroupPolicy();

  return <GenericDepartmentDropdown {...props} policy={policy} />;
};

export const DivisionDropdown = props => {
  const policy = useEffectiveProjectGroupPolicy();

  return <GenericDivisionDropdown {...props} policy={policy} />;
};

export const EmployeeTypeDropdown = props => {
  const policy = useEffectiveProjectGroupPolicy();

  return <GenericEmployeeTypeDropdown {...props} policy={policy} />;
};

export const LocationsDropdown = props => {
  const policy = useEffectiveProjectGroupPolicy();

  return <GenericLocationsDropdown {...props} policy={policy} />;
};

export const ServiceCenterDropdown = props => {
  const policy = useEffectiveProjectGroupPolicy();

  return <GenericServiceCenterDropdown {...props} policy={policy} />;
};
