import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Date from './Date';

const useStyles = makeStyles(theme => ({
  range: {
    display: 'inline',
    fontSize: theme.typography.caption.fontSize,
    color: 'inherit'
  },
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.caption.fontSize,
    display: 'inline'
  },
  spacer: {
    fontSize: theme.typography.caption.fontSize,
    color: 'inherit',
    lineHeight: 'inherit'
  }
}));

export const DateRange = ({
  classes: classesOverrides,
  start,
  end,
  format,
  startFormat,
  endFormat,
  noDateComponent = undefined
}) => {
  const classes = useStyles({ classes: classesOverrides });

  return (
    <span className={classes.range}>
      {start ? (
        <Date
          data-qe-id="StartDateField"
          value={start}
          format={startFormat || format}
          className={classes.date}
        />
      ) : (
        noDateComponent
      )}
      <span className={classes.spacer}>&nbsp;&mdash;&nbsp;</span>
      {end ? (
        <Date
          data-qe-id="EndDateField"
          value={end}
          format={endFormat || format}
          className={classes.date}
        />
      ) : (
        noDateComponent
      )}
    </span>
  );
};

DateRange.propTypes = {
  classes: PropTypes.object,
  start: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  end: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  format: PropTypes.string,
  startFormat: PropTypes.string,
  endFormat: PropTypes.string,
  noDateComponent: PropTypes.any
};

export default DateRange;
