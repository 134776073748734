import { makeStyles } from '@material-ui/core';

const useOverviewStyle = makeStyles(theme => ({
  cell: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap',
    minWidth: `${theme.spacing(15)}px`,
    justifyContent: 'flex-end',
    borderBottom: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.caption.fontSize
    }
  },
  cellWithBorder: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap',
    minWidth: `${theme.spacing(15)}px`,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.caption.fontSize
    }
  },
  cellHeader: {
    position: 'sticky',
    zIndex: 11,
    lineHeight: 1,
    whiteSpace: 'normal',
    top: ({ headerLevel }) => theme.spacing((headerLevel + 1) * 6)
  },
  cellLabel: {
    position: 'sticky',
    left: 0,
    lineHeight: `${theme.spacing(1.5)}px`,
    justifyContent: 'flex-start',
    textAlign: 'left',
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    '&$cellHeader': {
      zIndex: 14
    }
  },
  cellTotal: {
    position: 'sticky',
    right: 0,
    zIndex: 1,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey[100],
    fontWeight: theme.typography.fontWeightBold,
    '&$cellHeader': {
      zIndex: 12
    }
  },
  cellCurrent: {
    backgroundColor: theme.palette.grey[50]
  },
  row: {
    '&:last-child $cell': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  headerRow: {
    '& $cell, & $cellLabel': {
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
      lineHeight: `${theme.spacing(2.5)}px`,
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.25)
    },
    '& $cellLabel': {
      zIndex: 12
    }
  },
  totalRow: {
    '& $cell, & $cellLabel': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  subRow: {
    '& $cell, & $cellLabel, & $cellTotal': {
      ...theme.typography.caption,
      color: theme.palette.text.secondary
    },
    '& $cell, & $cellLabel': {
      backgroundColor: theme.palette.grey[100]
    },
    '& $cellLabel': {
      paddingLeft: theme.spacing(4)
    },
    '& $cellCurrent, & $cellTotal': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

export default useOverviewStyle;
