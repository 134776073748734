import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Hours, Money2 } from '~/modules/common/components';
import { hasError, getError } from '~/util';

export const TaskEstimationFields = ({
  classes,
  variant = 'standard',
  errors,
  estimatedHours,
  estimatedCost,
  onEstimatedHoursChange,
  onEstimatedCostAmountChange,
  onEstimatedCostCurrencyChange,
  fullScreen,
  canEditCostData
}) => {
  const { formatMessage } = useIntl();

  const labels = useMemo(
    () => ({
      hours: formatMessage({ id: 'projectTasksPage.estimatedHours' }),
      cost: formatMessage({ id: 'projectTasksPage.estimatedCost' })
    }),
    [formatMessage]
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      wrap={fullScreen ? 'wrap' : 'nowrap'}
    >
      <Grid item xs={12} sm={12}>
        <Hours
          label={labels.hours}
          ariaLabel={labels.hours}
          dataQeId="TaskEstimationField"
          variant={variant}
          fullWidth
          underline
          value={estimatedHours}
          onValueChange={onEstimatedHoursChange}
          isEditible
          error={hasError(errors, 'estimatedHours')}
          helperText={getError(errors, 'estimatedHours')}
        />
      </Grid>
      {canEditCostData && (
        <Grid item xs={12} sm={12}>
          <Money2
            label={labels.cost}
            variant={variant}
            amount={estimatedCost.amount}
            currencyDataQeId="estimatedCostCurrency"
            valueDataQeId="estimatedCost"
            currency={estimatedCost.currency}
            onAmountChange={onEstimatedCostAmountChange}
            onCurrencyChange={onEstimatedCostCurrencyChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

TaskEstimationFields.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'filled']),
  errors: PropTypes.object,
  estimatedHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  estimatedCost: PropTypes.object,
  onEstimatedHoursChange: PropTypes.func,
  onEstimatedCostAmountChange: PropTypes.func,
  onEstimatedCostCurrencyChange: PropTypes.func,
  fullScreen: PropTypes.bool,
  canEditCostData: PropTypes.bool
};

export default TaskEstimationFields;
