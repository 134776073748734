import React, { ReactElement, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { RequestAttributeWeights, RequestAttributeWeightage } from '~/types';
import useMeContext from '~/modules/me/useMeContext';
import { useHasPermission } from '~/modules/common/permissions';
import { MoneyValue } from '~/modules/common/components';
import useAllTags from '../ResourceRequestDrawerDetails/AddTags/useAllTags';
import ImportanceItem from './ImportanceItem';

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingTop: 0
  },
  importanceFormHeader: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'flex-start',
    padding: theme.spacing(0, 0.5, 1, 22),
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 1, 1)
    }
  },
  lowLabel: {
    margin: theme.spacing(0, 2, 0, 0)
  },
  middleLabel: {
    flexGrow: 1,
    flexShrink: 1,
    position: 'relative',
    overflow: 'visible',
    height: theme.spacing(0.25),
    backgroundColor: theme.palette.grey[200],
    '&:before, &:after': {
      content: `' '`,
      display: 'inline-block',
      border: `solid ${theme.palette.grey[200]}`,
      borderWidth: theme.spacing(0, 0.25, 0.25, 0),
      padding: theme.spacing(0.25),
      position: 'absolute',
      top: 0,
      margin: theme.spacing(-0.25, 0, 0, 0),
      height: 0,
      width: 0,
      overflow: 'hidden',
      backgroundColor: 'transparant'
    },
    '&:before': {
      transform: 'rotate(135deg)',
      left: 0
    },
    '&:after': {
      transform: 'rotate(-45deg)',
      right: 0
    }
  },
  highLabel: {
    margin: theme.spacing(0, 0, 0, 2)
  },
  importanceItems: {
    listStyle: 'none',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 1),
    display: 'grid',
    gridTemplateColumns: [[`${theme.spacing(20)}px`, 'auto']] as any,
    gridTemplateRows: 'auto',
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'stretch',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  importanceItem: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 2, 0),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  noneMessage: {
    color: theme.palette.text.secondary
  }
}));

const fields = [
  'role',
  'preferredResources',
  'skills',
  'costCenter',
  'department',
  'division',
  'employeeType',
  'location',
  'serviceCenter',
  'tags',
  'requestedCostRate',
  'resourcePools'
] as const;

const ImportanceForm = ({
  readonly,
  setFieldValue,
  values,
  weights
}: {
  weights: Required<Omit<RequestAttributeWeights, '__typename'>>;
  values: {
    location: any;
    division: any;
    serviceCenter: any;
    costCenter: any;
    department: any;
    employeeType: any;
    role: any;
    skills: any;
    tags: any;
    requestedCostRate: any;
    resourcePools: any;
    preferredResources: any;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
  readonly: boolean;
}): ReactElement => {
  const classes = useStyles();

  const me = useMeContext();

  const hasTagPermission = useHasPermission({
    actionUri: 'urn:replicon:user-action:view-custom-fields'
  });

  const canViewRequestedCostRate = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-cost-data'
  });

  const { tags: resourceTags = [], isTagLoading } = useAllTags();

  const canViewTags = !isTagLoading && resourceTags.length && hasTagPermission;

  const skillFormatValues = {
    count: values.skills && values.skills.length
  };

  const tagFormatValues = {
    count: values.tags && values.tags.length
  };

  const resourcePoolsFormatValues = {
    count: values.resourcePools?.length || 0
  };

  const preferredResourcesFormatValues = {
    count: values.preferredResources?.length || 0
  };

  const convertedValues = useMemo(
    () => ({
      location: values.location ? (
        <span>{values.location.displayText}</span>
      ) : null,
      division: values.division ? (
        <span>{values.division.displayText}</span>
      ) : null,
      serviceCenter: values.serviceCenter ? (
        <span>{values.serviceCenter.displayText}</span>
      ) : null,
      costCenter: values.costCenter ? (
        <span>{values.costCenter.displayText}</span>
      ) : null,
      department: values.department ? (
        <span>{values.department.displayText}</span>
      ) : null,
      employeeType: values.employeeType ? (
        <span>{values.employeeType.displayText}</span>
      ) : null,
      role: values.role ? <span>{values.role.displayText}</span> : null,
      skills:
        values.skills && values.skills.length > 0 ? (
          <FormattedMessage
            id={
              values.skills.length > 1
                ? 'resourceRequestDrawerDetails.skillsSet.plural'
                : 'resourceRequestDrawerDetails.skillsSet.singular'
            }
            values={skillFormatValues}
          />
        ) : null,
      tags:
        canViewTags && values.tags && values.tags.length > 0 ? (
          <FormattedMessage
            id={
              values.tags.length > 1
                ? 'resourceRequestDrawerDetails.tagsSet.plural'
                : 'resourceRequestDrawerDetails.tagsSet.singular'
            }
            values={tagFormatValues}
          />
        ) : null,
      requestedCostRate:
        canViewRequestedCostRate && values.requestedCostRate.amount ? (
          <MoneyValue money={values.requestedCostRate} />
        ) : null,
      resourcePools:
        (values.resourcePools || []).length > 0 ? (
          <FormattedMessage
            id="resourceRequestDrawerDetails.resourcePoolsSet.count"
            values={resourcePoolsFormatValues}
          />
        ) : null,
      preferredResources: (values.preferredResources || []).length ? (
        <FormattedMessage
          id="resourceRequestDrawerDetails.preferredResourcesSet.count"
          values={preferredResourcesFormatValues}
        />
      ) : null
    }),
    [
      values,
      skillFormatValues,
      tagFormatValues,
      canViewTags,
      canViewRequestedCostRate,
      resourcePoolsFormatValues,
      preferredResourcesFormatValues
    ]
  );

  const items = useMemo(
    () =>
      fields
        .filter(fieldName => !readonly || convertedValues[fieldName])
        .filter(fieldName => fieldName !== 'tags' || canViewTags)
        .filter(
          fieldName =>
            fieldName !== 'requestedCostRate' || canViewRequestedCostRate
        )
        .filter(
          fieldName =>
            !(me?.groupSettings as any)?.[fieldName] ||
            (me?.groupSettings as any)?.[fieldName].isEnabled
        )
        .map(fieldName => (
          <ImportanceItem
            weight={weights[fieldName]}
            key={fieldName}
            fieldName={fieldName}
            setFieldValue={setFieldValue}
            readonly={readonly}
            value={convertedValues[fieldName]}
          />
        )),
    [
      me,
      weights,
      convertedValues,
      setFieldValue,
      readonly,
      canViewTags,
      canViewRequestedCostRate
    ]
  );

  if (items.length === 0) {
    return (
      <div className={classes.noneMessage}>
        <FormattedMessage id="resourceRequestDrawerDetails.noQualifications" />
      </div>
    );
  }

  return (
    <>
      <div className={classes.importanceFormHeader}>
        <span className={classes.lowLabel}>
          <FormattedMessage
            id={`resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.NiceToHave}`}
          />
        </span>
        <span className={classes.middleLabel}></span>
        <span className={classes.highLabel}>
          <FormattedMessage
            id={`resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Required}`}
          />
        </span>
      </div>
      <ul className={classes.importanceItems}>{items}</ul>
    </>
  );
};

export default ImportanceForm;
