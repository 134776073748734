import { useApolloClient } from '@apollo/client';
import { matchPath, useHistory } from 'react-router-dom';
import {
  tryLoadPortfolioAncestoryFromCache,
  usePortfolioProjectsPermissions
} from '~/modules/common/hooks/portfolio';

export const getOnCreateProjectHistoryStateProps = ({
  canViewPortfolioProjects,
  history
}) => {
  if (
    matchPath(history?.location?.pathname, {
      path: '/project-request'
    })
  )
    return {
      goBackButtonPath: '/project-request'
    };

  const portfoliosPath = matchPath(history?.location?.pathname, {
    path: '/portfolios/:slug/requests'
  });

  if (portfoliosPath?.params?.slug) {
    return {
      goBackButtonPath: canViewPortfolioProjects
        ? `/portfolios/${portfoliosPath.params.slug}/projects`
        : `/portfolios/${portfoliosPath.params.slug}`
    };
  }

  return undefined;
};

const useOnCreateProjectHistoryStateProps = ({ portfolioId }) => {
  const history = useHistory();
  const client = useApolloClient();

  const { portfolioManager, ancestors } =
    tryLoadPortfolioAncestoryFromCache(client, portfolioId) || {};

  const { canViewPortfolioProjects } = usePortfolioProjectsPermissions({
    portfolioManager,
    ancestors
  });

  return getOnCreateProjectHistoryStateProps({
    canViewPortfolioProjects,
    history
  });
};

export default useOnCreateProjectHistoryStateProps;
