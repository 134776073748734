import PropTypes from 'prop-types';
import React, { useState, useCallback, memo } from 'react';

import { useIntl } from 'react-intl';
import { withResizeDetector } from 'react-resize-detector';
import { makeStyles } from '@material-ui/core/styles';
import { useDialogState } from '~/modules/common/hooks';

import { useHasPermission } from '~/modules/common/permissions';
import { ProjectContextProvider } from '~/modules/resourcing/common/contexts';
import { usePageTitle } from '~/modules/common/title';

import { SortDirection } from '~/types';
import {
  ResourceRequestActionMessageSnackBar,
  AddFabButton
} from '~/modules/common/components';
import AddResourceRequestDrawer from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/AddResourceRequestDrawer';
import useResourceGridWidthHeightExcludePropCheckHack from '~/modules/common/hooks/useResourceGridWidthHeightExcludePropCheckHack';
import { ProjectChartRowsLoading } from '~/modules/resourcing/common/components';
import { useProjectResourcingPlanSearch } from '../hooks';
import ResourceRequestToolbarContextProvider from '../ResourceRequestToolbarContextProvider';
import SelectedResourceRequestContextProvider from '../SelectedResourceRequestContextProvider';
import DesktopResourcingPlanPage from './DesktopResourcingPlanPage';

const useStyles = makeStyles(() => ({
  fab: {
    zIndex: 50
  }
}));

export const DesktopProjectResourcingPlan = memo(
  ({
    editable,
    project,
    width,
    setSearchCriteria,
    searchCriteria,
    resourceRequestsMinMaxDateAndStatuses,
    resourceAllocationsMinMaxDateAndStatuses,
    chartSettings
  }) => {
    const [sort, setSort] = useState();
    const intl = useIntl();

    const { open, closeDialog, openDialog } = useDialogState(false, {
      disabledBackDropClick: true,
      disableEscapeKeyDown: true
    });

    const editProjectEnabled = useHasPermission({
      actionUri: 'urn:replicon:project-action:edit-project'
    });

    const classes = useStyles();

    const onSortChange = useCallback(
      field =>
        setSort({
          field,
          direction:
            sort && sort.field === field && sort.direction === SortDirection.Asc
              ? SortDirection.Desc
              : SortDirection.Asc
        }),
      [sort, setSort]
    );

    usePageTitle([
      { messageId: 'routes.projects' },
      { title: project.displayText },
      { messageId: 'projectPageTabs.resourcing' }
    ]);

    const filter = useProjectResourcingPlanSearch({ searchCriteria });

    return (
      <ProjectContextProvider project={project}>
        <SelectedResourceRequestContextProvider>
          <ResourceRequestToolbarContextProvider
            project={project}
            width={width}
            setSearchCriteria={setSearchCriteria}
            searchCriteria={searchCriteria}
            filter={filter}
            sort={sort}
            onSortChange={onSortChange}
            resourceRequestsMinMaxDateAndStatuses={
              resourceRequestsMinMaxDateAndStatuses
            }
            resourceAllocationsMinMaxDateAndStatuses={
              resourceAllocationsMinMaxDateAndStatuses
            }
            chartSettings={chartSettings}
          >
            <DesktopResourcingPlanPage
              isEditSwitchEnabled={editable}
              openAddResourceRequestDialog={openDialog}
            />
            {editProjectEnabled && editable && (
              <AddFabButton
                className={classes.fab}
                label={intl.formatMessage({
                  id: 'addResourceRequestButton.label'
                })}
                shortLabel={intl.formatMessage({
                  id: 'addResourceRequestButton.shortLabel'
                })}
                dataQeId="addResourceRequestOpenDialog"
                onClick={openDialog}
              />
            )}
            {open && (
              <AddResourceRequestDrawer
                project={project}
                onClose={closeDialog}
                open={open}
              />
            )}
          </ResourceRequestToolbarContextProvider>
          <ResourceRequestActionMessageSnackBar />
        </SelectedResourceRequestContextProvider>
      </ProjectContextProvider>
    );
  },
  useResourceGridWidthHeightExcludePropCheckHack
);

DesktopProjectResourcingPlan.propTypes = {
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  width: PropTypes.number,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object,
  resourceRequestsMinMaxDateAndStatuses: PropTypes.object,
  resourceAllocationsMinMaxDateAndStatuses: PropTypes.object,
  chartSettings: PropTypes.object
};

export const SkipRenderBasedOnWidth = ({ width, ...rest }) => {
  if (!width) return <ProjectChartRowsLoading />;

  return <DesktopProjectResourcingPlan width={width} {...rest} />;
};

SkipRenderBasedOnWidth.propTypes = {
  width: PropTypes.number
};

export default withResizeDetector(SkipRenderBasedOnWidth);
