import { useMemo } from 'react';
import {
  roleTag,
  primaryRoleTag
} from '~/modules/common/components/SearchBox/Facets/RoleFacet/tag';
import {
  getForCriteria,
  getTagForCriteria,
  getValueForCriteria
} from '~/modules/resourcing/common/enhancers/withFilterBasedOnSearchTerm';
import { costCenterTag } from '~/modules/common/components/SearchBox/Facets/CostCenterFacet/tag';
import { skillTag } from '~/modules/common/components/SearchBox/Facets/SkillFacet/tag';
import { departmentTag } from '~/modules/common/components/SearchBox/Facets/DepartmentFacet/tag';
import { divisionTag } from '~/modules/common/components/SearchBox/Facets/DivisionFacet/tag';
import { employeeTypeTag } from '~/modules/common/components/SearchBox/Facets/EmployeeTypeFacet/tag';
import { locationTag } from '~/modules/common/components/SearchBox/Facets/LocationFacet/tag';
import { resourcePoolTag } from '~/modules/common/components/SearchBox/Facets/ResourcePoolFacet/tag';
import { serviceCenterTag } from '~/modules/common/components/SearchBox/Facets/ServiceCenterFacet/tag';
import { tagsTag } from '~/modules/common/components/SearchBox/Facets/TagFacet/tag';
import { programTag } from '~/modules/common/components/SearchBox/Facets/ProgramFacet/tag';
import { clientTag } from '~/modules/common/components/SearchBox/Facets/ClientFacet/tag';
import { projectTag } from '~/modules/common/components/SearchBox/Facets/ProjectFacet/tag';
import { resourceTag } from '~/modules/common/components/SearchBox/Facets/UserFacet/tag';
import { checkForCriteria } from '~/modules/common/util';

const allTags = [
  roleTag,
  primaryRoleTag,
  tagsTag,
  resourceTag,
  departmentTag,
  divisionTag,
  employeeTypeTag,
  locationTag,
  serviceCenterTag,
  costCenterTag,
  skillTag,
  projectTag,
  clientTag,
  programTag,
  resourcePoolTag
];

const useFilterBasedOnSearchCriteria = ({
  searchCriteria: { keywords, criterions } = {}
}) =>
  useMemo(() => {
    if (
      !(
        (keywords && keywords.length > 0) ||
        allTags.some(tag => checkForCriteria(criterions, tag))
      )
    ) {
      return {};
    }

    return {
      filter: {
        name: keywords && keywords.length > 0 ? keywords.join(' ') : undefined,
        roles: getForCriteria(criterions, roleTag),
        primaryRoles: getForCriteria(criterions, primaryRoleTag),
        costCenters: getForCriteria(criterions, costCenterTag),
        projects: getForCriteria(criterions, projectTag),
        clients: getForCriteria(criterions, clientTag),
        programs: getForCriteria(criterions, programTag),
        divisions: getForCriteria(criterions, divisionTag),
        serviceCenters: getForCriteria(criterions, serviceCenterTag),
        employeeTypes: getForCriteria(criterions, employeeTypeTag),
        locations: getForCriteria(criterions, locationTag),
        departments: getForCriteria(criterions, departmentTag),
        skills: getForCriteria(criterions, skillTag),
        tags: getTagForCriteria(criterions, tagsTag),
        resourcePools: getForCriteria(criterions, resourcePoolTag),
        userIds: getValueForCriteria(criterions, resourceTag)
      }
    };
  }, [keywords, criterions]);

export default useFilterBasedOnSearchCriteria;
