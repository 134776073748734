import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ReadOnlyContent } from '~/modules/common/components/EditableCard';
import ReadOnlySection from '../../common/components/ReadOnlySection';
import BasicInformationReadOnly from './BasicInformationReadOnly';

const title = <FormattedMessage id="resourceBasicInfoCard.basicInformation" />;

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(0, -2, -2, -2)
  }
}));

export const BasicInformation = ({
  classes: classesOverride,
  userDetails,
  permittedActions
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.card} data-qe-id="ResourceBasicInfo">
      <ReadOnlySection title={title} editable={false}>
        <ReadOnlyContent>
          <BasicInformationReadOnly
            userDetails={userDetails}
            permittedActions={permittedActions}
          />
        </ReadOnlyContent>
      </ReadOnlySection>
    </div>
  );
};

BasicInformation.propTypes = {
  userDetails: PropTypes.object,
  permittedActions: PropTypes.array.isRequired,
  classes: PropTypes.object
};

export default BasicInformation;
