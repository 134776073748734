import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { billQuery } from '~/modules/billing-invoicing/bill/hooks/useBillDetails';
import {
  billAssociatedPaymentQuery,
  PAGE_SIZE
} from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/useAssociatedBillPayments';
import billingTransactionRefetchQueries from '~/modules/common/components/RefetchQueries/BillingTransactionRefetchQueries';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const PUT_PAYMENTS_FOR_BILL = gql`
  mutation putBillPaymentsForBill($input: BillPaymentsForBillInput!) {
    putBillPaymentsForBill2(input: $input) {
      billUri
    }
  }
`;

export const usePutBillPaymentsForBill = () => {
  const [putBillPaymentsForBill] = useMutation(PUT_PAYMENTS_FOR_BILL);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });
  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : [
        'getDraftTotals',
        'getOverdueBillSummary',
        'getOutstandingBillSummary',
        'getIssuedBillSummary',
        'getUnallocatedBillSummary'
      ];

  return {
    putBillPaymentsForBill: billPayments =>
      putBillPaymentsForBill({
        variables: {
          input: billPayments
        },
        refetchQueries: [
          ...billingTransactionRefetchQueries,
          'availablePaymentsForBill',
          ...queries,
          {
            query: billQuery,
            variables: {
              id: billPayments.billId,
              isPsaPswatBillingDraftSubStatusEnabled
            }
          },
          {
            query: billAssociatedPaymentQuery,
            variables: {
              page: 1,
              pageSize: PAGE_SIZE,
              id: billPayments.billId
            }
          }
        ],
        awaitRefetchQueries: true
      })
  };
};
