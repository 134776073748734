import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { v4 } from 'uuid';
import { getTodayForUser } from '~/modules/common/dates/today';
import { useMeContext } from '~/modules/me';
import { DimensionObjectType } from '~/types';
import {
  mapQueryAndVariables,
  getAmount,
  addNewDimensionToProjectRates,
  getCreateNewEntry
} from './utils';

const filterOutMatchingRates = (dimensionsToMatch, projectRate) => rate =>
  !dimensionsToMatch.every(d => rate[d.id]?.id === projectRate[d.id]?.id);

export const useFormOnChange = ({ projectCurrency, setFieldValue, values }) => {
  const {
    projectDimensions,
    projectRates,
    removedProjectRateEntryGroup
  } = values;

  const client = useApolloClient();

  const me = useMeContext();
  const todayForUser = getTodayForUser(me);

  return {
    onAddDimension: useCallback(
      dimension => {
        setFieldValue(
          'projectDimensions',
          [...projectDimensions, dimension],
          false
        );

        const updatedProjectRates = addNewDimensionToProjectRates(
          projectRates,
          dimension
        );

        setFieldValue('projectRates', updatedProjectRates, false);
      },
      [projectDimensions, projectRates, setFieldValue]
    ),
    onRemoveDimension: useCallback(
      dimension => {
        setFieldValue(
          'projectDimensions',
          projectDimensions.filter(d => d.id !== dimension.id),
          false
        );
        setFieldValue(
          'projectRates',
          projectRates.map(({ [dimension.id]: _, ...rest }) => rest),
          false
        );
      },
      [projectDimensions, projectRates, setFieldValue]
    ),
    onDimensionValueChange: useCallback(
      async ({ index, dimensionId, value, objectType }) => {
        const dimensionIndex = projectDimensions.findIndex(
          d => d.id === dimensionId
        );
        const dimensionsToMatch = [...projectDimensions].slice(
          0,
          dimensionIndex + 1
        );

        const filteredProjectRates = projectRates.filter(
          filterOutMatchingRates(dimensionsToMatch, projectRates[index])
        );

        const filteredProjectRatesIdsSet = new Set(
          filteredProjectRates.map(r => r.id)
        );

        const removedRates = projectRates.filter(
          e => e[dimensionId].value?.id && !filteredProjectRatesIdsSet.has(e.id)
        );

        const { data } =
          value?.id && objectType !== DimensionObjectType.PayCode
            ? await client.query({
                ...mapQueryAndVariables(objectType, value)
              })
            : {};

        const previousRecord = Object.values(
          removedRates.reduce(
            (retVal, { groupId: idOfGroup, ...rest }) => ({
              ...retVal,
              [idOfGroup]: JSON.parse(JSON.stringify(rest))
            }),
            {}
          )
        );

        setFieldValue(
          'removedProjectRateEntryGroup',
          [...removedProjectRateEntryGroup, ...previousRecord],
          false
        );

        const updatedProjectRates = [
          ...[...filteredProjectRates].slice(0, index),
          {
            ...projectRates[index],
            [dimensionId]: {
              ...projectRates[index][dimensionId],
              value
            },
            effectiveDate: null,
            amount: getAmount({
              objectType,
              data,
              todayForUser,
              rate: projectRates[index],
              projectCurrency
            }),
            isInitialRate: true,
            totalEntries: 1
          },
          ...[...filteredProjectRates].slice(index)
        ];

        setFieldValue(`projectRates`, updatedProjectRates);
      },
      [
        client,
        projectCurrency,
        projectDimensions,
        projectRates,
        removedProjectRateEntryGroup,
        setFieldValue,
        todayForUser
      ]
    ),
    onAddEntry: useCallback(
      (index, entry) => {
        const firstEntryIndex = projectRates.findIndex(
          e => e.groupId === entry.groupId
        );

        const updatedProjectRates = [...projectRates];

        updatedProjectRates.splice(firstEntryIndex, 0, {
          ...entry,
          id: v4(),
          amount: {
            amount: 0,
            currency: projectCurrency
          },
          isInitialRate: false
        });

        setFieldValue(
          'projectRates',
          updatedProjectRates.map(e => ({
            ...e,
            totalEntries:
              e.groupId === entry.groupId ? e.totalEntries + 1 : e.totalEntries
          }))
        );
      },
      [projectRates, projectCurrency, setFieldValue]
    ),
    onRemoveEntry: useCallback(
      (index, entry) => {
        const updatedProjectRates = projectRates.filter((_, i) => i !== index);

        setFieldValue(
          'projectRates',
          updatedProjectRates.map(e => ({
            ...e,
            totalEntries:
              e.groupId === entry.groupId ? e.totalEntries - 1 : e.totalEntries
          }))
        );
      },
      [projectRates, setFieldValue]
    ),
    onAddNewEntry: useCallback(
      (record, dimensionIndex, index) => {
        const entry = getCreateNewEntry(
          projectDimensions,
          projectCurrency,
          dimensionIndex,
          record
        );

        const updatedProjectRates = [...projectRates];

        updatedProjectRates.splice(index + 1, 0, entry);

        setFieldValue('projectRates', updatedProjectRates);
      },
      [projectCurrency, projectDimensions, projectRates, setFieldValue]
    ),
    onDeleteGroup: useCallback(
      groupId => {
        const groupFirstEntry = projectRates.find(e => e.groupId === groupId);
        const updatedProjectRates = projectRates.filter(
          e => e.groupId !== groupId
        );

        setFieldValue(
          'removedProjectRateEntryGroup',
          [...removedProjectRateEntryGroup, groupFirstEntry],
          false
        );

        setFieldValue('projectRates', updatedProjectRates);
      },
      [projectRates, removedProjectRateEntryGroup, setFieldValue]
    ),
    onEffectiveDateChange: useCallback(
      async (index, value) => {
        setFieldValue(`projectRates[${index}].effectiveDate`, value);
      },
      [setFieldValue]
    ),
    onCurrencyChange: useCallback(
      async (index, value) => {
        setFieldValue(`projectRates[${index}].amount.currency`, value, false);
      },
      [setFieldValue]
    ),
    onAmountChange: useCallback(
      async (index, value) => {
        setFieldValue(`projectRates[${index}].amount.amount`, value, false);
      },
      [setFieldValue]
    )
  };
};

export default useFormOnChange;
