import React from 'react';
import ProjectSuggestionItem from './ProjectSuggestionItem';
import { projectTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import ProjectFacetDetails from './ProjectFacetDetails';

const createFacetControl = classes => (props = {}) => (
  <ProjectFacetDetails classes={classes} {...props} />
);

export const taskBeatsProjectFacet = classes => ({
  tag: projectTag,
  makeCriteriaProvider,
  DetailsControl: createFacetControl(classes),
  SuggestionItem: ProjectSuggestionItem
});

export default {
  tag: projectTag,
  makeCriteriaProvider,
  DetailsControl: ProjectFacetDetails,
  SuggestionItem: ProjectSuggestionItem
};
