import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import {
  useProjectRevenuePolicy,
  normalizeScriptParams
} from './useProjectRevenuePolicy';

export const getProjectRevenueQuery = gql`
  query getProjectRevenuePolicyDetails($projectId: String!) {
    project(projectId: $projectId) {
      id
      startDate: startDate2
      endDate: endDate2
      totalEstimatedContract {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      revenueContractDetails {
        id
        displayText
        description
        scripts
        projectScriptParameters
      }
    }
  }
`;

export const mapToProjectRevenue = ({
  revenueContractDetails,
  clauses,
  totalEstimatedContract,
  me
}) => {
  if (revenueContractDetails) {
    const requiredClause = clauses.find(
      x => x.id === revenueContractDetails.id
    );

    if (requiredClause) {
      return {
        ...requiredClause,
        ...revenueContractDetails
      };
    }

    return {
      ...revenueContractDetails,
      parameters: normalizeScriptParams({
        parameters: revenueContractDetails.projectScriptParameters,
        contractValue: totalEstimatedContract,
        me
      })
    };
  }

  return {
    id: null,
    description: null,
    displayText: null,
    parameters: [],
    scripts: []
  };
};

export const useProjectRevenue = (projectUri, me) => {
  const { data, loading, refetch } = useQuery(getProjectRevenueQuery, {
    variables: { projectId: projectUri },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });
  const {
    startDate,
    endDate,
    totalEstimatedContract,
    revenueContractDetails
  } = get(data, 'project', {});

  const { isLoading, revenuePolicies } = useProjectRevenuePolicy({
    projectStartDate: startDate,
    projectEndDate: endDate,
    contractValue: totalEstimatedContract
  });

  return {
    loading: loading || isLoading,
    refetchRevenuePolicy: refetch,
    revenue:
      loading || isLoading
        ? {}
        : mapToProjectRevenue({
            revenueContractDetails,
            clauses: revenuePolicies,
            totalEstimatedContract,
            me
          }),
    revenuePolicies: isLoading ? [] : revenuePolicies
  };
};

export default useProjectRevenue;
