import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const projectResourcesFragment = gql`
  fragment ProjectResources on Project {
    id
    resources(
      searchPhrase: $searchPhrase
      allocationStatusList: $allocationStatusList
    ) {
      totalItems
      items {
        id
        uri
        slug
        displayText
        isEnabled
        projectRoles {
          isPrimary
          projectRole {
            uri
            name
          }
        }
      }
    }
  }
`;

export const projectResourcesQuery = gql`
  query Eager_projectResourcesQuery(
    $projectSlug: String
    $projectId: String
    $searchPhrase: String
    $allocationStatusList: [ResourceAllocationStatus!]
  ) {
    project(projectSlug: $projectSlug, projectId: $projectId) {
      ...ProjectResources
    }
  }
  ${projectResourcesFragment}
`;

const useProjectResources = ({
  projectSlug,
  projectId,
  searchPhrase = '',
  allocationStatusList
}) => {
  const { loading, error, data } = useQuery(projectResourcesQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectSlug,
      projectId,
      searchPhrase,
      allocationStatusList
    }
  });

  return {
    loading,
    resources:
      data && data.project
        ? data.project.resources
        : { items: [], totalItems: 0 },
    error
  };
};

export default useProjectResources;
