import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import React, { useMemo, useCallback } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { NumberTextField } from '~/modules/common/components';
import { useExpenseScriptFormatter } from '~/modules/projects/project/BillPlanV2/hooks/useExpenseScriptFormatter';
import { ExpenseEntryType } from '~/types';
import { useExpensesCardContext } from '../ExpensesCardContext';

const useStyles = makeStyles(theme => ({
  input: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize
  },
  percentageLabel: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  root: {
    minWidth: theme.spacing(7.5),
    maxWidth: theme.spacing(10)
  }
}));

export const MarkupEditor = ({
  column: { id: columnId, setFieldValue, hasMaxVal, errors, isMobile, label },
  field,
  record,
  index,
  classes: classesOverride
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride, isMobile });
  const markup = useMemo(
    () =>
      record[field] ? parseFloat((record[field] * 100 - 100).toFixed(2)) : null,
    [record, field]
  );
  const inputClasses = useMemo(() => ({ input: classes.input }), [
    classes.input
  ]);

  const values = useExpensesCardContext();

  const { onMarkupChangeHandler } = useExpenseScriptFormatter({
    values,
    setFieldValue
  });

  const onChangeHandler = useCallback(
    newPercentage => {
      onMarkupChangeHandler({
        columnId,
        newPercentage,
        record
      });
    },
    [columnId, onMarkupChangeHandler, record]
  );

  const maxVal = useMemo(
    () => (hasMaxVal ? 999.99 : hasMaxVal ? 100 : undefined),
    [hasMaxVal]
  );
  const isVisible =
    record.expenseCode.expenseEntryType === ExpenseEntryType.Billable ||
    record.expenseCode.expenseEntryType ===
      ExpenseEntryType.BillableAndNonBillable;

  return isVisible ? (
    <NumberTextField
      align="left"
      variant="standard"
      fullWidth={isMobile}
      classes={inputClasses}
      endAdornment={
        <InputAdornment position="start">
          <span className={classes.percentageLabel}>%</span>
        </InputAdornment>
      }
      ariaLabel={intl.formatMessage({
        id: 'billPlan.markup'
      })}
      min={0}
      max={maxVal}
      className={classes.root}
      step="any"
      precision={2}
      onChange={onChangeHandler}
      value={markup}
      hiddenLabel={!isMobile}
      label={label}
    />
  ) : null;
};

MarkupEditor.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  index: PropTypes.number,
  field: PropTypes.string.isRequired
};

export default MarkupEditor;
