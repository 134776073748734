import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import Decimal from '../Decimal';
import AbbreviatedDecimal from '../AbbreviatedDecimal';

const useStyles = makeStyles(theme => ({
  dateWithDeltaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ({ disablePadding }) => (disablePadding ? 0 : '0.2rem 0.5rem'),
    borderRadius: '5px',
    minWidth: '1.8rem'
  },
  negative: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light
  },
  zero: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[200]
  },
  positive: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light
  },
  deltaLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: 'unset'
  },
  tooltip: {}
}));

const hasDecimal = number => {
  const remainder = number - Math.floor(number);

  return remainder > 0;
};

const getDeltaColors = ({ classes, reverseColors, delta }) =>
  reverseColors
    ? classNames({
        [classes.positive]: delta < 0,
        [classes.zero]: delta === 0,
        [classes.negative]: delta > 0
      })
    : classNames({
        [classes.negative]: delta < 0,
        [classes.zero]: delta === 0,
        [classes.positive]: delta > 0
      });

export const Delta = ({
  delta,
  classes: classesOverride,
  className,
  precision = hasDecimal(delta) ? 2 : 0,
  showAbbreviatedValue = false,
  disablePadding,
  disableTooltip,
  reverseColors,
  suffix,
  tooltipTitle = null,
  tooltipPlacement,
  prefix
}) => {
  const classes = useStyles({ classes: classesOverride, disablePadding });
  const tooltipClasses = useMemo(
    () => ({
      tooltip: classes.tooltip
    }),
    [classes.tooltip]
  );
  const isPositive = delta > 0;
  const deltaColors = getDeltaColors({ classes, reverseColors, delta });
  const Component = showAbbreviatedValue ? AbbreviatedDecimal : Decimal;

  const deltaValue = (
    <Typography className={classes.deltaLabel} variant="body2">
      {isPositive && '+'}
      <Component
        prefix={prefix}
        value={delta}
        precision={precision}
        suffix={suffix}
      />
    </Typography>
  );

  return (
    <div
      className={classNames(
        classes.dateWithDeltaContainer,
        deltaColors,
        className
      )}
    >
      {disableTooltip ? (
        deltaValue
      ) : (
        <Tooltip
          classes={tooltipClasses}
          placement={tooltipPlacement}
          title={tooltipTitle || deltaValue}
        >
          {deltaValue}
        </Tooltip>
      )}
    </div>
  );
};

Delta.propTypes = {
  delta: PropTypes.number.isRequired,
  classes: PropTypes.object,
  precision: PropTypes.number,
  className: PropTypes.string,
  showAbbreviatedValue: PropTypes.bool,
  reverseColors: PropTypes.bool,
  disablePadding: PropTypes.bool,
  disableTooltip: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  tooltipTitle: PropTypes.any,
  tooltipPlacement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top'
  ])
};

export default Delta;
