import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SmartFitAllocationMode } from '~/types';
import { useMeContext } from '~/modules/me';
import {
  mapIsoStringtoUtcObject,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';
import AssignmentDialogOptionItem from './AssignmentDialogOptionItem';

const ExtendDialogOptionItem = ({ hours, endDate, handleOptionSelect }) => {
  const intl = useIntl();
  const me = useMeContext();
  const luxonFormat = getLuxonJsDateFormatFromMe(me);
  const formattedDate = mapIsoStringtoUtcObject(endDate).toFormat(luxonFormat);

  const secondary = intl.formatMessage(
    {
      id: 'resourceAssignmentDialog.allocateExtendWithoutOverAllocationScale'
    },
    { endDate: formattedDate, hours: hours.toFixed(2) }
  );
  const extendAllocationLabel = intl.formatMessage({
    id: 'resourceAssignmentDialog.extendTheAllocation'
  });

  return (
    <AssignmentDialogOptionItem
      value={SmartFitAllocationMode.Extendallocation}
      primary={extendAllocationLabel}
      secondary={secondary}
      onSelect={handleOptionSelect}
      inputProps={{
        'aria-label': extendAllocationLabel
      }}
    />
  );
};

ExtendDialogOptionItem.propTypes = {
  hours: PropTypes.number,
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleOptionSelect: PropTypes.func
};

export default ExtendDialogOptionItem;
