import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(() => ({
  column: {
    whiteSpace: 'break-spaces',
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
}));

export const RevenueRecognitionPolicyFormatter = ({ field, record }) => {
  const classes = useStyles();

  return record[field] && record[field].displayText ? (
    <Typography className={classes.column} variant="body2">
      {record[field].displayText}
    </Typography>
  ) : (
    <NoValue />
  );
};

RevenueRecognitionPolicyFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default RevenueRecognitionPolicyFormatter;
