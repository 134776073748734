import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import {
  ReleaseResourceAllocationType,
  ResourceAllocationStatus,
  ResourceRequestStatus,
  SpecificResourceRequestFragmentDoc
} from '~/types';
import { ALLOCATIONS_QUERY } from '~/modules/resourcing/common/enhancers/requestStatusHandlers/useReleaseResourceAllocationForRequest';
import {
  GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
  tryResourcePlanAllocatedUsersAndRolesCachedQuery
} from '~/modules/projects/resourcing-plan/ResourceRequestChart/hooks/useFetchAllocatedUsersAndRoles';
import { RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF } from '../fragments/resourceRequestsQuery';
import { RESOURCE_REQUESTS_ALLOCATIONS_QUERY } from '../../hooks/useResourceRequests';
import {
  resourceRequestTotalsFragment,
  specificResourceAllocationFragment
} from '../fragments';

export const RELEASE_RESOURCE_ALLOCATION = gql`
  mutation releaseResourceAllocations(
    $input: BulkReleaseResourceAllocationsInput!
  ) {
    bulkReleaseResourceAllocations(input: $input) {
      resourceAllocations {
        ...SpecificResourceAllocation
      }
      resourceRequests {
        ...SpecificResourceRequest
        ...ResourceRequestTotalsFragment
      }
    }
  }
  ${specificResourceAllocationFragment}
  ${SpecificResourceRequestFragmentDoc}
  ${resourceRequestTotalsFragment}
`;

export const getFilteredAllocatedUserAndRolesList = ({
  allocatedUsersAndRoles,
  releasedAllocationsIds,
  userId
}) =>
  allocatedUsersAndRoles.reduce((acc, userObj) => {
    if (userObj.user.id !== userId) {
      acc.push(userObj);

      return acc;
    }

    const filteredRoles = (userObj.roles || [])
      .map(role => {
        const updatedRole = { ...role };

        updatedRole.resourceAllocationReference = role.resourceAllocationReference.filter(
          // eslint-disable-next-line max-nested-callbacks
          reference => !releasedAllocationsIds.includes(reference.id)
        );

        return updatedRole.resourceAllocationReference.length > 0
          ? updatedRole
          : null;
      })
      .filter(Boolean);

    if (filteredRoles.length > 0) {
      acc.push({ ...userObj, roles: filteredRoles });
    }

    return acc;
  }, []);
export const tryLoadCachedAllocationQuery = ({ proxy, variables }) => {
  try {
    return proxy.readQuery({
      query: ALLOCATIONS_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

export const updateResourceRequestQueryCache = ({
  proxy,
  resourceAllocations,
  resourceRequests,
  resourceRequestCacheUpdateProps: {
    projectId,
    resourceRequestsQueryVariables,
    showHolidays,
    showTimeOff
  }
}) => {
  if (resourceRequests.length === 0) return;
  const cacheQuery =
    showHolidays || showTimeOff
      ? RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
      : RESOURCE_REQUESTS_ALLOCATIONS_QUERY;

  const cacheVariables = resourceRequestsQueryVariables || {
    projectUri: projectId,
    limit: 500,
    cursor: null
  };

  try {
    const cachedData = proxy.readQuery({
      query: cacheQuery,
      variables: cacheVariables
    });

    if (cachedData) {
      const { resourceRequests: data } = cachedData;
      const cachedResourceRequests = data.resourceRequests;

      proxy.writeQuery({
        query: cacheQuery,
        variables: cacheVariables,
        data: {
          resourceRequests: {
            ...data,
            resourceRequests: resourceRequests.reduce(
              (acc, resourceRequest) => {
                const cachedRequest = acc.find(
                  request => request.id === resourceRequest.id
                );

                if (cachedRequest) {
                  const newResourceRequest = {
                    ...cachedRequest,
                    resourceAllocations: resourceAllocations.filter(
                      allocation =>
                        allocation.resourceRequestId === resourceRequest.id
                    )
                  };

                  return acc.map(req =>
                    req.id === newResourceRequest.id ? newResourceRequest : req
                  );
                }

                if (
                  resourceRequest.requestStatus ===
                  ResourceRequestStatus.Submitted
                ) {
                  return [
                    ...acc,
                    { ...resourceRequest, resourceAllocations: [] }
                  ];
                }

                return acc;
              },
              cachedResourceRequests
            )
          }
        }
      });
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const updateResourceAllocationsQueryCache = ({
  proxy,
  resourceAllocations,
  allocatedUserCacheUpdateProps: {
    projectId,
    chartDateRange,
    showTimeOff,
    showHolidays,
    filter,
    sort
  },
  releaseType,
  releaseDate
}) => {
  const allocationQueryvariables = {
    limit: 200,
    projectUri: projectId,
    allocationStatusList: [ResourceAllocationStatus.Committed],
    chartDateRange,
    showTimeOff,
    showHolidays,
    filter,
    sort
  };

  const results = tryLoadCachedAllocationQuery({
    proxy,
    variables: allocationQueryvariables
  });

  if (!results) return;

  const {
    resourceAllocations: {
      resourceAllocations: cachedAllocations,
      nextPageCursor
    }
  } = results;

  proxy.writeQuery({
    query: ALLOCATIONS_QUERY,
    variables: allocationQueryvariables,
    data: {
      resourceAllocations: {
        resourceAllocations: cachedAllocations.filter(cachedAllocation => {
          const matchingAllocation = resourceAllocations.find(
            resourceAllocation => resourceAllocation.id === cachedAllocation.id
          );

          return (
            matchingAllocation === undefined ||
            (releaseType === ReleaseResourceAllocationType.Asof &&
              mapIsoStringtoUtcObject(matchingAllocation.startDate) <
                mapIsoStringtoUtcObject(releaseDate))
          );
        }),
        nextPageCursor,
        __typename: 'ResourceAllocationsResponse'
      }
    }
  });
};

export const getReleaseResourceSnackBarMessageId = ({
  asOfDate,
  releasedRoles
}) =>
  asOfDate
    ? releasedRoles.length === 1
      ? 'resourceAllocationChangeSnackBar.releaseResourceAsOf'
      : 'resourceAllocationChangeSnackBar.releaseResourceMultipleRolesAsOf'
    : releasedRoles.length === 1
    ? 'resourceAllocationChangeSnackBar.releaseResource'
    : 'resourceAllocationChangeSnackBar.releaseResourceMultipleRoles';

export const setSnackBarMessage = ({
  asOfDate,
  userName,
  releasedRoles,
  setSnackBarState,
  formatMessage
}) => {
  const snackBarMessageId = getReleaseResourceSnackBarMessageId({
    asOfDate: Boolean(asOfDate),
    releasedRoles
  });

  setSnackBarState({
    open: true,
    message: formatMessage(
      { id: snackBarMessageId },
      {
        username: userName,
        role:
          releasedRoles[0] ||
          formatMessage({
            id: 'resourceAllocationChangeSnackBar.noRole'
          }),
        count: releasedRoles.length,
        date: asOfDate
      }
    )
  });
};

export const updateAllocatedUsersCacheOnDelete = ({
  handleRemoveResourceAllocation,
  userId,
  userName,
  releasedResourcesInfo: {
    selectedAllocations,
    releaseType,
    releaseDate,
    formattedReleaseDate
  },
  resourcePlanAllocatedUsersAndRolesCacheVariables,
  allocatedUserCacheUpdateProps,
  resourceRequestCacheUpdateProps,
  setSnackBarState,
  formatMessage
}) => (proxy, mutationResponse) => {
  const {
    data: {
      bulkReleaseResourceAllocations: { resourceRequests, resourceAllocations }
    }
  } = mutationResponse;

  const releasedRoles = selectedAllocations.map(
    allocation => allocation.role?.displayText
  );

  if (
    releaseType === ReleaseResourceAllocationType.Asof &&
    resourceAllocations.length !== 0
  ) {
    updateResourceAllocationsQueryCache({
      proxy,
      resourceAllocations,
      allocatedUserCacheUpdateProps,
      releaseType,
      releaseDate
    });
    setSnackBarMessage({
      userName,
      releasedRoles,
      asOfDate: formattedReleaseDate,
      setSnackBarState,
      formatMessage
    });
  }

  const releasedAllocationsIdsFromInput = selectedAllocations.map(
    allocation => allocation.id
  );
  const deletedAllocationIds = releasedAllocationsIdsFromInput.filter(
    id => !resourceAllocations.some(alloc => alloc.id === id)
  );

  if (deletedAllocationIds.length === 0) return;
  const cachedResults = tryResourcePlanAllocatedUsersAndRolesCachedQuery({
    proxy,
    variables: resourcePlanAllocatedUsersAndRolesCacheVariables
  });

  if (
    cachedResults?.project.resourcePlanAllocatedUsersAndRoles.some(
      ({ user }) => user.id === userId
    )
  ) {
    const {
      project: { resourcePlanAllocatedUsersAndRoles: allocatedUsersAndRoles }
    } = cachedResults;

    const updateAllocatedUsersAndRoles = getFilteredAllocatedUserAndRolesList({
      allocatedUsersAndRoles,
      releasedAllocationsIds: deletedAllocationIds,
      userId
    });

    if (allocatedUsersAndRoles.length !== updateAllocatedUsersAndRoles.length) {
      handleRemoveResourceAllocation(userId);
    }
    setSnackBarMessage({
      userName,
      releasedRoles,
      setSnackBarState,
      formatMessage
    });

    proxy.writeQuery({
      query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
      variables: resourcePlanAllocatedUsersAndRolesCacheVariables,
      data: {
        project: {
          ...cachedResults.project,
          resourcePlanAllocatedUsersAndRoles: updateAllocatedUsersAndRoles
        }
      }
    });

    updateResourceRequestQueryCache({
      proxy,
      resourceAllocations,
      resourceRequestCacheUpdateProps,
      resourceRequests
    });
  }
};

const useBulkReleaseResourceAllocations = ({
  handleRemoveResourceAllocation,
  userId,
  resourcePlanAllocatedUsersAndRolesCacheVariables,
  allocatedUserCacheUpdateProps,
  resourceRequestCacheUpdateProps,
  userName,
  setSnackBarState,
  sendNotification
}) => {
  const [releaseAllocations, { data, loading }] = useMutation(
    RELEASE_RESOURCE_ALLOCATION,
    {
      refetchQueries: ['getProjectTotalsBySlug']
    }
  );
  const { formatMessage } = useIntl();

  const releaseResourceAllocationCallback = useCallback(
    releasedResourcesInfo =>
      releaseAllocations({
        variables: {
          input: {
            releaseType: releasedResourcesInfo.releaseType,
            allocationIds: releasedResourcesInfo.selectedAllocations.map(
              allocation => allocation.id
            ),
            releaseDate: releasedResourcesInfo.releaseDate,
            comment: releasedResourcesInfo.comment,
            sendNotification
          }
        },
        update:
          resourcePlanAllocatedUsersAndRolesCacheVariables &&
          updateAllocatedUsersCacheOnDelete({
            handleRemoveResourceAllocation,
            userId,
            userName,
            resourcePlanAllocatedUsersAndRolesCacheVariables,
            allocatedUserCacheUpdateProps,
            resourceRequestCacheUpdateProps,
            releasedResourcesInfo,
            setSnackBarState,
            formatMessage
          })
      }),
    [
      allocatedUserCacheUpdateProps,
      formatMessage,
      handleRemoveResourceAllocation,
      releaseAllocations,
      resourcePlanAllocatedUsersAndRolesCacheVariables,
      resourceRequestCacheUpdateProps,
      sendNotification,
      setSnackBarState,
      userId,
      userName
    ]
  );

  return {
    releaseResourceAllocation: releaseResourceAllocationCallback,
    data,
    loading
  };
};

export default useBulkReleaseResourceAllocations;
