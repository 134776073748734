import React from 'react';

import { useIntl } from 'react-intl';
import { SummaryCardHelp } from '~/modules/common/components';

const TasksCardHelp = () => {
  const intl = useIntl();

  const toolTipContent = content(intl);

  return <SummaryCardHelp content={toolTipContent} />;
};

const content = intl => [
  {
    title: intl.formatMessage({ id: 'tasksCardHelp.overHoursEstimate' }),
    message: intl.formatMessage({ id: 'tasksCardHelp.overHoursEstimateAlert' })
  },
  {
    title: intl.formatMessage({ id: 'tasksCardHelp.overEndDate' }),
    message: intl.formatMessage({ id: 'tasksCardHelp.overEndDateAlert' })
  }
];

export default TasksCardHelp;
