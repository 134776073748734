import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { TaskAllocationEditor } from '../ResourceAllocationPeriodEditPopover/components/ResourceAllocationEditor/TaskAllocationEditor';

const defaultOffSet = 80;
const paperProps = {
  elevation: 2
};

const useStyles = makeStyles(theme => ({
  container: {}
}));

const TaskAllocationPeriodEditPopover = ({
  classes: classesOverrides,
  allocatedHours = 0,
  anchorEl,
  availableHours = 0,
  handleAllocationPeriodClose,
  isAvailabilityLoading,
  periodDetails,
  popoverClasses,
  scheduledHours = 0,
  setNextPeriod,
  setPreviousPeriod,
  taskResourceUserAllocation,
  onAllocationChange
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const handlePeriodUpdate = useCallback(
    event => {
      handleAllocationPeriodClose(periodDetails, event);
    },
    [handleAllocationPeriodClose, periodDetails]
  );

  const style = anchorEl
    ? {
        minWidth: anchorEl.offsetWidth,
        minHeight: anchorEl.offsetHeight
      }
    : null;

  const anchorProps = useMemo(() => {
    const anchorRight =
      mapIsoStringtoUtcObject(taskResourceUserAllocation.startDate) >
      periodDetails.startDate;
    const anchorLeft =
      mapIsoStringtoUtcObject(taskResourceUserAllocation.endDate) <
      periodDetails.endDate;

    return {
      vertical: 'top',
      horizontal: anchorRight ? 'right' : anchorLeft ? 'left' : 'center'
    };
  }, [periodDetails, taskResourceUserAllocation]);

  return (
    <Popover
      id="task-allocation-period-editor"
      anchorEl={anchorEl}
      anchorOrigin={anchorProps}
      transformOrigin={anchorProps}
      open={Boolean(anchorEl)}
      onClose={handleAllocationPeriodClose}
      classes={popoverClasses}
      PaperProps={paperProps}
    >
      <div className={classes.container} style={style}>
        <TaskAllocationEditor
          handlePeriodUpdate={handlePeriodUpdate}
          handlePreviousPeriod={setPreviousPeriod}
          handleNextPeriod={setNextPeriod}
          key={periodDetails.startDate.toISO()}
          allocatedHours={allocatedHours}
          targetWidth={anchorEl ? anchorEl.offsetWidth : defaultOffSet}
          targetHeight={anchorEl ? anchorEl.offsetHeight : defaultOffSet}
          availableHours={availableHours}
          periodDetails={periodDetails}
          scheduledHours={scheduledHours}
          isAvailabilityLoading={isAvailabilityLoading}
          onAllocationChange={onAllocationChange}
        />
      </div>
    </Popover>
  );
};

TaskAllocationPeriodEditPopover.propTypes = {
  classes: PropTypes.object,
  allocatedHours: PropTypes.number,
  anchorEl: PropTypes.object,
  availableHours: PropTypes.number,
  handleAllocationPeriodClose: PropTypes.func,
  isAvailabilityLoading: PropTypes.bool,
  periodDetails: PropTypes.object,
  popoverClasses: PropTypes.object,
  scheduledHours: PropTypes.number,
  setNextPeriod: PropTypes.func,
  setPreviousPeriod: PropTypes.func,
  taskResourceUserAllocation: PropTypes.object,
  onAllocationChange: PropTypes.func
};

export default TaskAllocationPeriodEditPopover;
