import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SkillIcon = React.forwardRef((props, ref) => (
  <SvgIcon data-qe-id="SkillIcon" {...props} ref={ref}>
    <>
      <path d="M 5.990234375 3.162109375 L 3.162109375 5.990234375 L 5.63671875 8.46484375 L 8.5 5.671875 L 5.990234375 3.162109375 z M 9.525390625 6.697265625 L 6.697265625 9.525390625 L 10.1640625 12.9921875 L 13.154296875 10.326171875 L 9.525390625 6.697265625 z M 16.2421875 13.4140625 L 13.4140625 16.2421875 L 18.009765625 20.837890625 L 20.837890625 20.837890625 L 20.837890625 18.009765625 L 16.2421875 13.4140625 z " />
      <path d="M 17.98046875 2.154296875 C 17.03196363020757 2.10839524747523 15.63525413729549 2.70695445890654 14.876953125 3.462890625 C 13.83223747042197 4.507606279578031 13.50474584051572 5.982172096080703 13.857421875 7.314453125 L 2.150390625 19.021484375 L 4.98046875 21.849609375 L 16.685546875 10.142578125 C 18.01691065453846 10.49371167024528 19.49136204453566 10.16293483046434 20.53515625 9.119140625 C 21.54307196396731 8.109212100840834 22.27546615618656 5.968825531186548 21.568359375 5.26171875 L 19.474609375 7.353515625 L 17 7 L 16.646484375 4.525390625 L 18.740234375 2.431640625 C 18.56345767970336 2.254863929703363 18.29663712326414 2.169597417508257 17.98046875 2.154296875 z " />
    </>
  </SvgIcon>
));

export default SkillIcon;
