import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const CLIENTS_DROPDOWN_QUERY = gql`
  query ClientsDropdownOptions {
    activeClients {
      id
      displayText
    }
  }
`;

const clientDropdownOptionMapper = client => ({
  label: client.displayText,
  value: client.id
});

const useClientDropdownOptionsForProject = skip => {
  const { data, loading } = useQuery(CLIENTS_DROPDOWN_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip
  });

  return {
    options: loading
      ? []
      : get(data, 'activeClients', []).map(clientDropdownOptionMapper),
    loading
  };
};

export default useClientDropdownOptionsForProject;
