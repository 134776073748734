import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  AddressFieldReadOnly,
  AddressTypeReadOnly
} from '../columns/formatters/index';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2.5),
    paddingTop: index => (index !== 0 ? theme.spacing(1.5) : theme.spacing(0)),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(0.5)
  },
  addressField: {
    padding: theme.spacing(0.5),
    width: '50%'
  },
  initialRow: {
    padding: theme.spacing(0.5),
    width: '100%'
  }
}));

const ReadOnlyMobileAddressInfoListItem = ({ record, rowIndex: index }) => {
  const classes = useStyles({ index });

  return (
    <Grid className={classes.container}>
      <Grid className={classes.column}>
        <Grid className={classes.initialRow}>
          <AddressFieldReadOnly field="address" record={record} />
        </Grid>
        <Grid className={classes.initialRow}>
          <AddressFieldReadOnly field="city" record={record} />
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.addressField}>
            <AddressFieldReadOnly field="stateProvince" record={record} />
          </Grid>
          <Grid className={classes.addressField}>
            <AddressFieldReadOnly field="zipPostalCode" record={record} />
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.addressField}>
            <AddressFieldReadOnly field="country" record={record} />
          </Grid>
          <Grid className={classes.addressField}>
            <AddressTypeReadOnly field="addressType" record={record} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ReadOnlyMobileAddressInfoListItem.propTypes = {
  record: PropTypes.object,
  rowIndex: PropTypes.number
};

export default ReadOnlyMobileAddressInfoListItem;
