import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import {
  useInitialState,
  useValidationSchema
} from '~/modules/rateCard/hooks/useFormState';
import { useMeContext } from '~/modules/me/useMeContext';

export const useRateCardFormState = () => {
  const me = useMeContext();
  const intl = useIntl();
  const billPlanValidationSchema = useValidationSchema({ intl });

  const formik = useFormik({
    initialValues: useInitialState({ me }),
    enableReinitialize: true,
    validationSchema: billPlanValidationSchema
  });

  return formik;
};

export default useRateCardFormState;
