import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useBackgroundJobStatus } from '~/modules/common/hooks/useBackgroundJobStatus';
import UnderConstruction from './UnderConstruction.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8, 4, 0, 4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  title: { lineHeight: 1 },
  inProgress: {
    width: '300px',
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '150px'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    opacity: 0.5
  }
}));

export const ProjectCopyInProgress = ({
  copyProjectJobId,
  setCopyingRequests
}) => {
  const classes = useStyles();

  const { hasProcessed } = useBackgroundJobStatus({
    jobId: copyProjectJobId
  });

  useEffect(() => {
    if (hasProcessed) setCopyingRequests(false);
  }, [hasProcessed, setCopyingRequests]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          <FormattedMessage id="duplicateProject.duplicationInProgress" />
        </Typography>
        <Typography component="p" variant="body1" className={classes.message}>
          <FormattedMessage id="duplicateProject.duplicationInProgressDescription" />
        </Typography>
      </div>
      <img
        src={UnderConstruction}
        className={classes.inProgress}
        alt="Not Supported"
      />
    </div>
  );
};

ProjectCopyInProgress.propTypes = {
  copyProjectJobId: PropTypes.string.isRequired,
  setCopyingRequests: PropTypes.func.isRequired
};

export default ProjectCopyInProgress;
