import { DateTime } from 'luxon';

export const getEffectiveSchedule = schedules => {
  if (schedules.length === 0) {
    return {};
  }

  const today = DateTime.local().toObject();

  const filteredSchedules = schedules.filter(
    schedule =>
      schedule.effectiveDate &&
      DateTime.fromObject(schedule.effectiveDate) <= DateTime.local()
  );

  if (schedules.length === 1 || filteredSchedules.length === 0) {
    return schedules[0];
  }

  const sortedSchedules = filteredSchedules.sort(
    (firstSchedule, secondSchedule) =>
      DateTime.fromObject(secondSchedule.effectiveDate || today) -
      DateTime.fromObject(firstSchedule.effectiveDate || today)
  );

  return sortedSchedules[0] || {};
};
