import { DateTime } from 'luxon';
import omit from 'lodash/fp/omit';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import map from 'lodash/fp/map';
import { v4 as uuidv4 } from 'uuid';
import {
  mapIsoStringtoUtcObject,
  dateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { ResourceRequestStatus, ResourceRequestAccessLevel } from '~/types';
import { RESOURCE_SUMMARY_COUNT_QUERY } from '~/modules/resourcing/common/hooks/useResourcePlanSummaryCounts';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import resourceRequestQuery from '~/modules/resourcing/common/resourceRequestQuery';
import { makeTenantScopedUri } from '~/util';
import { projectsQuery } from '../../graphql/projectsQuery';
import { updateResourcePlansForTenantCacheSingleRequest } from './cacheUpdates';

export const generateSequence = number => {
  const appliedArray = number >= 0 ? Array(number) : Array(1);

  return Array.apply(0, appliedArray).map((item, index) => index);
};

export const getResourceRequestDatesFromProjectDate = ({
  projectEndDate,
  projectStartDate,
  scale
}) => {
  const projEndDate = projectEndDate && mapIsoStringtoUtcObject(projectEndDate);

  const today = DateTime.utc();
  const requestStartDate = DateTime.max(
    ...(projectStartDate ? [mapIsoStringtoUtcObject(projectStartDate)] : []),
    today
  );
  const requestEndDateScale = scale
    ? scale === 'days'
      ? 'weeks'
      : scale
    : 'months';
  const requestEndDate =
    projEndDate && requestStartDate < projEndDate
      ? projEndDate
      : requestStartDate
          .plus({ [requestEndDateScale]: 1 })
          .minus({ days: scale ? 1 : 0 });

  return {
    requestStartDate: mapIsoStringtoUtcObject(
      dateToMidnightUTCString(requestStartDate)
    ),
    requestEndDate: mapIsoStringtoUtcObject(
      dateToMidnightUTCString(requestEndDate)
    )
  };
};

export const omitForbiddenFields = flow(
  omit('__typename'),
  omit('startDate'),
  omit('endDate'),
  omit('location'),
  omit('division'),
  omit('serviceCenter'),
  omit('costCenter'),
  omit('employeeType'),
  omit('department'),
  omit('requestStatus'),
  omit('role'),
  omit('resourceAllocations'),
  omit('currency'),
  omit('project'),
  omit('resourcePools'),
  update(
    'scheduleRules',
    map(flow(omit('__typename'), update('dateRange', flow(omit('__typename')))))
  ),
  update('requestAttributeWeights', omit('__typename')),
  omit('exchangeRateValues'),
  omit('totalRequestedCostByCurrency'),
  omit('preferredResources'),
  omit('sourceMetadata'),
  omit('isDelaDraftResourceRequest')
);

export const appendScheduleRuleTypeNameFields = rule => ({
  ...rule,
  __typename: 'ScheduleRule',
  dateRange: {
    ...rule.dateRange,
    __typename: 'DateRange'
  }
});

const getCurrency = baseCurrency => ({
  id: baseCurrency.id,
  displayText: baseCurrency.displayText,
  __typename: 'Currency'
});

export const getRequestRoleAttributesFromRoleChangeEvent = (
  event,
  baseCurrency
) =>
  event
    ? {
        role: {
          ...event,
          currentRate: event.currentRate || null
        },
        roleUri: event.id,
        roleRate: event.currentRate ? event.currentRate.amount : 0,
        currencyUri: event.currentRate
          ? event.currentRate.currency.id
          : baseCurrency.id,
        currency: event.currentRate
          ? event.currentRate.currency
          : getCurrency(baseCurrency)
      }
    : {
        role: null,
        roleUri: null,
        currencyUri: baseCurrency.id,
        currency: baseCurrency,
        roleRate: null,
        skills: []
      };

export const getRequestDateRangeFromStartDateChangeEvent = (
  event,
  resourceRequest
) =>
  getDateRangeFromStartDateChangeEvent(event, {
    start: resourceRequest.startDate,
    end: resourceRequest.endDate
  });

export const getDateRangeFromStartDateChangeEvent = (event, { start, end }) => {
  const startDateObject =
    event && DateTime.utc(event.year, event.month, event.day, 0, 0, 0, 0);

  const isGreaterThanEndDate =
    startDateObject && end && startDateObject > DateTime.fromISO(end);

  const startDate = startDateObject && startDateObject.toISO();
  const endDate = isGreaterThanEndDate ? startDate : end;

  return { startDate, endDate };
};

export const getDateRangeFromEndDateChangeEvent = (event, { start, end }) => {
  const endDateObject =
    event && DateTime.utc(event.year, event.month, event.day, 0, 0, 0, 0);

  const isLessThanStartDate =
    endDateObject && start && endDateObject < DateTime.fromISO(start);

  const endDate = endDateObject && endDateObject.toISO();
  const startDate = isLessThanStartDate ? endDate : start;

  return { startDate, endDate };
};

export const getRequestDateRangeFromEndDateChangeEvent = (
  event,
  resourceRequest
) =>
  getDateRangeFromEndDateChangeEvent(event, {
    start: resourceRequest.startDate,
    end: resourceRequest.endDate
  });

export const replaceProjectResourceRequest = (
  existingResourceRequests,
  newRequest
) =>
  existingResourceRequests.map(sr =>
    sr.id === newRequest.id ? newRequest : sr
  );

export const appendResourceRequestFields = resourceRequest => ({
  ...resourceRequest,
  roleUri: resourceRequest.role && resourceRequest.role.id,
  locationUri: resourceRequest.location && resourceRequest.location.id,
  divisionUri: resourceRequest.division && resourceRequest.division.id,
  costCenterUri: resourceRequest.costCenter && resourceRequest.costCenter.id,
  serviceCenterUri:
    resourceRequest.serviceCenter && resourceRequest.serviceCenter.id,
  departmentUri: resourceRequest.department && resourceRequest.department.id,
  employeeTypeUri:
    resourceRequest.employeeType && resourceRequest.employeeType.id,
  skills:
    resourceRequest.skills && resourceRequest.skills.length
      ? resourceRequest.skills.map(skill => ({
          skillUri: skill.id,
          skillLevelUri: skill.skillLevel.id
        }))
      : [],
  tags:
    resourceRequest.tags && resourceRequest.tags.length
      ? resourceRequest.tags.map(({ tag, value }) => ({
          uri: tag.id,
          value: value.id
        }))
      : [],
  resourcePoolUris: (resourceRequest.resourcePools || []).map(pool => pool.id),
  preferredResourceUris: (resourceRequest.preferredResources || []).map(
    r => r.id
  )
});
const tryLoadCachedQuery = (proxy, optimistic) => {
  try {
    return proxy.readQuery(
      {
        query: RESOURCE_SUMMARY_COUNT_QUERY,
        variables: {
          groupBy: 'PROJECT',
          requestAccessLevel: ResourceRequestAccessLevel.ResourceManager
        }
      },
      optimistic
    );
  } catch (e) {
    return null;
  }
};

export const tryLoadCachedResourceRequestsQuery = ({
  proxy,
  projectUri,
  optimistic
}) => {
  try {
    return proxy.readQuery(
      {
        query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
        variables: {
          projectUri,
          limit: 500,
          cursor: null
        }
      },
      optimistic
    );
  } catch (e) {
    return null;
  }
};

export const tryLoadCachedResourceRequestQuery = ({
  proxy,
  resourceRequestId,
  optimistic
}) => {
  try {
    return proxy.readQuery(
      {
        query: resourceRequestQuery,
        variables: {
          id: resourceRequestId
        }
      },
      optimistic
    );
  } catch (e) {
    return null;
  }
};

export const tryLoadCachedProjectQuery = ({ proxy, projectId }) => {
  try {
    return proxy.readQuery({
      query: projectsQuery,
      variables: {
        projectId
      }
    });
  } catch (e) {
    return null;
  }
};

export const updateResourcePlanSummaryTabCount = (
  existingRequestStatus,
  newRequestStatus,
  proxy,
  isOptimistic,
  requestAccessLevel = ResourceRequestAccessLevel.All
) => {
  if (
    !(
      newRequestStatus === ResourceRequestStatus.Tobehired ||
      newRequestStatus === ResourceRequestStatus.Rejected ||
      newRequestStatus === ResourceRequestStatus.Submitted ||
      newRequestStatus === ResourceRequestStatus.Tentative
    )
  )
    return;

  const cachedSummaryTabCount = tryLoadCachedQuery(proxy, isOptimistic);

  if (!cachedSummaryTabCount) return;

  const {
    resourcePlansSummaryForCurrentTenant: {
      pendingCount,
      proposedCount,
      toBeHiredCount
    }
  } = cachedSummaryTabCount;

  const newSummaryTabCounts =
    existingRequestStatus === ResourceRequestStatus.Tobehired &&
    newRequestStatus === ResourceRequestStatus.Rejected
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          toBeHiredCount: toBeHiredCount - 1
        }
      : existingRequestStatus === ResourceRequestStatus.Submitted &&
        newRequestStatus === ResourceRequestStatus.Rejected
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          pendingCount: pendingCount - 1
        }
      : existingRequestStatus === ResourceRequestStatus.Submitted &&
        newRequestStatus === ResourceRequestStatus.Tobehired
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          pendingCount: pendingCount - 1,
          toBeHiredCount: toBeHiredCount + 1
        }
      : existingRequestStatus === ResourceRequestStatus.Submitted &&
        newRequestStatus === ResourceRequestStatus.Tentative
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          pendingCount: pendingCount - 1,
          proposedCount: proposedCount + 1
        }
      : existingRequestStatus === ResourceRequestStatus.Rejected &&
        newRequestStatus === ResourceRequestStatus.Submitted
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          pendingCount: pendingCount + 1
        }
      : existingRequestStatus === ResourceRequestStatus.Tentative &&
        newRequestStatus === ResourceRequestStatus.Submitted
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          pendingCount: pendingCount + 1,
          proposedCount: proposedCount - 1
        }
      : existingRequestStatus === ResourceRequestStatus.Rejected &&
        newRequestStatus === ResourceRequestStatus.Tobehired
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          toBeHiredCount: toBeHiredCount + 1
        }
      : existingRequestStatus === ResourceRequestStatus.Allocationrejected &&
        newRequestStatus === ResourceRequestStatus.Tentative
      ? {
          ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
          pendingCount: pendingCount - 1,
          proposedCount: proposedCount + 1
        }
      : cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant;

  proxy.writeQuery({
    query: RESOURCE_SUMMARY_COUNT_QUERY,
    variables: {
      groupBy: 'PROJECT',
      requestAccessLevel
    },
    data: {
      resourcePlansSummaryForCurrentTenant: {
        ...cachedSummaryTabCount.resourcePlansSummaryForCurrentTenant,
        ...newSummaryTabCounts
      }
    }
  });
};

export const buildResourceRequest = ({
  project: {
    id,
    defaultScheduleRule,
    startDate: projectStartDate,
    endDate: projectEndDate
  },
  me,
  scale,
  resourceRequestDetails = {}
}) => {
  const {
    requestStartDate,
    requestEndDate
  } = getResourceRequestDatesFromProjectDate({
    projectStartDate,
    projectEndDate,
    scale
  });
  const requestScheduleRule = {
    ...defaultScheduleRule,
    dateRange: {
      startDate: dateToMidnightUTCString(requestStartDate),
      endDate: dateToMidnightUTCString(requestEndDate)
    }
  };

  return {
    id: makeTenantScopedUri(me, 'psa-resource-request', uuidv4()),
    projectUri: id,
    requestStatus: ResourceRequestStatus.Draft,
    scheduleRules: [requestScheduleRule],
    quantity: 1,
    load: 100,
    roleRate: 0,
    currencyUri: null,
    ...appendResourceRequestFields(resourceRequestDetails)
  };
};

export const getFilteredByStatus = (values, status) =>
  values.filter(v => v === status);

export const updateCache = (
  updateFunction,
  projectId,
  responseType,
  newRequestStatus,
  existingResourceRequest,
  chartSelectedTab,
  requestAccessLevel = ResourceRequestAccessLevel.All
) => (proxy, mutationResponse) => {
  const {
    data: {
      [responseType]: { isOptimistic, resourceRequest: newRequest }
    }
  } = mutationResponse;

  updateResourcePlanSummaryTabCount(
    existingResourceRequest.requestStatus,
    newRequestStatus,
    proxy,
    isOptimistic,
    requestAccessLevel
  );

  const cachedResourceRequests = tryLoadCachedResourceRequestsQuery({
    proxy,
    projectUri: projectId,
    optimistic: isOptimistic
  });

  if (cachedResourceRequests) {
    const { resourceRequests: data } = cachedResourceRequests;

    proxy.writeQuery({
      query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
      variables: {
        projectUri: projectId,
        cursor: null
      },
      data: {
        resourceRequests: {
          ...data,
          resourceRequests: updateFunction(data.resourceRequests, newRequest)
        }
      }
    });
  }

  if (chartSelectedTab) {
    updateResourcePlansForTenantCacheSingleRequest({
      proxy,
      existingResourceRequest,
      newRequestStatus,
      chartSelectedTab,
      requestAccessLevel
    });
  }
};

export const mapToServiceRequest = ({
  request,
  responseType,
  responseTypeName,
  requestStatus,
  cacheUpdate,
  chartSelectedTab,
  requestAccessLevel = ResourceRequestAccessLevel.All,
  refetchQueries = []
}) => ({
  variables: {
    notifyOnNetworkStatusChange: true,
    input: {
      resourceRequestId: request.id
    }
  },
  context: {
    debounceKey: `${request.id}_status`,
    debounceTimeout: 100
  },
  refetchQueries,
  optimisticResponse: {
    __typename: 'Mutation',
    [responseType]: {
      __typename: responseTypeName,
      isOptimistic: true,
      resourceRequest: {
        __typename: 'ResourceRequest',
        ...request,
        requestStatus
      }
    }
  },
  update: cacheUpdate
    ? cacheUpdate(
        replaceProjectResourceRequest,
        request.projectUri,
        responseType,
        requestStatus,
        request
      )
    : updateCache(
        replaceProjectResourceRequest,
        request.projectUri,
        responseType,
        requestStatus,
        request,
        chartSelectedTab,
        requestAccessLevel
      )
});

export const checkIfResourceRequestIsReadOnly = resourceRequest =>
  Boolean(
    resourceRequest.requestStatus !== ResourceRequestStatus.Draft &&
      resourceRequest.requestStatus !== ResourceRequestStatus.Rejected
  );

export const mapToServiceRequestWithTimeoff = ({
  request,
  responseType,
  responseTypeName,
  requestStatus,
  cacheUpdate,
  chartSelectedTab,
  requestAccessLevel = ResourceRequestAccessLevel.All,
  showTimeOff,
  chartDateRange,
  showHolidays,
  refetchQueries = []
}) => {
  const requestRole = request.role
    ? {
        __typename: request.role.__typename,
        displayText: request.role.displayText,
        id: request.role.id,
        uri: request.role.id
      }
    : {};

  const allocationsWithTimeoffs = request.resourceAllocations.map(
    allocation => ({
      ...allocation,
      user: {
        ...allocation.user,
        timeoffs: allocation.user.timeoffs || [],
        holidays: allocation.user.holidays || []
      },
      role: allocation.role || requestRole
    })
  );

  const requestWithTimeoffs = {
    ...request,
    resourceAllocations: allocationsWithTimeoffs
  };

  return {
    variables: {
      notifyOnNetworkStatusChange: true,
      input: {
        resourceRequestId: requestWithTimeoffs.id
      },
      chartDateRange,
      showTimeOff,
      showHolidays
    },
    context: {
      debounceKey: `${requestWithTimeoffs.id}_status`,
      debounceTimeout: 100
    },
    optimisticResponse: {
      __typename: 'Mutation',
      [responseType]: {
        __typename: responseTypeName,
        isOptimistic: true,
        resourceRequest: {
          __typename: 'ResourceRequest',
          ...requestWithTimeoffs,
          requestStatus
        }
      }
    },
    refetchQueries,
    update: cacheUpdate
      ? cacheUpdate(
          replaceProjectResourceRequest,
          request.projectUri,
          responseType,
          requestStatus,
          request
        )
      : updateCache(
          replaceProjectResourceRequest,
          request.projectUri,
          responseType,
          requestStatus,
          request,
          chartSelectedTab,
          requestAccessLevel
        )
  };
};

export const normalizeFilter = filter => {
  const { roleUris, searchText, userIds, resourcePoolIds } = filter;

  if (roleUris || searchText || userIds || resourcePoolIds) return filter;

  const { roles, text, users, resourcePools } = filter;

  if (!roles && !text && !users && !resourcePools) {
    return {};
  }

  return {
    searchText: text,
    roleUris: roles,
    userIds: users,
    resourcePoolIds: resourcePools
  };
};
