import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import FacetDetailField from '../../FacetDetailField';
import BillingTransactionTypeIcon from './BillingTransactionTypeIcon';
import { billingTransactionTypes } from './enhancers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: 'unset',
    lineHeight: 'normal',
    padding: theme.spacing(0.5, 1.5)
  },
  label: {
    color: theme.palette.text.secondary
  }
}));

export const billingTransactionTypeLabel = (
  <FormattedMessage id="billingTransactionTypeFacet.title" />
);

export const mapSelectedOptions = options =>
  options.map(value => {
    const { name } = billingTransactionTypes.find(x => x.id === value);

    return { key: name, value };
  });

const onChange = ({ setSelected, selectedOptions }) =>
  setSelected(
    (Array.isArray(selectedOptions)
      ? [...selectedOptions]
      : [selectedOptions]
    ).filter(value => Boolean(value))
  );

export const BillingTransactionTypeFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const classes = useStyles();
  const handleChange = useCallback(
    (_, types) =>
      onChange({ setSelected, selectedOptions: mapSelectedOptions(types) }),
    [setSelected]
  );

  const toggleValue = selected.map(({ value }) => value);

  return (
    <FacetDetailField label={billingTransactionTypeLabel} className={className}>
      <ToggleButtonGroup
        exclusive={false}
        value={toggleValue}
        onChange={handleChange}
      >
        {billingTransactionTypes.map(billingTransactionType => (
          <ToggleButton
            key={billingTransactionType.id}
            value={billingTransactionType.id}
            classes={classes}
          >
            <BillingTransactionTypeIcon
              billingTransactionTypeId={billingTransactionType.id}
            />
            <FormattedMessage id={billingTransactionType.displayText} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FacetDetailField>
  );
};

BillingTransactionTypeFacetDetails.propTypes = {
  className: PropTypes.object,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default BillingTransactionTypeFacetDetails;
