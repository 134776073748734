import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  dialogTitle: {},
  userName: {
    fontWeight: 'normal'
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  optionList: {
    margin: theme.spacing(0, -2)
  }
}));

export default useStyles;
