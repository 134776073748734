import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.button,
    fontSize: theme.typography.caption.fontSize,
    textAlign: 'center',
    width: '100%'
  },
  part: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1.3
  },
  value: {
    fontWeight: theme.typography.fontWeightBold
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  },
  circularProgress: { color: theme.palette.common.black }
}));

const SummaryTabLabel = ({
  value,
  label,
  showValue = true,
  isValueLoading = false,
  dataQeId
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {showValue && (typeof value === 'number' || value) ? (
        <span
          className={classNames(classes.part, classes.value)}
          data-qe-id={dataQeId && `${dataQeId}_Value`}
        >
          {isValueLoading ? (
            <CircularProgress size={10} className={classes.circularProgress} />
          ) : typeof value !== 'number' ? (
            value
          ) : (
            value || '–'
          )}
        </span>
      ) : null}
      <span
        className={classNames(classes.part, classes.label)}
        data-qe-id={dataQeId && `${dataQeId}_Label`}
      >
        {label}
      </span>
    </div>
  );
};

SummaryTabLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  label: PropTypes.node,
  showValue: PropTypes.bool,
  isValueLoading: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default SummaryTabLabel;
