import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  DEFAULT_PERIOD_SCALE,
  PERIOD_SCALE_ENUM
} from '~/modules/common/charts/timeline/periodScale';
import { UseChartStateInput, UseChartStateOutput } from '../types';

const defaultDateRange = {
  startDate: DateTime.local().startOf('month'),
  endDate: DateTime.local().endOf('month')
};

const useChartStateHandlers = ({
  showEntireProjectScaleOption = false,
  initialScale,
  initialDateRange
}: UseChartStateInput): UseChartStateOutput => {
  const [scale, setScale] = useState(
    showEntireProjectScaleOption
      ? PERIOD_SCALE_ENUM.ENTIRE_PROJECT
      : initialScale || DEFAULT_PERIOD_SCALE
  );
  const [dateRange, setDateRange] = useState(
    showEntireProjectScaleOption ? null : initialDateRange || defaultDateRange
  );

  return {
    scale,
    setScale,
    dateRange,
    setDateRange
  };
};

export default useChartStateHandlers;
