import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  BillingOverviewHeaderRow,
  BillingOverviewValueRow,
  AvailableToBillValueRowGroup
} from './components';

export const AvailableToBillGroup = ({
  availableToBill,
  displayTotalColumn
}) => {
  const [availableToBillingExpanded, setAvailableToBillingExpanded] = useState(
    false
  );

  return (
    <>
      <BillingOverviewHeaderRow>
        <FormattedMessage id="billingOverviewTable.billable" />
      </BillingOverviewHeaderRow>
      <BillingOverviewValueRow
        rowData={availableToBill.billableHours}
        rowTitle="billingOverviewTable.billableHours"
        displayTotalColumn={displayTotalColumn}
      />
      <AvailableToBillValueRowGroup
        sectionData={availableToBill}
        expanded={availableToBillingExpanded}
        onExpandChange={setAvailableToBillingExpanded}
        displayTotalColumn={displayTotalColumn}
      />
    </>
  );
};

AvailableToBillGroup.propTypes = {
  availableToBill: PropTypes.object,
  displayTotalColumn: PropTypes.bool
};

export default AvailableToBillGroup;
