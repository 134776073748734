import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ListItemText } from '@material-ui/core';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { ExpenseEntryType } from '~/types';
import { useExpenseScriptFormatter } from '~/modules/projects/project/BillPlanV2/hooks/useExpenseScriptFormatter';
import { useExpensesCardContext } from '../ExpensesCardContext';

const EXPENSE_ENTRY_TYPE_KEYS = {
  BILLABLE: 'billable',
  NON_BILLABLE: 'nonBillable',
  BILLABLE_AND_NON_BILLABLE: 'billableAndNonBillable'
};

const useStyles = makeStyles(theme => ({
  root: { width: theme.spacing(20) },
  inputRoot: {
    backgroundColor: 'transparent !important',
    padding: '0 !important',
    '&::before': {
      borderBottom: 'unset !important'
    },
    '& .MuiAutocomplete-input': {
      paddingLeft: '0 !important'
    },
    '& .MuiInputBase-input': {
      fontSize: theme.typography.body2.fontSize
    }
  },
  popper: {
    minWidth: theme.spacing(24)
  },
  option: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0, 2)
  }
}));

const useTextStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize
  }
}));
const getOptionLabel = o => o.displayText;
const getOptionSelected = (o, v) => o.uri === v.uri;

const getOptions = ({ formatMessage }) => {
  const types = [
    {
      uri: ExpenseEntryType.Billable,
      displayText: formatMessage({
        id: 'expenseEntryOptions.billable'
      })
    },
    {
      uri: ExpenseEntryType.NonBillable,
      displayText: formatMessage({
        id: 'expenseEntryOptions.nonBillable'
      })
    },
    {
      uri: ExpenseEntryType.BillableAndNonBillable,
      displayText: formatMessage({
        id: 'expenseEntryOptions.billableAndNonBillable'
      })
    }
  ];

  return types;
};
const getValue = ({ record, formatMessage }) => {
  const type =
    record?.expenseCode?.expenseEntryType || ExpenseEntryType.NonBillable;
  const key = EXPENSE_ENTRY_TYPE_KEYS[type];

  return {
    uri: type,
    displayText: formatMessage({
      id: 'expenseEntryOptions.'.concat(key)
    })
  };
};

export const BillableTypeFormatter = ({
  record,
  index,
  column: { setFieldValue, editable }
}) => {
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const textClasses = useTextStyles();

  const options = getOptions({ formatMessage });

  const value = getValue({ record, formatMessage });

  const renderOption = useCallback(
    option => (
      <ListItemText
        primary={option.displayText}
        className={textClasses.primary}
      />
    ),
    [textClasses]
  );

  const values = useExpensesCardContext();

  const { onBillableTypeChangeHandler } = useExpenseScriptFormatter({
    values,
    setFieldValue
  });

  const onBillableTypeChange = useCallback(
    billableType => {
      if (record.expenseCode.expenseEntryType === billableType.uri) {
        return;
      }
      onBillableTypeChangeHandler({ billableType, index, record });
    },
    [index, onBillableTypeChangeHandler, record]
  );

  return (
    <SimpleAutocomplete
      dataQeId="ExpenseEntryOptions"
      clearOnBlur
      value={value}
      onChange={onBillableTypeChange}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      classes={classes}
      disabled={!editable}
      disableClearable
      ariaLabel={formatMessage({
        id: 'expenseBillPlan.billableType'
      })}
    />
  );
};

BillableTypeFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  index: PropTypes.number,
  column: PropTypes.object
};

export default BillableTypeFormatter;
