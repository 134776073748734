import { useState, useCallback } from 'react';
import { ProjectManagementType } from '~/types';

export const useProjectTypeToggleProps = (
  defaultType = ProjectManagementType.Managed
) => {
  const [projectType, setProjectType] = useState(defaultType);

  const onProjectTypeClick = useCallback(
    (_, newValue) => setProjectType(newValue),
    []
  );

  return {
    projectType,
    onProjectTypeClick
  };
};
