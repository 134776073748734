import { useCallback, useMemo } from 'react';
import { useResourcingDragIndicators } from '~/modules/resourcing/hooks';
import useResourceAllocationDateRangeChangeHandler from '~/modules/resourcing/components/ResourceAssignmentDialog/components/ResourceUsersChartPanel/components/ResourceUsersChartRow/hooks/useResourceAllocationDateRangeChangeHandler';
import { compareDateObject } from '~/modules/common/dates/compare';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getResourceUserAvailabilityHoursSeriesForDateRangeCallback } from './useResourceUserAvailabilityHoursSeriesForDateRange';

export const getDragIndicatorsInChart = ({
  chartDisplayDateRange,
  resourceAllocation
}) => {
  const dragStartAfterChartStart =
    chartDisplayDateRange && resourceAllocation
      ? compareDateObject(
          chartDisplayDateRange?.startDate,
          mapIsoStringtoUtcObject(resourceAllocation.startDate)
        )
      : 1;

  const dragStartBeforeChartEnd =
    chartDisplayDateRange && resourceAllocation
      ? compareDateObject(
          chartDisplayDateRange?.endDate,
          mapIsoStringtoUtcObject(resourceAllocation.startDate)
        )
      : -1;

  const dragEndAfterChartStart =
    chartDisplayDateRange && resourceAllocation
      ? compareDateObject(
          chartDisplayDateRange?.startDate,
          mapIsoStringtoUtcObject(resourceAllocation.endDate)
        )
      : 1;
  const dragEndBeforeChartEnd =
    chartDisplayDateRange && resourceAllocation
      ? compareDateObject(
          chartDisplayDateRange?.endDate,
          mapIsoStringtoUtcObject(resourceAllocation.endDate)
        )
      : -1;

  return {
    startDragIndicator:
      dragStartAfterChartStart !== 1 && dragStartBeforeChartEnd !== -1,
    endDragIndicator:
      dragEndAfterChartStart !== 1 && dragEndBeforeChartEnd !== -1,
    allocationOutOfRange:
      (resourceAllocation &&
        dragStartBeforeChartEnd === -1 &&
        dragEndBeforeChartEnd === -1) ||
      (dragStartAfterChartStart === 1 && dragEndAfterChartStart === 1)
  };
};

export const useResourceAllocationDragIndicators = ({
  resourceAllocation,
  chartStartDate,
  scale,
  onAllocationChange,
  chartDisplayDateRange
}) => {
  const dragIndicatorsInChart = useMemo(
    () =>
      getDragIndicatorsInChart({
        chartDisplayDateRange,
        resourceAllocation
      }),
    [chartDisplayDateRange, resourceAllocation]
  );
  const {
    getResourceUserAvailabilityHoursSeriesForDateRange
  } = getResourceUserAvailabilityHoursSeriesForDateRangeCallback();

  const resourceAllocationUpdateHandler = useCallback(
    ({ entity, estimatedDate }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useResourceAllocationDateRangeChangeHandler({
        resourceAllocation: entity,
        estimatedDate,
        getResourceUserAvailabilityHoursSeriesForDateRange,
        onAllocationChange
      });
    },
    [getResourceUserAvailabilityHoursSeriesForDateRange, onAllocationChange]
  );

  const {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dates
  } = useResourcingDragIndicators({
    entity: resourceAllocation,
    chartStartDate,
    scale,
    entityUpdateHandler: resourceAllocationUpdateHandler
  });

  return {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dragIndicatorsInChart,
    dates
  };
};

export default useResourceAllocationDragIndicators;
