import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useMenuState } from '~/modules/common/hooks';
import useStyles from './useStyles';

export const SecondaryActions = ({
  qeIdTag,
  classes: classesOverrides,
  actions,
  disabled = false
}) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const menuOpen = Boolean(anchorEl);

  const intl = useIntl();
  const classes = useStyles({ classes: classesOverrides });

  const onActionClick = action => () => {
    if (action.onClick) action.onClick();
    onMenuClose();
  };

  return (
    <>
      <IconButton
        data-qe-id={`${qeIdTag}_MoreButton`}
        className={classes.secondaryActionsButton}
        onClick={onMenuClick}
        aria-label={intl.formatMessage({
          id: 'resourceRequestActions.more'
        })}
        aria-owns={menuOpen ? 'action-menu' : undefined}
        aria-haspopup="true"
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={onMenuClose}
      >
        {actions.map(action => (
          <MenuItem
            data-qe-id={`${action.label}.Option`}
            key={action.label}
            onClick={onActionClick(action)}
            disabled={action.disabled}
            className={classes[action.className]}
          >
            {action.icon && (
              <ListItemIcon className={classes[action.className]}>
                {action.icon}
              </ListItemIcon>
            )}
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SecondaryActions.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      className: PropTypes.string,
      primary: PropTypes.bool,
      disabled: PropTypes.bool
    })
  ).isRequired
};

export default SecondaryActions;
