import React, { useCallback } from 'react';
import { IconButton, Grid, makeStyles, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';
import { PropTypes } from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import ExpandLessIcon from '@material-ui/icons/ExpandLessSharp';

const useStyles = makeStyles(theme => ({
  headingContainer: {
    paddingRight: ({ editable }) =>
      editable ? theme.spacing(0) : theme.spacing(2),
    margin: 0,
    alignItems: 'center'
  },
  expandIcon: {
    color: theme.palette.text.secondary
  },
  heading: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary
  },
  secondaryHeading: {
    padding: theme.spacing(1),
    textAlign: 'right',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    '& span': {
      whiteSpace: 'nowrap'
    }
  },
  titleContainer: {
    display: 'flex',
    width: ({ editable }) => (editable ? '70%' : '90%'),
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0
  }
}));

export const ExpansionPanelTitle = ({
  editable,
  title,
  subTitle,
  onEdit,
  expanded
}) => {
  const classes = useStyles({ editable });
  const onEditHandler = useCallback(
    event => {
      onEdit();
      event && event.stopPropagation();
    },
    [onEdit]
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.headingContainer}
    >
      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      <div className={classes.titleContainer}>
        <Typography className={classes.heading}>{title}</Typography>
        {!expanded && (
          <div className={classes.secondaryHeading}>{subTitle}</div>
        )}
      </div>
      {editable && (
        <IconButton onClick={onEditHandler}>
          <EditIcon />
        </IconButton>
      )}
    </Grid>
  );
};
ExpansionPanelTitle.propTypes = {
  editable: PropTypes.bool,
  title: PropTypes.node,
  subTitle: PropTypes.node,
  onEdit: PropTypes.func,
  expanded: PropTypes.bool
};
export default ExpansionPanelTitle;
