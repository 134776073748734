import { gql } from 'graphql-tag';

export const projectGroupsFragment = gql`
  fragment ProjectGroups on Project {
    id
    costCenter {
      id
      displayText
    }
    division {
      id
      displayText
    }
    serviceCenter {
      id
      displayText
    }
    location {
      id
      displayText
    }
    department {
      id
      displayText
    }
    employeeType {
      id
      displayText
    }
  }
`;
