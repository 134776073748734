/* eslint-disable max-nested-callbacks */
import { useCallback, useEffect, useRef, useReducer } from 'react';
import deepEqual from 'fast-deep-equal';
import { useApolloClient } from '@apollo/client';
import {
  useCombinedPagination,
  sortByDisplayText
} from '~/modules/resourcing/common/hooks/useCombinedPagination';
import { useSessionStorage } from '~/modules/common/hooks';
import {
  filterChangeReducer,
  INITIAL_STATE
} from '~/modules/projects/resourcing-plan/ResourceRequestChart/filterChangeReducer';
import { getResourcePlanActualUsers } from './getResourcePlanActualUsers';
import { getResourcePlanAllocatedUsers } from './getResourcePlanAllocatedUsers';
import {
  getUntouchedAndDisplacedUsers,
  getInsertIndex,
  shouldDecrementCurrentPage,
  getMergedUserData
} from './useFetchResourcePlanUsers.util';

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const sortUserByDisplayText = order => (a, b) => {
  return sortByDisplayText(a, b, order);
};

export const GET_RESOURCE_PLAN_ALLOCATED_USERS_VARIABLES =
  'get-resource-plan-allocated-users-variables';

const getFilterInput = ({ roleUris, userIds, searchText, resourcePoolIds }) => {
  const hasFilter =
    Boolean(searchText) ||
    Boolean(roleUris) ||
    Boolean(userIds) ||
    Boolean(resourcePoolIds);

  return hasFilter
    ? {
        roles: roleUris,
        users: userIds,
        searchText,
        resourcePools: resourcePoolIds
      }
    : {};
};

export const useFetchResourcePlanUsers = ({
  projectId,
  pageSize,
  currentPage,
  filter = {},
  sort,
  setCurrentPage,
  isResourceActualModeEnabled,
  setAutofocusedUserId
}) => {
  const apolloClient = useApolloClient();
  const pageTokens = useRef({});
  const isSortByUserAscending = sort?.direction === SORT_ORDER.ASC;
  const { setValue: setVariables } = useSessionStorage(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_VARIABLES,
    null
  );

  const filterInput = getFilterInput(filter);

  const fetchActualsUsers = async page =>
    getResourcePlanActualUsers({
      page,
      pageSize,
      projectId,
      apolloClient,
      filter: filterInput,
      isSortByUserAscending
    });

  const fetchAllocatedUsers = async page => {
    const {
      allocatedUsers,
      pageTokens: updatedPageTokens
    } = await getResourcePlanAllocatedUsers({
      page,
      pageSize,
      projectId,
      pageTokens,
      filter: filterInput,
      isSortByUserAscending,
      apolloClient,
      setVariables
    });

    pageTokens.current = updatedPageTokens;

    return allocatedUsers;
  };

  const {
    loading,
    data,
    getNext,
    hasNext,
    refetch,
    reset,
    setData
  } = useCombinedPagination({
    getters: [
      ...(isResourceActualModeEnabled ? [fetchActualsUsers] : []),
      fetchAllocatedUsers
    ],
    sort: sortUserByDisplayText(sort?.direction)
  });
  const userData = loading ? {} : getMergedUserData(data);

  useEffect(() => {
    if (loading) return;
    const userLength = Object.keys(userData).length;

    if (!loading && hasNext && userLength < pageSize * currentPage) {
      getNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, data, isSortByUserAscending]);

  const [state, dispatch] = useReducer(filterChangeReducer, INITIAL_STATE);

  useEffect(() => {
    if (
      !deepEqual(state.prevFilter, filter) ||
      state.prevIsResourceActualModeEnabled !== isResourceActualModeEnabled
    ) {
      dispatch({
        type: 'FILTER_CHANGE',
        prevFilter: filter,
        prevIsResourceActualModeEnabled: isResourceActualModeEnabled
      });
      reset();
      setCurrentPage(1);
    }
  }, [
    filter,
    isResourceActualModeEnabled,
    reset,
    setCurrentPage,
    state.prevFilter,
    state.prevIsResourceActualModeEnabled
  ]);

  const handleRemoveResourceAllocation = useCallback(
    userId => {
      const updatedData = data.reduce((acc, userObj) => {
        if (userObj.id !== userId || userObj.isActualEntry) {
          acc.push(userObj);
        }

        return acc;
      }, []);

      setData(updatedData);
    },
    [data, setData]
  );

  const handleResourceAllocationUserChange = useCallback(
    ({ oldUser, newUsers }) => {
      newUsers.sort(sortByDisplayText);
      setAutofocusedUserId(newUsers[0].uri);
      const { untouchedUsers, displacedUsers } = getUntouchedAndDisplacedUsers({
        data,
        oldUser,
        newUsers
      });

      const insertIndex = getInsertIndex({
        currentPage,
        pageSize,
        untouchedUsers
      });

      untouchedUsers.splice(insertIndex, 0, ...displacedUsers);

      if (shouldDecrementCurrentPage({ currentPage, pageSize, insertIndex }))
        setCurrentPage(currentPage - 1);
      setData(untouchedUsers);
    },
    [setData, currentPage, pageSize, setCurrentPage, data, setAutofocusedUserId]
  );

  return {
    loading,
    userData,
    getNext,
    hasNext,
    refetch,
    reset,
    handleRemoveResourceAllocation,
    handleResourceAllocationUserChange
  };
};
