import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  text: {
    transform: 'scale(1, 1)'
  }
}));

export const OverviewChartLoading = () => {
  const classes = useStyles();

  return (
    <Skeleton
      width="100%"
      height={70}
      classes={useMemo(() => ({ text: classes.text }), [classes.text])}
    />
  );
};

export default OverviewChartLoading;
