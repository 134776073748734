import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';
import { billingScheduleEntries } from './query';

export const GET_USER_BILLINGRATE_SCHEDULES = gql`
  query getPageOfUserBillingRateSchedules($projectId: String!, $page: Int!, $pageSize: Int!) {
    project(projectId: $projectId) {
      id
      pageOfUserBillingRateSchedules(page: $page, pageSize: $pageSize) {
        id
        billingRate {
          id
          displayText
          ${billingScheduleEntries}
        }
      }
    }
  }
`;
const DEFAULT_PAGE_SIZE = 10;

export const updateQuery = (
  previousResult,
  { fetchMoreResult: { project: nextResult } }
) => {
  const prevResult =
    previousResult?.project.pageOfUserBillingRateSchedules || [];
  const nextResults = nextResult.pageOfUserBillingRateSchedules || [];

  return {
    project: {
      ...previousResult.project,
      pageOfUserBillingRateSchedules: [...prevResult, ...nextResults]
    }
  };
};
const useUserBillingRateSchedules = ({
  projectId,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { loading, data, fetchMore, variables } = useQuery(
    GET_USER_BILLINGRATE_SCHEDULES,
    {
      variables: {
        page,
        pageSize,
        projectId
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const pageOfUserBillingRateSchedules = get(
    data,
    'project.pageOfUserBillingRateSchedules',
    []
  );
  const hasMoreRows =
    pageOfUserBillingRateSchedules &&
    pageOfUserBillingRateSchedules.length !== 0 &&
    pageOfUserBillingRateSchedules.length % variables.pageSize === 0;

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows || isLoadingMore) {
      return;
    }
    setIsLoadingMore(true);
    try {
      await fetchMore({
        query: GET_USER_BILLINGRATE_SCHEDULES,
        variables: {
          ...variables,
          page: pageOfUserBillingRateSchedules.length / variables.pageSize + 1
        },
        updateQuery
      });
    } finally {
      setIsLoadingMore(false);
    }
  }, [
    hasMoreRows,
    isLoadingMore,
    fetchMore,
    variables,
    pageOfUserBillingRateSchedules
  ]);

  return {
    loading,
    hasMoreRows,
    isLoadingMore,
    loadMoreRows,
    userBillingRateSchedules: pageOfUserBillingRateSchedules
  };
};

export default useUserBillingRateSchedules;
