import { gql } from 'graphql-tag';

export const GET_AVAILABLE_TAX_PROFILE_QUERY = gql`
  query getAvailableTaxProfiles(
    $page: Int!
    $pageSize: Int!
    $textSearch: String
  ) {
    pageOfAvailableTaxProfiles(
      page: $page
      pageSize: $pageSize
      textSearch: $textSearch
    ) {
      id
      displayText
      description
    }
  }
`;
