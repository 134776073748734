import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ProgressChart } from '~/modules/common/charts/dashboard';
import { getProgressCostChartData } from '~/modules/projects/project/common/util';

const resourceKeyOverrides = {
  workRemaining: 'dashboardProgressChart.costRemaining'
};

export const ProgressCostChart = ({
  projectId,
  endDate,
  summary,
  projectCurrency
}) => {
  const progressChartProps = useMemo(() => ({ ...summary, projectCurrency }), [
    projectCurrency,
    summary
  ]);

  const {
    budget,
    actual,
    estimatedAtCompletion,
    estimatedBudgetRemaining,
    budgetRemaining,
    estimatedRemaining,
    percentBudgetBurned,
    percentScopeCompleted
  } = getProgressCostChartData({
    progressChartProps
  });

  return (
    <>
      <ProgressChart
        projectId={projectId}
        endDate={endDate}
        budget={budget}
        actual={actual}
        estimatedAtCompletion={estimatedAtCompletion}
        estimatedBudgetRemaining={estimatedBudgetRemaining}
        budgetRemaining={budgetRemaining}
        estimatedRemaining={estimatedRemaining}
        percentBudgetBurned={percentBudgetBurned}
        percentScopeCompleted={percentScopeCompleted}
        burnedAlertMessage="dashboardProgressChart.costBurnedAlert"
        burnedId="dashboardProgressChart.costBurned"
        scopeCompletedAlertMessage="dashboardProgressChart.scopeCompletedAlert"
        scopeCompletedId="dashboardProgressChart.costScopeCompleted"
        fixedDecimalScale
        prefix={`${projectCurrency.displayText} `}
        dataQeId="ProgressCostChart"
        resourceKeyOverrides={resourceKeyOverrides}
      />
    </>
  );
};

ProgressCostChart.propTypes = {
  projectId: PropTypes.string,
  endDate: PropTypes.string,
  projectCurrency: PropTypes.object,
  summary: PropTypes.object
};

export default ProgressCostChart;
