import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { MoneyValue, NoValue } from '~/modules/common/components';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    color: ({ hasError }) => (hasError ? theme.palette.error.dark : 'inherit'),
    fontSize: theme.typography.caption.fontSize,
    fontWeight: ({ hasError }) => (hasError ? 500 : 'inherit')
  },
  total: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    marginRight: theme.spacing(0.5)
  }
}));

export const MilestoneTotalAmount = ({ field, record }) => {
  const isMobile = useIsBreakpointDown('sm');
  const intl = useIntl();
  const { amount, currency, contractAmount } = record[field] || {};
  const money = useMemo(
    () => ({ amount: getRoundedValue(amount, 2), currency }),
    [amount, currency]
  );
  const hasError = useMemo(() => amount !== contractAmount, [
    amount,
    contractAmount
  ]);
  const classes = useStyles({ hasError });

  return (
    <div className={classes.root}>
      {isMobile ? (
        <Typography variant="caption" className={classes.total}>
          {intl.formatMessage({
            id: 'billPlan.totalAmount'
          })}
        </Typography>
      ) : null}
      {money ? <MoneyValue money={money} /> : <NoValue />}
    </div>
  );
};

MilestoneTotalAmount.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default MilestoneTotalAmount;
