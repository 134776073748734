import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { DateField } from '~/modules/common/components';
import { compareDateObjects } from '~/modules/common/dates/compare';

const useStyles = makeStyles(theme => ({
  range: {
    display: 'flex'
  },
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    maxWidth: theme.spacing(25)
  },
  divider: {
    padding: theme.spacing(1, 2)
  }
}));

export const DateRangeEditor = ({
  column: { id: columnId, setFieldValue, errors, label, isMobile },
  field,
  index,
  record,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { startDate, endDate } = record[field] || {};

  const onStartDateChangeHandler = useCallback(
    newDate => {
      if (!isEmpty(endDate) && compareDateObjects(newDate, endDate) === 1) {
        setFieldValue(`scripts[${index}].${columnId}.endDate`, newDate);
      }
      setFieldValue(`scripts[${index}].${columnId}.startDate`, newDate);
    },
    [columnId, index, setFieldValue, endDate]
  );

  const onEndDateChangeHandler = useCallback(
    newDate => {
      if (!isEmpty(startDate) && compareDateObjects(startDate, newDate) === 1) {
        setFieldValue(`scripts[${index}].${columnId}.startDate`, newDate);
      }
      setFieldValue(`scripts[${index}].${columnId}.endDate`, newDate);
    },
    [columnId, index, setFieldValue, startDate]
  );

  const requiredError = get(errors, `parameters['${columnId}'][${index}]`);

  return (
    <div className={classes.range}>
      <DateField
        clearable={false}
        value={startDate}
        className={classes.date}
        editable
        useDateFieldStyles={false}
        onChange={onStartDateChangeHandler}
        error={Boolean(requiredError)}
        helperText={requiredError}
        label={label}
        hiddenLabel={!isMobile}
      />
      <span className={classes.divider}>&nbsp;&mdash;&nbsp;</span>
      <DateField
        clearable={false}
        value={endDate}
        className={classes.date}
        editable
        useDateFieldStyles={false}
        onChange={onEndDateChangeHandler}
        error={Boolean(requiredError)}
        helperText={requiredError}
        hiddenLabel={!isMobile}
        label={label}
      />
    </div>
  );
};

DateRangeEditor.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number
};

export default DateRangeEditor;
