import React, { memo, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { makeStyles, alpha } from '@material-ui/core/styles';
import EntityAvatar from '../EntityAvatar';
import { pickColorFromName } from './colorUtil';
import useAvatar from './useAvatar';

const useStyles = makeStyles(theme => ({
  avatar: ({ color, disabled }) => ({
    backgroundColor: alpha(color, disabled ? 0.4 : 1),
    color: theme.palette.getContrastText(color),
    fontWeight: theme.typography.fontWeightBold
  })
}));

const getDefaultUserText = user => {
  if (!user.displayText) {
    return '';
  }

  return user.displayText
    .split(',')
    .map(part => part.trim())
    .reverse()
    .reduce(
      (str, namePart) => str + (namePart.length > 0 ? namePart.charAt(0) : ''),
      ''
    );
};

const UserAvatar = forwardRef(
  ({ user, className, classes: classesOverride, disabled, ...props }, ref) => {
    const color = pickColorFromName(user.displayText);
    const classes = useStyles({ color, classes: classesOverride, disabled });
    const defaultContent = getDefaultUserText(user);
    const intl = useIntl();

    const avatarUrl = useAvatar({
      userUri: user.uri || user.id,
      size: 'medium'
    });

    const avatarAlt = intl.formatMessage(
      {
        id: 'userAvatar.altLabel'
      },
      { user: user.displayText }
    );

    const avatarProps = useMemo(
      () =>
        avatarUrl
          ? {
              src: avatarUrl
            }
          : {},
      [avatarUrl]
    );

    return (
      <EntityAvatar
        className={classnames(classes.avatar, className)}
        alt={avatarAlt}
        ref={ref}
        {...avatarProps}
        {...props}
      >
        {defaultContent}
      </EntityAvatar>
    );
  }
);

UserAvatar.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    uri: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.bool
};

export default memo(UserAvatar);
