import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RESIZE_DIRECTION_ENUM } from '~/modules/resourcing/common/enums';
import {
  DragEndIndicator,
  DragStartIndicator
} from '~/modules/resourcing/common/chart/components';

export const ResourceAllocationSummaryBlockContainer = ({
  allocationPeriod,
  children,
  classes,
  dragIndicatorDates,
  dragDelta,
  dragIndicatorClasses,
  gestureBindEvents,
  resizeDirection,
  showResizeIndicator
}) => {
  const {
    dynamicPosition: periodDynamicPostion,
    hasAllocationStart,
    hasAllocationEnd
  } = allocationPeriod;

  const dynamicPosition = useMemo(() => {
    const leftMostPeriod =
      hasAllocationStart && resizeDirection === RESIZE_DIRECTION_ENUM.START;
    const rightMostPeriod =
      hasAllocationEnd && resizeDirection === RESIZE_DIRECTION_ENUM.END;

    return {
      left: leftMostPeriod
        ? dragDelta + periodDynamicPostion.left
        : periodDynamicPostion.left,
      width:
        leftMostPeriod && dragDelta < 0
          ? periodDynamicPostion.width - dragDelta
          : rightMostPeriod
          ? periodDynamicPostion.width + dragDelta
          : periodDynamicPostion.width
    };
  }, [
    hasAllocationStart,
    resizeDirection,
    hasAllocationEnd,
    dragDelta,
    periodDynamicPostion.left,
    periodDynamicPostion.width
  ]);

  const showStartIndicator = showResizeIndicator && hasAllocationStart;

  const showEndIndicator = showResizeIndicator && hasAllocationEnd;

  return (
    <>
      <div className={classes.root} style={dynamicPosition}>
        {children}
      </div>
      <div className={classes.dragIndicators} style={dynamicPosition}>
        {showStartIndicator && (
          <DragStartIndicator
            classes={dragIndicatorClasses}
            gestureBindEvents={gestureBindEvents}
            startDate={dragIndicatorDates.startDate}
            showTooltip
          />
        )}
        {showEndIndicator && (
          <DragEndIndicator
            classes={dragIndicatorClasses}
            gestureBindEvents={gestureBindEvents}
            endDate={dragIndicatorDates.endDate}
            showTooltip
          />
        )}
      </div>
    </>
  );
};

ResourceAllocationSummaryBlockContainer.propTypes = {
  allocationPeriod: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object,
  dragIndicatorDates: PropTypes.object,
  dragDelta: PropTypes.number,
  dragIndicatorClasses: PropTypes.object,
  gestureBindEvents: PropTypes.any,
  resizeDirection: PropTypes.string,
  showResizeIndicator: PropTypes.bool
};

export default ResourceAllocationSummaryBlockContainer;
