import { useState } from 'react';

export const useBillPlanState = () => {
  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });

  return {
    batchState,
    setBatchState
  };
};

export default useBillPlanState;
