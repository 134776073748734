import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: ({ rowIndex = 0 }) => `${theme.spacing(2) + rowIndex * 26}px`,
    height: theme.spacing(3),
    userSelect: 'none',
    '&:hover $resizeHandle': {
      opacity: 1
    },
    padding: theme.spacing(0.25, 0)
  },
  container: {
    width: '100%',
    ...theme.typography.caption,
    cursor: 'default',
    borderRadius: theme.spacing(1.5),
    height: theme.spacing(2.5)
  },
  containerCommitted: {
    backgroundColor: theme.palette.resourceRequest.complete.main
  },
  containerDraft: {
    backgroundColor: theme.palette.resourceRequest.pending.main
  },
  containerProposed: {
    backgroundColor: theme.palette.resourceRequest.proposed.main
  },
  containerRejected: {
    backgroundColor: theme.palette.resourceRequest.resourceRejected.main
  },
  period: {},
  loadingLabel: {}
}));
