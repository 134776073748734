import React from 'react';
import PropTypes from 'prop-types';

export const NoProjectManager = ({ intl, className }) => (
  <span className={className}>
    {intl.formatMessage({
      id: 'projectList.noProjectManager'
    })}
  </span>
);

NoProjectManager.propTypes = {
  intl: PropTypes.object,
  className: PropTypes.string
};

export default NoProjectManager;
