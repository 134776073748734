import upperFirst from 'lodash.upperfirst';

export const userToUserRef = user =>
  user.displayText ? mapDisplayTextToUserRef(user.displayText) : { ...user };

export const mapDisplayTextToUserRef = displayText => {
  const [secondName, firstName] = displayText.split(',');

  return {
    firstName: (firstName || '').trim(),
    lastName: (secondName || '').trim(),
    name: displayText
  };
};

export const userRefToTitle = userRef =>
  `${upperFirst(userRef.lastName)}, ${upperFirst(userRef.firstName)}`;
