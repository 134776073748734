import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DragIndicatorIcon from '@material-ui/icons/DragIndicatorSharp';
import { Tooltip } from '@material-ui/core';
import useStyles from './useStyles';

const DragEndIndicator = ({
  classes: classesOverrides,
  gestureBindEvents,
  style,
  showTooltip = false,
  endDate
}) => {
  const classes = useStyles({ classes: classesOverrides });

  return (
    <Tooltip
      title={
        showTooltip && endDate
          ? endDate.toLocaleString({ month: 'short', day: 'numeric' })
          : ''
      }
    >
      <div
        className={classNames(
          classes.resizeHandle,
          classes.resizeHandleEnd,
          'dragIndicator'
        )}
        resize="end"
        style={style}
        {...(gestureBindEvents ? gestureBindEvents() : {})}
      >
        <DragIndicatorIcon className={classes.resizeIcon} />
      </div>
    </Tooltip>
  );
};

DragEndIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  gestureBindEvents: PropTypes.func,
  showTooltip: PropTypes.bool,
  endDate: PropTypes.object
};

export default DragEndIndicator;
