import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

export const FocusableMenuItem = ({
  isSelected,
  value,
  handleOnClick,
  children,
  ...props
}) => {
  return (
    <MenuItem
      selected={isSelected}
      value={value}
      onClick={handleOnClick}
      tabIndex={0}
      autoFocus={isSelected}
      {...props}
    >
      {children}
    </MenuItem>
  );
};

FocusableMenuItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  value: PropTypes.object,
  handleOnClick: PropTypes.func,
  children: PropTypes.node
};

export default FocusableMenuItem;
