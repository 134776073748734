import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Date as DateValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  asOfDate: {
    display: 'flex',
    color: theme.palette.text.secondary,
    lineHeight: 1,
    '& p': {
      lineHeight: 1,
      marginLeft: '2px'
    }
  }
}));

export const AsOfDate = ({ date }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return date ? (
    <Typography className={classes.asOfDate}>
      {formatMessage(
        { id: 'client.asOfDate' },
        { date: <DateValue value={date} /> }
      )}
    </Typography>
  ) : null;
};

AsOfDate.propTypes = {
  date: PropTypes.object
};

export default AsOfDate;
