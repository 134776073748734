import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { hasError } from '~/util';

export const TaskNameField = ({
  errors,
  name,
  nameLabel,
  onNameChange,
  readOnly,
  variant = 'filled'
}) => {
  const nameError = hasError(errors, 'nameExists') || hasError(errors, 'name');
  const nameHelperText = hasError(errors, 'nameExists') ? (
    <FormattedMessage id="projectTasksPage.taskExists" />
  ) : (
    errors.name
  );

  const inputProps = useMemo(
    () => ({
      'aria-label': nameLabel,
      'data-qe-id': 'TaskNameField_TextFieldInput'
    }),
    [nameLabel]
  );

  return (
    <TextField
      required
      variant={variant}
      autoFocus
      label={nameLabel}
      inputProps={inputProps}
      fullWidth
      value={name}
      onChange={onNameChange}
      error={nameError}
      helperText={nameHelperText}
      disabled={readOnly}
    />
  );
};

TaskNameField.propTypes = {
  nameLabel: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  errors: PropTypes.object,
  onNameChange: PropTypes.func,
  variant: PropTypes.string
};

export default TaskNameField;
