import { gql } from 'graphql-tag';
import { CustomFieldDefinitionFragmentDoc } from '~/types';

export const GET_ENABLED_CUSTOM_FIELDS_QUERY = gql`
  query getEnabledCustomFields($groupName: String) {
    enabledCustomFields(groupName: $groupName) {
      ...CustomFieldDefinition
    }
  }
  ${CustomFieldDefinitionFragmentDoc}
`;
