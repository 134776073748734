import React from 'react';
import PropTypes from 'prop-types';
import { Decimal } from '~/modules/common/components';

export const RequestDrawerAllocatedHoursToolpTitle = ({
  requestedHours,
  allocatedHours,
  intl
}) => (
  <>
    <div>
      {intl.formatMessage({
        id: 'resourceRequestDrawerDetails.totalRequestedHours'
      })}
      <Decimal
        value={requestedHours}
        suffix={intl.formatMessage({
          id: 'resourceRequestDrawerDetails.hours'
        })}
      />
    </div>
    <div>
      {intl.formatMessage({
        id: 'resourceRequestDrawerDetails.totalAllocatedHours'
      })}
      <Decimal
        value={allocatedHours}
        suffix={intl.formatMessage({
          id: 'resourceRequestDrawerDetails.hours'
        })}
      />
    </div>
  </>
);

RequestDrawerAllocatedHoursToolpTitle.propTypes = {
  requestedHours: PropTypes.number.isRequired,
  allocatedHours: PropTypes.number,
  intl: PropTypes.object.isRequired
};
export default RequestDrawerAllocatedHoursToolpTitle;
