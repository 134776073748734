import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import specificResourceFragment from './specificResourceFragment';

export const getResourceDetailsBySlugQuery = gql`
  query getResourceDetailsBySlug($userSlug: String!) {
    resource(userSlug: $userSlug) {
      ...SpecificResourceFragment
    }
  }
  ${specificResourceFragment}
`;
export const useResourceDetails = ({ userSlug }) => {
  const { data, loading, error } = useQuery(getResourceDetailsBySlugQuery, {
    variables: { userSlug }
  });
  const userDetails = data && data.resource;
  const isResourceDetailsLoading = loading;

  return {
    userDetails,
    isResourceDetailsLoading,
    error
  };
};

export default useResourceDetails;
