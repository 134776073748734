import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { toRepliconDate } from '~/modules/common/dates/convert';

export const RECONFIGURE_REVENUE_RUNS = gql`
  mutation reconfigureRevenueRuns($startDate: DateObject) {
    reconfigureRevenueRuns(startDate: $startDate)
  }
`;

export const useReconfigureRevenueRuns = () => {
  const [reconfigureRevenueRuns] = useMutation(RECONFIGURE_REVENUE_RUNS);

  return {
    reconfigureRevenueRuns: async ({ startDate }) => {
      const { data } =
        (await reconfigureRevenueRuns({
          variables: {
            startDate: startDate ? toRepliconDate(startDate) : null
          },
          refetchQueries: ['initialRevenuePeriodStartDate'],
          awaitRefetchQueries: true
        })) || {};

      return data && data.reconfigureRevenueRuns
        ? data.reconfigureRevenueRuns
        : null;
    }
  };
};

export default useReconfigureRevenueRuns;
