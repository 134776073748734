import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { useMeContext } from '~/modules/me';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { FormattedDecimal } from './FormattedDecimal';

export const Decimal = ({ dataQeId = 'Decimal', ...rest }) => {
  const me = useMeContext();

  return (
    <FormattedDecimal
      dataQeId={dataQeId}
      numberFormat={getReactNumberFormatFromMe(me)}
      {...rest}
    />
  );
};

Decimal.propTypes = {
  dataQeId: PropTypes.string
};
export default forwardRef((props, ref) => (
  <Decimal forwardedRef={ref} {...props} />
));
