import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    border: `${theme.spacing(0.5)}px solid transparent`,
    borderRightColor: theme.palette.resourceAllocation.overAllocated,
    borderTopColor: theme.palette.resourceAllocation.overAllocated,
    width: theme.spacing(1),
    height: theme.spacing(1)
  }
}));

export default useStyles;
