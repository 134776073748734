import { useCallback } from 'react';
import { onChange } from '../../enhancers/facetHelpers';
import { formatScoreValue, rangeHasValue } from './scoreUtil';

const useScoreMetricFacetDetailsHandlers = ({ setSelected }) => {
  const onChangeHandler = useCallback(
    (e, selectedOptions) =>
      onChange({
        setSelected,
        selectedOptions: rangeHasValue(selectedOptions)
          ? formatScoreValue(selectedOptions)
          : undefined
      }),
    [setSelected]
  );

  const onClearSearchHandler = useCallback(
    () =>
      onChange({
        setSelected,
        selectedOptions: undefined
      }),
    [setSelected]
  );

  return { onChangeHandler, onClearSearchHandler };
};

export default useScoreMetricFacetDetailsHandlers;
