import React from 'react';
import { ProjectIcon } from '~/modules/common/components/Icons';
import { getRoutePermissions } from '~/modules/common/permissions';

const ProjectListPage = React.lazy(() =>
  import('./components/ProjectListPage')
);

const projectsRoute = ({ intl, me }) => {
  const {
    canViewProjectRequests,
    canViewProjects,
    canViewVendors
  } = getRoutePermissions(me);

  return {
    path: '/projects',
    icon: () => <ProjectIcon />,
    isSideBarRoute: true,
    title: () => intl.formatMessage({ id: 'routes.projects' }),
    showDivider: !canViewProjectRequests && !canViewVendors,
    component: ProjectListPage,
    hasPermission: canViewProjects
  };
};

export default projectsRoute;
