import get from 'lodash.get';

const convertGroupSizes = (groupSizes = []) =>
  groupSizes.includes(2) ? 'lakh' : groupSizes.includes(4) ? 'wan' : 'thousand';

const defaultNumberSettings = {
  decimalDigits: 2,
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSizes: [3]
};

const convertNumberSettings = numberSettings => {
  const settings = {
    thousandSeparator: numberSettings.groupSeparator || ',',
    decimalSeparator: numberSettings.decimalSeparator || '.',
    thousandsGroupStyle: convertGroupSizes(numberSettings.groupSizes || [3]),
    decimalScale: numberSettings.decimalDigits
  };

  return settings;
};

export const getReactNumberFormatFromMe = me => {
  const numberSettings =
    get(me, 'locale.numberSettings.number') || defaultNumberSettings;

  return convertNumberSettings(numberSettings);
};

export const getReactMoneyFormatFromMe = me => {
  const numberSettings =
    get(me, 'locale.numberSettings.currency') || defaultNumberSettings;

  return convertNumberSettings(numberSettings);
};
