import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { ChartLegends } from '~/modules/common/charts/dashboard';
import Chart from './Chart';
import useTasksChartData from './useTasksChartData';

const useLegendStyles = makeStyles(theme => ({
  container: {
    paddingTop: 0,
    paddingRight: theme.spacing(2)
  }
}));

export const TasksStatusChart = ({ activeTasksSummary }) => {
  const legendClasses = useLegendStyles();
  const chartData = useTasksChartData({ activeTasksSummary });

  return (
    <>
      <ChartLegends legends={chartData} classes={legendClasses} />
      <Chart segments={chartData} />
    </>
  );
};

TasksStatusChart.propTypes = {
  activeTasksSummary: PropTypes.object
};

export default TasksStatusChart;
