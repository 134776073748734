import { sortByDisplayText } from '~/modules/resourcing/common/hooks/useCombinedPagination';

export const sortProjectByDisplayText = order => (a, b) => {
  return sortByDisplayText(a.project, b.project, order);
};

export const sortRoleByDisplayText = (a, b) => {
  return sortByDisplayText(a.role, b.role);
};

export const getMergedRoles = (rolesArray1 = [], rolesArray2 = []) => {
  const roles = [...rolesArray1, ...rolesArray2].sort(sortRoleByDisplayText);

  return Object.values(
    roles.reduce((accRolesMap, currRole) => {
      const roleUri = currRole.role?.id;
      const { resourceAllocationReference } = accRolesMap[roleUri] || {
        resourceAllocationReference: []
      };

      return {
        ...accRolesMap,
        [roleUri]: {
          ...currRole,
          resourceAllocationReference: [
            ...currRole.resourceAllocationReference,
            ...resourceAllocationReference
          ]
        }
      };
    }, {})
  );
};

export const getCombinedProjectDataEntry = ({
  actualsRoles,
  allocatedRoles,
  project,
  userId
}) => ({
  roles: getMergedRoles(actualsRoles, allocatedRoles),
  project,
  id: `${userId}_${project.id}`
});

export const getMergedProjectData = combinedPaginationData => {
  return (combinedPaginationData || []).reduce((acc, currentProject) => {
    const { id: projectUri } = currentProject.project;
    const { project, roles } = acc[projectUri] || {
      project: currentProject.project,
      roles: []
    };

    return {
      ...acc,
      [projectUri]: {
        project,
        roles: getMergedRoles(roles, currentProject.roles)
      }
    };
  }, {});
};

export const getInsertIndexInMergedArray = ({
  currentPage,
  pageSize,
  projectsMergedLength
}) => {
  const pageEndIndex = currentPage * pageSize - 1;

  return Math.min(projectsMergedLength, pageEndIndex);
};

export const getInsertIndex = ({ currentPage, pageSize, projects }) => {
  const projectsMerged = getMergedProjectData(projects);

  const projectsMergedLength = Object.keys(projectsMerged).length;

  const insertIndexInMergedArray = getInsertIndexInMergedArray({
    currentPage,
    pageSize,
    projectsMergedLength
  });

  if (projects.length === projectsMergedLength) return insertIndexInMergedArray;

  const projectInsert =
    projectsMerged[Object.keys(projectsMerged)[insertIndexInMergedArray - 1]];

  return (
    projects
      .map(project => project.project.id)
      .lastIndexOf(projectInsert.project.id) + 1
  );
};

const createResourceAllocationReferenceEntry = allocation => ({
  __typename: 'ResourceAllocationReference',
  id: allocation.id,
  resourceRequestIds: allocation.resourceRequestId
    ? [allocation.resourceRequestId]
    : []
});

export const addOrReplaceProjectRoleCacheEntry = ({
  roleIndex,
  projectCacheEntry,
  allocation
}) => {
  const projectHasRole = roleIndex > -1;
  const updatedRoles = [...(projectCacheEntry?.roles || [])];

  if (projectHasRole) {
    const role = updatedRoles[roleIndex];

    updatedRoles[roleIndex] = {
      ...role,
      resourceAllocationReference: [
        ...role.resourceAllocationReference,
        createResourceAllocationReferenceEntry(allocation)
      ]
    };
  } else {
    updatedRoles.push({
      role: allocation.role
        ? {
            displayText: allocation.role.displayText,
            id: allocation.role.uri
          }
        : null,
      resourceAllocationReference: [
        createResourceAllocationReferenceEntry(allocation)
      ]
    });
  }

  return updatedRoles;
};

export const getUpdatedProjectList = ({
  allocation,
  cachedProjects,
  insertIndex
}) => {
  const {
    project,
    role: allocationRole,
    user: { userUri }
  } = allocation;
  const allocatedProjectIndex = cachedProjects.findIndex(
    d => d.project.id === project.id && !d.isActualEntry
  );

  const { untouchedProjects, projectWithActuals } = cachedProjects.reduce(
    (acc, currProject) => {
      if (currProject.project.id !== project.id)
        acc.untouchedProjects.push(currProject);
      else if (currProject.isActualEntry) acc.projectWithActuals = currProject;

      return acc;
    },
    {
      untouchedProjects: [],
      projectWithActuals: null
    }
  );

  const roleIndex = cachedProjects[allocatedProjectIndex]?.roles?.findIndex(
    role => role.role?.id === allocationRole?.uri
  );

  const projectWithNewAllocations = {
    ...(allocatedProjectIndex > -1
      ? cachedProjects[allocatedProjectIndex]
      : {
          id: `${userUri}_${project.id}`,
          project: { id: project.id }
        }),
    roles: addOrReplaceProjectRoleCacheEntry({
      roleIndex,
      projectCacheEntry: cachedProjects[allocatedProjectIndex],
      allocation
    })
  };

  const displacedProjects = [
    ...(projectWithActuals ? [projectWithActuals] : []),
    projectWithNewAllocations
  ];

  untouchedProjects.splice(insertIndex, 0, ...displacedProjects);

  return untouchedProjects;
};
