import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import AddProjectDialogForm from '~/modules/common/components/AddProjectDialogForm';
import { DIALOG_FORM_TYPE } from '~/modules/common/enums/AddProjectDialogFormTypes';
import { useIsPortfolioAssignable } from '~/modules/common/hooks/portfolio';
import {
  useAddProjectForm,
  useProjectCodeSettings
} from '~/modules/common/hooks/project';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { ProjectManagementType } from '~/types';
import { ESTIMATED_COST_MAX_AMOUNT } from '~/modules/project-request/ProjectRequestPage/hooks/constants';
import { useProjectRequestPlanningData } from '../ProjectRequestDrawer/hooks/useProjectRequestPlanningData';
import { useOnCreateProjectHistoryStateProps } from './hooks';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    }
  },
  titleText: {
    padding: theme.spacing(1, 2),
    ...theme.typography.h6
  }
}));

const addProjectDialogRole = {
  role: 'presentation'
};

export const CreateProjectFromRequestDialog = ({
  open,
  onClose,
  projectRequestId,
  projectRequestName,
  portfolioId,
  portfolioName
}) => {
  const fullScreen = useIsBreakpointDown('sm');
  const classes = useStyles();
  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [
    classes.paper
  ]);

  const { startDate, endDate, estimatedCost } = useProjectRequestPlanningData({
    id: projectRequestId
  });

  const historyState = useOnCreateProjectHistoryStateProps({
    portfolioId
  });

  const { isPortfolioAssignable, loading } = useIsPortfolioAssignable({
    portfolioId,
    portfolioName
  });

  const portfolioIdFormInput = isPortfolioAssignable ? portfolioId : null;
  const {
    nextProjectCode,
    projectCodeSettingsLoading
  } = useProjectCodeSettings();

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleReset
  } = useAddProjectForm({
    customFieldDefinitions: [],
    historyState,
    onClose,
    portfolioId: portfolioIdFormInput,
    portfolioName: portfolioIdFormInput ? portfolioName : null,
    projectName: projectRequestName,
    projectType: ProjectManagementType.Managed,
    projectCode: nextProjectCode,
    replaceHistoryOnClose: true,
    type: DIALOG_FORM_TYPE.PROJECT,
    startDate,
    endDate,
    estimatedCost:
      estimatedCost?.amount < ESTIMATED_COST_MAX_AMOUNT ? estimatedCost : null
  });

  const handleOnClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  if (projectCodeSettingsLoading) return null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      classes={dialogClasses}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={addProjectDialogRole}
    >
      <DialogTitle>
        <FormattedMessage
          className={classes.titleText}
          id="projectRequest.createProjectHeading"
        />
      </DialogTitle>
      <AddProjectDialogForm
        type={DIALOG_FORM_TYPE.PROJECT}
        onCancel={handleOnClose}
        values={values}
        errors={errors}
        isSubmitting={isSubmitting}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </Dialog>
  );
};
CreateProjectFromRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectRequestId: PropTypes.string,
  projectRequestName: PropTypes.string,
  portfolioId: PropTypes.string,
  portfolioName: PropTypes.string
};

export default CreateProjectFromRequestDialog;
