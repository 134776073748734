import React from 'react';
import PropTypes from 'prop-types';
import { NoValue } from '~/modules/common/components';
import {
  FILE_TYPE_URI,
  NUMERIC_TYPE_URI,
  TAG_TYPE_URI,
  TEXT_TYPE_URI
} from '../definitionTypes';
import FileFieldText from './FileFieldText';

export const getExtensionFieldValueText = extensionFieldValue => {
  switch (extensionFieldValue.definitionTypeUri) {
    case NUMERIC_TYPE_URI:
      return extensionFieldValue.numericValue;
    case FILE_TYPE_URI:
      return (
        extensionFieldValue.fileValue && (
          <FileFieldText value={extensionFieldValue.fileValue} />
        )
      );
    case TAG_TYPE_URI:
      return extensionFieldValue.tag && extensionFieldValue.tag.displayText;
    case TEXT_TYPE_URI:
    default:
      return extensionFieldValue.textValue;
  }
};

export const ExtensionFieldValueText = ({ extensionFieldValue }) => {
  const value = getExtensionFieldValueText(extensionFieldValue);
  const hasValue = Boolean(value);

  return hasValue ? value : <NoValue />;
};

ExtensionFieldValueText.propTypes = {
  extensionFieldValue: PropTypes.object
};

export default ExtensionFieldValueText;
