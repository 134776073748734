import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import CarryOverSummaryRow from './CarryOverSummaryRow';
import { getSortedCarryOverSummaryWithTotal } from './util';

const useStyles = makeStyles(theme => ({
  root: {
    width: '300px',
    overflowY: 'visible',
    paddingTop: 0,
    marginTop: theme.spacing(-1.5)
  },
  container: {
    marginTop: theme.spacing(0.25)
  }
}));

export const CarryOverSummaryDialogContent = ({
  summaryData,
  revenuePeriodEndDate,
  project,
  onClose
}) => {
  const classes = useStyles();

  const {
    periods,
    totalPeriod,
    currencySymbol
  } = getSortedCarryOverSummaryWithTotal({
    summaryData,
    revenuePeriodEndDate
  });

  const values = useMemo(
    () => ({
      projectDisplayText: project.displayText
    }),
    [project]
  );

  return (
    <>
      <DialogContent className={classes.root}>
        <Typography variant="subtitle2">
          <FormattedMessage
            id="carryOverSummaryDialog.subTitle"
            values={values}
          />
        </Typography>
        <Grid container className={classes.container} spacing={2}>
          {periods.map(period => {
            const { endDate, amount, isCurrentPeriod, type } = period;
            const key = endDate ? endDate.toString() : 'noDate';

            return (
              <CarryOverSummaryRow
                key={key}
                endDate={endDate}
                isCurrentPeriod={isCurrentPeriod}
                amount={amount}
                currencySymbol={currencySymbol}
                type={type}
              />
            );
          })}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <CarryOverSummaryRow
            key="total"
            endDate={totalPeriod.endDate}
            amount={totalPeriod.amount}
            currencySymbol={currencySymbol}
            isTotalsRow
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="button.close" />
        </Button>
      </DialogActions>
    </>
  );
};

CarryOverSummaryDialogContent.propTypes = {
  summaryData: PropTypes.array.isRequired,
  revenuePeriodEndDate: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CarryOverSummaryDialogContent;
