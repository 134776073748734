import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { taskFragment } from '~/modules/projects/tasks/hooks/useProjectTasks';

const setTaskTimeEntryDateRangeMutation = gql`
  mutation setTaskTimeEntryDateRange(
    $taskId: String!
    $startDate: Date
    $endDate: Date
    $rescheduleTaskModificationOption: RescheduleTaskModificationOption
  ) {
    setTaskTimeEntryDateRange: setTaskTimeEntryDateRange2(
      input: {
        taskId: $taskId
        startDate: $startDate
        endDate: $endDate
        rescheduleTaskModificationOption: $rescheduleTaskModificationOption
      }
    ) {
      dummy
    }
  }
`;

const useSetTaskTimeEntryDateRangeMutation = () =>
  useMutation(setTaskTimeEntryDateRangeMutation);

export const updateCachedTaskDateRange = ({
  taskId,
  startDate,
  endDate
}) => proxy => {
  const cachedId = `Task:${taskId}`;
  const fragment = taskFragment;
  const fragmentName = 'Task';

  const variables = {
    isRolledUpTaskEstimateCalculationMethodEnabled: false,
    includeRolledUpCostSummary: false,
    isPsaPrpTaskCodeOnTaskCreationEnabled: false,
    isPsaRmpTaskAllocation1Enabled: false,
    includeHasChildrenField: false
  };

  const cachedTask = proxy.readFragment({
    id: cachedId,
    fragment,
    fragmentName,
    variables
  });

  const updatedTask = {
    ...cachedTask,
    startDate,
    endDate
  };

  proxy.writeFragment({
    id: cachedId,
    fragment,
    fragmentName,
    data: updatedTask,
    variables
  });
};

export const useSetTaskTimeEntryDateRange = () => {
  const [
    setTaskTimeEntryDateRange,
    { loading }
  ] = useSetTaskTimeEntryDateRangeMutation();

  const setTaskTimeEntryDateRangeCallback = useCallback(
    ({ taskId, startDate, endDate, rescheduleTaskModificationOption }) =>
      setTaskTimeEntryDateRange({
        variables: {
          taskId,
          startDate,
          endDate,
          rescheduleTaskModificationOption
        },
        update: updateCachedTaskDateRange({
          taskId,
          startDate,
          endDate
        })
      }),
    [setTaskTimeEntryDateRange]
  );

  return {
    setTaskTimeEntryDateRange: setTaskTimeEntryDateRangeCallback,
    loading
  };
};

export default useSetTaskTimeEntryDateRange;
