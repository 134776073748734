import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel } from '@material-ui/core';

export const CompactFormControl = ({ label, children, ...props }) => {
  const { variant, fullWidth, shrink } = props;

  return (
    <FormControl variant={variant} fullWidth={fullWidth}>
      <InputLabel shrink={shrink}>{label}</InputLabel>
      {children}
    </FormControl>
  );
};

CompactFormControl.propTypes = {
  label: PropTypes.node,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  shrink: PropTypes.bool,
  children: PropTypes.node
};

export default CompactFormControl;
