import { useState, useCallback, useMemo } from 'react';

export const useSessionStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    value => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (e) {
        // ignore
      }
    },
    [key, storedValue]
  );

  const removeValue = useCallback(() => {
    try {
      setStoredValue(null);
      window.sessionStorage.removeItem(key);
    } catch (e) {
      // ignore
    }
  }, [key]);

  return useMemo(
    () => ({
      storedValue,
      setValue,
      removeValue
    }),
    [storedValue, setValue, removeValue]
  );
};

export const getSessionStorage = (key, initialValue) => {
  const item = window.sessionStorage.getItem(key);

  return item ? JSON.parse(item) : initialValue;
};
