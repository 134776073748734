import { Button, makeStyles, Typography } from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMoreOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingLeft: theme.spacing(2)
  },
  showMoreButton: {
    margin: theme.spacing(0, -1)
  },
  paginationLimit: {
    padding: theme.spacing(1, 0.5)
  },
  icon: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0),
    fontSize: theme.typography.subtitle2.fontSize,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(0.5)
    }
  }
}));

const MAX_RECORDS = 200;

const ShowMoreRow = ({
  classes: classesOverride,
  loadMore,
  message,
  recordCount,
  resourceKey,
  showIcon = false
}) => {
  const classes = useStyles({ classes: classesOverride });
  const isPastPaginationLimit = recordCount > MAX_RECORDS;

  return (
    <div className={classes.root}>
      {isPastPaginationLimit ? (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.paginationLimit}
        >
          <FormattedMessage id={resourceKey} />
        </Typography>
      ) : (
        <Button
          className={classes.showMoreButton}
          color="primary"
          onClick={loadMore}
        >
          {message || <FormattedMessage id="button.showMore" />}
          {showIcon ? <ExpandIcon className={classes.icon} role="img" /> : null}
        </Button>
      )}
    </div>
  );
};

ShowMoreRow.propTypes = {
  loadMore: PropTypes.func.isRequired,
  recordCount: PropTypes.number.isRequired,
  resourceKey: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  message: PropTypes.node,
  classes: PropTypes.object
};

export default ShowMoreRow;
