import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TableSortLabel as MuiTableSortLabel } from '@material-ui/core';

const TableSortLabel = ({
  show,
  field,
  customFieldUri,
  children,
  onClick,
  ...rest
}) => {
  const onSortClick = useCallback(() => onClick(field, customFieldUri), [
    customFieldUri,
    field,
    onClick
  ]);

  if (!show) {
    return <>{children}</>;
  }

  return (
    <MuiTableSortLabel onClick={onSortClick} {...rest}>
      {children}
    </MuiTableSortLabel>
  );
};

TableSortLabel.propTypes = {
  show: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  ...MuiTableSortLabel.propTypes
};

export default TableSortLabel;
