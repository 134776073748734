export const scoredSuggestions = options => async phrase => {
  const values = isPromise(options) ? await options() : options;

  return values
    .map(option => ({
      subject: option.label.toLowerCase(),
      option
    }))
    .map(({ subject, option }) => ({
      score: phrase
        .toLowerCase()
        .split(' ')
        .reduce((score, term) => {
          if (score < 0) {
            return score;
          }

          const index = subject.indexOf(term);

          if (index < 0) {
            return index;
          }

          return score + term.length / (index + 1) + subject.split(term).length;
        }, 0),
      option
    }))
    .filter(scored => scored.score > 1)
    .sort((a, b) => b.score - a.score)
    .map(scored => scored.option);
};

const isPromise = value => Boolean(value && typeof value === 'function');
