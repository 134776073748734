import React from 'react';
import PropTypes from 'prop-types';
import CommentIcon from '@material-ui/icons/CommentSharp';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useStyles from './useStyles';

export const ResourceRequestCommentField = ({ value, tooltipClasses }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <>
          <div className={classes.detailsIndicatorTooltipTitle}>
            <FormattedMessage id="resourceRequestItem.comment" />
          </div>
          <div className={classes.detailsIndicatorTooltipContent}>{value}</div>
        </>
      }
      classes={tooltipClasses}
    >
      <CommentIcon className={classes.detailsIndicator} />
    </Tooltip>
  );
};

ResourceRequestCommentField.propTypes = {
  value: PropTypes.string,
  tooltipClasses: PropTypes.object
};
export default ResourceRequestCommentField;
