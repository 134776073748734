import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { recursivelyStripTypeName } from '~/util';

export const GetCertificatesQuery = gql`
  query getResourceCertificates($userSlug: String!, $includeExpired: Boolean) {
    resource(userSlug: $userSlug) {
      id
      certificates(includeExpired: $includeExpired) {
        id
        uri
        name
        issueDate
        expiryDate
        issuer
        expires
        credentialId
        credentialUrl
      }
    }
  }
`;

export const useCertificates = ({ userSlug }) => {
  const { loading: isCertificateLoading, error, data } = useQuery(
    GetCertificatesQuery,
    {
      variables: {
        userSlug,
        includeExpired: true
      },
      skip: !userSlug
    }
  );

  return {
    error,
    certificates: get(data, 'resource.certificates', []).map(certificate => ({
      ...recursivelyStripTypeName(certificate)
    })),
    isCertificateLoading
  };
};

export default useCertificates;
