import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography, makeStyles, Grid, Badge } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { MoneyValue, NoValue } from '~/modules/common/components';

const useRowStyles = makeStyles(theme => ({
  bullet: {
    backgroundColor: ({ bulletColor }) => bulletColor,
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  key: {
    color: theme.palette.text.secondary,
    fontWeight: 500
  },
  valueItem: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  value: {
    fontWeight: 500,
    color: ({ amountColor }) => amountColor
  }
}));

export const KeyValuesRow = ({
  bulletColor = 'unset',
  title,
  amount,
  amountColor = 'unset'
}) => {
  const classes = useRowStyles({ bulletColor, amountColor });

  return (
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={1}>
        <Badge overlap="rectangular">
          <div className={classes.bullet} />
        </Badge>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2" className={classes.key}>
          <FormattedMessage id={`projectRevenueRecognitionCard.${title}`} />
        </Typography>
      </Grid>
      <Grid item xs={6} className={classes.valueItem}>
        <Typography variant="body2" className={classes.value}>
          {amount ? <MoneyValue money={amount} /> : <NoValue />}
        </Typography>
      </Grid>
    </Grid>
  );
};
KeyValuesRow.propTypes = {
  bulletColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  amount: PropTypes.object,
  amountColor: PropTypes.string
};
export default KeyValuesRow;
