import { useIntl } from 'react-intl';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { RATE_TYPES } from '../enum';

export const useBillPlanSuccess = ({
  milestoneTaskCreated,
  taskAddedCount,
  ratesModifier
}) => {
  const isPsaFpAdvancedRateCardEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpAdvancedRateCardEnabled'
  });
  let successTitle = '';
  const {
    clientRates,
    rateCreated,
    ratesAddedCount,
    projectRateCount,
    specificRate
  } = ratesModifier;
  const { formatMessage } = useIntl();

  if (milestoneTaskCreated) {
    successTitle =
      taskAddedCount > 1
        ? formatMessage({
            id: 'billPlanPreRequisites.mileStoneTasksAdded'
          })
        : formatMessage({
            id: 'billPlanPreRequisites.singleMilestoneTask'
          });
    successTitle =
      taskAddedCount > 0
        ? `  ${taskAddedCount.toString()} ${successTitle}`
        : successTitle;
  }

  if (clientRates) {
    successTitle =
      ratesAddedCount > 1
        ? formatMessage({
            id: 'billPlanPreRequisites.multipleRatesAdded'
          })
        : formatMessage({
            id: 'billPlanPreRequisites.singleRateAdded'
          });
    successTitle =
      ratesAddedCount > 0
        ? `  ${ratesAddedCount.toString()} ${successTitle}`
        : successTitle;
  }

  if (rateCreated) {
    successTitle =
      projectRateCount > 1
        ? formatMessage({
            id: 'billPlanPreRequisites.multipleRatesAdded'
          })
        : formatMessage({
            id: 'billPlanPreRequisites.singleRateAdded'
          });
    let rateTypeDisplayText = '';

    switch (!isPsaFpAdvancedRateCardEnabled && specificRate) {
      case RATE_TYPES.USER:
        rateTypeDisplayText = formatMessage({
          id: 'billPlanPreRequisites.rates.resource'
        });
        break;
      case RATE_TYPES.ROLE:
        rateTypeDisplayText = formatMessage({
          id: 'billPlanPreRequisites.rates.role'
        });
        break;
      case RATE_TYPES.PROJECT:
        rateTypeDisplayText = formatMessage({
          id: 'billPlanPreRequisites.rates.project'
        });
        break;
      default:
        break;
    }
    successTitle =
      projectRateCount > 0
        ? `  ${projectRateCount.toString()} ${rateTypeDisplayText} ${successTitle}`
        : successTitle;
  }

  return {
    successTitle
  };
};

export default useBillPlanSuccess;
