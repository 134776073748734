import { IconButton, makeStyles } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircleSharp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  iconContainer: {
    padding: theme.spacing(1)
  }
}));

export const DeleteRow = ({
  classes: classesOverride,
  record,
  column: { onDeleteRow }
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const deleteHandler = useCallback(e => onDeleteRow(record), [
    onDeleteRow,
    record
  ]);

  return (
    <IconButton
      className={classes.iconContainer}
      onClick={deleteHandler}
      aria-label={formatMessage({ id: 'button.delete' })}
    >
      <RemoveIcon fontSize="small" />
    </IconButton>
  );
};

DeleteRow.propTypes = {
  classes: PropTypes.object,
  column: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

export default DeleteRow;
