import {
  StringFormatter,
  DueDateFormatter,
  PeriodFormatter
} from '~/modules/billing-invoicing/common/columnFormatters';
import { Money } from '~/modules/common/components/ListTable/renderers';
import ClientRenderer from './client';
import ActionColumnFormatter from './action-column';
import TypeRenderer from './type';
import Status from './status';
import PastDue from './past-due-days';
import RowSelectorFormatter from './RowSelectorFormatter';

export { AllRowSelectorFormatter } from './AllRowSelectorFormatter';
export default {
  rowSelector: RowSelectorFormatter,
  displayText: StringFormatter,
  pastDueDays: PastDue,
  client: ClientRenderer,
  dueDate: DueDateFormatter,
  period: PeriodFormatter,
  transactionDate: StringFormatter,
  type: TypeRenderer,
  balanceAmount: Money,
  balanceWithoutAllocationsAmount: Money,
  balanceStatus: Status,
  actionColumn: ActionColumnFormatter,
  description: StringFormatter
};
