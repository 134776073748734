import { object, string } from 'yup';
import { useIntl } from 'react-intl';

const STRING_MAX_LENGTH = 255;

export const useValidationSchema = () => {
  const intl = useIntl();

  return object().shape({
    credentialUrl: string()
      .url(
        intl.formatMessage({
          id: 'resourceCertificatesAndLicensesCard.validation.invalidUrl'
        })
      )
      .nullable(),
    name: string()
      .trim()
      .max(
        STRING_MAX_LENGTH,
        intl.formatMessage(
          {
            id:
              'resourceCertificatesAndLicensesCard.validation.fieldExceededMaxLength'
          },
          { max: STRING_MAX_LENGTH }
        )
      )
      .required(
        intl.formatMessage({
          id: 'resourceCertificatesAndLicensesCard.validation.nameRequired'
        })
      )
  });
};

export default useValidationSchema;
