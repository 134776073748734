import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    borderRadius: 0,
    margin: theme.spacing(2, 0, 0, 0)
  },
  itemRoot: {
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  totalContainer: {
    paddingTop: theme.spacing(0.5),
    alignItems: 'flex-start',
    zIndex: 4
  },
  itemContainer: {
    padding: 0,
    alignItems: 'flex-start',
    zIndex: 4
  },
  openButton: {
    display: 'none'
  }
}));
