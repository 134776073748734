import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const TASK_RESOURCE_ALLOCATION_SUMMARY_QUERY = gql`
  query getTaskResourceAllocationSummary($taskId: String!) {
    task(taskId: $taskId) {
      id
      resourceAllocationSummary {
        count
        totalAllocatedHours
      }
    }
  }
`;

export const DEFAULT_PAGE_SIZE = 10;

export const useTaskResourceAllocationSummary = ({ taskId, skip }) => {
  const { data, loading } = useQuery(TASK_RESOURCE_ALLOCATION_SUMMARY_QUERY, {
    variables: {
      taskId
    },
    skip,
    fetchPolicy: 'network-only'
  });

  return {
    loading,
    resourceAllocationSummary: data?.task?.resourceAllocationSummary
  };
};

export default useTaskResourceAllocationSummary;
