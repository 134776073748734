import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ChartHeader } from '~/modules/common/charts/timeline/components';
import { ListTableNavigation } from '~/modules/common/components/ListTable/components/DataTable/components';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    zIndex: 6,
    top: theme.spacing(19)
  }
}));

const usTableHeaderPaginationStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(9)
  }
}));

export const ResourceAllocationUserChartHeaderRow = ({
  scale,
  classes: classesOverride,
  pageSize,
  chartDates,
  loadingRows,
  currentPage,
  setCurrentPage,
  leftComponentWidth,
  resourceTotalCount,
  showTableHeaderNavigation
}) => {
  const classes = useStyles({ classes: classesOverride });
  const tableHeaderPaginationClasses = usTableHeaderPaginationStyles();

  const leftComponent = useMemo(
    () => (
      <>
        <FormattedMessage id="resourceAllocationUserChartHeaderRow.resources" />
        {showTableHeaderNavigation && !loadingRows ? (
          <ListTableNavigation
            classes={tableHeaderPaginationClasses}
            pageSize={pageSize}
            recordCount={resourceTotalCount}
            currentPage={currentPage}
            navigatePage={setCurrentPage}
          />
        ) : null}
      </>
    ),
    [
      pageSize,
      currentPage,
      loadingRows,
      setCurrentPage,
      resourceTotalCount,
      tableHeaderPaginationClasses,
      showTableHeaderNavigation
    ]
  );

  if (
    showTableHeaderNavigation &&
    !loadingRows &&
    currentPage === 1 &&
    resourceTotalCount === 0
  )
    return null;

  return (
    <ChartHeader
      leftComponent={leftComponent}
      classes={classes}
      leftComponentWidth={leftComponentWidth}
      scale={scale}
      chartDates={chartDates}
    />
  );
};

ResourceAllocationUserChartHeaderRow.propTypes = {
  scale: PropTypes.string,
  classes: PropTypes.object,
  pageSize: PropTypes.number,
  chartDates: PropTypes.array,
  loadingRows: PropTypes.bool,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  leftComponentWidth: PropTypes.number.isRequired,
  resourceTotalCount: PropTypes.number,
  showTableHeaderNavigation: PropTypes.bool
};

export default ResourceAllocationUserChartHeaderRow;
