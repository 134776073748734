import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { User as UserAvatar, NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  name: {
    color: ({ disabled }) => (disabled ? theme.palette.grey[400] : undefined)
  }
}));

export const User = ({ field, record }) => {
  const disabled = Boolean(record.disabled);
  const user = field ? record[field] : undefined;

  const classes = useStyles({ disabled });

  return user ? (
    <UserAvatar user={user} classes={classes} disabled={disabled} />
  ) : (
    <NoValue />
  );
};

User.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default User;
