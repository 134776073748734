import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const getProjectOverbudgetCount = gql`
  query getProjectOverbudgetCount(
    $projectFilter: ProjectFilter2
    $isManaged: Boolean
    $canViewProjectCostData: Boolean!
  ) {
    projectsOverbudgetCount(
      projectFilter: $projectFilter
      isManaged: $isManaged
    ) {
      eitherOverHoursOrCostBudgetCount @include(if: $canViewProjectCostData)
      overHoursBudgetCount @skip(if: $canViewProjectCostData)
      compDateOverdueCount
      total
    }
  }
`;

export default ({ canViewProjectCostData, projectFilter, isManaged }) => {
  const { data, loading } = useQuery(getProjectOverbudgetCount, {
    variables: {
      canViewProjectCostData,
      isManaged,
      projectFilter
    },
    fetchPolicy: 'network-only'
  });

  return {
    loading,
    projectsOverbudgetCount: data?.projectsOverbudgetCount || null
  };
};
