import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Percentage } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  percentage: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export const MarkupFormatter = ({ field, record }) => {
  const markup = useMemo(
    () => (record[field] ? record[field] * 100 - 100 : null),
    [record, field]
  );
  const classes = useStyles();

  return <Percentage value={markup || 0} className={classes.percentage} />;
};

MarkupFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default MarkupFormatter;
