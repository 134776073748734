import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import get from 'lodash.get';
import { Money2 } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  currencyRoot: {
    fontSize: theme.typography.body2.fontSize,
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(-1.5)
    }
  },
  adornmentRoot: {
    marginTop: '0px !important'
  },
  amountField: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    padding: theme.spacing(0.5) + 1
  }
}));

export const AmountColumn = ({
  field,
  record,
  index,
  column: {
    id,
    onAmountChange,
    onAmountBlur,
    errors = {},
    errorKey,
    ariaLabel = '',
    allowNegative = false,
    isSubmitting,
    isLineItemUpdating = false
  }
}) => {
  const classes = useStyles();

  const amountStyles = useMemo(
    () => ({
      adornmentRoot: classes.adornmentRoot,
      currencyRoot: classes.currencyRoot
    }),
    [classes.adornmentRoot, classes.currencyRoot]
  );

  const amountChangeHandler = useCallback(e => onAmountChange(e, record), [
    onAmountChange,
    record
  ]);

  const amountBlurHandler = useCallback(value => onAmountBlur(value, record), [
    onAmountBlur,
    record
  ]);

  const fieldError = useMemo(
    () => get(errors, `${errorKey}.[${index}].${id}`),
    [errorKey, errors, id, index]
  );

  const [amountInputRef, setAmountInputRef] = useState(null);
  const { hasFocus = false } = record.project || {};

  const inputRef = useCallback(
    input => {
      if (input && (hasFocus || (isSubmitting && fieldError)))
        setAmountInputRef(input);
    },
    [hasFocus, fieldError, isSubmitting]
  );

  useEffect(() => {
    if (amountInputRef) {
      amountInputRef.focus();
      setAmountInputRef(null);
    }
  }, [amountInputRef]);

  return (
    <Money2
      disabled={isLineItemUpdating}
      classes={amountStyles}
      className={classes.amountField}
      variant="filled"
      ariaLabel={ariaLabel}
      amount={record[field].amount}
      inputRef={inputRef}
      currency={record[field].currency}
      currencyEditable={false}
      error={Boolean(fieldError)}
      helperText={fieldError}
      onAmountChange={onAmountChange ? amountChangeHandler : undefined}
      onAmountBlur={onAmountBlur ? amountBlurHandler : undefined}
      allowNegative={allowNegative}
      hiddenLabel
    />
  );
};

AmountColumn.propTypes = {
  field: PropTypes.string.isRequired,
  column: PropTypes.object,
  record: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default AmountColumn;
