import { useEffect } from 'react';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const sum = (billCurrencyUri, amountFieldKey) => (retVal, item) =>
  item &&
  item[amountFieldKey] &&
  item[amountFieldKey].currency &&
  item[amountFieldKey].currency.id === billCurrencyUri
    ? retVal + item[amountFieldKey].amount
    : retVal;

export const useAssociatedCreditsTotalProps = ({
  billCurrency,
  associatedCreditMemos = [],
  associatedBillPayments = [],
  setAssociateCreditTotal
}) => {
  const { id: billCurrencyUri } = billCurrency;

  const memoTotal = getRoundedValue(
    associatedCreditMemos.reduce(sum(billCurrencyUri, 'allocatedBillAmount'), 0)
  );
  const paymentTotal = getRoundedValue(
    associatedBillPayments.reduce(
      sum(billCurrencyUri, 'allocatedBillAmount'),
      0
    )
  );

  useEffect(() => setAssociateCreditTotal(memoTotal + paymentTotal), [
    memoTotal,
    paymentTotal,
    setAssociateCreditTotal
  ]);

  return {
    associatedCreditMemoTotal: {
      amount: memoTotal,
      currency: billCurrency
    },
    associatedBillPaymentsTotal: {
      amount: paymentTotal,
      currency: billCurrency
    }
  };
};

export default useAssociatedCreditsTotalProps;
