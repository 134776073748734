import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import { BillingItemColumnOption } from '~/types';
import getFormatters from './formatters';

export const buildFooters = ({
  classes,
  editable,
  selectedColumns,
  resourceKeys = {},
  isPsaFPOvertimeBillingEnabled
}) => ({
  deleteIcon: {
    id: 'firstColumn',
    visible: editable,
    className: classes.deleteIcon
  },
  billingType: {
    id: 'billingType',
    visible: selectedColumns.includes(BillingItemColumnOption.Itemtype)
  },
  task: {
    id: 'task',
    visible: selectedColumns.includes(BillingItemColumnOption.Task)
  },
  user: {
    id: 'user',
    visible: selectedColumns.includes(BillingItemColumnOption.User)
  },
  quantity: {
    id: 'quantity',
    visible: selectedColumns.includes(BillingItemColumnOption.Unit)
  },
  rate: {
    id: 'rate',
    visible: selectedColumns.includes(BillingItemColumnOption.Rate)
  },
  timesheetPeriod: {
    id: 'timesheetPeriod',
    visible: selectedColumns.includes(BillingItemColumnOption.TimesheetPeriod)
  },
  description: {
    id: 'description',
    visible: selectedColumns.includes(BillingItemColumnOption.Description)
  },
  payCode: {
    id: 'payCode',
    visible:
      isPsaFPOvertimeBillingEnabled &&
      selectedColumns.includes(BillingItemColumnOption.Paycode)
  },
  expenseCode: {
    id: 'expenseCode',
    visible: selectedColumns.includes(BillingItemColumnOption.ExpenseCode)
  },
  role: {
    id: 'role',
    visible:
      isPsaFPOvertimeBillingEnabled &&
      selectedColumns.includes(BillingItemColumnOption.Role)
  },
  subTotal: {
    id: 'subTotal',
    visible: true,
    className: classes.subTotal,
    value: resourceKeys.aggregateLabel ? (
      <FormattedMessage id={resourceKeys.aggregateLabel} />
    ) : (
      ''
    ),
    align: 'right'
  },
  lineItemsTotal: {
    id: 'lineItemsTotal',
    visible: true,
    align: 'right',
    flip: false,
    className: classes.totalAmount
  }
});

export const useListFooters = ({
  classes,
  editable,
  selectedColumns,
  resourceKeys,
  isPsaFPOvertimeBillingEnabled
}) =>
  useColumns({
    columns: buildFooters({
      classes,
      editable,
      selectedColumns,
      resourceKeys,
      isPsaFPOvertimeBillingEnabled
    }),
    renders: getFormatters()
  });
