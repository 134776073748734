import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { List, ListItem } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import { useMeContext } from '~/modules/me/useMeContext';
import { MobileListItemRowLoading } from '~/modules/common/components/DetailsPage';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import ShowClosedTasksCheckbox from '../common/ShowClosedTasksCheckbox';
import { ProjectCard, ProjectCard2 } from './ProjectCard';
import { TaskCard2, TaskCard } from './TaskCard';
import useStyles from './useStyles';

export const TaskList = ({
  tasks,
  includeClosedTasks,
  toggleIncludeClosedTasks,
  loadMore,
  hasMore,
  loadingMore,
  editable,
  canViewEstimate,
  canViewCostData,
  projectDetails,
  rolledUpSummary,
  rolledUpCostSummary,
  isRTL
}) => {
  const me = useMeContext();
  const {
    featureFlags: {
      isPSATaskEstimateRollUpMobileViewEnabled,
      isPsaRmpTaskAllocation1Enabled,
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaPpmCostEacEnhancements2Enabled
    },
    isRolledUpTaskEstimateCalculationMethodEnabled
  } = me;
  const classes = useStyles({
    isPSATaskEstimateRollUpMobileViewEnabled
  });
  const [expandedTasks, setExpandedTasks] = useState([]);
  const history = useHistory();

  const onRowClick = useCallback(
    task => () => {
      const { id } = task;

      if (id)
        history.push({
          hash: `#/task/${id}`,
          state: {
            isProjectEditEnabled: editable
          }
        });
    },
    [history, editable]
  );

  const onProjectClick = useCallback(
    project => () => {
      const { id } = project;

      if (id)
        history.push({
          hash: `#/project/${id}`
        });
    },
    [history]
  );

  const onExpandCollapse = useCallback(
    id => e => {
      if (expandedTasks.filter(projectId => projectId === id).length) {
        setExpandedTasks(expandedTasks.filter(projectId => projectId !== id));
      } else {
        setExpandedTasks([...expandedTasks, id]);
      }
      e.stopPropagation();
    },
    [expandedTasks]
  );

  const listItemClasses = useMemo(
    () => ({
      divider: classes.divider
    }),
    [classes]
  );

  const hasTasks = !!(tasks && tasks.length > 0);

  const visibleTasks = isPSATaskEstimateRollUpMobileViewEnabled
    ? tasks.filter(task =>
        task.fullPath
          .slice(0, task.fullPath.length - 1)
          .reduce((prev, cur) => prev && expandedTasks.includes(cur.id), true)
      )
    : tasks;

  const numberFormat = getReactNumberFormatFromMe(me);
  const missingEstimatesInProject =
    isRolledUpTaskEstimateCalculationMethodEnabled &&
    Boolean(tasks?.find(task => !task.initialEstimatedHours));

  return (
    <>
      <div className={classes.showClosedTasks}>
        <ShowClosedTasksCheckbox
          checked={includeClosedTasks}
          onClick={toggleIncludeClosedTasks}
        />
      </div>
      <List component="div" dense className={classes.root}>
        {isRolledUpTaskEstimateCalculationMethodEnabled &&
          (isPsaRmpTaskAllocation1Enabled ? (
            <ListItem
              className={classNames(
                classes.listItem,
                classes.noPaddingTop,
                classes.noPaddingBottom,
                classes.project,
                classes.child0
              )}
              classes={listItemClasses}
              key={projectDetails.id}
              button
              dense
              disableGutters
              divider={false}
              onClick={onProjectClick(projectDetails)}
            >
              <ProjectCard2
                projectDetails={projectDetails}
                rolledUpSummary={rolledUpSummary}
                rolledUpCostSummary={rolledUpCostSummary}
                numberFormat={numberFormat}
                missingEstimatesInProject={missingEstimatesInProject}
                hasTasks={hasTasks}
                isRolledUpTaskEstimateCalculationMethodEnabled={
                  isRolledUpTaskEstimateCalculationMethodEnabled
                }
                isPsaPpmCostEacEnhancementsEnabled={
                  isPsaPpmCostEacEnhancementsEnabled
                }
                isPsaPpmCostEacEnhancements2Enabled={
                  isPsaPpmCostEacEnhancements2Enabled
                }
                canViewEstimate={canViewEstimate}
                canViewCostData={canViewCostData}
                isRTL={isRTL}
              />
            </ListItem>
          ) : (
            isPSATaskEstimateRollUpMobileViewEnabled && (
              <ListItem
                className={classNames(
                  classes.listItem,
                  classes.noPaddingTop,
                  classes.noPaddingBottom,
                  classes.project,
                  classes.child0
                )}
                classes={listItemClasses}
                key={projectDetails.id}
                button
                dense
                disableGutters
                divider={false}
                onClick={onProjectClick(projectDetails)}
              >
                <ProjectCard
                  projectDetails={projectDetails}
                  rolledUpSummary={rolledUpSummary}
                  hasTasks={hasTasks}
                />
              </ListItem>
            )
          ))}
        {visibleTasks.map(task => (
          <ListItem
            className={classNames(
              classes.listItem,
              classes.noPaddingTop,
              classes.noPaddingBottom,
              classes.child,
              classes[`child${task.fullPath.length - 1}`]
            )}
            classes={listItemClasses}
            key={task.id}
            button
            dense
            disableGutters
            divider={!isPSATaskEstimateRollUpMobileViewEnabled}
            onClick={onRowClick(task)}
          >
            {isPsaRmpTaskAllocation1Enabled ? (
              <TaskCard2
                canViewEstimate={canViewEstimate}
                canViewCostData={canViewCostData}
                task={task}
                numberFormat={numberFormat}
                isRolledUpTaskEstimateCalculationMethodEnabled={
                  isRolledUpTaskEstimateCalculationMethodEnabled
                }
                isPsaPpmCostEacEnhancementsEnabled={
                  isPsaPpmCostEacEnhancementsEnabled
                }
                isPsaPpmCostEacEnhancements2Enabled={
                  isPsaPpmCostEacEnhancements2Enabled
                }
                isRTL={isRTL}
              />
            ) : (
              <TaskCard
                canViewEstimate={canViewEstimate}
                task={task}
                isExpanded={expandedTasks.includes(task.id)}
                onExpandCollapse={onExpandCollapse}
              />
            )}
          </ListItem>
        ))}
      </List>
      {!loadingMore && hasMore && loadMore && (
        <Waypoint
          fireOnRapidScroll
          bottomOffset="-20%"
          onEnter={loadMore}
          key={(tasks || []).length}
        />
      )}
      {loadingMore && <MobileListItemRowLoading />}
    </>
  );
};

TaskList.propTypes = {
  tasks: PropTypes.array,
  includeClosedTasks: PropTypes.bool,
  toggleIncludeClosedTasks: PropTypes.func,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  rolledUpSummary: PropTypes.object,
  rolledUpCostSummary: PropTypes.object,
  canViewEstimate: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  projectDetails: PropTypes.object,
  isRTL: PropTypes.bool
};

export default TaskList;
