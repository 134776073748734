import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(3, 0),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
  },
  message: { paddingTop: theme.spacing(1) }
}));

const NoAvailabilityRows = ({ groupType }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">
        <FormattedMessage id={`resourceManagementList.no${groupType}`} />
      </Typography>
      <div className={classes.message}>
        <FormattedMessage id="resourceManagementList.adjustFilterMessage" />
      </div>
    </div>
  );
};

NoAvailabilityRows.propTypes = {
  groupType: PropTypes.string.isRequired
};

export default NoAvailabilityRows;
