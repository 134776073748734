import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { mapToServiceFilter } from './useBillingTransactionRows';

export const billingTotalsQuery = gql`
  query getBillingTransactionTotals($filter: BillingTransactionFilter2) {
    getBillingTransactionTotals: getBillingTransactionTotals2(filter: $filter) {
      balanceAmount {
        amount
        currency {
          symbol
        }
      }
      balanceWithoutAllocationsAmount {
        amount
        currency {
          symbol
        }
      }
    }
  }
`;

export const useBillingTransactionTotals = ({
  projectUri,
  clientUri,
  programId,
  billingTransactionFilter
}) => {
  const { data, loading } = useQuery(billingTotalsQuery, {
    variables: {
      filter: mapToServiceFilter({
        projectUri,
        clientUri,
        programUri: programId,
        billingTransactionFilter
      })
    },

    fetchPolicy: 'network-only'
  });

  const getBillingTransactionTotals = get(
    data,
    'getBillingTransactionTotals',
    null
  );

  return { totals: getBillingTransactionTotals, isTotalsLoading: loading };
};

export default useBillingTransactionTotals;
