import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { object } from 'yup';
import {
  buildCustomFieldValidationSchema,
  buildCustomFieldFormValues
} from '~/modules/customFields/utils';
import { useEnabledCustomFieldDefinitions } from '~/modules/customFields/hooks/useEnabledCustomFieldDefinitions';
import EditProjectDialog from '../EditProjectDialog';
import EditAdditionalInfoForm from './EditAdditionalInfoForm';
import { useUpdateProjectAdditionalInfo } from './useUpdateProjectAdditionalInfo';

export const buildExtensionFieldFormValues = values =>
  values.reduce((extensionFieldValues, extensionFieldValue) => {
    const {
      definition: { id }
    } = extensionFieldValue;

    return {
      ...extensionFieldValues,
      [id]: extensionFieldValue
    };
  }, {});

export const EditAdditionalInfoDialog = ({
  title,
  open,
  onClose,
  projectId,
  customFieldValues,
  extensionFieldValues,
  canViewCustomFields,
  canEditCustomFields
}) => {
  const {
    customFieldDefinitionsLoading,
    customFieldDefinitions
  } = useEnabledCustomFieldDefinitions('Project');
  const intl = useIntl();

  const initialValues = {
    projectUri: projectId,
    customFieldValues: Array.isArray(customFieldValues)
      ? buildCustomFieldFormValues(customFieldValues)
      : {},
    extensionFieldValues: Array.isArray(extensionFieldValues)
      ? buildExtensionFieldFormValues(extensionFieldValues)
      : {}
  };
  const validationSchema = customFieldDefinitions
    ? object().shape({
        customFieldValues: buildCustomFieldValidationSchema({
          customFieldDefinitions,
          intl
        })
      })
    : undefined;

  const onSubmit = useUpdateProjectAdditionalInfo({
    closeDialog: onClose,
    extensionFieldValues
  });

  return (
    !customFieldDefinitionsLoading && (
      <EditProjectDialog
        title={title}
        open={open}
        onClose={onClose}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <EditAdditionalInfoForm
          canViewCustomFields={canViewCustomFields}
          canEditCustomFields={canEditCustomFields}
        />
      </EditProjectDialog>
    )
  );
};

EditAdditionalInfoDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectId: PropTypes.string,
  customFieldValues: PropTypes.array,
  extensionFieldValues: PropTypes.array,
  canViewCustomFields: PropTypes.bool,
  canEditCustomFields: PropTypes.bool
};

export default EditAdditionalInfoDialog;
