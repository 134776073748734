import React from 'react';
import PropTypes from 'prop-types';
import { MoneyValue, NoValue } from '~/modules/common/components';

export const EstimateAmountFormatter = ({ field, record }) => {
  const money = record[field];

  return money ? <MoneyValue money={money} /> : <NoValue />;
};

EstimateAmountFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default EstimateAmountFormatter;
