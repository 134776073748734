import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

export const TagsCardEditTitle = ({ avatar, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Icon className={classes.icon}>{avatar}</Icon>
      <Typography variant="h6">{title}</Typography>
    </div>
  );
};

TagsCardEditTitle.propTypes = {
  avatar: PropTypes.node,
  title: PropTypes.node.isRequired
};

export default TagsCardEditTitle;
