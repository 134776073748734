import { Typography, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  nonBillable: {
    whiteSpace: 'nowrap'
  },
  billable: {
    paddingBottom: theme.spacing(1.2)
  }
}));

export const ExpenseRowLabelFormatter = ({
  field,
  record,
  index,
  column: { allowBillable = false }
}) => {
  const classes = useStyles();

  return (
    <>
      {allowBillable ? (
        <Typography className={classes.billable} variant="body2">
          <FormattedMessage id="expenseBillPlan.billable" />
        </Typography>
      ) : null}
      <Typography className={classes.nonBillable} variant="body2">
        <FormattedMessage id="expenseBillPlan.nonBillable" />
      </Typography>
    </>
  );
};

ExpenseRowLabelFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  index: PropTypes.number,
  column: PropTypes.object
};

export default ExpenseRowLabelFormatter;
