import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import UserAvatar from '../Avatars/UserAvatar';
import { userRefToTitle, userToUserRef } from './common';

const styles = () => ({
  user: {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: '8px',
    paddingBottom: '8px'
  }
});

const CompactUser = ({ user, className, classes }) => {
  const userRef = userToUserRef(user);
  const userNameTitle = userRefToTitle(userRef);

  return (
    <div className={classNames(classes.user, className)}>
      <Tooltip title={userNameTitle}>
        <UserAvatar user={user} size="xsmall" className={classes.userAvatar} />
      </Tooltip>
    </div>
  );
};

CompactUser.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(CompactUser);
