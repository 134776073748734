import { useCallback, useMemo, useState } from 'react';

export const useLoadMore2 = ({
  totalFetchedItems,
  fetchMore,
  loading,
  pageSize
}) => {
  const [prevTotalFetchedItems, setPrevTotalFetchedItems] = useState(0);
  const hasMore = useMemo(
    () =>
      totalFetchedItems !== 0 &&
      totalFetchedItems % pageSize === 0 &&
      prevTotalFetchedItems !== totalFetchedItems,
    [totalFetchedItems, pageSize, prevTotalFetchedItems]
  );

  const [loadingMore, setLoadingMore] = useState(false);

  const loadMore = useCallback(async () => {
    if (loading || loadingMore || !hasMore) return;
    setLoadingMore(true);
    await fetchMore({
      variables: {
        page: totalFetchedItems / pageSize + 1
      }
    });
    setPrevTotalFetchedItems(totalFetchedItems);
    setLoadingMore(false);
  }, [
    loading,
    loadingMore,
    hasMore,
    fetchMore,
    totalFetchedItems,
    setPrevTotalFetchedItems,
    pageSize
  ]);

  return {
    loadMore,
    hasMore,
    loadingMore
  };
};
export default useLoadMore2;
