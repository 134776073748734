import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Decimal, NoValue } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';

export const EstimatedDuration = ({ record }) => {
  const intl = useIntl();

  const { periodResolution, periodQuantity } = record.timelineDuration || {};

  const periodResolutionMessage = intl.formatMessage(
    { id: `projectRequest.periodDuration.${periodResolution}` },
    {
      count: periodQuantity
    }
  );

  return !isNullOrUndefined(periodQuantity) ? (
    <Typography variant="body2">
      <Decimal value={periodQuantity} precision={0} />
      {periodResolutionMessage}
    </Typography>
  ) : (
    <NoValue />
  );
};

EstimatedDuration.propTypes = {
  record: PropTypes.object.isRequired
};
