import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0)
  },
  button: {
    height: theme.spacing(4)
  }
}));

export const ResourcingDisplayUnitToggleButtonGroup = ({
  classes: classesOverrides,
  displayUnit,
  setDisplayUnit
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const handleChange = useCallback(
    (_, newDisplayUnit) => {
      newDisplayUnit && setDisplayUnit(newDisplayUnit);
    },
    [setDisplayUnit]
  );

  return (
    <ToggleButtonGroup
      value={displayUnit}
      exclusive
      onChange={handleChange}
      className={classes.root}
    >
      <ToggleButton
        data-qe-id="Toggle_Hours"
        className={classes.button}
        value={DISPLAY_UNIT_ENUM.HOURS}
      >
        h
      </ToggleButton>
      <ToggleButton
        data-qe-id="Toggle_Percentage"
        className={classes.button}
        value={DISPLAY_UNIT_ENUM.PERCENTAGE}
      >
        %
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

ResourcingDisplayUnitToggleButtonGroup.propTypes = {
  classes: PropTypes.object,
  displayUnit: PropTypes.string.isRequired,
  setDisplayUnit: PropTypes.func
};

export default ResourcingDisplayUnitToggleButtonGroup;
