/* eslint-disable react/jsx-max-depth */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NoDataItem } from '~/modules/common/components';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useIsBreakpointDown } from '../../hooks';
import { useOnFileDrop } from './hooks/useOnFileDrop';
import FileUploadList from './FileUploadList';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    alignItems: 'center'
  },
  dropzone: {
    border: `2px dashed ${theme.palette.table.border}`,
    borderRadius: 4,
    height: '92%'
  },
  dropzoneDescription: {
    display: 'flex',
    flex: '1 1 auto',
    minHeight: 150,
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: ({ showStagedFileList }) => (showStagedFileList ? '75%' : '100%'),

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  fileList: {
    alignSelf: 'center',
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxWidth: '75%',
    maxHeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& > li, ul': {
      width: '100%'
    }
  },
  noFiles: {
    justifyContent: 'center',
    width: '100%',
    display: 'flex'
  },
  button: {
    margin: theme.spacing(1, 0)
  },
  fileUploadError: {
    textAlign: 'center'
  }
}));

const FileUpload = ({
  values,
  acceptedFileTypes,
  maximumFileSize,
  onFileUpload,
  onRemoveItem,
  className,
  error,
  multiple,
  disabled,
  loading = false,
  showStagedFileList = true,
  fileTypeErrorMessageId = 'fileWebAddressDialog.invalidFileTypeError'
}) => {
  const {
    getRootProps,
    getInputProps,
    rejectedFiles,
    isFileTooLarge
  } = useOnFileDrop({
    acceptedFileTypes,
    maximumFileSizeInMB: maximumFileSize,
    onFileUpload,
    disabled,
    multiple
  });
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles({ showStagedFileList });

  const errorClasses = useMemo(() => ({ root: classes.fileUploadError }), [
    classes.fileUploadError
  ]);

  return (
    <div
      {...getRootProps({
        className: classnames(classes.dropzone, className)
      })}
    >
      <input {...getInputProps()} type="file" />
      <div className={classes.container}>
        <div className={classes.dropzoneDescription}>
          {!isMobile && (
            <>
              <Typography variant="h5" color="textSecondary">
                <FormattedMessage id="fileWebAddressDialog.dragFileHere" />
              </Typography>

              <Typography variant="caption" color="textSecondary">
                <FormattedMessage id="fileWebAddressDialog.or" />
              </Typography>
            </>
          )}
          <LoadingButton
            isLoading={loading}
            className={classes.button}
            color="primary"
            variant="contained"
          >
            <FormattedMessage id="fileWebAddressDialog.chooseFileToUpload" />
          </LoadingButton>

          {rejectedFiles.length > 0 ? (
            isFileTooLarge ? (
              <Typography variant="caption" color="error">
                <FormattedMessage id="fileWebAddressDialog.exceededMaximumFileSizeError" />
              </Typography>
            ) : (
              <Typography
                variant="caption"
                color="error"
                classes={errorClasses}
              >
                <FormattedMessage id={fileTypeErrorMessageId} />
              </Typography>
            )
          ) : (
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="fileWebAddressDialog.maximumFileSize" />
            </Typography>
          )}
        </div>
        {showStagedFileList && (
          <div className={classes.fileList}>
            {values && values.length ? (
              <FileUploadList onRemoveItem={onRemoveItem} files={values} />
            ) : (
              <div className={classes.noFiles}>
                <NoDataItem>
                  <FormattedMessage id="attachments.noFilesSelected" />
                </NoDataItem>
              </div>
            )}
          </div>
        )}
      </div>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  values: PropTypes.array,
  maximumFileSize: PropTypes.number.isRequired,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onFileUpload: PropTypes.func,
  showStagedFileList: PropTypes.bool,
  className: PropTypes.string,
  onRemoveItem: PropTypes.func,
  fileTypeErrorMessageId: PropTypes.string
};

export default FileUpload;
