import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import useStyles, { useTooltipStyles } from './useStyles';

export const GroupIconField = ({
  value,
  GroupIcon,
  groupSettings,
  labelKey
}) => {
  const classes = useStyles();
  const tooltipPopperClasses = useTooltipStyles();

  return (
    groupSettings.isEnabled && (
      <Tooltip
        title={
          <>
            <div className={classes.detailsIndicatorTooltipTitle}>
              {groupSettings.singularName ? (
                groupSettings.singularName
              ) : (
                <FormattedMessage id={labelKey} />
              )}
            </div>
            <div className={classes.detailsIndicatorTooltipContent}>
              {value.displayText}
            </div>
          </>
        }
        classes={tooltipPopperClasses}
      >
        <GroupIcon className={classes.detailsIndicator} />
      </Tooltip>
    )
  );
};

GroupIconField.propTypes = {
  value: PropTypes.object,
  groupSettings: PropTypes.object.isRequired,
  GroupIcon: PropTypes.object.isRequired,
  labelKey: PropTypes.string.isRequired
};
export default GroupIconField;
