import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  itemContainer: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    paddingLeft: 16
  },
  timeContainer: {
    minHeight: theme.spacing(3)
  },
  totalContainer: {},
  root: {
    position: 'sticky',
    top: theme.spacing(7),
    zIndex: 10
  }
}));
