import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_OVERVIEW_UNIT } from '~/modules/resource-management/common/enums';
import ResourceAllocationChart from '../ResourceAllocationChart';
import ResourcingOverviewUnitDropDown from '../ResourcingOverviewUnitDropdown';
import ResourcingCard from '../ResourcingCard';

const titleId = 'resourceChart';

export const title = (
  <span id={titleId}>
    <FormattedMessage id="resourcingOverviewChart.title" />
  </span>
);

const useContentStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

export const ResourceAllocationCard = () => {
  const [overViewUnit, setOverViewUnit] = useState(DEFAULT_OVERVIEW_UNIT);

  const action = useMemo(
    () => (
      <ResourcingOverviewUnitDropDown
        value={overViewUnit}
        onChange={setOverViewUnit}
        showPercentage
      />
    ),
    [overViewUnit, setOverViewUnit]
  );

  return (
    <ResourcingCard title={title} action={action}>
      <CardContent classes={useContentStyles()}>
        <ResourceAllocationChart
          titleId={titleId}
          overViewUnit={overViewUnit}
        />
      </CardContent>
    </ResourcingCard>
  );
};

export default ResourceAllocationCard;
