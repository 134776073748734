import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { unallocatedBillSummaryQuery } from './totalsQuery';
import {
  mapToServiceFilter,
  getConsolidatedTypeFilter
} from './useOutstandingBillTabSummary';

const summaryType = ['PAYMENT', 'CREDITMEMO'];

export const useUnallocatedTabSummary = (
  billingTransactionFilter,
  contextFilter
) => {
  const consolidatedTypeFilter =
    billingTransactionFilter.transactionTypes &&
    billingTransactionFilter.transactionTypes.length > 0
      ? getConsolidatedTypeFilter(
          billingTransactionFilter.transactionTypes,
          summaryType
        )
      : summaryType;

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const { data, loading } = useQuery(unallocatedBillSummaryQuery, {
    variables: {
      filter: mapToServiceFilter({
        contextFilter,
        billingTransactionFilter,
        consolidatedTypeFilter
      })
    },
    skip:
      isPsaFpGraphqlOptimizationEnabled || !consolidatedTypeFilter.length > 0,
    fetchPolicy: 'network-only'
  });

  const unallocatedSummary = get(data, 'getBillingTransactionTotals', {});

  return {
    loading,
    unallocatedSummary
  };
};

export default useUnallocatedTabSummary;
