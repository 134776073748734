import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles, alpha } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhotoSharp';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';
import { AvatarLoading } from '~/modules/common/components/DetailsPage';
import {
  HEADER_BAR_HEIGHT_PX,
  AVATAR_SIZE_LARGE,
  AVATAR_SIZE_SMALL
} from './constants';

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    position: 'relative',
    height: HEADER_BAR_HEIGHT_PX
  },
  avatar: {
    fontSize: theme.typography.h6.fontSize,
    width: ({ small }) => (small ? AVATAR_SIZE_SMALL : AVATAR_SIZE_LARGE),
    height: ({ small }) => (small ? AVATAR_SIZE_SMALL : AVATAR_SIZE_LARGE),
    [theme.breakpoints.down('sm')]: {
      width: AVATAR_SIZE_SMALL,
      height: AVATAR_SIZE_SMALL
    }
  },
  avatarEditButton: {
    margin: 0,
    background: alpha(theme.palette.common.black, 0.54),
    color: theme.palette.common.white,
    padding: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    '&:hover': {
      background: alpha(theme.palette.common.black, 0.75)
    },
    border: `${theme.spacing(0.25)}px solid ${theme.palette.grey[300]}`,
    width: ({ small }) => (small ? AVATAR_SIZE_SMALL : AVATAR_SIZE_LARGE),
    height: ({ small }) => (small ? AVATAR_SIZE_SMALL : AVATAR_SIZE_LARGE),
    [theme.breakpoints.down('sm')]: {
      width: AVATAR_SIZE_SMALL,
      height: AVATAR_SIZE_SMALL
    }
  }
}));

const EditUserAvatarButton = ({
  editable,
  onEditClick,
  user,
  dataQeId,
  isResourceDetailsLoading,
  small
}) => {
  const classes = useStyles({ small });
  const { formatMessage } = useIntl();

  return (
    <div data-qe-id={dataQeId} className={classes.avatarContainer}>
      {isResourceDetailsLoading ? (
        <AvatarLoading />
      ) : (
        <>
          <UserAvatar user={user} className={classes.avatar} />
          {editable && (
            <IconButton
              data-qe-id={`${dataQeId}_Edit`}
              onClick={onEditClick}
              aria-label={formatMessage({ id: 'avatarUploadDialog.title' })}
              className={classes.avatarEditButton}
            >
              <AddAPhotoIcon />
            </IconButton>
          )}
        </>
      )}
    </div>
  );
};

EditUserAvatarButton.propTypes = {
  dataQeId: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  user: PropTypes.object,
  isResourceDetailsLoading: PropTypes.bool.isRequired,
  small: PropTypes.bool
};

export default EditUserAvatarButton;
