import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const ATTACHMENTS_QUERY = gql`
  query getAttachments($objectUri: String!) {
    attachments(objectUri: $objectUri) {
      uploadUri
      fileName
      fileExtension
      fileSize
      uploadedOn
      linkUri
      type
      uploadedBy {
        id
        uri
        displayText
      }
    }
  }
`;

const useGetAttachments = ({ objectUri }) => {
  const { data, loading: isLoading, refetch } = useQuery(ATTACHMENTS_QUERY, {
    variables: { objectUri },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });

  return {
    data,
    isLoading,
    refetch
  };
};

export default useGetAttachments;
