import React from 'react';
import { DateTime } from 'luxon';
import { Tooltip, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  holiday: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[700]
  },
  today: {
    background: '#134774',
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.common.white
  },
  starWrapper: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex'
  },
  star: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[700],
    verticalAlign: 'top',
    marginLeft: theme.spacing(0.25)
  }
}));

export const ResourceCalendarDayCellContent = ({
  date,
  dayNumberText,
  isToday,
  holidays,
  viewType
}) => {
  const classes = useStyles();
  const formattedDate = DateTime.fromJSDate(date).toISODate();

  const holiday = holidays.find(
    event => event.start === formattedDate && event.end === formattedDate
  );

  const isMobile = useIsBreakpointDown('md');

  return (
    <>
      {viewType === 'dayGridMonth' && (
        <span className={classNames({ [classes.today]: isToday })}>
          {dayNumberText}
        </span>
      )}
      {holiday && (
        <Tooltip
          title={`${holiday.title}: ${holiday.effectiveDuration}`}
          placement="top-end"
        >
          {isMobile && viewType !== 'dayGrid' ? (
            <span
              className={classNames({
                [classes.starWrapper]: viewType === 'dayGridWeek'
              })}
            >
              <StarIcon className={classes.star} />
            </span>
          ) : (
            <Typography variant="caption" className={classes.holiday}>
              {holiday.title}
            </Typography>
          )}
        </Tooltip>
      )}
    </>
  );
};

export default (holidays = []) => ({ date, dayNumberText, isToday, view }) => (
  <ResourceCalendarDayCellContent
    date={date}
    dayNumberText={dayNumberText}
    isToday={isToday}
    holidays={holidays}
    viewType={view.type}
  />
);

ResourceCalendarDayCellContent.propTypes = {
  date: PropTypes.object,
  dayNumberText: PropTypes.string,
  isToday: PropTypes.bool,
  viewType: PropTypes.string,
  holidays: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      start: PropTypes.string,
      end: PropTypes.string
    })
  )
};
