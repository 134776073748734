import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChartHeader } from '~/modules/common/charts/timeline/components';
import TableSortLabel from '~/modules/common/components/ListTable/components/DataTable/components/TableSortLabel';
import { PageSelector } from '~/modules/resourcing/common/components';
import { SortDirection } from '~/types';
import useQuickAllocationContext from '../../hooks/useQuickAllocationContext';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    zIndex: 7,
    top: theme.spacing(12)
  }
}));

const QuickAllocationUserChartHeaderRow = ({
  classes: classesOverride,
  currentPage,
  loadingRows,
  onNextPage,
  onPreviousPage,
  pageSize,
  resourceTotalCount
}) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    chartDates,
    leftComponentWidth,
    scale,
    setSort,
    sort
  } = useQuickAllocationContext();

  const onSortChange = useCallback(
    field => {
      setSort({
        field,
        direction:
          sort.field === field && sort.direction === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc
      });
    },
    [sort, setSort]
  );

  const leftComponent = useMemo(
    () => (
      <>
        <div className={classes.user}>
          <TableSortLabel
            field="displayText"
            show
            active={sort.field === 'displayText'}
            direction={
              sort.field === 'displayText' &&
              sort.direction === SortDirection.Asc
                ? SortDirection.Asc.toLowerCase()
                : SortDirection.Desc.toLowerCase()
            }
            onClick={onSortChange}
          >
            <FormattedMessage id="quickAllocation.resource" />
          </TableSortLabel>
        </div>
        {resourceTotalCount !== 0 && (
          <PageSelector
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            resourceTotalCount={resourceTotalCount}
            currentPage={currentPage}
            pageSize={pageSize}
            disablePageEvents={loadingRows}
          />
        )}
      </>
    ),
    [
      classes.user,
      sort.field,
      sort.direction,
      onSortChange,
      resourceTotalCount,
      onNextPage,
      onPreviousPage,
      currentPage,
      pageSize,
      loadingRows
    ]
  );

  return (
    <ChartHeader
      leftComponent={leftComponent}
      classes={classes}
      leftComponentWidth={leftComponentWidth}
      scale={scale}
      chartDates={chartDates}
    />
  );
};

QuickAllocationUserChartHeaderRow.propTypes = {
  classes: PropTypes.object,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  resourceTotalCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  loadingRows: PropTypes.bool
};
export default QuickAllocationUserChartHeaderRow;
