const abbreviations = intl => [
  {
    suffix: intl.formatMessage({ id: 'abbreviatedNumberSuffix.thousand' }),
    scale: Math.pow(10, 3)
  },
  {
    suffix: intl.formatMessage({ id: 'abbreviatedNumberSuffix.million' }),
    scale: Math.pow(10, 6)
  },
  {
    suffix: intl.formatMessage({ id: 'abbreviatedNumberSuffix.billion' }),
    scale: Math.pow(10, 9)
  },
  {
    suffix: intl.formatMessage({ id: 'abbreviatedNumberSuffix.trillion' }),
    scale: Math.pow(10, 12)
  }
];

export const getAbbreviatedProps = ({
  value,
  intl,
  minScaleLimit = Math.pow(10, 3)
}) => {
  const matchedAbbreviation = abbreviations(intl)
    .filter(c => c.scale >= minScaleLimit)
    .sort((a, b) => b.scale - a.scale)
    .find(ab => Math.abs(value) / ab.scale >= 1);

  return {
    scaledNumber: matchedAbbreviation
      ? value / matchedAbbreviation.scale
      : value,
    abbreviatedSuffix: matchedAbbreviation ? matchedAbbreviation.suffix : ''
  };
};

export const numberAbbreviatedTickFormat = ({ intl, tick }) => {
  const { abbreviatedSuffix, scaledNumber } = getAbbreviatedProps({
    intl,
    value: tick
  });

  return `${scaledNumber}${abbreviatedSuffix}`;
};

export const abbreviatedDecimalValue = (intl, value, precision = 2) => {
  const { abbreviatedSuffix, scaledNumber } = getAbbreviatedProps({
    intl,
    value
  });

  return `${
    Number.isInteger(scaledNumber)
      ? scaledNumber
      : scaledNumber.toFixed(precision)
  }${abbreviatedSuffix}`;
};
