import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { TextField, makeStyles, styled } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { getError } from '~/util';

const labelIds = {
  address: 'projectBasicInfoCard.addressInfo.fields.address',
  city: 'projectBasicInfoCard.addressInfo.fields.city',
  stateProvince: 'projectBasicInfoCard.addressInfo.fields.stateProvince',
  zipPostalCode: 'projectBasicInfoCard.addressInfo.fields.zipPostalCode'
};

const useTextFieldStyles = makeStyles(theme => ({
  input: {
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2.5)
      }
    },
    '& .MuiFormLabel-root': {
      paddingTop: theme.spacing(0)
    }
  }
}));

const usePlaceholderStyles = makeStyles(theme => ({
  input: {
    '&::placeholder': {
      color: `rgb(0, 0, 0)`,
      opacity: '0.6 !important',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily
    }
  }
}));

export const StyledTextField = styled(TextField)({
  '& .MuiFormHelperText-contained': {
    marginLeft: 0,
    textWrap: 'wrap'
  }
});

const AddressField = ({
  record,
  field,
  index,
  column: { addressHandlers }
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const textFieldClasses = useTextFieldStyles();
  const placeholderStyles = usePlaceholderStyles();

  const intl = useIntl();
  const { errors } = useFormikContext();
  const labelField = intl.formatMessage({ id: labelIds[field] });

  const textFieldInputProps = useMemo(() => {
    const inputProps = {
      inputProps: {
        classes: { input: placeholderStyles.input },
        'aria-label': labelField
      }
    };

    return inputProps;
  }, [labelField, placeholderStyles]);

  const handleChange = useCallback(
    event => {
      addressHandlers.onAddressChange({
        index,
        field,
        value: event.target.value
      });
    },
    [addressHandlers, index, field]
  );

  return (
    <StyledTextField
      data-qe-id={`${field}`}
      className={textFieldClasses.input}
      variant="filled"
      label={isMobile && labelField ? labelField : ''}
      placeholder={!isMobile && labelField ? labelField : ''}
      InputProps={textFieldInputProps}
      value={record[field]}
      fullWidth
      onChange={handleChange}
      error={Boolean(getError(errors, `addresses[${index}].${field}`))}
      helperText={getError(errors, `addresses[${index}].${field}`)}
    />
  );
};

AddressField.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string,
  index: PropTypes.number,
  column: PropTypes.object
};

export default AddressField;
