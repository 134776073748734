import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { SummaryCardHelp } from '~/modules/common/components';
import { PROJECT_PROGRESS_TYPE_ENUM } from '~/modules/projects/project/common/enums/projectProgressType';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    '& button': {
      padding: theme.spacing(1.375),
      height: theme.spacing(4),
      width: '50%',
      fontFamily: theme.typography.button.fontFamily
    }
  }
}));

const BudgetSummaryChartHelp = ({ projectBudgetChartProps }) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    showCostContent,
    projectBudgetView,
    setProjectBudgetView
  } = projectBudgetChartProps;

  const toolTipContent =
    showCostContent && projectBudgetView === PROJECT_PROGRESS_TYPE_ENUM.COST
      ? costContent(intl)
      : hoursContent(intl);

  const handleChange = useCallback(
    async (_, view) => {
      if (view !== null) {
        setProjectBudgetView(view);
      }
    },
    [setProjectBudgetView]
  );

  return (
    <>
      {showCostContent && (
        <ToggleButtonGroup
          classes={classes}
          size="small"
          value={projectBudgetView}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton
            data-qe-id="ProgressHoursCostSummaryChartHelp_Hours"
            value={PROJECT_PROGRESS_TYPE_ENUM.HOUR}
          >
            <FormattedMessage id="dashboardProgressChart.hours" />
          </ToggleButton>
          <ToggleButton
            data-qe-id="ProgressHoursCostSummaryChartHelp_Cost"
            value={PROJECT_PROGRESS_TYPE_ENUM.COST}
          >
            <FormattedMessage id="dashboardProgressChart.cost" />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      <SummaryCardHelp content={toolTipContent} />
    </>
  );
};

const hoursContent = intl => [
  {
    title: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.budget'
    }),
    message: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.budgetAlert'
    })
  },
  {
    title: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.project'
    }),
    message: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.projectAlert'
    })
  },
  {
    title: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.tasks'
    }),
    message: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.tasksAlert'
    })
  },
  {
    title: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.resources'
    }),
    message: intl.formatMessage({
      id: 'projectHoursSummaryChartHelp.resourcesAlert'
    })
  }
];

const costContent = intl => [
  {
    title: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.budget'
    }),
    message: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.budgetAlert'
    })
  },
  {
    title: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.project'
    }),
    message: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.projectAlert'
    })
  },
  {
    title: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.tasks'
    }),
    message: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.tasksAlert'
    })
  },
  {
    title: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.resources'
    }),
    message: intl.formatMessage({
      id: 'projectCostsSummaryChartHelp.resourcesAlert'
    })
  }
];

BudgetSummaryChartHelp.propTypes = {
  projectBudgetChartProps: PropTypes.object.isRequired
};

export default BudgetSummaryChartHelp;
