import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import useFilterImportExportActions from './hooks/useFilterImportExportActions';
import ImportExportMenu from './ImportExportMenu';
import ImportExportMenuButton from './ImportExportMenuButton';
import ActionPropTypes from './ActionPropTypes';

const ImportExportGroup = ({
  isMobile,
  menuId,
  actions: recievedActions,
  dateRange,
  billingFilter,
  periodResolution,
  filename
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = useCallback(event => {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  }, []);

  const actions = useFilterImportExportActions(recievedActions);

  if (isMobile || !actions || actions.length === 0) {
    return <></>;
  }

  return (
    <>
      <ImportExportMenuButton handleMenuOpen={handleMenuOpen} />
      <ImportExportMenu
        isOpen={isMenuOpen}
        billingFilter={billingFilter}
        onClose={handleMenuClose}
        menuId={menuId}
        actions={actions}
        anchorEl={anchorEl}
        dateRange={dateRange}
        periodResolution={periodResolution}
        filename={filename}
      />
    </>
  );
};

ImportExportGroup.propTypes = {
  menuId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  dateRange: PropTypes.object,
  billingFilter: PropTypes.object,
  periodResolution: PropTypes.string,
  actions: ActionPropTypes.actions,
  filename: PropTypes.string
};

export default ImportExportGroup;
