import { gql } from 'graphql-tag';
import {
  specificResourceRequestWithAllocationsFragment,
  specificProjectFragmentLegacy
} from '~/modules/resourcing/common/fragments';

const resourceRequestQuery = gql`
  query Eager_getResourceRequest(
    $id: String!
    $includeMergeResourceRequests: Boolean = false
  ) {
    resourceRequest(id: $id) {
      mergedResourceRequests @include(if: $includeMergeResourceRequests) {
        id
        startDate
        endDate
      }
      ...SpecificResourceRequestWithAllocation
      project {
        permittedActionUris
        ...SpecificProjectFragmentLegacy
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
  ${specificProjectFragmentLegacy}
`;

export default resourceRequestQuery;
