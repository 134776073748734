import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.caption.fontSize,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  cell: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  }
}));

const DrilldownTableFooter = ({ columns, totals }) => {
  const classes = useStyles();

  return (
    <TableFooter className={classes.root} role="rowgroup">
      <TableRow variant="foot" role="row" className={classes.row}>
        {columns.map(column => (
          <TableCell
            key={column.key}
            align={column.align}
            className={classNames(classes.cell, column.className)}
          >
            <column.component
              {...column.transformValue(totals[column.key], totals, true)}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableFooter>
  );
};

DrilldownTableFooter.propTypes = {
  columns: PropTypes.array.isRequired,
  totals: PropTypes.object.isRequired
};

export default DrilldownTableFooter;
