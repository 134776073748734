import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const PROJECT_COPY_BATCH = gql`
  mutation ProjectCopyBatch($projectCopyInputs: ProjectCopyInputs2!) {
    createProjectCopyBatch3(projectCopyInputs: $projectCopyInputs) {
      jobId
      batchId
    }
  }
`;

export const useProjectCopyBatchMutation = () =>
  useMutation(PROJECT_COPY_BATCH, {});

export default useProjectCopyBatchMutation;
