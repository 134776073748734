import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Date from '~/modules/common/components/Date';
import NoValue from '~/modules/common/components/NoValue';
import Decimal from '~/modules/common/components/Decimal';

import TaskField from './TaskField';
import ActualOverEstimated from './ActualOverEstimated';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 2)
  },
  gridItem: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '30%'
  },
  date: {
    flexBasis: '40%'
  }
}));

const resourceLabels = {
  hours: <FormattedMessage id="projectTasksPage.hours" />,
  cost: <FormattedMessage id="projectTasksPage.cost" />,
  estimatedCompletionDate: (
    <FormattedMessage id="projectTasksPage.estimatedCompletionDate" />
  )
};

export const TaskDetails = ({ canViewEstimate, task }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid item className={classes.gridItem}>
        <TaskField label={resourceLabels.hours}>
          <ActualOverEstimated
            canViewEstimate={canViewEstimate}
            actual={
              <Typography variant="body2">
                {task.totalActualHours ? (
                  <Decimal value={task.totalActualHours} />
                ) : (
                  <NoValue />
                )}
              </Typography>
            }
            estimated={
              <Typography variant="caption" color="textSecondary">
                {task.initialEstimatedHours ? (
                  <Decimal value={task.initialEstimatedHours} />
                ) : (
                  <NoValue />
                )}
              </Typography>
            }
          />
        </TaskField>
      </Grid>
      <Grid item className={classNames(classes.gridItem, classes.date)}>
        <TaskField label={resourceLabels.estimatedCompletionDate}>
          <Date value={task.endDate} />
        </TaskField>
      </Grid>
    </Grid>
  );
};
TaskDetails.propTypes = {
  canViewEstimate: PropTypes.bool,
  task: PropTypes.object
};

export default TaskDetails;
