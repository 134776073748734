import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import {
  LocationsDropdown,
  ServiceCenterDropdown,
  CostCenterDropdown,
  DivisionDropdown,
  DepartmentDropdown,
  EmployeeTypeDropdown
} from '~/modules/common/components/Groups/project';
import { useSimpleChangeHandler } from '~/util';
import GroupField from './GroupField';

export const EditGroupsForm = ({
  groupSettings,
  legacyDepartmentDisabled,
  legacyEmployeeTypeDisabled
}) => {
  const { formatMessage } = useIntl();
  const {
    values: {
      division,
      serviceCenter,
      location,
      department,
      employeeType,
      costCenter
    },
    setFieldValue
  } = useFormikContext();
  const handleChange = useSimpleChangeHandler(setFieldValue);

  return (
    <Grid container>
      <GroupField
        rightPadding
        component={CostCenterDropdown}
        value={costCenter}
        label={formatMessage({ id: 'costCenterDropdown.costCenter' })}
        groupSettings={groupSettings.costCenter}
        onChange={handleChange('costCenter')}
      />
      {legacyDepartmentDisabled && (
        <GroupField
          rightPadding
          component={DepartmentDropdown}
          value={department}
          label={formatMessage({ id: 'departmentDropdown.department' })}
          groupSettings={groupSettings.department}
          onChange={handleChange('department')}
        />
      )}
      <GroupField
        rightPadding
        component={DivisionDropdown}
        value={division}
        label={formatMessage({ id: 'divisionDropdown.division' })}
        groupSettings={groupSettings.division}
        onChange={handleChange('division')}
      />
      {legacyEmployeeTypeDisabled && (
        <GroupField
          rightPadding
          component={EmployeeTypeDropdown}
          value={employeeType}
          label={formatMessage({
            id: 'employeeTypeDropdown.employeeType'
          })}
          groupSettings={groupSettings.employeeType}
          onChange={handleChange('employeeType')}
        />
      )}
      <GroupField
        rightPadding
        component={LocationsDropdown}
        value={location}
        label={formatMessage({ id: 'locationsDropdown.location' })}
        groupSettings={groupSettings.location}
        onChange={handleChange('location')}
      />
      <GroupField
        rightPadding
        component={ServiceCenterDropdown}
        value={serviceCenter}
        label={formatMessage({
          id: 'serviceCenterDropdown.serviceCenter'
        })}
        groupSettings={groupSettings.serviceCenter}
        onChange={handleChange('serviceCenter')}
      />
    </Grid>
  );
};

EditGroupsForm.propTypes = {
  groupSettings: PropTypes.object,
  legacyDepartmentDisabled: PropTypes.bool,
  legacyEmployeeTypeDisabled: PropTypes.bool
};

export default EditGroupsForm;
