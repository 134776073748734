import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { NoValue } from '~/modules/common/components';
import SideCardSectionDescription from './SideCardSectionDescription';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: ({ noPadding }) => (noPadding ? 0 : theme.spacing(1))
  },
  label: {
    fontSize: theme.typography.caption.fontSize
  },
  detail: {
    fontSize: theme.typography.body2.fontSize,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  }
}));

export const SideCardSectionDetail = ({
  classes,
  label,
  detail,
  loading,
  noPadding,
  isDescription = false,
  dataQeId
}) => {
  const detailClasses = useStyles({ classes, noPadding });
  const showDescription = !isEmpty(detail) && isDescription;

  return (
    <div className={detailClasses.root}>
      {label && (
        <Typography
          className={detailClasses.label}
          component="label"
          variant="caption"
          color="textSecondary"
          data-qe-id={(label && label.props && label.props.id) || label}
        >
          {label}
        </Typography>
      )}
      {showDescription ? (
        <SideCardSectionDescription detail={detail} />
      ) : (
        <Typography
          data-qe-id={dataQeId && `${dataQeId}_Value`}
          className={detailClasses.detail}
          component="div"
        >
          {(!loading && detail) || <NoValue />}
        </Typography>
      )}
    </div>
  );
};

SideCardSectionDetail.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  detail: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  loading: PropTypes.bool,
  noPadding: PropTypes.bool,
  isDescription: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default SideCardSectionDetail;
