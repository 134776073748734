import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { SelectedResourceRequestContext } from './hooks/useSelectedResourceRequestContext';

const SelectedResourceRequestContextProvider = ({ children }) => {
  const [selectedResourceRequestId, setSelectedResourceRequestId] = useState(
    null
  );

  const closeResourceRequestDrawer = () => {
    setSelectedResourceRequestId(null);
  };

  const openResourceRequestDrawer = ({ resourceRequestId }) => {
    setSelectedResourceRequestId(resourceRequestId);
  };

  const contextValue = useMemo(
    () => ({
      selectedResourceRequestId,
      closeResourceRequestDrawer,
      openResourceRequestDrawer,
      isResourceRequestDrawerOpen: Boolean(selectedResourceRequestId)
    }),
    [selectedResourceRequestId]
  );

  return (
    <SelectedResourceRequestContext.Provider value={contextValue}>
      {children}
    </SelectedResourceRequestContext.Provider>
  );
};

SelectedResourceRequestContextProvider.propTypes = {
  children: PropTypes.node
};

export default SelectedResourceRequestContextProvider;
