import React from 'react';
import PropTypes from 'prop-types';
import EditProjectDialog from '../EditProjectDialog';
import EditGroupsForm from './EditGroupsForm';
import { useUpdateProjectGroups } from './useUpdateProjectGroups';

export const EditGroupsDialog = ({
  title,
  open,
  onClose,
  groupSettings,
  projectId,
  costCenter,
  department,
  division,
  employeeType,
  location,
  serviceCenter,
  legacyDepartmentDisabled,
  legacyEmployeeTypeDisabled
}) => {
  const initialValues = {
    projectUri: projectId,
    costCenter,
    department,
    division,
    employeeType,
    location,
    serviceCenter
  };

  const onSubmit = useUpdateProjectGroups({ closeDialog: onClose });

  return (
    <EditProjectDialog
      title={title}
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <EditGroupsForm
        groupSettings={groupSettings}
        legacyDepartmentDisabled={legacyDepartmentDisabled}
        legacyEmployeeTypeDisabled={legacyEmployeeTypeDisabled}
      />
    </EditProjectDialog>
  );
};

EditGroupsDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  groupSettings: PropTypes.object,
  projectId: PropTypes.string,
  costCenter: PropTypes.object,
  department: PropTypes.object,
  division: PropTypes.object,
  employeeType: PropTypes.object,
  location: PropTypes.object,
  serviceCenter: PropTypes.object,
  legacyDepartmentDisabled: PropTypes.bool,
  legacyEmployeeTypeDisabled: PropTypes.bool
};

export default EditGroupsDialog;
