import { DateTime } from 'luxon';
import { getTodayForUser } from '~/modules/common/dates/today';

export const getDueDates = me => {
  const { day, month, year } = getTodayForUser(me);

  const today = DateTime.utc(year, month, day);

  return {
    thirtyDays: {
      startDate: today.minus({ day: 30 }),
      endDate: today.minus({ day: 1 })
    },
    sixtyDays: {
      startDate: today.minus({ day: 60 }),
      endDate: today.minus({ day: 31 })
    },
    ninetyDays: {
      startDate: today.minus({ day: 90 }),
      endDate: today.minus({ day: 61 })
    },
    oneTwentyDays: {
      startDate: today.minus({ day: 120 }),
      endDate: today.minus({ day: 91 })
    },
    greaterThanOneTwentyDays: {
      startDate: null,
      endDate: today.minus({ day: 121 })
    }
  };
};
