import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import GenericProjectFacetDetails from '../GenericProjectFacetDetails';
import { onChange } from '../../enhancers/facetHelpers';
import { useProjectOptions } from './useProjectOptions';

export const ProjectFacetDetails = ({ selected, setSelected }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const { loading, projects } = useProjectOptions(searchTerm);

  return (
    <GenericProjectFacetDetails
      selected={selected}
      loading={loading}
      options={projects}
      onChange={onValueChange}
      setSearchTerm={setSearchTerm}
    />
  );
};

ProjectFacetDetails.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ProjectFacetDetails;
