import { useCallback } from 'react';
import useMeContext from '~/modules/me/useMeContext';
import useResourceRequestDrawerEventHandlers from './useResourceRequestDrawerEventHandlers';

const useResourceRequestDrawerDetailsOnChangeEventHandlers = ({
  fields,
  formValues,
  setValues,
  project,
  setFieldValue
}) => {
  const {
    quantity,
    load,
    startDate,
    endDate,
    role,
    totalHours,
    scheduleRules,
    isAdjustedLoading
  } = fields;
  const { baseCurrency } = useMeContext();
  const {
    handleExtendEndDate,
    handleTotalHoursChange,
    handleStartDateChange,
    handleEndDateChange,
    handleLoadChange,
    handleQuantityChange,
    handleTotalRequestedCostChange,
    handleRoleRateChange
  } = useResourceRequestDrawerEventHandlers();

  const getValue = event =>
    event.target &&
    event.target.value &&
    Number.isInteger(parseInt(event.target.value, 10))
      ? parseInt(event.target.value, 10)
      : '';

  const onQuantityChange = useCallback(
    event =>
      handleQuantityChange({
        scheduleRules: scheduleRules.value,
        newQuantity: getValue(event),
        load: load.value,
        startDate: startDate.value,
        endDate: endDate.value,
        values: formValues,
        setValues
      }),
    [
      handleQuantityChange,
      scheduleRules.value,
      load.value,
      startDate.value,
      endDate.value,
      formValues,
      setValues
    ]
  );

  const onLoadingChange = useCallback(
    value => {
      handleLoadChange({
        scheduleRules: scheduleRules.value,
        newLoad: value,
        setLoad: load.onChange,
        setTotalHours: totalHours.onChange,
        quantity: quantity.value,
        startDate: startDate.value,
        endDate: endDate.value,
        defaultScheduleRule: project.defaultScheduleRule,
        values: formValues,
        setValues
      });
    },
    [
      handleLoadChange,
      scheduleRules.value,
      load.onChange,
      totalHours.onChange,
      quantity.value,
      startDate.value,
      endDate.value,
      project.defaultScheduleRule,
      formValues,
      setValues
    ]
  );

  const onRoleChange = useCallback(
    eventValue => {
      role.onChange(eventValue, baseCurrency);
    },
    [role, baseCurrency]
  );

  const onExtendLoad = useCallback(
    () =>
      handleExtendEndDate({
        startDate: startDate.value,
        endDate: endDate.value,
        quantity: quantity.value,
        setEndDate: endDate.onChange,
        load: load.value,
        setLoad: load.onChange,
        setIsAdjustedLoading: isAdjustedLoading.onChange,
        totalHours: totalHours.value,
        setTotalHours: totalHours.onChange,
        scheduleRules: scheduleRules.value,
        setScheduleRules: scheduleRules.onChange,
        defaultScheduleRule: project.defaultScheduleRule
      }),
    [
      handleExtendEndDate,
      startDate.value,
      endDate.value,
      endDate.onChange,
      quantity.value,
      load.value,
      load.onChange,
      totalHours.value,
      totalHours.onChange,
      scheduleRules.value,
      scheduleRules.onChange,
      project.defaultScheduleRule,
      isAdjustedLoading.onChange
    ]
  );

  const onHoursChange = useCallback(
    value =>
      handleTotalHoursChange({
        newTotalHours: value,
        startDate: startDate.value,
        endDate: endDate.value,
        quantity: quantity.value,
        defaultScheduleRule: project.defaultScheduleRule,
        values: formValues,
        setValues
      }),
    [
      handleTotalHoursChange,
      startDate.value,
      endDate.value,
      quantity.value,
      project.defaultScheduleRule,
      formValues,
      setValues
    ]
  );

  const onStartDateChange = useCallback(
    value =>
      handleStartDateChange({
        scheduleRules: scheduleRules.value,
        newStartDate: value,
        endDate: endDate.value,
        quantity: quantity.value,
        values: formValues,
        setValues,
        load: load.value,
        defaultScheduleRule: project.defaultScheduleRule
      }),
    [
      handleStartDateChange,
      scheduleRules.value,
      endDate.value,
      setValues,
      formValues,
      quantity.value,
      load.value,
      project.defaultScheduleRule
    ]
  );

  const onEndDateChange = useCallback(
    value =>
      handleEndDateChange({
        scheduleRules: scheduleRules.value,
        newEndDate: value,
        startDate: startDate.value,
        values: formValues,
        setValues,
        quantity: quantity.value,
        load: load.value,
        defaultScheduleRule: project.defaultScheduleRule
      }),
    [
      handleEndDateChange,
      scheduleRules.value,
      startDate.value,
      quantity.value,
      load.value,
      project.defaultScheduleRule,
      formValues,
      setValues
    ]
  );

  const onTotalRequestedCostChange = useCallback(
    value =>
      handleTotalRequestedCostChange({
        newTotalRequestedCost: value.target.value,
        values: formValues,
        setValues
      }),
    [handleTotalRequestedCostChange, formValues, setValues]
  );

  const onRoleRateChange = useCallback(
    decimalScale => value =>
      handleRoleRateChange({
        newRoleRate: value.target.value,
        values: formValues,
        setValues,
        decimalScale
      }),
    [handleRoleRateChange, formValues, setValues]
  );

  const onWeightsChange = useCallback(
    weights => setFieldValue('requestAttributeWeights', weights),
    [setFieldValue]
  );

  return {
    onWeightsChange,
    onTotalRequestedCostChange,
    onEndDateChange,
    onStartDateChange,
    onHoursChange,
    onExtendLoad,
    onRoleChange,
    onLoadingChange,
    onQuantityChange,
    onRoleRateChange
  };
};

export default useResourceRequestDrawerDetailsOnChangeEventHandlers;
