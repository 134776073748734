import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export const UPDATE_GATES = gql`
  mutation updateGates(
    $projectRequestId: String!
    $gates: [ProjectRequestGateInput]!
  ) {
    updateProjectRequestGates(
      projectRequestId: $projectRequestId
      gates: $gates
    ) {
      projectRequestId
    }
  }
`;

export const useUpdateGates = ({ projectRequestId, refetchQueries = [] }) => {
  const [updateProjectRequestGates] = useMutation(UPDATE_GATES);

  return {
    updateGates: useCallback(
      gates =>
        updateProjectRequestGates({
          variables: {
            projectRequestId,
            gates: gates.map(gate => ({
              uri: gate.uri,
              displayText: gate.displayText,
              value: gate.value
            }))
          },
          refetchQueries
        }),
      [projectRequestId, refetchQueries, updateProjectRequestGates]
    )
  };
};

export default useUpdateGates;
