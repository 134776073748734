import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const editCardDialogRole = {
  role: 'presentation'
};
const paperProps = ariaLabel => ({
  'aria-label': ariaLabel
});

const getMatchingChildren = displayName => children =>
  React.Children.toArray(children)
    .filter(child => child.type.displayName === displayName)
    .map(child => child.props.children);

const getTitleChildren = getMatchingChildren('EditTitle');
const getContentChildren = getMatchingChildren('EditContent');

const useStyles = makeStyles(theme => ({
  dialogActions: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  spacer: { flex: '1 0 0' }
}));

export const EditCard = ({
  classes: classesOverride,
  className,
  fullScreen,
  open,
  maxWidth = 'md',
  saveable = true,
  isSaving,
  onSave,
  onCancel,
  onRemove,
  validateForm,
  actionComponentCustomProps,
  actionLabel,
  ActionComponent,
  children,
  dialogClasses,
  ariaLabel
}) => {
  const classes = useStyles({ classes: classesOverride });
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      PaperProps={paperProps(ariaLabel)}
      fullWidth
      fullScreen={fullScreen ?? isMobile}
      maxWidth={maxWidth}
      open={open}
      onClose={onCancel}
      disableBackdropClick
      TransitionProps={editCardDialogRole}
      disableEscapeKeyDown
      role="main"
      classes={dialogClasses}
    >
      <DialogTitle>{getTitleChildren(children)}</DialogTitle>
      <DialogContent className={className}>
        {getContentChildren(children)}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {ActionComponent ? (
          <ActionComponent
            onCancel={onCancel}
            onSave={onSave}
            validateForm={validateForm}
            saveable={saveable}
            isSaving={isSaving}
            onRemove={onRemove}
            actionLabel={actionLabel}
            actionComponentCustomProps={actionComponentCustomProps}
          />
        ) : (
          <>
            <Button onClick={onCancel} data-qe-id="EditCard_Cancel">
              <FormattedMessage id="button.cancel" />
            </Button>
            <LoadingButton
              color="primary"
              onClick={onSave}
              disabled={!saveable || isSaving}
              isLoading={isSaving}
              data-qe-id="EditCard_Save"
            >
              <FormattedMessage id="button.save" />
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

EditCard.propTypes = {
  classes: PropTypes.object,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  fullScreen: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  saveable: PropTypes.bool,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  actionLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  ActionComponent: PropTypes.elementType,
  validateForm: PropTypes.func,
  actionComponentCustomProps: PropTypes.object,
  dialogClasses: PropTypes.object,
  ariaLabel: PropTypes.string
};

export default EditCard;
