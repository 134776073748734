/* eslint-disable react/jsx-max-depth */
import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import EditProjectDialogActions from './EditProjectDialogActions';
import FormErrorContext from './EditProjectDialogErrorContext';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const editProjectDialogRole = {
  role: 'presentation'
};

export const EditProjectDialog = ({
  title,
  open,
  onClose,
  initialValues,
  validationSchema,
  onSubmit,
  children
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();

  const [error, setError] = useState(null);
  const clearError = useCallback(() => setError(null), [setError]);
  const errorContextValue = useMemo(() => ({ error, clearError }), [
    error,
    clearError
  ]);

  return (
    <FormErrorContext.Provider value={errorContextValue}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onReset={clearError}
        enableReinitialize
      >
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="md"
          fullScreen={isMobile}
          disableBackdropClick
          disableEscapeKeyDown
          TransitionProps={editProjectDialogRole}
        >
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <DescriptionIcon className={classes.icon} />
            <Typography variant="h6">{title}</Typography>
          </DialogTitle>
          <DialogContent>
            {error && (
              <Typography component="div" variant="caption" color="error">
                {error}
              </Typography>
            )}
            {children}
          </DialogContent>
          <EditProjectDialogActions onClose={onClose} />
        </Dialog>
      </Formik>
    </FormErrorContext.Provider>
  );
};

EditProjectDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};

export default EditProjectDialog;
