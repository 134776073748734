import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CircularProgress } from '@material-ui/core';
import { useHasSomePermissions } from '~/modules/common/permissions/useHasSomePermissions';
import CalendarHeader from '~/modules/common/components/Calendar/CalendarHeader';
import { retryLoading } from '~/util';
import {
  useEvents,
  useAllocationsForResourceUser,
  useTimeoffsForResourceUser,
  useHolidaysForResourceUser
} from './hooks';
import useCalendarContext from './hooks/useCalendarContext';
import GetResourceCalendarDayCellContent from './ResourceCalendarDayCellContent';

export const Calendar = React.lazy(() =>
  retryLoading(() => import('~/modules/common/components/Calendar'))
);

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    overflow: 'visible'
  },
  contentRoot: { padding: theme.spacing(0, 2) },
  mainHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    whiteSpace: 'nowrap'
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2)
  }
}));

const useCardStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(0, 2) }
}));

const circularProgress = <CircularProgress data-qe-id="circularProgress" />;

export const ResourceCalendarCard = ({
  userDetails: { uri: userUri, slug: userSlug },
  showCardLayout = true,
  calendarAspectRatio = '1.5'
}) => {
  const classes = useStyles();
  const cardContentClass = useCardStyles();

  const {
    setChildRef,
    period,
    title,
    dateRange,
    handlePrevPeriodChange,
    handleNextPeriodChange,
    handleDatesChange,
    handleCalendarViewChange
  } = useCalendarContext();

  const { allocations } = useAllocationsForResourceUser({ userUri, dateRange });
  const { timeoffs } = useTimeoffsForResourceUser({ userSlug, dateRange });
  const { holidays } = useHolidaysForResourceUser({
    userSlug,
    dateRange
  });

  const { events, holidayEvents } = useEvents({
    allocations,
    timeoffs,
    holidays
  });

  const canViewAllocations = useHasSomePermissions([
    {
      actionUri:
        'urn:replicon:resource-assignment-action:view-resource-assignments'
    }
  ]);

  const showCalendarLegend = status =>
    events.some(evt => evt.allocationStatus && evt.allocationStatus === status);

  const GetCalendarContent = () => (
    <>
      <div className={classes.mainHeader}>
        {/* Change/toggle depending on feature flag */}
        <CalendarHeader
          period={period}
          title={title}
          onPrevButtonClick={handlePrevPeriodChange}
          onNextButtonClick={handleNextPeriodChange}
          onViewChange={handleCalendarViewChange}
          showCalendarLegend={showCalendarLegend}
          displayAllocationCheckbox={canViewAllocations}
        />
      </div>
      <Suspense fallback={circularProgress}>
        <Calendar
          setChildRef={setChildRef}
          handleDatesRender={handleDatesChange}
          events={events}
          aspectRatio={calendarAspectRatio}
          dayCellContent={GetResourceCalendarDayCellContent(holidayEvents)}
        />
      </Suspense>
      <div className={classes.footer} />
    </>
  );

  return showCardLayout ? (
    <Card className={classes.root}>
      <CardContent classes={cardContentClass}>
        {GetCalendarContent()}
      </CardContent>
    </Card>
  ) : (
    GetCalendarContent()
  );
};

ResourceCalendarCard.propTypes = {
  userDetails: PropTypes.object,
  showCardLayout: PropTypes.bool,
  calendarAspectRatio: PropTypes.string
};

export default ResourceCalendarCard;
