import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import useClientRate from '~/modules/rateCard/hooks/useClientRate';
import { useDialogState } from '~/modules/common/hooks';
import RateCopyConfirmationDialog from '~/modules/rateCard/components/RateCopyConfirmationDialog';
import useCopyFromClient from '../hooks/useCopyFromClient';

const useStyles = makeStyles(theme => ({
  copyFromClientButton: {
    color: theme.palette.primary.main
  }
}));

export const CopyFromClient = ({
  projectDetails,
  clientUri,
  setRatesModifier
}) => {
  const classes = useStyles();

  const { defaultRates } = useClientRate(clientUri);

  const { open, openDialog, closeDialog } = useDialogState();
  const [copyClientRateInProgress, setCopyClientRateInProgress] = useState(
    false
  );

  const { projectRatesCount, roleRatesCount, resourceRatesCount } = useMemo(
    () => ({
      projectRatesCount: (defaultRates.projectRate || []).length,
      roleRatesCount: (defaultRates.roleRates || []).length,
      resourceRatesCount: (defaultRates.userRates || []).length
    }),
    [defaultRates.projectRate, defaultRates.roleRates, defaultRates.userRates]
  );

  const { count } = useMemo(
    () => ({
      count: projectRatesCount + roleRatesCount + resourceRatesCount
    }),
    [projectRatesCount, resourceRatesCount, roleRatesCount]
  );

  const { copyFromClient } = useCopyFromClient({
    setCopyClientRateInProgress,
    defaultRates,
    projectUri: projectDetails.projectId,
    setRatesModifier,
    count,
    closeDialog
  });

  return (
    <>
      <Button onClick={openDialog} className={classes.copyFromClientButton}>
        <FormattedMessage id="rateCard.copyFromClient" />
      </Button>
      {open ? (
        <RateCopyConfirmationDialog
          openDialog={open}
          onDialogClose={closeDialog}
          onConfirmClick={copyFromClient}
          defaultRates={defaultRates}
          copyClientRateInProgress={copyClientRateInProgress}
        />
      ) : null}
    </>
  );
};

CopyFromClient.propTypes = {
  clientUri: PropTypes.string,
  projectDetails: PropTypes.object,
  setRatesModifier: PropTypes.func
};

export default CopyFromClient;
