import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PropTypes } from 'prop-types';

export const BATCH_RESULTS_QUERY = gql`
  query getCreateBillFromBillingItemsBatchResults($batchId: String!) {
    getCreateBillFromBillingItemsBatchResults(batchId: $batchId) {
      billId
    }
  }
`;

export const useCreateBillFromBillingItemsBatchResults = ({
  batchId = null
} = {}) => {
  const {
    data: {
      getCreateBillFromBillingItemsBatchResults: { invoiceUri: billId } = {}
    } = {},
    refetch: fetchBatchResults
  } = useQuery(BATCH_RESULTS_QUERY, {
    variables: {
      batchId
    },
    skip: !batchId,
    fetchPolicy: 'network-only'
  });

  return { billId, fetchBatchResults };
};
useCreateBillFromBillingItemsBatchResults.propTypes = {
  invoiceId: PropTypes.String
};
export default useCreateBillFromBillingItemsBatchResults;
