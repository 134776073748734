import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const useListItemStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

export const BillingItemTypeItem = ({ value }) => {
  const classes = useStyles();
  const listItemClasses = useListItemStyles();

  const labelStyle = useMemo(() => ({ root: classes.root }), [classes.root]);

  return (
    <ListItem component="div" classes={listItemClasses}>
      <Typography variant="inherit" classes={labelStyle} color="inherit">
        {value}
      </Typography>
    </ListItem>
  );
};

BillingItemTypeItem.propTypes = {
  value: PropTypes.string
};

export default BillingItemTypeItem;
