import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

export const SelectAllFormatter = ({
  onSelectAll,
  selectedRows,
  billingItems,
  ariaLabel
}) => {
  const handleSelectAll = useCallback(
    event => {
      onSelectAll(event.target.checked);
    },
    [onSelectAll]
  );

  return (
    <Checkbox
      inputProps={{ 'aria-label': ariaLabel }}
      checked={Boolean(
        selectedRows.length && selectedRows.length === billingItems.length
      )}
      indeterminate={Boolean(
        selectedRows.length && selectedRows.length < billingItems.length
      )}
      onChange={handleSelectAll}
      color="primary"
      data-qe-id="addLineItemDialog.selectAllCheckbox"
    />
  );
};

SelectAllFormatter.propTypes = {
  onSelectAll: PropTypes.func,
  selectedRows: PropTypes.array,
  billingItems: PropTypes.array,
  ariaLabel: PropTypes.string
};

export default SelectAllFormatter;
