import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, DialogContent, Typography, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PropTypes } from 'prop-types';
import TaskAllocationCard from '../TaskAllocationCard';
import TaskEstimateCard from '../TaskEstimateCard';
import { ResourceRoleGridItemReadOnly } from '../ResourceRoleGridItem/ResourceRoleGridItem';

export const useStyles = makeStyles(theme => ({
  dropdownContainer: {
    width: '100%',
    margin: 0
  },
  estimateRowContainer: {
    padding: theme.spacing(1.5, 0, 2)
  },
  warningAlertContainer: {
    padding: theme.spacing(0, 0, 2)
  },
  hasActualHours: {
    marginTop: theme.spacing(1)
  }
}));

export const RemoveTaskEstimateConfirmationDialogContent = ({
  resourceEstimate,
  task
}) => {
  const classes = useStyles();
  const canEditTasks = false;

  return (
    <DialogContent>
      <Grid container className={classes.dropdownContainer}>
        <ResourceRoleGridItemReadOnly
          canEditTasks={canEditTasks}
          resourceEstimate={resourceEstimate}
        />
      </Grid>
      <Grid container className={classes.estimateRowContainer} spacing={2}>
        <TaskEstimateCard
          canEditTasks={canEditTasks}
          resourceEstimate={resourceEstimate}
          isReadOnlyView
        />
        <TaskAllocationCard
          resourceEstimate={resourceEstimate}
          task={task}
          canEditTasks={canEditTasks}
          isReadOnlyView
        />
      </Grid>
      {resourceEstimate?.taskResourceUserAllocation && (
        <div className={classes.warningAlertContainer}>
          <Alert severity="warning" icon={false}>
            <AlertTitle>
              <FormattedMessage
                id="removeTaskEstimateDialog.resourceHasTimeAllocation"
                values={{
                  resourceName: resourceEstimate.resourceUser?.displayText
                }}
              />
            </AlertTitle>
            <FormattedMessage id="removeTaskEstimateDialog.allocationRemoveWarning" />
          </Alert>
        </div>
      )}
      <Typography variant="body1" color="textSecondary">
        <FormattedMessage id="removeTaskEstimateDialog.message" />
      </Typography>
      {resourceEstimate?.taskResourceUserHoursProgressSummary?.actualHours && (
        <Typography
          variant="caption"
          component="p"
          color="textSecondary"
          className={classes.hasActualHours}
        >
          <FormattedMessage id="removeTaskEstimateDialog.pastTimeWillRemainMessage" />
        </Typography>
      )}
    </DialogContent>
  );
};

RemoveTaskEstimateConfirmationDialogContent.propTypes = {
  resourceEstimate: PropTypes.object,
  task: PropTypes.object
};

export default RemoveTaskEstimateConfirmationDialogContent;
