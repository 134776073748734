import lodashGet from 'lodash.get';

export const mapExtensionFieldFileValue = fileValue =>
  fileValue
    ? {
        __typename: 'ExtensionFieldFileValue',
        identityUri: lodashGet(fileValue, 'identityUri', null),
        linkUri: lodashGet(fileValue, 'identityUri', null),
        keyValues: lodashGet(fileValue, 'data.keyValues', null)
          ? fileValue.data.keyValues.map(keyValue => ({
              __typename: 'ExtensionFieldFileValueKeyValue',
              ...keyValue,
              value: {
                __typename: 'ExtensionFieldFileValueKeyValueValue',
                ...keyValue.value
              }
            }))
          : null
      }
    : null;

export const mapExtensionFieldTag = (tag, definitionTags) =>
  tag && definitionTags ? definitionTags.find(d => d.id === tag.uri) : null;

export const mapExtensionFieldValues = ({
  initialExtensionFieldValues,
  updatedExtensionFieldValues
}) =>
  initialExtensionFieldValues.map(original => {
    const updatedExtensionFieldValue = updatedExtensionFieldValues.find(
      oef => oef.definition.uri === original.definition.id
    );

    if (updatedExtensionFieldValue) {
      return {
        __typename: 'ExtensionFieldValue',
        ...original,
        textValue: updatedExtensionFieldValue.textValue,
        numericValue: updatedExtensionFieldValue.numericValue,
        fileValue: mapExtensionFieldFileValue(
          updatedExtensionFieldValue.fileValue
        ),
        tag: mapExtensionFieldTag(
          updatedExtensionFieldValue.tag,
          original.definition.tags
        )
      };
    }

    return original;
  });

export const optimisticResponse = ({
  projectUri,
  extensionFieldValues,
  initialExtensionFieldValues,
  fileExtensionFieldValues
}) => ({
  __typename: 'Mutation',
  updateProject2: {
    __typename: 'Project',
    id: projectUri,
    extensionFieldValues: mapExtensionFieldValues({
      initialExtensionFieldValues,
      updatedExtensionFieldValues: [
        ...extensionFieldValues,
        ...fileExtensionFieldValues
      ]
    }),
    customFieldValues: []
  }
});
