import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProfitIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 13,2 14.779296875,3.806640625 13,5.5859375 l -3,-3 L 6.29296875,6.29296875 5.5859375,7 7,8.4140625 7.70703125,7.70703125 10,5.4140625 l 3,3 L 16.18359375,5.23046875 18,7.072265625 V 2 Z" />
      <path d="M 2 10 L 2 22 L 22 22 L 22 10 L 2 10 z M 12 13 A 3 3 0 0 1 15 16 A 3 3 0 0 1 12 19 A 3 3 0 0 1 9 16 A 3 3 0 0 1 12 13 z M 6 15 A 1 1 0 0 1 7 16 A 1 1 0 0 1 6 17 A 1 1 0 0 1 5 16 A 1 1 0 0 1 6 15 z M 18 15 A 1 1 0 0 1 19 16 A 1 1 0 0 1 18 17 A 1 1 0 0 1 17 16 A 1 1 0 0 1 18 15 z " />
    </>
  </SvgIcon>
));

export default ProfitIcon;
