import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import {
  AbbreviatedDecimal,
  NoValue,
  Decimal
} from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  classes: {},
  allocatedCost: {},
  plannedCost: {},
  tooltipContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridColumnGap: theme.spacing(2)
  },
  costs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

export const CostComponent = ({
  classes,
  classesName,
  costs,
  label,
  dataQeId,
  Component,
  type
}) => (
  <div
    data-qe-id={dataQeId}
    className={classNames(classesName, classes.tooltipContent)}
  >
    {label && label}
    <div className={classes.costs}>
      {costs && costs.length !== 0 ? (
        costs.map(cost =>
          cost.amount ? (
            <Component
              key={`${type}_${cost.currencySymbol}`}
              value={cost.amount}
              prefix={`${cost.currencySymbol} `}
            />
          ) : (
            <NoValue key={`${type}_${cost.currencySymbol}`} />
          )
        )
      ) : (
        <NoValue />
      )}
    </div>
  </div>
);

CostComponent.propTypes = {
  classes: PropTypes.object,
  classesName: PropTypes.string,
  dataQeId: PropTypes.string,
  costs: PropTypes.array,
  type: PropTypes.string,
  label: PropTypes.object,
  Component: PropTypes.object
};

const allocatedCostLabel = (
  <FormattedMessage id="rowTotalTooltip.allocatedCost" />
);

const plannedCostLabel = <FormattedMessage id="rowTotalTooltip.plannedCost" />;

export const RowTotalCost = ({
  qeIdTag,
  classes: classesOverrides,
  showLabel = true,
  showAbbreviatedValue = true,
  plannedCost,
  allocatedCost
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const Component = showAbbreviatedValue ? AbbreviatedDecimal : Decimal;

  return (
    <>
      <CostComponent
        classes={classes}
        className={classes.allocatedCost}
        dataQeId={`${qeIdTag}_AllocatedCost`}
        Component={Component}
        label={showLabel && allocatedCostLabel}
        costs={allocatedCost}
        type="a"
      />
      <CostComponent
        classes={classes}
        className={classes.plannedCost}
        dataQeId={`${qeIdTag}_PlannedCost`}
        Component={Component}
        label={showLabel && plannedCostLabel}
        costs={plannedCost}
        type="p"
      />
    </>
  );
};

RowTotalCost.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  showLabel: PropTypes.bool,
  plannedCost: PropTypes.array,
  allocatedCost: PropTypes.array,
  showAbbreviatedValue: PropTypes.bool
};

export default RowTotalCost;
