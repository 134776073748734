export const hasExistingBillPlanAndNoTask = (
  canViewParams,
  canEditParams,
  tasksCount,
  isExistingBillPlan
) => canViewParams && !canEditParams && !tasksCount && isExistingBillPlan;

export const hasNoExistingBillPlanAndNoTask = (
  canViewParams,
  canEditParams,
  tasksCount,
  isExistingBillPlan
) => canViewParams && !canEditParams && !tasksCount && !isExistingBillPlan;

export const hasTaskAndExistingBillPlan = (
  canEditParams,
  tasksCount,
  isExistingBillPlan
) => !canEditParams && tasksCount && isExistingBillPlan;

export const hasTaskAndNoExistingBillPlan = (
  canEditParams,
  tasksCount,
  isExistingBillPlan
) => !canEditParams && tasksCount && !isExistingBillPlan;

export const setErrorTitle = ({
  canViewParams,
  canEditParams,
  tasksCount,
  isExistingBillPlan,
  formatMessage
}) => {
  let errorTitle = '';

  switch (true) {
    case !canViewParams:
      return formatMessage({
        id: 'billPlanPreRequisites.noViewTaskPermissionMessage'
      });
    case hasExistingBillPlanAndNoTask(
      canViewParams,
      canEditParams,
      tasksCount,
      isExistingBillPlan
    ):
      return formatMessage({
        id: 'billPlanPreRequisites.noViewTaskPermissionMessage'
      });
    case hasNoExistingBillPlanAndNoTask(
      canViewParams,
      canEditParams,
      tasksCount,
      isExistingBillPlan
    ):
      return formatMessage({
        id: 'billPlanPreRequisites.noViewTaskPermissionMessage'
      });
    case hasTaskAndExistingBillPlan(
      canEditParams,
      tasksCount,
      isExistingBillPlan
    ):
      return formatMessage({
        id: 'billPlanPreRequisites.noEditTaskPermissionMessage'
      });
    case hasTaskAndNoExistingBillPlan(
      canEditParams,
      tasksCount,
      isExistingBillPlan
    ):
      return formatMessage({
        id: 'billPlanPreRequisites.noViewTaskPermissionMessage'
      });

    default:
      errorTitle = !tasksCount
        ? formatMessage({
            id: 'billPlanPreRequisites.noMileStoneTasks'
          })
        : '';

      return errorTitle;
  }
};

export default setErrorTitle;
