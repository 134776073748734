import PropTypes from 'prop-types';
import { ScoreComputeStatus } from '~/types';

const scoreComputeStatus = PropTypes.oneOf([
  ScoreComputeStatus.Completed,
  ScoreComputeStatus.InProgress,
  ScoreComputeStatus.NotStarted,
  ScoreComputeStatus.Failed
]);

export default {
  scoreComputeStatus
};
