import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const GET_NEXT_REFERENCE_NUMBER_SETTING = gql`
  query getNextReferenceNumberSetting(
    $transactionType: BillingTransactionType!
  ) {
    transactionNextReferenceNumberSetting(transactionType: $transactionType) {
      isAllowEditAutoGeneratedNumber
      isAutoGeneratedReferenceNumber
    }
  }
`;

export const useNextReferenceNumberSetting = ({
  transactionType,
  skip = false
}) => {
  const { data, loading } = useQuery(GET_NEXT_REFERENCE_NUMBER_SETTING, {
    variables: {
      transactionType
    },
    skip,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const nextReferenceNumberSetting = get(
    data,
    `transactionNextReferenceNumberSetting`,
    {}
  );

  return {
    loading,
    nextReferenceNumberSetting
  };
};

export default useNextReferenceNumberSetting;
