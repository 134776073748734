import { useCallback, useState, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import { NETWORK_STATUS } from '~/modules/common/enums';
import { getOrThrow, createObjectPath } from '../util';

const getPath = fetchMoreResultPath =>
  Array.isArray(fetchMoreResultPath)
    ? fetchMoreResultPath
    : [fetchMoreResultPath];

export const updateQueryResultPath = ({
  setHasMore,
  fetchMoreResultPath,
  pageSize,
  queryId,
  queryIdRef
}) => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult || queryId !== queryIdRef.current) return prev;

  if (
    fetchMoreResult[fetchMoreResultPath] &&
    fetchMoreResult[fetchMoreResultPath].items
  ) {
    setHasMore(fetchMoreResult[fetchMoreResultPath].items.length === pageSize);

    return {
      ...prev,
      ...{
        [fetchMoreResultPath]: {
          ...prev[fetchMoreResultPath],
          items: [
            ...prev[fetchMoreResultPath].items,
            ...fetchMoreResult[fetchMoreResultPath].items
          ],
          totalItems: fetchMoreResult[fetchMoreResultPath].totalItems || 0
        }
      }
    };
  }

  const path = getPath(fetchMoreResultPath);

  setHasMore(getOrThrow(path)(fetchMoreResult).length === pageSize);

  const moreResults = [
    ...getOrThrow(path)(prev),
    ...getOrThrow(path)(fetchMoreResult)
  ];

  return createObjectPath({}, path, moreResults, prev);
};

export const loadMoreAndUpdatePage = async ({
  page,
  fetchMore,
  fetchMoreResultPath,
  setPage,
  setHasMore,
  hasMore,
  variables,
  loading,
  networkStatus,
  queryId,
  queryIdRef,
  loadingMore,
  setLoadingMore
}) => {
  if (
    loading ||
    loadingMore ||
    !hasMore ||
    networkStatus !== NETWORK_STATUS.READY
  ) {
    return;
  }
  setLoadingMore(true);

  try {
    await fetchMore({
      variables: {
        ...variables,
        page: page + 1
      },
      updateQuery: updateQueryResultPath({
        setHasMore,
        fetchMoreResultPath,
        pageSize: variables.pageSize || variables.pagesize,
        queryId,
        queryIdRef
      })
    });
    setPage(page + 1);
  } finally {
    setLoadingMore(false);
  }
};

export const useLoadMore = ({
  initialPage = 1,
  initialHasMore = true,
  fetchMore,
  variables,
  fetchMoreResultPath,
  loading,
  networkStatus
}) => {
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const queryIdRef = useRef(v4());

  const networkStatusIsSetVariables =
    networkStatus === NETWORK_STATUS.SET_VARIABLES;

  useEffect(() => {
    setPage(initialPage);
    setHasMore(initialHasMore);
    queryIdRef.current = v4();
  }, [initialHasMore, initialPage, networkStatusIsSetVariables]);

  const loadMore = useCallback(async () => {
    await loadMoreAndUpdatePage({
      fetchMore,
      fetchMoreResultPath,
      page,
      variables,
      setPage,
      setHasMore,
      hasMore,
      loading,
      networkStatus,
      loadingMore,
      setLoadingMore,
      queryId: queryIdRef.current,
      queryIdRef
    });
  }, [
    fetchMore,
    fetchMoreResultPath,
    page,
    variables,
    hasMore,
    loading,
    networkStatus,
    loadingMore
  ]);

  return {
    loadingMore,
    loadMore,
    hasMore,
    page,
    loading
  };
};

export default useLoadMore;
