import { useColumns } from '~/modules/common/components/ListTable';
import { formatters } from './formatters';

const alignment = {
  text: 'left',
  numeric: 'right',
  boolean: 'left',
  percentage: 'right'
};

export const buildColumns = ({ classes, taxRateHeaders }) => {
  const columns = taxRateHeaders.reduce(
    (resColumns, current) => ({
      ...resColumns,
      [current.keyUri]: {
        id: current.keyUri,
        value: current.displayText,
        visible: current.visible,
        className: classes[current.type],
        align: alignment[current.type]
      }
    }),
    {}
  );

  return columns;
};

export const useListColumns = ({ classes, taxRateHeaders }) =>
  useColumns({
    columns: buildColumns({ classes, taxRateHeaders }),
    renders: formatters({ taxRateHeaders })
  });
