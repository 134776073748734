import gql from 'graphql-tag';

export const vendorFragment = gql`
  fragment Vendor on Vendor {
    id
    name
    slug
    closedDate
    isActive
  }
`;
