import { useCallback } from 'react';
import { COLUMN_KEY } from '~/modules/resourcing/components/ResourceAssignmentDialog/enhancers';
import { AssignmentDialogColumns } from '~/modules/resourcing/components/ResourceAssignmentDialog/enhancers/constants';

const useDialogSettingsSave = ({
  putTableSettings,
  setIsSaving,
  onClose,
  selectedOptions,
  tableKey,
  setEnabledAssignmentDialogColumns,
  setIsCostOptimizationSmartMatchModeEnabled
}) =>
  useCallback(async () => {
    setIsSaving(true);

    const formattedSelectedColumns = [];
    const unformattedSelectedColumns = [];

    selectedOptions.forEach((option, index) => {
      if (option?.field === '') {
        return;
      }

      unformattedSelectedColumns.push(option.field);
      formattedSelectedColumns.push(`${COLUMN_KEY + index};${option.field}`);
    });

    await putTableSettings({
      variables: {
        tableKey,
        columns: formattedSelectedColumns
      }
    });

    setIsCostOptimizationSmartMatchModeEnabled(
      selectedOptions[2].field ===
        AssignmentDialogColumns.SmartMatchIncludingCost
    );
    setEnabledAssignmentDialogColumns(unformattedSelectedColumns);
    setIsSaving(false);
    onClose();
  }, [
    onClose,
    putTableSettings,
    selectedOptions,
    setIsSaving,
    tableKey,
    setEnabledAssignmentDialogColumns,
    setIsCostOptimizationSmartMatchModeEnabled
  ]);

export default useDialogSettingsSave;
