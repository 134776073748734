export const filterSummarizeColumnOptions = summarizeColumnOptions => {
  const existingColumnOptions = ['ITEMTYPE', 'UNIT', 'RATE'];
  const filteredColumnOptions =
    summarizeColumnOptions && summarizeColumnOptions.length
      ? summarizeColumnOptions.filter(
          columnOption => !existingColumnOptions.includes(columnOption)
        )
      : ['PROJECT'];

  return filteredColumnOptions;
};

export const formatPutBillInput = (
  input,
  isPsaPrpBillingDefaultForProjectEnabled
) => {
  return {
    id: input.id,
    displayId: input.displayId,
    description: input.description,
    issueDate: input.issueDate,
    paymentTerms: input.paymentTerms,
    ...(isPsaPrpBillingDefaultForProjectEnabled
      ? { taxProfileUri: input.taxProfile?.id }
      : {}),
    invoiceTemplateUri: input.invoiceTemplate?.id,
    poNumber: input.poNumber,
    period: input.period,
    billingAddress: input.billingAddress,
    dueDate: input.dueDate,
    clientUri: input.client.id,
    currencyUri: input.billCurrency.id,
    standardLineItems: input.id ? [] : input.standardLineItems,
    adhocLineItems: input.id ? [] : input.adhocLineItems,
    summarizeColumn: input.summarizeColumnOptions || ['PROJECT'],
    notesForCustomer: input.notesForCustomer,
    internalNotes: input.internalNotes,
    showComments: input.showComments
  };
};

export const formatInvoiceItems = invoiceItems => {
  return invoiceItems.map(lineItem => ({
    id: lineItem.id,
    amount: lineItem.amount.amount,
    projectUri: lineItem.project ? lineItem.project.id : null,
    timesheetPeriod: lineItem.timesheetPeriod ? lineItem.timesheetPeriod : null,
    isAdhoc: lineItem.isAdhoc
  }));
};
