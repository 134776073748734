import {
  ESTIMATED_COST_MAX,
  ESTIMATED_HOURS_MAX
} from '~/modules/common/components/TaskDrawer/EditTask/constants';
import { compareDateObjects } from '~/modules/common/dates/compare';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';

const onNameChange = ({ setFieldValue }) => ({ target: { value: name } }) => {
  setFieldValue('name', name);
};

const onCodeChange = ({ setFieldValue }) => ({ target: { value: code } }) => {
  setFieldValue('code', code);
};

const onStartDateChange = ({ setFieldValue, endDate }) => startDate => {
  setFieldValue('startDate', startDate);
  const isStartDateAfterEndDate = compareDateObjects(startDate, endDate) === 1;

  if (isStartDateAfterEndDate) {
    setFieldValue('endDate', startDate);
  }
};
const onEndDateChange = ({ setFieldValue, startDate }) => endDate => {
  setFieldValue('endDate', endDate);
  const isEndDateBeforeStartDate =
    compareDateObjects(endDate, startDate) === -1;

  if (isEndDateBeforeStartDate) {
    setFieldValue('startDate', endDate);
  }
};

const onTaskOwnerChange = ({ setFieldValue, assignedRole }) => assignedUser => {
  setFieldValue('assignedUser', assignedUser);

  const { id: assignedRoleId } = assignedRole || {};
  const userRole = assignedUser?.projectRoles?.[0]?.projectRole;

  if (userRole && !assignedRoleId) {
    setFieldValue('assignedRole', {
      id: userRole.uri,
      displayText: userRole.name
    });
  }
};

export const onAssignedRoleChange = ({
  setFieldValue,
  assignedRole,
  assignedUser
}) => (role, users) => {
  const { id: assignedRoleId } = assignedRole || {};
  const { id: assigneeId } = assignedUser || {};
  const { id: roleId } = role || {};

  if (!assignedRoleId && !assigneeId && users) {
    const matchingResource = (users || []).find(user =>
      (user.projectRoles || []).find(
        projectRole => projectRole.projectRole.uri === roleId
      )
    );

    if (matchingResource) setFieldValue('assignedUser', matchingResource);
  }
  setFieldValue('assignedRole', role);
};

const onDescriptionChange = ({ setFieldValue }) => ({
  target: { value: description }
}) => {
  setFieldValue('description', description);
};

export const onEstimatedHoursChange = ({
  setFieldValue,
  isPsaRmpTaskAllocation1Enabled
}) => ({ target: { value: v } }) => {
  if (isPsaRmpTaskAllocation1Enabled) {
    setFieldValue('estimatedHours', v && Math.min(v, ESTIMATED_HOURS_MAX));
  } else {
    setFieldValue('estimatedHours', v);
  }
};

export const onEstimatedCostAmountChange = ({
  setFieldValue,
  isPsaRmpTaskAllocation1Enabled
}) => ({ target: { value: v } }) => {
  if (isPsaRmpTaskAllocation1Enabled) {
    setFieldValue('estimatedCost.amount', v && Math.min(v, ESTIMATED_COST_MAX));
  } else {
    setFieldValue('estimatedCost.amount', v);
  }
};

export const onEstimatedCostCurrencyChange = ({ setFieldValue }) => value => {
  setFieldValue('estimatedCost.currency', value);
};

export const onIsMilestoneChange = ({ setFieldValue }) => ({
  target: { checked: c }
}) => {
  setFieldValue('isMilestone', c);
};

export const onTimeAndExpenseEntryTypeChange = ({ setFieldValue }) => value => {
  if (value.id === TIME_AND_EXPENSE_ENTRY_TYPE.NO) {
    setFieldValue('isTimeEntryAllowed', false);
  } else {
    setFieldValue('isTimeEntryAllowed', true);
  }
  setFieldValue('timeAndExpenseEntryType', value);
};

export const onTimesheetAccessChange = ({ setFieldValue }) => value => {
  setFieldValue('timesheetAccess', value);
};

export const onResourceEstimateAdd = ({
  setFieldValue,
  resourceEstimates,
  taskRole
}) => resourceEstimate => {
  const updatedResourceEstimate = { role: taskRole, ...resourceEstimate };

  setFieldValue('resourceEstimates', [
    ...resourceEstimates,
    updatedResourceEstimate
  ]);
};

const useFormChangeHandlers = ({
  setFieldValue,
  customFieldDefinitions,
  values,
  isPsaRmpTaskAllocation1Enabled
}) => ({
  onNameChange: onNameChange({ setFieldValue }),
  onCodeChange: onCodeChange({ setFieldValue }),
  onStartDateChange: onStartDateChange({
    setFieldValue,
    endDate: values.endDate
  }),
  onEndDateChange: onEndDateChange({
    setFieldValue,
    startDate: values.startDate
  }),
  onTaskOwnerChange: onTaskOwnerChange({
    setFieldValue,
    assignedRole: values.assignedRole
  }),
  onAssignedRoleChange: onAssignedRoleChange({
    setFieldValue,
    assignedRole: values.assignedRole,
    assignedUser: values.assignedUser
  }),
  onDescriptionChange: onDescriptionChange({
    setFieldValue
  }),
  onEstimatedHoursChange: onEstimatedHoursChange({
    setFieldValue,
    isPsaRmpTaskAllocation1Enabled
  }),
  onEstimatedCostAmountChange: onEstimatedCostAmountChange({
    setFieldValue,
    isPsaRmpTaskAllocation1Enabled
  }),
  onEstimatedCostCurrencyChange: onEstimatedCostCurrencyChange({
    setFieldValue
  }),
  onIsMilestoneChange: onIsMilestoneChange({ setFieldValue }),
  onTimeAndExpenseEntryTypeChange: onTimeAndExpenseEntryTypeChange({
    setFieldValue
  }),
  onTimesheetAccessChange: onTimesheetAccessChange({
    setFieldValue,
    timesheetAccess: values.timesheetAccess
  }),
  customFieldDefinitions: customFieldDefinitions.map(definition => ({
    ...definition,
    onChange: value => {
      switch (definition.type.uri) {
        case 'urn:replicon:custom-field-type:date': {
          setFieldValue(definition.uri, value);
          break;
        }
        default: {
          setFieldValue(definition.uri, value.target.value);
        }
      }
    }
  })),
  onResourceEstimateAdd: onResourceEstimateAdd({
    setFieldValue,
    resourceEstimates: values.resourceEstimates,
    taskRole: values.assignedRole
  })
});

export default useFormChangeHandlers;
