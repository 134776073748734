import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const LIST_TABLE_SETTINGS_QUERY = gql`
  query Eager_GetTableSettings($tableKey: String!) {
    tableSetting(tableKey: $tableKey) {
      id
      columns
      sort {
        field
        direction
        customFieldUri
      }
    }
  }
`;

const useTableSettings = ({ tableKey, defaultColumns, skip = false }) => {
  const { data, loading, error } = useQuery(LIST_TABLE_SETTINGS_QUERY, {
    variables: { tableKey },
    fetchPolicy: 'network-only',
    skip
  });

  if (!loading && !error && data) {
    if (data.tableSetting.columns && data.tableSetting.columns.length === 0) {
      return {
        data: {
          ...data.tableSetting,
          columns: defaultColumns
        },
        loading,
        error
      };
    }
  }

  return {
    data: data && data.tableSetting ? data.tableSetting : null,
    loading,
    error
  };
};

export default useTableSettings;
