import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BillIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 18,17 H 5.999999999999997 V 15 H 18 Z m 0,-4 H 5.999999999999997 V 11 H 18 Z M 18,9 H 5.999999999999997 V 7 H 18 Z m -15.000000000000003,13 1.500000000000001,-1.5 1.5,1.5 1.5,-1.5 L 8.999999999999996,22 10.5,20.5 12,22 13.5,20.5 15,22 16.5,20.5 18,22 19.5,20.5 21,22 V 2 L 19.5,3.5 18,2 16.5,3.5 15,2 13.5,3.5 12,2 10.5,3.5 8.999999999999996,2 7.499999999999997,3.5 l -1.5,-1.5 -1.5,1.5 L 2.999999999999996,2 Z" />
    </>
  </SvgIcon>
));

export default BillIcon;
