import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  skeleton: {
    height: theme.spacing(8),
    marginTop: theme.spacing(2)
  }
}));

const AdvancedRateCardSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.skeleton}>
      <Skeleton height={24} width={65} />
    </div>
  );
};

AdvancedRateCardSkeleton.propTypes = {};

export default AdvancedRateCardSkeleton;
