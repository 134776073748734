/* eslint-disable react/jsx-max-depth */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { IconButton } from '@material-ui/core';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import { makeStyles, alpha } from '@material-ui/core/styles';
import useIsTagOptionDisabled from '~/modules/tags/hooks/useIsTagOptionDisabled';
import useTagFormHelpers from './hooks/useTagFormHelpers';
import TagsEditKeyFormDropdown from './TagsEditKeyFormDropdown';
import TagsEditValueFormDropdown from './TagsEditValueFormDropdown';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0, 1, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3, 2, 3),
      margin: theme.spacing(1, -3, 1, -3),
      borderBottom: '1px solid hsla(0, 0%, 0%, 0.2)'
    }
  },
  dropdowns: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  input: {
    flexBasis: '49%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      paddingBottom: theme.spacing(1)
    }
  },
  inputContainer: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      paddingBottom: theme.spacing(1)
    },
    width: '49%'
  },
  removeButtonContainer: {
    display: 'flex'
  },
  disabledDropdown: {
    opacity: '35%'
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    position: 'relative',
    flexBasis: '10%',
    minWidth: '10%'
  },
  label: {
    height: theme.spacing(2.5),
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    color: alpha(theme.palette.common.black, 0.57),
    fontWeight: theme.typography.fontWeightBold
  }
}));

const TagsEditFormRow = ({
  tag,
  selectedTags,
  errors = {},
  showDropdownLabels,
  onTagChanged,
  onTagRemoved,
  rowIndex,
  canAddNewOption = true,
  hiddenLabel = true
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const {
    onCreateTag,
    onCreateTagValue,
    onSelectTag,
    onSelectTagValue
  } = useTagFormHelpers({
    tag,
    onTagChanged
  });

  const isTagValuesDisabled = !(tag.tagName || tag.tagId);

  const tagKeyDropdownValue = useMemo(
    () => ({ label: tag.tagName, value: tag.tagId }),
    [tag.tagId, tag.tagName]
  );

  const tagValueDropdownValue = useMemo(
    () => ({ label: tag.tagValue, value: tag.tagValueId }),
    [tag.tagValue, tag.tagValueId]
  );

  const selectedTagIds =
    (selectedTags.length &&
      selectedTags
        .map(selectedTag => selectedTag.tagId)
        .filter(c =>
          /^urn:replicon-tenant:[^:]+:object-extension-tag-definition:.*$/.exec(
            c
          )
        )) ||
    [];

  const isTagKeyDisabled = useIsTagOptionDisabled(selectedTagIds);

  return (
    <div className={classes.root}>
      <div className={classes.dropdowns}>
        <div className={classes.inputContainer}>
          {showDropdownLabels && (
            <div className={classes.label}>
              <FormattedMessage id="tags.key" />
            </div>
          )}
          {
            <TagsEditKeyFormDropdown
              rowIndex={rowIndex}
              value={tagKeyDropdownValue}
              onSelectTag={onSelectTag}
              onCreateTag={onCreateTag}
              errors={errors.tagName}
              isTagKeyDisabled={isTagKeyDisabled}
              canAddNewOption={canAddNewOption}
              hiddenLabel={hiddenLabel}
            />
          }
        </div>
        <div className={classes.inputContainer}>
          {showDropdownLabels && (
            <div className={classes.label}>
              <FormattedMessage id="tags.value" />
            </div>
          )}
          {
            <TagsEditValueFormDropdown
              tag={tag}
              value={tagValueDropdownValue}
              onCreateTagValue={onCreateTagValue}
              onSelectTagValue={onSelectTagValue}
              rowIndex={rowIndex}
              tagKeyDropdownValue={tagKeyDropdownValue}
              isDisabled={isTagValuesDisabled}
              errors={errors.tagValue}
              canAddNewOption={canAddNewOption}
              hiddenLabel={hiddenLabel}
            />
          }
        </div>
      </div>
      <div className={classes.buttonContainer}>
        {showDropdownLabels && <div className={classes.label} />}
        <div className={classes.removeButtonContainer}>
          <IconButton
            data-qe-id={`RemoveTag_${rowIndex}`}
            color="secondary"
            onClick={onTagRemoved}
            aria-label={formatMessage({ id: 'button.delete' })}
          >
            <RemoveCircle />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

TagsEditFormRow.propTypes = {
  rowIndex: PropTypes.number,
  tag: PropTypes.object,
  selectedTags: PropTypes.array,
  errors: PropTypes.object,
  showDropdownLabels: PropTypes.bool,
  onTagChanged: PropTypes.func,
  onTagRemoved: PropTypes.func,
  canAddNewOption: PropTypes.bool,
  hiddenLabel: PropTypes.bool
};

export default TagsEditFormRow;
