export const PROJECT_PERMISSION = {
  EDIT_CLIENT: 'urn:replicon:project-action:edit-client',
  EDIT_PORTFOLIO: 'urn:replicon:project-action:edit-portfolio',
  EDIT_PROGRAM: 'urn:replicon:project-action:edit-program',
  EDIT_PROJECT: 'urn:replicon:project-action:edit-project',
  EDIT_TASK: 'urn:replicon:project-action:edit-tasks',
  VIEW_PROJECT: 'urn:replicon:project-action:view-project',
  VIEW_PROJECT_ESTIMATES: 'urn:replicon:project-action:view-project-estimates',
  VIEW_COST_DATA: 'urn:replicon:project-action:view-cost-data'
};

export const PROJECT_DAL = {
  PORTFOLIO_MANAGER: 'urn:replicon:project-data-access-level:portfolio-manager',
  PROGRAM_MANAGER: 'urn:replicon:project-data-access-level:program-manager',
  PROJECT_ADMIN:
    'urn:replicon:project-data-access-level:project-management-data-access-scope',
  PROJECT_MANAGER: 'urn:replicon:project-data-access-level:project-manager',
  CLIENT_MANAGER: 'urn:replicon:project-data-access-level:client-manager',
  ALL: 'urn:replicon:project-data-access-level:all'
};
