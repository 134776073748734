import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withAvailabilityContextProvider } from '~/modules/resourcing/common/hooks/useAvailabilityContext';
import ResourceRequestUserAllocationRow from './ResourceRequestUserAllocationRow';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    '& > div:last-child > div.resourceRequestUserAllocation ': {
      marginBottom: theme.spacing(0.5)
    }
  },
  timeContainer: {
    flexGrow: 0,
    flexShrink: 0,
    position: 'relative',
    overflow: 'hidden',
    transform: `translateY(-${theme.spacing(0.5)}px)`
  }
}));

const useDynamicItemContainerStyles = makeStyles({
  dynamicContainer: {
    minHeight: ({ dynamicContainerHeight, totalAllocations }) =>
      Math.max(dynamicContainerHeight / Math.max(1, totalAllocations))
  }
});

export const ResourceRequestUserAllocations = ({
  chartDates,
  chartDisplayDateRange,
  displayUnit,
  dynamicContainerHeight,
  isEditable,
  resourceAllocations,
  resourceRequest,
  scale
}) => {
  const classes = useStyles();

  const dynamicClasses = useDynamicItemContainerStyles({
    dynamicContainerHeight,
    totalAllocations: resourceAllocations.length
  });

  const resourceAllocationIds = resourceAllocations.map(alloc => alloc.id);

  return (
    <div className={classes.root}>
      {resourceAllocations.map(resourceAllocation => (
        <div
          key={resourceAllocation.id}
          className={classNames(dynamicClasses, {
            [classes.timeContainer]: resourceAllocations.length > 1
          })}
        >
          <ResourceRequestUserAllocationRow
            resourceAllocation={resourceAllocation}
            chartDisplayDateRange={chartDisplayDateRange}
            isEditable={isEditable}
            resourceRequest={resourceRequest}
            scale={scale}
            chartDates={chartDates}
            displayUnit={displayUnit}
            resourceAllocationIds={resourceAllocationIds}
          />
        </div>
      ))}
    </div>
  );
};

ResourceRequestUserAllocations.propTypes = {
  scale: PropTypes.string.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  chartDates: PropTypes.array.isRequired,
  resourceAllocations: PropTypes.array.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  dynamicContainerHeight: PropTypes.number,
  displayUnit: PropTypes.string
};

export default withAvailabilityContextProvider(ResourceRequestUserAllocations);
