import {
  isoStringToObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';

const sortByDate = (dateA, dateB) => {
  if (dateA < dateB) return 1;
  if (dateA > dateB) return -1;

  return 0;
};

const typeOrder = { CARRYOVER: 0, DEFERRED: 1 };
const sortByType = (typeA, typeB) => typeOrder[typeA] - typeOrder[typeB];

export const getSortedCarryOverSummaryWithTotal = ({
  summaryData,
  revenuePeriodEndDate
}) => {
  const revenuePeriodEndDateObject = mapRepliconDateToUtcObject(
    revenuePeriodEndDate
  );
  const periods = summaryData.map(d => {
    const endDate = d.revenuePeriod
      ? mapRepliconDateToUtcObject(isoStringToObject(d.revenuePeriod.endDate))
      : null;

    return {
      endDate,
      amount: d.amount.amount,
      isCurrentPeriod: Boolean(
        endDate &&
          endDate.year === revenuePeriodEndDateObject.year &&
          endDate.month === revenuePeriodEndDateObject.month
      ),
      type: d.type
    };
  });

  periods.sort((a, b) => sortByType(a.type, b.type));
  periods.sort((a, b) => sortByDate(a.endDate, b.endDate));

  const total = summaryData.reduce(
    (retVal, current) => retVal + current.amount.amount,
    0
  );

  const currencySymbol = summaryData.length
    ? summaryData[0].amount.currency.symbol
    : '';

  return {
    periods,
    totalPeriod: { endDate: revenuePeriodEndDateObject, amount: total },
    currencySymbol
  };
};
