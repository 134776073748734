import React from 'react';
import { FormattedMessage } from 'react-intl';
import User from '~/modules/common/components/User';
import { POLICYTYPE, USER_ACCESS_ROLE } from '~/modules/common/enums';
import { userTag, resourceTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import UserFacetDetails from './UserFacetDetails';

const defaultLabels = {
  label: <FormattedMessage id="search.resource" />,
  resourceNoOptionsText: <FormattedMessage id="userDropdown.noResource" />
};

const withResourceUserFacets = (BaseComponent, policyId) => ({
  setSelected,
  selected,
  className
}) => (
  <BaseComponent
    setSelected={setSelected}
    selected={selected}
    className={className}
    policyId={policyId}
    userAccessRoleUri={USER_ACCESS_ROLE.PROJECT_RESOURCE}
    defaults={defaultLabels}
  />
);

export default {
  tag: userTag,
  makeCriteriaProvider,
  DetailsControl: UserFacetDetails,
  // eslint-disable-next-line react/prop-types
  SuggestionItem: ({ option }) => <User user={option} />
};

const getFacet = policyId => ({
  tag: resourceTag,
  makeCriteriaProvider,
  DetailsControl: withResourceUserFacets(UserFacetDetails, policyId),
  // eslint-disable-next-line react/prop-types
  SuggestionItem: ({ option }) => <User user={option} />
});

export const ResourceFacet = getFacet(POLICYTYPE.RESOURCE_MANAGEMENT);

export const ProjectResourceFacet = getFacet(POLICYTYPE.PROJECT_MANAGEMENT);
