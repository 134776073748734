import { object, string } from 'yup';
import { buildCustomFieldValidationSchema } from '~/modules/customFields/utils';

const STRING_MAX_LENGTH = 255;

export const useValidationSchema = ({
  nameExceededMaxLengthMessage,
  customFieldDefinitions = [],
  nameRequiredMessage,
  intl
}) =>
  object().shape({
    name: string()
      .trim()
      .max(STRING_MAX_LENGTH, nameExceededMaxLengthMessage)
      .required(nameRequiredMessage),
    customFieldValues: buildCustomFieldValidationSchema({
      customFieldDefinitions,
      intl
    })
  });

export default useValidationSchema;
