import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { MAX_DATE, MIN_DATE } from '~/modules/common/const';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import ChevronDateButtons from '~/modules/common/components/ChevronDateButtons';
import { useMeContext } from '~/modules/me/useMeContext';
import ChartDateRangeLabel from '../ChartDateRangeLabel';
import ChartDateRangePicker from '../ChartDateRangePicker';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  label: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize
    },
    padding: theme.spacing(0, 0.5),
    margin: theme.spacing(0, -0.5),
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.action.hover,
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}));

export const ChartDateRangePeriod = ({
  classes: classesOverride,
  onPreviousClick,
  onNextClick,
  scale,
  dateRange,
  setDateRange,
  children,
  setDateValue = false,
  scaleFormat = false
}) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [
    setAnchorEl
  ]);

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handleDateChange = useCallback(
    value => {
      setDateRange(value);
      handleClose();
    },
    [handleClose, setDateRange]
  );

  const disableLeftButton =
    mapIsoStringtoUtcObject(dateRange.startDate.toISODate()) <=
    mapIsoStringtoUtcObject(MIN_DATE);
  const disableRightButton =
    mapIsoStringtoUtcObject(dateRange.endDate.toISODate()) >=
    mapIsoStringtoUtcObject(MAX_DATE);

  return (
    <div className={classes.root}>
      <ChevronDateButtons
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        disableLeftButton={disableLeftButton}
        disableRightButton={disableRightButton}
      />
      <ButtonBase
        data-qe-id="ChartDateRangeLabel"
        className={classes.label}
        aria-owns={isOpen ? 'fixed-date-jump-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        focusRipple={isPsaPrpAccessibilityIssuesEnabled}
      >
        {children || (
          <ChartDateRangeLabel
            scale={scale}
            start={dateRange.startDate}
            end={dateRange.endDate}
            scaleFormat={scaleFormat}
          />
        )}
      </ButtonBase>
      <ChartDateRangePicker
        menuId="fixed-date-jump-menu"
        anchorEl={anchorEl}
        scale={scale}
        dateRange={dateRange}
        onClose={handleClose}
        onChange={handleDateChange}
        setDateValue={setDateValue}
      />
    </div>
  );
};

ChartDateRangePeriod.propTypes = {
  classes: PropTypes.object,
  dateRange: PropTypes.object.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.YEARS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.WEEKS
  ]),
  setDateRange: PropTypes.func.isRequired,
  children: PropTypes.node,
  setDateValue: PropTypes.bool,
  scaleFormat: PropTypes.bool
};

export default ChartDateRangePeriod;
