import { useMeContext } from '~/modules/me';
import { usePortfolioPermissions } from '~/modules/common/hooks/portfolio';
import { usePortfolioPageEditContext } from '~/modules/portfolios/portfolio/PortfolioPageEditContext';

export const useIsPortfolioManagerEditable = ({ isNew, isSubPortfolio }) => {
  const { id } = useMeContext();
  const { canEditAllPortfolios } = usePortfolioPermissions();
  const portfolioPageEditContext = usePortfolioPageEditContext();

  if (canEditAllPortfolios) {
    return true;
  }

  if (isNew) {
    return isSubPortfolio;
  }

  return (
    portfolioPageEditContext?.portfolio?.ancestors.some(
      ({ portfolioManager }) => portfolioManager && portfolioManager.id === id
    ) || false
  );
};

export default useIsPortfolioManagerEditable;
