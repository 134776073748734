/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditSharp';
import { compareDateObjects } from '~/modules/common/dates/compare';
import NoValue from '~/modules/common/components/NoValue';
import {
  mapRepliconDateToUtcObject,
  mapJSDateToRepliconDate
} from '~/modules/common/dates/convert';

const useStyles = makeStyles(theme => ({
  certificateCard: {
    boxShadow: '0 0 0 0'
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  headerRoot: {
    padding: 0
  },
  headerContent: {
    overflow: 'hidden'
  },
  editIcon: {
    margin: 0
  }
}));

export const CertificateReadOnly = ({
  editable,
  certificate: {
    name,
    issuer,
    issueDate,
    expiryDate,
    credentialId,
    credentialUrl,
    expires
  },
  onEdit
}) => {
  const classes = useStyles();
  const today = new Date();
  const linkDataQeId = `${name}_Link`;
  const editButtonQeId = `${name}_Edit`;
  const credentialDescriptionQeId = `${name}_Credentials`;
  const descriptionQeId = `${name}_Description`;

  const expiry = expiryDate
    ? {
        expiryDate: mapRepliconDateToUtcObject(expiryDate).toLocaleString({
          month: 'short',
          year: 'numeric'
        })
      }
    : null;

  const issuedOnDate = issueDate
    ? {
        issueDate: mapRepliconDateToUtcObject(issueDate).toLocaleString({
          month: 'short',
          year: 'numeric'
        })
      }
    : null;

  const isCertificateExpired =
    expiryDate &&
    compareDateObjects(mapJSDateToRepliconDate(today), expiryDate) === 1;

  const isAnyDescriptionValuePresent =
    issueDate || expiryDate || issuer || !expires;
  const isAllDescriptionValuesPresent = issueDate && expiryDate && issuer;

  const issuedOn = issuedOnDate ? (
    <span>
      <FormattedMessage
        id="resourceCertificatesAndLicensesCard.issuedByOn"
        values={issuedOnDate}
      />
    </span>
  ) : null;

  const expiresOn =
    expires && expiry ? (
      <span>
        <FormattedMessage
          id="resourceCertificatesAndLicensesCard.expiresOn"
          values={expiry}
        />
      </span>
    ) : null;

  const doesNotExpire = (
    <span>
      <FormattedMessage id="resourceCertificatesAndLicensesCard.doesntExpire" />
    </span>
  );

  const issuedBy = issuer || <NoValue />;

  const descriptionValues = useMemo(
    () => ({
      issuedOn,
      expiresOn: expires ? expiresOn : doesNotExpire,
      issuer: issuedBy
    }),
    [expires, issuedBy, issuedOn, expiresOn, doesNotExpire]
  );

  const issuedByComponent = issuer ? (
    <FormattedMessage
      id="resourceCertificatesAndLicensesCard.issuedBy"
      values={{ issuer }}
    />
  ) : null;

  const issuedOnComponent = issueDate ? (
    <FormattedMessage
      id={
        issuer
          ? 'resourceCertificatesAndLicensesCard.issuedByOn'
          : 'resourceCertificatesAndLicensesCard.issuedOn'
      }
      values={issuedOnDate}
    />
  ) : null;

  const expiredComponent = (
    <FormattedMessage
      id={
        issuer || issueDate
          ? 'resourceCertificatesAndLicensesCard.issuedByOrOnWithExpired'
          : 'resourceCertificatesAndLicensesCard.expired'
      }
      values={expiry}
    />
  );

  const expiresOnComponent = (
    <FormattedMessage
      id={
        issuer || issueDate
          ? 'resourceCertificatesAndLicensesCard.issuedByOrOnWithExpiresOn'
          : 'resourceCertificatesAndLicensesCard.expiresOn'
      }
      values={expiry}
    />
  );

  const expiryComponent = expiresOn
    ? isCertificateExpired
      ? expiredComponent
      : expiresOnComponent
    : null;

  const credentialIdDescriptionValues = useMemo(
    () => ({
      credentialId,
      credentialUrl: credentialUrl && (
        <Link
          data-qe-id={linkDataQeId}
          href={credentialUrl}
          target="_blank"
          color="default"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="resourceCertificatesAndLicensesCard.seeCredential" />
        </Link>
      )
    }),
    [credentialId, credentialUrl, linkDataQeId]
  );

  return (
    <Card className={classes.certificateCard}>
      <CardHeader
        classes={useMemo(
          () => ({
            title: classes.title,
            root: classes.headerRoot,
            action: classes.editIcon,
            content: classes.headerContent
          }),
          [
            classes.editIcon,
            classes.headerContent,
            classes.headerRoot,
            classes.title
          ]
        )}
        title={name}
        action={
          editable && (
            <IconButton
              aria-label={name}
              data-qe-id={editButtonQeId}
              onClick={onEdit}
            >
              <EditIcon />
            </IconButton>
          )
        }
      />
      <CardContent
        data-qe-id={descriptionQeId}
        classes={useMemo(() => ({ root: classes.contentRoot }), [
          classes.contentRoot
        ])}
      >
        {isAllDescriptionValuesPresent ? (
          <span>
            <FormattedMessage
              id={
                isCertificateExpired
                  ? 'resourceCertificatesAndLicensesCard.alreadyExpiredDescription'
                  : 'resourceCertificatesAndLicensesCard.description'
              }
              values={descriptionValues}
            />
          </span>
        ) : (
          <span>
            {isAnyDescriptionValuePresent ? (
              <>
                {issuedByComponent}
                {issuedOnComponent}
                {expires ? (
                  expiryComponent
                ) : (
                  <FormattedMessage
                    id={
                      issuer || issueDate
                        ? 'resourceCertificatesAndLicensesCard.issuedByOrOnWithDoesNotExpire'
                        : 'resourceCertificatesAndLicensesCard.doesntExpire'
                    }
                  />
                )}
              </>
            ) : null}
          </span>
        )}
        {credentialId && (
          <div data-qe-id={credentialDescriptionQeId}>
            <span>
              <FormattedMessage
                id="resourceCertificatesAndLicensesCard.credentialIdDescription"
                values={credentialIdDescriptionValues}
              />
            </span>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

CertificateReadOnly.propTypes = {
  editable: PropTypes.bool.isRequired,
  certificate: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default CertificateReadOnly;
