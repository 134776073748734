import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { updateResourceAssignmentsInCache } from './updateResourceAssignmentsInCache';
import { useFetchProjectRequestField } from './useFetchProjectRequestField';

export const PUT_PROJECT_REQUEST_RESOURCE_ASSIGNMENTS = gql`
  mutation PutResourceAssignments(
    $input: PutProjectRequestResourceAssignmentInput!
  ) {
    putProjectRequestResourceAssignments(input: $input) {
      projectRequestId
      added {
        assignmentUri
        parameterCorrelationId
      }
    }
  }
`;

const putResourceAssignments = ({
  projectRequestId,
  inputResourceAssignments
}) => (cache, { data }) => {
  const {
    putProjectRequestResourceAssignments: resourceAssignementsFromResponse
  } = data;
  const updatedResourceAssignments = inputResourceAssignments.map(e => {
    return {
      ...e,
      id:
        e.id ||
        resourceAssignementsFromResponse.added.find(
          element => e.rowId === element.parameterCorrelationId
        )?.assignmentUri
    };
  });

  updateResourceAssignmentsInCache({
    projectRequestId,
    resourceAssignments: updatedResourceAssignments
  })(cache);
};

export const usePutResourceAssignments = ({ projectRequestId }) => {
  const { fetchProjectRequestField } = useFetchProjectRequestField(
    projectRequestId
  );
  const [putProjectRequestResourceAssignments] = useMutation(
    PUT_PROJECT_REQUEST_RESOURCE_ASSIGNMENTS,
    {
      onCompleted: fetchProjectRequestField
    }
  );

  return {
    putResourceAssignments: useCallback(
      ({ resourceAssignments, deletedResourceAssignmentsUris }) =>
        putProjectRequestResourceAssignments({
          variables: {
            input: {
              projectRequestId,
              resourceAssignmentsToUpdate: resourceAssignments.map(
                resourceAssignment => ({
                  assignmentUri: resourceAssignment.id,
                  projectRoleUri: resourceAssignment.role.id,
                  quantity: resourceAssignment.quantity,
                  ...(resourceAssignment.id
                    ? {}
                    : { parameterCorrelationId: resourceAssignment.rowId })
                })
              ),
              resourceAssignmentsUrisToRemove: deletedResourceAssignmentsUris
            }
          },
          update: putResourceAssignments({
            projectRequestId,
            inputResourceAssignments: resourceAssignments
          })
        }),
      [projectRequestId, putProjectRequestResourceAssignments]
    )
  };
};

export default usePutResourceAssignments;
