import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BatchStatus } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  progress: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    background: theme.palette.grey[200],
    color: theme.palette.text.secondary
  },
  spinner: {
    marginRight: theme.spacing(3)
  }
}));

export const InProgress = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.progress}>
      <CircularProgress
        data-qe-id="revenueRecognition_batchInProgress"
        size={30}
        className={classes.spinner}
      />
      <Typography>
        <FormattedMessage id="projectRevenueCard.recalculatingRevenue" />
      </Typography>
    </Grid>
  );
};

export const RevenueRecalculateBatch = ({
  onBatchComplete,
  batchState,
  setBatchState
}) => {
  return (
    <BatchStatus
      batchState={batchState}
      setBatchState={setBatchState}
      onBatchComplete={onBatchComplete}
    >
      <InProgress />
    </BatchStatus>
  );
};

RevenueRecalculateBatch.propTypes = {
  setBatchState: PropTypes.func.isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  batchState: PropTypes.object
};

export default RevenueRecalculateBatch;
