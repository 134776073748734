import React from 'react';
import { PropTypes } from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { withStyles } from '@material-ui/core';
import useWaypointOffsetHeight from '~/modules/common/hooks/useWaypointOffsetHeight';

import { LeftColumn, GroupedColumns, RowTotalsColumn } from './columns';

const styles = theme => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    borderRadius: '8px',
    borderLeft: `solid 1px ${theme.palette.divider}`
  }
});

const calculateMinRowHeights = (settings, records) => {
  const heightMap = {};

  for (const record of records) {
    let i = 0;

    for (const row of record.rows) {
      // eslint-disable-next-line max-depth
      for (const key of Object.keys(row.cells)) {
        heightMap[i] = Math.max(
          settings.columns.minHeight[key](row.cells[key]) * 17,
          heightMap[i] || 0
        );
        heightMap.footer = Math.max(settings.column);
      }
      i += 1;
    }

    for (const key of Object.keys(record.totals)) {
      heightMap.footer = Math.max(
        settings.columns.minHeight[key](record.totals[key]) * 17,
        heightMap.footer || 0
      );
    }
  }

  return {
    ...settings,
    heightMap
  };
};

const GroupedColumnarTable = ({
  intl,
  classes,
  settings,
  records,
  rowTotals,
  hasMore,
  hasMoreSkeletonSize = 3,
  loadMore
}) => {
  const $settings = calculateMinRowHeights(settings, records);

  const firstRecord = records && records.length > 0 ? records[0] : { rows: [] };
  const rowCount = firstRecord.rows.length;

  const { offsetHeight, containerRef } = useWaypointOffsetHeight({
    heightFactor: 0.5,
    records: records[0].rows || []
  });

  const skeletons = Array.from(Array(hasMoreSkeletonSize), (_, index) => index);

  return (
    <>
      <div className={classes.container} ref={containerRef}>
        <LeftColumn
          intl={intl}
          settings={$settings}
          record={firstRecord}
          hasMore={hasMore}
          skeletons={skeletons}
        />
        <GroupedColumns
          settings={$settings}
          records={records}
          hasMore={hasMore}
          skeletons={skeletons}
        />
        {rowTotals && (
          <RowTotalsColumn
            intl={intl}
            settings={$settings}
            rowTotals={rowTotals}
            hasMore={hasMore}
            skeletons={skeletons}
          />
        )}
      </div>
      {hasMore && loadMore && (
        <div>
          <Waypoint
            bottomOffset={`${offsetHeight}px`}
            onEnter={loadMore}
            key={rowCount}
          />
        </div>
      )}
    </>
  );
};

GroupedColumnarTable.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  settings: PropTypes.object,
  records: PropTypes.array,
  rowTotals: PropTypes.object,
  hasMore: PropTypes.bool,
  hasMoreSkeletonSize: PropTypes.number,
  loadMore: PropTypes.func
};

export default withStyles(styles)(GroupedColumnarTable);
