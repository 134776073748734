import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { assignedUserText } from '../components/Gantt/hooks/useGanttData';
import { updateGanttTaskAssignedRole } from './useOnAssignRole';

export const updateGanttTaskAssignedUser = (gantt, taskId, user, intl) => {
  const task = gantt.getTask(taskId);

  task.assignee = user
    ? {
        id: user.id,
        displayText: user.displayText
      }
    : null;

  task.assigneeText = assignedUserText({ user, intl });

  gantt.updateTask(taskId);
};

export const useOnAssignUser = ({
  assignUserToTask,
  assignRoleToTask,
  closeAssignmentPopover,
  taskId,
  ganttRef
}) => {
  const intl = useIntl();

  return useCallback(
    user => {
      const { assignee, assignedRole, assignedUserRoleId } = ganttRef.getTask(
        taskId
      );
      const assignedUser = assignee
        ? {
            ...assignee,
            key: assignedUserRoleId
              ? `${assignee.id}-${assignedUserRoleId}`
              : `${assignee.id}-no-role`
          }
        : null;
      const { key: assigneeKey } = assignedUser || {};
      const { key: userKey } = user || {};

      if (assigneeKey !== userKey) {
        assignUserToTask(taskId, user);
      }

      const { id: assignedRoleId } = assignedRole || {};

      if (userKey && !assignedRoleId) {
        const userRole = user?.projectRoles?.[0]?.projectRole;

        if (userRole) {
          const role = {
            ...userRole,
            id: userRole.uri,
            displayText: userRole.name
          };

          assignRoleToTask(taskId, role);
          updateGanttTaskAssignedRole(ganttRef, taskId, role, intl);
        }
      }
      closeAssignmentPopover();
      updateGanttTaskAssignedUser(ganttRef, taskId, user, intl);
    },
    [
      assignRoleToTask,
      assignUserToTask,
      closeAssignmentPopover,
      ganttRef,
      intl,
      taskId
    ]
  );
};

export default useOnAssignUser;
