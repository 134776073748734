import React from 'react';
import PropTypes from 'prop-types';
import { CostCenterIcon } from '~/modules/common/components/Icons';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupName from './GroupName';

export const CostCenterName = ({
  value,
  costCenterSettings,
  variant,
  showFullPath,
  disableIcon
}) => (
  <GroupName
    value={value}
    Icon={CostCenterIcon}
    groupSettings={costCenterSettings}
    variant={variant}
    showFullPath={showFullPath}
    disableIcon={disableIcon}
  />
);

CostCenterName.propTypes = {
  value: PropTypes.object,
  costCenterSettings: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['icon', 'avatar']),
  showFullPath: PropTypes.bool,
  disableIcon: PropTypes.bool
};

export default withGroupSettings(
  'costCenter',
  'costCenterSettings'
)(CostCenterName);
