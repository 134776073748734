import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { RoleName } from '~/modules/common/components/entities/role';
import useRoleSearch from '~/modules/common/components/RolesMenuListDropdown/useRoleSearch';
import RolesSearchMenuList from '~/modules/common/components/RolesMenuListDropdown/RolesSearchMenuList';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { AddRoleDialog } from '~/modules/resourcing/common/components/SearchableRoleDropDown/AddRoleDialog';
import { useHasPermission } from '~/modules/common/permissions';
import useTaskOwnerSearch from '~/modules/tasks/components/TaskOwnerDropDown/useTaskOwnerSearch';
import MoreResult from '~/modules/common/components/MoreResult';
import CreatableOption from './CreatableOption';

const useStyles = makeStyles(theme => ({
  menu: {
    maxWidth: 320
  },
  title: {
    padding: theme.spacing(1, 2)
  }
}));

const noRoleLabel = <FormattedMessage id="rolesDropdown.noRole" />;

export const RoleAssignmentMenu = ({
  open,
  onClose,
  onChange,
  projectSlug,
  anchorPosition
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { loading: isTaskOwnersLoading, users } = useTaskOwnerSearch({
    projectSlug
  });

  const { open: newRoleDialogOpen, closeDialog, openDialog } = useDialogState();
  const openDialogAndSetInitialName = useCallback(
    name => {
      openDialog();
      onClose();
    },
    [onClose, openDialog]
  );

  const {
    hasMore,
    loading,
    activeRoles,
    searchText,
    updateSearchText
  } = useRoleSearch({
    includeSkills: false
  });

  const onSearchChange = useCallback(
    event => updateSearchText(event.target.value || ''),
    [updateSearchText]
  );

  const makeOnChange = useCallback(role => () => onChange(role, users), [
    users,
    onChange
  ]);

  const isLoading = isTaskOwnersLoading && loading;

  const MenuListProps = useMemo(
    () => ({
      component: RolesSearchMenuList,
      searchText,
      onSearchChange,
      loading: isLoading
    }),
    [searchText, onSearchChange, isLoading]
  );

  const menuClasses = useMemo(
    () => ({
      paper: classes.menu
    }),
    [classes.menu]
  );

  const canCreateRole = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:edit-project-role'
  });

  const handleRoleAdd = useCallback(role => onChange(role, users), [
    users,
    onChange
  ]);

  const moreAvailableText = intl.formatMessage(
    {
      id: 'searchAutocomplete.moreAvailable'
    },
    {
      option: intl.formatMessage({
        id: 'moreOptions.roles'
      })
    }
  );

  return (
    <>
      <Menu
        classes={menuClasses}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={open}
        onClose={onClose}
        MenuListProps={MenuListProps}
      >
        <MenuItem value="" onClick={makeOnChange()}>
          <Typography variant="body2" color="textSecondary">
            {noRoleLabel}
          </Typography>
        </MenuItem>
        {activeRoles.map(role => (
          <MenuItem key={role.id} value={role.id} onClick={makeOnChange(role)}>
            <RoleName role={role} showRate={false} />
          </MenuItem>
        ))}
        {canCreateRole && !isLoading && (
          <CreatableOption
            inputValue=""
            onCreateOption={openDialogAndSetInitialName}
            label={intl.formatMessage({
              id: 'addRoleDialog.role'
            })}
          />
        )}
        {hasMore && !loading && (
          <MenuItem value="more-available" key="more-available" disabled>
            <MoreResult message={moreAvailableText} />
          </MenuItem>
        )}
      </Menu>
      <AddRoleDialog
        open={newRoleDialogOpen}
        onClose={closeDialog}
        onChange={handleRoleAdd}
        initialName=""
      />
    </>
  );
};

RoleAssignmentMenu.propTypes = {
  anchorPosition: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  projectSlug: PropTypes.string
};

export default RoleAssignmentMenu;
