import React, { useMemo, useCallback } from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import SummaryTabLabel from '~/modules/common/components/QuickFilterSummaryTabs/SummaryTabLabel';

import { createTabWidthStyles } from '~/modules/common/components/QuickFilterSummaryTabs/summaryFilterUtil';
import {
  useIsBreakpointDown,
  useProjectsStatusCountSummaryQuery
} from '~/modules/common/hooks';

import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { useMeContext } from '~/modules/me';
import { projectTypeTabs } from './projectTypeTabs';

const useStyles = isPsaPrpRenameProjectStatus2Enabled =>
  makeStyles(theme => {
    const shadeColor = alpha(theme.palette.action.active, 0.12);

    return {
      toggleButton: {
        color: theme.palette.text.primary,
        opacity: 0.7,
        '&$toggleButtonSelected': {
          border: 'none'
        },
        '&:not(:first-child)': {
          marginLeft: 0
        },
        ...createTabWidthStyles(theme, isPsaPrpRenameProjectStatus2Enabled)
      },
      toggleButtonSelected: {
        opacity: 1
      },
      selectRoot: {
        margin: 0
      },
      select: {
        fontSize: theme.typography.body2.fontSize,
        minHeight: theme.spacing(5),
        padding: theme.spacing(0, 3, 0, 1.5),
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.action.active,
        [theme.breakpoints.down('xs')]: {
          fontSize: theme.typography.caption.fontSize,
          paddingLeft: theme.spacing(1),
          '&&': {
            paddingRight: theme.spacing(2.5)
          }
        },
        ...createTabWidthStyles(theme, isPsaPrpRenameProjectStatus2Enabled)
      },
      notchedOutline: {
        borderColor: shadeColor
      }
    };
  });

export const ProjectTypeToggle = ({
  projectType,
  searchCriteria,
  onProjectTypeClick,
  excludePrograms,
  excludePortfolios,
  className
}) => {
  const {
    featureFlags: { isPsaPrpRenameProjectStatus2Enabled }
  } = useMeContext();

  const classes = useStyles(isPsaPrpRenameProjectStatus2Enabled)();

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    excludePrograms,
    excludePortfolios
  });

  const {
    loading: isManageCountLoading,
    projectsStatusCountSummary: { total: manageTotal = 0 }
  } = useProjectsStatusCountSummaryQuery({
    isManaged: true,
    projectFilter
  });

  const {
    loading: isUnmanageCountLoading,
    projectsStatusCountSummary: { total: unmanageTotal = 0 }
  } = useProjectsStatusCountSummaryQuery({
    isManaged: false,
    projectFilter
  });

  const isMobile = useIsBreakpointDown('sm');
  const tabs = projectTypeTabs({
    managed: manageTotal,
    unmanaged: unmanageTotal
  });

  const handleDropdownChange = useCallback(
    event => {
      const { value } = event.target;

      if (onProjectTypeClick && tabs.some(item => item.key === value))
        onProjectTypeClick(null, value);
    },
    [tabs, onProjectTypeClick]
  );

  const toggleButtonGroupClasses = useMemo(
    () => ({
      root: classes.toggleButton,
      selected: classes.toggleButtonSelected
    }),
    [classes.toggleButton, classes.toggleButtonSelected]
  );

  const selectClasses = useMemo(
    () => ({
      root: classes.selectRoot,
      select: classes.select
    }),
    [classes.selectRoot, classes.select]
  );

  const input = useMemo(
    () => (
      <OutlinedInput
        labelWidth={0}
        classes={{ notchedOutline: classes.notchedOutline }}
      />
    ),
    [classes.notchedOutline]
  );

  const menuProps = useMemo(
    () => ({
      MenuListProps: {
        dense: true,
        disablePadding: true
      }
    }),
    []
  );

  const isLoading = isManageCountLoading || isUnmanageCountLoading;

  return isMobile ? (
    <FormControl className={className}>
      <Select
        classes={selectClasses}
        value={projectType}
        onChange={handleDropdownChange}
        input={input}
        MenuProps={menuProps}
      >
        {tabs.map(tab => (
          <MenuItem key={tab.key} value={tab.key}>
            <SummaryTabLabel
              label={tab.label}
              value={tab.value}
              isValueLoading={isLoading}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <ToggleButtonGroup
      size="small"
      role="tablist"
      exclusive
      value={projectType}
      onChange={onProjectTypeClick}
    >
      {tabs.map(tab => (
        <ToggleButton
          key={tab.key}
          role="tab"
          value={tab.key}
          classes={toggleButtonGroupClasses}
          data-qe-id={tab['data-qe-id']}
          aria-pressed={null}
          aria-selected={projectType === tab.key ? 'true' : 'false'}
        >
          <SummaryTabLabel
            label={tab.label}
            value={tab.value}
            isValueLoading={isLoading}
          />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

ProjectTypeToggle.propTypes = {
  projectType: PropTypes.string,
  searchCriteria: PropTypes.object,
  onProjectTypeClick: PropTypes.func,
  excludePrograms: PropTypes.array,
  excludePortfolios: PropTypes.array,
  className: PropTypes.string
};

export default ProjectTypeToggle;
