import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { getDayViewRoundedHours } from '~/modules/resourcing/common/util/scheduleUtil';
import { TimelineBlock } from '~/modules/resourcing/common/chart/components';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import ResourceUserRequestedCostAllocationTooltip from './ResourceUserRequestedCostAllocationTooltip';
import { ResourceAllocationSummaryBlockContainer } from './ResourceAllocationSummaryBlockContainer';

export const ResourceAllocationHoursSummaryBlock = ({
  allocation,
  allocationPeriod,
  containerClasses,
  dragDelta,
  dragIndicatorClasses,
  dragIndicatorDates,
  gestureBindEvents,
  handleAllocationPeriodClick,
  overlayPeriods,
  allocationPeriods,
  resizeDirection,
  resourceAvailabilitySummary,
  resourceRequest,
  scale,
  showResizeIndicator,
  timelineClassName,
  hideCost
}) => {
  const {
    startDate,
    endDate,
    hasAllocationStart,
    hasAllocationEnd,
    totalHours,
    totalCost,
    totalScheduleHours
  } = allocationPeriod;

  const loading = totalScheduleHours
    ? (totalHours * 100) / totalScheduleHours
    : 0;

  const handleClickAndKeyPress = useCallback(
    event => {
      if (
        event.type === 'click' ||
        event.key === 'Enter' ||
        event.keyCode === 13
      ) {
        if (handleAllocationPeriodClick) {
          handleAllocationPeriodClick({
            allocation,
            endDate,
            event,
            hasAllocationEnd,
            hasAllocationStart,
            isOverlay: false,
            overlayPeriods,
            requestPeriods: allocationPeriods,
            resourceAvailabilitySummary,
            startDate,
            totalAllocatedHours: totalHours,
            totalScheduledHours: totalScheduleHours
          });
        }
      }
    },
    [
      allocation,
      endDate,
      handleAllocationPeriodClick,
      hasAllocationEnd,
      hasAllocationStart,
      overlayPeriods,
      allocationPeriods,
      resourceAvailabilitySummary,
      startDate,
      totalHours,
      totalScheduleHours
    ]
  );

  const toolTipStartDate = hasAllocationStart
    ? mapIsoStringtoUtcObject(allocation.startDate)
    : startDate;
  const toolTipEndDate = hasAllocationEnd
    ? mapIsoStringtoUtcObject(allocation.endDate)
    : endDate;

  const title = useMemo(() => {
    return (
      <ResourceUserRequestedCostAllocationTooltip
        totalAllocatedHours={totalHours}
        loading={loading}
        totalCost={totalCost}
        startDate={toolTipStartDate}
        endDate={toolTipEndDate}
        resourceRequest={resourceRequest}
        hideCost={hideCost}
      />
    );
  }, [
    totalHours,
    loading,
    totalCost,
    toolTipStartDate,
    toolTipEndDate,
    resourceRequest
  ]);

  const roandedAllocatedHours = getDayViewRoundedHours({
    hours: totalHours,
    scale
  });

  return (
    <ResourceAllocationSummaryBlockContainer
      allocationPeriod={allocationPeriod}
      classes={containerClasses}
      dragDelta={dragDelta}
      dragIndicatorClasses={dragIndicatorClasses}
      dragIndicatorDates={dragIndicatorDates}
      gestureBindEvents={gestureBindEvents}
      resizeDirection={resizeDirection}
      showResizeIndicator={showResizeIndicator}
    >
      <Tooltip title={title}>
        <div
          className={timelineClassName}
          role="button"
          onClick={handleClickAndKeyPress}
          tabIndex="0"
          onKeyPress={handleClickAndKeyPress}
          aria-hidden="true"
        >
          <TimelineBlock
            dataQeId={`${allocation.user.user.displayText}_summaryBlocks`}
            hours={roandedAllocatedHours}
            showPercentage={false}
          />
        </div>
      </Tooltip>
    </ResourceAllocationSummaryBlockContainer>
  );
};

ResourceAllocationHoursSummaryBlock.propTypes = {
  allocation: PropTypes.object.isRequired,
  allocationPeriod: PropTypes.object.isRequired,
  containerClasses: PropTypes.object,
  dragDelta: PropTypes.number,
  dragIndicatorClasses: PropTypes.object,
  dragIndicatorDates: PropTypes.object,
  gestureBindEvents: PropTypes.func,
  handleAllocationPeriodClick: PropTypes.func,
  overlayPeriods: PropTypes.array,
  allocationPeriods: PropTypes.array,
  resizeDirection: PropTypes.string,
  resourceAvailabilitySummary: PropTypes.object,
  resourceRequest: PropTypes.object,
  scale: PropTypes.string.isRequired,
  showResizeIndicator: PropTypes.bool,
  timelineClassName: PropTypes.string,
  hideCost: PropTypes.bool
};
export default ResourceAllocationHoursSummaryBlock;
