import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';
import { VariationPillbox } from './VariationPillbox';

const usePillStylesOverride = makeStyles({
  OVER: {
    backgroundColor: theme.palette.error.lightBackground
  },
  UNDER: {
    backgroundColor: theme.palette.success.lightBackground
  },
  MATCHING: {
    backgroundColor: theme.palette.grey[200]
  },
  variationPill: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 0
  },
  variationIcon: {},
  variationContainer: {},
  variationValue: {},
  variationLabel: {}
});

export const CostVariationPillbox = ({
  intl,
  variationStatus,
  variationValue,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  numberFormat,
  isPsaRmpTaskAllocation1Enabled,
  pillClassesOverride
}) => {
  const pillClasses = usePillStylesOverride({
    classes: isPsaRmpTaskAllocation1Enabled ? pillClassesOverride : {}
  });

  return (
    <VariationPillbox
      intl={intl}
      isRTL={isRTL}
      pillClasses={pillClasses}
      variationStatus={variationStatus}
      variationValue={variationValue}
      variant="cost"
      isPsaPpmCostEacEnhancementsEnabled={isPsaPpmCostEacEnhancementsEnabled}
      isPsaPpmCostEacEnhancements2Enabled={isPsaPpmCostEacEnhancements2Enabled}
      numberFormat={numberFormat}
      isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
    />
  );
};
CostVariationPillbox.propTypes = {
  intl: PropTypes.object.isRequired,
  isRTL: PropTypes.bool.isRequired,
  variationStatus: PropTypes.oneOf([
    ESTIMATED_HOURS_VARIATION_STATUS.MATCHING,
    ESTIMATED_HOURS_VARIATION_STATUS.OVER,
    ESTIMATED_HOURS_VARIATION_STATUS.UNDER,
    ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE
  ]),
  variationValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  numberFormat: PropTypes.object,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  pillClassesOverride: PropTypes.object
};

export default CostVariationPillbox;
