/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useStyledPoolIcon } from '~/modules/resourcing/common/components/ResourcePool/hooks';
import { useStyles } from './useStyles';

const isEmptyArrayOrNull = value =>
  (Array.isArray(value) && value.length === 0) || !value;

const displayValue = value =>
  value.length === 1 ? (
    <FormattedMessage
      id="resourceRequestDrawerDetails.resourcePoolsSet.singular"
      values={{ value: value[0].displayText }}
    />
  ) : (
    <FormattedMessage
      id="resourceRequestDrawerDetails.resourcePoolsSet.count"
      values={{ count: value.length }}
    />
  );

export const ResourcePoolTitle = ({ resourcePools }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const resourcePoolLabel = formatMessage({
    id: 'groups.resourcePools'
  });

  const icon = useStyledPoolIcon({ omitColor: true });
  const displayText = displayValue(resourcePools);

  return (
    <span
      key="resourcePoolContent"
      className={classNames({
        [classes.requiredMissing]: isEmptyArrayOrNull(resourcePools)
      })}
    >
      <span className={classes.iconContainer}>{icon}</span>
      <span className={classes.text}>
        {isEmptyArrayOrNull(resourcePools) ? resourcePoolLabel : displayText}
      </span>
    </span>
  );
};

ResourcePoolTitle.propTypes = {
  resourcePools: PropTypes.array
};

export default ResourcePoolTitle;
