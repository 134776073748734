import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ResourcingCurrentDateLine } from '~/modules/resourcing/common/components';
import {
  itemWidth,
  actionsWidth
} from '~/modules/common/charts/timeline/calculations';
import { ResourceRequestToolbar } from '../components';
import {
  useResourceRequestToolbarContext,
  useSelectedResourceRequestContext
} from '../hooks';
import ResourceAllocationChart2 from '../ResourceRequestChart/ResourceAllocationChart2';
import ResourceRequestChart2 from '../ResourceRequestChart/ResourceRequestChart2';
import { ResourceRequestChartHeader } from '../ResourceRequestChart/components';
import ResourceRequestDrawer from '../ResourceRequestChart/components/ResourceRequestDrawer/ResourceRequestDrawer2';

const LEFT_WIDTH = 464;
const FOOTER_HEIGHT = 40;

const leftComponentWidth = actionsWidth + itemWidth * 2;

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative'
  }
}));

const DesktopResourcingPlanPage = ({
  isEditSwitchEnabled,
  openAddResourceRequestDialog
}) => {
  const {
    scale,
    chartDates,
    displayUnit,
    sort,
    onSortChange
  } = useResourceRequestToolbarContext();

  const classes = useStyles();
  const allocationChartRef = useRef();

  const {
    selectedResourceRequestId,
    closeResourceRequestDrawer,
    isResourceRequestDrawerOpen
  } = useSelectedResourceRequestContext();

  return (
    <>
      <ResourceRequestToolbar />
      <div className={classes.container}>
        <ResourcingCurrentDateLine
          scale={scale}
          chartDates={chartDates}
          leftWidth={LEFT_WIDTH}
          footerHeight={FOOTER_HEIGHT}
        />
        <ResourceRequestChartHeader
          chartDates={chartDates}
          scale={scale}
          leftComponentWidth={leftComponentWidth}
          isEditSwitchEnabled={isEditSwitchEnabled}
          displayUnit={displayUnit}
          sort={sort}
          onSortChange={onSortChange}
        />
        <ResourceRequestChart2
          isEditSwitchEnabled={isEditSwitchEnabled}
          handleAddResourceRequestClick={openAddResourceRequestDialog}
          allocationChartRef={allocationChartRef}
        />
        <ResourceAllocationChart2
          isEditSwitchEnabled={isEditSwitchEnabled}
          ref={allocationChartRef}
        />
        {isResourceRequestDrawerOpen && (
          <ResourceRequestDrawer
            resourceRequestId={selectedResourceRequestId}
            allocationChartRef={allocationChartRef}
            onClose={closeResourceRequestDrawer}
            isEditSwitchEnabled={isEditSwitchEnabled}
            open={isResourceRequestDrawerOpen}
          />
        )}
      </div>
    </>
  );
};

DesktopResourcingPlanPage.propTypes = {
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  openAddResourceRequestDialog: PropTypes.func.isRequired
};

export default DesktopResourcingPlanPage;
