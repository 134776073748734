import { DialogContent } from '@material-ui/core';
import { Formik } from 'formik';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import DialogButtonActions from '~/modules/common/components/DialogButtonActions';
import { useFormState } from '../ProjectRequestDrawer/hooks';
import AddProjectRequestForm from './AddProjectRequestForm';

const addProjectRequest = <FormattedMessage id="projectRequest.addRequest" />;

export const AddProjectRequestDialogContent = ({
  onClose,
  assignedPortfolioId
}) => {
  const { initialValues, validationSchema, onSubmit } = useFormState({
    onClose,
    assignedPortfolioId
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
    >
      <>
        <DialogContent>
          <AddProjectRequestForm
            hidePortfolioDropdown={Boolean(assignedPortfolioId)}
          />
        </DialogContent>
        <DialogButtonActions
          onClose={onClose}
          disableOnError={false}
          cancelButtonDataQeId="AddProjectRequestCancel"
          addButtonDataQeId="AddProjectRequestButton"
          primaryButtonMessage={addProjectRequest}
        />
      </>
    </Formik>
  );
};

AddProjectRequestDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  assignedPortfolioId: PropTypes.string
};

export default AddProjectRequestDialogContent;
