import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const CLIENT_DEFAULT_DATA = gql`
  query client($uri: String!, ${`$isPsaPrpBillingDefaultForProjectEnabled: Boolean = false`}) {
    client(uri: $uri) {
      id
      displayText
      defaultInvoicePaymentTerm
      invoiceTemplate {
        id
        displayText
      }
      taxProfile @include(if: $isPsaPrpBillingDefaultForProjectEnabled) {
        id
        displayText
        description
        enabled
      }
      invoiceCurrency {
        id
        displayText
        symbol
      }
      billingContactInfo {
        address
        city
        country {
          id
          displayText
        }
        email
        faxNumber
        phoneNumber
        stateProvince
        website
        zipPostalCode
      }
      billingSettings {
        billLineItemsBy
        description
        internalNotes
        showComments
      }
    }
  }
`;

export const useClientDefaultData = ({ client }) => {
  const isPsaPrpBillingDefaultForProjectEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpBillingDefaultForProjectEnabled'
  });
  const { id } = client || {};
  const { data, loading } = useQuery(CLIENT_DEFAULT_DATA, {
    variables: {
      uri: id,
      isPsaPrpBillingDefaultForProjectEnabled
    },
    skip: !id,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });
  const clientDefaultData = get(data, 'client', null);

  return {
    clientDefaultData,
    loading
  };
};

export default useClientDefaultData;
