import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { TableFooter as MuiTableFooter } from '@material-ui/core';
import ListTableVariantContext from '../../../ListTableVariantContext';

const TableFooter = ({ children, ...rest }) => {
  const variant = useContext(ListTableVariantContext);

  const Component = variant === 'table' ? MuiTableFooter : 'div';

  return <Component {...rest}>{children}</Component>;
};

TableFooter.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any
};

export default TableFooter;
