import { isoStringToObjectWithCache as isoStringToObject } from '~/modules/common/dates/convert';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';

export const sumHours = (accum, { hoursWorked }) => accum + hoursWorked;

export const extractPathFromFullPath = fullPath =>
  fullPath.map(({ displayText }) => displayText).slice(0, -1);

export const transformTaskEntry = ({
  quality,
  submittedTimestamp,
  override,
  totalWorkToDate,
  hoursWorked,
  ...taskEntry
}) => ({
  quality: parseInt(quality, 10),
  submittedTimestamp,
  submittedDate: isoStringToObject(submittedTimestamp),
  override: override ? transformOverride(override) : null,
  totalHoursWorked: totalWorkToDate + hoursWorked,
  hoursWorked,
  ...taskEntry
});

export const transformTaskEntries = taskEntries =>
  taskEntries.map(transformTaskEntry);

export const transformOverride = ({ quality, ...rest }) => ({
  quality: Number.parseInt(quality, 10) || null,
  ...rest
});

export const transformPath = ({ projectReference, fullPath }) => [
  projectReference.displayText,
  ...extractPathFromFullPath(fullPath)
];

export const transformTask = ({
  taskEntries,
  fullPath,
  projectReference,
  startDate,
  endDate,
  totalWorkToDate,
  progressStatus,
  override,
  closedOnDate,
  ...task
}) => {
  const projectCurrency = task.project?.defaultBillingCurrency;

  return {
    ...task,
    projectReference,
    path: transformPath({ projectReference, fullPath }),
    status: progressStatus,
    startDate: isoStringToObject(startDate),
    endDate: isoStringToObject(endDate),
    totalHoursWorked: totalWorkToDate + taskEntries.reduce(sumHours, 0),
    taskEntries: transformTaskEntries(taskEntries),
    title: task.displayText,
    closedOnDate: isoStringToObject(closedOnDate),
    totalWorkToDate,
    timeAndExpenseEntryType:
      task && task.isTimeEntryAllowed
        ? task.timeAndExpenseEntryType
        : { uri: TIME_AND_EXPENSE_ENTRY_TYPE.NO },
    children: task.children
      ? task.children.map(
          ({
            closedOnDate: childClosedOnDate,
            estimatedCompletionDate,
            rolledUpCostSummary,
            ...rest
          }) => ({
            ...rest,
            rolledUpCostSummary: mapToRolledUpCostSummaryWithDefaults({
              rolledUpCostSummary,
              projectCurrency
            }),
            closedOnDate: isoStringToObject(childClosedOnDate),
            estimatedCompletionDate: isoStringToObject(estimatedCompletionDate)
          })
        )
      : [],
    rolledUpCostSummary:
      task.rolledUpCostSummary &&
      mapToRolledUpCostSummaryWithDefaults({
        rolledUpCostSummary: task.rolledUpCostSummary,
        projectCurrency
      })
  };
};

const mapToRolledUpCostSummaryWithDefaults = ({
  rolledUpCostSummary,
  projectCurrency
}) => {
  return {
    ...rolledUpCostSummary,
    actualCostInProjectCurrency: rolledUpCostSummary?.actualCostInProjectCurrency || {
      amount: 0,
      currency: projectCurrency
    },
    intialEstimateOrBudgetedCost:
      rolledUpCostSummary?.initialEstimatedCostInProjectCurrency
  };
};

export const transformTasks = tasks => tasks.map(transformTask);

export default transformTasks;
