import { useState, useCallback } from 'react';

export const useListSelection = () => {
  const [selected, setSelected] = useState({ records: [], isSelectAll: false });

  const onSelectionChange = useCallback(
    newSelected => {
      setSelected({ ...selected, ...newSelected });
    },
    [selected, setSelected]
  );

  return {
    selected,
    onSelectionChange
  };
};
