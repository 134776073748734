import get from 'lodash.get';
import { useClientContext } from '../client/context';

export const hasPermittedAction = (permittedActionUris, permittedActionUri) =>
  permittedActionUris && permittedActionUris.includes(permittedActionUri);

export const useClientPermissions = () => {
  const result = useClientContext();

  const permittedActionUris = get(result, 'client.permittedActionUris') || [];

  return {
    canViewClientEstimates: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-estimates'
    ),
    canViewClientCostData: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-cost-data'
    ),
    canViewClientBillingData: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-billing-data'
    ),
    canViewBillingRates: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-billing-rates'
    ),
    canViewDocuments: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-documents'
    ),
    canEditDocuments: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:edit-documents'
    ),
    canEditClient: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:edit-client'
    ),
    canViewBillingAddress: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-billing-address'
    ),
    canEditBillingAddress: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:edit-billing-address'
    ),
    canViewCustomFields: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-custom-fields'
    ),
    canEditCustomFields: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:edit-custom-fields'
    ),
    canViewComanagers: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:view-client-sharing'
    ),
    canEditComanagers: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:client-action:edit-client-sharing'
    )
  };
};
export default useClientPermissions;
