import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListTablePropTypes from '../../PropTypes';
import { useListTableThemeContext } from '../../ListTableThemeContext';
import { useListTableVariantContext } from '../../ListTableVariantContext';
import {
  Table,
  TableHeader,
  TableHeaderRow,
  TableBody,
  NoRecordsRow,
  TableFooter,
  TableFooterRow
} from './components';
import SkeletonRows from './SkeletonRows';
import RowRender from './RowRender';

const useCalculatedInlineCellStyles = ({ columns }) =>
  useMemo(() => {
    const columnKeys = Object.keys(columns);

    const stylesFromColumn = column => ({
      width: column.width ? column.width : undefined,
      textAlign: column.align ? column.align : undefined
    });

    return columnKeys.reduce(
      (stylesObj, key) => ({
        ...stylesObj,
        [key]: stylesFromColumn(columns[key])
      }),
      {}
    );
  }, [columns]);

const DataTable = ({
  useInlineStyles = true,
  hover = false,
  headers,
  footers,
  columns,
  records,
  totals,
  className,
  onRowClick,
  onCellClick,
  showTableHeader = true,
  showLoadMoreSkeleton = false,
  loading = false,
  selected = {},
  onRowSelectionChange,
  navigationProps,
  ...rest
}) => {
  const classes = useListTableThemeContext() || {};
  const styles = useCalculatedInlineCellStyles({ columns });
  const variant = useListTableVariantContext() || 'standard';
  const { currentPage, navigatePage, showNavigationButtons } =
    navigationProps || {};

  return (
    <Table className={classNames(classes.tableRoot, className)} {...rest}>
      {showTableHeader && (
        <TableHeader className={classes.tableHeader}>
          <TableHeaderRow
            classes={classes}
            variant={variant}
            headers={headers}
            columns={columns}
            styles={styles}
            useInlineStyles={useInlineStyles}
            loading={loading}
            records={records}
            enableRowSelection={!!onRowSelectionChange}
            navigationProps={navigationProps}
            recordCount={records?.length || 0}
          />
        </TableHeader>
      )}
      {!loading && records?.length === 0 && showNavigationButtons ? (
        <NoRecordsRow
          columns={columns}
          currentPage={currentPage}
          navigatePage={navigatePage}
          enableRowSelection={!!onRowSelectionChange}
        />
      ) : null}
      <TableBody className={classes.tableBody}>
        {records.map((record, index) => (
          <RowRender
            key={record.key || record.id}
            record={record}
            classes={classes}
            columnStyles={styles}
            columns={columns}
            styles={styles}
            useInlineStyles={useInlineStyles}
            hover={hover}
            onRowClick={onRowClick}
            onCellClick={onCellClick}
            rowIndex={index + 1}
            loading={loading}
            onRowSelectionChange={onRowSelectionChange}
            selected={
              selected.records ? selected.records.includes(record.id) : false
            }
          />
        ))}
        {showLoadMoreSkeleton && (
          <SkeletonRows classes={classes} columns={columns} />
        )}
      </TableBody>
      {totals && (
        <TableFooter className={classes.tableFooter}>
          <TableFooterRow
            classes={classes}
            variant={variant}
            footers={footers}
            columns={columns}
            totals={totals}
            styles={styles}
            useInlineStyles={useInlineStyles}
            enableRowSelection={!!onRowSelectionChange}
          />
        </TableFooter>
      )}
    </Table>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  headers: ListTablePropTypes.headers,
  footers: ListTablePropTypes.footers,
  columns: ListTablePropTypes.columns,
  useInlineStyles: PropTypes.bool,
  records: PropTypes.any,
  totals: PropTypes.any,
  hover: PropTypes.bool,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  showTableHeader: PropTypes.bool,
  showLoadMoreSkeleton: PropTypes.bool,
  loading: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  selected: ListTablePropTypes.selected,
  navigationProps: PropTypes.object
};

export default DataTable;
