import React from 'react';
import { POLICYTYPE } from '~/modules/common/enums';
import RoleFacet, { primaryRoleFacet } from '../SearchBox/Facets/RoleFacet';
import LocationFacet from '../SearchBox/Facets/LocationFacet';
import DivisionFacet from '../SearchBox/Facets/DivisionFacet';
import ProgramFacet from '../SearchBox/Facets/ProgramFacet';
import ClientFacet from '../SearchBox/Facets/ClientFacet';
import ProjectFacet from '../SearchBox/Facets/ProjectFacet';
import ServiceCenterFacet from '../SearchBox/Facets/ServiceCenterFacet';
import SkillFacet from '../SearchBox/Facets/SkillFacet';
import CostCenterFacet from '../SearchBox/Facets/CostCenterFacet';
import EmployeeTypeFacet from '../SearchBox/Facets/EmployeeTypeFacet';
import DepartmentFacet from '../SearchBox/Facets/DepartmentFacet';
import TagFacet from '../SearchBox/Facets/TagFacet';
import ResourcePoolFacet from '../SearchBox/Facets/ResourcePoolFacet';
import { ResourceFacet } from '../SearchBox/Facets/UserFacet';

const withPolicyBasedGroupFacets = BaseComponent => props => (
  <BaseComponent {...props} policy={POLICYTYPE.RESOURCE_MANAGEMENT} />
);

const hasResourceManagerDataAccessLevel = permission =>
  permission.dataAccessLevelUris.includes(
    'urn:replicon:user-data-access-level:resource-manager'
  );

const hasResourcePoolManagerDataAccessLevel = ({ permission, featureFlags }) =>
  permission.dataAccessLevelUris.includes(
    'urn:replicon:user-data-access-level:resource-pool-manager'
  );

const getFacets = ({
  groupSettings,
  permissions,
  legacyDepartmentDisabled,
  legacyEmployeeTypeDisabled,
  filterFacetOptions,
  featureFlags
}) => [
  ...[ResourceFacet],
  ...(filterFacetOptions.role &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-project-role' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-project-role' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [primaryRoleFacet, RoleFacet]
    : []),
  ...(filterFacetOptions.project ? [ProjectFacet] : []),
  ...(filterFacetOptions.client ? [ClientFacet] : []),
  ...(filterFacetOptions.program ? [ProgramFacet] : []),
  ...(filterFacetOptions.skill &&
  permissions.some(
    p =>
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-skill-assignments' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-skill-assignments' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [SkillFacet]
    : []),
  ...(filterFacetOptions.location &&
  groupSettings.location &&
  groupSettings.location.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-location' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-location' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...LocationFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            LocationFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(filterFacetOptions.division &&
  groupSettings.division &&
  groupSettings.division.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-division' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-division' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...DivisionFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            DivisionFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(filterFacetOptions.serviceCenter &&
  groupSettings.serviceCenter &&
  groupSettings.serviceCenter.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-service-center' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-service-center' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...ServiceCenterFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            ServiceCenterFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(filterFacetOptions.costCenter &&
  groupSettings.costCenter &&
  groupSettings.costCenter.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-cost-center' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-cost-center' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...CostCenterFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            CostCenterFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(filterFacetOptions.department &&
  groupSettings.department &&
  groupSettings.department.isEnabled &&
  legacyDepartmentDisabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-department' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-department' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...DepartmentFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            DepartmentFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(filterFacetOptions.employeeType &&
  groupSettings.employeeType &&
  groupSettings.employeeType.isEnabled &&
  legacyEmployeeTypeDisabled &&
  permissions.some(
    p =>
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-employee-type' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-employee-type' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...EmployeeTypeFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            EmployeeTypeFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(filterFacetOptions.resourcePool &&
  permissions.some(
    p => p.permissionActionUri === 'urn:replicon:user-action:view-resource-pool'
  )
    ? [ResourcePoolFacet]
    : []),
  ...(filterFacetOptions.tags &&
  permissions.some(
    p =>
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-custom-fields' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-custom-fields' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...TagFacet,
          DetailsControl: withPolicyBasedGroupFacets(TagFacet.DetailsControl)
        }
      ]
    : [])
];

export default getFacets;
