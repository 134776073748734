import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RemoveFromSearchIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 11.5,3 C 8.448797829192976,3 5.89696179562396,5.104421695022899 5.197265625,7.939453125 L 7.009765625,9.75 7.0234375,9.736328125 C 7.019238588680753,9.656419514110336 7,9.58097652494575 7,9.5 7,7.01 9.00999999999999,5 11.5,5 13.99,5 16,7.009999999999998 16,9.5 c 0,2.49 -2.01,4.5 -4.5,4.5 -0.10941154832116,0 -0.18467419384153,-0.004362382532 -0.248046875,-0.005859375 L 13.0625,15.8046875 c 0.99801690205368,-0.24777266705588 1.90924862109009,-0.72276690672658 2.66796875,-1.375 L 16,14.7109375 V 15.5 L 21,20.490234375 22.490234375,19 17.5,14 h -0.7890625 l -0.28125,-0.26953125 C 17.4096875,12.59046875000001 18,11.11 18,9.5 18,5.909999999999992 15.09,3 11.5,3 Z M 3.71875,9.0390625 2.3046875,10.453125 5.83984375,13.98828125 2.3046875,17.5234375 3.71875,18.9375 7.25390625,15.40234375 10.7890625,18.9375 12.203125,17.5234375 8.66796875,13.98828125 12.203125,10.453125 10.7890625,9.0390625 7.25390625,12.57421875 Z" />
    </>
  </SvgIcon>
));

export default RemoveFromSearchIcon;
