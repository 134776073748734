import { gql } from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

export const projectsQuery = gql`
  query GetProjects(
    $page: Int!
    $pageSize: Int!
    $searchTerm: String
    $dataAccessLevels: [String]
  ) {
    getPageOfProjectsFilteredByDataAccessLevels(
      page: $page
      pageSize: $pageSize
      searchTerm: $searchTerm
      dataAccessLevels: $dataAccessLevels
    ) {
      id
      displayText
    }
  }
`;

export const searchProjects = ({ client }) => async searchTerm => {
  const result = await client.query({
    query: projectsQuery,
    variables: {
      page: 1,
      pageSize: 100,
      searchTerm,
      dataAccessLevels: [
        'urn:replicon:project-data-access-level:program-manager',
        'urn:replicon:project-data-access-level:project-manager',
        'urn:replicon:project-data-access-level:project-management-data-access-scope'
      ]
    }
  });

  return result.data.getPageOfProjectsFilteredByDataAccessLevels.map(
    project => ({
      ...project,
      value: project.id,
      label: project.displayText,
      name: project.displayText,
      key: project.displayText
    })
  );
};

export const useSearchableProjects = () => {
  const apolloClient = useApolloClient();

  return {
    searchProjects: searchProjects({
      client: apolloClient
    })
  };
};

export default useSearchableProjects;
