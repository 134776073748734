import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useHasPermission } from '~/modules/common/permissions';
import { CreatableAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import useRoles from '~/modules/common/hooks/useRoles';
import MoreResult from '~/modules/common/components/MoreResult';

import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import RoleDropdownItem from './RoleDropdownItem';
import AddRoleDialog from './AddRoleDialog';

const filter = createFilterOptions();
const getOptionLabel = o => o.displayText;
const getOptionSelected = (o, v) => o.id === v.id;

const useStyles = makeStyles(theme => ({
  input: {}
}));

export const getUserRolesInput = ({ value, searchTerm }) => ({
  searchTerm: value && getOptionLabel(value) === searchTerm ? '' : searchTerm
});

export const SearchableRoleDropDown = ({
  label,
  value,
  variant,
  onChange,
  disabled,
  hiddenLabel,
  placeholder,
  classes: classesOverrides,
  dataQeId = '',
  multiple,
  showCreatableOnlyOnInput = true,
  showRate,
  canUserEditSkillAssignment = true,
  disabledValues = [],
  maxRows,
  multiline = false,
  customPopperComponent,
  ariaLabel
}) => {
  const intl = useIntl();
  const canCreateRole = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:edit-project-role'
  });

  const ref = useRef();
  const classes = useStyles({
    classes: classesOverrides,
    current: ref?.current
  });

  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);
  const { hasMore, isLoading, defaultOptions: roles } = useRoles(
    getUserRolesInput({ value, searchTerm })
  );

  const { open, closeDialog, openDialog } = useDialogState();
  const [newRoleName, setNewRoleName] = useState('');

  const openDialogAndSetInitialName = useCallback(
    name => {
      setNewRoleName(name);
      openDialog();
    },
    [openDialog, setNewRoleName]
  );

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);

      if (
        canCreateRole &&
        showCreatableOnlyOnInput &&
        params.inputValue !== '' &&
        !options.some(o => o.displayText === params.inputValue)
      ) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          displayText: params.inputValue
        });
      }

      if (canCreateRole && !showCreatableOnlyOnInput) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          displayText: ''
        });
      }

      return filtered;
    },
    [canCreateRole, showCreatableOnlyOnInput]
  );

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <RoleDropdownItem
          option={option}
          onCreate={openDialogAndSetInitialName}
          showRate={showRate}
        />
      ),
    [openDialogAndSetInitialName, showRate]
  );

  const getOptionDisabled = useCallback(
    option =>
      disabledValues.some(v => v === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [disabledValues]
  );

  const handleRoleAdd = useCallback(role => onChange(role), [onChange]);

  return (
    <>
      <CreatableAutocomplete
        ref={ref}
        hasMore={hasMore}
        loading={isLoading}
        multiple={multiple}
        fullWidth
        canAddNewOption={canCreateRole}
        placeholder={placeholder}
        clearOnBlur
        value={value}
        onChange={onChange}
        onInputChange={handleInputChange}
        options={roles}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterOptions={filterOptions}
        inputLabel={label}
        hiddenLabel={hiddenLabel}
        renderOption={renderOption}
        classes={classes}
        variant={variant}
        disabled={disabled}
        loadingText={intl.formatMessage({ id: 'taskBeatTable.loading' })}
        optionTypeText={intl.formatMessage({
          id: 'moreOptions.roles'
        })}
        dataQeId={dataQeId}
        maxRows={maxRows}
        multiline={multiline}
        PopperComponent={customPopperComponent}
        ariaLabel={ariaLabel}
      />
      {open && (
        <AddRoleDialog
          open={open}
          onClose={closeDialog}
          onChange={handleRoleAdd}
          initialName={newRoleName}
          canUserEditSkillAssignment={canUserEditSkillAssignment}
        />
      )}
    </>
  );
};

SearchableRoleDropDown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  variant: PropTypes.string,
  onChange: PropTypes.func,
  showRate: PropTypes.bool,
  disabled: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  classes: PropTypes.object,
  showCreatableOnlyOnInput: PropTypes.bool,
  disabledValues: PropTypes.array,
  dataQeId: PropTypes.string,
  canUserEditSkillAssignment: PropTypes.bool,
  customPopperComponent: PropTypes.func,
  maxRows: PropTypes.number,
  multiline: PropTypes.bool,
  ariaLabel: PropTypes.string
};

export default SearchableRoleDropDown;
