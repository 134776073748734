import { DialogContent, Table, TableBody, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RESOURCING_ALLOCATION_MODEL } from '~/modules/common/enums';
import ReleaseDialogTableRow from './ReleaseResourceRequestDialogTableRow';
import ReleaseDialogTableHeader from './ReleaseResourceRequestDialogTableHeader';
import ResourceAllocationDetails from './ResourceAllocationDetails';

const useContentStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  model1: {
    backgroundColor: theme.palette.grey[50]
  }
}));

export const ReleaseResourceAllocationsDialogContent = ({
  onSelectAllClick,
  onRowSelectionChange,
  allocations,
  allocatedUser,
  resourcingAllocationModel
}) => {
  const classes = useContentStyles();

  const isResourcingAllocationModel1 =
    resourcingAllocationModel === RESOURCING_ALLOCATION_MODEL.MODEL_1;

  const tableCellContent = allocation => (
    <>
      {allocation.role && <span>{allocation.role.displayText}</span>}
      {!allocation.role && (
        <span>
          <FormattedMessage id="releaseResourceRequestDialog.noRole" />
        </span>
      )}
    </>
  );

  return (
    <DialogContent
      id="release-allocation-dialog-content"
      className={classNames(classes.root, {
        [classes.model1]: isResourcingAllocationModel1
      })}
    >
      {isResourcingAllocationModel1 ? (
        <ResourceAllocationDetails
          allocatedUser={allocatedUser}
          allocations={allocations}
        />
      ) : (
        <Table>
          <ReleaseDialogTableHeader
            usersCount={allocations.length}
            selectedCount={allocations.filter(a => a.isSelected).length}
            onSelectAllClick={onSelectAllClick}
            headerId="releaseResourceRequestDialog.role"
          />
          <TableBody>
            {allocations.map(allocation => (
              <ReleaseDialogTableRow
                key={allocation.id}
                allocation={allocation}
                onRowSelectionChange={onRowSelectionChange}
                tableCellContent={tableCellContent(allocation)}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </DialogContent>
  );
};

ReleaseResourceAllocationsDialogContent.propTypes = {
  allocations: PropTypes.array.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  allocatedUser: PropTypes.object,
  resourcingAllocationModel: PropTypes.string
};

export default ReleaseResourceAllocationsDialogContent;
