import { useCallback } from 'react';
import {
  resourceDetailsQuery,
  useResourceManagement
} from './useResourceManagement';

const updateQuery = (previousResult, { fetchMoreResult }) => {
  const { resources: nextResult } = fetchMoreResult;

  return {
    ...previousResult,
    resources: [...previousResult.resources, ...nextResult]
  };
};

export const useResourceUserListPagination = ({
  page = 1,
  pagesize = 20,
  tagFieldUris,
  sort,
  customFieldUris,
  enabledFields,
  me,
  skip = false
}) => {
  const {
    variables,
    loading,
    fetchMore,
    resources,
    error
  } = useResourceManagement({
    page,
    pagesize,
    tagFieldUris,
    sort,
    customFieldUris,
    enabledFields,
    me,
    skip
  });

  const loadMoreResources = useCallback(async () => {
    // Error handling for fetchMore unable to be cancelled on page change
    // Dependant on https://github.com/apollographql/apollo-client/issues/4114
    try {
      return await fetchMore({
        query: resourceDetailsQuery,
        variables: {
          ...variables,
          page: resources.length / variables.pagesize + 1
        },
        updateQuery
      });
    } catch (err) {
      return { data: err };
    }
  }, [fetchMore, resources.length, variables]);
  const hasMoreResources =
    resources &&
    resources.length !== 0 &&
    resources.length % variables.pagesize === 0;

  return {
    loadMoreResources,
    hasMoreResources,
    loading,
    error,
    resources
  };
};
