import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import SettingsButton from './SettingsButton';

const useStyles = makeStyles(theme => ({
  settings: {
    marginLeft: 'auto'
  }
}));

export const ListTableToolbarContents = ({
  onSettingsClick,
  showSettingsButton = true,
  children
}) => {
  const classes = useStyles();

  return (
    <>
      {children}
      {showSettingsButton && (
        <SettingsButton
          data-qe-id="SettingsButton"
          onClick={onSettingsClick}
          className={classes.settings}
        />
      )}
    </>
  );
};

ListTableToolbarContents.propTypes = {
  onSettingsClick: PropTypes.func,
  showSettingsButton: PropTypes.bool,
  children: PropTypes.node
};

export default ListTableToolbarContents;
