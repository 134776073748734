import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import Rating from '@material-ui/lab/Rating';

export const SkillRating = ({
  editable = true,
  skillAssignment,
  skillAssignment: { skillLevel },
  onSkillRatingUpdate,
  maxSkillLevel = 5,
  dataQeId
}) => {
  const rating = (skillLevel && skillLevel.rating) || 0;
  const onSkillRatingUpdateCallback = useCallback(
    (event, newValue) =>
      onSkillRatingUpdate({
        ratingIndex: newValue,
        skillAssignment
      }),
    [onSkillRatingUpdate, skillAssignment]
  );
  const intl = useIntl();

  const ratingLabel = useCallback(
    value =>
      intl.formatMessage(
        {
          id:
            value > 1
              ? 'skillInfoCard.rating.plural'
              : 'skillInfoCard.rating.singular'
        },
        {
          ratingValue: value
        }
      ),
    [intl]
  );

  const handleFirstKeyPress = useCallback(
    event => {
      if (event.keyCode === 13 && rating === 0) {
        onSkillRatingUpdate({
          ratingIndex: 1,
          skillAssignment
        });
      }
    },
    [onSkillRatingUpdate, rating, skillAssignment]
  );

  return (
    <Rating
      name={skillAssignment.displayText}
      value={rating}
      readOnly={!editable}
      disabled={!editable}
      max={maxSkillLevel}
      onChange={onSkillRatingUpdateCallback}
      getLabelText={ratingLabel}
      onKeyDown={handleFirstKeyPress}
    />
  );
};

SkillRating.propTypes = {
  skillAssignment: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onSkillRatingUpdate: PropTypes.func,
  dataQeId: PropTypes.string,
  maxSkillLevel: PropTypes.number
};

export default SkillRating;
