import React, { useCallback } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Button, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useBackgroundJobStatus } from '~/modules/common/hooks/useBackgroundJobStatus';
import { BACKGROUND_JOB_STATUS } from '~/modules/common/enums';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3)
  },
  backgroundMessage: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0, 0, 0)
  },
  rescheduleFailed: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FDECEA'
  },
  error: { color: '#611A15' },
  bulletList: { padding: theme.spacing(1, 3) },
  resultHeader: { fontWeight: 500 }
}));

export const RescheduleProjectDialogContent = ({ jobId, onClose }) => {
  const classes = useStyles();

  const {
    result: { batchStatus, error } = {},
    status,
    hasProcessed
  } = useBackgroundJobStatus({ jobId });

  const onCloseHandler = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const succeeded = Boolean(
    hasProcessed && status === BACKGROUND_JOB_STATUS.SUCCEEDED
  );

  return (
    <>
      <DialogContent className={classes.root}>
        {hasProcessed ? (
          <ReschedulingResult
            error={error}
            classes={classes}
            succeeded={succeeded}
          />
        ) : (
          <>
            <Typography variant="subtitle2" gutterBottom>
              <FormattedMessage
                id={
                  batchStatus
                    ? `rescheduleProject.${batchStatus}`
                    : 'rescheduleProject.movingProject'
                }
              />
            </Typography>
            <LinearProgress />
            <div className={classes.backgroundMessage}>
              <FormattedMessage id="rescheduleProject.backgroundMessage" />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>
          <FormattedMessage id="rescheduleProject.close" />
        </Button>
      </DialogActions>
    </>
  );
};

export const ReschedulingResult = ({ error, classes, succeeded }) => {
  const hasError = Object.keys(error).some(k => error[k]);

  return succeeded && !hasError ? (
    <Typography
      variant="subtitle1"
      gutterBottom
      className={classes.resultHeader}
    >
      <FormattedMessage id="rescheduleProject.reschedulingComplete" />
    </Typography>
  ) : (
    <div className={classes.rescheduleFailed}>
      <ReschedulingFailed error={error} classes={classes} />
    </div>
  );
};

export const ReschedulingFailed = ({ error, classes }) => {
  const isReschedulingResourceRequestsFailed = error
    ? error.isReschedulingResourceRequestsFailed
    : false;

  const isReschedulingTasksFailed = error
    ? error.isReschedulingTasksFailed
    : false;

  const isReschedulingBillPlanFailed = error
    ? error.isReschedulingBillPlanFailed
    : false;

  return (
    <>
      <Typography
        variant="subtitle1"
        gutterBottom
        className={classNames(classes.error, classes.resultHeader)}
      >
        <FormattedMessage id="rescheduleProject.rescheduleProjectError" />
      </Typography>
      <div className={classes.bulletList}>
        {isReschedulingResourceRequestsFailed ? (
          <li className={classes.error}>
            <FormattedMessage id="rescheduleProject.resourceRequestsError" />
          </li>
        ) : null}
        {isReschedulingTasksFailed ? (
          <li className={classes.error}>
            <FormattedMessage id="rescheduleProject.tasksError" />
          </li>
        ) : null}
        {isReschedulingBillPlanFailed ? (
          <li className={classes.error}>
            <FormattedMessage id="rescheduleProject.billPlanError" />
          </li>
        ) : null}
      </div>
    </>
  );
};

ReschedulingResult.propTypes = {
  succeeded: PropTypes.bool,
  classes: PropTypes.object,
  error: PropTypes.object
};

ReschedulingFailed.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.object
};

RescheduleProjectDialogContent.propTypes = {
  jobId: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default RescheduleProjectDialogContent;
