import {
  investmentScoreMetricTag,
  riskScoreMetricTag,
  valueScoreMetricTag,
  totalScoreTag
} from '~/modules/common/components/SearchBox/Facets/ScoreMetricFacet/tag';
import { portfolioTag } from '~/modules/common/components/SearchBox/Facets/PortfolioFacet/tag';
import { getForCriteria, isNullOrUndefined } from '~/modules/common/util';
import { PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS } from '~/modules/project-request/enums';

const getProjectRequestListFilter = ({ keywords } = {}) => ({
  text: keywords && keywords.length > 0 ? keywords.join(' ') : null
});

const getScoreMetricFilter = (searchCriteria, tag, filterKey) => {
  const { criterions } = searchCriteria;
  const tagFilter = criterions[tag];

  if (tagFilter && tagFilter.length > 0) {
    return {
      [filterKey]: {
        minValue: isNullOrUndefined(tagFilter[0].minValue)
          ? null
          : tagFilter[0].minValue,
        maxValue: isNullOrUndefined(tagFilter[0].maxValue)
          ? null
          : tagFilter[0].maxValue
      }
    };
  }

  return null;
};

const useProjectRequestFilter = ({
  searchCriteria,
  quickFilter,
  excludePortfolio
}) => {
  return {
    projectRequestFilter: {
      ...getScoreMetricFilter(searchCriteria, totalScoreTag, 'totalScore'),
      ...getScoreMetricFilter(searchCriteria, valueScoreMetricTag, 'value'),
      ...getScoreMetricFilter(
        searchCriteria,
        investmentScoreMetricTag,
        'investment'
      ),
      ...getScoreMetricFilter(searchCriteria, riskScoreMetricTag, 'risk'),
      ...getProjectRequestListFilter(searchCriteria),
      statusType: quickFilter?.filterByStatus,
      portfolios: getForCriteria(searchCriteria.criterions, portfolioTag),
      excludePortfolios: excludePortfolio ? [excludePortfolio] : null,
      includeSubPortfolios:
        quickFilter?.filterByAccess ===
        PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS
    }
  };
};

export default useProjectRequestFilter;
