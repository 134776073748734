import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { deepPure } from '~/util';
import { generateSequence } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import useRequestPeriodTimelineBlocks from '~/modules/resourcing/common/chart/hooks/useRequestPeriodTimelineBlocks';
import { REQUEST_PERIOD_OVERLAP_TYPE } from '../../enums/requestPeriodOverlapType';
import ResourceRequestOverlayBlock from './ResourceRequestOverlayBlock';
import ResourceRequestTimelineBlock from './ResourceRequestTimelineBlock';
import ResourceRequestPercentageBlock from './ResourceRequestPercentageBlock';

export const useStyles = makeStyles(theme => ({
  timeItem: {
    display: 'flex',
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    '&:hover': {
      '& .dragIndicator': {
        opacity: 1
      }
    }
  },
  timeItemEditable: {
    cursor: 'pointer'
  },
  periodBlock: {
    backgroundColor: 'transparent'
  },
  toBeHired: {
    backgroundColor: theme.palette.resourceRequest.toBeHired.main,
    height: '100%'
  },
  rejected: {
    backgroundColor: theme.palette.resourceRequest.requestRejected.main,
    height: '100%'
  }
}));

export const useDragIndicatorStyles = makeStyles(theme => ({
  resizeHandle: {
    top: 0,
    left: 0,
    position: 'absolute',
    height: ({ quantity }) =>
      `${quantity * theme.spacing(3) + (quantity - 1) * theme.spacing(0.5)}px `,
    zIndex: 3,
    cursor: 'ew-resize',
    opacity: 0,
    overflow: 'hidden',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  resizeHandleStart: {
    left: theme.spacing(-0.5)
  },
  resizeHandleEnd: {
    left: `calc(100% - ${theme.spacing(2.5)}px)`
  },
  resizeIcon: {
    top: theme.spacing(0.25),
    marginTop: ({ quantity }) =>
      `${(quantity * theme.spacing(3) + (quantity - 1) * theme.spacing(0.5)) *
        0.5 -
        12}px`
  }
}));

export const ResourceRequestTimelineBlocks = ({
  resourceRequest,
  dragState,
  handleRequestPeriodClick,
  isEditable,
  displayUnit,
  chartDisplayDateRange,
  scale,
  project,
  chartDisplayPeriods
}) => {
  const classes = useStyles();
  const dragIndicatorClasses = useDragIndicatorStyles({
    quantity: resourceRequest.quantity
  });

  const isPercentageView = displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE;

  const periodBlockClasses = useMemo(
    () => ({
      container: classNames(classes.periodBlock, {
        [classes.toBeHired]:
          resourceRequest.requestStatus === ResourceRequestStatus.Tobehired,
        [classes.rejected]:
          resourceRequest.requestStatus === ResourceRequestStatus.Rejected
      })
    }),
    [
      classes.periodBlock,
      classes.toBeHired,
      classes.rejected,
      resourceRequest.requestStatus
    ]
  );

  const { overlayPeriods, requestPeriods } = useRequestPeriodTimelineBlocks({
    resourceRequest,
    chartDisplayDateRange,
    chartDisplayPeriods,
    scale,
    project
  });
  const resourceRequestsQuantitySequence = generateSequence(
    resourceRequest.quantity
  );

  return (
    <div
      className={classNames(classes.timeItem, 'resourceRequestUserAllocation', {
        [classes.timeItemEditable]: isEditable
      })}
    >
      {isEditable &&
        overlayPeriods.map(overlayPeriod => (
          <ResourceRequestOverlayBlock
            key={overlayPeriod.key}
            overlayPeriod={overlayPeriod}
            isPercentageView={isPercentageView}
            scale={scale}
            chartDisplayDateRange={chartDisplayDateRange}
            resourceRequest={resourceRequest}
            handleRequestPeriodClick={handleRequestPeriodClick}
            overlayPeriods={overlayPeriods}
          />
        ))}
      {resourceRequestsQuantitySequence.map((_, rowIndex) =>
        requestPeriods.map(requestPeriod => (
          <ResourceRequestTimelineBlock
            key={requestPeriod.key}
            requestPeriod={requestPeriod}
            resourceRequest={resourceRequest}
            rowIndex={rowIndex}
            isEditable={isEditable}
            chartDisplayDateRange={chartDisplayDateRange}
            scale={scale}
            dragState={dragState}
            isPercentageView={isPercentageView}
            periodBlockClasses={periodBlockClasses}
            dragIndicatorClasses={dragIndicatorClasses}
          />
        ))
      )}
      {isPercentageView &&
        resourceRequestsQuantitySequence.map((_, rowIndex) =>
          overlayPeriods
            .filter(
              overlayPeriod =>
                overlayPeriod.requestPeriodOverlapType ===
                  REQUEST_PERIOD_OVERLAP_TYPE.PARTIAL ||
                overlayPeriod.requestPeriodOverlapType ===
                  REQUEST_PERIOD_OVERLAP_TYPE.FULL
            )
            .map(overlayPeriod => (
              <ResourceRequestPercentageBlock
                key={overlayPeriod.key}
                overlayPeriod={overlayPeriod}
                index={rowIndex}
                scale={scale}
              />
            ))
        )}
    </div>
  );
};

ResourceRequestTimelineBlocks.propTypes = {
  resourceRequest: PropTypes.object,
  dragState: PropTypes.object,
  handleRequestPeriodClick: PropTypes.func,
  isEditable: PropTypes.bool,
  displayUnit: PropTypes.string,
  chartDisplayDateRange: PropTypes.object,
  scale: PropTypes.string,
  project: PropTypes.object,
  chartDisplayPeriods: PropTypes.array
};

export default deepPure(ResourceRequestTimelineBlocks);
