import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import React from 'react';
import { slugNotFound } from '~/modules/common/nonOptimalStates';
import { RedirectToNotFound } from '~/modules/common/notfound';

export const PROGRAM_NAME_QUERY_BY_SLUG = gql`
  query getProgramBySlug($programSlug: String!) {
    program(programSlug: $programSlug) {
      id
      name
    }
  }
`;

export const useProgramBySlug = ({ slug }) => {
  const { data, error } = useQuery(PROGRAM_NAME_QUERY_BY_SLUG, {
    variables: {
      programSlug: slug
    }
  });

  if (error) return slugNotFound({ data: { error } }) && <RedirectToNotFound />;

  const program = get(data, 'program', {});

  return { program: program.name, id: program.id };
};

export default useProgramBySlug;
