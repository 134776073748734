import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  AutocompleteInput
} from '~/modules/common/components/SearchAutocomplete';
import { useErrors } from '~/modules/rateCard/advancedRateCard/components/hooks';
import { DimensionObjectType } from '~/types';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { useDimensionValueEditorHook } from '../hooks/useDimensionValueEditorHook';
import { useResourceLabels } from '../hooks/useResourceLabels';
import { useStyles, useDefaultRate } from './useStyles';
import { disableAddEntryButton, useValue } from './useValue';
import DimensionValueReadOnly from './DimensionValueReadOnly';
import AdvancedRateCardSkeleton from './AdvancedRateCardSkeleton';

const getOptionLabel = option => option.displayText;

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const DimensionValueEditor = ({
  record,
  nextRecord,
  previousRecord,
  field,
  index,
  column: {
    id,
    onChange,
    displayText,
    defaultRate,
    errors,
    onAddNewEntry,
    dimensionIndex,
    projectDimensions,
    objectType,
    rolesResponse,
    payCodeResponse,
    onInputChange,
    ariaLabelId,
    deletedRateLabelId
  },
  focusOnLastRow,
  setFocusOnLastRow,
  readOnly,
  loadingMore
}) => {
  const intl = useIntl();
  const classes = useStyles({ readOnly });
  const defaultRateClasses = useDefaultRate({ readOnly });

  const options =
    objectType === DimensionObjectType.ProjectRole
      ? rolesResponse.defaultOptions
      : payCodeResponse.options;
  const loading =
    objectType === DimensionObjectType.ProjectRole
      ? rolesResponse.isLoading
      : payCodeResponse.loading;
  const hasMore =
    objectType === DimensionObjectType.ProjectRole
      ? rolesResponse.hasMore
      : payCodeResponse.hasMore;

  const value = useValue({
    id: deletedRateLabelId,
    record,
    field,
    defaultRate,
    intl
  });

  const {
    getAddEntryLabel,
    defaultLabel,
    getSelectDimensionLabel
  } = useResourceLabels();

  const { hasError, helperText } = useErrors({
    errors,
    index,
    key: field
  });

  const {
    showAddRate,
    showValue,
    handleChange,
    onAddEntryClick
  } = useDimensionValueEditorHook({
    record,
    nextRecord,
    previousRecord,
    dimensionIndex,
    dimensionId: id,
    projectDimensions,
    onAddNewEntry,
    index,
    onChange,
    objectType,
    onInputChange,
    readOnly
  });

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        option.displayText
      ),
    []
  );

  const getOptionDisabled = useCallback(
    option => option.id === MORE_AVAILABLE_OPTION_ID,
    []
  );

  const inputRef = useRef();

  useEffect(() => {
    if (focusOnLastRow && projectDimensions && projectDimensions[0].id === id) {
      inputRef && inputRef.current && inputRef.current.focus();
      setFocusOnLastRow(false);
    }
  }, [focusOnLastRow, id, projectDimensions, setFocusOnLastRow]);

  const renderInput = useCallback(
    params => (
      <AutocompleteInput
        inputRef={inputRef}
        label={!value ? getSelectDimensionLabel(displayText) : undefined}
        variant="filled"
        showError={hasError}
        errorMessage={helperText}
        helperText={helperText}
        dataQeId={`${objectType}_Input`}
        ariaLabel={intl.formatMessage({
          id: ariaLabelId
        })}
        {...params}
      />
    ),
    [
      value,
      getSelectDimensionLabel,
      displayText,
      hasError,
      helperText,
      objectType,
      intl,
      ariaLabelId
    ]
  );

  if (loadingMore) return <AdvancedRateCardSkeleton />;

  return (
    <>
      {showValue ? (
        <div className={classes.container}>
          {value === defaultRate ? (
            <Typography
              component="div"
              className={defaultRateClasses.defaultRate}
              variant="body2"
              tabIndex={0}
            >
              {defaultLabel}
            </Typography>
          ) : readOnly ? (
            <DimensionValueReadOnly value={value.displayText} />
          ) : (
            <SimpleAutocomplete
              dataQeId={`${objectType}`}
              loading={loading}
              options={options}
              size="small"
              fullWidth
              inputLabel={
                !value ? getSelectDimensionLabel(displayText) : undefined
              }
              classes={classes}
              value={value}
              getOptionSelected={getOptionSelected}
              onChange={handleChange}
              getOptionLabel={getOptionLabel}
              optionTypeText={displayText}
              hasMore={hasMore}
              renderOption={renderOption}
              hasError={hasError}
              helperText={helperText}
              onInputChange={onInputChange(objectType, value)}
              getOptionDisabled={getOptionDisabled}
              disabled={record.disabled || record.isDimensionDeleted}
              ariaLabel={intl.formatMessage({
                id: ariaLabelId
              })}
              renderInput={renderInput}
            />
          )}
        </div>
      ) : (
        <div className={classes.container} />
      )}
      {record.isInitialRate && showAddRate ? (
        <Button
          onClick={onAddEntryClick}
          color="primary"
          size="small"
          data-qe-id={`Add ${objectType}`}
          disabled={
            record.disabled ||
            disableAddEntryButton({
              projectDimensions,
              dimensionIndex,
              record
            })
          }
        >
          <Typography variant="body2">
            {getAddEntryLabel(displayText)}
          </Typography>
        </Button>
      ) : null}
    </>
  );
};

DimensionValueEditor.propTypes = {
  column: PropTypes.object,
  field: PropTypes.string,
  record: PropTypes.object,
  nextRecord: PropTypes.object,
  previousRecord: PropTypes.object,
  index: PropTypes.number,
  focusOnLastRow: PropTypes.bool,
  setFocusOnLastRow: PropTypes.func,
  readOnly: PropTypes.bool,
  loadingMore: PropTypes.bool
};

export default DimensionValueEditor;
