import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    width: '300px',
    overflowY: 'visible',
    paddingTop: 0
  }
}));

export const CarryOverSummaryDialogSkeleton = ({ onClose }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.root}>
        <Grid item xs={12}>
          <Skeleton width="30%" height={25} />
        </Grid>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12}>
            <Skeleton width="100%" height={25} />
            <Skeleton width="100%" height={25} />
            <Skeleton width="100%" height={25} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Skeleton width="100%" height={25} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="button.close" />
        </Button>
      </DialogActions>
    </>
  );
};

CarryOverSummaryDialogSkeleton.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default CarryOverSummaryDialogSkeleton;
