import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import { useDialogState } from '~/modules/common/hooks';
import RemoveResourceConfirmationDialog from '../RemoveResourceConfirmationDialog';

export const Delete = ({
  record,
  column: { isUpdating, onRemove, showRemoveResourceConfirmation }
}) => {
  const { open, openDialog, closeDialog } = useDialogState(false);

  const handleClick = useCallback(
    e => {
      onRemove(record.resource.id);
    },
    [onRemove, record.resource.id]
  );

  const handleRemoveResource = useCallback(() => {
    closeDialog();
    handleClick();
  }, [closeDialog, handleClick]);

  return (
    <>
      <IconButton
        onClick={showRemoveResourceConfirmation ? openDialog : handleClick}
        disabled={isUpdating}
      >
        <RemoveCircle color="action" fontSize="small" />
      </IconButton>
      {open && (
        <RemoveResourceConfirmationDialog
          open={open}
          resource={record.resource}
          onCancel={closeDialog}
          onRemoveResource={handleRemoveResource}
        />
      )}
    </>
  );
};

Delete.propTypes = {
  record: PropTypes.object,
  column: PropTypes.object
};

export default Delete;
