import { useMemo, useCallback } from 'react';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import {
  PROJECT_STATUS,
  PROJECT_EXECUTION_PHASE
} from '~/modules/common/enums';
import { LIST } from '~/modules/projects/projectListTabs';

export const executionPhases = {
  [PROJECT_STATUS.INITIATE]: {
    value: PROJECT_EXECUTION_PHASE.INITIATE,
    key: 'Initiate'
  },
  [PROJECT_STATUS.PLANNING]: {
    value: PROJECT_EXECUTION_PHASE.PLANNING,
    key: 'Planning'
  },
  [PROJECT_STATUS.EXECUTION]: {
    value: PROJECT_EXECUTION_PHASE.EXECUTION,
    key: 'Execution'
  },
  [PROJECT_STATUS.CLOSEOUT]: {
    value: PROJECT_EXECUTION_PHASE.CLOSEOUT,
    key: 'Close Out'
  }
};

const useGetClickHandler = ({
  onTabChange,
  status,
  summaryFilterState: { quickFilter, setKeys, isLoadingQuickFilters }
}) =>
  useCallback(() => {
    if (!isLoadingQuickFilters) {
      onTabChange(null, LIST);
      const { value: executionPhaseId } = executionPhases[status];

      setKeys(quickFilter.filterByAccess, executionPhaseId);
    }
  }, [
    onTabChange,
    quickFilter.filterByAccess,
    setKeys,
    status,
    isLoadingQuickFilters
  ]);

export default () => {
  const { onTabChange, summaryFilterState } = useProjectsContext();

  const initiateHandler = useGetClickHandler({
    onTabChange,
    status: PROJECT_STATUS.INITIATE,
    summaryFilterState
  });

  const planningHandler = useGetClickHandler({
    onTabChange,
    status: PROJECT_STATUS.PLANNING,
    summaryFilterState
  });

  const executionHandler = useGetClickHandler({
    onTabChange,
    status: PROJECT_STATUS.EXECUTION,
    summaryFilterState
  });

  const closeOutHandler = useGetClickHandler({
    onTabChange,
    status: PROJECT_STATUS.CLOSEOUT,
    summaryFilterState
  });

  const onDataClick = useCallback((_, e) => {
    if (e && e.datum && e.datum.id) {
      const eventId = e.datum.id;

      if (eventId === PROJECT_STATUS.INITIATE) return initiateHandler();

      if (eventId === PROJECT_STATUS.PLANNING) return planningHandler();

      if (eventId === PROJECT_STATUS.EXECUTION) return executionHandler();

      return closeOutHandler();
    }

    return null;
  }, []);

  const events = [
    {
      childName: ['all'],
      target: 'data',
      eventHandlers: {
        onClick: onDataClick
      }
    }
  ];

  return useMemo(
    () => ({
      events,
      legendClickHandlers: {
        [PROJECT_STATUS.INITIATE]: initiateHandler,
        [PROJECT_STATUS.PLANNING]: planningHandler,
        [PROJECT_STATUS.EXECUTION]: executionHandler,
        [PROJECT_STATUS.CLOSEOUT]: closeOutHandler
      }
    }),
    [
      closeOutHandler,
      executionHandler,
      initiateHandler,
      planningHandler,
      events
    ]
  );
};
