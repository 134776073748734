import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ShowMoreRow } from '~/modules/resourcing/common/components';
import {
  actionsWidth,
  getScaleWidth,
  itemWidth,
  totalWidth
} from '~/modules/common/charts/timeline/calculations';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';

const useStyles = makeStyles(theme => ({
  showMoreContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#fff',
    display: 'flex'
  }
}));

const useShowMoreStyles = makeStyles(theme => ({
  root: {
    borderBottom: 'none',
    position: 'sticky',
    left: 0
  },
  showMoreButton: {
    margin: 0,
    fontSize: theme.spacing(1.5)
  }
}));

export const UserTaskAssignmentShowMore = ({ loadMore }) => {
  const classes = useStyles();
  const showMoreClasses = useShowMoreStyles();
  const { scale, chartDates } = useResourceRequestToolbarContext();

  const leftComponentWidth = actionsWidth + itemWidth * 2;
  const rangeWidth = chartDates.length * getScaleWidth(scale);
  const containerMinWidth = leftComponentWidth + rangeWidth + totalWidth;

  const minWidthStyle = useMemo(
    () => ({
      minWidth: containerMinWidth
    }),
    [containerMinWidth]
  );

  return (
    <div className={classes.showMoreContainer} style={minWidthStyle}>
      <ShowMoreRow
        classes={showMoreClasses}
        loadMore={loadMore}
        recordCount={0}
        resourceKey=""
      />
    </div>
  );
};

UserTaskAssignmentShowMore.propTypes = {
  loadMore: PropTypes.func.isRequired
};

export default UserTaskAssignmentShowMore;
