import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useMeContext from '~/modules/me/useMeContext';

export const useGroupLabels = () => {
  const {
    groupSettings: {
      location: locationSettings,
      division: divisionSettings,
      serviceCenter: serviceCenterSettings,
      costCenter: costCenterSettings,
      department: departmentSettings,
      employeeType: employeeTypeSettings
    }
  } = useMeContext();

  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      serviceCenter:
        serviceCenterSettings.singularName ||
        formatMessage({ id: 'groups.serviceCenter' }),
      location:
        locationSettings.singularName ||
        formatMessage({ id: 'groups.location' }),
      costCenter:
        costCenterSettings.singularName ||
        formatMessage({ id: 'groups.costCenter' }),
      department:
        departmentSettings.singularName ||
        formatMessage({ id: 'groups.department' }),
      employeeType:
        employeeTypeSettings.singularName ||
        formatMessage({ id: 'groups.employeeType' }),
      division:
        divisionSettings.singularName ||
        formatMessage({ id: 'groups.division' })
    }),
    [
      locationSettings,
      divisionSettings,
      serviceCenterSettings,
      costCenterSettings,
      departmentSettings,
      employeeTypeSettings
    ]
  );
};

export default useGroupLabels;
