import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SearchToolbar from '../SearchToolbar';
import { BillingTransactionContext } from './BillingTransactionContext';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    '& div div': {
      [theme.breakpoints.down('sm')]: { paddingBottom: 0 }
    }
  }
}));

const Toolbar = ({ searchFacets, isAccountReceivable }) => {
  const classes = useStyles();

  return (
    <BillingTransactionContext.Consumer>
      {ctx => (
        <div
          className={classNames({
            [classes.searchContainer]: isAccountReceivable
          })}
        >
          <SearchToolbar
            hideSearch={false}
            searchFacets={searchFacets}
            {...ctx}
          />
        </div>
      )}
    </BillingTransactionContext.Consumer>
  );
};

Toolbar.propTypes = {
  searchFacets: PropTypes.array,
  isAccountReceivable: PropTypes.bool
};

export default Toolbar;
