import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, alpha } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NoValue from '../NoValue';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  rootAlignRight: {
    '& $label': {
      transformOrigin: 'top right',
      left: 'unset',
      right: 0
    },
    '& $labelFilled': {
      transform: 'translate(-8px, 17px) scale(0.85)',
      '&$labelMarginDense': {
        transform: 'translate(-8px, 17px) scale(0.85)'
      },
      '&$shrink': {
        transform: 'translate(-8px, 7px) scale(0.65)',
        '&$labelMarginDense': {
          transform: 'translate(-8px, 7px) scale(0.65)'
        }
      }
    },
    '& $valueContainer': {
      textAlign: 'right'
    }
  },
  label: {
    whiteSpace: 'nowrap',
    transform: 'translate(0, 21px) scale(0.85)'
  },
  shrink: {
    transform: 'translate(0, 7px) scale(0.65)'
  },
  labelMarginDense: {},
  labelFilled: {
    transform: 'translate(8px, 17px) scale(0.85)',
    '&$labelMarginDense': {
      transform: 'translate(8px, 17px) scale(0.85)'
    },
    '&$shrink': {
      transform: 'translate(8px, 7px) scale(0.65)',
      '&$labelMarginDense': {
        transform: 'translate(8px, 7px) scale(0.65)'
      }
    }
  },
  base: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '1.1875em',
    margin: theme.spacing(2, 0, 0, 0)
  },
  baseFilled: {
    marginTop: 0
  },
  baseNoLabel: {
    marginTop: 0
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  fieldFilled: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[50],
    borderRadius: [[theme.shape.borderRadius, theme.shape.borderRadius, 0, 0]]
  },
  fieldOutlined: {
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: alpha(theme.palette.common.black, 0.23)
  },
  valueContainer: {
    flexGrow: 1,
    flexShrink: 1,
    fontSize: theme.typography.body1.fontSize,
    minHeight: '1.22em',
    lineHeight: '1.22em',
    padding: theme.spacing(0.5, 0, 0.75, 0),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  },
  valueContainerSmall: {
    fontSize: theme.typography.body2.fontSize
  },
  valueContainerFilled: {
    padding: [
      [
        theme.spacing(2.5),
        theme.spacing(1),
        theme.spacing(0.75) - 1,
        theme.spacing(1)
      ]
    ]
  },
  valueContainerOutlined: {
    padding: [
      [
        theme.spacing(1.75),
        theme.spacing(1) - 1,
        theme.spacing(1.75),
        theme.spacing(1) - 1
      ]
    ]
  },
  valueContainerNoLabel: {
    padding: theme.spacing(1, 0, 0.875, 0)
  },
  valueContainerFilledNoLabel: {
    padding: theme.spacing(1, 1, 0.75, 1)
  },
  valueContainerOutlinedNoLabel: {
    padding: [
      [
        theme.spacing(1) - 1,
        theme.spacing(1) - 1,
        theme.spacing(0.75),
        theme.spacing(1) - 1
      ]
    ]
  }
}));

export const FormReadOnlyField = ({
  className,
  label,
  keepLabelSpacing = false,
  align = 'left',
  variant = 'standard',
  textSize = 'normal',
  startAdornment,
  endAdornment,
  children
}) => {
  const classes = useStyles();
  const hasLabel = keepLabelSpacing || label;
  const valueContainerClassName = classNames(classes.valueContainer, {
    [classes.valueContainerSmall]: textSize === 'small',
    [classes.valueContainerFilled]: variant === 'filled',
    [classes.valueContainerOutlined]: variant === 'outlined',
    [classes.valueContainerNoLabel]: !hasLabel,
    [classes.valueContainerFilledNoLabel]: !hasLabel && variant === 'filled',
    [classes.valueContainerOutlinedNoLabel]: !hasLabel && variant === 'outlined'
  });

  const inputLabelClasses = useMemo(
    () => ({
      root: classes.label,
      shrink: classes.shrink,
      marginDense: classes.labelMarginDense,
      filled: classes.labelFilled
    }),
    [
      classes.label,
      classes.labelFilled,
      classes.labelMarginDense,
      classes.shrink
    ]
  );

  return (
    <FormControl
      className={classNames(
        classes.root,
        { [classes.rootAlignRight]: align === 'right' },
        className
      )}
      variant={variant}
    >
      {hasLabel ? (
        <InputLabel shrink classes={inputLabelClasses}>
          {label}
        </InputLabel>
      ) : null}
      <div
        className={classNames(classes.base, {
          [classes.baseFilled]: variant === 'filled',
          [classes.baseNoLabel]: !hasLabel
        })}
      >
        <div
          className={classNames(classes.field, {
            [classes.fieldFilled]: variant === 'filled',
            [classes.fieldOutlined]: variant === 'outlined'
          })}
        >
          {startAdornment}
          <div className={valueContainerClassName}>
            {React.Children.count(children) > 0 ? children : <NoValue />}
          </div>
          {endAdornment}
        </div>
      </div>
    </FormControl>
  );
};

FormReadOnlyField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  keepLabelSpacing: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right']),
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  textSize: PropTypes.oneOf(['normal', 'small']),
  children: PropTypes.node,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node
};

export default FormReadOnlyField;
