import { useCallback } from 'react';

const useEditAllocationUserDialogFormOnChange = ({
  setFieldValue,
  impactedTasks
}) => {
  const onAllocationTypeChange = useCallback(
    option => setFieldValue('changeAllocationType', option),
    [setFieldValue]
  );

  const onDateChange = useCallback(date => setFieldValue('asOfDate', date), [
    setFieldValue
  ]);

  const onAllocationChange = useCallback(
    value => setFieldValue('selectedAllocationId', value),
    [setFieldValue]
  );

  const onTasksCheckedChange = useCallback(
    tasksCheckedValue => {
      setFieldValue('isTasksChecked', tasksCheckedValue);
      setFieldValue('impactedTasks', impactedTasks);
    },
    [setFieldValue, impactedTasks]
  );

  return {
    onTasksCheckedChange,
    onAllocationChange,
    onDateChange,
    onAllocationTypeChange
  };
};

export default useEditAllocationUserDialogFormOnChange;
