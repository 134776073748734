import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { PageSelector } from '~/modules/resourcing/common/components';
import {
  useTableHeaderStyles,
  usePageSelectorStyles,
  useToolTipStyles
} from './useStyles';

const ResourcePoolTableHeader = ({
  assignedResourcePoolCount,
  onNextPage,
  onPreviousPage,
  currentPage,
  pageSize,
  loadingRows,
  isCurrentPageEmpty
}) => {
  const tableHeaderClasses = useTableHeaderStyles();
  const pageSelectorClasses = usePageSelectorStyles();
  const toolTipClasses = useToolTipStyles();

  const { formatMessage } = useIntl();

  return (
    <TableRow>
      <TableCell className={tableHeaderClasses.cell}>
        <div className={tableHeaderClasses.container}>
          <div className={tableHeaderClasses.name}>
            <FormattedMessage id="resourceDrawer.resourcePoolSection.userAssigned" />
          </div>
          <Tooltip
            title={formatMessage({
              id: 'resourceDrawer.resourcePoolSection.headerToolTip'
            })}
          >
            <span className={toolTipClasses.container}>
              <HelpOutlineOutlinedIcon className={toolTipClasses.root} />
            </span>
          </Tooltip>
          {assignedResourcePoolCount !== 0 && (
            <PageSelector
              classes={pageSelectorClasses}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
              resourceTotalCount={assignedResourcePoolCount}
              currentPage={currentPage}
              pageSize={pageSize}
              disablePageEvents={loadingRows}
              isCurrentPageEmpty={isCurrentPageEmpty}
            />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

ResourcePoolTableHeader.propTypes = {
  assignedResourcePoolCount: PropTypes.number,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  loadingRows: PropTypes.bool,
  isCurrentPageEmpty: PropTypes.bool
};

export default ResourcePoolTableHeader;
