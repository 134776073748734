import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/SearchSharp';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  searchField: {
    display: 'inline-flex',
    verticalAlign: 'bottom',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    width: '100%',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[100]
    },
    padding: theme.spacing(0, 0, 0, 1),
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  searchIcon: {
    color: theme.palette.text.secondary
  },
  inputRoot: {
    width: '100%'
  },
  inputInput: {
    padding: [
      [
        theme.spacing(1) - 1,
        theme.spacing(1),
        theme.spacing(1) - 2,
        theme.spacing(1)
      ]
    ],
    color: theme.palette.text.secondary
  }
}));

export const SearchField = ({
  classes: classesOverride,
  searchQeId,
  searchRef,
  ...otherProps
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.searchField}>
      <SearchIcon className={classes.searchIcon} />
      <Input
        data-qe-id={searchQeId}
        placeholder={intl.formatMessage({ id: 'search.placeholder' })}
        disableUnderline
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{
          'aria-label': intl.formatMessage({ id: 'search.title' })
        }}
        ref={searchRef}
        {...otherProps}
      />
    </div>
  );
};

SearchField.propTypes = {
  searchQeId: PropTypes.string,
  classes: PropTypes.object,
  intl: PropTypes.object,
  searchRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

export default SearchField;
