import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem, StickyHeader } from '~/modules/common/components';
import {
  updateCache,
  useKeyValueSettingsMutation
} from '~/modules/common/hooks';
import { useHasPermission } from '~/modules/common/permissions';
import { ResourcingCurrentDateLine } from '~/modules/resourcing/common/components';
import { useResourceAllocationChartEventHandlers } from '~/modules/resourcing/common/hooks';
import DisplayUnitContextProvider from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import { useChartDisplayPeriods } from '~/modules/resourcing/common/chart/hooks';
import {
  QuickAllocationUserChartHeaderRow,
  QuickAllocationUserChartLoading,
  QuickAllocationUserChartToolbar,
  QuickAllocationUserRow2
} from './components';
import useQuickAllocationContext, {
  withQuickAllocationContextProvider
} from './hooks/useQuickAllocationContext';
import useResourceUsers from './hooks/useResourceUsers';
import useResourceUsersCount from './hooks/useResourceUsersCount';
import useStyles from './useStyles';

const PAGE_SIZE = 50;
const LEFT_WIDTH = 350;

export const QuickAllocationUserChartContent = ({
  classes: classesOverride,
  onPrevious,
  onNext,
  page,
  setPage,
  handleExpand,
  userExpandState
}) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    displayUnit,
    setDisplayUnit,
    sort,
    scale,
    setScale,
    dateRange,
    filter,
    chartDates,
    isShowActualEnabled,
    setIsShowActualEnabled,
    projectStatusFilterOption
  } = useQuickAllocationContext();

  const chartDisplayPeriods = useChartDisplayPeriods({
    chartDisplayDateRange: dateRange,
    scale
  });

  const canViewAvailability = useHasPermission({
    actionUri: 'urn:replicon:user-action:view-availability'
  });
  const { loadingRows, resourceUsers } = useResourceUsers({
    sort: [sort],
    dateRange,
    filter,
    scale,
    page,
    pagesize: PAGE_SIZE,
    isViewAvailabilityEnabled: canViewAvailability,
    isViewResourceActualsEnabled: isShowActualEnabled
  });
  const { resourceUsersCount } = useResourceUsersCount(filter);

  const [putKeyValueSettings] = useKeyValueSettingsMutation(
    updateCache('quick_allocation_chart_settings')
  );

  const handleNextPage = useCallback(() => setPage(page + 1), [page, setPage]);

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) setPage(page - 1);
  }, [page, setPage]);
  const {
    handleDisplayUnitChange,
    handleScaleChange,
    handleShowActualChange
  } = useResourceAllocationChartEventHandlers({
    key: 'quick_allocation_chart_settings',
    setScale,
    scale,
    putKeyValueSettings,
    displayUnit,
    setDisplayUnit,
    showActuals: isShowActualEnabled,
    setShowActuals: setIsShowActualEnabled,
    projectStatusFilterOption
  });

  return (
    <DisplayUnitContextProvider
      defaultDisplayUnit={displayUnit}
      onDisplayUnitChange={handleDisplayUnitChange}
    >
      <StickyHeader
        showMenuButton={false}
        disableTitleFullWidth
        rightPadding
        level={1}
      >
        <QuickAllocationUserChartToolbar
          onPreviousClick={onPrevious}
          onNextClick={onNext}
          setScale={handleScaleChange}
          setShowActualChange={handleShowActualChange}
          resourceUsersLoading={loadingRows}
        />
      </StickyHeader>
      <div className={classes.container}>
        <ResourcingCurrentDateLine
          scale={scale}
          chartDates={chartDates}
          leftWidth={LEFT_WIDTH}
        />
        <QuickAllocationUserChartHeaderRow
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          resourceTotalCount={resourceUsersCount}
          currentPage={page}
          pageSize={PAGE_SIZE}
          loadingRows={loadingRows}
        />
        {loadingRows && <QuickAllocationUserChartLoading />}
        {resourceUsers.length > 0
          ? resourceUsers.map(u => (
              <QuickAllocationUserRow2
                key={u.id}
                resource={u}
                chartDisplayPeriods={chartDisplayPeriods}
                isExpanded={Boolean(userExpandState[u.id])}
                handleExpand={handleExpand}
                resourceUserActualSeries={u.actuals}
              />
            ))
          : !loadingRows && (
              <div className={classes.noMessage}>
                <NoDataItem>
                  <FormattedMessage id="quickAllocation.noResources" />
                </NoDataItem>
              </div>
            )}
      </div>
    </DisplayUnitContextProvider>
  );
};

QuickAllocationUserChartContent.propTypes = {
  classes: PropTypes.object,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  handleExpand: PropTypes.func,
  userExpandState: PropTypes.object
};

export default withQuickAllocationContextProvider(
  QuickAllocationUserChartContent
);
