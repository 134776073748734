import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { useTheme } from '@material-ui/styles';
import { DateTime } from 'luxon';
import { useMeContext } from '~/modules/me/useMeContext';
import useThemeBreakpoint from '~/modules/common/hooks/useThemeBreakpoint';
import {
  PERIOD_SCALE_ENUM,
  DEFAULT_PERIOD_SCALE
} from '~/modules/resourcing/common/enums';
import { scaleOffsets } from '~/modules/resource-management/ResourcingOverview/util';

export const weekUris = [
  'urn:replicon:day-of-week:monday',
  'urn:replicon:day-of-week:tuesday',
  'urn:replicon:day-of-week:wednesday',
  'urn:replicon:day-of-week:thursday',
  'urn:replicon:day-of-week:friday',
  'urn:replicon:day-of-week:saturday',
  'urn:replicon:day-of-week:sunday'
];

export const ResourceOverviewToolbarContext = createContext();

export const useResourceOverviewToolbarContext = () =>
  useContext(ResourceOverviewToolbarContext);

export const useResourceOverviewToolbarContextValue = () => {
  const theme = useTheme();
  const me = useMeContext();
  const {
    workWeekStartDay: { uri: workWeekStartDayUri }
  } = me;

  const breakPoint = useThemeBreakpoint(theme);
  const [periodScale, setPeriodScale] = useState(DEFAULT_PERIOD_SCALE);
  const [dateRange, setDateRange] = useState(
    getDateRange({
      date: DateTime.utc(),
      scale: periodScale,
      breakPoint
    })
  );

  const initialRange = useMemo(
    () =>
      getDateRange({
        date: DateTime.utc(),
        scale: periodScale,
        workWeekStartDayUri,
        breakPoint
      }),
    [periodScale, workWeekStartDayUri, breakPoint]
  );

  useEffect(() => setDateRange(initialRange), [initialRange]);

  const setScale = useCallback(
    scale => {
      if (periodScale !== scale) {
        setDateRange(
          getDateRange({
            date: DateTime.utc(),
            scale,
            workWeekStartDayUri,
            breakPoint
          })
        );
      }
      setPeriodScale(scale);
    },
    [periodScale, workWeekStartDayUri, breakPoint]
  );

  const setNextPeriod = useCallback(() => {
    const offsetToApply = scaleOffsets[periodScale];

    const range = getDateRange({
      date: dateRange.startDate.plus(offsetToApply),
      scale: periodScale,
      workWeekStartDayUri,
      breakPoint
    });

    setDateRange(range);
  }, [dateRange, periodScale, workWeekStartDayUri, breakPoint]);

  const setPreviousPeriod = useCallback(() => {
    const offsetToApply = scaleOffsets[periodScale];
    const range = getDateRange({
      date: dateRange.startDate.minus(offsetToApply),
      scale: periodScale,
      workWeekStartDayUri,
      breakPoint
    });

    setDateRange(range);
  }, [dateRange, breakPoint, periodScale, workWeekStartDayUri]);

  return useMemo(
    () => ({
      periodScale,
      setScale,
      dateRange,
      setDateRange,
      setNextPeriod,
      setPreviousPeriod
    }),
    [dateRange, periodScale, setNextPeriod, setPreviousPeriod, setScale]
  );
};

const getChartPeriodCount = breakPoint => {
  switch (breakPoint) {
    case 'xl':
      return 10;
    case 'lg':
      return 8;
    case 'md':
      return 6;
    case 'sm':
      return 5;
    default:
      return 3;
  }
};

const getDateRangeWithoutTimeStamp = ({ startDate, endDate }) => ({
  startDate: DateTime.utc(
    startDate.year,
    startDate.month,
    startDate.day,
    0,
    0,
    0,
    0
  ),
  endDate: DateTime.utc(endDate.year, endDate.month, endDate.day, 0, 0, 0, 0)
});

const getDateRange = ({ date, scale, workWeekStartDayUri, breakPoint }) => {
  switch (scale) {
    case PERIOD_SCALE_ENUM.WEEKS: {
      const tempStartDate = date.set({
        weekday: weekUris.indexOf(workWeekStartDayUri) + 1
      });

      const startDate =
        tempStartDate > date
          ? tempStartDate.minus({ weeks: 1 })
          : tempStartDate;
      const endDate = startDate
        .plus({
          week: getChartPeriodCount(breakPoint)
        })
        .minus({ days: 1 });

      return getDateRangeWithoutTimeStamp({ startDate, endDate });
    }
    case PERIOD_SCALE_ENUM.MONTHS: {
      const startDate = date.startOf('month');
      const endDate = startDate
        .plus({
          month: getChartPeriodCount(breakPoint)
        })
        .endOf('month');

      return getDateRangeWithoutTimeStamp({ startDate, endDate });
    }
    case PERIOD_SCALE_ENUM.YEARS: {
      const startDate = date.startOf('year');
      const endDate = startDate.endOf('year').plus({
        year: getChartPeriodCount(breakPoint)
      });

      return getDateRangeWithoutTimeStamp({ startDate, endDate });
    }
    case PERIOD_SCALE_ENUM.QUARTERS: {
      const startDate = date.startOf('quarter');
      const endDate = startDate.endOf('quarter').plus({
        quarters: getChartPeriodCount(breakPoint)
      });

      return getDateRangeWithoutTimeStamp({ startDate, endDate });
    }
    default: {
      const startDate = date.startOf('day');
      const endDate = startDate.plus({
        days: getChartPeriodCount(breakPoint)
      });

      return getDateRangeWithoutTimeStamp({ startDate, endDate });
    }
  }
};

export default useResourceOverviewToolbarContext;
