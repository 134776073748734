import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useDialogState, useMenuState } from '~/modules/common/hooks';
import ChildInfoDialog from '../Table/components/ChildInfoDialog/ChildInfoDialog';

const useStyles = makeStyles({
  header: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingLeft: '0 !important'
  },
  container: {
    width: '100%',
    margin: 0
  },
  icon: {
    cursor: 'pointer',
    marginBottom: theme.spacing(-0.5) - 1,
    marginLeft: theme.spacing(1),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.25)
  },
  data: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'end'
  }
});

const MobileTaskSummaryRow = ({
  label,
  children,
  childrenDetails,
  selectedColumns,
  hideTooltip = false,
  loadMore,
  loadingMore,
  hasMore,
  classesOverrides,
  tooltipComponent
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const { open, openDialog, closeDialog } = useDialogState();

  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();

  const handleClick = useCallback(
    event => {
      onMenuClick(event);
      openDialog();
    },
    [onMenuClick, openDialog]
  );

  const handleClosePopover = useCallback(() => {
    onMenuClose();
    closeDialog();
  }, [onMenuClose, closeDialog]);

  return (
    <Grid
      container
      className={classes.container}
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid item className={classes.header}>
        <FormattedMessage id={label} />:
      </Grid>
      <Grid item className={classes.data}>
        {children}
        {!hideTooltip && !tooltipComponent && childrenDetails?.length > 0 && (
          <>
            <InfoSharpIcon onClick={handleClick} className={classes.icon} />
            <ChildInfoDialog
              childTasks={childrenDetails}
              selectedColumns={selectedColumns}
              open={open}
              onClose={handleClosePopover}
              anchorEl={anchorEl}
              hasMore={hasMore}
              loadMore={loadMore}
              loadingMore={loadingMore}
            />
          </>
        )}
        {!hideTooltip && tooltipComponent}
      </Grid>
    </Grid>
  );
};

MobileTaskSummaryRow.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  childrenDetails: PropTypes.array,
  selectedColumns: PropTypes.array,
  hideTooltip: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  hasMore: PropTypes.bool,
  classesOverrides: PropTypes.object,
  tooltipComponent: PropTypes.node
};

export default MobileTaskSummaryRow;
