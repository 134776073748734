import { DialogTitle, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  parentName: {
    fontWeight: 'normal'
  }
});

export const AddPortfolioDialogHeader = ({ parentName }) => {
  const classes = useStyles();

  const values = useMemo(
    () => ({
      parentName: <span className={classes.parentName}>{parentName}</span>
    }),
    [classes.parentName, parentName]
  );

  return (
    <DialogTitle>
      {parentName ? (
        <FormattedMessage id="portfolio.addSubPortfolioTitle" values={values} />
      ) : (
        <FormattedMessage id="portfolio.addPortfolioLabel" />
      )}
    </DialogTitle>
  );
};

AddPortfolioDialogHeader.propTypes = {
  parentName: PropTypes.string
};

export default AddPortfolioDialogHeader;
