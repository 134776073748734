import React from 'react';
import { useIntl } from 'react-intl';
import NoDataItem from '~/modules/common/components/NoDataItem';

const NoTaskBeatsMessage = () => {
  const intl = useIntl();

  return (
    <NoDataItem>
      {intl.formatMessage({ id: 'taskBeatTable.noTaskEntries' })}
    </NoDataItem>
  );
};

export default NoTaskBeatsMessage;
