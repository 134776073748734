import { useMemo } from 'react';

const expansionPanelResourceKeys = {
  title: 'billPaymentDetails.outstandingBills',
  allocatedAmount: 'billPaymentDetails.allocatedAmount'
};

export default ({ client, intl }) => {
  const billsTableResourceKeys = useMemo(
    () => ({
      noDataMessage: `billPaymentDetails.${
        client && client.id ? 'noOutstandingBills' : 'selectClient'
      }`,
      amountAriaLabel: intl.formatMessage({
        id: 'billPaymentDetails.associatedBills.paymentAmountAriaLabel'
      }),
      allocatedBillAmountAriaLabel: intl.formatMessage({
        id: 'associatedBills.allocatedBillAmountAriaLabel'
      }),
      buttonAriaLabel: intl.formatMessage({
        id: 'associatedBills.buttonAriaLabel'
      })
    }),
    [client, intl]
  );

  const resourceLabels = useMemo(
    () => ({
      referenceNumber: intl.formatMessage({
        id: 'billPaymentDetails.referenceNumber'
      }),
      description: intl.formatMessage({ id: 'billPaymentDetails.description' }),
      paymentAmount: intl.formatMessage({
        id: 'billPaymentDetails.amount'
      })
    }),
    [intl]
  );

  const accesibilityResourceLabels = useMemo(
    () => ({
      paymentDate: intl.formatMessage({
        id: 'billPaymentDetails.paymentDate'
      }),
      paymentMethod: intl.formatMessage({
        id: 'billPaymentDetails.paymentMethod'
      }),
      currency: intl.formatMessage({
        id: 'billPaymentDetails.paymentInfo.currencyAriaLabel'
      }),
      client: intl.formatMessage({
        id: 'billPaymentDetails.paymentInfo.clientAriaLabel'
      })
    }),
    [intl]
  );

  return {
    billsTableResourceKeys,
    resourceLabels,
    accesibilityResourceLabels,
    expansionPanelResourceKeys
  };
};
