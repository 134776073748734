import { gql } from 'graphql-tag';

const resourceSkillsFragment = gql`
  fragment ResourceSkillsFragment on ResourceDetails {
    id
    resourceSkill {
      category {
        uri
        displayText
      }
      skillAssignments {
        uri
        displayText
        certificate
        skillLevel {
          id
          uri
          displayText
          rating
        }
        skillCustomMetadata {
          uri
          displayText
          type
          text
          date
          number
        }
      }
    }
  }
`;

export default resourceSkillsFragment;
