import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Skeleton } from '@material-ui/lab';
import ProgressItemTooltip from './ProgressItemTooltip';

const useStyles = makeStyles(theme => ({
  rowItems: {
    display: 'flex',
    justifyContent: 'center'
  },
  columnItems: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.subtitle1.fontFamily,
    color: theme.palette.text.secondary
  },
  keyValue: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary
  },
  variation: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  variationNegative: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  variationPositive: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark
  },
  variationIcon: {
    alignSelf: 'center'
  },
  notAvailable: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled
  }
}));

export const ProgressItemVariation = ({
  title,
  value,
  fixedDecimalScale,
  suffix,
  prefix,
  isLoading = false,
  dataQeId
}) => {
  const classes = useStyles();

  return (
    <div className={classes.columnItems}>
      <Typography className={classes.title}>
        <FormattedMessage id={title} />
      </Typography>
      {!isLoading && value !== null ? (
        <span
          className={classNames(
            classes.rowItems,
            classes.keyValue,
            classes.variation,
            {
              [classes.variationPositive]: value > 0,
              [classes.variationNegative]: value < 0
            }
          )}
        >
          {value > 0 && (
            <RemoveIcon
              className={classNames(
                classes.variationIcon,
                classes.variationPositive
              )}
            />
          )}
          {value < 0 && (
            <AddIcon
              className={classNames(
                classes.variationIcon,
                classes.variationNegative
              )}
            />
          )}
          <ProgressItemTooltip
            className={classNames(classes.variation, {
              [classes.variationPositive]: value > 0,
              [classes.variationNegative]: value < 0
            })}
            value={Math.abs(value)}
            fixedDecimalScale={fixedDecimalScale}
            suffix={suffix}
            prefix={prefix}
            dataQeId={`${dataQeId}_Value`}
          />
        </span>
      ) : (
        <div
          data-qe-id={`${dataQeId}_Value`}
          className={classNames(classes.keyValue, classes.notAvailable)}
        >
          {isLoading ? (
            <Skeleton width={36} />
          ) : (
            <FormattedMessage id="dashboardProgressChart.na" />
          )}
        </div>
      )}
    </div>
  );
};

ProgressItemVariation.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  isLoading: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default ProgressItemVariation;
