import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ResourceManagementIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12 6 C 10.57714285714286 6 9.427734375000002 7.14940848214285 9.427734375 8.572265625 C 9.427734375 9.995122767857129 10.57714285714286 11.142578125 12 11.142578125 C 13.42285714285715 11.142578125 14.5625 9.995122767857127 14.5625 8.572265625 C 14.56249999999999 7.149408482142841 13.42285714285716 6 12 6 z M 5.5 7.283203125 C 4.370909090909091 7.283203125 3.455078125 8.19903409090908 3.455078125 9.328125 C 3.455078125 10.4572159090909 4.370909090909102 11.375 5.5 11.375 C 6.6290909090909 11.375 7.537109375000001 10.4572159090909 7.537109375 9.328125 C 7.537109375 8.19903409090908 6.629090909090899 7.283203125 5.5 7.283203125 z M 18.5 7.283203125 C 17.3709090909091 7.283203125 16.455078125 8.19903409090908 16.455078125 9.328125 C 16.455078125 10.4572159090909 17.3709090909091 11.375 18.5 11.375 C 19.62909090909092 11.375 20.537109375 10.4572159090909 20.537109375 9.328125 C 20.537109375 8.19903409090908 19.62909090909091 7.283203125 18.5 7.283203125 z M 12 12.857421875 C 10.00285714285716 12.857421875 6.000000000000003 13.86027901785715 6 15.857421875 L 6 18 L 18 18 L 18 15.857421875 C 18 13.86027901785716 13.99714285714286 12.85742187499998 12 12.857421875 z M 5.5 12.908203125 C 4.002727272727281 12.908203125 1 13.66093039772727 1 15.158203125 L 1 17 L 4.5 17 L 4.5 15.857421875 C 4.500000000000001 14.85706319934089 5.032420911539379 14.00426983417669 5.640625 13.443359375 C 5.835823555482814 13.2633393562862 6.044465326646119 13.10778402833535 6.2578125 12.962890625 C 5.98344970690673 12.92963686011644 5.722619416702692 12.908203125 5.5 12.908203125 z M 18.5 12.908203125 C 18.2773805832973 12.908203125 18.01655029309327 12.9296368601164 17.7421875 12.962890625 C 17.95553467335388 13.10778402833535 18.16417644451719 13.2633393562862 18.359375 13.443359375 C 18.96757908846062 14.00426983417669 19.5 14.85706319934089 19.5 15.857421875 L 19.5 17 L 23 17 L 23 15.158203125 C 23 13.66093039772727 19.99727272727274 12.908203125 18.5 12.908203125 z " />
    </>
  </SvgIcon>
));

export default ResourceManagementIcon;
