import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Decimal,
  DiffValue,
  DIFF_VALUE_TYPE
} from '~/modules/common/components';
import RequestDrawerAllocatedHoursToolpTitle from './RequestDrawerAllocatedHoursTooltipTitle';

const RequestDrawerAllocatedHours = ({
  precision,
  requestedHours,
  allocatedHours,
  fixedDecimalScale
}) => {
  const intl = useIntl();

  const showDiff = !(
    !requestedHours ||
    !allocatedHours ||
    requestedHours === allocatedHours
  );

  const diff = allocatedHours - requestedHours;

  return (
    <Tooltip
      title={
        <RequestDrawerAllocatedHoursToolpTitle
          requestedHours={requestedHours}
          allocatedHours={allocatedHours}
          intl={intl}
        />
      }
    >
      <>
        <Decimal
          value={allocatedHours}
          precision={precision}
          fixedDecimalScale={fixedDecimalScale}
        />
        {showDiff && (
          <DiffValue
            diffValueType={
              diff > 0 ? DIFF_VALUE_TYPE.WARNING : DIFF_VALUE_TYPE.NEGATIVE
            }
          >
            <Decimal
              value={diff}
              prefix={diff > 0 ? '+' : null}
              precision={precision}
              fixedDecimalScale={fixedDecimalScale}
            />
          </DiffValue>
        )}
      </>
    </Tooltip>
  );
};

RequestDrawerAllocatedHours.propTypes = {
  precision: PropTypes.number,
  requestedHours: PropTypes.number,
  allocatedHours: PropTypes.number,
  fixedDecimalScale: PropTypes.bool
};

export default RequestDrawerAllocatedHours;
