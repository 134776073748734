import {
  makeStyles,
  Card,
  CardContent,
  DialogContentText
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  NoDataItem,
  RemoveEntityConfirmationDialog
} from '~/modules/common/components';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { ListTable } from '~/modules/common/components/ListTable';
import { useDialogState } from '~/modules/common/hooks';
import { Caption } from '~/modules/projects/project/common/components/ContactInfo';
import {
  EditableMobileAddressInfoListItem,
  ReadOnlyMobileAddressInfoListItem
} from './MobileAddressInfoListItem';
import { useListColumns } from './columns/hooks/useListColumns';
import useAddressHandlers from './hooks/useAddressHandlers';
import { AddressInfoListItemContextProvider } from './AddressInfoListItemContextProvider';

const deleteDialogResourceKeys = {
  header:
    'projectBasicInfoCard.addressInfo.actions.deleteConfirmationDialog.removeAddress',
  actionButton:
    'projectBasicInfoCard.addressInfo.actions.deleteConfirmationDialog.removeAddress'
};

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(1.5, 0.75),
    '&.MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(1.5)
    }
  }
}));

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRootEditable: {
    borderCollapse: 'separate',
    '& tbody tr td:nth-last-child(2), & tbody tr td:nth-last-child(3) ': {
      width: theme.spacing(22.5)
    }
  },
  tableRootReadOnly: {
    borderCollapse: 'separate',
    '& tbody tr td': {
      width: theme.spacing(15)
    }
  },
  tableHeaderCell: {
    padding: theme.spacing(1, 2, 1, 0.25),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    zIndex: 1
  },
  tableCellReadOnly: {
    height: 40,
    verticalAlign: 'top',
    padding: theme.spacing(1.25, 2, 0.75, 0.25),
    maxWidth: theme.spacing(22.5),
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  tableCellEditable: {
    height: 40,
    verticalAlign: 'top',
    padding: theme.spacing(1, 2, 0.25, 0.25),
    maxWidth: theme.spacing(22.5),
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  }
}));

export const AddressInfoListTable = ({ isReadOnly, countries }) => {
  const tableStyles = useListTableThemeProviderStyles();
  const cardStyles = useStyles();

  const providerClasses = useMemo(
    () => ({
      tableRoot: isReadOnly
        ? tableStyles.tableRootReadOnly
        : tableStyles.tableRootEditable,
      tableHeaderCell: tableStyles.tableHeaderCell,
      tableCell: isReadOnly
        ? tableStyles.tableCellReadOnly
        : tableStyles.tableCellEditable
    }),
    [tableStyles, isReadOnly]
  );

  const {
    values: { addresses }
  } = useFormikContext();
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const addressHandlers = useAddressHandlers({
    openRemoveDialog,
    closeRemoveDialog,
    countries
  });

  const columns = useListColumns({ addressHandlers, countries, isReadOnly });

  return (
    <>
      {!addresses || !addresses.length ? (
        <Card elevation={0}>
          <CardContent className={cardStyles.cardContent}>
            <NoDataItem>
              <Caption messageId="projectBasicInfoCard.addressInfo.noAddresses" />
            </NoDataItem>
          </CardContent>
        </Card>
      ) : (
        <AddressInfoListItemContextProvider
          addressHandlers={addressHandlers}
          countries={countries}
        >
          <ListTableThemeProvider classes={providerClasses}>
            <ListTable
              hover={false}
              variant="table"
              columns={columns}
              mobileListItem={
                isReadOnly
                  ? ReadOnlyMobileAddressInfoListItem
                  : EditableMobileAddressInfoListItem
              }
              elevation={0}
              records={addresses}
            />
          </ListTableThemeProvider>
        </AddressInfoListItemContextProvider>
      )}
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={addressHandlers.onCloseRemoveDialog}
          onConfirm={addressHandlers.onConfirmRemoveAddress}
        >
          <DialogContentText>
            <FormattedMessage id="projectBasicInfoCard.addressInfo.actions.deleteConfirmationDialog.description" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

AddressInfoListTable.propTypes = {
  isReadOnly: PropTypes.bool,
  countries: PropTypes.array
};

export default AddressInfoListTable;
