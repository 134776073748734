import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { roundToDecimals } from '~/modules/resourcing/common/util';
import ResourceRequestEditor from '../ResourceRequestEditor/ResourceRequestEditor';

const ResourceRequestPeriodEditPopover = ({
  id,
  anchorEl,
  periodDetails = {},
  resourceRequest,
  requestedHours,
  project,
  scale,
  handleRequestPeriodClose,
  setNextPeriod,
  setPreviousPeriod,
  allocatedHours
}) => {
  const isOpen = Boolean(anchorEl);
  const handlePeriodUpdate = useCallback(
    request => {
      handleRequestPeriodClose({ request, periodDetails });
    },
    [handleRequestPeriodClose, periodDetails]
  );

  const handleNext = useCallback(
    (request, periodRequestedHours) => {
      setNextPeriod(periodRequestedHours);
    },
    [setNextPeriod]
  );

  const handlePrevious = useCallback(
    (request, periodRequestedHours) => {
      setPreviousPeriod(periodRequestedHours);
    },
    [setPreviousPeriod]
  );

  const paperProps = useMemo(
    () => ({
      elevation: 2,
      style: {
        minHeight: `${resourceRequest.quantity * 26}px`,
        width: `min-content`
      }
    }),
    [resourceRequest.quantity]
  );

  const style = anchorEl
    ? {
        minWidth: anchorEl.offsetWidth,
        minHeight: anchorEl.offsetHeight
      }
    : null;

  const anchorProps = useMemo(() => {
    const anchorRight =
      mapIsoStringtoUtcObject(periodDetails.startDate) >
      resourceRequest.startDate;
    const anchorLeft =
      mapIsoStringtoUtcObject(periodDetails.endDate) < resourceRequest.endDate;

    return {
      vertical: 'top',
      horizontal: anchorRight ? 'right' : anchorLeft ? 'left' : 'center'
    };
  }, [
    periodDetails.endDate,
    periodDetails.startDate,
    resourceRequest.endDate,
    resourceRequest.startDate
  ]);

  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={anchorProps}
      transformOrigin={anchorProps}
      open={isOpen}
      onClose={handleRequestPeriodClose}
      PaperProps={paperProps}
    >
      <div style={style}>
        <ResourceRequestEditor
          scale={scale}
          key={periodDetails.startDate.toISO()}
          allocatedHours={roundToDecimals(allocatedHours)}
          requestedHours={roundToDecimals(requestedHours)}
          handlePeriodUpdate={handlePeriodUpdate}
          handlePreviousPeriod={handlePrevious}
          handleNextPeriod={handleNext}
          periodDetails={periodDetails}
          project={project}
          resourceRequest={resourceRequest}
        />
      </div>
    </Popover>
  );
};

ResourceRequestPeriodEditPopover.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  periodDetails: PropTypes.object,
  resourceRequest: PropTypes.object,
  requestedHours: PropTypes.number,
  setNextPeriod: PropTypes.func,
  setPreviousPeriod: PropTypes.func,
  allocatedHours: PropTypes.number,
  handleRequestPeriodClose: PropTypes.func,
  scale: PropTypes.string,
  project: PropTypes.object
};

export default ResourceRequestPeriodEditPopover;
