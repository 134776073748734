/* eslint-disable no-param-reassign */
import { useCallback } from 'react';
import {
  TASK_ADD,
  TASK_ASSIGNED_TO,
  TASK_ASSIGN_ROLE,
  TASK_TREE_NAME
} from './useOnTaskClick';

const handleKeyPress = ({ gantt, onTaskClick, el, className }) => event => {
  if (event.key === 'Enter') {
    const targetTaskId = gantt.locate(event);

    if (gantt.isTaskExists(targetTaskId)) {
      const { x: clientX, y: clientY } = el.getBoundingClientRect();

      onTaskClick(gantt)(targetTaskId, {
        target: { className },
        clientX,
        clientY,
        path: []
      });
    }
  }
};

export const useOnTaskKeyPress = ({ onTaskClick }) =>
  useCallback(
    gantt => {
      document.querySelectorAll(`.${TASK_ADD}`).forEach(el => {
        el.onkeypress = handleKeyPress({
          gantt,
          onTaskClick,
          el,
          className: TASK_ADD
        });
      });
      document.querySelectorAll(`.${TASK_ASSIGNED_TO}`).forEach(el => {
        el.onkeypress = handleKeyPress({
          gantt,
          onTaskClick,
          el,
          className: TASK_ASSIGNED_TO
        });
      });
      document.querySelectorAll(`.${TASK_ASSIGN_ROLE}`).forEach(el => {
        el.onkeypress = handleKeyPress({
          gantt,
          onTaskClick,
          el,
          className: TASK_ASSIGN_ROLE
        });
      });
      document.querySelectorAll(`.${TASK_TREE_NAME}`).forEach(el => {
        el.onkeypress = handleKeyPress({
          gantt,
          onTaskClick,
          el,
          className: TASK_TREE_NAME
        });
      });
    },
    [onTaskClick]
  );

export default useOnTaskKeyPress;
