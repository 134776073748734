import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import useTooltipConditions from '~/modules/common/components/TaskStatusSection/Hooks/useTooltipConditions';
import useAlternateTooltips from '~/modules/common/components/TaskStatusSection/Hooks/useAlternateTooltips';
import {
  useAlternateHoursTooltips,
  hasRowMarginCondition,
  hasVariationRowMarginCondition
} from '~/modules/common/components/TaskStatusSection/Hooks/useAlternateHoursTooltips';
import { useMeContext } from '~/modules/me/useMeContext';
import useCalculateVariationDetails from '../Table/useCalculateVariationDetails';
import { useTaskSummaryLabels } from '../useTaskSummaryLabels';
import CostAccordion from './CostAccordion';
import HoursAccordion from './HoursAccordion';
import DatesAccordion from './DatesAccordion';

const useStyles = makeStyles({
  title: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.75, 1)
  }
});

const useMobileTaskVariationRowStyle = makeStyles({
  content: ({ addMargin }) => ({
    marginRight: addMargin ? theme.spacing(3.25) : 0
  })
});

const useSummaryRowStyle = makeStyles({
  data: ({ isBold, isTooltipVisible }) => ({
    fontWeight: isBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight,
    marginRight: isTooltipVisible ? theme.spacing(3.25) : 0
  })
});

export const MobileRollUpProjectStatusSection = ({
  source,
  hasMore,
  loadMore,
  loadingMore,
  estimatedCompletionDate
}) => {
  const classes = useStyles();

  const me = useMeContext();
  const {
    isExpenseProductEnabled,
    featureFlags: { isPsaPrpProductPackagingEnabled }
  } = me;

  const checkForExpenseProduct = isPsaPrpProductPackagingEnabled
    ? isExpenseProductEnabled
    : true;

  const {
    isClosed,
    rolledUpSummary,
    rolledUpCostSummary,
    estimatedHours,
    estimatedCost
  } = source;

  const {
    hasAlternateExpenseRemainingValue,
    hasAlternateEstimatedAtCompletionCostValue,
    hasAlternateWorkRemainingValue,
    expenseRemainingAlternateTooltipComponent,
    estimatedAtCompletionAlternateTooltipComponent,
    workRemainingAlternateTooltipComponent
  } = useAlternateTooltips({
    isClosed,
    isProjectVariant: true,
    estimatedCost,
    rolledUpCostSummary
  });

  const hasChildTasks = source.children.length > 0;

  const {
    variationStatus,
    variationHours,
    variationDays,
    projectVariationCost,
    projectCostVariationStatus
  } = useCalculateVariationDetails({
    task: source
  });
  const tooltipConditions = useTooltipConditions({
    isProjectVariant: true,
    source,
    hasChildTasks,
    hasAlternateEstimatedAtCompletionCostValue,
    hasAlternateExpenseRemainingValue,
    hasAlternateWorkRemainingValue,
    variationHours,
    variationCost: projectVariationCost
  });
  const {
    actualsRowMarginCondition,
    workRemainingRowMarginCondition,
    estimatedAtCompletionRowMarginCondition,
    initialEstimatedRowMarginCondition,
    totalExpensesRowMarginCondition,
    expenseRemainigRowMarginCondition,
    mobileTaskCostVariationRowMarginCondition
  } = tooltipConditions;

  const {
    estimatedAtCompletionHoursRowTooltipCondition,
    workRemainingHoursRowTooltipCondition,
    atleastOneHoursRowHasTooltip,
    hideVariationRowHoursTooltip,
    hideEstimatedAtCompletionRowHoursTooltip,
    estimatedAtCompletionAlternateHoursTooltipComponent,
    workRemainingAlternateHoursTooltipComponent
  } = useAlternateHoursTooltips({
    isProjectVariant: true,
    hasChildTasks,
    estimatedHours,
    rolledUpSummary,
    isClosed
  });

  const mobileTaskHoursVariationRowClassOverride = useMobileTaskVariationRowStyle(
    {
      addMargin: hasVariationRowMarginCondition({
        variationHours,
        hasChildTasks,
        atleastOneHoursRowHasTooltip,
        hideTooltip: hideVariationRowHoursTooltip
      })
    }
  );

  const mobileTaskCostVariationRowClassOverride = useMobileTaskVariationRowStyle(
    {
      addMargin: mobileTaskCostVariationRowMarginCondition
    }
  );

  const actualHoursClassOverride = useSummaryRowStyle({
    isBold: rolledUpSummary?.actualHours > 0,
    isTooltipVisible: hasRowMarginCondition(
      atleastOneHoursRowHasTooltip,
      hasChildTasks
    )
  });

  const actualCostClassOverride = useSummaryRowStyle({
    isBold: rolledUpCostSummary?.actualCostInProjectCurrency?.amount > 0,
    isTooltipVisible: actualsRowMarginCondition
  });

  const workRemainingHoursClassOverride = useSummaryRowStyle({
    isTooltipVisible: hasRowMarginCondition(
      atleastOneHoursRowHasTooltip,
      workRemainingHoursRowTooltipCondition
    )
  });

  const estimatedAtCompletionHoursClassOverride = useSummaryRowStyle({
    isTooltipVisible: hasRowMarginCondition(
      atleastOneHoursRowHasTooltip,
      estimatedAtCompletionHoursRowTooltipCondition
    )
  });

  const workRemainingClassOverride = useSummaryRowStyle({
    isTooltipVisible: workRemainingRowMarginCondition
  });

  const estimatedAtCompletionClassOverride = useSummaryRowStyle({
    isTooltipVisible: estimatedAtCompletionRowMarginCondition
  });

  const initialEstimatedHoursClassOverride = useSummaryRowStyle({
    isTooltipVisible: atleastOneHoursRowHasTooltip
  });

  const initialEstimatedCostClassOverride = useSummaryRowStyle({
    isTooltipVisible: initialEstimatedRowMarginCondition
  });

  const totalExpensesClassOverride = useSummaryRowStyle({
    isBold: rolledUpCostSummary?.totalExpensesInProjectCurrency?.amount > 0,
    isTooltipVisible: totalExpensesRowMarginCondition
  });

  const expensesRemainingClassOverride = useSummaryRowStyle({
    isTooltipVisible: expenseRemainigRowMarginCondition
  });

  const projectPermittedActionUris = source.permittedActionUris || [];
  const showCost = projectPermittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  const {
    actuals,
    workRemaining,
    estimatedAtCompletion,
    estimateBudget,
    totalExpenses,
    expensesRemaining
  } = useTaskSummaryLabels({
    isProjectVariant: true
  });

  const { hoursOrCostLabels, dateLabels } = useMemo(
    () => ({
      hoursOrCostLabels: {
        actuals,
        workRemaining,
        estimatedAtCompletion,
        estimateBudget,
        totalExpenses,
        expensesRemaining
      },
      dateLabels: {
        estimatedAtCompletion,
        estimateBudget
      }
    }),
    [
      actuals,
      estimateBudget,
      estimatedAtCompletion,
      expensesRemaining,
      totalExpenses,
      workRemaining
    ]
  );

  const hoursTooltipConditions = useMemo(
    () => ({
      hideVariationRowHoursTooltip,
      hideEstimatedAtCompletionRowHoursTooltip
    }),
    [hideEstimatedAtCompletionRowHoursTooltip, hideVariationRowHoursTooltip]
  );

  return (
    <>
      <div className={classes.title}>
        <FormattedMessage id="projectDrawer.projectSummary" />
      </div>
      <HoursAccordion
        task={source}
        loadMore={loadMore}
        hasMore={hasMore}
        loadingMore={loadingMore}
        variation={variationHours}
        variationStatus={variationStatus}
        actualsClassOverride={actualHoursClassOverride}
        workRemainingClassOverride={workRemainingHoursClassOverride}
        estimatedAtCompletionClassOverride={
          estimatedAtCompletionHoursClassOverride
        }
        initialEstimatedClassOverride={initialEstimatedHoursClassOverride}
        labels={hoursOrCostLabels}
        estimatedAtCompletionAlternateTooltipComponent={
          estimatedAtCompletionAlternateHoursTooltipComponent
        }
        workRemainingAlternateTooltipComponent={
          workRemainingAlternateHoursTooltipComponent
        }
        mobileTaskVariationRowClassOverride={
          mobileTaskHoursVariationRowClassOverride
        }
        tooltipConditions={hoursTooltipConditions}
      />
      {showCost && (
        <CostAccordion
          task={source}
          loadMore={loadMore}
          hasMore={hasMore}
          loadingMore={loadingMore}
          variation={projectVariationCost}
          variationStatus={projectCostVariationStatus}
          actualsClassOverride={actualCostClassOverride}
          initialEstimatedClassOverride={initialEstimatedCostClassOverride}
          labels={hoursOrCostLabels}
          showExpense={checkForExpenseProduct}
          totalExpensesClassOverride={totalExpensesClassOverride}
          expensesRemainingClassOverride={expensesRemainingClassOverride}
          workRemainingClassOverride={workRemainingClassOverride}
          estimatedAtCompletionClassOverride={
            estimatedAtCompletionClassOverride
          }
          estimatedAtCompletionAlternateTooltipComponent={
            estimatedAtCompletionAlternateTooltipComponent
          }
          expenseRemainingAlternateTooltipComponent={
            expenseRemainingAlternateTooltipComponent
          }
          workRemainingAlternateTooltipComponent={
            workRemainingAlternateTooltipComponent
          }
          mobileTaskVariationRowClassOverride={
            mobileTaskCostVariationRowClassOverride
          }
          tooltipConditions={tooltipConditions}
        />
      )}
      <DatesAccordion
        task={source}
        estimatedCompletionDate={estimatedCompletionDate}
        endDate={source.endDate}
        variationDays={variationDays}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        labels={dateLabels}
        tooltipConditions={tooltipConditions}
      />
    </>
  );
};

MobileRollUpProjectStatusSection.propTypes = {
  source: PropTypes.object.isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  estimatedCompletionDate: PropTypes.string
};

export default MobileRollUpProjectStatusSection;
