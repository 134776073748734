import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';

const useAddressHandlers = ({
  openRemoveDialog,
  closeRemoveDialog,
  countries
}) => {
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const {
    initialValues,
    values: { addresses },
    setFieldValue
  } = useFormikContext();

  const onAddressChange = useCallback(
    ({ index, field, value }) => {
      setFieldValue(`addresses[${index}].${field}`, value);
    },
    [setFieldValue]
  );

  const onCountryChange = useCallback(
    ({ index, value }) => {
      const country = countries.find(c => c.id === value);

      setFieldValue(`addresses[${index}].country`, country);
    },
    [countries, setFieldValue]
  );

  const onRemoveAddress = useCallback(
    r => {
      const isExistingEntry = initialValues.addresses.some(
        address => address.id === r.id
      );

      if (isExistingEntry) {
        setSelectedRecordId(r.id);
        openRemoveDialog();
      } else {
        setFieldValue(
          'addresses',
          addresses.filter(address => address.id !== r.id)
        );
      }
    },
    [addresses, initialValues.addresses, openRemoveDialog, setFieldValue]
  );

  const onConfirmRemoveAddress = useCallback(() => {
    setFieldValue(
      'addresses',
      addresses.filter(address => address.id !== selectedRecordId)
    );
    closeRemoveDialog();
  }, [addresses, selectedRecordId, setFieldValue, closeRemoveDialog]);

  const onCloseRemoveDialog = useCallback(
    (_, reason) => {
      if (reason && reason === 'backdropClick') return;
      closeRemoveDialog();
    },
    [closeRemoveDialog]
  );

  return {
    onAddressChange,
    onCountryChange,
    onRemoveAddress,
    onConfirmRemoveAddress,
    onCloseRemoveDialog
  };
};

export default useAddressHandlers;
