import { roundToDecimals } from '~/modules/resourcing/common/util';

export const convertToComparableValue = ({
  hours,
  scheduledHours,
  isHoursMode
}) => {
  const value = isHoursMode ? hours : (hours * 100) / scheduledHours;

  return roundToDecimals(value);
};

export const compareEditorValue = ({
  isHoursMode,
  initialAllocatedHours,
  scheduledHours,
  inputValue
}) => {
  const previousValue = convertToComparableValue({
    isHoursMode,
    hours: initialAllocatedHours,
    scheduledHours
  });
  const currentValue = inputValue;

  return roundToDecimals(previousValue) === roundToDecimals(currentValue);
};
