import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';
import { useListColumns } from './columns';
import { useListFooters } from './footers';
import { MobileScriptParamsEditorListTable } from './MobileScriptParmsEditorTable';

const alignDeleteRow = 'center';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      position: 'sticky',
      right: 0
    },
    borderBottom: 'none',
    width: '100%',
    padding: theme.spacing(0, 1),
    paddingTop: 'none'
  },
  tableHeaderCell: {
    fontSize: theme.typography.body2.fontSize,
    verticalAlign: 'bottom',
    whiteSpace: 'break-spaces',
    position: 'sticky',
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    height: 0,
    padding: theme.spacing(0.5, 0.5)
  },
  tableCell: {
    height: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5, 0.5),
    whiteSpace: 'break-spaces',
    '& p': {
      fontWeight: theme.typography.fontWeightRegular
    },
    verticalAlign: 'top'
  },
  tableFooterCell: {
    paddingTop: 0,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    color: theme.palette.grey[800],
    height: 0,
    borderBottom: 'none'
  },
  tableFooterRow: {
    backgroundColor: theme.palette.common.white
  }
}));

const useColumnStyles = makeStyles(theme => ({
  date: {
    '& p': {
      whiteSpace: 'break-spaces'
    }
  },
  money: {
    '& span': {
      whiteSpace: 'nowrap'
    }
  },
  'read-only-amount': {
    '& span': {
      whiteSpace: 'nowrap'
    }
  },
  delete: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1)
  }
}));

const useCardStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    boxShadow: 'none'
  }
}));

export const ScriptParamsEditorTable = ({
  editable,
  parameters,
  scripts,
  totalEstimatedAmount,
  customEditors,
  customFormatters,
  setFieldValue,
  onDeleteRow,
  errors,
  errorKey,
  footerProps,
  canDeleteRow = true,
  isMobile,
  ...rest
}) => {
  const providerClasses = useListTableThemeProviderStyles();
  const columnClasses = useColumnStyles();
  const cardClasses = useCardStyles();
  const columns = useListColumns({
    parameters,
    setFieldValue,
    customEditors,
    customFormatters,
    editable,
    onDeleteRow,
    classes: columnClasses,
    errors,
    errorKey,
    footerProps,
    canDeleteRow,
    isMobile,
    alignDeleteRow,
    ...rest
  });

  const footers = useListFooters({
    parameters,
    customEditors,
    customFormatters,
    classes: columnClasses,
    editable,
    footerProps,
    isMobile,
    ...rest
  });

  const eAmount = useMemo(
    () =>
      totalEstimatedAmount
        ? {
            totalEstimatedAmount: {
              ...totalEstimatedAmount,
              amount: getRoundedValue(totalEstimatedAmount.amount || 0, 2)
            }
          }
        : null,
    [totalEstimatedAmount]
  );

  const totals = useMemo(
    () => ({
      ...eAmount,
      ...footerProps
    }),
    [eAmount, footerProps]
  );

  return scripts.length ? (
    isMobile && editable ? (
      <MobileScriptParamsEditorListTable
        columns={columns}
        records={scripts}
        footers={footers}
        totals={totals}
      />
    ) : (
      <ListTableThemeProvider classes={providerClasses}>
        <ListTable
          variant="table"
          classes={cardClasses}
          columns={columns}
          records={scripts}
          footers={footers}
          totals={totals}
        />
      </ListTableThemeProvider>
    )
  ) : null;
};

ScriptParamsEditorTable.propTypes = {
  editable: PropTypes.bool,
  scripts: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func,
  onDeleteRow: PropTypes.func,
  parameters: PropTypes.array.isRequired,
  customEditors: PropTypes.object,
  customFormatters: PropTypes.object,
  errors: PropTypes.object,
  errorKey: PropTypes.string,
  totalEstimatedAmount: PropTypes.object,
  footerProps: PropTypes.object,
  canDeleteRow: PropTypes.bool
};

export default ScriptParamsEditorTable;
