import { useState, useCallback } from 'react';
import { useAddClientMutation } from '~/modules/clients/graphql';
import {
  extractFirstValidationError,
  extractFirstErrorMessage
} from '~/modules/common/graphql/errors';

export const useAddClientOnSubmit = ({
  history,
  onClose,
  mapValuesOnSubmit,
  onClientAdd
}) => {
  const [error, setError] = useState();

  const clearError = useCallback(() => setError(null), [setError]);

  const [addClient] = useAddClientMutation();

  const onSubmit = useCallback(
    async (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);

      const payload = mapValuesOnSubmit(values);

      try {
        const { data } = await addClient({
          variables: {
            client: payload
          },
          refetchQueries: ['ClientsDropdownOptions'],
          awaitRefetchQueries: true
        });

        const {
          addClient2: { id, slug, displayText }
        } = data;

        if (onClientAdd) {
          onClientAdd({ displayText, id });
          onClose();

          return;
        }

        history.push(`/clients/${slug}?edit=true`);

        onClose();
      } catch (err) {
        const validationErrors = extractFirstValidationError(err);

        if (validationErrors) {
          const duplicationNameError = validationErrors.find(
            v =>
              v.failureUri ===
              'urn:replicon:validation-failure:client-name-duplicated'
          );

          setFieldError('name', duplicationNameError.displayText);
        } else {
          setError(extractFirstErrorMessage(err));
        }
      } finally {
        setSubmitting(false);
      }
    },
    [addClient, history, mapValuesOnSubmit, onClientAdd, onClose]
  );

  return { onSubmit, error, clearError };
};
