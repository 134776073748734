import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import {
  INCLUDE_DIMENSION_SLUGS,
  OVERRIDE_DIMENSION_PROPS,
  REQUIRED_DIMENSION_SLUGS
} from './enum';

export const GET_PAGE_OF_AVAILABLE_RATE_TABLE_DIMENSIONS = gql`
  query getPageOfAvailableRateTableDimensions(
    $page: Int!
    $pageSize: Int!
    $filter: RateTableDimensionFilter
  ) {
    pageOfAvailableRateTableDimensions(
      page: $page
      pageSize: $pageSize
      filter: $filter
    ) {
      id
      displayText
      slug
      name
      objectType
    }
  }
`;

export const usePageOfAvailableRateTableDimensions = (
  page = 1,
  pageSize = 100
) => {
  const intl = useIntl();

  const { data, loading } = useQuery(
    GET_PAGE_OF_AVAILABLE_RATE_TABLE_DIMENSIONS,
    {
      variables: { page, pageSize },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network'
    }
  );

  const dimensions = data?.pageOfAvailableRateTableDimensions || [];

  return {
    loading,
    availableRateTableDimensions: (dimensions || [])
      .filter(d => INCLUDE_DIMENSION_SLUGS.includes(d.slug))
      .map(d => ({
        ...d,
        ...(OVERRIDE_DIMENSION_PROPS[d.slug]
          ? OVERRIDE_DIMENSION_PROPS[d.slug](intl)
          : {})
      }))
      .sort((a, b) => a.sortIndex - b.sortIndex)
      .map(({ sortIndex, ...rest }) => rest),
    requiredDimensions: (dimensions || []).filter(d =>
      REQUIRED_DIMENSION_SLUGS.includes(d.slug)
    )
  };
};
