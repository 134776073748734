import { useState, useCallback } from 'react';
import { useDeleteBill } from '~/modules/billing-invoicing/bill/hooks';

export const useEditBillDrawerHandler = ({
  values,
  createAndView,
  onBillDataUpdatedOnAvailableToBill,
  onCancel,
  closeRemoveDialog
}) => {
  const [billDeleteInProgress, setBillDeleteInProgress] = useState(false);
  const { deleteBill } = useDeleteBill(createAndView);

  const onDeleteConfirm = useCallback(async () => {
    setBillDeleteInProgress(true);
    await deleteBill(values.id);
    await onBillDataUpdatedOnAvailableToBill(() => {
      setBillDeleteInProgress(false);
      onCancel();
    });
  }, [deleteBill, onBillDataUpdatedOnAvailableToBill, onCancel, values]);

  const onCloseRemoveDialog = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
      closeRemoveDialog();
    },
    [closeRemoveDialog]
  );

  return {
    onDeleteConfirm,
    billDeleteInProgress,
    onCloseRemoveDialog
  };
};
