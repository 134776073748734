import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import {
  ColumnSettingsDialog,
  useTableSettings,
  useSetListSort
} from '~/modules/common/components/ListTable';
import { useMeContext } from '~/modules/me';
import { useGetEnabledCustomFieldsQuery } from '~/modules/customFields/graphql';
import { useGetEnabledTagFieldsQuery } from '~/modules/tags/graphql';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import {
  Toolbar,
  ResourceManagementTabs,
  ResourceManagementActionIcons
} from '../common/components';
import { RESOURCE_MANAGEMENT_TAB_ENUM } from '../common';
import ResourceUserListTable from './ResourceUserListTable';
import getResourceColumns, {
  TABLE_KEY,
  hasViewCustomFields,
  mobileColumnNames as mobileColumns,
  getDefaultColumnNames
} from './resourceColumns';

const pageTitle = <FormattedMessage id="routes.resourceManagement" />;

const getSort = ({ currentSort }) => {
  if (!currentSort) {
    return null;
  }

  return {
    field: currentSort.field,
    direction: currentSort.direction,
    customFieldUri: currentSort.customFieldUri
  };
};

export const ResourceUserList = ({
  isMobile,
  selectedTab,
  setSelectedTab,
  openSettings,
  openSettingsDialog,
  closeSettingsDialog
}) => {
  const me = useMeContext();
  const [currentPage, setCurrentPage] = useState(1);
  const { permissions } = me;
  const {
    data: customFieldsData,
    loading: customFieldLoading
  } = useGetEnabledCustomFieldsQuery({
    variables: { groupName: 'User' }
  });

  const customFieldDefinitions =
    get(customFieldsData, 'enabledCustomFields') || [];

  const defaultColumnNames = getDefaultColumnNames({
    customFieldDefinitions,
    me
  });

  const { data, loading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: defaultColumnNames
  });

  const columns = isMobile ? mobileColumns({ me }) : get(data, 'columns') || [];

  const resourceColumns = getResourceColumns({
    customFieldDefinitions,
    me
  });
  const currentSort = get(data, 'sort');

  const { onSortChange, loading: sortLoading } = useSetListSort({
    tableKey: TABLE_KEY,
    currentSort,
    currentColumns: columns
  });

  const sort = getSort({ currentSort });

  const settingsLoading = loading || customFieldLoading || sortLoading;

  const customFieldUris = customFieldDefinitions
    .filter(c => columns.includes(c.id))
    .map(c => c.id);

  const tagFieldUris = columns.filter(c =>
    /^urn:replicon-tenant:[^:]+:object-extension-tag-definition:.*$/.exec(c)
  );

  const tagFieldData = useGetEnabledTagFieldsQuery(columns);

  return (
    <>
      <StickyHeader
        showMenuButton={isMobile}
        title={pageTitle}
        disableTitleFullWidth
        rightPadding
      >
        {!isMobile && <Toolbar setCurrentPage={setCurrentPage} />}
        {data && (
          <ColumnSettingsDialog
            tableKey={TABLE_KEY}
            groups={resourceColumns}
            selectedColumns={columns}
            sort={currentSort}
            defaultColumns={defaultColumnNames}
            open={openSettings}
            onClose={closeSettingsDialog}
            tagsObjectBinding={
              hasViewCustomFields(permissions) ? 'RESOURCE' : null
            }
          />
        )}
      </StickyHeader>
      {isMobile && <Toolbar />}
      <StickyHeader level={1} bottomBorder>
        <ResourceManagementTabs
          onChange={setSelectedTab}
          selectedTab={selectedTab}
        />
        <ResourceManagementActionIcons
          selectedTab={selectedTab}
          onSettingsClick={openSettingsDialog}
        />
      </StickyHeader>

      <ResourceUserListTable
        customFieldDefinitions={customFieldDefinitions}
        enabledFields={columns}
        customFieldUris={customFieldUris}
        isMobile={isMobile}
        tagFieldUris={tagFieldUris}
        tagFieldData={tagFieldData.data}
        onSortChange={onSortChange}
        sort={sort}
        settingsLoading={settingsLoading}
        me={me}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

ResourceUserList.propTypes = {
  isMobile: PropTypes.bool,
  selectedTab: PropTypes.oneOf([
    RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE,
    RESOURCE_MANAGEMENT_TAB_ENUM.LIST,
    RESOURCE_MANAGEMENT_TAB_ENUM.OVERVIEW
  ]),
  setSelectedTab: PropTypes.func,
  openSettings: PropTypes.bool,
  openSettingsDialog: PropTypes.func,
  closeSettingsDialog: PropTypes.func
};

export default ResourceUserList;
