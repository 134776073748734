import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { LinearQueryProgress } from '~/modules/common';

const makeTabRoute = props => route => (
  <Route
    key={route.path}
    path={route.path}
    exact={route.exact !== undefined ? route.exact : true}
    render={compProps => <route.component {...compProps} {...props} />}
  />
);

const TabContents = ({ tabRoutes, ...props }) => (
  <Suspense fallback={<LinearQueryProgress />}>
    <Switch>
      {tabRoutes.map(makeTabRoute(props))}
      <RedirectToNotFound />
    </Switch>
  </Suspense>
);

TabContents.propTypes = {
  tabRoutes: PropTypes.array.isRequired,
  progress: PropTypes.node
};

export default TabContents;
