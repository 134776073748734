import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonBase, IconButton, Typography } from '@material-ui/core';
import TabIndicator from '@material-ui/core/Tabs/TabIndicator';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import { SortDirection } from '~/types';
import DrilldownTable, {
  DrilldownTableLoading
} from '~/modules/common/components/DrilldownTable';
import { MoneyValue } from '~/modules/common/components/Money';
import { withOptionalField } from '~/modules/common/components/OptionalField';
import ProfitabilityDrilldownProfitValue from './ProfitabilityDrilldownProfitValue';

const useStyles = makeStyles(theme => ({
  backButton: {
    alignSelf: 'center'
  },
  topRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    padding: theme.spacing(1)
  },
  dateList: {
    display: 'contents'
  },
  date: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short
    })
  },
  label: {
    ...theme.typography.caption,
    lineHeight: 1,
    flexGrow: 1,
    flexShrink: 0,
    textAlign: 'center',
    paddingTop: '20px',
    padding: theme.spacing(1, 0.5)
  },
  showMoreButton: {
    textTransform: 'none',
    width: '100%'
  }
}));

export const ProfitabilityDrilldown = ({
  chartDates,
  selectedPeriod,
  setSelectedPeriod,
  filter,
  intl,
  useProfitabilityListData,
  label,
  noItemsMessage,
  onRowClick
}) => {
  const classes = useStyles();
  const goBack = useCallback(() => setSelectedPeriod(null), [
    setSelectedPeriod
  ]);
  const setPeriod = period => () => setSelectedPeriod(period);
  const [sortSetting, setSortSetting] = useState({});

  const onClick = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget;

      if (id) {
        onRowClick({
          id,
          dateRange: selectedPeriod
            ? {
                startDate: selectedPeriod.start.toISO(),
                endDate: selectedPeriod.end.toISO()
              }
            : null
        });
      }
    },
    [onRowClick, selectedPeriod]
  );

  const updateSort = useCallback(
    sortField =>
      sortField &&
      setSortSetting(prevState => {
        let newDirection = SortDirection.Asc;

        if (prevState.field === sortField) {
          if (prevState.direction === SortDirection.Asc) {
            newDirection = SortDirection.Desc;
          } else if (prevState.direction === SortDirection.Desc) {
            return {};
          }
        }

        return {
          field: sortField,
          direction: newDirection
        };
      }),
    [setSortSetting]
  );

  const columns = useMemo(
    () => [
      {
        key: 'displayText',
        sortField: 'name',
        label,
        align: 'left',
        component: Typography,
        transformValue: (value, _, isTotalRow) => ({
          children: isTotalRow
            ? intl.formatMessage({
                id: 'profitabilityDrilldown.total'
              })
            : value,
          variant: 'inherit'
        })
      },
      {
        key: 'totalActualRevenue',
        sortField: 'totalActualRevenue',
        label: intl.formatMessage({ id: 'profitabilityDrilldown.revenue' }),
        align: 'right',
        component: withOptionalField('money')(MoneyValue),
        transformValue: value => ({
          money: value
        })
      },
      {
        key: 'totalActualCost',
        sortField: 'totalActualCost',
        label: intl.formatMessage({ id: 'profitabilityDrilldown.cost' }),
        align: 'right',
        component: withOptionalField('money')(MoneyValue),
        transformValue: value => ({
          money: value
        })
      },
      {
        key: 'totalActualProfit',
        label: intl.formatMessage({ id: 'profitabilityDrilldown.profit' }),
        align: 'right',
        component: withOptionalField('money')(
          ProfitabilityDrilldownProfitValue
        ),
        transformValue: (_, i) => ({
          money:
            i.totalActualRevenue || i.totalActualCost
              ? {
                  amount:
                    ((i.totalActualRevenue && i.totalActualRevenue.amount) ||
                      0) -
                    ((i.totalActualCost && i.totalActualCost.amount) || 0),
                  currency:
                    (i.totalActualRevenue && i.totalActualRevenue.currency) ||
                    i.totalActualCost.currency
                }
              : null
        })
      }
    ],
    [intl, label]
  );

  const {
    loading,
    error,
    items,
    totals,
    loadMore,
    hasMore,
    loadingMore
  } = useProfitabilityListData({
    pageSize: 10,
    filter,
    dateRange: selectedPeriod
      ? {
          startDate: selectedPeriod.start.toISODate(),
          endDate: selectedPeriod.end.toISODate()
        }
      : null,
    sort: sortSetting
  });

  if (!selectedPeriod || error) return <></>;

  return (
    <>
      <div className={classes.topRow}>
        <IconButton className={classes.backButton} onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <ul className={classes.dateList}>
          {chartDates.map(item => (
            <ButtonBase
              key={item.key}
              className={classes.date}
              component="li"
              value={item.label}
              onClick={setPeriod(item)}
            >
              <div className={classes.label}>{item.label}</div>
              {item.key === selectedPeriod.key ? (
                <TabIndicator
                  className={classes.indicator}
                  orientation="horizontal"
                  color="primary"
                />
              ) : null}
            </ButtonBase>
          ))}
        </ul>
      </div>
      {loading ? (
        <DrilldownTableLoading />
      ) : (
        <DrilldownTable
          columns={columns}
          items={items}
          totals={totals}
          noItemsMessage={noItemsMessage}
          onRowClick={onClick}
          rowIdKey="slug"
          onSortChange={updateSort}
          sort={sortSetting}
        />
      )}
      {loadingMore && <DrilldownTableLoading />}
      {hasMore && !loading && !loadingMore && (
        <Button
          className={classes.showMoreButton}
          color="primary"
          onClick={loadMore}
        >
          <FormattedMessage id="profitabilityDrilldown.showMore" />
        </Button>
      )}
    </>
  );
};

ProfitabilityDrilldown.propTypes = {
  chartDates: PropTypes.array.isRequired,
  selectedPeriod: PropTypes.object,
  setSelectedPeriod: PropTypes.func.isRequired,
  filter: PropTypes.object,
  intl: PropTypes.object,
  useProfitabilityListData: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  noItemsMessage: PropTypes.string.isRequired,
  onRowClick: PropTypes.func
};

export default injectIntl(ProfitabilityDrilldown);
