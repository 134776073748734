import React, { useCallback } from 'react';
import { TableRow, TableCell, IconButton, makeStyles } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { User } from '~/modules/common/components';
import { useUserOverrideStyles, useStyles } from './styles';

const useRemoveIconStyles = makeStyles(theme => ({
  removeIconCell: {
    borderBottom: 'none',
    textAlign: 'right',
    '&:last-child': {
      paddingRight: '5px'
    },
    right: 0
  },
  user: {
    borderBottom: 'none'
  },
  button: {
    padding: 0
  }
}));

export const PreferredResourceTableRow = ({
  onRemove,
  resource,
  removeIconAriaLabel
}) => {
  const classes = useStyles();
  const removeIconClasses = useRemoveIconStyles();
  const userOverrideClasses = useUserOverrideStyles();

  const onRemoveHandler = useCallback(() => {
    onRemove(resource);
  }, [onRemove, resource]);

  return (
    <TableRow>
      <TableCell className={classes.user}>
        <User user={resource} classes={userOverrideClasses} />
      </TableCell>
      <TableCell className={removeIconClasses.removeIconCell}>
        <IconButton
          onClick={onRemoveHandler}
          className={classes.button}
          aria-label={removeIconAriaLabel}
        >
          <RemoveCircle />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

PreferredResourceTableRow.propTypes = {
  onRemove: PropTypes.func,
  resource: PropTypes.object,
  removeIconAriaLabel: PropTypes.string
};

export default PreferredResourceTableRow;
