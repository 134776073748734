import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import NoValue from '~/modules/common/components/NoValue';

const KILOBYTE = 1024;
const MEGABYTE = KILOBYTE * KILOBYTE;

const resolveFileSize = fileSizeBytes => {
  if (fileSizeBytes === null || fileSizeBytes === undefined) {
    return null;
  }

  if (fileSizeBytes < 5 * 1024) {
    return `${fileSizeBytes} B`;
  }

  if (fileSizeBytes < MEGABYTE) {
    return `${(fileSizeBytes / KILOBYTE).toFixed(2)} KB`;
  }

  return `${(fileSizeBytes / MEGABYTE).toFixed(2)} MB`;
};

const FileUploadFileSize = ({ fileSizeBytes }) => {
  const fileSizeString = useMemo(() => resolveFileSize(fileSizeBytes), [
    fileSizeBytes
  ]);

  return <>{fileSizeString || <NoValue />}</>;
};

FileUploadFileSize.propTypes = {
  fileSizeBytes: PropTypes.number
};

export default FileUploadFileSize;
