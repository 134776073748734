import React from 'react';
import MobileListItemRowLoading from './MobileListItemRowLoading';

export const MobileListItemLoading = () => (
  <>
    <MobileListItemRowLoading />
    <MobileListItemRowLoading />
    <MobileListItemRowLoading />
  </>
);

export default MobileListItemLoading;
