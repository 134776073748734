import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryTooltip,
  VictoryContainer
} from 'victory';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useVictoryChartContainerStyles } from '~/modules/common/charts/dashboard/config';
import { ChartLegends } from '../ChartLegends';
import chartStyles from './chartStyles';
import Gradient from './Gradient';
import useSeriesChartHooks from './useSeriesChartHooks';

const labelComponent = <VictoryTooltip />;

const useContainerStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

export const SeriesChart = ({
  classes: classesOverride,
  actualDataPoints,
  estimatedDataPoints,
  actualBarColor,
  gradientId,
  yAxisLabel = '',
  name = '',
  titleId = '',
  legends
}) => {
  const theme = useTheme();
  const chartStyle = chartStyles(theme);
  const classes = useContainerStyles({ classes: classesOverride });
  const victoryContainerClasses = useVictoryChartContainerStyles();
  const intl = useIntl();
  const ESTIMATED_BAR_GRADIENT_TOP_COLOR = theme.palette.grey[600];
  const ESTIMATED_BAR_GRADIENT_BOTTOM_COLOR = theme.palette.grey[800];

  const {
    xTickValues,
    actualBarStyles,
    estimatedBarStyles,
    yTickFormat,
    xTickFormat
  } = useSeriesChartHooks({
    name,
    gradientId,
    intl
  });

  return (
    <div
      className={classNames(
        classes.container,
        victoryContainerClasses.container
      )}
    >
      <Gradient
        actualBarTopColor={actualBarColor}
        actualBarBottomColor={actualBarColor}
        actualBarGradientId={`${name}${gradientId}`}
        estimatedBarTopColor={ESTIMATED_BAR_GRADIENT_TOP_COLOR}
        estimatedBarBottomColor={ESTIMATED_BAR_GRADIENT_BOTTOM_COLOR}
        estimatedBarGradientId={`${name}gradientForEstimated`}
      />

      <ChartLegends legends={legends} />
      <VictoryChart
        width={chartStyle.victoryChart.width}
        height={chartStyle.victoryChart.height}
        padding={chartStyle.victoryChart.padding}
        domainPadding={chartStyle.victoryChart.domainPadding}
        containerComponent={<VictoryContainer aria-labelledby={titleId} />}
      >
        <VictoryAxis
          style={chartStyle.victoryAxis.x}
          fixLabelOverlap={false}
          tickValues={xTickValues}
          tickFormat={xTickFormat}
          name={`${name}-xAxis`}
        />
        <VictoryAxis
          dependentAxis
          style={chartStyle.victoryAxis.y}
          domain={chartStyle.victoryAxis.y.domainPadding}
          tickFormat={yTickFormat}
          name={`${name}-yAxis`}
          label={yAxisLabel}
        />
        <VictoryBar
          data={estimatedDataPoints}
          labelComponent={labelComponent}
          style={estimatedBarStyles}
          y="value"
          x="month"
          alignment={chartStyle.victoryBar.estimatedAlignment}
          barWidth={chartStyle.victoryBar.barWidth}
        />
        <VictoryBar
          data={actualDataPoints}
          labelComponent={labelComponent}
          style={actualBarStyles}
          y="value"
          x="month"
          alignment={chartStyle.victoryBar.actualAlignment}
          barWidth={chartStyle.victoryBar.barWidth}
        />
      </VictoryChart>
    </div>
  );
};

SeriesChart.propTypes = {
  classes: PropTypes.object,
  estimatedDataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  actualDataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  actualBarColor: PropTypes.string.isRequired,
  gradientId: PropTypes.string.isRequired,
  titleId: PropTypes.string,
  name: PropTypes.string,
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      color: PropTypes.string
    })
  ).isRequired,
  yAxisLabel: PropTypes.string
};

export default SeriesChart;
