import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ResourceActualHoursSummaryBlocks2 } from '~/modules/resourcing/common/components/ResourceActualHoursSummaryBlocks';
import { getAllocationTotalsByPeriod } from '~/modules/resourcing/util';

const UserRoleActualsTimeLineRow = ({
  actualSummaryBlockPopupProps,
  actualsData,
  chartDisplayDateRange,
  chartDisplayPeriods,
  resourceAllocations,
  scale,
  user,
  userScheduleDetails
}) => {
  const cssStyles = useMemo(
    () => ({
      top: (resourceAllocations?.length || 0) * 32 + 8
    }),
    [resourceAllocations]
  );

  return (
    <ResourceActualHoursSummaryBlocks2
      actualSummaryBlockPopupProps={actualSummaryBlockPopupProps}
      allocationPeriods={getAllocationTotalsByPeriod({
        allocations: resourceAllocations,
        chartDisplayDateRange,
        chartDisplayPeriods,
        userScheduleDetails
      })}
      chartDisplayPeriods={chartDisplayPeriods}
      positionAttributes={cssStyles}
      resourceUser={user}
      resourceUserActualTimePeriods={actualsData?.periods}
      scale={scale}
      dataQeId="UserRoleActualsRow"
    />
  );
};

UserRoleActualsTimeLineRow.propTypes = {
  user: PropTypes.object,
  actualsData: PropTypes.object,
  scale: PropTypes.string,
  resourceAllocations: PropTypes.array,
  chartDisplayDateRange: PropTypes.object,
  chartDisplayPeriods: PropTypes.array,
  actualSummaryBlockPopupProps: PropTypes.object,
  userScheduleDetails: PropTypes.object.isRequired
};

export default UserRoleActualsTimeLineRow;
