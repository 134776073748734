import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me';
import { useEffectiveProjectGroupPolicy } from '~/modules/common/components/Groups/project';

export const PROJECT_TIMESHEET_ACCESS_QUERY = gql`
  query GetProjectTimesheetAccess(
    $projectId: String
    $includeLocations: Boolean!
    $includeServiceCenters: Boolean!
    $includeDivisions: Boolean!
    $includeCostCenters: Boolean!
    $includeDepartments: Boolean!
    $includeEmployeeTypes: Boolean!
    $includeLocationFullPath: Boolean!
    $includeServiceCenterFullPath: Boolean!
    $includeDivisionFullPath: Boolean!
    $includeCostCenterFullPath: Boolean!
    $includeDepartmentFullPath: Boolean!
    $includeEmployeeTypeFullPath: Boolean!
    $policy: String
  ) {
    project(projectId: $projectId) {
      id
      timesheetAccess(policy: $policy) {
        allUsers {
          id
          isAssigned
        }
        locations @include(if: $includeLocations) {
          id
          displayText
          fullPath @include(if: $includeLocationFullPath)
        }
        costCenters @include(if: $includeCostCenters) {
          id
          displayText
          fullPath @include(if: $includeCostCenterFullPath)
        }
        divisions @include(if: $includeDivisions) {
          id
          displayText
          fullPath @include(if: $includeDivisionFullPath)
        }
        departments @include(if: $includeDepartments) {
          id
          displayText
          fullPath @include(if: $includeDepartmentFullPath)
        }
        serviceCenters @include(if: $includeServiceCenters) {
          id
          displayText
          fullPath @include(if: $includeServiceCenterFullPath)
        }
        employeeTypes @include(if: $includeEmployeeTypes) {
          id
          displayText
          fullPath @include(if: $includeEmployeeTypeFullPath)
        }
      }
    }
  }
`;

export const useProjectTimesheetAccess = ({ projectId }) => {
  const { groupSettings, permissionsMap } = useMeContext();
  const {
    costCenter: costCenterSettings,
    department: departmentSettings,
    division: divisionSettings,
    employeeType: employeeTypeSettings,
    location: locationSettings,
    serviceCenter: serviceCenterSettings
  } = groupSettings;

  const effectiveProjectGroupPolicy = useEffectiveProjectGroupPolicy();
  const variables = {
    projectId,
    includeLocations: locationSettings.isEnabled,
    includeServiceCenters: serviceCenterSettings.isEnabled,
    includeDivisions: divisionSettings.isEnabled,
    includeCostCenters: costCenterSettings.isEnabled,
    includeDepartments: departmentSettings.isEnabled,
    includeEmployeeTypes: employeeTypeSettings.isEnabled,
    includeLocationFullPath: Boolean(
      permissionsMap['urn:replicon:location-action:view-location-details']
    ),
    includeServiceCenterFullPath: Boolean(
      permissionsMap[
        'urn:replicon:service-center-action:view-service-center-details'
      ]
    ),
    includeDivisionFullPath: Boolean(
      permissionsMap['urn:replicon:division-action:view-division-details']
    ),
    includeCostCenterFullPath: Boolean(
      permissionsMap['urn:replicon:cost-center-action:view-cost-center-details']
    ),
    includeDepartmentFullPath: Boolean(
      permissionsMap['urn:replicon:department-action:view-department']
    ),
    includeEmployeeTypeFullPath: Boolean(
      permissionsMap['urn:replicon:employee-type-action:view-employee-type']
    ),
    policy: effectiveProjectGroupPolicy
  };

  const { data, loading } = useQuery(PROJECT_TIMESHEET_ACCESS_QUERY, {
    variables,
    fetchPolicy: 'network-only'
  });

  let groupsOrder = {};

  if (data?.project?.timesheetAccess) {
    const { __typename, ...groups } = data.project.timesheetAccess;

    groupsOrder = Object.keys(groups).sort((a, b) => {
      if (a === 'allUsers' || b === 'allUsers') {
        return 1;
      }
      const getGroupName = groupName =>
        groupSettings[groupName.slice(0, -1)].singularName || groupName;

      return getGroupName(a).localeCompare(getGroupName(b));
    });
  }

  return {
    costCenterSettings,
    departmentSettings,
    divisionSettings,
    employeeTypeSettings,
    locationSettings,
    serviceCenterSettings,
    loading,
    timesheetAccess: data?.project?.timesheetAccess,
    groupsOrder
  };
};
