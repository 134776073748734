import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const sum = (billCurrencyUri, amountFieldKey) => (retVal, item) =>
  item &&
  item[amountFieldKey] &&
  item[amountFieldKey].currency &&
  item[amountFieldKey].currency.id === billCurrencyUri
    ? retVal + item[amountFieldKey].amount
    : retVal;

export const useBillTotalProps = ({
  standardLineItems = [],
  adhocLineItems = [],
  values,
  associateCreditTotal,
  taxTotal
}) => {
  const { billCurrency } = values;
  const { id: billCurrencyUri } = billCurrency;

  let standardLineItemsTotal = 0;
  let adhocLineItemsTotal = 0;

  standardLineItemsTotal = getRoundedValue(
    standardLineItems.reduce(sum(billCurrencyUri, 'amount'), 0)
  );
  adhocLineItemsTotal = getRoundedValue(
    adhocLineItems.reduce(sum(billCurrencyUri, 'amount'), 0)
  );

  return {
    standardLineItemsTotal: {
      amount: standardLineItemsTotal,
      currency: billCurrency
    },
    adhocLineItemsTotal: {
      amount: adhocLineItemsTotal,
      currency: billCurrency
    },
    billBalanceTotal: {
      amount: getRoundedValue(
        standardLineItemsTotal +
          adhocLineItemsTotal +
          taxTotal -
          getRoundedValue(associateCreditTotal)
      ),
      currency: billCurrency
    }
  };
};

export default useBillTotalProps;
