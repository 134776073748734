import React, { ComponentType, Consumer, Provider, useContext } from 'react';
import isEqual from 'react-fast-compare';

/*
 * Similar to pure from recompose, but performs deep prop comparison instead
 */
export const deepPure = (WrappedComponent: ComponentType) =>
  class extends React.Component {
    shouldComponentUpdate(nextProps: any) {
      return !isEqual(this.props, nextProps);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

const withContextProvider: <T>(options: {
  ContextProvider: Provider<T>;
  propName: string;
}) => (WrappedComponent: ComponentType) => ComponentType = ({
  ContextProvider,
  propName
}: any) => (WrappedComponent: ComponentType) => (props: any) => (
  /* eslint-disable react/destructuring-assignment */
  <ContextProvider value={props[propName]}>
    <WrappedComponent {...props} />
  </ContextProvider>
  /* eslint-enable react/destructuring-assignment */
);

const withContextConsumer: <T>(options: {
  ContextConsumer: Consumer<T>;
  propName: string;
}) => (WrappedComponent: any) => any = ({
  ContextConsumer,
  propName
}) => WrappedComponent =>
  React.forwardRef((props, ref) => (
    <ContextConsumer>
      {value => (
        <WrappedComponent ref={ref} {...props} {...{ [propName]: value }} />
      )}
    </ContextConsumer>
  ));

/*
 * Used to pass down a prop with a given name using react context
 */
export const createPropPassingContext = (
  propName: string,
  defaultValue: any = {}
) => {
  const context = React.createContext(defaultValue);

  return [
    withContextProvider({
      ContextProvider: context.Provider,
      propName
    }),
    withContextConsumer({
      ContextConsumer: context.Consumer,
      propName
    }),
    () => useContext(context),
    context
  ];
};
