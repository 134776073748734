import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ResourceAllocationStatus } from '~/types';

const getLabel = status => {
  switch (status) {
    case ResourceAllocationStatus.Draft:
      return 'pending';
    case ResourceAllocationStatus.Proposed:
      return 'proposed';
    case ResourceAllocationStatus.Committed:
      return 'complete';
    case ResourceAllocationStatus.Rejected:
      return 'reject';
    default:
      return '';
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 'inherit',
    height: theme.spacing(2)
  },
  rootDraft: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.pending.main
    ),
    backgroundColor: theme.palette.resourceRequest.pending.main
  },
  rootProposed: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.proposed.main
    ),
    backgroundColor: theme.palette.resourceRequest.proposed.main
  },
  rootComplete: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.complete.main
    ),
    backgroundColor: theme.palette.resourceRequest.complete.main
  },
  rootResourceRejected: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.resourceRejected.main
    ),
    backgroundColor: theme.palette.resourceRequest.resourceRejected.main
  }
}));

export const ResourceAllocationRequestStatusBadge = ({ status }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const requestStatus = `resourceRequestItemStatusField.${getLabel(status)}`;

  const chipClasses = useMemo(
    () => ({
      root: classNames(classes.root, {
        [classes.rootDraft]: status === ResourceAllocationStatus.Draft,
        [classes.rootComplete]: status === ResourceAllocationStatus.Committed,
        [classes.rootProposed]: status === ResourceAllocationStatus.Proposed,
        [classes.rootResourceRejected]:
          status === ResourceAllocationStatus.Rejected
      })
    }),
    [
      classes.root,
      classes.rootComplete,
      classes.rootDraft,
      classes.rootProposed,
      classes.rootResourceRejected,
      status
    ]
  );

  return (
    <Chip label={formatMessage({ id: requestStatus })} classes={chipClasses} />
  );
};

ResourceAllocationRequestStatusBadge.propTypes = {
  status: PropTypes.string.isRequired
};

export default ResourceAllocationRequestStatusBadge;
