import React from 'react';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';

export const isDynamic = pathSegment => pathSegment.startsWith(':');

/**
 * Ex:
 * dynamicLink = '/project/:id/overview'
 * matchParams = {id: 'administration' }
 * output => '/project/administration/overview'
 */
export const buildLinkPath = ({ dynamicPath, match }) =>
  dynamicPath.replace(match.path, match.url);

// eslint-disable-next-line react/prop-types
export const makeTabLink = match => ({ path, title, dataQeId }) => (
  <Tab
    key={path}
    label={title()}
    component={Link}
    to={buildLinkPath({
      dynamicPath: path,
      match
    })}
    data-qe-id={dataQeId && `${dataQeId}_Tab`}
  />
);

export const findTabValue = ({ tabRoutes, currentPath, match }) => {
  const tabIndex = tabRoutes.findIndex(route => {
    const linkPath = buildLinkPath({ dynamicPath: route.path, match });

    return route.exact === false
      ? currentPath.includes(linkPath)
      : linkPath === currentPath;
  });

  return tabIndex === -1 ? 0 : tabIndex; // Default tab to render is 0
};
