import { useCallback } from 'react';
import { DateTime } from 'luxon';

export const useScheduleEntryChangeHandler = ({
  setFieldValue,
  billingRateIndex,
  scheduleEntryIndex
}) => {
  const onEffectiveDateChange = useCallback(
    value => {
      setFieldValue(
        `billingRates[${billingRateIndex}].billingRate.billingScheduleEntries[${scheduleEntryIndex}].effectiveDate`,
        DateTime.fromObject(value)
      );
      setFieldValue(`billingRates[${billingRateIndex}].dirty`, true);
    },
    [setFieldValue, billingRateIndex, scheduleEntryIndex]
  );
  const onCurrencyChange = useCallback(
    value => {
      setFieldValue(
        `billingRates[${billingRateIndex}].billingRate.billingScheduleEntries[${scheduleEntryIndex}].rate.currency`,
        value
      );
      setFieldValue(`billingRates[${billingRateIndex}].dirty`, true);
    },
    [setFieldValue, billingRateIndex, scheduleEntryIndex]
  );
  const onAmountChange = useCallback(
    ({ target: { value } }) => {
      setFieldValue(
        `billingRates[${billingRateIndex}].billingRate.billingScheduleEntries[${scheduleEntryIndex}].rate.amount`,
        value
      );
      setFieldValue(`billingRates[${billingRateIndex}].dirty`, true);
    },
    [setFieldValue, billingRateIndex, scheduleEntryIndex]
  );

  return { onEffectiveDateChange, onAmountChange, onCurrencyChange };
};
export default useScheduleEntryChangeHandler;
