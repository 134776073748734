import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TimeOffBookingIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 15.49609375 2.234375 C 14.17720985809948 2.229396668984057 12.70159592921956 2.985177521825591 11.25 5 C 5.35358474019326 -0.5519673461046422 1.717501291573857 8 2 9 C 2.813662200600146 8.282197259703132 6.145556016875462 4.843639098961667 10 5.626953125 C 6 5.999744599387526 4 9.447903858951033 6 13 C 6.256322581105063 10.35518321071477 7.251009668695714 8.073655574634873 10.697265625 7 C 10.8666751293508 11.19071580517166 12.10840541313986 14.90993475047949 13.75 17.12109375 A 11 4.5 0 0 0 10.0625 20.0625 L 10 20 L 9 21 L 8 20 L 7 21 L 6 20 L 5 21 L 4 20 L 3 21 L 2 20 L 2 22 L 10.642578125 22 L 18 22 L 22 22 L 22 16.021484375 A 11 4.5 0 0 0 21 16 A 11 4.5 0 0 0 17.44140625 16.248046875 C 15.0436602108414 13.5922958993057 12.64754146908363 10.93521586913116 12 7 C 14.72735068598197 7.238904208998355 17 10 18 13 C 18.70428086503867 11.00000000000003 18.6945728311102 4.637526261797572 12.5 5.5 C 15.21553762073761 3.43113624070268 19 5.281611158000464 20 8 C 20.54056513448542 5.937499999999999 18.39763831218115 2.245327328235076 15.49609375 2.234375 z " />
    </>
  </SvgIcon>
));

export default TimeOffBookingIcon;
