import { ScoreComputeStatus, SortDirection } from '~/types';

export const getDefaultSort = (
  scoreComputeStatus,
  isAvailabilityEnabled = true
) => {
  const computeStatusCheck =
    scoreComputeStatus === ScoreComputeStatus.Completed;

  return [
    computeStatusCheck && {
      field: 'score',
      direction: SortDirection.Desc
    },
    isAvailabilityEnabled && {
      field: 'availabilityPercentage',
      direction: SortDirection.Desc
    },
    {
      field: 'displayText',
      direction: SortDirection.Asc
    }
  ].filter(Boolean);
};

export default getDefaultSort;
