import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { mapRepliconDateToMidnightUTCString } from '~/modules/common/dates/convert';
import { getScheduleRulesFromAllocationPeriods } from '~/modules/common/hooks/resourcing';
import {
  useUpdateTaskResourceUserAllocation,
  useCreateTaskResourceUserAllocation
} from '../../../common/hooks';
import {
  buildTaskAllocationPeriodsFromDates,
  omitForbiddenFields
} from '../../../common/hooks/taskAllocationSaveUtil';

export const getTaskAllocationScheduleRules = ({
  resourceAllocationScheduleRules = [],
  otherTaskAllocationsSummaryScheduleRules = [],
  values
}) => {
  const { startDate, endDate, projectAvailableHours, allocatedHours } = values;

  return getScheduleRulesFromAllocationPeriods(
    buildTaskAllocationPeriodsFromDates({
      startDate,
      endDate,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      // Main.min handles edge case scenario where projectAvailableHours < allocatedHours due to rounding differences
      // Example Scenario: Available = 199.995 rounded to 200.00, and user allocation is 200 yet user availability is only 199.995
      availabilityPercentage:
        Math.min(allocatedHours, projectAvailableHours) / projectAvailableHours
    })
  );
};

export const onUpdate = ({
  setFieldValue,
  resourceEstimates,
  resourceEstimateId,
  taskAllocationId,
  projectId,
  roleId,
  scheduleRules,
  taskId,
  hours,
  startDate,
  endDate
}) => () => {
  setFieldValue(
    'resourceEstimates',
    resourceEstimates.map(estimate =>
      estimate.id === resourceEstimateId
        ? {
            ...estimate,
            taskResourceUserAllocation: {
              id: taskAllocationId,
              startDate: mapRepliconDateToMidnightUTCString(startDate),
              endDate: mapRepliconDateToMidnightUTCString(endDate),
              projectUri: projectId,
              roleUri: roleId,
              scheduleRules,
              taskUri: taskId,
              totalHours: hours
            }
          }
        : estimate
    )
  );
};

export const useOnSubmit = ({ setFieldValue, resourceEstimates }) => {
  const createTaskResourceUserAllocation = useCreateTaskResourceUserAllocation();
  const updateTaskResourceUserAllocation = useUpdateTaskResourceUserAllocation();

  return useCallback(
    async ({
      setSubmitting,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      projectId,
      taskId,
      userId,
      tenantSlug,
      roleId,
      values,
      resourceEstimateId,
      taskResourceUserAllocation
    }) => {
      setSubmitting(true);

      const {
        startDate,
        endDate,
        allocatedHours,
        projectAvailableHours
      } = values;

      const taskAllocationId =
        taskResourceUserAllocation?.id ||
        `urn:replicon-tenant:${tenantSlug}:psa-task-allocation:${uuidv4()}`;

      const scheduleRules = getTaskAllocationScheduleRules({
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules:
          otherTaskAllocationsSummaryScheduleRules || [],
        values
      });

      const onTaskAllocationUpdate = onUpdate({
        setFieldValue,
        resourceEstimates,
        resourceEstimateId,
        taskAllocationId,
        projectId,
        roleId,
        scheduleRules,
        taskId,
        hours: Math.min(projectAvailableHours, allocatedHours),
        startDate,
        endDate
      });

      await (taskResourceUserAllocation
        ? updateTaskResourceUserAllocation({
            variables: {
              input: omitForbiddenFields({
                taskAllocationId,
                taskUri: taskId,
                projectUri: projectId,
                scheduleRules,
                roleUri: roleId
              })
            },
            update: onTaskAllocationUpdate
          })
        : createTaskResourceUserAllocation({
            variables: {
              input: omitForbiddenFields({
                taskAllocationId,
                taskUri: taskId,
                projectUri: projectId,
                allocationUserUri: userId,
                scheduleRules,
                roleUri: roleId
              })
            },
            update: onTaskAllocationUpdate
          }));

      setSubmitting(false);
    },
    [
      createTaskResourceUserAllocation,
      resourceEstimates,
      setFieldValue,
      updateTaskResourceUserAllocation
    ]
  );
};

export default useOnSubmit;
