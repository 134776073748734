import { DateTime } from 'luxon';

export const HOURS_TYPE_ENUM = {
  ESTIMATED: 'ESTIMATED',
  ACTUALS: 'ACTUALS'
};

export const computeChartData = (hourSeriesData, type) =>
  hourSeriesData.map(
    ({ period: { periodStart }, timeEnteredEstimated, timeEnteredActual }) => {
      const hoursValue =
        type === HOURS_TYPE_ENUM.ESTIMATED
          ? timeEnteredEstimated
          : timeEnteredActual;

      const { month } = DateTime.fromISO(periodStart, { zone: 'utc' });

      return {
        month,
        value: hoursValue,
        label: `${hoursValue}`
      };
    }
  );

export const useHourChartData = ({ hourSeriesData }) => ({
  estimatedDataPoints: computeChartData(
    hourSeriesData,
    HOURS_TYPE_ENUM.ESTIMATED
  ),
  actualDataPoints: computeChartData(hourSeriesData, HOURS_TYPE_ENUM.ACTUALS)
});

export default useHourChartData;
