import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

const FormikTextField = ({ label, variant, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      variant={variant}
      helperText={meta.error}
      error={Boolean(meta.touched && meta.error)}
    />
  );
};

FormikTextField.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string
};

export default FormikTextField;
