import { gql } from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { ResourceRequestStatus } from '~/types';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';
import { mapToServiceRequest } from '../../util';

export const ALLOCATION_REJECTED_RESOURCE_REQUEST = gql`
  mutation MarkResourceRequestAsAllocationRejected(
    $input: MarkResourceRequestAsAllocationRejectedInput!
  ) {
    markResourceRequestAsAllocationRejected2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
`;

export const invokeMarkResourceRequestAsAllocationRejected = markResourceRequestAsAllocationRejected => (
  request,
  chartSelectedTab,
  requestAccessLevel
) =>
  markResourceRequestAsAllocationRejected(
    mapToServiceRequest({
      request,
      responseType: 'markResourceRequestAsAllocationRejected2',
      responseTypeName: 'MarkResourceRequestAsAllocationRejectedResult',
      requestStatus: ResourceRequestStatus.Allocationrejected,
      chartSelectedTab,
      requestAccessLevel
    })
  );

export default graphql(ALLOCATION_REJECTED_RESOURCE_REQUEST, {
  name: 'markResourceRequestAsAllocationRejected'
});
