import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import UserWithRoleSchedules from '~/modules/common/components/User/UserWithRoleSchedules';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    alignSelf: 'stretch',
    padding: theme.spacing(0.5, 1, 0.5, 1),
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  }
}));

export const ResourceItem = ({ classes: classesOverride, resource }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ButtonBase
      className={classes.root}
      component={Link}
      to={`/resource-pool/${resource.slug}`}
    >
      <UserWithRoleSchedules user={resource} showTooltip />
    </ButtonBase>
  );
};

ResourceItem.propTypes = {
  classes: PropTypes.object,
  resource: PropTypes.object.isRequired
};

export default memo(ResourceItem);
