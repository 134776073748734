import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import CheckChipDropdown from './CheckChipDropdown';

export const SettingChips = ({ values, setValues, ...rest }) => {
  const onChange = useCallback(
    event => {
      const newValues = event.target.value;

      setValues(
        Array.isArray(newValues)
          ? newValues.length === 0
            ? values
            : newValues
          : [newValues]
      );
    },
    [values, setValues]
  );

  const onRemove = useCallback(
    value => () => {
      setValues(values.filter(v => v !== value));
    },
    [values, setValues]
  );

  return (
    <CheckChipDropdown
      {...rest}
      values={values}
      onChange={onChange}
      onRemove={onRemove}
    />
  );
};

SettingChips.propTypes = {
  values: PropTypes.array,
  setValues: PropTypes.func
};

export default SettingChips;
