import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { patchResourceRequest } from '~/modules/projects/resourcing-plan/enhancers/patchResourceRequest';
import { useUpdateResourceRequest } from '~/modules/resourcing/common/hooks';
import { useGetResourceRequestFilter } from '~/modules/resourcing/common/hooks/useGetResourceRequestFilter';
import ResourceRequestTimelineBlocks from '~/modules/resourcing/common/components/ResourceRequestTimelineBlocks';
import useRequestTimelineEditorChangeHandlers from '~/modules/resourcing/common/hooks/useRequestTimelineEditorChangeHandlers';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useDialogState } from '~/modules/common/hooks';
import useResourceRequestDragIndicators from '../hooks/useResourceRequestDragIndicators';
import ResourceRequestPeriodEditPopover from '../ResourceRequestPeriodEditPopover/ResourceRequestPeriodEditPopover';
import ResourceRequestPeriodDeleteDialog from './ResourceRequestPeriodDeleteDialog';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  }
}));

export const ResourceRequestDrafts2 = ({
  scale,
  chartDisplayDateRange,
  isEditable,
  resourceRequest,
  isResourceActualModeEnabled,
  displayUnit,
  chartDates,
  chartDisplayPeriods
}) => {
  const {
    open: showRemoveRequestDialog,
    openDialog: openRemoveRequestDialog,
    closeDialog: closeRemoveRequestDialog
  } = useDialogState(false);

  const classes = useStyles();

  const [requestPeriodEditTarget, setRequestPeriodEditTarget] = useState(null);
  const apolloClient = useApolloClient();
  const { updateResourceRequest } = useUpdateResourceRequest();
  const { project } = useProjectContext();
  const filter = useGetResourceRequestFilter();
  const chartStartDate = chartDisplayDateRange.startDate;
  const onPeriodClose = useCallback(
    ({ request }) => {
      patchResourceRequest({
        openRemoveRequestDialog,
        updateResourceRequest,
        project,
        request,
        apolloClient,
        filter,
        isResourceActualModeEnabled
      });
    },
    [
      project,
      updateResourceRequest,
      openRemoveRequestDialog,
      apolloClient,
      filter,
      isResourceActualModeEnabled
    ]
  );

  const {
    handleRequestPeriodClick,
    handleRequestPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  } = useRequestTimelineEditorChangeHandlers({
    requestPeriodEditTarget,
    setRequestPeriodEditTarget,
    chartDates,
    isPercentageMode: displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE,
    resourceRequest,
    onPeriodClose
  });

  const dragState = useResourceRequestDragIndicators({
    chartStartDate,
    scale,
    resourceRequest,
    project,
    isResourceActualModeEnabled
  });

  return (
    <div className={`${classes.root} resourceRequestDraft`}>
      <ResourceRequestTimelineBlocks
        resourceRequest={resourceRequest}
        isEditable={isEditable}
        displayUnit={displayUnit}
        handleRequestPeriodClick={handleRequestPeriodClick}
        handleRequestPeriodClose={handleRequestPeriodClose}
        setPreviousPeriod={setPreviousPeriod}
        setNextPeriod={setNextPeriod}
        dragState={dragState}
        chartDisplayDateRange={chartDisplayDateRange}
        scale={scale}
        project={project}
        chartDisplayPeriods={chartDisplayPeriods}
      />

      {requestPeriodEditTarget && requestPeriodEditTarget.anchorEl && (
        <ResourceRequestPeriodEditPopover
          scale={scale}
          id="period-editor"
          anchorEl={requestPeriodEditTarget.anchorEl}
          resourceRequest={resourceRequest}
          periodDetails={requestPeriodEditTarget}
          project={project}
          allocatedHours={requestPeriodEditTarget.totalHours}
          requestedHours={
            requestPeriodEditTarget.totalProjectDefaultScheduledHours
          }
          handleRequestPeriodClose={handleRequestPeriodClose}
          setNextPeriod={setNextPeriod}
          setPreviousPeriod={setPreviousPeriod}
        />
      )}
      {showRemoveRequestDialog && (
        <ResourceRequestPeriodDeleteDialog
          resourceRequest={resourceRequest}
          showRemoveRequestDialog={showRemoveRequestDialog}
          closeRemoveRequestDialog={closeRemoveRequestDialog}
          isResourceActualModeEnabled={isResourceActualModeEnabled}
        />
      )}
    </div>
  );
};

ResourceRequestDrafts2.propTypes = {
  scale: PropTypes.string.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  isResourceActualModeEnabled: PropTypes.bool,
  displayUnit: PropTypes.string,
  chartDates: PropTypes.array,
  chartDisplayPeriods: PropTypes.array
};

export default ResourceRequestDrafts2;
