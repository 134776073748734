import { Grid, makeStyles, Button, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Waypoint } from 'react-waypoint';
import { useMeContext } from '~/modules/me';
import { BillingRateEntryRow } from './components';
import { useBillingRateHandler } from './hooks';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: 0
  },
  item: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  noItemText: {
    fontStyle: 'italic',
    padding: theme.spacing(2)
  },
  showMoreItemContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  addBtnItem: {
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    bottom: 0,
    zIndex: 3
  }
}));

export const BillingRateEditor = ({
  projectId,
  values,
  setFieldValue,
  rateTitle,
  loadMoreRows,
  hasMoreRows,
  billingRateOptions,
  addOrRemoveBillingRate,
  errors,
  isMobile,
  rateType,
  availableBillingRates
}) => {
  const me = useMeContext();
  const classes = useStyles();
  const { billingRates } = values;
  const {
    onDeleteBillingRate,
    onAddBillingRate,
    onConfirmRemoveEntryRow
  } = useBillingRateHandler({
    me,
    projectId,
    rateType,
    setFieldValue,
    billingRates,
    availableBillingRates
  });
  const titleValues = useMemo(() => ({ rateTitle }), [rateTitle]);

  return (
    <Grid container spacing={0} className={classes.container}>
      {billingRates.length ? (
        <>
          {billingRates.map((br, index) => {
            return (
              <Grid item xs={12} key={br.id} className={classes.item}>
                <BillingRateEntryRow
                  id={br.id}
                  billingRate={br.billingRate}
                  setFieldValue={setFieldValue}
                  billingRateIndex={index}
                  onDeleteBillingRate={onDeleteBillingRate}
                  onConfirmRemoveEntryRow={onConfirmRemoveEntryRow}
                  dropdownOptions={billingRateOptions}
                  addOrRemoveBillingRate={addOrRemoveBillingRate}
                  errors={errors}
                  isMobile={isMobile}
                />
              </Grid>
            );
          })}
          {hasMoreRows && loadMoreRows ? (
            <Waypoint
              fireOnRapidScroll
              bottomOffset="-20%"
              onEnter={loadMoreRows}
              key={`editable-${rateType}-${billingRates.length}`}
            />
          ) : null}
        </>
      ) : (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.noItemText}
          >
            <FormattedMessage
              id="billingRateCard.noItem"
              values={titleValues}
            />
          </Typography>
        </Grid>
      )}
      {addOrRemoveBillingRate ? (
        <Grid item xs={12} className={classes.addBtnItem}>
          <Button color="primary" onClick={onAddBillingRate}>
            <FormattedMessage id="billingRateCard.addRate" />
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

BillingRateEditor.propTypes = {
  projectId: PropTypes.string,
  rateType: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  rateTitle: PropTypes.string,
  loadMoreRows: PropTypes.func,
  hasMoreRows: PropTypes.bool,
  addOrRemoveBillingRate: PropTypes.bool,
  billingRateOptions: PropTypes.array,
  errors: PropTypes.object,
  isMobile: PropTypes.bool,
  availableBillingRates: PropTypes.object
};

export default BillingRateEditor;
