import { gql } from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import get from 'lodash.get';
import { PROJECT_PROGRESS_TYPE_ENUM } from '~/modules/projects/project/common/enums/projectProgressType';

export const GET_PROJECT_PROGRESS_CARD_SETTINGS = gql`
  query GetProjectProgressCardSettings($storeId: String!) {
    searchSettings(key: $storeId) {
      selectedView: criterions
    }
  }
`;

export const PUT_GET_PROJECT_PROGRESS_CARD_SETTINGS = gql`
  mutation PutProjectProgressCardSettings($input: PutSearchSettingsInput) {
    putSearchSettings(input: $input) {
      selectedView: criterions
    }
  }
`;

const storeId = 'project-progress-card-settings';

export const onCompleted = setProjectProgressCardSettingInternal => ({
  searchSettings
}) => {
  if (Object.keys(searchSettings.selectedView).length !== 0)
    setProjectProgressCardSettingInternal(searchSettings.selectedView);
};

export const useProjectProgressCardSettingsStore = ({ showCostContent }) => {
  const [
    _projectProgressCardSetting,
    setProjectProgressCardSettingInternal
  ] = useState({
    selectedView: PROJECT_PROGRESS_TYPE_ENUM.HOUR
  });
  const { data, loading } = useQuery(GET_PROJECT_PROGRESS_CARD_SETTINGS, {
    variables: { storeId },
    onCompleted: onCompleted(setProjectProgressCardSettingInternal),
    fetchPolicy: 'network-only',
    skip: !showCostContent
  });

  const [putProjectProgressCardSettings] = useMutation(
    PUT_GET_PROJECT_PROGRESS_CARD_SETTINGS
  );

  const projectProgressCardSetting = get(
    data,
    'searchSettings?.selectedView',
    _projectProgressCardSetting
  );

  const saveProjectProgressCardSetting = settings => {
    setProjectProgressCardSettingInternal({
      selectedView: settings
    });

    putProjectProgressCardSettings({
      variables: {
        input: {
          key: storeId,
          settings: {
            criterions: {
              selectedView: settings
            }
          }
        }
      }
    });
  };

  return {
    loading,
    projectProgressCardSetting,
    saveProjectProgressCardSetting
  };
};

export default useProjectProgressCardSettingsStore;
