import { DateTime } from 'luxon';
import { v4 } from 'uuid';

const dayOfWeekParameterValueMap = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  7: 'SUNDAY'
};

const frequencyFormatter = {
  Daily: date => ({
    frequency: 'Daily'
  }),
  Weekly: date => ({
    frequency: 'Weekly',
    dayOfWeek: date
      ? dayOfWeekParameterValueMap[
          DateTime.local(date.year, date.month, date.day).weekday
        ]
      : null
  }),
  'Bi-Weekly': date => ({
    frequency: 'Bi-Weekly',
    dayOfWeek: date
      ? dayOfWeekParameterValueMap[
          DateTime.local(date.year, date.month, date.day).weekday
        ]
      : null
  }),
  'Semi-Monthly': date => ({
    frequency: 'Semi-Monthly',
    dayOfMonth: date ? date.day : null
  }),
  Monthly: date => ({
    frequency: 'Monthly',
    dayOfMonth: date ? date.day : null
  }),
  Quarterly: date => ({
    frequency: 'Quarterly',
    month: date ? date.month : null,
    dayOfMonth: date ? date.day : null
  }),
  Yearly: date => ({
    frequency: 'Yearly',
    month: date ? date.month : null,
    dayOfMonth: date ? date.day : null
  })
};

export const amountFormatter = (value, me) => ({
  currency: me.baseCurrency,
  amount: value.number
});

export const parameterDefaultValueFormatterByKeyUri = {
  'urn:replicon:script-key:parameter:frequency': value => 'Daily'
};

export const parameterDefaultValueFormatterByType = {
  money: (value, me) => amountFormatter(value, me),
  'read-only-amount': (value, me) => amountFormatter(value, me),
  text: value => value.text || '',
  integer: value => value.number || 0,
  date: value => value.date || {},
  numeric: value => value.number || 0,
  boolean: value => value.bool || false,
  'drop-down': value => value.text || '',
  'date-range': value => {}
};

export const defaultScriptsFormatter = plan => {
  const newScripts = plan.scripts.map(script => {
    const fKey = 'urn:replicon:script-key:parameter:frequency';
    const frequency = script[fKey];

    if (frequency) {
      const rdKey = 'urn:replicon:script-key:parameter:relative-date';
      const relativeDate = script[rdKey];

      return {
        ...script,
        key: script.key || v4(),
        'urn:replicon:script-key:parameter:frequency-formatted': frequencyFormatter[
          frequency
        ](relativeDate)
      };
    }

    return script;
  });

  return { ...plan, scripts: newScripts };
};
