import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import DrawerTitle from './DrawerTitle';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    },
    borderBottom: [[1, 'solid', theme.palette.grey[100]]],
    '&:last-child': {
      borderBottom: 'none'
    },
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    })
  },
  rootDirty: {
    backgroundColor: theme.palette.grey[50]
  },
  content: {
    margin: 0,
    padding: 0
  }
}));

export const DrawerSection = ({ dirty, title, children }) => {
  const classes = useStyles();

  return (
    <li className={classnames(classes.root, { [classes.rootDirty]: dirty })}>
      {title && <DrawerTitle title={title} />}
      <div className={classes.content}>{children}</div>
    </li>
  );
};

DrawerSection.propTypes = {
  dirty: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node
};

DrawerSection.defaultProps = {
  dirty: false
};

export default DrawerSection;
