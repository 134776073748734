import { getScheduleTotals } from '~/modules/resourcing/common/util/scheduleUtil';
import { ResourceCostModeType } from '~/types';

export const getResourceAllocationTotal = ({
  scheduleRules,
  user,
  exchangeRateValues,
  roleCostExchangeRateValues,
  contextCurrency,
  me
}) => {
  const isRoleBasedCost =
    me?.resourceCostMode === ResourceCostModeType.Rolecost;
  const costRate =
    isRoleBasedCost && user.primaryRoleCostRate
      ? user.primaryRoleCostRate
      : user.costRate
      ? user.costRate
      : 0;

  const contextExchangeRateValues = isRoleBasedCost
    ? roleCostExchangeRateValues
    : exchangeRateValues;

  const exchangeRate =
    contextCurrency && contextExchangeRateValues
      ? Object.values(contextExchangeRateValues)
          .filter(e => e)
          .find(r => r.currencyId === contextCurrency.id)
      : null;

  const conversionRate = exchangeRate ? exchangeRate.rate || 1 : 1;
  const costCurrency =
    exchangeRate && exchangeRate.rate
      ? contextCurrency
      : isRoleBasedCost && user.primaryRoleCostCurrency
      ? user.primaryRoleCostCurrency
      : user.costCurrency
      ? user.costCurrency
      : null;

  const { totalHours, totalCost } = getScheduleTotals({
    scheduleRules,
    quantity: 1,
    currencyUri: costCurrency && costCurrency.id,
    rate: conversionRate * costRate,
    currencySymbol:
      (costCurrency && costCurrency.symbol) ||
      (costCurrency && costCurrency.displayText)
  });

  return {
    resourceAllocationHours: Number((totalHours || 0).toFixed(4)),
    resourceAllocationCost: totalCost
  };
};

export default getResourceAllocationTotal;
