/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import LabelledIcon from '~/modules/common/components/LabelledIcon';
import TypeIcon from '../Icons/TypeIcon';
import { getTypeLabel } from './formatter-util';

const useStyles = makeStyles(theme => ({
  container: { paddingTop: 0, paddingBottom: 0 }
}));

export const TypeColumn = ({ field, record }) => {
  const classes = useStyles();
  const intl = useIntl();
  const type =
    record[field] === 'PAYMENT' &&
    record.balanceWithoutAllocationsAmount.amount > 0
      ? 'REFUND'
      : record[field];

  return (
    <LabelledIcon
      classes={useMemo(() => ({ container: classes.container }), [
        classes.container
      ])}
      icon={<TypeIcon id={type} />}
      label={intl.formatMessage({
        id: getTypeLabel({ type })
      })}
    />
  );
};

TypeColumn.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};
export default TypeColumn;
