import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Decimal from '../Decimal';
import ReadOnlyContainer from '../ReadOnlyContainer';

const ReadOnlyHours = ({
  precision,
  dataQeId,
  value,
  label,
  align = 'right',
  opacity = 1,
  ...rest
}) => (
  <ReadOnlyContainer label={label} opacity={opacity} {...rest}>
    <Tooltip
      title={value}
      placement={align === 'right' ? 'bottom-end' : 'bottom-start'}
    >
      <Decimal
        dataQeId={dataQeId}
        style={{ textAlign: align, display: 'block' }}
        value={value}
        precision={precision}
      />
    </Tooltip>
  </ReadOnlyContainer>
);

ReadOnlyHours.propTypes = {
  precision: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.node,
  align: PropTypes.string,
  dataQeId: PropTypes.string,
  opacity: PropTypes.number
};

export default memo(ReadOnlyHours);
