import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const PERIOD_HEIGHT = 24;
const PERIOD_GAP = 4;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: theme.spacing(2.5),
    zIndex: 3,
    pointerEvents: 'none'
  },
  summaryBlockContainer: {
    borderRight: `1px solid ${theme.palette.grey[500]}`
  },
  container: {
    height: theme.spacing(3),
    userSelect: 'none',
    zIndex: 2,
    flexShrink: 0,
    borderRadius: theme.spacing(0.25),
    borderTop: `2px dashed ${theme.palette.grey[700]}`,
    borderBottom: `2px dashed ${theme.palette.grey[700]}`
  },
  leftBorder: {
    borderLeft: `2px dashed ${theme.palette.grey[700]}`
  },
  rightBorder: {
    borderRight: `2px dashed ${theme.palette.grey[700]}`
  }
}));
const ResourceRequestTimelineBlockReadOnly = ({
  rowIndex,
  resourceRequest,
  periodBlockClasses,
  requestPeriod
}) => {
  const { dynamicPosition, hasRequestEnd, hasRequestStart } = requestPeriod;
  const classes = useStyles({
    quantity: resourceRequest.quantity
  });

  const periodBlockDynamicPosition = useMemo(
    () => ({
      left: dynamicPosition.left,
      width: dynamicPosition.width,
      top:
        rowIndex === 0
          ? dynamicPosition.top
          : dynamicPosition.top +
            PERIOD_HEIGHT * rowIndex +
            (PERIOD_GAP * rowIndex - 1)
    }),
    [dynamicPosition, rowIndex]
  );

  return (
    <div className={classes.root} style={periodBlockDynamicPosition}>
      <div
        className={classNames(classes.container, {
          [classes.summaryBlockContainer]: !hasRequestEnd,
          [classes.leftBorder]: hasRequestStart,
          [classes.rightBorder]: hasRequestEnd
        })}
        aria-hidden="true"
      >
        <div className={periodBlockClasses.container} />
      </div>
    </div>
  );
};

ResourceRequestTimelineBlockReadOnly.propTypes = {
  rowIndex: PropTypes.number,
  resourceRequest: PropTypes.object,
  periodBlockClasses: PropTypes.object,
  requestPeriod: PropTypes.object.isRequired
};

export default ResourceRequestTimelineBlockReadOnly;
