import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { clientTag } from '~/modules/common/components/SearchBox/Facets/ClientFacet/tag';
import { syncStatusTag } from '~/modules/common/components/SearchBox/Facets/SyncStatusFacet/tag';
import { billingTransactionTypeTag } from '~/modules/common/components/SearchBox/Facets/BillingTransactionTypeFacet/tag';
import { dateTag } from '~/modules/common/components/SearchBox/Facets/DateRangeFacet/tag';
import { getTodayForUser } from '~/modules/common/dates/today';
import { BillStatus, BillingTransactionBalanceStatus } from '~/types';
import { billStatusTag } from '~/modules/common/components/SearchBox/Facets/BillStatusFacet/tag';
import { billingTransactionTag } from '~/modules/common/components/SearchBox/Facets/BillingTransactionFacet/tag';
import {
  checkForCriteria,
  getForCriteria,
  getDateForCriteria
} from '~/modules/common/util';
import { INVOICE_DRAFT_STATUS_POLICY } from '~/modules/billing-invoicing/common/const';

export const getSyncStatusTagValuesForCriteria = (criterions, tag) =>
  checkForCriteria(criterions, tag)
    ? criterions[tag].reduce(
        (result, item) => ({
          ...result,
          [item.tagKeyUri]: [...(result[item.tagKeyUri] || []), item.value]
        }),
        {}
      )
    : null;

const allTags = [
  clientTag,
  billStatusTag,
  syncStatusTag,
  billingTransactionTypeTag,
  dateTag,
  billingTransactionTag
];

export const getDueDate = (key, today) => {
  switch (key) {
    case 'uptoThirtyDays':
      return {
        startDate: today.minus({ day: 30 }),
        endDate: today.minus({ day: 1 })
      };
    case 'thirtyToSixtyDays':
      return {
        startDate: today.minus({ day: 60 }),
        endDate: today.minus({ day: 31 })
      };
    case 'sixtyToNinetyDays':
      return {
        startDate: today.minus({ day: 90 }),
        endDate: today.minus({ day: 61 })
      };
    case 'ninetyToOneTwentyDays':
      return {
        startDate: today.minus({ day: 120 }),
        endDate: today.minus({ day: 91 })
      };
    case 'greaterThanOneTwentyDays':
      return {
        startDate: null,
        endDate: today.minus({ day: 121 })
      };

    default:
      return null;
  }
};

export const mapBillingTransactionSearchFilter = ({
  searchCriteria: { keywords, criterions } = {}
}) =>
  (keywords && keywords.length > 0) ||
  allTags.some(tag => checkForCriteria(criterions, tag))
    ? {
        billingTransactionFilter: {
          searchTerm:
            keywords && keywords.length > 0 ? keywords.join(' ') : null,
          clients: getForCriteria(criterions, clientTag),
          billStatusTag: getForCriteria(criterions, billStatusTag),
          tags: [
            getSyncStatusTagValuesForCriteria(criterions, syncStatusTag)
          ].filter(x => x),
          dateRange: getDateForCriteria(criterions, dateTag),
          transactionTypes: getForCriteria(
            criterions,
            billingTransactionTypeTag
          )
        }
      }
    : {};

const getTransactionType = key => {
  switch (key) {
    case 'outstanding':
    case 'overdue':
    case 'draft':
    case 'void':
    case 'issued':
      return ['BILL'];
    case 'unallocated':
      return ['PAYMENT', 'CREDITMEMO'];
    default:
      return [];
  }
};

const getBillStatus = key => {
  switch (key) {
    case 'outstanding':
    case 'overdue':
      return [BillStatus.IssuedPartiallypaid, BillStatus.IssuedUnpaid];
    case 'draft':
      return [BillStatus.Draft];
    case 'void':
      return [BillStatus.Void];
    case 'issued':
      return [
        BillStatus.IssuedPaid,
        BillStatus.IssuedUnpaid,
        BillStatus.IssuedPartiallypaid
      ];
    default:
      return [];
  }
};

const getConsolidatedTypeFilter = (quickFilterKey, criterions) =>
  (getForCriteria(criterions, billingTransactionTypeTag) || []).filter(
    type => getTransactionType(quickFilterKey).indexOf(type) !== -1
  );

export const useFilterBasedOnSearchCriteria = ({
  searchCriteria: { keywords, criterions },
  quickFilter: { filterByStatus },
  me
}) =>
  useMemo(() => {
    if (
      !(
        (keywords && keywords.length > 0) ||
        allTags.some(tag => checkForCriteria(criterions, tag))
      )
    ) {
      if (filterByStatus) {
        const { day, month, year } = getTodayForUser(me);

        const today = DateTime.utc(year, month, day);

        return {
          billingTransactionFilter: {
            transactionTypes: getTransactionType(filterByStatus),
            balanceStatusList:
              filterByStatus !== 'draft' &&
              filterByStatus !== 'void' &&
              filterByStatus !== 'issued'
                ? [
                    BillingTransactionBalanceStatus.Unallocated,
                    BillingTransactionBalanceStatus.PartiallyAllocated
                  ]
                : null,
            isOverdue: filterByStatus === 'overdue' ? true : undefined,
            dueDate: getDueDate(filterByStatus, today),
            billStatus: getBillStatus(filterByStatus)
          }
        };
      }

      return {};
    }

    const { day, month, year } = getTodayForUser(me);

    const today = DateTime.utc(year, month, day);

    const transactionType = getForCriteria(
      criterions,
      billingTransactionTypeTag
    );
    const consolidatedTypeFilter =
      transactionType && filterByStatus
        ? getConsolidatedTypeFilter(filterByStatus, criterions)
        : transactionType ||
          (getTransactionType(filterByStatus)?.length > 0
            ? getTransactionType(filterByStatus)
            : null);

    const { isPsaPswatBillingDraftSubStatusEnabled } = me?.featureFlags;

    const statusfilter = getForCriteria(criterions, billStatusTag) || [];

    const billStatusFilter = isPsaPswatBillingDraftSubStatusEnabled
      ? statusfilter.filter(x => Object.values(BillStatus).includes(x))
      : statusfilter;

    const draftStatusFilter = isPsaPswatBillingDraftSubStatusEnabled
      ? statusfilter.filter(x => x.startsWith(INVOICE_DRAFT_STATUS_POLICY))
      : [];

    return {
      billingTransactionFilter: {
        text: keywords && keywords.length > 0 ? keywords.join(' ') : null,
        clients: getForCriteria(criterions, clientTag),
        billingTransactionUris: me?.featureFlags
          ?.isPsaPrpManualBulkBillCreationEnabled
          ? getForCriteria(criterions, billingTransactionTag)
          : undefined,
        tags: [
          getSyncStatusTagValuesForCriteria(criterions, syncStatusTag)
        ].filter(x => x),
        dateRange: getDateForCriteria(criterions, dateTag),
        transactionTypes: consolidatedTypeFilter,
        balanceStatusList:
          filterByStatus &&
          filterByStatus !== 'draft' &&
          filterByStatus !== 'issued'
            ? [
                BillingTransactionBalanceStatus.Unallocated,
                BillingTransactionBalanceStatus.PartiallyAllocated
              ]
            : null,
        isOverdue: filterByStatus === 'overdue' ? true : undefined,
        dueDate: getDueDate(filterByStatus, today),
        billStatus: filterByStatus
          ? getBillStatus(filterByStatus)
          : billStatusFilter,
        draftSubStatusUris: filterByStatus ? [] : draftStatusFilter
      }
    };
  }, [criterions, filterByStatus, keywords, me]);

export const useFilterBasedFetchPolicy = ({ billingTransactionFilter }) =>
  billingTransactionFilter;

export default useFilterBasedOnSearchCriteria;
