import { makeStyles } from '@material-ui/core';
import BillingIcon from '@material-ui/icons/AttachMoneySharp';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import EditableCard, {
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import BillingReadonly from './BillingReadonly';

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    paddingBottom: theme.spacing(3)
  }
}));

const avatar = <BillingIcon />;
const title = <FormattedMessage id="projectBillingCard.title" />;

export const BillingCard = ({
  classes: classesOverride,
  billingDetails,
  isProjectLoading
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <EditableCard
      editable={false}
      dataQeId="Project_Billing"
      ariaLabelKey="billingCard.billingCardDialog"
    >
      <ReadOnly
        classes={classes}
        avatar={avatar}
        title={title}
        isLoading={isProjectLoading}
      >
        <ReadOnlyContent>
          <BillingReadonly {...billingDetails} />
        </ReadOnlyContent>
      </ReadOnly>
    </EditableCard>
  );
};

BillingCard.propTypes = {
  classes: PropTypes.object,
  isProjectLoading: PropTypes.bool,
  billingDetails: PropTypes.object
};

export default BillingCard;
