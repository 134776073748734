import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import EditableCard, {
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import TagsCardContentSkeleton from './TagsCardContentSkeleton';

const TagsCardSkeleton = ({ classes, title, icon, headerDividerVariant }) => {
  const tagSkeletonClasses = useMemo(
    () => ({
      tagSkeletonContainer: classes.tagSkeletonContainer,
      tagSkeletonItem: classes.tagSkeletonItem
    }),
    [classes.tagSkeletonContainer, classes.tagSkeletonItem]
  );
  const isMobile = useIsBreakpointDown('sm');

  return (
    <EditableCard
      classes={useMemo(
        () => ({
          card: classes.card,
          title: classes.title
        }),
        [classes.card, classes.title]
      )}
      maxWidth="sm"
      fullScreen={isMobile}
      dataQeId="Tags"
      ariaLabelKey="tags.tagsCardDialog"
    >
      <ReadOnly
        classes={useMemo(
          () => ({
            headerRoot: classes.readOnlyCardHeader,
            action: classes.action
          }),
          [classes.readOnlyCardHeader, classes.action]
        )}
        avatar={icon}
        title={title}
        dividerVariant={headerDividerVariant}
      >
        <ReadOnlyContent>
          <TagsCardContentSkeleton classes={tagSkeletonClasses} />
        </ReadOnlyContent>
      </ReadOnly>
    </EditableCard>
  );
};

TagsCardSkeleton.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  headerDividerVariant: PropTypes.string
};

export default TagsCardSkeleton;
