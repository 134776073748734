import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    flexShrink: 0,
    position: 'sticky',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  pageSelector: {
    display: 'flex',
    alignSelf: 'center'
  },
  pageList: {
    alignSelf: 'center',
    display: 'inline',
    fontWeight: theme.typography.fontWeightRegular
  },
  pageNumber: {
    lineHeight: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    display: 'inline',
    padding: theme.spacing(0, 0.25)
  }
}));

export const useButtonStyles = makeStyles(theme => ({
  previousButton: {
    margin: theme.spacing(-1, 0, -1, -1.5),
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  nextButton: {
    margin: theme.spacing(-1, 0.5, -1, 0),
    padding: `${theme.spacing(0)}px`,
    borderTopLeftRadius: '25%',
    borderBottomLeftRadius: '25%',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}));

export default useStyles;
