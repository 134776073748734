import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { withRoutes } from '~/modules/common/routes';
import { useMeContext } from '~/modules/me/useMeContext';
import { hasPermission } from '~/modules/common/permissions/useHasPermission';
import { useSessionStorage } from '~/modules/common/hooks';

export const isRouteExists = ({ routes, currentView }) =>
  routes.findIndex(route => currentView.pathname.includes(route.path));

export const RedirectToLandingPage = ({ routes }) => {
  const { userAccessRoles, featureFlags, permissionsMap } = useMeContext();
  const isResourceManager =
    userAccessRoles.includes(USER_ACCESS_ROLE.RESOURCE_MANAGER) &&
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-user',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    });

  const isResourcePoolManager =
    userAccessRoles.includes(USER_ACCESS_ROLE.RESOURCE_POOL_MANAGER) &&
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-user',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isClientManager =
    userAccessRoles.includes(USER_ACCESS_ROLE.CLIENT_MANAGER) &&
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:client-action:view-client'
    });

  const isProgramManager =
    userAccessRoles.includes(USER_ACCESS_ROLE.PROGRAM_MANAGER) &&
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:program-action:view-program'
    });

  const hasRevenueManagerRole = userAccessRoles.includes(
    USER_ACCESS_ROLE.REVENUE_MANAGER
  );

  const isProjectManager =
    userAccessRoles.includes(USER_ACCESS_ROLE.PROJECT_MANAGER) &&
    (!featureFlags?.isTimeCoreSubstituteUserLimitedAccessEnabled ||
      hasPermission(permissionsMap)({
        actionUri: 'urn:replicon:project-action:view-project'
      }));

  const isBillingManager = userAccessRoles.includes(
    USER_ACCESS_ROLE.BILLING_MANAGER
  );

  const {
    storedValue: currentView,
    setValue: setCurrentView
  } = useSessionStorage('current-view', null);

  if (
    currentView &&
    isRouteExists({ routes: Object.values(routes), currentView }) !== -1
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setCurrentView(null);
    });

    return <Redirect to={currentView} />;
  }

  if (isResourceManager || isResourcePoolManager)
    return <Redirect to={routes.resourcePool.path} />;
  if (isClientManager) return <Redirect to={routes.clients.path} />;
  if (isProgramManager) return <Redirect to={routes.programs.path} />;
  if (isProjectManager) return <Redirect to={routes.projects.path} />;
  if (hasRevenueManagerRole || isBillingManager)
    return <Redirect to={routes.billing.path} />;

  const firstVisibleRouteKey = Object.keys(routes).find(
    r => routes[r].isSideBarRoute
  );

  const firstVisibleRoute = routes[firstVisibleRouteKey] || routes.notFound;

  return <Redirect to={firstVisibleRoute.path} />;
};

RedirectToLandingPage.propTypes = {
  routes: PropTypes.object.isRequired
};

export default withRoutes(RedirectToLandingPage);
