import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';
import { useMeContext } from '~/modules/me/useMeContext';

export const dataArguments = `
$includePermittedActionUris: Boolean!
  `;

export const PROJECT_DROPDOWN_QUERY = gql`
  query ProjectDropdownSearch($filter: ProjectFilter, ${dataArguments}, $pagesize: Int, ${`$isPsaPrpPsaPpmMergerEnabled: Boolean = false`}) {
    projects(page: 1, pagesize: $pagesize, projectFilter: $filter) {
      id
      displayText
      permittedActionUris @include(if: $includePermittedActionUris)
      projectTemplateSetting @include(if: $isPsaPrpPsaPpmMergerEnabled) {
        key
        value
      }
    }
  }
`;

export const useProjectDropdownOptions = ({
  searchTerm,
  includePermissions
}) => {
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = me;
  const { data, loading } = useQuery(PROJECT_DROPDOWN_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      includePermittedActionUris: Boolean(includePermissions),
      columns: ['project', 'displayText'],
      filter: {
        text: searchTerm
      },
      pagesize: PAGE_SIZE + 1,
      isPsaPrpPsaPpmMergerEnabled
    },
    context: {
      debounceKey: 'project-search',
      debounceTimeout: 250
    }
  });

  const projects = loading ? [] : get(data, 'projects', []);

  return {
    projects,
    loading,
    hasMore: projects.length > PAGE_SIZE
  };
};

export default useProjectDropdownOptions;
