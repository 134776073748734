import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import formatters from './formatters';

export const buildColumns = ({
  classes,
  dateColumnKey,
  creditColumnKey,
  isMultiCurrency,
  resourceKeys,
  onDelete,
  editable
}) => ({
  deleteIcon: {
    id: 'delete-icon',
    visible: editable,
    className: classes.deleteIcon,
    value: ' ',
    resourceKeys,
    onDelete
  },
  displayText: {
    id: 'bill-credit-reference-number',
    visible: true,
    value: <FormattedMessage id="associatedBillCredits.referenceNumber" />,
    className: classes.displayText
  },
  [dateColumnKey]: {
    id: 'bill-credit-date',
    visible: true,
    value: <FormattedMessage id="associatedBillCredits.date" />,
    className: classes.date
  },
  [creditColumnKey]: {
    id: 'bill-credit-amount',
    visible: isMultiCurrency,
    value: (
      <>
        <FormattedMessage id="associatedBillCredits.amount" />
        <div className={classes.subTitle}>
          <FormattedMessage id={resourceKeys.creditColumnSubHeader} />
        </div>
      </>
    ),
    align: 'right',
    className: classes.creditAmount,
    targetFieldName: 'allocatedBillAmount'
  },
  allocatedBillAmount: {
    id: 'bill-amount',
    visible: true,
    value: <FormattedMessage id="associatedBillCredits.amount" />,
    align: 'right',
    className: classes.amount
  }
});

export const useListColumns = ({
  classes,
  dateColumnKey,
  creditColumnKey,
  isMultiCurrency,
  resourceKeys,
  onDelete,
  editable
}) =>
  useColumns({
    columns: buildColumns({
      classes,
      dateColumnKey,
      creditColumnKey,
      isMultiCurrency,
      resourceKeys,
      onDelete,
      editable
    }),
    renders: formatters
  });
