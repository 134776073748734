import React, { useCallback } from 'react';
import { UserWithRoles } from '~/modules/common/components/User';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { useResourceDropdownGroupBy } from '~/modules/rateCard/hooks/useResourceDropdownGroupBy';

export const useResourceRateDropdownCallbacks = ({
  onUserChange,
  setSearchTerm,
  projectSlug,
  formatMessage
}) => {
  const groupBy = useResourceDropdownGroupBy(projectSlug);

  const handleOnChange = (user, e) => {
    onUserChange(user);
  };
  const optionString = option => (!option.heading && option?.displayText) || '';

  const optionComponent = option => {
    if (option.heading) {
      return '';
    }

    return option.id === MORE_AVAILABLE_OPTION_ID ? (
      <MoreResult message={option.displayText} />
    ) : (
      <UserWithRoles user={option} />
    );
  };

  const handleInputChange = useCallback((_, v) => setSearchTerm(v), [
    setSearchTerm
  ]);

  return {
    handleOnChange,
    optionString,
    groupBy,
    optionComponent,
    handleInputChange
  };
};

export default useResourceRateDropdownCallbacks;
