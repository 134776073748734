import React from 'react';
import PropTypes from 'prop-types';
import DisplayUnitContextProvider from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import {
  ResourceRequestToolbarContext,
  useResourceRequestToolbarContextValue
} from './hooks';

const ResourceRequestToolbarContextProvider = ({
  children,
  project,
  width,
  setSearchCriteria,
  searchCriteria,
  filter,
  sort,
  onSortChange,
  resourceRequestsMinMaxDateAndStatuses,
  resourceAllocationsMinMaxDateAndStatuses,
  handleDisplayUnitChange,
  chartSettings
}) => {
  const contextValues = useResourceRequestToolbarContextValue({
    project,
    width,
    setSearchCriteria,
    searchCriteria,
    filter,
    sort,
    onSortChange,
    resourceRequestsMinMaxDateAndStatuses,
    resourceAllocationsMinMaxDateAndStatuses,
    handleDisplayUnitChange,
    chartSettings
  });

  return (
    <DisplayUnitContextProvider
      defaultDisplayUnit={contextValues.displayUnit}
      onDisplayUnitChange={contextValues.setDisplayUnit}
    >
      <ResourceRequestToolbarContext.Provider value={contextValues}>
        {children}
      </ResourceRequestToolbarContext.Provider>
    </DisplayUnitContextProvider>
  );
};

ResourceRequestToolbarContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  project: PropTypes.object.isRequired,
  width: PropTypes.number,
  setSearchCriteria: PropTypes.func,
  filter: PropTypes.object,
  sort: PropTypes.object,
  onSortChange: PropTypes.func,
  resourceRequestsMinMaxDateAndStatuses: PropTypes.object,
  resourceAllocationsMinMaxDateAndStatuses: PropTypes.object,
  searchCriteria: PropTypes.object,
  handleDisplayUnitChange: PropTypes.func,
  chartSettings: PropTypes.object
};

export default ResourceRequestToolbarContextProvider;
