import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListItemText, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NoDataItem, NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  unAssigned: {
    color: theme.palette.text.secondary
  }
}));

const useListItemTextStyles = makeStyles(theme => ({
  secondary: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.caption.fontSize
  },
  primary: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export const AssignmentInfo = ({
  roleDisplayText,
  estimatedHours,
  isAssignedTask
}) => {
  const classes = useStyles();
  const listItemTextClasses = useListItemTextStyles();

  const noRoleField = useMemo(
    () => (
      <NoDataItem>
        <FormattedMessage id="taskAssignmentDialog.noRole" />
      </NoDataItem>
    ),
    []
  );

  const roleField = useMemo(
    () =>
      roleDisplayText ? (
        <span className={!isAssignedTask ? classes.unAssigned : undefined}>
          {roleDisplayText}
        </span>
      ) : (
        noRoleField
      ),
    [classes.unAssigned, isAssignedTask, noRoleField, roleDisplayText]
  );
  const estimatedHoursField = useMemo(
    () =>
      estimatedHours ? (
        <span className={!isAssignedTask ? classes.unAssigned : undefined}>
          {estimatedHours}
          <FormattedMessage id="taskAssignmentDialog.hoursSuffix" />
        </span>
      ) : (
        <NoValue />
      ),
    [estimatedHours, isAssignedTask, classes.unAssigned]
  );

  if (!roleDisplayText && !estimatedHours) return noRoleField;

  return (
    <ListItemText
      classes={listItemTextClasses}
      primary={roleField}
      secondary={estimatedHoursField}
    />
  );
};

AssignmentInfo.propTypes = {
  roleDisplayText: PropTypes.string,
  estimatedHours: PropTypes.number,
  isAssignedTask: PropTypes.bool
};

export default AssignmentInfo;
