import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogContent,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import { objectHooks } from './hooks';
import dialogContents from './dialogContents';

const useStyles = makeStyles(theme => ({
  successAlert: {
    '& + &': {
      marginTop: theme.spacing(2)
    }
  }
}));

export const BulkDeleteResultDialogContent = ({
  batchId,
  onClose,
  onError,
  objectType
}) => {
  const classes = useStyles();
  const { objects, object } = dialogContents[objectType];
  const { getBatchResults } = objectHooks[objectType];

  const { deleted = [], archived = [], error } = getBatchResults({
    batchId
  });

  useEffect(() => {
    if (error) onError(error);
  }, [error, onError]);

  const deletedCount = deleted.length;
  const archivedCount = archived.length;
  const deletedValues = useMemo(
    () => ({
      value: deletedCount,
      object: <FormattedMessage id={deletedCount > 1 ? objects : object} />
    }),
    [deletedCount, objects, object]
  );

  const archivedValues = useMemo(
    () => ({
      value: archivedCount,
      object: <FormattedMessage id={archivedCount > 1 ? objects : object} />
    }),
    [archivedCount, objects, object]
  );

  return (
    <>
      <DialogContent>
        {deletedCount > 0 ? (
          <Alert
            icon={false}
            severity="success"
            className={classes.successAlert}
          >
            <FormattedMessage
              id="bulkRemoveDialog.success.removed"
              values={deletedValues}
            />
          </Alert>
        ) : null}
        {archivedCount > 0 ? (
          <Alert
            icon={false}
            severity="success"
            className={classes.successAlert}
          >
            <FormattedMessage
              id="bulkRemoveDialog.success.archived"
              values={archivedValues}
            />
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="bulkRemoveDialog.actions.ok" />
        </Button>
      </DialogActions>
    </>
  );
};

BulkDeleteResultDialogContent.propTypes = {
  objectType: PropTypes.oneOf(['PROJECT']),
  batchId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func
};

export default BulkDeleteResultDialogContent;
