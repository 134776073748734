import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { makeStyles, Slider } from '@material-ui/core';
import useGroupLabels from '~/modules/common/components/Groups/hooks/useGroupLabels';
import { RequestAttributeWeights, RequestAttributeWeightage } from '~/types';
import SliderValueLabel from './SliderValueLabel';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'contents',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  itemLabel: {
    ...theme.typography.subtitle2,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirecion: 'row',
      alignItems: 'baseline'
    }
  },
  label: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'inline',
      '&:after': {
        content: `':'`
      }
    }
  },
  value: {
    ...theme.typography.body2,
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1)
    }
  },
  noValue: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: 'normal'
  },
  slider: {}
}));

const ImportanceItem = ({
  className,
  fieldName,
  readonly,
  setFieldValue,
  value,
  weight
}: {
  weight: RequestAttributeWeights[typeof fieldName];
  fieldName:
    | 'location'
    | 'division'
    | 'serviceCenter'
    | 'costCenter'
    | 'department'
    | 'employeeType'
    | 'role'
    | 'skills'
    | 'tags'
    | 'requestedCostRate'
    | 'resourcePools'
    | 'preferredResources';
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
  readonly: boolean;
  className?: string;
  value?: ReactNode | null;
}): ReactElement => {
  const classes = useStyles();
  const id = `importance-slider-label-${fieldName}`;
  const sliderId = `importance-slider-${fieldName}`;

  const intl = useIntl();
  const groupLabels: any = useGroupLabels();
  const label: string =
    groupLabels[fieldName] ||
    intl.formatMessage({ id: `resourceRequestDrawerDetails.${fieldName}` });
  const noValue = groupLabels[fieldName]
    ? intl.formatMessage(
        { id: `resourceRequestDrawerDetails.anyGroup` },
        { groupName: groupLabels[fieldName] }
      )
    : intl.formatMessage({
        id: `resourceRequestDrawerDetails.any${fieldName}`
      });
  const weightLabels = useMemo(
    () => [
      intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.NiceToHave}`
      }),
      intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Useful}`
      }),
      intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Normal}`
      }),
      intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Important}`
      }),
      intl.formatMessage({
        id: `resourceRequestDrawerDetails.weights.${RequestAttributeWeightage.Required}`
      })
    ],
    [intl]
  );
  const getWeightText = useCallback((v: number) => weightLabels[v], [
    weightLabels
  ]);
  const weightToValue = useMemo(
    () => ({
      [RequestAttributeWeightage.NiceToHave]: 0,
      [RequestAttributeWeightage.Useful]: 1,
      [RequestAttributeWeightage.Normal]: 2,
      [RequestAttributeWeightage.Important]: 3,
      [RequestAttributeWeightage.Required]: 4
    }),
    []
  );

  const onChange = useCallback(
    (e: any, v: number | number[]) => {
      setFieldValue(fieldName, Object.keys(weightToValue)[v as any]);
    },
    [fieldName, setFieldValue, weightToValue]
  );

  return (
    <li className={classnames(classes.root, className)}>
      <label id={id} htmlFor={sliderId} className={classes.itemLabel}>
        <span className={classes.label}>{label}</span>
        {value ? (
          <span className={classes.value}>{value}</span>
        ) : (
          <span className={classnames(classes.value, classes.noValue)}>
            {noValue}
          </span>
        )}
      </label>
      <Slider
        id={sliderId}
        value={weightToValue[weight || RequestAttributeWeightage.Normal]}
        onChange={onChange}
        aria-labelledby={id}
        min={0}
        step={1}
        max={4}
        marks
        valueLabelFormat={getWeightText}
        getAriaValueText={getWeightText}
        ValueLabelComponent={SliderValueLabel}
        valueLabelDisplay="auto"
        disabled={readonly}
        className={classes.slider}
      />
    </li>
  );
};

export default ImportanceItem;
