import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NoValue, Hours } from '~/modules/common/components';

export const ActualHours = ({
  dataQeId,
  className,
  value,
  align = 'right'
}) => {
  return _.isNil(value) ? (
    <NoValue />
  ) : (
    <Hours
      className={className}
      value={value}
      precision={2}
      dataQeId={dataQeId}
      align={align}
    />
  );
};

ActualHours.propTypes = {
  className: PropTypes.string,
  dataQeId: PropTypes.string,
  value: PropTypes.number,
  align: PropTypes.string
};

export default ActualHours;
