import React, { createContext, useMemo, useEffect } from 'react';
import { useSearchState } from '~/modules/common/components/SearchBox';
import { useURLSearchParams } from '~/modules/common/hooks';
import { primaryRoleTag } from '~/modules/common/components/SearchBox/Facets/RoleFacet/tag';
import { costCenterTag } from '~/modules/common/components/SearchBox/Facets/CostCenterFacet/tag';
import { departmentTag } from '~/modules/common/components/SearchBox/Facets/DepartmentFacet/tag';
import { divisionTag } from '~/modules/common/components/SearchBox/Facets/DivisionFacet/tag';
import { employeeTypeTag } from '~/modules/common/components/SearchBox/Facets/EmployeeTypeFacet/tag';
import { locationTag } from '~/modules/common/components/SearchBox/Facets/LocationFacet/tag';
import { serviceCenterTag } from '~/modules/common/components/SearchBox/Facets/ServiceCenterFacet/tag';
import { skillTag } from '~/modules/common/components/SearchBox/Facets/SkillFacet/tag';
import { resourceTag } from '~/modules/common/components/SearchBox/Facets/UserFacet/tag';

export const ResourceManagementContext = createContext();

export const mapToField = (value, label) => ({
  id: value,
  value,
  key: label,
  label
});

export const getValue = (value, label, previousCriterion) => {
  return value ? [mapToField(value, label)] : previousCriterion;
};

export const withResourceSearchContextProvider = BaseComponent => props => {
  const queryParams = useURLSearchParams();
  const [searchCriteria, setSearchCriteria] = useSearchState('resource-pool');

  const label = queryParams.get('label');
  const roleValue = queryParams.get('role');
  const locationValue = queryParams.get('location');
  const divisionValue = queryParams.get('division');
  const serviceCenterValue = queryParams.get('serviceCenter');
  const costCenterValue = queryParams.get('costCenter');
  const departmentValue = queryParams.get('department');
  const employeeTypeValue = queryParams.get('employeeType');
  const skillValue = queryParams.get('skill');
  const resourceValue = queryParams.get('resource');

  const primaryRole = searchCriteria.criterions[primaryRoleTag] || [];
  const location = searchCriteria.criterions[locationTag] || [];
  const division = searchCriteria.criterions[divisionTag] || [];
  const serviceCenter = searchCriteria.criterions[serviceCenterTag] || [];
  const costCenter = searchCriteria.criterions[costCenterTag] || [];
  const department = searchCriteria.criterions[departmentTag] || [];
  const employeeType = searchCriteria.criterions[employeeTypeTag] || [];
  const skill = searchCriteria.criterions[skillTag] || [];
  const resource = searchCriteria.criterions[resourceTag] || [];

  useEffect(() => {
    if (setSearchCriteria) {
      setSearchCriteria({
        ...searchCriteria,
        criterions: {
          ...searchCriteria.criterions,
          [primaryRoleTag]: getValue(roleValue, label, primaryRole),
          [locationTag]: getValue(locationValue, label, location),
          [divisionTag]: getValue(divisionValue, label, division),
          [serviceCenterTag]: getValue(
            serviceCenterValue,
            label,
            serviceCenter
          ),
          [costCenterTag]: getValue(costCenterValue, label, costCenter),
          [departmentTag]: getValue(departmentValue, label, department),
          [employeeTypeTag]: getValue(employeeTypeValue, label, employeeType),
          [skillTag]: getValue(skillValue, label, skill),
          [resourceTag]: getValue(resourceValue, label, resource)
        },
        __typename: undefined
      });
    }
  }, [queryParams]);

  const values = useMemo(
    () => ({
      searchCriteria,
      setSearchCriteria,
      ...props
    }),
    [props, searchCriteria, setSearchCriteria]
  );

  return (
    <ResourceManagementContext.Provider value={values}>
      <BaseComponent {...props} />
    </ResourceManagementContext.Provider>
  );
};
export default ResourceManagementContext;
