import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl, FormattedMessage } from 'react-intl';
import { PreferredResourceIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';

const PreferredResourceIndicator = ({ value }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      className={classes.root}
      aria-label={intl.formatMessage({
        id: 'resourceRequestCard.preferredResources'
      })}
    >
      <Tooltip
        title={intl.formatMessage({
          id: 'resourceRequestCard.preferredResources'
        })}
      >
        <PreferredResourceIcon className={classes.icon} />
      </Tooltip>
      {value.length > 1 ? (
        <FormattedMessage
          id="resourceRequestCard.preferredResourceRequirements"
          values={{ count: value.length }}
        />
      ) : (
        <FormattedMessage
          id="resourceRequestCard.displayText"
          values={{ displayText: value[0]?.displayText }}
        />
      )}
    </div>
  );
};

PreferredResourceIndicator.propTypes = {
  value: PropTypes.array
};

export default PreferredResourceIndicator;
