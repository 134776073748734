import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

const toggleButtonResourceKeys = {
  show: <FormattedMessage id="addLineItemDialog.more" />,
  hide: <FormattedMessage id="addLineItemDialog.less" />
};

export default ({ intl }) => {
  const accesibilityResourceLabels = useMemo(
    () => ({
      currencyAriaLabel: intl.formatMessage({
        id: 'billDetails.currencyAriaLabel'
      }),
      clientAriaLabel: intl.formatMessage({
        id: 'billDetails.clientAriaLabel'
      })
    }),
    [intl]
  );

  const resourceLabels = useMemo(
    () => ({
      referenceNumber: intl.formatMessage({
        id: 'billDetails.referenceNumber'
      }),
      issueDate: intl.formatMessage({ id: 'billDetails.issueDate' }),
      description: intl.formatMessage({ id: 'billDetails.description' }),
      paymentTerms: intl.formatMessage({ id: 'billDetails.paymentTerm' }),
      dueDate: intl.formatMessage({ id: 'billDetails.dueDate' }),
      poNumber: intl.formatMessage({ id: 'billDetails.poNumber' }),
      period: intl.formatMessage({ id: 'billDetails.Period' }),
      billCurrency: intl.formatMessage({ id: 'billDetails.billCurrency' }),
      billingAddress: intl.formatMessage({ id: 'billDetails.billingAddress' }),
      taxProfile: intl.formatMessage({ id: 'billDetails.taxProfile' }),
      notesForCustomer: intl.formatMessage({
        id: 'billDetails.notesForCustomer'
      }),
      internalNotes: intl.formatMessage({ id: 'billDetails.internalNotes' }),
      showComments: intl.formatMessage({ id: 'billDetails.showComments' })
    }),
    [intl]
  );

  return {
    resourceLabels,
    accesibilityResourceLabels,
    toggleButtonResourceKeys
  };
};
