import React from 'react';
import { retryLoading } from '~/util';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { FinancialsIcon } from '../common/components/Icons';

const FinancialsPage = React.lazy(() =>
  retryLoading(() => import('./FinancialsPage'))
);

export const hasBillingManagerRole = ({ userAccessRoles }) =>
  Boolean(
    userAccessRoles.length &&
      userAccessRoles.includes(USER_ACCESS_ROLE.BILLING_MANAGER)
  );

export const hasRevenueManagerRole = ({ userAccessRoles }) =>
  Boolean(
    userAccessRoles.length &&
      userAccessRoles.includes(USER_ACCESS_ROLE.REVENUE_MANAGER)
  );

export const billingInvoicingRoute = ({ intl, me }) => ({
  path: '/financials',
  icon: () => <FinancialsIcon />,
  isSideBarRoute: true,
  title: () => intl.formatMessage({ id: 'routes.financials' }),
  showDivider: false,
  component: FinancialsPage,
  hasPermission: hasBillingManagerRole(me) || hasRevenueManagerRole(me),
  hidden: true,
  dataQeId: 'MainNav_Financials'
});
