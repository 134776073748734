import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { PropTypes } from 'prop-types';
import QuickFilterSummaryTabs from '~/modules/common/components/QuickFilterSummaryTabs';
import { MobileQuickFilterSummaryTabs } from '~/modules/common/components/QuickFilterSummaryTabs/MobileQuickFilterSummaryTabs';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useBillingTransactionFilterMemo } from '../BillingTransactionTab/hooks/useBillingTransactionFilterMemo';
import {
  useOutstandingBillTabSummary,
  useUnallocatedTabSummary,
  useOverdueBillTabSummary,
  useDraftTabSummary,
  useVoidedBillTabSummary,
  useIssuedTabSummary,
  useBillingTransactionSummary
} from './hooks';
import { getStatusCountSummaryBasedProps } from './useStatusCountSummary';
import { getQuickFilterSummaryTabs } from './getQuickFilterSummaryTabs';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0,
    right: 0,
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2)
  }
}));

export const BillingQuickFilterSummary = ({
  projectUri,
  clientUri,
  programId,
  searchCriteria,
  summaryFilterState,
  onResetSelection,
  isMobile
}) => {
  const classes = useStyles();

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const contextFilter = {
    projectUri,
    clientUri,
    programUri: programId
  };

  const { billingTransactionFilter } = useBillingTransactionFilterMemo({
    searchCriteria
  });

  const { loading: isDraftSummaryLoading, draftSummary } = useDraftTabSummary(
    billingTransactionFilter,
    contextFilter
  );

  const {
    loading: isIssuedSummaryLoading,
    issuedSummary
  } = useIssuedTabSummary(billingTransactionFilter, contextFilter);

  const {
    loading: isOutstandingBillSummaryLoading,
    outstandingBillSummary
  } = useOutstandingBillTabSummary(billingTransactionFilter, contextFilter);

  const {
    loading: isUnallocatedLoading,
    unallocatedSummary
  } = useUnallocatedTabSummary(billingTransactionFilter, contextFilter);

  const {
    loading: isOverdueBillLoading,
    overdueSummary
  } = useOverdueBillTabSummary(billingTransactionFilter, contextFilter);

  const {
    loading: isBillingTransactionSummaryLoading,
    billingTransacitonSummary
  } = useBillingTransactionSummary(billingTransactionFilter, contextFilter);

  const {
    loading: isVoidedBillLoading,
    voidedBillCount
  } = useVoidedBillTabSummary(billingTransactionFilter, contextFilter);

  const isLoading = isPsaFpGraphqlOptimizationEnabled
    ? isBillingTransactionSummaryLoading || isVoidedBillLoading
    : isDraftSummaryLoading ||
      isIssuedSummaryLoading ||
      isOutstandingBillSummaryLoading ||
      isUnallocatedLoading ||
      isOverdueBillLoading ||
      isVoidedBillLoading;

  const { statusOptions, voidedBill } = isPsaFpGraphqlOptimizationEnabled
    ? getStatusCountSummaryBasedProps({
        draftSummary: billingTransacitonSummary.draftSummary,
        issuedSummary: billingTransacitonSummary.issuedSummary,
        outstandingBillSummary:
          billingTransacitonSummary.outstandingBillSummary,
        unallocatedSummary: billingTransacitonSummary.unallocatedSummary,
        overdueSummary: billingTransacitonSummary.overdueSummary,
        voidedBillCount
      })
    : getStatusCountSummaryBasedProps({
        draftSummary,
        issuedSummary,
        outstandingBillSummary,
        unallocatedSummary,
        overdueSummary,
        voidedBillCount
      });

  const {
    allItems,
    transactionPhases,
    alternativeItems
  } = getQuickFilterSummaryTabs({
    statusOptions,
    voidedBill
  });

  return isMobile ? (
    <MobileQuickFilterSummaryTabs
      allItems={allItems}
      summaryItems={transactionPhases}
      summaryFilterState={summaryFilterState}
      isValueLoading={isLoading}
      showSingleAllItem
      onResetSelection={onResetSelection}
      alternativeItems={alternativeItems}
    />
  ) : (
    <div className={classes.root}>
      <QuickFilterSummaryTabs
        allItems={allItems}
        summaryItems={transactionPhases}
        summaryFilterState={summaryFilterState}
        isValueLoading={isLoading}
        onResetSelection={onResetSelection}
        alternativeItems={alternativeItems}
      />
    </div>
  );
};

BillingQuickFilterSummary.propTypes = {
  projectUri: PropTypes.string,
  clientUri: PropTypes.string,
  programId: PropTypes.string,
  searchCriteria: PropTypes.object,
  summaryFilterState: PropTypes.object,
  onResetSelection: PropTypes.func,
  isMobile: PropTypes.bool
};

export default BillingQuickFilterSummary;
