import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import { useIntl } from 'react-intl';
import RequestActionButton from './RequestActionButton';

const CompleteRequestActionButton = ({ className, onClick, ...otherProps }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <RequestActionButton
      className={className}
      paletteClass={theme.palette.resourceRequest.complete}
      onClick={onClick}
      label={intl.formatMessage({ id: 'resourceAssignmentDialog.completed' })}
      {...otherProps}
    />
  );
};

CompleteRequestActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default CompleteRequestActionButton;
