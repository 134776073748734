import { makeStyles } from '@material-ui/styles';

export const useListTableStyles = makeStyles(() => ({
  card: {
    overflow: 'inherit',
    boxShadow: 'none'
  }
}));

export const useListColumnsStyles = makeStyles(theme => ({
  group: {
    width: theme.spacing(17),
    borderLeft: '0px !important',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      cursor: 'pointer'
    }
  },
  groupwithNav: {
    width: theme.spacing(34)
  },
  count: {
    width: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(3)
    },
    maxWidth: theme.spacing(8),
    borderLeft: '0px !important'
  },
  duration: {}
}));

export const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    width: '100%',
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(0)
    },
    '& td:first-child, th:first-child': {
      borderLeft: 0,
      textAlign: 'left'
    },
    '& td:nth-child(2), th:nth-child(2)': {
      borderLeft: 0
    },
    '& tfoot > tr > td:first-child': {
      textAlign: 'right'
    }
  },
  tableCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(0),
    borderLeft: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'pre-wrap'
  },
  tableHeaderCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(0, 1.5, 0, 0.5),
    borderLeft: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'pre-wrap'
  },
  tableFooter: {
    backgroundColor: theme.palette.grey[100],
    color: 'inherit'
  },
  tableFooterCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(0),
    color: theme.palette.getContrastText(theme.palette.grey[200])
  },
  headerCellWithNavigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'end'
  }
}));
