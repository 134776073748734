import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PaymentIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M22 4H2.01L2 20h20V4zm-2 14H4v-6h16v6zm0-10H4V6h16v2z" />
    </>
  </SvgIcon>
));

export default PaymentIcon;
