import React, { useCallback } from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RescheduleTaskModificationOption } from '~/types';

const RescheduleTaskDialogFooter = ({
  onClose,
  onSubmit,
  newTaskDateRange,
  isRemoveAllocationsChecked,
  disableSubmitbutton
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit({
      startDate: newTaskDateRange.startDate,
      endDate: newTaskDateRange.endDate,
      rescheduleTaskModificationOption: isRemoveAllocationsChecked
        ? RescheduleTaskModificationOption.RemoveAllAllocations
        : null
    });
  }, [onSubmit, isRemoveAllocationsChecked, newTaskDateRange]);

  return (
    <DialogActions>
      <Button onClick={onClose} data-qe-id="button.cancel">
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        color="primary"
        onClick={handleSubmit}
        disabled={disableSubmitbutton}
        data-qe-id="rescheduleTaskDialog.rescheduleTask"
      >
        <FormattedMessage id="rescheduleTaskDialog.rescheduleTask" />
      </Button>
    </DialogActions>
  );
};

RescheduleTaskDialogFooter.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  newTaskDateRange: PropTypes.object,
  isRemoveAllocationsChecked: PropTypes.bool,
  disableSubmitbutton: PropTypes.bool
};

export default RescheduleTaskDialogFooter;
