import React from 'react';
import { FormattedMessage } from 'react-intl';

export const getQuickFilterSummaryTabs = ({
  statusOptions,
  allTotal
} = {}) => ({
  allItems: [
    {
      key: 'all-account-receivable',
      label: <FormattedMessage id="billingTransactionList.allBills" />,
      value: allTotal,
      dataQeId: 'Financials_BillingInvoicing_AccountReceiveable_All'
    }
  ],
  transactionPhases: statusOptions.map(statusOption => ({
    key: statusOption.id,
    label: statusOption.displayText,
    color: statusOption.color.dark,
    value: statusOption.totals,
    dataQeId: statusOption.dataQeId
  }))
});
