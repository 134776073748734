import { makeStyles, alpha } from '@material-ui/core/styles';
import { themeWithoutDir } from '~/modules/App/withRootTheme';

export const getLoadingColor = ({ load }) => {
  if (load > 100)
    return themeWithoutDir.palette.allocationChartStatus.overAllocated.main;

  return alpha(
    themeWithoutDir.palette.allocationChartStatus.allocated.main,
    Math.max(0, load / 100)
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    height: theme.spacing(3),
    userSelect: 'none',
    zIndex: 2,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  transparentButton: {
    opacity: 0,
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  }
}));

export default useStyles;
