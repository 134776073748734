import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const ModuleDialogTitle = ({
  classes: classesOverride,
  title,
  icon,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      <Icon className={classes.icon}>{icon}</Icon>
      <Typography data-qe-id={dataQeId} variant="h6">
        {title}
      </Typography>
    </div>
  );
};

ModuleDialogTitle.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node
};

export default ModuleDialogTitle;
