import React, { useMemo, useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core';

import CardExpansionPanel from '../CardExpansionPanel';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2]
  },
  expansionPanelSummaryContent: {
    padding: theme.spacing(1.5, 0, 1.5, 2)
  },
  expansionPanelSummary: {},
  expansionPanel: {}
}));

export const OverviewItemExpansionPanel = ({
  classes: classesOverride,
  counts,
  title,
  leftBorderColorClass,
  TitleComponent,
  square = false,
  elevation = 1,
  children,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });
  const [panelExpanded, setPanelExpanded] = useState(false);

  const onExpansionPanelClick = useCallback((event, expanded) => {
    setPanelExpanded(expanded);
  }, []);

  const titleComponentProps = useMemo(
    () => ({
      count: counts,
      'data-qe-id': 'OverviewTitle',
      title
    }),
    [counts, title]
  );

  const panelClasses = useMemo(
    () => ({
      expansionPanel: classes.expansionPanel,
      expansionPanelSummary: classes.root,
      expansionPanelSummaryContent: classNames(
        classes.expansionPanelSummaryContent,
        leftBorderColorClass
      )
    }),
    [
      classes.expansionPanelSummaryContent,
      classes.root,
      classes.expansionPanel,
      leftBorderColorClass
    ]
  );

  return (
    <CardExpansionPanel
      classes={panelClasses}
      TitleComponent={TitleComponent}
      titleComponentProps={titleComponentProps}
      defaultExpanded={false}
      onChange={onExpansionPanelClick}
      square={square}
      elevation={elevation}
      dataQeId={dataQeId}
    >
      {panelExpanded && children}
    </CardExpansionPanel>
  );
};

OverviewItemExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  counts: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  leftBorderColorClass: PropTypes.string,
  TitleComponent: PropTypes.elementType,
  children: PropTypes.node,
  square: PropTypes.bool,
  elevation: PropTypes.number,
  dataQeId: PropTypes.string
};

export default OverviewItemExpansionPanel;
