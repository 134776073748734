import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import { useEnabledSkills } from '~/modules/common/hooks';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import SkillDropdownOption from './SkillDropdownOption';

const groupBy = val => val.category && val.category.displayText.toUpperCase();

export const SkillFacetDetails = ({
  className,
  selected: selectedSkills,
  setSelected: setSelectedSkills,
  includeNoneOption = true
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const { formatMessage } = useIntl();

  const getOptionDisabled = useCallback(
    option =>
      selectedSkills.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selectedSkills]
  );

  const {
    isLoading,
    sortedOptionsWithCategory: options,
    hasMore
  } = useEnabledSkills({
    includeNoneOption,
    noneOptionText: formatMessage({ id: 'skill.noSkill' }),
    searchTerm
  });

  const renderOption = useCallback(
    option =>
      option.key === formatMessage({ id: 'NONE' }) ? (
        <NoneDropdownItem value={option.displayText} />
      ) : option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <SkillDropdownOption value={option.label} />
      ),
    [formatMessage]
  );

  const onValueChange = useCallback(
    selectedOptions =>
      onChange({ selectedOptions, setSelected: setSelectedSkills }),
    [setSelectedSkills]
  );

  const classes = useStyles();

  const skillLabel = formatMessage({ id: 'resourcePoolSearchBox.skill' });

  const skills = (!isLoading && options) || [];

  return (
    <FacetDetailField label={skillLabel} className={className}>
      <SimpleAutocomplete
        options={skills}
        variant="standard"
        loading={isLoading}
        getOptionDisabled={getOptionDisabled}
        hiddenLabel
        noOptionsText={formatMessage({
          id: 'skill.noSkill'
        })}
        onChange={onValueChange}
        fullWidth
        inputLabel={skillLabel}
        value={selectedSkills}
        optionPropText="label"
        classes={classes}
        multiple
        groupBy={groupBy}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        hasMore={hasMore}
        optionTypeText={formatMessage({
          id: 'moreOptions.skills'
        })}
      />
    </FacetDetailField>
  );
};

SkillFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  includeNoneOption: PropTypes.bool
};

export default SkillFacetDetails;
