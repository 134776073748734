import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { lookup } from 'mime-types';

import defaultAcceptedFileTypes from './fileTypes';

export const onReaderLoadHandler = ({
  droppedFile,
  reader,
  onComplete
}) => () => {
  const { name, type: detectedMimeType, size } = droppedFile;

  let mimeType = detectedMimeType;

  if (!mimeType) {
    mimeType = lookup(name);
  }

  const base64Content = window.btoa(reader.result);

  onComplete({
    filename: name,
    base64Content,
    mimeType,
    size
  });
};

export const useOnFileDrop = ({
  onFileUpload,
  maximumFileSizeInMB,
  acceptedFileTypes = defaultAcceptedFileTypes,
  disabled = false,
  multiple = false
}) => {
  const onDrop = useCallback(
    async droppedFiles => {
      const promises = droppedFiles.map(
        droppedFile =>
          new Promise(resolve => {
            const reader = new FileReader();

            reader.onload = onReaderLoadHandler({
              droppedFile,
              reader,
              onComplete: resolve
            });

            droppedFile && reader.readAsBinaryString(droppedFile);
          })
      );

      const files = await Promise.all(promises);

      files && files.length > 0 && onFileUpload(files);
    },
    [onFileUpload]
  );

  const maximumFileSizeInBytes = maximumFileSizeInMB * 1048576;
  const { getRootProps, getInputProps, rejectedFiles } = useDropzone({
    onDrop,
    multiple,
    accept: acceptedFileTypes.join(', '),
    maxSize: maximumFileSizeInBytes,
    disabled
  });

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maximumFileSizeInBytes;

  return {
    getRootProps,
    getInputProps,
    rejectedFiles,
    isFileTooLarge
  };
};
