import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EditableCard, {
  ReadOnly,
  ReadOnlyContent,
  Edit,
  EditTitle,
  EditContent
} from '~/modules/common/components/EditableCard';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { BillingRateReadOnly, BillingRateEditor } from './components';
import { usePutBillingRatesFormState, useUpdateBillingRates } from './hooks';
import { useEditableCardStyles } from './hooks/useBillingRateStyles';

export const BillingRateCardEditable = ({
  projectId,
  rateType,
  rates,
  count,
  editable,
  loadMoreRows,
  hasMoreRows,
  billingRates,
  addOrRemoveBillingRate,
  billingRateOptions,
  onBillingRatesSave,
  refetchQueries,
  hasMoreEditableBillingRates,
  loadEditableBillingRates
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsBreakpointDown('xs');
  const cardClasses = useEditableCardStyles();
  const availableBillingRates = useMemo(
    () =>
      billingRates?.length > 0
        ? billingRates.reduce(
            (retVal, current) => ({
              ...retVal,
              [current.billingRate.id]: true
            }),
            {}
          )
        : null,
    [billingRates]
  );
  const { updateBillingRates } = useUpdateBillingRates({
    projectId,
    rateType,
    refetchQueries
  });
  const formik = usePutBillingRatesFormState({
    updateBillingRates,
    projectId,
    billingRates
  });
  const {
    values,
    setFieldValue,
    resetForm,
    handleSubmit,
    errors,
    validateForm,
    isSubmitting
  } = formik;

  useEffect(() => {
    const validate = () => validateForm(values);

    validate();
  }, [validateForm, values]);
  const saveable = useMemo(() => Object.keys(errors).length === 0, [errors]);
  const onSave = useCallback(() => {
    onBillingRatesSave && onBillingRatesSave(1);
    handleSubmit();
  }, [handleSubmit, onBillingRatesSave]);
  const rateTypetitle = useMemo(
    () =>
      formatMessage({
        id: `billingRateCard.title.${rateType}`
      }),
    [formatMessage, rateType]
  );
  const editTitle = useMemo(
    () =>
      formatMessage({
        id: `billingRateCard.title.main`
      }),
    [formatMessage]
  );
  const countValues = useMemo(() => ({ count }), [count]);
  const subTitle = useMemo(() => {
    return (
      <FormattedMessage
        id={
          count <= 1
            ? 'billingRateCard.subTitle.singular'
            : 'billingRateCard.subTitle.plural'
        }
        values={countValues}
      />
    );
  }, [count, countValues]);

  return (
    <>
      <EditableCard
        editable={editable}
        className={cardClasses.card}
        editContentClassName={cardClasses.root}
        classes={cardClasses}
        fullScreen={isMobile}
        ariaLabelKey="billingRateCard.billingRateCardDialog"
      >
        <ReadOnly title={rateTypetitle} subTitle={subTitle} expandable>
          <ReadOnlyContent>
            <BillingRateReadOnly
              rateType={rateType}
              rateTitle={rateTypetitle}
              count={count}
              rates={rates}
              loadMoreRows={loadMoreRows}
              hasMoreRows={hasMoreRows}
              editable={editable}
            />
          </ReadOnlyContent>
        </ReadOnly>
        <Edit
          saveable={saveable}
          onSave={onSave}
          onCancel={resetForm}
          isSaving={isSubmitting}
        >
          <EditTitle>{editTitle}</EditTitle>
          <EditContent>
            <BillingRateEditor
              projectId={projectId}
              rateType={rateType}
              values={values}
              rateTitle={rateTypetitle}
              setFieldValue={setFieldValue}
              loadMoreRows={loadEditableBillingRates}
              hasMoreRows={hasMoreEditableBillingRates}
              errors={errors}
              billingRateOptions={billingRateOptions}
              addOrRemoveBillingRate={addOrRemoveBillingRate}
              isMobile={isMobile}
              availableBillingRates={availableBillingRates}
            />
          </EditContent>
        </Edit>
      </EditableCard>
    </>
  );
};

BillingRateCardEditable.propTypes = {
  projectId: PropTypes.string,
  rateType: PropTypes.string,
  rates: PropTypes.array,
  count: PropTypes.number,
  editable: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  hasMoreRows: PropTypes.bool,
  billingRates: PropTypes.array,
  addOrRemoveBillingRate: PropTypes.bool,
  billingRateOptions: PropTypes.array,
  onBillingRatesSave: PropTypes.func,
  refetchQueries: PropTypes.array,
  hasMoreEditableBillingRates: PropTypes.bool,
  loadEditableBillingRates: PropTypes.func
};

export default BillingRateCardEditable;
