import { makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 40,
    width: 464,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden',
    borderRight: 1,
    padding: theme.spacing(0, 0.5),
    margin: theme.spacing(1)
  },
  spacing: {
    margin: theme.spacing(1, 0)
  }
}));

const RowLeftItemLoading = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={250}
          height={12}
        />
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={50}
          height={12}
        />
      </div>
    </Paper>
  );
};

export default RowLeftItemLoading;
