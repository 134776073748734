import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProgressIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 5.68,4.26 C 7.160000000000001,3.05 8.99,2.25 11,2.05 V 4.070000000000002 C 9.539999999999999,4.25 8.21,4.83 7.100000000000003,5.690000000000003 Z M 4.070000000000002,11 H 2.050000000000001 C 2.25,8.99 3.050000000000001,7.160000000000001 4.260000000000002,5.68 L 5.690000000000003,7.1 C 4.83,8.21 4.25,9.539999999999999 4.070000000000002,11 Z m 1.620000000000001,5.9 -1.430000000000001,1.43 C 3.050000000000002,16.85 2.25,15.01 2.050000000000002,13.01 h 2.02 c 0.179999999999998,1.45 0.76,2.78 1.620000000000001,3.89 z M 11,19.93 v 2.02 C 8.99,21.75 7.160000000000001,20.95 5.68,19.74 L 7.110000000000001,18.31 C 8.209999999999999,19.17 9.539999999999999,19.75 11,19.93 Z m 2,0 v 2.02 C 18.05,21.45 22,17.19 22,12 22,6.810000000000004 18.05,2.550000000000001 13,2.050000000000001 V 4.070000000000002 C 16.95,4.56 20,7.92 20,12 c 0,4.08 -3.05,7.44 -7,7.93 z" />
      <path d="m 16.2421875,7.404296875 -0.70703125,0.70703125 -5.65625,5.65625 -1.4140625,-1.4140625 -0.70703125,-0.70703125 -1.4140625,1.4140625 0.70703125,0.70703125 2.828125,2.828125 7.0703125,-7.0703125 0.70703125,-0.70703125 z" />
    </>
  </SvgIcon>
));

export default ProgressIcon;
