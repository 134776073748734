import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useStyles from './useStyles';

export const OverAllocatedIndicator = ({ className }) => {
  const classes = useStyles();

  return <span className={classNames(classes.root, className)} />;
};

OverAllocatedIndicator.propTypes = {
  className: PropTypes.string
};

export default OverAllocatedIndicator;
