export const getAssignedRole = ({ selectedResource }) => {
  if (selectedResource?.projectRoles?.[0]?.projectRole.uri !== 'null') {
    return {
      id: selectedResource?.projectRoles?.[0]?.projectRole.uri,
      displayText: selectedResource?.projectRoles?.[0]?.projectRole.name
    };
  }

  return null;
};

const useTaskResourceEstimateChangeHandlers = ({
  onResourceEstimateChange,
  resourceEstimate
}) => ({
  onRemove: () => {
    onResourceEstimateChange({
      assignment: resourceEstimate,
      isDelete: true
    });
  },
  onEstimatedHoursChange: hours =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        estimatedHours: hours,
        estimatedCost: {
          amount: hours * 100,
          currencySymbol: '$'
        }
      }
    }),
  onResourceChange: selectedResource =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        resource: selectedResource,
        role: resourceEstimate.role || getAssignedRole({ selectedResource })
      }
    }),
  onRoleChange: role =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        role
      }
    }),
  onAllocationDateChange: dateRange =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        dateRange
      }
    })
});

export default useTaskResourceEstimateChangeHandlers;
