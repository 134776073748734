import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDayNameByLuxonWeekdayIndex } from '~/modules/common/dates/dayOfWeek';
import { longDateRangeFormat } from '~/modules/common/dates/format';
import { getQuarterFromMonth } from '~/modules/common/dates/quarter';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';

const useStyles = makeStyles(theme => ({
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const HeaderTimeItemTooltipContent = ({
  classes: classesOverrides,
  scale,
  start,
  end
}) => {
  const classes = useStyles({ classes: classesOverrides });

  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return (
      <>
        <div className={classes.tooltipTitle}>
          {getDayNameByLuxonWeekdayIndex([start.weekday])}
        </div>
        {longDateRangeFormat({ start, end })}
      </>
    );
  }

  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    return longDateRangeFormat({ start, end });
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    return (
      <>
        <FormattedMessage id="chart.quarterAbbreviated" />
        {getQuarterFromMonth(start.month)}{' '}
        {end.toLocaleString({ year: 'numeric' })}
        <br />
        {start.toLocaleString({ month: 'short' })} &mdash;{' '}
        {end.toLocaleString({ month: 'short' })}
      </>
    );
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    return start.toLocaleString({ year: 'numeric' });
  }

  return start.toLocaleString({ month: 'long', year: 'numeric' });
};

HeaderTimeItemTooltipContent.propTypes = {
  classes: PropTypes.object,
  scale: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object
};

export default HeaderTimeItemTooltipContent;
