import React from 'react';
import { Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { ChartSkeletonRows } from '~/modules/common/charts/timeline/components';

const useLeftComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.75, 1, 0.625, 2),
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

export const RowSkeletonLeftComponent = () => {
  const classes = useLeftComponentStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <Skeleton className={classes.spacing} width="30%" height={24} />
      <Skeleton className={classes.spacing} width="20%" height={24} />
    </Paper>
  );
};

export const GanttSkeleton = () => (
  <ChartSkeletonRows LeftComponent={RowSkeletonLeftComponent} />
);

export default GanttSkeleton;
