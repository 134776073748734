import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormControl } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { useGetTagFieldsQuery } from '../hooks/useGetTagFieldsQuery';

export const TagField = ({
  field,
  label,
  editable,
  variant,
  onChange,
  definitionId,
  setDefinitionId,
  fullWidth = true
}) => {
  const [tagSearchPhrase, setTagSearchPhrase] = useState('');
  const { tags, loading } = useGetTagFieldsQuery({
    tagId: definitionId,
    searchPhrase: tagSearchPhrase
  });

  const onInputChange = useCallback(
    (_, val, reason) => setTagSearchPhrase(reason === 'input' ? val : ''),
    [setTagSearchPhrase]
  );

  const { formatMessage } = useIntl();

  const handleOnChange = useCallback(
    val => {
      onChange(null, val);
      setTagSearchPhrase('');
    },
    [onChange, setTagSearchPhrase]
  );

  const onInputFocus = useCallback(() => {
    setTagSearchPhrase('');
    setDefinitionId(field.definition.id);
  }, [field, setTagSearchPhrase, setDefinitionId]);

  return (
    <FormControl
      disabled={!editable}
      fullWidth={fullWidth}
      {...(editable && {
        variant
      })}
    >
      <SimpleAutocomplete
        loading={loading}
        inputLabel={label}
        value={field.tag}
        onInputChange={onInputChange}
        onChange={handleOnChange}
        onFocus={onInputFocus}
        disabled={!editable}
        variant={variant}
        optionPropText="displayText"
        noOptionsText={formatMessage({ id: 'projectTasksPage.none' })}
        options={tags}
      />
    </FormControl>
  );
};

TagField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  definitionId: PropTypes.string.isRequired,
  setDefinitionId: PropTypes.func.isRequired
};

export default TagField;
