import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import {
  CostCenterDropdown,
  DepartmentDropdown,
  DivisionDropdown,
  EmployeeTypeDropdown,
  LocationsDropdown,
  ServiceCenterDropdown
} from '~/modules/common/components/Groups/project';
import useMeContext from '~/modules/me/useMeContext';
import { useStyledGroupLabels } from '~/modules/common/components/Groups/hooks';
import AddSkills from '~/modules/resourcing/common/components/SearchableRoleDropDown/AddSkills';
import GroupItems from '~/modules/projects/resourcing-plan/components/GroupItems';
import { WeightTag } from '~/modules/resourcing/common/components';
import { ResourcePoolDropdownGrid } from '~/modules/projects/resourcing-plan/components/ResourcePoolDropdownGrid';
import { ResourcePoolDropdown } from '~/modules/resourcing/common/components/ResourcePool/ResourcePoolDropdown';
import { PreferredResourcesInfo } from '~/modules/resourcing/common/components/PreferredResources';
import { User } from '~/modules/common/components';
import { getTodayForUser } from '~/modules/common/dates/today';
import GroupsTitle from '../../GroupsTitle';
import AddTags from '../AddTags/AddTags';
import useStyles from '../useStyles';
import useAllTags from '../AddTags/useAllTags';
import ResourcePoolTitle from '../../ResourcePoolTitle';

export const GroupDropdownComponentsMap = {
  costCenter: CostCenterDropdown,
  department: DepartmentDropdown,
  division: DivisionDropdown,
  employeeType: EmployeeTypeDropdown,
  location: LocationsDropdown,
  serviceCenter: ServiceCenterDropdown
};

const useUserStyles = makeStyles(theme => ({
  container: {
    width: 'max-content',
    margin: theme.spacing(0, 0.5)
  }
}));

const useUserSecondaryOverrideStyles = makeStyles(() => ({
  secondary: {
    display: 'flex'
  }
}));

export const useExpandableSections = ({
  fields,
  requiredFields,
  openImportanceDialog,
  setFieldValue,
  customLabel
}) => {
  const intl = useIntl();

  const me = useMeContext();
  const { groupSettings, permissionsMap, resourceCostMode } = me;

  const todayForUser = getTodayForUser(me);

  const canViewSkills = permissionsMap['urn:replicon:skill-action:view-skill'];

  const canViewTags =
    permissionsMap['urn:replicon:user-action:view-custom-fields'];

  const styledGroupLabels = useStyledGroupLabels(requiredFields);

  const { tags: resourceTags = [], isTagLoading } = useAllTags();

  const {
    requestAttributeWeights,
    skills,
    tags,
    resourcePools,
    preferredResources,
    role,
    roleRate
  } = fields;

  const classes = useStyles();
  const userClasses = useUserStyles();
  const userOverrideClasses = useUserSecondaryOverrideStyles();

  const [groupItemsMap, resourcePoolContent] = useMemo(() => {
    const groups = [
      'costCenter',
      'department',
      'division',
      'employeeType',
      'location',
      'serviceCenter'
    ].reduce((acc, key) => {
      const GroupDropdownComponent = GroupDropdownComponentsMap[key];
      const field = fields[key];

      return {
        ...acc,
        ...(groupSettings[key].isEnabled && {
          [`${key}`]: {
            value: field.value,
            isRequired: requiredFields[key],
            Component: (
              <div className={classes.weightTagContainer}>
                <GroupDropdownComponent
                  label={styledGroupLabels[key]}
                  groupSettings={groupSettings[key]}
                  value={field.value}
                  onChange={field.onChange}
                  variant="filled"
                  hasError={field.hasError}
                  helperText={
                    <>
                      {field.hasError ? <span>{field.error}</span> : null}
                      <WeightTag
                        className={classes.weightTag}
                        weight={requestAttributeWeights.value?.[key]}
                        onClick={openImportanceDialog}
                      />
                    </>
                  }
                  alwaysShowHelperText
                />
              </div>
            )
          }
        })
      };
    }, {});

    const resourcePool = {
      value: resourcePools.value,
      isRequired: requiredFields.resourcePools,
      key: 'resourcePoolContent',
      Component: (
        <div className={classes.weightTagContainer}>
          <ResourcePoolDropdown
            value={resourcePools.value}
            onChange={resourcePools.onChange}
            variant="filled"
            hasError={resourcePools.hasError}
            isRequired={requiredFields.resourcePools}
            helperText={
              <>
                {resourcePools.hasError ? (
                  <span>{resourcePools.error}</span>
                ) : null}
                <WeightTag
                  className={classes.weightTag}
                  weight={requestAttributeWeights.value?.resourcePools}
                  onClick={openImportanceDialog}
                />
              </>
            }
            alwaysShowHelperText
          />
        </div>
      )
    };

    return [groups, resourcePool];
  }, [
    classes.weightTag,
    classes.weightTagContainer,
    fields,
    groupSettings,
    openImportanceDialog,
    requestAttributeWeights.value,
    requiredFields,
    resourcePools,
    styledGroupLabels
  ]);

  const [groupsTitle, resourcePoolTitle] = useMemo(() => {
    const groups = <GroupsTitle itemsMap={groupItemsMap} key="groups-title" />;
    const resourcePool =
      resourcePoolContent?.value.length > 0 ||
      resourcePoolContent?.isRequired ? (
        <ResourcePoolTitle
          resourcePools={resourcePoolContent.value}
          key="resource-pool-title"
        />
      ) : null;

    return [groups, resourcePool];
  }, [groupItemsMap, resourcePoolContent]);

  const collapsedGroupDetails = useMemo(() => {
    const groupsCollapsedTitle = Object.values(groupItemsMap).some(
      item => item.value || item.isRequired
    )
      ? groupsTitle
      : null;

    const resourcePoolsCollapsedTitle =
      resourcePoolContent?.value.length > 0 || resourcePoolContent?.isRequired
        ? resourcePoolTitle
        : null;

    return groupsCollapsedTitle || resourcePoolsCollapsedTitle
      ? [groupsCollapsedTitle, resourcePoolsCollapsedTitle].filter(Boolean)
      : intl.formatMessage({
          id: 'resourceRequestDrawerDetails.noGroups'
        });
  }, [
    intl,
    groupItemsMap,
    groupsTitle,
    resourcePoolContent,
    resourcePoolTitle
  ]);

  const groupContent = useMemo(() => {
    const groupSectionInfo =
      Object.keys(groupItemsMap) && Object.keys(groupItemsMap).length > 0 ? (
        <GroupItems itemsMap={groupItemsMap} key="group-items" />
      ) : null;

    const resourcePoolSectionInfo = (
      <ResourcePoolDropdownGrid
        key="resourcePoolContentGrid"
        resourcePoolContent={resourcePoolContent}
        isLast
      />
    );

    return [groupSectionInfo, resourcePoolSectionInfo].filter(Boolean);
  }, [groupItemsMap, resourcePoolContent]);

  const expandableSections = useMemo(() => {
    const skillsInfo = {
      key: 'skills',
      label: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.skills'
      }),
      labelTag: (
        <WeightTag
          weight={requestAttributeWeights.value?.skills}
          onClick={openImportanceDialog}
        />
      ),
      collapsedDetails: skills.value.length ? (
        skills.value.map(skill => skill.displayText).join(', ')
      ) : (
        <span
          className={classNames({
            [classes.expandableError]: requiredFields.skills
          })}
        >
          {intl.formatMessage({
            id: 'resourceRequestDrawerDetails.noSkills'
          })}
        </span>
      ),
      content: (
        <AddSkills
          skills={skills.value}
          setFieldValue={setFieldValue}
          required={requiredFields.skills}
          variant="outlined"
          className={classes.skillsDropdown}
          isResourceRequestSkillRow
          customLabel={customLabel}
        />
      )
    };

    const preferredResourcesInfo = {
      key: 'preferredResources',
      label: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.preferredResources'
      }),
      labelTag: (
        <WeightTag
          weight={requestAttributeWeights.value?.preferredResources}
          onClick={openImportanceDialog}
        />
      ),
      overrideClasses: userOverrideClasses,
      collapsedDetails: preferredResources?.value.length ? (
        preferredResources.value.map(r => (
          <User
            key={r.displayText}
            user={r}
            classes={userClasses}
            showTooltip
          />
        ))
      ) : (
        <span
          className={classNames({
            [classes.expandableError]: requiredFields.preferredResources
          })}
        >
          {intl.formatMessage({
            id: 'resourceRequestDrawerDetails.noPreferredResources'
          })}
        </span>
      ),
      content: (
        <PreferredResourcesInfo
          preferredResources={preferredResources?.value}
          role={role?.value}
          roleRate={roleRate?.value}
          skills={skills?.value}
          setFieldValue={setFieldValue}
          isRequired={requiredFields.preferredResources}
          resourceCostMode={resourceCostMode}
          todayForUser={todayForUser}
        />
      )
    };

    const groupsSection = {
      key: 'groups',
      label: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.groups'
      }),
      collapsedDetails: collapsedGroupDetails,
      content: groupContent
    };

    const tagsInfo = {
      key: 'tags',
      label: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.tags'
      }),
      labelTag: (
        <WeightTag
          weight={requestAttributeWeights.value?.tags}
          onClick={openImportanceDialog}
        />
      ),
      collapsedDetails: tags.value.length ? (
        tags.value
          .map(
            ({ tag, selected }) =>
              `${tag?.name || '-'}: ${selected?.value || '-'}`
          )
          .join(', ')
      ) : (
        <span
          className={classNames({
            [classes.expandableError]: requiredFields.tags
          })}
        >
          {intl.formatMessage({
            id: 'resourceRequestDrawerDetails.noTags'
          })}
        </span>
      ),
      content: (
        <AddTags
          required={requiredFields.tags}
          resourceTags={resourceTags}
          selectedTags={tags.value}
          errors={tags.error}
          setFieldValue={setFieldValue}
          className={classes.tagsDropdown}
        />
      )
    };

    return [
      preferredResourcesInfo,
      canViewSkills ? skillsInfo : null,
      groupsSection.content?.length > 0 ? groupsSection : null,
      canViewTags &&
      (tags.value.length > 0 || (!isTagLoading && resourceTags.length > 0))
        ? tagsInfo
        : null
    ].filter(section => section);
  }, [
    intl,
    requestAttributeWeights.value,
    openImportanceDialog,
    skills.value,
    classes.expandableError,
    classes.skillsDropdown,
    classes.tagsDropdown,
    requiredFields.skills,
    requiredFields.preferredResources,
    requiredFields.tags,
    setFieldValue,
    customLabel,
    userOverrideClasses,
    preferredResources,
    collapsedGroupDetails,
    groupContent,
    tags.value,
    tags.error,
    resourceTags,
    canViewSkills,
    canViewTags,
    isTagLoading,
    userClasses,
    role,
    roleRate,
    resourceCostMode,
    todayForUser
  ]);

  return expandableSections;
};
