import { useCallback, useState, useMemo } from 'react';
import { debounce, scope } from '~/modules/common/debounce';

const searchPhraseDebounceScope = scope();

const DEBOUNCE_TIMEOUT = 250;

const useNavigateTablePages = () => {
  const [page, setPage] = useState(1);
  const [isDebounceActive, setIsDebounceActive] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [localSearchText, setLocalSearchText] = useState('');

  const onNextPage = useCallback(() => {
    setPage(prevPage => prevPage + 1);
  }, []);

  const onPreviousPage = useCallback(() => {
    setPage(prevPage => (prevPage !== 1 ? prevPage - 1 : prevPage));
  }, []);

  const resetSearchState = useCallback(() => {
    setPage(1);
    setLocalSearchText('');
    setSearchText('');
    setIsDebounceActive(false);
  }, []);

  const onFocus = useCallback(() => {
    setIsDebounceActive(true);
  }, []);

  const onBlur = useCallback(phrase => {
    const value = phrase.target.value || '';
    const trimmedValue = value.trim();

    setLocalSearchText(trimmedValue);
    setSearchText(trimmedValue);
    setIsDebounceActive(false);
  }, []);

  const handleSearchChange = useCallback(
    phrase => {
      const value = phrase.target.value || '';

      setLocalSearchText(value);
      debounce(
        searchPhraseDebounceScope,
        () => {
          const trimmedValue = value.trim();

          if (isDebounceActive) {
            setPage(1);
            setLocalSearchText(trimmedValue);
            setSearchText(trimmedValue);
          }

          setIsDebounceActive(true);
        },
        DEBOUNCE_TIMEOUT
      );
    },
    [isDebounceActive]
  );

  return useMemo(
    () => ({
      page,
      localSearchText,
      searchText,
      onNextPage,
      onPreviousPage,
      handleSearchChange,
      resetSearchState,
      setIsDebounceActive,
      onFocus,
      onBlur
    }),
    [
      page,
      localSearchText,
      searchText,
      onNextPage,
      onPreviousPage,
      handleSearchChange,
      resetSearchState,
      onFocus,
      onBlur
    ]
  );
};

export default useNavigateTablePages;
