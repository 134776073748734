import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.h5,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.subtitle1.fontSize
    },
    color: theme.palette.text.primary,
    padding: theme.spacing(1)
  }
}));

const DrawerTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h4">
      {title}
    </Typography>
  );
};

DrawerTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default DrawerTitle;
