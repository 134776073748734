import { makeStyles } from '@material-ui/core/styles';

export const useCaptionStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.secondary,
    lineHeight: 1
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    color: theme.palette.text.secondary,
    lineHeight: 1
  },
  value: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize
  },
  delta: {
    color: theme.palette.common.white,
    borderRadius: '4px',
    background: theme.palette.grey[400],
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.125, 0.75),
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1
  }
}));

export default useStyles;
