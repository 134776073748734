import MuiDrawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentIcon, RefundIcon } from '~/modules/common/components/Icons';
import {
  useAssociatedAndOutStandingBills,
  useBillPaymentTotalProps,
  usePutBillPayment,
  usePutBillPaymentFormState
} from '~/modules/billing-invoicing/billPayment/hooks';
import {
  DrawerFooter,
  DrawerHeader
} from '~/modules/billing-invoicing/common/components';
import {
  useFormOnCancel,
  useSaving,
  useNextReferenceNumberText
} from '~/modules/billing-invoicing/common/hooks';

import { useMeContext } from '~/modules/me/useMeContext';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';
import { BillPaymentDetails } from '../BillPaymentDetails';

const footerResourceKeys = ({ isRefund }) => ({
  actionButton: isRefund ? 'addDialog.addRefund' : 'addDialog.addPayment'
});

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

export const AddBillPaymentDrawer = ({
  open,
  onClose,
  client,
  billBalanceTotal,
  projectUri,
  projectName,
  billId,
  showAllBills,
  isRefund
}) => {
  const paymentIcon = isRefund ? RefundIcon : PaymentIcon;

  const drawerClasses = useDrawerStyles();
  const me = useMeContext();
  const { saving, setSaving } = useSaving();
  const { putBillPayment } = usePutBillPayment();
  const { associatedAndOutstandingBills } = useAssociatedAndOutStandingBills({
    me,
    billBalanceTotal,
    client,
    billId,
    isRefund
  });

  const { nextReferenceNumberText } = useNextReferenceNumberText(
    billingTransactionType.PAYMENT
  );

  const formik = usePutBillPaymentFormState({
    me,
    client,
    setSaving,
    onClose,
    billBalanceTotal,
    associatedAndOutstandingBills,
    billId,
    putBillPayment,
    isRefund,
    nextReferenceNumberText
  });
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    errors,
    isSubmitting
  } = formik;
  const {
    associatedBillsTotal,
    billPaymentBalanceTotal
  } = useBillPaymentTotalProps({ values });
  const { onCancel } = useFormOnCancel({ handleReset, onClose, saving });

  return (
    <MuiDrawer anchor="right" open={open} classes={drawerClasses}>
      <DrawerHeader
        onClose={onCancel}
        entityName={client && client.displayText}
        TitleIcon={paymentIcon}
      >
        <FormattedMessage
          id={isRefund ? 'addDialog.addRefund' : 'addDialog.addPayment'}
        />
      </DrawerHeader>
      <BillPaymentDetails
        me={me}
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        hasClient={Boolean(client && client.id)}
        associatedBillsTotal={associatedBillsTotal}
        billBalanceTotal={billBalanceTotal}
        projectUri={projectUri}
        projectName={projectName}
        billId={billId}
        showAllBills={showAllBills}
        isSubmitting={isSubmitting}
        isRefund={isRefund}
      />
      <DrawerFooter
        balanceTotal={billPaymentBalanceTotal}
        isSaving={saving}
        resourceKeys={footerResourceKeys({ isRefund })}
        onAdd={handleSubmit}
        onCancel={onCancel}
      />
    </MuiDrawer>
  );
};

AddBillPaymentDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  client: PropTypes.object,
  billBalanceTotal: PropTypes.object,
  projectUri: PropTypes.string,
  projectName: PropTypes.string,
  billId: PropTypes.string,
  showAllBills: PropTypes.bool,
  isRefund: PropTypes.bool
};

export default AddBillPaymentDrawer;
