import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogContent,
  DialogActions,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    minHeight: theme.spacing(15),
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center'
  }
}));

export const DialogInitialLoadingContent = ({ onClose }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <CircularProgress />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="bulkRemoveDialog.actions.cancel" />
        </Button>
      </DialogActions>
    </>
  );
};

DialogInitialLoadingContent.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DialogInitialLoadingContent;
