import { useState, useCallback } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { REQUEST_PERIOD_OVERLAP_TYPE } from '../enums/requestPeriodOverlapType';

const setPeriod = ({
  setAllPeriods,
  allPeriods,
  hasPartialPeriod,
  requestedValue,
  setRequestPeriodEditTarget,
  getNextIndex,
  anchorEl,
  periodStartDate,
  periodEndDate
}) => {
  const currentPeriodIndex = allPeriods.findIndex(
    period =>
      period.startDate <= periodStartDate && period.endDate >= periodEndDate
  );
  const requestOverlays = anchorEl
    .closest('.resourceRequestUserAllocation')
    .getElementsByClassName('resourceRequestAllocationOverlay');

  const currentPeriod = allPeriods[currentPeriodIndex];

  if (hasPartialPeriod) {
    setRequestPeriodEditTarget({
      anchorEl: requestOverlays[currentPeriodIndex],
      startDate: currentPeriod.startDate,
      endDate: currentPeriod.endDate,
      totalHours: currentPeriod.totalHours || 0,
      totalProjectDefaultScheduledHours:
        currentPeriod.totalProjectDefaultScheduledHours
    });

    return;
  }

  const periodDetailsWithHours = [...allPeriods];

  periodDetailsWithHours[currentPeriodIndex].totalHours = requestedValue;
  setAllPeriods(periodDetailsWithHours);

  const newIndex = getNextIndex(currentPeriodIndex);
  const isNewIndexOutOfBounds =
    newIndex < 0 || newIndex > periodDetailsWithHours.length - 1;

  if (isNewIndexOutOfBounds) {
    setRequestPeriodEditTarget(null);

    return;
  }

  const nextPeriod = periodDetailsWithHours[newIndex];

  setRequestPeriodEditTarget({
    anchorEl: requestOverlays[newIndex],
    startDate: nextPeriod.startDate,
    endDate: nextPeriod.endDate,
    totalHours: nextPeriod.totalHours || 0,
    totalProjectDefaultScheduledHours:
      nextPeriod.totalProjectDefaultScheduledHours
  });
};

const useRequestTimelineEditorChangeHandlers = ({
  chartDates,
  requestPeriodEditTarget,
  setRequestPeriodEditTarget,
  isPercentageMode,
  resourceRequest,
  onPeriodClose
}) => {
  const [allPeriods, setAllPeriods] = useState(null);

  const handleRequestPeriodClick = useCallback(
    ({ event, requestPeriod, overlayPeriods }) => {
      setAllPeriods(overlayPeriods);

      const {
        startDate,
        endDate,
        totalHours,
        totalProjectDefaultScheduledHours,
        hasRequestStart,
        hasRequestEnd,
        requestPeriodOverlapType
      } = requestPeriod;

      if (!event.currentTarget) {
        return;
      }

      if (isPercentageMode) {
        const requestOverlays = event.currentTarget
          .closest('.resourceRequestUserAllocation')
          .getElementsByClassName('resourceRequestAllocationOverlay');

        const overlayIndex = chartDates.findIndex(chartDate => {
          return startDate >= chartDate.start && startDate <= chartDate.end;
        });

        setRequestPeriodEditTarget({
          anchorEl: requestOverlays[overlayIndex],
          startDate,
          overlayPeriods,
          endDate,
          totalHours,
          totalProjectDefaultScheduledHours
        });

        return;
      }

      const hasRequestPartialStart =
        hasRequestStart &&
        requestPeriodOverlapType === REQUEST_PERIOD_OVERLAP_TYPE.PARTIAL;

      const hasRequestPartialEnd =
        hasRequestEnd &&
        requestPeriodOverlapType === REQUEST_PERIOD_OVERLAP_TYPE.PARTIAL;

      setRequestPeriodEditTarget({
        anchorEl: event.currentTarget,
        startDate: hasRequestPartialStart
          ? mapIsoStringtoUtcObject(resourceRequest.startDate)
          : startDate,
        overlayPeriods,
        endDate: hasRequestPartialEnd
          ? mapIsoStringtoUtcObject(resourceRequest.endDate)
          : endDate,
        totalHours,
        totalProjectDefaultScheduledHours,
        hasRequestPartialStart,
        hasRequestPartialEnd
      });
    },
    [chartDates, isPercentageMode, resourceRequest, setRequestPeriodEditTarget]
  );

  const handleRequestPeriodClose = useCallback(
    ({ request }) => {
      if (request && onPeriodClose) {
        onPeriodClose({ request });
      }

      setRequestPeriodEditTarget(null);
    },
    [onPeriodClose, setRequestPeriodEditTarget]
  );

  const setNextPeriod = useCallback(
    requestedValue => {
      const {
        hasRequestPartialEnd,
        anchorEl,
        startDate,
        endDate
      } = requestPeriodEditTarget;

      setPeriod({
        setAllPeriods,
        allPeriods,
        anchorEl,
        periodStartDate: startDate,
        periodEndDate: endDate,
        requestedValue,
        hasPartialPeriod: hasRequestPartialEnd,
        setRequestPeriodEditTarget,
        getNextIndex: currentPeriodIndex => currentPeriodIndex + 1
      });
    },
    [allPeriods, requestPeriodEditTarget, setRequestPeriodEditTarget]
  );

  const setPreviousPeriod = useCallback(
    requestedValue => {
      const {
        hasRequestPartialStart,
        anchorEl,
        startDate,
        endDate
      } = requestPeriodEditTarget;

      setPeriod({
        setAllPeriods,
        allPeriods,
        anchorEl,
        periodStartDate: startDate,
        periodEndDate: endDate,
        requestedValue,
        hasPartialPeriod: hasRequestPartialStart,
        setRequestPeriodEditTarget,
        getNextIndex: currentPeriodIndex => currentPeriodIndex - 1
      });
    },
    [allPeriods, requestPeriodEditTarget, setRequestPeriodEditTarget]
  );

  return {
    handleRequestPeriodClick,
    handleRequestPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  };
};

export default useRequestTimelineEditorChangeHandlers;
