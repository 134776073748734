import {
  yearWidth,
  quarterWidth,
  monthWidth,
  weekWidth,
  dayWidth
} from './dynamicPosition';

const minWidths = {
  year: yearWidth,
  quarter: quarterWidth,
  month: monthWidth,
  week: weekWidth,
  day: dayWidth
};

const findOptimalWidth = (space, minWidth) => {
  const itemCount = Math.floor(space / minWidth);

  return Math.floor(space / itemCount);
};

const getPeriodCount = (timeWidth, widths) => (breakpoint, scale) => {
  switch (scale) {
    case 'years':
      return Math.floor(timeWidth / widths.year);
    case 'quarters':
      return Math.floor(timeWidth / widths.quarter);
    case 'months':
      return Math.floor(timeWidth / widths.month);
    case 'weeks':
      return Math.floor(timeWidth / widths.week);
    default:
      return Math.floor(timeWidth / widths.day);
  }
};

const createFixedWidthView = (availableWidth, itemWidth) => {
  const cleanedItemWidth =
    typeof itemWidth === 'number' && itemWidth > 0 ? itemWidth : null;

  const timeWidth = Math.max(1, availableWidth - (itemWidth || 0));

  const widths = {
    year: findOptimalWidth(timeWidth, minWidths.year),
    quarter: findOptimalWidth(timeWidth, minWidths.quarter),
    month: findOptimalWidth(timeWidth, minWidths.month),
    week: findOptimalWidth(timeWidth, minWidths.week),
    day: findOptimalWidth(timeWidth, minWidths.day)
  };

  return {
    itemWidth: cleanedItemWidth,
    widths,
    getPeriodCount: getPeriodCount(timeWidth, widths)
  };
};

export default createFixedWidthView;
