import { useCallback, useMemo } from 'react';

export const useAdvancedRateDimensionsGroupHook = ({
  onAddDimension,
  onMenuClose,
  onRemoveDimension,
  projectDimensions,
  availableRateTableDimensions
}) => {
  const onMenuItemClick = useCallback(
    d => () => {
      if (onAddDimension) {
        onAddDimension(d);
      }
      onMenuClose();
    },
    [onAddDimension, onMenuClose]
  );

  const onDeleteClick = useCallback(
    d => () => {
      if (onRemoveDimension) {
        onRemoveDimension(d);
      }
    },
    [onRemoveDimension]
  );
  const options = useMemo(() => {
    const dimensionIdSet = new Set(projectDimensions.map(d => d.id));

    return availableRateTableDimensions.filter(d => !dimensionIdSet.has(d.id));
  }, [projectDimensions, availableRateTableDimensions]);

  return {
    onMenuItemClick,
    onDeleteClick,
    options
  };
};
