import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';

import ExpansionRowLoading from './ExpansionRowLoading';
import ExpansionRow from './ExpansionRow';

const useStyles = makeStyles(theme => ({
  showMoreButton: {
    textTransform: 'none',
    width: '100%'
  }
}));

export const ExpansionPanelDetails = ({
  overbudgetKey,
  items,
  loading,
  loadMore,
  hasMore,
  toKey
}) => {
  const classes = useStyles();

  return (
    <>
      {items.map(x => (
        <ExpansionRow
          projectsCount={x.projectsCount}
          key={x.key}
          label={x.label}
          to={`${toKey}/${x.slug}/projects?overbudgetKey=${overbudgetKey}`}
        />
      ))}
      {loading && <ExpansionRowLoading />}
      {hasMore && !loading && (
        <Button
          className={classes.showMoreButton}
          color="primary"
          onClick={loadMore}
        >
          <FormattedMessage id="clientsOverview.projects.showMore" />
        </Button>
      )}
    </>
  );
};

ExpansionPanelDetails.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      projectsCount: PropTypes.number.isRequired
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  overbudgetKey: PropTypes.string.isRequired,
  toKey: PropTypes.oneOf(['clients', 'programs'])
};

export default ExpansionPanelDetails;
