import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const PUT_PROJECT_REVENUE_POLICY = gql`
  mutation PutProjectRevenuePolicy(
    $projectId: String!
    $contractId: String!
    $scriptId: String
    $scripts: [ProjectScriptParameter]
  ) {
    putProjectRevenueContractDetails2(
      revenueContractDetailsInput: {
        projectId: $projectId
        contractId: $contractId
        scriptId: $scriptId
        scripts: $scripts
      }
    ) {
      project {
        id
      }
      batchUris
    }
  }
`;

export const usePutProjectRevenuePolicy = ({
  projectId,
  contractId,
  setForecastBatchState
}) => {
  const [putProjectRevenueContractDetails] = useMutation(
    PUT_PROJECT_REVENUE_POLICY
  );

  return {
    putProjectRevenueContractDetails: async updatedPolicy => {
      const { id, scripts } = updatedPolicy;

      await putProjectRevenueContractDetails({
        variables: {
          projectId,
          contractId,
          scriptId: id,
          scripts
        },
        update: (
          proxy,
          {
            data: {
              putProjectRevenueContractDetails2: { batchUris }
            }
          }
        ) => {
          setForecastBatchState({
            batchId: batchUris[1],
            batchInProgress: true
          });
        }
      });
    }
  };
};

export default usePutProjectRevenuePolicy;
