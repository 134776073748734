import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import get from 'lodash.get';
import { makeStyles } from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  useTableSettings,
  useSetListSort
} from '~/modules/common/components/ListTable';
import useEnabledBillingTransactionColumns from '~/modules/billing-invoicing/common/components/BillingTransactionTab/BillingTransactionListTable/useEnabledBillingTransactionColumns';
import AccountReceivableQuickFilterSummary from '~/modules/billing-invoicing/common/components/BillingQuickFilterSummary/AccountReceivableQuickFilterSummary';
import { BillStatus } from '~/types';
import BillingQuickFilterSummary from '../BillingQuickFilterSummary';
import { SELECTED_STATUS } from './enums';
import { useBillingTransactionContext } from './BillingTransactionContext';
import {
  useBillingTransactionRows,
  useBillingTransactionTotals,
  useFilterBasedOnSearchCriteria,
  useBillingTransactionRowCounts,
  useBillingTransactionSelectAll
} from './hooks';
import BillingTransactionListTable from './BillingTransactionListTable';
import Toolbar from './Toolbar';
import { SelectionStatusbar } from './components';

const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    left: 0,
    position: 'sticky',
    zIndex: 1000,
    top: theme.spacing(12)
  },
  selectionContainer: {
    marginTop: theme.spacing(6),
    left: 0,
    top: ({ headerLevel }) => theme.spacing((headerLevel + 1) * 6 - 1),
    position: 'sticky',
    zIndex: 1000,
    padding: theme.spacing(1, 0),
    bottom: 0,
    flex: '0 0 auto'
  }
}));
const BILLING_TRANSACTION_TABLE_KEY = 'billing-transaction';
const ACCOUNT_RECEIVABLE_TABLE_KEY = 'account-receivable';
const getSort = ({ currentSort }) => {
  if (!currentSort) {
    return null;
  }

  return {
    field: currentSort.field,
    direction: currentSort.direction
  };
};

export const BillingTransactionTabUtil = ({
  searchFacets,
  selectedColumns,
  searchState,
  canAddPayment,
  isAccountReceivable,
  queryParams,
  isGlobalBilling,
  projectUri,
  clientUri,
  programId,
  billingTransactionStates,
  selectionHandlers,
  tags,
  me,
  headerLevel,
  showAddButtonHandler,
  onRowClick,
  ...rest
}) => {
  const classes = useStyles({ headerLevel });
  const isMobile = useIsBreakpointDown('sm');
  const {
    allSelectedStatus,
    transactionItems,
    setTransactionItems,
    setIncludedTransactionItems,
    isSyncing,
    setIsSyncing,
    includedTransactionItems,
    excludedTransactionItems,
    setExcludedTransactionItems
  } = billingTransactionStates;
  const { onRowSelect, onSelectAll, onReset } = selectionHandlers;
  const {
    enabledBillingTransactionColumnNames
  } = useEnabledBillingTransactionColumns({
    columnClasses: {},
    selectedColumns,
    canAddPayment,
    tags
  });
  const {
    data: tableSettingsData,
    loading: tableSettingsLoading
  } = useTableSettings({
    tableKey: isAccountReceivable
      ? ACCOUNT_RECEIVABLE_TABLE_KEY
      : BILLING_TRANSACTION_TABLE_KEY,
    defaultColumns: enabledBillingTransactionColumnNames
  });
  const currentSort = get(tableSettingsData, 'sort');
  const tableColumns = get(tableSettingsData, 'columns');
  const sort = getSort({ currentSort });
  const { onSortChange, loading: sortLoading } = useSetListSort({
    tableKey: isAccountReceivable
      ? ACCOUNT_RECEIVABLE_TABLE_KEY
      : BILLING_TRANSACTION_TABLE_KEY,
    currentSort,
    currentColumns: tableColumns
  });
  const {
    searchCriteria,
    quickFilter,
    summaryFilterState
  } = useBillingTransactionContext();
  const { billingTransactionFilter } = useFilterBasedOnSearchCriteria({
    searchCriteria,
    quickFilter,
    me
  });

  const billingTransactionAccountReceivableFilter = {
    ...billingTransactionFilter,
    isOverdue: true,
    transactionTypes: ['BILL'],
    balanceStatusList: ['UNALLOCATED', 'PARTIALLY_ALLOCATED'],
    billStatus: [BillStatus.IssuedPartiallypaid, BillStatus.IssuedUnpaid]
  };

  const { isPsaPswatBillingDraftSubStatusEnabled } = me.featureFlags;

  const {
    billingRows,
    hasMoreRows,
    loadMoreRows,
    isRowsLoading,
    isLoadingMore,
    refetch: refetchBillingTransactionRows
  } = useBillingTransactionRows({
    sort,
    projectUri,
    clientUri,
    programId,
    billingTransactionFilter: isAccountReceivable
      ? billingTransactionAccountReceivableFilter
      : billingTransactionFilter,
    tagIds: (tags || []).map(x => x.id),
    isPsaPswatBillingDraftSubStatusEnabled
  });
  const { totals, isTotalsLoading } = useBillingTransactionTotals({
    projectUri,
    clientUri,
    programId,
    billingTransactionFilter: isAccountReceivable
      ? billingTransactionAccountReceivableFilter
      : billingTransactionFilter
  });
  const isListDataLoading = useMemo(
    () =>
      (isRowsLoading && !isLoadingMore) ||
      isTotalsLoading ||
      sortLoading ||
      tableSettingsLoading,
    [
      isLoadingMore,
      isRowsLoading,
      isTotalsLoading,
      sortLoading,
      tableSettingsLoading
    ]
  );
  const { count: transactionItemsCount } = useBillingTransactionRowCounts({
    projectUri,
    clientUri,
    programId,
    billingTransactionFilter
  });

  const { allSelectedCheckboxStatus, selectedTransactionItemsIds } = useMemo(
    () => ({
      allSelectedCheckboxStatus:
        includedTransactionItems.length === 0
          ? SELECTED_STATUS.NONE
          : includedTransactionItems.length === transactionItems.length
          ? SELECTED_STATUS.ALL
          : SELECTED_STATUS.PARTIAL,
      selectedTransactionItemsIds: includedTransactionItems.map(x => x.id)
    }),
    [includedTransactionItems, transactionItems.length]
  );
  const showSelectionStatus = useMemo(
    () => !isListDataLoading && selectedTransactionItemsIds.length,
    [isListDataLoading, selectedTransactionItemsIds.length]
  );

  const newIncludedTransactionItems = useMemo(() => {
    const excludedIds = excludedTransactionItems.map(x => x.id);

    return (billingRows || []).filter(x => !excludedIds.includes(x.id));
  }, [billingRows, excludedTransactionItems]);

  useBillingTransactionSelectAll({
    newIncludedTransactionItems,
    setTransactionItems,
    setIncludedTransactionItems,
    isListDataLoading,
    allSelectedStatus,
    billingRows,
    selectedTransactionItemsIds,
    showAddButtonHandler,
    setExcludedTransactionItems
  });

  const onRowClickHandler = useCallback(
    record => {
      onReset();
      onRowClick(record);
    },
    [onReset, onRowClick]
  );

  return (
    <>
      {
        <div className={classes.toolbarContainer}>
          <Toolbar
            searchFacets={searchFacets}
            isAccountReceivable={isAccountReceivable}
          />
        </div>
      }
      {Boolean(searchFacets) &&
        (!isAccountReceivable ? (
          <BillingQuickFilterSummary
            projectUri={projectUri}
            clientUri={clientUri}
            programId={programId}
            searchCriteria={searchCriteria}
            summaryFilterState={summaryFilterState}
            onResetSelection={onReset}
            isMobile={isMobile}
            {...rest}
          />
        ) : (
          <></>
        ))}

      {Boolean(searchFacets) &&
        (isAccountReceivable ? (
          <AccountReceivableQuickFilterSummary
            projectUri={projectUri}
            clientUri={clientUri}
            programId={programId}
            searchCriteria={searchCriteria}
            summaryFilterState={summaryFilterState}
            isMobile={isMobile}
            {...rest}
          />
        ) : (
          <></>
        ))}
      <BillingTransactionListTable
        sort={sort}
        onSortChange={onSortChange}
        isLoading={isListDataLoading}
        selectedColumns={selectedColumns}
        canAddPayment={canAddPayment}
        selectedTransactionItems={selectedTransactionItemsIds}
        allSelectedStatus={allSelectedCheckboxStatus}
        onRowSelect={onRowSelect}
        onSelectAll={onSelectAll}
        billingRows={billingRows}
        refetchBillingTransactionRows={refetchBillingTransactionRows}
        searchCriteria={searchCriteria}
        hasMoreRows={hasMoreRows}
        loadMoreRows={loadMoreRows}
        totals={totals}
        isMobile={isMobile}
        tags={tags}
        headerLevel={headerLevel}
        onRowClick={onRowClickHandler}
        summaryFilterState={summaryFilterState}
        {...rest}
      />
      <>
        {showSelectionStatus ? (
          <div className={classes.selectionContainer}>
            <SelectionStatusbar
              selectedTransactionItems={includedTransactionItems}
              allSelectedStatus={allSelectedStatus}
              onReset={onReset}
              billingTransactionFilter={billingTransactionFilter}
              setIsSyncing={setIsSyncing}
              isSyncing={isSyncing}
              excludedTransactionItems={excludedTransactionItems}
              totalTransactionItemsCount={transactionItemsCount}
              transactionItems={transactionItems}
            />
          </div>
        ) : null}
      </>
    </>
  );
};

BillingTransactionTabUtil.propTypes = {
  searchState: PropTypes.string,
  searchFacets: PropTypes.array,
  quickFilterKey: PropTypes.string,
  summaryFilterState: PropTypes.object,
  selectedColumns: PropTypes.array,
  canAddPayment: PropTypes.bool,
  isAccountReceivable: PropTypes.bool,
  queryParams: PropTypes.object,
  isGlobalBilling: PropTypes.bool,
  projectUri: PropTypes.string,
  clientUri: PropTypes.string,
  programId: PropTypes.string,
  billingTransactionStates: PropTypes.object,
  selectionHandlers: PropTypes.object,
  me: PropTypes.object,
  tags: PropTypes.array,
  showAddButtonHandler: PropTypes.func,
  headerLevel: PropTypes.number,
  onRowClick: PropTypes.func
};

export default BillingTransactionTabUtil;
