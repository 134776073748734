import React from 'react';
import { PropTypes } from 'prop-types';
import NotAuthorizedPage from '~/modules/common/notAuthorized';
import NotFoundPage from '~/modules/common/notfound';
import { getFirstErrorCode } from '~/modules/common/graphql/errors';
import Drawer, { DrawerHeader, DrawerContent } from '../Drawer';

const ErrorDrawer = ({ onClose, data }) => {
  const { error } = data;

  const errorCode = getFirstErrorCode(error);

  const ErrorContent =
    errorCode === 'AUTHORIZATION_ERROR' ? NotAuthorizedPage : NotFoundPage;

  return (
    <Drawer>
      <DrawerHeader onClose={onClose} />
      <DrawerContent>
        <ErrorContent />
      </DrawerContent>
    </Drawer>
  );
};

ErrorDrawer.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object
};

export default ErrorDrawer;
