import React, { useMemo } from 'react';

const DefaultRenderer = () => (
  <div>
    <i>Not Yet Supported</i>
  </div>
);

export const useColumns = ({
  columns,
  renders,
  defaultRender = DefaultRenderer
}) => {
  const visibleColumns = useMemo(
    () =>
      Object.keys(columns || {})
        .filter(col => columns[col] && columns[col].visible)
        .reduce(
          (cols, col) => ({
            ...cols,
            [col]: columns[col]
          }),
          {}
        ),
    [columns]
  );

  const columnsWithRenderers = useMemo(
    () =>
      Object.keys(visibleColumns || {}).reduce(
        (cols, field) => ({
          ...cols,
          [field]: {
            ...visibleColumns[field],
            render: renders[field] ? renders[field] : defaultRender
          }
        }),
        {}
      ),
    [defaultRender, renders, visibleColumns]
  );

  return columnsWithRenderers;
};
