import { ResourceCostModeType } from '~/types';
import getAllocationsTotalForResourceRequest from '../hooks/getAllocationsTotalForResourceRequest';
import getUpdatedCostByCurrency from '../hooks/getUpdatedCostByCurrency';
import { getCostAndHoursDelta } from '../util/getCostAndHoursDelta';
import {
  invokeUpdateResourceAllocationAggregateCache,
  readAllocatedTotalCostAndTotalHoursFromCache,
  writeAllocatedTotalCostAndTotalHoursToCache
} from './updateAllocationTotalsCache';

export const processAllocationAndUpdateCache = ({
  proxy,
  allocation,
  me,
  filter,
  invokeUpdateFunction,
  isResourceActualModeEnabled
}) => {
  if (!proxy) return {};

  const isRoleBasedCost =
    me?.resourceCostMode === ResourceCostModeType.Rolecost;

  const totalAllocatedCostAndHoursFromCache = readAllocatedTotalCostAndTotalHoursFromCache(
    {
      proxy,
      allocation,
      resourceCostMode: me?.resourceCostMode
    }
  );

  if (!totalAllocatedCostAndHoursFromCache) {
    return {};
  }

  const {
    totalAllocatedCostForResourceRequest,
    totalAllocatedHoursForResourceRequest: newTotalHours
  } = getAllocationsTotalForResourceRequest({
    me,
    resourceAllocations: [allocation],
    contextCurrency:
      allocation.exchangeRateValues?.projectBudgetCurrency ||
      allocation.exchangeRateValues?.baseCurrency
  });

  const newTotalCost = getUpdatedCostByCurrency({
    exchangeRateValues: {
      baseCurrency: isRoleBasedCost
        ? allocation.roleCostExchangeRateValues?.baseCurrency
        : allocation.exchangeRateValues?.baseCurrency,
      projectBudgetCurrency: isRoleBasedCost
        ? allocation.roleCostExchangeRateValues?.projectBudgetCurrency
        : allocation.exchangeRateValues?.projectBudgetCurrency
    },
    newTotalCost: totalAllocatedCostForResourceRequest[0],
    isAllocation: true
  });

  const updatedAllocation = isRoleBasedCost
    ? {
        ...allocation,
        totalRoleCostByCurrency: newTotalCost
      }
    : {
        ...allocation,
        totalUserCostByCurrency: newTotalCost
      };

  const updatedData = isRoleBasedCost
    ? {
        ...totalAllocatedCostAndHoursFromCache,
        totalRoleCostByCurrency: newTotalCost,
        totalHours: newTotalHours
      }
    : {
        ...totalAllocatedCostAndHoursFromCache,
        totalUserCostByCurrency: newTotalCost,
        totalHours: newTotalHours
      };

  writeAllocatedTotalCostAndTotalHoursToCache({
    proxy,
    allocation,
    updatedData,
    resourceCostMode: me?.resourceCostMode
  });

  const initialTotalCostObject = isRoleBasedCost
    ? totalAllocatedCostAndHoursFromCache.totalRoleCostByCurrency
    : totalAllocatedCostAndHoursFromCache.totalUserCostByCurrency;

  const costAndHoursDelta = getCostAndHoursDelta({
    newTotalCost:
      newTotalCost?.projectBudgetCurrency || newTotalCost?.baseCurrency,
    initialTotalCost:
      initialTotalCostObject?.projectBudgetCurrency ||
      initialTotalCostObject?.baseCurrency,
    newHours: newTotalHours,
    initialHours: totalAllocatedCostAndHoursFromCache.totalHours
  });

  if (costAndHoursDelta && Object.keys(costAndHoursDelta).length > 0) {
    if (invokeUpdateFunction) {
      invokeUpdateFunction({
        proxy,
        projectId: allocation.projectUri,
        costAndHoursDelta,
        filter: filter || {},
        isResourceActualModeEnabled
      });
    } else {
      invokeUpdateResourceAllocationAggregateCache({
        proxy,
        projectId: allocation.projectUri,
        costAndHoursDelta,
        filter: filter || {}
      });
    }
  }

  const result = {
    updatedAllocation,
    totalHours: newTotalHours,
    costAndHoursDelta
  };

  isRoleBasedCost
    ? (result.totalRoleCostByCurrency = newTotalCost)
    : (result.totalUserCostByCurrency = newTotalCost);

  return result;
};
