import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const QuickAllocationIcon = React.forwardRef((props, ref) => (
  <SvgIcon ref={ref}>
    <>
      <path d="M 2 4 L 2 20 L 16 20 L 16 11 L 22 11 L 22 6 L 12 6 L 10 4 L 2 4 z M 9 9 C 10.1 9 11 9.9 11 11 C 11 12.1 10.1 13 9 13 C 7.9 13 7 12.1 7 11 C 7 9.9 7.9 9 9 9 z M 9 14 C 10.33 14 13 14.67 13 16 L 13 17 L 5 17 L 5 16 C 5 14.67 7.67 14 9 14 z " />
      <path d="M 21.3,17 23,13 h -5 v 6 h 2 v 5 l 3.5,-7 z" />
    </>
  </SvgIcon>
));

export default QuickAllocationIcon;
