import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CREATE_PROJECT_STATUS_CHANGE_BATCH_MUTATION = gql`
  mutation CreateProjectInfoModificationBatch(
    $projectIds: [String!]!
    $projectWorkflowState: String!
  ) {
    createProjectInfoModificationBatch2(
      projectIds: $projectIds
      projectWorkflowState: $projectWorkflowState
    )
  }
`;

export const useCreateBulkProjectStatusChangeBatch = ({
  projectIds,
  setBatchState,
  projectWorkflowState
}) => {
  const [statusChangeBatch] = useMutation(
    CREATE_PROJECT_STATUS_CHANGE_BATCH_MUTATION
  );

  return useCallback(async () => {
    setBatchState({ batchId: null, batchInProgress: true });
    const { data, error } = await statusChangeBatch({
      variables: {
        projectIds,
        projectWorkflowState
      }
    });

    const batchId = data?.createProjectInfoModificationBatch2;

    setBatchState({ batchId, batchInProgress: !error });
  }, [projectIds, projectWorkflowState, setBatchState, statusChangeBatch]);
};

export default useCreateBulkProjectStatusChangeBatch;
