import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import getFormatters from './formatters';

export const buildColumns = ({
  classes,
  resourceKeys,
  onAmountChange,
  onAllocatedAmountChange,
  isAllocatedBillAmountColumnVisible,
  errors,
  isSubmitting,
  isRefund
}) => ({
  displayText: {
    visible: true,
    value: <FormattedMessage id="outstandingBillsTable.referenceNumber" />,
    className: classes.displayText
  },
  dueDate: {
    visible: true,
    value: <FormattedMessage id="outstandingBillsTable.dueDate" />
  },
  balanceAmount: {
    visible: true,
    value: <FormattedMessage id="outstandingBillsTable.balance" />,
    align: 'right',
    className: classes.balanceAmount
  },
  allocatedBillAmount: {
    id: 'allocatedBillAmount',
    visible: isAllocatedBillAmountColumnVisible,
    value: (
      <>
        <FormattedMessage id="outstandingBillsTable.amount" />
        <div className={classes.subTitle}>
          <FormattedMessage id="outstandingBillsTable.billCurrency" />
        </div>
      </>
    ),
    align: 'right',
    className: classes.allocatedBillAmount,
    onAllocatedAmountChange,
    targetFieldName: 'amount',
    errors,
    errorKey: 'associatedBills',
    ariaLabel: resourceKeys.allocatedBillAmountAriaLabel,
    buttonAriaLabel: resourceKeys.buttonAriaLabel,
    isSubmitting
  },
  amount: {
    id: 'amount',
    visible: true,
    value: <FormattedMessage id="outstandingBillsTable.amount" />,
    align: 'right',
    className: classes.amount,
    onAmountChange,
    errors,
    errorKey: 'associatedBills',
    ariaLabel: resourceKeys.amountAriaLabel,
    isSubmitting,
    allowNegative: isRefund
  }
});

export const useListColumns = ({
  classes,
  resourceKeys,
  onAmountChange,
  onAllocatedAmountChange,
  isAllocatedBillAmountColumnVisible,
  errors,
  editable,
  isSubmitting,
  isRefund
}) =>
  useColumns({
    columns: buildColumns({
      classes,
      resourceKeys,
      onAmountChange,
      onAllocatedAmountChange,
      isAllocatedBillAmountColumnVisible,
      errors,
      isSubmitting,
      isRefund
    }),
    renders: getFormatters(editable)
  });
