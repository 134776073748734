import { withStyles } from '@material-ui/core/styles';

export const styles = theme => ({
  subheader: {
    minWidth: theme.spacing(35),
    backgroundColor: theme.palette.background.paper,
    lineHeight: `${theme.spacing(4)}px`,
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex'
  },
  itemLabel: {
    flexGrow: 1
  },
  itemBalance: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 0, 3)
  },
  contentBalance: {
    color: theme.palette.text.secondary,
    margin: '0px 0px 0px 24px',
    'font-size': theme.typography.caption.fontSize
  },
  addMenuItem: {
    color: theme.palette.primary.main
  },
  addIconButton: {
    marginTop: theme.spacing(1.5)
  }
});

export default withStyles(styles);
