import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ListItemText } from '@material-ui/core';
import { uniqBy } from 'lodash';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { useDropdownStyles } from './useStyles';
import { ADD_RESOURCE_POOL } from './resourcePoolsReducer';
import useAvailableUserResourcePools from './hooks/useAvailableResourcePoolsForUser';

const getOptionLabel = option => option.displayText;

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const filterSelectedOptions = selectedResourcePools => options =>
  options.filter(
    option =>
      !selectedResourcePools.some(
        selectedOption => selectedOption.id === option.id
      )
  );

export const ResourcePoolUserDropdown = ({
  userId,
  dispatch,
  resourcePoolsState
}) => {
  const { formatMessage } = useIntl();
  const classes = useDropdownStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    resourcePools: dropdownResourcePools,
    loading,
    hasMore
  } = useAvailableUserResourcePools({
    userId,
    searchFilter: { searchTextParam: searchTerm, includeOnlyEnabled: true }
  });

  const uniqueDropdownResourcePools = useMemo(
    () =>
      uniqBy(
        [
          ...dropdownResourcePools,
          ...resourcePoolsState.unselectedResourcePools
        ],
        'id'
      ),
    [dropdownResourcePools, resourcePoolsState.unselectedResourcePools]
  );

  const resourcePoolDropdownOptions = loading
    ? []
    : searchTerm
    ? dropdownResourcePools
    : uniqueDropdownResourcePools;

  const optionText = {
    loading: formatMessage({ id: 'groupsDropdown.loading' }),
    noOptions: formatMessage({
      id: 'resourcePoolsDropdown.noResourcePools'
    })
  };

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const renderOption = useCallback(
    option => {
      if (option.id === MORE_AVAILABLE_OPTION_ID) {
        return option.displayText;
      }

      return (
        <ListItemText
          primary={option.displayText}
          className={classes.primary}
          classes={classes}
        />
      );
    },
    [classes]
  );

  const onChange = useCallback(
    (option, event, reason) => {
      if (option && reason === 'select-option') {
        dispatch({
          type: ADD_RESOURCE_POOL,
          pool: option
        });
        setSearchTerm('');
      }
    },
    [dispatch, setSearchTerm]
  );

  return (
    <SimpleAutocomplete
      className={classes.root}
      dataQeId="resource-pools-user-dropdown"
      loading={loading}
      options={resourcePoolDropdownOptions}
      size="small"
      loadingText={optionText.loading}
      noOptionsText={optionText.noOptions}
      fullWidth
      autoFocus
      openOnFocus
      value={null}
      inputValue={searchTerm}
      onInputChange={onInputChange}
      variant="outlined"
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      optionPropText="displayText"
      getOptionDisabled={getOptionDisabled}
      hasMore={hasMore}
      optionTypeText={formatMessage({
        id: 'moreOptions.resourcePools'
      })}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      filterOptions={filterSelectedOptions(
        resourcePoolsState.selectedResourcePools
      )}
      inputLabel={formatMessage({
        id: 'resourcePoolsDropdown.resourcePools'
      })}
    />
  );
};

ResourcePoolUserDropdown.propTypes = {
  userId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  resourcePoolsState: PropTypes.shape({
    dropdownResourcePools: PropTypes.array,
    selectedResourcePools: PropTypes.array,
    unselectedResourcePools: PropTypes.array,
    currentPageOfResourcePools: PropTypes.array,
    fetchedResourcePools: PropTypes.array
  })
};

export default ResourcePoolUserDropdown;
