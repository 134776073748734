import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useHoverSupported } from '~/modules/common/hooks';
import AvailabilityTooltipContent from './AvailabilityTooltipContent';

export const AvailabilityTooltip = ({
  resourceCount,
  availableFte,
  availableHours,
  availabilityPercentage,
  allocatedFte,
  allocatedHours,
  allocatedPercentage,
  scheduledHours,
  holidayFte,
  holidayHours,
  holidayPercentage,
  timeOffFte,
  timeOffHours,
  timeOffPercentage,

  overAllocatedFte,
  overAllocatedHours,
  overAllocatedPercentage,
  children
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const onMouseEnter = useCallback(() => setShowTooltip(true), [
    setShowTooltip
  ]);
  const onMouseLeave = useCallback(() => setShowTooltip(false), [
    setShowTooltip
  ]);

  if (useHoverSupported())
    return (
      <Tooltip
        title={
          showTooltip ? (
            // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
            <AvailabilityTooltipContent
              resourceCount={resourceCount}
              availableFte={availableFte}
              availableHours={availableHours}
              availabilityPercentage={availabilityPercentage}
              allocatedFte={allocatedFte}
              allocatedHours={allocatedHours}
              allocatedPercentage={allocatedPercentage}
              scheduledHours={scheduledHours}
              holidayFte={holidayFte}
              holidayHours={holidayHours}
              holidayPercentage={holidayPercentage}
              timeOffFte={timeOffFte}
              timeOffHours={timeOffHours}
              timeOffPercentage={timeOffPercentage}
              overAllocatedFte={overAllocatedFte}
              overAllocatedHours={overAllocatedHours}
              overAllocatedPercentage={overAllocatedPercentage}
            />
          ) : (
            ''
          )
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </Tooltip>
    );

  return children;
};

AvailabilityTooltip.propTypes = {
  resourceCount: PropTypes.number,
  availableFte: PropTypes.number.isRequired,
  availableHours: PropTypes.number.isRequired,
  availabilityPercentage: PropTypes.number.isRequired,
  allocatedFte: PropTypes.number.isRequired,
  allocatedHours: PropTypes.number.isRequired,
  allocatedPercentage: PropTypes.number.isRequired,
  scheduledHours: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  holidayFte: PropTypes.number.isRequired,
  holidayHours: PropTypes.number.isRequired,
  holidayPercentage: PropTypes.number.isRequired,
  timeOffFte: PropTypes.number.isRequired,
  timeOffHours: PropTypes.number.isRequired,
  timeOffPercentage: PropTypes.number.isRequired,
  overAllocatedFte: PropTypes.number.isRequired,
  overAllocatedHours: PropTypes.number.isRequired,
  overAllocatedPercentage: PropTypes.number.isRequired
};

export default AvailabilityTooltip;
