import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useMeContext from '~/modules/me/useMeContext';

export const useGroupItems = items => {
  const {
    legacyDepartmentDisabled,
    legacyEmployeeTypeDisabled,
    groupSettings
  } = useMeContext();

  const {
    location: locationSettings,
    division: divisionSettings,
    serviceCenter: serviceCenterSettings,
    costCenter: costCenterSettings,
    department: departmentSettings,
    employeeType: employeeTypeSettings
  } = groupSettings;
  const { formatMessage } = useIntl();

  const groupItemMap = useMemo(
    () => ({
      serviceCenter: {
        isEnabled: serviceCenterSettings.isEnabled,
        label:
          serviceCenterSettings.singularName ||
          formatMessage({ id: 'groups.serviceCenter' })
      },
      location: {
        isEnabled: locationSettings.isEnabled,
        label:
          locationSettings.singularName ||
          formatMessage({ id: 'groups.location' })
      },
      costCenter: {
        isEnabled: costCenterSettings.isEnabled,
        label:
          costCenterSettings.singularName ||
          formatMessage({ id: 'groups.costCenter' })
      },
      department: {
        isEnabled: legacyDepartmentDisabled && departmentSettings.isEnabled,
        label:
          departmentSettings.singularName ||
          formatMessage({ id: 'groups.department' })
      },
      employeeType: {
        isEnabled: legacyEmployeeTypeDisabled && employeeTypeSettings.isEnabled,
        label:
          employeeTypeSettings.singularName ||
          formatMessage({ id: 'groups.employeeType' })
      },
      division: {
        isEnabled: divisionSettings.isEnabled,
        label:
          divisionSettings.singularName ||
          formatMessage({ id: 'groups.division' })
      }
    }),
    [
      serviceCenterSettings.isEnabled,
      serviceCenterSettings.singularName,
      formatMessage,
      locationSettings.isEnabled,
      locationSettings.singularName,
      costCenterSettings.isEnabled,
      costCenterSettings.singularName,
      legacyDepartmentDisabled,
      departmentSettings.isEnabled,
      departmentSettings.singularName,
      legacyEmployeeTypeDisabled,
      employeeTypeSettings.isEnabled,
      employeeTypeSettings.singularName,
      divisionSettings.isEnabled,
      divisionSettings.singularName
    ]
  );

  return useMemo(
    () =>
      Object.keys(items)
        .reduce(
          (acc, key) => [
            ...acc,
            {
              element: items[key].Component,
              key,
              label: groupItemMap[key] ? groupItemMap[key].label : key,
              value: items[key].value
            }
          ],
          []
        )
        .filter(x => x),
    [groupItemMap, items]
  );
};

export default useGroupItems;
