import { useState, useMemo } from 'react';
import { useHasPermission } from '~/modules/common/permissions';
import { BILLING_PLAN_SLUG_ENUM } from '~/modules/projects/project/common/enums';

const hasNoTasksPermissions = (canViewTasks, canEditTasks, slug) =>
  !canViewTasks &&
  !canEditTasks &&
  slug === BILLING_PLAN_SLUG_ENUM.fixedBidMilestone;

const hasNoBillingRatePermissions = (
  canViewBillingRates,
  canEditBillingRates,
  slug
) =>
  !canViewBillingRates &&
  !canEditBillingRates &&
  slug === BILLING_PLAN_SLUG_ENUM.timeBilling;

export const useScriptParamsEditableModifier = ({ scriptDetails }) => {
  const [
    showScriptParamsEditorTable,
    setShowScriptParamsEditorTable
  ] = useState(true);

  const [saveable, setSaveable] = useState(true);

  const canViewBillingRates = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-billing-rates'
  });

  const canEditBillingRates = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-billing-rates'
  });

  const canViewTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-tasks'
  });

  const canEditTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-tasks'
  });

  useMemo(() => {
    if (
      hasNoTasksPermissions(canViewTasks, canEditTasks, scriptDetails.slug) ||
      hasNoBillingRatePermissions(
        canViewBillingRates,
        canEditBillingRates,
        scriptDetails.slug
      )
    ) {
      setShowScriptParamsEditorTable(false);
      setSaveable(false);
    }
  }, [
    canEditBillingRates,
    canEditTasks,
    canViewBillingRates,
    canViewTasks,
    scriptDetails.slug
  ]);

  return {
    saveable,
    showScriptParamsEditorTable
  };
};

export default useScriptParamsEditableModifier;
