import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { mapToProjectRates } from '~/modules/rateCard/enhancers';

export const GET_PROJECT_RATES = gql`
  query getAllProjectRates(
    $projectId: String!
    $roleRatePage: Int
    $roleRatePageSize: Int
    $resourceRatePage: Int
    $resourceRatePageSize: Int
  ) {
    project(projectId: $projectId) {
      id
      projectRate: projectRate2 {
        id
        billingScheduleEntries {
          effectiveDate: effectiveDate2
          amount {
            currency {
              id
              symbol
              displayText
            }
            amount
          }
        }
      }
      roleRates: roleRates2(page: $roleRatePage, pageSize: $roleRatePageSize) {
        id
        roleReference {
          id
          displayText
        }
        billingScheduleEntries {
          effectiveDate: effectiveDate2
          amount {
            currency {
              id
              symbol
              displayText
            }
            amount
          }
        }
      }
      roleRatesCount: roleRatesCount2
      userRatesCount: userRatesCount2
      userRates: userRates2(
        page: $resourceRatePage
        pageSize: $resourceRatePageSize
      ) {
        id
        userReference {
          id
          displayText
        }
        billingScheduleEntries {
          effectiveDate: effectiveDate2
          amount {
            currency {
              id
              symbol
              displayText
            }
            amount
          }
        }
      }
    }
  }
`;

export const useAllProjectRates = ({
  projectId,
  roleRatePage = 1,
  roleRatePageSize = 10,
  resourceRatePage = 1,
  resourceRatePageSize = 10
}) => {
  const { data, loading, fetchMore, variables, error, refetch } = useQuery(
    GET_PROJECT_RATES,
    {
      variables: {
        projectId,
        roleRatePage,
        roleRatePageSize,
        resourceRatePage,
        resourceRatePageSize
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );
  const projectDetails = get(data, `project`, {});

  return {
    ...mapToProjectRates(projectDetails),
    hasMoreRoleRateRows:
      projectDetails &&
      getRatesCount(projectDetails.roleRates) < projectDetails.roleRatesCount,
    hasMoreResourceRateRows:
      projectDetails &&
      getRatesCount(projectDetails.userRates) < projectDetails.userRatesCount,
    roleRatesCount: projectDetails && projectDetails.roleRatesCount,
    userRatesCount: projectDetails && projectDetails.userRatesCount,
    loadMoreRows: async ({ rateTypeKey }) => {
      await loadMoreRows({
        rateTypeKey,
        fetchMore,
        variables,
        projectDetails
      });
    },
    hasServiceError: Boolean(error),
    isProjectRatesLoading: loading,
    refetchProjectRates: refetch
  };
};

const loadMoreRows = async ({
  rateTypeKey,
  fetchMore,
  variables,
  projectDetails
}) => {
  await fetchMore({
    query: GET_PROJECT_RATES,
    variables: {
      ...variables,
      roleRatePage:
        rateTypeKey === 'roleRates'
          ? Math.ceil(
              getRatesCount(projectDetails.roleRates || []) /
                variables.roleRatePageSize
            ) + 1
          : -1,
      resourceRatePage:
        rateTypeKey === 'userRates'
          ? Math.ceil(
              getRatesCount(projectDetails.userRates || []) /
                variables.resourceRatePageSize
            ) + 1
          : -1
    },
    updateQuery: (previousResult, { fetchMoreResult: { project } }) => ({
      ...previousResult,
      project: {
        ...previousResult.project,
        [rateTypeKey]: mergeAndGroupRates(
          previousResult.project[rateTypeKey],
          project[rateTypeKey],
          rateTypeKey === 'roleRates' ? 'roleReference' : 'userReference'
        )
      }
    })
  });
};

export const getUniqueSchedules = (a, b) => {
  const schedules = [...a, ...b];

  return schedules.filter(
    (x, index) =>
      schedules.findIndex(
        y =>
          x.__typename === y.__typename && x.effectiveDate === y.effectiveDate
      ) === index
  );
};

export const mergeAndGroupRates = (
  previousResult = [],
  nextResult = [],
  reference
) => {
  const newRates = [...previousResult, ...nextResult].reduce((res, curr) => {
    if (res[curr[reference].id]) {
      res[curr[reference].id] = {
        id: curr.id,
        [reference]: curr[reference],
        billingScheduleEntries: getUniqueSchedules(
          res[curr[reference].id].billingScheduleEntries,
          curr.billingScheduleEntries
        )
      };
    } else {
      res[curr[reference].id] = {
        id: curr.id,
        [reference]: curr[reference],
        billingScheduleEntries: curr.billingScheduleEntries
      };
    }

    return res;
  }, {});

  const result = Object.keys(newRates).map(ref => ({
    __typename: 'ProjectRate',
    id: newRates[ref].id,
    billingScheduleEntries: newRates[ref].billingScheduleEntries,
    [reference]: newRates[ref][reference]
  }));

  return result;
};

export const getRatesCount = rates =>
  (rates || []).reduce(
    (retValue, currentValue) =>
      retValue + currentValue.billingScheduleEntries.length,
    0
  );
