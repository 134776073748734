import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  primaryMessage: {
    color: theme.palette.text.primary
  },
  secondaryMessage: {
    lineHeight: 1.2
  },
  details: {
    ...theme.typography.caption
  }
}));

const resourceKeys = {
  header: 'billDetails.currencyChangeConfirmationDialog.header',
  actionButton: 'billDetails.currencyChangeConfirmationDialog.actionButton',
  confirmationMessage:
    'billDetails.currencyChangeConfirmationDialog.confirmationMessage',
  lineItemsConfirmation:
    'billDetails.currencyChangeConfirmationDialog.lineItemsConfirmation'
};

export const CurrencyChangeConfirmationDialog = ({
  openDialog,
  onDialogClose,
  onConfirmClick,
  hasLineItems,
  billCurrency,
  newBillCurrency
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openDialog}
      onClose={onDialogClose}
      aria-labelledby="entity-confirmation-dialog-title"
      aria-describedby="entity-confirmation-dialog-description"
    >
      <DialogTitle id="entity-confirmation-dialog-title">
        <FormattedMessage id={resourceKeys.header} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="bill-change-confirmation-dialog-description"
          className={classes.primaryMessage}
        >
          <FormattedMessage
            id={resourceKeys.confirmationMessage}
            values={{
              sourceCurrency: <strong>{billCurrency.symbol}</strong>,
              destinationCurrecy: <strong>{newBillCurrency.symbol}</strong>
            }}
          />
        </DialogContentText>
        {hasLineItems && (
          <DialogContentText className={classes.secondaryMessage}>
            <FormattedMessage
              id={resourceKeys.lineItemsConfirmation}
              values={{
                currency: <strong>{newBillCurrency.symbol}</strong>
              }}
            />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button data-qe-id="CancelDialogButton" onClick={onDialogClose}>
          <FormattedMessage id="resourceRequestActions.cancel" />
        </Button>
        <Button data-qe-id="ConfirmDialogButton" onClick={onConfirmClick}>
          <FormattedMessage id={resourceKeys.actionButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CurrencyChangeConfirmationDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func,
  onConfirmClick: PropTypes.func,
  hasLineItems: PropTypes.bool,
  billCurrency: PropTypes.object,
  newBillCurrency: PropTypes.object.isRequired
};

export default CurrencyChangeConfirmationDialog;
