/* eslint-disable react/jsx-max-depth */
import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import Skeleton from '@material-ui/lab/Skeleton';
import { NoDataItem } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';
import SkillsForm from '~/modules/resourcing/common/components/Skills/SkillsForm';
import SkillCategory from '~/modules/resourcing/common/components/Skills/SkillCategory';
import SkillsSelectDropdown from '~/modules/resourcing/common/components/Skills/SkillsSelectDropdown';
import MobileSkillsEditable from './SkillsMobile/MobileSkillsEditable';
import { useSkillSettings, useFormOnChangeHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  noSkill: {
    paddingTop: theme.spacing(1)
  },
  assignSkillMessage: {
    paddingTop: theme.spacing(1)
  },
  skillCell: {
    minWidth: '300px'
  },
  cell: {
    minWidth: '140px',
    textAlign: 'center'
  },
  select: {
    zIndex: 2,
    position: 'sticky !important',
    left: 0
  }
}));

export const SkillsEditable = ({
  classes: classesOverride,
  userDetails,
  certificates,
  skillLevels,
  maxSkillLevel
}) => {
  const classes = useStyles({ classes: classesOverride });
  const isMobile = useIsBreakpointDown('sm');
  const { values, setFieldValue } = useFormikContext();
  const { error, skillSettings, isSkillSettingsLoading } = useSkillSettings();
  const {
    onSkillAdd,
    onSkillRemove,
    onSkillsSelected,
    onCategoryRemove,
    onSkillRatingUpdate,
    onCertificateUpdate,
    onCustomMetadataUpdate
  } = useFormOnChangeHandlers({
    values,
    skillLevels,
    setFieldValue
  });

  const { categories, skills } = values;

  if (error) return <RedirectToUnhandledError />;

  if (isSkillSettingsLoading) return <Skeleton width="100%" height={40} />;

  if (isMobile)
    return (
      <MobileSkillsEditable
        skills={skills}
        onSkillAdd={onSkillAdd}
        userDetails={userDetails}
        certificates={certificates}
        skillSetting={skillSettings}
        maxSkillLevel={maxSkillLevel}
        onSkillRemove={onSkillRemove}
        onCategoryRemove={onCategoryRemove}
        onSkillsSelected={onSkillsSelected}
        onSkillRatingUpdate={onSkillRatingUpdate}
        onCertificateUpdate={onCertificateUpdate}
        onCustomMetadataUpdate={onCustomMetadataUpdate}
      />
    );

  return (
    <>
      <SkillsSelectDropdown
        value={skills.map(u => ({
          ...u,
          value: u.uri,
          label: u.displayText
        }))}
        onChange={onSkillsSelected}
        onSkillAdd={onSkillAdd}
        classes={classes}
      />
      {skills.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.skillCell} />
              <TableCell className={classes.cell}>
                <FormattedMessage id="skillInfoCard.level" />
              </TableCell>
              <TableCell className={classes.cell}>
                <FormattedMessage id="skillInfoCard.certificate" />
              </TableCell>
              {skillSettings.map(setting => (
                <TableCell className={classes.cell} key={setting.id}>
                  <span>{setting.displayText}</span>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map(category => (
              <Fragment key={category.id}>
                <TableRow>
                  <SkillCategory
                    category={category}
                    skillSetting={skillSettings}
                    onCategoryRemove={onCategoryRemove}
                  />
                </TableRow>
                <SkillsForm
                  skills={skills}
                  category={category}
                  userDetails={userDetails}
                  onSkillAdd={onSkillAdd}
                  certificates={certificates}
                  maxSkillLevel={maxSkillLevel}
                  skillSetting={skillSettings}
                  onSkillRemove={onSkillRemove}
                  onSkillRatingUpdate={onSkillRatingUpdate}
                  onCertificateUpdate={onCertificateUpdate}
                  onCustomMetadataUpdate={onCustomMetadataUpdate}
                />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        <>
          <div data-qe-id="NoSkillLabel" className={classes.noSkill}>
            <NoDataItem>
              <FormattedMessage id="skillInfoCard.noSkillsAssignedMessage" />
            </NoDataItem>
            <div className={classes.assignSkillMessage}>
              <FormattedMessage id="skillInfoCard.assignSkillMessage" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

SkillsEditable.propTypes = {
  classes: PropTypes.object,
  certificates: PropTypes.array,
  userDetails: PropTypes.object,
  skillLevels: PropTypes.array,
  maxSkillLevel: PropTypes.number
};

export default SkillsEditable;
