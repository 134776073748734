const getRowDynamicStyle = ({ dynamicContainerHeight }) => {
  return {
    dynamicRowStyle: {
      itemContainer: {
        minHeight: dynamicContainerHeight
      }
    }
  };
};

export default getRowDynamicStyle;
