import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECT_DATES_AND_STATUSES = gql`
  query getProjectDatesAndStatuses($projectUri: String!) {
    resourcePlanDefaults(projectUri: $projectUri) {
      resourceAllocations {
        allocationStatuses
        minDate
        maxDate
      }
      resourceRequests {
        requestStatuses
        minDate
        maxDate
      }
    }
  }
`;

export const useGetResourcePlanDefaults = ({ projectUri, skip = false }) => {
  const { loading, data } = useQuery(GET_PROJECT_DATES_AND_STATUSES, {
    variables: { projectUri },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: skip || !projectUri
  });

  return {
    loading,
    resourceAllocationsMinMaxDateAndStatuses: {
      minDate: data?.resourcePlanDefaults?.resourceAllocations?.minDate,
      maxDate: data?.resourcePlanDefaults?.resourceAllocations?.maxDate,
      allocationStatuses:
        data?.resourcePlanDefaults?.resourceAllocations?.allocationStatuses
    },
    resourceRequestsMinMaxDateAndStatuses: {
      minDate: data?.resourcePlanDefaults?.resourceRequests?.minDate,
      maxDate: data?.resourcePlanDefaults?.resourceRequests?.maxDate,
      requestStatuses:
        data?.resourcePlanDefaults?.resourceRequests?.requestStatuses
    }
  };
};

export default useGetResourcePlanDefaults;
