import { gql } from 'graphql-tag';

export const RESOURCE_USERS = gql`
  query resourceUsers(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
    $asOfDate: DateObject
  ) {
    resourceUsers2(page: $page, pagesize: $pagesize, filter: $filter) {
      id
      currentBillingRate(asOfDate: $asOfDate) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

export const GET_CURRENT_BILLING_RATE_FOR_USER = gql`
  query getCurrentBillingRateForUser($userId: String!) {
    getCurrentBillingRateForUser(userId: $userId) {
      id
      rate {
        amount
        currency {
          id
          uri
          displayText
          name
          symbol
        }
      }
    }
  }
`;

export const PROJECT_ROLE = gql`
  query role($id: String!) {
    role(id: $id) {
      id
      billingScheduleEntries {
        effectiveDate
        amount {
          amount
          currency {
            id
            symbol
            displayText
          }
        }
      }
    }
  }
`;
