import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { hasPermission } from '~/modules/common/permissions';
import { ResourceAllocationStatus, ResourceCostModeType } from '~/types';
import { useResourceAssignmentRequestContext } from '~/modules/resourcing/components/ResourceAssignmentDialog/useResourceAssignmentRequestContext';
import { useResourceAllocationTotal } from '~/modules/resourcing/hooks';
import {
  updateResourceAllocation,
  getAllocationOnTotalHoursChange
} from '~/modules/resourcing/components/ResourceAssignmentDialog/resourceRequestAllocationsReducer';
import { MoneyValue } from '~/modules/common/components';

import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';
import useStyles from './useStyles';

const cleanValue = value => {
  const parsedValue = parseFloat(value);

  return isNaN(parsedValue) ? null : Math.max(0, parsedValue);
};

const getValue = (value, precision) => parseFloat(value.toFixed(precision));

const getMoneyValue = ({ resourceCost, parseTotalHoursValue }) => ({
  ...resourceCost,
  amount: resourceCost.amount * parseTotalHoursValue
});

export const EditableResourceAllocationUserItem = ({
  canEditHours = true,
  classes: classesOverrides,
  resourceAllocation,
  user
}) => {
  const me = useMeContext();
  const isViewCostResourceManagerEnabled = hasPermission(me.permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-hourly-cost',
    dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
  });

  const isViewCostResourcePoolManagerEnabled = hasPermission(me.permissionsMap)(
    {
      actionUri: 'urn:replicon:user-action:view-hourly-cost',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    }
  );

  const isViewCostEnabled =
    isViewCostResourceManagerEnabled || isViewCostResourcePoolManagerEnabled;

  const intl = useIntl();
  const isRoleBasedCost =
    me?.resourceCostMode === ResourceCostModeType.Rolecost;
  const { dispatch } = useResourceAssignmentRequestContext();

  const classes = useStyles({
    classes: classesOverrides
  });

  const totalHourClasses = useMemo(
    () => ({
      root: classes.totalHours,
      underline: classes.underline
    }),
    [classes.totalHours, classes.underline]
  );

  const numberSettings = getReactNumberFormatFromMe(me);
  const { resourceAllocationHours } = useResourceAllocationTotal({
    me,
    ...resourceAllocation
  });

  const inputProps = useMemo(
    () => ({
      inputProps: {
        'aria-label': intl.formatMessage({
          id: 'resourceAllocationUserItem.hours'
        })
      },
      endAdornment: (
        <InputAdornment
          position="end"
          disableTypography
          className={classes.inputAdornment}
        >
          {intl.formatMessage({ id: 'resourceAllocationUserItem.hours' })}
        </InputAdornment>
      ),
      readOnly: !canEditHours
    }),
    [canEditHours, classes.inputAdornment, intl]
  );

  const onTotalHoursChange = useCallback(
    event => {
      if (
        resourceAllocation.allocationStatus === ResourceAllocationStatus.Draft
      ) {
        const totalHours = cleanValue(event.target && event.target.value);

        dispatch(
          updateResourceAllocation(
            getAllocationOnTotalHoursChange({
              resourceAllocation,
              totalHours
            })
          )
        );
      }
    },
    [resourceAllocation, dispatch]
  );

  const parseTotalHoursValue = resourceAllocationHours
    ? getValue(resourceAllocationHours, numberSettings.decimalScale || 2)
    : 0;

  const resourceCost = isRoleBasedCost
    ? user.primaryRoleCostRate
    : user.costRateAsOf;

  return (
    <div
      data-qe-id={`${user.displayText}_AllocatedEditItem`}
      className={classes.root}
    >
      <span className={classes.allocated}>
        {intl.formatMessage({ id: 'resourceAllocationUserItem.allocated' })}
        {':'}
      </span>
      <TextField
        type="number"
        step="0.01"
        min="0"
        value={parseTotalHoursValue}
        onChange={onTotalHoursChange}
        InputProps={inputProps}
        classes={totalHourClasses}
        variant="standard"
        align="right"
      />
      {isViewCostEnabled && resourceCost && (
        <div className={classes.totalCost}>
          <MoneyValue
            dataQeId="userAllocatedTotalCost"
            money={getMoneyValue({ resourceCost, parseTotalHoursValue })}
          />
        </div>
      )}
    </div>
  );
};

EditableResourceAllocationUserItem.propTypes = {
  canEditHours: PropTypes.bool,
  classes: PropTypes.object,
  resourceAllocation: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default EditableResourceAllocationUserItem;
