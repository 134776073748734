import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import getNumberSuffixFormat from '~/modules/common/components/SummaryFormattedNumber/getNumberSuffixFormat';
import { FormattedNumber } from '~/modules/common/components/SummaryFormattedNumber/SummaryFormattedNumber';

const useCostVarianceStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize
  },
  diffPositive: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark
  }
}));

export const CostVarianceNumber = ({
  classes: classesOverrides,
  value,
  prefix,
  showAddIcon,
  fixedDecimalScale = false,
  isRoundedValue = true
}) => {
  const { formattedValue, suffix } = isRoundedValue
    ? getNumberSuffixFormat(value)
    : { formattedValue: value, suffix: '' };
  const classes = useCostVarianceStyles({ classes: classesOverrides });

  return (
    <FormattedNumber
      classes={classes}
      value={Math.abs(Number(formattedValue))}
      prefix={prefix}
      suffix={suffix}
      isHour={false}
      isHigher={showAddIcon}
      showAddIcon={showAddIcon}
      fixedDecimalScale={fixedDecimalScale}
    />
  );
};

CostVarianceNumber.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.number,
  prefix: PropTypes.string,
  showAddIcon: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  isRoundedValue: PropTypes.bool
};

export default CostVarianceNumber;
