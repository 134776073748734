import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import {
  FormSection,
  FormTextField,
  Money2,
  EntityDrawerSection,
  NumberTextField
} from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import RolesMenuListDropdown from '~/modules/common/components/RolesMenuListDropdown';
import useMeContext from '~/modules/me/useMeContext';
import { getResourceRequestTotal } from '~/modules/resourcing/common/enhancers/withResourceRequestTotal';
import ExpandableInfoSections from '~/modules/common/components/ExpandableInfoSections';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { WeightTag } from '~/modules/resourcing/common/components';
import ResourceRequestImportanceDialog from '../ResourceRequestImportanceDialog';
import ResourceRequestDrawerDetailsDateField from './ResourceRequestDrawerDetailsDateField';
import useAttachOnChangeHandlersToForm from './hooks/useAttachOnChangeHandlersToForm';
import ResourceRequestDrawerDetailsLoadingField from './ResourceRequestDrawerDetailsLoadingField';
import useResourceRequestDrawerDetailsOnChangeEventHandlers from './hooks/useResourceRequestDrawerDetailsOnChangeEventHandlers';
import useResourceRequestDrawerDetailsFieldValues from './hooks/useResourceRequestDrawerDetailsFieldValues';
import useStyles, {
  useQualificationStyles,
  useRequestedAmountStyles,
  useFormStyles,
  useFormInputFieldRightStyles,
  usePlaceholderStyles,
  useRowNameStyles
} from './useStyles';
import { useExpandableSections } from './hooks/useExpandableSections';
import {
  InputFieldAdornment,
  CommentIconAdornment
} from './ResourceDrawerAdornment';

const commentIcon = <CommentIconAdornment />;
const endAdornment = <InputFieldAdornment adornmentSymbol="@" />;

export const ResourceRequestDrawerDetails = ({
  form: _form,
  project,
  resourceRequest,
  isMobile
}) => {
  const intl = useIntl();
  const { values: formValues, setValues } = _form;
  const {
    form: { fields, setFieldValue }
  } = useAttachOnChangeHandlersToForm(_form);

  const {
    quantity,
    load,
    comment,
    startDate,
    endDate,
    role,
    roleRate,
    currency,
    totalHours,
    requestAttributeWeights,
    isAdjustedLoading
  } = fields;

  const {
    onWeightsChange,
    onTotalRequestedCostChange,
    onEndDateChange,
    onStartDateChange,
    onHoursChange,
    onExtendLoad,
    onRoleChange,
    onLoadingChange,
    onQuantityChange,
    onRoleRateChange
  } = useResourceRequestDrawerDetailsOnChangeEventHandlers({
    fields,
    formValues,
    setValues,
    project,
    setFieldValue
  });

  const { resourceRequestTotalCost } = useMemo(
    () => getResourceRequestTotal(resourceRequest),
    [resourceRequest]
  );

  const {
    locale,
    defaultRequestAttributeWeights,
    permissionsMap
  } = useMeContext();
  const numberSettings = getReactNumberFormatFromMe({ locale });
  const decimalScale = numberSettings.decimalScale || 2;

  const classes = useStyles();
  const formClasses = useFormStyles();
  const rightFormFieldClasses = useFormInputFieldRightStyles();
  const qualificationsSectionClasses = useQualificationStyles();
  const requestedAmountSectionClasses = useRequestedAmountStyles();
  const placeholderClasses = usePlaceholderStyles();
  const roleNameClasses = useRowNameStyles();

  const canViewRole = Boolean(
    permissionsMap['urn:replicon:project-role-action:view-project-role']
  );

  const canViewCostData = project.permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );
  const {
    requiredFields,
    importanceValues
  } = useResourceRequestDrawerDetailsFieldValues({
    fields
  });

  const {
    open: importanceDialogOpen,
    openDialog: openImportanceDialog,
    closeDialog: closeImportanceDialog
  } = useDialogState();

  const expandableSections = useExpandableSections({
    fields,
    classes,
    requiredFields,
    openImportanceDialog,
    setFieldValue,
    customLabel: 'skillInfoCard.addSkillRequirement'
  });

  const placeholder = useMemo(
    () => (
      <span className={placeholderClasses.root}>
        <FormattedMessage id="rolesDropdown.plusSelectRole" />
      </span>
    ),
    [placeholderClasses]
  );

  const resourcesNumberField = (
    <FormTextField
      fullWidth
      align="left"
      label={intl.formatMessage({
        id: 'resourceRequestDrawerDetails.noOfResources'
      })}
      ariaLabel={intl.formatMessage({
        id: 'resourceRequestDrawerDetails.noOfResources'
      })}
      required
      value={quantity.value}
      onChange={onQuantityChange}
      error={quantity.hasError}
      helperText={quantity.error}
      endAdornment={endAdornment}
      inputClassName={rightFormFieldClasses.fieldItem}
    />
  );

  const detailsLoadingField = (
    <ResourceRequestDrawerDetailsLoadingField
      classes={classes}
      load={load}
      onLoadingChange={onLoadingChange}
      decimalScale={decimalScale}
      endDate={endDate}
      onExtendLoad={onExtendLoad}
      isAdjustedLoading={isAdjustedLoading}
    />
  );

  const requestedHoursField = (
    <NumberTextField
      fullWidth
      disabled={endDate.hasError}
      align="left"
      label={intl.formatMessage({
        id: 'resourceRequestDrawerDetails.totalRequestedHours'
      })}
      ariaLabel={intl.formatMessage({
        id: 'resourceRequestDrawerDetails.totalRequestedHours'
      })}
      onChange={onHoursChange}
      value={parseFloat(totalHours.value.toFixed(decimalScale))}
      precision={decimalScale}
      variant="filled"
      min={0}
      error={totalHours.hasError && !endDate.hasError}
      helperText={totalHours.error && !endDate.hasError}
    />
  );

  const requestedCostRateLabel = intl.formatMessage({
    id: 'resourceRequestDrawerDetails.requestedCostRate'
  });

  const costRateField = canViewCostData && (
    <li className={classes.rowItem}>
      <Money2
        label={requestedCostRateLabel}
        ariaLabel={requestedCostRateLabel}
        error={roleRate.hasError}
        helperText={
          <>
            {roleRate.hasError ? <span>{roleRate.error}</span> : null}
            <WeightTag
              className={classes.weightTag}
              weight={requestAttributeWeights.value?.requestedCostRate}
              onClick={openImportanceDialog}
            />
          </>
        }
        amount={roleRate.value || 0}
        currency={currency.value}
        onCurrencyChange={currency.onChange}
        onAmountChange={onRoleRateChange(decimalScale)}
      />
    </li>
  );

  const totalRequestedCostLabel = intl.formatMessage({
    id: 'resourceRequestDrawerDetails.totalRequestedCost'
  });

  const totalRequestedCostComponent = canViewCostData && (
    <li className={classes.rowItem}>
      <Money2
        label={totalRequestedCostLabel}
        ariaLabel={totalRequestedCostLabel}
        amount={resourceRequestTotalCost.amount}
        currency={currency.value}
        onCurrencyChange={currency.onChange}
        onAmountChange={onTotalRequestedCostChange}
      />
    </li>
  );

  return (
    <FormSection classes={formClasses}>
      <EntityDrawerSection
        classes={qualificationsSectionClasses}
        title={intl.formatMessage({
          id: 'resourceRequestDrawerDetails.qualifications'
        })}
      >
        <ResourceRequestImportanceDialog
          weights={requestAttributeWeights.value}
          defaults={defaultRequestAttributeWeights}
          values={importanceValues}
          open={importanceDialogOpen}
          onClose={closeImportanceDialog}
          onChange={onWeightsChange}
        />
        <div className={classes.buttonRow}>
          <Button onClick={openImportanceDialog} color="primary">
            <FormattedMessage id="resourceRequestDrawerDetails.setImportance" />
          </Button>
        </div>
        <ul className={classes.formRow}>
          <li className={classes.rowItem}>
            <div className={classes.weightTagContainer}>
              <RolesMenuListDropdown
                classes={roleNameClasses}
                variant="filled"
                assignedRole={role.value}
                onChange={onRoleChange}
                label={
                  intl.formatMessage({
                    id: 'resourceRequestDrawerDetails.role'
                  }) + (requiredFields.role ? ' *' : '')
                }
                includeSkills={canViewRole}
                hasError={role.hasError}
                placeholder={placeholder}
                helperText={
                  <>
                    {role.hasError ? <span>{role.error}</span> : null}
                    <WeightTag
                      className={classes.weightTag}
                      weight={requestAttributeWeights.value?.role}
                      onClick={openImportanceDialog}
                    />
                  </>
                }
              />
            </div>
          </li>
        </ul>
        {expandableSections.length && (
          <ExpandableInfoSections sections={expandableSections} />
        )}
      </EntityDrawerSection>
      <div className={classes.hr} />
      <EntityDrawerSection
        classes={requestedAmountSectionClasses}
        title={intl.formatMessage({
          id: 'resourceRequestDrawerDetails.requestedAmount'
        })}
      >
        <ul className={classes.formRow}>
          <li className={classes.rowItem}>
            <ResourceRequestDrawerDetailsDateField
              date={startDate}
              error={endDate.hasError}
              onChange={onStartDateChange}
              label="resourceRequestDrawerDetails.startDate"
              classes={classes}
            />
          </li>
          <li className={classes.rowItem}>
            <ResourceRequestDrawerDetailsDateField
              date={endDate}
              error={endDate.hasError}
              classes={classes}
              label="resourceRequestDrawerDetails.endDate"
              onChange={onEndDateChange}
            />
          </li>
        </ul>
        {isMobile ? (
          <>
            <ul className={classes.formRow}>
              <li className={classNames(classes.rowItem, classes.flexRow)}>
                {resourcesNumberField}
                {detailsLoadingField}
              </li>
            </ul>
            <ul className={classes.formRow}>
              <li className={classes.rowItem}>{requestedHoursField}</li>
              {costRateField}
            </ul>
            <ul className={classNames(classes.formRow, classes.totalsRow)}>
              {totalRequestedCostComponent}
            </ul>
          </>
        ) : (
          <>
            <ul className={classes.formRow}>
              <li className={classNames(classes.rowItem, classes.flexRow)}>
                {resourcesNumberField}
                {detailsLoadingField}
              </li>
              <li className={classes.rowItem}>{requestedHoursField}</li>
            </ul>
            <ul className={classNames(classes.formRow, classes.totalsRow)}>
              {canViewCostData && (
                <>
                  {costRateField}
                  {totalRequestedCostComponent}
                </>
              )}
            </ul>
          </>
        )}
      </EntityDrawerSection>
      <div className={classes.hr} />
      <ul className={classNames(classes.formRow, classes.commentRow)}>
        <li className={classes.rowItem}>
          <FormTextField
            fullWidth
            value={comment.value || ''}
            onChange={comment.onChange}
            multiline
            ariaLabel={intl.formatMessage({
              id: 'resourceRequestDrawerDetails.comment'
            })}
            inputClassName={classes.comment}
            startAdornment={commentIcon}
            placeholder={intl.formatMessage({
              id: 'resourceRequestDrawerDetails.comment'
            })}
          />
        </li>
      </ul>
    </FormSection>
  );
};

ResourceRequestDrawerDetails.propTypes = {
  form: PropTypes.object.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  isMobile: PropTypes.bool
};

export default ResourceRequestDrawerDetails;
