import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    borderBottom: ({ noBottomBorder }) =>
      noBottomBorder ? 'none' : `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.modal,
    position: 'sticky',
    left: 0,
    right: 0
  },
  indicator: {
    backgroundColor: theme.palette.primary.main
  },
  scrollButtons: {
    flexBasis: theme.spacing(3)
  },
  scrollButtonsDesktop: {}
}));

export default useStyles;
