import {
  BILLING_INVOICING_PERMISSION_URIS,
  BILL_DAL_URIS,
  CREDIT_MEMO_DAL_URIS,
  PAYMENT_DAL_URIS
} from '~/modules/common/enums';
import { useMeContext } from '~/modules/me';
import { Permission } from '~/types';

type DalUriEnum = {
  PROJECT_MANAGER: string;
  PROJECT_ADMIN: string;
  PROGRAM_MANAGER: string;
  CLIENT_MANAGER: string;
  BILLING_MANAGER: string;
};

type AddPermissions = {
  asProjectManager: boolean;
  asProjectAdmin: boolean;
  asProgramManager: boolean;
  asClientManager: boolean;
  asBillingManager: boolean;
};

const hasPermissionWithDAL = (
  permission: { dataAccessLevelUris: string[] },
  dalUri: string
): boolean =>
  Boolean(permission && permission.dataAccessLevelUris.includes(dalUri));

export const evaluateAddPermissions = (
  editPermission: Permission,
  dalUriEnum: DalUriEnum
): AddPermissions => ({
  asProjectManager: hasPermissionWithDAL(
    editPermission as { dataAccessLevelUris: string[] },
    dalUriEnum.PROJECT_MANAGER
  ),
  asProjectAdmin: hasPermissionWithDAL(
    editPermission as { dataAccessLevelUris: string[] },
    dalUriEnum.PROJECT_ADMIN
  ),
  asProgramManager: hasPermissionWithDAL(
    editPermission as { dataAccessLevelUris: string[] },
    dalUriEnum.PROGRAM_MANAGER
  ),
  asClientManager: hasPermissionWithDAL(
    editPermission as { dataAccessLevelUris: string[] },
    dalUriEnum.CLIENT_MANAGER
  ),
  asBillingManager: hasPermissionWithDAL(
    editPermission as { dataAccessLevelUris: string[] },
    dalUriEnum.BILLING_MANAGER
  )
});

const usePermissions = (
  viewBillingDataPermission: string | null | undefined
): object | null => {
  const me = useMeContext();

  if (!me) return null;
  const { permissionsMap } = me;

  const editBillPermission =
    permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_BILL];
  const editCreditMemoPermission =
    permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_CREDIT_MEMO];
  const editPaymentPermission =
    permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_PAYMENT];

  return {
    canEditBill: Boolean(editBillPermission),
    canEditPayment: Boolean(editPaymentPermission),
    canEditCreditMemo: Boolean(editCreditMemoPermission),
    canViewBill: Boolean(
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_BILL]
    ),
    canViewPayment: Boolean(
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_PAYMENT]
    ),
    canViewCreditMemo: Boolean(
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_CREDIT_MEMO]
    ),
    canViewProject: Boolean(
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_PROJECT]
    ),
    canViewBillingData: viewBillingDataPermission
      ? Boolean(permissionsMap[viewBillingDataPermission])
      : true,
    addBillPermissions: evaluateAddPermissions(
      editBillPermission,
      BILL_DAL_URIS
    ),
    addCreditMemoPermissions: evaluateAddPermissions(
      editCreditMemoPermission,
      CREDIT_MEMO_DAL_URIS
    ),
    addPaymentPermissions: evaluateAddPermissions(
      editPaymentPermission,
      PAYMENT_DAL_URIS
    )
  };
};

export default usePermissions;
