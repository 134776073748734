import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { DateRange, Decimal, Percentage } from '~/modules/common/components';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getResourceAllocationTotal } from '~/modules/resourcing/hooks/useResourceAllocationTotal';
import { getStatusBasedClassName } from '~/modules/resourcing/common/enums';
import {
  isNumeric,
  getReactNumberFormatFromMe
} from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  RequestAllocatedDatesDiffView,
  ConditionalTooltip
} from '~/modules/resourcing/common/components';
import useStyles from './useStyles';
import RequestAllocationUserScoreItem from './RequestAllocationUserScoreItem';

export const RequestDrawerResourceAllocationItemTooltipTitle = ({
  intl,
  classes,
  resourceRequest,
  resourceAllocation,
  resourceRequestTotalHours,
  resourceAllocationHours
}) => {
  const requestLoadValue = resourceRequest.load;

  const allocationLoadValue = resourceAllocation && resourceAllocation.load;

  return (
    <>
      <div className={classes.tooltipContent}>
        <div className={classes.tooltipItem}>
          <span>
            {intl.formatMessage({
              id: 'resourceRequestDrawerDetails.requested'
            })}
          </span>
          <DateRange
            start={resourceRequest.startDate}
            end={resourceRequest.endDate}
          />
          <span> @ </span>
          <Percentage
            value={requestLoadValue}
            className={classNames(classes.loading, {
              [classes.overloaded]: requestLoadValue > 100
            })}
          />
          <span>{resourceRequest.isAdjustedLoading && '* '}=</span>
          <Decimal
            value={resourceRequestTotalHours / resourceRequest.quantity}
            suffix={intl.formatMessage({
              id: 'resourceRequestDrawerDetails.reqHours'
            })}
            className={classes.totalHours}
          />
        </div>
        {resourceAllocation && (
          <div className={classes.tooltipItem}>
            <span>
              {intl.formatMessage({
                id: 'resourceRequestDrawerDetails.allocated'
              })}
            </span>
            <DateRange
              start={resourceAllocation.startDate}
              end={resourceAllocation.endDate}
            />
            <span> @ </span>
            <Percentage
              value={allocationLoadValue}
              className={classNames(classes.loading, {
                [classes.overloaded]: allocationLoadValue > 100
              })}
            />
            <span>{resourceAllocation.isAdjustedLoading && '* '}=</span>
            <Decimal
              value={resourceAllocationHours}
              suffix={intl.formatMessage({
                id: 'resourceRequestDrawerDetails.reqHours'
              })}
              className={classes.totalHours}
            />
          </div>
        )}
      </div>
      {((resourceAllocation && resourceAllocation.isAdjustedLoading) ||
        resourceRequest.isAdjustedLoading) && (
        <div className={classes.adjustedMessage}>
          {intl.formatMessage({
            id: 'resourceLoadingField.adjustedLoading'
          })}
        </div>
      )}
    </>
  );
};

export const AllocationBarsComponentInternal = ({
  classes,
  resourceRequest,
  resourceAllocation,
  dateRange,
  resourceRequestTotalHours,
  resourceAllocationHours,
  numberSettings,
  resourceAllocationCost,
  getPositonStyles,
  intl,
  resourceRequestTotalCost
}) => {
  const decimalScale = numberSettings.decimalScale || 2;
  const {
    project: { permittedActionUris }
  } = resourceRequest;

  const canViewCostData = permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  return (
    <div className={classes.bars}>
      {resourceAllocation && (
        <div
          className={classNames(
            classes.allocationBar,
            classes[
              getStatusBasedClassName(
                resourceRequest.requestStatus,
                'allocationBar'
              )
            ]
          )}
          style={getPositonStyles(resourceAllocation)}
        >
          <span className={classes.loadingLabel}>
            <Percentage
              value={resourceAllocation.load}
              fixedDecimalScale={false}
              precision={decimalScale}
            />
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <Decimal
              value={resourceRequestTotalHours}
              suffix={intl.formatMessage({
                id: 'resourceRequestDrawerDetails.reqHours'
              })}
              fixedDecimalScale={false}
              precision={decimalScale}
            />
            {canViewCostData && isNumeric(resourceAllocationCost.amount) ? (
              <>
                &nbsp;&nbsp;-&nbsp;&nbsp;
                <Decimal
                  value={resourceAllocationCost.amount}
                  prefix={`${resourceAllocationCost.currencySymbol || ''} `}
                  fixedDecimalScale={false}
                  precision={decimalScale}
                />
              </>
            ) : null}
          </span>
        </div>
      )}
      {resourceRequest.quantity >= 1 && (
        <div
          className={classNames(
            classes.requestBar,
            classes[
              getStatusBasedClassName(
                resourceRequest.requestStatus,
                'requestBar'
              )
            ]
          )}
          style={getPositonStyles(resourceRequest)}
        >
          {!resourceAllocation && (
            <span className={classes.loadingLabel}>
              <Percentage
                value={resourceRequest.load}
                fixedDecimalScale={false}
                precision={numberSettings.decimalScale || 0}
              />
              {resourceRequest.isAdjustedLoading && '*'}
              &nbsp;&nbsp;-&nbsp;&nbsp;
              <Decimal
                value={resourceRequestTotalHours / resourceRequest.quantity}
                suffix={intl.formatMessage({
                  id: 'resourceRequestDrawerDetails.reqHours'
                })}
                fixedDecimalScale={false}
                precision={decimalScale}
              />
              {canViewCostData && resourceRequest.role && (
                <>
                  &nbsp;&nbsp;-&nbsp;&nbsp;
                  <Decimal
                    value={
                      resourceRequestTotalCost.amount / resourceRequest.quantity
                    }
                    prefix={`${resourceRequestTotalCost.currencySymbol} `}
                    fixedDecimalScale={false}
                    precision={decimalScale}
                  />
                </>
              )}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

const RequestDrawerResourceAllocationItem = ({
  classes: classesOverrides,
  resourceRequest,
  resourceAllocation,
  dateRange,
  resourceRequestTotalHours,
  resourceRequestTotalCost
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverrides });
  const tooltipClasses = useMemo(
    () => ({
      tooltip: classes.customWidth
    }),
    [classes.customWidth]
  );

  const me = useMeContext();
  const numberSettings = getReactNumberFormatFromMe(me);
  const length =
    dateRange.endDate.diff(dateRange.startDate, 'days').values.days + 1;

  const getPositonStyles = item => {
    const start = mapIsoStringtoUtcObject(item.startDate);
    const end = mapIsoStringtoUtcObject(item.endDate);
    const left = start.diff(dateRange.startDate, 'days').values.days / length;

    return {
      left: `${left * 100}%`,
      width: `${((end.diff(dateRange.startDate, 'days').values.days + 1) /
        length -
        left) *
        100}%`
    };
  };
  const { resourceAllocationHours, resourceAllocationCost } = resourceAllocation
    ? getResourceAllocationTotal({ me, ...resourceAllocation })
    : {};

  const isCombinedResourceRequest =
    resourceRequest?.mergedResourceRequests?.length > 1;

  return (
    <li className={classes.root}>
      <div className={classes.infoRow}>
        <div className={classes.user}>
          {resourceAllocation &&
          resourceAllocation.user &&
          resourceAllocation.user.userUri ? (
            <RequestAllocationUserScoreItem
              user={resourceAllocation.user.user}
              resourceRequestId={resourceRequest.id}
              preferredResources={resourceRequest?.preferredResources}
              isCombinedResourceRequest={isCombinedResourceRequest}
            />
          ) : (
            <span className={classes.noUserLabel}>
              {intl.formatMessage({
                id: 'resourceRequestDrawerDetails.noResourcesAllocated'
              })}
            </span>
          )}
        </div>
        {resourceAllocation && (
          <div className={classes.dates}>
            {isCombinedResourceRequest ? (
              <Typography
                component="div"
                variant="body2"
                className={classes.datesDiffView}
              >
                {shortDateRangeFormat({
                  start: mapIsoStringtoUtcObject(resourceAllocation.startDate),
                  end: mapIsoStringtoUtcObject(resourceAllocation.endDate)
                })}
              </Typography>
            ) : (
              <RequestAllocatedDatesDiffView
                resourceRequest={resourceRequest}
                resourceAllocations={[resourceAllocation]}
                className={classes.dates}
              />
            )}
          </div>
        )}
      </div>
      <ConditionalTooltip
        condition={!isCombinedResourceRequest}
        title={
          <RequestDrawerResourceAllocationItemTooltipTitle
            intl={intl}
            classes={classes}
            resourceRequest={resourceRequest}
            resourceAllocation={resourceAllocation}
            resourceRequestTotalHours={resourceRequestTotalHours}
            resourceAllocationHours={resourceAllocationHours}
          />
        }
        classes={tooltipClasses}
      >
        <div>
          <AllocationBarsComponentInternal
            classes={classes}
            resourceRequest={resourceRequest}
            resourceAllocation={resourceAllocation}
            dateRange={dateRange}
            resourceRequestTotalHours={resourceRequestTotalHours}
            resourceAllocationHours={resourceAllocationHours}
            numberSettings={numberSettings}
            getPositonStyles={getPositonStyles}
            intl={intl}
            resourceAllocationCost={resourceAllocationCost}
            resourceRequestTotalCost={resourceRequestTotalCost}
          />
        </div>
      </ConditionalTooltip>
    </li>
  );
};

RequestDrawerResourceAllocationItem.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocation: PropTypes.object,
  dateRange: PropTypes.object.isRequired,
  classes: PropTypes.object,
  resourceRequestTotalHours: PropTypes.number,
  resourceRequestTotalCost: PropTypes.object
};

RequestDrawerResourceAllocationItemTooltipTitle.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocation: PropTypes.object,
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  resourceRequestTotalHours: PropTypes.number,
  resourceAllocationHours: PropTypes.number
};

AllocationBarsComponentInternal.propTypes = {
  classes: PropTypes.object,
  resourceRequest: PropTypes.object,
  resourceAllocation: PropTypes.object,
  dateRange: PropTypes.object,
  resourceRequestTotalHours: PropTypes.number,
  resourceAllocationHours: PropTypes.number,
  numberSettings: PropTypes.object,
  getPositonStyles: PropTypes.func,
  resourceRequestTotalCost: PropTypes.object,
  intl: PropTypes.object.isRequired,
  resourceAllocationCost: PropTypes.object
};

export default RequestDrawerResourceAllocationItem;
