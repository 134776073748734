import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { MoneyValue } from '~/modules/common/components';

export const RequestedCostTooltipContent = ({
  classes,
  moneyObject,
  totalCost
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className={classes.detailsIndicatorTooltipTitle}>
        {formatMessage({ id: 'resourceRequestCostRateField.costRate' })}
      </div>
      <div className={classes.detailsIndicatorTooltipContent}>
        {formatMessage(
          { id: 'resourceRequestCostRateField.requestedCostRateAppend' },
          {
            cost: (
              <MoneyValue money={moneyObject} key="requestedCostRateAppend" />
            )
          }
        )}
      </div>
      <div className={classes.detailsIndicatorTooltipContent}>
        {formatMessage(
          { id: 'resourceRequestCostRateField.totalRequestedCostAppend' },
          {
            cost: (
              <MoneyValue money={totalCost} key="totalRequestedCostAppend" />
            )
          }
        )}
      </div>
    </>
  );
};

RequestedCostTooltipContent.propTypes = {
  classes: PropTypes.object.isRequired,
  moneyObject: PropTypes.object.isRequired,
  totalCost: PropTypes.object.isRequired
};
