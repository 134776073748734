import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  colorSecondary: {
    color: theme.palette.client.inActive.main,
    backgroundColor: theme.palette.client.inActive.main,
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.client.inActive.main
    }
  },
  root: ({ isActive, size }) => ({
    color: theme.palette.text.primary,
    '&:hover, &:focus': {
      color: theme.palette.text.primary
    },
    ...(size === 'medium' ? theme.typography.body2 : theme.typography.caption)
  }),
  colorPrimary: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.client.active.main
  },
  clickableColorPrimary: {
    backgroundColor: theme.palette.client.active.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.client.active.main
    }
  },
  clickableColorSecondary: {
    backgroundColor: theme.palette.client.inActive.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.client.inActive.main
    }
  }
}));

export default useStyles;
