import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Money } from '~/modules/common/components/ListTable/renderers';
import { CarryOverSummaryButton } from './CarryOverSummaryButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export const InnerRecognizableRevenueFormatter = ({
  field,
  record,
  revenuePeriodEndDate
}) => {
  const classes = useStyles();
  const { hasCarryOver, id, revenuePeriod, project } = record;
  const periodEndDate =
    revenuePeriodEndDate || (revenuePeriod && revenuePeriod.endDate);

  return (
    <div className={classes.root}>
      <Money record={record} field={field}></Money>
      {hasCarryOver && periodEndDate && project && (
        <CarryOverSummaryButton
          revenueEntryId={id}
          revenuePeriodEndDate={periodEndDate}
          project={project}
        />
      )}
    </div>
  );
};

InnerRecognizableRevenueFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  revenuePeriodEndDate: PropTypes.object
};

export const RecognizableRevenueFormatter = ({
  revenuePeriodEndDate
}) => props => {
  return (
    <InnerRecognizableRevenueFormatter
      revenuePeriodEndDate={revenuePeriodEndDate}
      {...props}
    />
  );
};

export default RecognizableRevenueFormatter;
