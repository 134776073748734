import { createContext } from 'react';
import { GetMeQuery, Maybe, Permission } from '~/types';

export type MeType = GetMeQuery['me'] & {
  permissionsMap: {
    [key: string]: Permission;
  };
};

export const MeContext = createContext<Maybe<MeType>>(null);
