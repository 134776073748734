import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { Decimal, DateRange } from '~/modules/common/components';
import {
  getTotalHoursForDateRangeFromScheduleRules,
  roundToDecimals
} from '~/modules/resourcing/common/util/scheduleUtil';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  fieldValueContainer: {
    textAlign: 'right'
  },
  fieldValue: {
    paddingLeft: theme.spacing(1)
  }
}));

const useDateRangeStyles = makeStyles(theme => ({
  date: {
    fontSize: 'unset',
    fontWeight: theme.typography.fontWeightBold
  },
  range: {
    fontSize: 'unset'
  }
}));
const useDividerStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  }
}));

const EditorPopoverTooltip = ({
  endDate,
  otherTaskAllocationsSummaryScheduleRules,
  projectAvailableHours,
  resourceAllocationScheduleRules,
  startDate
}) => {
  const classes = useStyles();
  const dividerClasses = useDividerStyles();
  const dateRangeClasses = useDateRangeStyles();
  const { formatMessage } = useIntl();

  const hoursSuffix = formatMessage({
    id: 'taskAllocationEditor.hoursSuffix'
  });

  const startDateUtc = mapRepliconDateToUtcObject(startDate);
  const endDateUtc = mapRepliconDateToUtcObject(endDate);

  const otherTaskAllocatedHours =
    otherTaskAllocationsSummaryScheduleRules &&
    getTotalHoursForDateRangeFromScheduleRules({
      start: startDateUtc,
      end: endDateUtc,
      scheduleRules: otherTaskAllocationsSummaryScheduleRules
    });
  const resourceAllocatedHours = getTotalHoursForDateRangeFromScheduleRules({
    start: startDateUtc,
    end: endDateUtc,
    scheduleRules: resourceAllocationScheduleRules
  });

  return (
    <div>
      <DateRange
        start={startDateUtc}
        end={endDateUtc}
        classes={dateRangeClasses}
      />
      <div className={classes.container}>
        <div>
          <div>
            <FormattedMessage id="taskAllocationEditor.projectAllocation" />
          </div>
          <div>
            <FormattedMessage id="taskAllocationEditor.allocatedToOtherTask" />
          </div>
          <Divider className={dividerClasses.root} />
          <div>
            <FormattedMessage id="taskAllocationEditor.availableAllocation" />
          </div>
        </div>
        <div className={classes.fieldValueContainer}>
          <div className={classes.fieldValue}>
            <Decimal
              value={roundToDecimals(resourceAllocatedHours)}
              suffix={hoursSuffix}
            />
          </div>
          <div className={classes.fieldValue}>
            <Decimal
              value={roundToDecimals(otherTaskAllocatedHours || 0)}
              suffix={hoursSuffix}
            />
          </div>
          <Divider className={dividerClasses.root} />
          <div className={classes.fieldValue}>
            <Decimal
              value={roundToDecimals(projectAvailableHours)}
              suffix={hoursSuffix}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EditorPopoverTooltip.propTypes = {
  endDate: PropTypes.object,
  otherTaskAllocationsSummaryScheduleRules: PropTypes.array,
  projectAvailableHours: PropTypes.number,
  resourceAllocationScheduleRules: PropTypes.array,
  startDate: PropTypes.object
};

export default EditorPopoverTooltip;
