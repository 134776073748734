import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexBasis: theme.spacing(7),
    flexGrow: 0,
    display: 'flex',
    flexShrink: 0,
    overflow: 'visible'
  },
  submitIcon: {
    color: theme.palette.primary.main
  },
  approveIcon: {
    color: theme.palette.success.main
  },
  rejectIcon: {
    color: theme.palette.error.main
  },
  removeIcon: {
    color: theme.palette.secondary.main
  },
  primaryActionButton: {
    padding: theme.spacing(0.8),
    margin: -theme.spacing(0.5)
  },
  actionsPlaceholder: {
    width: theme.spacing(3.5)
  },
  secondaryActionsButton: {}
}));
