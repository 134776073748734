import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { useDialogState } from '~/modules/common/hooks';
import { AddIconButton } from '~/modules/common/components';
import AddCertificateDialog from '~/modules/resourcing/common/components/Certificates/AddCertificateDialog';

const useStyles = makeStyles(theme => ({
  addCircleIcon: {
    marginLeft: theme.spacing(-0.5)
  },
  addLicenseButton: {
    marginTop: theme.spacing(2)
  },
  container: {}
}));

const messageValues = {
  license: (
    <FormattedMessage id="resourceCertificatesAndLicensesCard.license" />
  ),
  certificate: (
    <FormattedMessage id="resourceCertificatesAndLicensesCard.certificate" />
  )
};

export const AddCertificate = ({ userUri }) => {
  const {
    open: dialogOpen,
    openDialog: onAdd,
    closeDialog: onCancel
  } = useDialogState(false);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <AddIconButton
        onClick={onAdd}
        classes={useMemo(() => ({ root: classes.addLicenseButton }), [
          classes.addLicenseButton
        ])}
        data-qe-id="addCertificate"
      >
        <FormattedMessage
          id="resourceCertificatesAndLicensesCard.addLicenseOrCertificate"
          values={messageValues}
        />
      </AddIconButton>
      <AddCertificateDialog
        open={dialogOpen}
        onCancel={onCancel}
        userUri={userUri}
      />
    </div>
  );
};

AddCertificate.propTypes = {
  userUri: PropTypes.string
};

export default AddCertificate;
