/* eslint-disable max-nested-callbacks */
import { useEffect, useReducer, useRef, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import deepEqual from 'fast-deep-equal';
import { useCombinedPagination } from '~/modules/resourcing/common/hooks/useCombinedPagination';
import { useSessionStorage } from '~/modules/common/hooks';
import { getUsersAndRolesForProject } from './getUsersAndRolesForProject';
import { getResourceAllocationAllocatedProjects } from './getResourceAllocationAllocatedProjects';
import { getResourceAllocationActualProjects } from './getResourceAllocationActualProjects';

import { mapProjectStatusToProjectWorkflowStages } from './utils';
import { resetStateReducer } from './resetStateReducer';
import {
  getUpdatedProjectList,
  getInsertIndex,
  getCombinedProjectDataEntry,
  getMergedProjectData,
  sortProjectByDisplayText
} from './useFetchResourceUserProjects.util';

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const GET_QUICK_ALLOCATION_ALLOCATED_PROJECTS_VARIABLES =
  'get-quick-allocation-allocated-projects-variables';

export const useFetchResourceUserProjects = ({
  userId,
  pageSize,
  currentPage,
  sort,
  isShowActualEnabled,
  setCurrentPage,
  projectStatusFilterOption
}) => {
  const apolloClient = useApolloClient();
  const pageTokens = useRef({});
  const isSortByProjectAscending = sort?.direction === SORT_ORDER.ASC;
  const { setValue: setVariables } = useSessionStorage(
    GET_QUICK_ALLOCATION_ALLOCATED_PROJECTS_VARIABLES,
    null
  );

  const fetchActualProjects = async page =>
    getResourceAllocationActualProjects({
      page,
      pageSize,
      userId,
      apolloClient,
      projectWorkflowStages: mapProjectStatusToProjectWorkflowStages(
        projectStatusFilterOption
      ),
      isSortByProjectAscending
    });

  const fetchAllocatedProjects = async page => {
    const {
      pageTokens: updatedPageTokens,
      allocatedProjects
    } = await getResourceAllocationAllocatedProjects({
      page,
      pageSize,
      userId,
      pageTokens,
      isSortByProjectAscending,
      apolloClient,
      setVariables,
      projectWorkflowStages: mapProjectStatusToProjectWorkflowStages(
        projectStatusFilterOption
      )
    });

    pageTokens.current = updatedPageTokens;

    return allocatedProjects;
  };

  const {
    loading,
    data,
    getNext,
    hasNext,
    refetch,
    reset,
    setData
  } = useCombinedPagination({
    getters: [
      ...(isShowActualEnabled ? [fetchActualProjects] : []),
      fetchAllocatedProjects
    ],
    sort: sortProjectByDisplayText(sort?.direction)
  });

  const { filteredData } = data.reduce(
    (acc, proj) => {
      if (proj.isActualEntry) {
        acc.filteredData.push(proj);
      } else if (acc.existingProjectIds.indexOf(proj.project.id) === -1) {
        acc.existingProjectIds.push(proj.project.id);
        acc.filteredData.push(proj);
      }

      return acc;
    },
    {
      existingProjectIds: [],
      filteredData: []
    }
  );

  const projectData =
    loading && currentPage === 1 ? {} : getMergedProjectData(filteredData);

  useEffect(() => {
    if (
      !loading &&
      hasNext &&
      Object.keys(projectData).length < pageSize * currentPage
    ) {
      getNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, data, isSortByProjectAscending]);

  const [state, dispatch] = useReducer(resetStateReducer, {
    prevIsSortByProjectAscending: isSortByProjectAscending,
    prevFilter: {
      projectStatusFilterOption
    }
  });

  useEffect(() => {
    const hasFilterChanged = !deepEqual(
      state.prevFilter.projectStatusFilterOption,
      projectStatusFilterOption
    );
    const hasSortChanged =
      state.prevIsSortByProjectAscending !== isSortByProjectAscending;

    if (hasSortChanged) {
      dispatch({ type: 'SORT_CHANGE' });
    }

    if (hasFilterChanged) {
      dispatch({
        type: 'FILTER_CHANGE',
        projectStatusFilterOption
      });
    }

    if (hasFilterChanged || hasSortChanged) {
      reset();
      setCurrentPage(1);
    }
  }, [
    isSortByProjectAscending,
    projectStatusFilterOption,
    reset,
    setCurrentPage,
    state.prevFilter,
    state.prevIsSortByProjectAscending
  ]);

  const handleAddResourceAllocation = useCallback(
    async ({ allocation }) => {
      const projectId = allocation.project.id;

      const projectExists = data.some(d => d.project.id === projectId);

      const insertIndex = getInsertIndex({
        currentPage,
        pageSize,
        projects: data
      });

      if (projectExists) {
        const newData = getUpdatedProjectList({
          allocation,
          cachedProjects: data,
          insertIndex
        });

        setData(newData);
      } else {
        const {
          actualsUserAndRolesData,
          allocatedUsersAndRolesData
        } = await getUsersAndRolesForProject({
          projectId,
          userIds: [userId],
          isResourceActualModeEnabled: isShowActualEnabled,
          apolloClient
        });

        const projectWithAllAllocations = getCombinedProjectDataEntry({
          actualsRoles: actualsUserAndRolesData[0]?.roles,
          allocatedRoles: allocatedUsersAndRolesData[0]?.roles,
          project: allocation.project,
          userId
        });

        const newData = [...data];

        newData.splice(insertIndex, 0, projectWithAllAllocations);

        setData(newData);
      }
    },
    [
      currentPage,
      data,
      apolloClient,
      isShowActualEnabled,
      userId,
      pageSize,
      setData
    ]
  );

  const handleRemoveResourceAllocation = useCallback(
    ({ deletedAllocationIds, projectId }) => {
      const updatedData = data.reduce((acc, projectObj) => {
        const skipCacheUpdateForProject =
          projectObj.project.id !== projectId ||
          (projectObj.project.id === projectId && projectObj.isActualEntry);

        if (skipCacheUpdateForProject) {
          acc.push(projectObj);
        } else {
          const filteredRoles = (projectObj.roles || [])
            .map(role => {
              const updatedRole = { ...role };

              updatedRole.resourceAllocationReference = role.resourceAllocationReference.filter(
                reference => !deletedAllocationIds.includes(reference.id)
              );

              return updatedRole.resourceAllocationReference.length > 0
                ? updatedRole
                : null;
            })
            .filter(Boolean);

          if (filteredRoles.length > 0) {
            acc.push({ ...projectObj, roles: filteredRoles });
          }
        }

        return acc;
      }, []);

      setData(updatedData);
    },
    [data, setData]
  );

  return {
    loading,
    projectRoleAndAllocationData: projectData,
    getNext,
    hasNext,
    refetch,
    reset,
    handleAddResourceAllocation,
    handleRemoveResourceAllocation
  };
};

export default useFetchResourceUserProjects;
