import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  makeStyles,
  ListItemIcon,
  ListItemText,
  ButtonBase
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddSharp';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'start',
    margin: theme.spacing(0, -2)
  },
  icon: {
    padding: theme.spacing(1),
    minWidth: 42
  }
}));

const useListItemStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const primaryTypographyProps = {
  color: 'primary'
};

const CreatableOption = ({
  option,
  onCreate,
  primaryLabelTextId,
  optionPropText,
  showLabel = true,
  classes: classesOverrides
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverrides });
  const listItemTextClasses = useListItemStyles();

  const handleClick = useCallback(() => onCreate(option[optionPropText]), [
    option,
    onCreate,
    optionPropText
  ]);

  return (
    <ButtonBase className={classes.root} onClick={handleClick}>
      <ListItemIcon className={classes.icon}>
        <AddIcon color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={formatMessage(
          {
            id: primaryLabelTextId
          },
          {
            label: option[optionPropText]
          }
        )}
        primaryTypographyProps={primaryTypographyProps}
        classes={listItemTextClasses}
      />
    </ButtonBase>
  );
};

CreatableOption.propTypes = {
  option: PropTypes.object.isRequired,
  onCreate: PropTypes.func,
  optionPropText: PropTypes.string,
  primaryLabelTextId: PropTypes.string,
  classes: PropTypes.object,
  showLabel: PropTypes.bool
};

export default CreatableOption;
