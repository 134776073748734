export const csvExportColumnNamesOverride = {
  actualBilling: 'projectList.billableRevenue',
  actualBillingInProjectCurrency: 'projectList.billableRevenueProjectCurrency',
  actualCost: 'projectList.actualCost',
  actualCostInProjectCurrency: 'projectList.actualCostInProjectCurrency',
  actualHours: 'projectList.actualHours',
  budgetHours: 'projectList.budgetHours',
  budgetHoursRemaining: 'projectList.budgetRemaining',
  budgetHoursUsed: 'projectList.budgetHoursUsed',
  estimatedBudgetHoursRemaining: 'projectList.budgetVsScope',
  code: 'projectList.projectCode',
  estimatedHoursAtCompletion: 'projectList.estimatedAtTaskCompletion',
  estimatedTaskCompletionDate: 'projectList.estimatedTaskCompletionDate',
  finalFaskEndDate: 'projectList.actualTaskCompletionDate',
  initialTaskStartDate: 'projectList.actualTaskStartDate',
  name: 'projectList.project',
  projectManagerReference: 'projectList.projectLeader',
  scopeHoursCompleted: 'projectList.scopeCompleted',
  rolledUpEstimatedRemainingHours: 'projectList.workRemaining',
  status: 'projectList.projectStatus',
  totalTaskHours: 'projectList.taskHours'
};
