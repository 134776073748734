import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import PropTypes from 'prop-types';

export const DeleteGroup = ({ record, openDeleteDialog, onDeleteGroup }) => {
  const { formatMessage } = useIntl();
  const ariaLabel = formatMessage({
    id: 'advancedRateCard.delete'
  });
  const onClick = useCallback(() => {
    if (openDeleteDialog && record.isPersisted) {
      openDeleteDialog(record.groupId);
    } else {
      onDeleteGroup(record.groupId);
    }
  }, [openDeleteDialog, record.isPersisted, record.groupId, onDeleteGroup]);

  return (
    <>
      <IconButton
        aria-label={ariaLabel}
        disabled={record.disabled}
        onClick={onClick}
        data-qe-id="DeleteGroup"
      >
        <DeleteSharpIcon fontSize="small" />
      </IconButton>
    </>
  );
};

DeleteGroup.propTypes = {
  record: PropTypes.object,
  openDeleteDialog: PropTypes.func,
  onDeleteGroup: PropTypes.func
};
