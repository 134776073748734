import { useMemo } from 'react';
import { numberAbbreviatedTickFormat } from '~/modules/common/charts/dashboard';

export default ({ gradientId, intl, maxBudgetLimit, chartStyle }) => {
  const maxLimit = useMemo(
    () => [
      { x: 0, y: maxBudgetLimit },
      { x: chartStyle.victoryChart.width, y: maxBudgetLimit }
    ],
    [chartStyle.victoryChart.width, maxBudgetLimit]
  );

  const actualBarStyles = useMemo(
    () => ({
      data: {
        fill: `url(#${gradientId})`
      }
    }),
    [gradientId]
  );

  const estimatedBarStyles = {
    data: { fill: 'url(#gradientForEstimated)' }
  };

  const yTickFormat = useMemo(
    () => tick => numberAbbreviatedTickFormat({ intl, tick }),
    [intl]
  );

  return {
    maxLimit,
    actualBarStyles,
    estimatedBarStyles,
    yTickFormat
  };
};
