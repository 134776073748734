import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, memo } from 'react';

import { DateTime } from 'luxon';
import { deepPure } from '~/util';

import {
  DEFAULT_PERIOD_SCALE,
  PERIOD_SCALE_ENUM
} from '~/modules/common/charts/timeline';
import {
  getScaleWidth,
  totalWidth
} from '~/modules/common/charts/timeline/calculations';

import useStyles from './useStyles';

export const ChartRangeItem = memo(
  ({
    classes: { openButton, ...classesOverrides } = {},
    leftComponentWidth,
    leftComponent,
    rightComponent,
    timeItems,
    scale = DEFAULT_PERIOD_SCALE,
    scaleItemCount,
    dynamicStyle = {
      itemContainer: {}
    },
    rightComponentWidth = totalWidth,
    chartStartDate,
    isFixedRange
  }) => {
    const classes = useStyles({ classes: classesOverrides });

    const rangeWidth = scaleItemCount * getScaleWidth(scale);
    const containerMinWidth =
      leftComponentWidth +
      rangeWidth +
      (rightComponent ? rightComponentWidth : 0);

    const minWidthStyle = useMemo(
      () => ({
        minWidth: containerMinWidth
      }),
      [containerMinWidth]
    );

    const itemContainerStyle = useMemo(
      () => ({
        ...{
          flexBasis: leftComponentWidth,
          width: leftComponentWidth
        },
        ...dynamicStyle.itemContainer
      }),
      [dynamicStyle.itemContainer, leftComponentWidth]
    );

    const rangeWidthStyle = useMemo(
      () => ({
        width: rangeWidth
      }),
      [rangeWidth]
    );

    return (
      <div className={classes.root} style={minWidthStyle}>
        <div className={classes.itemContainer} style={itemContainerStyle}>
          {leftComponent}
        </div>
        <div
          className={classNames(classes.timeContainer, {
            [classes.timeContainerYear]: scale === PERIOD_SCALE_ENUM.YEARS,
            [classes.timeContainerQuarter]:
              scale === PERIOD_SCALE_ENUM.QUARTERS,
            [classes.timeContainerMonth]: scale === PERIOD_SCALE_ENUM.MONTHS,
            [classes.timeContainerWeek]: scale === PERIOD_SCALE_ENUM.WEEKS,
            [classes.timeContainerDay]: scale === PERIOD_SCALE_ENUM.DAYS,
            [classes[`timeContainerDay${chartStartDate.weekday}`]]:
              scale === PERIOD_SCALE_ENUM.DAYS,
            [classes.timeContainerFixedRange]: isFixedRange
          })}
          style={rangeWidthStyle}
        >
          {timeItems}
        </div>
        {!rightComponent ? null : (
          <div className={classes.totalContainer}>{rightComponent}</div>
        )}
      </div>
    );
  }
);

ChartRangeItem.propTypes = {
  classes: PropTypes.object,
  scale: PropTypes.string.isRequired,
  dynamicStyle: PropTypes.object,
  scaleItemCount: PropTypes.number.isRequired,
  leftComponentWidth: PropTypes.number,
  rightComponentWidth: PropTypes.number,
  timeItems: PropTypes.node,
  leftComponent: PropTypes.node,
  rightComponent: PropTypes.node,
  chartStartDate: PropTypes.instanceOf(DateTime).isRequired,
  isFixedRange: PropTypes.bool
};

export default deepPure(ChartRangeItem);
