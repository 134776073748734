import React from 'react';
import get from 'lodash.get';
import { useMeContext } from '~/modules/me/useMeContext';
import { getEffectiveProjectGroupPolicy } from '~/modules/common/components/Groups/project';
import {
  LocationFacet,
  CostCenterFacet,
  DepartmentFacet,
  DivisionFacet,
  EmployeeTypeFacet,
  ServiceCenterFacet
} from '~/modules/common/components/SearchBox/Facets';

const createFacetControl = (BaseComponent, policy) => props => (
  <BaseComponent {...props} policy={policy} />
);

const createGroupFacet = (facet, policy) => ({
  ...facet,
  DetailsControl: createFacetControl(facet.DetailsControl, policy)
});

const useProjectGroupFacets = () => {
  const { groupSettings, userAccessRoles, permissionsMap } = useMeContext();
  const policy = getEffectiveProjectGroupPolicy({
    userAccessRoles
  });

  const locationSettings = get(groupSettings, 'location', {});
  const divisionSettings = get(groupSettings, 'division', {});
  const departmentSettings = get(groupSettings, 'department', {});
  const employeeTypeSettings = get(groupSettings, 'employeeType', {});
  const serviceCenterSettings = get(groupSettings, 'serviceCenter', {});
  const costCenterSettings = get(groupSettings, 'costCenter', {});

  const canViewDivision = Boolean(
    permissionsMap['urn:replicon:project-action:view-division']
  );

  const canViewServiceCenter = Boolean(
    permissionsMap['urn:replicon:project-action:view-service-center']
  );

  const canViewCostCenter = Boolean(
    permissionsMap['urn:replicon:project-action:view-cost-center']
  );

  const canViewLocation = Boolean(
    permissionsMap['urn:replicon:project-action:view-location']
  );

  const canViewDepartment = Boolean(
    permissionsMap['urn:replicon:project-action:view-department']
  );

  const canViewEmployeeType = Boolean(
    permissionsMap['urn:replicon:project-action:view-employee-type']
  );

  const groupFacets = [];

  if (canViewLocation && locationSettings.isEnabled) {
    groupFacets.push(createGroupFacet(LocationFacet, policy));
  }
  if (canViewCostCenter && costCenterSettings.isEnabled) {
    groupFacets.push(createGroupFacet(CostCenterFacet, policy));
  }
  if (canViewDepartment && departmentSettings.isEnabled) {
    groupFacets.push(createGroupFacet(DepartmentFacet, policy));
  }
  if (canViewDivision && divisionSettings.isEnabled) {
    groupFacets.push(createGroupFacet(DivisionFacet, policy));
  }
  if (canViewEmployeeType && employeeTypeSettings.isEnabled) {
    groupFacets.push(createGroupFacet(EmployeeTypeFacet, policy));
  }
  if (canViewServiceCenter && serviceCenterSettings.isEnabled) {
    groupFacets.push(createGroupFacet(ServiceCenterFacet, policy));
  }

  return groupFacets;
};

export default useProjectGroupFacets;
