import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center'
  },
  overAllocatedMark: {
    margin: theme.spacing(0, 0.5, 0, 2)
  },
  overAllocatedCaption: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  }
}));

export default useStyles;
