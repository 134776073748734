import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import useClientRepresentativeQuery from '~/modules/projects/graphql/useClientRepDropdownForProject';

const getOptionLabel = option => (option && option.displayText) || '';

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const useStyles = makeStyles(theme => ({
  option: {
    fontSize: theme.typography.body2.fontSize
  },
  noOptions: {
    color: theme.typography.body2.fontSize
  },
  loading: {
    color: theme.typography.body2.fontSize
  }
}));

export const SearchableClientRepDropdown = ({ clientUri, value, onChange }) => {
  const { formatMessage } = useIntl();
  const { loading, data } = useClientRepresentativeQuery(clientUri);

  const options = (data && data.getClientRepresentatives) || [];

  const classes = useStyles();

  const renderInput = useCallback(
    params => {
      return (
        <TextField
          {...params}
          label={formatMessage({ id: 'clientRepresentative.label' })}
        />
      );
    },
    [formatMessage]
  );

  const onSubmit = useCallback((_, val) => onChange(val), [onChange]);

  return (
    <Autocomplete
      classes={classes}
      loading={loading}
      options={options}
      size="small"
      loadingText={formatMessage({ id: 'taskBeatTable.loading' })}
      noOptionsText={formatMessage({
        id: 'clientRepresentative.noclientRepresentative'
      })}
      fullWidth
      value={value || ''}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={onSubmit}
      renderInput={renderInput}
    />
  );
};

SearchableClientRepDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  clientUri: PropTypes.string
};

export default SearchableClientRepDropdown;
