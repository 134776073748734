import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AdministrationIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="m 5,10 v 7 h 2 v -7 z m 6,0 v 7 h 2 V 10 Z M 3,22 H 21 V 19 H 3 Z M 17,10 v 7 h 2 V 10 Z M 12,1 3,6 V 8 H 21 V 6 Z" />
    </>
  </SvgIcon>
));

export default AdministrationIcon;
