export const PERIOD_SCALE_ENUM = {
  MONTHS: 'months',
  WEEKS: 'weeks',
  DAYS: 'days',
  YEARS: 'years',
  QUARTERS: 'quarters',
  OVERVIEW: 'overview'
};

export const PERIOD_RESOLUTION_ENUM = {
  [PERIOD_SCALE_ENUM.YEARS]: 'YEARLY',
  [PERIOD_SCALE_ENUM.QUARTERS]: 'QUARTERLY',
  [PERIOD_SCALE_ENUM.DAYS]: 'DAILY',
  [PERIOD_SCALE_ENUM.MONTHS]: 'MONTHLY',
  [PERIOD_SCALE_ENUM.WEEKS]: 'WEEKLY'
};

export const DEFAULT_PERIOD_SCALE = PERIOD_SCALE_ENUM.MONTHS;
