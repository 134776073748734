export const mapToLineItemInput = ({ billId, selectedItem }) => ({
  billId,
  lineItem: {
    amount: 0,
    projectUri: selectedItem ? selectedItem.id : null
  }
});

export const mapAmountToLineItemInput = ({ billId, selectedItem, amount }) => ({
  billId,
  lineItem: {
    id: selectedItem.id,
    amount,
    description: selectedItem.description,
    projectUri: selectedItem.project ? selectedItem.project.id : null
  }
});
