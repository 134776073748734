import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { withMe } from '~/modules/me';
import SearchBox from '../SearchBox/SearchBox';
import getFacets from './facets';

const useStyles = makeStyles(() => ({
  searchBoxContainer: {
    padding: 0
  },
  searchBox: {},
  searchFacet: {
    overflow: 'auto',
    maxHeight: 295
  }
}));

export const ResourceSearchBox = ({
  classes: classesOverride,
  me,
  setSearchCriteria,
  searchCriteria,
  dataQeId,
  externalSearch,
  filterFacetOptions = {
    role: true,
    skill: true,
    location: true,
    division: true,
    serviceCenter: true,
    costCenter: true,
    department: true,
    employeeType: true,
    tags: true,
    project: false,
    client: false,
    program: false,
    resourcePool: true
  }
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { formatMessage } = useIntl();
  const expandIcon = formatMessage({ id: 'resourcePoolSearchBox.expand' });

  const searchBoxClasses = useMemo(
    () => ({
      root: classes.searchBox,
      searchFacet: classes.searchFacet
    }),
    [classes.searchBox, classes.searchFacet]
  );

  const facets = useMemo(
    () =>
      getFacets({
        groupSettings: me.groupSettings,
        permissions: me.permissions,
        legacyDepartmentDisabled: me.legacyDepartmentDisabled,
        legacyEmployeeTypeDisabled: me.legacyEmployeeTypeDisabled,
        filterFacetOptions,
        featureFlags: me.featureFlags
      }),
    [
      me.groupSettings,
      me.permissions,
      me.legacyDepartmentDisabled,
      me.legacyEmployeeTypeDisabled,
      filterFacetOptions,
      me.featureFlags
    ]
  );

  return (
    <>
      {setSearchCriteria && (
        <div className={classes.searchBoxContainer}>
          <SearchBox
            dataQeId={dataQeId}
            searchIconAriaLabel={expandIcon}
            classes={searchBoxClasses}
            setSearchCriteria={setSearchCriteria}
            searchCriteria={searchCriteria}
            facets={facets}
            externalSearch={externalSearch}
          />
        </div>
      )}
    </>
  );
};

ResourceSearchBox.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object.isRequired,
  externalSearch: PropTypes.node,
  me: PropTypes.object,
  filterFacetOptions: PropTypes.object
};

export default withMe()(ResourceSearchBox);
