import { makeStyles } from '@material-ui/styles';

export const useActionStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'block'
  }
}));

export const useContentStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 550,
    minHeight: 350
  }
}));

export const useSpinnerStyles = makeStyles(theme => ({
  progress: {
    left: '50%',
    position: 'absolute',
    bottom: '50%'
  },
  spinner: {
    marginRight: theme.spacing(3),
    color: theme.palette.text.disabled
  }
}));
