import React from 'react';
import PropTypes from 'prop-types';
import FileIcon from './FileIcon';
import URLIcon from './URLIcon';

export const AttachmentIcon = ({ attachment, ...svgProps }) => {
  const { fileExtension } = attachment;

  return fileExtension.toLowerCase() === 'link' ? (
    <URLIcon url={attachment.linkUri} {...svgProps} />
  ) : (
    <FileIcon type={fileExtension} {...svgProps} />
  );
};

AttachmentIcon.propTypes = {
  attachment: PropTypes.object
};

export default AttachmentIcon;
