import { mapEnabledFieldsToVariables } from '~/modules/common/util';
import { getColumns } from './resourceAllocationDialogColumns';

const columnVarColumnMap = me => {
  const {
    currentCostCenter = false,
    currentServiceCenter = false,
    currentDepartment = false,
    currentEmployeeType = false,
    currentDivision = false,
    currentLocation = false,
    roles = false,
    resourcePools = false,
    supervisorAssignment = false,
    employeeId = false
  } = getColumns([], [], me);

  return {
    includeCurrentCostCenter: currentCostCenter,
    includeCurrentServiceCenter: currentServiceCenter,
    includeCurrentDepartment: currentDepartment,
    includeCurrentEmployeeType: currentEmployeeType,
    includeCurrentDivision: currentDivision,
    includeCurrentLocation: currentLocation,
    includeRoles: roles,
    includeResourcePools: resourcePools,
    includeSupervisorAssignment: supervisorAssignment,
    includeEmployeeId: employeeId
  };
};

export const enabledFieldsToVariables = ({ enabledFields, me }) =>
  mapEnabledFieldsToVariables(enabledFields, columnVarColumnMap(me));
