import { debounce, scope } from '~/modules/common/debounce';

const calculationStatusScope = scope();

const onBillDataUpdatedOnAvailableToBill = async ({
  billId,
  fetchCalculationStatus,
  refetchAvailableToBillTotals,
  onBillDataUpdated,
  refetchFilterSummary
}) => {
  const {
    data: { isBillDataReflectedInAvailableToBillData: status } = {}
  } = await fetchCalculationStatus({ billId });

  if (status) {
    if (refetchAvailableToBillTotals) {
      await refetchAvailableToBillTotals();
    }
    if (refetchFilterSummary) {
      await refetchFilterSummary();
    }
    onBillDataUpdated();
  } else {
    debounce(
      calculationStatusScope,
      async () => {
        await onBillDataUpdatedOnAvailableToBill({
          billId,
          fetchCalculationStatus,
          refetchAvailableToBillTotals,
          onBillDataUpdated
        });
      },
      500
    );
  }
};

export const useOnBillDataUpdatedOnAvailableToBill = ({
  billId,
  fetchCalculationStatus,
  refetchAvailableToBillTotals,
  refetchFilterSummary
}) => {
  return {
    onBillDataUpdatedOnAvailableToBill: async onBillDataUpdated => {
      await onBillDataUpdatedOnAvailableToBill({
        billId,
        fetchCalculationStatus,
        refetchAvailableToBillTotals,
        onBillDataUpdated,
        refetchFilterSummary
      });
    }
  };
};

export default useOnBillDataUpdatedOnAvailableToBill;
