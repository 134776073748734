import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  BillingOverviewHeaderRow,
  BillingOverviewValueRow,
  EstimatedBillingValueRowGroup
} from './components';

export const EstimatedBillingGroup = ({
  estimatedBilling,
  displayTotalColumn
}) => {
  const [estimatedBillingExpanded, setEstimatedBillingExpanded] = useState(
    false
  );

  return (
    <>
      <BillingOverviewHeaderRow dataQeId="billPlan">
        <FormattedMessage id="billingOverviewTable.billPlan" />
      </BillingOverviewHeaderRow>
      <BillingOverviewValueRow
        rowData={estimatedBilling.totalHours}
        rowTitle="billingOverviewTable.hours"
        displayTotalColumn={displayTotalColumn}
      />
      <EstimatedBillingValueRowGroup
        sectionData={estimatedBilling}
        expanded={estimatedBillingExpanded}
        onExpandChange={setEstimatedBillingExpanded}
        displayTotalColumn={displayTotalColumn}
      />
    </>
  );
};

EstimatedBillingGroup.propTypes = {
  estimatedBilling: PropTypes.object,
  displayTotalColumn: PropTypes.bool
};

export default EstimatedBillingGroup;
