export const isValid = obj =>
  obj &&
  typeof obj === 'object' &&
  Object.prototype.hasOwnProperty.call(obj, 'id') &&
  obj.id;

export const isValidValueOrNull = value =>
  isValid(value)
    ? {
        id: value.id,
        displayText: value.displayText
      }
    : null;

export const isValidProgramOrNull = value =>
  isValid(value)
    ? {
        id: value.id,
        slug: value.slug,
        displayText: value.displayText
      }
    : null;
