import React, { useContext } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { makeStyles, Table as MuiTable } from '@material-ui/core';
import ListTableVariantContext from '../../../ListTableVariantContext';

const useStyles = makeStyles(theme => ({
  table: {
    display: 'table'
  }
}));

const Table = ({ className, classes: classesOverride, children }) => {
  const classes = useStyles({ classes: classesOverride });
  const variant = useContext(ListTableVariantContext);

  const Component = variant === 'table' ? MuiTable : 'div';

  return (
    <Component className={classNames(classes.table, className)}>
      {children}
    </Component>
  );
};

Table.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string
};

export default Table;
