import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles, Paper, Toolbar as MuiToolbar } from '@material-ui/core';
import ListTableToolbarContents from './ListTableToolbarContents';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    borderBottom: `1px solid ${theme.palette.table.border}`,
    flexDirection: 'row'
  }
}));

export const ListTableToolBar = ({
  onSettingsClick,
  showSettingsButton = true,
  children
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={0}>
      <MuiToolbar className={classes.toolbar} disableGutters>
        <ListTableToolbarContents
          onSettingsClick={onSettingsClick}
          showSettingsButton={showSettingsButton}
        >
          {children}
        </ListTableToolbarContents>
      </MuiToolbar>
    </Paper>
  );
};

ListTableToolBar.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
  showSettingsButton: PropTypes.bool,
  children: PropTypes.node
};

export default ListTableToolBar;
