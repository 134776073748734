import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    display: 'flex',
    alignItems: 'center'
  },
  alert: {
    alignItems: 'center',
    width: '100%',
    fontSize: theme.spacing(1.75)
  },
  errorTitle: {
    display: 'flex',
    width: '100%'
  },
  errorContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.light,
    left: 0,
    position: 'sticky'
  },
  successTitle: {
    display: 'flex',
    width: '100%'
  },
  successContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.success.light,
    left: 0,
    position: 'sticky'
  },
  textControl: {
    marginLeft: theme.spacing(3.2)
  }
}));

export const useInProgressStyles = makeStyles(theme => ({
  root: {
    height: 6,
    borderRadius: 5
  },
  bar: {
    borderRadius: 5
  }
}));

export default useStyles;
