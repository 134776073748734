import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, -1, 0.5)
  },
  duration: {
    padding: theme.spacing(1, 0, 1, 0)
  }
}));

export const OpenEndedTaskIcon = ({ classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });
  const intl = useIntl();

  return (
    <div className={classes.duration}>
      <FormattedMessage id="rescheduleTaskDialog.openEndedTask" />
      <Tooltip
        title={intl.formatMessage({
          id: 'rescheduleTaskDialog.openEndedTaskTooltip'
        })}
        placement="top"
      >
        <InfoIcon className={classes.infoIcon} />
      </Tooltip>
    </div>
  );
};

OpenEndedTaskIcon.propTypes = {
  classes: PropTypes.object
};

export default OpenEndedTaskIcon;
