import get from 'lodash.get';
import { useGetEnabledCustomFieldsQuery } from './useGetEnabledCustomFieldsQuery';

export const useGetRequiredCustomFieldsQuery = options => {
  const { data } = useGetEnabledCustomFieldsQuery(options);

  const customFieldDefinitions = get(data, 'enabledCustomFields') || [];

  return customFieldDefinitions.filter(d => d.isRequired && d.isVisible);
};
