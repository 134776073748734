import React from 'react';
import { PropTypes } from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { MobileListItemLoading } from '~/modules/common/components/DetailsPage';
import { MobileList } from '~/modules/common/components/ListTable/MobileList';
import { MobileResourceRequestItem } from './components';
import { useStyles } from './useStyles';

const MobileResourceRequests = ({
  resourceRequests,
  loadMoreRows,
  hasMoreRows,
  loadingRows,
  isEditSwitchEnabled
}) => {
  const classes = useStyles();

  return (
    <>
      <MobileList
        classes={classes}
        mobileListItem={MobileResourceRequestItem}
        records={resourceRequests}
        editable={isEditSwitchEnabled}
        subHeaderId="mobileResourcePlanning.resourceRequests"
      />
      {!loadingRows && hasMoreRows && (
        <Waypoint
          fireOnRapidScroll
          bottomOffset="-50%"
          onEnter={loadMoreRows}
        />
      )}
      {loadingRows && <MobileListItemLoading />}
    </>
  );
};

MobileResourceRequests.propTypes = {
  resourceRequests: PropTypes.array.isRequired,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  hasMoreRows: PropTypes.bool.isRequired,
  loadingRows: PropTypes.bool.isRequired
};

export default MobileResourceRequests;
