import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const styles = makeStyles({
  missingDateBarContainer: {
    cursor: 'pointer',
    marginTop: theme.spacing(-0.25),
    backgroundColor: '#f7f7f7',
    display: 'flex',
    '&:hover': {
      '& $dateMarker': {
        boxShadow: 'rgba(0,0,0,1) 0 0 0 1.5px'
      },
      '& $missingDateContainer': {
        boxShadow: 'rgba(0,0,0,1) 0 0 0 1px'
      }
    }
  },
  dateMarker: ({ taskStatus, hasChild }) => ({
    borderRadius: hasChild ? 0 : theme.spacing(1),
    backgroundColor: theme.palette.taskStatus[taskStatus].color,
    height: hasChild ? theme.spacing(3) : theme.spacing(2),
    width: theme.spacing(0.5),
    marginTop: hasChild ? 0 : theme.spacing(0.5),
    zIndex: 1,
    transition: 'box-shadow 0.2s',
    boxShadow: 'rgba(0,0,0,0) 0 0 0 1px',
    pointerEvents: 'none'
  }),
  missingDateContainer: ({ startDate, endDate }) => ({
    marginTop: theme.spacing(0.33),
    height: theme.spacing(2.25),
    backgroundColor: '#ebebeb',
    fontSize: theme.spacing(1.5) - 1,
    color: '#656565',
    paddingLeft: theme.spacing(1.25),
    paddingTop: theme.spacing(0.25),
    display: 'flex',
    borderRadius:
      !startDate && !endDate
        ? theme.spacing(0.33)
        : !startDate
        ? theme.spacing(0.33, 0, 0, 0.33)
        : theme.spacing(0, 0.33, 0.33, 0),
    transition: 'box-shadow 0.2s',
    boxShadow: 'rgba(0,0,0,0) 0 0 0 1px',
    pointerEvents: 'none'
  }),
  missingDateIcon: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    marginRight: theme.spacing(0.75),
    fill: theme.palette.error.dark,
    pointerEvents: 'none'
  }
});

const GanttMissingDateBar = ({
  task,
  intl: { formatMessage },
  hasChild = false
}) => {
  const { taskStatus, startDate, endDate } = task;
  const classes = styles({
    taskStatus,
    startDate,
    endDate,
    hasChild
  });

  const areDatesMissing = !startDate || !endDate;
  const isEndDateMissing = startDate && !endDate;
  const isStartDateMissing = !startDate && endDate;
  const missingDateText = formatMessage({
    id: isStartDateMissing
      ? 'projectTasksPage.startDateMissing'
      : isEndDateMissing
      ? 'projectTasksPage.endDateMissing'
      : 'projectTasksPage.datesAreMissing'
  });

  return areDatesMissing ? (
    <div className={classes.missingDateBarContainer}>
      {isEndDateMissing && <div className={classes.dateMarker} />}
      <div className={classes.missingDateContainer}>
        {missingDateText}
        <ReportProblemOutlinedIcon className={classes.missingDateIcon} />
      </div>
      {isStartDateMissing && <div className={classes.dateMarker} />}
    </div>
  ) : null;
};

GanttMissingDateBar.propTypes = {
  task: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  hasChild: PropTypes.bool
};

export default GanttMissingDateBar;
