import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const OverbudgetTitle = ({ messageId, highlightMsgId }) => {
  const values = useMemo(() => ({
    suffix: (
      <strong>
        <FormattedMessage id={highlightMsgId} />
      </strong>
    )
  }));

  return <FormattedMessage id={messageId} values={values} />;
};

OverbudgetTitle.propTypes = {
  messageId: PropTypes.string,
  highlightMsgId: PropTypes.string
};

export default OverbudgetTitle;
