import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import useStyledGroupIcons from './useStyledGroupIcons';
import useGroupLabels from './useGroupLabels';

const useStyles = makeStyles(theme => ({
  label: {
    paddingLeft: theme.spacing(0.5),
    display: 'inline-block',
    marginTop: theme.spacing(0.25)
  }
}));

export const useStyledGroupLabels = (requiredMap = {}) => {
  const classes = useStyles();

  const groupIconMap = useStyledGroupIcons();
  const groupLabelMap = useGroupLabels();

  return useMemo(
    () =>
      Object.keys(groupIconMap).reduce((acc, key) => {
        return {
          ...acc,
          [`${key}`]: (
            <>
              {groupIconMap[key]}
              <span className={classes.label}>
                {groupLabelMap[key] + (requiredMap[key] ? ' *' : '')}
              </span>
            </>
          )
        };
      }, {}),
    [classes, groupIconMap, groupLabelMap, requiredMap]
  );
};

export default useStyledGroupLabels;
