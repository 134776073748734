import { useMemo } from 'react';
import { BillStatus, BillingTransactionBalanceStatus } from '~/types';
import { useMeContext } from '~/modules/me';
import { BILLING_INVOICING_PERMISSION_URIS } from '~/modules/common/enums/BillingInvoicingPermissions';

const hasEditInvoicePermission = permittedActions => {
  return permittedActions.includes(
    'urn:replicon:invoice2-action:edit-invoices'
  );
};

export const useHasBillPermission = ({ bill = null, billPermission }) => {
  const me = useMeContext();

  const { permittedActions } = billPermission || {};

  return useMemo(
    () => ({
      canEditBasicDetailOrDeleteBill:
        Boolean(
          me.permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_BILL]
        ) &&
        Boolean(
          bill &&
            bill.billStatus === BillStatus.Draft &&
            permittedActions &&
            hasEditInvoicePermission(permittedActions)
        ),
      canEditBill:
        Boolean(
          me.permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_BILL]
        ) && Boolean(bill && bill.billStatus !== BillStatus.Void),
      canIssueBill: Boolean(
        bill &&
          (bill.billStatus === BillStatus.Draft ||
            bill.billStatus === BillStatus.Billed) &&
          Boolean(
            permittedActions && hasEditInvoicePermission(permittedActions)
          )
      ),
      canVoidBill:
        Boolean(
          me.permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VOID_BILL]
        ) && Boolean(bill && bill.billStatus === BillStatus.Billed),
      isBillIssuedAndPaid:
        Boolean(bill && bill.billStatus === BillStatus.Billed) &&
        bill.balanceStatus === BillingTransactionBalanceStatus.FullyAllocated,
      canAddPaymentOrCreditMemo:
        Boolean(
          me.permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_BILL]
        ) &&
        Boolean(
          bill &&
            (bill.billStatus === BillStatus.Draft ||
              (bill.billStatus !== BillStatus.Void &&
                bill.balanceStatus !==
                  BillingTransactionBalanceStatus.FullyAllocated))
        ),
      canSyncBill: Boolean(
        me.permissions.find(
          p =>
            p.permissionActionUri ===
            'urn:replicon-webui:invoice2-action:sync-invoices'
        )
      ),
      canReopenBill:
        Boolean(
          me.permissionsMap[BILLING_INVOICING_PERMISSION_URIS.REOPEN_BILL]
        ) && Boolean(bill && bill.billStatus === BillStatus.Billed)
    }),
    [bill, me.permissions, me.permissionsMap, permittedActions]
  );
};
