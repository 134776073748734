import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const ELIGIBLE_PORTFOLIO_MANAGERS = gql`
  query getEligiblePortfolioManagers {
    getEligiblePortfolioManagers {
      id
      displayText
    }
  }
`;

export const useEligiblePortfolioManagers = () => {
  const { data } = useQuery(ELIGIBLE_PORTFOLIO_MANAGERS);

  return {
    portfolioManagers: data?.getEligiblePortfolioManagers || []
  };
};

export default useEligiblePortfolioManagers;
