import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { isoToReplicon } from '~/util';
import { mapToServiceFilter } from '../utils';

export const availableToBillRowsQuery = gql`
  query getPageOfAvailableToBill(
    $page: Int!
    $pageSize: Int!
    $filter: AvailableToBillFilter2
  ) {
    getPageOfAvailableToBill: getPageOfAvailableToBill2(
      page: $page
      pageSize: $pageSize
      filter: $filter
    ) {
      id
      client {
        id
        displayText
      }
      startDate: startDate2
      endDate: endDate2
      amount {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

export const useAvailableToBillRows = ({
  page = 1,
  pageSize = 100,
  projectUri,
  client,
  programId,
  availableToBillFilter
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const {
    data,
    fetchMore,
    loading,
    refetch: refetchAvailableToBillRows,
    variables
  } = useQuery(availableToBillRowsQuery, {
    variables: {
      page,
      pageSize,
      filter: mapToServiceFilter({
        projectUri,
        client,
        programUri: programId,
        availableToBillFilter
      })
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const availableToBillRows = get(data, 'getPageOfAvailableToBill', []).map(
    availableBill => ({
      ...availableBill,
      startDate: availableBill.startDate
        ? isoToReplicon(availableBill.startDate)
        : availableBill.startDate,
      endDate: availableBill.endDate
        ? isoToReplicon(availableBill.endDate)
        : availableBill.endDate
    })
  );

  const hasMoreRows =
    availableToBillRows &&
    availableToBillRows.length !== 0 &&
    availableToBillRows.length % variables.pageSize === 0;

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows || isLoadingMore) {
      return;
    }

    setIsLoadingMore(true);

    try {
      await fetchMore({
        query: availableToBillRowsQuery,
        variables: {
          page: availableToBillRows.length / variables.pageSize + 1,
          filter: variables.filter,
          pageSize: variables.pageSize
        },
        updateQuery: (
          previousResult,
          { fetchMoreResult: { getPageOfAvailableToBill: nextResult } }
        ) => ({
          getPageOfAvailableToBill: [
            ...previousResult.getPageOfAvailableToBill,
            ...nextResult
          ]
        })
      });
    } finally {
      setIsLoadingMore(false);
    }
  }, [
    availableToBillRows.length,
    fetchMore,
    setIsLoadingMore,
    isLoadingMore,
    hasMoreRows,
    variables.filter,
    variables.pageSize
  ]);

  return {
    availableToBillRows,
    hasMoreRows,
    isLoadingMore,
    refetchAvailableToBillRows,
    loadMoreRows,
    isRowsLoading: loading
  };
};
