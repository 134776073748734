import React from 'react';
import PropTypes from 'prop-types';
import { Decimal } from '~/modules/common/components';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import useStyles from './useStyles';

export const TimelineBlock = ({
  classes: classesOverrides,
  percentage,
  showPercentage = true,
  hours,
  dataQeId,
  onClick
}) => {
  const { displayUnit } = useDisplayUnitContext();

  const classes = useStyles({ classes: classesOverrides });

  return (
    <div
      className={classes.container}
      data-qe-id={dataQeId}
      role="presentation"
      onClick={onClick}
    >
      <div className={classes.label}>
        {displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE && showPercentage ? (
          <Decimal
            dataQeId="Decimal"
            value={percentage}
            suffix="%"
            fixedDecimalScale={false}
            enableFixedFormatDecimalScale
          />
        ) : (
          <Decimal
            dataQeId="Decimal"
            value={hours}
            suffix="h"
            fixedDecimalScale={false}
            enableFixedFormatDecimalScale
          />
        )}
      </div>
    </div>
  );
};

TimelineBlock.propTypes = {
  classes: PropTypes.object,
  percentage: PropTypes.number,
  showPercentage: PropTypes.bool,
  hours: PropTypes.number,
  dataQeId: PropTypes.string,
  onClick: PropTypes.func
};

export default TimelineBlock;
