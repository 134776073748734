import { useMemo } from 'react';

import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { ProjectManagementType } from '~/types';

import { useProjectsStatusCountSummaryQuery } from '~/modules/common/hooks';

export default () => {
  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });
  const isManaged = useMemo(
    () => projectType === ProjectManagementType.Managed,
    [projectType]
  );

  const {
    loading,
    projectsStatusCountSummary
  } = useProjectsStatusCountSummaryQuery({
    projectFilter,
    isManaged
  });

  return { isLoading: loading, projectsStatusCountSummary };
};
