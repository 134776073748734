import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { isoToReplicon } from '~/util/date';
import { mapCreditMemoToAssociatedBills } from '~/modules/billing-invoicing/common/util';

export const creditMemoQuery = gql`
  query getCreditMemo($id: String!) {
    getCreditMemo(id: $id) {
      id
      displayId
      description
      client {
        id
        displayText
      }
      currency {
        id
        symbol
        displayText
      }
      balanceStatus
      creditDate: creditDate2
      associatedBills(page: 1, pageSize: 20) {
        associatedBills {
          id
          displayText
          dueDate: dueDate2
          balanceAmount {
            amount
            currency {
              id
              symbol
            }
          }
          allocatedBillAmount {
            amount
            currency {
              id
              displayText
              symbol
            }
          }
          allocatedCreditMemoAmount {
            amount
            currency {
              id
              displayText
              symbol
            }
          }
        }
      }
    }
  }
`;

export const useCreditMemoDetails = ({ id }) => {
  const { data, loading } = useQuery(creditMemoQuery, {
    variables: {
      id
    },
    skip: !id,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const getCreditMemo = get(data, 'getCreditMemo', { associatedBills: {} });
  const {
    associatedBills: { associatedBills }
  } = getCreditMemo;

  return {
    loading,
    creditMemo: {
      ...getCreditMemo,
      creditDate: isoToReplicon(getCreditMemo.creditDate),
      associatedBills: mapCreditMemoToAssociatedBills(associatedBills)
    }
  };
};

export default useCreditMemoDetails;
