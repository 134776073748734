import { useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import flow from 'lodash/fp/flow';
import omit from 'lodash/fp/omit';
import update from 'lodash/fp/update';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { array, mixed, object } from 'yup';
import { toRepliconDate } from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me';

const omitForbiddenFields = flow(
  omit('__typename'),
  update('currency', flow(omit('__typename')))
);

const getDuplicateRateIds = rates => {
  const idMap = {};

  return Object.keys(
    rates
      .map(rate => rate.id)
      .reduce((duplicates, current) => {
        if (!idMap[current]) {
          idMap[current] = true;

          return duplicates;
        }

        return { ...duplicates, [current]: current };
      }, {})
  );
};

const getFirstEntryMissingEffectiveDate = scheduleEntries =>
  (scheduleEntries.find(entry => entry.isNewEntry) || {}).entryId;

const getFirstEntryWithDuplicateEffectiveDate = scheduleEntries => {
  const dateKeyMap = {};
  let retVal;

  const entries = scheduleEntries.filter(entry => entry.effectiveDate);

  for (const current of entries) {
    const dateKey = current.effectiveDate.toFormat('yyyy-MM-dd');

    if (dateKeyMap[dateKey]) {
      retVal = dateKeyMap[dateKey].entryId;
      break;
    }
    dateKeyMap[dateKey] = current;
  }

  return retVal;
};

const getScheduleEntriesValidationClause = intl =>
  array()
    .test({
      name: 'noEffectiveDate',
      message: scheduleEntries => ({
        entryWithValidationError: getFirstEntryMissingEffectiveDate(
          scheduleEntries.value
        ),
        error: intl.formatMessage({
          id: 'rateCard.validations.emptyEffectiveDate'
        })
      }),
      test: scheduleEntries => !scheduleEntries.some(entry => entry.isNewEntry)
    })
    .test({
      name: 'duplicateEffectiveDate',
      message: scheduleEntries => ({
        entryWithValidationError: getFirstEntryWithDuplicateEffectiveDate(
          scheduleEntries.value
        ),
        error: intl.formatMessage({
          id: 'rateCard.validations.duplicateEffectiveDate'
        })
      }),
      test: scheduleEntries =>
        !getFirstEntryWithDuplicateEffectiveDate(scheduleEntries)
    });

export const useValidationSchema = ({ intl }) =>
  object().shape({
    projectRate: array()
      .of(
        object().shape({
          billingScheduleEntries: getScheduleEntriesValidationClause(intl),
          costScheduleEntries: getScheduleEntriesValidationClause(intl)
        })
      )
      .test({
        name: 'duplicatedRole',
        message: rates => ({
          duplicates: getDuplicateRateIds(rates.value),
          error: intl.formatMessage({
            id: 'rateCard.validations.duplicateRole'
          })
        }),
        test: rates => getDuplicateRateIds(rates).length <= 0
      }),
    roleRates: array()
      .of(
        object().shape({
          roleReference: mixed().required(
            intl.formatMessage({
              id: 'rateCard.validations.roleIsRequired'
            })
          ),
          billingScheduleEntries: getScheduleEntriesValidationClause(intl),
          costScheduleEntries: getScheduleEntriesValidationClause(intl)
        })
      )
      .test({
        name: 'duplicatedRole',
        message: rates => ({
          duplicates: getDuplicateRateIds(rates.value),
          error: intl.formatMessage({
            id: 'rateCard.validations.duplicateRole'
          })
        }),
        test: rates => getDuplicateRateIds(rates).length <= 0
      }),
    userRates: array()
      .of(
        object().shape({
          userReference: mixed().required(
            intl.formatMessage({
              id: 'rateCard.validations.userIsRequired'
            })
          ),
          billingScheduleEntries: getScheduleEntriesValidationClause(intl),
          costScheduleEntries: getScheduleEntriesValidationClause(intl)
        })
      )
      .test({
        name: 'duplicatedUser',
        message: rates => ({
          duplicates: getDuplicateRateIds(rates.value),
          error: intl.formatMessage({
            id: 'rateCard.validations.duplicateUser'
          })
        }),
        test: rates => getDuplicateRateIds(rates).length <= 0
      })
  });

const mapScheduleEntries = baseCurrency => scheduleEntries => {
  const idValue = v4();

  const entries =
    scheduleEntries && scheduleEntries.length
      ? scheduleEntries.map(schedule => ({
          ...schedule,
          key: schedule.entryId
        }))
      : [
          {
            entryId: idValue,
            key: idValue,
            isEffectiveAsOfToday: true,
            amount: { amount: 0, currency: baseCurrency }
          }
        ];

  return entries;
};

const mapRateToValue = baseCurrency => ({
  billingScheduleEntries,
  costScheduleEntries,
  ...rest
}) => ({
  ...rest,
  key: v4(),
  billingScheduleEntries: mapScheduleEntries(baseCurrency)(
    billingScheduleEntries
  ),
  costScheduleEntries: mapScheduleEntries(baseCurrency)(costScheduleEntries)
});

export const useInitialState = ({
  me: { baseCurrency },
  projectRate = [],
  roleRates = [],
  userRates = []
}) => {
  const values = useMemo(
    () => ({
      baseCurrency,
      projectRate: projectRate.map(mapRateToValue(baseCurrency)),
      roleRates: roleRates.map(mapRateToValue(baseCurrency)),
      userRates: userRates.map(mapRateToValue(baseCurrency))
    }),
    [baseCurrency, projectRate, roleRates, userRates]
  );

  return values;
};

const mapToScheduleEntryInput = ({ effectiveDate, amount }) => ({
  effectiveDate: toRepliconDate(effectiveDate),
  amount: omitForbiddenFields(amount)
});

export const useOnSubmit = ({ putRatesHandler, parentUri }) =>
  useCallback(
    async values => {
      const { projectRate, roleRates, userRates } = values;

      const rates = [
        ...(projectRate.length
          ? projectRate.map(
              ({ billingScheduleEntries, costScheduleEntries }) => ({
                rateType: 'PROJECT',
                billingScheduleEntries: billingScheduleEntries.map(
                  mapToScheduleEntryInput
                ),
                costScheduleEntries: costScheduleEntries.map(
                  mapToScheduleEntryInput
                )
              })
            )
          : [
              {
                rateType: 'PROJECT',
                billingScheduleEntries: [],
                costScheduleEntries: []
              }
            ]),
        ...(roleRates.length
          ? roleRates.map(
              ({
                billingScheduleEntries,
                costScheduleEntries,
                roleReference
              }) => ({
                rateType: 'ROLE',
                roleId: roleReference.id,
                billingScheduleEntries: billingScheduleEntries.map(
                  mapToScheduleEntryInput
                ),
                costScheduleEntries: costScheduleEntries.map(
                  mapToScheduleEntryInput
                )
              })
            )
          : [
              {
                rateType: 'ROLE',
                billingScheduleEntries: [],
                costScheduleEntries: []
              }
            ]),
        ...(userRates.length
          ? userRates.map(
              ({
                billingScheduleEntries,
                costScheduleEntries,
                userReference
              }) => ({
                rateType: 'RESOURCE',
                userId: userReference.id,
                billingScheduleEntries: billingScheduleEntries.map(
                  mapToScheduleEntryInput
                ),
                costScheduleEntries: costScheduleEntries.map(
                  mapToScheduleEntryInput
                )
              })
            )
          : [
              {
                rateType: 'RESOURCE',
                billingScheduleEntries: [],
                costScheduleEntries: []
              }
            ])
      ];

      await putRatesHandler({
        rates,
        parentId: parentUri
      });
    },
    [parentUri, putRatesHandler]
  );

export const useFormState = ({
  projectRate,
  roleRates,
  userRates,
  parentUri,
  putRatesHandler
}) => {
  const me = useMeContext();

  const initialValues = useInitialState({
    me,
    projectRate,
    roleRates,
    userRates
  });

  const onSubmit = useOnSubmit({ putRatesHandler, parentUri });

  const intl = useIntl();
  const validationSchema = useValidationSchema({ intl });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  });

  return formik;
};
