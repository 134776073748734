import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormattedMessage } from 'react-intl';
import TaxProfileOption from './TaxProfileOption';

const noOptionsText = <FormattedMessage id="client.noTaxProfile" />;

const getOptionLabel = option => option.displayText;
const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const SearchableTaxProfileDropdown = ({
  onChange,
  value,
  label,
  hasError,
  helperText,
  loading,
  onInputChange,
  options
}) => {
  const renderInput = useCallback(
    params => <TextField {...params} label={label} />,
    [label]
  );

  return (
    <>
      <Autocomplete
        loading={loading}
        forcePopupIcon
        fullWidth
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        getOptionSelected={getOptionSelected}
        noOptionsText={noOptionsText}
        renderOption={renderOption}
      />
      {helperText && (
        <FormHelperText error={hasError}>{helperText}</FormHelperText>
      )}
    </>
  );
};

const renderOption = option => <TaxProfileOption taxProfile={option} />;

SearchableTaxProfileDropdown.propTypes = {
  classes: PropTypes.object,
  components: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  noTaxProfileMessage: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
  options: PropTypes.array
};

export default SearchableTaxProfileDropdown;
