import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    flexBasis: '8em',
    padding: theme.spacing(1),
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  grow: {
    flexGrow: 1
  },
  shrink: {
    minWidth: '8rem',
    flexShrink: 1
  },
  xs: {
    flexBasis: theme.typography.pxToRem(42)
  },
  sm: {
    flexBasis: theme.typography.pxToRem(62)
  },
  md: {
    flexBasis: theme.typography.pxToRem(80)
  },
  lg: {
    flexBasis: theme.typography.pxToRem(180)
  },
  xl: {
    flexBasis: theme.typography.pxToRem(240)
  },
  xxl: {
    flexBasis: theme.typography.pxToRem(500)
  }
});

const flexToClasses = (flexSetting, classes) =>
  ({
    grow: classes.grow,
    shrink: classes.shrink,
    both: classNames(classes.grow, classes.shrink),
    none: undefined
  }[flexSetting]);

export const ResponsiveTableColumn = ({
  classes,
  children,
  flex = 'none',
  size = 'md',
  className,
  ...rest
}) => (
  <div
    className={classNames(
      classes.root,
      classes[size],
      flexToClasses(flex, classes),
      className
    )}
    {...rest}
  >
    {children}
  </div>
);

ResponsiveTableColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  flex: PropTypes.oneOf(['grow', 'shrink', 'both', 'none']),
  children: PropTypes.node,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  className: PropTypes.string
};

export default withStyles(styles)(ResponsiveTableColumn);
