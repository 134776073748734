import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useHasPermission } from '~/modules/common/permissions';
import { useBillPlanError, useBillPlanSuccess } from './hooks';
import { BillPlanError, BillPlanSuccess } from './components';

const taskKeyUri = 'urn:replicon:script-key:parameter:task';

export const BillPlanPreRequisites = ({
  projectDetails,
  scriptDetails,
  clientUri
}) => {
  const {
    canEditBillingRates,
    canViewBillingRates
  } = projectDetails.projectPermissions;
  const canViewTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-tasks'
  });

  const canEditTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-tasks'
  });

  const isExistingBillPlan = useMemo(
    () =>
      scriptDetails &&
      scriptDetails.scripts &&
      scriptDetails.scripts.length &&
      scriptDetails.scripts[0][taskKeyUri] &&
      !!Object.keys(scriptDetails.scripts[0][taskKeyUri]).length,
    [scriptDetails]
  );

  const preRequisiteValues = scriptDetails.billPlanPreRequisites;
  const { slug } = scriptDetails;

  const {
    errorTitle,
    milestoneTaskAvailable,
    rateAvailable,
    ratesCount,
    rates,
    hasViewRatePermission,
    hasViewEditRatePermission
  } = useBillPlanError({
    projectDetails,
    preRequisiteValues,
    billPlanSlug: slug,
    canEditParams: canEditTasks,
    canViewParams: canViewTasks,
    isExistingBillPlan,
    canViewBillingRates,
    canEditBillingRates
  });

  const [milestoneTaskCreated, setMilestoneTaskCreated] = useState(false);

  const [taskAddedCount, setTaskAddedCount] = useState(0);

  const [ratesModifier, setRatesModifier] = useState({
    clientRates: false,
    rateCreated: false,
    ratesAddedCount: 0,
    projectRateCount: 0,
    specificRate: ''
  });

  const { successTitle } = useBillPlanSuccess({
    milestoneTaskCreated,
    taskAddedCount,
    ratesModifier
  });

  return (
    <>
      {errorTitle && !successTitle && (
        <BillPlanError
          title={errorTitle}
          clientUri={clientUri}
          projectDetails={projectDetails}
          milestoneTaskAvailable={milestoneTaskAvailable}
          rateAvailable={rateAvailable}
          setTaskAddedCount={setTaskAddedCount}
          setMilestoneTaskCreated={setMilestoneTaskCreated}
          setRatesModifier={setRatesModifier}
          ratesCount={ratesCount}
          rates={rates}
          hasViewRatePermission={hasViewRatePermission}
          hasViewEditRatePermission={hasViewEditRatePermission}
        />
      )}
      {successTitle && (
        <BillPlanSuccess
          title={successTitle}
          milestoneTaskAvailable={milestoneTaskAvailable}
          rateAvailable={rateAvailable}
          projectDetails={projectDetails}
          setMilestoneTaskCreated={setMilestoneTaskCreated}
          setTaskAddedCount={setTaskAddedCount}
          milestoneTaskCreated={milestoneTaskCreated}
          taskAddedCount={taskAddedCount}
          clientRates={ratesModifier.clientRates}
          setRatesModifier={setRatesModifier}
          rates={rates}
          clientUri={clientUri}
        />
      )}
    </>
  );
};

BillPlanPreRequisites.propTypes = {
  projectDetails: PropTypes.object,
  clientUri: PropTypes.string,
  scriptDetails: PropTypes.object
};

export default BillPlanPreRequisites;
