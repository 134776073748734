import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import getAllocationsTotalForResourceRequest from '~/modules/resourcing/hooks/getAllocationsTotalForResourceRequest';
import { FormattedHoursAndCost } from '~/modules/resourcing/common/components';
import { ChartTotalItems } from '~/modules/common/charts/timeline/components';
import RowTotalHours from '~/modules/resourcing/components/RowTotalHours';
import RowTotalCost from '~/modules/resourcing/components/RowTotalCost';
import { useMeContext } from '~/modules/me';

export const AllocatedResourceRequestRowTotal = ({
  qeIdTag,
  resourceAllocations,
  resourceRequestTotalHours,
  resourceRequestTotalCost
}) => {
  const me = useMeContext();
  const plannedCost = [resourceRequestTotalCost];
  const { permissionsMap } = me;

  const hasCostPermissions = Boolean(
    permissionsMap['urn:replicon:user-action:view-cost-data']
  );

  const requested = useMemo(
    () => (
      <FormattedHoursAndCost
        hours={resourceRequestTotalHours}
        costs={plannedCost}
        hideCosts={!hasCostPermissions}
        showAbbreviatedValue={false}
      />
    ),
    [plannedCost, resourceRequestTotalHours, hasCostPermissions]
  );

  const {
    totalAllocatedHoursForResourceRequest,
    totalAllocatedCostForResourceRequest
  } = getAllocationsTotalForResourceRequest({ resourceAllocations, me });

  const allocated = useMemo(
    () => (
      <FormattedHoursAndCost
        hours={totalAllocatedHoursForResourceRequest}
        costs={totalAllocatedCostForResourceRequest}
        hideCosts={!hasCostPermissions}
        showAbbreviatedValue={false}
      />
    ),
    [
      totalAllocatedCostForResourceRequest,
      totalAllocatedHoursForResourceRequest,
      hasCostPermissions
    ]
  );

  const title = useMemo(
    () =>
      hasCostPermissions ? (
        <>
          <RowTotalHours
            qeIdTag={qeIdTag}
            showAbbreviatedValue={false}
            allocatedHours={totalAllocatedHoursForResourceRequest}
            plannedHours={resourceRequestTotalHours}
          />
          &nbsp;
          <RowTotalCost
            qeIdTag={qeIdTag}
            showAbbreviatedValue={false}
            allocatedCost={totalAllocatedCostForResourceRequest}
            plannedCost={plannedCost}
          />
        </>
      ) : (
        <RowTotalHours
          qeIdTag={qeIdTag}
          showAbbreviatedValue={false}
          allocatedHours={totalAllocatedHoursForResourceRequest}
          plannedHours={resourceRequestTotalHours}
        />
      ),
    [
      qeIdTag,
      plannedCost,
      totalAllocatedCostForResourceRequest,
      resourceRequestTotalHours,
      totalAllocatedHoursForResourceRequest,
      hasCostPermissions
    ]
  );

  return (
    <ChartTotalItems
      qeIdTag={qeIdTag}
      requested={requested}
      allocated={allocated}
      title={title}
    />
  );
};

AllocatedResourceRequestRowTotal.propTypes = {
  qeIdTag: PropTypes.string,
  resourceAllocations: PropTypes.array,
  resourceRequestTotalHours: PropTypes.number.isRequired,
  resourceRequestTotalCost: PropTypes.object.isRequired
};

export default memo(AllocatedResourceRequestRowTotal);
