import { gql } from 'graphql-tag';
import { projectListItemFragment } from '~/modules/common/fragments';
import { columnArguments } from '~/modules/common/hooks/project';

export const GET_PROJECTS_TO_ADD_TO_PORTFOLIO_QUERY = gql`
  query GetProjectsToAddToPortfolioQuery(
    $page: Int!
    $pageSize: Int!
    $projectFilter: ProjectFilter
    $sort: ProjectSort
    $isManaged: Boolean
    ${`$isPSAPercentageBudgetScopeEnabled: Boolean = false`},
    ${columnArguments}
  ) {
    projects(
      page: $page
      pagesize: $pageSize
      projectFilter: $projectFilter
      sort: $sort
      isManaged: $isManaged
    )
      {
      ...ProjectListItem
    }
  }
  ${projectListItemFragment}
`;
