import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem
} from '@material-ui/core';
import { DateField, NumberInput } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const getDisplayText = (setting, skill) => {
  const value = skill.customMetadata.find(x => x.uri === setting.uri);
  let displayValue = null;

  if (setting.type === 'urn:replicon:skill-setting-type:date') {
    displayValue = value && value.date ? value.date : null;
  }
  if (
    setting.type === 'urn:replicon:skill-setting-type:text' ||
    setting.type === 'urn:replicon:skill-setting-type:drop-down'
  ) {
    displayValue = value && value.text ? value.text : '';
  }
  if (setting.type === 'urn:replicon:skill-setting-type:numeric') {
    displayValue = value && value.number ? value.number : '0';
  }

  return displayValue;
};

export const SkillCustomMetadataCell = ({
  skill,
  setting,
  onCustomMetadataUpdate,
  dataQeId
}) => {
  const onChangeHandler = useCallback(
    e => onCustomMetadataUpdate(e, skill, setting),
    [onCustomMetadataUpdate, setting, skill]
  );
  const isMobile = useIsBreakpointDown('sm');

  return (
    <>
      {setting.type === 'urn:replicon:skill-setting-type:date' && (
        <DateField
          data-qe-id={`${dataQeId}_${setting.displayText}_DateField`}
          variant="filled"
          value={getDisplayText(setting, skill)}
          editable
          onChange={onChangeHandler}
          label={isMobile ? setting.displayText : ''}
          ariaLabel={setting.displayText}
          hiddenLabel={!isMobile}
        />
      )}
      {setting.type === 'urn:replicon:skill-setting-type:text' && (
        <TextField
          data-qe-id={`${dataQeId}_${setting.displayText}_TextField`}
          variant="filled"
          label={isMobile ? setting.displayText : ''}
          value={getDisplayText(setting, skill)}
          onChange={onChangeHandler}
          hiddenLabel={!isMobile}
          inputProps={{
            'aria-label': setting.displayText
          }}
        />
      )}
      {setting.type === 'urn:replicon:skill-setting-type:numeric' && (
        <NumberInput
          dataQeId={`${dataQeId}_${setting.displayText}_NumberInput`}
          variant="filled"
          label={isMobile ? setting.displayText : ''}
          value={getDisplayText(setting, skill)}
          onValueChange={onChangeHandler}
          fixedDecimalScale={false}
          defaultValue={0}
          ariaLabel={setting.displayText}
          hiddenLabel={!isMobile}
        />
      )}
      {setting.type === 'urn:replicon:skill-setting-type:drop-down' && (
        <FormControl variant="filled" fullWidth hiddenLabel={!isMobile}>
          {isMobile && (
            <InputLabel variant="filled">{setting.displayText}</InputLabel>
          )}
          <Select
            data-qe-id={`${dataQeId}_${setting.displayText}_Dropdown`}
            variant="filled"
            value={getDisplayText(setting, skill)}
            onChange={onChangeHandler}
            fullWidth
          >
            {setting.dropDownOptions.map(options => (
              <MenuItem key={options} value={options}>
                {options}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

SkillCustomMetadataCell.propTypes = {
  dataQeId: PropTypes.string,
  skill: PropTypes.object.isRequired,
  setting: PropTypes.array,
  onCustomMetadataUpdate: PropTypes.func
};

export default SkillCustomMetadataCell;
