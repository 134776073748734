import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import {
  CostCenterName,
  DepartmentName,
  DivisionName,
  EmployeeTypeName,
  LocationName,
  ServiceCenterName
} from '~/modules/common/components/Groups';
import { AllUsersIcon } from '~/modules/common/components/Icons';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import EntityAvatar from '~//modules/common/components/Avatars/EntityAvatar';
import { TIMESHEET_ACCESS_TYPE } from '~/modules/common/enums';
import { useProjectTimesheetAccess } from './useProjectTimesheetAccess';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(1)
  }
}));

const componentGroupTypeMap = {
  [TIMESHEET_ACCESS_TYPE.COST_CENTERS]: CostCenterName,
  [TIMESHEET_ACCESS_TYPE.DEPARTMENT_GROUP]: DepartmentName,
  [TIMESHEET_ACCESS_TYPE.DIVISIONS]: DivisionName,
  [TIMESHEET_ACCESS_TYPE.EMPLOYEE_TYPE_GROUP]: EmployeeTypeName,
  [TIMESHEET_ACCESS_TYPE.LOCATIONS]: LocationName,
  [TIMESHEET_ACCESS_TYPE.SERVICE_CENTERS]: ServiceCenterName
};

const ProjectTimesheetAccessGroupsMap = {
  allUsers: TIMESHEET_ACCESS_TYPE.ALL_USERS,
  locations: TIMESHEET_ACCESS_TYPE.LOCATIONS,
  costCenters: TIMESHEET_ACCESS_TYPE.COST_CENTERS,
  divisions: TIMESHEET_ACCESS_TYPE.DIVISIONS,
  departments: TIMESHEET_ACCESS_TYPE.DEPARTMENT_GROUP,
  serviceCenters: TIMESHEET_ACCESS_TYPE.SERVICE_CENTERS,
  employeeTypes: TIMESHEET_ACCESS_TYPE.EMPLOYEE_TYPE_GROUP
};

export const GroupTimesheetAccessDropdown = ({
  projectId,
  timesheetAccess,
  onTimesheetAccessChange
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const timesheetAccessLabel = intl.formatMessage({
    id: 'taskResourceAssignments.groupTimesheetAccess'
  });

  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const {
    loading,
    timesheetAccess: timesheetAccessOptions,
    groupsOrder
  } = useProjectTimesheetAccess({ projectId, includeResources: false });

  const groupBy = useCallback(
    option =>
      option?.group.displayText
        ? intl.formatMessage({
            id: `taskResourceAssignments.${option.group.displayText}`
          })
        : '',
    [intl]
  );

  const renderOption = useCallback(option => {
    const GroupComponent = componentGroupTypeMap[option.group.id];

    return (
      <>
        {option?.group.id !== TIMESHEET_ACCESS_TYPE.ALL_USERS ? (
          <GroupComponent value={option} variant="avatar" showFullPath />
        ) : (
          <>
            <EntityAvatar size="xsmall" className={classes.avatar}>
              <AllUsersIcon />
            </EntityAvatar>
            <ListItemText disableTypography primary={option.displayText} />
          </>
        )}
      </>
    );
  }, []);

  const timesheetAccessFormattedOptions =
    !loading && timesheetAccessOptions
      ? Object.entries(timesheetAccessOptions)
          .map(([key, timesheetGroup]) =>
            Array.isArray(timesheetGroup)
              ? timesheetGroup.map(timesheetSubGroup => ({
                  group: {
                    id: ProjectTimesheetAccessGroupsMap[key],
                    displayText: key
                  },
                  id: timesheetSubGroup.id,
                  displayText: timesheetSubGroup.displayText,
                  fullPath: timesheetSubGroup.fullPath
                }))
              : timesheetGroup?.isAssigned
              ? {
                  id: timesheetGroup?.id,
                  displayText: intl.formatMessage({
                    id: 'taskResourceAssignments.allUsers'
                  }),
                  group: {
                    id: ProjectTimesheetAccessGroupsMap[key]
                  }
                }
              : {}
          )
          .flat()
          .filter(element => element.id)
          .sort((a, b) => {
            const indexA = groupsOrder.indexOf(a.group.displayText);
            const indexB = groupsOrder.indexOf(b.group.displayText);

            return indexA - indexB;
          })
      : [];

  const getOption = useCallback(
    option =>
      timesheetAccess.some(timesheetGroup => timesheetGroup.id === option.id),
    [timesheetAccess]
  );

  return (
    <SimpleAutocomplete
      multiple
      fullWidth
      variant="outlined"
      loading={loading}
      value={timesheetAccess}
      groupBy={groupBy}
      options={timesheetAccessFormattedOptions}
      renderOption={renderOption}
      getOptionDisabled={getOption}
      getOptionSelected={getOption}
      noOptionsText={intl.formatMessage({
        id: 'taskResourceAssignments.noOptions'
      })}
      label={timesheetAccessLabel}
      ariaLabel={timesheetAccessLabel}
      inputLabel={timesheetAccessLabel}
      optionPropText="displayText"
      onChange={onTimesheetAccessChange}
      inputValue={searchTerm}
      onInputChange={handleInputChange}
    />
  );
};

GroupTimesheetAccessDropdown.propTypes = {
  projectId: PropTypes.string,
  timesheetAccess: PropTypes.array,
  onTimesheetAccessChange: PropTypes.func
};

export default GroupTimesheetAccessDropdown;
