import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = () => ({
  field: {
    padding: '8px',
    flexGrow: 1,
    flexShrink: 1
  }
});

const FormField = ({ classes, className, children }) => (
  <div className={classNames(classes.field, className)}>{children}</div>
);

FormField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
};

export default withStyles(styles)(FormField);
