import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import { useIntl } from 'react-intl';
import RequestActionButton from './RequestActionButton';

const ProposedRequestActionButton = ({ className, onClick, ...otherProps }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <RequestActionButton
      className={className}
      paletteClass={theme.palette.resourceRequest.proposed}
      label={intl.formatMessage({
        id: 'resourceAssignmentDialog.proposeResources'
      })}
      onClick={onClick}
      {...otherProps}
    />
  );
};

ProposedRequestActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default ProposedRequestActionButton;
