import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  getScheduleTotalHours,
  appendResourceRequestFields,
  appendScheduleRuleTypeNameFields,
  getRequestRoleAttributesFromRoleChangeEvent,
  omitForbiddenFields,
  replaceProjectResourceRequest,
  updateCache
} from '~/modules/resourcing/common/util';
import { useUpdateResourceRequest } from '~/modules/resourcing/common/hooks/useUpdateResourceRequest';
import { useMeContext } from '~/modules/me';
import { processResourceRequestAndUpdateCache } from '~/modules/resourcing/enhancers/processResourceRequestAndUpdateCache';
import { useGetResourceRequestFilter } from '~/modules/resourcing/common/hooks/useGetResourceRequestFilter';
import {
  ALLOCATION_STATUS_LIST,
  RESOURCE_REQUEST_STATUS_LIST
} from '~/modules/resourcing/common/fragments/projectTotalsFragment2';

export const invokeUpdateResourceRequest = ({
  updateResourceRequest,
  disableDebounce,
  apolloClient,
  filter,
  skipTotalsCacheUpdate = false,
  isResourceActualModeEnabled
}) => async requestInput => {
  const initialRequestWithId = {
    ...requestInput,
    tags: (requestInput.tags || []).map(tag => ({
      ...tag,
      id: `${requestInput.id}_${tag.tag.id}`
    }))
  };

  const { updatedRequest } = !skipTotalsCacheUpdate
    ? processResourceRequestAndUpdateCache({
        proxy: apolloClient.cache,
        request: initialRequestWithId,
        filter,
        isResourceActualModeEnabled
      })
    : {};

  const request = updatedRequest || initialRequestWithId;

  await updateResourceRequest({
    variables: {
      input: omitForbiddenFields(appendResourceRequestFields(request)),
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      allocationStatusList: ALLOCATION_STATUS_LIST,
      includeActualsResourceUsers: isResourceActualModeEnabled
    },
    context: disableDebounce
      ? undefined
      : {
          debounceKey: request.id,
          debounceTimeout: 100
        },
    optimisticResponse: {
      __typename: 'Mutation',
      updateResourceRequest2: {
        __typename: 'UpdateResourceRequestResult',
        isOptimistic: true,
        resourceRequest: {
          __typename: 'ResourceRequest',
          comment: request.comment,
          scheduleRules: request.scheduleRules.map(
            appendScheduleRuleTypeNameFields
          ),
          endDate: request.endDate,
          startDate: request.startDate,
          id: request.id,
          load: request.load,
          role: request.role,
          requestStatus: request.requestStatus,
          projectUri: request.projectUri,
          quantity: request.quantity,
          location: request.location,
          division: request.division,
          costCenter: request.costCenter,
          serviceCenter: request.serviceCenter,
          department: request.department,
          employeeType: request.employeeType,
          roleRate: request.roleRate,
          isAdjustedLoading: request.isAdjustedLoading,
          resourceAllocations: request.resourceAllocations || [],
          project: request.project,
          currencyUri: request.currencyUri,
          currency: request.currency,
          totalHours: request.totalHours || null,
          skills: request.skills || [],
          tags: request.tags || [],
          resourcePools: request.resourcePools || [],
          requestAttributeWeights: {
            __typename: 'RequestAttributeWeights',
            ...request.requestAttributeWeights
          },
          exchangeRateValues: request.exchangeRateValues || null,
          totalRequestedCostByCurrency: request.totalRequestedCostByCurrency || {
            baseCurrency: null,
            projectBudgetCurrency: null
          },
          preferredResources: request.preferredResources || [],
          sourceMetadata: request.sourceMetadata || null
        }
      }
    },
    update: updateCache(
      replaceProjectResourceRequest,
      request.projectUri,
      'updateResourceRequest2',
      request.requestStatus,
      request
    )
  });
};

export const useUpdateResourceRequestHandlers = ({
  resourceRequest,
  defaultScheduleRule,
  isResourceActualModeEnabled
}) => {
  const me = useMeContext();
  const apolloClient = useApolloClient();
  const filter = useGetResourceRequestFilter();

  const { updateResourceRequest } = useUpdateResourceRequest();

  const onQuantityChange = useCallback(
    quantity => {
      const totalHours =
        resourceRequest.totalHours || getScheduleTotalHours(resourceRequest);

      invokeUpdateResourceRequest({
        updateResourceRequest,
        apolloClient,
        filter,
        isResourceActualModeEnabled
      })({
        ...resourceRequest,
        quantity,
        totalHours: (totalHours * quantity) / resourceRequest.quantity
      });
    },
    [
      resourceRequest,
      updateResourceRequest,
      apolloClient,
      filter,
      isResourceActualModeEnabled
    ]
  );

  const onResourceRequestSave = useCallback(
    async passedResourceRequest => {
      await invokeUpdateResourceRequest({
        updateResourceRequest,
        disableDebounce: true,
        apolloClient,
        filter,
        isResourceActualModeEnabled
      })({
        ...passedResourceRequest
      });
    },
    [updateResourceRequest, apolloClient, filter, isResourceActualModeEnabled]
  );

  const onLoadChange = useCallback(
    load => {
      const newScheduleRules = [
        {
          dateRange: {
            startDate: resourceRequest.startDate,
            endDate: resourceRequest.endDate
          },
          do: {
            ...defaultScheduleRule.do,
            load
          }
        }
      ];
      const totalHours = getScheduleTotalHours({
        ...resourceRequest,
        scheduleRules: newScheduleRules
      });

      invokeUpdateResourceRequest({
        updateResourceRequest,
        apolloClient,
        filter,
        isResourceActualModeEnabled
      })({
        ...resourceRequest,
        load,
        isAdjustedLoading: false,
        scheduleRules: newScheduleRules,
        totalHours
      });
    },
    [
      resourceRequest,
      updateResourceRequest,
      defaultScheduleRule,
      apolloClient,
      filter,
      isResourceActualModeEnabled
    ]
  );

  const onRoleChange = useCallback(
    event => {
      invokeUpdateResourceRequest({
        updateResourceRequest,
        apolloClient,
        filter,
        isResourceActualModeEnabled
      })({
        ...resourceRequest,
        skills: event ? event.skills || [] : [],
        ...getRequestRoleAttributesFromRoleChangeEvent(event, me.baseCurrency)
      });
    },
    [
      resourceRequest,
      updateResourceRequest,
      me,
      apolloClient,
      filter,
      isResourceActualModeEnabled
    ]
  );

  return {
    onQuantityChange,
    onResourceRequestSave,
    onLoadChange,
    onRoleChange
  };
};

export default useUpdateResourceRequestHandlers;
