import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  roles: {
    marginBottom: theme.spacing(2)
  },
  contentRoot: {
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    width: '50%',
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  category: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1)
  },
  role: {
    ...theme.typography.body2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(0, 1, 0, 0),
    flexGrow: 1,
    flexShrink: 1
  },
  effectiveDate: {
    ...theme.typography.caption,
    margin: 0,
    display: 'flex',
    color: theme.palette.text.secondary
  },
  asOfInfo: {
    alignItems: 'baseline',
    margin: 0,
    display: 'flex'
  },
  historyButton: {
    margin: theme.spacing(-0.5, 0, -0.5, 1)
  },
  roleList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  avatar: {
    color: theme.palette.text.secondary,
    marginRight: '12px',
    height: theme.typography.h5.fontSize
  },
  headerRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  headerAction: {
    margin: theme.spacing(-1.5),
    marginRight: theme.spacing(-2)
  },
  content: {
    display: 'flex'
  }
}));

export default useStyles;
