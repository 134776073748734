import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useMemo, useState, useCallback } from 'react';

export const BILLING_ITEM = gql`
  query getPageOfAvailableBillingItemDetails(
    $page: Int
    $pageSize: Int
    $input: AvailableBillingItemInput2!
  ) {
    getPageOfAvailableBillingItemDetails: getPageOfAvailableBillingItemDetails2(
      page: $page
      pageSize: $pageSize
      input: $input
    ) {
      id
      billingItemUris
      projectName
      taskName
      quantity
      unitLabel
      itemType
      user {
        displayText
        uri
      }
      billingAmount {
        amount
        currency {
          id
          symbol
        }
      }
      billingAmountOriginal {
        amount
        currency {
          id
          symbol
        }
      }
      billingItemRate {
        amount
        currency {
          id
          symbol
        }
      }
      billingContractClauseName
      timesheetPeriod {
        startDate
        endDate
      }
      payCode
      role
      expenseCode
    }
  }
`;

export const usePageOfBillingItems = ({
  client,
  columns,
  billingItemSearch = {},
  billCurrency: { id: billingAmountCurrencyUri } = {}
}) => {
  const [loadingMore, setLoadingMore] = useState(false);

  const { data, error, variables, loading, fetchMore } = useQuery(
    BILLING_ITEM,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        page: 1,
        pageSize: 20,
        input: {
          billingItemColumnOption: columns,
          filter: {
            clientUri: client.id,
            textSearch: billingItemSearch.text ? billingItemSearch.text : null,
            userUris: billingItemSearch.userUris
              ? billingItemSearch.userUris
              : null,
            dateRange: billingItemSearch.dateRange
              ? billingItemSearch.dateRange
              : null,
            projectUris: billingItemSearch.projectUris
              ? billingItemSearch.projectUris
              : null,
            billingItemTypes: billingItemSearch.billingItemTypes
              ? billingItemSearch.billingItemTypes
              : null
          },
          billingAmountCurrencyUri
        }
      }
    }
  );
  const billingItems = get(data, 'getPageOfAvailableBillingItemDetails', []);
  const hasMoreRows = useMemo(
    () =>
      billingItems.length > 0 && billingItems.length % variables.pageSize === 0,
    [billingItems.length, variables.pageSize]
  );
  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows) {
      return;
    }

    setLoadingMore(true);
    try {
      await fetchMore({
        query: BILLING_ITEM,
        variables: {
          page: billingItems.length / variables.pageSize + 1,
          pageSize: variables.pageSize,
          input: variables.input
        },
        updateQuery: (
          previousResult,
          {
            fetchMoreResult: {
              getPageOfAvailableBillingItemDetails: nextResult
            }
          }
        ) => {
          const prevResult =
            previousResult?.getPageOfAvailableBillingItemDetails || [];
          const nextResults = nextResult || [];

          return {
            getPageOfAvailableBillingItemDetails: [
              ...prevResult,
              ...nextResults
            ]
          };
        }
      });
    } finally {
      setLoadingMore(false);
    }
  }, [
    hasMoreRows,
    fetchMore,
    billingItems.length,
    variables.pageSize,
    variables.input
  ]);

  return {
    loading,
    error,
    billingItems,
    loadingMore,
    loadMoreRows,
    hasMoreRows
  };
};

export default usePageOfBillingItems;
