/* eslint-disable react/jsx-max-depth */
import { Hidden, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { Waypoint } from 'react-waypoint';
import useWaypointOffsetHeight from '~/modules/common/hooks/useWaypointOffsetHeight';
import ListTablePropTypes from '../PropTypes';
import { MobileList } from '../MobileList';
import { DataTable } from '../components';

const useStyles = makeStyles(() => ({
  tablesContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%'
  },
  infiniteScrollContainer: {
    display: 'flex',
    width: '100%'
  },
  dataTable: {
    width: '100%'
  },
  tables: {
    top: '0px',
    width: '100%'
  },
  left: {
    position: 'sticky',
    left: '300px',
    zIndex: 200,
    display: 'flex'
  },
  right: {
    zIndex: 100,
    width: '100%'
  }
}));

const ScrollableListTable = ({
  headers,
  footers,
  columns,
  records,
  loadMore,
  hasMore,
  totals,
  mobileListItem,
  mobileListItemSkeleton,
  onListItemClick,
  variant,
  useInlineStyles,
  hover,
  onRowClick,
  onCellClick,
  isLoading,
  loadingComponent: LoadingComponent,
  showLoadingComponent = true,
  showLoadMoreSkeleton = false,
  selected,
  onRowSelectionChange
}) => {
  const classes = useStyles();
  const { offsetHeight, containerRef } = useWaypointOffsetHeight({
    heightFactor: 0.5,
    records
  });
  const handleOnRowSelectionChange = useCallback(
    (id, checked) =>
      onRowSelectionChange &&
      onRowSelectionChange({
        ...selected,
        records: checked
          ? [...selected.records, id]
          : selected.records.filter(r => r !== id)
      }),
    [selected, onRowSelectionChange]
  );

  return (
    <>
      <div className={classes.tables} ref={containerRef}>
        <Hidden smDown={Boolean(mobileListItem)}>
          {isLoading && showLoadingComponent ? (
            <LoadingComponent
              headers={headers}
              footers={footers}
              columns={columns}
            />
          ) : (
            <DataTable
              useInlineStyles={useInlineStyles}
              hover={hover}
              variant={variant}
              headers={headers}
              footers={footers}
              columns={columns}
              records={records}
              totals={totals}
              className={classes.dataTable}
              onRowClick={onRowClick}
              onCellClick={onCellClick}
              showLoadMoreSkeleton={isLoading && showLoadMoreSkeleton}
              onRowSelectionChange={
                onRowSelectionChange ? handleOnRowSelectionChange : null
              }
              selected={selected}
              loading={isLoading}
            />
          )}
        </Hidden>
        {!!mobileListItem && (
          <Hidden mdUp={Boolean(mobileListItem)}>
            <MobileList
              mobileListItem={mobileListItem}
              mobileListItemSkeleton={mobileListItemSkeleton}
              records={records}
              isLoading={isLoading}
              onListItemClick={onListItemClick}
              selected={selected}
              onRowSelectionChange={
                onRowSelectionChange ? handleOnRowSelectionChange : null
              }
            />
          </Hidden>
        )}
      </div>
      {hasMore && loadMore && !isLoading && (
        <Waypoint
          bottomOffset={`${offsetHeight}px`}
          onEnter={loadMore}
          key={(records || []).length}
        />
      )}
    </>
  );
};

ScrollableListTable.propTypes = {
  headers: ListTablePropTypes.headers,
  footers: ListTablePropTypes.footers,
  columns: ListTablePropTypes.columns,
  records: PropTypes.arrayOf(PropTypes.any),
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  totals: PropTypes.any,
  isLoading: PropTypes.bool,
  loadingComponent: PropTypes.func,
  mobileListItem: PropTypes.any,
  mobileListItemSkeleton: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'table']),
  hover: PropTypes.bool,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onListItemClick: PropTypes.func,
  showLoadingComponent: PropTypes.bool,
  showLoadMoreSkeleton: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  selected: ListTablePropTypes.selected,
  useInlineStyles: PropTypes.bool
};

export default ScrollableListTable;
