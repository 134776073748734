import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNumeric } from '~/modules/common/numbers';
import AbbreviatedDecimal from '../AbbreviatedDecimal';
import Decimal from '../Decimal';
import NoValue from '../NoValue';
import useTooltipTitleProps from './useTooltipTitleProps';

export const AbbreviatedDecimalWithToolTip = ({
  prefix,
  suffix,
  value,
  tooltipPrecision,
  tooltipMinPrecision,
  ...otherProps
}) => {
  const prefixSpaced = prefix ? `${prefix} ` : '';

  const tooltipTitleProps = useTooltipTitleProps({
    value,
    prefix: prefixSpaced,
    suffix,
    precision: tooltipPrecision,
    minPrecision: tooltipMinPrecision
  });

  const tooltipTitle = useMemo(() => <Decimal {...tooltipTitleProps} />, [
    tooltipTitleProps
  ]);

  return (
    <AbbreviatedDecimal
      prefix={prefixSpaced}
      suffixEnd={suffix}
      value={value}
      tooltipTitle={tooltipTitle}
      {...otherProps}
    />
  );
};

const AbbreviatedDecimalHideNull = ({ value, ...otherProps }) => {
  return isNumeric(value) ? (
    <AbbreviatedDecimalWithToolTip value={value} {...otherProps} />
  ) : (
    <NoValue />
  );
};

AbbreviatedDecimalHideNull.propTypes = {
  value: PropTypes.number
};

AbbreviatedDecimalWithToolTip.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.number,
  tooltipPrecision: PropTypes.number,
  tooltipMinPrecision: PropTypes.number
};

export default AbbreviatedDecimalHideNull;
