import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useMeContext } from '~/modules/me';
import EntityName from '../common/EntityName';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 'inherit',
    color: theme.palette.text.secondary
  },
  rootRolledUp: {
    fontSize: theme.spacing(1.5) + 1,
    fontWeight: '600'
  },
  taskName: {
    display: 'inline',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    lineHeight: 1,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  },
  pathIcon: {
    margin: theme.spacing(0, 0.5),
    fontSize: 12,
    opacity: 0.87
  }
}));

export const TaskPath = ({
  className,
  task,
  classes: classesOverride,
  children,
  ...rest
}) => {
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();
  const classes = useStyles({
    classes: classesOverride
  });

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootRolledUp]: isRolledUpTaskEstimateCalculationMethodEnabled
      })}
      {...rest}
    >
      {task.path.map((path, index) => (
        <Fragment key={path}>
          {index > 0 && (
            <ArrowForwardIcon fontSize="small" className={classes.pathIcon} />
          )}
          <EntityName
            name={path}
            variant="caption"
            className={classes.taskName}
            noWrap
          />
        </Fragment>
      ))}
      {children}
    </div>
  );
};
TaskPath.propTypes = {
  className: PropTypes.string,
  task: PropTypes.shape({
    path: PropTypes.arrayOf(PropTypes.string).isRequired
  }),
  classes: PropTypes.object,
  children: PropTypes.node
};

export default TaskPath;
