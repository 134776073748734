import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import User from '~/modules/common/components/User';
import { useMeContext } from '~/modules/me/useMeContext';
import { TaskCardNoValue } from './TaskCardNoValue';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  user: {
    padding: 0,
    paddingLeft: theme.spacing(0.5)
  },
  label: {
    fontSize: theme.spacing(1.75)
  }
}));

const TaskCardUser = ({ user }) => {
  const {
    featureFlags: { isPSATaskEstimateRollUpMobileViewEnabled }
  } = useMeContext();
  const classes = useStyles({ isPSATaskEstimateRollUpMobileViewEnabled });
  const userClasses = useMemo(
    () => ({
      container: classes.user,
      label: classes.label
    }),
    [classes.label, classes.user]
  );

  return user ? (
    isPSATaskEstimateRollUpMobileViewEnabled ? (
      <div className={classes.container}>
        <User classes={userClasses} user={user} />
      </div>
    ) : (
      <User classes={userClasses} user={user} />
    )
  ) : (
    <TaskCardNoValue id="projectTasksPage.noOwner" />
  );
};

TaskCardUser.propTypes = {
  user: PropTypes.object
};

export default TaskCardUser;
