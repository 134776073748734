import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    }
  },
  title: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  description: {
    whiteSpace: 'pre-wrap',
    hyphens: 'auto',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  }
}));

export const SideCardSectionDescriptionDialog = ({ detail, open, onClose }) => {
  const classes = useStyles();

  const fullScreen = useIsBreakpointDown('sm');
  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [
    classes.paper
  ]);

  const onCloseHandler = useCallback(() => {
    onClose(true);
  }, [onClose]);

  return (
    <Dialog
      fullScreen={fullScreen}
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      classes={dialogClasses}
    >
      <DialogTitle className={classes.title}>
        <FormattedMessage id="projectDetails.description" />
      </DialogTitle>
      <DialogContent className={classes.description}>{detail}</DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>
          <FormattedMessage id="projectDetails.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SideCardSectionDescriptionDialog.propTypes = {
  detail: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SideCardSectionDescriptionDialog;
