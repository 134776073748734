import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useURLSearchParams } from './useURLSearchParams';

export const useIsDefaultEdit = () => {
  const [edit, setEdit] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const searchParams = useURLSearchParams();

  useEffect(() => {
    if (match.url === history.location.pathname && !edit) {
      setEdit(searchParams.get('edit') === 'true');
    }
  }, [history, match, searchParams, edit]);

  return edit;
};
