import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, makeStyles } from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import FailedExport from './FailedExport';
import LoadingExport from './LoadingExport';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    },
    padding: theme.spacing(2, 3.5, 0, 3.5)
  }
}));

const ExportLoaderDialog = ({ isOpen, handleClose, exportFail }) => {
  const classes = useStyles();
  const fullScreen = useIsBreakpointDown('sm');

  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [
    classes.paper
  ]);

  return (
    <>
      <Dialog classes={dialogClasses} fullScreen={fullScreen} open={isOpen}>
        {exportFail ? (
          <FailedExport handleClose={handleClose} />
        ) : (
          <LoadingExport handleClose={handleClose} />
        )}
      </Dialog>
    </>
  );
};

ExportLoaderDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  exportFail: PropTypes.bool.isRequired
};

export default ExportLoaderDialog;
