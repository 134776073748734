import { useEffect } from 'react';
import { gantt } from '@replicon/dhtmlx-gantt';

export const useGanttReloadDataEffect = ({ ganttData, focusedTaskId }) => {
  useEffect(() => {
    const scrollState = gantt.getScrollState();

    gantt.clearAll();
    gantt.parse(ganttData || { data: [] });
    gantt.scrollTo(scrollState.x, scrollState.y);
  }, [ganttData, focusedTaskId]);
};

export default useGanttReloadDataEffect;
