import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import DateRange from '~/modules/common/components/DateRange';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  date: {
    fontSize: theme.typography.body2.fontSize
  },
  range: { display: 'inline-flex', maxWidth: 'fit-content', flexWrap: 'wrap' }
}));

export const DateRangeFormatter = ({ field, record }) => {
  const dateRange = record[field];
  const classes = useStyles();

  if (!dateRange) return <NoValue />;

  const { startDate, endDate } = dateRange;

  return <DateRange start={startDate} end={endDate} classes={classes} />;
};

DateRangeFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default DateRangeFormatter;
