import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  text: {
    marginLeft: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.25)
  },
  projectLabel: {
    marginLeft: theme.spacing(0.25)
  }
}));

export const useCheckboxStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(3)
  }
}));

export default useStyles;
