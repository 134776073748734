import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { moneyFragment } from '~/modules/common/fragments';

export const getEarnedRevenueDetailsQuery = gql`
  query getEarnedRevenueDetails($projectId: String!) {
    project(projectId: $projectId) {
      id
      earnedRevenue {
        ...MoneyFragment
      }
      earnedRevenueScript {
        name
        id
      }
      costAmountSummary(dateRange: null) {
        costActual {
          ...MoneyFragment
        }
      }
    }
  }
  ${moneyFragment}
`;

export const useEarnedRevenue = (projectUri, me) => {
  const { data, loading, refetch } = useQuery(getEarnedRevenueDetailsQuery, {
    variables: { projectId: projectUri },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const {
    earnedRevenue,
    earnedRevenueScript,
    costAmountSummary: { costActual } = {}
  } = get(data, 'project', {});

  return {
    loading,
    refetchEarnedRevenue: refetch,
    earnedRevenue,
    cost: costActual,
    earnedRevenueScript
  };
};

export default useEarnedRevenue;
