import React, { useState, useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { UserWithRoles } from '~/modules/common/components/User';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import {
  usePageOfProjectResources,
  useFilteredUsersAssignedToTask
} from '~/modules/common/hooks';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { User } from '~/modules/common/components';
import useDropdownHandlers from './useDropdownHandlers';

const PAGE_SIZE = 200;

export const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : (
    <UserWithRoles user={option} />
  );

const useUserStyles = makeStyles(theme => ({
  label: {
    maxWidth: theme.spacing(19)
  },
  name: {
    fontSize: theme.typography.body1.fontSize
  }
}));

export const getOptionLabel = option => option?.displayText || '';

export const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const ProjectTeamMemberDropdown = ({
  projectSlug,
  assignedRole,
  onResourceChange,
  value,
  selectedResources,
  placeholder,
  variant = 'standard',
  ariaLabel,
  label,
  className,
  taskId
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const {
    loading: loadingProjectResources,
    users,
    hasMore
  } = usePageOfProjectResources({
    projectSlug,
    searchTerm,
    pageSize: PAGE_SIZE
  });

  const {
    loading: loadingAssignedUsers,
    userIds: assignedUserIds
  } = useFilteredUsersAssignedToTask({
    taskId,
    userIds: users.map(u => u.id),
    skip: loadingProjectResources || !taskId
  });

  const userClasses = useUserStyles();
  const {
    handleOnChange,
    getOptionDisabled,
    handleInputChange,
    getGroupBy,
    getOptions
  } = useDropdownHandlers({
    assignedUserIds,
    onResourceChange,
    setSearchTerm,
    value,
    formatMessage,
    assignedRole,
    selectedResources
  });

  const renderTags = useCallback(
    values =>
      values.map(option => (
        <User
          classes={userClasses}
          user={option}
          disablePadding
          key={option.displayText}
        />
      )),
    [userClasses]
  );
  const loading = loadingProjectResources || loadingAssignedUsers;
  const options = loading ? [] : getOptions({ users, hasMore });

  const dropdownValue = useMemo(() => (value ? [value] : []), [value]);

  return (
    <SimpleAutocomplete
      clearOnBlur
      loading={loading}
      size="small"
      fullWidth
      multiple
      options={options}
      variant={variant}
      dataQeId="TaskResourceDropdown"
      noOptionsText={formatMessage({
        id: 'taskResourceAssignments.noOptions'
      })}
      value={dropdownValue}
      placeholder={value ? '' : placeholder}
      groupBy={getGroupBy}
      hasMore={hasMore}
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
      loadingText={formatMessage({
        id: 'taskResourceAssignments.loading'
      })}
      getOptionSelected={getOptionSelected}
      renderTags={renderTags}
      renderOption={renderOption}
      onInputChange={handleInputChange}
      getOptionDisabled={getOptionDisabled}
      ariaLabel={ariaLabel}
      inputLabel={label}
      className={className}
    />
  );
};

ProjectTeamMemberDropdown.propTypes = {
  onResourceChange: PropTypes.func.isRequired,
  assignedRole: PropTypes.object,
  value: PropTypes.object,
  selectedResources: PropTypes.array,
  projectSlug: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  taskId: PropTypes.string
};

export default ProjectTeamMemberDropdown;
