import { withCustomFetchCriteriaProvider } from '../enhancers';
import ProjectSuggestionItem from '../GenericProjectFacetDetails/ProjectSuggestionItem';
import { projectTag } from './tag';
import { useSearchableProjectOptions } from './useProjectOptions';
import ProjectFacetDetails from './ProjectFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableProjectOptions);

export default {
  tag: projectTag,
  makeCriteriaProvider,
  DetailsControl: ProjectFacetDetails,
  SuggestionItem: ProjectSuggestionItem
};
