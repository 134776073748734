import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CoManagerSelect from '~/modules/common/components/CoManagerSelect';
import { useProjectManagers } from '~/modules/common/hooks/useProjectManagers';

export const ProjectCoManagerSelect = ({ onChange, coManagers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, options, hasMore } = useProjectManagers({ searchTerm });
  const handleInputChange = useCallback(
    event => setSearchTerm(event.target.value),
    [setSearchTerm]
  );
  const { formatMessage } = useIntl();

  return (
    <CoManagerSelect
      hasMore={hasMore}
      coManagers={coManagers}
      onChange={onChange}
      options={options}
      loading={isLoading}
      handleInputChange={handleInputChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.projectCoManagers'
      })}
    />
  );
};

ProjectCoManagerSelect.propTypes = {
  coManagers: PropTypes.array,
  onChange: PropTypes.func
};

export default ProjectCoManagerSelect;
