import React from 'react';
import { PropTypes } from 'prop-types';
import SearchField from '../SearchField';
import withStyles from './withStyles';

export const TaskCardTitle = ({ classes, title, onChange, searchQeId }) => (
  <div>
    {onChange && <SearchField onChange={onChange} searchQeId={searchQeId} />}
    <h4 role="presentation" className={classes.taskTitle}>
      {title}
    </h4>
  </div>
);

TaskCardTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func,
  searchQeId: PropTypes.string
};

export default withStyles(TaskCardTitle);
