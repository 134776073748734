/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  DialogContent,
  DialogActions,
  Button,
  DialogContentText
} from '@material-ui/core';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import {
  RemoveEntityConfirmationDialog,
  LoadingButton
} from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import CertificateEditable from '~/modules/resourcing/common/components/Certificates/CertificateEditable';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  spacer: { flex: '1 0 auto' }
}));

const deleteDialogResourceKeys = {
  header:
    'resourceCertificatesAndLicensesCard.deleteCertificateDialog.removeCertificate',
  actionButton:
    'resourceCertificatesAndLicensesCard.deleteCertificateDialog.removeCertificateButton'
};

export const EditCertificateDialogContent = ({
  onCancel,
  submitError,
  isSubmitting,
  deleteCertificate
}) => {
  const classes = useStyles();
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);
  const { errors, handleSubmit } = useFormikContext();
  const saveable = useMemo(() => Object.keys(errors).length === 0, [errors]);

  const onDeleteConfirm = useCallback(() => {
    deleteCertificate();
    onCancel();
  }, [onCancel, deleteCertificate]);

  return (
    <>
      <DialogContent>
        {showRemoveDialog && (
          <RemoveEntityConfirmationDialog
            resourceKeys={deleteDialogResourceKeys}
            open={showRemoveDialog}
            onClose={closeRemoveDialog}
            onConfirm={onDeleteConfirm}
          >
            <DialogContentText>
              <FormattedMessage id="resourceCertificatesAndLicensesCard.deleteCertificateDialog.description" />
            </DialogContentText>
          </RemoveEntityConfirmationDialog>
        )}
        <CertificateEditable
          readOnly={isSubmitting}
          submitError={submitError}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          data-qe-id="EditDialogRemoveButton"
          variant="contained"
          color="secondary"
          onClick={openRemoveDialog}
          disabled={isSubmitting}
        >
          <FormattedMessage id="button.remove" />
        </Button>
        <div className={classes.spacer} />
        <Button
          data-qe-id="EditDialogCancelButton"
          variant="contained"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          data-qe-id="EditDialogSaveButton"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={isSubmitting || !saveable}
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </>
  );
};

EditCertificateDialogContent.propTypes = {
  onCancel: PropTypes.func,
  submitError: PropTypes.object,
  isSubmitting: PropTypes.bool,
  deleteCertificate: PropTypes.func
};

export default EditCertificateDialogContent;
