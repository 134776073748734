import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProgramIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 10,16 V 15 H 3.010000000000002 v 6 H 21 v -6 h -7 v 1 z M 22,7 H 16 V 5 L 14,3 H 10 L 8,5 V 7 H 2 v 7 h 8 v -2 h 4 v 2 h 8 z M 14,7 H 10 V 5 h 4 z" />
    </>
  </SvgIcon>
));

export default ProgramIcon;
