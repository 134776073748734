import ClientSuggestionItem from './ClientSuggestionItem';
import { clientTag } from './tag';
import ClientFacetDetails from './ClientFacetDetails';
import { makeCriteriaProvider } from './enhancers';

export default {
  makeCriteriaProvider,
  tag: clientTag,
  DetailsControl: ClientFacetDetails,
  SuggestionItem: ClientSuggestionItem
};
