import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const UPDATE_PROJECT_REQUEST_STATUS = gql`
  mutation updateProjectRequestStatus(
    $input: UpdateProjectRequestStatusInput!
  ) {
    updateProjectRequestStatus(input: $input) {
      projectRequestUri
    }
  }
`;

export const useUpdateProjectRequestStatus = () => {
  const [updateProjectRequestStatus, isLoading] = useMutation(
    UPDATE_PROJECT_REQUEST_STATUS
  );

  return {
    updateProjectRequestStatus: async ({ projectRequestId, statusType }) => {
      await updateProjectRequestStatus({
        variables: {
          input: { id: projectRequestId, type: statusType }
        },
        refetchQueries: [
          'projectRequest',
          'getPageOfProjectRequests',
          'Eager_GetProjectRequestsCountSummary'
        ]
      });
    },
    isLoading
  };
};

export default useUpdateProjectRequestStatus;
