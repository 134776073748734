import { useColumns } from '~/modules/common/components/ListTable';
import { formatters } from './formatters';
import { editors } from './editors';

export const buildFooters = ({ parameters, classes, ...rest }) => {
  const columns = parameters.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.id]: {
        id: current.id,
        visible: current.visible,
        className: classes[current.type],
        type: current.type,
        align: current.align,
        ...rest
      }
    }),
    {}
  );

  return columns;
};

export const useListFooters = ({
  parameters,
  classes,
  customFormatters,
  customEditors,
  editable,
  footerProps,
  ...rest
}) => {
  const footerKeys = Object.keys(footerProps || {});
  const footerParameters = footerKeys.map(item => ({
    id: item,
    visible: editable,
    align: 'right'
  }));
  const len = editable ? footerKeys.length - 1 : 0;
  const updatedParameters = editable
    ? [...parameters.filter((x, index) => index > len), ...footerParameters]
    : [
        ...parameters.filter((x, index) => index > len),
        ...(parameters.some(p => p.id === 'estimatedAmount')
          ? [
              {
                id: 'totalEstimatedAmount',
                type: 'read-only-amount',
                visible: true,
                align: 'right'
              }
            ]
          : [])
      ];

  const columns = buildFooters({
    parameters: updatedParameters,
    classes,
    ...rest
  });

  return useColumns({
    columns,
    renders: editable
      ? editors({ parameters: footerParameters, customEditors })
      : formatters({
          parameters: updatedParameters,
          customFormatters
        })
  });
};
