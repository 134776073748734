import { Divider, Grid, makeStyles, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import Money2 from '~/modules/common/components/Money/Money2';
import { useHasPermission } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me/useMeContext';
import { getError } from '~/util';
import AddSkills from './AddSkills';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1, 2)
  },
  addSkill: { paddingTop: theme.spacing(1) },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

const AddRoleDialogForm = ({ canUserEditSkillAssignment = true }) => {
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const { hasViewProjectBillingRates } = useMeContext();
  const canEditSkills = useHasPermission({
    actionUri: 'urn:replicon:skill-action:edit-skill'
  });

  const { setFieldValue, handleChange, values, errors } = useFormikContext();
  const { name, skills, costRate, description, billingRate } = values;

  const onCostRateAmountChange = useCallback(
    event => {
      setFieldValue('costRate.amount', event.target.value);
    },
    [setFieldValue]
  );

  const onCostRateCurrencyChange = useCallback(
    event => {
      setFieldValue('costRate.currency', event);
    },
    [setFieldValue]
  );

  const onBillingRateAmountChange = useCallback(
    event => {
      setFieldValue('billingRate.amount', event.target.value);
    },
    [setFieldValue]
  );

  const onBillingRateCurrencyChange = useCallback(
    event => {
      setFieldValue('billingRate.currency', event);
    },
    [setFieldValue]
  );

  const nameError = getError(errors, 'name');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="name"
          fullWidth
          variant="filled"
          label={formatMessage({ id: 'addRoleDialog.name' })}
          value={name}
          onChange={handleChange}
          autoFocus
          error={Boolean(nameError)}
          helperText={nameError}
          inputProps={getAriaLabel(formatMessage({ id: 'addRoleDialog.name' }))}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="description"
          fullWidth
          variant="filled"
          label={formatMessage({ id: 'addRoleDialog.description' })}
          value={description}
          inputProps={getAriaLabel(
            formatMessage({ id: 'addRoleDialog.description' })
          )}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Money2
          name="costRate"
          label={formatMessage({ id: 'addRoleDialog.costRate' })}
          ariaLabel={formatMessage({ id: 'addRoleDialog.costRate' })}
          valueDataQeId="AddRoleDialogForm_CostRate"
          amount={costRate.amount}
          currency={costRate.currency}
          onAmountChange={onCostRateAmountChange}
          onCurrencyChange={onCostRateCurrencyChange}
          disablePortal
        />
      </Grid>
      {Boolean(hasViewProjectBillingRates) && (
        <Grid item xs={12} sm={6}>
          <Money2
            name="billingRate"
            label={formatMessage({ id: 'addRoleDialog.billingRate' })}
            ariaLabel={formatMessage({ id: 'addRoleDialog.billingRate' })}
            valueDataQeId="AddRoleDialogForm_BillingRate"
            amount={billingRate.amount}
            currency={billingRate.currency}
            onAmountChange={onBillingRateAmountChange}
            onCurrencyChange={onBillingRateCurrencyChange}
            disablePortal
          />
        </Grid>
      )}
      {canEditSkills && canUserEditSkillAssignment && (
        <Grid item xs={12} className={classes.addSkill}>
          <Divider className={classes.divider} />
          <AddSkills setFieldValue={setFieldValue} skills={skills} />
        </Grid>
      )}
    </Grid>
  );
};

AddRoleDialogForm.propTypes = {
  canUserEditSkillAssignment: PropTypes.bool
};

export default AddRoleDialogForm;
