import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import DateRange from '~/modules/common/components/DateRange';
import Decimal from '~/modules/common/components/Decimal';

const CompletedRow = ({ classes, facts }) => (
  <>
    <div className={classNames(classes.row, classes.actual)} role="row">
      <div
        className={classNames(classes.cell, classes.label, classes.rowHeader)}
        role="rowheader"
      >
        <FormattedMessage id="taskStatus.actual" />
      </div>
      <div
        data-qe-id="Status_TotalHours"
        className={classNames(classes.cell, classes.hours)}
        role="cell"
      >
        <Decimal
          className={classNames(
            classes.fact,
            facts.actual.delta > 0 && classes.overPlan
          )}
          value={facts.actual.hours}
        />
      </div>
      <div className={classNames(classes.cell, classes.hoursEstimate)}></div>
      <div className={classNames(classes.cell, classes.dates)} role="cell">
        <DateRange
          classes={{
            range: facts.actual.date.delta > 0 && classes.dateRangeOverPlan,
            date: classes.fact
          }}
          start={facts.actual.date.start}
          end={facts.actual.date.end}
        />
        {facts.actual.date.delta > 0 && (
          <Typography
            component="span"
            className={classNames(classes.fact, classes.diffNegative)}
          >
            <FormattedMessage
              id="taskStatus.additionalDays"
              values={{
                days: facts.actual.date.delta
              }}
            />
          </Typography>
        )}
      </div>
    </div>
  </>
);

CompletedRow.propTypes = {
  classes: PropTypes.object,
  facts: PropTypes.object
};

export default CompletedRow;
