import { useState, useCallback, useMemo } from 'react';
import { MODE } from '~/modules/tasks/components/RescheduleTaskDialog';

const useTaskAssignmentState = () => {
  const [taskId, setTaskId] = useState(null);
  const [rescheduleTaskState, setRescheduleTaskState] = useState({
    updatedTaskDateRange: null,
    durationMode: MODE.KEEP_TASK_WORK_DAYS
  });
  const [anchorPositionX, setAnchorPositionX] = useState(0);
  const [anchorPositionY, setAnchorPositionY] = useState(0);
  const [isAssignmentPopoverOpen, setIsAssignmentPopoverOpen] = useState(false);
  const [
    isRoleAssignmentPopoverOpen,
    setIsRoleAssignmentPopoverOpen
  ] = useState(false);
  const [
    taskOwnerAssignmentTaskName,
    setTaskOwnerAssignmentTaskName
  ] = useState('');

  const openAssignmentPopover = useCallback(
    taskOwnerName => {
      setIsAssignmentPopoverOpen(true);
      setTaskOwnerAssignmentTaskName(taskOwnerName);
    },
    [setIsAssignmentPopoverOpen, setTaskOwnerAssignmentTaskName]
  );

  const closeAssignmentPopover = useCallback(() => {
    setIsAssignmentPopoverOpen(false);
    setTaskOwnerAssignmentTaskName('');
  }, [setIsAssignmentPopoverOpen, setTaskOwnerAssignmentTaskName]);

  const openRoleAssignmentPopover = useCallback(() => {
    setIsRoleAssignmentPopoverOpen(true);
  }, [setIsRoleAssignmentPopoverOpen]);

  const closeRoleAssignmentPopover = useCallback(() => {
    setIsRoleAssignmentPopoverOpen(false);
  }, [setIsRoleAssignmentPopoverOpen]);

  const setAnchorPosition = useCallback(
    ({ top, left }) => {
      setAnchorPositionX(left);
      setAnchorPositionY(top);
    },
    [setAnchorPositionX, setAnchorPositionY]
  );

  const anchorPosition = useMemo(
    () => ({
      top: anchorPositionY,
      left: anchorPositionX
    }),
    [anchorPositionX, anchorPositionY]
  );

  return {
    taskId,
    setTaskId,
    anchorPosition,
    setAnchorPosition,
    isAssignmentPopoverOpen,
    isRoleAssignmentPopoverOpen,
    taskOwnerAssignmentTaskName,
    openAssignmentPopover,
    closeAssignmentPopover,
    openRoleAssignmentPopover,
    closeRoleAssignmentPopover,
    rescheduleTaskState,
    setRescheduleTaskState
  };
};

export default useTaskAssignmentState;
