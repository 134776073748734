import React from 'react';
import { Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { ChartSkeletonRows } from '~/modules/common/charts/timeline/components';
import { ChartSkeletonRow } from '~/modules/common/charts/timeline/components/ChartSkeletonRows/ChartSkeletonRows';

const useLeftComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 40,
    width: 464,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden',
    borderRight: 1,
    padding: theme.spacing(0, 2)
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

export const RowSkeletonLeftComponent = () => {
  const classes = useLeftComponentStyles();

  return (
    <Paper className={classes.root} square elevation={0}>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={80}
          height={12}
        />
      </div>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={200}
          height={16}
        />
      </div>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={230}
          height={12}
        />
      </div>
    </Paper>
  );
};

const useRightComponentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 40,
    width: 300,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden'
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

export const RowSkeletonRightComponent = () => {
  const classes = useRightComponentStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={200}
          height={12}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  leftComponent: {
    width: 464
  },
  rightComponent: {
    width: 200
  }
}));

export const AllocationProjectRowLoading = ({
  classes: classesOverride,
  LeftComponent = RowSkeletonLeftComponent
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ChartSkeletonRows
      classes={classes}
      LeftComponent={LeftComponent}
      RightComponent={RowSkeletonRightComponent}
    />
  );
};

export const AllocationRowLoading = ({ classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ChartSkeletonRow
      classes={classes}
      LeftComponent={RowSkeletonLeftComponent}
      RightComponent={RowSkeletonRightComponent}
      barWidth="100%"
      offset={25}
    />
  );
};

AllocationRowLoading.propTypes = {
  classes: PropTypes.object
};

AllocationProjectRowLoading.propTypes = {
  classes: PropTypes.object,
  LeftComponent: PropTypes.elementType
};
export default AllocationProjectRowLoading;
