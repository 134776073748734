import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import { useIsBreakpointDown } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  message: {
    marginBottom: theme.spacing(2)
  }
}));

export const NotFoundDescription = () => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Typography
      component="p"
      variant={isMobile ? 'body1' : 'h5'}
      className={classes.message}
    >
      <FormattedMessage id="notFoundPage.description" />
    </Typography>
  );
};

NotFoundDescription.propTypes = {};

export default NotFoundDescription;
