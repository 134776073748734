import React from 'react';
import PropTypes from 'prop-types';
import { EmployeeTypeIcon } from '~/modules/common/components/Icons';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupName from './GroupName';

export const EmployeeTypeName = ({
  value,
  employeeTypeSettings,
  variant,
  showFullPath,
  disableIcon
}) => (
  <GroupName
    value={value}
    Icon={EmployeeTypeIcon}
    groupSettings={employeeTypeSettings}
    variant={variant}
    showFullPath={showFullPath}
    disableIcon={disableIcon}
  />
);

EmployeeTypeName.propTypes = {
  value: PropTypes.object,
  employeeTypeSettings: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['icon', 'avatar']),
  showFullPath: PropTypes.bool,
  disableIcon: PropTypes.bool
};

export default withGroupSettings(
  'employeeType',
  'employeeTypeSettings'
)(EmployeeTypeName);
