import { ResourceCostModeType } from '~/types';

export const getCurrencyAndAmountFromAllocation = ({
  allocation,
  costModeKey
}) => {
  const costByCurrency = allocation[costModeKey];

  if (!costByCurrency) return null;

  return costByCurrency?.projectBudgetCurrency || costByCurrency?.baseCurrency;
};

export const getTotalHoursAndCostFromAllocations = ({
  allocations,
  resourceCostMode
}) => {
  if (allocations.length === 0) return null;

  const isRoleCostMode = resourceCostMode === ResourceCostModeType.Rolecost;
  const costModeKey = isRoleCostMode
    ? 'totalRoleCostByCurrency'
    : 'totalUserCostByCurrency';

  const firstAllocationCurrencyAndAmount = getCurrencyAndAmountFromAllocation({
    allocation: allocations[0],
    costModeKey
  });

  if (!firstAllocationCurrencyAndAmount) return null;

  const { currency } = firstAllocationCurrencyAndAmount;

  return allocations.reduce(
    (acc, allocation) => {
      const { amount = 0 } = getCurrencyAndAmountFromAllocation({
        allocation,
        costModeKey
      });

      return {
        totalHours: acc.totalHours + allocation.totalHours,
        totalCost: {
          currency,
          amount: acc.totalCost.amount + amount
        }
      };
    },
    {
      totalHours: 0,
      totalCost: { currency, amount: 0 }
    }
  );
};
