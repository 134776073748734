import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  certificate: {
    display: 'flex',
    padding: theme.spacing(0.25, 0)
  },
  certificateIcon: {
    color: theme.palette.text.secondary,
    marginBottom: -10
  },
  addCertificate: {
    display: 'flex',
    padding: 0
  },
  title: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.15,
    color: theme.palette.text.secondary
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(0.25),
    alignSelf: 'center',
    flexGrow: 1
  },
  cell: {
    borderBottom: 'none',
    padding: theme.spacing(1, 1.25),
    display: 'flex',
    flex: '1 0 inherit',
    flexDirection: 'column',
    alignSelf: 'center'
  },
  skillRatingTitle: {
    textAlign: 'right',
    marginRight: theme.spacing(11.25)
  },
  skillName: {
    paddingLeft: 0
  },
  skillRatingContainer: {
    justifyContent: 'flex-end',
    padding: theme.spacing(0.25, 0)
  },
  removeIcon: {
    color: theme.palette.secondary.main
  },
  removeIconCell: {
    textAlign: 'end',
    marginLeft: theme.spacing(-1)
  },
  iconButton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-1)
  },
  removeLicence: {
    padding: 0
  }
}));

export default useStyles;
