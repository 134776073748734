import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { useIntl } from 'react-intl';
import { DateRange, Decimal } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  load: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1)
  },
  totalHours: {
    paddingLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary
  }
}));

const useDateRangeStyles = makeStyles(theme => ({
  range: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingRight: theme.spacing(1)
  }
}));

const Body = ({ request }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dateRangeClasses = useDateRangeStyles();

  return (
    <>
      <DateRange
        start={request.startDate}
        end={request.endDate}
        classes={dateRangeClasses}
      />
      <Decimal
        value={request.load}
        suffix={intl.formatMessage({
          id: 'viewResourceRequestsSummaryDialog.loadSuffix'
        })}
        prefix={intl.formatMessage({
          id: 'viewResourceRequestsSummaryDialog.loadPrefix'
        })}
        className={classes.load}
      />
      <Decimal
        value={request.totalHours}
        suffix={intl.formatMessage({
          id: 'viewResourceRequestsSummaryDialog.hoursSuffix'
        })}
        className={classes.totalHours}
      />
    </>
  );
};

Body.propTypes = {
  request: PropTypes.object
};

export default Body;
