import { DateTime } from 'luxon';

export const useDateRangesForChartData = ({ numberOfWeeks }) => {
  const dateRanges = [];

  const startDate = DateTime.local().startOf('week');
  const endDate = startDate.plus({
    days: 6
  });

  dateRanges.push({
    startDate,
    endDate
  });

  for (let index = 1; index < numberOfWeeks; index++) {
    const nextWeekStartDate = dateRanges[index - 1].endDate.plus({
      days: 1
    });
    const nextWeekEndDate = nextWeekStartDate.plus({
      days: 6
    });

    dateRanges.push({
      startDate: nextWeekStartDate,
      endDate: nextWeekEndDate
    });
  }

  return dateRanges.map(dateRange => ({
    startDate: dateRange.startDate.toISODate(),
    endDate: dateRange.endDate.toISODate()
  }));
};

export default useDateRangesForChartData;
