import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { array, number, object, string } from 'yup';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';
import { ResourceAllocationStatus } from '~/types';
import {
  buildResourceRequest,
  buildRuleConstraintsForPeriod,
  buildScheduleRulesWithDefaultConstraint,
  excludeWeekdaysDefault,
  getResourceRequestDatesFromProjectDate,
  getScheduleTotalHours
} from '~/modules/resourcing/common/util';
import { getAllocationDatesFromProjectDate } from '~/modules/resourcing/common/util/resourceAllocationUtil';
import {
  dateToMidnightUTCObject,
  dateToMidnightUTCString,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { compareISOStrings } from '~/modules/common/dates/compare';
import { makeTenantScopedUri } from '~/util';
import { useMeContext } from '~/modules/me';
import { useGetScheduledRulesForUserAndDateRangeCallback } from '~/modules/resourcing/common/hooks/useGetScheduleRulesForUserAndDateRange';
import { GET_EXISTING_ALLOCATION_QUERY } from '~/modules/resourcing/common/hooks/useGetExistingAllocation';

const getResourceAllocationInitialState = ({ me, scale, project }) => {
  const {
    requestStartDate: start,
    requestEndDate: end
  } = getResourceRequestDatesFromProjectDate({
    projectStartDate: project.startDate,
    projectEndDate: project.endDate,
    scale
  });

  return {
    resourceAllocation: {
      id: makeTenantScopedUri(me, 'psa-resource-allocation', uuidv4()),
      role: null,
      project,
      isNewAllocation: true
    },
    project,
    user: null,
    role: null,
    load: 100,
    startDate: dateToMidnightUTCString(start),
    endDate: dateToMidnightUTCString(end),
    totalAllocatedHours: 0,
    scheduleRules: []
  };
};

export const getExistingResourceAllocation = async ({
  apolloClient,
  projectUri,
  userUri
}) => {
  const { data } = await apolloClient.query({
    query: GET_EXISTING_ALLOCATION_QUERY,
    variables: {
      projectUri,
      userUri,
      allocationStatusList: [ResourceAllocationStatus.Committed]
    },
    fetchPolicy: 'network-only'
  });

  return data?.resourceAllocations?.resourceAllocations[0] || null;
};

const getQuickAllocationInitialState = ({
  user,
  projectRole,
  totalScheduledHours,
  startDate,
  endDate,
  scheduleRules
}) => ({
  id: uuidv4(),
  isNew: true,
  project: null,
  role: projectRole,
  load: 100,
  user,
  startDate,
  endDate,
  totalAllocatedHours: totalScheduledHours || 0,
  scheduleRules
});

const validationSchema = ({ intl }) =>
  object().shape({
    project: object().nullable(),
    role: object().nullable(),
    totalAllocatedHours: number().moreThan(
      0,
      intl.formatMessage(
        {
          id: 'resourceRequestDrawerDetails.minValueForTotalHours'
        },
        {
          value: 0
        }
      )
    ),
    load: number()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'resourceRequestDrawerDetails.loadError'
        })
      )
      .moreThan(
        0,
        intl.formatMessage(
          {
            id: 'resourceRequestDrawerDetails.minValueForLoad'
          },
          {
            value: 0
          }
        )
      )
      .max(
        999,
        intl.formatMessage(
          {
            id: 'resourceRequestDrawerDetails.maxValueForLoad'
          },
          {
            value: 999
          }
        )
      ),
    startDate: string(),
    endDate: string(),
    user: object().nullable(),
    scheduleRules: array().nullable()
  });

const getUpdatedScheduleRules = ({
  totalAllocatedHours,
  totalScheduledHours,
  scheduleRules,
  startDate,
  endDate
}) => {
  const dayFactor = totalAllocatedHours / totalScheduledHours;

  const totalDays =
    DateTime.fromISO(endDate)
      .diff(DateTime.fromISO(startDate), 'days')
      .toObject().days + 1;

  return scheduleRules.map(rule => ({
    ...rule,
    do: {
      ...rule.do,
      load:
        totalScheduledHours > 0
          ? 100 * dayFactor
          : totalAllocatedHours / totalDays
    }
  }));
};

const getUpdatedScheduleRulesWithScheduleRuleConstraint = ({
  scheduleRules,
  load,
  startDate,
  endDate,
  totalAllocatedHours
}) => {
  const scheduledHours = getScheduleTotalHours({
    scheduleRules,
    quantity: 1
  });

  return scheduledHours !== 0
    ? getUpdatedScheduleRules({
        totalAllocatedHours: (scheduledHours * load) / 100,
        scheduleRules,
        startDate,
        endDate,
        totalScheduledHours: scheduledHours
      })
    : buildScheduleRulesWithDefaultConstraint({
        startDate,
        endDate,
        defaultScheduleRule: {
          do: buildRuleConstraintsForPeriod({
            start: dateToMidnightUTCObject(startDate),
            end: dateToMidnightUTCObject(endDate),
            quantity: 1,
            totalHours: totalAllocatedHours,
            excludeWeekdays: excludeWeekdaysDefault,
            load: load || 100
          })
        }
      });
};

export const useAllocateResourcePopupHandler = ({
  scale,
  handleAddAllocation,
  project,
  setNewDraftResourceAllocation,
  user,
  projectRole,
  defaultDates,
  totalScheduledHours,
  scheduleRules
}) => {
  const intl = useIntl();
  const me = useMeContext();

  const initialValues = project
    ? getResourceAllocationInitialState({ me, scale, project })
    : getQuickAllocationInitialState({
        user,
        projectRole,
        startDate: defaultDates.startDate,
        endDate: defaultDates.endDate,
        totalScheduledHours,
        scheduleRules
      });

  const onResourceAdd = useCallback(
    value => {
      const scheduledHours = getScheduleTotalHours({
        scheduleRules: value.scheduleRules,
        quantity: 1
      });

      const updateScheduledRules = getUpdatedScheduleRulesWithScheduleRuleConstraint(
        {
          scheduleRules: value.scheduleRules,
          totalAllocatedHours: value.totalAllocatedHours,
          startDate: value.startDate,
          endDate: value.endDate,
          load: value.load
        }
      );

      handleAddAllocation({
        resourceAllocation: value.resourceAllocation,
        user: value.user,
        role: value.role,
        load:
          scheduledHours !== 0
            ? (value.totalAllocatedHours / scheduledHours) * 100
            : 100,
        startDate: value.startDate,
        endDate: value.endDate,
        scheduleRules: updateScheduledRules
      });

      return {
        ...value,
        isChanged: false
      };
    },
    [handleAddAllocation]
  );

  const onQuickAllocationAdd = useCallback(
    async value => {
      const req = buildResourceRequest({
        project: {
          ...value.project
        },
        scale,
        me
      });

      const updateScheduledRules = getUpdatedScheduleRulesWithScheduleRuleConstraint(
        {
          scheduleRules: value.scheduleRules,
          totalAllocatedHours: value.totalAllocatedHours,
          startDate: value.startDate,
          endDate: value.endDate,
          load: value.load
        }
      );

      setNewDraftResourceAllocation({
        ...req,
        user,
        project: value.project,
        isNew: true,
        roleUri: value.role && value.role.id,
        scheduleRules: updateScheduledRules
      });

      return {
        ...value,
        isChanged: false
      };
    },
    [scale, me, setNewDraftResourceAllocation, user]
  );

  return {
    initialValues,
    validationSchema: validationSchema({ intl }),
    handleAdd: project ? onResourceAdd : onQuickAllocationAdd
  };
};

export const useAllocateResourcePopupFormChange = ({
  setFieldValue,
  setValues,
  scale,
  values,
  fetchProjectDetails,
  setTotalScheduledHours,
  totalScheduledHours,
  setExistingResourceAllocation
}) => {
  const {
    getUserScheduleRulesForDateRange
  } = useGetScheduledRulesForUserAndDateRangeCallback();
  const apolloClient = useApolloClient();

  return {
    onRoleUpdate: useCallback(
      value => {
        setFieldValue('role', value);
      },
      [setFieldValue]
    ),
    onProjectUpdate: useCallback(
      async value => {
        if (!value) {
          setValues({
            ...values,
            project: undefined
          });

          return;
        }
        const response =
          value && value.id && (await fetchProjectDetails(value.id));

        const {
          allocationStartDate,
          allocationEndDate
        } = getAllocationDatesFromProjectDate({
          projectStartDate: response?.data?.project?.startDate,
          projectEndDate: response?.data?.project?.endDate,
          scale
        });
        const userScheduleRulesForDateRange =
          value &&
          (await getUserScheduleRulesForDateRange(values.user.uri, {
            startDate: allocationStartDate,
            endDate: allocationEndDate
          }));

        setTotalScheduledHours(userScheduleRulesForDateRange?.scheduledHours);
        setFieldValue(
          'scheduleRules',
          userScheduleRulesForDateRange?.scheduleRules
        );
        setFieldValue(
          'totalAllocatedHours',
          userScheduleRulesForDateRange?.scheduledHours > 0
            ? (values.load * userScheduleRulesForDateRange?.scheduledHours) /
                100
            : 0
        );
        setFieldValue('startDate', allocationStartDate);
        setFieldValue('endDate', allocationEndDate);
        setFieldValue('project', response?.data?.project);
      },

      [
        fetchProjectDetails,
        getUserScheduleRulesForDateRange,
        scale,
        setFieldValue,
        setTotalScheduledHours,
        setValues,
        values
      ]
    ),
    onUserUpdateForTaskAllocation: useCallback(
      async value => {
        setFieldValue('user', value);

        if (value && values.project?.id) {
          const existingResourceAllocation = await getExistingResourceAllocation(
            { apolloClient, projectUri: values.project.id, userUri: value.uri }
          );

          setExistingResourceAllocation(existingResourceAllocation);
        } else setExistingResourceAllocation(null);

        const response =
          value &&
          (await getUserScheduleRulesForDateRange(value.uri, {
            startDate: values.startDate,
            endDate: values.endDate
          }));

        setTotalScheduledHours(response?.scheduledHours);
        setFieldValue('scheduleRules', response?.scheduleRules);
        setFieldValue('totalAllocatedHours', response?.scheduledHours || 0);
        setFieldValue('load', 100);

        if (value && value.roles && !values.role) {
          const primaryUserRole = value.roles?.find(r => r.isPrimary);

          primaryUserRole &&
            setFieldValue('role', {
              displayText: primaryUserRole.projectRole.name,
              id: primaryUserRole.projectRole.uri
            });
        }
      },
      [
        setFieldValue,
        setExistingResourceAllocation,
        getUserScheduleRulesForDateRange,
        values.startDate,
        values.endDate,
        values.role,
        values.project?.id,
        setTotalScheduledHours,
        apolloClient
      ]
    ),
    onUserUpdate: useCallback(
      async value => {
        setFieldValue('user', value);

        const response =
          value &&
          (await getUserScheduleRulesForDateRange(value.uri, {
            startDate: values.startDate,
            endDate: values.endDate
          }));

        setTotalScheduledHours(response?.scheduledHours);
        setFieldValue('scheduleRules', response?.scheduleRules);
        setFieldValue('totalAllocatedHours', response?.scheduledHours || 0);
        setFieldValue('load', 100);

        if (value && value.roles && !values.role) {
          const primaryUserRole = value.roles?.find(r => r.isPrimary);

          primaryUserRole &&
            setFieldValue('role', {
              displayText: primaryUserRole.projectRole.name,
              id: primaryUserRole.projectRole.uri
            });
        }
      },
      [
        setFieldValue,
        getUserScheduleRulesForDateRange,
        values.startDate,
        values.endDate,
        values.role,
        setTotalScheduledHours
      ]
    ),
    onLoadingUpdate: useCallback(
      load => {
        setValues({
          ...values,
          load,
          totalAllocatedHours:
            totalScheduledHours > 0
              ? (load * totalScheduledHours) / 100
              : values.totalAllocatedHours
        });
      },
      [setValues, totalScheduledHours, values]
    ),
    onTotalAllocatedHoursUpdate: useCallback(
      value =>
        setValues({
          ...values,
          load:
            totalScheduledHours > 0
              ? (value / totalScheduledHours) * 100
              : values.load,
          totalAllocatedHours: value
        }),
      [setValues, totalScheduledHours, values]
    ),
    onStartDateUpdate: useCallback(
      async startDate => {
        const formattedStartDate = mapRepliconDateToMidnightUTCString(
          startDate
        );

        const isStartDateAfterEndDate =
          compareISOStrings(formattedStartDate, values.endDate) === 1;

        if (!values.user) {
          if (isStartDateAfterEndDate) {
            setValues({
              ...values,
              endDate: formattedStartDate,
              startDate: formattedStartDate
            });

            return;
          }

          setValues({
            ...values,
            startDate: formattedStartDate
          });

          return;
        }
        const {
          scheduledHours: totalHours,
          scheduleRules
        } = await getUserScheduleRulesForDateRange(values.user.uri, {
          startDate: formattedStartDate,
          endDate: isStartDateAfterEndDate ? formattedStartDate : values.endDate
        });

        setTotalScheduledHours(totalHours);
        if (isStartDateAfterEndDate) {
          setValues({
            ...values,
            scheduleRules,
            endDate: formattedStartDate,
            totalAllocatedHours: totalHours,
            startDate: formattedStartDate
          });

          return;
        }

        setValues({
          ...values,
          scheduleRules,
          totalAllocatedHours:
            totalHours > 0 ? (totalHours * values.load) / 100 : 0,
          startDate: formattedStartDate
        });
      },
      [
        getUserScheduleRulesForDateRange,
        setTotalScheduledHours,
        setValues,
        values
      ]
    ),
    onEndDateUpdate: useCallback(
      async endDate => {
        const formattedEndDate = mapRepliconDateToMidnightUTCString(endDate);
        const isEndDateBeforeStartDate =
          compareISOStrings(formattedEndDate, values.startDate) === -1;

        if (!values.user) {
          if (isEndDateBeforeStartDate) {
            setValues({
              ...values,
              endDate: formattedEndDate,
              startDate: formattedEndDate
            });

            return;
          }

          setValues({
            ...values,
            endDate: formattedEndDate
          });

          return;
        }
        const {
          scheduledHours: totalHours,
          scheduleRules
        } = await getUserScheduleRulesForDateRange(values.user.uri, {
          startDate: isEndDateBeforeStartDate
            ? formattedEndDate
            : values.startDate,
          endDate: formattedEndDate
        });

        if (isEndDateBeforeStartDate) {
          setValues({
            ...values,
            scheduleRules,
            endDate: formattedEndDate,
            totalAllocatedHours: totalHours,
            startDate: formattedEndDate
          });

          return;
        }

        setTotalScheduledHours(totalHours);
        setValues({
          ...values,
          scheduleRules,
          endDate: formattedEndDate,
          totalAllocatedHours:
            totalHours > 0 ? (totalHours * values.load) / 100 : 0
        });
      },
      [
        getUserScheduleRulesForDateRange,
        setTotalScheduledHours,
        setValues,
        values
      ]
    )
  };
};

export default useAllocateResourcePopupHandler;
