import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SmartFitAllocationMode } from '~/types';
import AssignmentDialogOptionItem from './AssignmentDialogOptionItem';

const AllocateFullAmountOptionItem = ({ hours, scale, handleOptionSelect }) => {
  const intl = useIntl();

  const secondary = intl.formatMessage(
    { id: 'resourceAssignmentDialog.allocateAllValueLeavingSomeWithScale' },
    { scale, hours: hours.toFixed(2) }
  );

  const allocateFullLabel = intl.formatMessage({
    id: 'resourceAssignmentDialog.allocateFullAmount'
  });

  return (
    <AssignmentDialogOptionItem
      value={SmartFitAllocationMode.Allocatefull}
      primary={allocateFullLabel}
      secondary={secondary}
      onSelect={handleOptionSelect}
      inputProps={{
        'aria-label': allocateFullLabel
      }}
    />
  );
};

AllocateFullAmountOptionItem.propTypes = {
  hours: PropTypes.number,
  scale: PropTypes.string,
  handleOptionSelect: PropTypes.func
};

export default AllocateFullAmountOptionItem;
