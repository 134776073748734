import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { RESOURCING_ALLOCATION_MODEL } from '~/modules/common/enums';
import ProjectContext from './ProjectContext';

export const getProjectFromProps = ({ project }) => {
  if (!project) return null;

  return {
    ...project,
    hasStartDate: Boolean(project.startDate),
    hasEndDate: Boolean(project.endDate)
  };
};

const ProjectContextProvider = ({ children, project }) => {
  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  const resourcingAllocationModel = isPsaRmpTaskAllocation1Enabled
    ? RESOURCING_ALLOCATION_MODEL.MODEL_1
    : RESOURCING_ALLOCATION_MODEL.MODEL_0;

  const contextValues = useMemo(
    () => ({
      project: getProjectFromProps({ project }),
      resourcingAllocationModel
    }),
    [project, resourcingAllocationModel]
  );

  return (
    <ProjectContext.Provider value={contextValues}>
      {children}
    </ProjectContext.Provider>
  );
};

ProjectContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  project: PropTypes.object
};

export default ProjectContextProvider;
