import { gql } from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';

import { ResourceRequestStatus } from '~/types';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import {
  mapToServiceRequest,
  tryLoadCachedResourceRequestsQuery
} from '../../util';

export const UNSUBMIT_RESOURCE_REQUEST = gql`
  mutation MarkResourceRequestAsDraft(
    $input: MarkResourceRequestAsDraftInput!
  ) {
    markResourceRequestAsDraft2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
`;

export const updateCache = (updateFunction, projectId) => (
  proxy,
  mutationResponse
) => {
  const {
    data: { isOptimistic, markResourceRequestAsDraft2: newRequest }
  } = mutationResponse;

  const cachedResourceRequests = tryLoadCachedResourceRequestsQuery({
    proxy,
    projectUri: projectId,
    optimistic: isOptimistic
  });

  if (cachedResourceRequests) {
    const { resourceRequests: data } = cachedResourceRequests;

    proxy.writeQuery({
      query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
      variables: {
        projectUri: projectId,
        cursor: null,
        limit: 500
      },
      data: {
        resourceRequests: {
          ...data,
          resourceRequests: data.resourceRequests.map(req =>
            req.id === newRequest.id
              ? {
                  ...req,
                  requestStatus: ResourceRequestStatus.Draft,
                  resourceAllocations: []
                }
              : req
          )
        }
      }
    });
  }
};

export const invokeMarkResourceRequestAsDraft = markResourceRequestAsDraft => (
  request,
  chartSelectedTab,
  requestAccessLevel
) =>
  markResourceRequestAsDraft(
    mapToServiceRequest({
      request,
      responseType: 'markResourceRequestAsDraft2',
      responseTypeName: 'MarkResourceRequestAsDraftResult',
      requestStatus: ResourceRequestStatus.Draft,
      cacheUpdate: updateCache,
      requestAccessLevel
    })
  );

export default graphql(UNSUBMIT_RESOURCE_REQUEST, {
  name: 'markResourceRequestAsDraft'
});
