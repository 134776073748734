import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { ContactInfoListItemContext } from './hooks/useContactInfoListItemContext';

export const ContactInfoListItemContextProvider = ({
  children,
  contactHandlers
}) => {
  const value = useMemo(
    () => ({
      contactHandlers
    }),
    [contactHandlers]
  );

  return (
    <ContactInfoListItemContext.Provider value={value}>
      {children}
    </ContactInfoListItemContext.Provider>
  );
};

ContactInfoListItemContextProvider.propTypes = {
  children: PropTypes.node,
  contactHandlers: PropTypes.object
};
