import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIsRTL } from '~/modules/common/hooks/useIsRTL';
import ListTablePropTypes from '../../../PropTypes';
import TableFooterCell from './TableFooterCell';
import TableRow from './TableRow';

const getAlignment = (footer, isRTL) => {
  if (
    isRTL &&
    footer.flip !== undefined &&
    !footer.flip &&
    (footer.align === 'right' || footer.align === 'left')
  ) {
    return footer.align === 'left' ? 'right' : 'left';
  }

  return footer.align;
};

const TotalCellRender = memo(({ field, value, component }) => {
  const record = useMemo(
    () => ({
      [field]: value
    }),
    [field, value]
  );

  if (!component) return value;

  const Component = component;

  return <Component field={field} record={record} />;
});

TotalCellRender.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
    PropTypes.array
  ]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

const TableFooterRow = ({
  classes,
  variant,
  footers,
  columns,
  totals,
  styles,
  enableRowSelection,
  useInlineStyles
}) => {
  const isRTL = useIsRTL();

  return variant === 'table' && footers && totals ? (
    <TableRow className={classes.tableFooterRow}>
      {enableRowSelection && (
        <TableFooterCell
          align="center"
          fixed
          className={classNames(
            classes.tableFooterCell,
            classes.tabelCellSelection
          )}
        />
      )}
      {Object.keys(footers).map((field, index) => (
        <TableFooterCell
          key={`${field}-footer`}
          fixed={footers[field].fixed}
          leftOfFixed={!footers[field].fixed}
          className={classNames(
            classes.tableFooterCell,
            footers[field].className,
            {
              [classes.tableFixedCellWithSelection]:
                index === 0 && enableRowSelection && footers[field].fixed
            }
          )}
          rowSpan={footers[field].rowSpan}
          colSpan={footers[field].colSpan}
          align={getAlignment(footers[field], isRTL)}
          data-qe-id={field}
        >
          {totals[field] ? (
            <TotalCellRender
              component={footers[field].render}
              field={field}
              value={totals[field]}
            />
          ) : (
            footers[field].value
          )}
        </TableFooterCell>
      ))}
    </TableRow>
  ) : (
    totals && (
      <TableRow className={classes.tableFooterRow}>
        {Object.keys(columns).map((field, index) => (
          <TableFooterCell
            key={`${field}-footer`}
            fixed={index === 0}
            leftOfFixed={index === 1}
            className={classes.tableFooterCell}
            style={useInlineStyles ? styles[field] : undefined}
          >
            {totals && totals[field] && totals[field].value}
          </TableFooterCell>
        ))}
      </TableRow>
    )
  );
};

TableFooterRow.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string,
  footers: ListTablePropTypes.footers,
  columns: ListTablePropTypes.columns,
  totals: PropTypes.any,
  styles: PropTypes.object,
  enableRowSelection: PropTypes.bool,
  useInlineStyles: PropTypes.bool
};

export default memo(TableFooterRow);
