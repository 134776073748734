import React from 'react';
import PropTypes from 'prop-types';
import { ServiceCenterIcon } from '~/modules/common/components/Icons';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupIconField from './GroupIconField';

export const ResourceRequestServiceCenterField = ({
  value,
  serviceCenterSettings
}) => (
  <GroupIconField
    value={value}
    GroupIcon={ServiceCenterIcon}
    groupSettings={serviceCenterSettings}
    labelKey="resourceRequestItem.serviceCenter"
  />
);

ResourceRequestServiceCenterField.propTypes = {
  value: PropTypes.object,
  serviceCenterSettings: PropTypes.object.isRequired
};
export default withGroupSettings(
  'serviceCenter',
  'serviceCenterSettings'
)(ResourceRequestServiceCenterField);
