import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import { SelectAllFormatter } from './formatters/SelectAllFormatter';
import { FormattedAmountHeader } from './FormattedAmountHeader';

const resourceLabels = {
  amount: <FormattedMessage id="billingItemTable.amount" />,
  originalCurrency: <FormattedMessage id="billingItemTable.originalCurrency" />,
  billCurrency: <FormattedMessage id="billingItemTable.billCurrency" />,
  billCurrencyNote: <FormattedMessage id="billingItemTable.billCurrencyNote" />
};

export const buildColumns = ({
  classes = {},
  selectedColumns = [],
  onRowSelect,
  onSelectAll,
  billingItems,
  selectedRows,
  hasMultipleCurrencies,
  resourceKey: { selectAriaLabel, selectAllAriaLabel } = {},
  isPsaFPOvertimeBillingEnabled
}) => {
  const columns = {
    rowSelector: {
      visible: true,
      onRowSelect,
      selectedRows,
      sortIndex: -1,
      ariaLabel: selectAriaLabel,
      value: (
        <SelectAllFormatter
          onSelectAll={onSelectAll}
          selectedRows={selectedRows}
          billingItems={billingItems}
          ariaLabel={selectAllAriaLabel}
        />
      )
    },
    projectName: {
      groupId: BILLING_COLUMN_TYPE.PROJECT,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.PROJECT),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.PROJECT),
      value: <FormattedMessage id="billingItemTable.project" />,
      className: classes.projectName
    },
    itemType: {
      groupId: BILLING_COLUMN_TYPE.ITEMTYPE,
      align: 'left',
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.ITEMTYPE),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.ITEMTYPE),
      value: <FormattedMessage id="billingItemTable.itemType" />,
      className: classes.itemType
    },
    taskName: {
      groupId: BILLING_COLUMN_TYPE.TASK,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.TASK),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.TASK),
      value: <FormattedMessage id="billingItemTable.task" />,
      className: classes.taskName
    },
    user: {
      groupId: BILLING_COLUMN_TYPE.USER,
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.USER),
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.USER),
      value: <FormattedMessage id="billingItemTable.resource" />,
      grouping: true,
      className: classes.user
    },
    timesheetPeriod: {
      groupId: BILLING_COLUMN_TYPE.TIMESHEET_PERIOD,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.TIMESHEET_PERIOD),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.TIMESHEET_PERIOD),
      value: <FormattedMessage id="billingItemTable.timesheetPeriod" />,
      className: classes.timesheetPeriod
    },
    payCode: {
      groupId: BILLING_COLUMN_TYPE.PAYCODE,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.PAYCODE),
      visible:
        isPsaFPOvertimeBillingEnabled &&
        selectedColumns.includes(BILLING_COLUMN_TYPE.PAYCODE),
      value: <FormattedMessage id="billingItemTable.payCode" />,
      className: classes.payCode
    },
    role: {
      groupId: BILLING_COLUMN_TYPE.ROLE,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.ROLE),
      visible:
        isPsaFPOvertimeBillingEnabled &&
        selectedColumns.includes(BILLING_COLUMN_TYPE.ROLE),
      value: <FormattedMessage id="billingItemTable.role" />,
      className: classes.role
    },
    expenseCode: {
      groupId: BILLING_COLUMN_TYPE.EXPENSE_CODE,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.EXPENSE_CODE),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.EXPENSE_CODE),
      align: 'left',
      value: <FormattedMessage id="billingItemTable.expenseCode" />,
      className: classes.expenseCode
    },
    quantity: {
      groupId: BILLING_COLUMN_TYPE.UNIT,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.UNIT),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.UNIT),
      align: 'right',
      value: <FormattedMessage id="billingItemTable.units" />,
      className: classes.quantity
    },
    billingItemRate: {
      groupId: BILLING_COLUMN_TYPE.RATE,
      sortIndex: selectedColumns.indexOf(BILLING_COLUMN_TYPE.RATE),
      visible: selectedColumns.includes(BILLING_COLUMN_TYPE.RATE),
      value: <FormattedMessage id="billingItemTable.rate" />,
      align: 'right',
      className: classes.billingItemRate
    },
    billingAmountOriginal: {
      visible: hasMultipleCurrencies,
      sortIndex: isPsaFPOvertimeBillingEnabled ? selectedColumns.length + 1 : 6,
      value: (
        <FormattedAmountHeader
          classes={classes}
          title={resourceLabels.amount}
          displaySubTitle={hasMultipleCurrencies}
          subTitle={resourceLabels.originalCurrency}
        />
      ),
      align: 'right',
      className: classes.billingAmount
    },
    billingAmount: {
      visible: true,
      sortIndex: isPsaFPOvertimeBillingEnabled ? selectedColumns.length + 2 : 7,
      value: (
        <FormattedAmountHeader
          classes={classes}
          title={resourceLabels.amount}
          displaySubTitle={hasMultipleCurrencies}
          subTitle={resourceLabels.billCurrency}
          subTitleTooltip={resourceLabels.billCurrencyNote}
        />
      ),
      align: 'right',
      className: classes.billingAmount
    }
  };

  return Object.keys(columns)
    .sort((a, b) => columns[a].sortIndex - columns[b].sortIndex)
    .reduce(
      (retVal, currentVal) => ({
        ...retVal,
        [currentVal]: columns[currentVal]
      }),
      {}
    );
};
