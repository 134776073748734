import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { ProjectChartRowsLoading } from '~/modules/resourcing/common/components';
import { useChartDisplayPeriods } from '~/modules/resourcing/common/chart/hooks';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { useGetTaskResourceUserAllocationsSummaryForUser } from '~/modules/common/components/TaskDrawer/common/hooks';
import { useFetchResourceUserTaskAssignments } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';
import UserAllocationsSummaryContextProvider from '../../UserAllocationsSummaryContextProvider';
import { UserTaskAssignmentRow } from './components/UserTaskAssignmentRow';
import {
  RowLeftItemLoading,
  UserTaskAssignmentShowMore
} from './components/UserTaskAssignmentRow/components';
import { UserTaskAssignmentFooter } from './components/UserTaskAssignmentFooter';

const PAGE_SIZE = 10;
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1)
  },
  loadingIndicator: {
    margin: theme.spacing(1, 0, 1, 3)
  }
}));

export const UserTaskAssignments = ({
  user,
  userScheduleDetails,
  resourceAllocationScheduleRules,
  loadingResourceAllocation
}) => {
  const classes = useStyles();
  const {
    project: { id: projectId }
  } = useProjectContext();

  const { dateRange, scale } = useResourceRequestToolbarContext();

  const { id: userId, displayText } = user;
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    userTaskAssignmentsData,
    handleRemoveTaskAssignment,
    handleUpdateTaskAssignment
  } = useFetchResourceUserTaskAssignments({
    userId,
    projectId,
    pageSize: PAGE_SIZE,
    currentPage,
    setCurrentPage
  });

  const chartDisplayPeriods = useChartDisplayPeriods({
    chartDisplayDateRange: dateRange,
    scale
  });

  const {
    loading: userTaskAllocationsSummaryLoading,
    taskResourceUserAllocationsSummary
  } = useGetTaskResourceUserAllocationsSummaryForUser({
    userId: user.id,
    projectId
  });

  const onShowMoreButtonClick = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage]);

  const loadingFirstPage = currentPage === 1 && loading;
  const loadingMore = currentPage > 1 && loading;
  const hasMore =
    Object.keys(userTaskAssignmentsData).length === currentPage * PAGE_SIZE;

  if (loadingFirstPage)
    return <ProjectChartRowsLoading LeftComponent={RowLeftItemLoading} />;

  return (
    <div data-qe-id={`${displayText}_Project`} className={classes.root}>
      {userTaskAssignmentsData.length ? (
        <UserAllocationsSummaryContextProvider
          userTaskAllocationsSummaryScheduleRules={
            taskResourceUserAllocationsSummary?.scheduleRules
          }
          userTaskAllocationsSummaryLoading={userTaskAllocationsSummaryLoading}
          resourceAllocationScheduleRules={resourceAllocationScheduleRules}
          loadingResourceAllocation={loadingResourceAllocation}
          chartDisplayPeriods={chartDisplayPeriods}
        >
          {userTaskAssignmentsData.map(taskResourceEstimate => (
            <UserTaskAssignmentRow
              key={taskResourceEstimate.task.id}
              taskResourceEstimate={taskResourceEstimate}
              userScheduleDetails={userScheduleDetails}
              user={user}
              chartDisplayPeriods={chartDisplayPeriods}
              projectId={projectId}
              handleRemoveTaskAssignment={handleRemoveTaskAssignment}
            />
          ))}
          {loadingMore && (
            <div className={classes.loadingIndicator}>
              <CircularProgress size={24} />
            </div>
          )}
          {hasMore && !loadingMore && (
            <UserTaskAssignmentShowMore loadMore={onShowMoreButtonClick} />
          )}
        </UserAllocationsSummaryContextProvider>
      ) : (
        <Typography className={classes.label}>
          <FormattedMessage id="taskAssignments.noTasksAssigned" />
        </Typography>
      )}
      <UserTaskAssignmentFooter
        projectId={projectId}
        user={user}
        handleUpdateTaskAssignment={handleUpdateTaskAssignment}
      />
    </div>
  );
};
UserTaskAssignments.propTypes = {
  user: PropTypes.object,
  userScheduleDetails: PropTypes.object,
  resourceAllocationScheduleRules: PropTypes.array,
  loadingResourceAllocation: PropTypes.bool
};
export default UserTaskAssignments;
