import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ListItemText, MenuItem } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { FocusableMenuItem } from '~/modules/common/components/FocusableMenuItem';

export const ChartDateRangeQuarterOption = ({
  isSelected,
  value,
  label,
  dateLabel,
  onClick
}) => {
  const handleOnClick = useCallback(() => onClick(value), [onClick, value]);
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();

  return (
    <>
      {isPsaPrpAccessibilityIssuesEnabled ? (
        <FocusableMenuItem
          isSelected={isSelected}
          value={value}
          handleOnClick={handleOnClick}
        >
          <ListItemText primary={label} secondary={dateLabel} />
        </FocusableMenuItem>
      ) : (
        <MenuItem selected={isSelected} value={value} onClick={handleOnClick}>
          <ListItemText primary={label} secondary={dateLabel} />
        </MenuItem>
      )}
    </>
  );
};

ChartDateRangeQuarterOption.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  value: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  dateLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ChartDateRangeQuarterOption;
