import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    height: 300,
    width: 400
  },
  loadingContainer: {
    position: 'fixed',
    left: '50%',
    top: '50%'
  },
  validationIndicator: {
    color: theme.palette.grey[600]
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  },
  dateInput: {
    paddingLeft: 0,
    '& button': {
      paddingRight: 0
    }
  },
  fieldLabel: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize
  },
  date: {
    cursor: 'pointer'
  },
  dateAdornedEnd: {
    paddingRight: 0
  },
  dateAdornmentRoot: {
    top: '50%',
    right: 0,
    color: theme.palette.text.secondary,
    position: 'absolute',
    pointerEvents: 'none'
  },
  input: {
    fontSize: 'inherit'
  },
  customContainer: {
    padding: theme.spacing(1)
  },
  dropDownIcon: {
    marginRight: theme.spacing(2)
  },
  zeroPaddingField: {
    padding: 0
  },
  readOnly: {
    marginLeft: theme.spacing(2)
  }
}));

export default useStyles;
