import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ResourceRequestStatus } from '~/types';
import User from '~/modules/common/components/User';
import { PreferredResourceIconTooltip } from '~/modules/resourcing/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: theme.spacing(0.5, -3, 0.25, -0.25)
  },
  rootFFOn: {
    minWidth: 0,
    padding: theme.spacing(0.25, 0, 0, 0)
  },
  resource: {
    ...theme.palette.text.primary,
    flexShrink: 1,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    flexDirection: 'row',
    padding: theme.spacing(0, 0, 0, 1),
    lineHeight: theme.spacing(3),
    display: 'flex',
    width: '100%'
  },
  allocation: {
    display: 'flex',
    overflow: 'hidden',
    paddingRight: theme.spacing(2.25),
    zIndex: 1
  },
  allocationFFOn: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0
  },
  resourceFFOn: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));

export const useDefaultNameStyles = makeStyles(theme => ({
  name: {
    hyphens: 'auto',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    padding: theme.spacing(0, 0, 0.25, 0.5)
  }
}));

export const useRejectedNameStyles = makeStyles(theme => ({
  name: {
    hyphens: 'auto',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    width: theme.spacing(12),
    padding: theme.spacing(0, 0, 0.25, 0.5)
  }
}));

export const useAllocationStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0.25, 0)
  },
  name: {
    ...theme.typography.body2
  }
}));

export const usePreferredIconStyles = makeStyles(theme => ({
  preferredResourceIcon: {
    flexShrink: 0,
    margin: theme.spacing(0, 0, 0, 0.5)
  }
}));

export const ResourceRequestAllocations = ({
  allocations = [],
  showResourceAllocation,
  requestStatus,
  preferredResources,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles();
  const defaultNameClass = useDefaultNameStyles();
  const rejectedNameClass = useRejectedNameStyles();
  const allocationClasses = useAllocationStyles();
  const preferredIconClasses = usePreferredIconStyles();

  return (
    <div
      className={classNames({
        [classes.root]: !isPsaRmpTaskAllocation1Enabled,
        [classes.rootFFOn]: isPsaRmpTaskAllocation1Enabled
      })}
      data-qe-id="ResourceRequestAllocation"
    >
      {showResourceAllocation &&
        allocations.length !== 0 &&
        (allocations || []).map(allocation => {
          const { user } = allocation.user;
          const isPreferredResource = Boolean(
            preferredResources?.find(
              preferredResource => preferredResource.id === user.uri
            )
          );

          return (
            <div
              key={allocation.id}
              className={classNames({
                [classes.allocation]:
                  isPreferredResource && !isPsaRmpTaskAllocation1Enabled,
                [classes.allocationFFOn]: isPsaRmpTaskAllocation1Enabled
              })}
            >
              <Tooltip
                title={user.displayText}
                placement={isPsaRmpTaskAllocation1Enabled && 'bottom-start'}
              >
                <div
                  className={classNames({
                    [classes.resource]: !isPsaRmpTaskAllocation1Enabled,
                    [classes.resourceFFOn]: isPsaRmpTaskAllocation1Enabled
                  })}
                >
                  <User
                    user={user}
                    classes={
                      isPsaRmpTaskAllocation1Enabled
                        ? allocationClasses
                        : requestStatus ===
                          ResourceRequestStatus.Allocationrejected
                        ? rejectedNameClass
                        : defaultNameClass
                    }
                  />
                </div>
              </Tooltip>

              {isPreferredResource && (
                <PreferredResourceIconTooltip
                  classes={
                    isPsaRmpTaskAllocation1Enabled && preferredIconClasses
                  }
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

ResourceRequestAllocations.propTypes = {
  allocations: PropTypes.array,
  showResourceAllocation: PropTypes.bool,
  requestStatus: PropTypes.string,
  preferredResources: PropTypes.array,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default ResourceRequestAllocations;
