import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback, useEffect } from 'react';
import { rate } from './query';

export const GET_PROJECT_RATES = gql`
  query getAllProjectRates($projectId: String!, $page: Int!, $pageSize: Int!) {
    project(projectId: $projectId) {
      id
      projectCurrentEffectiveBillingRates(page: $page, pageSize: $pageSize) {
        id
        billingRate {
          id
          displayText
        }
        ${rate}
        asOfDate
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const updateQuery = (
  previousResult,
  { fetchMoreResult: { project: nextResult } }
) => {
  const prevResult =
    previousResult?.project.projectCurrentEffectiveBillingRates || [];
  const nextResults = nextResult.projectCurrentEffectiveBillingRates || [];

  return {
    project: {
      ...previousResult.project,
      projectCurrentEffectiveBillingRates: [...prevResult, ...nextResults]
    }
  };
};

const useProjectUserBillingRates = ({ projectId }) => {
  const [page, setPage] = useState(1);
  const variables = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    projectId
  };

  const { loading, error, data, fetchMore } = useQuery(GET_PROJECT_RATES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables
  });

  const projectCurrentEffectiveBillingRates =
    data && data.project && data.project.projectCurrentEffectiveBillingRates;

  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(
    () =>
      setHasMoreRows(
        Boolean(
          projectCurrentEffectiveBillingRates &&
            projectCurrentEffectiveBillingRates.length > 0 &&
            projectCurrentEffectiveBillingRates.length % DEFAULT_PAGE_SIZE === 0
        )
      ),
    [projectCurrentEffectiveBillingRates, setHasMoreRows]
  );

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows || isLoadingMore) return;
    setIsLoadingMore(true);
    const nextPage = page + 1;

    const {
      data: {
        project: { projectCurrentEffectiveBillingRates: newEntries }
      }
    } = await fetchMore({
      query: GET_PROJECT_RATES,
      variables: {
        ...variables,
        page: nextPage
      },
      updateQuery
    });

    if (newEntries.length > 0) {
      setPage(nextPage);
    }
    setHasMoreRows((newEntries || []).length === DEFAULT_PAGE_SIZE);
    setIsLoadingMore(false);
  }, [page, fetchMore, variables]);

  return {
    loadingRows: loading,
    projectCurrentEffectiveBillingRates: (
      projectCurrentEffectiveBillingRates || []
    ).map(r => ({
      id: r.id,
      entity: r.billingRate,
      asOfDate: r.asOfDate,
      rate: r.rate
    })),
    error,
    loadMoreRows,
    hasMoreRows,
    isLoadingMore,
    setPage
  };
};

export default useProjectUserBillingRates;
