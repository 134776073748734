import { makeStyles } from '@material-ui/core/styles';

export const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: ({
    allowBillable,
    editable,
    viewSummary,
    isLabelRowColumnVisible,
    isBillableTypeRowColumnVisible,
    billableColumns
  }) => ({
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      position: 'sticky',
      right: -16,
      color: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[50]
    },
    '& td:nth-child(1), th:nth-child(1)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        position: 'sticky',
        left: 0,
        zIndex: 2
      }
    },
    '& td:nth-child(2), th:nth-child(2)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        position: 'sticky',
        left: 88,
        zIndex: 2
      }
    },
    '& td:nth-child(3), th:nth-child(3)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        position: 'sticky',
        left: 164,
        zIndex: 2
      }
    },
    '& th:nth-child(1)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        position: 'sticky',
        zIndex: 3
      }
    },
    '& th:nth-child(2)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        position: 'sticky',
        zIndex: 3
      }
    },
    '& th:nth-child(3)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        position: 'sticky',
        zIndex: 3
      }
    },
    '& td:nth-last-child(2), th:nth-last-child(2)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        position: 'sticky',
        right: 64,
        borderLeft: `1px solid ${theme.palette.divider}`
      }
    },
    '& td:nth-last-child(3), th:nth-last-child(3)': {
      [theme.breakpoints.between('sm', 'xl')]: {
        borderRight: 'none'
      }
    },
    '& tr td:first-child, th:first-child, td:last-child, th:last-child': {
      borderRight: 'none'
    },
    '& tbody tr:last-child td': {
      borderBottom: 'none'
    },
    '& tr td:nth-child(2), th:nth-child(2)': {
      borderLeft: !isBillableTypeRowColumnVisible,
      borderRight:
        (billableColumns.length && (editable || viewSummary)) ||
        (!editable && !viewSummary)
          ? 'none'
          : `1px solid ${theme.palette.divider}`,
      paddingRight: billableColumns.length ? theme.spacing(2) : 0
    },
    '& tfoot td:nth-child(3)': {
      borderRight: `1px solid ${theme.palette.divider}`,
      textAlign: 'right',
      fontSize: theme.typography.body2.fontSize,
      '& p': {
        fontWeight: theme.typography.fontWeightRegular
      }
    },
    borderBottom: 'none',
    width: '100%',
    padding: theme.spacing(0, 1),
    paddingTop: 'none'
  }),
  tableHeaderCell: ({ editable, viewSummary }) => ({
    fontSize: theme.typography.body2.fontSize,
    verticalAlign: 'bottom',
    whiteSpace: 'break-spaces',
    position: 'sticky',
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    zIndex: 1,
    color: theme.palette.text.primary,
    height: 0,
    top: theme.spacing(editable || viewSummary ? 8.5 : 0),
    padding: theme.spacing(0.5, 0.5)
  }),
  tableCell: {
    height: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5, 0.5),
    whiteSpace: 'break-spaces',
    '& p': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  tableFooterCell: {
    height: 0,
    padding: theme.spacing(0.5, 0.5),
    whiteSpace: 'break-spaces',
    '& p': {
      fontWeight: theme.typography.fontWeightRegular
    },
    minWidth: theme.spacing(10),
    maxWidth: theme.spacing(14),
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    bottom: theme.spacing(-2),
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[50]
  },
  tableFooterRow: {
    backgroundColor: theme.palette.common.white
  }
}));

export const useColumnStyles = makeStyles(theme => ({
  date: {
    '& p': {
      whiteSpace: 'break-spaces'
    }
  },
  money: {
    '& span': {
      whiteSpace: 'nowrap'
    }
  },
  'read-only-amount': {
    '& span': {
      whiteSpace: 'nowrap'
    }
  },
  delete: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1)
  },
  noBorder: {
    borderLeft: 'none',
    borderRight: 'none'
  }
}));

export const useCardStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    boxShadow: 'none'
  }
}));
