import React, { memo } from 'react';
import PropTypes from 'prop-types';

import EditableHours from './EditableHours';
import ReadOnlyHours from './ReadOnlyHours';

export const highlightTarget = event => event.target.select();

export const Hours = ({
  value = null,
  precision = 2,
  isEditible = false,
  onChange,
  variant = 'filled',
  className,
  minHeight,
  underline,
  hiddenLabel,
  align,
  ...rest
}) =>
  isEditible ? (
    <EditableHours
      className={className}
      onValueChange={onChange}
      value={value}
      onFocus={highlightTarget}
      precision={precision}
      variant={variant}
      underline={underline}
      hiddenLabel={hiddenLabel}
      align={align}
      {...rest}
    />
  ) : (
    <ReadOnlyHours
      className={className}
      value={value}
      precision={precision}
      variant={variant}
      minHeight={minHeight}
      underline={underline}
      {...rest}
    />
  );

Hours.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.node,
  variant: PropTypes.string,
  precision: PropTypes.number,
  isEditible: PropTypes.bool,
  onChange: PropTypes.func,
  align: PropTypes.string,
  minHeight: PropTypes.number,
  underline: PropTypes.bool,
  hiddenLabel: PropTypes.bool
};

export default memo(Hours);
