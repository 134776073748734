import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import AddResourceEstimateDialogHeader from './AddResourceEstimateDialogHeader';
import AddResourceEstimateDialogContent from './AddResourceEstimateDialogContent';
import AddResourceEstimateDialogFooter from './AddResourceEstimateDialogFooter';

const AddResourceEstimateDialog = ({ task, isOpen, onClose }) => {
  const [role, setRole] = useState(task.assignedRole || null);
  const [resource, setResource] = useState(null);

  return (
    <Dialog maxWidth="xs" fullWidth open={isOpen} onClose={onClose}>
      <AddResourceEstimateDialogHeader task={task} />
      <AddResourceEstimateDialogContent
        projectSlug={task.projectReference.slug}
        role={role}
        setRole={setRole}
        resource={resource}
        setResource={setResource}
        taskId={task.id}
      />
      <AddResourceEstimateDialogFooter
        taskId={task.id}
        role={role}
        resource={resource}
        onClose={onClose}
      />
    </Dialog>
  );
};

AddResourceEstimateDialog.propTypes = {
  task: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddResourceEstimateDialog;
