import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getEarliestDate, getLatestDate } from '~/modules/common/dates/compare';
import RequestDrawerResourceAllocationItem from '../RequestDrawerResourceAllocationItem';

const RequestDrawerResourceAllocationList = ({
  resourceRequest,
  resourceAllocations = [],
  resourceRequestTotalHours,
  resourceRequestTotalCost
}) => {
  const dateRange = useMemo(
    () => ({
      startDate: getEarliestDate([
        mapIsoStringtoUtcObject(resourceRequest.startDate),
        ...resourceAllocations.map(resourceAllocation =>
          mapIsoStringtoUtcObject(resourceAllocation.startDate)
        )
      ]),
      endDate: getLatestDate([
        mapIsoStringtoUtcObject(resourceRequest.endDate),
        ...resourceAllocations.map(resourceAllocation =>
          mapIsoStringtoUtcObject(resourceAllocation.endDate)
        )
      ])
    }),
    [resourceRequest.resourceAllocations]
  );

  const resourceAllocationEntries = useMemo(
    () =>
      resourceAllocations.length < resourceRequest.quantity
        ? resourceAllocations.concat(
            new Array(
              resourceRequest.quantity - resourceAllocations.length
            ).fill(null)
          )
        : resourceAllocations,
    [resourceRequest.quantity, resourceAllocations]
  );

  return (
    <List disablePadding>
      {resourceAllocationEntries.map((resourceAllocationEntry, index) => (
        <RequestDrawerResourceAllocationItem
          key={resourceAllocationEntry ? resourceAllocationEntry.id : index}
          resourceRequest={resourceRequest}
          resourceAllocation={resourceAllocationEntry}
          dateRange={dateRange}
          resourceRequestTotalHours={resourceRequestTotalHours}
          resourceRequestTotalCost={resourceRequestTotalCost}
        />
      ))}
    </List>
  );
};

RequestDrawerResourceAllocationList.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocations: PropTypes.array,
  resourceRequestTotalHours: PropTypes.number,
  resourceRequestTotalCost: PropTypes.object
};

export default RequestDrawerResourceAllocationList;
