import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1.5)
  },
  allocated: {
    fontWeight: 'bold',
    margin: theme.spacing(0, 1, 0, 0)
  },

  input: {
    ...theme.typography.caption,
    padding: theme.spacing(0.5, 0),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  inputAdornment: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    margin: 0
  },
  atDivider: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1)
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline'
  },
  loading: {
    padding: 0,
    width: theme.spacing(6)
  },
  loadingInputAdjusted: {
    fontStyle: 'italic'
  },
  adjustedIndicator: {},
  totalHours: {
    padding: 0,
    width: theme.spacing(9)
  },

  tooltipDetails: {
    margin: theme.spacing(0.5, 0, 0, 0)
  },
  totalCost: {
    minWidth: 100,
    textAlign: 'center'
  }
}));

export default useStyles;
