import { useCallback, useState } from 'react';
import {
  mapToCreditMemosInput,
  mapToDeleteCreditMemosInput
} from './mapperUtil';

const creditMemoPanelResourceKeys = {
  title: 'billDetails.creditMemos',
  allocatedAmount: 'billDetails.allocatedAmount'
};

const creditMemoTableResourceKeys = ({ intl }) => ({
  noDataMessage: 'billDetails.noAssociatedCreditMemos',
  removeLabel: 'billDetails.removeCredit',
  header: 'billDetails.removeCredit',
  removeMessage: 'billDetails.removeCreditMessage',
  actionButton: 'billDetails.removeCredit',
  creditColumnSubHeader: 'billDetails.creditMemoCurrency',
  deleteCredit: intl.formatMessage({ id: 'billDetails.removeCredit' })
});

const creditMemoAssignButtonResourceKeys = {
  idColumn: 'billDetails.creditMemo',
  balanceColumn: 'billDetails.balance',
  addButton: 'billDetails.creditMemo'
};

export default ({
  intl,
  billId,
  billCurrencyId,
  associatedCreditMemos,
  billBalanceTotal,
  putCreditMemosForBill
}) => {
  const [
    isCreditMemoAssignmentInProgress,
    setCreditMemoAssignmentInProgress
  ] = useState(false);

  const handleDeleteCreditMemoClick = useCallback(
    async creditMemo => {
      setCreditMemoAssignmentInProgress(true);
      await putCreditMemosForBill(
        mapToDeleteCreditMemosInput({
          billId,
          associatedCreditMemos,
          selectedCreditMemo: creditMemo
        })
      );
      setCreditMemoAssignmentInProgress(false);
    },
    [associatedCreditMemos, billId, putCreditMemosForBill]
  );

  const handleCreditMemoClick = useCallback(
    async creditMemo => {
      setCreditMemoAssignmentInProgress(true);
      await putCreditMemosForBill(
        mapToCreditMemosInput({
          billId,
          billBalanceTotal,
          associatedCreditMemos,
          selectedCreditMemo: creditMemo
        })
      );
      setCreditMemoAssignmentInProgress(false);
    },
    [associatedCreditMemos, billBalanceTotal, billId, putCreditMemosForBill]
  );

  return {
    isCreditMemoAssignmentInProgress,
    setCreditMemoAssignmentInProgress,
    creditMemoPanelResourceKeys,
    creditMemoTableResourceKeys: creditMemoTableResourceKeys({ intl }),
    creditMemoAssignButtonResourceKeys,
    handleCreditMemoClick,
    handleDeleteCreditMemoClick,
    hasMutiCurrencyAssociationsForCreditMemo: associatedCreditMemos.some(
      creditMemo =>
        creditMemo.allocatedCreditMemoAmount.currency.id !== billCurrencyId
    )
  };
};
