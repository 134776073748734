import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';

const PROJECT_ACTUAL_SERIES_BY_PROJECT_ROLE_QUERY = gql`
  query getResourcePlanUserActualSeriesByRole(
    $projectId: String!
    $userUri: String!
    $dateRange: DateRangeInput!
    $periodResolution: PeriodResolutionOption!
  ) {
    resourceUsers2(page: 1, pagesize: 1, filter: { userIds: [$userUri] }) {
      id
      projectActualSeriesByProjectRole(
        projectUri: $projectId
        dateRange: $dateRange
        periodResolution: $periodResolution
      ) {
        role {
          id
          displayText
        }
        periods {
          hours {
            hours
            minutes
            seconds
          }
          dateRange {
            startDate: startDate2
            endDate: endDate2
          }
        }
      }
    }
  }
`;

export const useProjectActualSeriesByProjectRole = ({
  dateRange,
  scale,
  userUri,
  projectId,
  isShowActualEnabled
}) => {
  const { data, loading } = useQuery(
    PROJECT_ACTUAL_SERIES_BY_PROJECT_ROLE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        dateRange,
        userUri,
        projectId,
        periodResolution: mapScaleToPeriodResolution(scale)
      },
      skip: !isShowActualEnabled
    }
  );

  return {
    isLoading: loading,
    projectActualSeriesByProjectRole:
      data?.resourceUsers2?.[0]?.projectActualSeriesByProjectRole || []
  };
};

export default useProjectActualSeriesByProjectRole;
