import { useColumns } from '~/modules/common/components/ListTable';
import { editors } from './editors';
import { formatters } from './formatters';

export const buildColumns = ({
  parameters,
  classes,
  setFieldValue,
  editable,
  onDeleteRow,
  canDeleteRow,
  errors,
  projectUri,
  isMobile,
  alignDeleteRow,
  ...rest
}) => {
  const columnAlign = {
    integer: 'right',
    money: 'right',
    'read-only-amount': 'right',
    numeric: 'right',
    boolean: 'center'
  };
  const columns = parameters.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.id]: {
        projectUri,
        ...current,
        id: current.id,
        value: current.displayText,
        label: isMobile ? current.displayText : null,
        visible: current.visible,
        className: classes[current.type],
        align: columnAlign[current.type],
        setFieldValue,
        errors,
        isMobile,
        ...rest
      }
    }),
    {}
  );

  if (!editable) return columns;

  return {
    ...columns,
    deleteRow: {
      id: 'deleteRow',
      visible: canDeleteRow,
      value: '',
      align: alignDeleteRow || 'right',
      className: classes.delete,
      onDeleteRow
    }
  };
};
export const useListColumns = ({
  parameters,
  classes,
  setFieldValue,
  customEditors,
  customFormatters,
  editable,
  onDeleteRow,
  canDeleteRow,
  errors,
  alignDeleteRow,
  ...rest
}) =>
  useColumns({
    columns: buildColumns({
      parameters,
      classes,
      setFieldValue,
      editable,
      onDeleteRow,
      errors,
      canDeleteRow,
      alignDeleteRow,
      ...rest
    }),
    renders: editable
      ? editors({ parameters, customEditors })
      : formatters({ parameters, customFormatters })
  });
