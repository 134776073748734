import { sortBy, sortedUniqBy, uniqBy } from 'lodash';

export const isValidValue = value =>
  value &&
  typeof value === 'object' &&
  Object.prototype.hasOwnProperty.call(value, 'id') &&
  Object.prototype.hasOwnProperty.call(value, 'displayText') &&
  value.id !== null &&
  value.id !== undefined &&
  value.id.length > 0 &&
  value.displayText !== null &&
  value.displayText !== undefined &&
  value.displayText.length > 0;

export const useOptionsMerge = ({ options, disableSort, value }) => {
  const mergedOptions = isValidValue(value) ? options.concat(value) : options;
  const processedOptions = disableSort
    ? uniqBy(mergedOptions, option => option.displayText)
    : sortedUniqBy(
        sortBy(mergedOptions, option => option.displayText),
        option => option.displayText
      );

  return processedOptions;
};
