import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me';
import {
  mapToGetPageOfProjectRatesVariables,
  GET_PAGE_OF_PROJECT_RATES
} from './usePageOfProjectRate';

export const UPDATE_PROJECT_RATES = gql`
  mutation updateProjectRates(
    $projectId: String!
    $dimensionUris: [String!]
    $requiredDimensionUris: [String!]
    $rates: [ProjectRateInput3!]
  ) {
    updateProjectRates: updateProjectRates2(
      projectId: $projectId
      dimensionUris: $dimensionUris
      requiredDimensionUris: $requiredDimensionUris
      rates: $rates
    ) {
      id
    }
  }
`;

export const useUpdateProjectRatesForDynamicRateTable = () => {
  const me = useMeContext();
  const [updateProjectRatesForDynamicRateTable] = useMutation(
    UPDATE_PROJECT_RATES
  );

  return {
    updateProjectRatesForDynamicRateTable: ({
      projectId,
      rates,
      projectRateDimensionUris,
      projectRateRequiredDimensionUris
    }) => {
      return updateProjectRatesForDynamicRateTable({
        variables: {
          projectId,
          rates,
          dimensionUris: projectRateDimensionUris,
          requiredDimensionUris: projectRateRequiredDimensionUris
        },
        refetchQueries: [
          {
            query: GET_PAGE_OF_PROJECT_RATES,
            variables: mapToGetPageOfProjectRatesVariables(
              projectId,
              projectRateDimensionUris,
              me
            )
          }
        ],
        awaitRefetchQueries: true
      });
    }
  };
};

export default useUpdateProjectRatesForDynamicRateTable;
