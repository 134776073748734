import { useHasPermission } from '~/modules/common/permissions';

export const usePortfolioPermissions = () => {
  return {
    canEditPortfolio: useHasPermission({
      actionUri: 'urn:replicon:portfolio-action:edit-portfolio'
    }),
    canViewPortfolio: useHasPermission({
      actionUri: 'urn:replicon:portfolio-action:view-portfolio'
    }),
    canEditAllPortfolios: useHasPermission({
      actionUri: 'urn:replicon:portfolio-action:edit-portfolio',
      dataAccessLevelUri: 'urn:replicon:portfolio-data-access-level:all'
    }),
    canViewAllPortfolios: useHasPermission({
      actionUri: 'urn:replicon:portfolio-action:view-portfolio',
      dataAccessLevelUri: 'urn:replicon:portfolio-data-access-level:all'
    }),
    canViewCostData: useHasPermission({
      actionUri: 'urn:replicon:portfolio-action:view-cost-data'
    })
  };
};
