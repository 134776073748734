import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import Decimal from '~/modules/common/components/Decimal';
import { TASK_STATUS } from '~/modules/common/enums';
import { isHoursDefined } from '~/modules/projects/tasks/components/Gantt/components/util';
import { TaskCardMissingEstimate } from './TaskCardMissingEstimate';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  subHeading: {
    fontSize: theme.spacing(1.25),
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.38)'
  },
  hoursContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(0.5)
  },
  hours: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.75),
    fontWeight: 600
  },
  label: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.5)
  }
}));

const TaskCardHours = ({ task, isProject = false }) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    taskStatus,
    rolledUpSummary: { actualHours, totalEstimatedAtCompletionHours } = {}
  } = task;
  const id =
    taskStatus === TASK_STATUS.NOTSTARTED
      ? isProject
        ? 'projectTasksPage.estimatedAtTaskCompletion'
        : 'projectTasksPage.estimatedAtCompletion'
      : taskStatus === TASK_STATUS.INPROGRESS
      ? isProject
        ? 'projectTasksPage.actualHoursEstimatedAtTaskCompletion'
        : 'projectTasksPage.actualHoursEstimatedAtCompletion'
      : 'projectTasksPage.actualHours';
  const hrsLabel = intl.formatMessage({
    id: 'projectTasksPage.hrs'
  });

  const hours =
    taskStatus === TASK_STATUS.NOTSTARTED
      ? totalEstimatedAtCompletionHours
      : actualHours;

  return (
    <div className={classes.container}>
      <Typography className={classes.subHeading} variant="body2">
        <FormattedMessage id={id} />
      </Typography>
      <div className={classes.hoursContainer}>
        {isHoursDefined(hours) ? (
          <>
            <Decimal
              className={classes.hours}
              value={hours}
              fixedDecimalScale={false}
            />
            <span className={classes.label}>{hrsLabel}</span>
          </>
        ) : (
          <TaskCardMissingEstimate />
        )}
        {taskStatus === TASK_STATUS.INPROGRESS && (
          <>
            <span className={classes.label}>/</span>
            {isHoursDefined(totalEstimatedAtCompletionHours) ? (
              <>
                <Decimal
                  className={classes.hours}
                  value={totalEstimatedAtCompletionHours}
                  fixedDecimalScale={false}
                />
                <span className={classes.label}>{hrsLabel}</span>
              </>
            ) : (
              <TaskCardMissingEstimate />
            )}
          </>
        )}
      </div>
    </div>
  );
};

TaskCardHours.propTypes = {
  task: PropTypes.object.isRequired,
  isProject: PropTypes.bool
};

export default TaskCardHours;
