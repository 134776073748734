import React from 'react';
import PropTypes from 'prop-types';
import { primaryRoles, additionalRoles } from './withRoles';
import RolesScheduleForm from './RolesScheduleForm';

const defaultAddValue = {
  effectiveDate: null,
  projectRoles: [
    {
      role: null,
      isPrimary: true
    },
    {
      role: null,
      isPrimary: false
    }
  ]
};

export const RoleSchedulesDialogContent = ({
  onCancel,
  onSubmit,
  userDetails: { permittedActions, roleScheduleDetails },
  editable
}) => {
  const scheduleDetails = roleScheduleDetails.some(
    schedule => !schedule.effectiveDate
  )
    ? [...roleScheduleDetails]
    : [{ effectiveDate: null, projectRoles: [] }, ...roleScheduleDetails];

  const schedules = scheduleDetails.map(schedule => ({
    ...schedule,
    projectRoles: primaryRoles(schedule.projectRoles).concat(
      additionalRoles(schedule.projectRoles)
    )
  }));

  return (
    <RolesScheduleForm
      fieldValues={schedules}
      onCancel={onCancel}
      onSubmit={onSubmit}
      fieldArrayName="schedule"
      dropdownFieldKey="role"
      permittedActions={permittedActions}
      defaultAddValue={defaultAddValue}
      editable={editable}
    />
  );
};

RoleSchedulesDialogContent.propTypes = {
  userDetails: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired
};

export default RoleSchedulesDialogContent;
