import React, { useMemo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FullPath } from '../FullPath';

const useStyles = makeStyles(() => ({
  fullpath: {}
}));
const PortfolioFullPath = ({ portfolio, classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });
  const fullpathClasses = useMemo(
    () => ({
      root: classes.fullpath
    }),
    [classes]
  );

  return (
    <>
      {Boolean(portfolio.ancestors?.length) && (
        <FullPath
          fullPath={portfolio.ancestors.map(ancestor => ancestor.displayText)}
          classes={fullpathClasses}
        />
      )}
      <Typography variant="body2">{portfolio.displayText}</Typography>
    </>
  );
};

PortfolioFullPath.propTypes = {
  portfolio: PropTypes.object.isRequired,
  classes: PropTypes.objectOf(
    PropTypes.shape({
      fullPath: PropTypes.string
    })
  )
};

export default PortfolioFullPath;
