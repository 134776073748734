import React, { useState, useMemo, useCallback } from 'react';
import { useSearchableSkills } from '~/modules/common/hooks/useEnabledSkills';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const matchOption = fetchOptions => async (_, term) => {
  if (!term) {
    return null;
  }

  const enabledSkills = await fetchOptions(cleanSearchTerm(term));

  const skill = enabledSkills.find(
    ({ key, displayText = '' }) =>
      displayText.toLowerCase() === term.toLowerCase() ||
      key.toLowerCase() === term.toLowerCase()
  );

  return skill || null;
};

export const mapOptions = skills =>
  skills.map(skill => ({
    ...skill,
    key: skill.uri,
    value: skill.uri,
    options: skill.options.map(opt => ({
      ...opt,
      value: opt.id,
      label: opt.displayText,
      key: opt.displayText
    }))
  }));

const withCriteriaProvider = (tag, context) => BaseComponent => ({
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);

  const { fetchOptions } = useSearchableSkills();

  const $fetchOptions = useCallback(
    async searchTerm => mapOptions(await fetchOptions(searchTerm)),
    [fetchOptions]
  );

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption($fetchOptions),
      selected,
      setSelected,
      fetchOptions: $fetchOptions,
      suggestions: $fetchOptions
    }),
    [$fetchOptions, selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
