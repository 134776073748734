import React, { useCallback } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { ListItem, Checkbox, makeStyles } from '@material-ui/core';
import LongPressable from 'react-longpressable';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  rootSelections: {
    '& $details': {
      paddingLeft: theme.spacing(5)
    },
    '& $selection': {
      width: theme.spacing(5.25)
    }
  },
  rootSelected: {
    '& $item': {
      backgroundColor: theme.palette.grey[100]
    },
    '& $details': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  listItem: {
    padding: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper
  },
  item: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'block',
    padding: 0,
    color: theme.palette.text.primary,
    textAlign: 'left',
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      duration: theme.transitions.duration.short
    })
  },
  details: {
    width: '100%',
    padding: theme.spacing(1, 2),
    background: theme.palette.grey[50],
    transition: theme.transitions.create(['background-color', 'padding'], {
      duration: theme.transitions.duration.short
    })
  },
  selection: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    zIndex: 1,
    width: 0,
    overflow: 'hidden',
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.short
    })
  },
  checkbox: {}
}));

export const MobileListItem = ({
  classes: overridenClasses,
  record,
  onClick,
  children,
  isSelected,
  selectionMode,
  divider = false,
  onRowSelectionChange
}) => {
  const classes = useStyles({ classes: overridenClasses });
  const handleSelection = useCallback(() => {
    onRowSelectionChange && onRowSelectionChange(record.id, !isSelected);
  }, [record.id, isSelected, onRowSelectionChange]);

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      if (selectionMode) {
        handleSelection();
      } else {
        onClick && onClick();
      }
    },
    [selectionMode, handleSelection, onClick]
  );

  return (
    <>
      <LongPressable onShortPress={handleClick} onLongPress={handleSelection}>
        <ListItem
          className={classNames(classes.listItem, {
            [classes.rootSelections]: selectionMode,
            [classes.rootSelected]: isSelected
          })}
          divider={divider}
        >
          {!!onRowSelectionChange && (
            <div className={classes.selection}>
              <Checkbox checked={isSelected} className={classes.checkbox} />
            </div>
          )}
          <div className={classes.details}>{children}</div>
        </ListItem>
      </LongPressable>
    </>
  );
};

MobileListItem.propTypes = {
  classes: PropTypes.object,
  divider: PropTypes.bool,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  selectionMode: PropTypes.bool,
  children: PropTypes.node.isRequired,
  record: PropTypes.object.isRequired,
  onRowSelectionChange: PropTypes.func
};

export default MobileListItem;
