import React from 'react';
import { retryLoading } from '~/util';
import { QuickAllocationIcon } from '~/modules/common/components/Icons';

const QuickAllocation = React.lazy(() =>
  retryLoading(() => import('./QuickAllocation'))
);

const route = ({ intl, me: { permissions } }) => ({
  path: '/quick-allocation',
  icon: QuickAllocationIcon,
  isSideBarRoute: false,
  component: QuickAllocation,
  hidden: true,
  title: () => intl.formatMessage({ id: 'routes.quickAllocation' }),
  showDivider: true,
  hasPermission: Boolean(
    permissions.find(
      p =>
        (p.permissionActionUri === 'urn:replicon:user-action:view-user' &&
          p.dataAccessLevelUris.includes(
            'urn:replicon:user-data-access-level:resource-manager'
          )) ||
        (p.permissionActionUri === 'urn:replicon:user-action:view-user' &&
          p.dataAccessLevelUris.includes(
            'urn:replicon:user-data-access-level:resource-pool-manager'
          ))
    )
  )
});

export default route;
