import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';
import { billingScheduleEntries } from './query';

export const GET_PROJECT_BILLINGRATE_SCHEDULES = gql`
  query getPageOfProjectBillingRateSchedules(
    $projectId: String!
    $page: Int!
    $pageSize: Int!
  ) {
    project(projectId: $projectId) {
      id
      pageOfProjectBillingRateSchedules(page: $page, pageSize: $pageSize) {
        id
        billingRate {
          id
          displayText
          ${billingScheduleEntries}
        }
      }
    }
  }
`;
const DEFAULT_PAGE_SIZE = 10;

export const updateQuery = (
  previousResult,
  { fetchMoreResult: { project: nextResult } }
) => {
  const prevResult =
    previousResult?.project.pageOfProjectBillingRateSchedules || [];
  const nextResults = nextResult.pageOfProjectBillingRateSchedules || [];

  return {
    project: {
      ...previousResult.project,
      pageOfProjectBillingRateSchedules: [...prevResult, ...nextResults]
    }
  };
};

const useProjectBillingRateSchedules = ({
  projectId,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { loading, data, fetchMore, variables } = useQuery(
    GET_PROJECT_BILLINGRATE_SCHEDULES,
    {
      variables: {
        page: 1,
        pageSize,
        projectId
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const pageOfProjectBillingRateSchedules = get(
    data,
    'project.pageOfProjectBillingRateSchedules',
    []
  );
  const hasMoreRows =
    pageOfProjectBillingRateSchedules &&
    pageOfProjectBillingRateSchedules.length !== 0 &&
    pageOfProjectBillingRateSchedules.length % variables.pageSize === 0;

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows || isLoadingMore) {
      return;
    }
    setIsLoadingMore(true);
    try {
      await fetchMore({
        query: GET_PROJECT_BILLINGRATE_SCHEDULES,
        variables: {
          ...variables,
          page:
            pageOfProjectBillingRateSchedules.length / variables.pageSize + 1
        },
        updateQuery
      });
    } finally {
      setIsLoadingMore(false);
    }
  }, [
    hasMoreRows,
    isLoadingMore,
    fetchMore,
    variables,
    pageOfProjectBillingRateSchedules
  ]);

  return {
    loading,
    hasMoreRows,
    isLoadingMore,
    loadMoreRows,
    projectBillingRateSchedules: pageOfProjectBillingRateSchedules
  };
};

export default useProjectBillingRateSchedules;
