import en from './en';
import sw from './sw';

const locales = {
  en,
  sw
};

const mergeFallbacks = messages => ({
  ...en,
  ...messages
});

const replaceTerms = (customTerms, messages) =>
  Object.keys(messages).reduce(
    (acc, messageKey) => ({
      ...acc,
      [messageKey]: customTerms.reduce(
        (m, term) =>
          m.replace(
            new RegExp(`(^|\\W)${term.originalTerm}($|\\W)`, 'gi'),
            `$1${term.customTerm}$2`
          ),
        messages[messageKey]
      )
    }),
    {}
  );

const buildMessages = (locale, customTerms = []) => {
  const lcLocale = locale.toLowerCase();
  const messages =
    locales[lcLocale] ||
    locales[Object.keys(locales).find(k => lcLocale.startsWith(k))] ||
    en;

  const messagesWithFallbacks = mergeFallbacks(messages);

  if (customTerms.length) {
    return replaceTerms(customTerms, messagesWithFallbacks);
  }

  return messagesWithFallbacks;
};

export default buildMessages;
