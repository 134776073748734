import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  skillRemoveIcon: {
    alignSelf: 'center'
  },
  certificate: {
    display: 'flex'
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '80%',
    paddingTop: theme.spacing(0.25)
  },
  certificateIcon: {
    color: theme.palette.text.secondary,
    marginBottom: -10
  },
  cell: {
    borderBottom: 'none',
    textAlign: 'center',
    padding: theme.spacing(0.5)
  },
  skillNameCell: {
    borderBottom: 'none',
    padding: theme.spacing(0.5),
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1
  },
  removeIconCell: {
    borderBottom: 'none',
    textAlign: 'right',
    padding: theme.spacing(0.5),
    '&:last-child': {
      paddingRight: '5px'
    },
    position: 'sticky',
    right: 0,
    backgroundColor: theme.palette.background.paper
  },
  skillName: {
    paddingLeft: theme.spacing(3)
  },
  iconButton: {
    padding: 0
  },
  button: {
    padding: 0
  },
  metadataCell: {
    borderBottom: 'none',
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1)
  },
  resourceRequestSkillNameCell: {
    borderBottom: 'none',
    padding: theme.spacing(0.5),
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    width: '70%'
  }
}));

export default useStyles;
