import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogTitle,
  makeStyles,
  Typography
} from '@material-ui/core';
import { LinearQueryProgress } from '~/modules/common';

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(0, 0, 3, 0),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  titleText: {
    padding: theme.spacing(0),
    ...theme.typography.h6
  },
  loaderText: {
    fontSize: theme.typography.body2.fontSize,
    paddingTop: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const ExportLoaderDialog = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          <FormattedMessage id="importExportMenu.exportDialog.csvExport" />
        </Typography>
      </DialogTitle>
      <LinearQueryProgress />
      <Typography className={classes.loaderText}>
        <FormattedMessage id="importExportMenu.exportDialog.loadingMessage" />
      </Typography>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
      </DialogActions>
    </>
  );
};

ExportLoaderDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ExportLoaderDialog;
