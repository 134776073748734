import { gql } from 'graphql-tag';
import {
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment,
  specificResourceAllocationTimeOffFragment,
  specificResourceAllocationHolidaysFragment,
  allocationTotalsFragment
} from '~/modules/resourcing/common/fragments';

const ALLOCATIONS_QUERY = gql`
  query Eager_GetAllocations(
    $projectUri: String
    $allocationStatusList: [ResourceAllocationStatus]
    $limit: Int
    $cursor: String
    $sort: ResourceAllocationSort
    $showTimeOff: Boolean!
    $chartDateRange: DateRangeInput
    $showHolidays: Boolean!
    $filter: ResourceAllocationFilter
  ) {
    resourceAllocations(
      projectUri: $projectUri
      limit: $limit
      cursor: $cursor
      allocationStatusList: $allocationStatusList
      sort: $sort
      filter: $filter
    ) {
      resourceAllocations {
        ...SpecificResourceAllocation
        role {
          uri
          id
          displayText
        }
        user {
          ...SpecificResourceAllocationUser
          ...SpecificResourceAllocationTimeOff
          ...SpecificResourceAllocationHolidays
          scheduleDurationByDay(dateRange: $chartDateRange) {
            date
            hours
          }
        }
        ...AllocationTotalsFragment
      }
      nextPageCursor
    }
  }
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
  ${specificResourceAllocationTimeOffFragment}
  ${specificResourceAllocationHolidaysFragment}
  ${allocationTotalsFragment}
`;

export default ALLOCATIONS_QUERY;
