import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { getTransitionUrlByRecordTypeFromPath } from '~/modules/billing-invoicing/common/util';
import { useListColumns, useListFooters, useListHeaders } from './columns';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    width: '100%',
    '& th:first-child': {
      left: 0,
      zIndex: 1,
      position: 'sticky'
    },
    '& td:first-child': {
      zIndex: 1
    }
  },
  tableHeaderCell: {
    padding: ({ editable }) =>
      editable ? theme.spacing(1, 2) : theme.spacing(1, 2, 1, 1),
    verticalAlign: 'bottom',
    height: 40
  },
  tableCell: {
    cursor: 'pointer',
    height: 'unset',
    padding: ({ editable }) =>
      editable ? theme.spacing(0.5, 2) : theme.spacing(1, 2, 1, 1)
  },
  tableFooterCell: {
    height: 20,
    fontWeight: theme.typography.fontWeightRegular
  },
  tableFooterRow: {
    backgroundColor: theme.palette.grey[50]
  }
}));

const useColumnStyles = makeStyles(theme => ({
  deleteIcon: {
    width: theme.spacing(1) + 2,
    padding: 0
  },
  displayText: {
    width: '200px',
    overflowWrap: 'break-word'
  },
  date: {
    borderRight: `1px solid ${theme.palette.grey[100]}`
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.table.border}`
  },
  creditAmount: {
    width: '25%',
    paddingRight: 15
  },
  amount: {
    width: '25%',
    paddingRight: 15
  },
  totalDisplayText: {
    borderRight: `1px solid ${theme.palette.grey[200]}`
  },
  subTitle: {
    lineHeight: 1,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const useCardStyles = makeStyles({
  card: {
    width: '100%',
    boxShadow: 'none',
    overflowX: 'auto'
  }
});
const useNoDataStyles = makeStyles(theme => ({
  noData: {
    padding: theme.spacing(0, 0, 0, 1)
  }
}));

export const AssociatedBillCreditsTable = ({
  history,
  itemType,
  associatedItems,
  allocatedAmountTotal,
  resourceKeys,
  dateColumnKey,
  creditColumnKey,
  isMultiCurrency,
  onDelete,
  editable = true,
  hasMore,
  loadMore
}) => {
  const providerClasses = useListTableThemeProviderStyles({ editable });
  const columnClasses = useColumnStyles();
  const cardClasses = useCardStyles();
  const noDataClasses = useNoDataStyles();
  const columns = useListColumns({
    classes: columnClasses,
    dateColumnKey,
    creditColumnKey,
    isMultiCurrency,
    resourceKeys,
    onDelete,
    editable
  });

  const headers = useListHeaders({ classes: columnClasses, columns });

  const footers = useListFooters({
    classes: columnClasses,
    isMultiCurrency,
    editable
  });
  const totals = useMemo(() => ({ allocatedAmountTotal }), [
    allocatedAmountTotal
  ]);

  const onRowClickHandler = useCallback(
    record => {
      const transitionUrl = getTransitionUrlByRecordTypeFromPath({
        record: { type: itemType, ...record },
        path: history.location.pathname
      });

      if (transitionUrl) {
        history.push(transitionUrl);
      }
    },
    [history, itemType]
  );

  const onCellClickHandler = useCallback((event, field) => {
    if (field === 'deleteIcon') {
      event.stopPropagation();
    }
  }, []);

  return associatedItems.length === 0 ? (
    <div className={noDataClasses.noData}>
      {resourceKeys.noDataMessage && (
        <NoDataItem>
          <FormattedMessage id={resourceKeys.noDataMessage} />
        </NoDataItem>
      )}
    </div>
  ) : (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTable
        hover
        variant="table"
        headers={headers}
        classes={cardClasses}
        columns={columns}
        footers={footers}
        totals={totals}
        records={associatedItems}
        onRowClick={onRowClickHandler}
        onCellClick={onCellClickHandler}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </ListTableThemeProvider>
  );
};

AssociatedBillCreditsTable.propTypes = {
  history: PropTypes.object.isRequired,
  itemType: PropTypes.string.isRequired,
  associatedItems: PropTypes.array.isRequired,
  allocatedAmountTotal: PropTypes.object.isRequired,
  resourceKeys: PropTypes.object.isRequired,
  dateColumnKey: PropTypes.string.isRequired,
  creditColumnKey: PropTypes.string.isRequired,
  isMultiCurrency: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func
};

export default AssociatedBillCreditsTable;
