/* eslint-disable react/jsx-max-depth */
import { Divider } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from '~/modules/common/components/Drawer';
import {
  USER_ACCESS_ROLE,
  RESOURCING_ALLOCATION_MODEL
} from '~/modules/common/enums';
import {
  useEditableState,
  useIsBreakpointDown,
  useGetKeyValueSettings
} from '~/modules/common/hooks';
import { SavingProgress } from '~/modules/common/components';
import { checkIfResourceRequestIsReadOnly } from '~/modules/resourcing/common/util';
import { getShowResourceAllocation } from '~/modules/projects/resourcing-plan/enhancers/withShowResourceAllocation';
import { getShowAllocationTotalForResourceRequest } from '~/modules/resourcing/common/enhancers/withShowAllocationTotalForResourceRequest';
import {
  ResourceRequestDrawerTitle,
  ResourceRequestDrawerAllocationDetails,
  ResourceRequestDrawerDetails,
  ResourceRequestDrawerReadonlyDetails,
  EditButton
} from '~/modules/resourcing/common/components/ResourceRequestDrawer';
import { useHasUserAccessRole } from '~/modules/common/permissions/useHasUserAccessRole';
import useUpdateResourceRequestHandlers from '~/modules/projects/resourcing-plan/hooks/useUpdateResourceRequestHandlers';
import {
  ProjectContextProvider,
  useProjectContext
} from '~/modules/resourcing/common/contexts';
import { useMergeDialogState } from '~/modules/resourcing/common/hooks';
import { RequestActionButton } from '~/modules/resourcing/common/components';
import { ResourceRequestDrawerActions } from '../../ResourceRequestDrawerActions';
import { useResourceRequestDrawerForm } from '../hooks';
import { getResourceRequestFromState } from '../hooks/useResourceRequestDrawerForm';
import useStyles, {
  useFooterStyles,
  useDrawerStyles,
  combinedRequestFooterStyles
} from './useStyles';

export const ResourceRequestDrawerContentInternal = ({
  resourceRequest,
  onClose,
  resourceAllocations,
  project,
  defaultRequestAttributeWeights,
  maxSkillLevel,
  allocationChartRef,
  isProjectEditEnabled,
  isProjectManagerView,
  isAssignmentDialog
}) => {
  const [savingResourceRequest, setResourceRequestSaving] = useState(false);
  const { keyValueSettings: chartSettings } = useGetKeyValueSettings(
    'resource_plan_chart_settings'
  );
  const { formatMessage } = useIntl();
  const isResourceActualModeEnabled = chartSettings?.showActuals || false;

  const { onResourceRequestSave } = useUpdateResourceRequestHandlers({
    resourceRequest,
    defaultScheduleRule: project.defaultScheduleRule,
    isResourceActualModeEnabled
  });

  const {
    project: { permittedActionUris }
  } = resourceRequest;

  const canEditProject = permittedActionUris.includes(
    'urn:replicon:project-action:edit-project'
  );
  const canEditTask = (permittedActionUris || []).includes(
    'urn:replicon:project-action:edit-tasks'
  );
  const canEditTeam = (permittedActionUris || []).includes(
    'urn:replicon:project-action:edit-team'
  );

  const isResourceRequestReadOnly = checkIfResourceRequestIsReadOnly(
    resourceRequest
  );

  const {
    editable: isEditable,
    toggleEditable,
    setEditable
  } = useEditableState(
    canEditProject && isProjectEditEnabled && !isResourceRequestReadOnly
  );

  useEffect(() => {
    setEditable(isProjectEditEnabled && !isResourceRequestReadOnly);
  }, [isResourceRequestReadOnly, isProjectEditEnabled, setEditable]);

  const footerClasses = useFooterStyles();
  const classes = useStyles();
  const combinedRequestFooterClasses = combinedRequestFooterStyles();
  const drawerHeaderClasses = useDrawerStyles();

  const {
    showAllocationTotalForResourceRequest
  } = getShowAllocationTotalForResourceRequest({ resourceRequest });

  const mergeDialogState = useMergeDialogState();
  const { resourcingAllocationModel } = useProjectContext();

  const { form } = useResourceRequestDrawerForm({
    onClose,
    project,
    resourceRequest,
    defaultRequestAttributeWeights,
    setEditButtonClicked: toggleEditable,
    setResourceRequestSaving,
    onResourceRequestSave
  });

  const { submitForm } = form;

  const { resourceRequestFromState } = getResourceRequestFromState({
    form,
    project
  });
  const { showResourceAllocation } = getShowResourceAllocation({
    resourceRequest
  });

  const canResourceManagerPerformDrawerActions =
    useHasUserAccessRole({
      roleUri: USER_ACCESS_ROLE.RESOURCE_MANAGER
    }) && !isProjectManagerView;

  const request = useMemo(
    () => ({
      ...resourceRequest,
      ...resourceRequestFromState,
      requestStatus: resourceRequest.requestStatus
    }),
    [resourceRequestFromState, resourceRequest]
  );

  const hasError = form.hasError && form.isChanged;

  const showResourceAllocationDetails = !savingResourceRequest && !isEditable;
  const resourceAllocationData =
    !isProjectManagerView || showResourceAllocation ? resourceAllocations : [];

  const canSeeDrawerActions =
    canEditProject || canResourceManagerPerformDrawerActions;

  const isMobile = useIsBreakpointDown('sm');

  const showEditButton =
    !isResourceRequestReadOnly &&
    !isEditable &&
    canSeeDrawerActions &&
    canEditProject;

  const editButton = useMemo(
    () =>
      showEditButton ? <EditButton toggleEditable={toggleEditable} /> : null,
    [showEditButton, toggleEditable]
  );

  const showDrawerActions = !savingResourceRequest && canSeeDrawerActions;
  const isCombinedResourceRequest =
    resourceRequest?.mergedResourceRequests?.length > 1;

  return (
    <>
      <div>
        <DrawerHeader
          classes={drawerHeaderClasses}
          onClose={onClose}
          extraButtons={editButton}
        >
          <ResourceRequestDrawerTitle
            resourceRequest={request}
            project={project}
          />
        </DrawerHeader>
      </div>
      <Divider />
      <DrawerContent className={classes.contentArea}>
        <>
          {!isEditable && !savingResourceRequest && (
            <ResourceRequestDrawerReadonlyDetails
              resourceAllocations={resourceAllocationData}
              resourceRequest={resourceRequest}
              drawerOnClose={onClose}
              maxSkillLevel={maxSkillLevel}
              project={project}
            />
          )}
          {savingResourceRequest ? <SavingProgress /> : null}
          {!savingResourceRequest && isEditable && (
            <ResourceRequestDrawerDetails
              form={form}
              resourceRequest={resourceRequestFromState}
              drawerOnClose={onClose}
              project={project}
              isMobile={isMobile}
            />
          )}
          {showResourceAllocationDetails && (
            <ResourceRequestDrawerAllocationDetails
              project={project}
              resourceRequest={resourceRequest}
              resourceAllocations={resourceAllocationData}
              showRequestAllocations={
                !isProjectManagerView || showResourceAllocation
              }
              showRequestAllocationTotals={
                !isProjectManagerView || showAllocationTotalForResourceRequest
              }
            />
          )}
        </>
        <DrawerFooter
          className={classNames(footerClasses.footer, {
            [combinedRequestFooterClasses.footer]: isCombinedResourceRequest
          })}
        >
          {showDrawerActions &&
            (isCombinedResourceRequest ? (
              <RequestActionButton
                label={formatMessage({
                  id: 'resourceRequestActions.ok'
                })}
                onClick={onClose}
              />
            ) : (
              <ResourceRequestDrawerActions
                hasError={hasError}
                canEditProject={canEditProject}
                isResourceRequestReadOnly={isResourceRequestReadOnly}
                drawerOnClose={onClose}
                resourceRequest={resourceRequest}
                resourceAllocations={resourceAllocations}
                onSaveClick={submitForm}
                setResourceRequestSaving={setResourceRequestSaving}
                isProjectManagerView={isProjectManagerView}
                isAssignmentDialog={isAssignmentDialog}
                isProjectResourcingView={!isProjectManagerView}
                canEditResourceRequest={isEditable}
                canEditTask={canEditTask}
                canEditTeam={canEditTeam}
                allocationChartRef={allocationChartRef}
                isResourceRequestMergeEnabled={
                  resourcingAllocationModel !==
                  RESOURCING_ALLOCATION_MODEL.MODEL_0
                }
                mergeDialogState={mergeDialogState}
              />
            ))}
        </DrawerFooter>
      </DrawerContent>
    </>
  );
};

export const ResourceRequestDrawerContent = ({
  resourceRequest,
  onClose,
  resourceAllocations,
  project,
  defaultRequestAttributeWeights,
  maxSkillLevel
}) => {
  const { pathname, state: { isProjectEditEnabled } = {} } = useLocation();
  const isProjectManagerView = pathname.includes('resourcingplan');
  const isAssignmentDialog = pathname.includes('resourcing/assignment');

  return (
    <ProjectContextProvider project={project}>
      <ResourceRequestDrawerContentInternal
        resourceRequest={resourceRequest}
        onClose={onClose}
        resourceAllocations={resourceAllocations}
        project={project}
        defaultRequestAttributeWeights={defaultRequestAttributeWeights}
        maxSkillLevel={maxSkillLevel}
        isProjectEditEnabled={isProjectEditEnabled}
        isProjectManagerView={isProjectManagerView}
        isAssignmentDialog={isAssignmentDialog}
      />
    </ProjectContextProvider>
  );
};

export const ResourceRequestDrawerContent2 = ({
  resourceRequest,
  onClose,
  resourceAllocations,
  project,
  defaultRequestAttributeWeights,
  maxSkillLevel,
  isEditSwitchEnabled,
  allocationChartRef
}) => {
  return (
    <ResourceRequestDrawerContentInternal
      resourceRequest={resourceRequest}
      onClose={onClose}
      resourceAllocations={resourceAllocations}
      project={project}
      defaultRequestAttributeWeights={defaultRequestAttributeWeights}
      maxSkillLevel={maxSkillLevel}
      isProjectEditEnabled={isEditSwitchEnabled}
      allocationChartRef={allocationChartRef}
      isProjectManagerView
    />
  );
};

ResourceRequestDrawerContentInternal.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  resourceAllocations: PropTypes.array,
  project: PropTypes.object.isRequired,
  defaultRequestAttributeWeights: PropTypes.object,
  maxSkillLevel: PropTypes.number,
  allocationChartRef: PropTypes.object,
  isProjectEditEnabled: PropTypes.bool,
  isProjectManagerView: PropTypes.bool,
  isAssignmentDialog: PropTypes.bool
};

ResourceRequestDrawerContent.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  resourceAllocations: PropTypes.array,
  project: PropTypes.object.isRequired,
  defaultRequestAttributeWeights: PropTypes.object,
  maxSkillLevel: PropTypes.number
};

ResourceRequestDrawerContent2.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  resourceAllocations: PropTypes.array,
  project: PropTypes.object.isRequired,
  defaultRequestAttributeWeights: PropTypes.object,
  maxSkillLevel: PropTypes.number,
  isEditSwitchEnabled: PropTypes.bool,
  allocationChartRef: PropTypes.object
};

export default ResourceRequestDrawerContent;
