import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useAvailableBillSummary } from '~/modules/billing-invoicing/common/hooks';
import { MoneyValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  card: {
    color: theme.palette.text.secondary,
    'font-size': theme.typography.caption.fontSize,
    backgroundColor: theme.palette.common.highlight,
    boxShadow: 'none',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block'
    }
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const AvailableBillSummary = ({ client, billCurrency }) => {
  const classes = useStyles();

  const {
    availableBillingItemSummary: { totalAmount, projectCount } = {}
  } = useAvailableBillSummary({
    client,
    billCurrency
  });

  const totalAvailableToBillAmount = useMemo(() => {
    return {
      amount: totalAmount ? (
        <>
          <MoneyValue money={totalAmount} className={classes.bold} />
          &nbsp;
        </>
      ) : null,
      projectCount: (
        <span className={classes.bold}>&nbsp;{projectCount}&nbsp;</span>
      )
    };
  }, [classes.bold, projectCount, totalAmount]);

  return (
    <>
      {totalAmount && totalAmount.amount > 0 && (
        <div className={classes.card}>
          <FormattedMessage
            id="billDetails.availableBillSummaryMessage.message"
            values={totalAvailableToBillAmount}
          />
        </div>
      )}
    </>
  );
};

AvailableBillSummary.propTypes = {
  client: PropTypes.object.isRequired,
  billCurrency: PropTypes.object
};

export default AvailableBillSummary;
