import { PeriodResolutionOption } from '~/types';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';

export const mapScaleToPeriodResolution = scale =>
  scale === PERIOD_SCALE_ENUM.DAYS
    ? PeriodResolutionOption.Daily
    : scale === PERIOD_SCALE_ENUM.MONTHS
    ? PeriodResolutionOption.Monthly
    : scale === PERIOD_SCALE_ENUM.QUARTERS
    ? PeriodResolutionOption.Quarterly
    : scale === PERIOD_SCALE_ENUM.WEEKS
    ? PeriodResolutionOption.Weekly
    : PeriodResolutionOption.Yearly;
