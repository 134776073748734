import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PlaceholderIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="m12 4v2c.37 0 .71108.094656 1.0039.25977l.99609-1.7246c-.58845-.34041-1.2709-.53516-2-.53516zm-2 .53516c-.6069.35108-1.1138.85795-1.4648 1.4648l1.7246.99609c.17407-.30871.42762-.56226.73633-.73633l-.99609-1.7246zm5.4648 1.4648-1.7246.99609c.16511.29282.25977.63391.25977 1.0039h2c0-.72905-.19474-1.4115-.53516-2zm-7.4648 2c0 .72905.19474 1.4115.53516 2l1.7246-.99609c-.16511-.29282-.25977-.63391-.25977-1.0039h-2zm5.7402 1.0039c-.17407.30871-.42762.56226-.73633.73633l.99609 1.7246c.6069-.35108 1.1138-.85795 1.4648-1.4648l-1.7246-.99609zm-2.7441.73633-.99609 1.7246c.58845.34041 1.2709.53516 2 .53516v-2c-.37 0-.71108-.094656-1.0039-.25977zm1.0039 4.2676c-.3677-.00032598-.73024.037514-1.0957.056641l.20703 1.9883c.29899-.018183.59806-.055014.89648-.054688.29843.00032615.59391.037806.89062.056641l.20898-1.9883c-.36891-.019778-.73972-.058268-1.1074-.058594zm-3.1914.41602c-.83387.20467-1.4665.43551-2.0508.67383l.79102 1.8398c.56514-.23773 1.1288-.4085 1.7207-.56055l-.46094-1.9531zm6.3828 0-.46094 1.9531c.59186.15205 1.1556.32281 1.7207.56055l.79102-1.8398c-.58433-.23832-1.2169-.46916-2.0508-.67383zm-10.434 1.9473c-.49435.46421-.75781 1.0621-.75781 1.6289v2h.5l1.5039-2.0039c.008361-.061766.10889-.15845.19141-.24609l-1.4375-1.3789zm14.484 0-1.4375 1.3789c.082513.087639.18305.18433.19141.24609l1.5039 2.0039h.5v-2c0-.56684-.26346-1.1647-.75781-1.6289zm-12.242 1.6289v2h2v-2h-2zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2z" />
    </>
  </SvgIcon>
));

export default PlaceholderIcon;
