import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { projectTasksQuery, DEFAULT_PAGE_SIZE } from './useProjectTasks';

const setTaskAssignedUserMutation = gql`
  mutation setTaskAssignedUser(
    $taskId: String!
    $userId: String
    $userRoleId: String
  ) {
    setTaskAssignedUser: setTaskAssignedUser2(
      input: { taskId: $taskId, userId: $userId, userRoleId: $userRoleId }
    ) {
      dummy
    }
  }
`;

export const updateCachedTaskAssignedUser = ({
  user,
  isOpen,
  taskId,
  projectSlug,
  assignedUserRoleId,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isPSATaskEstimateRollUpMobileViewEnabled
}) => proxy => {
  const cachedProjectTasksQuery = proxy.readQuery({
    query: projectTasksQuery,
    variables: {
      projectSlug,
      isOpen,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      isRolledUpTaskEstimateCalculationMethodEnabled:
        isRolledUpTaskEstimateCalculationMethodEnabled || false,
      isPSATaskEstimateRollUpMobileViewEnabled:
        isPSATaskEstimateRollUpMobileViewEnabled || false
    }
  });

  const { project: cachedGetProjectBySlug } = cachedProjectTasksQuery;

  const updateGetProjectBySlugQuery = {
    ...cachedGetProjectBySlug,
    tasks: cachedGetProjectBySlug.tasks.map(t => {
      if (t.id === taskId) {
        return {
          ...t,
          assignedUser: user || null,
          assignedUserRoleId
        };
      }

      return t;
    })
  };

  proxy.writeQuery({
    query: projectTasksQuery,
    variables: {
      projectSlug,
      isOpen,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      isRolledUpTaskEstimateCalculationMethodEnabled:
        isRolledUpTaskEstimateCalculationMethodEnabled || false,
      isPSATaskEstimateRollUpMobileViewEnabled:
        isPSATaskEstimateRollUpMobileViewEnabled || false
    },
    data: {
      project: updateGetProjectBySlugQuery
    }
  });
};

const useSetTaskAssignedUser = () => useMutation(setTaskAssignedUserMutation);

export const useAssignUserToTask = ({
  me,
  projectSlug,
  includeClosedTasks
}) => {
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = me || {};

  const [setTaskAssignedUser] = useSetTaskAssignedUser();

  return useCallback(
    (taskId, user) => {
      const userId = user ? user.id : null;
      const userRoleId =
        user && user.projectRoles && user.projectRoles.length > 0
          ? user.projectRoles[0].projectRole.uri
          : null;

      setTaskAssignedUser({
        variables: {
          taskId,
          userId,
          userRoleId
        },
        optimisticResponse: { setTaskAssignedUser: null },
        update: updateCachedTaskAssignedUser({
          user,
          isOpen: includeClosedTasks ? undefined : true,
          taskId,
          projectSlug,
          assignedUserRoleId: userRoleId,
          isRolledUpTaskEstimateCalculationMethodEnabled
        })
      });
    },
    [
      setTaskAssignedUser,
      isRolledUpTaskEstimateCalculationMethodEnabled,
      projectSlug,
      includeClosedTasks
    ]
  );
};

export default useAssignUserToTask;
