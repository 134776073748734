import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { updateClientFragment } from './updateClient.fragment';

export const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClient($client: UpdateClientInput!) {
    updateClient2(client: $client) {
      ...UpdateClient
    }
  }
  ${updateClientFragment}
`;

export const useUpdateClientMutation = () =>
  useMutation(UPDATE_CLIENT_MUTATION, {
    refetchQueries: ['clients'],
    awaitRefetchQueries: false
  });
