import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import RoleSchedulesDialogContent from './RoleSchedulesDialogContent';
import RolesDialogTitle from './RolesDialogTitle';
import {
  useUpdateRoleSchedule,
  onScheduleSubmit
} from './useUpdateRoleSchedule';

const roleDialog = {
  role: 'presentation'
};

export const RoleSchedulesDialog = ({
  open,
  onCancel,
  userDetails,
  editable,
  isResourceDetailsLoading
}) => {
  const [updateRoleSchedule] = useUpdateRoleSchedule({
    userUri: userDetails && userDetails.uri
  });

  const onSubmit = onScheduleSubmit({
    userUri: userDetails && userDetails.uri,
    onCancel,
    updateRoleSchedule
  });

  const isMobile = useIsBreakpointDown('sm');

  return (
    !isResourceDetailsLoading && (
      <Dialog
        fullScreen={isMobile}
        maxWidth="md"
        open={open}
        onClose={onCancel}
        TransitionProps={roleDialog}
      >
        <RolesDialogTitle />
        <RoleSchedulesDialogContent
          userDetails={userDetails}
          onCancel={onCancel}
          onSubmit={onSubmit}
          editable={editable}
        />
      </Dialog>
    )
  );
};

RoleSchedulesDialog.propTypes = {
  userDetails: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  isResourceDetailsLoading: PropTypes.bool.isRequired
};

export default RoleSchedulesDialog;
