import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  row: {
    minHeight: '65px'
  },
  title: {
    minWidth: '165px'
  },
  rowHighlighted: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary
  },
  cell: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // custom compact fontsize for userbeat
    '& input, textarea': {
      fontSize: theme.typography.body2.fontSize
    }
  },
  leftCell: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    position: 'relative'
  },
  cellHighlighted: {
    color: theme.palette.text.secondary
  },
  padding: {
    padding: '4px 8px 4px 8px'
  },
  zeroPadding: {
    padding: '0px'
  },
  zeroVerticalPadding: {
    padding: '0px 8px 0px 8px'
  },
  root: {
    [theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
      flexWrap: 'wrap',
      paddingBottom: theme.spacing(1.25)
    }
  },
  taskName: {},
  cellBorder: {
    borderBottom: `1px solid ${theme.palette.table.border}`
  },
  noBorder: {
    borderBottom: 'unset'
  }
});

export default withStyles(styles);
