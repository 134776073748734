import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { CardHeader, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditSharp';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';

const useCardStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  },
  content: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  },
  action: {
    margin: theme.spacing(-1.5, 0),
    marginRight: theme.spacing(-2)
  },
  root: {
    alignItems: 'center',
    flexGrow: 1
  }
}));

export const ReadOnlyCardHeader = ({
  classes: classesOverride,
  title,
  avatar,
  editable,
  onEdit,
  subheader,
  headerButtons,
  additionalInfo,
  fontSize = 'default',
  ariaLabel
}) => {
  const classes = useCardStyles({ classes: classesOverride });
  const { formatMessage } = useIntl();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const editAriaLabel = isPsaPrpAccessibilityIssuesEnabled
    ? ariaLabel
    : formatMessage({ id: 'button.edit' });
  const onEditHandler = useCallback(
    event => {
      onEdit();
      event && event.stopPropagation();
    },
    [onEdit]
  );

  return (
    <CardHeader
      classes={classes}
      avatar={avatar}
      title={title}
      subheader={subheader}
      action={
        <>
          {additionalInfo}
          {editable ? (
            <>
              <IconButton
                aria-label={editAriaLabel}
                data-qe-id="ReadOnlyCard_Edit"
                onClick={onEditHandler}
              >
                <EditIcon fontSize={fontSize} />
              </IconButton>
              {headerButtons}
            </>
          ) : null}
        </>
      }
    />
  );
};

ReadOnlyCardHeader.propTypes = {
  classes: PropTypes.object,
  avatar: PropTypes.node,
  title: PropTypes.node,
  headerButtons: PropTypes.node,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  fontSize: PropTypes.string,
  subheader: PropTypes.node,
  additionalInfo: PropTypes.node,
  ariaLabel: PropTypes.string
};

export default ReadOnlyCardHeader;
