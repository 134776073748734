import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';

export const TABLE_KEY = 'psa-resources';

export const hasViewCustomFields = permissions =>
  (permissions || []).filter(
    p => p.permissionActionUri === 'urn:replicon:user-action:view-custom-fields'
  ).length !== 0;

export const getColumns = (
  customFieldDefinitions = [],
  tagFieldData = [],
  me = {}
) => {
  const {
    groupSettings,
    legacyDepartmentDisabled,
    legacyEmployeeTypeDisabled,
    permissions
  } = me;
  const customFieldColumns = hasViewCustomFields(permissions)
    ? mapCustomFieldsToColumns(customFieldDefinitions)
    : [];
  const tagFieldColumns = hasViewCustomFields(permissions)
    ? mapTagFieldsToColumns(tagFieldData)
    : {};
  const locationSettings = get(groupSettings, 'location', {});
  const divisionSettings = get(groupSettings, 'division', {});
  const departmentSettings = get(groupSettings, 'department', {});
  const employeeTypeSettings = get(groupSettings, 'employeeType', {});
  const serviceCenterSettings = get(groupSettings, 'serviceCenter', {});
  const costCenterSettings = get(groupSettings, 'costCenter', {});
  const canViewRole = (permissions || []).some(
    p => p.permissionActionUri === 'urn:replicon:user-action:view-project-role'
  );

  const canViewResourcePool = (permissions || []).some(
    p => p.permissionActionUri === 'urn:replicon:user-action:view-resource-pool'
  );

  return {
    displayText: {
      field: 'displayText',
      value: <FormattedMessage id="resourceManagement.list.name" />,
      required: true,
      fixed: true,
      sortable: true,
      includesNavigationComponent: true
    },
    employeeId: {
      field: 'employeeId',
      value: <FormattedMessage id="resourceManagement.list.employeeId" />,
      sortable: true
    },
    startDate: {
      field: 'startDate',
      value: <FormattedMessage id="resourceManagement.list.startDate" />,
      sortable: true
    },
    endDate: {
      field: 'endDate',
      value: <FormattedMessage id="resourceManagement.list.endDate" />,
      sortable: true
    },
    ...(canViewRole
      ? {
          roles: {
            field: 'roles',
            value: <FormattedMessage id="resourceManagement.list.role" />,
            sortable: true
          }
        }
      : {}),
    supervisorAssignment: {
      field: 'supervisorAssignment',
      value: <FormattedMessage id="resourceManagement.list.supervisor" />,
      sortable: true
    },
    ...(costCenterSettings.isEnabled
      ? {
          currentCostCenter: {
            field: 'currentCostCenter',
            value: costCenterSettings.singularName || (
              <FormattedMessage id="resourceManagement.list.costCenter" />
            ),
            sortable: true
          }
        }
      : {}),
    ...(!legacyDepartmentDisabled
      ? {
          department: {
            field: 'department',
            value: <FormattedMessage id="resourceManagement.list.department" />,
            sortable: true
          }
        }
      : {}),
    ...(departmentSettings.isEnabled && legacyDepartmentDisabled
      ? {
          currentDepartment: {
            field: 'currentDepartment',
            value:
              departmentSettings.isEnabled && legacyDepartmentDisabled ? (
                departmentSettings.singularName || (
                  <FormattedMessage id="resourceManagement.list.department" />
                )
              ) : (
                <FormattedMessage id="resourceManagement.list.department" />
              ),
            sortable: true
          }
        }
      : {}),
    ...(divisionSettings.isEnabled
      ? {
          currentDivision: {
            field: 'currentDivision',
            value: divisionSettings.singularName || (
              <FormattedMessage id="resourceManagement.list.division" />
            ),
            sortable: true
          }
        }
      : {}),
    ...(employeeTypeSettings.isEnabled && legacyEmployeeTypeDisabled
      ? {
          currentEmployeeType: {
            field: 'currentEmployeeType',
            value:
              employeeTypeSettings.isEnabled && legacyEmployeeTypeDisabled ? (
                employeeTypeSettings.singularName || (
                  <FormattedMessage id="resourceManagement.list.employeeType" />
                )
              ) : (
                <FormattedMessage id="resourceManagement.list.employeeType" />
              ),
            sortable: true
          }
        }
      : {}),
    ...(!legacyEmployeeTypeDisabled
      ? {
          employeeType: {
            field: 'employeeType',
            value: (
              <FormattedMessage id="resourceManagement.list.employeeType" />
            ),
            sortable: true
          }
        }
      : {}),
    ...(locationSettings.isEnabled
      ? {
          currentLocation: {
            field: 'currentLocation',
            value: locationSettings.singularName || (
              <FormattedMessage id="resourceManagement.list.location" />
            ),
            sortable: true
          }
        }
      : {}),
    ...(serviceCenterSettings.isEnabled
      ? {
          currentServiceCenter: {
            field: 'currentServiceCenter',
            value: serviceCenterSettings.singularName || (
              <FormattedMessage id="resourceManagement.list.serviceCenter" />
            ),
            sortable: true
          }
        }
      : {}),
    ...(canViewResourcePool
      ? {
          resourcePools: {
            field: 'allResourcePools',
            value: (
              <FormattedMessage id="resourceManagement.list.resourcePools" />
            ),
            sortable: false
          }
        }
      : {}),
    ...customFieldColumns,
    ...tagFieldColumns
  };
};

const mapCustomFieldsToColumns = customFieldDefinitions =>
  customFieldDefinitions.reduce(
    (acc, customField) =>
      Object.assign(acc, {
        [customField.id]: {
          field: customField.id,
          value: customField.displayText,
          sortField: 'customField',
          sortable: true
        }
      }),
    {}
  );

const mapTagFieldsToColumns = tagFieldData =>
  tagFieldData.reduce(
    (acc, tagField) =>
      Object.assign(acc, {
        [tagField.id]: {
          field: tagField.id,
          value: tagField.name
        }
      }),
    {}
  );

const getDefaultColumns = (customFieldDefinitions, tagFieldData, me) => {
  const columns = getColumns(customFieldDefinitions, tagFieldData, me);

  return Object.keys(columns).map(c => columns[c]);
};

const getColumnGroups = ({
  customFieldDefinitions = [],
  tagFieldData = [],
  me
} = {}) => [
  {
    id: 'basic',
    title: null,
    columns: getDefaultColumns(customFieldDefinitions, tagFieldData, me)
  }
];

export const mobileColumnNames = ({ me }) => {
  const columns = getColumns(undefined, undefined, me);
  const mobileColumns = columns.roles
    ? [columns.displayText, columns.roles]
    : [columns.displayText];

  return mobileColumns.map(c => c.field);
};

export const getDefaultColumnNames = ({
  customFieldDefinitions = [],
  me
} = {}) => getDefaultColumns(customFieldDefinitions, [], me).map(c => c.field);

export default getColumnGroups;
