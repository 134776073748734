import React, { useMemo, useState, useCallback } from 'react';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useRevenueForecastSummary } from '~/modules/projects/project/RevenueRecognition/hooks';
import { ProjectHistoryDrawer } from '~/modules/billing-invoicing/revenueRecognition/ProjectRevenueEntriesHistory';
import { useHasPermission } from '~/modules/common/permissions';
import {
  OpenMonthRevenueChart,
  CloseMonthRevenueChart
} from '~/modules/projects/project/RevenueRecognition/OverviewChart';
import {
  mapRepliconDateToUtcObject,
  toRepliconDate
} from '~/modules/common/dates/convert';
import {
  EmptyRevenueRecognition,
  PolicyAccordion,
  LoadingChartComponents
} from './components';
import { AsOfCloseDateTitle } from './components/common';

const useStyles = makeStyles(theme => ({
  info: {
    color: theme.palette.text.secondary
  },
  subtitleContainer: {
    display: 'flex'
  },
  viewHistoryBtn: {
    color: theme.palette.primary.main,
    padding: 0,
    marginLeft: theme.spacing(1),
    textTransform: 'none'
  }
}));

export const RevenueRecognitionReadOnly = ({
  scriptId,
  policyLabel,
  parameters,
  scripts,
  projectUri,
  asOfCloseDate,
  tcv,
  startDate,
  endDate,
  isMobile,
  recognizedRevenueSummary
}) => {
  const classes = useStyles();

  const { loading: fsLoading, forecastSummary } = useRevenueForecastSummary({
    projectUri
  });

  const totalForecastedAmount = useMemo(() => tcv || forecastSummary, [
    forecastSummary,
    tcv
  ]);
  const [openHistory, setOpenHistory] = useState(false);
  const openRevenueHistory = useCallback(() => {
    setOpenHistory(true);
  }, []);
  const onCloseRevenueHistory = useCallback(() => {
    setOpenHistory(false);
  }, []);
  const openMonth = useMemo(
    () =>
      asOfCloseDate
        ? toRepliconDate(
            mapRepliconDateToUtcObject(asOfCloseDate).plus({ month: 1 })
          )
        : {},
    [asOfCloseDate]
  );

  const canViewRevenueItem = useHasPermission({
    actionUri: 'urn:replicon:revenue-item-action:view-revenue-item'
  });

  const canViewRevenueRun = useHasPermission({
    actionUri: 'urn:replicon:revenue-run-action:view-revenue-run'
  });

  if (!scriptId) return <EmptyRevenueRecognition />;

  return (
    <>
      {fsLoading ? (
        <LoadingChartComponents />
      ) : (
        <>
          {totalForecastedAmount ? (
            <>
              {!asOfCloseDate ? (
                <>
                  {canViewRevenueItem && (
                    <Typography variant="body2" className={classes.info}>
                      <FormattedMessage id="projectRevenueRecognitionCard.toCloseMonthMessage" />
                    </Typography>
                  )}
                  {totalForecastedAmount.amount > 0 ? (
                    <OpenMonthRevenueChart
                      totalForecastedAmount={totalForecastedAmount}
                      startDate={startDate}
                      endDate={endDate}
                      canViewRevenueItem={canViewRevenueItem}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <div className={classes.subtitleContainer}>
                    <Typography
                      variant="subtitle1"
                      className={classes.asOfDate}
                    >
                      <AsOfCloseDateTitle date={asOfCloseDate} />
                    </Typography>
                    {canViewRevenueRun ? (
                      <Button
                        onClick={openRevenueHistory}
                        className={classes.viewHistoryBtn}
                      >
                        <FormattedMessage id="projectRevenueRecognitionCard.viewHistory" />
                      </Button>
                    ) : null}
                  </div>
                  <CloseMonthRevenueChart
                    totalForecastedAmount={totalForecastedAmount}
                    startDate={startDate}
                    endDate={endDate}
                    asOfCloseDate={asOfCloseDate}
                    projectUri={projectUri}
                    recognizedRevenueSummary={recognizedRevenueSummary}
                  />
                  <ProjectHistoryDrawer
                    open={openHistory}
                    onClose={onCloseRevenueHistory}
                    projectId={projectUri}
                    date={openMonth}
                    isMobile={isMobile}
                  />
                </>
              )}
            </>
          ) : (
            <Typography variant="body2" className={classes.info}>
              <FormattedMessage id="projectRevenueRecognitionCard.noChartMessage" />
            </Typography>
          )}
        </>
      )}
      <PolicyAccordion
        policyLabel={policyLabel}
        parameters={parameters}
        scripts={scripts}
      />
    </>
  );
};

RevenueRecognitionReadOnly.propTypes = {
  scriptId: PropTypes.string,
  policyLabel: PropTypes.string,
  parameters: PropTypes.array,
  scripts: PropTypes.array,
  projectUri: PropTypes.string.isRequired,
  asOfCloseDate: PropTypes.object,
  tcv: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  recognizedRevenueSummary: PropTypes.object,
  isMobile: PropTypes.bool
};

export default RevenueRecognitionReadOnly;
