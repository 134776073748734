/* eslint-disable react/jsx-max-depth */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';
import {
  useIsBreakpointDown,
  useCodeAndNameRequired
} from '~/modules/common/hooks';
import { useGetRequiredCustomFieldsQuery } from '~/modules/customFields/graphql';
import {
  buildCustomFieldFormValuesFromDefinitions,
  customFieldValueToInput
} from '~/modules/customFields/utils';
import { useMeContext } from '~/modules/me';
import { useClientManagers } from '../../hooks';
import AddClientDialogForm from './AddClientDialogForm';
import AddClientDialogActions from './AddClientDialogActions';
import { useAddClientOnSubmit } from './useAddClientOnSubmit';
import { FormErrorContext } from './FormErrorContext';
import { buildValidationSchema } from './validationSchema';

const dialogRole = { role: 'presentation' };

export const AddClientDialog = ({
  open,
  onClose,
  intl,
  initialName = '',
  onClientAdd,
  onCancel
}) => {
  const history = useHistory();
  const {
    id: loginUserId,
    clientNameAndCodeUniqueness,
    hasClientManagerRole
  } = useMeContext();
  const isMobile = useIsBreakpointDown('sm');
  const clientManagers = useClientManagers({ clientId: '' });

  const customFieldDefinitions = useGetRequiredCustomFieldsQuery({
    variables: {
      groupName: 'Client'
    }
  });

  const mapValuesOnSubmit = useCallback(
    values => ({
      name: values.name,
      code: values.code,
      clientManagerUri: values.clientManager ? values.clientManager.id : null,
      customFieldValues: Object.values(values.customFieldValues).map(
        customFieldValueToInput
      )
    }),
    []
  );

  const { onSubmit, error, clearError } = useAddClientOnSubmit({
    history,
    onClose,
    mapValuesOnSubmit,
    onClientAdd
  });
  const errorContextValue = useMemo(() => ({ error, clearError }), [
    error,
    clearError
  ]);

  const clientManager = clientManagers.find(c => c.id === loginUserId);

  const initialValues = useMemo(
    () => ({
      name: initialName,
      code: '',
      clientManager,
      customFieldValues: buildCustomFieldFormValuesFromDefinitions(
        customFieldDefinitions
      )
    }),
    [clientManager, customFieldDefinitions, initialName]
  );

  const { isCodeRequired, isNameRequired } = useCodeAndNameRequired({
    codeNameAndUniqueness: clientNameAndCodeUniqueness
  });

  const validationSchema = buildValidationSchema({
    customFieldDefinitions,
    intl,
    isCodeRequired,
    isNameRequired
  });

  return (
    <FormErrorContext.Provider value={errorContextValue}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onReset={clearError}
        enableReinitialize
      >
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          fullScreen={isMobile}
          disableBackdropClick
          disableEscapeKeyDown
          TransitionProps={dialogRole}
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">
              <FormattedMessage id="clientList.addClient" />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <AddClientDialogForm
              showClientManager={hasClientManagerRole}
              clientManagers={clientManagers}
              isCodeRequired={isCodeRequired}
              isNameRequired={isNameRequired}
            />
          </DialogContent>
          <AddClientDialogActions onClose={onClose} onCancel={onCancel} />
        </Dialog>
      </Formik>
    </FormErrorContext.Provider>
  );
};

AddClientDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  intl: PropTypes.object,
  initialName: PropTypes.string,
  onClientAdd: PropTypes.func,
  onCancel: PropTypes.func
};

export default injectIntl(AddClientDialog);
