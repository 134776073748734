import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddToSearchIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 11.5 3 C 8.427882683563498 3 5.86521459576286 5.135138258134164 5.1875 8 L 7.29296875 8 C 7.907591523996217 6.255185176894677 9.54091255003055 4.999999999999999 11.5 5 C 13.99 5 16 7.009999999999998 16 9.5 C 16 11.45908744996945 14.74481482310532 13.09240847600378 13 13.70703125 L 13 15.818359375 C 14.02314787747018 15.57647484064918 14.95575426445856 15.09567012792158 15.73046875 14.4296875 L 16 14.7109375 L 16 15.5 L 21 20.490234375 L 22.490234375 19 L 17.5 14 L 16.7109375 14 L 16.4296875 13.73046875 C 17.4096875 12.59046875 18 11.11 18 9.5 C 18 5.910000000000002 15.09 3 11.5 3 z M 5 10 L 5 14 L 1 14 L 1 16 L 5 16 L 5 20 L 7 20 L 7 16 L 11 16 L 11 14 L 7 14 L 7 10 L 5 10 z " />
    </>
  </SvgIcon>
));

export default AddToSearchIcon;
