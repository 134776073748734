import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isoStringToFormattedISOString } from '~/modules/common/dates/convert';
import Decimal from '~/modules/common/components/Decimal';
import { FieldLabel } from '../../TaskAllocationEditor';

const dateFormatString = 'LLL dd, yyyy';

export const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    pointerEvents: 'none'
  },
  label: {
    color: theme.palette.text.secondary
  },
  dateRange: {
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    marginBottom: -theme.spacing(0.25)
  },
  hours: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const TaskAllocationCardContent = ({ taskResourceUserAllocation }) => {
  const { startDate, endDate, totalHours } = taskResourceUserAllocation;
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const hoursLabel = formatMessage({ id: 'taskResourceEstimates.hoursSuffix' });

  const dateRangeLabelValues = useMemo(
    () => ({
      startDate: isoStringToFormattedISOString(startDate, dateFormatString),
      endDate: isoStringToFormattedISOString(endDate, dateFormatString)
    }),
    [endDate, startDate]
  );

  return (
    <>
      <div className={classes.root}>
        <FieldLabel
          variant="caption"
          messageId="taskResourceEstimates.allocation"
          className={classes.label}
        />
        <div>
          <Decimal
            value={totalHours}
            className={classes.hours}
            suffix={hoursLabel}
          />
        </div>
      </div>

      <div className={classes.dateRange}>
        <FieldLabel
          messageId="taskResourceEstimates.dateRange"
          messageValues={dateRangeLabelValues}
        />
      </div>
    </>
  );
};

TaskAllocationCardContent.propTypes = {
  taskResourceUserAllocation: PropTypes.object.isRequired
};

export default TaskAllocationCardContent;
