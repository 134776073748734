import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles(theme => ({
  root: {},
  cell: {
    borderBottom: 'none',
    padding: theme.spacing(1, 2),
    verticalAlign: 'bottom',
    position: 'sticky',
    zIndex: 10,
    lineHeight: 1,
    fontSize: theme.typography.body2.fontSize,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  tableSortLabel: {
    '& svg': {
      fontSize: 18
    }
  }
}));

const DrilldownTableHeader = ({ columns, onSortChange, sort }) => {
  const classes = useStyles();

  const { direction, field: sortField } = sort || {};

  const directionLowerCase = direction ? direction.toLowerCase() : 'asc';

  const onClick = useCallback(
    (field, customFieldUri) => () => {
      onSortChange ? onSortChange(field, customFieldUri) : null;
    },
    [onSortChange]
  );

  return (
    <TableHead className={classes.root}>
      <TableRow variant="head" role="row">
        {columns.map(column => (
          <TableCell
            key={column.key}
            align={column.align}
            role="columnheader"
            className={classNames(
              classes.cell,
              column.className,
              column.headerClassName
            )}
          >
            <TableSortLabel
              sortid={column.key}
              className={classes.tableSortLabel}
              active={sortField && sortField === column.sortField}
              direction={
                sortField && sortField === column.sortField
                  ? directionLowerCase
                  : 'asc'
              }
              onClick={onClick(column.sortField, null)}
              hideSortIcon={!column.sortField}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

DrilldownTableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  onSortChange: PropTypes.func,
  sort: PropTypes.object
};

export default DrilldownTableHeader;
