export const hasPermittedAction = (permittedActionUris, permittedActionUri) =>
  permittedActionUris && permittedActionUris.includes(permittedActionUri);

export const useProjectObjectPermissions = ({ project }) => {
  const permittedActionUris = project.permittedActionUris || [];

  return {
    canEditClient: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-client'
    ),
    canViewClient: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:view-client'
    ),
    canEditProgram: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-program'
    ),
    canViewProgram: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:view-program'
    ),
    canViewBillingContracts: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:view-billing-contracts'
    ),
    canViewTasks: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:view-tasks'
    ),
    canEditTasks: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-tasks'
    ),
    canEditTeam: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-team'
    ),
    canEditBillingRates: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-billing-rates'
    ),
    canEditExpenseCodes: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-expense-codes'
    ),
    canEditResourceAllocations: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-resource-allocations'
    ),
    canEditBillingContracts: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-billing-contracts'
    ),
    canEditRevenueContract: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:project-action:edit-revenue-contract'
    )
  };
};
export default useProjectObjectPermissions;
