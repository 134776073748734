import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Client from '~/modules/common/components/Client/Client';

const styles = theme => ({
  root: {
    padding: theme.spacing(0, 1, 1, 1)
  }
});

export const MobileBillingTransactionClientRow = ({ classes, client }) => (
  <div className={classes.root}>
    <Client disablePadding client={client} />
  </div>
);

MobileBillingTransactionClientRow.propTypes = {
  classes: PropTypes.object,
  client: PropTypes.object.isRequired
};

export default withStyles(styles)(MobileBillingTransactionClientRow);
