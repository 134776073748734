import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CostIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 2,1.999999999999999 V 11.89949493661166 L 12.60660171779821,22.50609665440987 22.50609665440988,12.60660171779821 11.89949493661167,1.999999999999999 Z m 2.828427124746194,2.828427124746189 a 1.5,1.5 0 0 1 2.121320343559637,0 1.5,1.5 0 0 1 0,2.121320343559642 1.5,1.5 0 0 1 -2.121320343559637,0 1.5,1.5 0 0 1 0,-2.121320343559642 z m 2.97482032553871,4.087961078734735 1.179432013932247,-1.179432013932256 0.86178638957111,0.861786389571106 C 10.86535126912633,7.97560472819912 11.89977115019807,8.188012976141489 12.6024585140022,8.837667331356632 l -0.86592959336712,0.86592959336712 C 11.22387650427483,9.235138682187661 10.62836001199428,9.160008586686592 9.930092065572571,9.858276533108313 9.267179458210181,10.5211891404707 9.168847421451431,11.21835223254681 9.59311149016335,11.64261630125874 c 0.371231060122941,0.37123106012293 0.90211357318567,0.32869416781718 2.024645588319309,-0.33421843954521 1.12253201513364,-0.66291260736238 2.46051062766006,-1.23246502252124 3.57420380802887,-0.11877184215242 0.80433396359969,0.81317279836452 0.64109173403671,1.8597460772379 0.0179538831161,2.77456547539799 l 0.84383250645504,0.84383250645504 -1.17943201393225,1.17943201393225 -0.85211891404711,-0.85211891404707 c -0.9148193981601,0.59662134662615 -2.04121840350335,0.7507485278379 -2.94719896689862,-0.0580048531442 L 11.94092697457181,14.211402654788 c 0.50823299897783,0.41984465132951 1.18827084869708,0.46348639798087 1.9970242296792,-0.34526698300124 0.86620580695352,-0.86620580695352 0.62728105471666,-1.49403928884298 0.35769659438929,-1.76362374917035 -0.36681164274053,-0.36681164274053 -0.90570434980887,-0.51513833863786 -2.12546354735566,0.23616261637285 C 10.80900369750053,13.16952500688345 9.6069221694834,13.46838810736933 8.700941606088138,12.56240754397406 7.9408018163126,11.80226775419852 8.060126085637827,10.69354641838493 8.656747432263937,9.769888185459999 Z" />
    </>
  </SvgIcon>
));

export default CostIcon;
