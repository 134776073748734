import { withStyles } from '@material-ui/core';

export const styles = theme => ({
  collapseContainer: {
    minHeight: 'auto !important'
  },
  container: {
    padding: theme.spacing(1, 1, 0, 1),
    margin: 0,
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderTop: `1px solid ${theme.palette.table.border}`
  },
  collapsibleContainer: {
    padding: theme.spacing(0, 1, 1, 1),
    margin: 0,
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderBottom: `1px solid ${theme.palette.table.border}`
  },
  netReadOnly: {
    padding: theme.spacing(0, 1, 0, 0),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular
  },
  paymentTermsReadOnly: {
    fontSize: theme.typography.body2.fontSize,
    paddingTop: theme.spacing(1) - 3
  },
  showError: {
    paddingLeft: theme.spacing(2) - 1,
    color: theme.palette.error.dark
  },
  readOnlyInput: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular
  },
  input: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(0.75, 0)
  },
  toggleDetailsButton: {
    marginLeft: theme.spacing(-1)
  },
  internalNotesLabel: {
    whiteSpace: 'nowrap'
  },
  internalNotes: {
    overflowY: 'hidden'
  }
});

export default withStyles(styles);
