const createScheduleRulesEntry = ({ startDate, endDate, setHours }) => ({
  dateRange: {
    startDate,
    endDate,
    __typename: 'DateRange'
  },
  do: {
    load: 100,
    setHours,
    excludeWeekdays: []
  },
  __typename: 'ScheduleRule'
});

export const getScheduleRulesFromAllocationPeriods = sortedAllocationPeriods => {
  const scheduleRules = [];

  if (sortedAllocationPeriods.length === 0) return scheduleRules;

  let isFirstWorkingEntryMatched = false;
  let startDateOfSchedulePeriod = null;
  let scheduleHoursForSchedulePeriod = null;

  for (let i = 0; i < sortedAllocationPeriods.length; i++) {
    const { date, hours } = sortedAllocationPeriods[i];

    if (!isFirstWorkingEntryMatched) {
      startDateOfSchedulePeriod = date;
      scheduleHoursForSchedulePeriod = hours;
      isFirstWorkingEntryMatched = true;
      continue;
    }

    if (hours !== scheduleHoursForSchedulePeriod) {
      const previousEntry = sortedAllocationPeriods[i - 1];

      scheduleRules.push(
        createScheduleRulesEntry({
          startDate: startDateOfSchedulePeriod.toISO(),
          endDate: previousEntry.date.toISO(),
          setHours: scheduleHoursForSchedulePeriod
        })
      );

      startDateOfSchedulePeriod = date;
      scheduleHoursForSchedulePeriod = hours;
    }
  }

  const entry = sortedAllocationPeriods[sortedAllocationPeriods.length - 1];

  scheduleRules.push(
    createScheduleRulesEntry({
      startDate: startDateOfSchedulePeriod.toISO(),
      endDate: entry.date.toISO(),
      setHours: scheduleHoursForSchedulePeriod
    })
  );

  return scheduleRules;
};
