import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import deepEqual from 'fast-deep-equal';
import { extractFirstValidationError } from '~/modules/common/graphql/errors';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';

export const UPDATE_PROJECT_BILLING_SETTING = gql`
  mutation UpdateProjectBilling($input: UpdateProjectBillingSettingInput!) {
    updateProjectBillingSetting(input: $input) {
      project {
        id
        projectBillSettings {
          billLineItemsBy
          description
          internalNotes
          showComments
          defaultInvoicePaymentTerm
          invoiceCurrency {
            id
            symbol
          }
          taxProfile {
            id
            displayText
          }
          invoiceTemplate {
            id
            displayText
          }
          poNumber
        }
      }
    }
  }
`;

export const useUpdateProjectBillingSetting = ({
  initialValues,
  projectBillSettings
}) => {
  const [updateProjectBillingSetting] = useMutation(
    UPDATE_PROJECT_BILLING_SETTING
  );

  const onSubmit = useCallback(
    async (values, { resetForm, setSubmitting, setFieldError }) => {
      const {
        id,
        taxProfile,
        invoiceCurrency,
        defaultInvoicePaymentTerm,
        invoiceTemplate,
        billLineItemsBy,
        description,
        internalNotes,
        showComments,
        poNumber
      } = values;

      try {
        const {
          key: prevKey,
          poNumber: prevPoNumber,
          ...prevValues
        } = initialValues;
        const { key: currKey, poNumber: currPoNumber, ...currValues } = values;
        const isClientValueOverrided = !deepEqual(prevValues, currValues);

        const input =
          projectBillSettings?.billLineItemsBy || isClientValueOverrided
            ? {
                id,
                taxProfileUri: taxProfile ? taxProfile.id : null,
                invoiceCurrencyUri: invoiceCurrency.id,
                defaultInvoicePaymentTerm: defaultInvoicePaymentTerm || 0,
                invoiceTemplateUri: invoiceTemplate ? invoiceTemplate.id : null,
                billLineItemsBy: billLineItemsBy || [
                  BILLING_COLUMN_TYPE.PROJECT
                ],
                description: description || null,
                internalNotes: internalNotes || null,
                showComments: showComments || false,
                poNumber: poNumber || null
              }
            : {
                id,
                poNumber
              };

        await updateProjectBillingSetting({
          variables: {
            input
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateProjectBillingSetting: {
              __typename: 'UpdateProjectBillingSettingResponse',
              project: {
                __typename: 'Project',
                id,
                projectBillSettings: {
                  __typename: 'ProjectBillSettings',
                  billLineItemsBy,
                  description,
                  internalNotes,
                  showComments,
                  taxProfile,
                  invoiceCurrency,
                  defaultInvoicePaymentTerm,
                  invoiceTemplate,
                  poNumber
                }
              }
            }
          }
        });
        resetForm();
      } catch (error) {
        const validationErrors = extractFirstValidationError(error);

        setFieldError('billingInfo', validationErrors);
      } finally {
        setSubmitting(false);
      }
    },
    [
      initialValues,
      projectBillSettings?.billLineItemsBy,
      updateProjectBillingSetting
    ]
  );

  return { onSubmit };
};

export default useUpdateProjectBillingSetting;
