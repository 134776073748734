import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { NoValue } from '~/modules/common/components';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: theme.spacing(30)
  }
});

export const TaskFormatter = ({ field, record }) => {
  const classes = useStyles();
  const value = record[field];

  return value ? (
    <Typography className={classes.value} variant="body2">
      {value}
    </Typography>
  ) : (
    <NoValue />
  );
};

TaskFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default TaskFormatter;
