import { useQuery } from '@apollo/client';
import deepEqual from 'fast-deep-equal';
import get from 'lodash.get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useSessionStorage } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY, {
  RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
} from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import useProjectResourcePlanPermissions from '~/modules/resourcing/hooks/useProjectResourcePlanPermissions';
import { ResourceAllocationStatus, ResourceRequestStatus } from '~/types';
import { updateProjectTotalsCacheForNewRequest } from '~/modules/resourcing/enhancers/updateResourceRequestTotalsCache';

export const tryLoadCachedProjectResourceRequestsQuery = ({
  proxy,
  variables
}) => {
  try {
    return proxy.readQuery({
      query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

const getFilterInput = ({ searchText, roleUris, userIds, resourcePoolIds }) => {
  const hasFilter =
    Boolean(searchText) ||
    Boolean(roleUris) ||
    Boolean(userIds) ||
    Boolean(resourcePoolIds);

  return hasFilter
    ? {
        roles: roleUris,
        text: searchText,
        users: userIds,
        resourcePools: resourcePoolIds
      }
    : undefined;
};

export const updateCachedProjectResourceRequests = ({
  newRequest,
  variables,
  filter,
  isResourceActualModeEnabled
}) => client => {
  const results = tryLoadCachedProjectResourceRequestsQuery({
    proxy: client.cache,
    variables
  });

  updateProjectTotalsCacheForNewRequest({
    proxy: client.cache,
    newRequest,
    filter: filter || {},
    isResourceActualModeEnabled
  });

  if (!results) return;

  const { resourceRequests } = results;

  client.writeQuery({
    query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY,
    variables,
    data: {
      resourceRequests: {
        ...resourceRequests,
        resourceRequests: [...resourceRequests.resourceRequests, newRequest]
      }
    }
  });
};

const useProjectResourceRequests = ({
  projectUri,
  roleUris,
  searchText,
  userIds,
  resourcePoolIds,
  sort,
  chartDisplayDateRange = null
}) => {
  const {
    permissionsMap,
    featureFlags: { isPsaPraaResourcingUIEnabled }
  } = useMeContext();

  const isResourceAssignmentEnabled = Boolean(
    permissionsMap[
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
    ]
  );

  const {
    isViewAdvancedSettingsEnabled,
    isViewAvailabilitySettingsEnabled
  } = useProjectResourcePlanPermissions(permissionsMap);

  const showTimeOff =
    isViewAvailabilitySettingsEnabled && Boolean(chartDisplayDateRange);

  const showHolidays =
    isViewAdvancedSettingsEnabled && Boolean(chartDisplayDateRange);

  const formattedDateRange = useMemo(
    () =>
      chartDisplayDateRange
        ? {
            startDate: dateToMidnightUTCString(chartDisplayDateRange.startDate),
            endDate: dateToMidnightUTCString(chartDisplayDateRange.endDate)
          }
        : null,
    [chartDisplayDateRange]
  );

  const requestStatusList = [
    ResourceRequestStatus.Allocationrejected,
    ResourceRequestStatus.Draft,
    ResourceRequestStatus.Rejected,
    ResourceRequestStatus.Submitted,
    ResourceRequestStatus.Tentative,
    ResourceRequestStatus.Tobehired
  ];

  const filter = getFilterInput({
    roleUris,
    searchText,
    userIds,
    resourcePoolIds
  });

  const RESOURCE_REQUESTS_QUERY =
    showTimeOff || showHolidays
      ? RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
      : RESOURCE_REQUESTS_ALLOCATIONS_QUERY;
  const limit = 50;
  const statusList = requestStatusList;
  const minimumLimit = 10;

  const variables =
    showTimeOff || showHolidays
      ? {
          cursor: null,
          limit,
          projectUri,
          filter,
          sort,
          showTimeOff,
          showHolidays,
          chartDateRange: formattedDateRange,
          requestStatusList: statusList,
          minimumLimit
        }
      : {
          requestStatusList: statusList,
          limit,
          cursor: null,
          projectUri,
          filter,
          sort,
          minimumLimit
        };

  const { storedValue, setValue } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  useEffect(() => {
    if (!deepEqual(storedValue, variables)) {
      setValue(variables);
    }
  }, [variables, setValue, storedValue]);

  const [loadingMore, setLoadingMore] = useState(false);
  const { loading, error, data, fetchMore } = useQuery(
    RESOURCE_REQUESTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables,
      errorPolicy: 'all'
    }
  );

  const resourceRequests = get(data, 'resourceRequests.resourceRequests') || [];

  const nextPageCursor = get(data, 'resourceRequests.nextPageCursor');

  const hasMoreRows = Boolean(nextPageCursor);

  const loadMoreRows = useCallback(async () => {
    if (loading || loadingMore || !hasMoreRows) return;

    setLoadingMore(true);
    try {
      await fetchMore({
        query: RESOURCE_REQUESTS_QUERY,
        variables: {
          ...variables,
          cursor: nextPageCursor
        }
      });
    } finally {
      setLoadingMore(false);
    }
  }, [
    loading,
    loadingMore,
    hasMoreRows,
    fetchMore,
    RESOURCE_REQUESTS_QUERY,
    variables,
    nextPageCursor
  ]);

  return {
    loadingRows: loading,
    loadingMoreRows: loadingMore,
    nextPageCursor,
    resourceRequests: (resourceRequests || [])
      .filter(req => requestStatusList.includes(req.requestStatus))
      .map(req => ({
        ...req,
        resourceAllocations: req.resourceAllocations.filter(
          alloc =>
            isResourceAssignmentEnabled ||
            alloc.allocationStatus !== ResourceAllocationStatus.Draft
        ),
        isDelaDraftResourceRequest:
          isPsaPraaResourcingUIEnabled &&
          req.sourceMetadata?.sourcedFromAI &&
          req.requestStatus === ResourceRequestStatus.Draft
      })),
    error,
    loadMoreRows,
    hasMoreRows
  };
};

export default useProjectResourceRequests;
