import { useCallback } from 'react';
import { usePutTaskResourceEstimate } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/hooks';

const useOnChangeHandlers = ({
  isAssignedTask,
  defaultRole,
  userId,
  taskId,
  estimatedHours,
  setIsAssignedTask,
  setRoleDisplayText,
  setEstimatedHours,
  openRemoveTaskAssignmentDialog,
  closeRemoveTaskAssignmentDialog,
  onTaskAssignmentUpdateSuccess
}) => {
  const { putTaskResourceEstimate, loading } = usePutTaskResourceEstimate();

  return {
    loading,
    handleTaskClick: useCallback(async () => {
      if (!isAssignedTask) {
        await putTaskResourceEstimate({
          taskId,
          resourceUserId: userId,
          projectRoleId: defaultRole?.id,
          initialEstimatedHours: estimatedHours
        });
        setRoleDisplayText(defaultRole?.displayText);
        setIsAssignedTask(true);
        onTaskAssignmentUpdateSuccess({ userId, isAssignedTask });
      } else {
        openRemoveTaskAssignmentDialog();
      }
    }, [
      isAssignedTask,
      putTaskResourceEstimate,
      taskId,
      userId,
      defaultRole?.id,
      defaultRole?.displayText,
      estimatedHours,
      setRoleDisplayText,
      setIsAssignedTask,
      openRemoveTaskAssignmentDialog,
      onTaskAssignmentUpdateSuccess
    ]),
    handleDrawerIconClick: useCallback(e => {
      e.stopPropagation();
    }, []),
    onRemoveTaskAssignmentSuccess: useCallback(() => {
      setIsAssignedTask(false);
      setRoleDisplayText(defaultRole?.displayText);
      setEstimatedHours(null);
      onTaskAssignmentUpdateSuccess({ userId, isAssignedTask });
      closeRemoveTaskAssignmentDialog();
    }, [
      setIsAssignedTask,
      setRoleDisplayText,
      defaultRole?.displayText,
      setEstimatedHours,
      userId,
      isAssignedTask,
      closeRemoveTaskAssignmentDialog,
      onTaskAssignmentUpdateSuccess
    ])
  };
};

export default useOnChangeHandlers;
