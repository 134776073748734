import React, { useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/ClearSharp';
import SearchIcon from '@material-ui/icons/SearchSharp';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useContextualFacets } from './enhancers';
import {
  classifySearchPhrase,
  constructSearchPhrase
} from './hooks/useSearchState';

const SEARCH_INPUT_ID = 'search-input';

const getOptionLabel = option =>
  typeof option === 'string' ? option : option.label;

const filterOptions = options => {
  const counts = {};

  return options.filter(option => {
    counts[option.facet] = counts[option.facet] || 0;

    return counts[option.facet]++ < 3;
  });
};

const onOptionChange = (searchPhrase, handleSearchInputChange) => (
  _,
  value
) => {
  let phrase = '';

  if (value) {
    if (value.facet) {
      const { criterions } = classifySearchPhrase(searchPhrase);

      phrase = constructSearchPhrase({
        keywords: [],
        criterions: {
          ...Object.keys(criterions).reduce(
            (acc, key) => ({
              ...acc,
              [key]: criterions[key].map(v => ({ key: v }))
            }),
            {}
          ),
          [value.facet]: [
            ...(criterions[value.facet] || []).map(v => ({ key: v })),
            value.value
          ]
        }
      });
    } else {
      phrase = searchPhrase;
    }
  }

  handleSearchInputChange({ target: { value: phrase } });
};

const onChange = (
  intl,
  setSearchPhraseInternal,
  setOptions,
  facetCriteria
) => event => {
  const phrase = event.target.value || '';

  setSearchPhraseInternal(phrase);

  const criteria = classifySearchPhrase(phrase);

  const searchBy = {
    label: intl.formatMessage(
      {
        id: 'searchBox.searchBy'
      },
      { phrase: criteria.keywords.join(' ') }
    ),
    value: criteria.keywords.join(' ')
  };

  setOptions([searchBy]);

  Promise.all(
    Object.keys(facetCriteria)
      .map(
        key =>
          facetCriteria[key].suggestions &&
          Promise.resolve(
            facetCriteria[key].suggestions(criteria.keywords.join(' '))
          ).then(opts => ({ facet: key, options: opts }))
      )
      .filter(Boolean)
  ).then(suggestions => {
    const opts = suggestions.flatMap(({ facet, options: $options }) =>
      $options
        // eslint-disable-next-line max-nested-callbacks
        .filter(option => !!option.label)
        // eslint-disable-next-line max-nested-callbacks
        .map(option => ({
          label: option.label,
          value: option,
          facet
        }))
    );

    setOptions([searchBy, ...opts]);
  });
};

const SearchInput = ({
  classes,
  facets,
  dataQeId,
  ariaLabel,
  inputAriaLabel,
  autoFocus,
  searchHintLabel,
  hideSearch,
  searchPhrase,
  setSearchPhraseInternal,
  makeHandleSearchInputChange,
  makeHandleClearSearchTerm,
  handleSearchInputFocus,
  makeHandleSearchInputSearch,
  searchInputId,
  popperComponent
}) => {
  const intl = useIntl();

  const facetCriteria = useContextualFacets(facets).reduce(
    (
      acc,
      { tag, options, matchOption, selected, setSelected, suggestions }
    ) => ({
      ...acc,
      [tag]: {
        options,
        matchOption,
        selected,
        setSelected,
        suggestions
      }
    }),
    {}
  );

  const isMobile = useIsBreakpointDown('xs');

  const [changed, setChanged] = useState(false);

  const [open, setOpen] = useState(true);
  const [options, setOptions] = useState([]);

  const show = useCallback(() => setOpen(true), [setOpen]);
  const hide = useCallback(() => setOpen(false), [setOpen]);

  const handleSearchInputChange = useMemo(
    () => makeHandleSearchInputChange(facetCriteria),
    [facetCriteria, makeHandleSearchInputChange]
  );

  const handleClearSearchTerm = useMemo(
    () => makeHandleClearSearchTerm(facetCriteria),
    [facetCriteria, makeHandleClearSearchTerm]
  );

  const handleSearchInputSearch = useMemo(
    () => makeHandleSearchInputSearch(facetCriteria),
    [facetCriteria, makeHandleSearchInputSearch]
  );

  const onSearchInputKeyPress = useCallback(
    event => {
      if (
        event.key &&
        event.key.length > 1 &&
        event.key !== 'Backspace' &&
        event.key !== 'Enter' &&
        event.key !== 'Delete'
      ) {
        return;
      }

      if (event.key === 'Enter') {
        hide();
        setChanged(false);
        handleSearchInputSearch();
      } else {
        setChanged(true);
      }
    },
    [hide, handleSearchInputSearch]
  );

  const onSearchInputSearch = useCallback(() => {
    setChanged(false);
    handleSearchInputSearch();
  }, [setChanged, handleSearchInputSearch]);

  const autoCompleteClasses = useMemo(() => ({ root: classes.inputRoot }), [
    classes.inputRoot
  ]);

  const buttonClasses = useMemo(
    () => ({
      root: classNames(
        classes.searchButton,
        changed && classes.searchButtonUnappliedChanges
      ),
      label: classes.searchButtonLabel
    }),
    [
      classes.searchButton,
      changed,
      classes.searchButtonLabel,
      classes.searchButtonUnappliedChanges
    ]
  );

  const labelValue = useMemo(
    () => ({ label: searchPhrase, value: searchPhrase }),
    [searchPhrase]
  );

  return (
    <>
      <Autocomplete
        classes={autoCompleteClasses}
        disableClearable
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        options={options}
        freeSolo
        open={searchPhrase !== '' && open}
        onOpen={show}
        role="search"
        onClose={hide}
        value={labelValue}
        onChange={onOptionChange(searchPhrase, handleSearchInputChange)}
        id={searchInputId || SEARCH_INPUT_ID}
        PopperComponent={popperComponent}
        renderInput={({ ref, InputProps, inputProps }) => {
          const props = {
            autoComplete: 'off',
            'aria-label': inputAriaLabel || searchHintLabel,
            'data-qe-id': dataQeId && `${dataQeId}_Input`,
            ...inputProps,
            id: searchInputId || SEARCH_INPUT_ID
          };

          delete props.className;
          delete props.value;

          return (
            <Input
              ref={ref}
              inputProps={props}
              data-qe-id={dataQeId}
              autoFocus={autoFocus}
              placeholder={searchHintLabel}
              autoComplete="off"
              aria-label={ariaLabel}
              spellCheck="off"
              disableUnderline
              type="text"
              disabled={hideSearch}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              value={searchPhrase}
              onChange={onChange(
                intl,
                setSearchPhraseInternal,
                setOptions,
                facetCriteria
              )}
              onFocus={() => {
                show();
                handleSearchInputFocus();
              }}
              onBlur={hide}
              onKeyDown={onSearchInputKeyPress}
              {...InputProps}
            />
          );
        }}
        renderOption={option => {
          if (option.facet) {
            const facet = facets.find(f => f.tag === option.facet);

            if (facet && facet.SuggestionItem) {
              const { SuggestionItem } = facet;

              return <SuggestionItem option={option.value} />;
            }
          }

          return getOptionLabel(option);
        }}
      />
      {searchPhrase.length > 0 && (
        <IconButton
          aria-label={intl.formatMessage({ id: 'searchBox.clear' })}
          data-qe-id={`${dataQeId}_Clear`}
          className={classNames(classes.iconButton, classes.clearButton)}
          onClick={handleClearSearchTerm}
        >
          <ClearIcon />
        </IconButton>
      )}
      <Button
        color="primary"
        variant="contained"
        aria-label={intl.formatMessage({ id: 'searchBox.applySearch' })}
        classes={buttonClasses}
        onClick={onSearchInputSearch}
        data-qe-id={dataQeId && `${dataQeId}_ApplySearchButton`}
      >
        {isMobile ? (
          <SearchIcon className={classes.searchButtonIcon} />
        ) : (
          intl.formatMessage({ id: 'searchBox.search' })
        )}
      </Button>
    </>
  );
};

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  facets: PropTypes.array,
  dataQeId: PropTypes.string,
  ariaLabel: PropTypes.string,
  inputAriaLabel: PropTypes.string,
  autoFocus: PropTypes.any,
  searchHintLabel: PropTypes.string,
  hideSearch: PropTypes.bool,
  searchPhrase: PropTypes.string,
  setSearchPhraseInternal: PropTypes.func,
  makeHandleSearchInputChange: PropTypes.func,
  makeHandleClearSearchTerm: PropTypes.func,
  handleSearchInputFocus: PropTypes.func,
  makeHandleSearchInputSearch: PropTypes.func,
  searchInputId: PropTypes.string,
  popperComponent: PropTypes.object
};

export default SearchInput;
