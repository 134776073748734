import React, { useCallback, useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Divider,
  makeStyles
} from '@material-ui/core';

import { PropTypes } from 'prop-types';
import RefreshIcon from '@material-ui/icons/RefreshSharp';
import ShowChartSharpIcon from '@material-ui/icons/ShowChartSharp';
import {
  MoneyValue,
  Percentage,
  FormReadOnlyField
} from '~/modules/common/components';
import { FormRow } from '~/modules/common/components/FormSection';

import { useMeContext } from '~/modules/me/useMeContext';
import { EarnedRevenueRecalculateBatch } from './EarnedRevenueRecalculateBatch';
import {
  useCreateEarnedRevenueRecalculationBatch,
  useEarnedRevenue,
  useEarnedRevenueState
} from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 'unset',
    marginBottom: theme.spacing(2)
  }
}));

const useHeaderStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.subtitle1.fontSize
    }
  },
  root: {
    padding: theme.spacing(1.5, 1.75)
  },
  avatar: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
}));

const avatar = <ShowChartSharpIcon />;

const EarnedRevenue = ({ projectUri }) => {
  const me = useMeContext();
  const headerClasses = useHeaderStyles();
  const cardClasses = useStyles();

  const {
    loading,
    earnedRevenue,
    cost,
    refetchEarnedRevenue,
    earnedRevenueScript
  } = useEarnedRevenue(projectUri, me);

  const profit =
    earnedRevenue || cost
      ? {
          currency: earnedRevenue?.currency ?? cost?.currency,
          amount: (earnedRevenue?.amount ?? 0) - (cost?.amount ?? 0)
        }
      : null;
  const margin =
    earnedRevenue && cost && earnedRevenue.amount !== 0
      ? ((earnedRevenue.amount - cost.amount) * 100) / earnedRevenue.amount
      : null;
  const {
    recalculationBatchState,
    setRecalculationBatchState
  } = useEarnedRevenueState();

  const { recalculateEarnedRevenue } = useCreateEarnedRevenueRecalculationBatch(
    {
      projectId: projectUri,
      setRecalculationBatchState
    }
  );
  const earnedRevenueRecalculate = useMemo(
    () => recalculationBatchState.batchInProgress,
    [recalculationBatchState.batchInProgress]
  );
  const recalculate = useCallback(() => recalculateEarnedRevenue(), [
    recalculateEarnedRevenue
  ]);

  const onBatchComplete = useCallback(() => refetchEarnedRevenue(), [
    refetchEarnedRevenue
  ]);

  return (
    !loading &&
    earnedRevenueScript && (
      <Card classes={cardClasses}>
        <CardHeader
          avatar={avatar}
          title="Earned Revenue"
          classes={headerClasses}
        />
        <Divider variant="fullWidth" />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <FormRow>
                <FormReadOnlyField label="Earned Revenue">
                  {earnedRevenueRecalculate ? (
                    <EarnedRevenueRecalculateBatch
                      batchState={recalculationBatchState}
                      setBatchState={setRecalculationBatchState}
                      onBatchComplete={onBatchComplete}
                    />
                  ) : (
                    <>
                      {earnedRevenue ? (
                        <MoneyValue money={earnedRevenue} />
                      ) : null}
                      <IconButton onClick={recalculate} size="small">
                        <RefreshIcon />
                      </IconButton>
                    </>
                  )}
                </FormReadOnlyField>
                <FormReadOnlyField label="Project Cost">
                  {cost ? <MoneyValue money={cost} /> : null}
                </FormReadOnlyField>
              </FormRow>
              <FormRow>
                <FormReadOnlyField label="Profit">
                  {profit ? <MoneyValue money={profit} /> : null}
                </FormReadOnlyField>
                <FormReadOnlyField label="Margin">
                  {margin ? <Percentage value={margin} /> : null}
                </FormReadOnlyField>
              </FormRow>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  );
};

EarnedRevenue.propTypes = {
  projectUri: PropTypes.string
};

export default EarnedRevenue;
