import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const UPDATE_PROJECT_CONTACTS = gql`
  mutation UpdateProjectContacts($input: UpdateProjectContactInfoInput!) {
    updateProjectContacts(input: $input) {
      project {
        id
        contacts {
          name
          email
          phoneNumber
          faxNumber
          website
          contactType
        }
      }
    }
  }
`;

const getInputContacts = contacts => {
  return contacts.filter(contact =>
    Object.keys(contact).some(
      k => k !== 'id' && k !== 'contactType' && contact[k]
    )
  );
};

export const useUpdateProjectContacts = ({
  projectId,
  setIsSubmitting,
  onClose
}) => {
  const [updateProjectContacts] = useMutation(UPDATE_PROJECT_CONTACTS);

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      setIsSubmitting(true);
      const { contacts } = values;
      const inputContacts = getInputContacts(contacts);

      const input = {
        projectId,
        contacts: inputContacts.map(
          ({ name, email, phoneNumber, faxNumber, website, contactType }) => ({
            name: name || null,
            email: email || null,
            phoneNumber: phoneNumber || null,
            faxNumber: faxNumber || null,
            website: website || null,
            contactType
          })
        )
      };

      await updateProjectContacts({
        variables: { input },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProjectContacts: {
            __typename: 'UpdateProjectContactInfoResponse',
            project: {
              __typename: 'Project',
              id: projectId,
              contacts:
                inputContacts.map(contact => ({
                  __typename: 'Contact',
                  ...contact
                })) || []
            }
          }
        }
      });
      resetForm();
      onClose();
      setIsSubmitting(false);
    },
    [onClose, projectId, setIsSubmitting, updateProjectContacts]
  );

  return { onSubmit };
};

export default useUpdateProjectContacts;
