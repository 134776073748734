import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import ArrowUpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownIcon from '@material-ui/icons/ArrowDownwardSharp';
import { ScoreComputeStatus } from '~/types';
import {
  AttributeMatchIcon,
  AttributeNotMatchIcon,
  AttributePartialMatchIcon
} from '~/modules/common/components/Icons';
import { MoneyValue } from '~/modules/common/components';
import propTypes from '~/modules/resourcing/components/ResourceAssignmentDialog/propTypes';
import useStyles from './useStyles';

const ScoreDimensions = {
  role: 'urn:replicon:resource-score-dimension:role',
  location: 'urn:replicon:resource-score-dimension:location',
  division: 'urn:replicon:resource-score-dimension:division',
  costCenter: 'urn:replicon:resource-score-dimension:cost-center',
  department: 'urn:replicon:resource-score-dimension:department-group',
  serviceCenter: 'urn:replicon:resource-score-dimension:service-center',
  employeeType: 'urn:replicon:resource-score-dimension:employeeType-group',
  skill: 'urn:replicon:resource-score-dimension:skill',
  tag: 'urn:replicon:resource-score-dimension:tag',
  resourcePool: 'urn:replicon:resource-score-dimension:resource-pool'
};

const scoreToResourceMap = {
  [ScoreDimensions.role]: 'score.role',
  [ScoreDimensions.location]: 'score.location',
  [ScoreDimensions.division]: 'score.division',
  [ScoreDimensions.costCenter]: 'score.costCenter',
  [ScoreDimensions.department]: 'score.department',
  [ScoreDimensions.serviceCenter]: 'score.serviceCenter',
  [ScoreDimensions.employeeType]: 'score.employeeType',
  [ScoreDimensions.skill]: 'score.skill',
  [ScoreDimensions.tag]: 'score.tag',
  [ScoreDimensions.resourcePool]: 'score.resourcePool'
};

const computeStatusToTitleMap = {
  [ScoreComputeStatus.Failed]: 'score.errorCalculating',
  [ScoreComputeStatus.InProgress]: 'score.pleaseWait',
  [ScoreComputeStatus.NotStarted]: 'score.pleaseWait',
  [ScoreComputeStatus.Completed]: 'score.notComputed'
};

const computeStatusToContentMap = {
  [ScoreComputeStatus.Failed]: 'score.somethingWrong',
  [ScoreComputeStatus.InProgress]: 'score.notFinishedCalculating',
  [ScoreComputeStatus.NotStarted]: 'score.notFinishedCalculating',
  [ScoreComputeStatus.Completed]: 'score.wasNotComputed'
};

const ResourceScoreTooltipContent = ({
  score,
  matchingDimensions,
  unMatchedDimensions,
  matchSkillsMetadata,
  matchResourcePoolsMetadata,
  matchTagsMetadata,
  scoreComputeStatus,
  requestedHours,
  requestedCost,
  totalCost,
  isViewCostEnabled
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const { matchingSkillsCount = 0, requestedSkillsCount = 0 } =
    matchSkillsMetadata || {};

  const { matchingResourcePoolsCount = 0, requestedResourcePoolsCount = 0 } =
    matchResourcePoolsMetadata || {};

  const { matchingTagsCount = 0, requestedTagsCount = 0 } =
    matchTagsMetadata || {};

  const isSkillsCompleteMatch = matchingSkillsCount === requestedSkillsCount;
  const isTagsCompleteMatch = matchingTagsCount === requestedTagsCount;

  const isResourcePoolsCompleteMatch =
    matchingResourcePoolsCount === requestedResourcePoolsCount;

  const scoreMissing = typeof score !== 'number';

  if (scoreMissing) {
    return (
      <>
        <div className={classes.tooltipTitle}>
          {formatMessage({
            id:
              computeStatusToTitleMap[
                scoreComputeStatus || ScoreComputeStatus.Failed
              ]
          })}
        </div>
        {formatMessage({
          id:
            computeStatusToContentMap[
              scoreComputeStatus || ScoreComputeStatus.Failed
            ]
        })}
      </>
    );
  }

  const matchedDimensions = (matchingDimensions || []).filter(
    m =>
      (isSkillsCompleteMatch || m !== ScoreDimensions.skill) &&
      (isTagsCompleteMatch || m !== ScoreDimensions.tag) &&
      (isResourcePoolsCompleteMatch || m !== ScoreDimensions.resourcePool)
  );

  const costDiffAmount = requestedHours
    ? totalCost?.amount / requestedHours - requestedCost?.amount
    : 0;
  const showCostDifference =
    isViewCostEnabled &&
    totalCost?.currency?.uri === requestedCost?.currency?.id;

  return (
    <>
      <div className={classes.tooltipTitle}>
        {formatMessage({ id: 'score.matchScore' })}
        {Math.round(score)}
      </div>

      <ul className={classes.matchList}>
        {matchedDimensions.map(matchingDimension => {
          const resourceLabel = formatMessage({
            id: scoreToResourceMap[matchingDimension]
          });

          return (
            <li key={matchingDimension} className={classes.matchItem}>
              <AttributeMatchIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconMatch
                )}
              />
              <span className={classes.matchLabel}>{resourceLabel}</span>
            </li>
          );
        })}
        {Boolean(totalCost && showCostDifference) && (
          <li key="cost" className={classes.matchItem}>
            {costDiffAmount > 0 ? (
              <ArrowUpIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconNotMatch
                )}
              />
            ) : costDiffAmount < 0 ? (
              <ArrowDownIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconMatch
                )}
              />
            ) : (
              <AttributeMatchIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconMatch
                )}
              />
            )}
            <p>
              <span className={classes.matchLabel}>
                {formatMessage({ id: 'score.cost' })}
              </span>
              {costDiffAmount > 0 ? (
                <span> + </span>
              ) : costDiffAmount < 0 ? (
                <span> - </span>
              ) : null}
              {Math.abs(costDiffAmount) !== 0 && (
                <MoneyValue
                  money={{
                    amount: Math.abs(costDiffAmount),
                    currency: {
                      id: totalCost?.currency.uri || requestedCost?.currency.id,
                      displayText:
                        totalCost?.currency.symbol ||
                        requestedCost?.currency.displayText
                    }
                  }}
                />
              )}
            </p>
          </li>
        )}

        {!isSkillsCompleteMatch &&
          (matchingDimensions || []).includes(ScoreDimensions.skill) && (
            <li className={classes.matchItem}>
              <AttributePartialMatchIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconPartialMatch
                )}
              />
              <span className={classes.matchLabel}>
                {formatMessage({
                  id: 'score.skill'
                })}
                <span className={classes.matchDetails}>
                  ({matchingSkillsCount}/{requestedSkillsCount})
                </span>
              </span>
            </li>
          )}
        {!isResourcePoolsCompleteMatch &&
          (matchingDimensions || []).includes(ScoreDimensions.resourcePool) && (
            <li className={classes.matchItem}>
              <AttributePartialMatchIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconPartialMatch
                )}
              />
              <span className={classes.matchLabel}>
                {formatMessage({
                  id: 'score.resourcePool'
                })}
                <span className={classes.matchDetails}>
                  ({matchingResourcePoolsCount}/{requestedResourcePoolsCount})
                </span>
              </span>
            </li>
          )}
        {!isTagsCompleteMatch &&
          (matchingDimensions || []).includes(ScoreDimensions.tag) && (
            <li className={classes.matchItem}>
              <AttributePartialMatchIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconPartialMatch
                )}
              />
              <span className={classes.matchLabel}>
                {formatMessage({
                  id: 'score.tag'
                })}
                <span className={classes.matchDetails}>
                  ({matchingTagsCount}/{requestedTagsCount})
                </span>
              </span>
            </li>
          )}
        {(unMatchedDimensions || []).map(unmatchedDimension => {
          const resourceLabel = formatMessage({
            id: scoreToResourceMap[unmatchedDimension]
          });

          return (
            <li key={unmatchedDimension} className={classes.matchItem}>
              <AttributeNotMatchIcon
                className={classNames(
                  classes.matchIcon,
                  classes.matchIconNotMatch
                )}
              />
              <span className={classes.matchLabel}>{resourceLabel}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

ResourceScoreTooltipContent.propTypes = {
  score: PropTypes.number,
  matchingDimensions: PropTypes.array,
  unMatchedDimensions: PropTypes.array,
  matchSkillsMetadata: PropTypes.object,
  matchResourcePoolsMetadata: PropTypes.object,
  matchTagsMetadata: PropTypes.object,
  scoreComputeStatus: propTypes.scoreComputeStatus,
  requestedHours: PropTypes.number,
  requestedCost: PropTypes.object,
  totalCost: PropTypes.object,
  isViewCostEnabled: PropTypes.bool.isRequired
};

export default ResourceScoreTooltipContent;
