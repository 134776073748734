import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  expenseCode: {
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'break-spaces'
  },
  noExpenseCode: {
    color: alpha(theme.palette.common.black, 0.54),
    fontStyle: 'italic',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const ExpenseCodeFormatter = ({ field, record }) => {
  const classes = useStyles();

  return record[field] ? (
    <Typography className={classes.expenseCode} component="div">
      {record[field]}
    </Typography>
  ) : (
    <Typography className={classes.noExpenseCode} component="div">
      <FormattedMessage id="addLineItemDialog.noExpenseCode" />
    </Typography>
  );
};

ExpenseCodeFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default ExpenseCodeFormatter;
