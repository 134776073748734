import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavigationBreadcrumb } from '~/modules/common/components';
import { useProjectReference } from '~/modules/projects/project/ProjectPage/hooks';
import {
  slugNotFound,
  LinearQueryProgress
} from '~/modules/common/nonOptimalStates';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';

export const paths = [
  {
    to: '/projects',
    title: <FormattedMessage id="routes.projects" />
  }
];

export const ProjectNavigationBreadcrumb = () => {
  const {
    loading: projectRefLoading,
    error: projectRefError,
    projectName
  } = useProjectReference();

  if (projectRefLoading) return <LinearQueryProgress />;
  if (projectRefError) {
    return slugNotFound({ data: { error: projectRefError } }) ? (
      <RedirectToNotFound />
    ) : (
      <RedirectToUnhandledError />
    );
  }

  return <NavigationBreadcrumb paths={paths} title={projectName} />;
};

export default ProjectNavigationBreadcrumb;
