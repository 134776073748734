import { getSeriesDataMap } from '~/modules/common/enhancers';

export default ({
  periods,
  scale,
  sectionData: { billingItemBillings, adhocInvoiceItemBillings }
}) => {
  const billingItemBillingsSeriesMap = getSeriesDataMap({
    scale,
    rowData: billingItemBillings
  }).seriesDataMap;
  const adhocBillingsSeriesMap = getSeriesDataMap({
    scale,
    rowData: adhocInvoiceItemBillings
  }).seriesDataMap;

  const dataPointMap = periods.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.key]:
        (billingItemBillingsSeriesMap[current.key] || 0) +
        (adhocBillingsSeriesMap[current.key] || 0)
    }),
    {}
  );

  return {
    totalBilledSeriesDataMap: {
      ...dataPointMap,
      total:
        (billingItemBillingsSeriesMap.total || 0) +
        (adhocBillingsSeriesMap.total || 0),
      currencySymbol: billingItemBillingsSeriesMap.currencySymbol
    }
  };
};
