import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECT_DELETE_BATCH_RESULTS = gql`
  query GetProjectDeleteBatchResults($batchId: String!) {
    projectDeleteBatchResults(batchId: $batchId) {
      deleted {
        id
      }
      archived {
        id
      }
      errors {
        displayText
        project {
          id
        }
      }
    }
  }
`;

export const useGetDeleteProjectBatchResults = ({ batchId }) => {
  const { loading, error, data } = useQuery(GET_PROJECT_DELETE_BATCH_RESULTS, {
    fetchPolicy: 'network-only',
    variables: {
      batchId
    },
    errorPolicy: 'all'
  });

  return {
    loading,
    deleted: data?.projectDeleteBatchResults?.deleted || [],
    archived: data?.projectDeleteBatchResults?.archived || [],
    errors: data?.projectDeleteBatchResults?.errors || [],
    error
  };
};

export default useGetDeleteProjectBatchResults;
