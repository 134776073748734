import React, { useEffect } from 'react';
import { gantt } from '@replicon/dhtmlx-gantt';
import { Info } from 'luxon';
import { renderToStaticMarkup } from 'react-dom/server';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { GanttTaskBarText } from '../components/GanttTaskBarText';
import { GanttMissingDateBar } from '../components';
import { GanttTaskBarText2 } from '../components/GanttTaskBarText/GanttTaskBarText';
import { customTooltipKeys } from './useGanttConfiguration';

export const configureGanttTooltip = ({
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  gantt.ext.tooltips.detach(
    `[${gantt.config.task_attribute}]:not(.gantt_task_row)`
  );

  if (!isRolledUpTaskEstimateCalculationMethodEnabled) {
    gantt.ext.tooltips.tooltipFor({
      selector: `[${gantt.config.task_attribute}] .gantt_task_content`,
      html: event => {
        if (gantt.config.touch && !gantt.config.touch_tooltip) return null;

        const targetTaskId = gantt.locate(event);

        if (gantt.isTaskExists(targetTaskId)) {
          const task = gantt.getTask(targetTaskId);

          return gantt.templates.tooltip_text(
            task.start_date,
            task.end_date,
            task
          );
        }

        return null;
      }
    });
  }

  gantt.ext.tooltips.tooltipFor({
    selector: `.${customTooltipKeys.taskAssignedRole}`,
    html: event => {
      if (gantt.config.touch && !gantt.config.touch_tooltip) return null;

      const targetTaskId = gantt.locate(event);

      if (gantt.isTaskExists(targetTaskId)) {
        const task = gantt.getTask(targetTaskId);

        return task.assignedRole ? task.assignedRole.displayText : null;
      }

      return null;
    }
  });

  gantt.ext.tooltips.tooltipFor({
    selector: `.${customTooltipKeys.taskOwner}`,
    html: event => {
      if (gantt.config.touch && !gantt.config.touch_tooltip) return null;

      const targetTaskId = gantt.locate(event);

      if (gantt.isTaskExists(targetTaskId)) {
        const task = gantt.getTask(targetTaskId);

        return task.assignee ? task.assignee.displayText : null;
      }

      return null;
    }
  });
};

export const configureGanttLocale = ({ intl, me }) => {
  const {
    locale: {
      language: { cultureCode }
    }
  } = me;

  const buildLabel = id => intl.formatMessage({ id: `gantt.${id}` });

  const longWeekdays = Info.weekdays('long', { locale: cultureCode });
  const dayFull = [longWeekdays[6], ...longWeekdays.slice(0, 6)];

  const shortWeekdays = Info.weekdays('short', { locale: cultureCode });
  const dayShort = [shortWeekdays[6], ...shortWeekdays.slice(0, 6)];

  const locale = {
    date: {
      month_full: Info.months('long', { locale: cultureCode }),
      month_short: Info.months('short', { locale: cultureCode }),
      day_full: dayFull,
      day_short: dayShort
    },
    labels: {
      new_task: buildLabel('new_task'),
      icon_save: buildLabel('icon_save'),
      icon_cancel: buildLabel('icon_cancel'),
      icon_details: buildLabel('icon_details'),
      icon_edit: buildLabel('icon_edit'),
      icon_delete: buildLabel('icon_delete'),
      confirm_deleting: buildLabel('confirm_deleting'),
      section_description: buildLabel('section_description'),
      section_time: buildLabel('section_time'),
      section_type: buildLabel('section_type'),
      column_wbs: buildLabel('column_wbs'),
      column_text: buildLabel('column_text'),
      column_start_date: buildLabel('column_start_date'),
      column_duration: buildLabel('column_duration'),
      link: buildLabel('link'),
      confirm_link_deleting: buildLabel('confirm_link_deleting'),
      link_start: buildLabel('link_start'),
      link_end: buildLabel('link_end'),
      type_task: buildLabel('type_task'),
      type_project: buildLabel('type_project'),
      type_milestone: buildLabel('type_milestone'),
      minutes: buildLabel('minutes'),
      hours: buildLabel('hours'),
      days: buildLabel('days'),
      weeks: buildLabel('weeks'),
      months: buildLabel('months'),
      years: buildLabel('years'),
      message_ok: buildLabel('message_ok'),
      message_cancel: buildLabel('message_cancel'),
      section_constraint: buildLabel('section_constraint'),
      constraint_type: buildLabel('constraint_type'),
      constraint_date: buildLabel('constraint_date'),
      asap: buildLabel('asap'),
      alap: buildLabel('alap'),
      snet: buildLabel('snet'),
      snlt: buildLabel('snlt'),
      fnet: buildLabel('fnet'),
      fnlt: buildLabel('fnlt'),
      mso: buildLabel('mso'),
      mfo: buildLabel('mfo'),
      resources_filter_placeholder: buildLabel('resources_filter_placeholder'),
      resources_filter_label: buildLabel('resources_filter_label')
    }
  };

  gantt.locale = locale;
};

export const useGanttConfigureEffect = ({
  me,
  intl,
  ganttScale,
  ganttContainerRef,
  ganttConfiguration,
  ganttTemplates,
  ganttData,
  setGanttRef,
  attachEvents,
  detachEvents,
  editable,
  canViewEstimate,
  canViewCostData,
  isRTL
}) => {
  useEffect(() => {
    gantt.config = {
      ...gantt.config,
      ...ganttConfiguration,
      drag_multiple: false
    };
    gantt.templates = {
      ...gantt.templates,
      ...ganttTemplates
    };

    attachEvents();

    const scrollState = gantt.getScrollState();

    const { isRolledUpTaskEstimateCalculationMethodEnabled } = me;

    if (isRolledUpTaskEstimateCalculationMethodEnabled) {
      // When show_unscheduled is false, it shows unscheduled tasks (Gantt bug)
      gantt.config.show_unscheduled = false;
    }

    gantt.init(ganttContainerRef.current);
    gantt.clearAll();
    gantt.parse(ganttData || { data: [] });
    gantt.createDatastore({
      name: 'loadingTasks'
    });

    gantt.scrollTo(scrollState.x, scrollState.y);

    ganttSetTaskBarText({
      me,
      intl,
      canViewEstimate,
      canViewCostData,
      isRTL
    });
    ganttSetMissingDateBar({ intl, me });
    configureGanttTooltip({ isRolledUpTaskEstimateCalculationMethodEnabled });
    configureGanttLocale({ intl, me });
    setGanttRef(gantt);

    return () => detachEvents();
  }, [
    ganttScale,
    editable,
    ganttConfiguration,
    ganttTemplates,
    attachEvents,
    ganttContainerRef,
    ganttData,
    intl,
    me,
    detachEvents,
    setGanttRef,
    canViewEstimate,
    canViewCostData,
    isRTL
  ]);
};

const ganttTaskBarBottomText = ({
  task,
  numberFormat,
  intl,
  sizes,
  taskHeight,
  canViewEstimate,
  canViewCostData,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  isPsaRmpTaskAllocation1Enabled
}) =>
  renderToStaticMarkup(
    isPsaRmpTaskAllocation1Enabled ? (
      <GanttTaskBarText2
        task={task}
        numberFormat={numberFormat}
        intl={intl}
        sizes={sizes}
        taskHeight={taskHeight}
        canViewEstimate={canViewEstimate}
        canViewCostData={canViewCostData}
        isRTL={isRTL}
        isPsaPpmCostEacEnhancementsEnabled={isPsaPpmCostEacEnhancementsEnabled}
        isPsaPpmCostEacEnhancements2Enabled={
          isPsaPpmCostEacEnhancements2Enabled
        }
      />
    ) : (
      <GanttTaskBarText
        task={task}
        numberFormat={numberFormat}
        intl={intl}
        sizes={sizes}
        taskHeight={taskHeight}
        canViewEstimate={canViewEstimate}
        canViewCostData={canViewCostData}
        isRTL={isRTL}
        isPsaPpmCostEacEnhancementsEnabled={isPsaPpmCostEacEnhancementsEnabled}
        isPsaPpmCostEacEnhancements2Enabled={
          isPsaPpmCostEacEnhancements2Enabled
        }
      />
    )
  );

const ganttSetTaskBarText = ({
  me,
  intl,
  canViewEstimate,
  canViewCostData,
  isRTL
}) => {
  const {
    featureFlags: {
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaPpmCostEacEnhancements2Enabled,
      isPsaRmpTaskAllocation1Enabled
    },
    isRolledUpTaskEstimateCalculationMethodEnabled
  } = me;

  if (isRolledUpTaskEstimateCalculationMethodEnabled) {
    gantt.addTaskLayer(task => {
      const sizes = gantt.getTaskPosition(task, task.start_date, task.end_date);
      const taskBarBottomText = document.createElement('div');

      taskBarBottomText.innerHTML = ganttTaskBarBottomText({
        task,
        numberFormat: getReactNumberFormatFromMe(me),
        intl,
        sizes,
        taskHeight: gantt.config.task_height,
        canViewEstimate,
        canViewCostData,
        isRTL,
        isPsaPpmCostEacEnhancementsEnabled,
        isPsaPpmCostEacEnhancements2Enabled,
        isPsaRmpTaskAllocation1Enabled
      });
      taskBarBottomText.className = 'gantt_task_bar_name_tooltip';
      taskBarBottomText.setAttribute('taskId', task.id);

      return taskBarBottomText;
    });
  }
};

const ganttMissingDateBar = ({ task, intl, hasChild }) =>
  renderToStaticMarkup(
    <div taskId={task.id} className="gantt_task_bar_name_tooltip">
      <GanttMissingDateBar task={task} intl={intl} hasChild={hasChild} />
    </div>
  );

const ganttSetMissingDateBar = ({ me, intl }) => {
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = me;

  if (isRolledUpTaskEstimateCalculationMethodEnabled) {
    gantt.templates.leftside_text = (start, end, task) => {
      if (!task.startDate && task.endDate) {
        const hasChild = gantt.hasChild(task.id);

        return ganttMissingDateBar({ task, intl, hasChild });
      }

      return null;
    };

    gantt.templates.rightside_text = (start, end, task) => {
      if (!task.endDate) {
        const hasChild = gantt.hasChild(task.id);

        return ganttMissingDateBar({ task, intl, hasChild });
      }

      return null;
    };
  }
};

export default useGanttConfigureEffect;
