import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const TAG_LIST_ITEM_QUERY = gql`
  query TagListItemSearch($tagId: String!) {
    tag(id: $tagId) {
      id
      name
    }
  }
`;

const useTag = tagId => {
  const result = useQuery(TAG_LIST_ITEM_QUERY, {
    fetchPolicy: 'network-only',
    variables: { tagId }
  });

  return result;
};

export default useTag;
