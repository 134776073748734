import { Dialog, makeStyles, Typography } from '@material-ui/core';
import { DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ResourceAllocationStatus } from '~/types';
import { ReleaseResourceRequestDialogTitle } from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/components';
import { useQuickAllocationProjectAllocationsByIds } from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import ReleaseAllocationsDialogContent from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/ReleaseAllocationsDialogContent';
import ReleaseAllocationsDialogLoadingContent from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/ReleaseAllocationsDialogLoadingContent';

const useStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  value: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary
  }
}));

export const QaReleaseAllocationsDialog = ({
  allocatedProject,
  canEditTask,
  onCancelClick,
  onReleaseResourceAllocation,
  onReleaseSuccess,
  open,
  selectedAllocationId,
  user
}) => {
  const classes = useStyles();
  const allocationIds = allocatedProject.roles.reduce(
    (ids, role) => [
      ...ids,
      ...role.resourceAllocationReference.map(ref => ref.id)
    ],
    []
  );
  const {
    resourceAllocations,
    loadingAllocations
  } = useQuickAllocationProjectAllocationsByIds({
    allocationIds
  });

  const committedResourceAllocations = useMemo(
    () =>
      resourceAllocations.filter(
        allocation =>
          allocation.allocationStatus === ResourceAllocationStatus.Committed
      ),
    [resourceAllocations]
  );

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="release-allocations-dialog-title"
      aria-describedby="release-allocations-dialog-content"
    >
      <DialogTitle id="release-allocations-dialog-title">
        <ReleaseResourceRequestDialogTitle
          role={user}
          onClose={onCancelClick}
          headerId="releaseResourceRequestDialog.releaseAllocationsTitle"
        />
        <Typography className={classes.title}>
          <FormattedMessage
            id="releaseResourceRequestDialog.projectTitleSubheader"
            values={{
              project: `${allocatedProject.project.displayText}`,
              span: chunks => <span className={classes.value}>{chunks}</span>
            }}
          />
        </Typography>
      </DialogTitle>
      {loadingAllocations ? (
        <ReleaseAllocationsDialogLoadingContent onCancelClick={onCancelClick} />
      ) : (
        <ReleaseAllocationsDialogContent
          allocatedUser={{ user }}
          allocationIds={allocationIds}
          allocations={committedResourceAllocations}
          onReleaseSuccess={onReleaseSuccess}
          onReleaseResourceAllocations={onReleaseResourceAllocation}
          projectUri={allocatedProject.project.id}
          onCancelClick={onCancelClick}
          canEditTask={canEditTask}
          selectedAllocationId={selectedAllocationId}
        />
      )}
    </Dialog>
  );
};

QaReleaseAllocationsDialog.propTypes = {
  allocatedProject: PropTypes.object,
  canEditTask: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onReleaseResourceAllocation: PropTypes.func,
  onReleaseSuccess: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedAllocationId: PropTypes.string,
  user: PropTypes.object
};

export default QaReleaseAllocationsDialog;
