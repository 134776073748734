import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { FormattedMessage } from 'react-intl';

import { NoDataItem } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import TagsEditFormRow from './TagsEditFormRow';

const TagFieldArrayRenderer = ({
  arrayHelpers,
  selectedTags,
  tag,
  errors,
  index
}) => {
  const onTagChanged = useCallback(
    newTag => arrayHelpers.replace(index, newTag),
    [arrayHelpers, index]
  );

  const onTagRemoved = useCallback(() => arrayHelpers.remove(index), [
    arrayHelpers,
    index
  ]);

  const isMobile = useIsBreakpointDown('sm');

  return (
    <TagsEditFormRow
      tag={tag}
      selectedTags={selectedTags}
      showDropdownLabels={isMobile || index === 0}
      errors={
        errors && errors.tags && errors.tags.length
          ? errors.tags[index]
          : undefined
      }
      onTagChanged={onTagChanged}
      onTagRemoved={onTagRemoved}
      rowIndex={index}
    />
  );
};

TagFieldArrayRenderer.propTypes = {
  arrayHelpers: PropTypes.object,
  tag: PropTypes.object,
  selectedTags: PropTypes.array,
  errors: PropTypes.object,
  index: PropTypes.number
};

const TagsEditForm = ({ values, errors }) => {
  const fieldArrayRenderer = useCallback(
    arrayHelpers =>
      values.tags.map((tag, index) => (
        <TagFieldArrayRenderer
          arrayHelpers={arrayHelpers}
          tag={tag}
          selectedTags={values.tags}
          errors={errors}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={`tag_${index}`}
        />
      )),
    [errors, values]
  );

  if (!values.tags.length) {
    return (
      <NoDataItem>
        <FormattedMessage id="tags.noTagsEdit" />
      </NoDataItem>
    );
  }

  return <FieldArray name="tags" render={fieldArrayRenderer} />;
};

TagsEditForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object
};

export default TagsEditForm;
