import { gql } from 'graphql-tag';
import { SpecificResourceRequestFragmentDoc } from '~/types';

export const resourceRequestSubmittedSubscription = gql`
  subscription ResourceRequestSubmitted {
    resourceRequestSubmitted {
      ...SpecificResourceRequest
    }
  }
  ${SpecificResourceRequestFragmentDoc}
`;
