import { object, string } from 'yup';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const enterNameErrorMsg = (
  <FormattedMessage id="addRoleDialog.enterNameErrorMsg" />
);
const descriptionExceedsMaxLength = (
  <FormattedMessage id="addRoleDialog.descriptionExceedsMaxLength" />
);
const nameExceedsMaxLength = (
  <FormattedMessage id="addRoleDialog.nameExceedsMaxLength" />
);

const useValidationSchema = () =>
  object().shape({
    name: string()
      .required(enterNameErrorMsg)
      .max(50, nameExceedsMaxLength),
    description: string().max(255, descriptionExceedsMaxLength)
  });

export default useValidationSchema;
