import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  omitForbiddenFields,
  buildCreateTaskResourceUserAllocationOptimisticResponse,
  buildUpdateTaskResourceUserAllocationOptimisticResponse
} from '~/modules/common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import { TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY } from '~/modules/common/components/TaskDrawer/common/hooks/useGetTaskResourceUserAllocationsSummaryForUser';
import { BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY } from '~/modules/resourcing/common/hooks/useBulkGetTaskResourceUserAllocations';
import { calculateTaskAllocationScheduleRulesOnDrag } from '~/modules/resourcing/common/hooks/useTaskAllocationDragIndicators';
import { useUserAllocationsSummaryContext } from '../components/ResourceAllocationChartRow/UserAllocationsSummaryContext';

export const updateCacheOnCreateTaskAllocation = ({
  userId,
  projectId,
  taskId
}) => (proxy, { data }) => {
  const {
    createTaskResourceUserAllocation: { taskResourceUserAllocation }
  } = data;

  proxy.writeQuery({
    query: BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY,
    variables: {
      filter: {
        userUri: userId,
        projectUri: projectId,
        taskUris: [taskId]
      }
    },
    data: { taskResourceUserAllocationsForUser: [taskResourceUserAllocation] }
  });
};

export const filterScheduleRules = scheduleRules =>
  scheduleRules.filter(rule => rule.do.setHours > 0);

export const useTaskAssignmentTimelineEditorChangeHandlers = ({
  updateTaskResourceUserAllocation,
  createTaskResourceUserAllocation,
  taskResourceUserAllocation,
  taskResourceEstimate,
  projectId,
  userId,
  openReleaseTaskDialogsContainer
}) => {
  const { tenantSlug } = useMeContext();

  const {
    userTaskAllocationsSummaryScheduleRules,
    resourceAllocationScheduleRules
  } = useUserAllocationsSummaryContext();
  const onPeriodClose = useCallback(
    alloc => {
      const filteredScheduleRules = filterScheduleRules(alloc.scheduleRules);
      const newTaskAllocationId =
        !taskResourceUserAllocation &&
        `urn:replicon-tenant:${tenantSlug}:psa-task-allocation:${uuidv4()}`;

      if (taskResourceUserAllocation && filteredScheduleRules.length === 0) {
        openReleaseTaskDialogsContainer();
      } else {
        const refetchQueries = [
          {
            query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
            variables: {
              userId,
              filter: {
                projectId
              }
            }
          }
        ];

        taskResourceUserAllocation
          ? updateTaskResourceUserAllocation({
              variables: {
                input: omitForbiddenFields({
                  taskAllocationId: alloc.id,
                  taskUri: alloc.taskUri,
                  projectUri: alloc.projectUri,
                  scheduleRules: alloc.scheduleRules
                })
              },
              optimisticResponse: buildUpdateTaskResourceUserAllocationOptimisticResponse(
                {
                  id: alloc.id,
                  taskResourceEstimate,
                  projectId,
                  userId,
                  scheduleRules: alloc.scheduleRules
                }
              ),
              refetchQueries
            })
          : createTaskResourceUserAllocation({
              variables: {
                input: omitForbiddenFields({
                  taskAllocationId: newTaskAllocationId,
                  taskUri: taskResourceEstimate.task.id,
                  projectUri: projectId,
                  allocationUserUri: userId,
                  scheduleRules: alloc.scheduleRules,
                  roleUri: taskResourceEstimate.projectRole?.id
                })
              },
              optimisticResponse: buildCreateTaskResourceUserAllocationOptimisticResponse(
                {
                  id: newTaskAllocationId,
                  taskResourceEstimate,
                  projectId,
                  userId,
                  scheduleRules: alloc.scheduleRules
                }
              ),
              update: updateCacheOnCreateTaskAllocation({
                userId,
                projectId,
                taskId: taskResourceEstimate.task.id
              }),
              refetchQueries
            });
      }
    },
    [
      createTaskResourceUserAllocation,
      openReleaseTaskDialogsContainer,
      projectId,
      taskResourceEstimate,
      taskResourceUserAllocation,
      tenantSlug,
      updateTaskResourceUserAllocation,
      userId
    ]
  );

  const onAllocationChange = useCallback(
    ({ startDate, endDate, taskAllocation }) => {
      const taskAllocationScheduleRules = calculateTaskAllocationScheduleRulesOnDrag(
        {
          startDate,
          endDate,
          taskAllocation,
          resourceAllocationScheduleRules,
          userTaskAllocationsSummaryScheduleRules
        }
      );

      onPeriodClose({
        ...taskResourceUserAllocation,
        scheduleRules: taskAllocationScheduleRules
      });
    },
    [
      onPeriodClose,
      resourceAllocationScheduleRules,
      taskResourceUserAllocation,
      userTaskAllocationsSummaryScheduleRules
    ]
  );

  return { onPeriodClose, onAllocationChange };
};

export default useTaskAssignmentTimelineEditorChangeHandlers;
