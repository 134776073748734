import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Chip,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircleSharp';
import { useMenuState } from '~/modules/common/hooks';
import { DEFAULT_RATE } from '~/modules/rateCard/advancedRateCard/components/hooks/enum';
import { useAdvancedRateDimensionsGroupHook } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  label: {
    paddingRight: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  option: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}));

export const AdvancedRateDimensionsGroup = ({
  projectDimensions,
  availableRateTableDimensions,
  formOnChange: { onAddDimension, onRemoveDimension },
  projectRates,
  loadingMore
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const ariaLabel = formatMessage({
    id: 'advancedRateCard.defineRatesBy'
  });
  const {
    options,
    onMenuItemClick,
    onDeleteClick
  } = useAdvancedRateDimensionsGroupHook({
    onAddDimension,
    onMenuClose,
    onRemoveDimension,
    projectDimensions,
    availableRateTableDimensions
  });

  const disableProjectRateDimensions = useCallback(
    index => {
      if (index !== projectDimensions.length - 1) {
        return true;
      }

      const dimensionId = projectDimensions[index].id;

      return projectRates.some(e => e[dimensionId]?.value !== DEFAULT_RATE);
    },
    [projectDimensions, projectRates]
  );

  const isNonDefaultRates = projectRates.some(r =>
    projectDimensions.some(({ id }) => r[id]?.value !== DEFAULT_RATE)
  );

  return (
    <div className={classes.root} data-qe-id="DimensionGroup">
      <div className={classes.label}>
        <Typography component="span" variant="body2">
          <FormattedMessage id="advancedRateCard.defineRatesBy" />
        </Typography>
      </div>
      <div className={classes.option}>
        {projectDimensions.map((d, index) => (
          <Chip
            key={d.id}
            size="small"
            label={d.displayText}
            onDelete={onDeleteClick(d)}
            disabled={loadingMore || disableProjectRateDimensions(index)}
            data-qe-id={d.displayText}
          />
        ))}
        {options.length > 0 ? (
          <IconButton
            aria-label={ariaLabel}
            onClick={onMenuClick}
            disabled={loadingMore || isNonDefaultRates}
            data-qe-id="AddDimension"
          >
            <AddCircleIcon />
          </IconButton>
        ) : null}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onMenuClose}
        >
          {options.map(d => {
            return (
              <MenuItem key={d.id} value={d.id} onClick={onMenuItemClick(d)}>
                <ListItemText primary={d.displayText} />
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

AdvancedRateDimensionsGroup.propTypes = {
  projectDimensions: PropTypes.array,
  availableRateTableDimensions: PropTypes.array,
  formOnChange: PropTypes.object,
  projectRates: PropTypes.array,
  loadingMore: PropTypes.bool
};

export default AdvancedRateDimensionsGroup;
