import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    fontWeight: 500,
    fontSize: theme.typography.body2.fontSize,
    verticalAlign: 'middle',
    '& svg': {
      fontSize: theme.typography.h6.fontSize
    }
  },
  iconButton: {
    padding: theme.spacing(0)
  }
}));

const ViewDetailsLink = ({ count, handleViewDetails }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {count && <span className={classes.icon}>{count}</span>}
      <IconButton
        className={classes.iconButton}
        onClick={handleViewDetails}
        data-qe-id="ViewDetails"
      >
        <Typography variant="body2" noWrap>
          <Link>
            <FormattedMessage id="projectBasicInfoCard.contactInfo.actions.viewDetails" />
          </Link>
        </Typography>
      </IconButton>
    </div>
  );
};

ViewDetailsLink.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleViewDetails: PropTypes.func
};

export default ViewDetailsLink;
