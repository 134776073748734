import React from 'react';
import { FormattedMessage } from 'react-intl';
import { hasPermission } from '~/modules/common/permissions';
import getColumnGroups from './resourceAllocationDialogColumns';
import { AssignmentDialogColumns } from './constants';

export const defaultEmptyOption = {
  field: '',
  value: {}
};

export const getDefaultColumns = ({ me: { permissionsMap } }) => {
  const canViewRequestedCost =
    permissionsMap['urn:replicon:project-action:view-cost-data'] ||
    permissionsMap['urn:replicon:user-action:view-cost-data'];

  return [
    AssignmentDialogColumns.DisplayText,
    AssignmentDialogColumns.Availability,
    canViewRequestedCost
      ? AssignmentDialogColumns.SmartMatchIncludingCost
      : AssignmentDialogColumns.SmartMatchExcludingCost,
    AssignmentDialogColumns.CostRate
  ];
};

export const buildAdditionalDialogColumns = ({
  me: { permissionsMap, featureFlags }
}) => {
  const isAvailabilityEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-availability',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-availability',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewCostEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-hourly-cost',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-hourly-cost',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  return {
    ...(isAvailabilityEnabled
      ? {
          availability: {
            field: AssignmentDialogColumns.Availability,
            value: (
              <FormattedMessage id="resourceAssignmentDialog.availability" />
            ),
            required: true
          }
        }
      : {}),
    ...(isViewCostEnabled
      ? {
          costRate: {
            field: AssignmentDialogColumns.CostRate,
            value: <FormattedMessage id="resourceAssignmentDialog.costRate" />
          }
        }
      : {})
  };
};

export const buildSmartMatchColumns = ({ me: { permissionsMap } }) => {
  const canViewRequestedCost =
    permissionsMap['urn:replicon:project-action:view-cost-data'] ||
    permissionsMap['urn:replicon:user-action:view-cost-data'];

  return {
    ...(canViewRequestedCost
      ? {
          smartMatchIncludingCost: {
            field: AssignmentDialogColumns.SmartMatchIncludingCost,
            value: (
              <FormattedMessage id="resourceAllocationUserChartDialog.smartMatch" />
            )
          }
        }
      : {}),
    smartMatchExcludingCost: {
      field: AssignmentDialogColumns.SmartMatchExcludingCost,
      value: canViewRequestedCost ? (
        <FormattedMessage id="resourceAllocationUserChartDialog.smartMatchExcludingCost" />
      ) : (
        <FormattedMessage id="resourceAllocationUserChartDialog.smartMatch" />
      )
    }
  };
};

export const getDefaultAssignmentDialogColumnsUnfiltered = ({ me }) => {
  const displayText = {
    field: 'displayText',
    value: <FormattedMessage id="resourceAssignmentDialog.name" />,
    required: true,
    fixed: true,
    includesNavigationComponent: true
  };

  const defaultColumns = buildAdditionalDialogColumns({
    me
  });

  const smartMatchColumns = buildSmartMatchColumns({ me });

  const defaultColumnObjects = {
    displayText,
    ...defaultColumns,
    ...smartMatchColumns
  };

  return getDefaultColumns({ me })
    .map(column => defaultColumnObjects[column])
    .map(option => option || defaultEmptyOption);
};

export const getDefaultAssignmentDialogColumns = (...args) => {
  return getDefaultAssignmentDialogColumnsUnfiltered(...args).filter(
    column => column.field !== ''
  );
};

export const getAssignmentDialogColumnOptions = ({
  me,
  columnPermissions,
  customFieldDefinitions = [],
  tagFieldData = []
}) => {
  const resourceColumnGroup = getColumnGroups({
    me,
    customFieldDefinitions,
    tagFieldData
  });

  const resourceColumns = resourceColumnGroup || [];

  const dialogColumns = buildAdditionalDialogColumns({
    me
  });

  const dialogColumnArray = Object.values(dialogColumns);

  return [...dialogColumnArray, ...resourceColumns].filter(
    columnOption =>
      columnOption?.field &&
      (columnPermissions[columnOption.field] ||
        (columnOption?.category?.id &&
          columnPermissions[columnOption.category.id]))
  );
};

export const getSmartMatchColumnOptions = ({ me }) => {
  const smartMatchColumns = buildSmartMatchColumns({ me });

  return Object.values(smartMatchColumns);
};

export const getAssignmentDialogColumns = ({
  me,
  customFieldDefinitions = [],
  tagFieldData = []
}) => {
  const resourceColumnGroup = getColumnGroups({
    me,
    customFieldDefinitions,
    tagFieldData
  });

  const resourceColumns = resourceColumnGroup || [];

  const defaultColumns = buildAdditionalDialogColumns({
    me
  });

  const defaultColumnArray = Object.values(defaultColumns);
  const smartMatchArray = getSmartMatchColumnOptions({ me });

  return [
    {
      id: 'assignmentDialogColumns',
      columns: [...smartMatchArray, ...defaultColumnArray, ...resourceColumns]
    }
  ];
};

export const getParsedDialogColumns = ({ columns, allDropdownOptions }) => {
  if (!columns || columns.length === 0) return [];

  return [...columns].map(option => {
    return (
      allDropdownOptions.find(x => x.field === option) || defaultEmptyOption
    );
  });
};
