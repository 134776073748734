import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Typography, ButtonBase, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRedirectToDetails } from '../../hooks/useRedirectToDetails';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    alignItems: 'flex-start',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  skill: {
    ...theme.typography.body2,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary
  },
  category: {
    ...theme.typography.caption,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

export const SkillField = ({ record: { category, skill } }) => {
  const classes = useStyles();
  const history = useHistory();

  const onRowClick = useRedirectToDetails({
    history,
    skill: skill && skill.id,
    label: skill && skill.displayText
  });

  return (
    <ButtonBase
      className={classes.container}
      component={Link}
      onClick={onRowClick}
    >
      <Typography className={classes.category}>
        {category.displayText}
      </Typography>
      <Typography className={classes.skill}>{skill.displayText}</Typography>
    </ButtonBase>
  );
};

SkillField.propTypes = {
  record: PropTypes.shape({
    category: PropTypes.object.isRequired,
    skill: PropTypes.object.isRequired
  }).isRequired
};

export default SkillField;
