import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ChartHeader } from '~/modules/common/charts/timeline/components';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import ResourceRequestChartHeaderLeftItem from './ResourceRequestChartHeaderLeftItem';

const useChartStyles = makeStyles(theme => ({
  root: {
    top: theme.spacing(18) + 48,
    zIndex: 21
  }
}));

const useHeaderTimeStyles = makeStyles(theme => ({
  timeBlock: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    alignContent: 'center'
  }
}));

const useStyles = makeStyles(theme => ({
  item: {
    flexBasis: theme.spacing(11),
    flexGrow: 1,
    flexShrink: 1,
    ...theme.typography.caption,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    textAlign: 'right'
  },
  total: {
    width: theme.spacing(10),
    flexBasis: theme.spacing(10),
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0.5, 0),
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    textAlign: 'right'
  },
  allocated: {
    fontWeight: theme.typography.fontWeightBold
  },
  padding: {
    color: 'unset',
    ...theme.typography.subtitle2,
    padding: theme.spacing(0, 0.5, 0, 0)
  }
}));

export const ResourceRequestChartHeader = ({
  scale,
  chartDates,
  leftComponentWidth,
  isEditSwitchEnabled,
  sort,
  onSortChange
}) => {
  const classes = useStyles();
  const headerTimeClasses = useHeaderTimeStyles();
  const chartClasses = useChartStyles();

  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  const leftComponent = useMemo(
    () => (
      <ResourceRequestChartHeaderLeftItem
        isEditSwitchEnabled={isEditSwitchEnabled}
        sort={sort}
        onSortChange={onSortChange}
        isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
      />
    ),
    [isEditSwitchEnabled, sort, onSortChange, isPsaRmpTaskAllocation1Enabled]
  );

  const rightHeader = (
    <div
      className={classNames(classes.total, {
        [classes.padding]: isPsaRmpTaskAllocation1Enabled
      })}
    >
      <FormattedMessage id="resourceRequestChartHeader.total" />
    </div>
  );

  return (
    <ChartHeader
      classes={chartClasses}
      headerTimeClasses={
        isPsaRmpTaskAllocation1Enabled ? headerTimeClasses : null
      }
      leftComponentWidth={leftComponentWidth}
      leftComponent={leftComponent}
      scale={scale}
      chartDates={chartDates}
      rightComponent={rightHeader}
    />
  );
};

ResourceRequestChartHeader.propTypes = {
  chartDates: PropTypes.array.isRequired,
  scale: PropTypes.string.isRequired,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  sort: PropTypes.object,
  onSortChange: PropTypes.func
};

export default ResourceRequestChartHeader;
