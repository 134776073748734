import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useEffect } from 'react';
import { isoToReplicon } from '~/util/date';

export const billQuery = gql`
  query getBillDetails(
    $id: String!
    $isPsaPswatBillingDraftSubStatusEnabled: Boolean = false
  ) {
    getBill(id: $id) {
      id
      displayId
      billingAddress
      description
      client {
        id
        displayText
      }
      currency {
        id
        symbol
        displayText
      }
      taxProfile {
        id
        displayText
      }
      invoiceTemplate {
        id
        displayText
      }
      balanceStatus
      issueDate: issueDate2
      paymentTerms
      poNumber
      period {
        startDate: startDate2
        endDate: endDate2
      }
      summarizeColumn
      dueDate: dueDate2
      syncStatus
      voidBillDescription
      billStatus
      notesForCustomer
      internalNotes
      showComments
      draftSubStatusUri @include(if: $isPsaPswatBillingDraftSubStatusEnabled)
    }
  }
`;

export const useBillDetails = (
  billId,
  setSummarizeColumOptions,
  isPsaPswatBillingDraftSubStatusEnabled = false
) => {
  const { data, loading, refetch } = useQuery(billQuery, {
    variables: {
      id: billId,
      isPsaPswatBillingDraftSubStatusEnabled
    },
    skip: !billId,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const bill = get(data, 'getBill', {});

  const formattedBill =
    !loading && bill
      ? {
          ...bill,
          issueDate: isoToReplicon(bill.issueDate),
          dueDate: isoToReplicon(bill.dueDate),
          period: bill.period && {
            startDate: isoToReplicon(bill.period.startDate),
            endDate: isoToReplicon(bill.period.endDate)
          }
        }
      : bill;

  useEffect(() => {
    if (loading) return;
    setSummarizeColumOptions(bill.summarizeColumn);
  }, [setSummarizeColumOptions, loading, bill]);

  return {
    loading,
    refetchBill: refetch,
    bill: !loading ? formattedBill : {}
  };
};

export default useBillDetails;
