import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import get from 'lodash.get';
import { isEmpty } from 'lodash';
import { DateField } from '~/modules/common/components';
import { compareDateObjects } from '~/modules/common/dates/compare';

const useStyles = makeStyles(theme => ({
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    maxWidth: theme.spacing(15)
  }
}));

const startDateKeyUri = 'urn:replicon:script-key:parameter:start-date';
const endDateKeyUri = 'urn:replicon:script-key:parameter:end-date';

export const DateEditorForDateRange = ({
  column: { id: columnId, setFieldValue, errors, isMobile, label },
  field,
  index,
  record,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const onChangeHandler = useCallback(
    newDate => {
      if (columnId === startDateKeyUri) {
        const endDate = record[endDateKeyUri];
        const isEndDateInvalid =
          endDate === null || isEmpty(endDate)
            ? false
            : compareDateObjects(newDate, endDate) === 1;

        if (isEndDateInvalid) {
          setFieldValue(`scripts[${index}].${endDateKeyUri}`, newDate);
        }
      }

      if (columnId === endDateKeyUri) {
        const startDate = record[startDateKeyUri];
        const isStartDateInvalid =
          startDate === null || isEmpty(startDate)
            ? false
            : compareDateObjects(startDate, newDate) === 1;

        if (isStartDateInvalid) {
          setFieldValue(`scripts[${index}].${startDateKeyUri}`, newDate);
        }
      }

      setFieldValue(`scripts[${index}].${columnId}`, newDate);
    },
    [columnId, index, record, setFieldValue]
  );

  const requiredError = get(errors, `parameters['${columnId}'][${index}]`);

  const value = React.useMemo(
    () => (Object.keys(record[field]).length ? record[field] : null),
    [record, field]
  );

  return (
    <DateField
      clearable={false}
      value={value}
      className={classes.date}
      editable
      useDateFieldStyles={false}
      onChange={onChangeHandler}
      error={Boolean(requiredError)}
      helperText={requiredError}
      hiddenLabel={!isMobile}
      label={label}
    />
  );
};

DateEditorForDateRange.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number
};

export default DateEditorForDateRange;
