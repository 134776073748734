export const formattedBillingAddress = billingAddress => {
  const street = billingAddress?.address;
  const city = billingAddress?.city;
  const country = billingAddress?.country?.displayText || '';
  const stateProvince = billingAddress?.stateProvince || '';
  const zipPostalCode = billingAddress?.zipPostalCode || '';

  const formattedAddress = [];

  if (street) {
    formattedAddress.push(street);
  }
  const addressLine2 = [];

  if (city || stateProvince || zipPostalCode) {
    if (city) {
      addressLine2.push(city);
    }
    const s = [];

    if (stateProvince) {
      s.push(stateProvince);
    }
    if (zipPostalCode) {
      s.push(zipPostalCode);
    }
    if (s.length !== 0) {
      addressLine2.push(s.join(' '));
    }
    formattedAddress.push(addressLine2.join(', '));
  }
  if (country) {
    formattedAddress.push(country);
  }

  return formattedAddress.join('\n');
};
