import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  useHasPermission,
  useHasUserAccessRole
} from '~/modules/common/permissions';

import EditProjectDialog from '../EditProjectDialog';
import EditClientsForm from './EditClientsForm';
import { useUpdateProjectClients } from './useUpdateProjectClients';
import { buildValidationSchema } from './validationSchema';

export const EditClientsDialog = ({
  title,
  open,
  onClose,
  canViewClient,
  canEditClient,
  projectId,
  client,
  clientRepresentative,
  projectManagerReference
}) => {
  const initialValues = {
    projectUri: projectId,
    client: client || { id: '' },
    clientRepresentative
  };

  const intl = useIntl();

  const { uri: userId } = useMeContext();

  const isClientManager = useHasUserAccessRole({
    roleUri: USER_ACCESS_ROLE.CLIENT_MANAGER
  });

  const canViewAllProjects = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-project',
    dataAccessLevelUri:
      'urn:replicon:project-data-access-level:project-management-data-access-scope'
  });

  const shouldFetchOnlyMyClients =
    isClientManager &&
    !canViewAllProjects &&
    userId !== (projectManagerReference && projectManagerReference.id);

  const validationSchema = buildValidationSchema({
    intl,
    isClientRequired: shouldFetchOnlyMyClients
  });

  const onSubmit = useUpdateProjectClients({ closeDialog: onClose });

  return (
    <EditProjectDialog
      title={title}
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <EditClientsForm
        canViewClient={canViewClient}
        canEditClient={canEditClient}
        shouldFetchOnlyMyClients={shouldFetchOnlyMyClients}
      />
    </EditProjectDialog>
  );
};

EditClientsDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  canViewClient: PropTypes.bool,
  canEditClient: PropTypes.bool,
  projectId: PropTypes.string,
  client: PropTypes.object,
  clientRepresentative: PropTypes.object,
  projectManagerReference: PropTypes.object
};

export default EditClientsDialog;
