import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MenuItem, makeStyles } from '@material-ui/core';
import FormSelectField from '~/modules/common/components/FormSelectField';
import { NoneMenuItem } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  field: {
    padding: 0
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export const renderRevenuePolicy = selectedPolicy => () => {
  return selectedPolicy ? (
    selectedPolicy.displayText
  ) : (
    <FormattedMessage id="projectRevenueRecognitionCard.noPolicy" />
  );
};

const label = (
  <FormattedMessage id="projectRevenueRecognitionCard.selectPolicy" />
);

export const RevenuePolicyDropdown = ({
  selectedPolicy,
  onChange,
  revenuePolicies
}) => {
  const classes = useStyles();

  const formClasses = useMemo(
    () => ({
      field: classes.field,
      label: classes.label
    }),
    [classes.field, classes.label]
  );

  const handleChange = useCallback(
    event => {
      const uri =
        event.target && typeof event.target.value == 'string'
          ? event.target.value
          : null;

      onChange(uri ? revenuePolicies.filter(r => r.id === uri)[0] : null);
    },
    [revenuePolicies, onChange]
  );

  const renderValue = useMemo(() => renderRevenuePolicy(selectedPolicy), [
    selectedPolicy
  ]);

  return (
    <FormSelectField
      classes={formClasses}
      label={label}
      fullWidth
      value={selectedPolicy && selectedPolicy.id}
      onChange={handleChange}
      renderValue={renderValue}
      variant="filled"
    >
      <NoneMenuItem value={null}>
        <FormattedMessage id="projectRevenueRecognitionCard.noPolicy" />
      </NoneMenuItem>
      {revenuePolicies.map(policy => (
        <MenuItem key={policy.id} value={policy.id}>
          {policy.displayText}
        </MenuItem>
      ))}
    </FormSelectField>
  );
};

RevenuePolicyDropdown.propTypes = {
  revenuePolicies: PropTypes.array,
  selectedPolicy: PropTypes.object,
  onChange: PropTypes.func
};

export default RevenuePolicyDropdown;
