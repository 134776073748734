import TagsFilter from './TagsFilter';
import { tagsTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import { TagSuggestionItem } from './TagSuggestionItem';

export default {
  tag: tagsTag,
  makeCriteriaProvider,
  DetailsControl: TagsFilter,
  SuggestionItem: TagSuggestionItem
};
