import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { PERIOD_SCALE_ENUM } from '~/modules/resourcing/common/enums';

import { useHasFeatureFlag, useIsBreakpointDown } from '~/modules/common/hooks';
import { PeriodSelector, StickyHeader } from '~/modules/common/components';

import {
  ResourcingDisplayUnitToggleButtonGroup,
  ResourcingPeriodDropDown,
  ResourcingLegends,
  ResourcingActualToggleButton
} from '~/modules/resourcing/common/components';
import { useResourceRequestToolbarContext } from '../../hooks';

import ResourceRequestImportExportMenu from '../ResourceRequestImportExportMenu';

const useLegendStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}));

const useStyles = makeStyles(theme => ({
  spacer: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0, 1)
  }
}));

export const ResourceRequestToolbar = ({ classes: classesOverride }) => {
  const {
    displayUnit,
    setDisplayUnit,
    scale,
    dateRange,
    setScale,
    onPrevious,
    onNext,
    isResourceActualModeEnabled,
    setIsResourceActualModeEnabled,
    legends
  } = useResourceRequestToolbarContext();

  const isPSAQuickAllocationDemandEnabled = useHasFeatureFlag({
    featureFlag: 'isPSAQuickAllocationDemandEnabled'
  });

  const legendClasses = useLegendStyles();
  const classes = useStyles({ classes: classesOverride });

  const isMobile = useIsBreakpointDown('sm');

  return (
    <StickyHeader level={3}>
      {scale !== PERIOD_SCALE_ENUM.OVERVIEW && (
        <PeriodSelector
          onPreviousClick={onPrevious}
          onNextClick={onNext}
          dateRange={dateRange}
        />
      )}
      <div className={classes.spacer} />
      <ResourcingLegends legends={legends} classes={legendClasses} />
      <ResourcingActualToggleButton
        isResourceActualModeEnabled={isResourceActualModeEnabled}
        setIsResourceActualModeEnabled={setIsResourceActualModeEnabled}
      />

      {scale !== PERIOD_SCALE_ENUM.OVERVIEW ? (
        <ResourcingDisplayUnitToggleButtonGroup
          displayUnit={displayUnit}
          setDisplayUnit={setDisplayUnit}
        />
      ) : null}

      <ResourcingPeriodDropDown
        scale={scale}
        setScale={setScale}
        showYears={!isPSAQuickAllocationDemandEnabled}
        data-qe-id="ResourcePeriodDropdown"
      />
      {!isMobile && <ResourceRequestImportExportMenu isMobile={isMobile} />}
    </StickyHeader>
  );
};

ResourceRequestToolbar.propTypes = {
  classes: PropTypes.object
};

export default ResourceRequestToolbar;
