import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const TimeAndExpenseSummaryLoading = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Skeleton height={24} width={60} />
      </Grid>
      <Grid item xs={5}>
        <Skeleton height={24} width={100} />
        <Skeleton height={24} width={80} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height={24} width={100} />
        <Skeleton height={24} width={80} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton height={24} width={100} />
        <Skeleton height={24} width={80} />
      </Grid>
    </Grid>
  );
};
