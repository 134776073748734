import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileUpload } from '~/modules/common/components/FileUpload';
import useRemoveStagedFile from './hooks/useRemoveStagedFile';

import Uploading from './Uploading';
import WebAddressForm from './WebAddressForm';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 2, 0, 2),
    minHeight: 200,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  webAddressForm: {
    margin: theme.spacing(2, 2, 0, 2),
    minHeight: 140,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  dragZone: {
    border: `2px dashed ${theme.palette.table.border}`,
    borderRadius: 4
  },
  noPadding: {
    padding: 0,
    overflow: 'hidden'
  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.table.border}`
  }
}));

export const FILE_SOURCE_URI =
  'urn:replicon:object-extension-file-definition-source:file-upload';
export const LINK_SOURCE_URI =
  'urn:replicon:object-extension-file-definition-source:link';
export const FILE_TAB = 'file';
export const LINK_TAB = 'link';

const FILE_TAB_LABEL = (
  <FormattedMessage id="fileWebAddressDialog.uploadFile" />
);
const LINK_TAB_LABEL = (
  <FormattedMessage id="fileWebAddressDialog.webAddress" />
);

export const FileWebAddressDialogContents = ({
  values,
  errors,
  setFieldValue,
  handleSubmit,
  fileUploadEnabled,
  webAddressEnabled,
  showWebAddressNameField,
  onTabChange,
  tabValue,
  onOK,
  onCancel,
  isUploading,
  multiple
}) => {
  const classes = useStyles();

  const onChangeHandler = useCallback(
    linkValue => setFieldValue('link', linkValue),
    [setFieldValue]
  );

  const onNameChangeHandler = useCallback(
    linkName => setFieldValue('linkName', linkName),
    [setFieldValue]
  );

  const [uploadedFileData, setUploadedFileData] = useState([]);
  const onOkClick = useCallback(() => {
    const files = uploadedFileData.map(file => {
      const keyValues = [
        {
          keyUri: 'urn:replicon:binary-object-keyvalue-key:file-name',
          value: { text: file.filename }
        }
      ];

      return {
        data: {
          ...file,
          keyValues
        },
        keyValues
      };
    });

    onOK(files);
  }, [uploadedFileData, onOK]);

  const onRemoveItem = useRemoveStagedFile({
    uploadedFileData,
    setUploadedFileData
  });
  const onOkClicked = tabValue === LINK_TAB ? handleSubmit : onOkClick;

  const linkHasError =
    ((values.link && values.link.linkUri) || '').trim().length === 0 ||
    Boolean(errors && errors.link && errors.link.linkUri);
  const fileHasError = !uploadedFileData.length;
  const isOkDisabled = tabValue === LINK_TAB ? linkHasError : fileHasError;

  return (
    <>
      <DialogTitle
        className={classNames(classes.dialogTitle, classes.noPadding)}
      >
        <Tabs indicatorColor="primary" value={tabValue} onChange={onTabChange}>
          {fileUploadEnabled && <Tab value={FILE_TAB} label={FILE_TAB_LABEL} />}
          {webAddressEnabled && <Tab value={LINK_TAB} label={LINK_TAB_LABEL} />}
        </Tabs>
      </DialogTitle>
      <DialogContent className={classes.noPadding}>
        {tabValue === FILE_TAB &&
          (isUploading ? (
            <Uploading />
          ) : (
            <FileUpload
              className={classNames(classes.root, classes.dragZone)}
              values={uploadedFileData}
              onFileUpload={setUploadedFileData}
              onRemoveItem={onRemoveItem}
              maximumFileSize={5}
              loading={isUploading}
              multiple={multiple}
            />
          ))}
        {tabValue === LINK_TAB && (
          <WebAddressForm
            className={classes.webAddressForm}
            value={values.link}
            name={values.linkName}
            errors={errors}
            onChange={onChangeHandler}
            onNameChange={onNameChangeHandler}
            showWebAddressNameField={showWebAddressNameField}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button disabled={isOkDisabled} color="primary" onClick={onOkClicked}>
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </>
  );
};

FileWebAddressDialogContents.propTypes = {
  onCancel: PropTypes.func,
  onOK: PropTypes.func,
  showWebAddressNameField: PropTypes.bool,
  isUploading: PropTypes.bool,
  multiple: PropTypes.bool,

  values: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  fileUploadEnabled: PropTypes.bool,
  webAddressEnabled: PropTypes.bool,
  onTabChange: PropTypes.func,
  tabValue: PropTypes.oneOf([FILE_TAB, LINK_TAB])
};

export default FileWebAddressDialogContents;
