import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const REVENUE_CONTRACT = gql`
  query getRevenueContracts {
    revenueContracts {
      id
      displayText
    }
  }
`;

export const useDefaultRevenueContract = () => {
  const { data } = useQuery(REVENUE_CONTRACT, {});

  const revenueContracts = get(data, 'revenueContracts', []);

  return {
    defaultRevenueContract: {
      contractId:
        revenueContracts && revenueContracts.length
          ? revenueContracts[0].id
          : null
    }
  };
};

export default useDefaultRevenueContract;
