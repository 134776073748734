import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TextField, makeStyles } from '@material-ui/core';
import { ErrorSharp as ErrorIcon } from '@material-ui/icons';
import { useHasPermission } from '~/modules/common/permissions';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import withMilestoneTasks from './withMilestoneTasks';

const useStyles = makeStyles(theme => ({
  inputRoot: ({ error }) => ({
    color: error && theme.palette.error.dark,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(20),
      paddingTop: `8px !important`,
      paddingBottom: `4px !important`
    }
  }),
  noOptions: {
    opacity: '0.6'
  }
}));

const getOptionLabel = option => option.displayText;

const getOptionSelected = errorDetails => (option, selected) =>
  (errorDetails && errorDetails.milestoneErrorCode) ||
  (!selected && !selected.displayText) ||
  option.displayText === selected.displayText;

const getInputProps = (props, errorDetails) =>
  errorDetails && errorDetails.milestoneErrorCode === 'REMOVED_MILESTONE'
    ? { ...props, startAdornment: <ErrorIcon fontSize="small" /> }
    : { ...props };

const renderInput = (
  hasError,
  helperText,
  errorDetails,
  hiddenLabel,
  label
) => params => {
  return (
    <TextField
      {...params}
      error={hasError}
      helperText={helperText}
      InputProps={getInputProps(params.InputProps, errorDetails)}
      label={label}
      hiddenLabel={hiddenLabel}
    />
  );
};

export const MilestoneTaskDropdown = ({
  tasks,
  selectedTask,
  onChange,
  hasError,
  helperText,
  errorDetails,
  label,
  hiddenLabel = true
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({
    error:
      errorDetails && errorDetails.milestoneErrorCode === 'REMOVED_MILESTONE'
  });

  const canEditTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-tasks'
  });

  const value = useMemo(() => {
    if (
      errorDetails &&
      errorDetails.milestoneErrorCode === 'REMOVED_MILESTONE'
    ) {
      return { displayText: formatMessage({ id: 'billPlan.taskRemoved' }) };
    }

    if (!selectedTask || !selectedTask.displayText) {
      return { displayText: formatMessage({ id: 'tasksDropdown.selectTask' }) };
    }

    return selectedTask;
  }, [errorDetails, formatMessage, selectedTask]);

  return (
    <SimpleAutocomplete
      disableClearable={!selectedTask || !selectedTask.displayText}
      options={tasks}
      value={value}
      disabled={!canEditTasks}
      onChange={onChange}
      multiple={false}
      fullWidth
      noOptionsText={formatMessage({ id: 'billPlan.noTask' })}
      optionPropText="displayText"
      getOptionSelected={getOptionSelected(errorDetails)}
      getOptionLabel={getOptionLabel}
      classes={classes}
      renderInput={renderInput(
        hasError,
        helperText,
        errorDetails,
        hiddenLabel,
        label,
        classes
      )}
    />
  );
};

MilestoneTaskDropdown.propTypes = {
  tasks: PropTypes.array,
  selectedTask: PropTypes.object,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  helperText: PropTypes.any,
  errorDetails: PropTypes.object,
  label: PropTypes.string,
  hiddenLabel: PropTypes.bool
};

export default withMilestoneTasks(MilestoneTaskDropdown);
