const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const sortByDisplayText = (a, b, order = SORT_ORDER.ASC) => {
  const aName = (a?.displayText || '').toUpperCase();
  const bName = (b?.displayText || '').toUpperCase();

  if (aName === bName) {
    return 0;
  }

  if (order === SORT_ORDER.DESC) {
    return aName < bName ? 1 : -1;
  }

  return aName > bName ? 1 : -1;
};
