import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  UPDATE_RESOURCE_ALLOCATION,
  patchResourceAllocation
} from '~/modules/resourcing/hooks/useUpdateResourceAllocation';
import { useMeContext } from '~/modules/me';
import useQuickAllocationContext from './useQuickAllocationContext';
import { updateCache } from './useResourceAllocationUpdateHandler';

export const useQuickAllocationUpdateResourceAllocation = () => {
  const [updateResourceAllocation, { data, loading }] = useMutation(
    UPDATE_RESOURCE_ALLOCATION
  );

  const me = useMeContext();
  const apolloClient = useApolloClient();
  const {
    filter,
    scale,
    resourceUsersCurrentPage,
    sort,
    dateRange,
    periodResolution,
    projectStatusFilterOption
  } = useQuickAllocationContext();

  const onPatchResourceAllocation = useCallback(
    ({ originalAllocation, allocation, project, openReleaseResourcesDialog }) =>
      patchResourceAllocation({
        updateResourceAllocation,
        project,
        openReleaseResourcesDialog,
        allocation,
        originalAllocation,
        apolloClient,
        filter,
        updateAllocationCache: updateCache({
          resourceUsersCurrentPage,
          scale,
          userSort: [sort],
          dateRange,
          me,
          periodResolution,
          projectStatusFilterOption,
          existingResourceAllocation: originalAllocation
        })
      }),
    [
      updateResourceAllocation,
      apolloClient,
      filter,
      resourceUsersCurrentPage,
      scale,
      sort,
      dateRange,
      me,
      periodResolution,
      projectStatusFilterOption
    ]
  );

  return {
    data,
    loading,
    onPatchResourceAllocation
  };
};

export default useQuickAllocationUpdateResourceAllocation;
