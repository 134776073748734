import { useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { array, object } from 'yup';
import {
  objectToFormatString,
  toRepliconDate
} from '~/modules/common/dates/convert';

const getBillingRateIdCounts = entries => {
  const entryIdMap = {};

  entries.forEach(entry => {
    if (entry.billingRate && entry.billingRate.id) {
      const count = entryIdMap[entry.billingRate.id];

      entryIdMap[entry.billingRate.id] = count ? count + 1 : 1;
    }
  });

  return entryIdMap;
};

const getEffectiveDateCounts = entries => {
  const dateCountMap = {};

  entries.forEach(entry => {
    if (entry.effectiveDate) {
      const stringDate = objectToFormatString(
        toRepliconDate(entry.effectiveDate),
        'yyyy-MM-dd'
      );
      const count = dateCountMap[stringDate];

      dateCountMap[stringDate] = count ? count + 1 : 1;
    }
  });

  return dateCountMap;
};

const getBillingRatesErrors = (intl, entries) => {
  const errors = {
    hasError: false,
    billingRate: {
      required: {},
      duplicate: {},
      billingScheduleEntryEffectiveDate: {
        required: {},
        duplicate: {}
      }
    }
  };
  const requiredBillingRate = intl.formatMessage({
    id: 'billingRateCard.validation.billingRateRequired'
  });
  const duplicateBillingRate = intl.formatMessage({
    id: 'billingRateCard.validation.duplicateBillingRate'
  });
  const duplicateEffectiveDate = intl.formatMessage({
    id: 'billingRateCard.validation.duplicateEffectiveDate'
  });
  const billingRateIdCounts = getBillingRateIdCounts(entries);

  entries.forEach(entry => {
    if (!entry.billingRate) return;
    if (!entry.billingRate.id) {
      errors.hasError = true;
      errors.billingRate.required = {
        ...errors.billingRate.required,
        [entry.billingRate.id]: requiredBillingRate
      };
    }
    if (entry.billingRate.id && billingRateIdCounts[entry.billingRate.id] > 1) {
      errors.hasError = true;
      errors.billingRate.duplicate = {
        ...errors.billingRate.duplicate,
        [entry.billingRate.id]: duplicateBillingRate
      };
    }
    const effectiveDateCounts = getEffectiveDateCounts(
      entry.billingRate.billingScheduleEntries
    );

    entry.billingRate.billingScheduleEntries.forEach(bse => {
      if (
        bse.effectiveDate &&
        effectiveDateCounts[
          objectToFormatString(toRepliconDate(bse.effectiveDate), 'yyyy-MM-dd')
        ] > 1
      ) {
        errors.hasError = true;
        errors.billingRate.billingScheduleEntryEffectiveDate.duplicate = {
          ...errors.billingRate.billingScheduleEntryEffectiveDate.duplicate,
          [bse.id]: duplicateEffectiveDate
        };
      }
    });
  });

  return errors;
};

export const buildValidationSchema = ({ intl }) =>
  object().shape({
    billingRates: array().test({
      name: 'billingRatesTest',
      message: rates => {
        const { billingRate } = getBillingRatesErrors(intl, rates.value);

        return billingRate;
      },
      test: rates => {
        const errors = getBillingRatesErrors(intl, rates);

        return !errors.hasError;
      }
    })
  });

export const useInitialState = ({ billingRates }) => {
  const values = useMemo(
    () => ({
      billingRates: (billingRates || []).map(x => ({ ...x, dirty: false }))
    }),
    [billingRates]
  );

  return values;
};

export const useOnSubmit = ({ updateBillingRates, initialValues }) =>
  useCallback(
    async values => {
      const { billingRates } = values;
      const { billingRates: initialBillingRates } = initialValues;
      const availableIds = billingRates.map(x => x.billingRate.id);
      const initialIds = initialBillingRates.map(x => x.billingRate.id);
      const dirtyBillingRates = billingRates.filter(x => x.dirty);
      const removedBillingRates = initialBillingRates.filter(
        x => !availableIds.includes(x.billingRate.id)
      );
      const newAddedBillingRates = billingRates.filter(
        x => !initialIds.includes(x.billingRate.id)
      );

      await updateBillingRates({
        dirtyBillingRates,
        removedBillingRates,
        newAddedBillingRates
      });
    },
    [initialValues, updateBillingRates]
  );

export const usePutBillingRatesFormState = ({
  updateBillingRates,
  billingRates
}) => {
  const initialValues = useInitialState({
    billingRates
  });

  const onSubmit = useOnSubmit({ updateBillingRates, initialValues });

  const intl = useIntl();
  const validationSchema = useMemo(() => buildValidationSchema({ intl }), [
    intl
  ]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  });

  return formik;
};

export default usePutBillingRatesFormState;
