import { useColumns } from '~/modules/common/components/ListTable';
import { MultiCurrencyMoney } from '~/modules/common/components/ListTable/renderers';
import { buildFooters } from './footers';

const moneyColumnFormatter = {
  balanceAmount: MultiCurrencyMoney,
  balanceWithoutAllocationsAmount: MultiCurrencyMoney
};

export const useListFooters = ({ columnClasses, selectedColumns, tags }) =>
  useColumns({
    columns: buildFooters({
      classes: columnClasses,
      selectedColumns,
      tags
    }),
    renders: moneyColumnFormatter
  });
