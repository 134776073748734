import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  onHover: {
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.1)
    },
    padding: theme.spacing(0, 0.5, 0, 0.5)
  },
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  rowContainer: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  item: {
    flexGrow: 1,
    flexShrink: 1,
    ...theme.typography.caption,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    textAlign: 'right'
  },
  requested: {},
  allocated: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const ChartTotalItems = memo(
  ({
    classes: classesOverrides,
    requested,
    allocated,
    title,
    qeIdTag,
    hourDiff,
    costDiff,
    popperContent,
    handleClick,
    handleKeyDown,
    onClickAway
  }) => {
    const classes = useStyles({ classes: classesOverrides });

    const content = (
      <>
        <div className={classes.rowContainer}>
          {hourDiff}
          {costDiff}
        </div>

        <div
          data-qe-id={qeIdTag ? `RowHours${qeIdTag}` : 'RowHours'}
          className={classNames(classes.item, classes.requested)}
        >
          {requested}
        </div>
        <div
          data-qe-id={qeIdTag ? `RowCost${qeIdTag}` : 'RowCost'}
          className={classNames(classes.item, classes.allocated)}
        >
          {allocated}
        </div>
      </>
    );

    return title ? (
      <>
        <Tooltip title={title}>
          <div
            data-qe-id={
              qeIdTag ? `RowTotalContent${qeIdTag}` : 'RowTotalContent'
            }
            className={classes.root}
          >
            {content}
          </div>
        </Tooltip>
      </>
    ) : popperContent ? (
      <>
        <ClickAwayListener onClickAway={onClickAway}>
          <div
            onClick={handleClick}
            data-qe-id="ChartTotalContent"
            className={classNames(classes.onHover, classes.root)}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            {content}
          </div>
        </ClickAwayListener>

        {popperContent}
      </>
    ) : (
      <>
        <div data-qe-id="ChartTotalContent" className={classes.root}>
          {content}
        </div>
      </>
    );
  }
);

ChartTotalItems.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  requested: PropTypes.node,
  allocated: PropTypes.node,
  title: PropTypes.node,
  costDiff: PropTypes.node,
  hourDiff: PropTypes.node,
  popperContent: PropTypes.node,
  handleClick: PropTypes.func,
  handleKeyDown: PropTypes.func,
  onClickAway: PropTypes.func
};

export default ChartTotalItems;
