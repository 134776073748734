import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useProjectContext } from '~/modules/resourcing/common/contexts';

import {
  itemWidth,
  actionsWidth
} from '~/modules/common/charts/timeline/calculations';
import { useSkillLevels, useScrollToTop } from '~/modules/common/hooks';
import {
  ProjectChartRowsLoading,
  ResourcingCurrentDateLine
} from '~/modules/resourcing/common/components';
import { useScheduleDetailsForResourceUser } from '~/modules/resourcing/common/hooks';
import {
  getUserIdsFromRequests,
  mergeUserScheduleDetailsToRequests
} from '~/modules/resourcing/common/util/resourceAllocationUtil';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';
import {
  useProjectResourceRequests,
  useResourceRequestToolbarContext
} from '../hooks';
import { useUpdateProjectDateRangeHandlers } from './hooks';
import { ResourceRequestChartContent } from './components';
import { ResourceRequestChartFooter } from './components/ChartFooters';

const leftComponentWidth = actionsWidth + itemWidth * 2;

export const LEFT_WIDTH = 464;
export const FOOTER_HEIGHT = 75;

export const ResourceRequestChartInternal = ({
  chartDates,
  project,
  resourceRequests,
  scale,
  isEditSwitchEnabled,
  chartDisplayDateRange,
  displayUnit,
  updateProjectStartDate,
  updateProjectEndDate,
  hasMoreRows,
  loadingRows,
  loadMoreRows,
  loadingMoreRows,
  allocationChartRef,
  maxSkillLevel
}) => {
  const resourceAllocationUserIds = useMemo(
    () => getUserIdsFromRequests(resourceRequests),
    [resourceRequests]
  );

  const {
    loading: loadingUserScheduleDetails,
    scheduleDetailsByUserMap
  } = useScheduleDetailsForResourceUser({
    userIds: resourceAllocationUserIds,
    dateRange: chartDisplayDateRange,
    periodResolution: mapScaleToPeriodResolution(scale)
  });

  const resourceRequestsWithScheduleDetails = useMemo(
    () =>
      mergeUserScheduleDetailsToRequests(
        scheduleDetailsByUserMap,
        resourceRequests
      ),
    [resourceRequests, scheduleDetailsByUserMap]
  );

  useScrollToTop();

  if (loadingUserScheduleDetails) {
    return <ProjectChartRowsLoading />;
  }

  const hasResourceRequests = resourceRequests.length > 0;

  return (
    <>
      <ResourcingCurrentDateLine
        scale={scale}
        chartDates={chartDates}
        leftWidth={LEFT_WIDTH}
        footerHeight={FOOTER_HEIGHT}
      />
      <ResourceRequestChartContent
        chartDates={chartDates}
        project={project}
        hasResourceRequests={hasResourceRequests}
        resourceRequests={resourceRequestsWithScheduleDetails}
        scale={scale}
        isEditSwitchEnabled={isEditSwitchEnabled}
        leftComponentWidth={leftComponentWidth}
        chartDisplayDateRange={chartDisplayDateRange}
        displayUnit={displayUnit}
        updateProjectStartDate={updateProjectStartDate}
        updateProjectEndDate={updateProjectEndDate}
        hasMoreRows={hasMoreRows}
        loadingRows={loadingRows}
        loadMoreRows={loadMoreRows}
        loadingMoreRows={loadingMoreRows}
        allocationChartRef={allocationChartRef}
        maxSkillLevel={maxSkillLevel}
      />
    </>
  );
};

ResourceRequestChartInternal.propTypes = {
  chartDates: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  resourceRequests: PropTypes.array.isRequired,
  updateProjectStartDate: PropTypes.func,
  updateProjectEndDate: PropTypes.func,
  scale: PropTypes.string,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  displayUnit: PropTypes.string,
  loadingRows: PropTypes.bool.isRequired,
  hasMoreRows: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  loadingMoreRows: PropTypes.bool.isRequired,
  allocationChartRef: PropTypes.object,
  maxSkillLevel: PropTypes.number
};

export const ResourceRequestChart = ({
  isEditSwitchEnabled,
  handleAddResourceRequestClick,
  allocationChartRef
}) => {
  const { project } = useProjectContext();

  const {
    scale,
    chartDates,
    filter,
    sort,
    displayUnit,
    dateRange: chartDisplayDateRange
  } = useResourceRequestToolbarContext();

  const {
    updateProjectStartDate,
    updateProjectEndDate
  } = useUpdateProjectDateRangeHandlers(project);

  const {
    loadingRows,
    resourceRequests,
    loadingMoreRows,
    loadMoreRows,
    hasMoreRows
  } = useProjectResourceRequests({
    ...filter,
    projectUri: project.id,
    sort,
    chartDisplayDateRange
  });

  const { isSkillLoading, maxSkillLevel } = useSkillLevels();

  if (loadingRows || isSkillLoading) {
    return <ProjectChartRowsLoading />;
  }

  const hasResourceRequests = resourceRequests.length > 0;

  return (
    <>
      <ResourceRequestChartInternal
        chartDates={chartDates}
        project={project}
        hasResourceRequests={hasResourceRequests}
        resourceRequests={resourceRequests}
        scale={scale}
        isEditSwitchEnabled={isEditSwitchEnabled}
        leftComponentWidth={leftComponentWidth}
        chartDisplayDateRange={chartDisplayDateRange}
        displayUnit={displayUnit}
        updateProjectStartDate={updateProjectStartDate}
        updateProjectEndDate={updateProjectEndDate}
        hasMoreRows={hasMoreRows}
        loadingRows={loadingRows}
        loadMoreRows={loadMoreRows}
        loadingMoreRows={loadingMoreRows}
        allocationChartRef={allocationChartRef}
        maxSkillLevel={maxSkillLevel}
      />
      <ResourceRequestChartFooter
        isEditSwitchEnabled={isEditSwitchEnabled}
        handleAddResourceRequestClick={handleAddResourceRequestClick}
      />
    </>
  );
};

ResourceRequestChart.propTypes = {
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  handleAddResourceRequestClick: PropTypes.func,
  allocationChartRef: PropTypes.object
};

export default ResourceRequestChart;
