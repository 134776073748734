import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { columnArguments } from '~/modules/common/hooks/project';
import { projectListItemFragment } from '~/modules/common/fragments';

export const GET_PROJECTS_QUERY = gql`
  query getProjects(
    $page: Int!
    $pageSize: Int!
    $projectFilter: ProjectFilter
    $sort: ProjectSort
    $isManaged: Boolean
    $tagIds: [String!],
    ${`$isPSAPercentageBudgetScopeEnabled: Boolean = false`},
    ${columnArguments}
  ) {
    projects(
      page: $page
      pagesize: $pageSize
      projectFilter: $projectFilter
      sort: $sort
      isManaged: $isManaged
      tagIds: $tagIds
    ) {
      ...ProjectListItem,
    }
  }
  ${projectListItemFragment}
`;

export const useGetProjectsQuery = (options, query = GET_PROJECTS_QUERY) => {
  const { variables = {} } = options;

  const queryOptions = {
    variables: {
      ...variables,
      isPSAPercentageBudgetScopeEnabled:
        variables.isPSAPercentageBudgetScopeEnabled || false
    }
  };

  return useQuery(query, { ...options, ...queryOptions });
};
