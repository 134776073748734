import { useCallback } from 'react';

const useKeyBoardEventHandlers = ({ onClose, onEnterClick }) => {
  const handleKeyDown = useCallback(
    event => {
      if (event?.key === 'Tab' && typeof onClose === 'function') {
        onClose();
      }
      if (event?.key === 'Enter' && typeof onEnterClick === 'function') {
        onEnterClick(event);
      }
    },
    [onEnterClick, onClose]
  );

  return { handleKeyDown };
};

export default useKeyBoardEventHandlers;
