import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ResourceRequestsIcon = React.forwardRef((props, ref) => (
  <SvgIcon data-qe-id="ResourceRequestsIcon" {...props} ref={ref}>
    <>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="m0 4v20h20v-2h-18v-18h-2z" />
      <path d="m4 0v20h20v-20zm12 5c1.3812 0 2.5 1.1188 2.5 2.5s-1.1188 2.5-2.5 2.5-2.5-1.1188-2.5-2.5 1.1188-2.5 2.5-2.5zm-8 1h1v2h2v1h-2v2h-1v-2h-2v-1h2zm8 5.25c1.6688 0 3.9614 1.2018 5 2.5v1.25h-10v-1.25c0-1.6625 3.3312-2.5 5-2.5z" />
    </>
  </SvgIcon>
));

export default ResourceRequestsIcon;
