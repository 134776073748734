import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LongString from './LongString';

const useStyles = makeStyles(theme => ({
  root: props => ({
    color: theme.palette.text.secondary,
    fontStyle: props.fontStyle,
    fontWeight: theme.typography.fontWeightRegular
  })
}));

export const NoDataItem = ({
  classes,
  children,
  fontStyle = 'italic',
  dataQeId
}) => {
  const cls = useStyles({ classes, fontStyle });

  return (
    <span className={cls.root}>
      <LongString data-qe-id={dataQeId && `${dataQeId}_NoDataItem`}>
        {children}
      </LongString>
    </span>
  );
};

NoDataItem.propTypes = {
  classes: PropTypes.object,
  fontStyle: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataQeId: PropTypes.string
};

export default NoDataItem;
