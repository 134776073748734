import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TagIcon = React.forwardRef((props, ref) => (
  <SvgIcon data-qe-id="TagIcon" {...props} ref={ref}>
    <>
      <path d="M17.03 5L3 5.01v13.98l14.03.01L22 12l-4.97-7z" />
    </>
  </SvgIcon>
));

export default TagIcon;
