import { makeStyles } from '@material-ui/core';

export const useDrawerStyles = makeStyles(theme => ({
  headerArea: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0, 1, 2)
  },
  grow: {
    flexGrow: 0
  }
}));

const useStyles = makeStyles(theme => ({
  contentArea: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0)
  }
}));

export const useFooterStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.drawer
  }
}));

export const combinedRequestFooterStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 0, 1, 2)
  }
}));

export default useStyles;
