import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { projectTasksQuery, DEFAULT_PAGE_SIZE } from './useProjectTasks';

const setTaskAssignedRoleMutation = gql`
  mutation setTaskAssignedRole($taskId: String!, $roleId: String) {
    setTaskAssignedRole: setTaskAssignedRole2(
      input: { taskId: $taskId, roleId: $roleId }
    ) {
      dummy
    }
  }
`;

const useSetTaskAssignedRole = () => useMutation(setTaskAssignedRoleMutation);

export const updateCachedTaskAssignedRole = ({
  role,
  isOpen,
  taskId,
  projectSlug,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isPSATaskEstimateRollUpMobileViewEnabled
}) => proxy => {
  const cachedProjectTasksQuery = proxy.readQuery({
    query: projectTasksQuery,
    variables: {
      projectSlug,
      isOpen,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      isRolledUpTaskEstimateCalculationMethodEnabled:
        isRolledUpTaskEstimateCalculationMethodEnabled || false,
      isPSATaskEstimateRollUpMobileViewEnabled:
        isPSATaskEstimateRollUpMobileViewEnabled || false
    }
  });

  const { project: cachedGetProjectBySlug } = cachedProjectTasksQuery;

  const updateGetProjectBySlugQuery = {
    ...cachedGetProjectBySlug,
    tasks: cachedGetProjectBySlug.tasks.map(t => {
      if (t.id === taskId) {
        return {
          ...t,
          assignedRole: role || null
        };
      }

      return t;
    })
  };

  proxy.writeQuery({
    query: projectTasksQuery,
    variables: {
      projectSlug,
      isOpen,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      isRolledUpTaskEstimateCalculationMethodEnabled:
        isRolledUpTaskEstimateCalculationMethodEnabled || false,
      isPSATaskEstimateRollUpMobileViewEnabled:
        isPSATaskEstimateRollUpMobileViewEnabled || false
    },
    data: {
      project: updateGetProjectBySlugQuery
    }
  });
};

export const useAssignRoleToTask = ({
  me,
  projectSlug,
  includeClosedTasks
}) => {
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = me || {};
  const [setTaskAssignedRole] = useSetTaskAssignedRole();

  return useCallback(
    (taskId, role) => {
      const roleId = role ? role.id : null;

      setTaskAssignedRole({
        variables: {
          taskId,
          roleId
        },
        optimisticResponse: { setTaskAssignedRole: null },
        update: updateCachedTaskAssignedRole({
          projectSlug,
          taskId,
          role,
          isOpen: includeClosedTasks ? undefined : true,
          isRolledUpTaskEstimateCalculationMethodEnabled
        })
      });
    },
    [
      setTaskAssignedRole,
      isRolledUpTaskEstimateCalculationMethodEnabled,
      projectSlug,
      includeClosedTasks
    ]
  );
};

export default useAssignRoleToTask;
