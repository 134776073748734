import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import { numberAbbreviatedTickFormat } from '../../util';

export default ({ intl, data = [], currencySymbol }) => {
  const theme = useTheme();

  const yTickFormat = useCallback(
    tick => {
      const abbreviatedTickFormat = numberAbbreviatedTickFormat({
        intl,
        tick: Math.abs(tick)
      });

      return Math.sign(tick) >= 0
        ? abbreviatedTickFormat
        : `-${abbreviatedTickFormat}`;
    },
    [intl]
  );

  const xTickFormat = useCallback(
    tick =>
      tick.length > 10 ? tick.replace(' — ', '—').replace(',', '\n') : tick,
    []
  );

  const legends = [
    {
      label: <FormattedMessage id="profitabilityLegends.profit" />,
      color: theme.palette.success.main
    },
    {
      label: <FormattedMessage id="profitabilityLegends.loss" />,
      color: theme.palette.error.main
    }
  ];

  const highestValue = data.reduce((prev, current) => {
    const value = current.y || current.y0;

    const absoluteValue = Math.abs(Number.parseInt(value, 10));

    if (absoluteValue > prev) {
      return absoluteValue;
    }

    return prev;
  }, 0);

  return {
    yTickFormat,
    xTickFormat,
    legends,
    highestValue
  };
};
