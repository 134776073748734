import PropTypes from 'prop-types';
import React from 'react';
import { PageOutOfBoundsMessage } from '~/modules/common/components/ListTable';
import NoResourcesData from '~/modules/resource-management/NoResourcesData';

export const NoResourceOrPageOutOfBoundMessage = ({
  loadingRows,
  currentPage,
  setCurrentPage,
  resourceTotalCount
}) => {
  if (loadingRows) return null;

  return currentPage !== 1 && resourceTotalCount === 0 ? (
    <PageOutOfBoundsMessage
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  ) : (
    <NoResourcesData />
  );
};

NoResourceOrPageOutOfBoundMessage.propTypes = {
  loadingRows: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  resourceTotalCount: PropTypes.number.isRequired
};

export default NoResourceOrPageOutOfBoundMessage;
