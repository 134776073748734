import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';
import { BatchStatus } from '~/modules/common/components';

export const InProgress = () => {
  return (
    <Grid item xs={12}>
      <CircularProgress size={30} />
    </Grid>
  );
};

export const EarnedRevenueRecalculateBatch = ({
  onBatchComplete,
  batchState,
  setBatchState
}) => {
  return (
    <BatchStatus
      batchState={batchState}
      setBatchState={setBatchState}
      onBatchComplete={onBatchComplete}
    >
      <InProgress />
    </BatchStatus>
  );
};

EarnedRevenueRecalculateBatch.propTypes = {
  setBatchState: PropTypes.func.isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  batchState: PropTypes.object
};

export default EarnedRevenueRecalculateBatch;
