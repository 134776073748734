import React from 'react';
import PropTypes from 'prop-types';
import { deepPure } from '~/util';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { compareISOStrings } from '~/modules/common/dates/compare';
import { convertDurationToHours } from '~/modules/common/util';
import { useMeContext } from '~/modules/me';
import { getTodayForUser } from '~/modules/common/dates/today';
import ResourceActualHoursSummaryBlock2 from './ResourceActualHoursSummaryBlock/ResourceActualHoursSummaryBlock2';
import useStyles, { useHoursSummaryBlockStyles } from './useStyles';

const getAllocationPeriod = ({ allocationPeriods, periodStartDate, scale }) =>
  allocationPeriods?.find(
    period => period.key === `${scale}-${periodStartDate.toISODate()}`
  );

const getDynamicPosition = ({
  chartDisplayPeriods,
  periodStartDate,
  positionAttributeOverrides,
  scale
}) => {
  const { dynamicPosition } = chartDisplayPeriods.find(
    period => period.key === `${scale}-${periodStartDate.toISODate()}`
  );

  return { ...dynamicPosition, ...positionAttributeOverrides };
};

export const emptyDateInFuture = ({ todayDate, period }) => {
  const dateComparedValue = compareISOStrings(
    mapRepliconDateToMidnightUTCString(todayDate),
    period.dateRange.startDate
  );

  return !(dateComparedValue < 0 && convertDurationToHours(period.hours) === 0);
};

export const ResourceActualHoursSummaryBlocks2 = ({
  actualSummaryBlockPopupProps,
  allocationPeriods,
  chartDisplayPeriods,
  dataQeId,
  positionAttributes,
  resourceAvailabilityTimePeriods,
  resourceUser,
  resourceUserActualTimePeriods,
  scale
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const todayDate = getTodayForUser(me);
  const hoursSummaryBlockClasses = useHoursSummaryBlockStyles();

  const roleDisplayText =
    actualSummaryBlockPopupProps?.roleDisplayText || 'noRole';

  const positionAttributeOverrides = positionAttributes || { top: 50 };

  return (
    <div className={classes.timeBlocks}>
      {!resourceUserActualTimePeriods &&
        allocationPeriods?.map(period => {
          const periodStart = period.startDate.toISODate();

          return (
            compareISOStrings(
              mapRepliconDateToMidnightUTCString(todayDate),
              periodStart
            ) > -1 && (
              <ResourceActualHoursSummaryBlock2
                key={`${resourceUser.id}-${period.startDate}`}
                classes={hoursSummaryBlockClasses}
                actualSummaryBlockPopupProps={actualSummaryBlockPopupProps}
                allocationPeriod={period}
                dataQeId={`${dataQeId}-ActualBlock-${resourceUser.displayText}-${roleDisplayText}-${period.startDate}`}
                dynamicPosition={getDynamicPosition({
                  chartDisplayPeriods,
                  periodStartDate: period.startDate,
                  positionAttributeOverrides,
                  scale
                })}
                endDate={period.endDate}
                hours={0}
                resourceAvailabilityTimePeriod={resourceAvailabilityTimePeriods?.find(
                  entry =>
                    compareISOStrings(
                      entry.dateRange.startDate,
                      periodStart
                    ) === 0
                )}
                scale={scale}
                startDate={period.startDate}
              />
            )
          );
        })}
      {resourceUserActualTimePeriods?.map(periodActuals => {
        const isVisible = emptyDateInFuture({
          todayDate,
          period: periodActuals
        });

        if (!isVisible) return null;

        const periodStartDate = mapIsoStringtoUtcObject(
          periodActuals.dateRange.startDate
        );

        return (
          <ResourceActualHoursSummaryBlock2
            key={`${resourceUser.id}-${periodActuals.dateRange.startDate}`}
            classes={hoursSummaryBlockClasses}
            actualSummaryBlockPopupProps={actualSummaryBlockPopupProps}
            allocationPeriod={getAllocationPeriod({
              allocationPeriods,
              periodStartDate,
              scale
            })}
            dataQeId={`${dataQeId}-ActualBlock-${resourceUser.displayText}-${roleDisplayText}-${periodActuals.dateRange.startDate}`}
            dynamicPosition={getDynamicPosition({
              chartDisplayPeriods,
              periodStartDate,
              positionAttributeOverrides,
              scale
            })}
            endDate={mapIsoStringtoUtcObject(periodActuals.dateRange.endDate)}
            hours={convertDurationToHours(periodActuals.hours)}
            resourceAvailabilityTimePeriod={resourceAvailabilityTimePeriods?.find(
              entry =>
                compareISOStrings(
                  entry.dateRange.startDate,
                  periodActuals.dateRange.startDate
                ) === 0
            )}
            startDate={periodStartDate}
            scale={scale}
          />
        );
      })}
    </div>
  );
};
ResourceActualHoursSummaryBlocks2.propTypes = {
  actualSummaryBlockPopupProps: PropTypes.object,
  allocationPeriods: PropTypes.array,
  chartDisplayPeriods: PropTypes.array,
  positionAttributes: PropTypes.object,
  dataQeId: PropTypes.string,
  resourceAvailabilityTimePeriods: PropTypes.array,
  resourceUser: PropTypes.object,
  resourceUserActualTimePeriods: PropTypes.array,
  scale: PropTypes.string
};

export default deepPure(ResourceActualHoursSummaryBlocks2);
