import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CommunityIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M22 6h-3v9H6v3h12l4 4V6zm-5 7V2H2v15l4-4h11z" />
    </>
  </SvgIcon>
));

export default CommunityIcon;
