import { useMemo } from 'react';
import {
  mapRepliconDateToUtcObject,
  toRepliconDate
} from '~/modules/common/dates/convert';
import { getWorkDaysBetweenDates } from '~/modules/common/dates/compare';
import { USER_SCHEDULE_HOURS } from '../constants/scheduleHours';

export const useTimeoffSummaryPeriods = ({
  resource = {},
  hideTimeOffs = false
}) => {
  const { timeoffs } = resource;

  return useMemo(
    () =>
      hideTimeOffs
        ? []
        : (timeoffs || []).map(timeoff => {
            const {
              dateRange: { startDate, endDate },
              hours,
              ...rest
            } = timeoff;

            const totalAvailableHours =
              (startDate &&
                endDate &&
                getWorkDaysBetweenDates({
                  startDateInRepliconFormat: toRepliconDate(startDate),
                  endDateInRepliconFormat: toRepliconDate(endDate)
                }) *
                  USER_SCHEDULE_HOURS) /* This should consider shifts in future */ ||
              0;

            return {
              startDate: startDate && mapRepliconDateToUtcObject(startDate),
              endDate: endDate && mapRepliconDateToUtcObject(endDate),
              totalAvailableHours,
              totalTimeOffHours: hours,
              ...rest
            };
          }),
    [timeoffs, hideTimeOffs]
  );
};

export default useTimeoffSummaryPeriods;
