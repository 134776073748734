import { useEffect, useState } from 'react';
import { useSessionStorage } from '~/modules/common/hooks';
import { AVAILABLE_TO_BILL_BILLING_TRANSACTION_REDIRECT_KEY } from '~/modules/billing-invoicing/common/const';
import { useSearchState } from '~/modules/common/components/SearchBox';
import { billingTransactionTag } from '~/modules/common/components/SearchBox/Facets/BillingTransactionFacet/tag';

const mapToSeachCriteria = transaction => {
  return {
    keywords: [],
    criterions: {
      [billingTransactionTag]: transaction.map(({ id, displayId }) => ({
        id,
        displayText: displayId,
        key: displayId,
        label: displayId,
        value: id
      }))
    }
  };
};

export const useBillingTransactionSearchCriteria = (
  searchState,
  isPsaPrpManualBulkBillCreationEnabled
) => {
  const [redirectFilter, setRedirectFilter] = useState();
  const { storedValue, removeValue } = useSessionStorage(
    AVAILABLE_TO_BILL_BILLING_TRANSACTION_REDIRECT_KEY
  );

  const [searchCriteria, setSearchCriteria] = useSearchState(searchState);

  useEffect(() => {
    if (isPsaPrpManualBulkBillCreationEnabled && storedValue) {
      setRedirectFilter(storedValue);
      removeValue(AVAILABLE_TO_BILL_BILLING_TRANSACTION_REDIRECT_KEY);
    }
  }, [isPsaPrpManualBulkBillCreationEnabled, removeValue, storedValue]);

  return {
    searchCriteria:
      isPsaPrpManualBulkBillCreationEnabled && redirectFilter
        ? mapToSeachCriteria(redirectFilter)
        : searchCriteria,
    setSearchCriteria:
      isPsaPrpManualBulkBillCreationEnabled && redirectFilter
        ? () => {
            setRedirectFilter(null);
          }
        : setSearchCriteria
  };
};
