import React from 'react';
import { useMeContext } from '~/modules/me';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';
import { TaskVariationPillContent } from './TaskVariationPillContent';

export const TaskVariationPill = props => {
  const me = useMeContext();

  return (
    <TaskVariationPillContent
      numberFormat={getReactNumberFormatFromMe(me)}
      isPSAPRPTaskEstimateCalculationMethodEnabled={
        me?.featureFlags?.PSAPRPTaskEstimateCalculation !==
        TASK_ESTIMATE_CALCULATION_TYPES.OFF
      }
      isPsaRmpTaskAllocation1Enabled={
        me?.featureFlags?.isPsaRmpTaskAllocation1Enabled
      }
      {...props}
    />
  );
};

export default TaskVariationPill;
