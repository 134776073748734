import { isNullOrUndefined } from '~/modules/common/util';

export const rangeHasValue = range => {
  const minIsValid = !isNullOrUndefined(range[0]) && range[0] !== 0;
  const maxIsValid = !isNullOrUndefined(range[1]) && range[1] !== 5;

  return Array.isArray(range) && (minIsValid || maxIsValid);
};

export const parseScoreValue = selectedSearchValue => {
  const text =
    selectedSearchValue &&
    selectedSearchValue.length > 0 &&
    selectedSearchValue[0].value;

  if (text && text.trim().length > 0) {
    const parts = text.split('-').map(value => parseInt(value, 10));

    if (parts.length === 1 && parts[0]) return [parts[0], parts[0]];

    return [parts[0], parts[1]];
  }

  return [0, 5];
};

export const formatScoreValue = range => {
  const searchObject = {
    key: '',
    value: '',
    minValue: null,
    maxValue: null
  };

  const rangeMin = range[0];
  const rangeMax = range[1];

  const hasValidMin = !isNullOrUndefined(rangeMin) && rangeMin !== 0;
  const hasValidMax = !isNullOrUndefined(rangeMax) && rangeMax !== 5;

  if (!(hasValidMax || hasValidMin)) return searchObject;

  const minValue = hasValidMin ? rangeMin : 0;
  const maxValue = hasValidMax ? rangeMax : 5;

  const minKey = hasValidMin ? rangeMin : 'notRated';

  const searchValue = `${minValue.toFixed(0)}-${maxValue.toFixed(0)}`;
  const searchKey = `${minKey}${minValue === maxValue ? '' : `-${maxValue}`}`;

  return {
    key: searchKey,
    value: searchValue,
    minValue: rangeMin === 0 ? null : rangeMin,
    maxValue: rangeMax
  };
};

export const labelDescriptionFormat = selectedScoreObject => {
  return selectedScoreObject.key && selectedScoreObject.key.includes('notRated')
    ? selectedScoreObject.key.replace('notRated', 'Not Rated')
    : selectedScoreObject.key;
};
