import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Grid, IconButton, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditSharp as EditIcon } from '@material-ui/icons';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 0, 0.5, 0),
    color: theme.palette.grey[500]
  },
  grow: {
    flexGrow: 1
  },
  button: {
    padding: 0,
    visibility: 'hidden'
  },
  visible: {
    visibility: 'visible'
  },
  titleContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  title: {
    fontSize: theme.typography.body2.fontSize,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export const SideCardSectionHeader = ({
  title,
  onEditClick,
  dataQeId,
  showEdit,
  ariaLabelKey
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();

  const action = (
    <IconButton
      className={classNames(classes.button, showEdit && classes.visible)}
      size="small"
      onClick={onEditClick}
      data-qe-id={dataQeId}
      aria-label={formatMessage({
        id: isPsaPrpAccessibilityIssuesEnabled ? ariaLabelKey : 'button.edit'
      })}
    >
      <EditIcon />
    </IconButton>
  );

  return (
    <>
      <Grid
        className={classes.container}
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid className={classNames(classes.titleContainer, classes.grow)} item>
          <Typography
            className={classes.title}
            component="div"
            variant="h6"
            color="textSecondary"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>{action}</Grid>
      </Grid>
      <Divider />
    </>
  );
};

SideCardSectionHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onEditClick: PropTypes.func,
  dataQeId: PropTypes.string,
  showEdit: PropTypes.bool,
  ariaLabelKey: PropTypes.string
};

export default SideCardSectionHeader;
