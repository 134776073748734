import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES = gql`
  query GetResourcePlanAllocatedUsersAndRoles(
    $projectId: String!
    $userIds: [String!]!
  ) {
    project(projectId: $projectId) {
      id
      resourcePlanAllocatedUsersAndRoles(userIds: $userIds) {
        id
        user {
          id
          uri
          displayText
        }
        roles {
          role {
            id
            displayText
          }
          resourceAllocationReference {
            id
            resourceRequestIds
          }
        }
      }
    }
  }
`;

export const tryResourcePlanAllocatedUsersAndRolesCachedQuery = ({
  proxy,
  variables
}) => {
  try {
    return proxy.readQuery({
      query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
      variables
    });
  } catch {
    return null;
  }
};

export const useFetchAllocatedUsersAndRoles = ({
  variables,
  skip,
  onCompleted
}) => {
  const { data, loading } = useQuery(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
    {
      variables,
      skip,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onCompleted
    }
  );

  return {
    loading,
    allocatedUsersAndRolesData:
      data?.project?.resourcePlanAllocatedUsersAndRoles || []
  };
};
