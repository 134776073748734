import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DialogTitle, Typography, Tab, makeStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(1, 3, 0, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2, 0, 2)
    }
  },
  client: {
    fontSize: theme.typography.body1.fontSize
  },
  tabs: {
    flexGrow: 1,
    flexShrink: 1
  },
  title: {
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: theme.typography.fontWeightMedium,
    ...theme.typography.h6
  }
}));

const recurrentTabLabels = {
  availableToBill: <FormattedMessage id="addLineItemDialog.availableToBill" />,
  custom: <FormattedMessage id="addLineItemDialog.custom" />
};

export const AddLineItemsDialogTitle = ({
  createBill,
  client,
  currentTab,
  setCurrentTab
}) => {
  const classes = useStyles();
  const handleTabChange = useCallback(
    (_, newCurrentTab) => {
      setCurrentTab(newCurrentTab);
    },
    [setCurrentTab]
  );

  const addModeRecurrentTabKey = createBill
    ? 'addLineItemDialog.createBill'
    : 'addLineItemDialog.addLineItems';

  return (
    <DialogTitle disableTypography className={classes.dialogTitle}>
      <Typography variant="h1" className={classes.title}>
        <FormattedMessage id={addModeRecurrentTabKey} />
      </Typography>
      {createBill && (
        <span className={classes.client}>: {client && client.displayText}</span>
      )}
      {!createBill && (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab
            value="addLineItems"
            label={recurrentTabLabels.availableToBill}
          />
          <Tab value="addCustomLineItems" label={recurrentTabLabels.custom} />
        </Tabs>
      )}
    </DialogTitle>
  );
};

AddLineItemsDialogTitle.propTypes = {
  createBill: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired
};

export default AddLineItemsDialogTitle;
