import { useCallback } from 'react';
import { buildResourceAllocationFromResourceRequest } from '~/modules/resourcing/hooks/useCreateResourceAllocationHandler';
import useResourceAllocationHandlers from './useResourceAllocationHandlers';

export default ({ resourceRequest, me }) => {
  const {
    onCreateResourceAllocation,
    onDeleteResourceAllocation
  } = useResourceAllocationHandlers();

  const _onCreateResourceAllocation = useCallback(
    (user, overrideScheduleRules) => {
      const allocation = buildResourceAllocationFromResourceRequest({
        resourceRequest,
        user,
        me,
        scheduleRules: overrideScheduleRules
      });

      return onCreateResourceAllocation({ allocation, user });
    },
    [onCreateResourceAllocation, me, resourceRequest]
  );

  return {
    onCreateResourceAllocation: _onCreateResourceAllocation,
    onDeleteResourceAllocation
  };
};
