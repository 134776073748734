import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import NoValue from '../NoValue';
import { getLuxonJsDateFormatFromMe } from '../../dates/convert';

const useStyles = makeStyles(() => ({
  date: {
    whiteSpace: 'nowrap'
  },
  textField: {}
}));

const resolveDateTime = dateTime =>
  dateTime &&
  (typeof dateTime === 'string'
    ? DateTime.fromISO(dateTime, { setZone: true })
    : DateTime.utc(dateTime.year, dateTime.month, dateTime.day));

export const Date = ({
  classes: classesOverrides,
  value,
  className,
  format,
  component = 'Typography',
  elementType,
  defaultValue = '',
  hiddenLabel,
  variant = 'body2',
  ...restProps
}) => {
  const me = useMeContext();
  const classes = useStyles({ classes: classesOverrides });
  const dateTime = resolveDateTime(value);
  const luxonFormat = format || getLuxonJsDateFormatFromMe(me);

  const controlledProp = dateTime
    ? { value: dateTime.toFormat(luxonFormat) }
    : { defaultValue };

  return component === 'Typography' ? (
    <Typography
      component={elementType}
      variant={variant}
      className={classNames(classes.date, className)}
      {...restProps}
    >
      {dateTime ? controlledProp.value : <NoValue />}
    </Typography>
  ) : (
    <TextField
      disabled
      className={classNames(classes.textField, className)}
      fullWidth
      hiddenLabel={hiddenLabel}
      {...controlledProp}
      {...restProps}
    />
  );
};

Date.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  component: PropTypes.oneOf(['Typography', 'TextField']),
  elementType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  format: PropTypes.string,
  variant: PropTypes.string,
  defaultValue: PropTypes.string,
  hiddenLabel: PropTypes.bool
};

export default memo(Date);
