import { Card, CardContent, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import AddIcon from '@material-ui/icons/AddSharp';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  computeTableColumns,
  ListTable,
  ListTableThemeProvider,
  ListTablePropTypes
} from '~/modules/common/components/ListTable';
import { useProjectRequestPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import { getFormatters } from './columns';
import MobileProjectRequestListItem from './MobileProjectRequestListItem';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(2)
    },
    width: '100%'
  },
  tableHeaderCell: {
    padding: theme.spacing(1, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    lineHeight: 1.4,
    fontSize: theme.typography.body2.fontSize,
    top: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(8)
    },
    '& span': {
      backgroundColor: 'inherit'
    },
    zIndex: 1
  },
  tableCell: {
    height: 40,
    padding: theme.spacing(0.5, 2)
  },
  tableFooter: {
    backgroundColor: theme.palette.grey[100],
    color: 'inherit'
  },
  tableFooterCell: {
    padding: theme.spacing(1, 2),
    height: 20
  }
}));

const useColumnStyles = makeStyles(theme => ({
  name: {
    maxWidth: 250,
    overflow: 'hidden',
    borderRight: `1px solid ${theme.palette.table.border}`,
    fontWeight: 500
  },
  description: {
    maxWidth: 400,
    overflow: 'hidden'
  },
  requestedBy: {
    maxWidth: 250,
    overflow: 'hidden'
  },
  createdBy: {
    maxWidth: 250
  }
}));

const useListTableStyles = makeStyles({
  card: {
    overflow: 'inherit'
  }
});
const useHeaderClasses = makeStyles(theme => ({
  bottomHeader: {
    top: theme.spacing(17) + 1
  },
  topLeftHeader: {
    zIndex: 2
  }
}));
const useStyles = makeStyles(theme => ({
  addProjectRequestDiv: {
    paddingTop: theme.spacing(1)
  },
  addIcon: {
    position: 'relative',
    top: 6
  }
}));

export const ProjectRequestListTable = ({
  enabledFields,
  enabledColumnGroups,
  projectRequests,
  hasMore,
  loadMore,
  isLoading,
  sortCriteria,
  onSortChange,
  onRowClick,
  listClasses,
  listHeaderClasses,
  isExisting,
  totals
}) => {
  const columnClasses = useColumnStyles();
  const providerClasses = useListTableThemeProviderStyles();
  const listTableClasses = useListTableStyles();
  const headerClasses = useHeaderClasses();
  const { headers, columns, footers } = computeTableColumns({
    columnSpec: enabledColumnGroups,
    renders: getFormatters(isExisting)
  })({
    enabledFields,
    columnClasses,
    headerClasses: {
      ...headerClasses,
      ...listHeaderClasses
    }
  });

  const { canEditProjectRequest } = useProjectRequestPermissions();
  const classes = useStyles();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const values = useMemo(
    () => ({
      value: isPsaPrpAccessibilityIssuesEnabled ? (
        <AddIcon
          className={classes.addIcon}
          color="inherit"
          aria-hidden="false"
          aria-label="add"
        />
      ) : (
        <AddIcon className={classes.addIcon} color="inherit" />
      )
    }),
    [classes.addIcon, isPsaPrpAccessibilityIssuesEnabled]
  );

  const overrideClasses = useMemo(
    () => ({ ...providerClasses, ...listClasses }),
    [listClasses, providerClasses]
  );

  return (
    <div>
      {isLoading || projectRequests.length ? (
        <ListTableThemeProvider classes={overrideClasses}>
          <ListTable
            classes={listTableClasses}
            headers={headers}
            columns={columns}
            footers={footers}
            totals={totals}
            records={projectRequests}
            hasMore={hasMore}
            loadMore={loadMore}
            isLoading={isLoading}
            useInlineStyles={false}
            mobileListItem={MobileProjectRequestListItem}
            onListItemClick={onRowClick}
            variant="table"
            sort={sortCriteria}
            onSortChange={onSortChange}
            onRowClick={onRowClick}
          />
        </ListTableThemeProvider>
      ) : (
        <Card>
          <CardContent>
            <NoDataItem fontStyle="normal">
              <FormattedMessage id="projectRequest.noMatchingProjectRequest" />
            </NoDataItem>
            {canEditProjectRequest && !isExisting ? (
              <div className={classes.addProjectRequestDiv}>
                <NoDataItem fontStyle="normal">
                  <FormattedMessage
                    id="projectRequest.clickToAddProjectRequest"
                    values={values}
                  />
                </NoDataItem>
              </div>
            ) : null}
          </CardContent>
        </Card>
      )}
    </div>
  );
};
ProjectRequestListTable.propTypes = {
  enabledFields: PropTypes.arrayOf(PropTypes.string),
  enabledColumnGroups: PropTypes.array,
  projectRequests: PropTypes.array,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  sortCriteria: ListTablePropTypes.sort,
  onRowClick: PropTypes.func,
  listClasses: PropTypes.object,
  listHeaderClasses: PropTypes.object,
  isExisting: PropTypes.bool,
  totals: PropTypes.object
};
export default ProjectRequestListTable;
