import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import { IconButton, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useIsBreakpointUp } from '~/modules/common/hooks/useBreakpoint';
import { useGoBackContext } from '~/modules/navigation/GoBackContext';

const useStyles = makeStyles(theme => ({
  back: {}
}));

export const GoBackButton = ({ to, contextual, classes, dataQeId }) => {
  const { formatMessage } = useIntl();
  const cls = useStyles({ classes });
  const history = useHistory();
  const { search } = useGoBackContext();
  const onClick = useCallback(() => {
    const path = contextual ? `${to}${search}` : to;

    to ? history.push(path) : history.goBack();
  }, [history, to, contextual, search]);
  const isSMUp = useIsBreakpointUp('sm');

  return (
    <IconButton
      color="inherit"
      onClick={onClick}
      aria-label={formatMessage({ id: 'button.back' })}
    >
      <ArrowBackIcon
        data-qe-id={dataQeId}
        className={cls.back}
        fontSize={isSMUp ? 'large' : 'default'}
        mirror="rtl"
      />
    </IconButton>
  );
};

GoBackButton.propTypes = {
  dataQeId: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contextual: PropTypes.bool,
  classes: PropTypes.object
};

export default GoBackButton;
