import React, { useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { v4 } from 'uuid';
import { PropTypes } from 'prop-types';
import { DataTable } from './components';
import ListTablePropTypes from './PropTypes';

const SkeletonRow = () => <Skeleton height={30} />;

const useSkeletonColumnAndRecordMemo = columns =>
  useMemo(() => {
    const keys = Object.keys(columns);

    const skeletonColumns = keys.reduce(
      (cols, key) => ({
        ...cols,
        [key]: {
          ...columns[key],
          render: SkeletonRow
        }
      }),
      {}
    );

    const skeletonRecord = keys.reduce(record => record, {});

    return {
      columns: skeletonColumns,
      record: skeletonRecord
    };
  }, [columns]);

export const ListTableSkeleton = ({
  headers,
  footers,
  columns: listColumns,
  showTableHeader = true
}) => {
  const { columns, record } = useSkeletonColumnAndRecordMemo(listColumns);

  const records = useMemo(
    () => [
      { ...record, key: v4() },
      { ...record, key: v4() },
      { ...record, key: v4() },
      { ...record, key: v4() },
      { ...record, key: v4() },
      { ...record, key: v4() }
    ],
    [record]
  );

  return (
    <DataTable
      headers={headers}
      footers={footers}
      columns={columns}
      records={records}
      showTableHeader={showTableHeader}
      loading
    />
  );
};

ListTableSkeleton.propTypes = {
  headers: ListTablePropTypes.headers,
  footers: ListTablePropTypes.footers,
  columns: ListTablePropTypes.columns,
  showTableHeader: PropTypes.bool
};

export default ListTableSkeleton;
