import { useMeContext } from '~/modules/me';
import { useEligibleClientManagersQuery } from '~/modules/clients/graphql';
import { useCanEditAllClients } from './useCanEditAllClients';

export const useClientManagers = ({ clientId, currentClientManager }) => {
  const me = useMeContext();
  const canEditAllClients = useCanEditAllClients();

  const {
    clientManagers: eligibleClientManagers
  } = useEligibleClientManagersQuery({
    clientId,
    currentClientManager
  });

  return canEditAllClients
    ? eligibleClientManagers
    : eligibleClientManagers.filter(cm => cm.id === me.id);
};
