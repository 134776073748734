export default theme => ({
  victoryChart: {
    padding: {
      top: theme.spacing(3),
      bottom: theme.spacing(8),
      left: theme.spacing(7),
      right: theme.spacing(0)
    },
    domainPadding: { x: [0, 55], y: 10 },
    width: 500,
    height: 275,
    scaleXTime: { x: 'time' }
  },
  victoryAxis: {
    x: {
      tickLabels: {
        padding: 2,
        fontSize: 10,
        fontFamily: theme.typography.fontFamily,
        verticalAnchor: 'middle',
        textAnchor: 'start',
        fill: theme.palette.common.black,
        angle: '45'
      },
      grid: {
        stroke: 'clear'
      },
      axis: {
        stroke: theme.palette.common.black
      }
    },
    y: {
      tickLabels: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 10,
        fill: theme.palette.common.black
      },
      axisLabel: {
        fontSize: 12,
        padding: theme.spacing(4.5),
        fill: theme.palette.common.black
      },
      axis: {
        stroke: theme.palette.common.black
      },
      grid: {
        stroke: 'clear'
      }
    }
  },
  mainContainer: {}
});
