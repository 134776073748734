import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FixedBidReadonly from './FixedBidReadonly';

const useStyles = makeStyles(theme => ({
  message: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h6.fontSize
  }
}));

const BillingReadonly = ({ billingTypeUri, details }) => {
  const classes = useStyles();

  switch (billingTypeUri) {
    case 'urn:replicon:billing-type:fixed-bid':
      return <FixedBidReadonly {...details} />;
    case 'urn:replicon:billing-type:non-billable':
      return (
        <Typography variant="body2" className={classes.message}>
          <FormattedMessage id="projectBilling.empty" />
        </Typography>
      );
    default:
      return (
        <Typography variant="body2" className={classes.message}>
          Unexpected Billing Type:
          <br />
          {billingTypeUri}
        </Typography>
      );
  }
};

BillingReadonly.propTypes = {
  billingTypeUri: PropTypes.string.isRequired,
  details: PropTypes.any
};

export default BillingReadonly;
