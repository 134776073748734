import { useState, useCallback } from 'react';

export const useMenuState = () => {
  const [anchorEl, setAnchorE1] = useState(null);

  return {
    anchorEl,
    onMenuOpen: useCallback(anchor => {
      setAnchorE1(anchor);
    }, []),
    onMenuClose: useCallback(() => {
      setAnchorE1(null);
    }, []),
    onMenuClick: useCallback(event => {
      setAnchorE1(event && event.target ? event.target : null);
    }, [])
  };
};

export default useMenuState;
