import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
  label: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    opacity: '0.7'
  },
  container: {
    maxHeight: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'column'
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  labelPadding: {
    paddingBottom: 1
  },
  padding: {
    paddingLeft: theme.spacing(1)
  },
  verticalCenter: {
    margin: 'auto 0'
  }
});

const isCentered = verticalAlign => verticalAlign === 'center';
const ReadOnlyContainer = ({
  label,
  children,
  opacity,
  classes,
  padding = true,
  minHeight = 'unset',
  className,
  verticalAlign = 'center',
  underline = false
}) => (
  <div
    className={classNames(
      classes.container,
      underline && classes.underline,
      padding && classes.padding,
      className
    )}
    style={{ opacity, minHeight }}
  >
    <div className={isCentered(verticalAlign) && classes.verticalCenter}>
      {label && (
        <div
          className={classNames(classes.label, padding && classes.labelPadding)}
        >
          {label}
        </div>
      )}
      {children}
    </div>
  </div>
);

ReadOnlyContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  label: PropTypes.node,
  minHeight: PropTypes.string,
  underline: PropTypes.bool,
  padding: PropTypes.bool,
  opacity: PropTypes.number,
  verticalAlign: PropTypes.oneOf(['normal', 'center']),
  className: PropTypes.string
};
export default withStyles(styles)(ReadOnlyContainer);
