import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles } from '@material-ui/core';
import { TASK_STATUS } from '~/modules/common/enums';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import VariationCell from './VariationCell';

const useStyles = makeStyles({
  header: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingLeft: '0 !important'
  },
  container: {
    width: '100%',
    margin: 0
  },
  content: ({ addMargin }) => ({
    marginRight: addMargin ? theme.spacing(3.25) : 0
  })
});

export const MobileTaskVariationRow = ({
  task,
  hasMore,
  loadMore,
  loadingMore,
  classes: classesOverride,
  hideTooltip = false,
  variation,
  variationStatus,
  selectedColumns,
  variant
}) => {
  const { children, taskStatus } = task;
  const isChild = children.length === 0;
  const isNotStarted = taskStatus === TASK_STATUS.NOTSTARTED;

  const classes = useStyles({ classes: classesOverride });

  return (
    <Grid
      container
      className={classes.container}
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid item className={classes.header}>
        <FormattedMessage id="taskDrawer.variation" />:
      </Grid>
      <Grid item className={classes.content}>
        <VariationCell
          variation={variation}
          variationStatus={variationStatus}
          hideTooltip={hideTooltip}
          isChild={isChild}
          isNotStarted={isNotStarted}
          childTasks={task.children}
          selectedColumns={selectedColumns}
          hasMore={hasMore}
          loadMore={loadMore}
          loadingMore={loadingMore}
          variant={variant}
        />
      </Grid>
    </Grid>
  );
};

MobileTaskVariationRow.propTypes = {
  task: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  hideTooltip: PropTypes.bool,
  classes: PropTypes.object,
  variation: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ]),
  variationStatus: PropTypes.string,
  selectedColumns: PropTypes.array,
  variant: PropTypes.oneOf(['hours', 'cost', 'dates'])
};

export default MobileTaskVariationRow;
