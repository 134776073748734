/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { LoadingButton } from '~/modules/common/components';
import CertificateEditable from './CertificateEditable';

const useStyles = makeStyles(theme => ({
  addButton: {
    marginRight: theme.spacing(2)
  }
}));

export const AddCertificateDialogContent = ({
  onCancel,
  submitError,
  isSubmitting
}) => {
  const classes = useStyles();

  const { handleSubmit, errors, values } = useFormikContext();

  const saveable = useMemo(
    () =>
      Object.keys(errors).length === 0 &&
      values &&
      Object.keys(values).length > 0 &&
      values.name,
    [errors, values]
  );

  return (
    <DialogContent>
      <CertificateEditable submitError={submitError} readOnly={isSubmitting} />
      <DialogActions>
        <Button
          data-qe-id="LicenseCancelButton"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          data-qe-id="LicenseAddButton"
          className={classes.addButton}
          color="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={isSubmitting || !saveable}
        >
          <FormattedMessage id="button.add" />
        </LoadingButton>
      </DialogActions>
    </DialogContent>
  );
};

AddCertificateDialogContent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  submitError: PropTypes.object,
  isSubmitting: PropTypes.bool
};

export default AddCertificateDialogContent;
