import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { getScheduleTotals } from '~/modules/resourcing/common/util';
import { buildUpdatedScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { ChartBlockLabel } from '~/modules/common/charts/timeline/components';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import useStyles from './useStyles';

export const RequestBlock = memo(
  ({
    classes: classesOverrides,
    resourceRequest: {
      load,
      startDate,
      endDate,
      quantity,
      scheduleRules,
      requestStatus
    },
    scale,
    chartStartDate,
    projectDefaultScheduleRule,
    children,
    showPercentage = true
  }) => {
    const classes = useStyles({
      classes: classesOverrides,
      status: requestStatus
    });
    const { dynamicPosition } = getDynamicPositionAttributes({
      start: DateTime.fromISO(startDate).toUTC(),
      end: DateTime.fromISO(endDate).toUTC(),
      scale,
      chartStartDate
    });
    const { totalHours } = useMemo(
      () =>
        getScheduleTotals({
          scheduleRules: buildUpdatedScheduleRules({
            scheduleRules,
            start: DateTime.fromISO(startDate).toUTC(),
            end: DateTime.fromISO(endDate).toUTC(),
            defaultScheduleRule: projectDefaultScheduleRule
          }),
          quantity,
          load
        }),
      [
        endDate,
        load,
        quantity,
        scheduleRules,
        startDate,
        projectDefaultScheduleRule
      ]
    );

    const labelClasses = useMemo(
      () => ({
        loadingLabel: classes.loadingLabel
      }),
      [classes.loadingLabel]
    );

    return (
      <div className={classes.root} style={dynamicPosition}>
        <div
          className={classNames(classes.container, classes.readOnlyContainer)}
        >
          <ChartBlockLabel
            classes={labelClasses}
            load={load}
            hours={totalHours}
            showPercentage={showPercentage}
          />
          {children}
        </div>
      </div>
    );
  }
);

RequestBlock.propTypes = {
  classes: PropTypes.object,
  projectDefaultScheduleRule: PropTypes.object.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  children: PropTypes.node,
  showPercentage: PropTypes.bool,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object
};

export default RequestBlock;
