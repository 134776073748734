import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ResourcePoolIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 3 2 L 3 22 L 21 22 L 21 2 L 3 2 z M 8 4 C 8.8249984 4 9.5 4.6750016 9.5 5.5 C 9.5 6.3249984 8.8249984 7 8 7 C 7.1750016 7 6.5 6.3249984 6.5 5.5 C 6.5 4.6750016 7.1750016 4 8 4 z M 16 4 C 16.824998 4 17.5 4.6750016 17.5 5.5 C 17.5 6.3249984 16.824998 7 16 7 C 15.175002 7 14.5 6.3249984 14.5 5.5 C 14.5 4.6750016 15.175002 4 16 4 z M 8 7.75 C 8.7424986 7.75 9.4484382 7.9080475 10.085938 8.1855469 C 10.640936 8.4255463 11 8.9647668 11 9.5722656 L 11 11 L 5 11 L 5 9.5722656 C 5 8.9647668 5.3590637 8.4255463 5.9140625 8.1855469 C 6.5515613 7.9080475 7.2575014 7.75 8 7.75 z M 16 7.75 C 16.742498 7.75 17.448438 7.9080475 18.085938 8.1855469 C 18.640936 8.4255463 19 8.9647668 19 9.5722656 L 19 11 L 13 11 L 13 9.5722656 C 13 8.9647668 13.359064 8.4255463 13.914062 8.1855469 C 14.551562 7.9080475 15.257502 7.75 16 7.75 z M 8 13 C 8.8249984 13 9.5 13.675002 9.5 14.5 C 9.5 15.324998 8.8249984 16 8 16 C 7.1750016 16 6.5 15.324998 6.5 14.5 C 6.5 13.675002 7.1750016 13 8 13 z M 16 13 C 16.824998 13 17.5 13.675002 17.5 14.5 C 17.5 15.324998 16.824998 16 16 16 C 15.175002 16 14.5 15.324998 14.5 14.5 C 14.5 13.675002 15.175002 13 16 13 z M 8 16.75 C 8.7424986 16.75 9.4484382 16.908047 10.085938 17.185547 C 10.640936 17.425547 11 17.964766 11 18.572266 L 11 20 L 5 20 L 5 18.572266 C 5 17.964766 5.3590637 17.425547 5.9140625 17.185547 C 6.5515613 16.908047 7.2575014 16.75 8 16.75 z M 16 16.75 C 16.742498 16.75 17.448438 16.908047 18.085938 17.185547 C 18.640936 17.425547 19 17.964766 19 18.572266 L 19 20 L 13 20 L 13 18.572266 C 13 17.964766 13.359064 17.425547 13.914062 17.185547 C 14.551562 16.908047 15.257502 16.75 16 16.75 z " />
    </>
  </SvgIcon>
));

export default ResourcePoolIcon;
