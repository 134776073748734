import { useDecimalPrecision } from '~/modules/common/components/Decimal/usePrecision';

const useIsBudgetExceeded = ({ budget, actuals }) => {
  const budgetValue = useDecimalPrecision({
    value: budget
  });
  const actualsValue = useDecimalPrecision({
    value: actuals
  });

  return actualsValue > budgetValue;
};

export default useIsBudgetExceeded;
