import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  KPIIcon,
  ProgressIcon,
  ProfitIcon
} from '~/modules/common/components/Icons';
import {
  ChartCard,
  ProgressSummaryChartHelp
} from '~/modules/common/charts/dashboard';
import ProjectRiskSummary from '~/modules/project-risk-summary';
import { ProjectOverviewChart, ProjectProgressChart } from '../charts';
import { BudgetSummaryChart } from '../charts/BudgetChart';
import { useProjectInfoLGUpViewLayout } from './useProjectInfoLGUpViewLayout';

const useStyles = makeStyles(theme => ({
  firstColumn: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1)
    },
    paddingRight: 0
  },
  secondColumn: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1)
    },
    paddingLeft: 0
  },
  item: {
    padding: theme.spacing(1)
  }
}));

const useChartCardStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const progressChartTitle = <FormattedMessage id="projectPage.progress" />;
const progressChartAction = projectSummaryChartProps => (
  <ProgressSummaryChartHelp
    projectSummaryChartProps={projectSummaryChartProps}
  />
);

const icons = {
  progress: <ProgressIcon />,
  overview: <KPIIcon />,
  profit: <ProfitIcon />
};

export const ProjectInfoLGUpViewView = ({
  visibleCards: allVisibleCards,
  projectDetails,
  canViewBudgetChart,
  overViewChartDataProps,
  progressChartDataProps,
  canViewBillingData
}) => {
  const classes = useStyles();
  const chartClasses = useChartCardStyles();
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: { isPsaPpmCostEacEnhancementsEnabled }
  } = useMeContext();

  const {
    thirdColumnVisibleCards,
    secondColumnVisibleCards
  } = useProjectInfoLGUpViewLayout(
    allVisibleCards,
    isPsaPpmCostEacEnhancementsEnabled
  );

  const overviewChartTitle = useMemo(
    () => (
      <FormattedMessage
        id={
          !canViewBillingData
            ? 'projectPage.projectCost'
            : isRolledUpTaskEstimateCalculationMethodEnabled
            ? 'projectPage.profitability'
            : 'projectPage.overview'
        }
      />
    ),
    [canViewBillingData, isRolledUpTaskEstimateCalculationMethodEnabled]
  );

  const projectSummaryChartProps = useMemo(
    () => ({
      showCostContent: progressChartDataProps.showCostContent,
      projectProgressCardSettingsLoading:
        progressChartDataProps.projectProgressCardSettingsLoading,
      projectProgressView: progressChartDataProps.projectProgressView,
      saveProjectProgressCardSetting:
        progressChartDataProps.saveProjectProgressCardSetting
    }),
    [
      progressChartDataProps.showCostContent,
      progressChartDataProps.projectProgressCardSettingsLoading,
      progressChartDataProps.projectProgressView,
      progressChartDataProps.saveProjectProgressCardSetting
    ]
  );

  return (
    <Hidden mdDown>
      <Grid
        item
        xs={12}
        lg={thirdColumnVisibleCards.length > 0 ? 8 : 12}
        className={classes.item}
      >
        {isRolledUpTaskEstimateCalculationMethodEnabled && (
          <Grid item xs={12} sm={12} lg={12}>
            <ChartCard
              chartTitle={progressChartTitle}
              classes={chartClasses}
              chartAvatar={icons.progress}
              chartDataProps={progressChartDataProps}
              ChartComponent={ProjectProgressChart}
              showEntireProjectScaleOption
              chartAction={progressChartAction(projectSummaryChartProps)}
            />
          </Grid>
        )}
        <ProjectRiskSummary
          projectUri={projectDetails.projectId}
          projectEndDate={projectDetails.endDate}
          classes={chartClasses}
        />
        <Grid item xs={12} sm={12} lg={12}>
          <ChartCard
            chartTitle={overviewChartTitle}
            classes={chartClasses}
            chartAvatar={
              canViewBillingData &&
              isRolledUpTaskEstimateCalculationMethodEnabled
                ? icons.profit
                : icons.progress
            }
            chartDataProps={overViewChartDataProps}
            ChartComponent={ProjectOverviewChart}
            showPeriodFilter
            showScaleSelector
            showEntireProjectScaleOption
          />
        </Grid>
        {canViewBudgetChart && (
          <BudgetSummaryChart projectDetails={projectDetails} />
        )}
        {secondColumnVisibleCards.length > 0 && (
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.firstColumn}>
              {secondColumnVisibleCards.filter((_, index) => index % 2 === 0)}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.secondColumn}>
              {secondColumnVisibleCards.filter((_, index) => index % 2 === 1)}
            </Grid>
          </Grid>
        )}
      </Grid>
      {thirdColumnVisibleCards.length > 0 && (
        <Grid item xs={12} sm={6} lg={4} className={classes.item}>
          {thirdColumnVisibleCards}
        </Grid>
      )}
    </Hidden>
  );
};

ProjectInfoLGUpViewView.propTypes = {
  visibleCards: PropTypes.array.isRequired,
  projectDetails: PropTypes.object,
  canViewBudgetChart: PropTypes.bool,
  overViewChartDataProps: PropTypes.object,
  progressChartDataProps: PropTypes.object,
  canViewBillingData: PropTypes.bool
};

export default ProjectInfoLGUpViewView;
