import React from 'react';
import PropTypes from 'prop-types';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import { usePeriodTimelineBlocks } from '~/modules/resourcing/common/chart/hooks';
import { useResourceAllocationDragIndicators } from '~/modules/resourcing/common/hooks';
import GenericTimelineBlocks from './components/GenericTimelineBlocks';

export const ResourceAllocationTimelineBlocks = ({
  allocation,
  chartDisplayDateRange,
  chartDisplayPeriods,
  forceReadOnlyOverlays,
  handleAllocationPeriodClick,
  isEditable,
  onAllocationChange,
  resourceAvailabilitySummary,
  resourceRequest = {},
  scale,
  timeOffHolidaySummaryBlocksComponent
}) => {
  const { displayUnit } = useDisplayUnitContext();

  const { overlayPeriods, allocationPeriods } = usePeriodTimelineBlocks({
    allocation,
    chartDisplayDateRange,
    chartDisplayPeriods,
    scale
  });

  const dragIndicatorHandlers = useResourceAllocationDragIndicators({
    resourceAllocation: allocation,
    chartStartDate: chartDisplayDateRange.startDate,
    scale,
    onAllocationChange
  });

  const isReadOnly = forceReadOnlyOverlays || !isEditable;

  return (
    <GenericTimelineBlocks
      allocation={allocation}
      handleAllocationPeriodClick={handleAllocationPeriodClick}
      isEditable={isEditable}
      resourceAvailabilitySummary={resourceAvailabilitySummary}
      resourceRequest={resourceRequest}
      scale={scale}
      timeOffHolidaySummaryBlocksComponent={
        timeOffHolidaySummaryBlocksComponent
      }
      isReadOnly={isReadOnly}
      displayUnit={displayUnit}
      overlayPeriods={overlayPeriods}
      allocationPeriods={allocationPeriods}
      dragIndicatorHandlers={dragIndicatorHandlers}
    />
  );
};

ResourceAllocationTimelineBlocks.propTypes = {
  scale: PropTypes.string.isRequired,
  allocation: PropTypes.object,
  handleAllocationPeriodClick: PropTypes.func,
  chartDisplayDateRange: PropTypes.object,
  forceReadOnlyOverlays: PropTypes.bool,
  isEditable: PropTypes.bool,
  onAllocationChange: PropTypes.func.isRequired,
  chartDisplayPeriods: PropTypes.array,
  timeOffHolidaySummaryBlocksComponent: PropTypes.node,
  resourceRequest: PropTypes.object,
  resourceAvailabilitySummary: PropTypes.object
};

export default ResourceAllocationTimelineBlocks;
