import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedNumberInput } from '~/modules/common/components/NumberInput/FormattedNumberInput';
import CustomFieldValuePropType from './CustomFieldValuePropType';

const useStyles = makeStyles(theme => ({
  input: ({ customFieldValue }) => ({
    textOverflow: 'ellipsis',
    color: customFieldValue.text ? theme.palette.text.primary : 'inherit'
  }),
  dateFieldInput: {},
  root: {},
  field: {},
  label: {}
}));

export const ReadOnlyCustomField = ({
  classes: classesOverride,
  customFieldValue,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride, customFieldValue });

  const value = customFieldValue.text;

  const label = customFieldValue.customField.displayText;

  const isNumericType =
    customFieldValue.customFieldType.id ===
    'urn:replicon:custom-field-type:numeric';

  const textFieldProps = {
    disabled: true,
    fullWidth: true,
    label,
    InputProps: {
      disableUnderline: true,
      inputComponent: isNumericType && value ? FormattedNumberInput : undefined
    },
    inputProps: {
      className: classes.input
    },
    ...rest
  };

  return <TextField value={value || '–'} {...textFieldProps} />;
};

ReadOnlyCustomField.propTypes = {
  classes: PropTypes.object,
  customFieldValue: CustomFieldValuePropType
};

export default ReadOnlyCustomField;
