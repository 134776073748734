import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, DialogContentText, TableCell } from '@material-ui/core';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import { useDialogState } from '~/modules/common/hooks';
import RemoveEntityConfirmationDialog from '~/modules/common/components/RemoveEntityConfirmationDialog';

const useStyles = makeStyles(theme => ({
  removeIcon: {
    color: theme.palette.secondary.main
  },
  iconButton: {
    padding: 0
  },
  cell: {
    borderBottom: 'none',
    padding: theme.spacing(0.5)
  },
  categoryCell: {
    borderBottom: 'none',
    padding: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightMedium,
    position: 'sticky',
    left: 0,
    backgroundColor: 'inherit'
  },
  categorySkillRating: {
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center',
    borderBottom: 'none',
    fontSize: '11px'
  },
  removeIconCell: {
    borderBottom: 'none',
    textAlign: 'right',
    padding: theme.spacing(0.5),
    '&:last-child': {
      paddingRight: '5px'
    },
    position: 'sticky',
    right: 0,
    backgroundColor: theme.palette.common.white
  }
}));

const deleteDialogResourceKeys = {
  header: 'skillInfoCard.deleteSkillDialog.removeCategory',
  actionButton: 'skillInfoCard.deleteSkillDialog.removeCategory'
};

export const SkillCategory = ({
  classes: classesOverride,
  category,
  onCategoryRemove,
  skillSetting = []
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);
  const onConfirm = useCallback(() => {
    onCategoryRemove(category);
  }, [category, onCategoryRemove]);

  return (
    <>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onConfirm}
        >
          <DialogContentText>
            <FormattedMessage id="skillInfoCard.deleteSkillDialog.removeCategoryDescription" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
      <TableCell className={classes.categoryCell}>
        {category ? (
          <span>{category.displayText}</span>
        ) : (
          <FormattedMessage id="skillInfoCard.unCategorized" />
        )}
      </TableCell>
      <TableCell className={classes.cell} />
      <TableCell className={classes.categorySkillRating}>
        {Boolean(category) && (
          <FormattedMessage id="resourceRequestDrawerDetails.skillRating" />
        )}
      </TableCell>
      {skillSetting.map(setting => (
        <TableCell className={classes.cell} key={setting.uri} />
      ))}
      <TableCell className={classes.removeIconCell}>
        {category ? (
          <IconButton
            data-qe-id={`${category.displayText}_Category_Remove`}
            className={classes.iconButton}
            onClick={openRemoveDialog}
            aria-label={formatMessage({ id: 'button.remove' })}
          >
            <RemoveCircle />
          </IconButton>
        ) : (
          <IconButton className={classes.iconButton} onClick={openRemoveDialog}>
            <RemoveCircle />
          </IconButton>
        )}
      </TableCell>
    </>
  );
};

SkillCategory.propTypes = {
  classes: PropTypes.object,
  category: PropTypes.object,
  onCategoryRemove: PropTypes.func,
  skillSetting: PropTypes.array
};

export default SkillCategory;
