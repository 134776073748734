import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import User from '~/modules/common/components/User';

const useStyles = makeStyles(theme => ({
  user: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'break-spaces'
  }
}));

export const UserFormatter = ({ field, record }) => {
  const classes = useStyles();
  const userClasses = useMemo(() => ({ name: classes.user }), [classes.user]);

  return record[field] && record[field].displayText ? (
    <User user={record[field]} classes={userClasses} />
  ) : (
    ''
  );
};
UserFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default UserFormatter;
