import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  period: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    padding: 0,
    lineHeight: `${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    overflow: 'visible',
    borderRight: `1px solid rgb(255,255,255,0.2)`,
    '&:last-child': {
      borderRight: 'none'
    },
    '&:hover $resizeHandle': {
      opacity: 1
    },
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  periodClickable: {
    cursor: 'pointer'
  },
  loadingLabel: {
    flexGrow: 0,
    flexShrink: 1,
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 0.25),
    display: 'block',
    position: 'sticky',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: theme.spacing(0, 0, 1, 0)
  },
  resizeHandle: {
    cursor: 'ew-resize',
    top: theme.spacing(-0.75),
    position: 'absolute',
    opacity: 0,
    zIndex: 1,
    overflow: 'hidden',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  resizeIcon: {
    top: theme.spacing(0.25)
  },
  resizeHandleStart: {
    top: theme.spacing(-1),
    left: theme.spacing(-0.5)
  },
  resizeHandleEnd: {
    top: theme.spacing(-1),
    left: `calc(100% - ${theme.spacing(2.5)}px)`
  }
}));

export default useStyles;
