import { useState, useCallback } from 'react';
import { debounce, scope } from '~/modules/common/debounce';
import { useRoles } from '~/modules/common/hooks';

const searchPhraseDebounceScope = scope();

const useRoleSearch = ({ includeSkills = false }) => {
  const [searchText, setSearchText] = useState('');
  const [searchPhrase, setSearchPhrase] = useState('');
  const { isLoading: loading, hasMore, defaultOptions: activeRoles } = useRoles(
    {
      fetchPolicy: 'no-cache',
      searchTerm: searchPhrase,
      includeSkills
    }
  );

  const updateSearchText = useCallback(
    phrase => {
      setSearchText(phrase);
      debounce(
        searchPhraseDebounceScope,
        () => {
          setSearchPhrase(phrase);
        },
        500
      );
    },
    [setSearchText, setSearchPhrase]
  );

  return {
    searchText,
    updateSearchText,
    loading,
    activeRoles,
    hasMore
  };
};

export default useRoleSearch;
