import React from 'react';
import { PropTypes } from 'prop-types';
import SkillRow from './SkillRow';
import ResourceRequestSkillRow from './ResourceRequestSkillRow';

export const SkillsForm = ({
  skills,
  category,
  certificates,
  userDetails,
  skillSetting,
  maxSkillLevel = 5,
  isResourceRequestSkillRow = false,
  ...rest
}) => (
  <>
    {skills
      .filter(skill => skill.category.id === category.id)
      .map(skill =>
        isResourceRequestSkillRow ? (
          <ResourceRequestSkillRow
            key={skill.id}
            maxSkillLevel={maxSkillLevel}
            skill={skill}
            {...rest}
          />
        ) : (
          <SkillRow
            key={skill.id}
            skill={skill}
            certificates={certificates}
            userDetails={userDetails}
            maxSkillLevel={maxSkillLevel}
            skillSetting={skillSetting}
            {...rest}
          />
        )
      )}
  </>
);

SkillsForm.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
  category: PropTypes.object.isRequired,
  certificates: PropTypes.array,
  userDetails: PropTypes.object,
  skillSetting: PropTypes.array,
  isResourceRequestSkillRow: PropTypes.bool,
  maxSkillLevel: PropTypes.number
};

export default SkillsForm;
