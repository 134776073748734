/* eslint-disable max-nested-callbacks */
import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  makeStyles,
  Typography,
  TextField,
  InputAdornment,
  Popper
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFilterOptions } from '@material-ui/lab';
import classNames from 'classnames';
import { UserAvatar } from '~/modules/common/components';
import { UserWithRoles } from '~/modules/common/components/User';
import { SearchAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import useTaskOwnerSearch from './useTaskOwnerSearch';

export const NoneText = () => (
  <Typography variant="body2" color="textSecondary">
    <FormattedMessage id="projectTasksPage.noTaskOwner" />
  </Typography>
);

const useStyles = makeStyles(theme => ({
  noMessage: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 2)
  },
  avatar: {
    marginTop: theme.spacing(-2)
  }
}));

const useSearchAutocompleteStyles = makeStyles(theme => ({
  fontSize: theme.typography.body2.fontSize,
  option: {
    '&[aria-disabled="true"]': {
      opacity: 1
    }
  }
}));

const disableNoResourcesAllocatedTitle = option =>
  option.nonUserOption === 1 || option.uri === null;

const optionString = option => (option?.displayText ? option.displayText : '');

const filterOption = createFilterOptions({
  matchFrom: 'any',
  stringify: optionString
});

const optionGrouping = option => option.role;

const checkIfSelected = (option, value) => option.id === value.id;

const autoCompletePopper = ({ style, ...props }) => {
  return <Popper {...props} style={style} placement="bottom-start" />;
};

const getOptionComponent = noResourcesAllocatedTitle => option =>
  option.nonUserOption === 1 ? (
    noResourcesAllocatedTitle
  ) : option.uri ? (
    <UserWithRoles user={option} />
  ) : (
    <NoneText />
  );

export const TaskOwnerDropDown = ({
  assignedRole,
  assignedUser,
  avatarClassName,
  onChange,
  projectSlug,
  readOnly,
  variant = 'standard'
}) => {
  const { loading, users } = useTaskOwnerSearch({
    projectSlug
  });

  const { formatMessage } = useIntl();

  const taskOwnerText = formatMessage({
    id: 'projectTasksPage.taskOwner'
  });
  const otherProjResourceText = formatMessage({
    id: 'projectTasksPage.otherProjectResources'
  });

  const classes = useStyles();

  const SearchAutocompleteClasses = useSearchAutocompleteStyles();

  const noResourcesAllocatedTitle = useMemo(
    () =>
      assignedRole ? (
        <div key="no-resources-message" className={classes.noMessage}>
          <FormattedMessage
            id="projectTasksPage.noResourcesAllocated"
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            values={{
              role: <strong>{assignedRole.displayText || ''}</strong>
            }}
          />
        </div>
      ) : null,
    [classes.noMessage, assignedRole]
  );

  const optionComponent = getOptionComponent(noResourcesAllocatedTitle);

  const onOwnerChange = useCallback(
    (event, value, reason) => {
      const ownerKey = value && typeof value.key == 'string' ? value.key : null;

      onChange(ownerKey ? users.filter(r => r.key === ownerKey)[0] : null);
    },
    [onChange, users]
  );

  const userItems = useMemo(() => {
    let anyUserHasSelectedRole = false;

    const userList = users.map(user => {
      const hasRole =
        assignedRole &&
        user.projectRoles.some(
          role => role.projectRole.uri === assignedRole.id
        );

      anyUserHasSelectedRole = anyUserHasSelectedRole || hasRole;

      return {
        ...user,
        role: hasRole ? assignedRole.displayText : otherProjResourceText
      };
    });

    const groupSortDir =
      assignedRole?.displayText.localeCompare(otherProjResourceText) === 1
        ? 1
        : -1;

    return [
      {
        role: otherProjResourceText,
        displayText: '',
        nonUserOption: 0
      },
      ...(assignedRole && !anyUserHasSelectedRole
        ? [
            {
              role: assignedRole.displayText,
              displayText: formatMessage({
                id: 'projectTasksPage.noResourcesAllocated',
                values: assignedRole.displayText
              }),
              nonUserOption: 1
            }
          ]
        : []),
      ...userList
    ].sort(
      (a, b) =>
        groupSortDir * b.role.localeCompare(a.role) ||
        a.displayText.localeCompare(b.displayText)
    );
  }, [users, otherProjResourceText, assignedRole, formatMessage]);

  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);

  const onBlurHandle = event => {
    setIsTextFieldFocused(false);
  };

  const onFocusHandle = event => {
    setIsTextFieldFocused(true);
  };

  const inputLabelShrink = useMemo(() => {
    return assignedUser?.displayText.length > 0 || isTextFieldFocused;
  }, [assignedUser, isTextFieldFocused]);

  const inputField = params => (
    <TextField
      {...params}
      variant={variant}
      onFocus={onFocusHandle}
      onBlur={onBlurHandle}
      label={taskOwnerText}
      InputLabelProps={{
        ...params.InputLabelProps,
        shrink: inputLabelShrink
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            <InputAdornment position="start">
              {assignedUser?.displayText ? (
                <UserAvatar
                  user={assignedUser}
                  size="xxsmall"
                  className={classNames(classes.avatar, avatarClassName)}
                />
              ) : (
                <></>
              )}
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        )
      }}
    />
  );

  return (
    <FormControl fullWidth variant={variant}>
      <SearchAutocomplete
        loading={loading}
        loadingText={formatMessage({ id: 'taskBeatTable.loading' })}
        openOnFocus
        autoHighlight
        disabled={readOnly}
        options={userItems}
        groupBy={optionGrouping}
        getOptionLabel={optionString}
        getOptionDisabled={disableNoResourcesAllocatedTitle}
        getOptionSelected={checkIfSelected}
        renderInput={inputField}
        renderOption={optionComponent}
        filterOptions={filterOption}
        dataQeId="TaskOwnerDropdown"
        onChange={onOwnerChange}
        value={assignedUser}
        PopperComponent={autoCompletePopper}
        classes={SearchAutocompleteClasses}
      ></SearchAutocomplete>
    </FormControl>
  );
};

TaskOwnerDropDown.propTypes = {
  assignedRole: PropTypes.object,
  assignedUser: PropTypes.object,
  avatarClassName: PropTypes.string,
  onChange: PropTypes.func,
  projectSlug: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined'])
};

export default TaskOwnerDropDown;
