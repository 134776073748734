import React, { useMemo } from 'react';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { TabBarLoading } from '~/modules/common/components/DetailsPage';
import { makeTabLink, findTabValue } from './tabLinkUtil';
import useStyles from './useStyles';

export const TabBar = ({
  tabRoutes,
  bgColor,
  variant = 'standard',
  scrollButtons = 'auto',
  isLoading = false,
  noBottomBorder,
  classes: overrideClasses,
  handleTabChange
}) => {
  const match = useRouteMatch();
  const location = useLocation();

  const classes = useStyles({ noBottomBorder, classes: overrideClasses });
  const loadingStyles = useMemo(
    () => ({
      root: classes.root
    }),
    [classes.root]
  );

  const tabs = tabRoutes.filter(t => !t.hidden);

  return isLoading ? (
    <TabBarLoading classes={loadingStyles} />
  ) : (
    <Tabs
      classes={classes}
      value={findTabValue({
        tabRoutes: tabs,
        currentPath: location.pathname,
        match
      })}
      style={bgColor ? { backgroundColor: bgColor } : {}}
      variant={variant}
      scrollButtons={scrollButtons}
      onChange={handleTabChange}
    >
      {tabs.map(makeTabLink(match))}
    </Tabs>
  );
};

TabBar.propTypes = {
  tabRoutes: PropTypes.array.isRequired,
  bgColor: PropTypes.string,
  variant: PropTypes.string,
  scrollButtons: PropTypes.string,
  isLoading: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  classes: PropTypes.object,
  handleTabChange: PropTypes.func
};

export default TabBar;
