import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isInChartDisplayDateRange } from '~/modules/resourcing/common/hooks/useClippingDateRangeUtil';
import TimeOffSummaryBlock from './TimeOffSummaryBlock';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1
  },
  container: { opacity: 0.6 }
}));

export const TimeOffSummaryBlocks = ({
  classes: classesOverride,
  timeoffSummaryPeriods,
  timeOffs,
  scale,
  chartStartDate,
  chartDisplayDateRange,
  showTooltip = true,
  clickable = false
}) => {
  const classes = useStyles({ classes: classesOverride });
  const filteredTimeoffSummaryPeriods = useMemo(
    () =>
      timeoffSummaryPeriods.filter(entry =>
        isInChartDisplayDateRange({
          chartDisplayDateRange,
          entityDateRange: entry
        })
      ),
    [timeoffSummaryPeriods, chartDisplayDateRange]
  );

  return filteredTimeoffSummaryPeriods.map(timeoffSummaryPeriod => (
    <TimeOffSummaryBlock
      key={`${timeoffSummaryPeriod.startDate.toISODate()} - ${
        timeoffSummaryPeriod?.timeOffType.displayText
      } - ${timeoffSummaryPeriod.days} days
          `}
      classes={classes}
      scale={scale}
      chartStartDate={chartStartDate}
      timeoffSummaryPeriod={timeoffSummaryPeriod}
      timeOffs={timeOffs}
      chartDisplayDateRange={chartDisplayDateRange}
      showTooltip={showTooltip}
      clickable={clickable}
    />
  ));
};

TimeOffSummaryBlocks.propTypes = {
  timeoffSummaryPeriods: PropTypes.array.isRequired,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  showTooltip: PropTypes.bool,
  clickable: PropTypes.bool,
  timeOffs: PropTypes.array
};

export default TimeOffSummaryBlocks;
