import { gql } from 'graphql-tag';
import { useRouteMatch } from 'react-router-dom';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useIsMount } from '~/modules/common/hooks';
import projectTotalsFragment2, {
  ALLOCATION_STATUS_LIST,
  RESOURCE_REQUEST_STATUS_LIST
} from '~/modules/resourcing/common/fragments/projectTotalsFragment2';

export const projectTotalsQuery = gql`
  query getProjectTotalsBySlug(
    $projectSlug: String!
    $allocationStatusList: [ResourceAllocationStatus!]!
    $requestStatusList: [ResourceRequestStatus!]!
    $filter: ProjectTotalsFilter
    $includeActualsResourceUsers: Boolean
  ) {
    project(projectSlug: $projectSlug) {
      ...ProjectTotalsFragment2
    }
  }
  ${projectTotalsFragment2}
`;

export const useProjectTotals2 = ({
  filter,
  includeActualsResourceUsers = false
}) => {
  const {
    params: { slug }
  } = useRouteMatch();
  const isMounted = useIsMount();

  const { loading, data, error, networkStatus } = useQuery(projectTotalsQuery, {
    variables: {
      projectSlug: slug,
      allocationStatusList: ALLOCATION_STATUS_LIST,
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      filter,
      includeActualsResourceUsers
    },
    ...(!isMounted && {
      context: {
        debounceKey: 'resource-plan-project-totals',
        debounceTimeout: 5000
      }
    }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });

  return {
    error,
    loading: loading || networkStatus === NetworkStatus.refetch,
    allocationsTotals: data?.project?.allocationsTotals || {},
    resourceRequestsTotals: data?.project?.resourceRequestsTotals
  };
};
