import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip, makeStyles } from '@material-ui/core';
import Decimal from '~/modules/common/components/Decimal';
import Date from '~/modules/common/components/Date';
import UserWithRoleScheduleToolTip from '~/modules/common/components/User/UserWithRoleScheduleToolTip';
import RoleName from './RoleName';

const useStyles = makeStyles(theme => ({
  root: {},
  role: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  noValue: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  additional: {
    marginLeft: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize
  },
  effectiveDate: {
    ...theme.typography.caption,
    display: 'flex',
    color: theme.palette.text.secondary,
    margin: 0
  }
}));

export const mapProjectRole = r => ({
  ...r,
  projectRole: { ...r.projectRole, displayText: r.projectRole.name }
});

export const ResourceRoleSchedule = ({
  effectiveSchedule = {},
  showEffectiveDate = false,
  showTooltip = false
}) => {
  const classes = useStyles();
  const roles = effectiveSchedule.projectRoles || [];

  const primaryRole = useMemo(() => {
    const r = roles.find(s => s.isPrimary);

    return r && mapProjectRole(r);
  }, [roles]);

  const secondaryRoles = roles.filter(s => !s.isPrimary).map(mapProjectRole);

  const title = useMemo(
    () => <UserWithRoleScheduleToolTip effectiveSchedule={effectiveSchedule} />,
    [effectiveSchedule]
  );

  const roleScheduleContent = (
    <span className={classes.root}>
      {primaryRole ? (
        <RoleName
          role={primaryRole.projectRole}
          className={classes.role}
          showTooltip={false}
        />
      ) : (
        <span className={classes.noValue}>
          <FormattedMessage id="resourceRoles.noRole" />
        </span>
      )}
      {secondaryRoles.length > 0 ? (
        <Decimal
          value={secondaryRoles.length}
          precision={0}
          prefix="+"
          className={classes.additional}
        />
      ) : null}
      {showEffectiveDate && effectiveSchedule.effectiveDate && (
        <p className={classes.effectiveDate}>
          <FormattedMessage id="roleInfoCard.asOfDate" />
          &nbsp;
          <Date
            value={effectiveSchedule.effectiveDate}
            className={classes.effectiveDate}
          />
        </p>
      )}
    </span>
  );

  return showTooltip ? (
    <Tooltip title={title}>{roleScheduleContent}</Tooltip>
  ) : (
    roleScheduleContent
  );
};

ResourceRoleSchedule.propTypes = {
  effectiveSchedule: PropTypes.object,
  showEffectiveDate: PropTypes.bool,
  showTooltip: PropTypes.bool
};

export default ResourceRoleSchedule;
