import {
  mdiFileDocumentOutline,
  mdiFileImageOutline,
  mdiFilePdfOutline,
  mdiFileCodeOutline,
  mdiFileWordOutline,
  mdiFileVideoOutline,
  mdiFileMusicOutline,
  mdiFileExcelOutline,
  mdiFilePowerpointOutline,
  mdiEmailOutline
} from '@mdi/js';

const document = mdiFileDocumentOutline;
const email = mdiEmailOutline;
const image = mdiFileImageOutline;
const pdf = mdiFilePdfOutline;
const html = mdiFileCodeOutline;
const word = mdiFileWordOutline;
const video = mdiFileVideoOutline;
const audio = mdiFileMusicOutline;
const excel = mdiFileExcelOutline;
const ppt = mdiFilePowerpointOutline;

export const fileIconMap = {
  txt: document,
  jpg: image,
  png: image,
  pdf,
  tiff: image,
  html,
  doc: word,
  docx: word,
  bmp: image,
  gif: image,
  aac: audio,
  avi: video,
  eml: email,
  heic: image,
  heif: image,
  jpeg: image,
  m4a: video,
  m4v: video,
  mkv: video,
  mov: video,
  mp3: audio,
  mp4: video,
  mpe: video,
  mpeg: video,
  mpg: video,
  odp: document,
  ods: document,
  odt: document,
  oga: video,
  ogg: video,
  ogm: video,
  ogv: video,
  ppt,
  pptx: ppt,
  rtf: document,
  svg: image,
  tif: image,
  weba: video,
  webm: video,
  webp: video,
  xls: excel,
  xlsx: excel
};
