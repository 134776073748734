import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    margin: theme.spacing(2, 0, 0, 0)
  },
  itemRoot: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[100]
  },
  expandButton: {
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing(1),
    margin: theme.spacing(-1, -1, -1, 0),
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  actions: {
    flexBasis: theme.spacing(9),
    flexGrow: 0,
    flexShrink: 0,
    overflow: 'visible',
    paddingLeft: theme.spacing(1)
  },
  expandButtonOpen: {
    transform: 'rotate(180deg)'
  },
  totalContainer: {
    backgroundColor: theme.palette.background.paper
  },
  container: {
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  timeContainerMonth: {
    background: 'unset',
    backgroundColor: theme.palette.background.paper
  },
  timeContainerWeek: {
    background: 'unset',
    backgroundColor: theme.palette.background.paper
  },
  timeContainerDay: {
    background: 'unset',
    backgroundColor: theme.palette.background.paper
  },
  timeContainerQuarter: {
    background: 'unset',
    backgroundColor: theme.palette.background.paper
  }
}));

export default useStyles;
