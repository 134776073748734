import React from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  frame: {
    border: 0,
    height: ({ height }) => height
  }
}));

export const IframeContent = ({ height, title, src }) => {
  const classes = useStyles({ height });

  return <iframe className={classes.frame} title={title} src={src} />;
};

IframeContent.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.number
};

export const IframeContainer = ({ title, src }) => (
  <ReactResizeDetector handleWidth handleHeight>
    {({ width, height }) => (
      <IframeContent height={height} title={title} src={src} />
    )}
  </ReactResizeDetector>
);

IframeContainer.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
};

export default IframeContainer;
