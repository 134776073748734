import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Decimal, Percentage } from '~/modules/common/components';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  period: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: theme.spacing(0.5, 0),
    lineHeight: `${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    borderRight: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
    '&:last-child': {
      borderRight: 'none'
    }
  },
  loadingLabel: {
    flexGrow: 0,
    flexShrink: 1,
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 0.5),
    display: 'block',
    position: 'sticky',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontStyle: ({ isAdjustedLoading }) =>
      isAdjustedLoading ? 'italic' : 'normal'
  }
}));

export const ChartBlockLabel = ({
  classes: classesOverrides,
  load,
  hours,
  qeIdTag,
  showPercentage = true,
  isAdjustedLoading
}) => {
  const classes = useStyles({ classes: classesOverrides, isAdjustedLoading });
  const me = useMeContext();

  const numberSettings = getReactNumberFormatFromMe(me);

  return (
    <div className={classes.period}>
      <span className={classes.loadingLabel}>
        {showPercentage && (
          <>
            <Percentage
              className={classes.percentage}
              dataQeId={`PercentLabel${qeIdTag}`}
              value={load}
              precision={numberSettings.decimalScale || 2}
              fixedDecimalScale={false}
            />
            {isAdjustedLoading && '*'}
            {'  '}-{'  '}
          </>
        )}
        <Decimal
          dataQeId={`HoursLabel${qeIdTag}`}
          value={hours}
          fixedDecimalScale={false}
          precision={numberSettings.decimalScale || 0}
        />
        {'  '}
        <FormattedMessage id="resourceRequestBlockLabel.hours" />
      </span>
    </div>
  );
};

ChartBlockLabel.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  load: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  showPercentage: PropTypes.bool,
  isAdjustedLoading: PropTypes.bool
};

export default ChartBlockLabel;
