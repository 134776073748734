import { DateTime } from 'luxon';

export const dateToReplicon = date => {
  if (date && date.year && date.month && date.day) {
    return {
      year: date.year,
      month: date.month,
      day: date.day
    };
  }

  return date;
};

export const isoToReplicon = string => {
  if (string && typeof string === 'string') {
    return dateToReplicon(DateTime.fromISO(string, { setZone: true }));
  }

  return string;
};
