import React from 'react';
import { PropTypes } from 'prop-types';
import GroupedColumn from './GroupedColumn';

const groupingsIdentity = groupings =>
  groupings.reduce(
    (identity, grouping) =>
      `${identity}${grouping.key}${
        grouping.value && grouping.value.uri
          ? grouping.value.uri
          : grouping.value
      }`,
    ''
  );

const GroupedColumns = ({ settings, records, hasMore, skeletons }) =>
  records.map((record, index, nonemptyRecords) => (
    <GroupedColumn
      key={groupingsIdentity(record.groupings)}
      settings={settings}
      records={nonemptyRecords}
      record={record}
      index={index}
      hasMore={hasMore}
      skeletons={skeletons}
    />
  ));

GroupedColumns.propTypes = {
  settings: PropTypes.object,
  records: PropTypes.array,
  hasMore: PropTypes.bool,
  skeletons: PropTypes.array
};

export default GroupedColumns;
