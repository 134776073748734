import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { SimpleDropdown } from '~/modules/common/components';
import { useGetAvailableEarnedRevenueScripts } from './hooks/useGetAvailableEarnedRevenueScripts';

const useStyles = makeStyles(theme => ({
  field: {
    padding: 0
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const label = <FormattedMessage id="projectAddForm.contractType" />;

export const EarnedRevenueDropdown = ({ name, onChange, value }) => {
  const classes = useStyles();
  const {
    availableEarnedRevenueScripts
  } = useGetAvailableEarnedRevenueScripts();

  return (
    <SimpleDropdown
      name={name}
      onChange={onChange}
      value={value}
      options={availableEarnedRevenueScripts}
      noneOption
      classes={classes}
      label={label}
      variant="filled"
    />
  );
};

EarnedRevenueDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default EarnedRevenueDropdown;
