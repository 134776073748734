import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StickyHeader from '../StickyHeader/StickyHeader';
import NotSupportedSVG from './mobileIcons.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: { lineHeight: 1 },
  notSupported: {},
  container: {
    margin: theme.spacing(4, 2),
    textAlign: 'center'
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    opacity: 0.5
  }
}));

export const NotSupportedPage = ({ pageTitle }) => {
  const intl = useIntl();
  const title = <FormattedMessage id={pageTitle} />;

  const classes = useStyles();

  return (
    <>
      <StickyHeader showMenuButton title={title} />
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography variant="h6" className={classes.title}>
            <FormattedMessage id="notSupportedPage.title" />
          </Typography>
          <Typography component="p" variant="body1" className={classes.message}>
            <FormattedMessage id="notSupportedPage.description" />
          </Typography>
          <img
            src={NotSupportedSVG}
            className={classes.notSupported}
            alt={intl.formatMessage({
              id: 'notSupportedPage.alt'
            })}
          />
        </div>
      </div>
    </>
  );
};

NotSupportedPage.propTypes = {
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default NotSupportedPage;
