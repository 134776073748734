import React from 'react';
import PropTypes from 'prop-types';
import { Decimal } from '~/modules/common/components';

export const RequestDrawerAllocatedCostToolTipTitle = ({
  requestedCost,
  allocatedCost = [],
  intl
}) => (
  <span>
    <div>
      {intl.formatMessage({
        id: 'resourceRequestDrawerDetails.totalRequestedCost'
      })}
      <Decimal
        value={requestedCost.amount}
        prefix={requestedCost.currencySymbol || ''}
        tooltip={false}
      />
    </div>
    {allocatedCost && (
      <div>
        {intl.formatMessage({
          id: 'resourceRequestDrawerDetails.totalAllocatedCost'
        })}
        {allocatedCost.map((allocatedCostValue, index) => (
          <>
            <Decimal
              key={allocatedCostValue.currencySymbol}
              value={allocatedCostValue.amount}
              prefix={allocatedCostValue.currencySymbol || ''}
            />
            {index < allocatedCost.length - 1 && allocatedCost.length > 1
              ? ', '
              : ''}
          </>
        ))}
      </div>
    )}
  </span>
);

RequestDrawerAllocatedCostToolTipTitle.propTypes = {
  requestedCost: PropTypes.object.isRequired,
  allocatedCost: PropTypes.array,
  intl: PropTypes.object.isRequired
};
export default RequestDrawerAllocatedCostToolTipTitle;
