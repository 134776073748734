import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useMeContext } from '~/modules/me';
import { OVERBUDGET_OPTION_KEYS } from '~/modules/projects/components/ProjectListPage/Toolbar/OverbudgetFacet/overBudgetOptionKeys';
import OverbudgetExpansionRow from './OverbudgetExpansionRow';
import OverbudgetExpansionDetails from './OverbudgetExpansionDetails';

const useStyles = makeStyles(theme => ({
  viewInList: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginLeft: theme.spacing(1)
  }
}));

const useExpansionRowStyles = makeStyles(() => ({
  align: {
    marginRight: 0,
    maxWidth: 0
  }
}));

export const OverbudgetExpansionPanelContents = ({
  count,
  searchKey,
  onClick
}) => {
  const classes = useStyles();
  const {
    featureFlags: { isPsaPpmCostEacEnhancements2Enabled }
  } = useMeContext();
  const isOverBudgetProjectListVisible =
    isPsaPpmCostEacEnhancements2Enabled &&
    (searchKey === OVERBUDGET_OPTION_KEYS.OVERBUDGET ||
      searchKey === OVERBUDGET_OPTION_KEYS.HOURS_OVERBUDGET);
  const rowClasses = useExpansionRowStyles();

  return (
    <>
      <OverbudgetExpansionDetails
        count={count}
        searchKey={searchKey}
        isOverBudgetProjectListVisible={isOverBudgetProjectListVisible}
      />
      <OverbudgetExpansionRow
        onClick={onClick}
        classes={isOverBudgetProjectListVisible && rowClasses}
      >
        <Typography
          className={classes.viewInList}
          color="primary"
          component="span"
          variant="button"
        >
          <FormattedMessage id="projectOverview.viewInList" />
          <ArrowForward className={classes.icon} fontSize="small" />
        </Typography>
      </OverbudgetExpansionRow>
    </>
  );
};

OverbudgetExpansionPanelContents.propTypes = {
  count: PropTypes.number.isRequired,
  searchKey: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default OverbudgetExpansionPanelContents;
