export const props = {
  MuiAutocomplete: {
    size: 'small'
  },
  MuiListItem: {
    dense: true
  },
  MuiGrid: {
    spacing: 2
  },
  MuiTextField: {
    size: 'small',
    margin: 'none',
    variant: 'filled'
  },
  MuiFormControl: {
    size: 'small',
    margin: 'none',
    variant: 'filled'
  },
  MuiCheckbox: {
    color: 'primary'
  }
};
