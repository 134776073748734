import React from 'react';
import PropTypes from 'prop-types';
import { Grid, List, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';

export const RoleScheduleReadOnlyFormPrimaryRow = ({ item, dataQeId }) => {
  const { formatMessage } = useIntl();
  const { role } = item;

  return (
    <Grid item xs={12}>
      <div>
        <Typography variant="body2" color="textSecondary">
          {formatMessage({ id: 'rolesForm.primaryRole' })}
        </Typography>

        {role ? (
          <List data-qe-id="PrimaryRoleList">
            <div data-qe-id={dataQeId} key={role.displayText}>
              <span>{role.displayText}</span>
            </div>
          </List>
        ) : (
          <NoDataItem>
            {formatMessage({ id: 'roleInfoCard.noRoleAssigned' })}
          </NoDataItem>
        )}
      </div>
    </Grid>
  );
};

RoleScheduleReadOnlyFormPrimaryRow.propTypes = {
  item: PropTypes.object,
  dataQeId: PropTypes.string
};

export default RoleScheduleReadOnlyFormPrimaryRow;
