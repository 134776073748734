import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, List, ListItem, Popover } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  getLuxonJsDateFormatFromMe,
  dateToMidnightUTCString,
  isoStringToObject
} from '~/modules/common/dates/convert';
import Date from '~/modules/common/components/Date';
import DatePicker from '../DatePicker';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  label: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize
    },
    padding: theme.spacing(0, 0.5),
    margin: theme.spacing(0, -0.5),
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.action.hover,
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  },
  date: {
    ...theme.typography.h6,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize
    }
  },
  list: {
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const anchorProps = { vertical: 'top', horizontal: 'left' };

export const DatePickerButton = ({ id, date, onChange }) => {
  const me = useMeContext();
  const classes = useStyles();
  const isoDate = DateTime.fromObject(isoStringToObject(date), {
    setZone: false
  }).toISO();
  const luxonFormat = getLuxonJsDateFormatFromMe(me);
  const currentDate = dateToMidnightUTCString(me.currentTimeInUserTimeZone);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [
    setAnchorEl
  ]);

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handleDateChange = useCallback(
    value => {
      onChange(value);
      handleClose();
    },
    [handleClose, onChange]
  );

  const handleTodayClick = useCallback(() => {
    onChange(currentDate);
    handleClose();
  }, [currentDate, handleClose, onChange]);

  return (
    <div className={classes.root}>
      <ButtonBase
        className={classes.label}
        aria-owns={isOpen ? id : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Date className={classes.date} value={date} />
      </ButtonBase>
      <Popover
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={anchorProps}
        transformOrigin={anchorProps}
        open={isOpen}
        onClose={handleClose}
      >
        <DatePicker
          autoOk
          variant="static"
          inputFormat={luxonFormat}
          value={isoDate}
          onChange={handleDateChange}
        />
        <List className={classes.list} disablePadding>
          <ListItem button onClick={handleTodayClick}>
            <Date value={currentDate} />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

DatePickerButton.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DatePickerButton;
