import { gql } from 'graphql-tag';

const specificResourceAllocationTimeOffFragment = gql`
  fragment SpecificResourceAllocationTimeOff on ResourceAllocationUser {
    timeoffs(dateRange: $chartDateRange) @include(if: $showTimeOff) {
      dateRange {
        startDate
        endDate
      }
      timeOffType {
        displayText
      }
      hours
      days
      entries {
        date
        hours
        days
      }
    }
  }
`;

export default specificResourceAllocationTimeOffFragment;
