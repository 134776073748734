import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECT_REVREC_SETTINGS = gql`
  query getSettings($revenueRunUri: String!) {
    projectRevenueRecognitionBatchUriInProgress2(
      revenueRunUri: $revenueRunUri
    ) {
      batchUri
      batchStatus
    }
  }
`;

export const useGetProjectRevenueRecognitionSettings = revenueRunUri => {
  const { loading, data } = useQuery(GET_PROJECT_REVREC_SETTINGS, {
    variables: {
      revenueRunUri
    },
    skip: revenueRunUri === undefined,
    fetchPolicy: 'network-only'
  });

  return {
    projectRevenueRecognitionBatchUri:
      data &&
      data.projectRevenueRecognitionBatchUriInProgress2 &&
      data.projectRevenueRecognitionBatchUriInProgress2.batchUri
        ? data.projectRevenueRecognitionBatchUriInProgress2.batchUri
        : null,
    projectRevenueRecognitionBatchStatus:
      data &&
      data.projectRevenueRecognitionBatchUriInProgress2 &&
      data.projectRevenueRecognitionBatchUriInProgress2.batchStatus
        ? data.projectRevenueRecognitionBatchUriInProgress2.batchStatus
        : null,
    projectRevenueRecognitionBatchUriLoading: loading
  };
};

export default useGetProjectRevenueRecognitionSettings;
