import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  FormControl,
  MenuItem,
  OutlinedInput
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { PERIOD_SCALE_ENUM } from '~/modules/resourcing/common/enums';

const useFormStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 0, 0, 2),
    flexGrow: 0,
    flexShrink: 0
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0
  },
  select: {
    fontSize: theme.typography.body2.fontSize,
    minHeight: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1, 3, 1, 1.5),
    color: theme.palette.text.secondary
  }
}));

export const getRenderedValues = selectedValue => ({
  period:
    selectedValue === PERIOD_SCALE_ENUM.DAYS ? (
      <FormattedMessage id="resourceRequestToolbar.day" />
    ) : selectedValue === PERIOD_SCALE_ENUM.WEEKS ? (
      <FormattedMessage id="resourceRequestToolbar.week" />
    ) : selectedValue === PERIOD_SCALE_ENUM.MONTHS ? (
      <FormattedMessage id="resourceRequestToolbar.month" />
    ) : selectedValue === PERIOD_SCALE_ENUM.QUARTERS ? (
      <FormattedMessage id="resourceRequestToolbar.quarter" />
    ) : selectedValue === PERIOD_SCALE_ENUM.YEARS ? (
      <FormattedMessage id="resourceRequestToolbar.year" />
    ) : (
      <FormattedMessage id="resourceRequestToolbar.overview" />
    )
});

const outLinedInput = <OutlinedInput labelWidth={0} />;

export const ResourcingPeriodDropDown = ({
  classes: classesOverrides,
  scale,
  setScale,
  showYears = true,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const formClasses = useFormStyles();
  const intl = useIntl();

  const handleChange = useCallback(
    event => event.target.value && setScale(event.target.value),
    [setScale]
  );

  const renderValue = useCallback(selectedValue => {
    const periodValues = getRenderedValues(selectedValue);

    return (
      <FormattedMessage id="resourceRequestToolbar.by" values={periodValues} />
    );
  }, []);

  return (
    <FormControl className={formClasses.formControl}>
      <Select
        value={scale}
        input={outLinedInput}
        renderValue={renderValue}
        onChange={handleChange}
        classes={classes}
        MenuProps={useMemo(
          () => ({
            MenuListProps: {
              dense: true,
              'aria-label': intl.formatMessage({
                id: 'resourcePlanGroupDropdown.groupBySelect'
              })
            }
          }),
          [intl]
        )}
        {...rest}
      >
        <MenuItem data-qe-id="dayOption" value={PERIOD_SCALE_ENUM.DAYS}>
          <FormattedMessage id="resourceRequestToolbar.day" />
        </MenuItem>
        <MenuItem data-qe-id="weekOption" value={PERIOD_SCALE_ENUM.WEEKS}>
          <FormattedMessage id="resourceRequestToolbar.week" />
        </MenuItem>
        <MenuItem data-qe-id="monthOption" value={PERIOD_SCALE_ENUM.MONTHS}>
          <FormattedMessage id="resourceRequestToolbar.month" />
        </MenuItem>
        <MenuItem data-qe-id="quarterOption" value={PERIOD_SCALE_ENUM.QUARTERS}>
          <FormattedMessage id="resourceRequestToolbar.quarter" />
        </MenuItem>
        {showYears ? (
          <MenuItem data-qe-id="yearOption" value={PERIOD_SCALE_ENUM.YEARS}>
            <FormattedMessage id="resourceRequestToolbar.year" />
          </MenuItem>
        ) : null}
      </Select>
    </FormControl>
  );
};

ResourcingPeriodDropDown.propTypes = {
  classes: PropTypes.object,
  scale: PropTypes.string.isRequired,
  setScale: PropTypes.func.isRequired,
  showYears: PropTypes.bool
};

export default ResourcingPeriodDropDown;
