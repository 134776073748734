import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ReadOnlyContainer from '../ReadOnlyContainer';

export const validateCommentForWhiteSpaces = onChange => e => {
  const fieldValue = e.target.value;

  if (!fieldValue || fieldValue.trim() !== '') {
    onChange(e);
  }
};

const useStyles = makeStyles(theme => ({
  input: {}
}));

export const CommentField = ({
  classes,
  value,
  onChange,
  editable,
  className,
  minHeight,
  underline = true,
  InputProps,
  dataQeId,
  disabled,
  hiddenLabel,
  margin = 'none',
  ...rest
}) => {
  const commentFieldClasses = useStyles({ classes });
  const intl = useIntl();

  return (
    <>
      {editable ? (
        <TextField
          data-qe-id={`${dataQeId}_CommentField`}
          value={value || ''}
          onChange={validateCommentForWhiteSpaces(onChange)}
          InputProps={{
            className: commentFieldClasses.input,
            disableUnderline: !underline,
            inputProps: {
              'aria-label': intl.formatMessage({
                id: 'tasks.comment'
              })
            },
            ...InputProps
          }}
          disabled={disabled || !editable}
          fullWidth
          hiddenLabel={hiddenLabel}
          {...rest}
        />
      ) : (
        <ReadOnlyContainer
          minHeight={minHeight}
          underline={underline}
          {...rest}
        >
          {value}
        </ReadOnlyContainer>
      )}
    </>
  );
};

CommentField.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string,
  dataQeId: PropTypes.string,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  InputProps: PropTypes.object,
  disabled: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  margin: PropTypes.string,
  className: PropTypes.string,
  minHeight: PropTypes.number,
  underline: PropTypes.bool
};

export default CommentField;
