import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { gql } from 'graphql-tag';

export const BILL_SUB_STATUS_QUERY = gql`
  query billSubStatus($id: BillStatus!) {
    billSubStatus(billStatus: $id) {
      id
      text
      isEnabled
      order
    }
  }
`;

export const useBillSubStatus = (
  billStatus,
  isPsaPswatBillingDraftSubStatusEnabled = false
) => {
  const { data, loading } = useQuery(BILL_SUB_STATUS_QUERY, {
    variables: {
      id: billStatus
    },
    skip: !isPsaPswatBillingDraftSubStatusEnabled
  });

  const billSubStatus = get(data, 'billSubStatus', []);

  return {
    loading,
    billSubStatus: loading ? [] : billSubStatus
  };
};
