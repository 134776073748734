import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { mapJSDateToRepliconDate } from '~/modules/common/dates/convert';
import {
  CostCenterIcon,
  DivisionIcon,
  LocationIcon,
  DepartmentIcon,
  EmployeeTypeIcon,
  ServiceCenterIcon
} from '~/modules/common/components';
import { useMeContext } from '~/modules/me';
import { compareDateObjects } from '~/modules/common/dates/compare';
import InfoItem from '../BasicInformation/InfoItem';

export const sortGroups = groups =>
  groups
    .slice()
    .sort((a, b) => compareDateObjects(a.effectiveDate, b.effectiveDate));

export const currentGroup = unsortedGroups => {
  const groupEntries = unsortedGroups || [];

  const groups =
    groupEntries.length > 1 ? sortGroups(groupEntries) : groupEntries;

  const today = mapJSDateToRepliconDate(new Date());

  const entries = groups.filter(g => {
    const compare = compareDateObjects(g.effectiveDate, today);

    return compare === null || compare <= 0;
  });

  return entries.length !== 0 ? entries[entries.length - 1] : null;
};

export const resourceKeys = {
  costCenter: {
    none: <FormattedMessage id="resourceBasicInfoCard.noCostCenter" />,
    title: <FormattedMessage id="resourceBasicInfoCard.costCenter" />,
    icon: <CostCenterIcon />
  },
  division: {
    none: <FormattedMessage id="resourceBasicInfoCard.noDivision" />,
    title: <FormattedMessage id="resourceBasicInfoCard.division" />,
    icon: <DivisionIcon />
  },
  location: {
    none: <FormattedMessage id="resourceBasicInfoCard.noLocation" />,
    title: <FormattedMessage id="resourceBasicInfoCard.location" />,
    icon: <LocationIcon />
  },
  serviceCenter: {
    icon: <ServiceCenterIcon />,
    none: <FormattedMessage id="resourceBasicInfoCard.noServiceCenter" />,
    title: <FormattedMessage id="resourceBasicInfoCard.serviceCenter" />
  },
  department: {
    none: <FormattedMessage id="resourceBasicInfoCard.noDepartment" />,
    title: <FormattedMessage id="resourceBasicInfoCard.department" />,
    icon: <DepartmentIcon />
  },
  employeeType: {
    icon: <EmployeeTypeIcon />,
    none: <FormattedMessage id="resourceBasicInfoCard.noEmployeeType" />,
    title: <FormattedMessage id="resourceBasicInfoCard.employeeType" />
  }
};

const tooltip = (title, icon) => <Tooltip title={title}>{icon}</Tooltip>;

const tooltips = {
  costCenter: tooltip(
    resourceKeys.costCenter.title,
    resourceKeys.costCenter.icon
  ),
  department: tooltip(
    resourceKeys.department.title,
    resourceKeys.department.icon
  ),
  division: tooltip(resourceKeys.division.title, resourceKeys.division.icon),
  employeeType: tooltip(
    resourceKeys.employeeType.title,
    resourceKeys.employeeType.icon
  ),
  location: tooltip(resourceKeys.location.title, resourceKeys.location.icon),
  serviceCenter: tooltip(
    resourceKeys.serviceCenter.title,
    resourceKeys.serviceCenter.icon
  )
};

export const GroupsInformationReadOnly = ({
  userDetails: {
    costCenter,
    division,
    location,
    serviceCenter,
    departmentGroup,
    employeeTypeGroup
  },
  permittedActions
}) => {
  const {
    groupSettings,
    legacyEmployeeTypeDisabled,
    legacyDepartmentDisabled
  } = useMeContext();
  const currentCostCenter = useMemo(() => currentGroup(costCenter), [
    costCenter
  ]);
  const currentServiceCenter = useMemo(() => currentGroup(serviceCenter), [
    serviceCenter
  ]);
  const currentDepartment = useMemo(() => currentGroup(departmentGroup), [
    departmentGroup
  ]);
  const currentEmployeeType = useMemo(() => currentGroup(employeeTypeGroup), [
    employeeTypeGroup
  ]);
  const currentLocation = useMemo(() => currentGroup(location), [location]);
  const currentDivision = useMemo(() => currentGroup(division), [division]);

  return (
    <>
      {groupSettings.costCenter.isEnabled &&
        permittedActions.includes(
          'urn:replicon:user-action:view-cost-center'
        ) && (
          <InfoItem
            dataQeId="CostCenterLabel"
            noValue={currentCostCenter == null}
            value={
              currentCostCenter && currentCostCenter.displayText
                ? currentCostCenter.displayText
                : resourceKeys.costCenter.none
            }
            icon={tooltips.costCenter}
          />
        )}
      {groupSettings.department.isEnabled &&
        legacyDepartmentDisabled &&
        permittedActions.includes(
          'urn:replicon:user-action:view-department'
        ) && (
          <InfoItem
            dataQeId="DepartmentLabel"
            noValue={currentDepartment == null}
            value={
              currentDepartment && currentDepartment.displayText
                ? currentDepartment.displayText
                : resourceKeys.department.none
            }
            icon={tooltips.department}
          />
        )}

      {groupSettings.division.isEnabled &&
        permittedActions.includes('urn:replicon:user-action:view-division') && (
          <InfoItem
            dataQeId="DivisionLabel"
            noValue={currentDivision == null}
            value={
              currentDivision && currentDivision.displayText
                ? currentDivision.displayText
                : resourceKeys.division.none
            }
            icon={tooltips.division}
          />
        )}
      {groupSettings.employeeType.isEnabled &&
        legacyEmployeeTypeDisabled &&
        permittedActions.includes(
          'urn:replicon:user-action:view-employee-type'
        ) && (
          <InfoItem
            dataQeId="EmployeeTypeLabel"
            noValue={currentEmployeeType == null}
            value={
              currentEmployeeType && currentEmployeeType.displayText
                ? currentEmployeeType.displayText
                : resourceKeys.employeeType.none
            }
            icon={tooltips.employeeType}
          />
        )}
      {groupSettings.location.isEnabled &&
        permittedActions.includes('urn:replicon:user-action:view-location') && (
          <InfoItem
            dataQeId="LocationLabel"
            noValue={currentLocation == null}
            value={
              currentLocation && currentLocation.displayText
                ? currentLocation.displayText
                : resourceKeys.location.none
            }
            icon={tooltips.location}
          />
        )}
      {groupSettings.serviceCenter.isEnabled &&
        permittedActions.includes(
          'urn:replicon:user-action:view-service-center'
        ) && (
          <InfoItem
            dataQeId="ServiceCenterLabel"
            noValue={currentServiceCenter == null}
            value={
              currentServiceCenter && currentServiceCenter.displayText
                ? currentServiceCenter.displayText
                : resourceKeys.serviceCenter.none
            }
            icon={tooltips.serviceCenter}
          />
        )}
    </>
  );
};

GroupsInformationReadOnly.propTypes = {
  userDetails: PropTypes.object.isRequired,
  permittedActions: PropTypes.array.isRequired
};
export default GroupsInformationReadOnly;
