import { gql } from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import get from 'lodash.get';

export const GET_SCHEDULED_HOURS_FOR_USER_AND_DATERANGE = gql`
  query GetScheduleDurationForUser(
    $userUri: String!
    $dateRange: DateRangeInput!
  ) {
    getScheduleDurationForUser(userUri: $userUri, dateRange: $dateRange)
  }
`;

export const useGetScheduleHoursForUserAndDateRange = ({
  userUri,
  dateRange
}) => {
  const { data, loading } = useQuery(
    GET_SCHEDULED_HOURS_FOR_USER_AND_DATERANGE,
    {
      variables: {
        userUri,
        dateRange
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  return {
    scheduledHours: get(data, 'getScheduleDurationForUser', null),
    loading
  };
};

export const useGetScheduledHoursForUserAndDateRangeCallback = () => {
  const apolloClient = useApolloClient();

  const getScheduleHoursForUserAndDateRange = useCallback(
    async (userUri, dateRange) => {
      const { data, loading } = await apolloClient.query({
        query: GET_SCHEDULED_HOURS_FOR_USER_AND_DATERANGE,
        variables: {
          userUri,
          dateRange
        },
        fetchPolicy: 'network-only'
      });

      return {
        scheduledHours: get(data, 'getScheduleDurationForUser', 0),
        loading
      };
    },
    [apolloClient]
  );

  return {
    getScheduleHoursForUserAndDateRange
  };
};
