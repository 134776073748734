import React from 'react';
import { PropTypes } from 'prop-types';
import { ButtonBase, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const Name = ({ record: project }) => {
  return (
    <ButtonBase component={Link} to={`/projects/${project.slug}`}>
      <Typography>{project.displayText}</Typography>
    </ButtonBase>
  );
};

Name.propTypes = {
  record: PropTypes.object
};

export default Name;
