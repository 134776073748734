import PropTypes from 'prop-types';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { ResourcePlanGroupBy, ResourceRequestAccessLevel } from '~/types';
import useProjectResourcingContext from '~/modules/resourcing/contexts/useProjectResourcingContext';
import { useResourcePlanFilterBasedOnSearchCriteria } from '~/modules/resourcing/hooks';
import { useSkillLevels } from '~/modules/common/hooks';
import ResourceAllocationChart from '../ResourceAllocationChart';
import { useResourcingPlans } from './hooks';

export const ResourceAllocationPlans = ({
  projectUri,
  chartSelectedTab,
  resourcePlanGroup,
  requestAccessLevel = ResourceRequestAccessLevel.ResourceManager
}) => {
  const { searchCriteria } = useProjectResourcingContext();
  const { planFilter } = useResourcePlanFilterBasedOnSearchCriteria({
    searchCriteria
  });

  const {
    loading: resourceLoading,
    plans,
    loadMorePlans,
    hasMorePlans
  } = useResourcingPlans({
    resourcePlanGroup,
    planFilter: {
      ...planFilter,
      projects: projectUri
        ? [...((planFilter && planFilter.projects) || []), projectUri]
        : planFilter && planFilter.projects,
      planStatus: chartSelectedTab
    },
    requestAccessLevel
  });

  const { isSkillLoading, maxSkillLevel } = useSkillLevels();

  const loading = resourceLoading || isSkillLoading;

  return (
    <>
      <ResourceAllocationChart
        plans={plans}
        loading={loading}
        maxSkillLevel={maxSkillLevel}
        requestAccessLevel={requestAccessLevel}
      />

      {hasMorePlans && !loading && loadMorePlans && (
        <Waypoint
          fireOnRapidScroll
          bottomOffset="-50%"
          onEnter={loadMorePlans}
          key={(plans || []).length}
        />
      )}
    </>
  );
};

ResourceAllocationPlans.propTypes = {
  projectUri: PropTypes.string,
  chartSelectedTab: PropTypes.string.isRequired,
  resourcePlanGroup: PropTypes.oneOf([
    ResourcePlanGroupBy.Project,
    ResourcePlanGroupBy.Role
  ]),
  requestAccessLevel: PropTypes.string
};

export default ResourceAllocationPlans;
