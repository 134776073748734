import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import ListTablePropTypes from './PropTypes';

const ListTableContext = React.createContext();

export const ListTableContextProvider = ({
  sort,
  sortArray,
  isMultiSortEnabled,
  onSortChange,
  disableSort,
  children
}) => {
  const value = useMemo(
    () => ({
      ...sort,
      sortArray: sortArray || [],
      isMultiSortEnabled,
      onSortChange,
      disableSort
    }),
    [sort, sortArray, isMultiSortEnabled, onSortChange, disableSort]
  );

  return (
    <ListTableContext.Provider value={value}>
      {children}
    </ListTableContext.Provider>
  );
};

ListTableContextProvider.propTypes = {
  sort: ListTablePropTypes.sort,
  sortArray: ListTablePropTypes.sortArray,
  isMultiSortEnabled: PropTypes.bool,
  disableSort: PropTypes.bool,
  onSortChange: PropTypes.func,
  children: PropTypes.node.isRequired
};

export const useListTableContext = () => useContext(ListTableContext);

export default ListTableContext;
