import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import { PROJECT_PERMISSION } from '~/modules/common/enums';

const useVarianceStyle = makeStyles(theme => ({
  variance: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0.125, 0.25)
  }
}));

export const columns = classes => ({
  name: {
    field: 'name',
    value: <FormattedMessage id="projectList.project" />,
    align: 'left'
  },
  estimatedBudgetHoursRemaining: {
    field: 'estimatedBudgetHoursRemaining',
    value: <FormattedMessage id="projectList.hours" />,
    align: 'right',
    sortable: true,
    showAbbreviatedValue: true,
    classes
  },
  estimatedBudgetCostRemaining: {
    field: 'estimatedBudgetCostRemaining',
    value: <FormattedMessage id="projectList.cost" />,
    align: 'right',
    sortable: true,
    showAbbreviatedValue: true,
    classes
  }
});

export const getcolumnGroups = (canViewProjectCostData, classes) => {
  const {
    name,
    estimatedBudgetHoursRemaining,
    estimatedBudgetCostRemaining
  } = columns(classes);

  return [
    {
      columns: [
        name,
        estimatedBudgetHoursRemaining,
        canViewProjectCostData && estimatedBudgetCostRemaining
      ].filter(x => x)
    }
  ];
};

export const useProjectColumns = () => {
  const classes = useVarianceStyle();
  const me = useMeContext();
  const { permissionsMap } = me;
  const canViewProjectCostData = Boolean(
    permissionsMap[PROJECT_PERMISSION.VIEW_COST_DATA]
  );

  const group = getcolumnGroups(canViewProjectCostData, classes);

  return {
    projectColumnGroup: group,
    projectColumnNames: group[0].columns.map(c => c.field)
  };
};

export default useProjectColumns;
