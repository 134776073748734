import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  contentArea: {
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(2),
    margin: 0,
    listStyle: 'none'
  }
}));

const DrawerContent = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.contentArea, className)}>{children}</div>
  );
};

DrawerContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default DrawerContent;
