import React from 'react';
import { PropTypes } from 'prop-types';

import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';
import { useIntl } from 'react-intl';
import { HeaderWrapper } from '~/modules/common/components/LayoutWrapper';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  headerArea: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.black,
    padding: theme.spacing(1, 0, 1, 3)
  },
  heading: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingRight: theme.spacing(1)
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  grow: {
    flexGrow: 1
  }
}));

export const DrawerHeader = ({
  classes: classesOverride,
  onClose,
  extraButtons,
  to,
  title,
  color,
  bgColor,
  children,
  paddingLeft
}) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const { formatMessage } = useIntl();

  return (
    <HeaderWrapper>
      <div
        className={classes.headerArea}
        style={{ backgroundColor: bgColor, color, paddingLeft }}
      >
        <div className={classes.heading}>
          {children}
          <div className={classes.grow} />
          {extraButtons}
          <IconButton
            data-qe-id="Drawer_Close_Button"
            color="inherit"
            onClick={onClose}
            aria-label={formatMessage({
              id: isPsaPrpAccessibilityIssuesEnabled
                ? 'button.close'
                : 'button.closeButton'
            })}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </HeaderWrapper>
  );
};

DrawerHeader.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  extraButtons: PropTypes.node,
  to: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.node,
  paddingLeft: PropTypes.string
};

export default DrawerHeader;
