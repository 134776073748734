import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { DateTime } from 'luxon';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { KeyValuesRow } from './common';

const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(1)
  }
}));
const TotalForecastKeyValues = ({
  startDate,
  endDate,
  totalForecastedAmount
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { startMonth, endMonth } = useMemo(
    () => ({
      startMonth: DateTime.utc(
        startDate.year,
        startDate.month,
        startDate.date
      ).toLocaleString({
        month: 'short',
        year: 'numeric'
      }),
      endMonth: DateTime.utc(
        endDate.year,
        endDate.month,
        endDate.date
      ).toLocaleString({
        month: 'short',
        year: 'numeric'
      })
    }),
    [
      endDate.date,
      endDate.month,
      endDate.year,
      startDate.date,
      startDate.month,
      startDate.year
    ]
  );

  return (
    <>
      <Typography variant="subtitle2">
        {startMonth} - {endMonth}:
      </Typography>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} className={classes.item}>
          <KeyValuesRow
            bulletColor={theme.palette.grey[300]}
            title="totalForecast"
            amount={totalForecastedAmount}
          />
        </Grid>
      </Grid>
    </>
  );
};

TotalForecastKeyValues.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  totalForecastedAmount: PropTypes.object.isRequired
};
export default TotalForecastKeyValues;
