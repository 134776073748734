import NotFoundPage from './NotFoundPage';

const notFoundRoute = ({ intl }) => ({
  title: () => intl.formatMessage({ id: 'routes.pageNotFound' }),
  hasPermission: true,
  component: NotFoundPage,
  hidden: true
});

export default notFoundRoute;
