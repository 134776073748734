import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const GET_TASK_RESOURCE_USER_ALLOCATIONS = gql`
  query getPageOfTaskResourceUserAllocations(
    $limit: Int = 10
    $filter: TaskResourceUserAllocationsFilter!
    $cursor: String
  ) {
    pageOfTaskResourceUserAllocations(
      limit: $limit
      filter: $filter
      cursor: $cursor
    ) {
      taskAllocations {
        id
      }
    }
  }
`;

const useHasTaskAllocations = ({ taskUri }) => {
  const { loading, data } = useQuery(GET_TASK_RESOURCE_USER_ALLOCATIONS, {
    variables: {
      limit: 1,
      filter: {
        taskUri
      },
      cursor: null
    },
    fetchPolicy: 'cache-and-network'
  });

  return {
    hasTaskAllocations: Boolean(
      data?.pageOfTaskResourceUserAllocations?.taskAllocations[0]?.id
    ),
    loading
  };
};

export default useHasTaskAllocations;
