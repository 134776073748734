import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useVariationColors } from './hooks';
import { isValueDefined } from './util';

const useStyles = makeStyles({
  variationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(-1)
  },
  variationPill: ({ value, backgroundColor, color }) => ({
    display: 'flex',
    marginLeft: theme.spacing(1),
    padding:
      value > 0 || value < 0
        ? theme.spacing(0.375, 1.75, 0.125)
        : theme.spacing(0.375, 1, 0.125),
    borderRadius: theme.spacing(0.5),
    backgroundColor,
    color
  }),
  variationIcon: ({ value, fill }) => ({
    fontSize: theme.spacing(2.5),
    marginRight:
      value > 0 || value < 0 ? theme.spacing(0.25) : theme.spacing(1),
    fill
  }),
  variationLabel: {
    fontSize: theme.typography.body2.fontSize
  },
  variationText: {
    fontWeight: theme.typography.fontWeightBold
  }
});

export const TaskVariationDatePill = ({
  value,
  classes: classesOverride,
  isPsaPpmCostEacEnhancementsEnabled
}) => {
  const { color, backgroundColor, fill } = useVariationColors({
    variationStatus: null,
    days: value,
    isPsaPpmCostEacEnhancementsEnabled
  });

  const classes = useStyles({
    classes: classesOverride,
    value,
    color,
    backgroundColor,
    fill
  });
  const variationLabels =
    value <= -1 ? 'early' : value >= 1 ? 'late' : 'onTime';
  const variationValues =
    value !== 0
      ? {
          days: <span className={classes.variationText}>{Math.abs(value)}</span>
        }
      : null;

  if (!isValueDefined(value)) {
    return null;
  }

  return (
    <div className={classes.variationContainer}>
      <div className={classes.variationPill}>
        {value === 0 && <CheckCircle className={classes.variationIcon} />}
        <div className={classes.variationLabel}>
          <FormattedMessage
            id={`taskDrawer.${variationLabels}`}
            values={variationValues}
          />
        </div>
      </div>
    </div>
  );
};

TaskVariationDatePill.propTypes = {
  value: PropTypes.number,
  classes: PropTypes.object,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool
};

export default TaskVariationDatePill;
