import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useHasUserAccessRole } from '~/modules/common/permissions/useHasUserAccessRole';
import {
  CreatableAutocomplete,
  CreatableDropdownItem
} from '~/modules/common/components/SearchAutocomplete';

import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useHasPermission } from '~/modules/common/permissions';
import AddClientDialog from '~/modules/clients/ClientsPage/AddClientDialog';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { useMeContext } from '~/modules/me/useMeContext';
import useEventHandlers from '~/modules/projects/project/common/components/ClientDropdown/hooks/useEventHandlers';

const filter = createFilterOptions();

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const ClientDropdown = ({
  hasMore,
  options,
  isLoading,
  value,
  onChange,
  dataQeId,
  TextFieldProps: { error, helperText } = {},
  autoFocus = false,
  optionTypeText,
  ...rest
}) => {
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const { formatMessage } = useIntl();
  const { open, closeDialog, openDialog } = useDialogState();

  const [newClientName, setNewClientName] = useState();
  const openDialogAndSetInitialClient = useCallback(
    name => {
      setNewClientName(name);
      openDialog();
    },
    [openDialog, setNewClientName]
  );

  const selectedValue = useMemo(
    () =>
      Boolean(value && value.id && value.displayText) && {
        label: value.displayText,
        value: value.id
      },
    [value]
  );

  const onClientAdd = useCallback(
    ({ displayText, id }) =>
      onChange({
        label: displayText,
        value: id
      }),
    [onChange]
  );

  const canEditClient = useHasPermission({
    actionUri: 'urn:replicon:client-action:edit-client'
  });

  const isClientManager = useHasUserAccessRole({
    roleUri: USER_ACCESS_ROLE.CLIENT_MANAGER
  });

  const canEditAllClients = useHasPermission({
    actionUri: 'urn:replicon:client-action:edit-client',
    dataAccessLevelUri: 'urn:replicon:client-data-access-level:all'
  });

  const canCreateClient =
    (canEditClient && isClientManager) || canEditAllClients;

  const filterOptions = useCallback(
    (filterOption, params) => {
      const filtered = filter(filterOption, params);

      if (
        canCreateClient &&
        !isLoading &&
        params.inputValue !== '' &&
        !filterOption.find(o => o.label === params.inputValue)
      ) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          label: params.inputValue
        });
      }

      return filtered;
    },
    [canCreateClient, isLoading]
  );

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <CreatableDropdownItem
          option={option}
          onCreate={openDialogAndSetInitialClient}
          optionPropText="label"
          primaryLabelTextId="addProject.creatableClient"
        />
      ),
    [openDialogAndSetInitialClient]
  );

  const { handleOnChange, handleOnCancel } = useEventHandlers({
    openDialogAndSetInitialClient,
    onChange
  });

  return (
    <>
      <CreatableAutocomplete
        hasMore={hasMore}
        inputLabel={formatMessage({ id: 'projectDetails.client' })}
        loading={isLoading}
        options={options}
        fullWidth
        value={selectedValue}
        dataQeId={dataQeId}
        onChange={
          isPsaPrpAccessibilityIssuesEnabled ? handleOnChange : onChange
        }
        clearOnBlur={isPsaPrpAccessibilityIssuesEnabled}
        optionPropText="label"
        canAddNewOption={canCreateClient}
        filterOptions={filterOptions}
        renderOption={renderOption}
        showError={error}
        errorMessage={helperText}
        helperText={helperText}
        autoFocus={autoFocus}
        optionTypeText={optionTypeText}
        getOptionDisabled={getOptionDisabled}
        {...rest}
      />
      <AddClientDialog
        initialName={newClientName}
        open={open}
        onClose={closeDialog}
        onClientAdd={onClientAdd}
        onCancel={
          isPsaPrpAccessibilityIssuesEnabled ? handleOnCancel : undefined
        }
      />
    </>
  );
};

ClientDropdown.propTypes = {
  hasMore: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  intl: PropTypes.object,
  dataQeId: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayText: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  autoFocus: PropTypes.bool,
  optionTypeText: PropTypes.string
};

export default ClientDropdown;
