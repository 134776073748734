import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { NoValue, Decimal, FormattedNumber } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  rowLabel: {
    fontWeight: 'bold'
  },
  value: {
    textAlign: 'right',
    padding: theme.spacing(0, 0, 0, 2)
  },
  item: {
    display: 'contents'
  },
  cell: {}
}));

export const useHoursVarianceStyles = makeStyles(theme => ({
  diffPositive: {
    marginRight: theme.spacing(-0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    marginRight: theme.spacing(-0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

export const ActualSummaryPeriodPopupRow = ({
  allocatedHours,
  actualHours,
  rowLabel,
  roleDisplayText,
  classOverrides,
  qeIdTag
}) => {
  const values = useMemo(() => ({ roleDisplayText }), [roleDisplayText]);

  const differenceHours = allocatedHours - actualHours;
  const isActualsHigher = actualHours > allocatedHours;

  const classes = useStyles({ classes: classOverrides });
  const hourVarianceStyles = useHoursVarianceStyles();

  const allocatedHoursValue = useMemo(
    () => ({
      value: <Decimal value={allocatedHours} />
    }),
    [allocatedHours]
  );

  const actualHoursValue = useMemo(
    () => ({
      value: <Decimal value={actualHours} />
    }),
    [actualHours]
  );

  return (
    <div className={classes.item}>
      <span
        className={classNames(classes.rowLabel, classes.cell)}
        data-qe-id={`${qeIdTag}_RoleName`}
      >
        <FormattedMessage id={rowLabel} values={values} />
      </span>
      <span
        className={classNames(classes.value, classes.cell)}
        data-qe-id={`${qeIdTag}_AllocatedHours`}
      >
        {allocatedHours ? (
          <FormattedMessage
            id="actualsSummaryPeriodPopup.hours"
            values={allocatedHoursValue}
          />
        ) : (
          <NoValue />
        )}
      </span>
      <span
        className={classNames(classes.value, classes.cell)}
        data-qe-id={`${qeIdTag}_ActualHours`}
      >
        {actualHours ? (
          <FormattedMessage
            id="actualsSummaryPeriodPopup.hours"
            values={actualHoursValue}
          />
        ) : (
          <NoValue />
        )}
      </span>
      <span
        className={classNames(classes.value, classes.cell)}
        data-qe-id={`${qeIdTag}_Difference`}
      >
        {differenceHours ? (
          <FormattedNumber
            classes={hourVarianceStyles}
            value={differenceHours}
            showAddIcon={isActualsHigher}
            isHigher={isActualsHigher}
            fixedDecimalScale
            formattedMessageKey="actualsSummaryPeriodPopup.hours"
          />
        ) : (
          <NoValue />
        )}
      </span>
    </div>
  );
};

ActualSummaryPeriodPopupRow.propTypes = {
  rowLabel: PropTypes.string,
  allocatedHours: PropTypes.number,
  roleDisplayText: PropTypes.string,
  actualHours: PropTypes.number,
  classOverrides: PropTypes.object,
  qeIdTag: PropTypes.string
};

export default ActualSummaryPeriodPopupRow;
