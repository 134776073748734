import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0.25, 0, 0.25, 0),
    padding: theme.spacing(0, 0, 0, 1),
    ...theme.palette.text.primary
  },
  quantityRow: {},
  quantity: {
    fontWeight: theme.typography.fontWeightBold
  },
  groupsRow: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));

export const useRequestIndicatorStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: theme.spacing(7)
  }
}));

export default useStyles;
