import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { SearchBox } from '~/modules/common/components';

import { AddLineItemsDialogContext } from './AddLineItemsDialogContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2)
    },
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '3.5rem',
    minHeight: '-webkit-max-content'
  },
  createBillRoot: {
    marginTop: theme.spacing(1)
  },
  search: {
    flexGrow: 1
  }
}));

const useAccesibilityLabels = ({ intl }) => ({
  searchInputAriaLabel: intl.formatMessage({
    id: 'addLineItemDialogToolbar.search.searchText'
  }),
  searchIconAriaLabel: intl.formatMessage({
    id: 'addLineItemDialogToolbar.search.expand'
  }),
  ariaLabel: intl.formatMessage({
    id: 'addLineItemDialogToolbar.search.label'
  })
});

export const InnerToolbar = ({
  classes,
  createBill,
  columns,
  setSearchCriteria,
  searchCriteria,
  ariaLabel,
  searchInputAriaLabel,
  searchIconAriaLabel,
  searchFacets
}) =>
  Boolean(searchFacets) && (
    <div
      className={classNames(
        classes.root,
        createBill ? classes.createBillRoot : {}
      )}
    >
      {setSearchCriteria && (
        <div className={classes.search}>
          <SearchBox
            dataQeId="BillingDialog-SearchBar"
            hideSearch={!(columns.length > 0)}
            ariaLabel={ariaLabel}
            searchInputAriaLabel={searchInputAriaLabel}
            searchIconAriaLabel={searchIconAriaLabel}
            setSearchCriteria={setSearchCriteria}
            searchCriteria={searchCriteria}
            facets={searchFacets}
            searchInputId="search-input-addlineitems"
            autoFocus
          />
        </div>
      )}
    </div>
  );

InnerToolbar.propTypes = {
  createBill: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object.isRequired,
  columns: PropTypes.array,
  ariaLabel: PropTypes.string,
  searchInputAriaLabel: PropTypes.string,
  searchIconAriaLabel: PropTypes.string,
  searchFacets: PropTypes.array
};

const Toolbar = ({ createBill, columns, searchFacets }) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    searchInputAriaLabel,
    searchIconAriaLabel,
    ariaLabel
  } = useAccesibilityLabels({
    intl
  });

  return (
    <AddLineItemsDialogContext.Consumer>
      {ctx => (
        <InnerToolbar
          classes={classes}
          createBill={createBill}
          searchFacets={searchFacets}
          columns={columns}
          ariaLabel={ariaLabel}
          searchInputAriaLabel={searchInputAriaLabel}
          searchIconAriaLabel={searchIconAriaLabel}
          {...ctx}
        />
      )}
    </AddLineItemsDialogContext.Consumer>
  );
};

Toolbar.propTypes = {
  createBill: PropTypes.bool,
  columns: PropTypes.array,
  searchFacets: PropTypes.array
};

export default Toolbar;
