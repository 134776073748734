import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';
import {
  RefundIcon,
  CreditMemoIcon,
  PaymentIcon,
  BillIcon
} from '~/modules/common/components/Icons';
import { BillingTransactionType, BillStatus } from '~/types';
import { BillingTransactionStatusBadge } from './BillingTransactionStatusBadge';
import DrawerHeader from './DrawerHeader';
import { SubStatusDropdown } from './SubStatusDropdown';

const useBadgeStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 1.5, 0, 1)
  }
}));
const useEditButtonStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, -0.5, 0, 0)
  }
}));

const findBillSubStatus = ({
  isPsaPswatBillingDraftSubStatusEnabled,
  billSubStatusList,
  billSubStatusId
}) => {
  if (!isPsaPswatBillingDraftSubStatusEnabled) return null;

  const matchedDraftSubStatus = billSubStatusList?.find(
    ({ id }) => id === billSubStatusId
  );

  if (matchedDraftSubStatus) {
    return {
      ...matchedDraftSubStatus,
      displayText: matchedDraftSubStatus.text
    };
  }

  return null;
};

export const DrawerHeaderComponent = ({
  ariaLabel,
  values,
  onCancel,
  canEdit,
  balanceStatus,
  editable,
  setToEditMode,
  transactionType = 'BILL',
  drawerTitleId,
  isRefund = false,
  billStatus,
  billSubStatusId = null,
  billSubStatusList,
  setFieldValue,
  handleSubmit,
  isPsaPswatBillingDraftSubStatusEnabled = false
}) => {
  const badgeClasses = useBadgeStyles();
  const editButtonClasses = useEditButtonStyles();
  const titleIcon =
    transactionType === 'BILL'
      ? BillIcon
      : transactionType === 'CREDITMEMO'
      ? CreditMemoIcon
      : isRefund
      ? RefundIcon
      : PaymentIcon;

  const billSubStatus = findBillSubStatus({
    isPsaPswatBillingDraftSubStatusEnabled,
    billSubStatusList,
    billSubStatusId
  });

  const enabledBillSubStatusList =
    (isPsaPswatBillingDraftSubStatusEnabled &&
      billSubStatusList?.filter(({ isEnabled }) => isEnabled)) ||
    [];

  const shouldShowSubStatusDropdown =
    isPsaPswatBillingDraftSubStatusEnabled &&
    enabledBillSubStatusList?.length > 0 &&
    billStatus === BillStatus.Draft &&
    transactionType === BillingTransactionType.Bill &&
    canEdit &&
    !editable;

  return (
    <DrawerHeader
      entityName={values && values.client && values.client.displayText}
      onClose={onCancel}
      TitleIcon={titleIcon}
      extraButtons={
        <>
          {shouldShowSubStatusDropdown ? (
            <SubStatusDropdown
              billSubStatus={billSubStatus}
              enabledBillSubStatusList={enabledBillSubStatusList}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
            />
          ) : (
            <BillingTransactionStatusBadge
              classes={badgeClasses}
              balanceStatus={balanceStatus}
              transactionType={transactionType}
              billStatus={billStatus}
              draftSubStatus={billSubStatus}
            />
          )}
          {!editable &&
            canEdit &&
            (!isPsaPswatBillingDraftSubStatusEnabled ||
              transactionType !== BillingTransactionType.Bill) && (
              <IconButton
                aria-label={ariaLabel}
                data-qe-id="ReadOnly_Edit"
                onClick={setToEditMode}
                classes={editButtonClasses}
              >
                <EditIcon />
              </IconButton>
            )}
        </>
      }
    >
      <FormattedMessage
        id={drawerTitleId}
        values={useMemo(
          () => ({
            displayId: values && values.displayId
          }),
          [values]
        )}
      >
        {text => <span>{text}</span>}
      </FormattedMessage>
    </DrawerHeader>
  );
};

DrawerHeaderComponent.propTypes = {
  values: PropTypes.object,
  ariaLabel: PropTypes.string,
  onCancel: PropTypes.func,
  canEdit: PropTypes.bool,
  balanceStatus: PropTypes.string,
  editable: PropTypes.bool,
  setToEditMode: PropTypes.func,
  transactionType: PropTypes.string,
  drawerTitleId: PropTypes.string,
  isRefund: PropTypes.bool,
  billStatus: PropTypes.string,
  billSubStatusList: PropTypes.array,
  billSubStatusId: PropTypes.string,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  isPsaPswatBillingDraftSubStatusEnabled: PropTypes.bool
};

export default DrawerHeaderComponent;
