import { useEnabledCustomFieldDefinitions } from './useEnabledCustomFieldDefinitions';

export const useRequiredCustomFieldDefinitions = groupName => {
  const {
    customFieldDefinitions,
    customFieldDefinitionsLoading
  } = useEnabledCustomFieldDefinitions(groupName);

  return {
    customFieldDefinitionsLoading,
    customFieldDefinitions: (customFieldDefinitions || []).filter(
      c => c.isRequired && c.isVisible
    )
  };
};
