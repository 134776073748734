import { useProjectRequestPermissions } from '../../../hooks';
import {
  getEnabledColumnGroups,
  getServiceDataColumns
} from './requestColumns';

const enabledColumnNames = ({ columnGroups }) =>
  columnGroups.reduce(
    (acc, current) => [
      ...acc,
      ...current.columns.filter(c => Boolean(c.isDefault)).map(c => c.field)
    ],
    []
  );

const useEnabledColumns = ({
  classes,
  canEditProject,
  onCreateProject,
  projectRequestListPageType
}) => {
  const { canViewPlanningData } = useProjectRequestPermissions();

  const enabledColumnGroups = getEnabledColumnGroups({
    classes,
    canEditProject,
    onCreateProject,
    canViewPlanningData,
    projectRequestListPageType
  });

  return {
    enabledColumnGroups,
    enabledColumnNames: enabledColumnNames({
      columnGroups: enabledColumnGroups
    }),
    getServiceDataColumns
  };
};

export default useEnabledColumns;
