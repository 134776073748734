import {
  Typography,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRedirectToProjectResourcingLink } from '../hooks';
import useStyles from './useStyles';

export const ResourceReminder = ({
  count,
  boldMessageId,
  messageId,
  planStatus,
  subCount,
  subTextMessageId
}) => {
  const link = useRedirectToProjectResourcingLink(planStatus);
  const classes = useStyles();
  const intl = useIntl();
  const ariaLabel = intl.formatMessage({
    id: 'resourcingOverviewReminders.reminders'
  });

  return (
    <>
      <Link className={classes.root} to={link}>
        <ListItemAvatar className={classes.countRoot}>
          <Typography className={classes.count}>{count}</Typography>
        </ListItemAvatar>
        <ListItemText>
          {boldMessageId ? (
            <div className={classes.headerContainer}>
              <Typography className={classes.bold}>
                <FormattedMessage id={boldMessageId} />
              </Typography>
              &nbsp;
              <Typography className={classes.message}>
                <FormattedMessage id={messageId} />
              </Typography>
            </div>
          ) : (
            <Typography className={classes.message}>
              <FormattedMessage id={messageId} />
            </Typography>
          )}
          {Boolean(subCount) && subTextMessageId && (
            <Typography className={classes.subText}>
              {subCount}&nbsp;
              <FormattedMessage id={subTextMessageId} />
            </Typography>
          )}
        </ListItemText>
        {planStatus && (
          <IconButton aria-label={ariaLabel} className={classes.chevron}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </Link>
      <Divider />
    </>
  );
};

ResourceReminder.propTypes = {
  count: PropTypes.number,
  boldMessageId: PropTypes.string,
  messageId: PropTypes.string,
  planStatus: PropTypes.string,
  subCount: PropTypes.number,
  subTextMessageId: PropTypes.string
};

export default ResourceReminder;
