import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DialogActions, Button, CircularProgress } from '@material-ui/core';
import { BillingBatchStatus } from '~/modules/common/components';

export const AddLineItemsDialogFooter = ({
  onAddLineItems,
  onClose,
  disableAdd,
  addButtonLabel,
  saveInProgress,
  createBill,
  createAndViewInProgress,
  billingBatchState,
  setBillingBatchState,
  onBatchComplete,
  onBatchCompleteViewBill
}) => {
  const onAdd = useCallback(
    item => async () => {
      onAddLineItems(item);
    },
    [onAddLineItems]
  );

  return (
    <DialogActions>
      <Button
        onClick={onClose}
        disabled={saveInProgress || createAndViewInProgress}
        data-qe-id="addLineItemsDialogFooter_cancel"
      >
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        onClick={onAdd(false)}
        disabled={disableAdd}
        color="primary"
        data-qe-id="addLineItemsDialogFooter_createBill"
      >
        {saveInProgress && billingBatchState.batchInProgress && (
          <BillingBatchStatus
            billingBatchState={billingBatchState}
            setBillingBatchState={setBillingBatchState}
            onBatchComplete={onBatchComplete}
          >
            <CircularProgress data-qe-id="saveInProgress" size={24} />
          </BillingBatchStatus>
        )}
        <FormattedMessage id={addButtonLabel} />
      </Button>
      {createBill ? (
        <Button
          onClick={onAdd(true)}
          disabled={disableAdd || createAndViewInProgress}
          color="primary"
        >
          {createAndViewInProgress && billingBatchState.batchInProgress && (
            <BillingBatchStatus
              billingBatchState={billingBatchState}
              setBillingBatchState={setBillingBatchState}
              onBatchComplete={onBatchCompleteViewBill}
            >
              <CircularProgress size={0} />
            </BillingBatchStatus>
          )}
          <FormattedMessage id="addLineItemDialog.createAndViewBill" />
        </Button>
      ) : null}
    </DialogActions>
  );
};

AddLineItemsDialogFooter.propTypes = {
  disableAdd: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddLineItems: PropTypes.func,
  addButtonLabel: PropTypes.string.isRequired,
  saveInProgress: PropTypes.bool.isRequired,
  createBill: PropTypes.bool,
  createAndViewInProgress: PropTypes.bool,
  billingBatchState: PropTypes.object,
  setBillingBatchState: PropTypes.func,
  onBatchComplete: PropTypes.func,
  onBatchCompleteViewBill: PropTypes.func
};

export default AddLineItemsDialogFooter;
