import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'sticky',
    bottom: 0,
    paddingLeft: 0,
    margin: 0
  }
}));

export const DrawerFooter = ({
  classes: classesOverride,
  children,
  className
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classNames(classes.footer, className)}>{children}</div>
  );
};

DrawerFooter.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string
};

export default DrawerFooter;
