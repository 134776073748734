import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MyTeamIcon = () => (
  <SvgIcon>
    <>
      <path d="M 12 2 C 6.480000000000001 2 2 6.480000000000001 2 12 C 2 17.42633081088966 6.331995877440277 21.83783914053991 11.72265625 21.986328125 L 11.33203125 21.595703125 L 9.232421875 19.498046875 C 7.014076691031558 18.67798751852262 5.26548714444217 16.91265212605181 4.470703125 14.6796875 C 5.520703125 13.5596875 8.139374999999999 12.990234375 9.609375 12.990234375 C 10.139375 12.990234375 10.809765625 13.0709375 11.509765625 13.2109375 C 9.869765624999999 14.0809375 9.609375000000002 15.23062500000001 9.609375 15.890625 L 9.609375 19.07421875 L 11.2109375 17.47265625 L 11.2109375 15.890625 C 11.2109375 14.470625 14.149375 13.75976562500002 15.609375 13.759765625 C 16.39350252952997 13.759765625 17.59404455895805 13.97374634404603 18.54296875 14.384765625 L 19.81640625 13.111328125 L 20.5234375 12.404296875 L 21.9375 10.990234375 L 21.951171875 11.00390625 C 21.45038794739938 5.951356088222995 17.18358709724476 2 12 2 z M 9.609375 6.759765625 C 10.909375 6.759765625 11.970703125 7.819140625000002 11.970703125 9.119140625 C 11.970703125 10.419140625 10.909375 11.48046875 9.609375 11.48046875 C 8.309375000000001 11.48046875 7.25 10.419140625 7.25 9.119140625 C 7.25 7.809140625 8.299375000000001 6.759765624999999 9.609375 6.759765625 z M 15.609375 8.33984375 C 16.679375 8.33984375 17.5390625 9.19953125 17.5390625 10.26953125 C 17.5390625 11.33953125 16.679375 12.19921875 15.609375 12.19921875 C 14.539375 12.19921875 13.6796875 11.33953125 13.6796875 10.26953125 C 13.6696875 9.19953125 14.539375 8.33984375 15.609375 8.33984375 z M 21.9375 13.818359375 L 21.23046875 14.525390625 L 15.57421875 20.181640625 L 14.16015625 18.767578125 L 13.453125 18.060546875 L 12.0390625 19.474609375 L 12.74609375 20.181640625 L 15.57421875 23.009765625 L 22.64453125 15.939453125 L 23.3515625 15.232421875 L 21.9375 13.818359375 z " />
    </>
  </SvgIcon>
);

export default MyTeamIcon;
