import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForwardSharp';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  pathContainer: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    color: 'inherit'
  },
  path: {
    fontSize: 'inherit',
    color: 'inherit'
  },
  icon: {
    margin: theme.spacing(0, 0.25)
  }
}));

const FullPath = ({ fullPath, classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      {fullPath.map((name, index) => (
        <span key={name} className={classes.pathContainer}>
          <Typography className={classes.path} component="span" noWrap>
            {name}
          </Typography>
          {index < fullPath.length - 1 && (
            <ArrowIcon
              className={classes.icon}
              fontSize="inherit"
              color="inherit"
            />
          )}
        </span>
      ))}
    </div>
  );
};

FullPath.propTypes = {
  fullPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.object
};

export default FullPath;
