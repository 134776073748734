import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { EVENT_TYPE } from '~/modules/common/enums/EventTypes';
import { useOnRemoveTaskResourceEstimateSubmit } from '../../hooks/useOnChangeHandlers';

export const useStyles = makeStyles(theme => ({
  actionContainer: {
    margin: theme.spacing(1, 2, 1, 0)
  }
}));

const RemoveTaskEstimateConfirmationDialogFooter = ({
  taskId,
  resourceEstimateId,
  onClose
}) => {
  const {
    loading,
    onRemoveTaskResourceEstimateSubmit
  } = useOnRemoveTaskResourceEstimateSubmit({ taskId });

  const classes = useStyles();

  const {
    values: { resourceEstimates },
    setFieldValue
  } = useFormikContext();

  const onRemoveEstimateSubmit = useCallback(async () => {
    const resourceEstimate = resourceEstimates.find(
      est => est.id === resourceEstimateId
    );
    const { data } = await onRemoveTaskResourceEstimateSubmit(
      {
        taskResourceEstimateId: resourceEstimateId
      },
      [
        {
          eventName: EVENT_TYPE.GANTT_TASK_RESOURCE_COUNT_UPDATE,
          payload: {
            taskId,
            updateResourceEstimatesCountBy: -1,
            updateResourceEstimatesWithUsersCountBy: resourceEstimate.resourceUser
              ? -1
              : 0
          }
        }
      ]
    );

    const updatedResourceEstimates = resourceEstimates.filter(
      est => est.id !== data.removeTaskResourceEstimate.taskResourceEstimateId
    );

    setFieldValue('resourceEstimates', updatedResourceEstimates);
    onClose();
  }, [
    onRemoveTaskResourceEstimateSubmit,
    resourceEstimateId,
    onClose,
    resourceEstimates,
    setFieldValue,
    taskId
  ]);

  return (
    <DialogActions className={classes.actionContainer}>
      <Button onClick={onClose}>
        <FormattedMessage id="removeTaskEstimateDialog.cancel" />
      </Button>
      <LoadingButton
        color="secondary"
        onClick={onRemoveEstimateSubmit}
        isLoading={loading}
      >
        <FormattedMessage id="removeTaskEstimateDialog.removeEstimate" />
      </LoadingButton>
    </DialogActions>
  );
};

RemoveTaskEstimateConfirmationDialogFooter.propTypes = {
  taskId: PropTypes.string,
  resourceEstimateId: PropTypes.string,
  onClose: PropTypes.func
};

export default RemoveTaskEstimateConfirmationDialogFooter;
