import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Tooltip, makeStyles } from '@material-ui/core';
import { PreferredResourceIcon } from '~/modules/common/components/Icons';
import { User } from '~/modules/common/components';
import useStyles from './useStyles';

const usePreferredResourceStyles = makeStyles(theme => ({
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.caption.fontSize
  },
  tooltipContent: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    margin: theme.spacing(0.25, 0, 0, 0)
  }
}));

const useUserStyles = makeStyles(theme => ({
  label: {
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    paddingLeft: theme.spacing(0)
  },
  container: {
    margin: theme.spacing(0.5, 0),
    flexWrap: 'nowrap',
    textAlign: 'left',
    alignContent: 'flex-start',
    flexDirection: 'row',
    verticalAlign: 'top',
    justifyContent: 'flex-start',
    textOverflow: 'ellipsis'
  },
  avatar: {
    flexGrow: 0,
    flexShrink: 0,
    width: '1.95em !important',
    height: '1.95em !important',
    fontSize: '0.9em !important',
    alignSelf: 'center',
    margin: theme.spacing(-0.5, 0.5, -0.5, 0)
  }
}));

export const PreferredResourceToolTipContent = ({ preferredResources }) => {
  const { formatMessage } = useIntl();

  const userClasses = useUserStyles();
  const preferredResourceClasses = usePreferredResourceStyles();

  return (
    <>
      <div className={preferredResourceClasses.tooltipTitle}>
        {formatMessage({
          id: 'resourceRequestPreferredResources.preferredResources'
        })}
      </div>
      <div className={preferredResourceClasses.tooltipContent}>
        {preferredResources.map(user => (
          <User
            key={user.id}
            user={user}
            classes={userClasses}
            disablePadding
          />
        ))}
      </div>
    </>
  );
};

PreferredResourceToolTipContent.propTypes = {
  preferredResources: PropTypes.array
};

export const ResourceRequestPreferredResourcesField = ({
  preferredResources,
  tooltipClasses
}) => {
  const classes = useStyles();
  const title = useMemo(
    () => (
      <PreferredResourceToolTipContent
        preferredResources={preferredResources}
      />
    ),
    [preferredResources]
  );

  return (
    <Tooltip title={title} classes={tooltipClasses}>
      <PreferredResourceIcon className={classes.detailsIndicator} />
    </Tooltip>
  );
};

ResourceRequestPreferredResourcesField.propTypes = {
  preferredResources: PropTypes.array,
  tooltipClasses: PropTypes.object
};

export default ResourceRequestPreferredResourcesField;
