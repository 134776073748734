import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { v4 } from 'uuid';
import get from 'lodash.get';

export const GET_REMOVE_BILLING_RATE_DETAILS = gql`
  query getRemoveBillingRateErrorDetails(
    $projectId: String!
    $billingRate: BillingRateInput
  ) {
    project(projectId: $projectId) {
      id
      removeBillingRateErrorDetails(billingRate: $billingRate) {
        billingRate {
          id
          displayText
        }
        resourcesAssignedToBillingRate {
          resourceType {
            id
            displayText
          }
          user {
            id
            displayText
          }
        }
      }
    }
  }
`;

export const useBillingRateHandler = ({
  me,
  projectId,
  rateType,
  setFieldValue,
  billingRates,
  availableBillingRates
}) => {
  const client = useApolloClient();
  const onDeleteBillingRate = useCallback(
    async props => {
      const {
        id,
        billingRate,
        setOpenNotificationDialog,
        setRemoveErrorDetails
      } = props;

      if (availableBillingRates && availableBillingRates[billingRate.id]) {
        const { data } = await client.query({
          query: GET_REMOVE_BILLING_RATE_DETAILS,
          variables: {
            projectId,
            billingRate: { id: billingRate.id, type: rateType }
          },
          fetchPolicy: 'network-only'
        });

        const details = get(
          data,
          'project.removeBillingRateErrorDetails',
          null
        );

        if (details) {
          setOpenNotificationDialog(true);
          setRemoveErrorDetails({ ...details, id });

          return;
        }
      }

      setFieldValue(
        'billingRates',
        billingRates.filter(x => x.id !== id)
      );
    },
    [
      availableBillingRates,
      billingRates,
      client,
      projectId,
      rateType,
      setFieldValue
    ]
  );
  const onConfirmRemoveEntryRow = useCallback(
    rowId =>
      setFieldValue(
        'billingRates',
        billingRates.filter(x => x.id !== rowId)
      ),
    [billingRates, setFieldValue]
  );

  const onAddBillingRate = useCallback(() => {
    const newBillingRate = {
      id: v4(),
      billingRate: {
        id: null,
        billingScheduleEntries: [
          {
            id: v4(),
            effectiveDate: null,
            rate: { amount: 0, currency: me.baseCurrency }
          }
        ]
      }
    };

    setFieldValue('billingRates', [newBillingRate, ...billingRates]);
    setFieldValue(`billingRates[${billingRates.length}].dirty`, true);
  }, [billingRates, me.baseCurrency, setFieldValue]);

  return {
    onDeleteBillingRate,
    onAddBillingRate,
    onConfirmRemoveEntryRow
  };
};
export default useBillingRateHandler;
