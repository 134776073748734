import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useSessionStorage } from '~/modules/common/hooks';

export const PUT_TIME_AND_EXPENSE_SETTINGS = gql`
  mutation putTimeAndExpenseSettings(
    $timeAndExpenseSettingsInput: TimeAndExpenseSettingsInput!
  ) {
    putTimeAndExpenseSettings: putTimeAndExpenseSettings2(
      timeAndExpenseSettingsInput: $timeAndExpenseSettingsInput
    ) {
      rows
      groupings
      columns
    }
  }
`;

export const optimisticResponse = settings => ({
  __typename: 'Mutation',
  putTimeAndExpenseSettings: {
    __typename: 'TimeAndExpenseSettings',
    rows: settings.rows.values,
    groupings: settings.groupings.values,
    columns: settings.columns.values
  }
});

const usePutSettings = id => {
  const [putTimeAndExpenseSettings] = useMutation(
    PUT_TIME_AND_EXPENSE_SETTINGS
  );

  const { setValue, storedValue } = useSessionStorage(
    'timeAndExpenseSettings',
    {}
  );

  const saveSettings = useCallback(
    settings => {
      setValue({
        ...storedValue,
        [id]: {
          anchorDate: settings.anchorDate
        }
      });
      putTimeAndExpenseSettings({
        variables: {
          timeAndExpenseSettingsInput: {
            id,
            rows: settings.rows.values,
            groupings: settings.groupings.values,
            columns: settings.columns.values
          }
        },
        optimisticResponse: optimisticResponse(settings)
      });
    },
    [setValue, storedValue, id, putTimeAndExpenseSettings]
  );

  return {
    saveSettings
  };
};

export default usePutSettings;
