import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const getStyle = backgroundColor => ({
  backgroundColor,
  color: theme.palette.common.black
});

const backgroundColors = {
  thirtyDays: theme.palette.accountReceivableStatus.thirtyDays.main,
  sixtyDays: theme.palette.accountReceivableStatus.sixtyDays.main,
  ninetyDays: theme.palette.accountReceivableStatus.ninetyDays.main,
  oneTwentyDays: theme.palette.accountReceivableStatus.oneTwentyDays.main,
  greaterThanOneTwentyDays:
    theme.palette.accountReceivableStatus.greaterThanoneTwentyDays.main
};

const getStatusStyles = day => {
  if (day <= 30) return getStyle(backgroundColors.thirtyDays);
  if (day > 30 && day <= 60) return getStyle(backgroundColors.sixtyDays);
  if (day > 60 && day <= 90) return getStyle(backgroundColors.ninetyDays);
  if (day > 90 && day <= 120) return getStyle(backgroundColors.oneTwentyDays);

  return getStyle(backgroundColors.greaterThanOneTwentyDays);
};

const getChipLabel = daysValue => (
  <FormattedMessage id="accountReceivable.chipLabel" values={daysValue} />
);

export const PastDueDays = ({ field, record }) => {
  const days = record[field];
  const daysValue = useMemo(() => ({ days }), [days]);

  return <Chip label={getChipLabel(daysValue)} style={getStatusStyles(days)} />;
};

PastDueDays.propTypes = {
  record: PropTypes.object,
  field: PropTypes.string
};

export default PastDueDays;
