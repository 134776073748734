import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { rate } from './query';

export const GET_BILLING_RATE = gql`
  query getBillingRateOptions($projectId: String!, $asOfDate: DateObject) {
    project(projectId: $projectId) {
      id
      effectiveBillingRates(asOfDate: $asOfDate) {
        id
        displayText
        ${rate}
      }
    }
  }
`;

const useBillingRateOptions = ({ projectId, asOfDate = null }) => {
  const { loading, error, data } = useQuery(GET_BILLING_RATE, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      projectId,
      asOfDate
    }
  });

  const effectiveBillingRates = get(data, 'project.effectiveBillingRates', []);

  return {
    loading,
    error,
    billingRateOptions: effectiveBillingRates
  };
};

export default useBillingRateOptions;
