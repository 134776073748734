import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/LensSharp';
import { FormattedMessage } from 'react-intl';
import { scores, NONE } from './scores';

const useStyles = makeStyles(theme => ({
  none: {
    fontStyle: 'italic',
    color: theme.palette.text.secondary
  },
  circle: {
    width: 22,
    height: 22
  },
  green: {
    color: theme.palette.score.green.main
  },
  yellow: {
    color: theme.palette.score.yellow.main
  },
  red: {
    color: theme.palette.score.red.main
  }
}));

export const Score = ({ score }) => {
  const classes = useStyles();

  return score === NONE ? (
    <Typography variant="body1" className={classes.none}>
      <FormattedMessage id="score.none" />
    </Typography>
  ) : (
    <CircleIcon className={classNames(classes.circle, classes[score])} />
  );
};

Score.propTypes = {
  score: PropTypes.oneOf(scores).isRequired
};

export default Score;
