import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { vendorFragment } from '../graphql/vendorFragment';

export const UPDATE_VENDOR_STATUS = gql`
  mutation updateVendorStatus($input: UpdateVendorInput!) {
    updateVendor(input: $input) {
      isActive
    }
  }
`;

export const useUpdateVendorStatus = ({ id }) => {
  const [updateVendor] = useMutation(UPDATE_VENDOR_STATUS, {
    update: updateCache({ id })
  });

  return {
    updateVendor: input => {
      return updateVendor({
        variables: {
          input
        }
      });
    }
  };
};

export const updateCache = ({ id }) => (proxy, { data }) => {
  if (data && data.updateVendor) {
    const {
      updateVendor: { isActive }
    } = data;

    const fragmentId = `Vendor:${id}`;

    const vendor = proxy.readFragment({
      id: fragmentId,
      fragment: vendorFragment
    });

    proxy.writeFragment({
      id: fragmentId,
      fragment: vendorFragment,
      data: {
        ...vendor,
        isActive
      }
    });
  }
};

const useUpdateVendorStatusHandlers = ({
  id,
  name,
  isActive,
  closeMenu,
  closedDate
}) => {
  const { updateVendor } = useUpdateVendorStatus({
    id
  });

  const onStatusActiveHandler = useCallback(() => {
    updateVendor({ id, name, isActive: true, closedDate });
    closeMenu();
  }, [closeMenu, closedDate, id, name, updateVendor]);

  const onStatusInactiveHandler = useCallback(() => {
    updateVendor({ id, name, isActive: false, closedDate });
    closeMenu();
  }, [closeMenu, closedDate, id, name, updateVendor]);

  return {
    onStatusActiveHandler,
    onStatusInactiveHandler
  };
};

export default useUpdateVendorStatusHandlers;
