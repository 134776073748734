import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import DialogInitialLoadingContent from '~/modules/common/components/DialogInitialLoadingContent';
import ErrorDialogContent from '../ErrorDialogContent';
import BulkDeleteConfirmationDialogContent from './BulkDeleteConfirmationDialogContent';
import BulkDeleteResultDialogContent from './BulkDeleteResultDialogContent';
import BulkDeleteProgressDialogContent from './BulkDeleteProgressDialogContent';
import { objectHooks } from './hooks';
import dialogContents from './dialogContents';

const confirmationDialogRole = {
  role: 'presentation'
};

export const BulkDeleteConfirmationDialog = ({
  open,
  onClose,
  objectType,
  objectIds = []
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const content = dialogContents[objectType];
  const { canBulkDelete, createBulkDeleteBatch } = objectHooks[objectType];
  const {
    loading,
    canDeleteUris,
    canArchiveUris,
    errors,
    error: loadError
  } = canBulkDelete({
    objectIds
  });
  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false,
    batchCompleted: false
  });
  const [error, setError] = useState(null);

  const handleDelete = createBulkDeleteBatch({
    objectIds,
    setBatchState
  });
  const deleteCount = canDeleteUris.length;
  const archiveCount = canArchiveUris.length;
  const errorCount = errors.length;

  const deleteValue = useMemo(
    () =>
      deleteCount > 0
        ? {
            value: deleteCount,
            object: (
              <FormattedMessage
                id={deleteCount > 1 ? content.objects : content.object}
              />
            )
          }
        : null,
    [deleteCount, content]
  );

  const archiveValue = useMemo(
    () =>
      archiveCount > 0
        ? {
            value: archiveCount,
            object: (
              <FormattedMessage
                id={archiveCount > 1 ? content.objects : content.object}
              />
            )
          }
        : null,
    [archiveCount, content]
  );

  const errorValue = useMemo(
    () =>
      errorCount > 0
        ? {
            value: errorCount,
            object: (
              <FormattedMessage
                id={errorCount > 1 ? content.objects : content.object}
              />
            )
          }
        : null,
    [errorCount, content]
  );

  const removeAndArchiveValues = useMemo(
    () =>
      archiveValue && deleteValue
        ? {
            archiveValue: archiveValue.value,
            archiveObject: archiveValue.objects,
            removeValue: deleteValue.value,
            removeObject: deleteValue.object
          }
        : null,
    [archiveValue, deleteValue]
  );

  const dialogContent =
    deleteCount > 0 && archiveCount > 0
      ? {
          title: <FormattedMessage id={content.removeAndArchive.title} />,
          message: (
            <FormattedMessage
              id={content.removeAndArchive.message}
              values={removeAndArchiveValues}
            />
          ),
          info: (
            <FormattedMessage
              id={
                archiveCount > 1
                  ? 'bulkRemoveDialog.info.xArchive'
                  : 'bulkRemoveDialog.info.archive'
              }
              values={archiveValue}
            />
          )
        }
      : deleteCount === 0 && archiveCount > 0
      ? {
          title: <FormattedMessage id={content.archive.title} />,
          message: (
            <FormattedMessage
              id={content.archive.message}
              values={archiveValue}
            />
          ),
          info: (
            <FormattedMessage
              id={
                archiveCount > 1
                  ? 'bulkRemoveDialog.info.xArchive'
                  : 'bulkRemoveDialog.info.archive'
              }
              values={archiveValue}
            />
          )
        }
      : {
          title: <FormattedMessage id={content.remove.title} />,
          message: deleteValue && (
            <FormattedMessage
              id={content.remove.message}
              values={deleteValue}
            />
          )
        };

  const errorMessage =
    errorCount > 0 ? (
      <FormattedMessage
        id="bulkRemoveDialog.error.permission"
        values={errorValue}
      />
    ) : null;

  const updatedContnet = useMemo(() => ({ errorMessage, ...dialogContent }), [
    errorMessage,
    dialogContent
  ]);

  const handleOnClose = useCallback(() => {
    onClose(batchState);
  }, [batchState, onClose]);

  const onError = useCallback(
    newError => {
      setBatchState({ ...batchState, batchInProgress: false });
      setError(newError);
    },
    [batchState, setBatchState, setError]
  );

  const hasError = loadError || error;

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={confirmationDialogRole}
    >
      <DialogTitle>{dialogContent.title}</DialogTitle>
      {loading ? (
        <DialogInitialLoadingContent onClose={handleOnClose} />
      ) : batchState.batchId && batchState.batchInProgress ? (
        <BulkDeleteProgressDialogContent
          onClose={handleOnClose}
          onError={onError}
          batchId={batchState.batchId}
          setBatchState={setBatchState}
          objectCount={objectIds.length}
        />
      ) : !hasError && batchState.batchCompleted ? (
        <BulkDeleteResultDialogContent
          onClose={handleOnClose}
          onError={onError}
          objectType={objectType}
          batchId={batchState.batchId}
        />
      ) : hasError ? (
        <ErrorDialogContent
          title={dialogContent.title}
          error={error || loadError}
          onClose={handleOnClose}
        />
      ) : (
        <BulkDeleteConfirmationDialogContent
          onClose={handleOnClose}
          batchState={batchState}
          onDelete={handleDelete}
          dialogContent={updatedContnet}
          enableRemove={deleteCount > 0 || archiveCount > 0}
        />
      )}
    </Dialog>
  );
};

BulkDeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  objectType: PropTypes.oneOf(['PROJECT']).isRequired,
  objectIds: PropTypes.array.isRequired
};

export default BulkDeleteConfirmationDialog;
