import React from 'react';
import { FormattedMessage } from 'react-intl';

export const buildFooters = ({ classes, selectedColumns, tags }) => {
  const footers = {
    name: {
      id: 'billing-transaction',
      value: <FormattedMessage id="billingTransactionList.total" />,
      fixed: true,
      visible: true,
      align: 'right',
      className: classes.total
    },
    displayText: {
      id: 'displayText',
      visible: selectedColumns.includes('rowSelector')
    },
    pastDue: {
      id: 'past-due',
      visible: selectedColumns.includes('pastDueDays')
    },
    client: {
      id: 'client',
      visible: selectedColumns.includes('client')
    },
    transactionDate: {
      id: 'transactionDate',
      visible: selectedColumns.includes('transactionDate')
    },
    type: {
      id: 'type',
      visible: selectedColumns.includes('type')
    },
    dueDate: {
      id: 'dueDate',
      visible: selectedColumns.includes('dueDate')
    },
    period: {
      id: 'period',
      visible: selectedColumns.includes('period')
    },
    balanceWithoutAllocationsAmount: {
      id: 'balanceWithoutAllocations',
      visible: selectedColumns.includes('balanceWithoutAllocationsAmount'),
      align: 'right',
      flip: false
    },
    balanceAmount: {
      id: 'balance',
      visible: selectedColumns.includes('balanceAmount'),
      align: 'right',
      flip: false
    },
    balanceStatus: {
      id: 'status',
      visible: selectedColumns.includes('balanceStatus')
    },
    actionColumn: {
      id: 'actionColumn',
      visible: selectedColumns.includes('actionColumn')
    },
    description: {
      id: 'description',
      visible: selectedColumns.includes('description')
    }
  };

  const tagColumns = (tags || []).reduce(
    (obj, key) => ({
      ...obj,
      [key.id]: {
        id: key.id,
        visible: selectedColumns.includes(key.id)
      }
    }),
    {}
  );

  return { ...footers, ...tagColumns };
};
