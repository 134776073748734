import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';

import TagListItem from './TagListItem';

const TagList = ({ tags = [], onRemoveTag }) => (
  <List variant="scrollable">
    {tags.map((value, index) => (
      <TagListItem
        key={value}
        onRemoveTag={onRemoveTag}
        tagId={value}
        index={index}
      />
    ))}
  </List>
);

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  onRemoveTag: PropTypes.func
};

export default TagList;
