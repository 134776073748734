import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { NumberTextField, Percentage } from '~/modules/common/components';
import { useChangeOnBlurInputHandlers } from '~/modules/resourcing/common/chart/hooks';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';

const useToolTipStyles = makeStyles(theme => ({
  tooltipDetails: {
    margin: theme.spacing(0.5, 0, 0, 0)
  }
}));

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    width: '100%'
  },
  loadingPercentLabel: {},
  root: {}
}));

export const ResourceLoadingFieldToolTipContent = ({
  intl,
  isAdjustedLoading,
  numberValue
}) => {
  const classes = useToolTipStyles();

  return (
    <>
      <FormattedMessage id="resourceLoadingField.loading" />
      {': '}
      <Percentage
        aria-label={intl.formatMessage({
          id: 'resourceLoadingField.percentAria'
        })}
        value={numberValue || 0}
      />
      {isAdjustedLoading ? (
        <div className={classes.tooltipDetails}>
          {intl.formatMessage({
            id: 'resourceLoadingField.adjustedLoading'
          })}
          <br />
          {intl.formatMessage({
            id: 'resourceLoadingField.adjustedLoadingMessage'
          })}
        </div>
      ) : null}
    </>
  );
};

ResourceLoadingFieldToolTipContent.propTypes = {
  intl: PropTypes.object,
  isAdjustedLoading: PropTypes.bool,
  numberValue: PropTypes.number
};

const ResourceLoadingField = ({
  value = 0,
  classes: classesOverrides,
  onChange,
  dataQeId,
  max = 999,
  disabled = false,
  InputProps,
  isAdjustedLoading
}) => {
  const me = useMeContext();

  const classes = useStyles({ classes: classesOverrides });

  const numberSettings = getReactNumberFormatFromMe(me);

  const {
    numberValue,
    handleOnValueChange,
    handleOnBlur,
    highlightTarget
  } = useChangeOnBlurInputHandlers({ value, onChangeCallback: onChange });

  const {
    loadingPercentLabel,
    loadingContainer,
    ...remainingClasses
  } = classes;
  const intl = useIntl();

  const tooltip = useMemo(
    () => (
      <ResourceLoadingFieldToolTipContent
        intl={intl}
        isAdjustedLoading={isAdjustedLoading}
        numberValue={numberValue}
      />
    ),
    [intl, isAdjustedLoading, numberValue]
  );

  return (
    <Tooltip title={tooltip}>
      <span className={loadingContainer}>
        <NumberTextField
          dataqeid={dataQeId}
          align="left"
          classes={remainingClasses}
          value={parseFloat(
            (numberValue || 0).toFixed(numberSettings.decimalScale || 2)
          )}
          onChange={handleOnValueChange}
          onNumberBlur={handleOnBlur}
          ariaLabel={intl.formatMessage({ id: 'resourceLoadingField.loading' })}
          onFocus={highlightTarget}
          disabled={disabled}
          endAdornment={useMemo(
            () => (
              <span className={loadingPercentLabel}>%</span>
            ),
            [loadingPercentLabel]
          )}
          min={0.01}
          max={max}
          step={0.01}
          variant="standard"
          precision={numberSettings.decimalScale || 2}
          additionalInputProps={InputProps}
        />
        {isAdjustedLoading && (
          <span className={classes.adjustedIndicator}>*</span>
        )}
      </span>
    </Tooltip>
  );
};

ResourceLoadingField.propTypes = {
  dataQeId: PropTypes.string,
  value: PropTypes.number,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  max: PropTypes.number,
  isAdjustedLoading: PropTypes.bool
};

export default ResourceLoadingField;
