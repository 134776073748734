import { Tab, Tabs, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { AbbreviatedDecimal } from '~/modules/common/components';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { dateTag } from '~/modules/common/components/SearchBox/Facets/DateRangeFacet/tag';
import { AvailableToBillSearchContextProvider } from '../AvailableToBillSubTab/AvailableToBillSearchContextProvider';
import { useAvailableToBillTotals } from '../AvailableToBillListTable/hooks';
import { useFilterBasedOnSearchCriteria } from '../AvailableToBillSubTab/hooks';
import { useAvailableToBillSearchContext } from '../AvailableToBillSubTab/AvailableToBillSearchContext';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0,
    zIndex: theme.zIndex.drawer - 5,
    backgroundColor: theme.palette.common.white,
    top: 0
  }
}));

const tabValues = {
  overview: 'overview',
  availableToBill: 'availableToBill',
  transactions: 'transactions',
  accountReceivable: 'accountReceivble'
};

export const BillingInvoicingTabInternal = ({
  OverviewSubTab,
  AvailableToBillSubTab,
  TransactionsSubTab,
  AccountReceivableTab,
  headerLevel = 0,
  showMenuButton,
  permissions,
  useSavedCriteria,
  setUseSavedCriteria,
  isTotalsLoading,
  isAccountReceivable = false,
  ...rest
}) => {
  const classes = useStyles();
  const { projectUri, client, programId } = rest;
  const {
    searchCriteria,
    setOverviewCallToActionCriteria
  } = useAvailableToBillSearchContext();
  const { availableToBillFilter } = useFilterBasedOnSearchCriteria({
    searchCriteria
  });
  const {
    canViewCreditMemo,
    canViewPayment,
    canViewBill,
    canViewBillingData,
    canViewProject
  } = permissions;

  const { totals, refetchFilteredTotals } = useAvailableToBillTotals({
    projectUri,
    client,
    programId,
    skip: !canViewProject
  });
  const { amountInBaseCurrency, amount: availableTotals = [] } = totals || {};
  const canViewTransactionSubTab =
    canViewBill || canViewCreditMemo || canViewPayment;
  const isMobile = useIsBreakpointDown('xs');
  const canViewOverviewSubTab = canViewBillingData && !isMobile;
  const canViewAvailableToBillSubtab = canViewBillingData && canViewProject;

  const getDefaultTab = (canViewOverview, canViewBilling) => {
    if (canViewOverview) {
      return tabValues.overview;
    }

    if (canViewBilling) {
      return tabValues.availableToBill;
    }

    return tabValues.transactions;
  };
  const [selectedTab, setSelectedTab] = useState(
    getDefaultTab(canViewOverviewSubTab, canViewBillingData)
  );

  useEffect(() => {
    setSelectedTab(getDefaultTab(canViewOverviewSubTab, canViewBillingData));
  }, [canViewOverviewSubTab, canViewBillingData]);

  const onChangeHandler = useCallback(
    (e, val) => {
      setSelectedTab(val);
      setUseSavedCriteria(true);
    },
    [setUseSavedCriteria]
  );

  const resourceLabels = useMemo(() => {
    const total =
      availableTotals.length === 1 ? availableTotals[0] : amountInBaseCurrency;
    const messageValues = total
      ? {
          formattedAmount: (
            <AbbreviatedDecimal
              prefix={`${total.currency.symbol} `}
              value={total.amount}
            />
          )
        }
      : null;

    return {
      overview: <FormattedMessage id="billingInvoicingTab.overview" />,
      availableToBill: (
        <span data-qe-id="availableToBill">
          {messageValues ? (
            <FormattedMessage
              id="billingInvoicingTab.availableToBillWithTotal"
              values={messageValues}
            />
          ) : (
            <FormattedMessage id="billingInvoicingTab.availableToBill" />
          )}
        </span>
      ),
      transactions: <FormattedMessage id="billingInvoicingTab.transactions" />,
      accountReceivable: (
        <FormattedMessage id="billingInvoicingTab.accountReceivable" />
      )
    };
  }, [amountInBaseCurrency, availableTotals]);

  const resolveDateTime = dateTime =>
    dateTime.toLocaleString(DateTime.DATE_MED);

  const handleCallToAction = useCallback(
    (startDate, endDate) => {
      if (!canViewAvailableToBillSubtab) return;
      const startDateValue = startDate ? resolveDateTime(startDate) : '';
      const endDateValue = endDate ? resolveDateTime(endDate) : '';

      setOverviewCallToActionCriteria({
        keywords: [],
        criterions: {
          [dateTag]: [
            {
              key: `(${startDateValue} - ${endDateValue})`,
              range: { startDate, endDate }
            }
          ]
        }
      });
      setUseSavedCriteria(false);
      setSelectedTab(tabValues.availableToBill);
    },
    [
      canViewAvailableToBillSubtab,
      setOverviewCallToActionCriteria,
      setUseSavedCriteria
    ]
  );

  return (
    <>
      <StickyHeader level={headerLevel} showMenuButton={showMenuButton}>
        <Tabs
          value={selectedTab}
          classes={classes}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={onChangeHandler}
        >
          {Boolean(canViewOverviewSubTab) && (
            <Tab
              value={tabValues.overview}
              label={resourceLabels.overview}
              data-qe-id="BillingInvoicingOverviewTab"
            />
          )}
          {Boolean(canViewAvailableToBillSubtab) && (
            <Tab
              value={tabValues.availableToBill}
              label={resourceLabels.availableToBill}
              data-qe-id="BillingInvoicingAvailableToBillTab"
            />
          )}
          {canViewTransactionSubTab && (
            <Tab
              value={tabValues.transactions}
              label={resourceLabels.transactions}
              data-qe-id="BillingInvoicingTransactionsTab"
            />
          )}
          {canViewBill && isAccountReceivable && (
            <Tab
              value={tabValues.accountReceivable}
              label={resourceLabels.accountReceivable}
              data-qe-id="AccountsReceivable"
            />
          )}
        </Tabs>
      </StickyHeader>
      {selectedTab === tabValues.overview ? (
        <OverviewSubTab
          headerLevel={headerLevel}
          setSelectedTab={setSelectedTab}
          handleCallToAction={handleCallToAction}
          {...rest}
        />
      ) : selectedTab === tabValues.availableToBill ? (
        <AvailableToBillSubTab
          headerLevel={headerLevel}
          permissions={permissions}
          refetchAvailableToBillTotal={refetchFilteredTotals}
          totals={totals}
          isTotalsLoading={isTotalsLoading}
          availableToBillFilter={availableToBillFilter}
          setSelectedTab={setSelectedTab}
          {...rest}
        />
      ) : selectedTab === tabValues.transactions ? (
        <TransactionsSubTab
          headerLevel={headerLevel}
          permissions={permissions}
          {...rest}
        />
      ) : selectedTab === tabValues.accountReceivable ? (
        <AccountReceivableTab
          headerLevel={headerLevel}
          permissions={permissions}
          {...rest}
        />
      ) : null}
    </>
  );
};

BillingInvoicingTabInternal.propTypes = {
  classes: PropTypes.object,
  OverviewSubTab: PropTypes.any,
  AvailableToBillSubTab: PropTypes.any,
  AccountReceivableTab: PropTypes.any,
  TransactionsSubTab: PropTypes.any,
  useSavedCriteria: PropTypes.bool,
  setUseSavedCriteria: PropTypes.func,
  headerLevel: PropTypes.number,
  showMenuButton: PropTypes.bool,
  permissions: PropTypes.object,
  totals: PropTypes.object,
  isTotalsLoading: PropTypes.bool,
  isAccountReceivable: PropTypes.bool
};

export const BillingInvoicingTab = ({
  searchState,
  useSavedCriteria,
  ...rest
}) => (
  <AvailableToBillSearchContextProvider
    searchState={searchState}
    useSavedCriteria={useSavedCriteria}
  >
    <BillingInvoicingTabInternal
      searchState={searchState}
      useSavedCriteria={useSavedCriteria}
      {...rest}
    />
  </AvailableToBillSearchContextProvider>
);

BillingInvoicingTab.propTypes = {
  searchState: PropTypes.string,
  useSavedCriteria: PropTypes.any
};

export default BillingInvoicingTab;
