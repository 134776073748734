import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { PAGE_SIZE } from '~/modules/common/const';
import {
  useLoadMore2 as useLoadMore,
  useSessionStorage
} from '~/modules/common/hooks';
import { useProjectRequestFilter } from '~/modules/project-request/ProjectRequestPage/hooks';
import { isNullOrUndefined } from '~/modules/common/util';
import { GET_PAGE_OF_PROJECT_REQUESTS_QUERY_VARIABLES_KEY } from '~/modules/project-request/ProjectRequestPage/hooks/constants';
import {
  columnArguments,
  enabledFieldsToVariables
} from '../projectRequestColumnSelection';

export const GET_PAGE_OF_PROJECT_REQUESTS = gql`
  query getPageOfProjectRequests(
    $page: Int!
    $pageSize: Int!
    $columns: [ProjectRequestColumn]
    $sort: ProjectRequestSort
    $filter: ProjectRequestFilter
    ${columnArguments}
  ) {
    projectRequests(
      page: $page
      pageSize: $pageSize
      columns: $columns
      sort: $sort
      filter: $filter
    ) {
      id
      name
      description
      requestedBy
      portfolio @include(if: $includePortfolio){
        id
        displayText
        ancestors {
          displayText
        }
      }
      createdBy @include(if: $includeCreatedBy){
        id
        displayText
      }
      status {
        name
        type
      }
      score @include(if: $includeScore){
        investment @include(if: $includeInvestment)
        risk @include(if: $includeRisk)
        value @include(if: $includeValue)
        effectiveTotal @include(if: $includeEffectiveTotal)
      }
      startDate @include(if: $includeEstimatedStartDate)
      timelineDuration @include(if: $includeEstimatedDuration){
        periodQuantity
        periodResolution
      }
      resourceAssignmentsCount @include(if: $includeResourceAssignmentsCount)
      estimatedCostInBaseCurrency @include(if: $includeEstimatedCostInBaseCurrency){
        amount
        currency {
          id
          symbol
        }
      }
      estimatedCostInPortfolioCurrency @include(if: $includeEstimatedCostInPortfolioCurrency)
    }
  }
`;

export const usePageOfProjectRequests = ({
  page = 1,
  pageSize = PAGE_SIZE,
  columns = [],
  sort,
  searchCriteria,
  summaryFilterState,
  excludePortfolio,
  portfolioCurrency
}) => {
  const { projectRequestFilter } = useProjectRequestFilter({
    searchCriteria,
    quickFilter: summaryFilterState?.quickFilter,
    excludePortfolio
  });

  const { setValue: setVariables } = useSessionStorage(
    GET_PAGE_OF_PROJECT_REQUESTS_QUERY_VARIABLES_KEY,
    null
  );

  const variables = {
    page,
    pageSize,
    columns,
    sort,
    filter: projectRequestFilter,
    ...enabledFieldsToVariables({
      columns
    })
  };

  const { data, fetchMore, loading } = useQuery(GET_PAGE_OF_PROJECT_REQUESTS, {
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: () => {
      setVariables(variables);
    }
  });
  const projectRequests = get(data, 'projectRequests', []);

  const { hasMore, loadingMore, loadMore } = useLoadMore({
    totalFetchedItems: projectRequests.length,
    fetchMore,
    loading,
    pageSize
  });

  return {
    loading,
    loadingMore,
    projectRequests: projectRequests.map(projectRequest => ({
      ...projectRequest,
      estimatedCostInPortfolioCurrency: !isNullOrUndefined(
        projectRequest.estimatedCostInPortfolioCurrency
      )
        ? {
            amount: projectRequest.estimatedCostInPortfolioCurrency,
            currency: portfolioCurrency
          }
        : null
    })),
    loadMore,
    hasMore
  };
};
export default usePageOfProjectRequests;
