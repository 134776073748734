import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ErrorOutlined from '@material-ui/icons/ErrorOutlineSharp';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    maxHeight: '100vh',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.error.dark,
    boxSizing: 'border-box',
    cursor: 'help'
  },
  svg: {
    fill: 'currentColor',
    flex: '1 1 auto'
  }
}));

const toTitle = (error, componentStack) =>
  `${error.toString()}\n\nThis is located at:${componentStack}`;

export const ErrorBoundaryFallbackComponent = ({ componentStack, error }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} title={toTitle(error, componentStack)}>
      <ErrorOutlined />
      <Typography variant="h6">
        <FormattedMessage id="centeredError.errorOccurred" />
        <span id="errorBoundary">{`${error}`}</span>
      </Typography>
    </div>
  );
};

ErrorBoundaryFallbackComponent.propTypes = {
  error: PropTypes.object.isRequired,
  componentStack: PropTypes.string
};

export default ErrorBoundaryFallbackComponent;
