import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { getQuarterFromMonth } from '~/modules/common/dates/quarter';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';

const ChartDateRangeLabel = ({ scale, start, end, scaleFormat = false }) => {
  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return scaleFormat
      ? shortDateRangeFormat({ start, end })
      : start.toLocaleString({ month: 'short', day: 'numeric' });
  }
  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    return shortDateRangeFormat({ start, end });
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    return scaleFormat ? (
      shortDateRangeFormat({ start, end })
    ) : (
      <>
        <FormattedMessage id="chart.quarterAbbreviated" />
        {getQuarterFromMonth(start.month)}{' '}
        {end.toLocaleString({ year: 'numeric' })}
      </>
    );
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    return scaleFormat
      ? shortDateRangeFormat({ start, end })
      : start.toLocaleString({ year: 'numeric' });
  }

  if (scaleFormat && scale === PERIOD_SCALE_ENUM.MONTHS) {
    return shortDateRangeFormat({ start, end });
  }

  return start.toLocaleString({ month: 'long', year: 'numeric' });
};

ChartDateRangeLabel.propTypes = {
  scale: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object,
  scaleFormat: PropTypes.bool
};

export default ChartDateRangeLabel;
