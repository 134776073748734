import React from 'react';
import PropTypes from 'prop-types';
import { isInChartDisplayDateRange } from '~/modules/resourcing/common/hooks/useClippingDateRangeUtil';
import AllocationSummaryBlock from './AllocationSummaryBlock';

export const AllocationSummaryBlocks = ({
  userAllocationSummaryPeriods,
  chartDisplayDateRange,
  scale,
  chartStartDate
}) => {
  const filteredAllocationSummaryPeriods = userAllocationSummaryPeriods.filter(
    entry =>
      isInChartDisplayDateRange({
        chartDisplayDateRange,
        entityDateRange: entry
      })
  );

  return filteredAllocationSummaryPeriods.map(
    userAllocationSummaryPeriod =>
      userAllocationSummaryPeriod.containsAllocation && (
        <AllocationSummaryBlock
          key={userAllocationSummaryPeriod.startDate.toISODate()}
          scale={scale}
          chartStartDate={chartStartDate}
          userAllocationSummaryPeriod={userAllocationSummaryPeriod}
          chartDisplayDateRange={chartDisplayDateRange}
        />
      )
  );
};

AllocationSummaryBlock.propTypes = {
  userAllocationSummaryPeriods: PropTypes.array.isRequired,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired
};

export default AllocationSummaryBlocks;
