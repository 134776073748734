import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { numberAbbreviatedTickFormat } from '~/modules/common/charts/dashboard';

import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

export default ({ seriesData, dateRanges, intl }) => {
  const theme = useTheme();

  const { resourceRequest: resourceRequestColors } = theme.palette;

  const xTickFormat = () => {
    const startDates = [];

    dateRanges.forEach(dateRange => startDates.push(dateRange.startDate));

    return startDates.map(startDate =>
      mapIsoStringtoUtcObject(startDate).toLocaleString(DateTime.DATE_MED)
    );
  };

  const colorScale = [
    resourceRequestColors.draft.main,
    resourceRequestColors.pending.main,
    resourceRequestColors.proposed.main,
    resourceRequestColors.resourceRejected.main,
    resourceRequestColors.toBeHired.main,
    resourceRequestColors.complete.main
  ];

  const chartLegends = [
    {
      label: <FormattedMessage id="projectOverview.draft" />,
      color: resourceRequestColors.draft.main,
      labelColor: theme.palette.common.black,
      key: 'draft'
    },
    {
      label: <FormattedMessage id="projectOverview.pending" />,
      color: resourceRequestColors.pending.main,
      labelColor: theme.palette.common.black,
      key: 'pending'
    },
    {
      label: <FormattedMessage id="projectOverview.proposed" />,
      color: resourceRequestColors.proposed.main,
      labelColor: theme.palette.common.black,
      key: 'proposed'
    },
    {
      label: <FormattedMessage id="projectOverview.rejectedText" />,
      color: resourceRequestColors.resourceRejected.main,
      labelColor: theme.palette.common.black,
      key: 'rejected'
    },
    {
      label: <FormattedMessage id="projectOverview.toBeHired" />,
      color: resourceRequestColors.toBeHired.main,
      labelColor: theme.palette.common.black,
      key: 'toBeHired'
    },
    {
      label: <FormattedMessage id="projectOverview.complete" />,
      color: resourceRequestColors.complete.main,
      labelColor: theme.palette.common.black,
      key: 'completed'
    }
  ];
  const chartLegendKeys = chartLegends.map(r => r.key);

  const dataSet = () => {
    if (!seriesData?.length) return [];
    const allKeys = Object.keys(seriesData[0]).filter(
      k => k !== '__typename' && chartLegendKeys.includes(k)
    );

    return allKeys.map(key =>
      xTickFormat().map((range, index) => ({
        x: range,
        y: seriesData[index][key].requestedResourcesCount || 0
      }))
    );
  };

  const yTickFormat = useCallback(
    tick => numberAbbreviatedTickFormat({ intl, tick }),
    [intl]
  );

  return {
    xTickFormat: xTickFormat(),
    colorScale,
    dataSet: dataSet(),
    chartLegends,
    yTickFormat
  };
};
