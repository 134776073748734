import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { DateRange, Percentage } from '~/modules/common/components';
import { ResourceAllocationRequestStatusBadge } from '~/modules/common/components/ResourceAllocationRequestStatusBadge';
import EntityName from '~/modules/common/components/entities/common/EntityName';
import { ResourceAllocationStatus } from '~/types';
import { useStyles, useDateStyles } from './useStyles';

export const UserAllocationSummaryTooltipContent = ({
  startDate,
  endDate,
  load,
  allocationDistributionItems,
  hideTotalAllocationLabel = false
}) => {
  const classes = useStyles();
  const dateClasses = useDateStyles();

  return (
    <>
      <div className={classes.title}>
        <DateRange start={startDate} end={endDate} classes={dateClasses} />
        <br />
      </div>
      <ul className={classes.projectList}>
        {allocationDistributionItems.map(item => (
          <li
            key={`${item.name}_${item.allocationStatus}`}
            className={classes.allocationItem}
          >
            <Percentage
              className={classes.value}
              value={item.load}
              fixedDecimalScale={false}
            />
            <EntityName className={classes.projectName} name={item.name} />
            {item.allocationStatus &&
            item.allocationStatus !== ResourceAllocationStatus.Committed ? (
              <ResourceAllocationRequestStatusBadge
                status={item.allocationStatus}
              />
            ) : (
              <span></span>
            )}
          </li>
        ))}
        {!hideTotalAllocationLabel && (
          <li className={classNames(classes.allocationItem, classes.totalItem)}>
            <Percentage
              className={classes.value}
              value={load}
              fixedDecimalScale={false}
            />
            <span className={classes.label}>
              <FormattedMessage id="resourceAllocationSummaryTooltip.allocated" />
            </span>
            <span className={classes.label}></span>
          </li>
        )}
      </ul>
    </>
  );
};

UserAllocationSummaryTooltipContent.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  load: PropTypes.number.isRequired,
  allocationDistributionItems: PropTypes.array,
  hideTotalAllocationLabel: PropTypes.bool
};

export default UserAllocationSummaryTooltipContent;
