import React, { Suspense } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import { useMeContext } from '~/modules/me/useMeContext';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import LoadingDrawer from '~/modules/common/components/TaskDrawer/LoadingDrawer';
import ErrorDrawer from '~/modules/common/components/TaskDrawer/ErrorDrawer';
import FormattedNameCode from '~/modules/common/components/entities/task/FormattedNameCode';
import LongString from '../LongString';
import Drawer, { DrawerHeader, DrawerContent } from '../Drawer';
import { useProjectDetailsQuery } from './useProjectDetailsQuery';
import ProjectDrawerHeaderInfo from './ProjectDrawerHeaderInfo';
import ProjectStatusSection from './ProjectStatusSection';

const useStyles = makeStyles(theme => ({
  header: {
    paddingLeft: theme.spacing(1)
  },
  taskTitle: {
    overflow: 'hidden'
  },
  titleIcon: {
    fontSize: theme.spacing(5),
    color: '#656565',
    margin: theme.spacing(0.75, 1.75)
  },
  titleText: {
    ...theme.typography.h6,
    fontSize: theme.spacing(2.75),
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  drawerContentOverride: {
    overflowX: 'unset',
    overflowY: 'unset',
    padding: 0
  }
}));

export const ProjectDrawer = ({
  project,
  onClose,
  hasMore,
  loadMore,
  loadingMore
}) => {
  const me = useMeContext();
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: { isPsaPrpTaskCodeOnTaskCreationEnabled }
  } = me;
  const numberFormat = getReactNumberFormatFromMe(me);
  const classes = useStyles();

  return (
    isRolledUpTaskEstimateCalculationMethodEnabled && (
      <Drawer>
        <div className={classes.header}>
          <DrawerHeader
            onClose={onClose}
            bgColor="inherit"
            color="inherit"
            paddingLeft="0"
          >
            <FolderSharpIcon className={classes.titleIcon} />
            <div>
              <FormattedMessage id="projectDrawer.projectName" />
              <Typography
                className={classes.titleText}
                color="inherit"
                variant="subtitle2"
              >
                <LongString>
                  <FormattedNameCode
                    name={project.name}
                    code={
                      isPsaPrpTaskCodeOnTaskCreationEnabled
                        ? project.code
                        : null
                    }
                  />
                </LongString>
              </Typography>
            </div>
          </DrawerHeader>
        </div>
        <Suspense fallback="">
          <DrawerContent className={classes.drawerContentOverride}>
            <ProjectDrawerHeaderInfo project={project} />
            <Divider />
            <ProjectStatusSection
              numberFormat={numberFormat}
              project={project}
              hasMore={hasMore}
              loadMore={loadMore}
              loadingMore={loadingMore}
            />
            <Divider />
          </DrawerContent>
        </Suspense>
      </Drawer>
    )
  );
};

const ProjectDrawerWrapper = ({ projectId, onClose }) => {
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();
  const {
    loading,
    error,
    project,
    hasMore,
    loadMore,
    loadingMore
  } = useProjectDetailsQuery({
    projectId,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  if (loading) return <LoadingDrawer onClose={onClose} />;
  if (!project && error) return <ErrorDrawer data={error} onClose={onClose} />;

  return (
    <ProjectDrawer
      project={project}
      onClose={onClose}
      hasMore={hasMore}
      loadMore={loadMore}
      loadingMore={loadingMore}
    />
  );
};

ProjectDrawerWrapper.propTypes = {
  projectId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

ProjectDrawer.propTypes = {
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired
};

export default ProjectDrawerWrapper;
