import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    width: 0,
    margin: theme.spacing(0, 2, 0, 0),
    '&:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      '&:last-child': {
        marginBottom: 0
      }
    },
    padding: 0
  }
}));

export const FormRow = ({ className, children }) => {
  const classes = useStyles();
  const childArray = React.Children.toArray(children);

  if (childArray.length === 0) return null;

  return (
    <ul className={classNames(classes.root, className)}>
      {React.Children.map(
        children,
        (child, index) =>
          Boolean(child) && (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className={classes.rowItem}>
              {child}
            </li>
          )
      )}
    </ul>
  );
};

FormRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
export default FormRow;
