import { makeStyles, IconButton, Box, withStyles } from '@material-ui/core';
import React from 'react';

import { PropTypes } from 'prop-types';
import EditIcon from '@material-ui/icons/EditSharp';
import { useIntl } from 'react-intl';
import { DateField } from '~/modules/common/components';

const styles = () => ({
  editIconBox: {
    display: 'flex'
  }
});

const useEditButtonStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: '0%',
    margin: theme.spacing(-2, 1, 0, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(-2, -1, 0, 0)
    }
  }
}));

export const DateAndEditIcon = ({
  classes,
  resourceLabels,
  dueDate,
  isPsaPswatBillingDraftSubStatusEnabled = false,
  editable,
  canEdit,
  setToEditMode
}) => {
  const editButtonClasses = useEditButtonStyles();

  const intl = useIntl();

  const ariaLabel = intl.formatMessage({ id: 'billDetails.editBill' });

  return (
    <Box className={classes.editIconBox}>
      <DateField
        variant="standard"
        fullWidth
        clearable={false}
        label={resourceLabels.dueDate}
        value={dueDate}
        editable={false}
        underline={false}
        dataQeId="billDetails.dueDate"
      />
      {!editable && canEdit && isPsaPswatBillingDraftSubStatusEnabled && (
        <IconButton
          aria-label={ariaLabel}
          data-qe-id="ReadOnly_Edit"
          onClick={setToEditMode}
          classes={editButtonClasses}
        >
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

DateAndEditIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  editable: PropTypes.bool,
  setToEditMode: PropTypes.func,
  isPsaPswatBillingDraftSubStatusEnabled: PropTypes.bool,
  resourceLabels: PropTypes.object,
  dueDate: PropTypes.object
};

export default withStyles(styles)(DateAndEditIcon);
