import React from 'react';
import { Container, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SearchField from '~/modules/common/components/SearchField';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    '& > div': {
      backgroundColor: theme.palette.grey[100]
    }
  }
}));

const RolesSearchMenuList = React.forwardRef(
  (
    {
      searchText,
      onSearchChange,
      loading,
      children,
      title,
      ariaLabel,
      searchRef,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <>
        {title}
        <Container className={classes.searchContainer}>
          <SearchField
            searchQeId="resourcingPlan-projectRole-SearchBar"
            value={searchText}
            searchRef={searchRef}
            onChange={onSearchChange}
          />
          {loading && <LinearProgress />}
        </Container>
        <ul ref={ref} {...rest} aria-label={ariaLabel}>
          {children}
        </ul>
      </>
    );
  }
);

RolesSearchMenuList.propTypes = {
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  searchRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

export default RolesSearchMenuList;
