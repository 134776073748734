import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

const PUT_PROJECT_BILLING_CONTRACT = gql`
  mutation PutProjectBillingContract(
    $projectId: String!
    $contractId: String!
    $projectScripts: [ProjectScriptInput]
  ) {
    putProjectBillingContract2(
      billingInput: {
        projectId: $projectId
        contractId: $contractId
        projectScripts: $projectScripts
      }
    ) {
      id
      billingTypeUri
      details
    }
  }
`;

export const usePutProjectBillingContract = ({ projectId, contractId }) => {
  const [putBillingContract] = useMutation(PUT_PROJECT_BILLING_CONTRACT);

  return useCallback(async () => {
    return putBillingContract({
      variables: {
        projectId,
        contractId,
        projectScripts: []
      }
    });
  }, [projectId, contractId, putBillingContract]);
};

export default usePutProjectBillingContract;
