import { PropTypes } from 'prop-types';
import React from 'react';
import { RequestAllocationTotals } from '~/modules/projects/resourcing-plan/components';
import useMeContext from '~/modules/me/useMeContext';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import useStyles from './useStyles';

export const ResourceAllocationCardTotal = ({
  label,
  resourceAllocatedTotal,
  resourceAllocatedPrefix
}) => {
  const classes = useStyles();
  const me = useMeContext();

  const numberSettings = getReactNumberFormatFromMe(me);

  return (
    <div className={classes.root}>
      <RequestAllocationTotals
        label={label}
        showAllocationTotals
        resourceAllocatedTotal={resourceAllocatedTotal}
        resourceAllocatedPrefix={resourceAllocatedPrefix}
        fixedDecimalScale={false}
        precision={numberSettings.decimalScale || 2}
      />
    </div>
  );
};

ResourceAllocationCardTotal.propTypes = {
  label: PropTypes.string,
  resourceAllocatedTotal: PropTypes.number,
  resourceAllocatedPrefix: PropTypes.string
};

export default ResourceAllocationCardTotal;
