import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddSharp';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { useHasPermission } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(3, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
  },
  addIcon: {
    position: 'relative',
    top: 6
  }
}));

export const NoProjectData = ({ showClickToAdd = true }) => {
  const classes = useStyles();
  const canEditProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const values = useMemo(
    () => ({
      value: isPsaPrpAccessibilityIssuesEnabled ? (
        <AddIcon
          className={classes.addIcon}
          color="inherit"
          aria-hidden="false"
          aria-label="add"
        />
      ) : (
        <AddIcon className={classes.addIcon} color="inherit" />
      )
    }),
    [classes.addIcon, isPsaPrpAccessibilityIssuesEnabled]
  );

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">
        <FormattedMessage id="projectList.noMatchingProjects" />
      </Typography>
      {showClickToAdd && canEditProject ? (
        <FormattedMessage id="projectList.clickToAddProject" values={values} />
      ) : null}
    </div>
  );
};

NoProjectData.propTypes = {
  showClickToAdd: PropTypes.bool
};

export default NoProjectData;
