import React from 'react';
import { DateTime } from 'luxon';
import UserCell from '~/modules/time-expense/components/UserCell';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
export const decimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
export const hourFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const mapKeyLabelOptions = (intl, me) => key => {
  if (
    key === 'expenseamount' ||
    key === 'resourcecostamount' ||
    key === 'costamount'
  ) {
    return {
      key,
      label: intl.formatMessage({ id: `timeAndExpenseSettingsFF.${key}` })
    };
  }

  return {
    key,
    label: intl.formatMessage({ id: `timeAndExpenseSettings.${key}` })
  };
};
const mapGroupings = groupings =>
  groupings.reduce((acc, group) => ({ ...acc, [group.key]: group.value }), {});

const formatMoney = value => {
  if (typeof value === 'number') {
    return currencyFormatter.format(value);
  }

  if (typeof value === 'string') {
    return value;
  }

  if (value && value.multiCurrencyValue) {
    return value.multiCurrencyValue
      .map(
        v => `${v.currency.displayText} ${decimalFormatter.format(v.amount)}`
      )
      .map(v => <span key={v}>{v}</span>);
  }

  return '–';
};

export const resolveValues = (supported, values) => {
  const vals = supported.filter(val => (values || []).includes(val));

  return vals.length > 0 ? vals : [supported[0]];
};

export const defaultSettings = {
  rows: ({ intl, me, settingsValues, supportedSettings }) => ({
    options: supportedSettings.rows.map(mapKeyLabelOptions(intl, me)),
    values: resolveValues(supportedSettings.rows, settingsValues.rows),
    formatters: {
      username: value =>
        value
          ? typeof value === 'string'
            ? {
                key: value,
                value
              }
            : value.uri && value.displayText
            ? {
                key: value.uri,
                Component: <UserCell user={value} />
              }
            : {
                key: '–',
                value: '–'
              }
          : {
              key: '–',
              value: '–'
            }
    }
  }),
  groupings: ({ intl, me, settingsValues, supportedSettings }) => ({
    options: supportedSettings.groupings.map(mapKeyLabelOptions(intl, me)),
    values: resolveValues(
      supportedSettings.groupings,
      settingsValues.groupings
    ),
    formatters: {
      // entrydate__month: value =>
      // DateTime.fromObject({ month: value }).toFormat('MMMM'),
      // entrydate__week: value => `Week ${value}`,
      // entrydate__quarter: value => `Quarter ${value}`,
      entrydate__day: values => {
        const groupings = mapGroupings(values);

        const date = DateTime.fromObject({
          year: groupings.entrydate__year,
          month: groupings.entrydate__month,
          day: groupings.entrydate__day
        });

        return {
          label: date.toLocaleString({ month: 'short', day: 'numeric' }),
          tooltip: date.toLocaleString(DateTime.DATE_MED)
        };
      },
      entrydate__week: values => {
        const { start, end } = values[0];

        return {
          label: `${DateTime.fromObject(start).toLocaleString(
            DateTime.DATE_MED
          )} - ${DateTime.fromObject(end).toLocaleString(DateTime.DATE_MED)}`,
          tooltip: `${DateTime.fromObject(start).toLocaleString(
            DateTime.DATE_MED
          )} - ${DateTime.fromObject(end).toLocaleString(DateTime.DATE_MED)}`
        };
      },
      entrydate__month: values => {
        const groupings = mapGroupings(values);

        const date = DateTime.fromObject({
          year: groupings.entrydate__year,
          month: groupings.entrydate__month,
          day: 1
        });

        return {
          label: date.toLocaleString({ month: 'long', year: 'numeric' }),
          tooltip: date.toLocaleString({ month: 'long', year: 'numeric' })
        };
      },
      entrydate__quarter: values => {
        const groupings = mapGroupings(values);

        const quarter =
          groupings.entrydate__quarter || DateTime.local().quarter;

        const date = DateTime.fromObject({
          year: groupings.entrydate__year,
          month: 1,
          day: 1
        }).plus({ quarters: quarter - 1 });

        const start = date
          .startOf('quarter')
          .toLocaleString({ month: 'short' });
        const end = date.endOf('quarter').toLocaleString({ month: 'short' });

        const format = `Q${date.toFormat('q yyyy')}\n${start} - ${end}`;

        return {
          label: format,
          tooltip: format
        };
      },
      entrydate__year: values => ({
        label: values[0].value,
        tooltip: values[0].value
      })
    }
  }),
  columns: ({ intl, me, settingsValues, supportedSettings }) => ({
    options: supportedSettings.columns.map(mapKeyLabelOptions(intl, me)),
    values: resolveValues(supportedSettings.columns, settingsValues.columns),
    formatters: {
      billablehours: value =>
        value
          ? typeof value === 'number'
            ? hourFormatter.format(value)
            : value
          : '–',
      resourcecostamount: formatMoney,
      costamount: formatMoney,
      expenseamount: formatMoney,
      billableamount: formatMoney
    },
    minHeight: {
      billablehours: () => 1,
      resourcecostamount: value =>
        value && value.multiCurrencyValue ? value.multiCurrencyValue.length : 1,
      costamount: value =>
        value && value.multiCurrencyValue ? value.multiCurrencyValue.length : 1,
      billableamount: value =>
        value && value.multiCurrencyValue ? value.multiCurrencyValue.length : 1,
      expenseamount: value =>
        value && value.multiCurrencyValue ? value.multiCurrencyValue.length : 1
    }
  })
};
