import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import ResourcePlanGrandTotal from '../ResourcePlanGrandTotal';
import ResourceAllocationChartFooter from '../ChartFooters/ResourceAllocationChartFooter';
import { useProjectTotals2 } from '../ChartFooters/hooks/useProjectTotals2';

const useStyles = makeStyles(theme => ({
  emptyFooter: {
    height: theme.spacing(4.625)
  }
}));

export const TotalsFooterContainer = ({
  handleDirectResourceAllocationSubmit,
  isEditSwitchEnabled,
  createAllocationExistingUserLoading,
  allocateResourcePopupAnchorEl,
  allocateResourcePopupMenuClose,
  allocateResourcePopupClick,
  setExistingResourceAllocation,
  existingResourceAllocation
}) => {
  const classes = useStyles();
  const {
    filter,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();

  const {
    loading,
    allocationsTotals,
    resourceRequestsTotals
  } = useProjectTotals2({
    filter,
    includeActualsResourceUsers: isResourceActualModeEnabled
  });

  const showGrandTotals = Boolean(
    (allocationsTotals?.totalHours || allocationsTotals?.totalCost?.amount) &&
      (resourceRequestsTotals?.totalHours ||
        resourceRequestsTotals?.totalCost?.amount)
  );

  return (
    <>
      <ResourceAllocationChartFooter
        handleDirectResourceAllocationSubmit={
          handleDirectResourceAllocationSubmit
        }
        isEditSwitchEnabled={isEditSwitchEnabled}
        allocationsTotals={allocationsTotals}
        loading={loading}
        createAllocationExistingUserLoading={
          createAllocationExistingUserLoading
        }
        allocateResourcePopupAnchorEl={allocateResourcePopupAnchorEl}
        allocateResourcePopupMenuClose={allocateResourcePopupMenuClose}
        allocateResourcePopupClick={allocateResourcePopupClick}
        setExistingResourceAllocation={setExistingResourceAllocation}
        existingResourceAllocation={existingResourceAllocation}
      />
      {showGrandTotals ? (
        <ResourcePlanGrandTotal
          loading={loading}
          allocationsTotals={allocationsTotals}
          resourceRequestsTotals={resourceRequestsTotals}
        />
      ) : (
        <div className={classes.emptyFooter}></div>
      )}
    </>
  );
};

TotalsFooterContainer.propTypes = {
  handleDirectResourceAllocationSubmit: PropTypes.func.isRequired,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  createAllocationExistingUserLoading: PropTypes.bool,
  allocateResourcePopupAnchorEl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node
  ]),
  allocateResourcePopupMenuClose: PropTypes.func.isRequired,
  allocateResourcePopupClick: PropTypes.func.isRequired,
  setExistingResourceAllocation: PropTypes.func,
  existingResourceAllocation: PropTypes.object
};

export default TotalsFooterContainer;
