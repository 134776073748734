import { makeStyles } from '@material-ui/core/styles';

export const useCardStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  container: {
    margin: theme.spacing(2)
  },
  toolbar: {
    margin: theme.spacing(0, 0, 1, 0),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  legend: {
    flexGrow: 1,
    flexShrink: 1
  }
}));

export const useContentStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
}));
