import React, { useCallback } from 'react';
import { Popover, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import {
  ProjectTeamMemberDropdown,
  RoleDropdown
} from '~/modules/tasks/components';
import { User } from '~/modules/common/components/User';
import { EVENT_TYPE } from '~/modules/common/enums/EventTypes';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import useOnChangeHandlers from '../../../hooks/useOnChangeHandlers';

const useStyles = makeStyles(theme => ({
  roleDropdown: {
    padding: theme.spacing(0, 1, 0, 0)
  },
  resourceDropdown: {
    padding: theme.spacing(0, 0, 0, 1),
    width: '50%'
  },
  resource: {
    padding: theme.spacing(0, 0, 0, 1),
    margin: theme.spacing(-1.25, 0, 0),
    width: '50%'
  },
  resourceLabel: {
    color: theme.palette.text.secondary
  }
}));

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    maxWidth: theme.spacing(65),
    width: theme.spacing(65),
    padding: theme.spacing(2, 1.5, 1.5),
    display: 'flex'
  }
}));

const useUserStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0)
  },
  name: {
    fontSize: theme.typography.body1.fontSize
  }
}));

const useRoleDropdownStyles = makeStyles(theme => ({
  root: {
    width: '50%'
  }
}));

const ResourceRoleDropdownEditor = ({
  anchorEl,
  setAnchorEl,
  resourceEstimate,
  projectSlug,
  rowIndex
}) => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const { formatMessage } = useIntl();
  const userClasses = useUserStyles();
  const roleDropdownClasses = useRoleDropdownStyles();
  const { task } = useTaskEstimateRowContext();

  const { resourceUser, projectRole } = resourceEstimate;

  const { setFieldValue } = useFormikContext();

  const { onRoleChange, onResourceChange } = useOnChangeHandlers({
    resourceEstimate,
    setFieldValue,
    rowIndex
  });

  const handleOnRoleChange = useCallback(
    role => {
      onRoleChange(role?.id);
      setFieldValue(`resourceEstimates[${rowIndex}].projectRole`, role);
    },
    [onRoleChange, setFieldValue, rowIndex]
  );

  const handleOnResourceChange = useCallback(
    resource => {
      onResourceChange(resource?.id, [
        {
          eventName: EVENT_TYPE.GANTT_TASK_RESOURCE_COUNT_UPDATE,
          payload: {
            taskId: task.id,
            updateResourceEstimatesCountBy: 0,
            updateResourceEstimatesWithUsersCountBy: 1
          }
        }
      ]);
    },
    [onResourceChange, task]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const addResourceLabel = formatMessage({
    id: 'taskResourceEstimates.resource'
  });

  const addRoleLabel = formatMessage({
    id: 'taskResourceEstimates.role'
  });

  return (
    <Popover
      id="resource-role-editor"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={popoverClasses}
    >
      <RoleDropdown
        className={classes.roleDropdown}
        classes={roleDropdownClasses}
        variant="outlined"
        projectSlug={projectSlug}
        placeholder={addRoleLabel}
        label={addRoleLabel}
        value={projectRole}
        onChange={handleOnRoleChange}
        disableClearable={!resourceUser?.id}
      />
      {resourceUser ? (
        <div className={classes.resource}>
          <Typography variant="caption" className={classes.resourceLabel}>
            {addResourceLabel}
          </Typography>
          <User classes={userClasses} user={resourceUser} />
        </div>
      ) : (
        <ProjectTeamMemberDropdown
          className={classes.resourceDropdown}
          variant="outlined"
          label={addResourceLabel}
          ariaLabel={addResourceLabel}
          placeholder={addResourceLabel}
          projectSlug={projectSlug}
          assignedRole={projectRole}
          value={resourceUser}
          selectedResources={[resourceUser]}
          onResourceChange={handleOnResourceChange}
          taskId={task.id}
        />
      )}
    </Popover>
  );
};

ResourceRoleDropdownEditor.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  resourceEstimate: PropTypes.object,
  projectSlug: PropTypes.string,
  rowIndex: PropTypes.number
};

export default ResourceRoleDropdownEditor;
