import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { User, DateRange } from '~/modules/common/components';

export const DirectReleaseResourceDialogContent = ({ allocation }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="none">
            <FormattedMessage id="releaseResourceRequestDialog.userHeader" />
          </TableCell>
          <TableCell padding="none">
            <FormattedMessage id="releaseResourceRequestDialog.allocationDate" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell padding="none">
            <User user={allocation.user.user} />
          </TableCell>
          <TableCell padding="none">
            <DateRange start={allocation.startDate} end={allocation.endDate} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

DirectReleaseResourceDialogContent.propTypes = {
  allocation: PropTypes.object.isRequired
};

export default DirectReleaseResourceDialogContent;
