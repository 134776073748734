import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chart: {
    position: 'relative'
  },
  spaceContainer: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    background: 'none'
  },
  addButton: {
    borderRight: 'none',
    paddingTop: 5,
    paddingLeft: theme.spacing(1)
  },
  root: {
    ...theme.typography.body1,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  container: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  containerReadonly: {
    padding: theme.spacing(0.5, 2, 0, 1.5)
  }
}));

export default useStyles;
