import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { getEquivalentRolledUpTaskStatus } from '~/modules/common/components/ProjectDrawer';
import { ResourceEstimatesSummary } from '../../Gantt/components';
import { TaskCardTitle2, TaskCardFooter } from './components';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 7px -7px rgba(0, 0, 0, 0.85)',
    width: '100%'
  }
}));

const useResourceEstimatesStyles = makeStyles(theme => ({
  resourceEstimatesSummary: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
}));

const useCardHeaderStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  }
}));

const getCardStyle = ({ taskLevel }) => ({
  zIndex: 10 - taskLevel
});

export const TaskCard2 = ({
  canViewCostData,
  canViewEstimate,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  isRTL,
  numberFormat,
  task: {
    code,
    displayText,
    endDate,
    fullPath,
    hasChildren,
    initialEstimatedHours,
    rolledUpSummary,
    rolledUpCostSummary,
    resourceEstimatesSummary,
    startDate,
    taskStatus
  }
}) => {
  const classes = useStyles();
  const resourceEstimatesClasses = useResourceEstimatesStyles();
  const cardHeaderClasses = useCardHeaderStyles();
  const intl = useIntl();

  const status = isRolledUpTaskEstimateCalculationMethodEnabled
    ? getEquivalentRolledUpTaskStatus({
        status: taskStatus,
        actualHours: rolledUpSummary?.actualHours
      })
    : taskStatus;
  const includeRolledUpCostSummary =
    isPsaPpmCostEacEnhancements2Enabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled;

  const taskCardTitle = useMemo(
    () => (
      <TaskCardTitle2
        name={displayText}
        code={code}
        hasChildren={hasChildren}
        startDate={startDate}
        endDate={endDate}
        taskStatus={status}
      />
    ),
    [displayText, code, hasChildren, startDate, endDate, status]
  );

  return (
    <Card
      className={classes.card}
      style={getCardStyle({ taskLevel: fullPath.length - 1 })}
      square
      elevation={0}
    >
      <CardHeader classes={cardHeaderClasses} title={taskCardTitle} />
      <div>
        <ResourceEstimatesSummary
          resourceEstimatesSummary={resourceEstimatesSummary}
          intl={intl}
          classes={resourceEstimatesClasses}
        />
      </div>
      {isRolledUpTaskEstimateCalculationMethodEnabled && (
        <TaskCardFooter
          canViewEstimate={canViewEstimate}
          canViewCostData={canViewCostData}
          estimatedHours={initialEstimatedHours}
          intl={intl}
          isRTL={isRTL}
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacEnhancements2Enabled={
            isPsaPpmCostEacEnhancements2Enabled
          }
          isPsaRmpTaskAllocation1Enabled
          missingEstimatesInProject={false}
          numberFormat={numberFormat}
          rolledUpSummary={rolledUpSummary}
          rolledUpCostSummary={
            includeRolledUpCostSummary ? rolledUpCostSummary : undefined
          }
          taskStatus={status}
        />
      )}
    </Card>
  );
};

TaskCard2.propTypes = {
  canViewEstimate: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  isRolledUpTaskEstimateCalculationMethodEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  isRTL: PropTypes.bool,
  numberFormat: PropTypes.object,
  task: PropTypes.object
};

export default TaskCard2;
