import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const LongString = ({ classes, hyphenate = true, children, ...rest }) => (
  <span
    className={classNames(classes.root, {
      [classes.hyphenate]: hyphenate
    })}
    {...rest}
  >
    {children}
  </span>
);

LongString.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  hyphenate: PropTypes.bool
};

const withDecorate = withStyles({
  root: {
    overflowWrap: 'break-word'
  },
  hyphenate: {
    '-webkit-hyphens': 'auto',
    '-ms-hyphens': 'auto',
    hyphens: 'auto'
  }
});

export default withDecorate(LongString);
