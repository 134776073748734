import { makeStyles } from '@material-ui/core/styles';
import { ResourceRequestStatus } from '~/types';

export const useResourceBlockStyles = makeStyles(theme => ({
  root: {
    top: `${theme.spacing(5)}px !important`,
    pointerEvents: 'none'
  },
  loadingLabel: {
    opacity: 0
  }
}));

export const useResourceAllocationBlockStyles = makeStyles(theme => ({
  timeItem: {
    display: 'flex',
    height: theme.spacing(3),
    cursor: 'pointer',
    marginTop: theme.spacing(0.5),
    '&:hover': {
      '& .dragIndicator': {
        opacity: 1
      }
    }
  },
  root: {
    top: `${theme.spacing(5)}px !important`,
    height: theme.spacing(2.5)
  },
  resizeHandle: {
    top: theme.spacing(0),
    position: 'absolute',
    zIndex: 1,
    opacity: 0,
    overflow: 'hidden',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  resizeIcon: {
    top: theme.spacing(0.25)
  },
  resizeHandleStart: {
    left: theme.spacing(-0.5)
  },
  resizeHandleEnd: {
    left: `calc(100% - ${theme.spacing(2.5)}px)`
  },
  period: {
    top: theme.spacing(0.5)
  },
  containerDraft: {
    backgroundColor: theme.palette.resourceRequest.complete.dark,
    '& $resizeHandle': {
      backgroundColor: theme.palette.resourceRequest.complete.dark
    },
    '& $resizeIcon': {
      color: theme.palette.getContrastText(
        theme.palette.resourceRequest.complete.dark
      )
    }
  },
  containerComplete: {
    backgroundColor: theme.palette.resourceRequest.complete.dark,
    '& $resizeHandle': {
      backgroundColor: theme.palette.resourceRequest.complete.dark
    },
    '& $resizeIcon': {
      color: theme.palette.getContrastText(
        theme.palette.resourceRequest.complete.dark
      )
    }
  },
  containerRejected: {
    backgroundColor: theme.palette.resourceRequest.pending.main,
    '& $resizeHandle': {
      backgroundColor: theme.palette.resourceRequest.pending.main
    },
    '& $resizeIcon': {
      color: theme.palette.getContrastText(
        theme.palette.resourceRequest.pending.main
      )
    }
  },
  loadingLabel: ({ status }) => ({
    color:
      status === ResourceRequestStatus.Complete
        ? theme.palette.common.white
        : theme.palette.text.primary
  })
}));
