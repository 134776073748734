import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ClientIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 2 2 L 2 22 L 22 22 L 22 6 L 14 6 L 14 2 L 2 2 z M 5 5 L 7 5 L 7 7 L 5 7 L 5 5 z M 9 5 L 11 5 L 11 7 L 9 7 L 9 5 z M 5 9 L 7 9 L 7 11 L 5 11 L 5 9 z M 9 9 L 11 9 L 11 11 L 9 11 L 9 9 z M 14 9 L 19 9 L 19 11 L 14 11 L 14 9 z M 5 13 L 7 13 L 7 15 L 5 15 L 5 13 z M 9 13 L 11 13 L 11 15 L 9 15 L 9 13 z M 14 13 L 19 13 L 19 15 L 14 15 L 14 13 z M 5 17 L 7 17 L 7 19 L 5 19 L 5 17 z M 9 17 L 11 17 L 11 19 L 9 19 L 9 17 z M 14 17 L 19 17 L 19 19 L 14 19 L 14 17 z " />
    </>
  </SvgIcon>
));

export default ClientIcon;
