import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import useGroupLabels from '~/modules/common/components/Groups/hooks/useGroupLabels';
import {
  initialState,
  validationSchema,
  mapFormikValuesToWithForm
} from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestDrawer/hooks/useResourceRequestDrawerForm';

export const useInitialState = ({
  project,
  defaultRequestAttributeWeights,
  baseCurrency
}) => {
  const { requestedCostRate, ...attributeWeights } =
    defaultRequestAttributeWeights || {};

  return useMemo(
    () =>
      initialState({
        project,
        resourceRequest: {
          location: project.location,
          costCenter: project.costCenter,
          division: project.division,
          department: project.department,
          serviceCenter: project.serviceCenter,
          employeeType: project.employeeType,
          currency: project.budgetedCost?.currency || baseCurrency
        },
        defaultRequestAttributeWeights: {
          ...attributeWeights,
          requestedCostRate
        }
      }),
    [project, attributeWeights, baseCurrency, requestedCostRate]
  );
};

export const useAddResourceRequestForm = ({
  project,
  defaultRequestAttributeWeights,
  baseCurrency
}) => {
  const intl = useIntl();

  const initialValues = useInitialState({
    project,
    defaultRequestAttributeWeights,
    baseCurrency
  });

  const groupLabels = useGroupLabels();

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema({ intl, groupLabels }),
    enableReinitialize: true,
    validateOnMount: true
  });

  return useMemo(() => mapFormikValuesToWithForm(formik), [formik]);
};

export default useAddResourceRequestForm;
