import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useBillingOverviewTableContext } from '../../BillingOverviewTableContext';
import CellValueFormatter from './CellValueFormatter';
import RowGroupButton from './RowGroupButton';

const useStyles = makeStyles(theme => ({
  tableCell: {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export const ValueRow = ({
  seriesDataMap,
  rowTitle,
  totalRow = false,
  subRow = false,
  expanded,
  onExpandChange,
  collapsibleHeader,
  dataQeId,
  displayTotalColumn = false,
  useCellBorderClass = false,
  cellClickable = false,
  handleCellClick
}) => {
  const classes = useStyles();

  const cellClickableClasses = useMemo(
    () => ({
      root: cellClickable ? classes.tableCell : ''
    }),
    [cellClickable, classes.tableCell]
  );
  const { periods, overviewClasses } = useBillingOverviewTableContext();

  return (
    <TableRow
      data-qe-id={dataQeId}
      role="row"
      className={classNames(overviewClasses.row, {
        [overviewClasses.totalRow]: totalRow,
        [overviewClasses.subRow]: subRow
      })}
    >
      <TableCell
        component="th"
        scope="row"
        role="rowheader"
        className={classNames(
          { [overviewClasses.cell]: !useCellBorderClass },
          { [overviewClasses.cellWithBorder]: useCellBorderClass },
          overviewClasses.cellLabel
        )}
        data-qe-id={rowTitle}
      >
        {collapsibleHeader ? (
          <RowGroupButton
            expanded={expanded}
            onExpandChange={onExpandChange}
            rowTitle={rowTitle}
          />
        ) : (
          <FormattedMessage id={rowTitle} />
        )}
      </TableCell>
      {periods.map(({ key, isCurrentPeriod, start, end }) => (
        <TableCell
          key={key}
          role="cell"
          align="right"
          className={classNames(
            { [overviewClasses.cell]: !useCellBorderClass },
            { [overviewClasses.cellWithBorder]: useCellBorderClass },
            {
              [overviewClasses.cellCurrent]: isCurrentPeriod
            }
          )}
          classes={cellClickableClasses}
          onClick={cellClickable ? handleCellClick(start, end) : null}
        >
          <CellValueFormatter
            value={seriesDataMap[key]}
            currencySymbol={seriesDataMap.currencySymbol}
          />
        </TableCell>
      ))}

      {displayTotalColumn && (
        <TableCell
          align="right"
          className={classNames(
            { [overviewClasses.cell]: !useCellBorderClass },
            { [overviewClasses.cellWithBorder]: useCellBorderClass },
            overviewClasses.cellTotal
          )}
          data-qe-id="total"
        >
          <CellValueFormatter
            value={seriesDataMap.total}
            currencySymbol={seriesDataMap.currencySymbol}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

ValueRow.propTypes = {
  seriesDataMap: PropTypes.object,
  totalRow: PropTypes.bool,
  subRow: PropTypes.bool,
  rowTitle: PropTypes.string,
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  collapsibleHeader: PropTypes.bool,
  dataQeId: PropTypes.string,
  displayTotalColumn: PropTypes.bool,
  useCellBorderClass: PropTypes.bool,
  cellClickable: PropTypes.bool,
  handleCellClick: PropTypes.func
};

export default ValueRow;
