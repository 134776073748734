import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { PAGINATION_TYPE } from '~/modules/common/components/ListTable';
import { portfolioTag } from '~/modules/common/components/SearchBox/Facets/PortfolioFacet/tag';
import { PROJECT_LIST_PAGE_TYPE } from '~/modules/common/enums/ProjectListPageType';
import { usePortfolioProjectsPermissions } from '~/modules/common/hooks/portfolio';
import { useProjectsColumnSelection } from '~/modules/common/hooks/project';
import { usePageTitle } from '~/modules/common/title';
import { useMeContext } from '~/modules/me/useMeContext';
import { ProjectListTableBase } from '~/modules/projects';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { useGetEnabledTagFieldsQuery } from '~/modules/tags/graphql';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { mobileColumnNames } from '~/modules/common/hooks/project/projectColumns';
import usePortfolioProjectListTableSettings from '../../hooks/usePortfolioProjectListTableSettings';
import { usePortfolioPageEditContext } from '../../PortfolioPageEditContext';
import { usePortfolioProjects, usePortfolioProjectTotals } from './hooks';
import { usePortfolioProjectContext } from './PortfolioProjectsContext';

const useStyles = makeStyles(theme => ({
  bottomHeader: {
    top: theme.spacing(17)
  }
}));

export const ProjectListTable = ({ portfolio = {} }) => {
  const {
    featureFlags: {
      isPSAPercentageBudgetScopeEnabled,
      isPsaPpmPortfolioListActualsColumnsEnabled
    }
  } = useMeContext();

  const isMobile = useIsBreakpointDown('sm');
  const headerClasses = useStyles();
  const {
    name,
    id,
    portfolioManager,
    ancestors,
    portfolioCurrency
  } = portfolio;

  const { searchCriteria, summaryFilterState } = usePortfolioProjectContext();

  usePageTitle([
    { messageId: 'routes.portfolios' },
    { title: name },
    { messageId: 'routes.projects' }
  ]);

  const {
    enabledProjectColumnNames,
    selectedColumnNames
  } = usePortfolioProjectListTableSettings();

  const enabledFields = isPsaPpmPortfolioListActualsColumnsEnabled
    ? selectedColumnNames
    : enabledProjectColumnNames;

  const columnArguments = useProjectsColumnSelection({
    enabledFields: isMobile ? mobileColumnNames : enabledFields
  });

  const tagFieldData = useGetEnabledTagFieldsQuery(enabledFields);

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria: {
      ...searchCriteria,
      criterions: {
        ...(searchCriteria.criterions || {}),
        [portfolioTag]: [{ value: id }]
      }
    },
    quickFilter: summaryFilterState.quickFilter
  });

  const { projects, loading, loadMore, hasMore } = usePortfolioProjects({
    columnArguments,
    isPSAPercentageBudgetScopeEnabled,
    portfolioCurrency,
    projectFilter
  });

  const { totals, totalsLoading } = usePortfolioProjectTotals({
    columnArguments,
    portfolioCurrency,
    projectFilter
  });

  const { edit } = usePortfolioPageEditContext();
  const { canEditPortfolioProjects } = usePortfolioProjectsPermissions({
    portfolioManager,
    ancestors
  });
  const noProjectDataComponentProps = useMemo(
    () => ({
      showClickToAdd: edit && canEditPortfolioProjects
    }),
    [edit, canEditPortfolioProjects]
  );

  const projectListPageType = PROJECT_LIST_PAGE_TYPE.PORTFOLIO;

  return (
    <div id="Portfolio-project-list-container">
      <ProjectListTableBase
        headerClasses={headerClasses}
        projects={projects}
        totals={totals}
        hasMoreProjects={hasMore}
        loadMoreProjects={loadMore}
        projectsLoading={loading || totalsLoading}
        enabledFields={enabledFields}
        tagFieldData={tagFieldData.data}
        disableSort
        useHistoryForRedirect
        noProjectDataComponentProps={noProjectDataComponentProps}
        paginationType={PAGINATION_TYPE.SCROLLABLE}
        projectListPageType={projectListPageType}
      />
    </div>
  );
};

ProjectListTable.propTypes = {
  portfolio: PropTypes.object
};

export default ProjectListTable;
