import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import CalendarIcon from '@material-ui/icons/TodaySharp';
import EmailIcon from '@material-ui/icons/EmailSharp';
import LanguageIcon from '@material-ui/icons/LanguageSharp';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import DateRange from '~/modules/common/components/DateRange';
import {
  EmployeeIdIcon,
  TimezoneIcon
} from '~/modules/common/components/Icons';
import InfoItem from './InfoItem';

export const resourceLabels = {
  noEmployeeId: <FormattedMessage id="resourceBasicInfoCard.noEmployeeId" />,
  employeeId: <FormattedMessage id="resourceBasicInfoCard.employeeId" />,
  noEmail: <FormattedMessage id="resourceBasicInfoCard.noEmail" />,
  email: <FormattedMessage id="resourceBasicInfoCard.email" />,
  startDate: <FormattedMessage id="resourceBasicInfoCard.startDate" />,
  noLanguage: <FormattedMessage id="resourceBasicInfoCard.noLanguage" />,
  language: <FormattedMessage id="resourceBasicInfoCard.language" />,
  noTimeZone: <FormattedMessage id="resourceBasicInfoCard.noTimeZone" />,
  timeZone: <FormattedMessage id="resourceBasicInfoCard.timeZone" />
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  group: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const icons = {
  dateRangeIcon: (
    <Tooltip title={resourceLabels.startDate}>
      <CalendarIcon />
    </Tooltip>
  ),
  languageIcon: (
    <Tooltip title={resourceLabels.language}>
      <LanguageIcon />
    </Tooltip>
  ),
  timeZone: (
    <Tooltip title={resourceLabels.timeZone}>
      <TimezoneIcon />
    </Tooltip>
  ),
  email: (
    <Tooltip title={resourceLabels.email}>
      <EmailIcon />
    </Tooltip>
  ),
  employeeId: (
    <Tooltip title={resourceLabels.employeeId}>
      <EmployeeIdIcon />
    </Tooltip>
  )
};

export const BasicInformationReadOnly = ({
  classes: classesOverride,
  userDetails: {
    email,
    employeeId,
    startDate,
    endDate,
    locale: { language, timeZone }
  },
  showEmail = true,
  permittedActions
}) => {
  const classes = useStyles({ classes: classesOverride });
  const dateRange = useMemo(
    () => <DateRange start={startDate} end={endDate} />,
    [endDate, startDate]
  );

  const emailLink = useMemo(
    () => (
      <Link href={`mailto:${email}`} target="_blank">
        {email}
      </Link>
    ),
    [email]
  );

  return (
    <div className={classes.root}>
      <div className={classes.group}>
        <InfoItem
          dataQeId="EmployeeIdLabel"
          noValue={!employeeId}
          value={employeeId || resourceLabels.noEmployeeId}
          icon={icons.employeeId}
        />
        {showEmail && (
          <InfoItem
            dataQeId="EmailLabel"
            noValue={!email}
            value={email ? emailLink : resourceLabels.noEmail}
            icon={icons.email}
          />
        )}
        {permittedActions.includes(
          'urn:replicon:user-action:view-advanced-settings'
        ) && (
          <InfoItem
            dataQeId="EmployeeDateRangeLabel"
            noValue={!startDate}
            value={dateRange}
            icon={icons.dateRangeIcon}
          />
        )}
      </div>
      <div className={classes.group}>
        <InfoItem
          dataQeId="LanguageLabel"
          noValue={!language.displayText}
          value={
            language.displayText
              ? language.displayText
              : resourceLabels.noLanguage
          }
          icon={icons.languageIcon}
        />
        <InfoItem
          dataQeId="TimeZoneLabel"
          noValue={!timeZone.displayText}
          value={
            timeZone.displayText
              ? timeZone.displayText
              : resourceLabels.noTimeZone
          }
          icon={icons.timeZone}
        />
      </div>
    </div>
  );
};

BasicInformationReadOnly.propTypes = {
  classes: PropTypes.object,
  userDetails: PropTypes.object.isRequired,
  showEmail: PropTypes.bool,
  permittedActions: PropTypes.array.isRequired
};
export default BasicInformationReadOnly;
