import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { getOutstandingQueryParameters } from '~/modules/billing-invoicing/common/components/BillingQuickFilterSummary/hooks/useOutstandingBillTabSummary';
import {
  billingTransacitonSummaryQuery,
  draftQuery,
  outstandingBillSummaryQuery
} from '~/modules/billing-invoicing/common/components/BillingQuickFilterSummary/hooks/totalsQuery';
import { useSearchState } from '~/modules/common/components/SearchBox';
import { useBillingTransactionFilterMemo } from '~/modules/billing-invoicing/common/components/BillingTransactionTab/hooks/useBillingTransactionFilterMemo';
import { getDraftQueryParameters } from '~/modules/billing-invoicing/common/components/BillingQuickFilterSummary/hooks/useDraftTabSummary';
import { getBillingTransactionSummaryQueryParameters } from '~/modules/billing-invoicing/common/components/BillingQuickFilterSummary/hooks/useBillingTransactionSummary';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const getContextFilter = ({
  isGlobalBilling,
  projectUri,
  programUri,
  clientUri
}) => {
  const contextFilter = {};

  if (projectUri) {
    contextFilter.projectUri = projectUri;
  }

  if (programUri) {
    contextFilter.programUri = programUri;
  }

  if (!programUri && !projectUri && !isGlobalBilling) {
    contextFilter.clientUri = clientUri;
  }

  return contextFilter;
};

export const useTabSummary = ({
  isGlobalBilling,
  projectUri,
  programUri,
  clientUri,
  me
}) => {
  const client = useApolloClient();
  const contextFilter = getContextFilter({
    isGlobalBilling,
    projectUri,
    programUri,
    clientUri
  });

  const searchKey = useMemo(() => {
    if (isGlobalBilling) return 'billing-transaction';
    if (projectUri) return projectUri;
    if (programUri) return programUri;
    if (!isGlobalBilling && clientUri) return clientUri;

    return null;
  }, [clientUri, isGlobalBilling, programUri, projectUri]);
  const [searchCriteria] = useSearchState(searchKey);
  const { billingTransactionFilter } = useBillingTransactionFilterMemo({
    searchCriteria,
    me
  });

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const draftParameter = getDraftQueryParameters({
    billingTransactionFilter,
    contextFilter
  });
  const fetchDraftSummary = useCallback(() => {
    client.query({
      query: draftQuery,
      ...draftParameter
    });
  }, [client, draftParameter]);
  const outstandingParameter = getOutstandingQueryParameters({
    billingTransactionFilter,
    contextFilter
  });
  const fetchOutstandingSummary = useCallback(() => {
    client.query({
      query: outstandingBillSummaryQuery,
      ...outstandingParameter
    });
  }, [client, outstandingParameter]);

  const billingSummaryParameter = getBillingTransactionSummaryQueryParameters({
    billingTransactionFilter,
    contextFilter
  });

  const fetchBillingTransactionSummary = useCallback(() => {
    client.query({
      query: billingTransacitonSummaryQuery,
      ...billingSummaryParameter
    });
  }, [client, billingSummaryParameter]);

  const refetchFilterSummary = useCallback(() => {
    if (isPsaFpGraphqlOptimizationEnabled) {
      fetchBillingTransactionSummary();
    } else {
      fetchDraftSummary();
      fetchOutstandingSummary();
    }
  }, [
    fetchBillingTransactionSummary,
    fetchDraftSummary,
    fetchOutstandingSummary,
    isPsaFpGraphqlOptimizationEnabled
  ]);

  return {
    fetchDraftSummary,
    fetchOutstandingSummary,
    fetchBillingTransactionSummary,
    refetchFilterSummary
  };
};
