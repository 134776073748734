import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isInChartDisplayDateRange } from '~/modules/resourcing/common/hooks/useClippingDateRangeUtil';
import HolidaySummaryBlock from './HolidaySummaryBlock';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1
  },
  container: { opacity: 0.6 }
}));

export const HolidaySummaryBlocks = ({
  holidaySummaryPeriods,
  holidays,
  chartDisplayDateRange,
  scale,
  chartStartDate,
  showTooltip = true,
  clickable = false,
  allocationsCountInRequest,
  rowIndex,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const filteredHolidaySummaryPeriods = holidaySummaryPeriods.filter(entry =>
    isInChartDisplayDateRange({
      chartDisplayDateRange,
      entityDateRange: { startDate: entry.startDate, endDate: entry.endDate }
    })
  );

  return filteredHolidaySummaryPeriods.map(holidaySummaryPeriod => (
    <HolidaySummaryBlock
      key={holidaySummaryPeriod.startDate.toISODate()}
      scale={scale}
      chartStartDate={chartStartDate}
      holidaySummaryPeriod={holidaySummaryPeriod}
      holidays={holidays}
      showTooltip={showTooltip}
      clickable={clickable}
      allocationsCountInRequest={allocationsCountInRequest}
      rowIndex={rowIndex}
      classes={classes}
    />
  ));
};

HolidaySummaryBlocks.propTypes = {
  holidaySummaryPeriods: PropTypes.array.isRequired,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  showTooltip: PropTypes.bool,
  clickable: PropTypes.bool,
  holidays: PropTypes.array
};

export default HolidaySummaryBlocks;
