import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import User from '~/modules/common/components/User';

const useListItemStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

export const ClientItem = ({ option }) => {
  const listItemClasses = useListItemStyles();

  return (
    <ListItem
      component="div"
      classes={listItemClasses}
      data-qe-id={`${option.label}_UserItem`}
    >
      <User user={option} disablePadding />
    </ListItem>
  );
};

ClientItem.propTypes = {
  option: PropTypes.object
};

export default ClientItem;
