import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import ChartDateRangePeriod from '~/modules/common/charts/dashboard/ChartDateRangePeriod';
import BillingOverviewPeriodDropdown from './components/BillingOverviewPeriodDropdown';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 2),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flex: '0 0',
    position: 'sticky',
    left: 0,
    zIndex: 10,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  scaleSelect: {
    margin: theme.spacing(0, 0, 0, 2)
  }
}));

export const BillingOverviewToolbar = ({
  scale,
  onPrevious,
  onNext,
  setDate,
  setScale,
  dateRange
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ChartDateRangePeriod
        onPreviousClick={onPrevious}
        onNextClick={onNext}
        scale={scale}
        dateRange={dateRange}
        setDateRange={setDate}
        setDateValue
        scaleFormat
      />
      <BillingOverviewPeriodDropdown scale={scale} setScale={setScale} />
    </div>
  );
};

BillingOverviewToolbar.propTypes = {
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  dateRange: PropTypes.object,
  scale: PropTypes.string,
  setScale: PropTypes.func,
  setDate: PropTypes.func
};

export default BillingOverviewToolbar;
