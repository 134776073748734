import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECTS_FOR_BILLING_QUERY = gql`
  query GetPageOfProjectsToFilterBillingItems(
    $page: Int!
    $pageSize: Int!
    $searchTerm: String
    $clientUri: String
  ) {
    getPageOfProjectsAvailableForFilteringBillingItems(
      page: $page
      pageSize: $pageSize
      searchTerm: $searchTerm
      clientUri: $clientUri
    ) {
      id
      displayText
    }
  }
`;

export const useProjectsForBillingItems = ({
  searchTerm,
  clientUri = null
}) => {
  const { data, loading } = useQuery(GET_PROJECTS_FOR_BILLING_QUERY, {
    fetchPolicy: 'cache-and-network',
    context: {
      debounceKey: 'project-search-for-billing',
      debounceTimeout: 250
    },
    variables: {
      page: 1,
      pageSize: 100,
      searchTerm,
      clientUri
    }
  });

  const projects = !loading
    ? data?.getPageOfProjectsAvailableForFilteringBillingItems?.map(
        project => ({
          ...project,
          value: project.id,
          label: project.displayText,
          key: project.displayText
        })
      )
    : [];

  return {
    projects,
    loading
  };
};

export const fetchProjects = apolloClient => async searchTerm => {
  const result = await apolloClient.query({
    query: GET_PROJECTS_FOR_BILLING_QUERY,
    variables: {
      page: 1,
      pageSize: 100,
      searchTerm
    }
  });

  return result.data.getPageOfProjectsAvailableForFilteringBillingItems.map(
    project => ({
      ...project,
      value: project.id,
      label: project.displayText,
      name: project.displayText,
      key: project.displayText
    })
  );
};

export const useSearchableProjects = ({}) => ({
  fetchOptions: fetchProjects(useApolloClient())
});
