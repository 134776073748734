import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import UserAvatar from '../Avatars/UserAvatar';
import LabelledIcon from '../LabelledIcon';
import { userToUserRef } from './common';

const useStyles = makeStyles(theme => ({
  avatar: {},
  name: {},
  label: {},
  container: {
    overflow: 'hidden'
  }
}));

export const User = ({
  user,
  dataQeId,
  size = 'xsmall',
  disablePadding,
  classes,
  showTooltip,
  disabled,
  tabIndex
}) => {
  const userClasses = useStyles({ classes });
  const userRef = userToUserRef(user);
  const avatar = useMemo(
    () => (
      <UserAvatar
        user={user}
        size={size}
        disabled={Boolean(disabled)}
        className={userClasses.avatar}
      />
    ),
    [userClasses.avatar, size, user, disabled]
  );

  return (
    <LabelledIcon
      icon={avatar}
      label={userRef.name}
      className={userClasses.name}
      classes={userClasses}
      dataQeId={dataQeId}
      disablePadding={disablePadding}
      showTooltip={showTooltip}
      tabIndex={tabIndex}
    />
  );
};

User.propTypes = {
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  user: PropTypes.object.isRequired,
  size: PropTypes.string,
  disablePadding: PropTypes.bool,
  showTooltip: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number
};

export default User;
