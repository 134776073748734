import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { makeStyles, Checkbox, Typography } from '@material-ui/core';
import { equalDateTimes } from '~/modules/common/dates/compare';

const useStyles = makeStyles(theme => ({
  alertContainer: {
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(1)
  },
  alert: {
    marginBottom: '0px !important'
  },
  alertTitle: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(-5.5)
  },
  root: {
    padding: theme.spacing(3, 1, 0, 1)
  }
}));

export const getDateRangeComparisonResult = ({
  existingTaskDateRange,
  newTaskDateRange
}) => {
  const {
    startDate: existingStartDate,
    endDate: existingEndDate
  } = existingTaskDateRange;
  const { startDate: newStartDate, endDate: newEndDate } = newTaskDateRange;

  const isSameStartDate = equalDateTimes(existingStartDate, newStartDate);
  const isSameEndDate = equalDateTimes(existingEndDate, newEndDate);

  const isSameDates = isSameStartDate && isSameEndDate;

  const daysOffset =
    existingStartDate && newStartDate
      ? newStartDate.diff(existingStartDate, 'days').days
      : 0;

  return { isSameDates, isSameStartDate, isSameEndDate, daysOffset };
};

export const getRescheduleMessageDetails = ({
  isSameDates,
  isSameStartDate,
  isSameEndDate,
  daysOffset,
  existingTaskDateRange,
  newTaskDateRange
}) => {
  if (isSameDates) {
    return { messageId: null, values: {} };
  }

  const noStartOrEndDate =
    !existingTaskDateRange?.startDate || !existingTaskDateRange?.endDate;
  const noNewStartOrEndDate =
    !newTaskDateRange?.startDate || !newTaskDateRange?.endDate;

  if (
    !isSameStartDate &&
    existingTaskDateRange?.startDate &&
    newTaskDateRange?.startDate
  ) {
    const id =
      daysOffset > 0
        ? 'rescheduleTaskDialog.daysLater'
        : 'rescheduleTaskDialog.earlierDays';

    return {
      messageId: id,
      values: { count: Math.abs(daysOffset) }
    };
  }

  if (noStartOrEndDate || noNewStartOrEndDate || !isSameEndDate) {
    return {
      messageId: 'rescheduleTaskDialog.adjustTaskDates',
      values: {}
    };
  }

  return { messageId: null, values: {} };
};

const ConfirmationArea = ({
  isRemoveAllocationsChecked,
  handleRemoveAllocationCheckBoxClick,
  hasTaskAllocations,
  loading,
  existingTaskDateRange,
  newTaskDateRange
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const inputProps = useMemo(
    () => ({
      'aria-label': formatMessage({
        id: 'rescheduleTaskDialog.removeTaskAllocationAriaLabel'
      })
    }),
    [formatMessage]
  );

  if (loading) {
    return (
      <div className={classes.alertContainer}>
        <Skeleton variant="rect" height={30} />
      </div>
    );
  }

  const {
    isSameDates,
    isSameStartDate,
    isSameEndDate,
    daysOffset
  } = getDateRangeComparisonResult({
    existingTaskDateRange,
    newTaskDateRange
  });

  const { messageId, values } = getRescheduleMessageDetails({
    isSameDates,
    isSameStartDate,
    isSameEndDate,
    daysOffset,
    existingTaskDateRange,
    newTaskDateRange
  });

  return (
    <div className={classes.root}>
      {messageId && (
        <Typography variant="body1">
          <FormattedMessage id={messageId} values={values} />
        </Typography>
      )}
      {hasTaskAllocations && (
        <Alert
          severity="warning"
          icon={false}
          className={classes.alertContainer}
        >
          <Checkbox
            checked={isRemoveAllocationsChecked}
            onChange={handleRemoveAllocationCheckBoxClick}
            inputProps={inputProps}
          />
          <div className={classes.alertTitle}>
            <AlertTitle className={classes.alert}>
              <FormattedMessage id="rescheduleTaskDialog.removeTaskAllocationConfirmation" />
            </AlertTitle>
            <FormattedMessage id="rescheduleTaskDialog.removeTaskAllocationConfirmationDescription" />
          </div>
        </Alert>
      )}
    </div>
  );
};

ConfirmationArea.propTypes = {
  isRemoveAllocationsChecked: PropTypes.bool,
  handleRemoveAllocationCheckBoxClick: PropTypes.func,
  hasTaskAllocations: PropTypes.bool,
  loading: PropTypes.bool,
  existingTaskDateRange: PropTypes.object,
  newTaskDateRange: PropTypes.object
};

export default ConfirmationArea;
