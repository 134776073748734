import { withCustomFetchCriteriaProvider } from '../enhancers';
import ProjectSuggestionItem from '../GenericProjectFacetDetails/ProjectSuggestionItem';
import { projectTag } from '../ProjectFacet/tag';
import { useSearchableProjectOptionsForRevenueItems } from './useProjectOptionsForRevenueItems';
import RevenueProjectFacetDetails from './RevenueProjectFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(
    tag,
    context,
    useSearchableProjectOptionsForRevenueItems
  );

export default {
  tag: projectTag,
  makeCriteriaProvider,
  DetailsControl: RevenueProjectFacetDetails,
  SuggestionItem: ProjectSuggestionItem
};
