import { useState, useCallback } from 'react';

export const useEditableState = (initialState = false) => {
  const [editable, setEditable] = useState(initialState);
  const toggleEditable = useCallback(() => setEditable(!editable), [
    setEditable,
    editable
  ]);

  return {
    editable,
    setEditable,
    toggleEditable
  };
};

export default useEditableState;
