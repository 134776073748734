import PropTypes from 'prop-types';
import React from 'react';
import SearchToolbar from '../SearchToolbar';
import AvailableToBillSearchContext from './AvailableToBillSearchContext';

const Toolbar = ({ searchFacets }) => (
  <AvailableToBillSearchContext.Consumer>
    {ctx => <SearchToolbar searchFacets={searchFacets} {...ctx} />}
  </AvailableToBillSearchContext.Consumer>
);

Toolbar.propTypes = {
  searchFacets: PropTypes.array
};

export default Toolbar;
