import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import { GET_TAG_ID, useTagId } from './useTagId';

export const GET_SYNC_STATUS_TAG_QUERY = gql`
  query getSyncStatusTags($id: String!) {
    tag(id: $id) {
      id
      values {
        id
        value
      }
    }
  }
`;

const getNoneItem = (displayText, id) => ({
  id: NONE_OPTION_KEY,
  tagKeyUri: id,
  displayText,
  value: 'none',
  label: displayText,
  key: NONE_OPTION_KEY
});

const getSyncStatusTags = ({
  intl,
  noneOptionText = '',
  tagId,
  tagValues = [],
  includeNoneOption = false
}) => {
  const tags = tagValues.map(tag => ({
    ...tag,
    tagKeyUri: tagId,
    displayText: intl.formatMessage({
      id: `billingTransactionList.${tag.value}`,
      defaultMessage: tag.value
    }),
    value: tag.id,
    label: intl.formatMessage({
      id: `billingTransactionList.${tag.value}`,
      defaultMessage: tag.value
    }),
    key: intl.formatMessage({
      id: `billingTransactionList.${tag.value}`,
      defaultMessage: tag.value
    })
  }));

  return includeNoneOption
    ? [getNoneItem(noneOptionText, tagId), ...tags]
    : tags;
};

export const useSyncStatusTags = ({
  includeNoneOption,
  noneOptionText = ''
}) => {
  const intl = useIntl();
  const { tagId } = useTagId({ slug: 'sync-status' });
  const { loading, data } = useQuery(GET_SYNC_STATUS_TAG_QUERY, {
    skip: !tagId,
    variables: {
      id: tagId
    },
    fetchPolicy: 'cache-and-network'
  });
  const statusTag = get(data, 'tag', {});

  const syncStatusTags = getSyncStatusTags({
    intl,
    noneOptionText,
    tagId: statusTag.id,
    tagValues: statusTag.values,
    includeNoneOption
  });

  return {
    syncStatusTags,
    loading
  };
};

export const searchSyncStatusTags = ({ client, intl }) => async (
  searchTerm = ''
) => {
  const { data: tagData } = await client.query({
    query: GET_TAG_ID,
    variables: {
      slug: 'sync-status'
    }
  });

  const tagId = get(tagData, 'tagId', null);
  const { data } = await client.query({
    query: GET_SYNC_STATUS_TAG_QUERY,
    skip: !tagId,
    variables: {
      id: tagId
    }
  });

  const statusTag = get(data, 'tag', {});
  const syncStatusTags = getSyncStatusTags({
    intl,
    noneOptionText: intl.formatMessage({ id: 'syncStatus.none' }),
    tagId: statusTag.id,
    tagValues: statusTag.values,
    includeNoneOption: true
  });

  return syncStatusTags.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export const useSearchableSyncStatusTags = () => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  return {
    fetchOptions: searchSyncStatusTags({
      intl,
      client: apolloClient
    })
  };
};

export default useSyncStatusTags;
