import { makeStyles } from '@material-ui/styles';

import { getResourceRequestStatusStyles } from '~/modules/resourcing/common/util/status-color-util';

const useStyles = makeStyles(theme => ({
  root: {},
  ...getResourceRequestStatusStyles(theme),
  container: {}
}));

export default useStyles;
