import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { AbbreviatedDecimalHideNull } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.43
  },
  label: {
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.43
  },
  estimatedItem: {
    color: theme.palette.grey[600]
  },
  actualsItem: {
    color: props => props.actualBarColor
  },
  container: { paddingBottom: theme.spacing(3) }
}));

export const BudgetCostChartLegend = ({
  estimatedLabel,
  actualLabel,
  estimatedValue,
  actualValue,
  estimatedPrefix,
  actualPrefix,
  actualBarColor,
  dataQeId
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={6} sm={6} lg={6} className={classes.item}>
        <Typography
          className={classNames(classes.label, classes.estimatedItem)}
          variant="body2"
        >
          {estimatedLabel}
        </Typography>
        <Typography
          className={classNames(classes.value, classes.estimatedItem)}
          variant="subtitle1"
        >
          <AbbreviatedDecimalHideNull
            value={estimatedValue}
            prefix={estimatedPrefix}
            dataQeId={`${dataQeId}_EstimatedTooltip`}
          />
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} lg={6} className={classes.item}>
        <Typography
          className={classNames(classes.label, classes.actualsItem)}
          variant="body2"
        >
          {actualLabel}
        </Typography>
        <Typography
          className={classNames(classes.value, classes.actualsItem)}
          variant="subtitle1"
        >
          <AbbreviatedDecimalHideNull
            value={actualValue}
            prefix={actualPrefix}
            dataQeId={`${dataQeId}_ActualTooltip`}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

BudgetCostChartLegend.propTypes = {
  actualBarColor: PropTypes.string.isRequired,
  estimatedLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  actualLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  estimatedValue: PropTypes.number,
  actualValue: PropTypes.number,
  estimatedPrefix: PropTypes.string,
  actualPrefix: PropTypes.string,
  dataQeId: PropTypes.string.isRequired
};

export default BudgetCostChartLegend;
