import { useCallback } from 'react';
import { v4 } from 'uuid';
import { getTodayForUser } from '~/modules/common/dates/today';

export const useBillingRateEntryHandler = ({
  setOpenNotificationDialog,
  setRemoveErrorDetails,
  onConfirmRemoveEntryRow,
  onDeleteBillingRate,
  billingRateIndex,
  billingRate,
  setFieldValue,
  me,
  id
}) => {
  const onRemoveScheduleEntryRow = useCallback(
    entryId => {
      setFieldValue(
        `billingRates[${billingRateIndex}].billingRate.billingScheduleEntries`,
        billingRate.billingScheduleEntries.filter(x => x.id !== entryId)
      );
      setFieldValue(`billingRates[${billingRateIndex}].dirty`, true);
    },
    [billingRate.billingScheduleEntries, billingRateIndex, setFieldValue]
  );
  const onAddScheduleEntryRow = useCallback(() => {
    const newEntry = {
      id: v4(),
      effectiveDate: getTodayForUser(me),
      rate: {
        amount: 0,
        currency: me.baseCurrency
      }
    };

    setFieldValue(
      `billingRates[${billingRateIndex}].billingRate.billingScheduleEntries`,
      [newEntry, ...billingRate.billingScheduleEntries]
    );
    setFieldValue(`billingRates[${billingRateIndex}].dirty`, true);
  }, [billingRate.billingScheduleEntries, billingRateIndex, me, setFieldValue]);

  const onBillingRateChange = useCallback(
    newEntry => {
      const newBillingRate = {
        id: newEntry.id,
        billingScheduleEntries: [
          {
            id: v4(),
            effectiveDate: null,
            rate: newEntry.rate
          }
        ]
      };

      setFieldValue(
        `billingRates[${billingRateIndex}].billingRate`,
        newBillingRate
      );
      setFieldValue(`billingRates[${billingRateIndex}].dirty`, true);
    },
    [billingRateIndex, setFieldValue]
  );

  const onDeleteBillingRateRow = useCallback(
    () =>
      onDeleteBillingRate({
        id,
        billingRate,
        setOpenNotificationDialog,
        setRemoveErrorDetails
      }),
    [
      billingRate,
      id,
      onDeleteBillingRate,
      setOpenNotificationDialog,
      setRemoveErrorDetails
    ]
  );

  const onConfirmDeleteEntryRow = useCallback(() => {
    onConfirmRemoveEntryRow(id);
    setOpenNotificationDialog(false);
  }, [id, onConfirmRemoveEntryRow, setOpenNotificationDialog]);

  const onCloseNotificationDialog = useCallback(
    () => setOpenNotificationDialog(false),
    [setOpenNotificationDialog]
  );

  return {
    onRemoveScheduleEntryRow,
    onAddScheduleEntryRow,
    onBillingRateChange,
    onDeleteBillingRateRow,
    onConfirmDeleteEntryRow,
    onCloseNotificationDialog
  };
};

export default useBillingRateEntryHandler;
