import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(5.5),
    bottom: 0,
    width: 1,
    height: ({ footerHeight = 0 }) =>
      `calc(100% - ${theme.spacing(5.5)}px - ${footerHeight}px)`,
    marginLeft: -1,
    userSelect: 'none',
    backgroundColor: theme.palette.secondary.main,
    overflow: 'visible',
    zIndex: 100,
    opacity: 0.6,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    '&:hover': {
      opacity: 1
    },
    '&:hover $bubble': {
      borderRadius: theme.spacing(1.5),
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.getContrastText(theme.palette.secondary.main)
    }
  },
  bubble: {
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(3)}px`,
    position: 'absolute',
    pointerEvents: 'auto',
    top: 0.5,
    left: 0.5,
    transform: 'translate(-50%,-100%)',
    zIndex: 10,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    textAlign: 'center',
    overflow: 'hidden',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  }
}));

export default useStyles;
