import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { isValidValueOrNull as isValidGroupOrNull } from '../../validator';
import { UPDATE_PROJECT_GROUPS_MUTATION, optimisticResponse } from './graphql';

const mapValuesOnSubmit = values => ({
  projectUri: values.projectUri,
  costCenter: isValidGroupOrNull(values.costCenter),
  department: isValidGroupOrNull(values.department),
  division: isValidGroupOrNull(values.division),
  employeeType: isValidGroupOrNull(values.employeeType),
  location: isValidGroupOrNull(values.location),
  serviceCenter: isValidGroupOrNull(values.serviceCenter)
});

export const onSubmit = ({ closeDialog, updateProject }) => (
  values,
  { setSubmitting }
) => {
  const payload = mapValuesOnSubmit(values);

  updateProject({
    variables: {
      projectInput: payload
    },
    optimisticResponse: optimisticResponse(payload)
  });

  closeDialog();
  setSubmitting(false);
};

export const useUpdateProjectGroups = ({ closeDialog }) => {
  const [updateProject] = useMutation(UPDATE_PROJECT_GROUPS_MUTATION);

  return useCallback(onSubmit({ closeDialog, updateProject }), [
    closeDialog,
    updateProject
  ]);
};
