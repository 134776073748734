import React from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(-1.5)
  }
}));

export const BooleanFormatter = ({ record, field }) => {
  const classes = useStyles();

  return <Checkbox className={classes.root} disabled checked={record[field]} />;
};

BooleanFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default BooleanFormatter;
