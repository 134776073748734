import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core';
import { useIsBreakpointOnly } from '~/modules/common/hooks';
import {
  CHILD_INFO_DIALOG_COLUMN_TYPE,
  TASK_ESTIMATE_CALCULATION_TYPES
} from '~/modules/common/enums';
import {
  objectToFormatString,
  isoStringToFormattedISOString,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me';
import useTooltipConditions from '~/modules/common/components/TaskStatusSection/Hooks/useTooltipConditions';
import useAlternateTooltips from '~/modules/common/components/TaskStatusSection/Hooks/useAlternateTooltips';
import {
  useAlternateHoursTooltips,
  hasRowMarginCondition
} from '~/modules/common/components/TaskStatusSection/Hooks/useAlternateHoursTooltips';
import useStyles from '../useStyles';
import {
  TaskSummaryRow,
  TaskSummaryHeader,
  TaskVariationRow,
  EstimatedAtCompletionRow
} from '../Table';
import { useTaskSummaryLabels } from '../useTaskSummaryLabels';

const useSummaryRowStyle = makeStyles(theme => ({
  labelCell: ({
    isXsBreakPoint,
    isMobile,
    hasRowPaddingBottom,
    hasRowPaddingTop
  }) => ({
    fontSize: isXsBreakPoint && theme.spacing(1.5),
    padding: isMobile
      ? theme.spacing(1, 0, 0, 2)
      : hasRowPaddingBottom
      ? theme.spacing(1, 1, 2, 2)
      : hasRowPaddingTop && theme.spacing(2, 1, 1, 2)
  }),
  hoursCell: ({ isBolded, isMobile, isHourTooltipVisible }) => ({
    padding: isMobile && '0 6vw',
    fontWeight: isBolded
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight,
    marginRight:
      isMobile && isHourTooltipVisible
        ? theme.spacing(3.25)
        : isHourTooltipVisible
        ? theme.spacing(3.75)
        : 0
  }),
  costCell: ({ isBolded, isCostTooltipVisible }) => ({
    fontWeight: isBolded
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight,
    ...(isCostTooltipVisible && { marginRight: theme.spacing(3.75) })
  }),
  icon: ({ isXsBreakPoint }) => ({
    marginLeft: isXsBreakPoint && theme.spacing(0.5)
  }),
  datesCell: ({ isBolded, isMobile }) => ({
    paddingLeft: isMobile && theme.spacing(1),
    fontWeight: isBolded
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight
  })
}));

const headerStyles = makeStyles(theme => ({
  taskSummaryColumn: {
    marginRight: theme.spacing(0)
  },
  cell: {
    padding: theme.spacing(0.75, 1)
  },
  hoursColumn: {
    textAlign: 'right',
    justifySelf: 'end',
    padding: '0 6vw'
  },
  datesColumn: {
    paddingLeft: theme.spacing(2)
  }
}));

const useVariantStyles = makeStyles(theme => ({
  cell: {
    marginLeft: 0
  },
  labelCell: ({ isXsBreakPoint }) => ({
    fontSize: theme.spacing(isXsBreakPoint ? 1.5 : 1.75),
    marginTop: theme.spacing(0),
    padding: theme.spacing(1, 0, 0, 2)
  }),
  datesCell: ({ isXsBreakPoint }) => ({
    alignSelf: 'self-end',
    paddingLeft: theme.spacing(isXsBreakPoint ? 1 : 2)
  }),
  hoursCell: ({ isXsBreakPoint }) => ({
    paddingLeft: theme.spacing(isXsBreakPoint ? 1 : 2)
  }),
  pillsContainer: ({ isXsBreakPoint }) => ({
    display: 'flex',
    width: isXsBreakPoint && '100vw',
    justifyContent: 'space-around;'
  }),
  row: ({ isXsBreakPoint }) => ({
    display: isXsBreakPoint ? 'flex' : 'contents',
    flexDirection: 'column'
  })
}));

export const RollupStatusSection = ({
  source,
  variant = 'task',
  hasMore,
  loadMore,
  loadingMore,
  estimatedCompletionDate
}) => {
  const me = useMeContext();
  const {
    isExpenseProductEnabled,
    featureFlags: {
      PSAPRPTaskEstimateCalculation,
      isPsaPpmEstimatedCostAtCompletionEnabled,
      isPsaPrpProductPackagingEnabled
    }
  } = me;
  const isPSAPRPTaskEstimateCalculationMethodEnabled =
    PSAPRPTaskEstimateCalculation !== TASK_ESTIMATE_CALCULATION_TYPES.OFF;
  const isProjectVariant = variant === 'project';
  const intl = useIntl();
  const isXsBreakPoint = useIsBreakpointOnly('xs');
  const isMobile = useMediaQuery('(max-width:690px)', { noSsr: true });

  const labels = useTaskSummaryLabels({
    isProjectVariant,
    isPSAPRPTaskEstimateCalculationMethodEnabled
  });
  const {
    initialEstimatedHours,
    rolledUpSummary,
    rolledUpCostSummary,
    endDate,
    children,
    estimatedHours,
    estimatedCost,
    isClosed
  } = source;

  const projectPermittedActionUris =
    source.project?.permittedActionUris || source.permittedActionUris || [];

  const showCost =
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    projectPermittedActionUris.includes(
      'urn:replicon:project-action:view-cost-data'
    );

  const {
    hasAlternateExpenseRemainingValue,
    hasAlternateEstimatedAtCompletionCostValue,
    hasAlternateWorkRemainingValue,
    expenseRemainingAlternateTooltipComponent,
    estimatedAtCompletionAlternateTooltipComponent,
    workRemainingAlternateTooltipComponent
  } = useAlternateTooltips({
    isClosed,
    isProjectVariant,
    estimatedCost,
    rolledUpCostSummary
  });

  const hasChildTasks = children.length > 0;

  const {
    estimatedAtCompletionHoursRowTooltipCondition,
    workRemainingHoursRowTooltipCondition,
    atleastOneHoursRowHasTooltip,
    hideVariationRowHoursTooltip,
    hideEstimatedAtCompletionRowHoursTooltip,
    estimatedAtCompletionAlternateHoursTooltipComponent,
    workRemainingAlternateHoursTooltipComponent
  } = useAlternateHoursTooltips({
    isProjectVariant,
    hasChildTasks,
    estimatedHours,
    rolledUpSummary,
    isClosed
  });

  const classes = useStyles({ isMobile, showCost });
  const {
    atleastOneRowHasTooltip,
    actualsRowMarginCondition,
    workRemainingRowMarginCondition,
    estimatedAtCompletionRowMarginCondition,
    hideEstimatedAtCompletionRowTooltip,
    hideVariationRowTooltip,
    initialEstimatedRowMarginCondition,
    totalExpensesRowMarginCondition,
    expenseRemainigRowMarginCondition,
    variationRowMarginCondition
  } = useTooltipConditions({
    source,
    hasChildTasks,
    hasAlternateEstimatedAtCompletionCostValue,
    hasAlternateExpenseRemainingValue,
    hasAlternateWorkRemainingValue,
    isProjectVariant
  });

  const actualsClassOverride = useSummaryRowStyle({
    isBolded: rolledUpSummary?.actualHours > 0,
    isXsBreakPoint,
    isMobile,
    isHourTooltipVisible: hasRowMarginCondition(
      atleastOneHoursRowHasTooltip,
      hasChildTasks
    ),
    isCostTooltipVisible: actualsRowMarginCondition
  });
  const estimatedAtCompletionClassOverride = useSummaryRowStyle({
    hasRowPaddingTop: true,
    isXsBreakPoint,
    isMobile,
    isHourTooltipVisible: hasRowMarginCondition(
      atleastOneHoursRowHasTooltip,
      estimatedAtCompletionHoursRowTooltipCondition
    ),
    isCostTooltipVisible: estimatedAtCompletionRowMarginCondition
  });
  const labelCellClassOverride = useSummaryRowStyle({
    isBolded: false,
    isXsBreakPoint,
    isMobile,
    isHourTooltipVisible: hasRowMarginCondition(
      atleastOneHoursRowHasTooltip,
      workRemainingHoursRowTooltipCondition
    ),
    isCostTooltipVisible: workRemainingRowMarginCondition,
    hasRowPaddingBottom: !isProjectVariant
  });
  const initialEstimatedClassOverride = useSummaryRowStyle({
    isMobile,
    isHourTooltipVisible: atleastOneHoursRowHasTooltip,
    isCostTooltipVisible: initialEstimatedRowMarginCondition,
    hasRowPaddingBottom: true
  });
  const totalExpensesClassOverride = useSummaryRowStyle({
    isBolded: rolledUpCostSummary?.totalExpensesInProjectCurrency?.amount > 0,
    isCostTooltipVisible: totalExpensesRowMarginCondition,
    hasRowPaddingBottom: false
  });
  const expensesRemainingClassOverride = useSummaryRowStyle({
    isCostTooltipVisible: expenseRemainigRowMarginCondition,
    hasRowPaddingBottom: isProjectVariant
  });
  const variantStyles = useVariantStyles({
    isXsBreakPoint
  });
  const headerOverride = headerStyles();
  const dateFormat = getLuxonJsDateFormatFromMe(me);

  const checkForExpenseProduct = isPsaPrpProductPackagingEnabled
    ? isExpenseProductEnabled
    : true;

  const actualDate = isoStringToFormattedISOString(
    rolledUpSummary?.earliestTimeEntryDate,
    dateFormat
  );

  return (
    <div
      className={classNames(classes.table2, classes.wrapper)}
      role="table"
      aria-label={intl.formatMessage({ id: `${labels.tableHeader}` })}
    >
      <TaskSummaryHeader
        classes={isMobile ? headerOverride : null}
        variant={variant}
        isHourTooltipVisible={
          atleastOneHoursRowHasTooltip &&
          isPSAPRPTaskEstimateCalculationMethodEnabled
        }
        isCostTooltipVisible={atleastOneRowHasTooltip}
        showCost={showCost}
      />
      <TaskSummaryRow
        dataQeId="Actual_Hours_Row"
        classes={actualsClassOverride}
        label={labels.actuals}
        selectedColumns={[
          CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
          CHILD_INFO_DIALOG_COLUMN_TYPE.ACTUAL_HOURS
        ]}
        selectedCostColumns={[
          CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
          CHILD_INFO_DIALOG_COLUMN_TYPE.ACTUAL_COST
        ]}
        hours={rolledUpSummary?.actualHours}
        cost={rolledUpCostSummary?.actualCostInProjectCurrency}
        date={actualDate}
        noDateLabel={labels.noDate}
        childTasks={children}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        showCost={showCost}
      />
      <TaskSummaryRow
        dataQeId="Work_Remaining_Row"
        classes={labelCellClassOverride}
        label={labels.workRemaining}
        selectedColumns={[
          CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
          CHILD_INFO_DIALOG_COLUMN_TYPE.WORK_REMAINING
        ]}
        selectedCostColumns={[
          CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
          CHILD_INFO_DIALOG_COLUMN_TYPE.COST_REMAINING
        ]}
        hours={rolledUpSummary?.totalEstimatedRemainingHours}
        cost={rolledUpCostSummary?.totalEstimatedRemainingCostInProjectCurrency}
        hasDivider={!isProjectVariant}
        childTasks={children}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        showCost={showCost}
        costTooltipComponent={workRemainingAlternateTooltipComponent}
        hoursTooltipComponent={workRemainingAlternateHoursTooltipComponent}
      />
      {showCost && isProjectVariant && checkForExpenseProduct && (
        <>
          <TaskSummaryRow
            dataQeId="Total_Expenses_Row"
            classes={totalExpensesClassOverride}
            label={labels.totalExpenses}
            cost={rolledUpCostSummary?.totalExpensesInProjectCurrency}
            showCost={showCost}
            showHours={false}
          />
          <TaskSummaryRow
            dataQeId="Expenses_Remaining_Row"
            classes={expensesRemainingClassOverride}
            label={labels.expensesRemaining}
            cost={rolledUpCostSummary?.expensesRemainingInProjectCurrency}
            hasDivider
            showCost={showCost}
            showHours={false}
            selectedCostColumns={[
              CHILD_INFO_DIALOG_COLUMN_TYPE.MONTH,
              CHILD_INFO_DIALOG_COLUMN_TYPE.ESTIMATED_EXPENSES
            ]}
            childTasks={source.projectEstimatedExpenseRemainingSeriesByMonth}
            costTooltipComponent={expenseRemainingAlternateTooltipComponent}
          />
        </>
      )}
      <EstimatedAtCompletionRow
        isProjectVariant={isProjectVariant}
        label={labels.estimatedAtCompletion}
        classes={estimatedAtCompletionClassOverride}
        rolledUpCostSummary={rolledUpCostSummary}
        rolledUpHoursSummary={rolledUpSummary}
        date={estimatedCompletionDate}
        noDateLabel={labels.na}
        childTasks={children}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        showCost={showCost}
        estimatedCost={estimatedCost}
        estimatedAtCompletionAlternateTooltipComponent={
          estimatedAtCompletionAlternateTooltipComponent
        }
        estimatedAtCompletionAlternateHoursTooltipComponent={
          estimatedAtCompletionAlternateHoursTooltipComponent
        }
        hideCostTooltip={hideEstimatedAtCompletionRowTooltip}
        hideHoursTooltip={hideEstimatedAtCompletionRowHoursTooltip}
      />
      <TaskSummaryRow
        dataQeId="Original_Estimate_Row"
        classes={initialEstimatedClassOverride}
        label={labels.estimateBudget}
        hours={initialEstimatedHours}
        cost={
          isProjectVariant
            ? rolledUpCostSummary?.budgetedCostInProjectCurrency
            : rolledUpCostSummary?.initialEstimatedCostInProjectCurrency
        }
        date={objectToFormatString(endDate, dateFormat)}
        noDateLabel={labels.na}
        hasDivider
        showCost={showCost}
      />
      <TaskVariationRow
        classes={isMobile ? variantStyles : null}
        task={source}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        isPSAPRPTaskEstimateCalculationMethodEnabled={
          isPSAPRPTaskEstimateCalculationMethodEnabled
        }
        showCost={showCost}
        isPsaPpmEstimatedCostAtCompletionEnabled={
          isPsaPpmEstimatedCostAtCompletionEnabled
        }
        isProjectVariant={isProjectVariant}
        atleastOneHoursRowHasTooltip={atleastOneHoursRowHasTooltip}
        isCostTooltipVisible={variationRowMarginCondition}
        hideCostTooltip={hideVariationRowTooltip}
        hideHoursTooltip={hideVariationRowHoursTooltip}
        hideDatesTooltip={hideVariationRowTooltip}
      />
    </div>
  );
};

RollupStatusSection.propTypes = {
  source: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['task', 'project']),
  estimatedCompletionDate: PropTypes.string
};

export default RollupStatusSection;
