import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { isoToReplicon } from '~/util/date';

export const AVAILABLE_PAYMENTS = gql`
  query availablePaymentsForBill(
    $page: Int!
    $pageSize: Int!
    $billId: String!
  ) {
    availablePaymentsForBill(
      page: $page
      pageSize: $pageSize
      billId: $billId
    ) {
      id
      displayText
      transactionDate: paymentDate2
      balanceAmount: balanceAmountInInvoiceCurrency {
        amount
        currency {
          id: uri
          symbol
        }
      }
      balanceAmountInOriginalCurrency: balanceAmountInPaymentCurrency {
        amount
        currency {
          id: uri
          symbol
        }
      }
    }
  }
`;

export const useAvailableBillPayments = ({
  page = 1,
  pageSize = 100,
  billId,
  canEditBill
}) => {
  const { data, loading } = useQuery(AVAILABLE_PAYMENTS, {
    variables: {
      page,
      pageSize,
      billId
    },
    skip: !canEditBill || !billId,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const availablePaymentsForBill = get(data, 'availablePaymentsForBill', null);

  const formattedAvailablePayment = availablePaymentsForBill
    ? availablePaymentsForBill.map(payment => ({
        ...payment,
        transactionDate: isoToReplicon(payment.transactionDate)
      }))
    : availablePaymentsForBill;

  return { loading, availableBillPayments: formattedAvailablePayment };
};
