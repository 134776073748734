import { isValid } from '../../../validator';

export const getSlackChannel = keyValues => {
  const channel = keyValues.find(
    keyValue => keyValue.keyUri === 'replicon-psa:slack-channel'
  );

  return channel ? channel.value.text : null;
};

export const optimisticResponse = ({
  payload: {
    projectUri,
    name,
    code,
    startDate,
    endDate,
    description,
    earnedRevenueScriptId,
    isProjectLeaderApprovalRequired,
    program,
    portfolio,
    projectManagerReference,
    coManagers,
    projectManagementType,
    isTimeEntryAllowed,
    timeAndExpenseEntryType,
    keyValues
  },
  defaultScheduleRule,
  earnedRevenue
}) => ({
  updateProject2: {
    id: projectUri,
    name,
    code,
    startDate,
    endDate,
    description,
    earnedRevenueScript: earnedRevenueScriptId
      ? {
          displayText: earnedRevenue.displayText,
          name: earnedRevenue.displayText,
          id: earnedRevenueScriptId,
          __typename: 'Script'
        }
      : null,
    program: isValid(program)
      ? {
          id: program.id,
          slug: program.slug,
          displayText: program.displayText,
          __typename: 'ProgramReference'
        }
      : null,
    projectManagerReference: isValid(projectManagerReference)
      ? {
          displayText: projectManagerReference.displayText,
          id: projectManagerReference.id,
          __typename: 'ProjectManagerReference'
        }
      : null,
    coManagers: (coManagers || []).map(m => ({
      displayText: m.displayText,
      id: m.id,
      __typename: 'User'
    })),
    timeAndExpenseEntryType: timeAndExpenseEntryType
      ? {
          id: timeAndExpenseEntryType.id,
          displayText: timeAndExpenseEntryType.displayText,
          __typename: 'TimeAndExpenseEntry'
        }
      : null,
    portfolio: portfolio
      ? {
          id: portfolio.id,
          displayText: portfolio.displayText,
          __typename: 'ObjectReference'
        }
      : null,
    isTimeEntryAllowed,
    isProjectLeaderApprovalRequired,
    projectManagementType,
    slackChannel: getSlackChannel(keyValues),
    defaultScheduleRule: defaultScheduleRule
      ? {
          dateRange: {
            startDate,
            endDate,
            __typename: 'DefaultScheduleRuleDateRange'
          },
          do: {
            setHours: defaultScheduleRule.do.setHours,
            defaultScheduleRule: defaultScheduleRule.do.excludeWeekdays
          },
          __typename: 'DefaultScheduleRule'
        }
      : null,
    __typename: 'Project'
  }
});
