import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import { checkForCriteria } from '~/modules/common/util';

export const getForCriteria = (criterions, tag) =>
  checkForCriteria(criterions, tag)
    ? criterions[tag].map(v => {
        if (v.value === NONE_OPTION_KEY) return null;

        return v.value;
      })
    : undefined;

export const getTagForCriteria = (criterions, tag) =>
  criterions && criterions[tag] && criterions[tag].length > 0
    ? criterions[tag].map(value => ({
        valueUri: value.value === NONE_OPTION_KEY ? null : value.value,
        keyUri: value.tag.value
      }))
    : undefined;

export const getValueForCriteria = (criterions, tag) =>
  criterions && criterions[tag] && criterions[tag].length > 0
    ? criterions[tag].map(v => v.id)
    : undefined;
