import { useCallback } from 'react';

export const useMonthDetailsHooks = ({
  createRevenueContractScriptRecalculationBatch,
  createUpdateRevenueRunBatch,
  revenueRunUri,
  monthStartDate,
  setPreviousBatchInProgress,
  setNonBaseCurrencyFailureUri,
  deleteRevenueRunBatchUri,
  deleteProjectRevenueRecognitionBatchUri,
  refetchLastRevenueRunDetails
}) => {
  const onRecalculateClick = useCallback(() => {
    createRevenueContractScriptRecalculationBatch({
      date: {
        year: monthStartDate.c.year,
        month: monthStartDate.c.month,
        day: monthStartDate.c.day
      }
    });
  }, [createRevenueContractScriptRecalculationBatch, monthStartDate]);

  const onCreateRevenueContractScriptRecalculationBatchComplete = useCallback(async () => {
    setPreviousBatchInProgress({ batchUri: null, batchStatus: null });
    setNonBaseCurrencyFailureUri(null);
    await refetchLastRevenueRunDetails();
    await deleteRevenueRunBatchUri();
    await deleteProjectRevenueRecognitionBatchUri();
    createUpdateRevenueRunBatch({ revenueRunUri });
  }, [
    setPreviousBatchInProgress,
    setNonBaseCurrencyFailureUri,
    refetchLastRevenueRunDetails,
    deleteRevenueRunBatchUri,
    deleteProjectRevenueRecognitionBatchUri,
    createUpdateRevenueRunBatch,
    revenueRunUri
  ]);

  const onRevenueRunBatchComplete = useCallback(async () => {
    setPreviousBatchInProgress({ batchUri: null, batchStatus: null });
    setNonBaseCurrencyFailureUri(null);
    await refetchLastRevenueRunDetails();
    await deleteRevenueRunBatchUri();
  }, [
    setPreviousBatchInProgress,
    setNonBaseCurrencyFailureUri,
    deleteRevenueRunBatchUri,
    refetchLastRevenueRunDetails
  ]);

  return {
    onRecalculateClick,
    onCreateRevenueContractScriptRecalculationBatchComplete,
    onRevenueRunBatchComplete
  };
};

export default useMonthDetailsHooks;
