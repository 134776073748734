import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  OverviewItemCard,
  ProjectStatusChart
} from '~/modules/common/charts/dashboard';
import { ProjectIcon } from '~/modules/common/components/Icons';
import { ProjectsCardHelp } from '~/modules/overview/ProjectsCard';
import { CardTitle } from '../common';
import Overbudget from './Overbudget';
import {
  useOverbudgetCount,
  useProjectStatusClickHandlers,
  useProjectsTotals
} from './hooks';

const projectsTitle = <FormattedMessage id="clientPageCharts.projects" />;
const projectAvatar = <ProjectIcon />;

const overrideChartStyle = {
  width: 270,
  height: 270,
  innerRadius: 85,
  titleX: 135,
  titleY: 125,
  valueX: 135,
  valueY: 150
};

const useChartStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2, 2)
  }
}));

const title = <FormattedMessage id="projectOverview.stages" />;
const helpAction = <ProjectsCardHelp />;

const ProjectCard = () => {
  const statusDataProps = useProjectsTotals();
  const {
    projectsStatusCountSummary: {
      initiate = 0,
      planning = 0,
      execution = 0,
      closed = 0
    }
  } = statusDataProps;
  const statusData = useMemo(
    () => ({
      ...statusDataProps,
      projectsStatusCountSummary: {
        ...statusDataProps.projectsStatusCountSummary,
        total: initiate + planning + execution + closed
      }
    }),
    [initiate, planning, execution, closed, statusDataProps]
  );
  const { total, ...overdueDataProps } = useOverbudgetCount();
  const chartClasses = useChartStyles();

  const { legendClickHandlers, events } = useProjectStatusClickHandlers();

  return (
    <OverviewItemCard
      title={projectsTitle}
      avatar={projectAvatar}
      action={helpAction}
    >
      <Overbudget overdueData={overdueDataProps} />
      <CardTitle title={title} />
      <ProjectStatusChart
        classes={chartClasses}
        overrideChartStyle={overrideChartStyle}
        chartDataInput={statusData}
        legendClickHandlers={legendClickHandlers}
        events={events}
      />
    </OverviewItemCard>
  );
};

export default ProjectCard;
