import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import { useIntl } from 'react-intl';
import RequestActionButton from './RequestActionButton';

const SubmittedRequestActionButton = ({
  className,
  onClick,
  ...otherProps
}) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <RequestActionButton
      className={className}
      paletteClass={theme.palette.resourceRequest.pending}
      label={intl.formatMessage({ id: 'resourceAssignmentDialog.pending' })}
      onClick={onClick}
      {...otherProps}
    />
  );
};

SubmittedRequestActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default SubmittedRequestActionButton;
