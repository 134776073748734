import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DurationModeDropdown from './DurationModeDropdown';
import { MODE } from './enum';
import { getDurationSummaryValues } from './durationUtil';
import { OpenEndedTaskIcon } from './OpenEndedTaskIcon';

export const DurationModeContent = ({
  newTaskDateRange,
  durationValues,
  durationMode,
  onDurationModeChange
}) => {
  if (newTaskDateRange.startDate) {
    if (durationValues) {
      return (
        <DurationModeDropdown
          mode={durationMode}
          onModeChange={onDurationModeChange}
          newTaskDateRange={newTaskDateRange}
        />
      );
    }
    if (newTaskDateRange.endDate) {
      return (
        <div>
          <FormattedMessage
            id="rescheduleTaskDialog.duration"
            values={getDurationSummaryValues(
              newTaskDateRange.startDate,
              newTaskDateRange.endDate
            )}
          />
        </div>
      );
    }
  }

  return <OpenEndedTaskIcon />;
};

DurationModeContent.propTypes = {
  durationMode: PropTypes.oneOf(Object.values(MODE)),
  newTaskDateRange: PropTypes.object,
  onDurationModeChange: PropTypes.func,
  durationValues: PropTypes.object
};

export default DurationModeContent;
