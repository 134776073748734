import React, { memo, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { TableBody as MuiTableBody } from '@material-ui/core';
import ListTableVariantContext from '../../../ListTableVariantContext';

const TableBody = ({ children, ...rest }) => {
  const variant = useContext(ListTableVariantContext);

  const Component = variant === 'table' ? MuiTableBody : 'div';

  return <Component {...rest}>{children}</Component>;
};

TableBody.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any
};

export default memo(TableBody);
