import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { Popper, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SearchableRoleDropDown } from '~/modules/resourcing/common/components';
import useUpdateResourceAllocationRole from '~/modules/projects/resourcing-plan/ResourceRequestChart/hooks/useUpdateResourceAllocationRole';

export const validateRole = role => (role && !role.isCreatable) || !role;

const useInputStyles = makeStyles(theme => ({
  input: {
    ...theme.typography.body2,
    fontSize: theme.typography.caption.fontSize,
    padding: '0px 1px !important',
    height: theme.spacing(1.875)
  }
}));

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(0.5)
  },
  roleSelection: {
    minWidth: theme.spacing(20.75),
    padding: theme.spacing(0, 0.5, 0, 1),
    marginRight: theme.spacing(0.5)
  },
  roleRowCellReadonly: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.caption.fontSize,
    height: theme.spacing(5),
    lineHeight: 1.43,
    maxWidth: 'none',
    padding: theme.spacing(1, 1, 0, 1),
    wordBreak: 'break-word'
  }
}));

const popperStyle = { width: 'fit-content' };
const FullWidthPopper = props => {
  return <Popper {...props} style={popperStyle} placement="bottom-start" />;
};

export const ResourceAllocationUserRoleColumn = ({
  classes: classesOverride,
  allocationId,
  isEditAllowed,
  role,
  userId,
  qeIdTag
}) => {
  const intl = useIntl();
  const inputClasses = useInputStyles();
  const classes = useStyles({ classes: classesOverride });

  const {
    onUpdateResourceAllocationRole,
    loading
  } = useUpdateResourceAllocationRole();

  const onRoleChange = useCallback(
    requestedRole => {
      if (validateRole(requestedRole)) {
        onUpdateResourceAllocationRole({
          allocationId,
          requestedRole,
          userId
        });
      }
    },
    [allocationId, onUpdateResourceAllocationRole, userId]
  );

  return isEditAllowed && allocationId ? (
    <div className={classes.roleSelection}>
      {loading ? (
        <Skeleton variant="text" className={classes.spacing} />
      ) : (
        <SearchableRoleDropDown
          dataQeId={`${qeIdTag}_${role?.displayText || 'noRole'}_RoleDropDown`}
          value={role}
          placeholder={intl.formatMessage({
            id: 'resourceRequestTitle.anyRole'
          })}
          classes={inputClasses}
          variant="standard"
          multiline
          maxRows={2}
          onChange={onRoleChange}
          canUserEditSkillAssignment
          ariaLabel={intl.formatMessage({
            id: 'rolesDropdown.selectRole'
          })}
          customPopperComponent={FullWidthPopper}
        />
      )}
    </div>
  ) : (
    <div className={classes.roleRowCellReadonly}>
      {role?.displayText || (
        <FormattedMessage id="resourceRequestReadonlyItem.noRole" />
      )}
    </div>
  );
};

ResourceAllocationUserRoleColumn.propTypes = {
  classes: PropTypes.object,
  allocationId: PropTypes.string,
  isEditAllowed: PropTypes.bool,
  role: PropTypes.object,
  userId: PropTypes.string,
  qeIdTag: PropTypes.string
};

export default ResourceAllocationUserRoleColumn;
