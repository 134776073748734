import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  RadioGroup
} from '@material-ui/core';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';

import User from '~/modules/common/components/User';
import { SmartFitAllocationMode } from '~/types';
import {
  AllocateFullAmountOptionItem,
  AllocateWhatFitsOptionItem,
  ExtendDialogOptionItem,
  RedistributeAllocationItem
} from './components';
import useStyles from './useStyles';

const ResourceAssignmentSmartFitConfirmationDialog = ({
  classes: classesOverrides,
  resourceUser,
  scheduleRules,
  smartFitmentDetails,
  closeShowSmartFitDialogOrLoading,
  createAllocationWithUserAndOverrideRules,
  scale
}) => {
  const intl = useIntl();

  const classes = useStyles({ classes: classesOverrides });

  const [selectedOption, setSelectedOption] = useState(
    SmartFitAllocationMode.Allocatefull
  );

  const onConfirm = useCallback(() => {
    createAllocationWithUserAndOverrideRules(
      selectedOption === SmartFitAllocationMode.Allocatewhatfits
        ? smartFitmentDetails.availableScheduleRulesWhatFits
        : selectedOption === SmartFitAllocationMode.Extendallocation
        ? smartFitmentDetails.extendAllocationScheduleRules
        : selectedOption === SmartFitAllocationMode.Redestributeallocation
        ? smartFitmentDetails.redistributeAllocationScheduleRules
        : smartFitmentDetails.allocateFullScheduleRules.length > 0
        ? smartFitmentDetails.allocateFullScheduleRules
        : scheduleRules
    );
  }, [
    createAllocationWithUserAndOverrideRules,
    scheduleRules,
    selectedOption,
    smartFitmentDetails.allocateFullScheduleRules,
    smartFitmentDetails.availableScheduleRulesWhatFits,
    smartFitmentDetails.extendAllocationScheduleRules,
    smartFitmentDetails.redistributeAllocationScheduleRules
  ]);

  const handleOptionChange = useCallback(
    event => setSelectedOption(event.target && event.target.value),
    [setSelectedOption]
  );

  const handleOptionSelect = useCallback(id => setSelectedOption(id), [
    setSelectedOption
  ]);

  const {
    availableHoursWhatFits,
    requestedHours,
    redistributeHours,
    extendedHours,
    extendAllocationScheduleRules,
    redistributeAllocationScheduleRules
  } = smartFitmentDetails;

  const isAvailable = (availableHoursWhatFits || 0) > 0;

  const isRedistributeAvailable =
    roundToDecimals(redistributeHours || 0) === roundToDecimals(requestedHours);

  const isExtendedOptionAvailable =
    extendAllocationScheduleRules.length !==
      redistributeAllocationScheduleRules.length &&
    roundToDecimals(extendedHours || 0) === roundToDecimals(requestedHours);

  const {
    dateRange: { endDate: extendEndDate }
  } =
    extendAllocationScheduleRules.length !== 0
      ? extendAllocationScheduleRules[extendAllocationScheduleRules.length - 1]
      : { dateRange: {} };

  return (
    <Dialog open onClose={closeShowSmartFitDialogOrLoading} maxWidth={false}>
      <DialogTitle
        id="fit-allocation-dialog-title"
        className={classes.dialogTitle}
      >
        {intl.formatMessage({
          id: 'resourceAssignmentDialog.notEnoughAvailableHours'
        })}

        <User user={resourceUser} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <>
          <DialogContentText>
            {intl.formatMessage({
              id: 'resourceAssignmentDialog.whatWouldYouLikeToDo'
            })}
          </DialogContentText>
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <List className={classes.optionList} component="div" dense={false}>
              <AllocateFullAmountOptionItem
                handleOptionSelect={handleOptionSelect}
                scale={scale}
                hours={smartFitmentDetails.requestedHours}
              />
              {isAvailable ? (
                <AllocateWhatFitsOptionItem
                  handleOptionSelect={handleOptionSelect}
                  scale={scale}
                  hours={smartFitmentDetails.availableHoursWhatFits}
                />
              ) : null}
              {isRedistributeAvailable ? (
                <RedistributeAllocationItem
                  handleOptionSelect={handleOptionSelect}
                  scale={scale}
                  hours={smartFitmentDetails.redistributeHours}
                />
              ) : null}
              {isExtendedOptionAvailable ? (
                <ExtendDialogOptionItem
                  hours={extendedHours}
                  handleOptionSelect={handleOptionSelect}
                  scale={scale}
                  endDate={extendEndDate}
                />
              ) : null}
            </List>
          </RadioGroup>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeShowSmartFitDialogOrLoading}>
          {intl.formatMessage({ id: 'resourceAssignmentDialog.cancel' })}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {intl.formatMessage({
            id: 'resourceAssignmentDialog.createAllocation'
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResourceAssignmentSmartFitConfirmationDialog.propTypes = {
  classes: PropTypes.object,
  closeShowSmartFitDialogOrLoading: PropTypes.func,
  createAllocationWithUserAndOverrideRules: PropTypes.func,
  scale: PropTypes.string,
  resourceUser: PropTypes.object,
  smartFitmentDetails: PropTypes.object,
  scheduleRules: PropTypes.array
};

export default ResourceAssignmentSmartFitConfirmationDialog;
