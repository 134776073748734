import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AllUsersIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 7,4 A 3,3 0 0 0 4,7 3,3 0 0 0 7,10 3,3 0 0 0 7.025390625,9.998046875 C 7.008705712270596,9.834384118273874 7,9.667948016207853 7,9.5 c -4e-15,-1.801602637637134 0.98282237527468,-3.377316869465856 2.427734375,-4.2578125 A 3,3 0 0 0 7,4 Z M 17,4 A 3,3 0 0 0 14.57421875,5.2421875 C 16.01832521269006,6.122865505641013 17,7.699004886367724 17,9.5 17,9.668604866781153 16.9914233563724,9.835715454627202 16.974609375,10 A 3,3 0 0 0 17,10 3,3 0 0 0 20,7 3,3 0 0 0 17,4 Z M 12,6 A 3.500000000000001,3.500000000000001 0 0 0 8.5,9.5 3.500000000000001,3.500000000000001 0 0 0 12,13 3.500000000000001,3.500000000000001 0 0 0 15.5,9.5 3.500000000000001,3.500000000000001 0 0 0 12,6 Z m -5,4.75 c -2.0025,0 -6.0000000000000017,1.005 -6,3 V 16 H 3.828125 C 4.058931129256112,15.51030850431744 4.383440902463373,15.08065141870047 4.748046875,14.744140625 5.42337224479833,14.12085331399998 6.225517869778835,13.6962350112814 7.06640625,13.359375 7.564383314205304,13.15988531336412 8.017513879800373,13.20559293860614 8.52734375,13.068359375 7.88468920626549,12.4428896290692 7.409721884932616,11.65005134253409 7.171875,10.763671875 7.119189032765789,10.76237351064662 7.050325915677221,10.75 7,10.75 Z m 10,0 c -0.0503259156772,0 -0.11918903276579,0.0123735106466 -0.171875,0.013671875 -0.23784688493262,0.88637946753409 -0.71281420626549,1.6792177540692 -1.35546875,2.3046875 0.50982987019963,0.13723356360614 0.9629604357947,0.0915259383641 1.4609375,0.291015625 0.84088838022116,0.33686001128139 1.64303400520167,0.76147831399999 2.318359375,1.384765625 C 19.61655909753663,15.08065141870047 19.94106887074389,15.51030850431744 20.171875,16 H 23 v -2.25 c 0,-1.995 -3.9975,-3 -6,-3 z m -5,3.125 c -2.336249999999994,0 -7,1.1725 -7,3.5 V 20 h 14 v -2.625 c 0,-2.3275 -4.66375,-3.5 -7,-3.5 z" />
    </>
  </SvgIcon>
));

export default AllUsersIcon;
