import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export const RemoveResourceConfirmationDialog = ({
  open,
  resource,
  onCancel,
  onRemoveResource
}) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>
      <FormattedMessage id="taskResourceAssignments.removeResource" />
    </DialogTitle>
    <DialogContent>
      <Alert severity="warning">
        <Typography variant="body1">
          <FormattedMessage
            id="taskResourceAssignments.removeResourceBody"
            values={{
              resourceName: <strong>{resource.displayText}</strong>
            }}
          />
        </Typography>
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button
        color="secondary"
        onClick={onCancel}
        data-qe-id="RemoveResourceConfirmationDialog_Cancel"
      >
        <FormattedMessage id="taskResourceAssignments.cancel" />
      </Button>
      <Button
        color="primary"
        onClick={onRemoveResource}
        data-qe-id="RemoveResourceConfirmationDialog_RemoveResource"
      >
        <FormattedMessage id="taskResourceAssignments.removeResource" />
      </Button>
    </DialogActions>
  </Dialog>
);

RemoveResourceConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  resource: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemoveResource: PropTypes.func
};

export default RemoveResourceConfirmationDialog;
