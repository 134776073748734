import { makeStyles } from '@material-ui/core/styles';

export const useQualificationStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  title: {
    margin: theme.spacing(0.5, 0, 0, 0)
  },
  content: {
    marginTop: theme.spacing(-4)
  }
}));

export const useDateRangeStyles = makeStyles(theme => ({
  range: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold'
  },
  date: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold'
  }
}));
export const useFormStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2, 1, 2),
    backgroundColor: theme.palette.background.paper
  },
  fieldItem: {
    marginBottom: theme.spacing(2)
  }
}));

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  adjustedLoading: {
    margin: 0,
    fontStyle: 'italic'
  },
  hr: {
    border: `1px solid ${theme.palette.resourceRequest.draft.light}`,
    margin: theme.spacing(1, -2, 0, -2)
  },
  comment: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    paddingTop: theme.spacing(1)
  },
  loadOver: {
    color: theme.palette.error.dark
  },
  form: {
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'column',
    overflow: 'auto'
  },
  expandableItem: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      '&:last-child': {
        paddingLeft: 0
      }
    }
  },
  expandedItem: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 1, 1, 0)
  },
  buttonRow: {
    display: 'flex',
    width: '100%',
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    alignContent: 'flex-start',
    marginBottom: theme.spacing(2)
  },
  roleName: {
    margin: 0
  },
  inputIcon: {
    color: theme.palette.text.secondary
  },
  totalHours: { fontWeight: 'bold', fontSize: theme.typography.body1.fontSize },
  defaultFontSize: {
    fontSize: theme.typography.body1.fontSize
  },
  defaultFontFFOn: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold'
  }
}));

export const useReadOnlyGroupStyles = makeStyles(theme => ({
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    width: 0,
    margin: theme.spacing(0, 2, 0, 0),
    '&:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      '&:last-child': {
        marginBottom: 0
      }
    },
    padding: 0
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    fontSize: theme.typography.body2.fontSize
  },
  valueContainer: {
    padding: `${8 - 2}px 0 ${8 - 1}px`,
    lineHeight: '1.1875em',
    fontFamily: theme.typography.fontFamily
  }
}));

export default useStyles;
