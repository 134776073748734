import partition from 'lodash/partition';

export const partitionTagIds = columnOptions => {
  const [tagIds, nonTags] = partition(columnOptions, c =>
    /^urn:replicon-tenant:[^:]+:object-extension-tag-definition:.*$/.exec(c)
  );

  return [tagIds, nonTags];
};

export const includesTagIds = columnOptions =>
  partitionTagIds(columnOptions)[0].length > 0;

export const partitionCustomFieldIds = columnOptions => {
  const [customFieldIds, nonCustomFields] = partition(columnOptions, c =>
    /^urn:replicon-tenant:[^:]+:user-defined-field:.*$/.exec(c)
  );

  return [customFieldIds, nonCustomFields];
};

export const includesCustomFieldIds = columnOptions =>
  partitionCustomFieldIds(columnOptions)[0].length > 0;
