import { useCallback } from 'react';

export const useTaskResourceCountUpdate = ({ gantt }) =>
  useCallback(
    ({
      taskId,
      updateResourceEstimatesCountBy,
      updateResourceEstimatesWithUsersCountBy
    }) => {
      const ganttRef = gantt;
      const { resourceEstimatesSummary } = ganttRef.getTask(taskId);

      ganttRef.getTask(
        taskId
      ).resourceEstimatesSummary = resourceEstimatesSummary
        ? {
            resourceEstimatesCount:
              resourceEstimatesSummary.resourceEstimatesCount +
              updateResourceEstimatesCountBy,
            resourceEstimatesWithUsersCount:
              resourceEstimatesSummary.resourceEstimatesWithUsersCount +
              updateResourceEstimatesWithUsersCountBy
          }
        : {
            resourceEstimatesCount: updateResourceEstimatesCountBy,
            resourceEstimatesWithUsersCount: updateResourceEstimatesWithUsersCountBy
          };
      ganttRef.refreshData(taskId);
    },
    [gantt]
  );

export default useTaskResourceCountUpdate;
