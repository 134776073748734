import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { GET_AVAILABLE_TAX_PROFILE_QUERY } from '~/modules/clients/client/graphql';

export const useTaxProfiles = ({ searchTerm, skip = false }) => {
  const { data, loading } = useQuery(GET_AVAILABLE_TAX_PROFILE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      page: 1,
      pageSize: 100,
      searchText: searchTerm
    },
    skip,
    context: {
      debounceKey: 'client-tax-profile',
      debounceTimeout: 250
    }
  });

  return {
    taxProfiles: get(data, 'pageOfAvailableTaxProfiles', []),
    loading
  };
};

export default useTaxProfiles;
