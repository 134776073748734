import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useBillingOverviewTableContext } from '../BillingOverviewTableContext';
import {
  ValueRow,
  BillingOverviewValueRow,
  BillingOverviewHeaderRow
} from './components';

import {
  getTotalBilledSeriesDataMap,
  getUnbilledSeriesDataMap
} from './enhancers';

export const BillingActualsGroup = ({
  sectionData,
  availableToBill,
  displayTotalColumn,
  handleCallToAction
}) => {
  const handleCellClick = useCallback(
    (startDate, endDate) => event => {
      handleCallToAction(startDate, endDate);
    },
    [handleCallToAction]
  );
  const { periods, scale } = useBillingOverviewTableContext();
  const { totalBilledSeriesDataMap } = useMemo(
    () =>
      getTotalBilledSeriesDataMap({
        periods,
        scale,
        sectionData
      }),
    [sectionData, periods, scale]
  );
  const { unbilledSeriesDataMap } = useMemo(
    () =>
      getUnbilledSeriesDataMap({
        periods,
        scale,
        availableToBill,
        totalBilledSeriesDataMap,
        sectionData
      }),
    [availableToBill, periods, scale, totalBilledSeriesDataMap, sectionData]
  );

  return (
    <>
      <BillingOverviewHeaderRow>
        <FormattedMessage id="billingOverviewTable.billing" />
      </BillingOverviewHeaderRow>
      <ValueRow
        seriesDataMap={unbilledSeriesDataMap}
        rowTitle="billingOverviewTable.unbilled"
        cellClickable
        handleCellClick={handleCellClick}
        dataQeId="billingTransactionActualsSection.unbilled"
        displayTotalColumn={displayTotalColumn}
      />
      <ValueRow
        seriesDataMap={totalBilledSeriesDataMap}
        rowTitle="billingOverviewTable.billed"
        dataQeId="billingTransactionActualsSection.billed"
        displayTotalColumn={displayTotalColumn}
      />
      <BillingOverviewValueRow
        rowData={sectionData.invoiceItemOverrides}
        rowTitle="billingOverviewTable.adjustment"
        dataQeId="billingTransactionActualsSection.adjustment"
        displayTotalColumn={displayTotalColumn}
      />

      <BillingOverviewValueRow
        rowData={sectionData.payment}
        rowTitle="billingOverviewTable.payments"
        dataQeId="billingTransactionActualsSection.payments"
        displayTotalColumn={displayTotalColumn}
      />
      <BillingOverviewValueRow
        rowData={sectionData.creditMemo}
        rowTitle="billingOverviewTable.creditMemos"
        dataQeId="billingTransactionActualsSection.creditMemos"
        displayTotalColumn={displayTotalColumn}
      />

      <BillingOverviewValueRow
        totalRow
        rowData={sectionData.outstanding}
        rowTitle="billingOverviewTable.outstanding"
        dataQeId="billingTransactionActualsSection.outstanding"
        displayTotalColumn={displayTotalColumn}
      />
    </>
  );
};

BillingActualsGroup.propTypes = {
  availableToBill: PropTypes.object,
  sectionData: PropTypes.object,
  handleCallToAction: PropTypes.func,
  displayTotalColumn: PropTypes.bool
};

export default BillingActualsGroup;
