import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { DateTime } from 'luxon';

const AsOfCloseDateTitle = ({ date }) => {
  const closeMonth = useMemo(
    () => ({
      date: DateTime.utc(date.year, date.month, date.date).toLocaleString({
        month: 'short',
        year: 'numeric'
      })
    }),
    [date.date, date.month, date.year]
  );

  return (
    <>
      <FormattedMessage
        id="projectRevenueRecognitionCard.asOfCloseDateTitle"
        values={closeMonth}
      />
    </>
  );
};

AsOfCloseDateTitle.propTypes = {
  date: PropTypes.object.isRequired
};
export default AsOfCloseDateTitle;
