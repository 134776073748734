import { gantt } from '@replicon/dhtmlx-gantt';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {
  ProjectIcon,
  TaskIcon,
  TaskParentIcon
} from '~/modules/common/components';
import GanttTaskName2 from './GanttTaskName2';
import { isProject } from './util';
import ResourceEstimatesSummary from './ResourceEstimatesSummary';

const TaskNameTemplate = ({
  classes,
  task,
  intl,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  return (
    <div
      className={classNames(
        'gantt_TaskName_Container',
        classes.ganttTaskNameContainer
      )}
    >
      <span className={classes.taskIcon}>
        {isRolledUpTaskEstimateCalculationMethodEnabled &&
        isProject(task.$level) ? (
          <ProjectIcon />
        ) : gantt.hasChild(task.id) ? (
          <TaskParentIcon />
        ) : (
          <TaskIcon />
        )}
      </span>
      <GanttTaskName2
        isRolledUpTaskEstimateCalculationMethodEnabled={
          isRolledUpTaskEstimateCalculationMethodEnabled
        }
        task={task}
        ariaLabel={task.text}
      />
      <ResourceEstimatesSummary
        resourceEstimatesSummary={task?.resourceEstimatesSummary}
        intl={intl}
      />
    </div>
  );
};

TaskNameTemplate.propTypes = {
  classes: PropTypes.object,
  task: PropTypes.object,
  intl: PropTypes.object,
  isRolledUpTaskEstimateCalculationMethodEnabled: PropTypes.bool
};

export default TaskNameTemplate;
