import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal';
import { CustomField } from '~/modules/customFields/components';
import {
  getNameAndOnChangeHandlerProps,
  getErrorAndHelperTextProps
} from '~/modules/customFields/utils';

const RequiredCustomFieldValue = ({
  id,
  customFieldValue,
  errors,
  handleChange,
  setFieldValue
}) => {
  const { name: customFieldName, onChange } = useMemo(
    () =>
      getNameAndOnChangeHandlerProps({
        handleChange,
        setFieldValue,
        path: 'customFields',
        customFieldValue,
        key: id
      }),
    [handleChange, setFieldValue, customFieldValue, id]
  );

  const { error, helperText } = useMemo(
    () =>
      getErrorAndHelperTextProps({
        errors,
        customFieldValue,
        path: 'customFields'
      }),
    [errors, customFieldValue]
  );

  return (
    <CustomField
      variant="filled"
      editable
      fullWidth
      customFieldValue={customFieldValue}
      name={customFieldName}
      onChange={onChange}
      error={error}
      helperText={helperText}
    />
  );
};

RequiredCustomFieldValue.propTypes = {
  id: PropTypes.string.isRequired,
  customFieldValue: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

const areEqual = (prev, next) => deepEqual(prev, next);

export default memo(RequiredCustomFieldValue, areEqual);
