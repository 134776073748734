import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Drawer, makeStyles } from '@material-ui/core';
import { useSkillLevels } from '~/modules/common/hooks';
import ErrorDrawer from '~/modules/common/components/TaskDrawer/ErrorDrawer';
import LoadingDrawer from '~/modules/common/components/TaskDrawer/LoadingDrawer';
import { useResourceRequestDetails } from '~/modules/resourcing/common/hooks';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { RESOURCING_ALLOCATION_MODEL } from '~/modules/common/enums';
import { ResourceRequestDrawerContent2 } from './ResourceRequestDrawerContent/ResourceRequestDrawerContent';

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    padding: 0,
    width: 580,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 700
    }
  }
}));

export const ResourceRequestDrawer = ({
  resourceRequestId,
  onClose,
  allocationChartRef,
  open,
  isEditSwitchEnabled
}) => {
  const drawerClasses = useDrawerStyles();
  const { resourcingAllocationModel } = useProjectContext();

  const modalProps = useMemo(
    () => ({ onBackdropClick: onClose, onEscapeKeyDown: onClose }),
    [onClose]
  );

  const isResourceRequestMergeEnabled =
    resourcingAllocationModel !== RESOURCING_ALLOCATION_MODEL.MODEL_0;

  const {
    loading,
    projectRequestDetails: {
      resourceRequest,
      resourceAllocations,
      project,
      defaultRequestAttributeWeights
    } = {},
    error: errorData
  } = useResourceRequestDetails(
    resourceRequestId,
    isResourceRequestMergeEnabled
  );

  const { maxSkillLevel, isSkillLoading } = useSkillLevels();

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="temporary"
      classes={drawerClasses}
      ModalProps={modalProps}
    >
      {isSkillLoading || loading ? (
        <LoadingDrawer onClose={onClose} />
      ) : errorData && errorData.error ? (
        <ErrorDrawer data={errorData} onClose={onClose} />
      ) : (
        <ResourceRequestDrawerContent2
          onClose={onClose}
          resourceRequest={resourceRequest}
          resourceAllocations={resourceAllocations}
          project={project}
          defaultRequestAttributeWeights={defaultRequestAttributeWeights}
          maxSkillLevel={maxSkillLevel}
          allocationChartRef={allocationChartRef}
          isEditSwitchEnabled={isEditSwitchEnabled}
        />
      )}
    </Drawer>
  );
};

ResourceRequestDrawer.propTypes = {
  resourceRequestId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  allocationChartRef: PropTypes.object,
  open: PropTypes.bool,
  isEditSwitchEnabled: PropTypes.bool
};

export default ResourceRequestDrawer;
