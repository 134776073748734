import { useCallback } from 'react';
import { UNMANAGED_URI, MANAGED_URI } from '~/modules/projects/projectTypes';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';

export const updateQueryOnFetchMoreResult = ({ isManaged }) => (
  prev = {
    projectsFilteredByResourceRequestStatus: { resourceRequestsProjects: [] }
  },
  {
    fetchMoreResult = {
      projectsFilteredByResourceRequestStatus: {
        resourceRequestsProjects: []
      }
    }
  }
) => {
  if (!fetchMoreResult) return prev;

  const prevProjects =
    prev.projectsFilteredByResourceRequestStatus.resourceRequestsProjects || [];
  const moreProjects =
    fetchMoreResult.projectsFilteredByResourceRequestStatus
      .resourceRequestsProjects || [];

  const currentQueryProjectManagementType = isManaged
    ? MANAGED_URI
    : UNMANAGED_URI;

  const shouldAppendResult = moreProjects
    ? prevProjects.some(
        p =>
          p.project.projectManagementType === currentQueryProjectManagementType
      )
    : false;

  if (!shouldAppendResult)
    return { projectsFilteredByResourceRequestStatus: [] };

  return {
    projectsFilteredByResourceRequestStatus: {
      resourceRequestsProjects: [...prevProjects, ...moreProjects],
      __typename: 'Project',
      paginationToken: fetchMoreResult.projectsFilteredByResourceRequestStatus
        ? fetchMoreResult.projectsFilteredByResourceRequestStatus
            .paginationToken
        : null
    }
  };
};

export const useLoadMoreProjectsFilteredByResourceRequestStatus = ({
  isManaged,
  searchCriteria,
  fetchMore,
  pageSize,
  hasMore,
  resourceRequestStatus,
  paginationToken,
  quickFilter
}) => {
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter
  });

  const loadMore = useCallback(async () => {
    if (!hasMore) return;
    try {
      await fetchMore({
        variables: {
          pageSize,
          paginationToken,
          projectFilter: { ...projectFilter, isManaged },
          resourceRequestStatus
        },
        updateQuery: updateQueryOnFetchMoreResult({
          isManaged
        }),
        fetchPolicy: 'network-only'
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }, [
    hasMore,
    fetchMore,
    pageSize,
    paginationToken,
    projectFilter,
    isManaged,
    resourceRequestStatus
  ]);

  return {
    loadMore
  };
};
