import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AttributePartialMatchIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12,2 C 6.480000000000001,2 2,6.480000000000001 2,12 2,17.52 6.480000000000001,22 12,22 17.52,22 22,17.52 22,12 22,6.480000000000001 17.52,2 12,2 Z M 7,13 v -2 h 10 v 2 z" />
    </>
  </SvgIcon>
));

export default AttributePartialMatchIcon;
