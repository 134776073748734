import React, { useCallback } from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import { useRemoveResourceUserTaskAssignment } from '../../hooks';

export const useStyles = makeStyles(theme => ({
  actionContainer: {
    margin: theme.spacing(1, 2, 1, 0)
  }
}));

export const RemoveTaskAssignmentConfirmationDialogFooter = ({
  isLoading,
  onClose,
  onRemoveTaskAssignmentSuccess
}) => {
  const classes = useStyles();
  const {
    loading,
    removeResourceUserTaskAssignment
  } = useRemoveResourceUserTaskAssignment();

  const { task, resourceEstimate } = useTaskEstimateRowContext();

  const onRemoveTaskAssignmentSubmit = useCallback(async () => {
    await removeResourceUserTaskAssignment({
      userId: resourceEstimate.resourceUser.id,
      projectId: task.project.id,
      taskId: task.id
    });
    onRemoveTaskAssignmentSuccess({ taskId: task.id });
  }, [
    task,
    resourceEstimate,
    removeResourceUserTaskAssignment,
    onRemoveTaskAssignmentSuccess
  ]);

  return (
    <DialogActions className={classes.actionContainer}>
      <Button onClick={onClose}>
        <FormattedMessage id="removeTaskEstimateDialog.cancel" />
      </Button>
      <LoadingButton
        color="secondary"
        onClick={onRemoveTaskAssignmentSubmit}
        disabled={isLoading}
        isLoading={loading}
      >
        <FormattedMessage id="removeTaskEstimateDialog.removeEstimate" />
      </LoadingButton>
    </DialogActions>
  );
};

RemoveTaskAssignmentConfirmationDialogFooter.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onRemoveTaskAssignmentSuccess: PropTypes.func.isRequired
};
