import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useHasPermission } from '~/modules/common/permissions';
import { useEnabledSkills } from '~/modules/common/hooks';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import {
  CreatableAutocomplete,
  CreatableDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import AddSkillDialog from './AddSkillDialog';

const groupBy = val => val.category && val.category.displayText.toUpperCase();

const renderTags = tagValue =>
  tagValue.map(option => <div key={option.id}></div>);

export const SkillsSelectDropdown = ({
  onChange,
  value,
  hiddenLabel = true,
  onSkillAdd,
  variant,
  className,
  customLabel
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const {
    hasMore,
    isLoading,
    sortedOptionsWithCategory: options
  } = useEnabledSkills({ searchTerm });

  const intl = useIntl();
  const { open, closeDialog, openDialog } = useDialogState();
  const [newSkillName, setNewSkillName] = useState('');
  const openDialogAndSetInitialName = useCallback(
    name => {
      setNewSkillName(name);
      openDialog();
    },
    [openDialog, setNewSkillName]
  );

  const getOptionDisabled = useCallback(
    option =>
      value.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [value]
  );

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <CreatableDropdownItem
          option={option}
          onCreate={openDialogAndSetInitialName}
          optionPropText="displayText"
          primaryLabelTextId="skillsDropdown.creatableSkill"
        />
      ),
    [openDialogAndSetInitialName]
  );

  const canCreateSkills = useHasPermission({
    actionUri: 'urn:replicon:skill-action:edit-skill'
  });

  const filteredOnChange = useCallback(
    (values, event, _, creatableValues) => {
      if (
        event.keyCode === 13 &&
        creatableValues[creatableValues.length - 1]?.isCreatable
      ) {
        openDialogAndSetInitialName(
          creatableValues[creatableValues.length - 1].displayText
        );

        return;
      }
      if (event.key !== 'Backspace' || event.keyCode !== 8) {
        onChange(values);
      }
    },
    [onChange, openDialogAndSetInitialName]
  );

  const filterOptions = useCallback(
    (filterOption, params) => {
      const items = [...filterOption];

      if (canCreateSkills && params.inputValue !== '' && items.length === 0) {
        items.push({
          isCreatable: true,
          id: params.inputValue,
          displayText: params.inputValue
        });
      }

      return items;
    },
    [canCreateSkills]
  );

  const fieldLabel = intl.formatMessage({
    id: customLabel || 'skillInfoCard.selectSkills'
  });

  return (
    <>
      <CreatableAutocomplete
        hasMore={hasMore}
        loading={isLoading}
        multiple
        getOptionDisabled={getOptionDisabled}
        groupBy={groupBy}
        filterOptions={filterOptions}
        fullWidth
        canAddNewOption={canCreateSkills}
        value={value}
        onChange={filteredOnChange}
        placeholder={fieldLabel}
        inputLabel={fieldLabel}
        options={options}
        optionPropText="displayText"
        renderOption={renderOption}
        renderTags={renderTags}
        disableClearable
        hiddenLabel={hiddenLabel}
        noOptionsText={intl.formatMessage({
          id: 'skillsDropdown.noMatchingSkillsFound'
        })}
        variant={variant}
        className={className}
        onInputChange={handleInputChange}
        optionTypeText={intl.formatMessage({
          id: 'moreOptions.skills'
        })}
      />
      <AddSkillDialog
        open={open}
        initialName={newSkillName}
        onClose={closeDialog}
        onSkillAdd={onSkillAdd}
      />
    </>
  );
};

SkillsSelectDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.node,
        label: PropTypes.node
      })
    )
  ]),
  hiddenLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSkillAdd: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  customLabel: PropTypes.string
};

export default SkillsSelectDropdown;
