/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DateRange } from '~/modules/common/components';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import useStyles, { usePopperStyles } from './useStyles';
import ResourceActualSummaryDialogRow from './ResourceActualSummaryDialogRow';

export const ResourceActualSummaryDialog = ({
  startDate,
  endDate,
  allocatedHours,
  actualHours,
  scheduledHours,
  onClose,
  anchorEl
}) => {
  const classes = useStyles();
  const popperClasses = usePopperStyles();

  const allocatedPercent = (allocatedHours * 100) / scheduledHours;
  const actualPercent = (actualHours * 100) / scheduledHours;
  const hoursDiff = allocatedHours - actualHours;
  const percentDiff = allocatedPercent - actualPercent;

  const values = useMemo(() => ({ scheduledHours }), [scheduledHours]);

  return (
    <>
      <ArrowPopup
        id="actuals-timeline-popup"
        anchorEl={anchorEl}
        placement="bottom"
        onClose={onClose}
        preventOverflow
        data-qe-id="ActualsArrowPopup"
        classes={popperClasses}
      >
        <div className={classes.root} data-qe-id="ResourceActualSummaryDialog">
          <div className={classes.heading}>
            <DateRange
              start={startDate}
              end={endDate}
              className={classes.date}
            />
            <div
              className={classes.subTitle}
              data-qe-id="ActualsDialogScheduledHours"
            >
              <FormattedMessage
                id="quickAllocation.actualsDialog.scheduledHours"
                values={values}
              />
            </div>
          </div>
          <div className={classes.values}>
            <ResourceActualSummaryDialogRow
              rowName="quickAllocation.actualsDialog.allocated"
              hours={allocatedHours}
              percent={allocatedPercent}
              dataQeId="ActualsDialogAllocatedHours"
              viewPercentage
            />
            <ResourceActualSummaryDialogRow
              rowName="quickAllocation.actualsDialog.actual"
              hours={actualHours}
              percent={actualPercent}
              dataQeId="ActualsDialogActualHours"
              viewPercentage
            />
            <ResourceActualSummaryDialogRow
              rowName="quickAllocation.actualsDialog.difference"
              isTotalRow
              showDiff={actualHours !== allocatedHours}
              isHigher={actualHours > allocatedHours}
              hours={hoursDiff}
              percent={percentDiff}
              dataQeId="ActualsDialogVariation"
              viewPercentage
            />
          </div>
        </div>
      </ArrowPopup>
    </>
  );
};

ResourceActualSummaryDialog.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  allocatedHours: PropTypes.number,
  actualHours: PropTypes.number,
  scheduledHours: PropTypes.number,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object
};

export default ResourceActualSummaryDialog;
