import React from 'react';
import PropTypes from 'prop-types';
import { deepPure } from '~/util';
import { RequestActions } from '~/modules/resourcing/common/components/RequestActions/RequestActions';
import ViewMultipleResourceRequestsDialog from '~/modules/resourcing/common/components/ViewMultipleResourceRequestsDialog';
import ReleaseAllocationsDialog from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/ReleaseAllocationsDialog';

export const LeftItemActions = ({
  actions,
  actionsClasses,
  qeIdTag,
  showReleaseResourcesDialog,
  allocatedUser,
  project,
  releaseResourceAllocation,
  onReleaseResourceAllocationSuccess,
  closeReleaseResourcesDialog,
  releaseAllocationLoading,
  showViewMultipleResourceRequestsDialog,
  onViewMultipleResourceRequestsDialogClose,
  openResourceRequestDrawer
}) => (
  <>
    <RequestActions
      actions={actions}
      classes={actionsClasses}
      qeIdTag={`${qeIdTag}_RequestActions`}
    />
    {showReleaseResourcesDialog && (
      <ReleaseAllocationsDialog
        allocatedUser={allocatedUser}
        projectUri={project.id}
        onReleaseResourceAllocation={releaseResourceAllocation}
        open={showReleaseResourcesDialog}
        onCancelClick={closeReleaseResourcesDialog}
        canEditTask={project.permittedActionUris.includes(
          'urn:replicon:project-action:edit-tasks'
        )}
        releaseAllocationLoading={releaseAllocationLoading}
        onReleaseSuccess={onReleaseResourceAllocationSuccess}
      />
    )}
    {showViewMultipleResourceRequestsDialog && (
      <ViewMultipleResourceRequestsDialog
        open={showViewMultipleResourceRequestsDialog}
        onClose={onViewMultipleResourceRequestsDialogClose}
        projectId={project.id}
        user={allocatedUser.user}
        resourceRequestListItemClickHandler={openResourceRequestDrawer}
      />
    )}
  </>
);

LeftItemActions.propTypes = {
  actions: PropTypes.array,
  actionsClasses: PropTypes.object,
  qeIdTag: PropTypes.string,
  showReleaseResourcesDialog: PropTypes.bool,
  allocatedUser: PropTypes.object,
  project: PropTypes.object,
  releaseResourceAllocation: PropTypes.func,
  closeReleaseResourcesDialog: PropTypes.func,
  releaseAllocationLoading: PropTypes.bool,
  onReleaseResourceAllocationSuccess: PropTypes.func,
  showViewMultipleResourceRequestsDialog: PropTypes.bool,
  onViewMultipleResourceRequestsDialogClose: PropTypes.func,
  openResourceRequestDrawer: PropTypes.func
};

export default deepPure(LeftItemActions);
