import React, { useCallback } from 'react';
import { Popover, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useGetTaskResourceUserAllocationsSummaryForUser } from '../../common/hooks';
import ResourceUserProjectAllocationContent from './ResourceUserProjectAllocationContent';
import NoResourceUserProjectAllocationContent from './NoResourceUserProjectAllocationContent';
import { useProjectAllocationForResourceUser } from './hooks';
import TaskAllocationEditorSkeleton from './TaskAllocationEditorSkeleton';

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    width: theme.spacing(64)
  }
}));

const TaskAllocationEditor = ({
  anchorEl,
  setAnchorEl,
  userId,
  projectId,
  projectSlug,
  taskId,
  roleId,
  taskStartDate,
  taskEndDate,
  initialEstimatedHours,
  taskResourceUserAllocation,
  resourceEstimateId,
  estimatedAtCompletionHours
}) => {
  const { loading, resourceAllocation } = useProjectAllocationForResourceUser({
    projectId,
    userId
  });

  const {
    loading: summaryLoading,
    taskResourceUserAllocationsSummary: otherTaskAllocationsSummary
  } = useGetTaskResourceUserAllocationsSummaryForUser({
    userId,
    projectId,
    taskAllocationId: taskResourceUserAllocation?.id
  });

  const popoverClasses = usePopoverStyles();

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <Popover
      id="task-allocation-editor"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={popoverClasses}
    >
      {loading || summaryLoading ? (
        <TaskAllocationEditorSkeleton onClose={onClose} />
      ) : resourceAllocation ? (
        <ResourceUserProjectAllocationContent
          taskId={taskId}
          onClose={onClose}
          userId={userId}
          taskStartDate={taskStartDate}
          taskEndDate={taskEndDate}
          projectId={projectId}
          projectSlug={projectSlug}
          roleId={roleId}
          resourceEstimateId={resourceEstimateId}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          resourceAllocation={resourceAllocation}
          initialEstimatedHours={initialEstimatedHours}
          taskResourceUserAllocation={taskResourceUserAllocation}
          estimatedAtCompletionHours={estimatedAtCompletionHours}
        />
      ) : (
        <NoResourceUserProjectAllocationContent
          projectSlug={projectSlug}
          onClose={onClose}
        />
      )}
    </Popover>
  );
};

TaskAllocationEditor.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  projectId: PropTypes.string,
  taskStartDate: PropTypes.object,
  taskEndDate: PropTypes.object,
  userId: PropTypes.string,
  taskId: PropTypes.string,
  roleId: PropTypes.string,
  projectSlug: PropTypes.string.isRequired,
  taskResourceUserAllocation: PropTypes.object,
  initialEstimatedHours: PropTypes.number,
  resourceEstimateId: PropTypes.string.isRequired,
  estimatedAtCompletionHours: PropTypes.number
};

export default TaskAllocationEditor;
