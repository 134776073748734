import { useQuery } from '@apollo/client';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';
import { useLoadMore2 as useLoadMore } from '~/modules/common/hooks';
import { RESOURCE_USERS_QUERY } from '../enhancers/withResourceUsersQuery';

export const useGetUseResourceUsers = ({ variables, query, skip }) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(query, {
    variables,
    skip,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const resourceUsers = (data && data.resourceUsers2) || [];

  const { hasMore, loadingMore, loadMore } = useLoadMore({
    totalFetchedItems: resourceUsers.length,
    fetchMore,
    variables,
    loading,
    pageSize: variables.pagesize
  });

  return {
    loadingRows: loading || false,
    loadingMore: loadingMore || false,
    resourceUsers: (resourceUsers || []).map(r => ({
      ...r,
      resourceAllocations: (r && r.resourceAllocations) || []
    })),
    error,
    loadMoreRows: loadMore,
    hasMoreRows: hasMore,
    refetch
  };
};

export const useResourceUsers = ({
  page = 1,
  pagesize = 20,
  filter,
  dateRange,
  scale,
  sort,
  isViewAvailabilityEnabled = false,
  isViewResourceAllocation = false,
  isViewResourceActualsEnabled = false,
  skipMerge = false
}) =>
  useGetUseResourceUsers({
    variables: {
      page,
      pagesize,
      filter,
      sort,
      isAvailabilityEnabled: isViewAvailabilityEnabled,
      isViewResourceAllocation,
      isViewResourceActualsEnabled,
      periodResolution: mapScaleToPeriodResolution(scale),
      dateRange,
      skipMerge
    },
    query: RESOURCE_USERS_QUERY
  });

export default useResourceUsers;
