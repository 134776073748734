import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Grid } from '@material-ui/core';
import SettingChips from './SettingChips';
import SettingLists from './SettingLists';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  setting: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    minWidth: '240px',
    '&:last-child': {
      borderRight: 'none',
      paddingRight: '0px',
      marginRight: '0px'
    }
  }
}));

const Settings = ({
  type = 'chips',
  settings,
  rowsLabel,
  rows,
  setRows,
  groupingsLabel,
  groupings,
  setGroupings,
  columnsLabel,
  columns,
  setColumns,
  disabled = false
}) => {
  const classes = useStyles();

  const SettingComponent = type === 'list' ? SettingLists : SettingChips;

  const countOfSettings =
    (rows ? 1 : 0) + (groupings ? 1 : 0) + (columns ? 1 : 0);

  const xs = 12 / countOfSettings;

  return (
    <Grid container direction="row">
      {rows && (
        <SettingComponent
          className={classes.setting}
          label={rowsLabel}
          options={settings.rows.options}
          values={rows}
          setValues={setRows}
          disabled={disabled}
          xs={xs}
        />
      )}
      {groupings && (
        <SettingComponent
          className={classes.setting}
          label={groupingsLabel}
          options={settings.groupings.options}
          values={groupings}
          setValues={setGroupings}
          multiselect={false}
          disabled={disabled}
          xs={xs}
        />
      )}
      {columns && (
        <SettingComponent
          className={classes.setting}
          label={columnsLabel}
          options={settings.columns.options}
          values={columns}
          setValues={setColumns}
          disabled={disabled}
          xs={xs}
        />
      )}
    </Grid>
  );
};

Settings.propTypes = {
  type: PropTypes.string,
  settings: PropTypes.object,
  rowsLabel: PropTypes.string,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  groupingsLabel: PropTypes.string,
  groupings: PropTypes.array,
  setGroupings: PropTypes.func,
  columnsLabel: PropTypes.string,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  disabled: PropTypes.bool
};

export default Settings;
