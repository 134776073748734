import { withStyles } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';

const styles = theme => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    flexShrink: 1
  },
  subcolumns: {
    display: 'flex',
    flexDirection: 'row',
    borderRight: `solid 1px ${theme.palette.divider}`
  },
  subcolumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    flexGrow: 3,
    flexShrink: 3
  },
  rowTotalsColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    right: 0,
    zIndex: 1,
    borderLeft: `solid 1px ${theme.palette.background.paper}`,
    backgroundColor: blueGrey[50]
  },
  subheader: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    minWidth: '48px',
    padding: '8px 16px 8px 16px',
    width: '100%',
    height: '31px',
    position: 'sticky',
    top: '137px'
  },
  datumSubheader: {
    textAlign: 'right'
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 16px 8px 16px',
    whiteSpace: 'nowrap',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: `solid 1px ${theme.palette.divider}`
  },
  datum: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    minWidth: '104px',
    width: '100%'
  },
  taskDatum: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '225px',
    minWidth: '104px'
  },
  boldDatum: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    minWidth: '104px',
    textAlign: 'right',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  footer: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    padding: '8px 16px 8px 16px',
    minWidth: '48px',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'right',
    position: 'sticky',
    bottom: 0,
    borderTop: `solid 1px ${theme.palette.background.paper}`,
    display: 'flex',
    flexDirection: 'column'
  }
});

export default withStyles(styles);
