import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DISPLAY_UNIT_ENUM } from '../enums';

export const DisplayUnitContext = React.createContext();

export const DisplayUnitContextProvider = ({
  defaultDisplayUnit,
  onDisplayUnitChange,
  children
}) => {
  const value = useMemo(
    () => ({
      displayUnit: defaultDisplayUnit,
      setDisplayUnit: onDisplayUnitChange
    }),
    [defaultDisplayUnit, onDisplayUnitChange]
  );

  return (
    <DisplayUnitContext.Provider value={value}>
      {children}
    </DisplayUnitContext.Provider>
  );
};

DisplayUnitContextProvider.propTypes = {
  defaultDisplayUnit: PropTypes.oneOf([
    DISPLAY_UNIT_ENUM.HOURS,
    DISPLAY_UNIT_ENUM.PERCENTAGE
  ]).isRequired,
  onDisplayUnitChange: PropTypes.func,
  children: PropTypes.node.isRequired
};

export const useDisplayUnitContext = () => useContext(DisplayUnitContext);

export default DisplayUnitContextProvider;
