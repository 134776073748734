import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const AvailableBillQuery = gql`
  query getAvailableBillingItemsTotal($input: AvailableBillingItemInput2!) {
    getAvailableBillingItemsTotal: getAvailableBillingItemsTotal2(
      input: $input
    ) {
      groupCount
      totalBillingAmount {
        amount
        currency {
          id
          symbol
        }
      }
    }
  }
`;

export const useAvailableBillSummary = ({
  client: { id: clientUri } = {},
  billCurrency: { id: billingAmountCurrencyUri } = {},
  columns,
  billingItemSearch = {}
}) => {
  const { data, loading, error } = useQuery(AvailableBillQuery, {
    variables: {
      input: {
        billingItemColumnOption: columns,
        filter: {
          clientUri,
          textSearch: billingItemSearch.text,
          userUris: billingItemSearch.userUris,
          projectUris: billingItemSearch.projectUris,
          dateRange: billingItemSearch.dateRange,
          billingItemTypes: billingItemSearch.billingItemTypes
        },
        billingAmountCurrencyUri
      }
    },
    fetchPolicy: 'network-only'
  });

  const getAvailableBillingItemsTotal = get(
    data,
    'getAvailableBillingItemsTotal',
    {}
  );

  return {
    availableBillingItemSummary: {
      projectCount:
        getAvailableBillingItemsTotal &&
        getAvailableBillingItemsTotal.groupCount,
      totalAmount:
        getAvailableBillingItemsTotal &&
        getAvailableBillingItemsTotal.totalBillingAmount,
      isLoading: loading,
      error
    }
  };
};
