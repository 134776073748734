import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { ResourceAllocationStatus } from '~/types';

export const PROJECT_ALLOCATION_FOR_RESOURCE_USER = gql`
  query Eager_GetAllocations(
    $projectUri: String
    $allocationStatusList: [ResourceAllocationStatus]
    $filter: ResourceAllocationFilter
  ) {
    resourceAllocations(
      projectUri: $projectUri
      allocationStatusList: $allocationStatusList
      filter: $filter
    ) {
      resourceAllocations {
        id
        startDate
        endDate
        totalHours
        user {
          userAllocationId
          user {
            id
            displayText
          }
        }
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
      }
    }
  }
`;

export const useProjectAllocationForResourceUser = ({ userId, projectId }) => {
  const { loading, error, data } = useQuery(
    PROJECT_ALLOCATION_FOR_RESOURCE_USER,
    {
      variables: {
        filter: {
          users: [userId]
        },
        projectUri: projectId,
        allocationStatusList: [ResourceAllocationStatus.Committed]
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  return {
    loading,
    error,
    resourceAllocation: data?.resourceAllocations?.resourceAllocations?.[0]
  };
};

export default useProjectAllocationForResourceUser;
