import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, useFormikContext } from 'formik';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { FinancialsIcon } from '~/modules/common/components/Icons';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { useMeContext } from '~/modules/me/useMeContext';
import FinancialCardEditableTitle from './FinancialCardEditableTitle';
import FinancialsEditable from './FinancialsEditable';
import FinancialsReadOnly from './FinancialsReadOnly';
import useFormState from './useFormState';

const useStyles = makeStyles(theme => ({
  action: { display: 'flex', alignSelf: 'center' },
  projectCurrency: {
    display: 'flex',
    alignSelf: 'center',
    ...theme.typography.caption,
    margin: theme.spacing(0, 1, 0, 0.5),
    color: theme.palette.text.secondary
  }
}));

const avatar = <FinancialsIcon />;
const title = <FormattedMessage id="projectFinancialsCard.title" />;

const FinancialsCard = ({
  editable,
  projectPermissions = {},
  projectCurrency,
  isProjectLoading
}) => {
  const classes = useStyles();

  const currencyValue = projectCurrency
    ? { value: projectCurrency.displayText }
    : null;

  const additionalInfo = useMemo(
    () =>
      !isProjectLoading ? (
        <div className={classes.projectCurrency}>
          <FormattedMessage
            id="projectFinancialsCard.projectCurrencyWithValue"
            values={currencyValue}
          />
        </div>
      ) : null,
    [isProjectLoading, currencyValue, classes.projectCurrency]
  );

  const cardHeaderClass = useMemo(
    () => ({
      action: classes.action
    }),
    [classes.action]
  );

  const fullScreen = useIsBreakpointDown('sm');

  const {
    handleReset,
    handleSubmit,
    isValid,
    isSubmitting
  } = useFormikContext();

  return (
    <EditableCard
      editable={editable}
      dataQeId="Project_Financials"
      fullScreen={fullScreen}
      ariaLabelKey="projectFinancialsCard.financialsCardDialog"
    >
      <ReadOnly
        avatar={avatar}
        title={title}
        isLoading={isProjectLoading}
        classes={cardHeaderClass}
        additionalInfo={additionalInfo}
      >
        <ReadOnlyContent>
          <FinancialsReadOnly projectPermissions={projectPermissions} />
        </ReadOnlyContent>
      </ReadOnly>
      <Edit
        onSave={handleSubmit}
        onCancel={handleReset}
        saveable={isValid && !isSubmitting}
      >
        <EditTitle>
          <FinancialCardEditableTitle />
        </EditTitle>
        <EditContent>
          <FinancialsEditable projectPermissions={projectPermissions} />
        </EditContent>
      </Edit>
    </EditableCard>
  );
};

FinancialsCard.propTypes = {
  projectPermissions: PropTypes.object,
  projectCurrency: PropTypes.object,
  editable: PropTypes.bool,
  isProjectLoading: PropTypes.bool
};

const FinancialsCardForm = ({
  onSetCurrencyChanged,
  projectUri,
  costType,
  budgetHours,
  totalEstimatedContract,
  budgetedCost,
  resourceBudgetedCost,
  expenseBudgetedCost,
  estimatedHours,
  estimatedCost,
  projectCurrency,
  editable,
  projectPermissions,
  isProjectLoading
}) => {
  const { formatMessage } = useIntl();
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();
  const { initialValues, validationSchema, onSubmit } = useFormState({
    onSetCurrencyChanged,
    projectUri,
    budgetHours,
    totalEstimatedContract,
    budgetedCost,
    estimatedHours,
    estimatedCost,
    projectCurrency,
    costType,
    resourceBudgetedCost,
    expenseBudgetedCost,
    formatMessage,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FinancialsCard
        editable={editable}
        projectPermissions={projectPermissions}
        projectCurrency={projectCurrency}
        isProjectLoading={isProjectLoading}
      />
    </Formik>
  );
};

FinancialsCardForm.propTypes = {
  projectPermissions: PropTypes.object,
  editable: PropTypes.bool,
  projectCurrency: PropTypes.object,
  isProjectLoading: PropTypes.bool,
  onSetCurrencyChanged: PropTypes.func,
  projectUri: PropTypes.string,
  costType: PropTypes.string,
  budgetHours: PropTypes.number,
  totalEstimatedContract: PropTypes.object,
  budgetedCost: PropTypes.object,
  resourceBudgetedCost: PropTypes.object,
  expenseBudgetedCost: PropTypes.object,
  estimatedHours: PropTypes.number,
  estimatedCost: PropTypes.object
};

export default FinancialsCardForm;
