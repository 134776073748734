import { DateTime } from 'luxon';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { useGetChartDates as useGetChartDatesFromAnchorDate } from '~/modules/resourcing/common/hooks/useGetChartDates';

export const getDefaultDate = (
  {
    resourceRequestsMinMaxDateAndStatuses,
    resourceAllocationsMinMaxDateAndStatuses
  },
  today
) => {
  const endOfPlan = DateTime.max(
    mapIsoStringtoUtcObject(resourceRequestsMinMaxDateAndStatuses?.maxDate),
    mapIsoStringtoUtcObject(resourceAllocationsMinMaxDateAndStatuses?.maxDate)
  );

  const startOfPlan = DateTime.min(
    mapIsoStringtoUtcObject(resourceRequestsMinMaxDateAndStatuses?.minDate),
    mapIsoStringtoUtcObject(resourceAllocationsMinMaxDateAndStatuses?.minDate)
  );

  const anchorDate =
    endOfPlan && today > endOfPlan
      ? endOfPlan
      : startOfPlan && today < startOfPlan
      ? startOfPlan
      : today;

  return anchorDate;
};

export const useGetChartDates = ({
  width,
  scale,
  resourceRequestsMinMaxDateAndStatuses = {},
  resourceAllocationsMinMaxDateAndStatuses = {}
}) => {
  const today = DateTime.utc();

  const anchorDate = getDefaultDate(
    {
      resourceRequestsMinMaxDateAndStatuses,
      resourceAllocationsMinMaxDateAndStatuses
    },
    today
  );

  return useGetChartDatesFromAnchorDate({ width, scale, anchorDate });
};

export default useGetChartDates;
