import { useCallback } from 'react';
import { compareEditorValue } from './util';

export const useTaskAllocationOnChangeHandlers = ({
  setAllocatedHours,
  isHoursMode,
  scheduledHours,
  initialAllocatedHours,
  onAllocationChange,
  handlePeriodUpdate,
  handleNextPeriod,
  handlePreviousPeriod
}) => {
  const getAllocatedHours = useCallback(
    event => {
      const value =
        event.target && event.target.value ? parseFloat(event.target.value) : 0;

      return isHoursMode ? value : (value * scheduledHours) / 100;
    },
    [isHoursMode, scheduledHours]
  );

  const handleAllocationChange = useCallback(
    event => {
      const isInputSame = compareEditorValue({
        isHoursMode,
        initialAllocatedHours,
        scheduledHours,
        inputValue: parseFloat(event.target.value)
      });

      if (!isInputSame) {
        const allocatedValue = getAllocatedHours(event);

        setAllocatedHours(allocatedValue);

        onAllocationChange(allocatedValue);
      }
    },
    [
      getAllocatedHours,
      initialAllocatedHours,
      isHoursMode,
      onAllocationChange,
      scheduledHours,
      setAllocatedHours
    ]
  );

  const handleHoursChange = useCallback(
    event => {
      setAllocatedHours(getAllocatedHours(event));
    },
    [getAllocatedHours, setAllocatedHours]
  );

  const handleOnBlur = useCallback(
    event => {
      handleAllocationChange(event);

      if (handlePeriodUpdate) {
        handlePeriodUpdate();
        event.preventDefault();
      }
    },
    [handleAllocationChange, handlePeriodUpdate]
  );

  const handleHoursKeyPress = useCallback(
    event => {
      if (handlePeriodUpdate && event.key === 'Enter') {
        handleOnBlur(event);
        event.preventDefault();
      }
    },
    [handlePeriodUpdate, handleOnBlur]
  );

  const handleHoursKeyDown = useCallback(
    event => {
      const allocatedValue = getAllocatedHours(event);

      if (event.key !== 'Tab') return;
      handleAllocationChange(event);
      if (event.shiftKey && handlePreviousPeriod) {
        event.preventDefault();

        handlePreviousPeriod(allocatedValue);
      } else if (!event.shiftKey && handleNextPeriod) {
        event.preventDefault();
        handleNextPeriod(allocatedValue);
      }
    },
    [
      getAllocatedHours,
      handleAllocationChange,
      handlePreviousPeriod,
      handleNextPeriod
    ]
  );

  return {
    handleOnBlur,
    handleHoursChange,
    handleHoursKeyDown,
    handleHoursKeyPress
  };
};
