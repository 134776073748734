import { useGesture } from 'react-with-gesture';
import Tooltip from '@material-ui/core/Tooltip';
import DragIndicatorIcon from '@material-ui/icons/DragIndicatorSharp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { DateTime } from 'luxon';
import { Date } from '~/modules/common/components';
import CHART_HANDLE_VARIANT_ENUM from './ChartHandleVariantEnum';
import useEstimatedDate from './useEstimatedDate';
import useOnEstimatedDateChangeHandler from './useOnEstimatedDateChangeHandler';
import useStyles from './useStyles';

export const ChartResizeHandle = memo(
  ({
    classes: classesOverrides,
    className,
    variant,
    displayLabel,
    onValueChange,
    value,
    limit,
    chartStartDate,
    scale,
    leftComponentWidth
  }) => {
    const [, { delta, down }] = useGesture();
    const classes = useStyles({ classes: classesOverrides });
    const { dynamicStyle, estimatedDate } = useEstimatedDate({
      delta,
      down,
      value,
      limit,
      variant,
      chartStartDate,
      scale,
      leftComponentWidth
    });

    useOnEstimatedDateChangeHandler({ down, onValueChange, estimatedDate });

    return (
      <Tooltip
        title={
          <>
            <div>{displayLabel}</div>
            <Date value={estimatedDate} className={classes.tooltip} />
          </>
        }
      >
        <div
          className={classNames(
            classes.root,
            {
              [classes.rootActive]: down,
              [classes.rootLeft]: variant === CHART_HANDLE_VARIANT_ENUM.LEFT,
              [classes.rootRight]: variant === CHART_HANDLE_VARIANT_ENUM.RIGHT
            },
            className
          )}
          style={dynamicStyle}
        >
          <DragIndicatorIcon className={classes.handleIcon} />
        </div>
      </Tooltip>
    );
  }
);

ChartResizeHandle.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    CHART_HANDLE_VARIANT_ENUM.LEFT,
    CHART_HANDLE_VARIANT_ENUM.RIGHT
  ]).isRequired,
  displayLabel: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(DateTime)
  ]),
  limit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(DateTime)
  ]),
  chartStartDate: PropTypes.instanceOf(DateTime),
  scale: PropTypes.string,
  leftComponentWidth: PropTypes.number,
  onValueChange: PropTypes.func
};

export default ChartResizeHandle;
