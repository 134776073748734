import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TimesheetIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 5,1 V 3 H 2 v 17 h 8.134765625 C 9.857957251543201,19.37300042502692 9.6712159092801,18.70064357733422 9.576171875,18 H 4 V 8 h 14 v 1.576171875 c 0.70064357733422,0.09504403428009 1.37300042502693,0.281785376543207 2,0.55859375 V 3 H 17 V 1 H 15 V 3 H 7 V 1 Z m 1,9 v 2 h 2 v -2 z m 4,0 v 2 h 1.4375 C 11.61543333678923,11.80240314965755 11.80240314965755,11.61543333678922 12,11.4375 V 10 Z m 4,0 v 0.134765625 C 14.11175117182941,10.08542961052612 14.22732917869362,10.0439391040497 14.341796875,10 Z" />
      <path d="m 17,11 c -3.3,0 -6,2.7 -6,6 0,3.29999999999999 2.7,6 6,6 3.3,0 6,-2.70000000000001 6,-6 0,-3.3 -2.7,-6 -6,-6 z m -0.798828125,2.80078125 H 17.5 v 3.205078125 l 2.77734375,1.66796875 -0.693359375,1.12109375 -3.3828125,-2.083984375 z" />
    </>
  </SvgIcon>
));

export default TimesheetIcon;
