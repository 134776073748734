import { useCallback } from 'react';
import { v4 } from 'uuid';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';
import { useMeContext } from '~/modules/me/useMeContext';
import { getTodayForUser } from '~/modules/common/dates/today';
import { getEffectiveRateFromScheduleEntries } from '~/modules/projects/project/utils';
import { GET_CURRENT_BILLING_RATE_FOR_USER, PROJECT_ROLE } from './query';

const rateTypeReferenceKeys = {
  projectRate: 'projectReference',
  roleRates: 'roleReference',
  userRates: 'userReference'
};

export const useRateEditHandlers = ({
  setFieldValue,
  rateTypeKey,
  rateTypeReferenceKey,
  rateIndex,
  entryIndex,
  scheduleEntries
}) => {
  const me = useMeContext();
  const client = useApolloClient();
  const { baseCurrency } = me;
  const todayForUser = getTodayForUser(me);

  return {
    onAddRateType: useCallback(
      rateType => {
        const newRate = {
          billingScheduleEntries: [
            {
              isAddMode: true,
              entryId: v4(),
              effectiveDate: null,
              amount: { amount: 0, currency: baseCurrency }
            }
          ],
          costScheduleEntries: [],
          [rateTypeReferenceKeys[rateType]]: null
        };

        setFieldValue(`${rateType}`, [newRate], false);
      },
      [setFieldValue, baseCurrency]
    ),
    onRemoveAllEntries: useCallback(
      ({ field }) => {
        setFieldValue(field, []);
      },
      [setFieldValue]
    ),
    onRemoveRateEntry: useCallback(
      entryId => {
        const updatedEntries = scheduleEntries.filter(
          entry => entry.entryId !== entryId
        );

        setFieldValue(
          `${rateTypeKey}[${rateIndex}].billingScheduleEntries`,
          updatedEntries
        );
        setFieldValue(`${rateTypeKey}[${rateIndex}].isDirty`, true);
      },
      [setFieldValue, scheduleEntries, rateTypeKey, rateIndex]
    ),
    onAddNewRate: useCallback(
      ({ field, rates }) => {
        const updatedRates = [
          ...rates,
          {
            billingScheduleEntries: [
              {
                entryId: v4(),
                amount: { amount: 0, currency: baseCurrency }
              }
            ],
            costScheduleEntries: [],
            isDirty: true
          }
        ];

        setFieldValue(field, updatedRates);
      },
      [setFieldValue, baseCurrency]
    ),
    onRemoveRate: useCallback(() => {
      setFieldValue(`${rateTypeKey}[${rateIndex}]`, {
        id: v4(),
        billingScheduleEntries: [],
        costScheduleEntries: [],
        [rateTypeReferenceKey]: {}
      });
    }, [setFieldValue, rateTypeKey, rateIndex, rateTypeReferenceKey]),
    onAddNewRateEntry: useCallback(() => {
      const updatedEntries = [
        {
          entryId: v4(),
          isNewEntry: true,
          amount: { amount: 0, currency: baseCurrency }
        },
        ...scheduleEntries
      ];

      setFieldValue(
        `${rateTypeKey}[${rateIndex}].billingScheduleEntries`,
        updatedEntries
      );
      setFieldValue(`${rateTypeKey}[${rateIndex}].isDirty`, true);
    }, [setFieldValue, scheduleEntries, rateTypeKey, rateIndex, baseCurrency]),
    onCurrencyChange: useCallback(
      async value => {
        setFieldValue(
          `${rateTypeKey}[${rateIndex}].billingScheduleEntries[${entryIndex}].amount.currency`,
          value
        );
        setFieldValue(`${rateTypeKey}[${rateIndex}].isDirty`, true);
      },
      [setFieldValue, rateTypeKey, rateIndex, entryIndex]
    ),
    onAmountChange: useCallback(
      async ({ target: { value } }) => {
        setFieldValue(
          `${rateTypeKey}[${rateIndex}].billingScheduleEntries[${entryIndex}].amount.amount`,
          value
        );
        setFieldValue(`${rateTypeKey}[${rateIndex}].isDirty`, true);
      },
      [setFieldValue, rateTypeKey, rateIndex, entryIndex]
    ),
    onEffectiveDateChange: useCallback(
      async value => {
        await setFieldValue(
          `${rateTypeKey}[${rateIndex}].billingScheduleEntries[${entryIndex}].effectiveDate`,
          DateTime.fromObject(value)
        );
        setFieldValue(
          `${rateTypeKey}[${rateIndex}].billingScheduleEntries[${entryIndex}].isNewEntry`,
          false
        );
        setFieldValue(`${rateTypeKey}[${rateIndex}].isDirty`, true);
      },
      [setFieldValue, rateTypeKey, rateIndex, entryIndex]
    ),
    onUserChange: useCallback(
      async (value, _) => {
        const { data } = value
          ? await client.query({
              query: GET_CURRENT_BILLING_RATE_FOR_USER,
              variables: {
                userId: value.id
              }
            })
          : {};

        const updatedRate = {
          billingScheduleEntries: [
            {
              entryId: v4(),
              amount: data?.getCurrentBillingRateForUser?.rate
                ? data.getCurrentBillingRateForUser.rate
                : {
                    amount: 0,
                    currency: baseCurrency
                  }
            }
          ],
          costScheduleEntries: [],
          [rateTypeReferenceKey]: value,
          isDirty: true,
          id: value && value.id
        };

        await setFieldValue(`${rateTypeKey}[${rateIndex}]`, updatedRate);
      },
      [
        client,
        baseCurrency,
        rateTypeReferenceKey,
        setFieldValue,
        rateTypeKey,
        rateIndex
      ]
    ),
    onRoleChange: useCallback(
      async value => {
        const { data } = await client.query({
          query: PROJECT_ROLE,
          variables: {
            id: value.id
          }
        });

        const rate = getEffectiveRateFromScheduleEntries(
          data?.role?.billingScheduleEntries,
          todayForUser
        );

        const updatedRate = {
          billingScheduleEntries: [
            {
              entryId: v4(),
              amount: rate?.amount || { amount: 0, currency: baseCurrency }
            }
          ],
          costScheduleEntries: [],
          [rateTypeReferenceKey]: value,
          id: value && value.id,
          isDirty: true
        };

        await setFieldValue(`${rateTypeKey}[${rateIndex}]`, updatedRate);
      },
      [
        client,
        todayForUser,
        baseCurrency,
        rateTypeReferenceKey,
        setFieldValue,
        rateTypeKey,
        rateIndex
      ]
    )
  };
};
