import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECTS_FOR_REVENUE_QUERY = gql`
  query GetPageOfProjectsToFilterRevenueItems(
    $page: Int!
    $pagesize: Int!
    $projectFilter: ProjectFilter
  ) {
    projects(page: $page, pagesize: $pagesize, projectFilter: $projectFilter) {
      id
      displayText
    }
  }
`;

export const useProjectOptionsForRevenueItems = searchTerm => {
  const { data, loading } = useQuery(GET_PROJECTS_FOR_REVENUE_QUERY, {
    fetchPolicy: 'cache-and-network',
    context: {
      debounceKey: 'project-search-for-revenue',
      debounceTimeout: 250
    },
    variables: {
      page: 1,
      pagesize: 100,
      projectFilter: {
        text: searchTerm
      }
    }
  });

  const projects = !loading
    ? data?.projects?.map(project => ({
        ...project,
        value: project.id,
        label: project.displayText,
        key: project.displayText
      }))
    : [];

  return {
    projects,
    loading
  };
};

export const searchableProjects = ({ client }) => async searchTerm => {
  const result = await client.query({
    query: GET_PROJECTS_FOR_REVENUE_QUERY,
    variables: {
      page: 1,
      pagesize: 100,
      projectFilter: {
        text: searchTerm
      }
    }
  });

  return result.data.projects.map(project => ({
    ...project,
    value: project.id,
    label: project.displayText,
    name: project.displayText,
    key: project.displayText
  }));
};

export const useSearchableProjectOptionsForRevenueItems = () => {
  const apolloClient = useApolloClient();

  return {
    fetchOptions: searchableProjects({
      client: apolloClient
    })
  };
};
