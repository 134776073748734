import { withStyles } from '@material-ui/core';

const styles = theme => ({
  expansionPanel: {
    boxShadow: 'none',
    padding: 0,
    borderTop: `1px solid ${theme.palette.grey[100]}`
  },
  expansionPanelDetails: {
    display: 'block',
    padding: 0
  },
  expansionPanelSummary: {
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1.fontSize,
    flexDirection: 'row-reverse',
    width: '100%',
    padding: 0,
    '&$expanded': {
      minHeight: 0,
      padding: 0
    }
  },
  expansionPanelSummaryTitle: {
    flexGrow: 1
  },
  amountTotal: {
    paddingRight: theme.spacing(1),
    ...theme.typography.body2,
    color: theme.palette.text.primary
  },
  expansionPanelSummaryContent: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    '&$expanded': {
      margin: 0,
      '& $amountTotal': {
        display: 'none'
      }
    }
  },
  expanded: {
    paddingRight: theme.spacing(1.5)
  },
  expandIcon: {
    padding: 0
  }
});

export default withStyles(styles);
