import { ProjectManagementType } from '~/types';

export const MANAGED_URI = 'urn:replicon:project-management-type:managed';
export const UNMANAGED_URI = 'urn:replicon:project-management-type:unmanaged';

export const getProjectTypeUri = type =>
  type === ProjectManagementType.Managed ? MANAGED_URI : UNMANAGED_URI;

export const getProjectTypes = formatMessage => [
  {
    displayText: formatMessage({ id: 'projectTypes.managed' }),
    id: MANAGED_URI
  },
  {
    displayText: formatMessage({ id: 'projectTypes.unmanaged' }),
    id: UNMANAGED_URI
  }
];
