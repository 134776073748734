import { useCallback } from 'react';
import { onChange } from '../../enhancers/facetHelpers';

const separator = '–';

export const formatTotalKeyMinMax = (min, max) => {
  const parsedMinValue = parseFloat(min);
  const parsedMaxValue = parseFloat(max);

  if (isNaN(parsedMinValue) && isNaN(parsedMaxValue)) {
    return undefined;
  }

  return {
    key: `${
      !isNaN(parsedMinValue) ? parsedMinValue.toFixed(2) : ''
    }${separator}${!isNaN(parsedMaxValue) ? parsedMaxValue.toFixed(2) : ''}`,
    minValue: !isNaN(parsedMinValue) ? parsedMinValue : undefined,
    maxValue: !isNaN(parsedMaxValue) ? parsedMaxValue : undefined
  };
};

const useScoreTotalHandlers = ({ setSelected, minValue, maxValue }) => {
  const onMinChangeHandler = useCallback(
    e => {
      onChange({
        setSelected,
        selectedOptions: formatTotalKeyMinMax(e.target.value, maxValue)
      });
    },
    [maxValue, setSelected]
  );

  const onMinBlurHandler = useCallback(
    e => {
      onChange({
        setSelected,
        selectedOptions: formatTotalKeyMinMax(
          e.target.value,
          maxValue !== '' && maxValue < e.target.value
            ? e.target.value
            : maxValue
        )
      });
    },
    [maxValue, setSelected]
  );

  const onMaxChangeHandler = useCallback(
    e => {
      onChange({
        setSelected,
        selectedOptions: formatTotalKeyMinMax(minValue, e.target.value)
      });
    },
    [minValue, setSelected]
  );

  const onMaxBlurHandler = useCallback(
    e => {
      onChange({
        setSelected,
        selectedOptions: formatTotalKeyMinMax(
          minValue !== '' && minValue > e.target.value
            ? e.target.value
            : minValue,
          e.target.value
        )
      });
    },
    [minValue, setSelected]
  );

  const onClearHandler = useCallback(() => {
    onChange({
      setSelected,
      selectedOptions: undefined
    });
  }, [setSelected]);

  return {
    onMinChangeHandler,
    onMaxChangeHandler,
    onMinBlurHandler,
    onMaxBlurHandler,
    onClearHandler
  };
};

export default useScoreTotalHandlers;
