import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  exchangeRate: {
    lineHeight: 1,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

export const ExchangeRate = ({ sourceAmount, targetAmount }) => {
  const classes = useStyles();

  const exchangeRate = useMemo(
    () => (targetAmount.amount / sourceAmount.amount).toFixed(4),
    [targetAmount.amount, sourceAmount.amount]
  );

  return (
    <Typography
      className={classes.exchangeRate}
      component="div"
      variant="inherit"
    >
      {sourceAmount.currency.symbol} <FormattedMessage id="billDetails.one" />{' '}
      {' = '} {targetAmount.currency.symbol} {exchangeRate}
    </Typography>
  );
};

ExchangeRate.propTypes = {
  sourceAmount: PropTypes.object.isRequired,
  targetAmount: PropTypes.object.isRequired
};

export default ExchangeRate;
