import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { PillChartColors } from './colors';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: theme.spacing(3),
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'nowrap',
    height: theme.spacing(6),
    lineHeight: theme.spacing(6),
    borderRadius: theme.spacing(3),
    overflow: 'hidden'
  },
  segment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  value: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold
  },
  overEstimation: {
    backgroundColor: PillChartColors.overEstimation,
    color: theme.palette.getContrastText(PillChartColors.overEstimation)
  },
  underEstimation: {
    backgroundColor: PillChartColors.underEstimation,
    color: theme.palette.getContrastText(PillChartColors.underEstimation)
  },
  onTrack: {
    backgroundColor: PillChartColors.onTrack,
    color: theme.palette.getContrastText(PillChartColors.onTrack)
  }
}));

export const Chart = ({ segments }) => {
  const classes = useStyles();

  const tasksTotal = segments.reduce(
    (total, segment) => total + segment.value,
    0
  );

  if (!tasksTotal) return null;

  return (
    <ul className={classes.root}>
      {segments.map(segment => (
        <li
          key={segment.key}
          className={classNames(
            classes.segment,
            classes[`${segment.className}`]
          )}
          style={barColorStyle(segment.value, tasksTotal)}
        >
          <Typography
            data-qe-id={`pill_${segment.key}`}
            className={classes.value}
          >
            {segment.value}
          </Typography>
        </li>
      ))}
    </ul>
  );
};

const barColorStyle = (value, total) => ({
  width: `${(value / total) * 100}%`
});

Chart.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.node,
      className: PropTypes.oneOf([
        'overEstimation',
        'underEstimation',
        'onTrack'
      ])
    })
  ).isRequired
};

export default Chart;
