import { useState, useCallback, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_KEY_VALUE_SETTINGS,
  PUT_KEY_VALUE_SETTINGS
} from './useKeyValueSettings';

export const onCompleted = setQuickFilterInternal => ({
  keyValueSettings: quickFilterSettings
}) => {
  setQuickFilterInternal(quickFilterSettings);
};

export const setQuickFilter = (
  key,
  setQuickFilterInternal,
  putQuickFilterSettings,
  updateQuery
) => settings => {
  setQuickFilterInternal(settings);
  putQuickFilterSettings({
    variables: { input: { key, settings } }
  });
  updateQuery(() => ({
    keyValueSettings: {
      ...settings
    }
  }));
};

export const useQuickFilterState = ({ filterKey, defaultValue }) => {
  const [quickFilter, setQuickFilterInternal] = useState({
    filterByAccess: defaultValue,
    filterByStatus: null
  });

  const { updateQuery, loading: loadingQuickFilters } = useQuery(
    GET_KEY_VALUE_SETTINGS,
    {
      variables: { key: filterKey },
      onCompleted: onCompleted(setQuickFilterInternal)
    }
  );

  const [putQuickFilterSettings] = useMutation(PUT_KEY_VALUE_SETTINGS);

  const $setQuickFilter = useCallback(
    setQuickFilter(
      filterKey,
      setQuickFilterInternal,
      putQuickFilterSettings,
      updateQuery
    ),
    [filterKey, setQuickFilterInternal, putQuickFilterSettings, updateQuery]
  );

  return useMemo(
    () => [
      {
        filterByAccess:
          (quickFilter && quickFilter.filterByAccess) || defaultValue,
        filterByStatus: (quickFilter && quickFilter.filterByStatus) || null
      },
      loadingQuickFilters ? null : $setQuickFilter,
      loadingQuickFilters
    ],
    [quickFilter, loadingQuickFilters, $setQuickFilter]
  );
};

export default useQuickFilterState;
