import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  TextField,
  Collapse,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { getError, hasError } from '~/util';

const useStyles = makeStyles(theme => ({
  toRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  collapsibleContainer: {
    padding: theme.spacing(1),
    width: '100%'
  },
  ccButton: {
    flexGrow: 0,
    flexShrink: 0,
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4) + 3,
    paddingRight: theme.spacing(4) + 3,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: theme.spacing(-1)
    }
  },
  messageContainer: { position: 'relative' },
  circularProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto'
  }
}));

const sendBillFormContentKeys = {
  from: <FormattedMessage id="sendBillDialog.from" />,
  to: <FormattedMessage id="sendBillDialog.to" />,
  cc: <FormattedMessage id="sendBillDialog.cc" />,
  subject: <FormattedMessage id="sendBillDialog.subject" />,
  message: <FormattedMessage id="sendBillDialog.message" />
};

export const SendBillForm = ({
  errors,
  sendingError,
  values: { emailTo, emailFrom, emailCc, subject, message },
  onSubjectFieldChange,
  onEmailToFieldChange,
  onEmailFromFieldChange,
  onMessageFieldChange,
  onEmailCcFieldChange,
  loading
}) => {
  const classes = useStyles();
  const [showCc, setShowCc] = useState(false);

  const ccInputRef = useRef(null);
  const InputLabelProps = useMemo(() => ({ shrink: true }), []);

  useEffect(() => {
    if (showCc && ccInputRef && ccInputRef.current) ccInputRef.current.focus();
  }, [ccInputRef, showCc]);

  const handleShowCc = useCallback(() => {
    setShowCc(true);
  }, [setShowCc]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="sendBillDialog.email.from"
          required
          value={emailFrom}
          autoFocus
          onChange={onEmailFromFieldChange}
          label={sendBillFormContentKeys.from}
          fullWidth
          variant="filled"
          error={
            hasError(errors, 'emailFrom') || hasError(sendingError, 'reason')
          }
          helperText={
            getError(errors, 'emailFrom') || getError(sendingError, 'reason')
          }
        />
      </Grid>
      <Grid item xs={showCc ? 12 : 10}>
        <TextField
          id="sendBillDialog.email.to"
          required
          value={emailTo}
          onChange={onEmailToFieldChange}
          fullWidth
          label={sendBillFormContentKeys.to}
          variant="filled"
          error={hasError(errors, 'emailTo')}
          helperText={getError(errors, 'emailTo')}
        />
      </Grid>
      <div className={classes.toRow}>
        {showCc ? null : (
          <Grid item xs={2}>
            <Button
              size="small"
              className={classes.ccButton}
              onClick={handleShowCc}
            >
              {sendBillFormContentKeys.cc}
            </Button>
          </Grid>
        )}
      </div>
      <Collapse
        className={classes.collapsibleContainer}
        in={showCc}
        mountOnEnter
        unmountOnExit
      >
        <Grid item xs={12}>
          <TextField
            id="sendBillDialog.email.cc"
            value={emailCc}
            fullWidth
            inputRef={ccInputRef}
            onChange={onEmailCcFieldChange}
            label={sendBillFormContentKeys.cc}
            variant="filled"
            error={hasError(errors, 'emailCc')}
            helperText={getError(errors, 'emailCc')}
          />
        </Grid>
      </Collapse>
      <Grid item xs={12}>
        <TextField
          id="sendBillDialog.email.subject"
          required
          value={subject}
          label={sendBillFormContentKeys.subject}
          onChange={onSubjectFieldChange}
          variant="filled"
          fullWidth
          error={hasError(errors, 'subject')}
          helperText={getError(errors, 'subject')}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.messageContainer}>
          {loading ? (
            <CircularProgress className={classes.circularProgress} size={24} />
          ) : null}
          <TextField
            id="sendBillDialog.email.message"
            required
            value={!loading ? message : null}
            onChange={onMessageFieldChange}
            label={sendBillFormContentKeys.message}
            multiline
            variant="filled"
            InputLabelProps={InputLabelProps}
            fullWidth
            error={hasError(errors, 'message')}
            helperText={getError(errors, 'message')}
          />
        </div>
      </Grid>
    </Grid>
  );
};

SendBillForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  sendingError: PropTypes.object,
  onSubjectFieldChange: PropTypes.func.isRequired,
  onEmailToFieldChange: PropTypes.func.isRequired,
  onEmailFromFieldChange: PropTypes.func.isRequired,
  onMessageFieldChange: PropTypes.func.isRequired,
  onEmailCcFieldChange: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default SendBillForm;
