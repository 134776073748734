export const getResourceRequestStatusStyles = theme => ({
  rootDraft: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.draft.main
    ),
    backgroundColor: theme.palette.resourceRequest.draft.main
  },
  rootSubmitted: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.pending.main
    ),
    backgroundColor: theme.palette.resourceRequest.pending.main
  },
  rootTentative: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.proposed.main
    ),
    backgroundColor: theme.palette.resourceRequest.proposed.main
  },
  rootRejected: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.resourceRejected.main
    ),
    backgroundColor: theme.palette.resourceRequest.resourceRejected.main
  },
  rootAllocationRejected: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.resourceRejected.main
    ),
    backgroundColor: theme.palette.resourceRequest.resourceRejected.main
  },
  rootComplete: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.complete.main
    ),
    backgroundColor: theme.palette.resourceRequest.complete.main
  },
  rootToBeHired: {
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.toBeHired.main
    ),
    backgroundColor: theme.palette.resourceRequest.toBeHired.main
  }
});
