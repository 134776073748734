import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const SET_TASK_OWNER = gql`
  mutation BulkReleaseTaskOwners($inputs: [SetTaskAssignedUserInput!]!) {
    releaseTaskOwners(inputs: $inputs) {
      taskId
    }
  }
`;

export const useReleaseTaskOwners = () => {
  const [releaseTaskOwners] = useMutation(SET_TASK_OWNER);

  return {
    releaseTaskOwners: (tasks, userId = null, userRoleId = null) => {
      return releaseTaskOwners({
        variables: {
          inputs: tasks.map(task => ({
            taskId: task.id,
            userId,
            userRoleId
          }))
        }
      });
    },
    setTaskOwners: async (tasks, userId, userRoleId = null) => {
      if (!userId) return null;

      return releaseTaskOwners({
        variables: {
          inputs: tasks.map(task => ({
            taskId: task.id,
            userId,
            userRoleId
          }))
        }
      });
    }
  };
};

export default useReleaseTaskOwners;
