import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import User from '~/modules/common/components/User';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.table.body,
    textDecoration: 'none'
  },
  user: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: 320,
    minWidth: 200,
    whiteSpace: 'normal',
    wordWrap: 'breakword',
    wordBreak: 'breakword'
  }
}));

export const UserAvatarColumn = ({
  classes: classesOverride,
  record: resourcePool
}) => {
  const classes = useStyles({ classes: classesOverride });
  const userClasses = useMemo(() => ({ name: classes.user }), [classes.user]);

  return (
    <Link className={classes.link} to={`/resource-pool/${resourcePool.slug}`}>
      <User user={resourcePool} classes={userClasses} />
    </Link>
  );
};

UserAvatarColumn.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired
};

export default UserAvatarColumn;
