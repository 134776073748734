import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PropTypes } from 'prop-types';

export const INVOICE_CALCULATION_STATUS_QUERY = gql`
  query isBillDataReflectedInAvailableToBillData($billId: String!) {
    isBillDataReflectedInAvailableToBillData(billId: $billId)
  }
`;

export const useIsBillDataReflectedOnAvailableToBill = ({
  billId = null
} = {}) => {
  const { refetch: fetchCalculationStatus } = useQuery(
    INVOICE_CALCULATION_STATUS_QUERY,
    {
      variables: {
        billId
      },
      skip: !billId,
      fetchPolicy: 'network-only'
    }
  );

  return { fetchCalculationStatus };
};
useIsBillDataReflectedOnAvailableToBill.propTypes = {
  invoiceId: PropTypes.String
};
export default useIsBillDataReflectedOnAvailableToBill;
