import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tag: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(30)
    }
  }
}));

export default useStyles;
