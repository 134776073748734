import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { Money2, MoneyValue } from '~/modules/common/components';
import AdvancedRateCardSkeleton from './AdvancedRateCardSkeleton';

const useStyles = makeStyles(theme => ({
  amountField: {
    '& input': {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75)
    },
    '& .MuiInputAdornment-marginDense': {
      paddingBottom: theme.spacing(1.75)
    }
  },
  amountReadOnlyField: {
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

export const RateEditor = ({
  record,
  field,
  index,
  column: { onAmountChange, onCurrencyChange },
  readOnly,
  loadingMore
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { amount, currency } = record[field] || {};

  const handleAmountChange = useCallback(
    e => {
      if (onAmountChange && e && e.target && amount !== e.target.value) {
        onAmountChange(index, e.target.value || 0);
      }
    },
    [index, onAmountChange, amount]
  );

  const handleCurrencyChange = useCallback(
    value => {
      if (onCurrencyChange) {
        onCurrencyChange(index, value);
      }
    },
    [index, onCurrencyChange]
  );

  if (loadingMore) return <AdvancedRateCardSkeleton />;

  return (
    <>
      {readOnly ? (
        <MoneyValue
          className={classes.amountReadOnlyField}
          money={record[field]}
          tabIndex={0}
        />
      ) : (
        <Money2
          className={classes.amountField}
          onAmountChange={handleAmountChange}
          onCurrencyChange={handleCurrencyChange}
          amount={amount}
          currency={currency}
          disabled={record.disabled || record.isDimensionDeleted}
          currencyEditable={!record.disabled}
          ariaLabel={formatMessage({
            id: 'advancedRateCard.billingRate'
          })}
          valueDataQeId="AdvancedRateCard_BillingRate"
        />
      )}
    </>
  );
};

RateEditor.propTypes = {
  record: PropTypes.object,
  field: PropTypes.string,
  column: PropTypes.object,
  index: PropTypes.number,
  readOnly: PropTypes.bool,
  loadingMore: PropTypes.bool
};

export default RateEditor;
