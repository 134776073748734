import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: ({ onClick, canView }) =>
        onClick && canView ? 'pointer' : 'default'
    },
    '& td': {
      fontSize: theme.typography.body2.fontSize
    }
  },
  cell: {
    maxHeight: theme.spacing(4.5),
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  }
}));

const DrilldownTableRow = ({
  className: rowClassName,
  columns,
  item,
  onClick,
  canView,
  idKey = 'slug'
}) => {
  const classes = useStyles({ onClick, canView });

  const onClickHandler = useCallback(
    ({ currentTarget }) => onClick({ currentTarget, item }),
    [onClick, item]
  );

  return (
    <TableRow
      className={classNames(classes.root, rowClassName)}
      role="row"
      id={item[idKey]}
      onClick={onClickHandler}
    >
      {columns.map(column => {
        const { key, align, className, transformValue } = column;

        return (
          <TableCell
            key={key}
            align={align}
            className={classNames(classes.cell, className)}
          >
            <column.component {...transformValue(item[key], item)} />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

DrilldownTableRow.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  canView: PropTypes.bool,
  idKey: PropTypes.string
};

export default DrilldownTableRow;
