import { useState, useCallback } from 'react';

export const useSending = () => {
  const [sending, setSending] = useState(false);
  const [sendingError, setSendingError] = useState(null);

  return {
    sending,
    sendingError,
    setSending: useCallback(state => {
      setSending(state);
    }, []),
    setSendingError: useCallback(error => {
      setSendingError(error);
    }, [])
  };
};

export default useSending;
