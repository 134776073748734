import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  ServiceCenterIcon,
  LocationIcon,
  EmployeeTypeIcon,
  DepartmentIcon,
  CostCenterIcon,
  DivisionIcon
} from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  label: { paddingLeft: theme.spacing(0.5) },
  labelIcon: {
    fontSize: theme.spacing(2.25),
    margin: theme.spacing(-0.5, 0),
    color: ({ omitColor }) =>
      omitColor ? undefined : theme.palette.text.secondary
  }
}));

export const useStyledGroupIcons = ({ omitColor } = {}) => {
  const classes = useStyles({ omitColor });

  return useMemo(
    () => ({
      serviceCenter: (
        <ServiceCenterIcon key="serviceCenter" className={classes.labelIcon} />
      ),
      location: <LocationIcon key="location" className={classes.labelIcon} />,
      costCenter: (
        <CostCenterIcon key="costCenter" className={classes.labelIcon} />
      ),
      department: (
        <DepartmentIcon key="department" className={classes.labelIcon} />
      ),
      employeeType: (
        <EmployeeTypeIcon key="employeeType" className={classes.labelIcon} />
      ),
      division: <DivisionIcon key="division" className={classes.labelIcon} />
    }),
    [classes.labelIcon]
  );
};

export default useStyledGroupIcons;
