import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { resolveURLIcon } from './resolveURLIcon';

export const URLIcon = ({ url, ...svgProps }) => {
  const { path } = resolveURLIcon(url);

  return (
    <SvgIcon {...svgProps}>
      <path d={path} />
    </SvgIcon>
  );
};

URLIcon.propTypes = {
  url: PropTypes.string
};

export default URLIcon;
