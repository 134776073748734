import get from 'lodash.get';

export const extractFirstValidationError = error =>
  get(error, 'graphQLErrors[0].extensions.exception.validationErrors');

export const getFirstErrorCode = error =>
  get(error, 'graphQLErrors[0].extensions.code');

export const getValidationErrorByUri = (error, failureUri) => {
  const validationErrors = extractFirstValidationError(error);

  return (
    validationErrors && validationErrors.find(v => v.failureUri === failureUri)
  );
};

export const getValidationErrorByDisplayText = (error, displayText) => {
  const validationErrors = extractFirstValidationError(error);

  return (
    validationErrors &&
    validationErrors.find(v => v.displayText === displayText)
  );
};

export const extractFirstErrorMessage = error =>
  get(error, 'graphQLErrors[0].message') || get(error, 'message') || error;
