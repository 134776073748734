import React from 'react';
import PropTypes from 'prop-types';
import {
  ResourceAllocationEditableUserItem,
  ResourceAllocationReadonlyUserItem
} from './components';

export const AllocatedResourceRequestUserItem = ({
  classes,
  qeIdTag,
  isAdjustedLoading,
  isReadonly,
  resourceAllocationLoad,
  resourceAllocation,
  resourceRequest,
  project
}) => (
  <>
    {!isReadonly && (
      <ResourceAllocationEditableUserItem
        classes={classes}
        qeIdTag={qeIdTag}
        resourceAllocation={resourceAllocation}
        resourceRequest={resourceRequest}
        resourceAllocationUser={resourceAllocation.user.user}
        resourceAllocationLoad={resourceAllocationLoad}
        project={project}
      />
    )}
    {isReadonly && (
      <ResourceAllocationReadonlyUserItem
        classes={classes}
        qeIdTag={qeIdTag}
        resourceAllocationUser={resourceAllocation.user.user}
        resourceAllocationLoad={resourceAllocationLoad}
        isAdjustedLoading={isAdjustedLoading}
        project={project}
      />
    )}
  </>
);

AllocatedResourceRequestUserItem.propTypes = {
  qeIdTag: PropTypes.string,
  isReadonly: PropTypes.bool.isRequired,
  resourceAllocation: PropTypes.object.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocationLoad: PropTypes.number.isRequired,
  isAdjustedLoading: PropTypes.bool,
  classes: PropTypes.object,
  project: PropTypes.object
};

export default React.memo(AllocatedResourceRequestUserItem);
