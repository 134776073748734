import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { LabelSharp as LabelIcon } from '@material-ui/icons';
import { TagsCard } from '~/modules/tags';

export const TagsIcon = <LabelIcon />;
export const TagsTitle = <FormattedMessage id="tags.title" />;

const PROJECT_TAGS_QUERY = gql`
  query ProjectTags($projectId: String!) {
    project(projectId: $projectId) {
      id
      tags {
        id
        tag {
          id
          name
        }
        value {
          id
          value
        }
      }
    }
  }
`;

const DEFAULT_TAGS_RESPONSE = [];

const ProjectTagsCard = ({ projectUri, ...rest }) => {
  const { data, loading } = useQuery(PROJECT_TAGS_QUERY, {
    variables: {
      projectId: projectUri
    }
  });

  const refetchQuery = useMemo(
    () => ({
      query: PROJECT_TAGS_QUERY,
      variables: {
        projectId: projectUri
      }
    }),
    [projectUri]
  );

  return (
    <TagsCard
      objectId={projectUri}
      showReadOnlyAvatar
      icon={TagsIcon}
      title={TagsTitle}
      refetchQuery={refetchQuery}
      tags={
        data && data.project && data.project.tags
          ? data.project.tags
          : DEFAULT_TAGS_RESPONSE
      }
      loading={loading}
      {...rest}
    />
  );
};

ProjectTagsCard.propTypes = {
  projectUri: PropTypes.string
};

export default ProjectTagsCard;
