import { invokeUpdateResourceAllocationForRequestsAggregateCache } from '~/modules/resourcing/enhancers/updateAllocationTotalsCache';
import { useUpdateResourceAllocationInternal } from '~/modules/resourcing/hooks/useUpdateResourceAllocation';
import useResourceRequestToolbarContext from './useResourceRequestToolbarContext';

export const useUpdateResourceAllocationForRequest = () => {
  const { isResourceActualModeEnabled } = useResourceRequestToolbarContext();

  return useUpdateResourceAllocationInternal({
    isResourceActualModeEnabled,
    refetchQuery: [],
    invokeUpdateFunction: invokeUpdateResourceAllocationForRequestsAggregateCache
  });
};
