import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { IconButton } from '@material-ui/core';

export const ScreenReadableIconWrapper = props => {
  const { formatMessage } = useIntl();

  const {
    children,
    buttonAriaLabel,
    iconAriaLabel,
    role,
    color,
    onClick
  } = props;

  return (
    <IconButton
      aria-label={formatMessage({ id: buttonAriaLabel })}
      onClick={onClick}
      color={color}
    >
      <span role={role} aria-label={formatMessage({ id: iconAriaLabel })}>
        {children}
      </span>
    </IconButton>
  );
};

ScreenReadableIconWrapper.propTypes = {
  children: PropTypes.object,
  onClick: PropTypes.func,
  buttonAriaLabel: PropTypes.string,
  iconAriaLabel: PropTypes.string,
  role: PropTypes.string,
  color: PropTypes.string
};

export default ScreenReadableIconWrapper;
