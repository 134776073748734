import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'yup';
import {
  STRING_MAX_LENGTH,
  exceededMaxLengthMessage
} from '~/modules/clients/client/ClientInfo/dialogs/validationSchemas/common';
import { buildCustomFieldValidationSchema } from '~/modules/customFields/utils';

const requiredMessage = {
  name: <FormattedMessage id="client.nameRequired" />,
  code: <FormattedMessage id="client.codeRequired" />
};

export const buildValidationSchema = ({
  customFieldDefinitions,
  intl,
  isCodeRequired = false,
  isNameRequired = true
}) =>
  object().shape({
    name: isNameRequired
      ? string()
          .trim()
          .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
          .required(requiredMessage.name)
      : string()
          .trim()
          .max(STRING_MAX_LENGTH, exceededMaxLengthMessage),

    code: isCodeRequired
      ? string()
          .trim()
          .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
          .required(requiredMessage.code)
      : string()
          .trim()
          .max(STRING_MAX_LENGTH, exceededMaxLengthMessage),

    customFieldValues: buildCustomFieldValidationSchema({
      customFieldDefinitions,
      intl
    })
  });
