import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 1, 1, 2),
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.main
  },
  countRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: theme.spacing(4.5)
  },
  headerContainer: {
    display: 'flex'
  },
  count: {
    fontSize: theme.typography.h5.fontSize,
    alignSelf: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: theme.spacing(1)
  },
  message: {
    fontSize: theme.typography.body2.fontSize,
    alignSelf: 'center'
  },
  bold: {
    fontSize: theme.typography.body2.fontSize,
    alignSelf: 'center',
    fontWeight: theme.typography.fontWeightMedium
  },
  subText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    alignSelf: 'center',
    marginTop: theme.spacing(-0.5)
  },
  chevron: {
    padding: theme.spacing(0),
    marginLeft: 'auto'
  }
}));

export default useStyles;
