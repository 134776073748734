import React from 'react';
import { PropTypes } from 'prop-types';

export const EditContent = ({ children }) => <>{children}</>;

EditContent.propTypes = {
  children: PropTypes.node
};

EditContent.displayName = 'EditContent';

export default EditContent;
