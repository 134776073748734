import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  getDateRangeFromScheduleRules,
  getResourceRequestDatesFromProjectDate,
  getTotalHours
} from '~/modules/resourcing/common/util';
import {
  dateToMidnightUTCString,
  getLuxonJsDateFormatFromMe,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import mergePeriodRulesIntoScheduleRules from '~/modules/resourcing/common/util/mergeScheduleUtil';
import useFulfilledResourceAllocationHandlersWithoutResourceRequest from '~/modules/resourcing/common/hooks/useFulfilledResourceAllocationHandlersWithoutResourceRequest';
import { ResourceAllocationStatus } from '~/types';
import { buildResourceAllocation } from '~/modules/resourcing/hooks/useCreateResourceAllocationHandler';
import { useUpdateResourceAllocation } from '~/modules/resourcing/hooks';
import useReleaseTaskOwners from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/hooks/useReleaseTaskOwners';
import { getUpdatedAllocatedUserList } from '~/modules/resourcing/common/util/allocationCacheUpdates';
import { getSessionStorage } from '~/modules/common/hooks';
import { useGetScheduledHoursForUserAndDateRangeCallback } from '~/modules/resourcing/common/hooks/useGetScheduleHoursForUserAndDateRange';
import { GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES } from '../../../hooks/useFetchResourcePlanUsersAndRoles';
import {
  tryResourcePlanAllocatedUsersAndRolesCachedQuery,
  GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES
} from '../../../hooks/useFetchAllocatedUsersAndRoles';

const mapUserToAllocationUser = user => ({
  ...user,
  id: user.id,
  costCurrencyUri: user.currentCostRate
    ? user.currentCostRate.currency.id
    : null,
  costCurrency: user.currentCostRate ? user.currentCostRate.currency : null,
  costRate: user.currentCostRate ? user.currentCostRate.amount : null,
  displayText: user.displayText,
  userUri: user.id,
  user: {
    displayText: user.displayText,
    uri: user.id,
    roles: user.roles,
    slug: user.slug,
    permittedActionUris: user.permittedActionUris || []
  }
});

export const onCreateReplaceCacheUpdate = ({
  proxy,
  newAllocation,
  handleResourceAllocationUserChange,
  setSnackBarState,
  oldUser,
  allocateResourcePopupMenuClose
}) => {
  setSnackBarState(state => ({ ...state, open: true }));

  const newUser = newAllocation.user;

  const variables = getSessionStorage(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES,
    null
  );

  const cachedResults = tryResourcePlanAllocatedUsersAndRolesCachedQuery({
    proxy,
    variables
  });

  const userList = cachedResults
    ? getUpdatedAllocatedUserList({
        oldUser,
        newUser,
        newAllocation,
        cachedAllocatedUsers:
          cachedResults.project.resourcePlanAllocatedUsersAndRoles
      })
    : [];

  proxy.writeQuery({
    query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
    variables,
    data: {
      project: {
        ...cachedResults?.project,
        resourcePlanAllocatedUsersAndRoles: userList
      }
    }
  });

  const excludeOldUser = !userList.some(
    entry => entry.user.id === oldUser?.user.uri
  );

  handleResourceAllocationUserChange({
    oldUser: excludeOldUser ? oldUser?.user : null,
    newUsers: [newUser.user]
  });

  allocateResourcePopupMenuClose();
};

export const updateCacheOnAllocationUserChange = ({
  handleResourceAllocationUserChange,
  setSnackBarState,
  smartFitResourceAllocation,
  allocateResourcePopupMenuClose
}) => (proxy, mutationResponse) => {
  const {
    data: {
      updateResourceAllocation2: { resourceAllocation: newAllocation }
    }
  } = mutationResponse;

  onCreateReplaceCacheUpdate({
    proxy,
    newAllocation,
    handleResourceAllocationUserChange,
    setSnackBarState,
    oldUser: smartFitResourceAllocation?.existingUser,
    allocateResourcePopupMenuClose
  });
};

export const updateCacheOnCreateResourceAllocation = ({
  setSnackBarState,
  allocateResourcePopupMenuClose,
  handleResourceAllocationUserChange
}) => (proxy, mutationResponse) => {
  const {
    data: {
      createFulfilledResourceAllocationWithoutResourceRequest: {
        resourceAllocation: newAllocation
      }
    }
  } = mutationResponse;

  onCreateReplaceCacheUpdate({
    proxy,
    newAllocation,
    setSnackBarState,
    handleResourceAllocationUserChange,
    allocateResourcePopupMenuClose
  });
};

const useProjectResourceAllocationEventHandlers = ({
  project,
  chartDateRange,
  showTimeOff,
  showHolidays,
  scale,
  me,
  smartFitResourceAllocation,
  setSmartFitResourceAllocation,
  openShowSmartFitDialogOrLoading,
  closeShowSmartFitDialogOrLoading,
  handleResourceAllocationUserChange,
  setSnackBarState,
  allocateResourcePopupMenuClose,
  setResourceRequestAcceptedLoading,
  existingResourceAllocation,
  setExistingResourceAllocation
}) => {
  const luxonFormat = getLuxonJsDateFormatFromMe(me);
  const { formatMessage } = useIntl();
  const { setTaskOwners } = useReleaseTaskOwners();
  const noRoleText = formatMessage({
    id: 'resourceAllocationChangeSnackBar.noRole'
  });

  const {
    onCreateResourceAllocation,
    loading: createAllocationExistingUserLoading
  } = useFulfilledResourceAllocationHandlersWithoutResourceRequest({
    chartDateRange,
    showTimeOff: Boolean(showTimeOff),
    showHolidays: Boolean(showHolidays),
    updateCreateCache: updateCacheOnCreateResourceAllocation({
      handleResourceAllocationUserChange,
      setSnackBarState,
      allocateResourcePopupMenuClose
    })
  });
  const {
    onUpdateResourceAllocation,
    onPatchResourceAllocation,
    loading: updateAllocationLoading
  } = useUpdateResourceAllocation(
    updateCacheOnAllocationUserChange({
      handleResourceAllocationUserChange,
      allocateResourcePopupMenuClose,
      smartFitResourceAllocation,
      setSnackBarState
    })
  );

  const handleAddResourceAllocation = useCallback(
    async ({
      projectDetail: {
        id: projectUri,
        defaultScheduleRule,
        startDate: projectStartDate,
        endDate: projectEndDate
      },
      resourceAllocation,
      overrideScheduleRules = null
    }) => {
      const {
        requestStartDate: start,
        requestEndDate: end
      } = getResourceRequestDatesFromProjectDate({
        projectStartDate,
        projectEndDate,
        scale
      });

      await onCreateResourceAllocation({
        allocation: buildResourceAllocation({
          allocationId: smartFitResourceAllocation.id || null,
          user: resourceAllocation.user,
          me,
          roleUri: resourceAllocation.role && resourceAllocation.role.id,
          allocationStatus: ResourceAllocationStatus.Committed,
          load: resourceAllocation.load,
          projectUri,
          scheduleRules: overrideScheduleRules || {
            do: defaultScheduleRule.do,
            dateRange: {
              startDate: dateToMidnightUTCString(start),
              endDate: dateToMidnightUTCString(end)
            }
          }
        })
      });
    },
    [me, onCreateResourceAllocation, scale, smartFitResourceAllocation.id]
  );

  const handleEditResourceAllocation = useCallback(
    async ({
      isAsOf,
      patchedAllocation,
      resourceAllocation,
      onDialogClose,
      impactedTasks = [],
      existingUser
    }) => {
      onDialogClose();
      if (isAsOf) {
        setSmartFitResourceAllocation({
          resourceAllocation,
          scheduleRules: resourceAllocation.scheduleRules,
          user: {
            id: resourceAllocation.user.userUri,
            ...resourceAllocation.user.user
          },
          patchedAllocation,
          impactedTasks,
          isAsOf,
          isDirectAllocation: false,
          existingUser
        });

        openShowSmartFitDialogOrLoading();

        return;
      }

      setSmartFitResourceAllocation({
        resourceAllocation,
        scheduleRules: resourceAllocation.scheduleRules,
        user: {
          id: resourceAllocation.user.userUri,
          ...resourceAllocation.user.user
        },
        isDirectAllocation: false,
        impactedTasks,
        isAsOf,
        existingUser
      });

      openShowSmartFitDialogOrLoading();
    },
    [openShowSmartFitDialogOrLoading, setSmartFitResourceAllocation]
  );

  const {
    getScheduleHoursForUserAndDateRange
  } = useGetScheduledHoursForUserAndDateRangeCallback();

  const getAllocationLoadingAndDateRange = useCallback(
    async ({ scheduleRules, userUri }) => {
      const { startDate, endDate } = getDateRangeFromScheduleRules(
        scheduleRules
      );

      const dateRange = {
        startDate,
        endDate
      };

      const { scheduledHours } = await getScheduleHoursForUserAndDateRange(
        userUri,
        dateRange
      );

      const allocatedHours =
        getTotalHours({
          scheduleRules
        }) || 0;

      return {
        load:
          scheduledHours !== 0 ? (allocatedHours / scheduledHours) * 100 : 0,
        dateRange
      };
    },
    [getScheduleHoursForUserAndDateRange]
  );

  const handleNewMergeResourceAllocation = useCallback(
    async ({
      overrideScheduleRules,
      resourceAllocation: existingAllocation,
      updatedAllocation: allocation,
      isNewAllocation
    }) => {
      if (isNewAllocation) {
        const { dateRange, load } = await getAllocationLoadingAndDateRange({
          scheduleRules: overrideScheduleRules,
          userUri: existingAllocation.user.userUri
        });

        onUpdateResourceAllocation({
          allocation: {
            ...existingAllocation,
            load,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            scheduleRules: overrideScheduleRules,
            isAdjustedLoading:
              allocation.resourceAllocation?.isAdjustedLoading || false,
            resourceRequestId:
              allocation.resourceAllocation?.resourceRequestId || null
          }
        });
        setSnackBarState(state => ({
          ...state,
          message: formatMessage(
            {
              id: 'resourceAllocationChangeSnackBar.mergedAllocationAndRole'
            },
            {
              username: existingAllocation.user.user.displayText
            }
          )
        }));
      }
    },
    [
      formatMessage,
      getAllocationLoadingAndDateRange,
      onUpdateResourceAllocation,
      setSnackBarState
    ]
  );

  const handleDirectResourceAllocationSubmit = useCallback(
    ({ resourceAllocation, user, role, load, scheduleRules }) => {
      setSmartFitResourceAllocation({
        resourceAllocation,
        load,
        user: mapUserToAllocationUser(user),
        role,
        scheduleRules,
        isDirectAllocation: true
      });
      openShowSmartFitDialogOrLoading();
    },
    [openShowSmartFitDialogOrLoading, setSmartFitResourceAllocation]
  );

  const onMergeAllocationSubmit = useCallback(
    async ({ overrideScheduleRules, isMerge, resourceAllocation }) => {
      if (isMerge) {
        await handleNewMergeResourceAllocation({
          isNewAllocation:
            smartFitResourceAllocation.resourceAllocation.isNewAllocation,
          overrideScheduleRules,
          smartFitResourceAllocation,
          updatedAllocation: smartFitResourceAllocation.resourceAllocation,
          resourceAllocation
        });
      }

      if (
        !isMerge &&
        smartFitResourceAllocation.resourceAllocation.isNewAllocation
      ) {
        await handleAddResourceAllocation({
          projectDetail: project,
          resourceAllocation: smartFitResourceAllocation,
          overrideScheduleRules
        });

        setSnackBarState(state => ({
          ...state,
          message: formatMessage(
            {
              id: 'resourceAllocationChangeSnackBar.directAllocation'
            },
            {
              username: smartFitResourceAllocation.user.user.displayText,
              role: smartFitResourceAllocation.role
                ? smartFitResourceAllocation.role.displayText
                : noRoleText
            }
          )
        }));
      }
    },
    [
      formatMessage,
      handleAddResourceAllocation,
      handleNewMergeResourceAllocation,
      noRoleText,
      project,
      setSnackBarState,
      smartFitResourceAllocation
    ]
  );

  const handleMergeAllocation = useCallback(
    async overrideScheduleRules => {
      const existingResourceAllocationScheduleRules =
        existingResourceAllocation?.scheduleRules;

      if (existingResourceAllocationScheduleRules) {
        const mergedAllocations = mergePeriodRulesIntoScheduleRules(
          existingResourceAllocationScheduleRules
        )(overrideScheduleRules);

        await onMergeAllocationSubmit({
          overrideScheduleRules: mergedAllocations,
          resourceAllocation: existingResourceAllocation,
          isMerge: true
        });
      } else {
        await onMergeAllocationSubmit({
          overrideScheduleRules,
          isMerge: false
        });
      }
    },
    [existingResourceAllocation, onMergeAllocationSubmit]
  );

  const createAllocationWithUserAndOverrideRules = useCallback(
    async overrideScheduleRules => {
      const {
        impactedTasks,
        isAsOf,
        resourceAllocation,
        patchedAllocation,
        isDirectAllocation
      } = smartFitResourceAllocation;

      if (closeShowSmartFitDialogOrLoading) closeShowSmartFitDialogOrLoading();

      if (isDirectAllocation) {
        if (me?.featureFlags?.isPsaRmpTaskAllocation1Enabled) {
          setSmartFitResourceAllocation({
            ...smartFitResourceAllocation,
            scheduleRules: overrideScheduleRules
          });

          await handleMergeAllocation(overrideScheduleRules);

          setExistingResourceAllocation(null);

          return;
        }
        handleAddResourceAllocation({
          projectDetail: project,
          resourceAllocation: smartFitResourceAllocation,
          overrideScheduleRules
        });
        setSnackBarState(state => ({
          ...state,
          message: formatMessage(
            {
              id: 'resourceAllocationChangeSnackBar.directAllocation'
            },
            {
              username: smartFitResourceAllocation.user.user.displayText,
              role: smartFitResourceAllocation.role
                ? smartFitResourceAllocation.role.displayText
                : noRoleText
            }
          )
        }));
      } else if (isAsOf) {
        setSnackBarState(state => ({
          ...state,
          message: formatMessage(
            {
              id: 'resourceAllocationChangeSnackBar.resourceChangedAsOf'
            },
            {
              username: resourceAllocation.user.user.displayText,
              date: mapIsoStringtoUtcObject(
                resourceAllocation.startDate
              ).toFormat(luxonFormat)
            }
          )
        }));
        onPatchResourceAllocation({
          allocation: patchedAllocation,
          project
        });
        onCreateResourceAllocation({
          allocation: {
            ...resourceAllocation,
            scheduleRules: overrideScheduleRules
          }
        });
      } else {
        onUpdateResourceAllocation({
          allocation: {
            ...resourceAllocation,
            scheduleRules: overrideScheduleRules
          }
        });
        setSnackBarState(state => ({
          ...state,
          message: formatMessage(
            {
              id: 'resourceAllocationChangeSnackBar.resourceChanged'
            },
            {
              username: resourceAllocation.user.user.displayText
            }
          )
        }));
      }

      if (impactedTasks?.length > 0) {
        await setTaskOwners(
          impactedTasks,
          resourceAllocation.user.userUri,
          resourceAllocation.requestedRoleUri
        );
      }
    },
    [
      smartFitResourceAllocation,
      closeShowSmartFitDialogOrLoading,
      me?.featureFlags?.isPsaRmpTaskAllocation1Enabled,
      handleAddResourceAllocation,
      project,
      setSnackBarState,
      setSmartFitResourceAllocation,
      handleMergeAllocation,
      setExistingResourceAllocation,
      formatMessage,
      noRoleText,
      onPatchResourceAllocation,
      onCreateResourceAllocation,
      luxonFormat,
      onUpdateResourceAllocation,
      setTaskOwners
    ]
  );

  const handleLoadingStateOnResourceRequestAccept = useCallback(
    ({ newUsers }) => {
      setResourceRequestAcceptedLoading(false);

      const snackBarMessage =
        newUsers.length === 1
          ? formatMessage(
              {
                id: 'resourceAllocationChangeSnackBar.acceptResource'
              },
              {
                username: newUsers[0].user.displayText
              }
            )
          : formatMessage(
              {
                id: 'resourceAllocationChangeSnackBar.acceptMultipleResources'
              },
              {
                count: newUsers.length
              }
            );

      setSnackBarState({
        open: true,
        message: snackBarMessage
      });
    },
    [setResourceRequestAcceptedLoading, formatMessage, setSnackBarState]
  );

  return {
    onMergeAllocationSubmit,
    createAllocationWithUserAndOverrideRules,
    handleDirectResourceAllocationSubmit,
    handleEditResourceAllocation,
    handleLoadingStateOnResourceRequestAccept,
    createAllocationExistingUserLoading,
    updateAllocationLoadingUserUri:
      updateAllocationLoading &&
      smartFitResourceAllocation?.existingUser?.userUri,
    handleNewMergeResourceAllocation,
    handleMergeAllocation
  };
};

export default useProjectResourceAllocationEventHandlers;
