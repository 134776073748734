import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { DialogContent, Grid, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import ReadOnlyDateField from './components/ReadOnlyDateField';
import DurationModeContent from './components/DurationModeContent';
import { MODE } from './components/enum';
import { getDurationSummaryValues } from './components/durationUtil';
import { EditableDateField } from './components/EditableDateField';
import { OpenEndedTaskIcon } from './components/OpenEndedTaskIcon';
import { useOnChangeHandlers } from './hooks/useOnChangeHandlers';
import ConfirmationArea from './components/ConfirmationArea';
import RescheduleTaskDialogContentLoading from './components/RescheduleTaskDialogContentLoading';

export const useStyles = makeStyles(theme => ({
  dialog: {
    background: theme.palette.grey[50],
    padding: theme.spacing(2)
  },
  arrow: {
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    alignSelf: 'center'
  },
  duration: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    display: 'flex'
  }
}));

const labels = {
  existingStartDate: (
    <FormattedMessage id="rescheduleTaskDialog.existingStartDate" />
  ),
  existingEndDate: (
    <FormattedMessage id="rescheduleTaskDialog.existingEndDate" />
  ),
  newStartDate: <FormattedMessage id="rescheduleTaskDialog.newStartDate" />,
  newEndDate: <FormattedMessage id="rescheduleTaskDialog.newEndDate" />
};

const RescheduleTaskDialogContent = ({
  initialDurationMode,
  task,
  newTaskDateRange,
  setNewTaskDateRange,
  isRemoveAllocationsChecked,
  setIsRemoveAllocationsChecked,
  loading,
  hasTaskAllocations,
  isSubmitting,
  existingTaskDateRange
}) => {
  const classes = useStyles();
  const [durationMode, setDurationMode] = useState(
    initialDurationMode || MODE.KEEP_TASK_WORK_DAYS
  );

  const durationValues =
    existingTaskDateRange.startDate &&
    existingTaskDateRange.endDate &&
    getDurationSummaryValues(
      existingTaskDateRange.startDate,
      existingTaskDateRange.endDate
    );

  const {
    onDurationModeChange,
    handleRemoveAllocationCheckBoxClick,
    onNewStartDateChange,
    onNewEndDateChange
  } = useOnChangeHandlers({
    setNewTaskDateRange,
    setDurationMode,
    setIsRemoveAllocationsChecked,
    durationValues,
    durationMode,
    existingTaskDateRange,
    newTaskDateRange
  });

  if (isSubmitting) {
    return <RescheduleTaskDialogContentLoading />;
  }

  return (
    <DialogContent className={classes.dialog}>
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="flex-start"
        >
          <Grid item xs={5}>
            <ReadOnlyDateField
              label={labels.existingStartDate}
              dateValue={existingTaskDateRange.startDate}
            />
          </Grid>
          <Grid item xs={1} spacing={0} container></Grid>
          <Grid item xs={6}>
            <EditableDateField
              name="newStartDate"
              labelResourceKey={labels.newStartDate}
              onChange={onNewStartDateChange}
              value={newTaskDateRange.startDate}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item xs={5} className={classes.duration}>
            {durationValues ? (
              <FormattedMessage
                id="rescheduleTaskDialog.duration"
                values={durationValues}
              />
            ) : (
              <OpenEndedTaskIcon />
            )}
          </Grid>
          <Grid item xs={1} spacing={0} container justifyContent="flex-end">
            <ArrowForwardIcon className={classes.arrow} />
          </Grid>
          <Grid item xs={6}>
            <DurationModeContent
              newTaskDateRange={newTaskDateRange}
              durationValues={durationValues}
              durationMode={durationMode}
              onDurationModeChange={onDurationModeChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="flex-start"
        >
          <Grid item xs={5}>
            <ReadOnlyDateField
              label={labels.existingEndDate}
              dateValue={existingTaskDateRange.endDate}
            />
          </Grid>
          <Grid item xs={1} container spacing={0}></Grid>
          <Grid item xs={6}>
            <EditableDateField
              name="newEndDate"
              labelResourceKey={labels.newEndDate}
              onChange={onNewEndDateChange}
              value={newTaskDateRange.endDate}
            />
          </Grid>
        </Grid>
        <ConfirmationArea
          isRemoveAllocationsChecked={isRemoveAllocationsChecked}
          handleRemoveAllocationCheckBoxClick={
            handleRemoveAllocationCheckBoxClick
          }
          loading={loading}
          hasTaskAllocations={hasTaskAllocations}
          existingTaskDateRange={existingTaskDateRange}
          newTaskDateRange={newTaskDateRange}
        />
      </Grid>
    </DialogContent>
  );
};

RescheduleTaskDialogContent.propTypes = {
  initialDurationMode: PropTypes.oneOf(Object.values(MODE)),
  setNewTaskDateRange: PropTypes.func,
  task: PropTypes.object,
  newTaskDateRange: PropTypes.object,
  isRemoveAllocationsChecked: PropTypes.bool,
  loading: PropTypes.bool,
  hasTaskAllocations: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setIsRemoveAllocationsChecked: PropTypes.func,
  existingTaskDateRange: PropTypes.object
};

export default RescheduleTaskDialogContent;
