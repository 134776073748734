import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { MoneyValue } from '~/modules/common/components';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { CHILD_INFO_DIALOG_COLUMN_TYPE } from '~/modules/common/enums';
import { MobileTaskVariationRow } from './MobileTaskVariationRow';
import { useDividerStyles } from './useDividerStyles';
import MobileTaskSummaryRow from './MobileTaskSummaryRow';
import RolledUpSummaryAccordionContainer from './RolledUpSummaryAccordionContainer';

const useStyles = makeStyles({
  na: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  },
  container: {
    paddingBottom: 'unset'
  },
  content: {}
});

const getColumns = ({ rowType }) => [
  rowType === CHILD_INFO_DIALOG_COLUMN_TYPE.ESTIMATED_EXPENSES
    ? CHILD_INFO_DIALOG_COLUMN_TYPE.MONTH
    : CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
  rowType
];

export const RowValue = ({ value }) => {
  const classes = useStyles();

  return value === null ? (
    <Typography className={classes.na}>
      <FormattedMessage id="taskDrawer.na" />
    </Typography>
  ) : (
    <MoneyValue money={value} />
  );
};

export const CostAccordionContent = ({
  actualsClassOverride,
  estimatedAtCompletionAlternateTooltipComponent,
  estimatedAtCompletionClassOverride,
  expenseRemainingAlternateTooltipComponent,
  expensesRemainingClassOverride,
  hasMore,
  initialEstimatedClassOverride,
  labels,
  loadMore,
  loadingMore,
  mobileTaskVariationRowClassOverride,
  showExpense,
  task,
  totalExpensesClassOverride,
  variation,
  variationStatus,
  workRemainingAlternateTooltipComponent,
  workRemainingClassOverride,
  tooltipConditions
}) => {
  const { rolledUpCostSummary } = task;
  const {
    hideEstimatedAtCompletionRowTooltip,
    hideVariationRowTooltip
  } = tooltipConditions;

  const classes = useStyles({ classes: mobileTaskVariationRowClassOverride });

  const variationClasses = useMemo(
    () => ({
      container: classes.container,
      content: classes.content
    }),
    [classes.container, classes.content]
  );
  const dividerclass = useDividerStyles();

  return (
    <>
      <MobileTaskSummaryRow
        label={labels.actuals}
        childrenDetails={task.children}
        loadingMore={loadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
        selectedColumns={getColumns({
          rowType: CHILD_INFO_DIALOG_COLUMN_TYPE.ACTUAL_COST
        })}
        classesOverrides={actualsClassOverride}
      >
        <RowValue value={rolledUpCostSummary.actualCostInProjectCurrency} />
      </MobileTaskSummaryRow>
      <MobileTaskSummaryRow
        label={labels.workRemaining}
        childrenDetails={task.children}
        loadingMore={loadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
        selectedColumns={getColumns({
          rowType: CHILD_INFO_DIALOG_COLUMN_TYPE.COST_REMAINING
        })}
        classesOverrides={workRemainingClassOverride}
        tooltipComponent={workRemainingAlternateTooltipComponent}
      >
        <RowValue
          value={
            rolledUpCostSummary.totalEstimatedRemainingCostInProjectCurrency
          }
        />
      </MobileTaskSummaryRow>
      {showExpense && (
        <>
          <MobileTaskSummaryRow
            label={labels.totalExpenses}
            classesOverrides={totalExpensesClassOverride}
          >
            <RowValue
              value={rolledUpCostSummary.totalExpensesInProjectCurrency}
            />
          </MobileTaskSummaryRow>
          <MobileTaskSummaryRow
            label={labels.expensesRemaining}
            classesOverrides={expensesRemainingClassOverride}
            childrenDetails={task.projectEstimatedExpenseRemainingSeriesByMonth}
            selectedColumns={getColumns({
              rowType: CHILD_INFO_DIALOG_COLUMN_TYPE.ESTIMATED_EXPENSES
            })}
            tooltipComponent={expenseRemainingAlternateTooltipComponent}
          >
            <RowValue
              value={rolledUpCostSummary.expensesRemainingInProjectCurrency}
            />
          </MobileTaskSummaryRow>
        </>
      )}
      <Divider className={dividerclass.divider} />
      <MobileTaskSummaryRow
        label={labels.estimatedAtCompletion}
        childrenDetails={task.children}
        hideTooltip={hideEstimatedAtCompletionRowTooltip}
        loadingMore={loadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
        selectedColumns={getColumns({
          rowType: CHILD_INFO_DIALOG_COLUMN_TYPE.COST_ESTIMATED_AT_COMPLETION
        })}
        classesOverrides={estimatedAtCompletionClassOverride}
        tooltipComponent={estimatedAtCompletionAlternateTooltipComponent}
      >
        <RowValue
          value={
            rolledUpCostSummary.totalEstimatedAtCompletionCostInProjectCurrency
          }
        />
      </MobileTaskSummaryRow>
      <MobileTaskSummaryRow
        label={labels.estimateBudget}
        classesOverrides={initialEstimatedClassOverride}
      >
        <RowValue value={rolledUpCostSummary.intialEstimateOrBudgetedCost} />
      </MobileTaskSummaryRow>
      <Divider className={dividerclass.divider} />
      <MobileTaskVariationRow
        task={task}
        classes={variationClasses}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        variation={variation}
        variationStatus={variationStatus}
        selectedColumns={getColumns({
          rowType: CHILD_INFO_DIALOG_COLUMN_TYPE.COST_VARIATION
        })}
        hideTooltip={hideVariationRowTooltip}
        variant="cost"
      />
    </>
  );
};

const CostAccordion = ({
  actualsClassOverride,
  estimatedAtCompletionAlternateTooltipComponent,
  estimatedAtCompletionClassOverride,
  expenseRemainingAlternateTooltipComponent,
  expensesRemainingClassOverride,
  hasMore,
  initialEstimatedClassOverride,
  labels,
  loadMore,
  loadingMore,
  mobileTaskVariationRowClassOverride,
  showExpense,
  task,
  totalExpensesClassOverride,
  variation,
  variationStatus,
  workRemainingAlternateTooltipComponent,
  workRemainingClassOverride,
  tooltipConditions
}) => {
  return (
    <RolledUpSummaryAccordionContainer
      variation={variation}
      variationStatus={variationStatus}
      task={task}
      variant="cost"
      title="taskStatus.cost"
    >
      <CostAccordionContent
        task={task}
        loadMore={loadMore}
        hasMore={hasMore}
        loadingMore={loadingMore}
        variation={variation}
        variationStatus={variationStatus}
        actualsClassOverride={actualsClassOverride}
        initialEstimatedClassOverride={initialEstimatedClassOverride}
        labels={labels}
        showExpense={showExpense}
        totalExpensesClassOverride={totalExpensesClassOverride}
        expensesRemainingClassOverride={expensesRemainingClassOverride}
        workRemainingClassOverride={workRemainingClassOverride}
        estimatedAtCompletionClassOverride={estimatedAtCompletionClassOverride}
        estimatedAtCompletionAlternateTooltipComponent={
          estimatedAtCompletionAlternateTooltipComponent
        }
        expenseRemainingAlternateTooltipComponent={
          expenseRemainingAlternateTooltipComponent
        }
        workRemainingAlternateTooltipComponent={
          workRemainingAlternateTooltipComponent
        }
        mobileTaskVariationRowClassOverride={
          mobileTaskVariationRowClassOverride
        }
        tooltipConditions={tooltipConditions}
      />
    </RolledUpSummaryAccordionContainer>
  );
};

RowValue.propTypes = {
  value: PropTypes.object
};
CostAccordionContent.propTypes = {
  task: PropTypes.object,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  variation: PropTypes.object,
  variationStatus: PropTypes.string,
  initialEstimatedClassOverride: PropTypes.object,
  actualsClassOverride: PropTypes.object,
  labels: PropTypes.object,
  showExpense: PropTypes.bool,
  totalExpensesClassOverride: PropTypes.object,
  expensesRemainingClassOverride: PropTypes.object,
  workRemainingClassOverride: PropTypes.object,
  estimatedAtCompletionClassOverride: PropTypes.object,
  estimatedAtCompletionAlternateTooltipComponent: PropTypes.node,
  expenseRemainingAlternateTooltipComponent: PropTypes.node,
  workRemainingAlternateTooltipComponent: PropTypes.node,
  mobileTaskVariationRowClassOverride: PropTypes.object,
  tooltipConditions: PropTypes.object
};

CostAccordion.propTypes = {
  task: PropTypes.object,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  variation: PropTypes.object,
  variationStatus: PropTypes.string,
  actualsClassOverride: PropTypes.object,
  initialEstimatedClassOverride: PropTypes.object,
  labels: PropTypes.object,
  showExpense: PropTypes.bool,
  totalExpensesClassOverride: PropTypes.object,
  expensesRemainingClassOverride: PropTypes.object,
  workRemainingClassOverride: PropTypes.object,
  estimatedAtCompletionClassOverride: PropTypes.object,
  estimatedAtCompletionAlternateTooltipComponent: PropTypes.node,
  expenseRemainingAlternateTooltipComponent: PropTypes.node,
  workRemainingAlternateTooltipComponent: PropTypes.node,
  mobileTaskVariationRowClassOverride: PropTypes.object,
  tooltipConditions: PropTypes.object
};
export default CostAccordion;
