import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useStyles } from '~/modules/projects/project/BillPlanV2/BillPlanPreRequisites/hooks';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import AddMilestoneTaskLink from './AddMilestoneTaskLink';
import AddRateType from './AddRateType';

export const BillPlanSuccess = ({
  title,
  projectDetails,
  setMilestoneTaskCreated,
  setTaskAddedCount,
  milestoneTaskCreated,
  clientRates,
  setRatesModifier,
  rates
}) => {
  const isPsaFpAdvancedRateCardEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpAdvancedRateCardEnabled'
  });
  const classes = useStyles();

  return (
    <Grid container spacing={0}>
      <div className={classes.root}>
        <Alert
          severity="success"
          className={classes.alert}
          data-qe-id="SuccessMessage"
        >
          <strong>{title}</strong>
          {!isPsaFpAdvancedRateCardEnabled && clientRates ? (
            <AddRateType
              projectDetails={projectDetails}
              rates={rates}
              setRatesModifier={setRatesModifier}
            />
          ) : null}
          {milestoneTaskCreated ? (
            <AddMilestoneTaskLink
              projectDetails={projectDetails}
              setMilestoneTaskCreated={setMilestoneTaskCreated}
              setTaskAddedCount={setTaskAddedCount}
            />
          ) : null}
        </Alert>
      </div>
    </Grid>
  );
};

BillPlanSuccess.propTypes = {
  title: PropTypes.string,
  projectDetails: PropTypes.object,
  setMilestoneTaskCreated: PropTypes.func,
  setTaskAddedCount: PropTypes.func,
  milestoneTaskCreated: PropTypes.bool,
  clientRates: PropTypes.bool,
  setRatesModifier: PropTypes.func,
  rates: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default BillPlanSuccess;
