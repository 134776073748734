import { gql } from 'graphql-tag';
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const BATCH_STATUS_QUERY = gql`
  query getBatchStatus($batchId: String!) {
    getBatchStatus(batchId: $batchId) {
      executionState
      overallProgress {
        currentProgress
        maximumProgress
      }
    }
  }
`;

export const useBatchStatus = ({ batchId, onBatchComplete }) => {
  const { stopPolling, data } = useQuery(BATCH_STATUS_QUERY, {
    variables: {
      batchId
    },
    fetchPolicy: 'network-only',
    pollInterval: 500
  });

  const { executionState, overallProgress, error } = get(
    data,
    'getBatchStatus',
    {}
  );

  const hasProcessed =
    executionState === 'SUCCEEDED' ||
    executionState === 'CANCELLED' ||
    executionState === 'FAILED';

  useEffect(() => {
    if (!hasProcessed) return;
    stopPolling();
    onBatchComplete && onBatchComplete();
  }, [hasProcessed, stopPolling, onBatchComplete]);

  return { error, executionState, overallProgress, hasProcessed };
};

export default useBatchStatus;
