import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ChartLegend from './ChartLegend';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    justifyContent: 'flex-end',
    flexWrap: 'wrap'
  },
  legendText: {}
}));

export const ChartLegends = ({
  classes: classesOverride,
  legends,
  className
}) => {
  const classes = useStyles({ classes: classesOverride });
  const chartLegendClasses = useMemo(
    () => ({ legendText: classes.legendText }),
    [classes.legendText]
  );

  return (
    <div className={classnames(classes.container, className)}>
      {legends.map((legend, level) => {
        const ratingIndex = level + 1;

        return (
          <ChartLegend
            classes={chartLegendClasses}
            key={`${legend.label}-${ratingIndex}`}
            label={legend.label}
            color={legend.color}
            labelColor={legend.labelColor}
            borderColor={legend.borderColor}
            borderStyle={legend.borderStyle}
            custom={legend.custom}
          />
        );
      })}
    </div>
  );
};

ChartLegends.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    })
  ).isRequired
};

export default ChartLegends;
