import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { ResourceRequestAccessLevel } from '~/types';

export const RESOURCE_SUMMARY_COUNT_QUERY = gql`
  query resourcePlansSummaryForCurrentTenant(
    $filter: ResourcePlanFilter
    $groupBy: ResourcePlanGroupBy
    $requestAccessLevel: ResourceRequestAccessLevel
  ) {
    resourcePlansSummaryForCurrentTenant(
      filter: $filter
      groupBy: $groupBy
      requestAccessLevel: $requestAccessLevel
    ) {
      pendingCount
      toBeHiredCount
      proposedCount
      allocationRejectedCount
    }
  }
`;

const useResourcePlanSummaryCounts = ({
  resourcePlanFilter,
  resourcePlanGroup,
  requestAccessLevel = ResourceRequestAccessLevel.All
} = {}) => {
  const {
    loading,
    error,
    data: { resourcePlansSummaryForCurrentTenant = {} } = {},
    refetch
  } = useQuery(RESOURCE_SUMMARY_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      requestAccessLevel,
      filter: resourcePlanFilter,
      groupBy: resourcePlanGroup
    }
  });

  return {
    loading,
    error,
    refetch,
    ...resourcePlansSummaryForCurrentTenant
  };
};

export default useResourcePlanSummaryCounts;
