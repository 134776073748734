import { totalScoreTag } from '../Facets/ScoreMetricFacet/tag';

const hasReservedChars = value => value.includes(',') || value.includes(' ');

const defaultFormatter = key => {
  const stripped = key.replace(/"/gm, '');

  return hasReservedChars(stripped) ? `"${stripped}"` : stripped;
};

export const formatScore = key => {
  const separator = '–';
  const keyPieces = key.split(separator);
  const parsedMin = keyPieces[0];
  const parsedMax = keyPieces[1];

  return Boolean(parsedMin) && Boolean(parsedMax)
    ? `(${parsedMin} to ${parsedMax})`
    : parsedMin
    ? `(min ${parsedMin})`
    : `(max ${parsedMax})`;
};

export default tag =>
  ({ [totalScoreTag]: formatScore }[tag] || defaultFormatter);
