import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MyWorkIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 5 3 C 3.339999999999999 3 2 4.340000000000007 2 6 L 2 18 C 2 19.66 3.34 21 5 21 L 22 21 L 22 6.240234375 L 13.7421875 14.5 L 9.5 14.5 L 9.5 10.2578125 L 13.7578125 6 L 8 6 L 8 3 L 5 3 z M 5 5 L 6 5 L 6 16 L 5 16 C 4.49896681687536 16 4 16.49742460932213 4 17 L 4 6 C 4 5.450000000000001 4.449999999999999 5 5 5 z " />
      <path d="m 18.07090758602288,3.807772070417481 1.76776695296636,-1.767766952966368 2.12000000000003,2.120000000000032 -1.7677669529664,1.767766952966331 z m -1.06066017177983,1.06066017177982 2.12132034355964,2.121320343559646 -6.01040764008565,6.010407640085654 -2.12132034355964,9e-15 0,-2.12132034355965 z" />
    </>
  </SvgIcon>
));

export default MyWorkIcon;
