import { createContext, useState, useMemo, useContext } from 'react';

export const SelectedResourceRequestContext = createContext();

const useSelectedResourceRequestContext = () =>
  useContext(SelectedResourceRequestContext);

export const useSelectedResourceRequestContextValue = () => {
  const [selectedResourceRequestId, setSelectedResourceRequestId] = useState(
    null
  );

  const closeResourceRequestDrawer = () => {
    setSelectedResourceRequestId(null);
  };

  const openResourceRequestDrawer = ({ resourceRequestId }) => {
    setSelectedResourceRequestId(resourceRequestId);
  };

  return useMemo(
    () => ({
      selectedResourceRequestId,
      closeResourceRequestDrawer,
      openResourceRequestDrawer,
      isResourceRequestDrawerOpen: Boolean(selectedResourceRequestId)
    }),
    [selectedResourceRequestId]
  );
};

export default useSelectedResourceRequestContext;
