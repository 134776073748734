import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import {
  ListTable,
  computeTableColumns,
  ListTablePropTypes
} from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { clientTag } from '~/modules/common/components/SearchBox/Facets/ClientFacet/tag';
import MobileBillingTransactionListItem from '~/modules/billing-invoicing/common/components/MobileBillingTransactionListItem';
import BillingTransactionListNoData from '../BillingTransactionListNoData';
import { useListColumns, useListFooters } from './columns';
import formatters, { AllRowSelectorFormatter } from './formatters';
import useEnabledBillingTransactionColumns from './useEnabledBillingTransactionColumns';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(2)
    },
    '& th:first-child': {
      zIndex: 3,
      left: 0,
      position: 'sticky'
    },
    '& tr:hover td:first-child': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  tableHeaderCell: {
    padding: theme.spacing(1, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    fontSize: theme.typography.body2.fontSize,
    top: ({ headerLevel }) => theme.spacing((headerLevel + 1) * 6)
  },
  tableCell: {
    cursor: 'pointer',
    height: 40,
    padding: theme.spacing(0.5, 2)
  },
  tableFooter: {
    backgroundColor: theme.palette.grey[50],
    color: 'inherit',
    '& ul': {
      listStyleType: 'none',
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  tableFooterCell: {
    padding: theme.spacing(1, 2),
    height: 20
  }
}));

const useColumnStyles = makeStyles(theme => ({
  bottomHeader: {
    top: 40
  },
  name: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    maxWidth: 200
  },
  description: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    maxWidth: '240px'
  },
  total: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    maxWidth: 200,
    verticalAlign: 'top'
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.table.border}`
  },
  displayText: {
    position: 'sticky',
    left: 0,
    zIndex: 1
  },
  rowSelector: { position: 'sticky', left: 0 },
  tag: {
    minWidth: theme.spacing(20)
  }
}));

const useStyles = makeStyles(theme => ({
  allSelectorContainer: {
    display: 'flex',
    padding: theme.spacing(1, 1.5),
    backgroundColor: '#fff'
  }
}));

export const BillingTransactionListTable = ({
  hover = true,
  selectedColumns,
  onRowClick,
  resourceKeys,
  headerLevel,
  canAddPayment,
  sort,
  onSortChange,
  isLoading,
  billingRows,
  searchCriteria,
  hasMoreRows,
  loadMoreRows,
  totals,
  selectedTransactionItems,
  allSelectedStatus,
  onRowSelect,
  onSelectAll,
  isMobile,
  refetchBillingTransactionRows,
  tags,
  summaryFilterState
}) => {
  const isSummaryFilterStateVoid =
    summaryFilterState?.quickFilter?.filterByStatus === 'void';
  const filteredSelectedColumns = isSummaryFilterStateVoid
    ? selectedColumns.filter(c => c !== 'rowSelector')
    : selectedColumns;

  const classes = useStyles();
  const providerClasses = useListTableThemeProviderStyles({ headerLevel });
  const columnClasses = useColumnStyles();
  const columns = useListColumns({
    classes: columnClasses,
    selectedColumns: filteredSelectedColumns,
    billingRows,
    selectedTransactionItems,
    allSelectedStatus,
    onRowSelect,
    onSelectAll,
    canAddPayment,
    tags,
    refetchBillingTransactionRows
  });
  const footers = useListFooters({
    columnClasses,
    selectedColumns: filteredSelectedColumns,
    tags
  });

  const onCellClickHandler = useCallback((event, field) => {
    if (field === 'actionColumn' || field === 'rowSelector') {
      event.stopPropagation();
    }
  }, []);

  const resourceKey =
    searchCriteria &&
    Object.keys(searchCriteria.criterions).length > 0 &&
    searchCriteria.criterions[clientTag] &&
    searchCriteria.criterions[clientTag].length > 0
      ? {
          ...resourceKeys,
          message: resourceKeys.messageWithFilter || resourceKeys.message
        }
      : resourceKeys;

  const {
    enabledBillingTransactionColumnGroups
  } = useEnabledBillingTransactionColumns({
    columnClasses,
    selectedColumns: filteredSelectedColumns,
    canAddPayment,
    tags
  });

  const { headers } = computeTableColumns({
    columnSpec: enabledBillingTransactionColumnGroups,
    renders: formatters
  })({
    enabledFields: filteredSelectedColumns,
    columnClasses: {
      displayText: filteredSelectedColumns.includes('rowSelector')
        ? {}
        : columnClasses.displayText,
      rowSelector: columnClasses.rowSelector
    }
  });

  return (
    <div>
      {!billingRows.length && !isLoading ? (
        <BillingTransactionListNoData resourceKeys={resourceKey} />
      ) : (
        <>
          {filteredSelectedColumns.includes('rowSelector') &&
          isMobile &&
          !isSummaryFilterStateVoid ? (
            <div className={classes.allSelectorContainer}>
              <AllRowSelectorFormatter
                onSelectAll={onSelectAll}
                allSelectedStatus={allSelectedStatus}
              />
            </div>
          ) : null}
          <ListTableThemeProvider classes={providerClasses}>
            <ListTable
              hasMore={hasMoreRows}
              loadMore={loadMoreRows}
              headers={headers}
              footers={footers}
              columns={columns}
              totals={totals}
              variant="table"
              records={billingRows}
              mobileListItem={MobileBillingTransactionListItem}
              hover={hover}
              onRowClick={onRowClick}
              onCellClick={onCellClickHandler}
              isLoading={isLoading}
              sort={sort}
              onSortChange={onSortChange}
            />
          </ListTableThemeProvider>
        </>
      )}
    </div>
  );
};

BillingTransactionListTable.propTypes = {
  hover: PropTypes.bool,
  selectedColumns: PropTypes.array,
  onRowClick: PropTypes.func,
  resourceKeys: PropTypes.object,
  headerLevel: PropTypes.number,
  canAddPayment: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  sort: ListTablePropTypes.sort,
  billingRows: PropTypes.array,
  searchCriteria: PropTypes.object,
  hasMoreRows: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  totals: PropTypes.object,
  selectedTransactionItems: PropTypes.array,
  allSelectedStatus: PropTypes.number,
  onRowSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  isMobile: PropTypes.bool,
  tags: PropTypes.array,
  refetchBillingTransactionRows: PropTypes.func,
  summaryFilterState: PropTypes.object
};

export default BillingTransactionListTable;
