import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { GridItem } from '~/modules/common/components';
import { ClientDropdownForProject } from '~/modules/projects/components/Common';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import SearchableClientRepDropdown from '../../common/components/SearchableClientRepDropdown';

const transformDropdownResult = result => ({
  displayText: result && result.label,
  id: result && result.value
});

export const EditClientsForm = ({
  formik: {
    values: { client, clientRepresentative },
    errors,
    setFieldValue
  },
  canViewClient,
  canEditClient,
  shouldFetchOnlyMyClients
}) => {
  const handleClientChange = useCallback(
    async value => {
      await setFieldValue('client', transformDropdownResult(value));
      await setFieldValue('clientRepresentative', {});
    },
    [setFieldValue]
  );

  const handleClientRepChange = useCallback(
    value => {
      setFieldValue('clientRepresentative', value || null);
    },
    [setFieldValue]
  );

  const clientTextFieldProps = useMemo(
    () => ({
      error: Boolean(errors.client),
      helperText: errors.client
    }),
    [errors.client]
  );

  return (
    <>
      {!isEmpty(errors) && <FormErrorAlert />}
      <Grid container>
        {canViewClient && (
          <GridItem fullWidth>
            <ClientDropdownForProject
              dataQeId="ClientDropdown"
              value={client}
              onChange={handleClientChange}
              editable={canEditClient}
              shouldFetchOnlyMyClients={shouldFetchOnlyMyClients}
              TextFieldProps={clientTextFieldProps}
            />
          </GridItem>
        )}
        <GridItem fullWidth>
          <SearchableClientRepDropdown
            dataQeId="ClientRepesentativeDropdown"
            clientUri={client.id}
            value={clientRepresentative}
            onChange={handleClientRepChange}
            key={client.id}
          />
        </GridItem>
      </Grid>
    </>
  );
};

EditClientsForm.propTypes = {
  formik: PropTypes.object,
  canViewClient: PropTypes.bool,
  canEditClient: PropTypes.bool,
  shouldFetchOnlyMyClients: PropTypes.bool
};

export default connect(EditClientsForm);
