import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';

const useTasksChartData = ({ activeTasksSummary }) => {
  const theme = useTheme();

  return [
    {
      value: activeTasksSummary.overEstimatedTasksCount,
      label: <FormattedMessage id="projectOverview.overEstimationOrEndDate" />,
      className: 'overEstimation',
      color: theme.palette.error.dark,
      labelColor: theme.palette.common.black,
      key: 'overEstimation'
    },
    {
      value: activeTasksSummary.underEstimatedTasksCount,
      label: <FormattedMessage id="projectOverview.underEstimation" />,
      className: 'underEstimation',
      color: theme.palette.success.dark,
      labelColor: theme.palette.common.black,
      key: 'underEstimation'
    },
    {
      value: activeTasksSummary.onTrackTasksCount,
      label: <FormattedMessage id="projectOverview.onTrack" />,
      className: 'onTrack',
      color: theme.palette.information.dark,
      labelColor: theme.palette.common.black,
      key: 'onTrack'
    }
  ];
};

export default useTasksChartData;
