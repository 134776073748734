import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  billTaxLineItemsQuery,
  PAGE_SIZE as TAX_LINE_ITEMS_PAGE_SIZE
} from '~/modules/billing-invoicing/common/components/TaxLineItems/hooks/useTaxLineItems';
import { filterSummarizeColumnOptions } from '~/modules/billing-invoicing/common/util';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { billQuery } from './useBillDetails';
import { billQuery as lineItemQuery, PAGE_SIZE } from './useLineItems';

export const PUT_BILL = gql`
  mutation putBill($input: BillInput2!) {
    putBill: putBill3(input: $input) {
      billUri
    }
  }
`;

export const usePutBillHandler = () => {
  const [putBill] = useMutation(PUT_BILL);
  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  return {
    putBill: input => {
      const filteredSummarizeColumnOptions = filterSummarizeColumnOptions(
        input.summarizeColumn
      );

      const refetchQueries = [
        'getPageOfBillingTransactions',
        'getBillingTransactionTotals',
        'getAvailableBillingItemsTotal',
        'getPageOfAvailableToBill',
        'getAvailableToBillTotals'
      ];

      if (input.id) {
        refetchQueries.push({
          query: billQuery,
          variables: {
            id: input.id,
            isPsaPswatBillingDraftSubStatusEnabled
          }
        });
        refetchQueries.push({
          query: lineItemQuery,
          variables: {
            page: 1,
            pageSize: PAGE_SIZE,
            id: input.id,
            filter: { isAdhoc: false },
            summarizeColumnOptions: filteredSummarizeColumnOptions
          }
        });
        refetchQueries.push({
          query: lineItemQuery,
          variables: {
            page: 1,
            pageSize: PAGE_SIZE,
            id: input.id,
            filter: { isAdhoc: true },
            summarizeColumnOptions: filteredSummarizeColumnOptions
          }
        });
      }

      if (input.id)
        refetchQueries.push({
          query: billTaxLineItemsQuery,
          variables: {
            page: 1,
            pageSize: TAX_LINE_ITEMS_PAGE_SIZE,
            id: input.id,
            filter: { isTaxItem: true }
          }
        });

      return putBill({
        variables: {
          input
        },
        refetchQueries
      });
    }
  };
};

export default usePutBillHandler;
