import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { billQuery } from '~/modules/billing-invoicing/bill/hooks/useBillDetails';
import {
  billAssociatedPaymentQuery,
  PAGE_SIZE
} from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/useAssociatedBillPayments';
import billingTransactionRefetchQueries from '~/modules/common/components/RefetchQueries/BillingTransactionRefetchQueries';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const PUT_BILL_PAYMENT = gql`
  mutation putBillPayment($input: BillPaymentInput2!) {
    putBillPayment: putBillPayment3(input: $input) {
      paymentUri
    }
  }
`;

export const usePutBillPayment = () => {
  const [putBillPayment] = useMutation(PUT_BILL_PAYMENT);
  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });
  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : [
        'getDraftTotals',
        'getOverdueBillSummary',
        'getOutstandingBillSummary',
        'getIssuedBillSummary',
        'getUnallocatedBillSummary'
      ];

  return {
    putBillPayment: (billPaymentDetails, billId) => {
      const refetchQueries = billId
        ? [
            ...billingTransactionRefetchQueries,
            ...queries,
            'availablePaymentsForBill',
            {
              query: billQuery,
              variables: {
                id: billId,
                isPsaPswatBillingDraftSubStatusEnabled
              }
            },
            {
              query: billAssociatedPaymentQuery,
              variables: {
                page: 1,
                pageSize: PAGE_SIZE,
                id: billId
              }
            }
          ]
        : ['getPageOfBillingTransactions', 'getBillingTransactionTotals'];

      return putBillPayment({
        variables: {
          input: billPaymentDetails
        },
        refetchQueries,
        awaitRefetchQueries: true
      });
    }
  };
};
export default usePutBillPayment;
