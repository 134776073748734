import { useHasPermission } from '~/modules/common/permissions';

export const useVendorPermissions = () => {
  return {
    canEditVendor: useHasPermission({
      actionUri: 'urn:replicon:vendor-action:edit-vendor'
    }),
    canViewVendor: useHasPermission({
      actionUri: 'urn:replicon:vendor-action:view-vendor'
    })
  };
};
