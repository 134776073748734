import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import {
  RemoveTaskAllocationConfirmationDialog,
  RemoveTaskAssignmentConfirmationDialog
} from '~/modules/projects/resourcing-plan/components';

export const ReleaseTaskDialogsContainer = ({
  projectId,
  task,
  user,
  closeReleaseTaskDialogsContainer,
  handleRemoveTaskAssignment
}) => {
  const {
    open: taskAssignmentDialogOpen,
    openDialog: openTaskAssignmentDialog,
    closeDialog: closeTaskAssignmentDialog
  } = useDialogState(false);

  const {
    open: taskAllocationReleaseDialogOpen,
    closeDialog: closeTaskAllocationReleaseDialog
  } = useDialogState(true);

  const onRemoveTaskAssignmentSuccess = useCallback(
    ({ taskId }) => {
      handleRemoveTaskAssignment({ taskId });
      closeTaskAssignmentDialog();
      closeReleaseTaskDialogsContainer();
    },
    [
      handleRemoveTaskAssignment,
      closeTaskAssignmentDialog,
      closeReleaseTaskDialogsContainer
    ]
  );

  return (
    <>
      {taskAllocationReleaseDialogOpen && (
        <RemoveTaskAllocationConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={taskAllocationReleaseDialogOpen}
          onClose={closeTaskAllocationReleaseDialog}
          openRemoveTaskAssignmentDialog={openTaskAssignmentDialog}
          onRemoveTaskAllocationSuccess={closeTaskAllocationReleaseDialog}
        />
      )}
      {taskAssignmentDialogOpen && (
        <RemoveTaskAssignmentConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={taskAssignmentDialogOpen}
          onClose={closeTaskAssignmentDialog}
          onRemoveTaskAssignmentSuccess={onRemoveTaskAssignmentSuccess}
        />
      )}
    </>
  );
};

ReleaseTaskDialogsContainer.propTypes = {
  user: PropTypes.object,
  task: PropTypes.object,
  projectId: PropTypes.string,
  closeReleaseTaskDialogsContainer: PropTypes.func,
  handleRemoveTaskAssignment: PropTypes.func
};

export default ReleaseTaskDialogsContainer;
