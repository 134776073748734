import { useProgramContext } from '~/modules/programs/program/context/ProgramContext';

export const hasPermittedAction = (permittedActionUris, permittedActionUri) =>
  permittedActionUris && permittedActionUris.includes(permittedActionUri);

export const useProgramPermissions = () => {
  const { program = {} } = useProgramContext();

  const permittedActionUris = program.permittedActionUris || [];

  return {
    canViewProgramEstimates: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:program-action:view-estimates'
    ),
    canViewProgramCostData: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:program-action:view-cost-data'
    ),
    canViewProgramBillingData: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:program-action:view-billing-data'
    ),
    canViewDocuments: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:program-action:view-documents'
    ),
    canEditDocuments: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:program-action:edit-documents'
    ),
    canEditProgram: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:program-action:edit-program'
    ),
    canEditProgramCostData: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:program-action:edit-cost-data'
    )
  };
};
export default useProgramPermissions;
