import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  DialogActions,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  titleText: {
    padding: theme.spacing(0),
    ...theme.typography.h6
  }
}));

const ExportLoaderDialog = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.titleText}>
        <FormattedMessage id="importExportMenu.exportDialog.failed" />
      </Typography>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </>
  );
};

ExportLoaderDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ExportLoaderDialog;
