import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  dateToMidnightUTCObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { equalDateTimes } from '~/modules/common/dates/compare';
import RescheduleTaskDialogTitle from './RescheduleTaskDialogTitle';
import RescheduleTaskDialogFooter from './RescheduleTaskDialogFooter';
import RescheduleTaskDialogContent from './RescheduleTaskDialogContent';
import { MODE } from './components/enum';
import useHasTaskAllocations from './hooks/useHasTaskAllocations';

const useDialogStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('sm')]: { minWidth: theme.spacing(75) },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxWidth: 'unset',
      margin: 'unset',
      maxHeight: 'unset',
      width: '100%'
    }
  }
}));

export const toLuxonDateTime = date => {
  if (typeof date === 'string') {
    return dateToMidnightUTCObject(date);
  }

  if (date && typeof date === 'object') {
    return mapRepliconDateToUtcObject(date);
  }

  return date;
};

export const haveDatesChanged = (existingTaskDateRange, newTaskDateRange) =>
  !equalDateTimes(
    existingTaskDateRange?.startDate,
    newTaskDateRange?.startDate
  ) ||
  !equalDateTimes(existingTaskDateRange?.endDate, newTaskDateRange?.endDate);

export const RescheduleTaskDialog = ({
  initialDurationMode,
  initialTaskDateRange,
  onCancel,
  onSubmit,
  task,
  isSubmitting
}) => {
  const dialogClasses = useDialogStyles();
  const existingTaskDateRange = useMemo(
    () => ({
      startDate: toLuxonDateTime(task.startDate),
      endDate: toLuxonDateTime(task.endDate)
    }),
    [task]
  );

  const [newTaskDateRange, setNewTaskDateRange] = useState(
    initialTaskDateRange || existingTaskDateRange
  );

  const haveDateChanged = haveDatesChanged(
    existingTaskDateRange,
    newTaskDateRange
  );

  const [isRemoveAllocationsChecked, setIsRemoveAllocationsChecked] = useState(
    true
  );

  const { loading, hasTaskAllocations } = useHasTaskAllocations({
    taskUri: task.id
  });

  return (
    <Dialog classes={dialogClasses} open onClose={onCancel}>
      <RescheduleTaskDialogTitle task={task} />
      <RescheduleTaskDialogContent
        task={task}
        existingTaskDateRange={existingTaskDateRange}
        newTaskDateRange={newTaskDateRange}
        setNewTaskDateRange={setNewTaskDateRange}
        initialDurationMode={initialDurationMode}
        isRemoveAllocationsChecked={isRemoveAllocationsChecked}
        setIsRemoveAllocationsChecked={setIsRemoveAllocationsChecked}
        loading={loading}
        hasTaskAllocations={haveDateChanged && hasTaskAllocations}
        isSubmitting={isSubmitting}
      />
      <RescheduleTaskDialogFooter
        onClose={onCancel}
        onSubmit={onSubmit}
        newTaskDateRange={newTaskDateRange}
        isRemoveAllocationsChecked={isRemoveAllocationsChecked}
        disableSubmitbutton={loading || isSubmitting || !haveDateChanged}
      />
    </Dialog>
  );
};

RescheduleTaskDialog.propTypes = {
  initialDurationMode: PropTypes.oneOf(Object.values(MODE)),
  initialTaskDateRange: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  task: PropTypes.object
};

export default RescheduleTaskDialog;
