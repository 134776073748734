import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  loading: {
    paddingTop: theme.spacing(3)
  },
  noData: { padding: theme.spacing(2) },
  list: {},
  footer: {}
}));

export const useTitleStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '100%'
  },
  list: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    paddingLeft: 0
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    minWidth: 50,
    maxWidth: 500
  },
  title: {
    fontSize: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  noData: { padding: theme.spacing(2) }
}));

export const usePanelStyles = makeStyles(theme => ({
  expansionPanel: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    margin: '0 !important'
  },
  expansionPanelSummary: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    flexDirection: 'row-reverse',
    alignItems: 'flex-start'
  },
  expansionPanelSummaryContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0)
  },
  expandIcon: {
    padding: theme.spacing(1, 0.5)
  }
}));

export const useAllocationStyles = makeStyles(theme => ({
  list: {
    paddingLeft: theme.spacing(2),
    '& th': {
      borderBottom: `1px solid ${theme.palette.grey[300]} !important`
    }
  },
  footer: { paddingLeft: '16px !important' }
}));

export const useColumnStyles = makeStyles(theme => ({
  deleteColumn: {
    width: theme.spacing(5),
    padding: theme.spacing(0)
  },
  hoursColumn: {
    paddingRight: theme.spacing(11.5)
  }
}));

export const useHoursStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    height: 24
  },
  hoursRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    height: 24,
    paddingRight: theme.spacing(11.5)
  },
  text: {
    fontSize: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5)
  },
  hour: {
    display: 'flex',
    justifyContent: 'end',
    width: 90,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noValue: {
    paddingRight: theme.spacing(1)
  },
  editableHours: {
    width: 100,
    height: '100%',
    marginRight: theme.spacing(-1),
    '& .MuiInputBase-root': {
      height: '100%'
    },
    '& .MuiInputBase-input': {
      fontSize: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5)
    }
  },
  delta: {
    display: 'flex',
    justifyContent: 'start',
    width: 90,
    height: '100%',
    padding: theme.spacing(0.25, 0),
    alignItems: 'center'
  }
}));

export const useFooterStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 0),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100]
  },
  cell: {
    padding: theme.spacing(0, 2)
  },
  resourecCell: {
    display: 'flex',
    flex: '1 1 auto'
  },
  deleteCell: {
    minWidth: '44px'
  }
}));

export const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'collapse',
    '& th, & td': {
      borderBottom: 'none'
    },
    '& td:last-child, th:last-child': {
      paddingRight: ({ editable }) => theme.spacing(editable ? 0 : 2),
      paddingLeft: ({ editable }) => theme.spacing(editable ? 0 : 2)
    }
  },
  tableHeader: {
    top: theme.spacing(-1),
    position: 'sticky',
    zIndex: 1
  },
  tableHeaderCell: {
    padding: theme.spacing(0, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40
  },
  tableCell: {
    height: 40,
    maxWidth: 200,
    padding: theme.spacing(0.5, 2)
  },
  headerCellWithNavigationButtons: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    gridColumnGap: theme.spacing(0.5),
    gridRowGap: 0,
    alignItems: 'center'
  }
}));

export const useDeltaStyles = makeStyles(theme => ({
  deltaLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightRegular
  },
  negative: {
    color: theme.palette.common.white,
    backgroundColor: '#d32f2f'
  },
  positive: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark
  },
  tooltip: {
    whiteSpace: 'pre',
    marginLeft: theme.spacing(-2)
  }
}));

export const useInheritStyles = makeStyles(theme => ({
  message: { padding: theme.spacing(2), color: theme.palette.text.secondary }
}));
