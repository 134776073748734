import React, { useCallback, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import {
  DialogContentText,
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core';
import {
  RemoveEntityConfirmationDialog,
  MoneyValue
} from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';

const useButtonStyles = makeStyles({
  removeButton: {
    padding: 0
  }
});

export const DeleteBillCredit = ({
  column: { resourceKeys, onDelete },
  record
}) => {
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = useCallback(
    event => setAnchorEl(event.currentTarget),
    []
  );

  const buttonClasses = useButtonStyles();

  const onMenuClose = useCallback(() => setAnchorEl(null), []);
  const openConfirmDialog = useCallback(() => {
    onMenuClose();
    openRemoveDialog();
  }, [onMenuClose, openRemoveDialog]);

  const onDeleteConfirm = useCallback(() => {
    onDelete(record);
    closeRemoveDialog();
  }, [closeRemoveDialog, onDelete, record]);

  const values = useMemo(
    () => ({
      amount: <MoneyValue money={record.allocatedBillAmount} />
    }),
    [record.allocatedBillAmount]
  );

  return (
    <>
      <IconButton
        aria-label={resourceKeys.deleteCredit}
        onClick={handleClick}
        className={buttonClasses.removeButton}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={onMenuClose}
      >
        <MenuItem key="item-Delete" onClick={openConfirmDialog}>
          <FormattedMessage id={`${resourceKeys.removeLabel}`} />
        </MenuItem>
      </Menu>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={resourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onDeleteConfirm}
        >
          <DialogContentText>
            <FormattedMessage
              id={`${resourceKeys.removeMessage}`}
              values={values}
            />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

DeleteBillCredit.propTypes = {
  column: PropTypes.object,
  record: PropTypes.array
};

export default DeleteBillCredit;
