import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import useMeContext from '~/modules/me/useMeContext';
import { RowItem, Decimal } from '~/modules/common/components';
import { RequestAllocatedDatesDiffView } from '~/modules/resourcing/common/components';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getResourceRequestTotal } from '~/modules/resourcing/common/enhancers/withResourceRequestTotal';
import { getResourceAllocationTotal } from '~/modules/resourcing/hooks/useResourceAllocationTotal';
import { ResourceRequestStatus } from '~/types';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import useStyles from './useStyles';
import {
  RequestDrawerAllocatedHours,
  RequestDrawerAllocatedCost,
  RequestDrawerResourceAllocationList
} from './components';

const ResourceRequestDrawerAllocationDetails = ({
  project,
  resourceRequest,
  resourceAllocations
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const me = useMeContext();
  const { locale, baseCurrency } = me;

  const numberSettings = getReactNumberFormatFromMe({ locale });
  const decimalScale = numberSettings.decimalScale || 2;
  const showAllocations =
    !(
      resourceRequest.requestStatus === ResourceRequestStatus.Draft ||
      resourceRequest.requestStatus === ResourceRequestStatus.Rejected ||
      resourceRequest.requestStatus === ResourceRequestStatus.Tobehired ||
      resourceRequest.requestStatus === ResourceRequestStatus.Submitted
    ) || resourceAllocations.length !== 0;

  const contextCurrency = resourceRequest.currency;
  const isCombinedResourceRequest =
    resourceRequest?.mergedResourceRequests?.length > 1;

  const allocationTotals = useMemo(() => {
    const allocTotals = resourceAllocations.map(a =>
      getResourceAllocationTotal({ me, contextCurrency, ...a })
    );

    const totalHours = allocTotals.reduce(
      (tHours, calloc) => tHours + calloc.resourceAllocationHours,
      0
    );

    const totalCost = allocTotals.reduce((retVal, total) => {
      const {
        resourceAllocationCost: { currencyUri, currencySymbol, amount }
      } = total;

      const totalAmount = retVal[currencyUri] ? retVal[currencyUri].amount : 0;

      return {
        ...retVal,
        [currencyUri]: {
          currencyUri,
          currencySymbol,
          amount: totalAmount + amount
        }
      };
    }, {});

    return {
      hours: totalHours,
      cost: Object.keys(totalCost).map(currencyUri => totalCost[currencyUri])
    };
  }, [resourceAllocations, contextCurrency, me]);

  const { resourceRequestTotalHours, resourceRequestTotalCost } = useMemo(
    () => getResourceRequestTotal(resourceRequest),
    [resourceRequest]
  );

  const requestedCost = useMemo(
    () => ({
      ...resourceRequestTotalCost,
      currencyUri: resourceRequestTotalCost.currencyUri || baseCurrency.uri,
      currencySymbol:
        resourceRequestTotalCost.currencySymbol || baseCurrency.symbol
    }),
    [resourceRequestTotalCost, baseCurrency]
  );

  const { permittedActionUris = [] } = project;

  const canViewCostData = permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>
        {intl.formatMessage({
          id: isCombinedResourceRequest
            ? 'resourceRequestDrawerDetails.allocatedResource'
            : 'resourceRequestDrawerDetails.allocatedResources'
        })}
      </h4>
      <div className={classes.section}>
        {showAllocations && (
          <>
            <ul className={classes.rowItem}>
              <RowItem
                label={intl.formatMessage({
                  id: 'resourceRequestDrawerDetails.allocatedDates'
                })}
              >
                {isCombinedResourceRequest ? (
                  <div className={classes.valueContainer}>
                    <Typography component="div" variant="body2">
                      {shortDateRangeFormat({
                        start: mapIsoStringtoUtcObject(
                          resourceAllocations[0]?.startDate
                        ),
                        end: mapIsoStringtoUtcObject(
                          resourceAllocations[0]?.endDate
                        )
                      })}
                    </Typography>
                  </div>
                ) : (
                  <RequestAllocatedDatesDiffView
                    resourceRequest={resourceRequest}
                    resourceAllocations={resourceAllocations}
                    className={classes.dates}
                  />
                )}
              </RowItem>
            </ul>
            <ul className={classes.rowItem}>
              <RowItem
                label={intl.formatMessage({
                  id: 'resourceRequestDrawerDetails.allocatedHours'
                })}
              >
                {isCombinedResourceRequest ? (
                  <Decimal
                    value={allocationTotals.hours}
                    precision={decimalScale}
                    fixedDecimalScale={false}
                  />
                ) : (
                  <RequestDrawerAllocatedHours
                    requestedHours={resourceRequestTotalHours}
                    allocatedHours={allocationTotals.hours}
                    fixedDecimalScale={false}
                    precision={decimalScale}
                  />
                )}
              </RowItem>
              {canViewCostData ? (
                <RowItem
                  label={intl.formatMessage({
                    id: 'resourceRequestDrawerDetails.allocatedCost'
                  })}
                >
                  {isCombinedResourceRequest ? (
                    <Decimal
                      precision={decimalScale}
                      value={allocationTotals.cost[0].amount}
                      prefix={allocationTotals.cost[0].currencySymbol || ''}
                      fixedDecimalScale={false}
                    />
                  ) : (
                    <RequestDrawerAllocatedCost
                      requestedCost={requestedCost}
                      allocatedCost={allocationTotals.cost}
                      fixedDecimalScale={false}
                      precision={decimalScale}
                    />
                  )}
                </RowItem>
              ) : null}
            </ul>
          </>
        )}
        <RequestDrawerResourceAllocationList
          resourceRequest={resourceRequest}
          resourceAllocations={resourceAllocations}
          resourceRequestTotalHours={resourceRequestTotalHours}
          resourceRequestTotalCost={requestedCost}
        />
      </div>
    </div>
  );
};

ResourceRequestDrawerAllocationDetails.propTypes = {
  project: PropTypes.object.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocations: PropTypes.array
};

export default ResourceRequestDrawerAllocationDetails;
