import React from 'react';
import { makeCriteriaProvider } from './enhancers';
import ScoreMetricFacetDetails from './ScoreMetricFacetDetails';
import ScoreTotalFacet from './ScoreTotalFacet';
import {
  investmentScoreMetricTag,
  riskScoreMetricTag,
  totalScoreTag,
  valueScoreMetricTag
} from './tag';

const createFacetControl = (labelResourceKey, metric) => (props = {}) => (
  <ScoreMetricFacetDetails
    labelResourceKey={labelResourceKey}
    metric={metric}
    {...props}
  />
);

export const ValueScoreMetricFacet = {
  tag: valueScoreMetricTag,
  makeCriteriaProvider,
  DetailsControl: createFacetControl('search.value', 'Value')
};

export const RiskScoreMetricFacet = {
  tag: riskScoreMetricTag,
  makeCriteriaProvider,
  DetailsControl: createFacetControl('search.risk', 'Risk')
};

export const InvestmentScoreMetricFacet = {
  tag: investmentScoreMetricTag,
  makeCriteriaProvider,
  DetailsControl: createFacetControl('search.investment', 'Investment')
};

export const TotalScoreFacet = {
  tag: totalScoreTag,
  makeCriteriaProvider,
  DetailsControl: ScoreTotalFacet
};
