import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import flow from 'lodash/fp/flow';
import omit from 'lodash/fp/omit';
import update from 'lodash/fp/update';
import clientRatesFragment from '~/modules/common/enhancers/clientRates.fragment';
import { toRepliconDate } from '~/modules/common/dates/convert';
import { RATE_TYPE } from './usePutProjectRates';

const omitForbiddenFields = flow(
  omit('__typename'),
  update('currency', flow(omit('__typename')))
);

export const PUT_CLIENT_RATES = gql`
  mutation putClientRates($clientId: String!, $rates: [ProjectRateInput2!]) {
    putClientRates: putClientRates3(clientId: $clientId, rates: $rates) {
      client {
        id
        ...ClientRates
      }
    }
  }
  ${clientRatesFragment}
`;

const mapToScheduleEntryInput = ({ effectiveDate, amount }) => ({
  effectiveDate: toRepliconDate(effectiveDate),
  amount: omitForbiddenFields(amount)
});

const mapToProjectRatesInput = (rates, rateTypeKey) =>
  (rates || []).map(r => ({
    rateType: RATE_TYPE[rateTypeKey],
    roleId: rateTypeKey === 'roleRates' ? r.roleReference.id : null,
    userId: rateTypeKey === 'userRates' ? r.userReference.id : null,
    billingScheduleEntries: (r.billingScheduleEntries || []).map(
      mapToScheduleEntryInput
    ),
    costScheduleEntries: (r.costScheduleEntries || []).map(
      mapToScheduleEntryInput
    )
  }));

export const usePutClientRates = () => {
  const [putClientRates] = useMutation(PUT_CLIENT_RATES);

  return {
    putClientRates: async ({ clientId, rates, rateTypeKey }) =>
      putClientRates({
        variables: {
          clientId,
          rates:
            rates && rates.length
              ? mapToProjectRatesInput(rates, rateTypeKey)
              : [
                  {
                    rateType: RATE_TYPE[rateTypeKey],
                    billingScheduleEntries: [],
                    costScheduleEntries: []
                  }
                ]
        },
        refetchQueries: ['GetClientRates', 'getEffectiveRates'],
        awaitRefetchQueries: true
      })
  };
};
