import { gql } from 'graphql-tag';

export const CLIENT_ID_QUERY_BY_SLUG = gql`
  query clientBySlug($slug: String!) {
    client(slug: $slug) {
      id
      name
    }
  }
`;
