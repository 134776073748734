import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { PAGE_SIZE } from '~/modules/common/const';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';

export const TAG_DROPDOWN_QUERY = gql`
  query TagKeyDropdownSearch(
    $textSearch: String
    $objectBinding: ObjectBinding
    $pageSize: Int
  ) {
    tags(
      page: 1
      pageSize: $pageSize
      objectBinding: $objectBinding
      searchTerm: $textSearch
    ) {
      id
      name
    }
  }
`;

const mapOptions = options =>
  options.map(option => ({
    label: option.name,
    value: option.id
  }));

export const useTagKeys = ({ search, objectBinding }) => {
  const { data, loading } = useQuery(TAG_DROPDOWN_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search,
      pageSize: PAGE_SIZE + 1,
      objectBinding
    },
    context: {
      debounceKey: `tag-search`,
      debounceTimeout: 250
    }
  });

  const resultOptions =
    data && data.tags && Array.isArray(data.tags) ? mapOptions(data.tags) : [];

  return {
    data: resultOptions,
    loading,
    hasMore: resultOptions.length > PAGE_SIZE
  };
};

export const TagKeyDropdown = ({
  value,
  onChange,
  objectBinding,
  menuPlacement,
  isOptionDisabled,
  disableAttachment,
  intl,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = useCallback(
    param =>
      param &&
      param.target &&
      param.target.value &&
      setSearchTerm(param.target.value),
    [setSearchTerm]
  );

  const { loading, hasMore, data } = useTagKeys({
    objectBinding,
    search: searchTerm
  });

  const selectedValue = useMemo(
    () =>
      value
        ? {
            label: value && value.name,
            value: value && value.id
          }
        : null,
    [value]
  );

  const getOptionDisabled = useCallback(
    option =>
      isOptionDisabled(option) || option.id === MORE_AVAILABLE_OPTION_ID,
    [isOptionDisabled]
  );

  return (
    <SimpleAutocomplete
      loading={loading}
      options={data}
      hiddenLabel
      fullWidth
      variant="standard"
      onInputChange={handleInputChange}
      onChange={onChange}
      optionPropText="label"
      placeholder={formatMessage({ id: 'tags.tagSearch' })}
      inputLabel={formatMessage({ id: 'tags.tagSearch' })}
      value={selectedValue}
      getOptionDisabled={getOptionDisabled}
      hasMore={hasMore}
      optionTypeText={formatMessage({
        id: 'moreOptions.tags'
      })}
    />
  );
};

TagKeyDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  objectBinding: PropTypes.string,
  disableAttachment: PropTypes.bool,
  menuPlacement: PropTypes.string,
  isOptionDisabled: PropTypes.func,
  intl: PropTypes.object
};

export default TagKeyDropdown;
