import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { useVoidBill } from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/';
import { LoadingButton } from '~/modules/common/components';
import { useTrackBillDataCalculation } from './hooks';

const useStyles = makeStyles(theme => ({
  formHelperText: {
    textAlign: 'right'
  },
  description: {
    paddingBottom: theme.spacing(1.5)
  }
}));

const descriptionLength = 150;

export const VoidBillConfirmationDialog = ({
  open,
  onClose,
  billId,
  refetchAvailableToBillTotals
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [values, setValues] = useState({
    description: ''
  });

  const [isConvertingToVoid, setIsConvertingToVoid] = useState(false);

  const { voidBill } = useVoidBill({ billId });
  const { trackBillDataCalculation } = useTrackBillDataCalculation({
    billId,
    refetchAvailableToBillTotals
  });
  const handleChange = description => event => {
    setValues({ ...values, [description]: event.target.value });
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(async () => {
    setIsConvertingToVoid(true);
    await voidBill(values.description);
    await trackBillDataCalculation();
    setIsConvertingToVoid(false);
    onClose();
  }, [onClose, trackBillDataCalculation, values.description, voidBill]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullScreen={useMediaQuery(theme => theme.breakpoints.down('sm'), {
        noSsr: true
      })}
      open={open}
      onClose={onClose}
      TransitionProps={useMemo(() => ({ role: 'presentation' }), [])}
    >
      <DialogTitle>
        <FormattedMessage id="voidBillDialog.voidBill" />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" className={classes.description}>
          <FormattedMessage id="voidBillDialog.details" />
        </Typography>
        <TextField
          rows={8}
          multiline
          inputProps={useMemo(
            () => ({
              maxLength: descriptionLength
            }),
            []
          )}
          label={intl.formatMessage({
            id: 'voidBillDialog.description'
          })}
          placeholder={intl.formatMessage({
            id: 'voidBillDialog.placeholder'
          })}
          FormHelperTextProps={useMemo(
            () => ({
              className: classes.formHelperText
            }),
            [classes.formHelperText]
          )}
          fullWidth
          helperText={`${values.description.length}/${descriptionLength}`}
          onChange={handleChange('description')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isConvertingToVoid}>
          <FormattedMessage id="voidBillDialog.cancel" />
        </Button>
        <LoadingButton
          data-qe-id="ConfirmDialogButton"
          onClick={handleSave}
          color="secondary"
          isLoading={isConvertingToVoid}
          autoFocus
        >
          <FormattedMessage id="voidBillDialog.voidBill" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

VoidBillConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  billId: PropTypes.string,
  refetchAvailableToBillTotals: PropTypes.func
};

export default VoidBillConfirmationDialog;
