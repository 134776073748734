import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import {
  DragEndIndicator,
  DragStartIndicator,
  TimelineBlock
} from '~/modules/resourcing/common/chart/components';
import {
  getAllocationStatusBasedClassName,
  RESIZE_DIRECTION_ENUM
} from '~/modules/resourcing/common/enums';
import { isInChartDisplayDateRange } from '~/modules/resourcing/common/hooks/useClippingDateRangeUtil';
import {
  getDayViewRoundedHours,
  getDayViewRoundedPercentage
} from '~/modules/resourcing/common/util/scheduleUtil';
import ResourceUserRequestedCostAllocationTooltip from '../ResourceUserRequestedCostAllocationTooltip';
import { useStyles } from './useStyles';

const ResourceUserRequestAllocationSummaryBlock = ({
  allocation,
  chartDisplayDateRange,
  classes: classesOverrides,
  dates,
  dragDelta,
  endDate,
  gestureBindEvents,
  handleAllocationPeriodClick,
  hasAllocationEnd,
  hasAllocationStart,
  isEditable,
  overlayPeriods,
  periodDynamicPostion,
  requestPeriods,
  resizeDirection,
  resourceAvailabilitySummary,
  resourceRequest,
  scale,
  showResizeIndicator,
  startDate,
  totalAllocatedHours,
  totalCost,
  totalScheduledHours
}) => {
  const loading = totalScheduledHours
    ? (totalAllocatedHours * 100) / totalScheduledHours
    : 0;

  const classes = useStyles({
    classes: classesOverrides
  });

  const dragIndicatorClasses = useMemo(
    () => ({
      resizeHandle: classNames({
        [classes.resizeHandle]: true,
        [classes.resizeHandleDisplay]: !isEditable
      }),
      resizeHandleStart: classes.resizeHandleStart,
      resizeHandleEnd: classes.resizeHandleEnd,
      resizeIcon: classes.resizeIcon
    }),
    [
      classes.resizeHandle,
      classes.resizeHandleDisplay,
      classes.resizeHandleStart,
      classes.resizeHandleEnd,
      classes.resizeIcon,
      isEditable
    ]
  );

  const handleClickAndKeyPress = useCallback(
    event => {
      if (
        event.type === 'click' ||
        event.key === 'Enter' ||
        event.keyCode === 13
      ) {
        if (handleAllocationPeriodClick) {
          handleAllocationPeriodClick({
            allocation,
            endDate,
            event,
            hasAllocationEnd,
            hasAllocationStart,
            isOverlay: false,
            overlayPeriods,
            requestPeriods,
            resourceAvailabilitySummary,
            startDate,
            totalAllocatedHours,
            totalScheduledHours
          });
        }
      }
    },
    [
      allocation,
      endDate,
      handleAllocationPeriodClick,
      hasAllocationEnd,
      hasAllocationStart,
      overlayPeriods,
      requestPeriods,
      resourceAvailabilitySummary,
      startDate,
      totalAllocatedHours,
      totalScheduledHours
    ]
  );

  const alcStart = mapIsoStringtoUtcObject(allocation.startDate);
  const alcEnd = mapIsoStringtoUtcObject(allocation.endDate);

  const toolTipStartDate =
    alcStart > startDate && alcStart < endDate ? alcStart : startDate;

  const toolTipEndDate =
    alcEnd < endDate && alcEnd > startDate ? alcEnd : endDate;

  const title = useMemo(() => {
    return (
      <ResourceUserRequestedCostAllocationTooltip
        totalAllocatedHours={totalAllocatedHours}
        loading={loading}
        totalCost={totalCost}
        startDate={toolTipStartDate}
        endDate={toolTipEndDate}
        resourceRequest={resourceRequest}
      />
    );
  }, [
    totalAllocatedHours,
    loading,
    totalCost,
    toolTipStartDate,
    toolTipEndDate,
    resourceRequest
  ]);

  const isInChartRange = isInChartDisplayDateRange({
    chartDisplayDateRange,
    entityDateRange: { startDate, endDate }
  });

  const dynamicPosition = useMemo(() => {
    const leftMostPeriod =
      requestPeriods &&
      requestPeriods[0].startDate === startDate &&
      resizeDirection === RESIZE_DIRECTION_ENUM.START;
    const rightMostPeriod =
      requestPeriods &&
      requestPeriods[requestPeriods.length - 1].endDate === endDate &&
      resizeDirection === RESIZE_DIRECTION_ENUM.END;

    return {
      left: leftMostPeriod
        ? dragDelta + periodDynamicPostion.left
        : periodDynamicPostion.left,
      width:
        leftMostPeriod && dragDelta < 0
          ? periodDynamicPostion.width - dragDelta
          : rightMostPeriod
          ? periodDynamicPostion.width + dragDelta
          : periodDynamicPostion.width
    };
  }, [
    startDate,
    endDate,
    periodDynamicPostion,
    requestPeriods,
    resizeDirection,
    dragDelta
  ]);

  const timelineClassName = classNames(
    classes.container,
    classes[
      getAllocationStatusBasedClassName(
        allocation.allocationStatus,
        'container'
      )
    ]
  );

  const percentageValue = (totalAllocatedHours * 100) / totalScheduledHours;

  const showPercentage = totalScheduledHours > 0;

  const percentage = getDayViewRoundedPercentage({
    percentage: percentageValue,
    scale
  });

  const totalHours = getDayViewRoundedHours({
    hours: totalAllocatedHours,
    scale
  });

  const showStartIndicator = showResizeIndicator && hasAllocationStart;

  const showEndIndicator = showResizeIndicator && hasAllocationEnd;

  return (
    isInChartRange && (
      <div className={classes.root} style={dynamicPosition}>
        <Tooltip title={title}>
          <div
            className={timelineClassName}
            role="button"
            onClick={handleClickAndKeyPress}
            tabIndex="0"
            onKeyPress={handleClickAndKeyPress}
            aria-hidden="true"
          >
            <TimelineBlock
              dataQeId={`${allocation.user.user.displayText}_summaryBlocks`}
              hours={totalHours}
              showPercentage={showPercentage}
              percentage={percentage}
            />
          </div>
        </Tooltip>
        {showStartIndicator && (
          <DragStartIndicator
            classes={dragIndicatorClasses}
            gestureBindEvents={gestureBindEvents}
            startDate={dates.startDate}
            showTooltip
          />
        )}
        {showEndIndicator && (
          <DragEndIndicator
            classes={dragIndicatorClasses}
            gestureBindEvents={gestureBindEvents}
            endDate={dates.endDate}
            showTooltip
          />
        )}
      </div>
    )
  );
};

ResourceUserRequestAllocationSummaryBlock.propTypes = {
  scale: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  totalAllocatedHours: PropTypes.number,
  totalScheduledHours: PropTypes.number.isRequired,
  totalCost: PropTypes.object,
  requestPeriods: PropTypes.array,
  gestureBindEvents: PropTypes.any,
  periodDynamicPostion: PropTypes.object,
  resizeDirection: PropTypes.string,
  dragDelta: PropTypes.number,
  classes: PropTypes.object,
  overlayPeriods: PropTypes.array,
  showResizeIndicator: PropTypes.bool,
  allocation: PropTypes.object.isRequired,
  hasAllocationStart: PropTypes.bool,
  hasAllocationEnd: PropTypes.bool,
  isEditable: PropTypes.bool,
  handleAllocationPeriodClick: PropTypes.func,
  chartDisplayDateRange: PropTypes.object,
  resourceAvailabilitySummary: PropTypes.object,
  resourceRequest: PropTypes.object,
  dates: PropTypes.object
};

export default ResourceUserRequestAllocationSummaryBlock;
