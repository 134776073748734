import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, makeStyles } from '@material-ui/core';
import CreatableCategoryOption from './CreatableCategoryOption';

const useStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

export const SkillCategoryDropdownItem = ({ option, onCreate }) => {
  const { isCreatable } = option;
  const classes = useStyles();

  return isCreatable ? (
    <CreatableCategoryOption option={option} onCreate={onCreate} />
  ) : (
    <ListItemText primary={option.displayText} classes={classes} />
  );
};

SkillCategoryDropdownItem.propTypes = {
  option: PropTypes.object.isRequired,
  onCreate: PropTypes.func
};

export default SkillCategoryDropdownItem;
