import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_SETTINGS = gql`
  query getSettings($revenueRunUri: String!) {
    revenueRunBatchUriInProgress(revenueRunUri: $revenueRunUri)
  }
`;

export const useGetRevenueRunSettings = revenueRunUri => {
  const { loading, data } = useQuery(GET_SETTINGS, {
    variables: {
      revenueRunUri
    },
    skip: revenueRunUri === undefined,
    fetchPolicy: 'network-only'
  });

  return {
    batchUri:
      data && data.revenueRunBatchUriInProgress
        ? data.revenueRunBatchUriInProgress
        : null,
    loading
  };
};

export default useGetRevenueRunSettings;
