import { object, string } from 'yup';
import { useFormik } from 'formik';
import { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { filterSummarizeColumnOptions } from '~/modules/billing-invoicing/common/util';

export const buildValidationSchema = ({ intl }) =>
  object().shape({
    description: string().max(
      255,
      intl.formatMessage({
        id: 'billDetails.validations.descriptionExceedsMaxLength'
      })
    )
  });

export const useInitialState = () => ({
  project: null,
  description: '',
  unit: null,
  label: '',
  rate: null,
  amount: 0
});

export const useOnSubmit = ({ putLineItemForBill, billDetails }) =>
  useCallback(
    async values => {
      const billId = billDetails.id;
      const filteredSummarizeColumnOptions = filterSummarizeColumnOptions(
        billDetails.summarizeColumnOptions
      );
      const { amount, project, description, rate, quantity } = values;

      await putLineItemForBill(
        {
          billId,
          lineItem: {
            amount,
            projectUri:
              project && project.id !== 'none-option' ? project.id : null,
            description,
            rate,
            quantity
          }
        },
        billId,
        filteredSummarizeColumnOptions
      );
    },
    [billDetails, putLineItemForBill]
  );

export const usePutCustomLineItemFormState = ({
  putLineItemForBill,
  billDetails
}) => {
  const intl = useIntl();
  const initialValues = useInitialState();
  const onSubmit = useOnSubmit({
    putLineItemForBill,
    billDetails
  });
  const validationSchema = useMemo(() => buildValidationSchema({ intl }), [
    intl
  ]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  });

  return formik;
};

export default usePutCustomLineItemFormState;
