import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  primary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const ResourcePoolDropdownOption = ({ value }) => {
  const classes = useStyles();

  return (
    <ListItemText
      primary={value}
      className={classes.primary}
      classes={classes}
    />
  );
};

ResourcePoolDropdownOption.propTypes = {
  value: PropTypes.string.isRequired
};

export default ResourcePoolDropdownOption;
