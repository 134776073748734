import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import OkButton from '~/modules/common/components/OkButton';
import CancelButton from '~/modules/common/components/CancelButton';
import UnsavedChangesTitle from './UnsavedChangesTitle';
import UnsavedChangesText from './UnsavedChangesText';

export const UnsavedChangesPromptDialog = ({
  open,
  onCancelClick,
  onOkClick
}) => (
  <Dialog open={open} onClose={onCancelClick}>
    <DialogTitle disableTypography>
      <Typography role="presentation" variant="h6">
        <UnsavedChangesTitle />
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body2">
        <UnsavedChangesText />
      </Typography>
    </DialogContent>
    <DialogActions>
      <CancelButton
        variant="contained"
        onClick={onCancelClick}
        color="primary"
      />
      <OkButton variant="text" onClick={onOkClick} />
    </DialogActions>
  </Dialog>
);

UnsavedChangesPromptDialog.propTypes = {
  open: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onOkClick: PropTypes.func
};

export default UnsavedChangesPromptDialog;
