import { useMemo } from 'react';

export const useValue = ({ id, record, field, defaultRate, intl }) =>
  useMemo(() => {
    if (record[field].value === defaultRate) {
      return defaultRate;
    }

    if (record[field].value?.displayText === 'OBJECT_DELETED') {
      return {
        ...record[field].value,
        displayText: intl.formatMessage({ id })
      };
    }

    return record[field].value;
  }, [defaultRate, field, id, intl, record]);

export const disableAddEntryButton = ({
  projectDimensions,
  dimensionIndex,
  record
}) =>
  (projectDimensions || []).some(({ id: dId }, i) =>
    i < dimensionIndex
      ? record[dId].value?.displayText === 'OBJECT_DELETED'
      : false
  );
