import { useCallback, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { getRequestRoleAttributesFromRoleChangeEvent } from '~/modules/resourcing/common/util';

export const defaultOnChangeHandler = ({ key, setFieldValue }) => event =>
  setFieldValue(
    key,
    event &&
      Object.prototype.hasOwnProperty.call(event, 'target') &&
      Object.prototype.hasOwnProperty.call(event.target, 'value')
      ? event.target.value
      : event
  );

export const formWithOnChangeHandlers = ({
  handlers,
  defaultHandler = defaultOnChangeHandler,
  form
} = {}) => ({
  form: {
    ...form,
    fields: Object.keys(form.fields).reduce(
      (fields, key) => ({
        ...fields,
        [key]: {
          ...form.fields[key],
          onChange:
            handlers && Object.prototype.hasOwnProperty.call(handlers, key)
              ? handlers[key]
              : defaultHandler({ key, setFieldValue: form.setFieldValue })
        }
      }),
      {}
    )
  }
});

export const useAttachOnChangeHandlersToForm = form => {
  const { setFieldValue, setValues, values } = form;
  const useChangeHandler = key =>
    useCallback(value => setFieldValue(key, value));

  const locationChangeHandler = useChangeHandler('location');
  const divisionChangeHandler = useChangeHandler('division');
  const serviceCenterChangeHandler = useChangeHandler('serviceCenter');
  const costCenterChangeHandler = useChangeHandler('costCenter');
  const departmentChangeHandler = useChangeHandler('department');
  const employeeTypeChangeHandler = useChangeHandler('employeeType');
  const resourcePoolsChangeHandler = useChangeHandler('resourcePools');

  const roleChangeHandler = (value, baseCurrency) => {
    setValues(
      {
        ...values,
        role: value,
        skills: sortBy(value?.skills || [], skillItem => skillItem.displayText),
        ...getRequestRoleAttributesFromRoleChangeEvent(value, baseCurrency)
      },
      true
    );
  };

  const currencyChangeHandler = value => {
    setValues(
      {
        ...values,
        currency: {
          ...value,
          __typename: 'Currency'
        },
        currencyUri: value.id
      },
      true
    );
  };

  const roleRateChangeHandler = event =>
    setFieldValue('roleRate', event.target.value || 0);

  const handlers = useMemo(
    () => ({
      location: locationChangeHandler,
      division: divisionChangeHandler,
      serviceCenter: serviceCenterChangeHandler,
      costCenter: costCenterChangeHandler,
      department: departmentChangeHandler,
      employeeType: employeeTypeChangeHandler,
      role: roleChangeHandler,
      currency: currencyChangeHandler,
      roleRate: roleRateChangeHandler,
      resourcePools: resourcePoolsChangeHandler
    }),
    [
      locationChangeHandler,
      divisionChangeHandler,
      serviceCenterChangeHandler,
      costCenterChangeHandler,
      departmentChangeHandler,
      employeeTypeChangeHandler,
      roleChangeHandler,
      currencyChangeHandler,
      roleRateChangeHandler,
      resourcePoolsChangeHandler
    ]
  );

  return formWithOnChangeHandlers({
    handlers,
    form
  });
};

export default useAttachOnChangeHandlersToForm;
