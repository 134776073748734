import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import Decimal from '~/modules/common/components/Decimal';
import {
  CHILD_INFO_DIALOG_COLUMN_TYPE,
  TASK_STATUS
} from '~/modules/common/enums';
import { useDialogState, useMenuState } from '~/modules/common/hooks';
import { TaskVariationPill } from '~/modules/common/components/TaskVariationPill';
import { useStyles as usePillStyles } from '~/modules/common/components/TaskVariationPill/hooks';
import NotStartedVariationValue from '../../NotStartedVariationValue';
import useCalculateVariationDetails from '../useCalculateVariationDetails';
import NoDataCell from './NoDataCell';
import ChildInfoDialog from './ChildInfoDialog/ChildInfoDialog';

const useStyles = makeStyles(theme => ({
  hoursCell: ({
    isVariationHoursInBold,
    isPsaPpmEstimatedCostAtCompletionEnabled,
    isVariationPillNotDisplayed,
    useWideVariant,
    addPaddingRight
  }) => ({
    display: 'flex',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingRight: addPaddingRight ? theme.spacing(3.75) : 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    minWidth: useWideVariant ? theme.spacing(18.75) : theme.spacing(15),
    fontWeight: isVariationHoursInBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight,
    ...(isPsaPpmEstimatedCostAtCompletionEnabled && {
      alignSelf: isVariationPillNotDisplayed ? 'baseline' : 'self-end',
      justifySelf: 'flex-end'
    })
  }),
  data: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: 'inherit'
  },
  variationPillContainer: {
    display: 'flex'
  },
  icon: {
    cursor: 'pointer',
    marginTop: '1px',
    marginLeft: theme.spacing(1.75),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.75)
  }
}));

const usePillStylesOverride = makeStyles(theme => ({
  variationPill: {
    padding: theme.spacing(0.375, 1, 0.125),
    borderRadius: theme.spacing(0.5)
  },
  variationText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  },
  variationValue: ({ isVariationInBold }) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: isVariationInBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight
  }),
  variationLabel: {
    display: 'none'
  }
}));

const selectedColumns = [
  CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
  CHILD_INFO_DIALOG_COLUMN_TYPE.HOURS_VARIATION
];

export const HoursVariationCell = ({
  classes: classesOverride,
  hasMore,
  loadMore,
  loadingMore,
  task,
  atleastOneRowHasTooltip,
  useWideVariant = true,
  isPsaPpmEstimatedCostAtCompletionEnabled,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacAlternateHoursEacEnabled,
  hideTooltip
}) => {
  const intl = useIntl();
  const { open, openDialog, closeDialog } = useDialogState();
  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();

  const { variationStatus, variationHours } = useCalculateVariationDetails({
    task
  });

  const isLeafTask = task.children.length === 0;
  const isNotStarted = task.taskStatus === TASK_STATUS.NOTSTARTED;

  const isVariationPillNotDisplayed =
    (isNotStarted && isLeafTask) || variationHours === null;

  const addPaddingRight =
    hideTooltip ||
    (isPsaPpmCostEacAlternateHoursEacEnabled &&
      atleastOneRowHasTooltip &&
      isLeafTask) ||
    (isPsaPpmEstimatedCostAtCompletionEnabled &&
      variationHours === null &&
      !isLeafTask);

  const pillClassesOverride = usePillStylesOverride({
    isVariationInBold: variationHours > 0 || variationHours < 0
  });
  const pillClasses = usePillStyles({
    classes: pillClassesOverride,
    isPsaPpmCostEacEnhancementsEnabled
  });
  const classes = useStyles({
    classes: classesOverride,
    isVariationPillNotDisplayed,
    isPsaPpmEstimatedCostAtCompletionEnabled,
    useWideVariant,
    addPaddingRight,
    isVariationHoursInBold: variationHours > 0 || variationHours < 0
  });

  const iconClasses = useMemo(() => ({ icon: classes.icon }), [classes]);

  const handleClick = useCallback(
    event => {
      onMenuClick(event);
      openDialog();
    },
    [onMenuClick, openDialog]
  );

  const handleClosePopover = useCallback(() => {
    onMenuClose();
    closeDialog();
  }, [onMenuClose, closeDialog]);

  return (
    <div className={classes.hoursCell} role="cell">
      {variationHours === null ? (
        <NoDataCell />
      ) : isNotStarted && isLeafTask ? (
        isPsaPpmCostEacEnhancementsEnabled ? (
          <NotStartedVariationValue
            className={classes.data}
            variant="hours"
            variation={variationHours}
            variationStatus={variationStatus}
          />
        ) : (
          <Decimal
            className={classes.data}
            value={variationHours}
            fixedDecimalScale
          />
        )
      ) : (
        <div className={classes.variationPillContainer}>
          <TaskVariationPill
            intl={intl}
            classes={pillClasses}
            variationStatus={variationStatus}
            value={variationHours}
            displayType="text"
            fixedDecimalScale
            hideStatusMessage={isPsaPpmEstimatedCostAtCompletionEnabled}
          />
          {!hideTooltip && !isLeafTask && (
            <>
              <InfoSharpIcon className={classes.icon} onClick={handleClick} />
              <ChildInfoDialog
                childTasks={task.children}
                classes={iconClasses}
                selectedColumns={selectedColumns}
                open={open}
                onClose={handleClosePopover}
                anchorEl={anchorEl}
                hasMore={hasMore}
                loadMore={loadMore}
                loadingMore={loadingMore}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

HoursVariationCell.propTypes = {
  classes: PropTypes.object,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  atleastOneRowHasTooltip: PropTypes.bool,
  useWideVariant: PropTypes.bool,
  isPsaPpmEstimatedCostAtCompletionEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacAlternateHoursEacEnabled: PropTypes.bool,
  hideTooltip: PropTypes.bool
};

export default HoursVariationCell;
