import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
  ListItemSecondaryAction,
  ListSubheader
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import ListTablePropTypes from '../PropTypes';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  subHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white
  }
}));

const primary = <Skeleton variant="rect" />;

const DefaultListItemSkeleton = () => (
  <>
    <ListItem>
      <ListItemText primary={primary} />
      <ListItemSecondaryAction>
        <Skeleton variant="circle" height={24} width={24} />
      </ListItemSecondaryAction>
    </ListItem>
    <Divider component="li" role="presentation" />
  </>
);

export const MobileListItemSkeletons = ({
  mobileListItemSkeleton: MobileListItemSkeleton
}) => {
  const ListItemSkeleton = MobileListItemSkeleton || DefaultListItemSkeleton;

  return (
    <>
      <ListItemSkeleton />
      <ListItemSkeleton />
      <ListItemSkeleton />
    </>
  );
};

MobileListItemSkeletons.propTypes = {
  mobileListItemSkeleton: PropTypes.func
};

export const MobileList = ({
  classes: classesOverride,
  mobileListItem: MobileListItem,
  mobileListItemSkeleton,
  records,
  onListItemClick,
  isLoading,
  editable,
  selected = {},
  subHeaderId,
  onRowSelectionChange
}) => {
  const classes = useStyles({ classes: classesOverride });
  const selectionMode = selected.records?.length > 0;

  return (
    <List className={classes.root}>
      {isLoading ? (
        <MobileListItemSkeletons
          mobileListItemSkeleton={mobileListItemSkeleton}
        />
      ) : (
        <>
          {subHeaderId && (
            <ListSubheader className={classes.subHeader}>
              <FormattedMessage id={subHeaderId} />
            </ListSubheader>
          )}
          {records.map((record, index) => (
            <Fragment key={record.id}>
              <MobileListItem
                record={record}
                rowIndex={index}
                editable={editable}
                isSelected={(selected.records || []).includes(record.id)}
                onClick={onListItemClick}
                selectionMode={selectionMode}
                onRowSelectionChange={onRowSelectionChange}
              />
              <Divider component="li" role="presentation" />
            </Fragment>
          ))}
        </>
      )}
    </List>
  );
};

MobileList.propTypes = {
  classes: PropTypes.object,
  mobileListItem: PropTypes.any,
  mobileListItemSkeleton: PropTypes.func,
  records: PropTypes.any,
  isLoading: PropTypes.bool,
  onListItemClick: PropTypes.func,
  editable: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  selected: ListTablePropTypes.selected,
  subHeaderId: PropTypes.string
};

export default MobileList;
