import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { useIsBreakpointDown } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  message: {
    marginBottom: theme.spacing(2)
  }
}));

export const UnauthorizedDescription = ({ descriptionId = '' }) => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Typography
      component="p"
      variant={isMobile ? 'body1' : 'h5'}
      className={classes.message}
    >
      <FormattedMessage id={descriptionId || 'unauthorizedPage.description'} />
    </Typography>
  );
};

UnauthorizedDescription.propTypes = {
  descriptionId: PropTypes.string
};

export default UnauthorizedDescription;
