import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import NoDataItem from '~/modules/common/components/NoDataItem';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    padding: theme.spacing(2)
  }
}));

const ErrorMessage = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.errorMessage}>
      <NoDataItem>
        {intl.formatMessage({ id: 'vendorListTable.errorMsg' })}
      </NoDataItem>
    </div>
  );
};

export default ErrorMessage;
