import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[500],
    verticalAlign: 'middle',
    '& svg': {
      fontSize: theme.typography.h6.fontSize
    }
  }
}));

export const SideCardSectionIconDetail = ({ icon, detail }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.icon}>{icon}</span>
      <Typography variant="caption" noWrap>
        {detail}
      </Typography>
    </div>
  );
};

SideCardSectionIconDetail.propTypes = {
  icon: PropTypes.node,
  detail: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default SideCardSectionIconDetail;
