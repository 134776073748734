import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RefundIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 21,9 H 5 V 22 H 21 Z M 19,20 H 7 v -4 h 12 z m 0,-7 H 7 v -2 h 12 z" />
      <path d="M 6,3 V 1 L 2,4.5 6,8 V 6 C 9,6 12.28813559246913,6.277401129567885 15,8.5 13.44971751305113,5.324858756525547 10.27966101724427,3.279096044929449 6,3 Z" />
    </>
  </SvgIcon>
));

export default RefundIcon;
