import { useMutation, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { invokeUpdateResourceRequest } from '~/modules/projects/resourcing-plan/hooks/useUpdateResourceRequestHandlers';
import { useMeContext } from '~/modules/me';
import { useSessionStorage } from '~/modules/common/hooks';
import {
  CREATE_RESOURCE_REQUEST,
  duplicateResourceRequest
} from '../enhancers/withDuplicateResourceRequestHandler';
import { useGetResourceRequestFilter } from './useGetResourceRequestFilter';
import useUpdateResourceRequest from './useUpdateResourceRequest';

export const getRoundedValue = (value, precision = 2) => {
  const roundedValue =
    Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

  return roundedValue === 0 ? 0 : roundedValue;
};

export const useSecondaryResourceRequestActionHandlers = ({
  resourceRequest,
  isResourceActualModeEnabled
}) => {
  const [createResourceRequest] = useMutation(CREATE_RESOURCE_REQUEST);
  const { updateResourceRequest } = useUpdateResourceRequest();
  const me = useMeContext();
  const apolloClient = useApolloClient();
  const { storedValue: variables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const filter = useGetResourceRequestFilter();

  const onResourceRequestDuplicate = useCallback(
    () =>
      duplicateResourceRequest({
        createResourceRequest,
        me,
        variables,
        filter,
        isResourceActualModeEnabled
      })(resourceRequest),
    [
      createResourceRequest,
      me,
      variables,
      resourceRequest,
      filter,
      isResourceActualModeEnabled
    ]
  );

  const onResourceRequestSplit = useCallback(() => {
    const updatedQuantity = Math.ceil(resourceRequest.quantity / 2);
    const { totalHours } = resourceRequest;

    duplicateResourceRequest({
      createResourceRequest,
      me,
      variables,
      filter,
      skipTotalsCacheUpdate: true,
      isResourceActualModeEnabled
    })({
      ...resourceRequest,
      quantity: resourceRequest.quantity - updatedQuantity,
      ...(totalHours && {
        totalHours: getRoundedValue(
          (totalHours * (resourceRequest.quantity - updatedQuantity)) /
            resourceRequest.quantity
        )
      })
    });

    invokeUpdateResourceRequest({
      updateResourceRequest,
      apolloClient,
      skipTotalsCacheUpdate: true,
      isResourceActualModeEnabled
    })({
      ...resourceRequest,
      quantity: updatedQuantity,
      ...(totalHours && {
        totalHours: getRoundedValue(
          (totalHours * updatedQuantity) / resourceRequest.quantity
        )
      })
    });
  }, [
    resourceRequest,
    createResourceRequest,
    me,
    variables,
    updateResourceRequest,
    filter,
    apolloClient,
    isResourceActualModeEnabled
  ]);

  return {
    onResourceRequestDuplicate,
    onResourceRequestSplit
  };
};

export default useSecondaryResourceRequestActionHandlers;
