const billTypeResourceKeys = isAccountReceivable => ({
  BILL: !isAccountReceivable
    ? 'billingTransactionList.typeLabel.bill'
    : 'billingTransactionList.typeLabel.referenceNumber',
  PAYMENT: 'billingTransactionList.typeLabel.payment',
  CREDITMEMO: 'billingTransactionList.typeLabel.creditMemo',
  REFUND: 'billingTransactionList.typeLabel.refund'
});

export const getTypeLabel = ({ type, isAccountReceivable }) =>
  billTypeResourceKeys(isAccountReceivable)[type];
