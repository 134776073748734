import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Percentage, Decimal } from '~/modules/common/components';
import { useValueWithPrecision } from '~/modules/common/hooks';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import { DISPLAY_UNIT_ENUM } from '../../enums';

const useStyles = makeStyles(theme => ({
  period: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: theme.spacing(0.5, 0),
    lineHeight: `${theme.spacing(2)}px`,
    textAlign: 'center',
    userSelect: 'none',
    borderRight: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
    '&:last-child': {
      borderRight: 'none'
    },
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 1,
    display: 'block'
  }
}));

export const UserAllocationSummaryLabel = ({
  classes: classesOverride,
  loadRatio,
  totalHours
}) => {
  const { displayUnit } = useDisplayUnitContext();
  const classes = useStyles({ classes: classesOverride });

  const value = useValueWithPrecision({
    value: loadRatio * 100
  });

  return (
    <div className={classes.period}>
      {displayUnit === DISPLAY_UNIT_ENUM.HOURS ? (
        <Decimal
          value={totalHours}
          suffix="h"
          fixedDecimalScale={false}
          enableFixedFormatDecimalScale
        />
      ) : (
        <Percentage value={value} fixedDecimalScale={false} />
      )}
    </div>
  );
};

UserAllocationSummaryLabel.propTypes = {
  classes: PropTypes.object,
  loadRatio: PropTypes.number.isRequired,
  totalHours: PropTypes.number
};

export default UserAllocationSummaryLabel;
