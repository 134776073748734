import React, { createContext, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

export const RESOURCE_ALLOCATION_CHART_TAB_ENUM = {
  ALL: 'ALL',
  PENDING: 'PENDING',
  PROPOSED: 'PROPOSED',
  TOBEHIRED: 'TOBEHIRED'
};
export const getPlanStatus = ({ params: { planStatus } = {} }) => {
  if (!planStatus) return {};

  return {
    planStatus: planStatus.toUpperCase()
  };
};
export const ResourceAllocationChartTabContext = createContext();

export const ResourceAllocationChartTabContextProvider = ({ children }) => {
  const { planStatus } = getPlanStatus(useRouteMatch());
  const initialPlanStatus =
    planStatus || RESOURCE_ALLOCATION_CHART_TAB_ENUM.PENDING;

  const [selectedChartTab, setSelectedChartTab] = useState(initialPlanStatus);

  const value = useMemo(
    () => ({
      selectedChartTab,
      setSelectedChartTab
    }),
    [selectedChartTab, setSelectedChartTab]
  );

  return (
    <ResourceAllocationChartTabContext.Provider value={value}>
      {children}
    </ResourceAllocationChartTabContext.Provider>
  );
};

ResourceAllocationChartTabContextProvider.propTypes = {
  children: PropTypes.object
};

export const useResourceAllocationChartTabStateContext = () => {
  const { selectedChartTab, setSelectedChartTab } =
    useContext(ResourceAllocationChartTabContext) || {};

  return {
    selectedChartTab,
    setSelectedChartTab
  };
};

export default useResourceAllocationChartTabStateContext;
