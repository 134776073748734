import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import {
  useGetKeyValueSettings,
  useWidthExcludePropCheckHack
} from '~/modules/common/hooks';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import {
  createFixedWidthView,
  useFixedRange
} from '~/modules/common/charts/timeline/calculations';
import { ResourceAllocationUserChartLoading } from './components';
import ResourceAllocationUserChartContent from './ResourceAllocationUserChartContent';

const leftComponentFixedWidth = 300;

export const UserChart = memo(
  ({
    width,
    searchCriteria,
    setSearchCriteria,
    chartSettings,
    currentPageForTimeline,
    setCurrentPageForTimeline
  }) => {
    const rangeView = useMemo(
      () => createFixedWidthView(Math.floor(width), leftComponentFixedWidth),
      [width]
    );

    const {
      scale,
      date,
      chartDates,
      dateRange,
      setScale,
      setDate,
      onPrevious,
      onNext
    } = useFixedRange({
      getPeriodCount: rangeView.getPeriodCount,
      anchor: 0,
      defaults:
        chartSettings && chartSettings.scale
          ? {
              scale: chartSettings.scale
            }
          : null
    });

    return (
      <ResourceAllocationUserChartContent
        key={scale}
        scale={scale}
        date={date}
        chartDates={chartDates}
        dateRange={dateRange}
        setScale={setScale}
        setDate={setDate}
        onPrevious={onPrevious}
        onNext={onNext}
        width={width}
        leftComponentWidth={leftComponentFixedWidth}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        defaultDisplayUnit={
          (chartSettings && chartSettings.displayUnit) ||
          DISPLAY_UNIT_ENUM.PERCENTAGE
        }
        currentPageForTimeline={currentPageForTimeline}
        setCurrentPageForTimeline={setCurrentPageForTimeline}
      />
    );
  },
  useWidthExcludePropCheckHack
);

export const ResourceAllocationUserChart = ({
  width,
  searchCriteria,
  setSearchCriteria,
  currentPageForTimeline,
  setCurrentPageForTimeline
}) => {
  const {
    isLoading: isKeyValueSettingsLoading,
    keyValueSettings: chartSettings
  } = useGetKeyValueSettings('allocation_chart_settings');

  if (isKeyValueSettingsLoading || !width)
    return <ResourceAllocationUserChartLoading />;

  return (
    <UserChart
      width={width}
      chartSettings={chartSettings}
      searchCriteria={searchCriteria}
      setSearchCriteria={setSearchCriteria}
      currentPageForTimeline={currentPageForTimeline}
      setCurrentPageForTimeline={setCurrentPageForTimeline}
    />
  );
};

ResourceAllocationUserChart.propTypes = {
  width: PropTypes.number,
  searchCriteria: PropTypes.object,
  setSearchCriteria: PropTypes.func,
  currentPageForTimeline: PropTypes.number,
  setCurrentPageForTimeline: PropTypes.func
};

UserChart.propTypes = {
  width: PropTypes.number,
  searchCriteria: PropTypes.object,
  setSearchCriteria: PropTypes.func,
  chartSettings: PropTypes.object,
  currentPageForTimeline: PropTypes.number,
  setCurrentPageForTimeline: PropTypes.func
};

export default withResizeDetector(ResourceAllocationUserChart);
