import React from 'react';
import { retryLoading } from '~/util';

const ResourceDetails = React.lazy(() =>
  retryLoading(() => import('./ResourceDetails'))
);

const route = ({ intl, me }) => ({
  path: '/resource-pool/:slug',
  icon: () => null,
  hidden: true,
  title: () => intl.formatMessage({ id: 'resourceManagementList.details' }),
  component: ResourceDetails,
  hasPermission: Boolean(
    me.permissions.find(
      p =>
        (p.permissionActionUri === 'urn:replicon:user-action:view-user' &&
          p.dataAccessLevelUris.includes(
            'urn:replicon:user-data-access-level:resource-manager'
          )) ||
        (p.permissionActionUri === 'urn:replicon:user-action:view-user' &&
          p.dataAccessLevelUris.includes(
            'urn:replicon:user-data-access-level:resource-pool-manager'
          ))
    )
  )
});

export default route;
