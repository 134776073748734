import {
  getWorkDaysBetweenDates,
  getDaysBetweenDates
} from '~/modules/common/dates/compare';
import {
  toRepliconDate,
  isoStringToObjectWithCache as isoStringToObject
} from '~/modules/common/dates/convert';
import { MODE } from './enum';

export const getDurationSummaryValues = (
  startDate,
  endDate,
  includeEndDay = true
) => {
  const workDays =
    startDate &&
    endDate &&
    getWorkDaysBetweenDates({
      startDateInRepliconFormat: toRepliconDate(startDate),
      endDateInRepliconFormat: toRepliconDate(endDate)
    });

  const calendarDays = getDaysBetweenDates(
    isoStringToObject(endDate),
    isoStringToObject(startDate),
    includeEndDay
  );

  return { calendarDays, workDays };
};

const isDateOnWeekEnd = date =>
  Boolean(date.weekday === 6 || date.weekday === 7);

export const addWorkingDays = ({ date, workDays }) => {
  let newEndDate = date;

  if (workDays <= 0) return newEndDate;
  if (isDateOnWeekEnd(newEndDate)) {
    newEndDate = newEndDate.minus({ days: newEndDate.weekday === 6 ? 1 : 2 });
  }

  const daysToAdd =
    workDays +
    Math.floor((workDays - 1 + (newEndDate.weekday % 6 || 1)) / 5) * 2;

  return newEndDate.plus({ days: daysToAdd });
};

export const calculateNewEndDate = ({
  durationValues,
  newStartDate,
  mode,
  newTaskDateRange
}) => {
  const newStartDateTime = newStartDate;
  const { workDays, calendarDays } = durationValues;

  switch (mode) {
    case MODE.KEEP_TASK_WORK_DAYS:
      return calculateEndDateFromWorkDaysDuration(newStartDateTime, workDays);
    case MODE.KEEP_TASK_LENGTH:
      return calculateEndDateFromCalendarDaysDuration(
        newStartDateTime,
        calendarDays
      );
    case MODE.CUSTOM:
      return newTaskDateRange.endDate;
    default:
      return newTaskDateRange.endDate;
  }
};

const calculateEndDateFromWorkDaysDuration = (newStartDateTime, workDays) =>
  addWorkingDays({
    date: newStartDateTime,
    workDays: isDateOnWeekEnd(newStartDateTime) ? workDays : workDays - 1
  });

const calculateEndDateFromCalendarDaysDuration = (
  newStartDateTime,
  calendarDays
) => newStartDateTime.plus({ days: calendarDays - 1 });

export const calculateMode = ({
  newEndDate,
  newTaskDateRange,
  durationValues
}) => {
  const {
    calendarDays: existingCalendarDays,
    workDays: existingWorkDays
  } = durationValues;

  const {
    calendarDays: newCalendarDays,
    workDays: newWorkDays
  } = getDurationSummaryValues(newTaskDateRange.startDate, newEndDate);

  if (newWorkDays === existingWorkDays) {
    return MODE.KEEP_TASK_WORK_DAYS;
  }

  if (newCalendarDays === existingCalendarDays) {
    return MODE.KEEP_TASK_LENGTH;
  }

  return MODE.CUSTOM;
};
