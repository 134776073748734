import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { TaskProgressStatus } from '~/types';
import {
  TaskDoneIcon,
  TaskDoneOverrideIcon,
  TaskNotDoneOverrideIcon
} from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  taskDone: {
    color: theme.palette.primary.main
  },
  taskNotDone: {
    color: theme.palette.primary.secondary
  }
}));

export const TaskDone = ({ dataQeId, status, override }) => {
  const classes = useStyles();

  if (status !== TaskProgressStatus.Completed && !override) {
    return null;
  }

  if (
    status === TaskProgressStatus.Completed &&
    (!override || override.status === TaskProgressStatus.Completed)
  ) {
    return <TaskDoneIcon data-qe-id={dataQeId} className={classes.taskDone} />;
  }

  if (
    status !== TaskProgressStatus.Completed &&
    override.status === TaskProgressStatus.Completed
  ) {
    return (
      <TaskDoneOverrideIcon
        className={classes.taskDone}
        data-qe-id={dataQeId}
      />
    );
  }

  if (
    status === TaskProgressStatus.Completed &&
    override.status !== TaskProgressStatus.Completed
  ) {
    return (
      <TaskNotDoneOverrideIcon
        className={classes.taskNotDone}
        data-qe-id={dataQeId}
      />
    );
  }

  return null;
};

TaskDone.propTypes = {
  dataQeId: PropTypes.string,
  status: PropTypes.string,
  override: PropTypes.object
};

export default TaskDone;
