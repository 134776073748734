import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Percentage } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  percentage: {
    fontSize: theme.typography.body2.fontSize
  },
  error: {
    color: theme.palette.error.dark
  }
}));

export const PercentageFormatter = ({ field, record }) => {
  const percentage = record[field];
  const { errorDetails } = record;
  const hasError = Boolean(errorDetails && errorDetails.milestoneErrorCode);
  const classes = useStyles();

  return (
    <Percentage
      value={percentage || 0}
      className={classNames(classes.percentage, { [classes.error]: hasError })}
    />
  );
};

PercentageFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default PercentageFormatter;
