import React from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash.get';

import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  useSetListSort,
  useTableSettings
} from '~/modules/common/components/ListTable';

import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import {
  TABLE_KEY,
  mobileColumnNames as mobileColumns
} from '~/modules/common/hooks/project/projectColumns';
import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { useProjectsContext } from '../../../ProjectsContext';
import ProjectListTable from './ProjectListTable';

const getSort = ({ currentSort }) => {
  if (!currentSort) {
    return null;
  }

  return {
    field: currentSort.field,
    direction: currentSort.direction
  };
};

export const ProjectList = ({ onRowSelectionChange }) => {
  const { projectType } = useProjectsContext();
  const { enabledProjectColumnNames } = useEnabledProjectColumns();

  const { data, loading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: enabledProjectColumnNames
  });
  const currentColumns = get(data, 'columns');
  const currentSort = get(data, 'sort');

  const { onSortChange, loading: sortLoading } = useSetListSort({
    tableKey: TABLE_KEY,
    currentColumns,
    currentSort
  });
  const isMobile = useIsBreakpointDown('sm');

  const sort = getSort({
    currentSort
  });

  if (!currentColumns) {
    return <></>;
  }

  const isLoading = loading || sortLoading;

  return (
    <>
      <ErrorBoundary>
        <ProjectListTable
          isMobile={isMobile}
          enabledFields={isMobile ? mobileColumns : data && data.columns}
          sort={sort}
          onSortChange={onSortChange}
          isLoading={isLoading}
          projectType={projectType}
          onRowSelectionChange={onRowSelectionChange}
        />
      </ErrorBoundary>
    </>
  );
};

ProjectList.propTypes = {
  onRowSelectionChange: PropTypes.func
};

export default ProjectList;
