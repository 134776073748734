import React, { useMemo } from 'react';
import get from 'lodash.get';
import { PropTypes } from 'prop-types';
import ImportExportGroup from '~/modules/common/components/ImportExportMenu';
import { useMeContext } from '~/modules/me';
import { useHasPermission } from '~/modules/common/permissions';
import {
  IMPORT_OPTIONS,
  EXPORT_OPTIONS
} from '~/modules/common/components/ImportExportMenu/enums/ImportExportMenuEnums';
import { useTableSettings } from '~/modules/common/components/ListTable';
import { useGetEnabledCustomFieldsQuery } from '~/modules/customFields/graphql';
import { useResourceManagementContext } from '~/modules/resource-management/common/context';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';
import { RESOURCE_MANAGEMENT_TAB_ENUM } from '../../enums/resourceManagementTabs';
import {
  TABLE_KEY,
  getDefaultColumnNames
} from '../../../ResourceUserList/resourceColumns';

const { IMPORT, CSV_IMPORT } = IMPORT_OPTIONS;
const { EXPORT, CSV_EXPORT } = EXPORT_OPTIONS;

export const ResourceManagementImportExportMenu = ({
  isMobile,
  selectedTab
}) => {
  const viewDataImportPermission = useHasPermission({
    actionUri: 'urn:replicon-webui:resource-data-import:edit-import'
  });

  const editUserPermission = useHasPermission({
    actionUri: 'urn:replicon:user-action:edit-user'
  });

  const me = useMeContext();
  const { uiRootPath } = me;

  const { searchCriteria } = useResourceManagementContext();
  const { filter: resourceDetailsFilter } = useFilterBasedOnSearchCriteria({
    searchCriteria
  });

  const { data: customFieldsData } = useGetEnabledCustomFieldsQuery({
    variables: { groupName: 'User' }
  });

  const customFieldDefinitions =
    get(customFieldsData, 'enabledCustomFields') || [];

  const defaultColumnNames = getDefaultColumnNames({
    customFieldDefinitions,
    me
  });

  const { data, loading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: defaultColumnNames
  });

  const activeColumns = get(data, 'columns');

  const actions = useMemo(
    () => [
      viewDataImportPermission && {
        label: IMPORT,
        actionItems: [
          ...(editUserPermission
            ? [
                {
                  label: 'importExportMenu.options.addUser',
                  href: `${uiRootPath}administration/users/add`
                }
              ]
            : []),
          {
            label: CSV_IMPORT,
            href: `${uiRootPath}admin/data-import/user`
          }
        ]
      },
      selectedTab === RESOURCE_MANAGEMENT_TAB_ENUM.LIST &&
        !loading && {
          label: EXPORT,
          actionItems: [
            {
              label: CSV_EXPORT,
              tableKey: TABLE_KEY,
              activeColumns,
              filter: { resourceDetailsFilter }
            }
          ]
        }
    ],
    [
      editUserPermission,
      uiRootPath,
      viewDataImportPermission,
      resourceDetailsFilter,
      loading,
      activeColumns,
      selectedTab
    ]
  );

  return (
    <ImportExportGroup
      actions={actions}
      isMobile={isMobile}
      menuId="resource-pool-import-export-menu"
    />
  );
};

ResourceManagementImportExportMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string.isRequired
};

export default ResourceManagementImportExportMenu;
