import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { tagAssignmentPropType } from '~/modules/common/components/AddTags/PropTypes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    alignItems: 'start',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    listStyle: 'none',
    gridColumnGap: theme.spacing(1),
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto',
    padding: 0,
    justifyContent: 'start'
  },
  row: { lineHeight: 1.5, display: 'contents' },
  tagKey: { color: theme.palette.text.secondary }
}));

export const TagsReadOnly = ({ tags }) => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      {tags.map(({ tag, value }) => (
        <li key={tag.id} className={classes.row}>
          <span className={classes.tagKey}>{tag.name}</span>
          <span>{value.value}</span>
        </li>
      ))}
    </ul>
  );
};

TagsReadOnly.propTypes = {
  tags: PropTypes.arrayOf(tagAssignmentPropType)
};

export default TagsReadOnly;
