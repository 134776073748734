import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { POLICYTYPE } from '~/modules/common/enums';
import { PAGE_SIZE } from '~/modules/common/const';

export const SERVICECENTER_QUERY = gql`
  query getServiceCenters(
    $searchTerm: String
    $policy: String
    $pageSize: Int
  ) {
    serviceCenters(
      searchTerm: $searchTerm
      page: 1
      pageSize: $pageSize
      policy: $policy
    ) {
      id
      displayText
      hierarchyLevel
    }
  }
`;

const mappedServiceCenters = data =>
  data?.serviceCenters?.map(serviceCenter => ({
    ...serviceCenter,
    value: serviceCenter.id,
    label: serviceCenter.displayText,
    key: serviceCenter.displayText
  }));

const useServiceCenters = ({
  searchTerm,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  includeNoneOption = false,
  noneOptionText = ''
}) => {
  const { data, loading, refetch } = useQuery(SERVICECENTER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchTerm,
      pageSize: PAGE_SIZE + 1,
      policy
    },
    context: {
      debounceKey: 'service-center-search',
      debounceTimeout: 250
    }
  });

  const serviceCenters = !loading ? mappedServiceCenters(data) : [];

  return {
    serviceCenters: (serviceCenters.length && includeNoneOption
      ? prependNoneItem(noneOptionText, serviceCenters)
      : serviceCenters
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    loading,
    refetch,
    hasMore: serviceCenters.length > PAGE_SIZE
  };
};

export const fetchServiceCenters = ({
  apolloClient,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  intl
}) => async searchTerm => {
  const { data } = await apolloClient.query({
    query: SERVICECENTER_QUERY,
    variables: { searchTerm, pageSize: PAGE_SIZE, policy }
  });

  return prependNoneItem(
    intl.formatMessage({ id: 'resourceBasicInfoCard.noServiceCenter' }),
    mappedServiceCenters(data)
  );
};

export const useSearchableServiceCenters = ({ policy } = {}) => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const fetchOptions = fetchServiceCenters({
    apolloClient,
    policy,
    intl
  });

  return { fetchOptions };
};

export default useServiceCenters;
