import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, TextField } from '@material-ui/core';
import { withMe } from '~/modules/me';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import FormattedNumberInput from './FormattedNumberInput';

const useStyles = makeStyles(theme => ({
  root: {},
  inputRoot: {},
  input: {
    textAlign: ({ align }) => align
  },
  label: ({ align }) =>
    align === 'right'
      ? {
          whiteSpace: 'nowrap',
          left: 'auto',
          right: 0,
          transformOrigin: 'top right',
          '&.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
            transform: 'translate(-12px, 7px) scale(0.75)'
          },
          '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(-12px, 10px) scale(0.75)'
          },
          '&.MuiInputLabel-filled.MuiInputLabel-marginDense': {
            transform: 'translate(-12px, 17px) scale(1)'
          },
          '&.MuiInputLabel-filled': {
            transform: 'translate(-12px, 20px) scale(1)'
          }
        }
      : {
          whiteSpace: 'nowrap'
        }
}));

export const NumberInput = ({
  reactNumberFormatProps,
  onValueChange,
  onBlur,
  onFocus,
  classes,
  dataQeId,
  width,
  precision,
  allowNegative = false,
  className,
  underline = true,
  editable = true,
  disableLabel = !editable,
  me,
  fixedDecimalScale = true,
  startAdornment,
  ariaLabel,
  defaultValue,
  hiddenLabel,
  align = 'right',
  InputLabelProps,
  endAdornment,
  ...rest
}) => {
  const numberInputClasses = useStyles({ classes, align });
  const numberSettings = getReactNumberFormatFromMe(me);

  const InputProps = {
    disableUnderline: !underline || !editable,
    className: numberInputClasses.inputRoot,
    inputProps: {
      className: numberInputClasses.input,
      'aria-label': ariaLabel,
      fixedDecimalScale,
      ...(dataQeId && {
        'data-qe-id': `${dataQeId}_NumberInput_TextFieldInput`
      }),
      allowNegative,
      defaultValue,
      onValueChange,
      ...numberSettings,
      decimalScale:
        precision !== undefined ? precision : numberSettings.decimalScale,
      decimals:
        precision !== undefined ? precision : numberSettings.decimalScale,
      onBlur,
      onFocus,
      ...reactNumberFormatProps
    },
    inputComponent: FormattedNumberInput,
    startAdornment,
    endAdornment
  };

  const inputLabelProps = useMemo(
    () => ({
      className: numberInputClasses.label,
      ...InputLabelProps
    }),
    [InputLabelProps, numberInputClasses.label]
  );

  return (
    <TextField
      data-qe-id={dataQeId}
      className={classNames(numberInputClasses.root, className)}
      fullWidth
      InputProps={InputProps}
      InputLabelProps={inputLabelProps}
      disabled={disableLabel}
      hiddenLabel={hiddenLabel}
      {...rest}
    />
  );
};

NumberInput.propTypes = {
  className: PropTypes.string,
  onValueChange: PropTypes.func, // may cause prop bounce
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimals: PropTypes.number,
  dataQeId: PropTypes.string,
  allowNegative: PropTypes.bool,
  reactNumberFormatProps: PropTypes.object,
  editable: PropTypes.bool,
  me: PropTypes.object.isRequired,
  startAdornment: PropTypes.node,
  ariaLabel: PropTypes.string,
  align: PropTypes.string,
  underline: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  classes: PropTypes.object,
  InputLabelProps: PropTypes.object,
  fixedDecimalScale: PropTypes.bool,
  disableLabel: PropTypes.bool
};

export default withMe()(NumberInput);
