import { IconButton, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import SecondaryActions from '../SecondaryActions';
import useStyles from './useStyles';

const defaultActionClickHandler = action => () => {
  if (action.onClick) action.onClick();
};

export const RequestActions = ({
  classes: classesOverrides,
  qeIdTag,
  actions = [],
  onActionClick = defaultActionClickHandler,
  primaryActionPlaceHolder = true
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const primaryActions = actions.filter(action => action.primary);
  const secondaryActions = actions.filter(action => !action.primary);
  const cssClasses = useMemo(
    () => ({ secondaryActionsButton: classes.secondaryActionsButton }),
    [classes.secondaryActionsButton]
  );

  return (
    <>
      {secondaryActions.length > 0 ? (
        <SecondaryActions
          classes={cssClasses}
          qeIdTag={qeIdTag}
          actions={secondaryActions}
        />
      ) : (
        <span className={classes.actionsPlaceholder} />
      )}
      {primaryActions.length > 0
        ? primaryActions.map(primaryAction => (
            <Tooltip title={primaryAction.label} key={primaryAction.label}>
              <span>
                <IconButton
                  data-qe-id={`${qeIdTag}_SubmitButton`}
                  color="primary"
                  className={classNames(
                    classes.primaryActionButton,
                    classes[primaryAction.className]
                  )}
                  onClick={onActionClick(primaryAction)}
                  aria-label={primaryAction.label}
                  disabled={primaryAction.disabled}
                >
                  {primaryAction.icon}
                </IconButton>
              </span>
            </Tooltip>
          ))
        : primaryActionPlaceHolder && (
            <span className={classes.actionsPlaceholder} />
          )}
    </>
  );
};

RequestActions.propTypes = {
  classes: PropTypes.object,
  onActionClick: PropTypes.func,
  qeIdTag: PropTypes.string,
  primaryActionPlaceHolder: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      className: PropTypes.string,
      primary: PropTypes.bool,
      disabled: PropTypes.bool
    })
  )
};

export default RequestActions;
