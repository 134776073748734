import { useFormik } from 'formik';
import { useMemo } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

import { ReleaseResourceAllocationType } from '~/types';

export const getInitialState = ({ resourceRequest, today = new Date() }) => {
  const {
    role,
    resourceAllocations,
    startDate,
    endDate,
    projectUri
  } = resourceRequest;

  return {
    comment: '',
    releaseConfirmed: false,
    releaseDate:
      mapIsoStringtoUtcObject(endDate) < today ? endDate : today.toISOString(),
    releaseType: ReleaseResourceAllocationType.Entirely,
    requestStartDate: startDate,
    requestEndDate: endDate,
    role,
    allocations: resourceAllocations.map(allocation => ({
      id: allocation.id,
      startDate: allocation.startDate,
      endDate: allocation.endDate,
      user: {
        ...allocation.user.user
      },
      isSelected: true
    })),
    projectId: projectUri
  };
};
export const useReleaseResourceRequestDialogState = ({
  resourceRequest,
  onResourceRequestSubmit
}) => {
  const initialValues = useMemo(() => {
    return getInitialState({ resourceRequest, today: new Date() });
  }, [resourceRequest]);

  const formik = useFormik({
    initialValues,
    onSubmit: onResourceRequestSubmit,
    enableReinitialize: true
  });

  return formik;
};
export default useReleaseResourceRequestDialogState;
