import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';

export const MY_CLIENTS_DROPDOWN_QUERY = gql`
  query MyClientDropdownOptions($pageSize: Int!) {
    myActiveClients(page: 1, pageSize: $pageSize) {
      id
      displayText
    }
  }
`;

const clientDropdownOptionMapper = client => ({
  label: client.displayText,
  value: client.id
});

const useMyClientDropDownOptionsForProject = skip => {
  const { data, loading } = useQuery(MY_CLIENTS_DROPDOWN_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { pageSize: PAGE_SIZE + 1 },
    skip
  });

  const clients = loading
    ? []
    : get(data, 'myActiveClients', []).map(clientDropdownOptionMapper);

  return {
    options: clients.slice(0, PAGE_SIZE),
    hasMore: clients.length > PAGE_SIZE,
    loading
  };
};

export default useMyClientDropDownOptionsForProject;
