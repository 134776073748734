import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TagColumnGroup from './TagColumnGroup';

import ColumnGroup from './ColumnGroup';

const ColumnSettingsDialogContent = ({
  groups,
  selectedColumns,
  onColumnClick,
  settingsColumnJustify = 'space-between',
  onRemoveTag,
  onTagKeySelection,
  tagsObjectBinding
}) => (
  <Grid container spacing={2} justifyContent={settingsColumnJustify}>
    {groups.map(group => (
      <ColumnGroup
        columns={group.columns}
        key={group.id}
        title={group.title}
        selectedColumns={selectedColumns}
        onColumnClick={onColumnClick}
        noOfGroups={groups.length}
      />
    ))}
    {tagsObjectBinding && (
      <TagColumnGroup
        onTagKeySelection={onTagKeySelection}
        selectedColumns={selectedColumns}
        onRemoveTag={onRemoveTag}
        tagsObjectBinding={tagsObjectBinding}
      />
    )}
  </Grid>
);

ColumnSettingsDialogContent.propTypes = {
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  onColumnClick: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.object
      ]),
      columns: PropTypes.arrayOf(PropTypes.object)
    })
  ),
  onRemoveTag: PropTypes.func,
  settingsColumnJustify: PropTypes.string,
  onTagKeySelection: PropTypes.func,
  tagsObjectBinding: PropTypes.string
};

export default ColumnSettingsDialogContent;
