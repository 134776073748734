import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { convertDurationToHours } from '~/modules/common/util';
import { TimelineBlock } from '~/modules/resourcing/common/chart/components';
import { useQuickAllocationContext } from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import {
  getRoundedHours,
  getDayViewRoundedPercentage
} from '~/modules/resourcing/common/util/scheduleUtil';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import ResourceUserPeriodSummaryTooltip from '~/modules/resourcing/common/components/ResourceUserPeriodSummaryTooltip/ResourceUserPeriodSummaryTooltip';
import useStyles, { getLoadingColor } from './useStyles';

const QuickAllocationUserPeriodSummaryBlock = ({
  startDate,
  endDate,
  holidays,
  holidayDuration,
  timeOffs,
  timeOffDuration,
  allocatedDuration,
  scheduledDuration,
  resourceId
}) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => setOpen(false), [setOpen]);
  const handleTooltipClick = useCallback(() => setOpen(!open), [open, setOpen]);

  const { chartStartDate, scale } = useQuickAllocationContext();

  const { scheduledHours, totalAllocatedHours, allocatedHours } = useMemo(
    () => ({
      scheduledHours: convertDurationToHours(scheduledDuration),
      allocatedHours: convertDurationToHours(allocatedDuration),
      totalAllocatedHours:
        convertDurationToHours(allocatedDuration) +
        convertDurationToHours(holidayDuration) +
        convertDurationToHours(timeOffDuration)
    }),
    [scheduledDuration, allocatedDuration, holidayDuration, timeOffDuration]
  );

  const loading = (totalAllocatedHours * 100) / scheduledHours;
  const percentage = getDayViewRoundedPercentage({
    percentage: loading,
    scale
  });

  const totalHours = getRoundedHours({
    hours: totalAllocatedHours,
    scale
  });
  const loadValue = allocatedHours > 0 ? loading : 0;
  const classes = useStyles();

  const dynamicStyle = useMemo(() => {
    const { dynamicPosition } = getDynamicPositionAttributes({
      chartStartDate,
      start: startDate,
      end: endDate,
      scale,
      isPsaRmpUserSchedulePerfFixEnabled: true
    });

    return {
      ...dynamicPosition,
      backgroundColor: getLoadingColor({ load: loadValue }),
      pointerEvents: loadValue > 0 ? 'auto' : 'none',
      borderRight:
        loadValue > 0 ? `1px solid ${theme.palette.grey[500]}` : `none`
    };
  }, [startDate, endDate, chartStartDate, scale, loadValue]);

  return (
    <ResourceUserPeriodSummaryTooltip
      startDate={startDate}
      endDate={endDate}
      totalAllocatedHours={totalAllocatedHours}
      scheduledHours={scheduledHours}
      holidays={holidays}
      timeOffs={timeOffs}
      resourceId={resourceId}
      isAllocationsLazyLoaded
      open={open}
      onClose={handleTooltipClose}
    >
      <div className={classes.root} style={dynamicStyle}>
        <TimelineBlock
          hours={totalHours}
          percentage={percentage}
          showPercentage={scheduledHours > 0}
        />
        <button
          type="button"
          onClick={handleTooltipClick}
          aria-label={formatMessage({ id: 'button.showMore' })}
          className={classes.transparentButton}
          onMouseLeave={handleTooltipClose}
        />
      </div>
    </ResourceUserPeriodSummaryTooltip>
  );
};

QuickAllocationUserPeriodSummaryBlock.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  holidays: PropTypes.array.isRequired,
  holidayDuration: PropTypes.object.isRequired,
  timeOffs: PropTypes.array.isRequired,
  timeOffDuration: PropTypes.object.isRequired,
  allocatedDuration: PropTypes.object.isRequired,
  scheduledDuration: PropTypes.object.isRequired,
  resourceId: PropTypes.string.isRequired
};

export default QuickAllocationUserPeriodSummaryBlock;
