import { useCallback } from 'react';
import {
  defaultEmptyOption,
  getParsedDialogColumns
} from '~/modules/resourcing/components/ResourceAssignmentDialog/enhancers';

const useSelectedOptions = ({
  initialState = [],
  defaultColumns = initialState,
  allDropdownOptions = defaultColumns,
  selectedOptions,
  setSelectedOptions
}) => {
  const onChange = useCallback(
    ({
      columnPlacement,
      selectedOption,
      allSelectedOptions,
      setAllSelectedOptions
    }) => {
      const updatedSelection = allSelectedOptions.map((value, index) => {
        if (columnPlacement === index) {
          if (selectedOption === null || value === null) {
            return defaultEmptyOption;
          }

          return selectedOption;
        }

        return value;
      });

      setAllSelectedOptions(updatedSelection);
    },
    []
  );

  const onOptionChange = useCallback(
    (option, columnPlacement) => {
      onChange({
        columnPlacement,
        selectedOption: option,
        allSelectedOptions: selectedOptions,
        setAllSelectedOptions: setSelectedOptions
      });
    },
    [selectedOptions, onChange]
  );

  const resetSelection = useCallback(
    () =>
      setSelectedOptions(
        getParsedDialogColumns({
          columns: initialState,
          allDropdownOptions
        })
      ),
    [initialState, allDropdownOptions]
  );

  const resetToDefault = useCallback(() => setSelectedOptions(defaultColumns), [
    defaultColumns
  ]);

  return {
    onOptionChange,
    resetSelection,
    resetToDefault
  };
};

export default useSelectedOptions;
