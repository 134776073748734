import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  timeBlocks: {
    '&:hover': {
      boxShadow: theme.shadows[4]
    },
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  }
}));

export const useHoursSummaryBlockStyles = makeStyles(theme => ({
  root: {
    borderLeft: `none`,
    borderRight: `1px solid ${theme.palette.grey[400]}`
  }
}));

export default useStyles;
