import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LicensesCertificatesIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 9.3203125 1.3203125 L 7.830078125 3.8359375 L 4.984375 4.474609375 L 5.251953125 7.390625 L 3.328125 9.59765625 L 5.251953125 11.796875 L 4.984375 14.71484375 L 7.05078125 15.18359375 L 4.16015625 20.1796875 L 7.55078125 20.4921875 L 9.5625 23.23828125 L 12.025390625 18.818359375 L 14.486328125 23.23828125 L 14.4921875 23.23828125 L 16.505859375 20.4921875 L 19.89453125 20.1796875 L 16.994140625 15.166015625 L 19.015625 14.70703125 L 18.748046875 11.796875 L 20.671875 9.59765625 L 18.748046875 7.3984375 L 19.015625 4.490234375 L 16.169921875 3.84375 L 14.6796875 1.3203125 L 12 2.470703125 L 9.3203125 1.3203125 z M 15.10546875 6.357421875 L 16.2734375 7.525390625 L 10.494140625 13.318359375 L 7.498046875 10.314453125 L 8.666015625 9.1484375 L 10.494140625 10.986328125 L 15.10546875 6.357421875 z M 15.466796875 16.541015625 L 16.61328125 18.51171875 L 15.330078125 18.65625 L 14.693359375 19.576171875 L 13.4921875 17.357421875 L 14.6796875 17.8671875 L 15.466796875 16.541015625 z M 8.556640625 16.587890625 L 9.3203125 17.875 L 10.568359375 17.3359375 L 9.35546875 19.576171875 L 8.71875 18.65625 L 7.435546875 18.51171875 L 8.556640625 16.587890625 z " />
    </>
  </SvgIcon>
));

export default LicensesCertificatesIcon;
