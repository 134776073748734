import { useEffect } from 'react';
import { usePageLayoutContext } from '~/modules/navigation/components/PageLayout/PageLayoutContext';

export const useScrollToTop = path => {
  const { mainRef } = usePageLayoutContext();

  useEffect(() => {
    if (mainRef) mainRef.current.scrollTo(0, 0);
  }, [mainRef]);
};

export default useScrollToTop;
