import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@material-ui/core';
import { CurrencyDropdown } from '~/modules/common/components';

export const FormCurrencyDropdown = ({
  variant,
  classes,
  ariaLabel,
  label,
  value,
  onCurrencyChange,
  editable,
  dataQeId
}) =>
  editable ? (
    <FormControl variant={variant} fullWidth data-qe-id={dataQeId}>
      <CurrencyDropdown
        classes={classes}
        variant={variant}
        ariaLabel={ariaLabel}
        label={label}
        value={value}
        noneOption={false}
        onChange={onCurrencyChange}
        editable={editable}
      />
    </FormControl>
  ) : (
    <TextField
      id={ariaLabel}
      label={label}
      variant="filled"
      disabled
      fullWidth
      value={value.displayText}
      data-qe-id={dataQeId}
    />
  );

FormCurrencyDropdown.propTypes = {
  classes: PropTypes.object,
  ariaLabel: PropTypes.string,
  variant: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  onCurrencyChange: PropTypes.func,
  editable: PropTypes.any,
  dataQeId: PropTypes.string
};

export default FormCurrencyDropdown;
