import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import getNumberSuffixFormat from '~/modules/common/components/SummaryFormattedNumber/getNumberSuffixFormat';
import { FormattedNumber } from '~/modules/common/components/SummaryFormattedNumber/SummaryFormattedNumber';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize
  },
  diffPositive: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark
  }
}));

export const FormattedDeltaChip = ({
  classes: classesOverride,
  value,
  prefix,
  isHour = false,
  showAddIcon,
  fixedDecimalScale = false,
  isRoundedValue = true,
  formattedMessageKey = ''
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { formattedValue, suffix } = isRoundedValue
    ? getNumberSuffixFormat(value)
    : { formattedValue: value, suffix: '' };

  return (
    <FormattedNumber
      classes={classes}
      value={Math.abs(Number(formattedValue))}
      prefix={prefix}
      suffix={suffix}
      isHour={isHour}
      isHigher={showAddIcon}
      showAddIcon={showAddIcon}
      fixedDecimalScale={fixedDecimalScale}
      formattedMessageKey={formattedMessageKey}
    />
  );
};

FormattedDeltaChip.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.number,
  prefix: PropTypes.string,
  isHour: PropTypes.bool,
  showAddIcon: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  formattedMessageKey: PropTypes.string,
  isRoundedValue: PropTypes.bool
};

export default FormattedDeltaChip;
