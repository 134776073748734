import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me/useMeContext';

export const getAvailableEarnedRevenueScriptsQuery = gql`
  query getAvailableEarnedRevenueScripts(
    $isPsaEarnedRevenueEnabled: Boolean = false
  ) {
    availableEarnedRevenueScripts @include(if: $isPsaEarnedRevenueEnabled) {
      displayText: name
      name
      id
    }
  }
`;

export const useGetAvailableEarnedRevenueScripts = () => {
  const me = useMeContext();
  const res = useQuery(getAvailableEarnedRevenueScriptsQuery, {
    variables: {
      isPsaEarnedRevenueEnabled: me.featureFlags?.isPsaEarnedRevenueEnabled
    }
  });
  const scripts = get(res?.data, 'availableEarnedRevenueScripts', []);

  const availableEarnedRevenueScripts = scripts.map(script => ({
    ...script,
    displayText: script.name
  }));

  return { loading: res?.loading, availableEarnedRevenueScripts };
};

export default useGetAvailableEarnedRevenueScripts;
