import React from 'react';
import PropTypes from 'prop-types';
import {
  ResourceOverviewToolbarContext,
  useResourceOverviewToolbarContextValue
} from '~/modules/resource-management/ResourcingOverview/hooks';

const ResourceOverviewToolbarContextProvider = ({ children }) => {
  const contextValues = useResourceOverviewToolbarContextValue();

  return (
    <ResourceOverviewToolbarContext.Provider value={contextValues}>
      {children}
    </ResourceOverviewToolbarContext.Provider>
  );
};

ResourceOverviewToolbarContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ResourceOverviewToolbarContextProvider;
