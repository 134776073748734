import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  header: {
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    color: 'rgba(0,0,0,0.66)'
  }
});

const getColumnMetadata = ({
  classes,
  displayTextResourceId,
  columnId,
  align
}) => {
  return {
    columnId,
    fixed: false,
    align,
    value: (
      <span className={classes.header}>
        <FormattedMessage id={displayTextResourceId} />
      </span>
    )
  };
};

export const buildColumnHeaders = ({ classes }) => ({
  TASK_NAME: {
    column: 'tasks',
    fixed: false,
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.tasks" />
      </span>
    ),
    align: 'left'
  },
  ACTUAL_HOURS: {
    column: 'actual-hours',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.actualHoursToDate" />
      </span>
    )
  },
  WORK_REMAINING: {
    column: 'work-remaining',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.hoursRemaining" />
      </span>
    )
  },
  ESTIMATED_AT_COMPLETION: {
    column: 'estimated-at-completion',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.estimatedHoursAtCompletion" />
      </span>
    )
  },
  HOURS_VARIATION: {
    column: 'hours-variation',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.hoursVariation" />
      </span>
    )
  },
  DATE_VARIATION: {
    column: 'date-variation',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.dateVariation" />
      </span>
    )
  },
  ACTUAL_COST: {
    column: 'actual-cost',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.actualCostToDate" />
      </span>
    )
  },
  MONTH: getColumnMetadata({
    classes,
    displayTextResourceId: 'taskDrawer.month',
    columnId: 'month',
    align: 'left'
  }),
  ESTIMATED_EXPENSES: getColumnMetadata({
    classes,
    displayTextResourceId: 'taskDrawer.estimatedExpenses',
    columnId: 'estimated-expenses',
    align: 'right'
  }),
  COST_REMAINING: {
    column: 'cost-remaining',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.costRemaining" />
      </span>
    )
  },
  COST_VARIATION: {
    column: 'cost-variation',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.costVariation" />
      </span>
    )
  },
  COST_ESTIMATED_AT_COMPLETION: {
    column: 'cost-estimated-at-completion',
    fixed: false,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.estimatedCostAtCompletion" />
      </span>
    )
  }
});

export const useListHeaders = ({ selectedColumns }) => {
  const classes = useStyles();
  const columnHeaders = buildColumnHeaders({ classes });
  const selectedColumnHeaders = selectedColumns.map(
    column => columnHeaders[column]
  );

  return [
    {
      id: 'header',
      columns: selectedColumnHeaders
    }
  ];
};
