import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  tagSkeleton: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '25%',
    minWidth: '25%',
    padding: theme.spacing(1),

    '& div': {
      margin: theme.spacing(0.5)
    }
  },
  label: {
    color: theme.palette.text.secondary
  }
}));

const TagsCardContentSkeleton = ({ classes }) => {
  const skeletonClasses = useStyles();

  return (
    <div
      className={classNames(skeletonClasses.root, classes.tagSkeletonContainer)}
    >
      <div
        className={classNames(
          skeletonClasses.tagSkeleton,
          classes.tagSkeletonItem
        )}
      >
        <Skeleton variant="rect" width="25%" height="12" />
        <Skeleton variant="rect" width="60%" height="14" />
      </div>
      <div
        className={classNames(
          skeletonClasses.tagSkeleton,
          classes.tagSkeletonItem
        )}
      >
        <Skeleton variant="rect" width="25%" height="12" />
        <Skeleton variant="rect" width="60%" height="14" />
      </div>
      <div
        className={classNames(
          skeletonClasses.tagSkeleton,
          classes.tagSkeletonItem
        )}
      >
        <Skeleton variant="rect" width="25%" height="12" />
        <Skeleton variant="rect" width="60%" height="14" />
      </div>
    </div>
  );
};

TagsCardContentSkeleton.propTypes = {
  classes: PropTypes.object
};

export default TagsCardContentSkeleton;
