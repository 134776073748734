import { makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Percentage } from '~/modules/common/components';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    alignItems: 'center',
    '& span': {
      paddingLeft: ({ isMobile }) => (isMobile ? 0 : '25%')
    },
    fontSize: theme.typography.caption.fontSize
  },
  error: {
    fontWeight: 500,
    color: theme.palette.error.dark
  },
  total: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    marginRight: theme.spacing(0.5)
  }
}));

export const MilestoneTotalPercentage = ({ field, record }) => {
  const isMobile = useIsBreakpointDown('sm');
  const intl = useIntl();
  const percentage = getRoundedValue(record[field].value, 2);

  const hasWarning = useMemo(() => percentage !== 100, [percentage]);
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.root}>
      {isMobile ? (
        <Typography variant="caption" className={classes.total}>
          {intl.formatMessage({
            id: 'billPlan.totalPercentage'
          })}
        </Typography>
      ) : null}
      <Percentage
        value={percentage || 0}
        className={classNames(classes.percentage, {
          [classes.error]: hasWarning
        })}
      />
    </div>
  );
};

MilestoneTotalPercentage.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default MilestoneTotalPercentage;
