import { useEffect } from 'react';
import { getRoundedValue } from '~/modules/billing-invoicing/common/hooks';

const descriptionFormatterUri =
  'urn:replicon:invoice-item-metadata-key:description-formatter';

const getFormattedValue = (value, intl) => {
  const { text, number } = value;

  if (number !== null && number !== undefined)
    return intl.formatNumber(number, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4
    });

  if (text !== null && text !== undefined) return text;

  return null;
};

const getFormattedTaxLineItems = (taxLineItems, intl) => {
  const formattedTaxLineItems = (taxLineItems || []).map(taxItem => {
    if (
      !(
        taxItem &&
        taxItem.description &&
        taxItem.keyValues &&
        taxItem.keyValues[descriptionFormatterUri]
      )
    )
      return taxItem;

    let { text: descriptionFormatter = '' } =
      taxItem.keyValues[descriptionFormatterUri] || {};

    Object.keys(taxItem.keyValues).forEach(p => {
      if (descriptionFormatter && descriptionFormatter.includes(p)) {
        const formattedValue = getFormattedValue(taxItem.keyValues[p], intl);

        descriptionFormatter = descriptionFormatter.replace(p, formattedValue);
      }
    });

    return { ...taxItem, description: descriptionFormatter };
  });

  return formattedTaxLineItems;
};

const sum = (currencyUri, amountFieldKey) => (retVal, item) =>
  item &&
  item[amountFieldKey] &&
  item[amountFieldKey].currency &&
  item[amountFieldKey].currency.id === currencyUri
    ? retVal + item[amountFieldKey].amount
    : retVal;

export const useTaxLineItemsHook = ({
  taxLineItems,
  setTaxTotal,
  lineItemsTotal: { amount: lineItemsAmountTotal = 0 } = {},
  setHasTaxItems,
  intl
}) => {
  useEffect(
    () => setHasTaxItems(Boolean(taxLineItems && taxLineItems.length)),
    [setHasTaxItems, taxLineItems]
  );
  const currency =
    taxLineItems[0] &&
    taxLineItems[0].amount &&
    taxLineItems[0].amount.currency;

  const { id: currencyUri } = currency || {};

  const taxLineItemsTotal = getRoundedValue(
    taxLineItems.reduce(sum(currencyUri, 'amount'), 0)
  );

  const formattedTaxLineItems = getFormattedTaxLineItems(taxLineItems, intl);

  useEffect(() => setTaxTotal(taxLineItemsTotal), [
    setTaxTotal,
    taxLineItemsTotal
  ]);

  return {
    total: {
      amount: taxLineItemsTotal + lineItemsAmountTotal,
      currency
    },
    formattedTaxLineItems
  };
};
