import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import {
  specificResourceRequestWithAllocationsFragment,
  resourceRequestTotalsFragment
} from '~/modules/resourcing/common/fragments';

export const PUT_RESOURCE_REQUEST = gql`
  mutation Eager_UpdateResourceRequest($input: UpdateResourceRequestInput!) {
    updateResourceRequest2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
        ...ResourceRequestTotalsFragment
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
  ${resourceRequestTotalsFragment}
`;

export const useUpdateResourceRequest = () => {
  const [updateResourceRequest] = useMutation(PUT_RESOURCE_REQUEST);

  return {
    updateResourceRequest
  };
};

export default useUpdateResourceRequest;
