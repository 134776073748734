/* eslint-disable react-hooks/rules-of-hooks */
import { Tooltip, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { deepPure } from '~/util';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import UserAllocationSummaryTooltipContent from '../UserAllocationSummaryTooltipContent';
import UserAllocationSummaryLabel from '../UserAllocationSummaryLabel';
import UserAllocationSummaryContent from './UserAllocationSummaryContent';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    height: theme.spacing(3),
    userSelect: 'none',
    display: 'flex',
    zIndex: 2
  },
  container: {
    width: '100%',
    ...theme.typography.caption,
    height: theme.spacing(26),
    cursor: 'move'
  },
  readOnlyContainer: {
    cursor: 'default',
    alignSelf: 'center'
  }
}));

export const UserAllocationSummaryBlockTooltip = ({
  classes,
  dynamicPosition,
  holidays,
  timeOffs,
  totalAvailableHours,
  period,
  loadRatio,
  allocationDistributionItems,
  hideTotalAllocationLabel,
  totalHours,
  handleMouseEnter,
  handleMouseLeave,
  style,
  showLabel
}) => (
  <div className={classes.root} style={dynamicPosition}>
    <Tooltip
      placement="right"
      title={useMemo(
        () =>
          (holidays || []).length > 0 || (timeOffs || []).length > 0 ? (
            <UserAllocationSummaryContent
              scheduledHours={totalAvailableHours}
              holidays={holidays}
              timeOffs={timeOffs}
              startDate={period.startDate}
              endDate={period.endDate}
            />
          ) : (
            <UserAllocationSummaryTooltipContent
              startDate={period.startDate}
              endDate={period.endDate}
              load={loadRatio * 100}
              allocationDistributionItems={allocationDistributionItems}
              hideTotalAllocationLabel={hideTotalAllocationLabel}
              totalHours={totalHours}
            />
          ),
        [
          totalAvailableHours,
          holidays,
          timeOffs,
          period.startDate,
          period.endDate,
          loadRatio,
          allocationDistributionItems,
          hideTotalAllocationLabel,
          totalHours
        ]
      )}
    >
      <div
        className={classNames(classes.container, classes.readOnlyContainer)}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showLabel && (
          <UserAllocationSummaryLabel
            loadRatio={loadRatio}
            totalHours={totalHours}
          />
        )}
      </div>
    </Tooltip>
  </div>
);
UserAllocationSummaryBlockTooltip.propTypes = {
  classes: PropTypes.object,
  dynamicPosition: PropTypes.object,
  holidays: PropTypes.array,
  timeOffs: PropTypes.array,
  totalAvailableHours: PropTypes.number,
  period: PropTypes.object.isRequired,
  loadRatio: PropTypes.number.isRequired,
  allocationDistributionItems: PropTypes.array,
  hideTotalAllocationLabel: PropTypes.bool,
  totalHours: PropTypes.number,
  showLabel: PropTypes.bool,
  style: PropTypes.object,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func
};

export const UserAllocationSummaryBlock = ({
  classes: classesOverride,
  dynamicStyle,
  loadRatio,
  period,
  chartStartDate,
  clippedChartDisplayDateRange,
  allocationDistributionItems,
  hideTotalAllocationLabel = false,
  scale,
  totalHours,
  totalAvailableHours,
  showLabel = true,
  showTooltip = true,
  clickable = false,
  timeOffs,
  holidays
}) => {
  const classes = useStyles({ classes: classesOverride });

  const { dynamicPosition } = useMemo(
    () =>
      getDynamicPositionAttributes({
        chartStartDate,
        scale,
        ...clippedChartDisplayDateRange
      }),
    [clippedChartDisplayDateRange, chartStartDate, scale]
  );

  const [style, setDynamicStyle] = useState(dynamicStyle);

  const hoverStyle = useMemo(
    () => ({
      ...dynamicStyle,
      backgroundColor: darken(dynamicStyle?.backgroundColor, 0.1)
    }),
    [dynamicStyle]
  );

  const handleMouseEnter = useCallback(() => {
    if (clickable) {
      setDynamicStyle(hoverStyle);
    }
  }, [clickable, hoverStyle]);

  const handleMouseLeave = useCallback(() => {
    if (clickable) {
      setDynamicStyle(dynamicStyle);
    }
  }, [clickable, dynamicStyle]);

  return !showTooltip ? (
    <div className={classes.root} style={dynamicPosition}>
      <div
        className={classNames(classes.container, classes.readOnlyContainer)}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showLabel && (
          <UserAllocationSummaryLabel
            loadRatio={loadRatio}
            totalHours={totalHours}
          />
        )}
      </div>
    </div>
  ) : (
    <UserAllocationSummaryBlockTooltip
      style={style}
      period={period}
      classes={classes}
      loadRatio={loadRatio}
      totalHours={totalHours}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      timeOffs={timeOffs}
      holidays={holidays}
      dynamicPosition={dynamicPosition}
      totalAvailableHours={totalAvailableHours}
      allocationDistributionItems={allocationDistributionItems}
      hideTotalAllocationLabel={hideTotalAllocationLabel}
      showLabel={showLabel}
    />
  );
};

UserAllocationSummaryBlock.propTypes = {
  classes: PropTypes.object,
  dynamicStyle: PropTypes.object.isRequired,
  loadRatio: PropTypes.number.isRequired,
  period: PropTypes.object.isRequired,
  allocationDistributionItems: PropTypes.array,
  hideTotalAllocationLabel: PropTypes.bool,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  clippedChartDisplayDateRange: PropTypes.object.isRequired,
  totalHours: PropTypes.number,
  totalAvailableHours: PropTypes.number,
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  clickable: PropTypes.bool,
  timeOffs: PropTypes.array,
  holidays: PropTypes.array
};

export default deepPure(UserAllocationSummaryBlock);
