import React from 'react';
import { PropTypes } from 'prop-types';

const Drawer = ({ children }) => <>{children}</>;

Drawer.propTypes = {
  children: PropTypes.node
};

export default Drawer;
