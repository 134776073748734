import { useCallback, useMemo } from 'react';
import { object, string, mixed, number, array } from 'yup';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { toRepliconDate } from '~/modules/common/dates/convert';
import { mapPaymentsToAssociatedBillsInput } from '~/modules/billing-invoicing/common/util';
import {
  getValidationErrorByUri,
  getValidationErrorByDisplayText
} from '~/modules/common/graphql/errors';

export const buildValidationSchema = ({ intl, isRefund }) =>
  object().shape({
    description: string().max(
      255,
      intl.formatMessage({
        id: 'billPaymentDetails.validations.descriptionExceedsMaxLength'
      })
    ),
    displayId: string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'billPaymentDetails.validations.referenceNumberIsRequired'
        })
      )
      .max(
        255,
        intl.formatMessage({
          id: 'billPaymentDetails.validations.referenceNumberExceedsMaxLength'
        })
      ),
    client: object().shape({
      id: string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'creditMemoDetails.validations.clientIsRequired'
          })
        )
    }),
    paymentDate: mixed().required(
      intl.formatMessage({
        id: 'billPaymentDetails.validations.paymentDateRequired'
      })
    ),
    paymentAmount: number().required(
      intl.formatMessage({
        id: 'billPaymentDetails.validations.paymentAmountRequired'
      })
    ),
    associatedBills: array().of(
      object().shape({
        allocatedBillAmount: object().test(
          'allocatedBillAmountTest',
          intl.formatMessage({
            id: isRefund
              ? 'billPaymentDetails.validations.refundAllocatedBillAmountError'
              : 'billPaymentDetails.validations.allocatedBillAmountError'
          }),
          function test(value) {
            const { amount } = this.parent;

            return (
              parseFloat(amount.amount.toFixed(2)) ===
                parseFloat(value.amount.toFixed(2)) ||
              (isRefund ? value.amount < 0 : value.amount > 0)
            );
          }
        ),
        amount: object().test(
          'amountTest',
          intl.formatMessage({
            id: 'billPaymentDetails.validations.allocatedAmountError'
          }),
          function test(value) {
            const { balanceAmount, allocatedBillAmount } = this.parent;

            return isRefund
              ? parseFloat(balanceAmount.amount.toFixed(2)) <=
                  parseFloat(allocatedBillAmount.amount.toFixed(2))
              : parseFloat(balanceAmount.amount.toFixed(2)) >=
                  parseFloat(allocatedBillAmount.amount.toFixed(2));
          }
        )
      })
    ),

    billPaymentBalanceTotal: number().min(
      0,
      intl.formatMessage({
        id:
          'billPaymentDetails.validations.totalAllocatedAmountExceedsBalanceError'
      })
    )
  });

export const useInitialState = ({
  me: { baseCurrency },
  billPayment = {},
  client,
  billBalanceTotal,
  associatedAndOutstandingBills = [],
  nextReferenceNumberText
}) => {
  const values = useMemo(
    () => ({
      id: billPayment.id || null,
      displayId: billPayment.displayId || nextReferenceNumberText || '',
      description: billPayment.description || '',
      paymentDate: billPayment.paymentDate || toRepliconDate(DateTime.local()),
      paymentAmount:
        billPayment.paymentAmount ||
        (billBalanceTotal && billBalanceTotal.amount) ||
        0,
      currency:
        billPayment.currency ||
        (billBalanceTotal && billBalanceTotal.currency) ||
        baseCurrency,
      paymentMethod: billPayment.paymentMethod || {},
      client: billPayment.client || client || { id: '' },
      associatedBills: associatedAndOutstandingBills,
      billPaymentBalanceTotal: 0
    }),
    [
      associatedAndOutstandingBills,
      baseCurrency,
      billBalanceTotal,
      billPayment,
      client,
      nextReferenceNumberText
    ]
  );

  return values;
};

export const useOnSubmit = ({
  billId,
  putBillPayment,
  setSaving,
  onClose,
  intl,
  isRefund
}) =>
  useCallback(
    async (values, { resetForm, setFieldError }) => {
      const {
        id,
        displayId,
        description,
        paymentDate,
        client: { id: clientUri },
        paymentAmount,
        currency: { id: currencyUri },
        paymentMethod: { id: paymentMethodUri },
        associatedBills
      } = values;

      setSaving(true);
      const associatedBillsInput = associatedBills
        .filter(x => (isRefund ? x.amount.amount < 0 : x.amount.amount > 0))
        .map(mapPaymentsToAssociatedBillsInput);

      try {
        await putBillPayment(
          {
            id,
            displayId,
            description,
            paymentAmount,
            paymentDate,
            clientUri,
            currencyUri,
            paymentMethodUri,
            associatedBills: associatedBillsInput
          },
          billId
        );
        resetForm();
        setSaving(false);
        onClose();
      } catch (exception) {
        setSaving(false);

        const displayIdError =
          getValidationErrorByUri(
            exception,
            'urn:replicon:validation-failure:client-payment-display-id-duplicated'
          ) ||
          getValidationErrorByDisplayText(
            exception,
            'The specified ClientPayment already exists.'
          );

        if (displayIdError) {
          setFieldError('displayId', 'duplicateReferenceNumber');
        }
      }
    },
    [setSaving, isRefund, putBillPayment, billId, onClose]
  );

export const usePutBillPaymentFormState = ({
  me,
  bill,
  client,
  setEditable,
  history,
  setSaving,
  onClose,
  billPayment,
  billBalanceTotal,
  associatedAndOutstandingBills,
  billId,
  putBillPayment,
  isRefund,
  nextReferenceNumberText
}) => {
  const intl = useIntl();

  const initialValues = useInitialState({
    me,
    billPayment,
    client,
    billBalanceTotal,
    associatedAndOutstandingBills,
    nextReferenceNumberText
  });

  const onSubmit = useOnSubmit({
    billId,
    putBillPayment,
    setSaving,
    onClose,
    setEditable,
    bill,
    history,
    intl,
    isRefund
  });

  const validationSchema = useMemo(
    () => buildValidationSchema({ intl, isRefund }),
    [intl, isRefund]
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  });

  return formik;
};

export default usePutBillPaymentFormState;
