import { PropTypes } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { DateRange, Decimal, Percentage } from '~/modules/common/components';
import ResourceRequestStatusChip from '../ResourceRequestStatusChip';
import useStyles, { useDateRangeStyles } from './useStyles';

const useMultipleRequestsLabelStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const ResourceRequestTitle = ({
  classes: classesOverride,
  resourceRequest,
  project
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride });

  const cssClasses = useDateRangeStyles();
  const multipleRequestsLabelClasses = useMultipleRequestsLabelStyles();

  const { role, load, isAdjustedLoading } = resourceRequest;

  return (
    <div className={classes.root}>
      <div className={classes.headline}>
        <div className={classes.titleArea}>
          <div className={classes.breadcrumb}>{project.name}</div>
          <div className={classes.title}>
            {resourceRequest?.mergedResourceRequests?.length > 1 ? (
              <span className={multipleRequestsLabelClasses.label}>
                {intl.formatMessage({
                  id: 'resourceRequestItem.multipleResourceRequests'
                })}
              </span>
            ) : (
              <>
                <span className={classes.name}>
                  {role ? (
                    role.displayText
                  ) : (
                    <span className={classes.noRole}>
                      {intl.formatMessage({
                        id: 'resourceRequestTitle.anyRole'
                      })}
                    </span>
                  )}
                </span>
                <span className={classes.quantity}>
                  {resourceRequest.quantity > 1 ? (
                    <>
                      &nbsp;×&nbsp;
                      <Decimal
                        className={classes.rate}
                        value={resourceRequest.quantity}
                        fixedDecimalScale={false}
                      />
                    </>
                  ) : null}
                </span>
              </>
            )}
          </div>
        </div>
        <ResourceRequestStatusChip status={resourceRequest.requestStatus} />
      </div>
      <div className={classes.subtext}>
        <DateRange
          classes={cssClasses}
          start={resourceRequest.startDate}
          end={resourceRequest.endDate}
        />
        {load !== 100 ? (
          <>
            &nbsp;@&nbsp;
            <Percentage
              className={classNames(classes.loading, {
                [classes.overloaded]: load > 100
              })}
              value={load}
              fixedDecimalScale={false}
            />
            {isAdjustedLoading && '*'}
          </>
        ) : null}
      </div>
    </div>
  );
};

ResourceRequestTitle.propTypes = {
  classes: PropTypes.object,
  resourceRequest: PropTypes.object.isRequired,
  project: PropTypes.object
};

export default ResourceRequestTitle;
