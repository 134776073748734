import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SearchAutocomplete from './SearchAutocomplete';
import AutocompleteInput from './AutocompleteInput';

export const CreatableAutocomplete = ({
  value,
  onChange,
  loading = false,
  noOptionsText,
  options,
  loadingText,
  multiple = false,
  getOptionSelected,
  placeholder,
  fullWidth,
  filterOptions,
  getOptionLabel,
  renderInput,
  dataQeId,
  onInputChange,
  inputLabel,
  hiddenLabel,
  variant,
  canAddNewOption = true,
  classes,
  showError = false,
  optionPropText,
  errorMessage,
  helperText,
  autoFocus,
  className,
  optionTypeText,
  hasMore = false,
  maxRows,
  multiline = false,
  ariaLabel = inputLabel,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const input =
    renderInput ||
    (params => (
      <AutocompleteInput
        showError={showError}
        errorMessage={errorMessage}
        placeholder={placeholder}
        label={inputLabel}
        hiddenLabel={hiddenLabel}
        variant={variant}
        helperText={helperText}
        autoFocus={autoFocus}
        className={className}
        multiline={multiline}
        ariaLabel={ariaLabel}
        maxRows={maxRows}
        {...params}
      />
    ));

  const optionSelected =
    !getOptionSelected && optionPropText
      ? (option, selected) =>
          option[optionPropText] === selected[optionPropText]
      : getOptionSelected;

  const optionLabel =
    !getOptionLabel && optionPropText
      ? option => (option && option[optionPropText]) || ''
      : getOptionLabel;

  const textLoading =
    loadingText || formatMessage({ id: 'taskBeatTable.loading' });

  const filteredOnChange = multiple
    ? (event, values, reason) =>
        onChange(
          values.filter(val => !val.isCreatable && !val.length),
          event,
          reason,
          values
        )
    : (event, values, reason) => onChange(values, event, reason);

  return (
    <SearchAutocomplete
      hasMore={hasMore}
      freeSolo={canAddNewOption}
      classes={classes}
      dataQeId={dataQeId || null}
      loading={loading}
      options={options}
      fullWidth={fullWidth}
      loadingText={textLoading}
      multiple={multiple}
      forcePopupIcon
      value={value}
      onChange={filteredOnChange}
      getOptionLabel={optionLabel}
      onInputChange={onInputChange}
      renderInput={input}
      noOptionsText={noOptionsText}
      getOptionSelected={optionSelected}
      filterOptions={filterOptions}
      optionTypeText={optionTypeText}
      ariaLabel={ariaLabel}
      {...rest}
    />
  );
};

CreatableAutocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  loading: PropTypes.bool,
  noOptionsText: PropTypes.string,
  options: PropTypes.array.isRequired,
  inputLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  variant: PropTypes.string,
  canAddNewOption: PropTypes.bool,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  renderInput: PropTypes.func,
  dataQeId: PropTypes.string,
  getOptionSelected: PropTypes.func,
  multiple: PropTypes.bool,
  loadingText: PropTypes.string,
  classes: PropTypes.object,
  placeholder: PropTypes.string,
  showError: PropTypes.bool,
  optionPropText: PropTypes.string,
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  optionTypeText: PropTypes.string,
  hasMore: PropTypes.bool,
  className: PropTypes.string,
  maxRows: PropTypes.number,
  multiline: PropTypes.bool,
  ariaLabel: PropTypes.string
};

export default forwardRef((props, ref) => (
  <CreatableAutocomplete forwardedRef={ref} {...props} />
));
