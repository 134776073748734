import {
  ExpenseCodeFormatter,
  StringFormatter
} from '~/modules/billing-invoicing/common/columnFormatters';
import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';
import DateRangeFormatter from '~/modules/common/components/formatters/DateRangeFormatter';
import QuantityFormatter from './QuantityFormatter';
import RowSelectorFormatter from './RowSelectorFormatter';
import TaskFormatter from './TaskFormatter';
import UserFormatter from './UserFormatter';

const getFormatters = () => ({
  rowSelector: RowSelectorFormatter,
  projectName: StringFormatter,
  itemType: StringFormatter,
  taskName: TaskFormatter,
  billingItemRate: MoneyFormatter,
  billingAmount: MoneyFormatter,
  billingAmountOriginal: MoneyFormatter,
  quantity: QuantityFormatter,
  user: UserFormatter,
  billingAmountTotal: MoneyFormatter,
  billingContractClauseName: StringFormatter,
  timesheetPeriod: DateRangeFormatter,
  payCode: StringFormatter,
  role: StringFormatter,
  expenseCode: ExpenseCodeFormatter
});

export default getFormatters;
