import { Checkbox, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { BillStatus } from '~/types';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(-1)
  }
}));

const inputProps = formatMessage => ({
  'aria-label': formatMessage({
    id: 'billingTransactionList.singleRowSelected'
  })
});

export const RowSelectorFormatter = ({
  record,
  column: { onRowSelect, selectedTransactionItems }
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const isSelected = selectedTransactionItems.includes(record.id);
  const onSelectClick = useCallback(event => onRowSelect(record, event), [
    onRowSelect,
    record
  ]);
  const { billStatus } = record;

  return (
    <Checkbox
      checked={isSelected}
      onChange={onSelectClick}
      disabled={billStatus === BillStatus.Void}
      color="primary"
      classes={classes}
      inputProps={inputProps(formatMessage)}
    />
  );
};

RowSelectorFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};

export default RowSelectorFormatter;
