import { gql } from 'graphql-tag';
import { notificationFragment } from './notificationFragment';

export const notificationCreatedSubscription = gql`
  subscription NotificationCreated {
    notificationCreated {
      ...Notification
    }
  }

  ${notificationFragment}
`;
