import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { UserRoleRowTotal } from './UserRoleRowTotal';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(23.75),
    height: '100%'
  },
  skeletonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0)
  }
}));

const getAllocationsForRole = ({
  resourceAllocationReference,
  resourceAllocationsById
}) =>
  resourceAllocationReference
    .map(({ id }) => resourceAllocationsById[id])
    .filter(x => x);

export const ResourceAllocationChartRightItem = ({
  allocatedUser,
  loadingAllocations,
  resourceAllocationsById
}) => {
  const classes = useStyles();
  const { project } = useProjectContext();

  const canViewCostData = project.permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  return (
    <div className={classes.root}>
      {(allocatedUser.roles || []).map(
        ({ role, resourceAllocationReference }) => {
          const key = role?.id || `${allocatedUser.user?.uri}-no-role`;

          return !loadingAllocations ? (
            <UserRoleRowTotal
              key={key}
              allocationsForUserRole={getAllocationsForRole({
                resourceAllocationReference,
                resourceAllocationsById
              })}
              canViewCostData={canViewCostData}
              projectBudgetCostCurrency={project.budgetedCost?.currency}
            />
          ) : (
            <div key={key} className={classes.skeletonContainer}>
              <Skeleton variant="rect" width={180} height={12} />
            </div>
          );
        }
      )}
    </div>
  );
};

ResourceAllocationChartRightItem.propTypes = {
  allocatedUser: PropTypes.object.isRequired,
  loadingAllocations: PropTypes.bool.isRequired,
  resourceAllocationsById: PropTypes.object.isRequired
};

export default ResourceAllocationChartRightItem;
