import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { mapRepliconDateToMidnightUTCString } from '~/modules/common/dates/convert';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { DIALOG_FORM_TYPE } from '~/modules/common/enums/AddProjectDialogFormTypes';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';
import {
  extractFirstErrorMessage,
  extractFirstValidationError
} from '~/modules/common/graphql/errors';
import {
  buildCustomFieldFormValuesFromDefinitions,
  customFieldValueToInput
} from '~/modules/customFields/utils';
import { useMeContext } from '~/modules/me/useMeContext';
import { getProjectTypeUri } from '~/modules/projects/projectTypes';
import usePageofProjectTemplates from '~/modules/common/components/ProjectTemplateDropdown/hooks/usePageofProjectTemplates';
import { useAddProject } from './useAddProject';
import { useDefaultBillingType } from './useDefaultBillingType';
import { mapToProjectInput } from './util';
import {
  buildValidationSchemaForNonProjectManager,
  buildValidationSchemaForProjectManager
} from './validationSchema';

export const useAddProjectForm = ({
  type,
  clientId,
  clientName,
  clientCurrency,
  programId,
  programName,
  portfolioId,
  portfolioName,
  projectType,
  earnedRevenueScript,
  customFieldDefinitions,
  onClose,
  refetchQueries,
  historyState,
  replaceHistoryOnClose,
  projectName,
  projectCode,
  startDate,
  endDate,
  estimatedCost
}) => {
  const intl = useIntl();
  const history = useHistory();
  const me = useMeContext();
  const {
    baseCurrency,
    userAccessRoles,
    featureFlags: {
      isPsaEarnedRevenueEnabled,
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrp2023q3BugFixesEnabled
    },
    isRolledUpTaskEstimateCalculationMethodEnabled
  } = me;

  const defaultCurrencyProp = {
    id: baseCurrency.id,
    uri: baseCurrency.uri,
    displayText: baseCurrency.displayText,
    name: baseCurrency.name,
    symbol: baseCurrency.symbol
  };

  const currencyData =
    clientCurrency && clientCurrency.invoiceCurrency
      ? {
          id: clientCurrency.invoiceCurrency.id,
          uri: clientCurrency.invoiceCurrency.uri,
          displayText: clientCurrency.invoiceCurrency.displayText,
          name: clientCurrency.invoiceCurrency.name,
          symbol: clientCurrency.invoiceCurrency.symbol
        }
      : defaultCurrencyProp;

  const timeAndExpenseEntryType = {
    id: TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE_AND_NON_BILLABLE
  };
  const { loading, projectTemplates } = usePageofProjectTemplates({
    skip: !isPsaPrpPsaPpmMergerEnabled
  });

  const initialValues = useMemo(
    () => ({
      name: projectName || '',
      code: projectCode || '',
      startDate: startDate || null,
      endDate: endDate || null,
      client:
        type === DIALOG_FORM_TYPE.CLIENT
          ? { id: clientId, displayText: clientName }
          : null,
      projectManager:
        type === DIALOG_FORM_TYPE.PROJECT &&
        userAccessRoles.includes(USER_ACCESS_ROLE.PROJECT_MANAGER)
          ? {
              id: me.uri,
              displayText: me.displayText
            }
          : null,
      program:
        type === DIALOG_FORM_TYPE.PROGRAM
          ? { id: programId, displayText: programName }
          : null,
      projectManagementType: getProjectTypeUri(projectType),
      portfolio: portfolioId
        ? {
            id: portfolioId,
            displayText: portfolioName
          }
        : null,
      customFields:
        customFieldDefinitions &&
        buildCustomFieldFormValuesFromDefinitions(customFieldDefinitions),
      budgetHours:
        isPsaPpmCostEacEnhancementsEnabled ||
        isRolledUpTaskEstimateCalculationMethodEnabled
          ? null
          : 0,
      earnedRevenueScript: isPsaEarnedRevenueEnabled
        ? earnedRevenueScript
        : null,
      totalEstimatedContract: {
        amount: 0,
        currency: currencyData
      },
      budgetedCost: {
        amount:
          isPsaPpmCostEacEnhancementsEnabled ||
          isRolledUpTaskEstimateCalculationMethodEnabled
            ? null
            : 0,
        currency: currencyData
      },
      estimatedCost: estimatedCost || {
        amount:
          isPsaPpmCostEacEnhancementsEnabled ||
          isRolledUpTaskEstimateCalculationMethodEnabled
            ? null
            : 0,
        currency: currencyData
      },
      defaultBillingCurrency: currencyData,
      isProjectLeaderApprovalRequired: true,
      isTimeEntryAllowed: true,
      timeAndExpenseEntryType,
      projectTemplate: !loading ? projectTemplates[1] : null
    }),
    [
      projectName,
      projectCode,
      startDate,
      endDate,
      type,
      clientId,
      clientName,
      userAccessRoles,
      me.uri,
      me.displayText,
      programId,
      programName,
      projectType,
      portfolioId,
      portfolioName,
      customFieldDefinitions,
      isPsaPpmCostEacEnhancementsEnabled,
      isRolledUpTaskEstimateCalculationMethodEnabled,
      isPsaEarnedRevenueEnabled,
      earnedRevenueScript,
      currencyData,
      estimatedCost,
      timeAndExpenseEntryType,
      loading,
      projectTemplates
    ]
  );

  const validationSchemaFunc =
    type === DIALOG_FORM_TYPE.PROJECT
      ? buildValidationSchemaForProjectManager
      : buildValidationSchemaForNonProjectManager;
  const validationSchema = useMemo(
    () =>
      validationSchemaFunc({
        customFieldDefinitions,
        intl,
        me
      }),
    [customFieldDefinitions, intl, me, validationSchemaFunc]
  );

  const onSubmit = useOnSubmit({
    history,
    historyState,
    onClose,
    refetchQueries,
    replaceHistoryOnClose,
    type,
    isPsaPrp2023q3BugFixesEnabled
  });

  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  });
};

const handleError = ({ err, setFieldError, isPsaPrp2023q3BugFixesEnabled }) => {
  const validationErrors = extractFirstValidationError(err);

  if (validationErrors) {
    const firstRenderableError = validationErrors.find(e => e.displayText);

    if (isPsaPrp2023q3BugFixesEnabled) {
      firstRenderableError.failureUri ===
      'urn:replicon:validation-failure:project-code-duplicated'
        ? setFieldError('code', firstRenderableError.displayText)
        : setFieldError('name', firstRenderableError.displayText);
    } else {
      setFieldError('name', firstRenderableError.displayText);
    }
  } else {
    setFieldError('unhandled', extractFirstErrorMessage(err));
  }
};

export const useOnSubmit = ({
  history,
  historyState,
  onClose,
  refetchQueries,
  replaceHistoryOnClose,
  isPsaPrp2023q3BugFixesEnabled
}) => {
  const addProjectMutation = useAddProject();
  const defaultBillingType = useDefaultBillingType();

  return useCallback(
    (values, bag) => {
      const addProject = async () => {
        bag.setSubmitting(true);
        try {
          const {
            name,
            code,
            startDate,
            endDate,
            earnedRevenueScript,
            client,
            program,
            portfolio,
            projectManager,
            budgetHours,
            totalEstimatedContract,
            budgetedCost,
            estimatedCost,
            defaultBillingCurrency,
            projectManagementType,
            customFields,
            isProjectLeaderApprovalRequired,
            isTimeEntryAllowed,
            timeAndExpenseEntryType,
            projectTemplate
          } = values;
          const projectInput = mapToProjectInput({
            projectTemplate,
            client,
            program,
            portfolio,
            projectManagerReference: projectManager,
            projectManagementType,
            isTimeEntryAllowed,
            timeAndExpenseEntryType,
            name,
            code,
            startDate: startDate
              ? mapRepliconDateToMidnightUTCString(startDate)
              : null,
            endDate: endDate
              ? mapRepliconDateToMidnightUTCString(endDate)
              : null,
            customFieldValues: Object.values(customFields).map(
              customFieldValueToInput
            ),
            budgetHours,
            totalEstimatedContract,
            budgetedCost,
            estimatedCost,
            earnedRevenueScriptId: earnedRevenueScript?.id,
            defaultBillingCurrency,
            isProjectLeaderApprovalRequired,
            ...defaultBillingType
          });

          const {
            data: {
              addProject2: { project }
            }
          } = await addProjectMutation({
            variables: {
              projectInput
            },
            refetchQueries
          });
          const { slug } = project;

          bag.resetForm();
          bag.setSubmitting(false);
          onClose(project);

          if (replaceHistoryOnClose)
            history.push({
              pathname: `/projects/${slug}`,
              search: '?edit=true',
              state: historyState || undefined
            });
        } catch (err) {
          handleError({
            err,
            setFieldError: bag.setFieldError,
            isPsaPrp2023q3BugFixesEnabled
          });
          bag.setSubmitting(false);
        }
      };

      addProject();
    },
    [
      addProjectMutation,
      defaultBillingType,
      history,
      historyState,
      onClose,
      refetchQueries,
      replaceHistoryOnClose
    ]
  );
};
