import { useFormik } from 'formik';
import { orderBy, pickBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { useMeContext } from '~/modules/me';
import { validationSchema } from '~/modules/projects/project/ScriptParamsEditableCard/enhancers/withFormState';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { compareISOStrings } from '~/modules/common/dates/compare';

export const expenseTypeUri = 'urn:replicon:script-key:parameter:expense-type';

const getKey = (scale, date) =>
  `${scale}-${mapIsoStringtoUtcObject(date).toISODate()}`;

const getDateFromKey = (scale, key) => {
  const date = key.replace(`${scale}-`, '');

  if (isNaN(Date.parse(date))) {
    return null;
  }

  return date;
};

const mergeEstimatesAndActualsByPeriod = (estimates, actuals) => {
  const estimatesKeys = Object.keys(estimates || {});
  const actualsKeys = Object.keys(actuals || {});

  return [...estimatesKeys, ...actualsKeys].reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr]: {
        ...(estimates[curr] || {}),
        ...(actuals[curr] || {})
      }
    }),
    {}
  );
};

const mapMonthlyExpenses = (
  expenseCodeIds,
  expenseActualsSeries,
  estimatedExpensesSeries,
  expenseActualsSummary,
  estimatedExpensesSummary,
  scale,
  expenseCodeById
) => {
  return expenseCodeIds.map(expenseCodeId => {
    const seriesRowForEstimates = (estimatedExpensesSeries || []).find(
      s => s.expenseCode.id === expenseCodeId
    );

    const seriesRowEstimatesByPeriod = (
      seriesRowForEstimates?.dataPoints || []
    ).reduce((retVal, curr) => {
      const key = getKey(scale, curr.period.start);

      return {
        ...retVal,
        [key]: {
          estimated: {
            billableAmount: curr.billableAmount
              ? {
                  amount: curr.billableAmount,
                  currency: seriesRowForEstimates?.currency
                }
              : null,
            nonBillableAmount: curr.nonBillableAmount
              ? {
                  amount: curr.nonBillableAmount,
                  currency: seriesRowForEstimates?.currency
                }
              : null
          }
        }
      };
    }, {});

    const seriesRowForActuals = (expenseActualsSeries || []).find(
      s => s.expenseCode.id === expenseCodeId
    );

    const seriesRowActualsByPeriod = (
      seriesRowForActuals?.dataPoints || []
    ).reduce((retVal, curr) => {
      const key = getKey(scale, curr.period.start);

      return {
        ...retVal,
        [key]: {
          actuals: {
            billableAmount: {
              amount: curr.billableAmount,
              currency: seriesRowForActuals?.currency
            },
            nonBillableAmount: curr.nonBillableAmount
              ? {
                  amount: curr.nonBillableAmount,
                  currency: seriesRowForActuals?.currency
                }
              : null
          }
        }
      };
    }, {});

    const final = mergeEstimatesAndActualsByPeriod(
      seriesRowEstimatesByPeriod,
      seriesRowActualsByPeriod
    );

    const summaryForEstimates = (estimatedExpensesSummary || []).find(
      s => s.expenseCode.id === expenseCodeId
    );

    const summaryForActuals = (expenseActualsSummary || []).find(
      s => s.expenseCode.id === expenseCodeId
    );

    return {
      expenseCode: {
        ...expenseCodeById[expenseCodeId],
        id: expenseCodeId
      },
      estimate: summaryForEstimates
        ? {
            amount:
              (summaryForEstimates?.billableAmount || 0) +
              (summaryForEstimates?.nonBillableAmount || 0),
            currency: summaryForEstimates.currency
          }
        : null,
      totalEstimates: {
        estimated: {
          billableAmount: summaryForEstimates
            ? {
                amount: summaryForEstimates.billableAmount || 0,
                currency: summaryForEstimates.currency
              }
            : null,
          nonBillableAmount: summaryForEstimates
            ? {
                amount: summaryForEstimates.nonBillableAmount || 0,
                currency: summaryForEstimates.currency
              }
            : null
        },
        actuals: {
          billableAmount: summaryForActuals
            ? {
                amount: summaryForActuals.billableAmount || 0,
                currency: summaryForActuals.currency
              }
            : null,
          nonBillableAmount: summaryForActuals
            ? {
                amount: summaryForActuals.nonBillableAmount || 0,
                currency: summaryForActuals.currency
              }
            : null
        }
      },
      ...final
    };
  });
};

export const useInitialState = ({
  me,
  scriptDetails: {
    id,
    parameters,
    displayText,
    description,
    estimatedAmount,
    contractAmount,
    scripts,
    isAddMode = false,
    footerProps
  },
  projectExpenseCodes,
  scriptType,
  scale,
  expenseActualsSeries,
  estimatedExpensesSeries,
  estimatedExpensesSummary,
  expenseActualsSummary,
  expenseCardSettings,
  context,
  isBillingContractType
}) => {
  const { showActuals, allowBillable } = expenseCardSettings || {};
  const sortedProjectExpenseCodes = orderBy(
    projectExpenseCodes,
    'displayText',
    ['asc']
  );

  const expenseCodeIds = sortedProjectExpenseCodes.map(x => x.id);

  const expenseCodeById = sortedProjectExpenseCodes.reduce(
    (retVal, curr) => ({ ...retVal, [curr.id]: curr }),
    {}
  );

  const monthlyExpenses = mapMonthlyExpenses(
    expenseCodeIds,
    expenseActualsSeries,
    estimatedExpensesSeries,
    expenseActualsSummary,
    estimatedExpensesSummary,
    scale,
    expenseCodeById
  );

  const values = pickBy(
    {
      id: id || undefined,
      description: description || undefined,
      scriptType: scriptType || undefined,
      parameters: orderBy(parameters || [], 'sortIndex', ['asc']),
      scripts: orderBy(
        (scripts || []).filter(script => script.id || script.scriptId),
        item => item[expenseTypeUri].displayText,
        ['asc']
      ),
      monthlyExpenses,
      projectExpenseCodes: sortedProjectExpenseCodes,
      displayText: displayText || undefined,
      estimatedAmount: estimatedAmount || undefined,
      hasHeaderComponent: contractAmount ? true : undefined,
      headerComponentProps: contractAmount ? { contractAmount } : undefined,
      footerProps: footerProps || undefined,
      isAddMode: isAddMode || undefined,
      featureFlags: me?.featureFlags,
      showActuals,
      allowBillable: !isBillingContractType
        ? false
        : context === 'expenseBillPlan'
        ? true
        : allowBillable
    },
    x => x !== undefined
  );

  return values;
};

const isValidPeriod = (date, dateRange) =>
  Boolean(date && compareISOStrings(date, dateRange.endDate.toISODate()) <= 0);

const getEndDate = (date, endDate) => {
  const d = DateTime.fromISO(date)
    .plus({ month: 1 })
    .minus({ day: 1 });

  if (d <= endDate) return d.toISODate();

  return endDate.toISODate();
};

const hasBillableAmountOrNonBillableAmount = (estimated = {}) => {
  const { billableAmount, nonBillableAmount } = estimated;

  return !(isNaN(billableAmount?.amount) && isNaN(nonBillableAmount?.amount));
};

const mapToEstimatedExpensesEntries = (
  dateRange,
  monthlyExpenses,
  scale,
  allowBillable
) =>
  monthlyExpenses.reduce((retVal, curr) => {
    const { expenseCode } = curr;

    const keys = Object.keys(curr);

    const res = [];

    for (const key of keys) {
      const date = getDateFromKey(scale, key);

      if (!date) continue;

      if (
        isValidPeriod(date, dateRange) &&
        curr[key].estimated &&
        hasBillableAmountOrNonBillableAmount(curr[key].estimated)
      ) {
        const { billableAmount, nonBillableAmount } = curr[key].estimated;

        res.push({
          expenseCodeId: expenseCode.id,
          billableAmount: billableAmount?.amount,
          nonBillableAmount: nonBillableAmount?.amount,
          currencyId:
            billableAmount?.currency?.id || nonBillableAmount?.currency?.id,
          incurredDate: getEndDate(date, dateRange.endDate)
        });
      }
    }

    return [...retVal, ...res];
  }, []);

export const useOnSubmit = ({
  projectId,
  scale,
  onSave,
  bulkPutEstimatedExpenses,
  putExpenseCardSettings,
  updateProjectExpenses,
  recalculate,
  projectDateRange,
  canEditBillingContracts,
  canRecalculateBillingData,
  isBillingContractType,
  setSaving
}) =>
  useCallback(
    async values => {
      const {
        monthlyExpenses,
        allowBillable,
        showActuals,
        projectExpenseCodes
      } = values;

      if (!isBillingContractType) setSaving(true);

      await putExpenseCardSettings({ allowBillable, showActuals });

      const updateProjectExpensesResult = await updateProjectExpenses({
        expenseCodes: projectExpenseCodes.map(code => ({
          ...code,
          isExpenseEntryToThisCodeAllowed: true
        }))
      });

      if (projectDateRange && updateProjectExpensesResult?.id) {
        const data = mapToEstimatedExpensesEntries(
          projectDateRange,
          monthlyExpenses,
          scale,
          allowBillable
        );

        await bulkPutEstimatedExpenses(projectId, data);
      }

      if (isBillingContractType && canEditBillingContracts) {
        const { scripts } = values;

        await onSave({ id: values.id, scripts });
      }

      if (isBillingContractType && canRecalculateBillingData) {
        await recalculate();
      }
      setSaving(false);
    },
    [
      isBillingContractType,
      setSaving,
      putExpenseCardSettings,
      updateProjectExpenses,
      projectDateRange,
      canEditBillingContracts,
      canRecalculateBillingData,
      scale,
      bulkPutEstimatedExpenses,
      projectId,
      onSave,
      recalculate
    ]
  );

export const useFormState = ({
  scriptDetails,
  scriptType,
  onSave,
  scale,
  projectExpenseCodes,
  expenseActualsSeries,
  estimatedExpensesSeries,
  bulkPutEstimatedExpenses,
  estimatedExpensesSummary,
  expenseActualsSummary,
  projectId,
  viewSummary,
  expenseCardSettings,
  putExpenseCardSettings,
  updateProjectExpenses,
  recalculate,
  projectDateRange,
  overrideBillable,
  canEditBillingContracts,
  canRecalculateBillingData,
  context,
  isBillingContractType,
  setSaving
}) => {
  const intl = useIntl();
  const me = useMeContext();
  const initialValues = useInitialState({
    scriptDetails,
    scriptType,
    me,
    projectExpenseCodes,
    scale,
    expenseActualsSeries,
    estimatedExpensesSeries,
    estimatedExpensesSummary,
    expenseActualsSummary,
    viewSummary,
    expenseCardSettings,
    overrideBillable,
    context,
    isBillingContractType
  });

  const onSubmit = useOnSubmit({
    projectId,
    scale,
    onSave,
    bulkPutEstimatedExpenses,
    putExpenseCardSettings,
    updateProjectExpenses,
    recalculate,
    projectDateRange,
    canEditBillingContracts,
    canRecalculateBillingData,
    isBillingContractType,
    setSaving
  });

  return useFormik({
    initialValues,
    validationSchema: validationSchema({ intl }),
    onSubmit,
    enableReinitialize: true
  });
};
