import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import get from 'lodash.get';
import { Money2 } from '~/modules/common/components';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  currencyRoot: {
    fontSize: theme.typography.body2.fontSize
  },
  adornmentRoot: {
    marginTop: '0px'
  },
  amountField: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto'
  }
}));

const amountUri = 'urn:replicon:script-key:parameter:milestone-amount';
const percentageUri = 'urn:replicon:script-key:parameter:percentage';
const totalAmountKeyUri = 'milestone-total-amount';

export const ContractAmount = ({
  setFieldValue,
  headerComponentProps,
  scripts,
  errors,
  isMobile
}) => {
  const classes = useStyles();
  const amountStyles = useMemo(
    () => ({
      adornmentRoot: classes.adornmentRoot,
      currencyRoot: classes.currencyRoot
    }),
    [classes.adornmentRoot, classes.currencyRoot]
  );
  const contractAmount = useMemo(
    () => headerComponentProps && headerComponentProps.contractAmount,
    [headerComponentProps]
  );
  const handleAmountChange = useCallback(
    async event => {
      const newAmount = event.target.value || 0;

      setFieldValue('headerComponentProps.contractAmount.amount', newAmount);
      setFieldValue(
        `footerProps.${totalAmountKeyUri}.contractAmount`,
        newAmount
      );
      const total = { amount: 0 };

      await Promise.all(
        scripts.map((value, index) => {
          const percentage = value[percentageUri];
          const updatedAmount = getRoundedValue(
            (newAmount * percentage) / 100,
            2
          );

          total.amount += updatedAmount;

          return setFieldValue(
            `scripts[${index}].${amountUri}.amount`,
            updatedAmount
          );
        })
      );
      setFieldValue(
        `footerProps.${totalAmountKeyUri}.amount`,
        getRoundedValue(total.amount, 2)
      );
    },
    [scripts, setFieldValue]
  );

  const handleCurrencyChange = useCallback(
    async newCurrency => {
      setFieldValue(
        'headerComponentProps.contractAmount.currency',
        newCurrency
      );
      setFieldValue('parameters[2].defaultValue.currency', newCurrency);
      await Promise.all(
        scripts.map((value, index) =>
          setFieldValue(`scripts[${index}].${amountUri}.currency`, newCurrency)
        )
      );
      setFieldValue(`footerProps.${totalAmountKeyUri}.currency`, newCurrency);
    },
    [scripts, setFieldValue]
  );

  const errorMessage = useMemo(
    () => get(errors, `headerComponentProps.contractAmount.amount`),
    [errors]
  );

  return (
    <div className={classes.root}>
      <Money2
        label="Contract Amount"
        fullWidth={isMobile}
        classes={amountStyles}
        className={classes.amountField}
        amount={contractAmount.amount}
        currency={contractAmount.currency}
        onAmountChange={handleAmountChange}
        onCurrencyChange={handleCurrencyChange}
        currencyDataQeId="milestoneContractCurrency"
        error={Boolean(errorMessage)}
        helperText={errorMessage}
      />
    </div>
  );
};

ContractAmount.propTypes = {
  setFieldValue: PropTypes.func,
  headerComponentProps: PropTypes.object,
  scripts: PropTypes.array,
  errors: PropTypes.object,
  isMobile: PropTypes.bool
};

export default ContractAmount;
