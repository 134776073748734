import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Chip, makeStyles } from '@material-ui/core';

export const labels = {
  active: <FormattedMessage id="statusChip.active" />,
  inActive: <FormattedMessage id="statusChip.inactive" />
};

export const useStyles = makeStyles(theme => ({
  colorSecondary: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.client.inActive.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.client.inActive.main,
      color: theme.palette.text.primary
    }
  },
  root: ({ size }) => ({
    ...(size === 'medium' ? theme.typography.body2 : theme.typography.caption),
    color: theme.palette.text.primary,
    '&:hover, &:focus': {
      color: theme.palette.text.primary
    }
  }),
  colorPrimary: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.client.active.main
  }
}));

export const StatusChip = ({ classes, isActive, size = 'small' }) => {
  const cssClasses = useStyles({ classes, isActive, size });

  const StatusLabel = isActive ? labels.active : labels.inActive;

  const chipClasses = useMemo(
    () => ({
      ...cssClasses,
      clickableColorPrimary: cssClasses.colorPrimary,
      clickableColorSecondary: cssClasses.colorPrimary
    }),
    [cssClasses]
  );

  return (
    <Chip
      classes={chipClasses}
      color={isActive ? 'primary' : 'secondary'}
      size={size}
      label={StatusLabel}
    />
  );
};

StatusChip.propTypes = {
  classes: PropTypes.object,
  isActive: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium'])
};

export default StatusChip;
