import { DateTime } from 'luxon';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import {
  getTotalHoursForPeriodFromScheduleRules,
  getTotalHoursFromScheduleRuleEntry2
} from '~/modules/resourcing/common/util/scheduleUtil';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { REQUEST_PERIOD_OVERLAP_TYPE } from '../../enums/requestPeriodOverlapType';
import { getScheduleRulesInChartDisplayRange } from './useAllocationTimelineBlocks2';

export const getRequestPeriodOverlapType = ({
  periodStart,
  periodEnd,
  requestStartDate,
  requestEndDate,
  hasRequestStart,
  hasRequestEnd
}) =>
  (hasRequestStart && !periodStart.equals(requestStartDate)) ||
  (hasRequestEnd && !periodEnd.equals(requestEndDate))
    ? REQUEST_PERIOD_OVERLAP_TYPE.PARTIAL
    : REQUEST_PERIOD_OVERLAP_TYPE.FULL;

const useRequestPeriodTimelineBlocks = ({
  chartDisplayPeriods,
  chartDisplayDateRange,
  resourceRequest,
  scale,
  project
}) => {
  const {
    startDate: chartStartDate,
    endDate: chartEndDate
  } = chartDisplayDateRange;

  const { scheduleRules, startDate, endDate } = resourceRequest;

  const requestStartDate = mapIsoStringtoUtcObject(startDate);
  const requestEndDate = mapIsoStringtoUtcObject(endDate);

  const noChartOverlap =
    requestEndDate < chartStartDate || requestStartDate > chartEndDate;

  const scheduleRulesInRange = noChartOverlap
    ? []
    : getScheduleRulesInChartDisplayRange({
        rangeStart: chartStartDate,
        rangeEnd: chartEndDate,
        scheduleRules
      });

  return chartDisplayPeriods.reduce(
    (acc, period) => {
      const { startDate: periodStart, endDate: periodEnd } = period;

      const requestHoursForRange = getTotalHoursForPeriodFromScheduleRules({
        start: periodStart,
        end: periodEnd,
        scheduleRules: scheduleRulesInRange,
        useVersion2: true
      });

      const projectDefaultScheduledHoursForRange = getTotalHoursFromScheduleRuleEntry2(
        {
          start: periodStart,
          end: periodEnd,
          constraints: project.defaultScheduleRule.do
        }
      );

      const overlayPeriod = {
        ...period,
        totalHours: requestHoursForRange,
        percentage: projectDefaultScheduledHoursForRange
          ? (requestHoursForRange / projectDefaultScheduledHoursForRange) * 100
          : undefined,
        totalProjectDefaultScheduledHours: projectDefaultScheduledHoursForRange,
        requestPeriodOverlapType: REQUEST_PERIOD_OVERLAP_TYPE.NONE
      };

      const hasChartOverlap = !noChartOverlap;

      if (hasChartOverlap) {
        const noPeriodOverlap =
          requestEndDate < periodStart || requestStartDate > periodEnd;

        const hasPeriodOverlap = !noPeriodOverlap;

        if (hasPeriodOverlap) {
          const overlapStart = DateTime.max(requestStartDate, periodStart);
          const overlapEnd = DateTime.min(requestEndDate, periodEnd);

          const hasRequestStart = overlapStart.equals(requestStartDate);
          const hasRequestEnd = overlapEnd.equals(requestEndDate);

          const periodOverlapPosition = getDynamicPositionAttributes({
            chartStartDate,
            start: overlapStart,
            end: overlapEnd,
            scale,
            isPsaRmpUserSchedulePerfFixEnabled: true
          }).dynamicPosition;

          acc.requestPeriods.push({
            ...overlayPeriod,
            dynamicPosition: periodOverlapPosition,
            hasRequestStart: overlapStart.equals(requestStartDate),
            hasRequestEnd: overlapEnd.equals(requestEndDate)
          });

          overlayPeriod.requestPeriodOverlapType = getRequestPeriodOverlapType({
            periodStart,
            periodEnd,
            requestStartDate,
            requestEndDate,
            hasRequestEnd,
            hasRequestStart
          });
          overlayPeriod.hasRequestStart = hasRequestStart;
          overlayPeriod.hasRequestEnd = hasRequestEnd;
        }
      }

      acc.overlayPeriods.push(overlayPeriod);

      return acc;
    },
    {
      overlayPeriods: [],
      requestPeriods: []
    }
  );
};

export default useRequestPeriodTimelineBlocks;
