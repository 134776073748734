import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import OverviewItemExpansionPanel from './OverviewItemExpansionPanel';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.background.default
    },
    borderTop: `1px solid ${theme.palette.divider}`,
    '&::before': {
      top: 0
    },
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`,
    boxShadow: 'none'
  },
  expansionPanelSummaryContent: {
    padding: theme.spacing(1, 0, 1, 1)
  },
  expansionPanel: {
    '&:before': {
      top: 0
    },
    '&.Mui-expanded': {
      margin: 0
    }
  }
}));

const FlatOverviewItemExpansionPanel = ({
  children,
  classes: classesOverride,
  leftBorderColorClass,
  dataQeId,
  ...props
}) => {
  const flatClasses = useStyles({
    classes: classesOverride
  });

  const classes = useMemo(
    () => ({
      ...flatClasses,
      root: classNames(flatClasses.root, leftBorderColorClass)
    }),
    [flatClasses, leftBorderColorClass]
  );

  return (
    <OverviewItemExpansionPanel
      dataQeId={dataQeId}
      classes={classes}
      {...props}
    >
      {children}
    </OverviewItemExpansionPanel>
  );
};

FlatOverviewItemExpansionPanel.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  leftBorderColorClass: PropTypes.string
};

export default FlatOverviewItemExpansionPanel;
