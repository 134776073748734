import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import MilestoneTaskDropdown from './MilestoneTaskDropdown';

export const MilestoneTaskEditor = ({
  field,
  record,
  index,
  column: { id: columnId, setFieldValue, projectUri, errors, isMobile, label }
}) => {
  const onChangeHandler = useCallback(
    task => setFieldValue(`scripts[${index}].${columnId}`, task),
    [columnId, index, setFieldValue]
  );

  const { errorDetails } = record[field] || {};

  const { hasError, helperText } = useMemo(() => {
    if (errorDetails)
      return {
        hasError: true,
        helperText: (
          <FormattedMessage
            id={`billPlan.${errorDetails.milestoneErrorCode}`}
          />
        )
      };
    const error = get(errors, `parameters['${columnId}'][${index}]`);

    return {
      hasError: Boolean(error),
      helperText:
        typeof error === 'object' ? (
          <FormattedMessage id={`billPlan.${error.milestoneErrorCode}`} />
        ) : (
          error
        )
    };
  }, [columnId, errorDetails, errors, index]);

  return (
    <MilestoneTaskDropdown
      selectedTask={record[field]}
      onChange={onChangeHandler}
      isMilestone
      projectId={projectUri}
      errorDetails={errorDetails}
      hasError={hasError}
      helperText={helperText}
      label={label}
      hiddenLabel={!isMobile}
    />
  );
};

MilestoneTaskEditor.propTypes = {
  record: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default MilestoneTaskEditor;
