import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { recursivelyStripTypeName } from '~/util';
import { useMeContext } from '~/modules/me/useMeContext';

const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $projectInput: ProjectInput!
    $includeExpenseBudgetedCost: Boolean!
  ) {
    updateProject2(projectInput: $projectInput) {
      id
      budgetHours
      totalEstimatedContract {
        amount
        currency {
          id
          displayText
        }
      }
      budgetedCost {
        amount
        currency {
          id
          displayText
        }
      }
      resourceBudgetedCost @include(if: $includeExpenseBudgetedCost) {
        amount
        currency {
          id
          displayText
        }
      }
      expenseBudgetedCost @include(if: $includeExpenseBudgetedCost) {
        amount
        currency {
          id
          displayText
        }
      }
      estimatedHours
      estimatedCost {
        amount
        currency {
          id
          displayText
        }
      }
      defaultBillingCurrency {
        id
        displayText
      }
      costType {
        id
        displayText
      }
    }
  }
`;

const response = amount =>
  amount
    ? {
        __typename: 'Money',
        ...amount,
        currency: {
          __typename: 'Currency',
          id: amount.currency.uri,
          ...amount.currency
        }
      }
    : null;
const optimisticResponse = (
  projectUri,
  {
    budgetHours,
    totalEstimatedContract,
    budgetedCost,
    resourceBudgetedCost,
    expenseBudgetedCost,
    estimatedHours,
    estimatedCost,
    projectCurrency,
    costType
  }
) => ({
  __typename: 'Mutation',
  updateProject2: {
    __typename: 'Project',
    id: projectUri,
    budgetHours,
    totalEstimatedContract: response(totalEstimatedContract),
    budgetedCost: response(budgetedCost),
    resourceBudgetedCost: response(resourceBudgetedCost),
    expenseBudgetedCost: response(expenseBudgetedCost),
    estimatedHours,
    estimatedCost: response(estimatedCost),
    defaultBillingCurrency: {
      __typename: 'Currency',
      ...projectCurrency
    },
    costType: costType
      ? {
          __typename: 'CostType',
          ...costType
        }
      : null
  }
});

const shouldMakeRefetchQueries = ({
  onSetCurrencyChanged,
  isProjectCurrencyChanged,
  isPsaPpmEstimatedCostAtCompletionEnabled
}) => {
  const refetchQueries = ['GetBudgetCostSummary'];

  if (isProjectCurrencyChanged) {
    onSetCurrencyChanged();
    refetchQueries.push('getProjectBillPlans', 'GetProjectCostSeries');
  }

  if (isPsaPpmEstimatedCostAtCompletionEnabled) {
    refetchQueries.push('GetProjectProgressSummaryDetails');
  }

  return { refetchQueries };
};

export const useUpdateFinancials = ({ onSetCurrencyChanged, projectUri }) => {
  const {
    featureFlags: {
      isPsaPpmEstimatedCostAtCompletionEnabled,
      isPsaPrpProductPackagingEnabled
    },
    isExpenseProductEnabled
  } = useMeContext();
  const [updateFinancialsMutation] = useMutation(UPDATE_PROJECT);

  const checkForExpenseProduct = isPsaPrpProductPackagingEnabled
    ? isExpenseProductEnabled
    : true;

  return useCallback(
    values => {
      const {
        budgetHours,
        totalEstimatedContract,
        budgetedCost,
        resourceBudgetedCost,
        expenseBudgetedCost,
        estimatedHours,
        estimatedCost,
        projectCurrency,
        costType,
        isProjectCurrencyChanged
      } = values;

      const budgetData = {
        projectUri,
        budgetHours,
        resourceBudgetedCost,
        expenseBudgetedCost,
        estimatedHours,
        estimatedCost,
        defaultBillingCurrency: projectCurrency,
        totalEstimatedContract,
        costType
      };

      updateFinancialsMutation({
        variables: {
          projectInput: checkForExpenseProduct
            ? recursivelyStripTypeName(budgetData)
            : recursivelyStripTypeName({
                projectUri,
                budgetHours,
                budgetedCost,
                estimatedHours,
                estimatedCost,
                defaultBillingCurrency: projectCurrency,
                totalEstimatedContract,
                costType
              }),
          includeExpenseBudgetedCost: checkForExpenseProduct
        },
        ...shouldMakeRefetchQueries({
          onSetCurrencyChanged,
          isProjectCurrencyChanged,
          isPsaPpmEstimatedCostAtCompletionEnabled
        }),
        optimisticResponse: optimisticResponse(
          projectUri,
          checkForExpenseProduct
            ? budgetData
            : {
                budgetHours,
                totalEstimatedContract,
                budgetedCost,
                estimatedHours,
                estimatedCost,
                projectCurrency,
                costType
              }
        )
      });
    },
    [
      checkForExpenseProduct,
      isPsaPpmEstimatedCostAtCompletionEnabled,
      onSetCurrencyChanged,
      projectUri,
      updateFinancialsMutation
    ]
  );
};
