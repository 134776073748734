import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { deepPure } from '~/util';
import { useRequestTimelineBlocks } from '../../hooks';
import useStyles from '../PeriodTimelineBlocks/useStyles';

export const PeriodTimelineBlocksLoading = ({
  classes: classesOverrides,
  chartStartDate,
  scheduleRules,
  scale,
  projectDefaultScheduleRule,
  relativeDynamicPositionLeft = 0
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const { requestPeriods } = useRequestTimelineBlocks({
    scale,
    chartStartDate,
    scheduleRules,
    projectDefaultScheduleRule,
    relativeDynamicPositionLeft
  });

  return (
    <div className={classes.root}>
      {requestPeriods.map(({ dynamicPosition, key }) => (
        <div key={key} className={classes.period} style={dynamicPosition}>
          <Skeleton variant="text" width={32} height={32} />
        </div>
      ))}
    </div>
  );
};

PeriodTimelineBlocksLoading.propTypes = {
  classes: PropTypes.object,
  chartStartDate: PropTypes.object.isRequired,
  scheduleRules: PropTypes.array,
  scale: PropTypes.string.isRequired,
  relativeDynamicPositionLeft: PropTypes.number,
  projectDefaultScheduleRule: PropTypes.object
};

export default deepPure(PeriodTimelineBlocksLoading);
