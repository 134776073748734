import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'contents'
  },
  header: {
    color: theme.palette.text.secondary
  },
  cell: {
    padding: theme.spacing(0, 1, 1, 2),
    whiteSpace: 'nowrap'
  },
  taskSummaryColumn: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(17.5),
    paddingRight: 0,
    paddingBottom: theme.spacing(1)
  },
  hoursColumn: ({ isHourTooltipVisible }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    textAlign: 'right',
    justifySelf: 'end',
    paddingRight: isHourTooltipVisible ? theme.spacing(3.75) : 0
  }),
  costColumn: ({ isCostTooltipVisible }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    justifySelf: 'end',
    paddingRight: isCostTooltipVisible ? theme.spacing(3.75) : 0
  }),
  datesColumn: ({ showCost }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    paddingLeft: showCost ? theme.spacing(5) : theme.spacing(12.5)
  })
}));

const TaskSummaryHeader = ({
  classes: classesOverride,
  variant = 'task',
  showCost,
  isHourTooltipVisible,
  isCostTooltipVisible
}) => {
  const classes = useStyles({
    classes: classesOverride,
    showCost,
    isCostTooltipVisible,
    isHourTooltipVisible
  });

  return (
    <div className={classNames(classes.row, classes.header)} role="row">
      <div
        className={classNames(classes.cell, classes.taskSummaryColumn)}
        role="cell"
      >
        <FormattedMessage
          id={`${
            variant === 'project'
              ? 'projectDrawer.projectSummary'
              : 'taskDrawer.taskSummary'
          }`}
        />
      </div>
      <div
        data-qe-id="Status_TotalPlannedHours"
        className={classNames(classes.cell, classes.hoursColumn)}
        role="columnheader"
      >
        <FormattedMessage id="taskStatus.hours" />
      </div>
      {showCost && (
        <div
          className={classNames(classes.cell, classes.costColumn)}
          role="columnheader"
        >
          <FormattedMessage id="taskStatus.cost" />
        </div>
      )}
      <div
        data-qe-id="Status_PlannedDate"
        className={classNames(classes.cell, classes.datesColumn)}
        role="columnheader"
      >
        <FormattedMessage id="taskStatus.dates" />
      </div>
    </div>
  );
};

TaskSummaryHeader.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf(['task', 'project']),
  isHourTooltipVisible: PropTypes.bool,
  isCostTooltipVisible: PropTypes.bool,
  showCost: PropTypes.bool
};

export default TaskSummaryHeader;
