import { withCustomFetchCriteriaProvider } from '../enhancers';
import { revenueRecognitionPolicyTag } from './tag';
import RevenueRecognitionPolicyFacetDetails from './RevenueRecognitionPolicyFacetDetails';
import { useSearchableProjectOptions } from './hooks/useRevenueRecognitionPolicy';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableProjectOptions);

export default {
  tag: revenueRecognitionPolicyTag,
  makeCriteriaProvider,
  DetailsControl: RevenueRecognitionPolicyFacetDetails
};
