import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import React, { useMemo, useCallback } from 'react';
import get from 'lodash.get';
import { Money2 } from '~/modules/common/components';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const useStyles = makeStyles(theme => ({
  currencyRoot: {
    fontSize: theme.typography.body2.fontSize
  },
  adornmentRoot: {
    marginTop: '0px !important'
  },
  amountField: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto'
  }
}));

const percentageKeyUri = 'urn:replicon:script-key:parameter:percentage';
const totalAmountKeyUri = 'milestone-total-amount';
const totalPercentageKeyUri = 'milestone-total-percentage';

export const MilestoneAmountEditor = ({
  field,
  record,
  index,
  column: {
    id: columnId,
    setFieldValue,
    hasHeaderComponent,
    headerComponentProps: { contractAmount },
    footerProps,
    errors,
    label,
    isMobile
  }
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const amountStyles = useMemo(
    () => ({
      adornmentRoot: classes.adornmentRoot,
      currencyRoot: classes.currencyRoot
    }),
    [classes.adornmentRoot, classes.currencyRoot]
  );
  const { amount, currency } = useMemo(
    () => ({
      amount: record[field].amount || 0,
      currency: hasHeaderComponent
        ? contractAmount.currency
        : record[field].currency
    }),
    [hasHeaderComponent, contractAmount.currency, record, field]
  );

  const onAmountChangeHandler = useCallback(
    e => {
      const newAmount = e.target.value || 0;

      if (amount === newAmount) return;

      setFieldValue(`scripts[${index}].${columnId}.amount`, newAmount);
      if (hasHeaderComponent) {
        const updatedPercentage = contractAmount.amount
          ? getRoundedValue((newAmount * 100) / contractAmount.amount, 2)
          : 0;

        setFieldValue(
          `scripts[${index}].${percentageKeyUri}`,
          updatedPercentage
        );
        if (footerProps) {
          setFieldValue(
            `footerProps.${totalPercentageKeyUri}.value`,
            getRoundedValue(
              footerProps[totalPercentageKeyUri].value -
                record[percentageKeyUri] +
                updatedPercentage,
              2
            ) || 0
          );
        }
      }
      if (footerProps) {
        setFieldValue(
          `footerProps.${totalAmountKeyUri}.amount`,
          getRoundedValue(
            footerProps[totalAmountKeyUri].amount - amount + newAmount,
            2
          ) || 0
        );
      }
    },
    [
      amount,
      columnId,
      contractAmount.amount,
      footerProps,
      hasHeaderComponent,
      index,
      record,
      setFieldValue
    ]
  );

  const requiredError = useMemo(
    () => get(errors, `parameters['${columnId}'][${index}]`),
    [columnId, errors, index]
  );

  return (
    <Money2
      classes={amountStyles}
      className={classes.amountField}
      ariaLabel={formatMessage({ id: 'billDetails.amount' })}
      amount={amount}
      currency={currency}
      currencyEditable={false}
      error={Boolean(requiredError)}
      helperText={requiredError}
      onAmountChange={onAmountChangeHandler}
      label={label}
      hiddenLabel={!isMobile}
    />
  );
};

MilestoneAmountEditor.propTypes = {
  field: PropTypes.string.isRequired,
  column: PropTypes.object,
  record: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default MilestoneAmountEditor;
