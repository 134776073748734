import { DateTime } from 'luxon';
import {
  getUserPreferenceWeekStart,
  getUserPreferenceWeekEnd
} from '~/modules/common/dates/dayOfWeek';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import {
  DateRange,
  GetDateRangeForScaleInput,
  HandleScaleChangeInput,
  HandleScaleChange,
  HandlePeriodChangeInput,
  HandlePeriodChange,
  UseChartChangeHandlersInput,
  UseChartChangeHandlersOutput
} from '../types';

export const getDateRangeForScale = ({
  me,
  date,
  scale
}: GetDateRangeForScaleInput): DateRange | null => {
  switch (scale) {
    case PERIOD_SCALE_ENUM.DAYS:
      return {
        startDate: date,
        endDate: date
      };
    case PERIOD_SCALE_ENUM.WEEKS:
      return {
        startDate: getUserPreferenceWeekStart(me, date),
        endDate: getUserPreferenceWeekEnd(me, date)
      };
    case PERIOD_SCALE_ENUM.MONTHS:
      return {
        startDate: date.startOf('month'),
        endDate: date.endOf('month')
      };
    case PERIOD_SCALE_ENUM.QUARTERS:
      return {
        startDate: date.startOf('quarter'),
        endDate: date.endOf('quarter')
      };
    case PERIOD_SCALE_ENUM.YEARS:
      return {
        startDate: date.startOf('year'),
        endDate: date.endOf('year')
      };
    default:
      return null;
  }
};

export const handlePrevPeriodChange = ({
  me,
  scale,
  dateRange,
  setDateRange
}: HandlePeriodChangeInput): HandlePeriodChange => (): void => {
  const { startDate } = dateRange;
  const previousDate = startDate.minus({
    days: 1
  });
  const newDateRange = getDateRangeForScale({
    me,
    date: previousDate,
    scale
  });

  setDateRange(newDateRange);
};

export const handleNextPeriodChange = ({
  me,
  scale,
  dateRange,
  setDateRange
}: HandlePeriodChangeInput): HandlePeriodChange => (): void => {
  const { endDate } = dateRange;
  const nextDate = endDate.plus({
    days: 1
  });
  const newDateRange = getDateRangeForScale({
    me,
    date: nextDate,
    scale
  });

  setDateRange(newDateRange);
};

export const handleScaleChange = ({
  me,
  setScale,
  setDateRange
}: HandleScaleChangeInput): HandleScaleChange => (newValue): void => {
  setScale(newValue);
  const date = DateTime.local();
  const range = getDateRangeForScale({ me, date, scale: newValue });

  setDateRange(range);
};

const useChartChangeHandlers = ({
  me,
  scale,
  dateRange,
  setScale,
  setDateRange
}: UseChartChangeHandlersInput): UseChartChangeHandlersOutput => ({
  handlePrevPeriodChange: handlePrevPeriodChange({
    me,
    scale,
    dateRange,
    setDateRange
  }),
  handleNextPeriodChange: handleNextPeriodChange({
    me,
    scale,
    dateRange,
    setDateRange
  }),
  handleScaleChange: handleScaleChange({
    me,
    setScale,
    setDateRange
  })
});

export default useChartChangeHandlers;
