import { compareDateObjects } from '~/modules/common/dates/compare';

export const useFormOnChangeHandlers = ({ values, setFieldValue }) => ({
  onNameChange: event => setFieldValue('name', event.target.value),
  onIssuerChange: event => setFieldValue('issuer', event.target.value),
  onCredentialIdChange: event =>
    setFieldValue('credentialId', event.target.value),
  onCredentialURLChange: event =>
    setFieldValue('credentialUrl', event.target.value),
  onExpiresChange: (_, checked) => {
    setFieldValue('doesNotExpire', checked);
    setFieldValue('expiryDate', null);
  },
  onIssueDateChange: issueDate => {
    setFieldValue('issueDate', issueDate);

    const { expiryDate } = values;
    const isStartDateAfterEndDate =
      compareDateObjects(issueDate, expiryDate) === 1;

    if (isStartDateAfterEndDate) {
      setFieldValue('expiryDate', issueDate);
    }
  },
  onExpiryDateChange: expiryDate => setFieldValue('expiryDate', expiryDate)
});

export default useFormOnChangeHandlers;
