import { set } from 'lodash';
import { SCALE_URI_ENUM } from '~/modules/common/charts/timeline';

const hoursDetails = `
series {
  period {
    periodStart: periodStart2
    periodEnd: periodEnd2
  }
  hours
}
summary {
  hours
}`;

const amountDetails = `
  series {
    currency {
      id
      displayText
    }
    dataPoints {
      period {
        periodStart: periodStart2
        periodEnd: periodEnd2
      }
      amount
    }
  }
  summary {
    amount {
      amount
      currency {
        id
        displayText
      }
    }
  }`;

export const billingOverviewDetails = `
billingOverview: billingOverview2(periodResolutionUri: $periodResolutionUri, , dateRange: $dateRange) {
    availableToBill {
      nonBillableHours {            
        ${hoursDetails}
      }
      billableHours {
        ${hoursDetails}
      }
      amountTotal {
        ${amountDetails}            
      }
    }
    estimatedBilling {
      totalHours {            
        ${hoursDetails}
      }
      amountTotal {
        ${amountDetails}            
      }
    }
    billingTransactionActuals {
      billingItemBillings {
        ${amountDetails}            
      }
      adhocInvoiceItemBillings {
        ${amountDetails}            
      }
      invoiceItemOverrides {
        ${amountDetails}            
      }
      creditMemo {
        ${amountDetails}            
      }
      payment {
        ${amountDetails}            
      }
      outstanding {
        ${amountDetails}            
      }          
    }
  }
`;

export const billPlanOverviewDetails = `billPlanOverview: billPlanOverview3(
  periodResolutionUri: $periodResolutionUri
  dateRange: $dateRange
  overviewMode: $overviewMode
) {
  displayText {
    outputPath
    result
  }
  series {
    outputPath
    result {
      currency {
        id
        uri
        displayText
        name
        symbol
      }
      dataPoints {
        period {
          periodStart
          periodEnd
        }
        amount
      }
      amount {
        amount
        currency {
          id
          uri
          displayText
          name
          symbol
        }
      }
    }
  }
}
`;

export const mapScaleToPeriodResolution = scale => SCALE_URI_ENUM[scale];

export const getChartPeriodCount = breakPoint => {
  switch (breakPoint) {
    case 'md':
      return 6;
    default:
      return 8;
  }
};

export const makeMapperFunc = operationName => ({
  data: {
    loading,
    error,
    [operationName]: {
      billingOverview: {
        availableToBill,
        estimatedBilling,
        billingTransactionActuals
      } = {}
    } = {}
  },
  ...rest
}) => ({
  ...rest,
  billingOverview: {
    availableToBill: availableToBill || {},
    estimatedBilling: estimatedBilling || {},
    billingTransactionActuals: billingTransactionActuals || {}
  },
  hasServiceError: Boolean(error),
  isLoading: loading
});

export const billPlanOverviewObject = result => {
  const data = {};

  if (result && result.displayText && result.series) {
    result.displayText.map(current => {
      set(data, current.outputPath, current.result);
    });

    result.series.map(current => {
      set(data, current.outputPath, current.result);
    });
  }

  return data;
};
