import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import SkillField from '../SkillField';

export const TABLE_KEY = 'psa-skills-distribution';

export const useColumns = ({ classes }) => {
  return useMemo(() => {
    const columns = [
      {
        field: 'userCount',
        value: '#',
        required: true,
        fixed: true,
        visible: true,
        sortable: false,
        className: classes.userCount,
        render: ({ record }) => record.userCount
      },
      {
        field: 'skill',
        value: <FormattedMessage id="resourcingOverview.skill" />,
        className: classes.skill,
        required: true,
        fixed: true,
        visible: true,
        sortable: false,
        render: ({ record }) => <SkillField record={record} />
      }
    ];
    const listColumns = columns.reduce(
      (obj, item) => ({
        ...obj,
        [item.field]: item
      }),
      {}
    );

    const headers = [
      {
        id: 'top',
        columns: columns.map(({ field, fixed, sortable, className }) => ({
          column: field,
          fixed: fixed || false,
          sortable: sortable || false,
          className
        }))
      },
      { id: 'bottom', columns: [] }
    ];
    const columnNames = columns.map(c => c.field);

    return {
      listColumns,
      headers,
      columnNames
    };
  }, [classes.skill, classes.userCount]);
};

export default useColumns;
