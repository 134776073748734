import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, DialogContent, DialogActions } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { LoadingButton } from '~/modules/common/components';
import SendBillForm from './SendBillForm';
import { useFormChange, useSending, useSendBill, useOnSend } from './hooks';

export const SendBillDialogContent = ({ onClose, billId, loading }) => {
  const { sendBill } = useSendBill(billId);
  const { sending, sendingError, setSendingError, setSending } = useSending();
  const { values, errors, setFieldValue } = useFormikContext();
  const onSend = useOnSend({
    values,
    sendBill,
    setSending,
    setSendingError,
    onClose
  });
  const handlers = useFormChange({ setFieldValue });
  const handleClose = useCallback(() => {
    onClose();
    setSendingError(null);
  }, [onClose, setSendingError]);

  const saveable = useMemo(
    () =>
      values.emailTo && values.emailFrom && values.subject && values.message,
    [values.emailFrom, values.emailTo, values.message, values.subject]
  );

  return (
    <>
      <DialogContent>
        <SendBillForm
          values={values}
          errors={errors}
          onClose={onClose}
          sendingError={sendingError}
          loading={loading}
          {...handlers}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={sending}>
          <FormattedMessage id="sendBillDialog.cancel" />
        </Button>
        <LoadingButton
          onClick={onSend}
          color="primary"
          disabled={sending || !saveable}
          isLoading={sending}
        >
          <FormattedMessage id="sendBillDialog.send" />
        </LoadingButton>
      </DialogActions>
    </>
  );
};

SendBillDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  billId: PropTypes.string,
  loading: PropTypes.bool
};

export default SendBillDialogContent;
