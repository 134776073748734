export const dayOfWeekParameterValueMap = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};

export const propertiesToRelativeDateMap = {
  Weekly: (dayOfWeek = 'MONDAY') => ({
    year: 2019,
    month: 1,
    day: 6 + dayOfWeekParameterValueMap[dayOfWeek]
  }),
  'Semi-Monthly': (dayOfMonth = 1) => ({
    year: 2019,
    month: 1,
    day: dayOfMonth
  }),
  Monthly: (dayOfMonth = 1) => ({ year: 2019, month: 1, day: dayOfMonth })
};
