import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import { NumberTextField } from '~/modules/common/components';
import { InputFieldAdornment } from './ResourceDrawerAdornment';

const endAdornment = <InputFieldAdornment adornmentSymbol="%" />;

export const ResourceRequestDrawerDetailsLoadingField = ({
  classes,
  load,
  onLoadingChange,
  decimalScale,
  endDate,
  onExtendLoad,
  isAdjustedLoading
}) => {
  const { formatMessage } = useIntl();

  return (
    <NumberTextField
      fullWidth
      align="left"
      disabled={endDate.hasError}
      label={formatMessage({
        id: 'resourceRequestDrawerDetails.loading'
      })}
      additionalInputProps={{
        classes: {
          input: classNames(classes.loading, {
            [classes.loadOver]: load.value > 100
          })
        }
      }}
      onChange={onLoadingChange}
      value={parseFloat(load.value.toFixed(decimalScale))}
      error={load.hasError}
      ariaLabel={formatMessage({
        id: 'resourceRequestDrawerDetails.loading'
      })}
      helperText={
        <>
          {load.error
            ? load.error
            : load.value > 100 && (
                <>
                  {formatMessage(
                    {
                      id: 'resourceRequestDrawerDetails.overLoadParam'
                    },
                    {
                      load: 100
                    }
                  )}
                  <Link onClick={onExtendLoad} className={classes.extendLink}>
                    {formatMessage({
                      id: 'resourceRequestDrawerDetails.extendEndDate'
                    })}
                  </Link>
                </>
              )}
          {load.error && isAdjustedLoading.value && <br />}
          {isAdjustedLoading.value && (
            <div>
              {formatMessage({
                id: 'resourceLoadingField.adjustedLoading'
              })}
            </div>
          )}
        </>
      }
      min={0}
      max={999}
      precision={decimalScale}
      variant="filled"
      endAdornment={endAdornment}
      step="any"
    />
  );
};

ResourceRequestDrawerDetailsLoadingField.propTypes = {
  classes: PropTypes.object,
  load: PropTypes.object,
  onLoadingChange: PropTypes.func,
  decimalScale: PropTypes.number,
  endDate: PropTypes.object,
  onExtendLoad: PropTypes.func,
  isAdjustedLoading: PropTypes.object
};

export default ResourceRequestDrawerDetailsLoadingField;
