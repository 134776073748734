import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PreferredResourceIcon } from '~/modules/common/components/Icons';

const useTooltipStyles = makeStyles(
  theme =>
    theme.direction === 'rtl' && {
      popper: {
        right: 'unset !important'
      }
    }
);

const useStyles = makeStyles(theme => ({
  preferredResourceIcon: {
    fontSize: 18,
    color: theme.palette.success.dark
  },
  noRoleIcon: {}
}));

export const PreferredResourceIconTooltip = ({
  classes: classesOverride,
  noRole
}) => {
  const classes = useStyles({
    classes: classesOverride
  });
  const tooltipClasses = useTooltipStyles();
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      title={formatMessage({
        id: 'resourceRequestPreferredResources.preferredResourcesMatched'
      })}
      classes={tooltipClasses}
    >
      <PreferredResourceIcon
        className={classNames(classes.preferredResourceIcon, {
          [classes.noRoleIcon]: noRole
        })}
      />
    </Tooltip>
  );
};

PreferredResourceIconTooltip.propTypes = {
  classes: PropTypes.object,
  noRole: PropTypes.bool
};

export default PreferredResourceIconTooltip;
