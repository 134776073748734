import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BULK_PUT_ESTIMATED_EXPENSES_FOR_PROJECT = gql`
  mutation bulkPutEstimatedExpensesForProject(
    $projectId: String!
    $estimatedExpensesEntries: [EstimatedExpensesEntryInput]
  ) {
    bulkPutEstimatedExpensesForProject(
      projectId: $projectId
      estimatedExpensesEntries: $estimatedExpensesEntries
    )
  }
`;

const isNullOrUndefined = amount => amount === null || amount === undefined;

const getValidateInput = estimatedExpensesEntries =>
  estimatedExpensesEntries.filter(
    entry =>
      !(
        isNullOrUndefined(entry.billableAmount) &&
        isNullOrUndefined(entry.nonBillableAmount)
      )
  );

export const useBulkPutEstimatedExpenses = (
  refetchExpenseEstimates = false
) => {
  const [bulkPutEstimatedExpenses] = useMutation(
    BULK_PUT_ESTIMATED_EXPENSES_FOR_PROJECT
  );

  return {
    bulkPutEstimatedExpenses: async (projectId, estimatedExpensesEntries) => {
      const { data } =
        (await bulkPutEstimatedExpenses({
          variables: {
            projectId,
            estimatedExpensesEntries: getValidateInput(estimatedExpensesEntries)
          },
          refetchQueries: refetchExpenseEstimates
            ? ['estimatedExpensesSummary', 'estimatedExpensesSeriess']
            : [],
          awaitRefetchQueries: true
        })) || {};

      return data?.bulkPutEstimatedExpensesForProject;
    }
  };
};
export default useBulkPutEstimatedExpenses;
