import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { isoToReplicon } from '~/util/date';

export const AVAILABLE_CREDIT_MEMOS = gql`
  query availableCreditMemosForBill(
    $page: Int!
    $pageSize: Int!
    $billId: String!
  ) {
    availableCreditMemosForBill(
      page: $page
      pageSize: $pageSize
      billId: $billId
    ) {
      id
      displayText
      transactionDate: creditDate2
      balanceAmount: balanceAmountInInvoiceCurrency {
        amount
        currency {
          id: uri
          symbol
        }
      }
      balanceAmountInOriginalCurrency: balanceAmountInCreditMemoCurrency {
        amount
        currency {
          id: uri
          symbol
        }
      }
    }
  }
`;

export const useAvailableCreditMemos = ({
  page = 1,
  pageSize = 100,
  billId,
  canEditBill
}) => {
  const { data, loading } = useQuery(AVAILABLE_CREDIT_MEMOS, {
    variables: {
      page,
      pageSize,
      billId
    },
    skip: !canEditBill || !billId,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const availableCreditMemosForBill = get(
    data,
    'availableCreditMemosForBill',
    null
  );

  const formattedAvailableCreditMemos = availableCreditMemosForBill
    ? availableCreditMemosForBill.map(creditMemo => ({
        ...creditMemo,
        transactionDate: isoToReplicon(creditMemo.transactionDate)
      }))
    : availableCreditMemosForBill;

  return {
    loading,
    availableCreditMemos: formattedAvailableCreditMemos
  };
};
