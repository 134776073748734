import { useCallback, useState } from 'react';
import { useDialogState } from '~/modules/common/hooks';
import { usePutProjectRatesForDynamicRateTable } from './usePutProjectRatesForDynamicRateTable';
import {
  areInitialAndCurrentProjectDimensionsEqual,
  mapProjectRatesInputForRemovedRates,
  mapProjectRatesInput
} from './utils';

export const useAdvancedRateCardEditableHandlers = ({
  projectId,
  projectCurrency,
  formik,
  formOnChange,
  availableRateTableDimensions,
  projectRateDimensions,
  projectRateRequiredDimensions,
  updateProjectRatesForDynamicRateTable,
  setRatesModifier,
  setEdit,
  loadMore,
  hasMore,
  setIsSubmitting,
  onCancelCallback
}) => {
  const [addNewEntry, setAddNewEntry] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const { open: dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const { values, resetForm } = formik;

  const {
    putProjectRatesForDynamicRateTable
  } = usePutProjectRatesForDynamicRateTable({
    availableRateTableDimensions,
    projectRateRequiredDimensions
  });

  const onRemoveAllEntries = useCallback(async () => {
    setIsSubmitting && setIsSubmitting(true);
    await putProjectRatesForDynamicRateTable({
      projectId,
      projectRateDimensionUris: [],
      projectRateRequiredDimensionUris: [],
      rates: []
    });
    setIsSubmitting && setIsSubmitting(false);
  }, [setIsSubmitting, putProjectRatesForDynamicRateTable, projectId]);

  const onCloseDialog = useCallback(() => {
    setGroupId(null);
    closeDialog();
  }, [closeDialog, setGroupId]);

  const onSave = useCallback(async () => {
    const {
      projectRates,
      projectDimensions,
      removedProjectRateEntryGroup
    } = values;

    const formattedProjectRates = mapProjectRatesInput(
      projectRates,
      projectCurrency,
      projectDimensions
    );

    if (
      areInitialAndCurrentProjectDimensionsEqual(
        projectRateDimensions,
        projectDimensions
      )
    ) {
      await updateProjectRatesForDynamicRateTable({
        projectId,
        projectRateDimensionUris: projectDimensions.map(d => d.id),
        projectRateRequiredDimensionUris: projectRateRequiredDimensions.map(
          d => d.id
        ),
        rates: formattedProjectRates.concat(
          mapProjectRatesInputForRemovedRates(
            removedProjectRateEntryGroup,
            projectDimensions
          )
        )
      });
    } else {
      await putProjectRatesForDynamicRateTable({
        projectId,
        projectRateDimensionUris: projectDimensions.map(d => d.id),
        projectRateRequiredDimensionUris: projectRateRequiredDimensions.map(
          d => d.id
        ),
        rates: formattedProjectRates
      });
    }

    if (setRatesModifier) {
      const projectRateCount =
        formattedProjectRates && formattedProjectRates.length;
      const rateCreated = Boolean(formattedProjectRates.length);

      setRatesModifier(prevState => {
        return {
          clientRates: prevState.clientRates,
          rateCreated,
          ratesAddedCount: prevState.ratesAddedCount,
          projectRateCount,
          specificRate: prevState.specificRate
        };
      });
    }
  }, [
    values,
    projectCurrency,
    projectRateDimensions,
    setRatesModifier,
    updateProjectRatesForDynamicRateTable,
    projectId,
    projectRateRequiredDimensions,
    putProjectRatesForDynamicRateTable
  ]);

  const onCancel = useCallback(() => {
    setEdit && setEdit(false);
    resetForm();
    onCancelCallback && onCancelCallback();
  }, [onCancelCallback, resetForm, setEdit]);

  const openDeleteDialog = useCallback(
    grpId => {
      setGroupId(grpId);
      openDialog();
    },
    [openDialog, setGroupId]
  );

  const onDeleteGroup = useCallback(() => {
    if (groupId) {
      formOnChange.onDeleteGroup(groupId);
      onCloseDialog();
    }
  }, [formOnChange, groupId, onCloseDialog]);

  const onAddEntryClick = useCallback(async () => {
    if (hasMore) {
      await loadMore(true);
    }
    setAddNewEntry(true);
  }, [hasMore, loadMore]);

  return {
    dialogOpen,
    groupId,
    onRemoveAllEntries,
    onCloseDialog,
    onSave,
    onCancel,
    openDeleteDialog,
    onDeleteGroup,
    addNewEntry,
    setAddNewEntry,
    onAddEntryClick
  };
};
