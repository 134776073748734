import {
  BillingProjectFacet,
  UserFacet,
  DateRangeFacet,
  BillingItemTypeFacet
} from '~/modules/common/components/SearchBox/Facets';

export const getFacets = ({ columns, client }) => {
  const selectedFacets = [DateRangeFacet, BillingItemTypeFacet];

  if (columns.includes('USER')) selectedFacets.push(UserFacet);
  if (columns.includes('PROJECT')) {
    selectedFacets.push(
      BillingProjectFacet({ clientUri: client && client.id })
    );
  }

  return selectedFacets;
};

export default getFacets;
