import useUpdateUserSkillAssignments from './useUpdateUserSkillAssignments';

export const mapAssignmentsToCategories = assignments =>
  assignments.map(assignment => assignment.category);

export const mapAssignmentsToSkillWithCategories = assignments =>
  assignments
    .map(assignment =>
      mapAssignmentsToSkills(assignment.category, assignment.skillAssignments)
    )
    .reduce((acc, val) => acc.concat(val), []);

export const mapAssignmentsToSkills = (category, skillAssignments) =>
  skillAssignments.map(assignment => ({
    id: assignment.uri,
    displayText: assignment.displayText,
    uri: assignment.uri,
    category,
    skillLevel: assignment.skillLevel,
    certificate: assignment.certificate || '',
    customMetadata: assignment.skillCustomMetadata
  }));

export const initialState = ({ resourceSkill }) => ({
  categories: mapAssignmentsToCategories(resourceSkill),
  skills: mapAssignmentsToSkillWithCategories(resourceSkill)
});

const useFormState = ({ userUri, resourceSkill = [], handleReset }) => {
  const { onSubmit } = useUpdateUserSkillAssignments({
    userUri,
    handleReset
  });
  const initialValues = initialState({
    resourceSkill
  });

  return {
    initialValues,
    onSubmit,
    enableReinitialize: true
  };
};

export default useFormState;
