import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

export const useStyles = makeStyles(theme => ({
  defaultRate: ({ readOnly }) => ({
    paddingTop: readOnly ? theme.spacing(0) : theme.spacing(1.5)
  })
}));

export const DefaultFormatter = ({ index, readOnly }) => {
  const classes = useStyles({ readOnly });

  return (
    <>
      {index === 0 ? (
        <Typography
          data-qe-id="DefaultRate"
          className={classes.defaultRate}
          tabIndex={0}
        >
          <FormattedMessage id="advancedRateCard.defaultRate" />
        </Typography>
      ) : null}
    </>
  );
};

DefaultFormatter.propTypes = {
  index: PropTypes.number,
  readOnly: PropTypes.bool
};

export default DefaultFormatter;
