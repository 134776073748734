import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  errorTitle: {
    display: 'flex',
    width: '100%'
  },
  errorContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.light,
    left: 0,
    position: 'sticky'
  },
  successTitle: {
    display: 'flex',
    width: '100%'
  },
  successContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.success.light,
    left: 0,
    position: 'sticky'
  }
}));

export const useInProgressStyles = makeStyles(theme => ({
  root: {
    height: 6,
    borderRadius: 5
  },
  bar: {
    borderRadius: 5
  }
}));
