import { useCallback } from 'react';
import { compareDateObjects } from './compare';

export const useSmartStartDateChange = ({
  setFieldValue,
  endDate,
  startDateKey = 'startDate',
  endDateKey = 'endDate'
}) =>
  useCallback(
    startDate => {
      setFieldValue(startDateKey, startDate);

      const isStartDateAfterEndDate =
        compareDateObjects(startDate, endDate) === 1;

      if (isStartDateAfterEndDate) {
        setFieldValue(endDateKey, startDate);
      }
    },
    [setFieldValue, endDate]
  );

export const useSmartEndDateChange = ({
  setFieldValue,
  startDate,
  startDateKey = 'startDate',
  endDateKey = 'endDate'
}) =>
  useCallback(
    endDate => {
      setFieldValue(endDateKey, endDate);

      const isEndDateBeforeStartDate =
        compareDateObjects(endDate, startDate) === -1;

      if (isEndDateBeforeStartDate) {
        setFieldValue(startDateKey, endDate);
      }
    },
    [setFieldValue, startDate]
  );
