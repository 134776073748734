import React from 'react';
import PropTypes from 'prop-types';

import { MobileListItemLoading } from '~/modules/common/components/DetailsPage';

import ShowClosedTasksCheckbox from '../common/ShowClosedTasksCheckbox';
import useStyles from './useStyles';

const TaskListSkeleton = ({ includeClosedTasks, toggleIncludeClosedTasks }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.showClosedTasks}>
        <ShowClosedTasksCheckbox
          checked={includeClosedTasks}
          onClick={toggleIncludeClosedTasks}
        />
      </div>
      <MobileListItemLoading />
    </>
  );
};

TaskListSkeleton.propTypes = {
  includeClosedTasks: PropTypes.bool.isRequired,
  toggleIncludeClosedTasks: PropTypes.func.isRequired
};

export default TaskListSkeleton;
