import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  caption: {
    ...theme.typography.caption
  },
  body2: {
    ...theme.typography.body2
  },
  subtitle1: {
    ...theme.typography.subtitle1
  }
}));

export const FieldLabel = ({
  className,
  messageValues,
  messageId,
  variant = 'caption',
  color = 'textPrimary'
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={classNames(classes[variant], className)}
      color={color}
    >
      <FormattedMessage id={messageId} values={messageValues} />
    </Typography>
  );
};

FieldLabel.propTypes = {
  className: PropTypes.string,
  messageValues: PropTypes.object,
  messageId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string
};

export default FieldLabel;
