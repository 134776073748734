import { PropTypes } from 'prop-types';
import React from 'react';
import { Decimal } from '~/modules/common/components';
import ExchangeRate from './ExchangeRate';

export const AmountWithExchangeRate = ({
  field,
  record,
  column: { targetFieldName }
}) => {
  const sourceField = record[field];
  const targetField = record[targetFieldName];

  return (
    <>
      <Decimal
        value={sourceField.amount}
        prefix={`${sourceField.currency.symbol} `}
      />
      {targetField.amount > 0 && (
        <ExchangeRate sourceAmount={sourceField} targetAmount={targetField} />
      )}
    </>
  );
};

AmountWithExchangeRate.propTypes = {
  field: PropTypes.string.isRequired,
  column: PropTypes.object,
  record: PropTypes.object.isRequired
};

export default AmountWithExchangeRate;
