import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { ErrorSharp as ErrorIcon } from '@material-ui/icons';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  errrorIcon: {
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(0.3),
    color: theme.palette.error.dark
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body1.fontSize
  },
  errorText: {
    color: theme.palette.error.dark
  }
}));

export const ErrorTitle = ({ displayText, hasError }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {hasError && <ErrorIcon className={classes.errrorIcon} />}
      <Typography
        className={classNames(classes.title, {
          [classes.errorText]: hasError
        })}
      >
        {displayText}
      </Typography>
    </div>
  );
};

ErrorTitle.propTypes = {
  displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasError: PropTypes.bool
};

export default ErrorTitle;
