import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormField } from '~/modules/common/components';
import {
  FILE_TYPE_URI,
  NUMERIC_TYPE_URI,
  TAG_TYPE_URI,
  TEXT_TYPE_URI
} from '../definitionTypes';
import FileField from './FileField';
import NumericField from './NumericField';
import TagField from './TagField';
import TextField from './TextField';

export const ExtensionField = ({
  extensionField,
  editable,
  onChange,
  variant = 'standard',
  definitionId,
  setDefinitionId,
  align
}) => {
  const label = extensionField.definition.displayText;

  switch (extensionField.definitionTypeUri) {
    case TEXT_TYPE_URI:
      return (
        <TextField
          label={label}
          value={extensionField.textValue}
          editable={editable}
          fullWidth
          onChange={onChange}
          variant={variant}
          rows={4}
          rowsMax={10}
          multiline
        />
      );
    case NUMERIC_TYPE_URI:
      return (
        <NumericField
          label={label}
          value={extensionField.numericValue}
          editable={editable}
          fullWidth
          allowNegative
          onValueChange={onChange}
          underline={editable}
          variant={variant}
          align={align}
        />
      );
    case FILE_TYPE_URI:
      return (
        <FileField
          value={extensionField.fileValue}
          label={extensionField.definition.displayText}
          definition={extensionField.definition}
          editable={editable}
          fullWidth
          onChange={onChange}
          variant={variant}
        />
      );
    case TAG_TYPE_URI:
      return (
        <TagField
          loading={!extensionField.definition}
          onChange={onChange}
          field={extensionField}
          editable={editable}
          variant={variant}
          label={label}
          definitionId={definitionId}
          setDefinitionId={setDefinitionId}
        />
      );
    default:
      return (
        <FormField>
          <FormattedMessage id="formCustomField.notSupported" />
        </FormField>
      );
  }
};

ExtensionField.propTypes = {
  extensionField: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  definitionId: PropTypes.string.isRequired,
  setDefinitionId: PropTypes.func.isRequired,
  align: PropTypes.string,
  variant: PropTypes.string
};

export default ExtensionField;
