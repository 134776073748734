import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  makeStyles,
  ListItemIcon,
  ListItemText,
  ButtonBase
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddSharp';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'start',
    margin: theme.spacing(0, -2)
  },
  icon: {
    padding: theme.spacing(1),
    minWidth: 42
  },
  listItem: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const primaryTypographyProps = {
  color: 'primary'
};

const CreatableProgramOption = ({ option, onCreate }) => {
  const intl = useIntl();
  const classes = useStyles();

  const listItemClasses = useMemo(
    () => ({
      primary: classes.listItem
    }),
    [classes.listItem]
  );

  const handleClick = useCallback(() => onCreate(option.label), [
    option.label,
    onCreate
  ]);

  return (
    <ButtonBase className={classes.root} onClick={handleClick}>
      <ListItemIcon className={classes.icon}>
        <AddIcon color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={`${intl.formatMessage({
          id: 'addProject.program'
        })} "${option.label}"`}
        primaryTypographyProps={primaryTypographyProps}
        classes={listItemClasses}
      />
    </ButtonBase>
  );
};

CreatableProgramOption.propTypes = {
  option: PropTypes.object.isRequired,
  onCreate: PropTypes.func
};

export default CreatableProgramOption;
