import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { PAGE_SIZE } from '~/modules/common/const';

export const AVAILABLE_USER_POOLS_FOR_USER = gql`
  query getPageOfResourcePoolsAvailableForUser(
    $userId: String!
    $page: Int!
    $pageSize: Int!
    $searchFilter: ResourcePoolSearchFilter!
  ) {
    resource(userId: $userId) {
      id
      availableResourcePools(
        page: $page
        pageSize: $pageSize
        searchFilter: $searchFilter
      ) {
        id
        displayText
      }
    }
  }
`;

const mappedResourcePools = data =>
  (data?.resource?.availableResourcePools || []).map(pool => ({
    id: pool.id,
    displayText: pool.displayText
  }));

const useAvailableUserResourcePools = ({ userId, page = 1, searchFilter }) => {
  const { loading, data } = useQuery(AVAILABLE_USER_POOLS_FOR_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
      page,
      pageSize: PAGE_SIZE,
      searchFilter
    },
    context: {
      debounceKey: 'available-resource-pools-search',
      debounceTimeout: 250
    }
  });

  const resourcePools = !loading ? mappedResourcePools(data) : [];

  return {
    resourcePools,
    loading,
    hasMore: (resourcePools || []).length > PAGE_SIZE
  };
};

export default useAvailableUserResourcePools;
