import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  recallButton: {
    color: theme.palette.resourceRequest.pending.dark
  }
}));

export const RecallResourceRequestDialogActions = ({
  onCancelClick,
  onRecallClick
}) => {
  const classes = useStyles();

  return (
    <>
      <Button onClick={onCancelClick}>
        <FormattedMessage id="recallResourceRequestDialog.cancel" />
      </Button>
      <Button className={classes.recallButton} onClick={onRecallClick}>
        <FormattedMessage id="recallResourceRequestDialog.recall" />
      </Button>
    </>
  );
};

RecallResourceRequestDialogActions.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onRecallClick: PropTypes.func.isRequired
};

export default RecallResourceRequestDialogActions;
