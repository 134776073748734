import React from 'react';
import { PropTypes } from 'prop-types';

export const EditTitle = ({ children }) => <>{children}</>;

EditTitle.propTypes = {
  children: PropTypes.node
};

EditTitle.displayName = 'EditTitle';

export default EditTitle;
