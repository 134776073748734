import { gql } from 'graphql-tag';

export const GET_RESOURCE_ALLOCATION_ACTUALS_PROJECTS = gql`
  query GetResourceAllocationActualUsers(
    $pageSize: Int!
    $page: Int!
    $userId: String!
    $isSortByProjectAscending: Boolean!
    $projectWorkflowStages: [ProjectWorkflowStage!]
  ) {
    resourceUsers2(page: 1, pagesize: 1, filter: { userIds: [$userId] }) {
      id
      pageOfActualsProjectAndRoles(
        page: $page
        pageSize: $pageSize
        isSortByProjectAscending: $isSortByProjectAscending
        projectWorkflowStages: $projectWorkflowStages
      ) {
        id
        project {
          id
          displayText
        }
        roles {
          id
          displayText
        }
      }
    }
  }
`;

export const getResourceAllocationActualProjects = async ({
  page,
  pageSize,
  userId,
  apolloClient,
  projectWorkflowStages,
  isSortByProjectAscending
}) => {
  const { data } = await apolloClient.query({
    query: GET_RESOURCE_ALLOCATION_ACTUALS_PROJECTS,
    variables: {
      userId,
      pageSize,
      page: page + 1,
      projectWorkflowStages,
      isSortByProjectAscending
    }
  });

  return (data?.resourceUsers2?.[0]?.pageOfActualsProjectAndRoles || []).map(
    ({ project, roles }) => ({
      project,
      isActualEntry: true,
      roles: roles.map(role => ({
        role: role || null,
        resourceAllocationReference: []
      }))
    })
  );
};
