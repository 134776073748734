import { useCallback } from 'react';

export default ({
  handleIssueSyncClick,
  handleIssuePrintClick,
  handleIssueEmailClick,
  confirmationDialogName,
  onClose
}) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);
  const handleClick = useCallback(async () => {
    ({
      sync: handleIssueSyncClick,
      print: handleIssuePrintClick,
      email: handleIssueEmailClick
    }[confirmationDialogName]());
    onClose();
  }, [
    handleIssueSyncClick,
    handleIssuePrintClick,
    handleIssueEmailClick,
    confirmationDialogName,
    onClose
  ]);

  return {
    handleClose,
    handleClick
  };
};
