import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useHasPermission } from '~/modules/common/permissions';

export const getBillingContractsQuery = gql`
  query getBillingContracts {
    billingContracts {
      id
      displayText
    }
  }
`;

export const useDefaultBillingType = () => {
  const canEditBillingContracts = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-billing-contracts'
  });

  if (!canEditBillingContracts) {
    return {
      billingContract: null
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useQuery(getBillingContractsQuery);
  const billingContracts = data && data.billingContracts;
  const billingContract =
    billingContracts && billingContracts.length ? billingContracts[0] : null;

  return {
    billingContract: billingContract ? { contractId: billingContract.id } : null
  };
};
