import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SmartFitAllocationMode } from '~/types';
import AssignmentDialogOptionItem from './AssignmentDialogOptionItem';

const AllocateWhatFitsOptionItem = ({ hours, scale, handleOptionSelect }) => {
  const intl = useIntl();

  const secondary = intl.formatMessage(
    {
      id:
        'resourceAssignmentDialog.allocateWhatFitsValueWithoutOverAllocationScale'
    },
    { scale, hours: hours.toFixed(2) }
  );

  const allocateWhatFitsLabel = intl.formatMessage({
    id: 'resourceAssignmentDialog.allocateWhatFits'
  });

  return (
    <AssignmentDialogOptionItem
      value={SmartFitAllocationMode.Allocatewhatfits}
      primary={allocateWhatFitsLabel}
      secondary={secondary}
      onSelect={handleOptionSelect}
      inputProps={{
        'aria-label': allocateWhatFitsLabel
      }}
    />
  );
};

AllocateWhatFitsOptionItem.propTypes = {
  hours: PropTypes.number,
  scale: PropTypes.string,
  handleOptionSelect: PropTypes.func
};

export default AllocateWhatFitsOptionItem;
