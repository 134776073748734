import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

export const RowSelectorFormatter = ({
  record,
  column: { onRowSelect, selectedRows, ariaLabel = '' }
}) => {
  const rowSelectHandler = useCallback(
    event => onRowSelect({ record, event }),
    [onRowSelect, record]
  );

  const isSelected = selectedRows.includes(record.id);

  const inputProps = useMemo(() => ({ 'aria-label': ariaLabel }), [ariaLabel]);

  return (
    <Checkbox
      inputProps={inputProps}
      checked={isSelected}
      onChange={rowSelectHandler}
      color="primary"
    />
  );
};

RowSelectorFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};

export default RowSelectorFormatter;
