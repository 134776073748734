import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const MODIFY_ASSIGNED_TAG_VALUES_MUTATION = gql`
  mutation modifyAssignedTagValues($input: ModifyAssignedTagValuesInput!) {
    modifyAssignedTagValues2(input: $input) {
      assigned {
        assignment {
          id
          tag {
            id
            name
          }
          value {
            id
            value
          }
        }
        error {
          type
          reason
        }
      }
      removed {
        tagAssignmentId
      }
    }
  }
`;

export const useModifyTagsMutation = () => {
  const [modifyTagsMutation] = useMutation(MODIFY_ASSIGNED_TAG_VALUES_MUTATION);

  return modifyTagsMutation;
};
