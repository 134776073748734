import ProgramSuggestionItem from './ProgramSuggestionItem';
import { programTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import ProgramFacetDetails from './ProgramFacetDetails';

export default {
  tag: programTag,
  makeCriteriaProvider,
  DetailsControl: ProgramFacetDetails,
  SuggestionItem: ProgramSuggestionItem
};
