import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BudgetCostSummary = gql`
  query GetBudgetCostSummary($projectId: String!) {
    project(projectId: $projectId) {
      id
      resourceCostSummary {
        budgetedResourceCost {
          amount
          currency {
            symbol
          }
        }
        estimatedResourceCost {
          amount
          currency {
            symbol
          }
        }
        estimatedResourceCostAtCompletion {
          amount
          currency {
            symbol
          }
        }
        allocatedResourceCost {
          amount
          currency {
            symbol
          }
        }
        actualResourceCost {
          amount
          currency {
            symbol
          }
        }
      }
    }
  }
`;

export const useBudgetCostSummary = ({ projectId }) => {
  const { loading, data } = useQuery(BudgetCostSummary, {
    variables: { projectId },
    fetchPolicy: 'cache-and-network'
  });

  return {
    projectCostSummary: data?.project?.resourceCostSummary || {},
    isSummaryLoading: loading
  };
};

export default useBudgetCostSummary;
