import get from 'lodash.get';

export const useErrors = ({ errors, index, key }) => {
  const validationError = get(errors, `projectRates.${key}.${index}`);

  return {
    hasError: !!validationError,
    helperText: validationError
  };
};
