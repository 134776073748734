import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MenuItem, Select, FilledInput } from '@material-ui/core';
import { CompactFormControl as FormControl } from '~/modules/clients/components';

const label = <FormattedMessage id="client.invoiceCurrency" />;
const input = <FilledInput />;

const renderSelectedValue = currency => () => currency.symbol;

export const CurrencySelect = ({ currencies, value, onChange }) => (
  <FormControl variant="filled" fullWidth shrink={Boolean(value)} label={label}>
    <Select
      name="invoiceCurrencyUri"
      value={value && value.id}
      renderValue={renderSelectedValue(value)}
      input={input}
      onChange={onChange}
      data-qe-id="ClientInvoiceCurrencyDropdown"
    >
      {currencies.map(c => (
        <MenuItem key={c.id} value={c.id} aria-label={c.symbol}>
          {c.symbol}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

CurrencySelect.propTypes = {
  currencies: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default CurrencySelect;
