import { DateTime } from 'luxon';
import { toRepliconDate } from '~/modules/common/dates/convert';

export const resolveValueOrDefaultText = (value, definition) => {
  if (value && value.text) {
    return value.text;
  }

  return definition.textConfiguration.defaultTextValue;
};

export const resolveValueOrDefaultNumeric = (value, definition) => {
  if (value && value.number !== null) {
    return value.number;
  }

  return definition.numericConfiguration.defaultNumericValue;
};

export const resolveValueOrDefaultDate = (value, definition) => {
  if (value && value.date) {
    return value.date;
  }

  if (definition.dateConfiguration.defaults.useTodayAsDefault) {
    return toRepliconDate(DateTime.local());
  }

  return definition.dateConfiguration.defaults.value;
};

export const resolveValueOrDefaultDropdown = (value, definition) => {
  if (value && value.dropDownOptionUri) {
    return value.dropDownOptionUri;
  }

  if (definition.defaultDropDownValue) {
    return definition.defaultDropDownValue.uri;
  }

  return null;
};

const resolveValueOrDefault = (values, definition) => {
  const value = (values || []).find(
    field => field.customField.id === definition.uri
  );

  switch (definition.type.uri) {
    case 'urn:replicon:custom-field-type:text':
      return resolveValueOrDefaultText(value, definition);
    case 'urn:replicon:custom-field-type:numeric':
      return resolveValueOrDefaultNumeric(value, definition);
    case 'urn:replicon:custom-field-type:date':
      return resolveValueOrDefaultDate(value, definition);
    case 'urn:replicon:custom-field-type:drop-down':
      return resolveValueOrDefaultDropdown(value, definition);
    default:
      return null;
  }
};

export default resolveValueOrDefault;
