/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  focusStyles: {
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

const Caption = ({ messageId }) => {
  const classes = useStyles();

  return (
    <div className={classes.focusStyles} tabIndex={0}>
      <FormattedMessage id={messageId} />
    </div>
  );
};

Caption.propTypes = {
  messageId: PropTypes.string
};

export default Caption;
