import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import TagsEditFormRow from '~/modules/tags/components/TagsEditForm/TagsEditFormRow';

const SelectedTagDropdowns = ({ tags, errors, onTagChanged, onTagRemoved }) => {
  const renderTagDropdowns = useCallback(
    () =>
      tags.map((tag, index) => (
        <TagsEditFormRow
          tag={tag}
          selectedTags={tags}
          rowIndex={index}
          errors={errors && errors.length ? errors[index] : undefined}
          onTagChanged={onTagChanged(index)}
          onTagRemoved={onTagRemoved(index)}
          // eslint-disable-next-line react/no-array-index-key
          key={`tag_${index}`}
          canAddNewOption={false}
          hiddenLabel={false}
        ></TagsEditFormRow>
      )),
    [tags, errors, onTagChanged, onTagRemoved]
  );

  return <>{renderTagDropdowns()}</>;
};

SelectedTagDropdowns.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.string,
      tagName: PropTypes.string,
      tagValueId: PropTypes.string,
      tagValue: PropTypes.string
    })
  ),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.string,
      tagName: PropTypes.string,
      tagValueId: PropTypes.string,
      tagValue: PropTypes.string
    })
  ),
  onTagChanged: PropTypes.func,
  onTagRemoved: PropTypes.func
};

export default SelectedTagDropdowns;
