import React, { useState, useMemo } from 'react';
import { scoredSuggestions } from '../../../scoredSuggestions';
import { useProgramManagers } from './useProgramManagers';

export const mapOptions = programManagers =>
  programManagers.map(programManager => ({
    ...programManager,
    key: programManager.displayText,
    label: programManager.displayText,
    value: programManager.id
  }));

export const matchOption = (options, term) => {
  const programManager = options.find(
    ({ key }) => key.toLowerCase() === term.toLowerCase()
  );

  return programManager || null;
};

const withCriteriaProvider = (tag, context) => BaseComponent => ({
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const { programManagers } = useProgramManagers();
  const options = mapOptions(programManagers);

  const value = useMemo(
    () => ({
      options,
      matchOption,
      selected,
      setSelected,
      suggestions: scoredSuggestions(options)
    }),
    [options, selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
