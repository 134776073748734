import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const TAG_VALUE_OPTIONS_QUERY = gql`
  query TagValueOptions($id: String!, $valueSearch: String) {
    tag(id: $id) {
      values(page: 1, pageSize: 20, searchTerm: $valueSearch) {
        id
        value
      }
    }
  }
`;

const mapValues = options =>
  options.map(option => ({
    label: option.value,
    value: option.id
  }));

const useTagValueOptions = ({ tagId, valueSearch }) => {
  const { data, loading, refetch } = useQuery(TAG_VALUE_OPTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: tagId,
      valueSearch
    },
    context: {
      debounceKey: `tag-option-search-${tagId}`,
      debounceTimeout: 250
    }
  });

  const resultOptions =
    data && data.tag && Array.isArray(data.tag.values)
      ? mapValues(data.tag.values)
      : [];

  return {
    loading,
    data: resultOptions,
    refetch
  };
};

export default useTagValueOptions;
