import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { DateTime } from 'luxon';
import { useSessionStorage } from '~/modules/common/hooks';

export const GET_TIME_AND_EXPENSE_SETTINGS = gql`
  query getTimeAndExpenseSettings($id: String!) {
    timeAndExpenseSettings(id: $id) {
      rows
      groupings
      columns
      projectTimeAndExpenseDateRange {
        startDate: startDate2
        endDate: endDate2
      }
    }
  }
`;

const useGetSettings = id => {
  const {
    loading,
    data: {
      timeAndExpenseSettings: {
        projectTimeAndExpenseDateRange,
        ...timeAndExpenseSettings
      } = {}
    } = {}
  } = useQuery(GET_TIME_AND_EXPENSE_SETTINGS, {
    fetchPolicy: 'network-only',
    variables: {
      id
    }
  });

  const { storedValue } = useSessionStorage('timeAndExpenseSettings', {});

  const settings = {
    ...timeAndExpenseSettings,
    anchorDate: storedValue[id]?.anchorDate
      ? DateTime.fromISO(storedValue[id].anchorDate, { setZone: true })
      : null
  };

  return {
    settings,
    projectTimeAndExpenseDateRange: projectTimeAndExpenseDateRange && {
      startDate: DateTime.fromISO(projectTimeAndExpenseDateRange.startDate, {
        setZone: true
      }),
      endDate: DateTime.fromISO(projectTimeAndExpenseDateRange.endDate, {
        setZone: true
      })
    },
    loading
  };
};

export default useGetSettings;
