import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash.get';
import { Money2 } from '~/modules/common/components';
import { EXCHANGE_RATE_QUERY } from '~/modules/billing-invoicing/common/hooks/useExchangeRates';

const totalContractValueInBaseCurrencyColumnId =
  'urn:replicon:script-key:parameter:total-contract-value';

const contractStartDateColumnId =
  'urn:replicon:script-key:parameter:contract-start-date';

const useStyles = makeStyles(theme => ({
  currencyRoot: {
    fontSize: theme.typography.body2.fontSize,
    marginTop: theme.spacing(1),
    textAlign: 'left'
  },
  adornmentRoot: {
    marginTop: '0px'
  },
  amountField: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto'
  }
}));

export const TotalContractValue = ({
  field,
  column: {
    id: columnId,
    setFieldValue,
    errors,
    isMobile,
    label,
    onShowWarning,
    currencyEditable,
    currencyLabel
  },
  record,
  index,
  classes: classesOverride
}) => {
  const { formatMessage } = useIntl();
  const { refetch } = useQuery(EXCHANGE_RATE_QUERY, {
    variables: {
      currencyId: record[columnId].currency.id,
      otherCurrencyIds: [
        record[totalContractValueInBaseCurrencyColumnId].currency.id
      ],
      skip: true,
      asOfDate: record[contractStartDateColumnId]
    },
    fetchPolicy: 'cache-first'
  });

  const classes = useStyles({ classes: classesOverride });

  const amountStyles = useMemo(
    () => ({
      adornmentRoot: classes.adornmentRoot,
      currencyRoot: classes.currencyRoot
    }),
    [classes.adornmentRoot, classes.currencyRoot]
  );
  const { amount, currency } = record[field] || {};

  const amountChangeHandler = useCallback(
    async e => {
      const value = e && e.target && e.target.value;

      setFieldValue(`scripts[${index}].${columnId}.amount`, value || 0);

      const { data } = (await refetch()) || {};

      const rates = get(data, 'currency.exchangeRates', []);

      if (
        record[columnId].currency.id ===
        record[totalContractValueInBaseCurrencyColumnId].currency.id
      ) {
        setFieldValue(
          `scripts[${index}].${totalContractValueInBaseCurrencyColumnId}.amount`,
          value || 0
        );
      } else if (value && rates && rates.length)
        setFieldValue(
          `scripts[${index}].${totalContractValueInBaseCurrencyColumnId}.amount`,
          value * rates[0].rate
        );
      else {
        setFieldValue(
          `scripts[${index}].${totalContractValueInBaseCurrencyColumnId}.amount`,
          0
        );
      }

      if (onShowWarning) onShowWarning(true);
    },
    [columnId, index, onShowWarning, refetch, setFieldValue]
  );

  const currencyChangeHandler = useCallback(
    async newCurrency => {
      setFieldValue(`scripts[${index}].${columnId}.currency`, newCurrency);
      const { amount: value } = record[columnId];
      const { data } = (await refetch({ currencyId: newCurrency.id })) || {};
      const rates = get(data, 'currency.exchangeRates', []);

      if (value && rates && rates.length)
        setFieldValue(
          `scripts[${index}].${totalContractValueInBaseCurrencyColumnId}.amount`,
          value * rates[0].rate
        );
      else {
        setFieldValue(
          `scripts[${index}].${totalContractValueInBaseCurrencyColumnId}.amount`,
          0
        );
      }

      if (onShowWarning) onShowWarning(true);
    },
    [columnId, index, onShowWarning, setFieldValue, record, refetch]
  );

  const requiredError = useMemo(
    () => get(errors, `parameters['${columnId}'][${index}]`),
    [columnId, errors, index]
  );

  return (
    <Money2
      classes={amountStyles}
      className={classes.amountField}
      amount={amount}
      currency={currency}
      onAmountChange={amountChangeHandler}
      onCurrencyChange={currencyChangeHandler}
      currencyLabel={isMobile ? null : currencyLabel}
      error={Boolean(requiredError)}
      helperText={requiredError}
      hiddenLabel={!isMobile}
      currencyEditable={currencyEditable}
      label={label}
      ariaLabel={formatMessage({
        id: 'projectRevenueRecognitionCard.projectContractValue'
      })}
    />
  );
};

TotalContractValue.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default TotalContractValue;
