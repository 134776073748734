import { FormControl, FormLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { NoValue } from '~/modules/common/components';
import { useReadOnlyGroupStyles } from './useStyles';

const ReadOnlyGroupField = ({
  groupSettings,
  value,
  label,
  rowClasses: classesOverride,
  tag,
  component
}) => {
  const classes = useReadOnlyGroupStyles({ classes: classesOverride });

  return (
    groupSettings.isEnabled && (
      <div className={classes.rowItem}>
        <FormControl>
          <FormLabel className={classes.label}>{label}</FormLabel>
          <div className={classes.valueContainer}>
            {value ? (
              <Typography component={component} variant="body2">
                {value.displayText}
              </Typography>
            ) : (
              <NoValue />
            )}
            {tag}
          </div>
        </FormControl>
      </div>
    )
  );
};

ReadOnlyGroupField.propTypes = {
  groupSettings: PropTypes.object.isRequired,
  value: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rowClasses: PropTypes.object,
  tag: PropTypes.node,
  component: PropTypes.string
};

export default ReadOnlyGroupField;
