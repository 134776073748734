import React from 'react';
import PropTypes from 'prop-types';

import { List } from '@material-ui/core';

import FileUploadListItem from './FileUploadListItem';

const FileUploadList = ({ files = [], onRemoveItem }) => (
  <List>
    {files.map(({ filename, size }, index) => (
      <FileUploadListItem
        onRemoveItem={onRemoveItem}
        key={filename}
        fileName={filename}
        fileSize={size}
        index={index}
      />
    ))}
  </List>
);

FileUploadList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      fileSize: PropTypes.number
    })
  ),
  onRemoveItem: PropTypes.func
};

export default FileUploadList;
