import { gql } from 'graphql-tag';

export const GET_RESOURCE_PLAN_ACTUALS_USERS_AND_ROLES = gql`
  query GetResourcePlanActualUsersAndRoles(
    $projectId: String!
    $userIds: [String!]!
  ) {
    project(projectId: $projectId) {
      id
      resourcePlanActualUsersAndRoles(userIds: $userIds) {
        id
        user {
          id
          uri
          displayText
        }
        roles {
          id
          displayText
        }
      }
    }
  }
`;

export const GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES = gql`
  query GetResourcePlanAllocatedUsersAndRoles(
    $projectId: String!
    $userIds: [String!]!
  ) {
    project(projectId: $projectId) {
      id
      resourcePlanAllocatedUsersAndRoles(userIds: $userIds) {
        id
        user {
          id
          uri
          displayText
        }
        roles {
          role {
            id
            displayText
          }
          resourceAllocationReference {
            id
            resourceRequestIds
          }
        }
      }
    }
  }
`;

export const getActualUsersAndRoles = async ({
  variables,
  skip,
  apolloClient
}) => {
  const { data } = await apolloClient.query({
    query: GET_RESOURCE_PLAN_ACTUALS_USERS_AND_ROLES,
    variables,
    fetchPolicy: 'network-only',
    skip
  });

  return {
    actualsUserAndRolesData: (
      data?.project?.resourcePlanActualUsersAndRoles || []
    ).map(({ user, roles }) => ({
      user,
      roles: roles.map(role => ({
        role: role || null,
        resourceAllocationReference: []
      }))
    }))
  };
};

export const getAllocatedUsersAndRoles = async ({
  apolloClient,
  variables,
  skip
}) => {
  const { data } = await apolloClient.query({
    query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
    variables,
    fetchPolicy: 'network-only',
    skip
  });

  return {
    allocatedUsersAndRolesData:
      data?.project?.resourcePlanAllocatedUsersAndRoles || []
  };
};

export const getUsersAndRolesForProject = async ({
  projectId,
  userIds,
  isResourceActualModeEnabled,
  apolloClient
}) => {
  const variables = { projectId, userIds };

  const { actualsUserAndRolesData } = await getActualUsersAndRoles({
    variables,
    apolloClient,
    skip: !isResourceActualModeEnabled
  });

  const { allocatedUsersAndRolesData } = await getAllocatedUsersAndRoles({
    variables,
    apolloClient
  });

  return {
    actualsUserAndRolesData,
    allocatedUsersAndRolesData
  };
};
