import { makeStyles, Divider } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Money2 } from '~/modules/common/components';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me';
import { ExpenseEntryType } from '~/types';
import {
  getAmount,
  getIsEditable,
  getNewValue,
  populateMonthlyExpenseAmount,
  totalEstimatesKey,
  getFloatString
} from '../hooks/utils';

const useStyles = makeStyles(theme => ({
  actualAmountField: ({ showActuals }) => ({
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: showActuals ? theme.spacing(16.5) : '100%',
    paddingLeft: theme.spacing(0.5),
    '& input': {
      fontSize: showActuals ? 'small' : 'medium'
    }
  }),
  estimatedAmountField: ({ showActuals }) => ({
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: showActuals ? theme.spacing(16.5) : '100%',
    paddingRight: theme.spacing(0.5),
    '& input': {
      fontSize: showActuals ? 'small' : 'medium'
    }
  }),
  currency: {
    fontSize: theme.typography.body2.fontSize,
    '& div': {
      paddingRight: 'unset !important'
    }
  },
  container: {
    display: 'flex'
  },
  billableAmountDivider: {
    height: 'auto',
    marginTop: theme.spacing(-1),
    color: theme.palette.grey[100]
  },
  nonBillableAmountDivider: ({ allowBillable }) => ({
    height: 'auto',
    marginBottom: theme.spacing(-1),
    marginTop: !allowBillable ? theme.spacing(-1) : 'unset',
    color: theme.palette.grey[100]
  })
}));

const getActualsBillableField = (classes, amountStyles, actuals, intl) => (
  <>
    <Divider className={classes.billableAmountDivider} orientation="vertical" />
    <Money2
      className={classes.actualAmountField}
      classes={amountStyles}
      variant="standard"
      amount={actuals.billableAmount.amount}
      currency={actuals.billableAmount.currency}
      currencyEditable={false}
      editable={false}
      hiddenLabel
      ariaLabel={intl.formatMessage({
        id: 'expenseBillPlan.actualBillable'
      })}
    />
  </>
);

const getActualsNonBillableField = (classes, amountStyles, actuals, intl) => (
  <>
    <Divider
      className={classes.nonBillableAmountDivider}
      orientation="vertical"
    />
    <Money2
      className={classes.actualAmountField}
      classes={amountStyles}
      variant="standard"
      amount={actuals.nonBillableAmount.amount}
      currency={actuals.nonBillableAmount.currency}
      currencyEditable={false}
      editable={false}
      hiddenLabel
      ariaLabel={intl.formatMessage({
        id: 'expenseBillPlan.nonBillableActual'
      })}
    />
  </>
);

export const ExpenseAmountEditor = ({
  field,
  record,
  index,
  column: {
    setFieldValue,
    showActuals = false,
    allowBillable = false,
    projectCurrency,
    viewSummary,
    projectDateRange,
    hasStartDate,
    hasEndDate
  }
}) => {
  const intl = useIntl();
  const me = useMeContext();
  const settings = getReactNumberFormatFromMe(me);
  const classes = useStyles({ showActuals, allowBillable });
  const hasValidDateRange = hasStartDate && hasEndDate;

  const editable = getIsEditable(
    projectDateRange,
    viewSummary,
    field,
    hasValidDateRange
  );

  const { actuals, estimated } = useMemo(() => {
    const { actuals: actualAmount, estimated: estimatedAmount } =
      record[field] || {};

    return getAmount({ actualAmount, estimatedAmount, projectCurrency });
  }, [field, projectCurrency, record]);

  const onAmountChange = useCallback(
    prop => e => {
      const previousAmt =
        (record[field]?.estimated && record[field]?.estimated[prop]?.amount) ||
        0;

      const value = parseFloat(getFloatString(e.target.value, settings) || 0);

      if (value === previousAmt) return;

      setFieldValue(
        `monthlyExpenses[${index}].${field}`,
        getNewValue(prop, field, record, value, projectCurrency)
      );

      if (field === totalEstimatesKey) {
        populateMonthlyExpenseAmount(
          projectDateRange,
          setFieldValue,
          value,
          index,
          prop,
          record,
          projectCurrency,
          hasValidDateRange
        );
      } else {
        const totalEstimatedAmount =
          record?.totalEstimates?.estimated[prop]?.amount || 0;
        const sum = totalEstimatedAmount - previousAmt + value;

        setFieldValue(
          `monthlyExpenses[${index}].${totalEstimatesKey}`,
          getNewValue(prop, totalEstimatesKey, record, sum, projectCurrency)
        );
      }
    },
    [
      field,
      hasValidDateRange,
      index,
      projectCurrency,
      projectDateRange,
      record,
      setFieldValue,
      settings
    ]
  );

  const amountStyles = useMemo(() => ({ currency: classes.currency }), [
    classes.currency
  ]);

  const isBillable =
    record.expenseCode.expenseEntryType === ExpenseEntryType.Billable;
  const isBillableAndNonBillable =
    record.expenseCode.expenseEntryType ===
    ExpenseEntryType.BillableAndNonBillable;

  const actualsBillableField = getActualsBillableField(
    classes,
    amountStyles,
    actuals,
    intl
  );

  const actualsNonBillableField = getActualsNonBillableField(
    classes,
    amountStyles,
    actuals,
    intl
  );

  return (
    <>
      {isBillable ? (
        <div className={classes.container}>
          <Money2
            className={classes.estimatedAmountField}
            classes={amountStyles}
            variant="standard"
            amount={estimated.billableAmount.amount}
            currency={estimated.billableAmount.currency}
            onChange={onAmountChange('billableAmount')}
            currencyEditable={false}
            hiddenLabel
            editable={editable}
            ariaLabel={intl.formatMessage({
              id: 'expenseBillPlan.estimatedBillable'
            })}
          />
          {showActuals ? actualsBillableField : null}
        </div>
      ) : isBillableAndNonBillable ? (
        <>
          <div className={classes.container}>
            <Money2
              className={classes.estimatedAmountField}
              classes={amountStyles}
              variant="standard"
              amount={estimated.billableAmount.amount}
              currency={estimated.billableAmount.currency}
              onChange={onAmountChange('billableAmount')}
              currencyEditable={false}
              hiddenLabel
              editable={editable}
              ariaLabel={intl.formatMessage({
                id: 'expenseBillPlan.estimatedBillable'
              })}
            />
            {showActuals ? actualsBillableField : null}
          </div>
          <div className={classes.container}>
            <Money2
              className={classes.estimatedAmountField}
              classes={amountStyles}
              variant="standard"
              amount={estimated.nonBillableAmount.amount}
              currency={estimated.nonBillableAmount.currency}
              onChange={onAmountChange('nonBillableAmount')}
              currencyEditable={false}
              hiddenLabel
              editable={editable}
              ariaLabel={intl.formatMessage({
                id: 'expenseBillPlan.nonBillableEstimated'
              })}
            />
            {showActuals ? actualsNonBillableField : null}
          </div>
        </>
      ) : (
        <div className={classes.container}>
          <Money2
            className={classes.estimatedAmountField}
            classes={amountStyles}
            variant="standard"
            amount={estimated.nonBillableAmount.amount}
            currency={estimated.nonBillableAmount.currency}
            onChange={onAmountChange('nonBillableAmount')}
            currencyEditable={false}
            hiddenLabel
            editable={editable}
            ariaLabel={intl.formatMessage({
              id: 'expenseBillPlan.nonBillableEstimated'
            })}
          />
          {showActuals ? actualsNonBillableField : null}
        </div>
      )}
    </>
  );
};

ExpenseAmountEditor.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  index: PropTypes.number,
  column: PropTypes.object
};

export default ExpenseAmountEditor;
