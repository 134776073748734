import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ProgressChart } from '~/modules/common/charts/dashboard';
import { getProgressHourChartData } from '~/modules/projects/project/common/util';
import { useMeContext } from '~/modules/me/useMeContext';

export const ProgressHourChart = ({ projectId, endDate, summary }) => {
  const intl = useIntl();
  const {
    featureFlags: { isPsaPpmCostEacAlternateHoursEacEnabled }
  } = useMeContext();

  const {
    budget,
    actual,
    estimatedAtCompletion,
    estimatedBudgetRemaining,
    budgetRemaining,
    estimatedRemaining,
    percentBudgetBurned,
    percentScopeCompleted
  } = getProgressHourChartData({
    progressChartProps: summary,
    isPsaPpmCostEacAlternateHoursEacEnabled
  });

  return (
    <>
      <ProgressChart
        projectId={projectId}
        endDate={endDate}
        budget={budget}
        actual={actual}
        estimatedAtCompletion={estimatedAtCompletion}
        estimatedBudgetRemaining={estimatedBudgetRemaining}
        budgetRemaining={budgetRemaining}
        estimatedRemaining={estimatedRemaining}
        percentBudgetBurned={percentBudgetBurned}
        percentScopeCompleted={percentScopeCompleted}
        burnedAlertMessage="dashboardProgressChart.budgetBurnedAlert"
        burnedId="dashboardProgressChart.budgetBurned"
        scopeCompletedAlertMessage="dashboardProgressChart.scopeCompletedAlert"
        scopeCompletedId="dashboardProgressChart.scopeCompleted"
        fixedDecimalScale={false}
        suffix={intl.formatMessage({
          id: 'dashboardProgressChart.hrs'
        })}
        dataQeId="ProgressHourChart"
      />
    </>
  );
};

ProgressHourChart.propTypes = {
  projectId: PropTypes.string,
  endDate: PropTypes.string,
  summary: PropTypes.object
};

export default ProgressHourChart;
