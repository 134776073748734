import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { getError, hasError } from '~/util';
import PortfolioManagerDropdown from './PortfolioManagerDropdown';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const AddPortfolioForm = ({ isNew = true, isSubPortfolio = false }) => {
  const { handleChange, setFieldValue, values, errors } = useFormikContext();
  const { formatMessage } = useIntl();
  const isMobile = useIsBreakpointDown('sm');
  const { name, code, description, portfolioManager } = values;
  const nameError = getError(errors, 'name');

  const label = useMemo(
    () => ({
      name: formatMessage({ id: 'portfolio.name' }),
      code: formatMessage({ id: 'portfolio.code' }),
      description: formatMessage({ id: 'portfolio.description' })
    }),
    [formatMessage]
  );

  const handlePortfolioManagerChange = useCallback(
    event => {
      setFieldValue('portfolioManager', event);
    },
    [setFieldValue]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            required
            fullWidth
            variant="filled"
            label={label.name}
            inputProps={getAriaLabel(label.name)}
            value={name}
            onChange={handleChange}
            autoFocus
            error={Boolean(nameError)}
            helperText={nameError}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="code"
            fullWidth
            variant="filled"
            label={label.code}
            inputProps={getAriaLabel(label.code)}
            value={code}
            error={hasError(errors, 'code')}
            helperText={getError(errors, 'code')}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label={label.description}
            inputProps={getAriaLabel(label.description)}
            multiline
            maxrows={isMobile ? 2 : 3}
            fullWidth
            value={description}
            onChange={handleChange}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <PortfolioManagerDropdown
            value={portfolioManager}
            onChange={handlePortfolioManagerChange}
            isNew={isNew}
            isSubPortfolio={isSubPortfolio}
          />
        </Grid>
      </Grid>
    </>
  );
};

AddPortfolioForm.propTypes = {
  isNew: PropTypes.bool,
  isSubPortfolio: PropTypes.bool
};

export default AddPortfolioForm;
