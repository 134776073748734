import { gql } from 'graphql-tag';

const commonFragment = gql`
  fragment CommonProjectFragment on Project {
    id
    name
    displayText
    permittedActionUris
    location {
      id
      displayText
    }
    costCenter {
      id
      displayText
    }
    division {
      id
      displayText
    }
    department {
      id
      displayText
    }
    serviceCenter {
      id
      displayText
    }
    employeeType {
      id
      displayText
    }
    defaultScheduleRule {
      dateRange {
        startDate
        endDate
      }
      do
    }
  }
`;

export const projectDate2Fragment = gql`
  fragment SpecificProjectDate2Fragment on Project {
    ...CommonProjectFragment
    startDate: startDate2
    endDate: endDate2
  }
  ${commonFragment}
`;

const specificProjectFragmentLegacy = gql`
  fragment SpecificProjectFragmentLegacy on Project {
    ...CommonProjectFragment
    startDate
    endDate
  }
  ${commonFragment}
`;

export default specificProjectFragmentLegacy;
