import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getSeriesDataMap } from '~/modules/common/enhancers';
import { useBillingOverviewTableContext } from '../../BillingOverviewTableContext';
import ValueRow from './ValueRow';

export const BillingOverviewValueRow = ({
  rowTitle,
  rowData,
  totalRow,
  subRow,
  expanded,
  collapsibleHeader,
  onExpandChange,
  dataQeId,
  displayTotalColumn,
  useCellBorderClass
}) => {
  const { scale } = useBillingOverviewTableContext() || {};
  const { seriesDataMap } = useMemo(
    () => getSeriesDataMap({ rowData: rowData || {}, scale }),
    [rowData, scale]
  );

  return (
    <ValueRow
      seriesDataMap={seriesDataMap}
      rowTitle={rowTitle}
      subRow={subRow}
      totalRow={totalRow}
      expanded={expanded}
      onExpandChange={onExpandChange}
      collapsibleHeader={collapsibleHeader}
      dataQeId={dataQeId}
      displayTotalColumn={displayTotalColumn}
      useCellBorderClass={useCellBorderClass}
    />
  );
};

BillingOverviewValueRow.propTypes = {
  rowData: PropTypes.object,
  rowTitle: PropTypes.string.isRequired,
  totalRow: PropTypes.bool,
  subRow: PropTypes.bool,
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  collapsibleHeader: PropTypes.bool,
  dataQeId: PropTypes.string,
  displayTotalColumn: PropTypes.bool,
  useCellBorderClass: PropTypes.bool
};

export default BillingOverviewValueRow;
