import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import WorkflowBadge from '~/modules/projects/project/WorkflowBadge';

export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(-0.5, -1.5),
    ...theme.typography.body2
  }
}));

const WorkflowStatusColumn = ({ record: project }) => {
  const classes = useStyles();

  return (
    project &&
    project.status && (
      <WorkflowBadge
        classes={classes}
        workflowId={project.status.uri}
        customStatusLabel={project.status.displayText}
      />
    )
  );
};

WorkflowStatusColumn.propTypes = {
  record: PropTypes.object.isRequired
};

export default WorkflowStatusColumn;
