import axios from 'axios';
import to from 'await-to-js';
import get from 'lodash/fp/get';

export const buildUrl = servicesUrl => ({ service, method }) =>
  `${servicesUrl}/${service}.svc/${method}`;

export const makeCoreServiceRequest = async ({
  servicesUrl,
  service,
  method,
  data
}) => {
  const url = buildUrl(servicesUrl)({ service, method });

  const [err, result] = await to(
    axios.post(url, data, { withCredentials: true })
  );

  if (err) {
    return [err, null];
  }

  const resData = get('data')(result);
  const error = get('error')(resData);

  return error ? [error, null] : [null, get('d')(resData)];
};
