import { useMemo } from 'react';

export const useRateCardEditable = ({ client, project, context }) => {
  const {
    rowCount,
    refetch,
    readOnlyRates,
    hasMoreRows,
    loadMoreReadOnlyRates,
    loading
  } = useMemo(
    () => ({
      rowCount: {
        client: client.count,
        project: project.count
      }[context],
      refetch: {
        client: client.refetchClientRates,
        project: project.refetchProjectRates
      }[context],
      readOnlyRates: {
        client: client.rates,
        project: project.rates
      }[context],
      hasMoreRows: {
        client: client.hasMoreRows,
        project: project.hasMoreRows
      }[context],
      loadMoreReadOnlyRates: {
        client: client.loadMoreRows,
        project: project.loadMoreRows
      }[context],
      loading: {
        client: client.isClientRatesLoading,
        project: project.isProjectRatesLoading
      }[context]
    }),
    [
      client.count,
      client.hasMoreRows,
      client.isClientRatesLoading,
      client.loadMoreRows,
      client.rates,
      client.refetchClientRates,
      context,
      project.count,
      project.hasMoreRows,
      project.isProjectRatesLoading,
      project.loadMoreRows,
      project.rates,
      project.refetchProjectRates
    ]
  );

  return {
    rowCount,
    refetch,
    readOnlyRates,
    hasMoreRows,
    loadMoreReadOnlyRates,
    loading
  };
};
