import React from 'react';
import PropTypes from 'prop-types';
import { Decimal, NoValue } from '~/modules/common/components';

export const Hours = ({ field, record, className }) => {
  const hours = record[field];

  return typeof hours === 'number' ? (
    <Decimal className={className} value={hours} precision={2} />
  ) : (
    <NoValue />
  );
};

export const HoursHideZero = ({ field, record, className }) => {
  const hours = record[field];

  return typeof hours === 'number' && hours !== 0 ? (
    <Decimal className={className} value={hours} precision={2} />
  ) : (
    <NoValue />
  );
};

Hours.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string
};

HoursHideZero.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string
};

export default Hours;
