import { FormControl, FormLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { NoValue } from '~/modules/common/components';
import { useStyledPoolLabel } from '~/modules/resourcing/common/components/ResourcePool/hooks';
import { useReadOnlyGroupStyles } from './useStyles';

const ResourcePoolReadOnly = ({
  resourcePools = [],
  classes: classesOverride,
  importanceTag,
  component
}) => {
  const resourcePoolsLabel = useStyledPoolLabel();
  const classes = useReadOnlyGroupStyles({ classes: classesOverride });

  return (
    <div className={classes.rowItem}>
      <FormControl>
        <FormLabel className={classes.label}>{resourcePoolsLabel}</FormLabel>
        <div className={classes.valueContainer}>
          {resourcePools?.length > 0 ? (
            <Typography component={component} variant="body2">
              {resourcePools.map(({ displayText }) => displayText).join(', ')}
            </Typography>
          ) : (
            <NoValue />
          )}
          {importanceTag}
        </div>
      </FormControl>
    </div>
  );
};

ResourcePoolReadOnly.propTypes = {
  resourcePools: PropTypes.array,
  component: PropTypes.string.isRequired,
  importanceTag: PropTypes.node,
  classes: PropTypes.object
};

export default ResourcePoolReadOnly;
