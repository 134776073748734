import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

import { GoBackButton } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { TextLoading } from '~/modules/common/components/DetailsPage';
import EditResourceButton from './EditResourceButton';
import EditUserAvatarButton from './EditUserAvatarButton';
import AvatarUploadDialog from './AvatarUploadDialog';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      height: 56
    }
  },
  header: {
    transition: `background-color ${theme.transition.timing} ease`,
    backgroundColor: theme.palette.primary.main
  },
  resourceTitle: {
    flexGrow: 1,
    marginLeft: 17.5,

    maxWidth: '60%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '35%'
    }
  },
  back: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    width: '1.5rem',
    height: '1.5rem'
  },
  editResourceButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 5
  }
}));

export const getGoBackPath = match => {
  switch (match.path) {
    case '/profile':
      return '/my-work';
    case '/resource-pool/:slug':
    default:
      return '/resource-pool';
  }
};

export const ResourceDetailsHeader = ({
  userDetails,
  classes: classesOverride,
  editable,
  toggleEditable,
  isResourceDetailsLoading,
  smallAvatar,
  showBackButton = true,
  canEditAvatar = true
}) => {
  const classes = useStyles({ classes: classesOverride });
  const match = useRouteMatch();
  const to = getGoBackPath(match);
  const { open, closeDialog, openDialog } = useDialogState(false);
  const goBackButtonClasses = useMemo(() => ({ back: classes.back }), [
    classes.back
  ]);

  return (
    <>
      {!isResourceDetailsLoading && showBackButton && (
        <GoBackButton
          dataQeId="ResourceBackButton"
          to={to}
          classes={goBackButtonClasses}
          contextual
        />
      )}
      <EditUserAvatarButton
        dataQeId="EditAvatarSection"
        editable={editable && canEditAvatar}
        user={userDetails}
        onEditClick={openDialog}
        isResourceDetailsLoading={isResourceDetailsLoading}
        small={smallAvatar}
      />
      <div className={classes.resourceTitle}>
        {isResourceDetailsLoading ? (
          <TextLoading />
        ) : (
          <Typography data-qe-id="UserNameLabel" variant="h6" noWrap>
            {userDetails.displayText}
          </Typography>
        )}
      </div>
      <div className={classes.editResourceButton}>
        <EditResourceButton
          dataQeId="ResourceEditSection"
          editable={editable}
          toggleEditable={toggleEditable}
        />
      </div>
      {!isResourceDetailsLoading && (
        <AvatarUploadDialog
          isOpen={open}
          onClose={closeDialog}
          userUri={userDetails.uri}
        />
      )}
    </>
  );
};

ResourceDetailsHeader.propTypes = {
  userDetails: PropTypes.object,
  classes: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  toggleEditable: PropTypes.func,
  isResourceDetailsLoading: PropTypes.bool.isRequired,
  smallAvatar: PropTypes.bool,
  showBackButton: PropTypes.bool,
  canEditAvatar: PropTypes.bool
};

export default ResourceDetailsHeader;
