import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PayrollIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="m 0,14 v 8 h 4 v -8 z m 6,0 v 7 l 7,1 8,-2 V 18 H 14 L 9.910156,17.269531 10.240234,16.320312 14,17 h 2 V 15 L 7.970703000000002,14 Z" />
      <path d="m 8,4 v 8 H 24 V 4 Z m 7.94921875,2 A 2,2 0 0 1 15.951171875,6 2,2 0 0 1 16,6 2,2 0 0 1 18,8 2,2 0 0 1 16,10 2,2 0 0 1 14,8 2,2 0 0 1 15.94921875,6 Z M 11,7 a 1,1 0 0 1 1,1 1,1 0 0 1 -1,1 1,1 0 0 1 -1,-1 1,1 0 0 1 1,-1 z m 10,0 a 1,1 0 0 1 1,1 1,1 0 0 1 -1,1 1,1 0 0 1 -1,-1 1,1 0 0 1 1,-1 z" />
    </>
  </SvgIcon>
));

export default PayrollIcon;
