import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Tooltip } from '@material-ui/core';
import { ErrorSharp as ErrorIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SyncIcon from '@material-ui/icons/SyncSharp';
import { ScoreComputeStatus } from '~/types';
import propTypes from '~/modules/resourcing/components/ResourceAssignmentDialog/propTypes';
import { NoValue } from '~/modules/common/components';
import ResourceScoreTooltipContent from '../ResourceScoreTooltipContent';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'help',
    display: 'flex',
    justifyContent: 'center'
  },
  '@keyframes rotateAnimation': {
    from: { transform: 'rotate(360deg)' },
    to: { transform: 'rotate(0deg)' }
  },
  checkIcon: {
    animation: `$rotateAnimation 1.5s linear infinite`,
    transformOrigin: ['50%'],
    color: theme.palette.text.disabled
  }
}));

export const ResourceScore = forwardRef(
  (
    {
      resourceScore,
      scoreComputeStatus,
      requestedHours,
      requestedCost,
      isViewCostEnabled,
      classes: classesOverride
    },
    ref
  ) => {
    const intl = useIntl();

    const classes = useStyles({ classes: classesOverride });

    const toolTipContent = useMemo(
      () => (
        <ResourceScoreTooltipContent
          score={resourceScore?.score}
          matchingDimensions={resourceScore?.matchingDimensions}
          unMatchedDimensions={resourceScore?.unMatchedDimensions}
          matchSkillsMetadata={resourceScore?.matchSkillsMetadata}
          matchResourcePoolsMetadata={resourceScore?.matchResourcePoolsMetadata}
          totalCost={resourceScore?.totalCost}
          matchTagsMetadata={resourceScore?.matchTagsMetadata}
          scoreComputeStatus={scoreComputeStatus}
          requestedHours={requestedHours}
          requestedCost={requestedCost}
          isViewCostEnabled={isViewCostEnabled}
        />
      ),
      [
        isViewCostEnabled,
        requestedCost,
        requestedHours,
        resourceScore,
        scoreComputeStatus
      ]
    );

    if (resourceScore && resourceScore.isError) {
      return (
        <Typography variant="caption" ref={ref}>
          <Tooltip
            title={intl.formatMessage({
              id: 'score.error'
            })}
          >
            <span className={classes.root}>
              <ErrorIcon color="error" />
            </span>
          </Tooltip>
        </Typography>
      );
    }

    return (
      <Tooltip title={toolTipContent} ref={ref}>
        <span className={classes.root}>
          {resourceScore === null ||
          (resourceScore &&
            (resourceScore.score === null ||
              resourceScore.score === undefined)) ? (
            scoreComputeStatus === ScoreComputeStatus.InProgress ? (
              <SyncIcon className={classes.checkIcon} />
            ) : (
              <NoValue />
            )
          ) : (
            <Typography variant="caption">
              {Math.round(resourceScore.score)}
            </Typography>
          )}
        </span>
      </Tooltip>
    );
  }
);

ResourceScore.propTypes = {
  resourceScore: PropTypes.shape({
    score: PropTypes.number,
    matchingDimensions: PropTypes.array,
    unMatchedDimensions: PropTypes.array,
    matchSkillsMetadata: PropTypes.object,
    totalCost: PropTypes.object,
    matchResourcePoolsMetadata: PropTypes.object,
    matchTagsMetadata: PropTypes.object,
    isError: PropTypes.bool
  }),
  requestedHours: PropTypes.number,
  requestedCost: PropTypes.object,
  isViewCostEnabled: PropTypes.bool,
  scoreComputeStatus: propTypes.scoreComputeStatus,
  classes: PropTypes.object
};

export default ResourceScore;
