import React from 'react';
import PropTypes from 'prop-types';
import { Date as DateValue, NoValue } from '~/modules/common/components';

export const Date = ({ field, record, className }) => {
  const date = record[field];

  return date ? <DateValue value={date} className={className} /> : <NoValue />;
};

Date.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string
};

export default Date;
