import { useSessionStorage } from '~/modules/common/hooks';

export const GANTT_SCALE_DAY = 'day';
export const GANTT_SCALE_WEEK = 'week';
export const GANTT_SCALE_MONTH = 'month';
export const GANTT_SCALE_QUARTER = 'quarter';
export const GANTT_SCALE_YEAR = 'year';

const useGanttScale = () => {
  const {
    storedValue: ganttScale,
    setValue: setGanttScale
  } = useSessionStorage('projects-tasks-view', GANTT_SCALE_MONTH);

  return [ganttScale, setGanttScale];
};

export default useGanttScale;
