import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

const useStyles = makeStyles(theme => ({
  alert: {
    padding: theme.spacing(1, 1, 1, 0)
  },
  loading: {
    marginRight: theme.spacing(1)
  },
  bulletList: {
    padding: theme.spacing(0, 1)
  },
  resultHeader: { fontWeight: 500 }
}));

const boldText = chunks => <strong>{chunks}</strong>;

const getDisplayTitle = ({ userDisplayText, releaseDate }) => ({
  userDisplayText,
  releaseDate: releaseDate
    ? mapIsoStringtoUtcObject(releaseDate).toLocaleString(DateTime.DATE_MED)
    : null,
  b: boldText
});

const getFormattedDisplayCount = ({ taskUris }) => ({
  taskCount: taskUris.length,
  b: boldText
});

const getFormattedDisplayHoursRemaining = ({
  totalHoursRemainingAsOfDate
}) => ({ allocatedHours: totalHoursRemainingAsOfDate.toFixed(2), b: boldText });

const ImpactedTaskSummaryBanner = ({
  isLoading,
  usersAssignedImpactedTaskSummary,
  releaseDate
}) => {
  const classes = useStyles();

  if (isLoading) return <Skeleton height={50} className={classes.loading} />;

  const hasTaskAssignments = usersAssignedImpactedTaskSummary.some(
    e => e.impactedTaskSummary.taskUris.length !== 0
  );

  return hasTaskAssignments ? (
    <div className={classes.alert}>
      <Alert severity="warning" icon={false}>
        {usersAssignedImpactedTaskSummary.map(entry => (
          <Fragment key={entry.id}>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.resultHeader}
            >
              <FormattedMessage
                id={
                  releaseDate
                    ? 'releaseResourceRequestDialog.impactedTaskSummaryWithAsOfDate'
                    : 'releaseResourceRequestDialog.impactedTaskSummaryWithOutAsOfDate'
                }
                values={getDisplayTitle({
                  userDisplayText: entry.displayText,
                  releaseDate
                })}
              />
            </Typography>

            <div className={classes.bulletList}>
              <li>
                <FormattedMessage
                  id="releaseResourceRequestDialog.impactedTaskSummaryAssignedTasks"
                  values={getFormattedDisplayCount(entry.impactedTaskSummary)}
                />
              </li>
              {entry.impactedTaskSummary.totalHoursRemainingAsOfDate > 0 && (
                <li>
                  <FormattedMessage
                    id="releaseResourceRequestDialog.impactedTaskSummaryAllocationRemaining"
                    values={getFormattedDisplayHoursRemaining(
                      entry.impactedTaskSummary
                    )}
                  />
                </li>
              )}
            </div>
          </Fragment>
        ))}
      </Alert>
    </div>
  ) : null;
};

ImpactedTaskSummaryBanner.propTypes = {
  isLoading: PropTypes.bool,
  usersAssignedImpactedTaskSummary: PropTypes.array,
  releaseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default ImpactedTaskSummaryBanner;
