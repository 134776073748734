import get from 'lodash.get';

import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';

import { useGetOverEstimatedTasksProjectsQuery } from './useGetOverEstimatedTasksProjectsQuery';

export const useOverEstimatedTasksProjectsQuery = ({
  pageSize,
  isManaged,
  searchCriteria,
  overEstimatedTaskType,
  quickFilter
}) => {
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter
  });

  const options = {
    variables: {
      page: 1,
      pageSize,
      projectFilter: { ...projectFilter, isManaged },
      overEstimatedTaskType
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  };

  const { data, fetchMore, loading } = useGetOverEstimatedTasksProjectsQuery(
    options
  );

  const overEstimatedProjects =
    get(data, 'overEstimatedTasksProjects.overEstimatedProjects') || [];
  const totalProjects =
    get(data, 'overEstimatedTasksProjects.totalProjects') || 0;

  return {
    loading,
    overEstimatedProjects,
    totalProjects,
    fetchMore
  };
};
