import {
  Grid,
  ListItem,
  makeStyles,
  Typography,
  ListItemIcon,
  Checkbox
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { NoValue, Portfolio } from '~/modules/common/components';
import { ProjectRequestStatusBadge } from '../ProjectRequestStatusBadge';

const useHeaderStyles = makeStyles(theme => ({
  statusChip: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-start'
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  description: {
    fontSize: theme.typography.caption.fontSize,
    paddingRight: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  expandIcon: {
    margin: theme.spacing(-0.5),
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  expandIconOpen: {
    transform: 'rotate(90deg)'
  },
  portfolio: {
    padding: 0
  }
}));

const useStyles = makeStyles(theme => ({
  listItem: {
    width: '100%',
    padding: theme.spacing(1, 2),
    position: 'relative',
    background: '#fff',
    transition: theme.transitions.create(['background-color', 'padding'], {
      duration: theme.transitions.duration.short
    })
  },
  paddingOverride: {
    padding: theme.spacing(1, 2, 1, 0)
  }
}));
const useRowStyles = makeStyles(theme => ({
  itemRoot: {
    flex: '0 0 50%'
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
    paddingRight: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  data: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.43
  }
}));

export const MobileProjectRequestListItemHeader = ({ record }) => {
  const { name, status, description, portfolio, isExisting } = record;
  const classes = useHeaderStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={8} sm={10}>
        <Typography className={classes.name}>{name}</Typography>
        {portfolio && isExisting ? (
          <Portfolio classes={classes.portfolio} portfolio={portfolio} />
        ) : (
          <Typography className={classes.description}>{description}</Typography>
        )}
      </Grid>
      <Grid item xs={4} sm={2} className={classes.statusChip}>
        <ProjectRequestStatusBadge status={status} />
      </Grid>
    </Grid>
  );
};

MobileProjectRequestListItemHeader.propTypes = {
  record: PropTypes.object.isRequired
};

export const Total = ({ score }) => {
  const total = score ? score.effectiveTotal : null;

  return Number.isFinite(total) ? (
    Number.parseFloat(total).toFixed(2)
  ) : (
    <NoValue />
  );
};

Total.propTypes = {
  score: PropTypes.object
};

export const MobileProjectRequestListItem = ({ record, onClick }) => {
  const {
    id,
    checked = false,
    name,
    requestedBy,
    score,
    portfolio,
    isExisting
  } = record;
  const classes = useStyles({ isExisting });
  const rowClasses = useRowStyles();
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (isExisting) {
      onClick({ id, name, portfolio });
    } else {
      history.push({ hash: `#/request/${id}` });
    }
  }, [isExisting, onClick, id, name, portfolio, history]);

  return (
    <ListItem
      className={classNames(classes.listItem, {
        [classes.paddingOverride]: isExisting
      })}
      onClick={handleClick}
    >
      {isExisting ? (
        <ListItemIcon className={classes.icon}>
          <Checkbox checked={checked} />
        </ListItemIcon>
      ) : null}
      <Grid container direction="column" wrap="nowrap" elevation={0}>
        <Grid item>
          <MobileProjectRequestListItemHeader record={record} />
          <Grid container>
            <Grid className={rowClasses.itemRoot} item>
              <Typography
                className={rowClasses.label}
                variant="caption"
                component="label"
              >
                <FormattedMessage id="projectRequest.requestedBy" />
              </Typography>
              <Typography className={rowClasses.data} component="div">
                {requestedBy || <NoValue />}
              </Typography>
            </Grid>
            <Grid className={rowClasses.itemRoot} item>
              <Typography
                className={rowClasses.label}
                variant="caption"
                component="label"
              >
                <FormattedMessage id="projectRequest.totalScore" />
              </Typography>
              <Typography className={rowClasses.data} component="div">
                <Total score={score} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

MobileProjectRequestListItem.propTypes = {
  record: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isExisting: PropTypes.bool
};

export default MobileProjectRequestListItem;
