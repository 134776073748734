import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { useMeContext } from '~/modules/me';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  select: {
    fontSize: theme.typography.body2.fontSize
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export const TimeAndExpenseEntryTypeDropdown = ({
  value,
  onChange,
  showBillingOptions = true,
  labelWidth,
  ...rest
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = me;

  const options = useMemo(() => {
    const types = [
      {
        id: TIME_AND_EXPENSE_ENTRY_TYPE.NO,
        displayText: intl.formatMessage({
          id: 'timeAndExpenseEntryOptions.'.concat(
            TIME_AND_EXPENSE_ENTRY_TYPE.NO
          )
        })
      },
      {
        id: isPsaPrpPsaPpmMergerEnabled
          ? showBillingOptions
            ? TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE
            : TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE
          : TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE,
        displayText: intl.formatMessage({
          id: showBillingOptions
            ? 'timeAndExpenseEntryOptions.'.concat(
                TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE
              )
            : 'timeAndExpenseEntryOptions.yes'
        })
      }
    ];

    if (showBillingOptions)
      return types.concat([
        {
          id: TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE,
          displayText: intl.formatMessage({
            id: 'timeAndExpenseEntryOptions.'.concat(
              TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE
            )
          })
        },
        {
          id: TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE_AND_NON_BILLABLE,
          displayText: intl.formatMessage({
            id: 'timeAndExpenseEntryOptions.'.concat(
              TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE_AND_NON_BILLABLE
            )
          })
        }
      ]);

    return types;
  }, [intl, showBillingOptions]);

  const selectedValue = useMemo(() => {
    return (
      options.find(x => x.id === value) ||
      options.find(x => x.id === TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE)
    );
  }, [options, value]);

  const dropdownClasses = useMemo(
    () => ({
      container: classes.container,
      select: classes.select
    }),
    [classes.container, classes.select]
  );

  return (
    <SimpleDropdown
      options={options}
      onChange={onChange}
      value={selectedValue}
      classes={dropdownClasses}
      noneOption={false}
      labelWidth={labelWidth}
      disableSort
      {...rest}
    />
  );
};

TimeAndExpenseEntryTypeDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showBillingOptions: PropTypes.bool,
  labelWidth: PropTypes.number
};

export default TimeAndExpenseEntryTypeDropdown;
