import React from 'react';
import PropTypes from 'prop-types';
import { SeriesChart } from '~/modules/common/charts/dashboard';
import { useHourChartConfig } from '~/modules/common/charts/dashboard/config';
import { useHourChartData } from '~/modules/common/charts/hooks';
import { BarsChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import { useProjectHours } from './hooks/useProjectHours';

export const HoursChart = ({ chartDataInput, titleId }) => {
  const { actualBarColor, gradientId, legends } = useHourChartConfig();
  const { loading, hourSeriesData } = useProjectHours({ chartDataInput });
  const { actualDataPoints, estimatedDataPoints } = useHourChartData({
    hourSeriesData
  });

  return loading ? (
    <BarsChartLoading />
  ) : (
    <SeriesChart
      name="projectHours"
      actualBarColor={actualBarColor}
      gradientId={gradientId}
      legends={legends}
      actualDataPoints={actualDataPoints}
      estimatedDataPoints={estimatedDataPoints}
      titleId={titleId}
    />
  );
};

HoursChart.propTypes = {
  chartDataInput: PropTypes.object,
  titleId: PropTypes.string
};

export default HoursChart;
