import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useGetResourceRequestFilter } from '~/modules/resourcing/common/hooks/useGetResourceRequestFilter';
import { DELETE_RESOURCE_REQUEST } from '~/modules/resourcing/common/hooks/useDeleteResourceRequestHandler';
import {
  writeRequestedTotalCostAndTotalHoursToCache,
  invokeUpdateResourceRequestAggregateCache
} from '~/modules/resourcing/enhancers/updateResourceRequestTotalsCache';
import { getCostAndHoursDelta } from '~/modules/resourcing/util/getCostAndHoursDelta';

export const updateCache = ({
  request,
  filter,
  isResourceActualModeEnabled
}) => proxy => {
  const resourceRequestCacheId = `ResourceRequest:{"id":"${request.id}"}`;

  const costAndHoursDelta = getCostAndHoursDelta({
    initialTotalCost: {
      currency: request.currencyUri,
      amount: request.roleRate * request.totalHours
    },
    initialHours: request.totalHours
  });

  const updatedData = {
    id: request.id,
    totalRequestedCostByCurrency: {
      baseCurrency: {
        amount: 0,
        currency: null,
        __typename: 'MoneyValue'
      },
      projectBudgetCurrency: null,
      __typename: 'ResourceRequestTotalCostByCurrency'
    },
    totalHours: 0
  };

  writeRequestedTotalCostAndTotalHoursToCache({
    proxy,
    request,
    updatedData
  });

  if (costAndHoursDelta && Object.keys(costAndHoursDelta).length > 0) {
    invokeUpdateResourceRequestAggregateCache({
      proxy,
      projectId: request.projectUri,
      costAndHoursDelta,
      filter: filter || {},
      isResourceActualModeEnabled
    });
  }

  proxy.evict({ id: resourceRequestCacheId });
  proxy.gc();
};

export const useDeleteResourceRequest = ({ isResourceActualModeEnabled }) => {
  const [deleteResourceRequest] = useMutation(DELETE_RESOURCE_REQUEST, {});
  const filter = useGetResourceRequestFilter();

  return useCallback(
    async ({ request }) => {
      await deleteResourceRequest({
        variables: {
          input: {
            id: request.id
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          isOptimistic: true,
          deleteResourceRequest2: {
            __typename: 'DeleteResourceRequestResult',
            id: request.id
          }
        },
        update: updateCache({
          request,
          filter,
          isResourceActualModeEnabled
        })
      });
    },
    [deleteResourceRequest, filter, isResourceActualModeEnabled]
  );
};

export default useDeleteResourceRequest;
