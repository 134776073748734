import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, DialogContentText, Grid } from '@material-ui/core';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  removeIcon: {
    color: theme.palette.secondary.main
  },
  iconButton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-1)
  },
  categoryCell: {
    borderBottom: 'none',
    padding: theme.spacing(1, 0),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: 'inherit',
    alignSelf: 'center'
  },
  removeIconCell: {
    textAlign: 'end',
    borderBottom: 'none'
  }
}));

const deleteDialogResourceKeys = {
  header: 'skillInfoCard.deleteSkillDialog.removeCategory',
  actionButton: 'skillInfoCard.deleteSkillDialog.removeCategory'
};

export const MobileSkillCategory = ({
  classes: classesOverride,
  category,
  onCategoryRemove
}) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);
  const onConfirm = useCallback(() => onCategoryRemove(category), [
    category,
    onCategoryRemove
  ]);

  return (
    <>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onConfirm}
        >
          <DialogContentText>
            <FormattedMessage id="skillInfoCard.deleteSkillDialog.removeCategoryDescription" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
      <Grid item xs={11} className={classes.categoryCell}>
        {category ? (
          <span>{category.displayText}</span>
        ) : (
          <FormattedMessage id="skillInfoCard.unCategorized" />
        )}
      </Grid>
      <Grid item xs={1} className={classes.removeIconCell}>
        <IconButton className={classes.iconButton} onClick={openRemoveDialog}>
          <RemoveCircle className={classes.removeIcon} />
        </IconButton>
      </Grid>
    </>
  );
};

MobileSkillCategory.propTypes = {
  classes: PropTypes.object,
  category: PropTypes.object,
  onCategoryRemove: PropTypes.func
};

export default MobileSkillCategory;
