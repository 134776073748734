import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AsOfDate } from './AsOfDate';

const yes = <FormattedMessage id="client.yes" />;
const no = <FormattedMessage id="client.no" />;

export const BooleanFormatter = ({ record, field }) => {
  const { value, asOfDate } = record[field];
  const displayText = useMemo(() => (value ? yes : no), [value]);

  return (
    <>
      <Typography>{displayText}</Typography>
      <AsOfDate date={asOfDate} />
    </>
  );
};

BooleanFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default BooleanFormatter;
