import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import { useIntl } from 'react-intl';
import RequestActionButton from './RequestActionButton';

const RequestRejectedRequestActionButton = ({
  className,
  onClick,
  label,
  size,
  overflow,
  variant,
  disabled
}) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <RequestActionButton
      className={className}
      paletteClass={theme.palette.resourceRequest.requestRejected}
      label={
        label || intl.formatMessage({ id: 'resourceAssignmentDialog.rejected' })
      }
      onClick={onClick}
      size={size}
      overflow={overflow}
      variant={variant}
      disabled={disabled}
    />
  );
};

RequestRejectedRequestActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  overflow: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default RequestRejectedRequestActionButton;
