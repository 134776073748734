import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ViewMultipleResourceRequestsDialogContent from './ViewMultipleResourceRequestsDialogContent';

export const ViewMultipleResourceRequestsDialog = ({
  onClose,
  open,
  projectId,
  user,
  resourceRequestListItemClickHandler
}) => {
  const userDisplayText = useMemo(() => ({ user: user.displayText }), [user]);

  return (
    <Dialog open={open} onClose={onClose}>
      <>
        <DialogTitle>
          <FormattedMessage
            id="viewResourceRequestsSummaryDialog.title"
            values={userDisplayText}
          />
        </DialogTitle>
        <ViewMultipleResourceRequestsDialogContent
          projectId={projectId}
          userId={user.id}
          onClose={onClose}
          resourceRequestListItemClickHandler={
            resourceRequestListItemClickHandler
          }
        />
        <DialogActions>
          <Button onClick={onClose} variant="text" color="secondary">
            <FormattedMessage id="button.cancel" />
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

ViewMultipleResourceRequestsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string,
  user: PropTypes.object,
  resourceRequestListItemClickHandler: PropTypes.func
};

export default ViewMultipleResourceRequestsDialog;
