import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    verticalAlign: 'top',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'flex-start',
    color: theme.palette.text.primary,
    width: '100%'
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'start',
    fontSize: theme.typography.body2.fontSize,
    width: '1.5em',
    height: '1.5em',
    margin: [[0, '0.375em', 0, '0.125em']],
    color: theme.palette.text.secondary
  }
}));

export default useStyles;
