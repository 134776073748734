import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECTS_QUERY = gql`
  query MyProjects($page: Int!, $pagesize: Int!, $searchTerm: String) {
    me {
      id
      projects(page: $page, pagesize: $pagesize, searchTerm: $searchTerm) {
        id
        displayText
      }
    }
  }
`;

export const useProjectOptions = searchTerm => {
  const { data, loading } = useQuery(GET_PROJECTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pagesize: 100,
      searchTerm
    },
    context: {
      debounceKey: 'project-option',
      debounceTimeout: 250
    }
  });
  const projects =
    data?.me?.projects?.map(project => ({
      ...project,
      value: project.id,
      label: project.displayText,
      key: project.displayText
    })) || [];

  return {
    loading,
    projects
  };
};

export const searchableProjects = ({
  client,
  page,
  pagesize
}) => async searchTerm => {
  const result = await client.query({
    query: GET_PROJECTS_QUERY,
    variables: {
      page,
      pagesize,
      searchTerm
    }
  });

  return result.data.me.projects.map(project => ({
    ...project,
    value: project.id,
    label: project.displayText,
    key: project.displayText
  }));
};

export const useSearchableProjectOptions = ({ page, pagesize }) => {
  const apolloClient = useApolloClient();

  return {
    fetchOptions: searchableProjects({
      page: page || 1,
      client: apolloClient,
      pagesize: pagesize || 100
    })
  };
};
