import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';

export const prependNoneItem = (displayText, items) => [
  {
    id: NONE_OPTION_KEY,
    displayText,
    label: displayText,
    key: NONE_OPTION_KEY,
    value: null
  },
  ...items
];
