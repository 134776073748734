import { gql, useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';

export const PAGE_SIZE = 20;

export const RESOURCE_USER_TOTAL_HOURS_BY_ALLOCATION_QUERY = gql`
  query GetProjectAllocationSummary(
    $cursor: String
    $pageSize: Int!
    $userFilter: GetResourceUsersFilter
    $projectAllocationSummaryFilter: ResourceUserProjectAllocationSummaryFilter!
  ) {
    resourceUsers2(filter: $userFilter) {
      id
      projectAllocationSummary(
        cursor: $cursor
        pageSize: $pageSize
        filter: $projectAllocationSummaryFilter
      ) {
        entries {
          project {
            id
            displayText
          }
          allocationStatus
          totalHours
        }
        nextPageCursor
      }
    }
  }
`;

export const getPaginationToken = result =>
  result?.resourceUsers2[0]?.projectAllocationSummary?.nextPageCursor;

const useGetLazyAllocationSummary = ({
  endDate,
  resourceId,
  startDate,
  skip
}) => {
  const [getCurrentAllocations, { data, loading, fetchMore }] = useLazyQuery(
    RESOURCE_USER_TOTAL_HOURS_BY_ALLOCATION_QUERY,
    {
      variables: {
        cursor: null,
        pageSize: PAGE_SIZE,
        userFilter: {
          userIds: [resourceId]
        },
        projectAllocationSummaryFilter: {
          startDate,
          endDate
        }
      }
    }
  );
  const cursor = !skip && getPaginationToken(data);

  const loadPaginatedResultsCallback = useCallback(() => {
    if (!cursor) return;

    loadPaginatedResults({
      variables: {
        cursor,
        pageSize: PAGE_SIZE,
        userFilter: {
          userIds: [resourceId]
        },
        projectAllocationSummaryFilter: {
          startDate,
          endDate
        }
      },
      fetchMore
    });
  }, [cursor, startDate, endDate, resourceId, fetchMore]);

  useEffect(() => {
    loadPaginatedResultsCallback();
  }, [loadPaginatedResultsCallback]);

  return {
    getCurrentAllocations,
    totalHoursByProject:
      data?.resourceUsers2[0]?.projectAllocationSummary.entries.filter(
        e => e.totalHours > 0
      ) || [],
    loading
  };
};

export const loadPaginatedResults = async ({ variables, fetchMore }) => {
  let mutableCursor = variables.cursor;

  while (mutableCursor) {
    // eslint-disable-next-line no-await-in-loop
    const { data } = await fetchMore({
      variables: {
        ...variables,
        cursor: mutableCursor
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    });

    mutableCursor = getPaginationToken(data);
  }
};

export default useGetLazyAllocationSummary;
