import { useContext } from 'react';
import { ProjectResourcingContext } from './ProjectResourcingContext';

export default () => {
  const { searchCriteria, setSearchCriteria } = useContext(
    ProjectResourcingContext
  );

  return { searchCriteria, setSearchCriteria };
};
