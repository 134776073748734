import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Delta } from '~/modules/common/components';
import EstimatedCompletionDateOverride from './EstimatedCompletionDateOverride';

const useStyles = makeStyles(theme => ({
  dataCell: {},
  overriddenCell: {},
  strikeThrough: {},
  estimatedCompletionDateCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  date: {
    justifyContent: 'flex-start'
  },
  delta: {
    justifyContent: 'flex-end'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
}));

export const EstimatedCompletionDate = ({
  classes: classesOverride,
  estimatedCompletionDate,
  delta,
  overrideValue,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.estimatedCompletionDateCell}>
      <div className={classNames(classes.item, classes.date)}>
        <EstimatedCompletionDateOverride
          dataQeId={dataQeId}
          classes={classes}
          estimatedCompletionDate={estimatedCompletionDate}
          overrideValue={overrideValue}
        />
      </div>
      <div className={classNames(classes.item, classes.delta)}>
        {Boolean(delta) && <Delta delta={delta} reverseColors />}
      </div>
    </div>
  );
};

EstimatedCompletionDate.propTypes = {
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  estimatedCompletionDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  delta: PropTypes.number,
  overrideValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default EstimatedCompletionDate;
