import getUpdatedCostByCurrency from '../hooks/getUpdatedCostByCurrency';
import { getCostAndHoursDelta } from '../util/getCostAndHoursDelta';
import {
  readRequestedTotalCostAndTotalHoursFromCache,
  writeRequestedTotalCostAndTotalHoursToCache,
  invokeUpdateResourceRequestAggregateCache
} from './updateResourceRequestTotalsCache';

export const processResourceRequestAndUpdateCache = ({
  proxy,
  request,
  filter,
  isResourceActualModeEnabled
}) => {
  if (!proxy) return {};

  const totalRequestedCostAndHoursFromCache = readRequestedTotalCostAndTotalHoursFromCache(
    {
      proxy,
      request
    }
  );

  if (!totalRequestedCostAndHoursFromCache) {
    return {};
  }

  const totalRequestedCostForResourceRequest =
    (request.roleRate ?? 0) * request.totalHours;

  const newTotalCost = getUpdatedCostByCurrency({
    exchangeRateValues: {
      baseCurrency: request.exchangeRateValues?.baseCurrency,
      projectBudgetCurrency: request.exchangeRateValues?.projectBudgetCurrency
    },
    newTotalCost: { amount: totalRequestedCostForResourceRequest },
    isAllocation: false
  });

  const updatedRequest = {
    ...request,
    totalRequestedCostByCurrency: newTotalCost
  };

  const updatedData = {
    ...totalRequestedCostAndHoursFromCache,
    totalRequestedCostByCurrency: newTotalCost,
    totalHours: request.totalHours
  };

  writeRequestedTotalCostAndTotalHoursToCache({
    proxy,
    request,
    updatedData
  });

  const costAndHoursDelta = getCostAndHoursDelta({
    newTotalCost:
      newTotalCost?.projectBudgetCurrency || newTotalCost?.baseCurrency,
    initialTotalCost:
      totalRequestedCostAndHoursFromCache?.totalRequestedCostByCurrency
        ?.projectBudgetCurrency ||
      totalRequestedCostAndHoursFromCache?.totalRequestedCostByCurrency
        ?.baseCurrency,
    newHours: request.totalHours,
    initialHours: totalRequestedCostAndHoursFromCache.totalHours
  });

  if (costAndHoursDelta && Object.keys(costAndHoursDelta).length > 0) {
    invokeUpdateResourceRequestAggregateCache({
      proxy,
      projectId: request.projectUri,
      costAndHoursDelta,
      filter: filter || {},
      isResourceActualModeEnabled
    });
  }

  return {
    updatedRequest,
    totalHours: request.totalHours,
    costAndHoursDelta,
    totalRequestedCostByCurrency: newTotalCost
  };
};
