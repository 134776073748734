import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

const MOVE_TASK_MUTATION = gql`
  mutation moveTask(
    $taskId: String!
    $targetId: String!
    $moveTaskMethod: MoveTaskMethod!
  ) {
    moveTask: moveTask2(
      input: {
        taskId: $taskId
        targetId: $targetId
        moveTaskMethod: $moveTaskMethod
      }
    ) {
      result
    }
  }
`;

const useMoveTaskMutation = () => useMutation(MOVE_TASK_MUTATION);

export const useMoveTask = ({
  projectUri,
  setMoveTaskError,
  setMoveTaskLoading,
  openMoveTaskErrorDialog
}) => {
  const [moveTaskMutation] = useMoveTaskMutation();

  return useCallback(
    async (taskId, targetId, moveTaskMethod) => {
      const moveTargetId = targetId || projectUri;

      setMoveTaskLoading(true);

      const {
        data: {
          moveTask: { result: error }
        }
      } = await moveTaskMutation({
        variables: {
          taskId,
          targetId: moveTargetId,
          moveTaskMethod
        },
        refetchQueries: ['projectTasksQuery'],
        awaitRefetchQueries: true
      });

      setMoveTaskLoading(false);

      if (error) {
        setMoveTaskError(error);
        openMoveTaskErrorDialog();
      }

      return error;
    },
    [
      projectUri,
      moveTaskMutation,
      setMoveTaskLoading,
      setMoveTaskError,
      openMoveTaskErrorDialog
    ]
  );
};

export default useMoveTask;
