import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { BillStatus } from '~/types';

export const UPDATE_BILL_BATCH_STATUS = gql`
  mutation updateBillStatusBatch($input: UpdateBillStatusBatchParameter!) {
    updateBillStatusBatch(updateBillStatusBatchParameter: $input) {
      batchUri
    }
  }
`;

export const mapToServiceFilter = ({
  filter: {
    clients: clientUris,
    transactionTypes,
    dateRange,
    text,
    balanceStatusList,
    isOverdue,
    dueDate
  } = {}
}) => ({
  clientUris,
  transactionTypes,
  dateRange,
  searchTerm: text,
  balanceStatusList,
  isOverdue,
  dueDate
});

export const useUpdateBillStatusBatch = ({
  includedBillIds,
  excludedBillIds,
  isAllSelected,
  filter,
  setRecalculationBatchState
}) => {
  const [updateBillStatusBatch] = useMutation(UPDATE_BILL_BATCH_STATUS);

  return {
    updateBillStatusBatch: async () => {
      const { data } =
        (await updateBillStatusBatch({
          variables: {
            input: {
              billStatus: BillStatus.Billed,
              syncBillsInput: {
                includeBillIds: includedBillIds,
                excludeBillIds: excludedBillIds,
                allSelected: isAllSelected,
                filter: mapToServiceFilter({ filter })
              }
            }
          }
        })) || {};

      const batchId = get(data, 'updateBillStatusBatch.batchUri', null);

      setRecalculationBatchState({
        batchId,
        batchInProgress: true
      });
    }
  };
};
export default useUpdateBillStatusBatch;
