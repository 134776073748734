import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { planParameterValueFormatter } from './useBillPlanEnhancers';

export const putProjectBillPlansQuery = gql`
  mutation PutProjectBillPlans(
    $projectId: String!
    $billPlans: [ProjectBillPlanInput!]
  ) {
    putProjectBillPlans2(projectId: $projectId, billPlans: $billPlans) {
      project {
        id
      }
      billingContractRecalcBatchUri
    }
  }
`;

const formatParameterValues = planValue => {
  const keys = Object.keys(planValue);
  const formattedValue = { ...planValue };

  keys.forEach(key => {
    const getValue = planParameterValueFormatter[key];

    if (getValue) formattedValue[key] = getValue(planValue[key]);
  });

  return formattedValue;
};

const planValuesWithTarget = planValues =>
  planValues.map(planValue => ({
    ...formatParameterValues(planValue),
    projectBillingContractScriptId: planValue.scriptId.includes(
      'project-billing-contract-script'
    )
      ? planValue.scriptId
      : null
  }));

export const usePutProjectBillPlans = ({ projectUri, billPlans }) => {
  const [putBillPlans] = useMutation(putProjectBillPlansQuery);

  return {
    putBillPlans: async ({ updatedBillPlan, removePlan, setBatchState }) => {
      const newBillPlans = billPlans.filter(
        plan => !(plan.id === updatedBillPlan.id)
      );

      const plans = removePlan
        ? newBillPlans.map(plan => ({
            id: plan.id,
            scripts: planValuesWithTarget(plan.scripts)
          }))
        : [...newBillPlans, updatedBillPlan].map(plan => ({
            id: plan.id,
            scripts: planValuesWithTarget(plan.scripts)
          }));

      await putBillPlans({
        variables: {
          projectId: projectUri,
          billPlans: plans
        },
        update: (
          proxy,
          {
            data: {
              putProjectBillPlans2: { billingContractRecalcBatchUri }
            }
          }
        ) => {
          setBatchState({
            batchId: billingContractRecalcBatchUri,
            batchInProgress: true
          });
        }
      });
    }
  };
};

export default usePutProjectBillPlans;
