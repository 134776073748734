import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import {
  CREATE_RESOURCE_ALLOCATION,
  buildCreateResourceAllocationOptimisticResponse
} from '~/modules/resourcing/hooks/useCreateResourceAllocationHandler';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';
import { omitForbiddenCreateFields } from '~/modules/resourcing/common/util/resourceAllocationUtil';

export const DELETE_RESOURCE_ALLOCATION = gql`
  mutation DeleteResourceAllocation($input: DeleteResourceAllocationInput!) {
    deleteResourceAllocation2(input: $input) {
      id
    }
  }
`;

export const updateCreateCache = ({ resourceRequestId }) => (
  proxy,
  mutationResponse
) => {
  const {
    data: {
      createResourceAllocation2: { resourceAllocation: newAllocation }
    }
  } = mutationResponse;

  const cachedFragmentId = `ResourceRequest:${resourceRequestId}`;

  const cachedRequest = tryLoadCachedFragment(proxy, cachedFragmentId);

  if (cachedRequest) {
    proxy.writeFragment({
      id: cachedFragmentId,
      fragment: specificResourceRequestWithAllocationsFragment,
      fragmentName: 'SpecificResourceRequestWithAllocation',
      data: {
        ...cachedRequest,
        resourceAllocations: [
          ...cachedRequest.resourceAllocations,
          newAllocation
        ]
      }
    });
  }
};

export const updateDeleteCache = resourceRequestId => (
  proxy,
  mutationResponse
) => {
  const {
    data: {
      deleteResourceAllocation2: { id: resourceAllocationId }
    }
  } = mutationResponse;

  const cachedFragmentId = `ResourceRequest:${resourceRequestId}`;

  const cachedRequest = tryLoadCachedFragment(proxy, cachedFragmentId);

  if (cachedRequest) {
    proxy.writeFragment({
      id: cachedFragmentId,
      fragment: specificResourceRequestWithAllocationsFragment,
      fragmentName: 'SpecificResourceRequestWithAllocation',
      data: {
        ...cachedRequest,
        resourceAllocations: cachedRequest.resourceAllocations.filter(
          c => c.id !== resourceAllocationId
        )
      }
    });
  }
};

const tryLoadCachedFragment = (proxy, fragmentId) => {
  try {
    return proxy.readFragment({
      id: fragmentId,
      fragmentName: 'SpecificResourceRequestWithAllocation',
      fragment: specificResourceRequestWithAllocationsFragment
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);

    return null;
  }
};

export default () => {
  const [createResourceAllocation] = useMutation(CREATE_RESOURCE_ALLOCATION);

  const onCreateResourceAllocation = useCallback(
    ({ allocation, user }) =>
      createResourceAllocation({
        variables: {
          input: omitForbiddenCreateFields(allocation)
        },
        optimisticResponse: buildCreateResourceAllocationOptimisticResponse({
          allocation,
          user
        })
      }),
    [createResourceAllocation]
  );

  const onCreateResourceAllocationWithOutCacheUpdate = useCallback(
    ({ allocation }) =>
      createResourceAllocation({
        variables: {
          input: omitForbiddenCreateFields(allocation)
        }
      }),
    [createResourceAllocation]
  );

  const [deleteResourceAllocation] = useMutation(DELETE_RESOURCE_ALLOCATION);

  const onDeleteResourceAllocation = useCallback(
    allocation =>
      deleteResourceAllocation({
        variables: {
          input: {
            id: allocation.id
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteResourceAllocation2: {
            __typename: 'DeleteResourceAllocationResult',
            id: allocation.id
          }
        },
        update: updateDeleteCache(allocation.resourceRequestId)
      }),
    [deleteResourceAllocation]
  );

  const onDeleteResourceAllocationWithoutCacheUpdate = useCallback(
    allocation =>
      deleteResourceAllocation({
        variables: {
          input: {
            id: allocation.id
          }
        }
      }),
    [deleteResourceAllocation]
  );

  return {
    onCreateResourceAllocationWithOutCacheUpdate,
    onDeleteResourceAllocationWithoutCacheUpdate,
    onCreateResourceAllocation,
    onDeleteResourceAllocation
  };
};
