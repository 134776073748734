import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, MenuItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useMenuState } from '~/modules/common/hooks';
import { RateCardEditable } from '~/modules/rateCard/components';
import { useRateEditHandlers } from '~/modules/rateCard/hooks/useRateEditHandlers';
import { useRateCardFormState } from '~/modules/projects/project/BillPlanV2/BillPlanPreRequisites/hooks/useRateCardFormState';

const context = 'project';

const useStyles = makeStyles(theme => ({
  addRateTypeButton: {
    color: theme.palette.primary.main
  }
}));

const getPrimaryText = rateType => (
  <FormattedMessage id={`rateCard.title.${rateType}`} />
);

const initialRates = [];

const AddRateType = ({
  projectDetails,
  rates: existingRates,
  setRatesModifier
}) => {
  const classes = useStyles();
  const { projectId, projectSlug } = projectDetails;
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const isOpen = Boolean(anchorEl);

  const formik = useRateCardFormState();

  const { validateForm, resetForm, setFieldValue, errors, values } = formik;

  const rates = useMemo(
    () => ({
      userRates: (values && values.userRates) || [],
      roleRates: (values && values.roleRates) || [],
      projectRate: (values && values.projectRate) || []
    }),
    [values]
  );
  const rateTypeKeys = useMemo(() => Object.keys(rates || {}), [rates]);

  const rateEditHandlers = useRateEditHandlers({
    setFieldValue
  });
  const { onAddRateType } = rateEditHandlers;

  const availableRatesToAdd = useMemo(
    () =>
      existingRates
        ? Object.keys(existingRates).filter(key => !existingRates[key].length)
        : [],
    [existingRates]
  );
  const onMenuItemClick = useCallback(
    rateType => () => {
      onAddRateType(rateType);
      onMenuClose(null);
    },
    [onAddRateType, onMenuClose]
  );

  return (
    <>
      <Button
        className={classes.addRateTypeButton}
        onClick={onMenuClick}
        disabled={!availableRatesToAdd.length}
      >
        <FormattedMessage id="rateCard.addRateType" />
      </Button>
      <Menu
        id="add-rate-type"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onMenuClose}
      >
        {availableRatesToAdd.map(rateType => (
          <MenuItem key={rateType} onClick={onMenuItemClick(rateType)}>
            <ListItemText primary={getPrimaryText(rateType)} />
          </MenuItem>
        ))}
      </Menu>
      {rateTypeKeys.length
        ? rateTypeKeys.map(rateTypeKey =>
            rates[rateTypeKey].length ? (
              <RateCardEditable
                key={rateTypeKey}
                errors={errors}
                rateTypeKey={rateTypeKey}
                rateEditHandlers={rateEditHandlers}
                editable
                rates={rates[rateTypeKey]}
                initialRates={initialRates}
                resetForm={resetForm}
                validateForm={validateForm}
                setFieldValue={setFieldValue}
                projectId={projectId}
                projectSlug={projectSlug}
                context={context}
                showRateCard
                setRatesModifier={setRatesModifier}
              />
            ) : null
          )
        : null}
    </>
  );
};

AddRateType.propTypes = {
  projectDetails: PropTypes.object,
  rates: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setRatesModifier: PropTypes.func
};
export default AddRateType;
