import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ReadOnlyHours } from '~/modules/common/components';

const useStyles = makeStyles({
  dataCell: {},
  overriddenCell: {},
  strikeThrough: {},
  paddedCell: {}
});

export const WorkRemainingHours = ({
  classes: classesOverride,
  hoursRemaining,
  dataQeId,
  align = 'right'
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ReadOnlyHours
      className={classes.dataCell}
      value={hoursRemaining}
      precision={2}
      dataQeId={dataQeId}
      align={align}
    />
  );
};

WorkRemainingHours.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  hoursRemaining: PropTypes.number,
  align: PropTypes.string
};

export default WorkRemainingHours;
