import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import flow from 'lodash/fp/flow';
import omit from 'lodash/fp/omit';
import update from 'lodash/fp/update';
import projectRatesFragment from '~/modules/projects/project/RateCard/enhancers/projectRates.fragment';
import { toRepliconDate } from '~/modules/common/dates/convert';

const omitForbiddenFields = flow(
  omit('__typename'),
  update('currency', flow(omit('__typename')))
);

export const PUT_PROJECT_RATES = gql`
  mutation putProjectRates($projectId: String!, $rates: [ProjectRateInput2!]) {
    putProjectRates: putProjectRates3(projectId: $projectId, rates: $rates) {
      project {
        id
        ...ProjectRates
      }
    }
  }
  ${projectRatesFragment}
`;

const mapToScheduleEntryInput = ({ effectiveDate, amount }) => ({
  effectiveDate: toRepliconDate(effectiveDate),
  amount: omitForbiddenFields(amount)
});

export const RATE_TYPE = {
  userRates: 'RESOURCE',
  projectRate: 'PROJECT',
  roleRates: 'ROLE'
};

const mapToProjectRatesInput = (rates, rateTypeKey) =>
  rates.map(r => ({
    rateType: RATE_TYPE[rateTypeKey],
    roleId: rateTypeKey === 'roleRates' ? r.roleReference.id : null,
    userId: rateTypeKey === 'userRates' ? r.userReference.id : null,
    billingScheduleEntries: (r.billingScheduleEntries || []).map(
      mapToScheduleEntryInput
    ),
    costScheduleEntries: (r.costScheduleEntries || []).map(
      mapToScheduleEntryInput
    )
  }));

export const usePutProjectRates = () => {
  const [putProjectRates] = useMutation(PUT_PROJECT_RATES);

  return {
    putProjectRates: async ({ projectId, rates, rateTypeKey }) =>
      putProjectRates({
        variables: {
          projectId,
          rates:
            rates && rates.length
              ? mapToProjectRatesInput(rates, rateTypeKey)
              : [
                  {
                    rateType: RATE_TYPE[rateTypeKey],
                    billingScheduleEntries: [],
                    costScheduleEntries: []
                  }
                ]
        },
        refetchQueries: ['getAllProjectRates', 'getEffectiveRates'],
        awaitRefetchQueries: true
      })
  };
};
