import { useMutation } from '@apollo/client';
import { CREATE_TASK_MUTATION } from '~/modules/projects/tasks/hooks/useCreateTask';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const useCreateTask = ({
  setMilestoneTaskCreated,
  setTaskAddedCount
}) => {
  const [createTask] = useMutation(CREATE_TASK_MUTATION);
  const isPsaPrpTaskCodeOnTaskCreationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpTaskCodeOnTaskCreationEnabled'
  });

  return {
    createTask: async ({
      parentUri,
      name,
      code,
      startDate,
      endDate,
      description,
      estimatedHours,
      estimatedCost,
      isMilestone,
      assignedUser,
      assignedRole,
      isTimeEntryAllowed,
      timeAndExpenseEntryType,
      assignedUserRoleId,
      costType,
      customFields
    }) => {
      const task = {
        parentUri,
        name,
        code: isPsaPrpTaskCodeOnTaskCreationEnabled ? code : undefined,
        startDate,
        endDate,
        description: isPsaPrpTaskCodeOnTaskCreationEnabled
          ? description
          : undefined,
        estimatedHours,
        estimatedCost,
        isMilestone,
        assignedUserUri: assignedUser ? assignedUser.id : null,
        assignedUserRoleId,
        assignedRoleUri: assignedRole ? assignedRole.id : null,
        isTimeEntryAllowed,
        timeAndExpenseEntryTypeUri:
          timeAndExpenseEntryType && timeAndExpenseEntryType.id,
        costTypeUri: costType ? costType.id : null,
        customFields
      };

      const { data } = await createTask({
        variables: {
          task
        },
        refetchQueries: ['projectTasksQuery', 'TaskDropdown'],
        awaitRefetchQueries: true
      });

      if (data) {
        setMilestoneTaskCreated(isMilestone);
        if (isMilestone) setTaskAddedCount(prevCount => prevCount + 1);
      }
    }
  };
};

export default useCreateTask;
