import { hasPermission } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me';

export default () => {
  const {
    groupSettings: {
      location: { isEnabled: isLoacationEnabled },
      division: { isEnabled: isDivisionEnabled },
      serviceCenter: { isEnabled: isServiceCenterEnabled },
      costCenter: { isEnabled: isCostCenterEnabled },
      employeeType: { isEnabled: isEmployeeTypeEnabled },
      department: { isEnabled: isDepartmentEnabled }
    },
    permissionsMap
  } = useMeContext();

  const canViewSkills =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-skill-assignments',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-skill-assignments',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewAvailabilityEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-availability',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-availability',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewRoleEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-project-role',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-project-role',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewLocationEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-location',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-location',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewDivisionEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-division',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-division',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewServiceCenterEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-service-center',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-service-center',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewCostCenterEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-cost-center',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-cost-center',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewDepartmentEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-department',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-department',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  const isViewEmployeeTypeEnabled =
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-employee-type',
      dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
    }) ||
    hasPermission(permissionsMap)({
      actionUri: 'urn:replicon:user-action:view-employee-type',
      dataAccessLevelUri:
        'urn:replicon:user-data-access-level:resource-pool-manager'
    });

  return {
    canViewSkills,
    isViewAvailabilityEnabled,
    isViewRoleEnabled,
    isViewCostCenterEnabled: isViewCostCenterEnabled && isCostCenterEnabled,
    isViewDivisionEnabled: isViewDivisionEnabled && isDivisionEnabled,
    isViewDepartmentEnabled: isViewDepartmentEnabled && isDepartmentEnabled,
    isViewEmployeeTypeEnabled:
      isViewEmployeeTypeEnabled && isEmployeeTypeEnabled,
    isViewLocationEnabled: isViewLocationEnabled && isLoacationEnabled,
    isViewServiceCenterEnabled:
      isViewServiceCenterEnabled && isServiceCenterEnabled
  };
};
