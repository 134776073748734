import { useFormik } from 'formik';
import { number, object, array } from 'yup';

const ESTIMATED_HOURS_MAX = 100000;

export const validationMessage = max =>
  `Exceeds max allowed value: ${max.toLocaleString('en')}`;

export const validationSchema = () =>
  object().shape({
    resourceAllocations: array().of(
      object().shape({
        allocatedHours: number()
          .max(ESTIMATED_HOURS_MAX, validationMessage(ESTIMATED_HOURS_MAX))
          .nullable()
      })
    )
  });

export const useFormState = ({ resourceAllocations = [] }) => {
  const initialValues = {
    resourceAllocations
  };

  return useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema()
  });
};

export default useFormState;
