import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SideCardSectionDetail } from '~/modules/common/components/SideCard';
import { projectType } from './BasicInfo.util';

const projectTypeLabel = (
  <FormattedMessage id="projectBasicInfoCard.projectType" />
);

export const ProjectType = ({ typeUri }) => {
  return (
    <SideCardSectionDetail
      label={projectTypeLabel}
      detail={projectType(typeUri)}
    />
  );
};

ProjectType.propTypes = {
  typeUri: PropTypes.string.isRequired
};

export default ProjectType;
