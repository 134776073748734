import { IconButton, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { DeleteOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { NoValue } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useRateEditHandlers } from '../../../hooks/useRateEditHandlers';
import ScheduleEntryRow from './ScheduleEntryRow';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  rateEntry: ({ rateTypeKey }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRight:
      rateTypeKey !== 'projectRate'
        ? `1px solid ${theme.palette.grey[300]}`
        : 'none',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      borderRight: 'none'
    }
  }),
  iconContainer: {
    alignSelf: 'center'
  },
  error: {
    marginTop: 0
  }
}));

export const RateEditor = ({
  rateIndex,
  scheduleEntries,
  rateTypeReference,
  rateTypeKey,
  rateTypeReferenceKey,
  errors,
  projectSlug,
  setFieldValue,
  hasMoreRows,
  loadMoreRows,
  context
}) => {
  const classes = useStyles({ rateTypeKey });
  const isMobile = useIsBreakpointDown('sm');
  const { formatMessage } = useIntl();

  const rateEditHandlers = useRateEditHandlers({
    setFieldValue,
    rateTypeKey,
    rateTypeReferenceKey,
    rateIndex,
    scheduleEntries
  });

  if (!scheduleEntries) return <NoValue />;

  return (
    <div className={classes.root}>
      <div className={classes.rateEntry}>
        {scheduleEntries.map((scheduleEntry, index) => (
          <ScheduleEntryRow
            key={scheduleEntry.entryId}
            entryIndex={index}
            rateIndex={rateIndex}
            isMobile={isMobile}
            rateTypeKey={rateTypeKey}
            rateTypeReference={rateTypeReference}
            rateTypeReferenceKey={rateTypeReferenceKey}
            scheduleEntry={scheduleEntry}
            onRemoveRateEntry={rateEditHandlers.onRemoveRateEntry}
            onAddEntry={rateEditHandlers.onAddNewRateEntry}
            errors={errors}
            setFieldValue={setFieldValue}
            projectSlug={projectSlug}
            context={context}
          ></ScheduleEntryRow>
        ))}
      </div>
      {Boolean(scheduleEntries && scheduleEntries.length) &&
        rateTypeKey !== 'projectRate' && (
          <div className={classes.iconContainer}>
            {!isMobile ? (
              <IconButton
                onClick={rateEditHandlers.onRemoveRate}
                data-qe-id="removeRateRow"
                aria-label={formatMessage({ id: 'rateTable.removeRateRow' })}
              >
                <DeleteOutline fontSize="small" />
              </IconButton>
            ) : (
              <Button
                color="secondary"
                onClick={rateEditHandlers.onRemoveRate}
                className={classes.removeAllButton}
              >
                <FormattedMessage id="rateCard.removeRate" />
              </Button>
            )}
          </div>
        )}
    </div>
  );
};

RateEditor.propTypes = {
  scheduleEntries: PropTypes.array,
  rateTypeKey: PropTypes.string,
  rateTypeReferenceKey: PropTypes.string,
  rateTypeReference: PropTypes.object,
  rateIndex: PropTypes.number,
  errors: PropTypes.object,
  projectSlug: PropTypes.string,
  setFieldValue: PropTypes.func,
  hasMoreRows: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  context: PropTypes.string
};

export default RateEditor;
