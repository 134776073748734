export const hasBillingSettingsEnabled = projectTemplateSetting =>
  projectTemplateSetting &&
  projectTemplateSetting?.some(
    e =>
      e?.key === 'urn:replicon:policy:project:billing' &&
      e?.value === 'urn:replicon:policy:project:billing:enabled'
  );

export const hasCostTypeSettingsEnabled = projectTemplateSetting =>
  projectTemplateSetting &&
  projectTemplateSetting?.some(
    e =>
      e?.key === 'urn:replicon:policy:project:cost-type' &&
      e?.value === 'urn:replicon:policy:project:cost-type:enabled'
  );

export const hasPortfolioSettingsEnabled = projectTemplateSetting =>
  projectTemplateSetting &&
  projectTemplateSetting?.some(
    e =>
      e?.key === 'urn:replicon:policy:project:portfolio' &&
      e?.value === 'urn:replicon:policy:project:portfolio:enabled'
  );

export const useProjectTemplateSettings = ({
  projectTemplateSetting,
  isPsaPrpPsaPpmMergerEnabled
}) => {
  if (isPsaPrpPsaPpmMergerEnabled) {
    return {
      hasBilling: hasBillingSettingsEnabled(projectTemplateSetting),
      hasCostType: hasCostTypeSettingsEnabled(projectTemplateSetting),
      hasPortfolio: hasPortfolioSettingsEnabled(projectTemplateSetting)
    };
  }

  return {};
};
export default useProjectTemplateSettings;
