import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { TimelineContent } from '@material-ui/lab';
import { RemoveCircleSharp } from '@material-ui/icons';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import RoleScheduleEditablePrimaryFormRow from './RolesEditableScheduleForm/RoleScheduleEditablePrimaryFormRow';
import RoleScheduleReadOnlyFormPrimaryRow from './RolesReadOnlyScheduleForm/RoleScheduleReadOnlyFormPrimaryRow';
import RoleScheduleEditableAdditionalFormRow from './RolesEditableScheduleForm/RoleScheduleEditableAdditionalFormRow';
import RoleScheduleReadOnlyFormAdditionalRow from './RolesReadOnlyScheduleForm/RoleScheduleReadOnlyFormAdditionalRow';
import TimelineCalendarContent from './TimelineCalendarContent';

const useStyles = makeStyles(theme => ({
  scheduleRows: {
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    display: 'flex'
  },
  removeButton: {
    marginRight: -theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
}));

export const RoleScheduleFormRows = ({
  scheduleIndex,
  classes: classesOverride,
  form: {
    values: { schedule: schedules },
    setValues
  },
  removeSchedule,
  form,
  fieldArrayName,
  permittedActions,
  editable
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles(classesOverride);

  const schedule = schedules[scheduleIndex];

  const isMobile = useIsBreakpointDown('xs');

  const PrimaryRow = editable
    ? RoleScheduleEditablePrimaryFormRow
    : RoleScheduleReadOnlyFormPrimaryRow;
  const AdditionalRow = editable
    ? RoleScheduleEditableAdditionalFormRow
    : RoleScheduleReadOnlyFormAdditionalRow;

  return (
    <TimelineContent>
      <FieldArray name={`schedule[${scheduleIndex}].projectRoles`}>
        {roleArrayHelpers => {
          const primaryRole = schedule.projectRoles.find(
            item => item.isPrimary
          );
          const additionalRoles = schedule.projectRoles.filter(
            item => !item.isPrimary
          );

          return (
            <div>
              {isMobile && (
                <TimelineCalendarContent
                  schedules={schedules}
                  setValues={setValues}
                  scheduleIndex={scheduleIndex}
                  editable
                />
              )}
              <div className={classes.scheduleRows}>
                <Grid container spacing={2} key={scheduleIndex}>
                  <PrimaryRow
                    dataQeId="PrimaryRoleDropdown"
                    roleIndex={0}
                    scheduleIndex={scheduleIndex}
                    fieldArrayName={fieldArrayName}
                    item={primaryRole}
                    form={form}
                    dropdownFieldKey="role"
                    permittedActions={permittedActions}
                    label={formatMessage({ id: 'rolesForm.primaryRole' })}
                    editable
                  />
                  <AdditionalRow
                    label={formatMessage({
                      id: 'rolesForm.additionalRole'
                    })}
                    dataQeId="AdditionalRoleDropdown"
                    additionalRoles={additionalRoles}
                    form={form}
                    scheduleIndex={scheduleIndex}
                    dropdownFieldKey="role"
                    permittedActions={permittedActions}
                    editable
                  />
                </Grid>
                {editable && (
                  <div className={classes.removeButton}>
                    {scheduleIndex > 0 && (
                      <IconButton
                        onClick={() => removeSchedule(scheduleIndex)}
                        aria-label={formatMessage({ id: 'rateTable.remove' })}
                      >
                        <RemoveCircleSharp fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </FieldArray>
    </TimelineContent>
  );
};

RoleScheduleFormRows.propTypes = {
  classes: PropTypes.object,
  fieldArrayName: PropTypes.string,
  permittedActions: PropTypes.array.isRequired,
  scheduleIndex: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  removeSchedule: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired
};

export default RoleScheduleFormRows;
