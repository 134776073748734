import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  InputLabel,
  FormControl,
  IconButton,
  FilledInput,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import { CloseSharp as CloseIcon } from '@material-ui/icons';
import { useDialogState } from '~/modules/common/hooks';
import { FILE_SOURCE_URI, LINK_SOURCE_URI } from '../fileSourceUris';
import FileWebAddressDialog from './FileWebAddressDialog';
import FileFieldText from './FileFieldText';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const resolveUnboundFieldSourceUris = value =>
  value ? (value.keyValues ? [FILE_SOURCE_URI] : [LINK_SOURCE_URI]) : [];

const useResolveEnabledSourceUris = (fileDetails, value) =>
  useMemo(() => {
    const isBoundedField = Boolean(fileDetails);

    return isBoundedField
      ? fileDetails.enabledSourceUris
      : resolveUnboundFieldSourceUris(value);
  }, [fileDetails, value]);

export const FileField = ({
  editable,
  fullWidth,
  definition,
  value,
  onChange
}) => {
  const classes = useStyles();
  const { displayText: label, fileDetails } = definition;

  const enabledSourceUris = useResolveEnabledSourceUris(fileDetails, value);

  const {
    open: dialogOpen,
    openDialog,
    closeDialog: onDialogCancel
  } = useDialogState(false);

  const onDialogOK = useCallback(
    newValue => {
      onDialogCancel();
      onChange(null, Array.isArray(newValue) ? newValue[0] : newValue);
    },
    [onDialogCancel, onChange]
  );

  const handleClearClick = useCallback(
    event => {
      event.stopPropagation();

      onChange(null, null);
    },
    [onChange]
  );

  const linkName = useMemo(() => {
    if (!value) return '';

    const fileKeyValue = value.keyValues
      ? value.keyValues.find(
          kv =>
            kv.keyUri === 'urn:replicon:binary-object-keyvalue-key:file-name'
        )
      : null;

    return fileKeyValue ? fileKeyValue.value.text : value.linkUri;
  }, [value]);

  return (
    <>
      <FormControl
        disabled={!editable}
        fullWidth={fullWidth}
        {...(editable && {
          variant: 'filled'
        })}
      >
        <InputLabel shrink={Boolean(!editable || linkName)}>{label}</InputLabel>
        {editable ? (
          <FilledInput
            className={classes.button}
            type="button"
            fullWidth={fullWidth}
            onClick={openDialog}
            value={linkName}
            endAdornment={
              linkName && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClearClick}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        ) : (
          <FileFieldText value={value} />
        )}
      </FormControl>
      <FileWebAddressDialog
        open={dialogOpen}
        onCancel={onDialogCancel}
        onOK={onDialogOK}
        enabledSourceUris={enabledSourceUris}
        initialValue={value}
      />
    </>
  );
};

FileField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  editable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  definition: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default FileField;
