import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { omitForbiddenCreateFields } from '~/modules/resourcing/common/util/resourceAllocationUtil';
import {
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment,
  specificResourceAllocationTimeOffFragment,
  specificResourceAllocationHolidaysFragment,
  allocationTotalsFragment
} from '~/modules/resourcing/common/fragments';

export const CREATE_RESOURCE_ALLOCATION_WITHOUT_RESOURCE_REQUEST = gql`
  mutation CreateFulfilledResourceAllocationWithoutResourceRequest(
    $input: CreateFulfilledResourceAllocationWithoutResourceRequestInput!
    $showTimeOff: Boolean!
    $chartDateRange: DateRangeInput
    $showHolidays: Boolean!
  ) {
    createFulfilledResourceAllocationWithoutResourceRequest(input: $input) {
      resourceAllocation {
        ...SpecificResourceAllocation
        project {
          id
          displayText
          startDate
          endDate
          permittedActionUris
          defaultScheduleRule {
            dateRange {
              startDate
              endDate
            }
            do
          }
        }
        role {
          uri
          id
          displayText
        }
        user {
          ...SpecificResourceAllocationUser
          ...SpecificResourceAllocationTimeOff
          ...SpecificResourceAllocationHolidays
          scheduleDurationByDay(dateRange: $chartDateRange) {
            date
            hours
          }
        }
        ...AllocationTotalsFragment
      }
    }
  }
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
  ${specificResourceAllocationTimeOffFragment}
  ${specificResourceAllocationHolidaysFragment}
  ${allocationTotalsFragment}
`;

const useFulfilledResourceAllocationHandlersWithoutResourceRequest = ({
  updateCreateCache,
  chartDateRange,
  showTimeOff,
  showHolidays
}) => {
  const [
    createFulfilledResourceAllocationWithoutResourceRequest,
    { data, loading }
  ] = useMutation(CREATE_RESOURCE_ALLOCATION_WITHOUT_RESOURCE_REQUEST, {
    refetchQueries: ['getProjectTotalsBySlug']
  });

  const onCreateResourceAllocation = useCallback(
    ({ allocation }) =>
      createFulfilledResourceAllocationWithoutResourceRequest({
        variables: {
          input: omitForbiddenCreateFields(allocation),
          chartDateRange,
          showTimeOff: Boolean(showTimeOff),
          showHolidays: Boolean(showHolidays)
        },
        update: updateCreateCache
      }),
    [
      createFulfilledResourceAllocationWithoutResourceRequest,
      chartDateRange,
      showTimeOff,
      showHolidays,
      updateCreateCache
    ]
  );

  return {
    onCreateResourceAllocation,
    loading,
    data
  };
};

export default useFulfilledResourceAllocationHandlersWithoutResourceRequest;
