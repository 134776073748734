import { Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import {
  EstimatedBillingGroup,
  BillingOverviewHeader,
  AvailableToBillGroup,
  BillingActualsGroup
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    borderCollapse: 'separate',
    width: 'unset',
    alignSelf: 'flex-start',
    minWidth: '100%'
  }
}));

export const BillingOverviewTable = ({
  billingOverview,
  displayTotalColumn,
  handleCallToAction
}) => {
  const classes = useStyles();

  const billingTransactionActuals =
    billingOverview.billingTransactionActuals || {};

  return (
    <Table className={classes.root} role="table">
      <BillingOverviewHeader displayTotalColumn={displayTotalColumn} />
      <TableBody role="rowgroup">
        <EstimatedBillingGroup
          estimatedBilling={billingOverview.estimatedBilling}
          displayTotalColumn={displayTotalColumn}
        />
        <AvailableToBillGroup
          availableToBill={billingOverview.availableToBill}
          displayTotalColumn={displayTotalColumn}
        />
        <BillingActualsGroup
          sectionData={billingTransactionActuals}
          availableToBill={billingOverview.availableToBill}
          displayTotalColumn={displayTotalColumn}
          handleCallToAction={handleCallToAction}
        />
      </TableBody>
    </Table>
  );
};

BillingOverviewTable.propTypes = {
  billingOverview: PropTypes.object,
  displayTotalColumn: PropTypes.bool,
  handleCallToAction: PropTypes.func
};

export default BillingOverviewTable;
