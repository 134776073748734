import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useMeContext } from '~/modules/me';
import { useSessionStorage } from '~/modules/common/hooks';
import { updateCachedProjectResourceRequests } from '~/modules/projects/resourcing-plan/hooks/useProjectResourceRequests';
import { getResourceRequest } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestDrawer/hooks/useResourceRequestDrawerForm';
import { SUBMIT_RESOURCE_REQUEST } from '~/modules/resourcing/common/enhancers/requestStatusHandlers/withMarkResourceRequestAsSubmitted';
import { CREATE_RESOURCE_REQUEST } from '~/modules/resourcing/common/enhancers/withDuplicateResourceRequestHandler';
import {
  omitForbiddenFields,
  buildResourceRequest
} from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';

export const _createResourceRequest = async ({
  createResourceRequest,
  form,
  me,
  scale,
  project
}) => {
  const request = buildResourceRequest({
    me,
    project,
    scale,
    resourceRequestDetails: getResourceRequest({
      form,
      defaultScheduleRule: project.defaultScheduleRule
    })
  });

  const {
    data: {
      createResourceRequest2: { resourceRequest: newRequest }
    }
  } = await createResourceRequest({
    variables: {
      input: omitForbiddenFields(request)
    }
  });

  return newRequest;
};

export const useOnSubmit = ({
  client,
  createResourceRequest,
  form,
  markResourceRequestAsSubmitted,
  me,
  onClose,
  scale,
  project,
  setResourceRequestSaving,
  isResourceActualModeEnabled
}) => {
  const { storedValue: variables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const { filter } = useResourceRequestToolbarContext();

  return useCallback(async () => {
    setResourceRequestSaving(true);

    const newRequest = await _createResourceRequest({
      createResourceRequest,
      form,
      scale,
      me,
      project
    });

    await markResourceRequestAsSubmitted({
      variables: {
        input: {
          resourceRequestId: newRequest.id
        }
      }
    });

    updateCachedProjectResourceRequests({
      newRequest: {
        ...newRequest,
        requestStatus: ResourceRequestStatus.Submitted
      },
      variables,
      filter,
      isResourceActualModeEnabled
    })(client);

    onClose();

    return {
      requestId: newRequest.id,
      projectId: project.id
    };
  }, [
    setResourceRequestSaving,
    createResourceRequest,
    form,
    scale,
    me,
    project,
    markResourceRequestAsSubmitted,
    variables,
    filter,
    client,
    onClose,
    isResourceActualModeEnabled
  ]);
};

export const useOnSave = ({
  client,
  createResourceRequest,
  form,
  me,
  onClose,
  project,
  scale,
  setResourceRequestSaving,
  isResourceActualModeEnabled
}) => {
  const { storedValue: variables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const { filter } = useResourceRequestToolbarContext();

  return useCallback(async () => {
    setResourceRequestSaving(true);

    const newRequest = await _createResourceRequest({
      createResourceRequest,
      form,
      me,
      scale,
      project
    });

    updateCachedProjectResourceRequests({
      newRequest,
      variables,
      filter,
      isResourceActualModeEnabled
    })(client);

    onClose();
  }, [
    setResourceRequestSaving,
    createResourceRequest,
    form,
    me,
    scale,
    project,
    variables,
    filter,
    client,
    onClose,
    isResourceActualModeEnabled
  ]);
};

const useResourceRequestActions = ({
  form,
  project,
  onClose,
  setResourceRequestSaving
}) => {
  const me = useMeContext();
  const [createResourceRequest] = useMutation(CREATE_RESOURCE_REQUEST);
  const [markResourceRequestAsSubmitted] = useMutation(SUBMIT_RESOURCE_REQUEST);
  const client = useApolloClient();
  const {
    scale,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();

  return {
    onSubmit: useOnSubmit({
      client,
      createResourceRequest,
      scale,
      form,
      markResourceRequestAsSubmitted,
      me,
      onClose,
      project,
      setResourceRequestSaving,
      isResourceActualModeEnabled
    }),
    onSave: useOnSave({
      client,
      createResourceRequest,
      form,
      scale,
      me,
      onClose,
      project,
      setResourceRequestSaving,
      isResourceActualModeEnabled
    })
  };
};

export default useResourceRequestActions;
