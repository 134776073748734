import React from 'react';
import PropTypes from 'prop-types';
import { NoValue } from '~/modules/common/components';

export const StringOrNoValue = ({ field, record }) => {
  return record[field] ? record[field] : <NoValue />;
};

StringOrNoValue.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default StringOrNoValue;
