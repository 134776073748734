import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  code: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 'inherit',
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}));

const variants = {
  body1: 'body1',
  h5: 'h5'
};

const FormattedNameCode = ({ name, code, variant }) => {
  const classes = useStyles();

  return (
    <>
      {name}
      {code && (
        <Typography
          component="span"
          variant={variants[variant] || variants.h5}
          className={classes.code}
        >{` - ${code}`}</Typography>
      )}
    </>
  );
};

FormattedNameCode.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string,
  variant: PropTypes.oneOf([variants.body1, variants.h5])
};

export default FormattedNameCode;
