import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useIsBreakpointUp = breakpoint => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.up(breakpoint), { noSsr: true });
};

export const useIsBreakpointDown = breakpoint => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(breakpoint), { noSsr: true });
};

export const useIsBreakpointOnly = breakpoint =>
  useMediaQuery(theme => theme.breakpoints.only(breakpoint), { noSsr: true });
