/* eslint-disable max-depth */
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import { useApolloClient } from '@apollo/client';
import { addLocalSkillToCache } from '~/modules/common/hooks/useEnabledSkills';

export const skillSettingTypeUris = {
  date: 'urn:replicon:skill-setting-type:date',
  text: 'urn:replicon:skill-setting-type:text',
  number: 'urn:replicon:skill-setting-type:numeric',
  dropDown: 'urn:replicon:skill-setting-type:drop-down'
};

export const useFormOnChangeHandlers = ({
  values: { skills, categories },
  skillLevels,
  setFieldValue
}) => {
  const client = useApolloClient();
  const mapCustomMetadata = (skillItem, setting, e) => {
    skillItem.customMetadata.push({
      uri: setting.uri,
      type: setting.type,
      keyUri: setting.uri,
      text:
        setting.type === skillSettingTypeUris.text ||
        setting.type === skillSettingTypeUris.dropDown
          ? e.target.value
          : '',
      date: setting.type === skillSettingTypeUris.date ? e : null,
      displayText: setting.displayText,
      number: setting.type === skillSettingTypeUris.number ? e.target.value : 0
    });
  };

  return {
    onSkillsSelected: selectedSkills => {
      const uniqueCategories = sortedUniqBy(
        sortBy(selectedSkills, skillItem => skillItem.category.displayText),
        skillItem => skillItem.category.id
      ).map(option => option.category);

      setFieldValue(
        'skills',
        sortBy(selectedSkills, skillItem => skillItem.displayText)
      );
      setFieldValue('categories', uniqueCategories);
    },
    onSkillRemove: skill => {
      const filteredSkills = skills.filter(item => item.id !== skill.id);

      const filteredCategories = sortedUniqBy(
        sortBy(filteredSkills, skillItem => skillItem.category.displayText),
        skillItem => skillItem.category.id
      ).map(option => option.category);

      setFieldValue('skills', filteredSkills);
      setFieldValue('categories', filteredCategories);
    },
    onCategoryRemove: category => {
      const filteredcategories = categories.filter(
        item => item.id !== category.id
      );

      const filteredSkills = skills.filter(
        skillItem => skillItem.category.id !== category.id
      );

      setFieldValue('skills', filteredSkills);
      setFieldValue('categories', filteredcategories);
    },
    onSkillAdd: skill => {
      const { category, description } = skill;

      const isCategoryDisplayed = Boolean(
        categories.find(c => c.id === category.id)
      );

      if (!isCategoryDisplayed) {
        setFieldValue(
          'categories',
          sortBy([...categories, category], c => c.displayText)
        );
      }

      setFieldValue('skills', [
        ...skills,
        {
          ...skill,
          description,
          customMetadata: [],
          skillLevel: skillLevels[0]
        }
      ]);

      addLocalSkillToCache({ client, skill });
    },
    onSkillRatingUpdate: ({ ratingIndex, skillAssignment }) => {
      const selectedSkillLevel = skillLevels.find(
        level => level.rating === ratingIndex
      );
      const filteredSkills = skills.map(skillItem => ({
        ...skillItem,
        skillLevel:
          skillItem.id === skillAssignment.id
            ? selectedSkillLevel
            : skillItem.skillLevel
      }));

      setFieldValue('skills', filteredSkills);
    },
    onCustomMetadataUpdate: (event, skill, setting) => {
      const filteredSkills = skills.map(skillItem => {
        let skillMetadataToUpdate = {};

        if (skillItem.id === skill.id) {
          if (skill.customMetadata.length) {
            const skillMetadata = skillItem.customMetadata.find(
              x => x.uri === setting.uri
            );

            if (skillMetadata) {
              if (setting.type === skillSettingTypeUris.date)
                skillMetadataToUpdate = {
                  ...skillMetadata,
                  date: event
                };
              if (
                setting.type === skillSettingTypeUris.text ||
                setting.type === skillSettingTypeUris.dropDown
              ) {
                skillMetadataToUpdate = {
                  ...skillMetadata,
                  text: event.target.value
                };
              }
              if (setting.type === skillSettingTypeUris.number)
                skillMetadataToUpdate = {
                  ...skillMetadata,
                  number: event.target.value ? event.target.value : 0
                };
            } else {
              mapCustomMetadata(skillItem, setting, event);
            }
          } else {
            mapCustomMetadata(skillItem, setting, event);
          }
        }

        return {
          ...skillItem,
          customMetadata: skillItem.customMetadata.map(data =>
            skillMetadataToUpdate && data.uri === skillMetadataToUpdate.uri
              ? { ...data, ...skillMetadataToUpdate }
              : data
          )
        };
      });

      setFieldValue('skills', filteredSkills);
    },
    onCertificateUpdate: (certificateUri, skill) => {
      const filteredSkills = skills.map(skillItem => ({
        ...skillItem,
        certificate:
          skillItem.id === skill.id ? certificateUri : skillItem.certificate
      }));

      setFieldValue('skills', filteredSkills);
    }
  };
};

export default useFormOnChangeHandlers;
