import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DialogActions, DialogContent, Button } from '@material-ui/core';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { isValid } from '~/modules/projects/project/validator';
import CopyProjectForm from './CopyProjectForm';

const closeButton = <FormattedMessage id="duplicateProject.close" />;
const copyButton = <FormattedMessage id="duplicateProject.title" />;

export const CopyProjectDialogContent = ({
  values,
  errors,
  onClose,
  screenFlow,
  handleSubmit,
  isSubmitting,
  handleChange,
  setFieldValue,
  projectTemplateSetting,
  ...rest
}) => {
  const { project } = values;

  const hasErrors =
    Object.keys(errors).filter(e => e !== 'unhandled').length > 0;

  return (
    <>
      <DialogContent>
        <CopyProjectForm
          values={values}
          errors={errors}
          screenFlow={screenFlow}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          projectTemplateSetting={projectTemplateSetting}
          {...rest}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          {closeButton}
        </Button>
        <LoadingButton
          data-qe-id="CopyProject"
          onClick={handleSubmit}
          color="primary"
          disabled={!isValid(project) || hasErrors || isSubmitting}
          isLoading={isSubmitting}
          type="submit"
        >
          {copyButton}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

CopyProjectDialogContent.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  onClose: PropTypes.func,
  screenFlow: PropTypes.string,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  projectPermissions: PropTypes.object,
  projectTemplateSetting: PropTypes.object
};

export default CopyProjectDialogContent;
