import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ProjectIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1)
  }
}));

export const ProjectSuggestionItem = ({ option: { label } }) => {
  const classes = useStyles();

  return (
    <>
      <ProjectIcon />
      <Typography
        variant="inherit"
        classes={{ root: classes.root }}
        color="inherit"
      >
        {label}
      </Typography>
    </>
  );
};

ProjectSuggestionItem.propTypes = {
  option: PropTypes.object
};

export default ProjectSuggestionItem;
