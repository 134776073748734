import ProjectSuggestionItem from '../GenericProjectFacetDetails/ProjectSuggestionItem';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { projectTag } from './tag';
import ResourceProjectFacetDetails from './ResourceProjectFacetDetails';
import { useSearchableProjectOptions } from './useSearchableProjectOptions';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableProjectOptions);

export default {
  tag: projectTag,
  makeCriteriaProvider,
  DetailsControl: ResourceProjectFacetDetails,
  SuggestionItem: ProjectSuggestionItem
};
