import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const sum = creditCurrencyUri => (retVal, item) =>
  item &&
  item.amount &&
  item.amount.currency &&
  item.amount.currency.id === creditCurrencyUri
    ? retVal + item.amount.amount
    : retVal;

export const useCreditMemoTotalProps = ({ values }) => {
  const { currency, lineItems = [], associatedBills = [] } = values;
  const { id: creditCurrencyUri } = currency;

  const lineItemsTotal = getRoundedValue(
    lineItems.reduce(sum(creditCurrencyUri), 0)
  );
  const associatedBillsTotal = getRoundedValue(
    associatedBills.reduce(sum(creditCurrencyUri), 0)
  );

  return {
    lineItemsTotal: {
      amount: lineItemsTotal,
      currency
    },
    associatedBillsTotal: {
      amount: associatedBillsTotal,
      currency
    },
    creditMemoBalanceTotal: {
      amount: getRoundedValue(lineItemsTotal - associatedBillsTotal),
      currency
    }
  };
};

export default useCreditMemoTotalProps;
