export const findAndRemoveAllocationIdFromUserRole = ({
  existingUserCacheEntry,
  newAllocationId
}) => {
  const oldUserData = {
    ...existingUserCacheEntry,
    roles: existingUserCacheEntry.roles
      .map(role => {
        const filteredResourceAllocationReferences = role.resourceAllocationReference.filter(
          allocRef => allocRef.id !== newAllocationId
        );

        return (
          filteredResourceAllocationReferences.length && {
            ...role,
            resourceAllocationReference: filteredResourceAllocationReferences
          }
        );
      })
      .filter(role => role)
  };

  return oldUserData;
};

const createResourceAllocationReferenceEntry = allocation => ({
  __typename: 'ResourceAllocationReference',
  id: allocation.id,
  resourceRequestIds: allocation.resourceRequestId
    ? [allocation.resourceRequestId]
    : []
});

export const addOrReplaceUserRoleCacheEntry = ({
  userCacheEntry,
  roleIndex,
  newAllocation
}) => {
  const userHasRole = roleIndex > -1;
  const updatedRoles = [...userCacheEntry.roles];

  if (userHasRole) {
    const role = updatedRoles[roleIndex];

    updatedRoles[roleIndex] = {
      ...role,
      resourceAllocationReference: [
        ...role.resourceAllocationReference.filter(
          allocation => allocation.id !== newAllocation.id
        ),
        createResourceAllocationReferenceEntry(newAllocation)
      ]
    };
  } else {
    updatedRoles.push({
      role: newAllocation.role
        ? {
            displayText: newAllocation.role.displayText,
            id: newAllocation.role.uri
          }
        : null,
      resourceAllocationReference: [
        createResourceAllocationReferenceEntry(newAllocation)
      ]
    });
  }

  return updatedRoles;
};

export const getUpdatedAllocatedUserList = ({
  oldUser,
  newUser,
  newAllocation,
  cachedAllocatedUsers
}) => {
  const oldUserIndex = oldUser
    ? cachedAllocatedUsers.findIndex(d => d.user.id === oldUser.user.uri)
    : -1;
  const newUserIndex = newUser
    ? cachedAllocatedUsers.findIndex(d => d.user.id === newUser.userUri)
    : -1;
  const updatedCachedAllocatedUsers = [...cachedAllocatedUsers];

  const isOldUserVisible = oldUserIndex > -1;

  if (isOldUserVisible) {
    const updatedOldUserData = findAndRemoveAllocationIdFromUserRole({
      existingUserCacheEntry: cachedAllocatedUsers[oldUserIndex],
      newAllocationId: newAllocation.id
    });

    if (updatedOldUserData.roles.length > 0) {
      updatedCachedAllocatedUsers.splice(oldUserIndex, 1, updatedOldUserData);
    } else {
      updatedCachedAllocatedUsers.splice(oldUserIndex, 1);
    }
  }

  const isNewUserVisible = newUserIndex > -1;

  if (isNewUserVisible) {
    const roleIndex = cachedAllocatedUsers[newUserIndex].roles.findIndex(
      role => role.role?.id === newAllocation.role?.uri
    );
    const spliceIndex = updatedCachedAllocatedUsers.findIndex(
      d => d.user.id === newUser.userUri
    );

    updatedCachedAllocatedUsers.splice(spliceIndex, 1, {
      ...cachedAllocatedUsers[newUserIndex],
      roles: addOrReplaceUserRoleCacheEntry({
        userCacheEntry: cachedAllocatedUsers[newUserIndex],
        roleIndex,
        newAllocation
      })
    });
  } else {
    const { user } = newUser;
    const { id, role, resourceRequestId, projectUri } = newAllocation;

    updatedCachedAllocatedUsers.splice(updatedCachedAllocatedUsers.length, 0, {
      __typename: 'ResourceAllocationUserRoleReference',
      id: `${projectUri}_${user.uri}`,
      user: {
        __typename: 'UserReference',
        id: user.uri,
        uri: user.uri,
        displayText: user.displayText
      },
      roles: [
        {
          __typename: 'ProjectRoleWithAllocations',
          role: role
            ? {
                displayText: role.displayText,
                id: role.uri
              }
            : null,
          resourceAllocationReference: [
            {
              __typename: 'ResourceAllocationReference',
              id,
              resourceRequestIds: resourceRequestId ? [resourceRequestId] : []
            }
          ]
        }
      ]
    });
  }

  return updatedCachedAllocatedUsers;
};

export const getUpdatedAllocatedUserListFromAllocations = ({
  newAllocations,
  cachedAllocatedUsers
}) =>
  newAllocations.reduce(
    (retVal, newAllocation) =>
      getUpdatedAllocatedUserList({
        newUser: newAllocation.user,
        newAllocation,
        cachedAllocatedUsers: retVal
      }),
    cachedAllocatedUsers
  );
