import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import { useIsBreakpointDown } from '~/modules/common/hooks';
import LoadingButton from '~/modules/common/components/LoadingButton';
import usePutTableSettings from '../hooks/usePutTableSettings';
import ListTablePropTypes from '../PropTypes';
import useSelectedColumns from './hooks/useSelectedColumns';
import ColumnSettingsDialogContent from './ColumnSettingsDialogContent';
import useOnColumnSettingsSave from './hooks/useOnColumnSettingsSave';

const useStyles = makeStyles(() => ({
  actionsSpacer: {
    flexGrow: 1,
    flexShrink: 1
  }
}));

const columnDialogRole = {
  role: 'presentation'
};

const ColumnSettingsDialog = props => {
  const {
    open,
    onClose,
    groups,
    selectedColumns,
    defaultColumns,
    tableKey,
    settingsColumnJustify,
    sort,
    tagsObjectBinding,
    ...otherProps
  } = props;

  const {
    selectedColumns: selectedColumnsState,
    toggleColumn,
    resetSelection,
    resetToDefault,
    onRemoveTag,
    onTagKeySelection
  } = useSelectedColumns({ initialState: selectedColumns, defaultColumns });

  const classes = useStyles(props);
  const fullScreen = useIsBreakpointDown('sm');

  const [isSaving, setIsSaving] = useState(false);
  const [putTableSettings] = usePutTableSettings();

  const onSave = useOnColumnSettingsSave({
    putTableSettings,
    setIsSaving,
    onClose,
    selectedColumns: selectedColumnsState,
    sort,
    tableKey
  });

  const onCancel = useCallback(() => {
    resetSelection();
    onClose();
  }, [onClose, resetSelection]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      disableBackdropClick
      TransitionProps={columnDialogRole}
      disableEscapeKeyDown
      aria-labelledby="project-grid-settings-dialog-title"
      aria-describedby="project-grid-settings-dialog-description"
      {...otherProps}
      role="main"
    >
      <DialogTitle id="project-grid-settings-dialog-title">
        <FormattedMessage id="columnSettings.tableSettings" />
      </DialogTitle>
      <DialogContent>
        <ColumnSettingsDialogContent
          groups={groups}
          selectedColumns={selectedColumnsState}
          onColumnClick={toggleColumn}
          settingsColumnJustify={settingsColumnJustify}
          onRemoveTag={onRemoveTag}
          onTagKeySelection={onTagKeySelection}
          tagsObjectBinding={tagsObjectBinding}
        />
      </DialogContent>
      <DialogActions>
        {defaultColumns && (
          <Button onClick={resetToDefault}>
            <FormattedMessage id="button.resetToDefault" />
          </Button>
        )}
        <span className={classes.actionsSpacer} />
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          isLoading={isSaving}
          onClick={onSave}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ColumnSettingsDialog.propTypes = {
  tableKey: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  sort: ListTablePropTypes.sort,
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
  settingsColumnJustify: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  tagsObjectBinding: PropTypes.string
};

export default ColumnSettingsDialog;
