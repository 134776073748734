import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { useTableSettings } from '~/modules/common/components/ListTable';

const TABLE_KEY = 'program-projects-list';

const useProgramProjectListTableSettings = () => {
  const {
    enabledProjectColumnNames,
    enabledProjectColumnGroups
  } = useEnabledProjectColumns({
    hideProgram: true
  });

  const { data: tableSettingsData, loading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: enabledProjectColumnNames
  });
  const selectedColumnNames = tableSettingsData?.columns;

  return {
    tableKey: TABLE_KEY,
    enabledProjectColumnGroups,
    enabledProjectColumnNames,
    selectedColumnNames,
    tableSettingsDataLoading: loading
  };
};

export default useProgramProjectListTableSettings;
