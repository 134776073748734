import { gql } from 'graphql-tag';

export const CARRY_OVER_SUMMARY_QUERY = gql`
  query getRecognizableRevenueCarryOverSummary($revenueEntryId: String!) {
    recognizableRevenueCarryOverSummary(revenueEntryId: $revenueEntryId) {
      revenuePeriod {
        endDate: endDate2
      }
      amount {
        amount
        currency {
          id
          symbol
        }
      }
      type
    }
  }
`;
