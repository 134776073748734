import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { gantt } from '@replicon/dhtmlx-gantt';
import {
  GANTT_SCALE_DAY,
  GANTT_SCALE_WEEK,
  GANTT_SCALE_MONTH,
  GANTT_SCALE_YEAR,
  GANTT_SCALE_QUARTER
} from '../../../hooks/useGanttScale';

import {
  GanttTaskName,
  GanttAddButton,
  GanttColumn,
  TaskNameTemplate,
  AddTaskTemplate
} from '../components';

export const customTooltipKeys = {
  taskOwner: 'gantt_task_owner_toolTip',
  taskAssignedRole: 'gantt_task_role_toolTip'
};

export const getGanttScaleHeight = ({ ganttScale }) => {
  switch (ganttScale) {
    case GANTT_SCALE_DAY:
      return 27;
    default:
      return 50;
  }
};

export const weekScaleTemplate = date => {
  const dateToStr = gantt.date.date_to_str('%d');
  const dateToStrMonth = gantt.date.date_to_str('%M');

  const startDate = gantt.date.week_start(new Date(date));
  const endDate = gantt.date.add(
    gantt.date.add(startDate, 1, 'week'),
    -1,
    'day'
  );
  const startMonth = dateToStrMonth(startDate);
  const endMonth = dateToStrMonth(endDate);

  if (startMonth !== endMonth) {
    return `${startMonth} ${dateToStr(startDate)} - ${endMonth} ${dateToStr(
      endDate
    )}`;
  }

  return `${startMonth} ${dateToStr(startDate)} - ${dateToStr(endDate)}`;
};

export const quarterScaleTemplate = date => {
  const dateToStr = gantt.date.date_to_str('%m');
  const dateToStrYear = gantt.date.date_to_str('%Y');

  return `Q${Math.floor(dateToStr(date) / 3) + 1} ${dateToStrYear(date)}`;
};

export const getGanttScaleConfiguration = ({ ganttScale }) => {
  switch (ganttScale) {
    case GANTT_SCALE_DAY:
      return [
        {
          unit: GANTT_SCALE_DAY,
          step: 1,
          format: '%d %M'
        }
      ];
    case GANTT_SCALE_WEEK:
      return [
        {
          unit: GANTT_SCALE_WEEK,
          step: 1,
          format: weekScaleTemplate
        },
        {
          unit: GANTT_SCALE_DAY,
          step: 1,
          format: '%d %M'
        }
      ];
    case GANTT_SCALE_YEAR:
      return [
        {
          unit: GANTT_SCALE_YEAR,
          step: 1,
          format: '%Y'
        }
      ];
    case GANTT_SCALE_QUARTER:
      return [
        {
          unit: GANTT_SCALE_QUARTER,
          step: 1,
          format: quarterScaleTemplate
        }
      ];
    case GANTT_SCALE_MONTH:
    default:
      return [
        {
          unit: GANTT_SCALE_MONTH,
          format: '%M %Y'
        }
      ];
  }
};

export const getTaskNameTemplate = ({
  me,
  classes,
  showAddButton,
  intl
}) => task => {
  const openingHtml = `<div class="${classes.ganttTaskNameContainer}">`;

  const taskNameHtml = renderToString(
    <GanttTaskName
      task={task}
      className={
        me.isRolledUpTaskEstimateCalculationMethodEnabled &&
        gantt.hasChild(task.id)
          ? task.parent
            ? classes.ganttTaskNameBold
            : classes.ganttProjectName
          : classes.ganttTaskName
      }
      ariaLabel={task.text}
      isPsaPrpTaskCodeOnTaskCreationEnabled={
        me?.featureFlags?.isPsaPrpTaskCodeOnTaskCreationEnabled
      }
    />
  );

  const addButtonHtml = renderToString(
    <GanttAddButton
      className={classes.ganttTaskAdd}
      ariaLabel={intl.formatMessage({
        id: 'button.addTask'
      })}
    />
  );

  const closingHtml = '</div>';

  return showAddButton
    ? openingHtml + addButtonHtml + taskNameHtml + closingHtml
    : openingHtml + taskNameHtml + closingHtml;
};

export const getAssignedRoleTemplate = ({
  me,
  classes,
  showAddButton,
  intl
}) => task => {
  if (me.isRolledUpTaskEstimateCalculationMethodEnabled) {
    return task.parent
      ? renderToString(
          <GanttColumn
            me={me}
            classes={classes}
            className="gantt_assignRole"
            toolTipClassKey={customTooltipKeys.taskAssignedRole}
            item={task.assignedRole}
            assignLabel={intl.formatMessage({
              id: 'projectTasksPage.assignRole'
            })}
            assignEnabled={showAddButton}
          />
        )
      : '';
  }

  return renderToString(
    <GanttColumn
      me={me}
      classes={classes}
      className="gantt_assignRole"
      toolTipClassKey={customTooltipKeys.taskAssignedRole}
      item={task.assignedRole}
      assignLabel={intl.formatMessage({
        id: 'projectTasksPage.assignRole'
      })}
      assignEnabled={showAddButton}
    />
  );
};

export const getTaskOwnerTemplate = ({
  me,
  classes,
  showAddButton,
  intl
}) => task => {
  if (me.isRolledUpTaskEstimateCalculationMethodEnabled) {
    return task.parent
      ? renderToString(
          <GanttColumn
            me={me}
            classes={classes}
            className="gantt_assignto"
            toolTipClassKey={customTooltipKeys.taskOwner}
            item={task.assignee}
            assignLabel={intl.formatMessage({
              id: 'projectTasksPage.assignTo'
            })}
            assignEnabled={showAddButton}
          />
        )
      : '';
  }

  return renderToString(
    <GanttColumn
      me={me}
      classes={classes}
      className="gantt_assignto"
      toolTipClassKey={customTooltipKeys.taskOwner}
      item={task.assignee}
      assignLabel={intl.formatMessage({
        id: 'projectTasksPage.assignTo'
      })}
      assignEnabled={showAddButton}
    />
  );
};

export const getGanttColumnConfiguration = ({
  me,
  intl,
  classes,
  editable,
  hasEditTaskPermissions
}) => {
  const showAddButton = editable && hasEditTaskPermissions;
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = me;

  const taskColumn = {
    resize: true,
    name: 'text',
    label: intl.formatMessage({ id: 'projectTasksPage.task' }),
    tree: true,
    width: 360,
    min_width: 100,
    parent: task => task.parent,
    template: task =>
      renderToString(
        <TaskNameTemplate
          task={task}
          classes={classes}
          intl={intl}
          isRolledUpTaskEstimateCalculationMethodEnabled={
            me.isRolledUpTaskEstimateCalculationMethodEnabled
          }
        />
      )
  };

  const addTaskIconColumn = showAddButton
    ? {
        resize: false,
        tree: false,
        max_width: 45,
        width: 45,
        min_width: 45,
        parent: task => task.parent,
        template: () => renderToString(<AddTaskTemplate />)
      }
    : null;

  const defaultColumns = isPsaRmpTaskAllocation1Enabled
    ? [taskColumn, addTaskIconColumn].filter(Boolean)
    : [
        {
          resize: true,
          name: 'text',
          label: intl.formatMessage({ id: 'projectTasksPage.task' }),
          tree: true,
          width: 300,
          min_width: 100,
          parent: task => task.parent,
          template: getTaskNameTemplate({
            me,
            classes,
            showAddButton,
            intl
          })
        },
        {
          resize: true,
          name: 'assigneeText',
          label: intl.formatMessage({ id: 'projectTasksPage.taskOwner' }),
          width: 120,
          template: getTaskOwnerTemplate({ me, classes, showAddButton, intl })
        },
        {
          resize: true,
          name: 'assignedRoleText',
          label: intl.formatMessage({ id: 'projectTasksPage.assignedRole' }),
          width: 120,
          template: getAssignedRoleTemplate({
            me,
            classes,
            showAddButton,
            intl
          })
        }
      ];

  return defaultColumns;
};

export const useGanttConfiguration = ({
  me,
  classes,
  intl,
  ganttScale,
  editable,
  hasEditTaskPermissions
}) =>
  useMemo(
    () => ({
      drag_links: false,
      drag_project: false,
      drag_move: editable && hasEditTaskPermissions,
      drag_resize: editable && hasEditTaskPermissions,
      drag_progress: false,
      inherit_scale_class: true,
      open_tree_initially: true,
      order_branch: editable && hasEditTaskPermissions ? 'marker' : false,
      order_branch_free: editable && hasEditTaskPermissions,
      round_dnd_dates: false,
      ...((me.featureFlags?.isPsaPrpTaskCodeOnTaskCreationEnabled ||
        me.isRolledUpTaskEstimateCalculationMethodEnabled ||
        me.featureFlags?.isPsaRmpTaskAllocation1Enabled) && {
        row_height: 62
      }),
      select_task: false,
      show_quick_info: false,
      show_task_cells: false,
      smart_scales: true,
      task_height: me.isRolledUpTaskEstimateCalculationMethodEnabled ? 18 : 30,
      undo: true,
      date_format: '%Y-%m-%dT00:00:00.000Z',
      columns: getGanttColumnConfiguration({
        me,
        classes,
        intl,
        editable,
        hasEditTaskPermissions
      }),
      scale_height: getGanttScaleHeight({ ganttScale }),
      scales: getGanttScaleConfiguration({ ganttScale })
    }),
    [classes, editable, ganttScale, hasEditTaskPermissions, intl, me]
  );

export default useGanttConfiguration;
