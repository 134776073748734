import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

export const CANCEL_BILLING_BATCH_MUTATION = gql`
  mutation cancelBillingBatch($batchId: String!) {
    cancelBillingBatch(batchId: $batchId)
  }
`;

export const useCancelBillingBatch = () => {
  const [cancelBillingBatch] = useMutation(CANCEL_BILLING_BATCH_MUTATION);

  return useCallback(
    batchId =>
      cancelBillingBatch({
        variables: {
          batchId
        }
      }),
    [cancelBillingBatch]
  );
};

export default useCancelBillingBatch;
