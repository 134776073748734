import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { v4 } from 'uuid';
import { LoadingButton } from '~/modules/common/components';
import { ADDRESS_TYPE } from '~/modules/common/enums';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  button: {
    '&.MuiGrid-item': {
      padding: theme.spacing(1, 0)
    }
  },
  addAddress: {
    padding: theme.spacing(0.5, 1.5)
  }
}));

export const AddressInfoActionComponent = ({
  isReadOnly,
  isSubmitting,
  onClose,
  setShouldScrollToBottom
}) => {
  const classes = useStyles();
  const {
    values: { addresses },
    isValid,
    resetForm,
    setFieldValue,
    handleSubmit
  } = useFormikContext();

  const isMobile = useIsBreakpointDown('sm');

  const onAddAddress = useCallback(() => {
    setFieldValue('addresses', [
      ...(addresses || []),
      {
        id: v4(),
        address: '',
        city: '',
        stateProvince: '',
        zipPostalCode: '',
        country: null,
        addressType: ADDRESS_TYPE.OTHERS
      }
    ]);
    isMobile && setShouldScrollToBottom(true);
  }, [addresses, isMobile, setFieldValue, setShouldScrollToBottom]);

  const onCancel = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item className={classes.button}>
        {!isReadOnly && (
          <Button
            color="primary"
            onClick={onAddAddress}
            disabled={addresses?.length >= 5 || isSubmitting}
            className={classes.addAddress}
            data-qe-id="AddAddress"
          >
            <FormattedMessage id="projectBasicInfoCard.addressInfo.actions.addAddress" />
          </Button>
        )}
      </Grid>
      <Grid item className={classes.button}>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              onClick={onCancel}
              data-qe-id="Cancel"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id={isReadOnly ? 'button.close' : 'button.cancel'}
              />
            </Button>
          </Grid>
          {!isReadOnly && (
            <Grid item>
              <LoadingButton
                color="primary"
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="button.save" />
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

AddressInfoActionComponent.propTypes = {
  isReadOnly: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  setShouldScrollToBottom: PropTypes.func
};

export default AddressInfoActionComponent;
