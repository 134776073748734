import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import {
  DiffValue,
  DIFF_VALUE_TYPE,
  Decimal
} from '~/modules/common/components';
import RequestDrawerAllocatedCostToolTipTitle from './RequestDrawerAllocatedCostToolTipTitle';

const RequestDrawerAllocatedCost = ({
  precision,
  requestedCost,
  allocatedCost,
  fixedDecimalScale
}) => {
  const intl = useIntl();

  const diff =
    allocatedCost.length === 0
      ? 0
      : allocatedCost[0].amount - requestedCost.amount;

  const showDiff =
    allocatedCost.length === 1 &&
    requestedCost &&
    requestedCost.currencyUri === allocatedCost[0].currencyUri &&
    diff !== 0;

  const tooltip = useMemo(
    () => (
      <RequestDrawerAllocatedCostToolTipTitle
        requestedCost={requestedCost}
        allocatedCost={allocatedCost}
        intl={intl}
      />
    ),
    [intl, requestedCost, allocatedCost]
  );

  return (
    <Tooltip title={tooltip}>
      <span>
        {(allocatedCost || []).map((allocatedCostValue, index) => (
          <Fragment key={allocatedCost.currencySymbol}>
            <Decimal
              precision={precision}
              value={allocatedCostValue.amount}
              prefix={allocatedCostValue.currencySymbol || ''}
              fixedDecimalScale={fixedDecimalScale}
            />
            {index < allocatedCost.length - 1 && allocatedCost.length > 1
              ? ', '
              : ''}
          </Fragment>
        ))}
        {showDiff && (
          <DiffValue
            diffValueType={
              diff > 0 ? DIFF_VALUE_TYPE.NEGATIVE : DIFF_VALUE_TYPE.POSITIVE
            }
          >
            <Decimal
              value={diff}
              prefix={
                diff > 0
                  ? `${requestedCost.currencySymbol}+ `
                  : `${requestedCost.currencySymbol} `
              }
              precision={precision}
              fixedDecimalScale={fixedDecimalScale}
            />
          </DiffValue>
        )}
      </span>
    </Tooltip>
  );
};

RequestDrawerAllocatedCost.propTypes = {
  precision: PropTypes.number,
  requestedCost: PropTypes.object,
  allocatedCost: PropTypes.array,
  fixedDecimalScale: PropTypes.bool
};

export default RequestDrawerAllocatedCost;
