import React, { useState, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

const useSetMessage = defaultMessage => {
  const [message, setMessage] = useState(defaultMessage || null);
  const hideMessage = useCallback(() => {
    setMessage(null);
  }, [setMessage]);
  const formattedTaskName = useMemo(
    () => ({
      taskName: <strong>{message}</strong>
    }),
    [message]
  );
  const formattedMessage = message ? (
    <FormattedMessage
      id="projectTasksPage.taskAdded"
      values={formattedTaskName}
    />
  ) : null;

  return {
    message,
    setMessage,
    hideMessage,
    formattedMessage
  };
};

export default useSetMessage;
