import React from 'react';
import { FormattedMessage } from 'react-intl';

export const STRING_MAX_LENGTH = 255;

export const exceededMaxLengthMessage = (
  <FormattedMessage
    id="client.fieldExceededMaxLength"
    values={{ max: STRING_MAX_LENGTH }}
  />
);
