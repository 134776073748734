import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { FormDateField } from '~/modules/common/components';
import { isoStringToObjectWithCache as isoStringToObject } from '~/modules/common/dates/convert';

export const ResourceRequestDrawerDetailsDateField = ({
  date,
  classes,
  onChange,
  label,
  error
}) => {
  const { formatMessage } = useIntl();

  const dateProps = useMemo(
    () => ({
      inputProps: {
        'aria-label': label,
        className: classes.dateField
      }
    }),
    [classes.dateField, label]
  );

  return (
    <FormDateField
      required
      editable
      InputProps={dateProps}
      label={formatMessage({
        id: label
      })}
      ariaLabel={formatMessage({
        id: label
      })}
      error={error}
      helperText={date.error}
      flex="shrink"
      underline={false}
      clearable={false}
      value={isoStringToObject(date.value)}
      onChange={onChange}
    />
  );
};

ResourceRequestDrawerDetailsDateField.propTypes = {
  date: PropTypes.object,
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool
};

export default ResourceRequestDrawerDetailsDateField;
