import { useCallback } from 'react';
import { ProjectRequestStatusType } from '~/types';
import { useUpdateProjectRequestStatus } from './useUpdateProjectRequestStatus';

export const useProjectRequestStatusHandler = ({
  projectRequestId,
  projectRequestGates,
  openApproveProjectRequestConfirmationDialog,
  setClickedButtonStatusType
}) => {
  const {
    updateProjectRequestStatus,
    isLoading
  } = useUpdateProjectRequestStatus();

  const onRequestStatusChange = useCallback(
    statusType => {
      updateProjectRequestStatus({
        projectRequestId,
        statusType
      });
      setClickedButtonStatusType(statusType);
    },
    [projectRequestId, updateProjectRequestStatus]
  );

  return {
    onRequestStatusChange,
    approveProjectRequestHandler: () => {
      const checkedGatesCount = projectRequestGates?.filter(
        g => g.value === 'MET'
      ).length;

      if (checkedGatesCount < projectRequestGates?.length) {
        openApproveProjectRequestConfirmationDialog();
      } else onRequestStatusChange(ProjectRequestStatusType.Approved);
    },
    isLoading
  };
};

export default useProjectRequestStatusHandler;
