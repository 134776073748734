/* eslint-disable no-param-reassign */
export const debounce = (scope, handler, wait) => {
  if (scope.timeout) {
    clearTimeout(scope.timeout);
  }

  const finish = () => {
    scope.pending -= 1;

    return scope.pending === 0;
  };

  scope.timeout = setTimeout(() => {
    scope.pending += 1;
    try {
      handler(finish);
      // eslint-disable-next-line no-empty
    } catch {}
  }, wait);
};

export const scope = () => ({
  timeout: null,
  pending: 0
});
