import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import ImportExportGroup from '~/modules/common/components/ImportExportMenu';
import { IMPORT_OPTIONS } from '~/modules/common/components/ImportExportMenu/enums/ImportExportMenuEnums';
import { useMeContext } from '~/modules/me';

const { IMPORT, CSV_IMPORT } = IMPORT_OPTIONS;

export const ResourceRequestImportExportMenu = ({ isMobile }) => {
  const { uiRootPath, permissionsMap } = useMeContext();

  const viewDataImportPermission = Boolean(
    permissionsMap['urn:replicon-webui:resource-data-import:edit-import']
  );

  const actions = useMemo(
    () => [
      viewDataImportPermission && {
        label: IMPORT,
        actionItems: [
          {
            label: CSV_IMPORT,
            href: `${uiRootPath}admin/data-import/resource-allocation`
          }
        ]
      }
    ],
    [uiRootPath, viewDataImportPermission]
  );

  return (
    <ImportExportGroup
      actions={actions}
      isMobile={isMobile}
      menuId="resource-requests-import-export-menu"
    />
  );
};

ResourceRequestImportExportMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default ResourceRequestImportExportMenu;
