import React, {
  createContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext
} from 'react';
import PropTypes from 'prop-types';

export const PortfolioPageEditContext = createContext();

export const usePortfolioPageEditContext = () =>
  useContext(PortfolioPageEditContext);

export const PortfolioPageEditContextProvider = ({
  portfolio = {},
  loading,
  error,
  edit: initialEdit,
  children
}) => {
  const [edit, setEdit] = useState(initialEdit);

  useEffect(() => {
    setEdit(initialEdit);
  }, [initialEdit]);

  const toggleEdit = useCallback(() => setEdit(!edit), [edit, setEdit]);

  const value = useMemo(
    () => ({ portfolio, loading, error, edit, toggleEdit }),
    [edit, error, loading, portfolio, toggleEdit]
  );

  return (
    <PortfolioPageEditContext.Provider value={value}>
      {children}
    </PortfolioPageEditContext.Provider>
  );
};

PortfolioPageEditContextProvider.propTypes = {
  edit: PropTypes.bool,
  children: PropTypes.node,
  portfolio: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object
};
