import { PORTFOLIO_PERMISSION } from '~/modules/common/enums';

export const usePortfolioObjectPermissions = (permittedActionUris = []) => {
  return {
    canViewPortfolio: permittedActionUris.includes(
      PORTFOLIO_PERMISSION.VIEW_PORTFOLIO
    ),

    canEditPortfolio: permittedActionUris.includes(
      PORTFOLIO_PERMISSION.EDIT_PORTFOLIO
    ),
    canViewCostData: permittedActionUris.includes(
      PORTFOLIO_PERMISSION.VIEW_COST_DATA
    ),

    canEditCostData: permittedActionUris.includes(
      PORTFOLIO_PERMISSION.EDIT_COST_DATA
    )
  };
};
export default usePortfolioObjectPermissions;
