import PropTypes from 'prop-types';
import React from 'react';
import List from '@material-ui/core/List';
import { FormattedMessage } from 'react-intl';
import { ListItem, Typography, makeStyles } from '@material-ui/core';
import { TaskAssignmentRow } from './components';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    paddingLeft: theme.spacing(3.4),
    paddingBottom: theme.spacing(0.75),
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.grey[700],
    fontWeight: 600,
    paddingTop: 0
  },
  taskHeader: {
    width: '66.25%'
  }
}));

export const TaskAssignmentList = ({
  resource,
  assignmentSummaryList,
  permittedActionUris,
  projectId,
  primaryRole,
  onTaskAssignmentUpdateSuccess
}) => {
  const classes = useStyles();

  return (
    <List>
      <ListItem className={classes.header}>
        <Typography
          className={classes.taskHeader}
          variant="inherit"
          component="div"
        >
          <FormattedMessage id="taskAssignmentDialog.task" />
        </Typography>
        <Typography variant="inherit" component="div">
          <FormattedMessage id="taskAssignmentDialog.assignment" />
        </Typography>
      </ListItem>
      {assignmentSummaryList.map(({ task, resourceEstimate }) => (
        <TaskAssignmentRow
          key={task.id}
          task={task}
          resource={resource}
          resourceEstimate={resourceEstimate}
          permittedActionUris={permittedActionUris}
          projectId={projectId}
          primaryRole={primaryRole}
          onTaskAssignmentUpdateSuccess={onTaskAssignmentUpdateSuccess}
        />
      ))}
    </List>
  );
};

TaskAssignmentList.propTypes = {
  assignmentSummaryList: PropTypes.array.isRequired,
  permittedActionUris: PropTypes.array.isRequired,
  resource: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  primaryRole: PropTypes.object,
  onTaskAssignmentUpdateSuccess: PropTypes.func.isRequired
};

export default TaskAssignmentList;
