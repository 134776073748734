import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { makeStyles } from '@material-ui/core';
import { parameterDefaultValueFormatterByType } from '~/modules/projects/project/common/scriptEnhancers';
import { useMeContext } from '~/modules/me';
import { EXCHANGE_RATE_QUERY } from '~/modules/billing-invoicing/common/hooks/useExchangeRates';
import { isoStringToObject } from '~/modules/common/dates/convert';
import { parameterDefaultValueFormatterByKeyUri } from './useRevenueScriptEnhancers';

const useStyles = makeStyles(theme => ({
  label: {
    position: 'absolute',
    fontSize: '0.6rem',
    marginTop: theme.spacing(-2.5)
  }
}));

export const getRevenuePolicyQuery = gql`
  query getProjectRevenuePolicy {
    revenueContractClauses {
      id
      displayText
      script {
        id
        name
        description
      }
      parameters
    }
  }
`;
const hiddenColumns = [
  'urn:replicon:script-key:revenue-contract-clause-script:additional-data-points'
];

const fixedCurrencyColumns = [
  'urn:replicon:script-key:parameter:total-contract-value'
];

const getDefaultValue = (defaultValue, keyUri, me, contractValue, rates) => {
  switch (keyUri) {
    case 'urn:replicon:script-key:parameter:total-contract-value':
      return {
        amount:
          contractValue &&
          contractValue.currency &&
          contractValue.currency.id === me.baseCurrency.id
            ? contractValue.amount
            : contractValue && contractValue.amount && rates && rates.length
            ? contractValue.amount * rates[0].rate
            : 0,
        currency: me.baseCurrency
      };
    case 'urn:replicon:script-key:parameter:total-contract-value-in-reference-currency':
      return {
        amount:
          contractValue && contractValue.amount ? contractValue.amount : 0,
        currency:
          contractValue && contractValue.currency
            ? contractValue.currency
            : me.baseCurrency
      };
    default:
      return defaultValue;
  }
};

export const normalizeScriptParams = ({
  parameters,
  projectStartDate,
  projectEndDate,
  contractValue,
  classes,
  me,
  rates
}) => {
  return parameters
    .filter(
      param =>
        Boolean(param.value && param.value.collection) &&
        !hiddenColumns.includes(param.keyUri)
    )
    .map(parameter => {
      const defaultValue = get(parameter, 'value.collection[1]');
      const valueType = get(parameter, 'value.collection[0].text');
      const { keyUri } = parameter;
      const valueFormatterByKeyUri =
        parameterDefaultValueFormatterByKeyUri[keyUri];
      const valueFormatterByType =
        parameterDefaultValueFormatterByType[valueType];

      const value = valueFormatterByKeyUri
        ? valueFormatterByKeyUri({
            me,
            defaultValue,
            projectStartDate,
            projectEndDate,
            contractValue
          })
        : valueFormatterByType
        ? valueFormatterByType(defaultValue, me)
        : defaultValue;

      return {
        id: keyUri,
        displayText: get(parameter, 'value.text'),
        type: valueType,
        options: get(parameter, 'value.collection[2].collection')
          ? parameter.value.collection[2].collection.map(x => x.text)
          : null,
        sortIndex: get(parameter, 'value.collection[3].number') || 0,
        defaultValue: getDefaultValue(value, keyUri, me, contractValue, rates),
        required: true,
        currencyEditable: !fixedCurrencyColumns.includes(keyUri),
        currencyLabel: null,
        visible: true
      };
    });
};

export const useProjectRevenuePolicy = ({
  projectStartDate = null,
  projectEndDate = null,
  contractValue = null
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const { data: { revenueContractClauses = [] } = {}, loading } = useQuery(
    getRevenuePolicyQuery
  );

  const { data } = useQuery(EXCHANGE_RATE_QUERY, {
    variables: {
      currencyId:
        contractValue && contractValue.currency && contractValue.currency.id,
      otherCurrencyIds: [me.baseCurrency.id],
      asOfDate: isoStringToObject(projectStartDate)
    },
    skip: !contractValue || !contractValue.currency || !projectStartDate,
    fetchPolicy: 'cache-first'
  });

  const rates = get(data, 'currency.exchangeRates', []);

  const revenuePolicies = (revenueContractClauses || []).map(policy => {
    return {
      ...policy,
      parameters: normalizeScriptParams({
        parameters: policy.parameters,
        projectStartDate,
        projectEndDate,
        contractValue,
        classes,
        me,
        rates
      })
    };
  });

  return { revenuePolicies, isLoading: loading };
};

export default useProjectRevenuePolicy;
