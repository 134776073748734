import { gql } from 'graphql-tag';

export const ASSIGNED_CLIENT_MANAGERS_QUERY = gql`
  query AssignedClientManagers {
    assignedClientManagers {
      id
      displayText
    }
  }
`;
