export const dialogContentForDeletion = {
  title: 'removeProgramDialog.title',
  content: 'removeProgramDialog.content',
  action: 'removeProgramDialog.action',
  cancel: 'removeProgramDialog.cancel'
};

export const dialogContentForDisable = {
  title: 'disableProgramDialog.title',
  content: 'disableProgramDialog.content',
  secondaryContent: 'disableProgramDialog.secondaryContent',
  action: 'disableProgramDialog.action',
  cancel: 'disableProgramDialog.cancel'
};

export default ({ canBeDeleted }) =>
  canBeDeleted ? dialogContentForDeletion : dialogContentForDisable;
