import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState, useCallback } from 'react';
import get from 'lodash.get';
import { filterSummarizeColumnOptions } from '~/modules/billing-invoicing/common/util';

export const billQuery = gql`
  query getBillLineItems(
    $page: Int!
    $pageSize: Int!
    $id: String!
    $filter: LineItemFilter
    $summarizeColumnOptions: [BillingItemColumn]
  ) {
    getBill(id: $id) {
      id
      lineItems(
        page: $page
        pageSize: $pageSize
        filter: $filter
        summarizeColumnOptions: $summarizeColumnOptions
      ) {
        lineItems {
          id
          amount {
            amount
            currency {
              id: uri
              symbol
              displayText
            }
          }
          project {
            id
            displayText
          }
          task {
            id
            displayText
          }
          user {
            id
            displayText
          }
          quantity
          unit
          billingType
          rate {
            amount
            currency {
              id
              symbol
            }
          }
          description
          hasAccessToLineItemProject
          timesheetPeriod {
            startDate
            endDate
          }
          payCode
          role
          expenseCode
        }
      }
    }
  }
`;

export const updateQueryOnFetchMoreResult = (
  prev = {
    getBill: {
      lineItems: { lineItems: [] }
    }
  },
  {
    fetchMoreResult = {
      getBill: {
        lineItems: {
          lineItems: []
        }
      }
    }
  }
) => {
  if (!fetchMoreResult) return prev;

  return {
    getBill: {
      ...prev.getBill,
      lineItems: {
        ...prev.getBill.lineItems,
        lineItems: [
          ...prev.getBill.lineItems.lineItems,
          ...fetchMoreResult.getBill.lineItems.lineItems
        ]
      }
    }
  };
};

export const PAGE_SIZE = 50;

export const useLineItems = ({ billId, summarizeColumnOptions, filter }) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const filteredSummarizeColumnOptions = filterSummarizeColumnOptions(
    summarizeColumnOptions
  );

  const { data, loading, refetch, fetchMore } = useQuery(billQuery, {
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
      id: billId,
      filter,
      summarizeColumnOptions: filteredSummarizeColumnOptions
    },
    skip: !billId,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const lineItems = get(data, 'getBill.lineItems.lineItems', []);

  const hasMore = lineItems.length && lineItems.length % PAGE_SIZE === 0;

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          id: billId,
          page: lineItems.length / PAGE_SIZE + 1,
          pageSize: PAGE_SIZE
        },
        updateQuery: updateQueryOnFetchMoreResult
      });
    } finally {
      setLoadingMore(false);
    }
  }, [billId, fetchMore, setLoadingMore, hasMore, lineItems]);

  return {
    loading,
    refetch,
    lineItems,
    hasMore,
    loadMore,
    loadingMore
  };
};

export default useLineItems;
