import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, InputAdornment } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import QualityDropdown from '~/modules/common/components/QualityDropdown';
import CommentField from '~/modules/common/components/CommentField';
import SentimentCommentFieldReadOnly from './SentimentCommentFieldReadOnly';

const useStyles = makeStyles(theme => ({
  input: {},
  adornment: {
    '&.MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      marginTop: 0
    }
  },
  quality: {
    padding: theme.spacing(1),
    [theme.breakpoints.only('md')]: {
      margin: theme.spacing(-1)
    }
  }
}));

export const SentimentCommentField = ({
  classes,
  comment,
  label,
  dataQeId,
  flagged = false,
  quality = 0,
  onCommentChange,
  onQualityChange,
  editable = true,
  multiline = true,
  intl,
  disabled,
  hiddenLabel,
  ...rest
}) => {
  const sentimentCommentFieldClasses = useStyles({ classes });

  const ariaLabel = intl.formatMessage({
    id: 'qualityFacetDetails.title'
  });

  const inputProps = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment
          className={sentimentCommentFieldClasses.adornment}
          position="start"
        >
          <QualityDropdown
            dataQeId={`${dataQeId}_Quality`}
            quality={quality}
            ariaLabel={ariaLabel}
            onChange={onQualityChange}
            readOnly={disabled || !editable}
            className={sentimentCommentFieldClasses.quality}
          />
        </InputAdornment>
      )
    }),
    [
      sentimentCommentFieldClasses.adornment,
      sentimentCommentFieldClasses.quality,
      dataQeId,
      quality,
      ariaLabel,
      onQualityChange,
      disabled,
      editable
    ]
  );

  return editable ? (
    <CommentField
      dataQeId={dataQeId}
      multiline={multiline}
      rowsMax={4}
      value={comment}
      onChange={onCommentChange}
      editable={editable}
      label={label}
      InputProps={inputProps}
      disabled={disabled}
      hiddenLabel={hiddenLabel}
      classes={classes}
      {...rest}
    />
  ) : (
    <SentimentCommentFieldReadOnly quality={quality} label={label} {...rest} />
  );
};

SentimentCommentField.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  quality: PropTypes.number,
  comment: PropTypes.string,
  editable: PropTypes.bool,
  onQualityChange: PropTypes.func,
  onCommentChange: PropTypes.func,
  multiline: PropTypes.bool,
  intl: PropTypes.object,
  hiddenLabel: PropTypes.bool,
  label: PropTypes.string,
  flagged: PropTypes.bool,
  disabled: PropTypes.bool
};

export default injectIntl(SentimentCommentField);
