import React from 'react';
import { PropTypes } from 'prop-types';
import PortfolioIcon from '../../../portfolios/PortfolioIcon';
import LabelledIcon from '../LabelledIcon';

const Portfolio = ({ classes, portfolio: { displayText } }) => (
  <LabelledIcon
    classes={classes}
    icon={<PortfolioIcon />}
    label={displayText}
  />
);

Portfolio.propTypes = {
  classes: PropTypes.object,
  portfolio: PropTypes.shape({
    displayText: PropTypes.string.isRequired
  }).isRequired
};

export default Portfolio;
