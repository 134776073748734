import { useState, useCallback } from 'react';
import {
  useKeyValueSettingsMutation,
  updateCache
} from '~/modules/common/hooks';
import { PROJECT_STATUS_FILTER } from './components/ProjectStatusFilter/enum';

const useQuickAllocationChartSettings = ({ chartSettings }) => {
  const [projectStatusFilterOption, setStatusFilterOption] = useState(
    (chartSettings && chartSettings.projectStatusFilterOption) || {
      option: PROJECT_STATUS_FILTER.UNARCHIVED_PROJECTS,
      statuses: []
    }
  );

  const [putKeyValueSettings] = useKeyValueSettingsMutation(
    updateCache('quick_allocation_chart_settings')
  );

  const setProjectStatusFilterOption = useCallback(
    option => {
      setStatusFilterOption(option);
      putKeyValueSettings({
        variables: {
          input: {
            key: 'quick_allocation_chart_settings',
            settings: chartSettings
              ? {
                  ...chartSettings,
                  projectStatusFilterOption: option
                }
              : {
                  projectStatusFilterOption: option
                }
          }
        }
      });
    },
    [chartSettings, putKeyValueSettings]
  );

  return {
    setProjectStatusFilterOption,
    projectStatusFilterOption
  };
};

export default useQuickAllocationChartSettings;
