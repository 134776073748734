import React, { memo } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import FormattedNameCode from '~/modules/common/components/entities/task/FormattedNameCode';
import LongString from '../../LongString';
import TaskPath from './TaskPath';

const useStyles = makeStyles(theme => ({
  taskTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: 'inherit',
    width: '100%',
    height: '100%',
    padding: theme.spacing(0.5, 0, 0.5, 1)
  },
  mainText: {
    lineHeight: 'unset',
    fontSize: 14
  },
  mainTextLarge: {
    fontWeight: theme.typography.fontWeightMedium
  },
  titleText: {
    width: '95%'
  }
}));

export const TaskTitle = ({
  task,
  classes: classesOverride,
  qeIdTag,
  titleVariant = 'subtitle2',
  large,
  className,
  titleClassName,
  showCode = false
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classNames(classes.taskTitle, className)}>
      <TaskPath data-qe-id={`${qeIdTag}_TaskPath`} task={task} />
      <Typography
        data-qe-id={`${qeIdTag}_TaskTitle`}
        className={classNames(classes.titleText, titleClassName, {
          [classes.mainTextLarge]: large,
          [classes.mainText]: !large
        })}
        color="inherit"
        variant={titleVariant}
      >
        <LongString>
          <FormattedNameCode
            name={task.name}
            code={showCode ? task.code : null}
          />
        </LongString>
      </Typography>
    </div>
  );
};

TaskTitle.propTypes = {
  task: PropTypes.object.isRequired,
  qeIdTag: PropTypes.string,
  titleVariant: PropTypes.string,
  large: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  titleClassName: PropTypes.string,
  showCode: PropTypes.bool
};

export default memo(TaskTitle);
