import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const DELETE_PROJECT_REVREC_SETTINGS = gql`
  mutation deleteProjectRevenueRecognitionBatchUri($revenueRunUri: String!) {
    deleteProjectRevenueRecognitionBatchUri2(revenueRunUri: $revenueRunUri)
  }
`;

export const useDeleteProjectRevenueRecognitionSettings = revenueRunUri => {
  const [deleteProjectRevenueRecognitionBatchUri] = useMutation(
    DELETE_PROJECT_REVREC_SETTINGS
  );

  return {
    deleteProjectRevenueRecognitionBatchUri: () => {
      return deleteProjectRevenueRecognitionBatchUri({
        variables: {
          revenueRunUri
        }
      });
    }
  };
};

export default useDeleteProjectRevenueRecognitionSettings;
