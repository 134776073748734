import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ResourceRequestIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 2,2 V 22 H 22 V 2 Z m 12,5 c 1.38124999999999,0 2.5,1.11875 2.5,2.5 C 16.5,10.88125000000001 15.38125,12 14,12 12.61875,12 11.5,10.88125000000001 11.5,9.5 11.5,8.118749999999998 12.61874999999999,7 14,7 Z M 6,8 h 1 v 2 h 2 v 1 H 7 v 2 H 6 V 11 H 4 v -1 h 2 z m 8,5.25 c 1.66874999999999,0 3.96144448344077,1.20180560430096 5,2.5 V 17 H 9 V 15.75 C 9,14.0875 12.33125,13.25 14,13.25 Z" />
    </>
  </SvgIcon>
));

export default ResourceRequestIcon;
