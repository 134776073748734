import { Checkbox, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { SELECTED_STATUS } from '~/modules/billing-invoicing/common/components/BillingTransactionTab/enums';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(-1)
  }
}));

const inputProps = formatMessage => ({
  'aria-label': formatMessage({
    id: 'billingTransactionList.allRowsSelected'
  })
});

export const AllRowSelectorFormatter = ({ onSelectAll, allSelectedStatus }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Checkbox
      checked={allSelectedStatus === SELECTED_STATUS.ALL}
      indeterminate={allSelectedStatus === SELECTED_STATUS.PARTIAL}
      onChange={onSelectAll(allSelectedStatus)}
      color="primary"
      classes={classes}
      inputProps={inputProps(formatMessage)}
    />
  );
};

AllRowSelectorFormatter.propTypes = {
  onSelectAll: PropTypes.func,
  allSelectedStatus: PropTypes.number
};

export default AllRowSelectorFormatter;
