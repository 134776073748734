import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { ResourceRequestStatusIndicator } from '~/modules/resourcing/common/components';
import { ResourceRequestStatus } from '~/types';
import { ResourceRequestCard } from './components';
import useStyles from './useStyles';

export const MobileResourceRequestItem = ({
  classes: classesOverride,
  record,
  editable
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { resourceAllocations, ...resourceRequest } = record;
  const location = {
    hash: `#/resourceRequest/${resourceRequest.id}`,
    state: {
      isProjectEditEnabled: editable
    }
  };

  return (
    <ListItem classes={{ root: classes.listItem }} disableGutters>
      <Link className={classes.link} to={location}>
        <div className={classes.root}>
          <ResourceRequestStatusIndicator
            classes={{ root: classes.statusBar }}
            status={resourceRequest.requestStatus}
          />
          {resourceRequest.requestStatus !== ResourceRequestStatus.Complete && (
            <ResourceRequestCard
              resourceRequest={resourceRequest}
              resourceAllocations={resourceAllocations}
              contextCurrency={resourceRequest.currency}
            />
          )}
        </div>
      </Link>
    </ListItem>
  );
};

MobileResourceRequestItem.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  editable: PropTypes.bool
};

export default MobileResourceRequestItem;
