import React from 'react';
import PropTypes from 'prop-types';
import { ResourceAllocationSummaryBlockContainer } from './ResourceAllocationSummaryBlockContainer';

export const ResourceAllocationPercentageSummaryBlock = ({
  allocationPeriod,
  containerClasses,
  dragDelta,
  dragIndicatorClasses,
  dragIndicatorDates,
  gestureBindEvents,
  resizeDirection,
  showResizeIndicator,
  timelineClassName
}) => (
  <ResourceAllocationSummaryBlockContainer
    allocationPeriod={allocationPeriod}
    classes={containerClasses}
    dragDelta={dragDelta}
    dragIndicatorClasses={dragIndicatorClasses}
    dragIndicatorDates={dragIndicatorDates}
    gestureBindEvents={gestureBindEvents}
    resizeDirection={resizeDirection}
    showResizeIndicator={showResizeIndicator}
  >
    <div className={timelineClassName} aria-hidden="true"></div>
  </ResourceAllocationSummaryBlockContainer>
);

ResourceAllocationPercentageSummaryBlock.propTypes = {
  allocationPeriod: PropTypes.object.isRequired,
  containerClasses: PropTypes.object,
  dragDelta: PropTypes.number,
  dragIndicatorClasses: PropTypes.object,
  dragIndicatorDates: PropTypes.object,
  gestureBindEvents: PropTypes.func,
  resizeDirection: PropTypes.string,
  showResizeIndicator: PropTypes.bool,
  timelineClassName: PropTypes.string
};
export default ResourceAllocationPercentageSummaryBlock;
