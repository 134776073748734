import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { BillStatus } from '~/types';
import { issuedBillSummaryQuery } from './totalsQuery';
import {
  getConsolidatedTypeFilter,
  mapToServiceFilter
} from './useOutstandingBillTabSummary';

const summaryType = ['BILL'];

export const useIssuedTabSummary = (
  billingTransactionFilter,
  contextFilter
) => {
  const consolidatedTypeFilter =
    billingTransactionFilter.transactionTypes &&
    billingTransactionFilter.transactionTypes.length > 0
      ? getConsolidatedTypeFilter(
          billingTransactionFilter.transactionTypes,
          summaryType
        )
      : summaryType;

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const { data, loading } = useQuery(issuedBillSummaryQuery, {
    variables: {
      filter: mapToServiceFilter({
        contextFilter,
        billingTransactionFilter,
        consolidatedTypeFilter,
        billStatus: [
          BillStatus.IssuedPaid,
          BillStatus.IssuedPartiallypaid,
          BillStatus.IssuedUnpaid
        ]
      })
    },
    skip:
      isPsaFpGraphqlOptimizationEnabled || !consolidatedTypeFilter.length > 0,
    fetchPolicy: 'network-only'
  });

  const issuedSummary = get(data, 'getBillingTransactionTotals', {});

  return {
    loading,
    issuedSummary
  };
};

export default useIssuedTabSummary;
