import { useTheme } from '@material-ui/core/styles';
import { useThemeBreakpoint } from '~/modules/common/hooks';

export const useChartStyles = () => {
  const theme = useTheme();
  const breakpoint = useThemeBreakpoint(theme);
  const isMobile = breakpoint === 'xs';

  const styles = {
    chart: {
      width: 600,
      height: 200,
      domainPadding: { x: 30 },
      padding: {
        top: theme.spacing(1.5),
        bottom: theme.spacing(3),
        left: theme.spacing(5.5),
        right: theme.spacing(1)
      },
      pendingBarWidth: 15,
      barWidth: 30,
      y: 'value',
      x: 'dates'
    },
    barStackColors: [
      theme.palette.resourceAllocation.overAllocated,
      theme.palette.resourceAllocation.holiday,
      theme.palette.resourceAllocation.timeOff,
      theme.palette.resourceAllocation.allocated,
      theme.palette.resourceRequest.pending.main,
      theme.palette.common.white,
      theme.palette.resourceRequest.toBeHired.main
    ],
    availableBar: {
      style: {
        data: {
          fill: 'transparent',
          stroke: theme.palette.grey[600],
          strokeWidth: 1,
          cursor: 'pointer'
        }
      }
    },
    axis: {
      labelStyle: {
        paddingTop: 5,
        angle: 0,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: isMobile ? '18px' : '7px', // px for VictoryChart
        fontFamily: theme.typography.fontFamily,
        verticalAnchor: 'middle',
        textAnchor: 'middle'
      },
      x: {
        offsetY: isMobile ? 20 : 25,
        styleX1: {
          axis: { stroke: theme.palette.grey[600] },
          tickLabels: {
            fill: 'transparent'
          }
        },
        styleX2: {
          axis: { stroke: 'transparent' }
        }
      },
      y: {
        domainPadding: [0, 10],
        style: {
          grid: {
            stroke: theme.palette.grey[100]
          },
          axisLabel: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: isMobile ? '18px' : '9px',
            fontFamily: theme.typography.fontFamily,
            fill: theme.palette.text.main
          }
        }
      }
    },
    cursorPointer: {
      data: {
        cursor: 'pointer'
      }
    }
  };

  if (breakpoint !== 'xs') return styles;

  return {
    ...styles,
    chart: {
      ...styles.chart,
      height: 360,
      barWidth: 60,
      domainPadding: { x: 120 },
      padding: {
        top: theme.spacing(5.5),
        bottom: theme.spacing(3),
        left: theme.spacing(5.5),
        right: theme.spacing(1)
      }
    }
  };
};

export default useChartStyles;
