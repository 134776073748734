import { useCallback } from 'react';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { calculateMode, calculateNewEndDate } from '../components/durationUtil';
import { MODE } from '../components/enum';

export const useOnChangeHandlers = ({
  setNewTaskDateRange,
  setDurationMode,
  setIsRemoveAllocationsChecked,
  setAnchorEl,
  onModeChange,
  durationValues,
  durationMode,
  newTaskDateRange
}) => {
  const onDurationModeChange = useCallback(
    mode => {
      setDurationMode(mode);
      const newEndDate = calculateNewEndDate({
        durationValues,
        newStartDate: newTaskDateRange.startDate,
        mode,
        newTaskDateRange
      });

      setNewTaskDateRange({
        startDate: newTaskDateRange.startDate,
        endDate: newEndDate
      });
    },
    [setDurationMode, durationValues, newTaskDateRange, setNewTaskDateRange]
  );

  const handleRemoveAllocationCheckBoxClick = useCallback(
    event => {
      setIsRemoveAllocationsChecked(event.target.checked);
    },
    [setIsRemoveAllocationsChecked]
  );

  const onNewStartDateChange = useCallback(
    selectedStartDate => {
      setNewTaskDateRange(prevState => {
        const newStartDate = selectedStartDate
          ? mapRepliconDateToUtcObject(selectedStartDate)
          : null;

        if (!durationValues) {
          return {
            startDate: newStartDate,
            endDate:
              prevState.endDate && prevState.endDate < newStartDate
                ? newStartDate
                : prevState.endDate
          };
        }

        if (!newStartDate) {
          return { ...prevState, startDate: newStartDate };
        }

        let newEndDate = prevState.endDate;

        if (durationMode === MODE.CUSTOM) {
          newEndDate =
            prevState.endDate && prevState.endDate < newStartDate
              ? newStartDate
              : prevState.endDate;
        } else {
          newEndDate = calculateNewEndDate({
            durationValues,
            newStartDate,
            mode: durationMode,
            newTaskDateRange: { ...prevState, startDate: newStartDate }
          });
        }

        return {
          startDate: newStartDate,
          endDate: newEndDate ? mapRepliconDateToUtcObject(newEndDate) : null
        };
      });
    },
    [durationMode, durationValues, setNewTaskDateRange]
  );

  const onNewEndDateChange = useCallback(
    selectedEndDate => {
      setNewTaskDateRange(prevState => {
        const newEndDate = selectedEndDate
          ? mapRepliconDateToUtcObject(selectedEndDate)
          : null;
        const newStartDate =
          newEndDate && newEndDate < prevState.startDate
            ? newEndDate
            : prevState.startDate;

        if (durationValues) {
          const newMode = !newEndDate
            ? MODE.CUSTOM
            : calculateMode({
                newEndDate,
                newTaskDateRange: {
                  startDate: newStartDate,
                  endDate: newEndDate
                },
                durationValues,
                durationMode
              });

          setDurationMode(newMode);
        }

        return { startDate: newStartDate, endDate: newEndDate };
      });
    },
    [durationValues, durationMode, setDurationMode, setNewTaskDateRange]
  );

  const handleModeClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleModeClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleModeSelect = useCallback(
    newMode => {
      onModeChange(newMode);
      handleModeClose();
    },
    [onModeChange, handleModeClose]
  );

  return {
    onDurationModeChange,
    handleRemoveAllocationCheckBoxClick,
    onNewStartDateChange,
    onNewEndDateChange,
    handleModeClick,
    handleModeClose,
    handleModeSelect
  };
};
