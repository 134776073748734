import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AbbreviatedDecimal } from '~/modules/common/components';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

export const statusColors = {
  thirtyDays: {
    dark: theme.palette.accountReceivableStatus.thirtyDays.main,
    light: theme.palette.accountReceivableStatus.thirtyDays.light
  },
  sixtyDays: {
    dark: theme.palette.accountReceivableStatus.sixtyDays.main,
    light: theme.palette.accountReceivableStatus.sixtyDays.light
  },
  ninetyDays: {
    dark: theme.palette.accountReceivableStatus.ninetyDays.main,
    light: theme.palette.accountReceivableStatus.ninetyDays.light
  },
  oneTwentyDays: {
    dark: theme.palette.accountReceivableStatus.oneTwentyDays.main,
    light: theme.palette.accountReceivableStatus.oneTwentyDays.light
  },
  greaterThanoneTwentyDays: {
    dark: theme.palette.accountReceivableStatus.greaterThanoneTwentyDays.main,
    light: theme.palette.accountReceivableStatus.greaterThanoneTwentyDays.light
  }
};

export const totalsDisplayText = (total, allowNegative = true) => (
  <AbbreviatedDecimal
    prefix={`${total.currency.symbol} `}
    value={total.amount}
    allowNegative={allowNegative}
  />
);

export const getStatusCountSummaryBasedProps = ({
  allSummary,
  thirtyDaysDueDateSummary,
  sixtyDaysdueDateSummary,
  ninetyDaysdueDateSummary,
  oneTwentyDaysdueDateSummary,
  greaterThanOneTwentyDaysdueDateSummary
} = {}) => ({
  allTotal:
    allSummary && allSummary.balanceAmountInBaseCurrency
      ? totalsDisplayText(allSummary.balanceAmountInBaseCurrency)
      : 0,

  statusOptions: [
    {
      id: 'uptoThirtyDays',
      displayText: <FormattedMessage id="accountReceivable.thirtyDays" />,
      color: statusColors.thirtyDays,
      dataQeId: 'AccountReceiveable_1To30Days',
      totals:
        thirtyDaysDueDateSummary &&
        thirtyDaysDueDateSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(
              thirtyDaysDueDateSummary.balanceAmountInBaseCurrency
            )
          : 0
    },
    {
      id: 'thirtyToSixtyDays',
      displayText: <FormattedMessage id="accountReceivable.sixtyDays" />,
      color: statusColors.sixtyDays,
      dataQeId: 'AccountReceiveable_30To60Days',
      totals:
        sixtyDaysdueDateSummary &&
        sixtyDaysdueDateSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(
              sixtyDaysdueDateSummary.balanceAmountInBaseCurrency
            )
          : 0
    },
    {
      id: 'sixtyToNinetyDays',
      displayText: <FormattedMessage id="accountReceivable.ninetyDays" />,
      color: statusColors.ninetyDays,
      dataQeId: 'AccountReceiveable_60To90Days',
      totals:
        ninetyDaysdueDateSummary &&
        ninetyDaysdueDateSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(
              ninetyDaysdueDateSummary.balanceAmountInBaseCurrency
            )
          : 0
    },
    {
      id: 'ninetyToOneTwentyDays',
      displayText: <FormattedMessage id="accountReceivable.oneTwentyDays" />,
      color: statusColors.oneTwentyDays,
      dataQeId: 'AccountReceiveable_90To120Days',
      totals:
        oneTwentyDaysdueDateSummary &&
        oneTwentyDaysdueDateSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(
              oneTwentyDaysdueDateSummary.balanceAmountInBaseCurrency
            )
          : 0
    },
    {
      id: 'greaterThanOneTwentyDays',
      displayText: (
        <FormattedMessage id="accountReceivable.greaterThanoneTwenty" />
      ),
      color: statusColors.greaterThanoneTwentyDays,
      dataQeId: 'AccountReceiveable_Over120Days',
      totals:
        greaterThanOneTwentyDaysdueDateSummary &&
        greaterThanOneTwentyDaysdueDateSummary.balanceAmountInBaseCurrency
          ? totalsDisplayText(
              greaterThanOneTwentyDaysdueDateSummary.balanceAmountInBaseCurrency
            )
          : 0
    }
  ]
});
