import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  resizeHandle: {},
  resizeHandleStart: {},
  resizeHandleEnd: {},
  resizeIcon: {}
}));

export default useStyles;
