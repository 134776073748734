import { withStyles } from '@material-ui/core';

export const styles = theme => ({
  container: {
    padding: theme.spacing(1, 1, 0, 1),
    margin: 0,
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderTop: `1px solid ${theme.palette.table.border}`
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '40%'
  }
});

export default withStyles(styles);
