/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { ProgressbarLegend } from '~/modules/common/charts/dashboard';
import ResourcingLegend from './ResourcingLegend';
import useStyles from './useStyles';

export const LegendItem = ({
  classes,
  isProgressBarLegend,
  barType,
  ...otherProps
}) => {
  const legendClasses = useMemo(
    () => ({
      rootLegend: classes[barType]
    }),
    [barType, classes]
  );

  return (
    <div className={classes.legendItem}>
      {isProgressBarLegend ? (
        <ProgressbarLegend {...otherProps} />
      ) : (
        <ResourcingLegend classes={legendClasses} {...otherProps} />
      )}
    </div>
  );
};

LegendItem.propTypes = {
  classes: PropTypes.object,
  isProgressBarLegend: PropTypes.bool,
  barType: PropTypes.oneOf(['dashedBar', 'roundBar'])
};

export const ResourcingLegends = memo(
  ({ classes: overriddenClasses, legends }) => {
    const classes = useStyles({ classes: overriddenClasses });

    return (
      <div className={classes.root}>
        {legends.map((legend, index) => (
          <LegendItem key={index} classes={classes} {...legend} />
        ))}
      </div>
    );
  }
);

ResourcingLegends.propTypes = {
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      isProgressBarLegend: PropTypes.bool,
      barType: PropTypes.oneOf(['dashedBar', 'roundBar']),
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      className: PropTypes.string,
      color: PropTypes.string
    }).isRequired
  ).isRequired,
  classes: PropTypes.object
};

export default ResourcingLegends;
