import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import {
  ALLOCATION_PERIOD_OVERLAP_TYPE,
  DISPLAY_UNIT_ENUM
} from '~/modules/resourcing/common/enums';
import { OverlayBlockLabel } from '~/modules/resourcing/common/chart/components';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import ResourceRequestUserAllocationPeriodOverlayTooltip from './ResourceRequestUserAllocationPeriodOverlayTooltip';

const useStyles = makeStyles(theme => ({
  overlay: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    zIndex: 1
  },
  overlayLabelVisible: {
    zIndex: 2
  },
  overlayBorder: {
    boxShadow: `0px 1px 0px #e0e0e0, 0px -1px 0px #e0e0e0`
  }
}));

const ResourceRequestUserAllocationPeriodOverlay = ({
  allocation,
  allocationPeriodOverlapType,
  classes: classesOverride,
  dynamicPosition,
  endDate,
  handleAllocationPeriodClick,
  isEditable = true,
  percentage,
  requestPeriods,
  resourceAvailabilitySummarySeries,
  resourceRequest = {},
  scale,
  startDate,
  totalCost,
  totalHours,
  totalScheduleHours,
  hideCost
}) => {
  const { displayUnit } = useDisplayUnitContext();

  const summary = useMemo(
    () =>
      resourceAvailabilitySummarySeries?.length
        ? resourceAvailabilitySummarySeries.find(
            r => r.dateRange.startDate === startDate.toISO()
          )
        : [],
    [resourceAvailabilitySummarySeries, startDate]
  );

  const handleClickAndKeyPress = useCallback(
    event => {
      if (
        (event.type === 'click' ||
          event.key === 'Enter' ||
          event.keyCode === 13) &&
        isEditable
      ) {
        handleAllocationPeriodClick({
          event,
          startDate,
          endDate,
          overlayPeriods: requestPeriods,
          allocation,
          isOverlay: true,
          totalAllocatedHours: totalHours,
          totalScheduledHours: totalScheduleHours,
          resourceAvailabilitySummary: summary,
          requestPeriods
        });
      }
    },
    [
      handleAllocationPeriodClick,
      startDate,
      endDate,
      allocation,
      isEditable,
      summary,
      totalHours,
      totalScheduleHours,
      requestPeriods
    ]
  );

  const overlayClasses = useStyles({ classes: classesOverride });

  const showOverlayBlockLabel =
    displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE &&
    allocationPeriodOverlapType !== ALLOCATION_PERIOD_OVERLAP_TYPE.NONE;

  return (
    <ResourceRequestUserAllocationPeriodOverlayTooltip
      showTooltip={showOverlayBlockLabel}
      totalHours={totalHours}
      totalCost={totalCost}
      startDate={startDate}
      endDate={endDate}
      percentage={percentage}
      resourceRequest={resourceRequest}
      hideCost={hideCost}
    >
      <div
        data-qe-id={`${allocation.user.user.displayText}_resourceRequestAllocationOverlay`}
        className={classNames({
          resourceRequestAllocationOverlay: true,
          [overlayClasses.overlay]: isEditable,
          [overlayClasses.overlayLabelVisible]: showOverlayBlockLabel,
          [overlayClasses.overlayBorder]:
            showOverlayBlockLabel &&
            allocationPeriodOverlapType ===
              ALLOCATION_PERIOD_OVERLAP_TYPE.PARTIAL
        })}
        style={dynamicPosition}
        onClick={isEditable ? handleClickAndKeyPress : null}
        role="button"
        tabIndex="0"
        onKeyPress={isEditable ? handleClickAndKeyPress : null}
        aria-hidden="true"
      >
        {showOverlayBlockLabel && (
          <OverlayBlockLabel
            hours={totalHours}
            percentage={percentage}
            showPercentage={totalScheduleHours > 0}
            scale={scale}
          />
        )}
      </div>
    </ResourceRequestUserAllocationPeriodOverlayTooltip>
  );
};

ResourceRequestUserAllocationPeriodOverlay.propTypes = {
  allocation: PropTypes.object,
  allocationPeriodOverlapType: PropTypes.oneOf([
    ALLOCATION_PERIOD_OVERLAP_TYPE.FULL,
    ALLOCATION_PERIOD_OVERLAP_TYPE.PARTIAL,
    ALLOCATION_PERIOD_OVERLAP_TYPE.NONE
  ]),
  dynamicPosition: PropTypes.object,
  endDate: PropTypes.object,
  handleAllocationPeriodClick: PropTypes.func,
  isEditable: PropTypes.bool,
  percentage: PropTypes.number,
  requestPeriods: PropTypes.array,
  resourceAvailabilitySummarySeries: PropTypes.array,
  resourceRequest: PropTypes.object,
  scale: PropTypes.string,
  startDate: PropTypes.object,
  totalHours: PropTypes.number,
  totalCost: PropTypes.object,
  totalScheduleHours: PropTypes.number,
  classes: PropTypes.object,
  hideCost: PropTypes.bool
};

export default ResourceRequestUserAllocationPeriodOverlay;
