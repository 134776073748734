import React from 'react';
import PropTypes from 'prop-types';
import TagsEditForm from '../TagsEditForm/TagsEditForm';

const TagsCardContentEditable = ({ values, errors }) => (
  <TagsEditForm values={values} errors={errors} />
);

TagsCardContentEditable.propTypes = {
  values: PropTypes.shape({
    tags: PropTypes.array
  }),
  errors: PropTypes.shape({
    tags: PropTypes.array
  })
};

export default TagsCardContentEditable;
