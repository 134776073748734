import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Checkbox,
  makeStyles
} from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import { LoadingButton } from '~/modules/common/components';
import {
  useReferenceNumberSettingFormState,
  useUpdateReferenceNumberSetting,
  useReferenceNumberSetting
} from '~/modules/billing-invoicing/common/hooks';
import { useFormOnChange } from './useFormOnChange';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const DEFAULT_REFERENCE_OPTION = {
  NO_DEFAULT: false,
  AUTO_GENERATED: true
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 3)
  },
  gridItem: {
    padding: theme.spacing(0.5)
  }
}));

const RadioButton = <Radio size="small" color="primary" />;
const getResources = (intl, displayText) => {
  return {
    title: intl.formatMessage({ id: 'referenceNumber.title' }),
    defaultInfo: intl.formatMessage(
      { id: 'referenceNumber.defaultInfo' },
      { displayText }
    ),
    prefix: intl.formatMessage({ id: 'referenceNumber.prefix' }),
    nextNumber: intl.formatMessage({ id: 'referenceNumber.nextNumber' }),
    noDefaultReference: intl.formatMessage({
      id: 'referenceNumber.noDefaultReference'
    }),
    autoGenerateReference: intl.formatMessage({
      id: 'referenceNumber.autoGenerateReference'
    }),
    allowEditReference: intl.formatMessage({
      id: 'referenceNumber.allowEditReference'
    })
  };
};

export const ReferenceNumberSettingDialog = ({
  open,
  onClose,
  displayText,
  transactionType,
  onReferenceNumberChange
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { referenceNumberSetting } = useReferenceNumberSetting(transactionType);
  const { updateReferenceNumberSetting } = useUpdateReferenceNumberSetting(
    transactionType
  );
  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useReferenceNumberSettingFormState({
    transactionType,
    updateReferenceNumberSetting,
    referenceNumberSetting,
    onReferenceSettingClose: onClose,
    onReferenceNumberChange
  });
  const {
    autoGeneratedNumber,
    editAutoGeneratedNumber,
    nextNumber,
    prefix
  } = values;
  const {
    onOptionChange,
    onPrefixChange,
    onNextNumberChange,
    onEditAutoGeneratedNumberChange
  } = useFormOnChange({ setFieldValue });
  const onCancel = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);
  const CheckBoxControl = useMemo(
    () => (
      <Checkbox
        checked={editAutoGeneratedNumber}
        onChange={onEditAutoGeneratedNumberChange}
        data-qe-id="reference-settings-allow-edit-reference"
        color="primary"
        size="small"
      />
    ),
    [editAutoGeneratedNumber, onEditAutoGeneratedNumberChange]
  );

  const resources = getResources(intl, displayText);

  const { prefixError, nextNumberError } = useMemo(() => {
    return {
      prefixError: get(errors, 'prefix', null),
      nextNumberError: get(errors, 'nextNumber', null)
    };
  }, [errors]);
  const { formatMessage } = useIntl();
  const label = useMemo(
    () => ({
      prefix: formatMessage({ id: 'referenceNumber.prefix' }),
      nextNumber: formatMessage({
        id: 'referenceNumber.nextNumber'
      })
    }),
    [formatMessage]
  );

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>{resources.title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary" variant="body2">
          {resources.defaultInfo}
        </DialogContentText>
        <FormControl component="fieldset">
          <RadioGroup
            name="defaultReferenceOption"
            value={autoGeneratedNumber}
            onChange={onOptionChange}
          >
            <FormControlLabel
              value={DEFAULT_REFERENCE_OPTION.NO_DEFAULT}
              control={RadioButton}
              label={resources.noDefaultReference}
            />
            <FormControlLabel
              value={DEFAULT_REFERENCE_OPTION.AUTO_GENERATED}
              control={RadioButton}
              label={resources.autoGenerateReference}
            />
          </RadioGroup>
        </FormControl>
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              variant="filled"
              label={resources.prefix}
              data-qe-id="reference-settings-prefix"
              value={prefix}
              onChange={onPrefixChange}
              error={Boolean(prefixError)}
              helperText={prefixError}
              disabled={!autoGeneratedNumber}
              inputProps={getAriaLabel(label.prefix)}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              variant="filled"
              required
              label={resources.nextNumber}
              data-qe-id="reference-settings-suffix"
              value={nextNumber}
              onChange={onNextNumberChange}
              error={Boolean(nextNumberError)}
              helperText={nextNumberError}
              disabled={!autoGeneratedNumber}
              inputProps={getAriaLabel(label.nextNumber)}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <FormControlLabel
              disabled={!autoGeneratedNumber}
              control={CheckBoxControl}
              label={resources.allowEditReference}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          data-qe-id="reference-settings-cancel"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          data-qe-id="reference-settings-save"
          color="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          autoFocus
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
ReferenceNumberSettingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  displayText: PropTypes.string.isRequired,
  transactionType: PropTypes.string.isRequired,
  onReferenceNumberChange: PropTypes.func.isRequired
};
export default ReferenceNumberSettingDialog;
