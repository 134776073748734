import { PropTypes } from 'prop-types';
import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';
import get from 'lodash.get';
import { Money2, Decimal } from '~/modules/common/components';
import ExchangeRate from './ExchangeRate';

const useStyles = makeStyles(theme => ({
  currencyRoot: {
    fontSize: theme.typography.body2.fontSize
  },
  adornmentRoot: {
    marginTop: '0px !important'
  },
  amountField: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    padding: theme.spacing(0.5) + 1
  }
}));

export const EditableAmountWithExchangeRate = ({
  field,
  record,
  index,
  column: {
    id,
    onAllocatedAmountChange,
    onAllocatedAmountBlur,
    targetFieldName,
    errors = {},
    errorKey,
    ariaLabel,
    buttonAriaLabel,
    isSubmitting
  }
}) => {
  const classes = useStyles();

  const amountStyles = useMemo(
    () => ({
      adornmentRoot: classes.adornmentRoot,
      currencyRoot: classes.currencyRoot
    }),
    [classes.adornmentRoot, classes.currencyRoot]
  );

  const sourceField = record[field];
  const targetField = record[targetFieldName];

  const editable =
    sourceField.currency.id !== targetField.currency.id &&
    targetField.amount > 0;

  const [editMode, setEditMode] = useState(false);

  const handleEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const allocatedAmountChangeHandler = useCallback(
    e => onAllocatedAmountChange(e, record),
    [onAllocatedAmountChange, record]
  );

  const allocatedAmountBlurHandler = useCallback(
    value => onAllocatedAmountBlur(value, record),
    [onAllocatedAmountBlur, record]
  );

  const fieldError = useMemo(
    () => get(errors, `${errorKey}.[${index}].${id}`),
    [errorKey, errors, id, index]
  );

  const inputRef = useRef();

  useEffect(() => {
    if (isSubmitting && fieldError && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, fieldError, isSubmitting]);

  return (
    <>
      {editable && !editMode && (
        <IconButton aria-label={buttonAriaLabel} onClick={handleEditMode}>
          <EditIcon />
        </IconButton>
      )}
      {!editMode && targetField.amount > 0 && (
        <Decimal
          value={record[field].amount}
          prefix={`${record[field].currency.symbol} `}
        />
      )}
      {editMode && targetField.amount > 0 && (
        <Money2
          classes={amountStyles}
          className={classes.amountField}
          variant="filled"
          ariaLabel={ariaLabel}
          inputRef={inputRef}
          amount={record[field].amount}
          currency={record[field].currency}
          currencyEditable={false}
          error={Boolean(fieldError)}
          helperText={fieldError}
          onAmountChange={
            onAllocatedAmountChange ? allocatedAmountChangeHandler : undefined
          }
          onAmountBlur={
            onAllocatedAmountBlur ? allocatedAmountBlurHandler : undefined
          }
        />
      )}
      {Boolean(editable && !fieldError) && (
        <ExchangeRate sourceAmount={sourceField} targetAmount={targetField} />
      )}
    </>
  );
};

EditableAmountWithExchangeRate.propTypes = {
  field: PropTypes.string.isRequired,
  column: PropTypes.object,
  record: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default EditableAmountWithExchangeRate;
