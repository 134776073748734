import {
  IconButton,
  Menu,
  MenuItem,
  DialogContentText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDialogState } from '~/modules/common/hooks';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { useOpenBill } from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/';
import { VoidBillConfirmationDialog } from './VoidBillConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2, 0, 1)
  }
}));

const resourceKeys = {
  header: 'billDetails.revertToDraft',
  actionButton: 'billDetails.revertToDraft'
};

export const IssuedBillMenu = ({
  billId,
  canVoidBill,
  canReopenBill,
  refetchAvailableToBillTotals,
  summarizeColumnOptions
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const onClose = useCallback(() => setAnchorEl(null), []);
  const [isConvertingToDraft, setIsConvertingToDraft] = useState(false);
  const { openBill } = useOpenBill({
    billId,
    summarizeColumnOptions
  });

  const {
    open: dialogOpen,
    openDialog: onMenuClick,
    closeDialog: onDialogClose
  } = useDialogState(false);

  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const onBillRevertToDraft = useCallback(async () => {
    setIsConvertingToDraft(true);
    await openBill();
    setIsConvertingToDraft(false);
    closeRemoveDialog();
  }, [closeRemoveDialog, openBill]);

  const handleVoidBillClick = useCallback(() => {
    onClose();
    onMenuClick();
  }, [onClose, onMenuClick]);

  const handleRevertToDraftClick = useCallback(() => {
    openRemoveDialog();
    onClose();
  }, [onClose, openRemoveDialog]);

  const handleClick = useCallback(
    event => setAnchorEl(event.currentTarget),
    []
  );

  return (
    <>
      <IconButton
        onClick={handleClick}
        classes={classes}
        data-qe-id="issuedBillMenu"
        aria-hidden="true"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onClose}
      >
        {canReopenBill ? (
          <MenuItem key="revert-to-draft" onClick={handleRevertToDraftClick}>
            <FormattedMessage id="billDetails.revertToDraft" />
          </MenuItem>
        ) : null}
        {canVoidBill ? (
          <MenuItem key="void" onClick={handleVoidBillClick}>
            <FormattedMessage id="billDetails.void" />
          </MenuItem>
        ) : null}
      </Menu>
      {Boolean(dialogOpen) && (
        <VoidBillConfirmationDialog
          open={dialogOpen}
          onClose={onDialogClose}
          billId={billId}
          refetchAvailableToBillTotals={refetchAvailableToBillTotals}
        />
      )}
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={resourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onBillRevertToDraft}
          isRemoving={isConvertingToDraft}
          disabled={isConvertingToDraft}
        >
          <DialogContentText>
            <FormattedMessage id="billDetails.revertDescription" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

IssuedBillMenu.propTypes = {
  billId: PropTypes.string,
  canVoidBill: PropTypes.bool,
  canReopenBill: PropTypes.bool,
  refetchAvailableToBillTotals: PropTypes.func,
  summarizeColumnOptions: PropTypes.any
};

export default IssuedBillMenu;
