import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  missingEstimateContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.4),
    padding: theme.spacing(0.25, 1.125, 0.25, 0.875),
    backgroundColor: '#ebebeb'
  },
  variationIcon: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(0.25)
  },
  label: {
    color: theme.palette.text.main,
    fontSize: theme.spacing(1.25)
  }
}));

export const TaskCardMissingEstimate = () => {
  const classes = useStyles();

  return (
    <div className={classes.missingEstimateContainer}>
      <ReportProblemOutlined className={classes.variationIcon} />
      <span className={classes.label}>
        <FormattedMessage id="projectTasksPage.estimateMissing" />
      </span>
    </div>
  );
};

export default TaskCardMissingEstimate;
