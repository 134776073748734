import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import EditCard from '~/modules/common/components/EditableCard/EditCard';
import ReadOnlySection from './ReadOnlySection';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(0, -2, -2, -2)
  }
}));

const onSave = (baseOnSave, onStopEdit) => () => {
  baseOnSave();
  onStopEdit();
};

const onCancel = (baseOnCancel, onStopEdit) => () => {
  baseOnCancel();
  onStopEdit();
};

const EditableSection = ({
  editable,
  dataQeId,
  className,
  children,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });

  const [editing, setEditing] = useState(false);
  const onEdit = useCallback(() => setEditing(true), [setEditing]);

  const onStopEdit = useCallback(() => setEditing(false), [setEditing]);

  return (
    <div className={classNames(className, classes.card)} data-qe-id={dataQeId}>
      {React.Children.toArray(children)
        .filter(child => child.type.displayName === 'ReadOnly')
        .map(child => (
          <ReadOnlySection
            key={child.type.displayName}
            editable={editable}
            onEdit={onEdit}
            {...child.props}
          >
            {child.props.children}
          </ReadOnlySection>
        ))}
      {React.Children.toArray(children)
        .filter(child => child.type.displayName === 'Edit')
        .map(child => (
          <EditCard
            key={child.type.displayName}
            open={editing}
            saveable={child.props.saveable}
            onSave={onSave(child.props.onSave, onStopEdit)}
            onCancel={onCancel(child.props.onCancel, onStopEdit)}
          >
            {child.props.children}
          </EditCard>
        ))}
    </div>
  );
};

EditableSection.propTypes = {
  editable: PropTypes.bool,
  dataQeId: PropTypes.string,
  className: PropTypes.string,
  editing: PropTypes.bool,
  onEdit: PropTypes.func,
  onStopEdit: PropTypes.func,
  children: PropTypes.node,
  classes: PropTypes.object
};

export default EditableSection;
