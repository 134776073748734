import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UncheckAllIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 8,4 V 6 L 17.998046875,6.001953125 18,16 h 2 V 4 Z M 4,20 H 16 V 8 H 4 Z m 2,-10 7.998046875,0.001953125 v 8 L 6,18 Z" />
    </>
  </SvgIcon>
));

export default UncheckAllIcon;
