import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { VictoryBar, VictoryGroup, VictoryAxis, VictoryLabel } from 'victory';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import { useRevenueForecastSummary } from '~/modules/projects/project/RevenueRecognition/hooks';
import { useChartStyles, useChartInputProps } from './hooks';
import { TotalForecastKeyValues, RevenueKeyValues } from './components';

const useStyles = makeStyles(theme => ({
  totalForecastKeyValues: {
    marginBottom: theme.spacing(1)
  }
}));

const labelComponent = <VictoryLabel dx={-60} />;

export const CloseMonthRevenueChart = ({
  totalForecastedAmount,
  projectUri,
  startDate,
  endDate,
  asOfCloseDate,
  recognizedRevenueSummary
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { forecastSummary } = useRevenueForecastSummary({
    projectUri,
    dateRange: {
      startDate: startDate
        ? {
            day: 1,
            month: startDate.month,
            year: startDate.year
          }
        : startDate,
      endDate: asOfCloseDate
    }
  });
  const {
    xTickFormat,
    tickValues,
    chartData,
    highestValue
  } = useChartInputProps({
    intl,
    data: {
      totalForecastedAmount,
      forecastedAmount: forecastSummary || { amount: 0 },
      recognizedAmount: recognizedRevenueSummary || { amount: 0 }
    },
    hiddenTickValues: [
      forecastSummary && forecastSummary.amount,
      recognizedRevenueSummary && recognizedRevenueSummary.amount
    ]
  });
  const chartStyles = useChartStyles(
    intl,
    useTheme(),
    highestValue,
    totalForecastedAmount.currency.displayText
  );

  return (
    <>
      {totalForecastedAmount?.amount > 0 ||
      recognizedRevenueSummary?.amount > 0 ? (
        <>
          <VictoryGroup
            height={chartStyles.group.height}
            horizontal
            padding={chartStyles.group.padding}
            offset={chartStyles.group.offset}
            colorScale={chartStyles.group.colorScale}
          >
            <VictoryBar
              barWidth={chartStyles.totalForecasted.barWidth}
              style={chartStyles.totalForecasted.style}
              cornerRadius={chartStyles.totalForecasted.cornerRadius}
              data={chartData.totalForecasted.data}
            />
            <VictoryBar
              barWidth={chartStyles.forecasted.barWidth}
              style={chartStyles.forecasted.style}
              cornerRadius={chartStyles.forecasted.cornerRadius}
              data={chartData.forecasted.data}
            />
            <VictoryBar
              barWidth={chartStyles.recognized.barWidth}
              style={chartStyles.recognized.style}
              cornerRadius={chartStyles.recognized.cornerRadius}
              data={chartData.recognized.data}
              labels={chartData.recognized.label}
              labelComponent={labelComponent}
            />
          </VictoryGroup>
          <VictoryAxis
            height={chartStyles.axis.height}
            style={chartStyles.axis.style}
            padding={chartStyles.axis.padding}
            offsetY={chartStyles.axis.offsetY}
            tickValues={tickValues}
            tickFormat={xTickFormat}
          />
        </>
      ) : null}
      <div className={classes.totalForecastKeyValues}>
        <TotalForecastKeyValues
          startDate={startDate}
          endDate={endDate}
          totalForecastedAmount={totalForecastedAmount}
        />
      </div>
      <RevenueKeyValues
        asOfCloseDate={asOfCloseDate}
        forecastedAmount={forecastSummary}
        recognizedAmount={recognizedRevenueSummary}
      />
    </>
  );
};
CloseMonthRevenueChart.propTypes = {
  totalForecastedAmount: PropTypes.object.isRequired,
  projectUri: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  asOfCloseDate: PropTypes.object.isRequired,
  recognizedRevenueSummary: PropTypes.object
};
export default CloseMonthRevenueChart;
