import { useApolloClient } from '@apollo/client';
import { GET_PROJECTS_QUERY } from '~/modules/common/hooks/useSearchableProjects';

export const searchableProjects = ({
  page,
  pageSize,
  client,
  dataAccessLevels
}) => async searchTerm => {
  const result = await client.query({
    query: GET_PROJECTS_QUERY,
    variables: {
      page,
      pageSize,
      searchTerm,
      dataAccessLevels
    }
  });

  return result.data.getPageOfProjectsFilteredByDataAccessLevels.map(
    project => ({
      ...project,
      value: project.id,
      label: project.displayText,
      key: project.displayText
    })
  );
};

export const useSearchableProjectOptions = ({
  page,
  pageSize,
  dataAccessLevels
}) => {
  const apolloClient = useApolloClient();

  return {
    fetchOptions: searchableProjects({
      page: page || 1,
      pageSize: pageSize || 100,
      client: apolloClient,
      dataAccessLevels: dataAccessLevels || [
        'urn:replicon:project-data-access-level:all'
      ]
    })
  };
};

export default useSearchableProjectOptions;
