import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Popover,
  Button,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';
import { useIsRTL, useIsBreakpointDown } from '~/modules/common/hooks';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ListTable } from '~/modules/common/components/ListTable';

import { getVariationDetails } from '../../useCalculateVariationDetails';
import { useListColumns } from './useListColumns';
import { useListHeaders } from './useListHeaders';

const useStyles = makeStyles({
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 0, 1.25, 0),
    backgroundColor: theme.palette.grey[50]
  },
  showMore: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0.75, 0, 0.5, 0)
  }
});

const useListTableStyles = makeStyles({
  card: ({ isMobile }) => ({
    padding: theme.spacing(0.5, 1, 0, 1),
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
      paddingRight: isMobile && 0
    }
  })
});

const popoverProps = {
  anchor: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transform: {
    vertical: 'top',
    horizontal: 'right'
  }
};

const getPrefix = variationStatus => {
  return variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.OVER
    ? '+ '
    : variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.UNDER
    ? '- '
    : '';
};

const mapChildTasks = ({ children }) =>
  children.map(child => {
    const {
      variationDays,
      variationStatus,
      variationHours,
      variationCost,
      costVariationStatus
    } = getVariationDetails({
      task: child
    });

    return {
      ...child,
      ...child.rolledUpSummary,
      actualCost: child.rolledUpCostSummary?.actualCostInProjectCurrency,
      month: child.period?.start,
      estimatedExpenses: child.estimatedExpensesInProjectCurrency,
      totalEstimatedRemainingCost:
        child.rolledUpCostSummary?.totalEstimatedRemainingCostInProjectCurrency,
      totalEstimatedAtCompletionCost:
        child.rolledUpCostSummary
          ?.totalEstimatedAtCompletionCostInProjectCurrency,
      dateVariation: variationDays,
      hoursVariation: variationHours,
      costVariation: variationCost?.amount,
      prefix: getPrefix(variationStatus),
      costPrefix: `${getPrefix(costVariationStatus)} ${
        variationCost?.currency.symbol
      } `
    };
  });

const ChildInfoDialog = ({
  childTasks = [],
  selectedColumns,
  open,
  onClose,
  anchorEl,
  hasMore,
  loadMore,
  loadingMore
}) => {
  const classes = useStyles();
  const isRTL = useIsRTL();
  const isMobile = useIsBreakpointDown('md');
  const listTableClasses = useListTableStyles({ isMobile });

  const formattedChildTasks = useMemo(
    () => mapChildTasks({ children: childTasks }),
    [childTasks]
  );

  const columns = useListColumns({ selectedColumns, isRTL });
  const headers = useListHeaders({ selectedColumns });

  return (
    <Popover
      anchorOrigin={popoverProps.anchor}
      transformOrigin={popoverProps.transform}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <ListTable
        variant="table"
        classes={listTableClasses}
        records={formattedChildTasks}
        headers={headers}
        columns={columns}
        elevation={0}
      />
      {loadingMore && (
        <div className={classes.loadingIndicator}>
          <CircularProgress size={20} />
        </div>
      )}
      {!loadingMore && hasMore && (
        <div className={classes.showMore}>
          <Button component="li" button onClick={loadMore}>
            <FormattedMessage id="taskDrawer.showMore" />
          </Button>
        </div>
      )}
    </Popover>
  );
};

ChildInfoDialog.propTypes = {
  childTasks: PropTypes.array,
  selectedColumns: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired
};

export default ChildInfoDialog;
