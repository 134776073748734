import { DateTime } from 'luxon';

const resolveDateTime = dateTime => dateTime.toLocaleString(DateTime.DATE_MED);

export const useDateRangeFacetHandlers = ({ setSelected }) => ({
  onDateChange: (startDate, endDate) => {
    const startDateValue = startDate ? resolveDateTime(startDate) : '';
    const endDateValue = endDate ? resolveDateTime(endDate) : '';

    setSelected(
      startDate || endDate
        ? [
            {
              range: {
                startDate,
                endDate
              },
              key: `(${startDateValue} - ${endDateValue})`
            }
          ]
        : []
    );
  }
});

export default useDateRangeFacetHandlers;
