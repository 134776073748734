import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useResourceAllocationTotal } from '~/modules/resourcing/hooks';
import useMeContext from '~/modules/me/useMeContext';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';
import EntityAvatar from '~/modules/common/components/Avatars/EntityAvatar';
import { ResourceRequestIcon } from '~/modules/common/components';
import {
  MobileResourceAllocationTitle,
  ResourceAllocationCardCost,
  ResourceAllocationCardHours
} from './components';
import useStyles, {
  topContainerStyles,
  bottomContainerStyles,
  spacingItemStyles,
  statusItemStyles,
  roleItemStyles
} from './useStyles';

export const ResourceAllocationCard = ({
  contextCurrency,
  resourceAllocation
}) => {
  const classes = useStyles();
  const topContainerClasses = topContainerStyles();
  const bottomContainerClasses = bottomContainerStyles();
  const spacingItemClasses = spacingItemStyles();
  const statusItemClases = statusItemStyles();
  const roleItemClasses = roleItemStyles();
  const me = useMeContext();

  const {
    resourceAllocationHours,
    resourceAllocationCost
  } = useResourceAllocationTotal({
    me,
    contextCurrency,
    ...resourceAllocation
  });

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid container classes={topContainerClasses} item>
        <Grid item classes={roleItemClasses}>
          {resourceAllocation.user && (
            <UserAvatar
              user={resourceAllocation.user.user}
              className={classes.resourceAvatar}
            />
          )}
          {!resourceAllocation.user && (
            <EntityAvatar className={classes.resquestAvatar}>
              <ResourceRequestIcon />
            </EntityAvatar>
          )}
          <MobileResourceAllocationTitle
            resourceAllocation={resourceAllocation}
          />
        </Grid>
        <Grid item classes={statusItemClases}></Grid>
      </Grid>
      <Grid container item classes={bottomContainerClasses}>
        <Grid item>
          <ResourceAllocationCardHours
            allocatedHours={resourceAllocationHours}
          />
        </Grid>
        <Grid item classes={spacingItemClasses}>
          <ResourceAllocationCardCost
            resourceAllocatedCost={resourceAllocationCost}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ResourceAllocationCard.propTypes = {
  contextCurrency: PropTypes.object,
  resourceAllocation: PropTypes.object
};

export default ResourceAllocationCard;
