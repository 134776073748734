import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import { DateField } from '~/modules/common/components';
import { useErrors } from '../../hooks';
import AdvancedRateCardSkeleton from './AdvancedRateCardSkeleton';
import DateReadOnly from './DateReadOnly';

const useStyles = makeStyles(theme => ({
  initialRate: ({ readOnly }) => ({
    color: theme.palette.text.secondary,
    paddingTop: readOnly ? theme.spacing(0) : theme.spacing(1.75),
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  })
}));

const useDateStyles = makeStyles(theme => ({
  input: {
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75)
    }
  }
}));

export const DateEditor = ({
  record,
  field,
  index,
  column: { onEffectiveDateChange, errors },
  readOnly,
  loadingMore
}) => {
  const dateClasses = useDateStyles();
  const { hasError, helperText } = useErrors({
    errors,
    index,
    key: field
  });

  const classes = useStyles({ readOnly });
  const { formatMessage } = useIntl();

  const value = record[field];
  const { isInitialRate, disabled, isDimensionDeleted } = record;

  const onChange = useCallback(
    date => {
      if (onEffectiveDateChange) {
        onEffectiveDateChange(index, date);
      }
    },
    [index, onEffectiveDateChange]
  );

  if (loadingMore) return <AdvancedRateCardSkeleton />;

  return (
    <>
      {isInitialRate ? (
        <Typography
          component="div"
          className={classes.initialRate}
          variant="body2"
          data-qe-id="InitialRate"
          tabIndex={0}
        >
          <FormattedMessage id="advancedRateCard.initialRate" />
        </Typography>
      ) : null}
      {readOnly && !isInitialRate ? (
        <DateReadOnly value={value} />
      ) : !isInitialRate ? (
        <DateField
          classes={dateClasses}
          data-qe-id="EffectiveDate"
          ariaLabel={formatMessage({
            id: 'advancedRateCard.effectiveDate'
          })}
          onChange={onChange}
          editable
          clearable={false}
          value={value}
          error={hasError}
          helperText={helperText}
          label={
            !value
              ? formatMessage({ id: 'advancedRateCard.effectiveDate' })
              : undefined
          }
          disabled={disabled || isDimensionDeleted}
        />
      ) : null}
    </>
  );
};

DateEditor.propTypes = {
  record: PropTypes.object,
  field: PropTypes.string,
  column: PropTypes.object,
  index: PropTypes.number,
  readOnly: PropTypes.bool,
  loadingMore: PropTypes.bool
};
