import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100]
  },
  error: {
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`
  },
  rejected: {
    borderLeft: `${theme.spacing(0.5)}px solid ${
      theme.palette.resourceRequest.requestRejected.main
    }`
  },
  proposed: {
    borderLeft: `${theme.spacing(0.5)}px solid ${
      theme.palette.resourceRequest.proposed.main
    }`
  },
  draft: {
    borderLeft: `${theme.spacing(0.5)}px solid ${
      theme.palette.resourceRequest.draft.main
    }`
  }
}));

export const ProjectRowLoading = ({ color = 'error' }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, classes[color])}>
      <Skeleton width="100%" height={24} />
    </div>
  );
};

ProjectRowLoading.propTypes = {
  color: PropTypes.oneOf(['error', 'proposed', 'draft', 'rejected'])
};

export default ProjectRowLoading;
