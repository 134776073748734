import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

const sum = paymentCurrencyUri => (retVal, item) =>
  item &&
  item.amount &&
  item.amount.currency &&
  item.amount.currency.id === paymentCurrencyUri
    ? retVal + item.amount.amount
    : retVal;

export const useBillPaymentTotalProps = ({ values }) => {
  const { currency, paymentAmount = 0, associatedBills = [] } = values;
  const { id: paymentCurrencyUri } = currency;
  const associatedBillsTotal = getRoundedValue(
    associatedBills.reduce(sum(paymentCurrencyUri), 0)
  );

  return {
    associatedBillsTotal: {
      amount: associatedBillsTotal,
      currency
    },
    billPaymentBalanceTotal: {
      amount: getRoundedValue(paymentAmount - associatedBillsTotal),
      currency
    }
  };
};

export default useBillPaymentTotalProps;
