import { useCallback, useMemo } from 'react';

const useIsTagOptionDisabled = (selectedValues = [], optionIdKey) => {
  const selectedValueSet = useMemo(
    () => new Set(selectedValues.map(v => (optionIdKey ? v[optionIdKey] : v))),
    [optionIdKey, selectedValues]
  );

  const isTagOptionDisabled = useCallback(
    option => selectedValueSet.has(option.value),
    [selectedValueSet]
  );

  return isTagOptionDisabled;
};

export default useIsTagOptionDisabled;
