import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';

import { Tooltip } from '@material-ui/core';
import { Decimal } from '~/modules/common/components';

const BudgetHoursChartLegendTooltip = ({ value, suffix, dataQeId }) => {
  const tooltipContent = useMemo(
    () => <Decimal value={value} suffix={suffix} />,
    [value, suffix]
  );

  return (
    <Tooltip title={tooltipContent}>
      <span>
        <Decimal
          value={value}
          suffix={suffix && `${suffix}`}
          dataQeId={dataQeId}
          fixedDecimalScale
        />
      </span>
    </Tooltip>
  );
};

BudgetHoursChartLegendTooltip.propTypes = {
  value: PropTypes.number,
  suffix: PropTypes.string,
  dataQeId: PropTypes.string
};

export default BudgetHoursChartLegendTooltip;
