import { useCallback } from 'react';

export const usePutCustomLineItemFormChange = ({ setFieldValue }) => {
  return {
    onDescriptionChange: useCallback(
      e => {
        setFieldValue('description', e.target.value);
      },
      [setFieldValue]
    ),
    onProjectChange: useCallback(
      (_, value) => {
        setFieldValue('project', value);
      },
      [setFieldValue]
    ),
    onUnitChange: useCallback(
      e => {
        setFieldValue('unit', e.target.value);
      },
      [setFieldValue]
    ),
    onLabelChange: useCallback(
      e => {
        setFieldValue('label', e.target.value);
      },
      [setFieldValue]
    ),
    onAmountChange: useCallback(
      e => {
        setFieldValue('amount', e.target.value);
      },
      [setFieldValue]
    ),
    onRateChange: useCallback(
      e => {
        setFieldValue('rate', e.target.value);
      },
      [setFieldValue]
    )
  };
};

export default usePutCustomLineItemFormChange;
