import { useCallback } from 'react';
import {
  onAssociatedBillAmountChange,
  onAllocatedBillAmountChange,
  onClientChange,
  onDescriptionChange,
  onDisplayIdChange,
  onProjectLineItemAdd,
  getRoundedValue
} from '~/modules//billing-invoicing/common/enhancers';

export const onCreditDateChange = ({
  setFieldValue,
  values: { associatedBills, currency }
}) => creditDate => {
  setFieldValue(
    'associatedBills',
    resetAssociations(associatedBills, currency)
  );
  setFieldValue('creditDate', creditDate);
};

const resetAssociations = (associatedBills, currency) =>
  associatedBills.map(bill => ({
    ...bill,
    amount: { amount: 0, currency }
  }));

export const onCreditCurrencyChange = ({
  setFieldValue,
  values: { associatedBills, currency, lineItems, creditDate },
  fetchMoreExchangeRates
}) => value => {
  const newCurrency = { ...value, symbol: value.displayText };

  setFieldValue(
    'associatedBills',
    resetAssociations(associatedBills, newCurrency)
  );
  fetchMoreExchangeRates({
    variables: {
      currencyId: currency.id,
      otherCurrencyIds: [newCurrency.id],
      asOfDate: creditDate
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      setFieldValue(
        'lineItems',
        lineItems.map(lineItem => ({
          ...lineItem,
          amount: {
            currency: { ...newCurrency },
            amount:
              lineItem.amount.amount *
              fetchMoreResult.currency.exchangeRates[0].rate
          }
        }))
      );
    }
  });
  setFieldValue('currency', newCurrency);
};
export const onLineItemAmountChange = ({
  setFieldValue,
  values: { lineItems },
  creditMemoBalanceTotal
}) => (e, record) => {
  const newValue = e.target.value || 0;
  const { id, key } = record;
  const updatedLineItems = lineItems.map(lineItem =>
    (id && lineItem.id === id) || (key && lineItem.key === key)
      ? {
          ...lineItem,
          amount: { ...lineItem.amount, amount: newValue }
        }
      : lineItem
  );

  setFieldValue('lineItems', updatedLineItems);

  const currentAmount = lineItems.find(
    lineItem => (id && lineItem.id === id) || (key && lineItem.key === key)
  ).amount.amount;

  const balance =
    ((creditMemoBalanceTotal && creditMemoBalanceTotal.amount) || 0) -
    currentAmount +
    newValue;

  setFieldValue('creditMemoBalanceTotal', getRoundedValue(balance));
};

export const onDeleteLineItem = ({
  setFieldValue,
  values: { lineItems },
  creditMemoBalanceTotal
}) => record => {
  const { id, amount, key } = record;
  const updatedLineItems = lineItems.filter(
    item => item.key !== key || item.id !== id
  );
  const balance = creditMemoBalanceTotal.amount - amount.amount;

  setFieldValue('creditMemoBalanceTotal', getRoundedValue(balance));
  setFieldValue('lineItems', updatedLineItems);
};

export const useFormOnChange = ({
  setFieldValue,
  values,
  fetchMoreExchangeRates,
  creditMemoBalanceTotal,
  associatedBills,
  exchangeRates
}) => ({
  onClientChange: useCallback(
    async value => {
      await onClientChange({ setFieldValue, values })(value);
    },
    [setFieldValue, values]
  ),
  onDisplayIdChange: useCallback(
    event => {
      onDisplayIdChange({ setFieldValue })(event);
    },
    [setFieldValue]
  ),
  onDescriptionChange: useCallback(
    event => {
      onDescriptionChange({ setFieldValue })(event);
    },
    [setFieldValue]
  ),
  onCreditDateChange: useCallback(
    paymentDate => {
      onCreditDateChange({ setFieldValue, values })(paymentDate);
    },
    [setFieldValue, values]
  ),
  onLineItemAmountChange: useCallback(
    (event, record) => {
      onLineItemAmountChange({
        setFieldValue,
        values,
        creditMemoBalanceTotal
      })(event, record);
    },
    [setFieldValue, values, creditMemoBalanceTotal]
  ),
  onCreditCurrencyChange: useCallback(
    value => {
      onCreditCurrencyChange({
        setFieldValue,
        values,
        fetchMoreExchangeRates
      })(value);
    },
    [setFieldValue, values, fetchMoreExchangeRates]
  ),
  onProjectLineItemAdd: useCallback(
    selectedProject => {
      onProjectLineItemAdd({
        setFieldValue,
        lineItems: values.lineItems,
        itemCurrency: values.currency,
        selectedProject
      });
    },
    [setFieldValue, values]
  ),
  onDeleteLineItem: useCallback(
    record => {
      onDeleteLineItem({ setFieldValue, values, creditMemoBalanceTotal })(
        record
      );
    },
    [creditMemoBalanceTotal, setFieldValue, values]
  ),
  onAssociatedBillAmountInCreditMemoCurrencyChange: useCallback(
    (e, record) => {
      onAssociatedBillAmountChange({
        setFieldValue,
        associatedBills,
        newValue: e.target.value,
        record,
        currency: values.currency,
        exchangeRates,
        billCreditBalanceTotal: creditMemoBalanceTotal,
        balanceField: 'creditMemoBalanceTotal'
      });
    },
    [
      associatedBills,
      creditMemoBalanceTotal,
      exchangeRates,
      setFieldValue,
      values.currency
    ]
  ),

  onAssociatedBillAmountInBillCurrencyChange: useCallback(
    (e, record) => {
      onAllocatedBillAmountChange({
        setFieldValue,
        associatedBills,
        newValue: e.target.value,
        record
      });
    },
    [associatedBills, setFieldValue]
  )
});
