import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ClientContext } from './ClientContext';

export default function ClientContextProvider({ data, loading, children }) {
  const { client = {} } = data;

  const value = useMemo(
    () => ({
      client,
      isLoading: loading
    }),
    [client, loading]
  );

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
}

ClientContextProvider.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  loading: PropTypes.bool
};

export const withClientContextProvider = BaseComponent => props => (
  <ClientContextProvider>
    <BaseComponent {...props} />
  </ClientContextProvider>
);
