import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_PERIOD_SCALE } from '~/modules/common/charts/timeline';
import { getScaleWidth } from '~/modules/common/charts/timeline/calculations';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: theme.spacing(4.5)
  },
  leftContainer: {
    left: 0,
    display: 'flex',
    position: 'sticky',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25, 0, 0.25, 0.5)
  }
}));

export const UserTaskAssignmentFooterRow = ({
  classes: classesOverrides,
  leftComponent,
  leftComponentWidth,
  scale = DEFAULT_PERIOD_SCALE,
  scaleItemCount
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const rangeWidth = scaleItemCount * getScaleWidth(scale);
  const containerMinWidth = leftComponentWidth + rangeWidth;

  const minWidthStyle = useMemo(
    () => ({
      minWidth: containerMinWidth
    }),
    [containerMinWidth]
  );

  const leftContainerStyle = useMemo(
    () => ({
      flexBasis: leftComponentWidth,
      width: leftComponentWidth
    }),
    [leftComponentWidth]
  );

  return (
    <div className={classes.root} style={minWidthStyle}>
      <div className={classes.leftContainer} style={leftContainerStyle}>
        {leftComponent}
      </div>
    </div>
  );
};

UserTaskAssignmentFooterRow.propTypes = {
  classes: PropTypes.object,
  scale: PropTypes.string.isRequired,
  scaleItemCount: PropTypes.number.isRequired,
  leftComponent: PropTypes.node,
  leftComponentWidth: PropTypes.number
};

export default UserTaskAssignmentFooterRow;
