import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/HomeSharp';

const useStyles = makeStyles(theme => ({
  homeIcon: {
    marginRight: theme.spacing(1)
  }
}));

export const HomeButton = ({ onClick, to }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      replace
      to={to}
      onClick={onClick}
    >
      <HomeIcon className={classes.homeIcon} />{' '}
      <FormattedMessage id="commonError.backtoHome" />
    </Button>
  );
};

HomeButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string
};

export default HomeButton;
