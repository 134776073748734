import React, { useState, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResourceRequestStatus } from '~/types';
import { AssignmentDialogColumns } from '~/modules/resourcing/components/ResourceAssignmentDialog/enhancers/constants';

const ResourceAssignmentDialogContext = React.createContext();

export const ResourceAssignmentDialogContextProvider = ({
  children,
  enabledAssignmentDialogColumns,
  expandedResourceRequestId,
  maxSkillLevel,
  resourcePlanGroupBy,
  resourceRequest,
  setEnabledAssignmentDialogColumns,
  setExpandedResourceRequestId,
  assignmentDialogColumnConfiguration
}) => {
  const hasPendingAllocation =
    resourceRequest.requestStatus === ResourceRequestStatus.Submitted &&
    resourceRequest.resourceAllocations.length !== 0;
  const [searchCriteria, setSearchCriteria] = useState({
    keywords: [],
    criterions: {}
  });

  const [isPendingRequestEditable, setIsPendingRequestEditable] = useState(
    resourceRequest.requestStatus === ResourceRequestStatus.Submitted &&
      !hasPendingAllocation
  );

  const [selectedDialogOptions, setSelectedDialogOptions] = useState([]);

  const [
    availabilityFilterLoadValue,
    setAvailabilityFilterLoadValue
  ] = useState(0);

  const [
    isCostOptimizationSmartMatchModeEnabled,
    setIsCostOptimizationSmartMatchModeEnabled
  ] = useState(
    enabledAssignmentDialogColumns.includes(
      AssignmentDialogColumns.SmartMatchIncludingCost
    )
  );

  useEffect(() => {
    setIsPendingRequestEditable(
      resourceRequest.requestStatus === ResourceRequestStatus.Submitted &&
        !hasPendingAllocation
    );
  }, [resourceRequest.requestStatus, hasPendingAllocation]);

  const contextValue = useMemo(
    () => ({
      expandedResourceRequestId,
      setExpandedResourceRequestId,
      availabilityFilterLoadValue,
      setAvailabilityFilterLoadValue,
      resourcePlanGroupBy,
      searchCriteria,
      setSearchCriteria,
      isPendingRequestEditable,
      maxSkillLevel,
      setIsPendingRequestEditable,
      isCostOptimizationSmartMatchModeEnabled,
      setIsCostOptimizationSmartMatchModeEnabled,
      enabledAssignmentDialogColumns,
      setEnabledAssignmentDialogColumns,
      assignmentDialogColumnConfiguration,
      selectedDialogOptions,
      setSelectedDialogOptions
    }),
    [
      expandedResourceRequestId,
      availabilityFilterLoadValue,
      setAvailabilityFilterLoadValue,
      setExpandedResourceRequestId,
      resourcePlanGroupBy,
      searchCriteria,
      maxSkillLevel,
      setSearchCriteria,
      isPendingRequestEditable,
      setIsPendingRequestEditable,
      isCostOptimizationSmartMatchModeEnabled,
      setIsCostOptimizationSmartMatchModeEnabled,
      enabledAssignmentDialogColumns,
      setEnabledAssignmentDialogColumns,
      assignmentDialogColumnConfiguration,
      selectedDialogOptions,
      setSelectedDialogOptions
    ]
  );

  return (
    <ResourceAssignmentDialogContext.Provider value={contextValue}>
      {children}
    </ResourceAssignmentDialogContext.Provider>
  );
};

ResourceAssignmentDialogContextProvider.propTypes = {
  resourceRequest: PropTypes.object,
  expandedResourceRequestId: PropTypes.string,
  setExpandedResourceRequestId: PropTypes.func,
  resourcePlanGroupBy: PropTypes.string,
  children: PropTypes.node,
  maxSkillLevel: PropTypes.number,
  enabledAssignmentDialogColumns: PropTypes.array,
  setEnabledAssignmentDialogColumns: PropTypes.func,
  assignmentDialogColumnConfiguration: PropTypes.object
};

export const useResourceAssignmentDialogContext = () =>
  useContext(ResourceAssignmentDialogContext);
