import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  legends: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    justifyContent: 'space-between'
  }
}));

export const CalendarLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.legends}>
        <Skeleton width="20%" height={30} />
        <Skeleton width="30%" height={30} />
      </div>
      <Skeleton height={400} variant="rect" />
    </div>
  );
};

export default CalendarLoading;
