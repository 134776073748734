import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Decimal } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: { paddingBottom: '0px !important' },
  rootTotal: { paddingTop: '0px !important' },
  dateColumn: { color: theme.palette.text.secondary },
  amountColumn: {
    textAlign: 'right',
    fontWeight: 500
  },
  bold: { color: theme.palette.text.primary, fontWeight: 500 }
}));

export const CarryOverSummaryRow = ({
  endDate,
  isCurrentPeriod,
  amount,
  currencySymbol,
  isTotalsRow = false,
  type
}) => {
  const classes = useStyles();
  const messageId = useMemo(() => {
    if (isTotalsRow) return 'carryOverSummaryDialog.rowType.total';
    if (isCurrentPeriod) return 'carryOverSummaryDialog.rowType.current';
    if (endDate && type === 'CARRYOVER')
      return 'carryOverSummaryDialog.rowType.carryOver';
    if (endDate && type === 'DEFERRED')
      return 'carryOverSummaryDialog.rowType.deferred';

    return 'carryOverSummaryDialog.rowType.noDate';
  }, [endDate, isCurrentPeriod, isTotalsRow, type]);
  const values = useMemo(
    () => ({
      month:
        endDate &&
        endDate.toLocaleString({
          month: 'short',
          year: 'numeric'
        })
    }),
    [endDate]
  );

  return (
    <Grid
      container
      item
      spacing={2}
      className={classNames({ [classes.rootTotal]: isTotalsRow }, classes.root)}
    >
      <Grid item xs={6} className={classes.dateColumn}>
        <Typography
          variant="caption"
          className={classNames({ [classes.bold]: isTotalsRow })}
        >
          <FormattedMessage id={messageId} values={values} />
        </Typography>
      </Grid>
      <Grid item xs={6} className={classes.amountColumn}>
        <Decimal value={amount} prefix={`${currencySymbol} `} />
      </Grid>
    </Grid>
  );
};

CarryOverSummaryRow.propTypes = {
  endDate: PropTypes.object,
  amount: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  isCurrentPeriod: PropTypes.bool,
  isTotalsRow: PropTypes.bool,
  type: PropTypes.string
};

export default CarryOverSummaryRow;
