import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import NavigationMenuButton from '~/modules/navigation/components/NavigationMenuButton';

const useStyles = makeStyles(theme => ({
  root: {
    top: ({ level }) => level * theme.spacing(6),
    left: 0,
    right: 'auto',
    color: theme.palette.text.primary,
    minHeight: theme.spacing(6),
    borderBottom: ({ bottomBorder }) =>
      bottomBorder ? `1px solid ${theme.palette.divider}` : 'none',
    zIndex: ({ level = 0 }) => theme.zIndex.appBar - level
  },
  paddingRight: { paddingRight: theme.spacing(2) },
  toolbar: {
    minHeight: theme.spacing(6),
    display: ({ display }) => display,
    flexWrap: ({ flexWrap }) => flexWrap
  },
  title: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    flexGrow: ({ disableTitleFullWidth }) => (disableTitleFullWidth ? 0 : 1),
    ...theme.typography.h6,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));

export const StickyHeader = React.forwardRef((props, ref) => {
  const {
    dataQeId,
    showMenuButton,
    title,
    disableTitleFullWidth,
    bottomBorder,
    rightPadding,
    level = 0,
    display = 'flex',
    flexWrap = 'nowrap',
    classes: classesOverride,
    children
  } = props;
  const classes = useStyles({
    display,
    flexWrap,
    disableTitleFullWidth,
    bottomBorder,
    level,
    classes: classesOverride
  });

  return (
    <AppBar
      className={classNames(classes.root, {
        [classes.paddingRight]: rightPadding
      })}
      component="div"
      color="inherit"
      position="sticky"
      elevation={0}
      ref={ref}
    >
      <Toolbar className={classes.toolbar} disableGutters>
        {showMenuButton && <NavigationMenuButton />}
        {title && (
          <Typography
            data-qe-id={dataQeId && `${dataQeId}_StickyHeader_Title`}
            className={classes.title}
            component="h1"
            variant="h1"
          >
            {title}
          </Typography>
        )}
        {children}
      </Toolbar>
    </AppBar>
  );
});

StickyHeader.propTypes = {
  dataQeId: PropTypes.string,
  showMenuButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disableTitleFullWidth: PropTypes.bool,
  bottomBorder: PropTypes.bool,
  children: PropTypes.node,
  rightPadding: PropTypes.bool,
  level: PropTypes.number,
  display: PropTypes.string,
  flexWrap: PropTypes.string,
  classes: PropTypes.object
};

export default StickyHeader;
