import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { ResourceCostModeType } from '~/types';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';

const mapRole = role => ({
  displayText: role.name,
  id: role.uri
});

const mapSkill = skill =>
  skill.skillAssignments.map(sa => ({
    id: sa.id,
    category: skill.category,
    displayText: sa.displayText,
    skillLevel: sa.skillLevel
  }));

const setRoleRate = ({ setFieldValue, roleRate }) => {
  setFieldValue('roleRate', roleRate.amount);
  setFieldValue('currency', roleRate.currency);
};

export const resourceUserQuery = gql`
  query getResourceUser(
    $filter: GetResourceUsersFilter!
    $includeRoles: Boolean!
    $includeSkills: Boolean!
    $includeCurrentCostRate: Boolean!
    $includePrimaryRoleCostRate: Boolean!
    $today: Date!
  ) {
    resourceUsers2(filter: $filter) {
      id
      currentCostRate @include(if: $includeCurrentCostRate) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      roles @include(if: $includeRoles) {
        isPrimary
        projectRole {
          uri
          name
        }
      }
      resourceSkills @include(if: $includeSkills) {
        category {
          id
          uri
          displayText
        }
        skillAssignments {
          id
          uri
          displayText
          certificate
          skillLevel {
            id
            uri
            displayText
            rating
          }
        }
      }
      primaryRoleCostRate(date: $today)
        @include(if: $includePrimaryRoleCostRate) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

export const usePreferredResourceHandlers = ({
  role,
  roleRate,
  skills,
  setFieldValue,
  preferredResources,
  setSelectedOptions,
  setLoading,
  apolloClient,
  resourceCostMode,
  todayForUser,
  setKey
}) => {
  const isRoleCostMode = resourceCostMode === ResourceCostModeType.Rolecost;

  const onResourceSelected = useCallback(
    async resource => {
      if (!resource) return;
      const updatedPreferredResources = [...preferredResources, resource];

      setSelectedOptions(updatedPreferredResources);

      setKey(Math.random());

      setFieldValue('preferredResources', updatedPreferredResources);

      if (preferredResources.length || (role && skills.length && roleRate)) {
        return;
      }

      setLoading(true);

      const { data } = await apolloClient.query({
        query: resourceUserQuery,
        variables: {
          page: 1,
          pagesize: 1,
          filter: {
            userIds: [resource.id]
          },
          includeRoles: !role,
          includeSkills: Boolean(!skills.length),
          includeCurrentCostRate: Boolean(!roleRate) && !isRoleCostMode,
          includePrimaryRoleCostRate: Boolean(!roleRate) && isRoleCostMode,
          today: mapRepliconDateToUtcObject(todayForUser)
        }
      });

      const {
        currentCostRate: userRate,
        roles: userRoles,
        resourceSkills,
        primaryRoleCostRate
      } = data.resourceUsers2?.[0];

      const primaryRole = userRoles?.find(r => r.isPrimary);

      if (primaryRole) setFieldValue('role', mapRole(primaryRole.projectRole));

      if (resourceSkills?.length) {
        const mappedSkills = resourceSkills.flatMap(skill => mapSkill(skill));

        setFieldValue('skills', mappedSkills);
      }

      if (!isRoleCostMode && userRate?.amount) {
        setRoleRate({ setFieldValue, roleRate: userRate });
      }

      if (isRoleCostMode && primaryRoleCostRate?.amount) {
        setRoleRate({ setFieldValue, roleRate: primaryRoleCostRate });
      }

      setLoading(false);
    },
    [
      apolloClient,
      preferredResources,
      role,
      roleRate,
      skills,
      setFieldValue,
      setLoading,
      setSelectedOptions,
      isRoleCostMode,
      todayForUser,
      setKey
    ]
  );

  const onRemove = useCallback(
    resource => {
      const filteredResources = preferredResources.filter(
        r => r.id !== resource.id
      );

      setFieldValue('preferredResources', filteredResources);

      setSelectedOptions(filteredResources);
    },
    [preferredResources, setFieldValue, setSelectedOptions]
  );

  return {
    onResourceSelected,
    onRemove
  };
};

export default usePreferredResourceHandlers;
