import ProjectInfo from './ProjectInfo';

const route = ({ intl }) => ({
  path: '/projects/:slug',
  title: () => intl.formatMessage({ id: 'projectPageTabs.summary' }),
  component: ProjectInfo,
  exact: true,
  dataQeId: 'ProjectPage_SummaryTab'
});

export default route;
