/* eslint-disable react/prop-types */

import React, { useContext, useReducer, useMemo } from 'react';
import availabilityReducer, {
  updateResourceAvailability
} from './availabilityReducer';

export const AvailabilityContext = React.createContext();

export const useAvailabilityContext = () => useContext(AvailabilityContext);

let availabilityTimeout = null;

export const withAvailabilityContextProvider = BaseComponent => props => {
  const [state, dispatch] = useReducer(availabilityReducer, {
    availability: false
  });

  const setAvailabilityLoading = () => {
    if (availabilityTimeout) {
      clearTimeout(availabilityTimeout);
    }
    dispatch(updateResourceAvailability(true));

    availabilityTimeout = setTimeout(() => {
      dispatch(updateResourceAvailability(false));
    }, 9000);
  };

  const contextValue = useMemo(
    () => ({
      state,
      setAvailabilityLoading,
      dispatch
    }),
    [state, dispatch]
  );

  return (
    <AvailabilityContext.Provider value={contextValue}>
      <BaseComponent {...props} />
    </AvailabilityContext.Provider>
  );
};

export default AvailabilityContext;
