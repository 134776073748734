import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  TextField,
  InputAdornment,
  Popover,
  makeStyles,
  Divider,
  Typography
} from '@material-ui/core';
import { Decimal } from '~/modules/common/components';
import FormattedHoursAndCost from '~/modules/resourcing/common/components/FormattedHoursAndCost';
import ButtonBaseHover from '~/modules/common/components/ButtonBaseHover';
import useOnChangeHandlers from './useOnChangeHandlers';

const hideCosts = true;

const highlightTarget = event => event.target.select();

const useStyles = makeStyles(theme => ({
  inputAdornment: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 0.5)
  },
  hoursFieldInput: {
    textAlign: 'right',
    padding: theme.spacing(0.5, 0, 0.5, 23),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  estimatedCost: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  buttonBase: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.text.secondary
  },
  enterHoursLabel: {
    pointerEvents: 'none'
  },
  cost: {
    paddingLeft: theme.spacing(23)
  }
}));

const useEstimatedStyles = makeStyles(theme => ({
  root: {
    pointerEvents: 'none'
  },
  currency: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  },
  hours: {
    ...theme.typography.body2,
    color: theme.palette.text.primary
  }
}));

const EstimatedHoursEditor = ({
  cost,
  estimatedHours,
  onEstimatedHoursChange,
  taskEstimatedCost
}) => {
  const classes = useStyles();
  const estimatedClasses = useEstimatedStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [hours, setHours] = useState(estimatedHours || 0);
  const { formatMessage } = useIntl();

  const {
    onEstimatedHoursClick,
    handleChange,
    onClose,
    handleOnBlur,
    handleHoursKeyPress
  } = useOnChangeHandlers({
    setAnchorEl,
    setHours,
    onEstimatedHoursChange,
    hours
  });

  const inputProps = useMemo(
    () => ({
      classes: {
        input: classes.hoursFieldInput
      },
      disableUnderline: true,
      inputProps: {
        min: 0
      },
      endAdornment: (
        <InputAdornment className={classes.inputAdornment}>h</InputAdornment>
      )
    }),
    [classes.inputAdornment, classes.hoursFieldInput]
  );

  const formattedCost = useMemo(() => {
    return [
      {
        amount: cost ? cost.amount : '-',
        currencySymbol: cost ? taskEstimatedCost.currency?.displayText : ''
      }
    ];
  }, [cost, taskEstimatedCost]);

  return (
    <>
      <ButtonBaseHover
        component="a"
        className={classes.buttonBase}
        onClick={onEstimatedHoursClick}
      >
        {estimatedHours ? (
          <FormattedHoursAndCost
            classes={estimatedClasses}
            hideCosts
            hours={estimatedHours}
            hoursSuffix={formatMessage({
              id: 'taskResourceAssignments.hoursSuffix'
            })}
            costs={formattedCost}
            showAbbreviatedValue={false}
          />
        ) : (
          <Typography variant="body2" className={classes.enterHoursLabel}>
            {formatMessage({
              id: 'taskResourceAssignments.enterEstimatedHours'
            })}
          </Typography>
        )}
      </ButtonBaseHover>
      {Boolean(anchorEl) && (
        <Popover
          id="estimated-hours-editor"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
        >
          <div>
            <TextField
              type="number"
              step="0.01"
              min="0"
              variant="standard"
              value={hours}
              onBlur={handleOnBlur}
              onChange={handleChange}
              onFocus={highlightTarget}
              onKeyDown={handleHoursKeyPress}
              autoFocus
              InputProps={inputProps}
            />
            {!hideCosts && (
              <>
                <Divider />
                <div className={classes.estimatedCost}>
                  {formatMessage({
                    id: 'taskResourceAssignments.estimatedCost'
                  })}
                  :
                  <div className={classes.cost}>
                    <span>
                      {cost ? taskEstimatedCost.currency?.displayText : ''}
                    </span>
                    <Decimal value={cost ? cost.amount : '-'} />
                  </div>
                </div>
              </>
            )}
          </div>
        </Popover>
      )}
    </>
  );
};

EstimatedHoursEditor.propTypes = {
  cost: PropTypes.object,
  taskEstimatedCost: PropTypes.object,
  onEstimatedHoursChange: PropTypes.func.isRequired,
  estimatedHours: PropTypes.number
};

export default EstimatedHoursEditor;
