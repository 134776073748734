import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonBase, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  date: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize
  },
  root: {
    display: 'flex',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    },
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  projectButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%'
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0, 1, 0, 2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  over: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    width: theme.spacing(5),
    textAlign: 'right'
  }
}));

export const ExpansionRow = ({ projectsCount, to, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonBase
        className={classes.projectButton}
        component={Link}
        to={to}
        disableRipple
      >
        <Typography className={classes.over} data-qe-id={label}>
          {projectsCount}
        </Typography>
        <Typography variant="subtitle1" className={classes.name}>
          {label}
        </Typography>
      </ButtonBase>
    </div>
  );
};

ExpansionRow.propTypes = {
  projectsCount: PropTypes.number,
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default ExpansionRow;
