import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 1, 1, 4),
    [theme.breakpoints.down('xs')]: {
      '&:last-child': {
        paddingLeft: theme.spacing(3)
      }
    },
    backgroundColor: 'transparent',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest
    }),
    listStyleType: 'none'
  },
  rootExpanded: {
    backgroundColor: theme.palette.grey[50]
  },
  title: {
    padding: 0,
    textAlign: 'left'
  },
  titleButtonLabel: {
    flexGrow: 1,
    flexShrink: 1
  },
  content: {
    margin: theme.spacing(1, 0, 0, 0),
    padding: 0
  },

  expand: {
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

const defaultLabel = <FormattedMessage id="businessIntelligence.learnMore" />;

const ExpandableItem = ({
  classes: classesOverrides,
  className,
  title,
  children,
  expandableButtonLabel = defaultLabel
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = useCallback(() => {
    setIsExpanded(expanded => !expanded);
  }, []);

  const moreIcon = useMemo(
    () => (
      <ExpandMoreIcon
        className={classNames(classes.expand, {
          [classes.expandOpen]: isExpanded
        })}
      />
    ),
    [isExpanded, classes.expand, classes.expandOpen]
  );

  return (
    <ul
      className={classNames(
        classes.root,
        { [classes.rootExpanded]: isExpanded },
        className
      )}
    >
      <span className={classes.title}>{title}</span>
      <div>
        <Button startIcon={moreIcon} onClick={handleClick}>
          {expandableButtonLabel}
        </Button>
      </div>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <div className={classes.content}>{children}</div>
      </Collapse>
    </ul>
  );
};

ExpandableItem.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  expandableButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default ExpandableItem;
