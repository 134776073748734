import {
  createFixedWidthView,
  useFixedRange
} from '~/modules/common/charts/timeline/calculations';

export const useGetChartDates = ({ scale, width, anchorDate }) => {
  const { getPeriodCount } = createFixedWidthView(width, 456, 172);

  const {
    chartDates,
    onPrevious,
    onNext,
    dateRange,
    setScale: setFixedRangeScale
  } = useFixedRange({
    getPeriodCount,
    anchor: 0.2,
    defaults: {
      date: anchorDate,
      scale
    }
  });

  return {
    chartDates,
    onPrevious,
    onNext,
    dateRange,
    setFixedRangeScale
  };
};
