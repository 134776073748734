import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { DateTime } from 'luxon';

export const revenueForecastRecalculateQuery = gql`
  mutation createProjectRevenueForecastRecalculationBatch(
    $projectId: String!
    $dateRange: DateRangeInput2
  ) {
    createProjectRevenueForecastRecalculationBatch(
      projectId: $projectId
      dateRange: $dateRange
    ) {
      batchUri
    }
  }
`;

export const useCreateProjectRevenueForecastRecalculationBatch = () => {
  const [revenueForecastRecalculationBatch] = useMutation(
    revenueForecastRecalculateQuery
  );

  const { day, month, year } = DateTime.local().toObject();

  return {
    revenueForecastRecalculationBatch: ({ projectId }) => {
      return revenueForecastRecalculationBatch({
        variables: {
          projectId,
          dateRange: {
            startDate: { day, month, year },
            endDate: { day, month, year }
          }
        }
      });
    }
  };
};

export default useCreateProjectRevenueForecastRecalculationBatch;
