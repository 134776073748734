import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { User } from '~/modules/common/components';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { useCanEditAllClients } from '../hooks';

const renderTags = values =>
  values.map(option => (
    <User
      dataQeId="ClientManagerSelected"
      user={option}
      disablePadding
      key={option.displayText}
    />
  ));

const renderOption = option => <User user={option} disablePadding />;

export const ClientManagerSelect = ({ clientManagers, value, onChange }) => {
  const { formatMessage } = useIntl();

  const canEditAllClients = useCanEditAllClients();

  const handleOnChange = useCallback(
    managers =>
      onChange(
        managers && managers.length ? managers[managers.length - 1] : null
      ),
    [onChange]
  );

  const selectedValue = useMemo(() => (value ? [value] : []), [value]);

  return (
    <SimpleAutocomplete
      inputLabel={formatMessage({ id: 'client.clientManager' })}
      dataQeId="ClientManagerDropdown"
      options={clientManagers}
      disabled={!canEditAllClients}
      renderTags={renderTags}
      value={selectedValue}
      onChange={handleOnChange}
      multiple
      fullWidth
      noOptionsText={formatMessage({ id: 'client.noClientManager' })}
      optionPropText="displayText"
      renderOption={renderOption}
    />
  );
};

ClientManagerSelect.propTypes = {
  clientManagers: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default ClientManagerSelect;
