import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import LoadingButton from '../LoadingButton';
import CancelButton from '../CancelButton';
import { useDeleteObject } from './useDeleteObject';
import dialogContents from './dialogContents';

export const gotoUrlUponDeletion = {
  PROGRAM: '/programs',
  CLIENT: '/clients?view=list',
  PROJECT: '/projects?view=list',
  VENDOR: '/vendors'
};

const confirmationDialogRole = {
  role: 'presentation'
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1, 3),
    margin: 0,
    minWidth: 300,
    minHeight: 64,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '40%'
  }
}));

export const DeleteConfirmationDialog = ({
  objectType,
  open,
  onCancel,
  objectId,
  canBeDeletedCallback,
  redirectOnDeleteSuccessUrl,
  objectName
}) => {
  const classes = useStyles();
  const { loading, canBeDeleted } = canBeDeletedCallback();

  const [status, setStatus] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    title,
    content,
    action,
    cancel,
    secondaryContent = null
  } = useMemo(() => dialogContents[objectType]({ canBeDeleted }), [
    canBeDeleted,
    objectType
  ]);

  const deleteObject = useDeleteObject({
    objectType,
    objectId,
    setStatus,
    closeDialog: onCancel,
    setIsDeleting,
    objectName
  });

  if (status === 'DELETED') {
    return (
      <Redirect
        to={redirectOnDeleteSuccessUrl || gotoUrlUponDeletion[objectType]}
      />
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      TransitionProps={confirmationDialogRole}
    >
      <DialogTitle>
        <FormattedMessage id={title} />
      </DialogTitle>
      <DialogContent className={classes.container}>
        {loading ? (
          <CircularProgress
            data-qe-id="delete_loading"
            size={24}
            className={classes.progress}
          />
        ) : (
          <>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id={content} />
            </Typography>
            {secondaryContent && (
              <>
                <br />
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage id={secondaryContent} />
                </Typography>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <CancelButton color="primary" onClick={onCancel} disabled={isDeleting}>
          <FormattedMessage id={cancel} />
        </CancelButton>
        <LoadingButton
          color="secondary"
          onClick={deleteObject}
          disabled={isDeleting || loading}
          isLoading={isDeleting}
        >
          <FormattedMessage id={action} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirmationDialog.propTypes = {
  objectType: PropTypes.oneOf(['PROGRAM', 'CLIENT', 'PROJECT', 'VENDOR']),
  objectId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  canBeDeletedCallback: PropTypes.func.isRequired,
  redirectOnDeleteSuccessUrl: PropTypes.string,
  objectName: PropTypes.string
};

export default DeleteConfirmationDialog;
