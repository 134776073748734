import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { BillStatus } from '~/types';
import { overdueBillSummaryQuery } from './totalsQuery';
import {
  getConsolidatedTypeFilter,
  mapToServiceFilter
} from './useOutstandingBillTabSummary';

const summaryType = ['BILL'];

export const useOverdueBillTabSummary = (
  billingTransactionFilter,
  contextFilter,
  dueDate
) => {
  const consolidatedTypeFilter =
    billingTransactionFilter.transactionTypes &&
    billingTransactionFilter.transactionTypes.length > 0
      ? getConsolidatedTypeFilter(
          billingTransactionFilter.transactionTypes,
          summaryType
        )
      : summaryType;

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const { data, loading } = useQuery(overdueBillSummaryQuery, {
    variables: {
      filter: mapToServiceFilter({
        contextFilter,
        billingTransactionFilter,
        consolidatedTypeFilter,
        billStatus: [BillStatus.IssuedPartiallypaid, BillStatus.IssuedUnpaid],
        isOverdue: true,
        dueDate
      })
    },
    skip:
      isPsaFpGraphqlOptimizationEnabled || !consolidatedTypeFilter.length > 0,
    fetchPolicy: 'network-only'
  });

  const overdueSummary = get(data, 'getBillingTransactionTotals', {});

  return {
    loading,
    overdueSummary
  };
};

export default useOverdueBillTabSummary;
