import { useMemo, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useURLSearchParams } from '~/modules/common/hooks';
import { projectTag } from '~/modules/common/components/SearchBox/Facets/ProjectFacet/tag';
import { clientTag } from '~/modules/common/components/SearchBox/Facets/ClientFacet/tag';
import { programTag } from '~/modules/common/components/SearchBox/Facets/ProgramFacet/tag';
import { monthTag } from '~/modules/common/components/SearchBox/Facets/MonthFacet/tag';
import { dateTag } from '~/modules/common/components/SearchBox/Facets/DateRangeFacet/tag';
import { locationTag } from '~/modules/common/components/SearchBox/Facets/LocationFacet/tag';
import { divisionTag } from '~/modules/common/components/SearchBox/Facets/DivisionFacet/tag';
import { costCenterTag } from '~/modules/common/components/SearchBox/Facets/CostCenterFacet/tag';
import { serviceCenterTag } from '~/modules/common/components/SearchBox/Facets/ServiceCenterFacet/tag';
import { departmentTag } from '~/modules/common/components/SearchBox/Facets/DepartmentFacet/tag';
import { employeeTypeTag } from '~/modules/common/components/SearchBox/Facets/EmployeeTypeFacet/tag';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

const mapToField = (value, label) => ({
  id: value,
  value,
  displayText: label,
  key: label,
  label
});

const getValue = (value, label, previousCriterion) => {
  return value ? [mapToField(value, label)] : previousCriterion;
};

const resolveMonthRange = dateTime =>
  mapIsoStringtoUtcObject(dateTime).toLocaleString({
    month: 'short',
    year: 'numeric'
  });

export const getMonthRangeValue = (startDate, endDate, previousCriterion) => {
  const startMonthValue = startDate ? resolveMonthRange(startDate) : '';
  const endMonthValue = endDate ? resolveMonthRange(endDate) : '';

  return startDate || endDate
    ? [
        {
          dateRange: {
            startDate: mapIsoStringtoUtcObject(startDate),
            endDate: mapIsoStringtoUtcObject(endDate)
          },
          key: `${startMonthValue} - ${endMonthValue}`
        }
      ]
    : previousCriterion;
};

const resolveDateTime = dateTime =>
  mapIsoStringtoUtcObject(dateTime).toLocaleString(DateTime.DATE_MED);

export const getDateRangeValue = (startDate, endDate, previousCriterion) => {
  const startDateValue = startDate ? resolveDateTime(startDate) : '';
  const endDateValue = endDate ? resolveDateTime(endDate) : '';

  return startDate || endDate
    ? [
        {
          range: {
            startDate: mapIsoStringtoUtcObject(startDate),
            endDate: mapIsoStringtoUtcObject(endDate)
          },
          key: `(${startDateValue} - ${endDateValue})`
        }
      ]
    : previousCriterion;
};

const isFilterTagEnabled = (filterTags, filterTag) =>
  filterTags.some(x => x === filterTag);

export const useQueryParamSearchContext = ({ filterTags }) => {
  const [callToActionSearchCriteria, setCallToActionSearchCriteria] = useState({
    keywords: [],
    criterions: {}
  });

  const queryParams = useURLSearchParams();
  const label = queryParams.get('label');
  const projectValue = queryParams.get('project');
  const clientValue = queryParams.get('client');
  const programValue = queryParams.get('program');
  const startDateValue = queryParams.get('startDate');
  const endDateValue = queryParams.get('endDate');
  const locationValue = queryParams.get('location');
  const divisionValue = queryParams.get('division');
  const serviceCenterValue = queryParams.get('serviceCenter');
  const costCenterValue = queryParams.get('costCenter');
  const departmentValue = queryParams.get('department');
  const employeeTypeValue = queryParams.get('employeeType');

  const project = callToActionSearchCriteria.criterions[projectTag] || [];
  const client = callToActionSearchCriteria.criterions[clientTag] || [];
  const program = callToActionSearchCriteria.criterions[programTag] || [];
  const month = callToActionSearchCriteria.criterions[monthTag] || [];
  const location = callToActionSearchCriteria.criterions[locationTag] || [];
  const division = callToActionSearchCriteria.criterions[divisionTag] || [];
  const serviceCenter =
    callToActionSearchCriteria.criterions[serviceCenterTag] || [];
  const costCenter = callToActionSearchCriteria.criterions[costCenterTag] || [];
  const department = callToActionSearchCriteria.criterions[departmentTag] || [];
  const employeeType =
    callToActionSearchCriteria.criterions[employeeTypeTag] || [];

  useEffect(() => {
    if (setCallToActionSearchCriteria) {
      setCallToActionSearchCriteria({
        criterions: {
          [projectTag]: isFilterTagEnabled(filterTags, 'projectTag')
            ? getValue(projectValue, label, project)
            : [],
          [clientTag]: isFilterTagEnabled(filterTags, 'clientTag')
            ? getValue(clientValue, label, client)
            : [],
          [programTag]: isFilterTagEnabled(filterTags, 'programTag')
            ? getValue(programValue, label, program)
            : [],
          [monthTag]: isFilterTagEnabled(filterTags, 'monthTag')
            ? getMonthRangeValue(startDateValue, endDateValue, month)
            : [],
          [dateTag]: isFilterTagEnabled(filterTags, 'dateTag')
            ? getDateRangeValue(startDateValue, endDateValue, month)
            : [],
          [locationTag]: isFilterTagEnabled(filterTags, 'locationTag')
            ? getValue(locationValue, label, location)
            : [],
          [divisionTag]: isFilterTagEnabled(filterTags, 'divisionTag')
            ? getValue(divisionValue, label, division)
            : [],
          [serviceCenterTag]: isFilterTagEnabled(filterTags, 'serviceCenterTag')
            ? getValue(serviceCenterValue, label, serviceCenter)
            : [],
          [costCenterTag]: isFilterTagEnabled(filterTags, 'costCenterTag')
            ? getValue(costCenterValue, label, costCenter)
            : [],
          [departmentTag]: isFilterTagEnabled(filterTags, 'departmentTag')
            ? getValue(departmentValue, label, department)
            : [],
          [employeeTypeTag]: isFilterTagEnabled(filterTags, 'employeeTypeTag')
            ? getValue(employeeTypeValue, label, employeeType)
            : []
        },
        keywords: []
      });
    }
  }, [queryParams]);

  const searchContext = useMemo(
    () => ({
      searchCriteria: callToActionSearchCriteria,
      setSearchCriteria: setCallToActionSearchCriteria
    }),
    [callToActionSearchCriteria, setCallToActionSearchCriteria]
  );

  const hasValue = Object.keys(callToActionSearchCriteria.criterions).some(
    x => callToActionSearchCriteria.criterions[x].length
  );

  return { searchContext, hasValue };
};

export default useQueryParamSearchContext;
