import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { NoValue } from '~/modules/common/components';
import { useMeContext } from '~/modules/me';
import {
  getReactMoneyFormatFromMe,
  getReactNumberFormatFromMe
} from '~/modules/common/numbers';
import OverviewItemTooltip from './OverviewItemTooltip';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      justifyContent: 'center'
    }
  },
  item2: {
    justifyContent: 'space-around'
  },
  container: {
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(17)
    }
  },
  container2: {
    textAlign: 'center'
  },
  value: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightMedium
  },
  value2: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary
  },
  valueNegative: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.dark
  },
  label: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  label2: {
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.subtitle1.fontFamily,
    color: theme.palette.text.secondary
  },
  budgetItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.subtitle2.fontSize
    }
  },
  notAvailable: {
    color: theme.palette.text.secondary
  },
  rowItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  help: {
    '&:hover': {
      cursor: 'help'
    }
  },
  warning: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(2)
  },
  details: {
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.text.primary
  }
}));

export const OverviewItem = ({
  title,
  value,
  trendDataPoints,
  currency,
  budget,
  dataQeId,
  isPercentage,
  ...rest
}) => {
  const classes = useStyles();
  const { me, isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();
  const moneySettings = getReactMoneyFormatFromMe(me);
  const numberSettings = getReactNumberFormatFromMe(me);

  return (
    <>
      {isRolledUpTaskEstimateCalculationMethodEnabled ? (
        <div className={classNames(classes.item, classes.item2)}>
          <div className={classNames(classes.container, classes.container2)}>
            <Typography
              className={classes.label2}
              data-qe-id={dataQeId && `${dataQeId}_Title`}
            >
              {title}
            </Typography>
            <div className={classes.rowItems}>
              {value < 0 ? (
                <RemoveIcon className={classes.valueNegative} />
              ) : null}
              <Typography
                className={classNames(classes.value2, {
                  [classes.valueNegative]: value < 0
                })}
                data-qe-id={dataQeId}
              >
                {value !== null ? (
                  <OverviewItemTooltip
                    currencySymbol={currency?.displayText || currency?.symbol}
                    value={Math.abs(value)}
                    moneySettings={moneySettings}
                    numberSettings={numberSettings}
                    intlKey="cost"
                    id="dashboardOverviewChart.costDescription"
                    isPercentage={isPercentage}
                  ></OverviewItemTooltip>
                ) : (
                  <span
                    className={classNames(classes.value2, classes.notAvailable)}
                  >
                    <FormattedMessage id="dashboardOverviewChart.na" />
                  </span>
                )}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.item}>
          <div className={classes.container}>
            <Typography
              className={classes.label}
              data-qe-id={dataQeId && `${dataQeId}_Title`}
            >
              {title}
            </Typography>
            <Typography className={classes.value} data-qe-id={dataQeId}>
              {value ? (
                <OverviewItemTooltip
                  currencySymbol={currency?.displayText || currency?.symbol}
                  value={value}
                  moneySettings={moneySettings}
                  numberSettings={numberSettings}
                  intlKey="cost"
                  id="dashboardOverviewChart.costDescription"
                  isPercentage={isPercentage}
                ></OverviewItemTooltip>
              ) : (
                <NoValue />
              )}
            </Typography>
            {budget && budget.total && budget.total.amount > 0 && (
              <Typography className={classes.budgetItem}>
                <OverviewItemTooltip
                  currencySymbol={budget.total.currency?.displayText}
                  value={budget.total.amount}
                  moneySettings={moneySettings}
                  numberSettings={numberSettings}
                  intlKey="budget"
                  id="dashboardOverviewChart.budgetDescription"
                ></OverviewItemTooltip>
              </Typography>
            )}
          </div>
        </div>
      )}
    </>
  );
};

OverviewItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.number,
  currency: PropTypes.object,
  trendDataPoints: PropTypes.array,
  budget: PropTypes.object,
  dataQeId: PropTypes.string,
  isPercentage: PropTypes.bool
};

export default OverviewItem;
