import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import ChevronDateButtons from '~/modules/common/components/ChevronDateButtons';
import DateRangePicker from '../DateRangePicker';
import DateRange from '../DateRange';

const useStyles = makeStyles(theme => ({
  date: {
    ...theme.typography.h6,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize
    }
  },
  range: {
    alignSelf: 'center'
  },
  dateRange: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '70%'
    }
  },
  rangeSelector: {
    display: 'flex',
    alignSelf: 'center'
  }
}));

const useButtonStyles = makeStyles(theme => ({
  previousButton: {
    margin: theme.spacing(-1, 0, -1, 0)
  }
}));

export const PeriodSelector = ({
  onPreviousClick,
  onNextClick,
  onStartDateChange,
  onEndDateChange,
  dateRange,
  classes: classesOverride,
  showDateRange = true,
  isLoading = false,
  readOnly = true
}) => {
  const buttonStyles = useButtonStyles();
  const classes = useStyles({ classes: classesOverride });

  const dateRangeClasses = useMemo(
    () => ({
      range: classes.range,
      date: classes.date
    }),
    [classes.date, classes.range]
  );

  return (
    <div className={classes.dateRange}>
      <div className={classes.rangeSelector}>
        <ChevronDateButtons
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          disableLeftButton={isLoading}
          disableRightButton={isLoading}
          classes={buttonStyles}
        />
      </div>
      {showDateRange &&
        (readOnly ? (
          <DateRange
            start={dateRange.startDate}
            end={dateRange.endDate}
            classes={dateRangeClasses}
          />
        ) : (
          <DateRangePicker
            dateRange={dateRange}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        ))}
    </div>
  );
};

PeriodSelector.propTypes = {
  classes: PropTypes.object,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  dateRange: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }).isRequired,
  showDateRange: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default PeriodSelector;
