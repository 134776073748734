import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobileApp as isFromMobileApp } from '~/util';
import { useSessionStorage } from './useSessionStorage';

export default ({ to, linkUrl, mobileMessage, target = '_self' }) => {
  const isMobileApp = isFromMobileApp();
  const { setValue: setCurrentView } = useSessionStorage('current-view', null);
  const location = useLocation();

  const onClickHandler = useCallback(
    e => {
      if (isMobileApp && mobileMessage) {
        e.preventDefault();
        window.postMessage(JSON.stringify(mobileMessage));
      } else {
        setCurrentView(location);
      }

      return false;
    },
    [mobileMessage]
  );

  const props =
    isMobileApp && mobileMessage
      ? { onClick: onClickHandler, to }
      : to
      ? { to }
      : {
          href: linkUrl,
          target,
          rel: 'noopener noreferrer',
          onClick: onClickHandler
        };

  return useMemo(() => props, [props]);
};
