import deepEqual from 'fast-deep-equal';
import calcScrollBarWidth from 'scrollbar-width';

const useWidthExcludePropCheckHack = (prevProps, nextProps) => {
  const { width: prevWidth, ...prevRestProps } = prevProps;
  const { width: nextWidth, ...nextRestProps } = nextProps;

  const propsEqual = deepEqual(prevRestProps, nextRestProps);
  const WIDTH_FLICKER_ADJUSTMENT = 30;
  // prevent re-rendering when vertical scroll bar appears and reduces the width

  return (
    propsEqual &&
    Math.abs(Math.floor(nextWidth) - Math.floor(prevWidth)) <=
      (WIDTH_FLICKER_ADJUSTMENT + calcScrollBarWidth() || 0)
  );
};

export default useWidthExcludePropCheckHack;
