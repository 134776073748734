import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useResourcePools from '~/modules/common/hooks/useResourcePools';
import { useStyledPoolLabel } from './hooks';
import ResourcePoolSelect from './ResourcePoolSelect';

export const ResourcePoolDropdown = ({
  alwaysShowHelperText,
  hasError,
  helperText,
  isRequired,
  onChange,
  value,
  variant
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const resourcePoolsLabel = useStyledPoolLabel({ isRequired });

  const { resourcePools, loading, hasMore } = useResourcePools({
    searchFilter: { searchTextParam: searchTerm, includeOnlyEnabled: true }
  });

  const resourcePoolOptions = loading ? [] : resourcePools;

  return (
    <ResourcePoolSelect
      label={resourcePoolsLabel}
      hasMore={hasMore}
      resourcePools={value}
      onChange={onChange}
      options={resourcePoolOptions}
      loading={loading}
      handleInputChange={handleInputChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.resourcePools'
      })}
      hasError={hasError}
      variant={variant}
      isRequired={isRequired}
      helperText={helperText}
      alwaysShowHelperText={alwaysShowHelperText}
    />
  );
};

ResourcePoolDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  hasError: PropTypes.bool,
  variant: PropTypes.string,
  isRequired: PropTypes.bool,
  helperText: PropTypes.any,
  alwaysShowHelperText: PropTypes.bool
};

export default ResourcePoolDropdown;
