import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormLabel } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    width: 0,
    margin: theme.spacing(0, 2, 0, 0),
    '&:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      '&:last-child': {
        marginBottom: 0
      }
    },
    padding: 0
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    fontSize: theme.typography.body2.fontSize
  },
  valueContainer: {
    padding: `${8 - 2}px 0 ${8 - 1}px`,
    lineHeight: '1.1875em',
    fontFamily: theme.typography.fontFamily
  }
}));

export const RowItem = ({ classes: classesOverride, children, label }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <li className={classes.rowItem}>
      <FormControl>
        <FormLabel className={classes.label}>{label}</FormLabel>
        <div className={classes.valueContainer}>{children}</div>
      </FormControl>
    </li>
  );
};

RowItem.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node
};

export default RowItem;
