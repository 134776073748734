import {
  Date as DateFormatter,
  String as StringFormatter,
  Money as MoneyFormatter
} from '~/modules/common/components/ListTable/renderers';
import DateRangeFormatter from '~/modules/common/components/formatters/DateRangeFormatter';
import BooleanFormatter from './BooleanFormatter';

const scriptParamTypeFormatters = {
  date: DateFormatter,
  money: MoneyFormatter,
  'date-range': DateRangeFormatter,
  text: StringFormatter,
  'drop-down': StringFormatter,
  numeric: StringFormatter,
  integer: StringFormatter,
  'read-only-amount': MoneyFormatter,
  boolean: BooleanFormatter
};

export const formatters = ({ parameters, customFormatters }) => {
  const allFormatters = { ...scriptParamTypeFormatters, ...customFormatters };

  return parameters.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.id]: allFormatters[current.id] || allFormatters[current.type]
    }),
    {}
  );
};
