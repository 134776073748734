import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import PeriodSelector from '~/modules/common/components/PeriodSelector';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      padding: theme.spacing(1.5, 0)
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  }
}));

const usePeriodSelectorStyles = makeStyles(theme => ({
  date: {
    [theme.breakpoints.down('xs')]: {
      display: 'inline-flex'
    }
  }
}));

export const ChartTitleWithPeriodSelector = ({
  classes: classesOverride,
  periodSelectorClasses: periodSelectorClassesOverride,
  title,
  titleId = '',
  onPreviousClick,
  onNextClick,
  dateRange,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });
  const periodSelectorClasses = usePeriodSelectorStyles({
    classes: periodSelectorClassesOverride
  });

  return (
    <div className={classes.root}>
      <span
        className={classes.title}
        id={titleId}
        data-qe-id={dataQeId && `${dataQeId}_Title`}
      >
        {title}
      </span>
      <PeriodSelector
        classes={periodSelectorClasses}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        dateRange={dateRange}
      />
    </div>
  );
};

ChartTitleWithPeriodSelector.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  titleId: PropTypes.string,
  dateRange: PropTypes.object.isRequired,
  periodSelectorClasses: PropTypes.object,
  dataQeId: PropTypes.string
};

export default ChartTitleWithPeriodSelector;
