import PropTypes from 'prop-types';

const DateConfigurationPropType = PropTypes.shape({
  value: PropTypes.object,
  maximumDate: PropTypes.object,
  minimumDate: PropTypes.object
});

const NumericConfigurationPropType = PropTypes.shape({
  decimalPlaces: PropTypes.number,
  defaultNumericValue: PropTypes.number,
  maximumNumericValue: PropTypes.number,
  minimumNumericValue: PropTypes.number
});

const TextConfigurationPropType = PropTypes.shape({
  defaultTextValue: PropTypes.string,
  maximumLength: PropTypes.number
});

export const CustomFieldValuePropType = PropTypes.shape({
  date: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    day: PropTypes.number
  }),
  dropDownOptionUri: PropTypes.string,
  number: PropTypes.number,
  text: PropTypes.string,
  customFieldDefinition: PropTypes.shape({
    defaultDropDownValue: PropTypes.shape({
      id: PropTypes.string,
      displayText: PropTypes.string
    }),
    dropDownOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        displayText: PropTypes.string
      })
    ),
    dateConfiguration: DateConfigurationPropType,
    numericConfiguration: NumericConfigurationPropType,
    textConfiguration: TextConfigurationPropType
  }),
  customField: PropTypes.shape({
    id: PropTypes.string,
    displayText: PropTypes.string
  }),
  customFieldType: PropTypes.shape({
    id: PropTypes.string
  })
});

export default CustomFieldValuePropType;
