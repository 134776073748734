import { gql } from 'graphql-tag';

export const VALIDATE_PROJECT_NAME_QUERY = gql`
  query ValidateProjectName(
    $updatedProjectName: String!
    $originalProjectName: String
    $projectOrDraftUri: String
  ) {
    validateProjectName(
      updatedProjectName: $updatedProjectName
      originalProjectName: $originalProjectName
      projectOrDraftUri: $projectOrDraftUri
    ) {
      id
      displayText
      failureUri
      severityUri
    }
  }
`;
