import { Grid, TextField, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AddProjectLineItemButton,
  AssociatedBillsTable,
  DrawerExpansionPanel,
  LineItemsTable,
  FormCurrencyDropdown,
  ReferenceNumberField
} from '~/modules/billing-invoicing/common/components';
import {
  useAssociatedAndOutStandingBills,
  useCreditMemoTotalProps,
  useFormOnChange,
  useCreditMemoResourceHook
} from '~/modules/billing-invoicing/creditMemo/hooks';
import { DateField } from '~/modules/common/components';
import { SearchableClientDropdown } from '~/modules/projects/project/common/components';
import { getError, hasError } from '~/util';
import {
  useExchangeRates,
  useIsMultiCurrency
} from '~/modules/billing-invoicing/common/hooks';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    padding: theme.spacing(1),
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderBottom: `1px solid ${theme.palette.table.border}`,
    minHeight: '-webkit-max-content'
  },
  showError: {
    paddingLeft: 15,
    color: theme.palette.error.dark
  }
}));

export const CreditMemoDetails = ({
  me,
  errors,
  values,
  billBalanceTotal,
  setFieldValue,
  hasClient,
  billId,
  showAllBills = true,
  editable = true,
  isUnAllocated = true,
  isSubmitting,
  hasMore,
  loadMore,
  deleteCreditMemo,
  onCreditMemoClose,
  hasAccessLineItems
}) => {
  const classes = useStyles();
  const [creditsExpanded, setCreditsExpanded] = useState(true);
  const [billsExpanded, setBillsExpanded] = useState(true);
  const {
    id,
    client,
    displayId,
    description,
    currency,
    creditDate,
    lineItems,
    associatedBills
  } = values;
  const intl = useIntl();
  const [showAllOutstandingBills, setShowAllOutstandingBIlls] = useState(
    showAllBills
  );
  const { associatedAndOutstandingBills } = useAssociatedAndOutStandingBills({
    me,
    client,
    values,
    billId,
    billBalanceTotal,
    showAllOutstandingBills
  });
  const { isMultiCurrency } = useIsMultiCurrency({
    values,
    associatedAndOutstandingBills
  });
  const {
    exchangeRates,
    isExchangeRateLoading,
    fetchMoreExchangeRates
  } = useExchangeRates({
    currency,
    creditDate,
    associatedAndOutstandingBills,
    isMultiCurrency
  });
  const {
    lineItemsTotal,
    associatedBillsTotal,
    creditMemoBalanceTotal
  } = useCreditMemoTotalProps({ values });
  const {
    onDisplayIdChange,
    onDescriptionChange,
    onCreditDateChange,
    onClientChange,
    onProjectLineItemAdd,
    onLineItemAmountChange,
    onDeleteLineItem,
    onAssociatedBillAmountInCreditMemoCurrencyChange,
    onAssociatedBillAmountInBillCurrencyChange,
    onCreditCurrencyChange
  } = useFormOnChange({
    setFieldValue,
    values,
    fetchMoreExchangeRates,
    creditMemoBalanceTotal,
    associatedBills,
    exchangeRates
  });
  const {
    lineItemsTableResourceKeys,
    associatedBillsTableResourceKeys,
    resourceLabels,
    accesibilityResourceLabels,
    associatedBillsPanelResourceKeys,
    lineItemsPanelResourceKeys
  } = useCreditMemoResourceHook({ intl, client });

  const inputRefCallback = useCallback(
    errorId => inputRef => {
      if (isSubmitting && hasError(errors, errorId) && inputRef)
        inputRef.focus();
    },
    [isSubmitting, errors]
  );

  const clientTextFieldProps = useMemo(
    () => ({
      error: hasError(errors, 'client'),
      helperText: hasError(errors, 'client') && (
        <FormattedMessage id="billDetails.validations.clientIsRequired" />
      )
    }),
    [errors]
  );

  const isEditable = useMemo(
    () => associatedBillsTotal.amount === 0 || id === null,
    [associatedBillsTotal.amount, id]
  );

  const isLoading = useMemo(() => isMultiCurrency && isExchangeRateLoading, [
    isExchangeRateLoading,
    isMultiCurrency
  ]);

  return (
    <Grid container className={classes.container} spacing={2}>
      {!hasClient && (
        <Grid item xs={12} data-qe-id="CreditMemoClientDropdown">
          <SearchableClientDropdown
            aria-label={accesibilityResourceLabels.clientAriaLabel}
            variant="filled"
            value={client}
            autoFocus={!hasClient}
            inputRef={inputRefCallback('client')}
            onChange={onClientChange}
            TextFieldProps={clientTextFieldProps}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <ReferenceNumberField
          hasClient={hasClient}
          onReferenceNumberChange={onDisplayIdChange}
          transactionType={billingTransactionType.CREDITMEMO}
          errors={errors}
          displayId={displayId}
          displayText={intl.formatMessage({
            id: 'creditMemoDetails.creditMemo'
          })}
          inputRefCallback={inputRefCallback}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          variant="filled"
          fullWidth
          required
          clearable={false}
          label={resourceLabels.creditDate}
          labelClassName={classes.inputLabel}
          errorClassName={classes.error}
          ariaLabel={accesibilityResourceLabels.creditDateAriaLabel}
          value={creditDate}
          onChange={onCreditDateChange}
          editable={isEditable}
          underline={isEditable}
          inputRef={inputRefCallback('creditDate')}
          error={hasError(errors, 'creditDate')}
          helperText={getError(errors, 'creditDate')}
          useDateFieldStyles={false}
          dataQeId="creditMemoDetails.creditDate"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          id="creditMemo.description"
          variant="filled"
          fullWidth
          label={resourceLabels.description}
          inputRef={inputRefCallback('description')}
          value={description}
          onChange={onDescriptionChange}
          error={hasError(errors, 'description')}
          helperText={getError(errors, 'description')}
          data-qe-id="creditMemoDetails.description"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormCurrencyDropdown
          ariaLabel={accesibilityResourceLabels.currencyAriaLabel}
          variant="filled"
          label={resourceLabels.currency}
          value={currency}
          noneOption={false}
          onCurrencyChange={onCreditCurrencyChange}
          editable={isEditable}
          dataQeId="creditMemoDetails.currency"
        />
      </Grid>

      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={lineItemsPanelResourceKeys}
          amountTotal={lineItemsTotal}
          expanded={creditsExpanded}
          setExpanded={setCreditsExpanded}
        >
          {Boolean(lineItems.length) && (
            <LineItemsTable
              lineItems={lineItems}
              lineItemsTotal={lineItemsTotal}
              onAmountChange={onLineItemAmountChange}
              onDelete={onDeleteLineItem}
              editable={editable && isUnAllocated}
              resourceKeys={lineItemsTableResourceKeys}
              isLoading={isLoading}
              hasMore={hasMore}
              loadMore={loadMore}
              onRemove={deleteCreditMemo}
              onClose={onCreditMemoClose}
              hasAccessLineItems={hasAccessLineItems}
            />
          )}
          {Boolean(editable && isUnAllocated && client && client.id) && (
            <AddProjectLineItemButton
              client={client}
              onSelection={onProjectLineItemAdd}
            />
          )}
        </DrawerExpansionPanel>
      </Grid>
      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={associatedBillsPanelResourceKeys}
          amountTotal={associatedBillsTotal}
          expanded={billsExpanded}
          setExpanded={setBillsExpanded}
        >
          <AssociatedBillsTable
            associatedBills={associatedAndOutstandingBills}
            onAmountChange={onAssociatedBillAmountInCreditMemoCurrencyChange}
            amountTotal={associatedBillsTotal}
            errors={errors}
            resourceKeys={associatedBillsTableResourceKeys}
            billId={billId}
            showAllOutstandingBills={showAllOutstandingBills}
            setShowAllOutstandingBIlls={setShowAllOutstandingBIlls}
            onAllocatedAmountChange={onAssociatedBillAmountInBillCurrencyChange}
            isMultiCurrency={isMultiCurrency}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
          />
        </DrawerExpansionPanel>
      </Grid>
      <div className={classes.showError}>
        {getError(errors, 'creditMemoBalanceTotal')}
      </div>
    </Grid>
  );
};

CreditMemoDetails.propTypes = {
  me: PropTypes.object,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hasClient: PropTypes.bool.isRequired,
  showAllBills: PropTypes.bool,
  billId: PropTypes.string,
  editable: PropTypes.bool,
  isUnAllocated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  billBalanceTotal: PropTypes.object,
  setFieldValue: PropTypes.func,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  deleteCreditMemo: PropTypes.func,
  onCreditMemoClose: PropTypes.func,
  hasAccessLineItems: PropTypes.any
};
export default CreditMemoDetails;
