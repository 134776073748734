import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, makeStyles, Typography } from '@material-ui/core';
import RolesMenuListDropdown from '~/modules/common/components/RolesMenuListDropdown';
import { useHasPermission } from '~/modules/common/permissions';
import { useErrors } from '../../hooks';
import { useDimensionValueEditorHook } from '../hooks/useDimensionValueEditorHook';
import { useResourceLabels } from '../hooks/useResourceLabels';
import { disableAddEntryButton, useValue } from './useValue';
import DimensionValueReadOnly from './DimensionValueReadOnly';
import AdvancedRateCardSkeleton from './AdvancedRateCardSkeleton';

const useStyles = makeStyles(theme => ({
  roleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  select: {
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75)
  },
  defaultRate: ({ readOnly }) => ({
    color: theme.palette.text.secondary,
    paddingTop: readOnly ? theme.spacing(0) : theme.spacing(2),
    paddingBottom: readOnly ? theme.spacing(0) : theme.spacing(2),
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  }),
  container: ({ readOnly }) => ({
    ...(readOnly ? {} : { height: theme.spacing(8) })
  })
}));

export const RoleDimensionEditor = ({
  record,
  nextRecord,
  previousRecord,
  field,
  index,
  column: {
    id,
    onChange,
    displayText,
    defaultRate,
    errors,
    onAddNewEntry,
    dimensionIndex,
    projectDimensions,
    objectType,
    deletedRateLabelId
  },
  focusOnLastRow,
  setFocusOnLastRow,
  readOnly,
  loadingMore
}) => {
  const intl = useIntl();

  const value = useValue({
    id: deletedRateLabelId,
    record,
    field,
    defaultRate,
    intl
  });

  const {
    getAddEntryLabel,
    getSelectDimensionLabel,
    defaultLabel
  } = useResourceLabels();

  const classes = useStyles({ readOnly });

  const { hasError, helperText } = useErrors({
    errors,
    index,
    key: field,
    role: value
  });
  const canCreateRole = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:edit-project-role'
  });

  const {
    showAddRate,
    showValue,
    onAddEntryClick,
    handleChange
  } = useDimensionValueEditorHook({
    record,
    nextRecord,
    previousRecord,
    dimensionIndex,
    dimensionId: id,
    projectDimensions,
    onChange,
    index,
    onAddNewEntry,
    objectType,
    readOnly
  });

  useEffect(() => {
    if (focusOnLastRow && projectDimensions && projectDimensions[0].id === id) {
      setFocusOnLastRow(false);
    }
  }, [focusOnLastRow, id, projectDimensions, setFocusOnLastRow]);

  if (loadingMore) return <AdvancedRateCardSkeleton />;

  return (
    <>
      {showValue ? (
        <div className={classes.container}>
          {value === defaultRate ? (
            <Typography
              component="div"
              className={classes.defaultRate}
              variant="body2"
              tabIndex={0}
            >
              {defaultLabel}
            </Typography>
          ) : readOnly ? (
            <DimensionValueReadOnly value={value.displayText} />
          ) : (
            <div className={classes.roleContainer}>
              <RolesMenuListDropdown
                dataQeId={`${objectType}`}
                label={
                  !value ? getSelectDimensionLabel(displayText) : undefined
                }
                classes={classes}
                assignedRole={value}
                onChange={handleChange}
                variant="filled"
                autoFocus={
                  focusOnLastRow &&
                  projectDimensions &&
                  projectDimensions[0].id === id
                    ? index
                    : index === 0
                }
                refetchActiveRoles
                hasError={hasError}
                helperText={helperText}
                canCreateRole={canCreateRole}
                disabled={record.disabled || record.isDimensionDeleted}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={classes.container} />
      )}
      {record.isInitialRate && showAddRate ? (
        <Button
          onClick={onAddEntryClick}
          color="primary"
          size="small"
          data-qe-id={`Add ${objectType}`}
          disabled={
            record.disabled ||
            disableAddEntryButton({
              projectDimensions,
              dimensionIndex,
              record
            })
          }
        >
          <Typography variant="body2">
            {getAddEntryLabel(displayText)}
          </Typography>
        </Button>
      ) : null}
    </>
  );
};

RoleDimensionEditor.propTypes = {
  column: PropTypes.object,
  field: PropTypes.string,
  record: PropTypes.object,
  nextRecord: PropTypes.object,
  previousRecord: PropTypes.object,
  index: PropTypes.number,
  focusOnLastRow: PropTypes.bool,
  setFocusOnLastRow: PropTypes.func,
  readOnly: PropTypes.bool,
  loadingMore: PropTypes.bool
};

export default RoleDimensionEditor;
