import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Date } from '~/modules/common/components';
import { compareDateObjects } from '~/modules/common/dates/compare';

export const EstimatedCompletionDateOverride = ({
  classes,
  estimatedCompletionDate,
  overrideValue,
  dataQeId
}) => {
  const showOverride =
    overrideValue &&
    compareDateObjects(overrideValue, estimatedCompletionDate) !== 0;

  return (
    <Grid container direction="column">
      <Grid item className={classes.gridItem}>
        <Date
          data-qe-id={dataQeId}
          component="Typography"
          value={estimatedCompletionDate}
          className={classNames(classes.dataCell, {
            [classes.overriddenCell]: showOverride,
            [classes.strikeThrough]: showOverride
          })}
        />
      </Grid>
      {showOverride && (
        <Grid item className={classes.gridItem}>
          <Date
            component="Typography"
            value={overrideValue}
            className={classNames(classes.dataCell, {
              [classes.overriddenCell]: showOverride
            })}
          />
        </Grid>
      )}
    </Grid>
  );
};

EstimatedCompletionDateOverride.propTypes = {
  classes: PropTypes.object,
  dataQeId: PropTypes.string,
  estimatedCompletionDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  overrideValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default EstimatedCompletionDateOverride;
