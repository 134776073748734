import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useDialogState } from '~/modules/common/hooks';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';

import useRemoveAvatar from './hooks/useRemoveAvatar';

const useStyles = makeStyles(() => ({
  leftButtonContainer: {
    flexGrow: 1
  }
}));

const deleteDialogResourceKeys = {
  header: 'avatarUploadDialog.removeAvatar',
  actionButton: 'button.remove'
};

const AvatarUploadDialogRemoveButton = ({ onRemove, userUri, ...rest }) => {
  const {
    open: showRemoveDialog,
    closeDialog: closeRemoveDialog,
    openDialog: openRemoveDialog
  } = useDialogState(false);
  const [removeAvatar, { loading: isRemoving }] = useRemoveAvatar({ userUri });

  const onConfirmRemove = useMemo(
    () => () =>
      removeAvatar().finally(() => {
        closeRemoveDialog();
        onRemove();
      }),
    [removeAvatar, closeRemoveDialog, onRemove]
  );

  const classes = useStyles();

  return (
    <>
      <div className={classes.leftButtonContainer}>
        <Button onClick={openRemoveDialog} {...rest}>
          <FormattedMessage id="button.remove" />
        </Button>
      </div>
      <RemoveEntityConfirmationDialog
        resourceKeys={deleteDialogResourceKeys}
        open={showRemoveDialog}
        onClose={closeRemoveDialog}
        onConfirm={onConfirmRemove}
        isRemoving={isRemoving}
      >
        <DialogContentText>
          <FormattedMessage id="avatarUploadDialog.removeAvatarDescription" />
        </DialogContentText>
      </RemoveEntityConfirmationDialog>
    </>
  );
};

AvatarUploadDialogRemoveButton.propTypes = {
  onRemove: PropTypes.func.isRequired,
  userUri: PropTypes.string.isRequired
};

export default AvatarUploadDialogRemoveButton;
