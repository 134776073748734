import {
  Date,
  Money,
  Hours,
  Percent,
  String,
  MoneyHideZero,
  HoursHideZero,
  Variance,
  MoneyVariance
} from '~/modules/common/components/ListTable/renderers';
import GroupColumn from '~/modules/common/components/Groups/GroupColumn';
import { HierarchyColumn } from '~/modules/common/components/HierarchyColumn';
import { RevenueRecognitionPolicyFormatter } from '~/modules/billing-invoicing/revenueRecognition/components/common/columnFormatters';
import name from './name';
import workflow from './workflowStatus';
import program from './program';
import client from './client';
import tag from './tag';
import withHighlighting from './withHighlighting';
import withProjectCurrencyToolTip from './withProjectCurrencyToolTip';
import ProjectLeader from './ProjectLeader';

export default {
  name,
  code: String,
  startDate: Date,
  endDate: Date,
  status: workflow,
  portfolio: HierarchyColumn,
  program,
  client,
  projectManagerReference: ProjectLeader,
  budgetHours: HoursHideZero,
  budgetHoursRemaining: withHighlighting(
    HoursHideZero,
    'budgetHoursRemaining',
    'budgetHours'
  ),
  estimatedBudgetHoursRemaining: Variance,
  budgetHoursUsed: withHighlighting(Percent, 'budgetHoursUsed', 100),
  budgetedCost: MoneyHideZero,
  budgetedCostInProjectCurrency: withProjectCurrencyToolTip(
    MoneyHideZero,
    'budgetedCost.amount'
  ),
  budgetCostAmountInPortfolioCurrency: MoneyHideZero,
  estimatedCost: Money,
  actualExpenseCost: MoneyHideZero,
  actualResourceCost: MoneyHideZero,
  actualCost: withHighlighting(
    MoneyHideZero,
    'actualCost.amount',
    'budgetedCost.amount'
  ),
  estimatedCostAtCompletion: withHighlighting(
    MoneyHideZero,
    'estimatedCostAtCompletion.amount',
    'budgetedCost.amount'
  ),
  estimatedCostAtCompletionInProjectCurrency: withHighlighting(
    MoneyHideZero,
    'estimatedCostAtCompletionInProjectCurrency.amount',
    'budgetedCostInProjectCurrency.amount'
  ),
  estimatedCostAtCompletionInPortfolioCurrency: withHighlighting(
    MoneyHideZero,
    'estimatedCostAtCompletionInPortfolioCurrency.amount',
    'budgetCostAmountInPortfolioCurrency.amount'
  ),
  budgetCostUsedPercentage: withHighlighting(
    Percent,
    'budgetCostUsedPercentage',
    100
  ),
  estimatedBudgetCostRemaining: MoneyVariance,
  estimatedBudgetCostRemainingInProjectCurrency: MoneyVariance,
  estimatedBudgetCostRemainingInPortfolioCurrency: MoneyVariance,
  actualCostInProjectCurrency: withProjectCurrencyToolTip(
    MoneyHideZero,
    'actualCost.amount',
    'budgetedCost.amount'
  ),
  actualCostAmountInPortfolioCurrency: MoneyHideZero,
  estimatedHours: Hours,
  actualHours: withHighlighting(HoursHideZero, 'actualHours', 'budgetHours'),
  rolledUpEstimatedRemainingHours: Hours,
  totalRolledUpEstimatedRemainingHours: Hours,
  scopeHoursCompleted: withHighlighting(Percent, 'scopeHoursCompleted', 100),
  totalBudgetHoursUsed: Percent,
  estimatedHoursAtCompletion: withHighlighting(
    HoursHideZero,
    'estimatedHoursAtCompletion',
    'budgetHours'
  ),
  estimatedBilling: Money,
  actualBilling: MoneyHideZero,
  actualBillingInProjectCurrency: withProjectCurrencyToolTip(
    MoneyHideZero,
    'actualBilling.amount'
  ),
  revenueRecognitionPolicy: RevenueRecognitionPolicyFormatter,
  totalEstimatedContract: MoneyHideZero,
  totalEstimatedContractInProjectCurrency: withProjectCurrencyToolTip(
    MoneyHideZero,
    'totalEstimatedContract.amount'
  ),
  totalBudgetHours: Hours,
  totalBudgetHoursRemaining: Hours,
  totalScopeHoursCompleted: Percent,
  totalEstimatedBudgetHoursRemaining: Variance,
  totalEstimatedHours: Hours,
  totalActualHours: Hours,
  totalEstimatedHoursAtCompletion: Hours,
  totalTaskHours: withHighlighting(
    HoursHideZero,
    'totalTaskHours',
    'budgetHours'
  ),
  taskHours: Hours,
  totalEstimatedCost: Money,
  totalBudgetedCost: Money,
  totalBudgetCostAmountInPortfolioCurrency: Money,
  totalActualExpenseCost: Money,
  totalActualResourceCost: Money,
  totalActualCost: Money,
  totalBudgetCostUsedPercentage: Percent,
  totalEstimatedBudgetCostRemaining: MoneyVariance,
  totalEstimatedBudgetCostRemainingInPortfolioCurrency: MoneyVariance,
  totalActualCostAmountInPortfolioCurrency: Money,
  totalEstimatedCostAtCompletion: Money,
  totalEstimatedCostAtCompletionInPortfolioCurrency: Money,
  totalEstimatedBilling: Money,
  totalActualBilling: Money,
  initialTaskStartDate: Date,
  estimatedTaskCompletionDate: Date,
  finalTaskEndDate: Date,
  tag,
  department: GroupColumn,
  employeeType: GroupColumn,
  location: GroupColumn,
  division: GroupColumn,
  serviceCenter: GroupColumn,
  costCenter: GroupColumn
};
