import React from 'react';
import PropTypes from 'prop-types';
import { BillingTransactionStatusBadge } from '~/modules/billing-invoicing/common/components/BillingTransactionStatusBadge';

export const Status = ({ field, record }) => {
  const balanceStatus = record[field];
  const transactionType = record.type;

  const billStatus = record.billStatus ? record.billStatus : null;
  const draftSubStatus = record.draftSubStatus ? record.draftSubStatus : null;

  return (
    <BillingTransactionStatusBadge
      balanceStatus={balanceStatus}
      transactionType={transactionType}
      billStatus={billStatus}
      draftSubStatus={draftSubStatus}
    />
  );
};
Status.propTypes = {
  record: PropTypes.object,
  field: PropTypes.string
};

export default Status;
