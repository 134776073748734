import React from 'react';
import { PropTypes } from 'prop-types';
import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from './SectionHeader';

const useSectionHeaderStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const useCardContentStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

export const ReadOnlySection = ({ title, editable, onEdit, children }) => {
  const sectionHeaderStyles = useSectionHeaderStyles();
  const cardContentStyles = useCardContentStyles();

  return (
    <>
      <SectionHeader
        classes={sectionHeaderStyles}
        editable={editable}
        title={title}
        onEditClick={onEdit}
      >
        <CardContent classes={cardContentStyles}>
          {React.Children.toArray(children)
            .filter(child => child.type.displayName === 'ReadOnlyContent')
            .map(child => child.props.children)}
        </CardContent>
      </SectionHeader>
    </>
  );
};

ReadOnlySection.propTypes = {
  title: PropTypes.node,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  children: PropTypes.node
};

export default ReadOnlySection;
