import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';

export const RateCardCopyDropdown = ({ value, onChange, ...rest }) => {
  const me = useMeContext();
  const {
    featureFlags: { isPsaFpAdvancedRateCardEnabled }
  } = me;
  const intl = useIntl();

  const rateCardCopyOptions = isPsaFpAdvancedRateCardEnabled
    ? ['urn:replicon:rate-table-entry-copy-option:copy-from-project']
    : [
        'urn:replicon:rate-table-entry-copy-option:copy-from-project',
        'urn:replicon:rate-table-entry-copy-option:copy-from-client'
      ];

  const options = rateCardCopyOptions.map(key => ({
    id: key,
    displayText: intl.formatMessage({ id: `rateCardCopyOptions.${key}` })
  }));

  const selectedValue = useMemo(() => options.find(x => x.id === value), [
    options,
    value
  ]);

  return (
    <SimpleDropdown
      options={options}
      onChange={onChange}
      value={selectedValue}
      disableSort
      {...rest}
    />
  );
};

RateCardCopyDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default RateCardCopyDropdown;
