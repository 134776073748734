import React from 'react';

import { useIntl } from 'react-intl';
import { SummaryCardHelp } from '~/modules/common/components';

const AdjustWorkDaysHelp = () => {
  const intl = useIntl();

  const toolTipContent = content(intl);

  return <SummaryCardHelp content={toolTipContent} />;
};

const content = intl => [
  {
    message: intl.formatMessage({
      id: 'rescheduleProject.adjustWorkDaysHelpTitle'
    })
  },
  {
    message: intl.formatMessage({
      id: 'rescheduleProject.adjustWorkDaysHelpDescription'
    })
  }
];

export default AdjustWorkDaysHelp;
