import { RESOURCE_ALLOCATION_CHART_TAB_ENUM } from '~/modules/resourcing/hooks/useResourceAllocationChartTabStateContext';
import { ResourceRequestStatus } from '~/types';

export const getResourceRequestStatusListFromPlanStatus = (
  planStatus,
  isProjectManagerActionsEnabled
) => {
  return planStatus === RESOURCE_ALLOCATION_CHART_TAB_ENUM.PENDING
    ? [
        ResourceRequestStatus.Submitted,
        ResourceRequestStatus.Allocationrejected
      ]
    : planStatus === RESOURCE_ALLOCATION_CHART_TAB_ENUM.PROPOSED
    ? [ResourceRequestStatus.Tentative]
    : planStatus === RESOURCE_ALLOCATION_CHART_TAB_ENUM.TOBEHIRED
    ? [ResourceRequestStatus.Tobehired]
    : [
        ...(isProjectManagerActionsEnabled
          ? [ResourceRequestStatus.Draft]
          : []),
        ResourceRequestStatus.Submitted,
        ResourceRequestStatus.Allocationrejected,
        ResourceRequestStatus.Complete,
        ResourceRequestStatus.Tentative,
        ResourceRequestStatus.Tobehired,
        ResourceRequestStatus.Rejected
      ];
};
