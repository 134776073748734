import React from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

export const ShowMoreBillsButton = ({ onShowMore }) => (
  <Button size="small" onClick={onShowMore}>
    <FormattedMessage id="associatedBillCredits.showMoreBills" />
  </Button>
);

ShowMoreBillsButton.propTypes = {
  onShowMore: PropTypes.func.isRequired
};

export default ShowMoreBillsButton;
