import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  section: {
    margin: 0,
    padding: 0
  },
  expandButton: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandIconOpen: {
    transform: 'rotate(180deg)'
  },
  label: {
    textTransform: 'none',
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden'
  },
  primary: {
    display: 'flex',
    alignItems: 'center'
  },
  secondary: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  content: {
    padding: theme.spacing(0, 0, 2, 0)
  },
  contentLast: {
    paddingBottom: 0
  }
}));

const createDefaultExpandedMap = sections =>
  sections.reduce((map, section) => {
    return {
      ...map,
      [section.key]: !!section.defaultExpanded
    };
  }, {});

export const ExpandableInfoSection = ({
  section,
  isLast,
  expandedMap,
  setExpandedMap,
  classes
}) => {
  const isExpanded = !!expandedMap[section.key];

  return (
    <li className={classes.section}>
      <Button
        id={`${section.key}_button`}
        component="div"
        onClick={useCallback(() => {
          setExpandedMap({ ...expandedMap, [section.key]: !isExpanded });
        }, [setExpandedMap, expandedMap, section.key, isExpanded])}
        startIcon={useMemo(
          () => (
            <ExpandMoreIcon
              className={classNames(classes.expandIcon, {
                [classes.expandIconOpen]: isExpanded
              })}
            />
          ),
          [classes.expandIcon, classes.expandIconOpen, isExpanded]
        )}
        className={classNames(classes.expandButton, {
          [classes.expandButtonOpen]: isExpanded
        })}
        aria-expanded={isExpanded ? 'true' : 'false'}
        aria-controls={section.key}
      >
        <div className={classes.label}>
          <div className={classes.primary}>
            {section.label}
            {section.labelTag}
          </div>
          {section.collapsedDetails ? (
            <Collapse in={!isExpanded} timeout="auto">
              <div
                className={classNames(
                  classes.secondary,
                  section.overrideClasses?.secondary
                )}
              >
                {section.collapsedDetails}
              </div>
            </Collapse>
          ) : null}
        </div>
      </Button>
      <Collapse in={isExpanded} timeout="auto">
        <div
          id={section.key}
          aria-labelledby={`${section.key}_button`}
          role="region"
          className={classNames(classes.content, {
            [classes.contentLast]: isLast
          })}
        >
          {section.content}
        </div>
      </Collapse>
    </li>
  );
};

ExpandableInfoSection.propTypes = {
  section: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    labelTag: PropTypes.node,
    collapsedDetails: PropTypes.node,
    content: PropTypes.node.isRequired,
    defaultExpanded: PropTypes.bool,
    overrideClasses: PropTypes.object
  }),
  isLast: PropTypes.bool,
  expandedMap: PropTypes.object,
  setExpandedMap: PropTypes.func,
  classes: PropTypes.object
};

const ExpandableInfoSections = ({ className, sections }) => {
  const classes = useStyles();

  const [expandedMap, setExpandedMap] = useState(
    createDefaultExpandedMap(sections)
  );

  return (
    <ul className={classNames(classes.root, className)}>
      {sections.map((section, sectionIndex) => (
        <ExpandableInfoSection
          key={section.key}
          section={section}
          isLast={sectionIndex === sections.length - 1}
          expandedMap={expandedMap}
          setExpandedMap={setExpandedMap}
          classes={classes}
        />
      ))}
    </ul>
  );
};

ExpandableInfoSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      labelTag: PropTypes.node,
      collapsedDetails: PropTypes.node,
      content: PropTypes.node.isRequired,
      defaultExpanded: PropTypes.bool
    }).isRequired
  ).isRequired,
  className: PropTypes.string
};

export default ExpandableInfoSections;
