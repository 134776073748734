import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useCurrencies } from './useCurrencies';

const handleChange = (options, onChange) => (_, { props }) =>
  onChange({
    displayText:
      props.value !== 'none' && options
        ? options.find(option => option.id === props.value).displayText
        : 'None',
    id: props.value === 'none' ? null : props.value
  });

const renderValue = ({ value }) => () => value && value.displayText;

const hiddenInputProps = {
  style: {
    visibility: 'hidden'
  }
};

const initialInputProps = {
  style: {
    visibility: 'initial'
  }
};

export const Currency = ({
  dataQeId,
  editable = true,
  fullWidth = false,
  ariaLabel = '',
  value,
  onChange,
  variant = 'standard',
  disablePortal,
  ...rest
}) => {
  const isPsaPrpAccessibilityIssuesEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpAccessibilityIssuesEnabled'
  });
  const currencies = useCurrencies();
  const handleOnChange = useCallback(handleChange(currencies, onChange), [
    currencies,
    onChange
  ]);
  const theme = useTheme();

  const menuProps = useMemo(
    () => ({
      disablePortal: Boolean(disablePortal),
      MenuListProps: { 'aria-label': ariaLabel }
    }),
    [ariaLabel, disablePortal]
  );

  return (
    <Select
      data-qe-id={dataQeId}
      MenuProps={menuProps}
      fullWidth={fullWidth}
      variant={variant}
      value={value && value.id}
      onChange={handleOnChange}
      disabled={!editable}
      IconComponent={!editable ? Icon : undefined}
      renderValue={renderValue({ value })}
      SelectDisplayProps={{
        style: {
          color: theme ? theme.palette.text.primary : undefined
        }
      }}
      inputProps={
        isPsaPrpAccessibilityIssuesEnabled
          ? hiddenInputProps
          : initialInputProps
      }
      {...rest}
    >
      {currencies.map(({ id, displayText, component }) => (
        <MenuItem value={id} key={id}>
          {displayText}
        </MenuItem>
      ))}
    </Select>
  );
};

Currency.propTypes = {
  ariaLabel: PropTypes.string,
  dataQeId: PropTypes.string,
  editable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  disablePortal: PropTypes.bool
};

export default Currency;
