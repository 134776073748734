import React from 'react';
import { PropTypes } from 'prop-types';

export const Edit = ({ children }) => <>{children}</>;

Edit.propTypes = {
  children: PropTypes.node
};

Edit.displayName = 'Edit';

export default Edit;
