import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PAGE_SIZE } from '~/modules/common/const';

export const RESOURCE_POOLS_QUERY = gql`
  query GetResourcePools(
    $page: Int!
    $pageSize: Int!
    $searchFilter: ResourcePoolSearchFilter!
  ) {
    resourcePools(
      page: $page
      pageSize: $pageSize
      searchFilter: $searchFilter
    ) {
      id
      displayText
    }
  }
`;

const mappedResourcePools = data =>
  (data?.resourcePools || []).map(pool => ({
    ...pool,
    value: pool.id,
    label: pool.displayText,
    key: pool.displayText
  }));

const useResourcePools = ({
  includeNoneOption = false,
  noneOptionText = '',
  searchFilter = {
    includeOnlyEnabled: true
  }
}) => {
  const { data, loading, refetch } = useQuery(RESOURCE_POOLS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchFilter,
      page: 1,
      pageSize: PAGE_SIZE + 1
    },
    context: {
      debounceKey: 'resource-pools-search',
      debounceTimeout: 250
    }
  });

  const resourcePools = !loading ? mappedResourcePools(data) : [];

  return {
    resourcePools: (includeNoneOption
      ? prependNoneItem(noneOptionText, resourcePools)
      : resourcePools
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    loading,
    refetch,
    hasMore: (resourcePools || []).length > PAGE_SIZE
  };
};

export const fetchResourcePools = ({
  apolloClient,
  intl
}) => async searchTextParam => {
  const { data } = await apolloClient.query({
    query: RESOURCE_POOLS_QUERY,
    variables: {
      page: 1,
      searchFilter: {
        searchTextParam,
        includeOnlyEnabled: true
      },
      pageSize: PAGE_SIZE
    }
  });

  return prependNoneItem(
    intl.formatMessage({
      id: 'resourcePoolSearchBox.noResourcePools'
    }),
    mappedResourcePools(data)
  );
};

export const useSearchableResourcePools = () => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const fetchOptions = fetchResourcePools({
    apolloClient,
    intl
  });

  return { fetchOptions };
};

export default useResourcePools;
