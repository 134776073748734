import { useEffect } from 'react';

import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import { ProjectManagementType } from '~/types';
import { useOverEstimatedTasksProjectsQuery } from './useOverEstimatedTasksProjectsQuery';
import { useLoadMoreOverEstimatedTasksProjects } from './useLoadMoreOverEstimatedTasksProjects';

export const DEFAULT_PAGE_SIZE = 10;

const calcHasMore = (projectsLength, totalProjects) =>
  projectsLength < totalProjects;

export const usePaginatedOverEstimatedTasksProjectsQuery = ({
  overEstimatedTaskType,
  page,
  setPage,
  hasMore,
  setHasMore
}) => {
  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();

  const isManaged = projectType === ProjectManagementType.Managed;

  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [isManaged, searchCriteria, setPage, setHasMore]);

  const {
    loading,
    overEstimatedProjects,
    totalProjects = 0,
    fetchMore
  } = useOverEstimatedTasksProjectsQuery({
    pageSize: DEFAULT_PAGE_SIZE,
    isManaged,
    searchCriteria,
    overEstimatedTaskType,
    quickFilter: summaryFilterState.quickFilter
  });

  useEffect(() => {
    setHasMore(calcHasMore(overEstimatedProjects.length, totalProjects));
  }, [overEstimatedProjects.length, setHasMore, totalProjects]);

  const { loadMore } = useLoadMoreOverEstimatedTasksProjects({
    page,
    setPage,
    hasMore,
    pageSize: DEFAULT_PAGE_SIZE,
    isManaged,
    searchCriteria,
    fetchMore,
    setHasMore,
    overEstimatedTaskType,
    quickFilter: summaryFilterState.quickFilter
  });

  return {
    loading,
    overEstimatedProjects,
    totalProjects,
    loadMore
  };
};
