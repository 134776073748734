import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import RevenueIcon from '@material-ui/icons/InsertChartSharp';
import { FormattedMessage } from 'react-intl';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { useMeContext } from '~/modules/me';
import {
  useProjectRevenue,
  useDefaultRevenueContract,
  usePutProjectRevenuePolicy,
  useRevenueState,
  useAsOfCloseDate
} from './hooks';
import { RevenueRecognitionCard } from './components';
import { editors } from './editors';

const avatar = <RevenueIcon />;
const title = <FormattedMessage id="projectRevenueRecognitionCard.title" />;

export const RevenueRecognition = ({ editable, projectUri }) => {
  const me = useMeContext();
  const {
    loading,
    revenue: scriptDetails,
    revenuePolicies,
    refetchRevenuePolicy
  } = useProjectRevenue(projectUri, me);
  const { loading: aocdLoading, date: asOfCloseDate } = useAsOfCloseDate({
    projectUri
  });
  const { defaultRevenueContract } = useDefaultRevenueContract();
  const { forecastBatchState, setForecastBatchState } = useRevenueState();
  const { putProjectRevenueContractDetails } = usePutProjectRevenuePolicy({
    projectId: projectUri,
    contractId: defaultRevenueContract.contractId,
    setForecastBatchState
  });
  const onSave = useCallback(
    async updatedPolicy => {
      await putProjectRevenueContractDetails(updatedPolicy);
    },
    [putProjectRevenueContractDetails]
  );

  return loading || aocdLoading ? (
    <CardLoading avatar={avatar} title={title} />
  ) : (
    <RevenueRecognitionCard
      key="revenue-recognition"
      editable={editable}
      projectUri={projectUri}
      scriptDetails={scriptDetails}
      onSave={onSave}
      revenuePolicies={revenuePolicies}
      avatar={avatar}
      title={title}
      customEditors={editors}
      refetchRevenuePolicy={refetchRevenuePolicy}
      forecastBatchState={forecastBatchState}
      setForecastBatchState={setForecastBatchState}
      asOfCloseDate={asOfCloseDate}
    />
  );
};

RevenueRecognition.propTypes = {
  editable: PropTypes.bool,
  projectUri: PropTypes.string
};

export default RevenueRecognition;
