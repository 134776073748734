import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  allocationRoot: {
    flexGrow: 1,
    flexShrink: 1,
    alignSelf: 'stretch',
    padding: 0,
    margin: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  roleItem: {
    flexBasis: 100
  },
  column: {
    width: 130
  },
  allocation: {
    flexShrink: 1,
    alignSelf: 'stretch',
    padding: 0,
    margin: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    },
    flexBasis: 240
  },
  allocationAndResource: {
    flexShrink: 1,
    alignSelf: 'stretch',
    padding: 0,
    marginTop: '8px',
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  resourceItems: {
    flexBasis: 240,
    display: 'flex',
    alignSelf: 'flex-start',
    flexShrink: 0,
    alignItems: 'stretch',
    flexDirection: 'column',
    padding: theme.spacing(0.5)
  },
  userItem: {
    backgroundColor: 'unset',
    padding: theme.spacing(0, 0, 0, 0.25),
    height: '26px'
  }
}));
