import React, { memo, useCallback, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ListTablePropTypes from '../../PropTypes';
import { TableRow, TableCell } from './components';

const CellRender = memo(
  ({ index, field, column, record, component: Component, dataQeId }) => (
    <Component
      index={index}
      field={field}
      column={column}
      record={record}
      dataQeId={dataQeId}
    />
  )
);

CellRender.propTypes = {
  index: PropTypes.number,
  field: PropTypes.string,
  column: PropTypes.object,
  record: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  dataQeId: PropTypes.string
};

export const RowRender = memo(
  ({
    record,
    columns,
    columnStyles,
    useInlineStyles,
    hover,
    classes,
    onRowClick,
    onCellClick,
    rowIndex,
    loading,
    selected,
    onRowSelectionChange
  }) => {
    const handleSelectionClick = useCallback(() => {
      onRowSelectionChange && onRowSelectionChange(record.id, !selected);
    }, [record.id, onRowSelectionChange, selected]);

    const inputProps = useMemo(
      () => ({
        'aria-label': record?.selectionCheckboxProps?.ariaLabel
      }),
      [record]
    );

    return (
      <TableRow
        key={record.key || record.id}
        record={record}
        className={classes.tableRow}
        hover={hover}
        onRowClick={onRowClick}
        data-qe-id={rowIndex}
        selected={selected}
      >
        {!!onRowSelectionChange && (
          <TableCell
            align="center"
            fixed
            className={classNames(
              classes.tableCell,
              classes.tabelCellSelection
            )}
            onCellClick={handleSelectionClick}
          >
            {loading ? (
              <Skeleton height={30} width={42} />
            ) : (
              <Checkbox
                checked={selected}
                className={classes.checkbox}
                inputProps={inputProps}
              />
            )}
          </TableCell>
        )}
        {Object.keys(columns).map((field, index) => {
          const dataQeId = record.id
            ? `${record.id}_${field}`
            : `row${rowIndex}_${field}`;

          return (
            <TableCell
              key={`${record.key || record.id}:${field}`}
              data-qe-id={dataQeId}
              fixed={columns[field].fixed}
              leftOfFixed={!columns[field].fixed}
              className={classNames(
                classes.tableCell,
                columns[field].className,
                {
                  [classes.tableFixedCellWithSelection]:
                    index === 0 && !!onRowSelectionChange,
                  [classes.tableCellDisabled]: Boolean(record.disabled)
                }
              )}
              style={useInlineStyles ? columnStyles[field] : undefined}
              align={columns[field].align}
              field={field}
              onCellClick={onCellClick}
            >
              <CellRender
                component={columns[field].render}
                field={field}
                column={columns[field]}
                record={record}
                index={rowIndex - 1}
                dataQeId={dataQeId}
              />
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
);

RowRender.propTypes = {
  record: PropTypes.any,
  columns: ListTablePropTypes.columns,
  columnStyles: PropTypes.object,
  classes: PropTypes.object,
  hover: PropTypes.bool,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  useInlineStyles: PropTypes.bool,
  rowIndex: PropTypes.number,
  loading: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  selected: PropTypes.bool
};

export default RowRender;
