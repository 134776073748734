import React from 'react';
import { PropTypes } from 'prop-types';
import EstimateItemMetric from './EstimateItemMetric';

export const PlaceholderEstimateContent = ({ initialEstimatedHours }) => {
  return (
    <EstimateItemMetric
      formatMessageKey="taskResourceEstimates.estimate"
      hours={initialEstimatedHours}
    />
  );
};

PlaceholderEstimateContent.propTypes = {
  initialEstimatedHours: PropTypes.number
};

export default PlaceholderEstimateContent;
