import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FormSelectField } from '~/modules/common/components';
import CustomFieldValuePropType from './CustomFieldValuePropType';

const useStyles = makeStyles({
  disableDropDowOption: { display: 'none' }
});

export const EditableDropDownCustomField = ({
  classes: overriddenClasses,
  customFieldValue,
  onChange,
  required,
  ...rest
}) => {
  const classes = useStyles({ classes: overriddenClasses });

  const {
    customField,
    customFieldDefinition,
    dropDownOptionUri
  } = customFieldValue;

  const { displayText: label } = customField;

  const { isRequired, dropDownOptions } = customFieldDefinition || {
    dropDownOptions: []
  };

  return (
    <FormSelectField
      shrink={Boolean(dropDownOptionUri)}
      editable
      label={label}
      value={dropDownOptionUri}
      onChange={onChange}
      required={required}
      {...rest}
    >
      {!isRequired && (
        <MenuItem value={null}>
          <FormattedMessage id="projectTasksPage.none" />
        </MenuItem>
      )}
      {dropDownOptions.map(option => (
        <MenuItem
          className={classNames(
            !option.isEnabled && classes.disableDropDowOption
          )}
          key={option.id}
          value={option.id}
        >
          {option.displayText}
        </MenuItem>
      ))}
    </FormSelectField>
  );
};

EditableDropDownCustomField.propTypes = {
  customFieldValue: CustomFieldValuePropType,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  classes: PropTypes.object
};

export default EditableDropDownCustomField;
