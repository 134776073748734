import { DimensionObjectType } from '~/types';
import { RateEditor } from './RateEditor';
import { DateEditor } from './DateEditor';
import { DimensionValueEditor } from './DimensionValueEditor';
import { ResourceDimensionEditor } from './ResourceDimensionEditor';
import { AddRemoveEntry } from './AddRemoveEntry';
import { DefaultFormatter } from './DefaultFormatter';
import { RoleDimensionEditor } from './RoleDimensionEditor';

export const getFormatters = ({ projectDimensions }) => {
  const dynamicFormatters = projectDimensions.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.id]:
        curr.objectType === DimensionObjectType.ProjectRole
          ? RoleDimensionEditor
          : curr.objectType === DimensionObjectType.User
          ? ResourceDimensionEditor
          : DimensionValueEditor
    }),
    {}
  );

  return {
    ...dynamicFormatters,
    default: DefaultFormatter,
    effectiveDate: DateEditor,
    amount: RateEditor,
    addRemoveEntry: AddRemoveEntry
  };
};
