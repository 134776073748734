import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';

export const MY_PROGRAMS_DROPDOWN_QUERY = gql`
  query MyProgramDropdownOptions($pageSize: Int) {
    myActivePrograms(page: 1, pageSize: $pageSize) {
      id
      slug
      displayText
    }
  }
`;

const programDropdownOptionMapper = program => ({
  label: program.displayText,
  slug: program.slug,
  value: program.id
});

const useMyProgramDropDownOptionsForProject = skip => {
  const { data, loading } = useQuery(MY_PROGRAMS_DROPDOWN_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { pageSize: PAGE_SIZE + 1 },
    skip
  });

  const programs = loading
    ? []
    : get(data, 'myActivePrograms', []).map(programDropdownOptionMapper);

  return {
    options: programs.slice(0, PAGE_SIZE),
    hasMore: programs.length > PAGE_SIZE,
    loading
  };
};

export default useMyProgramDropDownOptionsForProject;
