import MuiDrawer from '@material-ui/core/Drawer';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import { CreditMemoIcon } from '~/modules/common/components/Icons';
import {
  DrawerHeader,
  DrawerFooter
} from '~/modules/billing-invoicing/common/components';
import {
  useFormOnCancel,
  useSaving,
  useNextReferenceNumberText
} from '~/modules/billing-invoicing/common/hooks';
import {
  useAssociatedAndOutStandingBills,
  usePutCreditMemoFormState,
  usePutCreditMemo,
  useCreditMemoTotalProps
} from '~/modules/billing-invoicing/creditMemo/hooks';
import { styles } from '~/modules/billing-invoicing/common/inProgressStyle';
import { useMeContext } from '~/modules/me/useMeContext';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';
import { CreditMemoDetails } from '../CreditMemoDetails';

const useStyles = makeStyles(styles);
const footerResourceKeys = {
  actionButton: 'addDialog.addCreditMemo'
};

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

export const AddCreditMemoDrawer = ({
  open,
  onClose,
  projectUri,
  projectName,
  client,
  billId,
  billBalanceTotal,
  showAllBills
}) => {
  const classes = useStyles();
  const drawerClasses = useDrawerStyles();
  const me = useMeContext();
  const { saving, setSaving } = useSaving();
  const { putCreditMemo } = usePutCreditMemo();
  const { associatedAndOutstandingBills } = useAssociatedAndOutStandingBills({
    me,
    billBalanceTotal,
    client,
    billId
  });
  const { nextReferenceNumberText } = useNextReferenceNumberText(
    billingTransactionType.CREDITMEMO
  );
  const formik = usePutCreditMemoFormState({
    me,
    client,
    projectUri,
    projectName,
    setSaving,
    onClose,
    billBalanceTotal,
    associatedAndOutstandingBills,
    billId,
    putCreditMemo,
    nextReferenceNumberText
  });
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    errors,
    isSubmitting
  } = formik;
  const {
    lineItemsTotal,
    associatedBillsTotal,
    creditMemoBalanceTotal
  } = useCreditMemoTotalProps({ values });
  const { onCancel } = useFormOnCancel({ handleReset, onClose, saving });

  return (
    <MuiDrawer anchor="right" open={open} classes={drawerClasses}>
      <DrawerHeader
        onClose={onCancel}
        entityName={client && client.displayText}
        TitleIcon={CreditMemoIcon}
      >
        <FormattedMessage id="addDialog.addCreditMemo" />
      </DrawerHeader>
      {saving ? (
        <Grid container className={classes.container} spacing={2}>
          <CircularProgress
            data-qe-id="addCreditMemo_saving"
            size={24}
            className={classes.progress}
          />
        </Grid>
      ) : (
        <>
          <CreditMemoDetails
            me={me}
            values={values}
            hasClient={Boolean(client && client.id)}
            setFieldValue={setFieldValue}
            errors={errors}
            lineItemsTotal={lineItemsTotal}
            associatedBillsTotal={associatedBillsTotal}
            projectUri={projectUri}
            projectName={projectName}
            showAllBills={showAllBills}
            billId={billId}
            isSubmitting={isSubmitting}
          />
          <DrawerFooter
            balanceTotal={creditMemoBalanceTotal}
            isSaving={saving}
            resourceKeys={footerResourceKeys}
            onAdd={handleSubmit}
            onCancel={onCancel}
          />
        </>
      )}
    </MuiDrawer>
  );
};

AddCreditMemoDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  projectUri: PropTypes.string,
  projectName: PropTypes.string,
  client: PropTypes.object,
  billId: PropTypes.string,
  billBalanceTotal: PropTypes.object,
  showAllBills: PropTypes.bool
};

export default AddCreditMemoDrawer;
