import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { isoStringToObject } from '~/modules/common/dates/convert';

export const AS_OF_CLOSE_DATE = gql`
  query getLatestRevenueCloseDateForProject($projectId: String) {
    project(projectId: $projectId) {
      id
      latestRevenueCloseDate
    }
  }
`;

export const useAsOfCloseDate = ({ projectUri }) => {
  const { data, loading } = useQuery(AS_OF_CLOSE_DATE, {
    variables: {
      projectId: projectUri
    },
    skip: !projectUri,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const date = isoStringToObject(
    get(data, 'project.latestRevenueCloseDate', null)
  );

  return { loading, date };
};

export default useAsOfCloseDate;
