import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import get from 'lodash.get';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

export const GET_RESOURCE_AVAILABILITY_HOURS_SERIES = gql`
  query resourceUserAvailabilityHoursSeriesForDateRange(
    $userUri: String!
    $dateRange: DateRangeInput!
  ) {
    resourceUserAvailabilityHoursSeriesForDateRange(
      userUri: $userUri
      dateRange: $dateRange
    ) {
      hours
      date
    }
  }
`;

const getHoursForDateRange = ({ dateRange, availabilityHoursSeriesByDay }) => {
  if (availabilityHoursSeriesByDay.length === 0) return [];

  let runningDate = mapIsoStringtoUtcObject(dateRange.startDate);
  const endDate = mapIsoStringtoUtcObject(dateRange.endDate);
  const datesHours = [];

  const availabilityHoursSeriesByDayMap = availabilityHoursSeriesByDay.reduce(
    (acc, entry) => ({ ...acc, [entry.date]: entry }),
    {}
  );

  while (runningDate <= endDate) {
    const entryValue = availabilityHoursSeriesByDayMap[runningDate];

    datesHours.push(entryValue || { date: runningDate, hours: 0 });
    runningDate = runningDate.plus({ days: 1 });
  }

  return datesHours;
};

const mapResults = (result, isLoading, dateRange) => {
  const availabilityHoursSeriesByDay = get(
    result,
    'resourceUserAvailabilityHoursSeriesForDateRange',
    []
  );

  return {
    availabilityHoursSeriesByDay: getHoursForDateRange({
      dateRange,
      availabilityHoursSeriesByDay: availabilityHoursSeriesByDay.map(r => ({
        ...r,
        date: mapIsoStringtoUtcObject(r.date)
      }))
    }),
    isLoading
  };
};

const useResourceUserAvailabilityHoursSeriesForDateRange = ({
  userUri,
  dateRange
}) => {
  const { data, loading } = useQuery(GET_RESOURCE_AVAILABILITY_HOURS_SERIES, {
    variables: {
      userUri,
      dateRange
    },
    fetchPolicy: 'cache-and-network'
  });

  return mapResults(data, loading, dateRange);
};

export const useResourceUserAvailabilityHoursSeriesForDateRange2 = ({
  userUri,
  dateRange
}) => {
  const { data, loading } = useQuery(GET_RESOURCE_AVAILABILITY_HOURS_SERIES, {
    variables: {
      userUri,
      dateRange
    }
  });

  return mapResults(data, loading, dateRange);
};

export const getResourceUserAvailabilityHoursSeriesForDateRangeCallback = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const apolloClient = useApolloClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getResourceUserAvailabilityHoursSeriesForDateRange = useCallback(
    async (userUri, dateRange) => {
      const { data, loading } = await apolloClient.query({
        query: GET_RESOURCE_AVAILABILITY_HOURS_SERIES,
        variables: {
          userUri,
          dateRange
        },
        fetchPolicy: 'network-only'
      });

      return mapResults(data, loading, dateRange);
    },
    [apolloClient]
  );

  return {
    getResourceUserAvailabilityHoursSeriesForDateRange
  };
};

export default useResourceUserAvailabilityHoursSeriesForDateRange;
