import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  calculateContainer: {
    padding: theme.spacing(1, 2)
  },
  noCalculation: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  noDataMessage: {
    width: '100%',
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-line'
  },
  recalculateContainer: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.warning.light,
    left: 0,
    position: 'sticky'
  },
  startingMonthContainer: {
    padding: theme.spacing(0.75, 2),
    whiteSpace: 'pre-line',
    backgroundColor: '#e4f3fd',
    left: 0,
    position: 'sticky'
  },
  startingMonthMessage: {
    display: 'flex',
    flexDirection: 'column'
  },
  info: {
    marginTop: theme.spacing(2)
  },
  daysOld: {
    fontWeight: 500
  },
  recalculate: {
    color: theme.palette.primary.main,
    padding: 0,
    marginLeft: '2px'
  },
  startMonthHeading: {
    lineHeight: 1
  },
  iconText: {
    paddingLeft: theme.spacing(2)
  },
  bulletListIcon: {
    fontSize: '0.5rem',
    paddingRight: theme.spacing(0.25)
  },
  removeBtn: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end'
  },
  errorTitle: {
    display: 'flex',
    width: '100%'
  },
  errorContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.light,
    left: 0,
    position: 'sticky'
  },
  errorHeading: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    fontWeight: 500,
    fontSize: theme.typography.subtitle2.fontSize
  },
  errorMessage: {
    marginLeft: theme.spacing(4),
    fontSize: theme.typography.body2.fontSize
  }
}));
