import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { PAGE_SIZE } from '~/modules/common/const';

export const GET_PROJECTS_QUERY = gql`
  query GetPageOfProjectsFilteredByDataAccessLevels(
    $page: Int!
    $pageSize: Int!
    $searchTerm: String
    $dataAccessLevels: [String]
  ) {
    getPageOfProjectsFilteredByDataAccessLevels(
      page: $page
      pageSize: $pageSize
      searchTerm: $searchTerm
      dataAccessLevels: $dataAccessLevels
    ) {
      id
      displayText
    }
  }
`;

export const useSearchableProjects = ({
  dataAccessLevels = [
    'urn:replicon:project-data-access-level:project-manager',
    'urn:replicon:project-data-access-level:program-manager',
    'urn:replicon:project-data-access-level:client-manager',
    'urn:replicon:project-data-access-level:project-management-data-access-scope'
  ],
  searchTerm
}) => {
  const { data, loading } = useQuery(GET_PROJECTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pageSize: PAGE_SIZE + 1,
      searchTerm,
      dataAccessLevels
    },
    context: {
      debounceKey: 'project-search-option',
      debounceTimeout: 250
    }
  });

  const projects = get(data, 'getPageOfProjectsFilteredByDataAccessLevels', []);

  const projectsToShowInList = (projects || [])
    .slice(0, PAGE_SIZE)
    .map(project => ({
      ...project,
      value: project.id,
      label: project.displayText,
      key: project.displayText
    }));

  return {
    projects: projectsToShowInList,
    loading,
    hasMore: projects.length > PAGE_SIZE
  };
};
