import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import FormattedDeltaChip from '../../FormattedDeltaChip/FormattedDeltaChip';
import EstimateItemMetric from './EstimateItemMetric/EstimateItemMetric';

export const useStyles = makeStyles(theme => ({
  estimate: {
    display: 'flex',
    pointerEvents: 'none'
  },
  variance: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    marginBottom: theme.spacing(2)
  },
  icon: {
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  margin: {
    margin: theme.spacing(0, 0, 2)
  }
}));

export const useVarianceStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold',
    margin: theme.spacing(0, 0, 0.5)
  },
  diffPositive: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark
  }
}));

const getValueOrDefault = value => value || 0;

export const ResourceEstimateContent = ({ resourceEstimate }) => {
  const classes = useStyles();
  const varianceClasses = useVarianceStyles();
  const costVariance = false;

  const {
    initialEstimatedHours,
    taskResourceUserHoursProgressSummary
  } = resourceEstimate;

  const { actualHours, estimatedAtCompletionHours, estimatedRemainingHours } =
    taskResourceUserHoursProgressSummary || {};
  const hoursVariance =
    getValueOrDefault(estimatedAtCompletionHours) -
    getValueOrDefault(initialEstimatedHours);

  return (
    <>
      <EstimateItemMetric
        formatMessageKey="taskResourceEstimates.actual"
        hours={getValueOrDefault(actualHours)}
      />
      <span className={classes.icon}>
        <AddSharp />
      </span>
      <EstimateItemMetric
        formatMessageKey="taskResourceEstimates.workRemaining"
        hours={estimatedRemainingHours}
      />
      <Typography className={classes.icon}>=</Typography>
      <div className={classes.estimate}>
        <EstimateItemMetric
          formatMessageKey="taskResourceEstimates.estimate"
          hours={estimatedAtCompletionHours}
        />
        <div className={classes.variance}>
          {Boolean(hoursVariance) && (
            <FormattedDeltaChip
              isHour
              showAddIcon={hoursVariance > 0}
              isRoundedValue={false}
              classes={varianceClasses}
              formattedMessageKey="taskResourceEstimates.hours"
              value={hoursVariance}
            />
          )}
          {Boolean(costVariance) && (
            <FormattedDeltaChip
              value={costVariance}
              prefix="$"
              showAddIcon={costVariance > 0}
            />
          )}
        </div>
      </div>
    </>
  );
};

ResourceEstimateContent.propTypes = { resourceEstimate: PropTypes.object };

export default ResourceEstimateContent;
