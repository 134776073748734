import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LineChartIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 2,3 V 21 H 20 V 19 H 4 V 3 Z M 18.4140625,6.26953125 14.080078125,10.60546875 11.125,7.744140625 l -5.767578125,5.5 1.3046875,1.404296875 L 11.125,10.40625 14.080078125,13.58203125 19.828125,7.744140625 Z" />
    </>
  </SvgIcon>
));

export default LineChartIcon;
