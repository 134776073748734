import React from 'react';
import PropTypes from 'prop-types';
import { MoneyValue, NoValue } from '~/modules/common/components';

export const Money = ({ field, record, className }) => {
  const money = record[field];

  return money ? (
    <MoneyValue dataQeId={record?.id} money={money} className={className} />
  ) : (
    <NoValue />
  );
};

export const MoneyHideZero = ({ field, record, className }) => {
  const money = record[field];

  return money?.amount ? (
    <MoneyValue dataQeId={record?.id} money={money} className={className} />
  ) : (
    <NoValue />
  );
};

Money.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string
};

MoneyHideZero.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string
};

export default Money;
