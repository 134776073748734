import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import get from 'lodash.get';

import { isNumeric } from '~/modules/common/numbers';
import { NoValue } from '~/modules/common/components';
import { NUMERIC_TYPE_URI } from '../definitionTypes';
import CustomFieldValuePropType from './CustomFieldValuePropType';

export const CustomFieldValueText = ({
  customFieldValue,
  noneValue = <NoValue />
}) => {
  const value = customFieldValue.text;
  const hasValue = Boolean(value);

  const isNumericType =
    customFieldValue.customFieldType.id === NUMERIC_TYPE_URI;

  const digits = get(
    customFieldValue,
    'customFieldDefinition.numericConfiguration.decimalPlaces',
    0
  );

  const formattedValue =
    isNumericType && hasValue && isNumeric(Number(value)) ? (
      <FormattedNumber
        value={value}
        minimumFractionDigits={digits}
        maximumFractionDigits={digits}
      />
    ) : hasValue ? (
      value
    ) : (
      noneValue
    );

  return formattedValue;
};

CustomFieldValueText.propTypes = {
  customFieldValue: CustomFieldValuePropType,
  noneValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default CustomFieldValueText;
