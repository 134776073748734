/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { PropTypes } from 'prop-types';
import { useMenuState } from '~/modules/common/hooks';
import QualityIcon from '../QualityIcon';
import QualityMenu from './QualityMenu';

export const QualityDropdown = ({
  quality,
  onChange,
  className,
  dataQeId,
  readOnly = false,
  qualityIconSize,
  ariaLabel
}) => {
  const intl = useIntl();
  const qualityTitle = intl.formatMessage({ id: 'qualityDropDown.quality' });

  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();
  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={qualityTitle} aria-label={qualityTitle}>
        <div>
          <IconButton
            data-qe-id={dataQeId}
            className={className}
            onClick={onMenuClick}
            aria-haspopup="true"
            disabled={readOnly}
            aria-label={ariaLabel}
          >
            <QualityIcon quality={quality} size={qualityIconSize} />
          </IconButton>
        </div>
      </Tooltip>
      <QualityMenu
        onChange={onChange}
        isOpen={isOpen}
        anchor={anchorEl}
        onClose={onMenuClose}
        quality={quality}
      />
    </>
  );
};

QualityDropdown.propTypes = {
  dataQeId: PropTypes.string,
  className: PropTypes.string,
  quality: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  qualityIconSize: PropTypes.string,
  ariaLabel: PropTypes.string
};

export default QualityDropdown;
