import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECT_RATE_COUNTS = gql`
  query getProjectBillingRateCounts($projectId: String!) {
    project(projectId: $projectId) {
      id
      projectEffectiveSystemRateCount
      projectEffectiveBillingRateCount
      userEffectiveBillingRateCount
    }
  }
`;
const useBillingRateCounts = ({ projectId }) => {
  const variables = {
    projectId
  };

  const { loading, error, data } = useQuery(GET_PROJECT_RATE_COUNTS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables
  });

  const project = (data && data.project) || {};

  return {
    loading,
    counts: {
      projectEffectiveSystemRateCount: project.projectEffectiveSystemRateCount,
      projectEffectiveBillingRateCount:
        project.projectEffectiveBillingRateCount,
      userEffectiveBillingRateCount: project.userEffectiveBillingRateCount
    },
    error
  };
};

export default useBillingRateCounts;
