import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { List } from '@material-ui/core';
import classNames from 'classnames';
import Date from '~/modules/common/components/Date';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex'
  },
  roleList: {
    paddingTop: 0,
    marginTop: 0,
    paddingBottom: 0
  },
  primaryRoleWithAdditionalRole: {
    paddingRight: theme.spacing(2.25)
  },
  additionalRole: {
    paddingRight: theme.spacing(0.5)
  },
  primaryRole: {
    paddingRight: theme.spacing(0.5)
  },
  noRole: {
    fontStyle: 'italic'
  },
  listSpacing: {
    paddingBottom: theme.spacing(1)
  },
  effectiveDate: {
    display: 'flex',
    margin: 0,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const UserWithRoleScheduleToolTip = ({ effectiveSchedule = {} }) => {
  const classes = useStyles();
  const primaryRole = (effectiveSchedule.projectRoles || []).find(
    role => role.isPrimary
  );
  const additionalRoles = (effectiveSchedule.projectRoles || []).filter(
    role => !role.isPrimary
  );

  const effectiveDate = effectiveSchedule && effectiveSchedule.effectiveDate;

  const primaryRoleHeading =
    additionalRoles.length > 0 ? (
      <div className={classes.primaryRoleWithAdditionalRole}>
        <FormattedMessage id="resourceRoles.primaryRole" />
      </div>
    ) : (
      <div className={classes.primaryRole}>
        <FormattedMessage id="resourceRoles.primaryRole" />
      </div>
    );

  return (
    <div>
      <div className={classes.list}>
        {primaryRoleHeading}
        {primaryRole ? (
          <List
            data-qe-id="PrimaryRoleList"
            className={classNames(
              classes.roleList,
              additionalRoles.length > 0 ? classes.listSpacing : ''
            )}
          >
            <div key={primaryRole.projectRole.name}>
              {primaryRole.projectRole.name}
            </div>
          </List>
        ) : (
          <div className={classes.noRole}>
            <FormattedMessage id="resourceRoles.noRole" />
          </div>
        )}
      </div>

      {additionalRoles.length > 0 && (
        <div className={classes.list}>
          <div className={classes.additionalRole}>
            <FormattedMessage id="resourceRoles.additionalRoles" />:
          </div>
          <List
            data-qe-id="AdditionalRoleList"
            className={classNames(
              classes.roleList,
              effectiveDate ? classes.listSpacing : ''
            )}
          >
            {additionalRoles.map(role => (
              <div key={role.projectRole.name} className={classes.roleName}>
                {role.projectRole.name}
              </div>
            ))}
          </List>
        </div>
      )}
      {effectiveDate && (
        <p className={classes.effectiveDate}>
          <FormattedMessage id="roleInfoCard.asOfDate" />
          &nbsp;
          <Date value={effectiveDate} className={classes.effectiveDate} />
        </p>
      )}
    </div>
  );
};

UserWithRoleScheduleToolTip.propTypes = {
  effectiveSchedule: PropTypes.object.isRequired
};

export default UserWithRoleScheduleToolTip;
