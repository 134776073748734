import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Decimal, NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  item: {
    margin: 0,
    display: 'flex',
    padding: '8px',
    flexGrow: 1,
    flexBasis: '100px',
    flexShrink: 1,
    flexDirection: 'column'
  },
  label: {
    color: theme.palette.text.secondary,
    lineHeight: 1.3
  }
}));

export const MobileBillingTransactionListItemAmount = ({
  labelResourceKey,
  amount
}) => {
  const classes = useStyles();

  const prefix =
    amount && amount.currency ? `${amount.currency.symbol} ` : null;

  return (
    <Grid className={classes.item} item>
      <Typography className={classes.label} variant="caption" component="label">
        <FormattedMessage id={labelResourceKey} />
      </Typography>
      {amount ? <Decimal prefix={prefix} value={amount.amount} /> : <NoValue />}
    </Grid>
  );
};

MobileBillingTransactionListItemAmount.propTypes = {
  labelResourceKey: PropTypes.string.isRequired,
  amount: PropTypes.object
};

export default memo(MobileBillingTransactionListItemAmount);
