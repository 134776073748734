import { useCallback } from 'react';

const useFormOnChange = ({
  statuses,
  setFieldValue,
  projectStatusFilterOption,
  onDialogClose
}) => {
  const onStatusChange = status => {
    const newStatus = statuses.includes(status)
      ? statuses.filter(s => s !== status)
      : [...statuses, status];

    setFieldValue('statuses', newStatus);
  };

  const onOptionChange = useCallback(
    event => setFieldValue('option', event.target.value),
    [setFieldValue]
  );

  const onCancel = () => {
    onDialogClose();
    setFieldValue('option', projectStatusFilterOption.option);
    setFieldValue('statuses', projectStatusFilterOption.statuses);
  };

  return {
    onStatusChange,
    onOptionChange,
    onCancel
  };
};

export default useFormOnChange;
