import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import BillingRateTable from './BillingRateTable';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: 0
  },
  noItemText: {
    padding: theme.spacing(2),
    fontStyle: 'italic'
  }
}));

export const BillingRateReadOnly = ({
  rateType,
  rates,
  loadMoreRows,
  hasMoreRows,
  count,
  rateTitle
}) => {
  const classes = useStyles();
  const titleValues = useMemo(() => ({ rateTitle }), [rateTitle]);

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12}>
        {count ? (
          <BillingRateTable
            rateType={rateType}
            billingRates={rates}
            hasMoreRows={hasMoreRows}
            loadMoreRows={loadMoreRows}
          />
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.noItemText}
          >
            <FormattedMessage
              id="billingRateCard.noItem"
              values={titleValues}
            />
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

BillingRateReadOnly.propTypes = {
  rateType: PropTypes.string,
  rates: PropTypes.array,
  loadMoreRows: PropTypes.func,
  hasMoreRows: PropTypes.bool,
  count: PropTypes.number,
  rateTitle: PropTypes.string
};

export default BillingRateReadOnly;
