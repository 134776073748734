import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const ConditionalTooltip = ({ condition, children, ...rest }) =>
  condition ? <Tooltip {...rest}>{children}</Tooltip> : children;

ConditionalTooltip.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.node
};

export default ConditionalTooltip;
