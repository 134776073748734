import { useCallback } from 'react';

const useEventHandlers = ({ openDialogAndSetInitialClient, onChange }) => {
  const handleOnChange = useCallback(
    v => {
      if (v?.isCreatable) {
        openDialogAndSetInitialClient(v?.label);
      }
      onChange(v);
    },
    [onChange, openDialogAndSetInitialClient]
  );

  const handleOnCancel = useCallback(() => {
    onChange(null);
  }, [onChange]);

  return { handleOnChange, handleOnCancel };
};

export default useEventHandlers;
