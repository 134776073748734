import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { useProjectManagers } from '~/modules/common/hooks';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import FacetDetailField from '../../FacetDetailField';
import UserItem from '../components/UserACItem';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';

const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : (
    <UserItem option={option} />
  );

export const ProjectCoManagerFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const { formatMessage } = useIntl();
  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );

  const [searchTerm, setSearchTerm] = useState('');

  const onValueChange = useCallback(
    selectedOptions => onChange({ selectedOptions, setSelected }),
    [setSelected]
  );

  const classes = useStyles();

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const {
    options: projectCoManagers,
    isLoading,
    hasMore
  } = useProjectManagers({ searchTerm });

  const options = !isLoading ? projectCoManagers : [];

  return (
    <FacetDetailField
      label={formatMessage({ id: 'search.projectCoManager' })}
      className={className}
    >
      <SimpleAutocomplete
        options={options}
        variant="standard"
        loading={isLoading}
        onInputChange={onInputChange}
        getOptionDisabled={getOptionDisabled}
        inputLabel={formatMessage({ id: 'search.projectCoManager' })}
        hiddenLabel
        noOptionsText={formatMessage({ id: 'project.noProjectCoManager' })}
        onChange={onValueChange}
        fullWidth
        value={selected}
        optionPropText="label"
        classes={classes}
        multiple
        renderOption={renderOption}
        hasMore={hasMore}
        optionTypeText={formatMessage({ id: 'moreOptions.projectCoManagers' })}
      />
    </FacetDetailField>
  );
};

ProjectCoManagerFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ProjectCoManagerFacetDetails;
