const acceptedFileTypes = [
  '.txt',
  '.jpg',
  '.png',
  '.pdf',
  '.tiff',
  '.html',
  '.doc',
  '.docx',
  '.bmp',
  '.gif',
  '.aac',
  '.avi',
  '.bmp',
  '.eml',
  '.heic',
  '.heif',
  '.jpeg',
  '.m4a',
  '.m4v',
  '.mkv',
  '.mov',
  '.mp3',
  '.mp4',
  '.mpe',
  '.mpeg',
  '.mpg',
  '.odp',
  '.ods',
  '.odt',
  '.oga',
  '.ogg',
  '.ogm',
  '.ogv',
  '.ppt',
  '.pptx',
  '.rtf',
  '.svg',
  '.tif',
  '.weba',
  '.webm',
  '.webp',
  '.xls',
  '.xlsx'
];

export default acceptedFileTypes;
