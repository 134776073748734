import React from 'react';
import { ResourcePoolIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';

export const useStyledPoolIcon = ({ omitColor } = {}) => {
  const classes = useStyles({ omitColor });

  return (
    <ResourcePoolIcon
      key="resourceManagementPools"
      className={classes.labelIcon}
    />
  );
};

export default useStyledPoolIcon;
