import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogContent,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  errorAlert: {
    color: theme.palette.text.primary
  }
}));

export const ErrorDialogContent = ({ title, error, onClose }) => {
  const classes = useStyles();
  const value = useMemo(
    () => ({
      title
    }),
    [title]
  );

  return (
    <>
      <DialogContent>
        <Alert icon={false} severity="error" className={classes.errorAlert}>
          {error?.reason || (
            <FormattedMessage
              id="bulkRemoveDialog.error.unknown"
              values={value}
            />
          )}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="bulkRemoveDialog.actions.ok" />
        </Button>
      </DialogActions>
    </>
  );
};

ErrorDialogContent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  error: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

export default ErrorDialogContent;
