import { PropTypes } from 'prop-types';
import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Date } from '~/modules/common/components';
import { MoneyValue } from '~/modules/common/components/Money';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: theme.spacing(40)
  },
  showMoreContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  rate: {
    whiteSpace: 'nowrap'
  },
  stickyHeader: {
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 3
  },
  stickyCell: {
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    left: 0
  }
}));

export const BillingRateTable = ({
  rateType,
  billingRates,
  hasMoreRows,
  loadMoreRows
}) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyHeader}>
              <FormattedMessage id="billingRateCard.columns.name" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="billingRateCard.columns.effectiveDate" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="billingRateCard.columns.rate" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billingRates.map(({ id, entity, rate, asOfDate }) => (
            <TableRow key={id}>
              <TableCell
                component="th"
                scope="row"
                className={classes.stickyCell}
              >
                {entity.displayText}
              </TableCell>
              <TableCell>
                {asOfDate ? (
                  <Date value={asOfDate} />
                ) : (
                  <FormattedMessage id="billingRateCard.table.initialRate" />
                )}
              </TableCell>
              <TableCell align="right">
                <MoneyValue money={rate} className={classes.rate} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {hasMoreRows && loadMoreRows ? (
        <Waypoint
          fireOnRapidScroll
          bottomOffset="-20%"
          onEnter={loadMoreRows}
          key={`readOnly-${rateType}-${billingRates.length}`}
        />
      ) : null}
    </TableContainer>
  );
};

BillingRateTable.propTypes = {
  rateType: PropTypes.string,
  billingRates: PropTypes.array,
  hasMoreRows: PropTypes.bool,
  loadMoreRows: PropTypes.func
};

export default BillingRateTable;
