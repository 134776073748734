import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
  VictoryContainer,
  VictoryTooltip
} from 'victory';
import { makeStyles } from '@material-ui/styles';
import { BarsChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import { useVictoryChartContainerStyles } from '~/modules/common/charts/dashboard/config';
import { ChartLegends } from '../ChartLegends';
import chartStyles from './chartStyles';
import { useProfitabilityChartHooks } from './hooks';

const CustomLabel = props => {
  const labelOverrideProps = useMemo(() => {
    const {
      datum: { value }
    } = props;

    return { text: value, dy: 12 };
  }, [props]);

  const toolTipOverrideProps = useMemo(() => {
    const { style, overrideStyles } = props;

    return { ...style, ...overrideStyles };
  }, [props]);

  return (
    <g data-qe-id={labelOverrideProps.text}>
      <VictoryLabel {...props} {...labelOverrideProps} />
      <VictoryTooltip {...props} style={toolTipOverrideProps} />
    </g>
  );
};

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;
CustomLabel.propTypes = {
  datum: PropTypes.object,
  style: PropTypes.object,
  overrideStyles: PropTypes.object
};

const customlabelComponent = overrideStyles => (
  <CustomLabel overrideStyles={overrideStyles.victoryTooltip} />
);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1.75, 0, 1.75)
  }
}));

export const ProfitabilityChart = ({
  intl,
  chartDates,
  setSelectedPeriod,
  loading,
  titleId = '',
  data,
  currencySymbol
}) => {
  const classes = useStyles();
  const victoryContainerClasses = useVictoryChartContainerStyles();

  const {
    yTickFormat,
    legends,
    xTickFormat,
    highestValue
  } = useProfitabilityChartHooks({
    intl,
    data
  });

  const styles = chartStyles(useTheme(), highestValue);

  const yTickLabel = useMemo(
    () => <VictoryLabel style={styles.axis.labelStyle} dx={-5} />,
    [styles.axis.labelStyle]
  );

  const xTickLabel = useMemo(
    () => <VictoryLabel style={styles.axis.labelStyle} dy={-10} />,
    [styles.axis.labelStyle]
  );

  const onDataClick = useCallback(
    (target, e) => {
      if (chartDates && e && e.datum && setSelectedPeriod) {
        setSelectedPeriod(chartDates.find(d => d.key === e.datum.key));
      }
    },
    [chartDates, setSelectedPeriod]
  );

  const events = useMemo(
    () => [
      {
        childName: 'all',
        target: 'data',
        eventHandlers: {
          onClick: onDataClick
        }
      }
    ],
    [onDataClick]
  );

  const currencyLabel = `${intl.formatMessage({
    id: 'profitabilityChart.profit'
  })}(${currencySymbol})`;

  if (loading) return <BarsChartLoading showLegends />;

  return (
    <div className={classes.root}>
      {legends && <ChartLegends legends={legends} />}
      <div className={victoryContainerClasses.container}>
        <VictoryChart
          height={styles.chart.height}
          width={styles.chart.width}
          padding={styles.chart.padding}
          domainPadding={styles.chart.domainPadding}
          events={events}
          containerComponent={<VictoryContainer aria-labelledby={titleId} />}
        >
          <VictoryAxis
            label={currencyLabel}
            dependentAxis
            domain={styles.axis.y.domainPadding}
            crossAxis={false}
            theme={VictoryTheme.material}
            style={styles.axis.y.style}
            tickLabelComponent={yTickLabel}
            tickFormat={yTickFormat}
          />
          <VictoryBar
            style={styles.victoryBar.style}
            labelComponent={customlabelComponent(styles)}
            barWidth={styles.victoryBar.barWidth}
            data={data}
            groupComponent={<g data-qe-id="chartBars" />}
          />
          <VictoryAxis
            theme={VictoryTheme.material}
            style={styles.axis.x.styleX1}
          />
          <VictoryAxis
            theme={VictoryTheme.material}
            offsetY={styles.axis.x.offsetY}
            style={styles.axis.x.styleX2}
            tickLabelComponent={xTickLabel}
            tickFormat={xTickFormat}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

ProfitabilityChart.propTypes = {
  intl: PropTypes.object.isRequired,
  chartDates: PropTypes.array.isRequired,
  setSelectedPeriod: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  titleId: PropTypes.string,
  currencySymbol: PropTypes.string
};

export default injectIntl(ProfitabilityChart);
