import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ChevronDateButtons } from '~/modules/common/components';
import useStyles, { useButtonStyles } from './useStyles';

const PageSelector = ({
  classes: classesOverride,
  onNextPage,
  onPreviousPage,
  resourceTotalCount,
  currentPage,
  pageSize,
  disablePageEvents = false,
  isCurrentPageEmpty = false
}) => {
  const classes = useStyles({ classes: classesOverride });
  const buttonStyles = useButtonStyles();

  const pageEndCount =
    resourceTotalCount >= currentPage * pageSize + 1
      ? pageSize * currentPage
      : resourceTotalCount;

  return (
    <div className={classes.root}>
      <div className={classes.pageList}>
        <p className={classes.pageNumber}>
          {isCurrentPageEmpty
            ? '0'
            : `${pageSize * (currentPage - 1) + 1} - ${pageEndCount}`}
        </p>
        <FormattedMessage id="quickAllocation.of" />
        <p className={classes.pageNumber}>{resourceTotalCount}</p>
      </div>
      <div className={classes.pageSelector}>
        <ChevronDateButtons
          onPreviousClick={onPreviousPage}
          onNextClick={onNextPage}
          classes={buttonStyles}
          disableLeftButton={disablePageEvents || currentPage === 1}
          disableRightButton={
            disablePageEvents || resourceTotalCount < currentPage * pageSize + 1
          }
        />
      </div>
    </div>
  );
};

PageSelector.propTypes = {
  classes: PropTypes.object,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  resourceTotalCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  disablePageEvents: PropTypes.bool,
  isCurrentPageEmpty: PropTypes.bool
};

export default PageSelector;
