import { getDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

export const getAllocationPeriodEditorDateRange = ({ periodDetails } = {}) =>
  getAllocationPeriodEditorDateRangeFromAllocation({
    scheduleRules: periodDetails.resourceAllocation?.scheduleRules,
    isPartial: !periodDetails.isOverlay,
    startDate: periodDetails.startDate,
    endDate: periodDetails.endDate
  });

export const getAllocationPeriodEditorDateRangeFromAllocation = ({
  scheduleRules,
  startDate,
  endDate,
  isPartial
} = {}) => {
  const allocationDateRange =
    scheduleRules && getDateRangeFromScheduleRules(scheduleRules);

  const allocationStartDate = mapIsoStringtoUtcObject(
    allocationDateRange.startDate
  );
  const allocationEndDate = mapIsoStringtoUtcObject(
    allocationDateRange.endDate
  );

  const periodStartDateWithAllocation =
    isPartial &&
    allocationStartDate > startDate &&
    allocationStartDate < endDate
      ? allocationStartDate
      : startDate;

  const periodEndDateWithAllocation =
    isPartial && allocationEndDate < endDate && allocationEndDate > startDate
      ? allocationEndDate
      : endDate;

  return {
    periodStartDate: periodStartDateWithAllocation,
    periodEndDate: periodEndDateWithAllocation
  };
};

export default getAllocationPeriodEditorDateRange;
