import { useMemo, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { object, string, number, mixed, array } from 'yup';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { mapCreditMemoToAssociatedBillsInput } from '~/modules/billing-invoicing/common/util';
import { toRepliconDate } from '~/modules/common/dates/convert';
import {
  getValidationErrorByUri,
  getValidationErrorByDisplayText
} from '~/modules/common/graphql/errors';

export const buildValidationSchema = ({ intl }) =>
  object().shape({
    description: string().max(
      255,
      intl.formatMessage({
        id: 'creditMemoDetails.validations.descriptionExceedsMaxLength'
      })
    ),
    displayId: string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'creditMemoDetails.validations.referenceNumberIsRequired'
        })
      )
      .max(
        255,
        intl.formatMessage({
          id: 'creditMemoDetails.validations.referenceNumberExceedsMaxLength'
        })
      ),
    client: object().shape({
      id: string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'creditMemoDetails.validations.clientIsRequired'
          })
        )
    }),
    creditDate: mixed().required(
      intl.formatMessage({
        id: 'creditMemoDetails.validations.creditDateRequired'
      })
    ),
    associatedBills: array().of(
      object().shape({
        allocatedBillAmount: object().test(
          'allocatedBillAmountTest',
          intl.formatMessage({
            id: 'creditMemoDetails.validations.allocatedBillAmountError'
          }),
          function test(value) {
            const { amount } = this.parent;

            return (
              parseFloat(amount.amount.toFixed(2)) ===
                parseFloat(value.amount.toFixed(2)) || value.amount > 0
            );
          }
        ),
        amount: object().test(
          'amountTest',
          intl.formatMessage({
            id: 'creditMemoDetails.validations.allocatedAmountError'
          }),
          function test(value) {
            const { balanceAmount, allocatedBillAmount } = this.parent;

            return (
              parseFloat(balanceAmount.amount.toFixed(2)) >=
              parseFloat(allocatedBillAmount.amount.toFixed(2))
            );
          }
        )
      })
    ),
    creditMemoBalanceTotal: number().min(
      0,
      intl.formatMessage({
        id:
          'creditMemoDetails.validations.totalAllocatedAmountExceedsBalanceError'
      })
    )
  });

export const useInitialState = ({
  projectUri,
  projectName,
  me: { baseCurrency },
  creditMemo = {},
  client,
  billBalanceTotal,
  associatedAndOutstandingBills = [],
  lineItems,
  key,
  nextReferenceNumberText
}) =>
  useMemo(
    () => ({
      id: creditMemo.id || null,
      displayId: creditMemo.displayId || nextReferenceNumberText || '',
      description: creditMemo.description || '',
      creditDate: creditMemo.creditDate || toRepliconDate(DateTime.local()),
      currency:
        creditMemo.currency ||
        (billBalanceTotal && billBalanceTotal.currency) ||
        baseCurrency,
      client: creditMemo.client || client || { id: '' },
      lineItems: lineItems || [
        {
          key,
          project: { id: projectUri, displayText: projectName },
          amount: {
            amount: (billBalanceTotal && billBalanceTotal.amount) || 0,
            currency:
              (billBalanceTotal && billBalanceTotal.currency) || baseCurrency
          }
        }
      ],
      associatedBills: associatedAndOutstandingBills,
      creditMemoBalanceTotal: 0
    }),
    [
      associatedAndOutstandingBills,
      baseCurrency,
      billBalanceTotal,
      client,
      creditMemo,
      key,
      lineItems,
      projectName,
      projectUri,
      nextReferenceNumberText
    ]
  );

export const useOnSubmit = ({
  billId,
  putCreditMemo,
  setSaving,
  onClose,
  intl
}) =>
  useCallback(
    async (values, { resetForm, setFieldError }) => {
      const {
        id,
        displayId,
        description,
        creditDate,
        client: { id: clientUri },
        currency: { id: currencyUri },
        lineItems,
        associatedBills
      } = values;

      setSaving(true);

      const lineItemInputs = lineItems.map(lineItem => ({
        id: lineItem.id,
        amount: lineItem.amount.amount,
        projectUri: lineItem.project ? lineItem.project.id : null
      }));

      const associatedBillsInput = associatedBills
        .filter(x => x.amount.amount > 0)
        .map(mapCreditMemoToAssociatedBillsInput);

      try {
        await putCreditMemo(
          {
            id,
            displayId,
            description,
            creditDate,
            clientUri,
            currencyUri,
            lineItems: lineItemInputs,
            associatedBills: associatedBillsInput
          },
          billId
        );
        resetForm();
        setSaving(false);
        onClose();
      } catch (exception) {
        setSaving(false);

        const displayIdError =
          getValidationErrorByUri(
            exception,
            'urn:replicon:validation-failure:client-credit-memo-display-id-duplicated'
          ) ||
          getValidationErrorByDisplayText(
            exception,
            'The specified ClientCreditMemo already exists.'
          );

        if (displayIdError) {
          setFieldError('displayId', 'duplicateReferenceNumber');
        }
      }
    },
    [setSaving, putCreditMemo, billId, onClose]
  );

export const usePutCreditMemoFormState = ({
  me,
  client,
  setSaving,
  onClose,
  projectUri,
  projectName,
  billBalanceTotal,
  associatedAndOutstandingBills,
  billId,
  putCreditMemo,
  creditMemo,
  lineItems,
  nextReferenceNumberText
}) => {
  const intl = useIntl();
  const [key] = useState(v4());

  const initialValues = useInitialState({
    projectUri,
    projectName,
    me,
    creditMemo,
    client,
    billBalanceTotal,
    associatedAndOutstandingBills,
    lineItems,
    key,
    nextReferenceNumberText
  });

  const onSubmit = useOnSubmit({
    billId,
    putCreditMemo,
    setSaving,
    onClose,
    intl
  });

  const validationSchema = useMemo(() => buildValidationSchema({ intl }), [
    intl
  ]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  });

  return formik;
};
export default usePutCreditMemoFormState;
