import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import useTag from '../hooks/useTag';

const useStyles = makeStyles(theme => ({
  listItem: {
    maxWidth: 224,
    overflow: 'hidden'
  },
  tagLabel: {
    maxWidth: 140,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const secondaryTypographyProps = {
  component: 'span',
  color: 'textPrimary'
};

const TagListItem = ({ onRemoveTag, index, tagId }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { loading, data } = useTag(tagId);
  const onClick = useCallback(
    e => {
      e.stopPropagation();
      onRemoveTag(tagId);
    },
    [onRemoveTag, tagId]
  );

  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        className={classes.tagLabel}
        secondaryTypographyProps={secondaryTypographyProps}
        secondary={!loading && data && data.tag && data.tag.name}
      />
      <IconButton
        aria-label={formatMessage({ id: 'button.delete' })}
        onClick={onClick}
      >
        <RemoveCircle color="secondary" fontSize="small" />
      </IconButton>
    </ListItem>
  );
};

TagListItem.propTypes = {
  onRemoveTag: PropTypes.func,
  index: PropTypes.number,
  tagId: PropTypes.string
};

export default TagListItem;
