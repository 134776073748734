import { useCallback } from 'react';

const useOnColumnSettingsSave = ({
  putTableSettings,
  setIsSaving,
  onClose,
  sort,
  selectedColumns,
  tableKey
}) =>
  useCallback(async () => {
    setIsSaving(true);

    const field =
      sort && sort.field
        ? sort.field === 'customField' && sort.customFieldUri
          ? sort.customFieldUri
          : sort.field
        : null;

    const shouldClearSort = field && !selectedColumns.includes(field);

    await putTableSettings({
      variables: {
        tableKey,
        columns: selectedColumns,
        sort: shouldClearSort ? null : undefined
      }
    });
    setIsSaving(false);
    onClose();
  }, [onClose, putTableSettings, selectedColumns, setIsSaving, sort, tableKey]);

export default useOnColumnSettingsSave;
