import { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { array, object, number, string, date } from 'yup';
import moment from 'moment';
import { useIntl } from 'react-intl';
import omit from 'lodash/fp/omit';
import {
  buildScheduleRulesWithDefaultConstraint,
  getResourceRequestDatesFromProjectDate,
  getTotalHours
} from '~/modules/resourcing/common/util';
import useGroupLabels from '~/modules/common/components/Groups/hooks/useGroupLabels';
import { ResourceRequestStatus, RequestAttributeWeightage } from '~/types';

export const getResourceRequestFromState = ({
  form: {
    fields: {
      role,
      quantity,
      load,
      roleRate,
      comment,
      location,
      division,
      department,
      employeeType,
      serviceCenter,
      costCenter,
      resourcePools,
      currency,
      startDate,
      endDate,
      totalHours,
      scheduleRules,
      preferredResources
    }
  },
  project: { defaultScheduleRule }
}) => ({
  resourceRequestFromState: {
    startDate: startDate.value,
    endDate: endDate.value,
    comment: comment.value,
    role: role.value,
    location: location.value,
    division: division.value,
    department: department.value,
    employeeType: employeeType.value,
    costCenter: costCenter.value,
    serviceCenter: serviceCenter.value,
    scheduleRules:
      (scheduleRules && scheduleRules.value) ||
      buildScheduleRulesWithDefaultConstraint({
        startDate: startDate.value,
        endDate: endDate.value,
        defaultScheduleRule
      }),
    currency: currency.value,
    quantity: quantity.value,
    load: load.value,
    roleRate: roleRate.value,
    currencyUri: currency.value?.id,
    totalHours: totalHours.value,
    resourcePools: resourcePools?.value,
    preferredResources: preferredResources?.value
  }
});

export const initialState = ({
  project: { startDate, endDate, defaultScheduleRule },
  resourceRequest = {},
  defaultRequestAttributeWeights = {}
}) => {
  const { requestAttributeWeights } = resourceRequest;
  const {
    requestStartDate,
    requestEndDate
  } = getResourceRequestDatesFromProjectDate({
    projectStartDate: startDate,
    projectEndDate: endDate
  });

  const rules =
    resourceRequest.scheduleRules ||
    buildScheduleRulesWithDefaultConstraint({
      startDate: requestStartDate,
      endDate: requestEndDate,
      defaultScheduleRule
    });

  const totalHours = getTotalHours({
    scheduleRules: resourceRequest.scheduleRules,
    quantity: resourceRequest.quantity || 1,
    startDate: requestStartDate,
    endDate: requestEndDate,
    defaultScheduleRule
  });

  const tags =
    resourceRequest.tags?.map(({ tag, value }) => ({
      tag,
      selected: tag.values.find(val => value.value === val.value)
    })) || [];

  return {
    ...resourceRequest,
    requestStatus: resourceRequest.requestStatus || ResourceRequestStatus.Draft,
    quantity: resourceRequest.quantity || 1,
    load: resourceRequest.load || 100,
    startDate: resourceRequest.startDate || requestStartDate,
    endDate: resourceRequest.endDate || requestEndDate,
    comment: resourceRequest.comment || null,
    location: resourceRequest.location || null,
    division: resourceRequest.division || null,
    department: resourceRequest.department || null,
    employeeType: resourceRequest.employeeType || null,
    serviceCenter: resourceRequest.serviceCenter || null,
    costCenter: resourceRequest.costCenter || null,
    role: resourceRequest.role || null,
    roleRate: resourceRequest.roleRate || 0,
    currencyUri: resourceRequest.currencyUri || null,
    currency: resourceRequest.currency || null,
    totalHours: resourceRequest.totalHours || totalHours,
    scheduleRules: resourceRequest.scheduleRules || rules,
    isAdjustedLoading: resourceRequest.isAdjustedLoading || false,
    skills: resourceRequest.skills || [],
    tags,
    resourcePools: resourceRequest.resourcePools || [],
    preferredResources: resourceRequest.preferredResources || [],
    requestAttributeWeights: {
      location:
        requestAttributeWeights?.location ||
        defaultRequestAttributeWeights?.location,
      division:
        requestAttributeWeights?.division ||
        defaultRequestAttributeWeights?.division,
      serviceCenter:
        requestAttributeWeights?.serviceCenter ||
        defaultRequestAttributeWeights?.serviceCenter,
      costCenter:
        requestAttributeWeights?.costCenter ||
        defaultRequestAttributeWeights?.costCenter,
      department:
        requestAttributeWeights?.department ||
        defaultRequestAttributeWeights?.department,
      employeeType:
        requestAttributeWeights?.employeeType ||
        defaultRequestAttributeWeights?.employeeType,
      role:
        requestAttributeWeights?.role || defaultRequestAttributeWeights?.role,
      skills:
        requestAttributeWeights?.skills ||
        defaultRequestAttributeWeights?.skills,
      tags:
        requestAttributeWeights?.tags || defaultRequestAttributeWeights?.tags,
      requestedCostRate:
        requestAttributeWeights?.requestedCostRate ||
        defaultRequestAttributeWeights?.requestedCostRate,
      resourcePools:
        requestAttributeWeights?.resourcePools ||
        defaultRequestAttributeWeights?.resourcePools,
      preferredResources:
        requestAttributeWeights?.preferredResources ||
        defaultRequestAttributeWeights?.preferredResources
    }
  };
};

export const getResourceRequest = ({
  form,
  defaultScheduleRule,
  resourceRequest = {}
}) => {
  const {
    quantity,
    load,
    comment,
    startDate,
    endDate,
    location,
    division,
    department,
    employeeType,
    serviceCenter,
    costCenter,
    role,
    roleRate,
    currency,
    requestStatus,
    totalHours,
    scheduleRules,
    skills,
    tags: tags_,
    resourcePools,
    requestAttributeWeights,
    isAdjustedLoading,
    preferredResources
  } = form.fields;

  const tags = tags_.value.map(({ tag, selected }) => ({
    tag,
    value: selected
  }));

  return {
    ...resourceRequest,
    roleUri: role.value && role.value.id,
    role: role.value,
    location: location.value,
    division: division.value,
    department: department.value,
    employeeType: employeeType.value,
    serviceCenter: serviceCenter.value,
    costCenter: costCenter.value,
    quantity: quantity.value,
    comment: comment.value,
    isAdjustedLoading: isAdjustedLoading.value,
    scheduleRules:
      (scheduleRules && scheduleRules.value) ||
      buildScheduleRulesWithDefaultConstraint({
        startDate: startDate.value,
        endDate: endDate.value,
        defaultScheduleRule
      }),
    load: load.value,
    roleRate: roleRate.value,
    requestStatus: requestStatus.value,
    currencyUri: currency.value?.id,
    currency: currency.value,
    totalHours: totalHours.value,
    project: undefined,
    resourceAllocations: undefined,
    skills: skills.value || [],
    tags: tags || [],
    resourcePools: resourcePools?.value || [],
    requestAttributeWeights: omit('__typename')(requestAttributeWeights.value),
    preferredResources: preferredResources?.value || []
  };
};

export const validationSchema = ({ intl, groupLabels }) =>
  object().shape({
    role: object()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.role === RequestAttributeWeightage.Required
          ? schema.required(
              intl.formatMessage({
                id: 'resourceRequestDrawerDetails.roleRequired'
              })
            )
          : schema
      ),
    roleRate: number()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.requestedCostRate ===
        RequestAttributeWeightage.Required
          ? schema.min(
              0.01,
              intl.formatMessage({
                id: 'resourceRequestDrawerDetails.roleCostRequired'
              })
            )
          : schema
      ),
    skills: array().when(
      'requestAttributeWeights',
      (requestAttributeWeights, schema) =>
        requestAttributeWeights?.skills === RequestAttributeWeightage.Required
          ? schema.min(
              1,
              intl.formatMessage({
                id: 'resourceRequestDrawerDetails.skillsRequired'
              })
            )
          : schema
    ),
    tags: array()
      .of(
        object().shape({
          tag: object()
            .shape({
              id: string().required(),
              name: string().required(
                intl.formatMessage({
                  id: 'tags.noTagErrorNoCreate'
                })
              )
            })
            .required(),
          selected: object()
            .shape({
              id: string().required(),
              value: string().required(
                intl.formatMessage({
                  id: 'tags.noValueErrorNoCreate'
                })
              )
            })
            .required()
        })
      )
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.tags === RequestAttributeWeightage.Required
          ? schema.min(
              1,
              intl.formatMessage({
                id: 'resourceRequestDrawerDetails.skillsRequired'
              })
            )
          : schema
      ),
    resourcePools: array().when(
      'requestAttributeWeights',
      (requestAttributeWeights, schema) =>
        requestAttributeWeights?.resourcePools ===
        RequestAttributeWeightage.Required
          ? schema.min(
              1,
              intl.formatMessage({
                id: 'resourceRequestDrawerDetails.resourcePoolRequired'
              })
            )
          : schema
    ),
    preferredResources: array().when(
      'requestAttributeWeights',
      (requestAttributeWeights, schema) =>
        requestAttributeWeights?.preferredResources ===
        RequestAttributeWeightage.Required
          ? schema.min(
              1,
              intl.formatMessage({
                id: 'resourceRequestDrawerDetails.preferredResourceRequired'
              })
            )
          : schema
    ),
    costCenter: object()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.costCenter ===
        RequestAttributeWeightage.Required
          ? schema.required(
              intl.formatMessage(
                {
                  id: 'resourceRequestDrawerDetails.groupRequired'
                },
                { groupName: groupLabels.costCenter }
              )
            )
          : schema.nullable()
      ),
    department: object()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.department ===
        RequestAttributeWeightage.Required
          ? schema.required(
              intl.formatMessage(
                {
                  id: 'resourceRequestDrawerDetails.groupRequired'
                },
                { groupName: groupLabels.department }
              )
            )
          : schema
      ),
    division: object()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.division === RequestAttributeWeightage.Required
          ? schema.required(
              intl.formatMessage(
                {
                  id: 'resourceRequestDrawerDetails.groupRequired'
                },
                { groupName: groupLabels.division }
              )
            )
          : schema
      ),
    employeeType: object()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.employeeType ===
        RequestAttributeWeightage.Required
          ? schema.required(
              intl.formatMessage(
                {
                  id: 'resourceRequestDrawerDetails.groupRequired'
                },
                { groupName: groupLabels.employeeType }
              )
            )
          : schema
      ),
    location: object()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.location === RequestAttributeWeightage.Required
          ? schema.required(
              intl.formatMessage(
                {
                  id: 'resourceRequestDrawerDetails.groupRequired'
                },
                { groupName: groupLabels.location }
              )
            )
          : schema
      ),
    serviceCenter: object()
      .nullable()
      .when('requestAttributeWeights', (requestAttributeWeights, schema) =>
        requestAttributeWeights?.serviceCenter ===
        RequestAttributeWeightage.Required
          ? schema.required(
              intl.formatMessage(
                {
                  id: 'resourceRequestDrawerDetails.groupRequired'
                },
                { groupName: groupLabels.serviceCenter }
              )
            )
          : schema
      ),
    quantity: number()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'resourceRequestDrawerDetails.quantityError'
        })
      )
      .min(
        1,
        intl.formatMessage(
          {
            id: 'resourceRequestDrawerDetails.minValueForQuantity'
          },
          {
            value: 1
          }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: 'resourceRequestDrawerDetails.maxValueForQuantity'
          },
          {
            value: 50
          }
        )
      ),
    totalHours: number().moreThan(
      0,
      intl.formatMessage(
        {
          id: 'resourceRequestDrawerDetails.minValueForTotalHours'
        },
        {
          value: 0
        }
      )
    ),
    load: number()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'resourceRequestDrawerDetails.loadError'
        })
      )
      .moreThan(
        0,
        intl.formatMessage(
          {
            id: 'resourceRequestDrawerDetails.minValueForLoad'
          },
          {
            value: 0
          }
        )
      )
      .max(
        999,
        intl.formatMessage(
          {
            id: 'resourceRequestDrawerDetails.maxValueForLoad'
          },
          {
            value: 999
          }
        )
      ),
    startDate: date(),
    endDate: date().test({
      name: 'sameExcludedDateValidation',
      exclusive: true,
      params: {},
      message: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.dateRangeError'
      }),
      test(value) {
        const startDate = moment.utc(this.parent.startDate).startOf('days');
        const endDate = moment.utc(value).startOf('days');

        return (
          endDate.diff(startDate, 'days') > 2 ||
          startDate.day() % 6 !== 0 ||
          endDate.day() % 6 !== 0
        );
      }
    })
  });

export const useOnSubmit = ({
  resourceRequest = {},
  setEditButtonClicked,
  onResourceRequestSave,
  setResourceRequestSaving,
  project: { defaultScheduleRule }
}) =>
  useCallback(
    async (values, formikBag) => {
      const form = mapFormikValuesToFields({ values });

      setResourceRequestSaving(true);
      await onResourceRequestSave(
        getResourceRequest({ form, defaultScheduleRule, resourceRequest })
      );
      setResourceRequestSaving(false);
      setEditButtonClicked(false);

      return {
        form: {
          ...form,
          isChanged: false
        }
      };
    },
    [
      onResourceRequestSave,
      setResourceRequestSaving,
      resourceRequest,
      defaultScheduleRule,
      setEditButtonClicked
    ]
  );

export const mapFormikValuesToFields = ({ values }) => ({
  fields: Object.keys(values).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        hasError: false,
        isChanged: false,
        error: '',
        value: values[key],
        originalValue: values[key]
      }
    };
  }, {})
});

export const mapFormikValuesToWithForm = formik => {
  const { values, dirty, touched, errors, isValid } = formik;

  return {
    form: {
      ...formik,
      isChanged: dirty,
      hasError: !isValid,
      error: !isValid ? 'unknown error' : '',
      fields: Object.keys(values).reduce(
        (fields, key) => ({
          ...fields,
          [key]: {
            isChanged: Boolean(touched[key]),
            hasError: Boolean(errors[key]),
            error: errors[key] || '',
            value: values[key],
            originalValue: values[key]
          }
        }),
        {}
      )
    }
  };
};

export const useInitialState = ({
  project,
  resourceRequest,
  defaultRequestAttributeWeights
}) => {
  const values = useMemo(
    () =>
      initialState({
        project,
        resourceRequest,
        defaultRequestAttributeWeights
      }),
    [project, resourceRequest, defaultRequestAttributeWeights]
  );

  return values;
};

export const useForm = ({
  project,
  resourceRequest,
  defaultRequestAttributeWeights,
  setEditButtonClicked,
  onResourceRequestSave,
  setResourceRequestSaving
}) => {
  const intl = useIntl();

  const onSubmit = useOnSubmit({
    project,
    resourceRequest,
    setEditButtonClicked,
    onResourceRequestSave,
    setResourceRequestSaving
  });

  const initialValues = useInitialState({
    project,
    resourceRequest,
    defaultRequestAttributeWeights
  });

  const groupLabels = useGroupLabels();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema({ intl, groupLabels }),
    enableReinitialize: true
  });

  return useMemo(() => mapFormikValuesToWithForm(formik), [formik]);
};

export default useForm;
