import { useCallback } from 'react';
import { CONTEXT_ENUM } from '../enums';
import { usePutClientRates } from './usePutClientRates';
import { usePutProjectRates } from './usePutProjectRates';

export const useRemoveAllEntries = ({
  context,
  projectId,
  rateTypeKey,
  clientId,
  resetForm
}) => {
  const { putProjectRates } = usePutProjectRates();
  const { putClientRates } = usePutClientRates();

  const onRemoveAllEntries = useCallback(async () => {
    if (context === CONTEXT_ENUM.project && projectId) {
      putProjectRates({ projectId, rateTypeKey, rates: [] });
    }

    if (context === CONTEXT_ENUM.client && clientId)
      putClientRates({ clientId, rateTypeKey, rates: [] });

    resetForm();
  }, [
    context,
    projectId,
    rateTypeKey,
    clientId,
    putClientRates,
    putProjectRates,
    resetForm
  ]);

  return {
    onRemoveAllEntries
  };
};
