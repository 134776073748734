import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@mui/material';
import { getFirstErrorCode } from '~/modules/common/graphql/errors';

const useStyles = makeStyles(theme => ({
  error: {
    margin: theme.spacing(4, 3, 4, 3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(8),
    color: theme.palette.text.secondary
  }
}));

export const AddLineItemsDialogError = ({ error }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.error}>
      <Alert severity="warning">
        <FormattedMessage
          id={
            getFirstErrorCode(error) === 'INTERNAL_SERVER_ERROR'
              ? 'addLineItemDialog.internalServiceError'
              : 'addLineItemDialog.unknownError'
          }
        />
      </Alert>
    </Typography>
  );
};

AddLineItemsDialogError.propTypes = {
  error: PropTypes.object.isRequired
};

export default AddLineItemsDialogError;
