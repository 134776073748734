import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { isNumeric } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';
import { getPeriodsByScale } from '~/modules/resourcing/common/hooks/usePeriodsByScale';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { ResourceCostModeType } from '~/types';

export const getScheduledHoursByRange = ({
  allocationStartDate,
  allocationEndDate,
  startDate,
  endDate,
  allocationScheduleDurationHours
}) => {
  const startDateWithRange =
    allocationStartDate > startDate && allocationStartDate < endDate
      ? allocationStartDate
      : startDate;

  const endDateWithRange =
    allocationEndDate < endDate && allocationEndDate > startDate
      ? allocationEndDate
      : endDate;

  const scheduledHours =
    allocationScheduleDurationHours
      .filter(d => {
        const date = mapRepliconDateToUtcObject(d.date);

        return date >= startDateWithRange && date <= endDateWithRange;
      })
      .reduce((acc, curr) => acc + curr.hours, 0) || 0;

  return scheduledHours;
};

export const getAllocationTotalsByPeriods = ({ allocation, me, scale }) => {
  const { scheduleRules = [] } = allocation;

  if (!scheduleRules.length) {
    return { allocationPeriods: [] };
  }

  const { displayPeriods = [] } = getPeriodsByScale({
    me,
    rangeStart: mapIsoStringtoUtcObject(allocation.startDate),
    rangeEnd: mapIsoStringtoUtcObject(allocation.endDate),
    scale
  });

  return {
    allocationPeriods: displayPeriods.map(range => {
      const { start, end, key } = range;

      return {
        key,
        totalHours: getTotalHoursForDateRangeFromScheduleRules({
          start,
          end,
          scheduleRules
        })
      };
    })
  };
};

const useAllocationTimelineBlocks = ({
  periodStartDate,
  periodEndDate,
  allocation,
  allocationScheduleDurationHours = [],
  chartStartDate,
  scale,
  relativeDynamicPositionLeft = 0,
  showTotalCost = true,
  skip
}) => {
  const me = useMeContext();

  if (skip) return { allocationPeriods: [] };

  const { resourceCostMode } = me;
  const { scheduleRules = [], user } = allocation || {};
  const allocationStartDate =
    allocation && mapIsoStringtoUtcObject(allocation.startDate);
  const allocationEndDate =
    allocation && mapIsoStringtoUtcObject(allocation.endDate);

  const startDate = periodStartDate || allocationStartDate;
  const endDate = periodEndDate || allocationEndDate;
  const { displayPeriods = [] } = getPeriodsByScale({
    me,
    rangeStart: startDate,
    rangeEnd: endDate,
    scale
  });

  if (!scheduleRules.length) {
    return { allocationPeriods: [] };
  }

  const isRoleCostMode = resourceCostMode === ResourceCostModeType.Rolecost;

  return {
    allocationPeriods: displayPeriods.map(range => {
      const { start, end, key } = range;
      const allocatedHoursForRange = getTotalHoursForDateRangeFromScheduleRules(
        {
          start,
          end,
          scheduleRules
        }
      );

      const scheduledHoursForRange = allocationScheduleDurationHours.length
        ? getScheduledHoursByRange({
            allocationStartDate,
            allocationEndDate,
            startDate: start,
            endDate: end,
            allocationScheduleDurationHours
          })
        : 0;

      return {
        key,
        startDate: start,
        endDate: end,
        dynamicPosition: getDynamicPositionAttributes({
          chartStartDate,
          start: startDate && start < startDate ? startDate : start,
          end: endDate && end > endDate ? endDate : end,
          scale,
          relativeDynamicPositionLeft,
          isPsaRmpUserSchedulePerfFixEnabled: true
        }).dynamicPosition,
        totalHours: allocatedHoursForRange,
        percentage: (allocatedHoursForRange / scheduledHoursForRange) * 100,
        totalScheduleHours: scheduledHoursForRange,
        ...(showTotalCost
          ? {
              totalCost:
                user && isNumeric(allocatedHoursForRange)
                  ? {
                      amount:
                        allocatedHoursForRange *
                          (isRoleCostMode
                            ? user.primaryRoleCostRate
                            : user.costRate) || 0,
                      symbol: isRoleCostMode
                        ? user.primaryRoleCostCurrency &&
                          user.primaryRoleCostCurrency.displayText
                        : user.costCurrency && user.costCurrency.displayText
                    }
                  : null
            }
          : {})
      };
    })
  };
};

export default useAllocationTimelineBlocks;
