import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { usePageTitle } from '~/modules/common/title';
import HomeButton from '~/modules/common/components/HomeButton';
import StickyHeader from '../components/StickyHeader/StickyHeader';
import { useIsBreakpointDown } from '../hooks';
import useStyles from './useStyles';
import { NotFoundDescription, NotFoundTitle } from './components';
import NotFoundSVG from './404.svg';

const pageTitle = <FormattedMessage id="routes.pageNotFound" />;

export const NotFoundPage = ({ classes: classesOverrides }) => {
  const classes = useStyles({ classes: classesOverrides });

  usePageTitle([{ messageId: 'routes.pageNotFound' }]);
  const isMobile = useIsBreakpointDown('sm');

  return (
    <>
      <StickyHeader showMenuButton={isMobile} title={pageTitle} />
      <div className={classes.root}>
        <div className={classes.container}>
          <img src={NotFoundSVG} className={classes.logo404} alt="Not Found" />
          <NotFoundTitle />
          <NotFoundDescription />
          <Hidden smUp>
            <HomeButton to="/" />
          </Hidden>
        </div>
      </div>
    </>
  );
};

NotFoundPage.propTypes = {
  classes: PropTypes.object
};

export default NotFoundPage;
