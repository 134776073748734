import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetKeyValueSettings } from '~/modules/common/hooks';
import {
  ResourcingOverviewToolbar,
  ResourceAllocationCard,
  ResourceRequestRemindersCard,
  SkillsDistributionCard,
  ResourceAvailabilityCard
} from './components';
import { useResourceAvailabilityPermissions } from './hooks';
import ResourceOverviewToolbarContextProvider from './ResourceOverviewToolbarContextProvider';

const useStyles = makeStyles(theme => ({
  gridRoot: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 2, 0)
    },
    minHeight: '-webkit-max-content'
  },
  resourceAllocationItem: {
    padding: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0)
    }
  },
  skillDistributionItem: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 0, 0)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0, 0, 0)
    }
  }
}));

export const ResourcingOverview = ({ currentPage, setCurrentPage }) => {
  const classes = useStyles();

  const {
    canViewSkills,
    isViewAvailabilityEnabled,
    isViewRoleEnabled,
    isViewCostCenterEnabled,
    isViewDivisionEnabled,
    isViewDepartmentEnabled,
    isViewEmployeeTypeEnabled,
    isViewLocationEnabled,
    isViewServiceCenterEnabled
  } = useResourceAvailabilityPermissions();

  const {
    isLoading,
    keyValueSettings: availabilitySettings
  } = useGetKeyValueSettings('availability_settings');

  return (
    <ResourceOverviewToolbarContextProvider>
      <Grid container spacing={0} className={classes.gridRoot}>
        {isViewAvailabilityEnabled && (
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            className={classes.resourceAllocationItem}
          >
            <ResourcingOverviewToolbar />
            <ResourceAllocationCard />
            {!isLoading &&
              (isViewRoleEnabled ||
                isViewCostCenterEnabled ||
                isViewDivisionEnabled ||
                isViewDepartmentEnabled ||
                isViewEmployeeTypeEnabled ||
                isViewLocationEnabled ||
                isViewServiceCenterEnabled) && (
                <ResourceAvailabilityCard
                  settings={availabilitySettings}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              )}
          </Grid>
        )}
        <Hidden smDown>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ResourceRequestRemindersCard />
            {canViewSkills && <SkillsDistributionCard />}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          {canViewSkills && (
            <Grid
              item
              className={classes.skillDistributionItem}
              xs={12}
              sm={12}
              md={4}
              lg={3}
            >
              <SkillsDistributionCard />
            </Grid>
          )}
        </Hidden>
      </Grid>
    </ResourceOverviewToolbarContextProvider>
  );
};

ResourcingOverview.propTypes = {
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired
};
export default ResourcingOverview;
