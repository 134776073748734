import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import AddCircleIcon from '@material-ui/icons/AddCircleSharp';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[0],
    '&:hover': {
      boxShadow: theme.shadows[2]
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.3),
      padding: theme.spacing(0.3, 1)
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(0.5)
    }
  }
}));

const buttonSizeToIconSize = size =>
  ['small', 'large'].some(d => d === size) ? size : 'default';

export const AddIconButton = ({
  classes: classesOverride,
  size,
  children,
  ...otherProps
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const { icon, ...buttonClasses } = classes;
  const addText = intl.formatMessage({ id: 'addButton.add' });

  return (
    <Button
      variant="contained"
      {...otherProps}
      className={classes.root}
      classes={buttonClasses}
      size={size}
    >
      <AddCircleIcon
        className={icon}
        fontSize={buttonSizeToIconSize(size)}
        titleAccess={addText}
        role="img"
      />
      {children}
    </Button>
  );
};

AddIconButton.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node,
  intl: PropTypes.object
};

export default AddIconButton;
