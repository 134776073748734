import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    textAlign: 'right'
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 0, 0.5)
  },
  sampleGradient: {
    display: 'inline-block',
    width: theme.spacing(10),
    height: theme.spacing(1),
    backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 0%, ${theme.palette.resourceAllocation.allocated}  100%)`,
    margin: theme.spacing(0, 0.25, 0, 0)
  }
}));

const ProgressbarLegend = ({ classes, label }) => {
  const cssClasses = useStyles({ classes });

  return (
    <div className={cssClasses.root}>
      <Typography component="span" className={cssClasses.label}>
        0%
        <Typography component="span" className={cssClasses.sampleGradient} />
        100%
      </Typography>
      <Typography component="span" className={cssClasses.label}>
        {label}
      </Typography>
    </div>
  );
};

ProgressbarLegend.propTypes = {
  label: PropTypes.node.isRequired,
  classes: PropTypes.object
};

export default ProgressbarLegend;
