import { useQuery } from '@apollo/client';
import { BILLING_PLAN_SLUG_ENUM } from '~/modules/projects/project/common/enums';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { GET_EFFECTIVE_PROJECT_RATES_COUNT } from '~/modules/rateCard/hooks/useProjectRates';

export const useEffectiveProjectRate = ({ projectId, billPlanSlug }) => {
  const isPsaFpAdvancedRateCardEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpAdvancedRateCardEnabled'
  });
  const effectiveProjectRate = useQuery(GET_EFFECTIVE_PROJECT_RATES_COUNT, {
    variables: {
      projectId
    },
    skip:
      isPsaFpAdvancedRateCardEnabled ||
      billPlanSlug !== BILLING_PLAN_SLUG_ENUM.timeBilling,
    fetchPolicy: 'network-only'
  });
  const {
    projectRate = null,
    userRatesCount,
    roleRatesCount,
    userRates,
    roleRates
  } = effectiveProjectRate.data?.project || {};
  const { loading } = effectiveProjectRate;

  return {
    projectRate,
    loadingProjectRate: loading,
    userRatesCount,
    roleRatesCount,
    userRates,
    roleRates
  };
};
