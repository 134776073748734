export const useProjectResourcesWithRole = ({ taskOwners, assignedRole }) => {
  return assignedRole
    ? taskOwners.reduce(
        ([group1, group2], user) => {
          return (user.projectRoles || []).filter(
            role => role.projectRole.uri === assignedRole.id
          ).length > 0
            ? [[...group1, user], group2]
            : [group1, [...group2, user]];
        },
        [[], []]
      )
    : [[], taskOwners];
};
