import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Chip, makeStyles } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import DateRange from '~/modules/common/components/DateRange';

const useStyles = makeStyles({
  wrapper: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1)
  },
  columnItem: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(1.5)
  },
  subheading: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  statusChip: ({ status }) => ({
    backgroundColor: theme.palette.taskStatus[status].color,
    fontWeight: 600
  }),
  range: ({ hasStartAndEndDate }) => ({
    marginTop: hasStartAndEndDate ? theme.spacing(1) : theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    display: hasStartAndEndDate ? 'inline' : 'flex',
    alignItems: hasStartAndEndDate ? undefined : 'center',
    color: 'inherit'
  }),
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    display: 'inline'
  },
  spacer: {
    color: 'inherit',
    fontWeight: 400,
    lineHeight: 'inherit'
  },
  noDateIcon: {
    color: theme.palette.error.dark
  }
});

const ProjectDrawerHeaderInfo = ({ project }) => {
  const { formatMessage } = useIntl();
  const { startDate, endDate, status } = project;
  const hasStartAndEndDate = startDate && endDate;
  const classes = useStyles({ hasStartAndEndDate, status });

  const dateRangeClasses = useMemo(
    () => ({
      range: classes.range,
      date: classes.date,
      spacer: classes.spacer
    }),
    [classes]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classNames(classes.flexRow, classes.row)}>
        <div className={classes.columnItem}>
          <span className={classes.subheading}>
            <FormattedMessage id="projectDrawer.status" />
          </span>
          <Chip
            className={classes.statusChip}
            label={formatMessage({
              id: `projectTasksPage.tasksStatus.${status}`
            })}
          />
        </div>
        <div className={classes.columnItem}>
          <span className={classes.subheading}>
            <FormattedMessage id="projectDrawer.plannedDates" />
          </span>
          <DateRange
            classes={dateRangeClasses}
            start={startDate}
            end={endDate}
            noDateComponent={
              <ReportProblemOutlinedIcon className={classes.noDateIcon} />
            }
          />
        </div>
      </div>
    </div>
  );
};

ProjectDrawerHeaderInfo.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectDrawerHeaderInfo;
