import { useSearchableCostCenters } from '~/modules/common/enhancers';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { costCenterTag } from './tag';
import CostCenterFacetDetails from './CostCenterFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableCostCenters);

export default {
  tag: costCenterTag,
  makeCriteriaProvider,
  DetailsControl: CostCenterFacetDetails
};
