import { Grow, makeStyles, Paper, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: theme.zIndex.modal,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '2em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    right: theme.direction === 'rtl' ? '0 !important' : 'unset',
    left: theme.direction === 'rtl' ? 'unset !important' : 'unset',
    marginRight: theme.direction === 'rtl' ? '-7px !important' : 'unset',
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'unset',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
}));

const ArrowPopup = ({
  id,
  anchorEl,
  placement,
  children,
  onClose,
  preventOverflow,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const isOpen = Boolean(anchorEl);
  const [arrowRef, setArrowRef] = React.useState(null);

  const modifiers = {
    arrow: {
      enabled: true,
      element: arrowRef
    }
  };

  if (preventOverflow) {
    modifiers.preventOverflow = {
      enabled: true,
      boundariesElement: 'scrollParent'
    };
  }

  return (
    <Popper
      id={id}
      className={classes.popper}
      anchorEl={anchorEl}
      role="dialog"
      placement={placement}
      open={isOpen}
      onClose={onClose}
      modifiers={modifiers}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper} elevation={6}>
            <span className={classes.arrow} ref={setArrowRef} />
            {children}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

ArrowPopup.propTypes = {
  id: PropTypes.string.isRequired,
  anchorEl: PropTypes.any,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top'
  ]),
  children: PropTypes.node,
  onClose: PropTypes.func,
  preventOverflow: PropTypes.bool.isRequired,
  classes: PropTypes.object
};

export default ArrowPopup;
