import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

export const ActualOverEstimated = ({ canViewEstimate, actual, estimated }) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>{actual}</Grid>
      {canViewEstimate && <Grid item>{estimated}</Grid>}
    </Grid>
  );
};

ActualOverEstimated.propTypes = {
  canViewEstimate: PropTypes.bool,
  actual: PropTypes.node,
  estimated: PropTypes.node
};

export default ActualOverEstimated;
