import { DateTime } from 'luxon';
import {
  getUserPreferenceWeekStart,
  getUserPreferenceWeekEnd
} from '~/modules/common/dates/dayOfWeek';

import { PERIOD_SCALE_ENUM } from '../periodScale';

export const mapDateToLastDayOfMonth = date =>
  DateTime.utc(date.year, date.month, date.daysInMonth);

const scaleOffsets = {
  [PERIOD_SCALE_ENUM.DAYS]: { days: 1 },
  [PERIOD_SCALE_ENUM.WEEKS]: { weeks: 1 },
  [PERIOD_SCALE_ENUM.QUARTERS]: { months: 3 },
  [PERIOD_SCALE_ENUM.YEARS]: { years: 1 },
  default: { months: 1 }
};

export const getNextDateRange = ({ chartStartDate, chartEndDate, scale }) => {
  const offsetToApply = scaleOffsets[scale] || scaleOffsets.default;

  return {
    chartStartDate: chartStartDate.plus(offsetToApply),
    chartEndDate:
      offsetToApply === scaleOffsets.default
        ? mapDateToLastDayOfMonth(chartEndDate.plus(offsetToApply))
        : chartEndDate.plus(offsetToApply)
  };
};

export const getPreviousDateRange = ({
  chartStartDate,
  chartEndDate,
  scale
}) => {
  const offsetToApply = scaleOffsets[scale] || scaleOffsets.default;

  return {
    chartStartDate: chartStartDate.minus(offsetToApply),
    chartEndDate:
      offsetToApply === scaleOffsets.default
        ? mapDateToLastDayOfMonth(chartEndDate.minus(offsetToApply))
        : chartEndDate.minus(offsetToApply)
  };
};

const periodScaleUnitMap = {
  [PERIOD_SCALE_ENUM.DAYS]: 'day',
  [PERIOD_SCALE_ENUM.WEEKS]: 'week',
  [PERIOD_SCALE_ENUM.MONTHS]: 'month',
  [PERIOD_SCALE_ENUM.QUARTERS]: 'quarter',
  [PERIOD_SCALE_ENUM.YEARS]: 'year'
};

export const getDateRangeOnScaleChange = ({
  chartStartDate,
  chartEndDate,
  scale,
  me
}) => {
  const unit = periodScaleUnitMap[scale];

  let newStartDate = chartStartDate.startOf(unit);
  let newEndDate = chartEndDate.endOf(unit);

  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    newStartDate = getUserPreferenceWeekStart(me, chartStartDate);
    newEndDate = getUserPreferenceWeekEnd(me, chartEndDate);
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    newEndDate = newEndDate.plus({ years: 3 });
  }

  return {
    newStartDate,
    newEndDate
  };
};
