const buildStructure = (obj, key, value) => {
  key.split('.').reduce((acc, item, index, array) => {
    if (index === array.length - 1) {
      acc[item] = value;
    } else {
      acc[item] = acc[item] || {};
    }

    return acc[item];
  }, obj);
};

const rebuildStructure = (messages = {}, prefix = '') => {
  const root = {};

  Object.keys(messages)
    .filter(key => prefix.length === 0 || key.startsWith(prefix))
    .map(key => ({
      key: prefix.length === 0 ? key : key.substr(prefix.length + 1),
      value: messages[key]
    }))
    .forEach(item => buildStructure(root, item.key, item.value));

  return root;
};

export default rebuildStructure;
