import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { VictoryBar, VictoryGroup, VictoryAxis } from 'victory';
import { useTheme } from '@material-ui/core/styles';
import { TotalForecastKeyValues } from './components';
import { useChartStyles, useChartInputProps } from './hooks';

export const OpenMonthRevenueChart = ({
  totalForecastedAmount,
  startDate,
  endDate,
  canViewRevenueItem
}) => {
  const intl = useIntl();

  const {
    xTickFormat,
    tickValues,
    chartData,
    highestValue
  } = useChartInputProps({
    intl,
    data: { totalForecastedAmount }
  });
  const chartStyles = useChartStyles(
    intl,
    useTheme(),
    highestValue,
    totalForecastedAmount.currency.displayText
  );

  return (
    <>
      {canViewRevenueItem && (
        <>
          <VictoryGroup
            height={chartStyles.group.height}
            horizontal
            padding={chartStyles.group.padding}
            offset={chartStyles.group.offset}
          >
            <VictoryBar
              barWidth={chartStyles.totalForecasted.barWidth}
              style={chartStyles.totalForecasted.style}
              cornerRadius={chartStyles.totalForecasted.cornerRadius}
              data={chartData.totalForecasted.data}
            />
          </VictoryGroup>
          <VictoryAxis
            height={chartStyles.axis.height}
            style={chartStyles.axis.style}
            padding={chartStyles.axis.padding}
            offsetY={chartStyles.axis.offsetY}
            tickValues={tickValues}
            tickFormat={xTickFormat}
          />
        </>
      )}
      <TotalForecastKeyValues
        startDate={startDate}
        endDate={endDate}
        totalForecastedAmount={totalForecastedAmount}
      />
    </>
  );
};
OpenMonthRevenueChart.propTypes = {
  totalForecastedAmount: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  canViewRevenueItem: PropTypes.bool.isRequired
};
export default OpenMonthRevenueChart;
