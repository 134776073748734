import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import { BasicListItem } from '../GenericItems';
import { useProjectLinkTypes } from './enhancers/useProjectLinkTypes';

export const projectLinkLabel = <FormattedMessage id="search.projectLink" />;
const renderOption = option => <BasicListItem value={option.label} />;
const getOptionSelected = (option, value) => option.id === value.id;

export const ProjectLinkTypeFacetDetails = ({ selected, setSelected }) => {
  const intl = useIntl();

  const { projectLinkTypes } = useProjectLinkTypes(intl);

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option => selected.some(v => v.id === option.id),
    [selected]
  );

  return (
    <FacetDetailField label={projectLinkLabel}>
      <SimpleAutocomplete
        multiple
        fullWidth
        hiddenLabel
        value={selected}
        variant="standard"
        optionPropText="label"
        options={projectLinkTypes}
        onChange={onValueChange}
        renderOption={renderOption}
        getOptionDisabled={getOptionDisabled}
        getOptionSelected={getOptionSelected}
      />
    </FacetDetailField>
  );
};

ProjectLinkTypeFacetDetails.propTypes = {
  setSelected: PropTypes.func,
  selected: PropTypes.array.isRequired
};

export default ProjectLinkTypeFacetDetails;
