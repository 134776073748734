import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: theme.spacing(3),
    top: theme.spacing(6),
    userSelect: 'none',
    zIndex: 2,
    backgroundColor: theme.palette.resourceAllocation.timeOff
  },
  actualPlacement: {
    top: theme.spacing(11)
  },
  transparentButton: {
    opacity: 0,
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  }
}));

export default useStyles;
