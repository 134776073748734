import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const salesforceQuery = gql`
  {
    me {
      id
      salesforce {
        isConnected
        href
      }
    }
  }
`;

export const useSalesforceStatus = () => {
  const { data, loading } = useQuery(salesforceQuery);

  return { loading, status: data?.me?.salesforce };
};

export default useSalesforceStatus;
