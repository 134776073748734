import { useState, useLayoutEffect, useEffect } from 'react';

import useEventCallback from '@material-ui/core/utils/useEventCallback';
import debounce from '@material-ui/core/utils/debounce';
import ownerWindow from '@material-ui/core/utils/ownerWindow';

const useRefScroll = ref => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(0);
  const updateRefScroll = useEventCallback(() => {
    if (ref.current) {
      const { scrollWidth } = ref.current;
      const { clientWidth } = ref.current;
      const newScrollLeft = ref.current.scrollLeft;
      const newScrollRight = Math.max(
        0,
        scrollWidth - clientWidth - newScrollLeft
      );

      if (scrollLeft !== newScrollLeft) setScrollLeft(newScrollLeft);
      if (scrollRight !== setScrollRight) setScrollRight(newScrollRight);
    }
  });

  useLayoutEffect(() => {
    const handleScroll = debounce(() => {
      updateRefScroll();
    });

    if (ref.current) ref.current.addEventListener('scroll', handleScroll);

    return () => {
      handleScroll.clear();
      if (ref.current) ref.current.removeEventListener('scroll', handleScroll);
    };
  });
  useEffect(() => {
    const handleResize = debounce(() => {
      updateRefScroll();
    });
    const win = ownerWindow(ref.current);

    win.addEventListener('resize', handleResize);

    return () => {
      handleResize.clear();
      win.removeEventListener('resize', handleResize);
    };
  }, [updateRefScroll, ref]);
  useEffect(() => {
    updateRefScroll();
  });

  return [scrollLeft, scrollRight];
};

export default useRefScroll;
