import {
  AddressFieldReadOnly,
  AddressTypeReadOnly,
  AddressField,
  AddressTypeDropdown,
  CountryDropdown,
  RemoveAddressButton
} from './formatters/index';

export const getFormatters = ({ isReadOnly }) => ({
  address: isReadOnly ? AddressFieldReadOnly : AddressField,
  city: isReadOnly ? AddressFieldReadOnly : AddressField,
  stateProvince: isReadOnly ? AddressFieldReadOnly : AddressField,
  zipPostalCode: isReadOnly ? AddressFieldReadOnly : AddressField,
  country: isReadOnly ? AddressFieldReadOnly : CountryDropdown,
  addressType: isReadOnly ? AddressTypeReadOnly : AddressTypeDropdown,
  removeAddress: RemoveAddressButton
});
