import { Typography, Grid } from '@material-ui/core';
import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.error.main
  },
  errorContainer: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.error.light,
    left: 0,
    position: 'sticky'
  },
  errorHeading: {
    fontWeight: 500,
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.error.main
  }
}));

export const VoidBillReasonDetails = ({ voidBillDescription }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={0}
      className={classes.errorContainer}
    >
      <Grid item>
        <Typography className={classes.errorHeading}>
          <FormattedMessage id="billDetails.voidReason" />
        </Typography>
        <Typography className={classes.errorMessage}>
          {voidBillDescription}
        </Typography>
      </Grid>
    </Grid>
  );
};

VoidBillReasonDetails.propTypes = {
  voidBillDescription: PropTypes.string
};

export default VoidBillReasonDetails;
