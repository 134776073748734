import { useMemo } from 'react';

import { mapBillingTransactionSearchFilter } from './useFilterBasedOnSearchCriteria';

export const useBillingTransactionFilterMemo = ({ searchCriteria }) =>
  useMemo(() => {
    const searchFilter = mapBillingTransactionSearchFilter({
      searchCriteria
    });

    return {
      billingTransactionFilter: {
        ...searchFilter.billingTransactionFilter
      }
    };
  }, [searchCriteria]);
