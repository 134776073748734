import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const FILTERED_USERS_ASSIGNED_TO_TASK_QUERY = gql`
  query getFilteredUserIdsAssignedToTask(
    $taskId: String!
    $userIds: [String!]!
  ) {
    getFilteredTaskResourceAllocationUserIds(taskId: $taskId, userIds: $userIds)
  }
`;

export const useFilteredUsersAssignedToTask = ({ taskId, userIds, skip }) => {
  const { loading, data } = useQuery(FILTERED_USERS_ASSIGNED_TO_TASK_QUERY, {
    variables: {
      taskId,
      userIds
    },
    skip,
    fetchPolicy: 'network-only'
  });

  return {
    loading,
    userIds: data?.getFilteredTaskResourceAllocationUserIds || []
  };
};

export default useFilteredUsersAssignedToTask;
