import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  },
  content: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  },
  header: {
    alignItems: 'center',
    flexGrow: 1
  }
}));

export const CardLoading = ({
  title,
  avatar,
  showHeader = true,
  LoadingComponent
}) => {
  const classes = useStyles();
  const headerClasses = useMemo(
    () => ({
      root: classes.header,
      content: classes.content,
      title: classes.title,
      avatar: classes.avatar
    }),
    [classes.header, classes.content, classes.title, classes.avatar]
  );

  return (
    <Card classes={useMemo(() => ({ root: classes.root }), [classes.root])}>
      {showHeader ? (
        <CardHeader classes={headerClasses} avatar={avatar} title={title} />
      ) : null}
      <Divider />
      <CardContent>
        {LoadingComponent ? (
          <LoadingComponent />
        ) : (
          <>
            <Skeleton width="70%" height={25} />
            <Skeleton width="70%" height={25} />
            <Skeleton width="70%" height={25} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

CardLoading.propTypes = {
  title: PropTypes.node,
  avatar: PropTypes.node,
  showHeader: PropTypes.bool,
  LoadingComponent: PropTypes.func
};

export default CardLoading;
