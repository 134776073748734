import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useState } from 'react';

export const getbillSnapshotQuery = gql`
  query getBillSnapshot($billId: String!) {
    billSnapshot(billId: $billId) {
      id
      asOfDateTime
      signature
    }
  }
`;

export const useGetBillSnapshot = ({ bill }) => {
  const [wait, setWait] = useState(true);
  const { data, loading } = useQuery(getbillSnapshotQuery, {
    variables: {
      billId: bill.id
    },
    skip: Boolean(wait),
    fetchPolicy: 'network-only'
  });

  setTimeout(() => {
    setWait(false);
  }, 5000);

  const billSnapshot = get(data, 'billSnapshot', {});
  const { id, asOfDateTime, signature } = billSnapshot || {};

  return {
    loading: loading || wait,
    billSnapshot: {
      id,
      asOfDate: asOfDateTime,
      signature
    }
  };
};

export default useGetBillSnapshot;
