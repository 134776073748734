import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  primaryText: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary
  },
  secondaryText: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    whiteSpace: 'break-spaces'
  }
}));

export const TaxProfileOption = ({ taxProfile }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.primaryText}>
        {taxProfile.displayText}
      </Typography>
      <Typography className={classes.secondaryText}>
        {taxProfile.description}
      </Typography>
    </div>
  );
};

TaxProfileOption.propTypes = {
  taxProfile: PropTypes.object
};

export default TaxProfileOption;
