/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { TASK_STATUS } from '~/modules/common/enums';
import { isoStringToFormattedISOString } from '~/modules/common/dates/convert';
import { getEstimatedHoursVariation, isHoursDefined, isProject } from '../util';
import { HoursValue } from '../HoursValue';
import { TooltipMissingEstimate } from './TooltipMissingEstimate';
import { TooltipHeader } from './TooltipHeader';
import { TooltipRow } from './TooltipRow';
import { TooltipVariationRow } from './TooltipVariationRow';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    boxShadow: 'rgba(0,0,0,0.2) 0 0 3px 2px',
    padding: theme.spacing(0, 1.25),
    lineHeight: '1.5em',
    fontWeight: 400,
    maxWidth: '400px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.main
  },
  detailsContainer: {
    backgroundColor: '#fff',
    margin: theme.spacing(-1.25, -1.25, 0, -1.25),
    padding: theme.spacing(0.6, 1.25, 1, 1.25)
  },
  noEndDateIcon: {
    marginLeft: theme.spacing(1.25)
  },
  taskTooltipDivider: {
    borderBottom: 'solid 1px #d5d5d5',
    margin: theme.spacing(1, -1.25)
  },
  taskTooltipDividerLight: ({ hasEstimatedHours }) => ({
    borderBottom: 'solid 1px #ebebeb',
    marginTop: !hasEstimatedHours ? theme.spacing(1) : 0
  }),
  containerWithFFOn: {
    padding: theme.spacing(0, 1)
  },
  detailsContainerWithFFOn: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  taskTooltipDividerLightWithFFOn: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  taskTooltipDividerWithFFOn: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
});

const useTooltipRowStyles = makeStyles({
  hours: {
    fontSize: theme.spacing(2) - 1,
    fontWeight: '600'
  },
  hoursWithFFOn: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold
  }
});

const GanttTooltip = ({
  numberFormat,
  intl,
  task,
  canViewEstimate,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const {
    $level,
    taskStatus,
    estimatedHours,
    rolledUpSummary: {
      actualHours,
      totalEstimatedAtCompletionHours,
      totalEstimatedRemainingHours
    }
  } = task;
  const { variationStatus, variation } = getEstimatedHoursVariation({
    estimatedHours,
    totalEstimatedAtCompletionHours
  });
  const tooltipRowClasses = useTooltipRowStyles();

  const isTask = !isProject($level);
  const hasEstimatedHours = isHoursDefined(estimatedHours);
  const hasTotalEstimatedRemainingHours = isHoursDefined(
    totalEstimatedRemainingHours
  );
  const hasTotalEstimatedAtCompletionHours = isHoursDefined(
    totalEstimatedAtCompletionHours
  );
  const classes = useStyles({
    taskStatus,
    variationStatus,
    hasEstimatedHours
  });

  const actualHoursLabel = intl.formatMessage({
    id: 'projectTasksPage.actualHours'
  });
  const workRemainingLabel = intl.formatMessage({
    id: 'projectTasksPage.workRemaining'
  });
  const estimatedAtCompletionLabel = intl.formatMessage({
    id: isTask
      ? 'projectTasksPage.estimatedAtCompletion'
      : 'projectTasksPage.estimatedAtTaskCompletion'
  });
  const originalEstimateLabel = intl.formatMessage({
    id: isTask
      ? 'projectTasksPage.originalEstimate'
      : 'projectTasksPage.projectBudget'
  });
  const estimatedDateOfCompletionLabel = intl.formatMessage({
    id: 'projectTasksPage.estimatedDateOfCompletion'
  });

  return (
    <div
      className={classNames(classes.container, {
        [classes.containerWithFFOn]: isPsaRmpTaskAllocation1Enabled
      })}
    >
      <TooltipHeader
        intl={intl}
        task={task}
        isTask={isTask}
        isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
      />
      {canViewEstimate && (
        <div
          className={classNames(classes.detailsContainer, {
            [classes.detailsContainerWithFFOn]: isPsaRmpTaskAllocation1Enabled
          })}
        >
          <TooltipRow
            intl={intl}
            classes={
              taskStatus === TASK_STATUS.INPROGRESS
                ? tooltipRowClasses
                : undefined
            }
            label={actualHoursLabel}
            data={
              <HoursValue
                value={actualHours}
                isPsaPpmCostEacEnhancements2Enabled={
                  isPsaPpmCostEacEnhancements2Enabled
                }
                numberFormat={numberFormat}
              />
            }
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
          <TooltipRow
            intl={intl}
            label={workRemainingLabel}
            data={
              hasTotalEstimatedRemainingHours ? (
                <HoursValue
                  value={totalEstimatedRemainingHours}
                  isPsaPpmCostEacEnhancements2Enabled={
                    isPsaPpmCostEacEnhancements2Enabled
                  }
                  numberFormat={numberFormat}
                />
              ) : (
                <TooltipMissingEstimate
                  intl={intl}
                  isPsaRmpTaskAllocation1Enabled={
                    isPsaRmpTaskAllocation1Enabled
                  }
                />
              )
            }
            isHours={hasTotalEstimatedRemainingHours}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
          <div
            className={classNames(classes.taskTooltipDivider, {
              [classes.taskTooltipDividerWithFFOn]: isPsaRmpTaskAllocation1Enabled
            })}
          />
          <TooltipRow
            intl={intl}
            classes={tooltipRowClasses}
            label={estimatedAtCompletionLabel}
            data={
              hasTotalEstimatedAtCompletionHours ? (
                <HoursValue
                  value={totalEstimatedAtCompletionHours}
                  isPsaPpmCostEacEnhancements2Enabled={
                    isPsaPpmCostEacEnhancements2Enabled
                  }
                  numberFormat={numberFormat}
                />
              ) : (
                <TooltipMissingEstimate
                  intl={intl}
                  isPsaRmpTaskAllocation1Enabled={
                    isPsaRmpTaskAllocation1Enabled
                  }
                />
              )
            }
            isHours={hasTotalEstimatedAtCompletionHours}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
          <TooltipRow
            intl={intl}
            label={originalEstimateLabel}
            data={
              hasEstimatedHours ? (
                <HoursValue
                  value={estimatedHours}
                  isPsaPpmCostEacEnhancements2Enabled={
                    isPsaPpmCostEacEnhancements2Enabled
                  }
                  numberFormat={numberFormat}
                />
              ) : (
                <TooltipMissingEstimate
                  intl={intl}
                  isPsaRmpTaskAllocation1Enabled={
                    isPsaRmpTaskAllocation1Enabled
                  }
                />
              )
            }
            isHours={hasEstimatedHours}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
          <div
            className={classNames(classes.taskTooltipDividerLight, {
              [classes.taskTooltipDividerLightWithFFOn]: isPsaRmpTaskAllocation1Enabled
            })}
          ></div>
          <TooltipVariationRow
            intl={intl}
            hours={variation}
            variationStatus={variationStatus}
            isRTL={isRTL}
            isPsaPpmCostEacEnhancementsEnabled={
              isPsaPpmCostEacEnhancementsEnabled
            }
            isPsaPpmCostEacEnhancements2Enabled={
              isPsaPpmCostEacEnhancements2Enabled
            }
            numberFormat={numberFormat}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
          {!isTask && (
            <>
              <div
                className={classNames(classes.taskTooltipDivider, {
                  [classes.taskTooltipDividerWithFFOn]: isPsaRmpTaskAllocation1Enabled
                })}
              ></div>
              <TooltipRow
                intl={intl}
                classes={tooltipRowClasses}
                label={estimatedDateOfCompletionLabel}
                data={
                  task.endDate ? (
                    isoStringToFormattedISOString(task.endDate, 'DD')
                  ) : (
                    <ReportProblemOutlined className={classes.noEndDateIcon} />
                  )
                }
                isHours={false}
                isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

GanttTooltip.propTypes = {
  numberFormat: PropTypes.object,
  intl: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  canViewEstimate: PropTypes.bool.isRequired,
  isRTL: PropTypes.bool.isRequired,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default GanttTooltip;
