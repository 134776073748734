import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const billQuery = gql`
  query getBillPermittedActions($id: String!) {
    getBill(id: $id) {
      permittedActions
    }
  }
`;

export const useBillPermission = ({ billId, skip = false }) => {
  const { data, loading } = useQuery(billQuery, {
    variables: {
      id: billId
    },
    skip: skip || !billId,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const billPermission = get(data, 'getBill', {});

  return {
    loading,
    billPermission: !loading ? billPermission : {}
  };
};

export default useBillPermission;
