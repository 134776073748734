import React from 'react';
import { PropTypes } from 'prop-types';
import GridItem from '~/modules/common/components/GridItem';

export const GroupField = ({
  groupSettings,
  component: Component,
  value,
  label,
  onChange,
  rightPadding,
  fullWidth
}) => {
  return (
    groupSettings.isEnabled && (
      <GridItem rightPadding={rightPadding} fullWidth={fullWidth}>
        <Component
          groupSettings={groupSettings}
          label={label}
          onChange={onChange}
          variant="filled"
          value={value}
          attachMenuToRoot
        />
      </GridItem>
    )
  );
};

GroupField.propTypes = {
  groupSettings: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  component: PropTypes.object,
  rightPadding: PropTypes.bool,
  fullWidth: PropTypes.bool
};

export default GroupField;
