import { Collapse, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, memo, useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  useQuickAllocationContext,
  useCreateResourceAllocation,
  useFetchResourceUserProjects
} from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import { useMeContext } from '~/modules/me';
import {
  ShowMoreRow,
  ResourceAllocationChangeSnackBar
} from '~/modules/resourcing/common/components';
import { useMenuState } from '~/modules/common/hooks';
import QuickAllocationProjectGroupHeader from '../QuickAllocationProjectGroupHeader';
import AddProjectButton from '../../../AddProjectButton';
import useStyles, { useSkeletonStyles } from './useStyles';
import QuickAllocationProjectChartRowsLoading from './QuickAllocationProjectChartRowsLoading';
import QuickAllocationProjectGroupDetailRow2 from './QuickAllocationProjectGroupDetailRow2';

const PAGE_SIZE = 5;

const useShowMoreStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    justifyContent: 'flex-end',
    border: 0
  },
  showMoreButton: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    margin: 0
  }
}));

const showMoreMessage = <FormattedMessage id="quickAllocation.showMore" />;

const QuickAllocationProjectGroupDetail2 = ({
  user,
  rangeMaxDate,
  rangeMinDate,
  holidaySummaryPeriods,
  timeoffSummaryPeriods
}) => {
  const {
    scale,
    chartDates,
    leftComponentWidth,
    isShowActualEnabled,
    projectStatusFilterOption,
    setAvailabilityLoading
  } = useQuickAllocationContext();

  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: ''
  });

  const [currentPage, setCurrentPage] = useState(1);
  const end = currentPage * PAGE_SIZE;
  const me = useMeContext();

  const { permissionsMap } = me;
  const isResourceAssignmentEnabled = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );

  const [sort, setSort] = useState({
    field: 'projectName',
    direction: 'ASC'
  });

  const classes = useStyles();
  const showMoreClasses = useShowMoreStyles();
  const skeltonClasses = useSkeletonStyles();

  const resourceUser = useMemo(
    () => ({
      id: user.id,
      uri: user.uri,
      displayText: user.displayText
    }),
    [user.displayText, user.id, user.uri]
  );

  const {
    loading,
    projectRoleAndAllocationData,
    hasNext,
    handleAddResourceAllocation,
    handleRemoveResourceAllocation
  } = useFetchResourceUserProjects({
    userId: user.id,
    pageSize: PAGE_SIZE,
    sort,
    currentPage,
    setCurrentPage,
    isShowActualEnabled,
    projectStatusFilterOption
  });
  const allProjects = Object.values(projectRoleAndAllocationData);
  const visibleAllocatedProjects = useMemo(
    () => Object.values(projectRoleAndAllocationData).slice(0, end) || [],
    [projectRoleAndAllocationData, end]
  );
  const {
    anchorEl: popupAnchorEl,
    onMenuClose: onPopupMenuClose,
    onMenuClick: onPopupMenuClick
  } = useMenuState();

  const {
    createResourceAllocation,
    loading: createResourceAllocationLoading
  } = useCreateResourceAllocation({
    me,
    user,
    handleAddResourceAllocation,
    onPopupMenuClose,
    setSnackBarState
  });

  const hasMore = visibleAllocatedProjects.length < allProjects.length;
  const loadingFirstPage = currentPage === 1 && loading;
  const loadingMore = currentPage > 1 && loading;

  const onShowMoreButtonClick = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage]);

  if (loadingFirstPage)
    return <QuickAllocationProjectChartRowsLoading classes={skeltonClasses} />;

  const noAllocatedProjects = !loading && visibleAllocatedProjects.length === 0;

  return (
    <Collapse in timeout="auto" unmountOnExit>
      <div
        data-qe-id={`${user.displayText}_Project`}
        className={classes.rootExpanded}
      >
        <QuickAllocationProjectGroupHeader
          scale={scale}
          chartDates={chartDates}
          leftComponentWidth={leftComponentWidth}
          isRoleColumnSortable={false}
          onSortChange={setSort}
          sort={sort}
        />
        {visibleAllocatedProjects.map(entry => (
          <QuickAllocationProjectGroupDetailRow2
            key={entry.project.id}
            allocatedProject={entry}
            handleRemoveResourceAllocation={handleRemoveResourceAllocation}
            resourceUser={user}
            holidaySummaryPeriods={holidaySummaryPeriods}
            timeoffSummaryPeriods={timeoffSummaryPeriods}
            rangeMinDate={rangeMinDate}
            rangeMaxDate={rangeMaxDate}
            setSnackBarState={setSnackBarState}
          />
        ))}
        {snackBarState.open && (
          <ResourceAllocationChangeSnackBar
            snackBarState={snackBarState}
            setSnackBarState={setSnackBarState}
          />
        )}
        {noAllocatedProjects && (
          <span className={classes.noneMessage}>
            <FormattedMessage id="quickAllocation.noProjectAllocated" />
          </span>
        )}
        {loadingMore && (
          <QuickAllocationProjectChartRowsLoading classes={skeltonClasses} />
        )}
        <div className={classes.bottomRow}>
          <div className={classes.addProjectButton}>
            {isResourceAssignmentEnabled && (
              <AddProjectButton
                roleSchedules={user.roleSchedules}
                classes={classes}
                scale={scale}
                createResourceAllocation={createResourceAllocation}
                setAvailabilityLoading={setAvailabilityLoading}
                me={me}
                resourceUser={resourceUser}
                loading={createResourceAllocationLoading}
                popupAnchorEl={popupAnchorEl}
                onPopupMenuClick={onPopupMenuClick}
                onPopupMenuClose={onPopupMenuClose}
              />
            )}
          </div>
          {(hasNext || hasMore) && (
            <ShowMoreRow
              classes={showMoreClasses}
              loadMore={onShowMoreButtonClick}
              message={showMoreMessage}
              showIcon
              recordCount={0}
              resourceKey=""
            />
          )}
        </div>
      </div>
    </Collapse>
  );
};

QuickAllocationProjectGroupDetail2.propTypes = {
  user: PropTypes.object,
  rangeMinDate: PropTypes.object,
  rangeMaxDate: PropTypes.object,
  timeoffSummaryPeriods: PropTypes.array,
  holidaySummaryPeriods: PropTypes.array
};

export default memo(QuickAllocationProjectGroupDetail2);
