import { useApolloClient } from '@apollo/client';
import { useMemo, useCallback } from 'react';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import { omit, flow } from 'lodash/fp';
import get from 'lodash.get';
import { useFormik } from 'formik';
import { GET_NEXT_REFERENCE_NUMBER_TEXT } from '~/modules/billing-invoicing/common/hooks/useNextReferenceNumberText';
import { getValidationErrorByUri } from '~/modules/common/graphql/errors';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';

const omitForbiddenFields = flow(omit('__typename'));

export const getValidationSchema = ({ intl }) =>
  object().shape({
    nextNumber: string()
      .trim()
      .test(
        'requiredNextNumber',
        intl.formatMessage({ id: 'referenceNumber.requiredNextNumber' }),
        function test(value) {
          if (this.parent.autoGeneratedNumber) {
            return Boolean(value);
          }

          return true;
        }
      )
  });

export const useOnSubmit = ({
  updateReferenceNumberSetting,
  onReferenceSettingClose,
  onReferenceNumberChange,
  client,
  transactionType,
  intl
}) =>
  useCallback(
    async (values, { setFieldError }) => {
      try {
        await updateReferenceNumberSetting({
          ...omitForbiddenFields(values),
          minimumDigits: values.nextNumber.length,
          hasLeadingZero: true
        });

        if (values.autoGeneratedNumber) {
          const { data } = await client.query({
            GET_NEXT_REFERENCE_NUMBER_TEXT,
            query: GET_NEXT_REFERENCE_NUMBER_TEXT,
            variables: {
              transactionType
            },
            fetchPolicy: 'network-only'
          });
          const nextReferenceNumberText = get(
            data,
            `transactionNextReferenceNumberText`,
            ''
          );

          onReferenceNumberChange({
            target: { value: nextReferenceNumberText }
          });
        }
        onReferenceSettingClose();
      } catch (error) {
        const duplicateReferenceNumber =
          transactionType === billingTransactionType.BILL
            ? getValidationErrorByUri(
                error,
                'urn:replicon:validation-failure:invoice-reference-number-not-unique'
              )
            : transactionType === billingTransactionType.CREDITMEMO
            ? getValidationErrorByUri(
                error,
                'urn:replicon:validation-failure:client-credit-memo-reference-number-not-unique'
              )
            : transactionType === billingTransactionType.PAYMENT
            ? getValidationErrorByUri(
                error,
                'urn:replicon:validation-failure:client-payment-reference-number-not-unique'
              )
            : null;

        if (duplicateReferenceNumber) {
          setFieldError(
            'nextNumber',
            intl.formatMessage({
              id: 'referenceNumber.duplicateReferenceNumber'
            })
          );
        }
      }
    },
    [
      client,
      intl,
      onReferenceNumberChange,
      onReferenceSettingClose,
      transactionType,
      updateReferenceNumberSetting
    ]
  );

export const useReferenceNumberSettingFormState = ({
  transactionType,
  updateReferenceNumberSetting,
  referenceNumberSetting,
  onReferenceSettingClose,
  onReferenceNumberChange
}) => {
  const intl = useIntl();
  const client = useApolloClient();
  const initialValues = useMemo(
    () => ({
      ...referenceNumberSetting,
      editAutoGeneratedNumber:
        referenceNumberSetting.editAutoGeneratedNumber || false,
      autoGeneratedNumber: referenceNumberSetting.autoGeneratedNumber || false,
      prefix: referenceNumberSetting.prefix || '',
      nextNumber: referenceNumberSetting.nextNumber || '001'
    }),
    [referenceNumberSetting]
  );
  const validationSchema = useMemo(() => getValidationSchema({ intl }), [intl]);
  const onSubmit = useOnSubmit({
    updateReferenceNumberSetting,
    onReferenceSettingClose,
    onReferenceNumberChange,
    client,
    transactionType,
    intl
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  });

  return formik;
};

export default useReferenceNumberSettingFormState;
