import { Grid, Button, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';
import { RatesTable } from './components';

const useStyles = makeStyles(theme => ({
  showMore: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const rateTypeReferenceKeys = {
  projectRate: 'projectReference',
  roleRates: 'roleReference',
  userRates: 'userReference'
};

export const RateCardReadOnly = ({
  rates,
  rateTypeKey,
  hasMoreRows,
  loadMoreRows
}) => {
  const classes = useStyles();
  const onClick = React.useCallback(() => {
    loadMoreRows({ rateTypeKey });
  }, [loadMoreRows, rateTypeKey]);

  const scheduleEntries = rates.reduce((schedules, currentSchedule) => {
    const entry = currentSchedule.effectiveBillingRate;

    if (entry)
      entry[`${rateTypeReferenceKeys[rateTypeKey]}`] =
        currentSchedule[`${rateTypeReferenceKeys[rateTypeKey]}`];

    return [...schedules, entry];
  }, []);

  return scheduleEntries.length ? (
    <Grid container spacing={0} data-qe-id={rateTypeKey}>
      <Grid item xs={12}>
        <RatesTable
          rates={scheduleEntries}
          rateTypeReferenceKey={rateTypeReferenceKeys[rateTypeKey]}
        />
      </Grid>
      {hasMoreRows ? (
        <Button color="primary" className={classes.showMore} onClick={onClick}>
          <FormattedMessage id="rateCard.showMore" />
        </Button>
      ) : null}
    </Grid>
  ) : (
    <NoDataItem>
      <FormattedMessage id="rateCard.noRates.rate" />
    </NoDataItem>
  );
};

RateCardReadOnly.propTypes = {
  rates: PropTypes.array,
  rateTypeKey: PropTypes.string,
  loadMoreRows: PropTypes.func,
  hasMoreRows: PropTypes.bool
};

export default RateCardReadOnly;
