import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const SYNC_BILLS = gql`
  mutation syncBills($input: SyncBillsInput!) {
    syncBills2(syncBillsInput: $input) {
      completed
      failed
      total
    }
  }
`;

export const mapToServiceFilter = ({
  filter: {
    clients: clientUris,
    transactionTypes,
    dateRange,
    text,
    balanceStatusList,
    isOverdue,
    dueDate
  } = {}
}) => ({
  clientUris: clientUris || [],
  transactionTypes,
  dateRange,
  searchTerm: text,
  balanceStatusList,
  isOverdue,
  dueDate
});

export const useSyncBills = ({
  includedBillIds,
  excludedBillIds,
  isAllSelected,
  filter,
  setIsSyncing
}) => {
  const [syncBills] = useMutation(SYNC_BILLS);

  return {
    syncBills: async () => {
      setIsSyncing(true);
      await syncBills({
        variables: {
          input: {
            includeBillIds: includedBillIds,
            excludeBillIds: excludedBillIds,
            allSelected: isAllSelected,
            filter: mapToServiceFilter({ filter })
          }
        },
        refetchQueries: ['getPageOfBillingTransactions'],
        awaitRefetchQueries: true
      });
      setIsSyncing(false);
    }
  };
};
export default useSyncBills;
