import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import getNumberSuffixFormat from '~/modules/common/components/SummaryFormattedNumber/getNumberSuffixFormat';
import { FormattedNumber } from '~/modules/common/components/SummaryFormattedNumber/SummaryFormattedNumber';

export const useHoursVarianceStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold',
    fontSize: theme.typography.caption.fontSize
  },
  diffPositive: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  },
  diffNegative: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  }
}));

export const HoursVarianceNumber = ({
  classes: classesOverrides,
  value,
  showAddIcon,
  fixedDecimalScale = false,
  isRoundedValue = true,
  formattedMessageKey
}) => {
  const { formattedValue, suffix } = isRoundedValue
    ? getNumberSuffixFormat(value)
    : { formattedValue: value, suffix: '' };
  const classes = useHoursVarianceStyles({ classes: classesOverrides });

  return (
    <FormattedNumber
      classes={classes}
      value={Math.abs(Number(formattedValue))}
      suffix={suffix}
      isHour
      isHigher={showAddIcon}
      showAddIcon={showAddIcon}
      fixedDecimalScale={fixedDecimalScale}
      formattedMessageKey={formattedMessageKey}
    />
  );
};

HoursVarianceNumber.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.number,
  showAddIcon: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  formattedMessageKey: PropTypes.string,
  isRoundedValue: PropTypes.bool
};

export default HoursVarianceNumber;
