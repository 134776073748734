import { useState, useCallback } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

const setPeriod = ({
  setAllPeriods,
  allPeriods,
  hasPartialPeriod,
  allocatedValue,
  setAllocationPeriodEditTarget,
  getNextIndex,
  anchorEl,
  periodStartDate,
  periodEndDate
}) => {
  const currentPeriodIndex = allPeriods.findIndex(
    period =>
      period.startDate <= periodStartDate && period.endDate >= periodEndDate
  );
  const allocationOverlays = anchorEl
    .closest('.resourceRequestUserAllocation')
    .getElementsByClassName('resourceRequestAllocationOverlay');

  const currentPeriod = allPeriods[currentPeriodIndex];

  if (hasPartialPeriod) {
    setAllocationPeriodEditTarget({
      anchorEl: allocationOverlays[currentPeriodIndex],
      startDate: currentPeriod.startDate,
      endDate: currentPeriod.endDate,
      allocatedHours: currentPeriod.totalHours || 0
    });

    return;
  }

  const periodDetailsWithHours = [...allPeriods];

  periodDetailsWithHours[currentPeriodIndex].totalHours = allocatedValue;
  setAllPeriods(periodDetailsWithHours);

  const newIndex = getNextIndex(currentPeriodIndex);
  const isNewIndexOutOfBounds =
    newIndex < 0 || newIndex > periodDetailsWithHours.length - 1;

  if (isNewIndexOutOfBounds) {
    setAllocationPeriodEditTarget(null);

    return;
  }

  const nextPeriod = periodDetailsWithHours[newIndex];

  setAllocationPeriodEditTarget({
    anchorEl: allocationOverlays[newIndex],
    startDate: nextPeriod.startDate,
    endDate: nextPeriod.endDate,
    allocatedHours: nextPeriod.totalHours || 0
  });
};

const useResourceTimelineEditorChangeHandlers = ({
  chartDates,
  allocationPeriodEditTarget,
  setAllocationPeriodEditTarget,
  isPercentageMode,
  resourceAllocation
}) => {
  const [allPeriods, setAllPeriods] = useState(null);

  const handleAllocationPeriodClick = useCallback(
    ({
      event,
      startDate,
      endDate,
      totalAllocatedHours,
      overlayPeriods,
      hasAllocationStart,
      hasAllocationEnd
    }) => {
      setAllPeriods(overlayPeriods);

      if (!event.currentTarget) {
        return;
      }

      if (isPercentageMode) {
        const allocationOverlays = event.currentTarget
          .closest('.resourceRequestUserAllocation')
          .getElementsByClassName('resourceRequestAllocationOverlay');

        const overlayIndex = chartDates.findIndex(chartDate => {
          return startDate >= chartDate.start && startDate <= chartDate.end;
        });

        setAllocationPeriodEditTarget({
          anchorEl: allocationOverlays[overlayIndex],
          startDate,
          overlayPeriods,
          endDate,
          allocatedHours: totalAllocatedHours
        });

        return;
      }

      setAllocationPeriodEditTarget({
        anchorEl: event.currentTarget,
        startDate,
        overlayPeriods,
        endDate,
        allocatedHours: totalAllocatedHours,
        hasAllocationPartialStart:
          hasAllocationStart &&
          mapIsoStringtoUtcObject(resourceAllocation.startDate) > startDate,
        hasAllocationPartialEnd:
          hasAllocationEnd &&
          mapIsoStringtoUtcObject(resourceAllocation.endDate) < endDate
      });
    },
    [
      chartDates,
      isPercentageMode,
      resourceAllocation.endDate,
      resourceAllocation.startDate,
      setAllocationPeriodEditTarget
    ]
  );

  const handleAllocationPeriodClose = useCallback(() => {
    setAllocationPeriodEditTarget(null);
  }, [setAllocationPeriodEditTarget]);

  const setNextPeriod = useCallback(
    allocatedValue => {
      const {
        hasAllocationPartialEnd,
        anchorEl,
        startDate,
        endDate
      } = allocationPeriodEditTarget;

      setPeriod({
        setAllPeriods,
        allPeriods,
        anchorEl,
        hasPartialPeriod: hasAllocationPartialEnd,
        periodStartDate: startDate,
        periodEndDate: endDate,
        allocatedValue,
        setAllocationPeriodEditTarget,
        getNextIndex: currentPeriodIndex => currentPeriodIndex + 1
      });
    },
    [allPeriods, allocationPeriodEditTarget, setAllocationPeriodEditTarget]
  );

  const setPreviousPeriod = useCallback(
    allocatedValue => {
      const {
        hasAllocationPartialStart,
        anchorEl,
        startDate,
        endDate
      } = allocationPeriodEditTarget;

      setPeriod({
        setAllPeriods,
        allPeriods,
        anchorEl,
        hasPartialPeriod: hasAllocationPartialStart,
        periodStartDate: startDate,
        periodEndDate: endDate,
        allocatedValue,
        setAllocationPeriodEditTarget,
        getNextIndex: currentPeriodIndex => currentPeriodIndex - 1
      });
    },
    [allPeriods, allocationPeriodEditTarget, setAllocationPeriodEditTarget]
  );

  return {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  };
};

export default useResourceTimelineEditorChangeHandlers;
