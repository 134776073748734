import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import CommentIcon from '@material-ui/icons/CommentSharp';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';
import {
  DateRange,
  Decimal,
  FormSection,
  Percentage,
  RowItem,
  EntityDrawerSection
} from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { getResourceRequestTotal } from '~/modules/resourcing/common/enhancers/withResourceRequestTotal';
import useMeContext from '~/modules/me/useMeContext';
import ExpandableInfoSections from '~/modules/common/components/ExpandableInfoSections';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { ResourceRequestStatus } from '~/types';
import ResourceRequestImportanceDialog from '../ResourceRequestImportanceDialog';
import { useExpandableSectionsReadOnly } from './useExpandableSectionsReadOnly';
import ExpandableRequestedAmount from './ExpandableRequestedAmount';
import useStyles, {
  useQualificationStyles,
  useDateRangeStyles,
  useFormStyles
} from './useStyles';

export const ResourceRequestDrawerReadonlyDetails = ({
  maxSkillLevel,
  project,
  resourceAllocations,
  resourceRequest
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const dateRangeClasses = useDateRangeStyles();
  const formClasses = useFormStyles();

  const { baseCurrency, locale } = useMeContext();
  const numberSettings = getReactNumberFormatFromMe({ locale });

  const { resourceRequestTotalHours, resourceRequestTotalCost } = useMemo(
    () =>
      getResourceRequestTotal({
        ...resourceRequest,
        currency: resourceRequest.currency || baseCurrency
      }),
    [baseCurrency, resourceRequest]
  );

  const {
    open: importanceDialogOpen,
    openDialog: openImportanceDialog,
    closeDialog: closeImportanceDialog
  } = useDialogState();

  const skills = useMemo(
    () => getSkillsGroupedByCategory(resourceRequest.skills),
    [resourceRequest.skills]
  );

  const expandableSections = useExpandableSectionsReadOnly({
    skills,
    resourceRequest,
    maxSkillLevel,
    openImportanceDialog
  });

  const qualificationsSectionClasses = useQualificationStyles();

  const loadValue = resourceRequest.load;

  const importanceValues = useMemo(
    () => ({
      location: resourceRequest.location,
      division: resourceRequest.division,
      serviceCenter: resourceRequest.serviceCenter,
      costCenter: resourceRequest.costCenter,
      department: resourceRequest.department,
      employeeType: resourceRequest.employeeType,
      role: resourceRequest.role,
      skills: resourceRequest.skills,
      tags: resourceRequest.tags,
      resourcePools: resourceRequest.resourcePools,
      requestedCostRate: {
        amount: resourceRequest.roleRate,
        currency: resourceRequest.currency
      },
      preferredResources: resourceRequest.preferredResources
    }),
    [
      resourceRequest.costCenter,
      resourceRequest.department,
      resourceRequest.division,
      resourceRequest.employeeType,
      resourceRequest.location,
      resourceRequest.serviceCenter,
      resourceRequest.role,
      resourceRequest.skills,
      resourceRequest.tags,
      resourceRequest.roleRate,
      resourceRequest.currency,
      resourceRequest.resourcePools,
      resourceRequest.preferredResources
    ]
  );

  const hasAllocation = useMemo(
    () =>
      !(
        resourceRequest.requestStatus === ResourceRequestStatus.Draft ||
        resourceRequest.requestStatus === ResourceRequestStatus.Rejected ||
        resourceRequest.requestStatus === ResourceRequestStatus.Tobehired ||
        resourceRequest.requestStatus === ResourceRequestStatus.Submitted
      ) || resourceAllocations.length !== 0,
    [resourceRequest.requestStatus, resourceAllocations]
  );

  const { permittedActionUris } = project;

  const canViewCostData = permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  return (
    <>
      <FormSection classes={formClasses}>
        {expandableSections.length > 0 ? (
          <EntityDrawerSection
            classes={qualificationsSectionClasses}
            title={intl.formatMessage({
              id: 'resourceRequestDrawerDetails.qualifications'
            })}
          >
            <ResourceRequestImportanceDialog
              weights={resourceRequest.requestAttributeWeights}
              values={importanceValues}
              open={importanceDialogOpen}
              onClose={closeImportanceDialog}
            />
            <div className={classes.buttonRow}>
              <Button onClick={openImportanceDialog}>
                <FormattedMessage id="resourceRequestDrawerDetails.importance" />
              </Button>
            </div>
            <ExpandableInfoSections sections={expandableSections} />
            <div className={classes.hr} />
          </EntityDrawerSection>
        ) : null}
        <ExpandableRequestedAmount
          isItemExpanded={!hasAllocation}
          expandableButtonLabel={intl.formatMessage({
            id: 'resourceRequestDrawerDetails.requestedAmount'
          })}
        >
          <ul className={classes.row}>
            <RowItem
              label={intl.formatMessage({
                id: 'resourceRequestDrawerReadonlyDetails.dates'
              })}
            >
              <DateRange
                classes={dateRangeClasses}
                start={resourceRequest.startDate}
                end={resourceRequest.endDate}
              />
            </RowItem>
          </ul>
          <>
            <ul className={classes.row}>
              <RowItem
                label={intl.formatMessage({
                  id: 'resourceRequestDrawerReadonlyDetails.noOfResources'
                })}
              >
                <Decimal
                  value={resourceRequest.quantity}
                  fixedDecimalScale={false}
                  className={classes.defaultFontFFOn}
                />{' '}
                <span className={classes.defaultFontFFOn}>@</span>{' '}
                <Percentage
                  value={loadValue}
                  fixedDecimalScale={false}
                  precision={numberSettings.decimalScale || 2}
                  className={classNames(classes.defaultFontFFOn, {
                    [classes.loadOver]: loadValue > 100
                  })}
                />
                {resourceRequest.isAdjustedLoading && '*'}
                {resourceRequest.isAdjustedLoading && (
                  <FormHelperText className={classes.adjustedLoading}>
                    {intl.formatMessage({
                      id: 'resourceLoadingField.adjustedLoading'
                    })}
                  </FormHelperText>
                )}
              </RowItem>
              <RowItem
                label={intl.formatMessage({
                  id: 'resourceRequestDrawerReadonlyDetails.totalRequestedHours'
                })}
              >
                <Decimal
                  value={resourceRequestTotalHours}
                  className={classes.totalHours}
                  fixedDecimalScale={false}
                  precision={numberSettings.decimalScale || 2}
                />
              </RowItem>
            </ul>
            <ul className={classes.row}>
              {canViewCostData ? (
                <>
                  <RowItem
                    label={intl.formatMessage({
                      id:
                        'resourceRequestDrawerReadonlyDetails.requestedCostRate'
                    })}
                  >
                    <Decimal
                      value={resourceRequest.roleRate}
                      prefix={`${resourceRequestTotalCost.currencySymbol} `}
                      fixedDecimalScale={false}
                      className={classes.defaultFontFFOn}
                      precision={numberSettings.decimalScale || 2}
                    />
                  </RowItem>
                  <RowItem
                    label={intl.formatMessage({
                      id:
                        'resourceRequestDrawerReadonlyDetails.totalRequestedCost'
                    })}
                  >
                    <Decimal
                      value={resourceRequestTotalCost.amount}
                      prefix={`${resourceRequestTotalCost.currencySymbol} `}
                      fixedDecimalScale={false}
                      className={classes.defaultFontFFOn}
                      precision={numberSettings.decimalScale || 2}
                    />
                  </RowItem>
                </>
              ) : null}
            </ul>
          </>
        </ExpandableRequestedAmount>
        {resourceRequest.comment && (
          <>
            <div className={classes.hr} />
            <ul className={classNames(classes.row, classes.comment)}>
              <CommentIcon className={classes.inputIcon} />
              &nbsp;
              <li className={classes.defaultFontSize}>
                {resourceRequest.comment}
              </li>
            </ul>
          </>
        )}
      </FormSection>
    </>
  );
};

export const getSkillsGroupedByCategory = options => {
  const uniqueCategories = sortedUniqBy(
    sortBy(options, skillItem => skillItem.category.displayText),
    skillItem => skillItem.category.id
  ).map(option => option.category);

  return uniqueCategories.reduce((groups, category) => {
    const sortedOptions = [...options].sort((a, b) =>
      a.displayText.localeCompare(b.displayText)
    );

    return [
      ...groups,
      {
        category: {
          id: category.id,
          uri: category.id,
          displayText: category.displayText
        },
        skillAssignments: sortedOptions.filter(
          skillItem => skillItem.category.id === category.id
        )
      }
    ];
  }, []);
};

ResourceRequestDrawerReadonlyDetails.propTypes = {
  project: PropTypes.object,
  resourceRequest: PropTypes.object.isRequired,
  maxSkillLevel: PropTypes.number,
  resourceAllocations: PropTypes.array
};

export default ResourceRequestDrawerReadonlyDetails;
