import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'block',
    fontSize: theme.typography.caption.fontSize,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  rootDraft: {
    color: theme.palette.resourceRequest.draft.dark
  },
  rootSubmitted: {
    color: theme.palette.resourceRequest.pending.dark
  },
  rootTentative: {
    color: theme.palette.resourceRequest.proposed.dark
  },
  rootRejected: {
    color: theme.palette.resourceRequest.requestRejected.dark
  },
  rootAllocationRejected: {
    color: theme.palette.resourceRequest.requestRejected.dark
  },
  rootComplete: {
    color: theme.palette.resourceRequest.complete.dark
  },
  rootToBeHired: {
    color: theme.palette.resourceRequest.toBeHired.dark
  }
}));
