import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { defaultScriptsFormatter } from '~/modules/projects/project/common/scriptEnhancers';
import { useMeContext } from '~/modules/me/useMeContext';
import { planScriptsFormatter, clauseFormatter } from './useBillPlanEnhancers';
import { useProjectBillingClauses } from './useProjectBillingClauses';

export const projectBillPlanQuery = gql`
  query getProjectBillPlans($projectId: String!) {
    project(projectId: $projectId) {
      id
      startDate: startDate2
      endDate: endDate2
      name
      expenseCodes {
        id
        displayText
        isExpenseEntryToThisCodeAllowed
        expenseEntryType
      }
      defaultBillingCurrency {
        id
        displayText
        symbol
      }
      projectTotalMilestoneContractAmount {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      billPlans {
        totalEstimatedAmount {
          amount
          currency {
            id
            displayText
            symbol
          }
        }
        billPlans {
          id
          displayText
          description
          estimatedAmount {
            amount
            currency {
              id
              symbol
              displayText
            }
          }
          scripts
        }
      }
    }
  }
`;

export const mapToProjectBillPlan = ({
  clauses,
  billPlans,
  projectTotalMilestoneContractAmount: contractAmount,
  baseCurrency
}) => {
  const billPlanMap = (billPlans || []).reduce(
    (planMap, current) => ({ ...planMap, [current.id]: current }),
    {}
  );

  return clauses?.map(c => {
    const formatClause = clauseFormatter(baseCurrency)[c.slug];
    const clause = formatClause
      ? formatClause({ clause: c, contractAmount })
      : c;
    const billPlan = billPlanMap[clause.id];

    if (billPlan) {
      const scriptsFormatter = planScriptsFormatter(baseCurrency)[clause.slug];
      const enhancedPlan = scriptsFormatter
        ? scriptsFormatter(billPlan, contractAmount)
        : billPlan;
      const modifiedPlan = defaultScriptsFormatter(enhancedPlan);

      return { ...clause, ...modifiedPlan, slug: clause.slug };
    }

    return { ...clause, scripts: [] };
  });
};

export const useProjectBillPlans = (projectUri, skip = false) => {
  const { baseCurrency } = useMeContext();

  const { clauses } = useProjectBillingClauses();
  const { data, loading, refetch } = useQuery(projectBillPlanQuery, {
    variables: {
      projectId: projectUri
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    skip
  });

  const {
    billPlans,
    projectTotalMilestoneContractAmount,
    startDate,
    endDate,
    name,
    defaultBillingCurrency,
    expenseCodes
  } = get(data, 'project', {});

  return {
    loading,
    refetchBillPlans: refetch,
    billPlans: loading
      ? {}
      : {
          totalEstimatedAmount: billPlans?.totalEstimatedAmount,
          billPlans: mapToProjectBillPlan({
            clauses,
            billPlans: billPlans?.billPlans,
            projectTotalMilestoneContractAmount,
            baseCurrency
          })
        },
    dates: loading ? {} : { startDate, endDate },
    projectName: loading ? null : name,
    projectCurrency: defaultBillingCurrency,
    expenseCodes: loading ? [] : expenseCodes
  };
};

export default useProjectBillPlans;
