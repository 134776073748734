import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { POLICYTYPE } from '~/modules/common/enums';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PAGE_SIZE } from '~/modules/common/const';

export const LOCATIONS_QUERY = gql`
  query getLocations($searchTerm: String, $policy: String, $pageSize: Int) {
    locations(
      searchTerm: $searchTerm
      page: 1
      pageSize: $pageSize
      policy: $policy
    ) {
      id
      displayText
      hierarchyLevel
    }
  }
`;

const mappedLocations = data =>
  data?.locations?.map(location => ({
    ...location,
    value: location.id,
    label: location.displayText,
    key: location.displayText
  }));

const useLocations = ({
  searchTerm,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  includeNoneOption = false,
  noneOptionText = ''
}) => {
  const { data, loading, refetch } = useQuery(LOCATIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchTerm,
      pageSize: PAGE_SIZE + 1,
      policy
    },
    context: {
      debounceKey: 'location-search',
      debounceTimeout: 250
    }
  });

  const locations = !loading ? mappedLocations(data) : [];

  return {
    locations: (locations.length && includeNoneOption
      ? prependNoneItem(noneOptionText, locations)
      : locations
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    loading,
    refetch,
    hasMore: locations.length > PAGE_SIZE
  };
};

export const fetchLocations = ({
  apolloClient,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  intl
}) => async searchTerm => {
  const { data } = await apolloClient.query({
    query: LOCATIONS_QUERY,
    variables: { searchTerm, pageSize: PAGE_SIZE, policy }
  });

  return prependNoneItem(
    intl.formatMessage({ id: 'resourceBasicInfoCard.noLocation' }),
    mappedLocations(data)
  );
};

export const useSearchableLocations = ({ policy } = {}) => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const fetchOptions = fetchLocations({
    apolloClient,
    policy,
    intl
  });

  return { fetchOptions };
};

export default useLocations;
