import { makeStyles } from '@material-ui/core/styles';
import { ResourceRequestStatus } from '~/types';
import dashBorderSVG from '../DashedBorder.svg';

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    height: theme.spacing(3),
    userSelect: 'none'
  },
  container: {
    width: '100%',
    ...theme.typography.caption,
    height: `${theme.spacing(3)}px`,
    borderColor: theme.palette.grey[600],
    cursor: 'move',
    border: `2px dashed ${theme.palette.grey[700]}`,
    borderImage: `url(${dashBorderSVG}) 2 repeat`,
    borderRadius: theme.spacing(1.5)
  },
  readOnlyContainer: {
    cursor: 'default'
  },
  loadingLabel: ({ status }) => ({
    color:
      status && status === ResourceRequestStatus.Rejected
        ? theme.palette.common.white
        : theme.palette.text.primary
  })
}));
