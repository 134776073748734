import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import SyncStatusItem from './SyncStatusItem';
import { useSyncStatusTags } from './hooks';

export const SyncStatusFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: 'search.syncStatus' });
  const noneOptionText = formatMessage({ id: 'syncStatus.none' });

  const renderOption = useCallback(
    option =>
      option.key === formatMessage({ id: 'NONE' }) ? (
        <NoneDropdownItem value={option.displayText} />
      ) : (
        <SyncStatusItem value={option.displayText} />
      ),
    [formatMessage]
  );

  const { syncStatusTags, loading } = useSyncStatusTags({
    includeNoneOption: true,
    noneOptionText
  });

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option => selected.some(v => v.id === option.id),
    [selected]
  );
  const syncStatusTagOptions = loading ? [] : syncStatusTags;

  const classes = useStyles();

  return (
    <FacetDetailField label={label} className={className}>
      <SimpleAutocomplete
        loading={loading}
        options={syncStatusTagOptions}
        inputLabel={formatMessage({ id: 'search.syncStatus' })}
        value={selected}
        variant="standard"
        onChange={onValueChange}
        multiple
        fullWidth
        classes={classes}
        hiddenLabel
        noOptionsText={formatMessage({ id: 'syncStatus.noSyncStatus' })}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        label={label}
        optionPropText="label"
      />
    </FacetDetailField>
  );
};

SyncStatusFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default SyncStatusFacetDetails;
