import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import DrilldownTableFooter from './DrilldownTableFooter';
import DrilldownTableHeader from './DrilldownTableHeader';
import DrilldownTableRow from './DrilldownTableRow';

const useStyles = makeStyles(theme => ({
  noMatchMessage: {
    padding: theme.spacing(3, 2),
    color: theme.palette.text.secondary
  },
  table: {
    backgroundColor: theme.palette.grey[100],
    alignSelf: 'flex-start',
    width: 'unset',
    minWidth: '100%',
    borderCollapse: 'separate',
    margin: 0,
    padding: 0,
    border: 'none'
  },
  tbody: {
    '&:first-of-type $groupHeaderRowCell': {
      borderTop: 'none'
    }
  },

  tableHeader: {},
  tableHeaderRow: {},
  tableHeaderCellFirst: {},
  tableHeaderCellFirstFrozen: {},
  tableHeaderCell: {},
  tableHeaderCellLast: {},
  tableHeaderCellLastFrozen: {},

  row: {},
  cell: {},
  cellFirst: {},
  cellFirstFrozen: {},
  cellLast: {},
  cellLastFrozen: {},

  groupHeaderRow: {},
  groupHeaderRowCell: {},

  tableFooter: {},
  tableFooterRow: {},
  tableFooterCellFirst: {},
  tableFooterCellFirstFrozen: {},
  tableFooterCell: {},
  tableFooterCellLast: {},
  tableFooterCellLastFrozen: {},

  disableGutters: {}
}));

const DrilldownTable = ({
  columns,
  items,
  totals,
  noItemsMessage,
  onRowClick,
  canView = true,
  rowIdKey = 'slug',
  onSortChange,
  sort
}) => {
  const classes = useStyles();

  if (items && items.length > 0) {
    return (
      <Table className={classes.table} role="table">
        <DrilldownTableHeader
          columns={columns}
          onSortChange={onSortChange}
          sort={sort}
        />
        {totals && <DrilldownTableFooter columns={columns} totals={totals} />}
        <TableBody role="rowgroup" className={classes.tbody}>
          {items.map(item => (
            <DrilldownTableRow
              key={item.id}
              columns={columns}
              item={item}
              onClick={onRowClick}
              canView={canView}
              idKey={rowIdKey}
            />
          ))}
        </TableBody>
      </Table>
    );
  }

  return <div className={classes.noMatchMessage}>{noItemsMessage}</div>;
};

DrilldownTable.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array,
  totals: PropTypes.object,
  noItemsMessage: PropTypes.node.isRequired,
  onRowClick: PropTypes.func,
  canView: PropTypes.bool,
  rowIdKey: PropTypes.string,
  onSortChange: PropTypes.func,
  sort: PropTypes.object
};

export default DrilldownTable;
