import { ResourceAllocationStatus } from '~/types';
import { getTotalHours } from '~/modules/resourcing/common/util';

export const CREATE_ALLOCATION = 'CREATE_ALLOCATION';
export const INITIAL_STATE = 'INITIAL_STATE';
export const UPDATE_ALLOCATION = 'UPDATE_ALLOCATION';
export const DELETE_ALLOCATION = 'DELETE_ALLOCATION';
export const PROPOSE_ALLOCATION = 'PROPOSE_ALLOCATION';
export const COMPLETE_ALLOCATION = 'COMPLETE_ALLOCATION';

export const filterAllocationScheduleRules = alloc => ({
  ...alloc,
  scheduleRules: alloc.scheduleRules.filter(rule => rule.do.setHours > 0)
});

export default (state, action = {}) => {
  const allocation = action.values;

  if (
    allocation &&
    allocation.allocatedDifference &&
    action.type !== UPDATE_ALLOCATION
  ) {
    delete allocation.allocatedDifference;
  }

  switch (action.type) {
    case INITIAL_STATE: {
      return {
        ...state,
        allocations: action.allocations || []
      };
    }

    case CREATE_ALLOCATION: {
      return {
        ...state,
        allocations: [
          ...state.allocations,
          filterAllocationScheduleRules(allocation)
        ]
      };
    }

    case UPDATE_ALLOCATION: {
      return {
        ...state,
        allocations: [
          ...state.allocations.filter(r => r.id !== allocation.id),
          filterAllocationScheduleRules(allocation)
        ]
      };
    }

    case DELETE_ALLOCATION: {
      return {
        ...state,
        allocations: [...state.allocations.filter(r => r.id !== allocation.id)]
      };
    }

    case PROPOSE_ALLOCATION: {
      return {
        ...state,
        allocations: state.allocations.map(r => ({
          ...r,
          allocationStatus: ResourceAllocationStatus.Proposed
        }))
      };
    }

    case COMPLETE_ALLOCATION: {
      return {
        ...state,
        allocations: state.allocations.map(r => ({
          ...r,
          allocationStatus: ResourceAllocationStatus.Committed
        }))
      };
    }

    default:
      return state;
  }
};

export const createResourceAllocation = allocation => ({
  type: CREATE_ALLOCATION,
  values: allocation
});

export const updateResourceAllocation = allocation => ({
  type: UPDATE_ALLOCATION,
  values: allocation
});

export const deleteResourceAllocation = allocation => ({
  type: DELETE_ALLOCATION,
  values: allocation
});

export const getAllocationForLoadValueChange = ({
  resourceAllocation,
  load
}) => {
  const newScheduleRules = resourceAllocation.scheduleRules.map(r => ({
    ...r,
    do: { ...r.do, load }
  }));

  const newTotalHours = getTotalHours({
    startDate: resourceAllocation.startDate,
    endDate: resourceAllocation.endDate,
    scheduleRules: newScheduleRules
  });

  return {
    ...resourceAllocation,
    load,
    scheduleRules: newScheduleRules,
    isAdjustedLoading: false,
    totalHours: newTotalHours
  };
};

export const getAllocationOnTotalHoursChange = ({
  resourceAllocation,
  totalHours
}) => {
  const actualTotalHours = getTotalHours({
    startDate: resourceAllocation.startDate,
    endDate: resourceAllocation.endDate,
    scheduleRules: resourceAllocation.scheduleRules.map(r => ({
      ...r,
      do: { ...r.do, load: 100 }
    }))
  });

  const newLoad =
    actualTotalHours > 0 && totalHours > 0
      ? (totalHours * 100.0) / actualTotalHours
      : 0;

  const newScheduleRules = resourceAllocation.scheduleRules.map(r => ({
    ...r,
    do: { ...r.do, load: newLoad }
  }));

  return {
    ...resourceAllocation,
    totalHours,
    load: newLoad,
    scheduleRules: newScheduleRules
  };
};
