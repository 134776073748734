import { DialogContentText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';

const resourceKeys = {
  header: 'resourceRequestActions.removeResourceRequest',
  actionButton: 'resourceRequestActions.removeRequest'
};

export const ResourceRequestDeleteActions = ({
  showRemoveRequestDialog,
  closeRemoveRequestDialog,
  onDeleteRequestClick
}) =>
  showRemoveRequestDialog && (
    <RemoveEntityConfirmationDialog
      resourceKeys={resourceKeys}
      open={showRemoveRequestDialog}
      onClose={closeRemoveRequestDialog}
      onConfirm={onDeleteRequestClick}
    >
      <DialogContentText>
        <FormattedMessage id="resourceRequestActions.removeResourceRequestMessage" />
      </DialogContentText>
    </RemoveEntityConfirmationDialog>
  );

ResourceRequestDeleteActions.propTypes = {
  showRemoveRequestDialog: PropTypes.bool.isRequired,
  closeRemoveRequestDialog: PropTypes.func.isRequired,
  onDeleteRequestClick: PropTypes.func.isRequired
};

export default ResourceRequestDeleteActions;
