import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { MoneyValue } from '~/modules/common/components/Money';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import DeleteScriptConfirmationDialog from '~/modules/projects/project/BillPlanV2/components/DeleteScriptConfirmationDialog';
import { useFixedRange } from '~/modules/common/charts/timeline/calculations';
import { ScriptParamsEditor } from '~/modules/projects/project/ScriptParamsEditableCard/components/ScriptParamsEditor';
import { TimeAndExpenseSummary } from '~/modules/projects/project/TimeAndExpense/TimeAndExpenseSummary';
import CardExpansionPanel from '~/modules/common/components/CardExpansionPanel';
import { TimeAndExpenseTitleComponent } from '~/modules/projects/project/TimeAndExpense/TimeAndExpenseTitleComponent';
import useUpdateProjectTimeAndExpense from '~/modules/projects/project/TimeAndExpense/useUpdateProjectTimeAndExpense';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { ExpensesCardContext } from './ExpensesCardContext';
import { ErrorMessage } from './ErrorMessage';
import ExpenseScriptParamsEditor from './ExpenseScriptParamsEditor';
import {
  useExpenseScriptParamsCardHooks,
  useFormState,
  useExpenseActualsSeries,
  useEstimatedExpensesSeries,
  useBulkPutEstimatedExpenses,
  useEstimatedExpensesSummary,
  useActualsExpensesSummary,
  useExpenseCardSettings,
  usePutExpenseCardSettings,
  useExpenseScriptParamsEditableCardHook,
  useExpenseEditDialogSetting,
  useActionComponentCustomProps
} from './hooks';
import { ExpenseBillPlanTableHeader } from './ExpenseBillPlanTableHeader';
import { ExpenseCardActionComponent } from './ExpenseCardActionComponent';
import { useCardContentStyles, useCardExpansionPanelStyles } from './styles';

const getResourceLabels = (estimatedAmount, expenseTypesCount) => {
  return {
    editTitle: (
      <FormattedMessage id="expenseBillPlan.allowedExpensesAndEstimates" />
    ),
    allowBillableSwitchLabel: (
      <FormattedMessage id="expenseBillPlan.allowedBillable" />
    ),
    showActualsCheckboxLabel: (
      <FormattedMessage id="expenseBillPlan.showActuals" />
    ),
    subTitle: estimatedAmount ? (
      <>
        <FormattedMessage id="billPlan.estimatedAmount" />
        <MoneyValue money={estimatedAmount} />
      </>
    ) : null
  };
};

export const getPeriodCount = hideColumn => breakPoint => {
  switch (breakPoint) {
    case 'xs':
    case 'sm':
      return 1;
    case 'md':
      return hideColumn ? 3 : 4;
    case 'lg':
      return hideColumn ? 3 : 5;
    default:
      return 5;
  }
};

export const ExpenseScriptParamsEditableCard = ({
  onDeleteScript,
  customEditors,
  customFormatters,
  onCancelCallback,
  editable,
  additionalProps,
  readOnlyParameters,
  HeaderComponent,
  scriptDetails = {},
  scriptType,
  projectId,
  projectCurrency,
  onSave,
  recalculate,
  context = 'expenseBillPlan',
  viewSummary,
  expenseBudgetedCost,
  title,
  onViewSummaryClick,
  startDate,
  endDate,
  expenseCodes,
  canRecalculateBillingData,
  isBillingContractType,
  setSaving,
  projectPermissions
}) => {
  const { putExpenseCardSettings } = usePutExpenseCardSettings({ projectId });
  const updateProjectExpenses = useUpdateProjectTimeAndExpense({
    projectUri: projectId,
    includeExpenseCodes: !isBillingContractType
  });
  const { expenseCardSettings } = useExpenseCardSettings({ projectId });
  const { bulkPutEstimatedExpenses } = useBulkPutEstimatedExpenses(
    !isBillingContractType
  );

  const projectExpenseCodes = (expenseCodes || []).filter(
    code => code.isExpenseEntryToThisCodeAllowed
  );

  const {
    canViewExpenseCodes,
    canEditExpenseCodes,
    canEditBillingContracts,
    canViewProjectCostData
  } = projectPermissions;

  const projectDateRange = useMemo(
    () => ({
      startDate: startDate
        ? mapIsoStringtoUtcObject(startDate)
        : DateTime.utc(2000, 1, 1),
      endDate: endDate
        ? mapIsoStringtoUtcObject(endDate)
        : DateTime.utc(2100, 12, 31)
    }),
    [endDate, startDate]
  );

  const { hideColumn, setHideColumn } = useExpenseEditDialogSetting({
    expenseCardSettings
  });

  const fixedRange = useFixedRange({
    getPeriodCount: getPeriodCount(hideColumn),
    anchor: 0,
    isPast: true,
    centerOffset: true
  });

  const {
    scale,
    chartDates,
    onPrevious,
    onNext,
    dateRange,
    setDate,
    resetDate
  } = fixedRange;

  const isMobile = useIsBreakpointDown('sm');
  const { open: dialogOpen, openDialog, closeDialog } = useDialogState(false);

  const {
    expenseActualsSeries,
    loading: expenseActualsSeriesLoading
  } = useExpenseActualsSeries(projectId, projectDateRange, projectCurrency?.id);

  const {
    estimatedExpensesSeries,
    loading: estimatedExpensesSeriesLoading
  } = useEstimatedExpensesSeries(
    projectId,
    projectDateRange,
    projectCurrency?.id
  );

  const currencyId = projectCurrency?.id;

  const {
    estimatedExpensesSummary,
    loading: estimatedExpensesSummaryLoading
  } = useEstimatedExpensesSummary({
    projectId,
    dateRange: projectDateRange,
    currencyId
  });

  const {
    expenseActualsSummary,
    loading: expenseActualsSummaryLoading
  } = useActualsExpensesSummary({
    projectId,
    dateRange: projectDateRange,
    currencyId
  });

  const formik = useFormState({
    scriptDetails,
    scriptType,
    onSave,
    scale,
    projectExpenseCodes,
    expenseActualsSeries,
    estimatedExpensesSeries,
    estimatedExpensesSummary,
    expenseActualsSummary,
    bulkPutEstimatedExpenses,
    putExpenseCardSettings,
    projectId,
    viewSummary,
    expenseCardSettings,
    updateProjectExpenses,
    recalculate,
    projectDateRange,
    context,
    canEditBillingContracts,
    canRecalculateBillingData,
    isBillingContractType,
    setSaving
  });
  const {
    values,
    setFieldValue,
    resetForm,
    handleSubmit,
    errors,
    validateForm,
    isSubmitting
  } = formik;
  const {
    displayText,
    estimatedAmount,
    id: planId,
    hasHeaderComponent,
    showActuals,
    allowBillable,
    scripts,
    parameters
  } = values;

  const {
    onDeleteScriptHandler,
    onShowActualsChange,
    onCancel,
    onDeleteScriptConfirmClick
  } = useExpenseScriptParamsEditableCardHook({
    openDialog,
    resetForm,
    onCancelCallback,
    setFieldValue,
    onDeleteScript,
    planId,
    bulkPutEstimatedExpenses,
    projectId,
    updateProjectExpenses,
    resetDate,
    setHideColumn,
    expenseCardSettings
  });

  const classes = useCardContentStyles();
  const resourceLabels = useMemo(
    () =>
      getResourceLabels(estimatedAmount, {
        count: projectExpenseCodes?.length || 0
      }),
    [estimatedAmount, projectExpenseCodes]
  );

  const actionLabel = useMemo(
    () => ({
      remove: { scriptType }
    }),
    [scriptType]
  );

  const titleComponentProps = useMemo(
    () => ({
      expenseTypesCount: { count: values.projectExpenseCodes?.length || 0 }
    }),
    [values.projectExpenseCodes]
  );

  const cardExpansionPanelStyles = useCardExpansionPanelStyles();

  const loading =
    expenseActualsSeriesLoading ||
    estimatedExpensesSeriesLoading ||
    estimatedExpensesSummaryLoading ||
    expenseActualsSummaryLoading;

  const isEditable = useMemo(
    () => (viewSummary ? false : canEditExpenseCodes ? editable : false),
    [canEditExpenseCodes, editable, viewSummary]
  );

  const {
    availableExpenseCodes,
    onAddExpenseTypeClick,
    anchorEl,
    onMenuClose,
    onAddRowHandler,
    updatedParameters,
    onDeleteRowHandler
  } = useExpenseScriptParamsCardHooks({
    scripts,
    expenseCodes,
    setFieldValue,
    editable,
    parameters,
    readOnlyParameters,
    monthlyExpenses: values.monthlyExpenses,
    projectExpenseCodes: values.projectExpenseCodes,
    allowBillable,
    viewSummary
  });

  const actionComponentCustomProps = useActionComponentCustomProps({
    canEditExpenseCodes,
    context,
    canEditBillingContracts,
    displayText,
    availableExpenseCodes,
    onAddExpenseTypeClick,
    editable,
    viewSummary
  });

  const dialogActionClasses = useMemo(
    () => ({
      dialogActions: classes.dialogActions,
      spacer: classes.spacer
    }),
    [classes.dialogActions, classes.spacer]
  );

  const value = useMemo(
    () => ({
      editable,
      readOnlyParameters,
      viewSummary,
      values
    }),
    [editable, readOnlyParameters, values, viewSummary]
  );

  return (
    <>
      <ExpensesCardContext.Provider value={value}>
        <EditableCard
          editable={isEditable}
          className={classes.card}
          editContentClassName={classes.root}
          dialogActionClasses={dialogActionClasses}
          classes={useMemo(
            () => ({
              card: classes.card
            }),
            [classes.card]
          )}
          edit={values.isAddMode || viewSummary}
          maxWidth={
            values.monthlyExpenses && values.monthlyExpenses.length
              ? 'xl'
              : 'sm'
          }
          dataQeId={displayText}
          fullScreen={isMobile}
          ariaLabelKey={
            scriptType === 'Billing Type'
              ? 'expenseBillPlan.expenseBillPlanCardDialog'
              : 'expenseBillPlan.expensesCardDialog'
          }
        >
          {context === 'expenseBillPlan' &&
            !values.isAddMode &&
            values.scripts &&
            values.scripts.length > 0 && (
              <ReadOnly
                title={displayText}
                subTitle={resourceLabels.subTitle}
                expandable
              >
                <ReadOnlyContent>
                  <ScriptParamsEditor
                    editable={false}
                    values={values}
                    readOnlyParameters={readOnlyParameters}
                    customFormatters={customFormatters}
                    isMobile={isMobile}
                    projectId={projectId}
                    expenseCodes={expenseCodes}
                    {...additionalProps}
                  />
                </ReadOnlyContent>
              </ReadOnly>
            )}
          {context === 'timeAndExpense' && !viewSummary && (
            <ReadOnly title={title} expandable={false}>
              <ReadOnlyContent>
                <TimeAndExpenseSummary
                  expenseBudgetedCost={expenseBudgetedCost}
                  estimatedExpensesSummary={estimatedExpensesSummary}
                  expenseActualsSummary={expenseActualsSummary}
                  canViewCostData={canViewProjectCostData}
                  canViewExpenseCodes={canViewExpenseCodes}
                  loading={
                    expenseActualsSummaryLoading ||
                    estimatedExpensesSummaryLoading
                  }
                />
                {values.monthlyExpenses && values.monthlyExpenses.length > 0 ? (
                  <CardExpansionPanel
                    TitleComponent={TimeAndExpenseTitleComponent}
                    titleComponentProps={titleComponentProps}
                    classes={cardExpansionPanelStyles}
                    defaultExpanded={false}
                    dataQeId="AllowedExpenseTypes"
                  >
                    <ExpenseScriptParamsEditor
                      editable={false}
                      values={values}
                      showActuals={showActuals}
                      allowBillable={allowBillable}
                      setFieldValue={setFieldValue}
                      customEditors={customEditors}
                      HeaderComponent={
                        hasHeaderComponent ? HeaderComponent : null
                      }
                      errors={errors}
                      context={context}
                      isMobile={isMobile}
                      slug={scriptDetails.slug}
                      projectId={projectId}
                      chartDates={chartDates}
                      expenseCodes={expenseCodes}
                      projectCurrency={projectCurrency}
                      dateRange={dateRange}
                      projectDateRange={projectDateRange}
                      hasStartDate={Boolean(startDate)}
                      hasEndDate={Boolean(endDate)}
                      loading={loading}
                      anchorEl={anchorEl}
                      onMenuClose={onMenuClose}
                      onAddRowHandler={onAddRowHandler}
                      availableExpenseCodes={availableExpenseCodes}
                      updatedParameters={updatedParameters}
                      onDeleteRowHandler={onDeleteRowHandler}
                      projectPermissions={projectPermissions}
                      {...additionalProps}
                    />
                  </CardExpansionPanel>
                ) : null}
                {values.monthlyExpenses && values.monthlyExpenses.length > 0 ? (
                  <Button
                    color="primary"
                    onClick={onViewSummaryClick}
                    className={classes.viewSummaryButton}
                  >
                    <FormattedMessage id="projectTimeAndExpenseCard.viewSummary" />
                  </Button>
                ) : null}
              </ReadOnlyContent>
            </ReadOnly>
          )}
          <Edit
            saveable={canViewExpenseCodes && !isSubmitting}
            actionComponentCustomProps={actionComponentCustomProps}
            onSave={handleSubmit}
            validateForm={validateForm}
            onCancel={onCancel}
            onRemove={onDeleteScriptHandler}
            actionLabel={actionLabel}
            ActionComponent={ExpenseCardActionComponent}
          >
            <EditTitle>{resourceLabels.editTitle}</EditTitle>
            <EditContent className={classes.content}>
              {canViewExpenseCodes &&
              values.monthlyExpenses &&
              values.monthlyExpenses.length ? (
                <ExpenseBillPlanTableHeader
                  showActuals={showActuals}
                  onPrevious={onPrevious}
                  onNext={onNext}
                  scale={scale}
                  dateRange={dateRange}
                  setDate={setDate}
                  viewSummary={viewSummary}
                  resourceLabels={resourceLabels}
                  onShowActualsChange={onShowActualsChange}
                />
              ) : null}
              {canViewExpenseCodes ? (
                <ExpenseScriptParamsEditor
                  editable={editable}
                  viewSummary={viewSummary}
                  values={values}
                  showActuals={showActuals}
                  allowBillable={allowBillable}
                  readOnlyParameters={readOnlyParameters}
                  setFieldValue={setFieldValue}
                  customEditors={customEditors}
                  customFormatters={customFormatters}
                  HeaderComponent={hasHeaderComponent ? HeaderComponent : null}
                  errors={errors}
                  context={context}
                  isMobile={isMobile}
                  {...additionalProps}
                  slug={scriptDetails.slug}
                  projectId={projectId}
                  chartDates={chartDates}
                  expenseCodes={expenseCodes}
                  projectCurrency={projectCurrency}
                  dateRange={dateRange}
                  projectDateRange={projectDateRange}
                  hasStartDate={Boolean(startDate)}
                  hasEndDate={Boolean(endDate)}
                  loading={loading}
                  anchorEl={anchorEl}
                  onMenuClose={onMenuClose}
                  onAddRowHandler={onAddRowHandler}
                  updatedParameters={updatedParameters}
                  onDeleteRowHandler={onDeleteRowHandler}
                  availableExpenseCodes={availableExpenseCodes}
                  projectPermissions={projectPermissions}
                  showDeleteRow={
                    actionComponentCustomProps.showAddExpenseTypeButton
                  }
                />
              ) : (
                <ErrorMessage
                  errorKey={
                    context === 'timeAndExpense'
                      ? 'billPlan.noPermissionToViewExpenseCodes'
                      : 'billPlan.noPermissionToEditBillPlan'
                  }
                />
              )}
            </EditContent>
          </Edit>
        </EditableCard>
        {dialogOpen && (
          <DeleteScriptConfirmationDialog
            openDialog={dialogOpen}
            onDialogClose={closeDialog}
            onConformClick={onDeleteScriptConfirmClick}
            scriptType={displayText}
          />
        )}
      </ExpensesCardContext.Provider>
    </>
  );
};

ExpenseScriptParamsEditableCard.propTypes = {
  onDeleteScript: PropTypes.func,
  customEditors: PropTypes.object,
  customFormatters: PropTypes.object,
  onCancelCallback: PropTypes.func,
  editable: PropTypes.bool,
  additionalProps: PropTypes.object,
  readOnlyParameters: PropTypes.array,
  HeaderComponent: PropTypes.func,
  scriptDetails: PropTypes.object,
  scriptType: PropTypes.any,
  projectId: PropTypes.string,
  projectCurrency: PropTypes.object,
  onSave: PropTypes.func,
  recalculate: PropTypes.func,
  context: PropTypes.oneOf(['expenseBillPlan', 'timeAndExpense']),
  viewSummary: PropTypes.bool,
  expenseBudgetedCost: PropTypes.object,
  onViewSummaryClick: PropTypes.func,
  title: PropTypes.any,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  expenseCodes: PropTypes.array,
  canRecalculateBillingData: PropTypes.bool,
  isBillingContractType: PropTypes.bool,
  setSaving: PropTypes.func,
  projectPermissions: PropTypes.object
};

export default ExpenseScriptParamsEditableCard;
