import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl as withIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  primaryMessage: {
    color: theme.palette.text.primary
  },
  secondaryMessage: {
    lineHeight: 1.2
  },
  details: {
    ...theme.typography.caption
  },
  confirmButton: {
    color: theme.palette.secondary.main
  }
}));

const resourceKeys = {
  header: 'billPlan.deletePlan',
  actionButton: 'billPlan.removeBillingType',
  cancelButton: 'billPlan.cancel',
  conformationMessage: 'billPlan.deleteScriptConformationMessage'
};

export const DeleteScriptConfirmationDialog = ({
  intl,
  openDialog,
  onDialogClose,
  onConformClick,
  scriptType
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openDialog}
      onClose={onDialogClose}
      aria-labelledby="billPlan-deleteScript-conformation-dialog"
      aria-describedby="billPlan-deleteScript-conformation-dialog"
    >
      <DialogTitle id="billPlan.deleteScript.conformationDialog">
        {intl.formatMessage({ id: resourceKeys.header }, { scriptType })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.primaryMessage}>
          {intl.formatMessage(
            { id: resourceKeys.conformationMessage },
            { scriptType }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-qe-id="CancelDialogButton" onClick={onDialogClose}>
          <FormattedMessage id={resourceKeys.cancelButton} />
        </Button>
        <Button
          data-qe-id="ConfirmDialogButton"
          onClick={onConformClick}
          className={classes.confirmButton}
        >
          <FormattedMessage id={resourceKeys.actionButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteScriptConfirmationDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  openDialog: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func,
  onConformClick: PropTypes.func,
  scriptType: PropTypes.string
};

export default withIntl(DeleteScriptConfirmationDialog);
