import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useDialogState } from '~/modules/common/hooks';
import { AddTaskDialog } from '~/modules/projects/tasks/components';
import { useCreateTask } from '../hooks/useCreateTask';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  addMilestone: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(0.5)
  }
}));

export const AddMilestoneTaskLink = ({
  projectDetails,
  setMilestoneTaskCreated,
  setTaskAddedCount
}) => {
  const classes = useStyles();
  const {
    startDate,
    endDate,
    projectId,
    projectName,
    projectSlug
  } = projectDetails;
  const {
    open: isAddTaskDialogOpen,
    closeDialog: closeAddTaskDialog,
    openDialog: openAddTaskDialog
  } = useDialogState(false);

  const { createTask } = useCreateTask({
    setMilestoneTaskCreated,
    setTaskAddedCount
  });

  return (
    <span>
      <b>&nbsp; &ndash;</b>
      <Button className={classes.addMilestone} onClick={openAddTaskDialog}>
        <FormattedMessage id="rateCard.addMilestone" />
      </Button>
      {isAddTaskDialogOpen && (
        <AddTaskDialog
          open={isAddTaskDialogOpen}
          onClose={closeAddTaskDialog}
          initStartDate={startDate}
          initEndDate={endDate}
          parentUri={projectId}
          createTask={createTask}
          fullScreen={false}
          isSubtask={false}
          projectSlug={projectSlug}
          parentName={projectName}
          isMilestone
        />
      )}
    </span>
  );
};

AddMilestoneTaskLink.propTypes = {
  projectDetails: PropTypes.object,
  setMilestoneTaskCreated: PropTypes.func,
  setTaskAddedCount: PropTypes.func
};

export default AddMilestoneTaskLink;
