import { mapEnabledFieldsToVariables } from '~/modules/common/util';
import { getColumns } from '../ResourceUserList/resourceColumns';

const columnVarColumnMap = me => {
  const columns = getColumns([], [], me);

  return {
    includeEmployeeId: columns.employeeId,
    includeStartDate: columns.startDate,
    includeEndDate: columns.endDate,
    includeDepartment: columns.department || false,
    includeCurrentCostCenter: columns.currentCostCenter || false,
    includeCurrentServiceCenter: columns.currentServiceCenter || false,
    includeCurrentDepartment: columns.currentDepartment || false,
    includeCurrentEmployeeType: columns.currentEmployeeType || false,
    includeCurrentDivision: columns.currentDivision || false,
    includeCurrentLocation: columns.currentLocation || false,
    includeEmployeeType: columns.employeeType || false,
    includeRoles: columns.roles || false,
    includeSupervisorAssignment: columns.supervisorAssignment,
    includeResourcePools: columns.resourcePools
  };
};

export const columnArguments = `
$includeEmployeeId: Boolean!
$includeStartDate: Boolean!
$includeEndDate: Boolean!
$includeDepartment: Boolean!
$includeCurrentCostCenter: Boolean!
$includeCurrentServiceCenter: Boolean!
$includeCurrentDivision: Boolean!
$includeCurrentLocation: Boolean!
$includeCurrentEmployeeType: Boolean!
$includeCurrentDepartment: Boolean!
$includeRoles: Boolean!
$includeEmployeeType: Boolean!
$includeSupervisorAssignment: Boolean!
$includeTags: Boolean!
$includeResourcePools: Boolean!
  `;

export const enabledFieldsToVariables = (enabledFields, me) =>
  mapEnabledFieldsToVariables(enabledFields, columnVarColumnMap(me));
