import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';

export const getDefaultCustomPopper = (minWidth = 500, maxWidth = 500) => ({
  style,
  anchorEl,
  className,
  open,
  role,
  ...restProps
}) => (
  <CustomPopper
    style={style}
    maxWidth={maxWidth}
    minWidth={minWidth}
    anchorEl={anchorEl}
    className={className}
    open={open}
    role={role}
    {...restProps}
  />
);

const CustomPopper = ({
  style,
  minWidth = 200,
  maxWidth = 250,
  placement = 'bottom-start',
  anchorEl,
  className,
  open,
  role,
  ...restProps
}) => {
  const customStyles = useMemo(
    () => ({
      ...style,
      width: minWidth,
      minWidth,
      maxWidth
    }),
    [minWidth, maxWidth, style]
  );

  return (
    <Popper
      anchorEl={anchorEl}
      className={className}
      open={open}
      role={role}
      placement={placement}
      style={customStyles}
      {...restProps}
    />
  );
};

CustomPopper.propTypes = {
  style: PropTypes.object,
  placement: PropTypes.string,
  width: PropTypes.number,
  anchorEl: PropTypes.object,
  className: PropTypes.string,
  open: PropTypes.bool,
  role: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.number
};

export default CustomPopper;
