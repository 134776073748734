import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { billQuery } from '~/modules/billing-invoicing/bill/hooks/useBillDetails';
import {
  billAssociatedCreditMemoQuery,
  PAGE_SIZE
} from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/useAssociatedCreditMemos';
import billingTransactionRefetchQueries from '~/modules/common/components/RefetchQueries/BillingTransactionRefetchQueries';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const PUT_CREDIT_MEMO = gql`
  mutation putCreditMemo($input: CreditMemoInput2!) {
    putCreditMemo: putCreditMemo3(input: $input) {
      creditMemoUri
    }
  }
`;

export const usePutCreditMemo = () => {
  const [putCreditMemo] = useMutation(PUT_CREDIT_MEMO);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });
  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : [
        'getDraftTotals',
        'getOverdueBillSummary',
        'getOutstandingBillSummary',
        'getIssuedBillSummary',
        'getUnallocatedBillSummary'
      ];

  return {
    putCreditMemo: (CreditMemoDetails, billId) => {
      const refetchQueries = billId
        ? [
            ...billingTransactionRefetchQueries,
            ...queries,
            'availablePaymentsForBill',
            {
              query: billQuery,
              variables: {
                id: billId,
                isPsaPswatBillingDraftSubStatusEnabled
              }
            },
            {
              query: billAssociatedCreditMemoQuery,
              variables: {
                page: 1,
                pageSize: PAGE_SIZE,
                id: billId
              }
            }
          ]
        : ['getPageOfBillingTransactions', 'getBillingTransactionTotals'];

      return putCreditMemo({
        variables: {
          input: CreditMemoDetails
        },
        refetchQueries,
        awaitRefetchQueries: true
      });
    }
  };
};
export default usePutCreditMemo;
