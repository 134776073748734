import React, { useCallback, useMemo } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/CloseSharp';
import { PropTypes } from 'prop-types';

const messagePosition = { vertical: 'bottom', horizontal: 'left' };

export const ResourceAllocationChangeSnackBar = ({
  snackBarState,
  setSnackBarState
}) => {
  const closeSnackBar = useCallback(
    () => setSnackBarState(state => ({ ...state, open: false })),
    [setSnackBarState]
  );

  const closeSnackbarButton = useMemo(
    () => (
      <IconButton onClick={closeSnackBar} color="inherit">
        <CloseIcon fontSize="small" />
      </IconButton>
    ),
    [closeSnackBar]
  );

  return (
    <Snackbar
      open={Boolean(snackBarState.open)}
      autoHideDuration={5000}
      onClose={closeSnackBar}
      TransitionComponent={Slide}
      anchorOrigin={messagePosition}
      message={snackBarState.message}
      action={closeSnackbarButton}
    />
  );
};

export default ResourceAllocationChangeSnackBar;

ResourceAllocationChangeSnackBar.propTypes = {
  snackBarState: PropTypes.object.isRequired,
  setSnackBarState: PropTypes.func.isRequired
};
