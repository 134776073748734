import React, { useCallback, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import {
  buildCustomFieldFormValues,
  customFieldValidationSchema,
  customFieldValueToInput
} from '~/modules/customFields/utils';
import EditDialog from '~/modules/common/components/EditDialog';
import { EditCustomFieldDialogForm } from '~/modules/customFields/components';
import useUpdateUserCustomFields from './useUpdateUserCustomFields';

const title = <FormattedMessage id="resourceManagement.additionalInfo" />;

const FormErrorContext = createContext();

export const EditAdditionalInfoDialog = ({
  open,
  onClose,
  details,
  customFieldDefinitions
}) => {
  const intl = useIntl();
  const { id, customFieldValues } = details;

  const initialValues = useMemo(
    () => ({
      id,
      customFieldValues: buildCustomFieldFormValues(customFieldValues || [])
    }),
    [customFieldValues, id]
  );

  const validationSchema = customFieldValidationSchema({
    customFieldDefinitions,
    intl
  });

  const mapValuesOnSubmit = useCallback(
    values => ({
      userUri: values.id,
      customFieldValues: Object.values(values.customFieldValues).map(
        customFieldValueToInput
      )
    }),
    []
  );

  const { onSubmit, error, clearError } = useUpdateUserCustomFields({
    mapValuesOnSubmit,
    onClose
  });

  const errorContextValue = useMemo(() => ({ error, clearError }), [
    error,
    clearError
  ]);

  return (
    <FormErrorContext.Provider value={errorContextValue}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onReset={clearError}
        enableReinitialize
      >
        <EditDialog open={open} onClose={onClose} title={title}>
          <EditCustomFieldDialogForm />
        </EditDialog>
      </Formik>
    </FormErrorContext.Provider>
  );
};

EditAdditionalInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  customFieldDefinitions: PropTypes.array.isRequired
};

export default EditAdditionalInfoDialog;
