import React from 'react';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import { PeriodSelector, PeriodDropdown } from '~/modules/common/components';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useResourceOverviewToolbarContext } from '../../hooks';

const usePeriodDropdownStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 2, 0, 0)
  }
}));

const useStyles = makeStyles(() => ({
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  }
}));

const useStickyHeaderStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  toolbar: {
    backgroundColor: grey[50]
  }
}));

const usePeriodSelectorStyles = makeStyles(theme => ({
  date: {
    [theme.breakpoints.down('xs')]: {
      display: 'inline-flex'
    }
  }
}));

export const ResourcingOverviewToolbar = () => {
  const {
    dateRange,
    periodScale,
    setScale,
    setNextPeriod,
    setPreviousPeriod
  } = useResourceOverviewToolbarContext();

  const classes = useStyles();
  const stickyHeaderStyles = useStickyHeaderStyles();
  const periodSelectorClasses = usePeriodSelectorStyles();
  const periodClasses = usePeriodDropdownStyles();

  return (
    <StickyHeader level={2} classes={stickyHeaderStyles}>
      <PeriodSelector
        classes={periodSelectorClasses}
        onPreviousClick={setPreviousPeriod}
        onNextClick={setNextPeriod}
        dateRange={dateRange}
      />
      <div className={classes.spacer} />
      <PeriodDropdown
        classes={periodClasses}
        value={periodScale}
        onChange={setScale}
        showYears={false}
      />
    </StickyHeader>
  );
};

export default ResourcingOverviewToolbar;
