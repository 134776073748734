import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const CLIENT_REPRESENTATIVE_DROPDOWN_QUERY = gql`
  query ClientRepresentativeDropdownSearch($clientUri: String!) {
    getClientRepresentatives(clientUri: $clientUri) {
      id
      displayText
    }
  }
`;

const useClientRepresentativeQuery = clientUri =>
  useQuery(CLIENT_REPRESENTATIVE_DROPDOWN_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      clientUri
    },
    skip: Boolean(!clientUri)
  });

export default useClientRepresentativeQuery;
