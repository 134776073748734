import React, { useMemo } from 'react';
import compose from '~/util/compose';

export const withSearchFacetProviders = BaseComponent => ({
  facets,
  searchCriteria,
  ...rest
}) => {
  const { providers, contextualFacets } = useMemo(
    () =>
      facets.reduce(
        (acc, { tag, makeCriteriaProvider, ...facetRest }) => {
          if (!makeCriteriaProvider) {
            acc.contextualFacets.push({
              tag,
              context: null,
              ...facetRest
            });

            return acc;
          }

          const { criterions } = searchCriteria || {};

          const context = React.createContext({
            options: [],
            matchOption: () => {},
            selected: criterions && criterions[tag] ? criterions[tag] : [],
            setSelected: () => {}
          });

          const withProvider = makeCriteriaProvider(tag, context);

          acc.providers.push(withProvider);
          acc.contextualFacets.push({
            tag,
            context,
            ...facetRest
          });

          return acc;
        },
        { providers: [], contextualFacets: [] }
      ),
    [facets, searchCriteria]
  );

  const ComponentWithFacetProviders = useMemo(
    () => compose(...providers)(BaseComponent),
    [providers]
  );

  const props = useMemo(
    () => ({
      facets: contextualFacets,
      searchCriteria,
      ...rest
    }),
    [contextualFacets, searchCriteria, rest]
  );

  return <ComponentWithFacetProviders {...props} />;
};

export default withSearchFacetProviders;
