import dialogContentForProgram from './program';
import dialogContentForClient from './client';
import dialogContentForProject from './project';
import dialogContentForVendor from './vendor';

export default {
  PROGRAM: dialogContentForProgram,
  CLIENT: dialogContentForClient,
  PROJECT: dialogContentForProject,
  VENDOR: dialogContentForVendor
};
