import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  CreditMemoIcon,
  PaymentIcon,
  BillIcon
} from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  icon: {
    margin: theme.spacing(0, 0.5, 0, 0)
  }
}));

export const TypeIcon = ({ billingTransactionTypeId, ...rest }) => {
  switch (billingTransactionTypeId) {
    case 'BILL':
      return <BillIcon {...rest} />;
    case 'CREDITMEMO':
      return <CreditMemoIcon {...rest} />;
    case 'PAYMENT':
      return <PaymentIcon {...rest} />;
    default:
      return null;
  }
};

TypeIcon.propTypes = {
  billingTransactionTypeId: PropTypes.string
};

const BillingTransactionTypeIcon = ({ billingTransactionTypeId }) => {
  const classes = useStyles();

  return (
    <TypeIcon
      billingTransactionTypeId={billingTransactionTypeId}
      className={classes.icon}
    />
  );
};

BillingTransactionTypeIcon.propTypes = {
  billingTransactionTypeId: PropTypes.string.isRequired
};

export default BillingTransactionTypeIcon;
