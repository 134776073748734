import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '~/modules/common/components';

export const BillPlanActionComponent = ({
  onCancel,
  onSave,
  validateForm,
  onRemove,
  actionLabel,
  saveable,
  isSaving,
  actionComponentCustomProps
}) => {
  const { showRemoveAction } = actionComponentCustomProps || {};

  const handleSave = useCallback(async () => {
    if (validateForm) {
      const errors = await validateForm();

      if (Object.keys(errors).length) return;
    }
    onSave();
  }, [onSave, validateForm]);

  return (
    <>
      <Grid item xs={12} container spacing={0}>
        <Grid item xs={6} container justifyContent="flex-start" spacing={0}>
          {showRemoveAction && (
            <Button color="secondary" onClick={onRemove}>
              <FormattedMessage
                id="scriptParamEditor.removeScript"
                values={actionLabel.remove}
              />
            </Button>
          )}
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end" spacing={0}>
          <Button onClick={onCancel}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={!saveable || isSaving}
            isLoading={isSaving}
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};
BillPlanActionComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  actionLabel: PropTypes.object.isRequired,
  saveable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  validateForm: PropTypes.func,
  actionComponentCustomProps: PropTypes.object
};

export default BillPlanActionComponent;
