import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[500]
  }
}));

export const NoValue = forwardRef(
  ({ classes: classesOverride, className, ...otherProps }, ref) => {
    const classes = useStyles({ classes: classesOverride });

    return (
      <span {...otherProps} className={classNames(classes.root, className)}>
        &ndash;
      </span>
    );
  }
);

NoValue.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string
};

export default NoValue;
