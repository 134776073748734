import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';

import ProgramItem from './ProgramItem';
import { useSearchablePrograms } from './enhancers/useSearchablePrograms';

const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : option.key === NONE_OPTION_KEY ? (
    <NoneDropdownItem value={option.displayText} />
  ) : (
    <ProgramItem value={option.displayText} />
  );

export const programLabel = <FormattedMessage id="search.program" />;

export const ProgramFacetDetails = ({ selected, setSelected }) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const { programs, loading, hasMore } = useSearchablePrograms(searchTerm);

  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);
  const programOptions =
    !loading && programs.length
      ? prependNoneItem(
          formatMessage({
            id: 'program.noProgram'
          }),
          programs
        )
      : [];

  const classes = useStyles();

  return (
    <FacetDetailField label={formatMessage({ id: 'search.program' })}>
      <SimpleAutocomplete
        fullWidth
        hiddenLabel
        classes={classes}
        options={programOptions}
        multiple
        noOptionsText={formatMessage({ id: 'program.noProgram' })}
        loading={loading}
        inputLabel={formatMessage({ id: 'search.program' })}
        label={formatMessage({ id: 'search.program' })}
        optionPropText="label"
        getOptionDisabled={getOptionDisabled}
        value={selected}
        onInputChange={onInputChange}
        onChange={onValueChange}
        variant="standard"
        renderOption={renderOption}
        hasMore={hasMore}
        optionTypeText={formatMessage({
          id: 'moreOptions.programs'
        })}
      />
    </FacetDetailField>
  );
};

ProgramFacetDetails.propTypes = {
  setSelected: PropTypes.func,
  selected: PropTypes.array.isRequired
};

export default ProgramFacetDetails;
