const MAX_DEPTH = 20;

export const recursivelyStripTypeName = (object, depth = 0) =>
  Object.keys(object).reduce((acc, key) => {
    if (Array.isArray(object[key])) {
      acc[key] =
        depth < MAX_DEPTH
          ? object[key].map(v => recursivelyStripTypeName(v, depth + 1))
          : object[key];
    } else if (
      object[key] &&
      typeof object[key] === 'object' &&
      depth < MAX_DEPTH
    ) {
      acc[key] = recursivelyStripTypeName(object[key], depth + 1);
    } else if (key !== '__typename') {
      acc[key] = object[key];
    }

    return acc;
  }, {});

export const createMoneyValue = money =>
  money
    ? {
        __typename: 'Money',
        amount: money.amount,
        currency: money.currency
          ? {
              __typename: 'Currency',
              id: money.currency.id,
              displayText: money.currency.displayText
            }
          : null
      }
    : null;
