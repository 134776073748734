import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_USERS_AVAILABILITY_SERIES_TOTALS_QUERY = gql`
  query Eager_GetResourceUserAvailabilitySeriesTotals(
    $dateRange: DateRangeInput!
    $periodResolution: PeriodResolutionOption!
    $groupBy: ResourceUserAvailabilityGroupType!
    $filter: ResourceUserAvailabilitySeriesFilter
  ) {
    resourceUserAvailabilitySeriesTotals(
      dateRange: $dateRange
      periodResolution: $periodResolution
      groupBy: $groupBy
      filter: $filter
    ) {
      count
      periods {
        dateRange {
          startDate
          endDate
        }
        availableDuration {
          hours
          minutes
          seconds
        }
        scheduledDuration {
          hours
          minutes
          seconds
        }
        timeOffDuration {
          hours
          minutes
          seconds
        }
        holidayDuration {
          hours
          minutes
          seconds
        }
        overAllocatedDuration {
          hours
          minutes
          seconds
        }
        allocatedDuration {
          hours
          minutes
          seconds
        }
      }
    }
  }
`;

const useResourceUserAvailabilitySeriesTotals = ({
  groupBy,
  dateRange,
  periodResolution,
  filter
}) => {
  const variables = {
    dateRange,
    periodResolution,
    groupBy,
    filter
  };

  const { loading, error, data } = useQuery(
    RESOURCE_USERS_AVAILABILITY_SERIES_TOTALS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables
    }
  );

  return {
    loading,
    resourceUserAvailabilitySeriesTotals:
      data && data.resourceUserAvailabilitySeriesTotals,
    error
  };
};

export default useResourceUserAvailabilitySeriesTotals;
