import { gql } from 'graphql-tag';
import { CustomFieldValueFragmentDoc } from '~/types';

const specificResourceFragment = gql`
  fragment SpecificResourceFragment on ResourceDetails {
    id
    uri
    slug
    displayText
    firstName
    lastName
    employeeId
    isEnabled
    startDate
    endDate
    email
    supervisorAssignment {
      supervisor {
        uri
        displayText
      }
      effectiveDate
    }
    department
    resourceSkill {
      category {
        id
        uri
        displayText
      }
      skillAssignments {
        id
        uri
        displayText
        certificate
        skillLevel {
          id
          uri
          displayText
          rating
        }
        skillCustomMetadata {
          uri
          displayText
          type
          text
          date
          number
        }
      }
    }
    roleScheduleDetails {
      effectiveDate
      projectRoles {
        projectRole {
          uri
          name
        }
        isPrimary
      }
    }
    roleDetails {
      projectRole {
        uri
        name
      }
      isPrimary
    }
    locale {
      id
      language {
        displayText
        id
      }
      timeZone {
        displayText
        id
      }
    }
    supervisorAssignments {
      supervisor {
        uri
        displayText
      }
      effectiveDate
    }
    costCenter {
      effectiveDate
      displayText
      uri
      id
    }
    serviceCenter {
      effectiveDate
      displayText
      uri
      id
    }
    location {
      effectiveDate
      displayText
      uri
      id
    }
    departmentGroup {
      effectiveDate
      displayText
      uri
      id
    }
    employeeTypeGroup {
      effectiveDate
      displayText
      uri
      id
    }
    division {
      effectiveDate
      displayText
      uri
      id
    }
    customFieldValues {
      ...CustomFieldValue
    }
    permittedActions
  }
  ${CustomFieldValueFragmentDoc}
`;

export default specificResourceFragment;
