import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  onClientChange,
  onDescriptionChange,
  onDisplayIdChange,
  onPONumberChange,
  onNotesForCustomerChange,
  onInternalNotesChange,
  onShowCommentsChange
} from '~/modules//billing-invoicing/common/enhancers';
import {
  mapRepliconDateToUtcObject,
  toRepliconDate,
  mapMomentDateToRepliconDate
} from '~/modules/common/dates/convert';
import { formattedBillingAddress } from '~/modules/billing-invoicing/bill/formattedBillingAddress';
import { CLIENT_DEFAULT_DATA_QUERY } from '../graphql';
import { mapToLineItemInput, mapAmountToLineItemInput } from './util';

export const setDueDate = ({ setFieldValue, issueDate, paymentTerms }) => {
  if (issueDate) {
    const dueDate = mapRepliconDateToUtcObject(issueDate).plus({
      days: paymentTerms
    });

    setFieldValue('dueDate', toRepliconDate(dueDate));
  }
};
export const setPeriod = ({ setFieldValue, dateRange }) => {
  if (dateRange) {
    const period = {
      startDate: dateRange.startDate
        ? mapMomentDateToRepliconDate(dateRange.startDate)
        : null,
      endDate: dateRange.endDate
        ? mapMomentDateToRepliconDate(dateRange.endDate)
        : null
    };

    setFieldValue('period', period);
  }
};
export const setPaymentTerms = ({ setFieldValue, issueDate, dueDate }) => {
  if (issueDate && dueDate) {
    const paymentTerms = mapRepliconDateToUtcObject(dueDate)
      .diff(mapRepliconDateToUtcObject(issueDate), 'days')
      .toObject().days;

    if (paymentTerms >= 0) setFieldValue('paymentTerms', paymentTerms);
  }
};

export const onIssueDateChange = ({
  setFieldValue,
  values: { paymentTerms = 0 }
}) => async issueDate => {
  await setDueDate({ setFieldValue, issueDate, paymentTerms });
  setFieldValue('issueDate', issueDate);
};

export const onPeriodChange = ({ setFieldValue }) => dateRange => {
  setPeriod({ setFieldValue, dateRange });
};
export const onBillCurrencyChange = ({ setFieldValue, values }) => value => {
  const { id } = values;
  const currency = { ...value, symbol: value.displayText };

  setFieldValue('billCurrency', currency);
  if (id) return;

  const updatedStandardLineItems = values.standardLineItems.map(lineItem => ({
    ...lineItem,
    amount: { amount: lineItem.amount.amount, currency }
  }));
  const updatedAdhocLineItems = values.adhocLineItems.map(lineItem => ({
    ...lineItem,
    amount: { amount: lineItem.amount.amount, currency }
  }));

  setFieldValue('standardLineItems', updatedStandardLineItems);
  setFieldValue('adhocLineItems', updatedAdhocLineItems);
};

export const onBillingAddressChange = ({
  setFieldValue,
  values
}) => address => {
  if (address.target) {
    setFieldValue('billingAddress', address?.target?.value);
  } else {
    const billingAddress = formattedBillingAddress(address);

    setFieldValue('billingAddress', billingAddress);
  }
};

export const onPaymentTermsChange = ({
  setFieldValue,
  values: { issueDate }
}) => paymentTerms => {
  setDueDate({ setFieldValue, issueDate, paymentTerms });
  setFieldValue('paymentTerms', paymentTerms);
};

export const onInvoiceTemplateChange = ({
  setFieldValue,
  values
}) => invoiceTemplate => {
  setFieldValue('invoiceTemplate', invoiceTemplate);
};

export const onTaxProfileChange = ({ setFieldValue }) => taxProfile =>
  setFieldValue('taxProfile', taxProfile);

export const onDueDateChange = ({
  setFieldValue,
  values: { issueDate }
}) => dueDate => {
  setPaymentTerms({ setFieldValue, issueDate, dueDate });
  setFieldValue('dueDate', dueDate);
};

export const onLineItemAmountChange = ({ setFieldValue, values }) => (
  e,
  record
) => {
  const { id, key } = record;
  const isAdhoc = values.adhocLineItems?.find(
    lineItem => id && lineItem.id === id
  );
  const sectionLineItems = isAdhoc
    ? values.adhocLineItems
    : values.standardLineItems;
  const newValue = e.target.value || 0;
  const updatedLineItems = sectionLineItems.map(lineItem =>
    (id && lineItem.id === id) || (key && lineItem.key === key)
      ? {
          ...lineItem,
          amount: { ...lineItem.amount, amount: newValue },
          isDirty: true
        }
      : lineItem
  );

  setFieldValue(
    isAdhoc ? 'adhocLineItems' : 'standardLineItems',
    updatedLineItems
  );
};

export const onLineItemAmountBlur = ({
  setFieldValue,
  putLineItemForBill,
  values
}) => (e, record) => {
  const { id, key } = record;
  const isAdhoc = values.adhocLineItems?.some(
    lineItem => id && lineItem.id === id
  );
  const sectionLineItems = isAdhoc
    ? values.adhocLineItems
    : values.standardLineItems;
  const { id: billId, summarizeColumnOptions } = values;
  const currentItem = sectionLineItems.filter(
    lineItem => id && lineItem.id === id
  )[0];

  if (!currentItem.isDirty) return;
  const updatedLineItems = sectionLineItems.map(lineItem =>
    (id && lineItem.id === id) || (key && lineItem.key === key)
      ? {
          ...lineItem,
          isDirty: false
        }
      : lineItem
  );

  setFieldValue(
    isAdhoc ? 'adhocLineItems' : 'standardLineItems',
    updatedLineItems
  );
  putLineItemForBill(
    mapAmountToLineItemInput({
      billId,
      selectedItem: record,
      amount: currentItem.amount.amount
    }),
    billId,
    summarizeColumnOptions
  );
};

export const onDeleteLineItem = ({ deleteLineItemForBill, values }) => async (
  record,
  refetchBill
) => {
  const { summarizeColumnOptions } = values;
  const { id } = record;

  await deleteLineItemForBill(id, refetchBill, summarizeColumnOptions);
};

export const onProjectLineItemAdd = ({
  putLineItemForBill,
  setLineItemAdding,
  values
}) => async selectedProject => {
  const { id: billId } = values;

  setLineItemAdding(true);
  await putLineItemForBill(
    mapToLineItemInput({ billId, selectedItem: selectedProject }),
    billId
  );
  setLineItemAdding(false);
};

export const useOnBillInfoChange = ({
  setFieldValue,
  values,
  invoiceDefaultTemplate,
  isPsaPrpCommentsOnInvoiceEnabled,
  isPsaPrpBillingDefaultForProjectEnabled
}) => {
  const { id } = values.client || {};
  const { refetch: refetchClientDefaultData } = useQuery(
    CLIENT_DEFAULT_DATA_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: !id,
      variables: {
        uri: id,
        isPsaPrpBillingDefaultForProjectEnabled
      }
    }
  );

  return {
    onIssueDateChange: useCallback(
      date => {
        onIssueDateChange({ setFieldValue, values })(date);
      },
      [setFieldValue, values]
    ),
    onDueDateChange: useCallback(
      date => {
        onDueDateChange({ setFieldValue, values })(date);
      },
      [setFieldValue, values]
    ),
    onBillCurrencyChange: useCallback(
      value => {
        onBillCurrencyChange({
          setFieldValue,
          values
        })(value);
      },
      [setFieldValue, values]
    ),
    onPaymentTermsChange: useCallback(
      paymentTerms => {
        onPaymentTermsChange({ setFieldValue, values })(paymentTerms);
      },
      [setFieldValue, values]
    ),
    onInvoiceTemplateChange: useCallback(
      (_, invoiceTemplate) => {
        onInvoiceTemplateChange({ setFieldValue, values })(invoiceTemplate);
      },
      [setFieldValue, values]
    ),
    onBillingAddressChange: useCallback(
      event => {
        onBillingAddressChange({ setFieldValue, values })(event);
      },
      [setFieldValue, values]
    ),
    onClientChange: useCallback(
      async value => {
        if (value && value.value) {
          const result = await refetchClientDefaultData({
            uri: value.value
          });
          const { client } = result.data || {};

          if (client && client.invoiceCurrency) {
            onBillCurrencyChange({
              setFieldValue,
              values
            })(client.invoiceCurrency);
          }
          if (client) {
            onPaymentTermsChange({ setFieldValue, values })(
              client.defaultInvoicePaymentTerm
            );
            onInvoiceTemplateChange({ setFieldValue, values })(
              client.invoiceTemplate || invoiceDefaultTemplate
            );
          }
          if (client && client.billingContactInfo) {
            onBillingAddressChange({ setFieldValue, values })(
              client.billingContactInfo
            );
          }
          if (isPsaPrpBillingDefaultForProjectEnabled && client) {
            onTaxProfileChange({ setFieldValue, values })(client.taxProfile);
          }
          if (
            client &&
            client.billingSettings &&
            client.billingSettings.description
          ) {
            onDescriptionChange({ setFieldValue })(
              client.billingSettings.description
            );
          }
          if (
            client &&
            client.billingSettings &&
            client.billingSettings.internalNotes
          ) {
            onInternalNotesChange({ setFieldValue })(
              client.billingSettings.internalNotes
            );
          }
          if (
            isPsaPrpCommentsOnInvoiceEnabled &&
            client &&
            client.billingSettings
          ) {
            onShowCommentsChange({ setFieldValue })(
              client.billingSettings.showComments
            );
          }
        }
        onClientChange({ setFieldValue, values })(value);
      },
      [
        setFieldValue,
        values,
        refetchClientDefaultData,
        isPsaPrpCommentsOnInvoiceEnabled
      ]
    ),
    onDescriptionChange: useCallback(
      event => {
        onDescriptionChange({ setFieldValue })(event);
      },
      [setFieldValue]
    ),
    onTaxProfileChange: useCallback(
      (_, taxProfile) => {
        onTaxProfileChange({ setFieldValue })(taxProfile);
      },
      [setFieldValue]
    ),
    onDisplayIdChange: useCallback(
      event => {
        onDisplayIdChange({ setFieldValue })(event);
      },
      [setFieldValue]
    ),
    onPONumberChange: useCallback(
      event => {
        onPONumberChange({ setFieldValue })(event);
      },
      [setFieldValue]
    ),
    onPeriodChange: useCallback(
      dateRange => {
        onPeriodChange({ setFieldValue })(dateRange);
      },
      [setFieldValue]
    ),
    onNotesForCustomerChange: useCallback(
      event => {
        onNotesForCustomerChange({ setFieldValue })(event);
      },
      [setFieldValue]
    ),
    onInternalNotesChange: useCallback(
      event => {
        onInternalNotesChange({ setFieldValue })(event);
      },
      [setFieldValue]
    ),
    onShowCommentsOnInvoiceChange: useCallback(() => {
      onShowCommentsChange({ setFieldValue })(!values.showComments);
    }, [setFieldValue, values.showComments])
  };
};

export const useOnBillDetailsChange = ({
  setFieldValue,
  values,
  putLineItemForBill,
  deleteLineItemForBill,
  setLineItemAdding
}) => ({
  onLineItemAmountChange: useCallback(
    (e, record) => {
      onLineItemAmountChange({
        setFieldValue,
        values
      })(e, record);
    },
    [setFieldValue, values]
  ),
  onLineItemAmountBlur: useCallback(
    (e, record) => {
      onLineItemAmountBlur({
        setFieldValue,
        putLineItemForBill,
        values
      })(e, record);
    },
    [setFieldValue, putLineItemForBill, values]
  ),
  onDeleteLineItem: useCallback(
    async (record, refetchBill) => {
      await onDeleteLineItem({
        deleteLineItemForBill,
        values
      })(record, refetchBill);
    },
    [deleteLineItemForBill, values]
  ),
  onProjectLineItemAdd: useCallback(
    selectedProject => {
      onProjectLineItemAdd({
        putLineItemForBill,
        setLineItemAdding,
        values
      })(selectedProject);
    },
    [putLineItemForBill, setLineItemAdding, values]
  )
});
