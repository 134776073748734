import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0
  },
  item: {
    display: 'contents'
  },
  label: {
    textAlign: 'left',
    padding: theme.spacing(0, 1, 0, 0)
  },
  value: {
    textAlign: 'right',
    padding: theme.spacing(0, 1)
  },

  percent: {
    paddingRight: 0
  },

  allocated: {
    '& $label, & $value': {
      paddingBottom: theme.spacing(0.25)
    }
  },
  fittingAllocation: {
    '& $label, & $value': {
      borderTop: '1px solid #fff',
      paddingTop: theme.spacing(0.25)
    }
  }
}));

export default useStyles;
