import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import {
  ResourceChartTotalPopper,
  usePopperHandlers
} from '~/modules/resourcing/components/ResourceChartTotalPopper';
import { ChartTotalItems } from '~/modules/common/charts/timeline/components';
import { FormattedHoursAndCost } from '~/modules/resourcing/common/components';

const useSkeletonStyles = makeStyles(theme => ({
  skeletonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0)
  }
}));

const useStyles = makeStyles(theme => ({
  hours: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useTotalStyles = makeStyles(theme => ({
  allocated: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const RowRightItem = ({ totalHours, loadingAllocation }) => {
  const classes = useStyles();
  const skeletonClasses = useSkeletonStyles();
  const totalClasses = useTotalStyles();

  const {
    anchorEl,
    onMenuClose,
    onMenuClick,
    handleKeyDown
  } = usePopperHandlers();

  const popperContent = useMemo(
    () => (
      <ResourceChartTotalPopper
        id="task-allocation-popper"
        anchorEl={anchorEl}
        onClose={onMenuClose}
        allocatedHours={totalHours}
        viewCosts={false}
        viewAllocation
        viewDifference={false}
      />
    ),
    [anchorEl, onMenuClose, totalHours]
  );

  const total = useMemo(
    () => (
      <FormattedHoursAndCost
        classes={classes}
        hours={totalHours}
        hoursSuffix="h"
        hideCosts
        showAbbreviatedValue={false}
      />
    ),
    [classes, totalHours]
  );

  return !loadingAllocation ? (
    <ChartTotalItems
      classes={totalClasses}
      allocated={total}
      showCost={false}
      popperContent={popperContent}
      handleClick={onMenuClick}
      handleKeyDown={handleKeyDown}
      onClickAway={onMenuClose}
    />
  ) : (
    <div className={skeletonClasses}>
      <Skeleton variant="rect" width={180} height={12} />
    </div>
  );
};

RowRightItem.propTypes = {
  totalHours: PropTypes.number,
  loadingAllocation: PropTypes.bool
};

export default RowRightItem;
