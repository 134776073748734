import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonBase, Typography } from '@material-ui/core';
import IconLinkButton from '~/modules/resourcing/common/components/IconLinkButton';
import { ResourceRequestStatusIndicator } from '~/modules/resourcing/common/components';
import { ResourceRequestsIcon } from '~/modules/common/components';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { RESOURCING_ALLOCATION_MODEL } from '~/modules/common/enums';
import { ResourceRequestStatus } from '~/types';
import ResourceRequestReadonlyItem from './ResourceRequestReadonlyItem';
import useStyles from './useStyles';

export const LegacyResourceRequestItem = ({
  qeIdTag,
  resourceRequest,
  actions,
  displayEntityType,
  isEditSwitchEnabled = true,
  isResourcingPlan = false,
  project,
  maxSkillLevel = 5,
  classesOverrides
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const intl = useIntl();
  const history = useHistory();

  const { resourcingAllocationModel } = useProjectContext();

  const iconLinkButtonClasses = useMemo(
    () => ({ openButton: classes.openButton }),
    [classes.openButton]
  );

  const location = {
    hash: `#/resourceRequest/${resourceRequest.id}`,
    state: {
      isProjectEditEnabled: isResourcingPlan ? isEditSwitchEnabled : false
    }
  };

  const openDrawer = useCallback(() => {
    history.push(location);
  }, [isEditSwitchEnabled, history, location]);

  const columnClasses = useMemo(() => {
    return classnames(
      classes.column,
      classes.columnBorder,
      classes.clickableColumn
    );
  }, [
    classes.columnBorder,
    classes.clickableColumn,
    classes.column,
    isEditSwitchEnabled
  ]);

  return (
    <>
      <div
        className={classnames(
          classes.column,
          classes.columnBorder,
          isEditSwitchEnabled ? classes.actionsColumn : classes.statusColumn
        )}
      >
        <ResourceRequestStatusIndicator
          qeIdTag={qeIdTag}
          status={resourceRequest.requestStatus}
        />
        {isEditSwitchEnabled && actions && (
          <div
            data-qe-id={
              qeIdTag ? `RowTotalAction_${qeIdTag}` : 'RowTotalAction'
            }
            className={classes.actions}
          >
            {actions}
          </div>
        )}
      </div>
      <ButtonBase className={columnClasses} onClick={openDrawer}>
        {resourcingAllocationModel !== RESOURCING_ALLOCATION_MODEL.MODEL_0 &&
        resourceRequest.requestStatus === ResourceRequestStatus.Complete &&
        resourceRequest?.mergedResourceRequests?.length ? (
          <div className={classes.requestContainer}>
            <ResourceRequestsIcon />
            <Typography className={classes.multipleRequestsLabel}>
              <FormattedMessage id="resourceRequestItem.multipleRequests" />
            </Typography>
          </div>
        ) : (
          <ResourceRequestReadonlyItem
            qeIdTag={qeIdTag}
            displayEntityType={displayEntityType}
            resourceRequest={resourceRequest}
            toLink={location}
            project={project}
            maxSkillLevel={maxSkillLevel}
          />
        )}
        <IconLinkButton
          classes={iconLinkButtonClasses}
          qeIdTag={qeIdTag ? `Drawer_Icon_${qeIdTag}` : 'Drawer_Icon'}
          toLink={location}
          ariaLabel={intl.formatMessage({
            id: 'resourceRequestItem.showMoreDetails'
          })}
        />
      </ButtonBase>
    </>
  );
};

LegacyResourceRequestItem.propTypes = {
  actions: PropTypes.node.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  isEditSwitchEnabled: PropTypes.bool,
  displayEntityType: PropTypes.string,
  isResourcingPlan: PropTypes.bool,
  maxSkillLevel: PropTypes.number,
  qeIdTag: PropTypes.string,
  project: PropTypes.object,
  classesOverrides: PropTypes.object
};

export default LegacyResourceRequestItem;
