import { useQuery } from '@apollo/client';
import { RequestAttributeWeightage } from '~/types';
import resourceRequestQuery from '~/modules/resourcing/common/resourceRequestQuery';
import { useMeContext } from '~/modules/me';

export const mapResource = ({ resourceRequest, me }) => {
  const { project, resourceAllocations } = resourceRequest;
  const { defaultRequestAttributeWeights } = me;

  return {
    resourceRequest,
    resourceAllocations,
    project,
    defaultRequestAttributeWeights: {
      location:
        defaultRequestAttributeWeights?.location ||
        RequestAttributeWeightage.Normal,
      division:
        defaultRequestAttributeWeights?.division ||
        RequestAttributeWeightage.Normal,
      serviceCenter:
        defaultRequestAttributeWeights?.serviceCenter ||
        RequestAttributeWeightage.Normal,
      costCenter:
        defaultRequestAttributeWeights?.costCenter ||
        RequestAttributeWeightage.Normal,
      department:
        defaultRequestAttributeWeights?.department ||
        RequestAttributeWeightage.Normal,
      employeeType:
        defaultRequestAttributeWeights?.employeeType ||
        RequestAttributeWeightage.Normal,
      role:
        defaultRequestAttributeWeights?.role ||
        RequestAttributeWeightage.Normal,
      skills:
        defaultRequestAttributeWeights?.skills ||
        RequestAttributeWeightage.Normal,
      tags:
        defaultRequestAttributeWeights?.tags ||
        RequestAttributeWeightage.Normal,
      requestedCostRate:
        defaultRequestAttributeWeights?.requestedCostRate ||
        RequestAttributeWeightage.Normal,
      resourcePools:
        defaultRequestAttributeWeights?.resourcePools ||
        RequestAttributeWeightage.Normal,
      preferredResources:
        defaultRequestAttributeWeights?.preferredResources ||
        RequestAttributeWeightage.Normal
    }
  };
};

export const useResourceRequestDetails = (
  id,
  includeMergeResourceRequests = false
) => {
  const me = useMeContext();
  const { data, loading, error } = useQuery(resourceRequestQuery, {
    variables: {
      id,
      includeMergeResourceRequests
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  });

  const projectRequestDetails =
    !loading && data
      ? mapResource({ resourceRequest: data.resourceRequest, me })
      : {};

  return {
    loading,
    error,
    projectRequestDetails
  };
};

export default useResourceRequestDetails;
