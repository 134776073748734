import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import BreadcrumbArrow from './BreadcrumbArrow';

const NavigationBreadcrumb = ({ paths, title, classes }) => (
  <>
    {paths.map(({ to, title: linkTitle }) => (
      <Fragment key={to}>
        <Link to={to} className={classes.link}>
          {linkTitle}
        </Link>
        <BreadcrumbArrow />
      </Fragment>
    ))}
    <span>{title}</span>
  </>
);

NavigationBreadcrumb.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object
};

const styles = {
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
};

export default withStyles(styles)(NavigationBreadcrumb);
