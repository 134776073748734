import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PROJECTS_SUMMARY_FILTER_TABS } from '~/modules/common/enums';

export const getQuickFilterSummaryTabs = ({
  hideMyProjects,
  totalProjects,
  statusOptions,
  myProjects,
  archivedStatus
} = {}) => ({
  allItems: [
    {
      key: PROJECTS_SUMMARY_FILTER_TABS.ALL_PROJECTS,
      label: <FormattedMessage id="projectList.allProjects" />,
      value: totalProjects
    },
    !hideMyProjects && {
      key: PROJECTS_SUMMARY_FILTER_TABS.MY_PROJECTS,
      label: <FormattedMessage id="projectList.myProjects" />,
      value: myProjects
    }
  ].filter(r => r),
  executionPhases: statusOptions.map(statusOption => ({
    key: statusOption.id,
    label: statusOption.displayText,
    value: statusOption.projectsCount,
    color: statusOption.color.dark
  })),
  alternativeItems: [
    {
      key: archivedStatus.id,
      label: archivedStatus.displayText,
      value: archivedStatus.projectsCount,
      color: archivedStatus.color.dark
    }
  ]
});
