import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { NoDataItem } from '~/modules/common/components/NoDataItem';
import { selectedTagPropType } from '~/modules/common/components/AddTags/PropTypes';
import SelectedTagDropdowns from './SelectedTagDropdowns';

const addIconJsx = <AddIcon />;

const useStyles = makeStyles(theme => ({
  noTag: { paddingTop: theme.spacing(2) },
  error: { color: theme.palette.error.main }
}));

export const useTagOperations = ({
  setFieldValue,
  selectedTags,
  resourceTags
}) => {
  const onTagCreated = useCallback(() => {
    setFieldValue('tags', [...selectedTags, { tag: {}, selected: {} }]);
  }, [setFieldValue, selectedTags]);

  const onTagChanged = useCallback(
    index => newTag => {
      const newFieldValue = [...selectedTags];
      const mappedNewTag = {
        tag: (resourceTags || []).find(tag => tag.id === newTag.tagId),
        selected: { value: newTag.tagValue, id: newTag.tagValueId }
      };

      newFieldValue[index] = mappedNewTag;

      setFieldValue('tags', newFieldValue);
    },
    [setFieldValue, selectedTags, resourceTags]
  );

  const onTagRemoved = useCallback(
    index => () => {
      setFieldValue('tags', [
        ...selectedTags.slice(0, index),
        ...selectedTags.slice(index + 1)
      ]);
    },
    [setFieldValue, selectedTags]
  );

  return { onTagCreated, onTagChanged, onTagRemoved };
};

export const useFormMappings = ({ selectedTags, errors }) => {
  const mappedSelectedTags = useMemo(
    () =>
      (selectedTags || []).map(({ tag, selected }) => ({
        tagId: tag?.id,
        tagName: tag?.name,
        tagValue: selected?.value,
        tagValueId: selected?.id
      })),
    [selectedTags]
  );

  const mappedErrors = useMemo(
    () =>
      (errors && Array.isArray(errors) ? errors : []).map(error => ({
        tagId: error?.tag?.id,
        tagName: error?.tag?.name,
        tagValue: error?.selected?.value,
        tagValueId: error?.selected?.id
      })),
    [errors]
  );

  return { mappedSelectedTags, mappedErrors };
};

const AddTags = ({
  required,
  resourceTags,
  selectedTags,
  errors,
  setFieldValue,
  className
}) => {
  const classes = useStyles();

  const { onTagCreated, onTagChanged, onTagRemoved } = useTagOperations({
    selectedTags,
    setFieldValue,
    resourceTags
  });

  const { mappedSelectedTags, mappedErrors } = useFormMappings({
    selectedTags,
    errors
  });

  return (
    <div className={className}>
      {selectedTags.length > 0 ? (
        <SelectedTagDropdowns
          tags={mappedSelectedTags}
          errors={mappedErrors}
          onTagChanged={onTagChanged}
          onTagRemoved={onTagRemoved}
        />
      ) : (
        <div className={classes.noTag}>
          <NoDataItem>
            <span
              className={classNames({
                [classes.error]: required
              })}
            >
              <FormattedMessage id="resourceRequestDrawerDetails.noTagsExpanded" />
            </span>
          </NoDataItem>
        </div>
      )}
      <Button color="primary" startIcon={addIconJsx} onClick={onTagCreated}>
        <FormattedMessage id="search.tag"></FormattedMessage>
      </Button>
    </div>
  );
};

AddTags.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  selectedTags: PropTypes.arrayOf(selectedTagPropType),
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  setFieldValue: PropTypes.func,
  resourceTags: PropTypes.array
};

export default AddTags;
