import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import { BubbleChart } from '~/modules/common/charts/dashboard';
import { useRedirectToDetails } from '~/modules/resource-management/ResourcingOverview/hooks';

export const SkillsDistributionBubbleChart = ({ data, width, height }) => {
  const redirectCallback = useRedirectToDetails();

  const onClick = useCallback(
    ({ label, id }) => redirectCallback({ label, skill: id }),
    [redirectCallback]
  );

  return (
    <BubbleChart data={data} width={width} height={height} onClick={onClick} />
  );
};

SkillsDistributionBubbleChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number
};

export default withResizeDetector(SkillsDistributionBubbleChart);
