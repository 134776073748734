import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useHasPermission } from '~/modules/common/permissions';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useHasUserAccessRole } from '~/modules/common/permissions/useHasUserAccessRole';
import AddProgramDialog from '~/modules/programs/components/AddProgram/AddProgramDialog';
import { CreatableAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { useMeContext } from '~/modules/me/useMeContext';
import useEventHandlers from '~/modules/projects/project/common/components/ProgramDropdown/hooks/useEventHandlers';
import ProgramDropdownItem from './ProgramDropdownItem';

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

const filter = createFilterOptions();

export const ProgramDropdown = ({
  hasMore,
  options,
  isLoading,
  value,
  onChange,
  TextFieldProps: { error, helperText } = {},
  optionTypeText,
  ...rest
}) => {
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const { formatMessage } = useIntl();
  const { open, closeDialog, openDialog } = useDialogState();

  const [newProgramName, setNewProgramName] = useState();
  const openDialogAndSetInitialProgram = useCallback(
    name => {
      setNewProgramName(name);
      openDialog();
    },
    [openDialog, setNewProgramName]
  );

  const selectedValue = useMemo(
    () => ({
      label: (value && value.displayText) || '',
      value: (value && value.id) || ''
    }),
    [value]
  );

  const renderOption = useCallback(
    (option, { selected }) =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <ProgramDropdownItem
          option={option}
          selected={selected}
          onCreate={openDialogAndSetInitialProgram}
        />
      ),
    [openDialogAndSetInitialProgram]
  );

  const onProgramAdd = useCallback(
    ({ displayText, id }) =>
      onChange({
        label: displayText,
        value: id
      }),
    [onChange]
  );

  const canEditProgram = useHasPermission({
    actionUri: 'urn:replicon:program-action:edit-program'
  });

  const isProgramManager = useHasUserAccessRole({
    roleUri: USER_ACCESS_ROLE.PROGRAM_MANAGER
  });

  const canEditAllPrograms = useHasPermission({
    actionUri: 'urn:replicon:program-action:edit-program',
    dataAccessLevelUri: 'urn:replicon:program-data-access-level:all'
  });

  const canCreateProgram =
    (canEditProgram && isProgramManager) || canEditAllPrograms;

  const filterOptions = useCallback(
    (filterOption, params) => {
      const filtered = filter(filterOption, params);

      if (
        canCreateProgram &&
        params.inputValue !== '' &&
        !filterOption.some(o => o.label === params.inputValue)
      ) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          label: params.inputValue
        });
      }

      return filtered;
    },
    [canCreateProgram]
  );

  const { handleOnChange, handleOnCancel } = useEventHandlers({
    openDialogAndSetInitialProgram,
    onChange
  });

  return (
    <>
      <CreatableAutocomplete
        hasMore={hasMore}
        inputLabel={formatMessage({ id: 'projectDetails.program' })}
        loading={isLoading}
        options={options}
        fullWidth
        value={selectedValue}
        onChange={
          isPsaPrpAccessibilityIssuesEnabled ? handleOnChange : onChange
        }
        clearOnBlur={isPsaPrpAccessibilityIssuesEnabled}
        disableClearable={!(selectedValue && selectedValue.value)}
        optionPropText="label"
        canAddNewOption={canCreateProgram}
        showError={error}
        errorMessage={helperText}
        helperText={helperText}
        filterOptions={filterOptions}
        renderOption={renderOption}
        optionTypeText={optionTypeText}
        getOptionDisabled={getOptionDisabled}
        {...rest}
      />
      <AddProgramDialog
        initialName={newProgramName}
        open={open}
        onClose={closeDialog}
        onProgramAdd={onProgramAdd}
        onCancel={
          isPsaPrpAccessibilityIssuesEnabled ? handleOnCancel : undefined
        }
      />
    </>
  );
};

ProgramDropdown.propTypes = {
  hasMore: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayText: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  optionTypeText: PropTypes.string
};

export default ProgramDropdown;
