import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Rating from '@material-ui/lab/Rating';
import { Tooltip } from '@material-ui/core';
import { SkillIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';
import useSkillStyles from './useSkillStyles';

export const SkillToolTipContent = ({
  skills,
  classes,
  intl,
  maxSkillLevel = 5
}) => {
  const skillCategoryMap = {};

  const displayLabel = intl.formatMessage({
    id: 'resourceRequestSkillField.uncategorized'
  });

  skills.forEach(s => {
    const categoryUri = s.category ? s.category.id : 'uncategorized';

    if (!skillCategoryMap[categoryUri]) {
      skillCategoryMap[categoryUri] = {
        skills: [],
        category: s.category || {
          id: 'uncategorized',
          displayText: displayLabel
        }
      };
    }
    skillCategoryMap[categoryUri].skills.push({
      id: s.id,
      displayText: s.displayText,
      rating: s.skillLevel && s.skillLevel.rating
    });
  });

  const keyUris = Object.keys(skillCategoryMap);

  return (
    <>
      <div className={classes.tooltipTitle}>
        {intl.formatMessage({ id: 'resourceRequestSkillField.skills' })}
      </div>
      <div className={classes.tooltipContent}>
        <ul
          className={classNames(classes.detailList, {
            [classes.detailListDisplay]: maxSkillLevel > 0
          })}
        >
          {keyUris.map(categoryUri => {
            const group = skillCategoryMap[categoryUri];

            return (
              <React.Fragment key={categoryUri}>
                <li className={classes.group}>{group.category.displayText}</li>
                {group.skills.map(sk => (
                  <li
                    key={sk.id}
                    className={classNames({
                      [classes.assignment]: maxSkillLevel > 0
                    })}
                  >
                    <span className={classes.name}>{sk.displayText}</span>
                    {Boolean(maxSkillLevel) && (
                      <Rating
                        name={sk.displayText}
                        value={sk.rating}
                        size="small"
                        max={maxSkillLevel}
                        readOnly
                        className={classes.rating}
                      />
                    )}
                  </li>
                ))}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </>
  );
};

SkillToolTipContent.propTypes = {
  skills: PropTypes.array,
  classes: PropTypes.object,
  intl: PropTypes.object,
  maxSkillLevel: PropTypes.number
};

export const ResourceRequestSkillsField = ({
  skills,
  maxSkillLevel,
  tooltipClasses
}) => {
  const classes = useStyles();
  const skillClasses = useSkillStyles();

  const intl = useIntl();

  const title = useMemo(
    () => (
      <SkillToolTipContent
        skills={skills}
        intl={intl}
        classes={skillClasses}
        maxSkillLevel={maxSkillLevel}
      />
    ),
    [skills, intl, skillClasses, maxSkillLevel]
  );

  return (
    <Tooltip title={title} classes={tooltipClasses}>
      <SkillIcon className={classes.detailsIndicator} />
    </Tooltip>
  );
};

ResourceRequestSkillsField.propTypes = {
  skills: PropTypes.array,
  maxSkillLevel: PropTypes.number,
  tooltipClasses: PropTypes.object
};

export default ResourceRequestSkillsField;
