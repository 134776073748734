import { Grid, TextField, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchableClientDropdown } from '~/modules/projects/project/common/components';
import {
  AssociatedBillsTable,
  DrawerExpansionPanel,
  ReferenceNumberField
} from '~/modules/billing-invoicing/common/components';
import {
  useExchangeRates,
  useIsMultiCurrency
} from '~/modules/billing-invoicing/common/hooks';
import { CompactFormControl as FormControl } from '~/modules/clients/components';
import { DateField, Money2 } from '~/modules/common/components';
import { getError, hasError } from '~/util';
import {
  useAssociatedAndOutStandingBills,
  useBillPaymentTotalProps,
  useFormOnChange,
  useBillPaymentResourceHook
} from '~/modules/billing-invoicing/billPayment/hooks';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';
import PaymentMethodDropdown from '../PaymentMethodDropdown';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    margin: 0,
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderBottom: `1px solid ${theme.palette.table.border}`,
    minHeight: '-webkit-max-content'
  },
  showError: {
    paddingLeft: 15,
    color: theme.palette.error.dark
  }
}));

export const BillPaymentDetails = ({
  me,
  errors,
  values,
  hasClient,
  billId,
  showAllBills = true,
  isSubmitting,
  billBalanceTotal,
  setFieldValue,
  isRefund
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const {
    id,
    client,
    displayId,
    description,
    paymentDate,
    currency,
    paymentAmount,
    paymentMethod,
    associatedBills
  } = values;
  const intl = useIntl();
  const [showAllOutstandingBills, setShowAllOutstandingBIlls] = useState(
    showAllBills
  );
  const {
    associatedAndOutstandingBills,
    loading: isRowsLoading,
    hasMoreRows,
    isLoadingMore,
    loadMoreRows
  } = useAssociatedAndOutStandingBills({
    me,
    client,
    values,
    billId,
    billBalanceTotal,
    isRefund,
    showAllOutstandingBills
  });
  const { isMultiCurrency } = useIsMultiCurrency({
    values,
    associatedAndOutstandingBills
  });

  const {
    exchangeRates,
    isExchangeRateLoading,
    fetchMoreExchangeRates
  } = useExchangeRates({
    currency,
    paymentDate,
    associatedAndOutstandingBills,
    isMultiCurrency
  });
  const {
    associatedBillsTotal,
    billPaymentBalanceTotal
  } = useBillPaymentTotalProps({ values });
  const {
    onClientChange,
    onDisplayIdChange,
    onDescriptionChange,
    onPaymentDateChange,
    onPaymentAmountChange,
    onAllocatedBillAmountChange,
    onPaymentCurrencyChange,
    onPaymentMethodChange,
    onAssociatedBillAmountChange
  } = useFormOnChange({
    setFieldValue,
    values,
    fetchMoreExchangeRates,
    billPaymentBalanceTotal,
    associatedBills,
    currency,
    exchangeRates
  });
  const {
    billsTableResourceKeys,
    resourceLabels,
    accesibilityResourceLabels,
    expansionPanelResourceKeys
  } = useBillPaymentResourceHook({
    client,
    intl
  });

  const inputRefCallback = useCallback(
    errorId => inputRef => {
      if (isSubmitting && hasError(errors, errorId) && inputRef)
        inputRef.focus();
    },
    [isSubmitting, errors]
  );

  const clientTextFieldProps = useMemo(
    () => ({
      error: hasError(errors, 'client'),
      helperText: hasError(errors, 'client') && (
        <FormattedMessage id="billDetails.validations.clientIsRequired" />
      )
    }),
    [errors]
  );

  const isEditable = useMemo(
    () => associatedBillsTotal.amount === 0 || id === null,
    [associatedBillsTotal.amount, id]
  );

  const isLoading = useMemo(
    () =>
      (isRowsLoading && !isLoadingMore) ||
      (isMultiCurrency && isExchangeRateLoading),
    [isExchangeRateLoading, isLoadingMore, isMultiCurrency, isRowsLoading]
  );

  return (
    <Grid container className={classes.container} spacing={2}>
      {!hasClient && (
        <Grid item xs={12} data-qe-id="PaymentClientDropdown">
          <SearchableClientDropdown
            aria-label={accesibilityResourceLabels.client}
            variant="filled"
            value={client}
            autoFocus={!hasClient}
            inputRef={inputRefCallback('client')}
            onChange={onClientChange}
            TextFieldProps={clientTextFieldProps}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <ReferenceNumberField
          hasClient={hasClient}
          onReferenceNumberChange={onDisplayIdChange}
          transactionType={billingTransactionType.PAYMENT}
          errors={errors}
          displayId={displayId}
          displayText={intl.formatMessage({
            id: 'billPaymentDetails.payment'
          })}
          inputRefCallback={inputRefCallback}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          variant="filled"
          fullWidth
          required
          clearable={false}
          label={accesibilityResourceLabels.paymentDate}
          ariaLabel={accesibilityResourceLabels.paymentDate}
          value={paymentDate}
          labelClassName={classes.inputLabel}
          errorClassName={classes.error}
          onChange={onPaymentDateChange}
          editable={isEditable}
          underline={isEditable}
          inputRef={inputRefCallback('paymentDate')}
          error={hasError(errors, 'paymentDate')}
          helperText={getError(errors, 'paymentDate')}
          useDateFieldStyles={false}
          data-qe-id="billPaymentDetails.paymentDate"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="billPayment.description"
          variant="filled"
          fullWidth
          label={resourceLabels.description}
          inputRef={inputRefCallback('description')}
          value={description}
          onChange={onDescriptionChange}
          error={hasError(errors, 'description')}
          helperText={getError(errors, 'description')}
          data-qe-id="billPaymentDetails.description"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="filled" fullWidth>
          <PaymentMethodDropdown
            variant="filled"
            ariaLabel={accesibilityResourceLabels.paymentMethod}
            label={accesibilityResourceLabels.paymentMethod}
            value={paymentMethod}
            onChange={onPaymentMethodChange}
            dataQeId="billPaymentDetails.paymentMethod"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Money2
          id="billPayment.PaymentAmount"
          label={resourceLabels.paymentAmount}
          currencyAriaLabel={accesibilityResourceLabels.currency}
          variant="filled"
          useNumberLabelStyles
          inputRef={inputRefCallback('paymentAmount')}
          editable={!isRefund && isEditable}
          onCurrencyChange={onPaymentCurrencyChange}
          amount={paymentAmount}
          currency={currency}
          allowNegative={isRefund}
          labelClassName={classes.label}
          onAmountChange={onPaymentAmountChange}
          error={hasError(errors, 'paymentAmount')}
          helperText={getError(errors, 'paymentAmount')}
          data-qe-id="billPaymentDetails.amount"
        />
      </Grid>
      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={expansionPanelResourceKeys}
          amountTotal={associatedBillsTotal}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <AssociatedBillsTable
            associatedBills={associatedAndOutstandingBills}
            onAmountChange={onAssociatedBillAmountChange}
            amountTotal={associatedBillsTotal}
            errors={errors}
            resourceKeys={billsTableResourceKeys}
            billId={billId}
            showAllOutstandingBills={showAllOutstandingBills}
            setShowAllOutstandingBIlls={setShowAllOutstandingBIlls}
            onAllocatedAmountChange={onAllocatedBillAmountChange}
            isMultiCurrency={isMultiCurrency}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            isRefund={isRefund}
            editable={!isRefund}
            hasMoreRows={hasMoreRows}
            loadMoreRows={loadMoreRows}
          />
        </DrawerExpansionPanel>
      </Grid>
      <div className={classes.showError}>
        {getError(errors, 'billPaymentBalanceTotal')}
      </div>
    </Grid>
  );
};

BillPaymentDetails.propTypes = {
  me: PropTypes.object,
  billBalanceTotal: PropTypes.any,
  setFieldValue: PropTypes.func,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hasClient: PropTypes.bool.isRequired,
  showAllBills: PropTypes.bool,
  billId: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isRefund: PropTypes.bool
};

export default BillPaymentDetails;
