import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { objectToUTCString } from '~/modules/common/dates/convert';

const PUT_USER_CERTIFICATE_MUTATION = gql`
  mutation putCertificate($input: UpdateUserCertificateInput!) {
    putUserCertificate2(input: $input) {
      certificate {
        id
        name
        issuer
        expiryDate
        issueDate
        expires
        credentialId
        credentialUrl
      }
      error {
        reason
      }
    }
  }
`;

export const mapCertificateToInput = ({
  userUri,
  certificate: {
    uri,
    name,
    issuer,
    credentialId,
    credentialUrl,
    doesNotExpire,
    issueDate,
    expiryDate
  }
}) => ({
  userUri,
  certificate: {
    uri,
    name,
    issuer,
    credentialId,
    credentialUrl,
    expires: !doesNotExpire,
    issueDate: issueDate ? objectToUTCString(issueDate) : null,
    expiryDate:
      expiryDate && !doesNotExpire ? objectToUTCString(expiryDate) : null
  }
});

export const usePutCertificateMutation = () =>
  useMutation(PUT_USER_CERTIFICATE_MUTATION);

export const usePutCertificate = ({
  userUri,
  onCancel,
  handleReset,
  setSubmitError,
  setIsSubmitting
}) => {
  const [putUserCertificate] = usePutCertificateMutation();

  const onPut = useCallback(
    async certificate => {
      setIsSubmitting(true);

      try {
        await putUserCertificate({
          variables: {
            input: mapCertificateToInput({ userUri, certificate })
          },
          refetchQueries: ['getResourceCertificates'],
          awaitRefetchQueries: true
        });
      } catch (err) {
        setSubmitError(err);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
        setSubmitError(null);
        if (handleReset) handleReset();
        onCancel();
      }
    },
    [
      userUri,
      onCancel,
      handleReset,
      setSubmitError,
      setIsSubmitting,
      putUserCertificate
    ]
  );

  return { onPut };
};

export default usePutCertificate;
