import { useState, useCallback } from 'react';

export const useIsToggleExpanded = (initialState = false) => {
  const [isExpanded, setIsExpanded] = useState(initialState);
  const toggleIsExpandedState = useCallback(() => setIsExpanded(t => !t), []);

  return {
    isExpanded,
    toggleIsExpandedState
  };
};

export default useIsToggleExpanded;
