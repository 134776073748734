import { ResourceUserAvailabilityGroupType } from '~/types';

const useGetDefaultGroupField = ({
  settings,
  isViewRoleEnabled,
  isViewCostCenterEnabled,
  isViewDivisionEnabled,
  isViewDepartmentEnabled,
  isViewEmployeeTypeEnabled,
  isViewLocationEnabled,
  isViewServiceCenterEnabled
}) => {
  if (settings && settings.groupDropdown) {
    const { groupDropdown } = settings;

    if (
      isViewRoleEnabled &&
      groupDropdown === ResourceUserAvailabilityGroupType.Role
    )
      return ResourceUserAvailabilityGroupType.Role;

    if (
      isViewCostCenterEnabled &&
      groupDropdown === ResourceUserAvailabilityGroupType.CostCenter
    )
      return ResourceUserAvailabilityGroupType.CostCenter;

    if (
      isViewDepartmentEnabled &&
      groupDropdown === ResourceUserAvailabilityGroupType.Department
    )
      return ResourceUserAvailabilityGroupType.Department;

    if (
      isViewDivisionEnabled &&
      groupDropdown === ResourceUserAvailabilityGroupType.Division
    )
      return ResourceUserAvailabilityGroupType.Division;

    if (
      isViewEmployeeTypeEnabled &&
      groupDropdown === ResourceUserAvailabilityGroupType.EmployeeType
    )
      return ResourceUserAvailabilityGroupType.EmployeeType;
    if (
      isViewLocationEnabled &&
      groupDropdown === ResourceUserAvailabilityGroupType.Location
    )
      return ResourceUserAvailabilityGroupType.Location;
    if (
      isViewServiceCenterEnabled &&
      groupDropdown === ResourceUserAvailabilityGroupType.ServiceCenter
    )
      return ResourceUserAvailabilityGroupType.ServiceCenter;
  }

  return isViewRoleEnabled
    ? ResourceUserAvailabilityGroupType.Role
    : isViewCostCenterEnabled
    ? ResourceUserAvailabilityGroupType.CostCenter
    : isViewDepartmentEnabled
    ? ResourceUserAvailabilityGroupType.Department
    : isViewDivisionEnabled
    ? ResourceUserAvailabilityGroupType.Division
    : isViewEmployeeTypeEnabled
    ? ResourceUserAvailabilityGroupType.EmployeeType
    : isViewLocationEnabled
    ? ResourceUserAvailabilityGroupType.Location
    : ResourceUserAvailabilityGroupType.ServiceCenter;
};

export default useGetDefaultGroupField;
