import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';

const CalendarDateInput = ({
  value,
  variant,
  validationError,
  KeyboardButtonProps,
  InputAdornmentProps,
  onClick,
  onValueChange,
  InputProps,
  dateFormat,
  disabled,
  intl,
  onKeyDown,
  ...other
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const onChangeEventHandler = useCallback(
    e => {
      const v = e.target.value;

      setTextValue(v);
      if (!v) {
        onValueChange(null);
        setErrorMessage(null);

        return;
      }
      const parsedDate = DateTime.fromFormat(v, dateFormat);

      onValueChange(parsedDate);

      if (parsedDate.isValid) {
        setErrorMessage(null);
      } else {
        setErrorMessage(
          intl.formatMessage({ id: 'dateField.invalidDateFormat' })
        );
      }
    },
    [dateFormat, intl, onValueChange, setTextValue, setErrorMessage]
  );

  return (
    <TextField
      {...other}
      disabled={disabled}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      value={textValue}
      onChange={onChangeEventHandler}
      variant={variant}
      InputProps={InputProps}
    />
  );
};

CalendarDateInput.propTypes = {
  value: PropTypes.string,
  variant: PropTypes.string,
  validationError: PropTypes.string,
  KeyboardButtonProps: PropTypes.object,
  InputAdornmentProps: PropTypes.object,
  onClick: PropTypes.func,
  onValueChange: PropTypes.func,
  InputProps: PropTypes.object,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  intl: PropTypes.object,
  onKeyDown: PropTypes.func
};

export default injectIntl(CalendarDateInput);
