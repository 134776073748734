import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { UserWithRoles } from '~/modules/common/components/User';
import { useStyles } from './useStyles';

export const ResoureUserListItem = ({ record: resource }) => {
  const classes = useStyles();

  const { slug } = resource;

  const userWithRolesClass = useMemo(
    () => ({
      row: classes.nameRow
    }),
    [classes.nameRow]
  );

  const history = useHistory();
  const onClick = useCallback(() => history.push(`/resource-pool/${slug}`), [
    history,
    slug
  ]);

  return (
    <ListItem
      component="li"
      role="listitem"
      className={classes.root}
      onClick={onClick}
    >
      <UserWithRoles
        user={resource}
        showTooltip={false}
        classes={userWithRolesClass}
      />
    </ListItem>
  );
};

ResoureUserListItem.propTypes = {
  record: PropTypes.object
};

export default ResoureUserListItem;
