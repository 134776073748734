import {
  Money as MoneyFormatter,
  String as StringFormatter
} from '~/modules/common/components/ListTable/renderers';
import { AmountWithExchangeRate } from '~/modules/billing-invoicing/common/columnFormatters';
import DeleteBillCredit from './DeleteBillCredit';

export default {
  deleteIcon: DeleteBillCredit,
  displayText: StringFormatter,
  creditDate: StringFormatter,
  paymentDate: StringFormatter,
  allocatedBillAmount: MoneyFormatter,
  allocatedCreditMemoAmount: AmountWithExchangeRate,
  allocatedBillPaymentAmount: AmountWithExchangeRate,
  allocatedAmountTotal: MoneyFormatter
};
