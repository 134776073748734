import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { NoValue, Decimal, Delta } from '~/modules/common/components';
import { useFooterStyles, useHoursStyles, useDeltaStyles } from './useStyles';
import ResourceDropDown from './ResourceDropDown';

const deltaTooltip = (
  <FormattedMessage id="taskResourceAssignments.deltaTooltip" />
);

export const CustomHours = ({ description, value, delta, dataQeId }) => {
  const classes = useHoursStyles();
  const deltaClasses = useDeltaStyles();

  return (
    <div className={classes.root}>
      <span className={classes.text}>{description}</span>
      <div className={classes.hour} data-qe-id={`${dataQeId}_Hours`}>
        {isNil(value) ? (
          <NoValue className={classes.noValue} />
        ) : (
          <Decimal className={classes.text} value={value} />
        )}
      </div>
      <div className={classes.delta} data-qe-id={`${dataQeId}_Delta`}>
        {Boolean(delta) && (
          <Delta
            delta={delta}
            reverseColors
            precision={2}
            classes={deltaClasses}
            tooltipTitle={deltaTooltip}
            tooltipPlacement="bottom-start"
          />
        )}
      </div>
    </div>
  );
};

CustomHours.propTypes = {
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  value: PropTypes.number,
  delta: PropTypes.number,
  dataQeId: PropTypes.string
};

const totalHoursString = (
  <FormattedMessage id="taskResourceAssignments.total" />
);
const estimatedHoursString = (
  <FormattedMessage id="taskResourceAssignments.taskEstimatedHours" />
);

export const TaskResourceAllocationsFooter = ({
  classes: overrideClasses,
  taskId,
  dataQeId,
  editable,
  disabled,
  isUpdating,
  projectSlug,
  assignedRole,
  onResourceAdd,
  initialEstimatedHours,
  resourceAllocations = [],
  totalAllocatedHours
}) => {
  const classes = useFooterStyles({ classes: overrideClasses });
  const delta = (totalAllocatedHours || 0) - (initialEstimatedHours || 0);

  return (
    <div className={classes.root}>
      <div className={classNames(classes.cell, classes.resourecCell)}>
        {editable && (
          <ResourceDropDown
            taskId={taskId}
            disabled={disabled}
            isUpdating={isUpdating}
            assignedRole={assignedRole}
            onResourceAdd={onResourceAdd}
            projectSlug={projectSlug}
            selectedUserIds={resourceAllocations.map(a => a.resource.id)}
          />
        )}
      </div>
      <div className={classes.cell}>
        <CustomHours
          description={totalHoursString}
          value={totalAllocatedHours}
          delta={delta}
          dataQeId={dataQeId && `${dataQeId}_Total`}
        />
        <CustomHours
          description={estimatedHoursString}
          value={initialEstimatedHours}
          dataQeId={dataQeId && `${dataQeId}_TaskEstimated`}
        />
      </div>
      {editable && (
        <div className={classNames(classes.cell, classes.deleteCell)} />
      )}
    </div>
  );
};

TaskResourceAllocationsFooter.propTypes = {
  classes: PropTypes.object,
  taskId: PropTypes.string,
  dataQeId: PropTypes.string,
  disabled: PropTypes.bool,
  isUpdating: PropTypes.bool,
  assignedRole: PropTypes.object,
  projectSlug: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  initialEstimatedHours: PropTypes.number,
  onResourceAdd: PropTypes.func,
  resourceAllocations: PropTypes.array.isRequired,
  totalAllocatedHours: PropTypes.number
};

export default TaskResourceAllocationsFooter;
