import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  taskTitle: {
    display: 'flex',
    padding: theme.spacing(1),
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...theme.typography.h6,
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(0.25),
    fontSize: theme.typography.body1.fontSize
  }
});

export default withStyles(styles);
