import { useQuery } from '@apollo/client';
import { useMemo, useCallback, useState } from 'react';
import { gql } from 'graphql-tag';
import { DateTime } from 'luxon';
import { getTodayForUser } from '~/modules/common/dates/today';
import { useMeContext } from '~/modules/me';
import { SortDirection } from '~/types';

export const GET_PAGE_OF_PROJECT_RATES = gql`
  query getPageOfProjectRates(
    $projectId: String!
    $pageSize: Int!
    $page: Int!
    $dimensionUris: [String!]
    $filters: ProjectRateFilter
    $projectRateCountFilters: ProjectRateFilter
    $sort: [ProjectRateSort]
  ) {
    project(projectId: $projectId) {
      id
      projectRateCount(filters: $projectRateCountFilters)
      allProjectRatesCount: projectRateCount(filters: $filters)
      pageOfProjectRate(
        page: $page
        pageSize: $pageSize
        filters: $filters
        dimensionUris: $dimensionUris
        sort: $sort
      ) {
        id
        dimensions {
          dimensionUri
          dimensionValueReference {
            id
            displayText
          }
        }
        effectiveDate
        amount {
          amount
          currency {
            id
            displayText
            symbol
          }
        }
      }
    }
  }
`;

const getToday = me => {
  const { day, month, year } = getTodayForUser(me);

  return DateTime.utc(year, month, day);
};

export const mapToGetPageOfProjectRatesVariables = (
  projectId,
  projectRateDimensionUris,
  me
) => {
  const today = getToday(me);

  return {
    projectId,
    dimensionUris: projectRateDimensionUris,
    sort: (projectRateDimensionUris || []).map(dimensionUri => ({
      dimensionUri,
      direction: SortDirection.Asc
    })),
    page: 1,
    pageSize: 25,
    projectRateCountFilters: {
      effectiveDateRange: { startDate: today, endDate: today }
    }
  };
};

const areAllDimensionsPresentInPrevRates = (
  prevRateDimensions,
  currDimensionsUrisSet
) => {
  return (prevRateDimensions || []).every(obj =>
    currDimensionsUrisSet.has(obj.dimensionUri)
  );
};

export const getModifiedRates = rates => {
  return rates.reduce((acc, rate, index) => {
    if (rate.dimensions === null) {
      return [...acc, { ...rate, isDimensionDeleted: false }];
    }

    const isDimensionDeleted =
      rate.dimensions[rate.dimensions.length - 1].dimensionValueReference
        .displayText === 'OBJECT_DELETED' ||
      (areAllDimensionsPresentInPrevRates(
        rates[index - 1].dimensions,
        new Set(rate.dimensions.map(d => d.dimensionUri))
      ) &&
        acc[index - 1].isDimensionDeleted);

    return [...acc, { ...rate, isDimensionDeleted }];
  }, []);
};

export const usePageOfProjectRate = ({
  projectId,
  dimensionUris,
  skip = false
}) => {
  const me = useMeContext();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, loading, fetchMore, variables } = useQuery(
    GET_PAGE_OF_PROJECT_RATES,
    {
      variables: mapToGetPageOfProjectRatesVariables(
        projectId,
        dimensionUris,
        me
      ),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip
    }
  );

  const rates = useMemo(() => data?.project?.pageOfProjectRate || [], [
    data?.project?.pageOfProjectRate
  ]);
  const groupCount = data?.project?.projectRateCount || 0;
  const totalCount = data?.project?.allProjectRatesCount || null;

  const hasMore = totalCount > rates?.length;

  const loadMore = useCallback(
    async onAddEntryClick => {
      if (!hasMore) return;
      setIsLoadingMore(true);
      try {
        await fetchMore({
          query: GET_PAGE_OF_PROJECT_RATES,
          variables: {
            ...variables,
            page: onAddEntryClick ? 1 : rates.length / variables.pageSize + 1,
            pageSize: onAddEntryClick ? 1000 : variables.pageSize
          },
          updateQuery: (
            previousResult,
            {
              fetchMoreResult: {
                project: { pageOfProjectRate: fetchMoreResult }
              }
            }
          ) => {
            const result = {
              project: {
                ...previousResult.project,
                pageOfProjectRate: onAddEntryClick
                  ? [...fetchMoreResult]
                  : [...rates, ...fetchMoreResult]
              }
            };

            return result;
          }
        });
      } finally {
        setIsLoadingMore(false);
      }
    },
    [fetchMore, hasMore, rates, variables]
  );

  return {
    loading,
    projectRates: getModifiedRates(rates),
    projectRateCount: groupCount,
    hasMore,
    loadingMore: isLoadingMore,
    loadMore
  };
};
