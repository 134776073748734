import { gql } from 'graphql-tag';

export const getResourcingPlansQuery = gql`
  query getResourcePlansForCurrentTenant(
    $paginationToken: String
    $filter: ResourcePlanFilter
    $groupBy: ResourcePlanGroupBy
    $requestAccessLevel: ResourceRequestAccessLevel
  ) {
    getResourcePlansForCurrentTenant(
      pageSize: 100
      paginationToken: $paginationToken
      filter: $filter
      groupBy: $groupBy
      requestAccessLevel: $requestAccessLevel
    )
      @connection(
        key: "resourcePlansForCurrentTenant"
        filter: ["filter", "groupBy", "requestAccessLevel"]
      ) {
      paginationToken
      plans {
        id
        planName
        planUri
        count
      }
    }
  }
`;
