import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  FormControlLabel,
  Radio,
  Typography,
  makeStyles,
  ListItem
} from '@material-ui/core';

import { DateRange } from '~/modules/common/components';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

const useLabelClasses = makeStyles(theme => ({
  root: {
    width: theme.spacing(25)
  },
  label: {
    ...theme.typography.body2
  }
}));
const useDateRangeStyles = makeStyles(theme => ({
  range: {
    marginRight: theme.spacing(9),
    ...theme.typography.body2
  },
  date: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(0.5, 1)
  },
  roleLabel: {
    width: theme.spacing(25),
    marginLeft: theme.spacing(0.5)
  }
}));

const control = <Radio color="primary" />;

export const EditAllocationUserListItem = ({
  allocation,
  showRadioButton,
  onAllocationChange
}) => {
  const dateRangeClasses = useDateRangeStyles();
  const labelClasses = useLabelClasses();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { id, startDate, endDate, role } = allocation;

  const displayText = role
    ? role.displayText
    : formatMessage({
        id: 'editAllocationUserDialog.noRole'
      });

  const onClick = useCallback(() => {
    onAllocationChange(id);
  }, [id, onAllocationChange]);

  return (
    <ListItem
      button={showRadioButton}
      dense={false}
      className={classes.listItem}
      onClick={onClick}
    >
      {showRadioButton ? (
        <FormControlLabel
          value={id}
          control={control}
          classes={labelClasses}
          label={displayText}
        />
      ) : (
        <Typography variant="body2" className={classes.roleLabel}>
          {displayText}
        </Typography>
      )}
      <DateRange
        start={mapIsoStringtoUtcObject(startDate)}
        end={mapIsoStringtoUtcObject(endDate)}
        classes={dateRangeClasses}
      />
    </ListItem>
  );
};

EditAllocationUserListItem.propTypes = {
  allocation: PropTypes.object,
  showRadioButton: PropTypes.bool,
  onAllocationChange: PropTypes.func
};

export default EditAllocationUserListItem;
