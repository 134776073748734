import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: 0,
    top: theme.spacing(-8)
  },
  groupUl: {
    padding: 0
  },
  resourceTitle: {
    flexGrow: 1,
    flexShrink: 1
  },
  availabilityTitle: {
    flexGrow: 0,
    flexShrink: 0
  },
  nameSortLabel: {},
  availabilitySortLabel: {
    flexDirection: 'row-reverse',
    marginLeft: theme.spacing(-1)
  },
  option: {
    ...theme.typography.body2
  },
  resourceAvatar: {
    flexGrow: 0,
    flexShrink: 0,
    margin: theme.spacing(0, 1, 0, 0)
  },
  resourceName: {},
  resourceRoles: {},
  resourceAvailability: {
    flexGrow: 0,
    flexShrink: 0,
    margin: theme.spacing(0, 0, 0, 1)
  }
});

export const useInputStyles = makeStyles(theme => ({
  inputRoot: {
    paddingRight: '0px !important' // Fix extra padding for input fields
  },
  input: theme.typography.body2,
  option: {
    justifyContent: 'space-between'
  }
}));

export const useTooltipStyles = makeStyles(
  theme =>
    theme.direction === 'rtl' && {
      popper: {
        right: 'unset !important'
      }
    }
);

export const useTableSortLabelHoverStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      color: theme.palette.grey[900]
    }
  }
}));

export const useIconButtonStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.625)
  }
}));

export const useAvailabilityTitleStyles = makeStyles(theme => ({
  isEnabled: { color: theme.palette.grey[900] },
  root: {
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[900]
  },
  isDisabled: {
    color: theme.palette.grey[400]
  }
}));

export default makeStyles(styles);
