import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate'
  },
  tableHeaderCell: {
    padding: theme.spacing(2, 2, 1, 2),
    top: theme.spacing(12),
    height: 'auto'
  },
  tableCell: {
    padding: theme.spacing(1, 2),
    height: 'auto'
  },
  headerCellWithNavigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'end'
  }
}));
