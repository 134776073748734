import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const CREATE_PROJECT_DELETEBATCH_MUTATION = gql`
  mutation createProjectDeleteBatch($projectIds: [String!]!) {
    createProjectDeleteBatch2(projectIds: $projectIds) {
      batchUri
    }
  }
`;

export const useCreateProjectDeleteBatch = ({
  objectIds: projectIds,
  setBatchState
}) => {
  const [deleteBatch] = useMutation(CREATE_PROJECT_DELETEBATCH_MUTATION);

  return useCallback(async () => {
    setBatchState({ batchId: null, batchInProgress: true });
    const { data, error } = await deleteBatch({
      variables: {
        projectIds
      }
    });

    const batchId = data?.createProjectDeleteBatch2?.batchUri;

    setBatchState({ batchId, batchInProgress: !error });
  }, [projectIds, deleteBatch, setBatchState]);
};

export default useCreateProjectDeleteBatch;
