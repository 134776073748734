import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useRedirectToDetails } from '~/modules/resource-management/ResourcingOverview/hooks';
import { ResourceUserAvailabilityGroupType } from '~/types';
import { useMeContext } from '~/modules/me/useMeContext';
import { getTodayForCompany } from '~/modules/common/dates/today';
import {
  getFormattedDateForScale,
  scaleOffsets
} from '~/modules/resource-management/ResourcingOverview/util';
import {
  RoleField,
  GradientField,
  GroupField,
  CountField
} from './columnRenders';

export const getGroupByColumnDisplayName = groupBy =>
  groupBy === ResourceUserAvailabilityGroupType.Role ? (
    <FormattedMessage id="resourceAvailabilityCard.role" />
  ) : groupBy === ResourceUserAvailabilityGroupType.CostCenter ? (
    <FormattedMessage id="resourceAvailabilityCard.costCenter" />
  ) : groupBy === ResourceUserAvailabilityGroupType.ServiceCenter ? (
    <FormattedMessage id="resourceAvailabilityCard.serviceCenter" />
  ) : groupBy === ResourceUserAvailabilityGroupType.Division ? (
    <FormattedMessage id="resourceAvailabilityCard.division" />
  ) : groupBy === ResourceUserAvailabilityGroupType.Location ? (
    <FormattedMessage id="resourceAvailabilityCard.location" />
  ) : groupBy === ResourceUserAvailabilityGroupType.EmployeeType ? (
    <FormattedMessage id="resourceAvailabilityCard.employeeType" />
  ) : (
    <FormattedMessage id="resourceAvailabilityCard.department" />
  );

export const useColumns = ({
  groupBy,
  overViewUnit,
  dateRange,
  periodScale,
  classes,
  totals
}) => {
  const onClick = useRedirectToDetails();
  const me = useMeContext();
  const companyDate = getTodayForCompany(me);
  const { periods = [], count } = totals || {};

  return useMemo(() => {
    const columns = [
      {
        field: groupBy,
        value: getGroupByColumnDisplayName(groupBy),
        className: classNames(classes.group, classes.groupwithNav),
        required: true,
        fixed: true,
        visible: true,
        sortable: true,
        includesNavigationComponent: true,
        render: ({ record }) =>
          groupBy === ResourceUserAvailabilityGroupType.Role ? (
            <RoleField
              record={record}
              dateRange={dateRange}
              onClick={onClick}
            />
          ) : (
            <GroupField
              record={record}
              groupBy={groupBy}
              dateRange={dateRange}
              onClick={onClick}
            />
          )
      },
      {
        field: 'count',
        value: '#',
        sortable: false,
        visible: true,
        required: true,
        className: classes.count,
        render: ({ record }) => (
          <CountField count={record.count} companyDate={companyDate} />
        )
      }
    ];

    const totalColumn = totalValue => () =>
      totalValue ? (
        <GradientField
          period={totalValue}
          dateRange={dateRange}
          overViewUnit={overViewUnit}
        />
      ) : (
        <div />
      );

    const periodColumn = index => ({ record }) => {
      const period = record.periods[index];

      return period ? (
        <GradientField
          period={period}
          dateRange={dateRange}
          overViewUnit={overViewUnit}
        />
      ) : (
        <div />
      );
    };

    let date = dateRange.startDate;
    let index = 0;

    const footerColumns = [
      {
        id: groupBy,
        visible: true,
        value: null,
        colSpan: 1,
        align: 'right',
        render: () => <FormattedMessage id="resourceAvailabilityCard.total" />
      },
      {
        id: 'count',
        visible: true,
        value: null,
        align: 'center',
        render: () =>
          periods ? (
            <CountField count={count} companyDate={companyDate} />
          ) : (
            <div />
          )
      }
    ];

    while (date <= dateRange.endDate) {
      const fieldDate = getFormattedDateForScale(periodScale, date);

      columns.push({
        field: `zzz${fieldDate}`,
        value: fieldDate,
        sortable: false,
        required: true,
        visible: true,
        className: classes.duration,
        render: periodColumn(index)
      });

      footerColumns.push({
        id: `zzz${fieldDate}`,
        visible: true,
        render: totalColumn(periods[index]),
        align: 'center'
      });

      index++;

      date = date.plus(scaleOffsets[periodScale]);
    }

    const listColumns = columns.reduce(
      (obj, item) => ({
        ...obj,
        [item.field]: item
      }),
      {}
    );

    const footers = footerColumns.reduce(
      (obj, item) => ({
        ...obj,
        [item.id]: item
      }),
      {}
    );

    const headers = [
      {
        id: 'top',
        columns: columns.map(
          ({
            field,
            fixed,
            sortable,
            includesNavigationComponent,
            className
          }) => ({
            column: field,
            fixed: fixed || false,
            sortable: sortable || false,
            includesNavigationComponent: includesNavigationComponent || false,
            className
          })
        )
      },
      { id: 'bottom', columns: [] }
    ];

    return {
      listColumns,
      headers,
      footers
    };
  }, [
    classes.count,
    classes.duration,
    classes.group,
    classes.groupwithNav,
    companyDate,
    count,
    dateRange,
    groupBy,
    onClick,
    overViewUnit,
    periodScale,
    periods
  ]);
};

export default useColumns;
