export const rate = `
rate {
  amount
  currency {
    id
    symbol
    displayText
  }
}`;
export const billingScheduleEntries = `  
  billingScheduleEntries {
    id
    effectiveDate
    ${rate}
  }
`;
