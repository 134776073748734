import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import {
  Hours as CommonHours,
  Decimal,
  NoValue
} from '~/modules/common/components';
import { hasError } from '~/util';
import { useHoursStyles } from '../useStyles';

export const Hours = ({
  column: { editable, errors, isUpdating, onHoursBlur, onHoursChange },
  field,
  record,
  index
}) => {
  const hours = record[field];
  const classes = useHoursStyles();

  const handleOnChange = useCallback(
    e => {
      onHoursChange(e, index);
    },
    [index, onHoursChange]
  );

  const handleHoursBlur = useCallback(
    e => {
      onHoursBlur && onHoursBlur(e, record);
    },
    [record, onHoursBlur]
  );

  return (
    <div className={classes.hoursRow}>
      {!editable && isNil(hours) ? (
        <NoValue />
      ) : (
        <>
          {editable ? (
            <CommonHours
              className={classes.editableHours}
              variant="outlined"
              margin="none"
              value={hours}
              fullWidth={false}
              isEditible={editable}
              disabled={isUpdating}
              onBlur={handleHoursBlur}
              onChange={handleOnChange}
              error={hasError(errors && errors[index], 'allocatedHours')}
            />
          ) : (
            <Decimal className={classes.text} value={hours} />
          )}
        </>
      )}
    </div>
  );
};

Hours.propTypes = {
  index: PropTypes.number,
  field: PropTypes.string,
  record: PropTypes.object,
  column: PropTypes.object,
  editable: PropTypes.bool
};

export default Hours;
