import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { BATCH_STATUS_QUERY } from '../../hooks/useBatchStatus';

export const BatchStatus = ({
  batchState: { batchId, batchInProgress },
  setBatchState,
  onBatchComplete,
  children
}) => {
  const { stopPolling, data: { getBatchStatus = {} } = {} } = useQuery(
    BATCH_STATUS_QUERY,
    {
      variables: {
        batchId
      },
      fetchPolicy: 'network-only',
      pollInterval: 500
    }
  );

  const { executionState } = getBatchStatus;

  const hasProcessed =
    executionState === 'SUCCEEDED' ||
    executionState === 'CANCELLED' ||
    executionState === 'FAILED';

  useEffect(() => {
    if (!hasProcessed) return;
    stopPolling();
    setBatchState({
      batchId: null,
      batchInProgress: false
    });
    onBatchComplete();
  }, [
    executionState,
    hasProcessed,
    onBatchComplete,
    setBatchState,
    stopPolling
  ]);

  return batchInProgress && children;
};
BatchStatus.propTypes = {
  batchState: PropTypes.object,
  setBatchState: PropTypes.func
};
export default BatchStatus;
