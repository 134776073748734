import React from 'react';
import PropTypes from 'prop-types';
import { DepartmentIcon } from '~/modules/common/components';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupIconField from './GroupIconField';

export const ResourceRequestDepartmentField = ({
  value,
  me: { legacyDepartmentDisabled },
  departmentSettings
}) =>
  legacyDepartmentDisabled && (
    <GroupIconField
      value={value}
      GroupIcon={DepartmentIcon}
      groupSettings={departmentSettings}
      labelKey="resourceRequestItem.department"
    />
  );

ResourceRequestDepartmentField.propTypes = {
  value: PropTypes.object,
  me: PropTypes.object,
  departmentSettings: PropTypes.object.isRequired
};
export default withGroupSettings(
  'department',
  'departmentSettings'
)(ResourceRequestDepartmentField);
