import {
  useSetListSort,
  useTableSettings
} from '~/modules/common/components/ListTable';
import { SortDirection } from '~/types';
import useProjectColumns from './useProjectColumns';

export const TABLE_KEY = 'psa-over-budget-projects';

const getSort = ({ currentSort }) => {
  if (!currentSort) {
    return {
      field: 'estimatedBudgetHoursRemaining',
      direction: SortDirection.Asc
    };
  }

  return {
    field: currentSort.field,
    direction: currentSort.direction
  };
};

export const useProjectListSort = showOverBudgetInList => {
  const { projectColumnNames } = useProjectColumns();

  const { data: tableData, loading: tableDataLoading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: projectColumnNames,
    skip: !showOverBudgetInList
  });
  const currentSort = tableData?.sort;

  const { onSortChange, loading: sortLoading } = useSetListSort({
    tableKey: TABLE_KEY,
    currentColumns: projectColumnNames,
    currentSort
  });

  return {
    sort: getSort({
      currentSort
    }),
    onSortChange,
    isLoading: tableDataLoading || sortLoading
  };
};
