import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { BillStatus } from '~/types';
import { outstandingBillSummaryQuery } from './totalsQuery';

const summaryType = ['BILL'];

export const getConsolidatedTypeFilter = (
  transactionTypes,
  summaryTransactionType
) =>
  (transactionTypes || []).filter(
    z => summaryTransactionType.indexOf(z) !== -1
  );

export const mapToServiceFilter = ({
  contextFilter,
  billingTransactionFilter,
  consolidatedTypeFilter,
  isOverdue,
  dueDate,
  billStatus
}) => ({
  projectUri: contextFilter.projectUri,
  clientUris: contextFilter.clientUri
    ? [contextFilter.clientUri]
    : billingTransactionFilter.clients,
  programUri: contextFilter.programUri,
  dateRange: billingTransactionFilter.dateRange,
  searchTerm: billingTransactionFilter.searchTerm,
  transactionTypes: consolidatedTypeFilter,
  balanceStatusList: billStatus ? null : ['UNALLOCATED', 'PARTIALLY_ALLOCATED'],
  isOverdue,
  dueDate,
  billStatus
});

export const getOutstandingQueryParameters = ({
  billingTransactionFilter,
  contextFilter,
  isPsaFpGraphqlOptimizationEnabled
}) => {
  const consolidatedTypeFilter =
    billingTransactionFilter.transactionTypes &&
    billingTransactionFilter.transactionTypes.length > 0
      ? getConsolidatedTypeFilter(
          billingTransactionFilter.transactionTypes,
          summaryType
        )
      : summaryType;

  return {
    variables: {
      filter: mapToServiceFilter({
        contextFilter,
        billingTransactionFilter,
        consolidatedTypeFilter,
        billStatus: [BillStatus.IssuedPartiallypaid, BillStatus.IssuedUnpaid]
      })
    },
    skip:
      isPsaFpGraphqlOptimizationEnabled || !consolidatedTypeFilter.length > 0,
    fetchPolicy: 'network-only'
  };
};

export const useOutstandingBillTabSummary = (
  billingTransactionFilter,
  contextFilter
) => {
  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });
  const param = getOutstandingQueryParameters({
    billingTransactionFilter,
    contextFilter,
    isPsaFpGraphqlOptimizationEnabled
  });
  const { data, loading } = useQuery(outstandingBillSummaryQuery, param);

  const outstandingBillSummary = get(data, 'getBillingTransactionTotals', {});

  return {
    loading,
    outstandingBillSummary
  };
};

export default useOutstandingBillTabSummary;
