import { useMemo } from 'react';
import { getSeriesDataMap } from '~/modules/common/enhancers';

export default ({
  periods,
  scale,
  totalActualRevenueAmountSeries,
  totalActualCostAmountSeries
}) =>
  useMemo(() => {
    const revenueSeriesMap = getSeriesDataMap({
      scale,
      rowData: { series: totalActualRevenueAmountSeries }
    }).seriesDataMap;
    const costSeriesMap = getSeriesDataMap({
      scale,
      rowData: { series: totalActualCostAmountSeries }
    }).seriesDataMap;

    const dataPointMap = periods.reduce(
      (retVal, current) => ({
        ...retVal,
        [current.key]:
          (revenueSeriesMap[current.key] || 0) -
          (costSeriesMap[current.key] || 0)
      }),
      {}
    );

    return {
      profitabilitySeriesDataMap: {
        ...dataPointMap,
        currencySymbol: costSeriesMap.currencySymbol
      }
    };
  }, [
    periods,
    scale,
    totalActualCostAmountSeries,
    totalActualRevenueAmountSeries
  ]);
