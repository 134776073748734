import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh'
  },
  container: {
    textAlign: 'center'
  },
  logo404: {
    width: '100%',
    maxWidth: '70%',
    marginBottom: theme.spacing(1)
  }
});

export default withStyles(styles);
