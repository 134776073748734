import { useContext } from 'react';

const useContextualFacets = facets =>
  facets.map(({ tag, context, ...rest }) => {
    // facets are expected to be provided as a static array and should guarantee order
    const {
      options,
      matchOption,
      selected,
      setSelected,
      suggestions
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useContext(context);

    return {
      ...rest,
      tag,
      shortTag: tag.slice(0, -1),
      context,
      options,
      matchOption,
      selected,
      setSelected,
      suggestions
    };
  }, {});

export default useContextualFacets;
