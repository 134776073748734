import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useUpdateResourceAllocationForRequest } from '~/modules/projects/resourcing-plan/hooks';
import { TimelineEditorOverlay } from '~/modules/resourcing/common/components';
import { ResourceRequestUserAllocationBlock2 } from '~/modules/resourcing/common/components/ResourceRequestUserAllocationBlock';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useResourceTimelineEditorChangeHandlers } from '~/modules/resourcing/common/hooks';
import RecallResourceRequestDialog from '~/modules/resourcing/common/components/RecallResourceRequestDialog';
import { useResourceRequestStatusHandlers } from '~/modules/resourcing/common/enhancers/requestStatusHandlers/useResourceRequestStatusHandlers';

import { useAvailabilityContext } from '~/modules/resourcing/common/hooks/useAvailabilityContext';
import { useDeleteResourceAllocation } from '~/modules/resourcing/hooks/useDeleteResourceAllocation';
import { useDialogState } from '~/modules/common/hooks';

export const ResourceRequestUserAllocationRow = ({
  chartDisplayDateRange,
  isEditable,
  resourceAllocation,
  resourceRequest,
  scale,
  chartDates,
  displayUnit,
  resourceAllocationIds
}) => {
  const [allocationPeriodEditTarget, setAllocationPeriodEditTarget] = useState(
    null
  );

  const { onPatchResourceAllocation } = useUpdateResourceAllocationForRequest();

  const { project } = useProjectContext();
  const deleteResourceAllocationFromProject = useDeleteResourceAllocation({
    projectUri: project.id,
    resourceRequestId: resourceRequest.id
  });

  const { setAvailabilityLoading } = useAvailabilityContext();

  const {
    open: showRecallResourcesDialog,
    openDialog: openRecallResourcesDialog,
    closeDialog: closeRecallResourcesDialog
  } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const onPeriodClose = useCallback(
    (evt, originalAllocation) => {
      setAvailabilityLoading();

      onPatchResourceAllocation({
        allocation: {
          ...evt.values,
          requestedRoleUri: resourceRequest.role?.id
        },
        deleteResourceAllocationFromProject,
        openRecallResourcesDialog,
        originalAllocation,
        project
      });
    },
    [
      deleteResourceAllocationFromProject,
      onPatchResourceAllocation,
      openRecallResourcesDialog,
      project,
      resourceRequest.role?.id,
      setAvailabilityLoading
    ]
  );

  const {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  } = useResourceTimelineEditorChangeHandlers({
    allocationPeriodEditTarget,
    chartDates,
    isPercentageMode: displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE,
    setAllocationPeriodEditTarget,
    resourceAllocation
  });

  const {
    onMarkResourceRequestAsSubmitted
  } = useResourceRequestStatusHandlers({ resourceRequest });

  return (
    <>
      <ResourceRequestUserAllocationBlock2
        allocation={resourceAllocation}
        chartDisplayDateRange={chartDisplayDateRange}
        handleAllocationPeriodClick={handleAllocationPeriodClick}
        isEditable={isEditable}
        onAllocationChange={onPeriodClose}
        resourceRequest={resourceRequest}
        scale={scale}
      />
      {isEditable && allocationPeriodEditTarget?.anchorEl && (
        <TimelineEditorOverlay
          allocationPeriodEditTarget={allocationPeriodEditTarget}
          chartDisplayDateRange={chartDisplayDateRange}
          handleAllocationPeriodClose={handleAllocationPeriodClose}
          onChange={onPeriodClose}
          resourceAllocation={resourceAllocation}
          setNextPeriod={setNextPeriod}
          setPreviousPeriod={setPreviousPeriod}
          isPersistedAllocation
        />
      )}

      {showRecallResourcesDialog && (
        <RecallResourceRequestDialog
          resourceRequest={resourceRequest}
          open={showRecallResourcesDialog}
          onCancelClick={closeRecallResourcesDialog}
          onMarkResourceRequestAsSubmitted={onMarkResourceRequestAsSubmitted}
          allocationId={resourceAllocation.id}
          resourceAllocationIds={resourceAllocationIds}
        />
      )}
    </>
  );
};

ResourceRequestUserAllocationRow.propTypes = {
  scale: PropTypes.string.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  chartDates: PropTypes.array.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  displayUnit: PropTypes.string,
  resourceAllocation: PropTypes.object,
  resourceAllocationIds: PropTypes.array
};

export default ResourceRequestUserAllocationRow;
