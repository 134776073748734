import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Date from '~/modules/common/components/Date';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    padding: theme.spacing(0.5, 0, 0, 0)
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  },
  date: {
    fontSize: theme.typography.body1.fontSize,
    paddingTop: theme.spacing(0.2)
  },
  noValue: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(0.2)
  }
}));

const ReadOnlyDateField = ({ className, label, dateValue }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      {label && <div className={classes.label}>{label}</div>}

      {dateValue ? (
        <div>
          <Date value={dateValue} className={classes.date} />
        </div>
      ) : (
        <div className={classes.noValue}>
          <FormattedMessage id="rescheduleTaskDialog.noDateSet" />
        </div>
      )}
    </div>
  );
};

ReadOnlyDateField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.object,
  dateValue: PropTypes.object
};
export default ReadOnlyDateField;
