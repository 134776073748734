import sortBy from 'lodash/fp/sortBy';
import uniqBy from 'lodash/fp/uniqBy';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const ELIGIBLE_CLIENT_MANAGERS_QUERY = gql`
  query EligibleClientManagers($clientId: String!) {
    eligibleClientManagers(clientId: $clientId) {
      id
      displayText
    }
  }
`;

export const useEligibleClientManagersQuery = ({
  clientId,
  currentClientManager
}) => {
  const { data, loading } = useQuery(ELIGIBLE_CLIENT_MANAGERS_QUERY, {
    variables: {
      clientId
    }
  });

  const defaultClientManagers = currentClientManager
    ? [currentClientManager]
    : [];

  const eligibleClientManagers = (data && data.eligibleClientManagers) || [];
  const clientManagers = sortBy(
    cm => cm.displayText,
    uniqBy('id', eligibleClientManagers.concat(defaultClientManagers))
  );

  return { clientManagers, loading };
};
