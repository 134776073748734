export const dialogContentForDeletion = {
  title: 'vendor.removeDialog.title',
  content: 'vendor.removeDialog.content',
  action: 'vendor.removeDialog.action',
  cancel: 'vendor.removeDialog.cancel'
};

export const dialogContentForDisable = {
  title: 'vendor.disableDialog.title',
  content: 'vendor.disableDialog.content',
  secondaryContent: 'vendor.disableDialog.secondaryContent',
  action: 'vendor.disableDialog.action',
  cancel: 'vendor.disableDialog.cancel'
};

export default ({ canBeDeleted }) =>
  canBeDeleted ? dialogContentForDeletion : dialogContentForDisable;
