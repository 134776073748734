export const transactionRefetchQueries1 = [
  'getPageOfBillingTransactions',
  'getBillingTransactionTotals',
  'getBillingTransactionSummary'
];

export const transactionRefetchQueries2 = [
  'getPageOfBillingTransactions',
  'getBillingTransactionTotals',
  'getDraftTotals',
  'getIssuedBillSummary',
  'getOutstandingBillSummary',
  'getOverdueBillSummary',
  'getUnallocatedBillSummary'
];
