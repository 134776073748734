import { useCallback } from 'react';
import { ESTIMATED_HOURS_MAX } from '~/modules/common/components/TaskDrawer/EditTask/constants';

export const useOnChangeHandlers = ({
  hours,
  onEstimatedHoursChange,
  setAnchorEl,
  setHours
}) => {
  const handleOnBlur = useCallback(() => {
    onEstimatedHoursChange(hours);
    setAnchorEl(null);
  }, [hours, onEstimatedHoursChange, setAnchorEl]);

  const handleHoursKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleOnBlur();
      }
    },
    [handleOnBlur]
  );

  return {
    onEstimatedHoursClick: useCallback(
      event => {
        setAnchorEl(event.target);
      },
      [setAnchorEl]
    ),
    handleChange: useCallback(
      event => {
        setHours(
          Math.min(ESTIMATED_HOURS_MAX, parseFloat(event.target?.value) || 0)
        );
      },
      [setHours]
    ),
    handleOnBlur,
    handleHoursKeyPress
  };
};

export default useOnChangeHandlers;
