import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { DonutChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';
import DonutChart from '../DonutChart';
import useProjectStatusConfig from '../DonutChart/enhancers';
import ProjectStatusChartHeader from './ProjectStatusChartHeader';

const stagesTitle = <FormattedMessage id="dashboardOverviewChart.stages" />;

export const ProjectStatusChart = ({
  classes,
  chartDataInput,
  showHeader = false,
  overrideChartStyle,
  legendClickHandlers,
  events
}) => {
  const {
    featureFlags: { isPsaPrpRenameProjectStatusEnabled }
  } = useMeContext();

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const { projectsStatusCountSummary, isLoading } = chartDataInput;
  const {
    colorScale,
    legends,
    dataPoints,
    mobileChartStyle
  } = useProjectStatusConfig({
    chartDataInput,
    isCustomStatusNameEnabled,
    customStatusMapping
  });

  const intl = useIntl();

  const isMobile = useIsBreakpointDown('md');
  const chartStyle = useMemo(() => ({ ...overrideChartStyle }), [
    overrideChartStyle
  ]);

  return isLoading ? (
    <DonutChartLoading />
  ) : (
    <>
      {showHeader && <ProjectStatusChartHeader title={stagesTitle} />}
      <DonutChart
        classes={classes}
        dataPoints={dataPoints}
        colorScale={colorScale}
        legends={legends}
        chartTitle={
          projectsStatusCountSummary && projectsStatusCountSummary.total
        }
        chartValue={intl.formatMessage({
          id: 'programPage.projectsTotal'
        })}
        overrideChartConfig={isMobile ? mobileChartStyle : chartStyle}
        showNoDataCircle={
          projectsStatusCountSummary && projectsStatusCountSummary.total === 0
        }
        events={events}
        legendClickHandlers={legendClickHandlers}
      />
    </>
  );
};

ProjectStatusChart.propTypes = {
  classes: PropTypes.object,
  chartDataInput: PropTypes.object,
  showHeader: PropTypes.bool,
  overrideChartStyle: PropTypes.object,
  legendClickHandlers: PropTypes.object,
  events: PropTypes.array
};

export default ProjectStatusChart;
