import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { ResourceRequestStatusChip } from '~/modules/resourcing/common/components';
import GroupItems from '~/modules/projects/resourcing-plan/components/GroupItems';
import { getResourceRequestDeltaTotals } from '~/modules/projects/resourcing-plan/enhancers/withResourceRequestDeltaTotals';
import { getResourceRequestTotal } from '~/modules/resourcing/common/enhancers/withResourceRequestTotal';
import { getShowAllocationTotalForResourceRequest } from '~/modules/resourcing/common/enhancers/withShowAllocationTotalForResourceRequest';
import getAllocationsTotalForResourceRequest from '~/modules/resourcing/hooks/getAllocationsTotalForResourceRequest';
import {
  CostCenterName,
  DepartmentName,
  DivisionName,
  ServiceCenterName,
  EmployeeTypeName,
  LocationName,
  User
} from '~/modules/common/components';
import ResourceRequestIcon from '~/modules/common/components/Icons/Objects/ResourceRequestIcon';
import useMeContext from '~/modules/me/useMeContext';
import EntityAvatar from '~/modules/common/components/Avatars/EntityAvatar';
import { generateSequence } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import {
  MobileResourceRequestTitle,
  ResourceRequestCardCost,
  ResourceRequestCardHours,
  SkillsIndicator,
  CommentsIndicator,
  TagsIndicator,
  ResourcePoolIndicator,
  PreferredResourceIndicator
} from './components';
import useStyles, {
  topContainerStyles,
  bottomContainerStyles,
  spacingItemStyles,
  roleItemStyles,
  statusItemStyles
} from './useStyles';

export const GroupNameComponentsMap = {
  costCenter: CostCenterName,
  department: DepartmentName,
  division: DivisionName,
  employeeType: EmployeeTypeName,
  location: LocationName,
  serviceCenter: ServiceCenterName,
  skills: SkillsIndicator,
  comment: CommentsIndicator,
  tags: TagsIndicator,
  resourcePools: ResourcePoolIndicator,
  preferredResources: PreferredResourceIndicator
};

export const ResourceRequestCard = ({
  resourceRequest,
  contextCurrency,
  resourceAllocations
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const topContainerClasses = topContainerStyles();
  const bottomContainerClasses = bottomContainerStyles();
  const spacingItemClasses = spacingItemStyles();
  const roleItemClasses = roleItemStyles();
  const statusItemClasses = statusItemStyles();
  const {
    noAllocatedQuantity,
    requestedHours,
    requestedCost,
    showAllocationTotalForResourceRequest,
    allocatedHours,
    allocatedCost,
    deltaTotalCost,
    deltaTotalHours
  } = useMemo(() => {
    const {
      resourceRequestTotalHours,
      resourceRequestTotalCost
    } = getResourceRequestTotal(resourceRequest);

    const {
      totalAllocatedHoursForResourceRequest,
      totalAllocatedCostForResourceRequest
    } = getAllocationsTotalForResourceRequest({
      me,
      resourceAllocations,
      contextCurrency
    });

    const {
      deltaTotalCost: cost,
      deltaTotalHours: hours
    } = getResourceRequestDeltaTotals({
      resourceRequestTotalHours,
      resourceRequestTotalCost,
      totalAllocatedHoursForResourceRequest,
      totalAllocatedCostForResourceRequest
    });

    return {
      noAllocatedQuantity:
        resourceRequest.quantity - resourceAllocations.length || 0,
      requestedHours: resourceRequestTotalHours,
      requestedCost: resourceRequestTotalCost,
      deltaTotalCost: cost,
      deltaTotalHours: hours,
      allocatedHours: totalAllocatedHoursForResourceRequest,
      allocatedCost: totalAllocatedCostForResourceRequest,
      ...getShowAllocationTotalForResourceRequest({ resourceRequest })
    };
  }, [contextCurrency, resourceAllocations, me, resourceRequest]);

  const groupItemsMap = useMemo(
    () =>
      [
        'preferredResources',
        'skills',
        'costCenter',
        'department',
        'division',
        'employeeType',
        'location',
        'serviceCenter',
        'tags',
        'comment',
        'resourcePools'
      ].reduce((acc, key) => {
        const GroupNameComponent = GroupNameComponentsMap[key];

        return {
          ...acc,
          ...(resourceRequest[key] &&
            (([
              'skills',
              'tags',
              'comment',
              'preferredResources',
              'resourcePools'
            ].includes(key) &&
              resourceRequest[key].length !== 0) ||
              (me.groupSettings[key] && me.groupSettings[key].isEnabled)) && {
              [`${key}`]: {
                value: resourceRequest[key],
                Component: <GroupNameComponent value={resourceRequest[key]} />
              }
            })
        };
      }, {}),
    [resourceRequest, me.groupSettings]
  );

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid container classes={topContainerClasses} item>
        <Grid item classes={roleItemClasses}>
          <EntityAvatar className={classes.resquestAvatar}>
            <ResourceRequestIcon />
          </EntityAvatar>
          <MobileResourceRequestTitle
            resourceRequest={resourceRequest}
            resourceAllocations={resourceAllocations}
          />
        </Grid>
        <Grid item classes={statusItemClasses}>
          <ResourceRequestStatusChip status={resourceRequest.requestStatus} />
        </Grid>
      </Grid>
      {Boolean(Object.keys(groupItemsMap).length) && (
        <GroupItems itemsMap={groupItemsMap} />
      )}
      {resourceRequest.requestStatus === ResourceRequestStatus.Tentative && (
        <Grid
          container
          item
          className={classes.resourceAllocationContainer}
          spacing={1}
        >
          {resourceAllocations.length > 0 &&
            resourceAllocations.map(allocation => (
              <Grid
                key={allocation.id}
                item
                xs={6}
                className={classes.resourceAllocationItem}
              >
                <User user={allocation.user.user} />
              </Grid>
            ))}

          {generateSequence(noAllocatedQuantity).map(() => (
            <Grid
              key={uuidv4()}
              item
              xs={6}
              className={classes.noResourceAllocationItem}
            >
              <FormattedMessage id="mobileResourcePlanning.noResourceAllocation" />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid container item classes={bottomContainerClasses}>
        <Grid item>
          <ResourceRequestCardHours
            showAllocationTotals={showAllocationTotalForResourceRequest}
            resourceRequestHours={requestedHours}
            allocatedHours={allocatedHours}
            deltaHours={deltaTotalHours}
          />
        </Grid>
        <Grid item classes={spacingItemClasses}>
          <ResourceRequestCardCost
            resourceRequest={resourceRequest}
            showAllocationTotals={showAllocationTotalForResourceRequest}
            resourceRequestCost={requestedCost}
            resourceAllocatedCost={allocatedCost}
            deltaCost={deltaTotalCost}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ResourceRequestCard.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  contextCurrency: PropTypes.object,
  resourceAllocations: PropTypes.array
};

export default ResourceRequestCard;
