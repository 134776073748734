import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { totalWidth } from '~/modules/common/charts/timeline/calculations';
import StyledChartRangeItem2 from '../ChartRangeItem2/StyledChartRangeItem2';
import HeaderTimeItems from './components/HeaderTimeItems';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    paddingLeft: 16
  },
  totalContainer: {},
  root: {
    position: 'sticky',
    top: theme.spacing(7),
    zIndex: 10
  }
}));

export const ChartHeader = ({
  classes: classesOverrides,
  headerTimeClasses,
  leftComponent,
  rightComponent,
  scale,
  chartDates,
  leftComponentWidth,
  rightComponentWidth = totalWidth
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const timeItems = useMemo(
    () => (
      <HeaderTimeItems
        scale={scale}
        chartDates={chartDates}
        classes={headerTimeClasses}
      />
    ),
    [scale, chartDates, headerTimeClasses]
  );

  return (
    <>
      <StyledChartRangeItem2
        classes={classes}
        leftComponentWidth={leftComponentWidth}
        scale={scale}
        rightComponentWidth={rightComponentWidth}
        scaleItemCount={chartDates.length}
        chartStartDate={chartDates.length && chartDates[0].start}
      >
        {leftComponent}
        {timeItems}
        {rightComponent}
      </StyledChartRangeItem2>
    </>
  );
};

ChartHeader.propTypes = {
  classes: PropTypes.object,
  headerTimeClasses: PropTypes.object,
  leftComponent: PropTypes.node.isRequired,
  chartDates: PropTypes.array,
  scale: PropTypes.string.isRequired,
  rightComponent: PropTypes.node,
  leftComponentWidth: PropTypes.number.isRequired,
  rightComponentWidth: PropTypes.number
};

export default ChartHeader;
