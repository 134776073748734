import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ALLOCATION_PERIOD_OVERLAP_TYPE } from '~/modules/resourcing/common/enums';
import { OverlayBlockLabel } from '~/modules/resourcing/common/chart/components';
import ResourceRequestUserAllocationPeriodOverlayTooltip from '../ResourceRequestUserAllocationPeriodOverlayTooltip';

const useStyles = makeStyles(theme => ({
  overlay: {
    zIndex: 2,
    position: 'absolute',
    height: theme.spacing(3),
    marginTop: theme.spacing(-1)
  },
  overlayBorder: {
    boxShadow: `0px 1px 0px #e0e0e0, 0px -1px 0px #e0e0e0`
  }
}));

const ResourceRequestUserAllocationPeriodOverlayReadOnly = ({
  overlayPeriod,
  allocation,
  scale,
  resourceRequest = {}
}) => {
  const {
    allocationPeriodOverlapType,
    totalHours,
    totalCost,
    startDate,
    endDate,
    percentage,
    dynamicPosition,
    totalScheduleHours
  } = overlayPeriod;

  const classes = useStyles();

  return (
    <ResourceRequestUserAllocationPeriodOverlayTooltip
      showTooltip
      totalHours={totalHours}
      totalCost={totalCost}
      startDate={startDate}
      endDate={endDate}
      percentage={percentage}
      resourceRequest={resourceRequest}
    >
      <div
        data-qe-id={`${allocation.user.user.displayText}_resourceRequestAllocationOverlay`}
        className={classNames({
          [classes.overlay]: true,
          [classes.overlayBorder]:
            allocationPeriodOverlapType ===
            ALLOCATION_PERIOD_OVERLAP_TYPE.PARTIAL
        })}
        style={dynamicPosition}
      >
        <OverlayBlockLabel
          hours={totalHours}
          percentage={percentage}
          showPercentage={totalScheduleHours > 0}
          scale={scale}
        />
      </div>
    </ResourceRequestUserAllocationPeriodOverlayTooltip>
  );
};

ResourceRequestUserAllocationPeriodOverlayReadOnly.propTypes = {
  scale: PropTypes.string,
  allocation: PropTypes.object,
  resourceRequest: PropTypes.object,
  overlayPeriod: PropTypes.object.isRequired
};

export default ResourceRequestUserAllocationPeriodOverlayReadOnly;
