import React from 'react';
import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  enabled: {
    color: theme.palette.success.dark,
    fontWeight: 'bold'
  },
  notEnabled: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
}));

export const ImportExportMenuItemLabel = ({
  primaryIcon = null,
  label,
  secondaryLabel,
  secondaryIcon = null,
  isSFConnected
}) => {
  const classes = useStyles();

  const hasPrimaryIcon = primaryIcon ? (
    <ListItemIcon>{primaryIcon}</ListItemIcon>
  ) : null;

  const hasSecondaryIcon = secondaryIcon ? (
    <ListItemSecondaryAction>{secondaryIcon}</ListItemSecondaryAction>
  ) : null;

  const hasSecondaryLabel = secondaryLabel ? (
    <FormattedMessage id={`${secondaryLabel}`} />
  ) : null;

  const sfIsEnabledClassName = isSFConnected
    ? classes.enabled
    : classes.notEnabled;

  return (
    <>
      {hasPrimaryIcon}
      <ListItemText
        primary={<FormattedMessage id={`${label}`} />}
        secondary={hasSecondaryLabel}
        secondaryTypographyProps={{ className: sfIsEnabledClassName }}
      />
      {hasSecondaryIcon}
    </>
  );
};

ImportExportMenuItemLabel.propTypes = {
  primaryIcon: PropTypes.node,
  label: PropTypes.node.isRequired,
  secondaryLabel: PropTypes.node,
  secondaryIcon: PropTypes.node,
  isSFConnected: PropTypes.bool
};

export default ImportExportMenuItemLabel;
