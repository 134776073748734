import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'visible'
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.black
  },
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    margin: 0
  },
  cardHeader: {},
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(1)
    }
  }
}));

export const OverviewItemCard = ({
  classes: classesOverride,
  title,
  subtitle,
  avatar,
  action,
  children,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });
  const cardHeaderClasses = useMemo(
    () => ({
      root: classes.cardHeader,
      title: classes.title,
      subheader: classes.subtitle,
      action: classes.action,
      avatar: classes.avatar
    }),
    [
      classes.avatar,
      classes.action,
      classes.cardHeader,
      classes.subtitle,
      classes.title
    ]
  );

  return (
    <Card className={classes.root} data-qe-id={`Card_${dataQeId}`}>
      <CardHeader
        data-qe-id="CardHeader"
        classes={cardHeaderClasses}
        title={title}
        subheader={subtitle}
        avatar={avatar}
        action={action}
      />
      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
};

OverviewItemCard.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  avatar: PropTypes.node,
  action: PropTypes.node,
  children: PropTypes.node,
  dataQeId: PropTypes.string
};

export default OverviewItemCard;
