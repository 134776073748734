import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const resourceRequestsSeriesDataQuery = gql`
  query Eager_GetResourceRequestsSeriesData(
    $projectFilter: ProjectFilter
    $dateRanges: [DateRangeInput]
  ) {
    resourceRequestsSeriesData(
      projectFilter: $projectFilter
      dateRanges: $dateRanges
    ) {
      draft {
        requestedResourcesCount
      }
      pending {
        requestedResourcesCount
      }
      proposed {
        requestedResourcesCount
      }
      rejected {
        requestedResourcesCount
      }
      toBeHired {
        requestedResourcesCount
      }
      completed {
        requestedResourcesCount
      }
    }
  }
`;

export const useResourceRequestsSeriesData = ({
  projectFilter,
  dateRanges
}) => {
  const { data, loading, error } = useQuery(resourceRequestsSeriesDataQuery, {
    variables: {
      projectFilter,
      dateRanges
    },
    fetchPolicy: 'network-only'
  });

  const resourceRequestsSeriesData = data?.resourceRequestsSeriesData || [];

  return {
    loading,
    resourceRequestsSeriesData,
    error
  };
};

export default useResourceRequestsSeriesData;
