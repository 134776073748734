import { useMeContext } from '~/modules/me/useMeContext';
import {
  PROJECT_DAL,
  PROJECT_PERMISSION,
  USER_ACCESS_ROLE
} from '~/modules/common/enums';

export const useCopyTaskPermissions = () => {
  const { permissionsMap, userAccessRoles } = useMeContext();
  const editTaskPermission = permissionsMap[PROJECT_PERMISSION.EDIT_TASK];

  return (
    Boolean(
      editTaskPermission &&
        editTaskPermission.dataAccessLevelUris.some(
          dal =>
            dal === PROJECT_DAL.PROJECT_MANAGER ||
            dal === PROJECT_DAL.PROJECT_ADMIN
        )
    ) ||
    Boolean(
      userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_ADMIN) &&
        editTaskPermission &&
        editTaskPermission.dataAccessLevelUris.includes(PROJECT_DAL.ALL)
    )
  );
};
