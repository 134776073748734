import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const projectHoursQuery = gql`
  query GetProjectHoursSeries(
    $projectId: String!
    $dateRange: DateTimeRangeInput!
  ) {
    project(projectId: $projectId) {
      id
      hourDetails(dateRange: $dateRange) {
        period {
          periodStart: periodStart2
          periodEnd: periodEnd2
        }
        timeEnteredActual
        timeEnteredEstimated
      }
    }
  }
`;

export const useProjectHours = ({
  chartDataInput: { projectId, dateRange }
}) => {
  const { loading, data } = useQuery(projectHoursQuery, {
    variables: { projectId, dateRange },
    fetchPolicy: 'cache-and-network'
  });

  return {
    hourSeriesData: !loading ? data.project?.hourDetails : [],
    loading
  };
};

export default useProjectHours;
