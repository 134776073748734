import { useCallback } from 'react';

export const useFieldHandlers = ({ setSelected, selected, options }) => {
  const onChange = useCallback(
    ({ target: { name, checked } } = {}) =>
      checked
        ? setSelected([
            ...selected,
            ...options.filter(({ value }) => name === value)
          ])
        : setSelected(selected.filter(({ value }) => name !== value)),
    [options, selected, setSelected]
  );

  const isSelected = useCallback(
    option => selected.some(c => c.value === option.value),
    [selected]
  );

  return { onChange, isSelected };
};
