import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const KPIIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12 4 A 10 10 0 0 0 2 14 A 10 10 0 0 0 3.35546875 19 L 20.650390625 19 A 10 10 0 0 0 22 14 A 10 10 0 0 0 19.068359375 6.935546875 L 13.419921875 15.41015625 A 2 2 0 0 1 10.58984375 15.41015625 A 2 2 0 0 1 10.58984375 12.580078125 L 19.064453125 6.9296875 A 10 10 0 0 0 12 4 z " />
    </>
  </SvgIcon>
));

export default KPIIcon;
