import { DialogContentText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';

const removeResourceKeys = {
  header: 'resourceAllocationActions.removeAllResourceAllocations',
  actionButton: 'resourceAllocationActions.removeAllResourcesAction'
};

export const ResourceAllocationDeleteActions = ({
  showRemoveAllocationDialog,
  closeRemoveAllocationDialog,
  onDeleteAllocationClick
}) =>
  showRemoveAllocationDialog && (
    <RemoveEntityConfirmationDialog
      resourceKeys={removeResourceKeys}
      open={showRemoveAllocationDialog}
      onClose={closeRemoveAllocationDialog}
      onConfirm={onDeleteAllocationClick}
    >
      <DialogContentText>
        <FormattedMessage id="resourceAllocationActions.removeAllResourceAllocationContent" />
      </DialogContentText>
    </RemoveEntityConfirmationDialog>
  );

ResourceAllocationDeleteActions.propTypes = {
  onDeleteAllocationClick: PropTypes.func.isRequired,
  showRemoveAllocationDialog: PropTypes.bool.isRequired,
  closeRemoveAllocationDialog: PropTypes.func.isRequired
};

export default ResourceAllocationDeleteActions;
