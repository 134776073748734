import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const earnedRevenueRecalculateQuery = gql`
  mutation recalculateEarnedRevenue($projectId: String!) {
    recalculateProjectEarnedRevenue(projectId: $projectId) {
      batchUri
    }
  }
`;

export const useCreateEarnedRevenueRecalculationBatch = ({
  projectId,
  setRecalculationBatchState
}) => {
  const [recalculateEarnedRevenue] = useMutation(earnedRevenueRecalculateQuery);

  return {
    recalculateEarnedRevenue: () => {
      recalculateEarnedRevenue({
        variables: {
          projectId
        },
        update: (
          proxy,
          {
            data: {
              recalculateProjectEarnedRevenue: { batchUri }
            }
          }
        ) => {
          setRecalculationBatchState({
            batchId: batchUri,
            batchInProgress: true
          });
        }
      });
    }
  };
};

export default useCreateEarnedRevenueRecalculationBatch;
