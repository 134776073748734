import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import Decimal from '~/modules/common/components/Decimal';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { MoneyValue } from '~/modules/common/components/Money';
import { useStyles as usePillStyles } from '~/modules/common/components/TaskVariationPill/hooks';
import {
  TaskVariationPill,
  TaskVariationDatePill
} from '~/modules/common/components/TaskVariationPill';
import { useDialogState, useMenuState } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import ChildInfoDialog from '../Table/components/ChildInfoDialog/ChildInfoDialog';
import NotStartedVariationValue from '../NotStartedVariationValue';

const usePillStylesOverride = makeStyles({
  variationPill: {
    padding: theme.spacing(0.375, 1, 0.125),
    borderRadius: theme.spacing(0.5)
  },
  variationText: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary
  },
  variationValue: ({ isVariationInBold }) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: isVariationInBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight
  }),
  variationLabel: {
    display: 'none'
  }
});

const useDatePillStylesOverride = makeStyles({
  variationLabel: {
    fontSize: '0.8125rem'
  }
});

const useStyles = makeStyles({
  variationPillContainer: {
    display: 'flex'
  },
  data: ({ isVariationInBold }) => ({
    ...theme.typography.body2,
    fontWeight: isVariationInBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight,
    color: theme.palette.text.secondary,
    flexBasis: 'unset'
  }),
  icon: {
    cursor: 'pointer',
    marginTop: '1px',
    marginLeft: theme.spacing(1.75),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.25)
  },
  na: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  }
});

const VariationCell = ({
  variation,
  hideTooltip = false,
  isChild,
  isNotStarted,
  selectedColumns,
  variant,
  variationStatus,
  hasMore,
  loadMore,
  loadingMore,
  childTasks
}) => {
  const {
    featureFlags: { isPsaPpmCostEacEnhancementsEnabled }
  } = useMeContext();
  const intl = useIntl();
  const { open, openDialog, closeDialog } = useDialogState();

  const isVariationInBold =
    (variant === 'hours' && (variation > 0 || variation < 0)) ||
    (variant === 'cost' && (variation?.amount > 0 || variation?.amount < 0));

  const pillClassesOverride = usePillStylesOverride({ isVariationInBold });
  const pillClasses = usePillStyles({
    classes: pillClassesOverride,
    isPsaPpmCostEacEnhancementsEnabled
  });
  const datePillClasses = useDatePillStylesOverride();
  const classes = useStyles({ isVariationInBold });

  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();

  const handleClick = useCallback(
    event => {
      onMenuClick(event);
      openDialog();
    },
    [onMenuClick, openDialog]
  );

  const hoursHandleClosePopover = useCallback(() => {
    onMenuClose();
    closeDialog();
  }, [onMenuClose, closeDialog]);

  const shouldRenderNoData =
    variant === 'dates'
      ? (isNotStarted && isChild) || (isChild && variation === null)
      : variation == null;

  return (
    <>
      {shouldRenderNoData ? (
        <Typography className={classes.na}>
          <FormattedMessage id="taskDrawer.na" />
        </Typography>
      ) : isNotStarted && isChild ? (
        isPsaPpmCostEacEnhancementsEnabled ? (
          <NotStartedVariationValue
            className={classes.data}
            variant={variant}
            variation={variation}
            variationStatus={variationStatus}
          />
        ) : variant === 'hours' ? (
          <Decimal
            className={classes.data}
            value={variation}
            fixedDecimalScale
          />
        ) : (
          <MoneyValue className={classes.data} money={variation} />
        )
      ) : (
        <div className={classes.variationPillContainer}>
          {variant === 'dates' ? (
            <TaskVariationDatePill
              value={variation}
              classes={datePillClasses}
              isPsaPpmCostEacEnhancementsEnabled={
                isPsaPpmCostEacEnhancementsEnabled
              }
            />
          ) : (
            <TaskVariationPill
              intl={intl}
              classes={pillClasses}
              variationStatus={variationStatus}
              value={variation}
              displayType="text"
              fixedDecimalScale
              hideStatusMessage
              variant={variant}
            />
          )}
          {!hideTooltip && childTasks?.length > 0 && (
            <>
              <InfoSharpIcon className={classes.icon} onClick={handleClick} />
              <ChildInfoDialog
                childTasks={childTasks}
                selectedColumns={selectedColumns}
                open={open}
                onClose={hoursHandleClosePopover}
                anchorEl={anchorEl}
                hasMore={hasMore}
                loadMore={loadMore}
                loadingMore={loadingMore}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

VariationCell.propTypes = {
  variation: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ]),
  hideTooltip: PropTypes.bool,
  isChild: PropTypes.bool,
  isNotStarted: PropTypes.bool,
  selectedColumns: PropTypes.array,
  variant: PropTypes.oneOf(['cost', 'hours', 'dates']),
  variationStatus: PropTypes.string,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  childTasks: PropTypes.array
};

export default VariationCell;
