import { Grid, Typography, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { DateRange } from '~/modules/common/components';
import User from '~/modules/common/components/User';
import WorkflowBadge from '~/modules/projects/project/WorkflowBadge';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { MobileListItem } from '~/modules/common/components/ListTable';
import { useOnListItemClick } from './useOnListItemClick';

const useHeaderStyles = makeStyles(theme => ({
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(1.5)
  },
  statusChip: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-start'
  }
}));

const useStyles = makeStyles(theme => ({
  selection: {
    marginTop: theme.spacing(0.5)
  }
}));

export const SecondaryItem = ({ record }) => (
  <>
    <DateRange start={record.startDate} end={record.endDate} />
    {record.projectManagerReference && (
      <User user={record.projectManagerReference} />
    )}
  </>
);

SecondaryItem.propTypes = {
  record: PropTypes.object.isRequired
};

export const MobileProjectListItemHeader = ({ record }) => {
  const classes = useHeaderStyles();

  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid
        item
        xs={isPsaPrpRenameProjectStatusEnabled ? 7 : 9}
        sm={isPsaPrpRenameProjectStatusEnabled ? 10 : 11}
      >
        <Typography className={classes.name}>{record.name}</Typography>
      </Grid>
      <Grid
        item
        className={classes.statusChip}
        xs={isPsaPrpRenameProjectStatusEnabled ? 5 : 3}
        sm={isPsaPrpRenameProjectStatusEnabled ? 2 : 1}
      >
        <WorkflowBadge
          workflowId={record.status.uri}
          customStatusLabel={record.status.displayText}
        />
      </Grid>
    </Grid>
  );
};

MobileProjectListItemHeader.propTypes = {
  record: PropTypes.object.isRequired
};

export const MobileProjectListItem = ({
  record,
  isSelected,
  selectionMode,
  onRowSelectionChange
}) => {
  const classes = useStyles();
  const onClick = useOnListItemClick(record);

  return (
    <MobileListItem
      classes={classes}
      record={record}
      onClick={onClick}
      isSelected={isSelected}
      selectionMode={selectionMode}
      onRowSelectionChange={onRowSelectionChange}
    >
      <Grid container direction="column" wrap="nowrap" square elevation={0}>
        <Grid item>
          <MobileProjectListItemHeader record={record} />
          <SecondaryItem record={record} />
        </Grid>
      </Grid>
    </MobileListItem>
  );
};

MobileProjectListItem.propTypes = {
  record: PropTypes.object.isRequired,
  onRowSelectionChange: PropTypes.func,
  isSelected: PropTypes.bool,
  selectionMode: PropTypes.bool
};

export default MobileProjectListItem;
