import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { SortDirection } from '~/types';
import { ChartHeader } from '~/modules/common/charts/timeline/components';
import { useProjectContext } from '~/modules/resourcing/common/contexts';

import {
  itemWidth,
  actionsWidth
} from '~/modules/common/charts/timeline/calculations';
import { RESOURCING_ALLOCATION_MODEL } from '~/modules/common/enums';
import { HeaderContentModel0, HeaderContentModel1 } from './components';

const useContentStyles = makeStyles(theme => ({
  tabelCell: {
    minWidth: theme.spacing(31.5),
    alignSelf: 'stretch',
    marginLeft: theme.spacing(-0.75)
  },
  role: {
    minWidth: theme.spacing(4.3)
  }
}));

const useStyles = makeStyles(theme => ({
  total: {
    width: theme.spacing(10),
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0.5, 0),
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    textAlign: 'right'
  }
}));

const useChartStyles = makeStyles(theme => ({
  root: {
    top: theme.spacing(24),
    zIndex: 21
  }
}));

const usePaginationStyles = makeStyles(theme => ({
  caption: {
    paddingBottom: theme.spacing(0.5)
  },
  root: {
    minWidth: theme.spacing(21.125),
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2)
  },
  backButton: {
    margin: theme.spacing(-2, 0, -1.5, 1.5),
    padding: theme.spacing(0)
  },
  nextButton: {
    margin: theme.spacing(-2, -1.5, -1.5, 0),
    padding: theme.spacing(0)
  }
}));
const resourceSortField = 'userName';
const leftComponentWidth = actionsWidth + itemWidth * 2;

export const ResourceAllocationChartHeader = ({
  chartDates,
  currentPage,
  currentPageRowCount,
  loadingUsers,
  pageSize,
  resetPagination,
  scale,
  setCurrentPage,
  setSort,
  sort
}) => {
  const contentClasses = useContentStyles();
  const classes = useStyles();
  const paginationClasses = usePaginationStyles();
  const { resourcingAllocationModel } = useProjectContext();
  const chartClasses = useChartStyles();

  const onSortChange = useCallback(
    field => {
      setSort({
        field,
        direction:
          sort.field === field && sort.direction === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc
      });

      setCurrentPage(1);
      resetPagination();
    },
    [resetPagination, setCurrentPage, setSort, sort]
  );

  const leftComponent = useMemo(
    () => (
      <>
        {resourcingAllocationModel === RESOURCING_ALLOCATION_MODEL.MODEL_1 ? (
          <HeaderContentModel1
            resourceSortField={resourceSortField}
            sort={sort}
            classes={contentClasses}
            onSortChange={onSortChange}
            loadingUsers={loadingUsers}
            paginationClasses={paginationClasses}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            currentPageRowCount={currentPageRowCount}
          />
        ) : (
          <HeaderContentModel0
            classes={contentClasses}
            paginationClasses={paginationClasses}
            resourceSortField={resourceSortField}
            sort={sort}
            onSortChange={onSortChange}
            loadingUsers={loadingUsers}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            currentPageRowCount={currentPageRowCount}
          />
        )}
      </>
    ),
    [
      resourcingAllocationModel,
      contentClasses,
      paginationClasses,
      sort,
      onSortChange,
      loadingUsers,
      currentPage,
      setCurrentPage,
      pageSize,
      currentPageRowCount
    ]
  );

  const rightHeader = (
    <>
      <div className={classes.total}>
        <FormattedMessage id="resourceRequestChartHeader.total" />
      </div>
    </>
  );

  return (
    <ChartHeader
      classes={chartClasses}
      leftComponentWidth={leftComponentWidth}
      leftComponent={leftComponent}
      scale={scale}
      chartDates={chartDates}
      rightComponent={rightHeader}
    />
  );
};

ResourceAllocationChartHeader.propTypes = {
  chartDates: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  currentPageRowCount: PropTypes.number.isRequired,
  loadingUsers: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  resetPagination: PropTypes.func.isRequired,
  scale: PropTypes.string,
  setCurrentPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sort: PropTypes.object
};

export default ResourceAllocationChartHeader;
