import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { NoDataItem } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0
  }
}));

const NoAttachments = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NoDataItem dataQeId="NoFileAdded">
        <FormattedMessage id="attachments.noAttachments" />
      </NoDataItem>
    </div>
  );
};

export default NoAttachments;
