import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BILLING_ITEMS_COUNT_QUERY = gql`
  query BillingItemsCount($projectId: String!) {
    billingItemsCount(projectId: $projectId)
  }
`;

export const useBillingItemsCount = ({ projectId, skip }) =>
  useQuery(BILLING_ITEMS_COUNT_QUERY, {
    variables: {
      projectId
    },
    fetchPolicy: 'network-only',
    skip
  });

export default useBillingItemsCount;
