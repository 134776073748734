import { useState, useEffect } from 'react';

export const useEditableState = ({ loading, creditItem }) => {
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (!loading && creditItem.balanceStatus !== 'FULLY_ALLOCATED')
      setEditable(true);
  }, [creditItem.balanceStatus, loading]);

  return {
    editable,
    setEditable
  };
};

export default useEditableState;
