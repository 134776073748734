import PropTypes from 'prop-types';
import React from 'react';
import {
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Typography
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { ProjectWorkflowStage } from '~/types';
import { PROJECT_STATUS_FILTER } from '../enum';
import useStyles, { useCheckboxStyles } from './useStyles';

const RadioButton = <Radio />;

const dialogResourcingLabels = {
  [PROJECT_STATUS_FILTER.ALL_PROJECTS]: {
    labelId: 'showProjects',
    strongTextId: 'allProjects'
  },
  [PROJECT_STATUS_FILTER.UNARCHIVED_PROJECTS]: {
    labelId: 'showUnarchivedProjects',
    strongTextId: 'unarchivedProjects'
  },
  [PROJECT_STATUS_FILTER.CUSTOM_STATUS]: {
    labelId: 'showCustomProjects'
  }
};

const ProjectStatusFilterDialogContent = ({
  values,
  onStatusChange,
  onOptionChange,
  errors,
  customStatusMapping,
  isProjectCustomStatusNamesLoading
}) => {
  const { statuses, option } = values;
  const classes = useStyles();
  const checkboxClasses = useCheckboxStyles();
  const { formatMessage } = useIntl();
  const {
    featureFlags: { isPsaPrpRenameProjectStatusEnabled }
  } = useMeContext();

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const getStatusCheckbox = status => (
    <Checkbox
      checked={statuses.includes(status)}
      onChange={() => onStatusChange(status)}
    />
  );

  const radioGroupLabels = Object.keys(dialogResourcingLabels).map(key => {
    const val = dialogResourcingLabels[key];
    const label = val.strongTextId && (
      <strong key={val.strongTextId} className={classes.projectLabel}>
        {formatMessage({
          id: `projectStatusFilterDialog.${val.strongTextId}`
        })}
      </strong>
    );

    return (
      <FormControlLabel
        key={key}
        labelPlacement="end"
        control={RadioButton}
        value={key}
        label={
          label
            ? formatMessage(
                {
                  id: `projectStatusFilterDialog.${val.labelId}`
                },
                {
                  project: label
                }
              )
            : formatMessage({
                id: `projectStatusFilterDialog.${val.labelId}`
              })
        }
      />
    );
  });

  return (
    <DialogContent>
      <div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={option}
            name="radio-buttons-group"
            onChange={onOptionChange}
          >
            {radioGroupLabels}
          </RadioGroup>
          {option === PROJECT_STATUS_FILTER.CUSTOM_STATUS && (
            <FormGroup classes={checkboxClasses}>
              {[
                ProjectWorkflowStage.Initiate,
                ProjectWorkflowStage.Planning,
                ProjectWorkflowStage.Execution,
                ProjectWorkflowStage.Closeout,
                ProjectWorkflowStage.Archived
              ].map(status => (
                <FormControlLabel
                  key={status}
                  control={getStatusCheckbox(status)}
                  label={
                    isCustomStatusNameEnabled && customStatusMapping[status]
                      ? customStatusMapping[status]
                      : formatMessage({
                          id: `projectStatus.${status.toLowerCase()}`
                        })
                  }
                />
              ))}
              {errors && errors.statuses ? (
                <Typography
                  variant="caption"
                  color="error"
                  className={classes.errorText}
                  data-qe-id="ProjectStatusFilterDialogError"
                >
                  {formatMessage({
                    id: 'projectStatusFilterDialog.selectProjectStatus'
                  })}
                </Typography>
              ) : null}
            </FormGroup>
          )}
        </FormControl>
      </div>
    </DialogContent>
  );
};

ProjectStatusFilterDialogContent.propTypes = {
  values: PropTypes.object.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  customStatusMapping: PropTypes.object,
  isProjectCustomStatusNamesLoading: PropTypes.bool
};

export default ProjectStatusFilterDialogContent;
