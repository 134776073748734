import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Decimal from '~/modules/common/components/Decimal';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { CHILD_INFO_DIALOG_COLUMN_TYPE } from '~/modules/common/enums';
import { useDividerStyles } from './useDividerStyles';
import { MobileTaskVariationRow } from './MobileTaskVariationRow';
import MobileTaskSummaryRow from './MobileTaskSummaryRow';
import RolledUpSummaryAccordionContainer from './RolledUpSummaryAccordionContainer';

const useStyles = makeStyles({
  na: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  }
});

const getColumns = ({ row }) => {
  const column = {
    actuals: CHILD_INFO_DIALOG_COLUMN_TYPE.ACTUAL_HOURS,
    remaining: CHILD_INFO_DIALOG_COLUMN_TYPE.WORK_REMAINING,
    estimated: CHILD_INFO_DIALOG_COLUMN_TYPE.ESTIMATED_AT_COMPLETION,
    variation: CHILD_INFO_DIALOG_COLUMN_TYPE.HOURS_VARIATION
  };

  return [CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME, column[row]];
};

export const RowValue = ({ value }) => {
  const classes = useStyles();

  return value === null ? (
    <Typography className={classes.na}>
      <FormattedMessage id="taskDrawer.na" />
    </Typography>
  ) : (
    <Decimal value={value} />
  );
};

export const HoursAccordionContent = ({
  task,
  loadMore,
  hasMore,
  loadingMore,
  variation,
  variationStatus,
  actualsClassOverride,
  workRemainingClassOverride,
  estimatedAtCompletionClassOverride,
  initialEstimatedClassOverride,
  labels,
  estimatedAtCompletionAlternateTooltipComponent,
  workRemainingAlternateTooltipComponent,
  mobileTaskVariationRowClassOverride,
  tooltipConditions
}) => {
  const { rolledUpSummary, initialEstimatedHours } = task;
  const dividerclass = useDividerStyles();

  const {
    hideVariationRowHoursTooltip,
    hideEstimatedAtCompletionRowHoursTooltip
  } = tooltipConditions || {};

  return (
    <>
      <MobileTaskSummaryRow
        label={labels.actuals}
        childrenDetails={task.children}
        loadingMore={loadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
        selectedColumns={getColumns({
          row: 'actuals'
        })}
        classesOverrides={actualsClassOverride}
      >
        <RowValue value={rolledUpSummary.actualHours} />
      </MobileTaskSummaryRow>
      <MobileTaskSummaryRow
        label={labels.workRemaining}
        childrenDetails={task.children}
        loadingMore={loadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
        selectedColumns={getColumns({ row: 'remaining' })}
        classesOverrides={workRemainingClassOverride}
        tooltipComponent={workRemainingAlternateTooltipComponent}
      >
        <RowValue value={rolledUpSummary.totalEstimatedRemainingHours} />
      </MobileTaskSummaryRow>
      <Divider className={dividerclass.divider} />
      <MobileTaskSummaryRow
        label={labels.estimatedAtCompletion}
        childrenDetails={task.children}
        loadingMore={loadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
        selectedColumns={getColumns({ row: 'estimated' })}
        classesOverrides={estimatedAtCompletionClassOverride}
        tooltipComponent={estimatedAtCompletionAlternateTooltipComponent}
        hideTooltip={hideEstimatedAtCompletionRowHoursTooltip}
      >
        <RowValue value={rolledUpSummary.totalEstimatedAtCompletionHours} />
      </MobileTaskSummaryRow>
      <MobileTaskSummaryRow
        label={labels.estimateBudget}
        classesOverrides={initialEstimatedClassOverride}
      >
        <RowValue value={initialEstimatedHours} />
      </MobileTaskSummaryRow>
      <Divider className={dividerclass.divider} />
      <MobileTaskVariationRow
        task={task}
        classes={mobileTaskVariationRowClassOverride}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        variation={variation}
        variationStatus={variationStatus}
        selectedColumns={getColumns({ row: 'variation' })}
        hideTooltip={hideVariationRowHoursTooltip}
        variant="hours"
      />
    </>
  );
};

const HoursAccordion = ({
  task,
  loadMore,
  hasMore,
  loadingMore,
  variation,
  variationStatus,
  actualsClassOverride,
  workRemainingClassOverride,
  estimatedAtCompletionClassOverride,
  initialEstimatedClassOverride,
  labels,
  estimatedAtCompletionAlternateTooltipComponent,
  workRemainingAlternateTooltipComponent,
  mobileTaskVariationRowClassOverride,
  tooltipConditions
}) => {
  return (
    <RolledUpSummaryAccordionContainer
      variation={variation}
      variationStatus={variationStatus}
      task={task}
      variant="hours"
      title="taskStatus.hours"
    >
      <HoursAccordionContent
        task={task}
        loadMore={loadMore}
        hasMore={hasMore}
        loadingMore={loadingMore}
        variation={variation}
        variationStatus={variationStatus}
        actualsClassOverride={actualsClassOverride}
        workRemainingClassOverride={workRemainingClassOverride}
        estimatedAtCompletionClassOverride={estimatedAtCompletionClassOverride}
        initialEstimatedClassOverride={initialEstimatedClassOverride}
        labels={labels}
        estimatedAtCompletionAlternateTooltipComponent={
          estimatedAtCompletionAlternateTooltipComponent
        }
        workRemainingAlternateTooltipComponent={
          workRemainingAlternateTooltipComponent
        }
        mobileTaskVariationRowClassOverride={
          mobileTaskVariationRowClassOverride
        }
        tooltipConditions={tooltipConditions}
      />
    </RolledUpSummaryAccordionContainer>
  );
};

RowValue.propTypes = {
  value: PropTypes.number
};

HoursAccordionContent.propTypes = {
  task: PropTypes.object,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  variation: PropTypes.number,
  variationStatus: PropTypes.string,
  actualsClassOverride: PropTypes.object,
  workRemainingClassOverride: PropTypes.object,
  estimatedAtCompletionClassOverride: PropTypes.object,
  initialEstimatedClassOverride: PropTypes.object,
  labels: PropTypes.object,
  estimatedAtCompletionAlternateTooltipComponent: PropTypes.node,
  workRemainingAlternateTooltipComponent: PropTypes.node,
  mobileTaskVariationRowClassOverride: PropTypes.object,
  tooltipConditions: PropTypes.object
};

HoursAccordion.propTypes = {
  task: PropTypes.object,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  variation: PropTypes.number,
  variationStatus: PropTypes.string,
  actualsClassOverride: PropTypes.object,
  workRemainingClassOverride: PropTypes.object,
  estimatedAtCompletionClassOverride: PropTypes.object,
  initialEstimatedClassOverride: PropTypes.object,
  labels: PropTypes.object,
  estimatedAtCompletionAlternateTooltipComponent: PropTypes.node,
  workRemainingAlternateTooltipComponent: PropTypes.node,
  mobileTaskVariationRowClassOverride: PropTypes.object,
  tooltipConditions: PropTypes.object
};
export default HoursAccordion;
