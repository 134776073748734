import { BillStatus } from '~/types';

const actionResourceKeys = billStatus => {
  return {
    syncBill:
      billStatus === BillStatus.Billed
        ? 'billingTransactionList.syncIfIssued'
        : 'billingTransactionList.sync',
    printBill:
      billStatus === BillStatus.Billed
        ? 'billingTransactionList.printIfIssued'
        : 'billingTransactionList.print',
    emailBill:
      billStatus === BillStatus.Billed
        ? 'billingTransactionList.emailIfIssued'
        : 'billingTransactionList.email'
  };
};

export const useBillActions = (canSyncBill, billStatus) => {
  const actionKeys = actionResourceKeys(billStatus);

  const res = canSyncBill
    ? [
        { id: 0, label: actionKeys.syncBill, value: 'sync' },
        { id: 1, label: actionKeys.printBill, value: 'print' },
        { id: 2, label: actionKeys.emailBill, value: 'email' }
      ]
    : [
        { id: 0, label: actionKeys.printBill, value: 'print' },
        { id: 1, label: actionKeys.emailBill, value: 'email' }
      ];

  return res;
};
