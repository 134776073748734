import { gql } from 'graphql-tag';

export const clientPageHeaderFragment = gql`
  fragment ClientPageHeader on Client {
    id
    name
    isActive
    clientManager {
      id
      displayText
    }
  }
`;

export const CLIENT_PAGE_HEADER_QUERY = gql`
  query clientBySlug($slug: String!) {
    client(slug: $slug) {
      ...ClientPageHeader
    }
  }
  ${clientPageHeaderFragment}
`;
