import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  bar: {
    overflow: 'hidden',
    height: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  progressBar: ({ width }) => ({
    width: `${width}%`
  }),
  backgroundBar: {
    width: theme.spacing(15),
    backgroundColor: theme.palette.grey[400]
  },
  successBar: {
    backgroundColor: theme.palette.success.main
  },
  errorBar: {
    backgroundColor: theme.palette.error.main
  }
}));

export const ProgressBar = ({ value = 0 }) => {
  const classes = useStyles({ width: value });
  const isOverOneHundredPercent = value > 100;

  return (
    <div className={classNames(classes.bar, classes.backgroundBar)}>
      <div
        className={classNames(classes.bar, classes.progressBar, {
          [classes.successBar]: !isOverOneHundredPercent,
          [classes.errorBar]: isOverOneHundredPercent
        })}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number
};

export default ProgressBar;
