import { withCustomFetchCriteriaProvider } from '../enhancers';
import { linkTypeTag } from './tag';
import ProjectLinkTypeFacetDetails from './ProjectLinkTypeFacetDetails';
import { useSearchableProjectLinkTypes } from './enhancers/useProjectLinkTypes';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableProjectLinkTypes);

export default {
  tag: linkTypeTag,
  makeCriteriaProvider,
  DetailsControl: ProjectLinkTypeFacetDetails
};
