export const useChangeHandlers = ({ setFieldValue, resourceAllocations }) => {
  const onResourceHoursChange = (e, index) => {
    const { value } = e.target;

    setFieldValue(`resourceAllocations[${index}].isDirty`, true);
    setFieldValue(`resourceAllocations[${index}].allocatedHours`, value);
  };

  const onResourceAllocationAdd = resourceAllocation => {
    const allocation = { ...resourceAllocation, isDirty: true };

    setFieldValue('resourceAllocations', [...resourceAllocations, allocation]);
  };

  const onResourceAllocationRemove = id => {
    const allocations = resourceAllocations.filter(a => a.resource.id !== id);

    setFieldValue('resourceAllocations', allocations);
  };

  const onInheritResourcesChange = ({ target: { checked: value } }) => {
    setFieldValue('resourceAllocations', []);
    setFieldValue('copyParentTaskResourceAllocations', value);
  };

  return {
    onResourceHoursChange,
    onResourceAllocationAdd,
    onInheritResourcesChange,
    onResourceAllocationRemove
  };
};

export default useChangeHandlers;
