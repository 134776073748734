/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import ReceiptIcon from '@material-ui/icons/ReceiptSharp';
import { useMeContext } from '~/modules/me/useMeContext';
import { CreditMemoIcon, PaymentIcon } from '~/modules/common/components/Icons';
import { AddBillDrawer } from '~/modules/billing-invoicing/bill/components';
import { AddCreditMemoDrawer } from '~/modules/billing-invoicing/creditMemo/components';
import { IconReadableWrapper } from '~/modules/common/components';
import { AddBillPaymentDrawer } from '~/modules/billing-invoicing/billPayment/components';

const useStyles = makeStyles(theme => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  speedDialActionDisplayText: {
    whiteSpace: 'nowrap'
  }
}));

export const AddBillingMenuButton = ({
  history,
  projectUri,
  projectName,
  client,
  showBillAction = true,
  showBillPaymentAction = true,
  showCreditMemoAction = true,
  isGlobalBilling = false
}) => {
  const intl = useIntl();
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = me;
  const classes = useStyles();
  const [actionMenuOpen, setActionMenuOpen] = React.useState(false);
  const [billDrawerOpen, setBillDrawerOpen] = useState(false);
  const [creditMemoDrawerOpen, setCreditMemoDrawerOpen] = useState(false);
  const [billPaymentDrawerOpen, setBillPaymentDrawerOpen] = useState(false);

  const openActionMenu = useCallback(() => setActionMenuOpen(true), []);
  const closeActionMenu = useCallback(() => setActionMenuOpen(false), []);

  const closeBillDrawer = useCallback(() => setBillDrawerOpen(false), []);
  const openBillDrawer = useCallback(() => {
    closeActionMenu();
    setBillDrawerOpen(true);
  }, [closeActionMenu]);

  const closeCreditMemoDrawer = useCallback(
    () => setCreditMemoDrawerOpen(false),
    []
  );
  const openCreditMemoDrawer = useCallback(() => {
    closeActionMenu();
    setCreditMemoDrawerOpen(true);
  }, [closeActionMenu]);

  const closeBillPaymentDrawer = useCallback(
    () => setBillPaymentDrawerOpen(false),
    []
  );
  const openBillPaymentDrawer = useCallback(() => {
    closeActionMenu();
    setBillPaymentDrawerOpen(true);
  }, [closeActionMenu]);

  const addBillButton = intl.formatMessage({
    id: 'addBillingMenuButton.addBillButton'
  });

  const addBillPaymentButton = intl.formatMessage({
    id: 'addBillingMenuButton.addBillPaymentButton'
  });

  const addCreditMemoButton = intl.formatMessage({
    id: 'addBillingMenuButton.addCreditMemoButton'
  });

  return (
    <>
      {creditMemoDrawerOpen && (
        <AddCreditMemoDrawer
          open={creditMemoDrawerOpen}
          onClose={closeCreditMemoDrawer}
          projectUri={projectUri}
          projectName={projectName}
          client={client}
        />
      )}
      {billDrawerOpen && (
        <AddBillDrawer
          history={history}
          open={billDrawerOpen}
          onClose={closeBillDrawer}
          projectUri={projectUri}
          projectName={projectName}
          client={client}
          isGlobalBilling={isGlobalBilling}
        />
      )}
      {billPaymentDrawerOpen && (
        <AddBillPaymentDrawer
          open={billPaymentDrawerOpen}
          onClose={closeBillPaymentDrawer}
          client={client}
        />
      )}
      <SpeedDial
        ariaLabel={intl.formatMessage({
          id: 'addBillingMenuButton.addBill'
        })}
        className={classes.speedDial}
        icon={<SpeedDialIcon data-qe-id="AddBillMenuButtonIcon" />}
        onOpen={openActionMenu}
        onClose={closeActionMenu}
        open={actionMenuOpen}
        data-qe-id="addBillingMenuButton"
      >
        {showBillAction && (
          <SpeedDialAction
            data-qe-id="addBill"
            key="addBill"
            FabProps={{
              'aria-label': addBillButton,
              'data-qe-id': 'addBillButton'
            }}
            icon={
              isPsaPrpAccessibilityIssuesEnabled ? (
                <IconReadableWrapper
                  id="addBillingButton.addBillButton"
                  role="img"
                >
                  <ReceiptIcon />
                </IconReadableWrapper>
              ) : (
                <ReceiptIcon />
              )
            }
            tooltipTitle={<FormattedMessage id="addBillingMenuButton.bill" />}
            tooltipOpen
            onClick={openBillDrawer}
          />
        )}
        {showBillPaymentAction && (
          <SpeedDialAction
            data-qe-id="addBillPayment"
            key="addBillPayment"
            FabProps={{
              'aria-label': addBillPaymentButton,
              'data-qe-id': 'addBillPaymentButton'
            }}
            icon={
              isPsaPrpAccessibilityIssuesEnabled ? (
                <IconReadableWrapper
                  id="addBillingButton.addBillPaymentButton"
                  role="img"
                >
                  <PaymentIcon />
                </IconReadableWrapper>
              ) : (
                <PaymentIcon />
              )
            }
            tooltipTitle={
              <FormattedMessage id="addBillingMenuButton.payment" />
            }
            tooltipOpen
            onClick={openBillPaymentDrawer}
          />
        )}
        {showCreditMemoAction && (
          <SpeedDialAction
            data-qe-id="addCreditMemo"
            key="addCreditMemo"
            FabProps={{
              'aria-label': addCreditMemoButton,
              'data-qe-id': 'addCreditMemoButton'
            }}
            icon={
              isPsaPrpAccessibilityIssuesEnabled ? (
                <IconReadableWrapper
                  id="addBillingButton.addCreditMemoButton"
                  role="img"
                >
                  <CreditMemoIcon />
                </IconReadableWrapper>
              ) : (
                <CreditMemoIcon />
              )
            }
            tooltipTitle={
              <span className={classes.speedDialActionDisplayText}>
                <FormattedMessage id="addBillingMenuButton.creditMemo" />
              </span>
            }
            tooltipOpen
            onClick={openCreditMemoDrawer}
          />
        )}
      </SpeedDial>
    </>
  );
};

AddBillingMenuButton.propTypes = {
  history: PropTypes.object,
  projectUri: PropTypes.string,
  projectName: PropTypes.string,
  client: PropTypes.object,
  showBillAction: PropTypes.bool,
  showBillPaymentAction: PropTypes.bool,
  showCreditMemoAction: PropTypes.bool,
  isGlobalBilling: PropTypes.bool
};

export default AddBillingMenuButton;
