import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import RateCardIcon from '@material-ui/icons/AttachMoneySharp';
import { useIntl } from 'react-intl';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { AdvancedRateCardEditable } from './components';
import {
  useAdvancedRateCardViewSummary,
  usePageOfProjectRate,
  useRateTableDimension,
  usePageOfAvailableRateTableDimensions
} from './components/hooks';

const avatar = <RateCardIcon />;

const getDimensionUris = dimensions => (dimensions || []).map(d => d.id);

export const AdvancedRateCard = ({
  editable,
  projectId,
  projectCurrency,
  projectSlug,
  projectPermissions
}) => {
  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    loading: rateTableDimensionLoading,
    projectDimensions
  } = useRateTableDimension({ projectId });

  const {
    availableRateTableDimensions,
    requiredDimensions,
    loading: availableRateTableDimensionsLoading
  } = usePageOfAvailableRateTableDimensions();

  const {
    loading: projectRatesLoading,
    projectRates,
    projectRateCount,
    hasMore,
    loadingMore,
    loadMore
  } = usePageOfProjectRate({
    projectId,
    dimensionUris: getDimensionUris(projectDimensions),
    skip: rateTableDimensionLoading
  });
  const { viewSummary, onViewSummaryClick } = useAdvancedRateCardViewSummary();

  const isLoading =
    projectRatesLoading ||
    rateTableDimensionLoading ||
    availableRateTableDimensionsLoading ||
    isSubmitting;

  if (isLoading)
    return (
      <CardLoading
        avatar={avatar}
        title={intl.formatMessage({ id: 'rateCard.cardTitle' })}
      />
    );

  return (
    <>
      <AdvancedRateCardEditable
        projectCurrency={projectCurrency}
        editable={editable}
        projectRates={projectRates}
        projectRateCount={projectRateCount}
        availableRateTableDimensions={availableRateTableDimensions}
        projectRateDimensions={projectDimensions}
        projectId={projectId}
        projectRateRequiredDimensions={requiredDimensions}
        hasMore={hasMore}
        loadingMore={loadingMore}
        loadMore={loadMore}
        projectSlug={projectSlug}
        setIsSubmitting={setIsSubmitting}
        projectPermissions={projectPermissions}
        onViewSummaryClick={onViewSummaryClick}
      />
      {viewSummary && (
        <AdvancedRateCardEditable
          projectCurrency={projectCurrency}
          editable={false}
          projectRates={projectRates}
          projectRateCount={projectRateCount}
          availableRateTableDimensions={availableRateTableDimensions}
          projectRateDimensions={projectDimensions}
          projectId={projectId}
          projectRateRequiredDimensions={requiredDimensions}
          hasMore={hasMore}
          loadingMore={loadingMore}
          loadMore={loadMore}
          projectSlug={projectSlug}
          setIsSubmitting={setIsSubmitting}
          edit={viewSummary}
          viewSummary={viewSummary}
          projectPermissions={projectPermissions}
          onCancelCallback={onViewSummaryClick}
        />
      )}
    </>
  );
};

AdvancedRateCard.propTypes = {
  editable: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  projectCurrency: PropTypes.object,
  projectSlug: PropTypes.string,
  projectPermissions: PropTypes.object
};

export default AdvancedRateCard;
