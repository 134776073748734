import PropTypes from 'prop-types';
import React from 'react';
import {
  Tooltip,
  makeStyles,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import Decimal from '~/modules/common/components/Decimal';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {},
  additional: {
    marginLeft: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize
  }
}));

const useListStyles = makeStyles(() => ({
  list: {
    paddingTop: 0,
    marginTop: 0,
    paddingBottom: 0
  }
}));

const useListItemStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    fontSize: theme.typography.caption.fontSize
  }
}));

export const getToolTipTitleContext = ({ pools, classes, listItemClasses }) => (
  <List className={classes.list}>
    {pools.map(pool => (
      <ListItem key={pool.id} disableGutters classes={listItemClasses}>
        <ListItemText primary={pool.displayText} classes={listItemClasses} />
      </ListItem>
    ))}
  </List>
);

export const ResourcePoolColumn = ({
  record: { allResourcePools },
  classes: classesOverrides
}) => {
  const pools = allResourcePools || [];
  const classes = useStyles({ classes: classesOverrides });
  const listClasses = useListStyles();
  const listItemClasses = useListItemStyles();

  if (pools.length === 0) return <NoValue />;

  if (pools.length === 1)
    return <div className={classes.root}>{pools[0].displayText}</div>;

  const [pool, ...otherPools] = pools;

  return (
    <Tooltip
      title={getToolTipTitleContext({
        pools,
        classes: listClasses,
        listItemClasses
      })}
    >
      <span className={classes.root}>
        {pool.displayText}
        <Decimal
          value={otherPools.length}
          precision={0}
          prefix="+"
          className={classes.additional}
        />
      </span>
    </Tooltip>
  );
};

ResourcePoolColumn.propTypes = {
  record: PropTypes.object.isRequired,
  classes: PropTypes.object
};

export default ResourcePoolColumn;
