import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, alpha, emphasize } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0, 0, 1)
  },
  outlined: props => ({
    backgroundColor: `${
      props.item.color ? alpha(props.item.color, 0.2) : 'transparent'
    } !important`,
    '&:hover, &:focus': {
      backgroundColor: `${
        props.item.color
          ? alpha(props.item.color, 0.25)
          : alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
      } !important`
    }
  }),
  colorPrimary: props => ({
    backgroundColor: props.item.color
      ? props.item.color
      : theme.palette.primary.main,
    color: props.item.color
      ? theme.palette.getContrastText(props.item.color)
      : theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: emphasize(
        props.item.color ? props.item.color : theme.palette.primary.main,
        0.08
      )
    }
  }),
  tabLabel: {
    display: 'flex',
    flexDirection: 'row'
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(0, 1, 0, 0)
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  },
  circularProgress: { color: theme.palette.common.black }
}));

const SummarySetItem = ({
  item,
  selected,
  onClick,
  isValueLoading,
  ...otherProps
}) => {
  const classes = useStyles({ item });

  const handleItemClick = React.useCallback(
    event => {
      if (onClick) onClick(event, item.key);
    },
    [onClick, item]
  );

  return (
    <Chip
      {...otherProps}
      variant={selected ? 'default' : 'outlined'}
      clickable
      onClick={handleItemClick}
      color={selected ? 'primary' : 'default'}
      component="li"
      role="tab"
      aria-selected={selected}
      classes={useMemo(
        () => ({
          root: classes.root,
          outlined: classes.outlined,
          outlinedPrimary: classes.outlinedPrimary,
          colorPrimary: classes.colorPrimary
        }),
        [
          classes.root,
          classes.outlined,
          classes.outlinedPrimary,
          classes.colorPrimary
        ]
      )}
      icon={item.icon}
      label={useMemo(
        () => (
          <span className={classes.tabLabel}>
            {typeof item.value === 'number' || item.value ? (
              <span className={classes.value}>{item.value}</span>
            ) : null}
            <span className={classes.label}>{item.label}</span>
          </span>
        ),
        [classes.tabLabel, item.value, classes.label, item.label, classes.value]
      )}
    />
  );
};

SummarySetItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  item: PropTypes.object,
  isValueLoading: PropTypes.bool
};

export default SummarySetItem;
