import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  submitIcon: {
    color: theme.palette.primary.main
  },
  approveIcon: {
    color: theme.palette.success.main
  },
  rejectIcon: {
    color: theme.palette.error.main
  },
  removeIcon: {
    color: theme.palette.secondary.main
  },
  remove: { color: theme.palette.secondary.dark },
  secondaryActionsButton: {
    padding: theme.spacing(0.75),
    margin: theme.spacing(-1, 0, -1, -0.5)
  }
}));
