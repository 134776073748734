import { IconButton, makeStyles, Slider } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearSharp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NoValue from '../../../NoValue';
import FacetDetailField from '../../FacetDetailField';
import {
  formatScoreValue,
  parseScoreValue,
  rangeHasValue,
  labelDescriptionFormat,
  useScoreMetricFacetDetailsHandlers
} from './hooks';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    flexShrink: 1
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  slider: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: theme.spacing(50)
  },
  clearButton: {
    flexGrow: 0,
    flexShrink: 0,
    margin: theme.spacing(-1.5, -1.5, -1.5, 1),
    visibility: ({ hasValidRange }) => (hasValidRange ? 'visible' : 'hidden')
  },
  sliderValueLabel: {
    ...theme.typography.caption,
    color: ({ showSecondaryColor }) =>
      showSecondaryColor < 1
        ? theme.palette.text.secondary
        : theme.palette.text.primary
  }
}));

const valueLabelFormat = value => (value < 1 ? <NoValue /> : value);

export const ScoreMetricFacetDetails = ({
  className,
  selected,
  setSelected,
  labelResourceKey,
  metric
}) => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: labelResourceKey });
  const score = parseScoreValue(selected);
  const formattedScore = formatScoreValue(score);
  const hasValidRange = rangeHasValue(score);

  const classes = useStyles({
    hasValidRange,
    showSecondaryColor: !!formattedScore.key
  });
  const sliderAnyValueDescValue = useMemo(() => ({ metric }), [metric]);
  const sliderabelDescriptionValue = useMemo(
    () => ({
      metric,
      val: labelDescriptionFormat(formattedScore)
    }),
    [formattedScore, metric]
  );

  const {
    onChangeHandler,
    onClearSearchHandler
  } = useScoreMetricFacetDetailsHandlers({ setSelected });

  return (
    <FacetDetailField label={label} className={className}>
      <div className={classes.container}>
        <div className={classes.controls}>
          <Slider
            data-qe-id={metric}
            value={score}
            onChange={onChangeHandler}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            min={0}
            max={5}
            step={1}
            marks
            className={classes.slider}
          />
          <IconButton
            aria-label={formatMessage(
              {
                id: 'searchBox.resetMetric'
              },
              { metric }
            )}
            className={classes.clearButton}
            onClick={onClearSearchHandler}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className={classes.sliderValueLabel}>
          {formattedScore.key ? (
            <FormattedMessage
              id="search.sliderDescription"
              values={sliderabelDescriptionValue}
            />
          ) : (
            <FormattedMessage
              id="search.anyMetricScore"
              values={sliderAnyValueDescValue}
            />
          )}
        </div>
      </div>
    </FacetDetailField>
  );
};

ScoreMetricFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  labelResourceKey: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired
};

export default ScoreMetricFacetDetails;
