import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const GET_RESOURCE_PLAN_ACTUALS_USERS_AND_ROLES = gql`
  query GetResourcePlanActualUsersAndRoles(
    $projectId: String!
    $userIds: [String!]!
  ) {
    project(projectId: $projectId) {
      id
      resourcePlanActualUsersAndRoles(userIds: $userIds) {
        id
        user {
          id
          uri
          displayText
        }
        roles {
          id
          displayText
        }
      }
    }
  }
`;

export const useFetchActualUsersAndRoles = ({ variables, skip }) => {
  const { data, loading } = useQuery(
    GET_RESOURCE_PLAN_ACTUALS_USERS_AND_ROLES,
    {
      variables,
      skip
    }
  );

  return {
    loading,
    actualsUserAndRolesData: (
      data?.project?.resourcePlanActualUsersAndRoles || []
    ).map(({ user, roles }) => ({
      user,
      roles: roles.map(role => ({
        role: role || null,
        resourceAllocationReference: []
      }))
    }))
  };
};
