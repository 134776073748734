import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useServiceCenters from '~/modules/common/enhancers/useServiceCenters';
import GroupDropdown from './GroupDropdown';
import { useStyledGroupLabels } from './hooks';

export const ServiceCenterDropdown = ({
  classes,
  groupSettings,
  value = '',
  policy,
  onChange,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { serviceCenter: serviceCenterLabel } = useStyledGroupLabels();
  const [searchTerm, setSearchTerm] = useState('');

  const { serviceCenters, loading, hasMore } = useServiceCenters({
    searchTerm,
    policy
  });

  const noGroupsText = groupSettings.singularName
    ? formatMessage(
        { id: 'groupsDropdown.noGroup' },
        { group: groupSettings.singularName }
      )
    : formatMessage({ id: 'serviceCenterDropdown.noServiceCenter' });

  return (
    <GroupDropdown
      hasMore={hasMore}
      classes={classes}
      options={serviceCenters}
      label={serviceCenterLabel}
      value={value}
      loading={loading}
      setSearchTerm={setSearchTerm}
      noOptionsText={noGroupsText}
      onChange={onChange}
      optionTypeText={formatMessage({
        id: 'moreOptions.serviceCenter'
      })}
      {...rest}
    />
  );
};

ServiceCenterDropdown.propTypes = {
  classes: PropTypes.object,
  groupSettings: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
  policy: PropTypes.string
};

export default ServiceCenterDropdown;
