import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';

export const getVariation = ({ estimated, totalEstimatedAtCompletion }) =>
  isDefined(estimated) && isDefined(totalEstimatedAtCompletion)
    ? Math.abs(estimated - totalEstimatedAtCompletion)
    : null;

export const getEstimatedVariationStatus = ({
  estimated,
  totalEstimatedAtCompletion,
  missingEstimatesInProject
}) => {
  if (
    !isDefined(estimated) ||
    !isDefined(totalEstimatedAtCompletion) ||
    missingEstimatesInProject
  ) {
    return ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE;
  }
  if (estimated < totalEstimatedAtCompletion) {
    return ESTIMATED_HOURS_VARIATION_STATUS.OVER;
  }
  if (estimated > totalEstimatedAtCompletion) {
    return ESTIMATED_HOURS_VARIATION_STATUS.UNDER;
  }

  return ESTIMATED_HOURS_VARIATION_STATUS.MATCHING;
};

export const getEstimatedHoursVariation = ({
  estimatedHours,
  totalEstimatedAtCompletionHours,
  missingEstimatesInProject = false
}) => ({
  variationStatus: getEstimatedVariationStatus({
    estimated: estimatedHours,
    totalEstimatedAtCompletion: totalEstimatedAtCompletionHours,
    missingEstimatesInProject
  }),
  variation: getVariation({
    estimated: estimatedHours,
    totalEstimatedAtCompletion: totalEstimatedAtCompletionHours
  })
});

export const getEstimatedCostVariation = ({
  estimatedCost,
  totalEstimatedAtCompletionCost
}) => {
  const variation = getVariation({
    estimated: estimatedCost?.amount,
    totalEstimatedAtCompletion: totalEstimatedAtCompletionCost?.amount
  });

  return {
    variationCost:
      variation != null
        ? {
            amount: variation,
            currency: estimatedCost?.currency
          }
        : null,
    costVariationStatus: getEstimatedVariationStatus({
      estimated: estimatedCost?.amount,
      totalEstimatedAtCompletion: totalEstimatedAtCompletionCost?.amount
    })
  };
};

export const getEstimatedProjectCostVariation = ({
  budgetedCost,
  totalEstimatedAtCompletionCost
}) => {
  const variation = getVariation({
    estimated: budgetedCost?.amount,
    totalEstimatedAtCompletion: totalEstimatedAtCompletionCost?.amount
  });

  return {
    projectVariationCost:
      variation != null
        ? {
            amount: variation,
            currency: budgetedCost?.currency
          }
        : null,
    projectCostVariationStatus: getEstimatedVariationStatus({
      estimated: budgetedCost?.amount,
      totalEstimatedAtCompletion: totalEstimatedAtCompletionCost?.amount
    })
  };
};

export const isProject = $level => $level === 0;

const isDefined = value => Boolean(value || value === 0);

export const isHoursDefined = isDefined;
