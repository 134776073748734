import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TagIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1)
  }
}));

export const TagSuggestionItem = ({ option: { label } }) => {
  const classes = useStyles();
  const text = label.replace('+', ': ');

  return (
    <>
      <TagIcon />
      <Typography
        variant="inherit"
        classes={{ root: classes.root }}
        color="inherit"
      >
        {text}
      </Typography>
    </>
  );
};

TagSuggestionItem.propTypes = {
  option: PropTypes.object
};

export default TagSuggestionItem;
