import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PROJECTS_SUMMARY_FILTER_TABS,
  PROJECT_EXECUTION_PHASE
} from '~/modules/common/enums';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

export const statusColors = {
  Initiate: {
    dark: theme.palette.project.initiate.main,
    light: theme.palette.project.initiate.light
  },
  Planning: {
    dark: theme.palette.project.planning.main,
    light: theme.palette.project.planning.light
  },
  Execution: {
    dark: theme.palette.project.execution.main,
    light: theme.palette.project.execution.light
  },
  Closeout: {
    dark: theme.palette.project.closeout.main,
    light: theme.palette.project.closeout.light
  },
  Archived: {
    dark: theme.palette.project.archived.main,
    light: theme.palette.project.archived.light
  }
};

export const getStatusCountSummaryBasedProps = ({
  allProjectsStatusCountSummary = {},
  myProjectsStatusCountSummary = {},
  archivedProjectsCount = 0,
  filterByAccess = PROJECTS_SUMMARY_FILTER_TABS.ALL_PROJECTS,
  customStatusMapping,
  isCustomStatusNameEnabled
} = {}) => {
  const counts =
    filterByAccess === PROJECTS_SUMMARY_FILTER_TABS.ALL_PROJECTS
      ? allProjectsStatusCountSummary
      : myProjectsStatusCountSummary;

  return {
    totalProjects: allProjectsStatusCountSummary.total || 0,
    myProjects: myProjectsStatusCountSummary.total || 0,
    statusOptions: [
      {
        id: PROJECT_EXECUTION_PHASE.INITIATE,
        displayText:
          isCustomStatusNameEnabled &&
          customStatusMapping[PROJECT_EXECUTION_PHASE.INITIATE] ? (
            customStatusMapping[PROJECT_EXECUTION_PHASE.INITIATE]
          ) : (
            <FormattedMessage id="projectStatus.initiate" />
          ),
        projectsCount: counts.initiate || 0,
        color: statusColors.Initiate
      },
      {
        id: PROJECT_EXECUTION_PHASE.PLANNING,
        displayText:
          isCustomStatusNameEnabled &&
          customStatusMapping[PROJECT_EXECUTION_PHASE.PLANNING] ? (
            customStatusMapping[PROJECT_EXECUTION_PHASE.PLANNING]
          ) : (
            <FormattedMessage id="projectStatus.planning" />
          ),
        projectsCount: counts.planning || 0,
        color: statusColors.Planning
      },
      {
        id: PROJECT_EXECUTION_PHASE.EXECUTION,
        displayText:
          isCustomStatusNameEnabled &&
          customStatusMapping[PROJECT_EXECUTION_PHASE.EXECUTION] ? (
            customStatusMapping[PROJECT_EXECUTION_PHASE.EXECUTION]
          ) : (
            <FormattedMessage id="projectStatus.execution" />
          ),
        projectsCount: counts.execution || 0,
        color: statusColors.Execution
      },
      {
        id: PROJECT_EXECUTION_PHASE.CLOSEOUT,
        displayText:
          isCustomStatusNameEnabled &&
          customStatusMapping[PROJECT_EXECUTION_PHASE.CLOSEOUT] ? (
            customStatusMapping[PROJECT_EXECUTION_PHASE.CLOSEOUT]
          ) : (
            <FormattedMessage id="projectStatus.closeout" />
          ),
        projectsCount: counts.closed || 0,
        color: statusColors.Closeout
      }
    ],
    archivedStatus: {
      id: PROJECT_EXECUTION_PHASE.ARCHIVED,
      displayText: <FormattedMessage id="projectStatus.archived" />,
      projectsCount: archivedProjectsCount,
      color: statusColors.Archived
    }
  };
};
