import React, { useMemo, useState } from 'react';

const withCriteriaProvider = (tag, context) => BaseComponent => props => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);

  const value = useMemo(
    () => ({
      matchOption: () => {},
      selected,
      setSelected
    }),
    [selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

export default withCriteriaProvider;
