import React from 'react';
import PropTypes from 'prop-types';
import ProjectStatusChart from '../ProjectStatusChart';
import { useProjectsSummaryStatusClickHandler } from './hooks/useProjectsSummaryStatusClickHandler';

export const ClickableProjectStatusChart = ({ chartDataInput }) => {
  const {
    legendClickHandlers,
    events
  } = useProjectsSummaryStatusClickHandler();

  return (
    <ProjectStatusChart
      chartDataInput={chartDataInput}
      legendClickHandlers={legendClickHandlers}
      events={events}
    />
  );
};

ClickableProjectStatusChart.propTypes = {
  chartDataInput: PropTypes.object
};

export default ClickableProjectStatusChart;
