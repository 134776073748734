import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import SyncIcon from '@material-ui/icons/SyncSharp';
import { Decimal, NoValue } from '~/modules/common/components';
import { isNumeric } from '~/modules/common/numbers';
import { useHasPermission } from '~/modules/common/permissions';
import { PROJECT_DAL, PROJECT_PERMISSION } from '~/modules/common/enums';
import ProjectTimelineChartRowBarTooltip from './ProjectTimelineChartRowBarTooltip';

const hoursLabel = <FormattedMessage id="projectList.hours" />;
const reschedulingLabel = (
  <FormattedMessage id="rescheduleProject.reschedule" />
);

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    margin: 0
  },
  spacing: {
    padding: theme.spacing(0, 0.25)
  },
  labelContainer: {
    position: 'sticky',
    right: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(0, 1)
  },
  label: {
    whiteSpace: 'nowrap',
    '& span': {
      textTransform: 'lowercase',
      ...theme.typography.caption
    }
  },
  '@keyframes rotateAnimation': {
    from: { transform: 'rotate(360deg)' },
    to: { transform: 'rotate(0deg)' }
  },
  syncIcon: {
    margin: theme.spacing(-0.25, 0.5, -0.25, 0),
    verticalAlign: 'text-bottom',
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    animation: `$rotateAnimation 1.5s linear infinite`,
    transformOrigin: [['50%', '50%']]
  }
}));

const useNoValueStyles = makeStyles({
  root: {
    color: 'inherit'
  }
});

const EmDash = () => {
  const classes = useNoValueStyles();

  return <NoValue classes={classes} />;
};

const Label = ({ className, actualHours, budgetHours, canViewBudgetHours }) => (
  <span className={className}>
    <span>{actualHours}</span>
    {canViewBudgetHours && <span> / {budgetHours}</span>}
    <span> {hoursLabel}</span>
  </span>
);

Label.propTypes = {
  className: PropTypes.string,
  actualHours: PropTypes.node,
  budgetHours: PropTypes.node,
  canViewBudgetHours: PropTypes.bool
};

export const ProjectTimelineChartRowBarLabel = ({
  startDate,
  endDate,
  actualHours: actualHoursValue,
  budgetHours: budgetHoursValue,
  isRescheduleProjectJobRunningInBackground
}) => {
  const classes = useStyles();
  const canViewBudgetHours = useHasPermission({
    actionUri: PROJECT_PERMISSION.VIEW_PROJECT_ESTIMATES,
    dataAccessLevelUri: PROJECT_DAL.PROJECT_ADMIN
  });

  const actualHours = isNumeric(actualHoursValue) ? (
    <Decimal value={actualHoursValue} />
  ) : (
    <EmDash />
  );

  const budgetHours = isNumeric(budgetHoursValue) ? (
    <Decimal value={budgetHoursValue} />
  ) : (
    <EmDash />
  );

  return (
    <ProjectTimelineChartRowBarTooltip
      startDate={startDate}
      endDate={endDate}
      actualHours={actualHours}
      budgetHours={budgetHours}
      canViewBudgetHours={canViewBudgetHours}
    >
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid className={classes.labelContainer}>
          {isRescheduleProjectJobRunningInBackground ? (
            <>
              <SyncIcon className={classes.syncIcon} />
              {reschedulingLabel}
            </>
          ) : (
            <Label
              className={classes.label}
              actualHours={actualHours}
              budgetHours={budgetHours}
              canViewBudgetHours={canViewBudgetHours}
            />
          )}
        </Grid>
      </Grid>
    </ProjectTimelineChartRowBarTooltip>
  );
};

ProjectTimelineChartRowBarLabel.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  actualHours: PropTypes.number,
  budgetHours: PropTypes.number,
  isRescheduleProjectJobRunningInBackground: PropTypes.bool
};

export default ProjectTimelineChartRowBarLabel;
