import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TimezoneIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 11.990234375,2 C 6.470234375000001,2 2,6.480000000000004 2,12 c 0,5.20467675644134 3.975593629142719,9.4811175036064 9.056640625,9.953125 A 8,8 0 0 1 10,18 8,8 0 0 1 10.189453125,16.291015625 C 10.15767703573196,16.19323792222249 10.11953514571293,16.09874115318485 10.08984375,16 H 10.265625 a 8,8 0 0 1 0.814453125,-2 H 9.66015625 C 9.57015625,13.34 9.50000000000001,12.68 9.5,12 c 0,-0.68 0.07015624999999,-1.35 0.16015625,-2 h 4.6796875 c 0.0383259830378,0.27679876638397 0.0684920759555,0.55851661555803 0.095703125,0.841796875 a 8,8 0 0 1 1.94140625,-0.666015625 C 16.37020971602254,10.11731678124797 16.3664599183949,10.05845057675788 16.359375,10 H 18 19.740234375 c 0.0170614315279,0.0682457261116 0.0253671898374,0.14006852610007 0.041015625,0.208984375 a 8,8 0 0 1 2.171875,0.841796875 C 21.47387053783548,5.976083197682873 17.19906612040944,2 11.990234375,2 Z M 12,4.0390625 C 12.83,5.239062500000002 13.48015625000001,6.570000000000002 13.91015625,8 h -3.8203125 C 10.51984375,6.570000000000002 11.17,5.239062500000003 12,4.0390625 Z M 9.41015625,4.439453125 C 8.810156249999998,5.549453124999997 8.349296875,6.750000000000001 8.029296875,8 H 5.080078125 C 6.040078125000001,6.339999999999999 7.57015624999999,5.069453124999999 9.41015625,4.439453125 Z m 5.1796875,0 C 16.42984374999999,5.069453124999999 17.95992187500001,6.350000000000002 18.919921875,8 h -2.94921875 c -0.32,-1.25 -0.78085937500001,-2.450546875000005 -1.380859375,-3.560546875 z M 4.259765625,10 H 7.640625 c -0.08,0.66 -0.140625,1.32 -0.140625,2 0,0.68 0.060625,1.34 0.140625,2 H 4.259765625 C 4.099765625,13.36 3.999999999999989,12.69 4,12 4,11.31 4.099765625000011,10.64 4.259765625,10 Z m 0.8203125,6 h 2.94921875 c 0.32,1.25 0.780859375000009,2.450546875 1.380859375,3.560546875 C 7.570156250000001,18.930546875 6.040078125000001,17.66 5.080078125,16 Z" />
      <path d="M 18 12 C 14.68629150229023 12 12 14.68629150229023 12 18 C 12 21.31370849770978 14.68629150229023 24 18 24 C 21.31370849770978 24 24 21.31370849770978 24 18 C 24 14.68629150229023 21.31370849770978 12 18 12 z M 17.25 14 L 18.75 14 L 18.75 17.541015625 L 20.833984375 18.6015625 L 20.154296875 19.9375 L 17.25 18.458984375 L 17.25 14 z " />
    </>
  </SvgIcon>
));

export default TimezoneIcon;
