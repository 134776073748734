import React, { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddSharp';
import { FormSelectField } from '~/modules/common/components';
import { RoleName } from '~/modules/common/components/entities/role';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { AddRoleDialog } from '~/modules/resourcing/common/components/SearchableRoleDropDown/AddRoleDialog';
import NoneMenuItem from '~/modules/common/components/NoneMenuItem';
import { useHasPermission } from '~/modules/common/permissions';
import MoreResult from '~/modules/common/components/MoreResult';
import useRoleSearch from './useRoleSearch';
import RolesSearchMenuList from './RolesSearchMenuList';
import useStyles from './useStyles';

const addNewRoleOptionValue = 'replicon:psa-project-role:new';

const renderRole = ({
  classes,
  assignedRole,
  showSelectRole,
  placeholder
}) => () =>
  assignedRole ? (
    <RoleName
      role={{
        ...assignedRole,
        amount: assignedRole.currentRate
          ? assignedRole.currentRate.amount
          : null,
        symbol: assignedRole.currentRate
          ? assignedRole.currentRate.currency.displayText
          : ''
      }}
      className={classes.name}
    />
  ) : (
    placeholder ||
    (showSelectRole ? (
      <span className={classes.noRoleLabel}>
        <FormattedMessage id="rolesDropdown.selectRole" />
      </span>
    ) : null)
  );

export const NoneText = () => (
  <Typography variant="body2" color="textSecondary">
    <FormattedMessage id="rolesDropdown.noRole" />
  </Typography>
);

export const RolesMenuListDropdown = ({
  open,
  label,
  dataQeId,
  onClose,
  onOpen,
  hasError,
  onChange,
  helperText,
  assignedRole,
  variant = 'standard',
  classes: classesOverride,
  showSelectRole = false,
  placeholder,
  showNoRole = true,
  includeSkills = false,
  refetchActiveRoles = false,
  ...rest
}) => {
  const canCreateRole = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:edit-project-role'
  });

  const classes = useStyles({ classes: classesOverride });
  const intl = useIntl();

  const { open: newRoleDialogOpen, closeDialog, openDialog } = useDialogState();

  const roleNameClasses = useMemo(
    () => ({
      costRate: classes.costRate,
      tooltipName: classes.tooltipName,
      roleName: classes.roleName
    }),
    [classes]
  );

  const {
    hasMore,
    loading,
    activeRoles,
    searchText,
    updateSearchText
  } = useRoleSearch({
    includeSkills
  });

  const onSearchChange = useCallback(
    event => updateSearchText(event.target.value || ''),
    [updateSearchText]
  );

  const addRoleChange = useCallback(
    role => {
      onChange(role);
    },
    [onChange]
  );

  const renderValue = useMemo(
    () =>
      renderRole({
        classes,
        assignedRole,
        showSelectRole,
        placeholder
      }),
    [classes, assignedRole, showSelectRole, placeholder]
  );

  const onRoleChange = useCallback(
    event => {
      const roleUri =
        event.target && typeof event.target.value == 'string'
          ? event.target.value
          : null;

      if (roleUri === addNewRoleOptionValue) {
        openDialog();
        if (onClose) {
          onClose();
        }

        return;
      }

      onChange(roleUri ? activeRoles.filter(r => r.id === roleUri)[0] : null);
    },
    [onChange, onClose, activeRoles, openDialog]
  );

  const moreAvailableText = intl.formatMessage(
    {
      id: 'searchAutocomplete.moreAvailable'
    },
    {
      option: intl.formatMessage({
        id: 'moreOptions.roles'
      })
    }
  );

  const selectRef = useRef(null);

  const handleMenuItemKeyDown = useCallback(
    event => {
      if (event.keyCode === 9 && event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        selectRef.current.click();
      }
    },
    [selectRef]
  );

  return (
    <>
      <FormSelectField
        dataQeId={dataQeId}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        classes={useMemo(
          () => ({
            icon: classes.icon,
            select: classes.select
          }),
          [classes.icon, classes.select]
        )}
        label={label}
        fullWidth
        renderValue={renderValue}
        MenuProps={useMemo(
          () => ({
            MenuListProps: {
              component: RolesSearchMenuList,
              searchText,
              onSearchChange,
              ariaLabel: intl.formatMessage({
                id: 'addRoleDialog.role'
              }),
              loading,
              searchRef: selectRef,
              autoFocus: false,
              autoFocusItem: false
            }
          }),
          [searchText, onSearchChange, intl, loading]
        )}
        value={assignedRole ? assignedRole.id : null}
        shrink={Boolean(assignedRole?.id || placeholder)}
        variant={variant}
        onChange={onRoleChange}
        error={hasError}
        helperText={helperText}
        {...rest}
      >
        {showNoRole && (
          <NoneMenuItem value="" onKeyDown={handleMenuItemKeyDown}>
            <FormattedMessage id="rolesDropdown.noRole" />
          </NoneMenuItem>
        )}
        {activeRoles.map(role => (
          <MenuItem
            key={role.id}
            value={role.id}
            onKeyDown={handleMenuItemKeyDown}
          >
            <RoleName
              role={{
                ...role,
                amount: role.currentRate && role.currentRate.amount,
                symbol: role.currentRate
                  ? role.currentRate.currency.displayText
                  : ''
              }}
              classes={roleNameClasses}
            />
          </MenuItem>
        ))}
        {canCreateRole && !loading && (
          <MenuItem
            value={addNewRoleOptionValue}
            key={addNewRoleOptionValue}
            onKeyDown={handleMenuItemKeyDown}
          >
            <ListItemIcon className={classes.createOptionIcon}>
              {addIcon}
            </ListItemIcon>
            <ListItemText
              primary={intl.formatMessage({
                id: 'addRoleDialog.role'
              })}
              primaryTypographyProps={primaryTypographyProps}
            />
          </MenuItem>
        )}
        {hasMore && !loading && (
          <MenuItem value="more-available" key="more-available" disabled>
            <MoreResult message={moreAvailableText} />
          </MenuItem>
        )}
      </FormSelectField>
      <AddRoleDialog
        open={newRoleDialogOpen}
        onClose={closeDialog}
        onChange={addRoleChange}
        initialName=""
        includeSkills
        refetchActiveRoles
      />
    </>
  );
};

const addIcon = <AddIcon color="primary" />;

const primaryTypographyProps = {
  color: 'primary'
};

RolesMenuListDropdown.propTypes = {
  dataQeId: PropTypes.string,
  open: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.any,
  assignedRole: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'filled']),
  classes: PropTypes.object,
  showSelectRole: PropTypes.bool,
  showNoRole: PropTypes.bool,
  includeSkills: PropTypes.bool,
  refetchActiveRoles: PropTypes.bool,
  placeholder: PropTypes.node
};

export default RolesMenuListDropdown;
