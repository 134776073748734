import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useMeContext } from '~/modules/me';
import {
  objectToFormatString,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';
import { CHILD_INFO_DIALOG_COLUMN_TYPE } from '~/modules/common/enums';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useDividerStyles } from './useDividerStyles';
import MobileTaskSummaryRow from './MobileTaskSummaryRow';
import MobileTaskVariationRow from './MobileTaskVariationRow';
import RolledUpSummaryAccordionContainer from './RolledUpSummaryAccordionContainer';

const useSummaryRowStyle = makeStyles({
  data: ({ addMargin }) => ({
    marginRight: addMargin ? theme.spacing(3.25) : 0
  })
});

export const RowValue = ({ date }) => {
  return date ? <span>{date}</span> : <FormattedMessage id="taskDrawer.na" />;
};

const DatesAccordion = ({
  estimatedCompletionDate,
  endDate,
  variationDays,
  task,
  hasMore,
  loadMore,
  loadingMore,
  labels,
  tooltipConditions
}) => {
  return (
    <RolledUpSummaryAccordionContainer
      variation={variationDays}
      task={task}
      variant="dates"
      title="taskStatus.dates"
    >
      <DatesAccordionContent
        task={task}
        estimatedCompletionDate={estimatedCompletionDate}
        endDate={endDate}
        loadMore={loadMore}
        hasMore={hasMore}
        loadingMore={loadingMore}
        variationDays={variationDays}
        labels={labels}
        tooltipConditions={tooltipConditions}
      />
    </RolledUpSummaryAccordionContainer>
  );
};

export const DatesAccordionContent = ({
  estimatedCompletionDate,
  endDate,
  variationDays,
  task,
  hasMore,
  loadMore,
  loadingMore,
  labels,
  tooltipConditions
}) => {
  const { hideVariationRowTooltip } = tooltipConditions || {};
  const me = useMeContext();
  const dateFormat = getLuxonJsDateFormatFromMe(me);
  const originaldate = objectToFormatString(endDate, dateFormat);
  const selectedColumns = useMemo(
    () => [
      CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
      CHILD_INFO_DIALOG_COLUMN_TYPE.DATE_VARIATION
    ],
    []
  );

  const classesOverrides = useSummaryRowStyle({
    addMargin: task.children.length > 0
  });

  const dividerclass = useDividerStyles();

  return (
    <>
      <MobileTaskSummaryRow
        label={labels.estimatedAtCompletion}
        classesOverrides={classesOverrides}
      >
        <RowValue date={estimatedCompletionDate} />
      </MobileTaskSummaryRow>
      <MobileTaskSummaryRow
        label={labels.estimateBudget}
        value={originaldate}
        classesOverrides={classesOverrides}
      >
        <RowValue date={originaldate} />
      </MobileTaskSummaryRow>
      <Divider className={dividerclass.divider} />
      <MobileTaskVariationRow
        task={task}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        variant="dates"
        variation={variationDays}
        selectedColumns={selectedColumns}
        hideTooltip={hideVariationRowTooltip}
      />
    </>
  );
};

RowValue.propTypes = {
  date: PropTypes.string
};

DatesAccordionContent.propTypes = {
  task: PropTypes.object.isRequired,
  variationDays: PropTypes.number,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  estimatedCompletionDate: PropTypes.string,
  endDate: PropTypes.object,
  labels: PropTypes.object,
  tooltipConditions: PropTypes.object
};

DatesAccordion.propTypes = {
  task: PropTypes.object.isRequired,
  variationDays: PropTypes.number,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  estimatedCompletionDate: PropTypes.string,
  endDate: PropTypes.object,
  labels: PropTypes.object,
  tooltipConditions: PropTypes.object
};

export default DatesAccordion;
