import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import useMeContext from '~/modules/me/useMeContext';
import SearchBox from '~/modules/common/components/SearchBox/SearchBox';
import getFacets from './facets';

const useStyles = makeStyles(() => ({
  searchBoxContainer: {
    padding: 0
  },
  searchBox: {},
  searchFacet: {
    overflow: 'auto',
    maxHeight: 295
  }
}));

export const ProjectResourcingSearchBox = ({
  classes: classesOverrides,
  setSearchCriteria,
  searchCriteria
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const { formatMessage } = useIntl();
  const expandIcon = formatMessage({ id: 'resourcePoolSearchBox.expand' });

  const searchBoxClasses = useMemo(
    () => ({
      root: classes.searchBox,
      searchFacet: classes.searchFacet
    }),
    [classes.searchBox, classes.searchFacet]
  );

  const { groupSettings, permissions, featureFlags } = useMeContext();

  const facets = useMemo(
    () =>
      getFacets({
        groupSettings,
        permissions,
        featureFlags
      }),
    [groupSettings, permissions, featureFlags]
  );

  return (
    <>
      {setSearchCriteria && (
        <div className={classes.searchBoxContainer}>
          <SearchBox
            dataQeId="Project-Resourcing-SearchBar"
            searchIconAriaLabel={expandIcon}
            classes={searchBoxClasses}
            setSearchCriteria={setSearchCriteria}
            searchCriteria={searchCriteria}
            facets={facets}
          />
        </div>
      )}
    </>
  );
};

ProjectResourcingSearchBox.propTypes = {
  classes: PropTypes.object,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object.isRequired
};

export default ProjectResourcingSearchBox;
