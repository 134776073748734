import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { deepPure } from '~/util';
import DelaWeekViewSvg from './Images/DelaViews/WeekView.svg';
import DelaMonthViewSvg from './Images/DelaViews/MonthView.svg';
import DelaQuarterViewSvg from './Images/DelaViews/QuarterView.svg';
import DelaYearViewSvg from './Images/DelaViews/YearView.svg';
import DelaDayViewSvg from './Images/DelaViews/DayView.svg';
import WeekViewSvg from './Images/WeekView.svg';
import MonthViewSvg from './Images/MonthView.svg';
import QuarterViewSvg from './Images/QuarterView.svg';
import YearViewSvg from './Images/YearView.svg';
import DayViewSvg from './Images/DayView.svg';
import { ChartRangeItem2 } from './ChartRangeItem2';

export const STYLED_CHART_RANGE_ITEM_VARIANT = {
  WHITE: 'WHITE',
  PURPLE: 'PURPLE'
};

const useWhiteVariantStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  itemContainer: {
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  totalContainer: { borderLeft: `1px solid ${theme.palette.grey[400]}` },
  openButton: {},
  timeContainer: {},
  timeContainerFixedRange: {},
  timeContainerDay1: {},
  timeContainerDay2: {},
  timeContainerDay3: {},
  timeContainerDay4: {},
  timeContainerDay5: {},
  timeContainerDay6: {},
  timeContainerDay7: {},
  timeContainerYear: {
    backgroundImage: `url(${YearViewSvg})`
  },
  timeContainerQuarter: {
    backgroundImage: `url(${QuarterViewSvg})`,
    backgroundPositionX: 1,
    backgroundPositionY: 0
  },
  timeContainerMonth: {
    backgroundImage: `url(${MonthViewSvg})`
  },
  timeContainerWeek: {
    backgroundImage: `url(${WeekViewSvg})`
  },
  timeContainerDay: {
    backgroundImage: `url(${DayViewSvg})`
  }
}));

const usePurpleVariantStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  itemContainer: {
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  totalContainer: { borderLeft: `1px solid ${theme.palette.grey[400]}` },
  openButton: {},
  timeContainer: {},
  timeContainerFixedRange: {},
  timeContainerDay1: {},
  timeContainerDay2: {},
  timeContainerDay3: {},
  timeContainerDay4: {},
  timeContainerDay5: {},
  timeContainerDay6: {},
  timeContainerDay7: {},
  timeContainerYear: {
    backgroundImage: `url(${DelaYearViewSvg})`
  },
  timeContainerQuarter: {
    backgroundImage: `url(${DelaQuarterViewSvg})`,
    backgroundPositionX: 1,
    backgroundPositionY: 0
  },
  timeContainerMonth: {
    backgroundImage: `url(${DelaMonthViewSvg})`
  },
  timeContainerWeek: {
    backgroundImage: `url(${DelaWeekViewSvg})`
  },
  timeContainerDay: {
    backgroundImage: `url(${DelaDayViewSvg})`
  }
}));

export const StyledChartRangeItem2 = ({
  classes: classesOverrides,
  variant = STYLED_CHART_RANGE_ITEM_VARIANT.WHITE,
  ...props
}) => {
  const whiteVariantClasses = useWhiteVariantStyles({
    classes: classesOverrides
  });
  const purpleVariantClasses = usePurpleVariantStyles({
    classes: classesOverrides
  });

  return (
    <ChartRangeItem2
      {...props}
      classes={
        variant === STYLED_CHART_RANGE_ITEM_VARIANT.WHITE
          ? whiteVariantClasses
          : purpleVariantClasses
      }
    />
  );
};

StyledChartRangeItem2.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf(Object.values(STYLED_CHART_RANGE_ITEM_VARIANT)),
  props: PropTypes.object
};

export default deepPure(StyledChartRangeItem2);
