import PropTypes from 'prop-types';
import React from 'react';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { useMeContext } from '~/modules/me';
import ResourceRequestTimelineBlocksReadOnly from '~/modules/resourcing/common/components/ResourceRequestTimelineBlocksReadOnly';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { useChartDisplayPeriods } from '~/modules/resourcing/common/chart/hooks';
import ResourceRequestUserAllocations from '../../../ResourceRequestUserAllocations';
import ResourceRequestDrafts2 from './ResourceRequestDrafts2';

export const ResourceRequestBlockGroup2 = ({
  chartDates,
  chartDisplayDateRange,
  displayUnit,
  dynamicContainerHeight,
  isEditSwitchEnabled,
  resourceAllocations,
  resourceRequest,
  scale
}) => {
  const { permissionsMap } = useMeContext();

  const chartDisplayPeriods = useChartDisplayPeriods({
    chartDisplayDateRange,
    scale
  });
  const { project } = useProjectContext();

  const { isResourceActualModeEnabled } = useResourceRequestToolbarContext();

  const isResourceAssignmentEnabled = Boolean(
    permissionsMap[
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
    ]
  );

  const isCompleteResourceAllocationPermissionEnabled = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );

  return (
    <>
      <ResourceRequestUserAllocations
        resourceAllocations={resourceAllocations}
        scale={scale}
        chartDisplayDateRange={chartDisplayDateRange}
        resourceRequest={resourceRequest}
        chartDates={chartDates}
        isEditable={
          isEditSwitchEnabled &&
          isCompleteResourceAllocationPermissionEnabled &&
          isResourceAssignmentEnabled
        }
        dynamicContainerHeight={dynamicContainerHeight}
        displayUnit={displayUnit}
      />
      {resourceAllocations.length > 0 ? (
        <ResourceRequestTimelineBlocksReadOnly
          resourceRequest={resourceRequest}
          chartDisplayDateRange={chartDisplayDateRange}
          scale={scale}
          project={project}
          chartDisplayPeriods={chartDisplayPeriods}
        />
      ) : (
        <ResourceRequestDrafts2
          resourceRequest={resourceRequest}
          chartDates={chartDates}
          scale={scale}
          isEditable={isEditSwitchEnabled}
          displayUnit={displayUnit}
          chartDisplayDateRange={chartDisplayDateRange}
          isResourceActualModeEnabled={isResourceActualModeEnabled}
          chartDisplayPeriods={chartDisplayPeriods}
        />
      )}
    </>
  );
};

ResourceRequestBlockGroup2.propTypes = {
  scale: PropTypes.string.isRequired,
  resourceAllocations: PropTypes.array,
  resourceRequest: PropTypes.object.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  displayUnit: PropTypes.string,
  chartDates: PropTypes.array,
  isEditSwitchEnabled: PropTypes.bool,
  dynamicContainerHeight: PropTypes.number
};

export default ResourceRequestBlockGroup2;
