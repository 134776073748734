import { useMemo } from 'react';

const associatedBillsPanelResourceKeys = {
  title: 'creditMemoDetails.outstandingBills',
  allocatedAmount: 'creditMemoDetails.allocatedAmount'
};

const lineItemsPanelResourceKeys = {
  title: 'creditMemoDetails.credits',
  allocatedAmount: 'creditMemoDetails.allocatedAmount'
};

export default ({ client, intl }) => {
  const lineItemsTableResourceKeys = useMemo(
    () => ({
      removeLineItemMessageForNoProject:
        'creditMemoDetails.removeLineItemMessageForNoProject',
      removeLabel: 'creditMemoDetails.removeLineItem',
      header: 'creditMemoDetails.removeLineItem',
      removeMessage: 'creditMemoDetails.removeLineItemMessage',
      actionButton: 'creditMemoDetails.removeLineItem',
      noDataMessage: 'creditMemoDetails.noLineItem',
      removeWarning: 'creditMemoDetails.removeCreditMemo',
      lastLineItemMessage: 'creditMemoDetails.lastLineItem',
      permissionWarning: 'creditMemoDetails.permissionWarning',
      permissionRemovedWarning: 'creditMemoDetails.permissionRemovedWarning'
    }),
    []
  );

  const resourceLabels = useMemo(
    () => ({
      referenceNumber: intl.formatMessage({
        id: 'creditMemoDetails.referenceNumber'
      }),
      creditDate: intl.formatMessage({ id: 'creditMemoDetails.creditDate' }),
      description: intl.formatMessage({ id: 'creditMemoDetails.description' }),
      currency: intl.formatMessage({ id: 'creditMemoDetails.currency' })
    }),
    [intl]
  );

  const associatedBillsTableResourceKeys = useMemo(
    () => ({
      noDataMessage: `creditMemoDetails.${
        client && client.id ? 'noOutstandingBills' : 'selectClient'
      }`,
      amountAriaLabel: intl.formatMessage({
        id: 'creditMemoDetails.associatedBills.amountAriaLabel'
      }),
      allocatedBillAmountAriaLabel: intl.formatMessage({
        id: 'associatedBills.allocatedBillAmountAriaLabel'
      }),
      buttonAriaLabel: intl.formatMessage({
        id: 'associatedBills.buttonAriaLabel'
      })
    }),
    [client, intl]
  );

  const accesibilityResourceLabels = useMemo(
    () => ({
      creditDateAriaLabel: intl.formatMessage({
        id: 'creditMemoDetails.creditDate'
      }),
      currencyAriaLabel: intl.formatMessage({
        id: 'creditMemoDetails.creditInfo.currencyAriaLabel'
      }),
      clientAriaLabel: intl.formatMessage({
        id: 'creditMemoDetails.creditInfo.clientAriaLabel'
      })
    }),
    [intl]
  );

  return {
    lineItemsTableResourceKeys,
    associatedBillsTableResourceKeys,
    resourceLabels,
    accesibilityResourceLabels,
    associatedBillsPanelResourceKeys,
    lineItemsPanelResourceKeys
  };
};
