import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, useMediaQuery } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { useMeContext } from '~/modules/me/useMeContext';
import SendBillDialogContent from './SendBillDialogContent';
import {
  useFormState,
  useGetClientDetailsForBill,
  useGetBillSnapshot
} from './hooks';

const transitionProps = { role: 'presentation' };

export const SendBillDialog = ({ bill, open, onClose }) => {
  const me = useMeContext();
  const { billSnapshot, loading } = useGetBillSnapshot({ bill });

  const billUrl = useMemo(
    () =>
      !loading && billSnapshot
        ? `${me.uiRootPath}invoicing2/invoice-snapshot/${me.tenantSlug}/${billSnapshot.id}/${billSnapshot.asOfDate}/${me.locale.language.languageCode}/${billSnapshot.signature}`
        : null,
    [
      billSnapshot,
      loading,
      me.locale.language.languageCode,
      me.tenantSlug,
      me.uiRootPath
    ]
  );

  const { clientDetails } = useGetClientDetailsForBill({ bill });
  const { validationSchema, initialValues } = useFormState({
    clientDetails,
    me,
    bill,
    billUrl
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        fullScreen={useMediaQuery(theme => theme.breakpoints.down('sm'), {
          noSsr: true
        })}
        open={open}
        onClose={onClose}
        TransitionProps={transitionProps}
      >
        <DialogTitle>
          <FormattedMessage id="sendBillDialog.sendBill" />
          <span>{bill.displayText || bill.displayId}</span>
        </DialogTitle>

        <SendBillDialogContent
          onClose={onClose}
          billId={bill.id}
          loading={loading}
        />
      </Dialog>
    </Formik>
  );
};

SendBillDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  bill: PropTypes.object
};

export default SendBillDialog;
