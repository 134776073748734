import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import { makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  expandButton: {
    paddingLeft: 0
  },
  expandIcon: {
    margin: theme.spacing(-0.5),
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  expandIconOpen: {
    transform: 'rotate(90deg)'
  },
  cellButton: ({ isPsaPrpAccessibilityIssuesEnabled }) => ({
    display: 'flex',
    justifyContent: 'inherit',
    textAlign: 'inherit',
    margin: theme.spacing(-1, -2),
    padding: theme.spacing(1, 2, 1, 0),
    '&:focus': {
      ...(isPsaPrpAccessibilityIssuesEnabled
        ? { backgroundColor: theme.palette.grey[200] }
        : {})
    }
  })
}));

export const RowGroupButton = ({ expanded, onExpandChange, rowTitle }) => {
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const classes = useStyles({ isPsaPrpAccessibilityIssuesEnabled });

  const handleClick = useCallback(() => {
    if (onExpandChange) onExpandChange(!expanded);
  }, [expanded, onExpandChange]);

  return (
    <ButtonBase
      component="a"
      className={classNames(classes.cellButton, classes.expandButton)}
      onClick={handleClick}
    >
      <ChevronRightIcon
        className={classNames(classes.expandIcon, {
          [classes.expandIconOpen]: expanded
        })}
        mirror="rtl"
      />
      <FormattedMessage id={rowTitle} />
    </ButtonBase>
  );
};

RowGroupButton.propTypes = {
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  rowTitle: PropTypes.string
};

export default RowGroupButton;
