import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import { NoValue } from '~/modules/common/components';
import { useMeContext } from '~/modules/me/useMeContext';
import BudgetHoursChartLegend from './BudgetHoursChartLegend';
import BudgetHoursChartLegendTooltip from './BudgetHoursChartLegendTooltip';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: `1 5 ${theme.spacing(34)}px`,
    minWidth: theme.spacing(34),
    maxWidth: theme.spacing(70),
    paddingLeft: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(2)
  },
  title: {
    width: '100%',
    color: theme.palette.grey[600],
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  budgetTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  budgetValue: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.43
  }
}));

const estimatedLabel = <FormattedMessage id="projectBudgetChart.estimate" />;
const estimateAtCompletionLabel = (
  <FormattedMessage id="projectBudgetChart.estimateAtCompletion" />
);
const allocatedLabel = <FormattedMessage id="projectBudgetChart.allocated" />;
const hoursWorkedLabel = (
  <FormattedMessage id="projectBudgetChart.hoursWorked" />
);

export const BudgetHoursChartLegends = ({
  actualBarColor,
  projectHoursSummary
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();

  const suffix = intl.formatMessage({
    id: 'dashboardProgressChart.hrs'
  });

  return (
    <div className={classes.root}>
      <Typography className={classes.budgetTitle} variant="body2">
        <FormattedMessage id="chart.budget" />
      </Typography>
      <Typography className={classes.budgetValue} variant="subtitle1">
        {isRolledUpTaskEstimateCalculationMethodEnabled &&
        projectHoursSummary.budgetHours === null ? (
          <NoValue />
        ) : (
          <BudgetHoursChartLegendTooltip
            value={projectHoursSummary.budgetHours}
            suffix={suffix}
            dataQeId="BudgetHoursChartLegends_BudgetHoursTooltip"
          />
        )}
      </Typography>
      <div className={classes.container}>
        <Typography className={classes.title} variant="body2">
          <FormattedMessage id="chart.project" />
        </Typography>
        <BudgetHoursChartLegend
          actualBarColor={actualBarColor}
          estimatedValue={projectHoursSummary.estimatedHours}
          actualValue={
            projectHoursSummary.timeEnteredSummary?.timeEnteredActual || 0
          }
          estimatedLabel={estimatedLabel}
          actualLabel={hoursWorkedLabel}
          suffix={suffix}
          dataQeId="BudgetHoursChartLegend_ProjectEstimate"
        />
        <Typography className={classes.title} variant="body2">
          <FormattedMessage id="chart.tasks" />
        </Typography>
        <BudgetHoursChartLegend
          actualBarColor={actualBarColor}
          estimatedValue={
            projectHoursSummary.timeEnteredSummary
              ?.timeEnteredTaskEstimatedAtCompletion || 0
          }
          actualValue={
            projectHoursSummary.timeEnteredSummary?.timeEnteredTaskActual || 0
          }
          estimatedLabel={estimateAtCompletionLabel}
          actualLabel={hoursWorkedLabel}
          suffix={suffix}
          dataQeId="BudgetHoursChartLegend_EstimatedHoursAtCompletion"
        />
        <Typography className={classes.title} variant="body2">
          <FormattedMessage id="chart.resources" />
        </Typography>
        <BudgetHoursChartLegend
          actualBarColor={actualBarColor}
          estimatedValue={projectHoursSummary.totalAllocatedHours}
          actualValue={
            projectHoursSummary.timeEnteredSummary
              ?.timeEnteredAllocatedResourceActual || 0
          }
          estimatedLabel={allocatedLabel}
          actualLabel={hoursWorkedLabel}
          suffix={suffix}
          dataQeId="BudgetHoursChartLegend_TotalAllocatedHours"
        />
      </div>
    </div>
  );
};

BudgetHoursChartLegends.propTypes = {
  actualBarColor: PropTypes.string.isRequired,
  projectHoursSummary: PropTypes.object
};

export default BudgetHoursChartLegends;
