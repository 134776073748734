import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gantt } from '@replicon/dhtmlx-gantt';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import {
  TASK_STATUS,
  ESTIMATED_HOURS_VARIATION_STATUS
} from '~/modules/common/enums';
import HoursValue from '../HoursValue';
import {
  getEstimatedHoursVariation,
  getEstimatedCostVariation,
  isHoursDefined,
  isProject
} from '../util';
import { VariationPillbox } from './VariationPillbox';
import { CostVariationPillbox } from './CostVariationPillbox';
import { useStyles, useTaskBarTextStyles } from './useStyles';

const getHoursStyle = ({ taskHeight, sizes, startDate, endDate }) => ({
  top: `${sizes.top + taskHeight + 17}px`,
  left: `${!startDate && endDate ? sizes.left - 132 : sizes.left}px`
});

export const usePillStylesOverride = makeStyles({
  variationIcon: {
    height: theme.spacing(2),
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(0.25)
  },
  variationContainer: {
    height: theme.spacing(2.55)
  },
  variationPill: {
    fontSize: theme.typography.caption.fontSize,
    height: theme.spacing(2.55)
  },
  variationValue: {
    fontSize: theme.typography.caption.fontSize
  },
  variationLabel: {
    fontSize: theme.typography.caption.fontSize
  }
});

const useVariationPillBoxStyles = makeStyles(() => ({
  container: ({ isCostVariationPillBoxVisible }) => ({
    ...(isCostVariationPillBoxVisible && {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    })
  })
}));

const useVariationPillBoxTaskBarText2Styles = makeStyles(() => ({
  container: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}));

export const GanttTaskBarText = ({
  intl,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  canViewEstimate,
  canViewCostData,
  numberFormat,
  sizes,
  task,
  taskHeight
}) => {
  const {
    $level,
    taskStatus,
    estimatedHours,
    rolledUpSummary: { actualHours, totalEstimatedAtCompletionHours } = {},
    rolledUpCostSummary: {
      initialEstimatedCostOrBudgetedCostInProjectCurrency,
      totalEstimatedAtCompletionCostInProjectCurrency
    } = {},
    startDate,
    endDate
  } = task;

  const missingEstimatesInProject =
    isProject($level) && gantt.getTaskBy('estimatedHours', null).length > 0;

  const { variationStatus, variation } = getEstimatedHoursVariation({
    estimatedHours,
    totalEstimatedAtCompletionHours,
    missingEstimatesInProject
  });

  const { costVariationStatus, variationCost } = getEstimatedCostVariation({
    estimatedCost: initialEstimatedCostOrBudgetedCostInProjectCurrency,
    totalEstimatedAtCompletionCost: totalEstimatedAtCompletionCostInProjectCurrency
  });

  const isCostVariationPillBoxVisible = Boolean(
    isPsaPpmCostEacEnhancements2Enabled &&
      canViewCostData &&
      canViewEstimate &&
      variationCost
  );

  const classes = useStyles({
    sizes,
    taskHeight,
    variationStatus,
    startDate,
    endDate,
    isRTL,
    isCostVariationPillBoxVisible
  });

  const variationPillBoxClasses = useVariationPillBoxStyles({
    isCostVariationPillBoxVisible
  });

  const pillClasses = useMemo(
    () => ({
      variationPill: classes.noBorderRightRadius
    }),
    [classes.noBorderRightRadius]
  );

  return (
    <GanttTaskBarTextInternal
      actualHours={actualHours}
      canViewEstimate={canViewEstimate}
      classes={classes}
      costVariationStatus={costVariationStatus}
      intl={intl}
      isRTL={isRTL}
      isCostVariationPillBoxVisible={isCostVariationPillBoxVisible}
      isPsaPpmCostEacEnhancementsEnabled={isPsaPpmCostEacEnhancementsEnabled}
      isPsaPpmCostEacEnhancements2Enabled={isPsaPpmCostEacEnhancements2Enabled}
      isPsaRmpTaskAllocation1Enabled={false}
      numberFormat={numberFormat}
      pillClasses={pillClasses}
      taskStatus={taskStatus}
      totalEstimatedAtCompletionHours={totalEstimatedAtCompletionHours}
      variationStatus={variationStatus}
      variation={variation}
      variationCost={variationCost}
      variationPillBoxClasses={variationPillBoxClasses}
    />
  );
};

export const GanttTaskBarTextInternal = ({
  actualHours,
  canViewEstimate,
  classes,
  costVariationStatus,
  intl,
  isRTL,
  isCostVariationPillBoxVisible,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  isPsaRmpTaskAllocation1Enabled,
  numberFormat,
  pillClasses,
  style,
  taskStatus,
  totalEstimatedAtCompletionHours,
  variationStatus,
  variation,
  variationCost,
  variationPillBoxClasses
}) => {
  const showMissingEstimate =
    variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE;
  const hrsLabel = intl.formatMessage({
    id: isPsaRmpTaskAllocation1Enabled
      ? 'projectTasksPage.h'
      : 'projectTasksPage.hrs'
  });

  return (
    <div className={classes.container} style={style}>
      <div className={classes.textContainer}>
        {taskStatus === TASK_STATUS.NOTSTARTED &&
          canViewEstimate &&
          !showMissingEstimate && (
            <div className={classes.hoursContainer}>
              <HoursValue
                className={classes.timeValue}
                value={totalEstimatedAtCompletionHours}
                fixedDecimalScale={false}
                numberFormat={numberFormat}
                isPsaPpmCostEacEnhancements2Enabled={
                  isPsaPpmCostEacEnhancements2Enabled
                }
              />
              <span
                className={classNames(
                  classes.hours,
                  !variationStatus
                    ? classes.hoursWithoutVariationStatus
                    : undefined
                )}
              >
                {hrsLabel}
              </span>
            </div>
          )}
        {taskStatus === TASK_STATUS.INPROGRESS && (
          <>
            <div className={classes.hoursContainer}>
              <HoursValue
                className={classes.timeValue}
                value={actualHours}
                fixedDecimalScale={false}
                numberFormat={numberFormat}
                isPsaPpmCostEacEnhancements2Enabled={
                  isPsaPpmCostEacEnhancements2Enabled
                }
              />
              <span
                className={classNames(
                  classes.hours,
                  !variationStatus
                    ? classes.hoursWithoutVariationStatus
                    : undefined
                )}
              >
                {hrsLabel}
              </span>
            </div>
            {canViewEstimate &&
              isHoursDefined(totalEstimatedAtCompletionHours) && (
                <>
                  <span
                    className={classNames(
                      classes.divider,
                      !isRTL ? classes.dividerWithoutRTL : undefined
                    )}
                  >
                    /
                  </span>
                  <div className={classes.hoursContainer}>
                    <HoursValue
                      className={classes.subTimeValue}
                      value={totalEstimatedAtCompletionHours}
                      fixedDecimalScale={false}
                      numberFormat={numberFormat}
                      isPsaPpmCostEacEnhancements2Enabled={
                        isPsaPpmCostEacEnhancements2Enabled
                      }
                    />
                    <span
                      className={classNames(
                        classes.hours,
                        !variationStatus
                          ? classes.hoursWithoutVariationStatus
                          : undefined
                      )}
                    >
                      {hrsLabel}
                    </span>
                  </div>
                </>
              )}
          </>
        )}
        {taskStatus === TASK_STATUS.COMPLETED && canViewEstimate && (
          <>
            <HoursValue
              className={classes.timeValue}
              value={totalEstimatedAtCompletionHours}
              fixedDecimalScale={false}
              numberFormat={numberFormat}
              isPsaPpmCostEacEnhancements2Enabled={
                isPsaPpmCostEacEnhancements2Enabled
              }
            />
            <span className={classes.hours}>{hrsLabel}</span>
          </>
        )}
      </div>
      {canViewEstimate && showMissingEstimate ? (
        taskStatus !== TASK_STATUS.COMPLETED && (
          <VariationPillbox
            classes={variationPillBoxClasses}
            pillClasses={pillClasses}
            intl={intl}
            variationStatus={variationStatus}
            isRTL={isRTL}
            isPsaPpmCostEacEnhancementsEnabled={
              isPsaPpmCostEacEnhancementsEnabled
            }
            isPsaPpmCostEacEnhancements2Enabled={
              isPsaPpmCostEacEnhancements2Enabled
            }
            numberFormat={numberFormat}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
        )
      ) : (
        <VariationPillbox
          classes={variationPillBoxClasses}
          pillClasses={pillClasses}
          intl={intl}
          variationStatus={variationStatus}
          variationValue={variation}
          isRTL={isRTL}
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacEnhancements2Enabled={
            isPsaPpmCostEacEnhancements2Enabled
          }
          numberFormat={numberFormat}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      )}
      {isCostVariationPillBoxVisible ? (
        <CostVariationPillbox
          intl={intl}
          variationStatus={costVariationStatus}
          variationValue={variationCost}
          isRTL={isRTL}
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacEnhancements2Enabled={
            isPsaPpmCostEacEnhancements2Enabled
          }
          numberFormat={numberFormat}
          pillClassesOverride={pillClasses}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      ) : null}
    </div>
  );
};

export const GanttTaskBarText2 = ({
  canViewEstimate,
  canViewCostData,
  intl,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  numberFormat,
  sizes,
  task,
  taskHeight
}) => {
  const {
    $level,
    taskStatus,
    estimatedHours,
    rolledUpSummary: { actualHours, totalEstimatedAtCompletionHours } = {},
    rolledUpCostSummary: {
      initialEstimatedCostOrBudgetedCostInProjectCurrency,
      totalEstimatedAtCompletionCostInProjectCurrency
    } = {},
    startDate,
    endDate
  } = task;

  const missingEstimatesInProject =
    isProject($level) && gantt.getTaskBy('estimatedHours', null).length > 0;

  const { variationStatus, variation } = getEstimatedHoursVariation({
    estimatedHours,
    totalEstimatedAtCompletionHours,
    missingEstimatesInProject
  });

  const { costVariationStatus, variationCost } = getEstimatedCostVariation({
    estimatedCost: initialEstimatedCostOrBudgetedCostInProjectCurrency,
    totalEstimatedAtCompletionCost: totalEstimatedAtCompletionCostInProjectCurrency
  });

  const isCostVariationPillBoxVisible = Boolean(
    isPsaPpmCostEacEnhancements2Enabled &&
      canViewCostData &&
      canViewEstimate &&
      variationCost
  );

  const classes = useTaskBarTextStyles();

  const pillClassesOverride = usePillStylesOverride();

  const variationPillBoxClasses = useVariationPillBoxTaskBarText2Styles();

  return (
    <GanttTaskBarTextInternal
      actualHours={actualHours}
      classes={classes}
      canViewEstimate={canViewEstimate}
      costVariationStatus={costVariationStatus}
      intl={intl}
      isRTL={isRTL}
      isCostVariationPillBoxVisible={isCostVariationPillBoxVisible}
      isPsaPpmCostEacEnhancementsEnabled={isPsaPpmCostEacEnhancementsEnabled}
      isPsaPpmCostEacEnhancements2Enabled={isPsaPpmCostEacEnhancements2Enabled}
      isPsaRmpTaskAllocation1Enabled
      numberFormat={numberFormat}
      pillClasses={pillClassesOverride}
      style={getHoursStyle({ sizes, startDate, endDate, taskHeight })}
      taskStatus={taskStatus}
      totalEstimatedAtCompletionHours={totalEstimatedAtCompletionHours}
      variationStatus={variationStatus}
      variation={variation}
      variationCost={variationCost}
      variationPillBoxClasses={variationPillBoxClasses}
    />
  );
};

GanttTaskBarTextInternal.propTypes = {
  taskStatus: PropTypes.string,
  canViewEstimate: PropTypes.bool,
  totalEstimatedAtCompletionHours: PropTypes.number,
  numberFormat: PropTypes.object,
  actualHours: PropTypes.number,
  intl: PropTypes.object,
  variationStatus: PropTypes.string,
  isRTL: PropTypes.bool,
  variation: PropTypes.number,
  classes: PropTypes.object,
  pillClasses: PropTypes.object,
  variationCost: PropTypes.object,
  costVariationStatus: PropTypes.string,
  isCostVariationPillBoxVisible: PropTypes.bool,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  style: PropTypes.object,
  variationPillBoxClasses: PropTypes.object
};

GanttTaskBarText.propTypes = {
  intl: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  taskHeight: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  isRTL: PropTypes.bool.isRequired,
  canViewEstimate: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  numberFormat: PropTypes.object
};

GanttTaskBarText2.propTypes = {
  intl: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  taskHeight: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  isRTL: PropTypes.bool.isRequired,
  canViewEstimate: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  numberFormat: PropTypes.object
};

export default GanttTaskBarText;
