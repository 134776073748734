import React, { useMemo } from 'react';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { useAddSkillOnSubmit } from '~/modules/resourcing/common/components/Skills/hooks';
import DialogForm from './AddSkillDialogForm';
import buildValidationSchema from './addSkillDialogValidationSchema';

const useStyles = makeStyles(() => ({
  title: {
    paddingBottom: 0,
    backgroundColor: 'inherit'
  }
}));

const addSkillDialogRole = {
  role: 'presentation'
};

const AddSkillDialog = ({ open, initialName = '', onClose, onSkillAdd }) => {
  const intl = useIntl();
  const fullScreen = useIsBreakpointDown('xs');
  const initialValues = useMemo(
    () => ({
      category: null,
      name: initialName,
      description: ''
    }),
    [initialName]
  );

  const classes = useStyles();

  const { onSubmit } = useAddSkillOnSubmit({
    onClose,
    onSkillAdd
  });

  const validationSchema = buildValidationSchema(intl);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      TransitionProps={addSkillDialogRole}
      fullWidth
    >
      <DialogTitle className={classes.title}>
        <FormattedMessage id="skillsDropdown.addSkill" />
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <DialogForm onClose={onClose} />
      </Formik>
    </Dialog>
  );
};

AddSkillDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSkillAdd: PropTypes.func,
  initialName: PropTypes.string
};

export default AddSkillDialog;
