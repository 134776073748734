import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(1, 0, 0, 0),
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridTemplateRows: 'auto',
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize
  },
  listNoCost: {
    gridTemplateColumns: 'repeat(3, auto)'
  },
  item: {
    padding: 0,
    margin: 0,
    display: 'contents'
  },
  value: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    margin: theme.spacing(0, 0, 0, 2)
  },
  title: {
    fontSize: theme.typography.caption.fontSize
  }
}));

export default useStyles;
