import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';
import MoneyEditor from './MoneyEditor';
import DeleteRow from './DeleteRow';
import DateEditor from './DateEditor';
import DropdownEditor from './DropdownEditor';
import DateRangeEditor from './DateRangeEditor';
import TextEditor from './TextEditor';
import NumericEditor from './NumericEditor';
import BooleanEditor from './BooleanEditor';

const scriptParamTypeditors = {
  date: DateEditor,
  money: MoneyEditor,
  'drop-down': DropdownEditor,
  'date-range': DateRangeEditor,
  text: TextEditor,
  numeric: NumericEditor,
  integer: NumericEditor,
  'read-only-amount': MoneyFormatter,
  boolean: BooleanEditor
};

export const editors = ({ parameters, customEditors }) => {
  const allEditors = { ...scriptParamTypeditors, ...customEditors };
  const attachedEditors = parameters.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.id]: allEditors[current.id] || allEditors[current.type]
    }),
    {}
  );

  return { ...attachedEditors, deleteRow: DeleteRow };
};
