import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { useIsBreakpointUp } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  message: {
    marginBottom: theme.spacing(2)
  }
}));

export const UnhandledErrorDescription = ({ eventId }) => {
  const classes = useStyles();
  const isSmWidth = useIsBreakpointUp('sm');

  return (
    <>
      <Typography component="p" variant={isSmWidth ? 'h5' : 'body1'}>
        <FormattedMessage
          id="unhandledErrorPage.description"
          values={{
            email: (
              <a href="mailto:support@replicon.com">support@replicon.com</a>
            )
          }}
        />
      </Typography>
      <Typography className={classes.message} component="p" variant="caption">
        <FormattedMessage
          id="unhandledErrorPage.eventId"
          values={{ eventId }}
        />
      </Typography>
    </>
  );
};

UnhandledErrorDescription.propTypes = {
  eventId: PropTypes.string
};

export default UnhandledErrorDescription;
