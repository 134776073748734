import { makeStyles } from '@material-ui/core';

export const useAlternateTooltipStyles = makeStyles(theme => ({
  messageContent: {
    maxWidth: theme.spacing(67.5),
    whiteSpace: 'break-spaces',
    padding: theme.spacing(1.25),
    lineHeight: 2,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(45.5),
      whiteSpace: 'unset',
      lineHeight: '1.5'
    },
    color: theme.palette.text.secondary,
    fontWeight: 500
  }
}));
