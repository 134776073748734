import {
  useOutstandingBills,
  mergeAssociatedAndOutstandingBills
} from '~/modules/billing-invoicing/common/hooks';

export const useAssociatedAndOutStandingBills = ({
  me,
  values,
  client,
  creditMemo,
  billId,
  billBalanceTotal,
  showAllOutstandingBills
}) => {
  const { outstandingBills } = useOutstandingBills({
    me,
    values,
    client,
    creditMemo,
    showAllOutstandingBills,
    billId
  });

  return mergeAssociatedAndOutstandingBills({
    outstandingBills,
    creditItem: values || creditMemo,
    billId,
    billBalanceTotal
  });
};
