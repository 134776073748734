import { Table, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReleaseResourceRequestDialogTableRow from './ReleaseResourceRequestDialogTableRow';
import ReleaseResourceRequestDialogTableHeader from './ReleaseResourceRequestDialogTableHeader';

export const ReleaseResourceRequestDialogContent = ({
  onSelectAllClick,
  onRowSelectionChange,
  allocations
}) => {
  return (
    <Table>
      <ReleaseResourceRequestDialogTableHeader
        usersCount={allocations.length}
        selectedCount={allocations.filter(a => a.isSelected).length}
        onSelectAllClick={onSelectAllClick}
      />
      <TableBody>
        {allocations.map(allocation => (
          <ReleaseResourceRequestDialogTableRow
            key={allocation.id}
            allocation={allocation}
            onRowSelectionChange={onRowSelectionChange}
          />
        ))}
      </TableBody>
    </Table>
  );
};

ReleaseResourceRequestDialogContent.propTypes = {
  allocations: PropTypes.array.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired
};

export default ReleaseResourceRequestDialogContent;
