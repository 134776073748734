import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  searchBox: {
    marginTop: 0
  }
}));

export const SearchBarSkeleton = () => {
  const classes = useStyles();

  return <Skeleton width="100%" height={40} className={classes.searchBox} />;
};

export default SearchBarSkeleton;
