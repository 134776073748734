import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { useIsBreakpointUp } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2)
    }
  }
}));

export const UnauthorizedTitle = () => {
  const classes = useStyles();
  const isMdWidth = useIsBreakpointUp('md');
  const isSmWidth = useIsBreakpointUp('sm');

  return (
    <Typography
      component="h1"
      variant={isMdWidth ? 'h2' : isSmWidth ? 'h3' : 'h4'}
      className={classes.title}
    >
      <FormattedMessage id="unauthorizedPage.title" />
    </Typography>
  );
};

UnauthorizedTitle.propTypes = {};

export default UnauthorizedTitle;
