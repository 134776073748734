import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpSharp';
import { getRTLPopperStyles } from '~/modules/common/util';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    '$message + &': {
      marginTop: theme.spacing(1)
    }
  },
  message: {
    margin: 0,
    '& + &': {
      marginTop: theme.spacing(1)
    }
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    cursor: 'help',
    opacity: 0.3,
    verticalAlign: 'middle',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),

    '&:hover': {
      opacity: 1
    }
  }
}));

const useTooltipStyles = makeStyles(theme => ({
  popper: ({ curr }) => getRTLPopperStyles({ curr, theme })
}));

export const SummaryCardHelp = ({ content }) => {
  const classes = useStyles();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const tooltipPopperClasses = useTooltipStyles();

  return (
    <Tooltip
      title={useMemo(
        () => (
          <Content classes={classes} content={content} />
        ),
        [classes, content]
      )}
      interactive
      enterTouchDelay={0}
      classes={tooltipPopperClasses}
    >
      {isPsaPrpAccessibilityIssuesEnabled ? (
        <HelpIcon className={classes.helpIcon} tabIndex={0} />
      ) : (
        <HelpIcon className={classes.helpIcon} />
      )}
    </Tooltip>
  );
};

const Content = ({ classes, content }) => {
  return (
    <>
      {content.map(item => (
        <Fragment key={item.title}>
          {item.title ? <h4 className={classes.title}>{item.title}</h4> : null}
          <p className={classes.message}>{item.message}</p>
        </Fragment>
      ))}
    </>
  );
};

SummaryCardHelp.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      message: PropTypes.node.isRequired
    }).isRequired
  ).isRequired
};

Content.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      message: PropTypes.node.isRequired
    }).isRequired
  ).isRequired,
  classes: PropTypes.object
};

export default SummaryCardHelp;
