import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import {
  GANTT_SCALE_DAY,
  GANTT_SCALE_WEEK,
  GANTT_SCALE_MONTH,
  GANTT_SCALE_QUARTER,
  GANTT_SCALE_YEAR
} from '../../hooks/useGanttScale';

const useStyles = makeStyles(theme => ({
  selectMenu: {
    padding: theme.spacing(1, 4, 1, 1.5),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary
  }
}));

const handleChange = setGanttScale => ({ target: { value } }) =>
  setGanttScale(value);

const outLineInput = <OutlinedInput labelWidth={0} />;

export const ChartScaleSelect = ({
  classes: classesOverride,
  ganttScale,
  setGanttScale,
  className
}) => {
  const classes = useStyles({ classes: classesOverride });
  const cssClasses = useMemo(
    () => ({
      selectMenu: classes.selectMenu
    }),
    [classes.selectMenu]
  );

  return (
    <FormControl variant="outlined" className={className}>
      <Select
        data-qe-id="ganttScaleSelect"
        classes={cssClasses}
        value={ganttScale}
        onChange={handleChange(setGanttScale)}
        input={outLineInput}
      >
        <MenuItem data-qe-id="DayOption" value={GANTT_SCALE_DAY}>
          <FormattedMessage id="ganttScale.day" />
        </MenuItem>
        <MenuItem data-qe-id="WeekOption" value={GANTT_SCALE_WEEK}>
          <FormattedMessage id="ganttScale.week" />
        </MenuItem>
        <MenuItem data-qe-id="MonthOption" value={GANTT_SCALE_MONTH}>
          <FormattedMessage id="ganttScale.month" />
        </MenuItem>
        <MenuItem data-qe-id="QuarterOption" value={GANTT_SCALE_QUARTER}>
          <FormattedMessage id="ganttScale.quarter" />
        </MenuItem>
        <MenuItem data-qe-id="YearOption" value={GANTT_SCALE_YEAR}>
          <FormattedMessage id="ganttScale.year" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

ChartScaleSelect.propTypes = {
  classes: PropTypes.object,
  ganttScale: PropTypes.oneOf([
    GANTT_SCALE_DAY,
    GANTT_SCALE_WEEK,
    GANTT_SCALE_MONTH,
    GANTT_SCALE_QUARTER,
    GANTT_SCALE_YEAR
  ]),
  setGanttScale: PropTypes.func,
  className: PropTypes.string
};

export default ChartScaleSelect;
