import { useCallback } from 'react';

export const useExpenseScriptParamsEditableCardHook = ({
  openDialog,
  resetForm,
  onCancelCallback,
  setFieldValue,
  onDeleteScript,
  planId,
  projectId,
  bulkPutEstimatedExpenses,
  updateProjectExpenses,
  resetDate,
  setHideColumn,
  expenseCardSettings
}) => {
  const onDeleteScriptHandler = useCallback(() => {
    openDialog();
  }, [openDialog]);

  const onCancel = useCallback(() => {
    resetForm();
    resetDate();
    setHideColumn(expenseCardSettings?.showActuals);
    onCancelCallback && onCancelCallback();
  }, [
    resetForm,
    resetDate,
    setHideColumn,
    expenseCardSettings,
    onCancelCallback
  ]);

  const onShowActualsChange = useCallback(
    event => {
      const value = event.target.checked;

      setFieldValue('showActuals', value);
      setHideColumn(value);
    },
    [setFieldValue, setHideColumn]
  );

  const onDeleteScriptConfirmClick = useCallback(async () => {
    await bulkPutEstimatedExpenses(projectId, []);
    await updateProjectExpenses({ expenseCodes: [] });
    await onDeleteScript(planId)();
  }, [
    bulkPutEstimatedExpenses,
    onDeleteScript,
    planId,
    projectId,
    updateProjectExpenses
  ]);

  return {
    onDeleteScriptHandler,
    onShowActualsChange,
    onCancel,
    onDeleteScriptConfirmClick
  };
};
