import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { NoValue } from '~/modules/common/components';

export const TagFieldColumn = ({ field, record: { tags = [] } }) => {
  const tagItem =
    (tags && tags.find(x => x && x.tag && x.tag.id === field)) || {};
  const displayText = tagItem.value ? tagItem.value.value : <NoValue />;

  return (
    <Typography align="left" variant="body2">
      {displayText}
    </Typography>
  );
};

TagFieldColumn.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string
};

export default TagFieldColumn;
