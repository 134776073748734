import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { PastDueDays } from '~/modules/billing-invoicing/common/components/BillingTransactionTab/BillingTransactionListTable/formatters/past-due-days';
import { BillingTransactionStatusBadge } from '../../BillingTransactionStatusBadge';
import { getTypeLabel } from '../../BillingTransactionTab/BillingTransactionListTable/formatters/formatter-util';

const useStyles = makeStyles(theme => ({
  title: {
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  header: {
    padding: theme.spacing(1),
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'inherit'
  },
  overdueDate: {
    color: theme.palette.accountReceivableStatus.greaterThanoneTwentyDays.main
  },
  date: {
    color: theme.palette.text.secondary
  },
  chip: {
    margin: theme.spacing(0, -1, 0, 1),
    flexGrow: 0,
    flexShrink: 0
  }
}));

export const MobileBillingTransactionHeaderRow = ({
  record,
  isAccountReceivable
}) => {
  const classes = useStyles();
  const isRefund =
    record.balanceWithoutAllocationsAmount &&
    record.balanceWithoutAllocationsAmount.amount > 0;
  const type = record.type === 'PAYMENT' && isRefund ? 'REFUND' : record.type;

  return (
    <Grid
      container
      className={classes.header}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item className={classes.title}>
        <Typography className={classes.name} variant="body2" component="div">
          <FormattedMessage id={getTypeLabel({ type, isAccountReceivable })} />
          {record.displayText && (
            <Typography display="inline" variant="body2">
              &nbsp; {record.displayText}
            </Typography>
          )}
        </Typography>
        <div
          className={classNames(classes.overdueDate, {
            [classes.date]: !isAccountReceivable
          })}
        >
          <Typography display="inline" variant="caption">
            {record.transactionDate}
          </Typography>
        </div>
      </Grid>
      <Grid item className={classes.chip}>
        {!isAccountReceivable ? (
          <BillingTransactionStatusBadge
            balanceStatus={record.balanceStatus}
            transactionType={record.type}
            billStatus={record.billStatus}
            draftSubStatus={record.draftSubStatus}
          />
        ) : (
          <PastDueDays field="pastDueDays" record={record} />
        )}
      </Grid>
    </Grid>
  );
};

MobileBillingTransactionHeaderRow.propTypes = {
  record: PropTypes.object,
  isAccountReceivable: PropTypes.bool
};

export default MobileBillingTransactionHeaderRow;
