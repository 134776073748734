import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useState } from 'react';
import get from 'lodash.get';
import {
  billTaxLineItemsQuery,
  PAGE_SIZE as TAX_LINE_ITEMS_PAGE_SIZE
} from '~/modules/billing-invoicing/common/components/TaxLineItems/hooks/useTaxLineItems';
import billingTransactionRefetchQueries from '~/modules/common/components/RefetchQueries/BillingTransactionRefetchQueries';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { filterSummarizeColumnOptions } from '~/modules/billing-invoicing/common/util';
import { billQuery as lineItemQuery, PAGE_SIZE } from './useLineItems';

export const PUT_LINE_ITEMS_FOR_BILL = gql`
  mutation putLineItemForBill($input: LineItemForBillInput!) {
    putLineItemForBill2(input: $input) {
      billItemUri
    }
  }
`;

export const usePutLineItemForBill = () => {
  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const queries = isPsaFpGraphqlOptimizationEnabled
    ? ['getBillingTransactionSummary']
    : ['getDraftTotals', 'getOutstandingBillSummary'];

  const [inProgress, setInProgress] = useState(false);
  const [putLineItemForBill] = useMutation(PUT_LINE_ITEMS_FOR_BILL);

  return {
    putLineItemForBill: async (lineItem, billId, summarizeColumnOptions) => {
      setInProgress(true);
      const filteredSummarizeColumnOptions = filterSummarizeColumnOptions(
        summarizeColumnOptions
      );
      const refetchQueries = [...billingTransactionRefetchQueries, ...queries];

      if (billId) {
        refetchQueries.push({
          query: lineItemQuery,
          variables: {
            page: 1,
            pageSize: PAGE_SIZE,
            id: billId,
            filter: { isAdhoc: false },
            summarizeColumnOptions: filteredSummarizeColumnOptions
          }
        });
        refetchQueries.push({
          query: lineItemQuery,
          variables: {
            page: 1,
            pageSize: PAGE_SIZE,
            id: billId,
            filter: { isAdhoc: true },
            summarizeColumnOptions: filteredSummarizeColumnOptions
          }
        });
        refetchQueries.push({
          query: billTaxLineItemsQuery,
          variables: {
            page: 1,
            pageSize: TAX_LINE_ITEMS_PAGE_SIZE,
            id: billId,
            filter: { isTaxItem: true }
          }
        });
      }

      try {
        const result = await putLineItemForBill({
          variables: {
            input: lineItem
          },
          skip: !billId,
          refetchQueries,
          awaitRefetchQueries: true
        });

        const billingItemUri = get(
          result,
          'data.putLineItemForBill2.billItemUri',
          null
        );

        if (billingItemUri) setInProgress(false);

        return result;
      } catch (exception) {
        setInProgress(false);

        return null;
      }
    },
    inProgress,
    setInProgress
  };
};

export default usePutLineItemForBill;
