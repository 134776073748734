import {
  decomposeColor,
  recomposeColor
} from '@material-ui/core/styles/colorManipulator';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

export const blendColors = (startColor, endColor, ratio) => {
  const startingColor = decomposeColor(startColor);
  const endingColor = decomposeColor(endColor);
  let colorRatio = ratio;

  if (ratio <= 0) colorRatio = 0;
  if (ratio > 1) colorRatio = 1;

  return recomposeColor({
    type: startingColor.type,
    values: startingColor.values.map((startValue, index) => {
      const endValue = endingColor.values[index];

      return startValue * (1 - colorRatio) + endValue * colorRatio;
    })
  });
};

const {
  underAllocated,
  allocated,
  overAllocated
} = theme.palette.resourceAllocation;

export const useAllocationDynamicStyles = ({ loadRatio }) => {
  let backgroundColor = allocated;

  if (loadRatio < 1) {
    backgroundColor = blendColors(underAllocated, allocated, loadRatio);
  }
  if (loadRatio > 1) {
    backgroundColor = overAllocated;
  }

  return {
    dynamicStyle: {
      backgroundColor
    }
  };
};

export default useAllocationDynamicStyles;
