import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';

export const GET_PROJECT_REQUEST_FIELD = gql`
  query ProjectRequestField($id: String!) {
    projectRequest(id: $id) {
      id
      score {
        value
        risk
        investment
        total
        overrideTotal
        effectiveTotal
      }
      estimatedCost {
        amount
        currency {
          id
          displayText
        }
      }
      startDate
      timelineDuration {
        periodQuantity
        periodResolution
      }
      estimatedCostOverride {
        amount
        currency {
          id
          displayText
        }
      }
    }
  }
`;

export const useFetchProjectRequestField = id => {
  const client = useApolloClient();

  return {
    fetchProjectRequestField: () =>
      client.query({
        query: GET_PROJECT_REQUEST_FIELD,
        variables: { id },
        fetchPolicy: 'network-only'
      })
  };
};

export default useFetchProjectRequestField;
