import { useContext, useEffect } from 'react';
import HideFooterContext from '~/modules/navigation/components/PageLayout/HideFooterContext';

export const useHideFooter = () => {
  const setHideFooter = useContext(HideFooterContext);

  useEffect(() => {
    setHideFooter(true);

    return () => {
      setHideFooter(false);
    };
  });
};

export default useHideFooter;
