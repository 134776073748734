import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles, alpha } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Tab from '@material-ui/core/Tab';

import { useMeContext } from '~/modules/me';
import { createTabWidthStyles } from './summaryFilterUtil';
import SummaryTabLabel from './SummaryTabLabel';

const useStyles = isPsaPrpRenameProjectStatus2Enabled =>
  makeStyles(theme => {
    const shadeColor = alpha(theme.palette.action.active, 0.12);
    const border = `1px solid ${shadeColor}`;

    return {
      root: props => ({
        padding: theme.spacing(0, 0.5),
        minHeight: theme.spacing(5),
        ...createTabWidthStyles(theme, isPsaPrpRenameProjectStatus2Enabled),
        borderTop: props.alternativeItem.color ? 'none' : border,
        borderBottom: props.alternativeItem.color ? 'none' : border,
        backgroundColor: props.alternativeItem.color
          ? alpha(props.alternativeItem.color, 0.2)
          : theme.palette.background.paper,
        '&:hover': {
          backgroundColor: props.alternativeItem.color
            ? alpha(props.alternativeItem.color, 0.25)
            : alpha(theme.palette.action.active, 0.05)
        }
      }),
      first: props => ({
        borderLeft: props.alternativeItem.color ? 'none' : border,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius
      }),
      last: props => ({
        borderRight: props.alternativeItem.color ? 'none' : border,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius
      }),
      selected: props => ({
        opacity: 1,
        border: 'none',
        color: props.alternativeItem.color
          ? theme.palette.getContrastText(props.alternativeItem.color)
          : theme.palette.action.active,
        backgroundColor: props.alternativeItem.color
          ? props.alternativeItem.color
          : shadeColor,
        '&:hover': {
          backgroundColor: props.alternativeItem.color
            ? darken(props.alternativeItem.color, 0.05)
            : alpha(theme.palette.action.active, 0.15)
        }
      })
    };
  });

const AlternativeItemTab = ({
  alternativeItem,
  isValueLoading,
  selected,
  first,
  last,
  ...otherProps
}) => {
  const {
    featureFlags: { isPsaPrpRenameProjectStatus2Enabled }
  } = useMeContext();

  const classes = useStyles(isPsaPrpRenameProjectStatus2Enabled)({
    alternativeItem
  });

  return (
    <Tab
      {...otherProps}
      selected={selected}
      className={classNames(classes.root, {
        [classes.first]: first,
        [classes.last]: last,
        [classes.selected]: selected
      })}
      label={useMemo(
        () => (
          <SummaryTabLabel
            showValue
            label={alternativeItem.label}
            value={alternativeItem.value}
            isValueLoading={isValueLoading}
            dataQeId={alternativeItem.dataQeId}
          />
        ),
        [alternativeItem.label, alternativeItem.value, isValueLoading]
      )}
      data-qe-id={alternativeItem.dataQeId && `${alternativeItem.dataQeId}_Tab`}
    />
  );
};

AlternativeItemTab.propTypes = {
  selected: PropTypes.bool,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  alternativeItem: PropTypes.object,
  isValueLoading: PropTypes.bool
};

export default AlternativeItemTab;
