import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { BillingBatchStatus } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  spinner: {
    marginRight: theme.spacing(3),
    color: theme.palette.text.disabled
  },
  progress: {
    paddingLeft: theme.spacing(2)
  }
}));

export const InProgress = ({ classes }) => {
  return (
    <div className={classes.progress}>
      <CircularProgress size={24} className={classes.spinner} />
    </div>
  );
};

InProgress.propTypes = {
  classes: PropTypes.object
};

export const SyncBillRecalculateBatch = ({
  onBatchComplete,
  batchState,
  setBatchState
}) => {
  const classes = useStyles();

  return (
    <BillingBatchStatus
      billingBatchState={batchState}
      setBillingBatchState={setBatchState}
      onBatchComplete={onBatchComplete}
    >
      <InProgress classes={classes} />
    </BillingBatchStatus>
  );
};

SyncBillRecalculateBatch.propTypes = {
  setBatchState: PropTypes.func.isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  batchState: PropTypes.object
};

export default SyncBillRecalculateBatch;
