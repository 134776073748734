import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ResourceRequestIcon } from '~/modules/common/components/Icons';
import { NoDataItem } from '~/modules/common/components';
import ResourcingCard from '../ResourcingCard';
import ResourceReminder from './ResourceReminder';
import ResourceRequestRemindersCardSkeleton from './ResourceRequestRemindersCardSkeleton';
import useResourceRequestRemindersData from './useResourceRequestRemindersData';

const title = <FormattedMessage id="resourcingOverviewReminders.title" />;
const avatar = <ResourceRequestIcon />;

const useContentStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(0)
    }
  },
  noReminder: {
    padding: theme.spacing(1.5, 2)
  }
}));

export const ResourceRequestRemindersCard = () => {
  const { loading, reminders } = useResourceRequestRemindersData();
  const classes = useContentStyles();

  return (
    <ResourcingCard title={title} avatar={avatar}>
      <CardContent className={classes.root}>
        {loading ? (
          <ResourceRequestRemindersCardSkeleton />
        ) : reminders && reminders.length ? (
          reminders.map(reminder => (
            <ResourceReminder key={reminder.messageId} {...reminder} />
          ))
        ) : (
          <div className={classes.noReminder}>
            <NoDataItem>
              <FormattedMessage id="resourcingOverviewReminders.noReminders" />
            </NoDataItem>
          </div>
        )}
      </CardContent>
    </ResourcingCard>
  );
};

export default ResourceRequestRemindersCard;
