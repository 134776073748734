import React from 'react';
import User from '~/modules/common/components/User';
import { clientManagerTag } from './tag';
import { makeCriteriaProvider } from './enhancers';
import ClientManagerFacetDetails from './ClientManagerFacetDetails';

export default {
  makeCriteriaProvider,
  tag: clientManagerTag,
  DetailsControl: ClientManagerFacetDetails,
  // eslint-disable-next-line react/prop-types
  SuggestionItem: ({ option }) => <User user={option} />
};
