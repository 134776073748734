import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  task: {
    fontSize: theme.typography.body2.fontSize
  },
  noUser: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const TaskFormatter = ({ field, record }) => {
  const classes = useStyles();

  return record[field] ? (
    <Typography className={classes.task} component="div">
      {record[field]}
    </Typography>
  ) : (
    <Typography className={classes.noUser} component="div">
      <FormattedMessage id="addLineItemDialog.noTask" />
    </Typography>
  );
};

TaskFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default TaskFormatter;
