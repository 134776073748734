import { ExpenseEntryType } from '~/types';

const getOrDefault = (expense, allowBillable, expenseEntryType) => {
  const { nonBillableAmount, billableAmount } = expense || {};

  const { amount: nonBillableAmountValue } = nonBillableAmount || { amount: 0 };
  const { amount: billableAmountValue } = billableAmount || { amount: 0 };

  const isBillable = expenseEntryType === ExpenseEntryType.Billable;
  const isNonBillable = expenseEntryType === ExpenseEntryType.NonBillable;

  return isBillable
    ? billableAmountValue
    : isNonBillable
    ? nonBillableAmountValue
    : nonBillableAmountValue + billableAmountValue;
};

export const useMonthColumnTotals = ({
  columns,
  showActuals,
  allowBillable,
  monthlyExpenses,
  projectCurrency
}) => {
  const keys = Object.keys(columns).filter(
    r => r.includes('months') || r.includes('totalEstimates')
  );

  return keys.reduce((retVal, curr) => {
    let estimatedSum = 0;
    let actualsSum = 0;

    for (const monthlyExpense of monthlyExpenses) {
      const { expenseEntryType } = monthlyExpense.expenseCode;

      if (!monthlyExpense[curr]) continue;

      estimatedSum += getOrDefault(
        monthlyExpense[curr]?.estimated,
        allowBillable,
        expenseEntryType
      );

      if (showActuals) {
        actualsSum += getOrDefault(
          monthlyExpense[curr]?.actuals,
          allowBillable,
          expenseEntryType
        );
      }
    }

    return {
      ...retVal,
      [curr]: {
        estimated: {
          amount: estimatedSum,
          currency: projectCurrency
        },
        actuals: {
          amount: actualsSum,
          currency: projectCurrency
        },
        allowBillable,
        showActuals
      }
    };
  }, {});
};

export default useMonthColumnTotals;
