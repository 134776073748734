import { object, string, mixed } from 'yup';

export const buildValidationSchema = ({ intl, isProgramRequired }) =>
  object().shape({
    name: string()
      .required(intl.formatMessage({ id: 'projectBasicInfo.nameRequired' }))
      .max(
        255,
        intl.formatMessage({
          id: 'projectBasicInfo.projectNameExceedsMaxLength'
        })
      ),
    code: string().max(
      50,
      intl.formatMessage({ id: 'projectBasicInfo.codeExceedsMaxLength' })
    ),
    description: string().max(
      3000,
      intl.formatMessage({
        id: 'projectBasicInfo.projectDescriptionExceedsMaxLength'
      })
    ),
    slackChannel: string()
      .matches(
        /^[0-9a-z_\-]+$/,
        intl.formatMessage({
          id: 'projectBasicInfo.slackChannelValidationError'
        })
      )
      .max(
        80,
        intl.formatMessage({
          id: 'projectBasicInfo.slackChannelValidationError'
        })
      )
      .nullable(),
    program: isProgramRequired
      ? object().shape({
          id: string()
            .trim()
            .required(
              intl.formatMessage({ id: 'addProject.programIsRequired' })
            )
        })
      : mixed()
  });
