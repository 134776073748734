import React from 'react';
import PropTypes from 'prop-types';
import { DateField } from '~/modules/common/components';
import { mapRepliconDateToJSDate } from '~/modules/common/dates/convert';
import CustomFieldValuePropType from './CustomFieldValuePropType';

export const EditableDateCustomField = ({
  customFieldValue,
  onChange,
  ...rest
}) => {
  const { customField, customFieldDefinition, date } = customFieldValue;

  const { displayText: label } = customField;

  const { dateConfiguration } = customFieldDefinition;

  const { minimumDate, maximumDate } = dateConfiguration || {};

  const minDate = minimumDate
    ? mapRepliconDateToJSDate(minimumDate)
    : undefined;
  const maxDate = maximumDate
    ? mapRepliconDateToJSDate(maximumDate)
    : undefined;

  return (
    <DateField
      editable
      useDateFieldStyles={false}
      label={label}
      value={date}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      {...rest}
    />
  );
};

EditableDateCustomField.propTypes = {
  classes: PropTypes.object,
  customFieldValue: CustomFieldValuePropType,
  onChange: PropTypes.func
};

export default EditableDateCustomField;
