import { useSessionStorage } from '~/modules/common/hooks';
import { sortByDisplayText } from '~/modules/resourcing/common/hooks/useCombinedPagination';
import { useFetchActualUsersAndRoles } from './useFetchActualUsersAndRoles';
import { useFetchAllocatedUsersAndRoles } from './useFetchAllocatedUsersAndRoles';

export const sortRoleByDisplayText = (a, b) => {
  return sortByDisplayText(a.role, b.role);
};

export const getMergedRoles = (rolesArray1 = [], rolesArray2 = []) => {
  const roles = [...rolesArray1, ...rolesArray2].sort(sortRoleByDisplayText);

  return Object.values(
    roles.reduce((accRolesMap, currRole) => {
      const roleUri = currRole.role?.id;
      const { resourceAllocationReference } = accRolesMap[roleUri] || {
        resourceAllocationReference: []
      };

      return {
        ...accRolesMap,
        [roleUri]: {
          ...currRole,
          resourceAllocationReference: [
            ...currRole.resourceAllocationReference,
            ...resourceAllocationReference
          ]
        }
      };
    }, {})
  );
};

export const getMergedUsersAndRoleData = (actuals, allocations) => {
  return ([...actuals, ...allocations] || []).reduce((acc, currrentUser) => {
    const { uri: userUri } = currrentUser.user;

    const { user, roles } = acc[userUri] || {
      user: currrentUser.user,
      roles: [],
      totalHours: 0,
      totalCost: 0
    };

    return {
      ...acc,
      [userUri]: {
        user,
        roles: getMergedRoles(roles, currrentUser.roles)
      }
    };
  }, {});
};

export const GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES =
  'get-resource-plan-allocated-users-and-roles-variables';

export const useFetchResourcePlanUsersAndRoles = ({
  projectId,
  userIds,
  loadingUsers,
  isResourceActualModeEnabled
}) => {
  const { setValue: setVariables } = useSessionStorage(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES,
    null
  );
  const variables = { projectId, userIds };

  const {
    loading: actualsUsersAndRolesLoading,
    actualsUserAndRolesData
  } = useFetchActualUsersAndRoles({
    variables,
    skip: !isResourceActualModeEnabled || loadingUsers || !userIds.length
  });

  const {
    loading: allocatedUsersAndRolesLoading,
    allocatedUsersAndRolesData
  } = useFetchAllocatedUsersAndRoles({
    variables,
    skip: loadingUsers || !userIds.length,
    onCompleted: () => {
      setVariables(variables);
    }
  });

  const allocatedUsersAndRoles = getMergedUsersAndRoleData(
    actualsUserAndRolesData,
    allocatedUsersAndRolesData
  );
  const loading =
    loadingUsers ||
    actualsUsersAndRolesLoading ||
    allocatedUsersAndRolesLoading;

  return {
    allocatedUsersAndRoles: !loading
      ? userIds.map(id => allocatedUsersAndRoles[id]).filter(x => x)
      : [],
    loading
  };
};
