import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import MoneyReadonly from '../../MoneyReadonly';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    opacity: 0.7
  },
  amount: {
    fontSize: theme.typography.body1.fontSize
  }
}));

const FixedBidReadonly = ({ frequency, rate }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="body2" className={classes.title}>
        {frequency.displayText} Fixed
      </Typography>
      <MoneyReadonly className={classes.amount} money={rate} />
    </div>
  );
};

FixedBidReadonly.propTypes = {
  frequency: PropTypes.shape({
    displayText: PropTypes.string.isRequired
  }).isRequired,
  rate: PropTypes.object.isRequired
};

export default FixedBidReadonly;
