import { makeStyles } from '@material-ui/core/styles';
import { projectHandleWidth } from '~/modules/common/charts/timeline/calculations';

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: projectHandleWidth,
    cursor: 'ew-resize',
    userSelect: 'none',
    backgroundColor: theme.palette.grey[600],
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    '&:hover': {
      opacity: 0.6
    }
  },
  rootActive: {
    opacity: 0.6
  },
  rootLeft: {
    borderRight: `1px solid ${theme.palette.grey[800]}`
  },
  rootRight: {
    borderLeft: `1px solid ${theme.palette.grey[800]}`
  },
  handleIcon: {
    color: theme.palette.common.white,
    margin: theme.spacing(0, -0.5)
  },
  tooltip: {
    color: theme.palette.common.white,
    fontSize: theme.typography.caption.fontSize
  }
}));
