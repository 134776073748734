import { useCallback } from 'react';
import get from 'lodash.get';
import * as yup from 'yup';

const schema = yup
  .string()
  .trim()
  .min(1)
  .max(50);

const getNewOptionData = (inputValue, optionLabel) => {
  const trimmedValue = inputValue.trim();

  return {
    label: optionLabel,
    value: trimmedValue
  };
};

const isValidNewOption = (inputValue, selectValue, selectOptions) => {
  const isValid = schema.isValidSync(inputValue);

  if (!isValid) {
    return false;
  }

  return !selectOptions.some(
    x => x.label.toLowerCase() === inputValue.toLowerCase()
  );
};

const useTagFormHelpers = ({ tag, onTagChanged }) => {
  const onCreateTag = useCallback(
    tagName =>
      onTagChanged({
        ...tag,
        tagValue: undefined,
        tagValueId: undefined,
        tagName,
        tagId: undefined
      }),
    [onTagChanged, tag]
  );

  const onSelectTag = useCallback(
    tagOption =>
      onTagChanged({
        ...tag,
        tagValue: undefined,
        tagValueId: undefined,
        tagName: get(tagOption, 'label'),
        tagId: get(tagOption, 'value')
      }),
    [onTagChanged, tag]
  );

  const onCreateTagValue = useCallback(
    tagValue =>
      onTagChanged({
        ...tag,
        tagValue,
        tagValueId: undefined
      }),
    [onTagChanged, tag]
  );

  const onSelectTagValue = useCallback(
    tagValueOption =>
      onTagChanged({
        ...tag,
        tagValue: get(tagValueOption, 'label'),
        tagValueId: get(tagValueOption, 'value')
      }),
    [onTagChanged, tag]
  );

  return {
    getNewOptionData,
    onCreateTag,
    onSelectTag,
    onCreateTagValue,
    onSelectTagValue,
    isValidNewOption
  };
};

export default useTagFormHelpers;
