import TaskFormatter from './TaskFormatter';
import MonthFormatter from './MonthFormatter';
import HoursVariationFormatter from './HoursVariationFormatter';
import DateVariationFormatter from './DateVariationFormatter';
import ActualHoursFormatter from './ActualHoursFormatter';
import HoursFormatter from './HoursFormatter';
import CostFormatter from './CostFormatter';
import ActualCostFormatter from './ActualCostFormatter';
import CostVariationFormatter from './CostVariationFormatter';

const getFormatters = () => ({
  name: TaskFormatter,
  hoursVariation: HoursVariationFormatter,
  dateVariation: DateVariationFormatter,
  totalEstimatedRemainingHours: HoursFormatter,
  totalEstimatedAtCompletionHours: HoursFormatter,
  actualHours: ActualHoursFormatter,
  actualCost: ActualCostFormatter,
  month: MonthFormatter,
  estimatedExpenses: CostFormatter,
  totalEstimatedRemainingCost: CostFormatter,
  totalEstimatedAtCompletionCost: CostFormatter,
  costVariation: CostVariationFormatter
});

export default getFormatters;
