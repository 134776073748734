import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import {
  getEquivalentRolledUpTaskStatus,
  getRolledUpEstimatedAtCompletionHours
} from '~/modules/common/components/ProjectDrawer';
import { TaskCardTitle2, TaskCardFooter } from '../TaskCard/components';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.grey[50],
    width: '100%',
    zIndex: 10
  },
  cardBorder: {
    borderBottom: `4px solid #d7d7d7`
  }
}));

const useCardHeaderStyles = makeStyles(theme => ({
  root: {
    lineHeight: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingBottom: theme.spacing(1.5)
  },
  content: {
    overflow: 'hidden'
  }
}));

export const ProjectCard2 = ({
  canViewCostData,
  canViewEstimate,
  hasTasks,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  isRTL,
  missingEstimatesInProject,
  numberFormat,
  projectDetails: {
    endDate,
    projectCode,
    projectName,
    startDate,
    status: { name: statusName },
    budgetHours
  },
  rolledUpSummary: rolledUpSummaryInput,
  rolledUpCostSummary
}) => {
  const classes = useStyles();
  const cardHeaderClasses = useCardHeaderStyles();
  const intl = useIntl();
  const status = getEquivalentRolledUpTaskStatus({
    status: statusName,
    actualHours: rolledUpSummaryInput.actualHours
  });
  const taskCardTitle = useMemo(
    () => (
      <TaskCardTitle2
        name={projectName}
        code={projectCode}
        startDate={startDate}
        endDate={endDate}
        taskStatus={status}
        isProject
      />
    ),
    [endDate, projectCode, projectName, startDate, status]
  );

  const includeRolledUpCostSummary =
    isPsaPpmCostEacEnhancements2Enabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled;

  const rolledUpSummary = useMemo(
    () => ({
      ...rolledUpSummaryInput,
      totalEstimatedAtCompletionHours: getRolledUpEstimatedAtCompletionHours({
        status: statusName,
        actualHours: rolledUpSummaryInput.actualHours,
        totalEstimatedAtCompletionHours:
          rolledUpSummaryInput.totalEstimatedAtCompletionHours
      })
    }),
    [statusName, rolledUpSummaryInput]
  );

  return (
    <Card
      className={classNames(classes.card, {
        [classes.cardBorder]: hasTasks
      })}
      square
      elevation={0}
    >
      <CardHeader classes={cardHeaderClasses} title={taskCardTitle} />

      {isRolledUpTaskEstimateCalculationMethodEnabled && (
        <TaskCardFooter
          canViewCostData={canViewCostData}
          canViewEstimate={canViewEstimate}
          estimatedHours={budgetHours}
          intl={intl}
          isRTL={isRTL}
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacEnhancements2Enabled={
            isPsaPpmCostEacEnhancements2Enabled
          }
          isPsaRmpTaskAllocation1Enabled
          isProject
          missingEstimatesInProject={missingEstimatesInProject}
          numberFormat={numberFormat}
          rolledUpSummary={rolledUpSummary}
          rolledUpCostSummary={
            includeRolledUpCostSummary ? rolledUpCostSummary : undefined
          }
          taskStatus={status}
        />
      )}
    </Card>
  );
};

ProjectCard2.propTypes = {
  canViewEstimate: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  hasTasks: PropTypes.bool,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  isRolledUpTaskEstimateCalculationMethodEnabled: PropTypes.bool,
  isRTL: PropTypes.bool,
  missingEstimatesInProject: PropTypes.bool,
  numberFormat: PropTypes.object,
  projectDetails: PropTypes.object,
  rolledUpSummary: PropTypes.object,
  rolledUpCostSummary: PropTypes.object
};

export default ProjectCard2;
