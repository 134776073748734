import { gql } from 'graphql-tag';

export const CLIENT_DEFAULT_DATA_QUERY = gql`
  query client($uri: String!, ${`$isPsaPrpBillingDefaultForProjectEnabled: Boolean = false`}) {
    client(uri: $uri) {
      id
      displayText
      defaultInvoicePaymentTerm
      invoiceCurrency {
        id
        displayText
        symbol
      }
      invoiceTemplate {
        id
        displayText
      }
      taxProfile @include(if: $isPsaPrpBillingDefaultForProjectEnabled) {
        id
        displayText
        description
        enabled
      }
      billingContactInfo {
        address
        city
        country {
          id
          displayText
        }
        email
        faxNumber
        phoneNumber
        stateProvince
        website
        zipPostalCode
      }
      billingSettings {
        billLineItemsBy
        description
        internalNotes
        showComments
      }
    }
  }
`;
