import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ReportProblemOutlined, RemoveSharp } from '@material-ui/icons';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  missingEstimateContainer: {
    display: 'flex'
  },
  variationIcon: {
    fontSize: theme.spacing(2.5),
    marginRight: theme.spacing(0.25)
  },
  disabledIcon: {
    fill: theme.palette.taskRollUp.missing.light
  },
  missingIcon: {
    fill: theme.palette.taskRollUp.missing.main
  },
  disabledText: {
    color: theme.palette.taskRollUp.missing.light
  },
  label: {
    fontSize: theme.spacing(2) - 1,
    fontWeight: '600'
  },
  labelWithFFOn: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize
  }
});

export const TooltipMissingEstimate = ({
  intl,
  isVariationRow = false,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles();

  return (
    <div className={classes.missingEstimateContainer}>
      {!isVariationRow && (
        <ReportProblemOutlined className={classes.variationIcon} />
      )}
      <RemoveSharp
        className={classNames(classes.variationIcon, {
          [classes.disabledIcon]: isVariationRow,
          [classes.missingIcon]: !isVariationRow
        })}
      />
      <span
        className={classNames({
          [classes.disabledText]: isVariationRow,
          [classes.label]: !isVariationRow,
          [classes.labelWithFFOn]:
            isPsaRmpTaskAllocation1Enabled && !isVariationRow
        })}
      >
        {intl.formatMessage({
          id: 'projectTasksPage.na'
        })}
      </span>
    </div>
  );
};

TooltipMissingEstimate.propTypes = {
  intl: PropTypes.object.isRequired,
  isVariationRow: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default TooltipMissingEstimate;
