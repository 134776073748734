import { useHasPermission } from '~/modules/common/permissions';
import {
  PROJECT_REQUEST_DAL,
  PROJECT_REQUEST_PERMISSION
} from '~/modules/common/enums';

export const useProjectRequestPermissions = () => {
  return {
    canEditProjectRequest: useHasPermission({
      actionUri: PROJECT_REQUEST_PERMISSION.EDIT_PROJECT_REQUEST
    }),
    canViewAllProjectRequest: useHasPermission({
      actionUri: PROJECT_REQUEST_PERMISSION.VIEW_PROJECT_REQUEST,
      dataAccessLevelUri: PROJECT_REQUEST_DAL.ALL
    }),
    canViewPlanningData: useHasPermission({
      actionUri: PROJECT_REQUEST_PERMISSION.VIEW_PLANNING_DATA
    })
  };
};
export default useProjectRequestPermissions;
