import { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import deepEqual from 'fast-deep-equal';
import { ResourceAllocationStatus } from '~/types';
import { useMeContext } from '~/modules/me';
import { useProjectResourcePlanPermissions } from '~/modules/resourcing/hooks';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useIsMount, useSessionStorage } from '~/modules/common/hooks';
import ALLOCATIONS_QUERY from './useProjectCommitedAllocationQuery';

export const removeDuplicatesAndMerge = (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev;

  const existingAllocationIds = prev.resourceAllocations.resourceAllocations.map(
    r => r.id
  );
  const newAllocations = fetchMoreResult.resourceAllocations.resourceAllocations.filter(
    r => !existingAllocationIds.includes(r.id)
  );

  return {
    ...prev,
    resourceAllocations: {
      ...prev.resourceAllocations,
      resourceAllocations: [
        ...prev.resourceAllocations.resourceAllocations,
        ...newAllocations
      ],
      nextPageCursor: fetchMoreResult.resourceAllocations.nextPageCursor
    }
  };
};

export const getFilterInput = ({ searchText, roleUris, userIds }) => {
  const hasFilter =
    Boolean(searchText) || Boolean(roleUris) || Boolean(userIds);

  return hasFilter
    ? {
        roles: roleUris,
        searchText,
        users: userIds
      }
    : undefined;
};

const useCompletedProjectResourceAllocations = ({
  projectUri,
  sort,
  dateRange,
  searchText,
  roleUris,
  userIds,
  isResourceActualModeEnabled
}) => {
  const isMounted = useIsMount();
  const { permissionsMap } = useMeContext();

  const [loadingMore, setLoadingMore] = useState(false);

  const {
    isViewAdvancedSettingsEnabled,
    isViewAvailabilitySettingsEnabled
  } = useProjectResourcePlanPermissions(permissionsMap);

  const formattedDateRange = useMemo(
    () =>
      dateRange
        ? {
            startDate: dateToMidnightUTCString(dateRange.startDate),
            endDate: dateToMidnightUTCString(dateRange.endDate)
          }
        : null,
    [dateRange]
  );

  const variables = {
    projectUri,
    limit: 50,
    allocationStatusList: [ResourceAllocationStatus.Committed],
    sort,
    showTimeOff: Boolean(
      formattedDateRange && isViewAvailabilitySettingsEnabled
    ),
    chartDateRange: formattedDateRange,
    showHolidays: Boolean(formattedDateRange && isViewAdvancedSettingsEnabled),
    filter: getFilterInput({ searchText, roleUris, userIds })
  };

  const { storedValue, setValue } = useSessionStorage(
    'RESOURCE-ALLOCATIONS-QUERY-VARIABLES',
    null
  );

  useEffect(() => {
    if (!deepEqual(storedValue, variables)) {
      setValue(variables);
    }
  }, [variables, setValue, storedValue]);

  const { loading, error, data, fetchMore, refetch } = useQuery(
    ALLOCATIONS_QUERY,
    {
      variables,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  useEffect(() => {
    if (!isMounted && isResourceActualModeEnabled) refetch();
  }, [refetch, isResourceActualModeEnabled, isMounted]);

  const entries = get(data, 'resourceAllocations.resourceAllocations') || [];

  const nextPageCursor = get(data, 'resourceAllocations.nextPageCursor');

  const hasMoreRows = Boolean(nextPageCursor);

  const loadMoreRows = useCallback(async () => {
    if (loading || loadingMore || !hasMoreRows) return;

    setLoadingMore(true);
    try {
      await fetchMore({
        query: ALLOCATIONS_QUERY,
        variables: {
          ...variables,
          cursor: nextPageCursor
        },
        updateQuery: removeDuplicatesAndMerge
      });
    } finally {
      setLoadingMore(false);
    }
  }, [loading, loadingMore, hasMoreRows, fetchMore, variables, nextPageCursor]);

  return {
    loadingRows: loading,
    loadingMore,
    resourceAllocations: entries,
    error,
    loadMoreRows,
    hasMoreRows: Boolean(nextPageCursor),
    refetch
  };
};

export default useCompletedProjectResourceAllocations;
