import { useState, useMemo } from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import {
  mapRepliconDateToJSDate,
  mapIsoStringtoUtcObject,
  momentDateToISOString
} from '~/modules/common/dates/convert';
import {
  createFixedWidthView,
  useFixedRange
} from '~/modules/common/charts/timeline/calculations';
import { GROUP_RESOURCE_REQUESTS_PANEL_WIDTH } from '../widthConstants';
import useDefaultSort from './useDefaultSort';

const useResourceUsersChartState = ({
  resourceRequest,
  width,
  leftComponentWidth,
  defaultScale,
  availabilityFilterLoadValue,
  scoreComputeStatus,
  isAvailabilityEnabled
}) => {
  const [sortOverrideByUser, setSortOverrideByUser] = useState(false);

  const defaultSort = useDefaultSort(scoreComputeStatus, isAvailabilityEnabled);

  const [matchScore, setMatchScore] = useState(0);
  const [sort, setSort] = useState(defaultSort);
  const [refreshData, setRefreshData] = useState(false);

  const [avalabilityState, setAvailabilityState] = useState({
    startDate: moment(
      mapRepliconDateToJSDate(
        mapIsoStringtoUtcObject(resourceRequest.startDate)
      )
    ),
    endDate: moment(
      mapRepliconDateToJSDate(mapIsoStringtoUtcObject(resourceRequest.endDate))
    ),
    availability: availabilityFilterLoadValue
  });

  const range = {
    startDate: momentDateToISOString(avalabilityState.startDate),
    endDate: momentDateToISOString(avalabilityState.endDate)
  };

  const rangeView = createFixedWidthView(
    Math.floor(width),
    leftComponentWidth + GROUP_RESOURCE_REQUESTS_PANEL_WIDTH
  );

  const fixedRange = useFixedRange({
    getPeriodCount: rangeView.getPeriodCount,
    anchor: 0,
    defaults: {
      date: DateTime.fromISO(range.startDate).toUTC(),
      scale: defaultScale
    }
  });

  return useMemo(
    () => ({
      fixedRange,
      avalabilityState,
      setAvailabilityState,
      refreshData,
      setRefreshData,
      sort,
      setSort,
      matchScore,
      setMatchScore,
      sortOverrideByUser,
      setSortOverrideByUser
    }),
    [
      fixedRange,
      avalabilityState,
      setAvailabilityState,
      refreshData,
      setRefreshData,
      sort,
      setSort,
      matchScore,
      setMatchScore,
      sortOverrideByUser,
      setSortOverrideByUser
    ]
  );
};

export default useResourceUsersChartState;
