import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

import { useMeContext } from '~/modules/me';
import { createTabWidthStyles } from './summaryFilterUtil';
import SummaryTabLabel from './SummaryTabLabel';

const useStyles = isPsaPrpRenameProjectStatus2Enabled =>
  makeStyles(theme => {
    const shadeColor = alpha(theme.palette.action.active, 0.12);
    const border = `1px solid ${shadeColor}`;

    return {
      root: {
        padding: theme.spacing(0, 1),
        minHeight: theme.spacing(5),
        ...createTabWidthStyles(theme, isPsaPrpRenameProjectStatus2Enabled),
        borderTop: border,
        borderBottom: border,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          backgroundColor: alpha(theme.palette.action.active, 0.05)
        }
      },
      first: {
        borderLeft: border,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius
      },
      last: {
        borderRight: border,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius
      },
      separator: { borderLeft: border },
      subSelected: {
        opacity: 1,
        border: 'none',
        color: theme.palette.action.active,
        backgroundColor: shadeColor,
        '&:hover': {
          backgroundColor: alpha(theme.palette.action.active, 0.15)
        }
      }
    };
  });

const AllItemTab = ({
  allItem,
  subSelected,
  first,
  last,
  showValue,
  isValueLoading,
  isPsaPrpAccessibilityIssuesEnabled,
  ...otherProps
}) => {
  const {
    featureFlags: { isPsaPrpRenameProjectStatus2Enabled }
  } = useMeContext();

  const classes = useStyles(isPsaPrpRenameProjectStatus2Enabled)();

  const label = useMemo(
    () => (
      <SummaryTabLabel
        showValue={showValue}
        label={allItem.label}
        value={allItem.value}
        isValueLoading={isValueLoading}
        dataQeId={allItem.dataQeId}
      />
    ),
    [allItem.label, allItem.value, showValue, isValueLoading]
  );

  if (isPsaPrpAccessibilityIssuesEnabled)
    return (
      <Tab
        {...otherProps}
        className={classNames(classes.root, {
          [classes.first]: first,
          [classes.last]: last,
          [classes.separator]: !first,
          [classes.subSelected]: subSelected
        })}
        label={label}
        data-qe-id={allItem.dataQeId && `${allItem.dataQeId}_Tab`}
        tabIndex={0}
      />
    );

  return (
    <Tab
      {...otherProps}
      className={classNames(classes.root, {
        [classes.first]: first,
        [classes.last]: last,
        [classes.separator]: !first,
        [classes.subSelected]: subSelected
      })}
      label={label}
      data-qe-id={allItem.dataQeId && `${allItem.dataQeId}_Tab`}
    />
  );
};

AllItemTab.propTypes = {
  subSelected: PropTypes.bool.isRequired,
  showValue: PropTypes.bool,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  allItem: PropTypes.object,
  isValueLoading: PropTypes.bool,
  isPsaPrpAccessibilityIssuesEnabled: PropTypes.bool
};

export default AllItemTab;
