import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import { useMeContext } from '~/modules/me/useMeContext';

export const getRequiredBillingPermission = ({ id: billingTypeUri = '' }) => {
  switch (billingTypeUri) {
    case 'urn:replicon:billing-type:time-and-material':
      return 'urn:replicon:project-action:view-billing-rates';

    case 'urn:replicon:billing-type:fixed-bid':
      return 'urn:replicon:project-action:view-billing-data';

    case 'urn:replicon:billing-type:billing-contract':
      return 'urn:replicon:project-action:view-billing-contracts';

    default:
      return '';
  }
};

export const permissionsMap = {
  canEditProject: actionMap =>
    actionMap.includes('urn:replicon:project-action:edit-project'),
  canViewBilling: (actionMap, { billingType }) =>
    actionMap.includes(getRequiredBillingPermission(billingType || {})) &&
    actionMap.includes('urn:replicon:project-action:view-billing-data'),
  canEditBilling: actionMap =>
    actionMap.includes('urn:replicon:project-action:edit-billing-data'),
  canViewProjectBillingData: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling &&
        actionMap.includes('urn:replicon:project-action:view-billing-data')
      : actionMap.includes('urn:replicon:project-action:view-billing-data'),
  canViewBillingContracts: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling &&
        actionMap.includes('urn:replicon:project-action:view-billing-contracts')
      : actionMap.includes(
          'urn:replicon:project-action:view-billing-contracts'
        ),
  canEditBillingContracts: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling &&
        actionMap.includes('urn:replicon:project-action:edit-billing-contracts')
      : actionMap.includes(
          'urn:replicon:project-action:edit-billing-contracts'
        ),
  canViewProjectEstimates: actionMap =>
    actionMap.includes('urn:replicon:project-action:view-project-estimates'),
  canEditProjectEstimates: actionMap =>
    actionMap.includes('urn:replicon:project-action:edit-project-estimates'),
  canViewRevenueContracts: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling &&
        actionMap.includes('urn:replicon:project-action:view-revenue-contract')
      : actionMap.includes('urn:replicon:project-action:view-revenue-contract'),
  canEditRevenueContracts: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling &&
        actionMap.includes('urn:replicon:project-action:edit-revenue-contract')
      : actionMap.includes('urn:replicon:project-action:edit-revenue-contract'),
  canViewProjectCostData: actionMap =>
    actionMap.includes('urn:replicon:project-action:view-cost-data'),
  canViewBillingRates: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling &&
        actionMap.includes('urn:replicon:project-action:view-billing-rates')
      : actionMap.includes('urn:replicon:project-action:view-billing-rates'),
  canEditBillingRates: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling &&
        actionMap.includes('urn:replicon:project-action:edit-billing-rates')
      : actionMap.includes('urn:replicon:project-action:edit-billing-rates'),
  canViewDocuments: actionMap =>
    actionMap.includes('urn:replicon:project-action:view-documents'),
  canEditDocuments: actionMap =>
    actionMap.includes('urn:replicon:project-action:edit-documents'),
  canRecalculateBillingData: actionMap =>
    actionMap.includes('urn:replicon:project-action:recalculate-billing-item'),
  canViewTasks: actionMap =>
    actionMap.includes('urn:replicon:project-action:view-tasks'),
  canEditTasks: actionMap =>
    actionMap.includes('urn:replicon:project-action:edit-tasks'),
  canViewTeam: actionMap =>
    actionMap.includes('urn:replicon:project-action:view-team'),
  canViewCostTypes: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasCostType &&
        actionMap.includes('urn:replicon:project-action:view-cost-type')
      : actionMap.includes('urn:replicon:project-action:view-cost-type'),
  canEditCostTypes: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasCostType &&
        actionMap.includes('urn:replicon:project-action:edit-cost-type')
      : actionMap.includes('urn:replicon:project-action:edit-cost-type'),
  canViewExpenseCodes: actionMap =>
    actionMap.includes('urn:replicon:project-action:view-expense-codes'),
  canEditExpenseCodes: actionMap =>
    actionMap.includes('urn:replicon:project-action:edit-expense-codes'),
  canViewPortfolio: (
    actionMap,
    { templateSettings, isPsaPrpPsaPpmMergerEnabled }
  ) =>
    isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasPortfolio &&
        actionMap.includes('urn:replicon:project-action:view-portfolio')
      : actionMap.includes('urn:replicon:project-action:view-portfolio')
};

export const useProjectInfoPermissions = ({
  permittedActionUris,
  billingType,
  projectTemplateSetting
}) => {
  const {
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = useMeContext();
  const projectPermissions = {};
  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  Object.keys(permissionsMap).forEach(key => {
    projectPermissions[key] = permissionsMap[key](permittedActionUris || [], {
      billingType,
      templateSettings,
      isPsaPrpPsaPpmMergerEnabled
    });
  });

  return projectPermissions;
};

export default useProjectInfoPermissions;
