import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { POLICYTYPE } from '~/modules/common/enums';
import useEmployeeTypes from '~/modules/common/enhancers/useEmployeeTypes';
import { onChange } from '../enhancers/facetHelpers';

import GroupFacetDetails from '../GroupFacetDetails';

export const EmployeeTypeFacetDetails = ({
  className,
  selected,
  setSelected,
  policy = POLICYTYPE.PROJECT_MANAGEMENT
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const noneOptionText = formatMessage({
    id: 'employeeTypeDropdown.noEmployeeType'
  });

  const { employeeTypes, loading, hasMore } = useEmployeeTypes({
    searchTerm,
    policy,
    includeNoneOption: true,
    noneOptionText
  });

  return (
    <GroupFacetDetails
      label={formatMessage({ id: 'search.employeeType' })}
      className={className}
      selected={selected}
      onChange={onChange}
      setSelected={setSelected}
      isLoading={loading}
      setSearchTerm={setSearchTerm}
      options={employeeTypes}
      noOptionsLabel={formatMessage({
        id: 'employeeTypeDropdown.noEmployeeType'
      })}
      hasMore={hasMore}
      optionTypeText={formatMessage({
        id: 'moreOptions.employeeType'
      })}
    />
  );
};

EmployeeTypeFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  policy: PropTypes.oneOf([
    POLICYTYPE.PROJECT_MANAGEMENT,
    POLICYTYPE.PORTFOLIO_MANAGEMENT,
    POLICYTYPE.RESOURCE_MANAGEMENT,
    POLICYTYPE.REVENUE_MANAGEMENT
  ])
};

export default EmployeeTypeFacetDetails;
