import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { isNullOrUndefined } from '~/modules/common/util';
import { MoneyValue } from '~/modules/common/components/Money';

const useStyles = makeStyles({
  value: {
    fontWeight: 600,
    fontSize: theme.spacing(1.75)
  },
  noValue: {
    fontWeight: 400
  }
});

export const CostFormatter = ({ field, record }) => {
  const classes = useStyles();
  const value = record[field];

  return isNullOrUndefined(value) ? (
    <FormattedMessage id="taskDrawer.na" />
  ) : (
    <MoneyValue
      className={value.amount !== 0 ? classes.value : classes.noValue}
      money={value}
    />
  );
};

CostFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default CostFormatter;
