import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import { editors } from '~/modules/projects/project/ScriptParamsEditableCard/components/editors';
import { formatters } from '~/modules/projects/project/ScriptParamsEditableCard/components/formatters';
import { ExpenseType } from '~/modules/projects/project/BillPlanV2/ExpenseType';
import { DateRange } from '~/modules/common/components';
import { ExpenseAmountEditor } from './ExpenseAmountEditor';
import { ExpenseRowLabelFormatter } from './ExpenseRowLabelFormatter';
import { EstimateAmountFormatter } from './EstimateAmountFormatter';
import { useColumnVisibilityProp } from './useColumnVisibilityProp';
import { BillableTypeFormatter } from './BillableTypeFormatter';

const getTotalEstimatesLabel = (
  projectDateRange,
  hasStartDate,
  hasEndDate,
  showActuals
) => (
  <>
    <FormattedMessage
      id={
        showActuals
          ? 'expenseBillPlan.totalEstimatesAndActuals'
          : 'expenseBillPlan.totalEstimates'
      }
    />
    {projectDateRange ? (
      <div>
        <DateRange
          start={hasStartDate ? projectDateRange.startDate : null}
          end={hasEndDate ? projectDateRange.endDate : null}
        />
      </div>
    ) : null}
  </>
);

export const buildColumns = ({
  parameters,
  classes,
  setFieldValue,
  editable,
  onDeleteRow,
  canDeleteRow,
  errors,
  context,
  projectUri,
  isMobile,
  alignDeleteRow,
  allowBillable,
  showActuals,
  chartDates,
  projectCurrency,
  viewSummary,
  projectDateRange,
  hasStartDate,
  hasEndDate,
  canEditExpenseCodes,
  canEditBillingContracts,
  canViewBillingData,
  showDeleteRow,
  isLabelRowColumnVisible,
  isBillableTypeRowColumnVisible,
  billableColumns,
  ...rest
}) => {
  const columnAlign = {
    integer: 'right',
    money: 'right',
    'read-only-amount': 'right',
    numeric: 'right',
    boolean: 'center'
  };

  const getAllowBillableCondition = id =>
    id === 'urn:replicon:script-key:parameter:markup';
  const isBillable = id =>
    id === 'urn:replicon:script-key:parameter:markup' && billableColumns.length;
  const columns = parameters.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.id]: {
        projectUri,
        ...current,
        id: current.id,
        value: current.displayText,
        label: isMobile ? current.displayText : null,
        visible:
          getAllowBillableCondition(current.id) &&
          isBillable(current.id) &&
          canViewBillingData &&
          (editable || viewSummary) &&
          current.visible,
        className: classes[current.type],
        align: columnAlign[current.type],
        setFieldValue,
        errors,
        isMobile,
        hasMaxVal: true,
        ...rest
      }
    }),
    {}
  );

  const monthlyBreakdownColumns = chartDates.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.key]: {
        id: curr.key,
        visible: editable || viewSummary,
        value: curr.label,
        startDate: curr.start,
        endDate: curr.end,
        setFieldValue,
        align: 'center',
        allowBillable,
        showActuals,
        projectCurrency,
        viewSummary,
        projectDateRange
      }
    }),
    {}
  );

  return {
    expenseCode: {
      id: 'expenseCode',
      visible: true,
      value: <FormattedMessage id="expenseBillPlan.type" />,
      align: 'left'
    },
    ...columns,
    billableType: {
      id: 'billableType',
      editable,
      visible: isBillableTypeRowColumnVisible && canViewBillingData,
      value: <FormattedMessage id="expenseBillPlan.billableType" />,
      align: 'left',
      setFieldValue
    },
    estimate: {
      id: 'estimate',
      visible: context === 'timeAndExpense' && !editable && !viewSummary,
      value: <FormattedMessage id="expenseBillPlan.estimate" />,
      align: 'right'
    },
    ...monthlyBreakdownColumns,
    totalEstimates: {
      id: 'totalEstimates',
      visible: editable || viewSummary,
      value: getTotalEstimatesLabel(
        projectDateRange,
        hasStartDate,
        hasEndDate,
        showActuals
      ),
      align: 'center',
      showActuals,
      setFieldValue,
      allowBillable,
      projectCurrency,
      viewSummary,
      projectDateRange,
      hasStartDate,
      hasEndDate
    },
    deleteRow: {
      id: 'deleteRow',
      visible: showDeleteRow,
      value: '',
      align: alignDeleteRow || 'right',
      className: classes.delete,
      onDeleteRow
    }
  };
};

const getEditorForChartDates = chartDates =>
  chartDates.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.key]: ExpenseAmountEditor
    }),
    {}
  );

export const useListColumns = ({
  parameters,
  classes,
  setFieldValue,
  customEditors,
  customFormatters,
  editable,
  context,
  onDeleteRow,
  canDeleteRow,
  errors,
  alignDeleteRow,
  chartDates,
  allowBillable,
  showActuals,
  projectCurrency,
  viewSummary,
  canEditBillingContracts,
  billableColumns,
  ...rest
}) => {
  const {
    isLabelRowColumnVisible,
    isBillableTypeRowColumnVisible
  } = useColumnVisibilityProp({
    viewSummary,
    editable
  });

  return useColumns({
    columns: buildColumns({
      parameters,
      classes,
      setFieldValue,
      editable,
      context,
      onDeleteRow,
      errors,
      canDeleteRow,
      alignDeleteRow,
      chartDates,
      allowBillable,
      showActuals,
      projectCurrency,
      viewSummary,
      canEditBillingContracts,
      isLabelRowColumnVisible,
      isBillableTypeRowColumnVisible,
      billableColumns,
      ...rest
    }),
    renders: {
      ...(editable
        ? editors({ parameters, customEditors })
        : formatters({ parameters, customFormatters })),
      labelRow: ExpenseRowLabelFormatter,
      ...getEditorForChartDates(chartDates),
      totalEstimates: ExpenseAmountEditor,
      expenseCode: ExpenseType,
      estimate: EstimateAmountFormatter,
      billableType: BillableTypeFormatter
    }
  });
};
