import React, { useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { useAppContext } from '~/modules/App/AppContext';

const messagePosition = { vertical: 'bottom', horizontal: 'left' };
const slideUp = ({ in: openCloseState, children }) => {
  return (
    <Slide
      in={openCloseState}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      direction="up"
    />
  );
};

export const ResourceRequestActionMessageSnackBar = () => {
  const {
    state: { resourceRequestButtonActionMessage },
    dispatch
  } = useAppContext();

  const hideMessage = useCallback(() => {
    dispatch({
      type: 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION',
      resourceRequestButtonActionMessage: null
    });
  }, [dispatch]);

  return (
    <Snackbar
      open={Boolean(resourceRequestButtonActionMessage)}
      autoHideDuration={5000}
      onClose={hideMessage}
      TransitionComponent={slideUp}
      anchorOrigin={messagePosition}
      message={resourceRequestButtonActionMessage}
    />
  );
};

export default ResourceRequestActionMessageSnackBar;
