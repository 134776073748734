const dateFormats = {
  'MMMM d, yy': 'MMMM D, YY',
  'MMMM d, yyyy': 'MMMM D, YYYY',
  'd MMMM yy': 'D MMMM YY',
  'd MMMM yyyy': 'D MMMM YYYY',
  'yy MMMM d': 'YY MMMM D',
  'yyyy MMMM d': 'YYYY MMMM D',
  "MM'/'dd'/'yy": 'MM/DD/YY',
  "MM'/'dd'/'yyyy": 'MM/DD/YYYY',
  "dd'/'MM'/'yy": 'DD/MM/YY',
  "dd'/'MM'/'yyyy": 'DD/MM/YYYY',
  "yy'/'MM'/'dd": 'YY/MM/DD',
  "yyyy'/'MM'/'dd": 'YYYY/MM/DD',
  "yy'/'dd'/'MM": 'YY/DD/MM',
  "yyyy'/'dd'/'MM": 'YYYY/DD/MM',
  'MMM d, yy': 'MMM D, YY',
  'MMM d, yyyy': 'MMM D, YYYY',
  'MM.dd.yy': 'MM.DD.YY',
  'MM.dd.yyyy': 'MM.DD.YYYY',
  'dd.MM.yy': 'DD.MM.YY',
  'dd.MM.yyyy': 'DD.MM.YYYY'
};

export const dateFormatMap = format => {
  return dateFormats[format] || 'MMM D, YYYY';
};
