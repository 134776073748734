import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

const DELETE_CERTIFICATE = gql`
  mutation deleteCertificate($input: DeleteCertificateInput!) {
    deleteUserCertificate2(input: $input) {
      userCertificateUri
    }
  }
`;

export const useDeleteCertificateMutation = () =>
  useMutation(DELETE_CERTIFICATE);

export const useDeleteCertificate = ({ userCertificateUri }) => {
  const [deleteUserCertificate] = useDeleteCertificateMutation();

  const deleteCertificate = useCallback(async () => {
    await deleteUserCertificate({
      variables: {
        input: { userCertificateUri }
      },
      refetchQueries: ['getResourceCertificates'],
      awaitRefetchQueries: true
    });
  }, [userCertificateUri, deleteUserCertificate]);

  return { deleteCertificate };
};

export default useDeleteCertificate;
