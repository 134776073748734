import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ListTable,
  PAGINATION_TYPE,
  ListTableThemeProvider,
  ListTableNavigationContext
} from '~/modules/common/components/ListTable';
import { NoDataItem, CardExpansionPanel } from '~/modules/common/components';
import {
  useTaskResourceAllocations,
  useTaskResourceAllocationSummary
} from '~/modules/common/hooks';
import InheritResources from './InheritResources';
import TaskResourceAllocationTitle from './TaskResourceAllocationTitle';
import TaskResourceAllocationsFooter from './TaskResourceAllocationsFooter';
import useFormState from './useFormState';
import useAutoSaveChangeHandlers from './useAutoSaveChangeHandlers';
import {
  useStyles,
  usePanelStyles,
  useColumnStyles,
  useAllocationStyles,
  useListTableThemeProviderStyles
} from './useStyles';
import { useListColumns } from './columns';

const noDataString = (
  <FormattedMessage id="taskResourceAssignments.noResourceAssignments" />
);

const TASK_RESOURCE_ALLOCATIONS_PAGE_SIZE = 10;

export const TaskResourceAllocations = ({
  assignedRole,
  classes: overrideClasses,
  copyParentTaskResourceAllocations = false,
  editable = false,
  errors,
  fromPanel = false,
  hasMore = false,
  initialEstimatedHours,
  isUpdating = false,
  loadMore,
  loading = false,
  onInheritResourcesChange,
  onResourceAllocationAdd,
  onResourceAllocationRemove,
  onResourceHoursBlur,
  onResourceHoursChange,
  paginationType = PAGINATION_TYPE.SCROLLABLE,
  parentTaskId,
  projectSlug,
  resourceAllocationCount = 0,
  resourceAllocations = [],
  showRemoveResourceConfirmation = false,
  taskId,
  totalAllocatedHours
}) => {
  const classes = useStyles({ classes: overrideClasses });
  const columnStyles = useColumnStyles();
  const listClasses = useMemo(() => ({ card: classes.list }), [classes.list]);
  const tableClasses = useListTableThemeProviderStyles({ editable });
  const footerClasses = useMemo(() => ({ root: classes.footer }), [
    classes.footer
  ]);

  const { headers, columns } = useListColumns({
    classes: columnStyles,
    editable,
    errors,
    isUpdating,
    onHoursBlur: onResourceHoursBlur,
    onHoursChange: onResourceHoursChange,
    onRemove: onResourceAllocationRemove,
    showRemoveResourceConfirmation
  });

  const handleResourceAdd = useCallback(
    user => {
      onResourceAllocationAdd({
        resource: { id: user.id, displayText: user.displayText },
        allocatedHours:
          resourceAllocationCount === 0 ? initialEstimatedHours : null
      });
    },
    [initialEstimatedHours, onResourceAllocationAdd, resourceAllocationCount]
  );

  return (
    <div className={classes.root} data-qe-id="TaskResourceAllocations">
      {!fromPanel && <TaskResourceAllocationTitle />}
      {parentTaskId && (
        <InheritResources
          taskId={parentTaskId}
          checked={copyParentTaskResourceAllocations}
          onChange={onInheritResourcesChange}
        />
      )}
      {!loading &&
        resourceAllocationCount === 0 &&
        !copyParentTaskResourceAllocations && (
          <div className={classes.noData}>
            <NoDataItem>{noDataString}</NoDataItem>
          </div>
        )}
      {(loading || resourceAllocationCount > 0) && (
        <ListTableThemeProvider classes={tableClasses}>
          <ListTable
            classes={listClasses}
            variant="table"
            headers={headers}
            columns={columns}
            hasMore={hasMore}
            isLoading={loading}
            loadMore={loadMore}
            elevation={0}
            records={resourceAllocations.map(a => ({
              ...a,
              key: a.resource.id
            }))}
            paginationType={paginationType}
          />
        </ListTableThemeProvider>
      )}
      {!loading && (
        <TaskResourceAllocationsFooter
          classes={footerClasses}
          taskId={taskId}
          projectSlug={projectSlug}
          editable={editable}
          isUpdating={isUpdating}
          assignedRole={assignedRole}
          disabled={copyParentTaskResourceAllocations}
          onResourceAdd={handleResourceAdd}
          dataQeId="TaskResourceAllocationsFooter"
          resourceAllocations={resourceAllocations}
          initialEstimatedHours={initialEstimatedHours}
          totalAllocatedHours={totalAllocatedHours}
        />
      )}
    </div>
  );
};

export const TaskResourceAllocationsWithExpansionPanel = ({
  taskId,
  editable,
  projectSlug,
  assignedRole,
  initialEstimatedHours
}) => {
  const classes = usePanelStyles();
  const allocationsClasses = useAllocationStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    loadingMore,
    hasMore,
    loadMore,
    resourceAllocations
  } = useTaskResourceAllocations({
    taskId,
    page: currentPage
  });

  const {
    loading: summaryLoading,
    resourceAllocationSummary
  } = useTaskResourceAllocationSummary({
    taskId
  });

  const titleComponentProps = useMemo(
    () => ({
      resourceAllocations,
      resourceAllocationCount: resourceAllocationSummary?.count
    }),
    [resourceAllocations, resourceAllocationSummary]
  );

  const navigationContext = useMemo(
    () => ({
      navigatePage: setCurrentPage,
      currentPage,
      maximumPageSize: TASK_RESOURCE_ALLOCATIONS_PAGE_SIZE,
      disableInfiniteScroll: true
    }),
    [setCurrentPage, currentPage]
  );

  const { values, errors, dirty, setFieldValue } = useFormState({
    resourceAllocations
  });

  const {
    isUpdating,
    onResourceHoursBlur,
    onResourceHoursChange,
    onResourceAllocationAdd,
    onResourceAllocationRemove
  } = useAutoSaveChangeHandlers({ taskId, dirty, setFieldValue });

  return (
    <CardExpansionPanel
      classes={classes}
      elevation={0}
      TitleComponent={TaskResourceAllocationTitle}
      titleComponentProps={titleComponentProps}
    >
      <ListTableNavigationContext.Provider value={navigationContext}>
        <TaskResourceAllocations
          assignedRole={assignedRole}
          classes={allocationsClasses}
          editable={editable}
          errors={errors.resourceAllocations}
          fromPanel
          hasMore={hasMore}
          initialEstimatedHours={initialEstimatedHours}
          isUpdating={isUpdating}
          loadMore={loadMore}
          loading={loading || loadingMore || summaryLoading}
          onResourceAllocationAdd={onResourceAllocationAdd}
          onResourceAllocationRemove={onResourceAllocationRemove}
          onResourceHoursBlur={onResourceHoursBlur}
          onResourceHoursChange={onResourceHoursChange}
          paginationType={PAGINATION_TYPE.NAVIGATION}
          projectSlug={projectSlug}
          resourceAllocationCount={resourceAllocationSummary?.count || 0}
          resourceAllocations={values.resourceAllocations}
          showRemoveResourceConfirmation
          taskId={taskId}
          totalAllocatedHours={resourceAllocationSummary?.totalAllocatedHours}
        />
      </ListTableNavigationContext.Provider>
    </CardExpansionPanel>
  );
};

TaskResourceAllocationsWithExpansionPanel.propTypes = {
  taskId: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  assignedRole: PropTypes.object,
  projectSlug: PropTypes.string.isRequired,
  initialEstimatedHours: PropTypes.number
};

TaskResourceAllocations.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.array,
  taskId: PropTypes.string,
  editable: PropTypes.bool,
  fromPanel: PropTypes.bool,
  parentTaskId: PropTypes.string,
  assignedRole: PropTypes.object,
  projectSlug: PropTypes.string.isRequired,
  initialEstimatedHours: PropTypes.number,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  isUpdating: PropTypes.bool,
  paginationType: PropTypes.oneOf([
    PAGINATION_TYPE.NAVIGATION,
    PAGINATION_TYPE.SCROLLABLE
  ]),
  onResourceHoursBlur: PropTypes.func,
  onResourceHoursChange: PropTypes.func,
  onResourceAllocationAdd: PropTypes.func,
  onResourceAllocationRemove: PropTypes.func,
  resourceAllocations: PropTypes.array.isRequired,
  totalAllocatedHours: PropTypes.number,
  resourceAllocationCount: PropTypes.number.isRequired,
  showRemoveResourceConfirmation: PropTypes.bool,
  copyParentTaskResourceAllocations: PropTypes.bool,
  onInheritResourcesChange: PropTypes.func
};

export default TaskResourceAllocations;
