import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { getScheduleRulesFromAllocationPeriods } from '~/modules/common/hooks/resourcing';
import {
  getDateRangeFromScheduleRules,
  getTotalHours,
  mergePeriodRuleIntoScheduleRules,
  mergePeriodRulesIntoScheduleRules
} from '~/modules/resourcing/common/util';
import { updateResourceAllocation } from '~/modules/resourcing/components/ResourceAssignmentDialog/resourceRequestAllocationsReducer';

const useResourceAllocationDateRangeChangeHandler = async ({
  estimatedDate,
  resourceAllocation,
  onAllocationChange,
  getResourceUserAvailabilityHoursSeriesForDateRange
}) => {
  const allocationStart = mapIsoStringtoUtcObject(resourceAllocation.startDate);
  const allocationEnd = mapIsoStringtoUtcObject(resourceAllocation.endDate);

  const estimatedStartDate = mapRepliconDateToUtcObject(
    estimatedDate.startDate
  );
  const estimatedEndDate = mapRepliconDateToUtcObject(estimatedDate.endDate);

  let updatedScheduleRules = [];

  if (
    estimatedStartDate.equals(allocationStart) &&
    allocationEnd.equals(estimatedEndDate)
  )
    return;

  if (
    estimatedStartDate > allocationStart ||
    allocationEnd > estimatedEndDate
  ) {
    const newPeriodRuleDateRange =
      estimatedStartDate > allocationStart
        ? {
            endDate: estimatedStartDate.minus({ days: 1 }),
            startDate: allocationStart
          }
        : {
            endDate: allocationEnd,
            startDate: estimatedEndDate.plus({ days: 1 })
          };

    updatedScheduleRules = mergePeriodRuleIntoScheduleRules(
      resourceAllocation.scheduleRules,
      true
    )({
      dateRange: newPeriodRuleDateRange,
      do: { load: 0, setHours: 0, excludeWeekdays: [] }
    });
  } else {
    const newPeriodRuleDateRange =
      estimatedStartDate < allocationStart
        ? {
            endDate: allocationStart.minus({ days: 1 }).toISO(),
            startDate: estimatedStartDate.toISO()
          }
        : {
            endDate: estimatedEndDate.toISO(),
            startDate: allocationEnd.plus({ days: 1 }).toISO()
          };

    const {
      availabilityHoursSeriesByDay
    } = await getResourceUserAvailabilityHoursSeriesForDateRange(
      resourceAllocation.user.userUri,
      newPeriodRuleDateRange
    );

    const periodRuleWithUpdatedRules = getScheduleRulesFromAllocationPeriods(
      availabilityHoursSeriesByDay
    );

    updatedScheduleRules = mergePeriodRulesIntoScheduleRules(
      resourceAllocation.scheduleRules,
      true
    )(periodRuleWithUpdatedRules);
  }

  const updatedTotalHours = getTotalHours({
    scheduleRules: updatedScheduleRules
  });

  const dateRange = getDateRangeFromScheduleRules(updatedScheduleRules);

  const alloc = !dateRange
    ? null
    : {
        ...resourceAllocation,
        ...dateRange,
        quantity: undefined,
        load: 100,
        totalHours: updatedTotalHours,
        __typename: 'ResourceAllocation',
        scheduleRules: updatedScheduleRules
      };

  onAllocationChange(updateResourceAllocation(alloc));
};

export default useResourceAllocationDateRangeChangeHandler;
