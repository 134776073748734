import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '~/modules/common/components';

export const RevenueRecognitionAction = ({
  onCancel,
  onSave,
  validateForm,
  saveable,
  isSaving
}) => {
  const handleSave = useCallback(async () => {
    if (validateForm) {
      const errors = await validateForm();

      if (errors && Object.keys(errors).length) return;
    }
    if (saveable) {
      await onSave();
    }
  }, [onSave, saveable, validateForm]);

  return (
    <Grid item xs={12} container spacing={0}>
      <Grid item xs={12} container justifyContent="flex-end" spacing={0}>
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={!saveable || isSaving}
          isLoading={isSaving}
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
RevenueRecognitionAction.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saveable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  validateForm: PropTypes.func
};

export default RevenueRecognitionAction;
