import { Grid, makeStyles, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getError, hasError } from '~/util';
import { PortfoliosDropdown } from '~/modules/common/components';
import { PORTFOLIOS_DROPDOWN_DATA_VARIANT } from '~/modules/common/components/PortfoliosDropDown/enum';
import { useFormOnChange } from '../ProjectRequestDrawer/hooks';

const useStyles = makeStyles(() => ({
  container: {}
}));

const resourceLabels = {
  name: <FormattedMessage id="projectRequest.name" />,
  description: <FormattedMessage id="projectRequest.description" />,
  requestedBy: <FormattedMessage id="projectRequest.requestedBy" />
};

export const AddProjectRequestForm = ({
  classes: classesOverride,
  hidePortfolioDropdown
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { values, errors, setFieldValue, setFieldError } = useFormikContext();
  const { name, description, requestedBy, portfolio } = values;

  const {
    onNameChange,
    onDescriptionChange,
    onRequestedByChange,
    onPortfolioChange
  } = useFormOnChange({ setFieldValue, setFieldError });

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="name"
          id="project-request-name"
          variant="filled"
          fullWidth
          required
          label={resourceLabels.name}
          value={name}
          onChange={onNameChange}
          autoFocus
          autoComplete="off"
          error={hasError(errors, 'name')}
          helperText={getError(errors, 'name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="description"
          id="project-request-description"
          multiline
          rows={6}
          variant="filled"
          fullWidth
          required
          label={resourceLabels.description}
          value={description}
          onChange={onDescriptionChange}
          error={hasError(errors, 'description')}
          helperText={getError(errors, 'description')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="requestedBy"
          id="project-request-requestedby"
          variant="filled"
          fullWidth
          label={resourceLabels.requestedBy}
          value={requestedBy}
          onChange={onRequestedByChange}
          autoComplete="off"
          error={hasError(errors, 'requestedBy')}
          helperText={getError(errors, 'requestedBy')}
        />
      </Grid>
      {!hidePortfolioDropdown && (
        <Grid item xs={12}>
          <PortfoliosDropdown
            value={portfolio}
            onChange={onPortfolioChange}
            dataVariant={PORTFOLIOS_DROPDOWN_DATA_VARIANT.REQUEST}
            hasError={hasError(errors, 'portfolio')}
            helperText={getError(errors, 'portfolio')}
          />
        </Grid>
      )}
    </Grid>
  );
};

AddProjectRequestForm.propTypes = {
  hidePortfolioDropdown: PropTypes.bool,
  classes: PropTypes.object
};
export default AddProjectRequestForm;
