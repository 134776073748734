import React from 'react';
import PropTypes from 'prop-types';
import { Decimal, NoValue } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';

export const ResourceRequired = ({ record }) => {
  const { resourceAssignmentsCount } = record;

  return !isNullOrUndefined(resourceAssignmentsCount) ? (
    <Decimal value={resourceAssignmentsCount} precision={2} />
  ) : (
    <NoValue />
  );
};

ResourceRequired.propTypes = {
  record: PropTypes.object.isRequired
};
