import React from 'react';
import { PropTypes } from 'prop-types';
import { Container, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchField from '~/modules/common/components/SearchField';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    '& > div': {
      backgroundColor: theme.palette.grey[100]
    },
    zIndex: theme.zIndex.drawer - 1
  },
  hasMore: {
    ...theme.typography.caption,
    fontStyle: 'italic',
    color: theme.palette.text.hint,
    padding: theme.spacing(0, 1)
  }
}));

const TaskOwnerSearchMenuList = React.forwardRef(
  ({ searchText, onSearchChange, loading, children, title, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <>
        {title}
        <Container className={classes.searchContainer}>
          <SearchField value={searchText} onChange={onSearchChange} />
          {loading && <LinearProgress />}
        </Container>
        <ul ref={ref} {...rest}>
          {children}
        </ul>
      </>
    );
  }
);

TaskOwnerSearchMenuList.propTypes = {
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.object,
  title: PropTypes.string
};

export default TaskOwnerSearchMenuList;
