import React, { memo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableRow as MuiTableRow, makeStyles } from '@material-ui/core';
import ListTableVariantContext from '../../../ListTableVariantContext';

const useStyles = makeStyles(theme => ({
  selected: {
    backgroundColor: theme.palette.grey[100]
  }
}));

export const TableRow = ({
  children,
  record,
  onRowClick,
  className,
  selected,
  hover,
  ...rest
}) => {
  const classes = useStyles();
  const variant = useContext(ListTableVariantContext);

  const Component = variant === 'table' ? MuiTableRow : 'div';

  const onClickHandler = useCallback(() => onRowClick && onRowClick(record), [
    onRowClick,
    record
  ]);

  return (
    <Component
      onClick={onClickHandler}
      className={classNames(className, selected && classes.selected)}
      hover={variant === 'table' ? hover : undefined}
      {...rest}
    >
      {children}
    </Component>
  );
};

TableRow.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  record: PropTypes.object,
  onRowClick: PropTypes.func,
  selected: PropTypes.bool,
  hover: PropTypes.bool,
  className: PropTypes.string
};

export default memo(TableRow);
