import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';

const DELETE_PROJECT_REQUEST = gql`
  mutation deleteProjectRequest($id: String!) {
    deleteProjectRequest(id: $id)
  }
`;

export const useDeleteProjectRequest = () => {
  const [deleteProjectRequest] = useMutation(DELETE_PROJECT_REQUEST);

  return {
    deleteProjectRequest: async requestId => {
      await deleteProjectRequest({
        variables: {
          id: requestId
        },
        skip: !requestId,
        refetchQueries: [
          'getPageOfProjectRequests',
          'Eager_GetProjectRequestsCountSummary'
        ]
      });
    }
  };
};

export default useDeleteProjectRequest;
