import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import useMeContext from '~/modules/me/useMeContext';
import { useStyledGroupLabels } from '~/modules/common/components/Groups/hooks';
import SkillsReadOnly from '~/modules/resource-management/resourceUserDetails/Skills/SkillsReadOnly';
import GroupItems from '~/modules/projects/resourcing-plan/components/GroupItems';
import TagsReadOnly from '~/modules/resource-management/resourceUserDetails/tags/TagsReadOnly';
import { WeightTag } from '~/modules/resourcing/common/components';
import { User } from '~/modules/common/components';
import GroupsTitle from '../GroupsTitle';
import ResourcePoolTitle from '../ResourcePoolTitle';
import { PreferredResourcesReadOnlyTable } from '../../PreferredResources/components';
import ResourcePoolReadOnly from './ResourcePoolReadOnly';
import ReadOnlyGroupField from './ReadOnlyGroupField';

const useUserSecondaryOverrideStyles = makeStyles(() => ({
  secondary: {
    display: 'flex'
  }
}));

const useUserStyles = makeStyles(theme => ({
  container: {
    width: 'max-content',
    margin: theme.spacing(0, 0.5)
  }
}));

export const useExpandableSectionsReadOnly = ({
  skills,
  resourceRequest,
  maxSkillLevel,
  openImportanceDialog
}) => {
  const intl = useIntl();
  const userOverrideClasses = useUserSecondaryOverrideStyles();
  const userClasses = useUserStyles();

  const { groupSettings, permissionsMap } = useMeContext();

  const canViewSkills = permissionsMap['urn:replicon:skill-action:view-skill'];

  const canViewTags =
    permissionsMap['urn:replicon:user-action:view-custom-fields'];

  const styledGroupLabels = useStyledGroupLabels();

  const { tags, requestAttributeWeights } = resourceRequest;

  const [groupItemsMap, resourcePoolContent] = useMemo(() => {
    const groups = [
      'costCenter',
      'division',
      'department',
      'employeeType',
      'location',
      'serviceCenter'
    ].reduce(
      (acc, key) => ({
        ...acc,
        ...(resourceRequest[key] &&
          groupSettings[key].isEnabled && {
            [`${key}`]: {
              value: resourceRequest[key],
              Component: (
                <ReadOnlyGroupField
                  groupSettings={groupSettings[key]}
                  value={resourceRequest[key]}
                  label={styledGroupLabels[key]}
                  component="span"
                  tag={
                    // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
                    <WeightTag
                      weight={resourceRequest.requestAttributeWeights?.[key]}
                      onClick={openImportanceDialog}
                    />
                  }
                />
              )
            }
          })
      }),
      {}
    );

    const resourcePool =
      (resourceRequest?.resourcePools || []).length > 0
        ? {
            value: resourceRequest.resourcePools,
            Component: (
              <ResourcePoolReadOnly
                resourcePools={resourceRequest.resourcePools}
                component="span"
                importanceTag={
                  // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
                  <WeightTag
                    weight={
                      resourceRequest.requestAttributeWeights?.resourcePools
                    }
                    onClick={openImportanceDialog}
                  />
                }
              />
            )
          }
        : null;

    return [groups, resourcePool];
  }, [resourceRequest, groupSettings, styledGroupLabels, openImportanceDialog]);

  const [groupsTitle, resourcePoolTitle] = useMemo(() => {
    const groups =
      Object.keys(groupItemsMap) && Object.keys(groupItemsMap).length > 0 ? (
        <GroupsTitle key="groups-title-read-only" itemsMap={groupItemsMap} />
      ) : null;

    const resourcePool =
      resourcePoolContent?.value.length > 0 ? (
        <ResourcePoolTitle
          key="resource-pool-title-read-only"
          resourcePools={resourcePoolContent.value}
        />
      ) : null;

    return [groups, resourcePool];
  }, [groupItemsMap, resourcePoolContent]);

  const groupContent = useMemo(() => {
    if (resourcePoolContent?.value.length > 0) {
      groupItemsMap.resourcePools = resourcePoolContent;
    }
    const groupSectionInfo =
      Object.keys(groupItemsMap) && Object.keys(groupItemsMap).length > 0 ? (
        <GroupItems itemsMap={groupItemsMap} key="group-items-read-only" />
      ) : null;

    return [groupSectionInfo].filter(Boolean);
  }, [groupItemsMap, resourcePoolContent]);

  const expandableSections = useMemo(() => {
    const skillsInfo = {
      key: 'skills',
      label: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.skills'
      }),
      labelTag: (
        <WeightTag
          weight={requestAttributeWeights?.skills}
          onClick={openImportanceDialog}
        />
      ),
      collapsedDetails: (resourceRequest.skills || [])
        .map(skill => skill.displayText)
        .join(', '),
      content: <SkillsReadOnly skills={skills} maxSkillLevel={maxSkillLevel} />
    };

    const groupsSection = {
      key: 'groups',
      label: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.groups'
      }),
      collapsedDetails: [groupsTitle, resourcePoolTitle].filter(Boolean),
      content: groupContent
    };

    const tagsInfo = {
      key: 'tags',
      label: intl.formatMessage({
        id: 'resourceRequestDrawerDetails.tags'
      }),
      labelTag: (
        <WeightTag
          weight={requestAttributeWeights?.tags}
          onClick={openImportanceDialog}
        />
      ),
      collapsedDetails: tags?.length
        ? tags
            .map(({ tag, value }) => `${tag.name}: ${value?.value || '-'}`)
            .join(', ')
        : null,
      content: <TagsReadOnly tags={tags}></TagsReadOnly>
    };

    const { preferredResources } = resourceRequest;

    const preferredResourcesInfo = preferredResources?.length
      ? {
          key: 'preferredResources',
          label: intl.formatMessage({
            id: 'resourceRequestPreferredResources.preferredResources'
          }),
          labelTag: (
            <WeightTag
              weight={requestAttributeWeights?.preferredResources}
              onClick={openImportanceDialog}
            />
          ),
          overrideClasses: userOverrideClasses,
          collapsedDetails: preferredResources?.length
            ? preferredResources.map(r => (
                <User
                  key={r.displayText}
                  user={r}
                  classes={userClasses}
                  showTooltip
                />
              ))
            : null,
          content: (
            <PreferredResourcesReadOnlyTable
              preferredResources={preferredResources}
            />
          )
        }
      : null;

    return [
      preferredResourcesInfo,
      canViewSkills &&
      resourceRequest.skills &&
      resourceRequest.skills.length > 0
        ? skillsInfo
        : null,
      groupsSection.content?.length > 0 ? groupsSection : null,
      canViewTags && tags && tags.length > 0 ? tagsInfo : null
    ].filter(section => section);
  }, [
    intl,
    requestAttributeWeights,
    openImportanceDialog,
    resourceRequest,
    skills,
    maxSkillLevel,
    groupsTitle,
    resourcePoolTitle,
    groupContent,
    tags,
    userOverrideClasses,
    canViewSkills,
    canViewTags,
    userClasses
  ]);

  return expandableSections;
};
