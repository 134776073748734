import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { StyledChartRangeItem2 } from '~/modules/common/charts/timeline/components';
import { QuickAllocationProjectChartHeaderTimeItems } from './components';
import useStyles from './useStyles';

const QuickAllocationProjectChartHeader = ({
  chartDates,
  classes: classesOverrides,
  leftComponent,
  leftComponentWidth,
  scale
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const timeItems = useMemo(
    () => (
      <QuickAllocationProjectChartHeaderTimeItems
        scale={scale}
        chartDates={chartDates}
      />
    ),
    [scale, chartDates]
  );

  return (
    <>
      <StyledChartRangeItem2
        classes={classes}
        scale={scale}
        scaleItemCount={chartDates.length}
        chartStartDate={chartDates.length && chartDates[0].start}
        leftComponentWidth={leftComponentWidth}
      >
        {leftComponent}
        {timeItems}
      </StyledChartRangeItem2>
    </>
  );
};

QuickAllocationProjectChartHeader.propTypes = {
  classes: PropTypes.object,
  leftComponent: PropTypes.node.isRequired,
  chartDates: PropTypes.array,
  scale: PropTypes.string.isRequired,
  leftComponentWidth: PropTypes.number.isRequired
};

export default QuickAllocationProjectChartHeader;
