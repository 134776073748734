import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { dateToMidnightUTCObject } from '~/modules/common/dates/convert';
import { useProjectCostPermissions } from './useProjectCostPermissions';

export const GET_PROJECT_REQUEST_PLANNING_DETAILS = gql`
  query projectRequest($id: String!, $includeEstimatedCost: Boolean = false) {
    projectRequest(id: $id) {
      id
      startDate
      timelineDuration {
        periodQuantity
        periodResolution
      }
      estimatedCost @include(if: $includeEstimatedCost) {
        amount
        currency {
          id
          displayText
        }
      }
      estimatedCostOverride @include(if: $includeEstimatedCost) {
        amount
        currency {
          id
          displayText
        }
      }
    }
  }
`;

const mapPeriodResolutionToLuxonDurationObjectUnits = periodResolution =>
  ({ WEEK: 'weeks', MONTH: 'months', QUARTER: 'quarters', YEAR: 'years' }[
    periodResolution
  ] || 'months');

export const computeEndDate = ({
  startDate,
  periodQuantity,
  periodResolution
}) => {
  if (!startDate) {
    return null;
  }

  return dateToMidnightUTCObject(startDate)
    .plus({
      [mapPeriodResolutionToLuxonDurationObjectUnits(
        periodResolution
      )]: periodQuantity
    })
    .minus({ days: 1 });
};
export const useProjectRequestPlanningData = ({ id }) => {
  const {
    canEditProjectEstimates,
    canEditProjectCostData
  } = useProjectCostPermissions();

  const { data, loading } = useQuery(GET_PROJECT_REQUEST_PLANNING_DETAILS, {
    variables: {
      id,
      includeEstimatedCost: canEditProjectEstimates && canEditProjectCostData
    },
    fetchPolicy: 'network-only'
  });

  const { startDate, timelineDuration, estimatedCost, estimatedCostOverride } =
    !loading && data ? data.projectRequest : {};

  return {
    startDate: startDate && dateToMidnightUTCObject(startDate).toObject(),
    endDate: computeEndDate({
      startDate,
      periodQuantity: timelineDuration?.periodQuantity,
      periodResolution: timelineDuration?.periodResolution
    })?.toObject(),
    estimatedCost: estimatedCostOverride || estimatedCost
  };
};

export default useProjectRequestPlanningData;
