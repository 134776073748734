import {
  String,
  StringOrNoValue,
  User,
  Date,
  Money
} from '~/modules/common/components/ListTable/renderers';
import { HierarchyColumn } from '~/modules/common/components/HierarchyColumn';
import NameColumn from './name';
import Name from './styledName';
import StatusColumn from './status';
import { Score, Total } from './Score';
import CreateProjectColumn from './createProjectColumn';
import { ResourceRequired } from './ResourceRequired';
import { EstimatedDuration } from './EstimatedDuration';

const getFormatters = isExisting => {
  return {
    name: isExisting ? Name : NameColumn,
    status: StatusColumn,
    description: String,
    requestedBy: StringOrNoValue,
    portfolio: HierarchyColumn,
    createdBy: User,
    createProject: CreateProjectColumn,
    investment: Score,
    risk: Score,
    value: Score,
    effectiveTotal: Total,
    startDate: Date,
    estimatedDuration: EstimatedDuration,
    resourceAssignmentsCount: ResourceRequired,
    estimatedCostInBaseCurrency: Money,
    estimatedCostInPortfolioCurrency: Money
  };
};

export default getFormatters;
