import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import NoValue from '~/modules/common/components/NoValue';
import User from '~/modules/common/components/User';

const styles = theme => ({
  comment: {
    paddingTop: '0px',
    paddingBottom: '3px',
    fontSize: theme.typography.caption.fontSize
  },
  commentOverride: {
    paddingTop: '3px',
    paddingBottom: '3px',
    fontSize: theme.typography.body2.fontSize
  },
  commentContent: {
    fontSize: theme.typography.caption.fontSize,
    paddingBottom: '6px'
  },
  commentOverrideContent: {
    fontSize: theme.typography.body2.fontSize
  },
  divider: {
    backgroundColor: theme.palette.divider
  }
});

export const CommentContent = ({
  classes,
  comment,
  commentOverride,
  isContentForTooltip = false,
  showOverride,
  user,
  me
}) => {
  const userClasses = useMemo(
    () => ({ name: classes.comment, avatar: classes.comment }),
    [classes.comment]
  );

  const userOverrideClasses = useMemo(
    () => ({ name: classes.commentOverride, avatar: classes.commentOverride }),
    [classes.commentOverride]
  );

  if (!showOverride) return comment;
  if (!(Boolean(comment) || Boolean(commentOverride))) return <NoValue />;

  const commentContentForTooltip = comment && (
    <div className={classes.commentContent}>
      <User user={user} classes={userClasses} />
      {comment}
    </div>
  );
  const commentOverrideContentForToolTip = commentOverride && (
    <div className={classes.commentOverrideContent}>
      <User user={me} classes={userOverrideClasses} />
      {commentOverride}
    </div>
  );
  const divider = isContentForTooltip ? (
    <Divider variant="fullWidth" className={classes.divider} />
  ) : (
    <br />
  );

  return (
    <>
      {/* empty div is to suppress safari tooltip */}
      <div />
      {isContentForTooltip ? commentContentForTooltip : comment}
      {comment && commentOverride && divider}
      {isContentForTooltip ? commentOverrideContentForToolTip : commentOverride}
    </>
  );
};

CommentContent.propTypes = {
  comment: PropTypes.string,
  commentOverride: PropTypes.string,
  isContentForTooltip: PropTypes.bool,
  showOverride: PropTypes.bool.isRequired,
  user: PropTypes.object,
  me: PropTypes.object,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CommentContent);
