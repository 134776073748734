import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PERIOD_RESOLUTION_ENUM } from '~/modules/resourcing/common/enums';

export const GetSmartFitResourceAvailabilityHoursOptionsQuery = gql`
  query getSmartFitResourceAvailabilityHoursOptionsQuery(
    $userUri: String!
    $scheduleRules: [ScheduleRuleInput!]!
    $periodResolution: PeriodResolutionOption!
  ) {
    smartFitResourceAvailablityHoursOptions(
      userUri: $userUri
      scheduleRules: $scheduleRules
      periodResolution: $periodResolution
    ) {
      availableScheduleRulesWhatFits {
        dateRange {
          startDate
          endDate
        }
        do
      }
      extendAllocationScheduleRules {
        dateRange {
          startDate
          endDate
        }
        do
      }
      redistributeAllocationScheduleRules {
        dateRange {
          startDate
          endDate
        }
        do
      }
      allocateFullScheduleRules {
        dateRange {
          startDate
          endDate
        }
        do
      }
      redistributeHours
      extendedHours
      requestedHours
      allocateFullHours
      availableHoursWhatFits
    }
  }
`;

const useGetSmartFitResourceAvailablityHoursOptions = ({
  userUri,
  scale,
  scheduleRules
}) => {
  const { loading, error, data } = useQuery(
    GetSmartFitResourceAvailabilityHoursOptionsQuery,
    {
      fetchPolicy: 'network-only',
      variables: {
        userUri,
        periodResolution: PERIOD_RESOLUTION_ENUM[scale],
        scheduleRules
      }
    }
  );

  return {
    isLoading: loading,
    smartFitmentDetails: data && data.smartFitResourceAvailablityHoursOptions,
    error
  };
};

export default useGetSmartFitResourceAvailablityHoursOptions;
