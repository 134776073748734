import { useState } from 'react';

export const useBillingTransactionState = () => {
  const [includedTransactionItems, setIncludedTransactionItems] = useState([]);
  const [allSelectedStatus, setAllSelectedStatus] = useState(0);
  const [transactionItems, setTransactionItems] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [excludedTransactionItems, setExcludedTransactionItems] = useState([]);

  return {
    includedTransactionItems,
    setIncludedTransactionItems,
    allSelectedStatus,
    setAllSelectedStatus,
    transactionItems,
    setTransactionItems,
    isSyncing,
    setIsSyncing,
    excludedTransactionItems,
    setExcludedTransactionItems
  };
};

export default useBillingTransactionState;
