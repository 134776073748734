import { DateTime } from 'luxon';
import { useGetChartDates as useGetChartDatesFromAnchorDate } from '~/modules/resourcing/common/hooks/useGetChartDates';

export const getDefaultDate = (chartStartDate, chartEndDate, today) => {
  const anchorDate =
    chartEndDate && today > chartEndDate
      ? chartEndDate
      : chartStartDate && today < chartStartDate
      ? chartStartDate
      : today;

  return anchorDate;
};

export const useGetChartDates = ({ width, chartStartDate, chartEndDate }) => {
  const today = DateTime.utc();
  const anchorDate = getDefaultDate(chartStartDate, chartEndDate, today);

  return useGetChartDatesFromAnchorDate({ width, anchorDate });
};
