import { useState } from 'react';

export function useSubStatusDropdown({
  billSubStatus = null,
  setFieldValue,
  handleSubmit
}) {
  const [open, setOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(billSubStatus);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleStatusChange = e => {
    if (e?.target?.value?.id) {
      setFieldValue('draftSubStatusUri', e.target.value.id);
      setCurrentStatus(e.target.value);
      handleSubmit();
    }
  };

  return {
    open,
    currentStatus,
    handleClose,
    handleOpen,
    handleStatusChange
  };
}
