import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { GET_PAGE_OF_CHILDREN_PORTFOLIOS } from '~/modules/portfolios/portfolio/hooks/usePageOfChildrenPortfolios';

export const PUT_PORTFOLIO = gql`
  mutation putPortfolio($input: PutPortfolioInput!) {
    putPortfolio(input: $input) {
      portfolioUri
      portfolioSlug
    }
  }
`;

export const updateCache = input => (cache, { data }) => {
  const cachedData = cache.readQuery({
    query: GET_PAGE_OF_CHILDREN_PORTFOLIOS,
    variables: {
      page: 1,
      pageSize: 10,
      parentId: input.parentId
    }
  });

  const newSubPortfolio = {
    id: data.putPortfolio?.portfolioUri,
    slug: data.putPortfolio?.portfolioSlug,
    displayText: input.name,
    __typeName: 'PortfolioReference'
  };

  const storedData = (cachedData && cachedData.childPortfolios) || [];

  cache.writeQuery({
    query: GET_PAGE_OF_CHILDREN_PORTFOLIOS,
    variables: {
      page: 1,
      pageSize: 10,
      parentId: input.parentId
    },
    data: {
      childPortfolios: [newSubPortfolio, ...storedData]
    }
  });
};

export const usePutPortfolio = ({ doCacheUpdate, refetchQueries } = {}) => {
  const [putPortfolio] = useMutation(PUT_PORTFOLIO);

  return {
    putPortfolio: input => {
      return putPortfolio({
        variables: {
          input
        },
        refetchQueries: refetchQueries || [
          'getPageOfPortfolios',
          'getQuickFilterSummaryCount'
        ],
        update: doCacheUpdate ? updateCache(input) : undefined
      });
    }
  };
};

export default usePutPortfolio;
