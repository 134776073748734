import { useMemo } from 'react';
import get from 'lodash.get';
import {
  useTableSettings,
  useSetListSort
} from '~/modules/common/components/ListTable';
import { useHasPermission } from '~/modules/common/permissions';
import useEnabledColumns from './useEnabledColumns';

const getSort = ({ currentSort }) => {
  if (!currentSort) {
    return null;
  }

  return {
    field: currentSort.field,
    direction: currentSort.direction
  };
};

const useProjectRequestListTableSettings = ({ tableKey }) => {
  const canEditProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });
  const { enabledColumnNames, enabledColumnGroups } = useEnabledColumns({
    classes: {},
    canEditProject
  });

  const { data: tableSettingsData } = useTableSettings({
    tableKey,
    defaultColumns: enabledColumnNames
  });
  const tableColumns = get(tableSettingsData, 'columns');
  const currentSort = get(tableSettingsData, 'sort');

  const { onSortChange, loading: sortLoading } = useSetListSort({
    tableKey,
    currentColumns: tableColumns,
    currentSort
  });

  const sortCriteria = useMemo(() => getSort({ currentSort }), [currentSort]);

  const selectedColumnNames = tableSettingsData?.columns;

  return {
    tableKey,
    enabledColumnGroups,
    enabledColumnNames,
    selectedColumnNames,
    sortLoading,
    sortCriteria,
    onSortChange
  };
};

export default useProjectRequestListTableSettings;
