import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  container: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.5, 2, 0, 2)
  }
}));

const GroupTitleHeader = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classNames(classes.container, className)}>{children}</div>
    </div>
  );
};

GroupTitleHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default GroupTitleHeader;
