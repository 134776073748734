import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  PERIOD_ENUM,
  DEFAULT_PERIOD
} from '~/modules/common/components/Calendar/period';
import {
  dateObjectToIsoDateOrDefault,
  isoStringToObject
} from '~/modules/common/dates/convert';

const getDisplayStyle = period => {
  switch (period) {
    case PERIOD_ENUM.DAYS:
      return 'dayGrid';
    case PERIOD_ENUM.WEEKS:
      return 'dayGridWeek';
    default:
      return 'dayGridMonth';
  }
};

const useCalendarContext = () => {
  const [childRef, setChildRef] = useState(null);
  const [period, setPeriod] = useState(DEFAULT_PERIOD);
  const [title, setTitle] = useState('');
  const [dateRange, setDateRange] = useState({
    start: DateTime.local()
      .startOf('month')
      .toISODate(),
    end: DateTime.local()
      .endOf('month')
      .toISODate()
  });

  return {
    childRef,
    setChildRef,
    period,
    setPeriod,
    title,
    setTitle,
    dateRange,
    setDateRange,
    getDisplayStyle,
    handlePrevPeriodChange: () => {
      if (!childRef) return;
      const calendarApi = childRef.current.getApi();

      calendarApi.prev();
    },
    handleNextPeriodChange: () => {
      if (!childRef) return;
      const calendarApi = childRef.current.getApi();

      calendarApi.next();
    },
    handleDatesChange: info => {
      setDateRange({
        start: dateObjectToIsoDateOrDefault(
          isoStringToObject(info.view.currentStart.toISOString())
        ),
        end: dateObjectToIsoDateOrDefault(
          isoStringToObject(info.view.currentEnd.toISOString())
        )
      });
      setTitle(info.view.title);
    },
    handleCalendarViewChange: event => {
      setPeriod(event.target.value);
      childRef.current.getApi().changeView(getDisplayStyle(event.target.value));
    }
  };
};

export default useCalendarContext;
