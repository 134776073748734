import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  teamRateLinkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  },
  manageTeamRates: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium
  },
  link: {
    textDecoration: 'none',
    '&:focus, &:hover, &:active': {
      textDecoration: 'underline'
    }
  }
}));
export const useHeaderStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    ...theme.typography.h6,
    color: theme.palette.text.secondary
  },
  action: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(-2)
  },
  subheader: {
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    marginTop: '2px',
    fontSize: theme.typography.body2.fontSize,
    '& strong': {
      color: theme.palette.common.black
    }
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export const useCardStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&:last-child': { paddingBottom: 0 }
  }
}));

export const useEditableCardStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(50)
    },
    padding: theme.spacing(0, 2)
  },
  contentRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  card: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));
