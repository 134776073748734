import { Dialog, DialogTitle } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import AddProjectRequestDialogContent from './AddProjectRequestDialogContent';

const addDialogRole = {
  role: 'presentation'
};

export const AddProjectRequestDialog = ({ open, onClose }) => {
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={addDialogRole}
    >
      <DialogTitle>
        <FormattedMessage id="projectRequest.addRequest" />
      </DialogTitle>
      <AddProjectRequestDialogContent onClose={onClose} />
    </Dialog>
  );
};

AddProjectRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddProjectRequestDialog;
