/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  }
}));

export const TaskAssignmentListSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {[...Array(8)].map((_, index) => (
        <Skeleton width="100%" height={50} key={`Option_${index}`} />
      ))}
    </div>
  );
};

export default TaskAssignmentListSkeleton;
