import React from 'react';
import { PropTypes } from 'prop-types';
import DateField from '../DateField';

export const FormDateField = ({ editable, ...rest }) => (
  <DateField readOnlyComponent="TextField" editable {...rest} />
);

FormDateField.propTypes = {
  editable: PropTypes.bool
};

export default FormDateField;
