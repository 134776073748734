import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveTaskEstimateConfirmationDialogContent from './RemoveTaskEstimateConfirmationDialogContent';
import RemoveTaskEstimateConfirmationDialogTitle from './RemoveTaskEstimateConfirmationDialogTitle';
import RemoveTaskEstimateConfirmationDialogFooter from './RemoveTaskEstimateConfirmationDialogFooter';

const useDialogStyles = makeStyles(theme => ({
  paper: {
    maxWidth: theme.spacing(90)
  }
}));

const RemoveTaskEstimateConfirmationDialog = ({
  open,
  onClose,
  task,
  resourceEstimate
}) => {
  const dialogClasses = useDialogStyles();

  return (
    <Dialog classes={dialogClasses} fullWidth open={open} onClose={onClose}>
      <RemoveTaskEstimateConfirmationDialogTitle
        taskName={task.name}
        isParentTask={task.hasChildren}
      />
      <RemoveTaskEstimateConfirmationDialogContent
        resourceEstimate={resourceEstimate}
        task={task}
      />
      <RemoveTaskEstimateConfirmationDialogFooter
        onClose={onClose}
        taskId={task.id}
        resourceEstimateId={resourceEstimate.id}
      />
    </Dialog>
  );
};

RemoveTaskEstimateConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object,
  resourceEstimate: PropTypes.object
};

export default RemoveTaskEstimateConfirmationDialog;
