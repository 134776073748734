import { useResourceUserRoles } from './useResourceUserRoles';

export const useResourceUserPrimaryRole = ({ variables, skip }) => {
  const { resourceUserRoles, loading, error } = useResourceUserRoles({
    variables,
    skip
  });

  return {
    loading,
    primaryRole: resourceUserRoles.find(r => r.isPrimary)?.projectRole,
    error
  };
};

export default useResourceUserPrimaryRole;
