import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';
import { Decimal, NoValue } from '~/modules/common/components';
import { useScoreMetricLables } from '../../ProjectRequestDrawer/components/ScoresAccordion/hooks';

const NOT_RATED = 'Not Rated';
const useStyles = makeStyles({
  score: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export const Score = ({ record, field }) => {
  const classes = useStyles();
  const { score } = record;

  const getScoreLabel = useScoreMetricLables(field);
  const scoreValue = score ? score[field] : null;

  return (
    <Tooltip
      title={scoreValue ? getScoreLabel[scoreValue] : NOT_RATED}
      placement="bottom"
    >
      <span className={classes.score}>{scoreValue || <NoValue />}</span>
    </Tooltip>
  );
};

Score.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string
};

export const Total = ({ record, field }) => {
  const { score } = record;
  const total = score ? score[field] : null;

  return Number.isFinite(total) ? (
    <Decimal value={total} precision={2} />
  ) : (
    <NoValue />
  );
};

Total.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string
};
