import { PropTypes } from 'prop-types';
import React from 'react';
import { AddBillPaymentDrawer } from '~/modules/billing-invoicing/billPayment/components';
import { useProjectReference } from '~/modules/billing-invoicing/common/hooks';

export const AssignNewPaymentDrawer = ({
  billId,
  open,
  onClose,
  client,
  isRefund = false,
  billBalanceTotal,
  queryParams
}) => {
  const projectSlug = queryParams ? queryParams.get('projectSlug') : null;
  const { projectUri, projectName } = useProjectReference({
    projectSlug
  });

  return (
    <AddBillPaymentDrawer
      open={open}
      onClose={onClose}
      projectUri={projectUri}
      projectName={projectName}
      client={client}
      billId={billId}
      billBalanceTotal={billBalanceTotal}
      showAllBills={false}
      isRefund={isRefund}
    />
  );
};

AssignNewPaymentDrawer.propTypes = {
  billId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.object,
  isRefund: PropTypes.bool,
  billBalanceTotal: PropTypes.object,
  queryParams: PropTypes.object
};

export default AssignNewPaymentDrawer;
