import { useMemo } from 'react';
import get from 'lodash.get';
import omit from 'lodash/fp/omit';
import flow from 'lodash/fp/flow';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import { LIST } from '~/modules/projects/projectListTabs';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { overbudgetTag } from '~/modules/projects/components/ProjectListPage/Toolbar/OverbudgetFacet';
import { ProjectManagementType } from '~/types';
import { useMeContext } from '~/modules/me';
import { useProjectOverBudgetCount } from '../graphql';

const getOverbudgetFilterGroups = canViewProjectCostData =>
  [
    canViewProjectCostData && {
      key: 'eitherOverHoursOrCostBudgetCount',
      messageId: 'projectOverview.overEstimate',
      highlightMsgId: 'projectOverview.budget',
      searchKey: 'overbudget'
    },
    !canViewProjectCostData && {
      key: 'overHoursBudgetCount',
      messageId: 'projectOverview.overEstimate',
      highlightMsgId: 'projectOverview.hoursBudget',
      searchKey: 'hoursOverbudget'
    },
    {
      key: 'compDateOverdueCount',
      messageId: 'projectOverview.overEstimate',
      highlightMsgId: 'projectOverview.endDate',
      searchKey: 'completionDateOverdue'
    }
  ].filter(x => x);

const omitForbiddenFields = flow(omit('__typename'));

const getOverbudgetCriteria = ({ overbudgetKey, overbudgetCriteria }) =>
  overbudgetCriteria &&
  overbudgetCriteria.some(({ key }) => key === overbudgetKey)
    ? overbudgetCriteria
    : [...overbudgetCriteria, { key: overbudgetKey, value: overbudgetKey }];

export const getSearchCriteria = ({ searchCriteria, overbudgetKey }) => ({
  ...omitForbiddenFields(searchCriteria),
  criterions: {
    ...searchCriteria.criterions,
    [overbudgetTag]:
      searchCriteria.criterions &&
      getOverbudgetCriteria({
        overbudgetKey,
        overbudgetCriteria: searchCriteria.criterions[overbudgetTag] || []
      })
  }
});

const getClickHandler = ({
  searchCriteria,
  setSearchCriteria,
  onTabChange,
  overbudgetKey
}) => () => {
  onTabChange(null, LIST);
  setSearchCriteria(getSearchCriteria({ searchCriteria, overbudgetKey }));
};

export default () => {
  const {
    featureFlags: { isPsaPpmCostEacEnhancementsEnabled },
    permissionsMap
  } = useMeContext();
  const canViewProjectCostData =
    isPsaPpmCostEacEnhancementsEnabled &&
    Boolean(permissionsMap['urn:replicon:project-action:view-cost-data']);
  const {
    searchCriteria,
    setSearchCriteria,
    onTabChange,
    projectType,
    summaryFilterState
  } = useProjectsContext();
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });
  const isManaged = useMemo(
    () => projectType === ProjectManagementType.Managed,
    [projectType]
  );

  const { loading, projectsOverbudgetCount } = useProjectOverBudgetCount({
    canViewProjectCostData,
    projectFilter,
    isManaged
  });

  const overbudgetList = useMemo(
    () =>
      getOverbudgetFilterGroups(canViewProjectCostData).map(
        ({ searchKey, ...rest }) => ({
          ...rest,
          searchKey,
          count: get(projectsOverbudgetCount, `${rest.key}`),
          onClick: getClickHandler({
            searchCriteria,
            setSearchCriteria,
            onTabChange,
            overbudgetKey: searchKey
          })
        })
      ),
    [
      canViewProjectCostData,
      onTabChange,
      projectsOverbudgetCount,
      searchCriteria,
      setSearchCriteria
    ]
  );

  return {
    loading,
    overbudgetList,
    total: get(projectsOverbudgetCount, 'total')
  };
};
