import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const TASK_RESOURCE_ESTIMATES_LIST_QUERY = gql`
  query getTaskResourceEstimatesForTask(
    $taskId: String!
    $page: Int!
    $pageSize: Int!
  ) {
    task(taskId: $taskId) {
      id
      resourceEstimates(page: $page, pageSize: $pageSize) {
        id
        taskId
        resourceUser {
          id
          uri
          slug
          displayText
        }
        projectRole {
          id
          displayText
        }
        initialEstimatedHours
        taskResourceUserAllocation {
          id
          taskUri
          projectUri
          roleUri
          startDate
          endDate
          scheduleRules {
            dateRange {
              startDate
              endDate
            }
            do
          }
          totalHours
        }
        taskResourceUserHoursProgressSummary {
          actualHours
          estimatedAtCompletionHours
          estimatedRemainingHours
        }
      }
    }
  }
`;

export const DEFAULT_PAGE_SIZE = 10;

export const useTaskResourceEstimates = ({
  taskId,
  page,
  pageSize = DEFAULT_PAGE_SIZE
}) => {
  const { data, loading } = useQuery(TASK_RESOURCE_ESTIMATES_LIST_QUERY, {
    variables: {
      taskId,
      page: page || 1,
      pageSize
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  return {
    loading,
    resourceEstimates: get(data, 'task.resourceEstimates', [])
  };
};

export default useTaskResourceEstimates;
