import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me';
import {
  mapToGetPageOfProjectRatesVariables,
  GET_PAGE_OF_PROJECT_RATES
} from './usePageOfProjectRate';
import { GET_PORJECT_RATE_TABLE_DIMENSIONS } from './useRateTableDimension';

export const PUT_PROJECT_RATES = gql`
  mutation putProjectRates2(
    $projectId: String!
    $dimensionUris: [String!]
    $requiredDimensionUris: [String!]
    $rates: [ProjectRateInput3!]
  ) {
    putProjectRates: putProjectRates2(
      projectId: $projectId
      dimensionUris: $dimensionUris
      requiredDimensionUris: $requiredDimensionUris
      rates: $rates
    ) {
      id
      dimensions {
        dimensionUri
        objectUri
      }
      scheduleEntries {
        amount {
          amount
          currency {
            id
          }
        }
      }
    }
  }
`;

export const updateCache = ({
  projectId,
  updatedProjectRateDimensions,
  updatedProjectRateRequiredDimensions
}) => (cache, { data }) => {
  const storedProjectRateTableDimensions = cache.readQuery({
    query: GET_PORJECT_RATE_TABLE_DIMENSIONS,
    variables: {
      projectId
    }
  });

  cache.writeQuery({
    query: GET_PORJECT_RATE_TABLE_DIMENSIONS,
    variables: {
      projectId
    },
    data: {
      project: {
        ...storedProjectRateTableDimensions.project,
        rateTableDimension: {
          ...storedProjectRateTableDimensions.project.rateTableDimension,
          dimensions: updatedProjectRateDimensions,
          requiredDimensions: updatedProjectRateRequiredDimensions
        }
      }
    }
  });
};

export const usePutProjectRatesForDynamicRateTable = ({
  availableRateTableDimensions,
  projectRateRequiredDimensions
}) => {
  const me = useMeContext();
  const [putProjectRatesForDynamicRateTable] = useMutation(PUT_PROJECT_RATES);

  return {
    putProjectRatesForDynamicRateTable: async ({
      projectId,
      rates,
      projectRateDimensionUris,
      projectRateRequiredDimensionUris
    }) => {
      const input = {
        projectId,
        rates,
        dimensionUris: projectRateDimensionUris,
        requiredDimensionUris: projectRateRequiredDimensionUris
      };

      const updatedProjectRateDimensions = availableRateTableDimensions.filter(
        pd => projectRateDimensionUris.some(d => pd.id === d)
      );

      const updatedProjectRateRequiredDimensions = projectRateRequiredDimensions.filter(
        rd => projectRateRequiredDimensionUris.some(d => rd.id === d)
      );

      return putProjectRatesForDynamicRateTable({
        variables: input,
        refetchQueries: [
          {
            query: GET_PAGE_OF_PROJECT_RATES,
            variables: mapToGetPageOfProjectRatesVariables(
              projectId,
              projectRateDimensionUris,
              me
            )
          }
        ],
        awaitRefetchQueries: true,
        update: updateCache({
          projectId,
          updatedProjectRateDimensions,
          updatedProjectRateRequiredDimensions
        })
      });
    }
  };
};
