import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';
import {
  TaskVariationPill,
  TaskVariationPillContent
} from '~/modules/common/components/TaskVariationPill';
import { useStyles as usePillStyles } from '~/modules/common/components/TaskVariationPill/hooks';
import { TooltipMissingEstimate } from './TooltipMissingEstimate';

const useStyles = makeStyles({
  variationRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1, 0.7, 0.7, 0.7)
  },
  variation: {
    display: 'flex',
    alignItems: 'center'
  },
  hoursLabel: {
    fontSize: theme.spacing(1.25) + 1
  },
  disabledText: {
    color: theme.palette.taskRollUp.missing.light
  },
  hoursLabelWithFFOn: {
    fontSize: theme.typography.body2.fontSize
  }
});

const usePillClassesOverride = makeStyles({
  variationPill: ({ isRTL }) => ({
    padding: isRTL
      ? theme.spacing(0.25, 0, 0, 0.75)
      : theme.spacing(0.25, 0.75, 0),
    marginLeft: !isRTL ? theme.spacing(1) : 0,
    marginRight: isRTL ? theme.spacing(1) : 0
  }),
  variationIcon: ({
    isRTL,
    variationStatus,
    isPsaRmpTaskAllocation1Enabled
  }) => ({
    ...(isRTL && { marginRight: theme.spacing(0.25) }),
    marginLeft:
      isRTL && variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MATCHING
        ? theme.spacing(1)
        : 0,
    ...(isPsaRmpTaskAllocation1Enabled && {
      width: theme.spacing(2.25),
      paddingBottom: theme.spacing(0.25)
    })
  }),
  variationValue: ({ isPsaRmpTaskAllocation1Enabled }) => ({
    ...(isPsaRmpTaskAllocation1Enabled && {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body2.fontSize
    })
  }),
  variationText: ({ isPsaRmpTaskAllocation1Enabled }) => ({
    ...(isPsaRmpTaskAllocation1Enabled && {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary
    })
  })
});

export const TooltipVariationRow = ({
  intl,
  hours,
  variationStatus,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  numberFormat,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles();

  const pillClassesOverride = usePillClassesOverride({
    variationStatus,
    isRTL,
    isPsaRmpTaskAllocation1Enabled
  });

  const pillClasses = usePillStyles({
    classes: pillClassesOverride,
    isPsaPpmCostEacEnhancementsEnabled
  });
  const isMissingEstimate =
    variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE;

  return (
    <div className={classes.variationRow}>
      <span
        className={classNames(classes.hoursLabel, {
          [classes.hoursLabelWithFFOn]: isPsaRmpTaskAllocation1Enabled,
          [classes.disabledText]: isMissingEstimate
        })}
      >
        {intl.formatMessage({
          id: 'projectTasksPage.variation'
        })}
        :
      </span>
      <div className={classes.variation}>
        {isMissingEstimate ? (
          <TooltipMissingEstimate intl={intl} isVariationRow />
        ) : isPsaPpmCostEacEnhancements2Enabled ? (
          <TaskVariationPillContent
            intl={intl}
            classes={pillClasses}
            variationStatus={variationStatus}
            value={hours}
            numberFormat={numberFormat}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
        ) : (
          <TaskVariationPill
            intl={intl}
            classes={pillClasses}
            variationStatus={variationStatus}
            value={hours}
          />
        )}
      </div>
    </div>
  );
};

TooltipVariationRow.propTypes = {
  intl: PropTypes.object.isRequired,
  hours: PropTypes.any,
  variationStatus: PropTypes.oneOf([
    ESTIMATED_HOURS_VARIATION_STATUS.MATCHING,
    ESTIMATED_HOURS_VARIATION_STATUS.OVER,
    ESTIMATED_HOURS_VARIATION_STATUS.UNDER,
    ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE
  ]).isRequired,
  isRTL: PropTypes.bool.isRequired,
  numberFormat: PropTypes.object,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default TooltipVariationRow;
