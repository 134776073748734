import { useMemo, useCallback } from 'react';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';

export const getValidationSchema = ({ intl }) =>
  object().shape({
    emailTo: string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'sendBillDialog.validation.emailRequired'
        })
      )
      .email(
        intl.formatMessage({
          id: 'sendBillDialog.validation.validEmail'
        })
      ),
    emailFrom: string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'sendBillDialog.validation.emailRequired'
        })
      )
      .email(
        intl.formatMessage({
          id: 'sendBillDialog.validation.validEmail'
        })
      ),
    emailCc: string()
      .trim()
      .email(
        intl.formatMessage({
          id: 'sendBillDialog.validation.validEmail'
        })
      ),
    subject: string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'sendBillDialog.validation.subjectRequired'
        })
      ),
    message: string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'sendBillDialog.validation.bodyRequired'
        })
      )
  });

export const getInitialValues = ({
  clientDetails = {},
  me,
  bill,
  intl,
  billUrl
}) => ({
  emailTo:
    clientDetails && clientDetails.contactInfo
      ? clientDetails.contactInfo.email
      : '',

  emailCc: '',
  emailFrom: me.emailAddress || '',
  subject: bill.description
    ? `${bill.displayText || bill.displayId} - ${bill.description}`
    : `${bill.displayText || bill.displayId}`,
  message: intl.formatMessage(
    { id: 'sendBillDialog.billDetails' },
    {
      contactName: clientDetails ? clientDetails.contactName : null,
      billUrl,
      transactionDate: bill ? bill.transactionDate : null
    }
  )
});

export const useFormState = ({ clientDetails, me, bill, billUrl }) => {
  const intl = useIntl();

  const initialValues = useMemo(
    () => getInitialValues({ clientDetails, me, bill, intl, billUrl }),
    [bill, billUrl, clientDetails, intl, me]
  );
  const validationSchema = useMemo(() => getValidationSchema({ intl }), [intl]);

  return {
    validationSchema,
    initialValues
  };
};

export const useOnSend = ({
  values,
  sendBill,
  setSending,
  setSendingError,
  onClose
}) =>
  useCallback(async () => {
    const { emailTo, emailCc, emailFrom, subject, message } = values;

    setSending(true);
    const {
      data: {
        sendBillByEmail: { error }
      }
    } = await sendBill({
      emailTo,
      emailCc,
      emailFrom,
      subject,
      message
    });

    setSending(false);
    setSendingError(error || null);
    if (error.reason === null) {
      onClose();
    }
  }, [onClose, sendBill, setSending, setSendingError, values]);
