import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import { TaskIcon } from '~/modules/common/components/Icons';
import { OverviewItemCard } from '~/modules/common/charts/dashboard';

import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import { ProjectManagementType } from '~/types';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { NoData, CardTitle } from '../common';
import TasksStatusChart from './Chart/TasksStatusChart';
import TasksSummary from './TasksSummary';
import { usePendingTasksCountQuery, useActiveTasksSummaryQuery } from './hooks';
import ActiveTaskCardLoading from './ActiveTaskCardLoading';
import TasksCardHelp from './TasksCardHelp';

const noActiveTasks = <FormattedMessage id="projectOverview.noActiveTasks" />;

const avatar = <TaskIcon />;
const helpAction = <TasksCardHelp />;

export const ActiveTasksCard = () => {
  const intl = useIntl();

  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();

  const isManaged =
    projectType === ProjectManagementType.Managed
      ? true
      : projectType === ProjectManagementType.Unmanaged
      ? false
      : null;

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });

  const {
    loading: isCountLoading,
    pendingTasksCount = 0,
    errorMessage
  } = usePendingTasksCountQuery({
    projectFilter: {
      ...projectFilter,
      isManaged,
      includeMyProjectsOnly: true
    },
    intl
  });

  const {
    loading: isActiveTasksLoading,
    activeTasksSummary = {}
  } = useActiveTasksSummaryQuery({
    projectFilter: { ...projectFilter, isManaged }
  });

  const isLoading = isCountLoading || isActiveTasksLoading;

  const anyTaskPresent = pendingTasksCount > 0 || activeTasksSummary.total > 0;

  const t = id => intl.formatMessage({ id });

  return (
    <OverviewItemCard
      title={t('projectTasksCard.title')}
      avatar={avatar}
      action={helpAction}
    >
      {isLoading ? (
        <ActiveTaskCardLoading />
      ) : (
        <>
          {errorMessage ? (
            <NoData message={errorMessage} />
          ) : anyTaskPresent ? (
            <TaskSummaryAndChart
              activeTasksSummary={activeTasksSummary}
              pendingTasksCount={pendingTasksCount}
            />
          ) : (
            <NoData message={t('projectOverview.noActiveTasks')} />
          )}
        </>
      )}
    </OverviewItemCard>
  );
};

export const TaskSummaryAndChart = ({
  activeTasksSummary,
  pendingTasksCount
}) => {
  const values = useMemo(
    () => ({
      count: activeTasksSummary.total
    }),
    [activeTasksSummary.total]
  );

  const title = useMemo(
    () =>
      values.count > 0 ? (
        <FormattedMessage
          id="projectOverview.activeTasksTotal"
          values={values}
        />
      ) : (
        <FormattedMessage id="projectOverview.activeTasks" />
      ),
    [values]
  );

  return (
    <>
      {(pendingTasksCount > 0 ||
        activeTasksSummary.overEstimatedTasksCount > 0) && (
        <TasksSummary activeTasksSummary={activeTasksSummary} />
      )}
      <CardTitle title={title} />
      {activeTasksSummary.total > 0 ? (
        <TasksStatusChart activeTasksSummary={activeTasksSummary} />
      ) : (
        <NoData message={noActiveTasks} />
      )}
    </>
  );
};

TaskSummaryAndChart.propTypes = {
  activeTasksSummary: PropTypes.object,
  pendingTasksCount: PropTypes.number
};

export default ActiveTasksCard;
