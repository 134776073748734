import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CostCenterIcon = React.forwardRef((props, ref) => (
  <SvgIcon data-qe-id="CostCenterIcon" {...props} ref={ref}>
    <>
      <path d="M 12 1.998046875 L 3 6.783203125 L 3 21.1171875 L 21 21.1171875 L 21 21.115234375 L 21 6.783203125 L 12 1.998046875 z M 11.36328125 7.591796875 L 13.033203125 7.591796875 L 13.033203125 8.80859375 A 2.215 2.215 0 0 1 14.81640625 10.92578125 L 13.58984375 10.92578125 C 13.55684375 10.23078125 13.18821875 9.7578125 12.19921875 9.7578125 C 11.26021875 9.7578125 10.69921875 10.17915625 10.69921875 10.78515625 C 10.69921875 11.31315625 11.10228125 11.655421875 12.36328125 11.982421875 C 13.62428125 12.309421875 14.974609375 12.84778125000001 14.974609375 14.42578125 C 14.974609375 15.56478125 14.11425 16.1924375 13.03125 16.3984375 L 13.03125 17.591796875 L 11.36328125 17.591796875 L 11.36328125 16.38671875 A 2.328 2.328 0 0 1 9.3203125 14.2578125 L 10.544921875 14.2578125 C 10.60688383060177 14.91340996572195 11.05481867136528 15.42515465358577 12.197265625 15.42578125 C 12.197265625 15.42578125 12.19921875 15.42578125 12.19921875 15.42578125 C 13.42539333217622 15.42507120386797 13.697265625 14.81441786343277 13.697265625 14.431640625 C 13.697265625 13.91464062499999 13.42025 13.42479687500001 12.03125 13.091796875 C 10.48125 12.719796875 9.419921875 12.081828125 9.419921875 10.798828125 A 2.168 2.168 0 0 1 11.36328125 8.79296875 L 11.36328125 7.591796875 z " />
    </>
  </SvgIcon>
));

export default CostCenterIcon;
