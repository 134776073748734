import get from 'lodash.get';
import { useGetProjectsQuery } from '../../graphql';
import { useProjectFilterMemo } from './useProjectFilterMemo';

export const useFilteredProjectsQuery = ({
  currentPage,
  pageSize,
  isManaged,
  searchCriteria,
  sort,
  quickFilter,
  tagIds,
  enabledFields,
  isPSAPercentageBudgetScopeEnabled = false
}) => {
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter
  });

  const options = {
    variables: {
      page: currentPage || 1,
      pageSize,
      projectFilter,
      isManaged,
      sort,
      tagIds,
      isPSAPercentageBudgetScopeEnabled,
      ...enabledFields
    },
    fetchPolicy: 'network-only'
  };

  const {
    data,
    fetchMore,
    loading,
    networkStatus,
    refetch
  } = useGetProjectsQuery(options);

  const projects = get(data, 'projects') || [];

  return {
    loading,
    projects,
    fetchMore,
    refetch,
    networkStatus
  };
};
