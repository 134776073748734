import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import React, { memo } from 'react';
import {
  AbbreviatedDecimal,
  Decimal,
  NoValue
} from '~/modules/common/components';
import { skipComponentUpdate } from '../../util/skipComponentUpdate';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    textAlign: 'right',
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0, 0, 0, 0.5)
  },
  currency: {
    lineHeight: 1
  },
  hours: {},
  cost: {}
}));

export const FormattedHoursAndCost = ({
  classes: classesOverrides,
  hours,
  costs = [],
  dataQeId,
  hoursSuffix,
  showHours = true,
  hideCosts = false,
  showAbbreviatedValue = true
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const validCosts = costs.filter(
    c => c.amount !== 0 && c.amount !== undefined && c.amount !== null
  );

  const Component = showAbbreviatedValue ? AbbreviatedDecimal : Decimal;

  return (
    <div className={classes.root}>
      {!showHours ? null : (
        <>
          {!hours ? (
            <NoValue
              className={classes.hours}
              data-qe-id={`${dataQeId}_Hours`}
            />
          ) : (
            <Component
              value={hours}
              className={classes.hours}
              suffix={hoursSuffix}
              dataQeId={`${dataQeId}_Hours`}
            />
          )}
          <br />
        </>
      )}
      {!hideCosts &&
        (validCosts.length === 0 ? (
          <NoValue
            key="NoValue"
            className={classes.cost}
            data-qe-id={`${dataQeId}_Cost`}
          />
        ) : (
          validCosts.map(cost => (
            <div
              className={classes.currency}
              key={`${cost.currencySymbol}${cost.amount}`}
            >
              <span>{`${cost.currencySymbol} `}</span>
              <Component
                value={cost.amount}
                className={classes.cost}
                dataQeId={`${dataQeId}_Cost`}
              />
            </div>
          ))
        ))}
    </div>
  );
};

FormattedHoursAndCost.propTypes = {
  classes: PropTypes.object,
  hours: PropTypes.number,
  costs: PropTypes.array,
  dataQeId: PropTypes.string,
  showHours: PropTypes.bool,
  hideCosts: PropTypes.bool,
  showAbbreviatedValue: PropTypes.bool,
  hoursSuffix: PropTypes.string
};
export default memo(
  FormattedHoursAndCost,
  skipComponentUpdate(['hours', 'costs'])
);
