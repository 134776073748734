import React, { memo } from 'react';
import PropTypes from 'prop-types';
import EditableCustomField from './EditableCustomField';
import ReadOnlyCustomField from './ReadOnlyCustomField';

export const CustomField = ({
  editable,
  customFieldValue,
  onChange,
  name,
  ...rest
}) => {
  return (
    <label htmlFor={name}>
      {editable ? (
        <EditableCustomField
          customFieldValue={customFieldValue}
          onChange={onChange}
          name={name}
          {...rest}
        />
      ) : (
        <ReadOnlyCustomField customFieldValue={customFieldValue} {...rest} />
      )}
    </label>
  );
};

CustomField.propTypes = {
  editable: PropTypes.bool,
  customFieldValue: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string
};

export default memo(CustomField);
