import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { StyledChartRangeItem2 } from '~/modules/common/charts/timeline/components';
import { useRescheduleProject } from '~/modules/projects/graphql';
import Project from './ProjectTimelineChartRowProject';
import ProjectTimelineChartRowBar from './ProjectTimelineChartRowBar';
import { GET_PROJECTS_QUERY } from './useGetProjectQuery';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 0
  }
}));

const refetchQuery = async ({
  client,
  projectId,
  setIsJobRunningInBackground
}) => {
  await client.query({
    query: GET_PROJECTS_QUERY,
    variables: {
      page: 1,
      pageSize: 1,
      projectFilter: {
        project: projectId
      }
    },
    fetchPolicy: 'network-only'
  });
  setIsJobRunningInBackground(false);
};

export const ProjectTimelineChartRow = ({
  scale,
  project,
  isSelected,
  chartDates,
  leftComponentWidth,
  chartDisplayDateRange,
  onRowSelectionChange
}) => {
  const classes = useStyles();

  const [{ start: chartStartDate }] = chartDates;

  const [isJobRunningInBackground, setIsJobRunningInBackground] = useState(
    false
  );

  const { id: projectId, startDate, rescheduleProjectJob } = project;

  const {
    client,
    resetGraphQlStore,
    rescheduledProjectId
  } = useRescheduleProject({
    projectId,
    rescheduleProjectJob
  });

  useEffect(() => {
    if (projectId === rescheduledProjectId) setIsJobRunningInBackground(true);

    if (resetGraphQlStore) {
      refetchQuery({ client, projectId, setIsJobRunningInBackground });
    }
  }, [
    client,
    projectId,
    resetGraphQlStore,
    rescheduledProjectId,
    setIsJobRunningInBackground
  ]);

  const leftComponent = useMemo(
    () => (
      <Project
        project={project}
        isSelected={isSelected}
        onRowSelectionChange={onRowSelectionChange}
      />
    ),
    [isSelected, onRowSelectionChange, project]
  );

  const timeItems = useMemo(
    () => (
      <ProjectTimelineChartRowBar
        scale={scale}
        key={startDate}
        project={project}
        chartStartDate={chartStartDate}
        leftComponentWidth={leftComponentWidth}
        chartDisplayDateRange={chartDisplayDateRange}
        isRescheduleProjectJobRunningInBackground={isJobRunningInBackground}
      />
    ),
    [
      chartDisplayDateRange,
      chartStartDate,
      isJobRunningInBackground,
      leftComponentWidth,
      project,
      scale,
      startDate
    ]
  );

  return (
    <>
      <StyledChartRangeItem2
        classes={classes}
        scale={scale}
        leftComponentWidth={leftComponentWidth}
        scaleItemCount={chartDates.length}
        chartStartDate={chartStartDate}
      >
        {leftComponent}
        {timeItems}
      </StyledChartRangeItem2>
    </>
  );
};

ProjectTimelineChartRow.propTypes = {
  project: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  chartDates: PropTypes.array.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onRowSelectionChange: PropTypes.func
};

export default ProjectTimelineChartRow;
