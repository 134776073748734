import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  primary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const SkillDropdownOption = ({ value }) => {
  const classes = useStyles();

  return (
    <ListItemText primary={value} className={classes.text} classes={classes} />
  );
};

SkillDropdownOption.propTypes = {
  value: PropTypes.string.isRequired
};

export default SkillDropdownOption;
