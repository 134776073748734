import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const PUT_PROJECT_REQUEST_SCORE = gql`
  mutation putProjectRequestScore($input: PutProjectRequestScoreInput!) {
    putProjectRequestScore(input: $input) {
      projectRequestUri
    }
  }
`;

export const usePutProjectRequestScore = ({ id, score }) => {
  const [putProjectRequestScore] = useMutation(PUT_PROJECT_REQUEST_SCORE);

  return {
    putProjectRequestScore: async () => {
      await putProjectRequestScore({
        variables: {
          input: {
            id,
            value: score.value === 0 ? null : score.value,
            risk: score.risk === 0 ? null : score.risk,
            investment: score.investment === 0 ? null : score.investment
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          putProjectRequestScore: {
            projectRequestUri: id
          }
        },
        skip: !id
      });
    }
  };
};

export default usePutProjectRequestScore;
