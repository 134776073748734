import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  COLUMN_KEY,
  AssignmentDialogColumns,
  includesTagIds,
  includesCustomFieldIds
} from '~/modules/resourcing/components/ResourceAssignmentDialog/enhancers';
import { useAssignmentDialogColumnPermission } from '~/modules/resourcing/components/ResourceAssignmentDialog/hooks';
import { LIST_TABLE_SETTINGS_QUERY } from '~/modules/common/components/ListTable/hooks/useTableSettings';

export const useDialogSettings = ({ tableKey, defaultColumns }) => {
  const [columnState, setColumnState] = useState(new Array(4));
  const { data, loading, error } = useQuery(LIST_TABLE_SETTINGS_QUERY, {
    variables: { tableKey },
    fetchPolicy: 'network-only',
    skip: false
  });
  const columnPermissions = useAssignmentDialogColumnPermission();

  useMemo(() => {
    const columnArray = new Array(4);

    if (loading || !data?.tableSetting?.columns?.length) {
      defaultColumns.forEach((columnInfo, index) => {
        if (columnInfo?.field) {
          columnArray[index] = columnInfo.field;
        }
      });
    } else {
      data.tableSetting.columns.forEach(columnInfo => {
        const separatorIndex = columnInfo.indexOf(';');
        const column = columnInfo.substr(separatorIndex + 1);
        const columnSeparator = columnInfo.substr(0, separatorIndex);

        const columnIndex = parseInt(
          columnSeparator.substr(COLUMN_KEY.length),
          10
        );

        if (columnPermissions[column]) {
          columnArray[columnIndex] = column;
        } else if (column === AssignmentDialogColumns.SmartMatchIncludingCost) {
          columnArray[columnIndex] =
            AssignmentDialogColumns.SmartMatchExcludingCost;
        } else if (
          (includesTagIds([column]) &&
            columnPermissions[AssignmentDialogColumns.Tags]) ||
          (includesCustomFieldIds([column]) &&
            columnPermissions[AssignmentDialogColumns.CustomFields])
        ) {
          columnArray[columnIndex] = column;
        }
      });
    }
    setColumnState(columnArray);
  }, [loading, data, columnPermissions]);

  return { data: columnState, isLoading: loading, error };
};

export default useDialogSettings;
