import { useMemo } from 'react';
import { DateTime } from 'luxon';

export const getEntityDateRange = entity => ({
  startDate: DateTime.fromISO(entity.startDate).toUTC(),
  endDate: DateTime.fromISO(entity.endDate).toUTC()
});

export const isInChartDisplayDateRange = ({
  chartDisplayDateRange,
  entityDateRange
}) =>
  !(
    entityDateRange.endDate < chartDisplayDateRange.startDate ||
    entityDateRange.startDate > chartDisplayDateRange.endDate
  );

export const getClippedChartDisplayDateRange = ({
  entityDateRange,
  chartDisplayDateRange
}) => ({
  start:
    entityDateRange.startDate > chartDisplayDateRange.startDate
      ? entityDateRange.startDate
      : chartDisplayDateRange.startDate,
  end:
    entityDateRange.endDate < chartDisplayDateRange.endDate
      ? entityDateRange.endDate
      : chartDisplayDateRange.endDate
});

export default ({ entity, chartDisplayDateRange }) => {
  const entityDateRange = getEntityDateRange(entity);

  const clippedChartDisplayDateRange = useMemo(
    () =>
      getClippedChartDisplayDateRange({
        entityDateRange,
        chartDisplayDateRange
      }),
    [entityDateRange, chartDisplayDateRange]
  );

  return {
    entityDateRange,
    isInChartRange: isInChartDisplayDateRange({
      entityDateRange,
      chartDisplayDateRange
    }),
    clippedChartDisplayDateRange
  };
};
