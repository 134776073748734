import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  useIsDefaultEdit,
  useIsBreakpointDown,
  useGetKeyValueSettings
} from '~/modules/common/hooks';
import { ProjectChartRowsLoading } from '~/modules/resourcing/common/components';
import { MobileListItemLoading } from '~/modules/common/components/DetailsPage';
import { ProjectCopyInProgress } from '~/modules/common/components';
import { useProjectInfo } from '../hooks/useProjectInfo';
import { useGetResourcePlanDefaults } from '../hooks/useGetResourcePlanDefaults';
import DesktopProjectResourcingPlan from './DesktopProjectResourcingPlan';
import MobileProjectResourcingPlan from './MobileProjectResourcingPlan';

export const ResourcingPlanPage = ({
  editable,
  setEditable,
  canEditProject,
  setSearchCriteria,
  searchCriteria
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const [isCopyingRequests, setCopyingRequests] = useState(true);
  const isDefaultEdit = useIsDefaultEdit() && canEditProject;
  const { project, isLoading } = useProjectInfo();
  const copyProjectJobId = project?.copyProjectJobId;

  useEffect(() => {
    if (isDefaultEdit) {
      setEditable(isDefaultEdit);
    }
  }, [setEditable, isDefaultEdit]);

  const {
    resourceRequestsMinMaxDateAndStatuses,
    resourceAllocationsMinMaxDateAndStatuses,
    loading: resourcePlanLoading
  } = useGetResourcePlanDefaults({
    projectUri: project.id,
    skip: isMobile
  });

  const {
    isLoading: isKeyValueSettingsLoading,
    keyValueSettings: chartSettings
  } = useGetKeyValueSettings('resource_plan_chart_settings');

  if (isLoading || resourcePlanLoading || isKeyValueSettingsLoading) {
    return isMobile ? <MobileListItemLoading /> : <ProjectChartRowsLoading />;
  }

  if (copyProjectJobId && isCopyingRequests)
    return (
      <ProjectCopyInProgress
        copyProjectJobId={copyProjectJobId}
        setCopyingRequests={setCopyingRequests}
      />
    );

  if (isMobile)
    return (
      <MobileProjectResourcingPlan
        editable={canEditProject || editable}
        project={project}
        setSearchCriteria={setSearchCriteria}
        searchCriteria={searchCriteria}
      />
    );

  return (
    <DesktopProjectResourcingPlan
      editable={canEditProject || editable}
      project={project}
      setSearchCriteria={setSearchCriteria}
      searchCriteria={searchCriteria}
      chartSettings={chartSettings}
      resourceRequestsMinMaxDateAndStatuses={
        resourceRequestsMinMaxDateAndStatuses
      }
      resourceAllocationsMinMaxDateAndStatuses={
        resourceAllocationsMinMaxDateAndStatuses
      }
    />
  );
};

ResourcingPlanPage.propTypes = {
  editable: PropTypes.bool.isRequired,
  setEditable: PropTypes.func.isRequired,
  canEditProject: PropTypes.bool,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object
};

export default ResourcingPlanPage;
