import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import {
  isHoursDefined,
  getEstimatedHoursVariation
} from '~/modules/projects/tasks/components/Gantt/components/util';
import { TaskVariationPill } from '~/modules/common/components/TaskVariationPill';
import { useStyles as usePillStyles } from '~/modules/common/components/TaskVariationPill/hooks';
import { useMeContext } from '~/modules/me';
import { TaskCardMissingEstimate } from './TaskCardMissingEstimate';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  subHeading: {
    fontSize: theme.spacing(1.25),
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.38)'
  },
  hoursContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(0.5)
  },
  hours: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.75),
    fontWeight: 600
  },
  label: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.5)
  }
}));

const usePillStylesOverride = makeStyles(theme => ({
  variationContainer: {
    flexDirection: 'row-reverse',
    flexWrap: 'wrap-reverse',
    justifyContent: 'flex-end',
    marginRight: 0
  },
  variationText: {
    marginTop: theme.spacing(0.5)
  },
  variationPill: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    alignItems: 'center'
  },
  variationValue: {
    fontSize: theme.spacing(1.75)
  },
  variationIcon: {
    fontSize: theme.spacing(2.25)
  }
}));

const TaskCardVariation = ({ task, isProject = false }) => {
  const {
    featureFlags: { isPsaPpmCostEacEnhancementsEnabled }
  } = useMeContext();

  const intl = useIntl();
  const classes = useStyles();
  const pillClasses = usePillStyles({
    classes: usePillStylesOverride(),
    isPsaPpmCostEacEnhancementsEnabled
  });
  const {
    initialEstimatedHours,
    rolledUpSummary: { totalEstimatedAtCompletionHours } = {}
  } = task;
  const { variationStatus, variation } = getEstimatedHoursVariation({
    estimatedHours: initialEstimatedHours,
    totalEstimatedAtCompletionHours
  });

  return (
    <div className={classes.container}>
      <Typography className={classes.subHeading} variant="body2">
        <FormattedMessage
          id={`projectTasksPage.${isProject ? 'projectBudget' : 'variation'}`}
        />
      </Typography>
      <div className={classes.hoursContainer}>
        {isHoursDefined(variation) ? (
          <TaskVariationPill
            intl={intl}
            classes={pillClasses}
            variationStatus={variationStatus}
            fixedDecimalScale={false}
            value={variation}
          />
        ) : (
          <TaskCardMissingEstimate />
        )}
      </div>
    </div>
  );
};

TaskCardVariation.propTypes = {
  task: PropTypes.object.isRequired,
  isProject: PropTypes.bool
};

export default TaskCardVariation;
