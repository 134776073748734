import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const GET_PROGRAM_MANAGERS_QUERY = gql`
  query GetAllProgramManagers {
    getAllProgramManagersAssignedToPrograms {
      displayText
      id
    }
  }
`;
export const useProgramManagers = () => {
  const { data, loading } = useQuery(GET_PROGRAM_MANAGERS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const programManagers =
    data?.getAllProgramManagersAssignedToPrograms?.map(programManager => ({
      ...programManager,
      value: programManager.id,
      label: programManager.displayText,
      key: programManager.displayText
    })) || [];

  return { programManagers, loading };
};

export default useProgramManagers;
