import { Grid, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AssociatedBillsTable,
  DrawerExpansionPanel
} from '~/modules/billing-invoicing/common/components';
import { DateField, ReadOnlyField } from '~/modules/common/components';
import { MoneyValue } from '~/modules/common/components/Money';
import { useIsMultiCurrency } from '~/modules/billing-invoicing/common/hooks';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    margin: 0,
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderBottom: `1px solid ${theme.palette.table.border}`
  },
  display: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  }
}));

const expansionPanelResourceKeys = {
  title: 'billPaymentDetails.outstandingBills',
  allocatedAmount: 'billPaymentDetails.allocatedAmount'
};

const billsTableResourceKeys = {
  noDataMessage: 'billPaymentDetails.noOutstandingBills'
};

const resourceLabels = {
  referenceNumber: <FormattedMessage id="billPaymentDetails.referenceNumber" />,
  paymentDate: <FormattedMessage id="billPaymentDetails.paymentDate" />,
  description: <FormattedMessage id="billPaymentDetails.description" />,
  paymentMethod: <FormattedMessage id="billPaymentDetails.paymentMethod" />,
  paymentAmount: <FormattedMessage id="billPaymentDetails.amount" />,
  none: <FormattedMessage id="simpleDropdown.none" />
};

export const BillPaymentDetailsReadOnly = ({
  values,
  associatedBillsTotal,
  associatedAndOutstandingBills,
  isRefund
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const filteredAssociatedAndOutstandingBills = React.useMemo(
    () =>
      associatedAndOutstandingBills.filter(bill =>
        isRefund ? bill.amount.amount < 0 : bill.amount.amount > 0
      ),
    [associatedAndOutstandingBills, isRefund]
  );
  const {
    displayId,
    description,
    paymentDate,
    currency,
    paymentAmount,
    paymentMethod
  } = values;
  const { isMultiCurrency } = useIsMultiCurrency({
    values,
    filteredAssociatedAndOutstandingBills
  });
  const money = useMemo(
    () => ({
      currency,
      amount: paymentAmount
    }),
    [currency, paymentAmount]
  );

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={6}>
        <ReadOnlyField
          className={classes.display}
          label={resourceLabels.referenceNumber}
          value={displayId}
          dataQeId="billPaymentDetails.referenceNumber"
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          variant="standard"
          fullWidth
          clearable={false}
          label={resourceLabels.paymentDate}
          value={paymentDate}
          editable={false}
          underline={false}
          dataQeId="billPaymentDetails.paymentDate"
        />
      </Grid>
      {description && (
        <Grid item xs={12}>
          <ReadOnlyField
            className={classes.display}
            label={resourceLabels.description}
            value={description}
            dataQeId="billPaymentDetails.description"
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <ReadOnlyField
          label={resourceLabels.paymentMethod}
          value={
            paymentMethod.displayText
              ? paymentMethod.displayText
              : resourceLabels.none
          }
          dataQeId="billPaymentDetails.paymentMethod"
        />
      </Grid>

      <Grid item xs={6}>
        <ReadOnlyField
          label={resourceLabels.paymentAmount}
          value={useMemo(
            () => (
              <MoneyValue money={money} />
            ),
            [money]
          )}
          dataQeId="billPaymentDetails.amount"
        />
      </Grid>
      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={expansionPanelResourceKeys}
          amountTotal={associatedBillsTotal}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <AssociatedBillsTable
            associatedBills={filteredAssociatedAndOutstandingBills}
            amountTotal={associatedBillsTotal}
            resourceKeys={billsTableResourceKeys}
            showAllOutstandingBills
            editable={false}
            isMultiCurrency={isMultiCurrency}
            isRefund={isRefund}
          />
        </DrawerExpansionPanel>
      </Grid>
    </Grid>
  );
};

BillPaymentDetailsReadOnly.propTypes = {
  values: PropTypes.object.isRequired,
  associatedBillsTotal: PropTypes.object.isRequired,
  associatedAndOutstandingBills: PropTypes.array,
  isRefund: PropTypes.bool
};

export default BillPaymentDetailsReadOnly;
