import { useState, useCallback } from 'react';

export const usePopperHandlers = () => {
  const [anchorEl, setAnchorE1] = useState(null);

  const onMenuClick = useCallback(event => {
    setAnchorE1(event && event.target ? event.target : null);
  }, []);

  const handleKeyDown = useCallback(
    event => {
      if (
        event.type === 'click' ||
        event.key === 'Enter' ||
        event.keyCode === 13
      ) {
        onMenuClick();
      }
    },
    [onMenuClick]
  );

  return {
    anchorEl,
    onMenuClose: useCallback(() => {
      setAnchorE1(null);
    }, []),
    onMenuClick,
    handleKeyDown
  };
};

export default usePopperHandlers;
