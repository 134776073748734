import React from 'react';
import PropTypes from 'prop-types';
import { deepPure } from '~/util';
import { useAllocationTimelineBlocks2 } from '~/modules/resourcing/common/chart/hooks';
import {
  useResourceAllocationDragIndicators,
  useHolidaySummaryPeriods,
  useTimeoffSummaryPeriods
} from '~/modules/resourcing/common/hooks';
import RequestBlock from '../RequestBlock';
import TimeOffSummaryBlocks from '../TimeOffSummaryBlock/TimeOffSummaryBlocks';
import HolidaySummaryBlocks from '../HolidaySummaryBlock/HolidaySummaryBlocks';
import {
  ResourceUserRequestAllocationSummaryBlock,
  ResourceRequestUserAllocationOverlay
} from './components';
import {
  useResourceBlockStyles,
  useResourceAllocationBlockStyles
} from './useStyles';

export const ResourceRequestUserAllocationBlock = ({
  allocation,
  chartDisplayDateRange,
  handleAllocationPeriodClick,
  isEditable = true,
  onAllocationChange,
  overlayPeriods,
  projectDefaultScheduleRule,
  resourceAvailabilitySummarySeries: resourceAvailabilitySummarySeriesParam,
  resourceRequest: resourceRequestParam,
  scale,
  showOverlay = true,
  showRequestBlock = true,
  showResizeIndicator = true,
  hideTimeOffAndHolidays = false
}) => {
  const requestBlockClasses = useResourceBlockStyles();
  const resourceRequest = resourceRequestParam || {};
  const resourceAvailabilitySummarySeries =
    resourceAvailabilitySummarySeriesParam || [];
  const resourceAllocationBlockClasses = useResourceAllocationBlockStyles({
    status: resourceRequest.requestStatus
  });

  const timeoffSummaryPeriods = useTimeoffSummaryPeriods({
    resource: allocation?.user,
    hideTimeOffs: hideTimeOffAndHolidays
  });

  const holidaySummaryPeriods = useHolidaySummaryPeriods({
    resource: allocation?.user,
    hideHolidays: hideTimeOffAndHolidays
  });

  const { allocationPeriods } = useAllocationTimelineBlocks2({
    chartDisplayDateRange,
    allocation,
    scale
  });

  const {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dates
  } = useResourceAllocationDragIndicators({
    resourceAllocation: allocation,
    chartStartDate: chartDisplayDateRange.startDate,
    scale,
    onAllocationChange
  });

  return (
    <div
      className={`${resourceAllocationBlockClasses.timeItem} resourceRequestUserAllocation`}
    >
      {!hideTimeOffAndHolidays && (
        <>
          <TimeOffSummaryBlocks
            scale={scale}
            chartStartDate={chartDisplayDateRange.startDate}
            timeoffSummaryPeriods={timeoffSummaryPeriods}
            chartDisplayDateRange={chartDisplayDateRange}
            timeOffs={allocation?.user.timeoffs}
            showTooltip
            clickable
          />
          <HolidaySummaryBlocks
            scale={scale}
            chartStartDate={chartDisplayDateRange.startDate}
            holidaySummaryPeriods={holidaySummaryPeriods}
            chartDisplayDateRange={chartDisplayDateRange}
            holidays={allocation?.user.holidays}
            showTooltip
            clickable
          />
        </>
      )}
      {showOverlay && (
        <ResourceRequestUserAllocationOverlay
          allocation={allocation}
          isEditable={isEditable}
          scale={scale}
          requestPeriods={overlayPeriods}
          handleAllocationPeriodClick={handleAllocationPeriodClick}
          resourceAvailabilitySummarySeries={resourceAvailabilitySummarySeries}
        />
      )}

      {allocationPeriods &&
        allocationPeriods.map(
          ({
            key,
            startDate,
            endDate,
            dynamicPosition,
            hasAllocationStart,
            hasAllocationEnd,
            totalHours,
            totalCost,
            totalScheduleHours
          }) => (
            <ResourceUserRequestAllocationSummaryBlock
              key={key}
              periodDynamicPostion={dynamicPosition}
              scale={scale}
              isEditable={isEditable}
              startDate={startDate}
              endDate={endDate}
              totalAllocatedHours={totalHours}
              totalScheduledHours={totalScheduleHours}
              totalCost={totalCost}
              resourceRequest={resourceRequest}
              handleAllocationPeriodClick={handleAllocationPeriodClick}
              hasAllocationStart={hasAllocationStart}
              hasAllocationEnd={hasAllocationEnd}
              allocation={allocation}
              chartDisplayDateRange={chartDisplayDateRange}
              resourceAvailabilitySummary={
                resourceAvailabilitySummarySeries.length > 0
                  ? resourceAvailabilitySummarySeries.find(
                      r => r.dateRange.startDate === startDate.toISO()
                    )
                  : // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                    {}
              }
              requestPeriods={allocationPeriods}
              overlayPeriods={overlayPeriods}
              gestureBindEvents={gestureBindEvents}
              dragDelta={dragDelta}
              resizeDirection={currentResizeDirection}
              showResizeIndicator={showResizeIndicator}
              dates={dates}
            />
          )
        )}
      {showRequestBlock && (
        <RequestBlock
          classes={requestBlockClasses}
          resourceRequest={resourceRequest}
          scale={scale}
          chartStartDate={chartDisplayDateRange.startDate}
          projectDefaultScheduleRule={projectDefaultScheduleRule}
          showPercentage={false}
        />
      )}
    </div>
  );
};

ResourceRequestUserAllocationBlock.propTypes = {
  scale: PropTypes.string.isRequired,
  resourceRequest: PropTypes.object,
  projectDefaultScheduleRule: PropTypes.object,
  allocation: PropTypes.object,
  handleAllocationPeriodClick: PropTypes.func,
  resourceAvailabilitySummarySeries: PropTypes.array,
  chartDisplayDateRange: PropTypes.object,
  showRequestBlock: PropTypes.bool,
  isEditable: PropTypes.bool,
  showResizeIndicator: PropTypes.bool,
  onAllocationChange: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool,
  overlayPeriods: PropTypes.array,
  hideTimeOffAndHolidays: PropTypes.bool
};

export default deepPure(ResourceRequestUserAllocationBlock);
