import { makeStyles } from '@material-ui/core';

// check if this is successfully done or not

export const useQualificationStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  title: {
    margin: theme.spacing(0.5, 0, 0, 0)
  },
  content: {
    marginTop: theme.spacing(-4)
  }
}));

export const useRequestedAmountStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0, 0, 0)
  }
}));

export const useFormStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  fieldItem: {
    marginBottom: 0
  }
}));

export const usePlaceholderStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
    fontWeight: 700
  }
}));

export const useFormInputFieldRightStyles = makeStyles({
  fieldItem: {
    borderTopRightRadius: 0
  }
});

export const getResourceRequestFormStyles = theme => ({
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    width: 0,
    margin: theme.spacing(0, 2, 0, 0),
    '&:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      '&:last-child': {
        marginBottom: 0
      }
    },
    padding: 0
  },
  formRow: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  totalsRow: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1.5)
  },
  commentRow: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  selectField: {
    [theme.breakpoints.up('sm')]: {
      padding: [
        [
          theme.spacing(0.5),
          theme.spacing(1),
          theme.spacing(1) - 1,
          theme.spacing(0)
        ]
      ]
    }
  },
  hr: {
    border: `1px solid ${theme.palette.resourceRequest.draft.light}`,
    margin: theme.spacing(1, -2, 0, -2)
  },
  loadingPercentLabel: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  dropDownField: {
    minWidth: '160px',
    padding: 0
  },
  rightField: {
    padding: 0,
    margin: theme.spacing(0, 0, 0, 3)
  },
  input: {
    '-moz-appearance': 'textfield',
    '&:hover, &:focus': {
      '-moz-appearance': 'number-input'
    }
  },
  extendLink: {
    cursor: 'pointer'
  },
  loadOver: {
    color: theme.palette.error.dark
  },
  zeroPaddingField: {
    padding: 0
  },
  dateField: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold'
  },
  quantityLabel: {
    whiteSpace: 'nowrap',
    transform: 'translate(0, 21px) scale(0.85)'
  },
  quantityShrink: {
    transform: 'translate(0, 7px) scale(0.65)'
  },
  quantityInput: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0.5, 0, 0.75, 0),
    height: '1.22em'
  },
  textFieldClassName: {
    margin: 0
  },
  inputClassName: {
    padding: theme.spacing(0.5, 0, 0.5, 0)
  },
  buttonRow: {
    display: 'flex',
    width: '100%',
    padding: 0,
    listStyle: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    alignContent: 'flex-start',
    marginBottom: theme.spacing(1)
  },
  weightTagContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  weightTag: {
    float: 'right',
    marginRight: '-14px'
  },
  expandableError: {
    color: theme.palette.error.main
  }
});

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2)
  },
  buttonRow: {
    display: 'flex',
    marginTop: theme.spacing(4),
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  ...getResourceRequestFormStyles(theme),
  expandableItem: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      '&:last-child': {
        paddingLeft: 0
      }
    }
  },
  expandedItem: {
    backgroundColor: theme.palette.background.paper
  },
  bold: { fontWeight: 'bold' },
  loading: {
    '&[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  totalHours: {
    fontWeight: 'bold',
    '&[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  inputIcon: {
    color: theme.palette.text.secondary
  },
  comment: {
    '&.MuiFilledInput-multiline.MuiFilledInput-marginDense': {
      paddingTop: theme.spacing(1.5)
    }
  },
  adornment: {
    '&.MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      marginTop: 0
    }
  },
  skillsDropdown: {
    '&>div': {
      paddingTop: '6px!important'
    }
  },
  tagsDropdown: {
    marginTop: '-8px'
  },
  name: { fontWeight: 'bold' },
  flexRow: { display: 'flex' }
}));

export const useRowNameStyles = makeStyles(theme => ({
  name: { fontWeight: 'bold' }
}));

export default useStyles;
