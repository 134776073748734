import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSearchState } from '~/modules/common/components/SearchBox';
import {
  useQueryParamSearchContext,
  useIsBillDataReflectedOnAvailableToBill
} from '~/modules/billing-invoicing/common/hooks';
import { debounce, scope } from '~/modules/common/debounce';
import AvailableToBillSearchContext from './AvailableToBillSearchContext';

const calculationStatusScope = scope();

export const recursiveStatusCheckAndRefetchWhenUpdated = async ({
  billId,
  fetchCalculationStatus,
  refetchCallback
}) => {
  const calculationStatus = await fetchCalculationStatus({ billId });

  if (calculationStatus?.data?.isBillDataReflectedInAvailableToBillData) {
    refetchCallback && refetchCallback();
  } else {
    debounce(
      calculationStatusScope,
      async () => {
        await recursiveStatusCheckAndRefetchWhenUpdated({
          billId,
          fetchCalculationStatus,
          refetchCallback
        });
      },
      500
    );
  }
};

export const AvailableToBillSearchContextProvider = ({
  children,
  searchState,
  useSavedCriteria,
  ...props
}) => {
  const [saveCriteria, setSaveCriteria] = useSearchState(searchState);
  const [
    overviewCallToActionCriteria,
    setOverviewCallToActionCriteria
  ] = useState({
    keywords: [],
    criterions: {}
  });

  const filterTags = [
    'projectTag',
    'clientTag',
    'programTag',
    'dateTag',
    'locationTag',
    'divisionTag',
    'serviceCenterTag',
    'costCenterTag',
    'departmentTag',
    'employeeTypeTag'
  ];

  const queryParamSearchContext = useQueryParamSearchContext({ filterTags });

  const { fetchCalculationStatus } = useIsBillDataReflectedOnAvailableToBill();

  const refetchAvailableToBillQueries = useCallback(
    ({ billId, refetchCallback }) =>
      recursiveStatusCheckAndRefetchWhenUpdated({
        billId,
        fetchCalculationStatus,
        refetchCallback
      }),
    [fetchCalculationStatus]
  );

  const values = useMemo(
    () => ({
      searchCriteria: saveCriteria,
      setSearchCriteria: setSaveCriteria,
      overviewCallToActionCriteria,
      setOverviewCallToActionCriteria,
      refetchAvailableToBillQueries,
      ...props
    }),
    [
      refetchAvailableToBillQueries,
      overviewCallToActionCriteria,
      props,
      saveCriteria,
      setSaveCriteria
    ]
  );
  const overviewCallToActionValues = useMemo(
    () => ({
      searchCriteria: overviewCallToActionCriteria,
      setSearchCriteria: setOverviewCallToActionCriteria,
      overviewCallToActionCriteria,
      setOverviewCallToActionCriteria,
      refetchAvailableToBillQueries,
      ...props
    }),
    [refetchAvailableToBillQueries, overviewCallToActionCriteria, props]
  );

  if (queryParamSearchContext.hasValue)
    return (
      <AvailableToBillSearchContext.Provider
        value={queryParamSearchContext.searchContext}
      >
        {children}
      </AvailableToBillSearchContext.Provider>
    );

  return useSavedCriteria ? (
    <AvailableToBillSearchContext.Provider value={values}>
      {children}
    </AvailableToBillSearchContext.Provider>
  ) : (
    <AvailableToBillSearchContext.Provider value={overviewCallToActionValues}>
      {children}
    </AvailableToBillSearchContext.Provider>
  );
};

AvailableToBillSearchContextProvider.propTypes = {
  children: PropTypes.node,
  searchState: PropTypes.any,
  useSavedCriteria: PropTypes.any
};
