import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import {
  transactionRefetchQueries1,
  transactionRefetchQueries2
} from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/useTransactionRefetchQueries';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const DELETE_CREDITMEMO = gql`
  mutation deleteCreditMemo($id: String!) {
    deleteCreditMemo2(id: $id)
  }
`;

export const useDeleteCreditMemo = () => {
  const [deleteCreditMemo] = useMutation(DELETE_CREDITMEMO);

  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  return {
    deleteCreditMemo: ({ id }) =>
      deleteCreditMemo({
        variables: {
          id
        },
        refetchQueries: isPsaFpGraphqlOptimizationEnabled
          ? [...transactionRefetchQueries1]
          : [...transactionRefetchQueries2],
        awaitRefetchQueries: true
      })
  };
};

export default useDeleteCreditMemo;
