import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import DropdownInputComponent from './DropdownInputComponent';

const handleOnDropdownFieldChange = ({
  form: { setFieldTouched, setFieldValue },
  dropdownFieldKeyName
}) => value => {
  setFieldValue(dropdownFieldKeyName, value, true);
  setFieldTouched(dropdownFieldKeyName, true);
};

export const RoleScheduleEditablePrimaryFormRow = ({
  item,
  roleIndex,
  fieldArrayName,
  scheduleIndex,
  form,
  dropdownFieldKey = 'role',
  label,
  permittedActions
}) => {
  const { role } = item;
  const dropdownFieldKeyName = `${fieldArrayName}[${scheduleIndex}].projectRoles[${roleIndex}].${dropdownFieldKey}`;

  const onDropdownFieldChange = useCallback(
    handleOnDropdownFieldChange({ form, dropdownFieldKeyName }),
    [form, dropdownFieldKeyName]
  );

  const disabledValues = useMemo(() => {
    const formSelectedValues =
      form.values &&
      form.values.schedule &&
      form.values.schedule[scheduleIndex] &&
      form.values.schedule[scheduleIndex].projectRoles;

    return formSelectedValues
      ? formSelectedValues.map(u => u.role && u.role.id).filter(x => x != null)
      : [];
  }, [scheduleIndex, form.values]);

  return (
    <Grid item xs={12}>
      <Field
        name={dropdownFieldKeyName}
        onDropdownFieldChange={onDropdownFieldChange}
        value={role}
        label={label}
        component={DropdownInputComponent}
        autoFocus
        disabledValues={disabledValues}
        permittedActions={permittedActions}
      />
    </Grid>
  );
};

RoleScheduleEditablePrimaryFormRow.propTypes = {
  item: PropTypes.object,
  label: PropTypes.string,
  roleIndex: PropTypes.number.isRequired,
  scheduleIndex: PropTypes.number.isRequired,
  fieldArrayName: PropTypes.string,
  form: PropTypes.object.isRequired,
  dropdownFieldKey: PropTypes.string.isRequired,
  permittedActions: PropTypes.array
};

export default RoleScheduleEditablePrimaryFormRow;
