/* eslint-disable max-nested-callbacks */
import { useCallback } from 'react';
import { useModifyTagsMutation } from './useModifyTagsMutation';

export const useOnSubmit = ({
  tags: originalTags,
  objectId,
  refetchQuery,
  intl
}) => {
  const modifyTagsMutation = useModifyTagsMutation();

  return useCallback(
    async (values, bag) => {
      const { resetForm, setValues, setStatus, setSubmitting, setErrors } = bag;

      setSubmitting(true);
      const { tags } = values;

      const removedTags = originalTags.filter(
        tag => tags.find(t => t.tagId === tag.tag.id) === undefined
      );

      const refetchQueries = refetchQuery ? [refetchQuery] : undefined;

      const tagsToAssign =
        tags.map(tag => ({
          objectId,
          tagId: tag.tagId,
          tagName: tag.tagId ? undefined : tag.tagName,
          tagValueId: tag.tagValueId,
          tagValue: tag.tagValueId ? undefined : tag.tagValue
        })) || [];

      const tagsToRemove =
        removedTags.map(tag => ({ tagAssignmentId: tag.id })) || [];

      const variables = { input: { tagsToAssign, tagsToRemove } };

      setStatus(null);
      try {
        const {
          data: {
            modifyAssignedTagValues2: { assigned }
          }
        } = await modifyTagsMutation({
          variables,
          refetchQueries,
          awaitRefetchQueries: true
        });

        setSubmitting(false);
        const isSuccess = !assigned.some(a => a.error);

        if (!isSuccess) {
          const updatedValues = mapValuesWithResponse({ assigned, values });

          setValues(updatedValues);
        }

        const errors = mapValuesWithErrors({ assigned, values, intl });

        setTimeout(() => setErrors(errors), 100);

        if (isSuccess) resetForm();

        return isSuccess;
      } catch (e) {
        setStatus(e);
        setSubmitting(false);

        return false;
      }
    },
    [intl, modifyTagsMutation, objectId, originalTags, refetchQuery]
  );
};

export const mapValuesWithResponse = ({ values, assigned }) => ({
  tags: values.tags.map((tag, i) => {
    const { assignment } = assigned[i];

    return assignment
      ? {
          tagId: assignment.tag.id,
          tagName: assignment.tag.name,
          tagValueId: assignment.value.id,
          tagValue: assignment.value.value
        }
      : tag;
  })
});

export const mapValuesWithErrors = ({ values, assigned, intl }) => ({
  tags: values.tags.map((_, i) => {
    const { error } = assigned[i];

    return error
      ? {
          tagName:
            error.type === 'ValidationError1'
              ? intl.formatMessage({
                  id: 'tags.duplicateKeyError'
                })
              : error.reason
        }
      : undefined;
  })
});
