import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    },
    borderBottom: [[1, 'solid', theme.palette.grey[100]]],
    '&:last-child': {
      borderBottom: 'none'
    },
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    })
  },
  rootDirty: {
    backgroundColor: theme.palette.grey[50]
  },
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, 2, 0),
    padding: 0
  },
  content: {
    margin: 0,
    padding: 0
  }
}));

export const EntityDrawerSection = ({
  classes: classesOverride,
  dirty = false,
  title,
  children
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classnames(classes.root, { [classes.rootDirty]: dirty })}>
      {title ? <h4 className={classes.title}>{title}</h4> : null}
      <div className={classes.content}>{children}</div>
    </div>
  );
};

EntityDrawerSection.propTypes = {
  classes: PropTypes.object,
  dirty: PropTypes.bool,
  title: PropTypes.node,
  children: PropTypes.node
};

export default EntityDrawerSection;
