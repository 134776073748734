import { DateTime } from 'luxon';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { PERIOD_SCALE_ENUM } from '~/modules/resourcing/common/enums';

export const getFormattedDateForScale = (
  periodScale,
  date,
  weeklyFormat = { month: 'short', day: 'numeric' }
) => {
  const startDate = mapIsoStringtoUtcObject(date);

  switch (periodScale) {
    case PERIOD_SCALE_ENUM.WEEKS: {
      const endDate = mapIsoStringtoUtcObject(date.plus({ days: 6 }));

      return `${startDate.toLocaleString(
        weeklyFormat
      )} - ${endDate.toLocaleString(weeklyFormat)}`;
    }
    case PERIOD_SCALE_ENUM.MONTHS: {
      return startDate.toLocaleString({ month: 'short', year: 'numeric' });
    }
    case PERIOD_SCALE_ENUM.YEARS: {
      return startDate.toLocaleString({ year: 'numeric' });
    }
    case PERIOD_SCALE_ENUM.QUARTERS: {
      return `Q${startDate.toFormat('q yyyy')}`;
    }
    default:
      return startDate.toLocaleString(DateTime.DATE_MED);
  }
};

export default getFormattedDateForScale;
