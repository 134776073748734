import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const RESCHEDULE_PROJECT_BATCH = gql`
  mutation RescheduleProjectBatch(
    $rescheduleProjectInputs: RescheduleProjectInputs!
  ) {
    beginRescheduleProjectBackgroundJob(
      rescheduleProjectInputs: $rescheduleProjectInputs
    )
  }
`;

export const useRescheduleProjectBatchMutation = () =>
  useMutation(RESCHEDULE_PROJECT_BATCH, {});

export default useRescheduleProjectBatchMutation;
