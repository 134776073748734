import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  column: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
});

export const StringFormatter = ({ field, record, classes, dataQeId }) => (
  <Typography className={classes.column} variant="body2" data-qe-id={dataQeId}>
    {record[field]}
  </Typography>
);

StringFormatter.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  dataQeId: PropTypes.string
};

export default withStyles(styles)(StringFormatter);
