import React from 'react';
import { FormattedMessage } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ProjectRequestStatusType } from '~/types';
import { PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS } from '~/modules/project-request/enums';

const useQuickFilterSummaryTabs = ({ projectRequestsCountSummary = {} }) => {
  return {
    allItems: [
      {
        key: PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.ALL,
        label: <FormattedMessage id="projectRequest.summaryTabs.allRequests" />,
        value: projectRequestsCountSummary.all
      }
    ],
    ...getSummaryItems({
      projectRequestsCountSummary
    })
  };
};

export const getSummaryItems = ({ projectRequestsCountSummary = 0 }) => ({
  summaryItems: [
    {
      key: ProjectRequestStatusType.New,
      label: <FormattedMessage id="projectRequestStatus.statusLabel.new" />,
      color: theme.palette.projectRequestStatus[ProjectRequestStatusType.New],
      value: projectRequestsCountSummary.new
    },
    {
      key: ProjectRequestStatusType.InReview,
      label: (
        <FormattedMessage id="projectRequestStatus.statusLabel.inReview" />
      ),
      color:
        theme.palette.projectRequestStatus[ProjectRequestStatusType.InReview],
      value: projectRequestsCountSummary.inReview
    },
    {
      key: ProjectRequestStatusType.Approved,
      label: (
        <FormattedMessage id="projectRequestStatus.statusLabel.approved" />
      ),
      color:
        theme.palette.projectRequestStatus[ProjectRequestStatusType.Approved],
      value: projectRequestsCountSummary.approved
    },
    {
      key: ProjectRequestStatusType.Rejected,
      label: (
        <FormattedMessage id="projectRequestStatus.statusLabel.rejected" />
      ),
      color:
        theme.palette.projectRequestStatus[ProjectRequestStatusType.Rejected],
      value: projectRequestsCountSummary.rejected
    }
  ]
});

export default useQuickFilterSummaryTabs;
