import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import HierarchyOptionItem from '~/modules/common/components/HierarchyOptionItem';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';
import { PORTFOLIOS_DROPDOWN_DATA_VARIANT } from './enum';
import usePortfolioDropdown from './hooks/usePortfolioDropdown';

const getOptionSelected = (option, value) => option.id === value.id;

export const isOptionDisabled = ({ option }) => {
  if (option.key === NONE_OPTION_KEY) return false;

  return !option.isAssignable;
};

export const PortfoliosDropdown = ({
  dataQeId,
  dataVariant = PORTFOLIOS_DROPDOWN_DATA_VARIANT.PROJECT,
  hasError,
  helperText,
  includeNoneOption = false,
  multiple,
  noneOptionText = '',
  onChange,
  showLabel = true,
  value = '',
  variant = 'filled'
}) => {
  const { formatMessage } = useIntl();

  const renderOption = useCallback(
    option =>
      option.key === NONE_OPTION_KEY ? (
        <NoneDropdownItem value={option.displayText} />
      ) : (
        <HierarchyOptionItem hierarchyLevel={option.hierarchyLevel}>
          {option.displayText}
        </HierarchyOptionItem>
      ),
    []
  );

  const [searchTerm, setSearchTerm] = useState('');
  const { portfolios, loading } = usePortfolioDropdown({
    dataVariant,
    searchTerm,
    includeNoneOption,
    noneOptionText
  });

  const onInputChange = useCallback(
    (_, val, reason) =>
      reason === 'input' ? setSearchTerm(val) : setSearchTerm(''),
    [setSearchTerm]
  );

  const onValueChange = useCallback(
    val => {
      setSearchTerm('');
      onChange(val);
    },
    [setSearchTerm, onChange]
  );
  const label = showLabel
    ? formatMessage({ id: 'portfoliosDropdown.portfolio' })
    : null;
  const options = loading ? [] : portfolios;

  const getOptionDisabled = useCallback(
    option => isOptionDisabled({ option }),
    []
  );

  const filterOptions = useCallback(
    (filteredOptions, _) => filteredOptions,
    []
  );

  return (
    <SimpleAutocomplete
      variant={variant}
      onInputChange={onInputChange}
      loading={loading}
      loadingText={formatMessage({ id: 'portfoliosDropdown.loading' })}
      noOptionsText={formatMessage({ id: 'portfoliosDropdown.noPortfolio' })}
      dataQeId={dataQeId}
      filterOptions={filterOptions}
      fullWidth
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      hasError={hasError}
      helperText={helperText}
      inputLabel={label}
      multiple={multiple}
      options={options}
      optionPropText="displayText"
      onChange={onValueChange}
      renderOption={renderOption}
      optionTypeText={formatMessage({
        id: 'portfoliosDropdown.portfolios'
      })}
      value={value}
    />
  );
};

PortfoliosDropdown.propTypes = {
  dataQeId: PropTypes.string,
  dataVariant: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.any,
  includeNoneOption: PropTypes.bool,
  multiple: PropTypes.bool,
  noneOptionText: PropTypes.string,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  value: PropTypes.array,
  variant: PropTypes.string
};

export default PortfoliosDropdown;
