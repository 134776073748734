import { useCallback } from 'react';
import { ResourcePlanGroupBy } from '~/types';
import useResourceAllocationChartTabStateContext, {
  RESOURCE_ALLOCATION_CHART_TAB_ENUM
} from './useResourceAllocationChartTabStateContext';
import useResourceAllocationToolbarStateContext from './useResourceAllocationToolbarStateContext';

const useRedirectToAssignResource = ({
  history,
  resourceRequest,
  isResourcingTab = true
}) => {
  const {
    selectedChartTab = RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL
  } = useResourceAllocationChartTabStateContext();

  const { resourcePlanGroup } = useResourceAllocationToolbarStateContext();

  const { id, projectUri, role } = resourceRequest;

  const onAssignResourceClick = useCallback(
    () =>
      isResourcingTab
        ? history.push(
            `/resourcing/assignment/${
              resourcePlanGroup === ResourcePlanGroupBy.Role
                ? role
                  ? role.id
                  : 'no_value'
                : projectUri
            }/${selectedChartTab.toLowerCase()}/${id}`
          )
        : history.push(`/projectResourcing/assignment/${projectUri}/all/${id}`),
    [
      history,
      resourcePlanGroup,
      projectUri,
      role,
      selectedChartTab,
      id,
      isResourcingTab
    ]
  );

  return onAssignResourceClick;
};

export default useRedirectToAssignResource;
