import React from 'react';
import PropTypes from 'prop-types';
import { StickyHeader } from '~/modules/common/components';
import { AdvancedRateDimensionsGroup } from './AdvancedRateDimensionsGroup';
import { RateListTable } from './rateListTable';

export const AdvancedRateCardEditorContent = ({
  availableRateTableDimensions,
  projectDimensions,
  projectRates,
  formOnChange,
  hasMore,
  loadingMore,
  loadMore,
  errors,
  openDeleteDialog,
  projectSlug,
  addNewEntry,
  setAddNewEntry,
  projectCurrency,
  readOnly
}) => {
  return (
    <>
      {readOnly ? null : (
        <StickyHeader>
          <AdvancedRateDimensionsGroup
            availableRateTableDimensions={availableRateTableDimensions}
            projectDimensions={projectDimensions}
            formOnChange={formOnChange}
            projectRates={projectRates}
            loadingMore={loadingMore}
          />
        </StickyHeader>
      )}
      <RateListTable
        projectRates={projectRates}
        projectDimensions={projectDimensions}
        formOnChange={formOnChange}
        hasMore={hasMore}
        loadingMore={loadingMore}
        loadMore={loadMore}
        errors={errors}
        openDeleteDialog={openDeleteDialog}
        projectSlug={projectSlug}
        addNewEntry={addNewEntry}
        setAddNewEntry={setAddNewEntry}
        projectCurrency={projectCurrency}
        readOnly={readOnly}
      />
    </>
  );
};

AdvancedRateCardEditorContent.propTypes = {
  availableRateTableDimensions: PropTypes.array,
  projectDimensions: PropTypes.array,
  projectRates: PropTypes.array,
  formOnChange: PropTypes.object,
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  loadMore: PropTypes.func,
  errors: PropTypes.object,
  openDeleteDialog: PropTypes.func,
  projectSlug: PropTypes.string,
  addNewEntry: PropTypes.bool,
  setAddNewEntry: PropTypes.func,
  projectCurrency: PropTypes.object,
  readOnly: PropTypes.bool
};

export default AdvancedRateCardEditorContent;
