import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';
import { useBillingOverviewTableContext } from '../../BillingOverviewTableContext';

export const BillingOverviewHeaderRow = ({
  children,
  displayTotalColumn = false,
  dataQeId
}) => {
  const { periods, overviewClasses } = useBillingOverviewTableContext();

  return (
    <TableRow
      role="row"
      className={classNames(overviewClasses.row, overviewClasses.headerRow)}
    >
      <TableCell
        component="th"
        scope="row"
        role="rowheader"
        className={classNames(overviewClasses.cell, overviewClasses.cellLabel)}
        data-qe-id={dataQeId}
      >
        {children}
      </TableCell>
      {periods.map(({ key, isCurrentPeriod }) => (
        <TableCell
          key={key}
          className={classNames(overviewClasses.cell, {
            [overviewClasses.cellCurrent]: isCurrentPeriod
          })}
        />
      ))}
      {displayTotalColumn && (
        <TableCell
          className={classNames(
            overviewClasses.cell,
            overviewClasses.cellTotal
          )}
        />
      )}
    </TableRow>
  );
};

BillingOverviewHeaderRow.propTypes = {
  children: PropTypes.object,
  displayTotalColumn: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default BillingOverviewHeaderRow;
