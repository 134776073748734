import { useCallback } from 'react';

export const useFormOnCancel = ({
  handleReset,
  onClose,
  saving,
  inProgress
}) => ({
  onCancel: useCallback(() => {
    handleReset();
    !saving && !inProgress && onClose();
  }, [saving, onClose, inProgress, handleReset])
});

export default useFormOnCancel;
