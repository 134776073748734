import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 0
  },
  resultContainer: {
    overflow: 'auto',
    height: theme.spacing(32)
  },
  lineItemsContainer: {
    width: '100%'
  },
  container: () => ({
    width: '100%',
    padding: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 0, 1)
    },
    margin: theme.spacing(0)
  }),
  loadingContainer: {
    padding: theme.spacing(1, 1, 0, 1),
    margin: 0,
    width: 700,
    height: 50,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '40%'
  },
  fieldContainer: {
    width: '100%',
    padding: theme.spacing(0, 2, 1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 1, 1)
    },
    margin: theme.spacing(0, 0, 1, 0)
  },
  groupBar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(0, 3, 1, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2, 1, 2)
    },
    minHeight: '-webkit-max-content'
  }
}));
