import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DelaStarIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <path
      d="M8.624.12a.85.85,0,0,1,.818.617l.922,3.227a4.252,4.252,0,0,0,2.92,2.92l3.227.922a.85.85,0,0,1,0,1.635l-3.227.922a4.252,4.252,0,0,0-2.92,2.92l-.922,3.227a.85.85,0,0,1-1.635,0l-.922-3.227a4.252,4.252,0,0,0-2.92-2.92L.737,9.442a.85.85,0,0,1,0-1.635l3.227-.922a4.252,4.252,0,0,0,2.92-2.92L7.807.737A.85.85,0,0,1,8.624.12Z"
      transform="translate(4.805 7.35)"
      fill="#4a01e0"
      fillRule="evenodd"
    />
    <path
      d="M4.923,0A.492.492,0,0,1,5.4.357l.534,1.868A2.462,2.462,0,0,0,7.621,3.916L9.49,4.45a.492.492,0,0,1,0,.947l-1.868.534A2.462,2.462,0,0,0,5.931,7.621L5.4,9.49a.492.492,0,0,1-.947,0L3.916,7.621A2.462,2.462,0,0,0,2.225,5.931L.357,5.4a.492.492,0,0,1,0-.947l1.868-.534A2.462,2.462,0,0,0,3.916,2.225L4.45.357A.492.492,0,0,1,4.923,0Z"
      fill="#4a01e0"
      fillRule="evenodd"
    />
  </SvgIcon>
));

export default DelaStarIcon;
