import React from 'react';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import MenuIcon from '@material-ui/icons/MenuSharp';

import { useSideNavigationContext } from '~/modules/navigation/components/SideNavigation/useSideNavigationContext';

export const NavigationMenuButton = ({}) => {
  const { formatMessage } = useIntl();
  const { openMenu } = useSideNavigationContext();

  return (
    <IconButton
      onClick={openMenu}
      aria-label={formatMessage({ id: 'mobileMenu.menu' })}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default NavigationMenuButton;
