import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { RESOURCE_MANAGEMENT_TAB_ENUM } from '../../enums';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: -1
  }
}));

export const ResourceManagementTabs = ({ selectedTab, onChange }) => {
  const classes = useStyles();
  const intl = useIntl();

  const isMobile = useIsBreakpointDown('sm');

  return (
    <Tabs
      classes={classes}
      indicatorColor="primary"
      value={selectedTab}
      onChange={onChange}
    >
      <Tab
        value={RESOURCE_MANAGEMENT_TAB_ENUM.OVERVIEW}
        label={intl.formatMessage({ id: 'resourceManagementTabs.overview' })}
      />
      {!isMobile && (
        <Tab
          value={RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE}
          label={intl.formatMessage({ id: 'resourceManagementTabs.timeline' })}
        />
      )}
      <Tab
        value={RESOURCE_MANAGEMENT_TAB_ENUM.LIST}
        label={intl.formatMessage({ id: 'resourceManagementTabs.list' })}
      />
    </Tabs>
  );
};

ResourceManagementTabs.propTypes = {
  selectedTab: PropTypes.oneOf([
    RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE,
    RESOURCE_MANAGEMENT_TAB_ENUM.LIST,
    RESOURCE_MANAGEMENT_TAB_ENUM.OVERVIEW
  ]),
  onChange: PropTypes.func.isRequired
};

export default ResourceManagementTabs;
