import React, { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

const titleSeparator = ' - ';

export const TitleContext = React.createContext();

const setDocumentTitle = title => {
  document.title = title;
};

export const withTitleStateProvider = BaseComponent => ({
  setTitle = setDocumentTitle,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { current: titleParts } = useRef([formatMessage({ id: 'appTitle' })]);

  const renderTitle = () => {
    const title = titleParts.join(titleSeparator);

    setTitle(title);
  };

  const pushTitleParts = (newParts = []) => {
    const newTitleParts = newParts
      .filter(p => p.title || p.messageId)
      .map(p => p.title || formatMessage({ id: p.messageId }));

    titleParts.splice(1, titleParts.length, ...newTitleParts);
    renderTitle();
  };

  const popTitleParts = (newParts = []) => {
    titleParts.splice(1, newParts.length);
    renderTitle();
  };

  return (
    <TitleContext.Provider value={{ pushTitleParts, popTitleParts }}>
      <BaseComponent {...rest} />
    </TitleContext.Provider>
  );
};

export const withTitleProvider = withTitleStateProvider;

export const usePageTitle = (newParts = []) => {
  const { pushTitleParts, popTitleParts } = useContext(TitleContext);

  useEffect(() => {
    pushTitleParts(newParts);

    return () => {
      popTitleParts(newParts);
    };
  }, [newParts, popTitleParts, pushTitleParts]);
};
