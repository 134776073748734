import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  noMessage: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  container: {
    position: 'relative'
  }
}));

export default useStyles;
