import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { ResourcePoolIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';

export const ResourcePoolTooltipContent = ({ resourcePools, classes }) => {
  const resourcePoolsCount = useMemo(
    () => ({
      count: resourcePools.length
    }),
    [resourcePools]
  );

  return (
    <>
      <div className={classes.detailsIndicatorTooltipTitle}>
        <FormattedMessage
          id="resourceRequestResourcePoolField.resourcePool"
          values={resourcePoolsCount}
        />
      </div>
      <div className={classes.detailsIndicatorTooltipContent}>
        <ul className={classes.noBulletList}>
          {resourcePools.map(pool => (
            <li key={pool.id}>
              <span>{pool.displayText}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

ResourcePoolTooltipContent.propTypes = {
  resourcePools: PropTypes.array,
  classes: PropTypes.object
};

const ResourcePoolTitle = ({ resourcePools, classes }) => (
  <ResourcePoolTooltipContent resourcePools={resourcePools} classes={classes} />
);

ResourcePoolTitle.propTypes = {
  resourcePools: PropTypes.array,
  classes: PropTypes.object
};

export const ResourceRequestResourcePoolField = ({
  resourcePools,
  tooltipClasses
}) => {
  const classes = useStyles();

  const title = ResourcePoolTitle({ resourcePools, classes });

  return (
    <Tooltip title={title} classes={tooltipClasses}>
      <ResourcePoolIcon className={classes.detailsIndicator} />
    </Tooltip>
  );
};

ResourceRequestResourcePoolField.propTypes = {
  resourcePools: PropTypes.array,
  tooltipClasses: PropTypes.object
};

export default ResourceRequestResourcePoolField;
