import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    opacity: 0.7,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
    borderTop: `solid 1px ${alpha(theme.palette.common.black, 0.2)}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    borderTop: `solid 1px ${alpha(theme.palette.common.black, 0.2)}`
  }
}));

const SectionHeader = ({
  classes: classesOverride,
  title,
  showDivider = true
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <Typography
      variant="h5"
      className={classNames(classes.root, { [classes.divider]: showDivider })}
    >
      {title}
    </Typography>
  );
};

SectionHeader.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  showDivider: PropTypes.bool
};

export default SectionHeader;
