import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Decimal, NoValue } from '~/modules/common/components';

export const CellValueFormatter = ({
  value,
  currencySymbol,
  dataQeId = 'Decimal'
}) => {
  if (!value) return <NoValue data-qe-id={dataQeId} />;

  return currencySymbol ? (
    <Decimal value={value} prefix={`${currencySymbol} `} dataQeId={dataQeId} />
  ) : (
    <Decimal value={value} dataQeId={dataQeId} />
  );
};

CellValueFormatter.propTypes = {
  value: PropTypes.number,
  currencySymbol: PropTypes.string,
  dataQeId: PropTypes.string
};

export default memo(
  CellValueFormatter,
  (prevProps, nextProps) => prevProps.value === nextProps.value
);
