import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useMeContext } from '~/modules/me';

export const BULK_GET_PROJECT_OR_CLIENT_BILLING_DEFAULTS_FOR_AVAILABLE_TO_BILL = gql`
  query bulkGetProjectOrClientBillingDefaultsForAvailableToBill(
    $filters: [AvailableToBillFilter2!]
  ) {
    bulkGetProjectOrClientBillingDefaultsForAvailableToBill(filters: $filters) {
      poNumber
      defaultInvoicePaymentTerm
      invoiceCurrency {
        id
        symbol
        displayText
      }
      taxProfile {
        id
        displayText
      }
      invoiceTemplate {
        id
        displayText
      }
      billingContactInfo {
        address
        city
        country {
          id
          displayText
        }
        email
        faxNumber
        phoneNumber
        stateProvince
        website
        zipPostalCode
      }
      billLineItemsBy
      description
      internalNotes
      showComments
    }
  }
`;

export const useBulkGetProjectOrClientBillingDefaultsForAvailableToBill = apolloClient => {
  return {
    fetchBulkGetProjectBillingDefaultsForAvailableToBill: async filters => {
      const { data } = await apolloClient.query({
        query: BULK_GET_PROJECT_OR_CLIENT_BILLING_DEFAULTS_FOR_AVAILABLE_TO_BILL,
        variables: { filters },
        fetchPolicy: 'network-only'
      });

      return (
        data?.bulkGetProjectOrClientBillingDefaultsForAvailableToBill || []
      );
    }
  };
};

export const useBulkProjectOrClientBillingDefaultsForAvailableToBill = filters => {
  const me = useMeContext();
  const { isPsaPrpBillingDefaultForProjectEnabled } = me.featureFlags;
  const { data, loading } = useQuery(
    BULK_GET_PROJECT_OR_CLIENT_BILLING_DEFAULTS_FOR_AVAILABLE_TO_BILL,
    {
      variables: { filters },
      skip: !isPsaPrpBillingDefaultForProjectEnabled,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  );

  return {
    projectOrClientBillingDefaults:
      data?.bulkGetProjectOrClientBillingDefaultsForAvailableToBill || [],
    loading
  };
};
