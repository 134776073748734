import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useMeContext } from '~/modules/me';
import {
  objectToFormatString,
  isoStringToFormattedISOString,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import RollupStatusSection from '../TaskStatusSection/RollupStatusSection/RollupStatusSection';
import MobileRollUpProjectStatusSection from '../TaskStatusSection/MobileRollupStatusSection/MobileRollUpProjectStatusSection';

const mapProject = project => ({
  ...project,
  children: project.tasks,
  taskStatus: project.status,
  initialEstimatedHours: project.budgetHours,
  effectiveStartDate: project.startDate,
  estimatedCompletionDate: project.endDate
});

const ProjectStatusSection = ({ project, hasMore, loadMore, loadingMore }) => {
  const me = useMeContext();
  const {
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled }
  } = me;
  const { rolledUpSummary, endDate } = project;
  const mappedProject = useMemo(() => mapProject(project), [project]);
  const dateFormat = getLuxonJsDateFormatFromMe(me);
  const estimatedAtCompletionDate = isoStringToFormattedISOString(
    rolledUpSummary?.estimatedCompletionDate,
    dateFormat
  );
  const isMobile = useIsBreakpointDown('sm');

  return isMobile && isPsaPpmEstimatedCostAtCompletionEnabled ? (
    <MobileRollUpProjectStatusSection
      source={mappedProject}
      hasMore={hasMore}
      loadMore={loadMore}
      loadingMore={loadingMore}
      estimatedCompletionDate={
        estimatedAtCompletionDate || objectToFormatString(endDate, dateFormat)
      }
    />
  ) : (
    <RollupStatusSection
      source={mappedProject}
      variant="project"
      hasMore={hasMore}
      loadMore={loadMore}
      loadingMore={loadingMore}
      estimatedCompletionDate={
        estimatedAtCompletionDate || objectToFormatString(endDate, dateFormat)
      }
    />
  );
};

ProjectStatusSection.propTypes = {
  project: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired
};

export default ProjectStatusSection;
