import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';

export const PROJECT_DROPDOWN_QUERY = gql`
  query ProjectDropdownSearch($filter: ProjectFilter) {
    projects(page: 1, pagesize: 100, projectFilter: $filter) {
      id
      displayText
    }
  }
`;

const useProjectDropdownOptions = (clientId, noneLabel) => {
  const { query, resultKey } = {
    query: PROJECT_DROPDOWN_QUERY,
    resultKey: 'projects'
  };
  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      columns: ['project', 'displayText'],
      filter: {
        clients: [clientId],
        executionPhase: [
          PROJECT_EXECUTION_PHASE.INITIATE,
          PROJECT_EXECUTION_PHASE.PLANNING,
          PROJECT_EXECUTION_PHASE.EXECUTION
        ]
      }
    }
  });

  const projects = [{ displayText: noneLabel, id: 'none-option' }];

  return {
    options: loading ? [] : projects.concat(get(data, resultKey, [])),
    loading
  };
};

export default useProjectDropdownOptions;
