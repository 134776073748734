import React from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useStyles } from './useStyles';

export const ResourcePoolDropdownGrid = ({
  classes: classesOverride,
  resourcePoolContent,
  isLast = false
}) => {
  const classes = useStyles({ classes: classesOverride });

  if (!resourcePoolContent) {
    return null;
  }

  return (
    <Grid container item className={classes.groupContainer} spacing={1}>
      <Grid
        container
        className={classNames(
          { [classes.lastItemContainer]: isLast },
          classes.itemsRowContainer
        )}
        spacing={1}
      >
        <Grid
          key="Group-resource-pool"
          item
          xs={12}
          className={classes.groupItem}
        >
          {resourcePoolContent?.Component}
        </Grid>
      </Grid>
    </Grid>
  );
};

ResourcePoolDropdownGrid.propTypes = {
  classes: PropTypes.object,
  resourcePoolContent: PropTypes.shape({
    value: PropTypes.array,
    Component: PropTypes.node,
    isRequired: PropTypes.bool
  }),
  isLast: PropTypes.bool
};

export default ResourcePoolDropdownGrid;
