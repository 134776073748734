export const getResourceRequestHoursDelta = ({
  resourceRequestTotalHours,
  totalAllocatedHoursForResourceRequest
}) => totalAllocatedHoursForResourceRequest - resourceRequestTotalHours;

export const getResourceRequestCostDelta = ({
  resourceRequestTotalCost,
  totalAllocatedCostForResourceRequest
}) => {
  const { currencyUri, amount } = resourceRequestTotalCost;
  const allocatedCost = totalAllocatedCostForResourceRequest.find(
    tac => tac.currencyUri === currencyUri
  );

  return {
    ...resourceRequestTotalCost,
    amount: (allocatedCost && allocatedCost.amount - amount) || 0
  };
};

export const getResourceRequestDeltaTotals = ({
  resourceRequestTotalHours,
  resourceRequestTotalCost = [],
  totalAllocatedHoursForResourceRequest,
  totalAllocatedCostForResourceRequest = []
}) => ({
  deltaTotalHours: getResourceRequestHoursDelta({
    resourceRequestTotalHours,
    totalAllocatedHoursForResourceRequest
  }),
  deltaTotalCost: getResourceRequestCostDelta({
    resourceRequestTotalCost,
    totalAllocatedCostForResourceRequest
  })
});
