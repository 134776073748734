export const BILLING_COLUMN_TYPE = {
  USER: 'USER',
  PROJECT: 'PROJECT',
  TASK: 'TASK',
  ITEMTYPE: 'ITEMTYPE',
  RATE: 'RATE',
  QUANTITY: 'QUANTITY',
  UNIT: 'UNIT',
  BILLING_CONTRACT_CLAUSE: 'BILLING_CONTRACT_CLAUSE',
  TIMESHEET_PERIOD: 'TIMESHEET_PERIOD',
  PAYCODE: 'PAYCODE',
  ROLE: 'ROLE',
  EXPENSE_CODE: 'EXPENSE_CODE'
};
