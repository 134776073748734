import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import RevenueRecognitionPolicy from './RevenueRecognitionPolicy';
import { useRevenueRecognitionPolicy } from './hooks';

export const RevenueRecognitionPolicyFacetDetails = ({
  className,
  selected,
  setSelected
}) => {
  const intl = useIntl();

  const { revenuePolicies, loading } = useRevenueRecognitionPolicy({
    includeNoneOption: true,
    noneOptionText: intl.formatMessage({
      id: 'revenueRecognitionPolicyFacet.noRevenueRecognitionPolicy'
    }),
    intl
  });

  const renderOption = useCallback(
    option =>
      option.key === intl.formatMessage({ id: 'NONE' }) ? (
        <NoneDropdownItem value={option.displayText} />
      ) : (
        <RevenueRecognitionPolicy value={option.label} />
      ),
    [intl]
  );
  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option => selected.some(v => v.id === option.id),
    [selected]
  );

  const resourceLables = useMemo(
    () => ({
      revenueRecognitionPolicy: intl.formatMessage({
        id: 'search.revenueRecognitionPolicy'
      }),
      noRevenueRecognitionPolicy: intl.formatMessage({
        id: 'revenueRecognitionPolicyFacet.noRevenueRecognitionPolicy'
      })
    }),
    [intl]
  );

  const classes = useStyles();

  return (
    <FacetDetailField
      label={resourceLables.revenueRecognitionPolicy}
      className={className}
    >
      <SimpleAutocomplete
        options={revenuePolicies}
        value={selected}
        variant="standard"
        loading={loading}
        onChange={onValueChange}
        multiple
        fullWidth
        classes={classes}
        hiddenLabel
        getOptionDisabled={getOptionDisabled}
        noOptionsText={resourceLables.noRevenueRecognitionPolicy}
        renderOption={renderOption}
        optionPropText="label"
      />
    </FacetDetailField>
  );
};

RevenueRecognitionPolicyFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default RevenueRecognitionPolicyFacetDetails;
