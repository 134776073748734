/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SideCardSectionDetail } from '~/modules/common/components/SideCard';
import { NoDataItem } from '~/modules/common/components';
import ViewDetailsLink from './ViewDetailsLink';

const getNoTextComponent = id => (
  <NoDataItem>
    <FormattedMessage id={id} />
  </NoDataItem>
);

const ContactInfoGenericList = ({
  items,
  noDataMessageId,
  handleViewDetails,
  children
}) => {
  return (
    <>
      {items && items.length ? (
        <>
          {items.slice(0, 2).map((item, index) => (
            <React.Fragment key={index}>
              {React.cloneElement(children, { key: `item-${index}`, item })}
              {index < 1 ? <Divider key={`divider-${index}`} /> : null}
            </React.Fragment>
          ))}
          {items.length > 0 && (
            <>
              <Divider />
              <ViewDetailsLink
                count={items.length < 3 ? '' : `+${items.length - 2}`}
                handleViewDetails={handleViewDetails}
              />
            </>
          )}
        </>
      ) : (
        <SideCardSectionDetail
          noPadding
          detail={getNoTextComponent(noDataMessageId)}
        />
      )}
    </>
  );
};

ContactInfoGenericList.propTypes = {
  items: PropTypes.array,
  noDataMessageId: PropTypes.string,
  handleViewDetails: PropTypes.func,
  children: PropTypes.node
};

export default ContactInfoGenericList;
