import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@material-ui/core';
import { NoValue } from '~/modules/common/components';
import { FullPath } from '../FullPath';

const getAncestors = data => {
  if (!data.ancestors) {
    return null;
  }

  return (
    <FullPath fullPath={data.ancestors.map(ancestor => ancestor.displayText)} />
  );
};

export const HierarchyColumn = ({ field, record }) => {
  if (record[field] && record[field].displayText) {
    return (
      <>
        {getAncestors(record[field])}
        <Typography variant="body2">{record[field].displayText}</Typography>
      </>
    );
  }

  return <NoValue />;
};

HierarchyColumn.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default HierarchyColumn;
