import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useMeContext } from '~/modules/me';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import {
  useHasPermission,
  useHasUserAccessRole
} from '~/modules/common/permissions';
import { useCodeAndNameRequired } from '~/modules/common/hooks/useCodeAndNameRequired';
import { isoStringToObjectWithCache as isoStringToObject } from '~/modules/common/dates/convert';
import { getProjectTypes } from '~/modules/projects/projectTypes';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';
import EditProjectDialog from '../EditProjectDialog';
import BasicInfoReadOnly from './BasicInfoReadOnly';
import EditBasicInfoForm from './EditBasicInfoForm';
import { buildValidationSchema } from './validationSchema';
import { useUpdateProjectBasicInfo } from './useUpdateProjectBasicInfo';
import { useUpdateCoManagersHandler } from './useUpdateCoManagersHandler';

export const getProjectTypeFromUri = (options, uri) =>
  options.find(option => option.id === uri);

export const EditBasicInfoDialog = ({
  title,
  open,
  onClose,
  projectPermissions,
  projectId,
  slackChannel,
  earnedRevenueScript,
  projectName,
  projectSlug,
  code,
  startDate,
  endDate,
  defaultScheduleRule,
  projectManagementType,
  projectManagerReference,
  coManagers,
  isProjectLeaderApprovalRequired,
  timeAndExpenseEntryType,
  isTimeEntryAllowed,
  program,
  portfolio,
  description,
  intl,
  onReschedule,
  templateSettings
}) => {
  const me = useMeContext();
  const { isPsaEarnedRevenueEnabled } = me.featureFlags;
  const { formatMessage } = intl;
  const [submitFromUnsavedPrompt, setSubmitFromUnsavedPrompt] = useState(false);
  const { isCodeRequired, isNameRequired } = useCodeAndNameRequired({
    codeNameAndUniqueness: me.projectNameAndCodeUniqueness
  });
  const projectTypes = useMemo(() => getProjectTypes(formatMessage), [
    formatMessage
  ]);

  const initialValues = useMemo(
    () => ({
      projectUri: projectId,
      name: projectName || '',
      code: code || '',
      startDate: startDate ? isoStringToObject(startDate) : null,
      endDate: endDate ? isoStringToObject(endDate) : null,
      earnedRevenueScript: isPsaEarnedRevenueEnabled
        ? earnedRevenueScript
        : null,
      description: description || '',
      program,
      portfolio,
      projectManagerReference,
      coManagers,
      isProjectLeaderApprovalRequired,
      isTimeEntryAllowed,
      timeAndExpenseEntryType: isTimeEntryAllowed
        ? {
            id:
              (timeAndExpenseEntryType && timeAndExpenseEntryType.id) ||
              TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE,
            displayText: intl.formatMessage({
              id: 'billingOptions.'.concat(TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE)
            })
          }
        : {
            id: TIME_AND_EXPENSE_ENTRY_TYPE.NO,
            displayText: intl.formatMessage({
              id: 'billingOptions.'.concat(TIME_AND_EXPENSE_ENTRY_TYPE.NO)
            })
          },
      projectTypeReference: getProjectTypeFromUri(
        projectTypes,
        projectManagementType
      ),
      slackChannel: slackChannel || ''
    }),
    [
      projectId,
      projectName,
      code,
      startDate,
      endDate,
      isPsaEarnedRevenueEnabled,
      earnedRevenueScript,
      description,
      program,
      portfolio,
      projectManagerReference,
      coManagers,
      isProjectLeaderApprovalRequired,
      isTimeEntryAllowed,
      timeAndExpenseEntryType,
      intl,
      projectTypes,
      projectManagementType,
      slackChannel
    ]
  );

  const isProgramManager = useHasUserAccessRole({
    roleUri: USER_ACCESS_ROLE.PROGRAM_MANAGER
  });

  const canViewAllProjects = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-project',
    dataAccessLevelUri:
      'urn:replicon:project-data-access-level:project-management-data-access-scope'
  });

  const shouldFetchOnlyMyPrograms =
    isProgramManager &&
    !canViewAllProjects &&
    me.uri !== (projectManagerReference && projectManagerReference.id);

  const validationSchema = buildValidationSchema({
    intl,
    isCodeRequired,
    isNameRequired,
    isProgramRequired: shouldFetchOnlyMyPrograms
  });

  const showBasicInfoReadOnly =
    !projectPermissions.canEditProject && projectPermissions.canEditCoManagers;

  const updateProjectBasicInfoHandler = useUpdateProjectBasicInfo({
    projectName,
    closeDialog: onClose,
    defaultScheduleRule,
    submitFromUnsavedPrompt,
    setSubmitFromUnsavedPrompt,
    onReschedule,
    isPsaEarnedRevenueEnabled
  });

  const updateCoManagersBasicInfoHandler = useUpdateCoManagersHandler({
    projectSlug,
    closeDialog: onClose
  });

  return (
    <EditProjectDialog
      title={title}
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={
        showBasicInfoReadOnly
          ? updateCoManagersBasicInfoHandler
          : updateProjectBasicInfoHandler
      }
    >
      {showBasicInfoReadOnly ? (
        <BasicInfoReadOnly
          projectPermissions={projectPermissions}
          templateSettings={templateSettings}
        />
      ) : (
        <EditBasicInfoForm
          intl={intl}
          projectPermissions={projectPermissions}
          isCodeRequired={isCodeRequired}
          isNameRequired={isNameRequired}
          onReschedule={onReschedule}
          setSubmitFromUnsavedPrompt={setSubmitFromUnsavedPrompt}
          shouldFetchOnlyMyPrograms={shouldFetchOnlyMyPrograms}
          templateSettings={templateSettings}
        />
      )}
    </EditProjectDialog>
  );
};

EditBasicInfoDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectId: PropTypes.string,
  slackChannel: PropTypes.string,
  earnedRevenueScript: PropTypes.object,
  projectName: PropTypes.string,
  projectSlug: PropTypes.string,
  code: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  defaultScheduleRule: PropTypes.object,
  projectManagementType: PropTypes.string,
  projectManagerReference: PropTypes.object,
  coManagers: PropTypes.array,
  isProjectLeaderApprovalRequired: PropTypes.bool,
  program: PropTypes.object,
  portfolio: PropTypes.object,
  description: PropTypes.string,
  intl: PropTypes.object,
  projectPermissions: PropTypes.object,
  onReschedule: PropTypes.func,
  isTimeEntryAllowed: PropTypes.bool,
  timeAndExpenseEntryType: PropTypes.object,
  templateSettings: PropTypes.object
};

export default injectIntl(EditBasicInfoDialog);
