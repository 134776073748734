import { useFormik } from 'formik';
import { useMemo } from 'react';

import { ReleaseResourceAllocationType } from '~/types';

export const getInitialState = ({ allocations, selectedAllocationId }) => ({
  comment: '',
  releaseConfirmed: false,
  releaseDate: new Date().toISOString(),
  releaseType: ReleaseResourceAllocationType.Entirely,
  releaseAllocationsRows: allocations.map(allocation => ({
    id: allocation.id,
    startDate: allocation.startDate,
    endDate: allocation.endDate,
    role: allocation.role,
    isSelected: selectedAllocationId
      ? selectedAllocationId === allocation?.id
      : allocations.length === 1 || false
  }))
});

export const useReleaseAllocationsDialogState = ({
  allocations,
  onReleaseResourceAllocations,
  selectedAllocationId
}) => {
  const initialValues = useMemo(
    () =>
      getInitialState({
        allocations,
        selectedAllocationId
      }),
    [allocations, selectedAllocationId]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: onReleaseResourceAllocations,
    enableReinitialize: true
  });

  return formik;
};
export default useReleaseAllocationsDialogState;
