import { gql } from 'graphql-tag';

const resourceRequestTotalsFragment = gql`
  fragment ResourceRequestTotalsFragment on ResourceRequest {
    totalRequestedCostByCurrency {
      baseCurrency {
        currency {
          id
        }
        amount
      }
      projectBudgetCurrency {
        currency {
          id
        }
        amount
      }
    }
  }
`;

export default resourceRequestTotalsFragment;
