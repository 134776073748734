import deepEqual from 'fast-deep-equal';

export const skipComponentUpdate = keyProps => (prev, next) => {
  const prevProps = keyProps.reduce((acc, propKey) => {
    acc[propKey] = prev[propKey];

    return acc;
  }, {});
  const nextProps = keyProps.reduce((acc, propKey) => {
    acc[propKey] = next[propKey];

    return acc;
  }, {});

  return deepEqual(prevProps, nextProps);
};
