import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

const customFieldQuery = gql`
  {
    enabledCustomFields(groupName: "Task") {
      uri
      displayText
      isRequired
      isVisible
      type {
        displayText
        uri
      }
      textConfiguration {
        defaultTextValue
        maximumLength
      }
      dateConfiguration {
        defaults {
          useTodayAsDefault
          value
        }
        maximumDate
        minimumDate
      }
      numericConfiguration {
        decimalPlaces
        defaultNumericValue
        maximumNumericValue
        minimumNumericValue
      }
      defaultDropDownValue {
        displayText
        isDefaultValue
        isEnabled
        uri
      }
      dropDownOptions {
        displayText
        isDefaultValue
        isEnabled
        uri
      }
    }
  }
`;

export const useTaskCustomFieldDefinitions = () => {
  const { data, error, loading } = useQuery(customFieldQuery);
  const enabledCustomFields = get(data, 'enabledCustomFields');

  return {
    loading,
    error,
    customFieldDefinitions: error || loading ? [] : enabledCustomFields
  };
};
