import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ResourceSearchBox } from '~/modules/common/components';
import { ResourcingTabs } from '~/modules/resourcing/common/components';
import StickyHeader from '../common/components/StickyHeader/StickyHeader';
import { useSearchState } from '../common/components/SearchBox';
import QuickAllocationUserChart from './components/QuickAllocationUserChart';
import ProjectStatusFilter from './components/ProjectStatusFilter';
import useQuickAllocationChartSettings from './useQuickAllocationChartSettings';

const useStyles = makeStyles(theme => ({
  search: {
    flexGrow: 1,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      padding: theme.spacing(0, 2, 1, 2)
    },
    backgroundColor: theme.palette.background.paper
  }
}));

const useSearchStyles = makeStyles(theme => ({
  searchBoxContainer: {
    display: 'flex',
    flexGrow: 1,
    paddingLeft: theme.spacing(2)
  }
}));

const filterFacetOptions = {
  role: true,
  skill: true,
  location: true,
  division: true,
  serviceCenter: true,
  costCenter: true,
  department: true,
  employeeType: true,
  tags: true,
  project: true,
  client: true,
  program: true,
  resourcePool: true
};

export const QuickAllocationContent = ({ chartSettings }) => {
  const {
    setProjectStatusFilterOption,
    projectStatusFilterOption
  } = useQuickAllocationChartSettings({
    chartSettings
  });

  const classes = useStyles();
  const [searchCriteria, setSearchCriteria] = useSearchState(
    'quick-allocation'
  );

  const searchClasses = useSearchStyles();

  return (
    <>
      <StickyHeader showMenuButton={false} disableTitleFullWidth rightPadding>
        <ResourcingTabs />
        {setSearchCriteria && (
          <div className={classes.search}>
            <ResourceSearchBox
              dataQeId="QuickAllocation-SearchBar"
              setSearchCriteria={setSearchCriteria}
              searchCriteria={searchCriteria}
              filterFacetOptions={filterFacetOptions}
              classes={searchClasses}
            />

            <ProjectStatusFilter
              projectStatusFilterOption={projectStatusFilterOption}
              setProjectStatusFilterOption={setProjectStatusFilterOption}
            />
          </div>
        )}
      </StickyHeader>
      <QuickAllocationUserChart
        setSearchCriteria={setSearchCriteria}
        searchCriteria={searchCriteria}
        projectStatusFilterOption={projectStatusFilterOption}
        chartSettings={chartSettings}
      />
    </>
  );
};

QuickAllocationContent.propTypes = {
  chartSettings: PropTypes.object.isRequired
};

export default QuickAllocationContent;
