import React, { useMemo, useCallback } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { DateField, GridItem } from '~/modules/common/components';
import { getError, hasError } from '~/util';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const AddVendorForm = () => {
  const { handleChange, setFieldValue, values, errors } = useFormikContext();
  const { formatMessage } = useIntl();
  const { name, closedDate } = values;
  const nameError = getError(errors, 'name');

  const label = useMemo(
    () => ({
      name: formatMessage({ id: 'vendor.name' }),
      closedDate: formatMessage({ id: 'vendor.closedDate' })
    }),
    [formatMessage]
  );

  const closedDateChange = useCallback(
    event => {
      setFieldValue('closedDate', event);
    },
    [setFieldValue]
  );

  return (
    <>
      <Grid container>
        <GridItem fullWidth>
          <TextField
            name="name"
            required
            fullWidth
            variant="filled"
            label={label.name}
            inputProps={getAriaLabel(label.name)}
            value={name}
            onChange={handleChange}
            autoFocus
            error={Boolean(nameError)}
            helperText={nameError}
          />
        </GridItem>
        <GridItem fullWidth>
          <DateField
            name="closedDate"
            fullWidth
            variant="filled"
            editable
            clearable
            error={hasError(errors, 'closedDate')}
            helperText={getError(errors, 'closedDate')}
            label={label.closedDate}
            ariaLabel={label.closedDate}
            value={closedDate}
            onChange={closedDateChange}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default AddVendorForm;
