import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowDropDown } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import TaskCustomFields from '~/modules/common/components/TaskDrawer/EditTask/TaskCustomFields';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import {
  RoleDropdown,
  TaskOwnerDropDown,
  TimeAndExpenseEntryTypeDropdown
} from '~/modules/tasks/components';
import { getError, hasError } from '~/util';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import TaskResourceAllocations, {
  useChangeHandlers
} from '~/modules/common/components/TaskDrawer/TaskResourceAllocations';
import { isNullOrUndefined } from '~/modules/common/util';
import TaskDateFields from './TaskDateFields';
import TaskNameField from './TaskNameField';
import useStyles from './useStyles';
import TaskEstimationFields from './TaskEstimationFields';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

const dateProps = {
  InputProps: {
    endAdornment: (
      <InputAdornment position="end">
        <ArrowDropDown />
      </InputAdornment>
    )
  }
};

const getNumberOrNull = value => {
  const parsedValue = parseFloat(value);

  return isNaN(parsedValue) ? null : Math.max(0, parsedValue);
};

const getTotalAllocatedHours = resourceAllocations => {
  const filteredAllocations = resourceAllocations.filter(
    a => !isNullOrUndefined(a.allocatedHours)
  );

  return filteredAllocations.reduce(
    (acc, a) => (acc || 0) + a.allocatedHours,
    null
  );
};

export const AddTaskForm = ({
  me,
  projectSlug,
  readOnly,
  values,
  errors,
  onNameChange,
  onCodeChange,
  onStartDateChange,
  onEndDateChange,
  onTaskOwnerChange,
  onAssignedRoleChange,
  onDescriptionChange,
  onEstimatedHoursChange,
  onEstimatedCostAmountChange,
  onEstimatedCostCurrencyChange,
  onIsMilestoneChange,
  onTimeAndExpenseEntryTypeChange,
  fullScreen,
  customFieldDefinitions,
  isLoading,
  project,
  setFieldValue,
  isSubtask,
  parentUri
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('xs');
  const {
    featureFlags: {
      PSAPRPTaskEstimateCalculation,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrpTaskCodeOnTaskCreationEnabled
    }
  } = me;

  const isTaskAssignmentsEnabled =
    PSAPRPTaskEstimateCalculation ===
      TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT && !isMobile;

  const {
    name,
    code,
    startDate,
    endDate,
    assignedUser,
    assignedRole = null,
    description,
    estimatedHours,
    estimatedCost,
    isMilestone,
    isTimeEntryAllowed,
    timeAndExpenseEntryType,
    assignedUserRoleId,
    resourceAllocations,
    copyParentTaskResourceAllocations,
    ...customFields
  } = values;

  const {
    onResourceAllocationAdd,
    onResourceHoursChange,
    onInheritResourcesChange,
    onResourceAllocationRemove
  } = useChangeHandlers({
    setFieldValue,
    resourceAllocations
  });

  const requiredCustomFieldDefinitions = customFieldDefinitions.filter(
    definition => definition.isRequired
  );
  const { isExpenseProductEnabled, hasViewProjectBillingOptions } = me;

  const timeEntryLabel = isExpenseProductEnabled
    ? intl.formatMessage({
        id: 'projectTasksPage.timeAndExpenseEntryAllowed'
      })
    : intl.formatMessage({
        id: 'projectTasksPage.timeEntryAllowed'
      });

  const nameLabel = intl.formatMessage({ id: 'projectTasksPage.name' });
  const codeLabel = intl.formatMessage({ id: 'projectTasksPage.code' });
  const descriptionFieldProps = useMemo(
    () => ({
      'aria-label': intl.formatMessage({ id: 'projectTasksPage.description' })
    }),
    [intl]
  );
  const milestoneLabel = intl.formatMessage({
    id: 'projectTasksPage.milestone'
  });

  const { permittedActionUris, billingType, projectTemplateSetting } = project;

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const { hasBilling } = templateSettings || {};

  if (isLoading) {
    return (
      <div className={classes.container}>
        <CircularProgress className={classes.loadingContainer} size={24} />
      </div>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item>
        {isPsaPrpTaskCodeOnTaskCreationEnabled ? (
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            wrap={fullScreen ? 'wrap' : 'nowrap'}
          >
            <Grid item xs={12} sm={12}>
              <TaskNameField
                nameLabel={nameLabel}
                name={name}
                errors={errors}
                onNameChange={onNameChange}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="filled"
                label={codeLabel}
                error={hasError(errors, 'code')}
                helperText={getError(errors, 'code')}
                inputProps={getAriaLabel(codeLabel)}
                fullWidth
                value={code}
                onChange={onCodeChange}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        ) : (
          <TaskNameField
            nameLabel={nameLabel}
            name={name}
            errors={errors}
            onNameChange={onNameChange}
            readOnly={readOnly}
          />
        )}
      </Grid>
      <Grid item>
        <TaskDateFields
          variant="filled"
          fullScreen={fullScreen}
          startDate={startDate}
          errors={errors}
          endDate={endDate}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
        />
      </Grid>
      {!isTaskAssignmentsEnabled && (
        <Grid container item spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} data-qe-id="role">
            <RoleDropdown
              value={assignedRole}
              onChange={onAssignedRoleChange}
              disabled={readOnly}
              label={intl.formatMessage({
                id: 'projectTasksPage.role'
              })}
              projectSlug={projectSlug}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TaskOwnerDropDown
              variant="filled"
              assignedRole={assignedRole}
              projectSlug={projectSlug}
              assignedUser={assignedUser}
              onChange={onTaskOwnerChange}
              readOnly={readOnly}
              assignedUserRoleId={assignedUserRoleId}
            />
          </Grid>
        </Grid>
      )}
      {permittedActionUris.includes(
        'urn:replicon:project-action:edit-project-estimates'
      ) && (
        <Grid item>
          <TaskEstimationFields
            classes={classes}
            variant="filled"
            errors={errors}
            readOnly={readOnly}
            fullScreen={fullScreen}
            estimatedHours={estimatedHours}
            estimatedCost={estimatedCost}
            onEstimatedHoursChange={onEstimatedHoursChange}
            onEstimatedCostAmountChange={onEstimatedCostAmountChange}
            onEstimatedCostCurrencyChange={onEstimatedCostCurrencyChange}
            canEditCostData={permittedActionUris.includes(
              'urn:replicon:project-action:edit-cost-data'
            )}
          />
        </Grid>
      )}
      <Grid container item spacing={2} justifyContent="space-between">
        {isTaskAssignmentsEnabled && (
          <Grid item xs={12} sm={12} md={6} data-qe-id="role">
            <RoleDropdown
              value={assignedRole}
              onChange={onAssignedRoleChange}
              disabled={readOnly}
              label={intl.formatMessage({
                id: 'projectTasksPage.role'
              })}
              projectSlug={projectSlug}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <TimeAndExpenseEntryTypeDropdown
            fullWidth
            variant="filled"
            showBillingOptions={
              isPsaPrpPsaPpmMergerEnabled
                ? hasBilling && hasViewProjectBillingOptions
                : hasViewProjectBillingOptions
            }
            value={timeAndExpenseEntryType && timeAndExpenseEntryType.id}
            onChange={onTimeAndExpenseEntryTypeChange}
            label={timeEntryLabel}
            disabled={Boolean(
              billingType &&
                billingType.id === 'urn:replicon:billing-type:non-billable'
            )}
          />
        </Grid>
        {isPsaPrpTaskCodeOnTaskCreationEnabled && (
          <Grid item xs={12}>
            <TextField
              data-qe-id="TaskDescriptionField"
              label={intl.formatMessage({ id: 'projectTasksPage.description' })}
              inputProps={descriptionFieldProps}
              value={description}
              onChange={onDescriptionChange}
              variant="filled"
              error={hasError(errors, 'description')}
              helperText={getError(errors, 'description')}
              fullWidth
              multiline
              rows={1}
              rowsMax={10}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isMilestone}
                onChange={onIsMilestoneChange}
                color="primary"
                data-qe-id="milestone"
              />
            }
            label={milestoneLabel}
            labelPlacement="end"
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} justifyContent="space-between">
        <TaskCustomFields
          editable
          customFieldDefinitions={requiredCustomFieldDefinitions}
          values={customFields}
          errors={errors}
          dateProps={dateProps}
        />
      </Grid>
      {isTaskAssignmentsEnabled &&
        permittedActionUris.includes(
          'urn:replicon:project-action:edit-resource-task-allocations'
        ) && (
          <Grid item xs={12}>
            <TaskResourceAllocations
              assignedRole={assignedRole}
              copyParentTaskResourceAllocations={
                copyParentTaskResourceAllocations
              }
              editable
              errors={errors.resourceAllocations}
              initialEstimatedHours={getNumberOrNull(estimatedHours)}
              onInheritResourcesChange={onInheritResourcesChange}
              onResourceAllocationAdd={onResourceAllocationAdd}
              onResourceAllocationRemove={onResourceAllocationRemove}
              onResourceHoursChange={onResourceHoursChange}
              parentTaskId={isSubtask ? parentUri : null}
              projectSlug={projectSlug}
              resourceAllocationCount={resourceAllocations.length}
              resourceAllocations={resourceAllocations}
              totalAllocatedHours={getTotalAllocatedHours(resourceAllocations)}
            />
          </Grid>
        )}
    </Grid>
  );
};

AddTaskForm.propTypes = {
  readOnly: PropTypes.bool,
  values: PropTypes.object,
  me: PropTypes.object,
  errors: PropTypes.object,
  onNameChange: PropTypes.func,
  onCodeChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onTaskOwnerChange: PropTypes.func,
  onAssignedRoleChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  onEstimatedHoursChange: PropTypes.func,
  onEstimatedCostAmountChange: PropTypes.func,
  onEstimatedCostCurrencyChange: PropTypes.func,
  onIsMilestoneChange: PropTypes.func,
  onTimeAndExpenseEntryTypeChange: PropTypes.func,
  fullScreen: PropTypes.bool,
  customFieldDefinitions: PropTypes.array,
  projectSlug: PropTypes.string,
  isLoading: PropTypes.bool,
  project: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  isSubtask: PropTypes.bool,
  parentUri: PropTypes.string
};

export default AddTaskForm;
