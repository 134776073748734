import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Date from '~/modules/common/components/Date';

const useStyles = makeStyles(theme => ({
  effectiveDate: {
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

const DateReadOnly = ({ value }) => {
  const classes = useStyles();

  return (
    <Date
      component="Typography"
      value={value}
      tabIndex={0}
      className={classes.effectiveDate}
    />
  );
};

DateReadOnly.propTypes = {
  value: PropTypes.string
};

export default DateReadOnly;
