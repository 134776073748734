import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useProjectRequestFilter } from '~/modules/project-request/ProjectRequestPage/hooks';
import { useSessionStorage } from '~/modules/common/hooks';
import { isNullOrUndefined } from '~/modules/common/util';
import { GET_PROJECT_REQUEST_TOTALS_QUERY_VARIABLES_KEY } from '~/modules/project-request/ProjectRequestPage/hooks/constants';

export const GET_PROJECT_REQUEST_TOTALS_QUERY = gql`
  query GetProjectRequestTotals(
    $columns: [ProjectRequestColumn]
    $filter: ProjectRequestFilter
  ) {
    projectRequestTotals(columns: $columns, filter: $filter) {
      resourceAssignmentsCount
      estimatedCostInBaseCurrency {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      estimatedCostInPortfolioCurrency
    }
  }
`;

export const useProjectRequestTotals = ({
  columns,
  searchCriteria,
  summaryFilterState,
  excludePortfolio,
  portfolioCurrency
}) => {
  const { projectRequestFilter } = useProjectRequestFilter({
    searchCriteria,
    quickFilter: summaryFilterState?.quickFilter,
    excludePortfolio
  });

  const { setValue: setVariables } = useSessionStorage(
    GET_PROJECT_REQUEST_TOTALS_QUERY_VARIABLES_KEY,
    null
  );

  const variables = {
    columns,
    filter: projectRequestFilter
  };

  const { data, loading } = useQuery(GET_PROJECT_REQUEST_TOTALS_QUERY, {
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: () => {
      setVariables(variables);
    }
  });
  const totals = data?.projectRequestTotals || null;

  return {
    totals: {
      ...(totals || {}),
      estimatedCostInPortfolioCurrency: !isNullOrUndefined(
        totals?.estimatedCostInPortfolioCurrency
      )
        ? {
            amount: totals.estimatedCostInPortfolioCurrency,
            currency: portfolioCurrency
          }
        : null
    },
    isTotalsLoading: loading
  };
};

export default useProjectRequestTotals;
