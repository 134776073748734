import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { TASK_STATUS } from '~/modules/common/enums';

export const TASK_OWNED_BY_RESOURCES_QUERY = gql`
  query GetTaskOwnedByResources(
    $projectId: String!
    $includeClosedTasks: Boolean
    $filter: GetResourceUsersFilter!
  ) {
    resourceUsers2(filter: $filter) {
      id
      displayText
      assignedTasks(
        projectId: $projectId
        includeClosedTasks: $includeClosedTasks
      ) {
        id
        endDate
        startDate
        totalActualHours
        assignedRoleUri
      }
      allocationCount(projectId: $projectId)
    }
  }
`;

export const useTaskOwnedByResources = ({
  projectId,
  resourceIds,
  includeClosedTasks = false,
  skip = false
}) => {
  const { data, loading } = useQuery(TASK_OWNED_BY_RESOURCES_QUERY, {
    variables: {
      projectId,
      filter: {
        userIds: resourceIds
      },
      includeClosedTasks
    },
    skip,
    fetchPolicy: 'network-only'
  });

  const resourceUsers = get(data, 'resourceUsers2', []);

  return {
    resources: resourceUsers.map(user => {
      return {
        ...user,
        assignedTasks: (user.assignedTasks || []).map(assignedTask => {
          const { totalActualHours } = assignedTask;

          return {
            ...assignedTask,
            status:
              (totalActualHours || 0) > 0
                ? TASK_STATUS.INPROGRESS
                : TASK_STATUS.NOTSTARTED
          };
        })
      };
    }),
    isLoading: loading
  };
};

export default useTaskOwnedByResources;
