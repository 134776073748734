import React, { useState, useMemo } from 'react';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const mapOptions = programs =>
  programs.map(({ displayText, id }) => ({ key: displayText, value: id }));

export const matchOption = searchPrograms => async (_, term) => {
  if (!term) {
    return null;
  }

  const programs = await searchPrograms(cleanSearchTerm(term));

  const option = programs.shift();

  if (option) {
    return {
      ...option,
      key: option.displayText
    };
  }

  return null;
};

export const withCriteriaProvider = (
  tag,
  context,
  useSearchableProgramsWithHasMore
) => BaseComponent => props => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const [hasMore, setHasMore] = useState(false);
  const { searchPrograms } = useSearchableProgramsWithHasMore();

  const $searchPrograms = searchPrograms(setHasMore);

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption($searchPrograms),
      selected,
      setSelected,
      searchPrograms: $searchPrograms,
      hasMore,
      setHasMore,
      suggestions: $searchPrograms
    }),
    [$searchPrograms, selected, hasMore, setHasMore]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};
