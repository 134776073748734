import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FlatOverviewItemExpansionPanel } from '~/modules/common/components/OverviewItemExpansionPanel';

const useStyles = makeStyles(theme => ({
  expansionPanelSummaryContent: {
    padding: theme.spacing(1, 0, 1, 1)
  }
}));

const ProjectsFlatOverviewItemExpansionPanel = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <FlatOverviewItemExpansionPanel classes={classes} {...props}>
      {children}
    </FlatOverviewItemExpansionPanel>
  );
};

ProjectsFlatOverviewItemExpansionPanel.propTypes = {
  children: PropTypes.node
};

export default ProjectsFlatOverviewItemExpansionPanel;
