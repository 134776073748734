import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import getFormatters from './formatters';

export const buildFooters = ({
  classes,
  isAllocatedBillAmountColumnVisible
}) => ({
  displayText: {
    id: 'displayText',
    visible: true,
    align: 'right'
  },
  dueDate: {
    id: 'dueDate',
    visible: true,
    align: 'right'
  },
  balanceAmount: {
    id: 'balanceAmount',
    visible: isAllocatedBillAmountColumnVisible,
    align: 'right',
    flip: false
  },
  totalDisplayText: {
    id: 'totalDisplayText',
    align: 'right',
    value: <FormattedMessage id="outstandingBillsTable.total" />,
    visible: true,
    className: classes.totalDisplayText
  },
  amountTotal: {
    id: 'amountTotal',
    visible: true,
    align: 'right',
    flip: false,
    className: classes.totalAmount
  }
});

export const useListFooters = ({
  classes,
  editable,
  isAllocatedBillAmountColumnVisible
}) =>
  useColumns({
    columns: buildFooters({ classes, isAllocatedBillAmountColumnVisible }),
    renders: getFormatters(editable)
  });
