import { ResourceRequestStatus, ResourceRequestAccessLevel } from '~/types';
import { getResourcingPlansQuery } from '../../graphql';
import { RESOURCE_ALLOCATION_CHART_TAB_ENUM } from '../../hooks/useResourceAllocationChartTabStateContext';

const tryLoadQuery = (proxy, options) => {
  try {
    return proxy.readQuery(options);
  } catch (e) {
    return null;
  }
};

const mapRequestStatusToChartTab = status => {
  switch (status) {
    case ResourceRequestStatus.Allocationrejected:
    case ResourceRequestStatus.Submitted:
    case ResourceRequestStatus.Draft:
      return RESOURCE_ALLOCATION_CHART_TAB_ENUM.PENDING;
    case ResourceRequestStatus.Complete:
    case ResourceRequestStatus.Rejected:
      return RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL;
    case ResourceRequestStatus.Tentative:
      return RESOURCE_ALLOCATION_CHART_TAB_ENUM.PROPOSED;
    case ResourceRequestStatus.Tobehired:
      return RESOURCE_ALLOCATION_CHART_TAB_ENUM.TOBEHIRED;
    default:
      throw new Error('unknown status mate');
  }
};

export const updateResourcePlansCountForMovedToTab = ({
  proxy,
  cachedPlans,
  planStatus,
  plan,
  requestAccessLevel = ResourceRequestAccessLevel.All
}) => {
  const updatedCache = {
    ...cachedPlans,
    getResourcePlansForCurrentTenant: {
      ...cachedPlans.getResourcePlansForCurrentTenant,
      plans: cachedPlans.getResourcePlansForCurrentTenant.plans.map(p => ({
        ...p,
        count: p.planUri === plan.planUri ? p.count + 1 : p.count
      }))
    }
  };

  proxy.writeQuery({
    query: getResourcingPlansQuery,
    variables: {
      filter: {
        planStatus
      },
      requestAccessLevel,
      groupBy: 'PROJECT'
    },
    data: updatedCache
  });
};

export const updateResourcePlansForTenantCacheSingleRequest = ({
  proxy,
  existingResourceRequest,
  newRequestStatus,
  chartSelectedTab,
  requestAccessLevel = ResourceRequestAccessLevel.All
}) => {
  const cachedPlans = tryLoadQuery(proxy, {
    query: getResourcingPlansQuery,
    variables: {
      filter: {
        planStatus: chartSelectedTab
      },
      requestAccessLevel,
      groupBy: 'PROJECT'
    }
  });

  if (!cachedPlans) return;

  const planUri = existingResourceRequest.projectUri;

  const plan = cachedPlans.getResourcePlansForCurrentTenant.plans.find(
    p => p.planUri === planUri
  );

  if (!plan) return;

  const isLastRequest = plan.count === 1;

  if (chartSelectedTab !== RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL) {
    if (isLastRequest) {
      updateResourcePlansForTenantCache({
        existingResourceRequests: [existingResourceRequest],
        newRequestStatus,
        proxy,
        chartSelectedTab,
        requestAccessLevel
      });
    } else {
      proxy.writeQuery({
        query: getResourcingPlansQuery,
        variables: {
          filter: {
            planStatus: chartSelectedTab
          },
          requestAccessLevel,
          groupBy: 'PROJECT'
        },
        data: {
          ...cachedPlans,
          getResourcePlansForCurrentTenant: {
            ...cachedPlans.getResourcePlansForCurrentTenant,
            plans: cachedPlans.getResourcePlansForCurrentTenant.plans.map(
              p => ({
                ...p,
                count: p.planUri === planUri ? p.count - 1 : p.count
              })
            )
          }
        }
      });
    }
  }

  const otherTab = mapRequestStatusToChartTab(newRequestStatus);

  if (otherTab === RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL) return;

  const cachedPlansOtherTab = tryLoadQuery(proxy, {
    query: getResourcingPlansQuery,
    variables: {
      filter: {
        planStatus: otherTab
      },
      requestAccessLevel,
      groupBy: 'PROJECT'
    }
  });

  if (!cachedPlansOtherTab) return;

  updateResourcePlansCountForMovedToTab({
    proxy,
    cachedPlans: cachedPlansOtherTab,
    planStatus: otherTab,
    plan,
    requestAccessLevel
  });
};

export const updateResourcePlansCacheForSelectedTab = ({
  proxy,
  cachedPlans,
  planStatus,
  plan,
  moveCount,
  requestAccessLevel
}) => {
  const removePlan = plan.count === moveCount;

  const updatedPlans = removePlan
    ? cachedPlans.getResourcePlansForCurrentTenant.plans.filter(
        p => p.planUri !== plan.planUri
      )
    : cachedPlans.getResourcePlansForCurrentTenant.plans.map(p => ({
        ...p,
        count: p.planUri === plan.planUri ? p.count - moveCount : p.count
      }));

  const updatedCache = {
    ...cachedPlans,
    getResourcePlansForCurrentTenant: {
      ...cachedPlans.getResourcePlansForCurrentTenant,
      plans: updatedPlans
    }
  };

  proxy.writeQuery({
    query: getResourcingPlansQuery,
    variables: {
      filter: {
        planStatus
      },
      requestAccessLevel,
      groupBy: 'PROJECT'
    },
    data: updatedCache
  });
};

export const updateResourcePlansCacheForMovedToTab = ({
  proxy,
  cachedPlans,
  planStatus,
  plan,
  requestAccessLevel = ResourceRequestAccessLevel.All
}) => {
  const existingPlan = cachedPlans.getResourcePlansForCurrentTenant.plans.find(
    p => p.planUri === plan.planUri
  );

  const updatedPlans = existingPlan
    ? cachedPlans.getResourcePlansForCurrentTenant.plans.map(p => ({
        ...p,
        count: p.planUri === plan.planUri ? p.count + plan.count : p.count
      }))
    : [...cachedPlans.getResourcePlansForCurrentTenant.plans, plan];

  const updatedCache = {
    ...cachedPlans,
    getResourcePlansForCurrentTenant: {
      ...cachedPlans.getResourcePlansForCurrentTenant,
      plans: updatedPlans
    }
  };

  proxy.writeQuery({
    query: getResourcingPlansQuery,
    variables: {
      filter: {
        planStatus
      },
      requestAccessLevel,
      groupBy: 'PROJECT'
    },
    data: updatedCache
  });
};

export const updateResourcePlansForTenantCache = ({
  proxy,
  existingResourceRequests,
  newRequestStatus,
  chartSelectedTab,
  requestAccessLevel = ResourceRequestAccessLevel.All
}) => {
  const planUri = existingResourceRequests[0].projectUri;

  const cachedPlans = tryLoadQuery(proxy, {
    query: getResourcingPlansQuery,
    variables: {
      filter: {
        planStatus: chartSelectedTab
      },
      requestAccessLevel,
      groupBy: 'PROJECT'
    }
  });

  if (!cachedPlans) return;

  const plan = cachedPlans.getResourcePlansForCurrentTenant.plans.find(
    p => p.planUri === planUri
  );

  if (chartSelectedTab !== RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL) {
    updateResourcePlansCacheForSelectedTab({
      proxy,
      cachedPlans,
      planStatus: chartSelectedTab,
      plan,
      moveCount: existingResourceRequests.length,
      requestAccessLevel
    });
  }

  const otherTab = mapRequestStatusToChartTab(newRequestStatus);

  if (otherTab === RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL) return;

  const cachedPlansOtherTab = tryLoadQuery(proxy, {
    query: getResourcingPlansQuery,
    variables: {
      filter: {
        planStatus: otherTab
      },
      requestAccessLevel,
      groupBy: 'PROJECT'
    }
  });

  if (!cachedPlansOtherTab) return;

  updateResourcePlansCacheForMovedToTab({
    proxy,
    cachedPlans: cachedPlansOtherTab,
    planStatus: otherTab,
    plan,
    requestAccessLevel
  });
};
