import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cell: {
    padding: theme.spacing(0.5, 1)
  }
}));

export const CellRenderer = ({
  index,
  field,
  column,
  record,
  component: Component
}) => <Component index={index} field={field} column={column} record={record} />;

CellRenderer.propTypes = {
  index: PropTypes.number,
  field: PropTypes.string,
  column: PropTypes.object,
  record: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export const RowRenderer = ({ record, columns, rowIndex }) => {
  const classes = useStyles();
  const hasDeleteRowButton = useMemo(() => Boolean(columns.deleteRow), [
    columns.deleteRow
  ]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={hasDeleteRowButton ? 11 : 12} container spacing={0}>
        {Object.keys(columns).map(field => {
          if (field === 'deleteRow') return null;

          return (
            <Grid
              key={`${record.key || record.id || record.scriptId}:${field}`}
              item
              xs={12}
              sm={6}
              className={classes.cell}
            >
              <CellRenderer
                component={columns[field].render}
                field={field}
                column={columns[field]}
                record={record}
                index={rowIndex}
              />
            </Grid>
          );
        })}
      </Grid>
      {hasDeleteRowButton ? (
        <Grid item xs={1} container alignItems="flex-start" spacing={0}>
          <CellRenderer
            component={columns.deleteRow.render}
            field="deleteRow"
            column={columns.deleteRow}
            record={record}
            index={rowIndex}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

RowRenderer.propTypes = {
  record: PropTypes.any,
  columns: PropTypes.any,
  rowIndex: PropTypes.number
};

export default RowRenderer;
