import { useSearchableResourcePools } from '~/modules/common/hooks/useResourcePools';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { resourcePoolTag } from './tag';
import ResourcePoolFacetDetails from './ResourcePoolFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableResourcePools);

export default {
  tag: resourcePoolTag,
  makeCriteriaProvider,
  DetailsControl: ResourcePoolFacetDetails
};
