import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bottomHeader: {
    top: theme.spacing(19),
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(13)
    }
  },
  name: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    minWidth: 180,
    maxWidth: 200
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.table.border}`
  }
}));

export default useStyles;
