import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';

export const useValueWithPrecision = ({ value }) => {
  const me = useMeContext();

  const numberSettings = getReactNumberFormatFromMe(me);

  return parseFloat((value || 0).toFixed(numberSettings.decimalScale || 2));
};
