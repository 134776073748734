import { useCallback } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { RESOURCE_MANAGEMENT_TAB_ENUM } from '~/modules/resource-management/common';

export const getStringifiedQueryParams = ({
  view,
  role,
  location,
  division,
  serviceCenter,
  costCenter,
  department,
  employeeType,
  label,
  skill
}) => {
  const stringified = queryString.stringify({
    view,
    role,
    location,
    division,
    serviceCenter,
    costCenter,
    department,
    employeeType,
    label,
    skill
  });

  return `/resource-pool?${stringified}`;
};
export const useRedirectToDetails = () => {
  const history = useHistory();

  const isMobile = useIsBreakpointDown('sm');

  return useCallback(
    params => {
      const view = isMobile
        ? RESOURCE_MANAGEMENT_TAB_ENUM.LIST
        : RESOURCE_MANAGEMENT_TAB_ENUM.TIMELINE;

      const stringified = getStringifiedQueryParams({ ...params, view });

      history.push(stringified);
    },
    [history, isMobile]
  );
};

export default useRedirectToDetails;
