import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateTime as LuxonDateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';

import { useMeContext } from '~/modules/me/useMeContext';
import NoValue from '../NoValue';
import { getLuxonJsDateTimeFormatFromMe } from '../../dates/convert';

const useStyles = makeStyles(() => ({
  date: {
    whiteSpace: 'nowrap'
  }
}));

const resolveDateTime = dateTime =>
  dateTime &&
  (typeof dateTime === 'string'
    ? LuxonDateTime.fromISO(dateTime)
    : LuxonDateTime.isDateTime(dateTime)
    ? dateTime.toLocal()
    : LuxonDateTime.local(
        dateTime.year,
        dateTime.month,
        dateTime.day,
        dateTime.hour,
        dateTime.minute,
        dateTime.second,
        dateTime.millisecond
      ));

export const DateTime = ({
  value,
  className,
  format,
  component = 'Typography',
  ...restProps
}) => {
  const me = useMeContext();
  const dateTime = resolveDateTime(value);
  const classes = useStyles();
  const luxonFormat = format || getLuxonJsDateTimeFormatFromMe(me);

  return component === 'Typography' ? (
    <Typography
      variant="body2"
      className={classNames(classes.date, className)}
      {...restProps}
    >
      {dateTime ? dateTime.toFormat(luxonFormat) : <NoValue />}
    </Typography>
  ) : (
    <TextField
      disabled
      value={dateTime ? dateTime.toFormat(luxonFormat) : ''}
      fullWidth
      {...restProps}
    />
  );
};

DateTime.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  component: PropTypes.oneOf(['Typography', 'TextField']),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  format: PropTypes.string,
  me: PropTypes.object
};

export default memo(DateTime);
