import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 4, 1, 2),
    color: theme.palette.text.primary
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export const PageHeaderLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Skeleton width="50%" height={40} />
        <Skeleton width="10%" height={40} />
      </div>
      <Skeleton width="30%" height={40} />
    </div>
  );
};

export default PageHeaderLoading;
