export * from './form';
export * from './functional';
export * from './graphql';
export * from './uri';
export * from './react';
export * from './date';

export const isMobileApp = () => window.isMobileApp || false;

export const retryLoading = (fn, retriesLeft = 5, interval = 1000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);

            return;
          }

          retryLoading(fn, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
