import { useColumns } from '~/modules/common/components/ListTable';
import { getFormatters } from '../renderers';
import { buildColumns } from './columns';

export const useListColumns = props => {
  return useColumns({
    columns: buildColumns(props),
    renders: getFormatters(props)
  });
};
