import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

import { getFirstErrorCode } from '~/modules/common/graphql/errors';

export const resourceRequestsCountSummaryQuery = gql`
  query GetResourceRequestsCountFilteredByProjects(
    $projectFilter: ProjectFilter
  ) {
    resourceRequestsSummaryForProjectManager(projectFilter: $projectFilter) {
      proposed
      rejected
      draft
    }
  }
`;

export const useResourceRequestsCountSummaryQuery = ({
  projectFilter,
  intl
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  const { data, loading, error } = useQuery(resourceRequestsCountSummaryQuery, {
    variables: {
      projectFilter
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (error) {
      setErrorMessage(
        intl.formatMessage({
          id:
            getFirstErrorCode(error) === 'MAX_LIMIT_EXCEEDED_ERROR'
              ? 'projectOverview.maxLimitError'
              : 'projectOverview.unknownServiceError'
        })
      );
      setErrorCode(getFirstErrorCode(error));
    } else {
      setErrorMessage(null);
      setErrorCode(null);
    }
  }, [error, intl]);

  const resourceRequestsCountSummary = get(
    data,
    'resourceRequestsSummaryForProjectManager',
    {
      proposed: 0,
      rejected: 0,
      draft: 0
    }
  );

  return {
    loading,
    errorMessage,
    errorCode,
    resourceRequestsCountSummary: {
      ...resourceRequestsCountSummary,
      total:
        resourceRequestsCountSummary.proposed +
        resourceRequestsCountSummary.rejected +
        resourceRequestsCountSummary.draft
    }
  };
};

export default useResourceRequestsCountSummaryQuery;
