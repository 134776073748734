import { useMemo, useCallback, useState } from 'react';

export default ({ openDialog }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const chartBarClickHandler = useCallback(
    index => {
      setSelectedIndex(index);
      openDialog();
    },
    [openDialog]
  );
  const chartClickHandler = useMemo(
    () => [
      {
        childName: ['stackBar', 'bar'],
        eventHandlers: {
          onClick: (_, { index }) => {
            chartBarClickHandler(index);
          }
        }
      }
    ],
    [chartBarClickHandler]
  );

  return {
    selectedIndex,
    chartClickHandler
  };
};
