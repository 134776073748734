import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const GET_ALL_ACTIVE_INVOICE_TEMPLATE = gql`
  query getAllActiveInvoiceTemplates {
    activeInvoiceTemplates {
      id
      displayText
    }
  }
`;

export const useInvoiceTemplates = () => {
  const { data, loading } = useQuery(GET_ALL_ACTIVE_INVOICE_TEMPLATE, {
    fetchPolicy: 'cache-and-network',
    context: {
      debounceKey: 'invoice-templates',
      debounceTimeout: 250
    }
  });

  return {
    invoiceTemplates: get(data, 'activeInvoiceTemplates', []),
    loading
  };
};

export default useInvoiceTemplates;
