import React, { useCallback, useMemo } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { v4 } from 'uuid';
import ScriptParamsEditorTable from './ScriptParamsEditorTable';
import { useSetSaveable } from './hooks';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    padding: theme.spacing(1)
  }
}));

export const ScriptParamsEditor = ({
  editable,
  values: {
    parameters,
    displayText,
    estimatedAmount,
    scripts,
    hasHeaderComponent,
    headerComponentProps,
    footerProps
  },
  customEditors,
  customFormatters,
  errors,
  allowAddScriptParam,
  setFieldValue,
  classes: classesOverride,
  HeaderComponent,
  readOnlyParameters,
  onDeleteRow,
  isMobile,
  slug,
  setSaveable,
  showScriptParamsEditorTable = true,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const onDeleteRowHandler = useCallback(
    record => {
      onDeleteRow && onDeleteRow({ record, setFieldValue, footerProps });
      setFieldValue(
        'scripts',
        scripts.filter(value => value.scriptId !== record.scriptId)
      );
    },
    [setFieldValue, scripts, onDeleteRow, footerProps]
  );

  const updatedParameters = editable
    ? parameters.filter(param => !readOnlyParameters.includes(param.id))
    : parameters;

  const onAddRowHandler = useCallback(
    record => {
      const newScriptParam = updatedParameters.reduce(
        (retVal, current) => ({
          ...retVal,
          scriptId: v4(),
          [current.id]: current.defaultValue
        }),
        {}
      );

      setFieldValue('scripts', [...scripts, newScriptParam]);
    },
    [updatedParameters, setFieldValue, scripts]
  );

  const displayTextValue = useMemo(
    () => ({
      add: { script: displayText }
    }),
    [displayText]
  );

  const { showAddButton } = useSetSaveable({
    setSaveable,
    billPlanSlug: slug,
    editable,
    showScriptParamsEditorTable
  });

  return (
    <>
      {showScriptParamsEditorTable && Boolean(HeaderComponent) && (
        <HeaderComponent
          setFieldValue={setFieldValue}
          scripts={scripts}
          headerComponentProps={headerComponentProps}
          errors={errors}
          isMobile={isMobile}
        />
      )}
      {showScriptParamsEditorTable && (
        <ScriptParamsEditorTable
          editable={editable}
          parameters={updatedParameters}
          totalEstimatedAmount={estimatedAmount}
          headerComponentProps={headerComponentProps}
          hasHeaderComponent={hasHeaderComponent}
          footerProps={footerProps}
          scripts={scripts}
          setFieldValue={setFieldValue}
          customEditors={customEditors}
          customFormatters={customFormatters}
          errors={errors}
          onDeleteRow={onDeleteRowHandler}
          isMobile={isMobile}
          {...rest}
        />
      )}
      {showAddButton && (
        <>
          {allowAddScriptParam && (
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                onClick={onAddRowHandler}
                data-qe-id="add"
              >
                <FormattedMessage
                  id="scriptParamEditor.addScriptParams"
                  values={displayTextValue.add}
                />
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

ScriptParamsEditor.propTypes = {
  editable: PropTypes.bool,
  scriptType: PropTypes.string,
  values: PropTypes.object.isRequired,
  allowAddScriptParam: PropTypes.bool,
  setFieldValue: PropTypes.func,
  customEditors: PropTypes.object,
  customFormatters: PropTypes.object,
  classes: PropTypes.object,
  errors: PropTypes.object,
  HeaderComponent: PropTypes.func,
  readOnlyParameters: PropTypes.array,
  onDeleteRow: PropTypes.func,
  isMobile: PropTypes.bool,
  canViewParams: PropTypes.bool,
  canEditParams: PropTypes.bool,
  slug: PropTypes.string,
  setSaveable: PropTypes.func,
  showScriptParamsEditorTable: PropTypes.bool
};

export default ScriptParamsEditor;
