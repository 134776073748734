import React from 'react';
import PropTypes from 'prop-types';
import Decimal, { FormattedDecimal } from '~/modules/common/components/Decimal';

export const HoursValue = ({
  className,
  value,
  numberFormat,
  fixedDecimalScale = true,
  isPsaPpmCostEacEnhancements2Enabled
}) => {
  return isPsaPpmCostEacEnhancements2Enabled ? (
    <FormattedDecimal
      className={className}
      value={value}
      fixedDecimalScale={fixedDecimalScale}
      numberFormat={numberFormat}
    />
  ) : (
    <Decimal
      className={className}
      value={value}
      fixedDecimalScale={fixedDecimalScale}
    />
  );
};

HoursValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  numberFormat: PropTypes.object,
  fixedDecimalScale: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool
};

export default HoursValue;
