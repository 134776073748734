import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';

export const ExpenseCodeMenu = ({
  open,
  onClose,
  anchorEl,
  onMenuItemClick,
  availableExpenseCodes
}) => {
  const onExpenseCodeClick = useCallback(
    code => event => {
      if (onMenuItemClick && typeof onMenuItemClick === 'function') {
        onMenuItemClick(code);
      }
      onClose();
    },
    [onClose, onMenuItemClick]
  );

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {availableExpenseCodes.map(code => (
        <MenuItem key={code.id} onClick={onExpenseCodeClick(code)}>
          {code.displayText}
        </MenuItem>
      ))}
    </Menu>
  );
};

ExpenseCodeMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.element,
  onMenuItemClick: PropTypes.func,
  availableExpenseCodes: PropTypes.array
};

export default ExpenseCodeMenu;
