import { Formik } from 'formik';
import { PropTypes } from 'prop-types';
import React from 'react';
import AddPortfolioDialogContent from './AddPortfolioDialogContent';
import { useAddPortfolio } from './hooks';

export const AddPortfolioDialog = ({
  open,
  onClose,
  parentId,
  parentName,
  putMutationProps
}) => {
  const { initialState, onSubmit, validationSchema } = useAddPortfolio({
    parentId,
    parentName,
    onClose,
    putMutationProps
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <AddPortfolioDialogContent
        onClose={onClose}
        open={open}
        parentName={parentName}
      />
    </Formik>
  );
};

AddPortfolioDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  parentName: PropTypes.string,
  putMutationProps: PropTypes.shape({
    doCacheUpdate: PropTypes.bool,
    refetchQueries: PropTypes.array
  })
};

export default AddPortfolioDialog;
