import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    backgroundColor: theme.palette.common.white
  },
  hoursFieldInput: {
    textAlign: 'right',
    padding: theme.spacing(0.5, 0),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    margin: theme.spacing(0.5, 0),
    height: theme.spacing(1)
  },
  details: {
    padding: theme.spacing(0, 0.25),
    textAlign: 'right',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  inputAdornment: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 0.5)
  },
  value: {
    marginLeft: theme.spacing(1)
  },
  overAllocated: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  },
  detailsContainer: {
    width: 'auto'
  },
  detailsLabel: {
    padding: '0px'
  },
  hr: {
    margin: 0
  },
  rootToBeHired: {
    backgroundColor: theme.palette.resourceRequest.toBeHired.main
  },
  rootRejected: {
    backgroundColor: theme.palette.resourceRequest.requestRejected.main
  }
}));

export default useStyles;
