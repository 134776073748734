import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  makeStyles,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Date, BatchStatus } from '~/modules/common/components';
import { isoStringToObjectWithCache as isoStringToObject } from '~/modules/common/dates/convert';
import { useDeleteSettings } from '../../Schedule/ScheduleMonthDetails/components/MonthDetails/hooks';

export const InProgress = ({ classes }) => {
  return (
    <>
      <CircularProgress size={15} />
      <Typography variant="caption" className={classes.progressMessage}>
        <FormattedMessage id="revenueRunSchedule.calculating" />
      </Typography>
    </>
  );
};
InProgress.propTypes = {
  classes: PropTypes.object
};

const useStyles = makeStyles(theme => ({
  label: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  container: {
    display: 'inline-flex',
    width: 'fit-content'
  },
  asOfDate: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize,
    display: 'flex'
  },
  progressMessage: {
    paddingLeft: theme.spacing(0.5)
  }
}));

const dateStyles = makeStyles(theme => ({
  date: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0, 0.5)
  }
}));

const statusMap = {
  'urn:replicon:revenue-run-status:completed': false,
  'urn:replicon:revenue-run-status:draft': true
};

export const RevenueRunStatus = ({
  status,
  asOfDate,
  inProgressBatchUri,
  revenueRunUri
}) => {
  const classes = useStyles();
  const dateClasses = dateStyles();
  const [previousBatchInProgress, setPreviousBatchInProgress] = useState(true);
  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });
  const isOpen = statusMap[status.id];
  const { label, color, chipClasses } = useMemo(() => {
    return {
      label: (
        <FormattedMessage
          id={`revenueRunSchedule.${isOpen ? 'open' : 'closed'}`}
        ></FormattedMessage>
      ),
      color: isOpen ? 'primary' : 'default',
      chipClasses: { label: classes.label }
    };
  }, [classes.label, isOpen]);

  const { deleteRevenueRunBatchUri } = useDeleteSettings(revenueRunUri);

  const onBatchComplete = useCallback(async () => {
    setPreviousBatchInProgress(false);
    await deleteRevenueRunBatchUri();
  }, [deleteRevenueRunBatchUri]);

  useEffect(() => {
    if (
      inProgressBatchUri &&
      !batchState.batchInProgress &&
      previousBatchInProgress
    ) {
      setBatchState({
        batchId: inProgressBatchUri,
        batchInProgress: true
      });
    }
  }, [inProgressBatchUri, batchState, previousBatchInProgress]);

  return (
    <Grid
      container
      direction="column"
      spacing={0}
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={12}>
        <Chip label={label} color={color} classes={chipClasses} />
      </Grid>
      {batchState.batchInProgress ? (
        <BatchStatus
          batchState={batchState}
          setBatchState={setBatchState}
          onBatchComplete={onBatchComplete}
        >
          <Grid item xs={12} className={classes.asOfDate}>
            <InProgress classes={classes} />
          </Grid>
        </BatchStatus>
      ) : (
        <Grid item xs={12} className={classes.asOfDate}>
          {asOfDate ? (
            <>
              {isOpen ? (
                <FormattedMessage
                  id="revenueRunSchedule.asOfDate"
                  values={{
                    date: (
                      <Date
                        value={isoStringToObject(asOfDate)}
                        classes={dateClasses}
                      />
                    )
                  }}
                />
              ) : (
                <Date
                  value={isoStringToObject(asOfDate)}
                  classes={dateClasses}
                />
              )}
            </>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

RevenueRunStatus.propTypes = {
  status: PropTypes.object,
  asOfDate: PropTypes.any,
  inProgressBatchUri: PropTypes.string,
  revenueRunUri: PropTypes.string
};

export default RevenueRunStatus;
