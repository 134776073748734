import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getStatusBasedClassName } from '~/modules/resourcing/common/enums';
import { ResourceRequestStatus } from '~/types';
import useStyles from './useStyles';

export const getStatusBasedMessageKey = status => {
  const statusMessageKeys = {
    [ResourceRequestStatus.Complete]: 'resourceRequestItemStatusField.complete',
    [ResourceRequestStatus.Tentative]:
      'resourceRequestItemStatusField.proposed',
    [ResourceRequestStatus.Tobehired]:
      'resourceRequestItemStatusField.toBeHired',
    [ResourceRequestStatus.Rejected]: 'resourceRequestItemStatusField.rejected',
    [ResourceRequestStatus.Allocationrejected]:
      'resourceRequestItemStatusField.allocationRejected',
    [ResourceRequestStatus.Submitted]: 'resourceRequestItemStatusField.pending',
    default: 'resourceRequestItemStatusField.draft'
  };

  return statusMessageKeys[status] || statusMessageKeys.default;
};

export const ResourceRequestStatusIndicator = memo(
  ({ qeIdTag, classes: classesOverride, className, status }) => {
    const classes = useStyles({ classes: classesOverride });

    return (
      <Tooltip
        title={useMemo(
          () => (
            <FormattedMessage id={getStatusBasedMessageKey(status)} />
          ),
          [status]
        )}
      >
        <div
          data-qe-id={
            qeIdTag ? `${qeIdTag}_StatusRowToolTip` : 'StatusRowToolTip'
          }
          className={classNames(
            classes.root,
            className,
            classes[getStatusBasedClassName(status, 'root')]
          )}
        />
      </Tooltip>
    );
  }
);

ResourceRequestStatusIndicator.propTypes = {
  qeIdTag: PropTypes.string,
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  classes: PropTypes.object
};

export default ResourceRequestStatusIndicator;
