import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { ChartLegends } from '~/modules/common/charts/dashboard';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const labels = {
  notStarted: <FormattedMessage id="taskStatusBadge.notStarted" />,
  inProgress: <FormattedMessage id="taskStatusBadge.inProgress" />,
  completed: <FormattedMessage id="taskStatusBadge.completed" />,
  parentTask: <FormattedMessage id="taskStatusBadge.parentTask" />
};

const useStyles = makeStyles(() => ({
  legendText: {
    fontSize: theme.typography.caption.fontSize
  }
}));

const legends = [
  {
    label: labels.notStarted,
    color: theme.palette.taskStatus.NOTSTARTED.color,
    labelColor: theme.palette.common.black
  },
  {
    label: labels.inProgress,
    color: theme.palette.taskStatus.INPROGRESS.color,
    labelColor: theme.palette.common.black
  },
  {
    label: labels.completed,
    color: theme.palette.taskStatus.COMPLETED.color,
    labelColor: theme.palette.common.black
  }
];

const rolledUpLegends = [
  {
    label: labels.notStarted,
    color: theme.palette.taskStatus.NOTSTARTED.color,
    labelColor: theme.palette.common.black
  },
  {
    label: labels.inProgress,
    color: theme.palette.taskStatus.INPROGRESS.color,
    labelColor: theme.palette.common.black,
    custom: {
      display: 'flex',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      alignSelf: 'center',
      border: `1px solid ${theme.palette.taskStatus.INPROGRESS.color}`,
      background: `linear-gradient(90deg, ${theme.palette.taskStatus.INPROGRESS.color} 50%,  ${theme.palette.taskStatus.INPROGRESS.lightColor} 50%)`
    }
  },
  {
    label: labels.completed,
    color: theme.palette.taskStatus.COMPLETED.color,
    labelColor: theme.palette.common.black
  },
  {
    label: labels.parentTask,
    color: '#ffffff',
    labelColor: theme.palette.common.black,
    custom: {
      display: 'flex',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      alignSelf: 'center',
      borderLeft: '2px solid #656565',
      borderRight: '2px solid #656565',
      background:
        'linear-gradient(180deg, transparent 40%,  #656565 40%, #656565 60%, transparent 60%)'
    }
  }
];

export const TaskStatusLegends = () => {
  const classes = useStyles();
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();

  if (isRolledUpTaskEstimateCalculationMethodEnabled) {
    return <ChartLegends legends={rolledUpLegends} classes={classes} />;
  }

  return <ChartLegends legends={legends} classes={classes} />;
};

TaskStatusLegends.propTypes = {};

export default TaskStatusLegends;
