import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  itemWidth,
  actionsWidth
} from '~/modules/common/charts/timeline/calculations';
import {
  usePopperHandlers,
  ResourceChartTotalPopper
} from '~/modules/resourcing/components/ResourceChartTotalPopper';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import ResourceRequestChartFooterRow from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestChartFooterRow';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import TotalsFooterPopoverCell from '../TotalsFooterPopoverCell/TotalsFooterPopoverCell';

const useGrandTotalStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'unset',
    borderBottom: 'none'
  },
  totalLabel: {
    borderRight: 'none',
    fontWeight: theme.typography.fontWeightBold
  }
}));

const leftComponentWidth = actionsWidth + itemWidth * 2;

export const ResourcePlanGrandTotal = ({
  allocationsTotals,
  resourceRequestsTotals,
  loading
}) => {
  const { project } = useProjectContext();
  const grandTotalClasses = useGrandTotalStyles();
  const { scale, chartDates } = useResourceRequestToolbarContext();

  const canViewCostData = project.permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  const {
    anchorEl,
    onMenuClose,
    onMenuClick,
    handleKeyDown
  } = usePopperHandlers();

  return (
    <ResourceRequestChartFooterRow
      classes={grandTotalClasses}
      scale={scale}
      scaleItemCount={chartDates.length}
      totalLabel="resourceRequestChartFooter.grandTotal"
      leftComponentWidth={leftComponentWidth}
    >
      <TotalsFooterPopoverCell
        allocationsTotals={allocationsTotals}
        resourceRequestsTotals={resourceRequestsTotals}
        handleClick={onMenuClick}
        handleKeyDown={handleKeyDown}
        onClickAway={onMenuClose}
        canViewCostData={canViewCostData}
        loading={loading}
      >
        <ResourceChartTotalPopper
          id="resource-grand-total-popper"
          anchorEl={anchorEl}
          onClose={onMenuClose}
          requestedHours={resourceRequestsTotals.totalHours}
          requestedCost={resourceRequestsTotals.totalCost}
          allocatedHours={allocationsTotals.totalHours}
          allocatedCost={allocationsTotals.totalCost}
          viewCosts={canViewCostData}
          viewAllocation
          viewDifference={false}
          viewGrandTotal
        />
      </TotalsFooterPopoverCell>
    </ResourceRequestChartFooterRow>
  );
};

ResourcePlanGrandTotal.propTypes = {
  allocationsTotals: PropTypes.object,
  resourceRequestsTotals: PropTypes.object,
  loading: PropTypes.bool
};

export default ResourcePlanGrandTotal;
