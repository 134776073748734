import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmployeeTypeIcon = React.forwardRef((props, ref) => (
  <SvgIcon data-qe-id="EmployeeTypeIcon" {...props} ref={ref}>
    <>
      <path d="M 12 1 C 10.7 1 9.5996875 1.84 9.1796875 3 L 3 3 L 3 21 L 21 21 L 21 3 L 14.8203125 3 C 14.4003125 1.84 13.3 1 12 1 z M 12 3 C 12.55 3 13 3.449999999999999 13 4 C 13 4.550000000000001 12.55 5 12 5 C 11.45 5 11 4.550000000000001 11 4 C 11 3.449999999999999 11.45 3 12 3 z M 12 7.705078125 C 13.38333333333334 7.705078124999997 14.4921875 8.821744791666669 14.4921875 10.205078125 C 14.4921875 11.58841145833333 13.38333333333334 12.705078125 12 12.705078125 C 10.61666666666667 12.705078125 9.5 11.58841145833333 9.5 10.205078125 C 9.500000000000002 8.821744791666669 10.61666666666667 7.705078125 12 7.705078125 z M 12 13.833984375 C 13.66666666666668 13.833984375 17 14.66731770833333 17 16.333984375 L 17 18 L 7 18 L 7 16.333984375 C 7 14.66731770833333 10.33333333333334 13.833984375 12 13.833984375 z " />
    </>
  </SvgIcon>
));

export default EmployeeTypeIcon;
