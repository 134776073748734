import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Tooltip } from '@material-ui/core';

import ReadOnlyContainer from '~/modules/common/components/ReadOnlyContainer';
import QualityIcon from '~/modules/common/components/QualityIcon';

import CommentContent from './CommentContent';

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  qualityContainer: {
    display: 'flex',
    paddingRight: 8,
    flexDirection: 'column'
  },
  comment: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '95%',
    fontSize: theme.typography.body2.fontSize
  }
});

const SentimentCommentFieldReadOnly = ({
  classes,
  className,
  label,
  comment,
  quality,
  commentOverride,
  qualityOverride,
  showOverride = false,
  user,
  me,
  showComment = Boolean(comment) || Boolean(commentOverride)
}) => {
  const title = useMemo(
    () =>
      showComment ? (
        <CommentContent
          comment={comment}
          commentOverride={commentOverride}
          isContentForTooltip
          showOverride={showOverride}
          user={user}
          me={me}
        />
      ) : (
        ''
      ),
    [comment, commentOverride, me, showComment, showOverride, user]
  );

  return (
    <ReadOnlyContainer label={label} className={className}>
      <div className={classes.container}>
        <div className={classes.qualityContainer}>
          {(!!quality || quality === 0) && <QualityIcon quality={quality} />}
          {showOverride && qualityOverride !== null && (
            <QualityIcon quality={qualityOverride} />
          )}
        </div>

        <Tooltip placement="bottom-start" title={title}>
          <div className={classes.comment}>
            <CommentContent
              comment={comment}
              commentOverride={commentOverride}
              showOverride={showOverride}
            />
          </div>
        </Tooltip>
      </div>
    </ReadOnlyContainer>
  );
};

SentimentCommentFieldReadOnly.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  comment: PropTypes.string,
  quality: PropTypes.number,
  qualityOverride: PropTypes.number,
  commentOverride: PropTypes.string,
  showOverride: PropTypes.bool,
  user: PropTypes.object,
  me: PropTypes.object,
  showComment: PropTypes.bool
};

export default withStyles(styles)(SentimentCommentFieldReadOnly);
