import { ProjectRequestStatusType } from '~/types';
import { GET_PROJECT_REQUEST_DETAILS } from './useProjectRequest';

export const updateGatesInCache = ({ id, gates, featureFlags }) => cache => {
  try {
    const { projectRequest } = cache.readQuery({
      query: GET_PROJECT_REQUEST_DETAILS,
      returnPartialData: true,
      variables: {
        id
      }
    });
    const inReviewStatusGates = projectRequest.statusGates.find(
      sg => sg.status?.type === ProjectRequestStatusType.InReview
    );
    const updatedProjectRequest = {
      ...projectRequest,
      statusGates: [
        ...(projectRequest.statusGates || []).filter(
          sg => sg.status?.type !== ProjectRequestStatusType.InReview
        ),
        {
          ...inReviewStatusGates,
          gates
        }
      ]
    };

    cache.writeQuery({
      query: GET_PROJECT_REQUEST_DETAILS,
      variables: {
        id
      },
      data: {
        projectRequest: updatedProjectRequest
      }
    });
  } catch (e) {} // eslint-disable-line
};

export default updateGatesInCache;
