import { makeStyles } from '@material-ui/core';

export const useCardContentStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0
  },
  card: {
    padding: 0
  },
  content: {
    padding: theme.spacing(2, 0, 2, 0)
  },
  toolbar: {
    display: 'flex'
  },
  displayRight: {
    marginLeft: 'auto'
  },
  viewSummaryButton: {
    marginTop: theme.spacing(1)
  },
  dialogActions: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'block'
  },
  spacer: { flex: '1 0 0' }
}));

export const useCardExpansionPanelStyles = makeStyles(theme => ({
  expansionPanel: {
    boxShadow: 'none',
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  expansionPanelDetails: {
    display: 'block',
    overflow: 'auto',
    padding: 0,
    '& thead tr th': {
      borderTop: 'unset'
    }
  },
  expansionPanelSummary: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    backgroundColor: 'unset',
    fontSize: theme.typography.body1.fontSize,
    width: '100%',
    padding: 0,
    '&$expanded': {
      minHeight: 0,
      padding: 0,
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  expansionPanelSummaryContent: {
    margin: 0,
    display: 'flex',
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {
    borderBottom: 'unset'
  },
  expandIcon: { marginRight: 0 }
}));
