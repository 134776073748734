import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProposeIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 20,12 12,4 V 8 H 4 v 8 h 8 v 4 z" />
    </>
  </SvgIcon>
));

export default ProposeIcon;
