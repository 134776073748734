import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DrawerExpansionPanel,
  LineItemsTable
} from '~/modules/billing-invoicing/common/components';
import { useDialogState } from '~/modules/common/hooks';
import AddLineItemsDialog from '~/modules/billing-invoicing/billingItem/AddLineItemsDialog';
import { useOnBillDetailsChange } from '~/modules/billing-invoicing/bill/hooks';
import { BillStatus } from '~/types';
import { NoDataItem } from '~/modules/common/components';
import { BillCreditsSection } from './components';
import withStyles, { useNoDataStyles } from './withBillDetailStyles';
import { LineItemsPanelContent } from './LineItemsPanelContent';

const lineItemsPanelResourceKeys = {
  title: 'billDetails.lineItems',
  allocatedAmount: 'billDetails.allocatedAmount'
};

const lineItemsTableResourceKeys = {
  removeLineItemMessageForNoProject:
    'billDetails.removeLineItemMessageForNoProject',
  removeLabel: 'billDetails.removeLineItem',
  header: 'billDetails.removeLineItem',
  removeMessage: 'billDetails.removeLineItemMessage',
  actionButton: 'billDetails.removeLineItem',
  noDataMessage: 'billDetails.noLineItem',
  removeWarning: 'billDetails.removeBill',
  lastLineItemMessage: 'billDetails.lastLineItem',
  permissionWarning: 'billDetails.permissionWarning',
  permissionRemovedWarning: 'billDetails.permissionRemovedWarning'
};

const adhocSummarizeColumnOptions = ['PROJECT', 'DESCRIPTION'];

export const BillDetails = ({
  history,
  classes,
  values,
  showBillCredits = true,
  showLineItems = true,
  billBalanceTotal,
  standardLineItemsTotal,
  adhocLineItemsTotal,
  queryParams,
  isBillUnpaid = true,
  lineItemAdding,
  setLineItemAdding,
  permissions,
  isLineItemUpdating,
  setFieldValue,
  putLineItemForBill,
  deleteLineItemForBill,
  setSummarizeColumOptions,
  handleSubmit,
  deleteBill,
  onBillClose,
  hasAccessLineItems,
  setAssociateCreditTotal,
  taxTotal,
  setTaxTotal,
  onBillDataUpdatedOnAvailableToBill,
  setInProgress,
  billStatus,
  refetchFilterSummary,
  standardLineItemsHasMore,
  standardLineItemsLoadMore,
  standardLineItemsLoading,
  adhocLineItemsHasMore,
  adhocLineItemsLoadMore,
  adhocLineItemsLoading
}) => {
  const {
    open: dialogOpen,
    openDialog: onAddButtonClick,
    closeDialog: onClose
  } = useDialogState(false);
  const [expanded, setExpanded] = useState(false);
  const [hasTaxItems, setHasTaxItems] = useState(false);

  const {
    id,
    standardLineItems,
    adhocLineItems,
    client,
    billCurrency,
    summarizeColumnOptions
  } = values;

  lineItemsTableResourceKeys.aggregateLabel =
    hasTaxItems || (adhocLineItems.length > 0 && standardLineItems.length > 0)
      ? 'billDetails.subTotal'
      : 'billDetails.total';

  const {
    onLineItemAmountChange,
    onLineItemAmountBlur,
    onDeleteLineItem,
    onProjectLineItemAdd
  } = useOnBillDetailsChange({
    setFieldValue,
    values,
    putLineItemForBill,
    deleteLineItemForBill,
    setLineItemAdding
  });

  const { canEditBasicDetailOrDeleteBill } = permissions;

  const onBatchComplete = useCallback(async () => {
    setInProgress(true);
    refetchFilterSummary();
    await onBillDataUpdatedOnAvailableToBill(() => {
      handleSubmit();
      setLineItemAdding(false);
      setInProgress(false);
    });
  }, [
    setInProgress,
    refetchFilterSummary,
    onBillDataUpdatedOnAvailableToBill,
    handleSubmit,
    setLineItemAdding
  ]);

  const onDeleteLineItemHandler = useCallback(
    async (record, closeDialog) => {
      setInProgress(true);
      await onDeleteLineItem(record, !closeDialog);
      await onBillDataUpdatedOnAvailableToBill(() => {
        setInProgress(false);
        closeDialog && onBillClose && onBillClose();
      });
    },
    [
      onBillDataUpdatedOnAvailableToBill,
      setInProgress,
      onBillClose,
      onDeleteLineItem
    ]
  );

  const [billingBatchState, setBillingBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });

  const canEditLineItems = useMemo(
    () => isBillUnpaid && canEditBasicDetailOrDeleteBill,
    [canEditBasicDetailOrDeleteBill, isBillUnpaid]
  );

  const noDataClasses = useNoDataStyles();

  const allLineItemsLength = standardLineItems.length + adhocLineItems.length;
  const allLineItemsTotalWithTax = useMemo(() => {
    const total = {
      amount:
        standardLineItemsTotal.amount + adhocLineItemsTotal.amount + taxTotal,
      currency: standardLineItemsTotal.currency
    };

    return total;
  }, [adhocLineItemsTotal, standardLineItemsTotal, taxTotal]);
  const allLineItemsTotalWithoutTax = useMemo(() => {
    const total = {
      amount: standardLineItemsTotal.amount + adhocLineItemsTotal.amount,
      currency: standardLineItemsTotal.currency
    };

    return total;
  }, [adhocLineItemsTotal, standardLineItemsTotal]);

  useEffect(() => {
    setExpanded(isBillUnpaid && billStatus === BillStatus.Draft);
  }, [billStatus, isBillUnpaid]);

  return (
    <>
      <Grid container className={classes.container} spacing={2}>
        {showLineItems && (
          <Grid item xs={12}>
            <DrawerExpansionPanel
              resourceKeys={lineItemsPanelResourceKeys}
              amountTotal={allLineItemsTotalWithTax}
              expanded={expanded}
              setExpanded={setExpanded}
            >
              {allLineItemsLength === 0 ? (
                <div className={noDataClasses.noData}>
                  <NoDataItem>
                    <FormattedMessage
                      id={lineItemsTableResourceKeys.noDataMessage}
                    />
                  </NoDataItem>
                </div>
              ) : (
                <>
                  {standardLineItems.length > 0 && (
                    <LineItemsTable
                      lineItems={standardLineItems}
                      lineItemsTotal={standardLineItemsTotal}
                      resourceKeys={lineItemsTableResourceKeys}
                      onAmountChange={onLineItemAmountChange}
                      onAmountBlur={onLineItemAmountBlur}
                      editable={canEditLineItems}
                      onDelete={onDeleteLineItemHandler}
                      summarizeColumnOptions={summarizeColumnOptions}
                      hasMore={standardLineItemsHasMore}
                      loadMore={standardLineItemsLoadMore}
                      isLoading={standardLineItemsLoading}
                      isLineItemUpdating={isLineItemUpdating}
                      onRemove={deleteBill}
                      onClose={onBillClose}
                      hasAccessLineItems={hasAccessLineItems}
                      hasAdhocLineItems={false}
                      allLineItemsLength={allLineItemsLength}
                    />
                  )}
                  {adhocLineItems.length > 0 && (
                    <LineItemsTable
                      lineItems={adhocLineItems}
                      lineItemsTotal={adhocLineItemsTotal}
                      resourceKeys={lineItemsTableResourceKeys}
                      onAmountChange={onLineItemAmountChange}
                      onAmountBlur={onLineItemAmountBlur}
                      editable={canEditLineItems}
                      onDelete={onDeleteLineItemHandler}
                      summarizeColumnOptions={adhocSummarizeColumnOptions}
                      hasMore={adhocLineItemsHasMore}
                      loadMore={adhocLineItemsLoadMore}
                      isLoading={adhocLineItemsLoading}
                      isLineItemUpdating={isLineItemUpdating}
                      onRemove={deleteBill}
                      onClose={onBillClose}
                      hasAccessLineItems={hasAccessLineItems}
                      hasAdhocLineItems
                      allLineItemsLength={allLineItemsLength}
                    />
                  )}
                </>
              )}
              <LineItemsPanelContent
                classes={classes}
                billId={id}
                billingBatchState={billingBatchState}
                setBillingBatchState={setBillingBatchState}
                onBatchComplete={onBatchComplete}
                client={client}
                lineItemAdding={lineItemAdding}
                canEditLineItems={canEditLineItems}
                isBillUnpaid={isBillUnpaid}
                onProjectLineItemAdd={onProjectLineItemAdd}
                onAddButtonClick={onAddButtonClick}
                billCurrency={billCurrency}
                lineItemsTotal={allLineItemsTotalWithoutTax}
                setTaxTotal={setTaxTotal}
                setHasTaxItems={setHasTaxItems}
                billStatus={billStatus}
              />
            </DrawerExpansionPanel>
          </Grid>
        )}
        {showBillCredits && (
          <BillCreditsSection
            history={history}
            billId={id}
            billCurrency={billCurrency}
            client={client}
            billBalanceTotal={billBalanceTotal}
            queryParams={queryParams}
            permissions={permissions}
            setAssociateCreditTotal={setAssociateCreditTotal}
            billStatus={billStatus}
          />
        )}
      </Grid>
      {Boolean(
        dialogOpen &&
          !lineItemAdding &&
          !billingBatchState.batchInProgress &&
          canEditLineItems
      ) && (
        <AddLineItemsDialog
          billId={id}
          client={client}
          open={dialogOpen}
          onClose={onClose}
          setLineItemAdding={setLineItemAdding}
          billCurrency={billCurrency}
          summarizeColumnOptions={summarizeColumnOptions}
          setSummarizeColumOptions={setSummarizeColumOptions}
          setBillingBatchState={setBillingBatchState}
          billDetails={values}
        />
      )}
    </>
  );
};
BillDetails.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  billBalanceTotal: PropTypes.object,
  queryParams: PropTypes.any,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func,
  putLineItemForBill: PropTypes.func,
  deleteLineItemForBill: PropTypes.func,
  standardLineItemsTotal: PropTypes.object.isRequired,
  adhocLineItemsTotal: PropTypes.object.isRequired,
  showBillCredits: PropTypes.bool,
  showLineItems: PropTypes.bool,
  isBillUnpaid: PropTypes.bool,
  isLineItemUpdating: PropTypes.bool,
  deleteBill: PropTypes.func,
  onBillClose: PropTypes.func,
  hasAccessLineItems: PropTypes.any,
  setAssociateCreditTotal: PropTypes.func,
  taxTotal: PropTypes.number,
  setTaxTotal: PropTypes.func,
  lineItemAdding: PropTypes.bool,
  setLineItemAdding: PropTypes.func,
  permissions: PropTypes.object,
  setSummarizeColumOptions: PropTypes.func,
  handleSubmit: PropTypes.func,
  billStatus: PropTypes.string,
  onBillDataUpdatedOnAvailableToBill: PropTypes.func,
  setInProgress: PropTypes.func,
  refetchFilterSummary: PropTypes.func,
  standardLineItemsHasMore: PropTypes.bool,
  standardLineItemsLoadMore: PropTypes.func,
  standardLineItemsLoading: PropTypes.bool,
  adhocLineItemsHasMore: PropTypes.bool,
  adhocLineItemsLoadMore: PropTypes.func,
  adhocLineItemsLoading: PropTypes.bool
};

export default withStyles(BillDetails);
