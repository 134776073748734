import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ListItemText, makeStyles } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CreatableAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import useTagOptions from './hooks/useTagOptions';

const filter = createFilterOptions();

const useListItemStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const TagsEditKeyFormDropdown = ({
  rowIndex,
  errors,
  value,
  onSelectTag,
  onCreateTag,
  isTagKeyDisabled,
  canAddNewOption = true,
  hiddenLabel = true
}) => {
  const { formatMessage } = useIntl();

  const onListItemClick = useCallback(
    () => option => {
      const optionLabel = option.label.trim();

      return onCreateTag(optionLabel);
    },
    [onCreateTag]
  );
  const listItemClasses = useListItemStyles();

  const renderKeyOption = useCallback(
    option => {
      const { isCreatable } = option;

      const optionLabel = option.label.trim();

      return isCreatable ? (
        <>
          <ListItemText
            classes={listItemClasses}
            primary={formatMessage(
              { id: 'tags.createTag' },
              {
                tagName: optionLabel
              }
            )}
            onClick={onListItemClick}
          />
        </>
      ) : (
        <ListItemText primary={option.label} classes={listItemClasses} />
      );
    },
    [onListItemClick, formatMessage, listItemClasses]
  );
  const [tagSearchOption, setTagSearchOption] = useState('');

  const { data, loading } = useTagOptions({
    search: tagSearchOption
  });

  const filterOptions = useCallback(
    (filterOption, params) => {
      const filtered = filter(filterOption, params);

      if (loading) {
        return filtered;
      }

      if (
        canAddNewOption &&
        params.inputValue !== '' &&
        !filterOption.some(o => o.label === params.inputValue)
      ) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          label: params.inputValue
        });
      }

      return filtered;
    },
    [loading, canAddNewOption]
  );

  const handleOptionInputChange = useCallback(
    (event, newValue) => setTagSearchOption(newValue || ''),
    [setTagSearchOption]
  );

  const hasDisabledKey = useCallback(option => isTagKeyDisabled(option), [
    isTagKeyDisabled
  ]);

  const valueOptions = (!loading && data) || [];

  return (
    <CreatableAutocomplete
      dataQeId={`TagKey_${rowIndex}`}
      value={value}
      fullWidth
      onChange={onSelectTag}
      renderOption={renderKeyOption}
      filterOptions={filterOptions}
      getOptionDisabled={hasDisabledKey}
      hiddenLabel={hiddenLabel}
      optionPropText="label"
      noOptionsText={formatMessage({
        id: canAddNewOption ? 'tags.noOptions' : 'tags.noMatchingKeys'
      })}
      options={valueOptions}
      loading={loading}
      label={formatMessage({ id: 'tags.key' })}
      inputLabel={formatMessage({ id: 'tags.key' })}
      errorMessage={errors}
      showError={!!errors}
      onInputChange={handleOptionInputChange}
      canAddNewOption={canAddNewOption}
    />
  );
};

TagsEditKeyFormDropdown.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  errors: PropTypes.string,
  value: PropTypes.object,
  onSelectTag: PropTypes.func.isRequired,
  onCreateTag: PropTypes.func.isRequired,
  isTagKeyDisabled: PropTypes.func,
  canAddNewOption: PropTypes.bool,
  hiddenLabel: PropTypes.bool
};

export default TagsEditKeyFormDropdown;
