import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';

const UPDATE_PROJECT = gql`
  mutation UpdateProject($projectInput: ProjectInput!) {
    updateProject2(projectInput: $projectInput) {
      id
      startDate
      endDate
      defaultScheduleRule {
        dateRange {
          startDate
          endDate
        }
        do
      }
    }
  }
`;

const applyUpdates = ({
  updateProjectDateRange,
  projectFragment,
  defaultScheduleRule
}) => {
  const { projectUri, startDate, endDate } = projectFragment;

  updateProjectDateRange({
    variables: {
      projectInput: projectFragment
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateProject2: {
        __typename: 'Project',
        id: projectUri,
        startDate,
        endDate,
        defaultScheduleRule
      }
    }
  });
};

export const useUpdateProjectDateRangeHandlers = project => {
  const [updateProjectDateRange] = useMutation(UPDATE_PROJECT);

  const updateProjectStartDate = useCallback(
    start => {
      const startDate = DateTime.utc(
        start.year,
        start.month,
        start.day,
        0,
        0,
        0,
        0
      ).toISO();
      const {
        id: projectUri,
        endDate,
        hasEndDate,
        defaultScheduleRule
      } = project;

      const projectFragment = {
        projectUri,
        startDate,
        endDate: hasEndDate ? endDate : null
      };

      applyUpdates({
        updateProjectDateRange,
        projectFragment,
        defaultScheduleRule: {
          ...defaultScheduleRule,
          dateRange: {
            ...defaultScheduleRule.dateRange,
            startDate
          }
        }
      });
    },
    [updateProjectDateRange, project]
  );

  const updateProjectEndDate = useCallback(
    end => {
      const endDate = DateTime.utc(
        end.year,
        end.month,
        end.day,
        0,
        0,
        0,
        0
      ).toISO();
      const {
        id: projectUri,
        startDate,
        hasStartDate,
        defaultScheduleRule
      } = project;

      const projectFragment = {
        projectUri,
        startDate: hasStartDate ? startDate : null,
        endDate
      };

      applyUpdates({
        updateProjectDateRange,
        projectFragment,
        defaultScheduleRule: {
          ...defaultScheduleRule,
          dateRange: {
            ...defaultScheduleRule.dateRange,
            endDate
          }
        }
      });
    },
    [updateProjectDateRange, project]
  );

  return {
    updateProjectStartDate,
    updateProjectEndDate
  };
};

export default useUpdateProjectDateRangeHandlers;
