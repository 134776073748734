import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import ResourceAllocationCardTotal from '../ResourceAllocationCardTotal';

export const ResourceAllocationCardHours = ({ allocatedHours }) => {
  const intl = useIntl();

  return (
    <ResourceAllocationCardTotal
      label={intl.formatMessage({
        id: 'mobileResourcePlanning.totalAllocatedHours'
      })}
      resourceAllocatedTotal={allocatedHours}
    />
  );
};

ResourceAllocationCardHours.propTypes = {
  allocatedHours: PropTypes.number
};

export default ResourceAllocationCardHours;
