import { gql } from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { ResourceRequestStatus } from '~/types';
import { specificResourceRequestWithAllocationsFragment } from '~/modules/resourcing/common/fragments';
import { mapToServiceRequest } from '../../util';

export const TOBEHIRED_RESOURCE_REQUEST = gql`
  mutation MarkResourceRequestAsToBeHired(
    $input: MarkResourceRequestAsToBeHiredInput!
  ) {
    markResourceRequestAsToBeHired2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
`;

export const invokeMarkResourceRequestAsToBeHired = markResourceRequestAsToBeHired => (
  request,
  chartSelectedTab,
  requestAccessLevel
) =>
  markResourceRequestAsToBeHired(
    mapToServiceRequest({
      request,
      responseType: 'markResourceRequestAsToBeHired2',
      responseTypeName: 'MarkResourceRequestAsToBeHiredResult',
      requestStatus: ResourceRequestStatus.Tobehired,
      chartSelectedTab,
      requestAccessLevel
    })
  );

export default graphql(TOBEHIRED_RESOURCE_REQUEST, {
  name: 'markResourceRequestAsToBeHired'
});
