import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PinIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 10.42471153846154,10.03984489160852 6.535576923076923,8.979184719828694 5.121346153846151,10.39339828220179 8.656923076923073,13.92893218813453 2.999999999999999,19.5857864376269 3.000000000000001,21 h 1.414230769230766 l 5.656923076923073,-5.65685424949238 3.53557692307693,3.53553390593274 1.41423076923076,-1.4142135623731 -1.06067307692308,-3.88908729652601 7.42471153846154,-5.303300858899102 -5.65692307692304,-5.65685424949238 z" />
    </>
  </SvgIcon>
));

export default PinIcon;
