import {
  Menu,
  MenuItem,
  Button,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import AddCircleIcon from '@material-ui/icons/AddCircleSharp';
import { useMenuState } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  addPlan: {
    color: theme.palette.primary.main,
    float: 'left',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5)
  },
  menuItem: {
    minWidth: '120px',
    whiteSpace: 'pre-line'
  }
}));

const menuProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
  listProp: {
    dense: true
  }
};

const addIcon = <AddCircleIcon />;

export const AddBillPlanTypeButton = ({
  onBillPlanItemClick,
  billPlans = []
}) => {
  const classes = useStyles();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const isOpen = Boolean(anchorEl);

  const onMenuItemClick = useCallback(
    billPlan => async () => {
      onBillPlanItemClick(billPlan);
      onMenuClose(null);
    },
    [onBillPlanItemClick, onMenuClose]
  );

  const billPlansToAdd = useMemo(
    () => billPlans.filter(billPlan => !billPlan.scripts.length),
    [billPlans]
  );

  return (
    <>
      <Button
        startIcon={addIcon}
        className={classes.addPlan}
        onClick={onMenuClick}
        size="small"
        data-qe-id="addPlan"
        disabled={!billPlansToAdd.length}
      >
        <FormattedMessage id="billPlan.addPlan" />
      </Button>
      <Menu
        id="add-bill-plan-type"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={menuProps.anchorOrigin}
        transformOrigin={menuProps.transformOrigin}
        open={isOpen}
        onClose={onMenuClose}
        MenuListProps={menuProps.listProp}
      >
        {billPlansToAdd.map(billPlan => (
          <MenuItem
            key={billPlan.id}
            className={classes.menuItem}
            onClick={onMenuItemClick(billPlan)}
            data-qe-id={billPlan.displayText}
          >
            <ListItemText
              primary={billPlan.displayText}
              secondary={billPlan.script.description}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
AddBillPlanTypeButton.propTypes = {
  onBillPlanItemClick: PropTypes.func,
  billPlans: PropTypes.array
};

export default AddBillPlanTypeButton;
