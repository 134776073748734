import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ChartTotalItems } from '~/modules/common/charts/timeline/components';
import { getResourceRequestTotal } from '~/modules/resourcing/common/enhancers/requestStatusHandlers/useResourceRequestTotal';
import { FormattedHoursAndCost } from '~/modules/resourcing/common/components';
import getAllocationsTotalForResourceRequest from '~/modules/resourcing/hooks/getAllocationsTotalForResourceRequest';
import { useMeContext } from '~/modules/me';
import { getShowAllocationTotalForResourceRequest } from '~/modules/resourcing/common/enhancers/requestStatusHandlers/useShowAllocationTotalForResourceRequest';
import {
  usePopperHandlers,
  ResourceChartTotalPopper
} from '~/modules/resourcing/components/ResourceChartTotalPopper';
import {
  CostVarianceNumber,
  HoursVarianceNumber
} from '~/modules/resourcing/common/components/VarianceStyledFormattedNumber';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    textAlign: 'right',
    fontSize: theme.typography.caption.fontSize
  },
  hours: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  },
  cost: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  currency: {
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useDelaStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.Dela.lightBackground
  }
}));

const mapToPopperCostProp = unformattedCost => {
  return unformattedCost
    ? {
        amount: unformattedCost.amount,
        currency: {
          symbol: unformattedCost.currencySymbol
        }
      }
    : null;
};

export const ResourceRequestChartTotal = ({
  canViewCostData,
  resourceRequest,
  resourceAllocations,
  contextCurrency
}) => {
  const me = useMeContext();
  const {
    anchorEl,
    onMenuClose,
    onMenuClick,
    handleKeyDown
  } = usePopperHandlers();

  const isAllocationExists =
    resourceAllocations && resourceAllocations.length !== 0;

  const classes = useStyles();
  const delaClasses = useDelaStyles();
  const {
    totalAllocatedCostForResourceRequest,
    totalAllocatedHoursForResourceRequest
  } = getAllocationsTotalForResourceRequest({
    me,
    resourceAllocations,
    contextCurrency
  });

  const {
    showAllocationTotalForResourceRequest
  } = getShowAllocationTotalForResourceRequest({ resourceRequest });

  const {
    resourceRequestTotalCost,
    resourceRequestTotalHours
  } = getResourceRequestTotal(resourceRequest);

  const total = useMemo(
    () => (
      <FormattedHoursAndCost
        classes={classes}
        hours={
          totalAllocatedHoursForResourceRequest || resourceRequestTotalHours
        }
        costs={
          totalAllocatedCostForResourceRequest &&
          totalAllocatedCostForResourceRequest.length > 0
            ? totalAllocatedCostForResourceRequest
            : [resourceRequestTotalCost]
        }
        hoursSuffix="h"
        hideCosts={!canViewCostData}
        showAbbreviatedValue={false}
      />
    ),
    [
      classes,
      resourceRequestTotalCost,
      resourceRequestTotalHours,
      canViewCostData,
      showAllocationTotalForResourceRequest,
      totalAllocatedCostForResourceRequest,
      totalAllocatedHoursForResourceRequest
    ]
  );

  const {
    totalAllocatedCostForResourceRequest: tooltipAllocatedCost
  } = getAllocationsTotalForResourceRequest({
    resourceAllocations,
    me,
    contextCurrency
  });

  const resourceRequestPopperCost = mapToPopperCostProp(
    resourceRequestTotalCost
  );

  const resourceAllocationPopperCost = mapToPopperCostProp(
    tooltipAllocatedCost[0]
  );

  const popperContent = (
    <ResourceChartTotalPopper
      id="resource-request-popper"
      anchorEl={anchorEl}
      onClose={onMenuClose}
      requestedHours={resourceRequestTotalHours}
      requestedCost={resourceRequestPopperCost}
      allocatedHours={totalAllocatedHoursForResourceRequest}
      allocatedCost={resourceAllocationPopperCost}
      viewCosts={canViewCostData}
      viewAllocation={isAllocationExists}
      viewDifference={isAllocationExists}
    />
  );

  const showHourDiffValue =
    totalAllocatedHoursForResourceRequest > 0 &&
    totalAllocatedHoursForResourceRequest !== resourceRequestTotalHours;

  const showCostDiffValue =
    canViewCostData &&
    totalAllocatedCostForResourceRequest.length === 1 &&
    totalAllocatedCostForResourceRequest[0].currencyUri ===
      resourceRequestTotalCost.currencyUri &&
    totalAllocatedCostForResourceRequest[0].amount !==
      resourceRequestTotalCost.amount;

  const hourDiffValue = showHourDiffValue
    ? totalAllocatedHoursForResourceRequest - resourceRequestTotalHours
    : null;

  const costDiffValue = showCostDiffValue
    ? totalAllocatedCostForResourceRequest[0].amount -
      resourceRequestTotalCost.amount
    : null;

  const hourDiff = hourDiffValue && (
    <HoursVarianceNumber
      value={hourDiffValue}
      showAddIcon={hourDiffValue > 0}
      formattedMessageKey="resourceActualSummaryDialog.hours"
    />
  );

  const costDiff = costDiffValue && (
    <CostVarianceNumber
      value={costDiffValue}
      prefix={resourceRequestTotalCost.currencySymbol}
      showAddIcon={costDiffValue > 0}
    />
  );

  return (
    <ChartTotalItems
      classes={resourceRequest.isDelaDraftResourceRequest ? delaClasses : null}
      allocated={total}
      showCost={canViewCostData}
      costDiff={costDiff}
      hourDiff={hourDiff}
      popperContent={popperContent}
      handleClick={onMenuClick}
      handleKeyDown={handleKeyDown}
      onClickAway={onMenuClose}
    />
  );
};

ResourceRequestChartTotal.propTypes = {
  canViewCostData: PropTypes.bool,
  resourceAllocations: PropTypes.array,
  resourceRequest: PropTypes.object.isRequired,
  contextCurrency: PropTypes.object
};

export default ResourceRequestChartTotal;
