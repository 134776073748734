const styles = theme => ({
  victoryChart: {
    padding: {
      top: theme.spacing(4),
      bottom: theme.spacing(4),
      left: theme.spacing(4),
      right: theme.spacing(1)
    },
    domainPadding: { x: [30, 20], y: [0, 20] },
    width: 600,
    height: 350,
    scaleXTime: { x: 'time' }
  },
  victoryAxis: {
    x: {
      tickLabels: {
        paddingTop: 15,
        angle: 0,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        verticalAnchor: 'middle',
        textAnchor: 'middle',
        fill: theme.palette.grey[600]
      },
      axis: {
        stroke: theme.palette.grey[100]
      }
    },
    y: {
      domainPadding: [0, 10],
      tickLabels: {
        padding: 1,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fill: theme.palette.grey[600]
      },
      axis: {
        stroke: theme.palette.common.white
      },
      grid: {
        stroke: theme.palette.grey[100]
      },
      axisLabel: {
        fontSize: 16,
        padding: theme.spacing(4.5),
        fill: theme.palette.grey[600]
      }
    }
  },
  victoryLegend: {
    style: {
      labels: {
        fontSize: 18
      }
    }
  },
  victoryBar: {
    estimatedAlignment: 'middle',
    actualAlignment: 'end',
    barWidth: 25
  },
  container: {}
});

export default styles;
