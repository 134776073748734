import PropTypes from 'prop-types';

const actionItem = PropTypes.shape({
  icon: PropTypes.node,
  secondaryIcon: PropTypes.node,
  label: PropTypes.node,
  status: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  className: PropTypes.string,
  tableKey: PropTypes.string,
  filter: PropTypes.object,
  customActionItems: PropTypes.array
});

const subGroup = PropTypes.shape({
  label: PropTypes.node.isRequired,
  actionItems: PropTypes.arrayOf(actionItem.isRequired).isRequired
});

const actionGroup = PropTypes.shape({
  icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  actionItems: PropTypes.arrayOf(
    PropTypes.oneOfType([actionItem, subGroup]).isRequired
  ).isRequired
});

export default {
  actionItem,
  subGroup,
  actionGroup,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([actionItem, actionGroup, PropTypes.bool])
  ),
  actionItems: PropTypes.arrayOf(
    PropTypes.oneOfType([actionItem, subGroup]).isRequired
  )
};
