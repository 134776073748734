import { gql } from 'graphql-tag';
import { useQuery, NetworkStatus } from '@apollo/client';
import { CustomFieldValueFragmentDoc } from '~/types';
import { useResourceManagementContext } from '~/modules/resource-management/common';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';
import {
  columnArguments,
  enabledFieldsToVariables
} from './resourceColumnSelection';

export const resourceDetailsQuery = gql`
query getResourceDetailsByPage(
  $page: Int!
  $pagesize: Int!
  $filter: ResourceDetailsFilter
  $customFieldUris:[String!]
  $tagIds:[String!]
  $sort: ResourceSort
  ${columnArguments}
) {
  resourceDetails(page: $page, pagesize: $pagesize, filter: $filter, customFieldUris: $customFieldUris, tagIds: $tagIds, sort: $sort) {
    id
    uri
    slug
    displayText
    employeeId @include(if: $includeEmployeeId)
    startDate @include(if: $includeStartDate)
    endDate @include(if: $includeEndDate)
    department @include(if: $includeDepartment)
    employeeType @include(if: $includeEmployeeType)
    currentDepartment @include(if: $includeCurrentDepartment) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    allResourcePools @include(if: $includeResourcePools) {
      id
      slug
      displayText
    }
    currentEmployeeType @include(if: $includeCurrentEmployeeType) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    currentCostCenter @include(if: $includeCurrentCostCenter) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    currentServiceCenter @include(if: $includeCurrentServiceCenter) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    currentDivision @include(if: $includeCurrentDivision) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    currentLocation @include(if: $includeCurrentLocation) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    supervisorAssignment @include(if: $includeSupervisorAssignment) {
      supervisor {
        uri
        displayText
      }
      effectiveDate
    }
    customFieldValues {
      ...CustomFieldValue
    }
    tags @include(if: $includeTags) {
      tag {
        id
        name
      }
      value {
        id
        value
      }
    }
    roles @include(if: $includeRoles) {
      isPrimary
      projectRole {
        uri
        name
      }
    }
    effectiveRoles @include(if: $includeRoles) {
      isPrimary
      effectiveDate
      projectRole {
        uri
        name
      }
    }
  }
}
${CustomFieldValueFragmentDoc}
`;
export const useResourceManagement = ({
  page,
  pagesize,
  tagFieldUris,
  sort,
  customFieldUris,
  enabledFields,
  me,
  skip
}) => {
  const { searchCriteria } = useResourceManagementContext();

  const { filter } = useFilterBasedOnSearchCriteria({ searchCriteria });
  const _includeTags = tagFieldUris.length > 0;
  const variables = {
    page,
    pagesize,
    filter,
    sort,
    customFieldUris,
    tagIds: tagFieldUris,
    includeTags: _includeTags,
    ...enabledFieldsToVariables(enabledFields, me)
  };
  const { data, loading, fetchMore, networkStatus, error } = useQuery(
    resourceDetailsQuery,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      skip
    }
  );
  const resources = (data && data.resourceDetails) || [];

  return {
    resources,
    loading,
    variables,
    fetchMore,
    fetchMoreLoading: loading && networkStatus === NetworkStatus.fetchMore,
    error
  };
};
