import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import { ExpenseAmountFooter } from './ExpenseAmountFooter';
import { ExpenseRowLabelFormatter } from './ExpenseRowLabelFormatter';
import { useColumnVisibilityProp } from './useColumnVisibilityProp';

export const buildColumns = ({
  parameters,
  classes,
  isMobile,
  allowBillable,
  showActuals,
  context,
  editable,
  chartDates,
  projectCurrency,
  viewSummary,
  canEditExpenseCodes,
  canEditBillingContracts,
  canViewBillingData,
  showDeleteRow,
  isLabelRowColumnVisible,
  isBillableTypeRowColumnVisible,
  billableColumns,
  ...rest
}) => {
  const getAllowBillableCondition = id =>
    id === 'urn:replicon:script-key:parameter:markup';
  const isBillable = id =>
    id === 'urn:replicon:script-key:parameter:markup' && billableColumns.length;
  const columns = parameters.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.id]: {
        ...current,
        id: current.id,
        value: '',
        label: isMobile ? current.displayText : null,
        visible:
          getAllowBillableCondition(current.id) &&
          isBillable(current.id) &&
          canViewBillingData &&
          (editable || viewSummary) &&
          current.visible,
        isMobile,
        className: classes.noBorder,
        ...rest
      }
    }),
    {}
  );

  const monthlyBreakdownColumns = chartDates.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.key]: {
        id: curr.key,
        visible: editable || viewSummary,
        value: '',
        startDate: curr.start,
        endDate: curr.end,
        align: 'center',
        showActuals,
        projectCurrency
      }
    }),
    {}
  );

  return {
    expenseCode: {
      id: 'expenseCode',
      visible: editable || viewSummary,
      value: isLabelRowColumnVisible ? (
        ''
      ) : (
        <FormattedMessage id="expenseBillPlan.total" />
      ),
      align: 'left',
      className: classes.noBorder
    },
    ...columns,
    billableType: {
      id: 'billableType',
      visible: isBillableTypeRowColumnVisible && canViewBillingData,
      value: '',
      align: 'left'
    },
    estimate: {
      id: 'estimate',
      visible: context === 'timeAndExpense' && !editable && !viewSummary,
      value: '',
      align: 'right'
    },
    ...monthlyBreakdownColumns,
    totalEstimates: {
      id: 'totalEstimates',
      visible: editable || viewSummary,
      value: '',
      align: 'center',
      showActuals,
      projectCurrency
    },
    deleteRow: {
      id: 'deleteRow',
      visible: showDeleteRow,
      value: ''
    }
  };
};

const getEditorForChartDates = chartDates =>
  chartDates.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.key]: ExpenseAmountFooter
    }),
    {}
  );

export const useListFooters = ({
  parameters,
  classes,
  chartDates = [],
  allowBillable,
  showActuals,
  context,
  editable,
  projectCurrency,
  viewSummary,
  billableColumns,
  ...rest
}) => {
  const {
    isLabelRowColumnVisible,
    isBillableTypeRowColumnVisible
  } = useColumnVisibilityProp({
    viewSummary,
    editable
  });

  return useColumns({
    columns: buildColumns({
      parameters,
      classes,
      chartDates,
      allowBillable,
      showActuals,
      context,
      editable,
      projectCurrency,
      viewSummary,
      isLabelRowColumnVisible,
      isBillableTypeRowColumnVisible,
      billableColumns,
      ...rest
    }),
    renders: {
      labelRow: ExpenseRowLabelFormatter,
      ...getEditorForChartDates(chartDates),
      totalEstimates: ExpenseAmountFooter
    }
  });
};
