import { DialogContentText, LinearProgress } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  useCreditMemoLineItems,
  useCreditMemoDetails,
  useAssociatedAndOutStandingBills,
  useCreditMemoTotalProps,
  usePutCreditMemo,
  usePutCreditMemoFormState,
  useDeleteCreditMemo
} from '~/modules//billing-invoicing/creditMemo/hooks';
import {
  useFormOnCancel,
  useSaving,
  useEditableState
} from '~/modules/billing-invoicing/common/hooks';
import { useDialogState } from '~/modules/common/hooks';
import {
  DrawerFooter,
  DrawerHeaderComponent
} from '~/modules/billing-invoicing/common/components';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { BILLING_INVOICING_PERMISSION_URIS } from '~/modules/common/enums/BillingInvoicingPermissions';
import { useMeContext } from '~/modules/me/useMeContext';
import { CreditMemoDetails, CreditMemoReadOnly } from '../CreditMemoDetails';

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

const deleteDialogResourceKeys = {
  header: 'creditMemoDetails.deleteDialog.removeCreditMemo',
  actionButton: 'creditMemoDetails.deleteDialog.removeCreditMemo'
};

const footerResourceKeys = ({ intl }) => ({
  actionButton: 'addDialog.save',
  deleteButton: 'creditMemoDetails.deleteCreditMemo',
  deleteMenu: intl.formatMessage({ id: 'creditMemoDetails.deleteCreditMemo' })
});

export const EditCreditMemoDrawer = ({
  id,
  open,
  billId,
  client,
  billBalanceTotal,
  projectUri,
  projectName,
  onClose
}) => {
  const drawerClasses = useDrawerStyles();
  const me = useMeContext();
  const permissions = useMemo(
    () => ({
      canEditCreditMemo: Boolean(
        me.permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_CREDIT_MEMO]
      )
    }),
    [me]
  );
  const intl = useIntl();
  const { saving, setSaving } = useSaving();
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);
  const { deleteCreditMemo } = useDeleteCreditMemo();
  const { putCreditMemo } = usePutCreditMemo();
  const { loading, creditMemo } = useCreditMemoDetails({ id });
  const {
    loading: lineItemsLoading,
    lineItems,
    hasMore,
    loadMore
  } = useCreditMemoLineItems(id);
  const { associatedAndOutstandingBills } = useAssociatedAndOutStandingBills({
    me,
    client,
    creditMemo,
    billId,
    billBalanceTotal
  });
  const formik = usePutCreditMemoFormState({
    me,
    client,
    setSaving,
    onClose,
    projectUri,
    projectName,
    billBalanceTotal,
    associatedAndOutstandingBills,
    billId,
    putCreditMemo,
    creditMemo,
    lineItems
  });
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    errors,
    isSubmitting
  } = formik;
  const { onCancel } = useFormOnCancel({ handleReset, onClose, saving });
  const {
    lineItemsTotal,
    associatedBillsTotal,
    creditMemoBalanceTotal
  } = useCreditMemoTotalProps({ values });
  const onDeleteConfirm = useCallback(() => {
    deleteCreditMemo({ id: values.id });
    onCancel();
  }, [deleteCreditMemo, onCancel, values.id]);
  const { editable, setEditable } = useEditableState({
    loading,
    creditItem: creditMemo
  });
  const setToEditMode = useCallback(() => {
    if (!editable) setEditable(!editable);
  }, [editable, setEditable]);

  const { canEditCreditMemo } = permissions;
  const allowEditableActions = editable && canEditCreditMemo;
  const hasAccessLineItems = useMemo(
    () =>
      lineItems &&
      lineItems.filter(lineItem => lineItem.hasAccessToLineItemProject).length,
    [lineItems]
  );
  const isLoading = loading || lineItemsLoading;

  return (
    <>
      {isLoading && <LinearProgress variant="query" />}
      <MuiDrawer
        anchor="right"
        open={open}
        onClose={editable ? null : onCancel}
        classes={drawerClasses}
      >
        {!isLoading && (
          <DrawerHeaderComponent
            ariaLabel={intl.formatMessage({
              id: 'creditMemoDetails.editCreditMemo'
            })}
            values={values}
            onCancel={onCancel}
            canEdit={canEditCreditMemo}
            balanceStatus={creditMemo.balanceStatus}
            editable={editable}
            setToEditMode={setToEditMode}
            transactionType="CREDITMEMO"
            drawerTitleId="creditMemoDetails.drawerTitle"
          />
        )}
        {!isLoading && allowEditableActions && (
          <CreditMemoDetails
            me={me}
            values={values}
            isUnAllocated={creditMemo.balanceStatus === 'UNALLOCATED'}
            hasClient
            setFieldValue={setFieldValue}
            lineItemsTotal={lineItemsTotal}
            associatedBillsTotal={associatedBillsTotal}
            hasMore={hasMore}
            loadMore={loadMore}
            errors={errors}
            isSubmitting={isSubmitting}
            deleteCreditMemo={onDeleteConfirm}
            onCreditMemoClose={onClose}
            hasAccessLineItems={hasAccessLineItems}
          />
        )}
        {!isLoading && !allowEditableActions && (
          <CreditMemoReadOnly
            values={values}
            hasClient
            lineItemsTotal={lineItemsTotal}
            associatedAndOutstandingBills={associatedAndOutstandingBills}
            associatedBillsTotal={associatedBillsTotal}
            billBalanceTotal={billBalanceTotal}
            hasMore={hasMore}
            loadMore={loadMore}
          />
        )}
        {!isLoading && (
          <DrawerFooter
            balanceTotal={creditMemoBalanceTotal}
            isSaving={saving}
            canDelete={allowEditableActions}
            resourceKeys={footerResourceKeys({ intl })}
            onAdd={handleSubmit}
            onCancel={onCancel}
            onDelete={openRemoveDialog}
            editable={allowEditableActions}
          />
        )}
      </MuiDrawer>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onDeleteConfirm}
        >
          <DialogContentText>
            <FormattedMessage id="creditMemoDetails.deleteDialog.description" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

EditCreditMemoDrawer.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  billId: PropTypes.string,
  client: PropTypes.object,
  billBalanceTotal: PropTypes.object,
  projectUri: PropTypes.string,
  projectName: PropTypes.string,
  onClose: PropTypes.func
};

export default EditCreditMemoDrawer;
