import React, { useMemo } from 'react';
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@material-ui/icons/AddSharp';
import { PropTypes } from 'prop-types';
import {
  AddProjectLineItemButton,
  TaxLineItems
} from '~/modules/billing-invoicing/common/components';
import { BillingBatchStatus, AddIconButton } from '~/modules/common/components';
import { BillStatus } from '~/types';
import AvailableBillSummary from './components/AvailableBillSummary';

const resourceLabels = {
  lineItem: <FormattedMessage id="billDetails.lineItem" />
};
const addIcon = <AddIcon />;
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[0],
    '&:hover': {
      boxShadow: theme.shadows[2]
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.3),
      padding: theme.spacing(0.5, 0.5),
      width: theme.spacing(10)
    }
  },
  addIconButton: {
    marginTop: theme.spacing(1.5)
  },
  availableBillSummaryHeader: {
    display: 'flex',
    marginTop: theme.spacing(1.5)
  },
  availableBillSummaryButton: {
    flexGrow: 1
  },
  addLineItemButton: {
    width: theme.spacing(11.2)
  }
}));

export const LineItemsPanelContent = ({
  classes,
  billId,
  billingBatchState,
  setBillingBatchState,
  onBatchComplete,
  client,
  lineItemAdding,
  canEditLineItems,
  isBillUnpaid,
  onProjectLineItemAdd,
  onAddButtonClick,
  billCurrency,
  setTaxTotal,
  lineItemsTotal,
  setHasTaxItems,
  billStatus
}) => {
  const buttonclasses = useStyles();
  const addIconButtonClasses = useMemo(() => ({ root: buttonclasses.root }), [
    buttonclasses
  ]);
  const buttonColor = billStatus === BillStatus.Draft ? 'primary' : 'default';

  return (
    <Grid container>
      <Grid item xs={12} sm={7}>
        {billingBatchState.batchInProgress && (
          <BillingBatchStatus
            billingBatchState={billingBatchState}
            setBillingBatchState={setBillingBatchState}
            onBatchComplete={onBatchComplete}
          >
            <CircularProgress size={24} data-qe-id="updatingLineItems" />
          </BillingBatchStatus>
        )}
        {Boolean(
          client &&
            client.id &&
            !lineItemAdding &&
            !billingBatchState.batchInProgress &&
            canEditLineItems
        ) && (
          <>
            {isBillUnpaid ? null : (
              <AddProjectLineItemButton
                client={client}
                onSelection={onProjectLineItemAdd}
              />
            )}
            <div className={buttonclasses.availableBillSummaryHeader}>
              {isBillUnpaid ? (
                <Typography component="div">
                  <AddIconButton
                    onClick={onAddButtonClick}
                    classes={addIconButtonClasses}
                    data-qe-id="lineItem"
                    color={buttonColor}
                  >
                    {resourceLabels.lineItem}
                  </AddIconButton>
                </Typography>
              ) : (
                <Typography component="div" className={classes.lineItem}>
                  <Button
                    startIcon={addIcon}
                    className={(classes.add, buttonclasses.addLineItemButton)}
                    size="small"
                    onClick={onAddButtonClick}
                    data-qe-id="lineItem"
                  >
                    {resourceLabels.lineItem}
                  </Button>
                </Typography>
              )}
              <AvailableBillSummary
                billingItemGroup="PROJECT"
                client={client}
                billCurrency={billCurrency}
              />
            </div>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={5}>
        <TaxLineItems
          billId={billId}
          setTaxTotal={setTaxTotal}
          lineItemsTotal={lineItemsTotal}
          setHasTaxItems={setHasTaxItems}
        />
      </Grid>
    </Grid>
  );
};

LineItemsPanelContent.propTypes = {
  classes: PropTypes.object.isRequired,
  isBillUnpaid: PropTypes.bool,
  billId: PropTypes.string,
  billingBatchState: PropTypes.object,
  setBillingBatchState: PropTypes.func,
  onBatchComplete: PropTypes.func,
  client: PropTypes.object,
  lineItemAdding: PropTypes.bool,
  canEditLineItems: PropTypes.bool,
  onProjectLineItemAdd: PropTypes.func,
  onAddButtonClick: PropTypes.func,
  billCurrency: PropTypes.object,
  lineItemsTotal: PropTypes.object,
  setTaxTotal: PropTypes.func,
  setHasTaxItems: PropTypes.func,
  billStatus: PropTypes.string
};

export default LineItemsPanelContent;
