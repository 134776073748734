import { useState } from 'react';

export const useBillFormState = () => {
  const [saving, setSaving] = useState(false);
  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });
  const [lineItemAdding, setLineItemAdding] = useState(false);
  const [editable, setEditable] = useState(false);

  return {
    saving,
    setSaving,
    editable,
    setEditable,
    lineItemAdding,
    setLineItemAdding,
    batchState,
    setBatchState
  };
};
