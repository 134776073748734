import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import ProgramDropdownForProject from '~/modules/projects/components/Common/ProgramDropdownForProject';
import ProjectManagerDropdown from '~/modules/projects/project/common/components/SearchableProjectManagerDropdown';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useMeContext } from '~/modules/me/useMeContext';
import { useProjectPermissions } from '~/modules/common/hooks/project';
import { useCodeAndNameRequired } from '~/modules/common/hooks';
import {
  useSmartEndDateChange,
  useSmartStartDateChange
} from '~/modules/common/dates/form';
import { TimeAndExpenseEntryTypeDropdown } from '~/modules/tasks/components';
import ClientDropdown from '~/modules/projects/components/Common/ClientDropdownForProject';
import { DIALOG_FORM_TYPE } from '~/modules/common/enums/AddProjectDialogFormTypes';
import useClientCurrency from '~/modules/projects/project/common/hooks/useClientCurrency';
import EarnedRevenueDropdown from '~/modules/projects/project/EarnedRevenue/EarnedRevenueDropdown';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import PortfoliosDropdown from '../PortfoliosDropDown/PortfoliosDropdown';
import { ProjectTemplateDropdown } from '../ProjectTemplateDropdown';
import RequiredCustomFields from './RequiredCustomFields';
import DateRangeFields from './DateRangeFields';
import useFormOnChange from './useFormOnChange';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1, 3)
  }
}));

const useLoadingStyle = makeStyles(() => ({
  progress: {
    position: 'absolute',
    left: '50%',
    top: '40%'
  },
  loader: {
    minHeight: '450px'
  }
}));

const AddProjectDialogForm = ({
  type,
  onCancel,
  values,
  errors,
  handleChange,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  loading = false
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const loadingClass = useLoadingStyle();
  const { formatMessage } = intl;

  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpPsaPpmMergerEnabled }
  } = me;

  const { isCodeRequired, isNameRequired } = useCodeAndNameRequired({
    codeNameAndUniqueness: me.projectNameAndCodeUniqueness
  });

  const { refetch } = useClientCurrency({
    client: values && values.client,
    skipClient: true
  });

  const {
    onClientAndCurrencyChange,
    onProgramChange,
    onProjectManagerChange,
    onPortfolioChange,
    onProjectTemplateChange,
    onEarnedRevenueScriptChange,
    onProjectManagerApprovalChange,
    onTimeAndExpenseEntryChange
  } = useFormOnChange({
    setFieldValue,
    values,
    refetch,
    me
  });

  const onStartDateChange = useSmartStartDateChange({
    setFieldValue,
    endDate: values.endDate
  });

  const onEndDateChange = useSmartEndDateChange({
    setFieldValue,
    startDate: values.startDate
  });

  const programTextFieldProps = useMemo(
    () => ({
      error: Boolean(errors.program),
      helperText: errors.program
    }),
    [errors.program]
  );

  const clientTextFieldProps = useMemo(
    () => ({
      error: Boolean(errors.client),
      helperText: errors.client
    }),
    [errors.client]
  );

  const hasErrors =
    Object.keys(errors).filter(e => e !== 'unhandled').length > 0;

  const unhandledError = errors.unhandled;

  const { hasBilling, hasPortfolio } = useProjectTemplateSettings({
    projectTemplateSetting: values?.projectTemplate?.settings,
    isPsaPrpPsaPpmMergerEnabled
  });

  const {
    hasEditClientPermission,
    hasEditPortfolioPermission,
    hasEditProgramPermission,
    hasEditProjectPermission,
    canViewAllProjects,
    canEditAllProjects,
    isClientManager,
    isProgramManager,
    isProjectManager,
    projectManagerCanViewProject
  } = useProjectPermissions();

  const shouldFetchOnlyMyClients =
    type === DIALOG_FORM_TYPE.PROJECT &&
    !canViewAllProjects &&
    !projectManagerCanViewProject &&
    isClientManager;

  const shouldFetchOnlyMyPrograms =
    type === DIALOG_FORM_TYPE.PROJECT &&
    !canViewAllProjects &&
    !projectManagerCanViewProject &&
    isProgramManager;

  const canEditOnlyMyProjects =
    type === DIALOG_FORM_TYPE.PROJECT
      ? isProjectManager && hasEditProjectPermission && !canEditAllProjects
      : false;

  const showProgramDropdown =
    type === DIALOG_FORM_TYPE.CLIENT ||
    type === DIALOG_FORM_TYPE.PORTFOLIO ||
    (type === DIALOG_FORM_TYPE.PROJECT && hasEditProgramPermission);

  const showClientDropdown =
    type === DIALOG_FORM_TYPE.PROGRAM ||
    type === DIALOG_FORM_TYPE.PORTFOLIO ||
    (type === DIALOG_FORM_TYPE.PROJECT && hasEditClientPermission);

  const showPortfolioDropdown = isPsaPrpPsaPpmMergerEnabled
    ? hasPortfolio &&
      hasEditPortfolioPermission &&
      (type === DIALOG_FORM_TYPE.PROGRAM ||
        type === DIALOG_FORM_TYPE.CLIENT ||
        type === DIALOG_FORM_TYPE.PROJECT)
    : hasEditPortfolioPermission &&
      (type === DIALOG_FORM_TYPE.PROGRAM ||
        type === DIALOG_FORM_TYPE.CLIENT ||
        type === DIALOG_FORM_TYPE.PROJECT);

  const projectLeaderApprovalRequired = useMemo(
    () => (
      <Checkbox
        checked={values.isProjectLeaderApprovalRequired}
        name="isProjectLeaderApprovalRequired"
        onChange={onProjectManagerApprovalChange}
        color="primary"
        value="primary"
      />
    ),
    [onProjectManagerApprovalChange, values.isProjectLeaderApprovalRequired]
  );

  return (
    <form>
      <DialogContent className={classes.content}>
        {loading ? (
          <div className={loadingClass.loader}>
            <CircularProgress size={24} className={loadingClass.progress} />
          </div>
        ) : (
          <Grid container spacing={2}>
            {unhandledError && (
              <Grid item xs={12}>
                <Typography
                  component="div"
                  variant="caption"
                  color="error"
                  gutterBottom
                >
                  {unhandledError}
                </Typography>
              </Grid>
            )}
            {isPsaPrpPsaPpmMergerEnabled ? (
              <Grid item xs={12}>
                <ProjectTemplateDropdown
                  value={values.projectTemplate}
                  onChange={onProjectTemplateChange}
                  hasError={Boolean(errors.projectTemplate)}
                  helperText={errors.projectTemplate}
                />
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <TextField
                variant="filled"
                data-qe-id="AddProjectName"
                id="name"
                label={intl.formatMessage({ id: 'addProject.name' })}
                value={values.name}
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                autoFocus
                fullWidth
                required={isNameRequired}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="filled"
                data-qe-id="AddProjectCode"
                id="code"
                label={intl.formatMessage({ id: 'addProject.code' })}
                value={values.code}
                onChange={handleChange}
                error={Boolean(errors.code)}
                helperText={errors.code}
                fullWidth
                required={isCodeRequired}
              />
            </Grid>
            <Grid item xs={12}>
              <DateRangeFields
                startDate={values.startDate}
                endDate={values.endDate}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
              />
            </Grid>
            {me.featureFlags?.isPsaEarnedRevenueEnabled && (
              <Grid item xs={12}>
                <EarnedRevenueDropdown
                  name="earnedRevenueScript"
                  onChange={onEarnedRevenueScriptChange}
                  value={values.earnedRevenueScript}
                />
              </Grid>
            )}
            {showPortfolioDropdown && (
              <Grid item xs={12}>
                <PortfoliosDropdown
                  dataQeId="AddProjectPortfolioDropdown"
                  value={values.portfolio}
                  onChange={onPortfolioChange}
                  hasError={Boolean(errors.portfolio)}
                  helperText={errors.portfolio}
                />
              </Grid>
            )}
            {showClientDropdown && (
              <Grid item xs={12}>
                <ClientDropdown
                  variant="filled"
                  dataQeId="AddProjectClientDropdown"
                  value={values.client}
                  onChange={onClientAndCurrencyChange}
                  TextFieldProps={clientTextFieldProps}
                  shouldFetchOnlyMyClients={shouldFetchOnlyMyClients}
                />
              </Grid>
            )}
            {showProgramDropdown && (
              <Grid item xs={12}>
                <ProgramDropdownForProject
                  variant="filled"
                  dataQeId="AddProjectProgramDropdown"
                  value={values.program}
                  onChange={onProgramChange}
                  TextFieldProps={programTextFieldProps}
                  shouldFetchOnlyMyPrograms={shouldFetchOnlyMyPrograms}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <ProjectManagerDropdown
                dataQeId="AddProjectProjectManagerDropdown"
                value={values.projectManager}
                onChange={onProjectManagerChange}
                disabled={canEditOnlyMyProjects}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={projectLeaderApprovalRequired}
                label={formatMessage({
                  id: 'projectBasicInfo.pmApprovalRequired'
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TimeAndExpenseEntryTypeDropdown
                fullWidth
                variant="filled"
                showBillingOptions={
                  isPsaPrpPsaPpmMergerEnabled
                    ? hasBilling && me.hasViewProjectBillingOptions
                    : me.hasViewProjectBillingOptions
                }
                value={values.timeAndExpenseEntryType.id}
                onChange={onTimeAndExpenseEntryChange}
                label={formatMessage({
                  id: 'addProject.allowEntryWithoutTasks'
                })}
                dataQeId="AllowEntryWithoutTasksDropdown"
              />
            </Grid>
            {values.customFields && (
              <Grid item xs={12}>
                <RequiredCustomFields
                  customFieldValues={values.customFields}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-qe-id="AddProjectCancel"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <LoadingButton
          data-qe-id="AddProjectAdd"
          onClick={handleSubmit}
          color="primary"
          disabled={hasErrors || isSubmitting || loading}
          isLoading={isSubmitting}
          type="submit"
        >
          {intl.formatMessage({ id: 'addProject.addProject' })}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

AddProjectDialogForm.propTypes = {
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default AddProjectDialogForm;
