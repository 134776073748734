import React, { useMemo } from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  BillStatus,
  BillingTransactionBalanceStatus,
  BillingTransactionType
} from '~/types';
import { ChipLabel } from '../common/ChipLabel';
import { getStatusStyles } from './util';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const getConsolidatedStatus = (balanceStatus, billStatus) => {
  if (billStatus === BillStatus.Billed) {
    if (balanceStatus === BillingTransactionBalanceStatus.FullyAllocated) {
      return 'ISSUED_PAID';
    }
    if (balanceStatus === BillingTransactionBalanceStatus.PartiallyAllocated) {
      return 'ISSUED_PARTIALLYPAID';
    }

    return 'ISSUED_UNPAID';
  }

  return billStatus;
};

export const BillingTransactionStatusBadge = ({
  balanceStatus,
  transactionType,
  billStatus = null,
  draftSubStatus = null
}) => {
  const classes = useStyles();
  const consolidatedStatus = useMemo(() => {
    return getConsolidatedStatus(balanceStatus, billStatus);
  }, [balanceStatus, billStatus]);

  return (
    <>
      {transactionType === BillingTransactionType.Bill ? (
        <Chip
          style={getStatusStyles(
            transactionType,
            consolidatedStatus,
            draftSubStatus
          )}
          label={ChipLabel(transactionType, consolidatedStatus, draftSubStatus)}
          data-qe-id="statusBadge"
        />
      ) : (
        <Chip
          style={getStatusStyles(transactionType, balanceStatus)}
          label={ChipLabel(transactionType, balanceStatus)}
          classes={classes}
          data-qe-id="statusBadge"
        />
      )}
    </>
  );
};

BillingTransactionStatusBadge.propTypes = {
  balanceStatus: PropTypes.string,
  transactionType: PropTypes.string,
  billStatus: PropTypes.string,
  draftSubStatus: PropTypes.object
};

export default BillingTransactionStatusBadge;
