import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  OverviewChartLoading,
  OverviewChart
} from '~/modules/common/charts/dashboard';
import { useMeContext } from '~/modules/me';
import { useFilteredProjectTotalsQuery } from '../../ProjectList/useFilteredProjectTotalsQuery';

export const ProjectsKPI = ({ chartDataInput: { dateRange } }) => {
  const { loading, totals } = useFilteredProjectTotalsQuery({
    dateRange
  });

  const hasProfitAmount =
    get(totals, 'totalActualRevenue.amount', null) !== null &&
    get(totals, 'totalActualCost.amount', null) !== null;

  const {
    costAmountSummary,
    timeEnteredSummary,
    revenueAmountSummary,
    profitAmountSummary
  } = useMemo(
    () => ({
      costAmountSummary: {
        costActual: totals && totals.totalActualCost
      },
      timeEnteredSummary: {
        timeEnteredActual: totals && totals.totalActualHours
      },
      revenueAmountSummary: {
        revenueActual: totals && totals.totalActualRevenue
      },
      profitAmountSummary: {
        profitActual: hasProfitAmount
          ? {
              amount:
                totals.totalActualRevenue.amount -
                totals.totalActualCost.amount,
              currency: totals.totalActualCost.currency
            }
          : null
      }
    }),
    [totals, hasProfitAmount]
  );

  const { permissionsMap } = useMeContext();

  const canViewCostData = Boolean(
    permissionsMap['urn:replicon:project-action:view-cost-data']
  );
  const canViewBillingData = Boolean(
    permissionsMap['urn:replicon:project-action:view-billing-data']
  );

  return loading ? (
    <OverviewChartLoading />
  ) : (
    <OverviewChart
      costAmountSummary={costAmountSummary}
      timeEnteredSummary={timeEnteredSummary}
      revenueAmountSummary={revenueAmountSummary}
      profitAmountSummary={profitAmountSummary}
      showCost={canViewCostData}
      showRevenue={canViewBillingData}
    />
  );
};

ProjectsKPI.propTypes = {
  chartDataInput: PropTypes.object.isRequired
};

export default ProjectsKPI;
