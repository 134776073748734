import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const UPDATE_PROJECT_CONTRACT_AMOUNT = gql`
  mutation UpdateProject($projectInput: ProjectInput!) {
    updateProject2(projectInput: $projectInput) {
      id
      keyValues {
        keyUri
        value
      }
    }
  }
`;

export const useUpdateProjectContractAmount = ({ projectUri }) => {
  const [updateContractAmount] = useMutation(UPDATE_PROJECT_CONTRACT_AMOUNT);

  return {
    updateProjectContractAmount: async ({ updatedContractAmount }) => {
      await updateContractAmount({
        variables: {
          projectInput: {
            projectUri,
            keyValues: [
              {
                keyUri:
                  'urn:replicon:project-key-value-key:total-milestone-contract-amount',
                value:
                  updatedContractAmount &&
                  updatedContractAmount.amount !== null &&
                  updatedContractAmount.amount !== undefined
                    ? {
                        number: updatedContractAmount.amount
                      }
                    : null
              },
              {
                keyUri:
                  'urn:replicon:project-key-value-key:total-milestone-contract-currency',
                value:
                  updatedContractAmount &&
                  updatedContractAmount.currency &&
                  updatedContractAmount.currency.id
                    ? {
                        uri: updatedContractAmount.currency.id
                      }
                    : null
              }
            ]
          }
        }
      });
    }
  };
};

export default useUpdateProjectContractAmount;
