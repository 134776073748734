import React from 'react';
import PropTypes from 'prop-types';

import { FileUpload } from '~/modules/common/components/FileUpload';
import useAvatarUpload from './hooks/useAvatarUpload';

const ACCEPTED_FILE_TYPES = [
  '.bmp',
  '.heic',
  '.heif',
  '.jpeg',
  '.jpg',
  '.png',
  '.tif',
  '.tiff',
  '.webp'
];

const AvatarUploadDialogContent = ({ userUri, onUploadComplete }) => {
  const { onUpload, isUploading } = useAvatarUpload({
    userUri,
    onUploadComplete
  });

  return (
    <FileUpload
      acceptedFileTypes={ACCEPTED_FILE_TYPES}
      onFileUpload={onUpload}
      maximumFileSize={5}
      loading={isUploading}
      disabled={isUploading}
      showStagedFileList={false}
      fileTypeErrorMessageId="fileWebAddressDialog.invalidAvatarFileTypeError"
    />
  );
};

AvatarUploadDialogContent.propTypes = {
  userUri: PropTypes.string.isRequired,
  onUploadComplete: PropTypes.func
};

export default AvatarUploadDialogContent;
