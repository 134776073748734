import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { OkButton } from '~/modules/common/components';

export const MoveTaskErrorDialog = ({ open, onClose, error }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <FormattedMessage id="moveTaskErrorDialog.title" />
    </DialogTitle>
    <DialogContent>
      <Typography variant="body2">{error}</Typography>
    </DialogContent>
    <DialogActions>
      <OkButton onClick={onClose} />
    </DialogActions>
  </Dialog>
);

MoveTaskErrorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  error: PropTypes.string
};

export default MoveTaskErrorDialog;
