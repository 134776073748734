import React, { useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  removeAllButton: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2)
    },
    [theme.breakpoints.up(400)]: {
      whiteSpace: 'nowrap'
    }
  },
  loadingButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-2),
      marginLeft: theme.spacing(0.5)
    }
  }
}));

export const AdvancedRateCardActionComponent = ({
  onRemove,
  saveable,
  isSaving,
  onCancel,
  onSave,
  validateForm,
  actionComponentCustomProps
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    loadingMore,
    addButtonResourceKey,
    onAddEntryClick,
    readOnly
  } = actionComponentCustomProps;

  const handleSave = useCallback(async () => {
    if (validateForm) {
      const errors = await validateForm();

      if (Object.keys(errors).length) return;
    }
    onSave();
  }, [onSave, validateForm]);

  const removeAllRates = useCallback(() => {
    if (onRemove) onRemove();
  }, [onRemove]);

  return (
    <>
      <Grid item xs={12} container spacing={0}>
        <Grid
          item
          xs={6}
          sm={7}
          md={6}
          container
          justifyContent="flex-start"
          spacing={0}
        >
          {!readOnly && addButtonResourceKey && (
            <Button
              color="primary"
              size="small"
              onClick={onAddEntryClick}
              disabled={isSaving || loadingMore}
            >
              <Typography variant="body2">
                {formatMessage(
                  { id: 'advancedRateCard.addDimensionValue' },
                  { displayText: addButtonResourceKey }
                )}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={0}>
        <Grid
          item
          xs={6}
          sm={7}
          md={6}
          container
          justify="flex-start"
          spacing={0}
        >
          {!readOnly && onRemove ? (
            <Button
              color="secondary"
              onClick={removeAllRates}
              className={classes.removeAllButton}
              disabled={isSaving || loadingMore}
              data-qe-id="RemoveAllRates"
            >
              <FormattedMessage id="advancedRateCard.removeAllRates" />
            </Button>
          ) : null}
        </Grid>

        <Grid
          item
          xs={6}
          sm={5}
          md={6}
          container
          justify="flex-end"
          spacing={0}
        >
          <Button
            onClick={onCancel}
            disabled={isSaving || loadingMore}
            data-qe-id="Cancel"
          >
            <FormattedMessage
              id={readOnly ? 'button.close' : 'button.cancel'}
            />
          </Button>
          {!readOnly && (
            <LoadingButton
              color="primary"
              onClick={handleSave}
              className={classes.loadingButton}
              disabled={!saveable || isSaving || loadingMore}
              isLoading={isSaving}
              data-qe-id="Save"
            >
              <FormattedMessage id="button.save" />
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};
AdvancedRateCardActionComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  saveable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  validateForm: PropTypes.func,
  actionComponentCustomProps: PropTypes.object
};

export default AdvancedRateCardActionComponent;
