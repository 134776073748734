import { gql } from 'graphql-tag';

export const ACTIVE_CLIENTS_QUERY = gql`
  query GetActiveClients {
    getClients {
      id
      name
    }
  }
`;
