import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpSharp';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Decimal, Percentage } from '~/modules/common/components';
import { ChartLegend } from '~/modules/common/charts/dashboard';
import { messages } from '../../hooks/useLegends';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'contents'
  },
  label: {
    gridColumnStart: 1,
    padding: theme.spacing(0.5, 2, 0.5, 1),
    display: 'inline-flex',
    '&:hover $helpIcon': {
      opacity: 1
    }
  },
  fte: {
    gridColumnStart: 2,
    padding: theme.spacing(0.5, 2),
    textAlign: 'right'
  },
  hours: {
    gridColumnStart: 3,
    textAlign: 'right',
    padding: theme.spacing(0.5, 2)
  },
  percent: {
    gridColumnStart: 4,
    textAlign: 'right',
    padding: theme.spacing(0.5, 2)
  },
  helpIcon: {
    width: theme.spacing(2),
    color: theme.palette.text.secondary,
    opacity: 0.3,
    verticalAlign: 'middle',
    fontSize: theme.spacing(2.25),
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  toolTip: {
    whiteSpace: 'pre-line',
    lineHeight: 1.5,
    '&:first-line': {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 500
    }
  }
}));

const useLegendStyle = makeStyles(theme => ({
  legendText: {
    color: theme.palette.text.primary
  }
}));

export const ResourceAllocationDialogRow = ({ rowData, showHelp = false }) => {
  const classes = useStyles();
  const intl = useIntl();

  const tooltipMessage =
    rowData.label ===
    intl.formatMessage({
      id: messages.fittingAllocation
    })
      ? 'resourcingOverviewChart.tooltips.fittingAllocation'
      : 'resourcingOverviewChart.tooltips.overAllocated';

  const tooltipContent = useMemo(
    () => <FormattedMessage id={tooltipMessage} />,
    [tooltipMessage]
  );

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <ChartLegend classes={useLegendStyle()} {...rowData} />
        {showHelp && (
          <Tooltip className={classes.toolTip} title={tooltipContent}>
            <HelpIcon className={classes.helpIcon} fontSize="small" />
          </Tooltip>
        )}
      </div>
      <Decimal value={rowData.fteValue} className={classes.fte} />
      <Decimal value={rowData.hoursValue} className={classes.hours} />
      <Percentage value={rowData.percentValue} className={classes.percent} />
    </div>
  );
};

ResourceAllocationDialogRow.propTypes = {
  rowData: PropTypes.object,
  showHelp: PropTypes.bool,
  tooltipId: PropTypes.string
};

export default ResourceAllocationDialogRow;
