import { useLayoutEffect, useRef, useState } from 'react';

const useWaypointOffsetHeight = ({ heightFactor, records }) => {
  const containerRef = useRef();
  const [offsetHeight, setOffsetHeight] = useState(-1200);

  useLayoutEffect(() => {
    if (containerRef.current && containerRef.current.clientHeight > 0) {
      setOffsetHeight(
        -1 * Math.floor(containerRef.current.clientHeight * heightFactor)
      );
    }
  }, [heightFactor, records.length]);

  return {
    offsetHeight,
    containerRef
  };
};

export default useWaypointOffsetHeight;
