import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDeleteResourceAllocation } from '~/modules/resourcing/hooks/useDeleteResourceAllocation';
import { RecallResourceRequestDialogActions } from './components';

export const RecallResourceRequestDialog = ({
  onCancelClick,
  open,
  resourceRequest,
  allocationId,
  onMarkResourceRequestAsSubmitted,
  resourceAllocationIds
}) => {
  const deleteResourceAllocation = useDeleteResourceAllocation({
    projectUri: resourceRequest.projectUri,
    resourceRequestId: resourceRequest.id
  });

  const shouldMarkResourceRequestAsSubmitted =
    resourceAllocationIds.length === 1;

  const onRecallClick = useCallback(async () => {
    await deleteResourceAllocation({
      projectUri: resourceRequest.projectId,
      resourceAllocationId: allocationId,
      resourceRequestId: resourceRequest.id
    });

    if (shouldMarkResourceRequestAsSubmitted) {
      await onMarkResourceRequestAsSubmitted();
    }
    onCancelClick();
  }, [
    deleteResourceAllocation,
    resourceRequest.projectId,
    resourceRequest.id,
    allocationId,
    shouldMarkResourceRequestAsSubmitted,
    onCancelClick,
    onMarkResourceRequestAsSubmitted
  ]);

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      aria-labelledby="recall-resource-dialog-title"
      aria-describedby="recall-resource-dialog-content"
    >
      <DialogTitle id="recall-resource-dialog-title">
        <FormattedMessage id="recallResourceRequestDialog.recallResourcesTitle" />
      </DialogTitle>
      <DialogContent id="recall-resource-dialog-content">
        <DialogContentText>
          <FormattedMessage id="recallResourceRequestDialog.recallMessage" />
        </DialogContentText>
        <DialogContentText>
          <FormattedMessage id="recallResourceRequestDialog.confirmation" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <RecallResourceRequestDialogActions
          onCancelClick={onCancelClick}
          onRecallClick={onRecallClick}
        />
      </DialogActions>
    </Dialog>
  );
};

RecallResourceRequestDialog.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  allocationId: PropTypes.string,
  resourceRequest: PropTypes.object.isRequired,
  onMarkResourceRequestAsSubmitted: PropTypes.func,
  resourceAllocationIds: PropTypes.array
};

export default RecallResourceRequestDialog;
