import {
  PROJECT_DAL,
  PROJECT_PERMISSION,
  USER_ACCESS_ROLE
} from '~/modules/common/enums';
import { hasPermission } from './useHasPermission';

const canViewProjectsRoute = ({ permissionsMap, userAccessRoles }) => {
  const canView = hasPermission(permissionsMap);
  const permissionChecksWithoutDal =
    Boolean(permissionsMap[PROJECT_PERMISSION.VIEW_PROJECT]) &&
    userAccessRoles.some(
      role =>
        role === USER_ACCESS_ROLE.BILLING_MANAGER ||
        role === USER_ACCESS_ROLE.CLIENT_MANAGER ||
        role === USER_ACCESS_ROLE.PROGRAM_MANAGER ||
        role === USER_ACCESS_ROLE.PROJECT_ADMIN ||
        role === USER_ACCESS_ROLE.PROJECT_MANAGER
    );

  return (
    permissionChecksWithoutDal ||
    userAccessRoles.some(
      role =>
        role === USER_ACCESS_ROLE.PORTFOLIO_ADMIN &&
        canView({
          actionUri: PROJECT_PERMISSION.VIEW_PROJECT,
          dataAccessLevelUri: PROJECT_DAL.ALL
        })
    )
  );
};

const canViewVendorRoute = ({
  permissionsMap,
  userAccessRoles,
  featureFlags
}) => {
  const canView = hasPermission(permissionsMap);
  const { isPsaPrpVendorUiEnabled } = featureFlags;

  const permissionCheck = userAccessRoles.some(
    role =>
      role === USER_ACCESS_ROLE.ADMIN &&
      canView({
        actionUri: 'urn:replicon:vendor-action:view-vendor'
      })
  );

  return isPsaPrpVendorUiEnabled && permissionCheck;
};

export const getRoutePermissions = me => {
  const { permissionsMap, userAccessRoles, featureFlags } = me;
  const canView = hasPermission(permissionsMap);

  return {
    canViewProjectRequests: canView({
      actionUri: 'urn:replicon:project-request-action:view-project-request'
    }),
    canViewPortfolios: canView({
      actionUri: 'urn:replicon:portfolio-action:view-portfolio'
    }),
    canViewProjects: canViewProjectsRoute({ permissionsMap, userAccessRoles }),
    canViewVendors: canViewVendorRoute({
      permissionsMap,
      userAccessRoles,
      featureFlags
    })
  };
};
