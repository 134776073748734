import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const GET_PROJECT_REFERENCE = gql`
  query GetProjectReferenceFromSlug($projectSlug: String!) {
    project(projectSlug: $projectSlug) {
      id
      name
    }
  }
`;

export const useProjectReference = ({ projectSlug }) => {
  const { data, loading } = useQuery(GET_PROJECT_REFERENCE, {
    variables: {
      projectSlug
    },
    skip: !projectSlug,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const project = get(data, 'project', {});

  return {
    loading,
    projectUri: project.id,
    projectName: project.name
  };
};

export default useProjectReference;
