import { DateTime } from 'luxon';
import {
  dateToPosition,
  positionToDate
} from '~/modules/common/charts/timeline/calculations';
import CHART_HANDLE_VARIANT_ENUM from './ChartHandleVariantEnum';

const toUtcDateObject = dateString => DateTime.fromISO(dateString).toUTC();

const calculateEstimatedDate = ({
  chartStartDate,
  scale,
  variant,
  value,
  limit,
  sizeDelta,
  down
}) => {
  if (!sizeDelta || !down) return value;

  const estimatedDate = positionToDate({
    chartStartDate,
    scale,
    position: dateToPosition({ chartStartDate, scale, date: value }) + sizeDelta
  });

  if (
    limit &&
    ((variant === CHART_HANDLE_VARIANT_ENUM.LEFT && estimatedDate > limit) ||
      (variant === CHART_HANDLE_VARIANT_ENUM.RIGHT && estimatedDate < limit))
  ) {
    return limit;
  }

  return estimatedDate;
};

export const getEstimatedDate = ({
  delta,
  down,
  value,
  limit,
  variant,
  chartStartDate,
  scale,
  leftComponentWidth
}) => {
  const estimatedDate = calculateEstimatedDate({
    chartStartDate,
    scale,
    variant,
    value: typeof value === 'string' ? toUtcDateObject(value) : value,
    limit: typeof limit === 'string' ? toUtcDateObject(limit) : limit,
    sizeDelta: delta[0],
    down
  });

  return {
    dynamicStyle: {
      transform: `translate3d(${leftComponentWidth +
        (variant === CHART_HANDLE_VARIANT_ENUM.LEFT
          ? dateToPosition({ chartStartDate, scale, date: estimatedDate }) - 16
          : dateToPosition({
              chartStartDate,
              scale,
              date: estimatedDate.plus({ days: 1 })
            }))}px,0px,0)`
    },
    estimatedDate
  };
};

const useEstimatedDate = ({
  delta,
  down,
  value,
  limit,
  variant,
  chartStartDate,
  scale,
  leftComponentWidth
}) => {
  return getEstimatedDate({
    delta,
    down,
    value,
    limit,
    variant,
    chartStartDate,
    scale,
    leftComponentWidth
  });
};

export default useEstimatedDate;
