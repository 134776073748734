import { useCallback } from 'react';
import useDeleteResourceAllocationHandler from './useDeleteResourceAllocationHandler';

const useResourceAllocationActionHandlers = ({
  resourceRequest,
  requestAccessLevel
}) => {
  const {
    deleteResourceAllocationFromProject
  } = useDeleteResourceAllocationHandler();

  const onRemoveAllResources = useCallback(() => {
    resourceRequest.resourceAllocations.forEach(allocation =>
      deleteResourceAllocationFromProject({
        projectUri: resourceRequest.projectUri,
        resourceAllocationId: allocation.id,
        resourceRequestId: allocation.resourceRequestId,
        requestAccessLevel
      })
    );
  }, [
    deleteResourceAllocationFromProject,
    requestAccessLevel,
    resourceRequest.projectUri,
    resourceRequest.resourceAllocations
  ]);

  return {
    onRemoveAllResources
  };
};

export default useResourceAllocationActionHandlers;
