import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, ListItemIcon, ListItemText, Radio } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  radio: {}
}));

const useListStyles = makeStyles(() => ({
  primary: {
    fontWeight: 'bold'
  },
  secondary: {}
}));

const ChangeAllocationUserOptionItem = ({
  value,
  primary,
  secondary,
  onSelect,
  listClasses: overrideListClasses,
  classes: overrideClasses
}) => {
  const classes = useStyles({ classes: overrideClasses });
  const listClasses = useListStyles({ classes: overrideListClasses });

  const handleClick = useCallback(() => {
    if (onSelect) onSelect(value);
  }, [onSelect, value]);

  return (
    <ListItem
      button
      dense={false}
      className={classes.root}
      onClick={handleClick}
    >
      <ListItemIcon>
        <Radio
          color="primary"
          value={value}
          disableRipple
          className={classes.radio}
        />
      </ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={secondary}
        classes={listClasses}
      />
    </ListItem>
  );
};

ChangeAllocationUserOptionItem.propTypes = {
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string,
  secondary: PropTypes.object,
  onSelect: PropTypes.func,
  listClasses: PropTypes.object,
  classes: PropTypes.object
};

export default ChangeAllocationUserOptionItem;
