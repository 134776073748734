import { ListItem } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { ResourceAllocationCard } from './components/ResourceAllocationCard';
import useStyles from './useStyles';

export const MobileResourceAllocationItem = ({
  classes: classesOverride,
  record: allocation
}) => {
  const { project } = useProjectContext();
  const classes = useStyles({ classes: classesOverride });

  const classesList = { root: classes.listItem };
  const contextCurrency = project.budgetedCost && project.budgetedCost.currency;

  return (
    <ListItem
      key={`${allocation.id}-${allocation.user.displayText}`}
      classes={classesList}
      disableGutters
    >
      <div className={classes.root}>
        <ResourceAllocationCard
          resourceAllocation={allocation}
          contextCurrency={contextCurrency}
        />
      </div>
    </ListItem>
  );
};

MobileResourceAllocationItem.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired
};

export default MobileResourceAllocationItem;
