import React, { useMemo, useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import FormControlContext from '@material-ui/core/FormControl/FormControlContext';
import InputAdornment from '@material-ui/core/InputAdornment';

import NumberInput from '../NumberInput';
import { Currency } from './Currency';

export const Money2 = ({
  classes,
  label,
  currencyAriaLabel,
  currencyDataQeId,
  valueDataQeId,
  ariaLabel = '',
  variant = 'filled',
  amount = null,
  currency = null,
  helperText,
  onAmountChange,
  onAmountBlur,
  onCurrencyChange,
  error,
  editable = true,
  currencyEditable = true,
  allowNegative = false,
  autoFocus = false,
  currencyLabel,
  disablePortal,
  numberInputClasses,
  align,
  showStartAdornment = true,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleAmountBlur = useCallback(
    event => {
      setIsFocused(false);
      if (onAmountBlur) onAmountBlur(event);
    },
    [onAmountBlur]
  );

  const handleAmountFocus = useCallback(event => {
    setIsFocused(true);
    event && event.target && event.target.select && event.target.select();
    event.stopPropagation();
  }, []);

  const InputLabelProps = useMemo(
    () => ({
      shrink: Boolean(amount) || amount === 0 || isFocused
    }),
    [amount, isFocused]
  );

  return (
    <NumberInput
      classes={numberInputClasses}
      dataQeId={valueDataQeId}
      allowNegative={allowNegative}
      value={amount}
      onValueChange={onAmountChange}
      onBlur={handleAmountBlur}
      onFocus={handleAmountFocus}
      fullWidth
      label={label}
      variant={variant}
      ariaLabel={ariaLabel}
      error={error}
      editable={editable}
      helperText={helperText}
      InputLabelProps={InputLabelProps}
      align={align}
      startAdornment={
        showStartAdornment ? (
          <InputAdornment position="start">
            <FormControlContext.Provider value={null}>
              {currencyLabel || null}
              <Currency
                dataQeId={currencyDataQeId}
                editable={editable && currencyEditable}
                value={currency}
                ariaLabel={currencyAriaLabel}
                input={
                  <InputBase
                    className={currencyLabel ? classes.currencyRoot : null}
                  />
                }
                onChange={onCurrencyChange}
                variant={variant}
                autoFocus={autoFocus}
                disablePortal={disablePortal}
                className={classes && classes.currency}
              />
            </FormControlContext.Provider>
          </InputAdornment>
        ) : null
      }
      {...rest}
    />
  );
};

Money2.propTypes = {
  classes: PropTypes.object,
  currencyAriaLabel: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
  currencyDataQeId: PropTypes.string,
  valueDataQeId: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  amount: PropTypes.number,
  currency: PropTypes.shape({
    id: PropTypes.string,
    displayText: PropTypes.string
  }),
  onAmountChange: PropTypes.func,
  onAmountBlur: PropTypes.func,
  onCurrencyChange: PropTypes.func,
  editable: PropTypes.bool,
  currencyEditable: PropTypes.bool,
  allowNegative: PropTypes.bool,
  autoFocus: PropTypes.bool,
  ariaLabel: PropTypes.string,
  disablePortal: PropTypes.bool,
  currencyLabel: PropTypes.node,
  numberInputClasses: PropTypes.object,
  align: PropTypes.string,
  showStartAdornment: PropTypes.bool
};

export default Money2;
