import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { deepPure } from '~/util';
import {
  useResourceRequestToolbarContext,
  useSelectedResourceRequestContext
} from '~/modules/projects/resourcing-plan/hooks';
import { useHasPermission } from '~/modules/common/permissions';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useProjectResourcePlanPermissions } from '~/modules/resourcing/hooks';
import { useMeContext } from '~/modules/me';
import { useSessionStorage, getSessionStorage } from '~/modules/common/hooks';
import { RESOURCING_ALLOCATION_MODEL } from '~/modules/common/enums';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import useResourceAllocationChartUserRowActions from '../../hooks/useResourceAllocationChartUserRowActions';
import { GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES } from '../../../../hooks/useFetchResourcePlanUsersAndRoles';
import { getFilterInput } from '../../hooks/useCompletedProjectResourceAllocations';
import {
  LeftItemActions,
  LeftItemContentModel0,
  LeftItemContentModel1
} from './components';

const useStyles = makeStyles(theme => ({
  rolesContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    flexBasis: theme.spacing(17.5),
    minWidth: theme.spacing(12.5),
    borderLeft: `1px solid ${theme.palette.divider}`
  },
  userSelectionWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(0.875),
    padding: theme.spacing(0, 1),
    width: theme.spacing(28.75)
  },
  userSelectionWrapperWithNoActions: {
    width: theme.spacing(31.75),
    paddingLeft: theme.spacing(4)
  },
  rowActions: {
    marginTop: theme.spacing(1)
  },
  readOnlyUser: {
    width: theme.spacing(31.75),
    padding: theme.spacing(0, 1, 0, 4)
  },
  readOnlyUserWithActions: {
    width: theme.spacing(28.75),
    padding: theme.spacing(0, 1)
  }
}));

const useUserStyles = makeStyles(theme => ({
  label: {
    maxWidth: theme.spacing(19)
  }
}));

const useActionsStyles = makeStyles(() => ({
  secondaryActionsButton: {
    marginLeft: 0,
    padding: 0
  },
  actionsPlaceholder: {}
}));

const useModel1ActionsStyles = makeStyles(() => ({
  actionsPlaceholder: {
    width: 0
  }
}));

const useActualsStyles = makeStyles(theme => ({
  roleSelection: {
    paddingBottom: theme.spacing(5)
  },
  roleRowCellReadonly: {
    height: theme.spacing(9)
  }
}));

const useRowStyles = makeStyles(theme => ({
  roleSelection: {
    paddingBottom: theme.spacing(1)
  },
  roleRowCellReadonly: {
    paddingBottom: theme.spacing(1)
  }
}));

const hasReleaseResourcePermissions = ({ permittedActionUris }) => {
  const isEditProject = permittedActionUris.includes(
    'urn:replicon:project-action:edit-project'
  );

  const isEditTeam = permittedActionUris.includes(
    'urn:replicon:project-action:edit-team'
  );

  return isEditProject && isEditTeam;
};

export const ResourceAllocationChartLeftItem2 = ({
  allocatedUser,
  onUserChange,
  handleRemoveResourceAllocation,
  isEditEnabled,
  setSnackBarState,
  autofocusedUserId,
  setAutofocusedUserId,
  isUserRowExpanded,
  onReleaseResourceAllocationSuccess,
  onUserRowExpansionPanelClick
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const userAvailabilityDropdownInputRef = useRef();
  const actualsClasses = useActualsStyles();
  const rowStyles = useRowStyles();
  const userClasses = useUserStyles();
  const {
    dateRange: chartDateRange,
    isResourceActualModeEnabled,
    filter,
    sort
  } = useResourceRequestToolbarContext();
  const { project, resourcingAllocationModel } = useProjectContext();

  const { openResourceRequestDrawer } = useSelectedResourceRequestContext();

  const resourcePlanAllocatedUsersAndRolesCacheVariables = getSessionStorage(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES
  );

  const { storedValue: resourceRequestsQueryVariables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );
  const actionsClasses = useActionsStyles();
  const model1Classes = useModel1ActionsStyles();
  const {
    permissionsMap,
    resourceCostMode,
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = useMeContext();

  const actionsClassesFFOn = useMemo(
    () => ({
      ...actionsClasses,
      ...model1Classes
    }),
    [actionsClasses, model1Classes]
  );

  const {
    isViewAdvancedSettingsEnabled,
    isViewAvailabilitySettingsEnabled
  } = useProjectResourcePlanPermissions(permissionsMap);

  const showTimeOff = Boolean(
    isViewAvailabilitySettingsEnabled && Boolean(chartDateRange)
  );

  const showHolidays = isViewAdvancedSettingsEnabled && Boolean(chartDateRange);

  const chartDisplayDateRange = useMemo(
    () =>
      chartDateRange
        ? {
            startDate: dateToMidnightUTCString(chartDateRange.startDate),
            endDate: dateToMidnightUTCString(chartDateRange.endDate)
          }
        : null,
    [chartDateRange]
  );

  const isEditAllowed =
    useHasPermission({
      actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
    }) && isEditEnabled;

  const {
    resourceAllocationReferencesForUser,
    resourceRequestIdsForUser,
    hasResourceAllocations
  } = allocatedUser.roles.reduce(
    (retVal, { role, resourceAllocationReference }) => {
      if (resourceAllocationReference.length) {
        return {
          resourceAllocationReferencesForUser: [
            ...retVal.resourceAllocationReferencesForUser,
            ...resourceAllocationReference.map(({ id }) => ({
              key: id,
              allocationId: id,
              role
            }))
          ],
          resourceRequestIdsForUser: [
            ...retVal.resourceRequestIdsForUser,
            ...resourceAllocationReference.reduce(
              (accRef, currRef) => [...accRef, ...currRef.resourceRequestIds],
              []
            )
          ],
          hasResourceAllocations: true
        };
      }

      return {
        ...retVal,
        resourceAllocationReferencesForUser: [
          ...retVal.resourceAllocationReferencesForUser,
          { key: role?.id || `${allocatedUser.user.uri}-no-role`, role }
        ]
      };
    },
    {
      resourceAllocationReferencesForUser: [],
      resourceRequestIdsForUser: [],
      hasResourceAllocations: false
    }
  );

  const {
    actions,
    showViewMultipleResourceRequestsDialog,
    onViewMultipleResourceRequestsDialogClose,
    releaseAllocationLoading,
    showReleaseResourcesDialog,
    closeReleaseResourcesDialog,
    releaseResourceAllocation
  } = useResourceAllocationChartUserRowActions({
    resourceRequestIds: resourceRequestIdsForUser,
    userId: allocatedUser.user.id,
    userName: allocatedUser.user.displayText,
    handleRemoveResourceAllocation,
    showReleaseResourceAction:
      hasResourceAllocations &&
      hasReleaseResourcePermissions({
        permittedActionUris: project.permittedActionUris
      }),
    resourcePlanAllocatedUsersAndRolesCacheVariables,
    allocatedUserCacheUpdateProps: {
      filter: getFilterInput(filter),
      projectId: project.id,
      chartDateRange: chartDisplayDateRange,
      showTimeOff,
      showHolidays,
      sort,
      resourceCostMode
    },
    resourceRequestCacheUpdateProps: {
      resourceRequestsQueryVariables,
      projectId: project.id,
      showHolidays,
      showTimeOff
    },
    setSnackBarState
  });

  const onBlur = useCallback(() => {
    setAutofocusedUserId(null);
  }, [setAutofocusedUserId]);

  useEffect(() => {
    if (autofocusedUserId === allocatedUser.user.uri) {
      userAvailabilityDropdownInputRef.current?.focus();
    }
  }, [allocatedUser.user.uri, autofocusedUserId]);
  const qeIdTag = `${allocatedUser.user.displayText}_AllocationRow`;

  const actionsComponentProps = useMemo(
    () => ({
      onReleaseResourceAllocationSuccess,
      actionsClasses: isPsaRmpTaskAllocation1Enabled
        ? actionsClassesFFOn
        : actionsClasses,
      showReleaseResourcesDialog,
      project,
      releaseResourceAllocation,
      closeReleaseResourcesDialog,
      releaseAllocationLoading,
      showViewMultipleResourceRequestsDialog,
      onViewMultipleResourceRequestsDialogClose,
      openResourceRequestDrawer,
      actions,
      qeIdTag,
      allocatedUser
    }),
    [
      onReleaseResourceAllocationSuccess,
      actions,
      actionsClasses,
      actionsClassesFFOn,
      allocatedUser,
      closeReleaseResourcesDialog,
      isPsaRmpTaskAllocation1Enabled,
      onViewMultipleResourceRequestsDialogClose,
      openResourceRequestDrawer,
      project,
      qeIdTag,
      releaseAllocationLoading,
      releaseResourceAllocation,
      showReleaseResourcesDialog,
      showViewMultipleResourceRequestsDialog
    ]
  );

  return (
    <>
      {resourcingAllocationModel === RESOURCING_ALLOCATION_MODEL.MODEL_0 ? (
        <LeftItemContentModel0
          hasResourceAllocations={hasResourceAllocations}
          isEditAllowed={isEditAllowed}
          classes={classes}
          userClasses={userClasses}
          chartDateRange={chartDateRange}
          onUserChange={onUserChange}
          allocatedUser={allocatedUser}
          userAvailabilityDropdownInputRef={userAvailabilityDropdownInputRef}
          onBlur={onBlur}
          formatMessage={formatMessage}
          resourceAllocationReferencesForUser={
            resourceAllocationReferencesForUser
          }
          isResourceActualModeEnabled={isResourceActualModeEnabled}
          actualsClasses={actualsClasses}
          rowStyles={rowStyles}
          ActionsComponent={LeftItemActions}
          actionsComponentProps={actionsComponentProps}
          qeIdTag={qeIdTag}
          actions={actions}
        />
      ) : (
        <LeftItemContentModel1
          allocatedUser={allocatedUser}
          isUserRowExpanded={isUserRowExpanded}
          ActionsComponent={LeftItemActions}
          actionsComponentProps={actionsComponentProps}
          onUserRowExpansionPanelClick={onUserRowExpansionPanelClick}
        />
      )}
    </>
  );
};

ResourceAllocationChartLeftItem2.propTypes = {
  allocatedUser: PropTypes.object,
  onUserChange: PropTypes.func.isRequired,
  handleRemoveResourceAllocation: PropTypes.func,
  isEditEnabled: PropTypes.bool,
  setSnackBarState: PropTypes.func,
  autofocusedUserId: PropTypes.string,
  setAutofocusedUserId: PropTypes.func,
  isUserRowExpanded: PropTypes.bool,
  onUserRowExpansionPanelClick: PropTypes.func,
  onReleaseResourceAllocationSuccess: PropTypes.func
};

export default deepPure(ResourceAllocationChartLeftItem2);
