import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const GET_CURRENCIES_QUERY = gql`
  query getCurrencies {
    currencies {
      id
      symbol
    }
  }
`;

export const useGetCurrenciesQuery = () => {
  const { data, loading, error } = useQuery(GET_CURRENCIES_QUERY);

  const currencies = get(data, 'currencies', []);

  return {
    data,
    loading,
    error,
    currencies
  };
};
