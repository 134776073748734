import { useMeContext } from '~/modules/me/useMeContext';
import {
  PROJECT_DAL,
  PROJECT_PERMISSION,
  USER_ACCESS_ROLE
} from '~/modules/common/enums';

export const useCopyProjectPermissions = () => {
  const { permissionsMap, userAccessRoles } = useMeContext();
  const editProjectPermission = permissionsMap[PROJECT_PERMISSION.EDIT_PROJECT];

  return {
    canCopyProject:
      Boolean(
        editProjectPermission &&
          editProjectPermission.dataAccessLevelUris.some(
            dal =>
              dal === PROJECT_DAL.PROJECT_MANAGER ||
              dal === PROJECT_DAL.PROJECT_ADMIN
          )
      ) ||
      Boolean(
        userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_ADMIN) &&
          editProjectPermission &&
          editProjectPermission.dataAccessLevelUris.includes(PROJECT_DAL.ALL)
      ),
    hasEditClientPermission: Boolean(
      permissionsMap[PROJECT_PERMISSION.EDIT_CLIENT]
    ),
    hasEditProgramPermission: Boolean(
      permissionsMap[PROJECT_PERMISSION.EDIT_PROGRAM]
    )
  };
};
