import { IconButton, Grid } from '@material-ui/core';
import { AddCircleSharp, RemoveCircleSharp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { DateField, Money2 } from '~/modules/common/components';
import { toRepliconDate } from '~/modules/common/dates/convert';
import UserDropdown from '~/modules/common/components/UserDropdown';
import RolesMenuListDropdown from '~/modules/common/components/RolesMenuListDropdown';
import { useHasPermission } from '~/modules/common/permissions';
import { useErrors } from '../../../hooks/useErrors';
import { useRateEditHandlers } from '../../../hooks/useRateEditHandlers';
import { useStyles } from './styles';
import { ResourceRateDropDown } from './ResourceRateDropDown';

const resourceLabels = {
  effectiveDate: <FormattedMessage id="rateCard.effectiveDate" />,
  billingRate: <FormattedMessage id="rateCard.billingRate" />,
  role: <FormattedMessage id="rateCard.role" />,
  resource: <FormattedMessage id="rateCard.resource" />
};

export const ScheduleEntryRow = ({
  scheduleEntry,
  onRemoveRateEntry,
  onAddEntry,
  rateTypeReference,
  rateTypeKey,
  rateTypeReferenceKey,
  entryIndex,
  rateIndex,
  errors,
  projectSlug,
  setFieldValue,
  isMobile,
  context
}) => {
  const { formatMessage } = useIntl();
  const ariaLabel = formatMessage({
    id: 'rateTable.rateAmount'
  });

  const classes = useStyles();
  const canCreateRole = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:edit-project-role'
  });
  const removeRateEntryHandler = useCallback(
    () => onRemoveRateEntry(scheduleEntry.entryId),
    [scheduleEntry, onRemoveRateEntry]
  );

  const rateEditHandlers = useRateEditHandlers({
    setFieldValue,
    rateTypeKey,
    rateTypeReferenceKey,
    rateIndex,
    entryIndex
  });

  const amountStyles = useMemo(
    () => ({
      adornmentRoot: classes.adornmentRoot,
      currencyRoot: classes.currencyRoot
    }),
    [classes.adornmentRoot, classes.currencyRoot]
  );

  const {
    isNewEntry,
    effectiveDate,
    amount: { amount, currency }
  } = scheduleEntry || {};

  const { rateTypeValidationErrors, dateValidationErrors } = useErrors({
    errors,
    errorKey: rateTypeKey,
    index: rateIndex,
    entryId: scheduleEntry && scheduleEntry.entryId,
    rateTypeId: rateTypeReference && rateTypeReference.id,
    rateTypeReferenceKey
  });

  return (
    <Grid
      container
      spacing={isMobile || rateTypeKey === 'projectRate' ? 1 : 2}
      data-qe-id={`${rateTypeKey}_${rateIndex}`}
    >
      <Grid item xs={11} sm={11} md={rateTypeKey === 'projectRate' ? 0 : 4}>
        {!entryIndex && rateTypeKey === 'userRates' && (
          <div className={classes.userContainer}>
            {context === 'project' ? (
              <ResourceRateDropDown
                value={rateTypeReference}
                projectSlug={projectSlug}
                onUserChange={rateEditHandlers.onUserChange}
                {...rateTypeValidationErrors()}
              />
            ) : (
              <UserDropdown
                value={rateTypeReference}
                label={resourceLabels.resource}
                onChange={rateEditHandlers.onUserChange}
                {...rateTypeValidationErrors()}
              />
            )}
          </div>
        )}
        {!entryIndex && rateTypeKey === 'roleRates' && (
          <div className={classes.roleContainer}>
            <RolesMenuListDropdown
              showSelectRole
              label={resourceLabels.role}
              showNoRole={!canCreateRole}
              assignedRole={rateTypeReference}
              onChange={rateEditHandlers.onRoleChange}
              variant="filled"
              autoFocus={rateIndex === 0}
              refetchActiveRoles
              {...rateTypeValidationErrors()}
            />
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={5}
        sm={5}
        md={rateTypeKey === 'projectRate' ? 6 : 3}
        className={classNames(
          rateTypeKey === 'projectRate' && classes.dateFieldContainer
        )}
      >
        {isNewEntry || effectiveDate ? (
          <DateField
            className={classes.dateField}
            editable
            clearable={false}
            label={resourceLabels.effectiveDate}
            value={toRepliconDate(effectiveDate)}
            onChange={rateEditHandlers.onEffectiveDateChange}
            {...dateValidationErrors()}
            ariaLabel={formatMessage({
              id: 'rateCard.effectiveDate'
            })}
          />
        ) : (
          <div className={classes.initialRate}>
            <FormattedMessage id="rateTable.initialRate" />
          </div>
        )}
      </Grid>
      <Grid
        className={classNames(
          classes.amountContainer,
          rateTypeKey === 'projectRate' && classes.padding
        )}
        item
        xs={6}
        sm={6}
        md={rateTypeKey === 'projectRate' ? 5 : 4}
      >
        <Money2
          classes={amountStyles}
          className={classes.amountField}
          amount={amount}
          label={resourceLabels.billingRate}
          currency={currency}
          onAmountChange={rateEditHandlers.onAmountChange}
          onCurrencyChange={rateEditHandlers.onCurrencyChange}
          ariaLabel={ariaLabel}
        />
      </Grid>
      <Grid
        item
        xs={1}
        className={classNames(rateTypeKey === 'projectRate' && classes.padding)}
      >
        {isNewEntry || effectiveDate ? (
          <IconButton
            className={classes.removeButton}
            onClick={removeRateEntryHandler}
            data-qe-id="removeRateFromRow"
            aria-label={formatMessage({ id: 'rateTable.removeRateFromRow' })}
          >
            <RemoveCircleSharp />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            className={classes.addButton}
            onClick={onAddEntry}
            data-qe-id="addRateToRow"
            aria-label={formatMessage({ id: 'rateTable.addRateToRow' })}
          >
            <AddCircleSharp />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

ScheduleEntryRow.propTypes = {
  scheduleEntry: PropTypes.object,
  onRemoveRateEntry: PropTypes.func,
  onAddEntry: PropTypes.func,
  rateTypeReference: PropTypes.object,
  rateTypeReferenceKey: PropTypes.string,
  entryIndex: PropTypes.number,
  rateIndex: PropTypes.number,
  rateTypeKey: PropTypes.string,
  errors: PropTypes.object,
  projectSlug: PropTypes.string,
  setFieldValue: PropTypes.func,
  isMobile: PropTypes.func,
  context: PropTypes.string
};

export default ScheduleEntryRow;
