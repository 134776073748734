import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import get from 'lodash.get';
import { debounce, scope } from '~/modules/common/debounce';

export const GET_BILL_DATA_CALCULATION_STATUS = gql`
  query getBillDataCalculationStatus($billId: String!) {
    isBillDataReflectedInAvailableToBillData(billId: $billId)
  }
`;
export const useTrackBillDataCalculation = ({
  billId,
  refetchAvailableToBillTotals
}) => {
  const apolloClient = useApolloClient();
  const trackBillDataCalculation = useCallback(async () => {
    const { data } = await apolloClient.query({
      query: GET_BILL_DATA_CALCULATION_STATUS,
      variables: {
        billId
      },
      fetchPolicy: 'network-only'
    });
    const status = get(data, 'isBillDataReflectedInAvailableToBillData', false);

    if (status) {
      await refetchAvailableToBillTotals();
    } else {
      debounce(
        scope(),
        async () => {
          await trackBillDataCalculation();
        },
        500
      );
    }
  }, [apolloClient, billId, refetchAvailableToBillTotals]);

  return {
    trackBillDataCalculation
  };
};

export default useTrackBillDataCalculation;
