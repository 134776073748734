import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import {
  FormControlLabel,
  makeStyles,
  Grid,
  Checkbox,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { StickyHeader } from '~/modules/common/components';
import ChartDateRangePeriod from '~/modules/common/charts/dashboard/ChartDateRangePeriod';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  settings: {
    marginLeft: 'auto'
  },
  title: {
    margin: 'auto'
  },
  container: {
    height: theme.spacing(8.5)
  }
}));

export const ExpenseBillPlanTableHeader = ({
  showActuals,
  onPrevious,
  onNext,
  scale,
  dateRange,
  setDate,
  viewSummary,
  resourceLabels,
  onShowActualsChange
}) => {
  const classes = useStyles();

  const showActualsCheckbox = useMemo(
    () => (
      <Checkbox
        onChange={onShowActualsChange}
        checked={showActuals}
        color="primary"
      />
    ),
    [onShowActualsChange, showActuals]
  );

  return (
    <StickyHeader>
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} data-qe-id="ExpenseCode">
          <div className={classes.flex}>
            <ChartDateRangePeriod
              onPreviousClick={onPrevious}
              onNextClick={onNext}
              scale={scale}
              dateRange={dateRange}
              setDateRange={setDate}
              setDateValue
              scaleFormat
            />
            {!viewSummary ? (
              <div className={classes.settings}>
                <FormControlLabel
                  value="start"
                  control={showActualsCheckbox}
                  label={resourceLabels.showActualsCheckboxLabel}
                  labelPlacement="start"
                  data-qe-id="ShowActual"
                />
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.flex}>
            <Typography variant="subtitle1" className={classes.title}>
              <FormattedMessage
                id={
                  showActuals
                    ? 'expenseBillPlan.estimatesAndActuals'
                    : 'expenseBillPlan.estimates'
                }
              />
            </Typography>
          </div>
        </Grid>
      </Grid>
    </StickyHeader>
  );
};

ExpenseBillPlanTableHeader.propTypes = {
  showActuals: PropTypes.bool,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  scale: PropTypes.string,
  dateRange: PropTypes.object,
  setDate: PropTypes.func,
  viewSummary: PropTypes.bool,
  resourceLabels: PropTypes.object,
  onShowActualsChange: PropTypes.func
};

export default ExpenseBillPlanTableHeader;
