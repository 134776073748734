import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useTimeAndExpenseThemeContext } from '../../TimeAndExpenseDetailsThemeContextProvider';

export const useStyles = makeStyles(theme => ({
  header: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderRight: `solid 1px ${theme.palette.divider}`,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    minHeight: '41px',
    position: 'sticky',
    top: '96px'
  }
}));

export const GroupedHeadersPlaceholders = ({ record, label = '' }) => {
  const classes = useStyles();
  const themeClasses = useTimeAndExpenseThemeContext();

  return (
    <div
      key={label}
      className={classNames(classes.header, themeClasses.header)}
    >
      {label}
    </div>
  );
};

GroupedHeadersPlaceholders.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string
};

export const GroupedHeaders = ({ settings, records, record, index }) => {
  const classes = useStyles();
  const themeClasses = useTimeAndExpenseThemeContext();

  const { label, tooltip } = settings.groupings.formatters[
    settings.groupings.values[0]
  ](record.groupings);

  return (
    <Tooltip title={tooltip} aria-label={label}>
      <div
        key={label}
        className={classNames(classes.header, themeClasses.header)}
      >
        {label}
      </div>
    </Tooltip>
  );
};

GroupedHeaders.propTypes = {
  settings: PropTypes.object,
  records: PropTypes.array,
  record: PropTypes.object,
  index: PropTypes.number
};

export default GroupedHeaders;
