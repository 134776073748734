import { DateTime } from 'luxon';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import {
  getUserPreferenceWeekStart,
  getUserPreferenceEndOfWeekDate
} from '~/modules/common/dates/dayOfWeek';
import { PERIOD_SCALE_ENUM } from '../periodScale';

export const getPeriodDateRangeByScale = ({ me, start, scale }) => {
  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return {
      start,
      end: start,
      label: start.toLocaleString(DateTime.DATE_MED),
      key: `${scale}-${start.toISODate()}`
    };
  }

  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    const startDate = getUserPreferenceWeekStart(me, start);
    const endDate = getUserPreferenceEndOfWeekDate(
      me?.workWeekStartDay?.uri,
      start
    );

    return {
      start: startDate,
      end: endDate,
      label: shortDateRangeFormat({ start: startDate, end: endDate }),
      key: `${scale}-${startDate.toISODate()}`
    };
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    const startDate = start.startOf('quarter');

    return {
      start: startDate,
      end: start.endOf('quarter'),
      label: `Q${startDate.toFormat('q yyyy')}`,
      key: `${scale}-${startDate.toISODate()}`
    };
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    const startDate = start.startOf('year');

    return {
      start: startDate,
      end: start.endOf('year'),
      label: startDate.toLocaleString({ year: 'numeric' }),
      key: `${scale}-${startDate.toISODate()}`
    };
  }

  const startDate = DateTime.utc(start.year, start.month, 1, 0, 0, 0, 0);

  return {
    start: startDate,
    end: DateTime.utc(start.year, start.month, start.daysInMonth, 0, 0, 0, 0),
    label: startDate.toLocaleString({ month: 'short', year: 'numeric' }),
    key: `${PERIOD_SCALE_ENUM.MONTHS}-${startDate.toISODate()}`
  };
};

export const getNextPeriodStartDateByScale = ({ me, start, scale }) => {
  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return start.plus({ days: 1 });
  }

  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    const startDate = getUserPreferenceWeekStart(me, start);

    return startDate.plus({ weeks: 1 });
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    return start.plus({ months: 3 }).startOf('quarter');
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    return start.plus({ year: 1 }).startOf('year');
  }

  return start.plus({ months: 1 });
};

export const getRangeEndDateByScale = ({ me, end, scale }) => {
  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return end;
  }

  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    const endDate = getUserPreferenceEndOfWeekDate(
      me?.workWeekStartDay?.uri,
      end
    );

    return endDate;
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    return end.endOf('quarter');
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    return end.endOf('year');
  }

  return DateTime.utc(end.year, end.month, end.daysInMonth, 0, 0, 0, 0);
};

export const getRangeStartDateByScale = ({ start, scale }) => {
  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return start;
  }

  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    const lastSundayOffset = start.weekday < 7 ? start.weekday : 0;

    return start.minus({ days: lastSundayOffset });
  }

  if (scale === PERIOD_SCALE_ENUM.QUARTERS) {
    return start.startOf('quarter');
  }

  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    return start.startOf('year');
  }

  return DateTime.utc(start.year, start.month, 1, 0, 0, 0, 0);
};
