import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const UPDATE_TASK_RESOURCE_ALLOCATIONS = gql`
  mutation putTaskResourceEstimate($input: PutTaskResourceEstimateInput!) {
    putTaskResourceEstimate(input: $input) {
      taskResourceEstimateId
    }
  }
`;

export const usePutTaskResourceEstimate = () => {
  const [putTaskResourceEstimate, { data, loading }] = useMutation(
    UPDATE_TASK_RESOURCE_ALLOCATIONS
  );

  const putTaskResourceEstimateCallback = useCallback(
    resourceEstimate =>
      putTaskResourceEstimate({
        variables: {
          input: resourceEstimate
        }
      }),
    [putTaskResourceEstimate]
  );

  return {
    putTaskResourceEstimate: putTaskResourceEstimateCallback,
    data,
    loading
  };
};

export default usePutTaskResourceEstimate;
