import React from 'react';
import PropTypes from 'prop-types';
import { CostCenterIcon } from '~/modules/common/components';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupIconField from './GroupIconField';

export const ResourceRequestCostCenterField = ({
  value,
  costCenterSettings
}) => (
  <GroupIconField
    value={value}
    GroupIcon={CostCenterIcon}
    groupSettings={costCenterSettings}
    labelKey="resourceRequestItem.costCenter"
  />
);

ResourceRequestCostCenterField.propTypes = {
  value: PropTypes.object,
  costCenterSettings: PropTypes.object.isRequired
};
export default withGroupSettings(
  'costCenter',
  'costCenterSettings'
)(ResourceRequestCostCenterField);
