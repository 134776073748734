/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useIntl } from 'react-intl';
import { useFieldHandlers } from './useFieldHandlers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  controlLabel: {},
  checkbox: {
    margin: [[-theme.spacing(1) * 1.5, 0]]
  }
}));

export const OverbudgetFacetDetails = ({
  classes: classesOverride,
  className,
  options,
  selected,
  setSelected
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { formatMessage } = useIntl();

  const { onChange, isSelected } = useFieldHandlers({
    setSelected,
    selected,
    options
  });

  return (
    <div className={classNames(classes.root, className)}>
      {options.map(option => (
        <FormControlLabel
          key={option.key}
          className={classes.controlLabel}
          control={
            <Checkbox
              data-qe-id={`Has_Facet_${option.key}`}
              name={option.key}
              checked={isSelected(option)}
              onChange={onChange}
              className={classes.checkbox}
            />
          }
          label={formatMessage({ id: `overbudget.${option.key}` })}
        />
      ))}
    </div>
  );
};

OverbudgetFacetDetails.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  setSelected: PropTypes.func.isRequired
};

export default OverbudgetFacetDetails;
