import { compareDateObjects } from '~/modules/common/dates/compare';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';

export const planParameterValueFormatter = {
  'urn:replicon:script-key:parameter:task': x => x.id,
  'urn:replicon:script-key:parameter:expense-type': x =>
    x && typeof x === 'object' ? x.id : x
};

export const planParameterEnhancers = {
  'fixed-bid-milestone': clause => {
    return {
      ...clause,
      parameters: [
        ...clause.parameters,
        {
          keyUri: 'urn:replicon:script-key:parameter:percentage',
          value: {
            text: 'Percentage',
            collection: [{ text: 'numeric' }, { number: 0 }, {}, { number: 2 }]
          }
        }
      ]
    };
  }
};

export const getHeaderProps = {
  'fixed-bid-milestone': me => ({ amount: 0, currency: me.baseCurrency })
};

export const getFooterProps = {
  'fixed-bid-milestone': me => ({
    'milestone-total-percentage': { value: 0 },
    'milestone-total-amount': {
      contractAmount: 0,
      amount: 0,
      currency: me.baseCurrency
    }
  })
};

export const estimatedAmountParameter = {
  keyUri: 'estimatedAmount',
  value: {
    text: 'Estimated Billing',
    collection: [{ text: 'read-only-amount' }, { number: 0 }, {}, { number: 6 }]
  }
};

export const readOnlyParameters = ['estimatedAmount'];

const addAdditionalFields = (plan, contractAmount, baseCurrency) => {
  const percentageKeyUri = 'urn:replicon:script-key:parameter:percentage';
  const amountKeyUri = 'urn:replicon:script-key:parameter:milestone-amount';
  const cAmount = contractAmount || {
    amount: 0,
    currency: baseCurrency
  };
  const newScripts = plan.scripts.map(script => {
    const milestoneAmount = script[amountKeyUri];
    const percentage =
      cAmount.amount !== 0
        ? getRoundedValue((milestoneAmount.amount * 100) / cAmount.amount, 2)
        : 0;

    return {
      ...script,
      [percentageKeyUri]: percentage
    };
  });

  const { totalAmount, totalPercentage } = newScripts.reduce(
    (total, current) => {
      return {
        ...total,
        totalAmount: (total.totalAmount || 0) + current[amountKeyUri].amount,
        totalPercentage:
          (total.totalPercentage || 0) + current[percentageKeyUri]
      };
    },
    {}
  );

  return {
    ...plan,
    contractAmount: cAmount,
    scripts: newScripts,
    footerProps: {
      'milestone-total-percentage': {
        value: getRoundedValue(totalPercentage, 2)
      },
      'milestone-total-amount': {
        contractAmount: cAmount.amount,
        amount: getRoundedValue(totalAmount, 2),
        currency: cAmount.currency
      }
    }
  };
};

const sortFixedBidMilestones = plan => {
  const taskKey = 'urn:replicon:script-key:parameter:task';
  const scripts = [...plan.scripts];

  return scripts.sort((a, b) =>
    compareDateObjects(a[taskKey].endDate, b[taskKey].endDate)
  );
};

export const planScriptsFormatter = baseCurrency => ({
  'fixed-bid-milestone': (plan, contractAmount) => {
    const sortedMilestones = sortFixedBidMilestones(plan);

    return addAdditionalFields(
      { ...plan, scripts: sortedMilestones },
      contractAmount,
      baseCurrency
    );
  }
});

export const clauseFormatter = baseCurrency => ({
  'fixed-bid-milestone': ({ clause, contractAmount }) => {
    const currency = contractAmount ? contractAmount.currency : baseCurrency;
    const parameters = [...clause.parameters];
    const milestoneIndex = parameters.findIndex(
      x => x.id === 'urn:replicon:script-key:parameter:milestone-amount'
    );

    parameters[milestoneIndex].defaultValue.currency = currency;

    return { ...clause, parameters };
  }
});
