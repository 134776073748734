import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { assignedRoleDisplayText } from '../components/Gantt/hooks/useGanttData';

export const updateGanttTaskAssignedRole = (gantt, taskId, role, intl) => {
  const task = gantt.getTask(taskId);

  task.assignedRole = role
    ? {
        id: role.id,
        displayText: role.displayText
      }
    : null;

  task.assignedRoleText = assignedRoleDisplayText({ role, intl });

  gantt.updateTask(taskId);
};

export const useOnAssignRole = ({
  assignRoleToTask,
  assignUserToTask,
  closeRoleAssignmentPopover,
  ganttRef,
  taskId
}) => {
  const intl = useIntl();

  return useCallback(
    (role, users) => {
      const { assignee, assignedRole } = ganttRef.getTask(taskId);
      const { id: assignedRoleId } = assignedRole || {};
      const { id: assigneeId } = assignee || {};

      const { id: roleId } = role || {};

      if (users && !assignedRoleId && !assigneeId) {
        const matchingResource = users.find(user =>
          (user.projectRoles || []).find(
            // eslint-disable-next-line max-nested-callbacks
            projectRole => projectRole.projectRole.uri === roleId
          )
        );

        if (matchingResource) assignUserToTask(taskId, matchingResource);
      }
      if (assignedRoleId !== roleId) {
        assignRoleToTask(taskId, role);
      }
      closeRoleAssignmentPopover();
      updateGanttTaskAssignedRole(ganttRef, taskId, role, intl);
    },
    [
      assignRoleToTask,
      assignUserToTask,
      closeRoleAssignmentPopover,
      ganttRef,
      intl,
      taskId
    ]
  );
};

export default useOnAssignRole;
