import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { SearchBox } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '3.5rem',
    [theme.breakpoints.down('sm')]: {
      height: 'unset'
    },
    position: 'sticky',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar - 5,
    minHeight: '-webkit-max-content',
    backgroundColor: theme.palette.background.paper
  },
  search: {
    flexGrow: 1,
    padding: theme.spacing(1, 2)
  }
}));

export const SearchToolbar = ({
  setSearchCriteria,
  searchCriteria,
  hideSearch = true,
  dataQeId = 'Billing-Transactons-SearchBar',
  searchFacets
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { searchText, expand, ariaLabel } = useMemo(
    () => ({
      searchText: intl.formatMessage({
        id: 'billingToolbar.search.searchText'
      }),
      expand: intl.formatMessage({
        id: 'billingToolbar.search.expand'
      }),
      ariaLabel: intl.formatMessage({
        id: 'billingToolbar.search.label'
      })
    }),
    [intl]
  );

  return searchFacets ? (
    <div className={classes.root}>
      {setSearchCriteria && (
        <div className={classes.search}>
          <SearchBox
            hideSearch={hideSearch}
            dataQeId={dataQeId}
            ariaLabel={ariaLabel}
            setSearchCriteria={setSearchCriteria}
            searchInputAriaLabel={searchText}
            searchIconAriaLabel={expand}
            searchCriteria={searchCriteria}
            facets={searchFacets}
          />
        </div>
      )}
    </div>
  ) : null;
};

SearchToolbar.propTypes = {
  setSearchCriteria: PropTypes.func,
  hideSearch: PropTypes.bool,
  searchCriteria: PropTypes.object.isRequired,
  searchFacets: PropTypes.array,
  dataQeId: PropTypes.string
};

export default SearchToolbar;
