import React from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const TaskAssignmentDialogFooter = ({ onClose }) => {
  const { formatMessage } = useIntl();

  return (
    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
        aria-label={formatMessage({ id: 'button.ok' })}
      >
        <FormattedMessage id="button.ok" />
      </Button>
    </DialogActions>
  );
};

TaskAssignmentDialogFooter.propTypes = {
  onClose: PropTypes.func
};
