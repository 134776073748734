import React from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { MAX_DATE, MIN_DATE } from '~/modules/common/const';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';

export const DatePicker = muiPickerProps => {
  const views = muiPickerProps?.views;

  const minDate = mapIsoStringtoUtcObject(MIN_DATE);

  const maxDateValue = mapIsoStringtoUtcObject(MAX_DATE);
  const maxDate = // for year view it is showing beyond maxDate in DatePicker calendar(3.2) so passing 31-12-2098
    views && views.length === 1 && views.includes('year')
      ? maxDateValue.minus({ year: 1 })
      : maxDateValue;

  return (
    <MuiDatePicker minDate={minDate} maxDate={maxDate} {...muiPickerProps} />
  );
};

export default DatePicker;
