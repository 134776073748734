import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import usePeriodsByScale from '~/modules/resourcing/common/hooks/usePeriodsByScale';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';

const useRequestTimelineBlocks = ({
  scheduleRules,
  scale,
  chartStartDate,
  relativeDynamicPositionLeft = 0,
  requestDisplayPeriods = [],
  startDate: requestStartDate,
  projectDefaultScheduleRule,
  endDate: requestEndDate
}) => {
  const { displayPeriods } = usePeriodsByScale({
    rangeStart: requestDisplayPeriods.length ? null : requestStartDate,
    rangeEnd: requestDisplayPeriods.length ? null : requestEndDate,
    scale
  });

  return {
    requestPeriods: (
      (requestDisplayPeriods.length && requestDisplayPeriods) ||
      displayPeriods
    ).map(range => {
      const { start, end, key, ...rest } = range;

      const totalHoursForRange = getTotalHoursForDateRangeFromScheduleRules({
        start,
        end,
        scheduleRules
      });

      const totalScheduleHours = getTotalHoursForDateRangeFromScheduleRules({
        start,
        end,
        scheduleRules: scheduleRules.map(scheduleRule => ({
          ...scheduleRule,
          do: projectDefaultScheduleRule
            ? projectDefaultScheduleRule.do
            : scheduleRule.do
        }))
      });
      const percentage = (totalHoursForRange / totalScheduleHours) * 100;

      return {
        key,
        startDate: start,
        endDate: end,
        dynamicPosition: getDynamicPositionAttributes({
          chartStartDate,
          start:
            requestStartDate && start < requestStartDate
              ? requestStartDate
              : start,
          end: requestEndDate && end > requestEndDate ? requestEndDate : end,
          scale,
          isPsaRmpUserSchedulePerfFixEnabled: true,
          relativeDynamicPositionLeft
        }).dynamicPosition,
        totalHours: totalHoursForRange,
        percentage,
        totalScheduleHours,
        ...(requestDisplayPeriods && {
          ...rest
        })
      };
    })
  };
};

export default useRequestTimelineBlocks;
