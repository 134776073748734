import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckCircle, AddSharp, RemoveSharp } from '@material-ui/icons';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';

export const TaskVariationIcon = ({ classes, variationStatus }) =>
  variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.OVER ? (
    <AddSharp className={classNames(classes.variationIcon, classes.OVER)} />
  ) : variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.UNDER ? (
    <RemoveSharp className={classNames(classes.variationIcon, classes.UNDER)} />
  ) : (
    <CheckCircle
      className={classNames(classes.variationIcon, classes.MATCHING)}
    />
  );

TaskVariationIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  variationStatus: PropTypes.string.isRequired
};

export default TaskVariationIcon;
