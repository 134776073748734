import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = ({ hierarchyLevel }) => () => ({
  hierarchy: {
    paddingLeft: `${16 * hierarchyLevel}px`,
    '&:only-child': {
      paddingLeft: '0px'
    }
  }
});

const withStyleProps = Component => props => {
  const StyledComponent = withStyles(styles(props))(Component);

  return <StyledComponent {...props} />;
};

const HierarchyOptions = ({ classes, displayText }) => (
  <div className={classes.hierarchy}>{displayText}</div>
);

HierarchyOptions.propTypes = {
  classes: PropTypes.object,
  displayText: PropTypes.string
};

export default withStyleProps(HierarchyOptions);
