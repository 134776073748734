import get from 'lodash.get';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import {
  mapRepliconDateToMidnightUTCString,
  toRepliconDate,
  mapMomentDateToRepliconDate
} from './convert';

export const getStartOfTodayUTCString = () => {
  const today = toRepliconDate(DateTime.local());

  return mapRepliconDateToMidnightUTCString(today);
};

export const getTodayForUser = me => {
  const ianaName = get(me, 'locale.timeZone.ianaName');

  return mapMomentDateToRepliconDate(
    ianaName ? moment().tz(ianaName) : moment()
  );
};

export const getTodayForCompany = me => {
  const ianaName = get(me, 'companyTimeZone.ianaName');

  return mapMomentDateToRepliconDate(
    ianaName ? moment().tz(ianaName) : moment()
  );
};
