import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { DialogTitle, makeStyles } from '@material-ui/core';
import { TaskIcon, TaskParentIcon } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    width: '1em',
    height: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const useTitleStyles = makeStyles(() => ({
  root: {
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

export const RemoveTaskEstimateConfirmationDialogTitle = ({
  taskName,
  isParentTask
}) => {
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const IconComponent = isParentTask ? TaskParentIcon : TaskIcon;

  return (
    <DialogTitle classes={titleClasses}>
      <FormattedMessage id="removeTaskEstimateDialog.title" />
      <IconComponent className={classes.icon} />
      <span>{taskName}</span>
    </DialogTitle>
  );
};

RemoveTaskEstimateConfirmationDialogTitle.propTypes = {
  taskName: PropTypes.string,
  isParentTask: PropTypes.bool
};

export default RemoveTaskEstimateConfirmationDialogTitle;
