import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { OverlayBlockLabel } from '~/modules/resourcing/common/chart/components';
import { PERIOD_HEIGHT, PERIOD_GAP } from './constants';

export const useStyles = makeStyles(theme => ({
  percentageOverlay: {
    margin: 0,
    borderTop: `1px solid ${theme.palette.table.border}`,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    position: 'absolute',
    textAlign: 'center'
  }
}));

const ResourceRequestPercentageBlock = ({ index, scale, overlayPeriod }) => {
  const {
    totalHours,
    dynamicPosition,
    totalProjectDefaultScheduledHours
  } = overlayPeriod;
  const { formatMessage } = useIntl();

  const classes = useStyles();

  const percentageOverlayStyle = useMemo(
    () => ({
      left: dynamicPosition.left,
      width: dynamicPosition.width,
      top: dynamicPosition.top + (PERIOD_HEIGHT + PERIOD_GAP) * index,
      height: `${PERIOD_HEIGHT}px`
    }),
    [dynamicPosition, index]
  );

  return (
    <div
      aria-label={formatMessage({
        id: 'resourcingOverviewChart.percentage'
      })}
      role="button"
      tabIndex="0"
      className={classes.percentageOverlay}
      style={percentageOverlayStyle}
    >
      <OverlayBlockLabel
        hours={totalHours}
        percentage={(totalHours / totalProjectDefaultScheduledHours) * 100}
        showPercentage={totalProjectDefaultScheduledHours > 0}
        scale={scale}
      />
    </div>
  );
};

ResourceRequestPercentageBlock.propTypes = {
  index: PropTypes.number,
  overlayPeriod: PropTypes.object,
  scale: PropTypes.string
};

export default ResourceRequestPercentageBlock;
