import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { billingTransacitonSummaryQuery } from './totalsQuery';
import {
  mapToServiceFilter,
  getConsolidatedTypeFilter
} from './useOutstandingBillTabSummary';

const summaryType = ['BILL'];

export const getBillingTransactionSummaryQueryParameters = ({
  billingTransactionFilter,
  contextFilter,
  dueDate,
  isPsaFpGraphqlOptimizationEnabled
}) => {
  const consolidatedTypeFilter =
    billingTransactionFilter.transactionTypes &&
    billingTransactionFilter.transactionTypes.length > 0
      ? getConsolidatedTypeFilter(
          billingTransactionFilter.transactionTypes,
          summaryType
        )
      : summaryType;

  return {
    variables: {
      filter: mapToServiceFilter({
        contextFilter,
        billingTransactionFilter,
        consolidatedTypeFilter,
        billStatus: [],
        dueDate
      })
    },
    skip:
      !isPsaFpGraphqlOptimizationEnabled || !consolidatedTypeFilter.length > 0,
    fetchPolicy: 'network-only'
  };
};

export const useBillingTransactionSummary = (
  billingTransactionFilter,
  contextFilter,
  dueDate
) => {
  const isPsaFpGraphqlOptimizationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpGraphqlOptimizationEnabled'
  });

  const param = getBillingTransactionSummaryQueryParameters({
    billingTransactionFilter,
    contextFilter,
    dueDate,
    isPsaFpGraphqlOptimizationEnabled
  });

  const { data, loading } = useQuery(billingTransacitonSummaryQuery, param);

  const billingTransacitonSummary = get(
    data,
    'getBillingTransactionSummary',
    {}
  );

  return { loading, billingTransacitonSummary };
};

export default useBillingTransactionSummary;
