import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage } from 'react-intl';
import Decimal from '~/modules/common/components/Decimal';

const useStyles = makeStyles(theme => ({
  costRate: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary
  },
  tooltipName: {
    fontWeight: theme.typography.fontWeightBold
  },
  roleName: {
    marginRight: theme.spacing(1)
  },
  name: {}
}));

export const getTooltipContent = (role, classes, showRate) => (
  <>
    <div className={classes.tooltipName}>{role.displayText}</div>
    {showRate ? (
      <div>
        <FormattedMessage id="rolesDropdown.costRate" />
        {': '}
        <Decimal value={role.amount} prefix={`${role.symbol} `} />
      </div>
    ) : null}
  </>
);

export const getRoleName = ({
  showRate,
  role,
  className,
  classes,
  ...otherProps
}) => (
  <span
    {...otherProps}
    className={classNames(className, classes.roleNameContainer)}
  >
    <span
      className={classNames(classes.roleName, classes.name)}
      data-qe-id="role"
    >
      {role.displayText}
    </span>
    {showRate && (
      <Decimal
        className={classes.costRate}
        value={role.amount}
        prefix={`${role.symbol} `}
      />
    )}
  </span>
);

export const RoleName = ({
  classes: classesOverride,
  className,
  role,
  showRate,
  showTooltip = true,
  ...otherProps
}) => {
  const classes = useStyles({ classes: classesOverride });

  return showTooltip ? (
    <Tooltip title={getTooltipContent(role, classes, showRate)}>
      {getRoleName({ role, showRate, className, classes, ...otherProps })}
    </Tooltip>
  ) : (
    getRoleName({ role, showRate, className, classes, ...otherProps })
  );
};

RoleName.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  showTooltip: PropTypes.bool,
  role: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    amount: PropTypes.number,
    symbol: PropTypes.string
  }),
  showRate: PropTypes.bool
};

export default RoleName;
