import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const ProjectRiskSummary = ({ projectUri, projectEndDate, classes }) => {
  const projectRiskSummary = useHasFeatureFlag({
    featureFlag: 'projectRiskSummary'
  });

  useEffect(() => {
    if (!projectRiskSummary || projectRiskSummary === 'off') {
      return () => {};
    }

    const script = document.createElement('script');

    script.setAttribute('type', 'module');
    script.setAttribute('src', projectRiskSummary);
    script.async = true;

    document.body.append(script);

    return () => {
      document.removeChild(script);
    };
  }, [projectRiskSummary]);

  if (!projectRiskSummary || projectRiskSummary === 'off') {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <tiprw-project-risk-summary
        project-uri={projectUri}
        project-end-date={projectEndDate}
      ></tiprw-project-risk-summary>
    </div>
  );
};

ProjectRiskSummary.propTypes = {
  classes: PropTypes.object,
  projectUri: PropTypes.string.isRequired,
  projectEndDate: PropTypes.string
};

export default ProjectRiskSummary;
