import React, { useMemo, useState } from 'react';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { useSearchableBillStatus } from './useBillStatus';
import { useSearchableBillStatusAndSubStatus } from './useBillStatusAndSubStatus';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const mapOptions = billStatus =>
  billStatus.map(({ name, id }) => ({ key: name, value: id }));

export const matchOption = searchBillStatus => async (_, term) => {
  if (!term) {
    return null;
  }

  const billStatus = await searchBillStatus(cleanSearchTerm(term));

  const option = billStatus.shift();

  if (option) {
    return {
      ...option,
      key: option.name
    };
  }

  return null;
};

const withCriteriaProvider = (tag, context) => BaseComponent => ({
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);

  const isPsaPswatBillingDraftSubStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatBillingDraftSubStatusEnabled'
  });

  const { searchBillStatus } = isPsaPswatBillingDraftSubStatusEnabled
    ? useSearchableBillStatusAndSubStatus()
    : useSearchableBillStatus();

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption(searchBillStatus),
      selected,
      setSelected,
      suggestions: searchBillStatus
    }),
    [searchBillStatus, selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
