import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { LinearProgress } from '@mui/material';
import {
  useBatchStatus,
  useCancelBatch,
  useHasFeatureFlag
} from '~/modules/common/hooks';
import CurrentProgressBarContent from '~/modules/common/components/CurrentProgressBarContent';
import ErrorDialogContent from '~/modules/common/components/ErrorDialogContent';
import LoadingButton from '~/modules/common/components/LoadingButton';

export const BulkProjectStatusChangeResult = ({
  onClose,
  itemCounts,
  batchState,
  bulkChangeInProgress,
  formatMessage,
  currentProgress,
  onBatchComplete,
  projectWorkflowState,
  bulkUpdateProjectState,
  setBulkUpdateProjectState
}) => {
  const isPsaPrpProjectStatusChangeNotificationEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpProjectStatusChangeNotificationEnabled'
  });
  const cancelBatch = useCancelBatch();
  const { batchId, batchInProgress } = batchState;
  const [cancelBatchInProgress, setCancelBatchInProgress] = useState(false);

  const handleCancelBatch = useCallback(async () => {
    setCancelBatchInProgress(true);
    await cancelBatch(batchId);
    setCancelBatchInProgress(false);
    onBatchComplete({ ...batchState, projectWorkflowState });
    onClose();
  }, [
    cancelBatch,
    batchId,
    onClose,
    onBatchComplete,
    batchState,
    projectWorkflowState
  ]);

  const handleOkAction = useCallback(async () => {
    onClose();
    if (isPsaPrpProjectStatusChangeNotificationEnabled) {
      onBatchComplete({ projectWorkflowState });
    } else {
      onBatchComplete({ ...batchState, projectWorkflowState });
    }
  }, [
    batchState,
    isPsaPrpProjectStatusChangeNotificationEnabled,
    onBatchComplete,
    onClose,
    projectWorkflowState
  ]);

  return (
    <>
      {isPsaPrpProjectStatusChangeNotificationEnabled ? (
        <>
          <DialogContent>
            {bulkUpdateProjectState.complete ? (
              <Typography variant="subtitle2" gutterBottom>
                {formatMessage({ id: 'bulkProjectEditStatus.complete' })}
              </Typography>
            ) : (
              <>
                <LinearProgress />
                <Typography variant="subtitle2" gutterBottom>
                  {formatMessage({ id: 'bulkProjectEditStatus.inProgress' })}
                </Typography>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleOkAction}
              disabled={!bulkUpdateProjectState.complete}
            >
              {formatMessage({ id: 'bulkProjectEditStatus.ok' })}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            {batchInProgress ? (
              <CurrentProgressBarContent
                itemCounts={itemCounts}
                currentProgress={currentProgress}
                progressInfo={formatMessage({
                  id: 'bulkProjectEditStatus.cancelActionInfo'
                })}
              />
            ) : (
              <Typography variant="subtitle2" gutterBottom>
                {formatMessage({ id: 'bulkProjectEditStatus.complete' })}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            {batchInProgress ? (
              <LoadingButton
                color="primary"
                onClick={handleCancelBatch}
                disabled={cancelBatchInProgress}
                isLoading={cancelBatchInProgress}
              >
                {formatMessage({ id: 'bulkProjectEditStatus.cancel' })}
              </LoadingButton>
            ) : (
              <Button onClick={handleOkAction}>
                {formatMessage({ id: 'bulkProjectEditStatus.ok' })}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </>
  );
};

export const BulkProjectStatusChangeProgressDialogContent = ({
  onClose,
  batchId,
  itemCounts,
  batchState,
  setBatchState,
  onBatchComplete,
  projectWorkflowState,
  bulkUpdateProjectState,
  setBulkUpdateProjectState
}) => {
  const {
    error: batchStatusError,
    hasProcessed,
    overallProgress: { currentProgress = 0 } = {}
  } = useBatchStatus({ batchId });

  useEffect(() => {
    if (hasProcessed) setBatchState({ batchId, batchInProgress: false });
  }, [setBatchState, batchId, hasProcessed, onBatchComplete]);
  const { formatMessage } = useIntl();

  return (
    <>
      {batchStatusError ? (
        <ErrorDialogContent
          title={formatMessage({
            id: 'bulkProjectEditStatus.editStatus'
          })}
          error={batchStatusError}
          onClose={onClose}
        />
      ) : (
        <BulkProjectStatusChangeResult
          onClose={onClose}
          itemCounts={itemCounts}
          batchState={batchState}
          formatMessage={formatMessage}
          currentProgress={currentProgress}
          onBatchComplete={onBatchComplete}
          projectWorkflowState={projectWorkflowState}
          bulkUpdateProjectState={bulkUpdateProjectState}
          setBulkUpdateProjectState={setBulkUpdateProjectState}
        />
      )}
    </>
  );
};

BulkProjectStatusChangeProgressDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  batchId: PropTypes.string,
  batchState: PropTypes.object.isRequired,
  setBatchState: PropTypes.func.isRequired,
  itemCounts: PropTypes.number.isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  projectWorkflowState: PropTypes.object.isRequired,
  bulkUpdateProjectState: PropTypes.object.isRequired,
  setBulkUpdateProjectState: PropTypes.func.isRequired
};

BulkProjectStatusChangeResult.propTypes = {
  onClose: PropTypes.func.isRequired,
  itemCounts: PropTypes.number.isRequired,
  batchState: PropTypes.object.isRequired,
  bulkChangeInProgress: PropTypes.bool,
  currentProgress: PropTypes.number.isRequired,
  onBatchComplete: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  projectWorkflowState: PropTypes.object.isRequired,
  bulkUpdateProjectState: PropTypes.object.isRequired,
  setBulkUpdateProjectState: PropTypes.func.isRequired
};

export default BulkProjectStatusChangeProgressDialogContent;
