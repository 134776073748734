import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { TaskCardNoValue } from './TaskCardNoValue';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  role: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(1, 0),
    lineHeight: 1.51
  }
}));

const TaskCardRole = ({ role }) => {
  const {
    featureFlags: { isPSATaskEstimateRollUpMobileViewEnabled }
  } = useMeContext();
  const classes = useStyles();

  return role ? (
    isPSATaskEstimateRollUpMobileViewEnabled ? (
      <div className={classes.container}>
        <Typography className={classes.role} variant="body2">
          {role.displayText}
        </Typography>
      </div>
    ) : (
      <Typography variant="body2">{role.displayText}</Typography>
    )
  ) : (
    <TaskCardNoValue id="projectTasksPage.noRole" />
  );
};

TaskCardRole.propTypes = {
  role: PropTypes.object
};

export default TaskCardRole;
