import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListTable,
  ListTableThemeProvider
} from '~/modules/common/components/ListTable';
import NoSkillsMessage from './NoSkillsMessage';
import { useColumns as skillColumns } from './hooks/useColumns';

const useListTableStyles = makeStyles(() => ({
  card: {
    overflow: 'inherit',
    boxShadow: 'none'
  }
}));

const useListColumnsStyles = makeStyles(theme => ({
  skill: {
    textAlign: 'left !important'
  },
  userCount: {
    width: theme.spacing(8),
    borderLeft: '0px !important',
    paddingRight: theme.spacing(8)
  }
}));

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    width: '100%',
    borderCollapse: 'separate'
  },
  tableCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.divider}`,
    textAlign: 'right',
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'pre-wrap',
    top: theme.spacing(12)
  },
  tableHeaderCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.divider}`,
    textAlign: 'right',
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'pre-wrap',
    top: theme.spacing(12)
  },
  tableRow: {
    [theme.breakpoints.up('md')]: {
      '&$hover:hover, &$hover:hover $cell': {
        backgroundColor: theme.palette.grey[200]
      },
      cursor: 'pointer'
    }
  },
  tableHeader: {}
}));

const SkillsDistributionTableContent = ({
  skills,
  hasMore,
  loadMore,
  skillsLoading
}) => {
  const cssColumnClasses = useListColumnsStyles();
  const providerClasses = useListTableThemeProviderStyles();

  const listTableClasses = useListTableStyles();

  const { headers, listColumns } = skillColumns({
    classes: cssColumnClasses
  });

  if (!skillsLoading && skills.length === 0) {
    return <NoSkillsMessage />;
  }

  return (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTable
        classes={listTableClasses}
        headers={headers}
        columns={listColumns}
        records={skills}
        hasMore={hasMore}
        loadMore={loadMore}
        isLoading={skillsLoading}
        variant="table"
        hover
        useInlineStyles={false}
        showLoadingComponent={skills.length === 0 && skillsLoading}
        showLoadMoreSkeleton={skills.length > 0 && skillsLoading}
      />
    </ListTableThemeProvider>
  );
};

SkillsDistributionTableContent.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.object.isRequired,
      skill: PropTypes.object.isRequired,
      userCount: PropTypes.number.isRequired
    })
  ).isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  skillsLoading: PropTypes.bool.isRequired
};

export default SkillsDistributionTableContent;
