import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { usePageOfAvailableRateTableDimensions } from '~/modules/rateCard/advancedRateCard/components/hooks/usePageOfAvailableRateTableDimensions';
import AdvancedRateCardEditable from '~/modules/rateCard/advancedRateCard/components/AdvancedRateCardEditable';

const useStyles = makeStyles(theme => ({
  addRatesButton: {
    color: theme.palette.primary.main
  }
}));

const projectRates = [];
const projectRateDimensions = [];
const projectRateCount = 0;

const AddDynamicRateCard = ({ projectDetails, setRatesModifier }) => {
  const classes = useStyles();
  const {
    projectId,
    projectSlug,
    projectCurrency,
    projectPermissions
  } = projectDetails;
  const {
    availableRateTableDimensions,
    requiredDimensions
  } = usePageOfAvailableRateTableDimensions();

  const [edit, setEdit] = useState(false);
  const handleOnClick = useCallback(() => {
    setEdit(true);
  }, []);

  return (
    <>
      <Button
        className={classes.addRatesButton}
        data-qe-id="AddRateCard"
        onClick={handleOnClick}
      >
        <FormattedMessage id="rateCard.addRateCard" />
      </Button>
      {edit && (
        <AdvancedRateCardEditable
          projectCurrency={projectCurrency}
          editable
          projectRates={projectRates}
          projectRateCount={projectRateCount}
          projectRateDimensions={projectRateDimensions}
          projectId={projectId}
          availableRateTableDimensions={availableRateTableDimensions}
          projectRateRequiredDimensions={requiredDimensions}
          projectSlug={projectSlug}
          edit={edit}
          setEdit={setEdit}
          setRatesModifier={setRatesModifier}
          projectPermissions={projectPermissions}
        />
      )}
    </>
  );
};

AddDynamicRateCard.propTypes = {
  projectDetails: PropTypes.object,
  setRatesModifier: PropTypes.func
};
export default AddDynamicRateCard;
