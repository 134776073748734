import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import { useMeContext } from '~/modules/me';
import ChartHeader from './ChartHeader';
import useChartStateHandlers from './useChartStateHandlers';
import useChartChangeHandlers from './useChartChangeHandlers';

const useStyles = makeStyles({
  root: {
    overflow: 'visible'
  },
  content: {},
  title: {},
  headerContainer: {}
});

export const ChartCard = ({
  classes: classesOverride,
  chartDataProps = {},
  chartTitle,
  chartAction,
  chartAvatar,
  ChartComponent,
  showPeriodFilter,
  titleId = '',
  showScaleSelector,
  showEntireProjectScaleOption = false,
  scale: initialScale,
  dateRange: initialDateRange,
  children,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });
  const me = useMeContext();
  const { scale, dateRange, setScale, setDateRange } = useChartStateHandlers({
    showEntireProjectScaleOption,
    initialScale,
    initialDateRange
  });

  const chartDataInput = useMemo(() => ({ ...chartDataProps, dateRange }), [
    chartDataProps,
    dateRange
  ]);

  const { title } = classes;
  const chartHeaderClasses = useMemo(() => ({ title }), [title]);

  const {
    handlePrevPeriodChange,
    handleNextPeriodChange,
    handleScaleChange
  } = useChartChangeHandlers({ me, scale, dateRange, setScale, setDateRange });

  return (
    <Card className={classes.root}>
      <ChartHeader
        classes={chartHeaderClasses}
        showPeriodFilter={
          scale !== PERIOD_SCALE_ENUM.ENTIRE_PROJECT && showPeriodFilter
        }
        titleId={titleId}
        scale={scale}
        title={chartTitle}
        avatar={chartAvatar}
        action={chartAction}
        dateRange={dateRange}
        setDateRange={setDateRange}
        onPrevButtonClick={handlePrevPeriodChange}
        onNextButtonClick={handleNextPeriodChange}
        onScaleChange={handleScaleChange}
        showScaleSelector={showScaleSelector}
        showEntireProjectScaleOption={showEntireProjectScaleOption}
        dataQeId={dataQeId}
      />
      <CardContent>
        <div className={classes.content}>
          <ChartComponent
            chartDataInput={chartDataInput}
            titleId={titleId}
            key={chartDataInput.key || titleId}
          />
          {children}
        </div>
      </CardContent>
    </Card>
  );
};

ChartCard.propTypes = {
  classes: PropTypes.object,
  chartDataProps: PropTypes.object,
  chartTitle: PropTypes.node,
  chartAvatar: PropTypes.node,
  ChartComponent: PropTypes.any,
  titleId: PropTypes.string,
  showPeriodFilter: PropTypes.bool,
  children: PropTypes.node,
  chartAction: PropTypes.node,
  showScaleSelector: PropTypes.bool,
  showEntireProjectScaleOption: PropTypes.bool,
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.YEARS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.WEEKS,
    PERIOD_SCALE_ENUM.ENTIRE_PROJECT
  ]),
  dateRange: PropTypes.object,
  dataQeId: PropTypes.string
};

export default ChartCard;
