import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const GET_NEXT_REFERENCE_NUMBER_TEXT = gql`
  query getReferenceNumberText($transactionType: BillingTransactionType!) {
    transactionNextReferenceNumberText(transactionType: $transactionType)
  }
`;

export const useNextReferenceNumberText = transactionType => {
  const { data, loading } = useQuery(GET_NEXT_REFERENCE_NUMBER_TEXT, {
    variables: {
      transactionType
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const nextReferenceNumberText = get(
    data,
    `transactionNextReferenceNumberText`,
    ''
  );

  return {
    loading,
    nextReferenceNumberText
  };
};

export default useNextReferenceNumberText;
