import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_RATE } from '../hooks/enum';
import RateListDataTable from './RateListDataTable';
import { useListColumns, useColumnStyles, useDimensionOption } from './hooks';

export const RateListTable = ({
  projectRates,
  projectDimensions,
  formOnChange,
  hasMore,
  loadingMore,
  loadMore,
  errors,
  openDeleteDialog,
  projectSlug,
  addNewEntry,
  setAddNewEntry,
  projectCurrency,
  readOnly
}) => {
  const columnClasses = useColumnStyles({ readOnly });
  const {
    usersResponse,
    rolesResponse,
    payCodeResponse,
    onInputChange
  } = useDimensionOption(projectSlug);

  const columns = useListColumns({
    projectDimensions,
    formOnChange,
    defaultRate: DEFAULT_RATE,
    classes: columnClasses,
    errors,
    usersResponse,
    rolesResponse,
    payCodeResponse,
    onInputChange,
    readOnly
  });

  return (
    <RateListDataTable
      columns={columns}
      records={projectRates}
      hasMore={hasMore}
      loadingMore={loadingMore}
      loadMore={loadMore}
      openDeleteDialog={openDeleteDialog}
      onDeleteGroup={formOnChange.onDeleteGroup}
      projectDimensions={projectDimensions}
      addNewEntry={addNewEntry}
      setAddNewEntry={setAddNewEntry}
      onAddNewEntry={formOnChange.onAddNewEntry}
      projectCurrency={projectCurrency}
      readOnly={readOnly}
    />
  );
};

RateListTable.propTypes = {
  projectRates: PropTypes.array,
  projectDimensions: PropTypes.array,
  formOnChange: PropTypes.object,
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  loadMore: PropTypes.func,
  openDeleteDialog: PropTypes.func,
  errors: PropTypes.object,
  onDeleteGroup: PropTypes.func,
  projectSlug: PropTypes.string,
  addNewEntry: PropTypes.bool,
  setAddNewEntry: PropTypes.func,
  projectCurrency: PropTypes.object,
  readOnly: PropTypes.bool
};

export default RateListTable;
