import React from 'react';
import { PropTypes } from 'prop-types';

export const ReadOnlyContent = ({ children }) => <>{children}</>;

ReadOnlyContent.propTypes = {
  children: PropTypes.node
};

ReadOnlyContent.displayName = 'ReadOnlyContent';

export default ReadOnlyContent;
