import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { specificTaskResourceUserAllocationFragment } from './specificTaskResourceUserAllocationFragment';

export const UPDATE_TASK_RESOURCE_USER_ALLOCATION = gql`
  mutation UpdateTaskResourceUserAllocation(
    $input: UpdateTaskResourceUserAllocationInput!
  ) {
    updateTaskResourceUserAllocation(input: $input) {
      taskResourceUserAllocation {
        ...SpecificTaskResourceUserAllocation
      }
    }
  }
  ${specificTaskResourceUserAllocationFragment}
`;

export const useUpdateTaskResourceUserAllocation = () => {
  const [updateTaskResourceUserAllocationMutation] = useMutation(
    UPDATE_TASK_RESOURCE_USER_ALLOCATION
  );

  return updateTaskResourceUserAllocationMutation;
};

export default useUpdateTaskResourceUserAllocation;
