import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const REVENUE_FORECAST_SUMMARY = gql`
  query getRevenueForecastSummaryForProject(
    $projectId: String
    $dateRange: DateRangeInput2
  ) {
    project(projectId: $projectId) {
      id
      revenueForecastSummary(dateRange: $dateRange) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

export const useRevenueForecastSummary = ({ projectUri, dateRange }) => {
  const { data, loading } = useQuery(REVENUE_FORECAST_SUMMARY, {
    variables: {
      projectId: projectUri,
      dateRange
    },
    skip: !projectUri,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const forecastSummary = get(data, 'project.revenueForecastSummary', null);

  return { loading, forecastSummary };
};

export default useRevenueForecastSummary;
