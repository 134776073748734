import { useMeContext } from '~/modules/me/useMeContext';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';

export const useTaskSummaryLabels = ({ isProjectVariant }) => {
  const me = useMeContext();
  const {
    featureFlags: { PSAPRPTaskEstimateCalculation }
  } = me;

  const isPSAPRPTaskEstimateCalculationMethodEnabled =
    PSAPRPTaskEstimateCalculation !== TASK_ESTIMATE_CALCULATION_TYPES.OFF;

  return {
    tableHeader: isProjectVariant
      ? 'projectDrawer.projectStatusDetails'
      : 'taskStatus.taskStatusDetails',
    actuals: isPSAPRPTaskEstimateCalculationMethodEnabled
      ? 'taskDrawer.Total Work Done'
      : 'taskDrawer.actuals',
    noDate: isProjectVariant
      ? 'projectDrawer.noStartDate'
      : 'taskDrawer.notStarted',
    workRemaining: 'taskDrawer.workRemaining',
    totalExpenses: 'taskDrawer.totalExpenses',
    expensesRemaining: 'taskDrawer.expensesRemaining',
    estimatedAtCompletion: isProjectVariant
      ? 'taskDrawer.estimatedAtTaskCompletion'
      : isPSAPRPTaskEstimateCalculationMethodEnabled
      ? 'taskDrawer.estimatedAtTaskCompletion'
      : 'taskDrawer.estimatedAtCompletion',
    na: 'taskDrawer.na',
    estimateBudget: isProjectVariant
      ? 'projectDrawer.projectBudget'
      : 'taskDrawer.originalEstimate'
  };
};

export default useTaskSummaryLabels;
