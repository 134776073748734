import { ListItemText, ListSubheader, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddSharp';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { AddIconButton, Date } from '~/modules/common/components';
import { MoneyValue } from '~/modules/common/components/Money';
import { useMenuState } from '~/modules/common/hooks';
import { BillStatus } from '~/types';
import {
  AssignNewCreditMemoDrawer,
  AssignNewPaymentDrawer
} from './components';
import { styles } from './withStyles';

const getComponentFromVariant = variant =>
  ({
    creditMemo: AssignNewCreditMemoDrawer,
    payment: AssignNewPaymentDrawer
  }[variant]);

const menuProps = {
  origin: { vertical: 'top', horizontal: 'left' },
  listProp: {
    dense: true
  }
};
const useDisplayStyles = makeStyles(theme => ({
  displayText: {
    display: 'inline-block',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    maxWidth: theme.spacing(30)
  }
}));
const useStyles = makeStyles(styles);

export const AssignBillCreditButton = ({
  availableBillCredits = [],
  resourceKeys,
  billId,
  client,
  billBalanceTotal,
  queryParams,
  variant,
  onSelection,
  dataQeId,
  billStatus
}) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const displayClass = useDisplayStyles();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isOpen = Boolean(anchorEl);

  const Tag = getComponentFromVariant(variant);
  const onMenuItemClick = useCallback(
    item => async () => {
      onSelection(item);
      onMenuClose();
    },
    [onMenuClose, onSelection]
  );

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);
  const closeDrawer = useCallback(() => setDrawerOpen(false), []);
  const onOpenDrawer = useCallback(() => {
    openDrawer();
    onMenuClose();
  }, [onMenuClose, openDrawer]);

  const buttonColor = billStatus === BillStatus.Billed ? 'primary' : 'default';

  return (
    <>
      <AddIconButton
        onClick={onMenuClick}
        classes={useMemo(() => ({ root: classes.addIconButton }), [
          classes.addIconButton
        ])}
        data-qe-id={dataQeId}
        color={buttonColor}
      >
        <FormattedMessage id={resourceKeys.addButton} />
      </AddIconButton>
      <Menu
        id="assign-bill-credit"
        anchorEl={anchorEl}
        anchorOrigin={menuProps.origin}
        transformOrigin={menuProps.origin}
        open={isOpen}
        onClose={onMenuClose}
        MenuListProps={menuProps.listProp}
      >
        {Boolean(availableBillCredits && availableBillCredits.length > 0) && (
          <ListSubheader role="presentation" className={classes.subheader}>
            <span className={classes.itemLabel}>
              <FormattedMessage id={resourceKeys.idColumn} />
            </span>
            <span className={classes.itemBalance}>
              <FormattedMessage id={resourceKeys.balanceColumn} />
            </span>
          </ListSubheader>
        )}
        {(availableBillCredits || []).map(item => (
          <MenuItem
            key={item.id}
            creditmemouri={item.id}
            onClick={onMenuItemClick(item)}
          >
            <ListItemText
              className={displayClass.displayText}
              primary={item.displayText}
              secondary={
                <Date elementType="span" value={item.transactionDate} />
              }
            />
            <MoneyValue
              className={classes.contentBalance}
              money={item.balanceAmount}
            />
          </MenuItem>
        ))}
        <MenuItem className={classes.addMenuItem} onClick={onOpenDrawer}>
          <AddIcon />
          <FormattedMessage id={resourceKeys.addButton} />
        </MenuItem>
      </Menu>
      {drawerOpen && (
        <Tag
          open={drawerOpen}
          onClose={closeDrawer}
          billId={billId}
          client={client}
          billBalanceTotal={billBalanceTotal}
          queryParams={queryParams}
        />
      )}
    </>
  );
};
AssignBillCreditButton.propTypes = {
  resourceKeys: PropTypes.object.isRequired,
  billId: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['creditMemo', 'payment']),
  client: PropTypes.object.isRequired,
  availableBillCredits: PropTypes.array,
  billBalanceTotal: PropTypes.object,
  queryParams: PropTypes.any,
  onSelection: PropTypes.func,
  dataQeId: PropTypes.string,
  billStatus: PropTypes.string
};

export default AssignBillCreditButton;
