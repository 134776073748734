import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { ChartHeader } from '~/modules/common/charts/timeline/components';

const useStyles = makeStyles(theme => ({
  root: {
    top: theme.spacing(12.5) + 1,
    left: 0,
    zIndex: 1
  }
}));

const useSelectionStyles = makeStyles(theme => ({
  name: { marginLeft: theme.spacing(3.25) }
}));

const projectsText = <FormattedMessage id="projectList.project" />;

export const ProjectTimelineChartHeader = ({
  scale,
  chartDates,
  leftComponentWidth,
  isSelectionEnabled
}) => {
  const classes = useStyles();
  const selecionClasses = useSelectionStyles();

  const leftComponent = useMemo(
    () => (
      <span
        className={classNames({
          [selecionClasses.name]: isSelectionEnabled
        })}
      >
        {projectsText}
      </span>
    ),
    [selecionClasses.name, isSelectionEnabled]
  );

  return (
    <ChartHeader
      leftComponent={leftComponent}
      classes={classes}
      leftComponentWidth={leftComponentWidth}
      scale={scale}
      chartDates={chartDates}
    />
  );
};

ProjectTimelineChartHeader.propTypes = {
  chartDates: PropTypes.array,
  scale: PropTypes.string,
  leftComponentWidth: PropTypes.number.isRequired,
  isSelectionEnabled: PropTypes.bool.isRequired
};

export default ProjectTimelineChartHeader;
