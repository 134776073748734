import React, { useCallback, useMemo } from 'react';
import { Grid, Button, Typography, makeStyles } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { AddResourceIcon, Delta } from '~/modules/common/components';
import FormattedHoursAndCost from '~/modules/resourcing/common/components/FormattedHoursAndCost';

const addResourceIcon = <AddResourceIcon />;

const diffHoursTooltip = (
  <FormattedMessage id="taskResourceAssignments.diffHoursToolTip" />
);

const diffCostsTooltip = (
  <FormattedMessage id="taskResourceAssignments.diffCostsToolTip" />
);

const useStyles = makeStyles(theme => ({
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1)
  },
  summaryLabels: {
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary
  },
  costDiff: {
    paddingTop: theme.spacing(1)
  },
  costDiffWithoutDelta: {
    paddingTop: theme.spacing(3.25)
  }
}));

export const useDeltaStyles = makeStyles(theme => ({
  dateWithDeltaContainer: {
    ...theme.typography.caption,
    padding: theme.spacing(1 / 8, 1 / 4),
    borderRadius: theme.shape.borderRadius
  },
  deltaLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightRegular
  },
  negative: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light
  },
  positive: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light
  }
}));

const useSummaryStyles = makeStyles(theme => ({
  currency: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1)
  },
  hours: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const TaskResourceEstimatesFooter = ({
  taskEstimatedCost,
  taskEstimatedHours,
  onResourceEstimateAdd,
  showSummary,
  showTotalCostRow = false,
  totalEstimatedHours,
  totalEstimatedCost,
  styleProps: { gridItemStyle }
}) => {
  const classes = useStyles();
  const summaryClasses = useSummaryStyles();
  const deltaClasses = useDeltaStyles();
  const { formatMessage } = useIntl();

  const diffHours = totalEstimatedHours - taskEstimatedHours;
  const diffCosts = totalEstimatedCost?.amount - taskEstimatedCost?.amount;

  const formattedCosts = useMemo(() => {
    if (totalEstimatedCost) {
      return [totalEstimatedCost];
    }

    return [];
  }, [totalEstimatedCost]);

  const addResourceAssignment = useCallback(() => {
    onResourceEstimateAdd({ rowId: uuid() });
  }, [onResourceEstimateAdd]);

  return (
    <Grid container>
      <Grid item style={gridItemStyle}>
        <Button
          color="primary"
          startIcon={addResourceIcon}
          onClick={addResourceAssignment}
        >
          {formatMessage({ id: 'taskResourceAssignments.resource' })}
        </Button>
      </Grid>
      {showSummary && (
        <>
          <Grid item style={gridItemStyle}>
            <div>
              <Typography variant="body2" className={classes.summaryLabels}>
                {formatMessage({
                  id: 'taskResourceAssignments.totalEstimatedHours'
                })}
              </Typography>
              {showTotalCostRow && (
                <Typography variant="body2" className={classes.summaryLabels}>
                  {formatMessage({
                    id: 'taskResourceAssignments.totalEstimatedCost'
                  })}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item style={gridItemStyle}>
            <div className={classes.summary}>
              <div className={classes.diff}>
                {Boolean(diffHours) && (
                  <Delta
                    delta={diffHours}
                    precision={2}
                    suffix={formatMessage({
                      id: 'taskResourceAssignments.hoursSuffix'
                    })}
                    tooltipTitle={diffHoursTooltip}
                    classes={deltaClasses}
                  />
                )}
                {showTotalCostRow && (
                  <div
                    className={
                      diffHours
                        ? classes.costDiff
                        : classes.costDiffWithoutDelta
                    }
                  >
                    {Boolean(diffCosts) && (
                      <Delta
                        delta={diffCosts}
                        prefix={`${totalEstimatedCost?.currencySymbol}`}
                        precision={2}
                        classes={deltaClasses}
                        tooltipTitle={diffCostsTooltip}
                      />
                    )}
                  </div>
                )}
              </div>
              <FormattedHoursAndCost
                classes={summaryClasses}
                hours={totalEstimatedHours}
                hideCosts
                hoursSuffix={formatMessage({
                  id: 'taskResourceAssignments.hoursSuffix'
                })}
                costs={formattedCosts}
                showAbbreviatedValue={false}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
        </>
      )}
    </Grid>
  );
};

TaskResourceEstimatesFooter.propTypes = {
  taskEstimatedCost: PropTypes.object,
  taskEstimatedHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onResourceEstimateAdd: PropTypes.func,
  showSummary: PropTypes.bool,
  showTotalCostRow: PropTypes.bool,
  totalEstimatedHours: PropTypes.number,
  totalEstimatedCost: PropTypes.object,
  styleProps: PropTypes.object
};

export default TaskResourceEstimatesFooter;
