import { useMeContext } from '~/modules/me/useMeContext';

export const useColumnVisibilityProp = ({ editable, viewSummary }) => {
  const {
    hasViewProjectCostType,
    hasViewProjectBillingOptions
  } = useMeContext();
  const isPPMProduct = Boolean(hasViewProjectCostType);
  const isPSAProduct = Boolean(hasViewProjectBillingOptions);

  const isLabelRowColumnVisible = !isPPMProduct && (editable || viewSummary);
  const isBillableTypeRowColumnVisible =
    isPSAProduct && (editable || viewSummary);

  return {
    isLabelRowColumnVisible,
    isBillableTypeRowColumnVisible
  };
};

export default useColumnVisibilityProp;
