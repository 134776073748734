export const PERIOD_SCALE_ENUM = {
  YEARS: 'years',
  QUARTERS: 'quarters',
  MONTHS: 'months',
  WEEKS: 'weeks',
  DAYS: 'days',
  ENTIRE_PROJECT: 'entire-project'
};

export const SCALE_URI_ENUM = {
  days: 'urn:replicon:period-resolution:daily',
  weeks: 'urn:replicon:period-resolution:weekly',
  months: 'urn:replicon:period-resolution:monthly',
  quarters: 'urn:replicon:period-resolution:quarterly',
  years: 'urn:replicon:period-resolution:yearly'
};

export const DEFAULT_PERIOD_SCALE = PERIOD_SCALE_ENUM.MONTHS;
