import React, { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useOnChangeHandlers } from '../hooks/useOnChangeHandlers';
import { MODE } from './enum';
import { getDurationSummaryValues } from './durationUtil';
import OpenEndedTaskIcon from './OpenEndedTaskIcon';

const useStyles = makeStyles(theme => ({
  dropdownButton: {
    width: '84%',
    textTransform: 'none',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: 'transparent'
    },
    textAlign: 'left'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(1, 0, 1, 0)
  },
  arrowIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-3.5)
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3, 2, 3)
  },
  value: {
    ...theme.typography.body2,
    color: theme.palette.text.primary
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    whiteSpace: 'normal'
  }
}));

const useDurationStyles = makeStyles(theme => ({
  infoIcon: {
    margin: theme.spacing(0, 0, -0.75, 1)
  },
  duration: {
    marginTop: theme.spacing(-1.5),
    paddingBottom: theme.spacing(0)
  }
}));

const getDropdownOptions = formatMessage => ({
  [MODE.KEEP_TASK_WORK_DAYS]: {
    label: formatMessage({ id: 'rescheduleTaskDialog.keepTaskWorkDays' }),
    description: formatMessage({
      id: 'rescheduleTaskDialog.keepTaskWorkDaysDescription'
    })
  },
  [MODE.KEEP_TASK_LENGTH]: {
    label: formatMessage({ id: 'rescheduleTaskDialog.keepTaskLength' }),
    description: formatMessage({
      id: 'rescheduleTaskDialog.keepTaskLengthDescription'
    })
  },
  [MODE.CUSTOM]: {
    label: formatMessage({ id: 'rescheduleTaskDialog.custom' }),
    description: formatMessage({
      id: 'rescheduleTaskDialog.customDescription'
    })
  }
});

const DurationModeDropdown = ({ mode, onModeChange, newTaskDateRange }) => {
  const classes = useStyles();
  const durationClasses = useDurationStyles();
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    handleModeClick,
    handleModeClose,
    handleModeSelect
  } = useOnChangeHandlers({
    setAnchorEl,
    onModeChange
  });

  const arrowIcon = useMemo(
    () => <ArrowDropDownIcon className={classes.arrowIcon} />,
    [classes.arrowIcon]
  );

  const dropdownOptions = getDropdownOptions(formatMessage);

  return (
    <Grid container direction="column">
      <Button
        className={classes.dropdownButton}
        onClick={handleModeClick}
        endIcon={arrowIcon}
        disableRipple
      >
        <div className={classes.contentWrapper}>
          <Typography className={classes.label}>
            {dropdownOptions[mode].label}
          </Typography>
          <Typography className={classes.value}>
            {newTaskDateRange.startDate && newTaskDateRange.endDate ? (
              <FormattedMessage
                id="rescheduleTaskDialog.duration"
                values={getDurationSummaryValues(
                  newTaskDateRange.startDate,
                  newTaskDateRange.endDate
                )}
              />
            ) : (
              <OpenEndedTaskIcon classes={durationClasses} />
            )}
          </Typography>
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleModeClose}
      >
        {Object.entries(dropdownOptions).map(
          ([key, { label, description }]) => (
            <MenuItem
              key={key}
              onClick={() => handleModeSelect(key)}
              className={classes.menuItem}
            >
              <Grid container direction="column">
                <Typography className={classes.value}>{label}</Typography>
                <Typography className={classes.label}>{description}</Typography>
              </Grid>
            </MenuItem>
          )
        )}
      </Menu>
    </Grid>
  );
};

DurationModeDropdown.propTypes = {
  mode: PropTypes.string,
  onModeChange: PropTypes.func,
  newTaskDateRange: PropTypes.object
};

export default DurationModeDropdown;
