import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ButtonBase, Typography, Tooltip, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import DateRange from '~/modules/common/components/DateRange';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.625, 1, 0.625, 1.5),
    width: '100%',
    height: 'inherit',
    display: 'flex',
    borderLeft: ({ workflowUri }) =>
      `${theme.spacing(0.5)}px solid ${
        theme.palette.projectWorkflow[workflowUri]
      }`
  },
  nameContainer: {
    flexShrink: 1,
    flexGrow: 1,
    overflow: 'hidden'
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '16px',
    overflow: 'hidden',
    whiteSpace: 'normal',
    marginBottom: theme.spacing(0.25)
  },
  checkbox: {
    marginLeft: theme.spacing(-0.5)
  },
  selection: {
    marginLeft: theme.spacing(-1)
  }
}));

const useDateRangeStyles = makeStyles({
  range: {
    display: 'block',
    overflow: 'hidden',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

export const ProjectTimelineChartRowProject = ({
  project,
  isSelected,
  onRowSelectionChange
}) => {
  const { displayText, slug, startDate, endDate, workflow } = project;

  const classes = useStyles({ workflowUri: workflow && workflow.uri });
  const dateRangeClasses = useDateRangeStyles();

  const handleOnRowSelectionChange = useCallback(() => {
    onRowSelectionChange(project.id, !isSelected);
  }, [project.id, isSelected, onRowSelectionChange]);

  return (
    <>
      {!!onRowSelectionChange && (
        <Checkbox
          checked={isSelected}
          onClick={handleOnRowSelectionChange}
          className={classes.checkbox}
        />
      )}
      <ButtonBase
        className={classNames(classes.root, {
          [classes.selection]: !!onRowSelectionChange
        })}
        component={Link}
        to={`/projects/${slug}`}
      >
        <div className={classes.nameContainer}>
          <Tooltip title={displayText}>
            <Typography
              className={classes.name}
              component="div"
              color="inherit"
            >
              {displayText}
            </Typography>
          </Tooltip>
          <DateRange
            classes={dateRangeClasses}
            start={startDate}
            end={endDate}
          />
        </div>
      </ButtonBase>
    </>
  );
};

ProjectTimelineChartRowProject.propTypes = {
  project: PropTypes.object,
  isSelected: PropTypes.bool,
  onRowSelectionChange: PropTypes.func
};

export default ProjectTimelineChartRowProject;
