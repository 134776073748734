import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RevenueRecognitionIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="m 1,20.61453965424405 v 0 L 1,5 c 0,0 2,-1.5 5.5,-1.5 3.5,0 4.5,1 4.5,1 V 18 h 2 V 4.5 c 0,0 1,-1 4.5,-1 3.5,0 5.5,1.5 5.5,1.5 V 20.5 C 21,19.34529946162074 19,19 17.5,19 14,20.88746034576727 10,20.88746034576727 6.5,19 5,19 3,19.34529946162075 1,20.5 Z m 14.25,-7.437 v 0 c 0.0837866710454,1.08852460830153 0.92744754742835,1.96489201965417 2.012,2.1 v 1.193 h 1.643 V 15.28053965424149 C 20.125,15.05153965424213 20.822,14.3445396542327 20.822,13.33753965424218 c 0,-1.531 -1.3,-2.074 -2.57,-2.406 -1.2,-0.311 -1.643,-0.629 -1.643,-1.174 0,-0.617 0.58,-1.016 1.477,-1.016 0.897,0 1.336,0.377 1.369,1.152 h 1.209 C 20.63590201475551,8.873699839143082 19.90791987424575,8.008080984141297 18.908,7.805539654242942 V 6.605539654242211 h -1.643 v 1.18 c -1.03667144454471,0.10238579144446 -1.84719445137172,0.939559606950324 -1.916,1.979 0,1.121 0.816,1.838 2.572,2.256 1.449,0.354 1.646,0.865 1.646,1.318 0,0.473 -0.391,0.98 -1.482,1 -1.258,0 -1.58,-0.623 -1.629,-1.146 h -1.2 z" />
    </>
  </SvgIcon>
));

export default RevenueRecognitionIcon;
