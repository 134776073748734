import React from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import isempty from 'lodash.isempty';
import { FormControl, FormHelperText } from '@material-ui/core';
import SearchableRoleDropDown from '~/modules/resourcing/common/components/SearchableRoleDropDown';

export const DropdownInputComponent = ({
  field = {},
  form: { touched = {}, errors = {} } = {},
  onDropdownFieldChange,
  value,
  label,
  permittedActions,
  disabledValues
}) => {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const canUserEditSkillAssignment = permittedActions.includes(
    'urn:replicon:user-action:edit-skill-assignments'
  );

  return (
    <>
      <SearchableRoleDropDown
        value={value}
        showRate
        onChange={onDropdownFieldChange}
        canUserEditSkillAssignment={canUserEditSkillAssignment}
        label={label}
        disabledValues={disabledValues}
      />
      {!isempty(error) && touch && (
        <FormControl error>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      )}
    </>
  );
};

DropdownInputComponent.propTypes = {
  form: PropTypes.object,
  value: PropTypes.object,
  field: PropTypes.object,
  disabledValues: PropTypes.array,
  label: PropTypes.string,
  onDropdownFieldChange: PropTypes.func.isRequired,
  permittedActions: PropTypes.array
};

export default DropdownInputComponent;
