import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { ProgramStatusFragmentDoc } from '~/types';

export const DELETE_PROGRAM_MUTATION = gql`
  mutation DeleteProgram($id: String!) {
    deleteProgram2(id: $id) {
      id
      status
    }
  }
`;

export const updateCache = programId => (proxy, { data }) => {
  if (data && data.deleteProgram2) {
    const {
      deleteProgram2: { status }
    } = data;

    const fragmentId = `Program:${programId}`;

    const program = proxy.readFragment({
      id: fragmentId,
      fragment: ProgramStatusFragmentDoc
    });

    proxy.writeFragment({
      id: fragmentId,
      fragment: ProgramStatusFragmentDoc,
      data: {
        ...program,
        isActive: status !== 'DISABLED'
      }
    });
  }
};

export const useDeleteProgram = ({ objectId: programId }) => {
  const [deleteProgram] = useMutation(DELETE_PROGRAM_MUTATION, {
    update: updateCache(programId)
  });

  return deleteProgram;
};
