import { useCallback, useRef } from 'react';
import { gantt } from '@replicon/dhtmlx-gantt';
import eventBus from '~/modules/App/eventBus';
import { EVENT_TYPE } from '~/modules/common/enums/EventTypes';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';

const onBeforeLightbox = () => false;

const incrementGanttRowAriaLevel = () =>
  document.querySelectorAll('.gantt_row').forEach(el => {
    // eslint-disable-next-line no-param-reassign
    el.ariaLevel++;
  });

export const useGanttEventCallbacks = ({
  me,
  isRTL,
  onTaskClick,
  onTaskKeyPress,
  onGridHeaderClick,
  onBeforeTaskDrag,
  onAfterTaskDrag,
  onAfterTaskMove,
  validateTaskMove,
  onTaskHoverTooltips,
  showExpandedTasks,
  handleTaskResourceCountModification
}) => {
  const attachedEventsRef = useRef([]);
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: {
      isPsaRmpTaskAllocation1Enabled,
      PSAPRPTaskEstimateCalculation
    }
  } = me;

  const attachEvents = useCallback(() => {
    attachedEventsRef.current.push(
      gantt.attachEvent('onBeforeLightbox', onBeforeLightbox)
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onTaskClick', function handleOnTaskClick(id, event) {
        onTaskClick(this)(id, event);
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onGridHeaderClick', function handleOnGridHeaderClick(
        name
      ) {
        onGridHeaderClick(this)(name);
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onBeforeRowDragEnd', (id, target) => {
        if (isRolledUpTaskEstimateCalculationMethodEnabled && !target) {
          return false;
        }

        return true;
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onAfterTaskDrag', function handleOnAfterTaskDrag(
        id,
        mode
      ) {
        onAfterTaskDrag(this)(id, mode);
      })
    );
    if (isRolledUpTaskEstimateCalculationMethodEnabled) {
      attachedEventsRef.current.push(
        gantt.attachEvent('onBeforeTaskDrag', function handleOnBeforeTaskDrag(
          id,
          mode
        ) {
          return onBeforeTaskDrag(this)(id, mode);
        })
      );
    }
    attachedEventsRef.current.push(
      gantt.attachEvent('onBeforeTaskMove', function handleOnBeforeTaskMove(
        id,
        parent
      ) {
        return validateTaskMove(this)(id, parent);
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onAfterTaskMove', function handleOnAfterTaskMove(
        id,
        parent
      ) {
        onAfterTaskMove(this)(id, parent);
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onParse', function handleOnParse() {
        showExpandedTasks(this);
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onGanttRender', function handleOnRender() {
        onTaskKeyPress(this);
        incrementGanttRowAriaLevel();
        if (isRolledUpTaskEstimateCalculationMethodEnabled) {
          onTaskHoverTooltips(this, isRTL);
        }
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onDataRender', function handleOnDataRender() {
        if (isRolledUpTaskEstimateCalculationMethodEnabled) {
          onTaskHoverTooltips(this, isRTL);
        }
      })
    );
    attachedEventsRef.current.push(
      gantt.attachEvent('onGanttScroll', function handleScroll() {
        onTaskKeyPress(this);
        incrementGanttRowAriaLevel();
        if (isRolledUpTaskEstimateCalculationMethodEnabled) {
          onTaskHoverTooltips(this, isRTL);
        }
      })
    );

    if (
      isPsaRmpTaskAllocation1Enabled &&
      PSAPRPTaskEstimateCalculation ===
        TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT
    )
      eventBus.subscribe(
        EVENT_TYPE.GANTT_TASK_RESOURCE_COUNT_UPDATE,
        handleTaskResourceCountModification
      );
  }, [
    isRolledUpTaskEstimateCalculationMethodEnabled,
    onBeforeTaskDrag,
    onAfterTaskDrag,
    onAfterTaskMove,
    onGridHeaderClick,
    onTaskClick,
    onTaskKeyPress,
    validateTaskMove,
    onTaskHoverTooltips,
    showExpandedTasks,
    handleTaskResourceCountModification,
    isRTL,
    isPsaRmpTaskAllocation1Enabled,
    PSAPRPTaskEstimateCalculation
  ]);

  const detachEvents = () => {
    attachedEventsRef.current.forEach(event => gantt.detachEvent(event));
    attachedEventsRef.current = [];
    eventBus.unsubscribe(
      EVENT_TYPE.GANTT_TASK_RESOURCE_COUNT_UPDATE,
      handleTaskResourceCountModification
    );
  };

  return {
    attachEvents,
    detachEvents
  };
};

export default useGanttEventCallbacks;
