import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { mapToServiceFilter } from '../utils';

export const availableToBillTotalsQuery = gql`
  query getAvailableToBillTotals($filter: AvailableToBillFilter2) {
    getAvailableToBillTotals: getAvailableToBillTotals2(filter: $filter) {
      amount {
        amount
        currency {
          symbol
        }
      }
      amountInBaseCurrency {
        amount
        currency {
          symbol
        }
      }
    }
  }
`;

export const useAvailableToBillTotals = ({
  projectUri,
  client,
  programId,
  availableToBillFilter,
  skip
}) => {
  const { data, loading, refetch } = useQuery(availableToBillTotalsQuery, {
    variables: {
      filter: mapToServiceFilter({
        projectUri,
        client,
        programUri: programId,
        availableToBillFilter
      })
    },
    skip,
    fetchPolicy: 'network-only'
  });

  const getAvailableToBillTotals = get(data, 'getAvailableToBillTotals', null);

  return {
    totals:
      getAvailableToBillTotals && getAvailableToBillTotals.amount
        ? {
            ...getAvailableToBillTotals,
            amount: getAvailableToBillTotals.amount.filter(
              availableTotal => availableTotal.amount !== 0
            )
          }
        : { ...getAvailableToBillTotals, amount: [] },
    isTotalsLoading: loading,
    refetchFilteredTotals: refetch
  };
};

export default useAvailableToBillTotals;
