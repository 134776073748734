import React, { useState, useMemo } from 'react';
import { scoredSuggestions } from '../../../scoredSuggestions';
import { useClientManagers } from './useClientManagers';

export const mapOptions = clientManagers =>
  clientManagers.map(clientManager => ({
    ...clientManager,
    key: clientManager.displayText,
    label: clientManager.displayText,
    value: clientManager.id
  }));

export const matchOption = (options, term) => {
  const clientManager = options.find(
    ({ key, id = '' }) =>
      key.toLowerCase() === term.toLowerCase() ||
      id.toLowerCase() === term.toLowerCase()
  );

  return clientManager || null;
};

const withCriteriaProvider = (tag, context) => BaseComponent => ({
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const { clientManagers } = useClientManagers();
  const options = mapOptions(clientManagers);

  const value = useMemo(
    () => ({
      options,
      matchOption,
      selected,
      setSelected,
      suggestions: scoredSuggestions(options)
    }),
    [options, selected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
