import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import LabelledIcon from '~/modules/common/components/LabelledIcon';
import { ClientIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(() => ({
  container: { paddingTop: 0, paddingBottom: 0 }
}));

const clientIcon = <ClientIcon />;

export const ClientColumn = ({ field, record }) => {
  const classes = useStyles();
  const iconClasses = useMemo(() => ({ container: classes.container }), [
    classes.container
  ]);
  const client = record[field];

  return (
    client && (
      <LabelledIcon
        classes={iconClasses}
        icon={clientIcon}
        label={client.displayText}
      />
    )
  );
};

ClientColumn.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default ClientColumn;
