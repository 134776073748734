import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { CancelButton } from '~/modules/common/components';
import { useMarkResourceRequestAsCompleteAndMerge } from '~/modules/resourcing/common/hooks';
import MergeResourceRequestDialogContent from './MergeResourceRequestDialogContent';

export const MergeResourceRequestDialog = ({
  open,
  onClose,
  resourceRequest
}) => {
  const resourceAllocationCount = (resourceRequest?.resourceAllocations || [])
    .length;

  const {
    onMarkResourceRequestAsCompleteAndMerge,
    isLoading
  } = useMarkResourceRequestAsCompleteAndMerge({
    resourceRequest
  });

  const handleClick = useCallback(() => {
    onMarkResourceRequestAsCompleteAndMerge();
    if (!isLoading) {
      onClose();
    }
  }, [isLoading, onClose, onMarkResourceRequestAsCompleteAndMerge]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage
          id="mergeResourceRequestDialog.mergeResourceTitle"
          values={{ count: resourceAllocationCount }}
        />
      </DialogTitle>
      <MergeResourceRequestDialogContent
        isLoading={isLoading}
        resourceAllocationCount={resourceAllocationCount}
      />
      <DialogActions>
        <CancelButton onClick={onClose} disabled={isLoading} />
        <Button color="primary" onClick={handleClick} disabled={isLoading}>
          <FormattedMessage id="mergeResourceRequestDialog.acceptAndMerge" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MergeResourceRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  resourceRequest: PropTypes.object
};

export default MergeResourceRequestDialog;
