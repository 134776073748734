import React from 'react';
import PropTypes from 'prop-types';
import { DivisionIcon } from '~/modules/common/components/Icons';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupName from './GroupName';

export const DivisionName = ({
  value,
  divisionSettings,
  variant,
  showFullPath,
  disableIcon
}) => (
  <GroupName
    value={value}
    Icon={DivisionIcon}
    groupSettings={divisionSettings}
    variant={variant}
    showFullPath={showFullPath}
    disableIcon={disableIcon}
  />
);

DivisionName.propTypes = {
  value: PropTypes.object,
  divisionSettings: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['icon', 'avatar']),
  showFullPath: PropTypes.bool,
  disableIcon: PropTypes.bool
};

export default withGroupSettings('division', 'divisionSettings')(DivisionName);
