import { withCustomFetchCriteriaProvider } from '../enhancers';
import ProjectSuggestionItem from '../GenericProjectFacetDetails/ProjectSuggestionItem';
import { projectTag } from '../ProjectFacet/tag';
import { useSearchableProjects } from './useProjectOptionsForBillingItems';
import BillingProjectFacetDetails from './BillingProjectFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableProjects);

const billingProjectFacetDetails = ({ clientUri = null }) => ({
  tag: projectTag,
  makeCriteriaProvider,
  DetailsControl: BillingProjectFacetDetails({ clientUri }),
  SuggestionItem: ProjectSuggestionItem
});

export default billingProjectFacetDetails;
