import { gql } from 'graphql-tag';

const specificResourceAllocationUserFragment = gql`
  fragment SpecificResourceAllocationUser on ResourceAllocationUser {
    userAllocationId
    userUri
    user {
      slug
      displayText
      uri
      permittedActionUris
      roles {
        projectRole {
          uri
          name
        }
        isPrimary
      }
    }
    userType
    costRate
    primaryRoleCostRate
    primaryRoleCostCurrencyUri
    primaryRoleCostCurrency {
      id
      displayText
    }
    costCurrencyUri
    costCurrency {
      id
      displayText
    }
  }
`;

export default specificResourceAllocationUserFragment;
