import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    textAlign: 'right',
    fontSize: theme.typography.caption.fontSize
  },
  hours: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  },
  cost: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  currency: {
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export default useStyles;
