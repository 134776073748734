import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BillIcon } from '~/modules/common/components/Icons';
import {
  DrawerFooter,
  DrawerHeader
} from '~/modules/billing-invoicing/common/components';
import { styles } from '~/modules/billing-invoicing/common/inProgressStyle';
import {
  useBillFormState,
  usePutBillHandler,
  usePutBillFormState
} from '~/modules/billing-invoicing/bill/hooks';
import {
  useInvoiceDefaultTemplate,
  useFormOnCancel,
  useNextReferenceNumberText,
  useClientDefaultData
} from '~/modules/billing-invoicing/common/hooks';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';
import { useMeContext } from '~/modules/me';
import { BillInfo } from '../BillDetails';

const useStyles = makeStyles(styles);

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

const footerResourceKeys = {
  actionButton: 'addDialog.addBill'
};

export const AddBillDrawer = ({
  history,
  open,
  onClose,
  projectUri,
  projectName,
  client,
  isGlobalBilling = false
}) => {
  const drawerClasses = useDrawerStyles();
  const classes = useStyles();
  const me = useMeContext();
  const { saving, setSaving, setEditable } = useBillFormState();
  const { putBill } = usePutBillHandler();

  const { clientDefaultData } = useClientDefaultData({ client });

  const { invoiceDefaultTemplate } = useInvoiceDefaultTemplate();
  const { nextReferenceNumberText } = useNextReferenceNumberText(
    billingTransactionType.BILL
  );

  const { billingContactInfo, billingSettings, taxProfile } =
    clientDefaultData || {};

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    errors,
    isSubmitting
  } = usePutBillFormState({
    projectUri,
    projectName,
    me,
    client,
    putBill,
    setSaving,
    onClose,
    setEditable,
    history,
    isGlobalBilling,
    invoiceDefaultTemplate,
    billingAddress: billingContactInfo,
    nextReferenceNumberText,
    billingSettings,
    clientTaxProfile: taxProfile
  });

  const { onCancel } = useFormOnCancel({ handleReset, onClose, saving });

  return (
    <MuiDrawer anchor="right" open={open} classes={drawerClasses}>
      <DrawerHeader
        onClose={onCancel}
        entityName={client && client.displayText}
        TitleIcon={BillIcon}
      >
        <FormattedMessage id="addDialog.addBill" />
      </DrawerHeader>

      {saving ? (
        <Grid container className={classes.container} spacing={2}>
          <CircularProgress
            data-qe-id="addBill_saving"
            size={24}
            className={classes.progress}
          />
        </Grid>
      ) : (
        <>
          <BillInfo
            values={values}
            hasClient={Boolean(client && client.id)}
            currencyEditable
            setFieldValue={setFieldValue}
            errors={errors}
            isSubmitting={isSubmitting}
            invoiceDefaultTemplate={invoiceDefaultTemplate}
          />
          <DrawerFooter
            showTotal={false}
            isSaving={saving}
            resourceKeys={footerResourceKeys}
            onAdd={handleSubmit}
            onCancel={onCancel}
          />
        </>
      )}
    </MuiDrawer>
  );
};

AddBillDrawer.propTypes = {
  history: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectUri: PropTypes.string,
  projectName: PropTypes.string,
  client: PropTypes.object,
  isGlobalBilling: PropTypes.bool
};

export default AddBillDrawer;
