import { CardContent } from '@material-ui/core';
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PERIOD_RESOLUTION_ENUM } from '~/modules/resourcing/common/enums';
import { DEFAULT_OVERVIEW_UNIT } from '~/modules/resource-management/common/enums';
import { SortDirection, ResourceUserAvailabilityGroupType } from '~/types';
import { useKeyValueSettingsMutation } from '~/modules/common/hooks';
import { useResourceManagementContext } from '~/modules/resource-management/common';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';
import {
  useResourceAvailabilityPermissions,
  useResourceOverviewToolbarContext
} from '../../hooks';
import ResourcingOverviewUnitDropDown from '../ResourcingOverviewUnitDropdown';
import ResourcingCard from '../ResourcingCard';
import {
  AvailabilityDimensionDropdown,
  AvailabilityLegend,
  AvailabilityTable
} from './components';
import {
  useResourceUserAvailability,
  useResourceUserAvailabilitySeriesTotals,
  useGetDefaultGroupField
} from './hooks';
import { useContentStyles, useCardStyles } from './useStyles';

const title = (
  <FormattedMessage id="resourceAvailabilityCard.resourceAvailability" />
);

export const ResourceAvailabilityCard = ({
  settings = {
    groupDropdown: ResourceUserAvailabilityGroupType.Role,
    overViewUnit: DEFAULT_OVERVIEW_UNIT
  },
  currentPage,
  setCurrentPage
}) => {
  const contentClasses = useContentStyles();
  const cardClasses = useCardStyles();
  const [availabilitySettings, setAvailabilitySettings] = useState({
    groupDropdown:
      settings.groupDropdown || ResourceUserAvailabilityGroupType.ROLE,
    overViewUnit: settings.overViewUnit || DEFAULT_OVERVIEW_UNIT
  });

  const {
    isViewRoleEnabled,
    isViewCostCenterEnabled,
    isViewDivisionEnabled,
    isViewDepartmentEnabled,
    isViewEmployeeTypeEnabled,
    isViewLocationEnabled,
    isViewServiceCenterEnabled
  } = useResourceAvailabilityPermissions();

  const groupByField = useGetDefaultGroupField({
    settings: availabilitySettings,
    isViewRoleEnabled,
    isViewCostCenterEnabled,
    isViewDivisionEnabled,
    isViewDepartmentEnabled,
    isViewEmployeeTypeEnabled,
    isViewLocationEnabled
  });

  const [groupBy, setGroupBy] = useState(groupByField);

  const [putKeyValueSettings] = useKeyValueSettingsMutation();
  const [overViewUnit, setOverViewUnit] = useState(
    availabilitySettings.overViewUnit
  );
  const [sort, setSort] = useState({
    field: groupBy,
    direction: SortDirection.Asc
  });

  const onGroupByChange = useCallback(
    newValue => {
      setCurrentPage(1);
      setGroupBy(newValue);
      setSort({
        field: newValue,
        direction: SortDirection.Asc
      });

      const newSettings = {
        ...availabilitySettings,
        groupDropdown: newValue
      };

      setAvailabilitySettings(newSettings);

      putKeyValueSettings({
        variables: {
          input: {
            key: 'availability_settings',
            settings: newSettings
          }
        }
      });
    },
    [
      setCurrentPage,
      setSort,
      setGroupBy,
      availabilitySettings,
      putKeyValueSettings
    ]
  );

  const onOverviewUnitChange = useCallback(
    newValue => {
      setOverViewUnit(newValue);
      const newSettings = {
        ...availabilitySettings,
        overViewUnit: newValue
      };

      setAvailabilitySettings(newSettings);
      putKeyValueSettings({
        variables: {
          input: {
            key: 'availability_settings',
            settings: newSettings
          }
        }
      });
    },
    [setOverViewUnit, availabilitySettings, putKeyValueSettings]
  );

  const onSortChange = useCallback(() => {
    setSort({
      field: groupBy,
      direction:
        sort.direction === SortDirection.Asc
          ? SortDirection.Desc
          : SortDirection.Asc
    });
  }, [groupBy, sort.direction]);

  const { searchCriteria } = useResourceManagementContext();

  const { dateRange, periodScale } = useResourceOverviewToolbarContext();
  const { filter } = useFilterBasedOnSearchCriteria({ searchCriteria });
  const {
    loadingRows,
    loadingMore,
    resourceUserAvailability,
    loadMoreRows,
    hasMoreRows
  } = useResourceUserAvailability({
    dateRange,
    periodResolution: PERIOD_RESOLUTION_ENUM[periodScale],
    overViewUnit,
    groupBy,
    filter,
    sort,
    page: currentPage
  });

  const {
    loading: loadingTotals,
    resourceUserAvailabilitySeriesTotals
  } = useResourceUserAvailabilitySeriesTotals({
    dateRange,
    periodResolution: PERIOD_RESOLUTION_ENUM[periodScale],
    overViewUnit,
    groupBy,
    filter
  });

  const action = useMemo(
    () => (
      <ResourcingOverviewUnitDropDown
        value={overViewUnit}
        onChange={onOverviewUnitChange}
      />
    ),
    [overViewUnit, onOverviewUnitChange]
  );

  return (
    <ResourcingCard className={cardClasses.root} title={title} action={action}>
      <CardContent classes={contentClasses}>
        <div className={cardClasses.container}>
          <div className={cardClasses.toolbar}>
            <AvailabilityDimensionDropdown
              value={groupBy}
              onChange={onGroupByChange}
              isViewRoleEnabled={isViewRoleEnabled}
              isViewCostCenterEnabled={isViewCostCenterEnabled}
              isViewDivisionEnabled={isViewDivisionEnabled}
              isViewDepartmentEnabled={isViewDepartmentEnabled}
              isViewEmployeeTypeEnabled={isViewEmployeeTypeEnabled}
              isViewLocationEnabled={isViewLocationEnabled}
              isViewServiceCenterEnabled={isViewServiceCenterEnabled}
            />
            <AvailabilityLegend />
          </div>
          <AvailabilityTable
            groupBy={groupBy}
            loadingRows={loadingRows || loadingTotals}
            rows={resourceUserAvailability}
            totals={resourceUserAvailabilitySeriesTotals}
            loadingMore={loadingMore}
            loadMoreRows={loadMoreRows}
            hasMoreRows={hasMoreRows}
            dateRange={dateRange}
            periodScale={periodScale}
            overViewUnit={overViewUnit}
            onSortChange={onSortChange}
            sort={sort}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </CardContent>
    </ResourcingCard>
  );
};

ResourceAvailabilityCard.propTypes = {
  settings: PropTypes.object,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired
};

export default ResourceAvailabilityCard;
