import { ResourceRequestStatus } from '~/types';

export const canShowResourceAllocation = requestStatus =>
  requestStatus === ResourceRequestStatus.Tentative ||
  requestStatus === ResourceRequestStatus.Allocationrejected ||
  requestStatus === ResourceRequestStatus.Complete;

export const getShowResourceAllocation = ({
  resourceRequest: { requestStatus }
}) => ({
  showResourceAllocation: canShowResourceAllocation(requestStatus)
});
