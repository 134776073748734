export const BILLING_INVOICING_PERMISSION_URIS = {
  VIEW_BILL: 'urn:replicon:invoice2-action:view-invoices',
  EDIT_BILL: 'urn:replicon:invoice2-action:edit-invoices',
  VIEW_PAYMENT: 'urn:replicon:client-payment-action:view-client-payment',
  EDIT_PAYMENT: 'urn:replicon:client-payment-action:edit-client-payment',
  VIEW_CREDIT_MEMO:
    'urn:replicon:client-credit-memo-action:view-client-credit-memo',
  EDIT_CREDIT_MEMO:
    'urn:replicon:client-credit-memo-action:edit-client-credit-memo',
  VIEW_PROJECT: 'urn:replicon:project-action:view-project',
  VIEW_CLIENT_BILLING_DATA: 'urn:replicon:client-action:view-billing-data',
  VIEW_PROGRAM_BILLING_DATA: 'urn:replicon:program-action:view-billing-data',
  VIEW_PROJECT_BILLING_DATA: 'urn:replicon:project-action:view-billing-data',
  VOID_BILL: 'urn:replicon:invoice2-action:void-invoices',
  REOPEN_BILL: 'urn:replicon:invoice2-action:reopen-invoices'
};

export const BILL_DAL_URIS = {
  PROGRAM_MANAGER: 'urn:replicon:invoice2-data-access-level:program-manager',
  PROJECT_ADMIN:
    'urn:replicon:invoice2-data-access-level:project-management-data-access-scope',
  PROJECT_MANAGER: 'urn:replicon:invoice2-data-access-level:project-manager',
  CLIENT_MANAGER: 'urn:replicon:invoice2-data-access-level:client-manager',
  BILLING_MANAGER: 'urn:replicon:invoice2-data-access-level:all'
};

export const CREDIT_MEMO_DAL_URIS = {
  PROGRAM_MANAGER:
    'urn:replicon:client-credit-memo-data-access-level:program-manager',
  PROJECT_ADMIN:
    'urn:replicon:client-credit-memo-data-access-level:project-management-data-access-scope',
  PROJECT_MANAGER:
    'urn:replicon:client-credit-memo-data-access-level:project-manager',
  CLIENT_MANAGER:
    'urn:replicon:client-credit-memo-data-access-level:client-manager',
  BILLING_MANAGER: 'urn:replicon:client-credit-memo-data-access-level:all'
};

export const PAYMENT_DAL_URIS = {
  PROGRAM_MANAGER:
    'urn:replicon:client-payment-data-access-level:program-manager',
  PROJECT_ADMIN:
    'urn:replicon:client-payment-data-access-level:project-management-data-access-scope',
  PROJECT_MANAGER:
    'urn:replicon:client-payment-data-access-level:project-manager',
  CLIENT_MANAGER:
    'urn:replicon:client-payment-data-access-level:client-manager',
  BILLING_MANAGER: 'urn:replicon:client-payment-data-access-level:all'
};

export const canViewBillingInvoicingTab = ({
  me: { permissionsMap },
  viewBillingDataPermissionUri,
  isMobile
}) => {
  const canViewBillingData = viewBillingDataPermissionUri
    ? Boolean(permissionsMap[viewBillingDataPermissionUri])
    : true;

  const canViewOverviewSubTab = canViewBillingData && !isMobile;

  const canViewAvailableToBillSubTab = Boolean(
    canViewBillingData &&
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_PROJECT]
  );

  const canViewTransactionsSubTab = Boolean(
    permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_BILL] ||
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_CREDIT_MEMO] ||
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.VIEW_PAYMENT]
  );

  return (
    canViewOverviewSubTab ||
    canViewAvailableToBillSubTab ||
    canViewTransactionsSubTab
  );
};

export const canEditBillingTransactions = ({ me: { permissionsMap } }) =>
  Boolean(
    permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_BILL] ||
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_CREDIT_MEMO] ||
      permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_PAYMENT]
  );
