import PropTypes from 'prop-types';
import React from 'react';
import { FullPath } from '../FullPath';

const GroupFullPath = ({ fullPath, classes }) => (
  <FullPath fullPath={fullPath} classes={classes} />
);

GroupFullPath.propTypes = {
  fullPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.object
};

export default GroupFullPath;
