import { useState, useCallback } from 'react';

export const useDialogState = (initialState = false, options = {}) => {
  const [open, setOpen] = useState(initialState);

  const closeDialog = useCallback(
    (_, reason) => {
      if (options.disabledBackDropClick || options.disableEscapeKeyDown) {
        if (
          (options.disabledBackDropClick && reason !== 'backdropClick') ||
          (options.disableEscapeKeyDown && reason !== 'escapeKeyDown')
        ) {
          setOpen(false);
        }
      } else {
        setOpen(false);
      }
    },
    [options.disableEscapeKeyDown, options.disabledBackDropClick]
  );

  const openDialog = useCallback(() => setOpen(true), [setOpen]);

  return {
    open,
    setOpen,
    closeDialog,
    openDialog
  };
};
