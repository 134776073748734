import React from 'react';
import ReadOnlyNoValue from './ReadOnlyNoValue';

export const withReadOnlyNoValue = BaseComponent => ({
  editable,
  value,
  fullWidth,
  label,
  classes,
  className,
  ...rest
}) =>
  !editable && !value ? (
    <ReadOnlyNoValue
      classes={{
        field: classes.field
      }}
      className={className}
      fullWidth={fullWidth}
      label={label}
    />
  ) : (
    <BaseComponent
      classes={classes}
      className={className}
      editable={editable}
      fullWidth={fullWidth}
      label={label}
      value={value}
      {...rest}
    />
  );
