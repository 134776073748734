import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const LINK_ATTACHMENTS_TO_OBJECT_MUTATION = gql`
  mutation linkAttachmentsToObject(
    $objectUri: String!
    $attachments: [AttachmentLinkInput!]!
  ) {
    linkAttachmentsToObject2(
      input: { attachToUri: $objectUri, attachments: $attachments }
    ) {
      attachments: attachments2 {
        uploadUri
      }
    }
  }
`;

const useLinkAttachmentsToObject = ({ onCompleted }) => {
  const [linkAttachmentsToObject] = useMutation(
    LINK_ATTACHMENTS_TO_OBJECT_MUTATION,
    {
      onCompleted
    }
  );

  return linkAttachmentsToObject;
};

export default useLinkAttachmentsToObject;
