import { useState, useCallback } from 'react';

const useMoveTaskState = () => {
  const [isMoveTaskErrorDialogOpen, setIsMoveTaskErrorDialogOpen] = useState(
    false
  );
  const [moveTaskError, setMoveTaskError] = useState(null);
  const [moveTaskLoading, setMoveTaskLoading] = useState(false);

  const openMoveTaskErrorDialog = useCallback(
    () => setIsMoveTaskErrorDialogOpen(true),
    [setIsMoveTaskErrorDialogOpen]
  );
  const closeMoveTaskErrorDialog = useCallback(
    () => setIsMoveTaskErrorDialogOpen(false),
    [setIsMoveTaskErrorDialogOpen]
  );

  return {
    isMoveTaskErrorDialogOpen,
    moveTaskError,
    moveTaskLoading,
    setMoveTaskError,
    setMoveTaskLoading,
    openMoveTaskErrorDialog,
    closeMoveTaskErrorDialog
  };
};

export default useMoveTaskState;
