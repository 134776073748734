import { makeStyles } from '@material-ui/core/styles';

export const useUserStyles = makeStyles(theme => ({
  name: {
    fontWeight: 'bold',
    verticalAlign: 'bottom'
  },
  container: {
    padding: 0,
    display: 'inline-flex'
  }
}));

const useStyles = makeStyles(theme => ({
  dialogContent: {
    ...theme.typography.body1
  },

  userChangeMessage: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  pathIcon: {
    flexShrink: 0,
    flexGrow: 0,
    margin: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
    verticalAlign: 'middle'
  },

  optionList: {
    margin: theme.spacing(0, -2)
  },
  clickProtectionWrapper: {}
}));

export const useDateStyles = makeStyles(theme => ({
  input: {
    padding: theme.spacing(0, 0.5),
    fontWeight: 'bold'
  }
}));

export default useStyles;
