import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, memo } from 'react';

export const isValid = number => number !== null && number !== undefined;

const getValue = (value, precision) =>
  precision
    ? parseFloat(parseFloat(value, 10).toFixed(precision))
    : parseInt(value, 10);

export const cleanValue = ({ event, min, max, precision, clearable }) => {
  const value = event?.target?.value;

  if (clearable && value === '') return '';

  let numberValue = value ? getValue(value, precision) : 0;

  if (!numberValue && isValid(min)) numberValue = min || 0;

  if (isValid(max))
    numberValue = Math.max(min || 0, Math.min(max, numberValue));

  return numberValue;
};

export const NumberTextField = memo(
  ({
    label,
    formHelperTextProps: overrideFormHelperTextProps,
    variant = 'standard',
    value,
    onChange,
    onNumberBlur,
    ariaLabel = '',
    min,
    max,
    precision,
    startAdornment,
    endAdornment,
    step,
    disabled = false,
    fullWidth = true,
    additionalInputProps = {},
    error,
    helperText,
    clearable = false,
    ...rest
  }) => {
    const onNumberChange = useCallback(
      event => onChange(cleanValue({ event, min, max, precision, clearable })),
      [onChange, min, max, precision, clearable]
    );

    const onNumberBlurEvent = useCallback(
      event =>
        onNumberBlur(cleanValue({ event, min, max, precision, clearable })),
      [min, max, precision, onNumberBlur, clearable]
    );

    const inputProps = useMemo(
      () => ({
        ...additionalInputProps,
        startAdornment,
        endAdornment,
        inputProps: {
          min,
          max,
          step,
          'aria-label': ariaLabel,
          onBlur: onNumberBlur ? onNumberBlurEvent : undefined
        }
      }),
      [
        additionalInputProps,
        endAdornment,
        max,
        min,
        onNumberBlur,
        onNumberBlurEvent,
        startAdornment,
        step,
        ariaLabel
      ]
    );

    return (
      <TextField
        {...rest}
        value={value}
        variant={variant}
        type="number"
        fullWidth={fullWidth}
        disabled={disabled}
        onChange={onNumberChange}
        label={label}
        InputProps={inputProps}
        error={error}
        helperText={helperText}
      />
    );
  }
);

NumberTextField.propTypes = {
  additionalInputProps: PropTypes.object,
  min: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  formHelperTextProps: PropTypes.object,
  max: PropTypes.number,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullWidth: PropTypes.bool,
  onNumberBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  startAdornment: PropTypes.node,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  clearable: PropTypes.bool,
  precision: PropTypes.number
};

export default NumberTextField;
