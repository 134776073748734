import React from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: theme.spacing(5),
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.grey[100],
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`
  }
}));

export const ExpansionRowLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton width="100%" height={16} />
    </div>
  );
};

export default ExpansionRowLoading;
