import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  IconButton,
  DialogContentText,
  TableCell,
  TableRow
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { CertificateIcon } from '~/modules/common/components/Icons';
import SkillRating from './SkillRating';
import SkillCertificateAssignment from './SkillCertificateAssignment';
import SkillCustomMetadataCell from './SkillCustomMetadataCell';
import useStyles from './useStyles';

const deleteDialogResourceKeys = {
  header: 'skillInfoCard.deleteSkillDialog.removeSkill',
  actionButton: 'skillInfoCard.deleteSkillDialog.removeSkill'
};

export const SkillRow = ({
  skill,
  showCertificates = true,
  skill: { certificate },
  certificates,
  userDetails,
  skillSetting,
  classes: classesOverride,
  onSkillRemove,
  maxSkillLevel,
  onSkillRatingUpdate,
  onCustomMetadataUpdate,
  onCertificateUpdate
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOverride });
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const certificateMetdata =
    certificate && (certificates || []).find(p => p.uri === certificate);
  const onRemoveCallback = useCallback(() => onSkillRemove(skill), [
    onSkillRemove,
    skill
  ]);

  const onUpdateCallback = useCallback(
    e => onCertificateUpdate(e.target.id, skill),
    [onCertificateUpdate, skill]
  );
  const onAssignmentCallback = useCallback(e => onCertificateUpdate(e, skill), [
    onCertificateUpdate,
    skill
  ]);

  return (
    <>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onRemoveCallback}
        >
          <DialogContentText>
            <FormattedMessage id="skillInfoCard.deleteSkillDialog.description" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
      <TableRow>
        <TableCell className={classes.skillNameCell}>
          <div className={classes.skillName}>{skill.displayText}</div>
        </TableCell>
        <TableCell
          data-qe-id={`${skill.displayText}_RatingSection`}
          className={classes.cell}
        >
          {Boolean(maxSkillLevel) && (
            <SkillRating
              dataQeId={`${skill.displayText}_Rating`}
              skillAssignment={skill}
              onSkillRatingUpdate={onSkillRatingUpdate}
              editable
              maxSkillLevel={maxSkillLevel}
            />
          )}
        </TableCell>
        {showCertificates ? (
          <TableCell
            data-qe-id={`${skill.displayText}_Certificate_Section`}
            className={classes.cell}
          >
            {certificateMetdata ? (
              <div className={classes.certificate}>
                <div
                  data-qe-id={`${skill.displayText}_Certificate_Name`}
                  className={classes.name}
                >
                  <CertificateIcon className={classes.certificateIcon} />
                  {certificateMetdata.name}
                </div>
                <IconButton
                  data-qe-id={`${skill.displayText}_Certificate_Delete`}
                  className={classes.iconButton}
                  onClick={onUpdateCallback}
                >
                  <RemoveCircle />
                </IconButton>
              </div>
            ) : (
              <SkillCertificateAssignment
                dataQeId={`${skill.displayText}_Certificate`}
                certificates={certificates}
                userDetails={userDetails}
                onCertificateUpdate={onAssignmentCallback}
              />
            )}
          </TableCell>
        ) : (
          <TableCell className={classes.cell} />
        )}
        {(skillSetting || []).map(setting => (
          <TableCell key={setting.uri} className={classes.metadataCell}>
            <SkillCustomMetadataCell
              dataQeId={`${skill.displayText}_Setting`}
              key={setting.uri}
              skill={skill}
              setting={setting}
              onCustomMetadataUpdate={onCustomMetadataUpdate}
            />
          </TableCell>
        ))}
        <TableCell className={classes.removeIconCell}>
          <IconButton
            data-qe-id={`${skill.displayText}_Delete_Skill`}
            onClick={openRemoveDialog}
            className={classes.button}
            aria-label={formatMessage({ id: 'button.delete' })}
          >
            <RemoveCircle />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

SkillRow.propTypes = {
  classes: PropTypes.object,
  showCertificates: PropTypes.bool,
  skill: PropTypes.object.isRequired,
  certificates: PropTypes.array,
  skillSetting: PropTypes.array,
  onSkillRemove: PropTypes.func,
  onSkillRatingUpdate: PropTypes.func,
  onCustomMetadataUpdate: PropTypes.func,
  onCertificateUpdate: PropTypes.func,
  userDetails: PropTypes.object,
  maxSkillLevel: PropTypes.number
};

export default SkillRow;
