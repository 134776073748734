import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Decimal, Percentage } from '~/modules/common/components';

const AvailabilityPeriod = ({ availableHours, isHoursMode, classes }) => {
  return isHoursMode ? (
    <Decimal
      value={availableHours}
      suffix="h"
      className={classNames(classes.value, {
        [classes.overAllocated]: availableHours < 0
      })}
    />
  ) : (
    <Percentage
      fixedDecimalScale={false}
      value={availableHours}
      className={classNames(classes.value, {
        [classes.overAllocated]: availableHours < 0
      })}
    />
  );
};

AvailabilityPeriod.propTypes = {
  availableHours: PropTypes.number.isRequired,
  isHoursMode: PropTypes.bool,
  classes: PropTypes.object
};

export default AvailabilityPeriod;
