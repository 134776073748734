import React, { useCallback, useMemo } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserWithRoleSchedules } from '~/modules/common/components/User';
import IconLinkButton from '~/modules/resourcing/common/components/IconLinkButton';
import useStyles from './useStyles';

const QuickAllocationUserLeftItem = ({
  user,
  handleExpand,
  isExpanded,
  isShowActualEnabled
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const toLink = `#/resource/${user.slug}`;
  const onUserExpanded = useCallback(() => handleExpand(user.id), [
    user.id,
    handleExpand
  ]);

  const iconLinkButtonClasses = useMemo(
    () => ({ openButton: classes.openButton }),
    [classes.openButton]
  );

  return (
    <>
      <div
        className={classNames(classes.resourceRow, {
          [classes.resourceRowWithActuals]: isShowActualEnabled
        })}
      >
        <div className={classes.resourceButton}>
          <IconButton
            data-qe-id={`${user.displayText}_Expand_Collapse`}
            aria-expanded={isExpanded}
            onClick={onUserExpanded}
            className={classNames(classes.expandButton, {
              [classes.expandButtonOpen]: isExpanded
            })}
            aria-label={
              isExpanded
                ? intl.formatMessage({
                    id: 'quickAllocation.showLess'
                  })
                : intl.formatMessage({
                    id: 'quickAllocation.showMore'
                  })
            }
            disableRipple
          >
            <ExpandMoreIcon
              className={classNames(classes.expandIcon, {
                [classes.expandIconOpen]: isExpanded
              })}
            />
            <div className={classes.resource}>
              <UserWithRoleSchedules user={user} />
            </div>
          </IconButton>
        </div>
        <div className={classes.iconLink}>
          <IconLinkButton
            classes={iconLinkButtonClasses}
            toLink={toLink}
            ariaLabel={
              intl.formatMessage({
                id: 'resourceRequestItem.viewMoreDetails'
              }) + user.displayText
            }
          />
        </div>
      </div>
      {isExpanded && (
        <div className={classes.timeoffLabel}>
          <FormattedMessage id="quickAllocation.timeOff" />
        </div>
      )}
    </>
  );
};

QuickAllocationUserLeftItem.propTypes = {
  user: PropTypes.object,
  handleExpand: PropTypes.func,
  isExpanded: PropTypes.bool,
  isShowActualEnabled: PropTypes.bool
};

export default QuickAllocationUserLeftItem;
