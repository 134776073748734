import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Dialog } from '@material-ui/core';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  FILE_SOURCE_URI,
  LINK_SOURCE_URI,
  FILE_DOCUMENT_SOURCE_URI,
  LINK_DOCUMENT_SOURCE_URI
} from '../../fileSourceUris';
import FileWebAddressDialogContents from './FileWebAddressDialogContents';

export const FILE_TAB = 'file';
export const LINK_TAB = 'link';

const fileWebAddressRole = {
  role: 'presentation'
};

export const FileWebAddressDialog = ({
  intl,
  open,
  onCancel,
  onOK,
  enabledSourceUris,
  initialValue,
  showWebAddressNameField = false,
  isUploading = false,
  multiple = false
}) => {
  const fileUploadEnabled = React.useMemo(
    () =>
      enabledSourceUris.some(
        uri => uri === FILE_SOURCE_URI || uri === FILE_DOCUMENT_SOURCE_URI
      ),
    [enabledSourceUris]
  );

  const webAddressEnabled = React.useMemo(
    () =>
      enabledSourceUris.some(
        uri => uri === LINK_SOURCE_URI || uri === LINK_DOCUMENT_SOURCE_URI
      ),
    [enabledSourceUris]
  );

  const defaultTab = fileUploadEnabled ? FILE_TAB : LINK_TAB;

  const [tabValue, setTabValue] = React.useState(defaultTab);

  const onTabChange = useCallback((_, value) => setTabValue(value), [
    setTabValue
  ]);

  const onSubmit = React.useCallback(
    values =>
      onOK(
        tabValue === FILE_TAB
          ? values.file
          : { ...values.link, linkName: values.linkName }
      ),
    [onOK, tabValue]
  );

  const isMobile = useIsBreakpointDown('xs');
  const isFileValue = initialValue && initialValue.keyValues;
  const initialFileValue = isFileValue ? initialValue : null;
  const initialLinkValue = isFileValue ? null : initialValue;
  const initialLinkNameValue = isFileValue ? null : initialValue;
  const initialValues = useMemo(
    () => ({
      file: initialFileValue,
      link: initialLinkValue,
      linkName: initialLinkNameValue
    }),
    [initialFileValue, initialLinkNameValue, initialLinkValue]
  );

  const validationSchema = useCallback(
    () =>
      object().shape({
        link: object().shape({
          linkUri: string().url(
            intl.formatMessage({ id: 'fileWebAddressDialog.invalidUrl' })
          )
        })
      }),
    [intl]
  );

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      open={open}
      onClose={onCancel}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={fileWebAddressRole}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <FileWebAddressDialogContents
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            fileUploadEnabled={fileUploadEnabled}
            webAddressEnabled={webAddressEnabled}
            showWebAddressNameField={showWebAddressNameField}
            tabValue={tabValue}
            onTabChange={onTabChange}
            onOK={onOK}
            isUploading={isUploading}
            onCancel={onCancel}
            multiple={multiple}
          />
        )}
      </Formik>
    </Dialog>
  );
};

FileWebAddressDialog.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOK: PropTypes.func,
  enabledSourceUris: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.object,
  showWebAddressNameField: PropTypes.bool,
  isUploading: PropTypes.bool,
  multiple: PropTypes.bool
};

export default injectIntl(FileWebAddressDialog);
