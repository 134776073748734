import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, Tab, IconButton, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { usePageTitle } from '~/modules/common/title';
import { useProjectsContext } from '../../ProjectsContext';
import { LIST, TIMELINE, OVERVIEW } from '../../projectListTabs';
import ProjectImportExportMenu from '../ProjectImportExportMenu';

const overviewTabLabel = <FormattedMessage id="projectList.overview" />;
const listTabLabel = <FormattedMessage id="projectList.list" />;
const timelineTabLabel = <FormattedMessage id="projectList.timeline" />;

const useTabsStyles = makeStyles(theme => ({
  root: {
    flex: 1
  }
}));

export const SettingsButton = props => (
  <IconButton {...props}>
    <SettingsIcon />
  </IconButton>
);

export const ProjectListPageTabs = ({ isMobile, onSettingsClick }) => {
  const isMobileXs = useIsBreakpointDown('xs');
  const tabsClasses = useTabsStyles();
  const { view, onTabChange } = useProjectsContext();
  const intl = useIntl();

  usePageTitle([
    { messageId: 'routes.projects' },
    { messageId: `projectList.${view}` }
  ]);

  return (
    <>
      <Tabs
        indicatorColor="primary"
        scrollButtons="off"
        onChange={onTabChange}
        value={view}
        classes={tabsClasses}
      >
        <Tab label={overviewTabLabel} value={OVERVIEW} data-qe-id="Overview" />
        {!isMobile && (
          <Tab
            label={timelineTabLabel}
            value={TIMELINE}
            data-qe-id="TimelineTab"
          />
        )}
        <Tab label={listTabLabel} value={LIST} data-qe-id="DetailsTab" />
      </Tabs>
      <ProjectImportExportMenu isMobile={isMobileXs} />
      <Hidden smDown>
        {view === LIST && (
          <span>
            <SettingsButton
              onClick={onSettingsClick}
              aria-label={intl.formatMessage({
                id: 'columnSettings.showTableSettings'
              })}
            />
          </span>
        )}
      </Hidden>
    </>
  );
};

ProjectListPageTabs.propTypes = {
  isMobile: PropTypes.bool,
  onSettingsClick: PropTypes.func.isRequired
};

export default ProjectListPageTabs;
