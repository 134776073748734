import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import withForwardedRef from '~/modules/common/enhancers/withForwardedRef';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  }
}));
const NoneMenuItem = ({
  classes: classesOverride,
  level,
  children,
  forwardedRef,
  ...otherProps
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <MenuItem
      {...otherProps}
      classes={{ root: classes.root }}
      ref={forwardedRef}
    >
      {children}
    </MenuItem>
  );
};

NoneMenuItem.muiName = 'MenuItem';

NoneMenuItem.propTypes = {
  level: PropTypes.number,
  classes: PropTypes.object,
  children: PropTypes.node,
  forwardedRef: PropTypes.any
};

export default withForwardedRef(NoneMenuItem);
