import {
  useIsBreakpointDown,
  useIsBreakpointOnly
} from '~/modules/common/hooks';

export default (theme, highestValue) => ({
  victoryBar: {
    barWidth: 25,
    style: {
      data: {
        fill: props => {
          const value = props.datum.y || props.datum.y0;

          return value > 1
            ? theme.palette.success.main
            : theme.palette.error.main;
        },
        cursor: 'pointer'
      },
      labels: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 5,
        fill: props => {
          const value = props.datum.y || props.datum.y0;

          if (highestValue) {
            const unitBarHeight = props.height / highestValue;
            const barHeight = Math.abs(value) * unitBarHeight;

            // Prevents label overlap with axis
            if (barHeight <= props.dy + 10) {
              return 'none';
            }
          }

          return value > 1
            ? theme.palette.getContrastText(theme.palette.success.main)
            : theme.palette.getContrastText(theme.palette.error.main);
        }
      }
    }
  },
  victoryTooltip: {
    fill: theme.palette.common.black,
    color: theme.palette.text.primary,
    fontSize: 8
  },
  chart: {
    width: useIsBreakpointDown('sm') ? 200 : 600,
    height: 200,
    domainPadding: { x: 30 },
    padding: {
      top: theme.spacing(1.5),
      bottom: theme.spacing(4),
      left: theme.spacing(5),
      right: theme.spacing(1)
    }
  },
  axis: {
    labelStyle: {
      paddingTop: 5,
      angle: 0,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: useIsBreakpointOnly('md') ? 9 : 7,
      fontFamily: theme.typography.fontFamily,
      verticalAnchor: 'middle',
      textAnchor: 'middle',
      fill: theme.palette.grey[600]
    },
    x: {
      offsetY: 20,
      styleX1: {
        axis: { stroke: theme.palette.grey[600] },
        tickLabels: {
          display: 'none'
        }
      },
      styleX2: {
        axis: { stroke: 'transparent' }
      }
    },
    y: {
      domainPadding: [0, 10],
      style: {
        axis: { stroke: theme.palette.grey[600] },
        grid: {
          stroke: theme.palette.grey[100]
        },
        axisLabel: {
          fontSize: 8,
          fontFamily: theme.typography.fontFamily,
          fill: theme.palette.grey[600],
          padding: theme.spacing(4)
        }
      }
    }
  }
});
