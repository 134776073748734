import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { Typography } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';
import { ShowMoreBillsButton } from '../ShowMoreBillsButton';
import { useListColumns } from './columns';
import { useListFooters } from './footers';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& th:last-child': {
      paddingRight: theme.spacing(1),
      zIndex: 1
    },
    '& td': {
      minWidth: theme.spacing(15)
    },
    '& td:first-child': {
      maxWidth: theme.spacing(15),
      zIndex: 1
    },
    '& tbody td:last-child': {
      paddingRight: ({ editable }) =>
        editable ? theme.spacing(0) : theme.spacing(1),
      width: theme.spacing(18)
    },
    '& td:last-child': {
      paddingRight: theme.spacing(1) + 2,
      flip: false
    }
  },
  tableHeaderCell: {
    padding: theme.spacing(1, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 78
  },
  tableCell: {
    flexDirection: 'row-reverse',
    padding: theme.spacing(0.5, 2),
    height: 'auto',
    minHeight: '55px'
  },
  tableFooterCell: {
    align: 'right',
    height: 30,
    fontWeight: theme.typography.fontWeightRegular
  },
  tableFooterRow: {
    align: 'right',
    backgroundColor: theme.palette.grey[50]
  }
}));

const useColumnStyles = makeStyles(theme => ({
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.table.border}`
  },
  balanceAmount: {
    fontSize: theme.typography.body2.fontSize
  },
  allocatedBillAmount: {
    fontSize: theme.typography.body2.fontSize,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    '& p': {
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      margin: 0
    }
  },
  amount: {
    padding: 0,
    overflowX: 'auto',
    '& p': {
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      margin: 0
    }
  },
  totalAmount: {
    paddingLeft: theme.spacing(10)
  },
  totalDisplayText: {
    align: 'right',
    borderRight: `1px solid ${theme.palette.grey[200]}`
  },
  displayText: {
    '& p': {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word'
    }
  },
  subTitle: {
    lineHeight: 1,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const useCardStyles = makeStyles({
  card: {
    width: '100%',
    boxShadow: 'none',
    overflowX: 'auto'
  }
});

export const AssociatedBillsTable = ({
  associatedBills: allAssociatedBills,
  onAmountChange,
  onAllocatedAmountChange,
  isMultiCurrency,
  isLoading,
  amountTotal,
  errors,
  showAllOutstandingBills,
  setShowAllOutstandingBIlls,
  resourceKeys,
  editable = true,
  isSubmitting,
  billId,
  isRefund,
  hasMoreRows,
  loadMoreRows
}) => {
  const associatedBills = useMemo(
    () =>
      showAllOutstandingBills
        ? allAssociatedBills
        : allAssociatedBills.filter(aBill => aBill.id === billId),
    [allAssociatedBills, billId, showAllOutstandingBills]
  );
  const providerClasses = useListTableThemeProviderStyles({ editable });
  const columnClasses = useColumnStyles();
  const cardClasses = useCardStyles();

  const isAllocatedBillAmountColumnVisible = useMemo(
    () =>
      !showAllOutstandingBills
        ? associatedBills.filter(
            aBill =>
              aBill.balanceAmount.currency.id !== aBill.amount.currency.id
          ).length > 0
        : isMultiCurrency,
    [associatedBills, isMultiCurrency, showAllOutstandingBills]
  );

  const columns = useListColumns({
    classes: columnClasses,
    onAmountChange,
    onAllocatedAmountChange,
    isAllocatedBillAmountColumnVisible,
    errors,
    editable,
    resourceKeys,
    isSubmitting,
    isRefund
  });

  const footers = useListFooters({
    classes: columnClasses,
    editable,
    isAllocatedBillAmountColumnVisible
  });
  const totals = useMemo(
    () => ({
      amountTotal: {
        ...amountTotal,
        amount: getRoundedValue(amountTotal.amount, 2)
      }
    }),
    [amountTotal]
  );

  const onShowMore = useCallback(() => setShowAllOutstandingBIlls(true), [
    setShowAllOutstandingBIlls
  ]);

  return associatedBills.length === 0 ? (
    <Typography variant="caption" component="p">
      <FormattedMessage id={resourceKeys.noDataMessage} />
    </Typography>
  ) : (
    <>
      <ListTableThemeProvider classes={providerClasses}>
        <ListTable
          hover={false}
          variant="table"
          classes={cardClasses}
          columns={columns}
          records={associatedBills}
          footers={footers}
          totals={totals}
          isLoading={isLoading}
          hasMore={hasMoreRows}
          loadMore={loadMoreRows}
        />
      </ListTableThemeProvider>
      {!showAllOutstandingBills && (
        <ShowMoreBillsButton onShowMore={onShowMore} />
      )}
    </>
  );
};

AssociatedBillsTable.propTypes = {
  associatedBills: PropTypes.array.isRequired,
  resourceKeys: PropTypes.object.isRequired,
  onAmountChange: PropTypes.func,
  onAllocatedAmountChange: PropTypes.func,
  amountTotal: PropTypes.object.isRequired,
  errors: PropTypes.object,
  showAllOutstandingBills: PropTypes.bool,
  editable: PropTypes.bool,
  setShowAllOutstandingBIlls: PropTypes.func,
  isMultiCurrency: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isRefund: PropTypes.bool,
  billId: PropTypes.string,
  hasMoreRows: PropTypes.bool,
  loadMoreRows: PropTypes.func
};

export default AssociatedBillsTable;
