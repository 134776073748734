import React from 'react';
import { useMeContext } from '~/modules/me';

const withGroupSettings = (
  groupName,
  groupSettingsKey
) => BaseComponent => props => {
  const me = useMeContext();
  const { groupSettings } = me;
  const allProps = {
    ...props,
    me,
    [groupSettingsKey]: groupSettings[groupName]
  };

  return <BaseComponent {...allProps} />;
};

export default withGroupSettings;
