import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

export const IconReadableWrapper = props => {
  const { formatMessage } = useIntl();

  const { id, role, children } = props;

  return (
    <span role={role} aria-label={formatMessage({ id })}>
      {children}
    </span>
  );
};

IconReadableWrapper.propTypes = {
  id: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.node
};

export default IconReadableWrapper;
