import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';
import { ExpenseEntryType } from '~/types';

const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $projectInput: ProjectInput!
    $includeExpenseCodes: Boolean!
  ) {
    updateProject2(projectInput: $projectInput) {
      id
      expenseCodes @include(if: $includeExpenseCodes) {
        id
        displayText
        isExpenseEntryToThisCodeAllowed
      }
    }
  }
`;

export const optimisticResponse = (projectUri, expenseCodes) => ({
  __typename: 'Mutation',
  updateProject2: {
    __typename: 'Project',
    id: projectUri,
    expenseCodes
  }
});

export const mapToProjectInput = (projectUri, expenseCodes) => ({
  projectUri,
  expenseCodes: expenseCodes.map(code => ({
    id: code.id,
    displayText: code.displayText,
    isExpenseEntryToThisCodeAllowed: code.isExpenseEntryToThisCodeAllowed,
    expenseEntryType: code.expenseEntryType || ExpenseEntryType.NonBillable
  }))
});

const getRefetchQueries = ({ isPsaPpmEstimatedCostAtCompletionEnabled }) => {
  return [
    isPsaPpmEstimatedCostAtCompletionEnabled &&
      'GetProjectProgressSummaryDetails'
  ].filter(x => x);
};

const useUpdateProjectTimeAndExpense = ({
  projectUri,
  includeExpenseCodes = true
}) => {
  const {
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled }
  } = useMeContext();
  const [updateProjectTimeAndExpenseMutation] = useMutation(UPDATE_PROJECT);

  return useCallback(
    async values => {
      const { expenseCodes } = values;

      const { data } =
        (await updateProjectTimeAndExpenseMutation({
          variables: {
            projectInput: mapToProjectInput(projectUri, expenseCodes),
            includeExpenseCodes
          },
          refetchQueries: getRefetchQueries({
            isPsaPpmEstimatedCostAtCompletionEnabled
          }),
          optimisticResponse: optimisticResponse(projectUri, expenseCodes)
        })) || {};

      return data?.updateProject2;
    },
    [
      includeExpenseCodes,
      isPsaPpmEstimatedCostAtCompletionEnabled,
      projectUri,
      updateProjectTimeAndExpenseMutation
    ]
  );
};

export default useUpdateProjectTimeAndExpense;
