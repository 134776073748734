import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SideCardSectionHeader } from '~/modules/common/components/SideCard';
import { useDialogState } from '~/modules/common/hooks';
import { ContactInfoGenericList } from '~/modules/projects/project/common/components/ContactInfo';
import EditableAddressInfoDialog from './AddressDialog/EditableAddressInfoDialog';
import AddressInfoListTable from './AddressDialog/AddressInfoListTable';
import { Address } from './Address';

const title = (
  <FormattedMessage id="projectBasicInfoCard.addressInfo.addresses" />
);

const Addresses = ({ projectId, addresses, showEdit }) => {
  const { open, openDialog, closeDialog } = useDialogState();
  const [isReadOnly, setIsReadOnly] = useState(false);

  const onEditClick = useCallback(() => {
    setIsReadOnly(false);
    openDialog();
  }, [openDialog]);

  const handleViewDetails = useCallback(() => {
    setIsReadOnly(true);
    openDialog();
  }, [openDialog]);

  return (
    <div data-qe-id="Addresses">
      <SideCardSectionHeader
        title={title}
        onEditClick={onEditClick}
        dataQeId="EditAddressSection"
        showEdit={showEdit}
        ariaLabelKey="button.addressEditButton"
      />
      <ContactInfoGenericList
        items={addresses}
        noDataMessageId="projectBasicInfoCard.addressInfo.noAddresses"
        handleViewDetails={handleViewDetails}
      >
        <Address />
      </ContactInfoGenericList>
      <EditableAddressInfoDialog
        projectId={projectId}
        addresses={addresses}
        open={open}
        onClose={closeDialog}
        isReadOnly={isReadOnly}
      >
        <AddressInfoListTable isReadOnly={isReadOnly} />
      </EditableAddressInfoDialog>
    </div>
  );
};

Addresses.propTypes = {
  projectId: PropTypes.string.isRequired,
  addresses: PropTypes.array.isRequired,
  showEdit: PropTypes.bool.isRequired
};

export default Addresses;
