import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReportIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 4,2 V 22 H 20 V 8 L 14,2 Z M 13,3.5 18.5,9 H 13 Z m 1,8.5 h 2 v 6.00700000000006 L 14,18 Z m -3,2 h 2 v 4 l -2,0.006 z m -3,2 h 2 v 2 H 8 Z" />
    </>
  </SvgIcon>
));

export default ReportIcon;
