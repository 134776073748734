import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    '& label': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '80%',
      '&.Mui-focused': {
        textOverflow: 'initial',
        overflow: 'visible'
      }
    }
  },
  inputRoot: {
    paddingTop: '9.5px !important',
    paddingBottom: '9.5px !important'
  },
  noOptions: {
    opacity: '0.6'
  },
  userContainer: {
    overflow: 'hidden'
  },
  user: {
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'break-spaces',
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  },
  container: ({ readOnly }) => ({
    ...(readOnly ? {} : { height: theme.spacing(8) })
  })
}));

export const useDefaultRate = makeStyles(theme => ({
  defaultRate: ({ readOnly }) => ({
    color: theme.palette.text.secondary,
    paddingTop: readOnly ? theme.spacing(0) : theme.spacing(2),
    paddingBottom: readOnly ? theme.spacing(0) : theme.spacing(2),
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  })
}));
