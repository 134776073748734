import { useIntl } from 'react-intl';
import { getAbbreviatedProps } from '~/modules/common/charts/dashboard/util';

export const HOURS_TYPE_ENUM = {
  ESTIMATED: 'ESTIMATED',
  ACTUALS: 'ACTUALS'
};

export const computeChartPoints = (hours, type, intl) =>
  hours.map(({ estimated, actual, id }) => {
    const hourValue =
      type === HOURS_TYPE_ENUM.ESTIMATED ? estimated || 0 : actual || 0;

    return {
      name: intl.formatMessage({ id }),
      value: hourValue,
      label:
        hourValue > 0
          ? `${abbreviatedDecimalValue({ intl, value: hourValue })}`
          : ''
    };
  });

export const abbreviatedDecimalValue = ({ intl, value }) => {
  const { scaledNumber, abbreviatedSuffix } = getAbbreviatedProps({
    value,
    intl
  });

  return `${
    Number.isInteger(scaledNumber) ? scaledNumber : scaledNumber.toFixed(2)
  }${abbreviatedSuffix}`;
};

export const useProjectBudgetHoursChartData = (
  projectHoursSummary,
  isSummaryLoading
) => {
  const intl = useIntl();

  if (isSummaryLoading) {
    return {};
  }

  const {
    estimatedHours = 0,
    totalAllocatedHours = 0,
    timeEnteredSummary: {
      timeEnteredActual = 0,
      timeEnteredTaskActual = 0,
      timeEnteredTaskEstimatedAtCompletion = 0,
      timeEnteredAllocatedResourceActual = 0
    } = {
      timeEnteredActual: 0,
      timeEnteredTaskActual: 0,
      timeEnteredTaskEstimatedAtCompletion: 0,
      timeEnteredAllocatedResourceActual: 0
    }
  } = projectHoursSummary;

  const actualHours = timeEnteredActual;
  const values = [
    {
      estimated: estimatedHours,
      actual: actualHours,
      id: 'chart.projects'
    },
    {
      estimated: timeEnteredTaskEstimatedAtCompletion,
      actual: timeEnteredTaskActual,
      id: 'chart.tasks'
    },
    {
      estimated: totalAllocatedHours,
      actual: timeEnteredAllocatedResourceActual,
      id: 'chart.resources'
    }
  ];

  return {
    estimatedDataPoints: computeChartPoints(
      values,
      HOURS_TYPE_ENUM.ESTIMATED,
      intl
    ),
    actualDataPoints: computeChartPoints(values, HOURS_TYPE_ENUM.ACTUALS, intl)
  };
};

export default useProjectBudgetHoursChartData;
