import { gql } from 'graphql-tag';
import { SpecificResourceRequestFragmentDoc } from '~/types';
import specificResourceAllocationFragment from './specificResourceAllocationFragment';

const specificResourceRequestWithAllocationsScheduleFragment = gql`
  fragment SpecificResourceRequestWithAllocationSchedule on ResourceRequest {
    ...SpecificResourceRequest
    resourceAllocations {
      ...SpecificResourceAllocation
      user {
        ...SpecificResourceAllocationUser
        scheduleDurationSeries(
          dateRange: $chartDateRange
          periodResolution: $periodResolution
        ) {
          dateRange {
            startDate: startDate2
            endDate: endDate2
          }
          hours
        }
      }
    }
  }
  ${SpecificResourceRequestFragmentDoc}
  ${specificResourceAllocationFragment}
`;

export default specificResourceRequestWithAllocationsScheduleFragment;
