import { useEffect } from 'react';
import { SELECTED_STATUS } from '../enums';

export const useBillingTransactionSelectAll = ({
  newIncludedTransactionItems,
  setTransactionItems,
  setIncludedTransactionItems,
  isListDataLoading,
  allSelectedStatus,
  billingRows,
  selectedTransactionItemsIds,
  showAddButtonHandler
}) => {
  useEffect(() => {
    if (!isListDataLoading) {
      if (allSelectedStatus === SELECTED_STATUS.ALL) {
        setIncludedTransactionItems(newIncludedTransactionItems);
      }
      setTransactionItems(billingRows);
      if (
        selectedTransactionItemsIds &&
        selectedTransactionItemsIds.length > 0
      ) {
        showAddButtonHandler && showAddButtonHandler(false);
      } else {
        showAddButtonHandler && showAddButtonHandler(true);
      }
    }
  }, [
    allSelectedStatus,
    billingRows,
    isListDataLoading,
    newIncludedTransactionItems,
    selectedTransactionItemsIds,
    setIncludedTransactionItems,
    setTransactionItems,
    showAddButtonHandler
  ]);
};
export default useBillingTransactionSelectAll;
