import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { makeStyles, ListItemText, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';

const useStyles = makeStyles(theme => ({
  primary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: theme.typography.body2.fontSize
  }
}));

const getOptionSelected = (option, selected) => option.field === selected.field;

export const ResourceAllocationUserChartDialogDropdown = ({
  label,
  options,
  value,
  onChange,
  selectedOptions,
  className,
  disabled = false,
  ...rest
}) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const groupBy = val =>
    val.category && formatMessage({ id: val.category.displayText });

  const getOptionLabel = useCallback(
    option => {
      const id = option?.value?.props?.id;

      if (!id) {
        return isEmpty(option.value) ? '' : option.value;
      }

      return formatMessage({ id });
    },
    [formatMessage]
  );

  const renderOption = useCallback(
    option => (
      <ListItemText
        primary={option.value}
        className={classes.primary}
        classes={classes}
      />
    ),
    [classes]
  );

  const getOptionDisabled = useCallback(
    option =>
      selectedOptions.some(selected => selected?.field === option?.field),
    [selectedOptions]
  );

  if (disabled) {
    return (
      <TextField
        variant="standard"
        className={className}
        fullWidth
        disabled
        label={label}
        defaultValue={formatMessage({
          id: value?.value?.props?.id
        })}
      />
    );
  }

  return (
    <SimpleAutocomplete
      fullWidth
      className={className}
      inputLabel={label}
      optionPropText="label"
      options={options}
      value={value}
      groupBy={groupBy}
      onChange={onChange}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      getOptionSelected={getOptionSelected}
      {...rest}
    />
  );
};

ResourceAllocationUserChartDialogDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default ResourceAllocationUserChartDialogDropdown;
