import React from 'react';
import PropTypes from 'prop-types';
import { FocusableDiv } from '~/modules/common/components/FocusableDiv';

const GanttAddButton = ({ className, ariaLabel }) => (
  <div className={className}>
    <FocusableDiv className="gantt_add" role="button" aria-label={ariaLabel} />
  </div>
);

GanttAddButton.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string
};

export default GanttAddButton;
