import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  makeStyles,
  CircularProgress,
  FormControl,
  FormLabel
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    margin: 0,
    padding: 0,
    border: 'none',
    position: 'relative'
  },
  legend: {
    display: 'block',
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  fieldList: {
    display: 'block',
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  fieldItem: {
    margin: 0,
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0
    },
    padding: 0
  },
  subbmittingShade: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  }
}));

export const FormSection = ({
  classes: classesOverride,
  className,
  title,
  isSubmitting,
  children
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <FormControl
      component="fieldset"
      className={classNames(classes.root, className)}
    >
      {title && (
        <FormLabel component="legend" className={classes.legend}>
          {title}
        </FormLabel>
      )}
      {React.Children.map(children, (child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={classes.fieldItem}>
          {child}
        </div>
      ))}
      {isSubmitting && (
        <div className={classes.subbmittingShade}>
          <CircularProgress data-qe-id="isSubmitting" />
        </div>
      )}
    </FormControl>
  );
};

FormSection.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  title: PropTypes.node,
  isSubmitting: PropTypes.bool,
  children: PropTypes.node
};

FormSection.defaultProps = {
  isSubmitting: false
};

export default FormSection;
