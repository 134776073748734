import { TableRow, TableCell, TableHead } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useBillingOverviewTableContext } from '../BillingOverviewTableContext';

export const BillingOverviewHeader = ({ displayTotalColumn = false }) => {
  const { periods, overviewClasses } = useBillingOverviewTableContext();

  return (
    <TableHead role="rowgroup">
      <TableRow variant="head" role="row" className={overviewClasses.row}>
        <TableCell
          className={classNames(
            overviewClasses.cell,
            overviewClasses.cellHeader,
            overviewClasses.cellLabel
          )}
        />
        {periods.map(period => (
          <TableCell
            key={period.key}
            scope="col"
            role="columnheader"
            align="right"
            className={classNames(
              overviewClasses.cell,
              overviewClasses.cellHeader,
              {
                [overviewClasses.cellCurrent]: period.currentPeriod
              }
            )}
          >
            {period.label}
          </TableCell>
        ))}

        {displayTotalColumn && (
          <TableCell
            align="right"
            className={classNames(
              overviewClasses.cell,
              overviewClasses.cellHeader,
              overviewClasses.cellTotal
            )}
          >
            <FormattedMessage id="billingOverviewTable.total" />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

BillingOverviewHeader.propTypes = {
  displayTotalColumn: PropTypes.bool
};

export default BillingOverviewHeader;
