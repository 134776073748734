import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { MoneyValue } from '~/modules/common/components/Money';
import { TaxLineItemsRow } from './TaxLineItemsRow';
import { useTaxLineItems, useTaxLineItemsHook } from './hooks';

const useStyles = makeStyles(theme => ({
  taxContainer: { float: 'right' },
  cell: {
    borderBottom: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingBottom: 0,
    paddingTop: theme.spacing(0.5)
  },
  amountTotal: {
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'nowrap'
  },
  total: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  },
  progress: {
    marginTop: theme.spacing(2),
    marginLeft: '70%'
  }
}));

export const TaxLineItems = ({
  billId,
  setTaxTotal,
  lineItemsTotal,
  setHasTaxItems
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const cellClass = useMemo(() => ({ root: classes.cell }), [classes.cell]);
  const totalLabel = intl.formatMessage({ id: 'taxLineItems.total' });

  const { taxLineItems, loading } = useTaxLineItems(billId);

  const { total, formattedTaxLineItems = [] } = useTaxLineItemsHook({
    taxLineItems,
    lineItemsTotal,
    setTaxTotal,
    setHasTaxItems,
    intl
  });

  if (loading)
    return (
      <>
        {loading && (
          <CircularProgress
            data-qe-id="tax_line_item_loading"
            size={24}
            className={classes.progress}
          />
        )}
      </>
    );
  if (formattedTaxLineItems.length === 0) return <></>;

  return (
    <div className={classes.taxContainer}>
      {formattedTaxLineItems.map(taxLineItem => (
        <TaxLineItemsRow key={taxLineItem.id} record={taxLineItem} />
      ))}
      <TableRow variant="footer">
        <TableCell classes={cellClass}>
          <Typography variant="body2"> {totalLabel}</Typography>
        </TableCell>
        <TableCell classes={cellClass}>
          <MoneyValue className={classes.amountTotal} money={total} />
        </TableCell>
      </TableRow>
    </div>
  );
};

TaxLineItems.propTypes = {
  billId: PropTypes.string,
  setTaxTotal: PropTypes.func,
  lineItemsTotal: PropTypes.object,
  setHasTaxItems: PropTypes.func
};

export default TaxLineItems;
