import React from 'react';
import { FormattedMessage } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const ACTUAL_COST_COLOR = theme.palette.cost.actual;
const ACTUAL_COST_LABEL_COLOR = theme.palette.cost.actualLabel;
const ESTIMATED_BAR_COLOR = theme.palette.cost.estimated;

export const useCostChartConfig = () => ({
  actualBarColor: ACTUAL_COST_COLOR,
  actualLabelColor: ACTUAL_COST_LABEL_COLOR,
  gradientId: 'gradientForActualCosts',
  legends: [
    {
      label: <FormattedMessage id="chart.actualcost" />,
      color: ACTUAL_COST_COLOR
    },
    {
      label: <FormattedMessage id="chart.allocatedCost" />,
      color: ESTIMATED_BAR_COLOR
    }
  ]
});

export default useCostChartConfig;
