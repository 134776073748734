import React, { useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  Grid,
  ButtonGroup,
  ClickAwayListener,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { BillStatus } from '~/types';
import { LoadingButton } from '~/modules/common/components';
import { useMeContext } from '~/modules/me/useMeContext';
import { useIssueActionHandlers } from './hooks';
import { useBillActions } from './useBillActions';

const useStyles = makeStyles(theme => ({
  action: {
    flexDirection: 'row'
  },
  popover: {
    zIndex: 100
  },
  wordSize: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.65rem',
      padding: theme.spacing(0.3, 1)
    }
  },
  dropdownIcon: {
    display: 'flex'
  }
}));

export const IssueActionButton = ({
  openPopper,
  setOpen,
  selectedIndex,
  setSelectedIndex,
  canSyncBill,
  handleIssuePrintClick,
  handleIssueEmailClick,
  handleIssueSyncClick,
  setLoading,
  isLoading,
  billStatus,
  openBillIssueDialog
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const anchorRef = useRef(null);
  const options = useBillActions(canSyncBill, billStatus);
  const { handleClick, menuItemClick, handleToggle } = useIssueActionHandlers({
    options,
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick,
    setLoading,
    setOpen,
    openBillIssueDialog,
    billStatus,
    setSelectedIndex,
    selectedIndex
  });

  const handleClose = useCallback(
    event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    },
    [setOpen]
  );

  const buttonColor = billStatus !== BillStatus.Billed ? 'primary' : 'default';

  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.action}
      >
        <Grid item xs={12}>
          <ButtonGroup variant="contained" color={buttonColor} ref={anchorRef}>
            <Button
              onClick={handleClick}
              disabled={isLoading}
              className={classes.wordSize}
              data-qe-id="IssueActionButton"
            >
              <FormattedMessage id={options[selectedIndex].label} />
            </Button>
            <LoadingButton
              color={buttonColor}
              size="small"
              onClick={handleToggle}
              isLoading={isLoading}
              disabled={isLoading}
              aria-label={formatMessage({
                id: isPsaPrpAccessibilityIssuesEnabled
                  ? 'issueActionButton.dropdown'
                  : 'issueActionButton.loadingButton'
              })}
            >
              <ArrowDropDownIcon
                className={classes.dropdownIcon}
                data-qe-id="IssueActionDropdown"
              />
            </LoadingButton>
          </ButtonGroup>
          <Popper
            open={openPopper}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            placement="right-start"
            className={classes.popover}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((q, index) => (
                    <MenuItem
                      key={q.label}
                      onClick={menuItemClick}
                      value={q.id}
                    >
                      <FormattedMessage id={q.label} />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </Grid>
      </Grid>
    </>
  );
};

IssueActionButton.propTypes = {
  openPopper: PropTypes.bool,
  setOpen: PropTypes.func,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  canSyncBill: PropTypes.bool,
  handleIssuePrintClick: PropTypes.func,
  handleIssueEmailClick: PropTypes.func,
  handleIssueSyncClick: PropTypes.func,
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  billStatus: PropTypes.string,
  openBillIssueDialog: PropTypes.func
};

export default IssueActionButton;
