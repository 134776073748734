import { gql } from 'graphql-tag';
import { projectClientsFragment } from './projectClientsFragment';

export const UPDATE_PROJECT_CLIENTS_MUTATION = gql`
  mutation UpdateProjectClients($projectInput: ProjectInput!) {
    updateProject2(projectInput: $projectInput) {
      ...ProjectClients
    }
  }
  ${projectClientsFragment}
`;
