import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.secondary
  }
}));

export const ShowCommentsOnInvoiceCheckBox = ({
  showCommentsLabel,
  showComments,
  onShowCommentsOnInvoiceChange,
  isReadOnly,
  dataQeId,
  labelClassName
}) => {
  const classes = useStyles();
  const titleField = resourceId => {
    return (
      <Typography variant="caption" className={classes.title}>
        <FormattedMessage id={resourceId} />
      </Typography>
    );
  };
  const showCommentsCheckbox = useMemo(
    () => (
      <Checkbox
        checked={Boolean(showComments)}
        name="showCommentsOnTheInvoice"
        onChange={onShowCommentsOnInvoiceChange}
        color="primary"
        disabled={isReadOnly}
        size="small"
        data-qe-id={dataQeId && `${dataQeId}_Checkbox`}
        value={Boolean(showComments)}
      />
    ),
    [dataQeId, isReadOnly, onShowCommentsOnInvoiceChange, showComments]
  );

  return (
    <FormControlLabel
      control={showCommentsCheckbox}
      label={titleField(showCommentsLabel)}
      className={labelClassName}
    />
  );
};

ShowCommentsOnInvoiceCheckBox.propTypes = {
  showCommentsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showComments: PropTypes.bool,
  onShowCommentsOnInvoiceChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
  dataQeId: PropTypes.string,
  labelClassName: PropTypes.object
};

export default ShowCommentsOnInvoiceCheckBox;
