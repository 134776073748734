import React, { useState } from 'react';
import differenceWith from 'lodash/differenceWith';
import intersectionBy from 'lodash/intersectionBy';
import { FormattedMessage } from 'react-intl';

export const getPoolsAddedCount = (incomingPools, existingPools) => {
  const uniquePools = differenceWith(
    incomingPools,
    existingPools,
    (a, b) => a.id === b.id
  );

  return uniquePools.length || 0;
};

export const getPoolsRemovedCount = (removedPools, existingPools) =>
  intersectionBy(removedPools, existingPools, 'id').length || 0;

export const handleSnackbarMessageChange = ({
  userName,
  setSnackbarMessages,
  setSnackbarOpen
}) => async ({ poolsAdded, poolsRemoved, existingPools }) => {
  const poolsAddedCount = getPoolsAddedCount(poolsAdded, existingPools);
  const poolsRemovedCount = getPoolsRemovedCount(poolsRemoved, existingPools);

  const addedPoolsMessage =
    poolsAddedCount > 0 ? (
      poolsAddedCount === 1 ? (
        <FormattedMessage
          id="resourcePoolCard.userAddedToSpecificPool"
          values={{ user: userName, displayText: poolsAdded[0].displayText }}
        />
      ) : (
        <FormattedMessage
          id="resourcePoolCard.userAddedToPools"
          values={{ count: poolsAddedCount, user: userName }}
        />
      )
    ) : null;

  const removedPoolsMessage =
    poolsRemovedCount > 0 ? (
      poolsRemovedCount === 1 ? (
        <FormattedMessage
          id="resourcePoolCard.removedFromSpecificPool"
          values={{ user: userName, displayText: poolsRemoved[0].displayText }}
        />
      ) : (
        <FormattedMessage
          id="resourcePoolCard.removedFromPools"
          values={{ count: poolsRemovedCount, user: userName }}
        />
      )
    ) : null;

  if (!addedPoolsMessage && !removedPoolsMessage) return;

  const messageContent = (
    <div>
      {addedPoolsMessage ? (
        <div key="added-pools">{addedPoolsMessage}</div>
      ) : null}
      {removedPoolsMessage ? (
        <div key="removed-pools">{removedPoolsMessage}</div>
      ) : null}
    </div>
  );

  setSnackbarOpen(true);

  setSnackbarMessages(messageContent);
  setTimeout(() => {
    setSnackbarOpen(false);
  }, 4000);
};

export const useResourcePoolsSnackbarState = ({ userName }) => {
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const [snackBarMessages, setSnackbarMessages] = useState([]);

  const setSnackbarMessage = handleSnackbarMessageChange({
    userName,
    setSnackbarOpen,
    setSnackbarMessages
  });

  return {
    open: isSnackbarOpen,
    snackBarMessage: snackBarMessages,
    setSnackbarMessage
  };
};
