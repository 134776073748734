import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SmartFitAllocationMode } from '~/types';
import AssignmentDialogOptionItem from './AssignmentDialogOptionItem';

export const RedistributeAllocationItem = ({
  hours,
  scale,
  handleOptionSelect
}) => {
  const intl = useIntl();

  const secondary = intl.formatMessage(
    {
      id: 'resourceAssignmentDialog.redistributeAllocationSecondary'
    },
    { hours: hours.toFixed(2), scale }
  );

  const redistributeLabel = intl.formatMessage({
    id: 'resourceAssignmentDialog.redistributeAllocation'
  });

  return (
    <AssignmentDialogOptionItem
      value={SmartFitAllocationMode.Redestributeallocation}
      primary={redistributeLabel}
      secondary={secondary}
      onSelect={handleOptionSelect}
      inputProps={{
        'aria-label': redistributeLabel
      }}
    />
  );
};

RedistributeAllocationItem.propTypes = {
  hours: PropTypes.number,
  scale: PropTypes.string,
  handleOptionSelect: PropTypes.func
};

export default RedistributeAllocationItem;
