import React from 'react';
import { Caption } from '~/modules/projects/project/common/components/ContactInfo';

export const buildColumns = ({ contactHandlers, isReadOnly }) => ({
  name: {
    visible: true,
    contactHandlers,
    value: <Caption messageId="projectBasicInfoCard.contactInfo.fields.name" />
  },
  email: {
    visible: true,
    contactHandlers,
    value: <Caption messageId="projectBasicInfoCard.contactInfo.fields.email" />
  },
  phoneNumber: {
    visible: true,
    contactHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.contactInfo.fields.phoneNumber" />
    )
  },
  faxNumber: {
    visible: true,
    contactHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.contactInfo.fields.faxNumber" />
    )
  },
  website: {
    visible: true,
    contactHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.contactInfo.fields.website" />
    )
  },
  contactType: {
    visible: true,
    contactHandlers,
    value: (
      <Caption messageId="projectBasicInfoCard.contactInfo.fields.contactType" />
    )
  },
  removeContact: {
    value: '',
    contactHandlers,
    visible: !isReadOnly
  }
});
