import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const TimeAndExpenseDetailsThemeContext = createContext();

export const TimeAndExpenseDetailsThemeContextProvider = ({
  classes,
  children
}) => (
  <TimeAndExpenseDetailsThemeContext.Provider value={classes}>
    {children}
  </TimeAndExpenseDetailsThemeContext.Provider>
);

TimeAndExpenseDetailsThemeContextProvider.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired
};

export const useTimeAndExpenseThemeContext = () =>
  useContext(TimeAndExpenseDetailsThemeContext) || {};
