/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

export const useLoadingStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0, 0, 0, 2)
  },
  loadingMessage: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1)
  }
}));

export const useSkeletonStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.75, 2),
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(41.5)
  },
  resourceDetails: {
    display: 'flex'
  },
  option: {
    paddingLeft: theme.spacing(1)
  },
  resourceAvailability: {
    width: theme.spacing(10)
  }
}));

export const AvailabilitySkeleton = () => (
  <Skeleton variant="text" width={50} height={16} />
);
export const AvatarSkeleton = () => (
  <Skeleton variant="circle" width={32} height={32} />
);
export const ResourceNameSkeleton = () => (
  <Skeleton variant="text" width={128} height={16} />
);
export const RoleSkeleton = () => (
  <Skeleton variant="text" width={92} height={16} />
);

const UserAvailabilityDropdownSkeleton = ({
  isAvailabilityEnabled,
  classes
}) => {
  const skeletonClasses = useSkeletonStyles();
  const loadingClasses = useLoadingStyles();
  const { formatMessage } = useIntl();

  const SkeletonRow = () => (
    <div className={skeletonClasses.root}>
      <AvatarSkeleton />
      <div className={skeletonClasses.option}>
        <div className={skeletonClasses.resourceDetails}>
          <ResourceNameSkeleton />
          <div className={skeletonClasses.resourceAvailability} />
          {isAvailabilityEnabled && <AvailabilitySkeleton />}
        </div>
        <RoleSkeleton />
      </div>
    </div>
  );

  return (
    <>
      {isAvailabilityEnabled && (
        <div className={loadingClasses.root}>
          {formatMessage({ id: 'addResource.loading' })}
          <div className={loadingClasses.loadingMessage}>
            {formatMessage({
              id: 'addResource.availabilityOnLoadingMessage'
            })}
          </div>
        </div>
      )}
      <ul className={classes.groupUl}>
        {[...Array(4)].map((_, index) => (
          <SkeletonRow key={`Option_${index}`} />
        ))}
      </ul>
    </>
  );
};

UserAvailabilityDropdownSkeleton.propTypes = {
  isAvailabilityEnabled: PropTypes.bool,
  classes: PropTypes.object
};

export default UserAvailabilityDropdownSkeleton;
