import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { TabBar } from '~/modules/common/components';
import { useMeContext } from '~/modules/me/useMeContext';
import resourcingRoute from '~/modules/resourcing/resourcingRoute';
import quickAllocationRoute from '~/modules/quickAllocation/route';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(48)
  }
}));

const tabRoutes = ({ intl, me, isMobile }) => [
  {
    ...resourcingRoute({ intl, me, isMobile }),
    title: () => intl.formatMessage({ id: 'resourcingTabs.resourceRequests' })
  },
  {
    ...quickAllocationRoute({
      intl,
      me,
      isMobile
    }),
    hidden: false
  }
];

const ResourcingTabs = () => {
  const intl = useIntl();
  const isMobile = useIsBreakpointDown('sm');
  const me = useMeContext();
  const classes = useStyles();

  const routes = useMemo(() => tabRoutes({ me, isMobile, intl }), [
    me,
    isMobile,
    intl
  ]);

  return <TabBar classes={classes} tabRoutes={routes} noBottomBorder />;
};

ResourcingTabs.propTypes = {};

export default ResourcingTabs;
