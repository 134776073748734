import { useMemo } from 'react';
import { useSessionStorage } from '~/modules/common/hooks';

export const useGetResourceRequestFilter = () => {
  const { storedValue: variables } =
    useSessionStorage('RESOURCE-REQUESTS-QUERY-VARIABLES', null) || {};

  const filter = variables?.filter;

  return useMemo(() => {
    if (!filter) {
      return {};
    }

    const { roles, text, users, resourcePools } = filter;

    return {
      searchText: text,
      roleUris: roles,
      userIds: users,
      resourcePoolIds: resourcePools
    };
  }, [filter]);
};
