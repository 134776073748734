import { useIntl } from 'react-intl';
import { getAbbreviatedProps } from '~/modules/common/charts/dashboard/util';

export const COST_TYPE_ENUM = {
  ESTIMATED: 'ESTIMATED',
  ACTUALS: 'ACTUALS'
};

export const computeChartPoints = (costs, type, intl) =>
  costs.map(({ estimated, actual, id }) => {
    const costValue =
      type === COST_TYPE_ENUM.ESTIMATED ? estimated || 0 : actual || 0;

    return {
      name: intl.formatMessage({ id }),
      value: costValue,
      label:
        costValue > 0
          ? `${abbreviatedDecimalValue({ intl, value: costValue })}`
          : ''
    };
  });

export const abbreviatedDecimalValue = ({ intl, value }) => {
  const { scaledNumber, abbreviatedSuffix } = getAbbreviatedProps({
    value,
    intl
  });

  return `${
    Number.isInteger(scaledNumber) ? scaledNumber : scaledNumber.toFixed(2)
  }${abbreviatedSuffix}`;
};

export const useProjectBudgetCostChartData = projectCostSummary => {
  const intl = useIntl();

  const {
    estimatedResourceCost,
    estimatedResourceCostAtCompletion,
    allocatedResourceCost,
    actualResourceCost
  } = projectCostSummary || {};

  const values = [
    {
      estimated: estimatedResourceCost?.amount,
      actual: actualResourceCost?.amount,
      id: 'chart.projects'
    },
    {
      estimated: estimatedResourceCostAtCompletion?.amount,
      actual: actualResourceCost?.amount,
      id: 'chart.tasks'
    },
    {
      estimated: allocatedResourceCost?.amount,
      actual: actualResourceCost?.amount,
      id: 'chart.resources'
    }
  ];

  return {
    estimatedCostsDataPoints: computeChartPoints(
      values,
      COST_TYPE_ENUM.ESTIMATED,
      intl
    ),
    actualCostsDataPoints: computeChartPoints(
      values,
      COST_TYPE_ENUM.ACTUALS,
      intl
    )
  };
};

export default useProjectBudgetCostChartData;
