import { useSearchableDivisions } from '~/modules/common/enhancers';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { divisionTag } from './tag';
import DivisionFacetDetails from './DivisionFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableDivisions);

export default {
  tag: divisionTag,
  makeCriteriaProvider,
  DetailsControl: DivisionFacetDetails
};
