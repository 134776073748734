/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { GanttTooltip } from '../../components/GanttTooltip';

export const TASK_LINE = 'gantt_task_line';
export const TASK_BAR_TOOLTIP = 'gantt_task_bar_name_tooltip';

export const removeAllTooltips = () => {
  const tooltips = document.getElementsByClassName('task_tooltip');

  while (tooltips.length > 0) {
    tooltips[0].parentNode.removeChild(tooltips[0]);
  }
};

const removeTooltips = event => {
  event.target.onmousemove = undefined;
  removeAllTooltips();
};

const getTooltipPosition = event => {
  const { clientX, clientY } = event;
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const tooltipHeight = 300;
  const tooltipWidth = 305;
  const offset = 25;
  let top = clientY + offset;
  let left = clientX + offset;

  if (windowHeight < top + tooltipHeight) {
    top = clientY - tooltipHeight - offset + 90;
  }

  if (windowWidth < left + tooltipWidth + 15) {
    left = clientX - tooltipWidth - offset;
  }

  return {
    top,
    left
  };
};

export const onEnterTooltip = ({
  gantt,
  intl,
  numberFormat,
  canViewEstimate,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  isPsaRmpTaskAllocation1Enabled
}) => event => {
  if (document.getElementsByClassName('task_tooltip').length === 0) {
    const targetTaskId =
      gantt.locate(event) || event.target.parentNode?.attributes?.taskid?.value;

    if (gantt.isTaskExists(targetTaskId)) {
      const task = gantt.getTask(targetTaskId);
      const tooltip = document.createElement('div');
      const { top, left } = getTooltipPosition(event);

      tooltip.style.position = 'absolute';
      tooltip.style.zIndex = 9999;
      tooltip.style.left = `${left}px`;
      tooltip.style.top = `${top}px`;
      tooltip.style.pointerEvents = 'none';
      tooltip.className = 'task_tooltip';
      tooltip.innerHTML = renderToStaticMarkup(
        <GanttTooltip
          numberFormat={numberFormat}
          intl={intl}
          canViewEstimate={canViewEstimate}
          task={task}
          isRTL={isRTL}
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacEnhancements2Enabled={
            isPsaPpmCostEacEnhancements2Enabled
          }
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      );
      document.body.appendChild(tooltip);
      event.target.onmousemove = e => {
        const { top: topMove, left: leftMove } = getTooltipPosition(e);

        tooltip.style.left = `${leftMove}px`;
        tooltip.style.top = `${topMove}px`;
      };
    }
  }

  return null;
};

export const useOnTaskHoverTooltips = ({ intl, me, canViewEstimate }) => {
  const numberFormat = getReactNumberFormatFromMe(me);

  const {
    featureFlags: {
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaPpmCostEacEnhancements2Enabled,
      isPsaRmpTaskAllocation1Enabled
    }
  } = me;

  return useCallback(
    (gantt, isRTL) => {
      if (me.isRolledUpTaskEstimateCalculationMethodEnabled) {
        const hoverTooltips = onEnterTooltip({
          gantt,
          intl,
          numberFormat,
          canViewEstimate,
          isRTL,
          isPsaPpmCostEacEnhancementsEnabled,
          isPsaPpmCostEacEnhancements2Enabled,
          isPsaRmpTaskAllocation1Enabled
        });

        document
          .querySelectorAll(`.${TASK_LINE}, .${TASK_BAR_TOOLTIP} > div`)
          .forEach(el => {
            el.onmouseenter = hoverTooltips;
            el.onmouseleave = removeTooltips;
          });
      }
    },
    [
      canViewEstimate,
      intl,
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaPpmCostEacEnhancements2Enabled,
      me.isRolledUpTaskEstimateCalculationMethodEnabled,
      numberFormat,
      isPsaRmpTaskAllocation1Enabled
    ]
  );
};

export default useOnTaskHoverTooltips;
