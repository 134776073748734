const styles = theme => ({
  victoryChart: {
    padding: {
      top: theme.spacing(3),
      bottom: theme.spacing(4),
      left: theme.spacing(4),
      right: theme.spacing(1)
    },
    domainPadding: { x: [103, 40], y: [0, 20] },
    width: 500,
    height: 350,
    scaleXTime: { x: 'time' }
  },
  victoryAxis: {
    x: {
      tickLabels: {
        paddingTop: 15,
        angle: 0,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        verticalAnchor: 'middle',
        textAnchor: 'end',
        fill: theme.palette.grey[600]
      },
      axis: {
        stroke: theme.palette.grey[100]
      }
    },
    y: {
      tickLabels: {
        padding: 1,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fill: theme.palette.grey[600]
      },
      axis: {
        stroke: theme.palette.common.white
      },
      grid: {
        stroke: theme.palette.grey[100]
      }
    }
  },
  victoryBar: {
    main: { data: { fill: theme.palette.grey[500] } }
  },
  maxLimit: {
    data: {
      stroke: theme.palette.grey[900],
      strokeWidth: 2,
      strokeDasharray: 7
    }
  },
  victoryLegend: {
    style: {
      labels: {
        fontSize: 18
      }
    }
  },
  victoryLabel: {
    actual: {
      fontFamily: theme.typography.fontFamily
    },
    estimate: {
      fill: theme.palette.grey[600],
      fontFamily: theme.typography.fontFamily
    }
  },
  container: {}
});

export default styles;
