import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';

import {
  FormControl,
  InputLabel,
  Chip,
  Select,
  MenuItem,
  Input,
  Checkbox,
  ListItemText,
  withStyles
} from '@material-ui/core';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250
    }
  }
};

const style = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  select: {
    height: 'auto'
  }
});

const isChecked = (option, values) =>
  values.some(value => value === option.key);

const getLabel = (options, key) =>
  (options.find(option => option.key === key) || { label: 'NA' }).label;

const input = <Input id="select-multiple-chip" />;

const CheckChipDropdown = ({
  className,
  classes,
  label,
  options,
  values = [],
  onChange,
  onRemove,
  multiselect = true,
  disabled = false
}) => (
  <div className={className}>
    <FormControl fullWidth>
      <InputLabel htmlFor="chipdown">{label}</InputLabel>
      <Select
        classes={useMemo(() => ({ selectMenu: classes.select }), [
          classes.select
        ])}
        multiple={multiselect}
        value={multiselect ? values : values[0]}
        onChange={onChange}
        disableUnderline
        disabled={disabled}
        input={input}
        renderValue={selected => (
          <div className={classes.chips}>
            {multiselect ? (
              selected.map(value => (
                <Chip
                  key={value}
                  label={getLabel(options, value)}
                  className={classes.chip}
                  onDelete={
                    !disabled && onRemove && selected.length > 1
                      ? onRemove(value)
                      : undefined
                  }
                />
              ))
            ) : (
              <Chip
                key={selected}
                label={getLabel(options, selected)}
                className={classes.chip}
                onDelete={
                  !disabled && onRemove ? onRemove(selected) : undefined
                }
              />
            )}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map(option => (
          <MenuItem key={option.key} value={option.key}>
            {multiselect && (
              <Checkbox
                color="primary"
                checked={isChecked(option, values)}
                disabled={isChecked(option, values) && values.length === 1}
              />
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

CheckChipDropdown.propTypes = {
  className: PropTypes.any,
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  multiselect: PropTypes.bool,
  disabled: PropTypes.bool
};

export default withStyles(style)(CheckChipDropdown);
