import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  na: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  }
}));

const NoDataCell = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.na}>
      <FormattedMessage id="taskDrawer.na" />
    </Typography>
  );
};

export default NoDataCell;
