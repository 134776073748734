import { useCallback } from 'react';
import {
  FILE_TYPE_URI,
  NUMERIC_TYPE_URI,
  TAG_TYPE_URI,
  TEXT_TYPE_URI
} from '~/modules/extensionFields/definitionTypes';

export const handleExtensionFieldValueChange = ({
  id,
  definitionTypeUri,
  setFieldValue
}) => (event, value) => {
  switch (definitionTypeUri) {
    case TEXT_TYPE_URI:
      setFieldValue(`extensionFieldValues.${id}.textValue`, event.target.value);
      break;
    case NUMERIC_TYPE_URI:
      setFieldValue(
        `extensionFieldValues.${id}.numericValue`,
        event.target.value
      );
      break;
    case FILE_TYPE_URI:
      setFieldValue(`extensionFieldValues.${id}.fileValue`, value);
      break;
    case TAG_TYPE_URI:
      setFieldValue(`extensionFieldValues.${id}.tag`, value || null);
      break;
    default:
      break;
  }
};

export const useExtensionFieldChangeHandler = () =>
  useCallback(({ id, definitionTypeUri, setFieldValue }) =>
    handleExtensionFieldValueChange({
      id,
      definitionTypeUri,
      setFieldValue
    })
  );
