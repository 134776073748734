import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  expandIconOpen: {
    transform: 'rotate(180deg)'
  },
  button: {},
  root: {},
  expanded: {}
}));

const ToggleDetailsButton = ({
  open,
  onChange,
  classes: classesOverride,
  resourceKeys
}) => {
  const classes = useStyles({ classes: classesOverride });
  const handleClick = useCallback(() => {
    onChange(!open);
  }, [onChange, open]);

  return (
    <Paper
      className={classNames(classes.root, { [classes.expanded]: open })}
      square
      elevation={0}
    >
      <Button
        onClick={handleClick}
        className={classes.button}
        startIcon={useMemo(
          () => (
            <ExpandMoreIcon
              className={classNames(classes.expandIcon, {
                [classes.expandIconOpen]: open
              })}
            />
          ),
          [classes.expandIcon, classes.expandIconOpen, open]
        )}
      >
        {open ? resourceKeys.hide : resourceKeys.show}
      </Button>
    </Paper>
  );
};

ToggleDetailsButton.propTypes = {
  open: PropTypes.bool,
  onChange: PropTypes.func,
  resourceKeys: PropTypes.object,
  classes: PropTypes.object
};

export default ToggleDetailsButton;
