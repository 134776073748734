import { useMemo } from 'react';
import { useFormik } from 'formik';
import { array, object } from 'yup';
import { useIntl } from 'react-intl';
import { EFFECTIVE_DATE } from './enum';
import {
  hasSameValueForDimension,
  hasSameEffectiveDate,
  getErrorForKey,
  mapProjectRates,
  getCreateDefaultEntry
} from './utils';

export const useInitialState = ({
  projectCurrency,
  projectDimensions,
  loadingMore,
  projectRates
}) => {
  const values = useMemo(
    () => ({
      projectDimensions:
        projectDimensions && projectDimensions.length > 0
          ? projectDimensions
          : [],
      projectRates:
        projectRates && projectRates.length > 0
          ? mapProjectRates(projectRates, projectDimensions, loadingMore)
          : getCreateDefaultEntry(projectDimensions, projectCurrency),
      removedProjectRateEntryGroup: []
    }),
    [projectDimensions, projectRates, loadingMore, projectCurrency]
  );

  return values;
};
export const validateFields = ({ data, intl }) => {
  const { projectRates, projectDimensions } = data;
  const errors = {};
  const projectRatesByGroup = projectRates.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.groupId]: retVal[curr.groupId]
        ? [...retVal[curr.groupId], curr]
        : [curr]
    }),
    []
  );

  const getErrorKeyForFormValidation = getErrorForKey;

  projectRates.forEach((rate, index) => {
    const { effectiveDate, isInitialRate, groupId, ...rest } = rate;

    if (!isInitialRate && !effectiveDate) {
      errors.effectiveDate = getErrorKeyForFormValidation({
        index,
        key: EFFECTIVE_DATE,
        intl,
        errors,
        type: 'required',
        field: intl.formatMessage({ id: 'advancedRateCard.effectiveDate' })
      });
    }
    if (
      hasSameEffectiveDate(
        isInitialRate,
        effectiveDate,
        projectRatesByGroup[groupId],
        rate
      )
    ) {
      errors.effectiveDate = getErrorKeyForFormValidation({
        index,
        key: EFFECTIVE_DATE,
        intl,
        errors,
        type: 'duplicate',
        field: intl.formatMessage({ id: 'advancedRateCard.effectiveDate' })
      });
    }
    projectDimensions.forEach((d, i) => {
      if (rest[d.id] && !rest[d.id].value) {
        errors[d.id] = getErrorKeyForFormValidation({
          index,
          key: d.id,
          intl,
          errors,
          type: 'required',
          field: d.displayText
        });
      }
      if (
        hasSameValueForDimension(
          projectRates,
          rate,
          d.id,
          projectDimensions[i - 1]?.id
        )
      ) {
        errors[d.id] = getErrorKeyForFormValidation({
          index,
          key: d.id,
          intl,
          errors,
          type: 'duplicate',
          field: d.displayText
        });
      }
    });
  });

  return errors;
};

export const useValidationSchema = ({ intl }) =>
  object().shape({
    projectRates: array().test('requiredTest', function validate() {
      const fieldErrors = validateFields({
        data: this.parent,
        intl
      });

      return Object.keys(fieldErrors).length
        ? this.createError({
            path: this.path,
            message: {
              ...fieldErrors
            }
          })
        : {};
    })
  });

export const useFormState = ({
  projectRateDimensions,
  projectRates,
  projectCurrency,
  loadingMore
}) => {
  const initialValues = useInitialState({
    projectCurrency,
    loadingMore,
    projectDimensions: projectRateDimensions,
    projectRates
  });
  const intl = useIntl();
  const validationSchema = useValidationSchema({
    intl
  });

  return useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema
  });
};
