import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  backButton: {
    margin: theme.spacing(-1.5, 0, -1.5, -0.5),
    paddingRight: theme.spacing(0.5),
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(-2)
    }
  },
  nextButton: {
    margin: theme.spacing(-1.5, -1.5, -1.5, 0),
    borderTopLeftRadius: '25%',
    borderBottomLeftRadius: '25%',
    paddingLeft: theme.spacing(0.5)
  },
  caption: {
    ...theme.typography.caption,
    flexGrow: 0,
    flexShrink: 1,
    color: theme.palette.text.secondary
  }
}));

export default useStyles;
