import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import React from 'react';
import { slugNotFound } from '~/modules/common/nonOptimalStates';
import { RedirectToNotFound } from '~/modules/common/notfound';

export const PROGRAM_ISACTIVE_QUERY_BY_SLUG = gql`
  query programIsActiveBySlug($slug: String!) {
    program(programSlug: $slug) {
      isActive
    }
  }
`;

export const useProgramIsActive = ({ programSlug }) => {
  const { isLoading, data, error } = useQuery(PROGRAM_ISACTIVE_QUERY_BY_SLUG, {
    variables: {
      slug: programSlug
    }
  });

  if (error) return slugNotFound({ data: { error } }) && <RedirectToNotFound />;

  const isActive = get(data, 'program.isActive');

  return { loading: isLoading, programActive: isActive };
};

export default useProgramIsActive;
