import { uniqBy } from 'lodash';

export const VIEW_RESOURCE_POOLS_PAGE_SIZE = 10;
export const FETCH_RESOURCE_POOLS_PAGE_SIZE = 20;

export const ADD_RESOURCE_POOL = 'ADD_RESOURCE_POOL';
export const CANCEL_RESOURCE_POOL = 'CANCEL_RESOURCE_POOL';
export const DELETE_RESOURCE_POOL = 'DELETE_RESOURCE_POOL';
export const SAVE_RESOURCE_POOL = 'SAVE_RESOURCE_POOL';
export const FETCH_RESOURCE_POOLS = 'FETCH_RESOURCE_POOLS';
export const FETCH_RESOURCE_POOLS_WITH_SEARCH =
  'FETCH_RESOURCE_POOLS_WITH_SEARCH';

export const initialResourcePoolReducerState = {
  selectedResourcePools: [],
  currentPageOfResourcePools: [],
  unselectedResourcePools: [],
  resourcePoolsCount: 0,
  allFetchedResourcePools: [],
  selectedPoolCountTally: 0
};

export const filterArrayOnSearchTerm = ({ target, searchTerm }) =>
  target.filter(pool =>
    pool.displayText.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const filterResourcePoolsOnPoolId = ({ target, poolId }) =>
  target.filter(pool => pool.id !== poolId) || [];

export const insertFetchedPools = ({
  currentPage,
  incomingResourcePools,
  existingFetchedPoolsState
}) => {
  if (currentPage === 1) {
    return incomingResourcePools;
  }
  const startIndex = (currentPage - 1) * FETCH_RESOURCE_POOLS_PAGE_SIZE;
  const endIndex = startIndex + FETCH_RESOURCE_POOLS_PAGE_SIZE;

  const copyOfExistingState = [...existingFetchedPoolsState];

  copyOfExistingState.splice(startIndex, endIndex, ...incomingResourcePools);

  return copyOfExistingState;
};

export const setPageOfResourcePools = ({
  allFetchedResourcePools,
  selectedResourcePools,
  unselectedResourcePools,
  page,
  searchTerm
}) => {
  const selectedPoolsFiltered = searchTerm
    ? filterArrayOnSearchTerm({
        target: selectedResourcePools,
        searchTerm
      })
    : selectedResourcePools;

  const allPools = uniqBy(
    [...selectedPoolsFiltered, ...allFetchedResourcePools].filter(
      pool => !unselectedResourcePools.find(x => x.id === pool.id)
    ),
    'id'
  );

  const startIndex = (page - 1) * VIEW_RESOURCE_POOLS_PAGE_SIZE;
  const endIndex = startIndex + VIEW_RESOURCE_POOLS_PAGE_SIZE;

  return allPools.slice(startIndex, endIndex) || [];
};

export const makeResourcePoolsReducer = ({ searchText, page }) => (
  state,
  action
) => {
  switch (action.type) {
    case ADD_RESOURCE_POOL: {
      const appendedSelectedResourcePools = [
        action.pool,
        ...state.selectedResourcePools
      ];
      const filteredUnselectedResourcePools = filterResourcePoolsOnPoolId({
        target: state.unselectedResourcePools,
        poolId: action.pool.id
      });

      const incrementIfPoolMatchesSearchText = action.pool.displayText
        .toLowerCase()
        .includes(searchText.toLowerCase())
        ? 1
        : 0;

      return {
        ...state,
        currentPageOfResourcePools: setPageOfResourcePools({
          allFetchedResourcePools: state.allFetchedResourcePools,
          unselectedResourcePools: filteredUnselectedResourcePools,
          selectedResourcePools: appendedSelectedResourcePools,
          page,
          searchTerm: searchText,
          resourcePoolsCount: state.resourcePoolsCount
        }),
        selectedResourcePools: appendedSelectedResourcePools,
        unselectedResourcePools: filteredUnselectedResourcePools,
        resourcePoolsCount:
          state.resourcePoolsCount + incrementIfPoolMatchesSearchText,
        selectedPoolCountTally: state.selectedPoolCountTally + 1
      };
    }
    case DELETE_RESOURCE_POOL: {
      const filteredSelectedResourcePools = filterResourcePoolsOnPoolId({
        target: state.selectedResourcePools,
        poolId: action.pool.id
      });

      const appendedUnselectedResourcePools = [
        ...state.unselectedResourcePools,
        action.pool
      ];

      return {
        ...state,
        selectedResourcePools: filteredSelectedResourcePools,
        allFetchedResourcePools: state.allFetchedResourcePools,
        currentPageOfResourcePools: setPageOfResourcePools({
          allFetchedResourcePools: state.allFetchedResourcePools,
          unselectedResourcePools: appendedUnselectedResourcePools,
          selectedResourcePools: filteredSelectedResourcePools,
          page,
          searchTerm: searchText
        }),
        unselectedResourcePools: appendedUnselectedResourcePools,
        resourcePoolsCount: state.resourcePoolsCount - 1,
        selectedPoolCountTally: state.selectedPoolCountTally - 1
      };
    }
    case CANCEL_RESOURCE_POOL: {
      return {
        ...state,
        selectedResourcePools: [],
        unselectedResourcePools: [],
        currentPageOfResourcePools: setPageOfResourcePools({
          allFetchedResourcePools: state.allFetchedResourcePools,
          unselectedResourcePools: [],
          selectedResourcePools: [],
          page,
          searchTerm: searchText
        }),
        resourcePoolsCount: action.resourcePoolsCount || 0,
        selectedPoolCountTally: 0
      };
    }
    case SAVE_RESOURCE_POOL: {
      return {
        ...state,
        selectedResourcePools: [],
        unselectedResourcePools: [],
        currentPageOfResourcePools: setPageOfResourcePools({
          allFetchedResourcePools: state.allFetchedResourcePools,
          unselectedResourcePools: [],
          selectedResourcePools: [],
          page,
          searchTerm: searchText
        }),
        selectedPoolCountTally: 0
      };
    }
    case FETCH_RESOURCE_POOLS: {
      const splicedResourcePools = insertFetchedPools({
        currentPage: page,
        incomingResourcePools: action.fetchedResourcePools || [],
        existingFetchedPoolsState: state.allFetchedResourcePools || []
      });

      return {
        ...state,
        allFetchedResourcePools: splicedResourcePools || [],
        currentPageOfResourcePools: setPageOfResourcePools({
          allFetchedResourcePools: splicedResourcePools || [],
          unselectedResourcePools: state.unselectedResourcePools,
          selectedResourcePools: state.selectedResourcePools,
          page
        }),
        resourcePoolsCount:
          action.resourcePoolsCount + state.selectedPoolCountTally || 0
      };
    }
    case FETCH_RESOURCE_POOLS_WITH_SEARCH: {
      const selectedPoolsFilteredCount = filterArrayOnSearchTerm({
        target: state.selectedResourcePools,
        searchTerm: searchText
      }).length;

      const unselectedPoolsFilteredCount = filterArrayOnSearchTerm({
        target: state.unselectedResourcePools,
        searchTerm: searchText
      }).length;

      const splicedResourcePools = insertFetchedPools({
        currentPage: page,
        incomingResourcePools: action.fetchedResourcePools || [],
        existingFetchedPoolsState: state.allFetchedResourcePools || []
      });

      return {
        ...state,
        allFetchedResourcePools: splicedResourcePools || [],
        currentPageOfResourcePools: setPageOfResourcePools({
          allFetchedResourcePools: splicedResourcePools || [],
          unselectedResourcePools: state.unselectedResourcePools,
          selectedResourcePools: state.selectedResourcePools,
          page,
          searchTerm: searchText
        }),
        resourcePoolsCount:
          action.resourcePoolsCount +
          selectedPoolsFilteredCount -
          unselectedPoolsFilteredCount
      };
    }
    default:
      return state;
  }
};
