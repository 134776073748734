import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AllRowSelectorFormatter } from '../formatters';

export const buildColumns = ({
  classes,
  selectedColumns,
  editable,
  selectedTransactionItems,
  allSelectedStatus,
  onRowSelect,
  onSelectAll,
  tags,
  refetchBillingTransactionRows
}) => {
  const columns = {
    rowSelector: {
      field: 'rowSelector',
      visible: selectedColumns.includes('rowSelector'),
      onRowSelect,
      selectedTransactionItems,
      className: classes.rowSelector,
      value: (
        <AllRowSelectorFormatter
          onSelectAll={onSelectAll}
          allSelectedStatus={allSelectedStatus}
        />
      )
    },
    displayText: {
      field: 'displayText',
      visible: selectedColumns.includes('displayText'),
      value: <FormattedMessage id="billingTransactionList.referenceNumber" />,
      className: classes.name,
      sortable: true
    },
    pastDueDays: {
      field: 'pastDueDays',
      visible: selectedColumns.includes('pastDueDays'),
      value: <FormattedMessage id="billingTransactionList.pastDue" />,
      sortable: true
    },
    client: {
      field: 'client',
      visible: selectedColumns.includes('client'),
      value: <FormattedMessage id="billingTransactionList.client" />,
      sortable: true
    },
    transactionDate: {
      field: 'transactionDate',
      visible: selectedColumns.includes('transactionDate'),
      value: <FormattedMessage id="billingTransactionList.date" />,
      sortable: true
    },
    dueDate: {
      field: 'dueDate',
      visible: selectedColumns.includes('dueDate'),
      value: <FormattedMessage id="billingTransactionList.dueDate" />,
      sortable: true
    },
    period: {
      field: 'period',
      visible: selectedColumns.includes('period'),
      value: <FormattedMessage id="billingTransactionList.period" />,
      sortable: true
    },
    type: {
      field: 'type',
      visible: selectedColumns.includes('type'),
      value: <FormattedMessage id="billingTransactionList.type" />,
      sortable: true
    },
    balanceWithoutAllocationsAmount: {
      field: 'balanceWithoutAllocationsAmount',
      visible: selectedColumns.includes('balanceWithoutAllocationsAmount'),
      value: <FormattedMessage id="billingTransactionList.originalAmount" />,
      align: 'right',
      sortable: true
    },
    balanceAmount: {
      field: 'balanceAmount',
      visible: selectedColumns.includes('balanceAmount'),
      value: <FormattedMessage id="billingTransactionList.balance" />,
      align: 'right',
      sortable: true
    },
    balanceStatus: {
      field: 'balanceStatus',
      visible: selectedColumns.includes('balanceStatus'),
      value: <FormattedMessage id="billingTransactionList.status" />
    },
    actionColumn: {
      field: 'actionColumn',
      visible: selectedColumns.includes('actionColumn') && editable,
      value: '',
      refetchBillingTransactionRows
    },
    description: {
      field: 'description',
      visible: selectedColumns.includes('description'),
      value: <FormattedMessage id="billingTransactionList.description" />,
      sortable: true,
      className: classes.description
    }
  };

  const tagColumns = (tags || []).reduce(
    (obj, key) => ({
      ...obj,
      [key.id]: {
        field: key.id,
        visible: selectedColumns.includes(key.id),
        value: key.displayText,
        className: classes.tag
      }
    }),
    {}
  );

  return { ...columns, ...tagColumns };
};

export const columnGroups = (classes, selectedColumns, editable, tags) => {
  const columns = buildColumns({
    classes,
    selectedColumns,
    editable,
    tags
  });

  return [
    {
      id: 'basic',
      title: null,
      columns: Object.values(getFilteredColumns(columns)),
      footers: []
    }
  ];
};

export const defaultColumnNames = (
  classes,
  selectedColumns,
  editable,
  tags
) => {
  const columns = buildColumns({
    classes,
    selectedColumns,
    editable,
    tags
  });

  return Object.values(getFilteredColumns(columns)).map(c => c.field);
};

const getFilteredColumns = columns =>
  Object.keys(columns || {})
    .filter(key => columns[key] && columns[key].visible)
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: columns[key]
      }),
      {}
    );
