import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useContentStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    padding: theme.spacing(0.625, 1, 0.625, 2),
    height: 40,
    display: 'flex',
    alignItems: 'center'
  },
  spacing: {
    margin: theme.spacing(0.25, 0)
  }
}));

const SkeletonContentComponent = () => {
  const classes = useContentStyles();

  return (
    <div className={classes.root}>
      <Skeleton
        className={classes.spacing}
        variant="rect"
        width="100%"
        height={15}
      />
    </div>
  );
};

const useRowStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white
  },
  content: {
    width: '100%'
  }
}));

export const ResourceAllocationGroupRowLoading = () => {
  const classes = useRowStyles();

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.content} item>
        <SkeletonContentComponent />
      </Grid>
    </Grid>
  );
};

export default ResourceAllocationGroupRowLoading;
