import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '@material-ui/core';

import FormDateField from '~/modules/common/components/FormDateField';
import FormField from '~/modules/common/components/FormField';
import FormTextField from '~/modules/common/components/FormTextField';
import FormNumberField from '~/modules/common/components/FormNumberField';
import FormSelectField from '~/modules/common/components/FormSelectField';
import { mapRepliconDateToJSDate } from '~/modules/common/dates/convert';

const FormCustomField = ({
  value,
  definition,
  editable,
  onChange,
  ...rest
}) => {
  switch (definition.type.uri) {
    case 'urn:replicon:custom-field-type:text':
      return (
        <FormTextField
          label={definition.displayText}
          value={value}
          editable={editable}
          onChange={onChange}
          required={definition.isRequired}
          {...rest}
        />
      );
    case 'urn:replicon:custom-field-type:numeric':
      return (
        <FormNumberField
          label={definition.displayText}
          value={value}
          editable={editable}
          onValueChange={onChange}
          required={definition.isRequired}
          precision={definition.numericConfiguration.decimalPlaces}
          {...rest}
        />
      );
    case 'urn:replicon:custom-field-type:date':
      return (
        <FormDateField
          label={definition.displayText}
          value={value}
          editable={editable}
          onChange={onChange}
          required={definition.isRequired}
          minDate={
            definition.dateConfiguration &&
            definition.dateConfiguration.minimumDate
              ? mapRepliconDateToJSDate(
                  definition.dateConfiguration.minimumDate
                )
              : undefined
          }
          maxDate={
            definition.dateConfiguration &&
            definition.dateConfiguration.maximumDate
              ? mapRepliconDateToJSDate(
                  definition.dateConfiguration.maximumDate
                )
              : undefined
          }
          {...rest}
        />
      );
    case 'urn:replicon:custom-field-type:drop-down':
      return (
        <FormSelectField
          variant="outlined"
          required={definition.isRequired}
          editable={editable}
          label={definition.displayText}
          onChange={onChange}
          value={value}
          displayEmpty={!definition.isRequired}
          shrink={Boolean(value)}
          {...rest}
        >
          {!definition.isRequired && (
            <MenuItem value={null}>
              <FormattedMessage id="formCustomField.none" />
            </MenuItem>
          )}
          {definition.dropDownOptions
            .filter(option => option.isEnabled)
            .map(option => (
              <MenuItem key={option.uri} value={option.uri}>
                {option.displayText}
              </MenuItem>
            ))}
        </FormSelectField>
      );
    default:
      return (
        <FormField>
          <FormattedMessage id="formCustomField.notSupported" />
        </FormField>
      );
  }
};

FormCustomField.propTypes = {
  value: PropTypes.any,
  definition: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func
};

export default FormCustomField;
