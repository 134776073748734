import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  CircularProgress,
  Grid,
  Icon,
  Button
} from '@material-ui/core';
import RateCardIcon from '@material-ui/icons/AttachMoneySharp';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { NoDataItem } from '~/modules/common/components';
import { useDialogState, useHasFeatureFlag } from '~/modules/common/hooks';
import { useFormState } from './hooks/useFormState';
import {
  AddRateTypeButton,
  RateCardEditable,
  RateCopyConfirmationDialog
} from './components';
import { useRateEditHandlers } from './hooks/useRateEditHandlers';

const avatar = <RateCardIcon />;

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  copyFromClientButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  progress: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    background: theme.palette.grey[200],
    color: theme.palette.text.secondary
  },
  spinner: {
    marginRight: theme.spacing(3)
  }
}));
const useHeaderStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    ...theme.typography.h6,
    color: theme.palette.text.secondary
  },
  action: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(-2)
  },
  subheader: {
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    marginTop: '2px',
    fontSize: theme.typography.body2.fontSize,
    '& strong': {
      color: theme.palette.common.black
    }
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const useCardStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&:last-child': { paddingBottom: 0 }
  }
}));

const useNoDataStyles = makeStyles(theme => ({
  noData: { padding: theme.spacing(2) },
  root: {
    '& span': {
      hyphens: 'unset'
    }
  }
}));

const useIconStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    marginRight: theme.spacing(2)
  }
}));

const appendKeyToRate = rate => ({ key: v4(), ...rate });

export const RateCard = ({
  editable,
  isLoading,
  defaultRates,
  hasMoreResourceRateRows,
  hasMoreRoleRateRows,
  loadMoreRows,
  projectUri: projectId,
  projectSlug,
  clientUri: clientId,
  context,
  projectRate,
  userRates,
  roleRates,
  putRatesHandler,
  parentUri
}) => {
  const isPsaFpAdvancedRateCardEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFpAdvancedRateCardEnabled'
  });

  const formik = useFormState({
    projectRate,
    roleRates,
    userRates,
    parentUri,
    putRatesHandler
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    validateForm,
    resetForm,
    errors
  } = formik;

  const initialRates = useMemo(
    () => ({
      userRates: userRates || [],
      roleRates: roleRates || [],
      projectRate: projectRate || []
    }),
    [userRates, roleRates, projectRate]
  );
  const { open, openDialog, closeDialog } = useDialogState();
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const cardClasses = useCardStyles();

  const noDataClasses = useNoDataStyles();
  const iconClasses = useIconStyles();

  const noDataItemClasses = useMemo(
    () => ({
      root: noDataClasses.root
    }),
    [noDataClasses.root]
  );

  const rateEditHandlers = useRateEditHandlers({
    setFieldValue
  });
  const rates = useMemo(
    () => ({
      userRates: (values && values.userRates) || [],
      roleRates: (values && values.roleRates) || [],
      projectRate: (values && values.projectRate) || []
    }),
    [values]
  );
  const rateTypeKeys = useMemo(() => Object.keys(rates || {}), [rates]);
  const hasRates = useMemo(() => rateTypeKeys.some(x => rates[x].length), [
    rates,
    rateTypeKeys
  ]);

  const hasDefaultRates = useMemo(
    () =>
      rateTypeKeys.some(
        x => defaultRates && defaultRates[x] && defaultRates[x].length
      ),
    [rateTypeKeys, defaultRates]
  );

  const [copyClientInProgress, setCopyClientInProgress] = useState(false);
  const onCopyClick = useCallback(async () => {
    setCopyClientInProgress(true);
    closeDialog();
    rateTypeKeys.forEach(propName =>
      setFieldValue(propName, [...defaultRates[propName].map(appendKeyToRate)])
    );

    await handleSubmit();
    setTimeout(() => {
      setCopyClientInProgress(false);
    }, 500);
  }, [closeDialog, defaultRates, handleSubmit, rateTypeKeys, setFieldValue]);

  const title = isLoading ? (
    <FormattedMessage id="rateCard.cardTitle" />
  ) : (
    <>
      <Icon classes={iconClasses}>
        <RateCardIcon />
      </Icon>
      <Typography variant="h6">
        <FormattedMessage id="rateCard.cardTitle" />
      </Typography>
    </>
  );

  if (isLoading) return <CardLoading avatar={avatar} title={title} />;

  return (
    <>
      <Card className={classes.root} data-qe-id="RateCard">
        <CardHeader title={title} classes={headerClasses} />
        <Divider variant="fullWidth" />
        <CardContent classes={cardClasses}>
          {copyClientInProgress ? (
            <>
              <Grid item xs={12} className={classes.progress}>
                <CircularProgress size={30} className={classes.spinner} />
                <Typography>
                  <FormattedMessage id="rateCard.copyingFromClient" />
                </Typography>
              </Grid>
              <Divider variant="fullWidth" />
            </>
          ) : (
            <>
              {rateTypeKeys.length
                ? rateTypeKeys.map(rateTypeKey =>
                    rates[rateTypeKey].length ? (
                      <RateCardEditable
                        key={rateTypeKey}
                        errors={errors}
                        rateTypeKey={rateTypeKey}
                        rateEditHandlers={rateEditHandlers}
                        editable={editable}
                        rates={rates[rateTypeKey]}
                        initialRates={initialRates[rateTypeKey]}
                        resetForm={resetForm}
                        validateForm={validateForm}
                        setFieldValue={setFieldValue}
                        hasMoreResourceRateRows={hasMoreResourceRateRows}
                        hasMoreRoleRateRows={hasMoreRoleRateRows}
                        loadMoreRows={loadMoreRows}
                        projectId={projectId}
                        projectSlug={projectSlug}
                        clientId={clientId}
                        context={context}
                      />
                    ) : null
                  )
                : null}
              {!hasRates && (
                <div className={noDataClasses.noData}>
                  <NoDataItem classes={noDataItemClasses}>
                    <FormattedMessage id={`rateCard.noRates.${context}`} />
                  </NoDataItem>
                </div>
              )}
              {!isPsaFpAdvancedRateCardEnabled &&
                editable &&
                !hasRates &&
                hasDefaultRates && (
                  <Button
                    onClick={openDialog}
                    className={classes.copyFromClientButton}
                  >
                    <FormattedMessage id="rateCard.copyFromClient" />
                  </Button>
                )}
            </>
          )}
          {!hasRates && <Divider variant="fullWidth" />}
          {editable && (
            <AddRateTypeButton
              onAddRateType={rateEditHandlers.onAddRateType}
              rates={rates}
            />
          )}
        </CardContent>
      </Card>
      {open ? (
        <RateCopyConfirmationDialog
          openDialog={open}
          onDialogClose={closeDialog}
          onConfirmClick={onCopyClick}
          defaultRates={defaultRates}
        />
      ) : null}
    </>
  );
};

RateCard.propTypes = {
  editable: PropTypes.bool,
  projectRate: PropTypes.array,
  roleRates: PropTypes.array,
  userRates: PropTypes.array,
  putRatesHandler: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      putProjectRates: PropTypes.func
    }),
    PropTypes.shape({
      putClientRates: PropTypes.func
    })
  ]),
  isLoading: PropTypes.bool,
  parentUri: PropTypes.string,
  defaultRates: PropTypes.object,
  hasMoreResourceRateRows: PropTypes.bool,
  hasMoreRoleRateRows: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  projectUri: PropTypes.string,
  projectSlug: PropTypes.string,
  clientUri: PropTypes.string,
  context: PropTypes.string
};

export default RateCard;
