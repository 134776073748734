import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const MAX_NOTIFICATIONS = 9;

export const TaskBeatIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12 1 C 10.7 1 9.599687500000002 1.84 9.1796875 3 L 3 3 L 3 21 L 21 21 L 21 3 L 14.8203125 3 C 14.4003125 1.84 13.3 1 12 1 z M 12 3 C 12.55 3 13 3.449999999999999 13 4 C 13 4.550000000000001 12.55 5 12 5 C 11.45 5 11 4.550000000000001 11 4 C 11 3.449999999999999 11.45 3 12 3 z M 11.66796875 6.91796875 L 13.361328125 12 L 17 12 L 17 13 L 14.02734375 13 L 13.333984375 15.08203125 L 11.666015625 10.080078125 L 9 18.080078125 L 7.638671875 14 L 5 14 L 5 13 L 8.361328125 13 L 9 14.91796875 L 11.66796875 6.91796875 z " />
    </>
  </SvgIcon>
));

export default TaskBeatIcon;
