import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DrawerHeader as HeaderBase } from '~/modules/common/components/Drawer';

const styles = theme => ({
  entityName: {
    color: theme.palette.text.secondary,
    marginBottom: '-8px',
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1.66
  },
  root: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  icon: {
    margin: theme.spacing(0, 2, 0, 0),
    color: theme.palette.text.secondary,
    width: 32,
    height: 32
  }
});

const drawerHeaderStyles = makeStyles(theme => ({
  headerArea: {
    position: 'sticky',
    top: 0,
    zIndex: 11,
    backgroundColor: 'inherit',
    color: 'inherit',
    padding: theme.spacing(1, 0, 1, 2),
    borderBottom: `1px solid ${theme.palette.table.border}`,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  }
}));
const typographyStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...theme.typography.h6
  }
}));

export const DrawerHeader = ({
  entityName,
  children,
  onClose,
  classes,
  TitleIcon,
  extraButtons
}) => {
  const typographyClasses = typographyStyles();
  const drawerheaderClasses = drawerHeaderStyles();

  return (
    <HeaderBase
      onClose={onClose}
      classes={drawerheaderClasses}
      extraButtons={extraButtons}
    >
      {Boolean(TitleIcon) && <TitleIcon className={classes.icon} />}
      <div className={classes.root}>
        <div className={classes.entityName}>{entityName}</div>
        <Typography variant="h1" classes={typographyClasses}>
          {children}
        </Typography>
      </div>
    </HeaderBase>
  );
};

DrawerHeader.propTypes = {
  classes: PropTypes.object,
  extraButtons: PropTypes.node,
  entityName: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  TitleIcon: PropTypes.any
};

export default withStyles(styles)(DrawerHeader);
