import { makeStyles } from '@material-ui/core';

export const useActionButtonStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.table.border}`,
    width: '100%',
    zIndex: '1000',
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1, 2, 1, 2)
  },
  saveButton: {
    marginRight: theme.spacing(1)
  }
}));
