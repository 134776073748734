import React, { useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { v4 } from 'uuid';
import { PropTypes } from 'prop-types';
import ListTablePropTypes from '../../PropTypes';
import RowRender from './RowRender';

const SkeletonRow = () => <Skeleton height={16} />;

const useSkeletonColumnAndRecordMemo = columns =>
  useMemo(() => {
    const keys = Object.keys(columns);

    const skeletonColumns = keys.reduce(
      (cols, key) => ({
        ...cols,
        [key]: {
          ...columns[key],
          render: SkeletonRow
        }
      }),
      {}
    );

    const skeletonRecord = keys.reduce(record => record, {});

    return {
      columns: skeletonColumns,
      record: skeletonRecord
    };
  }, [columns]);

const CellRender = ({ index, field, column, record, component: Component }) => (
  <Component index={index} field={field} column={column} record={record} />
);

CellRender.propTypes = {
  index: PropTypes.number,
  field: PropTypes.string,
  column: PropTypes.object,
  record: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export const SkeletonRows = ({
  classes,
  columns: listColumns,
  styles,
  useInlineStyles
}) => {
  const { columns, record: _record } = useSkeletonColumnAndRecordMemo(
    listColumns
  );

  const records = useMemo(
    () => [
      { ..._record, key: v4() },
      { ..._record, key: v4() },
      { ..._record, key: v4() }
    ],
    [_record]
  );

  return (
    <>
      {records.map((record, index) => (
        <RowRender
          key={record.key || record.id}
          record={record}
          classes={classes}
          columnStyles={styles}
          columns={columns}
          styles={styles}
          useInlineStyles={useInlineStyles}
          rowIndex={index + 1}
        />
      ))}
    </>
  );
};

SkeletonRows.propTypes = {
  classes: PropTypes.object,
  columns: ListTablePropTypes.columns,
  styles: PropTypes.object,
  useInlineStyles: PropTypes.bool
};

export default SkeletonRows;
