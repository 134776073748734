import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    height: theme.spacing(3),
    userSelect: 'none',
    zIndex: 2
  }
}));

export default useStyles;
