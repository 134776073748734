import React, { createContext, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSearchState } from '~/modules/common/components/SearchBox';
import { useURLSearchParams } from '~/modules/common/hooks';
import { roleTag } from '~/modules/common/components/SearchBox/Facets/RoleFacet/tag';
import { costCenterTag } from '~/modules/common/components/SearchBox/Facets/CostCenterFacet/tag';
import { projectTag } from '~/modules/common/components/SearchBox/Facets/ProjectFacet/tag';
import { departmentTag } from '~/modules/common/components/SearchBox/Facets/DepartmentFacet/tag';
import { divisionTag } from '~/modules/common/components/SearchBox/Facets/DivisionFacet/tag';
import { employeeTypeTag } from '~/modules/common/components/SearchBox/Facets/EmployeeTypeFacet/tag';
import { locationTag } from '~/modules/common/components/SearchBox/Facets/LocationFacet/tag';
import { serviceCenterTag } from '~/modules/common/components/SearchBox/Facets/ServiceCenterFacet/tag';
import { skillTag } from '~/modules/common/components/SearchBox/Facets/SkillFacet/tag';

export const ProjectResourcingContext = createContext();

export const mapToField = obj => ({
  id: obj.value,
  value: obj.value,
  key: obj.label,
  label: obj.label
});

export const getValue = (obj, previousCriterion) => {
  return obj && obj.label && obj.value ? [mapToField(obj)] : previousCriterion;
};

export const ProjectResourcingContextProvider = props => {
  const queryParams = useURLSearchParams();
  const { children } = props;
  const [resourcePoolSearchCriteria] = useSearchState('resource-pool');

  const [searchCriteria, setSearchCriteria] = useSearchState(
    'project-resourcing'
  );

  const roleValue = queryParams.get('roles');
  const locationValue = queryParams.get('locations');
  const divisionValue = queryParams.get('divisions');
  const serviceCenterValue = queryParams.get('serviceCenters');
  const costCenterValue = queryParams.get('costCenters');
  const departmentValue = queryParams.get('departments');
  const employeeTypeValue = queryParams.get('employeeTypes');
  const projectValue = queryParams.get('projects');
  const skillValue = queryParams.get('skills');

  const role = searchCriteria.criterions[roleTag] || [];
  const location = searchCriteria.criterions[locationTag] || [];
  const division = searchCriteria.criterions[divisionTag] || [];
  const serviceCenter = searchCriteria.criterions[serviceCenterTag] || [];
  const costCenter = searchCriteria.criterions[costCenterTag] || [];
  const department = searchCriteria.criterions[departmentTag] || [];
  const employeeType = searchCriteria.criterions[employeeTypeTag] || [];
  const project = searchCriteria.criterions[projectTag] || [];
  const skills = searchCriteria.criterions[skillTag] || [];

  const rpRole = resourcePoolSearchCriteria.criterions[roleTag] || [];
  const rpLocation = resourcePoolSearchCriteria.criterions[locationTag] || [];
  const rpDivision = resourcePoolSearchCriteria.criterions[divisionTag] || [];
  const rpServiceCenter =
    resourcePoolSearchCriteria.criterions[serviceCenterTag] || [];
  const rpCostCenter =
    resourcePoolSearchCriteria.criterions[costCenterTag] || [];
  const rpDepartment =
    resourcePoolSearchCriteria.criterions[departmentTag] || [];
  const rpEmployeeType =
    resourcePoolSearchCriteria.criterions[employeeTypeTag] || [];
  const rpProject = resourcePoolSearchCriteria.criterions[projectTag] || [];
  const rpSkills = resourcePoolSearchCriteria.criterions[skillTag] || [];

  useEffect(() => {
    if (setSearchCriteria) {
      const searchValue = {
        ...searchCriteria,
        criterions: {
          ...searchCriteria.criterions,
          [roleTag]: roleValue ? rpRole : role,
          [projectTag]: projectValue ? rpProject : project,
          [locationTag]: locationValue ? rpLocation : location,
          [divisionTag]: divisionValue ? rpDivision : division,
          [serviceCenterTag]: serviceCenterValue
            ? rpServiceCenter
            : serviceCenter,
          [costCenterTag]: costCenterValue ? rpCostCenter : costCenter,
          [departmentTag]: departmentValue ? rpDepartment : department,
          [employeeTypeTag]: employeeTypeValue ? rpEmployeeType : employeeType,
          [skillTag]: skillValue ? rpSkills : skills
        },
        __typename: undefined
      };

      setSearchCriteria(searchValue);
    }
  }, [
    queryParams,
    roleValue,
    projectValue,
    locationValue,
    divisionValue,
    serviceCenterValue,
    costCenterValue,
    employeeTypeValue,
    skillValue
  ]);

  const values = useMemo(
    () => ({
      searchCriteria,
      setSearchCriteria,
      ...props
    }),
    [props, searchCriteria, setSearchCriteria]
  );

  return (
    <ProjectResourcingContext.Provider value={values}>
      {children}
    </ProjectResourcingContext.Provider>
  );
};

ProjectResourcingContextProvider.propTypes = {
  children: PropTypes.any
};

export const useProjectResourcingContext = () =>
  useContext(ProjectResourcingContext);
export default ProjectResourcingContext;
