import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  ContactFieldReadOnly,
  ContactTypeReadOnly
} from '../columns/formatters/index';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2.5),
    paddingTop: index => (index !== 0 ? theme.spacing(1.5) : theme.spacing(0)),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(0.5)
  },
  contactField: {
    padding: theme.spacing(0.5),
    width: '50%'
  }
}));

const ReadOnlyMobileContactInfoListItem = ({ record, rowIndex: index }) => {
  const classes = useStyles({ index });

  return (
    <Grid className={classes.container}>
      <Grid className={classes.column}>
        <Grid className={classes.row}>
          <Grid className={classes.contactField}>
            <ContactFieldReadOnly field="name" record={record} />
          </Grid>
          <Grid className={classes.contactField}>
            <ContactFieldReadOnly field="email" record={record} />
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.contactField}>
            <ContactFieldReadOnly field="phoneNumber" record={record} />
          </Grid>
          <Grid className={classes.contactField}>
            <ContactFieldReadOnly field="faxNumber" record={record} />
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.contactField}>
            <ContactFieldReadOnly field="website" record={record} />
          </Grid>
          <Grid className={classes.contactField}>
            <ContactTypeReadOnly field="contactType" record={record} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ReadOnlyMobileContactInfoListItem.propTypes = {
  record: PropTypes.object.isRequired,
  rowIndex: PropTypes.number
};

export default ReadOnlyMobileContactInfoListItem;
