/* eslint-disable react/jsx-max-depth */
import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import SkillsSelectDropdown from '~/modules/resourcing/common/components/Skills/SkillsSelectDropdown';
import MobileSkillRow from './MobileSkillRow';
import MobileSkillCategory from './MobileSkillCategory';

const useStyles = makeStyles(theme => ({
  skillRows: {
    margin: theme.spacing(1, 1.75, 0)
  },
  skillOptions: {
    margin: theme.spacing(0, 3, 2)
  },
  divider: {
    margin: theme.spacing(1.5, -3, 0)
  },
  skillCategory: {
    display: 'flex',
    padding: theme.spacing(0.5, 3),
    backgroundColor: theme.palette.grey[100]
  },
  noSkill: {
    padding: theme.spacing(1, 3)
  },
  assignSkillMessage: {
    paddingTop: theme.spacing(1)
  }
}));

export const MobileSkillsEditable = ({
  classes: classesOverride,
  onSkillsSelected,
  onCategoryRemove,
  userDetails,
  maxSkillLevel,
  certificates,
  onSkillAdd,
  skillSetting,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    values: { categories, skills }
  } = useFormikContext();

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Grid item xs={12} className={classes.skillOptions}>
          <SkillsSelectDropdown
            value={skills.map(u => ({
              ...u,
              value: u.uri,
              label: u.displayText
            }))}
            onChange={onSkillsSelected}
            onSkillAdd={onSkillAdd}
            classes={classes}
          />
        </Grid>
        {skills.length > 0 ? (
          <Grid item xs={12}>
            {categories.map(category => (
              <Fragment key={category.uri}>
                <Grid item className={classes.skillCategory} xs={12}>
                  <MobileSkillCategory
                    category={category}
                    onCategoryRemove={onCategoryRemove}
                  />
                </Grid>
                <Grid item className={classes.skillRows} xs={12}>
                  {skills
                    .filter(skill => skill.category.uri === category.uri)
                    .map(skill => (
                      <div key={skill.uri}>
                        <MobileSkillRow
                          skill={skill}
                          certificates={certificates}
                          userDetails={userDetails}
                          skillSetting={skillSetting}
                          maxSkillLevel={maxSkillLevel}
                          {...rest}
                        />
                        <Divider
                          variant="fullWidth"
                          className={classes.divider}
                        />
                      </div>
                    ))}
                </Grid>
              </Fragment>
            ))}
          </Grid>
        ) : (
          <>
            <div className={classes.noSkill}>
              <div>
                <FormattedMessage id="skillInfoCard.noSkillsAssignedMessage" />
              </div>
              <div className={classes.assignSkillMessage}>
                <FormattedMessage id="skillInfoCard.assignSkillMessage" />
              </div>
            </div>
          </>
        )}
      </Grid>
    </>
  );
};

MobileSkillsEditable.propTypes = {
  classes: PropTypes.object,
  onSkillsSelected: PropTypes.func,
  onSkillRemove: PropTypes.func,
  onCategoryRemove: PropTypes.func,
  certificates: PropTypes.array,
  userDetails: PropTypes.object,
  skillSetting: PropTypes.array,
  onSkillAdd: PropTypes.func,
  maxSkillLevel: PropTypes.number
};

export default MobileSkillsEditable;
