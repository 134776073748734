import React, { useMemo, useState } from 'react';
import { useSearchableUsers } from './useUsers';

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const mapOptions = users =>
  users.map(({ displayText, id }) => ({ key: displayText, value: id }));

export const matchOption = searchUsers => async (_, term) => {
  if (!term) {
    return null;
  }

  const users = await searchUsers(cleanSearchTerm(term));

  const option = users.shift();

  if (option) {
    return {
      ...option,
      key: option.displayText
    };
  }

  return null;
};

const withCriteriaProvider = (tag, context) => BaseComponent => ({
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);
  const { searchUsers } = useSearchableUsers();
  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption(searchUsers),
      selected,
      setSelected,
      suggestions: searchUsers
    }),
    [searchUsers, selected, setSelected]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

const makeCriteriaProvider = (tag, context) =>
  withCriteriaProvider(tag, context);

export default makeCriteriaProvider;
