import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import { BACKGROUND_JOB_STATUS } from '~/modules/common/enums';
import useRescheduleProjectSubscription from './useRescheduleProjectSubscription';

export const useRescheduleProject = ({ projectId, rescheduleProjectJob }) => {
  const {
    id: rescheduleProjectJobID,
    status,
    result: { projectId: rescheduleProjectId } = {}
  } = useRescheduleProjectSubscription();

  const isRescheduleProjectJobRunningInBackground =
    rescheduleProjectId === projectId &&
    status === BACKGROUND_JOB_STATUS.IN_PROGRESS;
  const client = useApolloClient();

  const resetGraphQlStore =
    rescheduleProjectJobID &&
    rescheduleProjectId === projectId &&
    (status === BACKGROUND_JOB_STATUS.FAILED ||
      status === BACKGROUND_JOB_STATUS.SUCCEEDED);

  const { jobId, projectId: scheduledProjectId } = rescheduleProjectJob || {};

  const isJobIDPresentFromSubscription =
    rescheduleProjectJobID !== undefined && rescheduleProjectJobID !== null;

  return useMemo(
    () => ({
      client,
      rescheduledProjectId: rescheduleProjectId || scheduledProjectId,
      resetGraphQlStore,
      rescheduleProjectJobID: isJobIDPresentFromSubscription
        ? rescheduleProjectJobID
        : jobId,
      isRescheduleProjectJobRunningInBackground: isJobIDPresentFromSubscription
        ? isRescheduleProjectJobRunningInBackground
        : scheduledProjectId === projectId
    }),
    [
      jobId,
      client,
      projectId,
      resetGraphQlStore,
      scheduledProjectId,
      rescheduleProjectId,
      rescheduleProjectJobID,
      isJobIDPresentFromSubscription,
      isRescheduleProjectJobRunningInBackground
    ]
  );
};
