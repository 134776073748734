import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContentText } from '@material-ui/core';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { useIsBreakpointDown, useDialogState } from '~/modules/common/hooks';
import { MoneyValue } from '~/modules/common/components/Money';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { useMeContext } from '~/modules/me';
import { useProjectRates } from '../hooks/useProjectRates';
import { useClientRates } from '../hooks/useClientRates';
import { useRateCardEditable } from '../hooks/useRateCardEditable';
import { useModifyRates, useRemoveAllEntries } from '../hooks';
import { RateCardActionComponent } from './RateCardActionComponent';
import { RateCardEditor } from './RateCardEditor';
import RateCardReadOnly from './RateCardReadOnly';

const useCardContentStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  card: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  content: {
    padding: theme.spacing(2)
  }
}));

const useStyles = makeStyles(theme => ({
  subTitleContainer: {
    width: theme.spacing(12),
    textAlign: 'left'
  }
}));

const deleteDialogResourceKeys = rateTypeKey => ({
  header: `rateCard.deleteAllConfirmationDialog.header.${rateTypeKey}`,
  actionButton: 'rateCard.deleteAllConfirmationDialog.removeRate'
});

export const RateCardEditable = ({
  rates,
  initialRates,
  resetForm,
  editable,
  rateTypeKey,
  validateForm,
  rateEditHandlers,
  errors,
  setFieldValue,
  hasMoreResourceRateRows,
  hasMoreRoleRateRows,
  loadMoreRows,
  projectId,
  projectSlug,
  clientId,
  context,
  showRateCard,
  setRatesModifier
}) => {
  const me = useMeContext();
  const { open: dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const isMobile = useIsBreakpointDown('sm');
  const cardStyles = useCardContentStyles();
  const classes = useStyles();
  const saveable = useMemo(() => Object.keys(errors).length === 0, [errors]);

  const project = useProjectRates({
    projectId,
    context,
    rateTypeKey,
    me
  });

  const client = useClientRates({
    context,
    clientId,
    rateTypeKey,
    me
  });

  const {
    rowCount,
    refetch,
    readOnlyRates,
    hasMoreRows,
    loadMoreReadOnlyRates,
    loading
  } = useRateCardEditable({ project, client, context });

  const { modifyRates } = useModifyRates({
    context,
    projectId,
    initialRates,
    rates,
    rateTypeKey,
    clientId,
    resetForm,
    setRatesModifier
  });

  const { onRemoveAllEntries } = useRemoveAllEntries({
    context,
    projectId,
    rateTypeKey,
    clientId,
    resetForm
  });

  const title = useMemo(
    () => (
      <FormattedMessage id={`rateCard.title.${rateTypeKey}`}></FormattedMessage>
    ),
    [rateTypeKey]
  );
  const count = useMemo(() => ({ count: rowCount }), [rowCount]);
  const subTitle = useMemo(() => {
    if (rateTypeKey !== 'projectRate')
      return (
        <div className={classes.subTitleContainer}>
          {loading || !rowCount ? null : (
            <FormattedMessage
              id={`rateCard.subTitle.${rateTypeKey}`}
              values={count}
            />
          )}
        </div>
      );

    const { billingScheduleEntries = [] } = rates[0] || {};

    const { amount } =
      billingScheduleEntries.find(e => e.isEffectiveAsOfToday) || {};

    return amount ? (
      <div className={classes.subTitleContainer}>
        <MoneyValue money={amount} />
      </div>
    ) : null;
  }, [classes.subTitleContainer, count, loading, rateTypeKey, rates, rowCount]);

  const isAddMode = rates.some(
    x =>
      x.billingScheduleEntries &&
      x.billingScheduleEntries.some(entry => entry.isAddMode)
  );

  const actionComponent = useMemo(() => {
    return (
      rates.some(
        x => x.billingScheduleEntries && x.billingScheduleEntries.length > 0
      ) && RateCardActionComponent
    );
  }, [rates]);

  const onChange = useCallback(
    (_, expanded) => {
      if (!expanded && refetch) {
        refetch();
      }
    },
    [refetch]
  );

  return (
    <>
      <EditableCard
        editable={editable}
        className={cardStyles.card}
        editContentClassName={cardStyles.root}
        classes={cardStyles}
        edit={isAddMode}
        fullScreen={isMobile}
        ariaLabelKey="rateCard.rateCardDialog"
      >
        {!isAddMode && !showRateCard && (
          <ReadOnly
            title={title}
            subTitle={subTitle}
            expandable
            onChange={onChange}
          >
            <ReadOnlyContent>
              <RateCardReadOnly
                rates={readOnlyRates}
                rateTypeKey={rateTypeKey}
                hasMoreRows={hasMoreRows}
                loadMoreRows={loadMoreReadOnlyRates}
              />
            </ReadOnlyContent>
          </ReadOnly>
        )}
        <Edit
          saveable={saveable}
          onSave={modifyRates}
          validateForm={validateForm}
          onCancel={resetForm}
          onRemove={openDialog}
          actionLabel={rateTypeKey}
          ActionComponent={actionComponent}
        >
          <EditTitle>
            <FormattedMessage id={`rateCard.rateType.${rateTypeKey}`} />
          </EditTitle>
          <EditContent className={cardStyles.content}>
            <RateCardEditor
              rateTypeKey={rateTypeKey}
              rates={rates}
              rateEditHandlers={rateEditHandlers}
              errors={errors}
              setFieldValue={setFieldValue}
              hasMoreResourceRateRows={hasMoreResourceRateRows}
              hasMoreRoleRateRows={hasMoreRoleRateRows}
              loadMoreRows={loadMoreRows}
              projectId={projectId}
              projectSlug={projectSlug}
              context={context}
            />
          </EditContent>
        </Edit>
      </EditableCard>
      {dialogOpen && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys(rateTypeKey)}
          open={dialogOpen}
          onClose={closeDialog}
          onConfirm={onRemoveAllEntries}
        >
          <DialogContentText>
            <FormattedMessage
              id={`rateCard.deleteAllConfirmationDialog.message.${rateTypeKey}`}
            />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

RateCardEditable.propTypes = {
  rates: PropTypes.array,
  initialRates: PropTypes.array,
  resetForm: PropTypes.func,
  editable: PropTypes.bool,
  rateTypeKey: PropTypes.string,
  validateForm: PropTypes.func,
  rateEditHandlers: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  hasMoreResourceRateRows: PropTypes.bool,
  hasMoreRoleRateRows: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  projectId: PropTypes.string,
  clientId: PropTypes.string,
  context: PropTypes.string,
  showRateCard: PropTypes.bool,
  setRatesModifier: PropTypes.func,
  projectSlug: PropTypes.string
};

export default RateCardEditable;
