import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ALLOCATION_PERIOD_OVERLAP_TYPE } from '../../enums';
import { ResourceRequestUserAllocationPeriodOverlay } from './components';

export const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
    display: 'flex',
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    '&:hover': {
      '& .dragIndicator': {
        opacity: 1
      }
    },
    position: 'relative'
  }
}));

const useOverlayStyles = makeStyles(() => ({
  overlay: {
    cursor: 'pointer'
  }
}));

export const TaskAllocationTimelineOverlayBlocks = ({
  chartDisplayPeriods,
  handleAllocationPeriodClick,
  isEditable,
  scale,
  allocation
}) => {
  const classes = useStyles();
  const overlayClasses = useOverlayStyles();

  return (
    <div className={`${classes.root} resourceRequestUserAllocation`}>
      {chartDisplayPeriods.map(
        ({ key, startDate, endDate, dynamicPosition }) => (
          <ResourceRequestUserAllocationPeriodOverlay
            key={key}
            requestPeriods={chartDisplayPeriods}
            startDate={startDate}
            endDate={endDate}
            allocation={allocation}
            scale={scale}
            isEditable={isEditable}
            dynamicPosition={dynamicPosition}
            handleAllocationPeriodClick={handleAllocationPeriodClick}
            classes={overlayClasses}
            allocationPeriodOverlapType={ALLOCATION_PERIOD_OVERLAP_TYPE.NONE}
          />
        )
      )}
    </div>
  );
};

TaskAllocationTimelineOverlayBlocks.propTypes = {
  scale: PropTypes.string.isRequired,
  allocation: PropTypes.object,
  handleAllocationPeriodClick: PropTypes.func,
  isEditable: PropTypes.bool,
  chartDisplayPeriods: PropTypes.array
};

export default TaskAllocationTimelineOverlayBlocks;
