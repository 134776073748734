import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';
import {
  TaskVariationPill,
  TaskVariationPillContent
} from '~/modules/common/components/TaskVariationPill';
import { useStyles as usePillStyles } from '~/modules/common/components/TaskVariationPill/hooks';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.25),
    borderRadius: theme.spacing(0.4),
    padding: theme.spacing(0, 0.4),
    marginLeft: theme.spacing(0.75),
    marginTop: theme.spacing(-0.4),
    backgroundColor: '#ebebeb',
    color: '#656565',
    pointerEvents: 'none'
  },
  labelContainer: {
    display: 'block'
  },
  label: {
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
    fontWeight: 600
  },
  variationIcon: {
    fontSize: theme.spacing(2.3),
    marginRight: theme.spacing(0.25),
    fill: theme.palette.taskRollUp.missing.main
  }
});

const usePillStylesOverride = makeStyles({
  variationContainer: ({ isPsaPpmCostEacEnhancements2Enabled }) => ({
    pointerEvents: 'none',
    ...(isPsaPpmCostEacEnhancements2Enabled && { marginRight: 0 })
  }),
  variationPill: ({ isRTL }) => ({
    paddingTop: theme.spacing(0.25),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.4),
    padding: isRTL
      ? theme.spacing(0.25, 0.4, 0, 1)
      : theme.spacing(0.25, 0.4, 0, 0.4),
    marginLeft: !isRTL ? theme.spacing(0.75) : 0,
    marginRight: isRTL ? theme.spacing(1.75) : 0,
    marginTop: theme.spacing(-0.4)
  }),
  variationIcon: ({ variationStatus, isRTL }) => ({
    fontSize: theme.spacing(2.3),
    marginRight:
      !isRTL && variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MATCHING
        ? theme.spacing(0.75)
        : 0,
    marginLeft:
      isRTL && variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MATCHING
        ? theme.spacing(0.75)
        : 0
  }),
  variationValue: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600
  },
  variationText: {
    display: 'none'
  },
  variationLabel: ({ isRTL }) => ({
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
    marginRight: !isRTL ? theme.spacing(0.5) : 0,
    whiteSpace: 'pre'
  }),
  MATCHING: {},
  UNDER: {},
  OVER: {},
  MISSING_ESTIMATE: {}
});

export const VariationPillbox = ({
  intl,
  variationStatus,
  variationValue,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  variant,
  pillClasses: pillClassesOverride,
  classes: classesOverride,
  isPsaPpmCostEacEnhancements2Enabled,
  numberFormat,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles({ classes: classesOverride });
  const pillStylesOverride = usePillStylesOverride({
    isRTL,
    classes: pillClassesOverride,
    isPsaPpmCostEacEnhancements2Enabled
  });
  const pillStyles = usePillStyles({
    classes: pillStylesOverride,
    isPsaPpmCostEacEnhancementsEnabled
  });

  return variationStatus ===
    ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE ? (
    <div className={classes.container}>
      <ReportProblemOutlined
        className={classNames(classes.variationIcon, classes.MISSING_ESTIMATE)}
      />
      <div className={classes.labelContainer}>
        <span className={classes.label}>
          {intl.formatMessage({
            id: 'projectTasksPage.estimateMissing'
          })}
        </span>
      </div>
    </div>
  ) : isPsaPpmCostEacEnhancements2Enabled ? (
    <TaskVariationPillContent
      intl={intl}
      classes={pillStyles}
      variationStatus={variationStatus}
      fixedDecimalScale={false}
      value={variationValue}
      variant={variant}
      isPsaPpmCostEacEnhancements2Enabled={isPsaPpmCostEacEnhancements2Enabled}
      numberFormat={numberFormat}
      isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
    />
  ) : (
    <TaskVariationPill
      intl={intl}
      classes={pillStyles}
      variationStatus={variationStatus}
      fixedDecimalScale={false}
      value={variationValue}
      variant={variant}
    />
  );
};

VariationPillbox.propTypes = {
  intl: PropTypes.object.isRequired,
  isRTL: PropTypes.bool.isRequired,
  variationStatus: PropTypes.oneOf([
    ESTIMATED_HOURS_VARIATION_STATUS.MATCHING,
    ESTIMATED_HOURS_VARIATION_STATUS.OVER,
    ESTIMATED_HOURS_VARIATION_STATUS.UNDER,
    ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE
  ]),
  variationValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  variant: PropTypes.string,
  pillClasses: PropTypes.object,
  classes: PropTypes.object,
  numberFormat: PropTypes.object,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default VariationPillbox;
