import React, { useCallback } from 'react';
import { Switch, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-checked': {
      color: 'black',
      '& + .MuiSwitch-track': {
        backgroundColor: 'black'
      }
    }
  }
}));

const useContainerStyles = makeStyles(theme => ({
  container: {
    marginRight: theme.spacing(2),
    display: 'flex',
    border: `1px solid ${theme.palette.grey[400]}`,
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5)
  }
}));

const ResourcingActualToggleButton = ({
  isResourceActualModeEnabled,
  setIsResourceActualModeEnabled
}) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const { formatMessage } = useIntl();

  const handleChange = useCallback(
    () => setIsResourceActualModeEnabled(!isResourceActualModeEnabled),
    [isResourceActualModeEnabled, setIsResourceActualModeEnabled]
  );

  return (
    <div className={containerClasses.container}>
      <Switch
        classes={classes}
        size="small"
        checked={isResourceActualModeEnabled}
        onChange={handleChange}
        inputProps={{
          'aria-label': formatMessage({ id: 'resourceRequestToolbar.actuals' })
        }}
        data-qe-id="ActualsToggle"
      />
      <Typography variant="body2" color="textSecondary">
        <FormattedMessage id="resourceRequestToolbar.actuals" />
      </Typography>
    </div>
  );
};

ResourcingActualToggleButton.propTypes = {
  isResourceActualModeEnabled: PropTypes.bool.isRequired,
  setIsResourceActualModeEnabled: PropTypes.func.isRequired
};

export default ResourcingActualToggleButton;
