import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    lineHeight: '14px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary
  },
  diffPositive: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark
  },
  diffNegative: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffWarning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  },
  diffInfo: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark
  },
  icon: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(-0.125, 0.25, -0.125, 0),
    paddingTop: theme.spacing(0.25)
  },
  hourFont: {
    fontWeight: 'bold'
  }
}));

export default useStyles;
