import { useState, useCallback } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';

const getOverlay = ({
  periodDetails,
  anchorEl,
  moveRight,
  allocatedValue,
  chartDates,
  setAllocationPeriodEditTarget,
  getPeriod,
  allocation
}) => {
  const findIndex = chartDates.findIndex(chartDate => {
    return (
      periodDetails.startDate >= chartDate.start &&
      periodDetails.startDate <= chartDate.end
    );
  });
  const { isOverlay, overlayPeriods } = periodDetails;

  let overlay;

  if (!isOverlay) {
    const { parentElement } = anchorEl;

    const parentDiv = parentElement.closest('.resourceRequestUserAllocation');
    const allocationChildren = parentDiv.getElementsByClassName(
      'resourceRequestAllocationOverlay'
    );

    overlay = allocationChildren[findIndex];

    const dateRange = getDateRangeFromScheduleRules(
      periodDetails.resourceAllocation.scheduleRules
    );

    const requestStartDate = mapIsoStringtoUtcObject(dateRange.startDate);
    const requestEndDate = mapIsoStringtoUtcObject(dateRange.endDate);

    if (
      moveRight &&
      mapIsoStringtoUtcObject(requestEndDate) > periodDetails.startDate &&
      mapIsoStringtoUtcObject(requestEndDate) < periodDetails.endDate
    ) {
      getPeriod(
        periodDetails,
        allocation,
        overlay,
        allocatedValue,
        0,
        overlayPeriods,
        true
      );

      return { overlay, shouldReturn: true };
    }

    // Handle Partial Periods
    if (
      !moveRight &&
      mapIsoStringtoUtcObject(requestEndDate) > periodDetails.startDate &&
      mapIsoStringtoUtcObject(requestEndDate) < periodDetails.endDate
    ) {
      getPeriod(
        periodDetails,
        allocation,
        overlay.previousSibling,
        allocatedValue,
        -1,
        overlayPeriods,
        true
      );

      return { overlay, shouldReturn: true };
    }

    if (
      !moveRight &&
      mapIsoStringtoUtcObject(requestStartDate) > periodDetails.startDate &&
      mapIsoStringtoUtcObject(requestStartDate) < periodDetails.endDate
    ) {
      getPeriod(
        periodDetails,
        allocation,
        overlay,
        allocatedValue,
        0,
        overlayPeriods,
        true
      );

      return { overlay, shouldReturn: true };
    }

    if (
      moveRight &&
      mapIsoStringtoUtcObject(requestStartDate) > periodDetails.startDate &&
      mapIsoStringtoUtcObject(requestStartDate) < periodDetails.endDate
    ) {
      getPeriod(
        periodDetails,
        allocation,
        overlay.nextSibling,
        allocatedValue,
        1,
        overlayPeriods,
        true
      );

      return { overlay, shouldReturn: true };
    }

    // Handle other overlay cases except partial periods
    if (moveRight && overlay.nextSibling) {
      getPeriod(
        periodDetails,
        allocation,
        overlay.nextSibling,
        allocatedValue,
        +1,
        overlayPeriods
      );

      return { overlay, shouldReturn: true };
    }

    if (!moveRight && overlay.previousSibling) {
      getPeriod(
        periodDetails,
        allocation,
        overlay.previousSibling,
        allocatedValue,
        -1,
        overlayPeriods
      );

      return { overlay, shouldReturn: true };
    }

    return setAllocationPeriodEditTarget(null);
  }

  return { overlay, shouldReturn: false };
};

const usePeriodEditorChangeHandlers = ({
  onPeriodClick,
  chartDates,
  resourceRequest,
  allocationPeriodEditTarget,
  setAllocationPeriodEditTarget
}) => {
  const [allRequestPeriods, setAllRequestPeriods] = useState(null);

  const handleAllocationPeriodClick = useCallback(
    ({
      event,
      startDate,
      endDate,
      allocation,
      totalAllocatedHours,
      resourceAvailabilitySummary,
      totalScheduledHours,
      isOverlay,
      overlayPeriods,
      requestPeriods,
      ...rest
    }) => {
      if (event.currentTarget) {
        setAllRequestPeriods(requestPeriods);

        setAllocationPeriodEditTarget({
          anchorEl: event.currentTarget,
          resourceAllocation: allocation,
          startDate,
          isOverlay,
          overlayPeriods,
          scheduledHours: totalScheduledHours,
          endDate,
          allocatedHours: totalAllocatedHours,
          resourceAvailabilitySummary,
          requestPeriods,
          ...rest
        });

        if (onPeriodClick) {
          onPeriodClick({
            event,
            allocation,
            startDate,
            endDate,
            resourceRequest,
            isOverlay,
            overlayPeriods,
            totalAllocatedHours,
            totalScheduledHours,
            resourceAvailabilitySummary,
            requestPeriods,
            ...rest
          });
        }
      }
    },
    [onPeriodClick, resourceRequest, setAllocationPeriodEditTarget]
  );

  const handleAllocationPeriodClose = useCallback(
    evt => {
      setAllocationPeriodEditTarget(null);
    },
    [setAllocationPeriodEditTarget]
  );

  const newPeriod = (periodDetails, newAnchor, newSummary, allocation) => ({
    ...periodDetails,
    isOverlay: true,
    anchorEl: newAnchor,
    startDate: newSummary.startDate,
    endDate: newSummary.endDate,
    allocatedHours: newSummary.totalHours || 0,
    scheduledHours: newSummary.totalScheduleHours,
    resourceAllocation: allocation
  });

  const getPeriod = useCallback(
    (
      periodDetails,
      allocation,
      sibling,
      allocatedValue,
      addSubtract,
      overridePeriods,
      updateIsOverlay
    ) => {
      const requestPeriods = overridePeriods || allRequestPeriods;
      const currIndex = requestPeriods.findIndex(
        obj =>
          obj.startDate <= allocationPeriodEditTarget.startDate &&
          obj.endDate >= allocationPeriodEditTarget.endDate
      );

      const newIndex = currIndex + addSubtract;

      if (newIndex < 0 || newIndex > requestPeriods.length - 1) {
        const updatedRequestPeriods = [...requestPeriods];

        updatedRequestPeriods[currIndex].totalHours = allocatedValue;

        setAllRequestPeriods(updatedRequestPeriods);
        setAllocationPeriodEditTarget(
          newPeriod(
            periodDetails,
            null,
            updatedRequestPeriods[currIndex],
            allocation
          )
        );
      } else {
        const newSummary = requestPeriods[newIndex];

        const newAnchor = sibling || null;

        setAllocationPeriodEditTarget(
          newPeriod(
            updateIsOverlay
              ? { ...periodDetails, isOverlay: true }
              : periodDetails,
            newAnchor,
            newSummary,
            allocation
          )
        );

        const updatedRequestPeriods = [...requestPeriods];

        updatedRequestPeriods[currIndex].totalHours = allocatedValue;
        setAllRequestPeriods(requestPeriods);
      }
    },
    [
      allRequestPeriods,
      allocationPeriodEditTarget,
      setAllocationPeriodEditTarget
    ]
  );

  const setNextPeriod = useCallback(
    (periodDetails, allocation, allocatedValue) => {
      const { anchorEl } = allocationPeriodEditTarget;
      const { overlay, shouldReturn } =
        getOverlay({
          periodDetails,
          anchorEl,
          allocation,
          allocatedValue,
          chartDates,
          getPeriod,
          moveRight: true,
          setAllocationPeriodEditTarget
        }) || {};

      if (shouldReturn) {
        return;
      }

      const { nextSibling } = overlay || anchorEl;

      getPeriod(periodDetails, allocation, nextSibling, allocatedValue, +1);
    },
    [
      allocationPeriodEditTarget,
      chartDates,
      getPeriod,
      setAllocationPeriodEditTarget
    ]
  );

  const setPreviousPeriod = useCallback(
    (periodDetails, allocation, allocatedValue, event) => {
      const { anchorEl } = allocationPeriodEditTarget;

      const { overlay, shouldReturn } =
        getOverlay({
          periodDetails,
          anchorEl,
          moveRight: false,
          getPeriod,
          allocation,
          allocatedValue,
          setAllocationPeriodEditTarget,
          chartDates
        }) || {};

      if (shouldReturn) {
        return;
      }

      const { previousSibling } = overlay || anchorEl;

      getPeriod(periodDetails, allocation, previousSibling, allocatedValue, -1);
    },
    [
      allocationPeriodEditTarget,
      chartDates,
      getPeriod,
      setAllocationPeriodEditTarget
    ]
  );

  return {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  };
};

export default usePeriodEditorChangeHandlers;
