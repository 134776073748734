import { PROJECT_STATUS_FILTER } from '~/modules/quickAllocation/components/ProjectStatusFilter/enum';
import { ProjectWorkflowStage } from '~/types';

export const mapProjectStatusToProjectWorkflowStages = projectStatusFilterOption => {
  if (
    projectStatusFilterOption.option === PROJECT_STATUS_FILTER.CUSTOM_STATUS
  ) {
    return projectStatusFilterOption.statuses;
  }

  return [
    ProjectWorkflowStage.Initiate,
    ProjectWorkflowStage.Planning,
    ProjectWorkflowStage.Execution,
    ProjectWorkflowStage.Closeout,
    ...(projectStatusFilterOption.option !==
    PROJECT_STATUS_FILTER.UNARCHIVED_PROJECTS
      ? [ProjectWorkflowStage.Archived]
      : [])
  ];
};
