import { gql } from 'graphql-tag';
import specificResourceAllocationUserFragment from './specificResourceAllocationUserFragment';

const specificResourceAllocationFragment = gql`
  fragment SpecificResourceAllocation on ResourceAllocation {
    id
    projectUri
    resourceRequestId
    user {
      ...SpecificResourceAllocationUser
    }
    allocationStatus
    totalHours
    scheduleRules {
      dateRange {
        startDate
        endDate
      }
      do
    }
    startDate
    endDate
    load
    isAdjustedLoading
    requestedRoleUri
    exchangeRateValues {
      projectBudgetCurrency {
        currencyId
        rate
      }
      baseCurrency {
        currencyId
        rate
      }
      resourceRequestCurrency {
        currencyId
        rate
      }
    }
    roleCostExchangeRateValues {
      projectBudgetCurrency {
        currencyId
        rate
      }
      baseCurrency {
        currencyId
        rate
      }
      resourceRequestCurrency {
        currencyId
        rate
      }
    }
  }
  ${specificResourceAllocationUserFragment}
`;

export default specificResourceAllocationFragment;
