import { gql } from 'graphql-tag';

export const specificTaskResourceUserAllocationFragment = gql`
  fragment SpecificTaskResourceUserAllocation on TaskResourceUserAllocation {
    id
    taskUri
    projectUri
    allocationUserUri
    roleUri
    scheduleRules {
      dateRange {
        startDate
        endDate
      }
      do
    }
    totalHours
    taskResourceEstimateUri
    resourceAllocationUri
    startDate
    endDate
  }
`;

export default specificTaskResourceUserAllocationFragment;
