import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  level0: {
    paddingLeft: theme.spacing(0)
  },
  level1: {
    paddingLeft: theme.spacing(2)
  },
  level2: {
    paddingLeft: theme.spacing(4)
  },
  level3: {
    paddingLeft: theme.spacing(6)
  },
  level4: {
    paddingLeft: theme.spacing(8)
  },
  level5: {
    paddingLeft: theme.spacing(10)
  },
  level6: {
    paddingLeft: theme.spacing(12)
  },
  level7: {
    paddingLeft: theme.spacing(14)
  },
  level8: {
    paddingLeft: theme.spacing(16)
  },
  level9: {
    paddingLeft: theme.spacing(18)
  }
}));

const useTypographyStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export const HierarchyOptionItem = ({
  classes: classesOverride,
  hierarchyLevel = 0,
  children,
  ...otherProps
}) => {
  const classes = useStyles({ classes: classesOverride });

  const typographyClasses = useTypographyStyles();

  return (
    <Typography
      {...otherProps}
      classes={typographyClasses}
      className={classes[`level${hierarchyLevel}`]}
    >
      {children}
    </Typography>
  );
};

HierarchyOptionItem.propTypes = {
  classes: PropTypes.object,
  hierarchyLevel: PropTypes.number,
  children: PropTypes.node
};

export default HierarchyOptionItem;
