import { gql } from 'graphql-tag';

export const GET_RESOURCE_PLAN_ALLOCATED_USERS = gql`
  query GetResourcePlanAllocatedUsers(
    $projectId: String!
    $filter: ResourcePlanAllocatedUsersFilter!
    $paginationToken: String
    $pageSize: Int!
    $isSortByUserAscending: Boolean!
  ) {
    project(projectId: $projectId) {
      id
      resourcePlanAllocatedUsers(
        paginationToken: $paginationToken
        pageSize: $pageSize
        isSortByUserAscending: $isSortByUserAscending
        filter: $filter
      ) {
        allocatedUsers {
          id
          uri
          displayText
        }
        paginationToken
      }
    }
  }
`;

export const getResourcePlanAllocatedUsers = async ({
  page,
  pageSize,
  projectId,
  apolloClient,
  pageTokens,
  filter,
  isSortByUserAscending,
  setVariables
}) => {
  const paginationToken = pageTokens.current[page] || null;

  if (page !== 0 && !paginationToken) {
    return {
      pageTokens: pageTokens.current,
      allocatedUsers: []
    };
  }

  const variables = {
    projectId,
    pageSize,
    filter,
    isSortByUserAscending
  };

  setVariables({
    ...variables,
    pageTokens
  });

  const { data } = await apolloClient.query({
    query: GET_RESOURCE_PLAN_ALLOCATED_USERS,
    variables: { ...variables, paginationToken },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  return {
    pageTokens: {
      ...pageTokens.current,
      [page + 1]: data?.project?.resourcePlanAllocatedUsers?.paginationToken
    },
    allocatedUsers:
      data?.project?.resourcePlanAllocatedUsers?.allocatedUsers || []
  };
};
