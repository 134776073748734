import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Decimal from '../Decimal';

export const Percentage = ({ title, value, className, ...rest }) =>
  title ? (
    <Tooltip title={title}>
      <Decimal value={value} suffix="%" className={className} {...rest} />
    </Tooltip>
  ) : (
    <Decimal value={value} suffix="%" className={className} {...rest} />
  );

Percentage.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  title: PropTypes.node
};

export default Percentage;
