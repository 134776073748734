import { removeAllTooltips } from './useOnTaskHoverTooltips';

export const openTaskDrawer = ({
  history,
  gantt,
  expandTask,
  id,
  editable
}) => {
  gantt.open(id);
  expandTask(id);
  // Prevent double-navigation
  if (history.location.hash && history.location.hash.includes('#/task/')) {
    return;
  }

  history.push({
    hash: `#/task/${id}`,
    state: {
      isProjectEditEnabled: editable
    }
  });
};

export const openProjectDrawer = ({ history, gantt, expandTask, id }) => {
  gantt.open(id);
  expandTask(id);
  // Prevent double-navigation
  if (history.location.hash && history.location.hash.includes('#/project/')) {
    return;
  }

  history.push({
    hash: `#/project/${id}`
  });
};

export const getTaskEventHandlers = ({
  gantt,
  id,
  expandTask,
  collapseTask,
  setGanttRef,
  openAddTaskDialog,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isProject,
  history,
  editable
}) => {
  return {
    onTaskExpand: () => {
      gantt.open(id);
      expandTask(id);

      return true;
    },
    onTaskCollapse: () => {
      gantt.close(id);
      collapseTask(id);

      return true;
    },
    onTaskAdd: () => {
      setGanttRef(gantt);
      const task = gantt.getTask(id);

      openAddTaskDialog({
        parentUri: id,
        startDate: task.startDate,
        endDate: task.endDate,
        parentName: task.text
      });

      return true;
    },
    onDrawerOpen: () => {
      if (isRolledUpTaskEstimateCalculationMethodEnabled && isProject) {
        openProjectDrawer({ history, gantt, expandTask, id });
      } else {
        openTaskDrawer({ history, gantt, id, editable, expandTask });
      }

      return true;
    },
    onTaskBarClick: () => {
      removeAllTooltips();
      if (isRolledUpTaskEstimateCalculationMethodEnabled && isProject) {
        openProjectDrawer({ history, gantt, expandTask, id });
      } else {
        openTaskDrawer({ history, gantt, id, editable, expandTask });
      }

      return true;
    }
  };
};

export default getTaskEventHandlers;
