import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useClientDropdownOptions } from '../hooks';
import ClientDropdown from './ClientDropdown';

const useStyles = makeStyles(theme => ({}));

export const SearchableClientDropdown = ({ value, onChange, ...rest }) => {
  const [valueLoading, setValueLoading] = useState(false);
  const {
    clients,
    loading: isLoading,
    refetchOptions
  } = useClientDropdownOptions(null);

  useEffect(() => {
    setValueLoading(false);
  }, [value]);

  const handleInputChange = useCallback(
    (_, inputValue, reason) => {
      if (reason === 'input') {
        const clientFilter = inputValue ? { text: inputValue } : {};

        return refetchOptions({ clientFilter });
      }

      return refetchOptions({ clientFilter: {} });
    },
    [refetchOptions]
  );

  const ref = useRef();
  const classes = useStyles({ current: ref?.current });
  const handleOnChange = useCallback(
    val => {
      setValueLoading(true);

      return onChange(val);
    },
    [setValueLoading, onChange]
  );

  const clientOptions = useMemo(
    () => (valueLoading || isLoading ? [] : clients),
    [clients, isLoading, valueLoading]
  );

  return (
    <ClientDropdown
      value={value}
      ref={ref}
      classes={classes}
      options={clientOptions}
      isLoading={isLoading || valueLoading}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};

SearchableClientDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default SearchableClientDropdown;
