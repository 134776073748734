import { makeStyles } from '@material-ui/styles';
import { dashBorderSVG } from '~/modules/resourcing/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(2.25)}px`,
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3)
    },
    '&:last-child': {
      marginBottom: 0
    }
  },

  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: theme.spacing(0, 0, 0.5, 0),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    }
  },
  user: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex'
  },
  noUserLabel: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '100%'
    }
  },
  match: {
    flexGrow: 1,
    flexShrink: 1
  },
  dates: {
    flexGrow: 0,
    flexShrink: 0,
    margin: theme.spacing(0, 0, 0, 2)
  },

  bars: {
    position: 'relative',
    height: theme.spacing(3)
  },
  requestBar: {
    position: 'absolute',
    top: 0,
    height: theme.spacing(3),
    border: `2px dashed ${theme.palette.grey[700]}`,
    borderImage: `url(${dashBorderSVG}) 2 repeat`,
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  allocationBar: {
    position: 'absolute',
    top: 2,
    height: theme.spacing(3) - 4,
    borderRadius: (theme.spacing(3) - 4) / 2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },

  tooltipContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, auto)',
    gridColumnGap: theme.spacing(0.5),
    gridRowGap: theme.spacing(0.5)
  },
  tooltipItem: {
    display: 'contents'
  },
  loading: {},
  loadingAdjusted: {
    fontStyle: 'italic'
  },
  overloaded: {
    color: theme.palette.error.dark
  },
  totalHours: {
    whiteSpace: 'nowrap'
  },
  allocationBarComplete: {
    backgroundColor: theme.palette.resourceRequest.complete.main,
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.complete.main
    )
  },
  allocationBarSubmitted: {
    backgroundColor: theme.palette.resourceRequest.pending.main,
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.pending.main
    )
  },
  allocationBarTentative: {
    backgroundColor: theme.palette.resourceRequest.proposed.main,
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.proposed.main
    )
  },
  allocationBarAllocationRejected: {
    backgroundColor: theme.palette.resourceRequest.resourceRejected.main,
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.resourceRejected.main
    )
  },
  requestBarRejected: {
    borderRadius: 0,
    backgroundColor: theme.palette.resourceRequest.requestRejected.main,
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.requestRejected.main
    )
  },
  requestBarToBeHired: {
    borderRadius: 0,
    backgroundColor: theme.palette.resourceRequest.toBeHired.main,
    color: theme.palette.getContrastText(
      theme.palette.resourceRequest.toBeHired.main
    )
  },
  customWidth: {
    maxWidth: '350px'
  },
  loadingLabel: {
    flexGrow: 0,
    flexShrink: 1,
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 0.5),
    display: 'block',
    position: 'sticky',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  adjustedMessage: {
    marginTop: theme.spacing(1)
  }
}));

export default useStyles;
