import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { Decimal, DateRange } from '~/modules/common/components';
import { ResourceAllocationStatus } from '~/types';
import { convertDurationToHours } from '~/modules/common/util';
import { ResourceAllocationRequestStatusBadge } from '~/modules/common/components/ResourceAllocationRequestStatusBadge';
import useStyles from './useStyles';

export const AllocationSummaryPeriodContent = ({
  classes,
  entityName,
  allocatedHours,
  status = '',
  showStatus = false,
  scheduledHours
}) => {
  return (
    <li className={classNames(classes.item, classes.allocation)}>
      <span className={classes.label}>{entityName}</span>
      {showStatus && status !== ResourceAllocationStatus.Committed ? (
        <ResourceAllocationRequestStatusBadge status={status} />
      ) : (
        <span className={classes.label}></span>
      )}
      <Decimal
        value={allocatedHours}
        className={classNames(classes.value, classes.hours)}
      />
      <Decimal
        value={(allocatedHours * 100) / scheduledHours}
        suffix="%"
        className={classNames(classes.value, classes.loading)}
      />
    </li>
  );
};

const UserAllocationSummaryContent = ({
  scheduledHours,
  holidays,
  timeOffs,
  startDate,
  endDate
}) => {
  const classes = useStyles();
  const dateClasses = useMemo(() => ({ date: classes.dateRange }), [
    classes.dateRange
  ]);

  return (
    <>
      <div className={classes.title}>
        <DateRange start={startDate} end={endDate} classes={dateClasses} />
        <div>
          <Decimal
            value={scheduledHours}
            className={classNames(classes.value, classes.hours)}
          />{' '}
          <FormattedMessage id="resourceUserPeriodSummaryContent.scheduledHours" />
        </div>
      </div>
      <ul className={classes.allocationList}>
        <li className={classNames(classes.item, classes.header)}>
          <span className={classes.label}></span>
          <span className={classes.label}></span>
          <span className={classNames(classes.value, classes.hours)}>
            <FormattedMessage id="resourceUserPeriodSummaryContent.hours" />
          </span>
          <span className={classNames(classes.value, classes.percent)}></span>
        </li>
        {(timeOffs || []).map(timeOff => {
          const { entries } = timeOff;

          const totalHours = entries
            .filter(
              e =>
                mapRepliconDateToUtcObject(e.date) >= startDate &&
                mapRepliconDateToUtcObject(e.date) <= endDate
            )
            .reduce((total, current) => total + current.hours, 0);

          return (
            <AllocationSummaryPeriodContent
              classes={classes}
              key={timeOff.timeOffType.displayText}
              allocatedHours={totalHours}
              entityName={timeOff.timeOffType.displayText}
              scheduledHours={scheduledHours}
            />
          );
        })}
        {(holidays || []).map(holiday => (
          <AllocationSummaryPeriodContent
            classes={classes}
            key={holiday.name}
            allocatedHours={convertDurationToHours(
              holiday.duration ?? holiday.effectiveDuration
            )}
            entityName={holiday.name}
            scheduledHours={scheduledHours}
          />
        ))}
      </ul>
    </>
  );
};

UserAllocationSummaryContent.propTypes = {
  scheduledHours: PropTypes.number,
  holidays: PropTypes.array,
  timeOffs: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

AllocationSummaryPeriodContent.propTypes = {
  classes: PropTypes.object,
  entityName: PropTypes.string,
  allocatedHours: PropTypes.number,
  status: PropTypes.string,
  scheduledHours: PropTypes.number,
  showStatus: PropTypes.bool
};

export default UserAllocationSummaryContent;
