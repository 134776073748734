import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles, alpha } from '@material-ui/core/styles';
import TabScrollButton from '@material-ui/core/TabScrollButton';

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: theme.spacing(2),
    width: theme.spacing(2),
    maxWidth: theme.spacing(2)
  },
  left: {
    backgroundImage: `linear-gradient(90deg, ${alpha(
      theme.palette.common.black,
      0.1
    )} 0%, ${alpha(theme.palette.common.black, 0)} 50%)`
  },
  right: {
    backgroundImage: `linear-gradient(90deg, ${alpha(
      theme.palette.common.black,
      0
    )} 50%, ${alpha(theme.palette.common.black, 0.1)} 100%)`
  }
}));

const GradientScrollButton = ({
  className,
  visible,
  direction,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <TabScrollButton
      {...otherProps}
      visible={visible?.toString()}
      direction={direction}
      className={classNames(
        classes.root,
        {
          [classes.left]: visible && direction === 'left',
          [classes.right]: visible && direction !== 'left'
        },
        className
      )}
    />
  );
};

GradientScrollButton.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  direction: PropTypes.string
};

export default GradientScrollButton;
