import { Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ResourceRequestAccessLevel, ResourcePlanGroupBy } from '~/types';
import {
  ProjectChartRowsLoading,
  ShowMoreRow
} from '~/modules/resourcing/common/components';
import { getResourceRequestStatusListFromPlanStatus } from '~/modules/resourcing/util';
import useProjectResourcingContext from '~/modules/resourcing/contexts/useProjectResourcingContext';
import {
  useResourceAllocationToolbarStateContext,
  useResourcePlanFilterBasedOnSearchCriteria
} from '~/modules/resourcing/hooks';
import useResourceRequests from '~/modules/resourcing/hooks/useResourceRequests';
import AllocatedResourceRequestRow from '../AllocatedResourceRequestRow';
import { ProjectContextProvider } from '../../common/contexts';

const useStyles = makeStyles(() => ({
  resourceRequests: {
    padding: 0
  }
}));

export const ResourceAllocationGroupDetail = ({
  chartSelectedTab,
  maxSkillLevel,
  requestAccessLevel,
  leftComponentWidth,
  plan
}) => {
  const {
    chartDates,
    scale,
    dateRange: chartDisplayDateRange,
    chartDisplayPeriods,
    displayUnit,
    resourcePlanGroup
  } = useResourceAllocationToolbarStateContext();
  const classes = useStyles();

  const { searchCriteria } = useProjectResourcingContext();
  const { planFilter } = useResourcePlanFilterBasedOnSearchCriteria({
    searchCriteria
  });

  const requestStatusList = getResourceRequestStatusListFromPlanStatus(
    chartSelectedTab
  );

  const {
    resourceRequests,
    loadingRows,
    hasMoreRows,
    loadMoreRows,
    loadingMoreRows
  } = useResourceRequests({
    ...(resourcePlanGroup === ResourcePlanGroupBy.Role
      ? { roleUri: plan.planUri }
      : { projectUri: plan.planUri }),
    requestStatusList,
    requestAccessLevel,
    filter: planFilter,
    chartDateRange: {
      startDate: chartDates[0].start,
      endDate: chartDates[chartDates.length - 1].end
    },
    scale
  });

  if (loadingRows) return <ProjectChartRowsLoading />;

  return (
    <Collapse in timeout="auto" unmountOnExit>
      <div
        data-qe-id={`${plan.planName}_AllocDiv`}
        className={classes.resourceRequests}
      >
        {resourceRequests.map(req => {
          const uri = req.role && req.role.id;
          const resourceQeIdTag = `${plan.planName}_${uri}`;

          return (
            <ProjectContextProvider project={req.project} key={req.id}>
              <AllocatedResourceRequestRow
                qeIdTag={resourceQeIdTag}
                scale={scale}
                chartDates={chartDates}
                chartDisplayDateRange={chartDisplayDateRange}
                chartDisplayPeriods={chartDisplayPeriods}
                leftComponentWidth={leftComponentWidth}
                resourceRequest={req}
                project={req.project}
                displayEntityType={
                  resourcePlanGroup === ResourcePlanGroupBy.Role
                    ? 'PROJECT'
                    : 'ROLE'
                }
                maxSkillLevel={maxSkillLevel}
                chartSelectedTab={chartSelectedTab}
                requestAccessLevel={requestAccessLevel}
                displayUnit={displayUnit}
              />
            </ProjectContextProvider>
          );
        })}
        {loadingMoreRows && <ProjectChartRowsLoading />}
        {!loadingRows && !loadingMoreRows && hasMoreRows && (
          <ShowMoreRow
            loadMore={loadMoreRows}
            recordCount={resourceRequests.length}
            resourceKey="projectResourcing.showMoreRow.resourceRequestsAllocationMessage"
          />
        )}
      </div>
    </Collapse>
  );
};

ResourceAllocationGroupDetail.propTypes = {
  plan: PropTypes.object.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  chartSelectedTab: PropTypes.string,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ]),
  maxSkillLevel: PropTypes.number
};

export default memo(ResourceAllocationGroupDetail);
