import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BILLING_CONTRACT = gql`
  query getBillingContracts {
    billingContracts {
      id
      displayText
    }
  }
`;

export const useDefaultBillingContract = projectUri => {
  const { data } = useQuery(BILLING_CONTRACT, {});

  const billingContracts = get(data, 'billingContracts', []);

  return {
    defaultBillingContract: {
      contractId: billingContracts?.length ? billingContracts[0].id : null
    }
  };
};

export default useDefaultBillingContract;
