import { gql } from 'graphql-tag';

const summaryType = `balanceAmountInBaseCurrency {
  amount
  currency {
    symbol
  }
}`;

const getBillingTransactionSummary = `
getBillingTransactionSummary: billingTransactionSummary(filter: $filter) {
  draftSummary {
    ${summaryType}
  }
  outstandingBillSummary {
    ${summaryType}
  }
  issuedSummary {
    balanceWithoutAllocationsAmountInBaseCurrency {
      amount
      currency {
        symbol
      }
    }
  }
  overdueSummary {
    ${summaryType}
  }
  unallocatedSummary {
    ${summaryType}
  }
}
`;

export const billingTransacitonSummaryQuery = gql`
  query getBillingTransactionSummary($filter: BillingTransactionFilter2) {
    ${getBillingTransactionSummary}
  }
`;

const getBillingTransactionTotals = `
getBillingTransactionTotals: getBillingTransactionTotals2(filter: $filter) {
  balanceAmountInBaseCurrency {
    amount
    currency {
      symbol
    }
  }
}`;

const getBillingTransactionTotalsForIssuedBills = `
getBillingTransactionTotals: getBillingTransactionTotals2(filter: $filter) {
  balanceWithoutAllocationsAmountInBaseCurrency {
    amount
    currency {
      symbol
    }
  }
}`;

export const totalsQuery = gql`
  query getBillingTransactionTotals($filter: BillingTransactionFilter2) {
    ${getBillingTransactionTotals}
  }
`;

export const overdueBillSummaryQuery = gql`
  query getOverdueBillSummary($filter: BillingTransactionFilter2) {
    ${getBillingTransactionTotals}
  }
`;

export const outstandingBillSummaryQuery = gql`
  query getOutstandingBillSummary($filter: BillingTransactionFilter2) {
    ${getBillingTransactionTotals}
  }
`;

export const unallocatedBillSummaryQuery = gql`
  query getUnallocatedBillSummary($filter: BillingTransactionFilter2) {
    ${getBillingTransactionTotals}
  }
`;

export const draftQuery = gql`
  query getDraftTotals($filter: BillingTransactionFilter2) {
    ${getBillingTransactionTotals}
  }
`;

export const issuedBillSummaryQuery = gql`
  query getIssuedBillSummary($filter: BillingTransactionFilter2) {
    ${getBillingTransactionTotalsForIssuedBills}
  }
`;

export const voidedBillsQuery = gql`
  query getVoidedBillCounts($filter: BillingTransactionFilter2) {
    billingTransactionRowCounts: billingTransactionRowCounts2(filter: $filter)
  }
`;
