import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { POLICYTYPE } from '~/modules/common/enums';
import { PAGE_SIZE } from '~/modules/common/const';

export const DEPARTMENTS_QUERY = gql`
  query getDepartments($searchTerm: String, $policy: String, $pageSize: Int) {
    departments(
      searchTerm: $searchTerm
      page: 1
      pageSize: $pageSize
      policy: $policy
    ) {
      id
      displayText
      hierarchyLevel
    }
  }
`;

const mappedDepartments = data =>
  data?.departments?.map(department => ({
    ...department,
    value: department.id,
    label: department.displayText,
    key: department.displayText
  }));

const useGetDepartments = ({
  searchTerm,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  includeNoneOption = false,
  noneOptionText = ''
}) => {
  const { data, loading, refetch } = useQuery(DEPARTMENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchTerm,
      pageSize: PAGE_SIZE + 1,
      policy
    },
    context: {
      debounceKey: 'department-search',
      debounceTimeout: 250
    }
  });

  const departments = !loading ? mappedDepartments(data) : [];

  return {
    departments: (departments.length && includeNoneOption
      ? prependNoneItem(noneOptionText, departments)
      : departments
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    loading,
    refetch,
    hasMore: departments.length > PAGE_SIZE
  };
};

export const fetchDepartments = ({
  apolloClient,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  intl
}) => async searchTerm => {
  const { data } = await apolloClient.query({
    query: DEPARTMENTS_QUERY,
    variables: { searchTerm, pageSize: PAGE_SIZE, policy }
  });

  return prependNoneItem(
    intl.formatMessage({ id: 'resourceBasicInfoCard.noDepartment' }),
    mappedDepartments(data)
  );
};

export const useSearchableDepartments = ({ policy } = {}) => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const fetchOptions = fetchDepartments({
    apolloClient,
    policy,
    intl
  });

  return { fetchOptions };
};

export default useGetDepartments;
