import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { RowRenderer, FooterRenderer } from './components';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  row: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  footerRow: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey[100]}`
  }
}));

export const MobileScriptParamsEditorListTable = ({
  columns,
  records,
  footers,
  totals
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={0}
      className={classes.container}
    >
      {records.map((record, index) => {
        return (
          <Grid
            key={record.scriptId || record.key || record.id}
            item
            xs={12}
            className={classes.row}
          >
            <RowRenderer record={record} columns={columns} rowIndex={index} />
          </Grid>
        );
      })}
      {totals ? (
        <Grid item xs={12} className={classes.footerRow}>
          <FooterRenderer footers={footers} totals={totals} />
        </Grid>
      ) : null}
    </Grid>
  );
};

MobileScriptParamsEditorListTable.propTypes = {
  records: PropTypes.array.isRequired,
  columns: PropTypes.object.isRequired,
  footers: PropTypes.object,
  totals: PropTypes.object
};

export default MobileScriptParamsEditorListTable;
