export const getNumberSuffixFormat = value => {
  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact'
  });

  const result = formatter.format(value);

  return {
    formattedValue:
      Math.abs(value) >= 1000
        ? result.substring(0, result.length - 1)
        : value.toString(),
    suffix:
      Math.abs(value) >= 1000 ? result[result.length - 1].toLowerCase() : ''
  };
};

export default getNumberSuffixFormat;
