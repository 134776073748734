import omit from 'lodash/fp/omit';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';

export const mapCreditMemoToAssociatedBills = (bills = []) =>
  bills.map(
    ({
      allocatedCreditMemoAmount,
      allocatedBillAmount,
      balanceAmount,
      ...rest
    }) => ({
      ...rest,
      amount: allocatedCreditMemoAmount,
      allocatedBillAmount,
      balanceAmount: {
        ...balanceAmount,
        amount: balanceAmount.amount + allocatedBillAmount.amount
      }
    })
  );

export const mapPaymentsToAssociatedBills = (bills = []) =>
  bills.map(
    ({
      allocatedBillPaymentAmount,
      allocatedBillAmount,
      balanceAmount,
      ...rest
    }) => ({
      ...rest,
      amount: allocatedBillPaymentAmount,
      allocatedBillAmount,
      balanceAmount: {
        ...balanceAmount,
        amount: balanceAmount.amount + allocatedBillAmount.amount
      }
    })
  );

export const mapPaymentsToAssociatedBillsInput = bill => ({
  id: bill.id,
  allocatedBillAmount: omitForbiddenFields(bill.allocatedBillAmount),
  allocatedBillPaymentAmount: omitForbiddenFields(bill.amount)
});

export const mapCreditMemoToAssociatedBillsInput = bill => ({
  id: bill.id,
  allocatedBillAmount: omitForbiddenFields(bill.allocatedBillAmount),
  allocatedCreditMemoAmount: omitForbiddenFields(bill.amount)
});

const omitForbiddenFields = flow(
  omit('__typename'),
  update('currency', omit('__typename'))
);
