import React from 'react';
import isempty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  }
}));

export const RoleScheduleEditableFormActionButtons = ({
  onCancel,
  form: {
    submitForm,
    errors: { schedule }
  }
}) => {
  const classes = useStyles();

  return (
    <DialogActions className={classes.root}>
      <span className={classes.grow} />
      <Button onClick={onCancel} data-qe-id="RoleCancelButton">
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button
        color="primary"
        disabled={!isempty(schedule)}
        onClick={submitForm}
        data-qe-id="RoleSaveButton"
      >
        <FormattedMessage id="button.save" />
      </Button>
    </DialogActions>
  );
};

RoleScheduleEditableFormActionButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  form: PropTypes.object
};

export default RoleScheduleEditableFormActionButtons;
