import React from 'react';
import { PropTypes } from 'prop-types';
import { RevenueRunStatus } from './RevenueRunStatus';

export const StatusFormatter = ({ field, record }) => (
  <>
    {record[field] ? (
      <RevenueRunStatus status={record[field]}></RevenueRunStatus>
    ) : null}
  </>
);

StatusFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default StatusFormatter;
