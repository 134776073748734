import { tail, take } from 'lodash';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import reject from 'lodash/fp/reject';
import sortBy from 'lodash/fp/sortBy';
import { DateTime } from 'luxon';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { PERIOD_SCALE_ENUM } from '~/modules/resourcing/common/enums';

export const excludeWeekdaysDefault = ['sa', 'su'];
const dayShortNameToLuxonWeekdayMap = {
  mo: 1,
  tu: 2,
  we: 3,
  th: 4,
  fr: 5,
  sa: 6,
  su: 7
};

const dayShortNames = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

export const mapDayShortNameToLuxonWeekDayIndex = shortName => {
  if (dayShortNameToLuxonWeekdayMap[shortName])
    return dayShortNameToLuxonWeekdayMap[shortName];

  throw new Error(`index ${shortName} out of range`);
};

export const shouldExcludeDay = (d, excludeWeekdays = []) =>
  excludeWeekdays
    .map(mapDayShortNameToLuxonWeekDayIndex)
    .some(index => index === d.weekday);

export const buildScheduleRulesWithDefaultConstraint = ({
  startDate,
  endDate,
  defaultScheduleRule: { do: constraint }
}) => [
  {
    dateRange: { startDate, endDate },
    do: constraint
  }
];

export const hasNotOverlapForPeriod = ({ start, end }) => rule =>
  Boolean(
    rule.dateRange &&
      rule.dateRange.startDate &&
      rule.dateRange.endDate &&
      mapIsoStringtoUtcObject(rule.dateRange.startDate) >= end &&
      start >= mapIsoStringtoUtcObject(rule.dateRange.endDate)
  );

export const buildScheduleRuleForPeriod = ({
  start,
  end,
  requestStartDate,
  requestEndDate,
  totalHours,
  quantity,
  excludeWeekdays = excludeWeekdaysDefault,
  load
}) => {
  const doesNotPeriodOverlapWithRequest = hasNotOverlapForPeriod({
    start,
    end
  })({
    dateRange: { startDate: requestStartDate, endDate: requestEndDate }
  });
  const requestStart = mapIsoStringtoUtcObject(requestStartDate);
  const requestEnd = mapIsoStringtoUtcObject(requestEndDate);

  const periodStart =
    doesNotPeriodOverlapWithRequest && start < requestStart
      ? requestStart
      : start;
  const periodEnd =
    doesNotPeriodOverlapWithRequest && end > requestEnd ? requestEnd : end;

  return {
    dateRange: {
      startDate: periodStart.startOf('day').toISO(),
      endDate: periodEnd.startOf('day').toISO()
    },
    do: buildRuleConstraintsForPeriod({
      start: periodStart,
      end: periodEnd,
      totalHours: totalHours || 0,
      quantity: quantity || 1,
      excludeWeekdays,
      load
    })
  };
};

export const getDateRangeFromScheduleRules = scheduleRules => {
  if (scheduleRules.length === 0) return null;

  const [
    {
      dateRange: { startDate }
    }
  ] = scheduleRules;

  const {
    dateRange: { endDate }
  } = scheduleRules[scheduleRules.length - 1];

  return {
    startDate,
    endDate
  };
};

export const buildRuleConstraintsForPeriod = ({
  start,
  end,
  totalHours,
  quantity,
  excludeWeekdays = excludeWeekdaysDefault,
  load = 100.0
}) => {
  const workingDaysCount = getWorkingDaysCount({ start, end, excludeWeekdays });
  const daysCount =
    workingDaysCount || end.diff(start, 'days').toObject().days + 1;

  const setHours = 8;
  const loading = (totalHours * load) / (setHours * quantity * daysCount);
  const _exclude = workingDaysCount ? excludeWeekdays : [];

  return { setHours, excludeWeekdays: _exclude, load: loading };
};

export const compactScheduleRules = scheduleRules =>
  flow(
    sortBy(rule => mapIsoStringtoUtcObject(rule.dateRange.startDate)),
    populateExcludeWeekdaysIfPossible,
    stitchAdjacentRulesIfPossible(true)
  )(scheduleRules);

const diffDays = (start, end) => end.diff(start, 'days').toObject().days;

export const populateExcludeWeekdaysIfPossible = sortedScheduleRules => {
  const mapToExcludeWeekdays = (start, end) => {
    const retVal = [];
    let current = start;

    while (current < end) {
      retVal.push(mapLuxonWeekDayIndexToDayShortName(current.weekday));
      current = current.plus({ days: 1 });
    }

    return retVal;
  };

  return sortedScheduleRules.reduce((acc, current) => {
    if (!acc.length) {
      acc.push(current);

      return acc;
    }

    const previous = acc[acc.length - 1];
    const previousStartDate = mapIsoStringtoUtcObject(
      previous.dateRange.startDate
    );
    const previousEndDate = mapIsoStringtoUtcObject(previous.dateRange.endDate);

    const currentStartDate = mapIsoStringtoUtcObject(
      current.dateRange.startDate
    );

    if (
      !previous.do.excludeWeekdays?.length &&
      !current.do.excludeWeekdays?.length &&
      diffDays(previousStartDate, currentStartDate) === 7 &&
      diffDays(previousStartDate, previousEndDate) >= 4
    ) {
      acc[acc.length - 1] = {
        ...previous,
        dateRange: {
          ...previous.dateRange,
          endDate: currentStartDate.minus({ days: 1 }).toISO()
        },
        do: {
          ...previous.do,
          excludeWeekdays: mapToExcludeWeekdays(
            previousEndDate.plus({ days: 1 }),
            currentStartDate
          )
        }
      };
    }

    acc.push(current);

    return acc;
  }, []);
};

export const mapLuxonWeekDayIndexToDayShortName = dayIndex => {
  if (dayIndex < 1 || dayIndex > 7)
    throw new Error(`index ${dayIndex} is out of range`);

  return dayShortNames[dayIndex - 1];
};

export const mergePeriodRuleIntoScheduleRules = (
  scheduleRules = [],
  checkSetHours = false
) => periodRule => {
  const {
    dateRange: { startDate, endDate }
  } = periodRule;

  const start = mapIsoStringtoUtcObject(startDate);
  const end = mapIsoStringtoUtcObject(endDate);

  return flow(
    reject(isSubsetDateRangeRule({ start, end })),
    adjustRulesWithPeriodOverlap({ start, end }),
    appendPeriodRule(periodRule),
    reject(rule => Boolean(rule.do && rule.do.load === 0)),
    sortBy(rule => mapIsoStringtoUtcObject(rule.dateRange.startDate)),
    stitchAdjacentRulesIfPossible(checkSetHours)
  )(scheduleRules);
};

export const mergePeriodRulesIntoScheduleRules = (
  scheduleRules = [],
  checkSetHours = false
) => periodRules => {
  if (periodRules.length === 0) return scheduleRules;

  const [
    {
      dateRange: { startDate: periodRuleStartDate }
    }
  ] = periodRules;

  const {
    dateRange: { endDate: periodRuleEndDate }
  } = periodRules[periodRules.length - 1];

  const newPeriodRule = {
    dateRange: { startDate: periodRuleStartDate, endDate: periodRuleEndDate },
    do: { setHours: 0, load: 0, excludeWeekdays: [] }
  };

  const rules = mergePeriodRuleIntoScheduleRules(
    scheduleRules,
    checkSetHours
  )(newPeriodRule);
  const newRules = rules.concat(periodRules);

  return sortBy(rule => mapIsoStringtoUtcObject(rule.dateRange.startDate))(
    newRules
  );
};

export const stitchAdjacentRulesIfPossible = checkSetHours => sortedScheduleRules =>
  sortedScheduleRules.reduce((retVal, current, index) => {
    if (!retVal.length) return [...retVal, current];

    const previous = retVal[retVal.length - 1];
    const previousConstraints = previous.do;
    const currentConstraints = current.do;
    const matchingConstraints = checkSetHours
      ? previousConstraints.load === currentConstraints.load &&
        previousConstraints.setHours === currentConstraints.setHours
      : previousConstraints.load === currentConstraints.load;

    return matchingConstraints &&
      areConsecutiveDates(
        mapIsoStringtoUtcObject(previous.dateRange.endDate),
        mapIsoStringtoUtcObject(current.dateRange.startDate)
      ) &&
      haveSameExcludeWeekdays(
        previousConstraints.excludeWeekdays,
        currentConstraints.excludeWeekdays
      )
      ? [
          ...take(retVal, retVal.length - 1),
          {
            ...previous,
            dateRange: {
              ...previous.dateRange,
              endDate: current.dateRange.endDate
            },
            do: {
              ...previous.do
            }
          }
        ]
      : [...retVal, current];
  }, []);

const areConsecutiveDates = (start, end) =>
  end.diff(start, 'days').toObject().days === 1;

export const haveSameExcludeWeekdays = (excludeWeekdays1, excludeWeekdays2) =>
  (excludeWeekdays1 || []).every(e => (excludeWeekdays2 || []).includes(e)) &&
  (excludeWeekdays2 || []).every(e => (excludeWeekdays1 || []).includes(e));

export const appendPeriodRule = periodRule => scheduleRules => [
  ...scheduleRules,
  periodRule
];

export const adjustRulesWithPeriodOverlap = ({ start, end }) => scheduleRules =>
  scheduleRules.reduce((retVal, rule) => {
    const ruleStartDate = mapIsoStringtoUtcObject(rule.dateRange.startDate);
    const ruleEndDate = mapIsoStringtoUtcObject(rule.dateRange.endDate);

    const startOverlap = ruleStartDate < start && start <= ruleEndDate;
    const endOverlap = ruleStartDate <= end && end < ruleEndDate;

    return [
      ...retVal,
      ...(startOverlap && endOverlap
        ? [
            createStartOverlapAdjustedRule({ start, rule }),
            createEndOverlapAdjustedRule({ end, rule })
          ]
        : startOverlap
        ? [createStartOverlapAdjustedRule({ start, rule })]
        : endOverlap
        ? [createEndOverlapAdjustedRule({ end, rule })]
        : [rule])
    ];
  }, []);

const createStartOverlapAdjustedRule = ({ start, rule }) => ({
  ...rule,
  dateRange: {
    ...rule.dateRange,
    endDate: start
      .minus({ days: 1 })
      .startOf('day')
      .toISO()
  },
  do: {
    ...rule.do
  }
});

const createEndOverlapAdjustedRule = ({ end, rule }) => ({
  ...rule,
  dateRange: {
    ...rule.dateRange,
    startDate: end
      .plus({ days: 1 })
      .startOf('day')
      .toISO()
  },
  do: {
    ...rule.do
  }
});

export const isSubsetDateRangeRule = ({ start, end }) => rule => {
  const isValid = Boolean(
    rule.dateRange && rule.dateRange.startDate && rule.dateRange.endDate
  );

  if (!isValid) return false;

  const ruleStart = mapIsoStringtoUtcObject(rule.dateRange.startDate);
  const ruleEnd = mapIsoStringtoUtcObject(rule.dateRange.endDate);

  return Boolean(
    start <= ruleStart && ruleStart <= end && start <= ruleEnd && ruleEnd <= end
  );
};

export const hasOverlap = ({ start, end }) => rule =>
  Boolean(
    rule.dateRange &&
      rule.dateRange.startDate &&
      rule.dateRange.endDate &&
      mapIsoStringtoUtcObject(rule.dateRange.startDate) <= end &&
      start <= mapIsoStringtoUtcObject(rule.dateRange.endDate)
  );

export const updateScheduleStartBoundary = ({
  start,
  defaultScheduleRule
}) => sortedScheduleRules => {
  if (!sortedScheduleRules.length) return sortedScheduleRules;

  const minDateRule = sortedScheduleRules[0];
  const minStartDate = mapIsoStringtoUtcObject(minDateRule.dateRange.startDate);
  const {
    do: { setHours: hoursPerDay }
  } = defaultScheduleRule;

  if (minStartDate > start) {
    if (minDateRule.do.setHours === hoursPerDay) {
      return [
        buildScheduleRulesWithDefaultConstraint({
          startDate: start.toISO(),
          endDate: minDateRule.dateRange.endDate,
          defaultScheduleRule
        })[0],
        ...tail(sortedScheduleRules)
      ];
    }

    return [
      buildScheduleRulesWithDefaultConstraint({
        startDate: start.toISO(),
        endDate: minStartDate
          .minus({ days: 1 })
          .startOf('day')
          .toISO(),
        defaultScheduleRule
      })[0],
      ...sortedScheduleRules
    ];
  }

  return sortedScheduleRules;
};

export const updateScheduleEndBoundary = ({
  end,
  defaultScheduleRule
}) => sortedScheduleRules => {
  if (!sortedScheduleRules.length) return sortedScheduleRules;

  const maxDateRule = sortedScheduleRules[sortedScheduleRules.length - 1];
  const maxEndDate = mapIsoStringtoUtcObject(maxDateRule.dateRange.endDate);

  const {
    do: { setHours: hoursPerDay }
  } = defaultScheduleRule;

  if (maxEndDate < end) {
    if (maxDateRule.do.setHours === hoursPerDay) {
      return [
        ...take(sortedScheduleRules, sortedScheduleRules.length - 1),
        buildScheduleRulesWithDefaultConstraint({
          startDate: maxDateRule.dateRange.startDate,
          endDate: end.toISO(),
          defaultScheduleRule
        })[0]
      ];
    }

    return [
      ...sortedScheduleRules,
      buildScheduleRulesWithDefaultConstraint({
        startDate: maxEndDate.plus({ days: 1 }).toISO(),
        endDate: end.toISO(),
        defaultScheduleRule
      })[0]
    ];
  }

  return sortedScheduleRules;
};

const updateScheduleBoundaryPeriodsIfRequired = ({
  start,
  end,
  defaultScheduleRule
}) => sortedScheduleRules =>
  flow(
    updateScheduleStartBoundary({
      start,
      defaultScheduleRule
    }),
    updateScheduleEndBoundary({
      end,
      defaultScheduleRule
    })
  )(sortedScheduleRules);

export const truncateRuleBoundaries = ({ start, end }) => rule => ({
  ...rule,
  dateRange: {
    ...rule.dateRange,
    ...(mapIsoStringtoUtcObject(rule.dateRange.startDate) < start
      ? { startDate: start.startOf('day').toISO() }
      : {}),
    ...(mapIsoStringtoUtcObject(rule.dateRange.endDate) > end
      ? { endDate: end.startOf('day').toISO() }
      : {})
  },
  do: {
    ...rule.do
  }
});

export const buildUpdatedScheduleRules = ({
  start,
  end,
  scheduleRules = [],
  defaultScheduleRule
}) => {
  const noOverlapWithExistingSchedule = !scheduleRules.some(
    hasOverlap({ start, end })
  );

  if (noOverlapWithExistingSchedule)
    return buildScheduleRulesWithDefaultConstraint({
      startDate: start.toISO(),
      endDate: end.toISO(),
      defaultScheduleRule
    });

  return flow(
    sortBy(rule => mapIsoStringtoUtcObject(rule.dateRange.startDate)),
    filter(hasOverlap({ start, end })),
    updateScheduleBoundaryPeriodsIfRequired({
      start,
      end,
      defaultScheduleRule
    }),
    map(truncateRuleBoundaries({ start, end }))
  )(scheduleRules);
};
// obsolete - to be phased out
export const getWorkingDaysCount = ({ start, end, excludeWeekdays }) => {
  let workingDaysCount = 0;
  let current = start;

  while (current <= end) {
    if (!shouldExcludeDay(current, excludeWeekdays)) workingDaysCount++;
    current = current.plus({ days: 1 });
  }

  return workingDaysCount;
};

export const generateLuxonWeekdays = ({ start, end }) => {
  const retVal = [];
  let current = start;

  while (current <= end) {
    retVal.push(current.weekday);
    current = current.plus({ days: 1 });
  }

  return retVal;
};

// faster version - getWorkingDaysCount
export const getWorkingDaysCount2 = ({ start, end, excludeWeekdays }) => {
  if (!excludeWeekdays?.length)
    return end.diff(start, 'days').toObject().days + 1;

  const daysInDateRange = end.diff(start, 'days').toObject().days + 1;

  const weeks = Math.floor(daysInDateRange / 7);
  const subweekDays = daysInDateRange % 7;

  return (
    weeks * (7 - excludeWeekdays.length) +
    (subweekDays
      ? generateLuxonWeekdays({
          start,
          end: start.plus({ days: subweekDays - 1 })
        }).filter(
          w =>
            !excludeWeekdays
              .map(shortName => dayShortNameToLuxonWeekdayMap[shortName])
              .some(index => index === w)
        ).length
      : 0)
  );
};

// obsolete - to be phased out
const getTotalHoursFromScheduleRuleEntry = ({ start, end, constraints }) =>
  getWorkingDaysCount({
    start,
    end,
    excludeWeekdays: constraints.excludeWeekdays
  }) *
  (constraints.setHours || 0) *
  ((constraints.load !== null && constraints.load !== undefined
    ? constraints.load
    : 100) /
    100);

// faster version - getTotalHoursFromScheduleRuleEntry
export const getTotalHoursFromScheduleRuleEntry2 = ({
  start,
  end,
  constraints
}) =>
  (constraints.setHours || 0) *
  ((constraints.load !== null && constraints.load !== undefined
    ? constraints.load
    : 100) /
    100) *
  getWorkingDaysCount2({
    start,
    end,
    excludeWeekdays: constraints.excludeWeekdays
  });

export const getTotalHoursFromScheduleRule = ({
  dateRange,
  do: constraints
}) => {
  if (
    !dateRange ||
    !dateRange.startDate ||
    !dateRange.endDate ||
    !constraints ||
    !constraints.setHours
  )
    return 0;

  const start = DateTime.fromISO(dateRange.startDate).toUTC();
  const end = DateTime.fromISO(dateRange.endDate).toUTC();

  return getTotalHoursFromScheduleRuleEntry({ start, end, constraints });
};

export const getTotalHoursForPeriodFromScheduleRules = ({
  start,
  end,
  scheduleRules,
  useVersion2
}) => {
  const entryTotalsFunc = useVersion2
    ? getTotalHoursFromScheduleRuleEntry2
    : getTotalHoursFromScheduleRuleEntry;

  return scheduleRules
    .filter(rule => rule.startDate <= end && start <= rule.endDate)
    .reduce((total, rule) => {
      const { startDate, endDate, do: constraints } = rule;
      const currentHours = entryTotalsFunc({
        start: DateTime.max(startDate, start),
        end: DateTime.min(endDate, end),
        constraints
      });

      return total + currentHours;
    }, 0);
};

export const getTotalHoursForDateRangeFromScheduleRules = ({
  start,
  end,
  scheduleRules
}) => {
  if (!start || !end) return null;

  const noOverlapWithExistingSchedule = !scheduleRules.some(
    hasOverlap({ start, end })
  );

  if (noOverlapWithExistingSchedule) return null;

  const overlappingRules = flow(
    sortBy(rule => mapIsoStringtoUtcObject(rule.dateRange.startDate)),
    filter(hasOverlap({ start, end })),
    map(truncateRuleBoundaries({ start, end }))
  )(scheduleRules);

  return overlappingRules
    .map(getTotalHoursFromScheduleRule)
    .reduce((total, currentHours) => total + currentHours, 0);
};

export const roundToDecimals = number =>
  Math.round((number + Number.EPSILON) * 100) / 100;

export const getScheduleTotalHours = ({ scheduleRules, quantity }) => {
  const totalFromRules = scheduleRules
    .map(getTotalHoursFromScheduleRule)
    .reduce((total, currentHours) => total + currentHours, 0);

  return totalFromRules * (quantity || 0);
};

export const getRoundedHours = ({ hours, scale }) => {
  if (scale === PERIOD_SCALE_ENUM.DAYS) {
    return Math.round(hours * 10) / 10;
  }

  return Math.abs(hours) > 0 ? Math.round(hours * 100) / 100 : hours;
};

export const getDayViewRoundedHours = ({ hours, scale }) => {
  return scale === PERIOD_SCALE_ENUM.DAYS ? Math.round(hours * 10) / 10 : hours;
};

export const getDayViewRoundedPercentage = ({ percentage, scale }) => {
  return scale === PERIOD_SCALE_ENUM.DAYS ? Math.round(percentage) : percentage;
};

export const getScheduleTotals = ({
  scheduleRules = [],
  quantity,
  rate,
  currencyUri,
  currencySymbol
}) => {
  const totalHours = getScheduleTotalHours({ scheduleRules, quantity });

  return {
    totalHours,
    totalCost: {
      amount: totalHours * (rate || 0),
      currencyUri,
      currencySymbol
    }
  };
};

export const getTotalHours = ({
  quantity,
  scheduleRules,
  defaultScheduleRule,
  startDate,
  endDate
}) => {
  const rules =
    scheduleRules ||
    buildScheduleRulesWithDefaultConstraint({
      startDate,
      endDate,
      defaultScheduleRule
    });

  const totalHours = getScheduleTotalHours({
    scheduleRules: rules,
    quantity: quantity || 1
  });

  return getRoundedValue(totalHours);
};

export const getRoundedValue = (value, precision = 2) =>
  value ? parseFloat(value.toFixed(precision)) : 0;
