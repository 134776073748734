import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  period: {
    marginBottom: '8px',
    backgroundColor: theme.palette.resourceRequest.pending.main
  },
  periodClickable: {},
  loadingLabel: {}
}));

export default useStyles;
