import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { DialogActions, Typography, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { withCountries } from '~/modules/common/enhancers';
import { useIsBreakpointDown, useScrollToBottom } from '~/modules/common/hooks';
import AddressInfoActionComponent from './AddressInfoActionComponent';
import { addressValidationSchema } from './addressValidationSchema';
import useUpdateProjectAddresses from './hooks/useUpdateProjectAddresses';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(1.25)}px !important`,
      paddingRight: `${theme.spacing(1.25)}px !important`
    }
  },
  dialogContent: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(1.25)}px !important`,
      paddingRight: `${theme.spacing(1.25)}px !important`
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  typography: {
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  },
  dialogAction: {
    display: 'block',
    margin: theme.spacing(0.5, 3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1.25),
      marginRight: theme.spacing(1.25)
    }
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    minWidth: 'max-content',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'fit-content'
    }
  }
}));

export const EditableAddressInfoDialog = ({
  projectId,
  open,
  onClose,
  addresses,
  isReadOnly,
  countries,
  children
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onSubmit } = useUpdateProjectAddresses({
    projectId,
    setIsSubmitting,
    onClose
  });
  const initialValues = useMemo(
    () => ({
      addresses: (addresses || []).map(
        ({
          address,
          city,
          stateProvince,
          zipPostalCode,
          country,
          addressType
        }) => ({
          id: v4(),
          address: address || '',
          city: city || '',
          stateProvince: stateProvince || '',
          zipPostalCode: zipPostalCode || '',
          country:
            country && country.id
              ? { id: country.id, displayText: country.displayText }
              : null,
          addressType
        })
      )
    }),
    [addresses]
  );

  const handleClose = useCallback(
    (_, reason) => {
      if (reason && reason === 'backdropClick') return;
      onClose();
    },
    [onClose]
  );

  const {
    containerRef,
    shouldScrollToBottom,
    setShouldScrollToBottom,
    scrollToBottom
  } = useScrollToBottom();

  useEffect(() => shouldScrollToBottom && scrollToBottom(), [
    shouldScrollToBottom,
    scrollToBottom
  ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addressValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Dialog
        classes={useDialogStyles()}
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.dialogTitle}>
          <DescriptionIcon className={classes.icon} />
          <Typography
            variant="h6"
            component="h2"
            tabIndex={0}
            className={classes.typography}
          >
            <FormattedMessage id="projectBasicInfoCard.addressInfo.addresses" />
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div ref={containerRef}>
            {React.cloneElement(children, { ...children.props, countries })}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <AddressInfoActionComponent
            isReadOnly={isReadOnly}
            isSubmitting={isSubmitting}
            onClose={onClose}
            setShouldScrollToBottom={setShouldScrollToBottom}
          />
        </DialogActions>
      </Dialog>
    </Formik>
  );
};

EditableAddressInfoDialog.propTypes = {
  projectId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  addresses: PropTypes.array,
  isReadOnly: PropTypes.bool,
  countries: PropTypes.array,
  children: PropTypes.node
};

export default withCountries(EditableAddressInfoDialog);
