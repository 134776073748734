import { Chip, styled } from '@material-ui/core';

const CustomChip = styled(Chip)(({ theme }) => ({
  color: 'grey',
  fontWeight: 500,
  height: theme.spacing(2.25),
  borderRadius: theme.spacing(0.5),
  fontSize: theme.typography.body2.fontSize,
  '& span': {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  }
}));

export default CustomChip;
