import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  groupContainer: {
    margin: theme.spacing(0, 1, -2.5, 0)
  },
  groupItem: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  itemsRowContainer: {
    paddingBottom: theme.spacing(2.5)
  },
  lastItemContainer: {
    paddingTop: theme.spacing(2.5)
  }
}));
