import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0.5) + 1
  },
  label: {
    fontSize: theme.spacing(1.25) + 1
  },
  hours: {
    fontSize: theme.spacing(1.75)
  },
  estimatedDateAtCompletionRow: {
    marginBottom: theme.spacing(0)
  },
  estimatedDateAtCompletion: {
    fontSize: theme.spacing(1.5) + 1,
    fontWeight: '600'
  },
  hoursLabel: {
    fontSize: theme.spacing(1.5)
  },
  hoursWithFFOn: {
    fontSize: theme.typography.body2.fontSize
  },
  labelWithFFOn: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary
  },
  hoursLabelWithFFOn: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary
  }
});

export const TooltipRow = ({
  intl,
  classes: classesOverride,
  label,
  data,
  isHours = true,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles({
    classes: classesOverride
  });

  return (
    <div
      className={classNames(classes.row, {
        [classes.estimatedDateAtCompletionRow]: !isHours
      })}
    >
      <span
        className={classNames(classes.label, {
          [classes.labelWithFFOn]: isPsaRmpTaskAllocation1Enabled
        })}
      >
        {label}:
      </span>
      <div>
        <span
          className={classNames({
            [classes.hours]: isHours,
            [classes.estimatedDateAtCompletionRow]: !isHours,
            [classes.hoursWithFFOn]: isPsaRmpTaskAllocation1Enabled && isHours
          })}
        >
          {data}
        </span>
        {isHours && (
          <span
            className={classNames(classes.hoursLabel, {
              [classes.hoursLabelWithFFOn]: isPsaRmpTaskAllocation1Enabled
            })}
          >
            {intl.formatMessage({
              id: isPsaRmpTaskAllocation1Enabled
                ? 'projectTasksPage.h'
                : 'projectTasksPage.hrs'
            })}
          </span>
        )}
      </div>
    </div>
  );
};

TooltipRow.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  label: PropTypes.string,
  data: PropTypes.any,
  isHours: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default TooltipRow;
