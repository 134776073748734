import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, DialogContentText, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import classNames from 'classnames';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { CertificateIcon } from '~/modules/common/components/Icons';
import SkillRating from '~/modules/resourcing/common/components/Skills/SkillRating';
import SkillCertificateAssignment from '~/modules/resourcing/common/components/Skills/SkillCertificateAssignment';
import SkillCustomMetadataCell from '~/modules/resourcing/common/components/Skills/SkillCustomMetadataCell';
import useStyles from './useStyles';

const deleteDialogResourceKeys = {
  header: 'skillInfoCard.deleteSkillDialog.removeSkill',
  actionButton: 'skillInfoCard.deleteSkillDialog.removeSkill'
};

export const MobileSkillRow = ({
  skill,
  skill: { certificate },
  certificates,
  userDetails,
  skillSetting,
  classes: classesOverride,
  onSkillRemove,
  onSkillRatingUpdate,
  onCustomMetadataUpdate,
  maxSkillLevel,
  onCertificateUpdate
}) => {
  const classes = useStyles({ classes: classesOverride });
  const certificateMetdata =
    certificate &&
    certificates &&
    certificates.find(p => p.uri === certificate);
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const onRemoveConfirmCallback = useCallback(() => onSkillRemove(skill), [
    onSkillRemove,
    skill
  ]);

  const onRemoveClickCallback = useCallback(
    e => onCertificateUpdate(e.target.id, skill),
    [onCertificateUpdate, skill]
  );

  const assignmentCallback = useCallback(e => onCertificateUpdate(e, skill), [
    onCertificateUpdate,
    skill
  ]);

  return (
    <>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onRemoveConfirmCallback}
        >
          <DialogContentText>
            <FormattedMessage id="skillInfoCard.deleteSkillDialog.description" />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
      <Grid container direction="row">
        <Grid item className={classes.cell} xs={11}>
          <span className={classes.skillName}>{skill.displayText}</span>
        </Grid>
        <Grid item className={classes.removeIconCell} xs={1}>
          <IconButton onClick={openRemoveDialog} className={classes.iconButton}>
            <RemoveCircle className={classes.removeIcon} />
          </IconButton>
        </Grid>
        <Grid item className={classes.cell} xs={6}>
          <span className={classes.title}>
            <FormattedMessage id="skillInfoCard.certificate" />
          </span>
          {certificateMetdata ? (
            <div className={classes.certificate}>
              <div className={classes.name}>
                <CertificateIcon className={classes.certificateIcon} />
                {certificateMetdata.name}
              </div>
              <IconButton
                className={classes.removeLicence}
                onClick={onRemoveClickCallback}
              >
                <RemoveCircle />
              </IconButton>
            </div>
          ) : (
            <div className={classes.addCertificate}>
              <SkillCertificateAssignment
                certificates={certificates}
                userDetails={userDetails}
                onCertificateUpdate={assignmentCallback}
              />
            </div>
          )}
        </Grid>
        <Grid item className={classes.cell} xs={6}>
          <span className={classNames(classes.skillRatingTitle, classes.title)}>
            <FormattedMessage id="skillInfoCard.level" />
          </span>
          {Boolean(maxSkillLevel) && (
            <SkillRating
              skillAssignment={skill}
              onSkillRatingUpdate={onSkillRatingUpdate}
              className={classes.skillRatingContainer}
              editable
              maxSkillLevel={maxSkillLevel}
            />
          )}
        </Grid>
        {skillSetting.map(setting => (
          <Grid item key={setting.uri} className={classes.cell} xs={6}>
            <SkillCustomMetadataCell
              key={setting.uri}
              skill={skill}
              setting={setting}
              onCustomMetadataUpdate={onCustomMetadataUpdate}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

MobileSkillRow.propTypes = {
  classes: PropTypes.object,
  skill: PropTypes.object.isRequired,
  certificates: PropTypes.array,
  skillSetting: PropTypes.array,
  onSkillRemove: PropTypes.func,
  onSkillRatingUpdate: PropTypes.func,
  onCustomMetadataUpdate: PropTypes.func,
  maxSkillLevel: PropTypes.number,
  onCertificateUpdate: PropTypes.func,
  userDetails: PropTypes.object
};

export default MobileSkillRow;
