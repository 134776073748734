import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback
} from 'react';
import { ResourceRequestStatus, ResourceAllocationStatus } from '~/types';
import {
  DEFAULT_PERIOD_SCALE,
  DEFAULT_DISPLAY_UNIT
} from '~/modules/resourcing/common/enums';
import { resourcingLegendItems } from '~/modules/resourcing/common/components/ResourcingLegends/resourcingLegendItems';
import {
  useKeyValueSettingsMutation,
  updateCache
} from '~/modules/common/hooks';
import { useGetChartDates } from './useGetResourceChartInformation';

export const ResourceRequestToolbarContext = createContext();

const requestStatusList = [
  resourcingLegendItems.request,
  resourcingLegendItems.requestRejected,
  resourcingLegendItems.toBeHired,
  resourcingLegendItems.resourceRejected,
  resourcingLegendItems.pending,
  resourcingLegendItems.proposed,
  resourcingLegendItems.complete
];

const requestStatusMap = {
  [ResourceRequestStatus.Draft]: resourcingLegendItems.request,
  [ResourceRequestStatus.Rejected]: resourcingLegendItems.requestRejected,
  [ResourceRequestStatus.Tobehired]: resourcingLegendItems.toBeHired,
  [ResourceRequestStatus.Allocationrejected]:
    resourcingLegendItems.resourceRejected,
  [ResourceRequestStatus.Submitted]: resourcingLegendItems.pending,
  [ResourceRequestStatus.Tentative]: resourcingLegendItems.proposed,
  [ResourceRequestStatus.Complete]: resourcingLegendItems.complete
};

const allocationToRequestMap = {
  [ResourceAllocationStatus.Committed]: [ResourceRequestStatus.Complete],
  [ResourceAllocationStatus.Rejected]: [ResourceRequestStatus.Rejected],
  [ResourceAllocationStatus.Proposed]: [ResourceRequestStatus.Tentative],
  [ResourceAllocationStatus.Draft]: [ResourceRequestStatus.Draft]
};

const useResourceRequestToolbarContext = () =>
  useContext(ResourceRequestToolbarContext);

export const getLegends = ({
  requestStatusesFromPlan = [],
  allocationStatusesFromPlan = []
}) => {
  const allocationLegendsMapped = allocationStatusesFromPlan.flatMap(
    status => allocationToRequestMap[status]
  );

  const legendsSet = new Set([
    ...requestStatusesFromPlan,
    ...allocationLegendsMapped
  ]);

  const legends = Array.from(legendsSet).map(
    status => requestStatusMap[status]
  );

  const legendsInOrder = requestStatusList.filter(legend =>
    legends.includes(legend)
  );

  const includeNotWorkingLegend = [
    ResourceAllocationStatus.Committed,
    ResourceAllocationStatus.Proposed,
    ResourceAllocationStatus.Rejected
  ].some(status => allocationStatusesFromPlan.includes(status));

  return includeNotWorkingLegend
    ? [...legendsInOrder, resourcingLegendItems.notWorking]
    : legendsInOrder;
};

export const useResourceRequestToolbarContextValue = ({
  width,
  setSearchCriteria,
  searchCriteria,
  filter,
  sort,
  onSortChange,
  resourceRequestsMinMaxDateAndStatuses,
  resourceAllocationsMinMaxDateAndStatuses,
  chartSettings
}) => {
  const [scale, setScale] = useState(
    chartSettings?.scale || DEFAULT_PERIOD_SCALE
  );

  const [displayUnit, setDisplayUnit] = useState(
    chartSettings?.displayUnit || DEFAULT_DISPLAY_UNIT
  );

  const [
    isResourceActualModeEnabled,
    setIsResourceActualModeEnabled
  ] = useState(chartSettings?.showActuals || false);

  const [putKeyValueSettings] = useKeyValueSettingsMutation(
    updateCache('resource_plan_chart_settings')
  );

  const handleSettingsChange = useCallback(
    ({
      displayUnit: newDisplayUnit,
      showActuals: newIsResourceActualModeEnabled,
      scale: newScale
    }) => {
      putKeyValueSettings({
        variables: {
          input: {
            key: 'resource_plan_chart_settings',
            settings: {
              displayUnit: newDisplayUnit,
              showActuals: newIsResourceActualModeEnabled,
              scale: newScale
            }
          }
        }
      });
    },
    [putKeyValueSettings]
  );

  const legends = getLegends({
    requestStatusesFromPlan:
      resourceRequestsMinMaxDateAndStatuses?.requestStatuses,
    allocationStatusesFromPlan:
      resourceAllocationsMinMaxDateAndStatuses?.allocationStatuses
  });

  const {
    chartDates,
    onPrevious,
    onNext,
    dateRange,
    setFixedRangeScale
  } = useGetChartDates({
    width,
    scale,
    resourceRequestsMinMaxDateAndStatuses,
    resourceAllocationsMinMaxDateAndStatuses
  });

  const onScaleChange = useCallback(
    newValue => {
      setScale(newValue);
      setFixedRangeScale(newValue);
      handleSettingsChange({
        displayUnit,
        showActuals: isResourceActualModeEnabled,
        scale: newValue
      });
    },
    [
      displayUnit,
      handleSettingsChange,
      isResourceActualModeEnabled,
      setFixedRangeScale
    ]
  );

  const onSetDisplayUnit = useCallback(
    newValue => {
      setDisplayUnit(newValue);
      handleSettingsChange({
        displayUnit: newValue,
        showActuals: isResourceActualModeEnabled,
        scale
      });
    },
    [handleSettingsChange, isResourceActualModeEnabled, scale]
  );

  const onSetIsResourceActualModeEnabled = useCallback(
    newValue => {
      setIsResourceActualModeEnabled(newValue);
      handleSettingsChange({
        displayUnit,
        showActuals: newValue,
        scale
      });
    },
    [displayUnit, handleSettingsChange, scale]
  );

  return useMemo(
    () => ({
      displayUnit,
      setDisplayUnit: onSetDisplayUnit,
      scale,
      chartDates,
      dateRange,
      setScale: onScaleChange,
      onPrevious,
      onNext,
      isResourceActualModeEnabled,
      setIsResourceActualModeEnabled: onSetIsResourceActualModeEnabled,
      legends,
      setSearchCriteria,
      searchCriteria,
      filter,
      sort,
      onSortChange,
      handleSettingsChange
    }),
    [
      displayUnit,
      onSetDisplayUnit,
      scale,
      chartDates,
      dateRange,
      onScaleChange,
      onPrevious,
      onNext,
      isResourceActualModeEnabled,
      onSetIsResourceActualModeEnabled,
      legends,
      setSearchCriteria,
      searchCriteria,
      filter,
      sort,
      onSortChange,
      handleSettingsChange
    ]
  );
};

export default useResourceRequestToolbarContext;
