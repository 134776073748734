import React, { useEffect } from 'react';
import {
  useIsBreakpointDown,
  useGetKeyValueSettings
} from '~/modules/common/hooks';
import { NotSupportedPage } from '~/modules/common/components';
import { usePageTitle } from '~/modules/common/title';
import { usePageLayoutContext } from '~/modules/navigation/components/PageLayout/PageLayoutContext';

import QuickAllocationContent from './QuickAllocationContent';
import QuickAllocationUserChartLoading from './components/QuickAllocationUserChart/components/QuickAllocationUserChartLoading';

export const QuickAllocation = () => {
  const pageTitle = 'routes.quickAllocation';

  usePageTitle([{ messageId: pageTitle }]);

  const { mainRef } = usePageLayoutContext();

  useEffect(() => {
    mainRef.current.scrollLeft = 0;
  }, [mainRef]);

  const isMobile = useIsBreakpointDown('sm');

  const {
    isLoading: isKeyValueSettingsLoading,
    keyValueSettings: chartSettings
  } = useGetKeyValueSettings('quick_allocation_chart_settings');

  if (isMobile) return <NotSupportedPage pageTitle={pageTitle} />;

  if (isKeyValueSettingsLoading) {
    return <QuickAllocationUserChartLoading />;
  }

  return <QuickAllocationContent chartSettings={chartSettings} />;
};

export default QuickAllocation;
