/* eslint-disable react/jsx-max-depth */
import React, { Children, useMemo } from 'react';

import { PropTypes } from 'prop-types';
import { IconButton } from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMoreSharp';
import CollapseIcon from '@material-ui/icons/ExpandLessSharp';

import classNames from 'classnames';

import Checkbox from '~/modules/common/components/Checkbox';

import { TaskLink } from '~/modules/common/components/entities/task';
import { ResponsiveTableRow, ResponsiveTableColumn } from '../ResponsiveTable';
import withStyles from './withStyles';

export const TaskRow = ({
  classes,
  title,
  taskId,
  path,
  zeroPadding = false,
  zeroVerticalPadding = false,
  children,
  selected,
  showCheckbox,
  checkboxClassName,
  onToggleSelection,
  isHighlighted = false,
  minHeight = true,
  isMDView = false,
  expanded,
  showExpand,
  onToggleExpand,
  hideBorder = false
}) => {
  const task = useMemo(
    () =>
      title &&
      taskId &&
      path && {
        name: title,
        id: taskId,
        path
      },
    [title, taskId, path]
  );

  return (
    <ResponsiveTableRow
      className={classNames(
        minHeight && classes.row,
        isHighlighted && classes.rowHighlighted,
        isMDView && classes.root,
        hideBorder && classes.noBorder
      )}
    >
      <ResponsiveTableColumn
        flex="grow"
        size="lg"
        className={classNames(
          classes.cell,
          classes.title,
          isMDView && classes.taskName,
          isHighlighted && classes.cellHighlighted,
          zeroVerticalPadding && classes.zeroVerticalPadding
        )}
      >
        <div className={classes.leftCell}>
          {showCheckbox && (
            <Checkbox
              className={checkboxClassName}
              dataQeId={`${title}_Checkbox`}
              checked={selected}
              onChange={onToggleSelection}
            />
          )}
          {showExpand && (
            <div>
              <IconButton size="small" onClick={onToggleExpand}>
                {expanded ? <CollapseIcon /> : <ExpandIcon />}
              </IconButton>
            </div>
          )}
          {task && <TaskLink task={task} showNonLinkMode />}
        </div>
      </ResponsiveTableColumn>
      {Children.map(children, child => {
        if (!child) {
          return child;
        }
        const { size, flex, columnstyle } = child.props;

        return (
          <ResponsiveTableColumn
            className={classNames(
              classes.cell,
              zeroPadding ? classes.zeroPadding : classes.padding,
              zeroVerticalPadding && classes.zeroVerticalPadding,
              isHighlighted && classes.cellHighlighted,
              columnstyle,
              hideBorder && classes.cellBorder
            )}
            size={size}
            flex={flex}
          >
            {child}
          </ResponsiveTableColumn>
        );
      })}
    </ResponsiveTableRow>
  );
};

TaskRow.propTypes = {
  classes: PropTypes.object.isRequired,
  checkboxClassName: PropTypes.string,
  title: PropTypes.string,
  taskId: PropTypes.string,
  path: PropTypes.array,
  children: PropTypes.node.isRequired,
  zeroPadding: PropTypes.bool,
  zeroVerticalPadding: PropTypes.bool,
  selected: PropTypes.bool,
  onToggleSelection: PropTypes.func,
  showCheckbox: PropTypes.bool,
  showExpand: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  expanded: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  minHeight: PropTypes.bool,
  isMDView: PropTypes.bool,
  hideBorder: PropTypes.bool
};

export default withStyles(TaskRow);
