export const mapRole = role => ({
  role: {
    id: role.projectRole.uri,
    displayText: role.projectRole.name
  },
  isPrimary: role.isPrimary
});

export const primaryRoles = roleDetails => {
  const roles = roleDetails.filter(role => role.isPrimary === true);
  const noPrimaryRole = [{ role: null, isPrimary: true }];

  return roles && roles.length > 0
    ? roles.map(role => mapRole(role))
    : noPrimaryRole;
};

export const additionalRoles = roleDetails => {
  const roles = roleDetails.filter(role => role.isPrimary === false);

  return roles.map(role => mapRole(role));
};
