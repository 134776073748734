import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { RemoveCircleSharp } from '@material-ui/icons';
import TagValueDropdown from '~/modules/tags/components/Dropdowns/TagValueDropdown';
import FacetDetailField from '../../FacetDetailField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& > span': {
      color: theme.palette.text.primary
    },
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      height: 44,
      margin: '0 0 24px 0'
    }
  },
  select: {
    flexGrow: 1,
    flexShrink: 1,
    fontSize: theme.typography.caption.fontSize
  },
  tagValueContainer: {
    display: 'flex',
    width: '100%'
  }
}));

export const TagFacetDetails = ({
  className,
  selected,
  tagKey,
  label,
  onChange,
  onRemove,
  disableAttachment,
  menuPlacement
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const onSelect = useCallback(e => e.preventDefault(), []);

  const tagValue = selected && selected.label.split('+')[1];

  const transformSelected = {
    ...selected,
    label: tagValue,
    displayText: tagValue,
    key: tagValue
  };

  return (
    <FacetDetailField
      className={classes.root}
      onTouchEnd={onSelect}
      label={label}
    >
      <div className={classes.tagValueContainer}>
        <TagValueDropdown
          className={classes.select}
          disableAttachment={disableAttachment}
          menuPlacement={menuPlacement}
          variant="filled"
          data-qe-id="AddTagValueDropdown"
          tagId={tagKey}
          editable
          value={transformSelected}
          onChange={onChange}
        />
        <IconButton
          onClick={onRemove}
          aria-label={formatMessage({ id: 'button.remove' })}
        >
          <RemoveCircleSharp color="action" fontSize="small" />
        </IconButton>
      </div>
    </FacetDetailField>
  );
};

TagFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.object,
  tagKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  disableAttachment: PropTypes.bool,
  menuPlacement: PropTypes.string
};

export default TagFacetDetails;
