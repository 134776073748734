import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  input: {
    '& input': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.caption.fontSize,
      padding: theme.spacing(0.25, 0, 0.25, 0),
      '-moz-appearance': 'textfield',
      '&:hover, &:focus': {
        '-moz-appearance': 'number-input'
      }
    }
  },
  root: {
    display: 'flex',
    fontSize: theme.typography.caption.fontSize,
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    },
    '&:hover': {
      cursor: 'pointer',
      '&:hover': {
        background: alpha(theme.palette.action.active, 0.15)
      }
    }
  },
  userWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    width: 130,
    textAlign: 'left'
  },
  resouceAllocationCellClick: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    width: 130,
    textAlign: 'left'
  },
  loading: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  loadingContainer: {
    flexBasis: 25
  },
  atDivider: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(0.5)
  },
  icon: {
    height: '0.9em'
  },
  openButton: {
    display: 'none',
    opacity: 1,
    padding: theme.spacing(0.375, 0, 0, 0),
    margin: 0
  }
}));

export default useStyles;
