export const getUpdatedCostByCurrency = ({
  exchangeRateValues,
  newTotalCost,
  isAllocation
}) => {
  const updatedExchangeRateValues = Object.keys(exchangeRateValues).reduce(
    (acc, currency) => {
      if (exchangeRateValues[currency]) {
        const { rate } = exchangeRateValues[currency];
        const amount = rate * newTotalCost?.amount;

        acc[currency] = {
          currency: { id: exchangeRateValues[currency].currencyId },
          amount,
          __typename: 'MoneyValue'
        };
      } else {
        acc[currency] = null;
      }

      return acc;
    },
    {}
  );

  return {
    ...updatedExchangeRateValues,
    __typename: isAllocation
      ? 'AllocationTotalCostByCurrency'
      : 'ResourceRequestTotalCostByCurrency'
  };
};

export default getUpdatedCostByCurrency;
