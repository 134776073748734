import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, makeStyles, DialogContentText } from '@material-ui/core';
import RateCardIcon from '@material-ui/icons/AttachMoneySharp';
import EditableCard, {
  EditContent,
  Edit,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import {
  NoDataItem,
  RemoveEntityConfirmationDialog
} from '~/modules/common/components';
import {
  useFormOnChange,
  useFormState,
  useAdvancedRateCardEditableHandlers,
  useUpdateProjectRatesForDynamicRateTable
} from './hooks';
import { AdvancedRateCardEditorContent } from './AdvancedRateCardEditorContent';
import { AdvancedRateCardReadOnlyContent } from './AdvancedRateCardReadOnlyContent';
import { AdvancedRateCardActionComponent } from './AdvancedRateCardActionComponent';
import ViewSummaryButton from './ViewSummaryButton';

const useAdvancedRateCardStyles = makeStyles(theme => ({
  warning: {
    whiteSpace: 'pre-line'
  },
  rateCardSetup: {
    fontSize: theme.typography.h6.fontSize,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: 500,
    lineHeight: 1.6,
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  },
  content: { paddingTop: 0, paddingBottom: 0 },
  dialogActions: {
    display: 'block',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  }
}));

const useEditableCardStyles = makeStyles(theme => ({
  root: { paddingTop: 0 },
  card: {
    paddingBottom: 0,
    '& .MuiCardContent-root': {
      padding: '0px',
      '& div': {
        padding: theme.spacing(2.25, 3),
        borderBottom: `1px solid ${theme.palette.grey[300]}`
      }
    }
  },
  title: {}
}));

const useDialogCardStyles = makeStyles(theme => ({
  paper: { width: 'fit-content' }
}));

const resourceKeys = {
  remove: {
    header: `advancedRateCard.removeRateRow`,
    actionButton: 'advancedRateCard.removeRateRow'
  },
  removeAll: {
    header: `advancedRateCard.deleteAllConfirmationDialog.removeRate`,
    actionButton: 'advancedRateCard.deleteAllConfirmationDialog.removeRate'
  },
  message: {
    removeAll: 'advancedRateCard.deleteAllConfirmationDialog.message',
    removeGroup: 'advancedRateCard.removeRateRowMessage'
  }
};

const getMaxWidth = values => {
  const hasEffectiveDate = values?.projectRates.some(r => !r.isInitialRate);

  return values?.projectDimensions?.length > 2 && hasEffectiveDate
    ? 'xl'
    : !values?.projectDimensions?.length && hasEffectiveDate
    ? 'md'
    : !values?.projectDimensions?.length
    ? 'sm'
    : values.projectDimensions.length > 1
    ? 'lg'
    : 'md';
};

const avatar = <RateCardIcon />;

export const AdvancedRateCardEditable = ({
  projectCurrency,
  editable,
  projectRates,
  projectRateCount,
  availableRateTableDimensions,
  projectId,
  projectRateDimensions,
  projectRateRequiredDimensions,
  hasMore,
  loadingMore,
  loadMore,
  projectSlug,
  edit,
  setEdit,
  setRatesModifier,
  setIsSubmitting,
  viewSummary,
  onViewSummaryClick,
  projectPermissions,
  onCancelCallback
}) => {
  const classes = useAdvancedRateCardStyles();
  const editableClasses = useEditableCardStyles();
  const dialogClasses = useDialogCardStyles();

  const {
    updateProjectRatesForDynamicRateTable
  } = useUpdateProjectRatesForDynamicRateTable();

  const formik = useFormState({
    projectRateDimensions,
    projectRates,
    projectCurrency,
    loadingMore
  });

  const { values, setFieldValue, validateForm, errors } = formik;

  const formOnChange = useFormOnChange({
    setFieldValue,
    values,
    projectCurrency
  });

  const { formatMessage } = useIntl();

  const {
    dialogOpen,
    groupId,
    onSave,
    onCancel,
    openDeleteDialog,
    onDeleteGroup,
    onRemoveAllEntries,
    onCloseDialog,
    addNewEntry,
    setAddNewEntry,
    onAddEntryClick
  } = useAdvancedRateCardEditableHandlers({
    projectId,
    projectCurrency,
    formik,
    formOnChange,
    availableRateTableDimensions,
    projectRateDimensions,
    projectRateRequiredDimensions,
    updateProjectRatesForDynamicRateTable,
    setRatesModifier,
    setEdit,
    loadMore,
    hasMore,
    setIsSubmitting,
    onCancelCallback
  });

  const dialogActionClasses = useMemo(
    () => ({
      dialogActions: classes.dialogActions
    }),
    [classes.dialogActions]
  );

  const actionComponentCustomProps = useMemo(
    () => ({
      loadingMore,
      addButtonResourceKey: values.projectDimensions[0]?.displayText,
      onAddEntryClick,
      readOnly: viewSummary
    }),
    [loadingMore, onAddEntryClick, values.projectDimensions, viewSummary]
  );

  const { canEditBillingRates } = projectPermissions;

  const isEditable = useMemo(
    () => (viewSummary ? false : canEditBillingRates ? editable : false),
    [canEditBillingRates, editable, viewSummary]
  );

  return (
    <>
      <EditableCard
        editable={isEditable}
        edit={edit}
        maxWidth={getMaxWidth(values)}
        classes={editableClasses}
        editContentClassName={classes.content}
        dialogClasses={dialogClasses}
        dialogActionClasses={dialogActionClasses}
        ariaLabelKey="advancedRateCard.advancedRateCardDialog"
        dataQeId="RateCard"
      >
        {!edit && (
          <ReadOnly
            expandable={false}
            avatar={avatar}
            title={formatMessage({ id: 'rateCard.cardTitle' })}
          >
            <ReadOnlyContent>
              {projectRates?.length ? (
                <AdvancedRateCardReadOnlyContent
                  projectRateCount={projectRateCount}
                  projectDimensions={projectRateDimensions}
                />
              ) : (
                <div>
                  <NoDataItem dataQeId="RateCard">
                    <FormattedMessage id="advancedRateCard.noRates" />
                  </NoDataItem>
                </div>
              )}
              {projectRates?.length ? (
                <ViewSummaryButton onViewSummaryClick={onViewSummaryClick} />
              ) : null}
            </ReadOnlyContent>
          </ReadOnly>
        )}
        <Edit
          saveable
          onCancel={onCancel}
          onSave={onSave}
          onRemove={projectRates?.length > 0 ? openDeleteDialog : undefined}
          ActionComponent={AdvancedRateCardActionComponent}
          validateForm={validateForm}
          actionComponentCustomProps={actionComponentCustomProps}
        >
          <EditTitle>
            <Typography
              className={classes.rateCardSetup}
              data-qe-id="RateCardTitle"
              tabIndex={0}
            >
              <FormattedMessage
                id={
                  viewSummary
                    ? 'advancedRateCard.rateCardSummary'
                    : 'advancedRateCard.rateCardSetup'
                }
              />
            </Typography>
          </EditTitle>
          <EditContent>
            <AdvancedRateCardEditorContent
              projectSlug={projectSlug}
              projectDimensions={values.projectDimensions}
              projectRates={values.projectRates}
              availableRateTableDimensions={availableRateTableDimensions}
              formOnChange={formOnChange}
              hasMore={hasMore}
              loadingMore={loadingMore}
              loadMore={loadMore}
              errors={errors}
              openDeleteDialog={openDeleteDialog}
              addNewEntry={addNewEntry}
              setAddNewEntry={setAddNewEntry}
              projectCurrency={projectCurrency}
              readOnly={viewSummary}
            />
          </EditContent>
        </Edit>
      </EditableCard>
      {dialogOpen && (
        <RemoveEntityConfirmationDialog
          resourceKeys={groupId ? resourceKeys.remove : resourceKeys.removeAll}
          open={dialogOpen}
          onClose={onCloseDialog}
          onConfirm={groupId ? onDeleteGroup : onRemoveAllEntries}
          className={classes.warning}
        >
          <DialogContentText>
            <FormattedMessage
              id={
                groupId
                  ? resourceKeys.message.removeGroup
                  : resourceKeys.message.removeAll
              }
            />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};

AdvancedRateCardEditable.propTypes = {
  editable: PropTypes.bool,
  projectCurrency: PropTypes.object,
  projectRates: PropTypes.array,
  projectRateCount: PropTypes.number,
  availableRateTableDimensions: PropTypes.array,
  projectId: PropTypes.string,
  projectRateDimensions: PropTypes.array,
  loadingMore: PropTypes.bool,
  projectRateRequiredDimensions: PropTypes.array,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  projectSlug: PropTypes.string,
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  setRatesModifier: PropTypes.func,
  setIsSubmitting: PropTypes.func,
  viewSummary: PropTypes.bool,
  onViewSummaryClick: PropTypes.func,
  projectPermissions: PropTypes.object,
  onCancelCallback: PropTypes.func
};

export default AdvancedRateCardEditable;
