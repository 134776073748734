import { useFormik } from 'formik';
import { useMemo } from 'react';
import { resolveValueOrDefault } from '~/modules/common/components/FormCustomField';
import { isoStringToObject } from '~/modules/common/dates/convert';
import {
  TIME_AND_EXPENSE_ENTRY_TYPE,
  TASK_ESTIMATE_CALCULATION_TYPES
} from '~/modules/common/enums';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import buildValidationSchema from './buildValidationSchema';
import useOnSubmit from './useOnSubmit';

export const getInitialValues = ({
  baseCurrency,
  featureFlags,
  startDate,
  endDate,
  customFieldDefinitions,
  project,
  isMilestone = false,
  isMobile = false,
  hasBilling
}) => {
  const { defaultBillingCurrency } = project;
  const {
    isPsaPpmCostEacEnhancementsEnabled,
    PSAPRPTaskEstimateCalculation,
    isPsaPrpPsaPpmMergerEnabled,
    isPsaRmpTaskAllocation1Enabled
  } = featureFlags;
  const isTaskAssignmentsEnabled =
    PSAPRPTaskEstimateCalculation ===
    TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT;

  const copyParentTaskResourceAllocations = !(
    isTaskAssignmentsEnabled && !isMobile
  );

  const initialCostCurrency =
    (isPsaPpmCostEacEnhancementsEnabled && defaultBillingCurrency) ||
    baseCurrency;

  return {
    name: '',
    code: '',
    startDate: startDate ? isoStringToObject(startDate) : null,
    endDate: endDate ? isoStringToObject(endDate) : null,
    assignedUser: null,
    assignedRole: null,
    description: '',
    isMilestone,
    estimatedHours: '',
    estimatedCost: {
      amount: null,
      currency: {
        id: initialCostCurrency.id,
        displayText: initialCostCurrency.displayText
      }
    },
    isTimeEntryAllowed: true,
    timesheetAccess: [],
    timeAndExpenseEntryType: {
      id:
        (isPsaPrpPsaPpmMergerEnabled &&
          (!hasBilling ||
            project.billingType?.id ===
              'urn:replicon:billing-type:non-billable')) ||
        (!isPsaPrpPsaPpmMergerEnabled &&
          project.billingType?.id === 'urn:replicon:billing-type:non-billable')
          ? TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE
          : TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE
    },
    ...customFieldDefinitions.reduce((fields, definition) => {
      const customFieldValue = resolveValueOrDefault([], definition);

      return {
        ...fields,
        ...(customFieldValue !== null
          ? { [definition.uri]: customFieldValue }
          : {})
      };
    }, {}),
    assignedUserRoleId: null,
    copyParentTaskResourceAllocations,
    ...(isPsaRmpTaskAllocation1Enabled
      ? { resourceEstimates: [] }
      : { resourceAllocations: [] })
  };
};

const useTaskState = ({
  intl,
  baseCurrency,
  featureFlags,
  startDate,
  endDate,
  createTask,
  setMessage,
  onClose,
  validateTaskNameMutation,
  parentUri,
  customFieldDefinitions,
  project,
  isMilestone
}) => {
  const isMobile = useIsBreakpointDown('xs');
  const {
    isPsaPrpPsaPpmMergerEnabled,
    isPsaRmpTaskAllocation1Enabled
  } = featureFlags;
  const { projectTemplateSetting } = project;
  const { hasBilling } = useProjectTemplateSettings({
    projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const validationSchema = useMemo(
    () =>
      buildValidationSchema({
        intl,
        customFieldDefinitions,
        isPsaRmpTaskAllocation1Enabled
      }),
    [customFieldDefinitions, intl, isPsaRmpTaskAllocation1Enabled]
  );

  const onSubmit = useOnSubmit({
    validateTaskNameMutation,
    createTask,
    setMessage,
    onClose,
    parentUri,
    customFieldDefinitions,
    project
  });

  const formik = useFormik({
    validationSchema,
    initialValues: getInitialValues({
      baseCurrency,
      featureFlags,
      startDate,
      endDate,
      customFieldDefinitions,
      project,
      isMilestone,
      isMobile,
      hasBilling
    }),
    onSubmit,
    enableReinitialize: true
  });

  return formik;
};

export default useTaskState;
