import React from 'react';

import ProjectsQuickFilterSummary from '~/modules/common/components/ProjectsQuickFilterSummary';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';

export const ProjectsSummary = () => {
  const {
    projectType,
    searchCriteria,
    summaryFilterState
  } = useProjectsContext();

  return (
    <ProjectsQuickFilterSummary
      key={
        summaryFilterState.quickFilter &&
        summaryFilterState.quickFilter.filterByStatus
      }
      projectType={projectType}
      searchCriteria={searchCriteria}
      summaryFilterState={summaryFilterState}
    />
  );
};

export default ProjectsSummary;
