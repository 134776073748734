import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isoStringToObject } from '~/modules/common/dates/convert';
import {
  getEffectiveDateObject,
  compareDateObjects,
  dateAfterMinRepliconDate
} from '~/modules/common/dates/compare';
import { NoValue } from '~/modules/common/components';
import { ResourceRoleSchedule } from '~/modules/common/components/entities/role';

const useStyles = makeStyles(theme => ({
  role: {
    color: theme.palette.text.primary
  }
}));

export const RoleColumn = ({
  classes: classesOverride,
  record: resourceList
}) => {
  const classes = useStyles({ classes: classesOverride });
  const cssClasses = useMemo(() => ({ role: classes.role }), [classes.role]);

  const roleDates = (resourceList.effectiveRoles || []).map(
    role => role && isoStringToObject(role.effectiveDate)
  );

  const roles = resourceList.effectiveRoles || [];

  const effectiveDate =
    roleDates.length > 0 && getEffectiveDateObject(roleDates);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const effectiveSchedule = useMemo(
    () => ({
      effectiveDate: dateAfterMinRepliconDate(effectiveDate) && effectiveDate,
      projectRoles: effectiveDate
        ? (roles || []).filter(
            r =>
              r &&
              r.effectiveDate &&
              compareDateObjects(
                isoStringToObject(r.effectiveDate),
                effectiveDate
              ) === 0
          )
        : roles
    }),
    [effectiveDate, roles]
  );

  return roles.length > 0 ? (
    <ResourceRoleSchedule
      classes={cssClasses}
      effectiveSchedule={effectiveSchedule}
      showTooltip
      showEffectiveDate
    />
  ) : (
    <NoValue />
  );
};

RoleColumn.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired
};

export default RoleColumn;
