import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getStatusLabel } from '../BillingTransactionStatusBadge/util';

export const ChipLabel = (transactionType, balanceStatus, draftSubStatus) => {
  const intl = useIntl();

  if (draftSubStatus?.displayText) {
    const labelDraft = intl.formatMessage({
      id: 'billingTransactionList.statusLabel.draft'
    });

    return <span>{`${labelDraft}: ${draftSubStatus.displayText}`}</span>;
  }

  return (
    <FormattedMessage id={getStatusLabel(transactionType, balanceStatus)} />
  );
};
