import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { deepPure } from '~/util';
import { RoleName } from '~/modules/common/components/entities/role';
import { CreatableOption } from '~/modules/common/components/SearchAutocomplete';

const getRoleFromOption = option => ({
  displayText: option.displayText,
  symbol: option.currentRate?.currency?.displayText,
  amount: option.currentRate?.amount
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'start',
    margin: theme.spacing(0, -2),
    padding: theme.spacing(0, 2, 0, 0)
  },
  icon: {
    padding: theme.spacing(1),
    minWidth: 42
  }
}));

export const RoleDropdownItem = ({ option, onCreate }) => {
  const { isCreatable } = option;
  const classes = useStyles();
  const role = getRoleFromOption(option);

  return isCreatable ? (
    <CreatableOption
      classes={classes}
      option={option}
      onCreate={onCreate}
      optionPropText="displayText"
      primaryLabelTextId="addRoleDialog.role"
      showLabel={false}
    />
  ) : (
    <RoleName
      data-qe-id={`${option.displayText}_MenuItem`}
      role={role}
      showRate={false}
    />
  );
};

RoleDropdownItem.propTypes = {
  option: PropTypes.object,
  onCreate: PropTypes.func
};

export default deepPure(RoleDropdownItem);
