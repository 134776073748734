import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const TASKS_STATUS_COUNT_SUMMARY_QUERY = gql`
  query GetTasksStatusCountSummary($projectId: String!) {
    tasksStatusCountSummary(projectId: $projectId) {
      notStartedTasksCount
      inProgressTasksCount
      completedTasksCount
    }
  }
`;

export const useTimeEnteredTasksSummary = projectId =>
  useQuery(TASKS_STATUS_COUNT_SUMMARY_QUERY, {
    variables: {
      projectId
    },
    fetchPolicy: 'network-only'
  });

export default useTimeEnteredTasksSummary;
