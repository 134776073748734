import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  bubble: {
    margin: theme.spacing(2)
  },
  legends: {
    display: 'flex',
    padding: theme.spacing(2, 2, 0, 2),
    justifyContent: 'flex-end'
  }
}));

export const SkillsDistributionBubbleChartLoading = ({
  showLegends = true
}) => {
  const classes = useStyles();

  return (
    <>
      {showLegends && (
        <Typography variant="body1" className={classes.legends}>
          <Skeleton width="60%" />
        </Typography>
      )}
      <Skeleton
        height={350}
        variant="rect"
        classes={useMemo(() => ({ root: classes.bubble }), [classes.bubble])}
      />
    </>
  );
};

SkillsDistributionBubbleChartLoading.propTypes = {
  showLegends: PropTypes.bool
};

export default SkillsDistributionBubbleChartLoading;
