import { useState, useEffect } from 'react';

export const useExpenseEditDialogSetting = ({ expenseCardSettings }) => {
  const [hideColumn, setHideColumn] = useState(false);

  useEffect(() => {
    if (!expenseCardSettings?.loading)
      setHideColumn(expenseCardSettings?.showActuals);
  }, [expenseCardSettings]);

  return {
    hideColumn,
    setHideColumn
  };
};

export default useExpenseEditDialogSetting;
