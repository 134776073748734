import { useIntl } from 'react-intl';

export const itemTypes = intl => [
  {
    id: 'FIXED_BID',
    label: intl.formatMessage({ id: 'itemTypeLabels.fixedBid' })
  },
  {
    id: 'TIMESHEET',
    label: intl.formatMessage({ id: 'itemTypeLabels.timesheet' })
  },
  {
    id: 'EXPENSE',
    label: intl.formatMessage({ id: 'itemTypeLabels.expense' })
  }
];

const mappedBillingItemType = intl =>
  itemTypes(intl).map(itemType => ({
    ...itemType,
    value: itemType.id,
    label: itemType.label,
    key: itemType.label,
    name: itemType.label
  }));

export const useBillingItemType = intl => ({
  searchBillingItemType: mappedBillingItemType(intl)
});

export const searchBillingItemType = intl => (searchTerm = '') =>
  mappedBillingItemType(intl).filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const useSearchableBillingItemType = () => ({
  searchBillingItemType: searchBillingItemType(useIntl())
});

export default useSearchableBillingItemType;
