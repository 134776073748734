import { makeStyles } from '@material-ui/core';

const useVictoryContainerStyles = makeStyles(theme => ({
  container: {
    '& > .VictoryContainer': {
      touchAction: 'auto!important', // fix mobile scrolling when touch action happens on chart
      '& > svg': {
        overflow: 'visible'
      }
    }
  }
}));

export default useVictoryContainerStyles;
