import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const PUT_PROJECT_REQUEST = gql`
  mutation putProjectRequest($input: ProjectRequestInput!) {
    putProjectRequest(input: $input) {
      projectRequestUri
    }
  }
`;

export const usePutProjectRequest = () => {
  const [putProjectRequest] = useMutation(PUT_PROJECT_REQUEST);

  return {
    putProjectRequest: input => {
      return putProjectRequest({
        variables: {
          input
        },
        refetchQueries: [
          'getPageOfProjectRequests',
          'Eager_GetProjectRequestsCountSummary'
        ]
      });
    }
  };
};

export default usePutProjectRequest;
