import React from 'react';
import PropTypes from 'prop-types';
import { LocationIcon } from '~/modules/common/components';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupIconField from './GroupIconField';

export const ResourceRequestLocationField = ({ value, locationSettings }) => (
  <GroupIconField
    value={value}
    GroupIcon={LocationIcon}
    groupSettings={locationSettings}
    labelKey="resourceRequestItem.location"
  />
);

ResourceRequestLocationField.propTypes = {
  value: PropTypes.object,
  locationSettings: PropTypes.object.isRequired
};
export default withGroupSettings(
  'location',
  'locationSettings'
)(ResourceRequestLocationField);
