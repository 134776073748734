import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { TableHeaderPagination } from '~/modules/resourcing/common/components';
import TableSortLabel from '~/modules/common/components/ListTable/components/DataTable/components/TableSortLabel';

const HeaderContentModel0 = ({
  classes,
  resourceSortField,
  sort,
  onSortChange,
  loadingUsers,
  paginationClasses,
  currentPage,
  setCurrentPage,
  pageSize,
  currentPageRowCount
}) => (
  <>
    <TableSortLabel
      className={classes.tabelCell}
      field={resourceSortField}
      show
      active={sort?.field === resourceSortField}
      direction={sort && sort.direction && sort.direction.toLowerCase()}
      onClick={onSortChange}
    >
      <FormattedMessage id="resourceRequestChartHeader.resources" />
    </TableSortLabel>
    <div className={classes.role}>
      <FormattedMessage id="resourceRequestChartHeader.role" />
    </div>
    {!loadingUsers ? (
      <TableHeaderPagination
        classes={paginationClasses}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        isPastPaginationLimit={false}
        currentPageRowCount={currentPageRowCount}
      />
    ) : null}
  </>
);

HeaderContentModel0.propTypes = {
  classes: PropTypes.object,
  resourceSortField: PropTypes.string,
  sort: PropTypes.object,
  onSortChange: PropTypes.func,
  loadingUsers: PropTypes.bool,
  paginationClasses: PropTypes.object,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageSize: PropTypes.number,
  currentPageRowCount: PropTypes.number
};

export default HeaderContentModel0;
