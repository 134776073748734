import { useCallback, useState } from 'react';
import {
  mapToBillPaymentsInput,
  mapToDeleteBillPaymentsInput
} from './mapperUtil';

const resourceKeys = ({ intl }) => ({
  paymentPanelResourceKeys: {
    title: 'billDetails.payments',
    allocatedAmount: 'billDetails.allocatedAmount'
  },
  paymentTableResourceKeys: {
    noDataMessage: 'billDetails.noAssociatedPayments',
    removeLabel: 'billDetails.removePayment',
    header: 'billDetails.removePayment',
    removeMessage: 'billDetails.removePaymentMessage',
    actionButton: 'billDetails.removePayment',
    creditColumnSubHeader: 'billDetails.paymentCurrency',
    deleteCredit: intl.formatMessage({ id: 'billDetails.removePayment' })
  },
  paymentAssignButtonResourceKeys: {
    idColumn: 'billDetails.payment',
    balanceColumn: 'billDetails.balance',
    addButton: 'billDetails.payment'
  }
});

const refundResourceKeys = ({ intl }) => ({
  paymentPanelResourceKeys: {
    title: 'billDetails.refunds',
    allocatedAmount: 'billDetails.allocatedAmount'
  },
  paymentTableResourceKeys: {
    noDataMessage: 'billDetails.noAssociatedRefunds',
    removeLabel: 'billDetails.removeRefund',
    header: 'billDetails.removeRefund',
    removeMessage: 'billDetails.removeRefundMessage',
    actionButton: 'billDetails.removeRefund',
    creditColumnSubHeader: 'billDetails.paymentCurrency',
    deleteCredit: intl.formatMessage({ id: 'billDetails.removeRefund' })
  },
  paymentAssignButtonResourceKeys: {
    idColumn: 'billDetails.refund',
    balanceColumn: 'billDetails.balance',
    addButton: 'billDetails.refund'
  }
});

export default ({
  intl,
  billId,
  billCurrencyId,
  billBalanceTotal,
  associatedBillPayments,
  putBillPaymentsForBill,
  isRefund
}) => {
  const [
    isPaymentAssignmentInProgress,
    setPaymentAssignmentInProgress
  ] = useState(false);

  const handleDeletePaymentClick = useCallback(
    async billPayment => {
      setPaymentAssignmentInProgress(true);
      await putBillPaymentsForBill(
        mapToDeleteBillPaymentsInput({
          billId,
          associatedBillPayments,
          selectedBillPayment: billPayment
        })
      );
      setPaymentAssignmentInProgress(false);
    },
    [associatedBillPayments, billId, putBillPaymentsForBill]
  );

  const handlePaymentClick = useCallback(
    async billPayment => {
      setPaymentAssignmentInProgress(true);
      await putBillPaymentsForBill(
        mapToBillPaymentsInput({
          billId,
          billBalanceTotal,
          associatedBillPayments,
          selectedBillPayment: billPayment
        })
      );
      setPaymentAssignmentInProgress(false);
    },
    [associatedBillPayments, billBalanceTotal, billId, putBillPaymentsForBill]
  );

  const keys = isRefund ? refundResourceKeys({ intl }) : resourceKeys({ intl });

  return {
    isPaymentAssignmentInProgress,
    ...keys,
    handlePaymentClick,
    handleDeletePaymentClick,
    hasMutiCurrencyAssociationsForPayment: associatedBillPayments.some(
      payment =>
        payment.allocatedBillPaymentAmount.currency.id !== billCurrencyId
    )
  };
};
