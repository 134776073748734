/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

export const blockNavigation = event => {
  event.preventDefault();

  // chrome requires returnValue to be set
  event.returnValue = '';
};

export const useBlockExternalNavigation = when => {
  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', blockNavigation);
    } else {
      window.removeEventListener('beforeunload', blockNavigation);
    }
  }, [when]);
};

export default useBlockExternalNavigation;
