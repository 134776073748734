import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_PROJECTS_QUERY = gql`
  query GetProjectsFilteredByResourceRequestStatus(
    $paginationToken: PaginationTokenInput
    $pageSize: Int!
    $projectFilter: ProjectFilter
    $resourceRequestStatus: ResourceRequestStatus
  ) {
    projectsFilteredByResourceRequestStatus(
      paginationToken: $paginationToken
      pageSize: $pageSize
      projectFilter: $projectFilter
      resourceRequestStatus: $resourceRequestStatus
    ) {
      resourceRequestsProjects {
        project {
          id
          displayText
          startDate
          endDate
          slug
          projectManagementType
        }
        requestCounts
      }
      paginationToken
    }
  }
`;

export const useGetProjectsFilteredByResourceRequestStatusQuery = options =>
  useQuery(GET_PROJECTS_QUERY, options);
