import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import Decimal from '../Decimal';
import useAbbreviatedProps from './useAbbreviatedProps';

export const AbbreviatedDecimal = ({
  fixedDecimalScale = false,
  value,
  dataQeId,
  suffixEnd,
  tooltipTitle,
  ...otherProps
}) => {
  const intl = useIntl();
  const { scaledNumber, abbreviatedSuffix } = useAbbreviatedProps({
    value,
    intl,
    suffixEnd
  });

  const decimal = (
    <Decimal
      dataQeId={dataQeId && `${dataQeId}_AbbreviatedDecimal`}
      {...otherProps}
      value={scaledNumber}
      suffix={abbreviatedSuffix}
      fixedDecimalScale={fixedDecimalScale}
    />
  );

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>{decimal}</Tooltip>
  ) : (
    decimal
  );
};

AbbreviatedDecimal.propTypes = {
  value: PropTypes.number,
  otherProps: PropTypes.object,
  fixedDecimalScale: PropTypes.bool,
  dataQeId: PropTypes.string,
  suffixEnd: PropTypes.string,
  tooltipTitle: PropTypes.node
};

export default AbbreviatedDecimal;
