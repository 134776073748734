import { useCallback } from 'react';
import { useUpdateCoManagers } from './hooks';

export const onSubmit = ({ closeDialog, updateCoManagers }) => async (
  values,
  { setSubmitting }
) => {
  await updateCoManagers({
    projectUri: values.projectUri,
    coManagers: values.coManagers
  });
  closeDialog();
  setSubmitting(false);
};

export const useUpdateCoManagersHandler = ({ projectSlug, closeDialog }) => {
  const { updateCoManagers } = useUpdateCoManagers({ projectSlug });

  return useCallback(
    onSubmit({
      closeDialog,
      updateCoManagers
    })
  );
};
