import { makeStyles } from '@material-ui/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(3),
    userSelect: 'none',
    backgroundColor: theme.palette.resourceRequest.pending.main,
    zIndex: 1,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(theme.palette.resourceRequest.pending.main, 0.1)
    },
    flexShrink: 0
  },
  root: {
    position: 'absolute',
    cursor: 'pointer',
    height: theme.spacing(2.5),
    zIndex: 3,
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  containerCommitted: {
    backgroundColor: theme.palette.resourceRequest.complete.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(theme.palette.resourceRequest.complete.main, 0.1)
    }
  },
  containerProposed: {
    backgroundColor: theme.palette.resourceRequest.proposed.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(theme.palette.resourceRequest.proposed.main, 0.1)
    }
  },
  containerRejected: {
    backgroundColor: theme.palette.resourceRequest.resourceRejected.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(
        theme.palette.resourceRequest.resourceRejected.main,
        0.1
      )
    }
  },
  resizeHandle: {
    top: theme.spacing(0),
    position: 'absolute',
    zIndex: 1,
    cursor: 'ew-resize',
    opacity: 0,
    overflow: 'hidden',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  resizeHandleDisplay: {
    display: 'none'
  },
  resizeIcon: {
    top: theme.spacing(0.25)
  },
  resizeHandleStart: {
    left: theme.spacing(-0.5)
  },
  resizeHandleEnd: {
    left: `calc(100% - ${theme.spacing(2.5)}px)`
  }
}));
export default useStyles;
