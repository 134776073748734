import { gql } from 'graphql-tag';
import { SpecificResourceRequestFragmentDoc } from '~/types';
import specificResourceAllocationFragment from './specificResourceAllocationFragment';

const specificResourceRequestWithAllocationsFragment = gql`
  fragment SpecificResourceRequestWithAllocation on ResourceRequest {
    ...SpecificResourceRequest
    resourceAllocations {
      ...SpecificResourceAllocation
    }
  }
  ${SpecificResourceRequestFragmentDoc}
  ${specificResourceAllocationFragment}
`;

export default specificResourceRequestWithAllocationsFragment;
