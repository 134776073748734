import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1
  },
  last: {
    minWidth: theme.spacing(2)
  }
}));

const SummaryTabsSpacer = ({ className, last }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, className, { [classes.last]: !last })}
    />
  );
};

SummaryTabsSpacer.propTypes = {
  last: PropTypes.bool.isRequired,
  className: PropTypes.string
};

export default SummaryTabsSpacer;
