import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import FacetDetailField from '../../FacetDetailField';
import useStyles from '../useStyles';
import ProjectItem from './ProjectItem';

const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : (
    <ProjectItem value={option.displayText} />
  );

export const GenericProjectFacetDetails = ({
  classes: classesOverride,
  className,
  onChange,
  options,
  loading,
  setSearchTerm,
  selected,
  hasMore,
  searchFacetClasses
}) => {
  const classes = useStyles({ classes: classesOverride || {} });
  const { formatMessage } = useIntl();

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );

  const projectOptions = !loading ? options : [];

  return (
    <FacetDetailField
      label={formatMessage({ id: 'search.project' })}
      className={className}
      classes={searchFacetClasses}
    >
      <SimpleAutocomplete
        options={projectOptions}
        loading={loading}
        classes={classes}
        getOptionDisabled={getOptionDisabled}
        onChange={onChange}
        value={selected}
        inputLabel={formatMessage({ id: 'search.project' })}
        variant="standard"
        onInputChange={onInputChange}
        optionPropText="label"
        hiddenLabel
        fullWidth
        renderOption={renderOption}
        multiple
        noOptionsText={formatMessage({ id: 'projectFacetDetail.noProject' })}
        label={formatMessage({ id: 'projectFacetDetail.search' })}
        hasMore={hasMore}
        optionTypeText={formatMessage({
          id: 'moreOptions.projects'
        })}
      />
    </FacetDetailField>
  );
};

GenericProjectFacetDetails.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  hasMore: PropTypes.bool,
  searchFacetClasses: PropTypes.object
};

export default GenericProjectFacetDetails;
