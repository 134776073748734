import React, { useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, TextField } from '@material-ui/core';
import { connect } from 'formik';
import { getError } from '~/util';
import { CustomField } from '~/modules/customFields/components';
import {
  getNameAndOnChangeHandlerProps,
  getErrorAndHelperTextProps
} from '~/modules/customFields/utils';
import { GridItem } from '~/modules/common/components';
import { ClientManagerSelect } from '~/modules/clients/components';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { FormErrorContext } from './FormErrorContext';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const AddClientDialogForm = ({
  showClientManager,
  clientManagers,
  isCodeRequired,
  isNameRequired,
  formik: { handleChange, setFieldValue, values, errors }
}) => {
  const { formatMessage } = useIntl();

  const label = useMemo(
    () => ({
      name: formatMessage({ id: 'client.name' }),
      code: formatMessage({ id: 'client.code' })
    }),
    [formatMessage]
  );

  const { name, clientManager, customFieldValues, code } = values;

  const { error: submitError, clearError: clearDuplicateNameError } =
    useContext(FormErrorContext) || {};

  const handleNameChange = useCallback(
    event => {
      handleChange(event);
      clearDuplicateNameError();
    },
    [handleChange, clearDuplicateNameError]
  );

  const handleCodeChange = useCallback(
    event => {
      setFieldValue('code', event.target.value);
    },
    [setFieldValue]
  );

  const handleClientManagerChange = useCallback(
    value => {
      setFieldValue('clientManager', value);
    },
    [setFieldValue]
  );

  const nameError = getError(errors, 'name');
  const codeError = getError(errors, 'code');

  return (
    <>
      {!nameError && !codeError && submitError && (
        <FormErrorAlert errorMessage={submitError} />
      )}
      <Grid container>
        <GridItem fullWidth>
          <TextField
            name="name"
            variant="filled"
            label={label.name}
            value={name}
            fullWidth
            onChange={handleNameChange}
            autoFocus
            error={Boolean(nameError)}
            helperText={nameError}
            required={isNameRequired}
            inputProps={{
              ...getAriaLabel(label.name),
              'data-qe-id': `ClientNameInput`
            }}
          />
        </GridItem>
        <GridItem fullWidth>
          <TextField
            name="name"
            variant="filled"
            label={label.code}
            value={code}
            fullWidth
            onChange={handleCodeChange}
            error={Boolean(codeError)}
            helperText={codeError}
            required={isCodeRequired}
            inputProps={{
              ...getAriaLabel(label.code),
              'data-qe-id': `ClientCodeInput`
            }}
          />
        </GridItem>
        {Boolean(showClientManager) && (
          <GridItem fullWidth>
            <ClientManagerSelect
              clientManagers={clientManagers}
              value={clientManager}
              onChange={handleClientManagerChange}
            />
          </GridItem>
        )}
        {Object.entries(customFieldValues).map(([key, customFieldValue]) => {
          const {
            name: customFieldName,
            onChange
          } = getNameAndOnChangeHandlerProps({
            handleChange,
            setFieldValue,
            path: 'customFieldValues',
            customFieldValue,
            key
          });

          const { error, helperText } = getErrorAndHelperTextProps({
            errors,
            customFieldValue,
            path: 'customFieldValues'
          });

          return (
            <GridItem key={key} fullWidth>
              <CustomField
                compact
                variant="filled"
                fullWidth
                editable
                customFieldValue={customFieldValue}
                name={customFieldName}
                onChange={onChange}
                error={error}
                helperText={helperText}
              />
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
};

AddClientDialogForm.propTypes = {
  showClientManager: PropTypes.bool,
  isCodeRequired: PropTypes.bool,
  isNameRequired: PropTypes.bool,
  clientManagers: PropTypes.array,
  formik: PropTypes.object
};

export default connect(AddClientDialogForm);
