import { Grid, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateField, ReadOnlyField } from '~/modules/common/components';
import {
  AssociatedBillsTable,
  DrawerExpansionPanel,
  LineItemsTable
} from '~/modules/billing-invoicing/common/components';
import { useIsMultiCurrency } from '~/modules/billing-invoicing/common/hooks';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    margin: 0,
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderBottom: `1px solid ${theme.palette.table.border}`,
    minHeight: '-webkit-max-content'
  },
  display: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  }
}));

const lineItemsPanelResourceKeys = {
  title: 'creditMemoDetails.credits',
  allocatedAmount: 'creditMemoDetails.allocatedAmount'
};

const associatedBillsPanelResourceKeys = {
  title: 'creditMemoDetails.outstandingBills',
  allocatedAmount: 'billPaymentDetails.allocatedAmount'
};

const resourceLabels = {
  referenceNumber: <FormattedMessage id="billPaymentDetails.referenceNumber" />,
  creditDate: <FormattedMessage id="creditMemoDetails.creditDate" />,
  description: <FormattedMessage id="billPaymentDetails.description" />,
  currency: <FormattedMessage id="creditMemoDetails.currency" />
};

export const CreditMemoReadOnly = ({
  values,
  associatedBillsTotal,
  lineItemsTotal,
  associatedAndOutstandingBills,
  billId,
  onAssociatedBillAmountChange,
  showAllBills = true,
  hasMore,
  loadMore
}) => {
  const classes = useStyles();
  const {
    client,
    displayId,
    description,
    creditDate,
    currency,
    lineItems
  } = values;
  const [showAllOutstandingBills, setShowAllOutstandingBIlls] = useState(
    showAllBills
  );
  const [creditsExpanded, setCreditsExpanded] = useState(true);
  const [billsExpanded, setBillsExpanded] = useState(true);
  const filteredAssociatedAndOutstandingBills = React.useMemo(
    () => associatedAndOutstandingBills.filter(bill => bill.amount.amount > 0),
    [associatedAndOutstandingBills]
  );
  const { isMultiCurrency } = useIsMultiCurrency({
    values,
    filteredAssociatedAndOutstandingBills
  });

  const associatedBillsTableResourceKeys = useMemo(
    () => ({
      noDataMessage: `creditMemoDetails.${
        client && client.id ? 'noOutstandingBills' : 'selectClient'
      }`
    }),
    [client]
  );

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={6}>
        <ReadOnlyField
          className={classes.display}
          label={resourceLabels.referenceNumber}
          value={displayId}
          dataQeId="creditMemoDetails.referenceNumber"
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          variant="standard"
          fullWidth
          clearable={false}
          label={resourceLabels.creditDate}
          value={creditDate}
          editable={false}
          underline={false}
          dataQeId="creditMemoDetails.creditDate"
        />
      </Grid>
      {description && (
        <Grid item xs={6}>
          <ReadOnlyField
            className={classes.display}
            label={resourceLabels.description}
            value={description}
            data-qe-id="creditMemoDetails.description"
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <div className={classes.display}>
          <ReadOnlyField
            label={resourceLabels.currency}
            value={currency.symbol}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={lineItemsPanelResourceKeys}
          amountTotal={lineItemsTotal}
          expanded={creditsExpanded}
          setExpanded={setCreditsExpanded}
        >
          {lineItems.length && (
            <LineItemsTable
              lineItems={lineItems}
              lineItemsTotal={lineItemsTotal}
              hasMore={hasMore}
              loadMore={loadMore}
            />
          )}
        </DrawerExpansionPanel>
      </Grid>
      <Grid item xs={12}>
        <DrawerExpansionPanel
          resourceKeys={associatedBillsPanelResourceKeys}
          amountTotal={associatedBillsTotal}
          expanded={billsExpanded}
          setExpanded={setBillsExpanded}
        >
          <AssociatedBillsTable
            associatedBills={filteredAssociatedAndOutstandingBills}
            onAmountChange={onAssociatedBillAmountChange}
            amountTotal={associatedBillsTotal}
            resourceKeys={associatedBillsTableResourceKeys}
            billId={billId}
            showAllOutstandingBills={showAllOutstandingBills}
            setShowAllOutstandingBIlls={setShowAllOutstandingBIlls}
            editable={false}
            isMultiCurrency={isMultiCurrency}
          />
        </DrawerExpansionPanel>
      </Grid>
    </Grid>
  );
};

CreditMemoReadOnly.propTypes = {
  values: PropTypes.object.isRequired,
  associatedBillsTotal: PropTypes.object.isRequired,
  lineItemsTotal: PropTypes.object.isRequired,
  associatedAndOutstandingBills: PropTypes.array,
  onAssociatedBillAmountChange: PropTypes.func,
  billId: PropTypes.string,
  showAllBills: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func
};

export default CreditMemoReadOnly;
