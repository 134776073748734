import { useColumns } from '~/modules/common/components/ListTable';
import formatters from '../formatters';
import tag from '../formatters/tag';
import { buildColumns } from './columns';

export const tagFormatters = tags =>
  (tags || []).reduce(
    (obj, current) => ({
      ...obj,
      [current.id]: tag
    }),
    {}
  );

export const useListColumns = ({
  classes,
  selectedColumns,
  billingRows,
  canAddPayment,
  selectedTransactionItems,
  allSelectedStatus,
  onRowSelect,
  onSelectAll,
  tags,
  refetchBillingTransactionRows
}) => {
  return useColumns({
    columns: buildColumns({
      classes,
      selectedColumns,
      billingRows,
      editable: canAddPayment,
      selectedTransactionItems,
      allSelectedStatus,
      onRowSelect,
      onSelectAll,
      tags,
      refetchBillingTransactionRows
    }),
    renders: { ...formatters, ...tagFormatters(tags) }
  });
};
