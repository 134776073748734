import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  SimpleAutocomplete,
  NoneDropdownItem
} from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import useRoles from '~/modules/common/hooks/useRoles';
import FacetDetailField from '../../FacetDetailField';
import { onChange } from '../enhancers/facetHelpers';
import useStyles from '../useStyles';
import RoleItem from './RoleBarItem';

export const RoleFacetDetails = ({
  className,
  selected,
  setSelected,
  labelResourceKey,
  includeNoneOption = true
}) => {
  const { formatMessage } = useIntl();

  const label = formatMessage({ id: labelResourceKey });
  const noneOptionText = formatMessage({ id: 'rolesDropdown.noRole' });

  const renderOption = useCallback(
    option =>
      option.key === formatMessage({ id: 'NONE' }) ? (
        <NoneDropdownItem value={option.displayText} />
      ) : option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <RoleItem value={option.displayText} />
      ),
    [formatMessage]
  );

  const [searchTerm, setSearchTerm] = useState('');

  const onInputChange = useCallback((_, val) => setSearchTerm(val), [
    setSearchTerm
  ]);

  const { isLoading: loading, hasMore, defaultOptions } = useRoles({
    searchTerm,
    noneOptionText,
    includeNoneOption,
    includeCurrentRate: false
  });

  const roles = defaultOptions.map(option => ({
    ...option,
    value: option.id,
    label: option.displayText,
    key: option.key || option.displayText
  }));

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const getOptionDisabled = useCallback(
    option =>
      selected.some(v => v.id === option.id) ||
      option.id === MORE_AVAILABLE_OPTION_ID,
    [selected]
  );
  const roleValues = loading ? [] : roles;

  const classes = useStyles();

  return (
    <FacetDetailField label={label} className={className}>
      <SimpleAutocomplete
        loading={loading}
        options={roleValues}
        inputLabel={formatMessage({ id: 'search.role' })}
        value={selected}
        variant="standard"
        onInputChange={onInputChange}
        onChange={onValueChange}
        multiple
        fullWidth
        classes={classes}
        hiddenLabel
        noOptionsText={noneOptionText}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        label={label}
        optionPropText="label"
        optionTypeText={formatMessage({ id: 'moreOptions.roles' })}
        hasMore={hasMore}
      />
    </FacetDetailField>
  );
};

RoleFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  labelResourceKey: PropTypes.string.isRequired,
  includeNoneOption: PropTypes.string
};

export default RoleFacetDetails;
