import { ButtonBase, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  projectRequestButton: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    paddingTop: theme.spacing(0.5),
    whiteSpace: 'nowrap'
  }
}));

export const NameColumn = ({ record }) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.projectRequestButton}
      component={Link}
      to={`#/request/${record.id}`}
    >
      <Typography className={classes.name}>{record.name}</Typography>
    </ButtonBase>
  );
};
NameColumn.propTypes = {
  record: PropTypes.object
};
export default NameColumn;
