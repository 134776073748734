import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[0],
    transition: theme.transitions.create(['margin', 'box-shadow'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  rows: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0, 0, 0, 1),
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  rootExpanded: {
    margin: theme.spacing(0, 0, 1, 0),
    boxShadow: theme.shadows[4]
  },
  addRow: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    justifyContent: 'space-between',
    textOverflow: 'ellipsis',
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(1)}px`
  },
  bottomRow: {
    display: 'flex',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 0, 1, 0),
    flexDirection: 'row',
    width: theme.spacing(44)
  },
  addProjectButton: {
    display: 'flex',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    justifyContent: 'flex-start',
    flexGrow: '1'
  },
  showMoreButton: {
    display: 'flex',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    justifyContent: 'flex-end'
  },
  showMoreButtonText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightLight
  },
  icon: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0),
    fontSize: theme.typography.subtitle2.fontSize,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(0.5)
    }
  },
  noneMessage: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    padding: theme.spacing(2)
  }
}));

export const useSkeletonStyles = makeStyles(() => ({
  leftComponent: {
    width: 350,
    top: 86
  },
  rightComponent: {
    width: 350,
    top: 86
  }
}));

export default useStyles;
