import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  column: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  }
}));

export const AdhocDescriptionFormatter = ({ field, record }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.column} variant="body2">
      {record[field]}
    </Typography>
  );
};

AdhocDescriptionFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default AdhocDescriptionFormatter;
