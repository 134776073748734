import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

const ShowClosedTasksCheckbox = ({ checked, onClick, className }) => {
  const intl = useIntl();
  const checkbox = useMemo(
    () => <Checkbox checked={checked} onChange={onClick} color="primary" />,
    [checked, onClick]
  );

  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={checkbox}
        labelPlacement="start"
        label={intl.formatMessage({ id: 'tasks.includeClosedTasks' })}
      />
    </FormGroup>
  );
};

ShowClosedTasksCheckbox.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string
};

export default ShowClosedTasksCheckbox;
