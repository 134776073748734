import { useState } from 'react';

export const useSaving = () => {
  const [saving, setSaving] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  return {
    saving,
    submitError,
    setSaving,
    setSubmitError
  };
};

export default useSaving;
