export const FILENAME_URI = 'urn:replicon:binary-object-keyvalue-key:file-name';
export const FILESIZE_URI = 'urn:replicon:binary-object-keyvalue-key:file-size';
export const UPLOADED_ON_URI =
  'urn:replicon:binary-object-keyvalue-key:file-uploaded-on';
export const OWNER_URI = 'urn:replicon:binary-object-keyvalue-key:owner';
export const PROJECT_URI =
  'urn:replicon:binary-object-keyvalue-key:project-uri';
export const USER_URI = 'urn:replicon:binary-object-keyvalue-key:user-uri';
export const DOCUMENT_TYPE_URI =
  'urn:replicon:binary-object-keyvalue-key:document-type';

export const LINK_SOURCE_URI = 'urn:replicon:psa-attachment-type:link';
export const FILE_SOURCE_URI = 'urn:replicon:psa-attachment-type:binary-object';
