import React from 'react';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

import { createPropPassingContext } from '~/util';

export const currenciesQuery = gql`
  query getCurrencies {
    currencies {
      id
      uri
      name
      symbol
      displayText
    }
  }
`;

const [withCurrencyContextProvider, withCurrencies] = createPropPassingContext(
  'currencies'
);

export const injectCurrencies = BaseComponent => props => {
  const { data } = useQuery(currenciesQuery);
  const currencies = get(data, 'currencies', []);

  return (
    <BaseComponent {...props} {...withCurrencyContextProvider(currencies)} />
  );
};

export default withCurrencies;
