import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { clientPageHeaderFragment } from '~/modules/clients/client/graphql';

export const DELETE_CLIENT_MUTATION = gql`
  mutation DeleteClient($id: String!) {
    deleteClient2(id: $id) {
      id
      status
    }
  }
`;

export const updateCache = clientId => (proxy, { data }) => {
  if (data && data.deleteClient2) {
    const {
      deleteClient2: { status }
    } = data;

    const fragmentId = `Client:${clientId}`;

    const client = proxy.readFragment({
      id: fragmentId,
      fragment: clientPageHeaderFragment
    });

    proxy.writeFragment({
      id: fragmentId,
      fragment: clientPageHeaderFragment,
      data: {
        ...client,
        isActive: status !== 'DISABLED'
      }
    });
  }
};

export const useDeleteClient = ({ objectId: clientId }) => {
  const [deleteClient] = useMutation(DELETE_CLIENT_MUTATION, {
    update: updateCache(clientId)
  });

  return deleteClient;
};
