import { useState, useCallback } from 'react';
import { usePageOfProjectResources, useRoles } from '~/modules/common/hooks';
import { DimensionObjectType } from '~/types';
import { usePageOfPayCode } from '~/modules/rateCard/advancedRateCard/components/hooks/usePageOfPayCode';
import { useResourceDropdownGroupBy } from '~/modules/rateCard/hooks/useResourceDropdownGroupBy';

export const useDimensionOption = projectSlug => {
  const [searchTerm, setSearchTerm] = useState({
    userSearchTerm: '',
    roleSearchTerm: '',
    paycodeSearchTerm: ''
  });

  const usersResponse = usePageOfProjectResources({
    projectSlug,
    searchTerm: searchTerm.userSearchTerm
  });

  const usersGroupBy = useResourceDropdownGroupBy(projectSlug);

  const rolesResponse = useRoles({
    searchTerm: searchTerm.roleSearchTerm
  });

  const payCodeResponse = usePageOfPayCode({
    searchTerm: searchTerm.paycodeSearchTerm
  });

  const onInputChange = useCallback(
    (objectType, value) => (_, val) => {
      if (objectType === DimensionObjectType.User)
        setSearchTerm({
          ...searchTerm,
          userSearchTerm: value?.id ? '' : val
        });

      if (objectType === DimensionObjectType.ProjectRole)
        setSearchTerm({
          ...searchTerm,
          roleSearchTerm: value?.id ? '' : val
        });

      if (objectType === DimensionObjectType.PayCode)
        setSearchTerm({
          ...searchTerm,
          paycodeSearchTerm: value?.id ? '' : val
        });
    },
    [searchTerm]
  );

  return {
    usersResponse: { ...usersResponse, usersGroupBy },
    rolesResponse,
    payCodeResponse,
    onInputChange
  };
};
