import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ProjectChartRowsLoading } from '~/modules/resourcing/common/components';
import PageOutOfBoundsMessage from '~/modules/common/components/ListTable/PageOutOfBoundsMessage';
import { AllocationRowLoading } from '~/modules/resourcing/common/components/ProjectChartRowsLoading/ProjectChartRowsLoading';
import {
  useResourcePlanBulkUserActualSeriesByRoles,
  useScheduleDetailsForResourceUser
} from '~/modules/resourcing/common/hooks';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { useMeContext } from '~/modules/me';
import { ResourceAllocationChartRow2 } from './components/ResourceAllocationChart/components';
import {
  useFetchResourcePlanUsersAndRoles,
  useUserRowExpansionPanelStateReducer
} from './hooks';
import NoDataChartRow from './components/NoDataChartRow';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  }
}));

export const ResourceAllocationChartContent2 = ({
  isEditSwitchEnabled,
  allocatedUsers,
  loadingUsers,
  projectId,
  handleEditResourceAllocation,
  currentPage,
  handleRemoveResourceAllocation,
  setCurrentPage,
  updateAllocationLoadingUserUri,
  resourceRequestAcceptedLoading,
  isResourceActualModeEnabled,
  setSnackBarState,
  autofocusedUserId,
  setAutofocusedUserId
}) => {
  const classes = useStyles();

  const {
    onUserRowExpansionPanelClick,
    userExpandState
  } = useUserRowExpansionPanelStateReducer();

  const { resourceCostMode } = useMeContext();
  const { chartDates, dateRange, scale } = useResourceRequestToolbarContext();

  const userIds = allocatedUsers.map(x => x.uri);

  const {
    allocatedUsersAndRoles,
    loading: isUsersAndRolesRowsLoading
  } = useFetchResourcePlanUsersAndRoles({
    projectId,
    userIds,
    loadingUsers,
    isResourceActualModeEnabled
  });

  const {
    loading: loadingUserScheduleDetails,
    scheduleDetailsByUserMap
  } = useScheduleDetailsForResourceUser({
    userIds,
    dateRange,
    periodResolution: mapScaleToPeriodResolution(scale),
    loadingUsers
  });

  const {
    isLoading: isActualsLoading,
    bulkResourcePlanUserActualSeriesByRoleMap
  } = useResourcePlanBulkUserActualSeriesByRoles({
    dateRange,
    scale,
    projectId,
    userUris: userIds,
    skip: loadingUsers || !isResourceActualModeEnabled || userIds.length === 0
  });

  return (
    <>
      {allocatedUsersAndRoles.map(allocatedUser => (
        <div className={classes.container} key={allocatedUser.user.uri}>
          {updateAllocationLoadingUserUri === allocatedUser.user.uri ? (
            <AllocationRowLoading />
          ) : (
            <ResourceAllocationChartRow2
              isEditEnabled={isEditSwitchEnabled}
              allocatedUser={allocatedUser}
              projectId={projectId}
              currentPage={currentPage}
              handleEditResourceAllocation={handleEditResourceAllocation}
              handleRemoveResourceAllocation={handleRemoveResourceAllocation}
              setSnackBarState={setSnackBarState}
              updateAllocationLoadingUserUri={updateAllocationLoadingUserUri}
              autofocusedUserId={autofocusedUserId}
              setAutofocusedUserId={setAutofocusedUserId}
              resourceCostMode={resourceCostMode}
              loadingUserScheduleDetails={loadingUserScheduleDetails}
              onUserRowExpansionPanelClick={onUserRowExpansionPanelClick}
              isUserRowExpanded={Boolean(
                userExpandState[allocatedUser.user.id]
              )}
              userScheduleDetails={
                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                scheduleDetailsByUserMap[allocatedUser.user.uri] || {}
              }
              isActualsLoading={isActualsLoading}
              userActuals={
                bulkResourcePlanUserActualSeriesByRoleMap[
                  allocatedUser.user.uri
                ]?.actualSeriesByRole || [] // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
              }
            />
          )}
        </div>
      ))}
      {resourceRequestAcceptedLoading ? <AllocationRowLoading /> : null}
      {loadingUsers || isUsersAndRolesRowsLoading ? (
        <ProjectChartRowsLoading />
      ) : null}
      {!loadingUsers &&
        !isUsersAndRolesRowsLoading &&
        !allocatedUsers.length &&
        currentPage > 1 && (
          <PageOutOfBoundsMessage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      {!loadingUsers &&
        !isUsersAndRolesRowsLoading &&
        !allocatedUsers.length &&
        currentPage === 1 && (
          <NoDataChartRow
            scale={scale}
            scaleItemCount={chartDates.length}
            resourceKey="resourceAllocationChartContent.noRows"
          />
        )}
    </>
  );
};

ResourceAllocationChartContent2.propTypes = {
  allocatedUsers: PropTypes.array,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  projectId: PropTypes.string,
  currentPage: PropTypes.number,
  handleRemoveResourceAllocation: PropTypes.func,
  handleEditResourceAllocation: PropTypes.func,
  setCurrentPage: PropTypes.func,
  updateAllocationLoadingUserUri: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  resourceRequestAcceptedLoading: PropTypes.bool,
  isResourceActualModeEnabled: PropTypes.bool,
  setSnackBarState: PropTypes.func,
  autofocusedUserId: PropTypes.string,
  setAutofocusedUserId: PropTypes.func
};

export default ResourceAllocationChartContent2;
