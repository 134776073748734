import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ResourceUserPeriodSummaryContent from './ResourceUserPeriodSummaryContent';
import useGetLazyAllocationSummary from './hooks/useGetLazyAllocationSummary';

const ResourceUserPeriodSummaryTooltip = ({
  children,
  endDate,
  holidays,
  isAllocationsLazyLoaded,
  resourceAllocations,
  resourceId,
  scheduledHours,
  startDate,
  timeOffs,
  totalAllocatedHours,
  open,
  onClose
}) => {
  const {
    getCurrentAllocations,
    totalHoursByProject,
    loading
  } = useGetLazyAllocationSummary({
    endDate,
    resourceId,
    startDate
  });

  const title = useMemo(
    () => (
      <ResourceUserPeriodSummaryContent
        resourceAllocations={
          !isAllocationsLazyLoaded ? resourceAllocations : []
        }
        scheduledHours={scheduledHours}
        totalAllocatedHours={totalAllocatedHours}
        totalHoursByProject={isAllocationsLazyLoaded ? totalHoursByProject : []}
        holidays={holidays}
        timeOffs={timeOffs}
        startDate={startDate}
        endDate={endDate}
        isAllocationsLazyLoaded={isAllocationsLazyLoaded}
        loading={loading}
      />
    ),
    [
      isAllocationsLazyLoaded,
      resourceAllocations,
      scheduledHours,
      totalAllocatedHours,
      totalHoursByProject,
      holidays,
      timeOffs,
      startDate,
      endDate,
      loading
    ]
  );

  return (
    <Tooltip
      title={title}
      onOpen={isAllocationsLazyLoaded ? getCurrentAllocations : undefined}
      open={open}
      onClose={onClose}
    >
      {children}
    </Tooltip>
  );
};

ResourceUserPeriodSummaryTooltip.propTypes = {
  scheduledHours: PropTypes.number,
  totalAllocatedHours: PropTypes.number,
  resourceAllocations: PropTypes.array,
  holidays: PropTypes.array,
  timeOffs: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  children: PropTypes.node,
  resourceId: PropTypes.string,
  isAllocationsLazyLoaded: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ResourceUserPeriodSummaryTooltip;
