export const getTransitionUrlByRecordType = (module, record, slug, path) => {
  const { id, type } = record;

  const entityType = {
    BILL: 'bill',
    CREDITMEMO: 'credit-memo',
    PAYMENT: 'payment'
  }[type];

  return module
    ? `/${module}/${slug}/billing-invoicing/#/${entityType}/${id}`
    : path
    ? `${path}#/${entityType}/${id}`
    : `/billing/#/${entityType}/${id}`;
};

export const getTransitionUrlByRecordTypeForProject = ({
  record,
  projectSlug,
  createAndView = false
}) => {
  const params = createAndView
    ? `projectSlug=${projectSlug}&view=true`
    : `projectSlug=${projectSlug}`;

  return `${getTransitionUrlByRecordType(
    'projects',
    record,
    projectSlug
  )}?${params}`;
};

export const getTransitionUrlByRecordTypeForClient = ({
  record,
  clientSlug,
  createAndView = false
}) => {
  return createAndView
    ? `${getTransitionUrlByRecordType('clients', record, clientSlug)}?view=true`
    : getTransitionUrlByRecordType('clients', record, clientSlug);
};

export const getTransitionUrlByRecordTypeForProgram = ({
  record,
  programSlug,
  createAndView = false
}) => {
  const params = createAndView
    ? `programSlug=${programSlug}&view=true`
    : `programSlug=${programSlug}`;

  return `${getTransitionUrlByRecordType(
    'programs',
    record,
    programSlug
  )}?${params}`;
};

export const getTransitionUrlByRecordTypeForBilling = ({
  record,
  createAndView = false
}) => {
  const params = createAndView
    ? `globalBilling=true&view=true`
    : `globalBilling=true`;

  return `${getTransitionUrlByRecordType(null, record)}?${params}`;
};

export const getTransitionUrlByRecordTypeFromPath = ({
  record,
  path,
  isGlobalBilling
}) =>
  isGlobalBilling
    ? `${getTransitionUrlByRecordType(
        null,
        record,
        null,
        path
      )}?globalBilling=true`
    : getTransitionUrlByRecordType(null, record, null, path);

export const getTransitionUrl = ({ path, record, slug }) => {
  const isProjectPage = path.includes('/projects/');
  const isProgramPage = path.includes('/programs/');
  const isClientPage = path.includes('/clients/');

  if (isProjectPage) {
    return getTransitionUrlByRecordTypeForProject({
      record,
      projectSlug: slug,
      createAndView: true
    });
  }

  if (isProgramPage) {
    return getTransitionUrlByRecordTypeForProgram({
      record,
      programSlug: slug,
      createAndView: true
    });
  }
  if (isClientPage) {
    return getTransitionUrlByRecordTypeForClient({
      record,
      clientSlug: slug,
      createAndView: true
    });
  }

  return `${getTransitionUrlByRecordType(
    null,
    record,
    null,
    path
  )}?globalBilling=true&view=true`;
};
