import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import get from 'lodash.get';
import { DateField } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    maxWidth: theme.spacing(15)
  }
}));

export const DateEditor = ({
  column: { id: columnId, setFieldValue, errors, isMobile, label },
  field,
  index,
  record,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const onChangeHandler = useCallback(
    newDate => {
      setFieldValue(`scripts[${index}].${columnId}`, newDate);
    },
    [columnId, index, setFieldValue]
  );

  const requiredError = get(errors, `parameters['${columnId}'][${index}]`);

  const value = React.useMemo(
    () => (Object.keys(record[field]).length ? record[field] : null),
    [record, field]
  );

  return (
    <DateField
      clearable={false}
      value={value}
      className={classes.date}
      editable
      useDateFieldStyles={false}
      onChange={onChangeHandler}
      error={Boolean(requiredError)}
      helperText={requiredError}
      label={label}
      hiddenLabel={!isMobile}
    />
  );
};

DateEditor.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number
};

export default DateEditor;
