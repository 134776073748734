import React from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Date } from '~/modules/common/components';

const useDateStyles = makeStyles(theme => ({
  date: {
    fontSize: 'inherit',
    display: 'inline',
    padding: theme.spacing(0)
  }
}));

export const CountField = ({ count, companyDate }) => {
  const intl = useIntl();
  const dateClasses = useDateStyles();

  const period = <Date value={companyDate} classes={dateClasses} />;

  const title = intl.formatMessage(
    {
      id: 'resourceAvailabilityCard.countTooltip'
    },
    {
      count,
      period
    }
  );

  return (
    <Tooltip title={title}>
      <span>{count}</span>
    </Tooltip>
  );
};

CountField.propTypes = {
  count: PropTypes.number.isRequired,
  companyDate: PropTypes.object.isRequired
};

export default CountField;
