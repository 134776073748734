import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  NoValue,
  Decimal,
  Percentage,
  SummaryFormattedNumber
} from '~/modules/common/components';
import useStyles, { useVarianceStyles } from './useStyles';

export const ResourceActualSummaryDialogRow = ({
  classes: classesOverrides,
  dataQeId,
  hours,
  isHigher,
  isTotalRow = false,
  rowName,
  percent,
  showDiff
}) => {
  const classes = useStyles({ classes: classesOverrides });

  const varianceStyles = useVarianceStyles();

  const hourValue = useMemo(
    () => ({
      value: <Decimal className={classes.value} value={hours} />
    }),
    [hours, classes.value]
  );

  const hoursRow =
    isTotalRow && showDiff ? (
      <SummaryFormattedNumber
        classes={varianceStyles}
        value={hours}
        showAddIcon={isHigher}
        isHigher={isHigher}
        formattedMessageKey="resourceActualSummaryDialog.hours"
      />
    ) : (
      <FormattedMessage
        id="resourceActualSummaryDialog.hours"
        values={hourValue}
      />
    );

  const percentRow =
    isTotalRow && showDiff ? (
      <SummaryFormattedNumber
        classes={varianceStyles}
        value={percent}
        showAddIcon={isHigher}
        isHigher={isHigher}
        formattedMessageKey="resourceActualSummaryDialog.percentage"
      />
    ) : (
      <Percentage className={classes.value} value={percent} />
    );

  return (
    <div
      className={classNames(classes.item, { [classes.totalItem]: isTotalRow })}
      data-qe-id={dataQeId}
    >
      <span className={classes.label} data-qe-id={`${dataQeId}_rowName`}>
        <FormattedMessage id={rowName} />
      </span>
      <span className={classes.value} data-qe-id={`${dataQeId}_hours`}>
        {hours ? hoursRow : <NoValue />}
      </span>
      <span className={classes.value} data-qe-id={`${dataQeId}_percentage`}>
        {percent ? percentRow : <NoValue />}
      </span>
    </div>
  );
};

ResourceActualSummaryDialogRow.propTypes = {
  classes: PropTypes.object,
  rowName: PropTypes.string,
  hours: PropTypes.number,
  percent: PropTypes.number,
  isHigher: PropTypes.bool,
  showDiff: PropTypes.bool,
  isTotalRow: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default ResourceActualSummaryDialogRow;
