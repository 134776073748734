import { mapEnabledFieldsToVariables } from '~/modules/common/util';
import { columnDefinitions } from '~/modules/common/hooks/project/projectColumns';
import { useMeContext } from '~/modules/me';

const columnVarColumnMap = ({
  columns,
  isPsaPpmEstimatedCostAtCompletionEnabled
}) => ({
  includeWorkflowStatus: columns.workflowStatus,
  includeClient: columns.client,
  includeProgram: columns.program,
  includeProjectManagerReference: columns.projectManagerReference,
  includeActualBilling: columns.actualBilling,
  includeActualBillingInProjectCurrency: columns.actualBillingInProjectCurrency,
  includeRevenueRecognitionPolicy: columns.revenueRecognitionPolicy,
  includeBudgetCostInProjectCurrency: columns.budgetedCostInProjectCurrency,
  includeBudgetCostAmountInPortfolioCurrency:
    columns.budgetCostAmountInPortfolioCurrency,
  includeBudgetCost: columns.budgetedCost,
  includeActualExpenseCost: columns.actualExpenseCost,
  includeActualResourceCost: columns.actualResourceCost,
  includeActualCost: columns.actualCost,
  includeActualCostInProjectCurrency: columns.actualCostInProjectCurrency,
  includeActualCostAmountInPortfolioCurrency:
    columns.actualCostAmountInPortfolioCurrency,
  includeEstimatedCost: columns.estimatedCost,
  includeBudgetHours: columns.budgetHours,
  includeTotalTaskHours: columns.taskHours,
  includeEstimatedTaskCompletionDate: columns.estimatedTaskCompletionDate,
  includeActualHours: columns.actualHours,
  includeBudgetHoursUsed: columns.budgetHoursUsed,
  includeInitialTaskStartDate: columns.initialTaskStartDate,
  includeEstimatedHours: columns.estimatedHours,
  includeEstimatedHoursAtCompletion: columns.estimatedHoursAtCompletion,
  includeEstimatedContract: columns.estimatedContract,
  includeEstimatedContractInProjectCurrency:
    columns.estimatedContractInProjectCurrency,
  includePortfolio: columns.portfolio,
  includeFinalTaskEndDate: columns.finalTaskEndDate,
  includeCostCenter: columns.costCenter,
  includeDivision: columns.division,
  includeServiceCenter: columns.serviceCenter,
  includeLocation: columns.location,
  includeDepartment: columns.department,
  includeEmployeeType: columns.employeeType,
  includeEstimatedCostAtCompletion:
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    columns.estimatedCostAtCompletion,
  includeEstimatedCostAtCompletionInProjectCurrency:
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    columns.estimatedCostAtCompletionInProjectCurrency,
  includeEstimatedCostAtCompletionInPortfolioCurrency:
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    columns.estimatedCostAtCompletionInPortfolioCurrency,
  includeBudgetCostUsedPercentage:
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    columns.budgetCostUsedPercentage,
  includeEstimatedBudgetCostRemaining:
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    columns.estimatedBudgetCostRemaining,
  includeEstimatedBudgetCostRemainingInProjectCurrency:
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    columns.estimatedBudgetCostRemainingInProjectCurrency,
  includeEstimatedBudgetCostRemainingInPortfolioCurrency:
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    columns.estimatedBudgetCostRemainingInPortfolioCurrency
});

export const columnArguments = `
$includeWorkflowStatus: Boolean!
$includeClient: Boolean!
$includeProgram: Boolean!
$includeProjectManagerReference: Boolean!
$includeActualBilling: Boolean!
$includeActualBillingInProjectCurrency: Boolean!
$includeRevenueRecognitionPolicy: Boolean!
$includeBudgetCost: Boolean!
$includeBudgetCostInProjectCurrency: Boolean!
$includeBudgetCostAmountInPortfolioCurrency: Boolean!
$includeActualExpenseCost: Boolean!
$includeActualResourceCost: Boolean!
$includeActualCost: Boolean!
$includeActualCostInProjectCurrency: Boolean!
$includeActualCostAmountInPortfolioCurrency: Boolean!
$includeEstimatedCost: Boolean!
$includeBudgetHours: Boolean!
$includeTotalTaskHours: Boolean!
$includeEstimatedTaskCompletionDate: Boolean!
$includeActualHours: Boolean!
$includeBudgetHoursUsed: Boolean!
$includeEstimatedHours: Boolean!
$includeInitialTaskStartDate: Boolean!
$includeEstimatedHoursAtCompletion: Boolean!
$includeEstimatedContract: Boolean!
$includeEstimatedContractInProjectCurrency: Boolean!
$includePortfolio: Boolean!
$includeFinalTaskEndDate: Boolean!
$includeCostCenter: Boolean!
$includeDivision: Boolean!
$includeServiceCenter: Boolean!
$includeLocation: Boolean!
$includeDepartment: Boolean!
$includeEmployeeType: Boolean!
$includeEstimatedCostAtCompletion: Boolean!,
$includeEstimatedCostAtCompletionInProjectCurrency: Boolean!,
$includeEstimatedCostAtCompletionInPortfolioCurrency: Boolean!,
$includeBudgetCostUsedPercentage: Boolean!
$includeEstimatedBudgetCostRemaining: Boolean!
$includeEstimatedBudgetCostRemainingInProjectCurrency: Boolean!,
$includeEstimatedBudgetCostRemainingInPortfolioCurrency: Boolean!
  `;

export const useProjectsColumnSelection = ({ enabledFields }) => {
  const me = useMeContext();

  const columns = columnDefinitions(me);
  const columnMap = columnVarColumnMap({
    columns,
    isPsaPpmEstimatedCostAtCompletionEnabled:
      me?.featureFlags?.isPsaPpmEstimatedCostAtCompletionEnabled
  });

  return mapEnabledFieldsToVariables(enabledFields, columnMap);
};

export default useProjectsColumnSelection;
