import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftSharp';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import useStyles from './useStyles';

const TableHeaderPagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  isPastPaginationLimit,
  currentPageRowCount,
  classes: classesOveride
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: classesOveride });

  const handlePreviousClick = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage, setCurrentPage]);

  const handleNextClick = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage]);

  const values = useMemo(
    () => ({
      from: (currentPage - 1) * pageSize + 1,
      to: (currentPage - 1) * pageSize + currentPageRowCount,
      count: (currentPage - 1) * pageSize + currentPageRowCount
    }),
    [currentPage, pageSize, currentPageRowCount]
  );

  const isNextPageExists = currentPageRowCount % pageSize === 0;

  return (
    <nav className={classes.root} aria-label="Pagination">
      <span className={classes.caption}>
        {(isPastPaginationLimit || currentPageRowCount !== 0) && (
          <FormattedMessage
            id={
              isPastPaginationLimit
                ? 'resourceAssignmentDialog.paginationLabelPastLimit'
                : isNextPageExists
                ? 'resourceAssignmentDialog.paginationLabel'
                : 'resourceAssignmentDialog.paginationLabelWithCount'
            }
            values={values}
          />
        )}
      </span>
      <IconButton
        className={classes.backButton}
        onClick={handlePreviousClick}
        disabled={currentPage === 1}
        aria-label={formatMessage({ id: 'dateButton.previous' })}
      >
        <ChevronLeftIcon mirror="rtl" />
      </IconButton>
      <IconButton
        className={classes.nextButton}
        onClick={handleNextClick}
        disabled={
          isPastPaginationLimit ||
          !isNextPageExists ||
          currentPageRowCount === 0
        }
        aria-label={formatMessage({ id: 'dateButton.next' })}
      >
        <ChevronRightIcon mirror="rtl" />
      </IconButton>
    </nav>
  );
};

TableHeaderPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  isPastPaginationLimit: PropTypes.bool,
  currentPageRowCount: PropTypes.number,
  classes: PropTypes.object
};

export default TableHeaderPagination;
