import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  }
}));

export const RoleScheduleReadOnlyFormActionButtons = ({ onOk }) => {
  const classes = useStyles();

  return (
    <DialogActions className={classes.root}>
      <span className={classes.grow} />
      <Button onClick={onOk} data-qe-id="RoleOkButton">
        <FormattedMessage id="button.ok" />
      </Button>
    </DialogActions>
  );
};

RoleScheduleReadOnlyFormActionButtons.propTypes = {
  onOk: PropTypes.func.isRequired
};

export default RoleScheduleReadOnlyFormActionButtons;
