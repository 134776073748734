import React from 'react';
import { POLICYTYPE } from '~/modules/common/enums';
import RoleFacet from '~/modules/common/components/SearchBox/Facets/RoleFacet';
import LocationFacet from '~/modules/common/components/SearchBox/Facets/LocationFacet';
import DivisionFacet from '~/modules/common/components/SearchBox/Facets/DivisionFacet';
import ResourceProjectFacet from '~/modules/common/components/SearchBox/Facets/ResourceProjectFacet';
import { SkillFacetWithNoNoneOption } from '~/modules/common/components/SearchBox/Facets/SkillFacet';
import ServiceCenterFacet from '~/modules/common/components/SearchBox/Facets/ServiceCenterFacet';
import CostCenterFacet from '~/modules/common/components/SearchBox/Facets/CostCenterFacet';
import EmployeeTypeFacet from '~/modules/common/components/SearchBox/Facets/EmployeeTypeFacet';
import DepartmentFacet from '~/modules/common/components/SearchBox/Facets/DepartmentFacet';
import ResourcePoolFacet from '~/modules/common/components/SearchBox/Facets/ResourcePoolFacet';

const withPolicyBasedGroupFacets = BaseComponent => props => (
  <BaseComponent {...props} policy={POLICYTYPE.RESOURCE_MANAGEMENT} />
);

const hasResourceManagerDataAccessLevel = permission =>
  permission.dataAccessLevelUris.includes(
    'urn:replicon:user-data-access-level:resource-manager'
  );

const hasResourcePoolManagerDataAccessLevel = ({ permission, featureFlags }) =>
  permission.dataAccessLevelUris.includes(
    'urn:replicon:user-data-access-level:resource-pool-manager'
  );

const getFacets = ({ groupSettings, permissions, featureFlags }) => [
  ResourceProjectFacet,
  ...(permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-project-role' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-project-role' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [RoleFacet]
    : []),
  ...(groupSettings.location &&
  groupSettings.location.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-location' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-location' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...LocationFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            LocationFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(groupSettings.division &&
  groupSettings.division.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-division' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-division' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...DivisionFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            DivisionFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(groupSettings.serviceCenter &&
  groupSettings.serviceCenter.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-service-center' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-service-center' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...ServiceCenterFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            ServiceCenterFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(groupSettings.costCenter &&
  groupSettings.costCenter.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-cost-center' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-cost-center' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...CostCenterFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            CostCenterFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(groupSettings.department &&
  groupSettings.department.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri === 'urn:replicon:user-action:view-department' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri === 'urn:replicon:user-action:view-department' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...DepartmentFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            DepartmentFacet.DetailsControl
          )
        }
      ]
    : []),
  ...(groupSettings.employeeType &&
  groupSettings.employeeType.isEnabled &&
  permissions.some(
    p =>
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-employee-type' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-employee-type' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [
        {
          ...EmployeeTypeFacet,
          DetailsControl: withPolicyBasedGroupFacets(
            EmployeeTypeFacet.DetailsControl
          )
        }
      ]
    : []),
  SkillFacetWithNoNoneOption,
  ...(permissions.some(
    p =>
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-resource-pool' &&
        hasResourceManagerDataAccessLevel(p)) ||
      (p.permissionActionUri ===
        'urn:replicon:user-action:view-resource-pool' &&
        hasResourcePoolManagerDataAccessLevel({ permission: p, featureFlags }))
  )
    ? [ResourcePoolFacet]
    : [])
];

export default getFacets;
