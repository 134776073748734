import { Grid, ListItem, Paper, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useBillingTransactionContext } from '~/modules/billing-invoicing/common/components/BillingTransactionTab/BillingTransactionContext';
import { getTransitionUrlByRecordTypeFromPath } from '../../util';
import {
  MobileBillingTransactionHeaderRow,
  MobileBillingTransactionClientRow,
  MobileBillingTransactionAmountRow
} from './components';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(1, 1.5)
  },
  selector: {
    margin: theme.spacing(-1)
  }
}));

export const MobileBillingTransactionListItem = ({ record }) => {
  const classes = useStyles();

  const {
    isAccountReceivable,
    onRowSelect,
    selectedTransactionItems,
    hasRowSelector,
    summaryFilterState
  } = useBillingTransactionContext();

  const { client, balanceAmount, balanceWithoutAllocationsAmount } = record;
  const isSelected = selectedTransactionItems.includes(record.id);
  const onSelectClick = useCallback(
    event => {
      onRowSelect(record, event);
    },
    [onRowSelect, record]
  );
  const isSummaryFilterStateVoid =
    summaryFilterState?.quickFilter?.filterByStatus === 'void';

  return (
    <div className={classes.container}>
      {hasRowSelector && !isSummaryFilterStateVoid ? (
        <Checkbox
          checked={isSelected}
          onChange={onSelectClick}
          color="primary"
          className={classes.selector}
        />
      ) : null}
      <ListItem
        button
        component={Link}
        to={getTransitionUrlByRecordTypeFromPath({
          record,
          path: window.location.pathname
        })}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          component={Paper}
          square
          elevation={0}
        >
          <Grid item>
            <MobileBillingTransactionHeaderRow
              record={record}
              isAccountReceivable={isAccountReceivable}
            />
            <MobileBillingTransactionClientRow client={client} />
            <MobileBillingTransactionAmountRow
              balanceAmount={balanceAmount}
              balanceWithoutAllocationsAmount={balanceWithoutAllocationsAmount}
            />
          </Grid>
        </Grid>
      </ListItem>
    </div>
  );
};

MobileBillingTransactionListItem.propTypes = {
  record: PropTypes.object
};

export default memo(MobileBillingTransactionListItem);
