import { Typography, Grid, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  iconButton: {
    margin: theme.spacing(-1),
    color: theme.palette.text.primary
  },
  gridContainer: {
    minWidth: theme.spacing(24)
  },
  displayText: {
    whiteSpace: 'break-spaces'
  }
}));

export const ProjectFormatter = ({
  field,
  record,
  column: { id, onClick }
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { id: projectId, displayText } = record[field];

  const handleOnClick = useCallback(() => onClick(projectId), [
    onClick,
    projectId
  ]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
      className={classes.gridContainer}
    >
      <Grid item xs={10} className={classes.displayText}>
        <Typography variant="body2">{displayText}</Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton
          onClick={handleOnClick}
          className={classes.iconButton}
          aria-label={formatMessage({ id: 'chevronRight.label' })}
        >
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

ProjectFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  column: PropTypes.object
};

export default ProjectFormatter;
