import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';

export const CancelButton = ({ dataQeId, ...props }) => (
  <Button data-qe-id={dataQeId && `${dataQeId}_CancelButton`} {...props}>
    <FormattedMessage id="button.cancel" />
  </Button>
);

CancelButton.propTypes = {
  dataQeId: PropTypes.string
};

export default CancelButton;
