import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { TASKS_TO_RESCHEDULE_TYPE } from './enum';

export const TasksToRescheduleDropDown = ({ value, onChange, ...rest }) => {
  const intl = useIntl();

  const options = Object.values(TASKS_TO_RESCHEDULE_TYPE).map(v => ({
    id: v,
    displayText: intl.formatMessage({
      id: `rescheduleProject.tasksToRescheduleType.${v}`
    })
  }));

  const selectedValue = useMemo(() => options.find(x => x.id === value), [
    options,
    value
  ]);

  return (
    <SimpleDropdown
      label={intl.formatMessage({
        id: `rescheduleProject.tasksToReschedule`
      })}
      options={options}
      onChange={onChange}
      value={selectedValue}
      noneOption={false}
      disableSort
      required
      {...rest}
    />
  );
};

TasksToRescheduleDropDown.propTypes = {
  value: PropTypes.oneOf([
    TASKS_TO_RESCHEDULE_TYPE.ALL_TASKS,
    TASKS_TO_RESCHEDULE_TYPE.IN_PROGRESS,
    TASKS_TO_RESCHEDULE_TYPE.NOT_STARTED
  ]),
  onChange: PropTypes.func.isRequired
};

export default TasksToRescheduleDropDown;
