import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { VictoryPie, VictoryLabel } from 'victory';
import { useTheme, makeStyles } from '@material-ui/core';
import DonutLegends from './DonutLegends';
import { styles } from './styles';

const chartConfig = {
  width: 300,
  height: 300,
  innerRadius: 100,
  padding: { top: 20, bottom: 5, right: 0, left: 0 },
  titleX: 150,
  titleY: 150,
  valueX: 150,
  valueY: 170
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap-reverse'
  },
  donut: {}
});

export const DonutChart = ({
  classes: classesOverride,
  overrideChartConfig = {},
  dataPoints = [],
  colorScale,
  legends,
  legendClasses,
  legendItemClasses,
  chartTitle = '',
  chartValue = '',
  showNoDataCircle = false,
  legendClickHandlers = {},
  events,
  dataQeId,
  innerPieConfig,
  overrideChartStyles,
  titleTextComponent
}) => {
  const theme = useTheme();
  const classes = useStyles({ classes: classesOverride });
  const chartStyles = styles({ theme, overrideChartStyles });

  const config = { ...chartConfig, ...overrideChartConfig };

  const pieStyle = useMemo(
    () =>
      showNoDataCircle
        ? chartStyles.circleWithNoData
        : chartStyles.circleWithData,
    [chartStyles.circleWithNoData, chartStyles.circleWithData, showNoDataCircle]
  );
  const pieLabels = useMemo(() => () => null, []);

  return (
    <div className={classes.container} data-qe-id={dataQeId}>
      <svg
        width={config.width}
        height={config.height}
        className={classes.donut}
      >
        <VictoryPie
          standalone={false}
          width={config.width}
          height={config.height}
          data={dataPoints}
          events={events}
          innerRadius={config.innerRadius}
          colorScale={colorScale}
          padding={config.padding}
          labels={pieLabels}
          style={pieStyle}
        />
        {innerPieConfig && (
          <VictoryPie
            standalone={false}
            width={config.width}
            height={config.height}
            data={innerPieConfig.dataPoints}
            radius={config.innerRadius}
            innerRadius={innerPieConfig.innerRadius}
            colorScale={innerPieConfig.colorScale}
            padding={config.padding}
            labels={pieLabels}
          />
        )}
        {titleTextComponent || (
          <VictoryLabel
            textAnchor="middle"
            style={chartStyles.chartTitle}
            text={chartTitle}
            x={config.titleX}
            y={config.titleY}
          />
        )}
        <VictoryLabel
          textAnchor="middle"
          style={chartStyles.chartValue}
          text={chartValue}
          x={config.valueX}
          y={config.valueY}
        />
      </svg>
      <DonutLegends
        classes={legendClasses}
        legends={legends}
        legendClasses={legendItemClasses}
        legendClickHandlers={legendClickHandlers}
      />
    </div>
  );
};

DonutChart.propTypes = {
  classes: PropTypes.object,
  dataPoints: PropTypes.array,
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      colorScale: PropTypes.string.isRequired
    })
  ),
  legendClasses: PropTypes.object,
  legendItemClasses: PropTypes.object,
  events: PropTypes.array,
  colorScale: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartTitle: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chartValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  overrideChartConfig: PropTypes.object,
  overrideChartStyles: PropTypes.shape({
    chartTitle: PropTypes.object,
    chartValue: PropTypes.object
  }),
  innerPieConfig: PropTypes.shape({
    dataPoints: PropTypes.array,
    colorScale: PropTypes.array,
    innerRadius: PropTypes.number
  }),
  showNoDataCircle: PropTypes.bool,
  legendClickHandlers: PropTypes.object,
  dataQeId: PropTypes.string,
  titleTextComponent: PropTypes.node
};

export default DonutChart;
