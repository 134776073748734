import { OWNER_URI, PROJECT_URI, USER_URI, DOCUMENT_TYPE_URI } from './uris';

export const makeKeyValuePair = (keyUri, value) => ({
  keyUri,
  value: {
    text: value
  }
});

export const makeProjectCustomMetadata = ({ attachToUri: projectUri }) => [
  makeKeyValuePair(OWNER_URI, 'psa-ui'),
  { keyUri: PROJECT_URI, value: { uri: projectUri } }
];

export const makeResourceCustomMetadata = ({ attachToUri: userUri }) => [
  makeKeyValuePair(OWNER_URI, 'psa-ui'),
  makeKeyValuePair(DOCUMENT_TYPE_URI, 'resume'),
  { keyUri: USER_URI, value: { uri: userUri } }
];

export const CUSTOM_METADATA_BUILDER = {
  PROJECT: makeProjectCustomMetadata,
  RESOURCE: makeResourceCustomMetadata
};
