import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RejectIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 12 2 C 6.469999999999999 2 2 6.469999999999999 2 12 C 2 17.53 6.469999999999999 22 12 22 C 17.53 22 22 17.53 22 12 C 22 6.469999999999999 17.53 2 12 2 z M 8.41015625 7 L 12 10.58984375 L 15.58984375 7 L 17 8.41015625 L 13.41015625 12 L 17 15.58984375 L 15.58984375 17 L 12 13.41015625 L 8.41015625 17 L 7 15.58984375 L 10.58984375 12 L 7 8.41015625 L 8.41015625 7 z " />
    </>
  </SvgIcon>
));

export default RejectIcon;
