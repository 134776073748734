import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  label: {
    fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    color: theme.palette.text.secondary
  }
}));

export const LabelledValue = ({
  classes: classesOverride,
  label,
  labelVariant = 'caption',
  value,
  dataQeId
}) => {
  const classes = useStyles({ classes: classesOverride });
  const qeId = dataQeId || `${label}_label`;

  return (
    <Grid className={classes.root} container direction="column">
      <Grid item>
        <Typography
          data-qe-id={qeId}
          className={classes.label}
          variant={labelVariant}
        >
          {label}
        </Typography>
      </Grid>
      <Grid data-qe-id={`${qeId}_value`} className={classes.value} item>
        {value}
      </Grid>
    </Grid>
  );
};

LabelledValue.propTypes = {
  dataQeId: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelVariant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default LabelledValue;
