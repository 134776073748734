import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Decimal } from '~/modules/common/components';

const MoneyReadonly = ({
  className,
  money: { amount, currency },
  tooltipPlacement = 'left-start'
}) => (
  <Tooltip title={currency.name} placement={tooltipPlacement}>
    <Typography className={className} variant="body2">
      {currency.displayText}
      <Decimal precision={2} value={amount} />
    </Typography>
  </Tooltip>
);

MoneyReadonly.propTypes = {
  className: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  money: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.shape({
      displayText: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default MoneyReadonly;
