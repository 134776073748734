import React from 'react';
import PropTypes from 'prop-types';
import { DepartmentIcon } from '~/modules/common/components/Icons';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupName from './GroupName';

export const DepartmentName = ({
  value,
  departmentSettings,
  variant,
  showFullPath,
  disableIcon
}) => (
  <GroupName
    value={value}
    Icon={DepartmentIcon}
    groupSettings={departmentSettings}
    variant={variant}
    showFullPath={showFullPath}
    disableIcon={disableIcon}
  />
);

DepartmentName.propTypes = {
  value: PropTypes.object,
  departmentSettings: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['icon', 'avatar']),
  showFullPath: PropTypes.bool,
  disableIcon: PropTypes.bool
};

export default withGroupSettings(
  'department',
  'departmentSettings'
)(DepartmentName);
