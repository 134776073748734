import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { AddressInfoListItemContext } from './hooks/useAddressInfoListItemContext';

export const AddressInfoListItemContextProvider = ({
  children,
  addressHandlers,
  countries
}) => {
  const value = useMemo(
    () => ({
      addressHandlers,
      countries
    }),
    [addressHandlers, countries]
  );

  return (
    <AddressInfoListItemContext.Provider value={value}>
      {children}
    </AddressInfoListItemContext.Provider>
  );
};

AddressInfoListItemContextProvider.propTypes = {
  children: PropTypes.node,
  addressHandlers: PropTypes.object,
  countries: PropTypes.array
};
