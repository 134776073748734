import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DateIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M22 3h-3V1h-2v2H7V1H5v2H2v20h20V3zm-2 18H4V8h16v13z" />
    </>
  </SvgIcon>
));

export default DateIcon;
