import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const ASSIGNED_PROJECTS_QUERY = gql`
  query Eager_GetAssignedProjects(
    $userUri: String
    $startDate: String
    $allocationStatusList: [ResourceAllocationStatus]
    $projectName: String
    $limit: Int
    $cursor: String
    $viewProjectAdditionalDetails: Boolean!
    $sort: ResourceAllocationSort
  ) {
    resourceAllocations(
      userUri: $userUri
      startDate: $startDate
      limit: $limit
      projectName: $projectName
      cursor: $cursor
      allocationStatusList: $allocationStatusList
      sort: $sort
    ) {
      resourceAllocations {
        id
        load
        project {
          id
          displayText
          projectManagerReference @include(if: $viewProjectAdditionalDetails) {
            displayText
            id
          }
          startDate @include(if: $viewProjectAdditionalDetails)
          endDate @include(if: $viewProjectAdditionalDetails)
        }
        startDate
        endDate
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
        role {
          id
          displayText
        }
        totalHoursWorked
      }
      nextPageCursor
    }
  }
`;

export const useLoadMoreCallback = ({
  nextPageCursor,
  fetchMore,
  userUri,
  allocationStatusList,
  projectName,
  startDate,
  limit,
  viewProjectAdditionalDetails,
  sort
}) =>
  useCallback(async () => {
    if (!nextPageCursor) return;

    try {
      await fetchMore({
        variables: {
          userUri,
          startDate,
          allocationStatusList,
          projectName,
          limit,
          cursor: nextPageCursor,
          viewProjectAdditionalDetails,
          sort
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            ...prev,
            resourceAllocations: {
              ...prev.resourceAllocations,
              resourceAllocations: [
                ...prev.resourceAllocations.resourceAllocations,
                ...fetchMoreResult.resourceAllocations.resourceAllocations
              ],
              nextPageCursor: fetchMoreResult.resourceAllocations.nextPageCursor
            }
          };
        }
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }, [
    nextPageCursor,
    fetchMore,
    userUri,
    startDate,
    allocationStatusList,
    projectName,
    limit,
    viewProjectAdditionalDetails,
    sort
  ]);
const QUERY_LIMIT = 5;

export const useAssignedProjectsQuery = (
  { userUri, allocationStatusList, limit = QUERY_LIMIT, sort },
  viewProjectAdditionalDetails = false
) => {
  const variables = {
    userUri,
    allocationStatusList,
    limit,
    sort
  };

  const queryOptions = {
    variables: {
      ...variables,
      viewProjectAdditionalDetails
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  };

  const { loading: loadingProjects, data, fetchMore } = useQuery(
    ASSIGNED_PROJECTS_QUERY,
    queryOptions
  );

  const allocations = get(data, 'resourceAllocations') || {};
  const { resourceAllocations = [], nextPageCursor } = allocations;

  const loadMore = useLoadMoreCallback({
    ...variables,
    nextPageCursor,
    fetchMore,
    viewProjectAdditionalDetails
  });

  return {
    loadingProjects: loadingProjects || false,
    loadMore,
    nextPageCursor,
    resourceAllocations
  };
};
