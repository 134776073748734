import { useCallback } from 'react';

export const useSearchInputHandler = ({
  searchPhrase,
  hideDetailsMenu,
  makeSetSearchPhrase
}) => ({
  handleSearchInputFocus: useCallback(event => hideDetailsMenu(), [
    hideDetailsMenu
  ]),
  makeHandleSearchInputSearch: useCallback(
    criterions => () => {
      makeSetSearchPhrase(criterions)(searchPhrase);
      hideDetailsMenu();
    },
    [searchPhrase, hideDetailsMenu, makeSetSearchPhrase]
  ),
  makeHandleSearchInputChange: useCallback(
    criterions => event => {
      makeSetSearchPhrase(criterions)(event.target.value || '');
    },
    [makeSetSearchPhrase]
  ),
  makeHandleClearSearchTerm: useCallback(
    criterions => event => {
      makeSetSearchPhrase(criterions)('');
      hideDetailsMenu();
    },
    [hideDetailsMenu, makeSetSearchPhrase]
  )
});

export default useSearchInputHandler;
