import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const DELETE_SETTINGS = gql`
  mutation deleteRevenueRunBatchUri($revenueRunUri: String!) {
    deleteRevenueRunBatchUri(revenueRunUri: $revenueRunUri)
  }
`;

export const useDeleteSettings = revenueRunUri => {
  const [deleteRevenueRunBatchUri] = useMutation(DELETE_SETTINGS);

  return {
    deleteRevenueRunBatchUri: () => {
      return deleteRevenueRunBatchUri({
        variables: {
          revenueRunUri
        }
      });
    }
  };
};

export default useDeleteSettings;
