/* eslint-disable react/prop-types */
import React, {
  useContext,
  useCallback,
  useState,
  useMemo,
  useReducer
} from 'react';
import { SortDirection } from '~/types';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';
import availabilityReducer, {
  updateResourceAvailability
} from '~/modules/resourcing/common/hooks/availabilityReducer';
import { useSessionStorage } from '~/modules/common/hooks';
import useFilterBasedOnSearchCriteria from './useFilterBasedOnSearchCriteria';

export const QuickAllocationContext = React.createContext();

let availabilityTimeout;

const useQuickAllocationContext = () => useContext(QuickAllocationContext);

export const withQuickAllocationContextProvider = BaseComponent => props => {
  const {
    leftComponentWidth,
    chartDates,
    onPrevious,
    onNext,
    scale,
    setScale,
    dateRange,
    defaultDisplayUnit,
    searchCriteria,
    page,
    setPage,
    handleExpand,
    userExpandState,
    projectStatusFilterOption
  } = props;

  const {
    storedValue: isShowActualEnabled,
    setValue: setIsShowActualEnabled
  } = useSessionStorage('show-actual-enabled', false);

  const [displayUnit, setDisplayUnit] = useState(defaultDisplayUnit);

  const [state, dispatch] = useReducer(availabilityReducer, {
    availability: false
  });

  const setAvailabilityLoading = useCallback(() => {
    if (availabilityTimeout) {
      clearTimeout(availabilityTimeout);
    }

    dispatch(updateResourceAvailability(true));

    availabilityTimeout = setTimeout(() => {
      dispatch(updateResourceAvailability(false));
    }, 9000);
  }, []);

  const [sort, setSort] = useState({
    field: 'displayText',
    direction: SortDirection.Asc
  });

  const { filter } = useFilterBasedOnSearchCriteria({
    searchCriteria,
    isShowActualEnabled
  });

  const [{ start: chartStartDate }] = chartDates;
  const { end: chartEndDate } = chartDates[chartDates.length - 1];

  const contextValue = useMemo(
    () => ({
      displayUnit,
      setDisplayUnit,
      sort,
      scale,
      periodResolution: mapScaleToPeriodResolution(scale),
      setScale,
      dateRange,
      setAvailabilityLoading,
      state,
      setSort,
      filter,
      searchCriteria,
      leftComponentWidth,
      chartDates,
      chartStartDate,
      chartEndDate,
      resourceUsersCurrentPage: page,
      isShowActualEnabled,
      setIsShowActualEnabled,
      projectStatusFilterOption
    }),
    [
      displayUnit,
      setDisplayUnit,
      filter,
      sort,
      state,
      setAvailabilityLoading,
      setSort,
      projectStatusFilterOption,
      scale,
      setScale,
      dateRange,
      searchCriteria,
      leftComponentWidth,
      chartDates,
      chartStartDate,
      chartEndDate,
      page,
      isShowActualEnabled,
      setIsShowActualEnabled
    ]
  );

  return (
    <QuickAllocationContext.Provider value={contextValue}>
      <BaseComponent
        onPrevious={onPrevious}
        onNext={onNext}
        page={page}
        setPage={setPage}
        handleExpand={handleExpand}
        userExpandState={userExpandState}
      />
    </QuickAllocationContext.Provider>
  );
};

export default useQuickAllocationContext;
