import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ListItem, ListItemText } from '@material-ui/core';
import Body from './Body';
import Title from './Title';

const useStyles = makeStyles(theme => ({
  listItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1)
  }
}));

export const ViewResourceRequestListItem = React.memo(
  ({ request, onClose, onClick, qeIdTag }) => {
    const classes = useStyles();

    const handleClick = useCallback(() => {
      onClose();
      onClick({ resourceRequestId: request.id });
    }, [onClick, onClose, request.id]);

    const primaryText = useMemo(() => <Title request={request} />, [request]);

    const secondaryText = useMemo(() => <Body request={request} />, [request]);

    const secondaryTypographyProps = useMemo(
      () => ({
        component: 'span'
      }),
      []
    );

    return (
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          secondaryTypographyProps={secondaryTypographyProps}
          data-qe-id={qeIdTag}
        />
      </ListItem>
    );
  }
);

ViewResourceRequestListItem.propTypes = {
  request: PropTypes.object,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  qeIdTag: PropTypes.string
};

export default ViewResourceRequestListItem;
