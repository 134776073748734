import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import { useIsBreakpointDown, useSkillLevels } from '~/modules/common/hooks';
import EditableCard, {
  ReadOnly,
  ReadOnlyContent,
  Edit,
  EditTitle,
  EditContent
} from '~/modules/common/components/EditableCard';
import { SkillIcon } from '~/modules/common/components/Icons';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';
import useCertificates from '~/modules/resource-management/resourceUserDetails/hooks/useCertificates';
import ModuleDialogTitle from '~/modules/common/components/ModuleDialogTitle';
import SkillsEditable from './SkillsEditable';
import SkillsReadOnly from './SkillsReadOnly';
import { useFormState } from './hooks';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 0
  },
  content: {
    height: '60%',
    minHeight: '300px',
    padding: 0,
    margin: '8px 24px',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  }
}));

const title = <FormattedMessage id="skillInfoCard.skills" />;
const avatar = <SkillIcon />;

export const SkillsCard = ({
  classes: classesOverride,
  editable,
  userDetails
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles({ classes: classesOverride });
  const { handleReset, handleSubmit } = useFormikContext();

  const {
    error: errorInLoadingSkillLevels,
    skillLevels,
    maxSkillLevel,
    isSkillLoading
  } = useSkillLevels();

  const {
    error: errorInLoadingCertificates,
    certificates,
    isCertificateLoading
  } = useCertificates({
    userSlug: userDetails.slug
  });

  if (errorInLoadingSkillLevels || errorInLoadingCertificates)
    return <RedirectToUnhandledError />;

  const isLoading = isSkillLoading || isCertificateLoading;

  return (
    <EditableCard
      dataQeId="SkillEditSection"
      editable={editable && !isLoading}
      fullScreen={isMobile}
      editContentClassName={classes.content}
      ariaLabelKey="resourceBasicInfoCard.skillsInfoCardDialog"
    >
      <ReadOnly title={title} avatar={avatar} isLoading={isLoading}>
        <ReadOnlyContent>
          {!isLoading && (
            <SkillsReadOnly
              skills={userDetails.resourceSkill}
              certificates={certificates}
              maxSkillLevel={maxSkillLevel}
            />
          )}
        </ReadOnlyContent>
      </ReadOnly>
      <Edit saveable onSave={handleSubmit} onCancel={handleReset}>
        <EditTitle>
          <ModuleDialogTitle
            dataQeId="ManageSkillsDialog"
            title={title}
            icon={avatar}
          />
        </EditTitle>
        <EditContent>
          <SkillsEditable
            skillLevels={skillLevels}
            userDetails={userDetails}
            certificates={certificates}
            maxSkillLevel={maxSkillLevel}
          />
        </EditContent>
      </Edit>
    </EditableCard>
  );
};

export const SkillsInfo = ({
  classes,
  editable,
  userDetails,
  isResourceDetailsLoading
}) => {
  const { uri = '', resourceSkill = [] } = userDetails || {};
  const { initialValues, onSubmit } = useFormState({
    userUri: uri,
    resourceSkill
  });

  if (isResourceDetailsLoading)
    return <CardLoading title={title} avatar={avatar} />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <SkillsCard
        editable={editable}
        userDetails={userDetails}
        classes={classes}
      />
    </Formik>
  );
};

SkillsInfo.propTypes = {
  classes: PropTypes.object,
  editable: PropTypes.bool,
  userDetails: PropTypes.object,
  isResourceDetailsLoading: PropTypes.bool
};

SkillsCard.propTypes = {
  userDetails: PropTypes.object,
  editable: PropTypes.bool,
  classes: PropTypes.object
};

export default SkillsInfo;
