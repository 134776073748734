/* eslint-disable max-nested-callbacks */
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject,
  dateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { getTotalHoursForPeriodFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';

export const getScheduleRulesInChartDisplayRange = ({
  rangeStart,
  rangeEnd,
  scheduleRules
}) =>
  scheduleRules
    .map(rule => ({
      ...rule,
      startDate: mapIsoStringtoUtcObject(rule.dateRange.startDate),
      endDate: mapIsoStringtoUtcObject(rule.dateRange.endDate)
    }))
    .filter(rule => rule.startDate <= rangeEnd && rangeStart <= rule.endDate);

export const getAllocationTotalsByPeriod = ({
  chartDisplayDateRange: { startDate: chartStartDate, endDate: chartEndDate },
  chartDisplayPeriods,
  allocations,
  userScheduleDetails
}) =>
  Object.values(
    (allocations || []).reduce((acc, allocation) => {
      const { scheduleRules, startDate, endDate } = allocation;

      const allocationStartDate = mapIsoStringtoUtcObject(startDate);
      const allocationEndDate = mapIsoStringtoUtcObject(endDate);

      const noOverlap =
        allocationEndDate < chartStartDate ||
        allocationStartDate > chartEndDate;

      const scheduleRulesInRange = noOverlap
        ? []
        : getScheduleRulesInChartDisplayRange({
            rangeStart: chartStartDate,
            rangeEnd: chartEndDate,
            scheduleRules
          });

      chartDisplayPeriods.forEach(range => {
        const { startDate: start, endDate: end, key } = range;

        acc[key].totalHours += getTotalHoursForPeriodFromScheduleRules({
          start,
          end,
          scheduleRules: scheduleRulesInRange,
          useVersion2: true
        });
      });

      return acc;
    }, initialize({ chartDisplayPeriods, userScheduleDetails }))
  );

const initialize = ({
  chartDisplayPeriods,
  userScheduleDetails: { scheduleDurationByDay, scheduleDurationByPeriodMap }
}) =>
  chartDisplayPeriods.reduce((acc, range) => {
    const { startDate: start, endDate: end, key } = range;

    acc[key] = {
      key,
      startDate: start,
      endDate: end,
      totalHours: 0,
      totalScheduleHours: Object.keys(scheduleDurationByPeriodMap || {}).length
        ? scheduleDurationByPeriodMap[dateToMidnightUTCString(start.toISO())]
            .hours || 0
        : (scheduleDurationByDay || [])
            .map(d => ({
              date: mapRepliconDateToUtcObject(d.date),
              hours: d.hours
            }))
            .reduce(
              (sum, curr) =>
                curr.date >= start && curr.date <= end ? sum + curr.hours : sum,
              0
            )
    };

    return acc;
  }, {});
