import { useCallback, useEffect, useRef } from 'react';
import { v4 } from 'uuid';

import { ResourceRequestAccessLevel } from '~/types';
import { NETWORK_STATUS } from '~/modules/common/enums';

export const updateQueryOnFetchMoreResult = ({ queryId, queryIdRef }) => (
  previousResult,
  { fetchMoreResult }
) => {
  if (!fetchMoreResult || queryId !== queryIdRef.current) return previousResult;

  const previousEntry = previousResult.getResourcePlansForCurrentTenant;

  const {
    plans: newPlans,
    paginationToken: newPaginationToken
  } = fetchMoreResult.getResourcePlansForCurrentTenant;

  return {
    getResourcePlansForCurrentTenant: {
      paginationToken: newPaginationToken,
      hasNextPage: Boolean(newPaginationToken),
      plans: [...previousEntry.plans, ...newPlans],
      __typename: previousEntry.__typename
    }
  };
};

export const useLoadMoreResourcingPlans = ({
  fetchMore,
  loading,
  hasMore,
  paginationToken,
  planFilter,
  networkStatus,
  requestAccessLevel = ResourceRequestAccessLevel.All
}) => {
  const queryIdRef = useRef(v4());
  const networkStatusIsSetVariables =
    networkStatus === NETWORK_STATUS.SET_VARIABLES;

  useEffect(() => {
    queryIdRef.current = v4();
  }, [paginationToken, networkStatusIsSetVariables]);

  const loadMore = useCallback(async () => {
    if (
      !hasMore ||
      loading ||
      !paginationToken ||
      networkStatus !== NETWORK_STATUS.READY
    )
      return;

    try {
      await fetchMore({
        variables: {
          paginationToken,
          planFilter,
          requestAccessLevel
        },
        updateQuery: updateQueryOnFetchMoreResult({
          queryId: queryIdRef.current,
          queryIdRef
        })
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }, [
    hasMore,
    loading,
    paginationToken,
    networkStatus,
    fetchMore,
    planFilter,
    requestAccessLevel
  ]);

  return {
    loadMore
  };
};

export default useLoadMoreResourcingPlans;
