import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '~/modules/common/components';
import { useBillIssueDialogOpen } from './hooks';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 'bold',
    color: 'black'
  },
  description: {
    paddingBottom: theme.spacing(1.5),
    color: 'black'
  }
}));
const getResourceKeys = confirmationDialogName => ({
  header: `billDetails.${confirmationDialogName}Dialog.${confirmationDialogName}Bill`,
  details: `billDetails.${confirmationDialogName}Dialog.details`,
  description: `billDetails.${confirmationDialogName}Dialog.description`,
  cancel: `billDetails.${confirmationDialogName}Dialog.cancel`,
  actionButton: `billDetails.${confirmationDialogName}Dialog.actionButton`
});

export const BillIssueConfirmationDialog = ({
  open,
  onClose,
  confirmationDialogName,
  handleIssuePrintClick,
  handleIssueEmailClick,
  handleIssueSyncClick
}) => {
  const classes = useStyles();
  const resourceKeys = useMemo(() => getResourceKeys(confirmationDialogName), [
    confirmationDialogName
  ]);

  const { handleClose, handleClick } = useBillIssueDialogOpen({
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick,
    confirmationDialogName,
    onClose
  });

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullScreen={useMediaQuery(theme => theme.breakpoints.down('sm'), {
        noSsr: true
      })}
      open={open}
      onClose={onClose}
      TransitionProps={useMemo(() => ({ role: 'presentation' }), [])}
    >
      <DialogTitle>
        <FormattedMessage className={classes.header} id={resourceKeys.header} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" className={classes.description}>
          <FormattedMessage id={resourceKeys.details} />
        </Typography>
        <Typography variant="body2" className={classes.description}>
          <FormattedMessage id={resourceKeys.description} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id={resourceKeys.cancel} />
        </Button>
        <LoadingButton
          onClick={handleClick}
          color="primary"
          autoFocus
          isLoading={false}
          data-qe-id="IssueConfirm"
        >
          <FormattedMessage id={resourceKeys.actionButton} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

BillIssueConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  confirmationDialogName: PropTypes.string,
  handleIssuePrintClick: PropTypes.func,
  handleIssueEmailClick: PropTypes.func,
  handleIssueSyncClick: PropTypes.func
};

export default BillIssueConfirmationDialog;
