import PropTypes from 'prop-types';
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAvailableBillSummary } from '~/modules/billing-invoicing/common/hooks';
import { BillingItemsTable } from './BillingItemsTable';
import { usePageOfBillingItems } from './hooks';
import { AddLineItemsDialogError } from './AddLineItemsDialogError';

export const AddLineItemsDialogContent = ({
  classes,
  columns,
  client,
  billingItemSearch,
  billCurrency,
  setSelectedBillingItems
}) => {
  const {
    loading,
    error,
    billingItems,
    loadMoreRows,
    hasMoreRows
  } = usePageOfBillingItems({
    client,
    columns,
    billingItemSearch,
    billCurrency
  });
  const { availableBillingItemSummary } = useAvailableBillSummary({
    client,
    billCurrency,
    columns,
    billingItemSearch
  });
  const { totalAmount } = availableBillingItemSummary;

  return (
    <div className={classes.lineItemsContainer}>
      {loading ? (
        <LinearProgress variant="query" />
      ) : error ? (
        <AddLineItemsDialogError error={error} />
      ) : (
        <BillingItemsTable
          hasMoreRows={hasMoreRows}
          loadMoreRows={loadMoreRows}
          columns={columns}
          billingItems={billingItems}
          billingAmount={totalAmount}
          setSelectedBillingItems={setSelectedBillingItems}
        />
      )}
    </div>
  );
};

AddLineItemsDialogContent.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  setSelectedBillingItems: PropTypes.func.isRequired,
  client: PropTypes.object,
  billingItemSearch: PropTypes.object,
  billCurrency: PropTypes.object
};

export default AddLineItemsDialogContent;
