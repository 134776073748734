import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Icon from '@material-ui/icons/RefreshSharp';

export const RefreshButton = ({ onClick, titleLabel, disabled = false }) => (
  <Tooltip title={titleLabel}>
    <IconButton
      onClick={onClick}
      disabled={disabled}
      data-qe-id="refreshButton"
    >
      <Icon />
    </IconButton>
  </Tooltip>
);

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  titleLabel: PropTypes.string,
  disabled: PropTypes.bool
};

export default RefreshButton;
