import { useCallback } from 'react';
import {
  onAssociatedBillAmountChange as onAssociatedAmountChangePaymentCurrency,
  onAllocatedBillAmountChange as onAssociatedAmountChangeBillCurrency,
  onDescriptionChange,
  onDisplayIdChange,
  onClientChange,
  getRoundedValue
} from '~/modules/billing-invoicing/common/enhancers';

export const onPaymentDateChange = ({
  setFieldValue,
  values: { associatedBills, currency }
}) => async paymentDate => {
  setFieldValue('paymentDate', paymentDate);
};

export const onPaymentAmountChange = ({
  setFieldValue,
  values: { paymentAmount, associatedBills, currency },
  billPaymentBalanceTotal
}) => e => {
  const updatedAmount = e.target.value || 0;

  setFieldValue('paymentAmount', updatedAmount);

  const balance =
    ((billPaymentBalanceTotal && billPaymentBalanceTotal.amount) || 0) -
    paymentAmount +
    updatedAmount;

  setFieldValue('billPaymentBalanceTotal', getRoundedValue(balance));
};

export const onPaymentMethodChange = ({ setFieldValue }) => value => {
  setFieldValue('paymentMethod', value);
};

const resetAssociations = (associatedBills, currency) =>
  associatedBills.map(bill => ({
    ...bill,
    amount: { amount: 0, currency }
  }));

export const onPaymentCurrencyChange = ({
  setFieldValue,
  values: { associatedBills, currency, paymentAmount, paymentDate },
  fetchMoreExchangeRates
}) => value => {
  const newCurrency = { ...value, symbol: value.displayText };

  setFieldValue(
    'associatedBills',
    resetAssociations(associatedBills, newCurrency)
  );
  fetchMoreExchangeRates({
    variables: {
      currencyId: currency.id,
      otherCurrencyIds: [newCurrency.id],
      asOfDate: paymentDate
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      setFieldValue(
        'paymentAmount',
        paymentAmount * fetchMoreResult.currency.exchangeRates[0].rate
      );
    }
  });

  setFieldValue('currency', newCurrency);
};

export const useFormOnChange = ({
  setFieldValue,
  values,
  fetchMoreExchangeRates,
  billPaymentBalanceTotal,
  associatedBills,
  currency,
  exchangeRates
}) => ({
  onClientChange: useCallback(
    async value => {
      await onClientChange({ setFieldValue, values })(value);
    },
    [setFieldValue, values]
  ),
  onDisplayIdChange: useCallback(
    event => {
      onDisplayIdChange({ setFieldValue })(event);
    },
    [setFieldValue]
  ),
  onDescriptionChange: useCallback(
    event => {
      onDescriptionChange({ setFieldValue })(event);
    },
    [setFieldValue]
  ),
  onPaymentDateChange: useCallback(
    paymentDate => {
      onPaymentDateChange({ setFieldValue, values })(paymentDate);
    },
    [setFieldValue, values]
  ),
  onPaymentAmountChange: useCallback(
    event => {
      onPaymentAmountChange({
        setFieldValue,
        values,
        billPaymentBalanceTotal
      })(event);
    },
    [setFieldValue, values, billPaymentBalanceTotal]
  ),
  onPaymentCurrencyChange: useCallback(
    value => {
      onPaymentCurrencyChange({
        setFieldValue,
        values,
        fetchMoreExchangeRates
      })(value);
    },
    [setFieldValue, values, fetchMoreExchangeRates]
  ),
  onPaymentMethodChange: useCallback(
    value => {
      onPaymentMethodChange({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onAssociatedBillAmountChange: useCallback(
    (e, record) => {
      onAssociatedAmountChangePaymentCurrency({
        setFieldValue,
        associatedBills,
        newValue: e.target.value,
        record,
        currency,
        exchangeRates,
        billCreditBalanceTotal: billPaymentBalanceTotal,
        balanceField: 'billPaymentBalanceTotal'
      });
    },
    [
      setFieldValue,
      associatedBills,
      currency,
      exchangeRates,
      billPaymentBalanceTotal
    ]
  ),
  onAllocatedBillAmountChange: useCallback(
    (e, record) => {
      onAssociatedAmountChangeBillCurrency({
        setFieldValue,
        associatedBills,
        newValue: e.target.value,
        record
      });
    },
    [setFieldValue, associatedBills]
  )
});

export default useFormOnChange;
