import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  spacer: {
    margin: theme.spacing(1, 0, 0, 0),
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

export const useCssStyles = makeStyles(() => ({
  itemContainer: {
    flexDirection: 'column'
  }
}));

export default useStyles;
