import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const UPDATE_EARNED_REVENUE_SCRIPT = gql`
  mutation UpdateProjectEarnedRevenueScriptAndRecalculate(
    $projectId: String!
    $earnedRevenueScriptId: String
  ) {
    updateProjectEarnedRevenueScriptAndRecalculate(
      projectId: $projectId
      earnedRevenueScriptId: $earnedRevenueScriptId
    ) {
      project {
        id
        earnedRevenueScriptId
      }
      batchUri
    }
  }
`;

export const usePutEarnedRevenueScript = ({
  projectId,
  setRecalculationBatchState
}) => {
  const [updateEarnedRevenueScript] = useMutation(UPDATE_EARNED_REVENUE_SCRIPT);

  return {
    putEarnedRevenueScript: ({ earnedRevenueScriptId }) => {
      updateEarnedRevenueScript({
        variables: {
          projectId,
          earnedRevenueScriptId
        },
        update: (
          proxy,
          {
            data: {
              updateProjectEarnedRevenueScriptAndRecalculate: { batchUri }
            }
          }
        ) => {
          setRecalculationBatchState({
            batchId: batchUri,
            batchInProgress: true
          });
        }
      });
    }
  };
};

export default usePutEarnedRevenueScript;
