import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import { Waypoint } from 'react-waypoint';
import {
  createFixedWidthView,
  useFixedRange
} from '~/modules/common/charts/timeline/calculations';
import { useListSelection } from '~/modules/common/components/ListTable';
import { useHasPermission } from '~/modules/common/permissions';
import { columns } from '~/modules/common/hooks/project/projectColumns';
import BulkActionsForProjects from '../BulkActionsForProjects';
import { NoProjectData } from '../ProjectList';
import { usePaginatedFilteredProjectsQuery } from '../usePaginatedFilteredProjectsQuery';
import ProjectTimelineChartHeader from './ProjectTimelineChartHeader';
import ProjectTimelineChartRow from './ProjectTimelineChartRow';
import ProjectTimelineSkeletonRows from './ProjectTimelineSkeletonRows';
import ProjectTimelineToolbar from './ProjectTimelineToolbar';

const LEFT_COMPONENT_WIDTH = 320;

const requiredFields = [
  columns.name.field,
  columns.workflowStatus.field,
  columns.budgetHours.field,
  columns.actualHours.field
];

export const ProjectTimeline = ({ width, onRowSelectionChange }) => {
  const canEditProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });

  const {
    loading,
    projects,
    loadMore,
    loadingMore,
    refetch
  } = usePaginatedFilteredProjectsQuery({
    enabledFields: requiredFields
  });

  const rangeView = createFixedWidthView(width, LEFT_COMPONENT_WIDTH);
  const fixedRange = useFixedRange({
    getPeriodCount: rangeView.getPeriodCount,
    anchor: 0
  });
  const {
    chartDates,
    onPrevious,
    onNext,
    scale,
    setScale,
    dateRange
  } = fixedRange;

  const { selected, onSelectionChange } = useListSelection();

  const selectedProjects = canEditProject
    ? (projects || []).filter(
        p => selected.records && selected.records.includes(p.id)
      )
    : [];

  useEffect(() => {
    if (!loading) {
      onRowSelectionChange(selectedProjects);
    }
  }, [loading, selectedProjects, onRowSelectionChange]);

  const handleRowSelectionChange = useCallback(
    (id, checked) => {
      onSelectionChange({
        ...selected,
        records: checked
          ? [...selected.records, id]
          : selected.records.filter(r => r !== id)
      });
    },
    [selected, onSelectionChange]
  );

  const showBulkActions =
    !loading && canEditProject && selected?.records?.length > 0;

  return (
    <>
      <ProjectTimelineToolbar
        onPreviousClick={onPrevious}
        onNextClick={onNext}
        dateRange={dateRange}
        scale={scale}
        onScaleChange={setScale}
      />
      <ProjectTimelineChartHeader
        scale={scale}
        chartDates={chartDates}
        leftComponentWidth={LEFT_COMPONENT_WIDTH}
        isSelectionEnabled={canEditProject}
      />
      {loading && <ProjectTimelineSkeletonRows />}
      {!loading && projects.length === 0 && <NoProjectData />}
      {!loading && (
        <>
          {(projects || []).map(project => (
            <ProjectTimelineChartRow
              key={project.id}
              project={project}
              scale={scale}
              isSelected={selected.records.includes(project.id)}
              leftComponentWidth={LEFT_COMPONENT_WIDTH}
              chartDates={chartDates}
              chartDisplayDateRange={dateRange}
              onRowSelectionChange={
                canEditProject ? handleRowSelectionChange : false
              }
            />
          ))}
          {loadingMore && <ProjectTimelineSkeletonRows />}
          <Waypoint
            fireOnRapidScroll={!loading && !loadingMore}
            bottomOffset="-50%"
            onEnter={loadMore}
            key={(projects || []).length}
          />
          {showBulkActions && (
            <BulkActionsForProjects
              selected={selected}
              projects={selectedProjects}
              selectedProjects={selectedProjects}
              onSelectionChange={onSelectionChange}
              refetchProjects={refetch}
            />
          )}
        </>
      )}
    </>
  );
};

ProjectTimeline.propTypes = {
  width: PropTypes.number,
  onRowSelectionChange: PropTypes.func.isRequired
};

export default withResizeDetector(ProjectTimeline);
