import { getSessionStorage } from '~/modules/common/hooks';
import { getUpdatedAllocatedUserListFromAllocations } from '~/modules/resourcing/common/util/allocationCacheUpdates';
import {
  tryResourcePlanAllocatedUsersAndRolesCachedQuery,
  GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES
} from '../../../hooks/useFetchAllocatedUsersAndRoles';
import { GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES } from '../../../hooks/useFetchResourcePlanUsersAndRoles';
import ALLOCATIONS_QUERY from './useProjectCommitedAllocationQuery';

export const tryLoadCachedQuery = ({ proxy, variables }) => {
  try {
    return proxy.readQuery({
      query: ALLOCATIONS_QUERY,
      variables
    });
  } catch {
    return null;
  }
};

export const useUpdateCompletedResourceAllocationProjectCacheOnAddWithProposedRequest2 = ({
  allocationChartRef
}) => () => (proxy, mutationResponse) => {
  const {
    data: {
      markResourceRequestAsComplete2: {
        isOptimistic,
        resourceRequest: { resourceAllocations }
      }
    }
  } = mutationResponse;

  if (isOptimistic) return;

  const newUsers = resourceAllocations.map(allocation => allocation.user);

  const variables = getSessionStorage(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES,
    null
  );

  const cachedResults = tryResourcePlanAllocatedUsersAndRolesCachedQuery({
    proxy,
    variables
  });

  const userList = cachedResults
    ? getUpdatedAllocatedUserListFromAllocations({
        newAllocations: resourceAllocations,
        cachedAllocatedUsers:
          cachedResults.project.resourcePlanAllocatedUsersAndRoles
      })
    : [];

  proxy.writeQuery({
    query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
    variables,
    data: {
      project: {
        ...cachedResults?.project,
        resourcePlanAllocatedUsersAndRoles: userList
      }
    }
  });

  allocationChartRef?.current.handleResourceAllocationUserChange({
    newUsers: newUsers.map(user => user.user)
  });
  allocationChartRef?.current.handleLoadingStateOnResourceRequestAccept({
    newUsers
  });
};
