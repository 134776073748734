/* eslint-disable max-depth */
import React, { useContext, useMemo, ComponentType, ReactNode } from 'react';
import { ApolloError } from '@apollo/client';

import { useGetMeQuery, GetMeQuery, Maybe, Permission } from '~/types';

import { LinearQueryProgress } from '~/modules/common';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';

import { MeContext, MeType } from './context';

export const injectMe = ({
  Loading = LinearQueryProgress,
  Error = RedirectToUnhandledError,
  SpecificErrorHandlers = []
}: {
  Loading?: ComponentType;
  Error?: ComponentType;
  SpecificErrorHandlers?: {
    predicate: (error: ApolloError | undefined) => boolean;
    Component: ComponentType;
  }[];
} = {}) => (Component: ComponentType) => (props: any): ReactNode => {
  const {
    loading,
    error,
    data: { me = {} as Maybe<GetMeQuery['me']> } = {}
  } = useGetMeQuery();

  const memoMe: Maybe<MeType> = useMemo(
    () =>
      me
        ? {
            ...me,
            permissionsMap: (me.permissions || []).reduce(
              (result: any, current: any) => {
                if (current?.permissionActionUri) {
                  return {
                    ...result,
                    [current.permissionActionUri]: current as Permission
                  };
                }

                return result;
              },
              {}
            ) as { [key: string]: Permission }
          }
        : null,
    [me]
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !me) {
    for (const handler of SpecificErrorHandlers) {
      if (handler.predicate(error)) {
        return <handler.Component />;
      }
    }

    return <Error />;
  }

  return (
    <MeContext.Provider value={memoMe}>
      <Component {...props} me={memoMe} />
    </MeContext.Provider>
  );
};

const withMe = () => (Component: ComponentType) => (props: any): ReactNode => {
  const me = useContext(MeContext);

  return <Component {...props} me={me} />;
};

export default withMe;
