import { gql } from 'graphql-tag';
import React from 'react';
import get from 'lodash.get';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';
import { projectDate2Fragment } from '~/modules/resourcing/common/fragments/specificProjectFragment';

export const projectQuery = gql`
  query getProjectBySlug($projectSlug: String!) {
    project(projectSlug: $projectSlug) {
      ...SpecificProjectDate2Fragment
      copyProjectJobId
      budgetedCost {
        currency {
          id
          displayText
        }
      }
    }
  }
  ${projectDate2Fragment}
`;

export const useProjectInfo = () => {
  const {
    params: { slug }
  } = useRouteMatch();

  const { loading, data, error } = useQuery(projectQuery, {
    variables: {
      projectSlug: slug
    }
  });

  if (error) return <RedirectToUnhandledError />;

  const project = get(data, 'project', {});

  return { project, isLoading: loading, error };
};
