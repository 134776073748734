import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  field: {
    height: '48px',
    borderRadius: '8px',
    marginTop: '8px',
    width: '100%'
  },
  shimmer: {},
  label: {}
}));

const FormFieldShimmer = () => {
  const classes = useStyles();

  return (
    <Skeleton
      variant="rect"
      className={classNames(classes.field, classes.shimmer)}
    />
  );
};

export default FormFieldShimmer;
