import { gql } from 'graphql-tag';
import { ResourceAllocationStatus, ResourceRequestStatus } from '~/types';

const projectTotalsFragment2 = gql`
  fragment ProjectTotalsFragment2 on Project {
    id
    allocationsTotals(
      allocationStatusList: $allocationStatusList
      filter: $filter
      includeActualsResourceUsers: $includeActualsResourceUsers
    ) {
      totalHours
      totalCost {
        amount
        currency {
          id
          symbol
          name
        }
      }
    }
    resourceRequestsTotals(
      requestStatusList: $requestStatusList
      filter: $filter
    ) {
      totalHours
      totalCost {
        amount
        currency {
          id
          symbol
          name
        }
      }
    }
  }
`;

export const RESOURCE_REQUEST_STATUS_LIST = [
  ResourceRequestStatus.Draft,
  ResourceRequestStatus.Rejected,
  ResourceRequestStatus.Submitted,
  ResourceRequestStatus.Tobehired
];

export const ALLOCATION_STATUS_LIST = [ResourceAllocationStatus.Committed];

export default projectTotalsFragment2;
