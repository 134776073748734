import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PreferredResourceIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} data-qe-id="preferredResourceIcon" ref={ref}>
    <>
      <path d="M 11.867188,0.53125 8.27344,8.101562 0,9.43164 6.08789,15.189453 4.79688,23.46875 12.154297,19.457031 19.630859,23.24414 18.087891,15.007812 24,9.066406 15.689453,7.988281 Z M 12,8 c 1.243124,0 2.25,1.006868 2.25,2.25 0,1.243129 -1.006876,2.25 -2.25,2.25 -1.243124,0 -2.25,-1.006871 -2.25,-2.25 C 9.75,9.006868 10.756876,8 12,8 Z m 0,5.625 c 1.501874,0 4.5,0.753751 4.5,2.25 V 17 h -9 v -1.125 c 0,-1.496249 2.998127,-2.25 4.5,-2.25 z" />
    </>
  </SvgIcon>
));

export default PreferredResourceIcon;
