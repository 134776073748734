import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

export const GetSkillSettingQuery = gql`
  query getAllSkillSetting {
    skillSettings {
      id
      uri
      displayText
      type
      dropDownOptions
    }
  }
`;

export const useSkillSettings = () => {
  const { loading: isSkillSettingsLoading, error, data } = useQuery(
    GetSkillSettingQuery,
    {}
  );

  return {
    error,
    skillSettings: get(data, 'skillSettings', []),
    isSkillSettingsLoading
  };
};

export default useSkillSettings;
