import { DateTime } from 'luxon';

export const longDateRangeFormat = ({
  start,
  end,
  format = DateTime.DATE_MED
}) => {
  if (start && end && start === end) return start.toLocaleString(format);

  return `${start ? start.toLocaleString(format) : ''} — ${
    end ? end.toLocaleString(format) : ''
  }`;
};

export const shortDateRangeFormat = ({
  start,
  end,
  format = DateTime.DATE_MED
}) => {
  if (start && end && start === end) {
    return start.toLocaleString(format);
  }

  if (start && end && start.year === end.year) {
    if (start.month === end.month) {
      return `${start.toLocaleString({
        month: 'short',
        day: 'numeric'
      })}—${end.toFormat('d yyyy')}`;
    }

    return `${start.toLocaleString({
      month: 'short',
      day: 'numeric'
    })} — ${end.toLocaleString(DateTime.DATE_MED)}`;
  }

  return longDateRangeFormat({ start, end, format });
};
