import React, { useMemo } from 'react';
import { LaunchSharp } from '@material-ui/icons';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import ImportExportGroup from '~/modules/common/components/ImportExportMenu';
import { useHasFeatureFlag, useSalesforceStatus } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import { useHasPermission } from '~/modules/common/permissions';
import {
  IMPORT_OPTIONS,
  EXPORT_OPTIONS,
  STATUS_OPTIONS
} from '~/modules/common/components/ImportExportMenu/enums/ImportExportMenuEnums';
import { ProjectManagementType } from '~/types';
import { useTableSettings } from '~/modules/common/components/ListTable';
import { TABLE_KEY } from '~/modules/common/hooks/project/projectColumns';
import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { useProjectsContext } from '../../ProjectsContext';
import { LIST } from '../../projectListTabs';
import { useProjectFilterMemo } from '../ProjectListPage/useProjectFilterMemo';
import { csvExportColumnNamesOverride } from './utils';

const { IMPORT, CSV_IMPORT, SALESFORCE } = IMPORT_OPTIONS;
const { EXPORT, CSV_EXPORT } = EXPORT_OPTIONS;
const { ENABLED, NOT_ENABLED } = STATUS_OPTIONS;

export const ProjectImportExportMenu = ({ isMobile }) => {
  const {
    view,
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();
  const intl = useIntl();
  const isSalesforceFFEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaSalesforceIntegrationEnabled'
  });

  const launchIcon = <LaunchSharp />;
  const salesforce = useSalesforceStatus();
  const hasSalesforcePermission = useHasPermission({
    actionUri: 'urn:replicon:salesforce-integration-action:edit-settings'
  });

  const isManaged = projectType === ProjectManagementType.Managed;
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });

  const { uiRootPath } = useMeContext();

  const viewDataImportPermission = useHasPermission({
    actionUri: 'urn:replicon-webui:project-data-import:edit-import'
  });
  const {
    enabledProjectColumnNames = [],
    enabledProjectColumnOrder = []
  } = useEnabledProjectColumns();

  const { data, loading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: enabledProjectColumnNames
  });
  const activeColumns = get(data, 'columns') || [];

  const actions = useMemo(
    () => [
      (viewDataImportPermission ||
        (isSalesforceFFEnabled && salesforce.status)) && {
        label: IMPORT,
        actionItems: [
          ...(viewDataImportPermission
            ? [
                {
                  label: CSV_IMPORT,
                  href: `${uiRootPath}admin/data-import/project`
                }
              ]
            : []),
          ...(isSalesforceFFEnabled && salesforce.status && !salesforce.loading
            ? [
                {
                  label: SALESFORCE,
                  href: salesforce.status.href,
                  secondaryIcon: hasSalesforcePermission ? launchIcon : null,
                  isSFConnected: salesforce.status.isConnected,
                  secondaryLabel: salesforce.status.isConnected
                    ? ENABLED
                    : NOT_ENABLED,
                  permission: hasSalesforcePermission
                }
              ]
            : [])
        ]
      },
      view === LIST &&
        !loading && {
          label: EXPORT,
          actionItems: [
            {
              label: CSV_EXPORT,
              tableKey: TABLE_KEY,
              activeColumns: enabledProjectColumnOrder.filter(x =>
                activeColumns.includes(x)
              ),
              activeColumnNameOverrides: Object.keys(
                csvExportColumnNamesOverride
              ).map(c => ({
                column: c,
                name: intl.formatMessage({
                  id: csvExportColumnNamesOverride[c]
                })
              })),
              filter: { projectFilter, isManaged }
            }
          ]
        }
    ],
    [
      viewDataImportPermission,
      isSalesforceFFEnabled,
      salesforce.status,
      salesforce.loading,
      uiRootPath,
      hasSalesforcePermission,
      launchIcon,
      view,
      loading,
      enabledProjectColumnOrder,
      projectFilter,
      isManaged,
      activeColumns,
      intl
    ]
  );

  return (
    <ImportExportGroup
      actions={actions}
      isMobile={isMobile}
      menuId="projects-import-export-menu"
    />
  );
};

ProjectImportExportMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default ProjectImportExportMenu;
