import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TASK_STATUS } from '~/modules/common/enums';
import VariationFormattedDate from '../../VariationFormattedDate';

export const DateVariationFormatter = ({ field, record }) => {
  const value = record[field];

  return value !== null ? (
    <VariationFormattedDate
      days={record.taskStatus === TASK_STATUS.NOTSTARTED ? 0 : value}
    />
  ) : (
    <FormattedMessage id="taskDrawer.na" />
  );
};

DateVariationFormatter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default DateVariationFormatter;
