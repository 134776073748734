import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { IconButton, List, ListItem, Popover } from '@material-ui/core';
import { DatePicker } from '~/modules/common/components';
import { useMeContext } from '~/modules/me';
import {
  getLuxonJsDateFormatFromMe,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import {
  getUserPreferenceWeekStart,
  getUserPreferenceWeekEnd
} from '~/modules/common/dates/dayOfWeek';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import { getDateRangeForScale } from '../useChartChangeHandlers';
import ChartDateRangeLabel from '../ChartDateRangeLabel';
import ChartDateRangeQuarterPicker from './ChartDateRangeQuarterPicker';
import useStyles from './useStyles';

const anchorProps = { vertical: 'top', horizontal: 'left' };

export const getPickerProps = (me, scale, classes) => {
  if (scale === PERIOD_SCALE_ENUM.WEEKS) {
    return {
      renderDay: (date, selectedDate, dayInCurrentMonth) => {
        const start = getUserPreferenceWeekStart(me, selectedDate);
        const end = getUserPreferenceWeekEnd(me, selectedDate);
        const isFirstDay = date.hasSame(start, 'day');
        const isLastDay = date.hasSame(end, 'day');

        const dayIsBetween = date >= start && date <= end;

        const wrapperClassName = classNames({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay
        });

        const dayClassName = classNames(classes.day, {
          [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
          [classes.highlightNonCurrentMonthDay]:
            !dayInCurrentMonth && dayIsBetween
        });

        return (
          <div className={wrapperClassName}>
            <IconButton className={dayClassName}>
              <span> {date.toFormat('d')} </span>
            </IconButton>
          </div>
        );
      }
    };
  }
  if (scale === PERIOD_SCALE_ENUM.MONTHS) {
    return {
      views: ['year', 'month'],
      openTo: 'month'
    };
  }
  if (scale === PERIOD_SCALE_ENUM.YEARS) {
    return {
      views: ['year'],
      openTo: 'year'
    };
  }

  return {};
};

export const ChartDateRangePicker = ({
  menuId,
  anchorEl,
  scale,
  dateRange,
  onClose,
  onChange,
  setDateValue
}) => {
  const isOpen = Boolean(anchorEl);
  const me = useMeContext();
  const classes = useStyles();
  const weekStart = getUserPreferenceWeekStart(me, DateTime.local());
  const weekEnd = getUserPreferenceWeekEnd(me, DateTime.local());

  const todayDateRange = useMemo(
    () =>
      scale === PERIOD_SCALE_ENUM.WEEKS
        ? {
            startDate: weekStart,
            endDate: weekEnd
          }
        : {
            startDate: DateTime.local(),
            endDate: DateTime.local()
          },
    [scale, weekEnd, weekStart]
  );

  const luxonFormat = getLuxonJsDateFormatFromMe(me);

  const handleDateChange = useCallback(
    value => {
      if (onChange) {
        setDateValue
          ? onChange(value)
          : onChange(getDateRangeForScale({ me, date: value, scale }));
      }
      if (onClose) onClose();
    },
    [me, onChange, onClose, scale, setDateValue]
  );

  const handleTodayClick = useCallback(() => {
    if (onChange) onChange(todayDateRange);

    if (onClose) onClose();
  }, [onChange, onClose, todayDateRange]);

  const pickerProps = getPickerProps(me, scale, classes);

  return scale === PERIOD_SCALE_ENUM.QUARTERS ? (
    <ChartDateRangeQuarterPicker
      dateRange={dateRange}
      menuId={menuId}
      anchorEl={anchorEl}
      onChange={onChange}
      onClose={onClose}
    />
  ) : (
    <Popover
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={anchorProps}
      transformOrigin={anchorProps}
      open={isOpen}
      onClose={onClose}
    >
      <DatePicker
        {...pickerProps}
        autoOk
        variant="static"
        format={luxonFormat}
        value={mapIsoStringtoUtcObject(dateRange.startDate.toISODate())}
        onChange={handleDateChange}
      />
      <List disablePadding className={classes.quickLinksList}>
        <ListItem button onClick={handleTodayClick}>
          <ChartDateRangeLabel
            scale={scale}
            start={todayDateRange.startDate}
            end={todayDateRange.endDate}
          />
        </ListItem>
      </List>
    </Popover>
  );
};

ChartDateRangePicker.propTypes = {
  menuId: PropTypes.string.isRequired,
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.WEEKS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.YEARS
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  dateRange: PropTypes.object,
  setDateValue: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default ChartDateRangePicker;
