import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

export const TAGS_QUERY = gql`
  query getTags($searchTerm: String) {
    tags(page: 1, pageSize: 20, searchTerm: $searchTerm) {
      id
      name
      values {
        id
        value
      }
    }
  }
`;

export const useSearchableTags = () => {
  const apolloClient = useApolloClient();

  const fetchOptions = useCallback(
    fetchTags({
      apolloClient
    }),
    [apolloClient]
  );

  return { fetchOptions };
};

export const fetchTags = ({ apolloClient }) => async searchTerm => {
  const { data: { tags = [] } = {} } = await apolloClient.query({
    query: TAGS_QUERY,
    variables: {
      searchTerm
    },
    context: {
      debounceKey: 'tags-search',
      debounceTimeout: 250
    }
  });

  const transformedTags = tags
    .map(tag =>
      tag.values.reduce(
        (acc, curr) => [
          ...acc,
          {
            tag: {
              id: tag.id,
              name: tag.name
            },
            value: {
              ...curr
            }
          }
        ],
        []
      )
    )
    .reduce((acc, curr) => acc.concat(curr), []);

  return transformedTags.map(t => ({
    ...t,
    displayText: `${t.tag.name}+${t.value.value}`,
    label: `${t.tag.name}+${t.value.value}`,
    value: t.value.id,
    id: t.value.id,
    key: `${t.tag.name}+${t.value.value}`,
    tag: {
      label: t.tag.name,
      value: t.tag.id
    }
  }));
};
