const optimisticGroupResponse = value =>
  value
    ? {
        ...value,
        __typename: 'Group'
      }
    : null;

export const optimisticResponse = ({
  projectUri,
  location,
  division,
  serviceCenter,
  costCenter,
  department,
  employeeType
}) => ({
  __typename: 'Mutation',
  updateProject2: {
    __typename: 'Project',
    id: projectUri,
    location: optimisticGroupResponse(location),
    division: optimisticGroupResponse(division),
    serviceCenter: optimisticGroupResponse(serviceCenter),
    costCenter: optimisticGroupResponse(costCenter),
    department: optimisticGroupResponse(department),
    employeeType: optimisticGroupResponse(employeeType)
  }
});
