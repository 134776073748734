import { gql } from 'graphql-tag';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import { useQuery, useApolloClient } from '@apollo/client';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PAGE_SIZE } from '~/modules/common/const';

export const SKILLS_QUERY = gql`
  query GetEnabledSkills($page: Int!, $pageSize: Int!, $searchTerm: String) {
    enabledSkills(page: $page, pageSize: $pageSize, searchTerm: $searchTerm) {
      id
      uri
      displayText
      category {
        id
        uri
        displayText
      }
    }
    unavailableSkillLevel {
      uri
      displayText
    }
  }
`;

export const getSkillsGroupedByCategory = options => {
  const uniqueCategories = sortedUniqBy(
    sortBy(options, skillItem => skillItem.category.displayText),
    skillItem => skillItem.category.uri
  ).map(option => option.category);

  return uniqueCategories.reduce((groups, category) => {
    const sortedOptions = options.sort((a, b) =>
      a.displayText.localeCompare(b.displayText)
    );

    return [
      ...groups,
      {
        uri: category.uri,
        id: category.uri,
        label: category.displayText,
        key: category.displayText,
        options: sortedOptions.filter(
          skillItem => skillItem.category.uri === category.uri
        )
      }
    ];
  }, []);
};

export const getSortedSkillsByCategory = options =>
  sortBy(options, skillItem => skillItem.category.displayText);

export const useEnabledSkills = ({
  includeNoneOption = false,
  noneOptionText = '',
  searchTerm = ''
}) => {
  const apolloClient = useApolloClient();

  const {
    loading,
    data: { enabledSkills, unavailableSkillLevel } = {}
  } = useQuery(SKILLS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pageSize: PAGE_SIZE + 1,
      searchTerm
    },
    context: {
      debounceKey: 'user-skill-search',
      debounceTimeout: 250
    }
  });

  const enabledSkillsWithDefault = enabledSkills || [];

  const enabledSkillsWithSkillLevel = enabledSkillsWithDefault.map(u => ({
    ...u,
    skillLevel: {
      ...unavailableSkillLevel,
      id: unavailableSkillLevel.uri
    },
    value: u.uri,
    label: u.displayText,
    key: u.displayText,
    customMetadata: []
  }));

  const defaultOptions = getSkillsGroupedByCategory(
    enabledSkillsWithSkillLevel
  );

  const sortedOptionsWithCategory = getSortedSkillsByCategory(
    enabledSkillsWithSkillLevel
  );

  const fetchOptions = fetchSkills({
    apolloClient
  });

  return {
    isLoading: loading,
    fetchOptions,
    defaultOptions,
    sortedOptionsWithCategory: (sortedOptionsWithCategory.length &&
    includeNoneOption
      ? prependNoneItem(noneOptionText, sortedOptionsWithCategory)
      : sortedOptionsWithCategory
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    enabledSkills: enabledSkillsWithDefault,
    hasMore: sortedOptionsWithCategory.length > PAGE_SIZE
  };
};

export const fetchSkills = ({ apolloClient }) => async searchTerm => {
  const {
    data: { enabledSkills = [], unavailableSkillLevel } = {}
  } = await apolloClient.query({
    query: SKILLS_QUERY,
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
      searchTerm
    },
    context: {
      debounceKey: 'user-skill-search',
      debounceTimeout: 250
    }
  });

  const enabledSkillsWithDefault = enabledSkills || [];

  return getSkillsGroupedByCategory(
    enabledSkillsWithDefault.map(u => ({
      ...u,
      skillLevel: {
        ...unavailableSkillLevel,
        id: unavailableSkillLevel.uri
      },
      value: u.uri,
      label: u.displayText,
      key: u.displayText,
      customMetadata: []
    }))
  );
};

export const addLocalSkillToCache = ({ client, skill }) => {
  const cache = client.readQuery({
    query: SKILLS_QUERY,
    variables: {
      page: 1,
      pageSize: PAGE_SIZE + 1,
      searchTerm: ''
    }
  });

  const { enabledSkills } = cache;

  const updatedSkills = [
    ...enabledSkills,
    {
      __typename: 'Skill',
      ...skill,
      category: {
        __typename: 'Category',
        uri: skill.category.id,
        ...skill.category
      }
    }
  ];

  client.writeQuery({
    query: SKILLS_QUERY,
    variables: {
      page: 1,
      pageSize: PAGE_SIZE + 1,
      searchTerm: ''
    },
    data: {
      ...cache,
      enabledSkills: sortBy(updatedSkills, s => s.displayText)
    }
  });
};

export const useSearchableSkills = () => {
  const apolloClient = useApolloClient();

  const fetchOptions = fetchSkills({
    apolloClient
  });

  return { fetchOptions };
};

export default useEnabledSkills;
