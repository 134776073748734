import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { POLICYTYPE } from '~/modules/common/enums';
import useDepartments from '~/modules/common/enhancers/useDepartments';
import { onChange } from '../enhancers/facetHelpers';
import GroupFacetDetails from '../GroupFacetDetails';

export const DepartmentFacetDetails = ({
  className,
  selected,
  setSelected,
  policy = POLICYTYPE.PROJECT_MANAGEMENT
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const noneOptionText = formatMessage({
    id: 'departmentDropdown.noDepartment'
  });

  const { departments, loading, hasMore } = useDepartments({
    searchTerm,
    policy,
    includeNoneOption: true,
    noneOptionText
  });

  return (
    <GroupFacetDetails
      label={formatMessage({ id: 'search.department' })}
      className={className}
      selected={selected}
      onChange={onChange}
      setSelected={setSelected}
      isLoading={loading}
      setSearchTerm={setSearchTerm}
      options={departments}
      noOptionsLabel={formatMessage({ id: 'departmentDropdown.noDepartment' })}
      hasMore={hasMore}
      optionTypeText={formatMessage({
        id: 'moreOptions.department'
      })}
    />
  );
};

DepartmentFacetDetails.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  policy: PropTypes.oneOf([
    POLICYTYPE.PROJECT_MANAGEMENT,
    POLICYTYPE.PORTFOLIO_MANAGEMENT,
    POLICYTYPE.RESOURCE_MANAGEMENT,
    POLICYTYPE.REVENUE_MANAGEMENT
  ])
};

export default DepartmentFacetDetails;
