import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import CancelButton from '~/modules/common/components/CancelButton';
import UnsavedChangesTitle from './UnsavedChangesTitle';

export const UnsavedChangesSubmitPrompt = ({
  message,
  onSave,
  open,
  onClose
}) => {
  const onSaveClick = useCallback(() => {
    onSave();
  }, [onSave]);

  const onCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography role="presentation" variant="h6">
          <UnsavedChangesTitle />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CancelButton variant="text" onClick={onCloseClick} />
        <Button variant="text" onClick={onSaveClick}>
          <FormattedMessage id="button.saveChanges" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnsavedChangesSubmitPrompt.propTypes = {
  message: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default UnsavedChangesSubmitPrompt;
