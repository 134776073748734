import { getSeriesDataMap } from '~/modules/common/enhancers';

export default ({
  periods,
  scale,
  availableToBill: { amountTotal },
  totalBilledSeriesDataMap,
  sectionData: { adhocInvoiceItemBillings }
}) => {
  const adhocBillingsSeriesMap = getSeriesDataMap({
    scale,
    rowData: adhocInvoiceItemBillings
  }).seriesDataMap;

  const availableToBillTotalSeriesMap = getSeriesDataMap({
    scale,
    rowData: amountTotal
  }).seriesDataMap;

  const dataPointMap = periods.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.key]:
        (availableToBillTotalSeriesMap[current.key] || 0) -
        ((totalBilledSeriesDataMap[current.key] || 0) -
          (adhocBillingsSeriesMap[current.key] || 0))
    }),
    {}
  );

  return {
    unbilledSeriesDataMap: {
      ...dataPointMap,
      total:
        (availableToBillTotalSeriesMap.total || 0) -
        ((totalBilledSeriesDataMap.total || 0) -
          (adhocBillingsSeriesMap.total || 0)),
      currencySymbol: totalBilledSeriesDataMap.currencySymbol
    }
  };
};
