import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { mapExchangeRateToCurrency, extractCurrencyIdsFromBills } from './util';

export const EXCHANGE_RATE_QUERY = gql`
  query getExchangeRate(
    $currencyId: String!
    $otherCurrencyIds: [String]!
    $asOfDate: Date!
  ) {
    currency(id: $currencyId) {
      id
      exchangeRates(otherCurrencyIds: $otherCurrencyIds, asOfDate: $asOfDate) {
        currencyId
        rate
      }
    }
  }
`;

export const useExchangeRates = ({
  currency: { id: currencyId } = {},
  paymentDate = null,
  creditDate = null,
  associatedAndOutstandingBills = [],
  isMultiCurrency
}) => {
  const { loading, data, fetchMore } = useQuery(EXCHANGE_RATE_QUERY, {
    variables: {
      currencyId,
      otherCurrencyIds: extractCurrencyIdsFromBills(
        currencyId,
        associatedAndOutstandingBills
      ),
      asOfDate: paymentDate || creditDate
    },
    skip: !isMultiCurrency,
    fetchPolicy: 'network-only'
  });

  const currency = get(data, 'currency', {});
  const { id, exchangeRates } = currency;

  return {
    fetchMoreExchangeRates: fetchMore,
    exchangeRates: mapExchangeRateToCurrency(id, exchangeRates),
    isExchangeRateLoading: loading
  };
};

export default useExchangeRates;
