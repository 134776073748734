import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { object, string } from 'yup';
import { useMeContext } from '~/modules/me';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { getValidationErrorByUri } from '~/modules/common/graphql/errors';
import usePutPortfolio from './usePutPortfolioMutation';

const STRING_MAX_LENGTH = 255;
const validationMessages = {
  nameRequired: (
    <FormattedMessage id="portfolio.validationMessages.nameRequired" />
  ),
  nameExceedsMaxLength: (
    <FormattedMessage id="portfolio.validationMessages.nameExceedsMaxLength" />
  ),
  codeExceedsMaxLength: (
    <FormattedMessage id="portfolio.validationMessages.codeExceedsMaxLength" />
  )
};

export const validationSchema = object().shape({
  name: string()
    .trim()
    .max(STRING_MAX_LENGTH, validationMessages.nameExceedsMaxLength)
    .required(validationMessages.nameRequired),
  code: string()
    .trim()
    .max(50, validationMessages.codeExceedsMaxLength)
});

export const useAddPortfolio = ({
  parentId,
  parentName,
  onClose,
  portfolio,
  putMutationProps
}) => {
  const { putPortfolio } = usePutPortfolio(putMutationProps);
  const { uri, displayText, userAccessRoles } = useMeContext();
  const { formatMessage } = useIntl();

  const initialState = {
    id: portfolio?.id || undefined,
    name: portfolio?.name || undefined,
    code: portfolio?.code || undefined,
    parent: portfolio?.parent || undefined,
    description: portfolio?.description || undefined,
    status: portfolio?.status || undefined,
    portfolioManager: portfolio
      ? portfolio.portfolioManager
      : userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_MANAGER)
      ? {
          id: uri,
          displayText
        }
      : undefined
  };

  const onSubmit = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      const { id, name, description, code, portfolioManager, status } = values;

      try {
        await putPortfolio({
          id,
          name,
          description: description || undefined,
          code: code || undefined,
          parentId,
          portfolioManager: portfolioManager?.id || null,
          status: status || null
        });
        onClose();
      } catch (err) {
        const duplicationNameError = getValidationErrorByUri(
          err,
          'urn:replicon:validation-failure:portfolio-name-duplicated'
        );

        if (duplicationNameError && !parentId)
          setFieldError(
            'name',
            formatMessage({
              id: 'portfolio.validationMessages.duplicateRootPortfolio'
            })
          );
        if (duplicationNameError && parentId)
          setFieldError(
            'name',
            formatMessage(
              {
                id: 'portfolio.validationMessages.duplicateSubPortfolio'
              },
              { parentName }
            )
          );
      } finally {
        setSubmitting(false);
      }
    },
    [formatMessage, onClose, parentId, parentName, putPortfolio]
  );

  return {
    initialState,
    onSubmit,
    validationSchema
  };
};

export default useAddPortfolio;
