import PropTypes from 'prop-types';

export const DisplayText = ({ field, record }) => {
  const obj = record[field];

  return obj && obj.displayText;
};

DisplayText.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default DisplayText;
