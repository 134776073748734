import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { OpenInDrawerIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  openButton: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'center',
    margin: theme.spacing(-0.5, -1, -0.25, -2),
    padding: theme.spacing(1),
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    opacity: 0.3,
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  }
}));

export const IconLinkButton = ({
  qeIdTag,
  classes: classesOverrides,
  toLink,
  ariaLabel,
  CustomIcon = OpenInDrawerIcon,
  disabled = false
}) => {
  const classes = useStyles({ classes: classesOverrides });

  return (
    <IconButton
      data-qe-id={qeIdTag}
      classes={useMemo(() => ({ root: classes.openButton }), [
        classes.openButton
      ])}
      component={Link}
      to={toLink}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      <CustomIcon />
    </IconButton>
  );
};

IconLinkButton.propTypes = {
  classes: PropTypes.object,
  toLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ariaLabel: PropTypes.string,
  qeIdTag: PropTypes.string,
  disabled: PropTypes.bool,
  CustomIcon: PropTypes.object
};

export default IconLinkButton;
