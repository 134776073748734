import React from 'react';
import PropTypes from 'prop-types';
import { FormNumberField, FormTextField } from '~/modules/common/components';
import {
  DATE_TYPE_URI,
  DROP_DOWN_TYPE_URI,
  NUMERIC_TYPE_URI,
  TEXT_TYPE_URI
} from '../definitionTypes';
import CustomFieldValuePropType from './CustomFieldValuePropType';
import EditableDateCustomField from './EditableDateCustomField';
import EditableDropDownCustomField from './EditableDropDownCustomField';

export const EditableCustomField = ({
  customFieldValue,
  onChange,
  name,
  ...rest
}) => {
  const {
    customField,
    customFieldType,
    number,
    text,
    customFieldDefinition: definition
  } = customFieldValue;
  const { id: typeUri } = customFieldType;
  const { displayText: label } = customField;

  switch (typeUri) {
    case DATE_TYPE_URI:
      return (
        <EditableDateCustomField
          id={name}
          required={definition.isRequired}
          customFieldValue={customFieldValue}
          onChange={onChange}
          {...rest}
        />
      );
    case DROP_DOWN_TYPE_URI:
      return (
        <EditableDropDownCustomField
          name={name}
          customFieldValue={customFieldValue}
          onChange={onChange}
          required={definition.isRequired}
          {...rest}
        />
      );
    case NUMERIC_TYPE_URI:
      return (
        <FormNumberField
          name={name}
          editable
          label={label}
          required={definition.isRequired}
          value={number}
          onValueChange={onChange}
          useNumberLabelStyles={false}
          precision={
            definition.numericConfiguration.decimalPlaces
              ? definition.numericConfiguration.decimalPlaces
              : 0
          }
          {...rest}
        />
      );
    case TEXT_TYPE_URI:
    default:
      return (
        <FormTextField
          id={name}
          required={definition.isRequired}
          editable
          label={label}
          value={text}
          onChange={onChange}
          {...rest}
        />
      );
  }
};

EditableCustomField.propTypes = {
  customFieldValue: CustomFieldValuePropType,
  onChange: PropTypes.func,
  name: PropTypes.string
};

export default EditableCustomField;
