import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { get } from 'lodash';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';

export const projectSummaryQuery = gql`
  query ProjectOverviewSummary(
    $projectId: String!
    $dateRange: DateTimeRangeInput
  ) {
    project(projectId: $projectId) {
      id
      costAmountSummary(dateRange: $dateRange) {
        costActual {
          amount
          currency {
            id
            uri
            displayText
            name
            symbol
          }
        }
      }
      revenueAmountSummary(dateRange: $dateRange) {
        revenueActual {
          amount
          currency {
            id
            uri
            displayText
            name
            symbol
          }
        }
      }
      timeEnteredSummary(dateRange: $dateRange) {
        timeEnteredActual
      }
    }
  }
`;

export const useProjectOverviewSummary = ({
  projectId,
  dateRange,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const { data, loading } = useQuery(projectSummaryQuery, {
    variables: {
      projectId,
      dateRange: dateRange
        ? {
            startDate: dateToMidnightUTCString(dateRange.startDate),
            endDate: dateToMidnightUTCString(dateRange.endDate)
          }
        : isRolledUpTaskEstimateCalculationMethodEnabled && dateRange == null
        ? {
            startDate: null,
            endDate: null
          }
        : null
    },
    fetchPolicy: 'cache-and-network'
  });

  const project = get(data, 'project') || {};

  const hasProfitAmount =
    get(project, 'revenueAmountSummary.revenueActual.amount', null) !== null &&
    get(project, 'costAmountSummary.costActual.amount', null) !== null;

  return {
    costAmountSummary: project.costAmountSummary,
    revenueAmountSummary: project.revenueAmountSummary,
    profitAmountSummary: {
      profitActual: hasProfitAmount
        ? {
            amount:
              project.revenueAmountSummary.revenueActual.amount -
              project.costAmountSummary.costActual.amount,
            currency: project.costAmountSummary.costActual.currency
          }
        : null
    },
    timeEnteredSummary: project.timeEnteredSummary,
    loading
  };
};

export default useProjectOverviewSummary;
