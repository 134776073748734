import { useQuery, useApolloClient } from '@apollo/client';
import get from 'lodash.get';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { BillStatus } from '~/types';
import { status } from './useBillStatus';

export const BILL_SUB_STATUS_QUERY = gql`
  query billSubStatus($id: BillStatus!) {
    billSubStatus(billStatus: $id) {
      id
      text
      isEnabled
    }
  }
`;

export const mapBillStatusAndSubStatus = ({
  billStatus,
  billSubStatus,
  intl
}) => {
  const [billStatusDraft, otherBillStatus] = billStatus.reduce(
    ([draft, other], currentStatus) => {
      if (currentStatus.id === BillStatus.Draft) {
        draft.push(currentStatus);
      } else {
        other.push(currentStatus);
      }

      return [draft, other];
    },
    [[], []]
  );

  const labelDraft = intl.formatMessage({
    id: 'billingTransactionList.statusLabel.draft'
  });

  const draftSubStatus = billSubStatus
    ?.filter(({ isEnabled }) => isEnabled)
    .map(x => ({
      id: x.id,
      label: `${labelDraft}: ${x.text}`
    }));

  return [...billStatusDraft, ...draftSubStatus, ...otherBillStatus].map(s => ({
    ...s,
    value: s.id,
    label: s.label,
    key: s.label,
    name: s.label
  }));
};

export const useBillStatusAndSubStatus = (
  billStatus,
  isPsaPswatBillingDraftSubStatusEnabled
) => {
  const { data } = useQuery(BILL_SUB_STATUS_QUERY, {
    variables: {
      id: billStatus
    },
    skip: !isPsaPswatBillingDraftSubStatusEnabled
  });

  const billSubStatus = get(data, 'billSubStatus', []);
  const intl = useIntl();
  const result = mapBillStatusAndSubStatus({
    billStatus: status(intl),
    billSubStatus,
    intl
  });

  return {
    billStatusAndSubStatus: result
  };
};

export const searchBillStatusAndSubStatus = ({
  apolloClient,
  intl
}) => async searchTerm => {
  const { data } = await apolloClient.query({
    query: BILL_SUB_STATUS_QUERY,
    variables: {
      id: BillStatus.Draft
    }
  });
  const billSubStatus = get(data, 'billSubStatus', []);

  const result = mapBillStatusAndSubStatus({
    billStatus: status(intl),
    billSubStatus,
    intl
  });

  return result.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export const useSearchableBillStatusAndSubStatus = () => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  return {
    searchBillStatus: searchBillStatusAndSubStatus({
      apolloClient,
      intl
    })
  };
};
