import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DownloadIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M19 12v7H5v-7H3v9h18v-9h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z" />
    </>
  </SvgIcon>
));

export default DownloadIcon;
