import React from 'react';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const BillingIcon = React.forwardRef((props, ref) => (
  <RequestQuoteIcon {...props} ref={ref}>
    <>
      <path d="M 6.5,4 C 4.550000000000001,4 2.45,4.400000000000002 1,5.5 V 21 C 2.45,19.9 4.550000000000001,19.5 6.5,19.5 8.449999999999999,19.5 10.55,19.9 12,21 c 1.45,-1.1 3.55,-1.5 5.5,-1.5 1.17,0 2.39,0.15 3.5,0.5 0.75,0.25 1.4,0.55 2,1 V 5.5 C 22.4,5.05 21.75,4.75 21,4.5 19.89,4.150000000000002 18.67,4 17.5,4 15.55,4 13.45,4.400000000000002 12,5.5 10.55,4.400000000000002 8.449999999999997,4 6.5,4 Z m 11,2 c 1.2,0 2.4,0.150000000000002 3.5,0.5 V 18 c -1.1,-0.35 -2.3,-0.5 -3.5,-0.5 -1.7,0 -4.15,0.65 -5.5,1.5 V 7.5 C 13.35,6.650000000000002 15.8,6 17.5,6 Z m -1,2.5625 c -1.07419486822918,1.236848227507551 -2.08667447927492,2.477883354788609 -3,3.720703125 0.63317143448665,-0.21592112953127 1.28509607053172,-0.41929922355523 2,-0.560546875 v 3 C 16.14453359728087,14.59550807161969 16.79412564509289,14.5127067048519 17.5,14.5 v -3 c 0.6885880627788,0.001386319721 1.34287609407548,0.0548520234521 2,0.1484375 C 18.50532465187943,10.52952521607848 17.50289774321561,9.513999191042929 16.5,8.5625 Z M 6.49609375,8.93359375 C 6.829496076369895,8.934842718877712 7.164202004592126,8.947281550327549 7.5,8.96875 v 3 c 0.857277779578499,0.0466958572042 1.449276657105246,0.18556739579774 2,0.3125 -1.029953735829997,0.866027758502 -2.04318839216005,1.74243071470397 -3,2.65234375 -0.9583040829442,-0.92436258372246 -1.985751459010486,-1.78001937432285 -3,-2.6484375 0.631208225517469,-0.1494822720919 1.285568649200746,-0.27440120938379 2,-0.3203125 v -3 c 0.330592699112544,-0.0224643251833 0.662691423630107,-0.03249896887771 0.99609375,-0.03125 z" />
    </>
  </RequestQuoteIcon>
));

export default BillingIcon;
