import { useEffect } from 'react';

export const onBlock = ({
  setBlockedAction,
  openDialog,
  currentLocation,
  forceDialog = false
}) => (location, action) => {
  if (location.pathname === currentLocation.pathname && !forceDialog) {
    return true;
  }

  setBlockedAction({
    location,
    action
  });

  openDialog();

  return false;
};

export const useBlockNavigation = ({
  when,
  setBlockedAction,
  history,
  currentLocation,
  unblockRef,
  openDialog,
  forceDialog
}) => {
  useEffect(() => {
    if (when) {
      // eslint-disable-next-line no-param-reassign
      unblockRef.current = history.block(
        onBlock({
          setBlockedAction,
          currentLocation,
          openDialog,
          forceDialog
        })
      );
    } else {
      unblockRef.current && unblockRef.current();
    }
  }, [
    currentLocation,
    history,
    openDialog,
    setBlockedAction,
    unblockRef,
    when,
    forceDialog
  ]);
};
