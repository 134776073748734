import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLine
} from 'victory';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useVictoryChartContainerStyles } from '~/modules/common/charts/dashboard/config';
import chartStyles from './chartStyles';
import useBudgetChartHooks from './useBudgetChartHooks';
import Gradient from './Gradient';

const needOffset = (scale, value, maxBudgetLimit) => {
  if (value === 0 || value >= maxBudgetLimit) {
    return false;
  }

  const scaleOffset = scale.y(maxBudgetLimit) - scale.y(value);

  return Math.abs(scaleOffset) < 16;
};

const LabelComponent = ({
  datum,
  maxBudgetLimit,
  actualLabelColor,
  chartStyle,
  scale,
  variant,
  ...rest
}) => {
  const _needOffset = needOffset(scale, datum.value, maxBudgetLimit) || false;

  const yAdjustmentProps = _needOffset ? { y: scale.y(maxBudgetLimit) } : {};

  const victoryLabelProps =
    variant === 'actual'
      ? {
          dx: '-9%',
          textAnchor: 'end',
          style: { ...chartStyle.victoryLabel.actual, fill: actualLabelColor }
        }
      : {
          dx: '2%',
          textAnchor: 'start',
          style: chartStyle.victoryLabel.estimate
        };

  return (
    <VictoryLabel
      datum={datum}
      {...rest}
      verticalAnchor="middle"
      {...victoryLabelProps}
      {...yAdjustmentProps}
    />
  );
};

LabelComponent.propTypes = {
  datum: PropTypes.object,
  maxBudgetLimit: PropTypes.number,
  actualLabelColor: PropTypes.string,
  chartStyle: PropTypes.object.isRequired,
  scale: PropTypes.object,
  variant: PropTypes.string.isRequired
};

const useContainerStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: `1 1 ${theme.spacing(45)}px`,
    maxWidth: theme.spacing(55)
  }
}));

const dependentAxisYDomain = { y: [0, 100] };

export const BudgetChart = ({
  classes: classesOverride,
  estimatedDataPoints,
  actualDataPoints,
  actualBarColor,
  actualLabelColor,
  titleId = '',
  gradientId,
  maxBudgetLimit
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const chartStyle = chartStyles(theme);
  const classes = useContainerStyles({ classes: classesOverride });
  const victoryContainerClasses = useVictoryChartContainerStyles();
  const ESTIMATED_BAR_GRADIENT_TOP_COLOR = theme.palette.grey[600];
  const ESTIMATED_BAR_GRADIENT_BOTTOM_COLOR = theme.palette.grey[800];
  const {
    maxLimit,
    actualBarStyles,
    estimatedBarStyles,
    yTickFormat
  } = useBudgetChartHooks({
    gradientId,
    intl,
    maxBudgetLimit,
    chartStyle,
    actualBarColor
  });

  const estimatedLabelComponent = useMemo(
    () => (
      <LabelComponent
        maxBudgetLimit={maxBudgetLimit}
        chartStyle={chartStyle}
        variant="estimated"
      />
    ),
    [maxBudgetLimit, chartStyle]
  );

  const actualLabelComponent = useMemo(
    () => (
      <LabelComponent
        maxBudgetLimit={maxBudgetLimit}
        actualLabelColor={actualLabelColor}
        chartStyle={chartStyle}
        variant="actual"
      />
    ),
    [maxBudgetLimit, chartStyle, actualLabelColor]
  );

  return (
    <div
      className={classNames(
        classes.container,
        victoryContainerClasses.container
      )}
    >
      <Gradient
        actualBarTopColor={actualBarColor}
        actualBarBottomColor={actualBarColor}
        actualBarGradientId={gradientId}
        estimatedBarTopColor={ESTIMATED_BAR_GRADIENT_TOP_COLOR}
        estimatedBarBottomColor={ESTIMATED_BAR_GRADIENT_BOTTOM_COLOR}
        estimatedBarGradientId="gradientForEstimated"
      />
      <VictoryChart
        width={chartStyle.victoryChart.width}
        height={chartStyle.victoryChart.height}
        padding={chartStyle.victoryChart.padding}
        domainPadding={chartStyle.victoryChart.domainPadding}
        containerComponent={<VictoryContainer aria-labelledby={titleId} />}
      >
        <VictoryAxis
          dependentAxis
          style={chartStyle.victoryAxis.y}
          name="BudgetChart-yAxis"
          tickFormat={yTickFormat}
          domain={dependentAxisYDomain}
        />
        <VictoryAxis
          style={chartStyle.victoryAxis.x}
          fixLabelOverlap={false}
          name="BudgetChart-xAxis"
        />
        <VictoryBar
          data={estimatedDataPoints}
          style={estimatedBarStyles}
          y="value"
          actualHours
          x="name"
          alignment="middle"
          barRatio={0.9}
          labelComponent={estimatedLabelComponent}
        />
        <VictoryBar
          data={actualDataPoints}
          style={actualBarStyles}
          y="value"
          x="name"
          alignment="end"
          barRatio={0.9}
          labelComponent={actualLabelComponent}
        />
        {maxBudgetLimit > 0 && (
          <VictoryLine
            standalone={false}
            style={chartStyle.maxLimit}
            data={maxLimit}
          />
        )}
      </VictoryChart>
    </div>
  );
};

BudgetChart.propTypes = {
  classes: PropTypes.object,
  estimatedDataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  actualDataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  actualBarColor: PropTypes.string.isRequired,
  actualLabelColor: PropTypes.string.isRequired,
  gradientId: PropTypes.string.isRequired,
  maxBudgetLimit: PropTypes.number,
  titleId: PropTypes.string
};

export default BudgetChart;
