import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  RangeBoundaries,
  StyledChartRangeItem2
} from '~/modules/common/charts/timeline/components';
import { ResourceUserPeriodSummaryBlocks } from '~/modules/resourcing/common/components';
import { getRangeBoundaries } from '~/modules/resourcing/common/util/resourceAllocationUtil';
import { ResourceItem } from './components';
import useStyles from './useStyles';

export const ResourceAllocationUserRow = ({
  chartDates,
  chartDisplayDateRange,
  classes: classesOverride,
  leftComponentWidth,
  resource,
  scale
}) => {
  const [{ start: chartStartDate }] = chartDates;

  const classes = useStyles({ classes: classesOverride });

  const { rangeMinDate, rangeMaxDate } = getRangeBoundaries({
    resourceUser: resource,
    dateRange: chartDisplayDateRange
  });

  const user = useMemo(
    () => ({
      id: resource.id,
      uri: resource.uri,
      slug: resource.slug,
      displayText: resource.displayText,
      roles: resource.roles,
      roleSchedules: resource.roleSchedules
    }),
    [
      resource.id,
      resource.uri,
      resource.slug,
      resource.displayText,
      resource.roles,
      resource.roleSchedules
    ]
  );

  const leftComponent = useMemo(() => <ResourceItem resource={user} />, [user]);

  const timeLineComponent = useMemo(
    () => (
      <>
        <RangeBoundaries
          scale={scale}
          chartStartDate={chartStartDate}
          start={rangeMinDate.toISO()}
          end={rangeMaxDate.toISO()}
        />
        <ResourceUserPeriodSummaryBlocks
          chartStartDate={chartStartDate}
          scale={scale}
          resourceAvailabilitySummarySeries={
            resource.resourceAvailabilitySummarySeries
          }
          resourceAllocations={resource.resourceAllocations}
          timeoffs={resource.timeoffs}
          holidays={resource.holidays}
          chartDisplayDateRange={chartDisplayDateRange}
          resourceId={resource.id}
          isAllocationsLazyLoaded
        />
      </>
    ),
    [
      chartStartDate,
      scale,
      rangeMinDate,
      rangeMaxDate,
      resource,
      chartDisplayDateRange
    ]
  );

  return (
    <>
      <StyledChartRangeItem2
        classes={classes}
        scale={scale}
        isFixedRange
        leftComponentWidth={leftComponentWidth}
        scaleItemCount={chartDates.length}
        chartStartDate={chartStartDate}
      >
        {leftComponent}
        {timeLineComponent}
      </StyledChartRangeItem2>
    </>
  );
};

ResourceAllocationUserRow.propTypes = {
  classes: PropTypes.object,
  resource: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  chartDates: PropTypes.array.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired
};

export default ResourceAllocationUserRow;
