import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { shortDateRangeFormat } from '~/modules/common/dates/format';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getLatestDate, getEarliestDate } from '~/modules/common/dates/compare';
import {
  DiffValue,
  Decimal,
  DIFF_VALUE_TYPE,
  DateRange,
  Date
} from '~/modules/common/components';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  start: {},
  startDiff: {},
  divider: {},
  end: {},
  endDiff: {},
  datesDiffView: {
    display: 'flex'
  }
}));

export const RequestDrawerAllocatedDatesToolTipTitle = ({
  requestDateRange,
  allocatedDateRange,
  intl
}) => (
  <span>
    <div>
      {intl.formatMessage({
        id: 'resourceRequestDrawerDetails.requested'
      })}
      <DateRange
        start={requestDateRange.startDate}
        end={requestDateRange.endDate}
        tooltip={false}
      />
    </div>
    <div>
      {intl.formatMessage({
        id: 'resourceRequestDrawerDetails.allocated'
      })}
      <DateRange
        start={allocatedDateRange.startDate}
        end={allocatedDateRange.endDate}
      />
    </div>
  </span>
);

const getToolTipTitle = ({ requestDateRange, allocatedDateRange, intl }) => (
  <RequestDrawerAllocatedDatesToolTipTitle
    requestDateRange={requestDateRange}
    allocatedDateRange={allocatedDateRange}
    intl={intl}
  />
);

RequestDrawerAllocatedDatesToolTipTitle.propTypes = {
  allocatedDateRange: PropTypes.object,
  requestDateRange: PropTypes.object,
  intl: PropTypes.object
};

const RequestAllocatedDatesDiffView = ({
  classes: classesOverrides,
  resourceRequest,
  resourceAllocations
}) => {
  const requestDateRange = useMemo(
    () => ({
      startDate: mapIsoStringtoUtcObject(resourceRequest.startDate),
      endDate: mapIsoStringtoUtcObject(resourceRequest.endDate)
    }),
    [resourceRequest.startDate, resourceRequest.endDate]
  );

  const allocatedDateRange = useMemo(
    () =>
      resourceAllocations.length > 0
        ? {
            startDate: getEarliestDate(
              resourceAllocations.map(alloc =>
                mapIsoStringtoUtcObject(alloc.startDate)
              )
            ),
            endDate: getLatestDate(
              resourceAllocations.map(alloc =>
                mapIsoStringtoUtcObject(alloc.endDate)
              )
            )
          }
        : null,
    [resourceAllocations]
  );

  const intl = useIntl();
  const classes = useStyles({ classes: classesOverrides });

  const startDiff = allocatedDateRange
    ? allocatedDateRange.startDate.diff(requestDateRange.startDate, 'days')
        .values.days
    : 0;

  const endDiff = allocatedDateRange
    ? allocatedDateRange.endDate.diff(requestDateRange.endDate, 'days').values
        .days
    : 0;

  return (
    <Tooltip
      title={getToolTipTitle({ requestDateRange, allocatedDateRange, intl })}
    >
      <div className={classes.datesDiffView}>
        {startDiff !== 0 ? (
          <>
            <Date
              value={allocatedDateRange.startDate}
              className={classes.start}
            />
            <DiffValue
              className={classes.startDiff}
              diffValueType={DIFF_VALUE_TYPE.WARNING}
            >
              <Decimal
                value={startDiff}
                precision={0}
                prefix={startDiff > 0 ? '+' : null}
                suffix={intl.formatMessage({
                  id: 'resourceRequestDrawerDetails.days'
                })}
              />
            </DiffValue>
            <span className={classes.divider}> — </span>

            <Date value={allocatedDateRange.endDate} className={classes.end} />
          </>
        ) : (
          allocatedDateRange && (
            <Typography
              component="div"
              variant="body2"
              className={classes.datesDiffView}
            >
              {shortDateRangeFormat({
                start: allocatedDateRange.startDate,
                end: allocatedDateRange.endDate
              })}
            </Typography>
          )
        )}
        {endDiff !== 0 && (
          <DiffValue
            className={classes.endDiff}
            diffValueType={DIFF_VALUE_TYPE.WARNING}
          >
            <Decimal
              value={endDiff}
              precision={0}
              prefix={endDiff > 0 ? '+' : null}
              suffix={intl.formatMessage({
                id: 'resourceRequestDrawerDetails.days'
              })}
            />
          </DiffValue>
        )}
      </div>
    </Tooltip>
  );
};

RequestAllocatedDatesDiffView.propTypes = {
  classes: PropTypes.object,
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocations: PropTypes.array
};

export default RequestAllocatedDatesDiffView;
