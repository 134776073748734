import React, { useState, useCallback, useMemo } from 'react';
import { Collapse } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import ToggleDetailsButton from './ToggleDetailsButton';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    width: '100%',
    backgroundColor: 'transparent'
  },
  button: { width: '100%', flexGrow: 1, flexShrink: 1 },
  expanded: {
    backgroundColor: theme.palette.common.white
  }
}));

const toggleButtonResourceKeys = {
  show: <FormattedMessage id="expansionPanelActionButton.showDetails" />,
  hide: <FormattedMessage id="expansionPanelActionButton.hideDetails" />
};

export const CollapsibleDetailsPanel = ({
  children,
  defaultExpanded = false
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(defaultExpanded);

  const handleCollapsedChange = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const toggleButtonClasses = useMemo(
    () => ({
      root: classes.root,
      button: classes.button,
      expanded: classes.expanded
    }),
    [classes.button, classes.expanded, classes.root]
  );

  return (
    <>
      <ToggleDetailsButton
        open={open}
        onChange={handleCollapsedChange}
        resourceKeys={toggleButtonResourceKeys}
        classes={toggleButtonClasses}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

CollapsibleDetailsPanel.propTypes = {
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool
};

export default CollapsibleDetailsPanel;
