import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import EditableCard, {
  ReadOnly,
  ReadOnlyContent,
  Edit,
  EditContent,
  EditTitle
} from '~/modules/common/components/EditableCard';

import { RefreshButton } from '~/modules/projects/project/common/components';
import { withFormState } from '~/modules/projects/project/ScriptParamsEditableCard/enhancers';
import {
  useCreateProjectRevenuePolicyScriptRecalculationBatch,
  useCreateProjectRevenueForecastRecalculationBatch,
  useRecognizedRevenueSummary
} from '~/modules/projects/project/RevenueRecognition/hooks';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  RevenueRecalculateBatch,
  RevenueRecognitionReadOnly,
  RevenueRecognitionEditable,
  RevenueRecognitionAction,
  RevenueRecognitionEditableTitle
} from './components';

const tcvKeyUri = 'urn:replicon:script-key:parameter:total-contract-value';
const startDateKeyUri = 'urn:replicon:script-key:parameter:contract-start-date';
const endDateKeyUri = 'urn:replicon:script-key:parameter:contract-end-date';

export const RevenueRecognitionCard = ({
  values,
  setFieldValue,
  handleSubmit,
  resetForm,
  errors,
  editable,
  validateForm,
  projectUri,
  revenuePolicies,
  avatar,
  customEditors,
  title,
  refetchRevenuePolicy,
  forecastBatchState,
  setForecastBatchState,
  asOfCloseDate,
  isSubmitting
}) => {
  const {
    id: scriptId,
    description,
    displayText,
    parameters,
    scripts
  } = values;
  const isMobile = useIsBreakpointDown('sm');
  const onCancel = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const {
    revenuePolicyScriptRecalculationBatch
  } = useCreateProjectRevenuePolicyScriptRecalculationBatch({});

  const {
    revenueForecastRecalculationBatch
  } = useCreateProjectRevenueForecastRecalculationBatch({});

  const onBatchComplete = useCallback(() => refetchRevenuePolicy(), [
    refetchRevenuePolicy
  ]);

  const { tcv, startDate, endDate } = useMemo(
    () =>
      scripts.length
        ? {
            tcv: scripts[0][tcvKeyUri],
            startDate: scripts[0][startDateKeyUri],
            endDate: scripts[0][endDateKeyUri]
          }
        : {},
    [scripts]
  );

  const recalculate = useCallback(async () => {
    const data = await revenuePolicyScriptRecalculationBatch({
      projectId: projectUri
    });

    if (!(data && data.batchUri)) return;
    const {
      data: {
        createProjectRevenueForecastRecalculationBatch: {
          batchUri: revenueForecastBatchUri
        }
      }
    } = await revenueForecastRecalculationBatch({
      projectId: projectUri
    });

    setForecastBatchState({
      batchId: revenueForecastBatchUri,
      batchInProgress: true
    });
  }, [
    revenuePolicyScriptRecalculationBatch,
    projectUri,
    revenueForecastRecalculationBatch,
    setForecastBatchState
  ]);

  const { formatMessage } = useIntl();
  const revenueRecalculate = useMemo(() => forecastBatchState.batchInProgress, [
    forecastBatchState.batchInProgress
  ]);

  const { recognizedRevenueSummary } = useRecognizedRevenueSummary({
    projectUri,
    dateRange: {
      startDate,
      endDate: asOfCloseDate
    }
  });

  const refreshButton = useMemo(
    () =>
      editable ? (
        <RefreshButton
          onClick={recalculate}
          disabled={revenueRecalculate}
          titleLabel={formatMessage({
            id: 'projectRevenueCard.recalculateRevenue'
          })}
        />
      ) : null,
    [editable, formatMessage, recalculate, revenueRecalculate]
  );

  return (
    <EditableCard
      editable={editable}
      fullScreen={isMobile}
      dataQeId="Project_RevenueRecognition"
      ariaLabelKey="projectRevenueRecognitionCard.revenueRecognitionCardDialog"
    >
      <ReadOnly avatar={avatar} title={title} headerButtons={refreshButton}>
        <ReadOnlyContent>
          <>
            {revenueRecalculate ? (
              <RevenueRecalculateBatch
                batchState={forecastBatchState}
                setBatchState={setForecastBatchState}
                onBatchComplete={onBatchComplete}
              />
            ) : (
              <RevenueRecognitionReadOnly
                scriptId={scriptId}
                policyLabel={displayText}
                parameters={parameters}
                scripts={scripts}
                projectUri={projectUri}
                asOfCloseDate={asOfCloseDate}
                tcv={tcv}
                startDate={startDate}
                endDate={endDate}
                isMobile={isMobile}
                recognizedRevenueSummary={recognizedRevenueSummary}
              />
            )}
          </>
        </ReadOnlyContent>
      </ReadOnly>

      <Edit
        saveable={!isSubmitting}
        onCancel={onCancel}
        onSave={handleSubmit}
        validateForm={validateForm}
        ActionComponent={RevenueRecognitionAction}
      >
        <EditTitle>
          <RevenueRecognitionEditableTitle />
        </EditTitle>
        <EditContent>
          <RevenueRecognitionEditable
            scriptId={scriptId}
            description={description}
            parameters={parameters}
            scripts={scripts}
            setFieldValue={setFieldValue}
            revenuePolicies={revenuePolicies}
            errors={errors}
            customEditors={customEditors}
            isMobile={isMobile}
            asOfCloseDate={asOfCloseDate}
            recognizedRevenueSummary={recognizedRevenueSummary}
          />
        </EditContent>
      </Edit>
    </EditableCard>
  );
};

RevenueRecognitionCard.propTypes = {
  editable: PropTypes.bool,
  projectUri: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  errors: PropTypes.object,
  validateForm: PropTypes.func,
  revenuePolicies: PropTypes.array,
  avatar: PropTypes.node,
  title: PropTypes.node,
  customEditors: PropTypes.object,
  refetchRevenuePolicy: PropTypes.func,
  forecastBatchState: PropTypes.object,
  setForecastBatchState: PropTypes.func,
  asOfCloseDate: PropTypes.object,
  isSubmitting: PropTypes.bool
};

export default withFormState(RevenueRecognitionCard);
