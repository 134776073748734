import React from 'react';
import PropTypes from 'prop-types';
import Decimal from '../Decimal';

export const MoneyValue = ({
  money,
  minValue,
  className,
  dataQeId,
  precision,
  tabIndex
}) => {
  const amount =
    (minValue || minValue === 0) && money.amount < minValue
      ? minValue
      : money.amount;

  const prefix = money.currency.symbol || money.currency.displayText;

  return (
    <Decimal
      tabIndex={tabIndex}
      value={amount}
      className={className}
      prefix={`${prefix} `}
      dataQeId={dataQeId}
      precision={precision}
    />
  );
};

MoneyValue.propTypes = {
  className: PropTypes.string,
  minValue: PropTypes.number,
  money: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.object.isRequired
  }).isRequired,
  precision: PropTypes.number,
  dataQeId: PropTypes.string,
  tabIndex: PropTypes.number
};

export default MoneyValue;
