/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles, InputAdornment, Popper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { SearchableUserDropDown } from '~/modules/common/components/UserDropdown';
import { AutocompleteInput } from '~/modules/common/components/SearchAutocomplete';
import { usePageOfProjectResources } from '~/modules/common/hooks';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import useResourceRateDropdownCallbacks from './useResourceRateDropdownCallbacks';

const useAutoCompleteStyles = makeStyles(() => ({
  input: {
    '& input': {
      fontSize: 14
    }
  },
  inputRoot: {
    height: 40,
    paddingTop: '0px !important',
    backgroundColor: `rgba(0, 0, 0, 0.09)`
  }
}));

const useStyles = makeStyles(theme => ({
  popper: {
    border: `1px solid ${'#e1e4e8'}`,
    boxShadow: theme.shadows[8],
    borderRadius: 6,
    width: 250,
    zIndex: theme.zIndex.modal,
    fontSize: theme.typography.caption.fontSize,
    backgroundColor: theme.palette.background.paper,
    right: theme.direction === 'rtl' ? 'unset !important' : 'unset',
    [`& .${autocompleteClasses.paper}`]: {
      boxShadow: 'none',
      margin: 0
    }
  }
}));

export const ResourceRateDropdownPopper = ({ classes, ...additionalProps }) => {
  return (
    <Popper {...additionalProps} className={classes.popper}>
      {additionalProps.children}
    </Popper>
  );
};

ResourceRateDropdownPopper.propTypes = {
  classes: PropTypes.object
};

export const ResourceRateDropDown = ({
  value,
  projectSlug,
  onUserChange,
  hasError,
  helperText
}) => {
  const ref = useRef();
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const autoCompleteClasses = useAutoCompleteStyles();
  const classes = useStyles();

  const { loading, users, hasMore } = usePageOfProjectResources({
    projectSlug,
    searchTerm: value ? '' : searchTerm
  });

  users.unshift({
    heading: true
  });
  const shouldAddMoreAvailableOption =
    !loading && hasMore && users && users.length;

  const optionTypeText = formatMessage({
    id: 'moreOptions.resources'
  });

  shouldAddMoreAvailableOption &&
    users.push({
      id: 'searchAutocomplete.moreAvailable',
      displayText: formatMessage(
        {
          id: MORE_AVAILABLE_OPTION_ID
        },
        {
          option: optionTypeText
        }
      )
    });

  const placeholder = formatMessage({
    id: 'rateCard.resourceAssignments.select'
  });

  const inputField = useCallback(
    params => (
      <AutocompleteInput
        {...params}
        autoFocus
        fullWidth
        hiddenLabel
        variant="filled"
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    ),
    [placeholder]
  );

  const {
    handleOnChange,
    optionString,
    groupBy,
    optionComponent,
    handleInputChange
  } = useResourceRateDropdownCallbacks({
    onUserChange,
    setSearchTerm,
    projectSlug,
    formatMessage
  });

  return (
    <SearchableUserDropDown
      ref={ref}
      value={value}
      classes={autoCompleteClasses}
      loading={loading}
      size="small"
      fullWidth
      options={users}
      hasError={hasError}
      helperText={helperText}
      variant="standard"
      noOptionsText={formatMessage({
        id: 'rateCard.resourceAssignments.noOptions'
      })}
      groupBy={groupBy}
      hasMore={hasMore}
      renderInput={inputField}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      getOptionLabel={optionString}
      renderOption={optionComponent}
      PopperComponent={props => {
        return <ResourceRateDropdownPopper classes={classes} {...props} />;
      }}
    />
  );
};

ResourceRateDropDown.propTypes = {
  value: PropTypes.object,
  onUserChange: PropTypes.func,
  projectSlug: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default ResourceRateDropDown;
