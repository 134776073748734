import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { OverbudgetTitle } from '~/modules/overview';
import { FlatOverviewItemExpansionPanel } from '~/modules/common/components/OverviewItemExpansionPanel';
import { PanelTitle } from '../../common';
import OverbudgetExpansionPanelContents from './OverbudgetExpansionPanelContents';

export const Overbudget = ({ overdueData: { loading, overbudgetList } }) =>
  loading ? (
    <Skeleton width="100%" height={96} variant="rect" />
  ) : (
    <>
      {overbudgetList
        .filter(item => item.count > 0)
        .map(item => (
          <FlatOverviewItemExpansionPanel
            key={item.key}
            counts={item.count}
            TitleComponent={PanelTitle}
            title={
              <OverbudgetTitle
                messageId={item.messageId}
                highlightMsgId={item.highlightMsgId}
              />
            }
          >
            <OverbudgetExpansionPanelContents
              count={item.count}
              searchKey={item.searchKey}
              onClick={item.onClick}
            />
          </FlatOverviewItemExpansionPanel>
        ))}
    </>
  );

Overbudget.propTypes = {
  overdueData: PropTypes.shape({
    loading: PropTypes.bool,
    overbudgetList: PropTypes.array
  })
};

export default Overbudget;
