import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import get from 'lodash.get';
import { TextField } from '@material-ui/core';

export const TextEditor = ({
  column: { id: columnId, setFieldValue, errors, isMobile, label },
  field,
  index,
  record
}) => {
  const onChangeHandler = useCallback(
    e => setFieldValue(`scripts[${index}].${columnId}`, e.target.value),
    [columnId, index, setFieldValue]
  );

  const requiredError = get(errors, `parameters['${columnId}'][${index}]`);

  return (
    <TextField
      variant="filled"
      fullWidth
      value={record[field]}
      onChange={onChangeHandler}
      error={Boolean(requiredError)}
      helperText={requiredError}
      hiddenLabel={!isMobile}
      label={label}
    />
  );
};

TextEditor.propTypes = {
  column: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number
};

export default TextEditor;
