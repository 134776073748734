import { gql } from 'graphql-tag';
import { CustomFieldValueFragmentDoc } from '~/types';
import { extensionFieldValueFragment } from '~/modules/extensionFields/fragments';

export const projectAdditionalInfoFragment = gql`
  fragment ProjectAdditionalInfo on Project {
    id
    extensionFieldValues {
      ...ExtensionFieldValue
    }
    customFieldValues {
      ...CustomFieldValue
    }
  }
  ${extensionFieldValueFragment}
  ${CustomFieldValueFragmentDoc}
`;
