import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import formatters from './formatters';

export const buildFooters = (classes, isMultiCurrency, editable) => ({
  editable: {
    id: 'firstColumn',
    visible: editable,
    className: classes.deleteIcon
  },
  placeHolder: {
    id: 'bill-placeholder',
    visible: isMultiCurrency,
    className: classes.displayText
  },
  totalDisplayText: {
    id: 'totalDisplayText',
    align: 'right',
    value: <FormattedMessage id="associatedBillCredits.total" />,
    visible: true,
    colSpan: 2,
    className: classes.totalDisplayText
  },
  allocatedAmountTotal: {
    id: 'allocatedAmountTotal',
    visible: true,
    align: 'right',
    flip: false
  }
});

export const useListFooters = ({ classes, isMultiCurrency, editable }) =>
  useColumns({
    columns: buildFooters(classes, isMultiCurrency, editable),
    renders: formatters
  });
