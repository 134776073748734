import React from 'react';
import NumberFormat from 'react-number-format';
import { PropTypes } from 'prop-types';
import { usePrecision } from './usePrecision';

export const FormattedDecimal = ({
  value,
  precision = 2,
  fixedDecimalScale = true,
  className,
  forwardedRef,
  dataQeId = 'Decimal',
  numberFormat,
  enableFixedFormatDecimalScale,
  ...otherProps
}) => {
  const { precisedValue, preciseVal } = usePrecision({
    value
  });

  const overrideValue =
    enableFixedFormatDecimalScale && Number.isInteger(preciseVal)
      ? preciseVal
      : precisedValue;

  return (
    <NumberFormat
      {...otherProps}
      {...numberFormat}
      data-qe-id={dataQeId}
      displayType="text"
      decimalScale={precision}
      fixedDecimalScale={fixedDecimalScale}
      value={overrideValue}
      className={className}
      ref={forwardedRef}
    />
  );
};

FormattedDecimal.propTypes = {
  value: PropTypes.number,
  precision: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  dataQeId: PropTypes.string,
  enableFixedFormatDecimalScale: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    PropTypes.func
  ]),
  numberFormat: PropTypes.object
};

export default FormattedDecimal;
