import React, { useMemo } from 'react';
import { Card, Divider, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  legends: {
    padding: theme.spacing(0, 2, 2, 0),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    padding: theme.spacing(2, 2)
  },
  chart: { margin: theme.spacing(0, 2, 2, 2) }
}));

export const ResourcesCardLoading = () => {
  const classes = useStyles();

  return (
    <Card classes={useMemo(() => ({ root: classes.root }), [classes.root])}>
      <Skeleton width="100%" height={96} variant="rect" />
      <Divider />
      <Typography variant="h3" className={classes.title}>
        <Skeleton width="50%" />
      </Typography>

      <Typography variant="body1" className={classes.legends}>
        <Skeleton width="60%" />
      </Typography>
      <Skeleton
        height={150}
        variant="rect"
        classes={useMemo(() => ({ root: classes.chart }), [classes.chart])}
      />
    </Card>
  );
};

export default ResourcesCardLoading;
