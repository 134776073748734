import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { PortfolioStatus } from '~/types';
import { PAGE_SIZE } from '~/modules/common/const';
import { useMeContext } from '~/modules/me/useMeContext';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PORTFOLIOS_DROPDOWN_DATA_VARIANT } from '~/modules/common/components/PortfoliosDropDown/enum';
import { PORTFOLIO_PERMISSION } from '~/modules/common/enums/PortfolioPermission';

export const GET_PAGE_OF_PORTFOLIOS = gql`
  query getPageOfPortfolios(
    $page: Int!
    $pageSize: Int!
    $columns: [PortfolioColumn]
    $filter: PortfolioFilter
    $includeAncestors: Boolean!
  ) {
    portfolios(
      page: $page
      pageSize: $pageSize
      columns: $columns
      filter: $filter
    ) {
      id
      displayText: name
      ancestors @include(if: $includeAncestors) {
        id
      }
      hasViewAccess
      isDataRow
    }
  }
`;

export const PAGE_OF_PORTFOLIOS_FOR_FILTERING_PROJECTS_QUERY = gql`
  query pageOfPortfoliosAvailableForFilteringProjects(
    $input: PageOfProjectPortfolioInput
  ) {
    portfolios: pageOfPortfoliosAvailableForFilteringProjects(input: $input) {
      id
      displayText
      isSelectable: hasAssignment
      hierarchyLevel
    }
  }
`;

export const PAGE_OF_PORTFOLIOS_FOR_FILTERING_REQUESTS_QUERY = gql`
  query pageOfPortfoliosAvailableForFilteringProjectRequest(
    $input: PageOfProjectRequestPortfoliosInput
  ) {
    portfolios: pageOfPortfoliosAvailableForFilteringProjectRequest(
      input: $input
    ) {
      id
      displayText
      isSelectable: hasAssignment
      hierarchyLevel
    }
  }
`;

const useGetPageOfPortfolios = ({ searchTerm, skip }) => {
  const { data, loading } = useQuery(GET_PAGE_OF_PORTFOLIOS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
      columns: ['portfolio', 'ancestors', 'hasViewAccess'],
      filter: { effectiveStatus: PortfolioStatus.Active, text: searchTerm },
      includeAncestors: true
    },
    skip
  });

  return {
    portfolios: (data?.portfolios || []).map(p => ({
      id: p.id,
      isSelectable: Boolean(p.isDataRow && p.hasViewAccess),
      hierarchyLevel: p.ancestors?.length || 0,
      displayText: p.displayText,
      key: p.displayText,
      label: p.displayText,
      value: p.id
    })),
    loading
  };
};

const usePageOfPortfoliosForFiltering = ({ dataVariant, searchTerm, skip }) => {
  const query =
    dataVariant === PORTFOLIOS_DROPDOWN_DATA_VARIANT.REQUEST
      ? PAGE_OF_PORTFOLIOS_FOR_FILTERING_REQUESTS_QUERY
      : PAGE_OF_PORTFOLIOS_FOR_FILTERING_PROJECTS_QUERY;

  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        searchTerm,
        page: 1,
        pageSize: PAGE_SIZE
      }
    },
    skip
  });

  return {
    portfolios: (data?.portfolios || []).map(p => ({
      id: p.id,
      isSelectable: p.isSelectable,
      hierarchyLevel: p.hierarchyLevel,
      displayText: p.displayText,
      key: p.displayText,
      label: p.displayText,
      value: p.id
    })),
    loading
  };
};

export const useSearchablePortfolios = ({
  dataVariant,
  includeNoneOption,
  noneOptionText,
  searchTerm
}) => {
  const { permissionsMap } = useMeContext();

  const canViewPortfolio = Boolean(
    permissionsMap[PORTFOLIO_PERMISSION.VIEW_PORTFOLIO]
  );

  const getPageOfPortfoliosResult = useGetPageOfPortfolios({
    searchTerm,
    skip: !canViewPortfolio
  });

  const pageOfPortfoliosForFilteringResult = usePageOfPortfoliosForFiltering({
    dataVariant,
    searchTerm,
    skip: canViewPortfolio
  });

  const { portfolios, loading } = canViewPortfolio
    ? getPageOfPortfoliosResult
    : pageOfPortfoliosForFilteringResult;

  return {
    portfolios: (portfolios.length && includeNoneOption && !searchTerm
      ? prependNoneItem(noneOptionText, portfolios)
      : portfolios
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    loading
  };
};

export const fetchPortfolios = ({
  apolloClient,
  intl,
  dataVariant,
  canViewPortfolio
}) => async searchTerm => {
  const pageOfPortfoliosForFilteringQueryParams = {
    query:
      dataVariant === PORTFOLIOS_DROPDOWN_DATA_VARIANT.REQUEST
        ? PAGE_OF_PORTFOLIOS_FOR_FILTERING_REQUESTS_QUERY
        : PAGE_OF_PORTFOLIOS_FOR_FILTERING_PROJECTS_QUERY,
    variables: {
      input: {
        searchTerm,
        page: 1,
        pageSize: PAGE_SIZE
      }
    }
  };

  const getPageOfPortfoliosQueryParams = {
    query: GET_PAGE_OF_PORTFOLIOS,
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
      columns: ['portfolio', 'hasViewAccess'],
      filter: { effectiveStatus: PortfolioStatus.Active, text: searchTerm },
      includeAncestors: false
    }
  };

  const { data } = await apolloClient.query({
    context: {
      debounceKey: 'portfolio-dd',
      debounceTimeout: 250
    },
    ...(canViewPortfolio
      ? getPageOfPortfoliosQueryParams
      : pageOfPortfoliosForFilteringQueryParams)
  });

  const selectablePortfolios = (data?.portfolios || [])
    .map(p => ({
      id: p.id,
      displayText: p.displayText,
      isSelectable: p.isSelectable || Boolean(p.isDataRow && p.hasViewAccess)
    }))
    .filter(p => p.isSelectable);

  return prependNoneItem(
    intl.formatMessage({ id: 'portfoliosDropdown.noPortfolio' }),
    selectablePortfolios
  );
};

export const makeSearchableHook = dataVariant => () => {
  const { permissionsMap } = useMeContext();

  const apolloClient = useApolloClient();
  const intl = useIntl();

  const canViewPortfolio = Boolean(
    permissionsMap[PORTFOLIO_PERMISSION.VIEW_PORTFOLIO]
  );

  const fetchOptions = fetchPortfolios({
    apolloClient,
    intl,
    dataVariant,
    canViewPortfolio
  });

  return { fetchOptions };
};

export const useSearchableProjectPortfolios = makeSearchableHook(
  PORTFOLIOS_DROPDOWN_DATA_VARIANT.PROJECT
);

export const useSearchableRequestPortfolios = makeSearchableHook(
  PORTFOLIOS_DROPDOWN_DATA_VARIANT.REQUEST
);

export default useSearchablePortfolios;
