import React from 'react';
import PropTypes from 'prop-types';
import { ServiceCenterIcon } from '~/modules/common/components/Icons';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupName from './GroupName';

export const ServiceCenterName = ({
  value,
  serviceCenterSettings,
  variant,
  showFullPath,
  disableIcon
}) => (
  <GroupName
    value={value}
    Icon={ServiceCenterIcon}
    groupSettings={serviceCenterSettings}
    variant={variant}
    showFullPath={showFullPath}
    disableIcon={disableIcon}
  />
);

ServiceCenterName.propTypes = {
  value: PropTypes.object,
  serviceCenterSettings: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['icon', 'avatar']),
  showFullPath: PropTypes.bool,
  disableIcon: PropTypes.bool
};

export default withGroupSettings(
  'serviceCenter',
  'serviceCenterSettings'
)(ServiceCenterName);
