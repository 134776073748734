import deepEqual from 'fast-deep-equal';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { customFieldValueToInput } from '~/modules/customFields/utils';
import {
  UPDATE_PROJECT_ADDITIONAL_INFO_MUTATION,
  optimisticResponse
} from './graphql';
import { useUpdateExtensionField } from './useUpdateExtensionField';

export const byChangedExtensionField = initialExtensionFieldValues => extensionField =>
  !deepEqual(
    extensionField,
    initialExtensionFieldValues.find(
      v => v.definition.id === extensionField.definition.id
    )
  );

export const pickFileAttributes = ({ base64Content, keyValues, mimeType }) => ({
  base64Content,
  keyValues,
  mimeType
});

export const buildExtensionFieldValuesInputMap = (
  extensionFieldValues,
  initialExtensionFieldValues
) =>
  Object.values(extensionFieldValues)
    .filter(byChangedExtensionField(initialExtensionFieldValues))
    .map(extensionField => ({
      definition: {
        uri: extensionField.definition.id
      },
      numericValue: extensionField.numericValue,
      textValue: extensionField.textValue,
      fileValue: extensionField.fileValue
        ? {
            data:
              extensionField.fileValue.data &&
              pickFileAttributes(extensionField.fileValue.data),
            identityUri: extensionField.fileValue.identityUri
          }
        : null,
      tag: extensionField.tag
        ? {
            uri: extensionField.tag.id
          }
        : null
    }))
    .reduce(
      (extensionFieldsMap, extensionField) => {
        const hasFileValueData =
          extensionField.fileValue && extensionField.fileValue.data;

        const extensionFieldValuesInput = hasFileValueData
          ? extensionFieldsMap.extensionFieldValues
          : [...extensionFieldsMap.extensionFieldValues, extensionField];

        const fileExtensionFieldValues = hasFileValueData
          ? [...extensionFieldsMap.fileExtensionFieldValues, extensionField]
          : extensionFieldsMap.fileExtensionFieldValues;

        return {
          extensionFieldValues: extensionFieldValuesInput,
          fileExtensionFieldValues
        };
      },
      {
        extensionFieldValues: [],
        fileExtensionFieldValues: []
      }
    );

const mapValuesOnSubmit = ({ initialExtensionFieldValues, values }) => {
  const {
    extensionFieldValues,
    fileExtensionFieldValues
  } = buildExtensionFieldValuesInputMap(
    values.extensionFieldValues,
    initialExtensionFieldValues
  );

  const payload = {
    projectUri: values.projectUri,
    customFieldValues: Object.values(values.customFieldValues).map(
      customFieldValueToInput
    ),
    extensionFieldValues
  };

  return {
    fileExtensionFieldValues,
    payload
  };
};

export const onSubmit = ({
  closeDialog,
  updateProject,
  updateExtensionField,
  initialExtensionFieldValues
}) => async (values, { setSubmitting }) => {
  const { payload, fileExtensionFieldValues } = mapValuesOnSubmit({
    initialExtensionFieldValues,
    values
  });

  const updateFilePromises = fileExtensionFieldValues.map(value =>
    updateExtensionField({
      variables: {
        objectUri: values.projectUri,
        value
      }
    })
  );

  await Promise.all(updateFilePromises);

  updateProject({
    variables: {
      projectInput: payload
    },
    optimisticResponse: optimisticResponse({
      ...payload,
      initialExtensionFieldValues,
      fileExtensionFieldValues
    })
  });

  closeDialog();
  setSubmitting(false);
};

export const useUpdateProjectAdditionalInfo = ({
  closeDialog,
  extensionFieldValues
}) => {
  const [updateExtensionField] = useUpdateExtensionField();
  const [updateProject] = useMutation(UPDATE_PROJECT_ADDITIONAL_INFO_MUTATION);

  return useCallback(
    onSubmit({
      closeDialog,
      updateProject,
      updateExtensionField,
      initialExtensionFieldValues: extensionFieldValues
    }),
    [closeDialog, updateProject, updateExtensionField, extensionFieldValues]
  );
};
