/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';

export const FocusableSpan = ({ className, ariaLabel, children }) => (
  <span className={className} aria-label={ariaLabel} tabIndex={0}>
    {children}
  </span>
);

FocusableSpan.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node
};

export default FocusableSpan;
