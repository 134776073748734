import { DateTime } from 'luxon';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { isNumeric } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';
import { getPeriodsByScale } from '~/modules/resourcing/common/hooks/usePeriodsByScale';
import { getTotalHoursForPeriodFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { ResourceCostModeType } from '~/types';

export const getTotalScheduledHoursForPeriod = ({
  start,
  end,
  scheduleHoursByDate
}) =>
  scheduleHoursByDate.reduce(
    (acc, curr) =>
      curr.date >= start && curr.date <= end ? acc + curr.hours : acc,
    0
  );

export const getScheduleRulesInChartDisplayRange = ({
  rangeStart,
  rangeEnd,
  scheduleRules
}) =>
  scheduleRules
    .map(rule => ({
      ...rule,
      startDate: mapIsoStringtoUtcObject(rule.dateRange.startDate),
      endDate: mapIsoStringtoUtcObject(rule.dateRange.endDate)
    }))
    .filter(rule => rule.startDate <= rangeEnd && rangeStart <= rule.endDate);

export const getOverlappingDateRange = ({
  allocationStartDate,
  allocationEndDate,
  chartStartDate,
  chartEndDate
}) => {
  if (allocationEndDate < chartStartDate || allocationStartDate > chartEndDate)
    return undefined;

  const rangeStart = DateTime.max(allocationStartDate, chartStartDate);
  const rangeEnd = DateTime.min(allocationEndDate, chartEndDate);

  return { rangeStart, rangeEnd };
};

export const getAllocationTotalsByPeriods2 = ({
  chartDisplayDateRange: { startDate: chartStartDate, endDate: chartEndDate },
  resourceAllocation,
  scale,
  me
}) => {
  const { scheduleRules, startDate, endDate } = resourceAllocation || {};

  if (!startDate || !endDate) return { allocationPeriods: [] };

  const overlappingDateRange = getOverlappingDateRange({
    allocationStartDate: mapIsoStringtoUtcObject(startDate),
    allocationEndDate: mapIsoStringtoUtcObject(endDate),
    chartEndDate,
    chartStartDate
  });

  if (!overlappingDateRange) return { allocationPeriods: [] };

  const { rangeStart, rangeEnd } = overlappingDateRange;

  const scheduleRulesInRange = getScheduleRulesInChartDisplayRange({
    rangeStart,
    rangeEnd,
    scheduleRules
  });

  const { displayPeriods } = getPeriodsByScale({
    me,
    rangeStart,
    rangeEnd,
    scale
  });

  return {
    allocationPeriods: displayPeriods.map(range => {
      const { start, end, key } = range;

      return {
        key,
        totalHours: getTotalHoursForPeriodFromScheduleRules({
          start,
          end,
          scheduleRules: scheduleRulesInRange
        })
      };
    })
  };
};

const useAllocationTimelineBlocks2 = ({
  chartDisplayDateRange,
  allocation,
  scale,
  showTotalCost = true,
  skip
}) => {
  const me = useMeContext();

  if (skip) return { allocationPeriods: [] };

  const { resourceCostMode } = me;
  const isRoleCostMode = resourceCostMode === ResourceCostModeType.Rolecost;
  const {
    startDate: chartStartDate,
    endDate: chartEndDate
  } = chartDisplayDateRange;

  const { scheduleRules, user, startDate, endDate } = allocation || {};

  if (!startDate || !endDate) return { allocationPeriods: [] };

  const allocationStartDate = mapIsoStringtoUtcObject(startDate);
  const allocationEndDate = mapIsoStringtoUtcObject(endDate);

  const overlappingDateRange = getOverlappingDateRange({
    allocationStartDate,
    allocationEndDate,
    chartEndDate,
    chartStartDate
  });

  if (!overlappingDateRange) return { allocationPeriods: [] };

  const { rangeStart, rangeEnd } = overlappingDateRange;

  const scheduleRulesInRange = getScheduleRulesInChartDisplayRange({
    rangeStart,
    rangeEnd,
    scheduleRules
  });

  const { displayPeriods } = getPeriodsByScale({
    me,
    rangeStart,
    rangeEnd,
    scale
  });

  const scheduleHoursByDate = (user?.scheduleDurationByDay || []).map(d => ({
    date: mapRepliconDateToUtcObject(d.date),
    hours: d.hours
  }));

  return {
    allocationPeriods: displayPeriods.map((range, index) => {
      const { start, end, key } = range;

      const allocatedHoursForRange = getTotalHoursForPeriodFromScheduleRules({
        start,
        end,
        scheduleRules: scheduleRulesInRange
      });

      const overlapStart = DateTime.max(allocationStartDate, start);
      const overlapEnd = DateTime.min(allocationEndDate, end);

      const scheduledHoursForRange = getTotalScheduledHoursForPeriod({
        start: overlapStart,
        end: overlapEnd,
        scheduleHoursByDate
      });

      return {
        key,
        startDate: start,
        endDate: end,
        dynamicPosition: getDynamicPositionAttributes({
          chartStartDate,
          start: overlapStart,
          end: overlapEnd,
          scale,
          isPsaRmpUserSchedulePerfFixEnabled: true
        }).dynamicPosition,
        hasAllocationStart:
          index === 0 && overlapStart.equals(allocationStartDate),
        hasAllocationEnd:
          index === displayPeriods.length - 1 &&
          overlapEnd.equals(allocationEndDate),
        totalHours: allocatedHoursForRange,
        percentage: scheduledHoursForRange
          ? (allocatedHoursForRange / scheduledHoursForRange) * 100
          : undefined,
        totalScheduleHours: scheduledHoursForRange,
        totalCost:
          showTotalCost && user && isNumeric(allocatedHoursForRange)
            ? {
                amount:
                  allocatedHoursForRange *
                    (isRoleCostMode
                      ? user.primaryRoleCostRate?.amount ||
                        user.primaryRoleCostRate
                      : user.costRate) || 0,
                symbol: isRoleCostMode
                  ? user.primaryRoleCostCurrency?.displayText ||
                    user.primaryRoleCostRate?.currency?.displayText
                  : user.costCurrency && user.costCurrency.displayText
              }
            : undefined
      };
    })
  };
};

export default useAllocationTimelineBlocks2;
