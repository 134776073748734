import { gql } from 'graphql-tag';

import projectTotalsFragment2, {
  ALLOCATION_STATUS_LIST,
  RESOURCE_REQUEST_STATUS_LIST
} from '~/modules/resourcing/common/fragments/projectTotalsFragment2';
import { ResourceCostModeType } from '~/types';
import { normalizeFilter } from '../common/util/resourceRequestUtil';

export const GET_RESOURCE_ALLOCATION_USER_COST_TOTALS = gql`
  fragment ResourceAllocationUserCostTotalsFragment on ResourceAllocation {
    id
    totalHours
    totalUserCostByCurrency {
      baseCurrency {
        currency {
          id
        }
        amount
      }
      projectBudgetCurrency {
        currency {
          id
        }
        amount
      }
    }
  }
`;

export const GET_RESOURCE_ALLOCATION_ROLE_COST_TOTALS = gql`
  fragment ResourceAllocationRoleCostTotalsFragment on ResourceAllocation {
    id
    totalHours
    totalRoleCostByCurrency {
      baseCurrency {
        currency {
          id
        }
        amount
      }
      projectBudgetCurrency {
        currency {
          id
        }
        amount
      }
    }
  }
`;

export const readAllocatedTotalCostAndTotalHoursFromCache = ({
  proxy,
  allocation,
  resourceCostMode
}) => {
  return proxy.readFragment({
    id: proxy.identify({
      __typename: allocation.__typename,
      id: allocation.id
    }),
    fragment:
      resourceCostMode === ResourceCostModeType.Rolecost
        ? GET_RESOURCE_ALLOCATION_ROLE_COST_TOTALS
        : GET_RESOURCE_ALLOCATION_USER_COST_TOTALS
  });
};

export const writeAllocatedTotalCostAndTotalHoursToCache = ({
  proxy,
  allocation,
  updatedData,
  resourceCostMode
}) => {
  proxy.writeFragment({
    id: proxy.identify({
      __typename: allocation.__typename,
      id: allocation.id
    }),
    fragment:
      resourceCostMode === ResourceCostModeType.Rolecost
        ? GET_RESOURCE_ALLOCATION_ROLE_COST_TOTALS
        : GET_RESOURCE_ALLOCATION_USER_COST_TOTALS,
    data: updatedData
  });
};

export const invokeUpdateResourceAllocationAggregateCache = ({
  proxy,
  projectId,
  costAndHoursDelta,
  filter
}) => {
  const projectData = {
    __typename: 'Project',
    id: projectId
  };

  const cacheID = proxy.identify(projectData);
  const normalizedFilter = normalizeFilter(filter || {});

  const totals = proxy.readFragment({
    id: cacheID,
    fragment: projectTotalsFragment2,
    variables: {
      allocationStatusList: ALLOCATION_STATUS_LIST,
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      filter: normalizedFilter
    }
  });

  if (!totals) return;

  const { allocationsTotals } = totals;

  const newAllocationTotals = {
    ...allocationsTotals,
    totalCost: {
      ...allocationsTotals.totalCost,
      amount:
        allocationsTotals.totalCost.amount +
        costAndHoursDelta.totalCost.costDelta
    },
    totalHours:
      allocationsTotals.totalHours + costAndHoursDelta.totalHours.hoursDelta
  };

  proxy.writeFragment({
    id: cacheID,
    fragment: projectTotalsFragment2,
    variables: {
      allocationStatusList: ALLOCATION_STATUS_LIST,
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      filter: normalizedFilter
    },
    data: {
      ...totals,
      allocationsTotals: newAllocationTotals
    }
  });
};

export const invokeUpdateResourceAllocationForRequestsAggregateCache = ({
  proxy,
  projectId,
  costAndHoursDelta,
  filter,
  isResourceActualModeEnabled
}) =>
  updateResourcePlanProjectTotalsForFieldCache_Internal({
    proxy,
    projectId,
    costAndHoursDelta,
    filter,
    queryVariables: {
      allocationStatusList: ALLOCATION_STATUS_LIST,
      includeActualsResourceUsers: isResourceActualModeEnabled
    },
    queryFragment: projectTotalsFragment2,
    fieldName: 'resourceRequestsTotals'
  });

// eslint-disable-next-line camelcase
export const updateResourcePlanProjectTotalsForFieldCache_Internal = ({
  proxy,
  projectId,
  costAndHoursDelta,
  filter,
  queryVariables,
  queryFragment,
  fieldName
}) => {
  const projectData = {
    __typename: 'Project',
    id: projectId
  };

  const cacheID = proxy.identify(projectData);
  const normalizedFilter = normalizeFilter(filter || {});

  const totals = proxy.readFragment({
    id: cacheID,
    fragment: queryFragment,
    variables: {
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      filter: normalizedFilter,
      ...queryVariables
    }
  });

  if (!totals) return;

  const updatedTotal = totals?.[fieldName];

  const newTotals = {
    ...updatedTotal,
    totalCost: {
      ...updatedTotal.totalCost,
      amount:
        updatedTotal.totalCost.amount + costAndHoursDelta.totalCost.costDelta
    },
    totalHours:
      updatedTotal.totalHours + costAndHoursDelta.totalHours.hoursDelta
  };

  proxy.writeFragment({
    id: cacheID,
    fragment: queryFragment,
    variables: {
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      filter: normalizedFilter,
      ...queryVariables
    },
    data: {
      ...totals,
      ...{
        [fieldName]: newTotals
      }
    }
  });
};
