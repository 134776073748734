import { makeStyles } from '@material-ui/core/styles';
import { getResourceRequestStatusStyles } from '~/modules/resourcing/common/util/status-color-util';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(1),
    width: theme.spacing(1),
    alignSelf: 'stretch',
    height: 'calc(100% + 1px)',
    backgroundColor: theme.palette.grey[400]
  },
  ...getResourceRequestStatusStyles(theme)
}));

export default useStyles;
