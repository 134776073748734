export const PROJECT_REQUEST_PERMISSION = {
  EDIT_PROJECT_REQUEST:
    'urn:replicon:project-request-action:edit-project-request',
  VIEW_PROJECT_REQUEST:
    'urn:replicon:project-request-action:view-project-request',
  EDIT_PLANNING_DATA: 'urn:replicon:project-request-action:edit-planning-data',
  VIEW_PLANNING_DATA: 'urn:replicon:project-request-action:view-planning-data'
};

export const PROJECT_REQUEST_DAL = {
  ALL: 'urn:replicon:project-request-data-access-level:all'
};
