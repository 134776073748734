import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItemText } from '@material-ui/core';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import usePageofProjectTemplates from './hooks/usePageofProjectTemplates';

const getOptionSelected = (option, value) => option.id === value.id;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const ProjectTemplateDropdown = ({
  hasError,
  helperText,
  onChange,
  showLabel = true,
  value = '',
  variant = 'filled'
}) => {
  const { formatMessage } = useIntl();

  const renderOption = useCallback(
    option => <ListItemText primary={option.displayText} />,
    []
  );

  const [searchTerm, setSearchTerm] = useState('');
  const { loading, projectTemplates } = usePageofProjectTemplates({
    searchTerm
  });

  const onInputChange = useCallback(
    (_, val, reason) =>
      reason === 'input' ? setSearchTerm(val) : setSearchTerm(''),
    [setSearchTerm]
  );

  const onValueChange = useCallback(
    val => {
      setSearchTerm('');
      onChange(val);
    },
    [setSearchTerm, onChange]
  );
  const label = showLabel
    ? formatMessage({ id: 'templateDropdown.projectTemplate' })
    : null;
  const options = loading ? [] : projectTemplates;

  const filterOptions = useCallback(
    (filteredOptions, _) => filteredOptions,
    []
  );

  return (
    <SimpleAutocomplete
      variant={variant}
      onInputChange={onInputChange}
      loading={loading}
      loadingText={formatMessage({ id: 'templateDropdown.loading' })}
      noOptionsText={formatMessage({ id: 'templateDropdown.noTemplate' })}
      filterOptions={filterOptions}
      fullWidth
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      hasError={hasError}
      helperText={helperText}
      inputLabel={label}
      options={options}
      optionPropText="displayText"
      onChange={onValueChange}
      renderOption={renderOption}
      value={value}
    />
  );
};

ProjectTemplateDropdown.propTypes = {
  hasError: PropTypes.bool,
  helperText: PropTypes.any,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  value: PropTypes.object,
  variant: PropTypes.string
};

export default ProjectTemplateDropdown;
