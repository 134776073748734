import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { CertificateIcon } from '~/modules/common/components/Icons';
import SkillRating from '~/modules/resourcing/common/components/Skills/SkillRating';
import SkillCustomMetadataReadOnly from './SkillCustomMetadataReadOnly';

const useStyles = makeStyles(theme => ({
  root: {
    '&:last-child': {
      borderBottom: 'none'
    },
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingTop: 4,
    paddingBottom: 4
  },
  skillBarStarContainer: {
    marginTop: '-3px',
    flexGrow: 0,
    flexShrink: 0,
    minWidth: 80
  },
  skillHeader: {
    padding: theme.spacing(0, 1, 0, 0),
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    display: 'flex'
  },
  skillName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    flexShrink: 1,
    paddingLeft: theme.spacing(0.5)
  },
  certificateIcon: {
    color: theme.palette.text.secondary,
    marginBottom: -10
  },
  certificateContainer: {
    width: theme.spacing(3)
  }
}));

export const SkillDetails = forwardRef(
  (
    {
      skillAssignment,
      skillAssignment: { certificate },
      certificates,
      maxSkillLevel,
      dataQeId
    },
    ref
  ) => {
    const intl = useIntl();
    const classes = useStyles();

    const cert =
      certificate &&
      certificates &&
      certificates.find(p => p.uri === certificate);
    const title =
      skillAssignment.skillLevel.rating > 0
        ? `${intl.formatMessage({
            id: 'skillInfoCard.proficiencyLevel'
          })}: ${skillAssignment.skillLevel.rating}`
        : intl.formatMessage({ id: 'skillInfoCard.unrated' });

    return (
      <ListItem className={classes.root} disableGutters>
        <div data-qe-id={dataQeId} className={classes.skillHeader}>
          <div className={classes.certificateContainer}>
            {cert && (
              <Tooltip title={cert.name}>
                <CertificateIcon className={classes.certificateIcon} />
              </Tooltip>
            )}
          </div>
          <div className={classes.skillName}>{skillAssignment.displayText}</div>
          {Boolean(maxSkillLevel) && (
            <Tooltip title={title}>
              <SkillRating
                dataQeId={`${skillAssignment.displayText}_SkillRating`}
                className={classes.skillBarStarContainer}
                skillAssignment={skillAssignment}
                editable={false}
                maxSkillLevel={maxSkillLevel}
              />
            </Tooltip>
          )}
        </div>
        {skillAssignment &&
          skillAssignment.skillCustomMetadata &&
          skillAssignment.skillCustomMetadata.length > 0 && (
            <SkillCustomMetadataReadOnly {...skillAssignment} />
          )}
      </ListItem>
    );
  }
);

SkillDetails.propTypes = {
  dataQeId: PropTypes.string,
  skillAssignment: PropTypes.object.isRequired,
  certificates: PropTypes.array,
  maxSkillLevel: PropTypes.number
};

export default SkillDetails;
