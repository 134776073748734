import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CreditMemoIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 2 4 L 2 20 L 22 20 L 22 4 L 2 4 z M 5 9 L 10 9 L 10 11 L 5 11 L 5 9 z M 14.197265625 9 C 14.65711718570155 8.999471001361348 15.09869879036478 9.182613094282729 15.423828125 9.5078125 L 16.01953125 10.1015625 L 16.01953125 10.0625 L 16.576171875 9.5078125 C 16.90074914069764 9.183165879233952 17.34171063999195 9.000069349830225 17.80078125 9 C 18.26007980445292 8.998920481356992 18.6995116769795 9.182192493453149 19.0234375 9.5078125 C 19.69927164520424 10.18379103437761 19.69927164520424 11.27909959062239 19.0234375 11.955078125 L 16.0234375 14.955078125 L 16.0234375 14.99609375 L 16.01953125 15 L 12.9765625 11.955078125 C 12.30072835479574 11.27909959062238 12.30072835479574 10.18379103437762 12.9765625 9.5078125 C 13.3000830733975 9.182964422797982 13.73879884840473 8.999777667721172 14.197265625 9 z M 5 13 L 9 13 L 9 15 L 5 15 L 5 13 z " />
    </>
  </SvgIcon>
));

export default CreditMemoIcon;
