import { orderBy } from 'lodash';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';

export const getEffectiveRateFromScheduleEntries = (
  billingScheduleEntries = [],
  todayForUser
) => {
  const updatedEntries = billingScheduleEntries.map(entry => ({
    ...entry,
    effectiveDate:
      entry.effectiveDate && mapRepliconDateToUtcObject(entry.effectiveDate)
  }));
  const initialEntry = billingScheduleEntries.filter(
    entry => !entry.effectiveDate
  )[0];
  const sortedScheduleEntries = [
    ...orderBy(
      updatedEntries.filter(entry => entry.effectiveDate),
      ['effectiveDate'],
      ['desc']
    )
  ];

  const effectiveRate = sortedScheduleEntries.find(
    s => s.effectiveDate <= mapRepliconDateToUtcObject(todayForUser)
  );

  return effectiveRate || initialEntry;
};
