import { Link } from 'react-router-dom';
import { useOnListItemClick } from '../../../useOnListItemClick';

export const useNameColumnButtonBaseProps = (project = {}) => {
  const onClick = useOnListItemClick(project);

  if (project.useHistoryForRedirect) {
    return { onClick };
  }

  return { component: Link, to: `/projects/${project.slug}` };
};
