import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

export const RowSkeletonBar = ({ classes, barWidth }) => (
  <Paper className={classes.barContainer} square elevation={0}>
    <Skeleton
      className={classes.bar}
      height={24}
      width={barWidth}
      variant="rect"
    />
  </Paper>
);

RowSkeletonBar.propTypes = {
  classes: PropTypes.object,
  barWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const useRowStyles = makeStyles(theme => ({
  leftComponent: {
    width: 320,
    paddingBottom: theme.spacing(1)
  },
  rightComponent: {
    width: 200,
    paddingBottom: theme.spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  barContainer: {
    padding: theme.spacing(0.625, 2),
    width: '100%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.table.border}`
  },
  bar: {
    borderRadius: 16,
    marginLeft: ({ offset }) => offset
  },
  root: {}
}));

export const ChartSkeletonRow = ({
  classes: overrideClasses,
  LeftComponent,
  RightComponent,
  barWidth,
  offset
}) => {
  const classes = useRowStyles({ classes: overrideClasses, offset });

  return (
    <Grid spacing={0} className={classes.root} container>
      <Grid className={classes.leftComponent} item>
        <LeftComponent />
      </Grid>
      <Grid className={classes.grow} item>
        <RowSkeletonBar classes={classes} barWidth={barWidth} />
      </Grid>
      {RightComponent && (
        <Grid className={classes.rightComponent} item>
          <RightComponent />
        </Grid>
      )}
    </Grid>
  );
};

ChartSkeletonRow.propTypes = {
  classes: PropTypes.object,
  LeftComponent: PropTypes.func.isRequired,
  barWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offset: PropTypes.number,
  RightComponent: PropTypes.func
};

export const ChartSkeletonRows = ({
  classes,
  LeftComponent,
  RightComponent
}) => {
  const barGroup = (
    <>
      <ChartSkeletonRow
        classes={classes}
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
        barWidth="100%"
        offset={8}
      />
      <ChartSkeletonRow
        classes={classes}
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
        barWidth="75%"
        offset={300}
      />
      <ChartSkeletonRow
        classes={classes}
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
        barWidth="50%"
        offset={140}
      />
    </>
  );

  return (
    <>
      {barGroup}
      {barGroup}
    </>
  );
};

ChartSkeletonRows.propTypes = {
  classes: PropTypes.object,
  LeftComponent: PropTypes.func.isRequired,
  RightComponent: PropTypes.func
};

export default ChartSkeletonRows;
