import React, { useState, useMemo } from 'react';
import { useSearchableProjectManager } from '~/modules/common/hooks';

export const mapOptions = projectManagers =>
  projectManagers.map(projectManager => ({
    ...projectManager,
    key: projectManager.displayText,
    label: projectManager.displayText,
    value: projectManager.id
  }));

export const cleanSearchTerm = value => value.replace(/['",]+/g, '');

export const matchOption = fetchOptions => async (_, term) => {
  if (!term) {
    return null;
  }

  const projectManagers = await fetchOptions(cleanSearchTerm(term));

  const option = projectManagers.shift();

  if (option) {
    return {
      ...option,
      key: option.displayText || option.name
    };
  }

  return null;
};

export const withCriteriaProvider = (tag, context) => BaseComponent => ({
  ...props
}) => {
  const { searchCriteria: { criterions = {} } = {} } = props;
  const [selected, setSelected] = useState(criterions[tag] || []);

  const { searchProjectManagers } = useSearchableProjectManager();

  const value = useMemo(
    () => ({
      options: mapOptions([]),
      matchOption: matchOption(searchProjectManagers),
      selected,
      setSelected,
      suggestions: searchProjectManagers
    }),
    [selected, searchProjectManagers]
  );

  return (
    <context.Provider value={value}>
      <BaseComponent {...props} />
    </context.Provider>
  );
};

export default withCriteriaProvider;
