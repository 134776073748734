import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { getLuxonJsDateFormatFromMe } from '~/modules/common/dates/convert';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const DateFormatter = ({ field, record }) => {
  const classes = useStyles();
  const effectiveDate = record[field];

  const me = useMeContext();
  const effectiveDateDisplay = useMemo(
    () =>
      effectiveDate ? (
        effectiveDate.toFormat(getLuxonJsDateFormatFromMe(me))
      ) : (
        <FormattedMessage id="rateTable.initialRate"></FormattedMessage>
      ),
    [effectiveDate, me]
  );

  return (
    <Typography className={classes.root}>{effectiveDateDisplay}</Typography>
  );
};

DateFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default DateFormatter;
