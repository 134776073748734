export const saveKeyValueSettings = ({
  key,
  putKeyValueSettings,
  scale,
  displayUnit,
  showActuals,
  ...rest
}) => {
  putKeyValueSettings({
    variables: {
      input: {
        key,
        settings: {
          scale,
          displayUnit,
          showActuals,
          ...rest
        }
      }
    }
  });
};

export const handleScaleChange = ({
  putKeyValueSettings,
  displayUnit,
  setScale,
  key,
  showActuals,
  ...rest
}) => scale => {
  setScale(scale);
  saveKeyValueSettings({
    putKeyValueSettings,
    displayUnit,
    scale,
    key,
    showActuals,
    ...rest
  });
};

export const handleDisplayUnitChange = ({
  scale,
  setDisplayUnit,
  putKeyValueSettings,
  key,
  showActuals,
  ...rest
}) => displayUnit => {
  setDisplayUnit(displayUnit);
  saveKeyValueSettings({
    putKeyValueSettings,
    displayUnit,
    scale,
    key,
    showActuals,
    ...rest
  });
};

export const handleShowActualChange = ({
  key,
  putKeyValueSettings,
  displayUnit,
  scale,
  setShowActuals,
  ...rest
}) => showActuals => {
  setShowActuals(showActuals);
  saveKeyValueSettings({
    putKeyValueSettings,
    displayUnit,
    scale,
    key,
    showActuals,
    ...rest
  });
};

export default ({
  putKeyValueSettings,
  displayUnit,
  scale,
  setScale,
  setDisplayUnit,
  key,
  showActuals,
  setShowActuals,
  ...rest
}) => ({
  handleScaleChange: handleScaleChange({
    key,
    putKeyValueSettings,
    displayUnit,
    setScale,
    showActuals,
    ...rest
  }),
  handleShowActualChange: handleShowActualChange({
    key,
    putKeyValueSettings,
    displayUnit,
    scale,
    setShowActuals,
    ...rest
  }),
  handleDisplayUnitChange: handleDisplayUnitChange({
    key,
    putKeyValueSettings,
    setDisplayUnit,
    scale,
    showActuals,
    ...rest
  })
});
