import { gql } from 'graphql-tag';
import { columnArguments } from '../clientsColumnSelection';

export const CLIENTS_QUERY = gql`
  query clients($page: Int!, $pageSize: Int!, $clientFilter: ClientFilter2, $sort: ClientSort, ${columnArguments}) {
    clients(page: $page, pageSize: $pageSize, sort: $sort, clientFilter: $clientFilter) {
      totalItems
      items {
        id
        name
        displayText
        slug
        code @include(if: $includeCode)
        isActive @include(if: $includeIsActive)
        clientManager @include(if: $includeClientManager) {
          id
          uri
          displayText
        }
        projectCount @include(if: $includeProjectCount)
        totalEstimatedHours @include(if: $includeTotalEstimatedHours)
        totalActualHours @include(if: $includeTotalActualHours)
        totalEstimatedCost @include(if: $includeTotalEstimatedCost) {
          amount
          currency {
            id
            symbol
          }
        }
        totalActualExpenseCost @include(if: $includeTotalActualExpenseCost) {
          amount
          currency {
            id
            symbol
          }
        }
        totalActualResourceCost @include(if: $includeTotalActualResourceCost) {
          amount
          currency {
            id
            symbol
          }
        }
        totalActualCost @include(if: $includeTotalActualCost) {
          amount
          currency {
            id
            symbol
          }
        }
        totalEstimatedBilling @include(if: $includeTotalEstimatedBilling) {
          amount
          currency {
            id
            symbol
          }
        }
        totalActualBilling @include(if: $includeTotalActualBilling) {
          amount
          currency {
            id
            symbol
          }
        }
        totalActualRevenue @include(if: $includeTotalActualBilling) {
          amount
          currency {
            id
            symbol
          }
        }
        taxProfile @include(if: $includeTaxProfile) {
          id
          displayText
        }
      }
    }
  }
`;
