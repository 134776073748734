import React, { useMemo } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  TextField,
  InputAdornment,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { getError, hasError } from '~/util';
import { useDialogState } from '~/modules/common/hooks';
import { useNextReferenceNumberSetting } from '~/modules/billing-invoicing/common/hooks';
import { useMeContext } from '~/modules/me';
import { ReferenceNumberSettingDialog } from './ReferenceNumberSettingDialog';

export const getModifiedErrors = ({
  formatMessage,
  errors,
  nextReferenceNumberSetting
}) => {
  if (errors?.displayId === 'duplicateReferenceNumber')
    return {
      displayId: formatMessage({
        id:
          nextReferenceNumberSetting?.isAutoGeneratedReferenceNumber &&
          !nextReferenceNumberSetting?.isAllowEditAutoGeneratedNumber
            ? 'billDetails.validations.duplicateReferenceNumberErrorForNonEditableField'
            : 'billDetails.validations.duplicateReferenceNumberErrorForEditableField'
      })
    };

  return errors;
};

const useStyles = makeStyles(theme => ({
  referenceSetting: {
    padding: theme.spacing(0.5)
  }
}));
const editReferenceSettingPermissionUri =
  'urn:replicon:invoice2-settings-action:edit-invoice-reference-number-settings';

export const ReferenceNumberField = ({
  hasClient,
  onReferenceNumberChange,
  transactionType,
  errors,
  displayId,
  inputRefCallback,
  displayText
}) => {
  const {
    permissionsMap,
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    open: openReferenceSetting,
    openDialog: onReferenceSettingClick,
    closeDialog: onReferenceSettingClose
  } = useDialogState(false);

  const canEditReferenceSetting = useMemo(
    () => permissionsMap[editReferenceSettingPermissionUri],
    [permissionsMap]
  );
  const ReferenceInputProps = useMemo(
    () =>
      isPsaPrpAccessibilityIssuesEnabled
        ? canEditReferenceSetting
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={onReferenceSettingClick}
                    className={classes.referenceSetting}
                    aria-label={formatMessage({
                      id: 'referenceNumberField.settingLabel'
                    })}
                  >
                    <SettingsIcon />
                  </IconButton>
                </InputAdornment>
              ),
              'aria-label': formatMessage(
                {
                  id: 'billDetails.referenceNumber'
                },
                { value: displayId }
              )
            }
          : {
              'aria-label': formatMessage(
                {
                  id: 'billDetails.referenceNumber'
                },
                {
                  value: displayId
                }
              )
            }
        : canEditReferenceSetting
        ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={onReferenceSettingClick}
                  className={classes.referenceSetting}
                  aria-label={formatMessage({
                    id: 'referenceNumberField.settingLabel'
                  })}
                >
                  <SettingsIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        : null,
    [
      canEditReferenceSetting,
      classes.referenceSetting,
      displayId,
      formatMessage,
      isPsaPrpAccessibilityIssuesEnabled,
      onReferenceSettingClick
    ]
  );

  const { nextReferenceNumberSetting } = useNextReferenceNumberSetting({
    transactionType
  });
  const disableReferenceNumber = useMemo(
    () =>
      nextReferenceNumberSetting &&
      nextReferenceNumberSetting.isAutoGeneratedReferenceNumber &&
      !nextReferenceNumberSetting.isAllowEditAutoGeneratedNumber,
    [nextReferenceNumberSetting]
  );

  const modifiedErrors = getModifiedErrors({
    formatMessage,
    errors,
    nextReferenceNumberSetting
  });

  return (
    <>
      <TextField
        id={transactionType}
        variant="filled"
        required
        fullWidth
        autoFocus={hasClient}
        inputRef={inputRefCallback('displayId')}
        label={formatMessage({ id: 'referenceNumber.label' })}
        value={displayId}
        onChange={onReferenceNumberChange}
        error={hasError(modifiedErrors, 'displayId')}
        helperText={getError(modifiedErrors, 'displayId')}
        InputProps={ReferenceInputProps}
        disabled={disableReferenceNumber}
        data-qe-id="referenceNumber"
      />
      {canEditReferenceSetting ? (
        <ReferenceNumberSettingDialog
          open={openReferenceSetting}
          onClose={onReferenceSettingClose}
          displayText={displayText}
          transactionType={transactionType}
          onReferenceNumberChange={onReferenceNumberChange}
        />
      ) : null}
    </>
  );
};
ReferenceNumberField.propTypes = {
  hasClient: PropTypes.bool,
  onReferenceNumberChange: PropTypes.func,
  transactionType: PropTypes.string.isRequired,
  errors: PropTypes.object,
  displayId: PropTypes.string,
  displayText: PropTypes.string,
  inputRefCallback: PropTypes.func
};
export default ReferenceNumberField;
