import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SentimentNeutralIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 11.990234 2 C 6.4702344 2 2 6.48 2 12 C 2 17.52 6.4702344 22 11.990234 22 C 17.520234 22 22 17.52 22 12 C 22 6.48 17.520234 2 11.990234 2 z M 12 4 C 16.42 4 20 7.58 20 12 C 20 16.42 16.42 20 12 20 C 7.58 20 4 16.42 4 12 C 4 7.58 7.58 4 12 4 z M 8.5 8 A 1.5 1.5 0 0 0 7 9.5 A 1.5 1.5 0 0 0 8.5 11 A 1.5 1.5 0 0 0 10 9.5 A 1.5 1.5 0 0 0 8.5 8 z M 15.5 8 A 1.5 1.5 0 0 0 14 9.5 A 1.5 1.5 0 0 0 15.5 11 A 1.5 1.5 0 0 0 17 9.5 A 1.5 1.5 0 0 0 15.5 8 z M 14.980469 15.470703 L 9.0292969 15.480469 L 9.0292969 16.630859 L 14.980469 16.619141 L 14.980469 15.470703 z" />
    </>
  </SvgIcon>
));

export default SentimentNeutralIcon;
