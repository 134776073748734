import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useLoadMore2 as useLoadMore } from '~/modules/common/hooks';

export const GET_PAGE_OF_CHILDREN_PORTFOLIOS = gql`
  query getPageOfChildrenPortfolios(
    $page: Int!
    $pageSize: Int!
    $parentId: String!
  ) {
    childPortfolios(page: $page, pageSize: $pageSize, parentId: $parentId) {
      id
      slug
      displayText
    }
  }
`;

export const usePageOfChildrenPortfolios = ({
  page = 1,
  pageSize = 10,
  parentId
}) => {
  const inputVariables = useMemo(
    () => ({
      page,
      pageSize,
      parentId
    }),
    [page, pageSize, parentId]
  );
  const { data, fetchMore, loading } = useQuery(
    GET_PAGE_OF_CHILDREN_PORTFOLIOS,
    {
      variables: inputVariables,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const childPortfolios = get(data, 'childPortfolios', []);
  const { hasMore, loadingMore, loadMore } = useLoadMore({
    totalFetchedItems: childPortfolios.length,
    fetchMore,
    loading,
    pageSize: 10
  });

  return {
    loading,
    loadingMore,
    childPortfolios,
    loadMore,
    hasMore
  };
};

export default usePageOfChildrenPortfolios;
