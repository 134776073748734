import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { RemoveCircleSharp } from '@material-ui/icons';
import { PropTypes } from 'prop-types';

const RemoveAddressButton = ({
  record,
  column: {
    addressHandlers: { onRemoveAddress }
  }
}) => {
  const { formatMessage } = useIntl();

  const handleChange = useCallback(() => {
    onRemoveAddress(record);
  }, [onRemoveAddress, record]);

  return (
    <>
      <IconButton
        data-qe-id="RemoveAddress"
        aria-label={formatMessage({
          id:
            'projectBasicInfoCard.addressInfo.actions.deleteConfirmationDialog.removeAddress'
        })}
        onClick={handleChange}
      >
        <RemoveCircleSharp />
      </IconButton>
    </>
  );
};

RemoveAddressButton.propTypes = {
  record: PropTypes.object,
  column: PropTypes.object
};

export default RemoveAddressButton;
