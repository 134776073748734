import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ChartLegends } from '~/modules/common/charts/dashboard';
import NoSkillsMessage from './NoSkillsMessage';
import SkillsDistributionBubbleChart from './SkillsDistributionBubbleChart';
import SkillsDistributionBubbleChartLoading from './SkillsDistributionBubbleChartLoading';
import { useSkillsDistributionBubbleChartData } from './hooks';

const useLegendStyles = makeStyles(theme => ({
  chartLegends: {
    paddingRight: theme.spacing(1)
  }
}));

export const SkillsDistributionChartContent = ({ skillsLoading, skills }) => {
  const legendClasses = useLegendStyles();

  const { chartData, legends } = useSkillsDistributionBubbleChartData(skills);

  if (skillsLoading) {
    return <SkillsDistributionBubbleChartLoading />;
  }
  if (skills.length === 0) {
    return <NoSkillsMessage />;
  }

  return (
    <>
      <ChartLegends className={legendClasses.chartLegends} legends={legends} />
      <SkillsDistributionBubbleChart data={chartData} />
    </>
  );
};

SkillsDistributionChartContent.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.object.isRequired,
      skill: PropTypes.object.isRequired,
      userCount: PropTypes.number.isRequired
    })
  ).isRequired,
  skillsLoading: PropTypes.bool.isRequired
};

export default SkillsDistributionChartContent;
