import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProgressbarLegend } from '~/modules/common/charts/dashboard';
import useStyles from './useStyles';
import OverAllocatedIndicator from './OverAllocatedIndicator';

export const labels = {
  allocation: <FormattedMessage id="resourceAvailabilityCard.allocation" />
};

const AvailabilityLegend = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ProgressbarLegend label={labels.allocation} />
      <OverAllocatedIndicator className={classes.overAllocatedMark} />
      <span className={classes.overAllocatedCaption}>
        <FormattedMessage id="resourcingLegends.overAllocated" />
      </span>
    </div>
  );
};

export default AvailabilityLegend;
