import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KPIIcon } from '~/modules/common/components/Icons';
import { ChartCard } from '~/modules/common/charts/dashboard';
import { useIsBreakpointDown, useScrollToTop } from '~/modules/common/hooks';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import { useHasPermission } from '~/modules/common/permissions';
import ProjectCard from './ProjectCard';
import ActiveTasksCard from './ActiveTasksCard';
import ResourcesCard from './ResourcesCard';
import ProjectsKPI from './ProjectsKPI';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1)
    },
    minHeight: '-webkit-max-content'
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2)
    }
  }
}));

const icons = {
  overview: <KPIIcon />
};

export const ProjectsOverview = () => {
  useScrollToTop();
  const classes = useStyles();
  const canViewTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-tasks'
  });

  const isMobile = useIsBreakpointDown('xs');

  const labels = {
    overview: isMobile ? (
      <FormattedMessage id="projectOverview.kpi" />
    ) : (
      <FormattedMessage id="projectOverview.keyPerformanceIndicators" />
    )
  };

  return (
    <Grid spacing={0} container className={classes.container}>
      <Grid className={classes.item} item xs={12} sm={12} lg={12}>
        <ErrorBoundary key="ProjectsOverviewChart">
          <ChartCard
            chartTitle={labels.overview}
            chartAvatar={icons.overview}
            ChartComponent={ProjectsKPI}
            showScaleSelector
            showPeriodFilter
          />
        </ErrorBoundary>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={6} lg={4}>
        <ProjectCard />
      </Grid>
      {canViewTasks && (
        <Grid className={classes.item} item xs={12} sm={6} lg={4}>
          <ActiveTasksCard />
        </Grid>
      )}
      <Grid className={classes.item} item xs={12} sm={6} lg={4}>
        <ResourcesCard />
      </Grid>
    </Grid>
  );
};

export default ProjectsOverview;
