import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';
import BillingTransactionContextProvider from './BillingTransactionContextProvider';
import { BillingTransactionTabUtil } from './BillingTransactionTabUtil';
import {
  useBillingTransactionState,
  useBillingTransactionItemSelector
} from './hooks';

export const defaultColumns = [
  'displayText',
  'transactionDate',
  'dueDate',
  'type',
  'balanceWithoutAllocationsAmount',
  'balanceAmount',
  'balanceStatus',
  'actionColumn',
  'description',
  'period'
];

export const BillingTransactionTab = ({
  searchState,
  selectedColumns = defaultColumns,
  isAccountReceivable = false,
  queryParams,
  isGlobalBilling = false,
  ...rest
}) => {
  const me = useMeContext();
  const billingTransactionStates = useBillingTransactionState();
  const selectionHandlers = useBillingTransactionItemSelector(
    billingTransactionStates
  );
  const selectedTransactionItemsIds = useMemo(
    () => billingTransactionStates.includedTransactionItems.map(x => x.id),
    [billingTransactionStates.includedTransactionItems]
  );

  return (
    <BillingTransactionContextProvider
      searchState={searchState}
      queryParams={queryParams}
      isAccountReceivable={isAccountReceivable}
      isGlobalBilling={isGlobalBilling}
      onRowSelect={selectionHandlers.onRowSelect}
      selectedTransactionItems={selectedTransactionItemsIds}
      featureFlags={me.featureFlags}
      hasRowSelector={selectedColumns.includes('rowSelector')}
    >
      <BillingTransactionTabUtil
        selectedColumns={selectedColumns}
        searchState={searchState}
        isAccountReceivable={isAccountReceivable}
        queryParams={queryParams}
        isGlobalBilling={isGlobalBilling}
        selectionHandlers={selectionHandlers}
        billingTransactionStates={billingTransactionStates}
        me={me}
        {...rest}
      />
    </BillingTransactionContextProvider>
  );
};

BillingTransactionTab.propTypes = {
  searchState: PropTypes.string,
  selectedColumns: PropTypes.array,
  isAccountReceivable: PropTypes.bool,
  queryParams: PropTypes.object,
  isGlobalBilling: PropTypes.bool
};

export default BillingTransactionTab;
