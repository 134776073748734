import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    marginLeft: theme.spacing(-1)
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    paddingTop: theme.spacing(0.5),
    whiteSpace: 'nowrap'
  }
}));

const Name = ({ record }) => {
  const { checked = false } = record;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Checkbox
        className={classes.checkbox}
        checked={checked}
        color="primary"
      />
      <Typography className={classes.name}>{record.name}</Typography>
    </div>
  );
};

Name.propTypes = {
  record: PropTypes.object
};

export default Name;
