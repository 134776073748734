import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(2, 1),
    marginTop: theme.spacing(4)
  }
}));

export const Uploading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress data-qe-id="uploading" size={25} />
      &nbsp;&nbsp;
      <Typography variant="body1">
        <FormattedMessage id="fileWebAddressDialog.uploading" />
      </Typography>
    </div>
  );
};

Uploading.propTypes = {};

export default Uploading;
