import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useAddressInfoListItemContext } from '../hooks/useAddressInfoListItemContext';
import {
  AddressField,
  RemoveAddressButton,
  AddressTypeDropdown,
  CountryDropdown
} from '../columns/formatters/index';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2.5),
    paddingTop: index => (index !== 0 ? theme.spacing(1.5) : theme.spacing(0)),
    paddingLeft: theme.spacing(0)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(0)
  },
  addressField: {
    padding: theme.spacing(0.5),
    width: '50%'
  },
  initialRow: {
    padding: theme.spacing(0.5),
    width: '100%'
  }
}));

const EditableMobileAddressInfoListItem = ({ record, rowIndex: index }) => {
  const { addressHandlers, countries } = useAddressInfoListItemContext();

  const classes = useStyles(index);

  const handlers = useMemo(() => ({ addressHandlers }), [addressHandlers]);

  const column = useMemo(() => ({ addressHandlers, countries }), [
    addressHandlers,
    countries
  ]);

  return (
    <Grid className={classes.container}>
      <Grid className={classes.column}>
        <Grid className={classes.initialRow}>
          <AddressField
            field="address"
            record={record}
            column={handlers}
            index={index}
          />
        </Grid>
        <Grid className={classes.initialRow}>
          <AddressField
            field="city"
            record={record}
            column={handlers}
            index={index}
          />
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.addressField}>
            <AddressField
              field="stateProvince"
              record={record}
              column={handlers}
              index={index}
            />
          </Grid>
          <Grid className={classes.addressField}>
            <AddressField
              field="zipPostalCode"
              record={record}
              column={handlers}
              index={index}
            />
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.addressField}>
            <CountryDropdown
              field="country"
              record={record}
              column={column}
              index={index}
            />
          </Grid>
          <Grid className={classes.addressField}>
            <AddressTypeDropdown
              field="addressType"
              record={record}
              column={handlers}
              index={index}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <RemoveAddressButton record={record} column={handlers} />
      </Grid>
    </Grid>
  );
};

EditableMobileAddressInfoListItem.propTypes = {
  record: PropTypes.object,
  rowIndex: PropTypes.number
};

export default EditableMobileAddressInfoListItem;
