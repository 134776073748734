import { gql } from 'graphql-tag';

export const notificationFragment = gql`
  fragment Notification on Notification {
    id
    userUri
    message
    type
    targetUrl
    unreadOnTimestamp_id
    createdOnTimestamp_id
  }
`;
