import React from 'react';
import PropTypes from 'prop-types';
import { EmployeeTypeIcon } from '~/modules/common/components';
import { withGroupSettings } from '~/modules/common/enhancers';
import GroupIconField from './GroupIconField';

export const ResourceRequestEmployeeTypeField = ({
  value,
  me: { legacyEmployeeTypeDisabled },
  employeeTypeSettings
}) =>
  legacyEmployeeTypeDisabled && (
    <GroupIconField
      value={value}
      GroupIcon={EmployeeTypeIcon}
      groupSettings={employeeTypeSettings}
      labelKey="resourceRequestItem.employeeType"
    />
  );

ResourceRequestEmployeeTypeField.propTypes = {
  value: PropTypes.object,
  me: PropTypes.object,
  employeeTypeSettings: PropTypes.object.isRequired
};
export default withGroupSettings(
  'employeeType',
  'employeeTypeSettings'
)(ResourceRequestEmployeeTypeField);
