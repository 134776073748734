import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: alpha(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}));

const ButtonBaseHover = forwardRef((props, ref) => {
  const { className, ...otherProps } = props;
  const classes = useStyles();

  return (
    <ButtonBase
      {...otherProps}
      className={classNames(classes.root, className)}
      ref={ref}
    />
  );
});

ButtonBaseHover.propTypes = {
  className: PropTypes.string
};

export default ButtonBaseHover;
