import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NoValue } from '~/modules/common/components';
import { getLuxonJsDateFormatFromMe } from '~/modules/common/dates/convert';
import { getBillingPlanFrequencyDisplayText } from '~/modules/projects/project/common/enums';
import { useMeContext } from '~/modules/me';

const useStyles = makeStyles(theme => ({
  column: {
    fontWeight: theme.typography.fontWeightMedium,
    width: '100%'
  }
}));

export const FrequencyFormatter = ({ field, record }) => {
  const me = useMeContext();
  const frequencyDetail =
    record['urn:replicon:script-key:parameter:frequency-formatted'];
  const relativeDate =
    record['urn:replicon:script-key:parameter:relative-date'];
  const luxonFormat = useMemo(() => getLuxonJsDateFormatFromMe(me), [me]);
  const classes = useStyles();

  if (!(frequencyDetail && frequencyDetail.frequency)) return <NoValue />;

  return (
    <Typography className={classes.column} variant="body2">
      {getBillingPlanFrequencyDisplayText(
        { ...frequencyDetail, relativeDate },
        luxonFormat
      )}
    </Typography>
  );
};

FrequencyFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default FrequencyFormatter;
