import { useQuery } from '@apollo/client';
import { ASSIGNED_CLIENT_MANAGERS_QUERY } from '~/modules/clients/graphql';

export const useClientManagers = () => {
  const { data, loading } = useQuery(ASSIGNED_CLIENT_MANAGERS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const clientManagers =
    data?.assignedClientManagers?.map(clientManager => ({
      ...clientManager,
      value: clientManager.id,
      label: clientManager.displayText,
      key: clientManager.displayText
    })) || [];

  return { clientManagers, loading };
};

export default useClientManagers;
