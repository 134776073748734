import { useQuery } from '@apollo/client';
import { GET_ENABLED_CUSTOM_FIELDS_QUERY } from '../graphql';

export const useEnabledCustomFieldDefinitions = groupName => {
  const { data, loading } = useQuery(GET_ENABLED_CUSTOM_FIELDS_QUERY, {
    variables: {
      groupName
    }
  });

  return {
    customFieldDefinitions: data && data.enabledCustomFields,
    customFieldDefinitionsLoading: loading
  };
};
