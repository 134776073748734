import { useSearchableDepartments } from '~/modules/common/enhancers';
import { withCustomFetchCriteriaProvider } from '../enhancers';
import { departmentTag } from './tag';
import DepartmentFacetDetails from './DepartmentFacetDetails';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableDepartments);

export default {
  tag: departmentTag,
  makeCriteriaProvider,
  DetailsControl: DepartmentFacetDetails
};
