import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useSearchableProjects } from '~/modules/common/hooks/useSearchableProjects';
import { onChange } from '../enhancers/facetHelpers';
import GenericProjectFacetDetails from '../GenericProjectFacetDetails';

export const ProjectFacetDetails = ({
  classes,
  className,
  selected,
  setSelected,
  searchFacetClasses
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { projects, loading, hasMore } = useSearchableProjects({ searchTerm });

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  return (
    <GenericProjectFacetDetails
      className={className}
      onChange={onValueChange}
      options={projects}
      loading={loading}
      setSearchTerm={setSearchTerm}
      selected={selected}
      classes={classes}
      hasMore={hasMore}
      searchFacetClasses={searchFacetClasses}
    />
  );
};

ProjectFacetDetails.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  searchFacetClasses: PropTypes.object
};

export default ProjectFacetDetails;
