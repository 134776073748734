import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { FocusableMenuItem } from '~/modules/common/components/FocusableMenuItem';
import useKeyBoardEventHandlers from '~/modules/common/hooks/useKeyBoardEventHandlers';
import ImportExportMenuItemLabel from './ImportExportMenuItemLabel';
import ActionPropTypes from './ActionPropTypes';

export const ImportExportMenuItem = ({ actionItem, index }) => {
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const itemLabel = (
    <ImportExportMenuItemLabel
      primaryIcon={actionItem.primaryIcon}
      label={actionItem.label}
      secondaryLabel={actionItem.secondaryLabel}
      secondaryIcon={actionItem.secondaryIcon}
      isSFConnected={actionItem.isSFConnected}
    />
  );
  const [selected, setSelected] = useState(0);
  const getHandleClick = useCallback(() => {
    if (actionItem.onClick) actionItem.onClick();
  }, [actionItem]);

  const { handleKeyDown } = useKeyBoardEventHandlers({
    onEnterClick: getHandleClick
  });

  const onKeyDown = useCallback(
    event => {
      setSelected(event.currentTarget.id);
      handleKeyDown();
    },
    [handleKeyDown]
  );

  if (actionItem.href) {
    return isPsaPrpAccessibilityIssuesEnabled ? (
      <FocusableMenuItem
        id={index}
        isSelected={selected === index}
        key={`${actionItem.label}_MenuItem`}
        component="a"
        href={actionItem.href}
        disabled={actionItem.permission === false}
        onKeyDown={onKeyDown}
      >
        {itemLabel}
      </FocusableMenuItem>
    ) : (
      <MenuItem
        key={`${actionItem.label}_MenuItem`}
        component="a"
        href={actionItem.href}
        disabled={actionItem.permission === false}
      >
        {itemLabel}
      </MenuItem>
    );
  }

  return isPsaPrpAccessibilityIssuesEnabled ? (
    <FocusableMenuItem
      id={index}
      isSelected={selected === index}
      handleOnClick={getHandleClick}
      key={`${actionItem.label}_MenuItem`}
      onKeyDown={onKeyDown}
    >
      {itemLabel}
    </FocusableMenuItem>
  ) : (
    <MenuItem key={`${actionItem.label}_MenuItem`} onClick={getHandleClick}>
      {itemLabel}
    </MenuItem>
  );
};

ImportExportMenuItem.propTypes = {
  actionItem: ActionPropTypes.actionItem.isRequired,
  index: PropTypes.number
};

export default ImportExportMenuItem;
