import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { makeStyles, Popover, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDialogState, useMenuState } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: 'pointer',
    marginBottom: theme.spacing(-0.5) - 1,
    marginLeft: theme.spacing(1),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.75),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2.25)
    }
  },
  messageContent: {
    whiteSpace: 'pre',
    padding: theme.spacing(2),
    lineHeight: 1,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'unset',
      lineHeight: '1.5'
    },
    color: theme.palette.text.secondary,
    fontWeight: 500
  }
}));

const popoverProps = {
  anchor: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transform: {
    vertical: 'top',
    horizontal: 'right'
  }
};

const AlternateTooltip = ({ classes: classesOverride, messageId }) => {
  const {
    open: costDialogOpen,
    openDialog: openCostDialog,
    closeDialog: closeCostDialog
  } = useDialogState(false);

  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();

  const handleCostInfoClick = useCallback(
    event => {
      onMenuClick(event);
      openCostDialog();
    },
    [onMenuClick, openCostDialog]
  );

  const handleCloseCostPopover = useCallback(() => {
    onMenuClose();
    closeCostDialog();
  }, [onMenuClose, closeCostDialog]);

  const classes = useStyles({ classes: classesOverride });

  return (
    <>
      <InfoSharpIcon className={classes.icon} onClick={handleCostInfoClick} />
      <Popover
        anchorOrigin={popoverProps.anchor}
        transformOrigin={popoverProps.transform}
        anchorEl={anchorEl}
        open={costDialogOpen}
        onClose={handleCloseCostPopover}
      >
        <Typography className={classes.messageContent} variant="body2">
          <FormattedMessage id={messageId}></FormattedMessage>
        </Typography>
      </Popover>
    </>
  );
};

AlternateTooltip.propTypes = {
  classes: PropTypes.object,
  messageId: PropTypes.string
};

export default AlternateTooltip;
