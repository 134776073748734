import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ButtonBase, makeStyles } from '@material-ui/core';
import { CountAndText } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

export const ProjectRow = ({
  classes: classesOverride,
  overEstimatedProject
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <ButtonBase
      className={classes.root}
      component={Link}
      to={`/projects/${overEstimatedProject.project.slug}/tasks`}
      disableRipple
    >
      <CountAndText
        count={overEstimatedProject.taskCounts}
        text={overEstimatedProject.project.displayText}
        textVariant="body2"
      />
    </ButtonBase>
  );
};

ProjectRow.propTypes = {
  classes: PropTypes.object,
  overEstimatedProject: PropTypes.object
};

export default ProjectRow;
