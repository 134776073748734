import { useState, useCallback } from 'react';

export const useAdvancedRateCardViewSummary = () => {
  const [viewSummary, setViewSummary] = useState(false);
  const onViewSummaryClick = useCallback(() => {
    setViewSummary(!viewSummary);
  }, [viewSummary]);

  return {
    viewSummary,
    onViewSummaryClick
  };
};
