import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Grid, makeStyles, Button } from '@material-ui/core';
import FieldLabel from './components/FieldLabel';

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  title: {
    ...theme.typography.subtitle1,
    paddingTop: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  projectLabel: {
    color: theme.palette.text.secondary
  },
  gridItem: {
    textAlign: 'center'
  },
  taskAllocationGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  closeButton: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'end'
  }
}));

const availableProjectLabelValue = {
  project: (
    <span>
      <FormattedMessage id="taskAllocationEditor.project" />
    </span>
  )
};

const TaskAllocationEditorSkeleton = ({ onClose }) => {
  const classes = useStyles();

  return (
    <>
      <>
        <Skeleton variant="rect" width={480} height={50} />
        <FieldLabel
          messageId="taskAllocationEditor.taskAllocation"
          variant="subtitle1"
          className={classes.title}
        />
        <Grid>
          <Grid item container spacing={2} className={classes.datePicker}>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" width={220} height={40} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" width={220} height={40} />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FieldLabel
                variant="body2"
                messageId="taskAllocationEditor.availableProject"
                messageValues={availableProjectLabelValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Skeleton variant="text" width={64} height={20} />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.taskAllocationGrid}>
              <FieldLabel
                variant="body2"
                messageId="taskAllocationEditor.taskAllocation"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton variant="text" width={64} height={20} />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FieldLabel
                variant="body2"
                messageId="taskAllocationEditor.remaining"
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Skeleton variant="text" width={64} height={20} />
            </Grid>
          </Grid>
        </Grid>
      </>
      <div className={classes.closeButton}>
        <Button variant="contained" onClick={onClose} size="small">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          disabled
        >
          <FormattedMessage id="taskAllocationEditor.setAllocation" />
        </Button>
      </div>
    </>
  );
};

TaskAllocationEditorSkeleton.propTypes = {
  onClose: PropTypes.func
};

export default TaskAllocationEditorSkeleton;
