import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftSharp';
import FirstPageIcon from '@material-ui/icons/FirstPageSharp';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  apology: {
    color: theme.palette.text.secondary
  },
  buttonRow: {
    margin: theme.spacing(0, -1)
  },
  firstButton: {
    margin: theme.spacing(0, 1)
  }
}));

const firstPageButtonIcon = <FirstPageIcon />;
const lastPageButtonIcon = <ChevronLeftIcon />;

export const PageOutOfBoundsMessage = ({
  classes: classesOverride,
  currentPage,
  setCurrentPage
}) => {
  const classes = useStyles({ classes: classesOverride });

  const handleFirstClick = useCallback(() => setCurrentPage(1), [
    setCurrentPage
  ]);

  const handleLastClick = useCallback(() => setCurrentPage(currentPage - 1), [
    currentPage,
    setCurrentPage
  ]);
  const isOutOfBoundAfterSecondPage = currentPage > 2;

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.title}>
        <FormattedMessage id="pageOutOfBounds.noAvailableRecords" />
      </Typography>
      <p>
        {isOutOfBoundAfterSecondPage ? (
          <FormattedMessage id="pageOutOfBounds.firstOrLastPageMessage" />
        ) : (
          <FormattedMessage id="pageOutOfBounds.firstPageMessage" />
        )}
      </p>
      <div className={classes.buttonRow}>
        <Button
          className={classes.firstButton}
          color="primary"
          onClick={handleFirstClick}
          variant="outlined"
          startIcon={firstPageButtonIcon}
        >
          <FormattedMessage id="pageOutOfBounds.firstPageButtonTitle" />
        </Button>
        {isOutOfBoundAfterSecondPage ? (
          <Button
            color="primary"
            onClick={handleLastClick}
            variant="outlined"
            startIcon={lastPageButtonIcon}
          >
            <FormattedMessage id="pageOutOfBounds.backToLastRecordButtonTitle" />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

PageOutOfBoundsMessage.propTypes = {
  classes: PropTypes.object,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired
};

export default PageOutOfBoundsMessage;
