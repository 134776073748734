import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ResourceRequestStatus } from '~/types';
import {
  ProjectsFlatOverviewItemExpansionPanel,
  PanelTitle
} from '../../common';
import ProjectsExpansionPanelDetails from './ProjectsExpansionPanelDetails';

const useStyles = makeStyles(theme => {
  const makeBorder = color => `${theme.spacing(0.5)}px solid ${color}`;

  return {
    rejected: {
      borderLeft: makeBorder(theme.palette.resourceRequest.requestRejected.main)
    },
    proposed: {
      borderLeft: makeBorder(theme.palette.resourceRequest.proposed.main)
    },
    draft: {
      borderLeft: makeBorder(theme.palette.resourceRequest.draft.main)
    }
  };
});

const usePanelTitle = ({ key, count, pluralKey, singleKey }) =>
  useMemo(
    () => (
      <FormattedMessage
        id={
          count > 0
            ? `projectOverview.${pluralKey}`
            : `projectOverview.${singleKey}`
        }
        values={{
          requestType: (
            <strong>
              <FormattedMessage id={`projectOverview.${key}`} />
            </strong>
          )
        }}
      />
    ),
    [key, count, pluralKey, singleKey]
  );

export const ResourceRequestsSummary = ({ resourceRequestsCountSummary }) => {
  const classes = useStyles();

  const rejectedTitle = usePanelTitle({
    key: 'rejected',
    count: resourceRequestsCountSummary.rejected,
    pluralKey: 'requestsRejected',
    singleKey: 'requestRejected'
  });
  const proposedTitle = usePanelTitle({
    key: 'proposed',
    count: resourceRequestsCountSummary.proposed,
    pluralKey: 'requestsAwaitingApproval',
    singleKey: 'requestAwaitingApproval'
  });
  const draftTitle = usePanelTitle({
    key: 'draft',
    count: resourceRequestsCountSummary.draft,
    pluralKey: 'draftRequests',
    singleKey: 'draftRequest'
  });

  return (
    <>
      {resourceRequestsCountSummary.rejected > 0 && (
        <ProjectsFlatOverviewItemExpansionPanel
          counts={resourceRequestsCountSummary.rejected}
          leftBorderColorClass={classes.rejected}
          TitleComponent={PanelTitle}
          title={rejectedTitle}
        >
          <ProjectsExpansionPanelDetails
            requestType="rejected"
            resourceRequestStatus={ResourceRequestStatus.Rejected}
          />
        </ProjectsFlatOverviewItemExpansionPanel>
      )}
      {resourceRequestsCountSummary.proposed > 0 && (
        <ProjectsFlatOverviewItemExpansionPanel
          counts={resourceRequestsCountSummary.proposed}
          leftBorderColorClass={classes.proposed}
          TitleComponent={PanelTitle}
          title={proposedTitle}
        >
          <ProjectsExpansionPanelDetails
            requestType="proposed"
            resourceRequestStatus={ResourceRequestStatus.Tentative}
          />
        </ProjectsFlatOverviewItemExpansionPanel>
      )}
      {resourceRequestsCountSummary.draft > 0 && (
        <ProjectsFlatOverviewItemExpansionPanel
          counts={resourceRequestsCountSummary.draft}
          leftBorderColorClass={classes.draft}
          TitleComponent={PanelTitle}
          title={draftTitle}
        >
          <ProjectsExpansionPanelDetails
            requestType="draft"
            resourceRequestStatus={ResourceRequestStatus.Draft}
          />
        </ProjectsFlatOverviewItemExpansionPanel>
      )}
    </>
  );
};

ResourceRequestsSummary.propTypes = {
  resourceRequestsCountSummary: PropTypes.object
};

export default ResourceRequestsSummary;
