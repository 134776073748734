import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormattedMessage } from 'react-intl';
import AdjustWorkDaysHelp from './AdjustWorkDaysHelp';

const useStyles = makeStyles(theme => ({
  adjustWorkDays: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 0)
  },
  offsetMessage: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary
  },
  smartShiftCheck: {
    margin: theme.spacing(0, 0, 0, 2),
    '&:hover $helpIcon': {
      opacity: 1
    }
  },
  helpIcon: {
    opacity: 0.3,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    '&:hover': {
      opacity: 1
    }
  }
}));

const adjustWorkDays = (
  <>
    <FormattedMessage id="rescheduleProject.adjustWorkDays" />
    <AdjustWorkDaysHelp />
  </>
);

export const AdjustWorkDays = ({
  dateOffSet,
  offsetValue,
  setFieldValue,
  isAdjustWorkDaysChecked
}) => {
  const classes = useStyles();
  const handleAdjustWorkDaysChecked = useCallback(
    (_, checked) => {
      setFieldValue('isAdjustWorkDaysChecked', checked);
    },
    [setFieldValue]
  );

  const adjustWorkDaysCheckBox = useMemo(
    () => (
      <Checkbox
        checked={isAdjustWorkDaysChecked}
        onChange={handleAdjustWorkDaysChecked}
        name="isAdjustWorkDaysChecked"
      />
    ),
    [isAdjustWorkDaysChecked, handleAdjustWorkDaysChecked]
  );

  return (
    <div className={classes.adjustWorkDays}>
      <div className={classes.offsetMessage}>
        <FormattedMessage
          id={
            dateOffSet > 0
              ? 'rescheduleProject.daysLater'
              : 'rescheduleProject.earlierDays'
          }
          values={offsetValue}
        />
      </div>
      <FormControlLabel
        control={adjustWorkDaysCheckBox}
        label={adjustWorkDays}
        className={classes.smartShiftCheck}
      />
    </div>
  );
};

AdjustWorkDays.propTypes = {
  dateOffSet: PropTypes.number,
  offsetValue: PropTypes.object,
  setFieldValue: PropTypes.func,
  isAdjustWorkDaysChecked: PropTypes.bool
};

export default AdjustWorkDays;
