import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const RepliconAiIcon = React.forwardRef((props, ref) => (
  <Avatar {...props} ref={ref}>
    <SvgIcon>
      <>
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m23.577 8.9774a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m21.773 5.3638a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m18.786 2.6447a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m15.019 1.187a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m10.981 1.187a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m7.2139 2.6447a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m4.2273 5.3638a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m2.4234 8.9774a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
        />
        <path
          fill={
            props.statusColor ? props.statusColor : theme.palette.success.main
          }
          d="m0.95508 12.02a1 1 0 0 0-0.9043 1.0898c0.5717 6.1627 5.7601 10.891 11.949 10.891 6.1892 0 11.378-4.7279 11.949-10.891a1 1 0 0 0-0.9043-1.0898 1 1 0 0 0-1.0879 0.9043c-0.4779 5.1516-4.7833 9.0762-9.957 9.0762-5.1738 0-9.4791-3.9245-9.957-9.0762a1 1 0 0 0-1.0879-0.9043z"
        />
        <path d="m14.403 13.005c1.8091-0.37872 2.7803-1.631 2.7803-3.5192 0-2.2855-1.5835-3.6736-4.0326-3.6736h-5.6504v12h2.5151v-4.6857h1.6322l3.2818 4.6857h2.9386zm-4.3871-2.1113-4.19e-4 -2.2089-1.8949-0.62019h4.9312c1.0451 0 1.6165 0.60435 1.6165 1.4212 0 0.81681-0.57137 1.408-1.6165 1.408z" />
      </>
    </SvgIcon>
  </Avatar>
));

RepliconAiIcon.propTypes = {
  statusColor: PropTypes.string
};

export default RepliconAiIcon;
