import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from '~/modules/common/components/ListTable';
import { User, Hours, Delete } from './renderers';

const renders = {
  resource: User,
  allocatedHours: Hours,
  delete: Delete
};

export const buildColumns = ({
  errors,
  classes,
  editable,
  onRemove,
  isUpdating,
  onHoursBlur,
  onHoursChange,
  showRemoveResourceConfirmation
}) => ({
  resource: {
    field: 'resource',
    visible: true,
    value: <FormattedMessage id="taskResourceAssignments.resources" />,
    includesNavigationComponent: true
  },
  allocatedHours: {
    field: 'allocatedHours',
    align: 'right',
    visible: true,
    value: (
      <div className={classes.hoursColumn}>
        <FormattedMessage id="taskResourceAssignments.hours" />
      </div>
    ),
    errors,
    editable,
    isUpdating,
    onHoursBlur,
    onHoursChange
  },
  delete: {
    field: 'delete',
    visible: editable,
    value: '',
    className: classes.deleteColumn,
    onRemove,
    isUpdating,
    showRemoveResourceConfirmation
  }
});

export const useListColumns = ({
  errors,
  classes,
  editable,
  onRemove,
  isUpdating,
  onHoursBlur,
  onHoursChange,
  showRemoveResourceConfirmation
}) => {
  const columns = buildColumns({
    errors,
    classes,
    editable,
    onRemove,
    isUpdating,
    onHoursBlur,
    onHoursChange,
    showRemoveResourceConfirmation
  });

  return {
    headers: [
      {
        id: 'basic',
        title: null,
        columns: Object.keys(columns)
          .filter(c => columns[c] && columns[c].visible)
          .map(c => columns[c])
      }
    ],
    columns: useColumns({
      columns,
      renders
    })
  };
};
