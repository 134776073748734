import React from 'react';
import { DialogContent, DialogActions, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FormikTextField from '~/modules/common/components/FormikTextField';
import { CancelButton, LoadingButton } from '~/modules/common/components';
import SkillCategoryDropdown from './SkillCategoryDropdown';
import FormList from './AddSkillDialogFormList';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1, 2)
  }
}));

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const AddSkillDialogForm = ({ onClose }) => {
  const classes = useStyles();
  const {
    isSubmitting,
    handleSubmit,
    values: { category },
    errors
  } = useFormikContext();

  return (
    <form>
      <DialogContent className={classes.content}>
        <FormList>
          <SkillCategoryDropdown />
          <FormikTextField
            label="Name"
            inputProps={getAriaLabel('name')}
            name="name"
            fullWidth
            required
            variant="filled"
          />
          <FormikTextField
            label="Description"
            name="description"
            inputProps={getAriaLabel('description')}
            fullWidth
            variant="filled"
            multiline
          />
        </FormList>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <LoadingButton
          onClick={handleSubmit}
          color="primary"
          disabled={isSubmitting || Object.keys(errors).length > 0 || !category}
          isLoading={isSubmitting}
        >
          <FormattedMessage id="skillsDropdown.addSkill" />
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

AddSkillDialogForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AddSkillDialogForm;
