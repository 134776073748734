import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';

export const CLIENT_PROJECTS_QUERY = gql`
  query getProjects($page: Int!, $pagesize: Int!, $filter: ProjectFilter) {
    projects(page: $page, pagesize: $pagesize, projectFilter: $filter) {
      id
      displayText
    }
  }
`;

export const useClientProjects = ({ client: { id: clientId }, skip }) => {
  const { data, loading } = useQuery(CLIENT_PROJECTS_QUERY, {
    variables: {
      page: 1,
      pagesize: 100,
      columns: ['project', 'displayText'],
      filter: {
        clients: [clientId],
        executionPhase: [
          PROJECT_EXECUTION_PHASE.INITIATE,
          PROJECT_EXECUTION_PHASE.PLANNING,
          PROJECT_EXECUTION_PHASE.EXECUTION
        ]
      }
    },
    skip,
    fetchPolicy: 'cache-and-network'
  });

  const projects = get(data, 'projects', {});

  return {
    loading,
    clientProjects: projects
  };
};

export default useClientProjects;
