import { useCallback } from 'react';

export const MAX_DEPTH_LEVEL = 10;

const showMaxDepthError = ({
  intl,
  setMoveTaskError,
  openMoveTaskErrorDialog
}) => {
  setMoveTaskError(
    intl.formatMessage({ id: 'projectTasksPage.moveTaskMaxLevelError' })
  );
  openMoveTaskErrorDialog();
};

const showNameConflictError = ({
  intl,
  setMoveTaskError,
  openMoveTaskErrorDialog
}) => {
  setMoveTaskError(intl.formatMessage({ id: 'projectTasksPage.taskExists' }));
  openMoveTaskErrorDialog();
};

export const useValidateTaskMove = ({
  intl,
  setMoveTaskError,
  openMoveTaskErrorDialog
}) =>
  useCallback(
    gantt => (id, parent) => {
      const task = gantt.getTask(id);

      const currentParent = task.parent;

      // Trying to move the task in same hierarchy
      if (currentParent === parent) {
        const hasSiblingTasks =
          gantt.getTaskBy(t => t.parent === parent).length > 1;

        return hasSiblingTasks;
      }

      const siblingTasks = gantt.getTaskBy(t => t.parent === parent);

      const parentTask =
        typeof parent === 'string' ? gantt.getTask(parent) : undefined;

      const exceedsMaxDepth =
        parentTask && parentTask.$level === MAX_DEPTH_LEVEL;

      if (exceedsMaxDepth) {
        showMaxDepthError({
          intl,
          setMoveTaskError,
          openMoveTaskErrorDialog
        });

        return false;
      }

      const hasNameConflict = siblingTasks.some(
        sibling => sibling.text === task.text
      );

      if (hasNameConflict) {
        showNameConflictError({
          intl,
          setMoveTaskError,
          openMoveTaskErrorDialog
        });

        return false;
      }

      return true;
    },
    [intl, setMoveTaskError, openMoveTaskErrorDialog]
  );

export default useValidateTaskMove;
