import React, { useMemo } from 'react';
import AlternateTooltip from '~/modules/common/components/TaskStatusSection/Table/components/ChildInfoDialog/AlternateTooltip';
import { isNullOrUndefined } from '~/modules/common/util';
import { useAlternateTooltipStyles } from './styles';

const useAlternateTooltips = ({
  isClosed,
  isProjectVariant,
  estimatedCost,
  rolledUpCostSummary
}) => {
  const alternateExpenseRemainingClassOverride = useAlternateTooltipStyles();

  const {
    actualCostInProjectCurrency,
    expensesRemainingInProjectCurrency,
    totalEstimatedAtCompletionCostInProjectCurrency: totalEstimatedAtCompletionCost,
    totalEstimatedExpensesInProjectCurrency,
    totalExpensesInProjectCurrency,
    totalProjectTasksEstimatedAtCompletionCostInProjectCurrency: totalProjectTasksEstimatedAtCompletionCost
  } = rolledUpCostSummary || {};

  const hasAlternateExpenseRemainingValue =
    isProjectVariant &&
    (isClosed ||
      (isNullOrUndefined(totalEstimatedExpensesInProjectCurrency) &&
        !isNullOrUndefined(expensesRemainingInProjectCurrency)));

  const hasProjectActualsOrExpense =
    actualCostInProjectCurrency?.amount > 0 ||
    totalExpensesInProjectCurrency?.amount > 0;

  const hasAlternateEstimatedAtCompletionCostValue =
    !isClosed &&
    isProjectVariant &&
    !hasProjectActualsOrExpense &&
    !isNullOrUndefined(totalEstimatedAtCompletionCost) &&
    isNullOrUndefined(totalProjectTasksEstimatedAtCompletionCost);

  const hasAlternateWorkRemainingValue = isClosed && isProjectVariant;

  const workRemainingAlternateTooltipComponent = useMemo(
    () =>
      hasAlternateWorkRemainingValue ? (
        <AlternateTooltip
          classes={alternateExpenseRemainingClassOverride}
          messageId="projectDrawer.workRemainingForClosedProjectMessage"
        />
      ) : null,
    [alternateExpenseRemainingClassOverride, hasAlternateWorkRemainingValue]
  );

  const expenseRemainingAlternateTooltipComponent = useMemo(
    () =>
      hasAlternateExpenseRemainingValue ? (
        <AlternateTooltip
          classes={alternateExpenseRemainingClassOverride}
          messageId={
            isClosed
              ? 'projectDrawer.expenseRemainingForClosedProjectMessage'
              : 'projectDrawer.expenseRemainingFromProjectBudgetMessage'
          }
        />
      ) : null,
    [
      alternateExpenseRemainingClassOverride,
      hasAlternateExpenseRemainingValue,
      isClosed
    ]
  );

  const estimatedAtCompletionAlternateTooltipComponent = useMemo(
    () =>
      hasAlternateEstimatedAtCompletionCostValue ? (
        <AlternateTooltip
          messageId={
            estimatedCost
              ? 'projectDrawer.eacFromProjectEstimtesMessage'
              : 'projectDrawer.eacFromProjectBudgetMessage'
          }
        />
      ) : null,
    [estimatedCost, hasAlternateEstimatedAtCompletionCostValue]
  );

  return {
    hasAlternateExpenseRemainingValue,
    hasAlternateEstimatedAtCompletionCostValue,
    hasAlternateWorkRemainingValue,
    expenseRemainingAlternateTooltipComponent,
    estimatedAtCompletionAlternateTooltipComponent,
    workRemainingAlternateTooltipComponent
  };
};

export default useAlternateTooltips;
