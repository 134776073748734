import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { useUpdateResourceRequest } from '~/modules/resourcing/common/hooks';
import { useResourcingDragIndicators } from '~/modules/resourcing/hooks';
import { useGetResourceRequestFilter } from '~/modules/resourcing/common/hooks/useGetResourceRequestFilter';
import { patchResourceRequest } from '../../../enhancers/patchResourceRequest';

export const useResourceRequestDragIndicators = ({
  resourceRequest,
  project,
  chartStartDate,
  scale,
  isResourceActualModeEnabled
}) => {
  const { updateResourceRequest } = useUpdateResourceRequest();
  const apolloClient = useApolloClient();
  const filter = useGetResourceRequestFilter();

  const resourceRequestUpdateHandler = useCallback(
    ({ estimatedDate, entity }) => {
      patchResourceRequest({
        updateResourceRequest,
        project,
        request: {
          ...entity,
          startDate: mapRepliconDateToUtcObject(
            estimatedDate.startDate
          ).toISO(),
          endDate: mapRepliconDateToUtcObject(estimatedDate.endDate).toISO()
        },
        apolloClient,
        filter,
        isResourceActualModeEnabled
      });
    },
    [
      updateResourceRequest,
      project,
      apolloClient,
      filter,
      isResourceActualModeEnabled
    ]
  );

  const {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dates
  } = useResourcingDragIndicators({
    chartStartDate,
    scale,
    entity: resourceRequest,
    entityUpdateHandler: resourceRequestUpdateHandler
  });

  return { gestureBindEvents, dragDelta, currentResizeDirection, dates };
};

export default useResourceRequestDragIndicators;
