import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { BILLING_PLAN_FREQUENCY_ENUM } from '~/modules/projects/project/common/enums/billingPlanFrequencyLegacy';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 170
  },
  select: {
    paddingBottom: theme.spacing(0.75),
    fontSize: theme.typography.body2.fontSize,
    height: '1.2rem'
  },
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'inline-flex',
    '& .MuiFormControl-root .MuiFilledInput-root': {
      borderTopRightRadius: ({ isDaily }) => (isDaily ? '4px' : 0)
    }
  }
}));

const BillingPlanFrequencyDropdown = ({
  value,
  onChange,
  frequencies,
  label,
  hiddenLabel
}) => {
  const intl = useIntl();
  const options = useMemo(
    () =>
      (frequencies || Object.keys(BILLING_PLAN_FREQUENCY_ENUM)).map(fr => ({
        id: fr,
        displayText: intl.formatMessage({
          id: `billPlan.billPlanFrequency.${fr}`
        })
      })),
    [frequencies, intl]
  );

  const selectedValue = useMemo(() => options.find(x => x.id === value), [
    options,
    value
  ]);

  const classes = useStyles({ isDaily: selectedValue.id === 'Daily' });

  const dropdownClasses = useMemo(
    () => ({
      container: classes.container,
      select: classes.select
    }),
    [classes.container, classes.select]
  );

  return (
    <div className={classes.root}>
      <SimpleDropdown
        options={options}
        onChange={onChange}
        noneOption={false}
        value={selectedValue}
        classes={dropdownClasses}
        disableSort
        variant="filled"
        hiddenLabel={hiddenLabel}
        label={label}
      />
    </div>
  );
};

BillingPlanFrequencyDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  frequencies: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  hiddenLabel: PropTypes.bool,
  label: PropTypes.string
};

export default BillingPlanFrequencyDropdown;
