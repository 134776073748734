import { DateTime } from 'luxon';
import {
  FILENAME_URI,
  FILESIZE_URI,
  UPLOADED_ON_URI,
  FILE_SOURCE_URI,
  LINK_SOURCE_URI
} from './uris';
import { makeCoreServiceRequest } from './coreServicesClient';
import { makeKeyValuePair } from './attachmentUtil';

export const buildUploadData = ({
  attachmentUri,
  attachToUri,
  base64Content,
  mimeType,
  fileName,
  fileSize,
  uploadedOn,
  customMetadataFn
}) => ({
  correlatedObjectUri: attachmentUri,
  base64Content,
  mimeType,
  storagePolicyUri: 'urn:replicon:binary-object-storage-policy:store-forever',
  retrievalCacheControlUri:
    'urn:replicon:binary-object-retrieval-cache-control:no-cache',
  keyValues: [
    makeKeyValuePair(FILENAME_URI, fileName),
    makeKeyValuePair(FILESIZE_URI, fileSize),
    makeKeyValuePair(UPLOADED_ON_URI, uploadedOn),
    ...(customMetadataFn ? customMetadataFn({ attachToUri }) : [])
  ]
});

const bulkUploadFiles = async ({
  attachToUri,
  servicesUrl,
  files,
  uploadedOn,
  customMetadataFn
}) => {
  const [err, result] = await makeCoreServiceRequest({
    servicesUrl,
    service: 'BinaryObjectService1',
    method: 'BulkPutBinaryObject',
    data: {
      binaryObjects: files.map(file =>
        buildUploadData({
          attachmentUri: file.attachmentUri,
          base64Content: file.base64Content,
          mimeType: file.mimeType,
          fileName: file.fileName,
          fileSize: file.fileSize,
          uploadedOn,
          attachToUri,
          customMetadataFn
        })
      )
    }
  });

  return [err, result && result.map(r => r.binaryObject.uri)];
};

export const onLoadFile = ({
  servicesUrl,
  files,
  onDone,
  setError,
  attachToUri,
  linkAttachmentsToObject,
  customMetadataFn,
  _bulkUploadFiles = bulkUploadFiles,
  _DateTime = DateTime
}) => async () => {
  const uploadFiles = files.map(f => ({
    fileName: f.filename,
    fileSize: f.size,
    attachmentUri: attachToUri,
    mimeType: f.mimeType,
    base64Content: f.base64Content
  }));

  const uploadedOn = _DateTime.local().toString();

  const [uploadErr, uploadUris] = await _bulkUploadFiles({
    attachToUri,
    servicesUrl,
    files: uploadFiles,
    uploadedOn,
    customMetadataFn
  });

  if (uploadErr) {
    setError(`An error occured while trying to upload files.`);

    return;
  }

  const attachments = uploadUris.map(uploadUri => ({
    uploadUri,
    type: FILE_SOURCE_URI
  }));

  await linkAttachmentsToObject({
    attachments
  });

  onDone();
};

export const onLoadLink = ({
  file,
  onDone,
  linkAttachmentsToObject
}) => async () => {
  const uploadUri = file.linkUri;

  await linkAttachmentsToObject({
    attachments: [
      {
        uploadUri,
        type: LINK_SOURCE_URI,
        linkName: file.linkName
      }
    ]
  });

  onDone();
};

export const uploadFile = ({
  servicesUrl,
  attachToUri,
  onDone,
  setError,
  linkAttachmentsToObject,
  customMetadataFn
}) => files =>
  Array.isArray(files) && files.every(f => f.source === FILE_SOURCE_URI)
    ? onLoadFile({
        servicesUrl,
        files,
        attachToUri,
        onDone,
        setError,
        linkAttachmentsToObject,
        customMetadataFn
      })
    : onLoadLink({
        file: files[0],
        onDone,
        linkAttachmentsToObject
      });
