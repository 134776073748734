import { mixed } from 'yup';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';

export const createDueDateValidator = intl => {
  let validator = mixed().required(
    intl.formatMessage({ id: 'billDetails.validations.dueDateRequired' })
  );

  validator = validator.test(
    'due-date-on-or-after-issue-date',
    intl.formatMessage({
      id: 'billDetails.validations.dueDateMinLimit'
    }),
    // eslint-disable-next-line prefer-arrow-callback
    // eslint-disable-next-line func-names
    function(value) {
      const issueDate = this.parent && this.parent.issueDate;

      if (value && issueDate) {
        const min = mapRepliconDateToUtcObject(issueDate);
        const val = mapRepliconDateToUtcObject(value);

        return val >= min;
      }

      return true;
    }
  );

  return validator;
};

export const createIssueDateValidator = intl => {
  let validator = mixed().required(
    intl.formatMessage({ id: 'billDetails.validations.issueDateRequired' })
  );

  validator = validator.test(
    'issue-date-on-of-before-due-date',
    intl.formatMessage({
      id: 'billDetails.validations.issueDateMaxLimit'
    }),
    // eslint-disable-next-line prefer-arrow-callback
    // eslint-disable-next-line func-names
    function(value) {
      const dueDate = this.parent && this.parent.dueDate;

      if (value && dueDate) {
        const max = mapRepliconDateToUtcObject(dueDate);
        const val = mapRepliconDateToUtcObject(value);

        return val <= max;
      }

      return true;
    }
  );

  return validator;
};

export const createPeriodValidator = intl => {
  let validator = mixed().required();

  validator = validator.test(
    'absense-of-date',
    intl.formatMessage({
      id: 'billDetails.validations.periodValue'
    }),
    period => {
      const startDate = period && period.startDate;
      const endDate = period && period.endDate;
      const startDateInputText = document.getElementById('start_date_id');
      const endDateInputText = document.getElementById('end_date_id');

      const periodStartDate =
        startDate && mapRepliconDateToUtcObject(startDate);
      const periodEndDate = endDate && mapRepliconDateToUtcObject(endDate);
      const isValidDateRange =
        (periodStartDate?.isValid && periodEndDate?.isValid) ||
        (startDate === null &&
          endDate === null &&
          startDateInputText === null &&
          endDateInputText === null);

      if (
        isValidDateRange ||
        (startDateInputText?.value === '' && endDateInputText?.value === '')
      ) {
        return true;
      }

      return false;
    }
  );

  return validator;
};
