import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { buildResourceAllocation } from '~/modules/resourcing/hooks/useCreateResourceAllocationHandler';
import { ResourceAllocationStatus } from '~/types';
import { updateCreateAllocationCache } from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks/useFulfilledResourceAllocationHandler';
import { useFulfilledResourceAllocationHandlersWithoutResourceRequest } from '~/modules/resourcing/common/hooks';
import useQuickAllocationContext from './useQuickAllocationContext';

export const updateCache = ({
  resourceUsersCurrentPage,
  dateRange,
  userSort: [userSort],
  periodResolution,
  scale,
  me,
  filter,
  projectStatusFilterOption,
  handleAddResourceAllocation,
  onPopupMenuClose,
  setSnackBarState,
  formatMessage
}) => (proxy, mutationResponse) => {
  const {
    data: {
      createFulfilledResourceAllocationWithoutResourceRequest: {
        resourceAllocation
      }
    }
  } = mutationResponse;

  onPopupMenuClose();
  setSnackBarState({
    open: true,
    message: formatMessage(
      { id: 'addallocationSnackBar.addallocation' },
      {
        username: resourceAllocation.user.user.displayText,
        projectname: resourceAllocation.project.displayText,
        role:
          resourceAllocation.role?.displayText ||
          formatMessage({
            id: 'addallocationSnackBar.noRole'
          })
      }
    )
  });
  handleAddResourceAllocation({ allocation: resourceAllocation });

  updateCreateAllocationCache({
    resourceUsersCurrentPage,
    dateRange,
    userSort: [userSort],
    periodResolution,
    scale,
    me,
    filter,
    projectStatusFilterOption
  })(proxy, mutationResponse);
};

export const useCreateResourceAllocation = ({
  me,
  user,
  handleAddResourceAllocation,
  onPopupMenuClose,
  setSnackBarState
}) => {
  const {
    scale,
    projectStatusFilterOption,
    resourceUsersCurrentPage,
    periodResolution,
    dateRange,
    filter,
    sort: userSort
  } = useQuickAllocationContext();
  const { formatMessage } = useIntl();
  const {
    onCreateResourceAllocation,
    loading
  } = useFulfilledResourceAllocationHandlersWithoutResourceRequest({
    updateCreateCache: updateCache({
      resourceUsersCurrentPage,
      dateRange,
      userSort: [userSort],
      periodResolution,
      scale,
      me,
      filter,
      projectStatusFilterOption,
      handleAddResourceAllocation,
      onPopupMenuClose,
      setSnackBarState,
      formatMessage
    })
  });

  const createResourceAllocation = useCallback(
    ({ allocation, overrideScheduleRules }) => {
      const alloc = buildResourceAllocation({
        user,
        scheduleRules: overrideScheduleRules,
        projectUri: allocation.project.id,
        roleUri: allocation.roleUri,
        me,
        allocationStatus: ResourceAllocationStatus.Committed
      });

      onCreateResourceAllocation({ allocation: alloc });
    },
    [me, onCreateResourceAllocation, user]
  );

  return {
    createResourceAllocation,
    loading
  };
};

export default useCreateResourceAllocation;
