import { withCustomFetchCriteriaProvider } from '../enhancers';
import { syncStatusTag } from './tag';
import SyncStatusFacetDetails from './SyncStatusFacetDetails';
import { useSearchableSyncStatusTags } from './hooks/useSyncStatusTags';

const makeCriteriaProvider = (tag, context) =>
  withCustomFetchCriteriaProvider(tag, context, useSearchableSyncStatusTags);

export default {
  tag: syncStatusTag,
  makeCriteriaProvider,
  DetailsControl: SyncStatusFacetDetails
};
