import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { isoStringToFormattedISOString } from '~/modules/common/dates/convert';
import { Decimal } from '~/modules/common/components';
import FieldLabel from './FieldLabel';

const useStyles = makeStyles(theme => ({
  actionButton: {
    margin: theme.spacing(-3.5, -3.5)
  },
  pathIcon: {
    margin: theme.spacing(0, 0.5),
    fontSize: theme.typography.body2.fontSize,
    opacity: 0.87
  },
  action: {
    alignContent: 'center'
  },
  banner: {
    backgroundColor: '#e5f6fd'
  }
}));

const useBannerStyles = makeStyles(theme => ({
  message: {
    width: '100%'
  }
}));

const dateFormatString = 'LLL dd, yyyy';

const EditorPopoverSummaryHeader = ({
  resourceAllocation,
  otherTaskAllocationsSummary,
  projectSlug
}) => {
  const bannerClasses = useBannerStyles();
  const {
    totalHours: resourceAllocationHours,
    startDate,
    endDate,
    user: { user }
  } = resourceAllocation;
  const {
    totalHours: otherTaskAllocationsSummaryHours
  } = otherTaskAllocationsSummary;

  const availableHours =
    resourceAllocationHours - (otherTaskAllocationsSummaryHours || 0);

  const classes = useStyles();

  const dateRangeLabelValue = useMemo(
    () => ({
      startDate: isoStringToFormattedISOString(startDate, dateFormatString),
      endDate: isoStringToFormattedISOString(endDate, dateFormatString)
    }),
    [endDate, startDate]
  );

  const hoursLabelValue = useMemo(
    () => ({
      allocationHours: <Decimal value={resourceAllocationHours} />,
      availableHours: <Decimal value={availableHours} />
    }),
    [availableHours, resourceAllocationHours]
  );

  const history = useHistory();
  const onViewDetailsClick = useCallback(() => {
    history.push({
      pathname: `/projects/${projectSlug}/resourcingplan`,
      state: {
        userUri: user.id,
        userDisplayText: user.displayText
      }
    });
  }, [history, projectSlug, user]);

  const endIcon = useMemo(
    () => <ArrowForwardIcon fontSize="small" className={classes.pathIcon} />,
    [classes.pathIcon]
  );

  return (
    <Alert
      severity="info"
      className={classes.banner}
      classes={bannerClasses}
      icon={false}
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <FieldLabel
            messageId="taskAllocationEditor.projectAllocationDateRange"
            messageValues={dateRangeLabelValue}
            color="textSecondary"
          />
          <FieldLabel
            variant="body2"
            messageId="taskAllocationEditor.projectAllocationHours"
            messageValues={hoursLabelValue}
          />
        </Grid>
        <Grid item xs={2} className={classes.action}>
          <Button
            className={classes.actionButton}
            size="small"
            color="primary"
            variant="text"
            onClick={onViewDetailsClick}
            endIcon={endIcon}
          >
            <FormattedMessage id="taskAllocationEditor.details" />
          </Button>
        </Grid>
      </Grid>
    </Alert>
  );
};

EditorPopoverSummaryHeader.propTypes = {
  projectSlug: PropTypes.string.isRequired,
  resourceAllocation: PropTypes.object,
  otherTaskAllocationsSummary: PropTypes.object
};

export default EditorPopoverSummaryHeader;
