import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RELEASE_TASK_OWNER = gql`
  mutation ReleaseTaskOwners2($input: ReleaseTaskOwnersInput!) {
    releaseTaskOwners2(input: $input) {
      result
    }
  }
`;

export const useReleaseTaskOwners2 = () => {
  const [releaseTaskOwners] = useMutation(RELEASE_TASK_OWNER);

  return {
    releaseTaskOwners2: ({ entries, projectId }) => {
      const taskIds = [
        ...new Set(
          entries.flatMap(
            ({ impactedTaskSummary }) => impactedTaskSummary.taskUris
          )
        )
      ];

      if (taskIds.length === 0) return Promise.resolve();

      return releaseTaskOwners({
        variables: {
          input: {
            taskIds,
            projectId
          }
        }
      });
    }
  };
};

export default useReleaseTaskOwners2;
