import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl, FormattedMessage } from 'react-intl';
import { SkillIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';

const SkillsIndicator = ({ value }) => {
  const classes = useStyles();
  const intl = useIntl();
  const values = useMemo(
    () => ({
      count: value.length
    }),
    [value.length]
  );

  if (value.length === 0) return null;

  return (
    <span
      className={classes.root}
      aria-label={intl.formatMessage({ id: 'resourceRequestCard.skills' })}
    >
      <Tooltip title={intl.formatMessage({ id: 'resourceRequestCard.skills' })}>
        <SkillIcon className={classes.icon} />
      </Tooltip>
      <FormattedMessage
        id={
          value.length > 1
            ? 'resourceRequestCard.skillRequirements'
            : 'resourceRequestCard.skillRequirement'
        }
        values={values}
      />
    </span>
  );
};

SkillsIndicator.propTypes = {
  value: PropTypes.array
};

export default SkillsIndicator;
