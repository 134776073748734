import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DatePickerButton from './DatePickerButton';

const useStyles = makeStyles(theme => ({
  text: {
    ...theme.typography.h6,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize
    }
  },
  range: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const DateRangePicker = ({
  classes: classesOverrides,
  dateRange,
  onStartDateChange,
  onEndDateChange
}) => {
  const classes = useStyles({ classes: classesOverrides });

  return (
    <div className={classes.range}>
      <DatePickerButton
        date={dateRange.startDate}
        id="Start_Date_Picker_Menu"
        onChange={onStartDateChange}
      />
      <span className={classes.text}>&nbsp;&mdash;&nbsp;</span>
      <DatePickerButton
        date={dateRange.endDate}
        id="End_Date_Picker_Menu"
        onChange={onEndDateChange}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  classes: PropTypes.object,
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }).isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired
};

export default DateRangePicker;
