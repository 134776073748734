import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { OVERRIDE_DIMENSION_PROPS } from './enum';

export const GET_PORJECT_RATE_TABLE_DIMENSIONS = gql`
  query getProjectRateTableDimensions($projectId: String!) {
    project(projectId: $projectId) {
      id
      rateTableDimension {
        dimensions {
          id
          displayText
          slug
          objectType
        }
        requiredDimensions {
          id
          displayText
          slug
          objectType
        }
      }
    }
  }
`;

export const useRateTableDimension = ({ projectId }) => {
  const intl = useIntl();
  const { data, loading } = useQuery(GET_PORJECT_RATE_TABLE_DIMENSIONS, {
    variables: {
      projectId
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const { dimensions, requiredDimensions } =
    data?.project?.rateTableDimension || {};

  return {
    loading,
    projectDimensions: (dimensions || []).map(d => ({
      ...d,
      ...(OVERRIDE_DIMENSION_PROPS[d.slug]
        ? OVERRIDE_DIMENSION_PROPS[d.slug](intl)
        : {})
    })),
    requiredDimensions
  };
};
