import { useCallback } from 'react';

export default ({
  me,
  issueBill,
  onMenuClick,
  record,
  updateTransactionRows
}) => {
  const handleIssueSyncClick = useCallback(async () => {
    await issueBill(true);
    if (updateTransactionRows) {
      updateTransactionRows();
    }
  }, [issueBill, updateTransactionRows]);

  const handleIssueEmailClick = useCallback(async () => {
    await issueBill(false);

    onMenuClick();
  }, [issueBill, onMenuClick]);

  const handleIssuePrintClick = useCallback(async () => {
    await issueBill(false);
    if (updateTransactionRows) {
      updateTransactionRows();
    }
    window.open(
      `${me.uiRootPath}invoicing2/invoice-snapshot/${record.id}`,
      '_blank'
    );
  }, [issueBill, me.uiRootPath, record.id, updateTransactionRows]);

  return {
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick
  };
};
