import { gql } from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/client';
import get from 'lodash.get';
import { PAGE_SIZE } from '~/modules/common/const';

export const PROJECT_MANAGER_DROPDOWN_QUERY = gql`
  query ProjectManagerOptions($page: Int, $pageSize: Int, $searchTerm: String) {
    projectManagersFilteredBySearch(
      page: $page
      pageSize: $pageSize
      searchTerm: $searchTerm
    ) {
      id
      displayText
    }
  }
`;

export const useProjectManagers = ({ searchTerm = '' }) => {
  const { loading: isLoading, data } = useQuery(
    PROJECT_MANAGER_DROPDOWN_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        page: 1,
        pageSize: PAGE_SIZE + 1,
        searchTerm
      },
      context: {
        debounceKey: 'project-manager-search',
        debounceTimeout: 250
      }
    }
  );

  const options = get(data, 'projectManagersFilteredBySearch') || [];

  return {
    isLoading,
    options: mapResult(options).slice(0, PAGE_SIZE),
    hasMore: options.length > PAGE_SIZE
  };
};

const searchProjectManagers = ({ apolloClient }) => async searchTerm => {
  const {
    data: { projectManagersFilteredBySearch: items = [] } = {}
  } = await apolloClient.query({
    query: PROJECT_MANAGER_DROPDOWN_QUERY,
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
      searchTerm
    },
    context: {
      debounceKey: 'project-manager-search',
      debounceTimeout: 250
    }
  });

  return mapResult(items);
};

export const useSearchableProjectManager = () => {
  const apolloClient = useApolloClient();

  return {
    searchProjectManagers: searchProjectManagers({
      apolloClient
    })
  };
};

const mapResult = options =>
  (options || []).map(option => ({
    ...option,
    value: option.id,
    label: option.displayText,
    key: option.displayText
  }));

export default useProjectManagers;
