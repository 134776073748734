/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogTitle } from '@material-ui/core';
import Receipt from '@material-ui/icons/ReceiptSharp';
import { Formik } from 'formik';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import ModuleDialogTitle from '~/modules/common/components/ModuleDialogTitle';
import AddCertificateDialogContent from './AddCertificateDialogContent';
import { usePutCertificate, useValidationSchema } from './hooks';

const addCertificateRole = {
  role: 'presentation'
};

export const AddCertificateDialog = ({ open, userUri, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const isMobile = useIsBreakpointDown('sm');
  const validationSchema = useValidationSchema();
  const { onPut } = usePutCertificate({
    userUri,
    onCancel,
    setSubmitError,
    setIsSubmitting
  });

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      open={open}
      onClose={onCancel}
      TransitionProps={addCertificateRole}
    >
      <DialogTitle>
        <ModuleDialogTitle
          title={
            <FormattedMessage id="resourceCertificatesAndLicensesCard.addTitle" />
          }
          icon={<Receipt />}
        />
      </DialogTitle>
      <Formik
        initialValues={{}}
        onSubmit={onPut}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <AddCertificateDialogContent
          onCancel={onCancel}
          submitError={submitError}
          isSubmitting={isSubmitting}
        />
      </Formik>
    </Dialog>
  );
};

AddCertificateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userUri: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddCertificateDialog;
