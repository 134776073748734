import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { usePrecision } from '~/modules/common/components/Decimal/usePrecision';
import {
  DiffValue,
  DIFF_VALUE_TYPE,
  Decimal,
  AbbreviatedDecimal,
  NoValue
} from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  diff: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  variance: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0.5)
  },
  icon: {
    fontSize: '0.8rem'
  }
}));

const hasValue = amount => amount !== null && amount !== undefined;

const MoneyVariance = ({ field, record, column }) => {
  const showAbbreviatedValue = column?.showAbbreviatedValue;
  const overrideClasses = column?.classes;
  const Component = showAbbreviatedValue ? AbbreviatedDecimal : Decimal;

  const { amount, currency } = record[field] || {};
  const { precise: precisedValue } = usePrecision({
    value: amount
  });

  const classes = useStyles({ classes: overrideClasses });
  const precisedValueNumber = parseFloat(precisedValue);

  const varianceType =
    precisedValueNumber < 0
      ? DIFF_VALUE_TYPE.NEGATIVE
      : precisedValueNumber > 0
      ? DIFF_VALUE_TYPE.POSITIVE
      : DIFF_VALUE_TYPE.NEUTRAL;

  return hasValue(amount) ? (
    <DiffValue className={classes.diff} diffValueType={varianceType}>
      {varianceType === DIFF_VALUE_TYPE.POSITIVE ? (
        <RemoveIcon className={classes.icon} />
      ) : null}
      {varianceType === DIFF_VALUE_TYPE.NEGATIVE ? (
        <AddIcon className={classes.icon} />
      ) : null}
      <Component
        className={classes.variance}
        value={Math.abs(amount)}
        prefix={`${currency?.symbol || currency?.displayText} `}
        precision={2}
      />
    </DiffValue>
  ) : (
    <NoValue />
  );
};

MoneyVariance.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object,
  column: PropTypes.object
};

export default MoneyVariance;
