import {
  dateToPosition,
  positionToDate
} from '~/modules/common/charts/timeline/calculations';
import { RESIZE_DIRECTION_ENUM } from '~/modules/resourcing/common/enums';

export const getResizeDirection = node => {
  let tempNode = node;

  while (tempNode) {
    if (tempNode.getAttribute) {
      const direction = tempNode.getAttribute('resize');

      // eslint-disable-next-line max-depth
      if (
        direction === RESIZE_DIRECTION_ENUM.START ||
        direction === RESIZE_DIRECTION_ENUM.END
      )
        return direction;
    }
    if (tempNode.nodeName && tempNode.nodeName.toUpperCase() === 'BODY') break;
    tempNode = tempNode.parentNode;
  }

  return null;
};

export const decreaseMagnitude = (number, amount) => {
  if (number > 0) return Math.max(0, number - amount);
  if (number < 0) return Math.min(0, number + amount);

  return 0;
};

// Nudge floating points over a bit to help avoid 0.0000001 and 0.99999999 issues.
const nudgeFloat = delta => delta + 0.00001;

export const getMoveChanges = ({
  chartStartDate,
  scale,
  startDate,
  endDate,
  sizeDelta
}) => {
  const endPosition = dateToPosition({
    chartStartDate,
    scale,
    date: endDate
  });

  const delta = sizeDelta;

  const startPosition = dateToPosition({
    chartStartDate,
    scale,
    date: startDate
  });

  const newStartDate = positionToDate({
    chartStartDate,
    scale,
    position: startPosition + nudgeFloat(delta)
  });

  const newEndDate = positionToDate({
    chartStartDate,
    scale,
    position: endPosition + nudgeFloat(delta)
  });

  const dateAlignedDelta =
    dateToPosition({
      chartStartDate,
      scale,
      date: newStartDate
    }) - startPosition;

  return {
    startDate: newStartDate,
    endDate: newEndDate,
    moveDelta: delta,
    fudge: dateAlignedDelta - delta
  };
};

const getResizeStartChanges = ({
  chartStartDate,
  scale,
  startDate,
  endDate,
  sizeDelta
}) => {
  const startPosition = dateToPosition({
    chartStartDate,
    date: startDate,
    scale
  });

  const start = positionToDate({
    chartStartDate,
    scale,
    position: startPosition + sizeDelta
  });

  if (start >= endDate) {
    const delta =
      dateToPosition({
        chartStartDate,
        date: endDate,
        scale
      }) - startPosition;

    return {
      startDate: endDate,
      endDate,
      sizeDelta: -delta,
      moveDelta: delta
    };
  }

  const fudge =
    dateToPosition({ chartStartDate, scale, date: start }) -
    dateToPosition({
      chartStartDate,
      scale,
      date: startDate
    }) -
    sizeDelta;

  return {
    startDate: start,
    endDate,
    sizeDelta: -sizeDelta,
    moveDelta: sizeDelta,
    fudge
  };
};

const getResizeEndChanges = ({
  chartStartDate,
  scale,
  startDate,
  endDate,
  sizeDelta
}) => {
  const endPosition = dateToPosition({
    chartStartDate,
    scale,
    date: endDate
  });

  const end = positionToDate({
    chartStartDate,
    scale,
    position: endPosition + sizeDelta
  });

  if (end <= startDate) {
    return {
      startDate,
      endDate: startDate,
      sizeDelta:
        dateToPosition({ chartStartDate, scale, date: startDate }) - endPosition
    };
  }

  return {
    startDate,
    endDate: end,
    sizeDelta
  };
};

export const calculateDeltaDates = ({
  chartStartDate,
  scale,
  startDate,
  endDate,
  isActive,
  resizeDirection,
  sizeDelta
}) => {
  const base = {
    startDate,
    endDate,
    moveDelta: 0,
    sizeDelta: 0
  };

  if (!isActive || !sizeDelta) return base;

  if (resizeDirection === RESIZE_DIRECTION_ENUM.START) {
    return {
      ...base,
      ...getResizeStartChanges({
        chartStartDate,
        scale,
        startDate,
        endDate,
        sizeDelta
      })
    };
  }

  if (resizeDirection === RESIZE_DIRECTION_ENUM.END) {
    return {
      ...base,
      ...getResizeEndChanges({
        chartStartDate,
        scale,
        endDate,
        startDate,
        sizeDelta
      })
    };
  }

  return {
    ...base,
    ...getMoveChanges({
      chartStartDate,
      scale,
      startDate,
      endDate,
      sizeDelta
    })
  };
};
