import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(0.75, 2),
    position: 'sticky',
    left: 0,
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius
  },
  icon: { display: 'flex' },
  error: { color: '#611A15', backgroundColor: '#FDECEA' },
  warning: { color: '#663C00', backgroundColor: '#FFF4E5' },
  info: { color: '#0D3C61', backgroundColor: '#E8F4FD' },
  success: { color: '#1E4620', backgroundColor: '#EDF7ED' },
  errorIcon: { color: '#f44336' },
  warningIcon: { color: '#ff9800' },
  infoIcon: { color: '#2196f3' },
  successIcon: { color: '#4caf50' }
}));

const Alert = ({ icon, message, severity = 'info', classes }) => {
  const alertClasses = useStyles({ classes });
  const alertClassName = classNames(alertClasses.root, {
    [alertClasses.error]: severity === 'error',
    [alertClasses.warning]: severity === 'warning',
    [alertClasses.info]: severity === 'info',
    [alertClasses.success]: severity === 'success'
  });

  const iconClassName = classNames(alertClasses.icon, {
    [alertClasses.errorIcon]: severity === 'error',
    [alertClasses.warningIcon]: severity === 'warning',
    [alertClasses.infoIcon]: severity === 'info',
    [alertClasses.successIcon]: severity === 'success'
  });

  return (
    <div className={alertClassName} role="alert">
      {icon ? <div className={iconClassName}>{icon}</div> : null}
      {message}
    </div>
  );
};

Alert.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.object,
  severity: PropTypes.string
};

export default Alert;
