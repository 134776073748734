import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  makeStyles
} from '@material-ui/core';
import Settings from './Settings';
import useSettingsState from './useSettingsState';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(1)
  },
  container: {
    overflow: 'visible',
    padding: theme.spacing(2)
  },
  title: {
    padding: theme.spacing(2, 2, 0, 2)
  }
}));

const settingsDialogRole = {
  role: 'presentation'
};

const SettingsDialog = ({
  intl,
  open,
  settings,
  onUpdate,
  onClose,
  disabled = false
}) => {
  const classes = useStyles();

  const { rows, setRows, columns, setColumns } = useSettingsState(settings);

  const cancelAndClose = useCallback(() => {
    onClose();
    setRows(settings.rows.values);
    setColumns(settings.columns.values);
  }, [settings, setRows, setColumns, onClose]);

  const updateAndClose = useCallback(() => {
    onUpdate({
      rows: {
        ...settings.rows,
        values: rows
      },
      columns: {
        ...settings.columns,
        values: columns
      }
    });
    onClose();
  }, [settings, rows, columns, onUpdate, onClose]);

  return (
    <Dialog
      open={open}
      onClose={cancelAndClose}
      maxWidth="md"
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={settingsDialogRole}
    >
      <DialogTitle
        className={classes.title}
        id="project-grid-settings-dialog-title"
      >
        <FormattedMessage id="columnSettings.tableSettings" />
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Settings
          type="list"
          settings={settings}
          columnsLabel={intl.formatMessage({
            id: 'timeAndExpenseTab.setting.columns'
          })}
          columns={columns}
          setColumns={setColumns}
          rowsLabel={intl.formatMessage({
            id: 'timeAndExpenseTab.setting.rows'
          })}
          rows={rows}
          setRows={setRows}
          disabled={disabled}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={cancelAndClose}
          data-qe-id="TimeAndExpenseTab_Settings_Cancel"
        >
          {intl.formatMessage({ id: 'timeAndExpenseTab.setting.cancel' })}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          onClick={updateAndClose}
          data-qe-id="TimeAndExpenseTab_Settings_Save"
        >
          {intl.formatMessage({ id: 'timeAndExpenseTab.setting.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SettingsDialog.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool,
  settings: PropTypes.object,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool
};

export default SettingsDialog;
