import { IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { isNullOrUndefined } from '~/modules/common/util';
import NoValue from '../../../NoValue';
import NumberInput from '../../../NumberInput';
import FacetDetailField from '../../FacetDetailField';
import { useScoreTotalHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  numberField: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: theme.spacing(16)
  },
  divider: {
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1)
  },
  clearButton: {
    flexGrow: 0,
    flexShrink: 0,
    margin: theme.spacing(-1.5, -1.5, -1.5, 1)
  }
}));

export const ScoreTotalFacet = ({ className, selected, setSelected }) => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: 'search.totalScore' });
  const classes = useStyles();
  const { minValue, maxValue } =
    selected && selected.length > 0
      ? selected[0]
      : { key: '', minValue: '', maxValue: '' };
  const {
    onMinChangeHandler,
    onMaxChangeHandler,
    onMinBlurHandler,
    onMaxBlurHandler,
    onClearHandler
  } = useScoreTotalHandlers({ setSelected, minValue, maxValue });

  return (
    <FacetDetailField label={label} className={className}>
      <div className={classes.container}>
        <NumberInput
          className={classes.numberField}
          value={minValue}
          placeholder={formatMessage({ id: 'search.totalMinimum' })}
          onValueChange={onMinChangeHandler}
          onBlur={onMinBlurHandler}
          variant="standard"
          decimals={2}
          allowNegative
          size="small"
          data-qe-id="TotalMin"
          fixedDecimalScale={false}
        />
        <span className={classes.divider}>
          <NoValue />
        </span>
        <NumberInput
          className={classes.numberField}
          value={maxValue}
          placeholder={formatMessage({ id: 'search.totalMaximum' })}
          onValueChange={onMaxChangeHandler}
          onBlur={onMaxBlurHandler}
          variant="standard"
          decimals={2}
          allowNegative
          size="small"
          data-qe-id="TotalMax"
          fixedDecimalScale={false}
        />

        {(minValue !== '' && !isNullOrUndefined(minValue)) ||
        (maxValue !== '' && !isNullOrUndefined(maxValue)) ? (
          <IconButton className={classes.clearButton} onClick={onClearHandler}>
            <ClearIcon />
          </IconButton>
        ) : null}
      </div>
    </FacetDetailField>
  );
};

ScoreTotalFacet.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ScoreTotalFacet;
