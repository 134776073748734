import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useProjectsContext } from '../../ProjectsContext';
import { LIST, TIMELINE, OVERVIEW } from '../../projectListTabs';
import ProjectList from './ProjectList';
import ProjectTimeline from './ProjectTimeline';
import ProjectsOverview from './ProjectsOverview';

export const ProjectListPageTabContents = ({ onRowSelectionChange }) => {
  const { view } = useProjectsContext();

  useEffect(() => {
    if (view === OVERVIEW) {
      onRowSelectionChange([]);
    }
  }, [view, onRowSelectionChange]);

  return (
    <>
      {view === LIST && (
        <ProjectList onRowSelectionChange={onRowSelectionChange} />
      )}
      {view === TIMELINE && (
        <ProjectTimeline onRowSelectionChange={onRowSelectionChange} />
      )}
      {view === OVERVIEW && <ProjectsOverview />}
    </>
  );
};

ProjectListPageTabContents.propTypes = {
  onRowSelectionChange: PropTypes.func
};

export default ProjectListPageTabContents;
