import React from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import BillingCard from '~/modules/common/components/BillingCard';
import useClientDefaultData from '~/modules/billing-invoicing/common/hooks/useClientDefaultData';
import useFormState from './hooks/useFormState';

const ProjectBillingCard = ({ editable, isLoading, projectDetails }) => {
  const { id, client, projectBillSettings } = projectDetails;
  const { clientDefaultData } = useClientDefaultData({
    client
  });

  const showConfirmationDialogBeforeSave =
    clientDefaultData && !projectBillSettings?.billLineItemsBy;

  const { onSubmit, initialValues, validationSchema } = useFormState({
    id,
    projectBillSettings,
    clientDefaultData,
    showConfirmationDialogBeforeSave
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <BillingCard editable={editable} isLoading={isLoading} />
    </Formik>
  );
};

ProjectBillingCard.propTypes = {
  editable: PropTypes.bool,
  isLoading: PropTypes.bool,
  projectDetails: PropTypes.object
};

export default ProjectBillingCard;
