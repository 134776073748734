import { useQuery, useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';

export const GET_USERS_QUERY = gql`
  query Users(
    $enabled: Boolean
    $searchTerm: String
    $policyId: String
    $userAccessRoleUri: String
    $pageSize: Int
  ) {
    users(
      enabled: $enabled
      searchTerm: $searchTerm
      page: 1
      pagesize: $pageSize
      policyId: $policyId
      userAccessRoleUri: $userAccessRoleUri
    ) {
      id
      displayText
      uri
      slug
      loginName
    }
  }
`;

const mappedUsers = data =>
  data?.users?.map(user => ({
    ...user,
    value: user.id,
    label: user.displayText,
    key: user.displayText
  }));

export const useUsers = (
  searchTerm,
  userAccessRoleUri = null,
  policyId = null
) => {
  const { data, loading } = useQuery(GET_USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      enabled: true,
      searchTerm,
      userAccessRoleUri,
      policyId,
      pageSize: PAGE_SIZE + 1
    },
    context: {
      debounceKey: 'user-search-key',
      debounceTimeout: 250
    }
  });

  const options = mappedUsers(data) || [];

  return {
    users: options.slice(0, PAGE_SIZE),
    hasMore: options.length > PAGE_SIZE,
    loading
  };
};

export const searchUsers = ({ client }) => async searchTerm => {
  const result = await client.query({
    query: GET_USERS_QUERY,
    variables: { enabled: true, searchTerm, pageSize: PAGE_SIZE }
  });

  return mappedUsers(result.data);
};

export const useSearchableUsers = () => {
  const apolloClient = useApolloClient();

  return {
    searchUsers: searchUsers({
      client: apolloClient
    })
  };
};

export default useUsers;
