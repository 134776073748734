import React from 'react';
import { PropTypes } from 'prop-types';
import { DialogTitle, Dialog } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import RescheduleProjectDialogContent from './RescheduleProjectDialogContent';

const title = <FormattedMessage id="rescheduleProject.title" />;

const rescheduleProjectRole = {
  role: 'presentation'
};

export const RescheduleProjectDialog = ({
  open,
  onClose,
  project,
  newStartDate
}) => {
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={rescheduleProjectRole}
    >
      <DialogTitle>{title}</DialogTitle>
      <RescheduleProjectDialogContent
        project={project}
        onClose={onClose}
        newStartDate={newStartDate}
      />
    </Dialog>
  );
};

RescheduleProjectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  project: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  newStartDate: PropTypes.object
};

export default RescheduleProjectDialog;
