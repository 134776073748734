import { v4 } from 'uuid';
import { compareISOStrings } from '~/modules/common/dates/compare';
import { ExpenseEntryType } from '~/types';

export const expenseTypeUri = 'urn:replicon:script-key:parameter:expense-type';

export const totalEstimatesKey = 'totalEstimates';

export const getExpenseCodeIds = monthlyExpenses =>
  monthlyExpenses?.map(exp => exp.expenseCode.id);

const matchExpenseCode = (expenseCode, expenseCodeId) =>
  typeof expenseCode === 'object'
    ? expenseCode.id === expenseCodeId
    : expenseCode === expenseCodeId;

export const getCreateExpenseScript = (parameters, expenseCode, scripts) => {
  if (scripts && scripts.length) {
    const script = scripts.find(s =>
      matchExpenseCode(s[expenseTypeUri], expenseCode.id)
    );

    if (script) return script;
  }

  return parameters.reduce(
    (retVal, current) => ({
      ...retVal,
      scriptId: v4(),
      [current.id]:
        current.id === expenseTypeUri ? expenseCode : current.defaultValue
    }),
    {}
  );
};

export const getUpdatedParameters = (
  editable,
  parameters,
  readOnlyParameters,
  viewSummary
) =>
  editable || viewSummary
    ? parameters.filter(param => !readOnlyParameters.includes(param.id))
    : parameters;

export const getBillableTypeChangedStatus = ({
  prevBillableTypeStatus,
  currBillableTypeStatus
}) => {
  return {
    billableToNonBillableOrNonBillableToBillable:
      (prevBillableTypeStatus === ExpenseEntryType.Billable &&
        currBillableTypeStatus === ExpenseEntryType.NonBillable) ||
      (prevBillableTypeStatus === ExpenseEntryType.NonBillable &&
        currBillableTypeStatus === ExpenseEntryType.Billable),
    billableAndNonBillableToBillable:
      prevBillableTypeStatus === ExpenseEntryType.BillableAndNonBillable &&
      currBillableTypeStatus === ExpenseEntryType.Billable,
    billableAndNonBillableToNonBillable:
      prevBillableTypeStatus === ExpenseEntryType.BillableAndNonBillable &&
      currBillableTypeStatus === ExpenseEntryType.NonBillable
  };
};

export const getMonthlyExpensesPeriods = monthlyExpense => {
  return Object.keys(monthlyExpense).filter(k => k.includes('months'));
};

export const mergeScriptAndMonthlyExpense = (
  scripts,
  monthlyExpenses,
  allowBillable,
  canViewBillingData
) => {
  const billableCondition = !canViewBillingData;

  if (billableCondition) {
    return monthlyExpenses;
  }

  return monthlyExpenses.map(monthlyExpense => {
    const script = scripts.find(
      s =>
        s[expenseTypeUri]?.displayText ===
        monthlyExpense?.expenseCode?.displayText
    );

    return script ? { ...monthlyExpense, ...script } : { ...monthlyExpense };
  });
};

export const getAmountOrDefault = (amount, projectCurrency) =>
  amount || { amount: 0, currency: projectCurrency };

export const populateMonthlyExpenseAmount = (
  projectDateRange,
  setFieldValue,
  totalAmount,
  index,
  prop,
  record,
  projectCurrency,
  hasValidDateRange
) => {
  if (hasValidDateRange && projectDateRange) {
    const { startDate, endDate } = projectDateRange;

    const totalMonths =
      endDate.month -
      startDate.month +
      12 * (endDate.year - startDate.year) +
      1;

    const amount =
      totalAmount !== 0 && totalAmount !== undefined && totalAmount !== null
        ? totalAmount / totalMonths
        : 0;

    for (let i = 0; i < totalMonths; i++) {
      const date = startDate.set({ days: 1 }).plus({ month: i });

      const formattedMonth = date.month < 10 ? `0${date.month}` : date.month;
      const formattedDate = date.day < 10 ? `0${date.day}` : date.day;

      const key = `months-${date.year}-${formattedMonth}-${formattedDate}`;

      setFieldValue(`monthlyExpenses[${index}].${key}`, {
        ...(record[key] || {}),
        estimated: {
          ...(record[key]?.estimated || {}),
          [prop]: {
            amount,
            currency:
              record[key]?.estimated?.billableAmonut?.currency ||
              record[key]?.estimated?.nonBillableAmount?.currency ||
              projectCurrency
          }
        }
      });
    }
  }
};

export const getAmount = ({
  actualAmount,
  estimatedAmount,
  projectCurrency
}) => ({
  actuals: {
    billableAmount: getAmountOrDefault(
      actualAmount && actualAmount.billableAmount,
      projectCurrency
    ),
    nonBillableAmount: getAmountOrDefault(
      actualAmount && actualAmount.nonBillableAmount,
      projectCurrency
    )
  },
  estimated: {
    billableAmount: getAmountOrDefault(
      estimatedAmount && estimatedAmount.billableAmount,
      projectCurrency
    ),
    nonBillableAmount: getAmountOrDefault(
      estimatedAmount && estimatedAmount.nonBillableAmount,
      projectCurrency
    )
  }
});

const getDateFromKey = (scale, key) => {
  const date = key.replace(`${scale}-`, '');

  if (isNaN(Date.parse(date))) {
    return null;
  }

  return date;
};

export const getIsEditable = (
  projectDateRange,
  viewSummary,
  field,
  hasValidDateRange
) => {
  if (viewSummary) return false;

  if (field === totalEstimatesKey && hasValidDateRange) return true;

  const date = getDateFromKey('months', field);

  const startDate = projectDateRange.startDate.set({ days: 1 });
  const endDate = projectDateRange.endDate
    .set({ days: 1 })
    .plus({ month: 1 })
    .minus({ days: 1 });

  return Boolean(
    date &&
      compareISOStrings(startDate.toISODate(), date) <= 0 &&
      compareISOStrings(date, endDate.toISODate()) <= 0
  );
};

export const getNewValue = (prop, key, record, value, projectCurrency) => {
  const currentValue = record[key] || {};
  const estimated = currentValue?.estimated || {};

  return {
    ...currentValue,
    estimated: {
      ...estimated,
      [prop]: {
        amount: value,
        currency:
          estimated?.billableAmonut?.currency ||
          estimated?.nonBillableAmount?.currency ||
          projectCurrency
      }
    }
  };
};

const escapeRegExp = str => {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
};

const getNumberRegex = props => {
  const { decimalScale, decimalSeparator } = props;

  return new RegExp(
    `[0-9]${
      decimalSeparator && decimalScale !== 0
        ? `|${escapeRegExp(decimalSeparator)}`
        : ''
    }`,
    'g'
  );
};

export const getFloatString = (formattedValue, props) => {
  const { decimalScale, decimalSeparator } = props;

  const numRegex = getNumberRegex(props);

  let num = formattedValue;

  if (decimalSeparator && decimalScale === 0) {
    [num] = num.split(decimalSeparator);
  }

  num = (num.match(numRegex) || []).join('').replace(decimalSeparator, '.');

  const firstDecimalIndex = num.indexOf('.');

  if (firstDecimalIndex !== -1) {
    num = `${num.substring(0, firstDecimalIndex)}.${num
      .substring(firstDecimalIndex + 1, num.length)
      .replace(new RegExp(escapeRegExp(decimalSeparator), 'g'), '')}`;
  }

  return num;
};
