import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const CREATE_LINE_ITEMS = gql`
  mutation createInvoiceItemsFromBillingItemsBatch(
    $billId: String!
    $input: LineItemsFromBillingItemsBatchInput2!
  ) {
    createInvoiceItemsFromBillingItemsBatch: createInvoiceItemsFromBillingItemsBatch3(
      billId: $billId
      input: $input
    ) {
      batchUri
    }
  }
`;

export const useCreateInvoiceItemsFromBillItems = ({
  setBillingBatchState
}) => {
  const [createInvoiceItemsFromBillingItemsBatch] = useMutation(
    CREATE_LINE_ITEMS
  );

  return {
    createInvoiceItemsFromBillingItemsBatch: async ({
      billId,
      billingItemColumnOption,
      billingItemSearch,
      selectedBillingItems: {
        includedUris: billingItemsIncludedUris,
        excludedUris: billingItemsExcludedUris
      }
    }) => {
      const result = await createInvoiceItemsFromBillingItemsBatch({
        variables: {
          billId,
          input: {
            billingItemColumnOption,
            billingItemSearch: {
              textSearch: billingItemSearch.text,
              userUris: billingItemSearch.userUris,
              projectUris: billingItemSearch.projectUris,
              dateRange: billingItemSearch.dateRange,
              billingItemTypes: billingItemSearch.billingItemTypes
            },
            billingItemsIncludedUris,
            billingItemsExcludedUris
          }
        }
      });

      setBillingBatchState({
        batchId:
          result?.data?.createInvoiceItemsFromBillingItemsBatch?.batchUri,
        batchInProgress: true
      });
    }
  };
};

export default useCreateInvoiceItemsFromBillItems;
