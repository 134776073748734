import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_TAG_FIELDS_QUERY = gql`
  query extensionFieldTags($tagId: String!, $searchPhrase: String) {
    extensionFieldTags(tagId: $tagId, searchPhrase: $searchPhrase) {
      definition {
        displayText
      }
      displayText
      slug
      id
    }
  }
`;

export const useGetTagFieldsQuery = ({ tagId, searchPhrase = '' }) => {
  const { loading, data } = useQuery(GET_TAG_FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      tagId,
      searchPhrase
    },
    context: {
      debounceKey: 'tag-search-option',
      debounceTimeout: 250
    }
  });

  return {
    tags: !loading && data ? data.extensionFieldTags : [],
    loading
  };
};
