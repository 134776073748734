import React from 'react';
import { PropTypes } from 'prop-types';
import { DialogTitle, Dialog } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import ReschedulingProjectDialogContentData from './ReschedulingProjectDialogContentData';

const title = <FormattedMessage id="rescheduleProject.title" />;

export const ReschedulingProjectDialog = ({ open, jobId, onClose }) => {
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>{title}</DialogTitle>
      <ReschedulingProjectDialogContentData jobId={jobId} onClose={onClose} />
    </Dialog>
  );
};

ReschedulingProjectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  jobId: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default ReschedulingProjectDialog;
