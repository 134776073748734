import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ResourcePlanGroupBy } from '~/types';
import {
  ChartTotalItems,
  ChartHeader
} from '~/modules/common/charts/timeline/components';

const useStyles = makeStyles(theme => ({
  chartRoot: {
    top: theme.spacing(18)
  },
  statusPlaceHolder: {
    flexBasis: 18
  },
  itemContainer: {
    paddingLeft: theme.spacing(2)
  }
}));

const labels = {
  requested: (
    <FormattedMessage id="resourceAllocationChartHeaderRow.requested" />
  ),
  allocated: (
    <FormattedMessage id="resourceAllocationChartHeaderRow.allocated" />
  ),
  projects: <FormattedMessage id="resourceAllocationChartHeaderRow.projects" />,
  roles: <FormattedMessage id="resourceAllocationChartHeaderRow.roles" />
};

const rightComponent = (
  <ChartTotalItems requested={labels.requested} allocated={labels.allocated} />
);

export const ResourceAllocationChartHeaderRow = memo(
  ({
    scale,
    classes: classesOverrides,
    chartDates,
    leftComponentWidth,
    resourcePlanGroup
  }) => {
    const classes = useStyles({ classes: classesOverrides });

    const headerClasses = useMemo(
      () => ({
        root: classes.chartRoot,
        itemContainer: classes.itemContainer
      }),
      [classes.chartRoot, classes.itemContainer]
    );

    return (
      <ChartHeader
        classes={headerClasses}
        leftComponentWidth={leftComponentWidth}
        leftComponent={
          <>
            <div className={classes.statusPlaceHolder} />
            {resourcePlanGroup === ResourcePlanGroupBy.Project
              ? labels.projects
              : labels.roles}
          </>
        }
        scale={scale}
        chartDates={chartDates}
        rightComponent={rightComponent}
      />
    );
  }
);

ResourceAllocationChartHeaderRow.propTypes = {
  chartDates: PropTypes.array.isRequired,
  classes: PropTypes.object,
  scale: PropTypes.string.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  resourcePlanGroup: PropTypes.oneOf([
    ResourcePlanGroupBy.Project,
    ResourcePlanGroupBy.Role
  ])
};

export default ResourceAllocationChartHeaderRow;
