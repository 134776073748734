import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import MuiCheckbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(1, 0)
  },
  checkbox: {
    margin: theme.spacing(-1, -1.5),
    opacity: 0.3
  },
  checked: {
    opacity: 1
  }
}));

export const Checkbox = ({
  checked,
  classes: classesOverride,
  onChange,
  dataQeId,
  ...rest
}) => {
  const classes = useStyles({ classesOverride });
  const checkboxClasses = useMemo(
    () => ({
      root: classes.checkbox,
      checked: classes.checked
    }),
    [classes]
  );

  return (
    <div className={classes.wrapper}>
      <MuiCheckbox
        data-qe-id={dataQeId}
        checked={checked}
        classes={checkboxClasses}
        color="primary"
        onChange={onChange}
        data-checked={checked}
        {...rest}
      />
    </div>
  );
};

Checkbox.propTypes = {
  dataQeId: PropTypes.string,
  checked: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default Checkbox;
