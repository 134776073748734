import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  makeStyles,
  Grid,
  IconButton,
  Typography,
  Button
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FormattedMessage, useIntl } from 'react-intl';
import SyncIcon from '@material-ui/icons/Sync';
import { useDialogState } from '~/modules/common/hooks';
import {
  getSyncBillsInputProps,
  useSyncBills,
  useUpdateBillStatusBatch,
  getSelectedItemsCount
} from './hooks';
import { SyncConfirmationDialog } from './SyncConfirmationDialog';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#494949',
    height: theme.spacing(7),
    display: 'flex',
    justifyContent: 'space-between'
  },
  removeIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  iconButton: {
    color: '#fff',
    height: theme.spacing(4.5)
  },
  selectedStatus: {
    color: '#fff'
  },
  syncButton: {
    marginRight: theme.spacing(1.5),
    height: theme.spacing(4.5)
  }
}));
const syncIcon = <SyncIcon />;
const SelectionStatusbar = ({
  selectedTransactionItems,
  allSelectedStatus,
  onReset,
  billingTransactionFilter,
  setIsSyncing,
  excludedTransactionItems,
  totalTransactionItemsCount,
  transactionItems,
  isSyncing
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const ariaLabel = formatMessage({
    id: 'selectionStatusBar.clearIcon'
  });
  const { open, openDialog, closeDialog } = useDialogState(false);
  const [recalculationBatchState, setRecalculationBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });
  const transactionItemIds = transactionItems.map(x => x.id);
  const excludedItems = excludedTransactionItems.filter(x =>
    transactionItemIds.includes(x.id)
  );
  const selectedItems = selectedTransactionItems.filter(x =>
    transactionItemIds.includes(x.id)
  );
  const selectedItemsCount = getSelectedItemsCount({
    allSelectedStatus,
    totalCount: totalTransactionItemsCount,
    excludedItems,
    selectedItems
  });

  const statusValues = useMemo(() => ({ n: selectedItemsCount }), [
    selectedItemsCount
  ]);
  const inputProps = getSyncBillsInputProps({
    selectedItems,
    allSelectedStatus,
    excludedItems,
    billingTransactionFilter
  });
  const { syncBills } = useSyncBills({ ...inputProps, setIsSyncing });
  const { updateBillStatusBatch } = useUpdateBillStatusBatch({
    ...inputProps,
    setRecalculationBatchState
  });

  const onSyncBills = useCallback(async () => {
    await updateBillStatusBatch();
  }, [updateBillStatusBatch]);

  const onBatchComplete = useCallback(async () => {
    await syncBills();
    onReset();
    closeDialog();
  }, [closeDialog, onReset, syncBills]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        className={classes.container}
        alignItems="center"
      >
        <Grid item className={classes.removeIcon}>
          <IconButton
            onClick={onReset}
            className={classes.iconButton}
            aria-label={ariaLabel}
          >
            <ClearIcon />
          </IconButton>
          <Typography variant="subtitle1" className={classes.selectedStatus}>
            <FormattedMessage
              id="billingTransactionList.selectedItemStatus"
              values={statusValues}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.syncButton}
            startIcon={syncIcon}
            onClick={openDialog}
          >
            <FormattedMessage id="billingTransactionList.syncInvoice" />
          </Button>
        </Grid>
      </Grid>
      {open ? (
        <SyncConfirmationDialog
          open={open}
          onSync={onSyncBills}
          onCancel={closeDialog}
          isSyncing={isSyncing}
          recalculationBatchState={recalculationBatchState}
          setRecalculationBatchState={setRecalculationBatchState}
          onBatchComplete={onBatchComplete}
        />
      ) : null}
    </div>
  );
};

SelectionStatusbar.propTypes = {
  selectedTransactionItems: PropTypes.array,
  allSelectedStatus: PropTypes.number,
  onReset: PropTypes.func,
  billingTransactionFilter: PropTypes.object,
  setIsSyncing: PropTypes.func,
  excludedTransactionItems: PropTypes.array,
  totalTransactionItemsCount: PropTypes.number,
  transactionItems: PropTypes.array,
  isSyncing: PropTypes.bool
};

export default SelectionStatusbar;
