import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProgramIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} data-qe-id="milestoneTaskIcon">
    <>
      <path d="m 13.41818973222848,1.089309078338125 9.49250118943387,9.492501189433765 c 0.78574543778353,0.78574543778352 0.78574543778353,2.05088069139876 0,2.83662612918186 l -9.49225452470855,9.49225452470844 c -0.78574543778341,0.78574543778352 -2.05088069139877,0.78574543778352 -2.83662612918229,4.3e-13 L 1.08930907833764,13.41818973222885 c -0.7857454377836301,-0.78574543778395 -0.7857454377834145,-2.05088069139941 0,-2.83662612918273 L 10.58156360304618,1.089309078337699 c 0.78574543778353,-0.7857454377835182 2.05088069139867,-0.7857454377835182 2.8366261291823,4.26e-13 z" />
    </>
  </SvgIcon>
));

export default ProgramIcon;
