import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Checkbox, makeStyles } from '@material-ui/core';
import { DateRange, User } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export const ReleaseResourceRequestDialogTableRow = ({
  allocation,
  onRowSelectionChange,
  tableCellContent
}) => {
  const classes = useStyles();

  const onRowSelect = useCallback(
    event => onRowSelectionChange(allocation.id, event),
    [allocation.id, onRowSelectionChange]
  );

  return (
    <TableRow>
      <TableCell component="th" scope="row" size="small" padding="none">
        <Checkbox checked={allocation.isSelected} onChange={onRowSelect} />
      </TableCell>
      <TableCell padding="none">
        {tableCellContent || <User classes={classes} user={allocation.user} />}
      </TableCell>
      <TableCell padding="none">
        <DateRange start={allocation.startDate} end={allocation.endDate} />
      </TableCell>
    </TableRow>
  );
};

ReleaseResourceRequestDialogTableRow.propTypes = {
  onRowSelectionChange: PropTypes.func.isRequired,
  allocation: PropTypes.object.isRequired,
  tableCellContent: PropTypes.node
};

export default ReleaseResourceRequestDialogTableRow;
