export const DIMENSION_SLUG = {
  USER: 'user',
  PROJECTROLE: 'project-role',
  PAYCODE: 'pay-code',
  DATERANGE: 'date-range'
};

export const INCLUDE_DIMENSION_SLUGS = [
  DIMENSION_SLUG.PROJECTROLE,
  DIMENSION_SLUG.USER,
  DIMENSION_SLUG.PAYCODE
];

export const OVERRIDE_DIMENSION_PROPS = {
  [DIMENSION_SLUG.USER]: intl => ({
    displayText: intl.formatMessage({ id: 'advancedRateCard.resource' }),
    sortIndex: 1
  }),
  [DIMENSION_SLUG.PROJECTROLE]: intl => ({
    displayText: intl.formatMessage({ id: 'advancedRateCard.role' }),
    sortIndex: 2
  }),
  [DIMENSION_SLUG.PAYCODE]: () => ({
    sortIndex: 3
  })
};

export const DEFAULT_RATE = 'DEFAULT';

export const REQUIRED_DIMENSION_SLUGS = [DIMENSION_SLUG.DATERANGE];

export const EFFECTIVE_DATE = 'effectiveDate';
