import { getResourceAllocationTotal } from './useResourceAllocationTotal';

export const aggregateResourceAllocationTotals = () => allocationTotals => {
  const totalCost = {};

  allocationTotals.forEach(t => {
    const { currencyUri, currencySymbol } = t.resourceAllocationCost;

    if (currencyUri) {
      if (!totalCost[currencyUri])
        totalCost[currencyUri] = { currencySymbol, amount: 0 };

      totalCost[currencyUri].amount += t.resourceAllocationCost.amount;
    }
  });

  return {
    totalAllocatedHoursForResourceRequest: allocationTotals.reduce(
      (total, v) => total + v.resourceAllocationHours,
      0
    ),
    totalAllocatedCostForResourceRequest: Object.keys(totalCost).map(key => ({
      currencyUri: key,
      amount: totalCost[key].amount,
      currencySymbol: totalCost[key].currencySymbol
    }))
  };
};

const getAllocationsTotalForResourceRequest = ({
  me,
  resourceAllocations,
  contextCurrency
}) =>
  aggregateResourceAllocationTotals()(
    resourceAllocations.map(allocation => {
      const total = getResourceAllocationTotal({
        me,
        contextCurrency,
        ...allocation
      });

      return total;
    })
  );

export const getUpdatedCostByCurrency = ({
  exchangeRateValues,
  newTotalCost
}) => {
  const updatedExchangeRateValues = Object.keys(exchangeRateValues).reduce(
    (acc, currency) => {
      if (exchangeRateValues[currency]) {
        const { rate } = exchangeRateValues[currency];
        const amount = rate * newTotalCost.amount;

        acc[currency] = {
          currency: { id: exchangeRateValues[currency].currencyId },
          amount,
          __typename: 'MoneyValue'
        };
      } else {
        acc[currency] = null;
      }

      return acc;
    },
    {}
  );

  return {
    ...updatedExchangeRateValues,
    __typename: 'AllocationTotalCostByCurrency'
  };
};

export default getAllocationsTotalForResourceRequest;
