/* eslint-disable react/jsx-max-depth */
import React, { Fragment, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, Table, TableBody } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import { useSkillLevels } from '~/modules/common/hooks';
import NoDataItem from '~/modules/common/components/NoDataItem';
import { SkillsSelectDropdown, SkillsForm, SkillCategory } from '../Skills';

const useStyles = makeStyles(theme => ({
  select: {
    zIndex: 2,
    position: 'sticky !important',
    left: 0
  },
  noSkill: { paddingTop: theme.spacing(2) },
  error: { color: theme.palette.error.main },
  table: { marginTop: theme.spacing(1) }
}));

export const AddSkills = ({
  skills,
  setFieldValue,
  required,
  variant,
  className,
  isResourceRequestSkillRow,
  customLabel
}) => {
  const classes = useStyles();

  const { skillLevels, maxSkillLevel, isSkillLoading } = useSkillLevels();

  const onSkillsSelected = useCallback(
    event => {
      setFieldValue(
        'skills',
        sortBy(
          event.map(e => ({
            ...e,
            skillLevel: e.skillLevel || skillLevels[0]
          })),
          skillItem => skillItem.displayText
        )
      );
    },
    [setFieldValue, skillLevels]
  );

  const onSkillAdd = useCallback(
    event => onSkillsSelected([...skills, event]),
    [skills, onSkillsSelected]
  );

  const onSkillRatingUpdate = ({ ratingIndex, skillAssignment }) => {
    const selectedSkillLevel = skillLevels.find(
      level => level.rating === ratingIndex
    );
    const filteredSkills = skills.map(skillItem => ({
      ...skillItem,
      skillLevel:
        skillItem.id === skillAssignment.id
          ? selectedSkillLevel
          : skillItem.skillLevel
    }));

    setFieldValue('skills', filteredSkills);
  };

  const onCategoryRemove = category => {
    const filteredSkills = skills.filter(
      skillItem => skillItem.category.id !== category.id
    );

    setFieldValue('skills', filteredSkills);
  };

  const onSkillRemove = skill => {
    const filteredSkills = skills.filter(item => item.id !== skill.id);

    setFieldValue('skills', filteredSkills);
  };

  const categories = useMemo(
    () =>
      sortedUniqBy(
        sortBy(skills, skillItem => skillItem.category.displayText),
        skillItem => skillItem.category.id
      ).map(option => option.category),
    [skills]
  );

  return (
    <>
      <SkillsSelectDropdown
        value={skills.map(u => ({
          ...u,
          value: u.uri || u.id,
          label: u.displayText
        }))}
        onChange={onSkillsSelected}
        onSkillAdd={onSkillAdd}
        classes={classes}
        variant={variant}
        className={className}
        customLabel={customLabel}
      />
      {!isSkillLoading && skills.length > 0 ? (
        <Table size="small" className={classes.table}>
          <TableBody>
            {categories.map(category => (
              <Fragment key={category.id}>
                <TableRow>
                  <SkillCategory
                    category={category}
                    onCategoryRemove={onCategoryRemove}
                  />
                </TableRow>
                <SkillsForm
                  skills={skills}
                  maxSkillLevel={maxSkillLevel}
                  category={category}
                  onSkillRemove={onSkillRemove}
                  showCertificates={false}
                  onSkillRatingUpdate={onSkillRatingUpdate}
                  isResourceRequestSkillRow={isResourceRequestSkillRow}
                />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.noSkill}>
          <NoDataItem>
            <span
              className={classNames({
                [classes.error]: required
              })}
            >
              <FormattedMessage id="addRoleDialog.noSkills" />
            </span>
          </NoDataItem>
        </div>
      )}
    </>
  );
};

AddSkills.propTypes = {
  skills: PropTypes.array,
  setFieldValue: PropTypes.func,
  required: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  isResourceRequestSkillRow: PropTypes.bool,
  customLabel: PropTypes.string
};

export default AddSkills;
