import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import TableSortLabel from '~/modules/common/components/ListTable/components/DataTable/components/TableSortLabel';

const useStyles = makeStyles(theme => ({
  role: {
    paddingLeft: theme.spacing(1)
  },
  tableCell: {
    flexGrow: 1,
    minWidth: 100,
    alignSelf: 'stretch',
    flexBasis: 100,
    marginLeft: theme.spacing(-1.5)
  },
  roleEditCell: {
    paddingLeft: theme.spacing(9)
  },
  userCell: {
    marginLeft: theme.spacing(-1)
  },
  userEditCell: {
    marginLeft: theme.spacing(-0.5)
  },
  tableCellFFOn: {
    marginLeft: theme.spacing(1)
  }
}));

export const ResourceRequestChartHeaderLeftItem = ({
  isEditSwitchEnabled,
  sort,
  onSortChange,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles();

  return (
    <>
      <TableSortLabel
        className={classNames({
          [classes.tableCell]: !isPsaRmpTaskAllocation1Enabled,
          [classes.tableCellFFOn]: isPsaRmpTaskAllocation1Enabled
        })}
        field="roleName"
        show
        active={sort && sort.field === 'roleName'}
        direction={sort && sort.direction && sort.direction.toLowerCase()}
        onClick={onSortChange}
      >
        {isPsaRmpTaskAllocation1Enabled ? (
          <Typography variant="subtitle2">
            <FormattedMessage id="resourceRequestChartHeader.resourceRequests" />
          </Typography>
        ) : (
          <div
            className={classNames({
              [classes.role]: !isEditSwitchEnabled,
              [classes.roleEditCell]: isEditSwitchEnabled
            })}
          >
            <FormattedMessage id="resourceRequestChartHeader.role" />
          </div>
        )}
      </TableSortLabel>
      {!isPsaRmpTaskAllocation1Enabled && (
        <TableSortLabel
          className={classes.tableCell}
          field="userName"
          show
          active={sort && sort.field === 'userName'}
          direction={sort && sort.direction && sort.direction.toLowerCase()}
          onClick={onSortChange}
        >
          <div
            className={classNames({
              [classes.userCell]: !isEditSwitchEnabled,
              [classes.userEditCell]: isEditSwitchEnabled
            })}
          >
            <FormattedMessage id="resourceRequestChartHeader.resource" />
          </div>
        </TableSortLabel>
      )}
    </>
  );
};

ResourceRequestChartHeaderLeftItem.propTypes = {
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  sort: PropTypes.object,
  onSortChange: PropTypes.func,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default ResourceRequestChartHeaderLeftItem;
