import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const EXPENSE_ACTUALS_SERIES = gql`
  query expenseActualsSeries(
    $projectId: String!
    $dateRange: DateRangeInput2
    $currencyId: String
  ) {
    expenseActualsSeries(
      projectId: $projectId
      dateRange: $dateRange
      currencyId: $currencyId
    ) {
      currency {
        id
        symbol
        displayText
      }
      dataPoints {
        billableAmount
        nonBillableAmount
        period {
          start: periodStart2
          end: periodEnd2
        }
      }
      expenseCode {
        id
        displayText
      }
    }
  }
`;

export const useExpenseActualsSeries = (projectId, dateRange, currencyId) => {
  const { data, loading } = useQuery(EXPENSE_ACTUALS_SERIES, {
    variables: {
      projectId,
      dateRange:
        dateRange && dateRange.startDate && dateRange.endDate
          ? {
              startDate: dateRange.startDate.toISODate(),
              endDate: dateRange.endDate.toISODate()
            }
          : null,
      currencyId
    },
    skip: !projectId || !dateRange,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const expenseActualsSeries = get(data, 'expenseActualsSeries', []);

  return {
    loading,
    expenseActualsSeries
  };
};

export default useExpenseActualsSeries;
