import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory';
import { makeStyles, alpha } from '@material-ui/core/styles';
import {
  numberAbbreviatedTickFormat,
  ChartLegends
} from '~/modules/common/charts/dashboard';
import { useVictoryChartContainerStyles } from '~/modules/common/charts/dashboard/config';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import { OVERVIEW_UNITS_ENUM } from '~/modules/resource-management/common/enums';
import { themeWithoutDir as rootTheme } from '~/modules/App/withRootTheme';
import { useLegends, allocationLegendLabels } from '../hooks';
import useChartStyles from './useChartStyles';
import { useResourceAllocationDataPoints, useChartClickHandler } from './hooks';
import ResourceAllocationChartSkeleton from './ResourceAllocationChartSkeleton';
import { ResourceAllocationDialog } from './ResourceAllocationDialog';
import {
  useVictoryLabelCenteredOffset,
  useVictoryLabelValue,
  useVictoryLabelStyles,
  getTooltipLabels
} from './victoryLabelUtil';

const useLegendStyles = makeStyles(theme => ({
  container: {
    paddingRight: theme.spacing(2)
  },
  legendText: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const victoryStyles = {
  flyoutStyle: {
    stroke: 'none',
    fill: alpha(rootTheme.palette.grey[700], 0.9)
  },
  flyoutPadding: {
    top: rootTheme.spacing(0.5),
    bottom: rootTheme.spacing(0.5),
    left: rootTheme.spacing(1),
    right: rootTheme.spacing(1)
  },
  toolTip: {
    fontSize: '7px',
    fill: rootTheme.palette.common.white
  }
};

export const ResourceAllocationChart = ({
  intl,
  overViewUnit,
  titleId = ''
}) => {
  const styles = useChartStyles();
  const victoryContainerClasses = useVictoryChartContainerStyles();
  const legendClasses = useLegendStyles();
  const isMobile = useIsBreakpointDown('xs');

  const yTickFormat = useCallback(
    tick => numberAbbreviatedTickFormat({ intl, tick: Math.abs(tick) }),
    [intl]
  );
  const getTickLabel = useMemo(
    () => dx => <VictoryLabel style={styles.axis.labelStyle} dx={dx} />,
    [styles.axis.labelStyle]
  );
  const legends = useLegends({}, allocationLegendLabels);

  const dy = useVictoryLabelCenteredOffset();
  const labelValue = useVictoryLabelValue();
  const labelStyles = useVictoryLabelStyles();

  const {
    loading,
    dataPoints,
    totalSchedule,
    resourceSeriesDataInFteUnits,
    resourceSeriesDataInPercentage,
    resourceSeriesDataInHours,
    unit
  } = useResourceAllocationDataPoints({ overViewUnit });

  const { open, closeDialog, openDialog } = useDialogState();

  const { selectedIndex, chartClickHandler } = useChartClickHandler({
    openDialog
  });

  const unitLabel = intl.formatMessage({
    id:
      unit === OVERVIEW_UNITS_ENUM.HOURS
        ? 'resourcingOverviewChart.hours'
        : unit === OVERVIEW_UNITS_ENUM.PERCENTAGE
        ? 'resourcingOverviewChart.percentage'
        : 'resourcingOverviewChart.resourcesFTE'
  });

  return (
    <>
      {loading ? (
        <ResourceAllocationChartSkeleton />
      ) : (
        <div className={victoryContainerClasses.container}>
          <ChartLegends legends={legends} classes={legendClasses} />
          <VictoryChart
            height={styles.chart.height}
            width={styles.chart.width}
            padding={styles.chart.padding}
            domainPadding={styles.chart.domainPadding}
            events={chartClickHandler}
            containerComponent={
              <VictoryVoronoiContainer
                aria-labelledby={titleId}
                labels={getTooltipLabels}
                labelComponent={
                  <VictoryTooltip
                    style={victoryStyles.toolTip}
                    cornerRadius={5}
                    pointerLength={0}
                    flyoutPadding={victoryStyles.flyoutPadding}
                    flyoutStyle={victoryStyles.flyoutStyle}
                  />
                }
              />
            }
          >
            <VictoryAxis
              dependentAxis
              crossAxis={false}
              theme={VictoryTheme.material}
              name="resource-yAxis"
              style={styles.axis.y.style}
              tickLabelComponent={isMobile ? getTickLabel(-5) : getTickLabel()}
              tickFormat={yTickFormat}
              label={unitLabel}
              domain={styles.axis.y.domainPadding}
            />

            <VictoryAxis
              theme={VictoryTheme.material}
              style={styles.axis.x.styleX1}
            />
            <VictoryAxis
              theme={VictoryTheme.material}
              name="resource-xAxis"
              offsetY={styles.axis.x.offsetY}
              style={styles.axis.x.styleX2}
              tickLabelComponent={getTickLabel()}
            />
            <VictoryStack colorScale={styles.barStackColors}>
              {dataPoints.map(data => (
                <VictoryBar
                  name="stackBar"
                  barWidth={
                    data.id === 'requested'
                      ? styles.chart.pendingBarWidth
                      : styles.chart.barWidth
                  }
                  data={data.dataPoints}
                  key={`ResourceBar_${data.id}`}
                  y={styles.chart.y}
                  x={styles.chart.x}
                  style={styles.cursorPointer}
                  labelComponent={
                    <VictoryLabel
                      id={({ index }) => `${data.id}-${index}`}
                      style={labelStyles[data.id]}
                      dy={dy}
                    />
                  }
                  labels={labelValue}
                />
              ))}
            </VictoryStack>
            <VictoryBar
              name="bar"
              barWidth={styles.chart.barWidth}
              style={styles.availableBar.style}
              data={totalSchedule}
              y={styles.chart.y}
              x={styles.chart.x}
            />
          </VictoryChart>
          {selectedIndex >= 0 && (
            <ResourceAllocationDialog
              open={open}
              onCancel={closeDialog}
              allocationDataHours={resourceSeriesDataInHours[selectedIndex]}
              allocationDataPercentage={
                resourceSeriesDataInPercentage[selectedIndex]
              }
              allocationDataFTE={resourceSeriesDataInFteUnits[selectedIndex]}
            />
          )}
        </div>
      )}
    </>
  );
};

ResourceAllocationChart.propTypes = {
  overViewUnit: PropTypes.oneOf([
    OVERVIEW_UNITS_ENUM.PERCENTAGE,
    OVERVIEW_UNITS_ENUM.HOURS,
    OVERVIEW_UNITS_ENUM.FTE
  ]),
  intl: PropTypes.object,
  titleId: PropTypes.string
};

export default injectIntl(ResourceAllocationChart);
