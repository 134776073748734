import { useState, useEffect } from 'react';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { getFirstErrorCode } from '~/modules/common/graphql/errors';

export const pendingTasksCountQuery = gql`
  query Eager_GetTaskEntriesCount(
    $approvalStatus: TaskEntryApprovalStatus!
    $filter: TaskEntryFilter2
    $projectFilter: ProjectFilter2
  ) {
    taskEntriesCount(
      approvalStatus: $approvalStatus
      projectFilter: $projectFilter
      filter: $filter
    )
  }
`;

export const usePendingTasksCountQuery = ({ projectFilter, intl }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { data, loading, error } = useQuery(pendingTasksCountQuery, {
    variables: {
      approvalStatus: 'PENDING',
      projectFilter
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (error) {
      setErrorMessage(
        intl.formatMessage({
          id:
            getFirstErrorCode(error) === 'MAX_LIMIT_EXCEEDED_ERROR'
              ? 'projectOverview.maxLimitError'
              : 'projectOverview.unknownServiceError'
        })
      );
    }
  }, [error, intl]);

  const pendingTasksCount =
    !loading && !error && data ? data.taskEntriesCount : 0;

  return {
    loading,
    pendingTasksCount,
    errorMessage
  };
};

export default usePendingTasksCountQuery;
