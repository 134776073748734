import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const OverviewItemTooltipContent = ({
  value,
  currencySymbol,
  numberFormatSettings
}) =>
  currencySymbol ? (
    <>
      {`${currencySymbol} `}
      <NumberFormat
        displayType="text"
        value={value}
        {...numberFormatSettings}
        fixedDecimalScale
      ></NumberFormat>
    </>
  ) : (
    <NumberFormat
      displayType="text"
      value={value}
      {...numberFormatSettings}
      fixedDecimalScale
    ></NumberFormat>
  );

OverviewItemTooltipContent.propTypes = {
  value: PropTypes.number,
  currencySymbol: PropTypes.string,
  numberFormatSettings: PropTypes.shape({
    thousandSeparator: PropTypes.string,
    decimalSeparator: PropTypes.string,
    thousandsGroupStyle: PropTypes.string,
    decimalScale: PropTypes.number
  })
};

export default OverviewItemTooltipContent;
