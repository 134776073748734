import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  makeStyles,
  ButtonGroup,
  Menu,
  MenuItem,
  Button
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useBillPermission from '~/modules/billing-invoicing/bill/hooks/useBillPermission';
import { useDialogState } from '~/modules/common/hooks';
import { AssignNewPaymentDrawer } from '~/modules/billing-invoicing/bill/components/BillDetails/components/components/AssignBillCreditButton/components';
import { SendBillDialog } from '~/modules/billing-invoicing/common/components/SendBillDialog';
import { useMeContext } from '~/modules/me/useMeContext';
import { useIssueBill } from '~/modules/billing-invoicing/bill/components/BillDetails/components/hooks/';
import {
  useHasBillPermission,
  useIssueAction
} from '~/modules/billing-invoicing/common/hooks';
import { BillIssueConfirmationDialog } from '~/modules/billing-invoicing/common/components/DrawerFooter/BillIssueConfirmationDialog';
import {
  BillingTransactionType,
  BillingTransactionBalanceStatus
} from '~/types';
import { useBillActions } from '~/modules/billing-invoicing/common/components/DrawerFooter/useBillActions';
import useActionColumnHandler from '../useActionColumnHandler';

const useStyles = makeStyles(theme => ({
  link: {
    '&:focus, &:hover, &:active': {
      textDecoration: 'none'
    }
  },
  button: {
    position: 'static'
  },
  addPayment: {
    width: theme.spacing(11.82)
  }
}));

const menuListProps = { dense: true };

export const ActionColumnFormatter = ({
  field,
  record,
  column: { refetchBillingTransactionRows }
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openMenu = Boolean(anchorEl);

  const { billPermission } = useBillPermission({
    billId: record.id,
    skip: record.type !== BillingTransactionType.Bill
  });

  const {
    canSyncBill,
    canEditBill,
    canIssueBill,
    isBillIssuedAndPaid
  } = useHasBillPermission({
    bill: record,
    billPermission
  });

  const {
    open: dialogOpen,
    openDialog: onMenuClick,
    closeDialog
  } = useDialogState(false);

  const updateTransactionRows = useCallback(() => {
    if (refetchBillingTransactionRows) refetchBillingTransactionRows();
  }, [refetchBillingTransactionRows]);

  const onClose = useCallback(() => {
    if (updateTransactionRows) updateTransactionRows();
    closeDialog();
  }, [closeDialog, updateTransactionRows]);

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handleOpenToggle = useCallback(
    event => {
      setAnchorEl(openMenu ? null : event.currentTarget);
    },
    [openMenu]
  );

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const { issueBill } = useIssueBill({
    transactionId: record.id,
    refreshTransactionList: false,
    record
  });

  const closeDrawer = useCallback(() => setDrawerOpen(false), []);
  const onOpenDrawer = useCallback(() => {
    openDrawer();
    onMenuClose();
  }, [onMenuClose, openDrawer]);

  const isRefund = record.balanceWithoutAllocationsAmount.amount < 0;
  const showAddPayment =
    record.type === BillingTransactionType.Bill &&
    canEditBill &&
    (record.balanceStatus === BillingTransactionBalanceStatus.Unallocated ||
      record.balanceStatus ===
        BillingTransactionBalanceStatus.PartiallyAllocated) &&
    (record.balanceAmount.amount >= 0 || isRefund);

  const {
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick
  } = useIssueAction({
    me,
    issueBill,
    onMenuClick,
    record,
    updateTransactionRows
  });

  const {
    open: billIssueDialogOpen,
    openDialog: openBillIssueDialog,
    closeDialog: closeBillIssueDialog
  } = useDialogState(false);
  const options = useBillActions(canSyncBill, record.billStatus);
  const { menuItemClick, confirmationDialogName } = useActionColumnHandler({
    options,
    handleIssuePrintClick,
    handleIssueEmailClick,
    handleIssueSyncClick,
    openBillIssueDialog,
    record
  });
  const arialLabel = intl.formatMessage({
    id: 'billingTransactionRows.bills.actionColumn'
  });

  return (
    <>
      {record.type === BillingTransactionType.Bill ? (
        <>
          <ButtonGroup variant="text" size="small" disableRipple>
            {showAddPayment ? (
              <Button
                color="primary"
                onClick={onOpenDrawer}
                className={classes.button}
                disableRipple
              >
                <FormattedMessage
                  id={
                    isRefund
                      ? 'billingTransactionList.addRefund'
                      : 'billingTransactionList.addPayment'
                  }
                />
              </Button>
            ) : (
              <div className={classes.addPayment}></div>
            )}
            {canIssueBill && !isBillIssuedAndPaid ? (
              <Button
                aria-label={arialLabel}
                onClick={handleOpenToggle}
                className={classes.button}
                disableRipple
              >
                <ArrowDropDownIcon />
              </Button>
            ) : null}
          </ButtonGroup>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={onMenuClose}
            MenuListProps={menuListProps}
            onClick={onMenuClose}
          >
            {options.map(q => (
              <MenuItem
                key={q.label}
                onClick={menuItemClick(q.value)}
                value={q.id}
              >
                <FormattedMessage id={q.label} />
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null}

      {drawerOpen && (
        <AssignNewPaymentDrawer
          open={drawerOpen}
          onClose={closeDrawer}
          billId={record.id}
          client={record.client}
          isRefund={record.balanceWithoutAllocationsAmount.amount < 0}
          billBalanceTotal={record.balanceAmount}
        />
      )}
      {Boolean(dialogOpen) && (
        <SendBillDialog bill={record} open={dialogOpen} onClose={onClose} />
      )}
      {billIssueDialogOpen ? (
        <BillIssueConfirmationDialog
          open={billIssueDialogOpen}
          onClose={closeBillIssueDialog}
          confirmationDialogName={confirmationDialogName}
          handleIssuePrintClick={handleIssuePrintClick}
          handleIssueEmailClick={handleIssueEmailClick}
          handleIssueSyncClick={handleIssueSyncClick}
        />
      ) : null}
    </>
  );
};
ActionColumnFormatter.propTypes = {
  record: PropTypes.object,
  field: PropTypes.string,
  column: PropTypes.object
};
export default ActionColumnFormatter;
