import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import gql from 'graphql-tag';
import { orderBy } from 'lodash';
import { getSessionStorage } from '~/modules/common/hooks';
import { GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES } from './useFetchAllocatedUsersAndRoles';
import { GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES } from './useFetchResourcePlanUsersAndRoles';

export const UPDATE_RESOURCE_ALLOCATION = gql`
  mutation updateResourceAllocationRole(
    $input: UpdateResourceAllocationRoleInput!
  ) {
    updateResourceAllocationRole(input: $input) {
      resourceAllocation {
        id
        role {
          id
          uri
          displayText
        }
      }
    }
  }
`;

const updateRole = ({ allocatedUser, allocationId, requestedRole }) => {
  const requestedRoleId = requestedRole?.id || null;
  const cachedRoles = allocatedUser.roles;
  const roles = [...cachedRoles];

  const existingRoleIndex = cachedRoles.findIndex(role =>
    role.resourceAllocationReference.some(
      allocation => allocation.id === allocationId
    )
  );

  if (existingRoleIndex !== -1) {
    const { resourceAllocationReference } = cachedRoles[existingRoleIndex];

    if (resourceAllocationReference.length === 1) {
      roles.splice(existingRoleIndex, 1);
    } else {
      const updatedExistingRole = {
        ...roles[existingRoleIndex],
        resourceAllocationReference: resourceAllocationReference.filter(
          ({ id }) => id !== allocationId
        )
      };

      roles[existingRoleIndex] = updatedExistingRole;
    }

    const tagetRoleIndex = requestedRoleId
      ? roles.findIndex(r => r.role?.id === requestedRoleId)
      : roles.findIndex(r => !r.role);

    const filteredAllocationReference = resourceAllocationReference.filter(
      ({ id }) => id === allocationId
    );

    if (tagetRoleIndex !== -1) {
      roles[tagetRoleIndex] = {
        ...roles[tagetRoleIndex],
        resourceAllocationReference: [
          ...roles[tagetRoleIndex].resourceAllocationReference,
          ...filteredAllocationReference
        ]
      };
    } else {
      const requestedRoleDisplayText = requestedRole?.displayText || null;

      roles.push({
        __typename: 'ProjectRoleWithAllocations',
        role: requestedRoleId
          ? {
              id: requestedRoleId,
              displayText: requestedRoleDisplayText,
              __typename: 'ObjectReference'
            }
          : null,
        resourceAllocationReference: filteredAllocationReference
      });
    }
  }

  return {
    ...allocatedUser,
    roles: orderBy(roles, ({ role }) => role?.displayText || '', ['asc'])
  };
};

export const updateCache = ({
  variables,
  allocationId,
  requestedRole,
  userId
}) => (cache, { data }) => {
  if (!data) return;

  const resourcePlanAllocationUserAndRoleDetails = cache.readQuery({
    query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
    variables
  });

  if (
    resourcePlanAllocationUserAndRoleDetails &&
    resourcePlanAllocationUserAndRoleDetails.project.resourcePlanAllocatedUsersAndRoles.some(
      ({ user }) => user.id === userId
    )
  ) {
    const updatedCachedUserAndRoleDetails = {
      ...resourcePlanAllocationUserAndRoleDetails,
      project: {
        ...resourcePlanAllocationUserAndRoleDetails.project,
        resourcePlanAllocatedUsersAndRoles: resourcePlanAllocationUserAndRoleDetails.project.resourcePlanAllocatedUsersAndRoles.map(
          allocatedUser =>
            allocatedUser.user.id === userId
              ? updateRole({ allocatedUser, allocationId, requestedRole })
              : allocatedUser
        )
      }
    };

    cache.writeQuery({
      query: GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES,
      variables,
      data: updatedCachedUserAndRoleDetails
    });
  }
};

export const useUpdateResourceAllocationRole = () => {
  const [updateResourceAllocationRole, { loading }] = useMutation(
    UPDATE_RESOURCE_ALLOCATION
  );

  const onUpdateResourceAllocationRole = useCallback(
    ({ allocationId, requestedRole, userId }) => {
      const variables = getSessionStorage(
        GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES
      );

      return updateResourceAllocationRole({
        variables: {
          input: {
            allocationId,
            requestedRoleId: requestedRole?.id || null
          }
        },
        update: updateCache({
          variables,
          allocationId,
          requestedRole,
          userId
        })
      });
    },
    [updateResourceAllocationRole]
  );

  return { onUpdateResourceAllocationRole, loading };
};

export default useUpdateResourceAllocationRole;
