import React, { useMemo } from 'react';
import {
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ScriptParamsEditorTable } from '~/modules/projects/project/ScriptParamsEditableCard/components';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 500
  },
  accordion: {
    marginTop: theme.spacing(1),
    padding: 0
  },
  accordionSummary: {
    padding: 0,
    flexDirection: 'row-reverse'
  },
  expandIcon: {
    marginLeft: theme.spacing(-1.5),
    marginRight: 0
  },
  accordionDetails: {
    padding: 0
  }
}));
const expandMoreIcon = <ExpandMoreIcon />;
const resourceLabels = {
  tcvBaseCurrency: (
    <FormattedMessage id="projectRevenueRecognitionCard.tcvBaseCurrency" />
  ),
  tcvProjectCurrency: (
    <FormattedMessage id="projectRevenueRecognitionCard.tcvProjectCurrency" />
  )
};

export const PolicyAccordion = ({ policyLabel, parameters, scripts }) => {
  const classes = useStyles();
  const accordionSummaryClasses = useMemo(
    () => ({
      root: classes.accordionSummary,
      expandIcon: classes.expandIcon
    }),
    [classes.expandIcon, classes.accordionSummary]
  );

  const updatedParameter = useMemo(() => {
    const tcvBaseCurrency = parameters.find(
      x => x.id === 'urn:replicon:script-key:parameter:total-contract-value'
    );

    const tcvProjectCurrency = parameters.find(
      x =>
        x.id ===
        'urn:replicon:script-key:parameter:total-contract-value-in-reference-currency'
    );

    return tcvBaseCurrency && tcvProjectCurrency
      ? [
          ...parameters,
          { ...tcvBaseCurrency, displayText: resourceLabels.tcvBaseCurrency },
          {
            ...tcvProjectCurrency,
            displayText: resourceLabels.tcvProjectCurrency
          }
        ]
      : parameters;
  }, [parameters]);

  return (
    <>
      <Accordion elevation={0} className={classes.accordion}>
        <AccordionSummary
          expandIcon={expandMoreIcon}
          classes={accordionSummaryClasses}
        >
          <Typography component="div" className={classes.heading}>
            {policyLabel}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <ScriptParamsEditorTable
            editable={false}
            parameters={updatedParameter}
            scripts={scripts}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

PolicyAccordion.propTypes = {
  policyLabel: PropTypes.string,
  parameters: PropTypes.array,
  scripts: PropTypes.array
};

export default PolicyAccordion;
