import { Dialog, DialogTitle } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CarryOverSummaryDialogContent from './CarryOverSummaryDialogContent';
import CarryOverSummaryDialogSkeleton from './CarryOverSummaryDialogSkeleton';
import { CARRY_OVER_SUMMARY_QUERY } from './carryOverSummaryQuery';

const transitionProps = {
  role: 'presentation'
};

export const CarryOverSummaryDialog = ({
  revenueEntryId,
  revenuePeriodEndDate,
  project,
  open,
  onClose
}) => {
  const { data, loading } = useQuery(CARRY_OVER_SUMMARY_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !revenueEntryId,
    variables: {
      revenueEntryId
    }
  });
  const summaryData = (data && data.recognizableRevenueCarryOverSummary) || [];

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      TransitionProps={transitionProps}
    >
      <DialogTitle>
        <FormattedMessage id="carryOverSummaryDialog.title" />
      </DialogTitle>
      {loading && <CarryOverSummaryDialogSkeleton onClose={onClose} />}
      {!loading && (
        <CarryOverSummaryDialogContent
          summaryData={summaryData}
          revenuePeriodEndDate={revenuePeriodEndDate}
          project={project}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
};

CarryOverSummaryDialog.propTypes = {
  revenueEntryId: PropTypes.string.isRequired,
  revenuePeriodEndDate: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default CarryOverSummaryDialog;
