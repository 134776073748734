export const getTaxRateHeaders = (taxRateKeyValues, sort) => {
  const headers = taxRateKeyValues.map(item => {
    const { keyUri, value } = item;
    const displayText = value.text;
    const type = value.collection[0].text;
    const sortIndex = value.collection[3].number;
    const visible = true;

    return { keyUri, displayText, type, sortIndex, visible };
  });

  if (sort) headers.sort((a, b) => a.sortIndex - b.sortIndex);

  return headers;
};

export const defaultValueFormatterByType = {
  text: value => value.text,
  boolean: value => value.bool,
  percentage: value => value.number,
  numeric: value => value.number
};

const normalizeTaxRateValue = (value, asOfDate) => {
  const type = value.collection[0].text;
  const valueFormatter = defaultValueFormatterByType[type];
  const nValue = valueFormatter ? valueFormatter(value.collection[1]) : null;

  return { value: nValue, asOfDate };
};

export const mapTaxRatesForTaxProfile = ({
  loading,
  taxRatesForTaxProfile
}) => {
  if (loading || !taxRatesForTaxProfile.length)
    return { taxRateHeaders: [], taxRateRows: [] };
  const taxRateHeaders = getTaxRateHeaders(
    taxRatesForTaxProfile[0].taxRateKeyValues,
    true
  );

  const taxRateRows = taxRatesForTaxProfile.map(item => {
    const { effectiveDate, taxRateKeyValues } = item;
    let flag = true;

    return taxRateKeyValues.reduce((taxRateRow, current) => {
      const taxRateRowCopy = taxRateRow;
      const isHistorical = current.value.collection[4].bool;
      const asOfDate = flag && isHistorical ? effectiveDate : null;

      if (asOfDate) flag = false;

      taxRateRowCopy[current.keyUri] = normalizeTaxRateValue(
        current.value,
        asOfDate
      );

      return taxRateRowCopy;
    }, {});
  });

  return { taxRateHeaders, taxRateRows };
};
