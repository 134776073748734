import { useMemo, useState, useCallback } from 'react';
import { useGesture } from 'react-use-gesture';
import { isInChartDisplayDateRange } from '~/modules/resourcing/common/hooks/useClippingDateRangeUtil';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { calculateDeltaDates } from '~/modules/resourcing/common/hooks/useGestureUtil';

export const useProjectTimelineReschedule = ({
  scale,
  period,
  chartStartDate,
  chartDisplayDateRange,
  openRescheduleProjectDialog,
  rescheduleProjectDialogCancel
}) => {
  const clippedChartDisplayDateRange = useMemo(
    () => ({
      start:
        period.startDate > chartDisplayDateRange.startDate
          ? period.startDate
          : chartDisplayDateRange.startDate,
      end:
        period.endDate < chartDisplayDateRange.endDate
          ? period.endDate
          : chartDisplayDateRange.endDate
    }),
    [
      period.startDate,
      period.endDate,
      chartDisplayDateRange.endDate,
      chartDisplayDateRange.startDate
    ]
  );

  const isInChartRange = isInChartDisplayDateRange({
    chartDisplayDateRange,
    entityDateRange: period
  });

  const defaultState = useMemo(() => {
    return {
      startDate: period.startDate,
      endDate: period.endDate,
      moveDelta: 0,
      sizeDelta: 0
    };
  }, [period.startDate, period.endDate]);

  const [dates, setDates] = useState(defaultState);

  const handleDrag = useCallback(
    dragState => {
      setDates(
        calculateDeltaDates({
          chartStartDate,
          scale,
          startDate: period.startDate,
          endDate: period.endDate,
          isActive: true,
          sizeDelta: dragState.movement[0]
        })
      );
    },
    [chartStartDate, scale, period.startDate, period.endDate]
  );

  const handleDragEnd = useCallback(
    dragState => {
      setDates(dates);
      openRescheduleProjectDialog();
    },
    [dates, openRescheduleProjectDialog]
  );

  const onCloseHandler = useCallback(
    isJobRunningInBackground => {
      if (!isJobRunningInBackground) setDates(defaultState);
      rescheduleProjectDialogCancel();
    },
    [defaultState, rescheduleProjectDialogCancel]
  );

  const bind = useGesture({
    onDrag: handleDrag,
    onDragEnd: handleDragEnd
  });

  const { dynamicPosition } = getDynamicPositionAttributes({
    chartStartDate,
    ...clippedChartDisplayDateRange,
    scale
  });

  const newStyle = useMemo(
    () => ({
      ...dynamicPosition,
      ...(dates.moveDelta
        ? {
            transform: `translate3d(${dates.moveDelta}px,0px,0)`
          }
        : null),
      ...(dates.sizeDelta
        ? {
            width: dynamicPosition.width + dates.sizeDelta
          }
        : null)
    }),
    [dates.moveDelta, dates.sizeDelta, dynamicPosition]
  );

  return {
    bind,
    dates,
    newStyle,
    isInChartRange,
    onCloseHandler
  };
};
