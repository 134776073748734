import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import CountAndText from './CountAndText';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }
}));

export const PanelTitle = ({ classes: classesOverride, count, title }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      <CountAndText count={count} text={title} />
    </div>
  );
};

PanelTitle.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  count: PropTypes.number
};

export default PanelTitle;
