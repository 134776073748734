import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter
} from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import { Skeleton } from '@mui/material';
import { NoDataItem } from '~/modules/common/components';
import ResourcePoolTableHeader from '~/modules/resource-management/ResourceDrawer/components/ResourceDrawerContent/components/ResourcePoolSection/ResourcePoolTableHeader';
import { useTableStyles } from './useStyles';
import ResourcePoolUserDropdown from './ResourcePoolUserDropdown';
import {
  VIEW_RESOURCE_POOLS_PAGE_SIZE,
  DELETE_RESOURCE_POOL
} from './resourcePoolsReducer';

export const deletePool = ({ pool, dispatch }) => () =>
  dispatch({
    type: DELETE_RESOURCE_POOL,
    pool
  });

export const ResourcePoolUserTable = ({
  currentPage,
  dispatch,
  editOnlyMode = false,
  resourcePoolsState,
  loading,
  loadingCount,
  onNextPage,
  onPreviousPage,
  userDetails
}) => {
  const classes = useTableStyles();
  const intl = useIntl();

  const [showDropdown, setShowDropdown] = useState(false);

  const isCurrentPageEmpty =
    resourcePoolsState.currentPageOfResourcePools.length === 0;

  return (
    <>
      {!loading &&
      !editOnlyMode &&
      resourcePoolsState.currentPageOfResourcePools.length === 0 ? (
        <NoDataItem>
          <FormattedMessage id="resourceDrawer.noResourcePoolsAssigned" />
        </NoDataItem>
      ) : (
        <Table
          className={classes.table}
          size="small"
          aria-label={intl.formatMessage({
            id: 'resourceDrawer.resourcePools'
          })}
          padding="none"
        >
          <TableHead>
            <ResourcePoolTableHeader
              assignedResourcePoolCount={resourcePoolsState.resourcePoolsCount}
              currentPage={currentPage}
              loadingRows={loadingCount}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
              pageSize={VIEW_RESOURCE_POOLS_PAGE_SIZE}
              isCurrentPageEmpty={isCurrentPageEmpty}
            />
          </TableHead>
          <TableBody>
            {resourcePoolsState.currentPageOfResourcePools.map(resourcePool => {
              return (
                <TableRow key={resourcePool.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cell}
                  >
                    {loading ? (
                      <div className={classes.skeletonWrapper}>
                        <Skeleton variant="text" width="95%" height="100%" />
                      </div>
                    ) : (
                      <>
                        <Typography variant="h6" className={classes.cellText}>
                          {resourcePool.displayText}
                        </Typography>
                        {editOnlyMode && (
                          <Button
                            className={classes.deleteButton}
                            aria-label={intl.formatMessage({
                              id: 'button.delete'
                            })}
                            onClick={deletePool({
                              pool: resourcePool,
                              dispatch
                            })}
                          >
                            <ClearIcon fontSize="small" color="disabled" />
                          </Button>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {isCurrentPageEmpty && (
              <TableRow key="no-resource-pools">
                <TableCell component="th" scope="row" className={classes.cell}>
                  <Typography variant="h6" className={classes.noResourcePools}>
                    <FormattedMessage id="resourceBasicInfoCard.noResourcePools" />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {editOnlyMode && (
            <TableFooter className={classes.root}>
              <TableRow>
                <TableCell size="medium" className={classes.footerCell}>
                  {!showDropdown && (
                    <Button
                      color="primary"
                      className={classes.button}
                      onClick={() => setShowDropdown(true)}
                    >
                      <FormattedMessage id="resourceBasicInfoCard.resourcePoolDialog.assignResourcePools" />
                    </Button>
                  )}
                  {showDropdown && (
                    <ResourcePoolUserDropdown
                      userId={userDetails.id}
                      dispatch={dispatch}
                      resourcePoolsState={resourcePoolsState}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      )}
    </>
  );
};

ResourcePoolUserTable.propTypes = {
  currentPage: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  editOnlyMode: PropTypes.bool,
  loading: PropTypes.bool,
  loadingCount: PropTypes.bool,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  resourcePoolsState: PropTypes.shape({
    currentPageOfResourcePools: PropTypes.array,
    allFetchedResourcePools: PropTypes.array,
    unselectedResourcePools: PropTypes.array,
    selectedResourcePools: PropTypes.array,
    dropdownResourcePools: PropTypes.array,
    resourcePoolsCount: PropTypes.number
  }),
  userDetails: PropTypes.object.isRequired
};

export default ResourcePoolUserTable;
