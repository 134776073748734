import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_TIMEOFFS_FOR_USER_QUERY = gql`
  query getResourceTimeoffDetails(
    $userSlug: String!
    $dateRange: DateRangeInput!
  ) {
    resource(userSlug: $userSlug) {
      id
      timeoffs(dateRange: $dateRange) {
        dateRange {
          startDate
          endDate
        }
        timeOffType {
          uri
          displayText
          name
        }
        days
        units
        entries {
          date
          hours
          days
        }
      }
    }
  }
`;

const useTimeoffsForResourceUser = ({ userSlug, dateRange }) => {
  const { loading, error, data = {} } = useQuery(
    RESOURCE_TIMEOFFS_FOR_USER_QUERY,
    {
      variables: {
        userSlug,
        dateRange: dateRange
          ? {
              startDate: dateRange.start,
              endDate: dateRange.end
            }
          : null
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const { resource } = data;
  const timeoffs = (resource && resource.timeoffs) || [];

  return {
    loading,
    timeoffs,
    error
  };
};

export default useTimeoffsForResourceUser;
