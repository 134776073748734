export const resetStateReducer = (state, action) => {
  switch (action.type) {
    case 'SORT_CHANGE':
      return {
        ...state,
        prevIsSortByProjectAscending: !state.prevIsSortByProjectAscending
      };
    case 'FILTER_CHANGE':
      return {
        ...state,
        prevFilter: {
          projectStatusFilterOption: action.projectStatusFilterOption
        }
      };
    default:
      return state;
  }
};
