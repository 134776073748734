import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { array, object, number, mixed } from 'yup';

const QUANTITY_MAX = 50;

export const buildValidationSchema = intl =>
  object().shape({
    resourceAssignments: array().of(
      object().shape({
        role: mixed().required(
          intl.formatMessage({
            id: 'projectRequest.validations.resourceAssignment.roleIsRequired'
          })
        ),
        quantity: number().max(
          QUANTITY_MAX,
          intl.formatMessage(
            {
              id:
                'projectRequest.validations.resourceAssignment.quantityExceedsMax'
            },
            { max: QUANTITY_MAX }
          )
        )
      })
    )
  });

const useOnSubmit = ({ putResourceAssignments }) =>
  useCallback(
    async values => {
      const { resourceAssignments, deletedResourceAssignmentsUris } = values;

      await putResourceAssignments({
        resourceAssignments,
        deletedResourceAssignmentsUris
      });
    },
    [putResourceAssignments]
  );

export const useResourceAssignmentFormik = ({
  resourceAssignments,
  putResourceAssignments
}) => {
  const onSubmit = useOnSubmit({
    putResourceAssignments
  });
  const initialValues = {
    resourceAssignments: (resourceAssignments || []).map(
      resourceAssignment => ({
        ...resourceAssignment,
        rowId: resourceAssignment.id
      })
    ),
    deletedResourceAssignmentsUris: []
  };

  const intl = useIntl();

  const validationSchema = useMemo(() => buildValidationSchema(intl), [intl]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema
  });

  return formik;
};

export default useResourceAssignmentFormik;
