import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import React from 'react';
import get from 'lodash.get';
import FormFieldShimmer from '~/modules/common/components/FormFieldShimmer';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';

export const COUNTRIES_QUERY = gql`
  query GetAllCountries {
    countries {
      displayText
      id
    }
  }
`;

const withCountries = BaseComponent => props => {
  const { loading, data, error } = useQuery(COUNTRIES_QUERY);

  if (loading) return <FormFieldShimmer />;
  if (error) return <RedirectToUnhandledError />;

  const countries = get(data, 'countries') || [];

  return <BaseComponent {...props} countries={countries} />;
};

export default withCountries;
