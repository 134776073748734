import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  item: {
    margin: theme.spacing(3, 2)
  }
}));

export const ResourceRequestRemindersCardSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      {[1, 2].map(row => (
        <Skeleton
          key={`Loading_${row}`}
          className={classes.item}
          width="90%"
          height={20}
        />
      ))}
    </>
  );
};

export default ResourceRequestRemindersCardSkeleton;
