import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const UPDATE_TASK_RESOURCE_ALLOCATIONS = gql`
  mutation updateTaskResourceAllocations(
    $input: UpdateTaskResourceAllocationsInput
  ) {
    updateTaskResourceAllocations(input: $input)
  }
`;

export const useUpdateTaskResourceAllocations = taskId => {
  const [updateTaskResourceAllocations] = useMutation(
    UPDATE_TASK_RESOURCE_ALLOCATIONS
  );

  return useCallback(
    async resourceAllocations => {
      await updateTaskResourceAllocations({
        variables: {
          input: {
            taskId,
            resourceAllocations
          }
        },
        refetchQueries: [
          'getTaskResourceAllocations',
          'getFilteredUserIdsAssignedToTask',
          'getTaskResourceAllocationSummary'
        ],
        awaitRefetchQueries: true
      });
    },
    [taskId, updateTaskResourceAllocations]
  );
};

export default useUpdateTaskResourceAllocations;
