import React from 'react';

import { useIntl } from 'react-intl';
import { SummaryCardHelp } from '~/modules/common/components';

const ResourcesCardHelp = () => {
  const intl = useIntl();

  const toolTipContent = content(intl);

  return <SummaryCardHelp content={toolTipContent} />;
};

const content = intl => [
  {
    title: intl.formatMessage({ id: 'resourcesCardHelp.proposedRequests' }),
    message: intl.formatMessage({
      id: 'resourcesCardHelp.proposedRequestsAlert'
    })
  },
  {
    title: intl.formatMessage({ id: 'resourcesCardHelp.draftRequests' }),
    message: intl.formatMessage({ id: 'resourcesCardHelp.draftRequestsAlert' })
  }
];

export default ResourcesCardHelp;
