import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const PortfolioIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M 5 3 L 5 5 L 3 5 L 3 7 L 5 7 L 5 9 L 3 9 L 3 11 L 5 11 L 5 13 L 3 13 L 3 15 L 5 15 L 5 17 L 3 17 L 3 19 L 5 19 L 5 21 L 19 21 L 19 3 L 5 3 z M 8 6 L 16 6 L 16 10 L 8 10 L 8 6 z " />
    </>
  </SvgIcon>
));

export default PortfolioIcon;
