import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import EntityName from '~/modules/common/components/entities/common/EntityName';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0.5, 1, 0.5, 0),
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden'
  },
  name: {
    display: 'block',
    ...theme.typography.body2,
    lineHeight: `${theme.spacing(2)}px`,
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const ResourcePlanItem = ({ classes: classesOverrides, name }) => {
  const classes = useStyles({ classes: classesOverrides });

  return (
    <div className={classes.root}>
      <EntityName name={name} className={classes.name} />
    </div>
  );
};

ResourcePlanItem.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string.isRequired
};

export default ResourcePlanItem;
