import { useApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useIntl } from 'react-intl';
import { POLICYTYPE } from '~/modules/common/enums';
import { prependNoneItem } from '~/modules/common/enhancers/prependNoneItem';
import { PAGE_SIZE } from '~/modules/common/const';

export const DIVISIONS_QUERY = gql`
  query getDivisions($searchTerm: String, $policy: String, $pageSize: Int) {
    divisions(
      searchTerm: $searchTerm
      page: 1
      pageSize: $pageSize
      policy: $policy
    ) {
      id
      displayText
      hierarchyLevel
    }
  }
`;

const mappedDivisions = data =>
  data?.divisions?.map(division => ({
    ...division,
    value: division.id,
    label: division.displayText,
    key: division.displayText
  }));

const useDivisions = ({
  searchTerm,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  includeNoneOption = false,
  noneOptionText = ''
}) => {
  const { data, loading, refetch } = useQuery(DIVISIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchTerm,
      pageSize: PAGE_SIZE + 1,
      policy
    },
    context: {
      debounceKey: 'division-search',
      debounceTimeout: 250
    }
  });

  const divisions = !loading ? mappedDivisions(data) : [];

  return {
    divisions: (divisions.length && includeNoneOption
      ? prependNoneItem(noneOptionText, divisions)
      : divisions
    ).slice(0, includeNoneOption ? PAGE_SIZE + 1 : PAGE_SIZE),
    loading,
    refetch,
    hasMore: divisions.length > PAGE_SIZE
  };
};

export const fetchDivisions = ({
  apolloClient,
  policy = POLICYTYPE.PROJECT_MANAGEMENT,
  intl
}) => async searchTerm => {
  const { data } = await apolloClient.query({
    query: DIVISIONS_QUERY,
    variables: { searchTerm, pageSize: PAGE_SIZE, policy }
  });

  return prependNoneItem(
    intl.formatMessage({ id: 'resourceBasicInfoCard.noDivision' }),
    mappedDivisions(data)
  );
};

export const useSearchableDivisions = ({ policy } = {}) => {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const fetchOptions = fetchDivisions({
    apolloClient,
    policy,
    intl
  });

  return { fetchOptions };
};

export default useDivisions;
