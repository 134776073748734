import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1)
  },
  header: {
    color: theme.palette.text.secondary,
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: theme.typography.body2.fontSize,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 0, 0.5, 0),
    margin: theme.spacing(0, 0, 0, 0)
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  editButton: {
    margin: theme.spacing(-2.5, 0)
  }
}));

export const SectionHeader = ({
  classes: classesOverride,
  children,
  title,
  editable,
  onEditClick
}) => {
  const intl = useIntl();
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        <span className={classes.title}>{title}</span>
        {editable && (
          <IconButton
            className={classes.editButton}
            onClick={onEditClick}
            aria-label={intl.formatMessage({
              id: 'resourceBasicInfoCard.edit'
            })}
          >
            <EditIcon />
          </IconButton>
        )}
      </Typography>
      {children}
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func
};

export default SectionHeader;
