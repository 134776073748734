import {
  useCanDeleteProjects,
  useCreateProjectDeleteBatch,
  useGetDeleteProjectBatchResults
} from './project';

export const objectHooks = {
  PROJECT: {
    canBulkDelete: useCanDeleteProjects,
    createBulkDeleteBatch: useCreateProjectDeleteBatch,
    getBatchResults: useGetDeleteProjectBatchResults
  }
};
