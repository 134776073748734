import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cell: {
    padding: theme.spacing(0.5, 1)
  }
}));

export const CellRenderer = ({ field, value, component }) => {
  const record = useMemo(
    () => ({
      [field]: value
    }),
    [field, value]
  );

  if (!component) return value;

  const Component = component;

  return <Component field={field} record={record} />;
};

CellRenderer.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
    PropTypes.array
  ]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export const FooterRenderer = ({ footers, totals }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0}>
      {Object.keys(footers).map(field => {
        return (
          <Grid key={`${field}-footer`} item xs={12} className={classes.cell}>
            {totals[field] ? (
              <CellRenderer
                component={footers[field].render}
                field={field}
                value={totals[field]}
              />
            ) : (
              footers[field].value
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

FooterRenderer.propTypes = {
  footers: PropTypes.any,
  totals: PropTypes.object
};

export default FooterRenderer;
