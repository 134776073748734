import { abbreviatedDecimalValue } from '~/modules/common/charts/dashboard';

export default ({ intl, data, hiddenTickValues = [] }) => {
  const { totalForecastedAmount, forecastedAmount, recognizedAmount } = data;

  const {
    currency: { displayText: currencySymbol }
  } = totalForecastedAmount;

  const chartRawData = {
    totalForecasted: { data: [{ x: '', y: totalForecastedAmount.amount }] },
    forecasted: {
      data: forecastedAmount ? [{ x: '', y: forecastedAmount.amount }] : null
    },
    recognized: {
      data: recognizedAmount ? [{ x: '', y: recognizedAmount.amount }] : null,
      label: recognizedAmount
        ? [
            `${abbreviatedDecimalValue(
              intl,
              Math.abs(recognizedAmount.amount)
            )}`
          ]
        : []
    }
  };
  const chartData = Object.keys(chartRawData)
    .filter(k => chartRawData[k].data)
    .reduce((a, k) => ({ ...a, [k]: chartRawData[k] }), {});

  const tickValues = [
    0,
    ...Object.keys(chartData).map(x => chartData[x].data[0].y)
  ];

  const xTickFormat = tick => {
    if (
      tick !== 0 &&
      hiddenTickValues.includes(tick) &&
      tick !== totalForecastedAmount.amount
    ) {
      return '';
    }
    const abbreviatedTickFormat = abbreviatedDecimalValue(intl, Math.abs(tick));

    const value =
      Math.sign(tick) >= 0
        ? abbreviatedTickFormat
        : `-${abbreviatedTickFormat}`;

    return `${currencySymbol} ${value}`;
  };
  const highestValue = Math.max(...tickValues);

  return {
    xTickFormat,
    chartData,
    tickValues,
    highestValue
  };
};
