import queryString from 'query-string';
import { useResourceManagementContext } from '~/modules/resource-management/common';
import useFilterBasedOnSearchCriteria from '~/modules/resourcing/hooks/useFilterBasedOnSearchCriteria';

export const getStringifiedQueryParams = ({
  planStatus,
  roles,
  locations,
  divisions,
  serviceCenters,
  costCenters,
  departments,
  employeeTypes,
  skills,
  label
}) => {
  const stringified = queryString.stringify({
    roles,
    locations,
    divisions,
    serviceCenters,
    costCenters,
    departments,
    employeeTypes,
    label,
    skills
  });

  return `/resourcing/${planStatus}?${stringified}`;
};
export const useRedirectToProjectResourcingLink = planStatus => {
  const { searchCriteria } = useResourceManagementContext();

  const {
    filter: {
      roles = [],
      locations = [],
      divisions = [],
      serviceCenters = [],
      costCenters = [],
      departments = [],
      employeeTypes = [],
      skills = []
    } = {}
  } = useFilterBasedOnSearchCriteria({ searchCriteria });

  return getStringifiedQueryParams({
    planStatus,
    roles,
    locations,
    divisions,
    serviceCenters,
    costCenters,
    departments,
    employeeTypes,
    skills
  });
};

export default useRedirectToProjectResourcingLink;
