import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import { AbbreviatedDecimalHideNull } from '~/modules/common/components';
import { BudgetCostChartLegend } from './BudgetCostChartLegend';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: `1 5 ${theme.spacing(34)}px`,
    minWidth: theme.spacing(34),
    maxWidth: theme.spacing(70),
    paddingLeft: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(2)
  },
  title: {
    width: '100%',
    color: theme.palette.grey[600],
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  budgetTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  budgetValue: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.43
  }
}));

const estimatedCostLabel = (
  <FormattedMessage id="projectBudgetChart.estimatedCost" />
);
const estimatedCostAtCompletionLabel = (
  <FormattedMessage id="projectBudgetChart.estimatedCostAtCompletion" />
);
const allocatedCostLabel = (
  <FormattedMessage id="projectBudgetChart.allocatedCost" />
);
const resourceCostLabel = (
  <FormattedMessage id="projectBudgetChart.resourceCost" />
);

export const BudgetCostChartLegends = ({
  actualBarColor,
  projectCostSummary
}) => {
  const classes = useStyles();

  const {
    budgetedResourceCost,
    estimatedResourceCost,
    estimatedResourceCostAtCompletion,
    allocatedResourceCost,
    actualResourceCost
  } = projectCostSummary;

  return (
    <div className={classes.root}>
      <Typography className={classes.budgetTitle} variant="body2">
        <FormattedMessage id="projectBudgetChart.budgetCost" />
      </Typography>
      <Typography className={classes.budgetValue} variant="subtitle1">
        <AbbreviatedDecimalHideNull
          value={budgetedResourceCost?.amount}
          prefix={budgetedResourceCost?.currency?.symbol}
          dataQeId="BudgetCostChartLegends_BudgetCostTooltip"
        />
      </Typography>
      <div className={classes.container}>
        <Typography className={classes.title} variant="body2">
          <FormattedMessage id="chart.project" />
        </Typography>
        <BudgetCostChartLegend
          actualBarColor={actualBarColor}
          estimatedValue={estimatedResourceCost?.amount}
          actualValue={actualResourceCost?.amount}
          estimatedLabel={estimatedCostLabel}
          actualLabel={resourceCostLabel}
          estimatedPrefix={estimatedResourceCost?.currency?.symbol}
          actualPrefix={actualResourceCost?.currency?.symbol}
          dataQeId="BudgetCostChartLegend_ProjectEstimatedCost"
        />
        <Typography className={classes.title} variant="body2">
          <FormattedMessage id="chart.tasks" />
        </Typography>
        <BudgetCostChartLegend
          actualBarColor={actualBarColor}
          estimatedValue={estimatedResourceCostAtCompletion?.amount}
          actualValue={actualResourceCost?.amount}
          estimatedLabel={estimatedCostAtCompletionLabel}
          actualLabel={resourceCostLabel}
          estimatedPrefix={estimatedResourceCostAtCompletion?.currency?.symbol}
          actualPrefix={actualResourceCost?.currency?.symbol}
          dataQeId="BudgetCostChartLegend_EstimatedCostAtCompletion"
        />
        <Typography className={classes.title} variant="body2">
          <FormattedMessage id="chart.resources" />
        </Typography>
        <BudgetCostChartLegend
          actualBarColor={actualBarColor}
          estimatedValue={allocatedResourceCost?.amount}
          actualValue={actualResourceCost?.amount}
          estimatedLabel={allocatedCostLabel}
          actualLabel={resourceCostLabel}
          estimatedPrefix={allocatedResourceCost?.currency?.symbol}
          actualPrefix={actualResourceCost?.currency?.symbol}
          dataQeId="BudgetCostChartLegend_TotalAllocatedCost"
        />
      </div>
    </div>
  );
};

BudgetCostChartLegends.propTypes = {
  actualBarColor: PropTypes.string,
  projectCostSummary: PropTypes.object
};

export default BudgetCostChartLegends;
